import React, { useCallback, useImperativeHandle, useMemo, useState } from 'react'
import RoundedModal from '../../../../molecules/RoundedModal'
import { useStatusTemplateContext } from '../StatusTemplateProvider'
import AddTemplateItemForm from './AddTemplateItemForm'

const AddTemplateItemDialog = React.forwardRef(function AddTemplateItemDialog (_, ref) {
  const [dialogState, setDialogState] = useState({ open: false, processing: false })
  const close = useCallback(() => {
    setDialogState({ open: false, processing: false })
  }, [setDialogState])

  useImperativeHandle(ref, () => ({
    open: (item) => setDialogState({ open: true, processing: false, parent: item })
  }), [setDialogState])

  const parent = useMemo(() => {
    return dialogState.parent
  }, [dialogState])
  const { statusTemplate } = useStatusTemplateContext()

  return (
    <RoundedModal
      title='Add Template Item'
      open={dialogState.open}
      onClose={close}
      position='top'
      disablePortal={false}
    >
      <div>
        <AddTemplateItemForm parent={parent} onCancel={close} onComplete={close} statusTemplateId={statusTemplate.statusTemplateId} />
      </div>
    </RoundedModal>
  )
})

AddTemplateItemDialog.propTypes = {
}

export default AddTemplateItemDialog
