import React, { useCallback, useMemo } from 'react'
import { useTheme } from '@material-ui/core'
import PropTypes from 'prop-types'
import { ResponsivePie } from '@nivo/pie'
import { useFirmReportData } from '../../../api/coreData'
import { useFormattingContext } from '../FormattingProvider/FormattingContext'
import { useDenaliFilter } from './filters/DenaliFilterContextProvider'

const useFormatters = (valueFormat) => {
  const { formatter } = useFormattingContext()

  const valueFormatter = useCallback(d => {
    return formatter(d, valueFormat)
  }, [formatter, valueFormat])

  return {
    valueFormatter
  }
}

function DenaliPieChart ({
  reportId,
  baseQuery,
  indexBy,
  valueAccessor,
  valueFormat,
  height,
  ...chartProps
}) {
  const theme = useTheme()
  const { filter, membershipFilter } = useDenaliFilter()
  const query = useMemo(() => {
    return {
      ...baseQuery,
      membershipFilter,
      filters: {
        ...(filter || {}),
        ...(baseQuery?.filters || {})
      },
      reportId
    }
  }, [reportId, baseQuery, filter, membershipFilter])

  const { data: report, isLoading } = useFirmReportData(query)
  const pieData = useMemo(() => {
    if (!report) return []

    return report.data.map(x => ({
      id: x[indexBy],
      label: x[indexBy],
      value: x[valueAccessor]
    }))
  }, [report, indexBy, valueAccessor])
  const { valueFormatter } = useFormatters(valueFormat)

  if (isLoading) return null

  return (
    <div style={{ height }}>
      <ResponsivePie
        theme={{
          fontFamily: theme.typography.fontFamily,
          fontSize: '12px'
        }}
        data={pieData}
        valueFormat={valueFormatter}
        {...chartProps}
      />
    </div>
  )
}

DenaliPieChart.propTypes = {
  reportId: PropTypes.string,
  baseQuery: PropTypes.object,
  indexBy: PropTypes.string,
  valueAccessor: PropTypes.string,
  valueFormat: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
}

DenaliPieChart.defaultProps = {
  valueFormat: 'marketValue',
  height: 300
}

export default DenaliPieChart
