import React, { useCallback, useRef } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import RoundedButton from '../../../../atoms/RoundedButton'
import AllocationAnalysisTable from '../../components/AllocationAnalysisTable'
import ClientDisplay from '../../components/ClientDisplay'
import AllocationAnalysisChart from '../../components/AllocationAnalysisChart'
import ClientAsOfDate from '../../components/ClientAsOfDate'
import InlineError from '../../components/InlineError'
import { useSubmitRebalance } from '../../../../../api/rebalancer'
import { useAvtData } from '../useAvtData'
import InstructionsPanel, { useInstructionsPanel } from '../../components/InstructionsPanel'
import ModelStats from '../../components/ModelStats'
import { useAvailableCash } from '../../hooks/useAvailableCash'
import RaiseCashEquation from './RaiseCashEquation'
import RaiseCashOptions from './RaiseCashOptions'

const useStyles = makeStyles((theme) => ({
  flow: {
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start'
  },
  fu: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '60px',
    flexWrap: 'wrap',
    border: `1px solid ${theme.palette.gray.dark}`,
    borderRadius: '5px',
    padding: '20px 10px'
  },
  buttonGroup: {
    display: 'flex',
    flexDirection: 'row',
    gap: '15px'
  }
}))

function RaiseCashFlow ({ clientId }) {
  const classes = useStyles()
  const { mutateAsync: submitRebalance, isLoading: isSubmitting, error: submitError } = useSubmitRebalance()
  const { cashResponse, cashLoading } = useAvailableCash(clientId)
  const history = useHistory()
  const equationRef = useRef()
  const optionsRef = useRef()
  const handleSubmit = useCallback(async () => {
    const amountToRaise = +equationRef.current
    if (amountToRaise === 0) {
      alert('To raise cash you must choose some amount greater than zero.')
      return
    }

    const { mode, scope, taxabilityType } = (optionsRef.current || {})
    const withdrawals = scope === 'full'
      ? [{ scope, amount: amountToRaise }]
      : [{ scope, taxabilityType, amount: amountToRaise }]

    const result = await submitRebalance({
      flow: 'raiseCash',
      levelTypeId: 201,
      levelId: clientId,
      flowData: {
        includeRebalance: mode,
        withdrawals
      }
    })

    history.push(`/rebalancer/results/${result.rebalancingRunId}`)
  }, [clientId, submitRebalance, history])
  const { ref: instructionsRef, onSettingsClick } = useInstructionsPanel()
  const { data: avtData, isLoading } = useAvtData(clientId)

  return (
    <div className={classes.flow}>
      <div className={classes.header}>
        <ClientDisplay clientId={clientId} />
        <div>
          <ClientAsOfDate clientId={clientId} />
          <div className={classes.buttonGroup}>
            <RoundedButton
              variant='outlined'
              onClick={onSettingsClick}
              disabled={isSubmitting}
            >
              Instructions
            </RoundedButton>
            <RoundedButton
              variant='contained'
              primary
              onClick={handleSubmit}
              disabled={isSubmitting || cashLoading}
            >
              {isSubmitting ? 'Submitting...' : 'Raise Cash'}
            </RoundedButton>
          </div>
        </div>
      </div>
      <InlineError clientId={clientId} error={submitError} />
      <div>
        <ModelStats
          avtData={avtData}
          cashData={cashResponse}
          loading={isLoading || cashLoading}
          includeVariance
          includeCash
          includeValue
        />
        <div className={classes.fu}>
          <RaiseCashEquation
            ref={equationRef}
            loading={cashLoading}
            cash={cashResponse?.availableCash}
            available={cashResponse?.availableValue}
          />
          <RaiseCashOptions ref={optionsRef} loading={cashLoading} />
        </div>
        <AllocationAnalysisChart avtData={avtData} loading={isLoading} />
      </div>
      <AllocationAnalysisTable avtData={avtData} loading={isLoading} />
      <InstructionsPanel ref={instructionsRef} clientId={clientId} />
    </div>
  )
}

RaiseCashFlow.propTypes = {
  clientId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
}

export default RaiseCashFlow
