import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import AllocationBarChartItem from './AllocationBarChartItem'

const useStyles = makeStyles(() => ({
  barChart: {
    padding: 0
  }
}))

const AllocationBarChart = ({
  data,
  isLoading,
  valueFormat,
  balanceFormat
}) => {
  const classes = useStyles()

  if (isLoading) {
    return <div>Loading</div>
  }

  return (
    <div className={classes.barChart}>
      {data.map(datum => (
        <AllocationBarChartItem
          key={datum.id}
          value={datum.value}
          valueFormat={valueFormat}
          balance={datum.balance}
          balanceFormat={balanceFormat}
          label={datum.label}
          color={datum.color}
        />
      ))}
    </div>
  )
}

AllocationBarChart.propTypes = {
  data: PropTypes.array,
  isLoading: PropTypes.bool,
  valueFormat: PropTypes.string,
  balanceFormat: PropTypes.string
}

AllocationBarChart.defaultProps = {
  data: [],
  isLoading: false,
  valueFormat: 'allocation',
  balanceFormat: 'marketValue'
}

export default AllocationBarChart
