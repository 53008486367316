import { useMemo } from 'react'
import mergeWith from 'lodash/mergeWith'
import { useAppContext } from '../../../redux/slices/appContext'
import { useClientDates, useNormalizeRelativeDates, useTransactionsSearch } from '../../../api/coreData'

const mapSorts = (sorts) => sorts?.map(({ id, desc, field }) => ({ field: field ?? id, dir: desc ? 'desc' : 'asc' }))

export const useClientDateRange = (clientId, asOf, dateRange) => {
  const { data: clientDates, isLoading: loadingClientDates } = useClientDates(clientId)
  const normalizeRequest = useMemo(() => {
    if (!clientDates?.max) return null

    return ({
      asOfDate: asOf || clientDates.max,
      levelFilters: {
        levelTypes: ['client'],
        clientIds: [clientId]
      },
      relativeRanges: [dateRange]
    })
  }, [clientId, clientDates, dateRange, asOf])
  const { data: relativeDates, isLoading: loadingRelativeDates } = useNormalizeRelativeDates(normalizeRequest, { enabled: !!normalizeRequest })

  const value = useMemo(() => {
    const dateRangeKey = typeof dateRange === 'string' ? dateRange : dateRange.key
    return relativeDates?.[dateRangeKey]?.value || null
  }, [relativeDates, dateRange])

  return {
    relativeDates,
    clientDates,
    loading: loadingRelativeDates || loadingClientDates,
    value
  }
}

const merger = (a, b) => {
  if (Array.isArray(a) && Array.isArray(b)) {
    return a.concat(b)
  }
}

export const useTransactionsData = ({
  dateRange,
  asOfDate,
  featuredFilters,
  levelFilters,
  textFilter,
  tableFilter,
  scope = 'client',
  defaultFilter,
  sort,
  pageSize,
  pageIndex
}) => {
  const { clientId } = useAppContext()
  const { value: _dateRange, loading: datesLoading } = useClientDateRange(clientId, asOfDate, dateRange)

  const transactionsQuery = useMemo(() => {
    if (!_dateRange) return null

    const _filters = (featuredFilters || []).reduce((prev, fil) => {
      return mergeWith(prev, fil.filter, merger)
    }, {})

    return ({
      includes: {
        transactionCodeTags: {
          enabled: true,
          format: 'flat'
        },
        transactionTags: {
          enabled: true,
          format: 'flat'
        }
      },
      levelFilters,
      filters: {
        ...(defaultFilter || {}),
        ..._filters,
        ...(tableFilter || {}),
        valueDate: [
          { op: 'gte', value: _dateRange.startDate },
          { op: 'lte', value: _dateRange.endDate }
        ],
        ...(scope === 'client' ? { clientId } : {})
      },
      textSearch: textFilter || {},
      sort: mapSorts(sort),
      skip: pageSize * pageIndex,
      take: pageSize
    })
  }, [_dateRange, clientId, scope, featuredFilters, textFilter, tableFilter, defaultFilter, sort, pageSize, pageIndex, levelFilters])

  const { data: transactions, isFetching: transactionsLoading } = useTransactionsSearch(transactionsQuery, { enabled: !!transactionsQuery })

  return {
    relativeDates: _dateRange,
    datesLoading,
    transactions: transactions || [],
    transactionsLoading: transactionsLoading || datesLoading
  }
}

export const useTransactionsCounts = ({
  dateRange,
  asOfDate,
  featuredFilters,
  levelFilters,
  textFilter,
  tableFilter,
  scope = 'client',
  defaultFilter
}) => {
  const { clientId } = useAppContext()
  const { value: _dateRange, loading: datesLoading } = useClientDateRange(clientId, asOfDate, dateRange)

  const transactionsQuery = useMemo(() => {
    if (!_dateRange) return null

    const _filters = (featuredFilters || []).reduce((prev, fil) => {
      return mergeWith(prev, fil.filter, merger)
    }, {})

    return ({
      resultType: 'total',
      levelFilters,
      filters: {
        ...(defaultFilter || {}),
        ..._filters,
        ...(tableFilter || {}),
        valueDate: [
          { op: 'gte', value: _dateRange.startDate },
          { op: 'lte', value: _dateRange.endDate }
        ],
        ...(scope === 'client' ? { clientId } : {})
      },
      textSearch: textFilter || {}
    })
  }, [_dateRange, clientId, scope, featuredFilters, textFilter, tableFilter, defaultFilter, levelFilters])

  const { data: transactions, isFetching: transactionsLoading } = useTransactionsSearch(transactionsQuery, { enabled: !!transactionsQuery })

  return {
    relativeDates: _dateRange,
    datesLoading,
    counts: transactions || [],
    countsLoading: transactionsLoading || datesLoading
  }
}
