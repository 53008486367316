import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import isEmpty from 'lodash/isEmpty'
import find from 'lodash/find'
import { useParams } from 'react-router-dom'
import {
  Box,
  Grid,
  TextField,
  useTheme
} from '@material-ui/core'
import { FormProvider, useForm, Controller } from 'react-hook-form'
import { useBoolean, useCheckPolicy } from '../../../../hooks'
import { assetClassificationBulkUpdate } from '../../../../service'
import ErrorComponent from '../../../atoms/ErrorComponent'
import Text from '../../../atoms/Text'
import Avatar from '../../../atoms/Avatar'
import GroupingPicker from '../../../organisms/GroupingProvider/GroupingPicker'
import GroupingTab from '../../../organisms/GroupingProvider/GroupingTab'
import GroupingProvider from '../../../organisms/GroupingProvider'
import { useClassificationTagTypes, useAssetClassificationTagTypes } from '../../../../api/coreData'
import SaveCancelButtons from '../../../molecules/SaveCancelButtons'
import SectionHeader from '../shared/SectionHeader'
import { ASSETS } from '../../../../policies/admin'
import SydLabel from '../../../commonDesign/SydLabel'
import SydReadOnly from '../../../commonDesign/SydReadOnly'
import {
  mapFormDefaultValues
} from './helpers'
import { useAsset, useUpdateAsset } from './hooks'
import ClassificationTags from './ClassificationTags'
import Holdings from './Holdings'
import EditButton from './EditButton'

const useStyles = makeStyles((theme) => ({
  container: ({ editMode }) => ({
    padding: `0rem 3rem ${editMode ? '5.5rem' : '1rem'} 3rem`
  }),
  formActions: {
    position: 'fixed',
    bottom: '1rem',
    left: '50%',
    padding: '0',
    transform: 'translateX(-50%)',
    width: 'fit-content',
    zIndex: 2
  },
  label: {
    marginBottom: '17px'
  },
  firstRow: {
    marginBottom: '40px'
  },
  avatar: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  }
}))

const setDefaultValues = ({ classificationTagsData, asset }) => {
  const defaultClassification = classificationTagsData.reduce((acc, classificationTag) => {
    const classification = find(asset.classificationTags, ['classificationTagTypeId', classificationTag.classificationTagTypeId])
    return {
      ...acc,
      [classificationTag.classificationTagTypeId]: {
        classificationTagTypeId: classificationTag.classificationTagTypeId,
        classificationTagId: classification?.classificationTagId || '',
        isRequired: Boolean(classificationTag.required),
        name: classification?.longName
      }
    }
  }, {})
  return defaultClassification
}

function AssetDetailsView () {
  const params = useParams()
  const theme = useTheme()

  const [editMode, setIsEditMode] = useBoolean()
  const [selectedClassification, setSelectedClassification] = useState({})
  const [formSubmitting, setIsFormSubmitting] = useBoolean()
  const canViewAssetsHldgs = useCheckPolicy(ASSETS.viewAssetsHldgs)
  const canEditAssetDetails = useCheckPolicy(ASSETS.editAssetDetails)
  const canEditAssetTags = useCheckPolicy(ASSETS.editAssetTags)

  const classes = useStyles({ editMode })

  const { data: asset, loading, error: assetError } = useAsset({ assetId: params.id })
  const { data: classificationTagsData = [], loading: classificationTagLoading } = useClassificationTagTypes({ resultType: 'details' })
  const { data: assetClassificationTagsTypes = [], loading: assetClassificationTagTypesLoading } = useAssetClassificationTagTypes()
  const updateAsset = useUpdateAsset()
  const defaultValues = useMemo(() => {
    return mapFormDefaultValues(asset)
  }, [asset])

  const formMethods = useForm({
    mode: 'onChange',
    defaultValues
  })

  const {
    reset,
    errors = {},
    register,
    control,
    handleSubmit,
    formState: {
      isValid: _isFormValid
    }
  } = formMethods

  const onResetForm = useCallback(() => {
    reset(defaultValues)
  }, [reset, defaultValues])

  const onCancel = useCallback(() => {
    onResetForm()
    const defaultClassification = setDefaultValues({ classificationTagsData, asset })
    setSelectedClassification(defaultClassification)
    setIsEditMode.off()
  }, [onResetForm, setIsEditMode, classificationTagsData, asset])

  useEffect(() => {
    if (!isEmpty(asset) && !isEmpty(classificationTagsData)) {
      const defaultClassification = setDefaultValues({ classificationTagsData, asset })
      setSelectedClassification(defaultClassification)
    }
  }, [asset, classificationTagsData])

  const onChange = useCallback(({ value, classificationTagTypeId, isRequired, options }) => {
    const selectedOption = find(options, ['id', value])
    setSelectedClassification((prevState) => {
      return {
        ...prevState,
        [classificationTagTypeId]: {
          classificationTagTypeId,
          classificationTagId: value,
          isRequired,
          name: selectedOption?.tagName || ''
        }
      }
    })
  }, [])

  const onRemove = useCallback((classification) => {
    setSelectedClassification((prevState) => {
      return {
        ...prevState,
        [classification.classificationTagTypeId]: {
          classificationTagTypeId: classification.classificationTagTypeId,
          classificationTagId: '',
          name: '',
          isRequired: false
        }
      }
    })
  }, [])

  const onSubmit = useCallback(
    async (values) => {
      if (!canEditAssetDetails) return null
      try {
        setIsFormSubmitting.on()
        const payload = Object.values(selectedClassification).map((option) => {
          return {
            assetId: asset.assetId,
            ...canEditAssetTags ? {
              classificationTagTypeId: option.classificationTagTypeId,
              classificationTagId: option.classificationTagId || null
            } : {}
          }
        })
        await updateAsset.mutate({ assetId: asset.assetId, body: { displayName: values.displayName } })
        await assetClassificationBulkUpdate(payload)
      } catch (err) {
        console.error(err)
      } finally {
        setIsEditMode.off()
        setIsFormSubmitting.off()
      }
    },
    [setIsEditMode, setIsFormSubmitting, selectedClassification, asset, updateAsset, canEditAssetTags, canEditAssetDetails]
  )

  if (assetError) {
    return <ErrorComponent error={assetError} />
  }

  return (
    <>
      <div className={classes.container}>
        {(!loading || classificationTagLoading) && (
          <FormProvider {...formMethods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <GroupingProvider
                groupings={{
                  'General Info': 'generalInfo',
                  ...canViewAssetsHldgs ? { Positions: 'positions' } : {}
                }}
              >
                <Grid container>
                  <Grid container item xs={12}>
                    <Grid item xs={2} className={classes.avatar}>
                      <Avatar
                        src='' // TODO: replace avatar with image picker
                        avatarLetters={asset.assetName}
                        customSize='90px'
                        customClassName={classes.avatar}
                      />
                    </Grid>
                    <Grid item container xs={4}>
                      <Grid item xs={12} className={classes.firstRow}>
                        <Text text='Display Name' color={theme.palette.darkJungle} customFontSize='0.75rem' className={classes.label} />
                        {editMode ? (
                          <Controller
                            name='displayName'
                            render={({ field: { onChange } }) => (
                              <TextField
                                autoFocus
                                fullWidth
                                className={classes.textField}
                                helperText='100 max characters'
                                type='input'
                                placeholder='Display name'
                                onChange={onChange}
                                error={Boolean(errors.displayName)}
                                {...register('displayName', { maxLenght: 100 })}
                                defaultValue={asset?.displayName || ''}
                                control={control}
                              />)}
                          />
                        ) : <Text text={asset.displayName} />}
                      </Grid>
                      <Grid item container xs={12}>
                        <Grid item xs={12} md={6}>
                          <Text text='Symbol' color={theme.palette.darkJungle} customFontSize='0.75rem' className={classes.label} />
                          <Text text={asset.symbol || 'N/A'} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Text text='CUSIP' color={theme.palette.darkJungle} customFontSize='0.75rem' className={classes.label} />
                          <Text text={asset.cusip || 'N/A'} />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid container item xs={4}>
                      <Grid item xs={12} className={classes.firstRow}>
                        <Text text='Asset Name' color={theme.palette.darkJungle} customFontSize='0.75rem' className={classes.label} />
                        <Text text={asset.assetName} />
                      </Grid>
                      <Grid item container xs={12}>
                        <Grid item xs={12} md={6}>
                          <Text text='ISIN' color={theme.palette.darkJungle} customFontSize='0.75rem' className={classes.label} />
                          <Text text={asset.isin || 'N/A'} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Text text='Unique Identifier' color={theme.palette.darkJungle} customFontSize='0.75rem' className={classes.label} />
                          <Text text={asset.uniqueIdentifier || 'N/A'} />
                        </Grid>
                      </Grid>
                    </Grid>
                    {canEditAssetDetails && (
                      <Grid item xs={2}>
                        <Box flexDirection='column' gridGap='10px' display='flex'>
                          <EditButton
                            disabledOn='positions'
                            onClick={setIsEditMode.toggle}
                          >
                            {editMode ? 'Editing' : 'Edit'}
                          </EditButton>
                        </Box>
                      </Grid>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <Grid item xs={12} style={{ paddingBottom: 0, marginTop: '50px' }}>
                      <GroupingPicker
                        groupOptions={{
                          'General Info': {
                            hideCustomizeColumnsButton: true,
                            hideExpandCollapseButton: true,
                            hideExportButton: true
                          },
                          ...canViewAssetsHldgs ? {
                            Positions: {
                              hideCustomizeColumnsButton: true,
                              hideExpandCollapseButton: true,
                              hideExportButton: true
                            }
                          } : {}
                        }}
                      />
                    </Grid>
                    <GroupingTab tabKey='General Info'>
                      <ClassificationTags
                        editMode={editMode && canEditAssetTags}
                        loading={loading || assetClassificationTagTypesLoading || classificationTagLoading}
                        classificationTagsData={classificationTagsData}
                        assetClassificationTagsTypes={assetClassificationTagsTypes}
                        selectedClassification={selectedClassification}
                        onChange={onChange}
                        onClickRemove={onRemove}
                      />
                      <div>
                        <SectionHeader text='Other Information' />
                        <Grid container>
                          <Grid item xs={12} md={6}>
                            <SydLabel label='Asset Type'>
                              <SydReadOnly disabled>
                                {asset.assetType}
                              </SydReadOnly>
                            </SydLabel>
                          </Grid>
                        </Grid>
                      </div>
                    </GroupingTab>
                    {canViewAssetsHldgs && (
                      <GroupingTab tabKey='Positions'>
                        <Grid container xs={12}>
                          <Holdings
                            assetId={asset.assetId}
                          />
                        </Grid>
                      </GroupingTab>
                    )}
                  </Grid>
                </Grid>
              </GroupingProvider>
              {editMode && (
                <SaveCancelButtons
                  containerClassName={classes.formActions}
                  onCancel={onCancel}
                  isSubmitting={formSubmitting}
                  isFormValid={_isFormValid}
                />
              )}
            </form>
          </FormProvider>
        )}
      </div>
    </>
  )
}

export default AssetDetailsView
