import React, { useState } from 'react'
import PropTypes from 'prop-types'
import RuleEditor from './RuleEditor'

const schema = {
  transaction: {
    id: 'transaction',
    name: 'Transaction',
    description: 'A financial transaction',
    schema: {
      marketValue: {
        id: 'marketValue',
        name: 'Market Value',
        type: 'number',
        path: '$.marketValue',
        description: 'The monetary value of the transaction',
        nullable: false
      },
      valueDate: {
        id: 'valueDate',
        name: 'Value Date',
        type: 'date',
        path: '$.valueDate',
        description: 'The date of the transaction',
        nullable: false
      }
    }
  },
  position: {
    id: 'position',
    name: 'Position',
    description: 'The position of an account on a certain point in time',
    schema: {
      positionDate: {
        id: 'positionDate',
        name: 'Position Date',
        type: 'date',
        path: '$.minDate',
        description: 'The date of the position value',
        nullable: false
      }
    }
  }
}

function RuleBuilderForm ({ rules }) {
  const [rule, setRule] = useState(null)
  return (
    <div>
      <RuleEditor schema={schema} onRuleChange={setRule} />
      <div>
        <pre>{rule ? JSON.stringify(rule) : 'null'}</pre>
      </div>
    </div>
  )
}

RuleBuilderForm.propTypes = {
  rules: PropTypes.any
}

export default RuleBuilderForm
