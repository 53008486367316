import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { animated, useSpring } from 'react-spring'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { Box } from '@material-ui/core'
import isEmpty from 'lodash.isempty'
import { ICON_NAMES, TEXT_VARIANTS } from '../../constants'
import Text from '../atoms/Text'
import Icon from '../atoms/Icon'
import { menuOptions } from '../../prop-types'
import { useFallbackImg } from '../../hooks'
import Menu from './Menu'

dayjs.extend(relativeTime)

const LABEL_HEIGHT = '69px'
const LABEL_POSITION = -20
const LABEL_HOVERED_POSITION = 0

const useStyles = makeStyles((theme) => ({
  container: {
    cursor: 'pointer',
    position: 'relative',
    overflow: 'hidden',
    borderRadius: '1rem'
  },
  imageContainer: {
    height: '224px',
    width: '237px',
    borderRadius: 'inherit',
    backgroundColor: '#fafafa'
  },
  image: {
    width: '100%',
    borderRadius: 'inherit',
    padding: '12px'
  },
  label: {
    height: LABEL_HEIGHT,
    width: '100%',
    backgroundColor: theme.palette.primary.main,
    opacity: '90%',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'row',
    color: theme.palette.primary.contrastText,
    position: 'absolute',
    bottom: LABEL_POSITION,
    padding: '8px 16px'
  },
  title: {
    display: 'block',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  iconButton: {
    cursor: 'pointer'
  }
}))

const DocumentThumbnail = ({ onClick, src, fallbackSrc, title, updatedAt, menuOptions }) => {
  const classes = useStyles()
  const theme = useTheme()
  const { src: imgSrc, onError } = useFallbackImg(src, fallbackSrc)

  const [isHovered, setIsHovered] = useState(false)

  const onMouseEnter = () => setIsHovered(true)
  const onMouseLeave = () => setIsHovered(false)

  const { border, bottom, opacity } = useSpring({
    from: {
      border: '2px solid #ccc',
      bottom: LABEL_POSITION,
      opacity: 0
    },
    to: {
      bottom: isHovered ? LABEL_HOVERED_POSITION : LABEL_POSITION,
      border: isHovered ? `2px solid ${theme.palette.primary.main}` : '2px solid #ffffff00',
      opacity: isHovered ? 1 : 0
    }
  })

  const renderMenuIcon = useCallback(({ setAnchorEl }) => {
    return (
      <div className={classes.iconButton} onClick={setAnchorEl}>
        <Icon name={ICON_NAMES.threeDots} customSize='1.5rem' color='white' />
      </div>
    )
  }, [classes.iconButton])

  return (
    <animated.div
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      className={classes.container}
    >
      <animated.div
        className={classes.imageContainer}
        onClick={onClick}
        style={{ border }}
      >
        <img
          src={imgSrc}
          onError={onError}
          className={classes.image}
          alt='File thumbnail'
        />
      </animated.div>
      <animated.div className={classes.label} style={{ bottom }}>
        <Box flex='1 1 auto' width='calc(100% - 1.5rem)'>
          <div>
            <Text
              text={title}
              customFontSize='1rem'
              variant={TEXT_VARIANTS.h3}
              className={classes.title}
            />
          </div>
          <animated.div style={{ opacity, marginTop: '0.25rem' }}>
            <Text
              text={`Last updated ${dayjs(updatedAt).fromNow()}`}
              customFontSize='0.75rem'
            />
          </animated.div>
        </Box>
        {isHovered && !isEmpty(menuOptions) && (
          <Menu options={menuOptions}>{renderMenuIcon}</Menu>
        )}
      </animated.div>
    </animated.div>
  )
}

DocumentThumbnail.propTypes = {
  onClick: PropTypes.func,
  src: PropTypes.string,
  title: PropTypes.string,
  updatedAt: PropTypes.string.isRequired,
  menuOptions,
  fallbackSrc: PropTypes.string
}

DocumentThumbnail.defaultProps = {
  onClick: noop,
  src: undefined,
  title: '',
  menuOptions: [],
  fallbackSrc: ''
}

export default DocumentThumbnail
