import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useFormattingContext } from '../../../organisms/FormattingProvider/FormattingContext'
import AnalysisMetric from './AnalysisMetric'

function ValueMetric ({ cashData, loading }) {
  const { formatter } = useFormattingContext()
  const value = useMemo(() => {
    if (loading) return ''

    const variance = cashData.totalValue

    return formatter(variance, '$0,0.0a')
  }, [loading, cashData, formatter])

  return (
    <AnalysisMetric loading={loading} metricName='Value' value={value} />
  )
}

ValueMetric.propTypes = {
  loading: PropTypes.bool,
  cashData: PropTypes.shape({
    totalValue: PropTypes.number
  })
}

ValueMetric.defaultProps = {
}

export default ValueMetric
