import React, { useCallback, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Controller, useForm } from 'react-hook-form'
import dayjs from 'dayjs'
import quarterOfYear from 'dayjs/plugin/quarterOfYear'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import { Checkbox, FormControlLabel, makeStyles, TextField, useTheme } from '@material-ui/core'
import noop from 'lodash/noop'
import { Autocomplete, createFilterOptions } from '@material-ui/lab'
import { ALERT_SEVERITY, BUTTON_SIZES, ERROR_ALERT, SUCCESS_ALERT, TEXT_VARIANTS } from '../../../../../constants'
import { postReport, postReportBatch } from '../../../../../service'
import Text from '../../../../atoms/Text'
import RoundedButton from '../../../../atoms/RoundedButton'
import SnackAlert from '../../../../molecules/SnackAlert/SnackAlert'
import { messageToUse } from '../../common'
import Skeleton from '../../../../atoms/Skeleton'
import { pluralize } from '../../../../../utils'

dayjs.extend(isSameOrAfter)

dayjs.extend(quarterOfYear)

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%'
  },
  groupItem: {
    width: '100%',
    padding: '1rem'
  },
  labelWithInfo: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  buttonGroup: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    padding: '10px 0 20px'
  },
  buttonGroupItem: {
    display: 'flex',
    maxWidth: '100%',
    padding: '0 1rem'
  },
  itemTextField: {
    backgroundColor: '#F4F5F6',
    borderRadius: '4px',
    paddingTop: '0.625rem',
    paddingLeft: '0.625rem',
    marginTop: '0.625rem'
  },
  autocompleteTextField: {
    backgroundColor: '#F4F5F6',
    borderRadius: '4px',
    paddingTop: '0.225rem',
    paddingLeft: '0.625rem',
    marginTop: '0.625rem'
  },
  inputHelper: {
    margin: '.5rem .5rem 0'
  },
  checkBoxRoot: {
    color: `${theme.palette.primary.main} !important`
  },
  formErrorMessage: {
    color: theme.palette.error.main,
    marginTop: '.5rem'
  }
}))

const autoCompleteFilter = createFilterOptions()

const PostToVaultForm = ({
  reportRunId,
  batchRunId,
  tags,
  isTagsLoading,
  users,
  isUsersLoading,
  onSubmit,
  onCancel
}) => {
  const classes = useStyles()
  const [alert, setAlert] = useState({})
  const theme = useTheme()

  const tagOptions = useMemo(() => {
    return tags?.map((tag) => ({ title: tag.name, inputValue: tag.name }))
  }, [tags])

  const {
    handleSubmit,
    control,
    clearErrors,
    formState: { errors, isSubmitting, isValid },
    setValue,
    watch
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      reportRunId: reportRunId,
      tags: []
    }
  })

  const sendCommunication = watch('sendCommunication')
  const filterCommunicationToUsers = watch('filterCommunicationToUsers')

  const onSubmitHandler = useCallback(
    async ({ reportRunId, tags, sendCommunication, filterCommunicationToUsers }) => {
      clearErrors()

      const body = {
        tags: tags.map(row => row.inputValue),
        sendCommunication: !!sendCommunication,
        ...(reportRunId && !!sendCommunication && {
          filterCommunicationToUserIds: filterCommunicationToUsers.map(row => row.userId)
        })
      }

      try {
        batchRunId
          ? await postReportBatch(batchRunId, body)
          : await postReport(reportRunId, body)

        onSubmit({
          ...SUCCESS_ALERT,
          alertMessage: 'Item is posting to the the client\'s vault',
          alertSeverity: ALERT_SEVERITY.info
        })
      } catch (err) {
        setAlert({
          ...ERROR_ALERT,
          alertMessage: 'Issue posting document to the the client\'s vault',
          alertSeverity: ALERT_SEVERITY.info
        })
      }
    },
    [batchRunId, clearErrors, onSubmit]
  )

  const renderTagsControl = useCallback(
    ({ field: { onChange, ...field } }) => {
      const onChangeAutocomplete = (_, value) => onChange(value)

      if (isTagsLoading) {
        return (
          <>
            <Skeleton
              className={classes.itemTextField}
              height='2.3rem'
              width='100%'
            />
          </>
        )
      }
      if (!tags?.length) {
        return (
          <div style={{ marginTop: '.5rem' }}>
            <Text
              text='No tags exist'
              variant={TEXT_VARIANTS.body2}
            />
          </div>
        )
      }
      return (
        <Autocomplete
          multiple
          fullWidth
          onChange={onChangeAutocomplete}
          placeholder='Template'
          error={Boolean(errors.tags)}
          disabled={isTagsLoading}
          defaultValue={[]}
          className={classes.autocompleteTextField}
          freeSolo
          options={tagOptions}
          getOptionLabel={(option) => option.inputValue}
          renderOption={(option) => option.title}
          filterOptions={(options, params) => {
            const filtered = autoCompleteFilter(options, params)

            // Suggest the creation of a new value
            if (params.inputValue !== '') {
              filtered.push({
                inputValue: params.inputValue,
                title: `Click to create "${params.inputValue}" tag when posting to vault`
              })
            }

            return filtered
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder='Search tags'
            />
          )}
          {
            ...field
          }
        />
      )
    }
    ,
    [isTagsLoading, tags?.length, errors.tags, classes.autocompleteTextField, classes.itemTextField, tagOptions]
  )

  const renderSendCommunicationControl = useCallback(
    ({ field }) => {
      return (
        <FormControlLabel
          control={
            <Checkbox
              classes={{ root: classes.checkBoxRoot }}
              {...field}
            />
          } label='Send Email to Wealth Owners'
        />
      )
    },
    [classes.checkBoxRoot]
  )

  const renderfilterCommunicationToUsersControl = useCallback(
    ({ field }) => {
      const onChangeAutocomplete = (_, value) => field.onChange(value)

      if (isUsersLoading) {
        return (
          <>
            <Skeleton
              className={classes.itemTextField}
              height='2.3rem'
              width='100%'
            />
          </>
        )
      }
      if (!users?.length) {
        return (
          <div style={{ marginTop: '.5rem' }}>
            <Text
              text='No wealth owners exist for this client'
              variant={TEXT_VARIANTS.body2}
            />
          </div>
        )
      }

      return (
        <Autocomplete
          {...field}
          multiple
          fullWidth
          onChange={onChangeAutocomplete}
          placeholder='Template'
          error={Boolean(errors.filterCommunicationToUsers)}
          disabled={isUsersLoading}
          defaultValue={users}
          className={classes.autocompleteTextField}
          options={users}
          filterSelectedOptions
          getOptionSelected={(option, value) => option.userId === value.userId}
          getOptionLabel={(option) => option.firstName + ' ' + option.lastName}
          noOptionsText={field.value?.length ? 'No more wealth owners to select' : 'No wealth owners exist for this client'}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder='Search wealth owners'
            />
          )}
        />
      )
    }, [classes.autocompleteTextField, classes.itemTextField, errors.filterCommunicationToUsers, isUsersLoading, users]
  )

  const filterCommunicationToUserIdCount = useMemo(() => filterCommunicationToUsers?.length ?? 0, [filterCommunicationToUsers])

  useEffect(() => {
    if (users?.length) {
      setValue('filterCommunicationToUsers', users)
    }
  }, [users, setValue])

  return (
    <div className={classes.container}>
      <form onSubmit={handleSubmit(onSubmitHandler)}>
        <div className={classes.groupItem}>
          <div className={classes.labelWithInfo}>
            <Text text='Tags to assign' variant={TEXT_VARIANTS.h3} />
            {isTagsLoading && <Text variant={TEXT_VARIANTS.p}>Loading Tags...</Text>}
          </div>
          <Controller
            name='tags'
            render={renderTagsControl}
            control={control}
          />

          <div className={classes.inputHelper}>
            <Text
              text='Type to search the list of available tags or type in a new one to create a tag when posting to the vault.'
              variant={TEXT_VARIANTS.subtitle}
            />
          </div>

          {errors.tags && (
            <Text
              text={messageToUse(errors.tags)}
              color={theme.palette.error.main}
              className={classes.confirmError}
            />
          )}
        </div>

        <div className={classes.groupItem}>
          <div className={classes.labelWithInfo}>
            <Text text='Send Email?' variant={TEXT_VARIANTS.h3} />
          </div>
          <Controller
            name='sendCommunication'
            render={renderSendCommunicationControl}
            control={control}
          />
          {!!sendCommunication && !!batchRunId && (
            <div>
              <Text
                text='All wealth owners linked to the clients in this batch will receive an email when the report is posted to the document vault'
                variant={TEXT_VARIANTS.subtitle}
              />
            </div>
          )}
        </div>

        {!!sendCommunication && !batchRunId && (
          <div className={classes.groupItem}>
            <div className={classes.labelWithInfo}>
              <Text text='Wealth Owners to send to' variant={TEXT_VARIANTS.h3} />
              {
                isUsersLoading
                  ? <Text variant={TEXT_VARIANTS.p}>Loading Users...</Text>
                  : !filterCommunicationToUserIdCount
                    ? 'No wealth owners selected'
                    : `${pluralize(filterCommunicationToUserIdCount, `${filterCommunicationToUserIdCount} Wealth Owner`)} Selected`
              }
            </div>
            <Controller
              name='filterCommunicationToUsers'
              render={renderfilterCommunicationToUsersControl}
              control={control}
              rules={{ required: 'Must select at least one wealth owner' }}
            />
            {errors.filterCommunicationToUsers && (
              <Text variant={TEXT_VARIANTS.subtitle} className={classes.formErrorMessage}>
                {errors.filterCommunicationToUsers.message}
              </Text>
            )}
          </div>
        )}

        <div className={classes.buttonGroup}>
          <div className={classes.buttonGroupItem}>
            <RoundedButton
              secondary
              type='button'
              size={BUTTON_SIZES.medium}
              disabled={isSubmitting || isTagsLoading}
              fullWidth
              onClick={onCancel}
            >
              Cancel
            </RoundedButton>
          </div>
          <div className={classes.buttonGroupItem}>
            <RoundedButton
              primary
              type='submit'
              size={BUTTON_SIZES.medium}
              disabled={isSubmitting || !isValid || isTagsLoading}
              isLoading={isSubmitting}
              fullWidth
            >
              Move to Vault{sendCommunication && ' & Send Email'}
            </RoundedButton>
          </div>
        </div>
      </form>
      <SnackAlert alert={alert} />
    </div>
  )
}

PostToVaultForm.propTypes = {
  tags: PropTypes.array.isRequired,
  isTagsLoading: PropTypes.bool.isRequired,
  users: PropTypes.array,
  isUsersLoading: PropTypes.bool,
  reportRunId: PropTypes.number,
  batchRunId: PropTypes.number,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func
}

PostToVaultForm.defaultProps = {
  onSubmit: noop
}

export default PostToVaultForm
