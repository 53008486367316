import React, { createContext, useCallback, useContext, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { useOktaAuth } from '@okta/okta-react'
import { useAppContext, useInternalUserId } from '../../redux/slices/appContext'

function parseTopic (template, topic) {
  const templateParts = template.split('/')
  const topicParts = topic.split('/')
  const result = {}

  for (let i = 0; i < templateParts.length; i++) {
    if (templateParts[i].startsWith('{') && templateParts[i].endsWith('}')) {
      const key = templateParts[i].slice(1, -1)
      result[key] = topicParts[i]
    }
  }

  return result
}

function wildcardTemplate (template, wildcard = '*') {
  return template.replace(/{[^}]+}/g, wildcard)
}

function topicMatchesTemplate (template, topic, wildcard = '*') {
  const templateParts = template.split('/')
  const topicParts = topic.split('/')

  if (templateParts.length !== topicParts.length) return false

  for (let i = 0; i < templateParts.length; i++) {
    if (templateParts[i] !== wildcard && templateParts[i] !== topicParts[i]) {
      return false
    }
  }

  return true
}

function getMatchingCallbacks (subscriptionState, topic) {
  const matchingCallbacks = []

  Object.entries(subscriptionState).forEach(([template, callbacks]) => {
    if (topicMatchesTemplate(template, topic)) {
      matchingCallbacks.push(...callbacks)
    }
  })

  return matchingCallbacks
}

let handle = 0
function generateHandle () {
  return handle++
}

const websocketUrl = process.env.REACT_APP_WEBSOCKET_URL
export const WebsocketProviderContext = createContext()

export const WebsocketProvider = ({
  children
}) => {
  // Here we use a ref instead of state - useEffect can happen more than once
  const socketRef = useRef({
    socket: null,
    subscriptions: {}
  })
  const { authState } = useOktaAuth()
  const { firmId } = useAppContext()
  const internalUserId = useInternalUserId()

  // The main socket management stuff
  useEffect(() => {
    if (!socketRef.current.socket) {
      try {
        console.debug('creating new websocket', websocketUrl)
        const s = new WebSocket(websocketUrl, [`authorization.Bearer.${authState.accessToken.accessToken}`])

        s.onopen = () => {
          // Prime the socket, this is junk
          s.send(JSON.stringify('Hello'))
          console.log('websocket opened')
        }
        s.onclose = () => {
          console.log('websocket closed')
          socketRef.current.socket = null
        }
        s.onmessage = (event) => {
          try {
            console.log('onmessage', event)

            if (!event.data) {
              console.debug('empty websocket message')
              return
            }
            const message = JSON.parse(event.data)
            console.log('onmessage.topic', message?.topic)

            if (!message?.topic) return

            const registeredCallbacks = getMatchingCallbacks(socketRef.current.subscriptions, message.topic)
            if (!registeredCallbacks.length) {
              console.debug('no registered callbacks', message.topic)
              return
            }

            registeredCallbacks.forEach(({ topicPattern, callback }) => {
              try {
                callback(topicPattern, parseTopic(topicPattern, message.topic))
              } catch (err) {
                console.warn('error during ws subscription callback', err)
              }
            })

            socketRef.current.socket.send(JSON.stringify({
              receipt: message.topic,
              userId: internalUserId,
              firmId
            }))
          } catch (err) {
            console.error('error in onmessage', err)
          }
        }

        socketRef.current.socket = s
      } catch (err) {
        console.error('Failed to Connect Websocket', err)
      }
    }

    const socketManagerSocket = socketRef.current.socket
    return () => {
      if (!socketManagerSocket) return

      socketManagerSocket.close()
    }
  }, [authState.accessToken.accessToken, firmId, internalUserId])

  const subscribe = useCallback((topicPattern, callback) => {
    const wildcardPattern = wildcardTemplate(topicPattern)
    const handle = generateHandle()
    if (wildcardPattern in socketRef.current.subscriptions) {
      socketRef.current.subscriptions[wildcardPattern].push({ topicPattern, handle, callback })
    } else {
      socketRef.current.subscriptions[wildcardPattern] = [{ topicPattern, handle, callback }]
    }
    console.debug('registered subscription', topicPattern, wildcardPattern, handle)
    return handle
  }, [])

  const unsubscribe = useCallback((topicPattern, handle) => {
    const wildcardPattern = wildcardTemplate(topicPattern)
    if (wildcardPattern in socketRef.current.subscriptions) {
      const index = socketRef.current.subscriptions[wildcardPattern].findIndex(x => x.handle === handle)
      if (index === -1) {
        console.debug('could not find subscription', topicPattern, handle)
        return
      }
      socketRef.current.subscriptions[wildcardPattern].splice(index, 1)
      console.debug('removing subscription', topicPattern, handle)
    }
  }, [])

  return (
    <WebsocketProviderContext.Provider value={{ subscribe, unsubscribe }}>
      {children}
    </WebsocketProviderContext.Provider>
  )
}

WebsocketProvider.propTypes = {
  children: PropTypes.node
}

export const useWebsocketSubscription = (registerFunction, dependencies) => {
  const { subscribe, unsubscribe } = useContext(WebsocketProviderContext)

  useEffect(() => {
    return registerFunction(subscribe, unsubscribe)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscribe, unsubscribe, ...dependencies])
}
