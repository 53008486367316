import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import Section from './shared/Section'
import SectionTitle from './shared/SectionTitle'

const useStyles = makeStyles((theme) => ({
  noContent: {
    padding: `0 ${theme.layout.padding.medium}`,
    fontWeight: theme.typography.weights.thin,
    color: theme.palette.gray.darker,
    fontStyle: 'italic'
  },
  content: {
    padding: `0 ${theme.layout.padding.medium}`,
    fontWeight: theme.typography.weights.light
  }
}))

function FamilyRateSummary ({ familyRates }) {
  const classes = useStyles()
  const _fr = useMemo(() => {
    if (!familyRates) return null
    return Object.values(familyRates)
  }, [familyRates])

  if (!_fr) {
    return null
  }

  if (!_fr.length) {
    return (
      <Section>
        <SectionTitle title='Family Rates' />
        <div className='__no-content'>
          Not part of a Family Rate
        </div>
      </Section>
    )
  }

  if (_fr.length > 1) {
    return (
      <Section>
        <SectionTitle title='Family Rates' />
        <div className='__no-content'>
          Review Fee Schedule Assignment to see Family Rates
        </div>
      </Section>
    )
  }

  return (
    <Section>
      <SectionTitle title='Family Rates' />
      <div className={classes.content}>
        {_fr.at(0).map(fg => (
          <div key={fg.clientId}>{fg.longName}</div>
        ))}
      </div>
    </Section>
  )
}

FamilyRateSummary.propTypes = {
  familyRates: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.shape({
    feeScheduleId: PropTypes.number,
    clientId: PropTypes.number,
    longName: PropTypes.string
  })))
}

export default FamilyRateSummary
