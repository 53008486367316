import { useCallback } from 'react'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import {
  postNamedCommand,
  postNamedQuery
} from '../service'
import { useAppContext } from '../redux/slices/appContext'
import { QUERY_KEYS } from './queryKeys'

export const useSearchAllPeopleMultiple = (query, queryOptions = {}) => {
  const { userId } = useAppContext()
  const { mapper, enabled } = queryOptions
  return useQuery({
    queryKey: [QUERY_KEYS.searchAllPeopleMultiple, userId, query],
    queryFn: async () => {
      const { data } = await postNamedQuery('levels', 'search-all-people-multiple', query)
      return data
    },
    select: mapper,
    enabled
  })
}

export const useSearchAllPeople = (query, queryOptions = {}) => {
  const { userId } = useAppContext()
  const { mapper, enabled } = queryOptions
  return useQuery({
    queryKey: [QUERY_KEYS.searchAllPeople, userId, query],
    queryFn: async () => {
      const { data } = await postNamedQuery('levels', 'search-all-people', query)
      return data
    },
    select: mapper,
    enabled
  })
}

export const useSearchAssignedPeopleMultiple = (query, queryOptions = {}) => {
  const { userId } = useAppContext()
  const { mapper, enabled } = queryOptions
  return useQuery({
    queryKey: [QUERY_KEYS.searchAssignedPeopleMultiple, userId, query],
    queryFn: async () => {
      const { data } = await postNamedQuery('levels', 'search-assigned-people-multiple', query)
      return data
    },
    select: mapper,
    enabled
  })
}

export const useSearchAssignedPeople = (query, queryOptions = {}) => {
  const { userId } = useAppContext()
  const { mapper, enabled } = queryOptions
  return useQuery({
    queryKey: [QUERY_KEYS.searchAssignedPeople, userId, query],
    queryFn: async () => {
      const { data } = await postNamedQuery('levels', 'search-assigned-people', query)
      return data
    },
    select: mapper,
    enabled
  })
}

export const useInvalidateSearchAssignedPeopleMultiple = () => {
  const client = useQueryClient()
  const { userId } = useAppContext()
  return useCallback(() => {
    client.invalidateQueries({
      queryKey: [QUERY_KEYS.searchAssignedPeopleMultiple, userId],
      refetchType: 'all'
    }).catch(console.error)
  }, [client, userId])
}

export const useInvalidatePeopleSearch = () => {
  const queryClient = useQueryClient()
  const { userId } = useAppContext()
  return useCallback(() => {
    queryClient.invalidateQueries({
      queryKey: [QUERY_KEYS.searchAllPeopleMultiple, userId],
      refetchType: 'all'
    }).catch(console.error)
    queryClient.invalidateQueries({
      queryKey: [QUERY_KEYS.searchAllPeople, userId],
      refetchType: 'all'
    }).catch(console.error)
    queryClient.invalidateQueries({
      queryKey: [QUERY_KEYS.searchAssignedPeopleMultiple, userId],
      refetchType: 'all'
    }).catch(console.error)
    queryClient.invalidateQueries({
      queryKey: [QUERY_KEYS.searchAssignedPeople, userId],
      refetchType: 'all'
    }).catch(console.error)
  }, [queryClient, userId])
}

export const useListPersonTypes = (queryOptions = {}) => {
  const { userId } = useAppContext()
  const { enabled } = queryOptions
  return useQuery({
    queryKey: [QUERY_KEYS.listPersonTypes, userId],
    queryFn: async () => {
      const { data } = await postNamedQuery('levels', 'list-person-types', {})
      return data
    },
    enabled
  })
}

export const useCreatePersonMutation = () => {
  return useMutation({
    mutationFn: async (command) => {
      const { data } = await postNamedCommand('levels', 'create-person', command)

      return data
    }
  })
}
