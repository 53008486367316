import React from 'react'
import { useHistory } from 'react-router-dom'
import { ROLES } from '../../../../../policies/admin'
import { useCheckPolicy } from '../../../../../hooks'
import { ADMIN_ROUTES, USER_TYPE } from '../../../../../constants'
import EmptySection from '../../../../atoms/EmptySection'
import RoleNewCreateForm from './RoleNewCreateForm'

function WealthOwnerRoleNew () {
  const history = useHistory()
  const canCreate = useCheckPolicy(ROLES.editAdvisorRoles)

  if (!canCreate) {
    return (
      <EmptySection
        title='Access Denied'
        description='You do not have sufficient permissions to access this resource'
      />
    )
  }

  return (
    <>
      <RoleNewCreateForm
        title='Create a new experience'
        displayName='Experience'
        onSave={(roleId) => history.replace(`${ADMIN_ROUTES.WEALTH_OWNERS}/roles/${roleId}`)}
        onCancel={(roleId) => history.replace(`${ADMIN_ROUTES.CLIENTS}/?tab=experience`)}
        userTypeId={USER_TYPE.WEALTH_OWNER}
      />
    </>
  )
}

export default WealthOwnerRoleNew
