import { useCallback } from 'react'
import {
  useDownloadExportMutation,
  useMonitorExportStatusMutation,
  useStartExportMutation
} from '../../../../api/exports'

export const useDownloadExport = (setExportState, defaultState) => {
  const { mutateAsync: startExport } = useStartExportMutation()
  const { mutateAsync: monitorExport } = useMonitorExportStatusMutation()
  const { mutateAsync: download } = useDownloadExportMutation()
  const onConfirm = useCallback(async (metadata) => {
    try {
      // Tell listeners we are processing
      setExportState(prev => ({
        ...prev,
        processing: true
      }))
      const response = await startExport(metadata)

      // Close the dialog
      setExportState(prev => ({
        ...prev,
        open: false,
        processing: response
      }))

      // Check to see if the export is done
      let finishedOrErrored = false
      let statusResponse = null
      while (!finishedOrErrored) {
        statusResponse = await monitorExport(response.exportId)
        finishedOrErrored = statusResponse.status !== 'PENDING'
        await new Promise(resolve => setTimeout(resolve, 2000))
      }

      // It could have errored
      if (statusResponse.status === 'ERROR') {
        setExportState(prev => ({
          ...prev,
          processing: false,
          error: 'There was a producing the export'
        }))
        return
      }

      // Download that thing
      console.log('the file name is', statusResponse.fileName)
      await download(statusResponse.url, statusResponse.fileName)
      setExportState(defaultState)
    } catch (err) {
      console.error('Error processing export', err)
      setExportState(prev => ({
        ...prev,
        error: err,
        processing: false
      }))
    }
  }, [setExportState, startExport, monitorExport, download, defaultState])

  return onConfirm
}
