import React from 'react'
import { makeStyles } from '@material-ui/core'
import { useCurrentTab } from '../../../organisms/AdvisorView/components/AdvisorViewTabs'
import { ADMIN_ROUTES } from '../../../../constants'
import { useCheckPolicy } from '../../../../hooks'
import SydLinkButton from '../../../commonDesign/LinkButton'

const useStyles = makeStyles((theme) => ({
  actions: {
    marginLeft: 'auto',
    display: 'flex',
    gap: '0.5rem'
  }
}))
function HeaderActions () {
  const classes = useStyles()
  const tabValue = useCurrentTab()
  const canEdit = useCheckPolicy('admin_models_edit')

  if (tabValue === 'target-models' && canEdit) {
    return (
      <div className={classes.actions}>
        <SydLinkButton
          size='sm'
          variant='outline'
          icon='add'
          to={ADMIN_ROUTES.NEW_TARGET_MODEL}
        >
          Add Target Model
        </SydLinkButton>
      </div>
    )
  }

  if (tabValue === 'component-models' && canEdit) {
    return (
      <div className={classes.actions}>
        <SydLinkButton
          size='sm'
          variant='outline'
          icon='add'
          to={ADMIN_ROUTES.NEW_COMPONENT_MODEL}
        >
          Add Component Model
        </SydLinkButton>
      </div>
    )
  }

  return null
}

export default HeaderActions
