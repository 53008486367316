import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  viewLink: {
    textDecoration: 'none !important',
    display: 'flex'
  }
}))

function ViewLink ({ to, children }) {
  const classes = useStyles()

  return (
    <Link className={classes.viewLink} to={to}>{children}</Link>
  )
}

ViewLink.propTypes = {
  to: PropTypes.string,
  children: PropTypes.node
}

export default ViewLink
