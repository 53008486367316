import PropTypes from 'prop-types'
import TabGroup from './TabGroup'
import TabButtons from './TabButtons'
import Tab from './Tab'
import TabList from './TabList'
import TabLinks from './TabLinks'
import { useAdvisorViewTabGroup } from './advisorViewTabGroupContext'

export const useCurrentTab = () => {
  const tabs = useAdvisorViewTabGroup()
  return tabs.selectedValue
}

const AdvisorViewTabs = ({ children }) => {
  return children
}

AdvisorViewTabs.propTypes = {
  children: PropTypes.node
}

AdvisorViewTabs.Group = TabGroup
AdvisorViewTabs.Buttons = TabButtons
AdvisorViewTabs.List = TabList
AdvisorViewTabs.Tab = Tab
AdvisorViewTabs.Links = TabLinks

export default AdvisorViewTabs
