import React, { useCallback, useMemo, useRef } from 'react'
import PropTypes from 'prop-types'
import {
  CellMeasurer,
  CellMeasurerCache,
  ColumnSizer,
  Grid,
  InfiniteLoader,
  WindowScroller
} from 'react-virtualized'
import { splitArrayInChunks } from '../../../../utils'

const BlocksInfiniteGrid = ({
  // container props
  width,
  containerRef,
  columnCount,
  minColumnWidth,
  // grid properties
  data,
  header,
  rowHeight,
  rowCount,
  isLoading,
  fetchNextPage,
  useCellMeasurer,
  cellComponent: CellComponent
}) => {
  const gridRef = useRef(null)
  const onRowsRenderedRef = useRef(null)
  const realColumnWidth = useRef(null)

  const dataGrid = useMemo(() => {
    return splitArrayInChunks(data, columnCount)
  }, [data, columnCount])

  const isRowLoaded = useCallback(
    ({ index }) => {
      return !!dataGrid?.[index]
    },
    [dataGrid]
  )

  const loadMoreRows = useCallback(
    () => {
      if (isLoading) return
      fetchNextPage()
    },
    [fetchNextPage, isLoading]
  )

  const onSectionRendered = useCallback(({ rowStartIndex, rowStopIndex }) => {
    if (onRowsRenderedRef.current) {
      onRowsRenderedRef.current({
        startIndex: rowStartIndex,
        stopIndex: rowStopIndex
      })
    }
  }, [])

  const cacheRef = useRef(
    new CellMeasurerCache({
      defaultWidth: minColumnWidth,
      minWidth: 200,
      fixedWidth: true,
      defaultHeight: rowHeight
    })
  )

  const cellRenderer = useCallback(
    ({ key, columnIndex, parent, rowIndex, style }) => {
      const cellItem = dataGrid?.[rowIndex]?.[columnIndex]

      if (!cellItem) return null

      if (useCellMeasurer) {
        <CellMeasurer
          key={key}
          cache={cacheRef.current}
          parent={parent}
          columnIndex={columnIndex}
          rowIndex={rowIndex}
        >
          <div style={style}>
            <CellComponent {...cellItem} />
          </div>
        </CellMeasurer>
      }

      return (
        <div key={`${key}-${cellItem.id}`} style={style}>
          <CellComponent {...cellItem} />
        </div>
      )
    },
    [dataGrid, useCellMeasurer]
  )

  return (
    <InfiniteLoader
      isRowLoaded={isRowLoaded}
      loadMoreRows={loadMoreRows}
      rowCount={rowCount}
      threshold={1}
    >
      {({ onRowsRendered, registerChild }) => {
        onRowsRenderedRef.current = onRowsRendered

        return (
          <>
            {header}
            <WindowScroller scrollElement={containerRef}>
              {({ height, scrollTop, onChildScroll }) => {
                return (
                  <ColumnSizer
                    columnMaxWidth={width / columnCount}
                    columnMinWidth={minColumnWidth}
                    columnCount={columnCount}
                    width={width}
                  >
                    {({ adjustedWidth, getColumnWidth }) => {
                      realColumnWidth.current = getColumnWidth()
                      return (
                        <Grid
                          ref={(grid) => {
                            gridRef.current = grid
                            registerChild(grid)
                          }}
                          autoHeight
                          height={height || 0}
                          width={adjustedWidth}
                          scrollTop={scrollTop}
                          columnWidth={realColumnWidth.current}
                          columnCount={columnCount}
                          onScroll={onChildScroll}
                          rowHeight={rowHeight}
                          cellRenderer={cellRenderer}
                          rowCount={Math.ceil(rowCount / columnCount)}
                          onSectionRendered={onSectionRendered}
                          {...(useCellMeasurer
                            ? { rowHeight: cacheRef.current.rowHeight }
                            : {})}
                        />
                      )
                    }}
                  </ColumnSizer>
                )
              }}
            </WindowScroller>
          </>
        )
      }}
    </InfiniteLoader>
  )
}

BlocksInfiniteGrid.propTypes = {
  /* container width */
  width: PropTypes.number,
  /* plain list of records */
  data: PropTypes.array,
  containerRef: PropTypes.node,
  /* if true uses cell measurer to automatically calculate cell
  widths and cache them (bad performance in huge lists) */
  useCellMeasurer: PropTypes.bool,
  header: PropTypes.node,
  rowCount: PropTypes.number,
  isRowLoaded: PropTypes.bool,
  isLoading: PropTypes.bool,
  rowHeight: PropTypes.number,
  fetchNextPage: PropTypes.func,
  minColumnWidth: PropTypes.number,
  cellComponent: PropTypes.node,
  columnCount: PropTypes.number
}

export default BlocksInfiniteGrid
