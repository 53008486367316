import React from 'react'
import PropTypes from 'prop-types'
import {
  Switch,
  withStyles
} from '@material-ui/core'

const StyledSwitch = withStyles((theme) => ({
  root: {
    width: 50,
    height: 26,
    padding: 0
  },
  switchBase: {
    padding: 1,
    '&$checked': {
      transform: 'translateX(16px)',
      color: theme.palette.white,
      '& + $track': {
        backgroundColor: theme.palette.green.sheen,
        opacity: 1,
        border: 'none'
      }
    },
    '&$focusVisible $thumb': {
      color: theme.palette.green.sheen,
      border: '6px solid #fff'
    }
  },
  thumb: {
    width: 24,
    height: 24
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border'])
  },
  checked: {
    backgroundColor: theme.palette.green.sheen,
    left: 8
  }
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked
      }}
      disableRipple
      {...props}
    />
  )
})

function CustomSwitch ({
  onChange,
  checked,
  disabled
}, ref) {
  return (
    <StyledSwitch
      onChange={onChange}
      checked={checked}
      disabled={disabled}
      ref={ref}
    />
  )
}

const SwitchInput = React.forwardRef(CustomSwitch)

CustomSwitch.propTypes = {
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
  onChange: PropTypes.func
}

export default SwitchInput
