import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@material-ui/core'
import SectionHeader from '../../shared/SectionHeader'
import SydLabel from '../../../../commonDesign/SydLabel'
import SydInput from '../../../../commonDesign/SydInput'
import { AccountFormSection } from '../AccountFormProvider'
import AssignManagerDialog from './ManagerInput/AssignManagerDialog'
import TaxStatusInput from './TaxStatusInput'
import ManagerInput from './ManagerInput'
import DisplayNameInput from './DisplayNameInput'

function AccountInformation ({ account }) {
  const assignManagerRef = useRef()

  return (
    <AccountFormSection section='account_information'>
      <SectionHeader text='Account Information' />
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <SydLabel label='Name'>
            <SydInput disabled defaultValue={account.longName} />
          </SydLabel>
        </Grid>
        <Grid item xs={12} md={6}>
          <DisplayNameInput />
        </Grid>
        <Grid item xs={12} md={6}>
          <TaxStatusInput />
        </Grid>
        <Grid item xs={12} md={6}>
          <ManagerInput />
        </Grid>
      </Grid>
      <AssignManagerDialog ref={assignManagerRef} />
    </AccountFormSection>
  )
}

AccountInformation.propTypes = {
  account: PropTypes.shape({
    longName: PropTypes.string,
    displayName: PropTypes.string,
    taxStatusId: PropTypes.number
  })
}

export default AccountInformation
