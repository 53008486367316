import { useCallback, useEffect, useMemo, useState } from 'react'
import isEmpty from 'lodash/isEmpty'
import { orderBy } from 'lodash'
import { useWealthJourneyQuery } from '../../../../api/wealthJourney'

const queryKey = 'task-blocks'

const textSearchFields = ['shortName', 'longName']

const mapSearch = (searchText) =>
  searchText
    ? textSearchFields.reduce((prev, field) => {
      prev[field] = [{ op: 'contains', value: searchText }]
      return prev
    }, {})
    : {}

const useClientTasks = ({ queryText, pageSize, startDate, endDate, filters, includes }) => {
  const [tasks, setTasks] = useState({})
  const [pagination, setPagination] = useState({ take: pageSize, skip: 0 })

  useEffect(() => {
    setTasks({})
    setPagination(prevState => ({ ...prevState, skip: 0 }))
  }, [startDate, endDate])

  const { query, queryOptions } = useMemo(() => {
    const { skip, take } = pagination
    return {
      query: {
        skip,
        take,
        ...(filters ? { filters } : {}),
        includes: {
          taskCount: {
            ...includes
          },
          tasks: {
            ...includes,
            dueDate: [
              { op: 'gte', value: startDate },
              { op: 'lte', value: endDate }
            ]
          }
        },
        textSearch: mapSearch(queryText),
        sort: [{ field: 'taskDueDateCount', dir: 'desc' }]
      },
      queryOptions: {
        queryName: 'searchClientTasks',
        mapper: ({ data }) => {
          if (isEmpty(data)) {
            return null
          }
          return {
            [`${skip}-${take}`]: orderBy(
              data.filter((client) => client.tasks.length),
              ['tasks.length'],
              ['desc']
            )
          }
        }
      }
    }
  }, [pagination, startDate, includes, endDate, queryText, filters])

  const { data, isLoading } = useWealthJourneyQuery(
    queryKey,
    query,
    queryOptions
  )

  useEffect(() => {
    if (!isEmpty(data) && !isLoading) {
      setTasks((prevState) => ({
        ...prevState,
        ...data
      }))
    }
  }, [data, isLoading])

  const fetchNextPage = useCallback(() => {
    if (!isEmpty(tasks) && !isEmpty(data)) {
      setPagination((prevState) => ({
        ...prevState,
        skip: prevState.skip + prevState.take
      }))
    }
  }, [data, tasks])

  return {
    data: Object.values(tasks).reduce((acc, data) => [...acc, ...data], []),
    isLoading,
    fetchNextPage
  }
}

export default useClientTasks
