import React, { useCallback, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Divider, makeStyles } from '@material-ui/core'
import { useAppContext } from '../../../redux/slices/appContext'
import RelativeDateSelect, { defaultOptions, useRelativeDateRange } from '../../molecules/RelativeDateSelect'
import { useComponentsOfChangeQuery, useNormalizeDates } from '../../../api/coreData'
import { useWidgetContext } from '../../molecules/WidgetWrapper'
import Metric from './Metric'

const useStyles = makeStyles(() => ({
  clientComponentMetrics: {
    display: 'inline-block',
    '& .__metrics': {
      position: 'relative',
      display: 'flex',
      flexDirection: 'row'
    },
    '& .__divider': {
      margin: '0 1rem'
    },
    '& .__metrics-group': {
      alignItems: 'flex-end'
    },
    '& .__metrics-group-column': {
      display: 'flex',
      flexDirection: 'column'
    }
  }
}))

const useComponentsOfChange = ({ metrics, defaultFilter, defaultDateRange, components, dateRangeOptions }) => {
  const { clientId, availableDates, loadingAvailableDates } = useAppContext()
  const [relativeDateRange, setRelativeDateRange] = useState(defaultDateRange)

  const onDateRangeSelected = useCallback((key) => {
    setRelativeDateRange(key)
  }, [setRelativeDateRange])

  const {
    options,
    dateRange,
    value: dateRangeKey,
    valueLabel: dateRangeLabel
  } = useRelativeDateRange(
    relativeDateRange,
    availableDates,
    dateRangeOptions
  )

  const normalizeDatesQuery = useMemo(() => {
    return {
      dateRanges: {
        [dateRangeKey]: {
          key: relativeDateRange,
          startDate: dateRange.startDate,
          endDate: dateRange.endDate
        }
      }
    }
  }, [dateRangeKey, dateRange, relativeDateRange])

  const {
    data: normalizedDateRanges,
    isLoading: isLoadingNormalizedDates
  } = useNormalizeDates(normalizeDatesQuery)

  const query = useMemo(() => {
    if (!normalizedDateRanges || loadingAvailableDates || isLoadingNormalizedDates) return null

    return {
      levelFilters: {
        levelTypes: ['client'],
        clientIds: [clientId],
        ...(defaultFilter || {})
      },
      dateRange: normalizedDateRanges[dateRangeKey],
      components
    }
  }, [
    clientId,
    components,
    dateRangeKey,
    defaultFilter,
    normalizedDateRanges,
    loadingAvailableDates,
    isLoadingNormalizedDates
  ])

  const { data, isLoading } = useComponentsOfChangeQuery(query, {
    enabled: !!query,
    keepPreviousData: true
  })

  const mappedMetrics = useMemo(() => metrics.map(metric => {
    const component = (data || []).find(x => x.type === metric.type)
    if (!component) return null
    return {
      key: metric.type,
      name: component.name,
      value: component.default,
      ...metric
    }
  }).filter(x => !!x), [data, metrics])

  return {
    data: mappedMetrics ?? [],
    isLoading,
    dateRangeKey,
    dateRangeLabel,
    options,
    onDateRangeSelected,
    availableDates
  }
}

const ClientComponentMetrics = ({
  components,
  metrics,
  defaultFilter: _defaultFilter,
  defaultDateRange,
  dateRangeOptions,
  canChangeDateRange,
  showDateRange,
  showSeparatorLine
}) => {
  const { subscribedFilters } = useWidgetContext()

  const defaultFilter = useMemo(() => {
    return Object.assign({}, _defaultFilter, subscribedFilters?.levelFilters)
  }, [_defaultFilter, subscribedFilters?.levelFilters])

  const {
    data,
    isLoading,
    availableDates,
    onDateRangeSelected,
    options,
    dateRangeKey,
    dateRangeLabel
  } = useComponentsOfChange({
    metrics,
    defaultFilter,
    defaultDateRange,
    components,
    dateRangeOptions
  })

  const classes = useStyles()

  const { leadingMetric, metricsData } = useMemo(() => {
    const [leadingMetric, ...metrics] = data
    return { leadingMetric, metricsData: metrics }
  }, [data])

  if (isLoading) {
    return <div>Loading</div>
  }

  return (
    <div className={classes.clientComponentMetrics}>
      <div className='__metrics'>
        <Metric {...leadingMetric} />
        {showSeparatorLine && (
          <Divider
            orientation='vertical'
            className='__divider'
            flexItem
            mx={4}
          />
        )}
        <div className='__metrics-group-column'>
          {showDateRange && (
            <div className={classes.dateSelect}>
              {canChangeDateRange ? (
                <RelativeDateSelect
                  options={options}
                  defaultValue={defaultDateRange}
                  availableDates={availableDates}
                  selectedValue={dateRangeKey}
                  onDateRangeSelected={onDateRangeSelected}
                  disabled={!canChangeDateRange}
                />
              ) : (
                <span>{dateRangeLabel}</span>
              )}
            </div>
          )}
          <div className='__metrics __metrics-group'>
            {metricsData.map((metric) => (
              <Metric key={metric.key} {...metric} />
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

ClientComponentMetrics.propTypes = {
  components: PropTypes.object,
  defaultFilter: PropTypes.object,
  metrics: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      format: PropTypes.string,
      featured: PropTypes.bool
    })
  ),
  defaultDateRange: PropTypes.string,
  dateRangeOptions: PropTypes.array,
  canChangeDateRange: PropTypes.bool,
  showDateRange: PropTypes.bool,
  showSeparatorLine: PropTypes.bool
}

ClientComponentMetrics.defaultProps = {
  components: {
    beginningValue: false,
    endingValue: true,
    netGain: true,
    return: { returnType: 'cumulative' }
  },
  defaultFilter: {},
  defaultDateRange: 'QTD',
  metrics: [
    {
      type: 'endingValue',
      format: 'human',
      alternateFormat: 'marketValue',
      featured: true
    },
    { type: 'return', format: 'returns' },
    { type: 'netGain', format: 'human' }
  ],
  canChangeDateRange: true,
  showDateRange: true,
  showSeparatorLine: true,
  dateRangeOptions: defaultOptions
}

export default ClientComponentMetrics
