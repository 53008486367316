import React, { forwardRef, useImperativeHandle, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { Grid, makeStyles } from '@material-ui/core'
import SydSelect from '../../../../../commonDesign/SydSelect'

const useStyles = makeStyles((theme) => ({
  formLayout: {
    maxWidth: '800px'
  },
  wrap: {
  },
  experience: {
    width: '100%'
  }
}))

const useFormValues = (user) => {
  const defaultValues = useMemo(() => ({
    userId: user.userId || '',
    roleId: user.role?.roleId || ''
  }), [user])
  return useForm({
    mode: 'onChange',
    reValidateMode: 'onSubmit',
    criteriaMode: 'all',
    defaultValues
  })
}

const AssignExperienceForm = forwardRef(function AssignExperienceForm ({ user, roleOptions, editing }, ref) {
  const classes = useStyles({ editing })
  const { handleSubmit, reset, ...formValues } = useFormValues(user)
  useImperativeHandle(ref, () => {
    return {
      handleSubmit,
      reset
    }
  }, [handleSubmit, reset])

  return (
    <FormProvider handleSubmit={handleSubmit} {...formValues}>
      <div className={classes.wrap}>
        <Grid className={classes.formLayout} container spacing={3}>
          <Grid item xs={12}>
            <div>
              <Controller
                name='roleId'
                render={({ field, fieldState }) => (
                  <SydSelect
                    placeholder={editing ? 'Select a Role' : 'No Role Assigned'}
                    options={roleOptions}
                    onChange={field.onChange}
                    value={field.value}
                    disabled={!editing}
                    error={(fieldState.isDirty || fieldState.isTouched) && fieldState.error}
                  />
                )}
              />
            </div>
          </Grid>
        </Grid>
      </div>
    </FormProvider>
  )
})

AssignExperienceForm.propTypes = {
  user: PropTypes.shape({
    userId: PropTypes.number,
    role: PropTypes.shape({
      roleId: PropTypes.number
    })
  }),
  roleOptions: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.number
  })),
  editing: PropTypes.bool
}

AssignExperienceForm.defaultProps = {
  editing: false
}

export default AssignExperienceForm
