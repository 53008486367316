import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useTheme } from '@material-ui/styles'
import { Box } from '@material-ui/core'
import Text from '../../../atoms/Text'
import NumberFormat from '../../../atoms/NumberFormat'
import { numeralByCase, tableNumberFormatter } from '../../../../utils'
import Skeleton from '../../../atoms/Skeleton'

const NumberTitle = ({
  title,
  titleProps,
  subtitle,
  isLoading,
  subtitleProps,
  containerProps,
  isPositiveNumber,
  includePrefixSymbol,
  precision
}) => {
  const theme = useTheme()

  const [titleFormatted, titleColor] = useMemo(() => {
    let prefixSymbol = ''
    let titleColor = theme.palette.black

    if (!isPositiveNumber) {
      titleColor = theme.palette.error.A500
    }

    if (includePrefixSymbol && title) {
      titleColor = isPositiveNumber
        ? theme.palette.success.A500
        : theme.palette.error.A500

      prefixSymbol = isPositiveNumber ? '+' : '-'
    }
    const titleFormatted = `${prefixSymbol}${tableNumberFormatter(title, precision)}`

    return [titleFormatted, titleColor]
  }, [theme, title, isPositiveNumber, includePrefixSymbol, precision])

  return (
    <Box display='flex' flexDirection='column' {...containerProps}>
      {(titleFormatted || isLoading) ? (
        <NumberFormat title={numeralByCase(title, precision)}>
          <Text
            text={titleFormatted}
            color={titleColor}
            lineHeight='1'
            variant='body2'
            customFontWeight='bold'
            customFontSize='1.625rem'
            {...titleProps}
          />
        </NumberFormat>
      ) : (
        <Skeleton height='1.625rem' />
      )}
      {subtitle && (
        <Text
          text={subtitle}
          variant='body2'
          customFontWeight='normal'
          customFontSize='1rem'
          {...subtitleProps}
        />
      )}
    </Box>
  )
}

NumberTitle.propTypes = {
  includePrefixSymbol: PropTypes.bool,
  subtitle: PropTypes.string,
  isPositiveNumber: PropTypes.bool,
  title: PropTypes.string,
  isLoading: PropTypes.bool,
  titleProps: PropTypes.object,
  subtitleProps: PropTypes.object,
  containerProps: PropTypes.object,
  precision: PropTypes.string
}

NumberTitle.defaultProps = {
  includePrefixSymbol: false,
  subtitle: undefined,
  isPositiveNumber: true,
  isLoading: false,
  title: undefined,
  titleProps: {},
  subtitleProps: {},
  containerProps: {},
  precision: undefined
}

export default NumberTitle
