import React, { useMemo } from 'react'
import noop from 'lodash/noop'
import isEmpty from 'lodash/isEmpty'
import PropTypes from 'prop-types'
import 'react-multi-carousel/lib/styles.css'
import { useGroupTypeSearch } from '../../../api/groups'
import CarouselSlider from '../../molecules/CarouselSlider'
import GroupTagFilter from '../../molecules/GroupTagFilters/GroupTagFilter'

const CLIENTS_MEMBER_LEVEL_ID = 201

const mapGroupTypes = ({ data: groupTypes }) => {
  return groupTypes
    .filter(({ groups }) => !isEmpty(groups))
    .map(({ groupTypeId, longName: groupTypeLongName, groups }) => ({
      label: groupTypeLongName,
      value: groupTypeId,
      groups: groups.map(({ groupId, longName }) => ({
        label: longName,
        value: groupId,
        payload: { groupTypeId, groupName: groupTypeLongName }
      }))
    }))
}

const getQuery = () => {
  return {
    query: {
      skip: 0,
      take: 100,
      includes: {
        groups: true,
        groupMembers: true,
        groupMemberTypes: true
      },
      filters: {
        memberLevelTypeIds: [CLIENTS_MEMBER_LEVEL_ID]
      }
    },
    queryOptions: { mapper: mapGroupTypes }
  }
}

const ClientTags = ({ disabled, onChange }) => {
  const { query, queryOptions } = useMemo(() => getQuery(), [])
  const { data: groupTypes } = useGroupTypeSearch(query, queryOptions)

  return (
    <CarouselSlider>
      {(groupTypes || []).map(({ groups, ...option }) => {
        return (
          <GroupTagFilter
            key={option.value}
            disabled={disabled}
            label={option.label}
            groupTypeId={option.value}
            groups={groups}
            onChange={onChange}
          />
        )
      })}
    </CarouselSlider>
  )
}

ClientTags.propTypes = {
  disabled: PropTypes.bool,
  onChange: PropTypes.func
}

ClientTags.defaultProps = {
  disabled: false,
  onChange: noop
}

export default ClientTags
