import { useTheme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

export const useThemedStyles = (styleFactory) => {
  const theme = useTheme()
  const styles = makeStyles(() => styleFactory(theme))()

  return [styles, theme]
}

export const makeThemedStyles = (styleFactory) => () => useThemedStyles(styleFactory)
