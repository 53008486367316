import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import numeral from 'numeral'
import dayjs from 'dayjs'
import Text from '../../../atoms/Text'
import { TEXT_VARIANTS } from '../../../../constants'
import Tag from '../../../atoms/Tag'
import { useDynamicPlanning } from '../DynamicPlanningContext'

const useStyles = makeStyles((theme) => ({
  selectOption: {
    display: 'flex',
    width: '100%',
    alignItems: 'center'
  },
  selectOptionContent: {
    flex: '1 0 0'
  },
  selectOptionSubtitle: {
    display: 'block'
  },
  selectOptionTag: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    width: '100px',
    gap: '.25rem'
  }
}))

const PlanMenuItem = ({ plan, showLastModifiedDate, lastModifiedPrefix }) => {
  const classes = useStyles()
  const { plans, planStatusMap, isSandbox } = useDynamicPlanning()

  const editingPlan = useMemo(() => {
    return plans.find(row => row.originPlanId === plan.planId && isSandbox(row))
  }, [plans, plan.planId, isSandbox])

  const planAssumptionText = useMemo(() => {
    const { assumptions } = plan
    const returnArray = []

    if (assumptions.portfolioGrowthModel) {
      returnArray.push(
        numeral(assumptions.portfolioGrowthModel.growthFactor).format('0[.]0%') + ' Portfolio Growth'
      )
    }
    if (assumptions.inflationModel) {
      returnArray.push(
        numeral(assumptions.inflationModel.growthFactor).format('0[.]0%') + ' Inflation'
      )
    }
    return returnArray.join(' / ')
  }, [plan])

  const planModifiedText = useMemo(() => {
    if (plan.modifiedDate) {
      return lastModifiedPrefix + dayjs(plan.modifiedDate).format('YYYY-MM-DD')
    }
    return ''
  }, [plan?.modifiedDate, lastModifiedPrefix])

  return (
    <div className={classes.selectOption}>
      <div className={classes.selectOptionContent}>
        <Text variant={TEXT_VARIANTS.h3}>
          {plan.shortName}
        </Text>
        <Text variant={TEXT_VARIANTS.caption} className={classes.selectOptionSubtitle}>
          {planAssumptionText}
        </Text>
        {showLastModifiedDate && planModifiedText && (
          <Text variant={TEXT_VARIANTS.caption} className={classes.selectOptionSubtitle}>
            {planModifiedText}
          </Text>
        )}
      </div>
      <div className={classes.selectOptionTag}>
        {planStatusMap.ACTIVE.planStatusId === plan.planStatusId && (
          <Tag backgroundColor={plan.planStatusColor} color='#fff' label={plan.planStatusDisplayName} />
        )}
        {editingPlan && (
          <div style={{ opacity: 0.5 }}>
            <Tag
              backgroundColor={planStatusMap.SANDBOX.planStatusColor} color='#fff' label='Has Edits'
            />
          </div>
        )}
      </div>
    </div>
  )
}

PlanMenuItem.propTypes = {
  plan: PropTypes.object,
  showLastModifiedDate: PropTypes.bool,
  lastModifiedPrefix: PropTypes.string
}

export default PlanMenuItem
