import React from 'react'
import { useTheme } from '@material-ui/core'
import { Controller } from 'react-hook-form'
import PropTypes from 'prop-types'
import Text from '../../../../../atoms/Text'
import { messageToUse } from '../../../common'
import AutocompleteInput from './AutocompleteInput'

const FormAutocompleteInput = ({
  name,
  placeholder,
  control,
  errors,
  options,
  isLoading,
  className,
  classes,
  onInputChange,
  ...props
}) => {
  const theme = useTheme()
  return (
    <>
      <Controller
        name={name}
        control={control}
        rules={{ required: 'This field is required' }}
        render={({ field: { onChange, ...field } }) => {
          const onChangeAutocomplete = (_, value) => {
            onChange(value)
          }
          return (
            <AutocompleteInput
              options={options}
              loading={isLoading}
              placeholder={placeholder}
              onChange={onChangeAutocomplete}
              onInputChange={onInputChange}
              error={Boolean(errors?.[name])}
              classes={classes}
              className={className}
              {...props}
              {...field}
            />
          )
        }}
      />
      {errors[name] && (
        <>
          <Text
            text={messageToUse(errors[name])}
            color={theme.palette.error.main}
          />
        </>
      )}
    </>
  )
}

FormAutocompleteInput.propTypes = {
  name: PropTypes.string,
  control: PropTypes.object,
  errors: PropTypes.object,
  options: PropTypes.array,
  isLoading: PropTypes.bool,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  classes: PropTypes.object,
  onInputChange: PropTypes.func
}

export default FormAutocompleteInput
