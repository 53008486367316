import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import AttachmentButton from './AttachmentButton'
import CommentButton from './CommentButton'
import EditButton from './EditButton'
import StatusReportLink from './StatusReportLink'

const useStyles = makeStyles((theme) => ({
  actions: {
    minWidth: '20px',
    display: 'flex',
    flexDirection: 'row',
    gap: '10px',
    justifyContent: 'flex-end'
  }
}))

function StatusReportItemActions ({ item, parent }) {
  const classes = useStyles()
  const itemsToShow = useMemo(() => {
    return {
      link: !!item.linkReference,
      attachment: !!item.attachmentCount,
      comment: !!item.threadKey,
      edit: !item.attachmentCount && !item.threadKey
    }
  }, [item])

  return (
    <div className={classes.actions}>
      {itemsToShow.link ? (
        <StatusReportLink to={item.linkReference} />
      ) : null}
      {itemsToShow.attachment ? (
        <AttachmentButton
          item={item}
          parent={parent}
        />
      ) : null}
      {itemsToShow.comment ? (
        <CommentButton
          item={item}
          parent={parent}
        />
      ) : null}
      {itemsToShow.edit ? (
        <EditButton
          title={item.displayName}
          item={item}
          parent={parent}
        />
      ) : null}
    </div>
  )
}

StatusReportItemActions.propTypes = {
  item: PropTypes.shape({
    threadKey: PropTypes.string,
    attachmentCount: PropTypes.number,
    displayName: PropTypes.string,
    statusTemplateItemId: PropTypes.number,
    linkReference: PropTypes.string
  }),
  parent: PropTypes.any
}

export default StatusReportItemActions
