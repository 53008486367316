import React from 'react'
import { useParams } from 'react-router-dom'
import { makeStyles } from '@material-ui/core'
import { usePolicy } from '../../../../../hooks/usePolicy'
import EmptySection from '../../../../atoms/EmptySection'
import { useComponentModelById } from '../../../../../api/rebalancer'
import { ComponentModelProvider } from './ComponentModelProvider'
import ComponentModelTabs from './ComponentModelTabs'
import ComponentModelHeader from './Header'

const useStyles = makeStyles({
  page: {
    margin: '1rem'
  }
})

const useComponentModelId = () => {
  const { componentModelId } = useParams()
  return isNaN(+componentModelId) ? [-1] : [+componentModelId]
}

function ComponentModelDetails () {
  const classes = useStyles()
  const [targetModelId] = useComponentModelId()
  const canView = usePolicy('admin_models_view')
  const { data: componentModel, isLoading, isFetching, error } = useComponentModelById(targetModelId, { includeNames: true })

  if (!canView) {
    return (
      <EmptySection
        title='Access Denied'
        description='You do not have sufficient permissions to access this resource'
      />
    )
  }

  if (isLoading) {
    return null
  }

  if (error) {
    return (
      <EmptySection
        title='There was an error loading this Component Model'
        description=''
      />
    )
  }

  if (!componentModel) {
    return (
      <EmptySection
        title='Component Model Not Found'
        description=''
      />
    )
  }

  return (
    <div className={classes.page}>
      <ComponentModelProvider componentModel={componentModel} isFetching={isFetching}>
        <ComponentModelHeader />
        <ComponentModelTabs />
      </ComponentModelProvider>
    </div>
  )
}

export default ComponentModelDetails
