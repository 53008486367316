import React from 'react'
import { Grid, Checkbox } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import Text from '../atoms/Text'
import { BUTTON_VARIANT, BUTTON_SIZES } from '../../constants'
import RoundedButton from '../atoms/RoundedButton'
import PlanGoal from './PlanGoal'
import TitleWithSubtitle from './TitleWithSubtitle'

const useStyles = makeStyles((theme) => ({
  root: {
    width: 250
  },
  inputTop: {
    fontSize: '24px',
    fontWeight: 700,
    width: '160px',
    paddingBottom: '0px'
  },
  checkbox: {
    color: '#212945 !important',
    backgroundColor: '#F3F3F3 !important'
  },
  tier: {
    minWidth: '300px',
    maxWidth: '360px'
  },
  tierTitle: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  line: ({ lineColor }) => ({
    width: '100%',
    height: '7px',
    backgroundColor: lineColor || '#76C39A',
    marginBottom: '24px'
  })
}))

const PlanTier = ({
  tierInfo,
  isChecked,
  tierStatusHandler,
  goalEditHandler,
  goalStatusHandler,
  goals
}) => {
  const { tierId, title, subtitle, secondSubtitle, lineColor } = tierInfo
  const classes = useStyles({ lineColor })

  return (
    <Grid key={tierId} item xs={12} md={4} style={{ padding: '12px', fontSize: '14px', alignSelf: 'start' }}>
      <div className={classes.tier}>
        <div style={{ marginBottom: '12px', display: 'flex', flexDirection: 'row' }}>
          <Checkbox
            checked={isChecked}
            onChange={(event) => tierStatusHandler(tierId, event.target.checked)}
            name={`checkbox-tier-${tierId}`}
            color='default'
          />
          <div className={classes.tierTitle}>
            <Text
              text={title}
              customFontSize='20px'
            />
            <TitleWithSubtitle
              titleElement={
                <Text text={subtitle} customFontSize='12px' />
              }
              subtitleElement={
                <Text text={secondSubtitle} customFontSize='12px' />
              }
              alignment='flex-end'
            />
          </div>
        </div>
        <div className={classes.line} style={{ backgroundColor: `${lineColor} !important` }} />
        <div>
          {goals.map((goal) => {
            return (
              <PlanGoal key={goal.goalId} goal={goal} goalStatusHandler={goalStatusHandler} goalEditHandler={goalEditHandler} />
            )
          })}
        </div>
        <RoundedButton
          size={BUTTON_SIZES.extraSmall}
          disabled
          variant={BUTTON_VARIANT.text}
        >
          <Text
            text='+ Add goal'
            customFontSize='12px'
          />
        </RoundedButton>

      </div>
    </Grid>
  )
}

PlanTier.propTypes = {
  tierInfo: PropTypes.shape({
    tierId: PropTypes.string,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    secondSubtitle: PropTypes.string,
    lineColor: PropTypes.string
  }),
  goals: PropTypes.arrayOf(PropTypes.shape({
    goalId: PropTypes.string,
    edit: PropTypes.string,
    status: PropTypes.string,
    rate: PropTypes.shape({
      rateValue: PropTypes.string
    }),
    goalName: PropTypes.string
  })),
  goalStatusHandler: PropTypes.func,
  goalEditHandler: PropTypes.func,
  tierStatusHandler: PropTypes.func,
  isChecked: PropTypes.bool
}

PlanTier.defaultProps = {
  goal: {},
  goals: [],
  isChecked: false,
  tierStatusHandler: noop,
  goalStatusHandler: noop,
  goalEditHandler: noop
}

export default PlanTier
