import React, { useMemo } from 'react'
import { makeStyles } from '@material-ui/core'
import SectionScreen from '../../SectionScreen'
import { useClientDetails } from '../../ClientDetailsFormContext'
import { useGetClientAccountGroupHierarchy } from '../../../../../../api/groups'
import SectionHeader from '../../../shared/SectionHeader'
import FadeIn from '../../../../../molecules/Transitions/FadeIn'
import { useAccountSearch } from '../../../../../../api/coreData'
import Hierarchy from './Hierarchy'

const useStyles = makeStyles((theme) => ({
  tagsSection: {
    position: 'relative',
    padding: '10px'
  }
}))

function ClientAccountGroups () {
  const classes = useStyles()
  const { client } = useClientDetails()
  const { data, isLoading } = useGetClientAccountGroupHierarchy(client.clientId)
  const query = useMemo(() => {
    return {
      filters: {
        assignedToClientIds: [client.clientId]
      },
      take: 100,
      sort: [{ field: 'accountName', dir: 'asc' }]
    }
  }, [client])
  const { data: assignedAccounts, isLoading: assignedAccountsLoading } = useAccountSearch(query)
  const mergedData = useMemo(() => {
    if (!(data?.groupTypes && assignedAccounts?.data)) {
      return []
    }
    // mark those accounts that are not assigned as not assigned
    const result = data.groupTypes.map(gt => {
      return {
        ...gt,
        groups: gt.groups.map(g => ({
          ...g,
          accounts: g.accounts.map(acct => {
            const assignedAccount = assignedAccounts.data.find(assigned => assigned.accountId === acct.accountId)
            return {
              ...acct,
              assignedAccount,
              isAssigned: !!assignedAccount
            }
          })
        }))
      }
    })

    result.forEach(gt => {
      // find accounts not assigned to any group
      if (!gt.uniqueMembers) {
        gt.outstandingAccounts = []
        return
      }
      gt.outstandingAccounts = assignedAccounts.data.filter(assigned => !gt.groups.some(g => g.accounts.some(acct => assigned.accountId === acct.accountId)))
    })
    return result
  }, [data, assignedAccounts])

  if (isLoading || assignedAccountsLoading) {
    return null
  }

  return (
    <FadeIn>
      <SectionScreen sectionName='client-specific-groups' className={classes.tagsSection}>
        <SectionHeader text='Client-Account Tags' />
        <div>
          <Hierarchy client={client} groupTypes={mergedData} />
        </div>
      </SectionScreen>
    </FadeIn>
  )
}

export default ClientAccountGroups
