import { IconButton, darken, makeStyles } from '@material-ui/core'
import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import {
  ICON_NAMES
} from '../../../../../constants'
import { useContextContainer } from '../../../../../abundanceEngine/components/ContextContainer'
import { CLIENT_VIEW_CONTEXT } from '../..'
import Icon from '../../../../atoms/Icon'

const useStyles = makeStyles((theme) => ({
  iconButton: {
    position: 'inherit',
    padding: '0.75rem',
    backgroundColor: theme.palette.seaFoam,
    '&:hover': {
      backgroundColor: darken(theme.palette.seaFoam, 0.1)
    }
  }
}))

const SalesForceButton = ({ clientSalesforceId, disabled }) => {
  const classes = useStyles()
  const [, setClientViewContext] = useContextContainer(CLIENT_VIEW_CONTEXT)

  const onOpenDrawer = useCallback(() => {
    setClientViewContext((prevState) => ({
      ...prevState,
      drawerOpen: true
    }))
  }, [setClientViewContext])

  const onClick = useCallback((e) => {
    e.preventDefault()
    e.stopPropagation()
    onOpenDrawer()
    setClientViewContext((prevState) => ({
      ...prevState,
      integrationsInfo: { clientId: clientSalesforceId }
    }))
  }, [clientSalesforceId, onOpenDrawer, setClientViewContext])

  return (
    <IconButton
      className={classes.iconButton}
      onClick={onClick}
      disabled={disabled}
      size='small'
    >
      <Icon
        name={ICON_NAMES.salesforceSmall}
        customSize='1.125rem'
        padding='1rem'
      />
    </IconButton>
  )
}

SalesForceButton.propTypes = {
  disabled: PropTypes.bool,
  clientSalesforceId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

export default SalesForceButton
