import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Text from '../atoms/Text'

const useStyles = makeStyles((theme) => ({
  actions: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start'
  },
  actionItem: {
    marginLeft: '0.75rem'
  },
  report: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '0.5rem',
    border: `1px solid ${theme.palette.hardcoded}`,
    boxSizing: 'border-box',
    borderRadius: '4px',
    color: theme.palette.hardcoded,
    width: '100%'
  }
}))

function Report ({
  content,
  title
}) {
  const classes = useStyles()
  return (
    <div className={classes.report}>
      <Text text={title} lineHeight='1.5rem' customFontSize='1rem' />
      <Text text={content} />
      <div className={classes.actions}>
        <Text text='Resend' lineHeight='1rem' customFontSize='1rem' />
        <Text
          text='Edit'
          lineHeight='1rem'
          customFontSize='1rem'
          className={classes.actionItem}
        />
      </div>
    </div>
  )
}

Report.propTypes = {
  content: PropTypes.string,
  title: PropTypes.string
}

Report.defaultProps = {
  content: '',
  title: undefined
}

export default Report
