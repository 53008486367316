import React, { forwardRef, useMemo } from 'react'
import PropTypes from 'prop-types'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { Button, CircularProgress, alpha, makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import { isEmpty } from 'lodash'
import CloseIcon from '@material-ui/icons/Close'
import { BUTTON_SIZES, TEXT_VARIANTS } from '../../../constants'
import Text from '../../atoms/Text'

const useStyles = makeStyles(() => ({
  caret: {
    display: 'flex',
    marginLeft: '0.75rem'
  },
  button: {
    background: '#FFFFFF',
    border: '1px solid #ECECEC',
    borderRadius: '8px'
  },
  buttonFilled: {
    background: '#212945',
    color: '#FFFFFF',
    '&:hover': {
      background: alpha('#212945', 0.75)
    }
  },
  labelBase: {
    textTransform: 'none',
    lineHeight: '1',
    fontWeight: 'bold',
    whiteSpace: 'nowrap'
  },
  label: {
    fontSize: '0.625rem'
  },
  placeholder: {
    fontStyle: 'normal',
    fontSize: '0.8125rem'
  },
  prefixLabel: {
    fontSize: '0.8125rem',
    marginRight: '0.5rem'
  },
  labelContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '& span:first-child': {
      marginRight: '0.625rem'
    }
  }
}))

const SelectFilterButton = (
  {
    open,
    label,
    disabled,
    anchorRef,
    isLoading,
    className,
    placeholder,
    handleToggle,
    onClearFilters,
    showClearButton
  },
  ref
) => {
  const classes = useStyles()

  const classNames = useMemo(() => {
    return clsx(classes.button, {
      [classes.buttonFilled]: !isEmpty(label) || open,
      [className]: !!className
    })
  }, [classes.buttonFilled, classes.button, className, open, label])

  const renderLabel = useMemo(() => {
    if (isEmpty(label)) {
      return (
        <Text
          text={placeholder}
          variant={TEXT_VARIANTS.subtitle1}
          className={clsx(classes.placeholder, classes.labelBase)}
        />
      )
    }
    return (
      <div className={classes.labelContainer}>
        <Text
          text={placeholder}
          variant={TEXT_VARIANTS.subtitle1}
          className={clsx(classes.prefixLabel, classes.labelBase)}
        />
        <Text
          text={label}
          variant={TEXT_VARIANTS.subtitle1}
          className={clsx(classes.label, classes.labelBase)}
        />
      </div>
    )
  }, [
    label,
    placeholder,
    classes.label,
    classes.labelBase,
    classes.placeholder,
    classes.prefixLabel,
    classes.labelContainer
  ])

  const filterIcon = useMemo(() => {
    if (showClearButton) {
      return (
        <CloseIcon
          fontSize='small' onClick={(event) => {
            event.stopPropagation()
            onClearFilters()
          }}
        />
      )
    }
    if (open) {
      return <ExpandLessIcon fontSize='small' />
    }
    return <ExpandMoreIcon fontSize='small' />
  }, [open, onClearFilters, showClearButton])

  return (
    <Button
      ref={ref}
      disableRipple
      aria-haspopup='true'
      aria-controls={open ? 'menu-list-grow' : undefined}
      className={classNames}
      onClick={handleToggle}
      disabled={anchorRef}
      size={BUTTON_SIZES.small}
      endIcon={isLoading ? <CircularProgress size={15} /> : null}
    >
      {renderLabel}
      {!disabled && <div className={classes.caret}>{filterIcon}</div>}
    </Button>
  )
}

SelectFilterButton.propTypes = {
  open: PropTypes.bool,
  anchorRef: PropTypes.node,
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  handleToggle: PropTypes.func,
  onClearFilters: PropTypes.func,
  showClearButton: PropTypes.bool
}

export default forwardRef(SelectFilterButton)
