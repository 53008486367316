import React, { useCallback, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import { useTransactionCodeTagSearch } from '../../../../api/coreData'
import { useRelativeDateRange } from '../../../molecules/RelativeDateSelect'
import { useAppContext } from '../../../../redux/slices/appContext'
import Skeleton from '../../../atoms/Skeleton'
import TransactionCodeTagFilter from './TransactionCodeTagFilter'

const useStyles = makeStyles((theme) => ({
  featuredFilters: {
    display: 'grid',
    gridTemplateColumns: ({ transactionCodeTags, maxFilters }) => {
      const len = transactionCodeTags?.length === 0 ? maxFilters : Math.min(transactionCodeTags?.length, maxFilters)
      return Array.from(new Array(len), () => '1fr').join(' ')
    },
    placeContent: 'center',
    placeItems: 'center',
    gridGap: '20px'
  },
  featuredFiltersWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '1rem'
  }
}))

const buildQuery = ({
  dateRange,
  defaultFilter,
  defaultCountFilter,
  pageSize,
  clientId
}) => {
  const baseQuery = {
    includes: {
      transactionCount: {
        ...dateRange,
        ...(clientId ? { clientId } : {}),
        ...defaultCountFilter
      }
    },
    filters: {
      ...(defaultFilter ?? {})
    },
    skip: 0,
    take: pageSize
  }
  return baseQuery
}

export const useTransactionCodeTags = ({
  dateRange,
  enabled,
  defaultFilter,
  defaultCountFilter,
  pageSize,
  mapper,
  enableFirmWideView
}) => {
  const { clientId } = useAppContext()

  const query = useMemo(
    () =>
      !enabled || !dateRange
        ? null
        : buildQuery({
          dateRange,
          defaultFilter,
          defaultCountFilter,
          pageSize,
          clientId: enableFirmWideView ? null : clientId
        }),
    [
      enabled,
      pageSize,
      clientId,
      dateRange,
      defaultFilter,
      defaultCountFilter,
      enableFirmWideView
    ]
  )

  const { data, isLoading, error } = useTransactionCodeTagSearch(query, {
    enabled,
    mapper
  })

  return useMemo(() => ({
    data: (isLoading || error) ? [] : data,
    isLoading,
    error
  }), [data, isLoading, error])
}

const FeaturedFilters = ({
  relativeDateRange,
  availableDates,
  enabled,
  defaultFilter,
  maxFilters,
  onFilterSelected,
  defaultCountFilter,
  defaultSelectedFilter,
  enableFirmWideView
}) => {
  const { dateRange, valueLabel } = useRelativeDateRange(relativeDateRange, availableDates)
  const { data: transactionCodeTags, isLoading, error } = useTransactionCodeTags({
    dateRange,
    enabled,
    defaultFilter,
    defaultCountFilter,
    pageSize: maxFilters,
    enableFirmWideView
  })

  const classes = useStyles({ transactionCodeTags, maxFilters })

  const [selectedFilter, setSelectedFilter] = useState(defaultSelectedFilter)

  const onDeselect = useCallback((e) => {
    setSelectedFilter(null)
    e.preventDefault()
    e.stopPropagation()
  }, [setSelectedFilter])

  const onFilterClick = useCallback(filter => {
    setSelectedFilter(selected => {
      if (selected === filter) {
        return null
      }
      return filter
    })
  }, [setSelectedFilter])

  useEffect(() => {
    if (typeof onFilterSelected === 'function') {
      onFilterSelected(selectedFilter)
    }
  }, [selectedFilter, onFilterSelected])

  if (error) {
    return null
  }

  if (!enabled || isLoading) {
    return (
      <div className={classes.featuredFiltersWrapper} onClick={onDeselect}>
        <div className={classes.featuredFilters}>
          {(Array.from(new Array(maxFilters), (x, i) => i)).map((_, i) => (
            <Skeleton key={i} height='110px' width='8rem' />
          ))}
        </div>
      </div>
    )
  }

  return (
    <div className={classes.featuredFiltersWrapper} onClick={onDeselect}>
      <div className={classes.featuredFilters}>
        {transactionCodeTags.map(tagInfo => (
          <TransactionCodeTagFilter
            key={tagInfo.transactionCodeTagId}
            info={tagInfo}
            dateRangeLabel={valueLabel}
            loading={isLoading || !enabled}
            onClick={onFilterClick}
            selectedItem={selectedFilter}
          />
        ))}
      </div>
    </div>
  )
}

FeaturedFilters.propTypes = {
  relativeDateRange: PropTypes.string,
  availableDates: PropTypes.object,
  enabled: PropTypes.bool,
  defaultFilter: PropTypes.object,
  maxFilters: PropTypes.number,
  onFilterSelected: PropTypes.func,
  defaultSelectedFilter: PropTypes.object,
  enableFirmWideView: PropTypes.bool,
  defaultCountFilter: PropTypes.object
}

FeaturedFilters.defaultProps = {
  relativeDateRange: 'L30D',
  defaultFilter: {},
  maxFilters: 5,
  defaultSelectedFilter: null,
  enableFirmWideView: false,
  defaultCountFilter: {}
}

export default FeaturedFilters
