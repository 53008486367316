import React, { useCallback, useMemo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory, useParams } from 'react-router-dom'
import dayjs from 'dayjs'
import { Button } from '@material-ui/core'
import {
  useIngestionBatchById,
  useIngestionCancelJobMutation,
  useIngestionRestartJobMutation
} from '../../../../api/ingestion'
import LabeledValue from '../components/LabeledValue'
import Objective from './Objective'

const TIME_FORMAT = 'M/D/YYYY hh:mm:ss.SSS A z'
const useStyles = makeStyles((theme) => ({
  results: {
    padding: '1rem',
    '& > header': {
      borderBottom: '1px solid black',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between'
    },
    '& .__error': {
      color: theme.palette.error.primary
    }
  }
}))

const JobResultsIndex = () => {
  const classes = useStyles()
  const { batchId, jobId } = useParams()
  const { data, isLoading, refetch: refetchBatch } = useIngestionBatchById(batchId, { refetch: true })
  const { mutateAsync: restartAsync, isLoading: retrying } = useIngestionRestartJobMutation()
  const { mutateAsync: cancelAsync, isLoading: cancelling } = useIngestionCancelJobMutation()

  const headerText = useMemo(() => {
    if (!data) return 'Batch Results'

    return `Batch #${data.ingestionBatchId} - ${data.name}`
  }, [data])
  const history = useHistory()
  const restart = useCallback(async (e) => {
    e.preventDefault()
    e.stopPropagation()

    await restartAsync({ ingestionBatchId: batchId })
    await refetchBatch()
  }, [restartAsync, batchId, refetchBatch])

  const cancel = useCallback(async (e) => {
    e.preventDefault()
    e.stopPropagation()

    await cancelAsync({ ingestionBatchId: batchId })
    history.push(`/data-home/jobs/${jobId}`)
  }, [cancelAsync, batchId, jobId, history])

  if (isLoading) {
    return (
      <div className={classes.results}>Loading...</div>
    )
  }

  if (retrying) {
    return (
      <div className={classes.results}>Restarting...</div>
    )
  }

  if (cancelling) {
    return (
      <div className={classes.results}>Cancelling...</div>
    )
  }

  return (
    <div className={classes.results}>
      <header>
        <h2>
          <span>{headerText}</span>
        </h2>
        {data.status !== 'error' && data.status !== 'finished' && data.status !== 'cancelled' && (
          <Button color='danger' variant='contained' onClick={cancel}>Cancel</Button>
        )}
        {(data.status === 'error' || data.status === 'cancelled') && (
          <Button color='primary' variant='contained' onClick={restart}>Restart</Button>
        )}
      </header>
      <main>
        <LabeledValue label='Status'>{data.status}</LabeledValue>
        <LabeledValue label='Started'>{data.startDate ? dayjs(data.startDate).format(TIME_FORMAT) : '--'}</LabeledValue>
        <LabeledValue label='Finished'>{data.finishDate ? dayjs(data.finishDate).format(TIME_FORMAT) : '--'}</LabeledValue>
        <LabeledValue label='Duration'>{data.finishDate ? dayjs(data.finishDate).diff(data.startDate, 'minutes', true) : '--'}</LabeledValue>
        <LabeledValue label='Parameters'>
          <pre>
            {JSON.stringify(data.parameters, null, 2)}
          </pre>
        </LabeledValue>
        <div>
          {data.objectives.map(obj => (
            <Objective key={obj.ingestionBatchObjectiveId} objective={obj} />
          ))}
        </div>
      </main>
    </div>
  )
}

export default JobResultsIndex
