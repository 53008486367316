import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(() => ({
  container: {
    width: '14rem',
    borderRadius: '100px',
    border: '2px solid #F1F2F3',
    padding: ({ noPadding }) => noPadding ? 0 : '0.625rem'
  }
}))

const FamilyTreeNode = ({ children, noPadding }) => {
  const classes = useStyles({ noPadding })
  return <div className={classes.container}>{children}</div>
}

FamilyTreeNode.propTypes = {
  children: PropTypes.node,
  noPadding: PropTypes.bool
}

export default FamilyTreeNode
