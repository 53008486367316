import React from 'react'
import { Switch, useRouteMatch } from 'react-router-dom'
import { SecureRoute } from '@okta/okta-react'
import ErrorBoundary from '../../molecules/ErrorBoundary'
import { useHideSideMenuEffect } from '../../../redux/slices/appContext'
import NotAvailable from './components/NotAvailable'
import ConversationDashboard from './ConversationDashboard'
import AdvisorOversightIndex from './AdvisorOversight'
import { useIsConversationsActive } from './hooks'

const ConversationsIndex = () => {
  const { path } = useRouteMatch()
  useHideSideMenuEffect([])
  const isConversationsActive = useIsConversationsActive()

  if (!isConversationsActive) {
    return <NotAvailable />
  }

  return (
    <ErrorBoundary name='Conversations'>
      <Switch>
        <SecureRoute path={path} exact>
          <AdvisorOversightIndex />
        </SecureRoute>
        <SecureRoute path={`${path}/c/:clientId/t/:conversationKey`} exact>
          <ConversationDashboard />
        </SecureRoute>
        <SecureRoute path={`${path}/c/:clientId/new`} exact>
          <ConversationDashboard isNew />
        </SecureRoute>
        <SecureRoute path={`${path}/c/:clientId`} exact>
          <ConversationDashboard />
        </SecureRoute>
      </Switch>
    </ErrorBoundary>
  )
}

export default ConversationsIndex
