import { first, isArray, isObject, isEmpty, isString } from 'lodash'
import React, { useState, useCallback, useMemo } from 'react'
import DateRangePicker from '../components/molecules/DateRangePicker'
import { useAvailableDates } from '../redux/slices/appContext'
import {
  getBalanceInformationDateRanges,
  getSafeDateRange,
  insertAtIndex,
  modifyByIndex,
  removeByIndex
} from '../utils'
import { DATE_PRESET } from '../constants'
import useToggle from './useToggle'
import useDatePresetOptions from './useDatePresetOptions'

const COLUMN_DATE_RANGE_FILTER_ID = 'date_range_picker'

export default function useDateRangeTableFilter ({
  columns,
  setColumns,
  modifyColumns,
  dateRangeColSpan = 2,
  balanceInformationDateFilters
}) {
  const [dateRangeFilters, setDateRangeFilters] = useState(null)
  const { getDateByPresetValueKey } = useDatePresetOptions()

  const [isCustomDateRangeSelected, setIsCustomDateRangeSelected] =
    useState(false)
  const [availableDates] = useAvailableDates()
  const [
    showDateRangePicker,
    ,
    toggleDateRangePickerOn,
    toggleDateRangePickerOff
  ] = useToggle()

  const dateRangeFiltersHydrated = useMemo(() => {
    const { balanceInformationDateRanges } = getBalanceInformationDateRanges(
      availableDates,
      balanceInformationDateFilters
    )
    const dateRangeValues = Object.values(balanceInformationDateRanges)
    if (!dateRangeFilters) return dateRangeValues

    return dateRangeValues.map((dateRange) => {
      const { sourceKey } = dateRange
      const customDateRange = dateRangeFilters[sourceKey] || {}

      if (isEmpty(customDateRange)) {
        return dateRange
      }
      if (
        isObject(customDateRange) &&
          customDateRange.startDate &&
          customDateRange.endDate
      ) {
        return { sourceKey, ...customDateRange }
      }
      if (isString(customDateRange)) {
        const startDate = Object.values(DATE_PRESET).includes(customDateRange)
          ? getDateByPresetValueKey(customDateRange)
          : customDateRange

        return getSafeDateRange(availableDates, sourceKey, startDate)
      }
      return dateRange
    })
  }, [
    availableDates,
    dateRangeFilters,
    getDateByPresetValueKey,
    balanceInformationDateFilters
  ])

  const updateColumnsOnHeaderFilterChange = useCallback(
    (sourceKey, selectedValue) => {
      const mapColumn = (column) => {
        const { selectProps } = column
        if (
          selectProps?.sourceKey !== sourceKey ||
          selectProps?.selectType !== 'date'
        ) {
          return column
        }
        return {
          ...column,
          selectProps: {
            ...selectProps,
            selectedValue
          }
        }
      }
      const columnsUpdated = columns.map((column) =>
        isArray(column) ? column.map(mapColumn) : mapColumn(column)
      )

      setColumns(columnsUpdated)
    },
    [columns, setColumns]
  )

  const onHeaderFilterChange = useCallback(
    ({ sourceKey, onChange }, ...args) => {
      const [, selectedValue, datePresetKey] = args
      const isCustomOption = selectedValue === 'custom'
      setIsCustomDateRangeSelected(isCustomOption)
      const customDateRange = {
        ...(dateRangeFilters || {}),
        [sourceKey]: isCustomOption
          ? { sourceKey, startDate: null, endDate: null }
          : datePresetKey
      }
      if (Object.values(customDateRange).some(isObject)) {
        toggleDateRangePickerOn()
      } else {
        toggleDateRangePickerOff()
        onChange && onChange(...args)
      }
      setDateRangeFilters(customDateRange)
      updateColumnsOnHeaderFilterChange(sourceKey, selectedValue)
    },
    [
      dateRangeFilters,
      toggleDateRangePickerOn,
      toggleDateRangePickerOff,
      updateColumnsOnHeaderFilterChange
    ]
  )

  const onDateRangeChange = useCallback(
    (startDate, endDate, sourceKey) => {
      const dateRanges = {
        ...dateRangeFilters,
        [sourceKey]: {
          sourceKey,
          startDate,
          endDate
        }
      }
      const dateRangeValues = Object.values(dateRanges)
      if (
        dateRangeValues.some(({ startDate, endDate }) => startDate && endDate)
      ) {
        setIsCustomDateRangeSelected(false)
      }
      setDateRangeFilters(dateRanges)
    },
    [dateRangeFilters]
  )

  const dateRangePickers = useMemo(() => {
    if (isEmpty(dateRangeFilters)) {
      return null
    }
    const columnFilters = [
      { hidden: true, name: COLUMN_DATE_RANGE_FILTER_ID },
      { name: '', colSpan: dateRangeColSpan },
      ...balanceInformationDateFilters.map(({ sourceKey }) => {
        const dateRange = dateRangeFilters[sourceKey]
        const value = isObject(dateRange) ? (
          <DateRangePicker
            key={sourceKey}
            onChange={(...args) => onDateRangeChange(...args, sourceKey)}
            defaultStartDate={dateRange.startDate}
            defaultEndDate={dateRange.endDate}
            minDate={availableDates.min}
            maxDate={availableDates.max}
            format='MM/DD/YYYY'
            shouldDisableWeekends
          />
        ) : null
        return { name: value, alignment: 'right', colSpan: 2 }
      })
    ]
    const hasHeaderDateRangeFilters =
      Object.values(dateRangeFilters).some(isObject)
    modifyColumns((columns) => {
      const [firstColumn] = columns
      const firstCell = isArray(firstColumn) ? first(firstColumn) : firstColumn

      let columnsHydrated = []
      if (
        !hasHeaderDateRangeFilters &&
        firstCell.name === COLUMN_DATE_RANGE_FILTER_ID
      ) {
        columnsHydrated = removeByIndex(0, columns)
        return columnsHydrated.length > 1
          ? columnsHydrated
          : first(columnsHydrated)
      }
      if (hasHeaderDateRangeFilters) {
        if (firstCell.name === COLUMN_DATE_RANGE_FILTER_ID) {
          columnsHydrated = modifyByIndex(0, columns, [columnFilters])
          return columnsHydrated
        }
        if (isArray(firstColumn)) {
          columnsHydrated = insertAtIndex(0, columns, [columnFilters])
          return columnsHydrated
        }
        columnsHydrated = insertAtIndex(0, [columns], [columnFilters])
      }
      return columnsHydrated
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dateRangeColSpan,
    dateRangeFilters,
    onDateRangeChange,
    availableDates.min,
    availableDates.max,
    balanceInformationDateFilters
  ])

  return {
    dateRangePickers,
    showDateRangePicker,
    onHeaderFilterChange,
    dateRangeFiltersHydrated,
    isCustomDateRangeSelected
  }
}
