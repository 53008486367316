import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import { useWealthJourney } from '../v3/WealthJourneyProvider'
import TypeMarker from './TypeMarker'

const useStyles = makeStyles(theme => ({
  typeChip: {
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: theme.layout.gap.g10,
    fontWeight: theme.typography.weights.bold,
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: theme.palette.gray.darker,
    boxShadow: theme.layout.shadow.light,
    borderRadius: theme.layout.radius.round,
    paddingTop: theme.layout.padding.p10,
    paddingRight: theme.layout.padding.p20,
    paddingBottom: theme.layout.padding.p10,
    paddingLeft: theme.layout.padding.p15
  }
}))

const mapEntryType = (entryTypeId, entryTypes) => (entryTypes || []).find(et => et.entryTypeId === entryTypeId)

function TypeChip ({ entryTypeId, className, style }) {
  const { theming, entryTypes } = useWealthJourney()
  const classes = useStyles({ entryTypeId, theming })
  const entryType = mapEntryType(entryTypeId, entryTypes)

  if (!entryType) return null

  return (
    <div className={clsx(classes.typeChip, className)} style={style}>
      <TypeMarker color={entryType.color} />
      {entryType.entryTypeName}
    </div>
  )
}

TypeChip.propTypes = {
  entryTypeId: PropTypes.number,
  className: PropTypes.string,
  style: PropTypes.object
}

export default TypeChip
