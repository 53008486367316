import React from 'react'
import { makeStyles } from '@material-ui/core'
import config from '../../../config'

const useSplashStyles = makeStyles(() => ({
  splash: {
    padding: '20px 10px',
    minWidth: '75vw',
    backgroundColor: 'white',
    boxShadow: '0px 4px 12px rgba(24, 27, 53, 0.08)',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  }
}))

const SplashAlert = () => {
  const classes = useSplashStyles()
  const message = config.loginSplashMessage
  return message ? (
    <div className={classes.splash}>{message}</div>
  ) : null
}

export default SplashAlert
