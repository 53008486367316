import React, { useCallback, useRef } from 'react'
import { makeStyles } from '@material-ui/core'
import { useAssignRoleToWealthOwnerMutation } from '../../../../../api/users'
import SectionScreen from '../SectionScreen'
import { useWealthOwnerDetails, useSectionEditing } from '../ClientDetailsFormContext'
import EditButton from '../EditButton'
import SectionHeader from '../../shared/SectionHeader'
import PersonalSpace from '../../shared/PersonalSpace'
import { useRoleOptions } from './useRoleOptions'
import AssignExperienceForm from './AssignExperienceForm'

const useStyles = makeStyles((theme) => ({
  experienceSection: {
    position: 'relative',
    padding: '10px'
  }
}))

function ExperienceSection () {
  const { wealthOwner, editSection, refetchWealthOwner } = useWealthOwnerDetails()
  const editing = useSectionEditing('experience')
  const { data: roleOptions, isLoading } = useRoleOptions()

  const classes = useStyles()
  const formRef = useRef()
  const { mutateAsync: assignRole } = useAssignRoleToWealthOwnerMutation()
  const onSave = useCallback(async (e) => {
    const onValid = async (form) => {
      try {
        await assignRole({
          userId: form.userId,
          roleId: form.roleId
        })
        await refetchWealthOwner()
      } catch (err) {
        console.error('error trying to assign an experience to a wealth owner', err)
        alert('There was an unexpected problem saving the request.')
        throw err
      }
    }
    const onInvalid = (errors) => {
      console.error(errors)
    }

    const handler = formRef.current.handleSubmit(onValid, onInvalid)
    await handler(e)
  }, [formRef, assignRole, refetchWealthOwner])

  if (isLoading) {
    return <div>Loading</div>
  }

  return (
    <SectionScreen sectionName='experience' className={classes.experienceSection}>
      <SectionHeader text='Experience'>
        <div>
          <EditButton
            policy='admin_edit_wo_users'
            editing={editing}
            onClick={() => editSection({
              section: 'experience',
              saveDescription: 'Save Experience Assignment',
              onSave
            })}
          />
        </div>
      </SectionHeader>
      <AssignExperienceForm
        ref={formRef}
        wealthOwner={wealthOwner}
        roleOptions={roleOptions}
        editing={editing}
      />
      <PersonalSpace />
    </SectionScreen>
  )
}

export default ExperienceSection
