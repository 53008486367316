import React, { createContext, useCallback, useContext, useMemo, useState } from 'react'
import PropTypes from 'prop-types'

export const AdvisorUserFormContext = createContext({ })

export const useAdvisorUserDetails = () => {
  return useContext(AdvisorUserFormContext)
}

export const useSectionEditing = (sectionName) => {
  const { editing, section } = useAdvisorUserDetails()
  return useMemo(() => {
    return editing && section === sectionName
  }, [editing, section, sectionName])
}

const useAdvisorDetailsValue = ({ advisorUser, refetch, deleteAdvisorUser, defaultState }) => {
  const [formState, setFormState] = useState(defaultState)

  const editSection = useCallback(({
    section,
    saveDescription = 'Save',
    onSave
  }) => {
    setFormState((prevState) => ({
      ...prevState,
      editing: !prevState.editing,
      section: !prevState.editing ? section : null,
      saveDescription,
      onSave
    }))
  }, [setFormState])

  const cancelEdit = useCallback(() => {
    setFormState((prevState) => ({
      ...prevState,
      editing: false,
      section: null,
      saveDescription: null,
      onSave: null
    }))
  }, [setFormState])

  return useMemo(() => ({
    user: advisorUser,
    refetchUser: refetch,
    deleteAdvisorUser,
    editSection,
    cancelEdit,
    ...formState
  }), [advisorUser, editSection, cancelEdit, formState, refetch, deleteAdvisorUser])
}

export const AdvisorUserDetailsProvider = ({ advisorUser, refetch, deleteAdvisorUser, children, defaultState }) => {
  const value = useAdvisorDetailsValue({
    advisorUser,
    refetch,
    defaultState,
    deleteAdvisorUser
  })

  return (
    <AdvisorUserFormContext.Provider value={value}>
      {children}
    </AdvisorUserFormContext.Provider>
  )
}

AdvisorUserDetailsProvider.propTypes = {
  defaultState: PropTypes.shape({
    editing: PropTypes.bool,
    section: PropTypes.string,
    saveDescription: PropTypes.string,
    onSave: PropTypes.func
  }),
  advisorUser: PropTypes.object,
  children: PropTypes.node,
  refetch: PropTypes.func,
  deleteAdvisorUser: PropTypes.func
}

AdvisorUserDetailsProvider.defaultProps = {
  defaultState: {
    editing: false,
    section: null,
    saveDescription: null,
    onSave: null
  }
}
