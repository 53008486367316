import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import PresentationTable from '../../../../organisms/PresentationTable'
import { useFormattingContext } from '../../../../organisms/FormattingProvider/FormattingContext'
import { useTargetModelVisualizationData } from '../../../../../api/rebalancer'
import FadeIn from '../../../../molecules/Transitions/FadeIn'
import { useColumns } from './columnConfig'

const useStyles = makeStyles((theme) => ({
  analysisTable: {
    minHeight: '300px'
  }
}))

const mapChildren = (x) => {
  const children = x.children.filter(x => x.type === 'componentModel' || x.type === 'leaf')
  return {
    name: x.name,
    expanded: true,
    allocationToParent: x.relativeWeight / 10000,
    allocation: x.absoluteWeight / 10000,
    subRows: children.map(mapChildren),
    _next: children.length ? 1 : null
  }
}

function ComponentModelTable ({ targetModelId }) {
  const { formatter } = useFormattingContext()
  const { columns, defaultSort } = useColumns({ formatter })
  const { data, isLoading } = useTargetModelVisualizationData(targetModelId)
  const rows = useMemo(() => {
    if (isLoading) return []

    return data.tree.children.map(mapChildren)
  }, [data, isLoading])
  const classes = useStyles()

  return (
    <FadeIn className={classes.analysisTable} delay={0.3}>
      <PresentationTable
        columns={columns}
        expandable
        data={rows || []}
        loading={isLoading}
        defaultSort={defaultSort}
        sortable
        variant='bordered'
      />
    </FadeIn>
  )
}

ComponentModelTable.propTypes = {
  targetModelId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
}

export default ComponentModelTable
