import React, { useCallback } from 'react'
import { ClickAwayListener, makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import noop from 'lodash/noop'
import Text from '../atoms/Text'
import { useBoolean } from '../../hooks'

const useStyles = makeStyles(() => ({
  container: ({ focused, height }) => ({
    width: '100%',
    height: height || '100%',
    border: '2px solid #E9EAEF',
    borderRadius: '0.5rem',
    padding: '1rem',
    ...(focused
      ? {
        background: '#FFFFFF',
        border: '2px solid #212945',
        boxShadow: '0px 4px 16px rgba(18, 16, 43, 0.2)',
        borderRadius: '6px'
      }
      : {})
  }),
  titleContainer: {
    textAlign: 'left',
    marginTop: '0.75rem',
    marginBottom: '1.75rem'
  },
  title: {
    color: '#898D9B',
    fontSize: '1rem',
    lineHeight: '19px',
    fontWeight: 'bold',
    textTransform: 'uppercase'
  }
}))

const ListContainer = ({
  title,
  height,
  children,
  containerClassName,
  disableContainerHighlight,
  onClickAway: _onClickAway
}) => {
  const [focused, setIsFocused] = useBoolean()
  const classes = useStyles({
    focused: !disableContainerHighlight && focused,
    height
  })

  const onClickAway = useCallback(
    (event) => {
      setIsFocused.off()
      _onClickAway(event)
    },
    [_onClickAway, setIsFocused]
  )

  return (
    <ClickAwayListener onClickAway={onClickAway}>
      <div
        className={clsx(classes.container, {
          [containerClassName]: !!containerClassName
        })}
        onClick={setIsFocused.on}
      >
        {title && (
          <div className={classes.titleContainer}>
            <Text text={title} className={classes.title} />
          </div>
        )}
        <div className={classes.content}>{children}</div>
      </div>
    </ClickAwayListener>
  )
}

ListContainer.defaultProps = {
  title: '',
  children: null,
  height: null,
  onClickAway: noop,
  containerClassName: null,
  disableContainerHighlight: false
}

ListContainer.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  height: PropTypes.string,
  onClickAway: PropTypes.func,
  containerClassName: PropTypes.string,
  disableContainerHighlight: PropTypes.bool
}

export default ListContainer
