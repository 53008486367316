import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import ClientAvatar from '../../../../atoms/ClientAvatar'
import { useClientModelInfo } from '../../hooks/useClientModelInfo'
import FadeIn from '../../../../molecules/Transitions/FadeIn'

const useStyles = makeStyles((theme) => ({
  clientDisplay: {
    minHeight: '50px',
    padding: '10px',
    display: 'flex',
    flexDirection: 'row',
    gap: '10px',
    '& .__name': {
      fontSize: '20px'
    },
    '& .__model': {
      color: theme.palette.gray.A600
    }
  },
  loader: {
    minHeight: '50px'
  }
}))

function ClientDisplay ({ clientId }) {
  const classes = useStyles()

  const { client, model, loading } = useClientModelInfo(clientId)

  if (loading) {
    return (
      <section className={classes.loader} />
    )
  }

  return (
    <FadeIn className={classes.clientDisplay}>
      <div>
        <ClientAvatar client={client} />
      </div>
      <div>
        <div className='__name'>{client.longName}</div>
        <Link to={`/rebalancer/model/${model.targetModelId}`} className='__model'>{model?.name}</Link>
      </div>
    </FadeIn>
  )
}

ClientDisplay.propTypes = {
  clientId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
}

export default ClientDisplay
