export const mapSort = (sorts) =>
  sorts?.map(({ id, desc }) => ({ field: id, dir: desc ? 'desc' : 'asc' }))

const textSearchFields = ['email', 'firstName', 'lastName']

export const mapSearch = (searchText) =>
  searchText
    ? textSearchFields.reduce((prev, field) => {
      prev[field] = [{ op: 'contains', value: searchText }]
      return prev
    }, {})
    : {}
