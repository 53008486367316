import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import numeral from 'numeral'
import last from 'lodash/last'
import DOMPurify from 'dompurify'
import isArray from 'lodash/isArray'
import isObject from 'lodash/isObject'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import first from 'lodash/first'
import * as colors from '@material-ui/core/colors'
import get from 'lodash/get'
import isString from 'lodash/isString'
import {
  DATE_FORMAT,
  EMOJI_REGEX,
  NOTE_MENTION_REGEX,
  DAYJS_OPERATIONS,
  DAYJS_UNIT_TYPES,
  DAYS_OF_WEEK,
  THEME_COLORS,
  SALESFORCE_INTEGRATION,
  WEALTHBOX_INTEGRATION,
  REDTAIL_INTEGRATION,
  INTEGRATION_CRMS,
  PASSWORD_ERROR_MESSAGES,
  RETURN_STATUS_TYPES
} from './constants'
import { formatScientificNotation } from './utils/tableHelper'

dayjs.extend(utc)

export function convertEmoji (str) {
  return str
    ? str.replace(/\[e-([0-9a-fA-F]+)\]/g, (_, hex) =>
      String.fromCodePoint(Number.parseInt(hex, 16))
    )
    : ''
}

export function parseEmojisTextToUnicode (text) {
  return text.replace(
    EMOJI_REGEX,
    (match) => `[e-${match.codePointAt(0).toString(16)}]`
  )
}

export function replaceWithTextAsRegex (text, substr) {
  const regexEscaped = text.replace(NOTE_MENTION_REGEX, '\\$&')
  return text.replace(new RegExp(regexEscaped, 'g'), substr)
}

export const parseToCommentDisplayedText = (text, mentions, toRaw = true) => {
  const keyPairMentions = mentions.map(({ id, display }) => ({
    raw: `@[${display}](${id})`,
    display
  }))
  const matchIndexes = Array.from(Array(mentions.length * 2).keys()).slice(
    mentions.length,
    mentions.length * 2
  )
  let i = -1
  keyPairMentions.forEach((mention, index) => {
    const sourceKey = toRaw ? 'display' : 'raw'
    const targetKey = toRaw ? 'raw' : 'display'

    text = text.replace(
      new RegExp(mention[sourceKey].replace(NOTE_MENTION_REGEX, '\\$&'), 'g'),
      (match) => {
        i++
        return matchIndexes.includes(i) ? mention[targetKey] : match
      }
    )
  })
  return text
}

export const parseBodyComment = (comment) => {
  const regex = /@\[.+?\]\(.+?\)/gm
  const matches = comment.match(regex)
  if (!matches) return comment

  const mentions = []
  const displayRegex = /@\[.+?\]/g
  const idRegex = /\(.+?\)/g
  matches.forEach((m) => {
    const id = m.match(idRegex)[0].replace('(', '').replace(')', '')
    const display = m.match(displayRegex)[0].replace('@[', '').replace(']', '')

    mentions.push({ id: id, display: display })
  })

  const newComment = comment.split(regex)
  let output = ''
  for (let i = 0; i < newComment.length; i++) {
    const c = newComment[i]
    output +=
      i === newComment.length - 1
        ? c
        : c + `<a style="color: #004daa;">${mentions[i].display}</a>`
  }
  return DOMPurify.sanitize(output.replace(/\n\r?/g, '<br />'))
}

export function parseUnicodeTextToEmojis (text) {
  return convertEmoji(text)
}

export function removeEmptyProps (obj) {
  return Object.fromEntries(
    Object.entries(obj).filter(
      ([_, v]) =>
        (v !== null && v !== undefined && !isArray(v)) ||
        (isArray(v) && !isEmpty(v.filter(Boolean)))
    )
  )
}

export function removeByIndex (index, array) {
  return [...array.slice(0, index), ...array.slice(index + 1)]
}

export function modifyByIndex (index, array, item) {
  if (isArray(item)) return [...array.slice(0, index), ...item, ...array.slice(index + 1)]
  return [...array.slice(0, index), isObject(item) ? { ...item } : item, ...array.slice(index + 1)]
}

export function insertAtIndex (index, array, item) {
  if (isArray(item)) return [...array.slice(0, index), ...item, ...array.slice(index)]
  return [...array.slice(0, index), { ...item }, ...array.slice(index)]
}

export function insertDataAtIndex (originalData, data, insertAt) {
  return isEmpty(data) || insertAt === -1 ? originalData : insertAtIndex(insertAt, originalData, data)
}

export function capitalizeFirstLetter (string) {
  return string?.length ? string.charAt(0).toUpperCase() + string.slice(1) : string
}

export function numberToBPS (number, digits = 0) {
  // fixes rounding
  const power = Math.pow(10, digits)
  const num = Number(number || 0) * 10000
  return Number(((num * power) / power).toFixed(4))
}

export function BPSToNumber (number) {
  return Number(number || 0) / 10000
}

export function numberToUSD (number) {
  const numberFormat = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' })
  return numberFormat.format(Number(number))
}

export function feeLimitsDetailFormatter (lowerLimit, upperLimit) {
  return numFormatter(lowerLimit) + (upperLimit ? ` - ${numFormatter(upperLimit)}` : '+')
}

export function sum (key, data) {
  return data.reduce((acc, curr) => acc + curr[key], 0)
}

export function calcPercentage (numA, numB) {
  return numB === 0 ? 0 : (numA / numB) * 100
}

export function calcPositivePercentage (numA, numB) {
  return Math.abs(calcPercentage(numA, numB))
}

export function numFormatter (num) {
  if (num > 999 && num < 1000000) {
    return (num / 1000).toFixed(0) + 'K' // convert to K for number from > 1000 < 1 million
  } else if (num >= 1000000) {
    const millions = num / 1000000
    return millions.toFixed(Number.isInteger(millions) ? 0 : 1) + 'M' // convert to M for number from > 1 million
  } else if (num < 900) {
    return num // if value < 1000, nothing to do
  }
}

const DEFAULT_FORMAT = (x, _) => x
export function tableNumberFormatter (num, formatToPrecisionValue, options = {}) {
  const {
    defaultFormat = DEFAULT_FORMAT
  } = options
  if (!num) {
    return '–'
  }

  if (formatToPrecisionValue) {
    return numeral(num).format('0,0')
  }

  const absNum = Math.abs(num)

  if (absNum >= 1000000) {
    return numeral(num).format('0a')
  } else if (absNum >= 1000 && absNum < 1000000) {
    return numeral(num).format('0a')
  } else if (absNum >= 100 && absNum < 950) {
    return `${numeral(num / 1000).format('0.0')}k`
  } else if (absNum >= 950 && absNum < 1000) {
    return `${numeral(num / 1000).format('0')}k`
  }

  return defaultFormat(num, numeral)
}

export const fillDataGapBetweenDates = (
  valueDate,
  targetDate,
  value = null,
  reachTarget = false,
  reachTargetBackwards = false,
  format = DATE_FORMAT
) => {
  const dataGap = []
  const daysDiff = targetDate.diff(valueDate, 'day')
  const dayjsLength = Math.abs(reachTarget ? daysDiff : daysDiff - 1)
  for (let i = 0; i < dayjsLength; i++) {
    const nextValueDate = reachTargetBackwards
      ? valueDate.subtract(i + 1, 'day')
      : valueDate.add(i + 1, 'day')
    dataGap.push({
      x: nextValueDate.format(format),
      y: value
    })
  }
  return dataGap
}

/**
 * This function takes a string as input and capitalizes the
 * first letter of each word in the string.
 * @param {String} str
 * @returns string
 */
export const capitalizeEachWord = (str) => {
  if (!str) return ''
  return str.replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase())
}

export function convertPxToVh (pixels, documentHeight) {
  return (100 * pixels) / documentHeight
}

export function getAvatarProfilePicture (userName) {
  const nameNormalized = userName.trim().replace('\n', '')
  switch (nameNormalized) {
    case 'Jane Advisor':
      return 'pexels-photo-50480.jpg'
    case 'Harrison Barnes':
      return 'pexels-photo-50489.jpg'
    case 'Teresa Barnes':
      return 'pexels-photo-50481.jpg'
    case 'Brittany Barnes':
      return 'brittany-barnes.png'
    case 'Michelle Advisor':
      return 'michelle-advisor.jpg'
    case 'Glen Associate':
      return 'glen-associate.png'
    default:
      return nameNormalized
  }
}

export function truncate (num, digits = 2) {
  const factor = parseInt(String(1).padEnd(digits, '0'))
  return Math.trunc(num * factor) / factor
}

export function nearestDate (dates, target) {
  if (!target) target = Date.now()
  else if (target instanceof Date) target = target.getTime()

  let nearest = Infinity
  let winner = -1

  dates.forEach(function (date, index) {
    if (date instanceof Date) date = date.getTime()
    const distance = Math.abs(date - target)
    if (distance < nearest) {
      nearest = distance
      winner = index
    }
  })

  return winner
}

export const format = (num = 0, formatType) => {
  const fmt = num % 1 !== 0 ? formatType.LONG : formatType.SHORT
  return numeral(num).format(fmt)
}

export const getToolTip = (value, formatType, showPlaceholderIfNotValid = false) => {
  if (!value && showPlaceholderIfNotValid) {
    value = null
  } else if (value?.toString()?.includes('e')) {
    value = formatScientificNotation({ number: value, digits: 2 })
    value = format(value, formatType)
  } else {
    value = format(value, formatType)
  }
  return {
    title: value,
    format: formatType.LONG
  }
}

export function getDateFilterOptions (baseDate) {
  return [
    {
      key: 'Y',
      label: 'yesterday',
      value: dayjs(baseDate).subtract(1, 'day').format('YYYY-MM-DD')
    },
    {
      key: 'TW',
      label: 'this week',
      value: dayjs(baseDate).startOf('week').format('YYYY-MM-DD')
    },
    {
      key: 'L7D',
      label: 'last 7 days',
      value: dayjs(baseDate).subtract(7, 'day').format('YYYY-MM-DD')
    },
    {
      key: 'L30',
      label: 'last 30 days',
      value: dayjs(baseDate).subtract(30, 'day').format('YYYY-MM-DD')
    },
    {
      key: 'L3',
      label: 'last 3 months',
      value: dayjs(baseDate).subtract(3, 'month').format('YYYY-MM-DD')
    },
    {
      key: 'L6',
      label: 'last 6 months',
      value: dayjs(baseDate).subtract(6, 'month').format('YYYY-MM-DD')
    },
    {
      key: 'L12',
      label: 'last 12 months',
      value: dayjs(baseDate).subtract(1, 'year').format('YYYY-MM-DD')
    },
    {
      key: 'MD',
      label: 'Month to date',
      value: dayjs(baseDate).startOf('month').format('YYYY-MM-DD')
    },
    {
      key: 'QD',
      label: 'Quarter to date',
      value: dayjs(baseDate).startOf('quarter').format('YYYY-MM-DD')
    },
    {
      key: 'YD',
      label: 'Year to date',
      value: dayjs(baseDate).startOf('year').format('YYYY-MM-DD')
    }
  ]
}

export function getFileExtension (filename, includeFileName = true) {
  var ext = filename.split(/(?:\.([^.]+))?$/)
  if (ext === null) return null
  const [name, extension] = ext
  if (includeFileName) {
    return { name, extension }
  }
  return extension
}

export function formatBytes (bytes, precision = 2) {
  const kilobyte = 1024
  const megabyte = kilobyte * 1024
  const gigabyte = megabyte * 1024
  const terabyte = gigabyte * 1024

  if (bytes >= 0 && bytes < kilobyte) {
    return bytes + ' B'
  } else if (bytes >= kilobyte && bytes < megabyte) {
    return (bytes / kilobyte).toFixed(precision) + ' KB'
  } else if (bytes >= megabyte && bytes < gigabyte) {
    return (bytes / megabyte).toFixed(precision) + ' MB'
  } else if (bytes >= gigabyte && bytes < terabyte) {
    return (bytes / gigabyte).toFixed(precision) + ' GB'
  } else if (bytes >= terabyte) {
    return (bytes / terabyte).toFixed(precision) + ' TB'
  } else {
    return bytes + ' B'
  }
}

export const updateRowByIndex = (rows, initialRowIndexes, value = {}) => {
  const rowIndexes = [...initialRowIndexes]
  const rowIndex = rowIndexes.shift()
  if (rowIndex === undefined) return rows
  const row = rows[rowIndex] || [{}]
  const rowConfig = last(row)
  if (isEmpty(rows) || !isArray(rows)) return rows
  return [
    ...rows.slice(0, rowIndex),
    [
      ...row.slice(0, row.length - 1),
      {
        ...rowConfig,
        rows: updateRowByIndex(rowConfig?.rows || [], [...rowIndexes], value),
        ...(rowIndexes.length === 0 ? { ...value } : {})
      }
    ],
    ...rows.slice(rowIndex + 1)
  ]
}

export const mapTeamMembers = (teamMembers) =>
  (teamMembers || []).map(({ userId, firstName, lastName, profilePic }) => ({
    userId,
    firstName,
    lastName,
    profilePic
  }))

export const numeralByCase = (
  value,
  integerFormat = '$0,0',
  decimalsFormat = undefined
) => {
  if (value?.toString()?.includes('e')) {
    value = formatScientificNotation({ number: value, digits: 2 })
  }
  const format = value % 1 === 0
    ? integerFormat
    : decimalsFormat || integerFormat

  return numeral(value).format(format)
}

export const getSafeDate = (
  { mainDate, min, max },
  {
    unitValue = 0,
    unitType = DAYJS_UNIT_TYPES.DAY,
    operation = DAYJS_OPERATIONS.SUBTRACT,
    useMin = false,
    useMax = false
  },
  format = 'YYYY-MM-DD'
) => {
  const currentAsOfDate = DAYJS_OPERATIONS.START_OF === operation
    ? dayjs(mainDate)[operation](unitType)
    : dayjs(mainDate)[operation](unitValue, unitType)

  if (useMin || useMax) {
    if (min && useMin) {
      return dayjs(min).format(format)
    }
    if (max && useMax) {
      return dayjs(max).format(format)
    }
    return currentAsOfDate.format(format)
  }
  if (min) {
    if (currentAsOfDate.valueOf() < dayjs(min).valueOf()) {
      return dayjs(min).format(format)
    }
  }
  return currentAsOfDate.format(format)
}

export function createDataTestId (string, symbol) {
  return string.trim().replace(' ', symbol).toLowerCase()
}

export function formatTableRowValue (num = 0, formatWithDecimals, formatWithoutDecimals) {
  const fmt = num % 1 !== 0 ? formatWithDecimals : formatWithoutDecimals
  return numeral(num).format(fmt)
}

export function getBalanceInformationDateRanges (availableDates, ranges) {
  return {
    balanceInformationDateRanges: ranges.reduce((acc, { sourceKey, payload, ...dateRangeCriteria }) => {
      return {
        ...acc,
        [sourceKey]: {
          startDate: getSafeDate(availableDates, dateRangeCriteria),
          endDate: availableDates.mainDate,
          sourceKey,
          payload
        }
      }
    }, {}),
    balanceInformationSinceInceptionRange: {
      startDate: availableDates.min,
      endDate: availableDates.max
    }
  }
}

export function mergeBalanceInformation (balanceInformation) {
  const balanceInformationMerged = balanceInformation.reduce(
    (
      {
        balanceChangePercentage: prevBalanceChangePercentage,
        balanceChange: prevBalanceChange,
        balance: prevBalance
      },
      {
        balance,
        balanceChange,
        balanceChangePercentage,
        localPercentage = 1,
        ...balanceInformationRest // TODO: figure out how to merge the rest of the values
      }
    ) => {
      const balanceChangeWeightedAverage =
        prevBalanceChangePercentage + localPercentage * balanceChangePercentage
      const accBalanceChange = prevBalanceChange + balanceChange
      return {
        ...balanceInformationRest,
        balance: prevBalance + balance,
        balanceChange: accBalanceChange,
        balanceChangePositive: balanceInformation.balanceChangePositive,
        balanceChangePercentage: balanceChangeWeightedAverage
      }
    },
    { balance: 0, balanceChange: 0, balanceChangePercentage: 0 }
  )
  return balanceInformationMerged
}

export const getSafeDateRange = (availableDates, sourceKey, startDate, payload) => {
  const diffInDays = dayjs(availableDates.mainDate).diff(startDate, DAYJS_UNIT_TYPES.DAY)
  return {
    sourceKey,
    payload,
    startDate: getSafeDate(availableDates, { unitValue: diffInDays }),
    endDate: availableDates.mainDate
  }
}

export const disableWeekends = (date) => {
  return date.day() === DAYS_OF_WEEK.SATURDAY || date.day() === DAYS_OF_WEEK.SUNDAY
}

export const getDateUnits = (rawDate, isUTC = true) => {
  const date = isUTC ? dayjs.utc(rawDate) : dayjs(rawDate)
  const year = date.year()
  const month = date.month() + 1
  const day = date.date()
  return { year, month, day, date }
}

export const disableHolidaysAndWeekends = (rawDate, holidaysTree, isUTC = true) => {
  const { year, month, day, date } = getDateUnits(rawDate, isUTC)
  const isHoliday = Boolean(holidaysTree?.[year]?.[month]?.[day])
  return isHoliday || disableWeekends(date)
}

export const mapDatesIntoTree = (dates) => {
  return dates.reduce((acc, { date: rawDate }) => {
    const { year, month, day } = getDateUnits(rawDate)
    return {
      ...acc,
      [year]: {
        ...(acc[year] || {}),
        [month]: {
          ...(acc[year] ? acc[year][month] : {}),
          [day]: rawDate
        }
      }
    }
  }, {})
}

export const getRandomThemeColor = () => {
  const colors = Object.values(THEME_COLORS)
  return colors[Math.floor(Math.random() * colors.length)]
}

export const deepCopyWithoutOverrideSource = (source, target) => {
  const mergedObject = Object.entries(source).reduce(
    (acc, [key, sourceValue]) => {
      if (target[key] === undefined || !(key in target)) {
        acc.push([key, sourceValue])
      } else if (
        isObject(sourceValue) &&
        !isArray(sourceValue) &&
        !isNil(sourceValue)
      ) {
        acc.push([
          key,
          deepCopyWithoutOverrideSource(sourceValue, target[key])
        ])
      }
      return acc
    },
    Object.entries(target)
  )
  return Object.fromEntries(mergedObject)
}

export const compose = (...methods) => initialValue => methods.reduce((acc, method) => method(acc), initialValue)

export const VIEWS_PATH = 'views'

export const numberFormatters = {
  numFormatter,
  numeralByCase,
  numberToUSD
}

// Method to create payload for tables
export const createDynamicPayload = (item, depth = 0, prop = 'child', counter, row) => {
  let child

  for (const property in item) {
    if (prop === property) {
      child = item[property]
      counter++
    }
  }

  if (depth >= counter) {
    return createDynamicPayload(child, depth, prop, counter, row)
  }

  if (!child.attrs) {
    return {
      level: child.levelType
    }
  }

  return Object.keys(child.attrs).reduce((acc, current) => ({
    ...acc,
    [current]: row[current]
  }), { level: child.levelType })
}

export function paletteToThemeColorStrings (palette, themeColorPrefix = '') {
  return Object.keys(palette).map(paletteName => {
    const child = palette[paletteName]

    if (typeof child === 'object') {
      return Object.keys(child).map(() => paletteToThemeColorStrings(child, paletteName)).flat()
    }

    return themeColorPrefix ? `${themeColorPrefix}.${paletteName}` : paletteName
  }).flat()
}

export function getAvailableDatesFormatted (
  { min, max, ...restDates },
  currentAvailableDates = {}
) {
  let date = null
  const defaultMainDate = dayjs().format('YYYY-MM-DD')

  const { mainDate: currentMainDate } = currentAvailableDates

  // To preserve the current asOfDate if the client change
  if (currentMainDate && currentMainDate <= max && currentMainDate >= min) {
    date = currentMainDate
  }

  const availableDatesKeyValuePair = Object.entries({
    ...(min && max ? { min, max } : {}),
    mainDate: date || max || defaultMainDate
  }).map(([key, value]) => [key, first(value.split('T'))])

  return {
    ...Object.fromEntries(availableDatesKeyValuePair),
    ...restDates
  }
}

export function getPropValue (obj, key) {
  const keys = Object.keys(obj)
  let value = null
  let i = 0

  while (i < keys.length) {
    const prop = keys[i]

    if (!obj[prop]) {
      delete obj[prop]

      value = getPropValue(obj, key)
      break
    }

    if (prop === key) {
      value = obj[prop]
      break
    }

    if (obj[prop] && typeof obj[prop] === 'object') {
      value = getPropValue(obj[prop], key)
      break
    }

    i++
  }

  return value
}

export function getActiveCRM (activeIntegrations = []) {
  if (activeIntegrations.includes(SALESFORCE_INTEGRATION)) {
    return SALESFORCE_INTEGRATION
  }

  if (activeIntegrations.includes(WEALTHBOX_INTEGRATION)) {
    return WEALTHBOX_INTEGRATION
  }

  if (activeIntegrations.includes(REDTAIL_INTEGRATION)) {
    return REDTAIL_INTEGRATION
  }

  if (activeIntegrations.includes('practifi')) {
    return 'practifi'
  }

  return null
}

export function setActiveCRMToIntegrationsArray (activeCrm, activeIntegrations = []) {
  const active = activeCrm ? [activeCrm] : []

  activeIntegrations.forEach(integration => {
    if (!INTEGRATION_CRMS.includes(integration)) {
      active.push(integration)
    }
  })

  return active
}

export function getActiveCRMFromIntegrationsArray (activeIntegrations = []) {
  let active = null
  activeIntegrations.forEach(integration => {
    if (INTEGRATION_CRMS.includes(integration)) {
      active = integration
    }
  })

  return active
}

export function parseBadJSon (badJSON) {
  if (
    typeof badJSON === 'object' &&
    !Array.isArray(badJSON) &&
    badJSON !== null
  ) {
    return badJSON
  }

  if (typeof badJSON !== 'string') return {}

  const stringJSON = badJSON
    .replace(/:\s*"([^"]*)"/g, function (match, p1) {
      return ': "' + p1.replace(/:/g, '@colon@') + '"'
    })
    .replace(/:\s*'([^']*)'/g, function (match, p1) {
      return ': "' + p1.replace(/:/g, '@colon@') + '"'
    })
    .replace(/(['"])?([a-z0-9A-Z_]+)(['"])?\s*:/g, '"$2": ')
    .replace(/@colon@/g, ':')

  try {
    return JSON.parse(stringJSON)
  } catch (err) {
    console.error(
      `Error when trying to parse a JSON string. Error: [${err.message}]\nBad JSON: [${badJSON}\nString JSON: [${stringJSON}]]`
    )
    return {}
  }
}

export const downloadFile = (blob, fileName) => {
  const url = window.URL.createObjectURL(blob)
  const a = document.createElement('a')
  a.style.display = 'none'
  a.href = url
  a.download = fileName
  document.body.appendChild(a)
  a.click()
  window.URL.revokeObjectURL(url)
}

export const getRandomColor = (colorGroupKey = 50) => {
  const randomColors = Object.values(colors).map(
    (color) => color[colorGroupKey]
  )
  return randomColors[Math.floor(Math.random() * randomColors.length)]
}

export const generatePassErrorMessage = (error) => {
  const validateError = (msgError) => {
    return error?.message.toLowerCase().includes(msgError)
  }

  let message = ''

  if (validateError('old password')) {
    message = PASSWORD_ERROR_MESSAGES.OLD_PASSWORD
  } else if (validateError('current password')) {
    message = PASSWORD_ERROR_MESSAGES.CURRENT_PASSWORD
  } else if (validateError('too recently')) {
    message = PASSWORD_ERROR_MESSAGES.PREVIOUS_PASSWORD
  } else if (validateError('update of credentials failed')) {
    message = PASSWORD_ERROR_MESSAGES.INVALID_PASSWORD
  } else if (validateError('cannot be your username')) {
    message = PASSWORD_ERROR_MESSAGES.INVALID_FIELD
  } else {
    message = PASSWORD_ERROR_MESSAGES.INVALID_ANSWER
  }

  return message
}

export function pluralize (count, noun, suffix = 's') {
  return `${noun}${count !== 1 && count > 1 ? suffix : ''}`
}

export function splitArrayInChunks (list, chunkSize) {
  const tempList = [...list]
  return [...Array(Math.ceil(list.length / chunkSize))].map((_) =>
    tempList.splice(0, chunkSize)
  )
}

export function compareString (rowA, rowB, id, desc) {
  const a = rowA.values[id]?.toString()
  const b = rowB.values[id]?.toString()
  return desc ? b.localeCompare(a) : a.localeCompare(b)
}

export function compareNumericString (rowA, rowB, id, desc) {
  let a = Number.parseFloat(rowA.values[id])
  let b = Number.parseFloat(rowB.values[id])
  if (Number.isNaN(a)) {
    a = desc ? Number.NEGATIVE_INFINITY : Number.POSITIVE_INFINITY
  }
  if (Number.isNaN(b)) {
    b = desc ? Number.NEGATIVE_INFINITY : Number.POSITIVE_INFINITY
  }
  if (a > b) return 1
  if (a < b) return -1
  return 0
}

export function compareOrdinal (rowA, rowB, id, desc) {
  const ordinalA = rowA?.ordinal ?? rowA?.original?.ordinal ?? null
  const ordinalB = rowB?.ordinal ?? rowB?.original?.ordinal ?? null
  // the use of desc is not needed here, if desc is false, react-table
  // retrieves last rows as if they were the first ones
  if (ordinalA !== null && ordinalB !== null) {
    let order = 0
    if (ordinalA > ordinalB) order = 1
    if (ordinalA < ordinalB) order = -1
    return order
  }
  const a = rowA?.values?.[id]?.toString()
  const b = rowB?.values?.[id]?.toString()
  if (typeof a === 'string' && typeof b === 'string') {
    return desc ? a.localeCompare(b) : b.localeCompare(a)
  }
  return 0
}
export const isNumeric = (str) => {
  return !isNaN(str) && !isNaN(parseFloat(str))
}

export function findReplaceString (string, find, replace) {
  if (/[a-zA-Z_]+/g.test(string)) {
    return string.replace(
      new RegExp('{{(?:\\s+)?(' + find + ')(?:\\s+)?}}'),
      replace
    )
  }
  return string
}

export const replaceDoubleBraces = (str, source, fallback) => {
  const output = str.replace(/{{(.+?)}}/g, (match, path) => {
    const value = get(source, path, undefined)
    return value || match
  })
  // if the output is the same then we couldn't find anything to fullfil the template
  if (output === str) {
    return fallback || null
  }
  // if we couldn't find the variables for the template then don't show the result
  if (output.includes('{') && output.includes('}')) {
    return fallback || null
  }
  return output
}

export function isJson (str) {
  try {
    JSON.parse(str)
  } catch (e) {
    return false
  }
  return true
}

export function getReturnStatus (returnValue) {
  if (returnValue === 0) return RETURN_STATUS_TYPES.neutral
  return returnValue > 0
    ? RETURN_STATUS_TYPES.positive
    : RETURN_STATUS_TYPES.negative
}

/**
 * mainly used to merge two table configurations
 * it also looks for each column config item by ID and overrides those as well
 * @param {Object} baseColumnConfig - shape example { columns: [], defaultSort: [] }
 * @param {Object} columnConfigOverrides - shape example { defaultSort: [] }
 * @returns Object
 */
export const overrideColumnConfiguration = (
  baseColumnConfig,
  columnConfigOverrides
) => {
  return Object.fromEntries(
    Object.entries(baseColumnConfig).map(([key, value]) => {
      const configOverrides = columnConfigOverrides[key]
      if (!configOverrides) return [key, value]
      return [
        key,
        value.map((item) => {
          const itemOverride =
            configOverrides.find(({ id }) => id === item.id) || {}
          return { ...item, ...itemOverride }
        })
      ]
    })
  )
}

export const getRelativeTimeFromDate = (inputDate) => {
  const currentDate = dayjs.utc().valueOf()
  const targetDate = dayjs.utc(inputDate).valueOf()
  const timeDiff = currentDate - targetDate

  const minute = 60 * 1000
  const hour = minute * 60
  const day = hour * 24
  const week = day * 7
  const month = day * 30
  const year = day * 365

  if (timeDiff < minute) {
    return 'Just now'
  }
  if (timeDiff < hour) {
    const minutes = Math.floor(timeDiff / minute)
    return `${minutes} minute${minutes > 1 ? 's' : ''} ago`
  }
  if (timeDiff < day) {
    const hours = Math.floor(timeDiff / hour)
    return `${hours} hour${hours > 1 ? 's' : ''} ago`
  }
  if (timeDiff < week) {
    const days = Math.floor(timeDiff / day)
    return `${days} day${days > 1 ? 's' : ''} ago`
  }
  if (timeDiff < month) {
    const weeks = Math.floor(timeDiff / week)
    return `${weeks} week${weeks > 1 ? 's' : ''} ago`
  }
  if (timeDiff < year) {
    const months = Math.floor(timeDiff / month)
    return `${months} month${months > 1 ? 's' : ''} ago`
  }
  const years = Math.floor(timeDiff / year)
  return `${years} year${years > 1 ? 's' : ''} ago`
}

export const getUserGreeting = () => {
  const now = new Date()
  const currentHour = now.getHours()

  if (currentHour >= 5 && currentHour < 12) {
    return 'Good morning'
  }
  if (currentHour >= 12 && currentHour < 17) {
    return 'Good afternoon'
  }
  if (currentHour >= 17 && currentHour < 20) {
    return 'Good evening'
  }
  return 'Good night'
}

export const mergeSearchParams = (params1, params2) => {
  const mergedParams = new URLSearchParams(params1)
  for (const [key, value] of params2) {
    mergedParams.set(key, value)
  }
  return mergedParams
}

export const parseSearchParams = (searchParams) => {
  const params = Object.entries(Object.fromEntries(searchParams))
  return params.reduce((acc, [key, value]) => {
    return {
      ...acc,
      [key]: isJson(value) ? JSON.parse(value) : value
    }
  }, {})
}

export const stringifySearchParams = (params) => {
  return Object.entries(params).reduce(
    (acc, [key, value]) => ({
      ...acc,
      [key]: !isString(value) ? JSON.stringify(value) : value
    }),
    {}
  )
}

/**
 * Performs a deep merge of `source` into `target`.
 * Mutates `target` only but not its objects and arrays.
 */
export function mergeDeep (target, source) {
  const isObject = (obj) => obj && typeof obj === 'object'

  if (!isObject(target) || !isObject(source)) {
    return source
  }

  // Merge properties from source to target
  Object.keys(source).forEach((key) => {
    const targetValue = target[key]
    const sourceValue = source[key]

    if (Array.isArray(targetValue) && Array.isArray(sourceValue)) {
      target[key] = [...new Set(targetValue.concat(sourceValue))]
    } else if (isObject(targetValue) && isObject(sourceValue)) {
      target[key] = mergeDeep(Object.assign({}, targetValue), sourceValue)
    } else {
      target[key] = sourceValue
    }
  })

  return target
}

export function isNullOrUndefined (value) {
  return value === null || value === undefined
}

export function getInitials (text) {
  if (!text?.trim()) return ''
  const [firstSection, secondSection] = text.toUpperCase().split(' ')
  const firstInitial = firstSection.charAt(0)
  if (secondSection) {
    return `${firstInitial}${secondSection.charAt(0)}`
  }
  return firstInitial
}
