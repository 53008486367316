import { makeStyles } from '@material-ui/core'
import React from 'react'
import PropTypes from 'prop-types'
import { noop } from 'lodash'
import clsx from 'clsx'
import theme from '../../../../../theme'
import { getReportRuntimeConfiguration } from '../../../../../service'
import { useBoolean } from '../../../../../hooks'
import Icon from '../../../../atoms/Icon'
import { ICON_NAMES } from '../../../../../constants'

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column'
  },
  disabled: {
    cursor: 'not-allowed !important',
    opacity: '0.7'
  },
  buttonGroup: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '1rem',
    width: '4rem',
    justifyContent: 'space-between'
  },
  button: {
    cursor: 'pointer',
    '&:disabled': {
      cursor: 'default'
    }
  }
}))

const TemplateConfigurationsActions = ({
  row: {
    original: {
      runtimeConfigurationId
    }
  },
  updateTemplateConfigurationForm
}) => {
  const classes = useStyles()
  const [isLoading, setIsLoading] = useBoolean(false)
  const [isError, setIsError] = useBoolean(false)

  const onClick = async (actionType = 'edit') => {
    setIsLoading.on()
    setIsError.off()

    const { data } = await getReportRuntimeConfiguration(runtimeConfigurationId)
    setIsLoading.off()

    if (!data?.content) {
      setIsError.on()
      return
    }

    if (actionType === 'duplicate') {
      data.name = `${data.name} - Copy`
      data.runtimeConfigurationId = null
    }
    updateTemplateConfigurationForm(data)
  }

  return (
    <div className={clsx(classes.buttonGroup)}>
      <button
        key={'edit-' + runtimeConfigurationId}
        className={classes.button}
        type='button'
        onClick={
          isLoading ? noop : (event) => onClick('edit')
        }
        disabled={isLoading || isError}
      >
        <Icon
          color={theme.palette.summitBlue}
          name={ICON_NAMES.edit}
          customSize='1.4rem'
          variant='button'
        />
      </button>

      <button
        key={'duplicate-' + runtimeConfigurationId}
        className={classes.button}
        type='button'
        onClick={
          isLoading ? noop : (event) => onClick('duplicate')
        }
        disabled={isLoading || isError}
      >
        <Icon
          color={theme.palette.summitBlue}
          name={ICON_NAMES.fileCopy}
          customSize='1.4rem'
          variant='button'
        />
      </button>
    </div>
  )
}

TemplateConfigurationsActions.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      runtimeConfigurationId: PropTypes.number
    })
  }),
  updateTemplateConfigurationForm: PropTypes.func
}

export default TemplateConfigurationsActions
