import React from 'react'

const RuleEditor = () => {
  return (
    <div>Not Yet Implemented</div>
  )
}

RuleEditor.propTypes = {
}

export default RuleEditor
