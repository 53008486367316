import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import Dialog from '../../../molecules/Dialog'
import { DIALOG_ACTIONS } from '../../../../constants'

const useStyles = makeStyles((theme) => ({
  grayButton: {
    backgroundColor: theme.palette.gray.light,
    color: theme.palette.lightBlack,
    '&:hover': {
      backgroundColor: theme.palette.gray.dark
    }
  },
  redButton: {
    backgroundColor: theme.palette.lightCoral,
    color: theme.palette.error.main,
    '&:hover': {
      backgroundColor: '#f3b3be'
    }
  }
}))

const ManagementDialog = ({
  body,
  open,
  title,
  onClose,
  loading,
  onToggle,
  dialogType,
  primaryLabel,
  onTriggerAction
}) => {
  const classes = useStyles()

  const actions = useMemo(
    () => [
      {
        additionalClassName:
          dialogType === DIALOG_ACTIONS.delete
            ? classes.grayButton
            : classes.redButton,
        disabled: loading,
        handleClick: onToggle,
        label: 'Cancel'
      },
      {
        additionalClassName:
          dialogType === DIALOG_ACTIONS.delete
            ? classes.redButton
            : classes.grayButton,
        autofocus: true,
        disabled: loading,
        handleClick: onTriggerAction,
        label: primaryLabel,
        loading: loading
      }
    ],
    [
      classes.grayButton,
      classes.redButton,
      dialogType,
      loading,
      onToggle,
      onTriggerAction,
      primaryLabel
    ]
  )

  return (
    <Dialog
      actions={actions}
      body={body}
      handleClose={onClose}
      open={open}
      title={title}
    />
  )
}

ManagementDialog.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  body: PropTypes.string,
  title: PropTypes.string,
  loading: PropTypes.bool,
  onToggle: PropTypes.func,
  primaryLabel: PropTypes.string,
  onTriggerAction: PropTypes.func,
  dialogType: PropTypes.oneOf(Object.values(DIALOG_ACTIONS))
}

ManagementDialog.defaultProps = {
  primaryLabel: 'Delete',
  dialogType: DIALOG_ACTIONS.delete,
  body: 'This action will generate a permanent change.'
}

export default ManagementDialog
