import { makeStyles } from '@material-ui/core'
import React, { Children } from 'react'
import PropTypes from 'prop-types'
import { noop } from 'lodash'
import BlockButtonItem from './BlockButtonItem'

const useStyles = makeStyles(() => ({
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    '& div:first-child [class*="_item"]': {
      borderBottomLeftRadius: '8px',
      borderTopLeftRadius: '8px',
      borderRightColor: 'transparent'
    },
    '& div:last-child [class*="_item"]': {
      borderBottomLeftRadius: '0',
      borderTopLeftRadius: '0',
      borderBottomRightRadius: '8px',
      borderTopRightRadius: '8px',
      borderLeftColor: 'transparent'
    }
  }
}))

const BlockButtonGroup = ({
  children,
  comparator,
  selectedValue,
  onSelectOption
}) => {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      {Children.map(children, (child) => {
        if (child.type !== BlockButtonItem) {
          return null
        }
        return React.cloneElement(child, {
          isSelected: comparator(child.props.value, selectedValue),
          onSelect: () => onSelectOption(child.props.value)
        })
      })}
    </div>
  )
}

BlockButtonGroup.Option = BlockButtonItem

BlockButtonGroup.propTypes = {
  children: PropTypes.node.isRequired,
  selectedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  comparator: PropTypes.func,
  onSelectOption: PropTypes.func
}

BlockButtonGroup.defaultProps = {
  children: PropTypes.node,
  selectedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  comparator: (value, selectedValue) => value === selectedValue,
  onSelectOption: noop
}

export default BlockButtonGroup
