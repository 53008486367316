import React, { useCallback, useImperativeHandle, useState } from 'react'
import PropTypes from 'prop-types'
import RoundedModal from '../../../../../molecules/RoundedModal'
import AddAssetForm from './AddAssetForm'

const AddAssetDialog = React.forwardRef(function AddAssetDialog ({ onAdd }, ref) {
  const [dialogState, setDialogState] = useState({ open: false, processing: false })
  const close = useCallback(() => {
    setDialogState({ open: false, processing: false })
  }, [setDialogState])

  const complete = useCallback((componentModel) => {
    close()
    onAdd(componentModel)
  }, [close, onAdd])

  useImperativeHandle(ref, () => ({
    open: (item) => setDialogState({ open: true, processing: false, parent: item })
  }), [setDialogState])

  return (
    <RoundedModal
      title='Add Asset'
      open={dialogState.open}
      onClose={close}
      size='xmedium'
      position='top'
      disablePortal={false}
    >
      <div>
        <AddAssetForm onComplete={complete} />
      </div>
    </RoundedModal>
  )
})

AddAssetDialog.propTypes = {
  onAdd: PropTypes.func.isRequired
}

export default AddAssetDialog
