import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles, Grid } from '@material-ui/core'
import FadeIn from '../../../../molecules/Transitions/FadeIn'
import { useCheckPolicy } from '../../../../../hooks'
import NotAvailable from '../../../../organisms/NotAvailable'
import TransactionTaggingProvider from './TransactionTaggingContext'
import ClientBlade from './ClientBlade'
import MainBlade from './MainBlade'
import Header from './Header'

const useStyles = makeStyles((theme) => ({
  page: {
    padding: theme.layout.padding.medium,
    '& .__danger': {
      color: theme.palette.danger.main
    },
    '& .__no-content': {
      color: theme.palette.gray.darker,
      padding: theme.layout.padding.medium,
      fontStyle: 'italic'
    },
    '& .__content': {
      padding: theme.layout.padding.medium
    },
    '& .__table': {
      borderCollapse: 'collapse',
      width: '100%',
      '& thead tr': {
        position: 'sticky',
        top: 0,
        backgroundColor: theme.palette.background.default
      },
      '& thead th': {
        textAlign: 'left'
      },
      '& tr td': {
        fontWeight: theme.typography.weights.light
      }
    }
  },
  clientItem: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.layout.gap.medium,
    border: `1px solid ${theme.palette.gray.dark}`,
    padding: theme.layout.padding.thin,
    borderRadius: theme.layout.radius.tight,
    marginBottom: theme.layout.margin.thin
  }
}))

function TransactionTagging (props) {
  const classes = useStyles()
  const hasAccess = useCheckPolicy('internalOnly')

  if (!hasAccess) {
    return <NotAvailable />
  }

  return (
    <TransactionTaggingProvider clientsBaseQuery={props.clients.baseQuery}>
      <FadeIn className={classes.page}>
        <Grid container spacing={1}>
          <Grid item md={3} lg={2}>
            <ClientBlade {...props.clients} />
          </Grid>
          <Grid item md={9} lg={10}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Header />
              </Grid>
              <Grid item xs={12}>
                <MainBlade />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </FadeIn>
    </TransactionTaggingProvider>
  )
}

TransactionTagging.propTypes = {
  clients: PropTypes.shape({
    hideFilters: PropTypes.bool,
    baseQuery: PropTypes.object,
    columns: PropTypes.array,
    filterOn: PropTypes.arrayOf(PropTypes.shape({
      accessor: PropTypes.string,
      exact: PropTypes.bool
    }))
  })
}

TransactionTagging.defaultProps = {
  clients: {
    hideFilters: false,
    baseQuery: {
      includes: {
        accountCount: true
      }
    },
    columns: [
      { accessor: 'clientId', header: 'ID' },
      { accessor: 'longName', header: 'Name' },
      { accessor: 'accountCount', header: '#ACC', format: 'number', formatOptions: { dashZero: true }, align: 'right' }
    ],
    filterOn: [
      { accessor: 'clientId', exact: true },
      { accessor: 'longName' }
    ]
  }
}

export default TransactionTagging
