import React, { useCallback, useMemo, useRef } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { Button } from '@material-ui/core'
import Avatar from '../../../../atoms/Avatar'
import { useInternalUserId } from '../../../../../redux/slices/appContext'
import ConversationButton from '../../components/ConversationButton'

const useStyles = makeStyles(theme => ({
  messageInputPanel: {
    position: 'absolute',
    boxShadow: '0px -5px 10px -10px rgba(0, 0, 0, 0.3)',
    width: '100%',
    height: '175px',
    borderTop: `1px solid ${theme.palette.gray.main}`,
    padding: '24px'
  },
  '@keyframes messageInputLoad': {
    '0%': {
      opacity: 0,
      transform: 'translate(0, 100px)'
    },
    '1%': {
      opacity: 0
    },
    '100%': {
      opacity: 1,
      transform: 'translate(0, 0)'
    }
  },
  messageInputContainer: {
    animation: '$messageInputLoad .3s ease-in-out'
  },
  messageInput: {
    display: 'flex',
    flexDirection: 'row',
    gap: '10px',
    marginBottom: '16px',
    '& > textarea': {
      backgroundColor: theme.palette.white,
      fontSize: '1rem',
      padding: '10px',
      border: 'none',
      outline: 'none',
      width: '100%',
      resize: 'none',
      fontFamily: theme.typography.fontFamily
    }
  },
  postButton: {
    borderRadius: '18px',
    textTransform: 'initial',
    width: '120px',
    padding: '6px 16px',
    color: theme.palette.white,
    backgroundColor: theme.palette.primary.main
  },
  joinPanel: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '12px',
    '& .__message': {
      color: ({ isJoining }) => isJoining ? theme.palette.gray.darker : 'inherit'
    }
  }
}))

const useMyParticipantInfo = (participants) => {
  const internalUserId = useInternalUserId()
  return useMemo(() => {
    const match = participants.find(x => x.userId === internalUserId)

    return {
      self: match,
      abbreviation: match?.name?.substring(0, 1) ?? null,
      isParticipating: !!match
    }
  }, [internalUserId, participants])
}

const MessageInput = ({ onMessageEntered, inputPlaceholder, participants, onConversationJoined, isJoining }) => {
  const classes = useStyles({ isJoining })
  const inputRef = useRef()
  const { abbreviation, isParticipating } = useMyParticipantInfo(participants)
  const handleSend = useCallback(() => {
    onMessageEntered(inputRef.current.value)
    inputRef.current.value = ''
  }, [onMessageEntered])
  const handleKeyPress = useCallback((e) => {
    if (e.keyCode === 13 && !e.shiftKey) {
      handleSend()
    }
  }, [handleSend])

  if (!isParticipating) {
    return (
      <div className={classes.messageInputPanel}>
        <div className={classes.joinPanel}>
          <div className='__message'>Join this conversation to reply and send messages.</div>
          <div>
            <ConversationButton
              primary
              iconName='add'
              onClick={onConversationJoined}
              disabled={isJoining}
            >
              Join
            </ConversationButton>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className={classes.messageInputPanel}>
      <div className={classes.messageInputContainer}>
        <div className={classes.messageInput}>
          <Avatar customSize='36px' avatarLetters={abbreviation} />
          <textarea
            autoFocus
            ref={inputRef}
            rows={1}
            onKeyUp={handleKeyPress}
            placeholder={inputPlaceholder}
          />
        </div>
        <div>
          <Button className={classes.postButton} variant='contained' onClick={handleSend}>
            Post
          </Button>
        </div>
      </div>
    </div>
  )
}

MessageInput.propTypes = {
  onMessageEntered: PropTypes.func,
  inputPlaceholder: PropTypes.string,
  participants: PropTypes.arrayOf(PropTypes.shape({
    participantKey: PropTypes.string,
    name: PropTypes.string
  })),
  onConversationJoined: PropTypes.func,
  isJoining: PropTypes.bool
}

MessageInput.defaultProps = {
  inputPlaceholder: 'Reply to this thread'
}

export default MessageInput
