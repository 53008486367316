import { alpha, makeStyles } from '@material-ui/core'

export const useTableStyles = makeStyles((theme) => ({
  '@keyframes tableCellOpen': {
    '0%': {
      transform: 'scale(1, 0)',
      transformOrigin: 'top',
      maxHeight: 0
    },
    '100%': {
      transform: 'scale(1, 1)',
      transformOrigin: 'top',
      maxHeight: '2rem'
    }
  },
  '@keyframes fadey': {
    '0%': {
      opacity: 0.5
    },
    '100%': {
      opacity: 1
    }
  },
  presentationTableRC: ({ depthStyles, singleLevel }) => ({
    padding: '0',
    display: 'block',
    minWidth: 0,
    '& .__table': {
      fontSize: '0.85rem',
      borderSpacing: 0,
      '& .__thead': {
        position: 'sticky',
        top: ({ superHeaderRect }) =>
          superHeaderRect ? superHeaderRect.height : 0,
        boxShadow: '0px 6px 12px 12px #FFFFFF',
        overflowY: 'auto',
        overflowX: 'hidden',
        marginBottom: '12px'
      },
      '& .__headerGroup': {
      },
      '& .__th': {
        fontWeight: '800',
        fontSize: '0.875rem',
        padding: '8px 20px 8px 12px',
        userSelect: 'none',
        borderLeft: '1px solid rgba(0,0,0,.1)',
        borderRight: '1px solid rgba(0,0,0,0.0)',
        backgroundColor: theme.palette.gray.light,
        lineHeight: '30px',
        marginBottom: '-1px',
        '&:first-child': {
          borderLeft: '1px solid rgba(0,0,0,0.0)'
        },
        '&.__right': {
          textAlign: 'right'
        },
        '&.__center': {
          textAlign: 'center'
        },
        '&.__left': {
          textAlign: 'left'
        },
        '&.__depth_1': {
          padding: '4px 20px 6px 12px',
          borderLeft: '1px solid rgba(0,0,0,0.0)',
          borderRight: '1px solid rgba(0,0,0,0.0)',
          '&:nth-child(even)': {
            borderLeft: '1px solid rgba(0,0,0,0.1)'
          },
          borderBottom: '2px solid #212945'
        },
        '& .__th-content': {
          display: 'flex',
          alignItems: 'center'
        }
      },
      '& .__tbody': {
        overflowX: 'hidden' // rec
      },
      '& .__tr': {},
      '& .__tr:hover .__tr-wrapper': {
        backgroundColor: 'rgba(0, 0, 30, .1)',
        transition: 'background-color .5s ease-in-out .2s'
      },
      '& .__tr.__child': {
        animation: '$tableCellOpen .2s ease-in-out'
      },
      '& .__tr.__depth_0 .__td': {
        fontWeight: singleLevel ? 'inherit' : '800',
        ...(depthStyles?.[0] ?? {})
      },
      '& .__tr.__depth_1': {
        backgroundColor: theme.palette.gray.light,
        '& .__td': depthStyles?.[1] ?? {},
        '& .__tr-wrapper > *:first-child': {
          paddingLeft: '18px'
        }
      },
      '& .__tr.__depth_2': {
        backgroundColor: theme.palette.gray.main,
        '& .__td': depthStyles?.[2] ?? {},
        '& .__tr-wrapper > *:first-child': {
          paddingLeft: '36px'
        }
      },
      '& .__tr.__depth_3': {
        backgroundColor: theme.palette.gray.dark,
        '& .__td': depthStyles?.[3] ?? {},
        '& .__tr-wrapper > *:first-child': {
          paddingLeft: '54px'
        }
      },
      '& .__tr.__depth_4': {
        backgroundColor: theme.palette.gray.darker,
        '& .__td': depthStyles?.[4] ?? {},
        '& .__tr-wrapper > *:first-child': {
          paddingLeft: '72px'
        }
      },
      '& .__td': {
        textAlign: 'left',
        padding: '6px 20px 6px 12px',
        fontWeight: '400',
        transition: 'background-color .2s ease-in-out 0s',
        '&.__right': {
          textAlign: 'right'
        },
        '&:nth-child(even)': {
          borderLeft: '1px solid rgba(0,0,0,0.1)'
        },
        '& .__cell': {
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis'
        }
      },
      '& .__expand-container': {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        '& > *:first-child': {
          margin: '0 8px 0 0'
        },
        '& .__expand-item': {
          overflow: 'hidden'
        }
      },
      '& .__expand-handle': {
        animation: '$fadey .25s ease-in-out'
      },
      '& .__sort-icon': {
        padding: '0 0px 0 2px',
        '& .MuiSvgIcon-root': {
          transform: 'translateY(3px)'
        }
      },
      '& .__benchmark': {
        color: theme.palette.darkGrey,
        paddingTop: '4px',
        fontWeight: 'normal',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        width: '100%'
      }
    },
    '& .__sticky': {
      overflow: ({ hasHorizontalOverflow }) =>
        hasHorizontalOverflow ? 'auto' : 'unset',
      '& .__thead': {
        position: 'sticky',
        overflowX: 'unset !important',
        overflowY: 'unset !important',
        zIndex: 1,
        top: 0,
        boxShadow: '0px 3px 3px #ccc'
      },
      '& .__tbody': {
        position: 'relative',
        overflowX: 'unset !important',
        zIndex: 0
      },
      '& [data-sticky-td]': {
        position: 'sticky !important'
      },
      '& [data-sticky-last-left-td]': {
        borderRight: '1px solid rgba(0,0,0,0.0)'
      },
      '& [data-sticky-first-right-td]': {
        borderLeft: '1px solid rgba(0,0,0,.1)'
      },
      '& .__tr.__depth_0 > .__tr-wrapper > .__td': {
        backgroundColor: theme.palette.white
      },
      '& .__tr.__depth_1 > .__tr-wrapper > .__td': {
        backgroundColor: theme.palette.white
      },
      '& .__tr.__depth_2 > .__tr-wrapper > *.__td': {
        backgroundColor: '#F8F8F8'
      },
      '& .__tr.__depth_3 > .__tr-wrapper > *.__td': {
        backgroundColor: theme.palette.white
      },
      '& .__tr.__depth_4 > .__tr-wrapper > *.__td': {
        backgroundColor: theme.palette.gray.darker
      }
    },
    '& .__color-icon': {
      minHeight: '12px',
      minWidth: '12px',
      maxWidth: '12px',
      maxHeight: '12px',
      marginRight: '5px',
      border: `.5px solid ${theme.palette.gray.dark}`
    }
  }),

  default: ({ depthStyles, singleLevel }) => ({
    padding: '0',
    display: 'block',
    minWidth: 0,
    '& .__table': {
      fontSize: '0.85rem',
      borderSpacing: 0,
      '& .__thead': {
        position: 'sticky',
        top: ({ superHeaderRect }) =>
          superHeaderRect ? superHeaderRect.height : 0,
        boxShadow: '0px 6px 12px 12px #FFFFFF',
        overflowY: 'auto',
        overflowX: 'hidden',
        marginBottom: '12px',
        borderBottom: `3px solid ${theme.palette.black}`
      },
      '& .__headerGroup .__th.__first': {
        borderLeft: `1px solid ${theme.palette.gray.main}`
      },
      '& .__headerGroup .__th.__last': {
        borderRight: `1px solid ${theme.palette.gray.main}`
      },
      '& .__th': {
        fontWeight: '800',
        fontSize: '0.875rem',
        padding: '8px 20px 8px 12px',
        userSelect: 'none',
        borderCollapse: 'separate',
        borderSpacing: 0,
        borderLeft: `1px solid ${theme.palette.gray.main}`,
        borderRight: `1px solid ${theme.palette.gray.main}`,
        backgroundColor: theme.palette.gray.light,
        lineHeight: '30px',
        marginBottom: '-1px',
        '&.__right': {
          textAlign: 'right'
        },
        '&.__center': {
          textAlign: 'center'
        },
        '&.__left': {
          textAlign: 'left'
        },
        '&.__depth_0:last-child': {
          borderRight: 'none'
        },
        '&.__depth_1': {
          padding: '4px 20px 6px 12px',
          border: 'none'
        },
        '& .__th-content': {
          display: 'flex',
          alignItems: 'center'
        }
      },
      '& .__tbody': {
        overflowX: 'hidden' // rec
      },
      '& .__tr': {
        '& .__td.__first': {
          borderLeft: `1px solid ${theme.palette.gray.main}`
        }
      },
      '& .__tr:hover .__tr-wrapper': {
        backgroundColor: 'rgba(0, 0, 30, .1)',
        transition: 'background-color .5s ease-in-out .2s'
      },
      '& .__tr.__child': {
        animation: '$tableCellOpen .2s ease-in-out'
      },
      '& .__tr.__depth_0 .__td': {
        fontWeight: singleLevel ? 'inherit' : '800',
        ...(depthStyles?.[0] ?? {})
      },
      '& .__tr.__depth_1': {
        backgroundColor: theme.palette.gray.light,
        '& .__td': depthStyles?.[1] ?? {},
        '& .__tr-wrapper > *:first-child': {
          paddingLeft: '18px'
        }
      },
      '& .__tr.__depth_2': {
        backgroundColor: theme.palette.gray.main,
        '& .__td': depthStyles?.[2] ?? {},
        '& .__tr-wrapper > *:first-child': {
          paddingLeft: '36px'
        }
      },
      '& .__tr.__depth_3': {
        backgroundColor: theme.palette.gray.dark,
        '& .__td': depthStyles?.[3] ?? {},
        '& .__tr-wrapper > *:first-child': {
          paddingLeft: '54px'
        }
      },
      '& .__tr.__depth_4': {
        backgroundColor: theme.palette.gray.darker,
        '& .__td': depthStyles?.[4] ?? {},
        '& .__tr-wrapper > *:first-child': {
          paddingLeft: '72px'
        }
      },
      '& .__td': {
        textAlign: 'left',
        padding: '6px 20px 6px 12px',
        fontWeight: '400',
        transition: 'background-color .2s ease-in-out 0s',
        '&.__right': {
          textAlign: 'right'
        },
        '& .__cell': {
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis'
        }
      },
      '& .__expand-container': {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        '& > *:first-child': {
          margin: '0 8px 0 0'
        },
        '& .__expand-item': {
          overflow: 'hidden'
        }
      },
      '& .__expand-handle': {
        animation: '$fadey .25s ease-in-out'
      },
      '& .__sort-icon': {
        padding: '0 0px 0 2px',
        '& .MuiSvgIcon-root': {
          transform: 'translateY(3px)'
        }
      },
      '& .__benchmark': {
        color: theme.palette.darkGrey,
        paddingTop: '4px',
        fontWeight: 'normal',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        width: '100%'
      }
    },
    '& .__sticky': {
      overflow: ({ hasHorizontalOverflow }) =>
        hasHorizontalOverflow ? 'auto' : 'unset',
      '& .__thead': {
        position: 'sticky',
        overflowX: 'unset !important',
        overflowY: 'unset !important',
        zIndex: 1,
        top: 0,
        boxShadow: '0px 3px 3px #ccc'
      },
      '& .__tbody': {
        position: 'relative',
        overflowX: 'unset !important',
        zIndex: 0
      },
      '& [data-sticky-td]': {
        position: 'sticky !important'
      },
      '& [data-sticky-last-left-td]': {
        borderRight: '1px solid rgba(0,0,0,0.0)'
      },
      '& [data-sticky-first-right-td]': {
        borderLeft: '1px solid rgba(0,0,0,.1)'
      },
      '& .__tr.__depth_0 > .__tr-wrapper > .__td': {
        backgroundColor: theme.palette.white
      },
      '& .__tr.__depth_1 > .__tr-wrapper > .__td': {
        backgroundColor: theme.palette.white
      },
      '& .__tr.__depth_2 > .__tr-wrapper > *.__td': {
        backgroundColor: '#F8F8F8'
      },
      '& .__tr.__depth_3 > .__tr-wrapper > *.__td': {
        backgroundColor: theme.palette.white
      },
      '& .__tr.__depth_4 > .__tr-wrapper > *.__td': {
        backgroundColor: theme.palette.gray.darker
      }
    },
    '& .__color-icon': {
      minHeight: '12px',
      minWidth: '12px',
      maxWidth: '12px',
      maxHeight: '12px',
      marginRight: '5px',
      border: `.5px solid ${theme.palette.gray.dark}`
    }
  }),

  flat: ({ depthStyles, singleLevel }) => ({
    padding: '0',
    display: 'block',
    minWidth: 0,
    '& .__table': {
      fontSize: '0.85rem',
      borderSpacing: 0,
      '& .__thead': {
        position: 'sticky',
        top: ({ superHeaderRect }) =>
          superHeaderRect ? superHeaderRect.height : 0,
        boxShadow: '0px 6px 12px 12px #FFFFFF',
        overflowY: 'auto',
        overflowX: 'hidden',
        marginBottom: '12px',
        borderBottom: `3px solid ${theme.palette.black}`
      },
      '& .__th': {
        fontWeight: '800',
        fontSize: '0.875rem',
        padding: '8px 20px 8px 12px',
        userSelect: 'none',
        borderCollapse: 'separate',
        borderSpacing: 0,
        backgroundColor: theme.palette.gray.light,
        lineHeight: '30px',
        marginBottom: '-1px',
        '&.__right': {
          textAlign: 'right'
        },
        '&.__center': {
          textAlign: 'center'
        },
        '&.__left': {
          textAlign: 'left'
        },
        '&.__depth_1': {
          padding: '4px 20px 6px 12px',
          border: 'none'
        },
        '& .__th-content': {
          display: 'flex',
          alignItems: 'center'
        }
      },
      '& .__tbody': {
        overflowX: 'hidden' // rec
      },
      '& .__tr': {
      },
      '& .__tr:hover .__tr-wrapper': {
        backgroundColor: 'rgba(0, 0, 30, .1)',
        transition: 'background-color .5s ease-in-out .2s'
      },
      '& .__tr.__child': {
        animation: '$tableCellOpen .2s ease-in-out'
      },
      '& .__tr.__depth_0 .__td': {
        fontWeight: singleLevel ? 'inherit' : '800',
        ...(depthStyles?.[0] ?? {})
      },
      '& .__tr.__depth_1': {
        backgroundColor: theme.palette.gray.light,
        '& .__td': depthStyles?.[1] ?? {},
        '& .__tr-wrapper > *:first-child': {
          paddingLeft: '18px'
        }
      },
      '& .__tr.__depth_2': {
        backgroundColor: theme.palette.gray.main,
        '& .__td': depthStyles?.[2] ?? {},
        '& .__tr-wrapper > *:first-child': {
          paddingLeft: '36px'
        }
      },
      '& .__tr.__depth_3': {
        backgroundColor: theme.palette.gray.dark,
        '& .__td': depthStyles?.[3] ?? {},
        '& .__tr-wrapper > *:first-child': {
          paddingLeft: '54px'
        }
      },
      '& .__tr.__depth_4': {
        backgroundColor: theme.palette.gray.darker,
        '& .__td': depthStyles?.[4] ?? {},
        '& .__tr-wrapper > *:first-child': {
          paddingLeft: '72px'
        }
      },
      '& .__td': {
        textAlign: 'left',
        padding: '6px 20px 6px 12px',
        fontWeight: '400',
        transition: 'background-color .2s ease-in-out 0s',
        '&.__right': {
          textAlign: 'right'
        },
        '& .__cell': {
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis'
        }
      },
      '& .__expand-container': {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        '& > *:first-child': {
          margin: '0 8px 0 0'
        },
        '& .__expand-item': {
          overflow: 'hidden'
        }
      },
      '& .__expand-handle': {
        animation: '$fadey .25s ease-in-out'
      },
      '& .__sort-icon': {
        padding: '0 0px 0 2px',
        '& .MuiSvgIcon-root': {
          transform: 'translateY(3px)'
        }
      },
      '& .__benchmark': {
        color: theme.palette.darkGrey,
        paddingTop: '4px',
        fontWeight: 'normal',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        width: '100%'
      }
    },
    '& .__sticky': {
      overflow: ({ hasHorizontalOverflow }) =>
        hasHorizontalOverflow ? 'auto' : 'unset',
      '& .__thead': {
        position: 'sticky',
        overflowX: 'unset !important',
        overflowY: 'unset !important',
        zIndex: 1,
        top: 0,
        boxShadow: '0px 3px 3px #ccc'
      },
      '& .__tbody': {
        position: 'relative',
        overflowX: 'unset !important',
        zIndex: 0
      },
      '& [data-sticky-td]': {
        position: 'sticky !important'
      },
      '& [data-sticky-last-left-td]': {
        borderRight: '1px solid rgba(0,0,0,0.0)'
      },
      '& [data-sticky-first-right-td]': {
        borderLeft: '1px solid rgba(0,0,0,.1)'
      },
      '& .__tr.__depth_0 > .__tr-wrapper > .__td': {
        backgroundColor: theme.palette.white
      },
      '& .__tr.__depth_1 > .__tr-wrapper > .__td': {
        backgroundColor: theme.palette.white
      },
      '& .__tr.__depth_2 > .__tr-wrapper > *.__td': {
        backgroundColor: '#F8F8F8'
      },
      '& .__tr.__depth_3 > .__tr-wrapper > *.__td': {
        backgroundColor: theme.palette.white
      },
      '& .__tr.__depth_4 > .__tr-wrapper > *.__td': {
        backgroundColor: theme.palette.gray.darker
      }
    },
    '& .__color-icon': {
      minHeight: '12px',
      minWidth: '12px',
      maxWidth: '12px',
      maxHeight: '12px',
      marginRight: '5px',
      border: `.5px solid ${theme.palette.gray.dark}`
    }
  }),

  glass: ({ depthStyles, singleLevel, glassFactor = 0.85 }) => ({
    padding: '0',
    display: 'block',
    minWidth: 0,
    '& .__table': {
      fontSize: '0.85rem',
      borderSpacing: 0,
      border: `1px solid ${alpha(theme.palette.black, glassFactor / 2)}`,
      borderRadius: theme.layout.radius.tight,
      boxShadow: theme.layout.shadow.normal,
      '& .__thead': {
        position: 'sticky',
        top: ({ superHeaderRect }) =>
          superHeaderRect ? superHeaderRect.height : 0,
        overflowY: 'auto',
        overflowX: 'hidden',
        borderBottom: `2px solid ${alpha(theme.palette.black, glassFactor / 2)}`
      },
      '& .__th': {
        fontWeight: '800',
        fontSize: '0.875rem',
        padding: '8px 20px 8px 12px',
        userSelect: 'none',
        borderCollapse: 'separate',
        borderSpacing: 0,
        backgroundColor: alpha(theme.palette.gray.light, glassFactor),
        lineHeight: '30px',
        marginBottom: '-1px',
        '&.__right': {
          textAlign: 'right'
        },
        '&.__center': {
          textAlign: 'center'
        },
        '&.__left': {
          textAlign: 'left'
        },
        '&.__depth_1': {
          padding: '4px 20px 6px 12px',
          border: 'none'
        },
        '& .__th-content': {
          display: 'flex',
          alignItems: 'center'
        },
        '&:first-child': {
          borderTopLeftRadius: theme.layout.radius.tight
        },
        '&:last-child': {
          borderTopRightRadius: theme.layout.radius.tight
        }
      },
      '& .__tbody': {
        overflowX: 'hidden' // rec
      },
      '& .__tr': {
      },
      '& .__tr:last-child': {
        borderBottomLeftRadius: theme.layout.radius.tight,
        borderBottomRightRadius: theme.layout.radius.tight
      },
      '& .__tr:last-child .__td:first-child': {
        borderBottomLeftRadius: theme.layout.radius.tight
      },
      '& .__tr:last-child .__td:last-child': {
        borderBottomRightRadius: theme.layout.radius.tight
      },
      '& .__tr:hover .__tr-wrapper': {
        backgroundColor: alpha(theme.palette.primary.main, glassFactor),
        transition: 'background-color .5s ease-in-out .2s'
      },
      '& .__tr.__child': {
        animation: '$tableCellOpen .2s ease-in-out'
      },
      '& .__tr.__depth_0 .__td': {
        fontWeight: singleLevel ? 'inherit' : '800',
        ...(depthStyles?.[0] ?? {})
      },
      '& .__tr.__depth_1': {
        '& .__td': depthStyles?.[1] ?? {},
        '& .__tr-wrapper > *:first-child': {
          paddingLeft: '18px'
        }
        // '&:nth-child(2n)': {
        //   backgroundColor: alpha(theme.palette.gray.darker, 0.8)
        // }
      },
      '& .__tr.__depth_2': {
        '& .__td': depthStyles?.[2] ?? {},
        '& .__tr-wrapper > *:first-child': {
          paddingLeft: '36px'
        }
      },
      '& .__tr.__depth_3': {
        '& .__td': depthStyles?.[3] ?? {},
        '& .__tr-wrapper > *:first-child': {
          paddingLeft: '54px'
        }
      },
      '& .__tr.__depth_4': {
        '& .__td': depthStyles?.[4] ?? {},
        '& .__tr-wrapper > *:first-child': {
          paddingLeft: '72px'
        }
      },
      '& .__td': {
        textAlign: 'left',
        padding: '6px 20px 6px 12px',
        fontWeight: '400',
        transition: 'background-color .2s ease-in-out 0s',
        '&.__right': {
          textAlign: 'right'
        },
        '& .__cell': {
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis'
        }
      },
      '& .__expand-container': {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        '& > *:first-child': {
          margin: '0 8px 0 0'
        },
        '& .__expand-item': {
          overflow: 'hidden'
        }
      },
      '& .__expand-handle': {
        animation: '$fadey .25s ease-in-out'
      },
      '& .__sort-icon': {
        padding: '0 0px 0 2px',
        '& .MuiSvgIcon-root': {
          transform: 'translateY(3px)'
        }
      },
      '& .__benchmark': {
        color: theme.palette.darkGrey,
        paddingTop: '4px',
        fontWeight: 'normal',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        width: '100%'
      }
    },
    '& .__sticky': {
      overflow: ({ hasHorizontalOverflow }) =>
        hasHorizontalOverflow ? 'auto' : 'unset',
      '& .__thead': {
        position: 'sticky',
        overflowX: 'unset !important',
        overflowY: 'unset !important',
        zIndex: 1,
        top: 0
      },
      '& .__tbody': {
        position: 'relative',
        overflowX: 'unset !important',
        zIndex: 0
      },
      '& [data-sticky-td]': {
        position: 'sticky !important'
      },
      '& [data-sticky-last-left-td]': {
        borderRight: '1px solid rgba(0,0,0,0.0)'
      },
      '& [data-sticky-first-right-td]': {
        borderLeft: '1px solid rgba(0,0,0,.1)'
      },
      '& .__tr.__depth_0 > .__tr-wrapper > .__td': {
        backgroundColor: alpha(theme.palette.white, glassFactor)
      },
      '& .__tr.__depth_1 > .__tr-wrapper > .__td': {
        backgroundColor: alpha(theme.palette.white, glassFactor)
      },
      '& .__tr.__depth_2 > .__tr-wrapper > *.__td': {
        backgroundColor: alpha(theme.palette.white, glassFactor)
      },
      '& .__tr.__depth_3 > .__tr-wrapper > *.__td': {
        backgroundColor: alpha(theme.palette.white, glassFactor)
      },
      '& .__tr.__depth_4 > .__tr-wrapper > *.__td': {
        backgroundColor: alpha(theme.palette.white, glassFactor)
      }
    },
    '& .__color-icon': {
      minHeight: '12px',
      minWidth: '12px',
      maxWidth: '12px',
      maxHeight: '12px',
      marginRight: '5px',
      border: `.5px solid ${theme.palette.gray.dark}`
    }
  }),

  legacy: ({ depthStyles, singleLevel }) => ({
    padding: '0',
    display: 'block',
    minWidth: 0,
    '& .__table': {
      fontSize: '0.85rem',
      borderSpacing: 0,
      '& .__thead': {
        position: 'sticky',
        top: ({ superHeaderRect }) =>
          superHeaderRect ? superHeaderRect.height : 0,
        boxShadow: '0px 6px 12px 12px #FFFFFF',
        overflowY: 'auto',
        overflowX: 'hidden',
        marginBottom: '12px',
        borderBottom: `3px solid ${theme.palette.black}`
      },
      '& .__headerGroup': {
      },
      '& .__th': {
        fontWeight: '800',
        fontSize: '0.875rem',
        padding: '8px 20px 8px 12px',
        userSelect: 'none',
        borderCollapse: 'separate',
        borderSpacing: 0,
        border: `1px solid ${theme.palette.gray.main}`,
        // borderLeft: '1px solid rgba(0,0,0,.1)',
        // borderRight: '1px solid rgba(0,0,0,0.0)',
        backgroundColor: theme.palette.gray.light,
        lineHeight: '30px',
        marginBottom: '-1px',
        '&.__right': {
          textAlign: 'right'
        },
        '&.__center': {
          textAlign: 'center'
        },
        '&.__left': {
          textAlign: 'left'
        },
        '&.__depth_1': {
          padding: '4px 20px 6px 12px',
          borderLeft: '1px solid rgba(0,0,0,0.0)',
          borderRight: '1px solid rgba(0,0,0,0.0)',
          borderBottom: '2px solid #212945'
        },
        '& .__th-content': {
          display: 'flex',
          alignItems: 'center'
        }
      },
      '& .__tbody': {
        overflowX: 'hidden' // rec
      },
      '& .__tr': {},
      '& .__tr:hover .__tr-wrapper': {
        backgroundColor: 'rgba(0, 0, 30, .1)',
        transition: 'background-color .5s ease-in-out .2s'
      },
      '& .__tr.__child': {
        animation: '$tableCellOpen .2s ease-in-out'
      },
      '& .__tr.__depth_0 .__td': {
        fontWeight: singleLevel ? 'inherit' : '800',
        ...(depthStyles?.[0] ?? {})
      },
      '& .__tr.__depth_1': {
        backgroundColor: theme.palette.gray.light,
        '& .__td': depthStyles?.[1] ?? {},
        '& .__tr-wrapper > *:first-child': {
          paddingLeft: '18px'
        }
      },
      '& .__tr.__depth_2': {
        backgroundColor: theme.palette.gray.main,
        '& .__td': depthStyles?.[2] ?? {},
        '& .__tr-wrapper > *:first-child': {
          paddingLeft: '36px'
        }
      },
      '& .__tr.__depth_3': {
        backgroundColor: theme.palette.gray.dark,
        '& .__td': depthStyles?.[3] ?? {},
        '& .__tr-wrapper > *:first-child': {
          paddingLeft: '54px'
        }
      },
      '& .__tr.__depth_4': {
        backgroundColor: theme.palette.gray.darker,
        '& .__td': depthStyles?.[4] ?? {},
        '& .__tr-wrapper > *:first-child': {
          paddingLeft: '72px'
        }
      },
      '& .__td': {
        textAlign: 'left',
        padding: '6px 20px 6px 12px',
        fontWeight: '400',
        transition: 'background-color .2s ease-in-out 0s',
        '&.__right': {
          textAlign: 'right'
        },
        '& .__cell': {
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis'
        }
      },
      '& .__expand-container': {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        '& > *:first-child': {
          margin: '0 8px 0 0'
        },
        '& .__expand-item': {
          overflow: 'hidden'
        }
      },
      '& .__expand-handle': {
        animation: '$fadey .25s ease-in-out'
      },
      '& .__sort-icon': {
        padding: '0 0px 0 2px',
        '& .MuiSvgIcon-root': {
          transform: 'translateY(3px)'
        }
      },
      '& .__benchmark': {
        color: theme.palette.darkGrey,
        paddingTop: '4px',
        fontWeight: 'normal',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        width: '100%'
      }
    },
    '& .__sticky': {
      overflow: ({ hasHorizontalOverflow }) =>
        hasHorizontalOverflow ? 'auto' : 'unset',
      '& .__thead': {
        position: 'sticky',
        overflowX: 'unset !important',
        overflowY: 'unset !important',
        zIndex: 1,
        top: 0,
        boxShadow: '0px 3px 3px #ccc'
      },
      '& .__tbody': {
        position: 'relative',
        overflowX: 'unset !important',
        zIndex: 0
      },
      '& [data-sticky-td]': {
        position: 'sticky !important'
      },
      '& [data-sticky-last-left-td]': {
        borderRight: '1px solid rgba(0,0,0,0.0)'
      },
      '& [data-sticky-first-right-td]': {
        borderLeft: '1px solid rgba(0,0,0,.1)'
      },
      '& .__tr.__depth_0 > .__tr-wrapper > .__td': {
        backgroundColor: theme.palette.white
      },
      '& .__tr.__depth_1 > .__tr-wrapper > .__td': {
        backgroundColor: theme.palette.white
      },
      '& .__tr.__depth_2 > .__tr-wrapper > *.__td': {
        backgroundColor: '#F8F8F8'
      },
      '& .__tr.__depth_3 > .__tr-wrapper > *.__td': {
        backgroundColor: theme.palette.white
      },
      '& .__tr.__depth_4 > .__tr-wrapper > *.__td': {
        backgroundColor: theme.palette.gray.darker
      }
    },
    '& .__color-icon': {
      minHeight: '12px',
      minWidth: '12px',
      maxWidth: '12px',
      maxHeight: '12px',
      marginRight: '5px',
      border: `.5px solid ${theme.palette.gray.dark}`
    }
  }),
  sparkRoot: {
    opacity: ({ loading }) => loading ? 1 : 0,
    transition: ({ loading }) => loading ? 'opacity .25s ease-in-out .25s' : 'opacity .1s ease-in-out',
    height: '2px'
  }
}))
