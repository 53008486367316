import React, { useState } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core'
import SydButton from '../../../../../commonDesign/Button'
import { useModifyUserVisibility } from '../../../../../../api/users'

const useStyles = makeStyles((theme) => ({
  actions: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  group: {
    marginBottom: theme.layout.margin.thick,
    display: 'flex',
    flexDirection: 'column',
    gap: theme.layout.gap.medium
  },
  error: {
    backgroundColor: theme.palette.danger.main,
    color: theme.palette.danger.contrast,
    flex: '1 0 auto',
    padding: theme.layout.padding.medium,
    borderRadius: theme.layout.radius.round,
    fontSize: theme.typography.fontSizes.md,
    lineHeight: '20px',
    marginRight: theme.layout.margin.thin
  },
  type: {
    outline: `1px solid ${theme.palette.gray.darker}`,
    borderRadius: theme.layout.radius.round,
    padding: theme.layout.padding.thick,
    fontSize: theme.typography.fontSizes.xxl,
    transition: 'outline .2s ease-in-out, background-color .2s ease-in-out',
    '&:hover': {
      backgroundColor: theme.palette.gray.main,
      cursor: 'pointer'
    },
    '&.__selected': {
      outline: `4px solid ${theme.palette.primary.main}`,
      '&:hover': {
        outline: `4px solid ${theme.palette.primary.main}`
      }
    },
    '& article': {
      fontWeight: theme.typography.weights.light
    }
  }
}))

function ModifyVisibilityForm ({ onCancel, onComplete, user }) {
  const classes = useStyles()
  const [internal, setInternal] = useState(user.internal)
  const { mutateAsync: modifyUserVisibility } = useModifyUserVisibility()
  const [processing, setProcessing] = useState(false)
  const [error, setError] = useState(null)

  return (
    <>
      <div className={classes.form}>
        <div className={classes.group}>
          <div
            className={clsx(classes.type, { __selected: !internal })}
            onClick={() => setInternal(false)}
            role='button'
          >
            <header>Visible</header>
            <article>The user will be visible to the firm.</article>
          </div>
          <div
            className={clsx(classes.type, { __selected: internal })}
            onClick={() => setInternal(true)}
            role='button'
          >
            <header>Internal</header>
            <article>
              <p>The user will be hidden from all users who do not have a visibility of <strong>internal</strong>.</p>
              <p>This does not otherwise affect the privileges of the user.</p>
            </article>
          </div>
        </div>
      </div>
      <div className={classes.actions}>
        {error ? (<div className={classes.error}>{error}</div>) : null}
        <SydButton disabled={processing} variant='ghost' size='md' onClick={onCancel}>Cancel</SydButton>
        <SydButton
          variant='primary'
          size='md'
          processing={processing}
          onClick={async () => {
            try {
              setError(null)
              setProcessing(true)
              const command = {
                userId: user.userId,
                internal
              }
              await modifyUserVisibility(command)
              onComplete(command)
            } catch (err) {
              setError('There was a problem setting the user visibility')
              console.error(err)
            } finally {
              setProcessing(false)
            }
          }}
          icon='warning'
          priority='warning'
        >
          Change User Visibility
        </SydButton>
      </div>
    </>
  )
}

ModifyVisibilityForm.propTypes = {
  onComplete: PropTypes.func,
  onCancel: PropTypes.func,
  user: PropTypes.object
}

export default ModifyVisibilityForm
