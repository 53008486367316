import React, { useEffect, useMemo, useRef } from 'react'
import { makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import { useCurrentClient } from '../../../api/clients'
import FadeIn from '../../molecules/Transitions/FadeIn'
import ErrorBoundary from '../../molecules/ErrorBoundary'
import { useSetScrollPosition } from '../MainHeader/HeaderScrollContext'
import { HomeDashboardProvider } from './HomeDashboardContext'

const useStyles = makeStyles((theme) => ({
  advancedDashboardLayout: {
    margin: 'inherit',
    position: 'absolute',
    width: '100%',
    height: '100%',
    minHeight: '100%',
    overflowY: 'auto',
    top: 0
  },
  layoutBackdrop: ({ backgroundImage, isLoading, backgroundFilter }) => ({
    position: 'absolute',
    width: '100%',
    height: '100%',
    minHeight: '100%',
    overflowY: 'hidden',
    top: 0,
    backgroundImage: `url(${backgroundImage})` || 'url("RiverBack_50.png")',
    backgroundRepeat: 'repeat',
    backgroundSize: 'cover',
    transition: 'filter 2s ease-in-out',
    filter: isLoading ? 'none' : backgroundFilter || 'none'
  }),
  content: {
    position: 'relative',
    marginTop: '60px',
    minHeight: 'calc(100% - 60px)',
    width: '100%'
  },
  loader: {
    width: '100%',
    height: 'calc(100% - 15vh)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& img': {
      height: '15vh'
    }
  }
}))

const useScrollPositionListener = (isLoading) => {
  const scrollRef = useRef(null)
  const { setScrollPosition, scrollPosition } = useSetScrollPosition()

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = scrollRef.current.scrollTop
      setScrollPosition(scrollPosition)
    }

    const scrollContainer = scrollRef.current
    const setScroll = setScrollPosition
    if (scrollContainer) {
      scrollContainer.addEventListener('scroll', handleScroll)
      return () => {
        scrollContainer.removeEventListener('scroll', handleScroll)
        setScroll(0)
      }
    }
  }, [setScrollPosition, isLoading])

  return {
    scrollRef,
    scrollPosition
  }
}

function Layout ({ backgroundImage, logoSrc, logoAlt, children, rootUrl, viewConfig, backgroundFilter, backgroundParallax, style }) {
  const { data: client, isLoading } = useCurrentClient()
  const { scrollRef, scrollPosition } = useScrollPositionListener(isLoading)
  const value = useMemo(() => ({
    client,
    logoSrc,
    logoAlt,
    rootUrl,
    viewConfig
  }), [client, logoSrc, logoAlt, rootUrl, viewConfig])

  const classes = useStyles({ backgroundImage, isLoading, backgroundFilter, scrollPosition })

  return (
    <ErrorBoundary name='Client Dashboard'>
      <div className={classes.layoutBackdrop} key='layout_backdrop' style={{ backgroundPositionY: backgroundParallax ? -scrollPosition * backgroundParallax : 'bottom center', ...(style || {}) }} />
      <HomeDashboardProvider value={value}>
        <div ref={scrollRef} className={classes.advancedDashboardLayout}>
          {isLoading ? (
            <FadeIn className={classes.loader} key='logo_loader' duration={2.5} delay={2}>
              <img src={logoSrc} alt={logoAlt} />
            </FadeIn>
          ) : (
            <FadeIn className={classes.content} key='layout_content'>
              {children}
            </FadeIn>
          )}
        </div>
      </HomeDashboardProvider>
    </ErrorBoundary>
  )
}

Layout.propTypes = {
  backgroundImage: PropTypes.string,
  logoSrc: PropTypes.string,
  logoAlt: PropTypes.string,
  children: PropTypes.node,
  rootUrl: PropTypes.string,
  viewConfig: PropTypes.object,
  backgroundFilter: PropTypes.string,
  backgroundParallax: PropTypes.number,
  style: PropTypes.object
}

Layout.defaultProps = {
  logoAlt: 'Firm Logo',
  rootUrl: '/views/home-dashboard',
  backgroundFilter: 'none',
  backgroundParallax: null
}

export default Layout
