import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import groupBy from 'lodash/groupBy'
import { Grid } from '@material-ui/core'
import ClientTasks from './ClientTasks'

const GroupByClient = ({ data, take, sort, sortDir, linkBaseUrl, displayType, groupLimit, wrap }) => {
  const grouped = useMemo(() => {
    if (!data.length) return []
    const clients = Object.entries(groupBy(data, 'clientId')).map(([key, value]) => {
      return {
        clientId: key,
        taskCount: value.length,
        hasMoreTasks: value.length > take,
        name: value.length ? value[0].clientLongName : null,
        nameInitial: value.length ? value[0].clientLongName?.substring(0, 1) : null,
        photoUrl: value.length ? value[0].profilePic : null,
        tasks: [...value].slice(0, take).map(t => ({
          ...t,
          url: linkBaseUrl ? `${linkBaseUrl}/${t.taskId}/view` : null
        }))
      }
    })
    clients.sort((a, b) => a[sort] < b[sort] ? -1 * sortDir : sortDir)

    return clients.slice(0, groupLimit)
  }, [data, take, linkBaseUrl, sort, sortDir, groupLimit])

  if (data.length === 0) return null

  return (
    <Grid id='client-grouping' style={{ display: 'flex', flexWrap: wrap }}>
      {grouped.map((client) => {
        client.tasks.sort((a, b) => a.dueDate < b.dueDate ? -1 : 1)
        return <ClientTasks key={client.clientId} take={take} client={client} type={displayType} />
      })}
    </Grid>
  )
}

GroupByClient.propTypes = {
  data: PropTypes.array,
  take: PropTypes.number,
  linkBaseUrl: PropTypes.string,
  displayType: PropTypes.oneOf(['summary', 'details']),
  sort: PropTypes.oneOf(['taskCount', 'name', 'clientId']),
  sortDir: PropTypes.oneOf([1, -1]),
  groupLimit: PropTypes.number,
  wrap: PropTypes.string
}

GroupByClient.defaultProps = {
  take: 10,
  linkBaseUrl: null,
  displayType: 'details',
  sort: 'name',
  sortDir: 1,
  groupLimit: 1000000,
  wrap: 'wrap'
}

export default GroupByClient
