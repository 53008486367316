import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { useAccountStatusDetails } from '../../hooks/accountStatus'
import OperationalTable, { useOperationalTable } from '../../../OperationalTable'
import { ADMIN_ROUTES } from '../../../../../constants'
import { useAutoOperationalTableContext } from '../../../OperationalTable/AutoOperationalTableContext'
import { useGroupingContext } from '../../../GroupingProvider/GroupingContext'
import { useConfigColumns } from '../../../../../hooks/useConfigColumns'

import { accountStatusPropTypes } from './AccountStatusProps'

const AccountStatusTable = ({
  dataSourceIds,
  detailsData,
  searchText,
  columnsConfig,
  format
}) => {
  const [hideColums, setHideColumns] = useState(['closedDate'])
  const history = useHistory()
  const {
    headerProps,
    total
  } = detailsData

  const { defaultSort, columns } = useConfigColumns({ columnsConfig, format })
  const {
    defaultPageSize,
    pageIndex,
    pageSize,
    sort,
    onPagingChange,
    onSortingChange,
    onTableModeChange,
    tableMode
  } = useOperationalTable(defaultSort)
  const { data, loading } = useAccountStatusDetails(dataSourceIds, headerProps.selectedFilter.value, {
    pageIndex,
    pageSize,
    sort,
    searchText,
    tableMode
  })

  const { tableProps } = useAutoOperationalTableContext()
  const { setPerformanceTableProps } = useGroupingContext()

  const onRowClick = ({ original }) => {
    history.push(`${ADMIN_ROUTES.ACCOUNTS}/${original.accountId}`)
  }

  useEffect(() => {
    setHideColumns(['closedDate'])

    if (headerProps?.selectedFilter?.value?.isClosed) {
      setHideColumns([])
    }
  }, [headerProps.selectedFilter])

  useEffect(() => {
    if (tableProps) {
      setPerformanceTableProps((prevState) => ({
        ...prevState,
        ...tableProps
      }))
    }
  }, [tableProps, setPerformanceTableProps])

  return (
    <OperationalTable
      mode='server'
      columns={columns}
      data={data}
      defaultPageSize={defaultPageSize}
      defaultSort={defaultSort}
      itemName='Accounts'
      loading={loading}
      total={total}
      searchText={searchText}
      onSortingChange={onSortingChange}
      onPagingChange={onPagingChange}
      onTableModeChange={onTableModeChange}
      onRowClick={onRowClick}
      hiddenColumns={hideColums}
    />
  )
}

AccountStatusTable.propTypes = {
  ...accountStatusPropTypes,
  detailsData: PropTypes.object,
  searchText: PropTypes.string
}

AccountStatusTable.Wrapper = OperationalTable.Wrapper
AccountStatusTable.SuperHeader = OperationalTable.SuperHeader
AccountStatusTable.Search = OperationalTable.Search

export default AccountStatusTable
