import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import { InputBase, makeStyles, MenuItem, Select } from '@material-ui/core'
import clsx from 'clsx'
import { ICON_NAMES } from '../../../constants'
import Icon from '../../atoms/Icon'
import { mapInputStyles } from './styles'

const useStyles = makeStyles((theme) => ({
  inputRoot: ({ size }) => ({
    minWidth: size === 'xs' ? 'fit-content' : '300px',
    width: '100%',
    border: `1px solid ${theme.palette.gray.dark}`,
    borderRadius: '4px',
    backgroundColor: theme.palette.white,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    transition: 'outline 200ms ease-in-out',
    outline: '1px solid transparent',
    '&:has(:-internal-autofill-selected)': {
      backgroundColor: 'rgb(232, 240, 254)'
    },
    '&:focus-within': {
      outline: `1px solid ${theme.palette.primary.main}`
    }
  }),
  input: mapInputStyles(theme),
  select: {
    borderRadius: '4px'
  },
  sydMenuPaper: {
    boxShadow: '0px 4px 12px 0px rgba(24, 27, 53, 0.2)'
  },
  sydMenuList: ({ size }) => ({
    borderRadius: '8px',
    padding: 0,
    minWidth: size === 'xs' ? 'fit-content' : '300px'
  }),
  sydMenuItem: {
    background: '#fff',
    padding: '12px 16px',
    fontSize: '14px',
    '&.Mui-selected': {
      fontWeight: 700,
      background: '#F5F6F7'
    }
  },
  placeholder: {
    color: theme.palette.gray.dark,
    fontSize: '14px'
  }
}))

const SydSelect = forwardRef(function SydSelect ({
  className,
  name,
  onChange = noop,
  onBlur = noop,
  value: _value,
  placeholder,
  defaultValue,
  disabled,
  size,
  options,
  multiple
}, ref) {
  const classes = useStyles({ size, disabled })
  const inputRef = useRef(null)
  const [value, setValue] = useState(defaultValue ?? null)
  useImperativeHandle(ref, () => ({
    clear: () => setValue(defaultValue),
    focus: () => inputRef.current.focus(),
    value
  }), [value, defaultValue, inputRef])

  const handleChange = useCallback((e) => {
    setValue && setValue(e.target.value)
    onChange(e)
  }, [setValue, onChange])

  const handleBlur = useCallback((e) => {
    setValue && setValue(e.target.value)
    onBlur(e)
  }, [setValue, onBlur])

  useEffect(() => {
    if (_value === undefined) return
    if (value === _value) return
    setValue(_value)
  }, [_value, value, setValue])

  return (
    <div>
      <Select
        displayEmpty
        name={name}
        ref={inputRef}
        onChange={handleChange}
        onBlur={handleBlur}
        placeholder={placeholder}
        disabled={disabled}
        fullWidth
        autoWidth
        multiple={multiple}
        value={_value}
        classes={{
          root: clsx(
            classes.sydSelect,
            { [classes.disabled]: disabled }
          ),
          select: classes.select
        }}
        input={<InputBase classes={{ root: classes.inputRoot, input: classes.input }} placeholder={placeholder} />}
        IconComponent={(props) => (
          <Icon additionalClasses={props.className} name={ICON_NAMES.chevronDown} customSize='20px' margin='2px 8px' />
        )}
        renderValue={(selected) => {
          if (placeholder && (!selected || (Array.isArray(selected) && !selected.length))) {
            return (
              <span className={classes.placeholder}>{placeholder}</span>
            )
          }
          if (multiple) {
            return selected.map(x => {
              const matchingOption = options.find(opt => (opt?.value ?? opt) === x)
              if (matchingOption) {
                return matchingOption.label ?? matchingOption
              }
              return x
            }).join(', ')
          }
          const matchingOption = options.find(opt => (opt?.value ?? opt) === selected)
          if (matchingOption) {
            return matchingOption.label ?? matchingOption
          }
          return selected
        }}
        MenuProps={{
          getContentAnchorEl: null,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left'
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left'
          },
          classes: {
            paper: classes.sydMenuPaper,
            list: classes.sydMenuList
          }
        }}
      >
        {options?.map((option) => {
          return (
            <MenuItem
              key={option.key || option.value || option}
              value={option.value || option}
              disabled={option?.isDisabled}
              classes={{
                root: classes.sydMenuItem,
                selected: classes.sydMenuItemSelected
              }}
            >
              {option.label || option}
            </MenuItem>
          )
        })}
      </Select>
    </div>
  )
})

SydSelect.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  value: PropTypes.oneOfType(
    [PropTypes.string, PropTypes.number, PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number]))]
  ),
  placeholder: PropTypes.string,
  defaultValue: PropTypes.string,
  disabled: PropTypes.bool,
  size: PropTypes.oneOf(['xs', 'sm', 'lg']),
  options: PropTypes.array,
  multiple: PropTypes.bool
}

SydSelect.defaultProps = {
  defaultValue: '',
  disabled: false,
  size: 'lg'
}

export default SydSelect
