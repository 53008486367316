import React from 'react'
import noop from 'lodash/noop'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import InsertEmoticonIcon from '@material-ui/icons/InsertEmoticon'
import AttachFileOutlinedIcon from '@material-ui/icons/AttachFileOutlined'
import PhotoCameraOutlinedIcon from '@material-ui/icons/PhotoCameraOutlined'
import { EMOJI_PICKER_THEMES } from '../../constants'
import EmojiPicker from './EmojiPicker'

const useStyles = makeStyles({
  actions: {
    display: 'flex',
    marginLeft: 'auto',
    '& svg': {
      fontSize: '1rem',
      cursor: 'pointer',
      marginLeft: '0.5rem'
    }
  },
  actionDisabled: {
    opacity: '50%',
    cursor: 'not-allowed !important'
  }
})

const NoteCommentActions = ({
  emojiPickerOpen,
  toggleEmojiPicker,
  onEmojiSelectHandler
}) => {
  const classes = useStyles()
  return (
    <div className={classes.actions}>
      <EmojiPicker
        open={emojiPickerOpen}
        toggle={toggleEmojiPicker}
        theme={EMOJI_PICKER_THEMES.light}
        onSelect={onEmojiSelectHandler}
      >
        <InsertEmoticonIcon />
      </EmojiPicker>
      <PhotoCameraOutlinedIcon className={classes.actionDisabled} />
      <AttachFileOutlinedIcon className={classes.actionDisabled} />
    </div>
  )
}

NoteCommentActions.propTypes = {
  emojiPickerOpen: PropTypes.bool,
  toggleEmojiPicker: PropTypes.func,
  onEmojiSelectHandler: PropTypes.func
}

NoteCommentActions.defaultProps = {
  emojiPickerOpen: false,
  toggleEmojiPicker: noop,
  onEmojiSelectHandler: noop
}

export default NoteCommentActions
