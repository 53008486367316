import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import ViewTabs from '../../../organisms/AdvisorView/components/AdvisorViewTabs'
import { usePolicies } from '../../../../hooks'
import GeneralTab from './GeneralTab'
import AssignmentsTab from './AssignmentsTab'

const tabOptions = {
  general: {
    value: 'general',
    label: 'Details'
  },
  assignments: {
    value: 'assignments',
    label: 'Assignments'
  }
}

const useTabOptions = () => {
  const policies = usePolicies()
  return useMemo(() => {
    const result = {}
    if (policies.admin_view_managers) {
      result.general = tabOptions.general
    }
    if (policies.admin_view_accts) {
      result.assignments = tabOptions.assignments
    }
    return result
  }, [policies])
}

function ManagerTabs ({ disabled }) {
  const tabs = useTabOptions()

  return (
    <div>
      <ViewTabs.Group tabOptions={tabs}>
        <div>
          <ViewTabs.Links
            disabled={disabled}
            tabsKey='admin_manager_details'
          />
        </div>
        {tabs.general ? (
          <ViewTabs.Tab value='general'>
            <GeneralTab />
          </ViewTabs.Tab>
        ) : null}
        {tabs.assignments ? (
          <ViewTabs.Tab value='assignments'>
            <AssignmentsTab />
          </ViewTabs.Tab>
        ) : null}
      </ViewTabs.Group>
    </div>
  )
}

ManagerTabs.propTypes = {
  disabled: PropTypes.bool
}

ManagerTabs.defaultProps = {
  disabled: false
}

export default ManagerTabs
