import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { useObjectMappingById } from '../../../../api/ingestion'
import ObjectMappingHeader from './ObjectMappingHeader'
import ObjectMappingSources from './ObjectMappingSources'
import ObjectFieldMappings from './ObjectFieldMappings'
import ObjectMappingTransformations from './ObjectMappingTransformations'
import ObjectMappingAugmentations from './ObjectMappingAugmentations'
import TargetDetail from './TargetDetail'

const useStyles = makeStyles((theme) => ({
  details: {
    '& > *': {
      marginBottom: '3rem'
    }
  }
}))

const ObjectMappingDetails = ({ objectMappingId }) => {
  const classes = useStyles()
  const { data: objectMapping, isLoading } = useObjectMappingById(objectMappingId)

  if (!objectMappingId) {
    return null
  }

  if (isLoading) {
    return (
      <div className={classes.details}>Loading...</div>
    )
  }

  return (
    <div className={classes.details}>
      <ObjectMappingHeader mapping={objectMapping} />
      <ObjectFieldMappings mapping={objectMapping} />
      <ObjectMappingSources mapping={objectMapping} />
      <ObjectMappingTransformations mapping={objectMapping} />
      <ObjectMappingAugmentations mapping={objectMapping} />
      <TargetDetail target={objectMapping?.target} />
    </div>
  )
}

ObjectMappingDetails.propTypes = {
  objectMappingId: PropTypes.number
}

export default ObjectMappingDetails
