import React, { useCallback, useEffect, useState } from 'react'
import { Box, makeStyles } from '@material-ui/core'
import Icon from '../../../atoms/Icon'
import { useSetAppContext } from '../../../../redux/slices/appContext'
import { SALESFORCE_INTEGRATION, ALERT_SEVERITY, ICON_NAMES } from '../../../../constants'
import { integrations } from '../../../../service'
import SnackAlert from '../../../molecules/SnackAlert/SnackAlert'
import Menu from '../../../molecules/Menu'

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: theme.palette.ghostWhite,
    '&:hover': {
      cursor: 'default'
    },
    display: 'flex',
    justifyContent: 'right',
    paddingRight: '4rem'
  },
  menuIcon: {
    display: 'flex',
    float: 'right',
    cursor: 'pointer',
    color: 'dark'
  }
}))

const AdvisorMenuOptions = () => {
  const classes = useStyles()
  const [alert, setAlert] = useState({})
  const setAppContext = useSetAppContext()
  const [refreshData, setRefreshData] = useState(false)

  const syncOnDemand = useCallback(async () => {
    setAlert({ openAlert: true, alertMessage: 'Refreshing data', alertSeverity: ALERT_SEVERITY.success })
    integrations[SALESFORCE_INTEGRATION].syncOnDemand()
      .then(() => {
        setTimeout(() => {
          setRefreshData(true)
        }, 2000)
      })
      .catch((ex) => { console.error(ex) })
  }, [setAlert])

  useEffect(() => {
    setAppContext({ refetchData: refreshData })
    return () => setAppContext({ refetchData: false })
  }, [refreshData, setAppContext])

  const getThreadMenuOptions = useCallback(
    () => [
      {
        iconName: ICON_NAMES.database,
        label: 'Refresh Data',
        onClick: syncOnDemand
      }
    ],
    [syncOnDemand]
  )

  const options = getThreadMenuOptions()

  const renderMenuIcon = useCallback(
    ({ setAnchorEl }) => (
      <div className={classes.menuIcon} onClick={setAnchorEl}>
        <Icon name={ICON_NAMES.threeDots} customSize='2rem' />
      </div>
    ),
    [classes.menuIcon]
  )

  return (
    <div>
      <Box className={classes.paper} elevation={0}>
        <Menu options={options}>{renderMenuIcon}</Menu>
        <SnackAlert alert={alert} />
      </Box>
    </div>
  )
}

export default AdvisorMenuOptions
