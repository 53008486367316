import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import config from '../../../config'

function ActivationRedirect () {
  const location = useLocation()
  useEffect(() => {
    const params = new URLSearchParams(location.search)
    const token = params.get('token')
    window.location.href = `${config.oktaUrl}/tokens/${token}/verify`
  }, [location])

  return null
}

export default ActivationRedirect
