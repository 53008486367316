import React, { useCallback, useContext, useRef, useState } from 'react'
import { CircularProgress, makeStyles, useTheme } from '@material-ui/core'
import PropTypes from 'prop-types'
import { useFieldArray, useForm } from 'react-hook-form'
import FadeIn from '../../../../../molecules/Transitions/FadeIn'
import PersonalSpace from '../../../shared/PersonalSpace'
import SydButton from '../../../../../commonDesign/Button'
import SectionScreen from '../../../shared/SectionScreen'
import EditButton from '../../../shared/EditButton'
import SectionHeader from '../../../shared/SectionHeader'
import SaveCancelFooter from '../../../shared/SaveCancelFooter'
import Card from '../../../../../molecules/Card'
import { useModifyTargetModelComponentsMutation } from '../../../../../../api/rebalancer'
import { useSectionEditing, useTargetModelContext } from '../TargetModelProvider'
import { useFormattingContext } from '../../../../../organisms/FormattingProvider/FormattingContext'
import AddComponentModelDialog from './AddComponentModelDialog'
import EditComponentModelDialog from './EditComponentModelDialog'

const useStyles = makeStyles((theme) => ({
  tabBody: {
    padding: '20px 0',
    maxWidth: '1400px'
  },
  composition: {
    tableLayout: 'fixed',
    borderCollapse: 'collapse',
    width: '100%',
    '& th': {
      borderBottom: '1px solid black',
      textAlign: 'left',
      fontWeight: theme.typography.weights.bold,
      padding: `0 ${theme.layout.padding.medium}`
    },
    '& tr:nth-child(2n) td': {
      backgroundColor: theme.palette.gray.light
    },
    '& .__total > td': {
      padding: theme.layout.padding.medium,
      borderTop: `1px solid ${theme.palette.gray.darker}`
    },
    '& .__item > td': {
      fontWeight: theme.typography.weights.light,
      padding: theme.layout.padding.medium
    },
    '& .__actions': {
      display: 'flex',
      flexDirection: 'row',
      gap: '10px'
    }
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    height: '50px'
  },
  error: {
    textAlign: 'right',
    color: theme.palette.danger.main,
    backgroundColor: theme.palette.danger.contrast,
    marginBottom: theme.layout.margin.thick
  },
  itemGroup: {
    marginBottom: theme.layout.margin.thick,
    '& header': {
      fontSize: theme.typography.fontSizes.h5,
      padding: theme.layout.padding.medium
    }
  },
  actionGroup: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.layout.gap.medium,
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  grandTotal: {
    fontSize: theme.typography.fontSizes.h5,
    display: 'flex',
    flexDirection: 'row',
    gap: theme.layout.gap.medium,
    alignItems: 'center',
    justifyContent: 'space-between',
    transition: 'background-color .3s ease-in-out'
  }
}))

function ComponentModel ({ item, onRemove, index, editing, formatter }) {
  const templateContext = useContext(TemplateContext)
  return (
    <tr className='__item'>
      <td>{item.componentModelId}</td>
      <td>{item.name}</td>
      <td>{item.weight} Bps</td>
      <td>{formatter(item.weight / 10000, percentFormat)}</td>
      {editing ? (
        <td>
          <div className='__actions'>
            <SydButton size='sm' variant='outline' onClick={() => templateContext.editRef.current.open(item, index)}>Edit</SydButton>
            <SydButton size='sm' variant='outline' priority='warning' onClick={onRemove}>Remove</SydButton>
          </div>
        </td>
      ) : <td />}
    </tr>
  )
}

ComponentModel.propTypes = {
  item: PropTypes.any,
  onRemove: PropTypes.func,
  index: PropTypes.number,
  editing: PropTypes.bool,
  formatter: PropTypes.func
}

function TableHeader ({ editing }) {
  return (
    <thead>
      <tr>
        <th>Component Model ID</th>
        <th>Name</th>
        <th>Weight</th>
        <th>%</th>
        <th>Actions</th>
      </tr>
    </thead>
  )
}

TableHeader.propTypes = {
  editing: PropTypes.bool
}

const TemplateContext = React.createContext({})

const useSubmitter = (form, onComplete) => {
  const { handleSubmit } = form
  const [processing, setProcessing] = useState(false)
  const { mutateAsync: modifyComponents } = useModifyTargetModelComponentsMutation()
  const onSubmit = useCallback(async (formData) => {
    const command = {
      targetModelId: formData.targetModelId,
      components: formData.components
    }

    try {
      setProcessing(true)
      const result = await modifyComponents(command)
      onComplete(result)
    } finally {
      setProcessing(false)
    }
  }, [modifyComponents, setProcessing, onComplete])

  const submitter = useCallback(async (e) => {
    const onValid = async (form) => {
      await onSubmit(form)
    }
    const onInvalid = (errors) => {
      console.error(errors)
    }

    const handler = handleSubmit(onValid, onInvalid)
    await handler(e)
  }, [handleSubmit, onSubmit])

  return {
    submitter,
    processing
  }
}

function validate (values) {
  const errors = {}
  const totalWeight = values.components.reduce((p, x) => p + x.weight, 0)
  if (totalWeight !== 10000) {
    errors.components = { type: 'bad-weight', message: 'The total weight must be exactly 10000 Bps' }
  }

  return { values, errors }
}

const percentFormat = '0,0.00%'

function CompositionTab () {
  const classes = useStyles()
  const theme = useTheme()
  const { targetModel, isFetching, editing, editSection, cancelEdit } = useTargetModelContext()

  const { reset, ...form } = useForm({
    mode: 'onChange',
    defaultValues: {
      targetModelId: targetModel.targetModelId,
      components: targetModel.components
    },
    resolver: validate
  })
  const { append, remove, update, fields: components } = useFieldArray({
    control: form.control,
    name: 'components'
  })

  const onAddComponentModel = useCallback((componentModel) => {
    append(componentModel)
  }, [append])

  const onEditComponentModel = useCallback((componentModel, i) => {
    update(i, componentModel)
  }, [update])

  const { submitter, processing } = useSubmitter(form, cancelEdit)
  const onCancel = useCallback(() => {
    reset()
    cancelEdit()
  }, [cancelEdit, reset])

  const editRef = useRef()
  const addRef = useRef()

  const sectionIsEditing = useSectionEditing('composition')
  const { formatter } = useFormattingContext()

  if (isFetching) {
    return <CircularProgress />
  }

  const _items = components.map((x, i) => ({ index: i, ...x }))
  const totalWeight = _items.reduce((p, c) => p + c.weight, 0)
  const totalPercent = formatter(totalWeight / 10000, percentFormat)

  return (
    <>
      <FadeIn className={classes.tabBody}>
        <SectionScreen editing={editing} sectionIsEditing={sectionIsEditing}>
          <SectionHeader text='Target Model Composition'>
            <div>
              <EditButton
                policy='admin_models_edit'
                editing={editing}
                onClick={() => editSection({
                  section: 'composition'
                })}
              />
            </div>
          </SectionHeader>
          <TemplateContext.Provider value={{ editRef, addRef }}>
            <div className={classes.header}>
              <p>
                Here you can modify the composition of the Target Model. Target Models are composed of Component Models.
                The weights of the component models must add up to 10,000 Bps.
              </p>

            </div>

            <Card className={classes.itemGroup}>
              <div className={classes.actionGroup}>
                <header>Components</header>
                {sectionIsEditing ? (
                  <FadeIn>
                    <SydButton icon='add' size='sm' variant='outline' onClick={() => addRef.current.open(null)}>Add Component Model</SydButton>
                  </FadeIn>
                ) : null}
              </div>
              <table className={classes.composition}>
                <TableHeader />
                <tbody>
                  {components.map((t, i) => (
                    <ComponentModel
                      index={i} key={t.id} item={t} onRemove={() => remove(i)} editing={sectionIsEditing}
                      formatter={formatter}
                    />
                  ))}
                  <tr className='__total'>
                    <td>Total</td>
                    <td />
                    <td>{totalWeight} Bps</td>
                    <td>{totalPercent}</td>
                    <td />
                  </tr>
                </tbody>
              </table>
            </Card>

            <Card
              className={classes.grandTotal} style={{
                backgroundColor: sectionIsEditing
                  ? totalWeight !== 10000
                    ? theme.palette.warning.main
                    : theme.palette.success.main
                  : theme.palette.background.default
              }}
            >
              <div>Total of All Items</div>
              <div>{totalWeight} Bps ({totalPercent})</div>
            </Card>
            {form.formState.errors.components ? (
              <FadeIn className={classes.error}>{form.formState.errors.components.message}</FadeIn>
            ) : null}
            <PersonalSpace />
            <AddComponentModelDialog onAdd={onAddComponentModel} ref={addRef} />
            <EditComponentModelDialog onEdit={onEditComponentModel} ref={editRef} />
          </TemplateContext.Provider>
        </SectionScreen>
      </FadeIn>
      {sectionIsEditing ? (
        <SaveCancelFooter
          saveText='Save Details'
          onCancel={onCancel}
          onSave={submitter}
          processing={processing}
          disabled={!form.formState.isValid}
        />
      ) : null}
    </>
  )
}

CompositionTab.propTypes = {
}

export default CompositionTab
