import PropTypes from 'prop-types'
import FeaturedFiltersList from './List'
import FeaturedFiltersItem from './item'

const FeaturedFilters = ({ children }) => {
  return children
}

FeaturedFilters.propTypes = {
  children: PropTypes.node
}

FeaturedFilters.List = FeaturedFiltersList
FeaturedFilters.Item = FeaturedFiltersItem

export default FeaturedFilters
