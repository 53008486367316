import React, { useCallback } from 'react'
import { useSyncExternalStore } from 'use-sync-external-store/shim'
import PropTypes from 'prop-types'
import Icon from '../../atoms/Icon'
import SydButton from '../../commonDesign/Button'
import { getSuppression, setSuppression, subscribeSuppression } from '../../../utils/globalSuppression'
import FlexRow from '../../molecules/FlexRow'

const SUPPRESSION_KEY = 'refresh_rate_limit'

function FirmReportGenerating ({ onRefresh, disableSeconds }) {
  const handleClick = useCallback(() => {
    onRefresh()
    setSuppression(SUPPRESSION_KEY, true)
    window.setTimeout(() => setSuppression(SUPPRESSION_KEY, false), disableSeconds * 1000)
  }, [onRefresh, disableSeconds])

  const suppression = useSyncExternalStore(subscribeSuppression, () => getSuppression('refresh_rate_limit'))

  return (
    <FlexRow>
      <Icon name='warning' customSize={20} />
      <span>The report data is generating</span>
      <span>
        <SydButton
          disabled={suppression}
          icon='sync'
          size='xs'
          onClick={handleClick}
        >
          Refresh
        </SydButton>
      </span>
    </FlexRow>
  )
}

FirmReportGenerating.propTypes = {
  onRefresh: PropTypes.func,
  disableSeconds: PropTypes.number
}

FirmReportGenerating.defaultProps = {
  disableSeconds: 10
}

export default FirmReportGenerating
