import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { IconButton, Tooltip } from '@material-ui/core'
import dayjs from 'dayjs'
import { ICON_NAMES } from '../../../constants'
import Icon from '../../atoms/Icon'
import FlexRow from '../../molecules/FlexRow'
import ExportTableDialog from './dialogs/ExportTableDialog'
import CustomizeColumnsDialog from './dialogs/CustomizeColumnsDialog'
import { useTransactionsColumns } from './TransactionsColumnContext'
import { useTransactionsContext } from './TransactionsProvider'

function TransactionsTableActions ({ allowExport, allowColumns }) {
  const exportRef = useRef(null)
  const columnRef = useRef(null)
  const { columnState, setColumnState, availableColumns } = useTransactionsColumns()
  const { transactions, asOfDate } = useTransactionsContext()

  return (
    <>
      <FlexRow style={{ justifyContent: 'flex-end', marginBottom: '-10px' }}>
        {allowExport ? (
          <Tooltip title='Export Table'>
            <IconButton onClick={() => exportRef.current.open(transactions, columnState, availableColumns)}>
              <Icon
                name={ICON_NAMES.download}
                customSize='22px'
                color='black'
              />
            </IconButton>
          </Tooltip>
        ) : null}
        {allowColumns ? (
          <Tooltip title='Customize Columns'>
            <IconButton onClick={() => columnRef.current.open(columnState)}>
              <Icon
                name={ICON_NAMES.viewListConfig}
                customSize='22px'
                color='black'
              />
            </IconButton>
          </Tooltip>
        ) : null}
      </FlexRow>
      <ExportTableDialog
        ref={exportRef}
        defaultValues={{
          exportFileName: `transactions_${dayjs(asOfDate).format('YYYYMMDD')}`
        }}
      />
      <CustomizeColumnsDialog ref={columnRef} onSave={setColumnState} />
    </>
  )
}

TransactionsTableActions.propTypes = {
  allowExport: PropTypes.bool,
  allowColumns: PropTypes.bool
}

TransactionsTableActions.defaultProps = {
  allowExport: true,
  allowColumns: true
}

export default TransactionsTableActions
