import React, { useCallback, useMemo } from 'react'
import { Box, Grid, TextField } from '@material-ui/core'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { Autocomplete, createFilterOptions } from '@material-ui/lab'
import { useGroupSearch } from '../../../../api/groups'
import theme from '../../../../theme'
import useStyles from '../styles'
import {
  setSelectedCategory,
  setStepNavigation,
  useAddAccountContext
} from '../../../../redux/slices/addAccountContext'
import LoadingPlaceholder from '../LoadingPlaceholder'
import Text from '../../../atoms/Text'
import Divider from '../../../atoms/Divider'
import SydButton from '../../../commonDesign/Button'

const SelectCategory = ({ isExpanded = true }) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const addAccountContext = useAddAccountContext()
  const selectedCategory = useMemo(() => addAccountContext.selectedCategory, [addAccountContext.selectedCategory])
  const options = addAccountContext.config.categorySelectOptions

  const sizing = useMemo(() => !isExpanded
    ? { xs: 12, sm: 6, md: 4, lg: 3 }
    : { xs: 6, sm: 4, md: 3, lg: 2 },
  [isExpanded])

  const onSelectCategory = useCallback((category) => {
    const _selectedCategory = selectedCategory?.groupId === category?.groupId ? null : category
    dispatch(setSelectedCategory({ selectedCategory: _selectedCategory }))
  }, [dispatch, selectedCategory])

  const { data, isFetching } = useGroupSearch({
    skip: 0,
    take: 500,
    filters: {
      levelTypeId: [{ op: 'in', value: [203] }],
      ...(options?.filteredCategoryIds?.length && { groupId: [{ op: 'in', value: options.filteredCategoryIds }] })
    },
    includes: {
      groupType: true
    }
  })

  const displayGroups = useMemo(() => {
    if (!data?.data?.length) {
      return []
    }
    return data.data.map(group => ({ ...group, colorField: group.colorField ?? '#CCCCCC' }))
  }, [data?.data])

  const featuredGroups = useMemo(() => {
    if (!displayGroups?.length) {
      return []
    }

    return displayGroups.slice(0, Math.min(options?.maxFeaturedCategories, displayGroups.length))
  }, [displayGroups, options?.maxFeaturedCategories])

  return (
    <>
      {isFetching ? (
        <LoadingPlaceholder />
      ) : (
        <>
          <div className={classes.modalInner}>
            <div className={classes.modalInnerContent}>
              <Grid container spacing={2} style={{ paddingTop: '16px' }}>
                {featuredGroups?.map((group) => (
                  <Grid item key={group.groupId} {...sizing}>
                    <button
                      key={group.groupId}
                      onClick={() => onSelectCategory(group)}
                      className={classes.squaredButton}
                      style={{
                        opacity: !selectedCategory || selectedCategory?.groupId === group.groupId ? 1 : 0.55,
                        backgroundColor: group.colorField,
                        color: theme.palette.getContrastText(group.colorField)
                      }}
                    >
                      {group.shortName}
                    </button>
                  </Grid>
                ))}
                {displayGroups?.length > featuredGroups?.length && (
                  <Grid item sm={12}>
                    <Divider additionalClasses={classes.divider} />
                    <Text variant='h3' className={classes.otherCategoriesTitle}>{options?.otherCategoriesTitle}</Text>
                    <Autocomplete
                      filterOptions={createFilterOptions({ limit: 50 })}
                      onChange={(_, value) => onSelectCategory(value)}
                      renderInput={(params) => (
                        <>
                          <TextField {...params} label='Search for a category' variant='outlined' />
                        </>
                      )}
                      value={selectedCategory}
                      options={displayGroups}
                      getOptionSelected={(option, value) => option.groupId === value.groupId}
                      getOptionLabel={(option) => option.shortName}
                      renderOption={(option) => (
                        <Box
                          key={option.groupId} display='flex' alignItems='center' gridGap='8px'
                        >
                          <div className={classes.colorCircle} style={{ backgroundColor: option.colorField }} />
                          <Text variant='h3'>{option.shortName}</Text>
                        </Box>
                      )}
                    />
                  </Grid>
                )}
              </Grid>
            </div>
            <div className={classes.modalInnerFooter}>
              <Box display='flex' justifyContent='flex-end' width='100%'>
                <SydButton
                  variant='primary'
                  icon='chevronRight'
                  onClick={() => dispatch(setStepNavigation({ key: 'manual_editDetails' }))}
                  disabled={!selectedCategory}
                >
                  Continue
                </SydButton>
              </Box>
            </div>
          </div>
        </>
      )}
    </>
  )
}

SelectCategory.propTypes = {
  isExpanded: PropTypes.bool
}

export default SelectCategory
