import { jsPDF as JsPDF } from 'jspdf'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import {
  DATE_RANGE_CUSTOM_OPTION_KEYS,
  defaultCustomOptions,
  parseCustomDateRangeRawValue
} from '../../../molecules/RelativeDateSelect'
import { mapTableDataToCSV } from '../GroupingOptions/helpers'

dayjs.extend(utc)

export const FORMAT_TYPES = {
  CSV: { label: 'CSV', value: 'CSV' },
  PDF: { label: 'PDF', value: 'PDF' }
}

export const COLUMN_OPTIONS = {
  VISIBLE_COLUMNS: {
    label: 'Visible Columns',
    value: 'VISIBLE_COLUMNS'
  },
  ALL_COLUMNS: {
    label: 'All Columns',
    value: 'ALL_COLUMNS'
  }
}

const DATE_FORMAT = 'MM/DD/YYYY'

const { CUSTOM } = DATE_RANGE_CUSTOM_OPTION_KEYS

const CUSTOM_OPTION = defaultCustomOptions.find(({ key }) => key === CUSTOM)

export const downloadPDFFile = ({ data, fileName }) => {
  const unit = 'pt'
  const size = 'A4' // Use A1, A2, A3 or A4
  const orientation = 'portrait'
  const doc = new JsPDF(orientation, unit, size)

  doc.setFontSize(12)

  const [headers, ...body] = data
  const content = {
    startY: 30,
    head: [headers],
    body,
    theme: 'grid',
    headStyles: {
      fillColor: [0, 0, 0],
      fontSize: 8
    },
    bodyStyles: {
      fontSize: 8
    }
  }
  doc.autoTable(content)
  doc.save(fileName)
}

export const mapCSVData = (
  performanceTableProps,
  groupingDateRanges,
  includeAllColumns
) => {
  return mapTableDataToCSV(
    performanceTableProps,
    undefined,
    (value) => {
      if (value === CUSTOM_OPTION.label) {
        const customOptionValue = groupingDateRanges.find((dateRangeKey) =>
          dateRangeKey.startsWith(CUSTOM_OPTION.key)
        )
        if (!customOptionValue) return value
        const { startDate, endDate } =
          parseCustomDateRangeRawValue(customOptionValue)
        const startDateFormatted = dayjs.utc(startDate).format(DATE_FORMAT)
        const endDateFormatted = dayjs.utc(endDate).format(DATE_FORMAT)
        return `${startDateFormatted}-${endDateFormatted}`
      }
      return value
    },
    includeAllColumns
  )
}
