import React, { useCallback, useState, useEffect, useMemo } from 'react'
import dayjs from 'dayjs'
import PropTypes from 'prop-types'
import { Box, ClickAwayListener, IconButton, makeStyles } from '@material-ui/core'
import isEmpty from 'lodash/isEmpty'
import EditIcon from '@material-ui/icons/Edit'
import noop from 'lodash/noop'
import { BUTTON_VARIANT, BUTTON_SIZES, ICON_NAMES } from '../../constants'
import RoundedButton from '../atoms/RoundedButton'
import { useToggle } from '../../hooks'
import Icon from '../atoms/Icon'
import Text from '../atoms/Text'
import { createWealthJourneyEntry, deleteWealthJourneyEntry, editWealthJourneyEntry, fetchWealthJourneyEntries } from '../../service'
import Skeleton from '../atoms/Skeleton'
import TableSkeleton from '../atoms/TableSkeleton'
import { modifyByIndex, removeByIndex } from '../../utils'
import { useAppContext } from '../../redux/slices/appContext'
import WealthJourneyCategoryEntry from './WealthJourneyCategoryEntry'

const useStyles = makeStyles((theme) => ({
  container: ({ entriesEmpty }) => ({
    height: '24rem',
    width: '100%',
    border: '2px solid #21294533',
    borderRadius: '0.75rem',
    padding: '1.5rem',
    display: 'flex',
    flexDirection: 'column',
    ...(entriesEmpty ? {} : { cursor: 'pointer' })
  }),
  title: {
    display: 'flex',
    direction: 'row',
    alignItems: 'center',
    fontSize: '1.5rem',
    fontWeight: '700',
    marginBottom: '1.5rem'
  },
  content: ({ editMode }) => ({
    fontSize: '1rem',
    fontWeight: '400',
    lineHeight: '1.6',
    overflowY: 'auto',
    height: '100%',
    ...(editMode ? { marginRight: '-1.5rem' } : {})
  }),
  date: {
    marginLeft: '0.5rem',
    fontSize: '11px',
    color: '#999999'
  },
  highlightItem: {
    display: 'flex'
  },
  addNoteSection: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    borderRadius: '0.75rem',
    justifyContent: 'center',
    flexDirection: 'column',
    '&:hover': {
      backgroundColor: theme.palette.gray.lighter
    }
  }
}))

const WealthJourneyCategory = ({
  title,
  categoryId,
  entryDateYear,
  isLoading: isContainerLoading
}) => {
  const { clientId } = useAppContext()

  const [entries, setEntries] = useState([])
  const [hovered, , toggleHoveredOn, toggleHoveredOff] = useToggle()
  const [isLoading, , toggleIsLoadingOn, toggleIsLoadingOff] = useToggle()
  const [editMode, toggleEditMode, toggleEditModeOn, toggleEditModeOff] = useToggle()

  const entriesEmpty = useMemo(() => !isLoading && isEmpty(entries), [entries, isLoading])

  const classes = useStyles({ editMode, entriesEmpty })

  const fetchEntries = useCallback(async () => {
    try {
      toggleIsLoadingOn()
      const { data: wealthJourneyEntries } = await fetchWealthJourneyEntries({
        entryDateYear: entryDateYear,
        categoryId,
        clientId
      })
      const wealthJourneyEntriesOrdered = wealthJourneyEntries.sort((a, b) => {
        return dayjs(a.createdDate) - dayjs(b.createdDate)
      })
      setEntries(wealthJourneyEntriesOrdered)
    } catch (err) {
      console.error(err)
    } finally {
      toggleIsLoadingOff()
    }
  }, [categoryId, entryDateYear, toggleIsLoadingOn, toggleIsLoadingOff, clientId])

  useEffect(() => {
    fetchEntries()
  }, [fetchEntries])

  useEffect(() => {
    if (!editMode) {
      setEntries(prevEntries => prevEntries.filter(({ isNewEntry }) => !isNewEntry))
    }
  }, [editMode])

  const onAddEntry = useCallback(async (entryText) => {
    try {
      const body = {
        clientId,
        entryText,
        categoryId,
        entryDateYear
      }
      const { data: { raw: { insertId } } } = await createWealthJourneyEntry(body)
      setEntries(prevEntries => {
        const prevEntriesFiltered = prevEntries.filter(({ isNewEntry }) => !isNewEntry)
        return [...prevEntriesFiltered, { entryId: insertId, entryText }]
      })
    } catch (err) {
      console.error(err)
    }
  }, [categoryId, entryDateYear, clientId])

  const onEditEntry = useCallback(async ({ entryId: id, entryText }) => {
    try {
      setEntries(prevEntries => {
        const entryIndex = prevEntries.findIndex(({ entryId }) => entryId === id)
        return modifyByIndex(entryIndex, prevEntries, { entryText })
      })
      await editWealthJourneyEntry(id, { entryText })
    } catch (err) {
      console.error(err)
    }
  }, [])

  const onDeleteEntry = useCallback(async (id) => {
    try {
      setEntries(prevEntries => {
        const entryIndex = prevEntries.findIndex(({ entryId }) => entryId === id)
        return removeByIndex(entryIndex, prevEntries)
      })
      await deleteWealthJourneyEntry(id)
    } catch (err) {
      console.error(err)
    }
  }, [])

  const onDeletePlaceHolderNewEntry = useCallback(() => {
    setEntries(prevEntries => prevEntries.filter(({ isNewEntry }) => !isNewEntry))
  }, [])

  const onToggleAddEntry = useCallback(() => {
    if (!editMode) toggleEditModeOn()
    setEntries(prevEntries => ([
      ...prevEntries,
      { entryId: null, entryText: '', isNewEntry: true }
    ]))
  }, [editMode, toggleEditModeOn])

  const renderedWealthJourneyCardBody = useMemo(() => {
    if (isLoading) {
      return <TableSkeleton rows={4} columns={1} showHeader={false} />
    }

    if (!isEmpty(entries)) {
      return (
        <>
          <div className={classes.content}>
            {entries.map(entry =>
              <WealthJourneyCategoryEntry
                key={`wealth-journey-entry-${entry.entryId}`}
                entry={entry}
                editModeOn={editMode}
                onAddEntry={onAddEntry}
                onEditEntry={onEditEntry}
                onDeleteEntry={onDeleteEntry}
                onDeletePlaceHolderNewEntry={onDeletePlaceHolderNewEntry}
              />)}
          </div>
          {editMode && (
            <Box mt='1rem' alignSelf='start'>
              <RoundedButton
                size={BUTTON_SIZES.extraSmall}
                variant={BUTTON_VARIANT.ghost}
                className={classes.uploadButton}
                onClick={onToggleAddEntry}
                disabled={entries.some(({ isNewEntry }) => isNewEntry)}
              >
                <Box display='flex' mr='0.25rem'>
                  <Icon name={ICON_NAMES.addCircle} customSize='1rem' />
                </Box>
                <Text customFontWeight='bold' lineHeight='inherit' text='Add note' />
              </RoundedButton>
            </Box>
          )}
        </>
      )
    }
    if (entriesEmpty) {
      return (
        <Box className={classes.addNoteSection} onClick={onToggleAddEntry}>
          <Box display='flex' mb='0.5rem'>
            <Icon name={ICON_NAMES.addCircle} customSize='1.5rem' />
          </Box>
          <Text text='Add note' customFontWeight='bold' />
        </Box>
      )
    }
    return null
  }, [
    entries,
    editMode,
    isLoading,
    onAddEntry,
    onEditEntry,
    entriesEmpty,
    onDeleteEntry,
    onToggleAddEntry,
    onDeletePlaceHolderNewEntry,
    classes.content,
    classes.uploadButton,
    classes.addNoteSection
  ])

  return (
    <ClickAwayListener onClickAway={toggleEditModeOff}>
      <div
        className={classes.container}
        onClick={entriesEmpty ? noop : toggleEditModeOn}
        onMouseEnter={toggleHoveredOn}
        onMouseLeave={toggleHoveredOff}
      >
        <div className={classes.title}>
          {isContainerLoading ? (
            <Skeleton height='2rem' width='100%' />
          ) : (
            <>
              {title}
              {
                hovered && !editMode && !entriesEmpty && !isLoading && !isContainerLoading && (
                  <Box ml='auto'>
                    <IconButton aria-label='toggle wealth journey edit mode' size='small' onClick={toggleEditMode}>
                      <EditIcon fontSize='small' />
                    </IconButton>
                  </Box>
                )
              }
            </>
          )}
        </div>
        {renderedWealthJourneyCardBody}
      </div>
    </ClickAwayListener>
  )
}

WealthJourneyCategory.propTypes = {
  title: PropTypes.string.isRequired,
  categoryId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  isLoading: PropTypes.bool,
  entryDateYear: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
}

WealthJourneyCategory.defaultProps = {
  categoryId: undefined,
  isLoading: false
}

export default WealthJourneyCategory
