import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import RelativeDateSelect, { defaultOptions } from '../../molecules/RelativeDateSelect'
import AvailableDatePicker from '../../molecules/AvailableDatePicker'
import { useDates } from '../../../hooks/useDates'
import FlexRow from '../../molecules/FlexRow'
import { useTransactionsContext } from './TransactionsProvider'

const useStyles = makeStyles((theme) => ({
  dp: {
    width: 'auto',
    '& .MuiInputBase-input': {
      lineHeight: '30px',
      height: '30px',
      minWidth: 'unset',
      width: 'unset',
      paddingBottom: '0',
      paddingLeft: theme.layout.padding.p5,
      paddingRight: theme.layout.padding.p5,
      textAlign: 'left',
      transition: 'background-color .2s ease-in-out',
      '&:hover': {
        backgroundColor: theme.palette.gray.light
      }
    }
  },
  dateRangePicker: {
    flexWrap: 'wrap',
    justifyContent: ({ justifyContent }) => justifyContent,
    '& *': {
      fontWeight: `${theme.typography.weights.light} !important`
    }
  }
}))

function TransactionsDateRangePicker ({ dateRangeOptions, justifyContent, style, className }) {
  const classes = useStyles({ justifyContent })
  const { availableDates, onAvailableDateChange } = useDates()
  const { dateRange, setDateRange } = useTransactionsContext()

  return (
    <FlexRow className={clsx(className, classes.dateRangePicker)} style={style}>
      <AvailableDatePicker
        className={classes.dp}
        disableFuture
        value={availableDates.mainDate}
        onChange={onAvailableDateChange}
        format='[As of] MMMM DD, YYYY'
        type='date'
        extraStyles={{
          fontSize: '0.875rem',
          cursor: 'pointer',
          display: 'block'
        }}
      />
      <RelativeDateSelect
        options={dateRangeOptions}
        availableDates={availableDates}
        selectedValue={dateRange}
        onDateRangeSelected={setDateRange}
      />
    </FlexRow>
  )
}

TransactionsDateRangePicker.propTypes = {
  justifyContent: PropTypes.string,
  dateRangeOptions: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.string
  })),
  style: PropTypes.object,
  className: PropTypes.string
}

TransactionsDateRangePicker.defaultProps = {
  justifyContent: 'space-between',
  dateRangeOptions: defaultOptions
}

export default TransactionsDateRangePicker
