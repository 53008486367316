import { useMemo } from 'react'

export const useConfigAtDepth = (config, depth = 0) => {
  return useMemo(() => {
    return config[depth]
  }, [config, depth])
}

export const useStatusInfo = (status, statuses) => {
  const statusInfo = useMemo(() => {
    if (status === null || status === undefined) {
      return null
    }
    return (statuses || []).find(x => x.statusId === status)
  }, [statuses, status])

  return statusInfo
}

function countRecursive (items, countItemsAt, depth = 0, agg = {}) {
  items.forEach((item) => {
    if (!(item.status in agg)) {
      agg[item.status] = 0
    }
    if (!countItemsAt?.length || countItemsAt.includes(depth)) {
      agg[item.status] += 1
    }
    if (item.items?.length) {
      countRecursive(item.items, countItemsAt, depth + 1, agg)
    }
  })

  return agg
}

export const useStatusCounts = (report, countItemsAt = []) => {
  return useMemo(() => {
    if (!report || !report.items?.length) return null

    const result = countRecursive(report.items, countItemsAt)

    return (statusId) => {
      if (statusId in result) {
        return result[statusId]
      }
      return 0
    }
  }, [report, countItemsAt])
}

const findItem = (items, itemId, agg) => {
  return items.reduce((result, item) => {
    if (item.statusReportItemId === itemId) {
      return item
    }
    if (item.items?.length > 0) {
      return findItem(item.items, itemId, result)
    }
    return result
  }, agg)
}

export const useFindItemInTree = (items, itemId) => {
  return useMemo(() => findItem(items, itemId), [items, itemId])
}

export const useItemById = (items, itemId) => {
  return useMemo(() => items.find(x => x.statusReportItemId === itemId), [items, itemId])
}
