import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Box } from '@material-ui/core'
import Text from '../../../atoms/Text'
import NumberTitle from '../components/NumberTitle'
import { getSafeDate } from '../../../../utils'
import { useAvailableDates } from '../../../../redux/slices/appContext'
import { CALC_TYPES, DAYJS_OPERATIONS, DAYJS_UNIT_TYPES, LEVEL_TYPES } from '../../../../constants'
import { useGetGroupedCoreData } from '../../../../api/coreData'

const NetFlowsPreview = ({ advisorId }) => {
  const [availableDates] = useAvailableDates()
  const { query, queryOptions } = useMemo(() => {
    return {
      query: {
        dateRange: {
          startDate: getSafeDate(availableDates, {
            operation: DAYJS_OPERATIONS.SUBTRACT,
            unitType: DAYJS_UNIT_TYPES.DAY,
            unitValue: 30
          }),
          endDate: availableDates.mainDate
        },
        levelFilters: {
          advisorIds: [advisorId],
          levelTypes: [LEVEL_TYPES.ADVISOR],
          calcType: CALC_TYPES.performance
        }
      },
      queryOptions: {
        mapper: (data) => data?.[0] ?? {}
      }
    }
  }, [advisorId, availableDates])

  const { data, isLoading } = useGetGroupedCoreData(query, queryOptions)

  return (
    <Box display='flex' flexDirection='column' style={{ padding: '1rem' }}>
      <Box display='flex' mb='1.5rem'>
        <Text
          text='Net Flows'
          variant='body2'
          customFontSize='1.2rem'
          customFontWeight='bold'
        />
      </Box>
      <Box display='flex' justifyContent='space-between' width='100%'>
        <NumberTitle
          title={data?.netFlows}
          subtitle='In last 30 days'
          isLoading={isLoading}
          titleProps={{ customFontSize: '3.5rem' }}
          containerProps={{ justifyContent: 'end' }}
        />
      </Box>
    </Box>
  )
}

NetFlowsPreview.propTypes = {
  advisorId: PropTypes.number
}

export default NetFlowsPreview
