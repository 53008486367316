import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { makeStyles, useTheme } from '@material-ui/core'
import { ResponsiveLine } from '@nivo/line'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import Loading from '../../../molecules/Loading'
import CustomAreas from '../../../nivo/Lines/CustomAreas'
import CustomLines from '../../../nivo/Lines/CustomLines'
import { useFormattingContext } from '../../FormattingProvider/FormattingContext'
import FadeIn from '../../../molecules/Transitions/FadeIn'
import { useWjSeries2 } from './useWJSeriesData'
import { useAreaHighlightLayer } from './useAreaHighlightLayer'
import { useWealthJourney } from './WealthJourneyProvider'
import { useMilestoneLayer } from './useMilestoneLayer'

dayjs.extend(utc)

const useStyles = makeStyles((theme) => ({
  wjChart: {
    height: ({ height }) => height,
    flex: '1 0 auto',
    '& text': {
      fontFamily: `${theme.typography.fontFamily} !important`
    }
  }
}))

const useChartDefaults = (chartConfig, extents, yScaleBuffer) => {
  const { formatter } = useFormattingContext()
  const axisConfig = useMemo(() => {
    const axisLeftFormat = (x) => formatter(x, chartConfig?.axisLeft?.format || 'human')
    const axisBottomFormat = (y) => formatter(y, chartConfig?.axisBottom?.format || 'tradeDate')

    return {
      axisLeft: { ...(chartConfig?.axisLeft || {}), format: axisLeftFormat },
      axisBottom: { ...(chartConfig?.axisBottom || {}), format: axisBottomFormat }
    }
  }, [chartConfig, formatter])

  return useMemo(() => {
    const yScale = (yScaleBuffer && extents) ? {
      ...(chartConfig?.yScale || {}),
      min: chartConfig?.yScale?.min === 'auto' ? extents.minValue - extents.minValue * yScaleBuffer.min : chartConfig?.yScale?.min,
      max: chartConfig?.yScale?.max === 'auto' ? extents.maxValue + extents.maxValue * yScaleBuffer.max : chartConfig?.yScale?.max
    } : chartConfig?.yScale
    return {
      fontSize: 15,
      animate: false,
      margin: { top: 30, right: 45, bottom: 30, left: 50 },
      xScale: {
        type: 'time',
        format: '%Y/%m/%d',
        precision: 'day',
        useUTC: false // the dates are already in utc, so do not adjust them like they are not
      },
      xFormat: 'time: %b %Y',
      yFormat: ' >-,.0d',
      curve: 'basis',
      colors: (d) => d.color,
      lineWidth: 3,
      enableGridX: false,
      enableGridY: true,
      pointSize: 0,
      useMesh: true,
      motionStiffness: 175,
      motionDamping: 25,
      ...(chartConfig || {}),
      ...(axisConfig || {}),
      yScale
    }
  }, [chartConfig, axisConfig, yScaleBuffer, extents])
}

const useLayers = (selectedYear, start, end, onSelect, milestoneOptions, entryTypes) => {
  const areaHighlightLayer = useAreaHighlightLayer({ selectedYear, start, end, onSelect })

  const entryLayer = useMilestoneLayer(milestoneOptions || {}, entryTypes)
  return useMemo(() => {
    return [
      'grid',
      areaHighlightLayer,
      'axes',
      'markers',
      CustomAreas,
      CustomLines,
      'crosshair',
      'points',
      'slices',
      entryLayer
    ]
  }, [areaHighlightLayer, entryLayer])
}

const useMarkers = (selectedYear, dataEndDate) => {
  const theme = useTheme()
  return useMemo(() => {
    const result = []
    if (dataEndDate) {
      result.push({
        axis: 'x',
        value: dayjs.utc(dataEndDate).startOf('month'),
        lineStyle: { stroke: '#21294599', strokeWidth: 2, strokeDasharray: '5,5' },
        textStyle: { fontFamily: 'GothamPro', fontWeight: 700 }
      })
    }

    result.push({
      axis: 'x',
      value: dayjs.utc(`${selectedYear}-01-01`).add(1, 'year').startOf('year'),
      textStyle: { fontFamily: 'GothamPro' },
      lineStyle: { stroke: theme.palette.primary.main, strokeWidth: 2 }
    })

    result.push({
      axis: 'x',
      value: dayjs.utc(`${selectedYear}-01-01`),
      lineStyle: { stroke: theme.palette.primary.main, strokeWidth: 2 },
      textStyle: { fontFamily: 'GothamPro', fontWeight: 700, alignSelf: 'left', filter: 'opacity(0.8)' },
      legend: selectedYear,
      legendOffsetX: 10,
      legendOrientation: 'horizontal'
    })

    return result
  }, [
    selectedYear,
    dataEndDate,
    theme
  ])
}

function WJLineChart ({
  height,
  baseQuery,
  series,
  includeProjections,
  includeNetAdditions,
  milestoneOptions,
  entryTypes,
  yScaleBuffer,
  ...chartConfig
}) {
  const classes = useStyles({ height })
  const { selectedYear, setSelectedYear } = useWealthJourney()
  const { data: themedData, extents, isLoading, seriesEndDate, seriesStartDate, dataEndDate } = useWjSeries2({ baseQuery })
  const chartConfiguration = useChartDefaults(chartConfig, extents, yScaleBuffer)
  const layers = useLayers(selectedYear, seriesStartDate, seriesEndDate, setSelectedYear, milestoneOptions, entryTypes)
  const markers = useMarkers(selectedYear, dataEndDate)

  if (isLoading) {
    return (
      <div className={classes.wjChart}>
        <Loading />
      </div>
    )
  }

  return (
    <FadeIn className={classes.wjChart}>
      <ResponsiveLine
        {...chartConfiguration}
        layers={layers}
        markers={markers}
        data={themedData}
      />
    </FadeIn>
  )
}

WJLineChart.propTypes = {
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  series: PropTypes.shape({
    endingValue: PropTypes.shape({
      id: PropTypes.string,
      color: PropTypes.string,
      strokeWidth: PropTypes.number,
      options: PropTypes.object
    }),
    netAdditions: PropTypes.shape({
      id: PropTypes.string,
      color: PropTypes.string,
      strokeWidth: PropTypes.number,
      options: PropTypes.object
    }),
    projections: PropTypes.shape({
      id: PropTypes.string,
      color: PropTypes.string,
      strokeWidth: PropTypes.number,
      options: PropTypes.object
    })
  }),
  includeNetAdditions: PropTypes.bool,
  includeProjections: PropTypes.bool,
  milestoneOptions: PropTypes.shape({
    hideLine: PropTypes.bool,
    textBackgroundColor: PropTypes.string,
    rotateText: PropTypes.bool
  }),
  baseQuery: PropTypes.object,
  entryTypes: PropTypes.arrayOf(PropTypes.string),
  yScaleBuffer: PropTypes.shape({
    min: PropTypes.number,
    max: PropTypes.number
  })
}

WJLineChart.defaultProps = {
  height: '300px',
  includeNetAdditions: true,
  includeProjections: true,
  enableSlices: 'x',
  enableCrosshair: true,
  crosshairType: 'cross',
  yScaleBuffer: {
    min: 0,
    max: 0.2
  },
  yScale: {
    type: 'linear',
    stacked: false,
    min: 0,
    max: 'auto'
  }
}

export default WJLineChart
