import React from 'react'
import PropTypes from 'prop-types'
import EntryCard from './Card'
import HorizontalCard from './HorizontalCard'
import HorizontalCompact from './HorizontalCompact'

function EntryListItem ({ type, ...props }) {
  if (type === 'card') {
    return (
      <EntryCard {...props} />
    )
  }

  if (type === 'horizontal-card') {
    return (
      <HorizontalCard {...props} />
    )
  }

  if (type === 'horizontal-compact') {
    return (
      <HorizontalCompact {...props} />
    )
  }

  return (
    <EntryCard {...props} />
  )
}

EntryListItem.propTypes = {
  type: PropTypes.oneOf(['card', 'horizontal-card', 'horizontal-compact'])
}

export default EntryListItem
