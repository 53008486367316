import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import ViewTabs from '../../../organisms/AdvisorView/components/AdvisorViewTabs'
import { usePolicies } from '../../../../hooks'
import GeneralTab from './GeneralTab'
import ClientsTab from './ClientsTab'
import { useWealthOwnerDetails } from './ClientDetailsFormContext'
import ExperienceTab from './ExperienceTab'
import SecurityTab from './SecurityTab'
import IntegrationsTab from './IntegrationsTab'

const useStyles = makeStyles((theme) => ({
  clientDetailTabs: {},
  viewTabs: {
  }
}))

const tabOptions = {
  general: {
    value: 'general',
    label: 'General Info'
  },
  clients: {
    value: 'clients',
    label: 'Clients'
  },
  experiences: {
    value: 'experiences',
    label: 'Experience'
  },
  security: {
    value: 'security',
    label: 'Security'
  },
  integrations: {
    value: 'integrations',
    label: 'Integrations & SSO'
  }
}

const useTabOptions = () => {
  const policies = usePolicies()
  return useMemo(() => {
    const result = {}
    if (policies.admin_view_wo_users) {
      result.general = tabOptions.general
    }
    if (policies.admin_view_wo_users) {
      result.clients = tabOptions.clients
    }
    if (policies.admin_view_wo_users) {
      result.experiences = tabOptions.experiences
    }
    if (policies.admin_view_wo_users) {
      result.security = tabOptions.security
    }
    if (policies.admin_view_wo_integrations) {
      result.integrations = tabOptions.integrations
    }
    return result
  }, [policies])
}

function WealthOwnerDetailTabs ({ onTabChange, disabled }) {
  const classes = useStyles()
  const { cancelEdit } = useWealthOwnerDetails()
  const tabs = useTabOptions()

  return (
    <div className={classes.clientDetailTabs}>
      <ViewTabs.Group tabOptions={tabs} onChange={cancelEdit}>
        <div className={classes.viewTabs}>
          <ViewTabs.Links
            disabled={disabled}
            tabsKey='admin_wealth_owner_details'
          />
        </div>
        {tabs.general ? (
          <ViewTabs.Tab value='general'>
            <GeneralTab />
          </ViewTabs.Tab>
        ) : null}
        {tabs.clients ? (
          <ViewTabs.Tab value='clients'>
            <ClientsTab />
          </ViewTabs.Tab>
        ) : null}
        {tabs.experiences ? (
          <ViewTabs.Tab value='experiences'>
            <ExperienceTab />
          </ViewTabs.Tab>
        ) : null}
        {tabs.security ? (
          <ViewTabs.Tab value='security'>
            <SecurityTab />
          </ViewTabs.Tab>
        ) : null}
        {tabs.integrations ? (
          <ViewTabs.Tab value='integrations'>
            <IntegrationsTab />
          </ViewTabs.Tab>
        ) : null}
      </ViewTabs.Group>
    </div>
  )
}

WealthOwnerDetailTabs.propTypes = {
  onTabChange: PropTypes.func,
  disabled: PropTypes.bool
}

WealthOwnerDetailTabs.defaultProps = {
  onTabChange: () => {},
  disabled: false
}

export default WealthOwnerDetailTabs
