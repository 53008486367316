import React from 'react'
import PropTypes from 'prop-types'
import {
  TextField,
  withStyles
} from '@material-ui/core'
import { NumericFormat } from 'react-number-format'

const CustomTextField = withStyles({
  root: {
    '& fieldset.MuiOutlinedInput-notchedOutline': {
      border: '2px solid',
      borderColor: '#EEF0F8',
      borderRadius: '4px'
    },
    '& .Mui-disabled > .MuiOutlinedInput-notchedOutline': {
      borderColor: '#EEF0F8'
    },
    '& .Mui-disabled': {
      '&:hover': {
        cursor: 'not-allowed'
      }
    }
  }
})(TextField)

function CustomNumericInput ({
  onValueChange,
  suffix,
  value,
  defaultValue,
  isAllowed
}, ref) {
  const materialUITextFieldProps = {
    variant: 'outlined'
  }
  return (
    <NumericFormat
      valueIsNumericString
      suffix={suffix}
      ref={ref}
      value={value}
      defaultValue={defaultValue}
      isAllowed={isAllowed}
      onValueChange={onValueChange}
      customInput={CustomTextField}
      {...materialUITextFieldProps}
    />
  )
}

const NumericInput = React.forwardRef(CustomNumericInput)

CustomNumericInput.propTypes = {
  onValueChange: PropTypes.func,
  isAllowed: PropTypes.func,
  suffix: PropTypes.string,
  value: PropTypes.string,
  defaultValue: PropTypes.string
}

export default NumericInput
