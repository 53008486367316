import React from 'react'
import { makeStyles } from '@material-ui/core'
import ReportRecentTable from '../ReportRecentView/ReportRecentTable/Table'
import ReportRecentProvider from '../ReportRecentView/ReportRecentProvider'
import ReportRecentTableContainer from '../ReportRecentView/ReportRecentTable/TableContainer'
import SystemReportsProvider from './SystemReportsProvider'
import SystemReportsForm from './SystemReportsForm'
import { defaultRecentOutputsColumnConfig } from './ReportSavedTable/columnConfig'

const useStyles = makeStyles(() => ({
  container: {
    paddingTop: '0.5rem'
  }
}))

const ReportSystemView = () => {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <SystemReportsProvider>
        <SystemReportsForm />
        <ReportRecentProvider>
          <ReportRecentTableContainer>
            <ReportRecentTable
              showSavedRequests
              columnConfig={defaultRecentOutputsColumnConfig}
            />
          </ReportRecentTableContainer>
        </ReportRecentProvider>
      </SystemReportsProvider>
    </div>
  )
}

export default ReportSystemView
