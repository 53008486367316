import React from 'react'
import ConfigurableView from '../../ConfigurableView'
import BillingRunDetails from './BillingRunDetails'

function BillingRunDetailsIndex () {
  return (
    <ConfigurableView viewPath='@admin-billing-run-details'>
      <BillingRunDetails />
    </ConfigurableView>
  )
}

export default BillingRunDetailsIndex
