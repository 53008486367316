import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { IconButton, makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import KeyMirror from 'keymirror'
import { ICON_NAMES } from '../../../../../../../../constants'
import Icon from '../../../../../../../atoms/Icon'
import { useSystemReportsContext } from '../../SystemReportsProvider'
import { REPORT_TYPES } from '../../../../../../admin/billing/runBilling/helpers'

const useStyles = makeStyles({
  iconButton: {
    padding: '.25rem'
  },
  disabled: {
    opacity: '0.5'
  }
})

const REPORT_STATUSES = KeyMirror({
  pending: null
})

const ReportActions = ({ row: { original } }) => {
  const classes = useStyles()
  const { toggleExportDialog, setExportReportMeta } = useSystemReportsContext()

  const onClick = useCallback(() => {
    setExportReportMeta(original)
    toggleExportDialog.on()
  }, [original, setExportReportMeta, toggleExportDialog])

  const disabled = !original.type.startsWith(REPORT_TYPES.bobi) || original.status === REPORT_STATUSES.pending

  const classNames = clsx(classes.iconButton, {
    [classes.disabled]: disabled
  })

  return (
    <IconButton className={classNames} onClick={onClick} disabled={disabled}>
      <Icon name={ICON_NAMES.download} customSize='1.25rem' color='black' />
    </IconButton>
  )
}

ReportActions.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.object
  })
}

export default ReportActions
