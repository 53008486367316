import React from 'react'
import PropTypes from 'prop-types'
import { IconButton, Tooltip, makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import Icon from '../../../atoms/Icon'
import { ICON_NAMES } from '../../../../constants'
import useRedTailDataSync from '../hooks/useRedTailDataSync'

const useStyles = makeStyles(() => ({
  rotateIcon: {
    animation: '$spin 2s linear infinite'
  },
  '@keyframes spin': {
    '0%': {
      transform: 'rotate(360deg)'
    },
    '100%': {
      transform: 'rotate(0deg)'
    }
  }
}))

const RedTailSyncButton = ({ clientId, integrationName }) => {
  const classes = useStyles()
  const {
    syncRedTailAccounts,
    isSyncInProgress,
    isSyncFeatureActive
  } = useRedTailDataSync(clientId)

  if (!isSyncFeatureActive) return null

  return (
    <Tooltip title={`Sync data to ${integrationName}`}>
      <IconButton
        onClick={syncRedTailAccounts}
        disabled={isSyncInProgress}
      >
        <Icon
          name={ICON_NAMES.sync}
          customSize='1.5rem'
          additionalClasses={clsx({
            [classes.rotateIcon]: isSyncInProgress
          })}
        />
      </IconButton>
    </Tooltip>
  )
}

RedTailSyncButton.propTypes = {
  clientId: PropTypes.number.isRequired,
  integrationName: PropTypes.string
}

export default RedTailSyncButton
