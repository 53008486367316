import React, { useCallback, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles, Box } from '@material-ui/core'
import noop from 'lodash/noop'
import { useDates } from '../../../../hooks/useDates'
import RelativeDateSelect, {
  DATE_RANGE_CUSTOM_OPTION_KEYS,
  defaultOptions,
  getCalendarYearOptions
} from '../../../molecules/RelativeDateSelect'
import GroupingProvider from '../../../organisms/GroupingProvider'
import AvailableDatePicker from '../../../molecules/AvailableDatePicker'
import GroupingOptions from '../../../organisms/GroupingProvider/GroupingOptions'
import Select from '../../../molecules/Select'
import { useSelectedDateRange } from '../../../../hooks'
import CustomDateRangeSelector from '../../../organisms/GroupingProvider/CustomDateRangeSelector'
import TransactionsTable from './TransactionsTable'
import { useColumnConfig } from './columnConfig'
import FeaturedFilters from './FeaturedFilters'

import TransactionTableFilters from './TransactionTableFilters'
import { mapTransactionsFilters } from './helpers'

const useStyles = makeStyles(() => ({
  table: {
    '& .__header': {
      paddingBottom: '0'
    },
    '& .__header-content': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center'
    },
    '& .__header-content-options': {
      marginLeft: 'auto'
    },
    '& .__header-filters': {
      margin: '1rem 0'
    }
  },
  tableHeader: {
    zIndex: 3
  },
  featuredFilters: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%'
  },
  dateControls: {
    display: 'flex',
    alignItems: 'flex-end'
  },
  asOfDatePicker: {
    '& input': {
      width: '13rem'
    }
  }
}))

const TransactionsTableContainer = ({
  filters,
  maxFilters,
  columnConfig,
  defaultFilter,
  defaultPageSize,
  defaultDateRange,
  enableFirmWideView,
  onChangeSearchParams,
  hideFeaturedFilters,
  dateRangeOptions,
  defaultTagValues,
  defaultAssetValues,
  defaultCustodianValues,
  defaultTransactionCodeTag,
  defaultTransactionCodeTagFilter
}) => {
  const classes = useStyles()
  const [searchText, setSearchText] = useState('')
  const [transactionCodeTag, setTransactionCodeTag] = useState(
    defaultTransactionCodeTag
  )

  const { columns, defaultSort } = useColumnConfig(columnConfig)
  const { availableDates, loadingAvailableDates, onAvailableDateChange } = useDates()

  const {
    dateRange,
    selectedDateRange,
    setSelectedDateRange,
    value: featuredDateRange,
    selectedCustomDateRange,
    setSelectedCustomDateRange,
    isCustomDateRangeSelected
  } = useSelectedDateRange(defaultDateRange, availableDates, dateRangeOptions)

  useEffect(() => {
    const { transactionCodeTagId } = transactionCodeTag || {}
    if (transactionCodeTagId) {
      const searchParams = { transactionCodeTagId }
      onChangeSearchParams(searchParams)
    }
  }, [onChangeSearchParams, transactionCodeTag])

  useEffect(() => {
    if (featuredDateRange) {
      onChangeSearchParams({ dateRange: featuredDateRange })
    }
  }, [onChangeSearchParams, featuredDateRange])

  const onSearchTextChange = useCallback(
    (value) => {
      setSearchText(value)
    },
    [setSearchText]
  )

  const transactionTableFilters = useMemo(() => {
    return mapTransactionsFilters(transactionCodeTag, filters, searchText)
  }, [filters, searchText, transactionCodeTag])

  const {
    transactionsCodeFilters,
    transactionsCodeCountFilters
  } = useMemo(() => {
    const { groupId } = transactionTableFilters
    const {
      transactionCodeTagId,
      ...defaultCodeCountFilters
    } = defaultTransactionCodeTagFilter
    return {
      transactionsCodeFilters: { groupId, transactionCodeTagId },
      transactionsCodeCountFilters: { ...defaultCodeCountFilters }
    }
  }, [transactionTableFilters, defaultTransactionCodeTagFilter])

  const customDateRangeSelector = useMemo(() => {
    const { CALENDAR_YEAR, CUSTOM } = DATE_RANGE_CUSTOM_OPTION_KEYS
    if (selectedDateRange?.includes(CALENDAR_YEAR)) {
      const options = getCalendarYearOptions()
      return (
        <Select
          value={selectedCustomDateRange}
          options={options}
          size='small'
          placeholder='Select a Year'
          onChange={setSelectedCustomDateRange}
          showCheckMarOnSelectedItems
        />
      )
    }
    if (selectedDateRange?.includes(CUSTOM)) {
      return (
        <Box ml={1}>
          <CustomDateRangeSelector
            value={selectedCustomDateRange}
            divider={null}
            variant='small'
            datePickerWidth='8rem'
            valueParser={(value) => {
              const [key, startDate, endDate] = value?.split('_') || []
              return { key, startDate, endDate }
            }}
            onChange={(...args) => {
              const value = `${CUSTOM}_${args.join('_')}`
              setSelectedCustomDateRange(value)
            }}
          />
        </Box>
      )
    }
    return null
  }, [selectedDateRange, selectedCustomDateRange, setSelectedCustomDateRange])

  return (
    <>
      <div className={classes.dateControls}>
        <div>
          <AvailableDatePicker
            className={classes.asOfDatePicker}
            value={availableDates.mainDate}
            onChange={onAvailableDateChange}
            format='[As of] MMMM DD, YYYY'
            type='date'
            extraStyles={{
              fontSize: '0.875rem',
              cursor: 'pointer',
              display: 'block'
            }}
            disableFuture
          />
        </div>
        <RelativeDateSelect
          options={dateRangeOptions}
          availableDates={availableDates}
          selectedValue={selectedDateRange}
          onDateRangeSelected={setSelectedDateRange}
        />
        {isCustomDateRangeSelected && customDateRangeSelector}
      </div>
      {!hideFeaturedFilters && (
        <div className={classes.featuredFilters}>
          <FeaturedFilters
            availableDates={availableDates}
            enabled={!loadingAvailableDates}
            defaultFilter={transactionsCodeFilters}
            defaultCountFilter={transactionsCodeCountFilters}
            relativeDateRange={featuredDateRange}
            maxFilters={maxFilters}
            onFilterSelected={setTransactionCodeTag}
            defaultSelectedFilter={transactionCodeTag}
            enableFirmWideView={enableFirmWideView}
          />
        </div>
      )}
      <GroupingProvider>
        <TransactionsTable.Wrapper className={classes.table}>
          <TransactionsTable.SuperHeader className={classes.tableHeader}>
            <div className='__header'>
              <div className='__header-content'>
                <TransactionsTable.Search
                  onChange={onSearchTextChange}
                  placeholder='Search by asset name or account'
                />
                <div className='__header-content-options'>
                  <GroupingOptions
                    hideExpandCollapseButton
                    hideCustomizeColumnsColumnHiding={false}
                  />
                </div>
              </div>
              <div className='__header-filters'>
                <TransactionTableFilters
                  onChangeFilters={onChangeSearchParams}
                  defaultTagValues={defaultTagValues}
                  defaultAssetValues={defaultAssetValues}
                  defaultCustodianValues={defaultCustodianValues}
                />
              </div>
            </div>
          </TransactionsTable.SuperHeader>
          <TransactionsTable
            columns={columns}
            defaultPageSize={defaultPageSize}
            defaultSort={defaultSort}
            enabled={!loadingAvailableDates}
            defaultFilter={defaultFilter}
            dateRange={dateRange}
            filters={transactionTableFilters}
            enableFirmWideView={enableFirmWideView}
          />
        </TransactionsTable.Wrapper>
      </GroupingProvider>
    </>
  )
}

export const transactionsTableContainerProps = {
  columnConfig: PropTypes.oneOfType([PropTypes.string]),
  defaultDateRange: PropTypes.string,
  defaultPageSize: PropTypes.number,
  defaultFilter: PropTypes.object,
  defaultTransactionCodeTag: PropTypes.object,
  defaultTransactionCodeTagFilter: PropTypes.object,
  maxFilters: PropTypes.number,
  onChangeSearchParams: PropTypes.func,
  defaultCustodianValues: PropTypes.object,
  defaultAssetValues: PropTypes.object,
  defaultTagValues: PropTypes.object,
  filters: PropTypes.object,
  enableFirmWideView: PropTypes.bool,
  dateRangeOptions: PropTypes.array,
  hideFeaturedFilters: PropTypes.bool
}

export const transactionsTableContainerDefaultProps = {
  columnConfig: 'default',
  defaultDateRange: 'L30D',
  defaultFilter: {},
  defaultTransactionCodeTag: {},
  defaultTransactionCodeTagFilter: {},
  maxFilters: 5,
  onChangeSearchParams: noop,
  defaultCustodianValues: {},
  defaultAssetValues: {},
  defaultTagValues: {},
  filters: {},
  defaultPageSize: 1000,
  enableFirmWideView: false,
  dateRangeOptions: defaultOptions.map(({ value }) => value),
  hideFeaturedFilters: false
}

TransactionsTableContainer.propTypes = transactionsTableContainerProps
TransactionsTableContainer.defaultProps = transactionsTableContainerDefaultProps

export default TransactionsTableContainer
