import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import { useFormattingContext } from '../FormattingProvider/FormattingContext'
import MilestoneIcon from '../../molecules/MilestoneIcon'

const useStyles = makeStyles((theme) => ({
  miniMilestone: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    gap: theme.layout.gap.medium,
    padding: `${theme.layout.padding.dense} ${theme.layout.padding.medium}`,
    fontWeight: theme.typography.weights.light,
    '& .__title': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      gap: theme.layout.gap.medium,
      fontWeight: theme.typography.weights.bold,
      marginBottom: theme.layout.margin.thin
    },
    '& .__left': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }
  }
}))

function MiniMilestone ({ milestone }) {
  const classes = useStyles()
  const { formatter } = useFormattingContext()
  return (
    <div className={classes.miniMilestone}>
      <div className='__left'>
        <MilestoneIcon className={classes.icon} milestone={milestone} />
      </div>
      <div className='__right'>
        <div className='__title'>
          {milestone.entryText}
        </div>
        <div className='__right'>
          Target: {formatter(milestone.entryDate, '@MMM YYYY')}
        </div>
      </div>
    </div>
  )
}

MiniMilestone.propTypes = {
  milestone: PropTypes.shape({
    entryId: PropTypes.number,
    clientId: PropTypes.number,
    entryDate: PropTypes.string,
    entryTypeId: PropTypes.number,
    entryText: PropTypes.string,
    entryJson: PropTypes.shape({
      tasks: PropTypes.array,
      title: PropTypes.string,
      images: PropTypes.array
    })
  })
}

export default MiniMilestone
