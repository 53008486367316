import React from 'react'
import PropTypes from 'prop-types'
import PresentationTable from '../PresentationTable'
import { useColumns } from './columnConfig'
import { useTargetAllocationTreeData } from './useTargetAllocationTreeData'

function TargetAllocationTable ({
  defaultFilter,
  grouping,
  compareTo,
  allocationGrouping,
  allocator,
  columnConfig,
  initialDepth,
  initialExpandDepth
}) {
  const { data, isLoading, onLevelExpand } = useTargetAllocationTreeData({
    defaultFilter,
    grouping,
    compareTo,
    allocationGrouping,
    allocator,
    initialDepth,
    initialExpandDepth
  })
  const { columns, defaultSort } = useColumns(columnConfig)
  return (
    <div>
      <PresentationTable
        expandable
        columns={columns}
        data={data}
        loading={isLoading}
        defaultSort={defaultSort}
        sortable
        onRowClick={onLevelExpand}
        variant='bordered'
      />
    </div>
  )
}

TargetAllocationTable.propTypes = {
  defaultFilter: PropTypes.object,
  grouping: PropTypes.array,
  compareTo: PropTypes.array,
  allocationGrouping: PropTypes.array,
  allocator: PropTypes.string,
  columnConfig: PropTypes.object,
  initialDepth: PropTypes.number,
  initialExpandDepth: PropTypes.number
}

TargetAllocationTable.defaultProps = {
  allocator: 'endingValue'
}

export default TargetAllocationTable
