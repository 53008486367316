import React, { forwardRef, useCallback, useImperativeHandle, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import DebouncedInput from '../../../molecules/DebouncedInput'
import Select from '../../../molecules/Select'

const operationOptions = [
  { label: 'Equals', value: 'eq' },
  { label: 'Not Equals', value: 'neq' },
  { label: 'Less Than', value: 'lt' },
  { label: 'Greater Than', value: 'gt' },
  { label: 'Inside', value: 'range' },
  { label: 'Outside', value: 'outside' }
]
const rangeOperations = ['range', 'outside']

const types = ['number']

const useStyles = makeStyles((theme) => ({
  searchGroup: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '5px'
  },
  to: {
    fontSize: theme.typography.fontSizes.xl,
    padding: '0 .25rem'
  },
  value: {
    minWidth: '10rem !important',
    width: '300px'
  },
  range: {
    minWidth: '7rem !important',
    width: '175px'
  },
  option: {
    border: `1px solid ${theme.palette.gray.dark}`,
    minWidth: '10rem'
  }
}))

const ValueFilter = forwardRef(function ValueFilter ({ schema, onChange }, ref) {
  const { defaultField, options } = useMemo(() => {
    const defaultField = Object.entries(schema.columns)
      .map(([key, value]) => ({ key, value }))
      .sort((a, b) => a.value.ordinal - b.value.ordinal)
      .find(({ value }) => types.includes(value.type))?.key

    const options = Object.entries(schema.columns)
      .map(([key, value]) => ({ key, value }))
      .filter(({ value }) => types.includes(value.type))
      .sort((a, b) => a.value.ordinal - b.value.ordinal)
      .map(({ key, value }) => ({
        label: value.title,
        value: key
      }))

    return {
      defaultField,
      options
    }
  }, [schema])

  const [field, setField] = useState(defaultField)
  const [operation, setOperation] = useState('eq')

  const primaryRef = useRef()
  const secondaryRef = useRef()
  const [currentValue, setCurrentValue] = useState({})
  const onSearchChange = useCallback(() => {
    if (rangeOperations.includes(operation)) {
      const start = primaryRef.current.value
      const end = secondaryRef.current.value

      if (start === '' || end === '') {
        return
      }

      const lowerOp = operation === 'range' ? 'gte' : 'lt'
      const upperOp = operation === 'range' ? 'lte' : 'gt'
      const combine = operation === 'range' ? 'and' : 'or'

      const val = {
        [field]: [
          { op: lowerOp, value: start, combine },
          { op: upperOp, value: end, combine }
        ]
      }

      setCurrentValue(val)
      onChange(val)
      return
    }

    const filterVal = primaryRef.current.value
    const val = (filterVal === '')
      ? {}
      : {
        [field]: [{ op: operation, value: filterVal }]
      }

    setCurrentValue(val)
    onChange(val)
  }, [onChange, field, operation, primaryRef, secondaryRef, setCurrentValue])

  useImperativeHandle(ref, () => ({
    value: currentValue
  }), [currentValue])

  const classes = useStyles()

  return (
    <div className={classes.searchGroup}>
      <Select className={classes.option} options={options} value={field} onChange={setField} />
      <Select className={classes.option} options={operationOptions} value={operation} onChange={setOperation} />
      <DebouncedInput
        className={rangeOperations.includes(operation) ? classes.range : classes.value}
        ref={primaryRef}
        iconName='filters'
        placeholder={rangeOperations.includes(operation) ? 'From' : 'Value'}
        onChange={onSearchChange}
      />
      {rangeOperations.includes(operation) ? (
        <>
          <span className={classes.to}> To </span>
          <DebouncedInput
            className={classes.range}
            ref={secondaryRef}
            placeholder='To'
            iconName='filters'
            onChange={onSearchChange}
          />
        </>
      ) : null}
    </div>
  )
})

ValueFilter.propTypes = {
  schema: PropTypes.shape({
    columns: PropTypes.object
  }),
  onChange: PropTypes.func
}

export default ValueFilter
