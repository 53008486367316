import React, { forwardRef, useMemo } from 'react'
import { KeyboardDatePicker, KeyboardDateTimePicker } from '@material-ui/pickers'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import { mapInputStyles } from './styles'

const useStyles = makeStyles((theme) => ({
  sydDatePicker: mapInputStyles(theme, {
    minWidth: '300px',
    width: '100%',
    border: `1px solid ${theme.palette.gray.dark}`,
    borderRadius: '4px',
    backgroundColor: theme.palette.white,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    transition: 'outline 200ms ease-in-out',
    outline: '1px solid transparent',
    '&:has(:-internal-autofill-selected)': {
      backgroundColor: 'rgb(232, 240, 254)'
    },
    '&:focus-within': {
      outline: `1px solid ${theme.palette.primary.main}`
    }
  }),
  inner: {
    padding: 0
  }
}))

const SydDatePicker = forwardRef(function SydDatePicker ({
  className,
  name,
  disabled,
  size,
  type,
  dateFormat,
  value,
  ...rest
}, ref) {
  const classes = useStyles({ size, disabled })
  const _value = useMemo(() => value === '' ? null : value, [value])

  if (type === 'datetime') {
    return (
      <KeyboardDateTimePicker
        showTodayButton
        disabled={disabled}
        className={classes.sydDatePicker}
        format={dateFormat}
        value={_value}
        InputAdornmentProps={{ position: 'start' }}
        inputProps={{ className: classes.inner }}
        InputProps={{ disableUnderline: true, className: classes.control }}
        {...rest}
      />
    )
  }

  return (
    <KeyboardDatePicker
      showTodayButton
      disabled={disabled}
      className={classes.sydDatePicker}
      format={dateFormat}
      value={_value}
      InputAdornmentProps={{ position: 'start' }}
      inputProps={{ className: classes.inner }}
      InputProps={{ disableUnderline: true, className: classes.control }}
      {...rest}
    />
  )
})

SydDatePicker.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  size: PropTypes.oneOf(['sm', 'lg']),
  type: PropTypes.oneOf(['date', 'datetime']),
  dateFormat: PropTypes.string,
  value: PropTypes.any
}

SydDatePicker.defaultProps = {
  defaultValue: '',
  disabled: false,
  size: 'lg',
  type: 'date',
  dateFormat: 'YYYY-MM-DD'
}

export default SydDatePicker
