import React from 'react'
import PropTypes from 'prop-types'
import { alpha, makeStyles } from '@material-ui/core'
import { Link } from 'react-router-dom'
import SummaryMetric from './SummaryMetric'

const useStyles = makeStyles((theme) => ({
  group: {
    outline: `1px solid ${alpha(theme.palette.primary.main, 0.5)}`,
    borderRadius: '8px',
    padding: '15px 30px 30px',
    minWidth: '500px',
    '& .__group-title': {
      fontSize: theme.typography.fontSizes.h4,
      textAlign: 'left'
    },
    '& .__group-metrics': {
      display: 'flex',
      flexDirection: 'column',
      gap: '5px'
    },
    '&:hover': {
      outline: `1.5px solid ${theme.palette.primary.main}`,
      backgroundColor: alpha(theme.palette.primary.main, 0.05),
      color: 'rgba(0, 0, 0, 0.87)',
      cursor: 'pointer'
    },
    transition: 'outline 200ms ease-in-out, background-color 200ms ease-in-out',
    height: '100%'
  },
  groupLink: {
    textDecoration: 'none !important',
    display: 'block'
  }
}))

function SummaryGroup ({ group, reportId }) {
  const classes = useStyles()

  return (
    <Link className={classes.groupLink} to={`/data-home/out/r/${reportId}?filter=${group.key}`}>
      <div className={classes.group}>
        <div className='__group-title'>{group.name}</div>
        <div className='__group-metrics'>
          {group.metrics.map(metric => (
            <SummaryMetric key={metric.key} metric={metric} />
          ))}
        </div>
      </div>
    </Link>
  )
}

SummaryGroup.propTypes = {
  group: PropTypes.object,
  reportId: PropTypes.string
}

export default SummaryGroup
