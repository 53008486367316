import React, { useCallback, useRef } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import FadeIn from '../../../../molecules/Transitions/FadeIn'
import { useModifyClientAccountsMutation } from '../../../../../api/clients'
import { useInvalidateListAssignedClientAccounts, useInvalidateSearchAccountsMultiple } from '../../../../../api/accounts'
import ErrorBoundary from '../../../../molecules/ErrorBoundary'
import SectionHeader from '../../shared/SectionHeader'
import { useClientDetails, useSectionEditing } from '../ClientDetailsFormContext'
import PersonalSpace from '../../shared/PersonalSpace'
import SectionScreen from '../../shared/SectionScreen'
import EditButton from '../EditButton'
import { useModifyClientAccountOrdinals } from '../../../../../api/groups'
import ClientAccountsTable from './ClientAccountsTable'
import OrdinalForm from './OrdinalForm'

const useStyles = makeStyles((theme) => ({
  accountsSection: {
    position: 'relative',
    padding: '10px',
    transition: 'outline-color 300ms ease-in-out',
    minHeight: '500px',

    '& .__section-header': {
      fontSize: theme.typography.fontSizes.h4
    }
  }
}))

function AccountsTabView ({ client }) {
  return (
    <FadeIn>
      <ClientAccountsTable client={client} />
    </FadeIn>
  )
}

function AccountsTabEdit ({ client, formRef }) {
  return (
    <FadeIn>
      <ClientAccountsTable
        ref={formRef}
        client={client}
        editing
      />
    </FadeIn>
  )
}

function AccountsTab () {
  const { client, editSection, editing } = useClientDetails()
  const editingAccounts = useSectionEditing('accounts')
  const editingOrdinals = useSectionEditing('account-ordinals')
  const { mutateAsync: assignAccounts } = useModifyClientAccountsMutation()
  const { mutateAsync: modifyOrdinals } = useModifyClientAccountOrdinals()
  const invalidateSearch = useInvalidateSearchAccountsMultiple()
  const invalidateAssignedAccounts = useInvalidateListAssignedClientAccounts(client.clientId)
  const formRef = useRef()
  const onSaveAccountAssignments = useCallback(async () => {
    if (!formRef) {
      alert('An unexpected error has occurred')
      return
    }
    try {
      await assignAccounts({
        clientId: client.clientId,
        added: formRef.current.added,
        removed: formRef.current.removed
      })
      invalidateSearch()
      invalidateAssignedAccounts()
    } catch (err) {
      console.error(err)
      alert('An unexpected error has occurred')
    }
  }, [client, assignAccounts, formRef, invalidateAssignedAccounts, invalidateSearch])
  const onSaveAccountOrdinals = useCallback(async () => {
    if (!formRef) {
      alert('An unexpected error has occurred')
      return
    }
    try {
      await modifyOrdinals({
        clientId: client.clientId,
        accounts: formRef.current.getAccounts()
      })
    } catch (err) {
      console.error(err)
      alert('An unexpected error has occurred')
      throw err
    }
  }, [client, modifyOrdinals, formRef])
  const classes = useStyles()

  return (
    <ErrorBoundary name='Accounts Tab'>
      <SectionScreen editing={editing} sectionIsEditing={editingOrdinals || editingAccounts} className={classes.accountsSection}>
        <SectionHeader text='Assigned Accounts'>
          <EditButton
            policy='admin_edit_client_accts'
            editing={editingAccounts}
            onClick={() => editSection({
              section: 'accounts',
              saveDescription: 'Save Account Assignments',
              onSave: onSaveAccountAssignments
            })}
            disabled={editingOrdinals}
            buttonText='Edit Assignments'
          />
          <EditButton
            policy='admin_edit_client_accts'
            editing={editingOrdinals}
            onClick={() => editSection({
              section: 'account-ordinals',
              saveDescription: 'Save Account Ordinals',
              onSave: onSaveAccountOrdinals
            })}
            disabled={editingAccounts}
            buttonText='Edit Ordinals'
          />
        </SectionHeader>
        {editingOrdinals
          ? (
            <OrdinalForm client={client} formRef={formRef} />
          )
          : editingAccounts ? (
            <AccountsTabEdit client={client} formRef={formRef} />
          ) : (
            <AccountsTabView client={client} />
          )}
        <PersonalSpace />
      </SectionScreen>
    </ErrorBoundary>
  )
}

AccountsTab.propTypes = {}
AccountsTabView.propTypes = {
  client: PropTypes.object
}
AccountsTabEdit.propTypes = {
  client: PropTypes.object,
  formRef: PropTypes.any
}

export default AccountsTab
