import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import noop from 'lodash/noop'
import { postNamedCommand, postNamedQuery } from '../service'
import { useAppContext } from '../redux/slices/appContext'

export const useNLQuery = (options = {}) => {
  const { onSuccess = noop, enabled = true } = options

  const { userId } = useAppContext()
  return useQuery({
    queryKey: ['denali-query-nlquery', userId],
    queryFn: async () => {
      return []
    },
    onSuccess,
    enabled
  })
}

export const usePostNLQuery = () => {
  const queryClient = useQueryClient()
  const { userId } = useAppContext()
  return useMutation({
    mutationFn: async ({ prompt }) => {
      const { data } = await postNamedQuery('denali', 'nlQuery', {
        prompt
      })

      return data
    },
    onSuccess: (data, command) => {
      queryClient.setQueryData(['denali-query-nlquery', userId], prev => {
        if (!prev) {
          return [{ ...command, result: data?.result }]
        }
        prev.push({ ...command, result: data?.result })
        return prev
      })
    }
  })
}

export const useDenaliAssistantThread = (options = {}) => {
  const { onSuccess = noop, enabled = true } = options

  const { userId } = useAppContext()
  return useQuery({
    queryKey: ['denali-assistant-thread', userId],
    queryFn: async () => {
      return []
    },
    onSuccess,
    enabled
  })
}

export const useInvokeAssistant = () => {
  const queryClient = useQueryClient()
  const { userId } = useAppContext()
  return useMutation({
    mutationFn: async ({ prompt }) => {
      const { data } = await postNamedCommand('denali', 'invoke-assistant', {
        prompt
      })

      return data
    },
    onSuccess: (data, command) => {
      queryClient.setQueryData(['denali-assistant-thread', userId], prev => {
        if (!prev) {
          return [{ queryId: data.queryId }]
        }
        prev.push({ queryId: data.queryId })
        return prev
      })
    }
  })
}

export const useAssistantQuery = (queryId, options = {}) => {
  const { userId } = useAppContext()
  const { enabled = true, refetchInterval = null } = options
  return useQuery({
    queryKey: ['denali-assistant-query', userId, queryId],
    queryFn: async () => {
      const { data } = await postNamedQuery('denali', 'get-assistant-query', {
        queryId
      })

      return data
    },
    enabled,
    refetchInterval,
    refetchOnWindowFocus: !!refetchInterval
  })
}
