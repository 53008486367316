import React, { useCallback, useMemo } from 'react'
import { Radio } from '@material-ui/core'
import dayjs from 'dayjs'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { noop } from 'lodash'
import { useDynamicPlanning } from '../DynamicPlanningContext'
import { numeralByCase } from '../../../../utils'
import Tag from '../../../atoms/Tag'
import theme from '../../../../theme'

const useStyles = makeStyles(() => ({
  goalContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '.5rem',
    padding: '.25rem .5rem .25rem 0',
    borderRadius: '10px'
  },
  goalContent: {
    opacity: 1,
    fontSize: '0.875rem',
    flexGrow: 4,
    lineHeight: 1.4,
    display: 'flex',
    width: '100%',
    gap: '.5rem',
    cursor: 'pointer'
  },
  goalDescription: {
    flexGrow: 5,
    alignItems: 'center',
    textAlign: 'left'
  },
  goalAmountText: {
    fontSize: '1.25em'
  },
  goalSubtitle: {
    fontSize: '.85em',
    fontWeight: 400
  }
}))

const DynamicPlanningGoal = ({ goal = {}, onEditGoal = noop, allowToggle = true }) => {
  const { activeGoalIds, activeGoalIdsDispatch, linkedPlan, currentEditGoalId, currentPlan, isSandbox } = useDynamicPlanning()
  const goalIsActive = useMemo(() => !allowToggle || activeGoalIds.includes(goal.goalId), [activeGoalIds, allowToggle, goal.goalId])
  const goalIsEditing = useMemo(() => currentEditGoalId === goal.goalId, [currentEditGoalId, goal.goalId])
  const classes = useStyles({ flowAmount: parseInt(goal.flowAmount), goalIsActive, goalIsEditing: !!goalIsEditing })

  const displayAmount = useMemo(() => {
    let endingAdornment = ''
    if (goal.frequency === 'ANNUALLY') {
      endingAdornment = '/yr'
    }
    return numeralByCase(Math.abs(goal.flowAmount), '$0,000[.]0a') + endingAdornment
  }, [goal])

  const displaySubtitle = useMemo(() => {
    const { startDate, endDate, frequency } = goal
    let subTitleText = dayjs.utc(startDate).year()
    if (frequency === 'ONETIME') {
      return subTitleText
    }

    if (endDate && dayjs.utc(endDate)) {
      if (dayjs.utc(endDate).diff(startDate, 'year') > 0) {
        subTitleText += '-' + dayjs.utc(endDate).year()
      }
    } else {
      subTitleText = 'From ' + subTitleText + ' on'
    }
    return subTitleText
  }, [goal])

  const linkedGoalIds = useMemo(() => {
    return linkedPlan?.goals?.filter((g) => g.originGoalId === goal.goalId || g.goalId === goal.originGoalId).map((g) => g.goalId) ?? []
  }, [linkedPlan?.goals, goal.goalId, goal.originGoalId])

  const isEditAndNew = useMemo(() => {
    return !goal.originGoalId && isSandbox(currentPlan)
  }, [goal.originGoalId, isSandbox, currentPlan])

  const toggleGoalActive = useCallback(() => {
    activeGoalIdsDispatch({
      type: 'TOGGLE_GOAL_IDS',
      payload: [goal.goalId, ...linkedGoalIds],
      forceState: !goalIsActive ? 'ACTIVE' : 'INACTIVE'
    })
  }, [activeGoalIdsDispatch, goal.goalId, goalIsActive, linkedGoalIds])

  return (
    <div
      className={classes.goalContainer}
      style={{
        backgroundColor: goalIsEditing ? '#f3f3f3' : 'transparent',
        paddingLeft: allowToggle ? 0 : '.5rem'
      }}
      data-prevent-edit-close={1}
    >
      {allowToggle && (
        <div>
          <Radio
            onClick={toggleGoalActive}
            checked={goalIsActive}
            onChange={noop}
            value={goal.goalId}
            name='radio-buttons'
            inputProps={{ 'aria-label': goal.goalId }}
            color='default'
          />
        </div>
      )}
      <button
        className={classes.goalContent} style={{ opacity: goalIsActive ? 1 : 0.5 }} onClick={() => onEditGoal(goal)}
        type='button'
      >
        <div className={classes.goalDescription}>
          {goal.shortName}
          <div className={classes.goalSubtitle}>
            {displaySubtitle}
          </div>
          {isEditAndNew && <Tag backgroundColor={theme.palette.green.sheen} color='#fff' label='New' />}
        </div>
        <div
          className={classes.goalAmountText}
          style={{ color: goal.flowAmount < 0 ? 'inherit' : theme.palette.mediumSeaGreen }}
        >
          {displayAmount}
        </div>
      </button>
    </div>
  )
}

DynamicPlanningGoal.propTypes = {
  goal: PropTypes.objectOf(PropTypes.shape({
    goalId: PropTypes.any,
    planId: PropTypes.number,
    shortName: PropTypes.string,
    longName: PropTypes.string,
    status: PropTypes.string,
    flowAmount: PropTypes.number,
    frequency: PropTypes.string,
    startDate: PropTypes.string,
    endDate: PropTypes.string
  })),
  onEditGoal: PropTypes.func,
  allowToggle: PropTypes.bool
}

export default DynamicPlanningGoal
