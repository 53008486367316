import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import dayjs, { utc } from 'dayjs'
import { ResponsiveLine } from '@nivo/line'
import { makeStyles } from '@material-ui/core'
import get from 'lodash/get'
import { useAppContext } from '../../../../redux/slices/appContext'
import { useGetGroupedCoreData, useNormalizeRelativeDates } from '../../../../api/coreData'
import Skeleton from '../../../atoms/Skeleton'
import { useFormattingContext } from '../../FormattingProvider/FormattingContext'
import CustomLines from '../../../nivo/Lines/CustomLines'
import CustomAreas from '../../../nivo/Lines/CustomAreas'
import { DATE_TYPES } from '../../../../constants'
import { isNullOrUndefined } from '../../../../utils'
import Tooltip from './Tooltip'

dayjs.extend(utc)

const useStyles = makeStyles((theme) => ({
  lineChartV4: {
    height: ({ height }) => height,
    '& text': {
      fontFamily: `${theme.typography.fontFamily} !important`
    }
  }
}))

const transformSupplemental = (item) => {
  return {
    ...item,
    maxDate: item.minDate
  }
}

const DEFAULT_BREAKPOINTS = {
  day: 0,
  month: 185,
  quarter: 730,
  year: 1460
}

const getDateIntervalByDateRange = (startDate, endDate, breakPoints = DEFAULT_BREAKPOINTS) => {
  const diffInDays = Math.floor(dayjs.utc(endDate).diff(startDate, 'day', true))
  if (!isNullOrUndefined(breakPoints.year) && diffInDays > breakPoints.year) return DATE_TYPES.year
  if (!isNullOrUndefined(breakPoints.quarter) && diffInDays > breakPoints.quarter) return DATE_TYPES.quarter
  if (!isNullOrUndefined(breakPoints.month) && diffInDays > breakPoints.month) return DATE_TYPES.month

  return DATE_TYPES.day
}

function useGetCoreSeriesData (defaultFilter, clientId, calculatedInterval, selectedDateRange) {
  const coreDataQuery = useMemo(() => {
    return ({
      levelFilters: {
        ...(defaultFilter || {}),
        levelTypes: ['client'],
        clientIds: [clientId],
        calcType: 'timeseries',
        dateType: calculatedInterval
      },
      dateRange: selectedDateRange
    })
  }, [defaultFilter, clientId, calculatedInterval, selectedDateRange])
  const { data, isLoading } = useGetGroupedCoreData(coreDataQuery, { enabled: !!selectedDateRange })
  return { data, isLoading }
}

function useGetSupplementalData (defaultFilter, clientId, selectedDateRange) {
  const supplementalQuery = useMemo(() => ({
    levelFilters: {
      ...(defaultFilter || {}),
      levelTypes: ['client'],
      clientIds: [clientId],
      calcType: 'performance'
    },
    dateRange: {
      startDate: selectedDateRange?.startDate,
      endDate: selectedDateRange?.startDate
    }
  }), [defaultFilter, clientId, selectedDateRange])
  const {
    data: supplementalData,
    isLoading: supplementalLoading
  } = useGetGroupedCoreData(supplementalQuery, { enabled: !!selectedDateRange })
  return { supplementalData, supplementalLoading }
}

function useGetRequestDates (availableDates, defaultFilter, clientId, dateRange) {
  const dateRangeRequest = useMemo(() => ({
    asOfDate: availableDates.mainDate,
    levelFilters: {
      ...(defaultFilter || {}),
      levelTypes: ['client'],
      clientIds: [clientId]
    },
    relativeRanges: [dateRange]
  }), [defaultFilter, clientId, dateRange, availableDates.mainDate])

  const { data: normalizedDates, isLoading: datesLoading } = useNormalizeRelativeDates(dateRangeRequest)
  const selectedDateRange = useMemo(() => normalizedDates?.[dateRange]?.value, [normalizedDates, dateRange])

  return { datesLoading, selectedDateRange }
}

const useLineChartData = ({
  defaultFilter,
  dateRange,
  interval,
  series,
  yScaleBuffer,
  breakpoints
}) => {
  const { clientId, availableDates } = useAppContext()
  const { datesLoading, selectedDateRange } = useGetRequestDates(availableDates, defaultFilter, clientId, dateRange)

  const calculatedInterval = useMemo(() => {
    return interval === 'auto' && selectedDateRange ? getDateIntervalByDateRange(selectedDateRange.startDate, selectedDateRange.endDate, breakpoints) : interval
  }, [interval, selectedDateRange, breakpoints])

  const { data, isLoading } = useGetCoreSeriesData(defaultFilter, clientId, calculatedInterval, selectedDateRange)
  const { supplementalData, supplementalLoading } = useGetSupplementalData(defaultFilter, clientId, selectedDateRange)

  const supplementedData = useMemo(() => {
    if (!data?.length || !supplementalData) return []
    return supplementalData?.length ? [transformSupplemental(supplementalData.at(0)), ...data] : data
  }, [data, supplementalData])

  const mappedLines = useMemo(() => {
    // The data might not have loaded yet
    if (!supplementedData?.length || !series?.length) {
      return {
        data: [],
        min: Number.POSITIVE_INFINITY,
        max: Number.NEGATIVE_INFINITY
      }
    }

    // iterate over the series config to find datapoints to turn into lines
    const seriesBuckets = series.reduce((agg, item) => {
      if (item.explode) {
        // Get the first piece of data to determine the benchmark series lines
        const dataItem = supplementedData.at(0)

        // We can only explode arrays
        const property = get(dataItem, item.accessor)
        if (!Array.isArray(property)) {
          return agg
        }

        property.forEach((propertyItem, i) => {
          const explodedId = get(propertyItem, item.explode.label)
          agg[explodedId] = {
            // create an accessor for the benchmark data
            getItem: (x) => get(get(x, item.accessor).at(i), item.explode.value),
            ...item,
            label: explodedId,
            color: get(propertyItem, item.explode.color),
            id: explodedId,
            data: [],
            min: Number.POSITIVE_INFINITY,
            max: Number.NEGATIVE_INFINITY
          }
        })
        return agg
      }
      agg[item.id] = {
        getItem: (x) => get(x, item.accessor),
        ...item,
        data: [],
        min: Number.POSITIVE_INFINITY,
        max: Number.NEGATIVE_INFINITY
      }
      return agg
    }, {})

    const d = Object.values(seriesBuckets)

    d.forEach(bucket => {
      supplementedData.forEach(item => {
        const value = bucket.getItem(item) ?? null
        const date = dayjs.utc(item.maxDate).format('YYYY-MM-DD')
        bucket.min = Math.min(value, bucket.min)
        bucket.max = Math.max(value, bucket.max)
        bucket.data.push({
          x: date,
          y: value
        })
      })
    })
    const min = d.reduce((prev, cur) => Math.min(prev, cur.min), Number.POSITIVE_INFINITY)
    const max = d.reduce((prev, cur) => Math.max(prev, cur.max), Number.NEGATIVE_INFINITY)

    // Get the x tick values for later
    const xTicks = supplementedData.reduce((prev, cur) => {
      prev.push(dayjs.utc(cur.maxDate).format('YYYY-MM-DD'))
      return prev
    }, [])

    // Determine the chart colors from the config
    const colors = d.reduce((prev, cur) => {
      if (cur.color) {
        prev.push(cur.color)
      }
      return prev
    }, [])
    return {
      data: d,
      min: min - Math.abs(min * yScaleBuffer?.min ?? 0),
      max: max + Math.abs(max * yScaleBuffer?.max ?? 0),
      xTicks,
      colors
    }
  }, [supplementedData, series, yScaleBuffer])

  return {
    data: mappedLines?.data || [],
    min: mappedLines.min,
    max: mappedLines.max,
    xTicks: mappedLines.xTicks,
    colors: mappedLines.colors,
    isLoading: datesLoading || isLoading || supplementalLoading,
    calculatedInterval
  }
}

const layers = [
  'grid',
  'markers',
  'axes',
  CustomAreas,
  'crosshair',
  CustomLines,
  'points',
  'slices'
]

function chooseTicks (xTicks, axisBottomFormat, maxXTicks) {
  const xDistance = Math.floor((xTicks?.length ?? 0) / maxXTicks || 100) + 1
  let prevValue = null
  let itemsSinceLastTick = 0
  return xTicks?.reduce((prev, cur, index) => {
    const candidateValue = axisBottomFormat(cur)
    if (prevValue !== candidateValue) {
      prevValue = candidateValue
      if (itemsSinceLastTick >= xDistance || index === 0) {
        prev.push(cur)
        itemsSinceLastTick = 0
      }
      if (index === xTicks.length - 1) {
        prev.push(cur)
      }
    }

    itemsSinceLastTick++
    return prev
  }, []) || undefined
}

function PerformanceLine ({
  defaultFilter,
  dateRange,
  interval,
  height,
  series,
  margin,
  xScale,
  xFormat,
  yScale,
  yScaleBuffer,
  axisLeft,
  axisBottom,
  maxXTicks,
  tooltip,
  breakpoints,
  valueFormat,
  tooltipValueFormat,
  ...props
}) {
  const classes = useStyles({ height })
  const { formatter } = useFormattingContext()
  const { data, isLoading, min, max, xTicks, colors, calculatedInterval } = useLineChartData({
    interval, series, yScaleBuffer, maxXTicks, formatter, defaultFilter, dateRange, breakpoints
  })

  const [_axisLeft, _axisBottom] = useMemo(() => {
    const axisLeftFormat = valueFormat ?? axisLeft.format
    const axisBottomFormat = axisBottom.format === 'auto'
      ? (x) => formatter(x, xFormat[calculatedInterval])
      : axisBottom.format ? (x) => formatter(x, axisBottom.format) : undefined

    return [
      { ...axisLeft, format: axisLeftFormat ? (x) => formatter(x, axisLeftFormat) : undefined },
      { ...axisBottom, format: axisBottomFormat, tickValues: chooseTicks(xTicks, axisBottomFormat, maxXTicks) }
    ]
  }, [axisLeft, axisBottom, formatter, xTicks, xFormat, calculatedInterval, valueFormat, maxXTicks])

  if (isLoading) {
    return (
      <div className={classes.lineChartV4}>
        <Skeleton height='20rem' width='100%' />
      </div>
    )
  }
  return (
    <div className={classes.lineChartV4}>
      <ResponsiveLine
        areaBaselineValue={yScale.min === 'auto' ? min : yScale.min}
        data={data}
        margin={margin}
        xScale={xScale}
        yScale={{
          ...yScale,
          min: yScale.min === 'auto' ? min : yScale.min,
          max: yScale.max === 'auto' ? max : yScale.max
        }}
        axisLeft={_axisLeft}
        axisBottom={_axisBottom}
        layers={layers}
        colors={colors}
        sliceTooltip={Tooltip({ formatter, tooltip, data, valueFormat: tooltipValueFormat })}
        {...props}
      />
    </div>
  )
}

PerformanceLine.propTypes = {
  defaultFilter: PropTypes.object,
  dateRange: PropTypes.string,
  interval: PropTypes.oneOf(['day', 'week', 'month', 'quarter', 'year', 'auto']),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  series: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    accessor: PropTypes.string
  })),
  margin: PropTypes.shape({
    top: PropTypes.number,
    right: PropTypes.number,
    bottom: PropTypes.number,
    left: PropTypes.number
  }),
  xScale: PropTypes.shape({
    type: PropTypes.oneOf(['point', 'linear'])
  }),
  xFormat: PropTypes.shape({
    day: PropTypes.string,
    month: PropTypes.string,
    quarter: PropTypes.string,
    year: PropTypes.string
  }),
  yScale: PropTypes.shape({
    type: PropTypes.oneOf(['point', 'linear']),
    stacked: PropTypes.bool,
    min: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf(['auto'])]),
    max: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf(['auto'])])
  }),
  yScaleBuffer: PropTypes.shape({
    min: PropTypes.number,
    max: PropTypes.number
  }),
  axisLeft: PropTypes.shape({
    format: PropTypes.string
  }),
  axisBottom: PropTypes.shape({
    format: PropTypes.string
  }),
  maxXTicks: PropTypes.number,
  tooltip: PropTypes.shape({
    xFormat: PropTypes.string,
    yFormat: PropTypes.string
  }),
  breakpoints: PropTypes.shape({
    day: PropTypes.number,
    month: PropTypes.number,
    quarter: PropTypes.number,
    year: PropTypes.number
  }),
  valueFormat: PropTypes.string,
  tooltipValueFormat: PropTypes.string
}

PerformanceLine.defaultProps = {
  interval: 'auto',
  dateRange: 'ASI',
  margin: {
    top: 0,
    right: 20,
    bottom: 50,
    left: 60
  },
  xScale: { type: 'point' },
  yScale: {
    type: 'linear',
    stacked: false,
    min: 'auto',
    max: 'auto'
  },
  yScaleBuffer: {
    min: 0.2,
    max: 0.2
  },
  xFormat: {
    day: '@M/D/YY',
    month: '@MMM YY',
    quarter: '@[Q]Q YY',
    year: '@YYYY'
  },
  curve: 'monotoneX',
  lineWidth: 3,
  enablePoints: false,
  enableGridX: false,
  gridYValues: 4,
  gridXValues: 10,
  valueFormat: '$0.0a',
  axisLeft: {
    format: '$0.0a',
    tickSize: 5,
    tickValues: 5,
    tickPadding: 5,
    tickRotation: 0
  },
  axisBottom: {
    format: 'auto',
    tickSize: 5,
    tickPadding: 5,
    tickRotation: -45
  },
  isInteractive: true,
  enableSlices: 'x',
  maxXTicks: 25,
  tooltip: {
    xFormat: '@M/D/YYYY',
    yFormat: '$0,0.00'
  }
}

export default PerformanceLine
