import React from 'react'
import { Switch, useRouteMatch } from 'react-router-dom'
import { SecureRoute } from '@okta/okta-react'
import ErrorBoundary from '../../molecules/ErrorBoundary'
import FormattingProvider from '../../organisms/FormattingProvider'
import { useHideSideMenuEffect } from '../../../redux/slices/appContext'
import useIsDataHomeActive from '../../../hooks/useIsDataHomeActive'
import PlatformDataSources from './PlatformDataSources'
import QueryPlatformDataSource from './QueryPlatformDataSource'
import DataSourceDetails from './DataSourceDetails'
import JobsIndex from './Jobs'
import JobDetailsIndex from './JobDetails'
import UploadFile from './UploadFile'
import NotAvailable from './components/NotAvailable'
import TargetDetailsIndex from './TargetDetails'
import PublishPreviewIndex from './PublishPreview'
import PublishingResultsIndex from './PublishingResults'
import InternalOnly from './components/InternalOnly'
import TargetsIndex from './Targets'
import JobResultsIndex from './JobResults'
import IndexView from './IndexView'
import FirmReportView from './FirmReportView'
import FirmSummaryView from './FirmReportView/Summary'

const DataHomeIndex = () => {
  const { path } = useRouteMatch()
  useHideSideMenuEffect([])
  const isDataHomeActive = useIsDataHomeActive()

  if (!isDataHomeActive) {
    return <NotAvailable />
  }

  return (
    <ErrorBoundary name='Client Dashboard'>
      <FormattingProvider>
        <Switch>
          <SecureRoute path={path} exact>
            <IndexView root={path} />
          </SecureRoute>
          <SecureRoute path={`${path}/upload-file`} exact>
            <UploadFile />
          </SecureRoute>
          <SecureRoute path={`${path}/source`} exact>
            <PlatformDataSources root={path} />
          </SecureRoute>
          <SecureRoute path={`${path}/source/:platformDataSourceId`} exact>
            <DataSourceDetails />
          </SecureRoute>
          <SecureRoute path={`${path}/query/:platformDataSourceId`} exact>
            <QueryPlatformDataSource root={path} />
          </SecureRoute>
          <SecureRoute path={`${path}/out/r/:reportId`} exact>
            <FirmReportView />
          </SecureRoute>
          <SecureRoute path={`${path}/out/s/:reportId`} exact>
            <FirmSummaryView />
          </SecureRoute>
          <SecureRoute path={`${path}/jobs/:jobId/batch/:batchId`} exact>
            <InternalOnly>
              <JobResultsIndex />
            </InternalOnly>
          </SecureRoute>
          <SecureRoute path={`${path}/jobs/:jobId`} exact>
            <InternalOnly>
              <JobDetailsIndex />
            </InternalOnly>
          </SecureRoute>
          <SecureRoute path={`${path}/jobs`} exact>
            <InternalOnly>
              <JobsIndex />
            </InternalOnly>
          </SecureRoute>
          <SecureRoute path={`${path}/targets/:targetId/publish/:policyId/preview`} exact>
            <InternalOnly>
              <PublishPreviewIndex />
            </InternalOnly>
          </SecureRoute>
          <SecureRoute path={`${path}/targets/:targetId`} exact>
            <InternalOnly>
              <TargetDetailsIndex />
            </InternalOnly>
          </SecureRoute>
          <SecureRoute path={`${path}/targets`} exact>
            <InternalOnly>
              <TargetsIndex />
            </InternalOnly>
          </SecureRoute>
          <SecureRoute path={`${path}/publishing/results/:resultId`} exact>
            <InternalOnly>
              <PublishingResultsIndex />
            </InternalOnly>
          </SecureRoute>
        </Switch>
      </FormattingProvider>
    </ErrorBoundary>
  )
}

export default DataHomeIndex
