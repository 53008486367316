import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import WithSkeleton from '../../../../molecules/WithSkeleton'
import { SKELETON_VARIANTS, TEXT_VARIANTS } from '../../../../../constants'
import Text from '../../../../atoms/Text'

const useStyles = makeStyles(() => ({
  valueText: {
    padding: '6px'
  }
}))

const ClientMetricContainer = ({ title, children, isLoading }) => {
  const classes = useStyles()
  return (
    <div className='__clientMetricValue'>
      <WithSkeleton
        showSkeleton={isLoading}
        skeletonWidth='75px'
        skeletonHeight='54px'
        skeletonVariant={SKELETON_VARIANTS.rect}
      >
        <Text
          className={classes.valueText}
          variant={TEXT_VARIANTS.h3}
          text={children}
        />
        <Text text={title} />
      </WithSkeleton>
    </div>
  )
}

ClientMetricContainer.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  isLoading: PropTypes.bool
}

export default ClientMetricContainer
