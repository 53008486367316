import React from 'react'
import PropTypes from 'prop-types'
import GroupSection from './GroupSection'
import LinkSection from './LinkSection'

const Section = ({ section }) => {
  if (section.type === 'group') {
    return <GroupSection {...section} />
  }

  return <LinkSection {...section} />
}

Section.propTypes = {
  section: PropTypes.object.isRequired
}

export default Section
