import React from 'react'
import PropTypes from 'prop-types'
import DataHomeItem from '../shared/DataHomeItem'

const PlatformDataSource = ({ dataSource }) => {
  return (
    <DataHomeItem name={dataSource.name} imageUrl={dataSource.imageUrl} />
  )
}

PlatformDataSource.propTypes = {
  dataSource: PropTypes.shape({
    name: PropTypes.string,
    imageUrl: PropTypes.string
  }).isRequired
}

export default PlatformDataSource
