import { useEffect, useState } from 'react'

export const useDebounce = ({ value, immediate }, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value)
  useEffect(
    () => {
      const handler = setTimeout(() => {
        setDebouncedValue(value)
      }, immediate ? 0 : delay)
      return () => {
        clearTimeout(handler)
      }
    },
    [value, delay, immediate]
  )
  return [debouncedValue]
}
