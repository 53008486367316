import dayjs from 'dayjs'
import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Box, IconButton } from '@material-ui/core'
import Text from '../../../../atoms/Text'
import Icon from '../../../../atoms/Icon'
import { ICON_NAMES } from '../../../../../constants'
import SydLabel from '../../../../commonDesign/SydLabel'
import QuickEditDateModal from './QuickEditDateModal'

const QuickEditDateInput = ({
  currentValue,
  label,
  dateRange,
  onSubmit,
  isSubmitting,
  isSaved,
  error,
  userCanEdit,
  editModalTitle,
  editModalSubtitle
}) => {
  const [isEditing, setIsEditing] = useState(false)

  useEffect(() => {
    if (isSaved && isEditing) {
      setIsEditing(false)
    }
  }, [isEditing, isSaved])

  const displayValue = useMemo(() => {
    if (!currentValue?.length) return '--'
    return dayjs.utc(currentValue).format('MM/DD/YYYY')
  }, [currentValue])

  return (
    <Box>
      <Box display='flex' gridGap='.5rem'>
        <SydLabel label={label} />
        {!userCanEdit && (
          <Box height='2rem' mt='-14px'>
            <IconButton
              onClick={() => setIsEditing(true)}
              disabled={userCanEdit}
              editMode={isEditing}
            >
              <Icon name={ICON_NAMES.edit} customSize='18px' />
            </IconButton>
          </Box>
        )}
      </Box>

      <Text text={displayValue} customFontSize='18px' />

      {isEditing && (
        <QuickEditDateModal
          title={editModalTitle}
          subtitle={editModalSubtitle}
          inputLabel={label}
          onSubmit={onSubmit}
          onClose={() => setIsEditing(false)}
          value={currentValue}
          allowClear
          requireBusinessDay
          keyName='value'
          displayFormat='MM/DD/YYYY'
          isSubmitting={isSubmitting}
          errorMessage={error}
          dateRange={dateRange}
        />
      )}
    </Box>
  )
}

QuickEditDateInput.propTypes = {
  label: PropTypes.string,
  currentValue: PropTypes.string,
  dateRange: PropTypes.object,
  onSubmit: PropTypes.func,
  userCanEdit: PropTypes.bool,
  editModalTitle: PropTypes.string,
  editModalSubtitle: PropTypes.string,
  isSubmitting: PropTypes.bool,
  isSaved: PropTypes.bool,
  error: PropTypes.string
}

export default QuickEditDateInput
