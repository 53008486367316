import React from 'react'
import ViewTabs from '../../../organisms/AdvisorView/components/AdvisorViewTabs'
import { useAccountFormContext } from './AccountFormProvider'

export default function AccountTabs () {
  const { editing } = useAccountFormContext()

  return (
    <div>
      <ViewTabs.Links
        disabled={editing}
        tabsKey='admin_account_details'
      />
    </div>
  )
}
