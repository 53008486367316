import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import { SKELETON_VARIANTS } from '../../../constants'
import Skeleton from '../../atoms/Skeleton'

const useStyles = makeStyles(() => ({
  skeletons: {
    display: 'flex',
    flexDirection: 'row',
    gap: '1rem'
  }
}))

const GroupTagFiltersSkeleton = ({ itemsCount }) => {
  const classes = useStyles()

  return (
    <div className={classes.skeletons}>
      {Array(itemsCount)
        .fill(0)
        .map((_, index) => {
          return (
            <Skeleton
              key={index}
              variant={SKELETON_VARIANTS.text}
              height='3rem'
              width='8rem'
            />
          )
        })}
    </div>
  )
}

GroupTagFiltersSkeleton.propTypes = {
  itemsCount: PropTypes.number
}

GroupTagFiltersSkeleton.defaultProps = {
  itemsCount: 5
}

export default GroupTagFiltersSkeleton
