import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import get from 'lodash/get'
import SydButton from '../../../../../../commonDesign/Button'
import FadeIn from '../../../../../../molecules/Transitions/FadeIn'

const useStyles = makeStyles((theme) => ({
  removeList: {
    position: 'relative',
    overflowY: 'auto',
    borderRadius: theme.layout.radius.thin,
    border: `1px solid ${theme.palette.gray.darker}`,
    padding: theme.layout.padding.thin,
    maxHeight: '335px',
    minHeight: '335px'
  },
  removeListContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.layout.gap.dense,
    '& .__list-item': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between'
    },
    '& .__no-content': {
      fontStyle: 'italic',
      color: theme.palette.gray.darker
    }
  }
}))

const RemoveList = React.forwardRef(function RemoveList ({ value, options, labelAccessor, valueAccessor, onChange }) {
  const classes = useStyles()

  const _label = useCallback((val) => get(val, labelAccessor), [labelAccessor])
  const _value = useCallback((val) => get(val, valueAccessor), [valueAccessor])
  const _list = useMemo(() => {
    return (value || []).map(x => options.find(y => x === _value(y)))
  }, [value, options, _value])

  return (
    <div className={classes.removeList}>
      <div className={classes.removeListContent}>
        {_list?.length > 0 ? (
          _list.map(d => (
            <FadeIn key={_value(d)} className='__list-item'>
              <span>{_label(d)}</span>
              <SydButton size='xs' priority='warning' icon='close' onClick={() => onChange((value || []).filter(x => x !== _value(d)))}>Remove</SydButton>
            </FadeIn>
          ))
        ) : (
          <div className='__no-content'>No selection</div>
        )}
      </div>
    </div>
  )
})

RemoveList.propTypes = {
  value: PropTypes.array,
  options: PropTypes.array,
  labelAccessor: PropTypes.string,
  valueAccessor: PropTypes.string,
  onChange: PropTypes.func
}

export default RemoveList
