import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useCurrentClient } from '../../api/clients'

import Text from '../atoms/Text'

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '30px 0px 5px',
    display: 'flex',
    justifyContent: 'center',
    width: '100%'
  }
}))

const WealthMission = ({ prefix, className }) => {
  const classes = useStyles()
  const { data } = useCurrentClient()

  const title = useMemo(() => {
    if (!data) return ''
    if (prefix) return `${prefix} ${data.description}`
    return data.description
  }, [data, prefix])

  return (
    <Grid item xs={12} className={clsx(classes.container, { [className]: !!className })}>
      <Text
        text={title}
      />
    </Grid>
  )
}

WealthMission.propTypes = {
  className: PropTypes.string,
  prefix: PropTypes.string
}

WealthMission.defautProps = {
  className: '',
  prefix: undefined
}

export default WealthMission
