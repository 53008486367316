import React from 'react'
import { useParams } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { useGetTargetModel } from '../../../../api/rebalancer'
import AdvisorViewTabs from '../../../organisms/AdvisorView/components/AdvisorViewTabs'
import FadeIn from '../../../molecules/Transitions/FadeIn'
import EffectiveModelTable from '../components/EffectiveModelTable'
import ComponentModelTable from '../components/ComponentModelTable'
import TargetModelSankeyDiagram from './TargetModelSankeyDiagram'
import TargetModelTreeMapDiagram from './TargetModelTreeMapDiagram'
import PurposeBadge from './PurposeBadge'
import AssignedMetric from './AssignedMetric'

const useStyles = makeStyles((theme) => ({
  page: {},
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  modelName: {
    fontSize: '20px'
  },
  modelDescription: {
    fontWeight: 100
  },
  purposes: {
    margin: '10px 0'
  },
  diagrams: {
    margin: '0 0 30px',
    '& .MuiTabs-root': {
      marginLeft: 'auto',
      marginRight: 'unset'
    }
  },
  components: {
    marginBottom: '20px',
    '& > header': {
      fontSize: '16px'
    }
  },
  effectiveModel: {
    '& > header': {
      fontSize: '16px'
    }
  },
  group: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    gap: '20px',
    '& > *': {
      flex: '1 0 auto'
    }
  }
}))

function ModelView () {
  const classes = useStyles()
  const { targetModelId } = useParams()
  const { data: model, isLoading: modelLoading } = useGetTargetModel(targetModelId)

  if (modelLoading) {
    return null
  }

  return (
    <div className={classes.page}>
      <header className={classes.header}>
        <FadeIn>
          <div className={classes.modelName}>{model.name}</div>
          <div className={classes.modelDescription}>{model.description}</div>
          <FadeIn className={classes.purposes}>
            <PurposeBadge enabled={model.isForRebalancing} purpose='Rebalancing' />
            <PurposeBadge enabled={model.isForReporting} purpose='Reporting' />
          </FadeIn>
        </FadeIn>
        <AssignedMetric targetModelId={targetModelId} />
      </header>
      <section className={classes.diagrams}>
        <AdvisorViewTabs.Group tabOptions={{
          sankey: 'Flow',
          tree: 'Concentration'
        }}
        >
          <AdvisorViewTabs.Links className={classes.tablinks} />
          <AdvisorViewTabs.Tab value='sankey'>
            <TargetModelSankeyDiagram targetModelId={targetModelId} />
          </AdvisorViewTabs.Tab>
          <AdvisorViewTabs.Tab value='tree'>
            <TargetModelTreeMapDiagram targetModelId={targetModelId} />
          </AdvisorViewTabs.Tab>
        </AdvisorViewTabs.Group>
      </section>
      <div className={classes.group}>
        <FadeIn className={classes.components}>
          <header>Component Models</header>
          <ComponentModelTable targetModelId={targetModelId} />
        </FadeIn>
        <FadeIn className={classes.effectiveModel}>
          <header>Effective Model</header>
          <EffectiveModelTable targetModelId={targetModelId} />
        </FadeIn>
      </div>
    </div>
  )
}

ModelView.propTypes = {

}

export default ModelView
