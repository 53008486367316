import React from 'react'
import PropTypes from 'prop-types'
import { Tooltip } from '@material-ui/core'

const TooltipTitleCell = (props) => {
  const { column } = props

  if (column?.tooltipTitle) {
    return (
      <Tooltip title={column.tooltipTitle}>
        <span>{column.header}</span>
      </Tooltip>
    )
  }

  return <span>{column.header}</span>
}

TooltipTitleCell.propTypes = {
  column: PropTypes.shape({
    header: PropTypes.string,
    tooltipTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  })
}

export default TooltipTitleCell
