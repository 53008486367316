import React, { useMemo } from 'react'
import clsx from 'clsx'
import isEmpty from 'lodash/isEmpty'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { Tooltip } from '@material-ui/core'
import { useQuery } from '@tanstack/react-query'
import { useGetReportRuntimeConfigurations, useGetReports, useGetReportTemplates } from '../../../../api/reports'
import { fetchLevelDates } from '../../../../service'
import { QUERY_KEYS } from '../../../../api/queryKeys'
import { useAppContext } from '../../../../redux/slices/appContext'
import ReportRunActions from './ReportRunActions'

dayjs.extend(utc)

const statusMap = new Map([
  [1, { label: 'In Progress', class: 'inProgress' }],
  [2, { label: 'In Progress', class: 'inProgress' }],
  [3, { label: 'In Progress', class: 'inProgress' }],
  [4, { label: 'Completed', class: 'success' }],
  [5, { label: 'Error', class: 'warning' }],
  [6, { label: 'Shared', class: 'shared' }]
])

export const cellTemplates = {
  date: ({ value }) => value ? dayjs.utc(value).format('M/D/YYYY') : '',
  status: ({ classes, statusMap }) => ({ row }) => (
    <div className={classes.statusContainer}>
      <div className={clsx(classes.status, classes[statusMap.get(row.original?.status)?.class] ?? 'inProgress')}>
        <Tooltip key={row.original.reportRunId} title={row.original.statusDescription} arrow placement='top'>
          <span>{statusMap.get(row.original?.status)?.label ?? 'In Progress'}</span>
        </Tooltip>
      </div>
    </div>
  ),
  createdBy: ({
    row: {
      original: { createdByFirstName, createdByLastName }
    }
  }) => `${createdByFirstName} ${createdByLastName}`
}

export const useReportsColumnConfig = ({
  classes = {},
  includeRunBy = true,
  includeTemplate = true,
  isTableLoading,
  onPostRerunReport,
  onSetPreviewUrl,
  onSetPostToClientVault
}) => {
  return useMemo(() => ({
    columns: [
      { Header: 'Run Date', accessor: 'createdDate', id: 'createdDate', Cell: cellTemplates.date, disableSortBy: false },
      { Header: 'Report Name', accessor: 'title', id: 'title', disableSortBy: false },
      { Header: 'As of Date', accessor: 'endDate', id: 'endDate', Cell: cellTemplates.date, disableSortBy: true },
      { Header: 'Client Name', accessor: 'clientName', id: 'clientName', disableSortBy: true },
      ...(includeTemplate ? [{
        Header: 'Template',
        accessor: 'templateName',
        id: 'templateName',
        disableSortBy: true
      }] : []),
      ...(includeRunBy ? [{
        Header: 'Run by',
        accessor: 'createdByLastName',
        id: 'createdByLastName',
        Cell: cellTemplates.createdBy,
        disableSortBy: false
      }] : []),
      { Header: 'Status', accessor: 'statusDescription', id: 'status', Cell: cellTemplates.status({ classes, statusMap }) },
      {
        Header: 'Actions',
        accessor: 'reportRunId',
        id: 'reportRunId',
        Cell: ({ row }) => ReportRunActions({
          row,
          isTableLoading,
          onSetPreviewUrl,
          onPostRerunReport,
          onSetPostToClientVault
        }),
        disableSortBy: true
      }
    ],
    defaultSort: [
      { id: 'createdDate', desc: true }
    ],
    defaultPageSize: 100
  }), [includeTemplate, includeRunBy, classes, isTableLoading, onSetPreviewUrl, onPostRerunReport, onSetPostToClientVault])
}

export const mapSort = (sorts) => {
  if (!sorts || !sorts.length) {
    return {}
  }
  const sortMapping = {
    statusDescription: 'status'
  }

  // return format as an orderByString with order as appended string
  // as reports api expects `orderBy` instead of `id` and `desc` boolean
  return {
    orderBy: `${sortMapping[sorts[0].id] ?? sorts[0].id}${sorts[0].desc ? ' DESC' : ''}`
  }
}

export const useListReportsData = ({
  pageIndex,
  pageSize,
  sort,
  filterToUser,
  excludeBatchRuns = true,
  batchRunId
}) => {
  const formattedSort = mapSort(sort)

  const query = useMemo(() => {
    return {
      excludeBatchRuns,
      batchRunId,
      additionalColumns: 'clientId,clientName,endDate',
      offset: pageIndex * pageSize,
      limit: pageSize,
      filterToUser,
      ...formattedSort
    }
  }, [excludeBatchRuns, batchRunId, pageIndex, pageSize, filterToUser, formattedSort])

  const {
    data,
    isLoading,
    isPreviousData,
    isFetching,
    error,
    refetch
  } = useGetReports(query)

  return {
    data: isLoading ? [] : data?.data ?? [],
    loading: isLoading || isPreviousData || isFetching,
    error,
    refetch
  }
}

export const useListReportTemplatesData = ({
  pageIndex,
  pageSize,
  sort
}) => {
  const formattedSort = mapSort(sort)

  const query = useMemo(() => {
    return {
      offset: pageIndex * pageSize,
      limit: pageSize,
      ...formattedSort
    }
  }, [pageSize, pageIndex, formattedSort])

  const {
    data,
    isLoading,
    isPreviousData,
    error,
    refetch,
    isStale
  } = useGetReportTemplates(query)

  const templatesById = useMemo(() => {
    if (isEmpty(data) || isEmpty(data.data) || isLoading) return {}
    return data.data.reduce((acc, template) => ({
      ...acc,
      [template.templateId]: template
    }), {})
  }, [data, isLoading])

  return {
    data: isLoading ? [] : data?.data ?? [],
    loading: isLoading || isPreviousData,
    error,
    refetch,
    templatesById,
    isStale
  }
}

export const useListReportRuntimeConfigurationData = ({
  templateId,
  pageIndex,
  pageSize,
  sort
}) => {
  const formattedSort = mapSort(sort)

  const query = useMemo(() => {
    return {
      templateId: templateId,
      offset: pageIndex * pageSize,
      limit: pageSize,
      ...formattedSort
    }
  }, [templateId, pageSize, pageIndex, formattedSort])

  const {
    data,
    isLoading,
    error,
    refetch,
    isStale
  } = useGetReportRuntimeConfigurations(query)

  const runtimeConfigurationById = useMemo(() => {
    if (isEmpty(data) || isEmpty(data.data) || isLoading) return {}
    return data.data.reduce((acc, runtimeConfiguration) => ({
      ...acc,
      [runtimeConfiguration.runtimeConfigurationId]: runtimeConfiguration
    }), {})
  }, [data, isLoading])

  return {
    data: isLoading ? [] : data.data,
    loading: isLoading,
    error,
    refetch,
    runtimeConfigurationById,
    isStale
  }
}

export const useAsOfDate = (clientId) => {
  const { userId } = useAppContext()
  return useQuery({
    queryKey: [QUERY_KEYS.reports, 'clientAsOfDate', userId, clientId],
    queryFn: async () => {
      const { data } = await fetchLevelDates({
        levelType: 'client',
        clientIds: [clientId]
      })
      return data
    },
    enabled: !!clientId
  })
}
