import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { noop, isEmpty } from 'lodash'
import { useBoolean } from '../../../../hooks'
import { localStorageHelper } from '../../../../utils/localStorageHelper'
import { getGroupingStorageConfigKey } from '../PerformancePresentationTable/Cells/helpers'

export const GroupingContext = createContext({
  groupings: [],
  selectGrouping: () => {},
  selectedGrouping: null,
  defaultGrouping: null,
  setIsGroupingDateRangePicker: {
    on: noop,
    off: noop,
    toggle: noop
  },
  groupsExpanded: false,
  isGroupingDateRangePickerOpen: false,
  groupingDateRanges: {},
  performanceTableProps: undefined,
  setPerformanceTableProps: undefined,
  groupingDateRangeOptions: undefined
})

const getDefaultSubstitutionOptions = (
  configKey,
  { enabled, substitutionOptions: defaultConfig = [] } = {}
) => {
  if (!configKey || !enabled) return defaultConfig

  const defaultOptions = localStorageHelper.load(configKey)
  const defaultAndConfigAreEqual = defaultOptions?.reduce(
    (acc, option, index) => {
      return acc && option?.defaultValue === defaultConfig[index]?.defaultValue
    }, true
  )
  if (defaultOptions && defaultAndConfigAreEqual) {
    return defaultOptions
  }
  return defaultConfig
}

const useGroupingColumnConfigurations = (
  configurationKey,
  defaultColumnConfigurations
) => {
  const configKey = useMemo(
    () => getGroupingStorageConfigKey(configurationKey, 'columnConfigs'),
    [configurationKey]
  )
  const [columnConfigurations, setColumnConfigurations] = useState({
    ...defaultColumnConfigurations,
    substitutionOptions: getDefaultSubstitutionOptions(
      configKey,
      defaultColumnConfigurations
    )
  })
  useEffect(
    function persistColumnConfigurations () {
      if (
        defaultColumnConfigurations.enabled &&
        !isEmpty(columnConfigurations.substitutionOptions)
      ) {
        localStorageHelper.store(
          configKey,
          columnConfigurations.substitutionOptions
        )
      }
    },
    [columnConfigurations, configKey, defaultColumnConfigurations.enabled]
  )
  return [columnConfigurations, setColumnConfigurations]
}

const getDefaultGroupingDateRanges = (selectedGrouping, groupingDateRanges) => {
  if (selectedGrouping?.value?.groupingDateRanges) {
    return {
      ...(selectedGrouping.value?.groupingDateRanges || {})
    }
  }
  return { ...groupingDateRanges }
}

export const useGroupingContext = () => {
  return useContext(GroupingContext) || {}
}

export const useGroupingContextOptions = (
  /** The groupingOptions, provided as a dictionary of objects or string values */
  groupingOptions,

  /** Should the table underneath default to be expanded on load */
  groupsExpanded,

  /** Default date ranges in the form of a dictionary */
  groupingDateRanges,

  /** Date range options for groupings */
  groupingDateRangeOptions,

  /** Local storage key */
  configurationKey,

  onApplyClick,

  /** Should the local storage share keys across different tabs */
  shareLocalStorageAcrossGroupings,
  options,
  defaultColumnConfigurations
) => {
  /** In the past, the form of groupingDateRanges is ['YTD', 'MTD'], transform it to the new format { 'YTD': 'YTD', 'MTD': 'MTD' } */
  const _groupingDateRanges = useMemo(() => {
    if (Array.isArray(groupingDateRanges)) {
      return groupingDateRanges.reduce((prev, cur) => {
        prev[cur] = cur
        return prev
      }, {})
    }
    return groupingDateRanges
  }, [groupingDateRanges])

  /** We default to the first available grouping */
  const defaultGrouping = useMemo(() => {
    if (isEmpty(groupingOptions)) return {}
    const [key, value] = Object.entries(groupingOptions)?.at(0)
    return { key, value }
  }, [groupingOptions])

  const [selectedGrouping, setSelectedGrouping] = useState(defaultGrouping)

  const defaultDateRanges = useMemo(() => {
    const groupingKey = shareLocalStorageAcrossGroupings
      ? undefined
      : selectedGrouping.key

    const configKey = getGroupingStorageConfigKey(configurationKey, groupingKey)

    if (!configKey) return _groupingDateRanges

    let configuration = localStorageHelper.load(configKey) || []

    if (!isEmpty(configuration)) {
      // upgrade checker
      if (Array.isArray(configuration)) {
        // transform the array to an object
        const configAsObject = configuration.reduce((p, c) => {
          p[c] = c
          return p
        }, {})
        localStorageHelper.store(configKey, configAsObject)
        configuration = configAsObject
      }
    }

    // Only take saved values if they exist with keys in the default
    return Object.entries(_groupingDateRanges).reduce((prev, [key, value]) => {
      if (key in configuration) {
        prev[key] = configuration[key]
      } else {
        prev[key] = value
      }
      return prev
    }, {})
  }, [
    configurationKey,
    _groupingDateRanges,
    selectedGrouping.key,
    shareLocalStorageAcrossGroupings
  ])

  const selectGrouping = useCallback((groupingName) => {
    if (groupingName in groupingOptions) {
      setSelectedGrouping({ key: groupingName, value: groupingOptions[groupingName] })
    }
  }, [setSelectedGrouping, groupingOptions])

  const groupings = useMemo(() => {
    if (isEmpty(groupingOptions)) {
      return []
    }
    return Object.entries(groupingOptions).map(([key, value]) => ({ key, value }))
  }, [groupingOptions])

  const [groupingsExpanded, setGroupingsExpanded] = useBoolean(groupsExpanded)
  const [isGroupingDateRangePickerOpen, setIsGroupingDateRangePicker] = useBoolean()

  // date ranges used by grouping provider
  const [dateRanges, setDateRanges] = useState(
    getDefaultGroupingDateRanges(selectGrouping, defaultDateRanges)
  )
  const [tempDateRanges, setTempDateRanges] = useState(dateRanges)

  const [performanceTableProps, setPerformanceTableProps] = useState()

  useEffect(() => {
    // if there isn't a global set of date ranges, then look for one at group level
    const groupDateRanges = getDefaultGroupingDateRanges(
      selectedGrouping,
      defaultDateRanges
    )
    setDateRanges(groupDateRanges)
    setTempDateRanges(groupDateRanges)
  }, [selectedGrouping, defaultDateRanges])

  const [columnConfigurations, setColumnConfigurations] =
    useGroupingColumnConfigurations(
      configurationKey,
      defaultColumnConfigurations
    )

  return {
    options,
    groupsExpanded: groupingsExpanded,
    setGroupsExpanded: setGroupingsExpanded,
    defaultGrouping,
    selectedGrouping,
    selectGrouping,
    groupings,
    groupingDateRanges: dateRanges,
    setGroupingDateRanges: setDateRanges,
    tempGroupingDateRanges: tempDateRanges,
    setTempGroupingDateRanges: setTempDateRanges,
    defaultGroupingDateRanges: groupingDateRanges,
    isGroupingDateRangePickerOpen,
    setIsGroupingDateRangePicker,
    performanceTableProps,
    setPerformanceTableProps,
    groupingDateRangeOptions,
    configurationKey,
    onApplyClick,
    shareLocalStorageAcrossGroupings,
    columnConfigurations,
    setColumnConfigurations
  }
}
