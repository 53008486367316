import React, { useCallback, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import { useSearchVaultDocuments } from '../../../api/documentVault'
import DebouncedInput from '../../molecules/DebouncedInput'
import RoundedButton from '../../atoms/RoundedButton'
import FadeIn from '../../molecules/Transitions/FadeIn'
import { useComments } from './CommentContext'
import DocumentSearchResults from './DocumentSearchResults'

const useStyles = makeStyles((theme) => ({
  documentSearch: {
  },
  searchBar: {
    marginBottom: '10px'
  },
  actions: {
    backgroundColor: theme.palette.white,
    position: 'sticky',
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    padding: '24px',
    gap: '15px'
  }
}))

const useSearchEvents = (clientId, levelTypeId, levelId) => {
  const searchRef = useRef()
  const [searchText, setSearchText] = useState('')
  const [selected, setSelected] = useState([])
  const search = useCallback((searchValue) => {
    setSearchText(searchValue)
    setSelected([])
  }, [setSearchText, setSelected])

  const query = useMemo(() => {
    const onlyTags = searchText?.startsWith('#')
    const searchValue = onlyTags ? searchText.slice(1) : searchText
    return {
      skip: 0,
      take: 100,
      filters: {
        clientId: [{ op: 'eq', value: clientId }],
        attachedTo: {
          levelTypeId,
          levelId,
          negate: true
        }
      },
      textSearch: {
        name: (searchValue && !onlyTags) ? [{ op: 'contains', value: searchValue }] : undefined,
        tags: searchValue ? [{ op: 'contains', value: searchValue }] : undefined
      },
      includes: {
        tagDetails: true
      }
    }
  }, [clientId, searchText, levelTypeId, levelId])

  return {
    query,
    searchRef,
    search,
    selected,
    setSelected
  }
}

function DocumentSearch ({ clientId }) {
  const { addAttachment, stopAttaching, finishAttaching, levelTypeId, levelId } = useComments()
  const {
    query,
    searchRef,
    search,
    selected,
    setSelected
  } = useSearchEvents(clientId, levelTypeId, levelId)
  const { data, isLoading, error } = useSearchVaultDocuments(query)
  const classes = useStyles()
  const confirmProps = useMemo(() => {
    return selected.length
      ? {
        text: `Link ${selected.length === 1 ? '' : selected.length} Selected Document${selected.length === 1 ? '' : 's'}`,
        disabled: false
      } : {
        text: 'Link Selected Documents',
        disabled: true
      }
  }, [selected])
  const [attaching, setAttaching] = useState(false)

  return (
    <div className={classes.documentSearch}>
      <div className={classes.searchBar}>
        <DebouncedInput
          ref={searchRef}
          onChange={search}
          placeholder='Search Document Names or Tags'
        />
      </div>
      <DocumentSearchResults
        loading={isLoading}
        documents={data?.data}
        error={error}
        addAttachment={addAttachment}
        selected={selected}
        setSelected={setSelected}
      />
      {!isLoading ? (
        <FadeIn className={classes.actions}>
          <RoundedButton
            onClick={() => {
              setAttaching(true)
              finishAttaching({
                levelTypeId,
                levelId,
                documents: selected
              }).finally(() => {
                setAttaching(false)
              })
            }}
            primary
            disabled={attaching || confirmProps.disabled}
            isLoading={attaching}
          >
            {confirmProps.text}
          </RoundedButton>
          <RoundedButton
            onClick={stopAttaching}
            tertiary
          >
            Cancel
          </RoundedButton>
        </FadeIn>
      ) : null}
    </div>
  )
}

DocumentSearch.propTypes = {
  clientId: PropTypes.number
}

export default DocumentSearch
