import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Box } from '@material-ui/core'
import Text from '../../../atoms/Text'
import NumberTitle from '../components/NumberTitle'
import { useAvailableDates } from '../../../../redux/slices/appContext'
import { getSafeDate } from '../../../../utils'
import { DAYJS_OPERATIONS, DAYJS_UNIT_TYPES, LEVEL_TYPES } from '../../../../constants'
import { useTransactions } from '../../../../api/transactions'

const TransactionsPreview = ({ advisorId }) => {
  const [availableDates] = useAvailableDates()
  const query = useMemo(() => {
    return {
      startDate: getSafeDate(availableDates, {
        operation: DAYJS_OPERATIONS.SUBTRACT,
        unitType: DAYJS_UNIT_TYPES.DAY,
        unitValue: 7
      }),
      endDate: availableDates.mainDate,
      levelTypes: [LEVEL_TYPES.ACCOUNTS],
      advisorIds: [advisorId]
    }
  }, [advisorId, availableDates])

  const { isLoading, data } = useTransactions(query)

  return (
    <>
      <Box display='flex' mb='1.5rem'>
        <Text
          text='Transactions'
          variant='body2'
          customFontSize='1.5rem'
          customFontWeight='bold'
        />
      </Box>
      <NumberTitle
        title={data?.length || 0}
        subtitle='In the last 7 days'
        isLoading={isLoading}
        titleProps={{ customFontSize: '3.5rem' }}
        containerProps={{ justifyContent: 'end' }}
      />
    </>
  )
}

TransactionsPreview.propTypes = {
  advisorId: PropTypes.number
}

export default TransactionsPreview
