import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import NetWorthMetric from './NetWorthMetric'
import MarketValueMetric from './MarketValueMetric'
import ClientCountMetric from './ClientCountMetric'

const useStyles = makeStyles(() => ({
  metrics: {
    margin: '0 20px',
    flex: '1 0 auto',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center'
  },
  metricItem: {
  }
}))

const metricMap = {
  netWorth: NetWorthMetric,
  marketValue: MarketValueMetric,
  clientCount: ClientCountMetric
}

const MetricItem = ({ type, title, className, ...metricProps }) => {
  const MetricComponent = useMemo(() => metricMap[type], [type])

  return (
    <div className={className}>
      <MetricComponent title={title} {...metricProps} />
    </div>
  )
}

MetricItem.propTypes = {
  type: PropTypes.string,
  title: PropTypes.string,
  className: PropTypes.string
}

const Metrics = ({ types }) => {
  const classes = useStyles()
  return (
    <div className={classes.metrics}>
      {types.map(({ type, title, ...metricProps }) => (
        <MetricItem
          key={type}
          type={type}
          title={title}
          className={classes.metricItem}
          {...metricProps}
        />
      ))}
    </div>
  )
}

Metrics.propTypes = {
  types: PropTypes.arrayOf(PropTypes.string)
}

Metrics.defaultProps = {
  types: [{
    type: 'netWorth',
    title: 'Net Worth'
  }, {
    type: 'clientCount',
    title: 'Client Count'
  }]
}

export default Metrics
