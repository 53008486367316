import React from 'react'
import { Box, makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import { ReactMarkdown } from 'react-markdown/lib/react-markdown'
import { useContextContainer } from '../../../abundanceEngine/components/ContextContainer'
import { ASSET_TEAR_CONTEXT } from '.'

const useStyles = makeStyles(() => ({
  body: {
    textAlign: 'justify',
    fontSize: '1rem',
    lineHeight: '1.25rem',
    fontWeight: 'normal'
  }
}))

const AssetTearParagraph = ({ text, textKey, ...props }) => {
  const classes = useStyles()
  const [{ data }] = useContextContainer(ASSET_TEAR_CONTEXT)

  if (textKey && data?.[textKey]) {
    return <ReactMarkdown children={data?.[textKey]} remarkPlugins={[]} />
  }

  return (
    <Box className={classes.body} {...props}>
      {textKey ? data?.[textKey] || '' : text}
    </Box>
  )
}

AssetTearParagraph.propTypes = {
  text: PropTypes.string,
  textKey: PropTypes.string
}

AssetTearParagraph.defaultProps = {
  title: '',
  titleKey: undefined
}

export default AssetTearParagraph
