import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import LabeledValue from '../components/LabeledValue'

const useStyles = makeStyles((theme) => ({
  filters: {},
  item: {
    borderLeft: `2px solid ${theme.palette.gray.dark}`,
    paddingLeft: '.5rem',
    marginBottom: '.5rem'
  }
}))

const PublishingFilters = ({ filters }) => {
  const classes = useStyles()

  if (!filters?.length) {
    return (
      <div className={classes.filters}>
        No Filters
      </div>
    )
  }

  return (
    <div className={classes.filters}>
      <h3>Filters</h3>
      <div>
        {filters.map(filter => (
          <div className={classes.item} key={filter.publishingFilterId}>
            <LabeledValue label='Filter Id'>{filter.publishingFilterId}</LabeledValue>
            <LabeledValue label='Name'>{filter.name}</LabeledValue>
            <LabeledValue label='Type'>{filter.filterType}</LabeledValue>
            <LabeledValue label='Filter Text'>{filter.filterText}</LabeledValue>
            <LabeledValue label='Enabled'>{filter.enabled ? 'Yes' : 'No'}</LabeledValue>
          </div>
        ))}
      </div>
    </div>
  )
}

PublishingFilters.propTypes = {
  filters: PropTypes.arrayOf(PropTypes.shape({
    publishingFilterId: PropTypes.number,
    name: PropTypes.string,
    filterType: PropTypes.string,
    filterText: PropTypes.string,
    enabled: PropTypes.bool
  }))
}

export default PublishingFilters
