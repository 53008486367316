import dayjs from 'dayjs'

export const filterOptions = Object.freeze({
  next7: {
    title: 'Next 7 Days',
    value: {
      filters: {
        entryTypeId: [
          { op: 'eq', value: 1 }
        ],
        entryDate: [
          { op: 'gte', value: dayjs().utc().startOf('day').toISOString() },
          { op: 'lt', value: dayjs().utc().startOf('day').add(7, 'days').toISOString() }
        ]
      },
      sort: [{ field: 'entryDate', dir: 'asc' }]
    }
  },
  next30: {
    title: 'Next 30 Days',
    value: {
      filters: {
        entryTypeId: [
          { op: 'eq', value: 1 }
        ],
        entryDate: [
          { op: 'gte', value: dayjs().utc().startOf('day').toISOString() },
          { op: 'lt', value: dayjs().utc().startOf('day').add(30, 'days').toISOString() }
        ]
      },
      sort: [{ field: 'entryDate', dir: 'asc' }]
    }
  },
  last30: {
    title: 'Last 30 Days',
    value: {
      filters: {
        entryTypeId: [
          { op: 'eq', value: 1 }
        ],
        entryDate: [
          { op: 'lt', value: dayjs().utc().startOf('day').toISOString() },
          { op: 'gte', value: dayjs().utc().startOf('day').add(-30, 'days').toISOString() }
        ]
      },
      sort: [{ field: 'entryDate', dir: 'asc' }]
    }
  }
})
