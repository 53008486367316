import React, { useMemo } from 'react'
import { Link, useHistory } from 'react-router-dom'
import FadeIn from '../../../../molecules/Transitions/FadeIn'
import SectionScreen from '../../shared/SectionScreen'
import SectionHeader from '../../shared/SectionHeader'
import PersonalSpace from '../../shared/PersonalSpace'
import { useManagerContext } from '../ManagerProvider'
import { useAccountSearch } from '../../../../../api/coreData'
import OperationalTable from '../../../../organisms/OperationalTable'
import { ADMIN_ROUTES } from '../../../../../constants'
import { useFormattingContext } from '../../../../organisms/FormattingProvider/FormattingContext'

const cellTemplates = {
  link: (accessor) => ({ row }) => (
    <Link
      onClick={(event) => event.stopPropagation()}
      to={`${ADMIN_ROUTES.ACCOUNTS}/${row.original.accountId}`}
    >
      {accessor(row.original) || 'Link'}
    </Link>
  )
}

export const defaultColumnConfig = {
  columns: [
    { Header: 'Id', accessor: 'accountId', type: 'number', Cell: cellTemplates.link(row => row.accountId) },
    { Header: 'Name', accessor: 'accountName', type: 'string', Cell: cellTemplates.link(row => row.accountName) },
    { Header: 'Number', accessor: 'accountNumber', type: 'string' },
    { Header: 'Start Date', accessor: 'startDate', type: 'string', format: '@MM/DD/YYYY' },
    { Header: 'Close Date', accessor: 'closeDate', type: 'string', format: '@MM/DD/YYYY' },
    { Header: 'Custodian', accessor: 'custodian[0].name', type: 'string' }
  ],
  defaultSort: [{ id: 'accountId', desc: false }]
}

const useColumns = () => {
  const { formatter } = useFormattingContext()

  return useMemo(() => {
    return [...defaultColumnConfig.columns].map(x => {
      if (x.format) {
        x.Cell = (props) => formatter(props.value, x.format)
      }

      return x
    })
  }, [formatter])
}

function AssignmentsTab () {
  const history = useHistory()
  const { manager } = useManagerContext()
  const query = useMemo(() => {
    return {
      filters: {
        managerId: manager.managerId
      },
      includes: {
        custodian: true
      }
    }
  }, [manager])
  const { data, isLoading } = useAccountSearch(query)
  const columns = useColumns()

  return (
    <FadeIn>
      <SectionScreen>
        <SectionHeader text='Account Assignments' />
        <OperationalTable
          variant='v2'
          itemName='Accounts'
          data={data?.data || []}
          loading={isLoading}
          total={data?.data?.length}
          columns={columns}
          defaultSort={defaultColumnConfig.defaultSort}
          onRowClick={(row) => history.push(`${ADMIN_ROUTES.ACCOUNTS}/${row.original.accountId}`)}
        />
        <PersonalSpace />
      </SectionScreen>
    </FadeIn>
  )
}

AssignmentsTab.propTypes = {
}

export default AssignmentsTab
