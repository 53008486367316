import React, { useMemo } from 'react'
import { isEmpty } from 'lodash'
import PropTypes from 'prop-types'
import { useSearchClients } from '../../../../api/clients'
import { useBookmarks } from '../../../../api/groups'
import ClientsList from '../ClientsList'
import { ICON_NAMES } from '../../../../constants'
import AdvisorHomeEmptySection from '../AdvisorHomeEmptySection'

const clientBookmarksQuery = {
  levelType: 'clients'
}

const BookmarkedClients = ({ limit, emptySectionLabel }) => {
  const {
    data: bookmarks = [],
    isLoading: isLoadingBookmarks
  } = useBookmarks(clientBookmarksQuery)

  const clientBookmarkQuery = useMemo(() => {
    const clientBookmarked = bookmarks.map(({ levelId }) => levelId)
    return {
      query: {
        filters: {
          clientId: [{ op: 'in', value: clientBookmarked }]
        },
        take: limit,
        includes: {
          avatars: true
        }
      },
      queryOptions: {
        enabled: !isEmpty(bookmarks),
        mapper: ({ data }) => data
      }
    }
  }, [limit, bookmarks])

  const { data: bookmarkedCards = [], isLoading } = useSearchClients(
    clientBookmarkQuery.query,
    clientBookmarkQuery.queryOptions
  )

  if (
    (isEmpty(bookmarkedCards) && !isLoading) ||
    (isEmpty(bookmarks) && !isLoadingBookmarks)
  ) {
    return (
      <AdvisorHomeEmptySection
        description={emptySectionLabel}
        icon={ICON_NAMES.paperBookmarkClient}
      />
    )
  }

  return <ClientsList clients={bookmarkedCards} isLoading={isLoading} />
}

BookmarkedClients.propTypes = {
  limit: PropTypes.number,
  emptySectionLabel: PropTypes.string
}

BookmarkedClients.defaultProps = {
  limit: 5,
  emptySectionLabel: 'You have no bookmarked clients'
}

export default BookmarkedClients
