import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import { ASSET_FILTERS } from '../../constants'
import SplitButton from '../atoms/SplitButton'

const useStyles = makeStyles((theme) => ({
  listContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: '5px 0px'
  },
  listItem: {
    margin: '5px 0px 5px 12px'
  },
  button: {
    backgroundColor: '#F3F4F5',
    fontFamily: theme.typography.fontFamily,
    '&:hover': {
      backgroundColor: theme.palette.seaFoam
    },
    color: theme.palette.getContrastText('#F3F4F5')
  }
}))

const FilterList = ({
  filterItems
}) => {
  const classes = useStyles()

  return (
    <div className={classes.listContainer}>
      {filterItems && filterItems.map(({ label, onRemoveClick, ...filterProps }, index) => {
        return (
          <SplitButton
            key={`filter-item-${index}`}
            text={label}
            className={classes.listItem}
            buttonClassName={classes.button}
            onClickIcon={() => onRemoveClick(filterProps)}
            iconFontSize='0.825rem'
          />
        )
      })}
    </div>
  )
}

FilterList.propTypes = {
  filterItems: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    label: PropTypes.string,
    payload: PropTypes.shape({
      classType: PropTypes.oneOf(Object.values(ASSET_FILTERS)),
      assetTree: PropTypes.shape(Object.keys(ASSET_FILTERS).reduce(
        (acc, assetFilterKey) => ({
          ...acc,
          [assetFilterKey]: PropTypes.string
        }),
        {}
      ))
    }),
    onRemoveClick: PropTypes.func
  }))
}

FilterList.defaultProps = {
  filterItems: []
}

export default FilterList
