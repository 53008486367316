import React from 'react'
import ConfigurableView from '../../ConfigurableView'
import TransactionTagging from './TransactionTagging'

function TransactionTaggingIndex () {
  return (
    <ConfigurableView viewPath='@admin-transaction-tagging'>
      <TransactionTagging />
    </ConfigurableView>
  )
}

export default TransactionTaggingIndex
