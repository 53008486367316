import React from 'react'
import PropTypes from 'prop-types'
import ClientMarketValue from './ClientMarketValue'
import ClientNetWorthValue from './ClientNetWorthValue'

export const CLIENT_METRIC_TYPES = {
  marketValue: 'marketValue',
  netWorth: 'netWorth'
}

const ClientMetric = ({ title, type, ...props }) => {
  if (type === CLIENT_METRIC_TYPES.marketValue) {
    return <ClientMarketValue title={title} {...props} />
  }
  if (type === CLIENT_METRIC_TYPES.netWorth) {
    return <ClientNetWorthValue title={title} {...props} />
  }

  throw new Error(`Unknown client metric type: ${type}`)
}

ClientMetric.propTypes = {
  title: PropTypes.string.isRequired,
  type: PropTypes.oneOf(Object.values(CLIENT_METRIC_TYPES)).isRequired
}

ClientMetric.defaultProps = {
  title: 'Market Value',
  type: CLIENT_METRIC_TYPES.marketValue
}

export default ClientMetric
