import React, { useCallback, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Box, CircularProgress, makeStyles } from '@material-ui/core'
import noop from 'lodash/noop'
import isEmpty from 'lodash/isEmpty'
import { TEXT_VARIANTS } from '../../constants'
import RoundedButton from '../atoms/RoundedButton'
import Text from '../atoms/Text'
import { editDocuments, getTags } from '../../service'
import { useToggle } from '../../hooks'
import { fileRejections } from '../../prop-types'
import { useAppContext } from '../../redux/slices/appContext'
import DocumentVaultFileCard from './DocumentVaultFileCard'
import DocumentVaultFileRejectionMessageList from './DocumentVaultFileRejectionMessageList'
import CheckBoxItem from './CheckBoxItem'

const useStyles = makeStyles(() => ({
  buttonsSection: {
    display: 'flex',
    '& button:first-child': {
      marginRight: '0.5rem'
    },
    '& button:nth-child(2)': {
      marginLeft: '0.5rem'
    }
  },
  circularProgress: {
    position: 'absolute',
    right: '10%'
  },
  cardsContainer: {
    overflowY: 'auto',
    width: '35rem',
    maxHeight: 'calc(100% - 14rem)'
  },
  cardFileRejectionMessagesContainer: {
    width: '35rem',
    marginBottom: '1rem',
    paddingRight: '1rem'
  },
  cardFileUploadPreviewsContainer: {
    width: '35rem',
    marginTop: '1rem',
    paddingRight: '1rem'
  }
}))

const DocumentVaultUploadFilePreview = ({
  files,
  onFinish,
  clientId,
  onDeleteFile,
  fileRejections,
  openUploadFileWindow,
  showPreviewPlaceHolder,
  showFileRejectionsMessage
}) => {
  const classes = useStyles()
  const [loading, , loadingToggleOn, loadingToggleOff] = useToggle(false)
  const [tagsLoading, , tagsLoadingToggleOn, tagsLoadingToggleOff] = useToggle(true)
  const [documents, setDocuments] = useState([])
  const [tags, setTags] = useState([])
  const [notifyClient, setNotifyClient] = useState(false)

  const appContext = useAppContext()

  useEffect(() => {
    async function fetchTags () {
      const {
        data: { rows: tags }
      } = await getTags()
      setTags(tags.map(({ id, name }) => ({ id, label: name })))
      tagsLoadingToggleOff()
    }

    try {
      fetchTags()
    } catch (err) {
      console.error(err)
      tagsLoadingToggleOff()
    }
  }, [tagsLoadingToggleOn, tagsLoadingToggleOff])

  const onAddTag = useCallback(
    (tag) => setTags((prevState) => [...prevState, { ...tag }]),
    []
  )

  const updateDocument = useCallback((document) => {
    setDocuments((prevDocuments) => {
      return [...prevDocuments, document].reduce((prevDocuments, document) => {
        const documentIndex = prevDocuments.findIndex(
          (prevDocument) => prevDocument.id === document.id
        )
        if (documentIndex === -1) {
          prevDocuments.push(document)
          return prevDocuments
        }
        prevDocuments[documentIndex] = {
          ...prevDocuments[documentIndex],
          ...document
        }
        return prevDocuments
      }, [])
    })
  }, [])

  const onFinishHandler = useCallback(async () => {
    try {
      loadingToggleOn()
      const documentsFormatted = documents.map((document) => ({
        ...document,
        shouldNotifyWealthOwner: notifyClient,
        tags: !isEmpty(document.tags)
          ? document.tags.map((tag) => ({ id: tag.id }))
          : []
      }))

      const { data: updatedDocuments } = await editDocuments(clientId, documentsFormatted)
      onFinish(updatedDocuments)
    } catch (err) {
      console.error(err)
      onFinish([])
    } finally {
      loadingToggleOff()
    }
  }, [clientId, documents, onFinish, loadingToggleOff, loadingToggleOn, notifyClient])

  const isOnFinishButtonDisabled = useMemo(
    () =>
      !documents.every(
        (document) => document.uploadCompleted || document.cancelled
      ) || tagsLoading,
    [documents, tagsLoading]
  )

  const onCheckChange = useCallback((e) => {
    setNotifyClient(e.target.checked)
  }, [setNotifyClient])

  return (
    <Box p={2} height='100%'>
      <Box mb={4}>
        <Text text='Files' variant={TEXT_VARIANTS.h1} customFontWeight='bold' />
      </Box>
      {showFileRejectionsMessage && (
        <div className={classes.cardFileRejectionMessagesContainer}>
          <DocumentVaultFileRejectionMessageList
            fileRejections={fileRejections}
          />
        </div>
      )}
      <div className={classes.cardsContainer}>
        {!tagsLoading &&
          files.map(({ file, documentSpecification }, index) => {
            const isLastItem = files.length - 1 === index
            return (
              <Box mb={isLastItem ? 0 : 2} key={`${file.name}-${file.size}`}>
                <DocumentVaultFileCard
                  file={file}
                  tags={tags}
                  onAddTag={onAddTag}
                  clientId={clientId}
                  documentSpecification={documentSpecification}
                  onUpdateDocument={updateDocument}
                  onDeleteFile={() => onDeleteFile(documentSpecification)}
                />
              </Box>
            )
          })}
        {tagsLoading && <DocumentVaultFileCard showLoadingPlaceHolder />}
      </div>
      {showPreviewPlaceHolder && (
        <div className={classes.cardFileUploadPreviewsContainer}>
          <DocumentVaultFileCard showPlaceHolder />
        </div>
      )}
      {appContext.isAdvisor && (
        <Box mt={4}>
          <CheckBoxItem checked={notifyClient} onChange={onCheckChange}>Notify client of new document upload</CheckBoxItem>
        </Box>
      )}
      <Box mt={4}>
        <div className={classes.buttonsSection}>
          <RoundedButton
            variant='outlined'
            fullWidth
            onClick={openUploadFileWindow}
          >
            Upload more files
          </RoundedButton>
          <RoundedButton
            primary
            fullWidth
            disabled={loading || isOnFinishButtonDisabled}
            onClick={onFinishHandler}
          >
            Finish
            {loading && (
              <CircularProgress
                className={classes.circularProgress}
                size={20}
              />
            )}
          </RoundedButton>
        </div>
      </Box>
    </Box>
  )
}

DocumentVaultUploadFilePreview.propTypes = {
  clientId: PropTypes.number,
  files: PropTypes.arrayOf(PropTypes.object),
  fileRejections,
  onDeleteFile: PropTypes.func,
  openUploadFileWindow: PropTypes.func,
  showPreviewPlaceHolder: PropTypes.bool,
  showFileRejectionsMessage: PropTypes.bool,
  onFinish: PropTypes.func
}

DocumentVaultUploadFilePreview.defaultProps = {
  clientId: undefined,
  files: [],
  fileRejections: [],
  onDeleteFile: noop,
  onFinish: noop,
  openUploadFileWindow: noop,
  showPreviewPlaceHolder: false,
  showFileRejectionsMessage: false
}

export default DocumentVaultUploadFilePreview
