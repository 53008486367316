import React, { useCallback } from 'react'
import { useClassifyAssetsContext } from '../ClassifyAssetsContext'
import { COLUMN_IDS } from '../helpers'
import SelectWithCheckbox from '../../../../molecules/Select/SelectWithCheckbox'
import Skeleton from '../../../../atoms/Skeleton'
import { SKELETON_VARIANTS } from '../../../../../constants'

const AssetStaticFilters = () => {
  const {
    assetTypes,
    assetsFilters,
    visibleFilters,
    onChangeFilters,
    isLoadingAssetTypes
  } = useClassifyAssetsContext()

  const onChangeFilter = useCallback(
    (filterKey) => (values) => {
      onChangeFilters((prevState) => {
        return {
          ...prevState,
          [filterKey]: values
        }
      })
    },
    [onChangeFilters]
  )

  const showAssetTypeFilter = visibleFilters.includes(COLUMN_IDS.ASSET_TYPE)

  if (!Object.values(COLUMN_IDS).some((val) => visibleFilters.includes(val))) {
    return null
  }

  if (showAssetTypeFilter) {
    if (isLoadingAssetTypes) {
      return (
        <Skeleton variant={SKELETON_VARIANTS.text} height='3rem' width='8rem' />
      )
    }
    return (
      <SelectWithCheckbox
        placeholder='Asset Type'
        prefixLabel='Asset Type'
        options={assetTypes}
        onChange={onChangeFilter('assetTypeFilters')}
        selectedOptions={assetsFilters.assetTypeFilters}
      />
    )
  }

  return null
}

export default AssetStaticFilters
