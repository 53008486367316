import React, { useCallback, useImperativeHandle, useState } from 'react'
import { makeStyles } from '@material-ui/core'
import remarkGfm from 'remark-gfm'
import ReactMarkdown from 'react-markdown'
import RightPanel from '../RightPanel'
import PersonalSpace from '../../molecules/PersonalSpace'

const useStyles = makeStyles((theme) => ({
  panel: {
    position: 'relative',
    height: '100%',
    '& .__title': {
      padding: '20px 1.5rem',
      fontSize: '14px'
    },
    padding: theme.layout.padding.medium,
    fontWeight: theme.typography.weights.light
  }
}))

const DEFAULT_STATE = Object.freeze({ open: false, description: '', title: '' })

const SuperLongDescriptionPanel = React.forwardRef(function SuperLongDescriptionPanel (_, ref) {
  const classes = useStyles()
  const [state, setState] = useState(DEFAULT_STATE)

  const close = useCallback(() => {
    setState(DEFAULT_STATE)
  }, [setState])

  useImperativeHandle(ref, () => ({
    open: (description, title) => {
      setState({ open: true, description, title })
    }
  }), [setState])

  return (
    <RightPanel
      open={state.open}
      title={state.title}
      toggleOpen={close}
      width='600px'
      height='100%'
      showCloseButton
      titleClassName='__large'
    >
      <div className={classes.panel}>
        <ReactMarkdown className={classes.description} children={state.description} remarkPlugins={[remarkGfm]} />
        <PersonalSpace />
      </div>
    </RightPanel>
  )
})

export default SuperLongDescriptionPanel
