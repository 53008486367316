import { useCallback, useState } from 'react'
import { useIsMounted } from './useIsMounted'

export default function useToggleVisibility (keys) {
  const isMounted = useIsMounted()

  const [value, setValue] = useState(keys)

  const toggleVisility = useCallback(
    (key) => isMounted && setValue((prev) => {
      return {
        ...prev,
        [key]: !prev[key]
      }
    }), [isMounted]
  )

  return [value, toggleVisility]
}
