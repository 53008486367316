import React from 'react'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core'
import Page from '../../organisms/AdvisorHomeLayout/Page'
import { useTitleEffect } from '../../../redux/slices/appContext'
import { useAdvisorHomeNavigation } from './NavigatationContext'

const useStyles = makeStyles((theme) => ({
  chooseTemplate: {
    padding: '20px 20px 200px'
  },
  header: {
    fontSize: theme.typography.fontSizes.h3,
    padding: '20px 0'
  },
  choices: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: '20px',
    padding: '20px',
    margin: '0 -20px',
    borderTop: `1.5px solid ${theme.palette.gray.darker}`
  },
  item: {
    width: '300px',
    height: '200px',
    outline: `1.5px solid ${theme.palette.gray.main}`,
    borderRadius: '8px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '15px 10px',
    transition: 'outline 200ms ease-in-out',
    '&:hover': {
      textDecoration: 'none !important',
      outline: `1.5px solid ${theme.palette.gray.darker}`
    }
  }
}))

function ChooseTemplate () {
  useTitleEffect('Advisor Home', false)
  const { templateTree } = useAdvisorHomeNavigation()
  const classes = useStyles()

  return (
    <Page className={classes.chooseTemplate}>
      <header className={classes.header}>Choose a Template</header>
      <div className={classes.choices}>
        {templateTree.map(tt => (
          <Link key={tt.advisorViewTemplateId} to={`${tt.path}`} className={classes.item}>
            <div>
              {tt.name}
            </div>
          </Link>
        ))}
      </div>
    </Page>
  )
}

export default ChooseTemplate
