import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { Controller, useForm } from 'react-hook-form'
import { makeStyles } from '@material-ui/core'
import SydButton from '../../../../../commonDesign/Button'
import Loading from '../../../../../molecules/Loading'
import { useTeamMembers } from '../../../../../../api/clients'
import SydLabel from '../../../../../commonDesign/SydLabel'
import SydSelectBox from '../../../../../commonDesign/SydSelectBox'

const useSubmitter = (form, onComplete, teamMembers) => {
  const { handleSubmit } = form
  const onSubmit = useCallback(async (formData) => {
    const teamMember = teamMembers.find(x => x.teamMemberId === +formData.teamMemberId)

    onComplete(teamMember)
  }, [teamMembers, onComplete])

  const submitter = useCallback(async (e) => {
    const onValid = async (form) => {
      await onSubmit(form)
    }
    const onInvalid = (errors) => {
      console.error(errors)
    }

    const handler = handleSubmit(onValid, onInvalid)
    await handler(e)
  }, [handleSubmit, onSubmit])

  return {
    submitter
  }
}

const labelAccessor = ['firstName', 'lastName', 'primaryRoleName']
const useStyles = makeStyles((theme) => ({
  actions: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  group: {
    marginBottom: theme.layout.margin.thick
  }
}))

function AddTeamMemberForm ({ onCancel, onComplete }) {
  const classes = useStyles()
  const form = useForm({
    mode: 'onChange',
    defaultValues: {
      clientId: '',
      teamMemberId: ''
    }
  })

  const { data, isLoading } = useTeamMembers()
  const { submitter, processing, error } = useSubmitter(form, onComplete, data)

  if (isLoading) {
    return <Loading />
  }

  return (
    <>
      <div className={classes.form}>
        <div className={classes.group}>
          <Controller
            name='teamMemberId' control={form.control}
            render={(f => (
              <SydLabel label='Select Team Member'>
                <SydSelectBox
                  options={data}
                  valueAccessor='teamMemberId'
                  labelAccessor={labelAccessor}
                  {...f.field}
                />
              </SydLabel>
            ))}
          />
        </div>
      </div>
      <div className={classes.actions}>
        {error ? (<div className={classes.error}>{error}</div>) : null}
        <SydButton disabled={processing} variant='ghost' size='md' onClick={onCancel}>Cancel</SydButton>
        <SydButton
          variant='primary'
          size='md'
          onClick={submitter}
          processing={processing}
        >
          Add Team Member
        </SydButton>
      </div>
    </>
  )
}

AddTeamMemberForm.propTypes = {
  onComplete: PropTypes.func,
  onCancel: PropTypes.func
}

export default AddTeamMemberForm
