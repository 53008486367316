import React from 'react'
import PropTypes from 'prop-types'
import LineChartContainerV3 from '../LineChartContainerV3'
import LineChartContainerV2 from '../LineChartContainerV2'
import LineChartV4 from './v4'

const PerformanceLineChart = ({ version, ...props }) => {
  if (version === 'v4') {
    return <LineChartV4 {...props} />
  }
  if (version === 'v3') {
    return <LineChartContainerV3 {...props} />
  }
  return <LineChartContainerV2 {...props} />
}

PerformanceLineChart.propTypes = {
  version: PropTypes.string
}

PerformanceLineChart.defaultProps = {
  version: ''
}

export default PerformanceLineChart
