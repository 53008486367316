import React, { useEffect, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'
import { parseUrlSearchParams } from '../../../utils/parseUrlSearchParams'
import { LOCAL_STORAGE_KEYS } from '../../../constants'
import { isJson } from '../../../utils'
import AdvisorHomeContextProvider from '../../pages/advisorHome/AdvisorHomeContextProvider'

function replaceUrlQueryWithArrayParams (
  arrayParams,
  location,
  preserveCurrentSearch = false
) {
  const currentParams = Object.fromEntries([
    ...new URLSearchParams(location.search)
  ])
  const params = arrayParams.reduce(
    (acc, { pathSegment, values }) => ({ ...acc, [pathSegment]: values }),
    preserveCurrentSearch ? currentParams : {}
  )
  const urlParams = new URLSearchParams(params)
  return `${location.pathname}?${urlParams.toString()}`
}

const getDefaultScope = (scopes, defaultValues = []) => {
  if (!scopes?.length) return null
  return {
    ...scopes.at(0),
    values: defaultValues
  }
}

const useScopeFromQueryString = (scopes, storageKey, disableUrl) => {
  const history = useHistory()
  const search = history.location.search

  const searchParams = useMemo(() => {
    if (!disableUrl) {
      return parseUrlSearchParams({ location: { search } })
    }
    return null
  }, [disableUrl, search])

  /**
   * Check url (if applicable, then check local storage)
   * @type {{urlState: *, scope: {values: []}|null, storageKey: *}}
   */
  const scopeObject = useMemo(() => {
    // get the url scopes
    let scope = disableUrl ? getDefaultScope(scopes) : null
    if (!disableUrl) {
      const appliedScopes = scopes.reduce((prev, cur) => {
        prev.push({
          ...cur,
          values: (searchParams[cur.pathSegment] || []).filter(Boolean)
        })
        return prev
      }, [])

      scope = appliedScopes?.[0] ?? null
    }

    // get the local storage scopes to check if was stored in local storage
    if (scope && isEmpty(scope?.values)) {
      const scopeValues = localStorage.getItem(storageKey)
      if (scopeValues && isJson(scopeValues)) {
        scope.values = JSON.parse(scopeValues)
      }
    }

    return { scope, storageKey, urlState: !disableUrl }
  }, [searchParams, scopes, storageKey, disableUrl])

  /** rewrite parameter array */
  useEffect(() => {
    if (!disableUrl && scopeObject.scope) {
      history.replace(replaceUrlQueryWithArrayParams([scopeObject.scope], history.location, true))
    }
  }, [history, scopeObject, disableUrl])

  return scopeObject
}

const AdvisorHomeScopeProvider = ({ children, scopes, storageKey, disableUrl }) => {
  const scope = useScopeFromQueryString(scopes, storageKey, disableUrl)

  return (
    <AdvisorHomeContextProvider scope={scope}>
      {children}
    </AdvisorHomeContextProvider>
  )
}

AdvisorHomeScopeProvider.propTypes = {
  children: PropTypes.node,
  scopes: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      groupTypeId: PropTypes.number,
      pathSegment: PropTypes.string
    })
  ),
  storageKey: PropTypes.string,
  /** Should we persist scopes to the url? */
  disableUrl: PropTypes.bool
}

AdvisorHomeScopeProvider.defaultProps = {
  storageKey: LOCAL_STORAGE_KEYS.advisorHomeScopeValues
}

export default AdvisorHomeScopeProvider
