import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useListRebalancerClients } from '../../../../api/rebalancer'
import FadeIn from '../../../molecules/Transitions/FadeIn'
import ClientModelCard from './ClientModelCard'

const useStyles = makeStyles((theme) => ({
  page: {
    '& header': {
      fontSize: '20px',
      fontWeight: '100',
      marginBottom: '20px'
    }
  },
  results: {
    display: 'flex',
    flexDirection: 'row',
    gap: '20px',
    flexWrap: 'wrap'
  }
}))

function SelectClientView () {
  const classes = useStyles()
  const { data, isLoading } = useListRebalancerClients()

  return (
    <div className={classes.page}>
      {isLoading ? (
        <div />
      ) : (
        <div>
          <FadeIn>
            <header>Select a client from the list below</header>
          </FadeIn>
          <div className={classes.results}>
            {data.map((d, i) => (
              <ClientModelCard index={i} key={d.clientId} data={d} />
            ))}
          </div>
        </div>
      )}
    </div>
  )
}

export default SelectClientView
