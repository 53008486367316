import React, { useCallback, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Controller, useForm } from 'react-hook-form'
import { Checkbox, CircularProgress } from '@material-ui/core'
import SydButton from '../../../../../commonDesign/Button'
import { useDialogStyles } from '../common'
import { useGroupSearch } from '../../../../../../api/groups'

function AssignMultipleSmallGroupForm ({ onComplete, onClose, groupType, client }) {
  const classes = useDialogStyles()

  const { handleSubmit, ...form } = useForm({
    mode: 'onChange',
    defaultValues: {
      clientId: client.clientId,
      groupTypeId: groupType.groupTypeId,
      uniqueMembers: !!groupType.uniqueMembers,
      groupId: groupType.groups.at(0)?.groupId?.toString() || null,
      groups: groupType.groups.map(x => x.groupId)
    }
  })

  const query = useMemo(() => {
    return {
      filters: {
        levelTypeId: [{ op: 'eq', value: groupType.groupTypeId }]
      },
      take: 10,
      sort: [{ field: 'groupId', dir: 'asc' }]
    }
  }, [groupType])
  const { data, isFetching } = useGroupSearch(query)
  const options = data?.data || []

  const [processing, setProcessing] = useState(false)
  const onAssign = useCallback(async (e) => {
    const onValid = async (form) => {
      setProcessing(true)
      try {
        await onComplete({
          groupIds: form.groups
        })
      } finally {
        setProcessing(false)
      }
    }
    const onInvalid = (errors) => {
      console.error(errors)
    }

    const handler = handleSubmit(onValid, onInvalid)
    try {
      await handler(e)
    } catch (err) {
      alert('There was a problem saving the group assignment')
      console.error(err)
      throw err
    }
  }, [handleSubmit, onComplete])

  return (
    <>
      <div className={classes.form}>
        <div>
          {isFetching ? (
            <CircularProgress />
          ) : options.length ? (
            <Controller
              control={form.control} name='groups'
              render={({ field }) => (
                <table className={classes.checkTable}>
                  <tbody>
                    {options.map(x => (
                      <tr
                        key={x.groupId}
                        onClick={() => {
                          const isChecked = field.value.some(f => f === x.groupId)
                          if (!isChecked) {
                            field.onChange({
                              target: {
                                value: [...field.value, +x.groupId]
                              }
                            })
                          } else {
                            field.onChange({
                              target: {
                                value: [...field.value.filter(f => f !== (+x.groupId))]
                              }
                            })
                          }
                        }}
                      >
                        <td>
                          <Checkbox
                            className={classes.inputLabel}
                            checked={field.value.some(f => f === x.groupId)}
                            value={x.groupId}
                            onChange={(e) => {
                              if (e.target.checked) {
                                field.onChange({
                                  target: {
                                    value: [...field.value, +e.target.value]
                                  }
                                })
                              } else {
                                field.onChange({
                                  target: {
                                    value: [...field.value.filter(f => f !== (+e.target.value))]
                                  }
                                })
                              }
                            }}
                            color='#ff0000'
                          />
                        </td>
                        <td>{x.longName}</td>
                        <td>{x.description}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            />
          ) : (
            <div>No Results</div>
          )}
        </div>
      </div>
      <div className={classes.actions}>
        <SydButton variant='ghost' disabled={isFetching} onClick={onClose}>Cancel</SydButton>
        <SydButton
          variant='primary'
          onClick={onAssign}
          processing={processing}
          disabled={isFetching}
        >
          Assign
        </SydButton>
      </div>
    </>
  )
}

AssignMultipleSmallGroupForm.propTypes = {
  onComplete: PropTypes.func,
  onClose: PropTypes.func,
  groupType: PropTypes.shape({
    groupTypeId: PropTypes.number,
    longName: PropTypes.string,
    uniqueMembers: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
    groups: PropTypes.arrayOf(PropTypes.shape({
      groupId: PropTypes.number
    }))
  }),
  client: PropTypes.object
}

export default AssignMultipleSmallGroupForm
