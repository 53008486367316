/* eslint-disable react/jsx-boolean-value */
import React, { useEffect, useState } from 'react'
import dayjs from 'dayjs'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import isBetween from 'dayjs/plugin/isBetween'
import PropTypes from 'prop-types'
import { ResponsiveLine } from '@nivo/line'
import {
  numFormatter
} from '../../utils'

dayjs.extend(isSameOrAfter)
dayjs.extend(isSameOrBefore)
dayjs.extend(isBetween)

const backReturns = [
  0.12684,
  -0.12512,
  -0.08411,
  -0.00163,
  0.02859,
  0.03405,
  0.02043,
  0.01718,
  -0.01809,
  0.01313,
  0.06893,
  -0.06578,
  0.03931,
  0.01792,
  0.02973,
  0.07868,
  -0.09178,
  0.01786,
  -0.06940,
  0.00429,
  0.03026,
  0.03602,
  0.00484,
  0.02161,
  0.00272,
  -0.02688,
  -0.03895,
  0.05618,
  0.00983,
  0.02808,
  0.02219,
  0.01930,
  0.00055,
  0.01935,
  0.00481,
  0.01158,
  0.00909,
  -0.00039,
  0.03720,
  0.01788,
  0.01820,
  0.03417,
  -0.01943,
  -0.00123,
  -0.00122,
  0.03561,
  0.00091,
  0.01532,
  0.00270,
  0.06599
]

const customTooltipStyle = {
  container: {
    background: 'white',
    color: 'inherit',
    fontSize: 'inherit',
    borderRadius: '2px',
    boxShadow: 'rgb(0 0 0 / 25%) 0px 1px 2px',
    padding: '5px 9px'
  },
  content: {
    whiteSpace: 'pre',
    display: 'flex',
    alignItems: 'center'
  },
  span: ({ color }) => ({
    display: 'block',
    width: '12px',
    height: '12px',
    background: color,
    marginRight: '7px'
  })
}

const chartConfig = {
  fontSize: 15,
  animate: false,
  margin: { top: 30, right: 30, bottom: 30, left: 45 },
  xScale: {
    type: 'time',
    format: '%Y/%m/%d',
    precision: 'day'
  },
  xFormat: 'time: %b %Y',
  yFormat: ' >-,.0d',
  tooltip: (input) => {
    const {
      data: { xFormatted, y },
      color
    } = input.point
    const label = `${xFormatted}, $${numFormatter(y)}`
    return (
      <div style={customTooltipStyle.container}>
        <div style={customTooltipStyle.content}>
          <span style={customTooltipStyle.span({ color })} />
          <span>{label}</span>
        </div>
      </div>
    )
  },
  axisLeft: {
    legend: 'portfolio value',
    legendOffset: 12,
    format: '.3s',
    tickValues: 3
  },
  axisBottom: {
    visible: true,
    format: (value) => {
      return (dayjs(value).format('YYYY') * 1) + 1
    },
    legend: '',
    legendOffset: 0,
    ticks: 0,
    orient: 'bottom'
  },
  curve: 'basis',
  colors: (d) => d.color,
  lineWidth: 3,
  enableGridX: false,
  enableGridY: false,
  pointSize: 0,
  useMesh: true,
  motionStiffness: 175,
  motionDamping: 25
}

const WealthJourneyLine = ({
  data: dataSetBase,
  activeDatePeriod,
  ticks
}) => {
  const [dataSetNew, setDataSetNew] = useState(false)

  useEffect(() => {
    let lastDataItem = dataSetBase.data[0]
    const backReturnSet = []
    backReturns.forEach(item => {
      const newValue = lastDataItem.y / (1 + item)
      const newDate = dayjs(lastDataItem.x).add(-1, 'month').format('YYYY/MM/DD')
      const newDataItem = { x: newDate, y: newValue }
      backReturnSet.unshift(newDataItem)
      lastDataItem = newDataItem
    })

    dataSetBase.data = [...backReturnSet, ...dataSetBase.data]

    lastDataItem = dataSetBase.data[dataSetBase.data.length - 1]
    const forwardDataSet = []
    forwardDataSet.push(lastDataItem)
    for (let i = 0; i < 120; i++) {
      const newValue = lastDataItem.y * (1 + (0.07 / 12))
      const newDate = dayjs(lastDataItem.x).add(1, 'month').format('YYYY/MM/DD')
      const newDataItem = { x: newDate, y: newValue }
      forwardDataSet.push(newDataItem)
      lastDataItem = newDataItem
    }

    lastDataItem = dataSetBase.data[dataSetBase.data.length - 1]
    const forwardDataSetHigh = []
    forwardDataSetHigh.push(lastDataItem)
    for (let i = 0; i < 120; i++) {
      const newValue = lastDataItem.y * (1 + (0.10 / 12))
      const newDate = dayjs(lastDataItem.x).add(1, 'month').format('YYYY/MM/DD')
      const newDataItem = { x: newDate, y: newValue }
      forwardDataSetHigh.push(newDataItem)
      lastDataItem = newDataItem
    }

    lastDataItem = dataSetBase.data[dataSetBase.data.length - 1]
    const forwardDataSetLow = []
    forwardDataSetLow.push(lastDataItem)
    for (let i = 0; i < 120; i++) {
      const newValue = lastDataItem.y * (1 + (0.04 / 12))
      const newDate = dayjs(lastDataItem.x).add(1, 'month').format('YYYY/MM/DD')
      const newDataItem = { x: newDate, y: newValue }
      forwardDataSetLow.push(newDataItem)
      lastDataItem = newDataItem
    }

    const dataSetFormatted = [{
      id: `${dataSetBase.id}_HighlightedSection`,
      color: dataSetBase.color,
      strokeWidth: 5,
      data: dataSetBase.data
    },
    {
      id: `${dataSetBase.id}_HighlightedSection_Forward`,
      color: '#CCCCCC',
      strokeWidth: 15,
      data: forwardDataSet
    },
    {
      id: `${dataSetBase.id}_HighlightedSection_Forward_High`,
      color: '#CCCCCC33',
      strokeWidth: 2,
      data: forwardDataSetHigh
    },
    {
      id: `${dataSetBase.id}_HighlightedSection_Forward_Low`,
      color: '#CCCCCC33',
      strokeWidth: 2,
      data: forwardDataSetLow
    }]

    setDataSetNew(dataSetFormatted)
  }, [
    dataSetBase
  ])

  return (
    <ResponsiveLine
      {...chartConfig}
      layers={[
        'grid',
        'markers',
        'axes',
        'areas',
        'crosshair',
        'lines',
        'points',
        'slices',
        'mesh',
        'legends'
      ]}
      data={dataSetNew}
      markers={[
        {
          axis: 'x',
          value: new Date('2026/01/01'),
          lineStyle: { stroke: '#76C39A', strokeWidth: 2, strokeDasharray: [12, 8] },
          textStyle: { fontFamily: 'GothamPro' },
          legend: 'Retire in 2026!',
          legendOrientation: 'horizontal'
        },
        {
          axis: 'x',
          value: new Date(`${activeDatePeriod.value}/01/01`),
          lineStyle: { stroke: '#21294599', strokeWidth: 2 },
          textStyle: { fontFamily: 'GothamPro', fontWeight: 700 },
          legend: activeDatePeriod.value,
          legendOrientation: 'horizontal'
        },
        {
          axis: 'x',
          value: new Date(`${activeDatePeriod.value}/12/31`),
          textStyle: { fontFamily: 'GothamPro' },
          lineStyle: { stroke: '#21294599', strokeWidth: 2 }
        }
      ]}
      yScale={{
        type: 'linear',
        stacked: false,
        ticks
      }}
      theme={{
        tooltip: {
          text: {
            fontFamily: 'GothamPro'
          }
        },
        axis: {
          legend: {
            text: {
              fontFamily: 'GothamPro',
              fontWeight: 700
            }
          },
          ticks: {
            text: {
              fontFamily: 'GothamPro',
              fontWeight: 700
            }
          }
        }
      }}
    />
  )
}

WealthJourneyLine.propTypes = {
  data: PropTypes.object,
  activeDatePeriod: PropTypes.shape({
    value: PropTypes.number
  }).isRequired,
  ticks: PropTypes.number
}

WealthJourneyLine.defaultProps = {
  data: undefined,
  ticks: 3
}

export default WealthJourneyLine
