import React, { useCallback, useImperativeHandle, useMemo, useState } from 'react'
import RoundedModal from '../../../../molecules/RoundedModal'
import DescribeTemplateItemForm from './DescribeTemplateItemForm'

const DescribeTemplateItemDialog = React.forwardRef(function DescribeTemplateItemDialog (_, ref) {
  const [dialogState, setDialogState] = useState({ open: false, processing: false })
  const close = useCallback(() => {
    setDialogState({ open: false, processing: false })
  }, [setDialogState])

  useImperativeHandle(ref, () => ({
    open: (item) => setDialogState({ open: true, processing: false, item })
  }), [setDialogState])

  const templateItem = useMemo(() => {
    return dialogState.item
  }, [dialogState])

  return (
    <RoundedModal
      title={`Describe Template Item: ${templateItem?.codeName}`}
      open={dialogState.open}
      onClose={close}
      position='top'
      size='xmedium'
      disablePortal={false}
    >
      <div>
        {templateItem ? (
          <DescribeTemplateItemForm templateItem={templateItem} onCancel={close} onComplete={close} />
        ) : null}
      </div>
    </RoundedModal>
  )
})

DescribeTemplateItemDialog.propTypes = {
}

export default DescribeTemplateItemDialog
