import React from 'react'
import PropTypes from 'prop-types'
import AdvisorHomeEmptySection from '../AdvisorHomeEmptySection'
import { ICON_NAMES } from '../../../../constants'

const ClientMap = ({ emptyStateLabel }) => {
  return (
    <AdvisorHomeEmptySection
      icon={ICON_NAMES.paperGlassMagnifier}
      title='No data available'
      description={emptyStateLabel}
      borderedContent
    />
  )
}

ClientMap.propTypes = {
  emptyStateLabel: PropTypes.string
}

export default ClientMap
