import React from 'react'
import { CircularProgress } from '@material-ui/core'
import FadeIn from '../../../molecules/Transitions/FadeIn'

function Loader () {
  return (
    <FadeIn key='loading'>
      <CircularProgress />
    </FadeIn>
  )
}

export default Loader
