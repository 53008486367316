import React from 'react'
import PropTypes from 'prop-types'
import { useWealthJourneyQuery } from '../../../../api/wealthJourney'

const WealthJourneyQueryLoader = ({ queryKey, query, options, noWait, children }) => {
  const {
    data,
    isLoading,
    error
  } = useWealthJourneyQuery(queryKey, query, options)

  if (error && !noWait) {
    return <div>Sorry, there was an error</div>
  }

  if (isLoading && !noWait) {
    return <div>Loading...</div>
  }

  return children(data, { isLoading, error })
}

WealthJourneyQueryLoader.propTypes = {
  queryKey: PropTypes.string,
  query: PropTypes.object,
  options: PropTypes.object,
  noWait: PropTypes.bool,
  children: PropTypes.func.isRequired
}

WealthJourneyQueryLoader.defaultProps = {
  noWait: false
}

export default WealthJourneyQueryLoader
