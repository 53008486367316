import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'
import { useAppContext } from '../../../redux/slices/appContext'
import { useIntegrations } from '../../../hooks'
import { replaceValues } from '../../../utils/replacers'
import { relativeDateRanges } from '../../../hooks/useAbundanceEngineParameters'
import ContextualClientContextProvider from './context/ContextualClientProvider'
import useClientInfo from './hooks/useClientInfo'
import useClientIntegrationData from './hooks/useClientIntegrationData'
import { getIntegrationType } from './helpers'

const getDateRangeQueryFilter = (dateRangeSpec) => {
  const [startDateFilter, endDateFilter] = dateRangeSpec
  const startDateValue = replaceValues(startDateFilter, relativeDateRanges)
  const endDateValue = replaceValues(endDateFilter, relativeDateRanges)
  return [
    { op: 'gte', value: startDateValue },
    { op: 'lte', value: endDateValue }
  ]
}

const mapQueryFilters = (queryFilters) => {
  return Object.entries(queryFilters).reduce(
    (acc, [filterKey, filterValue]) => {
      if (isEmpty(filterValue)) return acc
      return {
        ...acc,
        [filterKey]: getDateRangeQueryFilter(filterValue)
      }
    },
    {}
  )
}

const ContextualClientWrapper = ({
  clientId: _clientId,
  useGlobalClientId,
  actionButtons,
  integrations,
  children,
  tasksFilters,
  meetingsFilters
}) => {
  const { clientId: globalClientId } = useAppContext()
  const [clientId, setClientId] = useState(
    useGlobalClientId ? globalClientId : _clientId
  )

  const clientInfoQuery = useMemo(() => {
    const includes = {}
    const tasksQueryFilters = mapQueryFilters(tasksFilters)
    if (!isEmpty(tasksQueryFilters)) {
      includes.tasks = tasksQueryFilters
    }
    const meetingsQueryFilters = mapQueryFilters(meetingsFilters)
    if (!isEmpty(meetingsQueryFilters)) {
      includes.meetings = meetingsQueryFilters
    }
    return { includes }
  }, [tasksFilters, meetingsFilters])

  const clientInfo = useClientInfo(clientId, clientInfoQuery)

  const integrationsVisibility = useIntegrations()
  const integrationType = useMemo(
    () => getIntegrationType(integrationsVisibility),
    [integrationsVisibility]
  )

  const clientIntegrationParams = useMemo(() => {
    return {
      client: clientInfo?.client,
      integrationType: integrationType,
      integrationSources: integrations
    }
  }, [clientInfo, integrationType, integrations])

  const {
    data: integrationsData,
    isLoading: isLoadingClientIntegrationData
  } = useClientIntegrationData(clientIntegrationParams)

  const clientIntegrationData = useMemo(() => {
    const data = integrationsData?.[integrationType]?.data?.[0] || {}
    return {
      ...data,
      key: integrationType
    }
  }, [integrationType, integrationsData])

  const value = useMemo(
    () => ({
      ...clientInfo,
      actionButtons,
      integrationsMeta: integrations,
      clientIntegrationData: clientIntegrationData,
      clientIntegrationsData: integrationsData,
      setContextualClientId: setClientId,
      isLoadingClientIntegrationData
    }),
    [
      clientInfo,
      integrations,
      actionButtons,
      integrationsData,
      clientIntegrationData,
      isLoadingClientIntegrationData
    ]
  )

  return (
    <ContextualClientContextProvider value={value}>
      {children}
    </ContextualClientContextProvider>
  )
}

ContextualClientWrapper.propTypes = {
  clientId: PropTypes.number,
  children: PropTypes.node,
  actionButtons: PropTypes.array,
  useGlobalClientId: PropTypes.bool,
  integrations: PropTypes.object,
  tasksFilters: PropTypes.shape({
    startDate: PropTypes.arrayOf(PropTypes.string),
    dueDate: PropTypes.arrayOf(PropTypes.string)
  }),
  meetingsFilters: PropTypes.shape({
    startDate: PropTypes.arrayOf(PropTypes.string)
  })
}

ContextualClientWrapper.defaultProps = {
  actionButtons: [],
  integrations: null,
  tasksFilters: {
    startDate: ['$today', '$monthEnd'],
    dueDate: []
  },
  meetingsFilters: {
    startDate: ['$today', '$weekEnd']
  }
}

export default ContextualClientWrapper
