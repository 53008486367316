import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { useParams } from 'react-router-dom'
import { useGetPlatformDataSourceById, useQueryPlatformDataSource } from '../../../../api/ingestion'
import DataSourceTable from './DataSourceTable'
import DataSourceFilter from './DataSourceFilter'

const useStyles = makeStyles(theme => ({
  container: {
    margin: '0.6rem 1rem',
    padding: '.5rem',
    position: 'relative',
    overflow: 'clip'
  },
  header: {
    fontSize: '1rem',
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '1rem',
    '& > *': {
      marginRight: '.5rem'
    }
  },
  tableContent: {
    overflowX: 'scroll',
    paddingTop: '3px'
  },
  dataSourceName: {
    marginRight: '1rem',
    alignSelf: 'center'
  }
}))

const QueryPlatformDataSource = ({ root }) => {
  const classes = useStyles()
  const { platformDataSourceId } = useParams()
  const [filters, setFilters] = useState([])
  const { data: dataSource, isLoading: isDataSourceLoading } = useGetPlatformDataSourceById(platformDataSourceId)
  const query = useMemo(() => {
    if (isDataSourceLoading) {
      return null
    }
    return {
      platformDataSourceId: dataSource.platformDataSourceId,
      limit: 100,
      offset: 0,
      filters: filters,
      options: {
        flatten: true
      }
    }
  }, [dataSource, isDataSourceLoading, filters])
  const { data: queryResult, isLoading: isQueryLoading } = useQueryPlatformDataSource(query)
  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <div className={classes.dataSourceName}>{dataSource?.name}</div>
        <DataSourceFilter
          loading={isDataSourceLoading}
          dataSource={dataSource}
          onFilterChange={setFilters}
        />
      </div>
      <div className={classes.tableContent}>
        <DataSourceTable
          dataSourceLoading={isDataSourceLoading}
          dataLoading={isQueryLoading}
          dataSource={dataSource}
          data={queryResult || []}
        />
      </div>
    </div>
  )
}

QueryPlatformDataSource.propTypes = {
  root: PropTypes.string
}

QueryPlatformDataSource.defaultProps = {
  root: '/data-home'
}

export default QueryPlatformDataSource
