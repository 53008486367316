import { useCallback, useRef } from 'react'
import isEmpty from 'lodash/isEmpty'
import { useDebouncedCallback } from '../../../../hooks'
import { useContextContainer } from '../../../../abundanceEngine/components/ContextContainer'
import { CLIENT_VIEW_CONTEXT } from '..'
import { useAppContext } from '../../../../redux/slices/appContext'
import useSaveBookmarkMutation from './useSaveBookmarkMutation'
import useRemoveBookmarkMutation from './useRemoveBookmarkMutation'

const useClientBookmarks = ({ clientId }) => {
  const { userProfile } = useAppContext()

  const [{ clientBookmarks, isLoadingBookmarks }, setContextContainer] =
    useContextContainer(CLIENT_VIEW_CONTEXT)

  const saveBookmarkMutation = useSaveBookmarkMutation()
  const removeBookmarkMutation = useRemoveBookmarkMutation()

  const clientBookmarked = !isEmpty(clientBookmarks?.[clientId]) ? 1 : 0

  const setClientBookmarks = useCallback(
    (clientId, clientBookmark) => {
      setContextContainer((prevState) => {
        return {
          ...prevState,
          clientBookmarks: {
            ...prevState.clientBookmarks,
            [clientId]: clientBookmark ? { ...clientBookmark } : null
          }
        }
      })
    },
    [setContextContainer]
  )

  const saveClientBookmark = useCallback((bookmark) => {
    saveBookmarkMutation.mutate(bookmark)
  }, [saveBookmarkMutation])

  const saveClientBookmarkDebounced = useRef(
    useDebouncedCallback(saveClientBookmark)
  )

  const removeClientBookmark = useCallback((bookmarkId) => {
    if (bookmarkId) {
      removeBookmarkMutation.mutate(bookmarkId)
    }
  }, [removeBookmarkMutation])

  const removeClientBookmarkDebounced = useRef(
    useDebouncedCallback(removeClientBookmark)
  )

  const persistClientBookmark = useCallback(
    (bookmarked) => {
      if (bookmarked) {
        // temporary bookmark used to optimistically update client bookmarks
        const bookmark = {
          bookmarkId: `new-${Math.floor(Math.random() * 101)}`,
          createdDate: new Date().toISOString(),
          levelId: clientId,
          levelType: 'clients',
          ownerUserId: userProfile.internalId,
          temporal: true
        }
        // update state directly without waiting for react-query
        setClientBookmarks(clientId, bookmark)
        saveClientBookmarkDebounced.current(bookmark)
      } else {
        const bookmark = clientBookmarks?.[clientId]
        if (!bookmark.temporal) {
          setClientBookmarks(clientId, null)
          removeClientBookmarkDebounced.current(bookmark?.bookmarkId)
        }
      }
    },
    [clientBookmarks, clientId, setClientBookmarks, userProfile.internalId]
  )

  return {
    clientBookmarked,
    isLoadingBookmarks,
    persistClientBookmark
  }
}
export default useClientBookmarks
