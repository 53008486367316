import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import LabeledValue from '../components/LabeledValue'

const useStyles = makeStyles((theme) => ({
  postProcesses: {},
  item: {
    borderLeft: `2px solid ${theme.palette.gray.dark}`,
    paddingLeft: '.5rem',
    marginBottom: '.5rem'
  }
}))

const PostProcesses = ({ postProcesses }) => {
  const classes = useStyles()

  if (!postProcesses?.length) {
    return (
      <div className={classes.postProcesses}>
        No Post-Processes
      </div>
    )
  }

  return (
    <div className={classes.postProcesses}>
      <h3>Post-Processes</h3>
      <div>
        {postProcesses.map(pp => (
          <div className={classes.item} key={pp.publishingPostProcessId}>
            <LabeledValue label='Post Process Id'>{pp.publishingPostProcessId}</LabeledValue>
            <LabeledValue label='Name'>{pp.name}</LabeledValue>
            <LabeledValue label='Type'>{pp.type}</LabeledValue>
            <LabeledValue label='Description'>{pp.description}</LabeledValue>
            <LabeledValue label='Enabled'>{pp.enabled ? 'Yes' : 'No'}</LabeledValue>
            <LabeledValue label='Ordinal'>{pp.ordinal}</LabeledValue>
            <LabeledValue label='Process Params'>
              <pre>
                {JSON.stringify(pp.processParams, null, 2)}
              </pre>
            </LabeledValue>
          </div>
        ))}
      </div>
    </div>
  )
}

PostProcesses.propTypes = {
  postProcesses: PropTypes.arrayOf(PropTypes.shape({
    publishingPostProcessId: PropTypes.number,
    name: PropTypes.string,
    type: PropTypes.string,
    description: PropTypes.string,
    processParams: PropTypes.any,
    enabled: PropTypes.bool,
    ordinal: PropTypes.number
  }))
}

export default PostProcesses
