import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { Box, makeStyles } from '@material-ui/core'
import noop from 'lodash/noop'
import PropTypes from 'prop-types'
import SectionHeader from '../../shared/SectionHeader'
import EditButton from '../../shared/EditButton'
import { ACCOUNTS } from '../../../../../policies/admin'
import Skeleton from '../../../../atoms/Skeleton'
import Select from '../../../../molecules/Select'
import RemoveButton from '../../FeeOverrides/RemoveButton'
import { AccountFormSection, useAccountFormContext } from '../AccountFormProvider'
import { useFeeSchedule } from '../../../../../api/billing'
import { ACCOUNT_FORM_NAMES, mapFeeScheduleOptions } from '../helpers'
import { ALERT_SEVERITY, ALERT_VARIANT, FEE_CALC_TYPES } from '../../../../../constants'
import {
  capitalizeFirstLetter,
  feeLimitsDetailFormatter,
  numberToBPS,
  numberToUSD,
  pluralize
} from '../../../../../utils'
import Text from '../../../../atoms/Text'
import SubmitButtons from '../SubmitButtons'
import MenuItem from '../../ClientDetailsView/MenuItem'
import { useCheckPolicy } from '../../../../../hooks'
import { updateAccountFeeSchedules } from '../../../../../service'
import Alert from '../../../../atoms/Alert'
import { mapFormDefaultValues } from './AccountBillingDetails'

const useStyles = makeStyles((theme) => ({
  feeDetail: {
    display: 'flex',
    fontWeight: 400,
    flexDirection: 'row'
  },
  itemRow: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flexBasis: '100%'
  },
  detailsContent: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    border: '2px solid rgb(233, 234, 239)',
    padding: '16px 1.8rem'
  }
}))

const AccountBillingFeeSchedules = ({
  disabled = false,
  onSave = noop
}) => {
  const classes = useStyles()
  const { account, sectionEditing, cancelEdit, editSection, editing } = useAccountFormContext()
  const isEditing = sectionEditing('account_billing_fee_schedules')
  const [isSubmitting, setIsSubmitting] = useState(false)

  const userCanEditBilling = useCheckPolicy(ACCOUNTS.editAccountBilling)

  const formMethods = useForm({
    mode: 'onChange',
    defaultValues: {}
  })

  const {
    setValue,
    handleSubmit,
    reset,
    watch,
    setError,
    formState: { errors, isValid }
  } = formMethods

  const defaultValues = useMemo(() => {
    return mapFormDefaultValues(account)
  }, [account])

  useEffect(() => {
    reset(defaultValues)
  }, [reset, defaultValues])

  const feeScheduleTypeValue = watch(ACCOUNT_FORM_NAMES.feeScheduleType)

  const {
    data: feeScheduleOptions = [],
    isLoading: isLoadingFeeScheduleOptions
  } = useFeeSchedule(mapFeeScheduleOptions)

  const selectedFeeScheduleDetails = useMemo(() => {
    const feeScheduleOverride = feeScheduleOptions.find(
      ({ value }) => value === feeScheduleTypeValue
    )
    if (!feeScheduleOverride) return null

    const feeSchedule = feeScheduleOverride.payload

    return (
      <div className={classes.container}>
        {feeSchedule.details.map((feeScheduleDetails) => {
          return feeScheduleDetails.map((detail, index) => {
            const showTier = detail.calcTypeId === FEE_CALC_TYPES.TIERED_SCHEDULE || detail.calcTypeId === FEE_CALC_TYPES.FLOATING_RATE
            const isDollarRate = detail.calcTypeId === FEE_CALC_TYPES.FLAT_DOLLAR
            const rateNumber = isDollarRate ? detail.rate : numberToBPS(detail.rate)
            const denomination = detail.denomination.toUpperCase()
            const feeDescription = isDollarRate
              ? `${numberToUSD(rateNumber)} ${pluralize(rateNumber, denomination, 'S')}`
              : `${rateNumber} ${denomination}`

            return (
              <div
                key={`fee-${feeSchedule.id}-${index}`}
                className={classes.feeDetail}
              >
                {
                  showTier &&
                    <Text
                      text={feeLimitsDetailFormatter(
                        detail.limits.lower,
                        detail.limits.upper
                      )}
                      customFontWeight='bold'
                    />
                }
                &nbsp;
                <Text
                  text={feeDescription}
                />
              </div>
            )
          })
        })}
      </div>
    )
  }, [
    classes.container,
    classes.feeDetail,
    feeScheduleOptions,
    feeScheduleTypeValue
  ])

  const feeScheduleOptionsRenderer = useCallback(
    ({ payload: feeSchedule }, onChange, checkedIcon) => {
      const { id, name, description } = feeSchedule
      const item = { id, name, description }
      return <MenuItem item={item} checked={checkedIcon} onChange={onChange} />
    },
    []
  )

  const { onChangeFeeScheduleType } = useMemo(() => {
    return Object.values(ACCOUNT_FORM_NAMES).reduce(
      (acc, name) => ({
        ...acc,
        [`onChange${capitalizeFirstLetter(name)}`]: (value) => {
          setValue(name, value, { shouldDirty: true })
        }
      }),
      {}
    )
  }, [setValue])

  const onSubmit = useCallback(async (values) => {
    setIsSubmitting(true)
    try {
      await updateAccountFeeSchedules(account.accountId, {
        feeScheduleId: values.feeScheduleType,
        removeFeeScheduleId: defaultValues.feeScheduleType && !values.feeScheduleType ? defaultValues.feeScheduleType : null
      })
      cancelEdit()
      onSave()
    } catch (err) {
      setError('form', { message: 'Issue saving fee schedules.  Try again' })
    }
    setIsSubmitting(false)
  }, [account.accountId, cancelEdit, defaultValues.feeScheduleType, onSave, setError])

  const onCancel = useCallback(() => {
    cancelEdit()
    reset(defaultValues)
  }, [cancelEdit, defaultValues, reset])

  return (
    <AccountFormSection section='account_billing_fee_schedules'>
      <SectionHeader text='Fee Schedules'>
        <EditButton
          policy={ACCOUNTS.assignFeeSchedules}
          editing={isEditing}
          onClick={() => editSection({
            section: 'account_billing_fee_schedules'
          })}
          disabled={editing}
        />
      </SectionHeader>
      {errors?.form && (
        <Box mb={2}>
          <Alert variant={ALERT_VARIANT.standard} severity={ALERT_SEVERITY.error}>
            {errors.form.message}
          </Alert>
        </Box>
      )}
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={classes.detailsContent}>
            {isLoadingFeeScheduleOptions ? (
              <Skeleton height='2.5rem' width='100%' />
            ) : (
              <div className={classes.itemRow}>
                <Box display='flex' gridGap='8px' flexGrow={1}>
                  <Select
                    disabled={disabled}
                    placeholder='Select Fee Schedule'
                    readOnly={!editing || !userCanEditBilling}
                    value={feeScheduleTypeValue}
                    onChange={onChangeFeeScheduleType}
                    options={feeScheduleOptions}
                    optionsRenderer={feeScheduleOptionsRenderer}
                    error={errors?.feeScheduleType?.message}
                    showCheckMarOnSelectedItems
                  />
                  {Boolean(feeScheduleTypeValue) && isEditing && (
                    <RemoveButton onClick={() => onChangeFeeScheduleType('')} />
                  )}
                </Box>
                {selectedFeeScheduleDetails}
              </div>
            )}
          </div>
          <SubmitButtons
            editMode={isEditing}
            onCancel={onCancel}
            isSubmitting={isSubmitting}
            isFormValid={isValid}
          />
        </form>
      </FormProvider>
    </AccountFormSection>
  )
}

AccountBillingFeeSchedules.propTypes = {
  onSave: PropTypes.func,
  disabled: PropTypes.bool
}

export default AccountBillingFeeSchedules
