import { useMemo } from 'react'
import { useFeatureFlag } from '../redux/slices/appConfig'
import { useCheckPolicies } from '../api/policy'
import { FEATURE_FLAG } from '../constants'

export default function useCheckPolicy (policyName) {
  const { active } = useFeatureFlag(FEATURE_FLAG.ROLES)
  const { data: policies, isLoading } = useCheckPolicies()

  return useMemo(() => {
    if (!active) return true
    if (isLoading) return false
    return Boolean(policies[policyName])
  }, [policyName, active, policies, isLoading])
}
