import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'
import { makeStyles } from '@material-ui/core/styles'
import { Box, Grid } from '@material-ui/core'
import { useHistory } from 'react-router-dom/cjs/react-router-dom'
import { useAlternatives } from '../../../api/coreData'
import { useAppContext } from '../../../redux/slices/appContext'
import { LEVEL_TYPES } from '../../../constants'
import Text from '../../atoms/Text'
import Avatar from '../../atoms/Avatar'
import Skeleton from '../../atoms/Skeleton'
import { useFormattingContext } from '../FormattingProvider/FormattingContext'

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: '20px'
  },
  emptyContainer: {
    height: '300px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  list: {
    display: 'flex',
    width: '100%',
    overflow: 'auto',
    flexDirection: 'column',
    padding: '20px',
    border: '2px solid #EEF0F8',
    borderRadius: '8px'
  },
  listItem: {
    marginBottom: '30px',
    cursor: 'pointer'
  },
  assetName: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline'
  },
  avatar: {
    marginRight: '1rem'
  },
  metrics: {
    textAlign: 'right'
  },
  title: {
    marginBottom: '36px'
  }
}))

const useAlternativesQuery = ({ defaultFilter, top }) => {
  const { clientId, availableDates, loadingAvailableDates } = useAppContext()
  const query = useMemo(() => loadingAvailableDates ? null : {
    dateRange: {
      startDate: availableDates.mainDate,
      endDate: availableDates.mainDate
    },
    levelType: LEVEL_TYPES.ASSETS,
    resultType: 'details',
    filters: {
      ...(defaultFilter || {}),
      clientId: clientId,
      fullyFunded: true
    },
    sort: [
      { field: 'capitalCalled', dir: 'desc' }
    ],
    take: top
  }, [clientId, availableDates, loadingAvailableDates, defaultFilter, top])

  const { data, isLoading } = useAlternatives(query)

  return {
    data: data || [],
    isLoading
  }
}

const FullyFunded = ({
  defaultFilter,
  emptyMessage,
  metrics,
  title,
  top,
  assetTearSheetPath
}) => {
  const classes = useStyles()
  const history = useHistory()
  const { formatter } = useFormattingContext()
  const { data = [], isLoading } = useAlternativesQuery({ defaultFilter, top })

  const dataSet = useMemo(() => {
    if (isLoading || isEmpty(data)) return []
    return data.map((item) => {
      const asset = {
        id: item.levelId,
        title: item.assetName,
        assetMetrics: metrics.map((metric) => {
          return {
            value: formatter(item[metric.accessor], metric.format),
            id: metric.accesor
          }
        })
      }
      return asset
    })
  }, [data, isLoading, metrics, formatter])

  const loadingState = useMemo(() => {
    return (
      <Box className={classes.container}>
        <Skeleton height='30px' width='200px' className={classes.title} />
        <Box className={classes.list}>
          <Grid container xs={12} className={classes.listItem}>
            <Grid item xs={6}>
              <Skeleton height='20px' width='200px' className={classes.title} />
            </Grid>
            {metrics.map(({ header }) => (
              <Grid key={header} item xs={6 / metrics.length} className={classes.metrics}>
                <Skeleton height='20px' width='80px' />
              </Grid>
            ))}
          </Grid>

          {Array(top).fill(0).map((_, index) => (
            <Grid contaier className={classes.listItem} key={`skeleton-${index}`}>
              <Skeleton height='30px' />
            </Grid>
          ))}
        </Box>
      </Box>
    )
  }, [top, classes, metrics])

  const onClickAsset = useCallback(
    (id) => (_) => {
      history.push(
        {
          pathname: assetTearSheetPath,
          search: `?id=${id}`
        }
      )
    },
    [assetTearSheetPath, history]
  )

  if (isLoading) {
    return loadingState
  }

  if (isEmpty(dataSet)) {
    return (
      <Box className={classes.emptyContainer}>
        <Text text={emptyMessage} />
      </Box>
    )
  }

  return (
    <Box className={classes.container}>
      <Text
        text={title}
        customFontSize='24px'
        customFontFamily='GothamPro-Bold'
        color='#141929'
        className={classes.title}
      />
      <Box className={classes.list}>
        <Grid container xs={12} className={classes.listItem}>
          <Grid item xs={6}>
            <Text text='Asset Name' color='#141929' customFontSize='18px' />
          </Grid>
          {metrics.map(({ header }) => (
            <Grid key={header} item xs={6 / metrics.length} className={classes.metrics}>
              <Text text={header} customFontSize='12px' />
            </Grid>
          ))}
        </Grid>
        {dataSet.map(
          ({ id, title, assetMetrics }) => {
            return (
              <Grid container xs={12} className={classes.listItem} key={id} onClick={onClickAsset(id)}>
                <Grid item xs={6} className={classes.assetName}>
                  <Avatar
                    src=''
                    avatarLetters={title}
                    useOneInitial
                    customClassName={classes.avatar}
                  />
                  <Text text={title} />
                </Grid>
                {assetMetrics.map((assetMetric) => (
                  <Grid key={assetMetric.id} item xs={6 / metrics.length} className={classes.metrics}>
                    <Text
                      key={assetMetric.id}
                      text={assetMetric.value}
                      color='#141929'
                    />
                  </Grid>
                ))}
              </Grid>
            )
          }
        )}
      </Box>
    </Box>
  )
}

FullyFunded.propTypes = {
  defaultFilter: PropTypes.object,
  emptyMessage: PropTypes.string,
  metrics: PropTypes.arrayOf(
    PropTypes.shape({
      header: PropTypes.string,
      accessor: PropTypes.string,
      format: PropTypes.string
    })
  ),
  title: PropTypes.string,
  top: PropTypes.number,
  assetTearSheetPath: PropTypes.string
}

FullyFunded.defaultProps = {
  defaultFilter: {},
  emptyMessage: 'No fully funded investments',
  metrics: [
    { header: 'Capital Called', accessor: 'capitalCalled', format: 'human' }
  ],
  title: 'Fully Funded',
  top: 5,
  assetTearSheetPath: '/'
}

export default FullyFunded
