import React, { useCallback, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import {
  Tabs as MuiTabs,
  Tab as MuiTab,
  Divider,
  makeStyles,
  withStyles
} from '@material-ui/core'
import isEmpty from 'lodash/isEmpty'
import Fab from '@material-ui/core/Fab'
import AddIcon from '@material-ui/icons/Add'
import get from 'lodash/get'
import { useLocation } from 'react-router-dom'
import {
  DRAWER_WIDTH,
  NOTES_BOARD_HEADERS_FILTER_HEIGHT,
  TRANSFORM_HORIZONTAL,
  TRANSFORM_VERTICAL
} from '../../constants'
import { useAbundanceEngineCurrentView } from '../../redux/slices/abundanceEngineContext'
import { useNoteContext, useNotesBoardToggle, useSetNoteContext } from '../../redux/slices/noteContext'
import { useClientsViewPath, useHomeViewPath } from '../../redux/slices/appConfig'
import { useAppContext } from '../../redux/slices/appContext'
import NoteThread from '../molecules/NoteThread'
import NoteNewReply from '../molecules/NoteNewReply'
import NoteThreadTitle from '../molecules/NoteThreadTitle'
import NoteThreadOverview from '../molecules/NoteThreadOverview'
import NoteThreadSkeleton from '../molecules/NoteThreadSkeleton'
import NoteEmpty from '../molecules/NoteEmpty'
import { activeClientThreadsOverviewInitialState, useNoteThreadContext } from '../../contexts/noteThreadContext'
import NoteThreadClientOverview from '../molecules/NoteThreadClientOverview'
import NoteThreadClientSkeleton from '../molecules/NoteThreadClientSkeleton'

const TITLE_HEIGHT = 56

const useStyles = makeStyles((theme) => ({
  content: ({ embedded, hasViewFilters }) => ({
    position: 'relative',
    overflowX: embedded ? 'auto' : 'hidden',
    overflowY: 'auto',
    height: embedded
      ? '100%'
      : `calc(100vh - ${TITLE_HEIGHT}px${hasViewFilters ? ` - ${NOTES_BOARD_HEADERS_FILTER_HEIGHT}px` : ''})`
  }),
  contentClientOverview: {
    height: `calc(100vh - ${TITLE_HEIGHT}px - 5px) !important`,
    position: 'relative',
    overflow: 'hidden auto'
  },
  panel: ({ embedded }) => ({
    padding: '0',
    width: embedded ? '100%' : DRAWER_WIDTH,
    overflowX: 'hidden'
  }),
  prompt: ({ embedded }) => ({
    position: embedded ? 'static' : 'fixed',
    width: embedded ? '100%' : DRAWER_WIDTH,
    bottom: 0
  }),
  fabContainer: {
    position: 'fixed',
    bottom: '1.5rem',
    right: '1.5rem'
  },
  fabAddButton: {
    backgroundColor: theme.palette.cloudBurst,
    '&:hover': {
      backgroundColor: theme.palette.mirage
    },
    color: theme.palette.getContrastText(theme.palette.cloudBurst)
  }
}))

const StyledTabs = withStyles((theme) => ({
  indicator: {
    display: 'none'
  },
  root: {
    backgroundColor: theme.palette.white,
    padding: '0.25rem',
    boxShadow: '0px -4px 8px rgba(0, 0, 0, 0.1)'
  }
}))((props) => <MuiTabs {...props} />)
StyledTabs.muiName = 'Tabs'

const StyledTab = withStyles((theme) => ({
  root: {
    flex: 1,
    textTransform: 'none',
    borderRadius: '0.25rem',
    height: '3.5'
  }
}))((props) => <MuiTab disableRipple {...props} />)
StyledTab.muiName = 'Tab'

const getThreadsViewId = (_viewId, threads) => {
  if (isEmpty(threads)) return undefined
  if (!threads.every(({ viewId }) => viewId === _viewId)) return undefined
  return get(threads, '0', { viewId: undefined }).viewId
}

const activeThreadInitial = {
  threadId: null
}

function NotesBoard ({ open, onClose, embedded }) {
  const location = useLocation()
  const homeViewPath = useHomeViewPath()
  const abundanceEngineCurrentView = useAbundanceEngineCurrentView()
  const {
    viewId,
    clientId: notesClientId,
    filters: viewNoteFilters,
    filters: notesViewFilters
  } = useNoteContext()

  const setNoteContext = useSetNoteContext()
  const onNotesBoardToggle = useNotesBoardToggle()
  const {
    threads,
    isLoading,
    loadThreads,
    threadsEmpty,
    activeThread,
    setActiveThread,
    clientsThreadsOverview,
    loadClientThreadsOverview,
    clientsThreadOverviewEmpty,
    showCommentsGroupedByClient,
    activeClientThreadsOverview,
    setActiveClientThreadsOverview
  } = useNoteThreadContext()

  const classes = useStyles({ embedded, hasViewFilters: !isEmpty(notesViewFilters) })

  const { isFullViewOpen, fullViewId } = useAppContext()
  const clientViewPath = useClientsViewPath()

  const loadNoteBoardThreads = useCallback((_viewId) => {
    const shouldLoadThreads = !threadsEmpty && isEmpty(threads)

    const currentThreadsViewId = getThreadsViewId(_viewId, threads)
    const givenViewIdIsDifferentThanCurrent = !threadsEmpty &&
      _viewId && currentThreadsViewId !== _viewId

    const shouldShowAllThreads = (!_viewId &&
      threads.every(({ viewId }) => viewId === threads[0].viewId) &&
      !threads.every(({ viewId }) => viewId === null)) ||
      (!_viewId && threadsEmpty && isFullViewOpen && isEmpty(threads))

    const activeClientId = activeClientThreadsOverview.clientId
    const currentClientId = get(threads, '0', { clientId: null }).clientId
    const givenClientIdIsDifferentThanCurrent = activeClientId && activeClientId !== currentClientId

    if (
      shouldLoadThreads ||
      givenViewIdIsDifferentThanCurrent ||
      givenClientIdIsDifferentThanCurrent ||
      shouldShowAllThreads
    ) {
      loadThreads()
    }
  }, [threads, threadsEmpty, loadThreads, isFullViewOpen, activeClientThreadsOverview.clientId])

  useEffect(() => {
    if (open && !isLoading) {
      if (clientViewPath === location.pathname) {
        const shouldLoadClientsOverview = !clientsThreadOverviewEmpty && isEmpty(clientsThreadsOverview)
        if (activeClientThreadsOverview.clientId || notesClientId) {
          loadNoteBoardThreads()
        } else if (shouldLoadClientsOverview) {
          loadClientThreadsOverview()
        }
      } else if (!isEmpty(viewNoteFilters)) {
        const [viewFilter] = viewNoteFilters
        loadNoteBoardThreads(viewFilter.id)
      } else if (!threadsEmpty && isEmpty(threads)) {
        loadNoteBoardThreads()
      }
    }
  }, [
    open,
    viewId,
    threads,
    isLoading,
    threadsEmpty,
    notesClientId,
    location,
    clientViewPath,
    viewNoteFilters,
    loadClientThreadsOverview,
    loadNoteBoardThreads,
    clientsThreadsOverview,
    clientsThreadOverviewEmpty,
    activeClientThreadsOverview.clientId
  ])

  useEffect(() => {
    if (embedded) loadThreads({ viewId: null })
  }, [embedded, loadThreads])

  useEffect(() => {
    const isMultiView = abundanceEngineCurrentView?.path === homeViewPath
    if (isFullViewOpen && viewId && !embedded) {
      onNotesBoardToggle({ open, abundanceEngineCurrentView, isFullViewOpen, isMultiView })
    } else {
      onNotesBoardToggle({
        open,
        view: abundanceEngineCurrentView,
        clearFilter: clientViewPath === location.pathname || location.pathname !== homeViewPath,
        isMultiView
      })
    }
  }, [onNotesBoardToggle, isFullViewOpen, location, abundanceEngineCurrentView, clientViewPath, viewId, open, embedded, homeViewPath])

  const onCreateNewThreadHandler = useCallback(
    (e) => {
      const position = e.target.getBoundingClientRect()
      setNoteContext({
        openNewCommentModal: true,
        modalPosition: {
          top: position.top,
          left: position.left
        },
        viewId: isFullViewOpen ? fullViewId : undefined
      })
    },
    [setNoteContext, isFullViewOpen, fullViewId]
  )

  const clientsThreadsOverviewRendered = useMemo(() => {
    return (
      <>
        <NoteThreadTitle
          title='Comments'
          onClose={onClose}
          height={TITLE_HEIGHT}
          showClose={!embedded}
          showViewFilters={false}
        />
        <div className={classes.contentClientOverview}>
          {clientsThreadsOverview.map(clientThreadsOverview => {
            return (
              <NoteThreadClientOverview
                key={clientThreadsOverview.clientId}
                clientThreadOverview={clientThreadsOverview}
              />
            )
          })}
        </div>
      </>
    )
  }, [onClose, embedded, clientsThreadsOverview, classes.contentClientOverview])

  const generalThreadRendered = useMemo(() => {
    const onBack = showCommentsGroupedByClient
      ? () => {
        loadClientThreadsOverview()
        setActiveClientThreadsOverview(activeClientThreadsOverviewInitialState)
      }
      : null

    return (
      <>
        <NoteThreadTitle
          onBack={onBack}
          title='Comments'
          onClose={onClose}
          height={TITLE_HEIGHT}
          showBack={!embedded}
          showClose={!embedded}
        />
        <div className={classes.content}>
          {isLoading ? (
            <NoteThreadSkeleton repeat={embedded ? 2 : 5} />
          ) : threads?.length ? (
            <>
              {threads.map((thread, index) => (
                <React.Fragment key={`note-thread-${thread.threadId}-${index}`}>
                  <NoteThreadOverview
                    thread={thread}
                    handleOnThreadDetails={() => setActiveThread(thread)}
                  />
                </React.Fragment>
              ))}
            </>
          ) : (
            <NoteEmpty />
          )}
          {!embedded && (
            <div className={classes.fabContainer}>
              <Fab
                className={classes.fabAddButton}
                color='primary'
                aria-label='add'
                onClick={onCreateNewThreadHandler}
              >
                <AddIcon />
              </Fab>
            </div>
          )}
        </div>
      </>
    )
  }, [
    classes.content,
    classes.fabAddButton,
    classes.fabContainer,
    onClose,
    threads,
    embedded,
    isLoading,
    setActiveThread,
    onCreateNewThreadHandler,
    loadClientThreadsOverview,
    showCommentsGroupedByClient,
    setActiveClientThreadsOverview
  ])

  const activeThreadRendered = useMemo(() => {
    if (!activeThread.threadId) return null
    return (
      <>
        <NoteThread
          embedded={embedded}
          handleOnClose={onClose}
          withMaxHeight={false}
          handleOnBack={() => setActiveThread(activeThreadInitial)}
        />
        <div className={classes.prompt}>
          <Divider />
          <NoteNewReply
            threadId={activeThread.threadId}
            threadResolved={activeThread.resolved}
            openModalOnSaveComment={false}
            popOverTransformOrigin={{
              horizontal: TRANSFORM_HORIZONTAL.Left,
              vertical: TRANSFORM_VERTICAL.Top
            }}
          />
        </div>
      </>
    )
  }, [
    onClose,
    embedded,
    activeThread,
    classes.prompt,
    setActiveThread
  ])

  const clientsThreadOverviewLoadingRendered = useMemo(() => {
    return (
      <>
        <NoteThreadTitle
          title='Comments'
          height={TITLE_HEIGHT}
          showClose={!embedded}
          onClose={onClose}
          showViewFilters={false}
        />
        <div className={classes.contentClientOverview}>
          <NoteThreadClientSkeleton repeat={embedded ? 2 : 10} withDivider={false} />
        </div>
      </>
    )
  }, [onClose, embedded, classes.contentClientOverview])

  const notesBoardBodyRendered = useMemo(() => {
    if (!isEmpty(clientsThreadsOverview) && !activeClientThreadsOverview.clientId) {
      return clientsThreadsOverviewRendered
    } else if (isLoading && showCommentsGroupedByClient && !activeClientThreadsOverview.clientId) {
      return clientsThreadOverviewLoadingRendered
    } else if (activeThread.threadId) {
      return activeThreadRendered
    }
    return generalThreadRendered
  }, [
    isLoading,
    activeThread.threadId,
    clientsThreadsOverview,
    activeThreadRendered,
    generalThreadRendered,
    showCommentsGroupedByClient,
    activeClientThreadsOverview,
    clientsThreadsOverviewRendered,
    clientsThreadOverviewLoadingRendered
  ])

  return (
    <div className={classes.panel}>
      {notesBoardBodyRendered}
    </div>
  )
}

NotesBoard.propTypes = {
  open: PropTypes.bool.isRequired,
  embedded: PropTypes.bool,
  onClose: PropTypes.func.isRequired
}

NotesBoard.defaultProps = {
  embedded: false
}

export default NotesBoard
