import React from 'react'
import PropTypes from 'prop-types'
import { Grid, makeStyles } from '@material-ui/core'
import PasswordFactor from './password/PasswordFactor'
import SecurityQuestionFactor from './securityQuestion/SecurityQuestionFactor'
import GoogleAuthenticatorFactor from './google/GoogleAuthenticatorFactor'
import OktaVerifyFactor from './okta/OktaVerifyFactor'

const useStyles = makeStyles((theme) => ({
  factors: {
    '& .__factor-card': {
      border: `2px solid ${theme.palette.gray.main}`,
      borderRadius: '8px',
      padding: '15px',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between'
    },
    '& .__factor-name': {
      fontSize: theme.typography.fontSizes.h5
    },
    '& .__factor-description': {
      fontWeight: theme.typography.weights.light,
      fontSize: theme.typography.fontSizes.xl
    },
    '& .__factor-subtext': {
      fontWeight: theme.typography.weights.light,
      fontSize: theme.typography.fontSizes.md
    },
    '& .__factor-action': {
      padding: '20px 20px 0',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center'
    },
    '& .__factor-details': {
      margin: '10px 0',
      display: 'flex',
      flexDirection: 'column',
      gap: '6px'
    },
    '& .__factor-list': {
      display: 'flex',
      flexDirection: 'row',
      gap: '6px',
      fontWeight: theme.typography.weights.light
    }
  }
}))

function SecurityFactorsForm ({ enrollments }) {
  const classes = useStyles()

  if (!enrollments) {
    return null
  }

  return (
    <Grid container className={classes.factors} spacing={3}>
      <PasswordFactor authenticators={enrollments} />
      <SecurityQuestionFactor authenticators={enrollments} />
      <GoogleAuthenticatorFactor authenticators={enrollments} />
      <OktaVerifyFactor authenticators={enrollments} />
    </Grid>
  )
}

SecurityFactorsForm.propTypes = {
  enrollments: PropTypes.array
}

export default SecurityFactorsForm
