import React from 'react'
import PropTypes from 'prop-types'
import AllocationPieLegendItem from './AllocationPieLegendItem'

const AllocationPieLegend = ({ data }) => {
  return (
    <div>
      {data.map(datum => (
        <AllocationPieLegendItem
          key={datum.id}
          name={datum.label}
          color={datum.color}
          value={datum.balance}
          valueFormat={datum.valueFormat}
        />
      ))}
    </div>
  )
}

AllocationPieLegend.propTypes = {
  data: PropTypes.array
}

export default AllocationPieLegend
