import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'

const useStyles = makeStyles((theme) => ({
  filterButtons: {
    display: 'flex',
    gridGap: '10px',
    marginBottom: '10px',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& > *': {
      width: '400px',
      maxWidth: '100%'
    }
  }
}))

const TableFiltersContainer = ({ children }) => {
  const classes = useStyles()

  return (
    <div className={classes.filterButtons}>
      {children}
    </div>
  )
}

TableFiltersContainer.propTypes = {
  children: PropTypes.node
}

export default TableFiltersContainer
