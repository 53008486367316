import React, { useMemo } from 'react'
import { makeStyles } from '@material-ui/core'
import SaveCancelButtons from '../../../../molecules/SaveCancelButtons'
import { useAbundanceEngineGalleryContext } from '../abundanceEngineGalleryContext'

const useStyles = makeStyles(() => ({
  container: {
    position: 'sticky',
    zIndex: 5000,
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'white'
  }
}))

const AbundanceEngineGalleryFooter = () => {
  const classes = useStyles()
  const { selectedViews, onGenerateWheel, toggleWheelEditMode } =
    useAbundanceEngineGalleryContext()

  const disablePrimaryButton = useMemo(
    () => !Object.values(selectedViews).some(Boolean),
    [selectedViews]
  )

  return (
    <div className={classes.container}>
      <SaveCancelButtons
        primaryButtonLabel='Generate'
        onCancel={toggleWheelEditMode.off}
        onSave={onGenerateWheel}
        disabledPrimaryButton={disablePrimaryButton}
      />
    </div>
  )
}

export default AbundanceEngineGalleryFooter
