import PropTypes from 'prop-types'

export const reportParamsShape = PropTypes.shape({
  levelType: PropTypes.string,
  exposureType: PropTypes.string,
  exposureTarget: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.number),
    PropTypes.number
  ]),
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  dateRangePreset: PropTypes.string
})
