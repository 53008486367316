
import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { FormLabel, Button, Checkbox } from '@material-ui/core'
import get from 'lodash/get'
import { numeralByCase, tableNumberFormatter } from '../../../utils'
import { WEALTHBOX_ITEMS, TEXT_VARIANTS } from '../../../constants'
import { childrenSchema } from '../../../prop-types'
import NumberFormat from '../../atoms/NumberFormat'
import Text from '../../atoms/Text'

import ListItem from '../../molecules/ListItem'

const useStyles = makeStyles((theme) => ({
  sidePanelContent: {
    padding: '0 1.875rem',
    height: 'calc(100% - 1.25rem)',
    overflow: 'auto'
  },
  header: {
    ...theme.typography[TEXT_VARIANTS.subtitle2],
    color: 'inherit'
  },
  label: {
    ...theme.typography[TEXT_VARIANTS.body1],
    color: 'inherit'
  },
  labelNext: {
    ...theme.typography[TEXT_VARIANTS.body1],
    color: 'inherit',
    display: 'inline'
  },
  container: {
    marginBottom: '0.625rem'
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center'
  },
  button: {
    borderRadius: '30px',
    fontWeight: 800,
    textTransform: 'capitalize',
    padding: '1.125rem 3.125rem',
    width: '9.375rem',
    backgroundColor: theme.palette.gray.light,
    color: theme.palette.lightBlack,
    '&:hover': {
      backgroundColor: theme.palette.gray.dark
    }
  }
}))

const renderMultiCheckBoxElement = (data, field, classes) => {
  const values = get(data, field.path.split('.'), [])

  const emptyElement = (
    <FormLabel htmlFor='accountName' className={classes.label}>
      -
    </FormLabel>
  )

  const element = (
    values.map((value, index) => {
      return (
        <FormLabel htmlFor='accountName' className={classes.label} key={index}>
          {value} <br />
        </FormLabel>
      )
    })
  )
  return (values.length === 0) ? emptyElement : element
}

const renderTextFieldElement = (data, field, classes) => {
  const path = (Array.isArray(field.path)) ? field.path : field.path.split('.')
  const value = get(data, path, '-')

  return (
    <FormLabel htmlFor='accountName' className={classes.label}>
      {value}
    </FormLabel>
  )
}

const renderSingleSelectElement = (data, field, classes) => {
  const path = (Array.isArray(field.path)) ? field.path : field.path.split('.')
  const values = get(data, path, [])
  const emptyElement = (
    <FormLabel htmlFor='accountName' className={classes.label}>
      -
    </FormLabel>
  )
  const element = values.map((value, index, collection) => {
    return (
      <FormLabel htmlFor='accountName' className={classes.label} key={index}>
        <ListItem title={value} noBorderBottom={index === collection.length - 1} customPadding='0px 0px' />
      </FormLabel>
    )
  })
  return (values.length === 0) ? emptyElement : element
}

const renderCheckBoxElement = (data, field, classes) => {
  const path = (Array.isArray(field.path)) ? field.path : field.path.split('.')
  const values = get(data, path, [])
  return (
    <FormLabel htmlFor='accountName' className={classes.labelNext}>
      <Checkbox disabled checked={values.includes('on')} />
    </FormLabel>
  )
}

const WealthboxInfoContent = ({ data, onClose }) => {
  const classes = useStyles()

  return (
    <>
      {
        Object.keys(WEALTHBOX_ITEMS).map((key, index) => (
          <div className={classes.container} key={key + index}>
            <h1 className={classes.header}>{WEALTHBOX_ITEMS[key].title}</h1>

            {WEALTHBOX_ITEMS[key].type === 'multi-check-box' && renderMultiCheckBoxElement(data, WEALTHBOX_ITEMS[key], classes)}
            {WEALTHBOX_ITEMS[key].type === 'text-field' && renderTextFieldElement(data, WEALTHBOX_ITEMS[key], classes)}
            {WEALTHBOX_ITEMS[key].type === 'single-select' && renderSingleSelectElement(data, WEALTHBOX_ITEMS[key], classes)}
            {WEALTHBOX_ITEMS[key].type === 'check-box' && renderCheckBoxElement(data, WEALTHBOX_ITEMS[key], classes)}
            {WEALTHBOX_ITEMS[key].isNumber && <NumberFormat number={tableNumberFormatter(data[key])} title={numeralByCase(data[key])} skipFormat />}
          </div>
        ))
      }
      <div className={classes.actions}>
        <Button onClick={onClose} className={classes.button}>
          <Text text='Close' variant={TEXT_VARIANTS.h3} />
        </Button>
      </div>
    </>
  )
}

WealthboxInfoContent.propTypes = {
  data: childrenSchema.isRequired,
  onClose: PropTypes.func.isRequired
}

function WealthboxInfo ({ data, onClose }) {
  const classes = useStyles()

  const content = useMemo(() => {
    return <WealthboxInfoContent data={data} onClose={onClose} />
  }, [data, onClose])

  return (
    <div className={classes.sidePanelContent}>
      {content}
    </div>
  )
}

WealthboxInfo.propTypes = {
  data: PropTypes.object,
  onClose: PropTypes.func
}

WealthboxInfo.defaultProps = {
  data: {},
  onClose: undefined
}

export default React.memo(WealthboxInfo)
