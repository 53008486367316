import React from 'react'
import PropTypes from 'prop-types'
import { Box, Divider, makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  divider: {
    width: '100%',
    borderColor: theme.palette.gallery
  }
}))

const AdvisorViewDivider = ({ className }) => {
  const classes = useStyles()
  return (
    <Box width='100%' py='0' className={className}>
      <Divider className={classes.divider} />
    </Box>
  )
}

AdvisorViewDivider.propTypes = {
  className: PropTypes.string
}

export default AdvisorViewDivider
