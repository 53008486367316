import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import { capitalize, isEmpty } from 'lodash'
import dayjs from 'dayjs'
import { DATA_SETS, EXPOSURE_TYPES, FILTER_LABELS } from '../helpers'
import { useGroupSearch } from '../../../../../api/groups'
import {
  useSearchAssetsDebounced,
  useSearchClassificationTagsDebounced
} from '../../../../../hooks'
import { defaultOptions } from '../../../../molecules/RelativeDateSelect'
import FilterSummaryItem from './FilterSummaryItem'

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    gap: '2.5rem'
  }
}))

const mapGroupTypesSummary = ({ data }) => {
  const groupTypesMeta = data.reduce(
    (acc, { groupType, ...group }) => {
      const groupName = group?.longName || group?.shortName || ''
      const groupTypeName = groupType?.longName || groupType?.shortName || ''
      const groupTypeId = groupType.groupTypeId
      const prevGroupType = acc[groupTypeId]
      return {
        ...acc,
        [groupTypeId]: {
          title: `${groupTypeName}${
            prevGroupType?.label ? ', ' + prevGroupType.label : ''
          }`,
          details: `${groupName}${
            prevGroupType?.details ? ', ' + prevGroupType.details : ''
          }`
        }
      }
    },
    {}
  )
  return Object.values(groupTypesMeta)
}

const mapClassificationTagTypesSummary = (data) => {
  return data.reduce(
    (acc, { classificationTagTypeName, shortName }) => ({
      ...acc,
      details: `${classificationTagTypeName}${
        acc?.details ? ', ' + acc.details : ''
      } - ${shortName}`
    }),
    { title: FILTER_LABELS.classificationTag }
  )
}

const mapAssetsSummary = (data) => {
  return data.reduce(
    (acc, { assetName, longName, shortName }) => ({
      ...acc,
      details: `${assetName || longName || shortName}${
        acc?.details ? ', ' + acc.details : ''
      }`
    }),
    { title: FILTER_LABELS.asset }
  )
}

const ResultsFiltersSummary = ({ reportParams }) => {
  const classes = useStyles()

  const reportFilters = useMemo(() => {
    if (!Array.isArray(reportParams.filters)) {
      return reportParams.filters
    }
    const filters = reportParams.filters.reduce((acc, filter) => {
      if (!filter?.type) {
        return acc
      }
      if (!filter?.entityType) {
        acc[filter.type] = filter.value
        return acc
      }
      acc[filter.entityType] = [
        ...(acc[filter.entityType] || []),
        ...filter.value
      ]
      return acc
    }, {})
    return filters
  }, [reportParams])

  const { query, queryOptions } = useMemo(() => {
    const filterIds = reportFilters?.groupType || reportFilters?.advisorIds
    return {
      query: {
        includes: {
          groupType: true
        },
        filters: {
          groupId: [{ op: 'in', value: filterIds ?? [] }]
        }
      },
      queryOptions: {
        mapper: mapGroupTypesSummary,
        enabled: !isEmpty(filterIds)
      }
    }
  }, [reportFilters])

  const { data: groupTypes } = useGroupSearch(query, queryOptions)

  const classificationTagsQuery = useMemo(() => {
    const filterIds = reportFilters?.[EXPOSURE_TYPES.CLASSIFICATION_TAG]
    const enabled = !isEmpty(reportFilters?.[EXPOSURE_TYPES.CLASSIFICATION_TAG])
    return {
      defaultClassificationTagIds: filterIds ?? [],
      queryOptions: {
        mapper: mapClassificationTagTypesSummary,
        enabled
      }
    }
  }, [reportFilters])

  const {
    defaultOptions: classificationTags
  } = useSearchClassificationTagsDebounced(classificationTagsQuery)

  const assetsQuery = useMemo(() => {
    const filterIds = reportFilters?.[EXPOSURE_TYPES.ASSET]
    const enabled = !isEmpty(reportFilters?.[EXPOSURE_TYPES.ASSET])
    return {
      defaultAssetIds: filterIds ?? [],
      queryOptions: {
        mapper: mapAssetsSummary,
        enabled
      }
    }
  }, [reportFilters])

  const { defaultOptions: assets } = useSearchAssetsDebounced(assetsQuery)

  const filtersSummary = useMemo(() => {
    if (isEmpty(reportParams)) return []

    const { endDate, dataSets = [], levelType, includeTags, dateRangePreset } =
      reportParams

    const dateRange = defaultOptions.find(({ key }) => key === dateRangePreset)
    const asOfDate = dayjs.utc(endDate).format('MM/DD/YYYY')

    const shouldIncludeDateRange =
      dataSets.includes(DATA_SETS.performance) ||
      dataSets.includes(DATA_SETS.rgl)

    return [
      {
        title: 'Report Level',
        details: capitalize(levelType)
      },
      shouldIncludeDateRange
        ? {
          title: 'Date Range',
          details: dateRange.label
        }
        : null,
      {
        title: 'As of Date',
        details: asOfDate
      },
      {
        title: 'Data Sets',
        details: dataSets.map(capitalize).join(', ')
      },
      {
        title: 'Include Tags',
        details: includeTags ? 'Yes' : 'No'
      },
      assets,
      ...(groupTypes ?? []),
      classificationTags
    ].filter(Boolean)
  }, [assets, groupTypes, reportParams, classificationTags])

  return (
    <div className={classes.container}>
      {filtersSummary.map((filterSummary, index) => (
        <FilterSummaryItem key={index} {...filterSummary} />
      ))}
    </div>
  )
}

ResultsFiltersSummary.propTypes = {
  reportParams: PropTypes.object,
  filterSummaryItems: PropTypes.array
}

export default ResultsFiltersSummary
