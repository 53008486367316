import { get } from 'lodash'
import KeyMirror from 'keymirror'
import { DATE_PRESET, LEVEL_TYPES } from '../../../../constants'
import { bookOfBusinessReportColumns } from './columnConfig'

export const DATE_RANGE_OPTIONS = [
  { label: 'Last 7 Days', value: DATE_PRESET.L7D },
  { label: 'Last 30 Days', value: DATE_PRESET.L30 },
  { label: 'Month to Date', value: DATE_PRESET.MD },
  { label: 'Quarter to Date', value: DATE_PRESET.QD },
  { label: 'Year to Date', value: DATE_PRESET.YD },
  { label: 'Custom Range', value: null, disabled: true }
]

export const EXPOSURE_REPORT_CONTEXT_KEY = 'EXPOSURE_REPORT_CONTEXT_KEY'
export const BOBI_REPORT_LIST_CONTEXT_KEY = 'BOBI_REPORT_LIST_CONTEXT_KEY'

export const EXPOSURE_TYPES = {
  ASSET: 'asset',
  CLASSIFICATION_TAG: 'classificationTag'
}

export const DATA_SET_TYPES = {
  BALANCE: 'balance',
  PERFORMANCE: 'performance',
  ALL: ['balance', 'performance'],
  BALANCE_AND_PERFORMANCE: 'balanceAndPerformance'
}

export const EXPOSURE_TYPE_ID_KEY = {
  [EXPOSURE_TYPES.ASSET]: 'assetId',
  [EXPOSURE_TYPES.CLASSIFICATION_TAG]: 'classificationTagId'
}

export const getAssetExposureName = (exposureType, asset) => {
  if (exposureType === EXPOSURE_TYPES.ASSET) {
    return `${asset?.assetIdentifier} - ${asset?.longName}`
  }
  if (exposureType === EXPOSURE_TYPES.CLASSIFICATION_TAG) {
    return asset.longName
  }
  return null
}

export const levelDatesQuery = { levelType: 'user' }

export const balanceColumnMappings = {
  'balance.assetClassTagLongName': 'Class (Long Name)',
  'balance.assetClassTag': 'Class',
  'balance.assetClassTagType': 'Asset Tag Type',
  'balance.allocation': 'Allocation',
  'balance.benchmarkValue': 'Benchmark Value',
  'balance.endingValue': 'Ending Value',
  'balance.income': 'Income',
  'balance.transactionCount': 'Transaction Count',
  'balance.units': 'Units',
  'balance.withdrawals': 'Withdrawals',
  'balance.additions': 'Additions'
}

export const performanceColumnMappings = {
  'performance.levelCount': 'Day Count',
  'performance.closeDate': 'Close Date',
  'performance.netFlows': 'Net Additions',
  'performance.netChange': 'Net Gain GOF',
  'performance.netChangeNOF': 'Net Gain NOF',
  'performance.previousAccrual': 'Previous Accrual',
  'performance.accrual': 'Accrual',
  'performance.previousUnits': 'Previous Units',
  'performance.units': 'Units',
  'performance.additions': 'Additions',
  'performance.withdrawals': 'Withdrawals',
  'performance.fee': 'Fee',
  'performance.income': 'Income',
  'performance.adjustedNetAdditions': 'Net Additions',
  'performance.cumulativeReturn': 'Return (Cumulative GOF)',
  'performance.cumulativeReturnNOF': 'Return (Cumulative NOF)',
  'performance.cumulativeBenchmark': 'Benchmark (Cumulative)',
  'performance.annualizedReturn': 'Return (Annualized GOF)',
  'performance.annualizedReturnNOF': 'Return (Annualized NOF)',
  'performance.annualizedBenchmark': 'Benchmark (Annualized)'
}

export const uglColumnMappings = {
  'ugl.endingValue': 'Ending Value',
  'ugl.costBasisAdjusted': 'Cost Basis',
  'ugl.shortTermUnrealizedLoss': 'ST Unrealized Loss',
  'ugl.longTermUnrealizedLoss': 'LT Unrealized Loss',
  'ugl.shortTermUnrealizedGain': 'ST Unrealized Gain',
  'ugl.longTermUnrealizedGain': 'LT Unrealized Gain',
  'ugl.totalUnrealizedGain': 'Total Unrealized Gain',
  'ugl.totalUnrealizedLoss': 'Total Unrealized Loss',
  'ugl.shortTermUGL': 'ST UGL',
  'ugl.longTermUGL': 'LT UGL',
  'ugl.totalUGL': 'Total UGL',
  'ugl.percentGainLoss': '% Gain / Loss'
}

export const rglColumnMappings = {
  'rgl.costBasisAdjusted': 'Cost Basis',
  'rgl.proceeds': 'Proceeds',
  'rgl.shortTermRealizedLoss': 'ST Realized Loss',
  'rgl.longTermRealizedLoss': 'LT Realized Loss',
  'rgl.shortTermRealizedGain': 'ST Realized Gain',
  'rgl.longTermRealizedGain': 'LT Realized Gain',
  'rgl.totalRealizedGain': 'Total Realized Gain',
  'rgl.totalRealizedLoss': 'Total Realized Loss',
  'rgl.shortTermRGL': 'ST RGL',
  'rgl.longTermRGL': 'LT RGL',
  'rgl.totalRGL': 'Total RGL',
  'rgl.percentGainLoss': '% Gain / Loss'
}

export const excludedColumnMappings = KeyMirror({
  levelType: null,
  'balance.ALL': null
})

export const REPORT_STATUS = {
  ERROR: 'error',
  COMPLETED: 'completed',
  PENDING: 'pending'
}
/**
 *
 * @param {string} string - titleCaseWRD
 * @returns string - TitleCaseWRD
 */
export const toTitleCase = (string) => {
  return string
    .replace(/([a-z])([A-Z])/g, '$1 $2')
    .replace(/([A-Z])([A-Z][a-z])/g, '$1 $2')
    .split('/s')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join('')
}

export const mapDataPointsToColumns = (dataPoints, excludedDataPoints = []) => {
  return Object.entries(dataPoints).reduce((acc, [key, { titleCase }]) => {
    if (
      excludedDataPoints.includes(key) ||
      !bookOfBusinessReportColumns?.[key]
    ) {
      return acc
    }
    const { label, template } = bookOfBusinessReportColumns?.[key] || {}
    return [
      ...acc,
      {
        id: key,
        template,
        hidden: true, // data point columns are hidden by default
        alignRight: true,
        Header: label || titleCase,
        accessor: (r) => get(r, key)
      }
    ]
  }, [])
}

/**
 *
 * @param {string} prefixKey - key separator (.)
 * @param {object} dataPoints - { balance: { endingValue: 2 }}
 * @returns { 'balance.endingValue': { titleCase: 'Ending Value', template: 'money' } }
 */
export const mapDataPointsToTitleCase = ({ prefixKey = null, dataPoints }) => {
  return Object.fromEntries(
    Object.entries(dataPoints).reduce((acc, [key, value]) => {
      const titleCase = toTitleCase(key)
      if (!!value && value.constructor === Object) {
        const valueDataPoints = mapDataPointsToTitleCase({
          prefixKey: key,
          dataPoints: value
        })
        return [...acc, ...Object.entries(valueDataPoints)]
      }
      return [
        ...acc,
        [
          `${prefixKey ? `${prefixKey}.` : ''}${key}`,
          { titleCase, template: 'money' }
        ]
      ]
    }, [])
  )
}

export const DATA_SETS = KeyMirror({
  balance: null,
  performance: null,
  ugl: null,
  rgl: null
})

export const defaultDataSetOptions = [
  {
    label: 'Balance',
    value: 'balance',
    footnote: 'Market Value and Units'
  },
  {
    label: 'Performance',
    value: 'performance',
    footnote: 'Incl. Net Gain and Return'
  },
  {
    label: 'UGL',
    value: 'ugl',
    footnote: 'Unrealized Gain/Loss'
  },
  {
    label: 'RGL',
    value: 'rgl',
    footnote: 'Realized Gain/Loss'
  }
]

export const getGroupTypesQuery = (levelType, groupTypeId) => {
  return {
    query: {
      includes: {
        groups: true,
        groupMembers: true
      },
      filters: {
        ...(groupTypeId
          ? { groupTypeId: [{ op: 'in', value: [groupTypeId] }] }
          : {}),
        memberLevelTypeIds:
          levelType === LEVEL_TYPES.ACCOUNTS ? [1] : [201, 1]
      }
    },
    queryOptions: {
      mapper: ({ data }) =>
        data?.reduce(
          (acc, groupType) => [
            ...acc,
            ...(groupType?.groups ?? []).map((group) => ({
              label: group.shortName,
              value: group.groupId
            }))
          ],
          []
        ) ?? []
    }
  }
}

export const mapGroupTypeFilters = ({ data }) => {
  return data
    .map(({ shortName, longName, memberTypes, groupTypeId }) => ({
      label: longName,
      value: shortName,
      payload: { groupTypeId, ...(memberTypes?.[0] || {}) }
    }))
    .sort((a, b) => a.label.localeCompare(b.label))
    .concat([
      {
        value: EXPOSURE_TYPES.ASSET,
        label: 'Asset'
      },
      {
        value: EXPOSURE_TYPES.CLASSIFICATION_TAG,
        label: 'Asset Tag'
      }
    ])
}

export const getGroupTypeFiltersQuery = (levelType) => {
  return {
    includes: {
      groups: true,
      groupMembers: true,
      groupMemberTypes: true
    },
    filters: {
      memberLevelTypeIds: levelType === LEVEL_TYPES.ACCOUNTS ? [1] : [201, 1]
    }
  }
}

export const exposureTypes = Object.values(EXPOSURE_TYPES)

export const isExposureFilter = (filter) => {
  return exposureTypes.includes(filter.type.replace('Ids', ''))
}

export const FILTER_TYPES = KeyMirror({
  [EXPOSURE_TYPES.ASSET]: null,
  [EXPOSURE_TYPES.CLASSIFICATION_TAG]: null,
  groupTypes: null
})

export const FILTER_LABELS = {
  [EXPOSURE_TYPES.ASSET]: 'Asset',
  [EXPOSURE_TYPES.CLASSIFICATION_TAG]: 'Asset Tag',
  groupTypes: null
}

export const getReportFilters = (reportParams) => {
  return (reportParams?.filters || []).map((filter) => {
    const { type, value } = filter
    const isExposure = Object.values(EXPOSURE_TYPES).includes(
      reportParams?.exposureType
    )

    if (isExposure) {
      const values = value.map((val) => val?.value || val)
      return {
        label: FILTER_LABELS[type.replace('Ids', '')],
        defaultValues: values,
        type
      }
    }
    return {
      label: type,
      defaultValues: value,
      type: FILTER_TYPES.groupTypes
    }
  })
}

export const escapeString = function (val) {
  // eslint-disable-next-line no-control-regex
  val = val.replace(/[\0\n\r\b\t\\'"\x1a]/g, function (s) {
    switch (s) {
      case '\0':
        return '\\0'
      case '\n':
        return '\\n'
      case '\r':
        return '\\r'
      case '\b':
        return '\\b'
      case '\t':
        return '\\t'
      case '\x1a':
        return '\\Z'
      case "'":
        return "''"
      case '"':
        return '""'
      default:
        return '\\' + s
    }
  })
  return val
}

export const getAdditionalData = ({ levelType }) => {
  if (levelType === 'account') {
    return {
      accounts: [
        'startDate',
        'performanceStartDate',
        'dailyStartDate',
        'managedStatusId',
        'discretionaryStatusId',
        'performanceStatusId',
        'taxStatusId',
        'custodianId',
        'accountRegistration'
      ],
      custodians: ['name'],
      dataSources: ['name'],
      taxStatus: ['longName'],
      managedStatus: ['name'],
      discretionaryStatus: ['name'],
      performanceStatus: ['name']
    }
  }
  return {}
}
