import { useMemo } from 'react'
import { USER_TYPE } from '../../../../../constants'
import { useSearchRoles } from '../../../../../api/users'

/**
 * Gets role options
 * @return {{isLoading: false | true, data}}
 */
export const useRoleOptions = () => {
  const query = useMemo(() => {
    return {
      filters: {
        assignableToUserType: USER_TYPE.WEALTH_OWNER
      },
      includes: {
        systemDefaults: true
      }
    }
  }, [])

  const { data, isLoading } = useSearchRoles(query)

  return useMemo(() => {
    const options = (data || []).map(x => ({
      label: x.name,
      value: x.roleId,
      isWealthOwnerDefault: x.systemDefaults?.isWealthOwnerDefault ?? false
    }))
    return {
      data: options,
      defaultOption: options.find(x => x.isWealthOwnerDefault),
      isLoading
    }
  }, [data, isLoading])
}
