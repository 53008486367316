import React from 'react'
import PropTypes from 'prop-types'
import RoundedModal from '../../molecules/RoundedModal'

function EditClientInfoDialog ({ title, open, onClose, children }) {
  return (
    <RoundedModal
      title={title}
      open={open}
      onClose={onClose}
      disablePortal={false}
    >
      {children}
    </RoundedModal>
  )
}

EditClientInfoDialog.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.string,
  onClose: PropTypes.func,
  children: PropTypes.node
}

EditClientInfoDialog.defaultProps = {
  title: 'Update your Information'
}

export default EditClientInfoDialog
