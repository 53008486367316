import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'
import LabeledValue from '../components/LabeledValue'
import StatusBadge from './StatusBadge'

const useStyles = makeStyles((theme) => ({
  stepItem: {
    backgroundColor: ({ isSelected }) => isSelected ? theme.palette.platinum : theme.palette.white,
    border: ({ isSelected }) => isSelected ? `1px solid ${theme.palette.selected}` : `1px solid ${theme.palette.darkGrey}`,
    marginBottom: '.5rem',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '& > .__wrapper': {
      paddingBottom: '.7rem'
    },
    '&:hover': {
      cursor: 'pointer'
    }
  },
  ordinal: {
    margin: '1rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  }
}))

const StepItem = ({ step, onSelect, selectedStep }) => {
  const isSelected = useMemo(() => {
    return step.ingestionStepId === selectedStep?.ingestionStepId
  }, [step, selectedStep])

  const handleSelect = useCallback((e) => {
    e.preventDefault()
    e.stopPropagation()

    return onSelect(isSelected ? null : step)
  }, [onSelect, step, isSelected])

  const classes = useStyles({ isSelected })

  return (
    <div className={classes.stepItem} onClick={handleSelect}>
      <div className={classes.ordinal}>
        <div><StatusBadge isActive={step.active} /></div>
        <div>{step.ordinal}</div>
      </div>
      <div className='__wrapper'>
        <h3>
          <span>{step.ingestionStepId}</span>
        </h3>
        <div>
          <LabeledValue label='Description'>{step.description}</LabeledValue>
          {step.objectMapping && (
            <LabeledValue label='Target'>
              <Link to={`/data-home/targets/${step.objectMapping.ingestionTargetId}`}>{step.objectMapping.sourceTable}</Link>
            </LabeledValue>
          )}
        </div>
      </div>
    </div>
  )
}

StepItem.propTypes = {
  step: PropTypes.shape({
    ingestionStepId: PropTypes.string,
    description: PropTypes.string,
    active: PropTypes.bool,
    ingestionTarget: PropTypes.string,
    ordinal: PropTypes.number,
    objectMapping: PropTypes.shape({
      ingestionTargetId: PropTypes.number,
      sourceTable: PropTypes.string
    })
  }),
  onSelect: PropTypes.func,
  selectedStep: PropTypes.shape({
    ingestionStepId: PropTypes.string
  })
}

export default StepItem
