import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import OperationalTable from '../../../../organisms/OperationalTable'
import { useGroupingContext } from '../../../../organisms/GroupingProvider/GroupingContext'
import { useAutoOperationalTableContext } from '../../../../organisms/OperationalTable/AutoOperationalTableContext'
import { useAccountsContext } from './AccountsContext'

const useStyles = makeStyles(() => ({
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  }
}))

const AccountsTable = ({ header, onClickRow }) => {
  const classes = useStyles()

  const {
    accounts,
    isLoading,
    columnConfig,
    operationalTable,
    isLoadingGroupTypes
  } = useAccountsContext()

  const { setPerformanceTableProps } = useGroupingContext()
  const { tableProps } = useAutoOperationalTableContext()

  const isTransitioning = isLoading || isLoadingGroupTypes

  const tableColsInSync =
    columnConfig.columns.length <= tableProps?.allColumns?.length

  useEffect(() => {
    if (!isTransitioning && tableColsInSync) {
      setPerformanceTableProps(tableProps)
    }
  }, [
    tableProps,
    tableColsInSync,
    isTransitioning,
    setPerformanceTableProps
  ])

  useEffect(() => {
    if (tableProps?.setHiddenColumns && !isTransitioning) {
      const hiddenColumns = columnConfig.columns.reduce(
        (acc, col) => (col.hidden ? [...acc, col.id] : acc),
        []
      )
      tableProps.setHiddenColumns(hiddenColumns)
    }
  }, [tableProps, isTransitioning, columnConfig.columns])

  const {
    searchText,
    onPagingChange,
    onSortingChange,
    defaultPageSize,
    onTableModeChange
  } = operationalTable

  return (
    <>
      <OperationalTable.SuperHeader className={classes.header}>
        {header}
      </OperationalTable.SuperHeader>
      <OperationalTable
        mode='server'
        itemName='Accounts'
        data={accounts}
        loading={isLoading}
        searchText={searchText}
        onRowClick={onClickRow}
        columns={columnConfig.columns}
        onPagingChange={onPagingChange}
        defaultPageSize={defaultPageSize}
        onSortingChange={onSortingChange}
        onTableModeChange={onTableModeChange}
        defaultSort={columnConfig.defaultSort}
      />
    </>
  )
}

AccountsTable.propTypes = {
  header: PropTypes.node,
  onClickRow: PropTypes.func
}

export default AccountsTable
