import React, { useCallback, useImperativeHandle, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import Grid from '@material-ui/core/Grid'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import SydModal from '../../commonDesign/SydModal'
import SydButton from '../../commonDesign/Button'
import { useAppContext } from '../../../redux/slices/appContext'
import PersonalSpace from '../../molecules/PersonalSpace'

dayjs.extend(isSameOrBefore)

const CalendarYearDialog = React.forwardRef(function ({ onSave }, ref) {
  const { availableDates } = useAppContext()
  const [isOpen, setIsOpen] = useState(false)
  useImperativeHandle(ref, () => ({
    open: () => {
      setIsOpen(true)
    }
  }), [setIsOpen])

  const options = useMemo(() => {
    const result = []
    for (let i = 0; dayjs(availableDates.min).add(i, 'year').isSameOrBefore(availableDates.max, 'year'); ++i) {
      const year = dayjs(availableDates.min).add(i, 'year').year()
      result.push({ label: year, value: year })
    }
    return result.sort((a, b) => b.value - a.value)
  }, [availableDates])

  const handleSave = useCallback((value) => {
    onSave(value)
    setIsOpen(false)
  }, [onSave, setIsOpen])

  return (
    <SydModal
      disablePortal={false}
      title='Choose Calendar Year'
      open={isOpen}
      onClose={() => setIsOpen(false)}
      size='small'
    >
      <Grid container spacing={2}>
        {options.map(o => (
          <Grid item xs={3} key={o.value}>
            <SydButton
              style={{ width: '100%' }}
              variant='primary'
              onClick={() => handleSave({ key: `CLY:${o.value}`, year: o.value })}
            >
              {o.label}
            </SydButton>
          </Grid>
        ))}
      </Grid>
      <PersonalSpace size={40} />
    </SydModal>
  )
})

CalendarYearDialog.propTypes = {
  onSave: PropTypes.func
}

export default CalendarYearDialog
