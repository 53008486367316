import { useMemo } from 'react'
import { useFormattingContext } from '../../FormattingProvider/FormattingContext'
import {
  createColumnMapper,
  mapCellAccessor,
  mapHeaderAccessor,
  mapSortFunction
} from '../columnUtility'

const mapColumn = createColumnMapper([
  mapCellAccessor(),
  mapHeaderAccessor,
  mapSortFunction
])

// In a pivot table, typically you leave nulls as empty spaces
const defaultFormatOptions = {
  dashNulls: true,
  dash: ''
}

export const usePivotColumns = (pivotColumns, columnConfig, formatOptions = defaultFormatOptions) => {
  const { formatter } = useFormattingContext({
    options: formatOptions
  })
  const effectiveColumns = useMemo(() => {
    const result = [...columnConfig]

    // We find the column with a star and splice the columns in from there
    const expandIndex = result.findIndex(x => x.header === '*')
    const pivotConfig = result[expandIndex]
    const mappedColumns = pivotColumns.map(c => ({
      ...pivotConfig,
      ...c
    }))
    result.splice(expandIndex, 1, ...mappedColumns)

    return result.map((c, i) => mapColumn(c, {
      formatter
    }, i))
  }, [pivotColumns, columnConfig, formatter])

  return effectiveColumns
}
