import { useMemo } from 'react'
import TemplateActions from './TemplateActions'

export const useTemplatesColumnConfig = ({ classes = {}, updateTemplateForm }) => {
  return useMemo(() => ({
    columns: [
      { Header: 'Template Name', accessor: 'name', id: 'name', disableSortBy: false },
      {
        Header: 'Actions',
        accessor: 'templateId',
        id: 'templateId',
        disableSortBy: true,
        Cell: (cell) => TemplateActions({ row: cell.row, updateTemplateForm })
      }
    ],
    defaultSort: [
      { id: 'createdDate', desc: true }
    ]
  }), [updateTemplateForm])
}
