import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  content: {
    borderRadius: '0.75rem',
    backgroundColor: theme.palette.white
  },
  borderedContent: {
    borderRadius: '0.75rem',
    border: '2px solid #E9EAEF',
    minWidth: '16rem'
  },
  columnContent: {
    display: 'flex',
    flexDirection: 'column'
  }
}))

const SectionContent = ({ borderedContent, columnContent, style, children }) => {
  const classes = useStyles()

  return (
    <div
      className={clsx(classes.content, {
        [classes.borderedContent]: borderedContent,
        [classes.columnContent]: columnContent
      })}
      style={style}
    >
      {children}
    </div>
  )
}

SectionContent.propTypes = {
  style: PropTypes.object,
  children: PropTypes.node,
  columnContent: PropTypes.bool,
  borderedContent: PropTypes.bool
}

export default SectionContent
