import React from 'react'
import { makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import FastAvatar from '../../../../molecules/FastAvatar'

const useStyles = makeStyles((theme) => ({
  teamMember: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.layout.gap.medium,
    padding: theme.layout.padding.thin,
    border: `1px solid ${theme.palette.gray.darker}`,
    borderRadius: theme.layout.radius.tight,
    '& .__left': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    '& .__right': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: theme.typography.fontSizes.xl
    },
    '& .__top': {
      display: 'flex',
      flexDirection: 'row',
      gap: theme.layout.gap.dense
    }
  }
}))

function TeamMember ({ teamMember }) {
  const classes = useStyles()
  return (
    <div className={classes.teamMember}>
      <div className='__left'>
        <FastAvatar avatarUrl={teamMember.profilePic} />
      </div>
      <div className='__right'>
        <div className='__top'>
          <span>{teamMember.firstName}</span>
          <span>{teamMember.lastName},</span>
          <span>{teamMember.overrideRoleName || teamMember.primaryRoleName}</span>
        </div>
      </div>
    </div>
  )
}

TeamMember.propTypes = {
  teamMember: PropTypes.shape({
    teamMemberId: PropTypes.number,
    ordinal: PropTypes.number,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    title: PropTypes.string,
    email: PropTypes.string,
    phone1: PropTypes.string,
    phone2: PropTypes.string,
    phone3: PropTypes.string,
    profilePic: PropTypes.string,
    primaryRoleName: PropTypes.string,
    overrideRoleName: PropTypes.string
  })
}

export default TeamMember
