import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Box, makeStyles } from '@material-ui/core'
import { isEmpty } from 'lodash'
import { Link } from 'react-router-dom'
import clsx from 'clsx'
import { useSearchClientConversations } from '../../../../api/conversations'
import { ICON_NAMES } from '../../../../constants'
import Icon from '../../../atoms/Icon'
import Text from '../../../atoms/Text'
import TableSkeleton from '../../../atoms/TableSkeleton'
import MessageItem from './MessageItem'

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0.5rem',
    '& a': {
      textDecoration: 'none !important'
    }
  },
  emptyStateContainer: {
    padding: '1rem',
    alignItems: 'center',
    flexDirection: 'row'
  }
}))

const getLastConversationByClient = (data) => {
  return Object.values(data?.conversations.reduce((acc, conversation) => {
    const prevConversation = acc[conversation.clientId]
    if (!prevConversation) {
      return { ...acc, [conversation.clientId]: conversation }
    }
    const currentTime = new Date(conversation.lastMessageDate).getTime()
    const prevTime = new Date(prevConversation?.lastMessageDate).getTime()

    return {
      ...acc,
      [conversation.clientId]:
        currentTime > prevTime ? conversation : prevConversation
    }
  }, {}))
}

const AdvisorMessages = ({ limit, emptySectionLabel, itemRedirectTo }) => {
  const classes = useStyles()
  const { query, queryOptions } = useMemo(() => {
    return {
      query: {},
      queryOptions: {
        // TODO: remove this once we support pagination
        mapper: (data) =>
          getLastConversationByClient(data)
            .sort(function (a, b) {
              return new Date(b.lastMessageDate) - new Date(a.lastMessageDate)
            })
            .slice(0, limit) ?? []
      }
    }
  }, [limit])

  const { data = [], isLoading } = useSearchClientConversations(query, queryOptions)

  if (isLoading) {
    return (
      <Box p={2}>
        <TableSkeleton
          firstColumnWithAvatar
          columns={1}
          rows={5}
          showHeader={false}
          rowHeight='2.5rem'
        />
      </Box>
    )
  }

  if (isEmpty(data) && !isLoading) {
    return (
      <div className={clsx(classes.container, classes.emptyStateContainer)}>
        <Icon name={ICON_NAMES.paperMessageBubbles} customSize='4.5rem' />
        <Box display='flex' flexDirection='column' ml={2} alignItems='baseline'>
          <Text text={emptySectionLabel} customFontWeight='bold' />
        </Box>
      </div>
    )
  }

  return (
    <div className={classes.container}>
      {data.map(
        ({
          conversationId,
          conversationKey,
          lastMessageDate,
          lastMessagePreview,
          clientId,
          clientShortName,
          avatarUrl,
          clientAbbreviation
        }) => {
          return (
            <Link
              key={conversationId}
              to={`${itemRedirectTo}/c/${clientId}/t/${conversationKey}`}
            >
              <MessageItem
                title={clientShortName}
                bodyText={lastMessagePreview}
                date={lastMessageDate}
                avatar={{
                  url: avatarUrl,
                  abbreviation: clientAbbreviation
                }}
              />
            </Link>
          )
        }
      )}
    </div>
  )
}

AdvisorMessages.propTypes = {
  limit: PropTypes.number,
  itemRedirectTo: PropTypes.string,
  emptySectionLabel: PropTypes.string
}

AdvisorMessages.defaultProps = {
  limit: 5,
  itemRedirectTo: '/conversations',
  emptySectionLabel: 'You have no conversations yet'
}

export default AdvisorMessages
