import React from 'react'
import PropTypes from 'prop-types'

function EmbeddedContentField ({ field }) {
  if (field.value?.frameProps) {
    return (
      <iframe title='Embedded Content' {...(field.value?.frameProps ?? {})} />
    )
  }

  return null
}

EmbeddedContentField.propTypes = {
  field: PropTypes.shape({
    value: PropTypes.any
  })
}

export default EmbeddedContentField
