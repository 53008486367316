import React, { useCallback, useMemo, useReducer, useState } from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Container,
  Divider,
  Grid,
  makeStyles
} from '@material-ui/core'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import isEmpty from 'lodash/isEmpty'
import last from 'lodash/last'
import { useAppContext } from '../../redux/slices/appContext'
import { getSafeDate } from '../../utils'
import useAccordion from '../../hooks/useAccordion'
import Text from '../atoms/Text'
import FilterList from '../molecules/FilterList'
import PortfolioAllocationPieProgressChart from '../molecules/PortfolioAllocationPieProgressChart'
import PortfolioAllocationTotalBalanceSummary from '../molecules/PortfolioAllocationTotalBalanceSummary'
import PortfolioAllocationTopHoldingsList from '../molecules/PortfolioAllocationTopHoldingsList'
import ComponentsOfChangeTable from '../molecules/ComponentsOfChangeTable'
import PortfolioAllocationHoldingsTable from '../molecules/PortfolioAllocationHoldingsTable'
import Tag from '../atoms/Tag'
import PerformanceTransactionsTable from '../molecules/PerformanceTransactionsTable'
import PortfolioAllocationTotalBalanceSummaryDetails from '../molecules/PortfolioAllocationTotalBalanceSummaryDetails'
import PrintViewHeader from '../molecules/PrintViewHeader'
import WebOnlyContent from '../atoms/WebOnlyContent'
import {
  DAYJS_OPERATIONS,
  DAYJS_UNIT_TYPES,
  EXPORT_CLASSNAME,
  LEVEL_TYPES,
  TEXT_VARIANTS
} from '../../constants'
import { portfolioViewPropTypes } from '../../prop-types'
import { getShouldClearExistingFilters, hydrateDate } from '../../utils/tableHelper'
import ExportRoot from '../molecules/ExportButton/ExportRoot'
import HistoricalPerformanceTable from './HistoricalPerformanceTable'
import LineChartContainer from './LineChartContainerV2'
import PerformanceTableContainer, { getTablePropName } from './PerformanceTableContainer'

const reducer = (state, payload) => {
  return {
    ...state,
    ...payload
  }
}

const useStyles = makeStyles((theme) => ({
  rootAccordion: {
    boxShadow: 'none',
    backgroundColor: 'white',
    // HACK: remove accordion padding to line up collapsible tables
    '& .MuiAccordionSummary-root': {
      padding: 0
    },
    '& .MuiAccordionDetails-root': {
      padding: 0
    }
  },
  tableButton: {
    borderRadius: '1rem',
    padding: '4px 10px 2px',
    fontSize: '0.875rem',
    fontWeight: 'bold'
  },
  tableActiveButton: {
    backgroundColor: 'rgba(0, 0, 0, 0.04)'
  },
  accordionSummary: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  accordionTitle: {
    color: theme.palette.cloudBurst
  },
  reverse: {
    flexDirection: 'row-reverse',
    gap: '10px'
  },
  expanded: {
    '& .MuiAccordionSummary-expandIcon.Mui-expanded': {
      transform: 'rotate(90deg)'
    }
  }
}))

const PortfolioAllocationView = ({
  accountCategoryIds,
  showFilterTag,
  performanceTableSectionProps,
  topHoldingsSectionProps,
  portfolioAllocationPieChartProps,
  balanceInformationSectionProps,
  holdingsSectionProps,
  transactionsSectionProps,
  lineChartContainerProps,
  historicalSectionProps,
  componentsOfChangeSectionProps,
  balanceInformationSummaryDetails,
  lineChartVersion,
  tableViewColumnsFormat = [],
  showExport
}) => {
  const classes = useStyles()
  const appContext = useAppContext()
  const { availableDates } = appContext
  const [viewFilters, setViewFilters] = useState({})
  const [viewContext, setViewContext] = useReducer(reducer, {
    currentViewId: '4',
    assetClassId: null,
    lineChartDataIdentifier: 'endingValue',
    assetFilterType: 'ASSET_CLASSES',
    minClientDate: getSafeDate(availableDates, {
      useMin: true
    }),
    maxClientDate: getSafeDate(availableDates, {
      useMax: true
    }),
    mainDate: availableDates.mainDate,
    streamChartDate: getSafeDate(availableDates, {
      operation: DAYJS_OPERATIONS.SUBTRACT,
      unitType: DAYJS_UNIT_TYPES.MONTH,
      unitValue: 12
    }),
    assetClassType: 'class'
  })

  const filterItems = useMemo(() => {
    return Object.values(viewFilters).map((filter) => ({
      ...filter,
      onRemoveClick: ({ payload: { classType: propKey } }) => {
        const { [propKey]: filterKey, ...filtersRest } = viewFilters
        setViewFilters(filtersRest)
      }
    }))
  }, [viewFilters])

  const showFilteredTag = useMemo(() => {
    return showFilterTag && !isEmpty(viewFilters)
  }, [viewFilters, showFilterTag])

  const showOnlyToAdvisorsAndSummitUsers = useMemo(() => {
    return appContext.isSummitUser || appContext.isAdvisor
  }, [appContext.isSummitUser, appContext.isAdvisor])

  const onPerformanceAllocationRowClick = useCallback(
    (_, rowData, { rowAssetsConfig }) => {
      const rowConfig = last(rowData)
      const { value, text, classType: { type: classTypeValue } } = rowConfig
      const filter = {
        id: value,
        label: text,
        payload: {
          classType: classTypeValue,
          assetTree: rowAssetsConfig
        }
      }
      setViewFilters(prevFilters => {
        const shouldClearExistingFilters = getShouldClearExistingFilters(
          prevFilters,
          rowAssetsConfig
        )
        return {
          ...(shouldClearExistingFilters
            ? { [classTypeValue]: filter }
            : { ...prevFilters, [classTypeValue]: filter })
        }
      })
    },
    []
  )

  const performanceTableProps = useMemo(() => {
    const { multiTablesContainer } = performanceTableSectionProps
    if (!multiTablesContainer) return null
    const { tableTabs, baseTableProps, ...restTableProps } = multiTablesContainer
    const tablesProps = tableTabs.reduce((acc, tableTab) => {
      const { propName } = getTablePropName(tableTab)
      return {
        ...acc,
        [propName]: multiTablesContainer[propName]
      }
    }, {})
    return {
      tabs: tableTabs,
      tableViewColumnsFormat,
      ...restTableProps,
      ...tablesProps,
      baseTableProps: {
        ...(baseTableProps || {}),
        onRowClick: onPerformanceAllocationRowClick,
        accountCategoryFilters: accountCategoryIds
      }
    }
  }, [
    accountCategoryIds,
    tableViewColumnsFormat,
    performanceTableSectionProps,
    onPerformanceAllocationRowClick
  ])

  const componentsOfChangeAccordion = useAccordion(
    componentsOfChangeSectionProps?.defaultExpanded
  )
  const holdingsAccordion = useAccordion(false)
  const historyAccordion = useAccordion(false)
  const transactionsAccordion = useAccordion(false)

  const TopHoldings = () => {
    /* eslint-disable react/prop-types */
    const displayOption = topHoldingsSectionProps?.moveTopHoldings ? 6 : 4
    const holdingsDisplayProps = { xs: 12, md: displayOption, xl: displayOption }
    const endingValueDateRange = topHoldingsSectionProps?.endingValueDateRange ?? {
      operation: 'startOf',
      unitType: 'yesterday',
      suffix: '_Y'
    }

    return (
      <Grid item hidden={topHoldingsSectionProps.hidden} {...holdingsDisplayProps}>
        <PortfolioAllocationTopHoldingsList
          viewFilters={viewFilters}
          viewContext={viewContext}
          title={topHoldingsSectionProps.title}
          assetHoldingListProps={{
            rightElementCustomFont:
            topHoldingsSectionProps.rightElementCustomFont
          }}
          fetchParams={{
            ...topHoldingsSectionProps.fetchParams,
            accountCategoryIds
          }}
          endingValueDateRange={hydrateDate(endingValueDateRange, availableDates)}
        />
      </Grid>
      /* eslint-disable react/prop-types */
    )
  }

  const componentsOfChangeDisplayOption = topHoldingsSectionProps?.moveTopHoldings ? 6 : 12
  const componentsOfChangeDisplayProps = {
    xs: 12,
    md: componentsOfChangeDisplayOption,
    xl: componentsOfChangeDisplayOption
  }

  return (
    <ExportRoot enabled={showExport}>
      <Container className={EXPORT_CLASSNAME} maxWidth='xl'>
        <Box sx={{ flexGrow: 1 }} pt={4}>
          <Grid container spacing={4}>
            <PrintViewHeader />
            <Grid item xs={12}>
              <Box display='flex' flexDirection='row' alignItems='center'>
                <PortfolioAllocationTotalBalanceSummary
                  viewFilters={viewFilters}
                  setViewFilters={setViewFilters}
                  viewContext={viewContext}
                  setViewContext={setViewContext}
                  showFilteredTag={showFilteredTag}
                  fetchParams={{ accountCategoryIds }}
                  showFiltersList={false}
                  titleFormat={balanceInformationSectionProps.titleFormat}
                  showInvestableAssetsLabel={
                    balanceInformationSectionProps.showInvestableAssetsLabel
                  }
                  onAvailableDatesChangeStrategyKey={
                    balanceInformationSectionProps.onAvailableDatesChangeStrategyKey
                  }
                  disableOnClickBalanceFormatToggle={
                    balanceInformationSectionProps.disableOnClickBalanceFormatToggle
                  }
                />
                <Divider orientation='vertical' flexItem />
                <PortfolioAllocationTotalBalanceSummaryDetails
                  viewFilters={viewFilters}
                  viewContext={viewContext}
                  flowDate={hydrateDate(
                    balanceInformationSummaryDetails.flowDate,
                    availableDates
                  )}
                  flowDateSelectedOption={hydrateDate(
                    balanceInformationSummaryDetails.flowDateSelectedOption,
                    availableDates
                  )}
                  fetchParams={{ accountCategoryIds }}
                  customDateRanges={balanceInformationSummaryDetails.customDateRanges}
                />
                <Box>
                  <FilterList filterItems={filterItems} />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={4} xl={4}>
              <PortfolioAllocationPieProgressChart
                viewContext={viewContext}
                setViewContext={setViewContext}
                showFilteredTag={showFilteredTag}
                showLegends={portfolioAllocationPieChartProps.showLegends}
                fetchParams={{
                  levelTypes:
                    viewContext.assetClassType === 'class'
                      ? [LEVEL_TYPES.ASSET_CLASS_TAG]
                      : [LEVEL_TYPES.SUBCLASS_TAG],
                  accountCategoryIds
                }}
                toggleTitles={portfolioAllocationPieChartProps.toggleTitles}
                toggleButtonLabels={
                  portfolioAllocationPieChartProps.toggleButtonLabels
                }
                pieProgressBarsChartProps={
                  portfolioAllocationPieChartProps.chartProps
                }
              />
            </Grid>
            <Grid item xs={12} md={8} xl={8}>
              <LineChartContainer
                showTagLabel={showFilteredTag}
                accountCategoryFilters={accountCategoryIds}
                seriesColors={lineChartContainerProps.seriesColors}
                seriesLabels={lineChartContainerProps.seriesLabels}
                assetFilters={viewFilters}
                onGraphDateChange={(value, selectedValue) =>
                  setViewContext({
                    streamChartDate: value,
                    streamChartDateSelectedOption: selectedValue
                  })}
                defaultGraphDate={lineChartContainerProps.defaultGraphDate}
                identifiersMapper={lineChartContainerProps.identifiersMapper}
                lineChartProps={lineChartContainerProps.chartProps}
                dateRangeOptions={lineChartContainerProps.dateRangeOptions}
              />
            </Grid>
            {!topHoldingsSectionProps?.moveTopHoldings && <TopHoldings />}
            <Grid
              item
              xs={12}
              {...(topHoldingsSectionProps.hidden ||
            topHoldingsSectionProps?.moveTopHoldings
                ? {}
                : { md: 8, xl: 8 })}
            >
              <WebOnlyContent>
                <Text
                  text='Table View'
                  color='#212945'
                  customFontSize='1rem'
                  customFontFamily='Gotham-Book'
                  textTransform='uppercase'
                  variant={TEXT_VARIANTS.subtitle2}
                />
              </WebOnlyContent>
              <PerformanceTableContainer {...performanceTableProps} />
            </Grid>
            {topHoldingsSectionProps?.moveTopHoldings && <TopHoldings />}
            <Grid
              item
              hidden={componentsOfChangeSectionProps.hidden}
              {...componentsOfChangeDisplayProps}
            >
              <WebOnlyContent force={componentsOfChangeAccordion.expanded}>
                <Box>
                  <Accordion
                    classes={{ root: classes.rootAccordion }}
                    {...componentsOfChangeAccordion}
                  >
                    <AccordionSummary
                      expandIcon={
                        <WebOnlyContent>
                          <ChevronRightIcon />
                        </WebOnlyContent>
                      }
                      classes={{ expanded: classes.expanded }}
                      className={classes.reverse}
                    >
                      <Text
                        text='Components of Change'
                        className={classes.accordionTitle}
                        customFontSize='1rem'
                        customFontWeight='bold'
                        customFontFamily='Gotham-Book'
                        textTransform='uppercase'
                        variant={TEXT_VARIANTS.subtitle1}
                      />
                    </AccordionSummary>
                    <AccordionDetails>
                      <ComponentsOfChangeTable
                        allowDownload
                        accountCategoryFilters={accountCategoryIds}
                        {...componentsOfChangeSectionProps}
                      />
                    </AccordionDetails>
                  </Accordion>
                </Box>
              </WebOnlyContent>
            </Grid>
            <Grid item xs={12} hidden={holdingsSectionProps.hidden}>
              <WebOnlyContent force={holdingsAccordion.expanded}>
                <Box>
                  <Accordion
                    classes={{ root: classes.rootAccordion }}
                    {...holdingsAccordion}
                  >
                    <AccordionSummary
                      expandIcon={
                        <WebOnlyContent>
                          <ChevronRightIcon />
                        </WebOnlyContent>
                      }
                      classes={{ expanded: classes.expanded }}
                      className={classes.reverse}
                    >
                      <Box className={classes.accordionSummary}>
                        <Text
                          text='Holdings Table View'
                          className={classes.accordionTitle}
                          customFontSize='1rem'
                          customFontWeight='bold'
                          customFontFamily='Gotham-Book'
                          textTransform='uppercase'
                          variant={TEXT_VARIANTS.subtitle1}
                        />
                        {showFilteredTag && (
                          <Box ml={2}>
                            <Tag label='Filtered' />
                          </Box>
                        )}
                      </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                      <PortfolioAllocationHoldingsTable
                        viewFilters={viewFilters}
                        viewContext={viewContext}
                        fetchParams={{
                          ...holdingsSectionProps.fetchParams,
                          ...(!isEmpty(accountCategoryIds)
                            ? { accountCategoryIds }
                            : {})
                        }}
                        allowDownloadTable={
                          holdingsSectionProps.allowDownloadTable
                        }
                      />
                    </AccordionDetails>
                  </Accordion>
                </Box>
              </WebOnlyContent>
            </Grid>
            <Grid item xs={12} hidden={historicalSectionProps.hidden}>
              <WebOnlyContent force={historyAccordion.expanded}>
                <Box>
                  <Accordion
                    classes={{ root: classes.rootAccordion }}
                    {...historyAccordion}
                  >
                    <AccordionSummary
                      expandIcon={
                        <WebOnlyContent>
                          <ChevronRightIcon />
                        </WebOnlyContent>
                      }
                      classes={{ expanded: classes.expanded }}
                      className={classes.reverse}
                    >
                      <Text
                        text='HISTORICAL TABLE VIEW'
                        className={classes.accordionTitle}
                        customFontSize='1rem'
                        customFontWeight='bold'
                        customFontFamily='Gotham-Book'
                        textTransform='uppercase'
                        variant={TEXT_VARIANTS.subtitle1}
                      />
                    </AccordionSummary>
                    <AccordionDetails>
                      {!historicalSectionProps.hidden && (
                        <HistoricalPerformanceTable
                          allowDownload={historicalSectionProps.allowDownloadTable}
                          accountCategoryIds={accountCategoryIds}
                        />
                      )}
                    </AccordionDetails>
                  </Accordion>
                </Box>
              </WebOnlyContent>
            </Grid>
            <Grid
              item
              xs={12}
              hidden={
                transactionsSectionProps.hidden ||
              !showOnlyToAdvisorsAndSummitUsers
              }
            >
              <WebOnlyContent force={transactionsAccordion.expanded}>
                <Box>
                  <Accordion
                    classes={{ root: classes.rootAccordion }}
                    {...transactionsAccordion}
                  >
                    <AccordionSummary
                      expandIcon={
                        <WebOnlyContent>
                          <ChevronRightIcon />
                        </WebOnlyContent>
                      }
                      classes={{ expanded: classes.expanded }}
                      className={classes.reverse}
                    >
                      <Text
                        text='TRANSACTIONS TABLE VIEW'
                        className={classes.accordionTitle}
                        customFontSize='1rem'
                        customFontWeight='bold'
                        customFontFamily='Gotham-Book'
                        textTransform='uppercase'
                        variant={TEXT_VARIANTS.subtitle1}
                      />
                    </AccordionSummary>
                    <AccordionDetails>
                      <PerformanceTransactionsTable
                        allowDownload={
                          transactionsSectionProps.allowDownloadTable
                        }
                        fetchParams={{ accountCategoryIds }}
                      />
                    </AccordionDetails>
                  </Accordion>
                </Box>
              </WebOnlyContent>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </ExportRoot>
  )
}

PortfolioAllocationView.propTypes = portfolioViewPropTypes

PortfolioAllocationView.defaultProps = {
  historicalSectionProps: {
    hidden: true
  }
}

export default PortfolioAllocationView
