import React from 'react'
import { makeStyles, InputBase } from '@material-ui/core'

const useStyles = makeStyles(() => ({
  input: {
    borderRadius: '4px',
    border: '2px solid #EEF0F8',
    background: '#FFF',
    padding: '13px 14px 13px 14px',
    fontSize: '0.875rem',
    fontWeight: 'bold',
    width: '10rem',
    maxWidth: '10rem',
    '& input': { padding: 0 }
  }
}))

const FilterInput = ({ ...props }) => {
  const classes = useStyles()
  return <InputBase className={classes.input} {...props} />
}

export default FilterInput
