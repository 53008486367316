import React, { useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { isEmpty } from 'lodash'
import SelectWithCheckboxAndSearch from '../../../components/molecules/Select/SelectWithCheckboxAndSearch'
import { BUTTON_SIZES } from '../../../constants'
import { useSearchAccountsDebounced } from '../../../hooks'
import { useFilterSubscription } from '../useFilterSubscription'
import { useAppContext } from '../../../redux/slices/appContext'
import { useFilterSubscriptionContext } from '../FilterSubscriptionProvider'

const AccountSubscriptionFilter = ({ filterSubscription, searchFilters }) => {
  const appContext = useAppContext()

  const { filters, setFilters, initialFilterState } =
    useFilterSubscriptionContext()

  // resets filters when the client changes and preserves visible
  // accounts to be used later to filter our groups if the filter is present
  useEffect(() => {
    const clientId = appContext.clientId
    setFilters((prevState) => {
      if (clientId === prevState?.clientId) return prevState
      return {
        ...prevState,
        ...initialFilterState,
        ...(!isEmpty(prevState?.accountIds)
          ? { accountIds: prevState?.accountIds }
          : {}),
        clientId
      }
    })
  }, [appContext.clientId, setFilters, initialFilterState])

  const defaultAccountIds = useMemo(() => {
    return filters?.levelFilters?.accountIds
  }, [filters?.levelFilters?.accountIds])

  const { publishFilters } = useFilterSubscription({
    publishKeys: filterSubscription.publish
  })

  const searchParams = useMemo(() => {
    const queryFilters = {
      assignedToClientIds: [{ op: 'eq', value: appContext.clientId }]
    }
    Object.entries(filters?.levelFilters || {}).forEach(([key, value]) => {
      if (searchFilters.includes(key) && !isEmpty(value)) {
        queryFilters.groupIds = (queryFilters.groupIds || [])
        const filterValue = Array.isArray(value) ? value : [value]
        queryFilters.groupIds.push({ op: 'in', value: filterValue, combine: 'and' })
      }
    })
    return {
      query: {
        take: 100,
        filters: queryFilters
      },
      queryOptions: {},
      defaultAccountIds: defaultAccountIds ?? []
    }
  }, [appContext.clientId, filters?.levelFilters, defaultAccountIds, searchFilters])

  const {
    options = [],
    onChangeQuery,
    isSearchLoading,
    isLoading,
    defaultOptions
  } = useSearchAccountsDebounced(searchParams)

  useEffect(() => {
    if (!isLoading && !isEmpty(options)) {
      // pushes account IDS as part of global filters
      setFilters(prevState => {
        return {
          ...prevState,
          accountIds: options.map(({ value }) => value)
        }
      })
    }
  }, [options, isLoading, setFilters])

  const onChange = (value) => {
    const accountIds = value.map(({ payload: { account } }) => {
      return account.accountId
    })
    publishFilters((prevState) => ({
      ...prevState,
      levelFilters: {
        ...(prevState?.levelFilters ?? {}),
        accountIds
      }
    }))
  }

  const onClearHandler = () => {
    onChangeQuery('')
  }

  return (
    <SelectWithCheckboxAndSearch
      placeholder='Accounts'
      prefixLabel='Accounts'
      searchPlaceholder='Search accounts'
      options={options}
      onChange={onChange}
      disabled={isLoading}
      onClear={onClearHandler}
      onClose={onClearHandler}
      loading={isSearchLoading}
      isLoading={isSearchLoading}
      onQueryChange={onChangeQuery}
      defaultOptions={defaultOptions}
      selectedOptions={defaultOptions ?? []}
      defaultSelectedOptions={defaultOptions}
      size={BUTTON_SIZES.small}
    />
  )
}

AccountSubscriptionFilter.propTypes = {
  filterSubscription: PropTypes.shape({
    publish: PropTypes.arrayOf(PropTypes.string)
  }),
  searchFilters: PropTypes.arrayOf(PropTypes.string)
}

AccountSubscriptionFilter.defaultProps = {
  filterSubscription: {
    publish: ['levelFilters']
  },
  searchFilters: ['accountCategoryIds']
}

export default AccountSubscriptionFilter
