import React from 'react'
import {
  Container,
  makeStyles
} from '@material-ui/core'
import { useMyUserProfile } from '../../../api/users'
import { useMyAuthenticatorEnrollments } from '../../../api/special/okta'
import PreferencesSkeleton from './skeleton'
import UserProfileForm from './profile/UserProfileForm'
import SectionHeading from './SectionHeading'
import SecurityFactorsForm from './SecurityFactorsForm'

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '1.5rem',
    height: '100%'
  }
}))

function PreferencesView () {
  const classes = useStyles()
  const { data: profile, isLoading: profileIsLoading } = useMyUserProfile()
  const { data: enrollments } = useMyAuthenticatorEnrollments()

  if (profileIsLoading || !profile) {
    return (
      <PreferencesSkeleton />
    )
  }

  return (
    <Container fixed maxWidth={false} className={classes.container}>
      <SectionHeading name='Your Profile'>
        <UserProfileForm profile={profile} classes={classes} />
      </SectionHeading>
      <SectionHeading name='Security Preferences'>
        <SecurityFactorsForm enrollments={enrollments} />
      </SectionHeading>
    </Container>
  )
}

export default PreferencesView
