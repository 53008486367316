import { useMemo } from 'react'
import { useListPersonTypes } from '../../../../../api/people'

/**
 * Gets role options
 * @return {{isLoading: false | true, data}}
 */
export const usePersonTypeOptions = () => {
  const { data, isLoading } = useListPersonTypes()

  return useMemo(() => {
    const options = (data?.personTypes || []).map(x => ({
      label: x.longName,
      value: x.personTypeId
    }))
    return {
      data: options,
      defaultOption: options.find(x => !!x),
      isLoading
    }
  }, [data, isLoading])
}
