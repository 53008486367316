import { useEffect, useState } from 'react'
import {
  DATE_RANGE_CUSTOM_OPTION_KEYS,
  useRelativeDateRange
} from '../components/molecules/RelativeDateSelect'

const { CUSTOM, CALENDAR_YEAR } = DATE_RANGE_CUSTOM_OPTION_KEYS

const isCustomDateRange = (dateRange) => {
  return (
    dateRange === CUSTOM ||
    dateRange === CALENDAR_YEAR ||
    dateRange.startsWith(CUSTOM) ||
    dateRange.startsWith(CALENDAR_YEAR)
  )
}

const getDefaultDateRange = (defaultDateRange, onlyRetrieveIdentifier = false) => {
  if (!isCustomDateRange(defaultDateRange)) return defaultDateRange
  if (!onlyRetrieveIdentifier) return defaultDateRange

  if (defaultDateRange.startsWith(CUSTOM)) return CUSTOM
  if (defaultDateRange.startsWith(CALENDAR_YEAR)) return CALENDAR_YEAR
  throw new Error('Invalid custom date range identifier')
}

/**
 * Custom hook for managing selected date ranges.
 *
 * @param {string} defaultDateRange - The default selected date range.
 * @param {Array} availableDates - The available dates for selection.
 * @param {Object} defaultDateRangeOptions - The default options for date range selection.
 * @returns {Object} - The selected date range and related state handlers.
 */
const useSelectedDateRange = (
  defaultDateRange,
  availableDates,
  defaultDateRangeOptions
) => {
  // State for plain selected date ranges (e.g. 'Last 7 days', 'Last 30 days', etc.)
  const [
    selectedDateRange,
    setSelectedDateRange
  ] = useState(getDefaultDateRange(defaultDateRange, true))

  // State for custom date range values (e.g. the result of selecting 'Custom', 'Calendar Year', etc.)
  const [
    selectedCustomDateRange,
    setSelectedCustomDateRange
  ] = useState(getDefaultDateRange(defaultDateRange))

  const { dateRange } = useRelativeDateRange(
    selectedCustomDateRange || selectedDateRange,
    availableDates,
    defaultDateRangeOptions
  )

  useEffect(() => {
    if (selectedDateRange !== CUSTOM && selectedDateRange !== CALENDAR_YEAR) {
      setSelectedCustomDateRange(null)
    }
  }, [selectedDateRange])

  const isCustomDateRangeSelected = isCustomDateRange(selectedDateRange)

  return {
    dateRange,
    value: selectedCustomDateRange || selectedDateRange,

    // handle state for plain selected date ranges
    selectedDateRange,
    setSelectedDateRange,

    // handle state for custom date range values
    isCustomDateRangeSelected,
    selectedCustomDateRange,
    setSelectedCustomDateRange
  }
}

export default useSelectedDateRange
