import React, { useCallback, useState } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { Box, CircularProgress, Snackbar, TextField } from '@material-ui/core'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { Alert } from '@material-ui/lab'
import Text from '../../../../atoms/Text'
import { TEXT_VARIANTS } from '../../../../../constants'
import RoundedButton from '../../../../atoms/RoundedButton'
import { postNamedCommand } from '../../../../../service'

const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: '100%',
    padding: '20px',
    margin: '0 auto',
    width: '900px'
  },
  inputLabel: {
    color: theme.palette.black,
    fontWeight: 600,
    '& span': {
      color: theme.palette.black
    }
  },
  formButton: {
    lineHeight: '2rem',
    height: '3rem'
  },
  formButtonContainer: {
    position: 'fixed',
    backgroundColor: theme.palette.white,
    bottom: 0,
    opacity: 1,
    left: '50%',
    padding: '1rem 1rem 1rem 4rem',
    transform: 'translate(-50%, 0)',
    width: '100vw',
    zIndex: 2,
    boxShadow: `0px 0px 10px 0px ${theme.palette.gray.main}`,
    animation: '$pullUp 200ms ease-in',
    display: 'flex',
    justifyContent: 'center',
    gap: '1rem'
  },
  '@keyframes pullUp': {
    from: {
      transform: 'translate(-50%, 100%)'
    },
    to: {
      transform: 'translate(-50%, 0)'
    }
  }
}))

function RoleNewCreateForm ({
  title,
  subTitle,
  displayName = 'Role',
  onCancel,
  userTypeId,
  onSave
}) {
  const classes = useStyles()

  const {
    handleSubmit,
    formState,
    setError,
    clearErrors,
    ...formMethods
  } = useForm({
    defaultValues: {
      name: '',
      description: null
    }
  })

  const [isSubmitting, setIsSubmitting] = useState(false)

  const onSubmit = useCallback(async (values) => {
    setIsSubmitting(true)
    try {
      const { data } = await postNamedCommand('users', 'createRole', {
        assignableToUserType: userTypeId,
        ...values
      })
      onSave(data?.roleId)
    } catch (err) {
      setError('root', { message: 'There was an issue saving the form. Please try again.' })
    } finally {
      setIsSubmitting(false)
    }
  }, [setIsSubmitting, setError, onSave, userTypeId])

  const clearRootError = useCallback(() => {
    clearErrors('root')
  }, [clearErrors])

  return (
    <>
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          {formState.errors?.root && (
            <Snackbar
              open
              autoHideDuration={6000}
              onClose={clearRootError}
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
              <Alert onClose={clearRootError} severity='error'>
                {formState.errors.root?.message ?? 'Issue saving form'}
              </Alert>
            </Snackbar>
          )}

          <Box className={classes.container}>
            {title?.length && <Text text={title} variant={TEXT_VARIANTS.h1} />}
            {subTitle?.length && <Text text={subTitle} variant={TEXT_VARIANTS.h3} />}
            <Controller
              name='name'
              render={({ field, fieldState }) => (
                <TextField
                  label='Name'
                  fullWidth
                  required
                  helperText={`Name of this ${displayName}`}
                  error={(formState.isSubmitted || fieldState.isDirty || fieldState.isTouched) && fieldState.error}
                  inputProps={field}
                  InputLabelProps={{ className: classes.inputLabel }}
                />
              )}
            />

            <Controller
              name='description'
              render={({ field, fieldState }) => (
                <TextField
                  label='Description'
                  fullWidth
                  helperText={`Description of ${displayName}`}
                  error={(formState.isSubmitted || fieldState.isDirty || fieldState.isTouched) && fieldState.error}
                  inputProps={field}
                  InputLabelProps={{ className: classes.inputLabel }}
                />
              )}
            />
          </Box>

          <Box className={classes.formButtonContainer}>
            <Box minWidth='12rem'>
              <RoundedButton
                secondary
                type='button'
                disabled={isSubmitting}
                onClick={onCancel}
                size='large'
                fullWidth
                className={classes.formButton}
              >
                <Text text='Cancel' variant={TEXT_VARIANTS.h3} customFontFamily='18px' />
              </RoundedButton>
            </Box>
            <Box minWidth='12rem'>
              <RoundedButton
                primary
                type='submit'
                size='large'
                fullWidth
                disabled={isSubmitting}
                className={classes.formButton}
              >
                {!isSubmitting
                  ? <Text text={`Create ${displayName}`} variant={TEXT_VARIANTS.h3} customFontFamily='18px' />
                  : <CircularProgress color='secondary' size={18} />}
              </RoundedButton>
            </Box>
          </Box>
        </form>
      </FormProvider>
    </>
  )
}

RoleNewCreateForm.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  displayName: PropTypes.string,
  userTypeId: PropTypes.number,
  onCancel: PropTypes.func,
  onSave: PropTypes.func
}

export default RoleNewCreateForm
