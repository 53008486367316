import React, { useCallback, useRef, useState } from 'react'
import { makeStyles } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { usePolicy } from '../../../../../hooks/usePolicy'
import EmptySection from '../../../../atoms/EmptySection'
import FadeIn from '../../../../molecules/Transitions/FadeIn'
import { useRoleOptions } from '../GeneralTab/AdvisorUserPermissionsSection/useRoleOptions'
import Loading from '../../../../molecules/Loading'
import PersonalSpace from '../../shared/PersonalSpace'
import { ADMIN_ROUTES } from '../../../../../constants'
import { useCreateAdvisorMutation } from '../../../../../api/users'
import SectionScreen from '../../shared/SectionScreen'
import NewAdvisorForm from './form'
import SaveFooter from './SaveFooter'

const useStyles = makeStyles({
  detailsPage: {
    margin: '1rem'
  }
})

const useSubmitter = (formRef, onComplete) => {
  const [processing, setProcessing] = useState(false)
  const [error, setError] = useState(null)
  const { mutateAsync: createAdvisor } = useCreateAdvisorMutation()
  const onSubmit = useCallback(async (formData, invite) => {
    const command = {
      user: {
        firstName: formData.user.firstName,
        lastName: formData.user.lastName,
        email: formData.user.email,
        allAccounts: formData.user.accessLevel === 'all',
        allowAllUnrestricted: formData.user.accessLevel === 'unrestricted',
        internal: formData.user.internal
      },
      roleId: +formData.role.roleId,
      invite
    }

    try {
      setError(null)
      setProcessing(true)
      const result = await createAdvisor(command)
      if (result?.statusCode === 500) {
        throw new Error('Failed to add create advisor')
      }
      onComplete(result)
    } catch (err) {
      setError(err?.toString())
    } finally {
      setProcessing(false)
    }
  }, [createAdvisor, setProcessing, onComplete, setError])

  const submitter = useCallback((invite) => async (e) => {
    const onValid = async (form) => {
      await onSubmit(form, invite)
    }
    const onInvalid = (errors) => {
      console.error(errors)
    }

    const handler = formRef.current.handleSubmit(onValid, onInvalid)
    await handler(e)
  }, [formRef, onSubmit])

  return {
    submitter,
    processing,
    error
  }
}

function NewAdvisor () {
  const classes = useStyles()
  const canEdit = usePolicy('admin_edit_advisor_users')
  const formRef = useRef()
  const history = useHistory()
  const { data: roles, isLoading: rolesLoading, defaultOption } = useRoleOptions()
  const onComplete = useCallback((result) => {
    history.push(`${ADMIN_ROUTES.ADVISORS}/${result.user.externalUserId}`)
  }, [history])

  const { submitter, processing } = useSubmitter(formRef, onComplete)

  if (!canEdit) {
    return (
      <EmptySection
        title='Access Denied'
        description='You do not have sufficient permissions to access this resource'
      />
    )
  }

  if (rolesLoading) {
    return (
      <Loading />
    )
  }

  return (
    <div className={classes.detailsPage}>
      <FadeIn>
        <SectionScreen editing sectionIsEditing={!processing}>
          <NewAdvisorForm ref={formRef} roleOptions={roles} defaultRoleOption={defaultOption} />
        </SectionScreen>
      </FadeIn>
      <PersonalSpace />
      <SaveFooter
        processing={processing}
        onCancel={() => history.push(ADMIN_ROUTES.ADVISORS)}
        onSave={(e) => submitter(false)(e)}
        onInvite={(e) => submitter(true)(e)}
      />
    </div>
  )
}

NewAdvisor.propTypes = {
}

export default NewAdvisor
