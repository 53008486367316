import React from 'react'
import PropTypes from 'prop-types'
import { Tooltip, makeStyles } from '@material-ui/core'
import { Link } from 'react-router-dom'
import Text from '../../../atoms/Text'
import FastAvatar from '../../../molecules/FastAvatar'

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '1rem',
    cursor: 'pointer',
    padding: '0.75rem 1rem',
    '&:hover': {
      background: '#F5F6F7',
      borderRadius: '0.25rem'
    }
  }
}))

const ClientItem = ({ client, tooltipTitle }) => {
  const classes = useStyles()

  return (
    <Link to={`/inbound-redirect?client=${client.clientId}`}>
      <Tooltip title={tooltipTitle}>
        <div className={classes.container}>
          <FastAvatar
            size='md'
            avatarUrl={client?.avatarUrl}
            abbreviation={client?.clientAbbreviation}
          />
          <Text text={client?.longName || client?.shortName} />
        </div>
      </Tooltip>
    </Link>
  )
}

ClientItem.propTypes = {
  client: PropTypes.shape({
    avatarUrl: PropTypes.string,
    clientAbbreviation: PropTypes.string,
    clientId: PropTypes.number,
    profilePic: PropTypes.string,
    longName: PropTypes.string,
    shortName: PropTypes.string
  }),
  tooltipTitle: PropTypes.string
}

ClientItem.defaultProps = {
  tooltipTitle: 'Go to client'
}

export default ClientItem
