export const filterOptions = Object.freeze({
  stale: {
    title: 'Out of Date',
    value: { isStale: true, isClosed: false },
    key: 'stale',
    color: '#FFC043'
  },
  current: {
    title: 'Current',
    value: { isStale: false, isClosed: false },
    key: 'current',
    color: '#3AA76D'
  },
  closed: {
    title: 'Closed',
    value: { isClosed: true },
    key: 'closed',
    color: '#898D9B'
  }
})
