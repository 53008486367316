import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { isObject, noop } from 'lodash'
import SelectFilterProvider from './SelectFilterProvider'
import ComparisonOperatorsFilter from './FilterComponents/ComparisonOperatorsFilter'
import DateFilter from './FilterComponents/DateFilter'
import CheckboxListFilter from './FilterComponents/CheckboxListFilter'
import { SELECT_FILTER_VARIANTS } from './helpers'
import CustodiansListFilter from './DomainFilterComponents/CustodiansListFilter'
import DataSourcesListFilter from './DomainFilterComponents/DataSourcesListFilter'

const getFilterComponent = (variant) => {
  if (
    [
      SELECT_FILTER_VARIANTS.numerical,
      SELECT_FILTER_VARIANTS.currency,
      SELECT_FILTER_VARIANTS.percentage,
      SELECT_FILTER_VARIANTS.count
    ].includes(variant)
  ) {
    return ComparisonOperatorsFilter
  }
  if (variant === SELECT_FILTER_VARIANTS.date) {
    return DateFilter
  }
  if (variant?.type === SELECT_FILTER_VARIANTS.list) {
    if (variant?.domain === 'custodians') {
      return CustodiansListFilter
    }
    if (variant?.domain === 'dataSources') {
      return DataSourcesListFilter
    }
    return CheckboxListFilter
  }
  throw Error('[Select Filter]: variant not found')
}

const SelectFilter = ({
  variant,
  disabled,
  onChange,
  isLoading,
  placeholder,
  filterProps
}) => {
  const FilterComponent = useMemo(() => {
    return getFilterComponent(variant)
  }, [variant])

  const filterPresetProperties = useMemo(() => {
    if (isObject(variant) && variant.type === SELECT_FILTER_VARIANTS.list) {
      return { options: variant?.options || [] }
    }
    if (variant === SELECT_FILTER_VARIANTS.currency) {
      return { startAdornment: '$' }
    }
    if (variant === SELECT_FILTER_VARIANTS.percentage) {
      return { endAdornment: '%', placeholder: '0.00' }
    }
    if (variant === SELECT_FILTER_VARIANTS.count) {
      return { placeholder: '00' }
    }
    return {}
  }, [variant])

  return (
    <SelectFilterProvider
      disabled={disabled}
      isLoading={isLoading}
      placeholder={placeholder}
      onChange={onChange}
      variant={variant || variant?.type}
    >
      <FilterComponent {...filterPresetProperties} {...filterProps} />
    </SelectFilterProvider>
  )
}

SelectFilter.propTypes = {
  variant: PropTypes.oneOfType([
    PropTypes.oneOf(Object.values(SELECT_FILTER_VARIANTS)),
    PropTypes.shape({
      type: PropTypes.oneOf(Object.values(SELECT_FILTER_VARIANTS)),
      valueKey: PropTypes.string,
      options: PropTypes.array
    })
  ]),
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  filterProps: PropTypes.object
}

SelectFilter.defaultProps = {
  variant: SELECT_FILTER_VARIANTS.numerical,
  onChange: noop,
  filterProps: {}
}

export default SelectFilter
