import React, { createContext, useCallback, useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'

export const TablePositioningContext = createContext({})

export const useTablePositioningContext = () => {
  return useContext(TablePositioningContext)
}

const getBoundingRect = (node) => node.getBoundingClientRect()

export const TablePositioningContextProvider = ({ children, shouldRefreshHeaderRect }) => {
  const [superHeaderRect, setSuperHeaderRect] = useState(null)
  const [node, setNode] = useState(null)

  const refCallback = useCallback(
    (node) => {
      if (node) {
        setNode(node)
        setSuperHeaderRect(getBoundingRect(node))
        setTimeout(() => {
          setSuperHeaderRect(getBoundingRect(node))
        }, 1000)
      }
    },
    [setSuperHeaderRect, setNode]
  )

  const resetPositioning = useCallback(() => {
    if (node) {
      setSuperHeaderRect(node.getBoundingClientRect())
    }
  }, [node, setSuperHeaderRect])

  useEffect(() => {
    if (shouldRefreshHeaderRect && node) {
      setTimeout(() => {
        setSuperHeaderRect(getBoundingRect(node))
      }, 1000)
    }
  }, [node, shouldRefreshHeaderRect])

  useEffect(() => {
    console.debug('TablePositioningContext.superHeaderRect', superHeaderRect)
  }, [superHeaderRect])

  const value = {
    superHeaderRect,
    setSuperHeaderRect,
    refCallback,
    resetPositioning
  }

  return (
    <TablePositioningContext.Provider value={value}>
      {children}
    </TablePositioningContext.Provider>
  )
}

TablePositioningContextProvider.propTypes = {
  children: PropTypes.node,
  shouldRefreshHeaderRect: PropTypes.bool
}
