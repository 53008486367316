import React, { useCallback, useImperativeHandle, useState } from 'react'
import RoundedModal from '../../../../../../molecules/RoundedModal'
import EditFamilyRateForm from './form'

const EditFamilyRateDialog = React.forwardRef(function EditFamilyRateDialog (_, ref) {
  const [dialogState, setDialogState] = useState({ open: false, processing: false })
  const close = useCallback(() => {
    setDialogState({ open: false, processing: false })
  }, [setDialogState])

  useImperativeHandle(ref, () => ({
    open: (client, feeSchedule, baseQuery) => setDialogState({ open: true, processing: false, client, feeSchedule, baseQuery })
  }), [setDialogState])

  return (
    <RoundedModal
      title='Edit Family Rate'
      open={dialogState.open}
      onClose={close}
      size='xmedium'
      position='top'
      disablePortal={false}
    >
      <div>
        {dialogState.open ? (
          <EditFamilyRateForm
            client={dialogState.client}
            feeSchedule={dialogState.feeSchedule}
            baseQuery={dialogState.baseQuery}
            onComplete={close}
            onCancel={close}
          />
        ) : null}
      </div>
    </RoundedModal>
  )
})

EditFamilyRateDialog.propTypes = {
}

export default EditFamilyRateDialog
