import { useMemo } from 'react'
import { useAdvisorViewTemplates } from '../../../api/abundanceEngine'

const findTree = (item, allItems, parentPath) => {
  const path = `${parentPath}/${item.pathSegment}`
  const children = allItems.filter(x => x.parentId === item.advisorViewTemplateId).map(x => findTree(x, allItems, path))
  return {
    ...item,
    path,
    parentPath: item.hierarchyLevel === 0 ? undefined : parentPath,
    children
  }
}

export const useTemplateConfiguration = (root) => {
  const { data, isLoading, error } = useAdvisorViewTemplates()
  const templateTree = useMemo(() => {
    if (!data) return []

    return data.filter(x => x.hierarchyLevel === 0).map(x => findTree(x, data, root))
  }, [data, root])

  return useMemo(() => ({
    templateTree,
    allTemplates: data,
    isLoading,
    error,
    root
  }), [templateTree, data, isLoading, error, root])
}
