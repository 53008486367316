import { useCallback, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { createSelector, createSlice } from '@reduxjs/toolkit'
import dayjs from 'dayjs'
import { noop } from 'lodash/util'
import {
  fetchLevelDates,
  createClientActivity,
  fetchClients
} from '../../service'
import { useAction, useAsyncAction } from '../utils'
import {
  SIDE_NAV_WIDTH,
  RIGHT_CONTENT_WIDTH,
  COLLAPSED_RIGHT_CONTENT_WIDTH,
  RIGHT_CONTENT_PADDING,
  LOCAL_STORAGE_KEYS,
  USER_ACTIVITIES_TYPES
} from '../../constants'
import { localStorageHelper } from '../../utils/localStorageHelper'
import { modifyByIndex, getAvailableDatesFormatted } from '../../utils'
import { useInvalidateRecentClients } from '../../api/clients'

const defaultMainDate = dayjs().format('YYYY-MM-DD')

/**
 * @typedef AppContext
 * @type object
 *
 * @property {number} firmId
 * @property {boolean} isAdvisor
 * @property {boolean} isSummitUser
 * @property {string} userId - the external user id
 * @property {string} internalUserId - the internal user id
 * @property {number} clientId
 * @property {boolean} isSidebarOpen
 * @property {boolean} isFullViewOpen
 * @property {string} fullViewId
 * @property {boolean} loadingAvailableDates
 * @property {boolean} loadingClient
 * @property {boolean} loadingClientBalance
 * @property {boolean} isPrintView
 * @property {object} userProfile
 * @property {string} viewTitle - title to be used on the main header
 */

export const initialState = {
  firmId: localStorageHelper.load(LOCAL_STORAGE_KEYS.firmId) || undefined,
  isAdvisor: undefined,
  isSummitUser: undefined,
  userId: undefined,
  userProfile: undefined,
  clientId: localStorageHelper.load(LOCAL_STORAGE_KEYS.clientId) || undefined,
  overviewFullViewContainerStyle: {},
  isSidebarOpen: true,
  isFullViewOpen: false,
  fullViewId: undefined,
  isNotesSideNavOpen: false,
  isProfileSideNavOpen: false,
  loadingAvailableDates: true,
  loadingClient: true,
  loadingClientBalance: true,
  disableOverviewKeyNavigation: false,
  drawerWidth: SIDE_NAV_WIDTH,
  notificationsCounter: undefined,
  clientsNotifications: [],
  rightContentWidth: RIGHT_CONTENT_WIDTH,
  rightContentPadding: RIGHT_CONTENT_PADDING,
  rightContentCollapsedWidth: COLLAPSED_RIGHT_CONTENT_WIDTH,
  availableDates: localStorageHelper.load(LOCAL_STORAGE_KEYS.availableDates) || {},
  isOverviewFullScreen: localStorageHelper.load(LOCAL_STORAGE_KEYS.isOverviewFullScreenEnabledKey) || false,
  isPrintView: false,
  overContentOnDesktop: false,
  isFullViewEditModeOn: false,
  breadCrumbAliases: {},
  showHeaderBackButton: false,
  viewTitle: undefined,
  termsAcceptance: null,
  isValidTermsAcceptance: false,
  presentationMode: false
}

const appContextSlice = createSlice({
  name: 'appContext',
  initialState,
  reducers: {
    setAppContext: (state, { payload }) => {
      Object.assign(state, payload)
    }
  }
})
const { actions } = appContextSlice

const getAppContextSlice = (state) => state[appContextSlice.name]

const getAvailableDates = createSelector(
  getAppContextSlice,
  ({ availableDates, loadingAvailableDates }) =>
    [availableDates, loadingAvailableDates]
)

export function useAppContext () {
  return useSelector(getAppContextSlice)
}

export function useAvailableDates () {
  return useSelector(getAvailableDates)
}

export function useInternalUserId () {
  const { userProfile } = useAppContext()

  return userProfile.internalId
}

export function useSetAppContext () {
  const payloadFn = useCallback((appContext) => appContext, [])
  return useAction(actions.setAppContext, payloadFn)
}

export function useHideSideMenuEffect (deps) {
  const { isFullViewOpen } = useAppContext()
  const setAppContext = useSetAppContext()
  useEffect(() => {
    setAppContext({ isFullViewOpen: isFullViewOpen })
    return () => setAppContext({ isFullViewOpen: false })
  }, [isFullViewOpen, setAppContext])

  useEffect(() => {
    setAppContext({ overContentOnDesktop: true, isSidebarOpen: false })
    return () => setAppContext({ overContentOnDesktop: true })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps)
}

const setCurrentClientActionWithCallback = (callback) => (clientId) => setCurrentClientAction(clientId, callback)

function setCurrentClientAction (clientId, callback = noop) {
  return async (dispatch, getState) => {
    try {
      dispatch(actions.setAppContext({ loadingClient: true, loadingClientBalance: true }))

      const { data: availableDatesData } = await fetchLevelDates({
        levelType: 'client',
        clientIds: [clientId]
      })

      const { appContext: { userId, availableDates: currentAvailableDates } } = getState()

      const availableDates = getAvailableDatesFormatted(availableDatesData, currentAvailableDates)
      if (userId) {
        // There is little reason to await this, as:
        // - We don't care about its response
        // - We don't need to suspend the loading of the client for this
        // - We just need to call it
        createClientActivity(userId, {
          type: USER_ACTIVITIES_TYPES.clientInteraction,
          payload: { clientId }
        })
          .then(callback)
          .catch(console.error)
      }

      localStorageHelper.storeMany({
        [LOCAL_STORAGE_KEYS.clientId]: clientId,
        [LOCAL_STORAGE_KEYS.availableDates]: availableDates
      })

      dispatch(
        actions.setAppContext({
          clientId,
          availableDates,
          loadingClient: false,
          loadingAvailableDates: false
        })
      )
    } catch (e) {
      localStorageHelper.storeMany({
        [LOCAL_STORAGE_KEYS.clientId]: null,
        [LOCAL_STORAGE_KEYS.availableDates]: {
          mainDate: defaultMainDate
        }
      })
      dispatch(
        actions.setAppContext({
          availableDates: {
            mainDate: defaultMainDate
          },
          clientId: undefined,
          loadingClient: false,
          loadingAvailableDates: false
        })
      )
    }
  }
}

export function useSetCurrentClient () {
  const invalidateRecentClients = useInvalidateRecentClients()
  const action = useMemo(() => setCurrentClientActionWithCallback(invalidateRecentClients), [invalidateRecentClients])
  return useAsyncAction(action)
}

function setIsOverviewFullScreen (isOverviewFullScreen) {
  return (dispatch) => {
    localStorageHelper.store(LOCAL_STORAGE_KEYS.isOverviewFullScreenEnabledKey, isOverviewFullScreen)
    dispatch(actions.setAppContext({ isOverviewFullScreen }))
  }
}

export function useSetIsOverviewFullScreen () {
  const payloadFn = useCallback((isOverviewFullScreen) => isOverviewFullScreen, [])
  return useAction(setIsOverviewFullScreen, payloadFn)
}

function setIsFullViewOpen (isFullViewOpen) {
  return (dispatch) => dispatch(actions.setAppContext({ isFullViewOpen }))
}

export function useSetIsFullViewOpen () {
  const payloadFn = useCallback((isFullViewOpen) => isFullViewOpen, [])
  return useAction(setIsFullViewOpen, payloadFn)
}

function setIsNotesBoardSideNavOpen (isNotesBoardOpen) {
  return (dispatch) => dispatch(actions.setAppContext({ isNotesSideNavOpen: isNotesBoardOpen }))
}

export function useSetIsNotesBoardOpen () {
  const payloadFn = useCallback((isNotesBoardOpen) => isNotesBoardOpen, [])
  return useAction(setIsNotesBoardSideNavOpen, payloadFn)
}

function setUserId (userId) {
  return (dispatch) => {
    dispatch(actions.setAppContext({ userId }))
  }
}

export function useSetUserId () {
  const payloadFn = useCallback((userId) => userId, [])
  return useAction(setUserId, payloadFn)
}

function setUserInformation ({ isAdvisor, firmId, firmClientId }) {
  return async (dispatch) => {
    try {
      const shouldRequestDefaultClient = firmId !== localStorageHelper.load(LOCAL_STORAGE_KEYS.firmId)

      let clientId = !isAdvisor
        ? firmClientId
        : localStorageHelper.load(LOCAL_STORAGE_KEYS.clientId)

      if ((shouldRequestDefaultClient || !clientId)) {
        const { data } = await fetchClients({ take: 1 })
        const [client] = data
        clientId = client.clientId
      }
      const availableDatesData = await fetchLevelDates({ levelType: 'client', clientIds: [clientId] })
      const availableDates = getAvailableDatesFormatted(availableDatesData.data)

      localStorageHelper.storeMany({
        [LOCAL_STORAGE_KEYS.firmId]: firmId,
        [LOCAL_STORAGE_KEYS.clientId]: clientId,
        [LOCAL_STORAGE_KEYS.availableDates]: availableDates
      })

      dispatch(
        actions.setAppContext({
          availableDates,
          clientId: clientId,
          loadingClient: false,
          loadingAvailableDates: false
        })
      )
    } catch (err) {
      console.error(err)
      localStorageHelper.storeMany({
        [LOCAL_STORAGE_KEYS.clientId]: null,
        [LOCAL_STORAGE_KEYS.availableDates]: {
          mainDate: defaultMainDate
        }
      })

      dispatch(
        actions.setAppContext({
          availableDates: {
            mainDate: defaultMainDate
          },
          clientId: undefined,
          loadingClient: false,
          loadingAvailableDates: false
        })
      )
    }
  }
}

export function useSetUserInformation () {
  return useAsyncAction(setUserInformation)
}

function setNotificationsCounter ({
  newCounter = undefined,
  increment = false,
  decrement = false,
  clientId
}) {
  return (dispatch, getState) => {
    let { appContext: { notificationsCounter, clientsNotifications } } = getState()
    if (newCounter) {
      notificationsCounter = newCounter
    } else if (increment) {
      notificationsCounter += 1
    } else if (decrement) {
      notificationsCounter -= 1
    }
    const clientNotificationsIndex = clientsNotifications.findIndex(
      ({ clientId: id }) => id === clientId
    )

    let clientNotifications = null
    if (clientNotificationsIndex !== -1) {
      const {
        clientId,
        notifications: { read, unread }
      } = clientsNotifications[clientNotificationsIndex]

      clientNotifications = {
        clientId,
        notifications: {
          read: read + 1,
          unread: unread ? unread - 1 : unread
        }
      }
    }

    dispatch(
      actions.setAppContext({
        notificationsCounter,
        clientsNotifications: clientNotifications
          ? modifyByIndex(clientNotificationsIndex, clientsNotifications, {
            ...clientNotifications
          })
          : clientsNotifications
      })
    )
  }
}

export function useSetNotificationCounter () {
  const payloadFn = useCallback(
    (notificationPayload) => notificationPayload,
    []
  )
  return useAction(setNotificationsCounter, payloadFn)
}

export function useSetShowHeaderBackButton () {
  const payloadFn = useCallback((showHeaderBackButton) => showHeaderBackButton, [])

  const setShowHeaderBackButton = useCallback((showHeaderBackButton) => {
    return (dispatch) => dispatch(actions.setAppContext({ showHeaderBackButton }))
  }, [])

  return useAction(setShowHeaderBackButton, payloadFn)
}

export function useSetViewTitle () {
  const payloadFn = useCallback((viewTitle) => viewTitle, [])
  const setViewTitle = useCallback((viewTitle) => {
    return (dispatch) => dispatch(actions.setAppContext({ viewTitle }))
  }, [])
  return useAction(setViewTitle, payloadFn)
}

export function useTitleEffect (viewTitle, showBack = false) {
  const setViewTitle = useSetViewTitle()
  const setShowHeaderBackButton = useSetShowHeaderBackButton()
  useEffect(() => {
    console.debug('useTitleEffect', viewTitle, showBack)
    setViewTitle(viewTitle)
    setShowHeaderBackButton(showBack)
  }, [viewTitle, showBack, setViewTitle, setShowHeaderBackButton])
}

export default appContextSlice
