import * as React from 'react'
import { useMemo } from 'react'
import { Box, makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import { useDynamicPlanning } from '../DynamicPlanningContext'
import { SKELETON_VARIANTS, TEXT_VARIANTS } from '../../../../constants'
import Text from '../../../atoms/Text'
import GoalCategoryOverviewItem from '../GoalCategories/OverviewItem'
import Skeleton from '../../../atoms/Skeleton'
import PyramidSectionDivider from './PyramidSectionDivider'
import PyramidSection from './PyramidSection'

const useStyles = makeStyles((theme) => ({
  pyramidContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    background: '#fff'
  },
  pyramidSectionWrapper: {
    position: 'relative'
  },
  pyramidSectionDivider: {
    position: 'absolute',
    zIndex: 1,
    left: 0,
    right: 0,
    top: '100%',
    borderBottom: '1px solid #ccc',
    marginTop: '-1px'
  },
  pyramidSection: {
    position: 'relative',
    '& svg': {
      display: 'block',
      margin: '0 auto'
    }
  },
  pyramidSectionLabelContainer: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    zIndex: 3,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}))

const DynamicPlanningPyramid = ({ showPyramid = true, showOverviews = true }) => {
  const { goalCategories, isLoadingData } = useDynamicPlanning()

  const classes = useStyles()

  const pyramidWidth = 550

  const sortedCategories = useMemo(() => [...goalCategories ?? []].sort((a, b) => b.ordinal - a.ordinal), [goalCategories])
  if (isLoadingData || !goalCategories) {
    return (
      <Box flexDirection='column' display='flex'>
        {[...Array(3)].map((_, index) => (
          <Box key={index} display='flex'>
            {showPyramid && (
              <Box flexBasis={pyramidWidth} flex={`0 0 ${pyramidWidth}px`}>
                <PyramidSection pyramidWidth={pyramidWidth} index={index} color='#eee' isPulsing />
                {index !== 2 && (
                  <PyramidSectionDivider
                    sourceColor='#fff'
                    index={index}
                    pyramidWidth={pyramidWidth}
                    isPulsing
                  />
                )}
              </Box>
            )}
            {showOverviews && (
              <Box flex='1' padding='1.5rem 2rem'>
                <Box display='flex' gridGap='2rem' height='100%'>
                  <Skeleton variant={SKELETON_VARIANTS.circle} width='70px' height='70px' />
                  <Box display='flex' flex='1' flexDirection='column' height='100%'>
                    <Box flex='1'>
                      <Skeleton height='30px' width='60%' text={SKELETON_VARIANTS.text} />
                      <Box margin='.5rem 0'>
                        <Skeleton height='20px' text={SKELETON_VARIANTS.text} />
                      </Box>
                    </Box>
                    <Box>
                      <Skeleton height='20px' width='50px' text={SKELETON_VARIANTS.text} />
                    </Box>
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
        ))}
      </Box>
    )
  }

  return (
    <Box flexDirection='column' display='flex'>
      {sortedCategories.map((category, index) => (
        <Box position='relative' display='flex' key={category.categoryId}>
          {showPyramid && (
            <Box
              position='relative'
              display='flex'
              flexDirection='column'
              flex={`0 0 ${pyramidWidth}px`}
              flexBasis={pyramidWidth}
              margin={!showOverviews ? '0 auto' : ''}
              zIndex={2}
            >
              <PyramidSection pyramidWidth={pyramidWidth} index={index} color={category.primaryColor} />
              <div className={classes.pyramidSectionLabelContainer} style={{ top: index === 0 ? '35%' : 0 }}>
                <Text
                  variant={TEXT_VARIANTS.h3}
                  text={category.shortName}
                  color='#000'
                  customFontSize='18px'
                />
              </div>
              {index !== goalCategories.length - 1 && (
                <PyramidSectionDivider
                  sourceColor={category.primaryColor}
                  index={index}
                  pyramidWidth={pyramidWidth}
                />
              )}
            </Box>
          )}
          {showOverviews && (
            <>
              <div className={classes.pyramidSectionDivider} style={{ left: showPyramid ? pyramidWidth / 2 : 0 }} />
              <Box>
                <GoalCategoryOverviewItem goalCategory={category} />
              </Box>
            </>
          )}
        </Box>
      ))}
    </Box>
  )
}

DynamicPlanningPyramid.propTypes = {
  showPyramid: PropTypes.bool,
  showOverviews: PropTypes.bool
}

export default DynamicPlanningPyramid
