import { useClassificationTagTypes } from '../../../../api/coreData'

const tagTypesQuery = {
  take: 200,
  sort: [{ field: 'longName', dir: 'asc' }]
}
const tagTypesMapper = data => {
  return data.map(x => ({
    label: `${x.classificationTagTypeId} - ${x.longName}`,
    value: x.classificationTagTypeId
  }))
}

export function useClassificationTagTypeOptions () {
  const { data: options, isLoading } = useClassificationTagTypes(tagTypesQuery, { mapper: tagTypesMapper })

  return {
    options,
    isLoading
  }
}
