import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import FadeIn from '../../../molecules/Transitions/FadeIn'

const useStyles = makeStyles((theme) => ({
  varianceDisplay: {
    minHeight: '105px',
    padding: '20px 60px'
  },
  value: {
    fontSize: '42px',
    lineHeight: '42px'
  },
  metricName: {
    fontSize: '16px',
    fontWeight: '100'
  },
  loader: {
    minHeight: '105px'
  }
}))

function AnalysisMetric ({ value, loading, metricName }) {
  const classes = useStyles()

  if (loading) {
    return (
      <div className={classes.loader} />
    )
  }

  return (
    <FadeIn className={`${classes.varianceDisplay} __loaded`}>
      <div className={classes.value}>{value}</div>
      <div className={classes.metricName}>{metricName}</div>
    </FadeIn>
  )
}

AnalysisMetric.propTypes = {
  loading: PropTypes.bool,
  value: PropTypes.any,
  metricName: PropTypes.string
}

AnalysisMetric.defaultProps = {
  purpose: 'rebalancing',
  metricName: 'Variance'
}

export default AnalysisMetric
