import { useCallback, useEffect, useMemo, useState } from 'react'
import { useAssignUnAssignEntities } from '../../../../hooks'
import useDebouncedValue from '../../../../hooks/useDebounceValue'
import {
  useSearchClients,
  useSearchClientsMultiple
} from '../../../../api/clients'
import { DEFAULT_SELECT_SEARCH_PAGINATION, MILLISECONDS_BEFORE_SEARCH } from '../../../../constants'
import {
  DEFAULT_ACCOUNTS_PAGINATION,
  getAccountsSearchQuery,
  mapClients,
  mapClientsMultiQueryResponse,
  mapClientsResponse
} from './helpers'

const useAccountClients = ({
  accountId,
  resetState = false,
  searchPagination = DEFAULT_SELECT_SEARCH_PAGINATION,
  pagination = DEFAULT_ACCOUNTS_PAGINATION
}) => {
  const {
    searchResultOptions,
    onAssignUnAssignEntity,
    selectedEntities: selectedClients,
    assignedEntities: clientsAssigned,
    unAssignedEntities: clientsUnAssigned,
    setAssignedEntities: setClientsAssigned,
    setUnAssignedEntities: setClientsUnAssigned,
    persistEntitiesSelection: persistClientsSelection
  } = useAssignUnAssignEntities({ resetState })

  const searchMultipleQuery = useMemo(() => {
    const paramsFilter = {
      assignedToAccountIds: [accountId]
    }
    const query = {
      assignedClients: {
        ...pagination,
        filters: paramsFilter
      },
      totalAssignedClients: {
        resultType: 'total',
        filters: paramsFilter
      }
    }
    return query
  }, [accountId, pagination])

  const {
    isLoading,
    data: clientsData,
    refetch: refetchClientsAssigned
  } = useSearchClientsMultiple(
    searchMultipleQuery,
    mapClientsMultiQueryResponse
  )

  useEffect(() => {
    const { clients = [], total } = clientsData || {}
    if (!isLoading) {
      setClientsAssigned({
        loading: false,
        data: mapClients(clients),
        total: total
      })
    } else {
      setClientsAssigned({
        loading: true
      })
    }
  }, [clientsData, isLoading, setClientsAssigned])

  const [query, setQuery] = useState('')
  const debouncedQuery = useDebouncedValue(query, MILLISECONDS_BEFORE_SEARCH)

  const { query: searchQuery, queryOptions } = useMemo(() => {
    const textSearch = getAccountsSearchQuery(debouncedQuery)
    return {
      query: {
        ...searchPagination,
        textSearch,
        filters: {
          notAssignedToAccountIds: { op: 'in', value: [accountId] }
        }
      },
      queryOptions: {
        mapper: mapClientsResponse
      }
    }
  }, [searchPagination, accountId, debouncedQuery])

  const { isLoading: unassignedClientsLoading, data: unassignedClients } =
    useSearchClients(searchQuery, queryOptions)

  const isTransitioning = debouncedQuery !== query || unassignedClientsLoading

  useEffect(() => {
    if (!isTransitioning) {
      setClientsUnAssigned({
        loading: false,
        data: mapClients(unassignedClients),
        total: unassignedClients.length,
        isEmpty: !unassignedClients.length
      })
    } else {
      setClientsUnAssigned({
        loading: isTransitioning
      })
    }
  }, [unassignedClients, isTransitioning, setClientsUnAssigned])

  const onAssignUnAssignClient = useCallback(
    ({ clientId, isAssign, client }) => {
      onAssignUnAssignEntity({
        entityId: clientId,
        entity: client,
        isAssign
      })
    },
    [onAssignUnAssignEntity]
  )

  return {
    clientsAssigned,
    clientsUnAssigned,
    selectedClients,
    searchResultOptions,
    refetchClientsAssigned,
    onAssignUnAssignClient,
    persistClientsSelection,
    onSearchClients: setQuery
  }
}

export default useAccountClients
