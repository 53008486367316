import React from 'react'
// import PropTypes from 'prop-types'
import { Box, Container, Grid, makeStyles } from '@material-ui/core'
import Skeleton from '../../atoms/Skeleton'
import { SKELETON_VARIANTS } from '../../../constants'

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '1.5rem',
    height: '100%'
  }
}))

const PreferencesSkeleton = () => {
  const classes = useStyles()

  return (
    <Container fixed maxWidth={false} className={classes.container}>
      <Grid container spacing={2}>
        <Grid container direction='column' alignItems='center' md={2} xs={12}>
          <Box display='flex' alignItems='center' justifyContent='center' width='100%'>
            <Skeleton
              variant={SKELETON_VARIANTS.circle}
              width='120px'
              height='120px'
            />
          </Box>
          <Box display='flex' alignItems='center' justifyContent='center' width='100%'>
            <Skeleton
              variant={SKELETON_VARIANTS.text}
              width='50px'
              height='50px'
            />
          </Box>
        </Grid>
        <Grid item md={10} xs={12}>
          <Grid container spacing={4}>
            <Grid item md={6} xs={12}>
              <Skeleton
                variant={SKELETON_VARIANTS.text}
                width='100%'
                height='88px'
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <Skeleton
                variant={SKELETON_VARIANTS.text}
                width='100%'
                height='88px'
              />
            </Grid>
            <Grid item xs={12}>
              <Skeleton
                variant={SKELETON_VARIANTS.text}
                width='100%'
                height='88px'
              />
            </Grid>
          </Grid>
        </Grid>
        {new Array(5).fill(0).map((_, index) => {
          return (
            <React.Fragment key={`skeleton-${index}`}>
              <Grid container spacing={2}>
                <Grid container xs={12} spacing={1}>
                  <Grid item md={4} xs={12}>
                    <Skeleton
                      variant={SKELETON_VARIANTS.text}
                      width='100%'
                      height='88px'
                    />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <Skeleton
                      variant={SKELETON_VARIANTS.text}
                      width='100%'
                      height='88px'
                    />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <Skeleton
                      variant={SKELETON_VARIANTS.text}
                      width='100%'
                      height='88px'
                    />
                  </Grid>
                </Grid>
              </Grid>
            </React.Fragment>
          )
        })}
      </Grid>
    </Container>
  )
}

export default PreferencesSkeleton
