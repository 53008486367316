import React, { useCallback, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Controller, useForm } from 'react-hook-form'
import { Grid, makeStyles, MenuItem, TextField, useTheme } from '@material-ui/core'
import isEmpty from 'lodash/isEmpty'
import noop from 'lodash/noop'
import { Link } from 'react-router-dom'
import { useQueryClient } from '@tanstack/react-query'
import { ADMIN_ROUTES, ALERT_SEVERITY, BUTTON_SIZES, ERROR_ALERT, SUCCESS_ALERT } from '../../../../../constants'
import { createReportTemplate, updateReportTemplate } from '../../../../../service'
import Text from '../../../../atoms/Text'
import SnackAlert from '../../../../molecules/SnackAlert/SnackAlert'
import { messageToUse } from '../../common'
import RoundedButton from '../../../../atoms/RoundedButton'
import AceCodeEditor from '../../../../molecules/AceCodeEditor'
import { useListReportRuntimeConfigurationData } from '../hooks'
import Skeleton from '../../../../atoms/Skeleton'
import { useAppContext } from '../../../../../redux/slices/appContext'
import { QUERY_KEYS } from '../../../../../api/queryKeys'

const useStyles = makeStyles(() => ({
  container: {
    padding: '0 .5rem'
  },
  confirmError: {
    textAlign: 'left',
    marginTop: '0.375rem'
  },
  groupItem: {
    width: '100%',
    padding: '1rem'
  },
  buttonGroup: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between'
  },
  buttonGroupItem: {
    display: 'flex',
    width: '20rem',
    maxWidth: '100%',
    padding: '0 1rem'
  },
  itemTextField: {
    backgroundColor: '#F4F5F6',
    borderRadius: '4px',
    paddingTop: '0.625rem',
    paddingLeft: '0.625rem',
    marginTop: '0.625rem',
    marginBottom: '0.625rem'
  },
  multilineItemTextField: {
    backgroundColor: '#F4F5F6',
    borderRadius: '4px',
    paddingTop: '0.625rem',
    paddingLeft: '0.625rem',
    marginTop: '0.625rem',
    marginBottom: '0.625rem',
    overflow: 'auto'
  },
  labelWithInfo: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  labelInfo: {
    opacity: '.8'
  }
}))

const TemplateForm = ({
  editTemplateData,
  onSubmit,
  onSetEditTemplateData
}) => {
  const classes = useStyles()
  const theme = useTheme()
  const [alert, setAlert] = useState({})
  const { userId } = useAppContext()
  const queryClient = useQueryClient()

  const initialFormState = useMemo(() => {
    return {
      templateId: null,
      name: '',
      content: '',
      defaultRuntimeConfigurationId: editTemplateData.templateId ?? ''
    }
  }, [editTemplateData.templateId])

  const {
    handleSubmit,
    control,
    register,
    reset,
    clearErrors,
    setError,
    formState: { errors, isSubmitting }
  } = useForm({
    mode: 'onChange',
    defaultValues: initialFormState
  })

  // runtime configurations
  const { data: runtimeConfigurations, loading: isRuntimeConfigurationsLoading } = useListReportRuntimeConfigurationData({
    templateId: editTemplateData.templateId,
    offset: 0,
    limit: 1000,
    sort: ''
  })

  useEffect(() => {
    reset(!isEmpty(editTemplateData) ? editTemplateData : initialFormState)
  }, [initialFormState, reset, editTemplateData])

  const isCreate = useMemo(() => !editTemplateData.templateId ?? false, [editTemplateData])

  const cancelEdit = useCallback(() => {
    onSetEditTemplateData({})
    reset(initialFormState)
  }, [reset, initialFormState, onSetEditTemplateData])

  const onSubmitHandler = useCallback(
    async (values) => {
      clearErrors()

      let parsedContent
      try {
        parsedContent = values.content?.length
          ? JSON.parse(values.content)
          : {}
      } catch (err) {
        setError('content', { type: 'pattern' })
        return
      }

      try {
        const body = {
          name: values.name,
          content: parsedContent,
          defaultRuntimeConfigurationId: values.defaultRuntimeConfigurationId
        }

        values.templateId
          ? await updateReportTemplate(values.templateId, body)
          : await createReportTemplate(body)

        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.reportTemplates, userId] })

        onSubmit({
          ...SUCCESS_ALERT,
          alertMessage: `Template ${values.templateId ? 'updated' : 'created'} successfully`,
          alertSeverity: ALERT_SEVERITY.info
        })
      } catch (error) {
        console.error(error)
        setAlert({
          ...ERROR_ALERT,
          alertMessage: 'Something went wrong. Please try again'
        })
        return
      }

      reset(initialFormState)
    },
    [clearErrors, reset, initialFormState, setError, queryClient, userId, onSubmit]
  )

  const renderTemplateName = useCallback(
    ({ field: { onChange } }) => {
      return (
        <>
          <TextField
            type='input'
            fullWidth
            className={classes.itemTextField}
            onChange={onChange}
            placeholder='Template Name'
            error={Boolean(errors.name)}
            disabled={isSubmitting}
            {...register('name', { required: true })}
          />
          {errors?.name && (
            <Text
              text={messageToUse(errors.name)}
              color={theme.palette.error.main}
              className={classes.confirmError}
            />
          )}
        </>
      )
    }, [
      register,
      errors?.name,
      classes.itemTextField,
      classes.confirmError,
      theme.palette.error.main,
      isSubmitting
    ]
  )

  const renderRuntimeConfigurationControl = useCallback(
    ({ field: { onChange, ...field } }) => {
      if (isRuntimeConfigurationsLoading) {
        return (
          <Skeleton
            className={classes.itemTextField}
            height='2.3rem'
            width='100%'
          />)
      }
      if (!runtimeConfigurations?.length) {
        return <Text text='No configurations are added to this template yet' variant='p' />
      }
      return (
        <TextField
          select
          fullWidth
          className={classes.itemTextField}
          onChange={onChange}
          placeholder='No runtime configuration'
          error={Boolean(errors.runtimeConfigurations)}
          disabled={isRuntimeConfigurationsLoading}
          defaultValue={field.value}
          {
            ...field
          }
        >
          {runtimeConfigurations.map(({ runtimeConfigurationId, name }) => (
            <MenuItem key={runtimeConfigurationId} value={runtimeConfigurationId}>
              {name}
            </MenuItem>
          ))}
        </TextField>
      )
    }
    ,
    [
      runtimeConfigurations,
      errors.runtimeConfigurations,
      classes.itemTextField,
      isRuntimeConfigurationsLoading
    ]
  )

  const renderContent = useCallback(
    ({ field: { onChange, value } }) =>
      (
        <div style={{ minHeight: 500, marginTop: '.5rem' }}>
          <AceCodeEditor
            tabSize={4}
            mode='markdown'
            id='view-editor'
            name='viewContent'
            value={value}
            defaultValue={initialFormState.content}
            onChange={onChange}
            width='100%'
            rows={20}
            required
          />
          {errors?.content && (
            <Text
              text={messageToUse(errors.content)}
              color={theme.palette.error.main}
              className={classes.confirmError}
            />
          )}
        </div>
      ), [initialFormState.content, errors.content, theme.palette.error.main, classes.confirmError]
  )

  return (
    <div className={classes.container}>
      <form onSubmit={handleSubmit(onSubmitHandler)}>
        <Grid container md={12}>
          <div className={classes.groupItem}>
            <Text text='Name' variant='h3' />
            <Controller
              name='name'
              render={renderTemplateName}
              control={control}
              rules={{ required: true }}
            />
          </div>
        </Grid>

        {!isCreate && (
          <Grid container md={12}>
            <div className={classes.groupItem}>
              <div className={classes.labelWithInfo}>
                <Text text='Default Runtime Configuration' variant='h3' />
                <Link to={ADMIN_ROUTES.REPORTS_TEMPLATES_RUNTIME_CONFIGURATIONS}>Manage Configurations</Link>
              </div>
              <Controller
                name='defaultRuntimeConfigurationId'
                render={renderRuntimeConfigurationControl}
                control={control}
                rules={{ required: true }}
              />
              {errors.defaultRuntimeConfigurationId && (
                <Text
                  text={messageToUse(errors.defaultRuntimeConfigurationId)}
                  color={theme.palette.error.main}
                  className={classes.confirmError}
                />
              )}
            </div>
          </Grid>)}

        <Grid container md={12}>
          <div className={classes.groupItem}>
            <Text text='Template' variant='h3' />
            <Controller
              name='content'
              render={renderContent}
              control={control}
              rules={{ required: true }}
            />
          </div>
          <Grid container md={12}>
            <Grid item md={12} className={classes.buttonGroup}>
              <div className={classes.buttonGroupItem}>
                <RoundedButton
                  secondary
                  size={BUTTON_SIZES.medium}
                  disabled={isSubmitting}
                  isLoading={isSubmitting}
                  fullWidth={1}
                  onClick={() => cancelEdit()}
                >
                  Cancel
                </RoundedButton>
              </div>
              <div className={classes.buttonGroupItem}>
                <RoundedButton
                  primary
                  type='submit'
                  size={BUTTON_SIZES.medium}
                  disabled={isSubmitting}
                  isLoading={isSubmitting}
                  fullWidth={1}
                >
                  {!isCreate ? 'Save Changes' : 'Create Template'}
                </RoundedButton>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </form>
      <SnackAlert alert={alert} />
    </div>
  )
}

TemplateForm.propTypes = {
  editTemplateData: PropTypes.object,
  onSubmit: PropTypes.func,
  onSetEditTemplateData: PropTypes.func
}

TemplateForm.defaultProps = {
  editTemplateData: {},
  onSubmit: noop,
  onSetEditTemplateData: noop
}

export default TemplateForm
