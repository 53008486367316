import React from 'react'
import { Grid } from '@material-ui/core'
import FadeIn from '../../../../molecules/Transitions/FadeIn'
import PersonalSpace from '../../shared/PersonalSpace'
import ClientInfoSection from './ClientInfoSection'
import ClientTeamSection from './ClientTeamSection'
import ActionsSection from './ActionsSection'

function GeneralTab () {
  return (
    <FadeIn>
      <Grid container>
        <Grid item xs={12} md={6}>
          <ClientInfoSection />
          <ActionsSection />
        </Grid>
        <Grid item xs={12} md={6}>
          <ClientTeamSection />
        </Grid>
      </Grid>
      <PersonalSpace />
    </FadeIn>
  )
}

GeneralTab.propTypes = {
}

export default GeneralTab
