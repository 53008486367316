import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { Pie } from '@nivo/pie'
import { makeStyles } from '@material-ui/core/styles'
import AutoSizer from 'react-virtualized-auto-sizer'
import noop from 'lodash/noop'
import { themeConfiguration } from '../../theme'
import { NIVO_MOTION_PRESETS } from '../../constants'

const useStyles = makeStyles(() => ({
  container: ({ height = '260px', width = '260px' }) => ({
    height,
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    flex: `1 1 ${width}`
  }),
  content: ({ height }) => ({
    height,
    flex: '1 1 100%'
  })
}))

function PieChart ({
  activeInnerRadiusOffset,
  activeOuterRadiusOffset,
  animate,
  arcLabel,
  arcLabelsRadiusOffset,
  arcLabelsTextColor,
  arcLabelsFontSize,
  arcLabelsComponent,
  arcLinkLabel,
  arcLinkLabelsColor,
  arcLinkLabelsDiagonalLength,
  arcLinkLabelsStraightLength,
  arcLinkLabelsTextColor,
  arcLinkLabelsTextOffset,
  arcLinkLabelsThickness,
  cornerRadius,
  data,
  enableArcLabels,
  enableArcLinkLabels,
  height,
  innerRadius,
  isInteractive,
  margin,
  motionConfig,
  onClick,
  padAngle,
  sortByValue,
  tooltip,
  useDataColors,
  width
}) {
  const classes = useStyles({ height, width })
  const colors = useCallback((datum) => datum.data.color, [])

  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <AutoSizer>
          {({ height, width }) => (
            <Pie
              height={height}
              width={width}
              tooltip={tooltip}
              activeInnerRadiusOffset={activeInnerRadiusOffset}
              activeOuterRadiusOffset={activeOuterRadiusOffset}
              animate={animate}
              arcLabel={arcLabel}
              arcLabelsRadiusOffset={arcLabelsRadiusOffset}
              arcLabelsTextColor={arcLabelsTextColor}
              arcLinkLabel={arcLinkLabel}
              arcLinkLabelsColor={arcLinkLabelsColor}
              arcLabelsComponent={arcLabelsComponent}
              arcLinkLabelsDiagonalLength={arcLinkLabelsDiagonalLength}
              arcLinkLabelsStraightLength={arcLinkLabelsStraightLength}
              arcLinkLabelsTextColor={arcLinkLabelsTextColor}
              arcLinkLabelsTextOffset={arcLinkLabelsTextOffset}
              arcLinkLabelsThickness={arcLinkLabelsThickness}
              colors={useDataColors ? colors : undefined}
              cornerRadius={cornerRadius}
              data={data}
              enableArcLabels={enableArcLabels}
              enableArcLinkLabels={enableArcLinkLabels}
              innerRadius={innerRadius}
              isInteractive={isInteractive}
              margin={margin}
              motionConfig={motionConfig}
              onClick={onClick}
              padAngle={padAngle}
              sortByValue={sortByValue}
              theme={{ labels: { text: { fontSize: arcLabelsFontSize, fontWeight: 500, fontFamily: themeConfiguration.typography.fontFamily } } }}
            />
          )}
        </AutoSizer>
      </div>
    </div>
  )
}

PieChart.propTypes = {
  // Skip label if corresponding slice's angle is lower than provided value
  activeInnerRadiusOffset: PropTypes.number,
  // Skip label if corresponding slice's angle is lower than provided value
  activeOuterRadiusOffset: PropTypes.number,
  // Enable/disable transitions
  animate: PropTypes.bool,
  // Defines how to get label text, can be a string (used to access current node data property) or a function which will receive the actual node data
  arcLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  // Define the radius to use to determine the label position, starting from inner radius, this is expressed as a ratio
  arcLabelsRadiusOffset: PropTypes.number,
  // Defines how to compute arc label text color
  arcLabelsTextColor: PropTypes.string,
  // Defines how to compute arc link label text
  arcLinkLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  // Defines how to compute arc link label link color
  arcLinkLabelsColor: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  // Font size of the labels of each slice
  arcLabelsFontSize: PropTypes.string,
  // Component to show in each slice as label
  arcLabelsComponent: PropTypes.func,
  // Link diagonal length
  arcLinkLabelsDiagonalLength: PropTypes.number,
  // Length of the straight segment of the links
  arcLinkLabelsStraightLength: PropTypes.number,
  // Defines how to compute arc link label text color
  arcLinkLabelsTextColor: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  // X offset from links' end
  arcLinkLabelsTextOffset: PropTypes.number,
  // Links stroke width.
  arcLinkLabelsThickness: PropTypes.number,
  // Corner radius of the slice. Measured in pixels
  cornerRadius: PropTypes.number,
  // The data to display in the PieChart
  data: PropTypes.arrayOf(PropTypes.shape({
    // Unique Id among the dataset
    id: PropTypes.string,
    // Label of the value
    label: PropTypes.string,
    // Value to calculate the pie chart portion
    value: PropTypes.number,
    // Color of the value
    color: PropTypes.string,
    // extra value
    balance: PropTypes.number,
    // Order for data
    ordinal: PropTypes.number
  })),
  // Enable/disable arc labels
  enableArcLabels: PropTypes.bool,
  // Enable/disable arc link labels
  enableArcLinkLabels: PropTypes.bool,
  // The height of the PieChart container
  height: PropTypes.string,
  // Donut chart if greater than 0. Value should be between 0~1 as it's a ratio from original radius
  innerRadius: PropTypes.number,
  // Enable/disable interactivity
  isInteractive: PropTypes.bool,
  // Margin of the entire chart measured in pixels
  margin: PropTypes.shape({
    top: PropTypes.number,
    bottom: PropTypes.number,
    right: PropTypes.number,
    left: PropTypes.number
  }),
  // Motion config for react-spring, either a preset or a custom configuration
  motionConfig: PropTypes.oneOf(Object.values(NIVO_MOTION_PRESETS)),
  // On click function. The function receives a dataset node and an event params. Example: (item, event) => void
  onClick: PropTypes.func,
  // Padding between each pie slice. Measured in grads
  padAngle: PropTypes.number,
  // If true, arcs will be ordered according to their associated value
  sortByValue: PropTypes.bool,
  // Defines component to show after hovering on a pie item
  tooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  // If true, use the color key passed in the dataset
  useDataColors: PropTypes.bool,
  // The width of the Pie Chart container
  width: PropTypes.string
}

PieChart.defaultProps = {
  activeInnerRadiusOffset: 0,
  activeOuterRadiusOffset: 0,
  animate: true,
  arcLabel: 'formattedValue',
  arcLabelsRadiusOffset: 0.5,
  arcLabelsTextColor: 'white',
  arcLinkLabel: 'id',
  arcLinkLabelsColor: 'black',
  arcLabelsFontSize: '1rem',
  arcLabelsComponent: undefined,
  arcLinkLabelsDiagonalLength: 0,
  arcLinkLabelsStraightLength: 0,
  arcLinkLabelsTextColor: 'black',
  arcLinkLabelsTextOffset: 0,
  arcLinkLabelsThickness: 1,
  cornerRadius: 0,
  data: [],
  enableArcLabels: true,
  enableArcLinkLabels: false,
  height: undefined,
  innerRadius: 0.35,
  isInteractive: true,
  margin: { top: 0, right: 0, bottom: 0, left: 0 },
  motionConfig: 'gentle',
  onClick: noop,
  padAngle: 0,
  sortByValue: false,
  tooltip: undefined,
  useDataColors: false,
  width: undefined
}

export default React.memo(PieChart)
