import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  thingContentBase: ({ jss }) => ({
    padding: 0,
    '& .__thing-content': {
      ...(jss || {})
    }
  })
}))

function ThingContentStyles ({ jss, children }) {
  const classes = useStyles({ jss })

  return (
    <div className={classes.thingContentBase}>
      {children}
    </div>
  )
}

ThingContentStyles.propTypes = {
  jss: PropTypes.object.isRequired,
  children: PropTypes.node
}

export default ThingContentStyles
