import React, { useMemo } from 'react'
import Grid from '@material-ui/core/Grid'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { Blade } from '../../shared/Blade'
import Loading from '../../../../molecules/Loading'
import {
  useGetGroupedCoreData,
  useTransactionsSearch
} from '../../../../../api/coreData'
import PersonalSpace from '../../../../molecules/PersonalSpace'
import MiniTable from '../../shared/MiniTable'
import { useTransactionTagging } from './TransactionTaggingContext'
import { useIdentifyFlows } from './useIdentifyFlows'

dayjs.extend(utc)

const defaultTransactionsColumns = [
  { accessor: 'suggestion', header: 'Tag Suggestion', format: 'title' },
  { accessor: 'transactionId', header: 'ID' },
  { accessor: 'custodianName', header: 'Cust', format: 'title' },
  { accessor: 'accountNumber', header: 'Acct#', format: 'title' },
  { accessor: 'assetIdentifier', header: 'Symbol', format: 'title' },
  { accessor: 'valueDate', header: 'Value Date', format: '@MM/DD/YYYY' },
  { accessor: 'adjustedMarketValue', header: 'AMV', format: 'marketValueCents' },
  { accessor: 'units', header: 'U', format: 'number' },
  { accessor: 'globalDescription', header: 'Desc', format: 'title' }
]

const useTransactions = (client, dateRange) => {
  const transactionsQuery = useMemo(() => {
    if (!client || !dateRange) return null

    return {
      includes: {
        transactionCodeTags: true
      },
      filters: {
        clientId: client?.clientId,
        valueDate: [
          { op: 'gte', value: dateRange.startDate },
          { op: 'lte', value: dateRange.endDate }
        ]
      },
      take: 1000
    }
  }, [client, dateRange])
  const { data: transactions, isLoading: transactionsLoading } = useTransactionsSearch(transactionsQuery, { enabled: !!transactionsQuery })

  const [_transactions, _codes] = useMemo(() => {
    if (!transactions) return [[], []]

    const codes = new Set()
    const result = transactions.map(t => {
      const tags = t.transactionCodeTags?.reduce((prev, cur) => {
        codes.add(cur.typeName)
        prev[`tc_${cur.typeName}`] = cur
        return prev
      }, {})
      return {
        ...t,
        ...tags
      }
    })

    return [result, Array.from(codes)]
  }, [transactions])

  const _columns = useMemo(() => {
    if (!_codes) return defaultTransactionsColumns

    const cols = [...defaultTransactionsColumns]
    for (const type of _codes) {
      cols.push({
        accessor: `tc_${type}.displayName`,
        header: type,
        format: 'title'
      })
    }

    return cols
  }, [_codes])

  return {
    transactions: _transactions,
    transactionsLoading,
    transactionsColumns: _columns,
    transactionCodeTypes: _codes
  }
}

const useAccountValues = (client, dateRange) => {
  const coreDataQuery = useMemo(() => {
    if (!client || !dateRange) return null

    return {
      levelFilters: {
        levelTypes: ['client', 'account'],
        clientIds: [client?.clientId],
        calcType: 'timeseries',
        dateType: 'day'
      },
      dateRange: {
        startDate: dateRange.startDate,
        endDate: dateRange.endDate
      }
    }
  }, [client, dateRange])
  const { data: coreData, isLoading: coreDataLoading } = useGetGroupedCoreData(coreDataQuery, { enabled: !!coreDataQuery })

  const _coreData = useMemo(() => {
    if (!coreData) return []

    return coreData.filter(x => !!x.netAdditionsNOF).sort((a, b) => b.minDate.localeCompare(a.minDate))
  }, [coreData])
  return {
    coreData: _coreData,
    coreDataLoading
  }
}

const transactionKeyAccessor = trx => trx?.transactionId

const flowAccessor = f => f ? f.position.uniqueId : undefined
const clientFlowAccessor = f => f ? f.position.minDate : undefined

function MainBlade () {
  const {
    client,
    relativeDates,
    datesLoading,
    dateRange,
    rules
  } = useTransactionTagging()
  const { transactions, transactionsLoading, transactionsColumns } = useTransactions(client, relativeDates?.[dateRange]?.value)
  const { coreData, coreDataLoading } = useAccountValues(client, relativeDates?.[dateRange]?.value)
  const { classifications, accountMatches, clientMatches, candidates, other } = useIdentifyFlows(transactions, coreData, rules.data)
  const clientFlowColumns = useMemo(() => {
    if (!classifications?.length) return []

    const result = [
      { accessor: 'position.minDate', header: 'Value Date', format: '@MM/DD/YYYY' },
      { accessor: 'position.netAdditionsNOF', header: 'NA', format: 'marketValueCents' }
    ]
    classifications.forEach(c => {
      // result.push({ accessor: `groups.${c}.aggregate.count`, header: `#${c}`, format: 'number' })
      result.push({ accessor: `groups.${c}.aggregate.adjustedMarketValue`, header: `${c}`, format: 'marketValueCents' })
    })
    result.push({ accessor: 'tbd', header: 'TBD', format: 'marketValueCents' })

    return result
  }, [classifications])

  const accountFlowColumns = useMemo(() => {
    if (!classifications?.length) return []

    const result = [
      { accessor: 'position.minDate', header: 'Value Date', format: '@MM/DD/YYYY' },
      { accessor: 'position.accountNumber', header: 'Acct #', format: 'title' },
      { accessor: 'position.netAdditionsNOF', header: 'NA', format: 'marketValueCents' }
    ]
    classifications.forEach(c => {
      // result.push({ accessor: `groups.${c}.aggregate.count`, header: `#${c}`, format: 'number' })
      result.push({ accessor: `groups.${c}.aggregate.adjustedMarketValue`, header: `${c}`, format: 'marketValueCents' })
    })
    result.push({ accessor: 'tbd', header: 'TBD', format: 'marketValueCents' })

    return result
  }, [classifications])

  if (!client || !classifications) {
    return null
  }

  if (datesLoading || transactionsLoading || coreDataLoading || rules.isLoading) {
    return (
      <Blade>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Loading />
          </Grid>
        </Grid>
      </Blade>
    )
  }

  return (
    <Blade>
      <Grid container spacing={1}>
        <Grid item xs={8}>
          <Grid container spacing={3}>
            {classifications.map(c => (
              <Grid item xs={12} key={c}>
                <header>Candidate {c}</header>
                <MiniTable
                  data={candidates[c]}
                  columns={transactionsColumns}
                  keyAccessor={transactionKeyAccessor}
                />
              </Grid>
            ))}
            <Grid item xs={12}>
              <header>Other</header>
              <MiniTable
                lowlighted
                data={other}
                columns={transactionsColumns}
                keyAccessor={transactionKeyAccessor}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <h3>Client Activity</h3>
              <MiniTable
                data={clientMatches || []}
                columns={clientFlowColumns}
                keyAccessor={clientFlowAccessor}
              />
            </Grid>

            <Grid item xs={12}>
              <h3>Account Activity</h3>
              <MiniTable
                data={accountMatches || []}
                columns={accountFlowColumns}
                keyAccessor={flowAccessor}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <PersonalSpace />
    </Blade>
  )
}

export default MainBlade
