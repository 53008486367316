import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles, Button } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import Icon from '../../../../atoms/Icon'
import { ADMIN_ROUTES, ICON_NAMES } from '../../../../../constants'

const useStyles = makeStyles(() => ({
  previewContainer: {
    minHeight: 0,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative'
  },
  content: {
    position: 'relative',
    height: '100%',
    width: '100%'
  },
  header: {
    padding: '0.5rem 1.5rem'
  },
  headerContent: {
    px: '4rem',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  backButton: {
    fontWeight: 'bold',
    textTransform: 'none'
  },
  icon: {
    marginLeft: '0.5rem'
  }
}))

const WheelPreviewContainer = ({ children }) => {
  const classes = useStyles()
  const history = useHistory()

  return (
    <div className={classes.previewContainer}>
      <div className={classes.header}>
        <div className={classes.headerContent}>
          <Button
            onClick={() => history.push(ADMIN_ROUTES.ABUNDANCE_ENGINE_GALLERY)}
            startIcon={
              <Icon
                additionalClasses={classes.icon}
                name={ICON_NAMES.arrowBack}
              />
            }
            className={classes.backButton}
          >
            Back to Gallery
          </Button>
        </div>
      </div>
      <div className={classes.content}>{children}</div>
    </div>
  )
}

WheelPreviewContainer.propTypes = {
  children: PropTypes.node
}

export default WheelPreviewContainer
