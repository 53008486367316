import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import {
  BLOCK_TYPES,
  DAYJS_OPERATIONS,
  DAYJS_UNIT_TYPES,
  ICON_NAMES,
  TRANSFORM_HORIZONTAL,
  TRANSFORM_VERTICAL,
  TEXT_VARIANTS
} from '../constants'

export const childrenSchema = PropTypes.oneOfType([
  PropTypes.arrayOf(PropTypes.node),
  PropTypes.node
])

export const commentObject = {
  body: PropTypes.string,
  commentId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  owner: PropTypes.shape({
    photo: PropTypes.string,
    name: PropTypes.string
  }),
  seen: PropTypes.bool,
  timestamp: PropTypes.string,
  title: PropTypes.string
}

export const commentSchema = PropTypes.shape(commentObject)

export const commentDefaults = {
  body: '',
  noteId: 0,
  owner: {
    photo: '',
    name: ''
  },
  seen: false,
  threadId: 0,
  title: '',
  timestamp: ''
}

export const threadSchema = PropTypes.shape({
  threadId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  title: PropTypes.string,
  comments: PropTypes.arrayOf(commentSchema)
})

export const popOverTransformOrigin = PropTypes.shape({
  horizontal: PropTypes.oneOf(Object.keys(TRANSFORM_HORIZONTAL)),
  vertical: PropTypes.oneOf(Object.keys(TRANSFORM_VERTICAL))
})

export const fileRejections = PropTypes.arrayOf(
  PropTypes.shape({
    code: PropTypes.string,
    message: PropTypes.string
  })
)

export const menuOptions = PropTypes.arrayOf(
  PropTypes.shape({
    iconName: PropTypes.oneOf(Object.values(ICON_NAMES)),
    label: PropTypes.string,
    onClick: PropTypes.func,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    disabled: PropTypes.bool
  })
)

export const tagOptions = PropTypes.arrayOf(
  PropTypes.shape({
    id: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.bool
  })
)

export const activeDatePeriod = PropTypes.shape({
  periodStart: PropTypes.string,
  periodEnd: PropTypes.string
})

const treeNode = PropTypes.shape({
  id: PropTypes.string,
  type: PropTypes.string
})

export const familyTreeNode = PropTypes.shape({
  id: PropTypes.string,
  parents: PropTypes.arrayOf(treeNode),
  siblings: PropTypes.arrayOf(treeNode),
  spouses: PropTypes.arrayOf(treeNode),
  children: PropTypes.arrayOf(treeNode)
})

export const noteReactions = PropTypes.arrayOf(
  PropTypes.shape({
    reactionId: PropTypes.number,
    reaction: PropTypes.string,
    createdBy: PropTypes.string,
    createdByName: PropTypes.string
  })
)

export const selectPropTypes = PropTypes.shape({
  selectedValue: PropTypes.string,
  onChange: PropTypes.func,
  closestValueFinder: PropTypes.func,
  options: PropTypes.array
})

const portfolioTableSectionProps = PropTypes.shape({
  hidden: PropTypes.bool,
  allowDownloadTable: PropTypes.bool,
  allocationTableProps: PropTypes.shape({
    multiHeaderRows: PropTypes.bool,
    balanceInformationDateFilters: PropTypes.array,
    columns: PropTypes.arrayOf(PropTypes.any)
  })
})

export const portfolioViewPropTypes = {
  showFilterTag: PropTypes.bool,
  accountCategoryIds: PropTypes.arrayOf(PropTypes.number),
  onAvailableDatesChangeStrategyKey: PropTypes.string,
  portfolioTableSectionProps: portfolioTableSectionProps,
  performanceTableSectionProps: portfolioTableSectionProps,
  portfolioAllocationPieChartProps: PropTypes.shape({
    primaryToggleButtonLabel: PropTypes.string,
    secondaryToggleButtonLabel: PropTypes.string,
    chartProps: PropTypes.shape({
      pie: PropTypes.bool,
      orderByOrdinal: PropTypes.bool,
      dataOrder: PropTypes.string
    })
  }),
  historicalSectionProps: PropTypes.shape({
    hidden: PropTypes.bool,
    title: PropTypes.string,
    allowDownloadTable: PropTypes.bool,
    showOnlyPercentages: PropTypes.bool,
    balanceInformationDateFilters: PropTypes.array
  }),
  holdingsSectionProps: PropTypes.shape({
    hidden: PropTypes.bool,
    fetchParams: PropTypes.object,
    allowDownloadTable: PropTypes.bool
  }),
  balanceInformationSectionProps: PropTypes.shape({
    titleFormat: PropTypes.string,
    showInvestableAssetsLabel: PropTypes.bool,
    disableOnClickBalanceFormatToggle: PropTypes.bool,
    onAvailableDatesChangeStrategyKey: PropTypes.string
  }),
  topHoldingsSectionProps: PropTypes.shape({
    hidden: PropTypes.bool,
    title: PropTypes.string,
    rightElementCustomFont: PropTypes.string,
    moveTopHoldings: PropTypes.bool,
    endingValueDateRange: PropTypes.object
  }),
  lineChartContainerProps: PropTypes.shape({
    identifiersMapper: PropTypes.string,
    chartProps: PropTypes.shape({
      axisLeft: PropTypes.shape({
        format: PropTypes.string,
        tickValues: PropTypes.number
      }),
      lineData: PropTypes.string,
      enableArea: PropTypes.bool,
      yFormat: PropTypes.string,
      curve: PropTypes.string
    })
  }),
  componentsOfChangeSectionProps: PropTypes.shape({
    hidden: PropTypes.bool,
    defaultExpanded: PropTypes.bool
  }),
  tableViewColumnsFormat: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string
  ]),
  showDashesWhenNotHeldEntirePeriod: PropTypes.bool,
  showExport: PropTypes.bool
}

export const selectItemType = PropTypes.shape({
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  rawValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  format: PropTypes.bool,
  isDisabled: PropTypes.bool,
  unitValue: PropTypes.number,
  unitType: PropTypes.oneOf(Object.values(DAYJS_UNIT_TYPES)),
  operation: PropTypes.oneOf(Object.values(DAYJS_OPERATIONS)),
  suffix: PropTypes.string
})

export const selectProps = {
  loading: PropTypes.bool,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool,
  className: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.any,
    selectItemType
  ])),
  onChange: PropTypes.func,
  selectedValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.any,
    selectItemType
  ]),
  textVariant: PropTypes.oneOf(Object.values(TEXT_VARIANTS)),
  closestValueFinder: PropTypes.func,
  displayCheckMark: PropTypes.bool,
  displayEmpty: PropTypes.bool,
  placeholder: PropTypes.string,
  inputClassName: PropTypes.string,
  iconFontSize: PropTypes.string,
  onBlur: PropTypes.func
}

export const selectDefaults = {
  loading: false,
  defaultValue: undefined,
  disabled: false,
  className: '',
  options: [],
  onChange: noop,
  selectedValue: undefined,
  textVariant: TEXT_VARIANTS.body1,
  closestValueFinder: undefined,
  displayCheckMark: false,
  displayEmpty: false,
  placeholder: '',
  onBlur: undefined
}

export const dateSelectProps = {
  initialValue: PropTypes.string,
  options: selectProps.options,
  onChange: PropTypes.func,
  selectedValue: selectProps.selectedValue,
  textVariant: PropTypes.oneOf(Object.values(TEXT_VARIANTS))
}

export const dateSelectDefaults = {
  initialValue: undefined,
  options: [],
  onChange: noop,
  selectedValue: undefined,
  textVariant: TEXT_VARIANTS.body1
}

export const tagProps = {
  // Category group id
  groupId: PropTypes.number,
  // Category name
  groupName: PropTypes.string,
  // Tag unique id
  categoryId: PropTypes.number,
  // Label to be display
  name: PropTypes.string
}

export const accountDetailsProps = {
  levelId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  levelType: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  blockColor: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  endingValue: PropTypes.number,
  blockType: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ordinal: PropTypes.number,
  categoryName: PropTypes.string
}

export const visualBalanceDataTypeShape = {
  levelId: PropTypes.number.isRequired,
  levelType: PropTypes.number.isRequired,
  blockColor: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  categoryName: PropTypes.string,
  blockType: PropTypes.oneOf(Object.values(BLOCK_TYPES)).isRequired,
  ordinal: PropTypes.number,
  AccountCategoryGroupId: PropTypes.number
}
