import React from 'react'
import PropTypes from 'prop-types'
import { Typography } from '@material-ui/core'
import { useFormattingContext } from '../../../../FormattingProvider/FormattingContext'

function DateField ({ field }) {
  const { formatter } = useFormattingContext()

  return (
    <Typography variant='h2'>{formatter(field.value, field?.data?.format || 'date')}</Typography>
  )
}

DateField.propTypes = {
  field: PropTypes.shape({
    value: PropTypes.any,
    data: PropTypes.shape({
      format: PropTypes.string
    })
  })
}

export default DateField
