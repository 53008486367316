import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import truncate from 'lodash/truncate'
dayjs.extend(utc)

export const mapFormDefaultValues = (asset) => {
  return {
    longName: asset.longName
  }
}

export const levelDatesQuery = { levelType: 'user' }

const MAX_CHARACTERS = 30
const CHARACTER_SEPARATOR = ' '

const accountNumberAccesor = (row) =>
  row.accountNumber || row.displayNumber || row.longNumber || row.shortNumber

export const defaultColumnConfig = {
  columns: [
    { Header: 'Account Number', accessor: accountNumberAccesor, id: 'longNumber', disableSortBy: true },
    { Header: 'Account Name', accessor: (row) => truncate(row.accountName || row.displayName || row.longName || row.accountLongName, { length: MAX_CHARACTERS, separator: CHARACTER_SEPARATOR }), id: 'displayName', disableSortBy: true }
  ],
  defaultSort: [
    { id: 'longName', desc: false }
  ]
}
