import React from 'react'
import PropTypes from 'prop-types'
import { useClientTeamMembers, useCurrentClient } from '../../../../api/clients'
import { useTeamMemberSchema } from '../../../../api/groups'

export const useClientTeam = () => {
  const { data: client, isLoading: clientLoading } = useCurrentClient()
  const { data, isLoading } = useClientTeamMembers(client?.clientId, { enabled: client && !clientLoading })
  const { data: schema, isLoading: schemaLoading } = useTeamMemberSchema()

  return {
    data,
    schema,
    isLoading: isLoading || schemaLoading
  }
}

const ClientTeamLoader = ({ children }) => {
  const { isLoading, data } = useClientTeam()

  if (isLoading || !data) return null

  return (
    <>
      {children}
    </>
  )
}

ClientTeamLoader.propTypes = {
  children: PropTypes.node.isRequired
}

export default ClientTeamLoader
