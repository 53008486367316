import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  asOfDate: {
    fontWeight: 400,
    fontSize: '.9rem',
    '& > *': {
      marginRight: '5px'
    }
  }
}))

const AsOfDate = ({ date }) => {
  const classes = useStyles()
  const formattedDate = useMemo(() => date ? dayjs.utc(date).format('MMM D, YYYY') : null, [date])

  if (!formattedDate) return null

  return (
    <div className={classes.asOfDate}>
      <span>As of</span>
      <span>{formattedDate}</span>
    </div>
  )
}

AsOfDate.propTypes = {
  date: PropTypes.string
}

export default AsOfDate
