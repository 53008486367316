import {
  CircularProgress,
  InputAdornment,
  TextField,
  makeStyles
} from '@material-ui/core'
import React, { forwardRef, useCallback, useMemo, useState } from 'react'
import SearchIcon from '@material-ui/icons/Search'
import CloseIcon from '@material-ui/icons/Close'
import { isEmpty, noop } from 'lodash'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { DEFAULT_DEBOUNCE_DELAY_IN_MILLISECONDS } from '../../../constants'
import { useDebouncedCallback } from '../../../hooks'

const useStyles = makeStyles((theme) => ({
  inputRoot: {
    width: '100%',
    color: 'inherit',
    paddingRight: '0px !important'
  },
  searchInput: {
    width: '100%',
    opacity: '0.8',
    borderRadius: '40px',
    background: '#F5F6F8',
    marginBottom: '0 !important',
    border: '1px solid #EEF1F3',
    padding: '0.25rem 0.5rem'
  },
  searchInputCloseIcon: {
    cursor: 'pointer'
  },
  input: {
    width: '100%',
    color: '#141929',
    fontWeight: '600',
    fontSize: '0.75rem',
    padding: theme.spacing(1, 1, 1, 0),
    transition: theme.transitions.create('width')
  },
  outlined: {
    background: '#FFFFFF',
    border: '2px solid #D7DCE1',
    borderRadius: '4px'
  },
  errorOutlined: {
    border: '2px solid red'
  }
}))

const SearchBarInput = (
  {
    value,
    name,
    error,
    setValue,
    loading,
    onFocus,
    onBlur,
    onKeyDown,
    placeholder,
    searchInputClassName,
    onClear = noop,
    onChange = noop,
    variant = 'standard',
    debounceDelay = DEFAULT_DEBOUNCE_DELAY_IN_MILLISECONDS
  },
  ref
) => {
  const [query, setQuery] = useState(value || '')
  const classes = useStyles()

  const onChangeDebounced = useDebouncedCallback(
    onChange,
    debounceDelay
  )

  const renderSearchInputEndAdornment = useMemo(() => {
    const isQueryEmpty = isEmpty((query || value)?.trim())
    if (!isQueryEmpty) {
      return (
        <InputAdornment position='end'>
          {loading ? (
            <CircularProgress size='1em' />
          ) : (
            <CloseIcon
              className={classes.searchInputCloseIcon}
              onClick={() => {
                const querySetter = setValue || setQuery
                querySetter('')
                onClear()
              }}
            />
          )}
        </InputAdornment>
      )
    }
    return null
  }, [query, onClear, value, setValue, loading, classes.searchInputCloseIcon])

  const onSearchTextChange = useCallback(
    (event) => {
      const value = event.target.value
      if (setValue) {
        setValue(value)
      } else {
        setQuery(value)
      }
      if (!value?.trim()) {
        onClear()
      }
      onChangeDebounced(value)
    },
    [onChangeDebounced, onClear, setValue]
  )

  const inputProps = useMemo(() => {
    return {
      classes: {
        root: classes.inputRoot,
        input: classes.input
      },
      disableUnderline: true,
      disabled: false,
      startAdornment: (
        <InputAdornment position='start'>
          <SearchIcon />
        </InputAdornment>
      ),
      endAdornment: renderSearchInputEndAdornment
    }
  }, [classes.input, classes.inputRoot, renderSearchInputEndAdornment])

  const classNames = useMemo(() => {
    const isOutlined = variant === 'outlined'
    return clsx(classes.searchInput, {
      [searchInputClassName]: !!searchInputClassName,
      [classes.outlined]: isOutlined,
      [classes.errorOutlined]: error && isOutlined
    })
  }, [
    error,
    variant,
    searchInputClassName,
    classes.searchInput,
    classes.outlined,
    classes.errorOutlined
  ])

  return (
    <TextField
      ref={ref}
      name={name}
      value={setValue ? value : query}
      onFocus={onFocus}
      onBlur={onBlur}
      onKeyDown={onKeyDown}
      InputProps={inputProps}
      placeholder={placeholder}
      onChange={onSearchTextChange}
      className={classNames}
    />
  )
}

export const searchBarInputProps = {
  name: PropTypes.string,
  error: PropTypes.bool,
  onClear: PropTypes.func,
  value: PropTypes.string,
  setValue: PropTypes.func,
  variant: PropTypes.oneOf(['standards', 'outlined']),
  placeholder: PropTypes.string,
  loading: PropTypes.bool,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onKeyDown: PropTypes.func,
  searchInputClassName: PropTypes.string,
  debounceDelay: PropTypes.number
}

SearchBarInput.propTypes = searchBarInputProps

SearchBarInput.defaultProps = {
  variant: 'standard',
  onClear: noop,
  onChange: noop,
  debounceDelay: DEFAULT_DEBOUNCE_DELAY_IN_MILLISECONDS
}

export default forwardRef(SearchBarInput)
