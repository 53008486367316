import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Icon from '../../atoms/Icon'
import PanelTrigger from './PanelTrigger'

const useStyles = makeStyles((theme) => ({
  commentButton: {
    '&:hover': {
      cursor: 'pointer'
    }
  }
}))

function CommentButton ({ item, parent }) {
  const classes = useStyles()

  return (
    <PanelTrigger
      className={classes.commentButton}
      item={item}
      parent={parent}
      tab='comments'
    >
      <Icon name='chat' />
    </PanelTrigger>
  )
}

CommentButton.propTypes = {
  item: PropTypes.any,
  parent: PropTypes.any
}

export default React.memo(CommentButton)
