import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useGroupingContext } from '../GroupingProvider/GroupingContext'
import { useWidgetContext } from '../../molecules/WidgetWrapper'
import PresentationTable from './PerformancePresentationTable'
import usePerformanceColumns from './usePerformanceColumns'
import usePerformanceTreeData from './usePerformanceTreeData'
import { getSelectedGroupingConfig } from './helpers'

const PerformanceTableV2 = ({
  defaultFilter: _defaultFilter,
  benchmarks,
  allocationAccessor,
  balanceDateRanges,
  performanceDateRanges,
  beginningValueDateRanges,
  rglDateRanges,
  groupings: _groupings,
  columnConfig: _columnConfig,
  title,
  initialDepth,
  initialExpandDepth,
  sortable,
  includes,
  autoSticky,
  scope,
  singleLevel,
  showExpandIcon,
  showColorIcon,
  depthStyles,
  rowFilters,
  variant,
  ratings,
  nitpicks
}) => {
  const {
    setPerformanceTableProps,
    groupsExpanded,
    selectedGrouping,
    groupingDateRanges,
    groupingDateRangeOptions,
    columnConfigurations: groupingColumnConfigurations
  } = useGroupingContext()

  const { subscribedFilters } = useWidgetContext()

  const defaultFilter = useMemo(() => {
    return Object.assign({}, _defaultFilter, subscribedFilters?.levelFilters)
  }, [_defaultFilter, subscribedFilters?.levelFilters])

  const { groupings, columnConfig, defaultPerformanceDateRanges } =
    useMemo(() => {
      return getSelectedGroupingConfig(
        selectedGrouping,
        _groupings,
        _columnConfig,
        groupingDateRanges,
        performanceDateRanges
      )
    }, [
      selectedGrouping,
      _groupings,
      _columnConfig,
      groupingDateRanges,
      performanceDateRanges
    ])

  const {
    data,
    isLoading,
    onLevelExpand,
    setPerformanceDateRanges,
    performanceDateRangeOptions,
    normalizedDateRanges
  } = usePerformanceTreeData({
    includes,
    groupings,
    benchmarks,
    defaultFilter,
    initialDepth,
    initialExpandDepth,
    balanceDateRanges,
    allocationAccessor,
    defaultPerformanceDateRanges,
    expanded: groupsExpanded,
    dateRangeOptions: groupingDateRangeOptions,
    performanceDateRanges: defaultPerformanceDateRanges,
    beginningValueDateRanges,
    rglDateRanges,
    scope,
    rowFilters,
    ratings
  })

  const { columns, defaultSort } = usePerformanceColumns({
    columnConfig,
    title,
    groupingDateRanges,
    setPerformanceDateRanges,
    performanceDateRangeOptions,
    normalizedDateRanges,
    groupingColumnConfigurations
  })

  const tableRef = useCallback(
    (node) => {
      if (node !== null && setPerformanceTableProps) {
        setPerformanceTableProps((prevState) => ({ ...prevState, ...node }))
      }
    },
    [setPerformanceTableProps]
  )

  return (
    <PresentationTable.Wrapper>
      <PresentationTable
        styleVariant={variant}
        ref={tableRef}
        data={data}
        columns={columns}
        loading={isLoading}
        defaultSort={defaultSort}
        expandable
        onRowClick={onLevelExpand}
        sortable={sortable}
        autoSticky={autoSticky}
        singleLevel={singleLevel}
        showExpandIcon={showExpandIcon}
        showColorIcon={showColorIcon}
        depthStyles={depthStyles}
        nitpicks={nitpicks}
      />
    </PresentationTable.Wrapper>
  )
}

PerformanceTableV2.propTypes = {
  defaultFilter: PropTypes.object,
  groupings: PropTypes.arrayOf(PropTypes.string).isRequired,
  benchmarks: PropTypes.arrayOf(PropTypes.string),
  allocationAccessor: PropTypes.string,
  balanceDateRanges: PropTypes.array,
  performanceDateRanges: PropTypes.arrayOf(PropTypes.string),
  beginningValueDateRanges: PropTypes.arrayOf(PropTypes.string),
  rglDateRanges: PropTypes.arrayOf(PropTypes.string),
  columnConfig: PropTypes.object,
  title: PropTypes.string,
  initialDepth: PropTypes.number,
  initialExpandDepth: PropTypes.number,
  sortable: PropTypes.bool,
  includes: PropTypes.arrayOf(PropTypes.string),
  autoSticky: PropTypes.bool,
  scope: PropTypes.string,
  singleLevel: PropTypes.bool,
  showExpandIcon: PropTypes.bool,
  showColorIcon: PropTypes.bool,
  depthStyles: PropTypes.object,
  rowFilters: PropTypes.any,
  variant: PropTypes.oneOf(['default', 'flat', 'legacy', 'glass', 'presentationTableRC']),
  ratings: PropTypes.arrayOf(PropTypes.string),
  nitpicks: PropTypes.shape({
    noDefaultTooltip: PropTypes.bool,
    glassFactor: PropTypes.number
  })
}

PerformanceTableV2.defaultProps = {
  title: 'Performance',
  benchmarks: [],
  balanceDateRanges: ['balance'],
  beginningValueDateRanges: [],
  rglDateRanges: [],
  displayOptions: {},
  sortable: false,
  includes: [],
  autoSticky: true,
  scope: 'client',
  showExpandIcon: true,
  showColorIcon: false,
  rowFilters: undefined,
  variant: 'default',
  nitpicks: {}
}

export default PerformanceTableV2
