import React from 'react'
import { makeStyles } from '@material-ui/core'
import ReportRecentTable from './ReportRecentTable/Table'
import ReportRecentProvider from './ReportRecentProvider'
import ReportRecentTableContainer from './ReportRecentTable/TableContainer'

const useStyles = makeStyles(() => ({
  container: {
    paddingTop: '0.5rem'
  }
}))

const ReportRecentView = () => {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <ReportRecentProvider>
        <ReportRecentTableContainer>
          <ReportRecentTable showSavedRequests={false} />
        </ReportRecentTableContainer>
      </ReportRecentProvider>
    </div>
  )
}

export default ReportRecentView
