import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@material-ui/core'
import WealthJourneyMeetingList from '../../WealthJourneyMeetingList'
import entryType, { entryTypeIdentifier, entryTypePropType } from './entryType'

const WealthJourneyMeetingContent = ({
  selectedEntryType,
  entries,
  entriesLoading,
  selectedEntry,
  onEntrySelected,
  onEditEntry,
  onAddEntry,
  onDeleteEntry
}) => {
  const filteredEntries = useMemo(() => {
    return (entries ?? []).filter(x => x.entryTypeId === entryTypeIdentifier.MEETINGS)
  }, [entries])

  if (selectedEntryType !== entryType.MEETINGS) return null

  return (
    <Grid container>
      <WealthJourneyMeetingList
        entries={filteredEntries}
        selectedEntry={selectedEntry}
        onEntrySelected={onEntrySelected}
        onEditEntry={onEditEntry}
        onAddEntry={onAddEntry}
        onDeleteEntry={onDeleteEntry}
        entriesLoading={entriesLoading}
      />
    </Grid>
  )
}

WealthJourneyMeetingContent.propTypes = {
  selectedEntryType: entryTypePropType.isRequired,
  entries: PropTypes.array,
  entriesLoading: PropTypes.bool,
  selectedEntry: PropTypes.object,
  onEntrySelected: PropTypes.func.isRequired,
  onAddEntry: PropTypes.func.isRequired,
  onEditEntry: PropTypes.func.isRequired,
  onDeleteEntry: PropTypes.func.isRequired
}

WealthJourneyMeetingContent.defaultProps = {
  entriesLoading: false,
  selectedEntry: null
}

export default React.memo(WealthJourneyMeetingContent)
