import React from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import {
  Dialog as MuiDialog,
  Slide,
  IconButton,
  makeStyles
} from '@material-ui/core'

import { ICON_NAMES } from '../../../constants'

import Icon from '../../atoms/Icon'
import Header from './Header'
import Body from './Body'
import Actions from './Actions'

const useStyles = makeStyles((theme) => ({
  dialog: {
    textAlign: 'center',
    position: 'relative'
  },
  paper: {
    borderRadius: ({ variant }) => {
      if (variant === 'sharp') return '0'
      return undefined
    },
    outline: `1.5px solid ${theme.palette.primary.main}`,
    padding: '0 26px 28px',
    backgroundColor: theme.palette.background.default
  },
  close: {
    position: 'absolute',
    top: '10px',
    right: '10px'
  }
}))

const Transition = React.forwardRef(function Transition (props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})

const Dialog = ({
  open,
  onClose,
  children,
  size,
  fullWidth,
  className,
  variant
}) => {
  const classes = useStyles({ variant })

  return (
    <MuiDialog
      className={className}
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
      maxWidth={size}
      fullWidth={fullWidth}
      classes={{
        root: classes.dialog,
        paper: classes.paper
      }}
    >
      <IconButton
        onClick={onClose}
        color='inherit'
        classes={{
          root: classes.close
        }}
      >
        <Icon name={ICON_NAMES.close} customSize='1.5rem' />
      </IconButton>
      {children}
    </MuiDialog>
  )
}

Dialog.propTypes = {
  children: PropTypes.node,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  size: PropTypes.string,
  fullWidth: PropTypes.bool,
  className: PropTypes.string,
  variant: PropTypes.oneOf(['normal', 'sharp'])
}

Dialog.defaultProps = {
  open: false,
  onClose: noop,
  size: 'sm',
  fullWidth: true,
  variant: 'normal'
}

Dialog.Header = Header
Dialog.Body = Body
Dialog.Actions = Actions

export default Dialog
