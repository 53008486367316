import React from 'react'
import { makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'

const useStyles = makeStyles((theme) => ({
  description: {
    fontWeight: theme.typography.weights.light,
    fontStyle: 'italic'
  }
}))

function Description ({ children }) {
  const classes = useStyles()

  return (
    <div className={classes.description}>{children}</div>
  )
}

Description.propTypes = {
  children: PropTypes.node
}

export default Description
