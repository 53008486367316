import React, { useCallback, useImperativeHandle, useState } from 'react'
import RoundedModal from '../../../../../../molecules/RoundedModal'
import AddPositionOverrideForm from './form'

const AddPositionOverrideDialog = React.forwardRef(function AddPositionOverrideDialog (_, ref) {
  const [dialogState, setDialogState] = useState({ open: false, processing: false })
  const close = useCallback(() => {
    setDialogState({ open: false, processing: false })
  }, [setDialogState])

  useImperativeHandle(ref, () => ({
    open: (client) => setDialogState({ open: true, processing: false, client })
  }), [setDialogState])

  return (
    <RoundedModal
      title='Add Position Override'
      open={dialogState.open}
      onClose={close}
      position='top'
      size='large'
      disablePortal={false}
    >
      <div>
        {dialogState.open ? (<AddPositionOverrideForm client={dialogState.client} onComplete={close} onCancel={close} />) : null}
      </div>
    </RoundedModal>
  )
})

AddPositionOverrideDialog.propTypes = {
}

export default AddPositionOverrideDialog
