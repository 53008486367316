import React, { useCallback, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { Grid } from '@material-ui/core'
import FadeIn from '../../../../molecules/Transitions/FadeIn'
import SectionScreen from '../../shared/SectionScreen'
import SectionHeader from '../../shared/SectionHeader'
import EditButton from '../../shared/EditButton'
import PersonalSpace from '../../shared/PersonalSpace'
import SydLabel from '../../../../commonDesign/SydLabel'
import SydInput from '../../../../commonDesign/SydInput'
import SaveCancelFooter from '../../shared/SaveCancelFooter'
import { useModifyManagerMutation } from '../../../../../api/accounts'
import { useManagerContext, useSectionEditing } from '../ManagerProvider'
import AssetDisplay from './AssetDisplay'

const useSubmitter = (form, onComplete) => {
  const { handleSubmit } = form
  const [processing, setProcessing] = useState(false)
  const { mutateAsync: modifyManager } = useModifyManagerMutation()
  const onSubmit = useCallback(async (formData) => {
    const command = {
      manager: {
        managerId: formData.managerId,
        name: formData.name
      }
    }

    try {
      setProcessing(true)
      const result = await modifyManager(command)
      onComplete(result)
    } finally {
      setProcessing(false)
    }
  }, [modifyManager, setProcessing, onComplete])

  const submitter = useCallback(async (e) => {
    const onValid = async (form) => {
      await onSubmit(form)
    }
    const onInvalid = (errors) => {
      console.error(errors)
    }

    const handler = handleSubmit(onValid, onInvalid)
    await handler(e)
  }, [handleSubmit, onSubmit])

  return {
    submitter,
    processing
  }
}

function GeneralTab () {
  // const classes = useStyles()
  const { editing, editSection, manager, cancelEdit } = useManagerContext()
  const sectionIsEditing = useSectionEditing('general')
  const form = useForm({
    mode: 'onChange',
    defaultValues: {
      managerId: manager.managerId,
      name: manager.name,
      symbol: manager.symbol
    }
  })
  const { submitter, processing } = useSubmitter(form, cancelEdit)

  return (
    <>
      <FadeIn>
        <SectionScreen editing={editing} sectionIsEditing={sectionIsEditing}>
          <SectionHeader text='Manager Information'>
            <div>
              <EditButton
                policy='admin_edit_managers'
                editing={editing}
                onClick={() => editSection({
                  section: 'general'
                })}
              />
            </div>
          </SectionHeader>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Controller
                name='name'
                control={form.control}
                render={(f) => (
                  <SydLabel label='Name' required>
                    <SydInput
                      disabled={!sectionIsEditing}
                      {...f.field}
                    />
                  </SydLabel>
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name='symbol'
                control={form.control}
                render={(f) => (
                  <SydLabel label='Symbol'>
                    <SydInput
                      disabled
                      {...f.field}
                    />
                  </SydLabel>
                )}
              />
            </Grid>
          </Grid>
        </SectionScreen>
        <AssetDisplay assetId={manager.assetId} />
        <PersonalSpace />
      </FadeIn>
      {sectionIsEditing ? (
        <SaveCancelFooter
          saveText='Save Details'
          onCancel={cancelEdit}
          onSave={submitter}
          processing={processing}
          disabled={!form.formState.isValid}
        />
      ) : null}
    </>
  )
}

GeneralTab.propTypes = {
}

export default GeneralTab
