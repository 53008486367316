import React from 'react'
import PropTypes from 'prop-types'
import { animated, useSpring } from 'react-spring'
import { makeStyles } from '@material-ui/core'
import AdvisorViewContainer from './Container'
import AdvisorViewItem from './Item'

const useStyles = makeStyles((theme) => ({
  dashboard: {
    background: '#f8f8f8',
    width: '100vw',
    height: '100%',
    padding: '2rem 2rem 2rem 1rem',
    '& .__item': {
      padding: '.5rem'
    }
  }
}))

const AdvisorViewDashboardLayout = ({ children }) => {
  const classes = useStyles()
  const springs = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 }
  })

  return (
    <animated.div className={classes.dashboard} style={springs}>
      {children}
    </animated.div>
  )
}

AdvisorViewDashboardLayout.Container = AdvisorViewContainer
AdvisorViewDashboardLayout.Item = AdvisorViewItem

AdvisorViewDashboardLayout.propTypes = {
  children: PropTypes.node
}

export default AdvisorViewDashboardLayout
