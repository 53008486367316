import { useEffect } from 'react'
import { useAdvisorViewTabGroup } from '../../AdvisorView/components/AdvisorViewTabs/advisorViewTabGroupContext'
import { useTasksContext } from '../TasksContext'

const TasksTabContent = ({ children }) => {
  const { selectedValue } = useAdvisorViewTabGroup()
  const { setSelectedTab } = useTasksContext()

  useEffect(() => {
    setSelectedTab(selectedValue)
  }, [selectedValue, setSelectedTab])

  return children
}

export default TasksTabContent
