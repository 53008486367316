import React, { useMemo } from 'react'
import { makeStyles } from '@material-ui/core'
import ViewTabs from '../../../organisms/AdvisorView/components/AdvisorViewTabs/index'
import RolesWealthOwnerView from '../Roles/RolesWealthOwnerView'
import { useCheckPolicy } from '../../../../hooks'
import { ROLES } from '../../../../policies/admin'
import ClientListTab from './ClientListTab'
import HeaderActions from './HeaderActions'
import UsersListTab from './UsersListTab'

const tabOptions = {
  clients: {
    label: 'Clients',
    value: 'clients'
  },
  users: {
    label: 'Users',
    value: 'users'
  },
  experiences: {
    label: 'Experiences',
    key: 'experiences'
  }
}

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '1rem'
  },
  roundedButton: {
    border: `2px solid ${theme.palette.primary.main}`,
    borderRadius: 25,
    padding: '0.45rem 1rem',
    textTransform: 'none',
    fontWeight: 'bold',
    fontSize: '0.875rem',
    alignSelf: 'flex-start',
    marginLeft: 'auto',
    '& .MuiButton-label': {
      fontSize: '0.75rem'
    }
  },
  actions: {
    marginLeft: 'auto',
    display: 'flex',
    gap: '0.5rem'
  }
}))

function ClientsView () {
  const classes = useStyles()

  const canViewWealthOwnerRoles = useCheckPolicy(ROLES.viewWealthOwnerRoles)
  const displayTabOptions = useMemo(() => {
    return Object.keys(tabOptions).reduce((acc, key) => {
      if (canViewWealthOwnerRoles || key !== 'experiences') {
        acc[key] = tabOptions[key]
      }
      return acc
    }, {})
  }, [canViewWealthOwnerRoles])

  return (
    <div className={classes.container}>
      <ViewTabs.Group tabOptions={displayTabOptions}>
        <ViewTabs.Links
          tabsKey='admin_clients_list'
          searchParamKey='tab'
          rightAdornment={<HeaderActions />}
        />
        <ViewTabs.Tab value='clients'>
          <ClientListTab />
        </ViewTabs.Tab>
        <ViewTabs.Tab value='users'>
          <UsersListTab />
        </ViewTabs.Tab>
        <ViewTabs.Tab value='experiences'>
          <RolesWealthOwnerView />
        </ViewTabs.Tab>
      </ViewTabs.Group>
    </div>
  )
}

ClientsView.propTypes = {
}

ClientsView.defaultProps = {
}

export default ClientsView
