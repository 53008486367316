import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Tooltip } from '@material-ui/core'
import { replaceDoubleBraces } from './helpers'

const BaseCell = ({ column, value, row }) => {
  const tooltipTitle = useMemo(() => {
    const { cellTooltipTitle } = column
    if (!cellTooltipTitle) {
      return null
    }
    if (cellTooltipTitle.includes('{{') && cellTooltipTitle.includes('}}')) {
      return replaceDoubleBraces(column?.cellTooltipTitle, row?.original)
    }
    return cellTooltipTitle
  }, [column, row?.original])

  if (tooltipTitle) {
    return (
      <Tooltip title={tooltipTitle}>
        <span>{value}</span>
      </Tooltip>
    )
  }

  return value || ''
}

BaseCell.propTypes = {
  value: PropTypes.string,
  row: PropTypes.object,
  column: PropTypes.shape({
    header: PropTypes.string,
    cellTooltipTitle: PropTypes.string
  })
}

export default BaseCell
