import React, { useCallback, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import { useAppContext } from '../../../../redux/slices/appContext'
import { useGainLossSearch } from '../../../../api/coreData'
import PresentationTable from '../../../organisms/PresentationTable'
import { useFormattingContext } from '../../../organisms/FormattingProvider/FormattingContext'

import { useColumns } from './columnConfig'

const groupings = {
  realized: ['account', 'asset', 'taxLot'],
  unrealized: ['account', 'asset', 'taxLot'],
  combined: ['account', 'asset']
}

/**
 * Gets the next level type in a list of groupings
 * @param filter
 * @param levelType
 * @return {null|string}
 */
const getNextLevelType = (filter, levelType) => {
  if (!(filter in groupings)) {
    throw new Error(`Could not find grouping ${filter}`)
  }
  const grouping = groupings[filter]
  const levelIndex = grouping.findIndex(x => x === levelType)
  if (levelIndex < 0) {
    throw new Error(`Could not find levelType ${levelType} in grouping ${filter}`)
  }

  if (levelIndex >= grouping.length) return null
  return grouping[levelIndex + 1]
}

const useGainLoss = ({
  dateRange,
  enabled,
  costBasisType,
  defaultFilter,
  levelFilters,
  taxStatusId
}) => {
  const { clientId } = useAppContext()
  const query = useMemo(() => enabled ? {
    levelFilters,
    filters: {
      ...(defaultFilter || {}),
      clientId,
      taxStatusId
    },
    dateRange,
    levelType: 'account',
    costBasisType,
    skip: 0,
    take: 100
  } : null, [dateRange, clientId, costBasisType, enabled, defaultFilter, levelFilters, taxStatusId])

  // eslint-disable-next-line no-unused-vars
  const { data, isLoading, error, fetchMore } = useGainLossSearch(query, {
    enabled
  })

  const mappedData = useMemo(() => {
    return (error || isLoading) ? [] : data.map(d => {
      const nextLevelType = getNextLevelType(costBasisType, 'account')
      return ({
        ...d,
        expanded: false,
        _parents: [],
        _next: {
          ...query,
          filters: {
            ...query.filters,
            accountId: d.levelId
          },
          levelType: nextLevelType
        }
      })
    })
  }, [data, isLoading, error, costBasisType, query])

  // eslint-disable-next-line no-unused-vars
  const [result, setResult] = useState([])
  useEffect(() => {
    setResult(mappedData)
  }, [mappedData, setResult])

  const getNextLevel = useCallback(async row => {
    const nextQuery = row.original?._next
    if (!nextQuery) return
    if (row.original._subRowsFetched) {
      setResult(p => {
        const target = row.original._parents.reduce((p, c) => {
          return p.find(x => x.levelId === c)?.subRows
        }, p)
        const parentRow = target.find(x => x.levelId === row.original.levelId)
        parentRow.expanded = !parentRow.expanded
        return [...p]
      })
      return
    }

    setResult(p => {
      const target = row.original._parents.reduce((p, c) => {
        return p.find(x => x.levelId === c)?.subRows
      }, p)
      const parentRow = target.find(x => x.levelId === row.original.levelId)
      parentRow._subRowsFetching = true
      return [...p]
    })

    const subDetails = await fetchMore(nextQuery)
    const parentId = row.original.levelId
    const parentLevelType = row.original.levelType
    const mappedDetails = (subDetails || []).map(sd => {
      const currentLevelType = getNextLevelType(costBasisType, parentLevelType)
      const childLevelType = getNextLevelType(costBasisType, currentLevelType)
      return {
        ...sd,
        _parents: [...row.original._parents, parentId],
        _next: childLevelType ? {
          ...nextQuery,
          filters: {
            ...nextQuery.filters,
            [`${currentLevelType}Id`]: sd.levelId
          },
          levelType: childLevelType
        } : null
      }
    })
    setResult(p => {
      const target = row.original._parents.reduce((p, c) => {
        return p.find(x => x.levelId === c)?.subRows
      }, p)
      const parentRow = target.find(x => x.levelId === row.original.levelId)
      parentRow.expanded = !parentRow.expanded
      parentRow.subRows = mappedDetails || []
      parentRow._subRowsFetched = true
      parentRow._subRowsFetching = false
      return [...p]
    })
  }, [setResult, costBasisType, fetchMore])

  return {
    data: result ?? [],
    isLoading,
    error,
    getNextLevel
  }
}

const useStyles = makeStyles(() => ({
  table: {
    '& .__sticky': {
      overflow: 'initial',
      '& .__thead': {
        marginBottom: '0 !important'
      }
    }
  }
}))

const CostBasisTable = ({ costBasisType, levelFilters, dateRange, enabled, defaultFilter, format, taxStatusId }) => {
  const classes = useStyles()
  const { formatter } = useFormattingContext()
  const { columns, defaultSort } = useColumns({
    costBasisType,
    format,
    formatter
  })
  const { data, isLoading, getNextLevel } = useGainLoss({
    dateRange,
    enabled,
    costBasisType,
    defaultFilter,
    levelFilters,
    taxStatusId
  })

  return (
    <PresentationTable
      className={classes.table}
      columns={columns}
      data={data}
      expandable
      loading={isLoading}
      onRowClick={getNextLevel}
      defaultSort={defaultSort}
      sortable
    />
  )
}

CostBasisTable.propTypes = {
  costBasisType: PropTypes.oneOf(['realized', 'unrealized', 'combined']),

  /** Tells us if the table should fetch data or not */
  enabled: PropTypes.bool,
  dateRange: PropTypes.object,
  defaultFilter: PropTypes.object,
  levelFilters: PropTypes.object,
  format: PropTypes.object,
  taxStatusId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
}

CostBasisTable.defaultProps = {
  defaultFilter: {}
}

CostBasisTable.Wrapper = PresentationTable.Wrapper
CostBasisTable.SuperHeader = PresentationTable.SuperHeader

export default CostBasisTable
