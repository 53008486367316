import { useMemo } from 'react'
import { compareNumericString } from '../../../../../utils'

const sharedAttrs = { alignRight: true, width: 50 }

export const defaultColumnConfig = {
  columns: [
    { Header: 'Name', accessor: 'levelName' },
    { id: 'marketValue', Header: 'Act $', accessor: 'allocationValue', ...sharedAttrs, format: 'marketValue', sortType: compareNumericString },
    { id: 'marketAllocation', Header: 'Act %', accessor: 'allocation', ...sharedAttrs, format: 'percentage', sortType: compareNumericString },
    { id: 'targetValue', Header: 'Tgt $', accessor: 'rebalancingValue', ...sharedAttrs, format: 'marketValue', sortType: compareNumericString },
    { id: 'targetAllocation', Header: 'Tgt %', accessor: 'rebalancingTarget', ...sharedAttrs, format: 'percentage', sortType: compareNumericString },
    { id: 'diffValue', Header: 'Diff $', accessor: 'rebalancingValueDiff', ...sharedAttrs, format: 'marketValue', sortType: compareNumericString },
    { id: 'diffAllocation', Header: 'Diff %', accessor: 'rebalancingAllocDiff', ...sharedAttrs, format: 'percentage', sortType: compareNumericString }
  ],
  groupedColumns: [
    { Header: 'Name', accessor: 'levelName' },
    {
      id: 'actuals',
      Header: 'Actual',
      alignCenter: true,
      columns: [
        { id: 'marketValue', Header: 'Value', accessor: 'allocationValue', ...sharedAttrs, format: 'marketValue', sortType: compareNumericString },
        { id: 'marketAllocation', Header: '%', accessor: 'allocation', ...sharedAttrs, format: 'percentage', sortType: compareNumericString }
      ]
    },
    {
      id: 'targets',
      Header: 'Target',
      alignCenter: true,
      columns: [
        { id: 'targetValue', Header: 'Value', accessor: 'rebalancingValue', ...sharedAttrs, format: 'marketValue', sortType: compareNumericString },
        { id: 'targetAllocation', Header: '%', accessor: 'rebalancingTarget', ...sharedAttrs, format: 'percentage', sortType: compareNumericString }
      ]
    },
    {
      id: 'diffs',
      Header: 'Difference',
      alignCenter: true,
      columns: [
        { id: 'diffValue', Header: 'Value', accessor: 'rebalancingValueDiff', ...sharedAttrs, format: 'marketValue', sortType: compareNumericString },
        { id: 'diffAllocation', Header: '%', accessor: 'rebalancingAllocDiff', ...sharedAttrs, format: 'percentage', sortType: compareNumericString }
      ]
    }
  ],
  defaultSort: [{ id: 'levelName', desc: false }]
}

export const useColumns = ({
  formatter,
  columnConfig = defaultColumnConfig
}) => {
  return useMemo(() => {
    const { groupedColumns: columns, defaultSort } = columnConfig
    const mapColumns = columns => {
      if (!columns) return undefined
      return columns.map(column => {
        const formatString = column.format

        return {
          ...column,
          Cell: ({ value }) => formatter(value, formatString),
          columns: mapColumns(column.columns)
        }
      })
    }
    const templatedColumns = mapColumns(columns)

    return {
      columns: templatedColumns,
      defaultSort
    }
  }, [columnConfig, formatter])
}
