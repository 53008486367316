import React, { createElement } from 'react'
import { useComments } from './CommentContext'

function Title () {
  const { title, titleProps } = useComments()

  if (typeof title === 'string') {
    return (
      <header className='__title'>
        {title}
      </header>
    )
  }

  return createElement(title, titleProps)
}

export default Title
