import React from 'react'
import { useAppContext } from '../../../redux/slices/appContext'
import { useLatestClientStatusReport } from '../../../api/groups'
import StatusReportSummary from './StatusReportSummary'

function ClientStatusReportSummary (props) {
  const { clientId } = useAppContext()
  const { data, isLoading } = useLatestClientStatusReport(clientId)

  return (
    <StatusReportSummary
      {...props}
      report={data?.report}
      statuses={data?.statuses}
      loading={isLoading}
    />
  )
}

export default ClientStatusReportSummary
