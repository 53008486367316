import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { Controller } from 'react-hook-form'
import dayjs from 'dayjs'
import SydInput from '../../../../commonDesign/SydInput'
import Select from '../../../../molecules/Select'
import Switch from '../../../../molecules/Switch'
import SydLabel, { hookFormFieldErrorAdapter } from '../../../../commonDesign/SydLabel'
import SydDatePicker from '../../../../commonDesign/SydDatePicker'
import ControlGroup from './ControlGroup'

const validators = {
  date: value => {
    if (value === '' || value === null) return true
    if (!dayjs(value).isValid()) {
      return 'Invalid Date'
    }
    return true
  },
  datetime: value => {
    if (value === '' || value === null) return true
    if (!dayjs(value).isValid()) {
      return 'Invalid Date'
    }
    return true
  },
  json: value => {
    if (value === '' || value === null) return true
    try {
      JSON.parse(value)
      return true
    } catch (err) {
      return 'Invalid JSON'
    }
  },
  number: value => {
    if (value === '' || value === null) return true
    const v = Number(value)
    if (isNaN(v)) {
      return 'Not a number'
    }
    return true
  },
  integer: value => {
    if (value === '' || value === null) return true
    const v = Number.parseInt(value)
    if (isNaN(v)) {
      return 'Not a number'
    }
    if (v !== Number(value)) {
      return 'Not a whole number'
    }
    return true
  }
}

function KdpInput ({
  name,
  control,
  datapoint,
  disabled
}) {
  const reset = useCallback((f, value = null) => () => {
    f.field.onChange({
      target: { value }
    })
  }, [])

  if (datapoint?.configurationJson?.type === 'date') {
    return (
      <Controller
        name={name} control={control} rules={{ validate: validators.date }}
        render={(f) => (
          <SydLabel description={datapoint?.configurationJson?.description} error={hookFormFieldErrorAdapter(f.fieldState)}>
            <ControlGroup disabled={disabled} onClick={reset(f)}>
              <SydDatePicker
                size='sm'
                type='date'
                disabled={disabled}
                dateFormat='YYYY-MM-DD'
                {...f.field}
              />
            </ControlGroup>
          </SydLabel>
        )}
      />
    )
  }
  if (datapoint?.configurationJson?.type === 'datetime') {
    return (
      <Controller
        name={name} control={control} rules={{ validate: validators.datetime }}
        render={(f) => (
          <SydLabel description={datapoint?.configurationJson?.description} error={hookFormFieldErrorAdapter(f.fieldState)}>
            <ControlGroup disabled={disabled} onClick={reset(f)}>
              <SydDatePicker
                size='sm'
                type='datetime'
                disabled={disabled}
                dateFormat='YYYY-MM-DD HH:mm:ss'
                {...f.field}
              />
            </ControlGroup>
          </SydLabel>
        )}
      />
    )
  }
  if (datapoint?.configurationJson?.type === 'bool') {
    return (
      <Controller
        name={name} control={control} render={(f) => (
          <Switch disabled={disabled} {...f.field} checked={f.field.value} />
        )}
      />
    )
  }
  if (datapoint?.configurationJson?.type === 'list') {
    return (
      <Controller
        name={name} control={control} render={(f) => (
          <SydLabel description={datapoint?.configurationJson?.description} error={hookFormFieldErrorAdapter(f.fieldState)}>
            <ControlGroup disabled={disabled} onClick={reset(f)}>
              <Select fullWidth variant='outlined-rounded' disabled={disabled} options={datapoint.configurationJson.options} {...f.field} />
            </ControlGroup>
          </SydLabel>
        )}
      />
    )
  }
  if (datapoint?.configurationJson?.type === 'string') {
    return (
      <Controller
        name={name} control={control} render={(f) => (
          <SydLabel description={datapoint?.configurationJson?.description} error={hookFormFieldErrorAdapter(f.fieldState)}>
            <ControlGroup disabled={disabled} onClick={reset(f, '')}>
              <SydInput size='sm' disabled={disabled} {...f.field} />
            </ControlGroup>
          </SydLabel>
        )}
      />
    )
  }
  if (datapoint?.configurationJson?.type === 'text') {
    return (
      <Controller
        name={name} control={control} render={(f) => (
          <SydLabel description={datapoint?.configurationJson?.description} error={hookFormFieldErrorAdapter(f.fieldState)}>
            <ControlGroup disabled={disabled} onClick={reset(f, '')}>
              <textarea style={{ width: '100%' }} rows={5} disabled={disabled} {...f.field} />
            </ControlGroup>
          </SydLabel>
        )}
      />
    )
  }
  if (datapoint?.configurationJson?.type === 'json') {
    return (
      <Controller
        name={name} control={control} rules={{ validate: validators.json }}
        render={(f) => (
          <SydLabel description={datapoint?.configurationJson?.description} error={hookFormFieldErrorAdapter(f.fieldState)}>
            <ControlGroup disabled={disabled} onClick={reset(f, '')}>
              <textarea style={{ width: '100%' }} rows={5} disabled={disabled} {...f.field} />
            </ControlGroup>
          </SydLabel>
        )}
      />
    )
  }
  if (datapoint?.configurationJson?.type === 'number') {
    return (
      <Controller
        name={name} control={control} rules={{ validate: validators.number }}
        render={(f) => (
          <SydLabel description={datapoint?.configurationJson?.description} error={hookFormFieldErrorAdapter(f.fieldState)}>
            <ControlGroup disabled={disabled} onClick={reset(f, '')}>
              <SydInput size='sm' disabled={disabled} {...f.field} />
            </ControlGroup>
          </SydLabel>
        )}
      />
    )
  }
  if (datapoint?.configurationJson?.type === 'fixed') {
    return (
      <Controller
        name={name} control={control} rules={{ validate: validators.number }}
        render={(f) => (
          <SydLabel description={datapoint?.configurationJson?.description} error={hookFormFieldErrorAdapter(f.fieldState)}>
            <ControlGroup disabled={disabled} onClick={reset(f, '')}>
              <SydInput size='sm' disabled={disabled} {...f.field} />
            </ControlGroup>
          </SydLabel>
        )}
      />
    )
  }
  if (datapoint?.configurationJson?.type === 'integer') {
    return (
      <Controller
        name={name} control={control} rules={{ validate: validators.integer }}
        render={(f) => (
          <SydLabel description={datapoint?.configurationJson?.description} error={hookFormFieldErrorAdapter(f.fieldState)}>
            <ControlGroup disabled={disabled} onClick={reset(f, '')}>
              <SydInput type='number' size='sm' disabled={disabled} {...f.field} />
            </ControlGroup>
          </SydLabel>
        )}
      />
    )
  }
  return (
    <Controller
      name={name} control={control} render={(f) => (
        <SydLabel description={datapoint?.configurationJson?.description} error={hookFormFieldErrorAdapter(f.fieldState)}>
          <ControlGroup disabled={disabled} onClick={reset(f, '')}>
            <SydInput size='sm' disabled={disabled} {...f.field} />
          </ControlGroup>
        </SydLabel>
      )}
    />
  )
}

KdpInput.propTypes = {
  name: PropTypes.string,
  control: PropTypes.any,
  datapoint: PropTypes.shape({
    configurationJson: PropTypes.shape({
      type: PropTypes.oneOf(['string', 'text', 'number', 'fixed', 'integer', 'json', 'date', 'datetime', 'list', 'bool']),
      options: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.any
      })),
      length: PropTypes.number,
      description: PropTypes.string
    })
  }),
  disabled: PropTypes.bool
}

export default KdpInput
