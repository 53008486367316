import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import Avatar from '../../../atoms/Avatar'
import Text from '../../../atoms/Text'
import Skeleton from '../../../atoms/Skeleton'
import { SKELETON_VARIANTS } from '../../../../constants'
import ClientCardIntegrationButtons from '../../ClientCardsViewV2/ClientCardView/ClientCard/ClientCardIntegrationButtons'
import { useAppContext } from '../../../../redux/slices/appContext'

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: '1rem',
    alignItems: 'center',
    gap: '0.625rem',
    '& .__title': {
      fontSize: '1.25rem',
      color: '#141929'
    },
    '& .__caption': {
      fontSize: '0.875rem'
    },
    '& .__footer': {
      '& button': {
        position: 'relative !important'
      }
    }
  }
}))

const ClientTeamMember = ({
  clientId,
  fullName,
  jobTitle,
  profilePic,
  isLoading,
  integrations,
  showIntegrations,
  /** This should be temporary - we should resolve these in the service */
  absoluteAvatarUrls
}) => {
  const classes = useStyles()
  const { isAdvisor } = useAppContext()

  const {
    showSalesforceClientInfo,
    showWealthBoxClientInfo,
    showRedTailClientInfo,
    isCrmAuthorized
  } = integrations || {}

  const showIntegration = isAdvisor && isCrmAuthorized && showIntegrations

  if (isLoading) {
    return (
      <div className={classes.container}>
        <Skeleton
          height='5rem'
          width='5rem'
          variant={SKELETON_VARIANTS.circle}
        />
        <Skeleton height='2rem' variant={SKELETON_VARIANTS.text} />
        <Skeleton height='1rem' variant={SKELETON_VARIANTS.text} />
      </div>
    )
  }

  return (
    <div className={classes.container}>
      <Avatar
        src={profilePic}
        avatarLetters={fullName}
        customSize='5rem'
        isPublicResource={absoluteAvatarUrls}
      />
      <Text className='__title'>{fullName}</Text>
      <Text className='__caption'>{jobTitle}</Text>
      <div className='__footer'>
        {showIntegration && (
          <ClientCardIntegrationButtons
            clientId={clientId}
            showSalesforceClientInfo={showSalesforceClientInfo}
            showWealthBoxClientInfo={showWealthBoxClientInfo}
            showRedTailClientInfo={showRedTailClientInfo}
          />
        )}
      </div>
    </div>
  )
}

ClientTeamMember.propTypes = {
  clientId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fullName: PropTypes.string,
  jobTitle: PropTypes.string,
  profilePic: PropTypes.string,
  isLoading: PropTypes.bool,
  integrations: PropTypes.object,
  showIntegrations: PropTypes.bool,
  absoluteAvatarUrls: PropTypes.bool
}

ClientTeamMember.propTypes = {
  showIntegrations: false,
  absoluteAvatarUrls: false
}

export default ClientTeamMember
