import React, { useCallback } from 'react'
import { Checkbox, FormControl, ListItemText, MenuItem, OutlinedInput, Select } from '@material-ui/core'
import PropTypes from 'prop-types'

const MultiSelect = ({
  className,
  value,
  onChange,
  options,
  optionKeySelector,
  optionValueSelector,
  optionDisplaySelector,
  placeholder,
  renderValue,
  hasError,
  disabled,
  shouldDisableOption
}) => {
  const _onChange = useCallback(({ target }) => {
    onChange(target.value)
  }, [onChange])

  const _renderValue = useCallback((selected) => {
    if (selected.filter(v => !!v).length === 0) {
      return placeholder
    }

    return renderValue(selected)
  }, [renderValue, placeholder])

  return (
    <FormControl fullWidth>
      <Select
        className={className}
        fullWidth
        multiple
        displayEmpty
        disabled={disabled}
        value={value}
        onChange={_onChange}
        renderValue={_renderValue}
        hasError={hasError}
        input={<OutlinedInput />}
      >
        <MenuItem value='' disabled>
          {placeholder}
        </MenuItem>
        {options.map((opt) => (
          <MenuItem
            disabled={shouldDisableOption(value, opt)}
            key={optionKeySelector(opt)}
            value={optionValueSelector(opt)}
          >
            <Checkbox
              checked={value.indexOf(optionValueSelector(opt)) > -1}
              disabled={shouldDisableOption(value, opt)}
            />
            <ListItemText primary={optionDisplaySelector(opt)} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

MultiSelect.propTypes = {
  className: PropTypes.string,
  value: PropTypes.array,
  disabled: PropTypes.bool,
  options: PropTypes.array.isRequired,
  optionKeySelector: PropTypes.func,
  optionValueSelector: PropTypes.func,
  optionDisplaySelector: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  renderValue: PropTypes.func,
  shouldDisableOption: PropTypes.func,
  hasError: PropTypes.bool
}

const identity = v => v

MultiSelect.defaultProps = {
  values: [],
  options: [],
  disabled: false,
  optionKeySelector: identity,
  optionValueSelector: identity,
  optionDisplaySelector: identity,
  placeholder: 'Please select an option',
  renderValue: identity,
  shouldDisableOption: (_) => false,
  hasError: false
}

export default MultiSelect
