import React, { createContext, useContext } from 'react'
import PropTypes from 'prop-types'
import { useBoolean } from '../../../../../../hooks'

const ReportRecentContext = createContext({})

const ReportRecentProvider = ({ children }) => {
  const [isLoading, toggleIsLoading] = useBoolean()
  return (
    <ReportRecentContext.Provider value={{ isLoading, toggleIsLoading }}>
      {children}
    </ReportRecentContext.Provider>
  )
}

export const useReportRecentContext = () => {
  return useContext(ReportRecentContext)
}

ReportRecentProvider.propTypes = {
  children: PropTypes.node
}

export default ReportRecentProvider
