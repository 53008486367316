import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import OperationalTable from '../../../../organisms/OperationalTable'
import GroupingProvider from '../../../../organisms/GroupingProvider'
import { useUsersListContext } from './UserListContext'
import { getFilterColumnId } from './helpers'

const UsersTableWrapper = ({ children }) => {
  const { onChangeFilters, setVisibleFilters, configurationKey } =
    useUsersListContext()

  const onSaveTableConfiguration = useCallback(
    ({ columns }) => {
      const filterColPrefix = getFilterColumnId()
      const visibleColFilters = columns.filter((col) =>
        col.startsWith(filterColPrefix)
      )
      setVisibleFilters(visibleColFilters)

      onChangeFilters(({
        ...prevState
      }) => {
        return {
          ...prevState
        }
      })
    },
    [onChangeFilters, setVisibleFilters]
  )

  return (
    <GroupingProvider
      configurationKey={configurationKey}
      onApplyClick={onSaveTableConfiguration}
    >
      <OperationalTable.Wrapper>{children}</OperationalTable.Wrapper>
    </GroupingProvider>
  )
}

UsersTableWrapper.propTypes = {
  children: PropTypes.node.isRequired
}

export default UsersTableWrapper
