import React, { useState, useCallback } from 'react'

import PropTypes from 'prop-types'

import Select from '../atoms/Select'

const CustomSelect = ({
  defaultValue,
  onChange,
  className,
  inputClassName,
  options = [],
  iconFontSize
}) => {
  const [selected, setSelected] = useState(defaultValue)

  const handleOnChange = useCallback((evt) => {
    const selectedValue = evt.target.value
    setSelected(selectedValue)
    onChange(selectedValue)
  }, [onChange])

  return (
    <div style={{ width: '100%' }}>
      <Select
        defaultValue={defaultValue}
        options={options}
        onChange={handleOnChange}
        selectedValue={selected}
        className={className}
        inputClassName={inputClassName}
        iconFontSize={iconFontSize}
      />
    </div>
  )
}

CustomSelect.propTypes = {
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  inputClassName: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  iconFontSize: PropTypes.string
}

export default CustomSelect
