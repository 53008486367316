import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import Tooltip from '../../../atoms/Tooltip'
import Icon from '../../../atoms/Icon'
import FastAvatar from '../../../molecules/FastAvatar'
import { DATE_TYPE_ICONS } from './utils'

dayjs.extend(utc)

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '0.75rem 1rem',
    gap: '1.5rem',
    borderRadius: '0.75rem 1rem',
    color: '#212945',
    '&:hover': {
      background: '#F5F6F7',
      borderRadius: '0.25rem'
    },
    '& .__content': {
      width: '70%'
    },
    '& .__caption': {
      color: '#666',
      width: 'fit-content',
      fontSize: '0.875rem'
    },
    '& .__title': {
      fontSize: '1rem',
      fontWeight: 'bold',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis'
    },
    '& .__icon': {
      marginLeft: 'auto'
    }
  }
}))

const KeyDateListItem = ({
  dateNow,
  dateType,
  dateText,
  contactName,
  tooltipTitle,
  avatarUrl,
  contactAbbreviation,
  iconName: _iconName
}) => {
  const classes = useStyles()

  const iconName = useMemo(() => {
    if (_iconName) return _iconName
    const dateTypeNormalized = dateType.toLowerCase().trim()
    return DATE_TYPE_ICONS?.[dateTypeNormalized] ?? DATE_TYPE_ICONS.DEFAULT
  }, [dateType, _iconName])

  return (
    <Tooltip title={tooltipTitle ?? `${contactName} ${dateText}`}>
      <div className={classes.container}>
        <FastAvatar
          size='md'
          avatarUrl={avatarUrl}
          abbreviation={contactAbbreviation}
        />
        <div className='__content'>
          <div className='__title'>{`${contactName} ${dateText}`}</div>
          <div className='__caption'>
            {dayjs.utc(dateNow).format('MMM D, ddd')}
          </div>
        </div>
        <div className='__icon'>
          <Icon name={iconName} customSize='1.25rem' />
        </div>
      </div>
    </Tooltip>
  )
}

KeyDateListItem.propTypes = {
  contactName: PropTypes.string,
  dateNow: PropTypes.string,
  dateText: PropTypes.string,
  dateType: PropTypes.string,
  iconName: PropTypes.string,
  tooltipTitle: PropTypes.string,
  avatarUrl: PropTypes.string,
  contactAbbreviation: PropTypes.string
}

export default KeyDateListItem
