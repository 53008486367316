import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import { ICON_NAMES } from '../../../constants'
import Icon from '../../atoms/Icon'

const useStyles = makeStyles(() => ({
  chartTypeToggle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 0,
    '& > *': {
      padding: '0 .5rem',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center'
    }
  },
  pieOption: {
    cursor: 'pointer',
    opacity: ({ selected }) => selected !== 'pie' ? 0.5 : 1
  },
  barOption: {
    cursor: 'pointer',
    opacity: ({ selected }) => selected !== 'bar' ? 0.5 : 1
  }
}))

const ChartTypeToggle = ({ selected, onClick }) => {
  const classes = useStyles({ selected })
  const {
    onPieClick,
    onBarClick
  } = useMemo(() => {
    return {
      onPieClick: (e) => {
        onClick('pie')
        e.preventDefault()
        e.stopPropagation()
      },
      onBarClick: (e) => {
        onClick('bar')
        e.preventDefault()
        e.stopPropagation()
      }
    }
  }, [onClick])

  return (
    <div className={classes.chartTypeToggle}>
      <span onClick={onPieClick} title='Show Pie Chart'>
        <Icon
          name={ICON_NAMES.pieChart}
          customSize='1.125rem'
          padding='0.875rem'
          additionalClasses={classes.pieOption}
        />
      </span>
      <span onClick={onBarClick} title='Show Bar Chart'>
        <Icon
          name={ICON_NAMES.alignTextLeft}
          customSize='1.125rem'
          padding='0.875rem'
          additionalClasses={classes.barOption}
        />
      </span>
    </div>
  )
}

ChartTypeToggle.propTypes = {
  selected: PropTypes.any,
  onClick: PropTypes.func
}

export default ChartTypeToggle
