import React from 'react'
import PropTypes from 'prop-types'
import { alpha, makeStyles } from '@material-ui/core'
import Icon from '../../atoms/Icon'

const useStyles = makeStyles((theme) => ({
  chip: {
    position: 'relative',
    width: '100%',
    border: `1px solid ${theme.palette.gray.main}`,
    borderRadius: '4px',
    marginBottom: '8px',
    display: 'flex',
    outline: ({ document }) => document.status?.uploaded ? `1px solid ${theme.palette.success.main}` : '1px solid transparent',
    '& .__chip-content': {
      flex: '1 0'
    },
    '& .__chip-button:hover': {
      cursor: 'pointer'
    },
    '& .__chip-progress': {
      zIndex: 0,
      position: 'absolute',
      height: '100%',
      width: ({ document }) => document.status?.progress > 0 ? `${document.status.progress}%` : '0%',
      background: ({ document }) => document.status?.uploaded
        ? theme.palette.success.A50
        : `linear-gradient(to right, ${alpha(theme.palette.lavanda, 0.3)}, ${theme.palette.lavanda})`,
      transition: 'width .3s ease-in, background .5s ease-in-out, outline 1s ease-in-out'
    },
    '& .__chip-wrapper': {
      width: '100%',
      zIndex: 1,
      padding: '5px 10px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      gap: '8px'
    }
  }
}))

function DocumentChip ({ document, onDelete }) {
  const classes = useStyles({ document })
  return (
    <div className={classes.chip}>
      <div className='__chip-progress' />
      <div className='__chip-wrapper'>
        <Icon name='document' />
        <span className='__chip-content'>{document.name}</span>
        <div className='__chip-button' role='button' onClick={onDelete}>
          <Icon name='delete' />
        </div>
      </div>
    </div>
  )
}

DocumentChip.propTypes = {
  document: PropTypes.shape({
    name: PropTypes.string
  }),
  onDelete: PropTypes.func
}

export default DocumentChip
