import React, { useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'
import { useAppContext, useSetAppContext } from '../redux/slices/appContext'
import AbundanceEngineView from './AbundanceEngineView'

const AbundanceEngineRoute = ({ viewPath: _viewPath }) => {
  const { view, subView } = useParams()
  const viewPath = useMemo(() => {
    if (typeof _viewPath !== 'undefined') {
      return _viewPath
    }
    if (typeof subView !== 'undefined') {
      return subView
    }
    if (typeof view !== 'undefined') {
      return view
    }
    return 'not-found'
  }, [_viewPath, view, subView])

  const { isFullViewOpen } = useAppContext()
  const setAppContext = useSetAppContext()
  useEffect(() => {
    setAppContext({ isFullViewOpen: isFullViewOpen })
    return () => setAppContext({ isFullViewOpen: false })
  }, [isFullViewOpen, setAppContext])

  useEffect(() => {
    setAppContext({ overContentOnDesktop: true, isSidebarOpen: false })
    return () => setAppContext({ overContentOnDesktop: true })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewPath])

  return (
    <AbundanceEngineView
      viewPath={viewPath}
      fallbackViewPath={viewPath}
    />
  )
}

AbundanceEngineRoute.propTypes = {
  viewPath: PropTypes.string
}

export default AbundanceEngineRoute
