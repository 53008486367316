import React, { useCallback } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Button } from '@material-ui/core'
import { useOktaAuth } from '@okta/okta-react'
import loadingImage from '../../assets/summit010720_2.png'

const useStyles = makeStyles(() => ({
  restricted: {
    display: 'flex',
    width: '100vw',
    height: '100vh',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  },
  logo: {
    maxWidth: '10rem',
    marginBottom: '2rem'
  },
  logout: {
    marginTop: '2rem'
  }
}))

const RestrictedView = () => {
  const classes = useStyles()
  const { oktaAuth } = useOktaAuth()
  const signOut = useCallback(() => oktaAuth.signOut(), [oktaAuth])

  return (
    <article className={classes.restricted}>
      <header>
        <img className={classes.logo} src={loadingImage} alt='Summit' />
      </header>
      <main>You are not authorized to use this application.</main>
      <footer className={classes.logout}>
        <Button onClick={signOut}>Logout</Button>
      </footer>
    </article>
  )
}

export default RestrictedView
