import React from 'react'
import PropTypes from 'prop-types'
import { Accordion, AccordionDetails, AccordionSummary, makeStyles } from '@material-ui/core'
import Card from '../../../../molecules/Card'
import Icon from '../../../../atoms/Icon'

const useStyles = makeStyles((theme) => ({
  groupCard: {
    padding: theme.layout.margin.medium,

    '& > header': {
      marginBottom: theme.layout.margin.medium,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      paddingBottom: theme.layout.padding.medium,
      borderBottom: `1px solid ${theme.palette.gray.darker}`
    },
    '& .__title': {
      fontWeight: theme.typography.weights.bold,
      fontSize: theme.typography.fontSizes.xxl
    },
    '& .__description': {
      fontWeight: theme.typography.weights.light,
      fontStyle: 'italic',
      fontSize: theme.typography.fontSizes.md
    },
    '& .__actions': {
      display: 'flex',
      flexDirection: 'row',
      gap: theme.layout.gap.medium
    },
    '& .__missing': {
      color: theme.palette.gray.darker
    },
    '& .__groups': {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.layout.gap.dense
    },
    '& .__group': {
      fontWeight: theme.typography.weights.light
    },
    '& .__account-group': {
      backgroundColor: theme.palette.gray.light,
      padding: theme.layout.padding.medium,
      borderRadius: theme.layout.radius.tight,
      border: `1px solid ${theme.palette.gray.dark}`,
      '& .__header-group': {
        fontWeight: theme.typography.weights.bold,
        fontSize: theme.typography.fontSizes.lg,
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        gap: theme.layout.gap.medium
      },
      '& .__account-group-header': {
        marginBottom: theme.layout.margin.medium,
        fontWeight: theme.typography.weights.bold,
        fontSize: theme.typography.fontSizes.lg,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexDirection: 'row',
        gap: theme.layout.gap.medium
      }
    },
    '& .__accounts': {
      marginLeft: theme.layout.margin.thick,
      display: 'flex',
      flexDirection: 'column',
      gap: theme.layout.gap.dense
    },
    '& .__account': {
      fontWeight: theme.typography.weights.light,
      fontSize: theme.typography.fontSizes.md,
      '& td:first-child': {
        width: '120px'
      }
    }
  },
  accordion: {
    width: '100%',
    backgroundColor: 'transparent',
    boxShadow: 'none',
    margin: theme.layout.margin.none
  },
  accordionSummaryContent: {
    margin: theme.layout.margin.none,
    display: 'flex',
    flexDirection: 'row',
    gap: theme.layout.gap.dense,
    '&.Mui-expanded': {
      margin: theme.layout.margin.none,
      minHeight: 0
    }
  },
  accordionSummaryRoot: {
    margin: theme.layout.margin.none,
    minHeight: 0,
    '&.Mui-expanded': {
      margin: theme.layout.margin.none,
      minHeight: 0
    }
  },
  accordionDetails: {
    flexDirection: 'column',
    fontWeight: theme.typography.weights.light
  }
}))

function GroupCard ({ title, description, actions, showActions, children, outstandingAccounts }) {
  const classes = useStyles()
  return (
    <Card className={classes.groupCard}>
      <header>
        <div className='__title-group'>
          <div className='__title'>{title}</div>
          <div className='__description'>{description}</div>
        </div>
        {showActions ? (<div><div className='__actions'>{actions}</div></div>) : null}
      </header>
      {outstandingAccounts?.length ? (
        <header style={{ flexDirection: 'column' }}>
          <Accordion classes={{ root: classes.accordion }}>
            <AccordionSummary classes={{ content: classes.accordionSummaryContent, root: classes.accordionSummaryRoot }}>
              <span><Icon name='warning' /></span>
              <span>There are accounts assigned to this client that are not in this group</span>
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetails}>
              <table>
                <tbody>
                  {outstandingAccounts.map(a => (
                    <tr key={a.accountId}>
                      <td>{a.accountNumber}</td>
                      <td>{a.displayName}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </AccordionDetails>
          </Accordion>
        </header>
      ) : null}
      <div>{children}</div>
    </Card>
  )
}

GroupCard.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  children: PropTypes.node,
  actions: PropTypes.node,
  showActions: PropTypes.bool,
  outstandingAccounts: PropTypes.array
}

export default GroupCard
