import React, { useMemo } from 'react'

import PropTypes from 'prop-types'

import { Button } from '@material-ui/core'

import { jsPDF as JSPdf } from 'jspdf'

import * as htmlToImage from 'html-to-image'

import { useSetAppContext } from '../../redux/slices/appContext'

const MIN_HEIGHT = 1520

export const DownloadPdfButton = ({
  id,
  containerStyles,
  buttonStyles,
  text,
  fileName,
  children
}) => {
  const setAppContext = useSetAppContext()

  const toPdf = (id, fileName) => () => {
    setAppContext({
      isPrintView: true
    })

    const node = document.getElementById(id)
    const childNode = node.firstChild.firstChild

    childNode.style.padding = '30px'

    setTimeout(() => {
      htmlToImage.toCanvas(childNode)
        .then((canvas) => {
          const dataUrl = canvas.toDataURL('image/svg')
          const pdfOptions = {
            orientation: childNode.scrollHeight > MIN_HEIGHT ? 'portrait' : 'landscape',
            unit: 'px',
            format: [childNode.offsetWidth, childNode.scrollHeight],
            compressPdf: true
          }

          childNode.style.padding = '0px'

          const pdf = new JSPdf(pdfOptions)

          const pdfWidth = pdf.internal.pageSize.getWidth()
          const pdfHeight = pdf.internal.pageSize.getHeight()
          pdf.addImage(dataUrl, 'PNG', 0, 0, pdfWidth, pdfHeight)
          pdf.save(fileName)
        })
        .catch(function (error) {
          console.error('Something went wrong generating the pdf', error)
        })
        .finally(() => {
          setAppContext({
            isPrintView: false
          })
        })
    }, 500)
  }

  return (
    <>
      <div id={id} style={{ marginTop: 10, ...containerStyles }}>
        {children}
      </div>
      <div>
        <Button
          type='button'
          color='primary'
          style={{ position: 'absolute', top: '5%', right: '5%', zIndex: 3000, ...buttonStyles }}
          variant='contained'
          onClick={toPdf(id, fileName)}
        >
          {text}
        </Button>
      </div>
    </>
  )
}

DownloadPdfButton.propTypes = {
  id: PropTypes.string.isRequired,
  containerStyles: PropTypes.object,
  buttonStyles: PropTypes.object,
  text: PropTypes.string.isRequired,
  fileName: PropTypes.string,
  children: PropTypes.object
}

export const withDownloadButton = (Component, buttonProps) => (props) => {
  return useMemo(() => {
    return (
      <DownloadPdfButton {...props} {...buttonProps}>
        <Component {...props} />
      </DownloadPdfButton>
    )
  }, [props])
}

export default React.memo(DownloadPdfButton)
