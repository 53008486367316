import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
// import { makeStyles } from '@material-ui/core'
import { Grid } from '@material-ui/core'
import dayjs from 'dayjs'
import { useCustomDataset } from '../../contexts/CustomDatasetContext'
import Avatar from '../atoms/Avatar'

// import { makeStyles } from '@material-ui/core'

// const useStyles = makeStyles((theme) => ({
//   baseTable: {
//     margin: '12px 12px 24px 12px',
//     '& th, td': {
//       textAlign: 'left',
//       margin: '3px 6px',
//       padding: '3px 6px'
//     }
//   }
// }))

const TileList = ({
  columnConfig,
  datasetIndex
}) => {
  // const classes = useStyles()
  const dataset = useCustomDataset()
  const rows = useMemo(() => dataset.isDone ? dataset.value[datasetIndex] : [], [dataset, datasetIndex])

  return (
    <div style={{ display: 'flex', width: '100%', flexWrap: 'wrap' }}>
      {rows && rows.length > 0 && rows.map((r, rowIndex) => {
        let divColor = '#F0F0F899'
        console.log('r.dateType.toLowerCase() === birthday', r.dateType.toLowerCase() === 'birthday')
        console.log('r.dateType.toLowerCase() === weddinganniversary', r.dateType.toLowerCase() === 'weddinganniversary')
        // eslint-disable-next-line no-debugger
        // debugger
        if (r.dateType.toLowerCase().trim() === 'birthday') {
          console.log('r.dateType.toLowerCase()', r.dateType.toLowerCase())
          divColor = '#FAFACC99'
        } else if (r.dateType.toLowerCase().trim() === 'weddinganniversary') {
          divColor = '#33CCFF99'
        }
        return (
          <Grid style={{ width: '180px', height: '240px', display: 'flex', flexDirection: 'column', backgroundColor: divColor, alignItems: 'center', textAlign: 'center', margin: '1rem', padding: '1.5rem 1rem', borderRadius: '6px' }} key={`r_${rowIndex}`}>
            <Avatar
              src={null}
              avatarLetters={r.contactName}
              customSize='54px'
              isPublicResource
            />
            <div style={{ alignSelf: 'center', marginTop: '1rem' }}>
              <div style={{ fontSize: '1.1rem', fontWeight: '400', marginBottom: '0.5rem' }}>{r.contactName}</div>
              <div style={{ fontSize: '1rem', color: '#212945', fontWeight: '600', marginBottom: '0.5rem' }}>{dayjs(r.dateNow).format('MMM D, ddd')}</div>
              <div style={{ fontSize: '0.8rem', color: '#212945' }}>{r.dateText}</div>
            </div>
          </Grid>

        )
      }
      )}
    </div>
  )
}

TileList.propTypes = {
  datasetIndex: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  columnConfig: PropTypes.array
}

TileList.defaultProps = {
  datasetIndex: 0,
  columnConfig: []
}

export default TileList
