import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'

const useStyles = makeStyles({
  container: props => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: props.active
      ? '#212a43'
      : '#e2e3e6',
    borderRadius: '2rem',
    width: '3rem',
    height: '3rem',
    marginBottom: '10px',
    transition: 'background-color 200ms ease-in-out 0.1s'
  })
})

const IconContainer = (props) => {
  const classes = useStyles(props)
  return (
    <div className={`__icon-container ${classes.container}`}>
      {props.children}
    </div>
  )
}
// 33 42 67 - fill - #212a43
// 226 227 230 - light - #e2e3e6
IconContainer.propTypes = {
  children: PropTypes.element.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  active: PropTypes.bool.isRequired
}

export default IconContainer
