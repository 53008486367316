import React, { useCallback, useContext, useMemo, useRef } from 'react'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { useTheme } from '@material-ui/core'
import { usePolicy } from '../../../../hooks/usePolicy'
import { useWealthJourneyEntries, useWealthJourneyEntryTypes } from '../../../../api/wealthJourney'
import { useAppContext } from '../../../../redux/slices/appContext'
import { mapThemingColor } from '../shared/mapThemingColor'
import { useCurrentUser } from '../../../../api/users'
import AddEntryDialog from './dialogs/AddEntryDialog'
import UpdateEntryDialog from './dialogs/UpdateEntryDialog'
import RemoveEntryDialog from './dialogs/RemoveEntryDialog'
import ManageTasksDialog from './dialogs/ManageTasksDialog'
import { parseEntryTypePermissions } from './permissions'

dayjs.extend(utc)

const WealthJourneyContext = React.createContext({})

export const useWealthJourney = () => {
  return useContext(WealthJourneyContext)
}

const useTheming = (theming) => {
  const theme = useTheme()
  return useMemo(() => {
    return {
      milestoneColor: mapThemingColor(theming?.milestoneColor, theme, '#5B91F4'),
      meetingColor: mapThemingColor(theming?.meetingColor, theme, theme.palette.cloudBurst),
      futureMilestoneColor: mapThemingColor(theming?.futureMilestoneColor, theme, '#5B91F4'),
      iconColor: mapThemingColor(theming?.iconColor, theme, 'primary'),
      iconContrastColor: mapThemingColor(theming?.iconContrastColor, theme, 'primaryContrast')
    }
  }, [theming, theme])
}

const usePermissions = () => {
  const { data: identity } = useCurrentUser()
  const canEditMilestones = usePolicy('wj_edit_milestones')

  // we are given a set of permission policies via the backend, we're transforming them here
  return useMemo(() => ({
    identity,
    mapPermissions (entryType) {
      return parseEntryTypePermissions(identity, entryType, canEditMilestones)
    },
    canEditMilestones
  }), [canEditMilestones, identity])
}

const useTabs = tabs => {
  const tabOptions = useMemo(() => {
    return Object.entries(tabs || {}).map(([key, value]) => {
      const normalized = typeof value === 'string' ? {
        name: value
      } : value

      return ({
        key,
        id: key,
        label: normalized.name,
        entryTypeCode: normalized.entryTypeCode,
        entryTypeId: normalized.entryTypeId
      })
    })
  }, [tabs])
  const [selectedTab, setSelectedTab] = React.useState(tabOptions.length ? tabOptions.at(0)?.id : null)

  return { tabOptions, selectedTab, setSelectedTab }
}

const useWealthJourneyManager = ({ theming, tabs, addEntryRef, updateEntryRef, removeEntryRef, manageTasksRef }) => {
  const _theming = useTheming(theming)
  const permissions = usePermissions()

  // tab Options
  const { tabOptions, selectedTab, setSelectedTab } = useTabs(tabs)

  // selection
  const [selectedEntry, setSelectedEntry] = React.useState(null)
  const _setSelectedEntry = useCallback((entry, behavior = 'smooth') => {
    setSelectedEntry(entry)
    try {
      const matchedTabOption = tabOptions.find(x => x.entryTypeId === entry?.entryTypeId || x.entryTypeCode === entry?.entryType?.entryTypeCode)
      if (matchedTabOption) {
        setSelectedTab(matchedTabOption.id)
      }
    } catch (err) {
      console.error(err)
    }
    if (entry) {
      const el = document.querySelector(`.wj_entry[data-entry-id="${entry.entryId}"]`)
      if (el) {
        el.scrollIntoView({ behavior, inline: 'center', block: 'center' })
      }
    }
  }, [setSelectedEntry, tabOptions, setSelectedTab])

  // for selecting the year of milestones
  const [selectedYear, setSelectedYear] = React.useState(dayjs().year())
  const _setSelectedYear = useCallback((year) => {
    setSelectedYear(year)
    if (year) {
      const el = document.querySelector(`.wj_entry[data-year="${year}"]`)
      if (el) {
        el.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' })
      }
    }
  }, [setSelectedYear])

  // for filtering entries
  const [entryTypeFilter, setEntryTypeFilter] = React.useState(new Set())
  const _setEntryTypeFilter = useCallback((entryTypeId, add) => {
    if (add) {
      return setEntryTypeFilter(prev => {
        const r = new Set(prev)
        r.add(entryTypeId)
        return r
      })
    }
    return setEntryTypeFilter(prev => {
      const r = new Set(prev)
      if (prev.has(entryTypeId)) {
        r.delete(entryTypeId)
        return r
      }
      return new Set([entryTypeId])
    })
  }, [setEntryTypeFilter])

  // Get all the available entry types
  const { data: entryTypes, isLoading: entryTypesLoading } = useWealthJourneyEntryTypes()
  const _entryTypes = useMemo(() => {
    return (entryTypes || []).map(et => ({
      ...et,
      permissions: permissions.mapPermissions(et)
    }))
  }, [entryTypes, permissions])

  const { clientId } = useAppContext()
  const entrySearchQuery = useMemo(() => {
    return {
      filters: {
        clientId: [{ op: 'eq', value: clientId }]
      }
    }
  }, [clientId])

  // Get all the available entries - filtered for only those that have entry types, and those entry types that are not filtered out
  const { data: entries, isLoading: entriesLoading } = useWealthJourneyEntries(entrySearchQuery)
  const _entries = useMemo(() => {
    return (entries?.data || []).map(entry => ({
      ...entry,
      year: dayjs.utc(entry.entryDate).year(),
      entryType: (_entryTypes || []).find(et => et.entryTypeId === entry.entryTypeId),
      avatar: entry.entryJson?.images?.length ? entry.entryJson.images.at(0)?.imageUrl : null
    }))
      .filter(entry => _entryTypes.some(et => entry.entryTypeId === et.entryTypeId))
      .filter(entry => entryTypeFilter.size === 0 || entryTypeFilter.has(entry.entryTypeId))
  }, [entries, entryTypeFilter, _entryTypes])

  const _selectedEntry = useMemo(() => {
    return (_entries).find(x => x.entryId === selectedEntry?.entryId)
  }, [_entries, selectedEntry])

  /* eslint-disable object-property-newline */
  return useMemo(() => {
    return {
      permissions,
      entryTypes: _entryTypes,
      selectedEntry: _selectedEntry, setSelectedEntry: _setSelectedEntry,
      selectedYear, setSelectedYear: _setSelectedYear,
      entryTypeFilter, setEntryTypeFilter: _setEntryTypeFilter,
      selectedTab, setSelectedTab, tabOptions,
      entries: _entries, entriesLoading: entriesLoading || entryTypesLoading,
      theming: _theming,
      addEntry: p => addEntryRef.current.open(p),
      updateEntry: entry => updateEntryRef.current.open(entry),
      removeEntry: entry => removeEntryRef.current.open(entry),
      manageTasks: entry => manageTasksRef.current.open(entry)
    }
  }, [
    permissions,
    _selectedEntry, _setSelectedEntry,
    selectedYear, _setSelectedYear,
    selectedTab, setSelectedTab, tabOptions,
    entryTypeFilter, _setEntryTypeFilter,
    _entries, entriesLoading,
    _entryTypes, entryTypesLoading,
    _theming,
    addEntryRef, updateEntryRef, removeEntryRef, manageTasksRef
  ])
  /* eslint-enable object-property-newline */
}

function WealthJourneyProvider ({ children, theming, tabs }) {
  const addEntryRef = useRef(null)
  const updateEntryRef = useRef(null)
  const removeEntryRef = useRef(null)
  const manageTasksRef = useRef(null)
  const contextValue = useWealthJourneyManager({
    theming,
    tabs,
    addEntryRef,
    updateEntryRef,
    removeEntryRef,
    manageTasksRef
  })

  return (
    <WealthJourneyContext.Provider value={contextValue}>
      {children}
      <AddEntryDialog ref={addEntryRef} />
      <UpdateEntryDialog ref={updateEntryRef} />
      <RemoveEntryDialog ref={removeEntryRef} />
      <ManageTasksDialog ref={manageTasksRef} />
    </WealthJourneyContext.Provider>
  )
}

WealthJourneyProvider.propTypes = {
  children: PropTypes.node.isRequired,
  theming: PropTypes.shape({
    milestoneColor: PropTypes.string,
    meetingColor: PropTypes.string,
    futureMilestoneColor: PropTypes.string,
    iconColor: PropTypes.string
  }),
  tabs: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.shape({
      name: PropTypes.string,
      entryTypeId: PropTypes.number,
      entryTypeCode: PropTypes.string
    }),
    PropTypes.string
  ]))
}

export default WealthJourneyProvider
