import React, { useCallback, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, ClickAwayListener, InputAdornment, InputBase, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import noop from 'lodash/noop'
import Skeleton from '../../../atoms/Skeleton'
import { useDynamicPlanning } from '../DynamicPlanningContext'
import { SKELETON_VARIANTS, TEXT_VARIANTS } from '../../../../constants'
import Text from '../../../atoms/Text'

const useStyles = makeStyles(() => ({
  inputTop: {
    fontSize: '1.875rem',
    fontWeight: 700,
    width: '100%',
    padding: 0,
    margin: 0,
    height: '2.2rem'
  },
  disabled: {
    opacity: 0.5,
    pointerEvents: 'none',
    cursor: 'not-allowed'
  },
  assumptionContainer: {
    position: 'relative',
    display: 'flex'
  },
  stepContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: '.15rem',
    justifyContent: 'space-between'
  },
  stepToggle: {
    width: '1.5rem',
    height: '1rem',
    minWidth: 0
  }
}))

const PlanAssumption = ({
  label,
  displayValue,
  editingDisplayValue,
  isPlanAssumption,
  startAdornment,
  endAdornment,
  inputProps,
  displayProps,
  onChangeHandler,
  showStepToggles
}) => {
  const classes = useStyles()
  const { isLoadingData, updatePlan, currentPlanId, currentPlan } = useDynamicPlanning()

  const [isEditing, setIsEditing] = useState(false)
  const [editingValue, setEditingValue] = useState(editingDisplayValue)

  useEffect(() => {
    if (isEditing) {
      setEditingValue(editingDisplayValue)
    }
  }, [isEditing, editingDisplayValue])

  const handleOnCommit = async () => {
    if (editingValue === editingDisplayValue) {
      setIsEditing(false)
      return
    }
    if (isPlanAssumption) {
      const assumptionData = onChangeHandler(editingValue)
      updatePlan(currentPlanId, { assumptions: assumptionData })
    } else {
      onChangeHandler(editingValue)
    }
    setIsEditing(false)
  }

  const styles = useMemo(() => {
    if (!currentPlan?.isNew || isPlanAssumption) {
      return {}
    }
    return {
      pointerEvents: 'none',
      opacity: 0.35
    }
  }, [currentPlan?.isNew, isPlanAssumption])

  const incrementValue = useCallback((incrementValue) => {
    // add increment value to editing value and prevent floating point errors
    const newValue = (parseFloat(editingValue) + parseFloat(incrementValue)).toFixed(1)
    setEditingValue(newValue)
    if (isPlanAssumption) {
      const assumptionData = onChangeHandler(newValue)
      updatePlan(currentPlanId, { assumptions: assumptionData })
    } else {
      onChangeHandler(newValue)
    }
  }, [editingValue, isPlanAssumption, onChangeHandler, currentPlanId, updatePlan])

  if (isLoadingData) {
    return (
      <>
        <Skeleton width='180px' height='1.7rem' />
        <Skeleton width='180px' height='1.5rem' variant={SKELETON_VARIANTS.text} />
      </>
    )
  }

  return (
    <div style={{ minWidth: '120px', ...styles }}>
      {
        isEditing ? (
          <ClickAwayListener onClickAway={handleOnCommit}>
            <div className={classes.assumptionContainer}>
              {showStepToggles && displayProps?.step && (
                <div className={classes.stepContainer}>
                  <Button
                    type='button'
                    size='xs'
                    onClick={() => incrementValue(+displayProps.step)}
                    className={classes.stepToggle}
                  >
                    +
                  </Button>
                  <Button
                    type='button'
                    size='xs'
                    onClick={() => incrementValue(-(+displayProps.step))}
                    className={classes.stepToggle}
                  >
                    -
                  </Button>
                </div>
              )}
              <InputBase
                classes={{ input: classes.inputTop }}
                value={editingValue}
                margin='dense'
                onChange={e => setEditingValue(e.target.value)}
                endAdornment={
                  endAdornment && <InputAdornment position='start'>{endAdornment}</InputAdornment>
                }
                startAdornment={
                  startAdornment && <InputAdornment position='start'>{startAdornment}</InputAdornment>
                }
                inputProps={inputProps}
                autoFocus
                style={{ width: '120px' }}
                onKeyUp={e => e.key === 'Enter' && handleOnCommit()}
              />
            </div>
          </ClickAwayListener>
        ) : (
          <div
            onClick={() => isPlanAssumption ? setIsEditing(true) : noop()}
            className={classes.inputTop}
          >
            <Text
              variant={TEXT_VARIANTS.h3}
              customFontSize='1.875rem'
            >
              {displayValue}
            </Text>
          </div>
        )
      }
      <Typography gutterBottom>
        {label}
      </Typography>
    </div>
  )
}

PlanAssumption.propTypes = {
  label: PropTypes.string,
  displayValue: PropTypes.string,
  editingDisplayValue: PropTypes.string,
  isPlanAssumption: PropTypes.bool,
  startAdornment: PropTypes.string,
  endAdornment: PropTypes.string,
  inputProps: PropTypes.object,
  displayProps: PropTypes.object,
  showStepToggles: PropTypes.bool,
  onChangeHandler: PropTypes.func
}

export default PlanAssumption
