import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import loadingImage from '../../assets/summit010720_2.png'
import Logo from '../atoms/Logo'
import SydLinkButton from '../commonDesign/LinkButton'

const useStyles = makeStyles(() => ({
  restricted: {
    display: 'flex',
    width: '100vw',
    height: '75vh',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  },
  logo: {
    maxWidth: '20rem'
  },
  logout: {
    marginTop: '2rem'
  },
  msg: {
    fontSize: '3.0rem',
    textAlign: 'center',
    margin: '2rem 0 0'
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center'
  }
}))

const NotFoundView = () => {
  const classes = useStyles()
  return (
    <article className={classes.restricted}>
      <header className={classes.header}>
        <Logo className={classes.logo} src={loadingImage} alt='Summit' />
        <h1 className={classes.msg}>Page Not Found</h1>
      </header>
      <main>Sorry, we couldn't find that page</main>
      <footer className={classes.logout}>
        <SydLinkButton variant='primary' to='/'>Go Home</SydLinkButton>
      </footer>
    </article>
  )
}

export default NotFoundView
