import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  fieldValue: {
    display: 'flex',
    flexDirection: 'column',

    '& .__fieldValueLabel': {
      color: theme.palette.gray.darker
    },
    '& .__fieldValueValue': {
      color: theme.palette.summitBlue,
      padding: '.5rem'
    }
  }
}))

const FieldValue = ({ label, children }) => {
  const classes = useStyles()

  return (
    <div className={classes.fieldValue}>
      <label className='__fieldValueLabel'>{label}</label>
      <div className='__fieldValueValue'>{children}</div>
    </div>
  )
}

FieldValue.propTypes = {
  label: PropTypes.string,
  children: PropTypes.any
}

export default FieldValue
