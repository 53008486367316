import React from 'react'
import { Box, makeStyles } from '@material-ui/core'
import DateRangeSelector from './DateRangeSelector'
import MilestonesSearchBar from './MilestonesSearchBar'

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.white
  }
}))

const MilestonesFilters = () => {
  const classes = useStyles()
  return (
    <div className={classes.container}>
      <Box py={2}>
        <DateRangeSelector />
      </Box>
      <MilestonesSearchBar />
    </div>
  )
}

export default MilestonesFilters
