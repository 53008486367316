import React, { useCallback, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import PillButtonGroup from '../../../molecules/PillButtonGroup'
import entryType, { entryTypePropType } from './entryType'

export const useWealthJourneyEntryTypeSelector = ({ includeMeetings, includeMilestones, includeActivities }) => {
  const defaultEntryType = useMemo(() => {
    if (includeMilestones) {
      return entryType.MILESTONES
    }
    if (includeActivities) {
      return entryType.ACTIVITIES
    }
    if (includeMeetings) {
      return entryType.MEETINGS
    }

    return null
  }, [includeMeetings, includeMilestones, includeActivities])
  const [selectedEntryType, setSelectedEntryType] = useState(defaultEntryType)
  const onSelectEntryType = useCallback(entryType => setSelectedEntryType(entryType), [setSelectedEntryType])

  return {
    selectedEntryType,
    onSelectEntryType
  }
}

const WealthJourneyEntryTypeSelector = ({
  onSelect,
  selectedType,
  includeMeetings,
  includeMilestones,
  includeActivities
}) => {
  const options = useMemo(() => {
    const result = []
    if (includeMeetings) {
      result.push({ value: entryType.MEETINGS, text: 'Meetings' })
    }
    if (includeMilestones) {
      result.push({ value: entryType.MILESTONES, text: 'Milestones' })
    }
    if (includeActivities) {
      result.push({ value: entryType.ACTIVITIES, text: 'Actions' })
    }

    return result
  }, [includeMeetings, includeMilestones, includeActivities])

  const shouldHide = useMemo(() => options.length <= 1, [options.length])

  if (shouldHide) {
    return null
  }

  return (
    <div style={{ display: 'flex', margin: '24px 12px 24px 12px' }}>
      <PillButtonGroup defaultValue={entryType.MILESTONES} onSelect={onSelect} selectedValue={selectedType}>
        {options.map(o => (
          <PillButtonGroup.Option key={o.value} value={o.value}>{o.text}</PillButtonGroup.Option>
        ))}
      </PillButtonGroup>
    </div>
  )
}

WealthJourneyEntryTypeSelector.propTypes = {
  onSelect: PropTypes.func.isRequired,
  selectedType: entryTypePropType.isRequired,
  includeMeetings: PropTypes.bool,
  includeMilestones: PropTypes.bool,
  includeActivities: PropTypes.bool
}

export default WealthJourneyEntryTypeSelector
