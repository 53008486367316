import React from 'react'
import PropTypes from 'prop-types'
import { ContextualClientContext } from './ContextualClientContext'

const ContextualClientContextProvider = ({ children, value }) => {
  return (
    <ContextualClientContext.Provider value={value}>
      {children}
    </ContextualClientContext.Provider>
  )
}

ContextualClientContextProvider.propTypes = {
  children: PropTypes.node,
  value: PropTypes.object
}

export default ContextualClientContextProvider
