import React from 'react'
import { makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { BUTTON_SIZES } from '../../../../constants'
import Select from '../../../molecules/Select'

const useStyles = makeStyles(() => ({
  select: {
    borderRadius: '4px',
    border: '2px solid #EEF0F8',
    background: '#FFF',
    padding: '13px 14px 13px 16px',
    width: '10rem',
    '& button': { width: '100% !important' }
  }
}))

const FilterSelect = ({ className, fullWidth, ...props }) => {
  const classes = useStyles()
  return (
    <Select
      fullWidth={fullWidth}
      showCheckMarOnSelectedItems
      size={BUTTON_SIZES.small}
      className={clsx(classes.select, { [className]: !!className })}
      {...props}
    />
  )
}

FilterSelect.propTypes = {
  className: PropTypes.string,
  fullWidth: PropTypes.bool
}

export default FilterSelect
