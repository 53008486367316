import React, { useState, useMemo, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { Box, Grid } from '@material-ui/core'
import get from 'lodash.get'
import { isEmpty } from 'lodash'
import CollapsibleTable from '../molecules/CollapsibleTable'
import NumberFormat from '../atoms/NumberFormat'
import TableSkeleton from '../atoms/TableSkeleton'
import EmptySection from '../atoms/EmptySection'
import Text from '../atoms/Text'

import useAbortController from '../../hooks/useAbortController'
import { useToggle } from '../../hooks'

import { useAppContext } from '../../redux/slices/appContext'
import { fetchHistoricalPerformanceReport } from '../../service'
import {
  removeEmptyProps,
  formatTableRowValue,
  getBalanceInformationDateRanges
} from '../../utils'
import {
  ADVISOR_VIEW_HARD_LIMIT,
  CSV_CELL_TYPES,
  LEVEL_TYPES,
  TABLE_CELL_TYPE
} from '../../constants'

const DATE_RANGES = [
  {
    sourceKey: 'balanceInformation1Year',
    unitValue: 1,
    unitType: 'year',
    operation: 'subtract'
  },
  {
    sourceKey: 'balanceInformation2Year',
    unitValue: 2,
    unitType: 'year',
    operation: 'subtract'
  },
  {
    sourceKey: 'balanceInformation3Year',
    unitValue: 3,
    unitType: 'year',
    operation: 'subtract'
  },
  {
    sourceKey: 'balanceInformation5Year',
    unitValue: 5,
    unitType: 'year',
    operation: 'subtract'
  },
  {
    sourceKey: 'balanceInformation7Year',
    unitValue: 7,
    unitType: 'year',
    operation: 'subtract'
  },
  {
    sourceKey: 'balanceInformationSinceInceptionRange',
    useMin: true,
    useMax: true
  }
]

const AssetReturnCell = ({ tooltip, balanceChangePercentage }) => {
  return (
    <Box
      display='flex'
      flexDirection='row'
      alignItems='center'
      justifyContent='end'
    >
      <Text
        customFontWeight='bold'
        text={
          <NumberFormat
            title={tooltip.title}
            format={tooltip.format}
            number={balanceChangePercentage}
          />
        }
      />
    </Box>
  )
}

const getTableRows = (
  { balanceInformationSinceInceptionRange = [], ...data },
  isFinalRow = false
) => {
  return balanceInformationSinceInceptionRange.map((report, index) => {
    const { accountName, balanceChangePercentage, annualizedReturn } = report
    let tooltipInceptionTitle = formatTableRowValue(
      balanceChangePercentage,
      '0.0000%',
      '0%'
    )
    let initialRow = {
      alignment: 'left',
      value: <Text customFontWeight='bold' text={accountName} />,
      colSpan: 1,
      csvData: {
        type: CSV_CELL_TYPES.string,
        originalValue: accountName
      },
      type: TABLE_CELL_TYPE.COMPONENT
    }

    if (annualizedReturn > 0) {
      tooltipInceptionTitle = `Annualized Return: ${formatTableRowValue(
        annualizedReturn,
        '0.0000%',
        '0%'
      )}`
    }

    if (isFinalRow) {
      initialRow = { value: 'Total', variant: 'subtitle2', alignment: 'left', csvData: { type: CSV_CELL_TYPES.string } }
    }

    const rows = DATE_RANGES.reduce((acc, { sourceKey }) => {
      const { balanceChangePercentage, annualizedReturn } =
        get(data, sourceKey, [])?.[index] || {}
      if (!balanceChangePercentage) return acc
      let tooltipTitle = formatTableRowValue(
        balanceChangePercentage,
        '0.0000%',
        '0%'
      )

      if (annualizedReturn > 0) {
        tooltipTitle = `Annualized Return: ${formatTableRowValue(
          annualizedReturn,
          '0.0000%',
          '0%'
        )}`
      }

      return [
        ...acc,
        {
          colSpan: 1,
          alignment: 'right',
          type: TABLE_CELL_TYPE.COMPONENT,
          value: (
            <AssetReturnCell
              tooltip={{
                title: tooltipTitle,
                format: '0.0000%'
              }}
              balanceChangePercentage={balanceChangePercentage}
            />
          ),
          csvData: {
            type: CSV_CELL_TYPES.percentage,
            originalValue: balanceChangePercentage
          }
        }
      ]
    }, [])

    return [
      initialRow,
      ...rows,
      {
        colSpan: 1,
        alignment: 'right',
        type: TABLE_CELL_TYPE.COMPONENT,
        value: (
          <AssetReturnCell
            tooltip={{
              title: tooltipInceptionTitle,
              format: '0.0000%'
            }}
            balanceChangePercentage={balanceChangePercentage}
          />
        ),
        csvData: {
          type: CSV_CELL_TYPES.percentage,
          originalValue: balanceChangePercentage
        }
      }
    ]
  })
}

const columns = [
  { name: '', colSpan: 1 },
  { name: '1 YEAR', colSpan: 1 },
  { name: '2 YEAR', colSpan: 1 },
  { name: '3 YEAR', colSpan: 1 },
  { name: '5 YEAR', colSpan: 1 },
  { name: '7 YEAR', colSpan: 1 },
  { name: 'INCEPTION', colSpan: 1 }
]

const performanceReportRequest = (payload, signal) => {
  return fetchHistoricalPerformanceReport(removeEmptyProps(payload), {
    signal
  })
}

const performanceReports = async (payload, signal) => {
  const clientAccountLevels = {
    levelTypes: [LEVEL_TYPES.CLIENT, LEVEL_TYPES.ACCOUNTS]
  }
  const clientLevel = {
    levelTypes: [LEVEL_TYPES.CLIENT]
  }

  const promiseBalanceInformation = performanceReportRequest(
    {
      ...payload,
      ...clientAccountLevels
    },
    signal
  )

  const promiseFinalBalanceInformation = performanceReportRequest(
    {
      ...payload,
      ...clientLevel
    },
    signal
  )

  try {
    const [balanceInformation, finalBalanceInformation] = await Promise.all([
      promiseBalanceInformation,
      promiseFinalBalanceInformation
    ])

    const reportRows = getTableRows(balanceInformation.data)
    const reportFinalRow = getTableRows(finalBalanceInformation.data, true)

    return {
      reportRows,
      reportFinalRow
    }
  } catch (error) {
    console.error(error)
  }
}

const HistoricalPerformanceTable = ({ allowDownload, accountCategoryIds }) => {
  const [loading, , toggleLoadingOn, toggleLoadingOff] = useToggle(true)
  const { availableDates, clientId } = useAppContext()
  const [rows, setRows] = useState([])
  const [finalRow, setFinalRow] = useState([])
  const [isHistoricalData, setIsHistoricalData] = useState(false)
  const abortController = useAbortController()

  const { balanceInformationDateRanges } = useMemo(
    () => getBalanceInformationDateRanges(availableDates, DATE_RANGES),
    [availableDates]
  )

  const fetchReport = useCallback(async () => {
    toggleLoadingOn()
    const signal = abortController()

    try {
      const payload = {
        historyType: ['1'],
        limit: ADVISOR_VIEW_HARD_LIMIT,
        clientIds: [clientId],
        dateRanges: balanceInformationDateRanges,
        ...(!isEmpty(accountCategoryIds) ? { accountCategoryIds } : {})
      }

      let reports = await performanceReports(payload, signal)

      if (!reports?.reportRows?.length) {
        payload.historyType = '0'
        reports = await performanceReports(payload, signal)
      } else {
        setIsHistoricalData(true)
      }
      const { reportFinalRow, reportRows } = reports

      setFinalRow(reportFinalRow[0])
      setRows(reportRows)
    } catch (error) {
      console.error(error)
    } finally {
      toggleLoadingOff()
    }
  }, [
    toggleLoadingOn,
    toggleLoadingOff,
    abortController,
    clientId,
    accountCategoryIds,
    balanceInformationDateRanges
  ])

  useEffect(() => {
    if (loading) {
      fetchReport()
    }
  }, [fetchReport, loading, clientId])

  useEffect(() => {
    toggleLoadingOn()
  }, [clientId, toggleLoadingOn])

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {loading ? (
          <Box width='100%'>
            <TableSkeleton columns={7} rows={4} showHeader={false} />
          </Box>
        ) : !loading && rows.length ? (
          <>
            <CollapsibleTable
              rows={rows}
              columns={columns}
              finalRow={finalRow}
              allowDownload={allowDownload}
            />
            {isHistoricalData && (
              <Text
                text='* Includes historical data'
                customFontSize='0.75rem'
              />
            )}
          </>
        ) : (
          <EmptySection
            title='There is not data to display here yet.'
            description=''
          />
        )}
      </Grid>
    </Grid>
  )
}

HistoricalPerformanceTable.propTypes = {
  allowDownload: PropTypes.bool,
  accountCategoryIds: PropTypes.arrayOf(PropTypes.number)
}

HistoricalPerformanceTable.defaultProps = {
  allowDownload: false,
  accountCategoryIds: []
}

export default React.memo(HistoricalPerformanceTable)

AssetReturnCell.propTypes = {
  tooltip: PropTypes.shape({
    title: PropTypes.string,
    format: PropTypes.string
  }),
  balanceChangePercentage: PropTypes.number
}
