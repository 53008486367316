import React, { useCallback, useContext, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { compareNumericString } from '../../../utils'
import { useFormattingContext } from '../FormattingProvider/FormattingContext'
import { localStorageHelper } from '../../../utils/localStorageHelper'

export const defaultColumnConfig = {
  columns: [
    { id: 'valueDate', Header: 'Date', accessor: 'valueDate', format: 'tradeDate' },
    { id: 'accountNumber', Header: 'Acct Number', accessor: 'accountNumber', format: 'title' },
    { id: 'assetName', Header: 'Asset Name', accessor: 'assetName' },
    { id: 'assetIdentifier', Header: 'Symbol', accessor: 'assetIdentifier' },
    { id: 'custodianName', Header: 'Custodian', accessor: 'custodianName' },
    { id: 'transactionDescription', Header: 'Description', accessor: 'transactionDescription', disableSortBy: true },
    { id: 'marketValue', Header: 'Value', accessor: 'marketValue', format: 'marketValueCents', alignRight: true },
    { id: 'units', Header: 'Units', accessor: 'units', format: 'number', alignRight: true, sortType: compareNumericString },
    // // Hidden columns
    { id: 'accountName', Header: 'Account Name', accessor: 'accountName', hidden: true },
    { id: 'cusip', Header: 'CUSIP', accessor: 'cusip', hidden: true },
    { id: 'assetId', Header: 'Asset ID', accessor: 'assetId', hidden: true },
    { id: 'tradeDate', Header: 'Trade Date', accessor: 'tradeDate', format: 'tradeDate', hidden: true },
    { id: 'settleDate', Header: 'Settle Date', accessor: 'settleDate', format: 'tradeDate', hidden: true },
    { id: 'adjustedMarketValue', Header: 'Value - Cash Impact', accessor: 'adjustedMarketValue', format: 'marketValue', alignRight: true, hidden: true }
  ],
  defaultSort: [{ id: 'valueDate', desc: true }]
}

const mapDefaultColumnState = (columns, storageKey) => {
  const stored = storageKey ? localStorageHelper.load(storageKey) : null
  if (stored) {
    const allIncluded = columns.every(col => col.id in stored)
    if (allIncluded) return stored
    localStorageHelper.tryRemove(storageKey)
  }
  return columns.reduce((prev, cur, i) => {
    prev[cur.id] = {
      id: cur.id,
      Header: cur.Header,
      hidden: cur.hidden || false,
      ordinal: i
    }
    return prev
  }, {})
}

const mapColumns = (cols, formatter, columnState) => {
  if (!cols) return undefined
  return cols.filter(column => {
    const id = column.id // assuming all have explicit id
    const colState = columnState[id]
    return colState && !colState.hidden
  }).map(column => {
    const formatString = column.format
    const colState = columnState[column.id]
    return {
      ...column,
      Cell: ({ value }) => formatter(value, formatString),
      columns: mapColumns(column.columns, formatter, columnState),
      hidden: colState.hidden,
      ordinal: colState.ordinal
    }
  }).sort((a, b) => a.ordinal - b.ordinal)
}

const TransactionsColumnContext = React.createContext({
  columns: defaultColumnConfig.columns,
  defaultSort: defaultColumnConfig.defaultSort,
  register: () => {}
})

export const useTransactionsColumns = () => {
  return useContext(TransactionsColumnContext)
}

function TransactionsColumnProvider ({ storageKey, columns: _columns, children, defaultSort }) {
  const [columns] = useState(_columns)
  const [columnState, setColumnState] = useState(mapDefaultColumnState(_columns, storageKey))
  const { formatter } = useFormattingContext()

  const _setColumnState = useCallback((columnState) => {
    if (storageKey) {
      localStorageHelper.store(storageKey, columnState)
    }
    setColumnState(columnState)
  }, [setColumnState, storageKey])

  const value = useMemo(() => {
    return {
      columns: mapColumns(columns, formatter, columnState),
      columnState,
      setColumnState: _setColumnState,
      availableColumns: columns,
      defaultSort: defaultSort || defaultColumnConfig.defaultSort
    }
  }, [columns, formatter, columnState, _setColumnState, defaultSort])

  return (
    <TransactionsColumnContext.Provider value={value}>
      {children}
    </TransactionsColumnContext.Provider>
  )
}

TransactionsColumnProvider.propTypes = {
  columns: PropTypes.array,
  storageKey: PropTypes.string,
  children: PropTypes.node.isRequired,
  defaultSort: PropTypes.array
}

TransactionsColumnProvider.defaultProps = {
  columns: defaultColumnConfig.columns
}

export default TransactionsColumnProvider
