import React from 'react'
import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'
import {
  Container,
  makeStyles
} from '@material-ui/core'
import Text from '../../../atoms/Text'
import Skeleton from '../../../atoms/Skeleton'

import { SKELETON_VARIANTS, TEXT_VARIANTS } from '../../../../constants'

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '1.5rem 0rem',
    height: '100%',
    [theme.breakpoints.down('xs')]: {
      padding: '1.75rem'
    }
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
    borderRadius: '4px',
    border: '2px solid #EEF0F8',
    background: '#FFF',
    margin: '10px 0px',
    padding: '18px 10px'
  },
  chip: {
    display: 'flex',
    padding: '4px 12px',
    margin: '0 8px 8px 0',
    borderRadius: '4px',
    backgroundColor: '#F5F6F7 !important'
  },
  mainChip: {
    color: `${theme.palette.summitBlue} !important`,
    fontWeight: 600,
    textTransform: 'capitalize',
    fontSize: '0.75rem'
  },
  title: {
    marginBottom: '5px'
  }
}))

function PermissionGroups ({
  permissionGroups,
  selectedPermissionGroups,
  loading,
  isAdmin
}) {
  const classes = useStyles()

  if (loading) {
    return (
      <Container maxWidth={false} className={classes.container}>
        <Skeleton
          width='100%'
          height='200px'
          variant={SKELETON_VARIANTS.rect}
          className={classes.skeleton}
        />
      </Container>
    )
  }

  if (isAdmin) {
    return (
      <Container maxWidth={false} className={classes.container}>
        <div className={classes.header}>
          <Text text='Permisions Groups' variant={TEXT_VARIANTS.h3} className={classes.title} />
        </div>
        <div className={classes.chips}>
          <div className={classes.chip}>
            <Text
              text='All Permissions'
              className={classes.mainChip}
            />
          </div>
        </div>
      </Container>
    )
  }

  return (
    <Container maxWidth={false} className={classes.container}>
      <div className={classes.header}>
        <Text text='Permisions Groups' variant={TEXT_VARIANTS.h3} className={classes.title} />
        <Text> Add <strong>Permissions</strong> you'd like this role to include. At least one permission group is required to save.</Text>
      </div>
      {!isEmpty(selectedPermissionGroups) && (
        <div className={classes.chips}>
          {selectedPermissionGroups.map((value) => (
            <div key={value} className={classes.chip}>
              <Text
                text={permissionGroups[value]?.name}
                className={classes.mainChip}
              />
            </div>
          ))}
        </div>)}
    </Container>
  )
}

PermissionGroups.propTypes = {
  permissionGroups: PropTypes.array,
  selectedPermissionGroups: PropTypes.arrayOf(PropTypes.number),
  loading: PropTypes.bool,
  isAdmin: PropTypes.bool
}

PermissionGroups.defaultValues = {
  permissionGroups: [],
  selectedPermissionGroups: [],
  loading: false,
  isAdmin: false
}

export default PermissionGroups
