import React, { useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useLocation, Link } from 'react-router-dom'
import Tabs from '../../molecules/Tabs'
import { useLinkViewTabGroup } from './LinkViewTabGroupContext'

/**
 * Tab Links
 * @param {JSX.Element} rightAdornment
 */
const TabLinks = ({
  rightAdornment
}) => {
  const location = useLocation()
  const { selectedValue, selectValue, options } = useLinkViewTabGroup()

  useEffect(() => {
    const selectedOption = options[selectedValue]
    if (selectedOption.path !== location.pathname) {
      selectValue(selectedOption.key)
    }
  }, [selectValue, selectedValue, options, location])

  const onChangeTab = useCallback(
    (_, newValue) => {
      const option = options[newValue]
      if (!option) return null
      selectValue(option.key)
    },
    [selectValue, options]
  )

  return (
    <Tabs.Wrapper noMargin>
      <Tabs
        value={selectedValue}
        indicatorColor='primary'
        textColor='primary'
        onChange={onChangeTab}
      >
        {Object.values(options).map((o) => {
          return (
            <Tabs.Tab
              key={o.key}
              value={o.key}
              label={o.label}
              disabled={o.disabled}
              component={(props) => <Link to={o.path} onClick={(event) => event.stopPropagation()} {...props} />}
            />
          )
        })}
      </Tabs>
      {rightAdornment}
    </Tabs.Wrapper>
  )
}

TabLinks.propTypes = {
  rightAdornment: PropTypes.node
}

TabLinks.defaultProps = {
  rightAdornment: undefined
}

export default TabLinks
