import React from 'react'
import { useAbundanceEngineViewByPathName } from '../../../api/abundanceEngine'
import AbundanceParser from '../../../abundanceEngine/AbundanceParser'

const AddAccountWrapper = () => {
  const { data: viewData } = useAbundanceEngineViewByPathName('manual-account-entry')
  if (!viewData) {
    return null
  }
  return (
    <AbundanceParser
      jsx={viewData?.jsxView}
      jsxComponentsDefaultProps={viewData.defaultProps}
    />
  )
}

export default AddAccountWrapper
