import React, { useMemo, useRef } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import FadeIn from '../../../../molecules/Transitions/FadeIn'
import AdvisorStatusBadge from '../AdvisorStatusBadge'
import { usePolicy } from '../../../../../hooks/usePolicy'
import SydButton from '../../../../commonDesign/Button'
import ModifyAccessTypeDialog from '../dialogs/ModifyAccessTypeDialog'
import ModifyVisibilityDialog from '../dialogs/ModifyVisibilityDialog'

const useStyles = makeStyles((theme) => ({
  headerInfoDisplay: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.layout.gap.sparse
  },
  featured: {
    fontSize: theme.typography.fontSizes.h1,
    fontWeight: theme.typography.weights.light,
    fontFamily: theme.typography.families.bold
  },
  subFeatured: {
    fontSize: theme.typography.fontSizes.xl,
    fontWeight: theme.typography.weights.light,
    fontFamily: theme.typography.families.bold
  },
  subHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '10px',
    minHeight: '30px'
  },
  otherDetails: {
    display: 'flex',
    flexDirection: 'row',
    gap: '10px',
    margin: '10px 0'
  },
  description: {
    fontWeight: theme.typography.weights.light
  },
  mainItem: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  infoItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.layout.padding.thick,
    border: `1px solid ${theme.palette.gray.main}`,
    borderRadius: theme.layout.radius.round
  }
}))

function accessStatus (user) {
  if (user.allAccounts) {
    return 'All Accounts'
  }
  if (user.allowAllUnrestricted) {
    return 'All Unrestricted Accounts'
  }
  return 'Limited Access'
}

function visibilityStatus (user) {
  return user.internal ? 'Internal' : 'Normal'
}

function HeaderInfoDisplay ({ user }) {
  const classes = useStyles()
  const isInternal = usePolicy('internalOnly')
  const canEdit = usePolicy('admin_edit_advisor_users')
  const modifyAccessLevelRef = useRef()
  const modifyVisibilityRef = useRef()
  const fullName = useMemo(() => {
    return `${user.firstName} ${user.lastName}`
  }, [user])

  return (
    <FadeIn className={classes.headerInfoDisplay}>
      <div className={classes.mainItem}>
        <div className={classes.featured}>{fullName}</div>
        <div className={classes.subHeader}>
          <span>{user.email}</span>
          <AdvisorStatusBadge />
        </div>
      </div>
      <div className={classes.infoItem}>
        <div className={classes.subFeatured}>Data Access Level</div>
        {canEdit ? (
          <SydButton size='sm' variant='outline' icon='edit' onClick={() => modifyAccessLevelRef.current.open(user)}>{accessStatus(user)}</SydButton>
        ) : (
          <div className={classes.subHeader}>{accessStatus(user)}</div>
        )}
      </div>
      {isInternal ? (
        <div className={classes.infoItem}>
          <div className={classes.subFeatured}>Visibility Level</div>
          {canEdit ? (
            <SydButton size='sm' variant='outline' icon='edit' onClick={() => modifyVisibilityRef.current.open(user)}>{visibilityStatus(user)}</SydButton>
          ) : (
            <div className={classes.subHeader}>{visibilityStatus(user)}</div>
          )}
        </div>
      ) : null}
      <ModifyAccessTypeDialog ref={modifyAccessLevelRef} />
      <ModifyVisibilityDialog ref={modifyVisibilityRef} />
    </FadeIn>
  )
}

HeaderInfoDisplay.propTypes = {
  user: PropTypes.shape({
    userId: PropTypes.number,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    externalUserId: PropTypes.string
  })
}

export default HeaderInfoDisplay
