import { useMemo } from 'react'

export const isAdvisorUser = (groups = []) => {
  const isAdvisor = groups.reduce(
    (acc, group) => group.includes('advisors') || acc,
    false
  )
  return isAdvisor
}

export const isSummitUser = (groups = []) => {
  const isSummit = groups.reduce(
    (acc, group) => group === 'summit' || acc,
    false
  )
  return isSummit
}

export default function usePermissions (accessToken = {}) {
  return useMemo(() => {
    const { claims } = accessToken
    if (!claims) {
      return {
        isAdvisor: false,
        clientId: undefined,
        oktaId: undefined,
        firmId: undefined
      }
    }
    const { groups = [], firmClientId, uid, firmId, sub } = claims
    const isAdvisor = isAdvisorUser(groups)
    const isSummit = isSummitUser(groups)
    const isWealthOwner = !isAdvisor
    const role = isAdvisor ? 'advisor' : isSummit ? 'summit' : 'wealth-owner'

    return {
      isAdvisor,
      isWealthOwner,
      isSummitUser: isSummit,
      clientId: firmClientId,
      oktaId: uid,
      firmId,
      user: sub,
      role
    }
  }, [accessToken])
}
