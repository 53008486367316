import { useMemo } from 'react'
import { useCustodians } from '../../../../../api/coreData'
import { useTargetModels } from '../../../../../api/rebalancer'
import useGroupTypeColumns from './useGroupTypeColumns'
import { COLUMN_IDS } from './helpers'

const mapCustodianOptions = data => {
  return data.map(({ custodianId, custodianName }) => ({
    label: custodianName,
    value: custodianId
  }))
}

const mapTargetModelOptions = (data) => {
  return data.map(({ targetModelId, name }) => {
    return {
      label: name,
      value: targetModelId
    }
  })
}

const useAccountFilterOptions = (
  searchParams,
  visibleFilters,
  defaultColumnConfig
) => {
  const isCustodiansQueryEnabled = visibleFilters.includes(COLUMN_IDS.CUSTODIANS)
  const isTargetModelsQueryEnabled = visibleFilters.includes(COLUMN_IDS.TARGET_MODELS)

  const groupTypeColumnParams = useMemo(() => {
    const { tagFilters } = searchParams || {}
    return {
      query: { filters: { memberLevelTypeIds: [1] } },
      tagFilters: tagFilters,
      visibleFilters,
      defaultColumnConfig
    }
  }, [defaultColumnConfig, searchParams, visibleFilters])

  const {
    groupTypes,
    isLoadingGroupTypes,
    columnConfig: tableColumnConfig
  } = useGroupTypeColumns(groupTypeColumnParams)

  const custodiansQuery = useMemo(
    () => ({
      enabled: isCustodiansQueryEnabled,
      mapper: mapCustodianOptions
    }),
    [isCustodiansQueryEnabled]
  )

  const {
    data: custodians,
    isLoading: isLoadingCustodians
  } = useCustodians(custodiansQuery)

  const targetModelsQuery = useMemo(() => {
    return {
      query: {},
      queryOptions: {
        enabled: isTargetModelsQueryEnabled,
        mapper: mapTargetModelOptions
      }
    }
  }, [isTargetModelsQueryEnabled])

  const {
    data: targetModels,
    isLoading: isLoadingTargetModels
  } = useTargetModels(
    targetModelsQuery.query,
    targetModelsQuery.queryOptions
  )

  const isLoadingOptions =
    isLoadingGroupTypes ||
    (isLoadingCustodians && isCustodiansQueryEnabled) ||
    (isLoadingTargetModels && isTargetModelsQueryEnabled)

  return {
    groupTypes,
    isLoadingGroupTypes,

    custodians,
    isLoadingCustodians,

    targetModels,
    isLoadingTargetModels,

    isLoadingOptions,
    tableColumnConfig
  }
}

export default useAccountFilterOptions
