import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { Avatar as MuiAvatar, Badge } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useProtectedResource } from '../../hooks'
import { ANCHOR_ORIGIN, SIZE_VARIANTS } from '../../constants'
import { useImageLoaded } from '../../hooks/useImageLoaded'

function getInitials (text, useOneInitial, useAbbreviation) {
  if (!text?.trim()) return ''
  if (useAbbreviation) return text.toUpperCase()
  const [firstSection, secondSection] = text.toUpperCase().split(' ')
  const firstInitial = firstSection.charAt(0)
  if (secondSection && !useOneInitial) {
    return `${firstInitial}${secondSection.charAt(0)}`
  }
  return firstInitial
}

const getStylesByVariant = (theme) => ({
  [SIZE_VARIANTS.extraSmall]: {
    width: theme.spacing(3),
    height: theme.spacing(3)
  },
  [SIZE_VARIANTS.small]: {
    width: theme.spacing(4),
    height: theme.spacing(4)
  },
  [SIZE_VARIANTS.medium]: {
    width: theme.spacing(5),
    height: theme.spacing(5)
  },
  [SIZE_VARIANTS.large]: {
    width: theme.spacing(7),
    height: theme.spacing(7)
  },
  [SIZE_VARIANTS.xLarge]: {
    width: theme.spacing(12),
    height: theme.spacing(12)
  },
  [SIZE_VARIANTS.fill]: {
    width: '100%',
    height: '100%'
  }
})

/**
 * Is it done loading????????
 * @return {boolean}
 */
function itLoaded (src, imageLoaded, error) {
  if (!src) {
    // If it ain't got no source, it ain't gonna load
    return true
  }
  if (error) {
    // If we done had an error while trying to get a url, it ain't gonna load
    return true
  }
  if (imageLoaded) {
    // If the image was either loaded or not, because of a 404 or something, it ain't gonna load anymore than it has
    return true
  }

  return false
}

const useStyles = makeStyles((theme) => {
  const styles = getStylesByVariant(theme)
  return {
    ...styles,
    customSize: ({ customSize }) => ({
      width: customSize,
      height: customSize
    }),
    avatarLetter: ({ customFontSize }) => ({
      fontSize: customFontSize || '14px'
    }),
    avatarLetterColor: {
      color: theme.palette.getContrastText(theme.palette.gray.main),
      backgroundColor: theme.palette.gray.main
    },
    avatarOpacity: {
      opacity: 0.1,
      animationPlayState: ({ imageLoaded, error, src }) => itLoaded(src, imageLoaded, error) ? 'running' : 'paused',
      animation: '$atomAvatarFadeIn 300ms ease-in-out',
      animationFillMode: 'forwards'
    },
    '@keyframes atomAvatarFadeIn': {
      '0%': {
        opacity: 0.1
      },
      '100%': {
        opacity: 1
      }
    }
  }
})

function AvatarBadge ({ children, show, badgeAnchorOrigin, badge }) {
  if (show) {
    return (
      <Badge
        overlap='circle'
        anchorOrigin={badgeAnchorOrigin}
        badgeContent={badge}
      >
        {children}
      </Badge>
    )
  }

  return children
}

AvatarBadge.propTypes = {
  children: PropTypes.node,
  show: PropTypes.bool,
  badgeAnchorOrigin: PropTypes.shape({
    vertical: PropTypes.oneOf(Object.values(ANCHOR_ORIGIN.VERTICAL)),
    horizontal: PropTypes.oneOf(Object.values(ANCHOR_ORIGIN.HORIZONTAL))
  }),
  badge: PropTypes.node
}

function Avatar ({
  avatarLetters,
  badge,
  badgeAnchorOrigin,
  customClassName,
  customFontSize,
  customSize,
  isPublicResource,
  showBadge,
  size,
  src,
  useOneInitial,
  useAbbreviation,
  style,
  useCrossOriginForPublicResources
}) {
  const { url, error } = useProtectedResource(src, isPublicResource)
  /*
   * onError will not always be called.
   * see: https://github.com/mui/material-ui/blame/v4.12.4/packages/material-ui/src/Avatar/Avatar.js#L115
   * They have a condition in the version 4.9.11+ of MUI that will make the image not call on onError callback
   * unless it is remounted
   */

  const imageLoaded = useImageLoaded(url)
  const classes = useStyles({ customSize, customFontSize, imageLoaded, error, src })
  const customClasses = customSize ? classes.customSize : classes[size]
  const imgProps = useMemo(() => {
    return {
      // Set crossOrigin to anonymous to avoid having a cors error when generating a Canvas from html
      crossOrigin: (useCrossOriginForPublicResources && isPublicResource) ? 'anonymous' : undefined
    }
  }, [useCrossOriginForPublicResources, isPublicResource])

  return (
    <AvatarBadge show={showBadge && badge} badgeAnchorOrigin={badgeAnchorOrigin} badge={badge}>
      <div className={classes.avatarOpacity}>
        <MuiAvatar
          src={url}
          className={clsx([
            customClasses,
            { [classes.avatarLetter]: !!avatarLetters },
            { [classes.avatarLetterColor]: !!avatarLetters },
            customClassName
          ])}
          style={style}
          imgProps={imgProps}
        >
          {getInitials(avatarLetters, useOneInitial, useAbbreviation) || null}
        </MuiAvatar>
      </div>
    </AvatarBadge>
  )
}

Avatar.propTypes = {
  avatarLetters: PropTypes.string,
  badge: PropTypes.node,
  badgeAnchorOrigin: PropTypes.shape({
    vertical: PropTypes.oneOf(Object.values(ANCHOR_ORIGIN.VERTICAL)),
    horizontal: PropTypes.oneOf(Object.values(ANCHOR_ORIGIN.HORIZONTAL))
  }),
  customFontSize: PropTypes.string,
  customSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isPublicResource: PropTypes.bool,
  showBadge: PropTypes.bool,
  size: PropTypes.oneOf(Object.values(SIZE_VARIANTS)),
  src: PropTypes.string,
  useOneInitial: PropTypes.bool,
  customClassName: PropTypes.string,
  style: PropTypes.any,
  // avatarLetter is an abbreviation to be used directly
  useAbbreviation: PropTypes.bool,
  useCrossOriginForPublicResources: PropTypes.bool
}

Avatar.defaultProps = {
  avatarLetters: '',
  customFontSize: '',
  customSize: undefined,
  isPublicResource: false,
  showbadge: true,
  showSkeleton: false,
  size: SIZE_VARIANTS.medium,
  src: '',
  useOneInitial: false,
  customClassName: undefined,
  badge: undefined,
  badgeAnchorOrigin: undefined,
  style: {},
  useAbbreviation: false,
  useCrossOriginForPublicResources: false
}

export default Avatar
