import React from 'react'
import { Box } from '@material-ui/core'
import loadingImage from '../../../assets/summitLoad.gif'
import Text from '../../atoms/Text'

const LoadingPlaceholder = () => {
  return (
    <Box textAlign='center' minHeight='300px' alignItems='center' justifyContent='center' display='flex'>
      <Box>
        <Box>
          <img src={loadingImage} alt='Loading...' width='160px' height='auto' />
        </Box>
        <Text text='Loading...' />
      </Box>
    </Box>
  )
}

export default LoadingPlaceholder
