import { useMemo } from 'react'
import { useCheckPolicies } from '../api/policy'

export default function usePolicies () {
  const { data: policies, isLoading } = useCheckPolicies()

  return useMemo(() => {
    if (isLoading) return {}
    return policies
  }, [policies, isLoading])
}
