import React, { useCallback, useImperativeHandle, useState } from 'react'
import PropTypes from 'prop-types'
import RoundedModal from '../../../../../molecules/RoundedModal'
import AddTeamMemberForm from './form'

const AddTeamMemberDialog = React.forwardRef(function AddTeamMemberDialog ({ onAdd }, ref) {
  const [dialogState, setDialogState] = useState({ open: false, processing: false })
  const close = useCallback(() => {
    setDialogState({ open: false, processing: false })
  }, [setDialogState])

  const complete = useCallback((val) => {
    onAdd(val)
    close()
  }, [close, onAdd])

  useImperativeHandle(ref, () => ({
    open: (client) => setDialogState({ open: true, processing: false, client })
  }), [setDialogState])

  return (
    <RoundedModal
      title='Add Team Member'
      open={dialogState.open}
      onClose={close}
      position='top'
      disablePortal={false}
    >
      <div>
        {dialogState.open ? (<AddTeamMemberForm onComplete={complete} onCancel={close} />) : null}
      </div>
    </RoundedModal>
  )
})

AddTeamMemberDialog.propTypes = {
  onAdd: PropTypes.func
}

export default AddTeamMemberDialog
