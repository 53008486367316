import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles, Grid } from '@material-ui/core'
import clsx from 'clsx'
import SydButton from '../../../../../commonDesign/Button'
import { useModifyClientRestriction } from '../../../../../../api/groups'

const useStyles = makeStyles((theme) => ({
  actions: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  group: {
    marginBottom: theme.layout.margin.thick,
    display: 'flex',
    flexDirection: 'column',
    gap: theme.layout.gap.medium
  },
  error: {
    backgroundColor: theme.palette.danger.main,
    color: theme.palette.danger.contrast,
    flex: '1 0 auto',
    padding: theme.layout.padding.medium,
    borderRadius: theme.layout.radius.round,
    fontSize: theme.typography.fontSizes.md,
    lineHeight: '20px',
    marginRight: theme.layout.margin.thin
  },
  type: {
    outline: `1px solid ${theme.palette.gray.darker}`,
    borderRadius: theme.layout.radius.round,
    padding: theme.layout.padding.thick,
    fontSize: theme.typography.fontSizes.xxl,
    transition: 'outline .2s ease-in-out, background-color .2s ease-in-out',
    '&:hover': {
      backgroundColor: theme.palette.gray.main,
      cursor: 'pointer'
    },
    '&.__selected': {
      outline: `4px solid ${theme.palette.primary.main}`,
      '&:hover': {
        outline: `4px solid ${theme.palette.primary.main}`
      }
    },
    '& article': {
      fontWeight: theme.typography.weights.light
    }
  }
}))

function ModifyRestrictionForm ({ onCancel, onComplete, client }) {
  const classes = useStyles()
  const [restricted, setRestricted] = useState(client.restricted)
  const { mutateAsync: modifyClientRestriction } = useModifyClientRestriction()
  const [processing, setProcessing] = useState(false)
  const [error, setError] = useState(null)

  return (
    <>
      <div className={classes.form}>
        <div className={classes.group}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div
                className={clsx(classes.type, { __selected: !restricted })}
                onClick={() => setRestricted(false)}
                role='button'
              >
                <header>Standard</header>
                <article>
                  <p>The client will be accessible by</p>
                  <ul>
                    <li>Users designated with all data access</li>
                    <li>Users designated with unrestricted data access</li>
                    <li>Users specifically assigned access to this client</li>
                  </ul>
                </article>
              </div>
            </Grid>
            <Grid item xs={12}>
              <div
                className={clsx(classes.type, { __selected: restricted })}
                onClick={() => setRestricted(true)}
                role='button'
              >
                <header>Restricted</header>
                <article>
                  <p>The client will be accessible by</p>
                  <ul>
                    <li>Users designated with all data access</li>
                    <li>Users specifically assigned access to this client</li>
                  </ul>
                </article>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <div className={classes.actions}>
        {error ? (<div className={classes.error}>{error}</div>) : null}
        <SydButton disabled={processing} variant='ghost' size='md' onClick={onCancel}>Cancel</SydButton>
        <SydButton
          variant='primary'
          size='md'
          processing={processing}
          onClick={async () => {
            try {
              setError(null)
              setProcessing(true)
              const command = {
                clientId: client.clientId,
                restricted
              }
              await modifyClientRestriction(command)
              onComplete(command)
            } catch (err) {
              setError('There was a problem setting the client visibility setting')
              console.error(err)
            } finally {
              setProcessing(false)
            }
          }}
          icon='warning'
          priority='warning'
        >
          Change Client Visibility
        </SydButton>
      </div>
    </>
  )
}

ModifyRestrictionForm.propTypes = {
  onComplete: PropTypes.func,
  onCancel: PropTypes.func,
  client: PropTypes.shape({
    restricted: PropTypes.bool,
    clientId: PropTypes.number
  })
}

export default ModifyRestrictionForm
