import React from 'react'
import PropTypes from 'prop-types'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  makeStyles
} from '@material-ui/core'
import { useBoolean } from '../../../../../../hooks'
import Icon from '../../../../../atoms/Icon'
import { ICON_NAMES } from '../../../../../../constants'

const useStyles = makeStyles(() => ({
  container: {
    width: '100%',
    borderRadius: '0.75rem !important',
    border: '2px solid #E9EAEF',
    background: '#FFF',
    boxShadow: 'none',
    padding: '1rem'
  },
  accordionRoot: {
    '&::before': {
      opacity: '0 !important'
    }
  },
  accordionSummary: {
    padding: '0 !important'
  },
  accordionSummaryRoot: {
    minHeight: 'unset !important'
  },
  accordionSummaryContent: {
    margin: '0 !important'
  },
  accordionDetails: {
    padding: '1rem'
  }
}))

const TaskCardContainer = ({ children, header }) => {
  const classes = useStyles()
  const [open, toggleOpen] = useBoolean()
  return (
    <Accordion
      expanded={open}
      onChange={toggleOpen.toggle}
      className={classes.container}
      classes={{
        root: classes.accordionRoot
      }}
    >
      <AccordionSummary
        className={classes.accordionSummary}
        classes={{
          root: classes.accordionSummaryRoot,
          content: classes.accordionSummaryContent
        }}
        expandIcon={<Icon name={ICON_NAMES.chevronDown} customSize='2rem' />}
      >
        {header}
      </AccordionSummary>
      <AccordionDetails className={classes.accordionDetails}>
        {open && children}
      </AccordionDetails>
    </Accordion>
  )
}

TaskCardContainer.propTypes = {
  header: PropTypes.node,
  children: PropTypes.node
}

export default TaskCardContainer
