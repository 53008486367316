import { useMemo, useState } from 'react'
import isEmpty from 'lodash/isEmpty'
import { DEFAULT_SELECT_SEARCH_PAGINATION, MILLISECONDS_BEFORE_SEARCH } from '../constants'
import { useAssetSearch } from '../api/coreData'
import useDebouncedValue from './useDebounceValue'
import { getSearchQuery } from './helpers'

const SEARCH_FIELDS = ['assetIdentifier', 'longName', 'shortName']

export const mapAssets = (assets = []) =>
  assets.map((asset) => ({
    label: `${asset.assetIdentifier} - ${asset.longName}`,
    value: asset.assetId,
    payload: {
      ...asset
    }
  }))

const getQueryFilters = ({ query }) => ({
  textSearch: getSearchQuery(SEARCH_FIELDS, query)
})

const useSearchAssetsDebounced = ({
  query = {},
  queryOptions = {},
  defaultAssetIds = [],
  debounceDelay = MILLISECONDS_BEFORE_SEARCH
} = {}) => {
  const [queryText, setQueryText] = useState('')
  const debouncedQuery = useDebouncedValue(queryText, debounceDelay)

  const { queryParams, queryParamOptions } = useMemo(() => {
    const queryParams = getQueryFilters({
      query: debouncedQuery
    })

    return {
      queryParams: {
        ...DEFAULT_SELECT_SEARCH_PAGINATION,
        ...queryParams,
        ...query,
        ...(debouncedQuery?.length && {
          sort: [
            { field: 'assetIdentifierExact', dir: 'desc' },
            { field: 'assetId', dir: 'desc' }
          ]
        })
      },
      queryParamOptions: {
        mapper: mapAssets,
        ...queryOptions
      }
    }
  }, [query, queryOptions, debouncedQuery])

  const { data, isLoading } = useAssetSearch(queryParams, queryParamOptions)

  const defaultOptionsQuery = useMemo(() => {
    return {
      queryParams: {
        filters: {
          assetId: defaultAssetIds
        }
      },
      queryParamOptions: {
        mapper: mapAssets,
        enabled: !isEmpty(defaultAssetIds),
        ...queryOptions
      }
    }
  }, [queryOptions, defaultAssetIds])

  const { data: defaultOptions, isLoading: isLoadingDefaults } = useAssetSearch(
    defaultOptionsQuery.queryParams,
    defaultOptionsQuery.queryParamOptions
  )

  const isTransitioningOptions = isLoading || debouncedQuery !== queryText

  return {
    options: data,
    onChangeQuery: setQueryText,
    isSearchLoading: isTransitioningOptions,
    isLoading: isLoading || (isLoadingDefaults && !isEmpty(defaultAssetIds)),
    defaultOptions
  }
}

export default useSearchAssetsDebounced
