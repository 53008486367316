import React, { useEffect, useMemo, useState } from 'react'
import { Box, makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import isEmpty from 'lodash.isempty'
import { useContextContainer } from '../../../../abundanceEngine/components/ContextContainer'
import { ASSET_TEAR_CONTEXT } from '..'
import { fetchDocuments } from '../../../../service'
import { useAppContext } from '../../../../redux/slices/appContext'
import AssetTearDocument from './AssetTearDocument'

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'row'
  }
}))

const useDocuments = () => {
  const { clientId } = useAppContext()
  const [documents, setDocuments] = useState([])

  useEffect(() => {
    async function initDocuments () {
      const { data } = await fetchDocuments(clientId)
      setDocuments(data)
    }
    initDocuments()
  }, [clientId])

  return {
    isLoading: isEmpty(documents),
    documents
  }
}

const AssetTearDocuments = (props) => {
  const classes = useStyles()
  const [{ data }] = useContextContainer(ASSET_TEAR_CONTEXT)
  const { isLoading, documents } = useDocuments()

  const vaultDocuments = useMemo(() => {
    const assetTearDocumentIds = (data?.documents || []).map(
      ({ documentId }) => documentId
    )
    return documents.filter(({ id }) => assetTearDocumentIds.includes(id))
  }, [data?.documents, documents])

  return (
    <Box className={classes.container} {...props}>
      {vaultDocuments?.map((document) => {
        return (
          <AssetTearDocument
            key={document.documentId}
            document={document}
            isLoading={isLoading}
          />
        )
      })}
    </Box>
  )
}
AssetTearDocuments.propTypes = {
  documents: PropTypes.array
}

AssetTearDocuments.defaultProps = {
  documents: []
}

export default AssetTearDocuments
