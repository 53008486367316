import React from 'react'
import clsx from 'clsx'
import { Grid, makeStyles, TextField, MenuItem } from '@material-ui/core'
import PropTypes from 'prop-types'
import Text from '../../../atoms/Text'

const useStyles = makeStyles((theme) => ({
  selector: {
    marginBottom: '18px',
    padding: '0px 10px 10px 0'
  },
  label: {
    color: '#141929',
    lineHeight: 'normal',
    fontSize: '18px',
    marginBottom: '1rem',
    fontWeight: 350
  },
  itemTextField: {
    borderRadius: '4px',
    padding: '0.625rem 0px 0.625rem 0.625rem',
    border: '2px solid #EEF0F8',
    background: theme.palette.common.white,
    minHeight: '3rem'
  },
  itemText: {
    fontSize: '1rem'
  },
  emptySelector: {
    minHeight: '3rem'
  }
}))

const FormSelectField = ({
  label,
  accessor,
  editMode,
  value,
  options,
  text,
  className,
  ...rest
}) => {
  const classes = useStyles()

  return (
    <Grid item xs={12} sm={6} className={clsx(classes.selector, className)}>
      {label && <Text text={label} className={classes.label} />}
      {editMode ? (
        <TextField
          fullWidth
          name={accessor}
          select
          className={classes.itemTextField}
          InputProps={{ disableUnderline: true }}
          disabled={!editMode}
          value={value || ''}
          {...rest}
        >
          <MenuItem value='' disabled>
            Not assigned
          </MenuItem>
          {options.map(({ value, label }) => (
            <MenuItem key={value} value={value}>
              {label}
            </MenuItem>
          ))}
        </TextField>
      ) : (
        <div className={clsx(classes.itemTextField, { [classes.emptySelector]: !!label })}>
          <Text text={text} className={classes.itemText} />
        </div>
      )}
    </Grid>
  )
}

FormSelectField.propTypes = {
  label: PropTypes.string,
  accessor: PropTypes.string.isRequired,
  editMode: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.any,
    label: PropTypes.string
  })).isRequired,
  text: PropTypes.string.isRequired,
  className: PropTypes.string
}

FormSelectField.defaultProps = {
  label: undefined
}

export default FormSelectField
