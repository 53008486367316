import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import dayjs from 'dayjs'

const useStyles = makeStyles(theme => ({
  messageHeader: {
    display: 'flex',
    flexDirection: 'row',
    gap: '14px',
    fontWeight: 600,
    fontSize: '11px',
    lineHeight: '20px'
  },
  timePart: {
    verticalAlign: 'bottom',
    fontSize: '10px',
    fontWeight: 100
  }
}))

const MessageHeader = ({ message, participant }) => {
  const classes = useStyles()
  const datePart = useMemo(() => {
    return dayjs(message.messageDateUtc).format('MMMM D')
  }, [message.messageDateUtc])
  const timePart = useMemo(() => {
    return dayjs(message.messageDateUtc).format('h:mm A')
  }, [message.messageDateUtc])
  const participantName = useMemo(() => {
    return participant?.name ?? 'Unknown'
  }, [participant])

  return (
    <div className={classes.messageHeader}>
      <span>{participantName}</span>
      <span className={classes.timePart}>
        <span>{datePart}</span>
        <span> @ </span>
        <span>{timePart}</span>
      </span>
    </div>
  )
}

MessageHeader.propTypes = {
  participant: PropTypes.shape({
    name: PropTypes.string
  }),
  message: PropTypes.shape({
    rawMessageBody: PropTypes.string,
    fromParticipantKey: PropTypes.string,
    messageDateUtc: PropTypes.string
  })
}

export default MessageHeader
