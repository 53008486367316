import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import { isEmpty } from 'lodash'
import CardListSkeleton from '../../ClientCardsViewV2/components/CardListSkeleton'
import ClientCardItem from '../../ClientCardsViewV2/ClientCardView/ClientCard/ClientCardItem'
import AdvisorHomeEmptySection from '../AdvisorHomeEmptySection'
import { ICON_NAMES } from '../../../../constants'

const useStyles = makeStyles(() => ({
  cardsList: {
    display: 'flex',
    gap: '1rem',
    padding: '1rem',
    overflowX: 'auto'
  },
  skeletonsContainer: {
    overflowX: 'auto',
    flexWrap: 'nowrap'
  }
}))

const CardsLayout = ({ data, isLoading, renderItem, emptyStateLabel }) => {
  const classes = useStyles()

  if (isLoading) {
    return (
      <CardListSkeleton
        cardListLength={5}
        cardComponent={(props) => <ClientCardItem noPadding {...props} />}
        containerClassName={classes.skeletonsContainer}
        itemClassName={classes.skeleton}
      />
    )
  }

  if (isEmpty(data) && !isLoading) {
    return (
      <AdvisorHomeEmptySection
        icon={ICON_NAMES.paperGlassMagnifier}
        title='No data available'
        description={emptyStateLabel}
        borderedContent
      />
    )
  }

  return <div className={classes.cardsList}>{data.map(renderItem)}</div>
}

CardsLayout.propTypes = {
  data: PropTypes.array,
  isLoading: PropTypes.bool,
  emptyStateLabel: PropTypes.string,
  renderItem: PropTypes.oneOfType([PropTypes.func, PropTypes.node])
}

export default CardsLayout
