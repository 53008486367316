import SydTabs from './SydTabs'

const abundanceEngineExports = {
  SydTabs,
  SydTab: SydTabs.Tab,
  SydTabList: SydTabs.List,
  SydTabLinks: SydTabs.Links,
  SydTabGroup: SydTabs.Group,
  SydTabButtons: SydTabs.Buttons
}

export default abundanceEngineExports
