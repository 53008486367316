import React, { createContext, useContext, useMemo, useState } from 'react'
import PropTypes from 'prop-types'

const HeaderScrollContext = createContext({})

export const useSetScrollPosition = () => {
  const { setScrollPosition, scrollPosition } = useContext(HeaderScrollContext)

  return { setScrollPosition, scrollPosition }
}

export const useScrollPosition = () => {
  const { scrollPosition } = useContext(HeaderScrollContext)

  return scrollPosition
}

export const HeaderScrollContextProvider = ({ children }) => {
  const [scrollPosition, setScrollPosition] = useState(null)
  const value = useMemo(() => {
    return {
      scrollPosition,
      setScrollPosition
    }
  }, [scrollPosition, setScrollPosition])

  return (
    <HeaderScrollContext.Provider value={value}>
      {children}
    </HeaderScrollContext.Provider>
  )
}

HeaderScrollContextProvider.propTypes = {
  children: PropTypes.node
}
