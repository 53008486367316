import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

const mapSeriesName = ({ id, name }) => `${id}_${name}`

function StreamChartLegend ({ enabled, classes, keys, series, colors }) {
  const seriesLegends = useMemo(() => {
    const mapped = keys.map((key) => {
      const resultIndex = series.findIndex(({ id, name }) => mapSeriesName({ id, name }) === key)
      const item = series[resultIndex]
      return {
        ordinal: typeof item?.ordinal ?? Number.MAX_SAFE_INTEGER,
        name: item?.name || key,
        color: colors[resultIndex]
      }
    })
    mapped.sort((a, b) => {
      return a.ordinal - b.ordinal
    })
    return mapped
  }, [keys, series, colors])

  if (!enabled) return null

  return (
    <div className={classes.legendsContainer}>
      {seriesLegends.map(({ name, color }) => {
        return (
          <div key={name} className={classes.legendItem}>
            <div
              className={classes.legendCircle}
              style={{ backgroundColor: color }}
            />
            <span>{name}</span>
          </div>
        )
      })}
    </div>
  )
}

StreamChartLegend.propTypes = {
  enabled: PropTypes.bool,
  classes: PropTypes.shape({
    legendsContainer: PropTypes.string,
    legendItem: PropTypes.string,
    legendCircle: PropTypes.string
  }),
  keys: PropTypes.array,
  series: PropTypes.array,
  colors: PropTypes.array
}

export default StreamChartLegend
