import React, { useCallback, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Checkbox,
  ListItemIcon,
  ListItemText,
  MenuItem,
  alpha,
  makeStyles
} from '@material-ui/core'
import { isArray, isEmpty, isObject, noop } from 'lodash'
import clsx from 'clsx'
import Text from '../../atoms/Text'
import { BUTTON_SIZES, TEXT_VARIANTS } from '../../../constants'
import { useBoolean } from '../../../hooks'
import Tooltip from '../../atoms/Tooltip'
import { useStyles as useBaseStyles } from './index'

const useStyles = makeStyles((theme) => ({
  button: {
    background: '#FFFFFF',
    border: '1px solid #ECECEC',
    borderRadius: '8px'
  },
  buttonFilled: {
    background: '#212945',
    color: '#FFFFFF',
    '&:hover': {
      background: alpha('#212945', 0.75)
    }
  },
  labelContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '& span:first-child': {
      marginRight: '0.625rem'
    }
  },
  prefixLabel: {
    fontSize: '0.8125rem',
    fontWeight: 'bold',
    textTransform: 'none',
    marginRight: '0.5rem',
    lineHeight: '1',
    whiteSpace: 'nowrap'
  },
  label: {
    fontSize: '0.625rem',
    lineHeight: '1',
    fontWeight: 'bold',
    textTransform: 'none',
    whiteSpace: 'nowrap'
  },
  placeholder: {
    fontStyle: 'normal',
    textTransform: 'none',
    fontWeight: 'bold',
    fontSize: '0.8125rem',
    whiteSpace: 'nowrap'
  },
  checkbox: {
    color: `${theme.palette.primary.main} !important`
  },
  item: {
    padding: '0.25rem !important'
  },
  clearButton: {
    color: '#D44333'
  },
  confirmButton: {
    color: theme.palette.primary
  },
  modalButton: {
    width: '100%',
    fontWeight: 'bold',
    padding: '0.75rem 1rem',
    justifyContent: 'flex-start',
    textTransform: 'capitalize'
  },
  buttonGroup: {
    display: 'flex',
    flexDirection: 'row'
  }
}))

const getOptionLabels = (options) => {
  const [leadingOption, ...restOptions] = Object.values(options)
  return `${leadingOption.label}${!restOptions?.length ? '' : `, +${restOptions.length}`}`
}

const getOptionTitle = (options) => {
  const labels = Object.values(options).map(({ label }) => label)
  return labels.length > 1 ? labels.join(', ') : null
}

const mapSelectedOptions = (prevOptions, option) => {
  if (!prevOptions[option.value]) {
    return {
      ...prevOptions,
      [option.value]: option
    }
  }
  const { [option.value]: optionToRemove, ...options } = prevOptions
  return { ...options }
}

const mapDefaultSelectedOptions = (defaultValues) => {
  if (isObject(defaultValues) && !isArray(defaultValues)) {
    return defaultValues
  }
  return defaultValues.reduce(
    (acc, option) => ({ ...acc, [option.value]: option }),
    {}
  )
}

const withCheckbox = (SelectComponent) => {
  const SelectWithCheckbox = ({
    size,
    onClose,
    onChange,
    options,
    disabled,
    disableApplyButton,
    disableClearButton,
    pinnedOptions,
    useSearchInput,
    defaultSelectedOptions,
    className: _className,
    labelRenderer: _labelRenderer,
    selectedOptions: _selectedOptions,
    ...props
  }) => {
    const classes = useStyles()
    const baseClasses = useBaseStyles({ size })

    const [open, setOpen] = useBoolean()

    const [selectedOptions, setSelectedOptions] = useState(
      mapDefaultSelectedOptions(defaultSelectedOptions)
    )

    useEffect(() => {
      if (!isEmpty(_selectedOptions)) {
        if (isArray(_selectedOptions)) {
          const options = _selectedOptions.reduce((acc, option) => ({
            ...acc,
            [option.value]: { ...option }
          }), {})
          setSelectedOptions(options)
        } else {
          setSelectedOptions(_selectedOptions)
        }
      } else if (_selectedOptions !== undefined) {
        setSelectedOptions({})
      }
    }, [_selectedOptions])

    const onCloseHandler = useCallback(() => {
      setOpen.off()
      onClose()
    }, [setOpen, onClose])

    const onClear = useCallback(() => {
      const pinnedValues = pinnedOptions.map(({ value }) => value.toString())

      const prevPinnedOptions = Object.entries(selectedOptions).reduce(
        (acc, [key, value]) => {
          if (pinnedValues.includes(key)) {
            return { ...acc, [key]: value }
          }
          return acc
        },
        {}
      )
      setSelectedOptions(prevPinnedOptions)
      onChange(Object.values(prevPinnedOptions))
      setOpen.off()
    }, [setOpen, onChange, selectedOptions, pinnedOptions])

    const onApply = useCallback(() => {
      onChange(Object.values(selectedOptions))
      setOpen.off()
    }, [onChange, setOpen, selectedOptions])

    const onSelectOption = useCallback((_, option) => {
      setSelectedOptions((prevOptions) =>
        mapSelectedOptions(prevOptions, option)
      )
    }, [])

    const renderOptions = useCallback(
      (option) => {
        const { label, value, disabled } = option
        const selectedOption = selectedOptions[value]
        return (
          <MenuItem
            key={value}
            className={classes.item}
            disabled={disabled}
            onClick={(event) => onSelectOption(event, option)}
          >
            <ListItemIcon>
              <Checkbox
                className={classes.checkbox}
                checked={Boolean(selectedOption)}
                disabled={disabled}
              />
            </ListItemIcon>
            <Tooltip title={label}>
              <ListItemText
                primary={label}
                classes={{ primary: baseClasses.baseText }}
              />
            </Tooltip>
          </MenuItem>
        )
      },
      [
        onSelectOption,
        selectedOptions,
        classes.item,
        classes.checkbox,
        baseClasses.baseText
      ]
    )

    const labelRenderer = useCallback(
      ({ placeholder, prefixLabel }) => {
        if (isEmpty(selectedOptions)) {
          return (
            <Text
              text={placeholder}
              variant={TEXT_VARIANTS.subtitle1}
              className={classes.placeholder}
            />
          )
        }
        const displayText = getOptionLabels(selectedOptions)
        const displayTitle = getOptionTitle(selectedOptions)
        return (
          <Tooltip title={displayTitle}>
            <div className={classes.labelContainer}>
              <Text
                text={prefixLabel}
                variant={TEXT_VARIANTS.subtitle1}
                className={classes.prefixLabel}
              />
              <Text
                text={displayText}
                variant={TEXT_VARIANTS.subtitle1}
                className={classes.label}
              />
            </div>
          </Tooltip>
        )
      },
      [
        selectedOptions,
        classes.labelContainer,
        classes.prefixLabel,
        classes.label,
        classes.placeholder
      ]
    )

    const renderOptionsFooter = useMemo(() => {
      return (
        <div className={classes.buttonGroup}>
          <Button
            className={clsx(classes.modalButton, classes.clearButton)}
            onClick={onClear}
            size={size}
            disabled={disableClearButton}
          >
            Clear
          </Button>
          <Button
            className={clsx(classes.modalButton, classes.primary)}
            onClick={onApply}
            size={size}
            disabled={disableApplyButton}
          >
            Apply
          </Button>
        </div>
      )
    }, [
      classes.buttonGroup,
      classes.modalButton,
      classes.clearButton,
      classes.primary,
      onClear,
      size,
      onApply,
      disableApplyButton,
      disableClearButton
    ])

    const classNames = useMemo(() => {
      return clsx(classes.button, {
        [classes.buttonFilled]: !isEmpty(selectedOptions),
        [_className]: !!_className
      })
    }, [
      classes.button,
      classes.buttonFilled,
      selectedOptions,
      _className
    ])

    return (
      <SelectComponent
        {...props}
        size={size}
        open={open}
        disabled={disabled}
        options={options}
        setOpen={setOpen}
        onClose={onCloseHandler}
        className={classNames}
        pinnedOptions={pinnedOptions}
        labelRenderer={_labelRenderer || labelRenderer}
        optionsFooter={renderOptionsFooter}
        optionsRenderer={renderOptions}
      />
    )
  }

  SelectWithCheckbox.propTypes = {
    size: PropTypes.string,
    labelRenderer: PropTypes.node,
    onChange: PropTypes.func,
    useSearchInput: PropTypes.bool,
    selectedOptions: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    options: PropTypes.array,
    disabled: PropTypes.bool,
    defaultSelectedOptions: PropTypes.object,
    disableApplyButton: PropTypes.bool,
    disableClearButton: PropTypes.bool,
    pinnedOptions: PropTypes.array,
    className: PropTypes.string,
    onClose: PropTypes.func
  }

  SelectWithCheckbox.defaultProps = {
    defaultSelectedOptions: {},
    selectedOptions: undefined,
    size: BUTTON_SIZES.small,
    pinnedOptions: [],
    onClose: noop
  }

  return SelectWithCheckbox
}

withCheckbox.propTypes = {
  SelectComponent: PropTypes.node
}

export default withCheckbox
