import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import Select from '../../molecules/Select'
import FirmReportName from './FirmReportName'

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: theme.typography.fontSizes.h2
  },
  description: {
    fontSize: theme.typography.fontSizes.xl,
    fontWeight: theme.typography.weights.light,
    lineHeight: '20px',
    '& .MuiTypography-root': {
      fontSize: theme.typography.fontSizes.xl,
      fontWeight: theme.typography.weights.light,
      lineHeight: '20px'
    }
  }
}))

function FirmReportTitle ({ configuration, selected, onSelect }) {
  const classes = useStyles()
  const hasFilters = useMemo(() => configuration.filters?.length > 0, [configuration])
  const options = useMemo(() => {
    const options = configuration.filters?.map(f => ({
      label: f.name,
      value: f.firmReportFilterId
    })) || []
    if (options.length) {
      options.splice(0, 0, { label: configuration.report.description, value: null })
    }
    return options
  }, [configuration])

  if (hasFilters) {
    return (
      <div>
        <FirmReportName configuration={configuration} />
        <Select
          value={selected}
          onChange={onSelect}
          className={classes.description}
          options={options}
          placeholder={configuration.report.description}
        />
      </div>
    )
  }

  return (
    <div>
      <FirmReportName configuration={configuration} />
      <div className={classes.description}>{configuration.report.description}</div>
    </div>
  )
}

FirmReportTitle.propTypes = {
  configuration: PropTypes.shape({
    report: PropTypes.object,
    filters: PropTypes.array
  }).isRequired,
  selected: PropTypes.string,
  onSelect: PropTypes.func
}

export default FirmReportTitle
