import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Box, Divider, Grid, makeStyles } from '@material-ui/core'
import { useTheme } from '@material-ui/styles'

import { head } from 'lodash'

import Text from '../../../atoms/Text'
import Avatar from '../../../atoms/Avatar'
import Skeleton from '../../../atoms/Skeleton'
import { useAccountStatusTotals, useAccountStatusDetails } from '../hooks/accountStatus'

import { SKELETON_VARIANTS } from '../../../../constants'

const useStyles = makeStyles((theme) => ({
  accountStatusPreview: {
    display: 'flex',
    alignItems: 'center',
    padding: '1rem 1rem',
    justifyContent: 'space-between',
    '& hr': {
      margin: theme.spacing(0, 4)
    }
  }
}))

const TitleSubtitle = ({ title, subtitle, color }) => (
  <Box display='flex' flexDirection='column' alignItems='center'>
    <Text
      text={title}
      lineHeight='1'
      variant='body2'
      customFontWeight='bold'
      customFontSize='1.625rem'
      color={color}
    />
    <Text
      text={subtitle}
      variant='body2'
      customFontWeight='normal'
      customFontSize='1rem'
    />
  </Box>
)

const isNotZero = (value) => Number(value) > 0

const AccountStatusPreview = ({ title, dataSourceIds, showAvatar }) => {
  const classes = useStyles()
  const theme = useTheme()

  const { error, loading, result } = useAccountStatusTotals(dataSourceIds)
  const { data: accounts, loading: loadingAccounts } = useAccountStatusDetails(dataSourceIds, {}, {
    pageSize: 1
  })

  const isLoading = loadingAccounts || loading

  const custodian = useMemo(() => {
    const accountDetails = head(accounts) || []
    const custodian = head(accountDetails?.custodian) || []

    return {
      name: custodian?.name || '',
      imageUrl: custodian?.dataSourceImageUrl
    }
  }, [accounts])

  const titleWithMessage = useMemo(() => {
    if (error) return 'Something went wrong'
    if (loading) return 'Loading'

    return title
  }, [error, loading, title])

  const LoadingScreen = () => (
    <Box display='flex' flexDirection='row' height='100%' alignItems='center'>
      <Grid xs={2}>
        <Box display='flex' alignItems='center' justifyContent='center' width='100%'>
          <Skeleton
            variant={SKELETON_VARIANTS.circle}
            width='3rem'
            height='3rem'
          />
        </Box>
      </Grid>
      <Grid xs={10}>
        <Box display='flex' alignItems='center' justifyContent='center' width='100%'>
          <Skeleton
            variant={SKELETON_VARIANTS.text}
            width='100%'
            height='80px'
          />
        </Box>
      </Grid>
    </Box>
  )

  if (isLoading) {
    return <LoadingScreen />
  }

  return (
    <Box className={classes.accountStatusPreview} height='100%' width='100%' mb={0}>
      {
        showAvatar && (
          <Box>
            <Avatar
              customSize='3rem'
              src={custodian.imageUrl}
              isPublicResource
              avatarLetters={custodian.name}
            />
          </Box>
        )
      }
      <Box>
        <Text variant='body2' customFontSize='1.5rem'>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <span style={{ marginLeft: '1rem' }}>{titleWithMessage}</span>
          </div>
        </Text>
      </Box>
      <Box display='flex'>
        <TitleSubtitle
          title={result.current.total.toString()}
          subtitle='Current'
          color={isNotZero(result.current.total) ? theme.palette.black : theme.palette.gray.A700}
        />
        <Divider orientation='vertical' flexItem />
        <TitleSubtitle
          title={result.stale.total.toString()}
          subtitle='Not Current'
          color={isNotZero(result.stale.total) ? theme.palette.error.primary : theme.palette.gray.A700}
        />
      </Box>
    </Box>
  )
}

AccountStatusPreview.propTypes = {
  title: PropTypes.string,
  dataSourceIds: PropTypes.arrayOf(PropTypes.number),
  showAvatar: PropTypes.bool
}

TitleSubtitle.propTypes = {
  title: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  subtitle: PropTypes.string,
  color: PropTypes.string
}

AccountStatusPreview.defaultProps = {
  title: 'Account Status',
  dataSourceIds: [],
  showAvatar: false
}

export default AccountStatusPreview
