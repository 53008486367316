import React from 'react'
import PropTypes from 'prop-types'
import { useRecentClients } from '../../../../../api/clients'

const RecentClientsProvider = ({ take, children }) => {
  const {
    data,
    isLoading,
    error
  } = useRecentClients()

  if (error) {
    return <div>Sorry, there was an error</div>
  }

  if (isLoading) {
    return <div>Loading...</div>
  }

  return children(data?.slice(0, take), { isLoading, error })
}

RecentClientsProvider.propTypes = {
  take: PropTypes.number,
  children: PropTypes.func.isRequired
}

RecentClientsProvider.defaultProps = {
  take: 6
}

export default RecentClientsProvider
