import React from 'react'
import PropTypes from 'prop-types'
import { Divider, makeStyles } from '@material-ui/core'
import Text from '../../../atoms/Text'

const useStyles = makeStyles(() => ({
  container: {
    padding: '1rem'
  },
  title: {
    fontWeight: 'bold',
    textTransform: 'uppercase',
    paddingBottom: '1rem',
    color: '#898D9B'
  }
}))

const ClientCategory = ({ title, children }) => {
  const classes = useStyles()

  return (
    <>
      <Divider />
      <div className={classes.container}>
        <Text text={title} className={classes.title} />
        {children}
      </div>
    </>
  )
}

ClientCategory.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node
}

export default ClientCategory
