import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import FadeIn from '../../../../molecules/Transitions/FadeIn'
import { ADMIN_ROUTES } from '../../../../../constants'
import UserListContextProvider from './UserListContext'
import UsersTableWrapper from './tableWrapper'
import UsersTable from './table'
import { defaultColumnConfig } from './columnConfig'

function UsersListTab ({ urlFiltersMap }) {
  const history = useHistory()
  const onClickRow = useCallback((row) => {
    if (row.original) {
      history.push(`${ADMIN_ROUTES.WEALTH_OWNERS}/${row.original.externalUserId}`)
    }
  }, [history])

  return (
    <FadeIn>
      <UserListContextProvider
        urlFiltersMap={urlFiltersMap}
        defaultColumnConfig={defaultColumnConfig}
      >
        <UsersTableWrapper>
          <UsersTable
            onClickRow={onClickRow}
          />
        </UsersTableWrapper>
      </UserListContextProvider>
    </FadeIn>
  )
}

UsersListTab.propTypes = {
  urlFiltersMap: PropTypes.object
}

UsersListTab.defaultProps = {
  urlFiltersMap: { a: { filterKey: 'groupIds', op: 'eq' } }
}

export default UsersListTab
