import React from 'react'
import { makeStyles, Grid, alpha } from '@material-ui/core'
import clsx from 'clsx'
import PropTypes from 'prop-types'

const useStyles = makeStyles((theme) => ({
  type: {
    outline: `1px solid ${theme.palette.gray.darker}`,
    borderRadius: theme.layout.radius.round,
    padding: theme.layout.padding.thick,
    fontSize: theme.typography.fontSizes.xxl,
    transition: 'outline .2s ease-in-out, background-color .2s ease-in-out',
    '&:hover': {
      backgroundColor: theme.palette.gray.main,
      cursor: 'pointer'
    },
    '&.__selected': {
      outline: `2px solid ${theme.palette.primary.main}`,
      backgroundColor: alpha(theme.palette.selected, 0.15),
      '&:hover': {
        outline: `2px solid ${theme.palette.primary.main}`
      }
    },
    '& article': {
      fontWeight: theme.typography.weights.light
    }
  }
}))

const EnablementSelector = React.forwardRef(function EnablementSelector ({ value, onChange }, _) {
  const classes = useStyles()

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <div
          className={clsx(classes.type, { __selected: value === 'enabled' })}
          onClick={() => onChange('enabled')}
          role='button'
        >
          <header>Enabled</header>
          <article>
            <p>The user will be authorized to use this integration.</p>
          </article>
        </div>
      </Grid>
      <Grid item xs={12}>
        <div
          className={clsx(classes.type, { __selected: value === 'disabled' })}
          onClick={() => onChange('disabled')}
          role='button'
        >
          <header>Disabled</header>
          <article>
            <p>The user will not be authorized to use this integration.</p>
            <p>This doesn't stop the integration from being enabled by role.</p>
          </article>
        </div>
      </Grid>
    </Grid>
  )
})

EnablementSelector.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func
}

export default EnablementSelector
