import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import PropTypes from 'prop-types'

const useStyles = makeStyles(() => ({
  advisorViewLayoutPage: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: 'calc(100vh - 60px)'
  }
}))

const Page = ({ children, className, style }) => {
  const classes = useStyles()
  return (
    <div className={clsx(classes.advisorViewLayoutPage, className)} style={style}>
      {children}
    </div>
  )
}

Page.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  style: PropTypes.object
}

export default Page
