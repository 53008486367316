import KeyMirror from 'keymirror'
import PropTypes from 'prop-types'

const entryType = {
  MILESTONES: 'milestones',
  ACTIVITIES: 'activities',
  MEETINGS: 'meetings'
}

export const entryTypeIdentifier = {
  MILESTONES: 1,
  ACTIVITIES: 2,
  MEETINGS: 3
}

export const ACTIONS = KeyMirror({
  VIEW: null,
  EDIT: null
})

export default entryType

export const entryTypePropType = PropTypes.oneOf(Object.values(entryType))
