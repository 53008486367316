const permissionMapDefaultFalse = {
  edit: false,
  attach: false,
  tasks: false
}

const permissionMapDefaultTrue = {
  edit: true,
  attach: true,
  tasks: true
}

export function parseEntryTypePermissions (userInfo, entryType, policyResult) {
  if (!policyResult) {
    return { ...permissionMapDefaultFalse }
  }
  if (userInfo?.allAccountsAccess) {
    return { ...permissionMapDefaultTrue }
  }
  const userTypeKey = userInfo?.userTypeId === 2 ? 'advisor' : 'wealthOwner'
  const userPermissionMap = entryType?.permissions?.[userTypeKey] ?? permissionMapDefaultFalse
  const defaultPermissionMap = userPermissionMap.default === true ? permissionMapDefaultTrue : permissionMapDefaultFalse
  const effectivePermissionMap = {
    ...defaultPermissionMap,
    ...userPermissionMap
  }

  return effectivePermissionMap
}

export function evaluateEntryTypePermission (userInfo, entryType, key) {
  const p = parseEntryTypePermissions(userInfo, entryType.permissions || {})

  return (key in p) ? p[key] : false
}
