import { camelCase } from 'lodash'

export const mapPinnedOptions = (editablePinnedOptions) => {
  return ({ data }) => {
    return data.reduce((acc, group) => {
      const { groupId, longName, groupType } = group
      const { groupTypeId } = groupType
      if (!groupTypeId) return acc
      return {
        ...acc,
        [groupTypeId]: [
          ...(acc[groupTypeId] ?? []),
          {
            key: groupId,
            value: groupId,
            label: longName,
            disabled: !editablePinnedOptions,
            payload: group
          }
        ]
      }
    }, {})
  }
}

export const getFiltersVisibilityKey = (key) => {
  return `${key}_visible_filters`
}

export const getGroupTypeKey = (groupTypeName) => {
  return `${camelCase(groupTypeName.replace(/ /g, ''))}Ids`
}

export const getGroupIds = (filters) => {
  const { accountIds, ...groupTypeFilters } = filters?.levelFilters ?? {}
  const groupIds = Object.values(groupTypeFilters).reduce((acc, groupIds) => {
    return [...acc, ...groupIds]
  }, [])
  return groupIds
}
