import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '32px'
  },
  filtersContainer: {
    display: 'flex',
    gridGap: '16px',
    justifyContent: 'space-between',
    position: 'sticky',
    top: 0,
    background: '#fff',
    padding: '16px 0',
    zIndex: 100
  }
}))

export default useStyles
