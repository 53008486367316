import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import { makeStyles, Tooltip } from '@material-ui/core'
import { useAppContext, useSetAppContext } from '../../redux/slices/appContext'
import AvailableDatePicker from '../molecules/AvailableDatePicker'
import { getSafeDate } from '../../utils'
import { useSetViewContext } from '../../redux/slices/viewContext'

const useStyles = makeStyles(() => ({
  container: {
    width: '10.5rem',
    minWidth: 'unset'
  },
  picker: {
    width: '10.5rem',
    minWidth: 'unset',
    padding: '0.5rem',
    borderRadius: '0.25rem',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)'
    },
    '& input': {
      paddingBottom: '0 !important'
    }
  }
}))

const AsOfDateSelector = ({
  extraStyles,
  dateFormat,
  tooltip,
  shouldDisableWeekends,
  shouldDisableHolidays,
  readOnly
}) => {
  const classes = useStyles()
  const { availableDates } = useAppContext()
  const setAppContext = useSetAppContext()
  const setViewContext = useSetViewContext()

  const setAsOfDate = useCallback((date) => {
    const mainDate = dayjs(date).format('YYYY-MM-DD')
    setAppContext({
      availableDates: {
        ...availableDates,
        mainDate
      }
    })
    const availableDatesModified = {
      min: availableDates.min,
      max: mainDate,
      mainDate
    }
    setViewContext({
      minClientDate: getSafeDate(availableDatesModified, {
        useMin: true
      }),
      maxClientDate: getSafeDate(availableDatesModified, {
        useMax: true
      }),
      mainDate: dayjs(date).format('YYYY-MM-DD')
    })
  }, [setAppContext, setViewContext, availableDates])

  if (tooltip) {
    return (
      <Tooltip title={tooltip}>
        <div className={classes.container}>
          <AvailableDatePicker
            value={availableDates.mainDate}
            extraStyles={extraStyles}
            disableFuture
            onChange={setAsOfDate}
            type='date'
            format={dateFormat}
            className={classes.picker}
            shouldDisableHolidays={shouldDisableHolidays}
            shouldDisableWeekends={shouldDisableWeekends}
            disableCalendar={readOnly}
          />
        </div>
      </Tooltip>
    )
  }

  return (
    <AvailableDatePicker
      value={availableDates.mainDate}
      extraStyles={extraStyles}
      disableFuture
      onChange={setAsOfDate}
      type='date'
      format={dateFormat}
      className={classes.picker}
      disableCalendar={readOnly}
    />
  )
}

AsOfDateSelector.propTypes = {
  extraStyles: PropTypes.object,
  dateFormat: PropTypes.string,
  tooltip: PropTypes.string,
  shouldDisableWeekends: PropTypes.bool,
  shouldDisableHolidays: PropTypes.bool,
  readOnly: PropTypes.bool
}

AsOfDateSelector.defaultProps = {
  extraStyles: {
    fontSize: '1rem',
    minWidth: '16rem',
    fontFamily: 'Gotham-Book',
    '& input': {
      textAlign: 'left'
    }
  },
  dateFormat: '[As of] MMM DD, YYYY',
  tooltip: undefined,
  shouldDisableWeekends: true,
  shouldDisableHolidays: true,
  readOnly: false
}

export default AsOfDateSelector
