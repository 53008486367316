import React from 'react'
import { useParams } from 'react-router-dom'
import { makeStyles } from '@material-ui/core'
import FadeIn from '../../../molecules/Transitions/FadeIn'
import FirmReport from '../../firmReporting/FirmReport'
import { useSearchParam } from '../../../../hooks/useSearchParam'

const useStyles = makeStyles((theme) => ({
  firmReporting: {
    padding: '5px 2rem'
  }
}))

function FirmReporting () {
  const classes = useStyles()
  const { reportId } = useParams()
  const filter = useSearchParam('filter')

  return (
    <FadeIn className={classes.firmReporting}>
      <FirmReport reportId={reportId} defaultFilter={filter} />
    </FadeIn>
  )
}

export default FirmReporting
