import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import SydButton from '../../../../commonDesign/Button'
import FadeIn from '../../../../molecules/Transitions/FadeIn'

const useStyles = makeStyles((theme) => ({
  inputTable: {
    borderCollapse: 'collapse',
    width: '100%',
    '& td': {
      border: `1px solid ${theme.palette.gray.main}`
    },
    '& th': {
      backgroundColor: theme.palette.gray.light,
      borderBottom: `1px solid ${theme.palette.gray.darker}`,
      textAlign: 'left',
      minWidth: '150px'
    },
    '& input': {
      width: '100%',
      border: 'none',
      borderRadius: '0px'
    }
  },
  rowHeader: {
    backgroundColor: theme.palette.gray.light,
    minWidth: 'unset !important'
  },
  overflowy: {
    overflowX: 'auto'
  },
  tableHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    fontSize: theme.typography.fontSizes.xl,
    padding: '20px 0 10px'
  },
  actions: {
    '& > *': {
      marginLeft: '10px'
    }
  }
}))

function getDataValues (prev) {
  const data = prev.data.map((row, index) => {
    const rowResult = prev.columns.map(cn => {
      const el = document.getElementById(`tbl_row_${index}_col_${cn}`)
      return { cn, value: el.value }
    })
    const projected = rowResult.reduce((agg, { cn, value }) => {
      agg[cn] = value
      return agg
    }, {})
    return { ...row, ...projected }
  })

  return {
    ...prev,
    data
  }
}

function resetDataValues (prev) {
  prev.data.forEach((row, index) => {
    prev.columns.forEach(cn => {
      const el = document.getElementById(`tbl_row_${index}_col_${cn}`)
      el.value = row[cn]
    })
  })
}

function InputTable ({ data, title, onChange, onEditChange }) {
  const classes = useStyles()
  const [editing, setEditing] = useState(false)

  const toggleEdit = useCallback(() => {
    setEditing(!editing)
    onEditChange(!editing)
    if (editing && onChange) {
      onChange(getDataValues(data))
    }
  }, [editing, setEditing, onChange, data, onEditChange])

  const cancelEdit = useCallback(() => {
    setEditing(false)
    onEditChange(false)
    resetDataValues(data)
  }, [setEditing, onEditChange, data])

  return (
    <div>
      <div className={classes.tableHeader}>
        <span>{title}</span>
        {!editing ? (
          <FadeIn className={classes.actions} key='not-editing'>
            <SydButton
              size='xs'
              icon='edit'
              onClick={toggleEdit}
            >
              Edit
            </SydButton>
          </FadeIn>
        ) : null}
        {editing ? (
          <FadeIn className={classes.actions} key='editing'>
            <SydButton
              size='xs'
              variant='secondary'
              icon='close'
              onClick={cancelEdit}
            >
              Cancel
            </SydButton>
            <SydButton
              size='xs'
              variant='primary'
              icon='check'
              onClick={toggleEdit}
            >
              Save
            </SydButton>
          </FadeIn>
        ) : null}
      </div>
      <div className={classes.overflowy}>
        <table className={classes.inputTable}>
          <thead>
            <tr>
              <th className={classes.rowHeader}>Row</th>
              {data.columns.map(cn => (
                <th key={cn}>{cn}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.data.map((d, i) => (
              <tr key={`row${i}`}>
                <td className={classes.rowHeader}>{i + 1}</td>
                {data.columns.map(cn => (
                  <td key={`row${i}_${cn}`}>
                    <input id={`tbl_row_${i}_col_${cn}`} defaultValue={d[cn]} disabled={!editing} />
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

InputTable.propTypes = {
  data: PropTypes.shape({
    data: PropTypes.array,
    columns: PropTypes.array
  }),
  title: PropTypes.any,
  onChange: PropTypes.func,
  onEditChange: PropTypes.func
}

export default InputTable
