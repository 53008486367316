import React from 'react'
import { Grid } from '@material-ui/core'
import FadeIn from '../../../../molecules/Transitions/FadeIn'
import ErrorBoundary from '../../../../molecules/ErrorBoundary'
import PersonalSpace from '../../shared/PersonalSpace'
import ClientAccountGroups from './ClientAccountGroups'
import ClientGroups from './ClientGroups'

function TagsTab () {
  return (
    <ErrorBoundary name='Tags Tab'>
      <FadeIn>
        <Grid container>
          <Grid item md={6} xs={12} lg={4}>
            <ClientGroups singleValue title='Single-Option Tags' />
          </Grid>
          <Grid item md={6} xs={12} lg={4}>
            <ClientGroups multiValue title='Multi-Select Tags' />
          </Grid>
          <Grid item md={6} xs={12} lg={4}>
            <ClientAccountGroups />
          </Grid>
        </Grid>
      </FadeIn>
      <PersonalSpace />
    </ErrorBoundary>
  )
}

TagsTab.propTypes = {
}

export default TagsTab
