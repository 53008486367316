import { useQuery } from '@tanstack/react-query'
import { useAppContext } from '../../../redux/slices/appContext'
import { QUERY_KEYS } from '../../../api/queryKeys'
import { postNamedQuery } from '../../../service'

/**
 * Normalize The Full VBS Info
 * @param params {VisualBalanceQueryParams}
 * @param options {*}
 * @return {*}
 */
export const useVisualBalanceQuery = (params, options = {}) => {
  const { userId } = useAppContext()
  const { enabled = true, mapper = null } = options

  return useQuery({
    enabled,
    queryKey: [QUERY_KEYS.visualBalanceSheet, userId, params],
    queryFn: async () => {
      const { data } = await postNamedQuery('coreData', 'getVisualBalanceSheetData', params)
      return mapper ? mapper(data) : data
    }
  })
}

/**
 * Get Client Data
 * @param options {*}
 * @return {*}
 */
export const useGetClientQuery = (options = {}) => {
  const { userId, clientId } = useAppContext()
  const { enabled = true } = options

  return useQuery({
    enabled,
    queryKey: [QUERY_KEYS.visualBalanceSheet, userId, clientId],
    queryFn: async () => {
      const response = await postNamedQuery('levels', 'get-client-by-id', {
        clientId,
        resolveAvatar: true
      })

      return response.data.client
    }
  })
}

/**
 * Get Holdings Data
 * @param params {*}
 * @param options {*}
 * @return {*}
 */
export const useGetHoldingsData = (params = {}, options = {}) => {
  const { userId } = useAppContext()
  const { enabled = true, mapper = null } = options

  return useQuery({
    enabled,
    queryKey: [QUERY_KEYS.visualBalanceSheet, userId, params],
    queryFn: async () => {
      const { data } = await postNamedQuery('coreData', 'getGroupedCoreData', params)
      return mapper ? mapper(data) : data
    }
  })
}
