import { useMemo } from 'react'
import { FEATURE_FLAG } from '../../../constants'
import { useFeatureFlag } from '../../../redux/slices/appConfig'
import { useAppContext } from '../../../redux/slices/appContext'

/**
 * Determines if conversations
 * @return {boolean}
 */
export function useIsConversationsActive (otherwise = true) {
  const { active } = useFeatureFlag(FEATURE_FLAG.CONVERSATIONS)
  const { isSummitUser } = useAppContext()

  return useMemo(() => (active || isSummitUser) && otherwise, [active, isSummitUser, otherwise])
}
