import React from 'react'
import { useCurrentTab } from '../../../organisms/AdvisorView/components/AdvisorViewTabs'
import { ADMIN_ROUTES } from '../../../../constants'
import { useCheckPolicy } from '../../../../hooks'
import SydLinkButton from '../../../commonDesign/LinkButton'
import FadeIn from '../../../molecules/Transitions/FadeIn'

function HeaderActions () {
  const tabValue = useCurrentTab()
  const canEditBulkManual = useCheckPolicy('admin_manual_accounts')
  const canEditManagers = useCheckPolicy('admin_edit_managers')

  if (tabValue === 'accounts') {
    return canEditBulkManual ? (
      <FadeIn>
        <SydLinkButton
          size='md'
          variant='outline'
          to='/admin/manual-accounts'
          icon='link'
        >
          Manual Accounts
        </SydLinkButton>
      </FadeIn>
    ) : null
  }
  if (tabValue === 'managers' && canEditManagers) {
    return (
      <FadeIn>
        <SydLinkButton
          size='md'
          variant='primary'
          to={ADMIN_ROUTES.NEW_MANAGER}
          icon='add'
        >
          Add Manager
        </SydLinkButton>
      </FadeIn>
    )
  }

  return null
}

export default HeaderActions
