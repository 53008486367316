import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@material-ui/core'
import { Controller, useForm } from 'react-hook-form'
import SydInput from '../../../commonDesign/SydInput'
import SydLabel, { hookFormErrorAdapter as errorAdapter } from '../../../commonDesign/SydLabel'
import { useEditMyProfileMutation } from '../../../../api/users'
import SnackAlert from '../../../molecules/SnackAlert/SnackAlert'
import SaveCancelFooter from './SaveCancelFooter'
import ProfilePicture from './ProfilePicture'

const useSubmitter = (handleSubmit, reset) => {
  const { mutateAsync: update } = useEditMyProfileMutation()
  const [submitState, setSubmitState] = useState({ processing: false })
  const [alert, setAlert] = useState({})
  const onSubmit = useCallback(async (e) => {
    const onSuccess = async (formData) => {
      setSubmitState({ processing: true })
      try {
        const updateResult = await update(formData)
        const profile = updateResult.user
        reset({
          userId: profile.userId,
          externalUserId: profile.externalUserId,
          firstName: profile.firstName,
          lastName: profile.lastName,
          email: profile.email,
          avatarMediaId: profile.avatarMediaId
        })
        setAlert({
          openAlert: true,
          alertMessage: 'Your profile has been updated',
          alertSeverity: 'success'
        })
      } catch (err) {
        console.error(err)
        setAlert({
          openAlert: true,
          alertMessage: 'There was a problem saving your profile information',
          alertSeverity: 'error'
        })
      } finally {
        setSubmitState({ processing: false })
      }
    }

    const onError = (errors) => {
      // eslint-disable-next-line no-throw-literal
      throw { validationErrors: errors }
    }
    const handler = handleSubmit(onSuccess, onError)
    await handler(e)
  }, [handleSubmit, update, setSubmitState, reset, setAlert])

  return {
    submitState,
    onSubmit,
    alert
  }
}

function UserProfileForm ({ profile }) {
  const form = useForm({
    mode: 'onChange',
    defaultValues: {
      ...profile
    }
  })
  const { submitState, onSubmit, alert } = useSubmitter(form.handleSubmit, form.reset)

  return (
    <>
      <Grid container spacing={2}>
        <Grid item md={2} xs={12}>
          <Grid container direction='column' alignItems='center' justifyContent='center'>
            <ProfilePicture profile={profile} />
          </Grid>
        </Grid>
        <Grid item md={10} xs={12}>
          <Grid container spacing={4}>
            <Grid item md={6} xs={12}>
              <Controller
                name='firstName' control={form.control} rules={{ required: 'Required' }}
                render={({ field, fieldState }) => (
                  <SydLabel label='First Name' required error={errorAdapter(form, fieldState)}>
                    <SydInput
                      autoComplete='off'
                      placeholder='First Name'
                      ref={field.ref}
                      onChange={field.onChange}
                      onBlur={field.onBlur}
                      name={field.name}
                      value={field.value}
                      disabled={submitState.processing}
                    />
                  </SydLabel>
                )}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <Controller
                name='lastName' control={form.control} rules={{ required: 'Required' }}
                render={({ field, fieldState }) => (
                  <SydLabel label='Last Name' required error={errorAdapter(form, fieldState)}>
                    <SydInput
                      autoComplete='off'
                      placeholder='Last Name'
                      ref={field.ref}
                      onChange={field.onChange}
                      onBlur={field.onBlur}
                      name={field.name}
                      value={field.value}
                      disabled={submitState.processing}
                    />
                  </SydLabel>
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <SydLabel label='Email'>
                <SydInput
                  autoComplete='username'
                  iconName='mail'
                  name='email'
                  placeholder='Email'
                  disabled
                  defaultValue={profile.email}
                />
              </SydLabel>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {form.formState.isDirty ? (
        <SaveCancelFooter
          onCancel={() => form.reset({ keepDefaultValues: true })}
          onSave={onSubmit}
          processing={submitState.processing}
          disabled={!form.formState.isValid}
        />
      ) : null}
      <SnackAlert alert={alert} />
    </>
  )
}

UserProfileForm.propTypes = {
  profile: PropTypes.shape({
    userId: PropTypes.number,
    externalUserId: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    avatarMediaId: PropTypes.number
  })
}

export default UserProfileForm
