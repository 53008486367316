import { useMemo } from 'react'
import { useAvailableDates } from '../../../../redux/slices/appContext'
import { useNormalizeDates } from '../../../../api/coreData'

export const useLastBusinessDate = (availableDatesOverride) => {
  const [availableDates, isLoadingAvailableDates] = useAvailableDates()

  const levelDates = useMemo(() => {
    return availableDatesOverride || availableDates
  }, [availableDatesOverride, availableDates])

  const lastBusinessDayQuery = useMemo(() => {
    return {
      query: {
        dateRanges: {
          lastBusinessDate: {
            startDate: levelDates.min,
            endDate: levelDates.max
          }
        }
      },
      queryOptions: {
        mapper: (data) => {
          return data?.lastBusinessDate ?? {}
        },
        enabled: !isLoadingAvailableDates || !!levelDates
      }
    }
  }, [isLoadingAvailableDates, levelDates])

  const { data: lastBusinessDate, isLoading } = useNormalizeDates(
    lastBusinessDayQuery.query,
    lastBusinessDayQuery.queryOptions
  )

  return {
    lastBusinessDate,
    isLoading: isLoading || isLoadingAvailableDates
  }
}
