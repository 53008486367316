import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  mappingTable: {
    width: '100%',
    fontWeight: '200',
    '& > thead > tr > th': {
      textAlign: 'left'
    }
  }
}))

const TargetMappingTable = ({ target }) => {
  const classes = useStyles()
  const targetColumns = target?.columns

  if (!target) return null

  if (!target.columns?.length) {
    return (
      <div>No Columns</div>
    )
  }

  return (
    <table className={classes.mappingTable}>
      <thead>
        <tr>
          <th>Column</th>
          <th>Type</th>
          <th>Null</th>
          <th>Min</th>
          <th>Max</th>
          <th>Default</th>
          <th>Truncate</th>
          <th>Is Key</th>
        </tr>
      </thead>
      <tbody>
        {targetColumns.map((tf, index) => (
          <tr key={`fm_${tf.columnName}`}>
            <td>{tf.columnName}</td>
            <td>{tf.dataType}</td>
            <td>{tf.nullable ? 'Y' : 'N'}</td>
            <td>{tf.min}</td>
            <td>{tf.max}</td>
            <td>{tf.defaultValue}</td>
            <td>{tf.truncate ? 'Y' : 'N'}</td>
            <td>{tf.isKey ? 'Y' : 'N'}</td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}

TargetMappingTable.propTypes = {
  target: PropTypes.shape({
    columns: PropTypes.arrayOf(PropTypes.shape({
      columnName: PropTypes.string,
      dataType: PropTypes.string,
      nullable: PropTypes.bool,
      min: PropTypes.number,
      max: PropTypes.number,
      defaultValue: PropTypes.string,
      truncate: PropTypes.bool,
      isKey: PropTypes.bool
    }))
  })
}

export default TargetMappingTable
