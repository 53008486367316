import React from 'react'
import { Container, Grid, makeStyles } from '@material-ui/core'
import Skeleton from '../../../../atoms/Skeleton'
import { SKELETON_VARIANTS } from '../../../../../constants'

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '1.5rem',
    height: '100%'
  },
  start: {
    marginLeft: '-16px'
  },
  infoContainer: {
    marginTop: '3rem'
  }
}))

const BlendedSkeleton = () => {
  const classes = useStyles()

  return (
    <Container fixed maxWidth={false} className={classes.container}>
      <Grid container spacing={2} className={classes.infoContainer}>
        <Grid item md={10} xs={12}>
          <Grid container spacing={4}>
            <Grid item md={6} xs={12} className={classes.start}>
              <Skeleton
                variant={SKELETON_VARIANTS.text}
                width='100%'
                height='88px'
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <Skeleton
                variant={SKELETON_VARIANTS.text}
                width='100%'
                height='88px'
              />
            </Grid>
          </Grid>
        </Grid>
        {new Array(4).fill(0).map((_, index) => {
          return (
            <React.Fragment key={`skeleton-${index}`}>
              <Grid container spacing={2}>
                <Grid container xs={12} spacing={1}>
                  <Grid item md={7} xs={12}>
                    <Skeleton
                      variant={SKELETON_VARIANTS.text}
                      width='100%'
                      height='230px'
                    />
                  </Grid>
                </Grid>
              </Grid>
            </React.Fragment>
          )
        })}
      </Grid>
    </Container>
  )
}

export default BlendedSkeleton
