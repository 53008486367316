import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles((theme) => ({
  table: {
    borderCollapse: 'collapse',
    border: '1px solid #D6D6D6',
    width: '100%'
  },
  thead: {
    top: '70px',
    zIndex: 100
  },
  headerCell: {
    background: '#EDEDED',
    padding: '7px 14px',
    border: '1px solid #D6D6D6',
    fontSize: '12px',
    textAlign: 'left',
    '& span': {
      fontWeight: 400,
      fontFamily: 'GothamPro-Bold'
    }
  },
  headerCellInternal: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  sortIcon: {
    height: '14px',
    width: '14px'
  },
  bodyRow: {
    fontSize: '14px',
    fontWeight: 400,
    '&.__expandable': {
      fontFamily: 'GothamPro-Bold'
    },
    '&.__child': {
      fontFamily: 'GothamPro',
      fontSize: '12px',
      backgroundColor: '#FAFAFA',
      '& td:first-child': {
        paddingLeft: '36px'
      }
    },
    '&.__disabled': {
      fontWeight: 300,
      color: '#AAAAAA'
    },
    '&.__updating': {
      color: '#aaa'
    }
  },
  bodyCell: {
    padding: '.5em 14px'
  },
  expandContainer: {
    display: 'flex',
    alignItems: 'center',
    gridGap: '8px'
  },
  expandHandle: {
    width: '16px',
    height: '16px'
  },
  footerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    padding: '16px 0'
  },
  paginationContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    gridGap: '16px'
  },
  pageSelectContainer: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '12px',
    color: '#666666',
    gridGap: '8px',
    fontWeight: 700
  },
  totalCount: {
    color: '#888888',
    fontWeight: 300
  }

}))

export default useStyles
