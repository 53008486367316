import React, { useMemo } from 'react'
import isString from 'lodash/isString'
import { Grid, Typography } from '@material-ui/core'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { TEXT_VARIANTS } from '../../../../constants'

const useStyles = makeStyles((theme) => ({
  largeTitle: {
    marginBottom: '0',
    fontWeight: 500,
    fontSize: '19px'
  }
}))

const IntegrationsGroup = ({ children, title, display }) => {
  const classes = useStyles()

  const largeTextProps = useMemo(() => ({
    variant: TEXT_VARIANTS.h1,
    className: classes.largeTitle
  }), [classes.largeTitle])

  return display && (
    <Grid container spacing={3} style={{ marginBottom: '40px' }}>
      <Grid container style={{ paddingLeft: '15px' }}>
        {isString(title)
          ? <Typography {...largeTextProps}>{title}</Typography>
          : title}
      </Grid>
      {children}
    </Grid>
  )
}

IntegrationsGroup.propTypes = {
  children: PropTypes.node,
  display: PropTypes.bool,
  title: PropTypes.any
}

IntegrationsGroup.defaultProps = {
  children: undefined,
  display: true,
  title: ''
}

export default IntegrationsGroup
