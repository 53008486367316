import React, { useCallback, useMemo, useState, useEffect } from 'react'
import { Box, FormLabel, InputBase, makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import clsx from 'clsx'
import noop from 'lodash/noop'
import { useTheme } from '@material-ui/styles'
import isEmpty from 'lodash/isEmpty'
import { BUTTON_VARIANT, BUTTON_SIZES } from '../../constants'
import RoundedButton from '../atoms/RoundedButton'
import Text from '../atoms/Text'
import { tagOptions } from '../../prop-types'
import { getTags } from '../../service'
import { useToggle } from '../../hooks'
import ListItem from '../molecules/ListItem'
import Icon from '../atoms/Icon'
import InputSearchTagChip from './InputSearchTagChip'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: '2.5rem',
    backgroundColor: theme.palette.white
  },
  buttonGroup: {
    marginTop: '1rem',
    display: 'flex',
    '& > :nth-child(1)': {
      margin: '0 5px 0 0'
    },
    '& > :nth-child(2)': {
      margin: '0 0 0 5px'
    }
  },
  label: {
    color: theme.palette.primary.main,
    fontSize: '0.65rem',
    fontWeight: 'bold',
    lineHeight: '2rem'
  },
  formGroup: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '1rem'
  },
  title: {
    color: theme.palette.primary.main,
    fontSize: '1rem',
    fontWeight: 'bold'
  },
  inputError: {
    border: `1px solid ${theme.palette.error.main} !important`
  },
  inputRoot: {
    fontSize: '1rem',
    backgroundColor: theme.palette.background.paper,
    padding: '1rem',
    borderRadius: '0.25rem'
  },
  readOnly: {
    cursor: 'not-allowed !important'
  },
  input: {
    padding: '0 !important',
    '&::placeholder': {
      color: theme.palette.cadetBlue,
      fontSize: '0.75rem',
      fontWeight: 'bold'
    }
  },
  chipArray: {
    height: 'unset !important',
    minHeight: '3.1875rem',
    padding: '1rem !important',
    backgroundColor: theme.palette.background.paper
  },
  infoContainer: {
    marginBottom: '2rem'
  },
  infoList: {
    display: 'flex',
    flexDirection: 'column'
  },
  listItem: {
    justifyContent: 'flex-start'
  }
}))

const DocumentVaultEditDocumentForm = ({
  title,
  isLoading,
  clientId,
  onCancel,
  readOnly,
  defaultValues,
  documentDetails,
  containerClassName,
  tags: defaultTags,
  onSubmit: onCustomSubmit
}) => {
  const classes = useStyles()
  const theme = useTheme()
  const [tags, setTags] = useState([...defaultTags])
  const [tagsLoading, , tagsLoadingToggleOn, tagsLoadingToggleOff] = useToggle(
    true
  )
  const { register, handleSubmit, setValue, formState: { errors } } = useForm({
    defaultValues,
    shouldUnregister: false
  })

  useEffect(() => {
    async function fetchTags () {
      const {
        data: { rows: tags }
      } = await getTags()
      setTags(tags.map(({ id, name }) => ({ id, label: name })))
      tagsLoadingToggleOff()
    }

    try {
      fetchTags()
    } catch (err) {
      console.error(err)
      tagsLoadingToggleOff()
    }
  }, [tagsLoadingToggleOn, tagsLoadingToggleOff])

  const { tagOptions, tagDefaultOptions } = useMemo(() => {
    const tagIds = defaultValues.tags.split(',')
    const tagDefaultOptions = tags
      .filter((tag) => tagIds.includes(tag.id))
      .map((tag) => ({ ...tag, value: true }))

    const tagOptions = tags.map((tag) => ({
      ...tag,
      value: tagIds.includes(tag.id)
    }))
    return {
      tagOptions,
      tagDefaultOptions
    }
  }, [defaultValues.tags, tags])

  const onEditTagsHandler = useCallback(
    (tags) => {
      const tagValue = tags.map((tag) => tag.id).join(',')
      setValue('tags', tagValue, { shouldValidate: true })
    },
    [setValue]
  )

  const onAddTag = useCallback(
    (tag) => setTags((prevState) => [...prevState, { ...tag }]),
    []
  )

  const onSubmit = useCallback(
    (data) => {
      const { id, fileName, tags: tagsValue } = data
      if (!id) return
      const tagIds = tagsValue.split(',')
      const tagOptions = tags.filter((tag) => tagIds.includes(tag.id))

      onCustomSubmit({ id, fileName, tags: tagOptions })
    },
    [onCustomSubmit, tags]
  )

  const placeHolder = useMemo(() => {
    if (!readOnly) return 'Add Tags'
    return isEmpty(tagOptions) ? 'No Tags to show' : ''
  }, [readOnly, tagOptions])

  return (
    <div
      className={clsx(classes.container, {
        [containerClassName]: !!containerClassName
      })}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box mb={4}>
          <Text className={classes.title} text={title} />
        </Box>
        <div className={classes.formGroup}>
          <input type='hidden' {...register('id')} />
          <FormLabel htmlFor='file-name' className={classes.label}>
            FILE NAME
          </FormLabel>
          <InputBase
            disabled={readOnly}
            placeholder='File Name'
            classes={{
              root: clsx(classes.inputRoot, {
                [classes.inputError]: Boolean(errors.fileName)
              }),
              input: clsx(classes.input, { [classes.readOnly]: readOnly })
            }}
            inputProps={register('fileName', {
              minLength: 2,
              maxLength: 50,
              required: true
            })}
          />
        </div>
        <Box className={classes.formGroup} mb={`${readOnly ? 1 : 2}rem !important`}>
          <FormLabel htmlFor='tags' className={classes.label}>
            TAGS
          </FormLabel>
          <InputSearchTagChip
            clientId={clientId}
            onAddTag={onAddTag}
            options={tagOptions}
            readOnly={readOnly}
            placeHolder={placeHolder}
            onChange={onEditTagsHandler}
            defaultOptions={tagDefaultOptions}
            chipsArrayClassName={classes.chipArray}
          />
        </Box>
        {!isEmpty(documentDetails) && (
          <div className={classes.infoContainer}>
            <Box mt='1.5rem' mb='0.5rem'>
              <FormLabel htmlFor='tags' className={classes.label}>
                INFORMATION
              </FormLabel>
            </Box>
            <div className={classes.infoList}>
              <ListItem
                additionalClasses={classes.listItem}
                leftElement={<Icon name='calendar' variant='h3' roundedBackground={theme.palette.gray.light} />}
                rightElement={<Text variant='caption' text={documentDetails.createdAt} />}
              />
              {!isEmpty(documentDetails.lastViewedBy) && (
                <ListItem
                  additionalClasses={classes.listItem}
                  leftElement={<Icon name='user' variant='h3' roundedBackground={theme.palette.gray.light} />}
                  rightElement={<Text variant='caption' text={documentDetails.lastViewedBy} />}
                />
              )}
              {!isEmpty(documentDetails.size) && (
                <ListItem
                  noBorderBottom
                  additionalClasses={classes.listItem}
                  leftElement={<Icon name='file' variant='h3' roundedBackground={theme.palette.gray.light} />}
                  rightElement={<Text variant='caption' text={documentDetails.size} />}
                />
              )}
            </div>
          </div>
        )}
        <div className={classes.buttonGroup}>
          <RoundedButton
            fullWidth
            onClick={onCancel}
            variant={BUTTON_VARIANT.outlined}
            size={BUTTON_SIZES.medium}
          >
            {readOnly ? 'Close' : 'Cancel'}
          </RoundedButton>
          {!readOnly && (
            <RoundedButton
              primary
              fullWidth
              type='submit'
              disabled={tagsLoading}
              isLoading={isLoading}
              size={BUTTON_SIZES.medium}
              onClick={onSubmit}
            >
              Save
            </RoundedButton>
          )}
        </div>
      </form>
    </div>
  )
}

DocumentVaultEditDocumentForm.propTypes = {
  readOnly: PropTypes.bool,
  title: PropTypes.string,
  isLoading: PropTypes.bool,
  clientId: PropTypes.number.isRequired,
  tags: tagOptions,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  containerClassName: PropTypes.string,
  defaultValues: PropTypes.shape({
    fileName: PropTypes.string,
    tags: PropTypes.string
  }),
  documentDetails: PropTypes.shape({
    size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    createdAt: PropTypes.string,
    lastViewedBy: PropTypes.string
  })
}

DocumentVaultEditDocumentForm.defaultProps = {
  title: 'Edit document info',
  tags: [],
  onSubmit: noop,
  onCancel: noop,
  readOnly: false,
  isLoading: false,
  containerClassName: undefined,
  defaultValues: {
    fileName: '',
    tags: ''
  },
  documentDetails: undefined
}

export default DocumentVaultEditDocumentForm
