import { useMemo } from 'react'
import { useFormattingContext } from '../../../../organisms/FormattingProvider/FormattingContext'

const getPresetColumn = (presetColumns, column) => {
  const columnKey = column.accessor ?? column.id ?? column
  return {
    accessor: columnKey,
    id: columnKey,
    ...(presetColumns.get(columnKey) ?? {}),
    ...(typeof column === 'object' ? { ...column } : {})
  }
}

const getCell = (column, handlers, formatter) => {
  if (column.Cell) {
    return (props) => column.Cell(props, handlers)
  }

  return ({ value }) => formatter(value, column.format)
}

export const useColumns = ({
  format,
  presetColumns,
  columnsConfig,
  handlers = {}
}) => {
  const { formatter } = useFormattingContext(format)

  return useMemo(() => {
    const { columns: _columns, defaultSort, hiddenColumns } = columnsConfig

    const columns = _columns.map(column => getPresetColumn(presetColumns, column))
    const templatedColumns = columns.map((column, index) => {
      return {
        ...column,
        Cell: getCell(column, handlers, formatter),
        ...(column.columns ? {
          columns: column.columns.map((subColumn) => ({
            ...subColumn,
            Cell: getCell(column, handlers, formatter)
          }))
        } : {})
      }
    })

    return {
      columns: templatedColumns,
      defaultSort,
      hiddenColumns
    }
  }, [columnsConfig, formatter, handlers, presetColumns])
}
