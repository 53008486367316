import React, { useState, createContext, useContext, useMemo } from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import get from 'lodash/get'
import { capitalizeFirstLetter } from '../../utils'

let _contexts = {}

const ContextContainerComponent = ({
  initialState,
  Context,
  ContextSetter,
  children
}) => {
  const [contextValue, setContextValue] = useState(initialState)
  return (
    <Context.Provider value={contextValue}>
      <ContextSetter.Provider value={setContextValue}>
        {children}
      </ContextSetter.Provider>
    </Context.Provider>
  )
}

const getContextKeys = ({ name }) => {
  return {
    contextName: `${name}Context`,
    contextSetterName: `set${capitalizeFirstLetter(name)}Context`
  }
}

const createContextAccessors = (context) => {
  const Context = createContext(context.initialState)
  const ContextSetter = createContext(noop)
  const { contextName, contextSetterName } = getContextKeys(context)
  return {
    [contextName]: Context,
    [contextSetterName]: ContextSetter
  }
}

export const SingleContextContainerProvider = ({
  contextSpec,
  contexts,
  children
}) => {
  const { contextName, contextSetterName } = getContextKeys(contextSpec)
  return (
    <ContextContainerComponent
      initialState={contextSpec.initialState}
      Context={contexts[contextName]}
      ContextSetter={contexts[contextSetterName]}
    >
      {children}
    </ContextContainerComponent>
  )
}

export const ContextContainerProvider = ({
  context: contextSpec,
  contextList: contextListSpec,
  children
}) => {
  const contexts = useMemo(() => {
    return (contextSpec ? [contextSpec] : contextListSpec).reduce(
      (acc, _contextSpec) => {
        const context = createContextAccessors(_contextSpec)
        return {
          ...acc,
          ...context
        }
      },
      _contexts
    )
  }, [contextSpec, contextListSpec])

  _contexts = contexts

  if (contextSpec) {
    return (
      <SingleContextContainerProvider contextSpec={contextSpec} contexts={contexts}>
        {children}
      </SingleContextContainerProvider>
    )
  }

  return contextListSpec.reduce(
    (acc, _contextSpec) => (
      <SingleContextContainerProvider
        contextSpec={_contextSpec}
        contexts={contexts}
      >
        {acc}
      </SingleContextContainerProvider>
    ),
    children
  )
}

export const useContextContainer = (contextKey) => {
  if (!contextKey) {
    throw new Error(`[Context Container]: "${contextKey}" must not be empty.`)
  }
  const { contextName, contextSetterName } = getContextKeys({
    name: contextKey
  })

  if (
    !get(_contexts, contextName, null) ||
    !get(_contexts, contextSetterName, null)
  ) {
    throw new Error(`[Context Container]: "${contextKey}" context not found.`)
  }

  const context = useContext(_contexts[contextName])
  const contextSetter = useContext(_contexts[contextSetterName])
  return [context, contextSetter]
}

ContextContainerComponent.propTypes = {
  initialState: PropTypes.any.isRequired,
  Context: PropTypes.object.isRequired,
  ContextSetter: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired
}

const contextSpecShape = PropTypes.shape({
  name: PropTypes.string.isRequired,
  initialState: PropTypes.any
})

SingleContextContainerProvider.propTypes = {
  contextSpec: contextSpecShape,
  contexts: PropTypes.object,
  children: PropTypes.node.isRequired
}

ContextContainerProvider.propTypes = {
  context: contextSpecShape,
  children: PropTypes.node.isRequired,
  contextList: PropTypes.arrayOf(contextSpecShape)
}

ContextContainerProvider.defaultProps = {
  contextList: []
}
