import React from 'react'
import { makeStyles } from '@material-ui/core'
import { useParams } from 'react-router-dom'
import FadeIn from '../../../molecules/Transitions/FadeIn'
import { usePolicy } from '../../../../hooks/usePolicy'
import { useManagerById } from '../../../../api/accounts'
import EmptySection from '../../../atoms/EmptySection'
import { ManagerProvider } from './ManagerProvider'
import ManagerHeader from './Header'
import ManagerTabs from './ManagerTabs'

const useStyles = makeStyles({
  statusTemplatePage: {
    margin: '1rem'
  }
})

const useManagerId = () => {
  const { managerId } = useParams()
  return isNaN(+managerId) ? [-1] : [+managerId]
}

function ManagerDetailsIndex () {
  const classes = useStyles()
  const [managerId] = useManagerId()
  const canView = usePolicy('admin_view_managers')
  const { data, isLoading, isFetching } = useManagerById(managerId)

  if (!canView) {
    return (
      <EmptySection
        title='Access Denied'
        description='You do not have sufficient permissions to access this resource'
      />
    )
  }

  if (isLoading) {
    return null
  }

  if (!data?.manager) {
    return (
      <EmptySection
        title='Manager Not Found'
        description=''
      />
    )
  }

  return (
    <FadeIn className={classes.statusTemplatePage}>
      <ManagerProvider manager={data?.manager} isFetching={isFetching}>
        <ManagerHeader />
        <ManagerTabs />
      </ManagerProvider>
    </FadeIn>
  )
}

ManagerDetailsIndex.propTypes = {
}

export default ManagerDetailsIndex
