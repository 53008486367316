import React, { useCallback, useMemo } from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import advanced from 'dayjs/plugin/advancedFormat'
import { makeStyles } from '@material-ui/core'
import Avatar from '../../../../../atoms/Avatar'

dayjs.extend(timezone)
dayjs.extend(utc)
dayjs.extend(advanced)

const useStyles = makeStyles((theme) => ({
  meeting: {
    border: '2px solid rgba(33, 41, 69, 0.2)',
    background: '#ffffff',
    borderRadius: '8px',
    padding: '1rem',
    marginBottom: '1rem',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  meetingIcon: {
    marginRight: '1rem'
  },
  name: {
    fontSize: '1rem',
    fontWeight: '600'
  },
  meetingTime: {
    '& > *': {
      fontSize: '0.8rem',
      color: '#999999',
      marginRight: '.5rem'
    }
  },
  clickableMeeting: {
    cursor: 'pointer',
    transition: 'border 0.3s linear',
    '&:hover': {
      border: '2px solid rgba(33, 41, 69, 0.8)'
    }
  }
}))

const Meeting = ({ meeting, linkBaseUrl }) => {
  const classes = useStyles()
  const { start, end, date, timezone, initial, meetingId, url } = useMemo(() => {
    return {
      meetingId: meeting.meetingId,
      date: dayjs(meeting.startTime).format('ddd M/DD'),
      start: dayjs(meeting.startTime).format('h:mm'),
      end: dayjs(meeting.endTime).format('h:mm'),
      timezone: dayjs(meeting.endTime).format('z'),
      initial: meeting.clientLongName.substring(0, 1) ?? '',
      url: linkBaseUrl ? `${linkBaseUrl}/${meeting.meetingId}/view` : null
    }
  }, [meeting, linkBaseUrl])

  const isClickable = useMemo(() => (meetingId && linkBaseUrl), [meetingId, linkBaseUrl])

  const onClickMeetingHandler = useCallback(() => {
    if (isClickable) {
      window.open(url, '_blank', 'noreferrer')
    }
  }, [isClickable, url])

  return (
    <div
      className={clsx(classes.meeting, isClickable ? classes.clickableMeeting : null)}
      onClick={onClickMeetingHandler}
      title={meeting.subject}
    >
      <div className={classes.meetingIcon}>
        <Avatar src={meeting.profilePic} avatarLetters={initial} />
      </div>
      <div>
        <div className={classes.name}>{meeting.clientLongName}</div>
        <div className={classes.meetingTime}>
          <span>{date}</span>
          <span>{start} - {end} {timezone}</span>
        </div>
        {/* <div>{meeting.subject}</div> */}
      </div>
    </div>
  )
}

Meeting.propTypes = {
  meeting: PropTypes.object.isRequired,
  linkBaseUrl: PropTypes.string
}

Meeting.defaultProps = {
  linkBaseUrl: null
}

export default Meeting
