import { useQuery } from '@tanstack/react-query'
import { getResource } from '../service'
import { useAppContext } from '../redux/slices/appContext'

function useProtectedResource (src, isPublicResource) {
  const { userId } = useAppContext()
  const queryResult = useQuery({
    queryKey: [userId, src, isPublicResource],
    queryFn: async () => {
      if (isPublicResource || !src) {
        return src
      }
      const { data } = await getResource(src)
      return data.resourceUrl
    }
  })

  return {
    loading: queryResult.isLoading,
    url: queryResult.data,
    error: queryResult.error
  }
}

export default useProtectedResource
