import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import FastAvatar from '../../../molecules/FastAvatar'
import FadeIn from '../../../molecules/Transitions/FadeIn'
import { featureProps, schemaProps } from '../commonProps'
import TeamMemberAttribute from './TeamMemberAttribute'
import { mapCommonStyles } from './commonStyles'
import { useTeamMemberFields } from './useTeamMemberFields'
import BioArea from './BioArea'

const useStyles = makeStyles(theme => ({
  mini: mapCommonStyles(theme)({
    display: 'inline-flex',
    flexDirection: 'row',
    borderColor: theme.palette.gray.dark,
    borderRadius: theme.layout.radius.loose,
    padding: theme.layout.padding.thin,
    gap: theme.layout.gap.dense,
    alignItems: 'center',
    '& .__name': {
      fontWeight: theme.typography.weights.bold,
      whiteSpace: 'nowrap'
    },
    '& .__attribute': {
      fontWeight: theme.typography.weights.light
    }
  })
}))

function MiniTeamMember ({ teamMember, fieldConfig, features, schema, index }) {
  const classes = useStyles({ features })
  const { teamMember: _teamMember } = useTeamMemberFields(teamMember, fieldConfig, schema)

  return (
    <FadeIn delay={(index || 0) * 0.025} className={classes.mini} style={features?.cardStyles}>
      <BioArea teamMember={_teamMember} schema={schema} features={features}>
        <FastAvatar
          avatarUrl={teamMember.avatarUrl}
          abbreviation={_teamMember.abbreviation}
          size='md'
        />
      </BioArea>
      <div className='__attributes'>
        <div className='__name'>{_teamMember.name}</div>
        {_teamMember.attributes.map(f => (
          <TeamMemberAttribute
            key={f.key}
            className='__attribute'
            value={f.value}
            type={f.type}
          />
        ))}
      </div>
    </FadeIn>
  )
}

MiniTeamMember.propTypes = {
  teamMember: PropTypes.object.isRequired,
  fieldConfig: PropTypes.arrayOf(PropTypes.shape({
    accessor: PropTypes.string.isRequired,
    type: TeamMemberAttribute.propTypes.type
  })),
  features: featureProps,
  schema: schemaProps,
  index: PropTypes.number
}

export default MiniTeamMember
