const parseCellValue = (item) => {
  if (item === null) {
    return '<null>'
  }
  if (typeof item === 'object') {
    return '<object>'
  }

  return item
}

export default parseCellValue
