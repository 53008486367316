import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useTheme } from '@material-ui/core'
import { useFormattingContext } from '../../organisms/FormattingProvider/FormattingContext'

export const useValueLayer = (marker, sizeFactor, height) => {
  return useCallback((props) => {
    const { centerX, centerY, innerRadius } = props
    return (
      <ValueLayer
        x={centerX}
        y={centerY - (innerRadius / 3)}
        value={marker.value}
        format={marker.format}
        height={height}
        sizeFactor={sizeFactor}
      />
    )
  }, [marker, height, sizeFactor])
}

function ValueLayer ({ x, y, value, format, height, sizeFactor }) {
  const { formatter } = useFormattingContext()
  const theme = useTheme()
  const size = useMemo(() => {
    return `${height * sizeFactor}px`
  }, [height, sizeFactor])

  return (
    <text
      style={{ userSelect: 'none' }}
      x={x}
      y={y}
      fontFamily={theme.typography.fontFamily}
      fontSize={size}
      textAnchor='middle'
    >
      {formatter(value, format)}
    </text>
  )
}

ValueLayer.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  value: PropTypes.any,
  format: PropTypes.string,
  height: PropTypes.number,
  sizeFactor: PropTypes.number
}

ValueLayer.defaultProps = {
  format: 'percentage',
  sizeFactor: 0.2
}

export default ValueLayer
