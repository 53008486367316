import React from 'react'
import PropTypes from 'prop-types'
import { Box, Grid, makeStyles } from '@material-ui/core'
import Text from '../../../atoms/Text'

const useStyles = makeStyles((theme) => ({
  container: {
    marginLeft: 0,
    marginTop: 0,
    marginBottom: 0
  },
  header: {
    marginBottom: '1.25rem'
  }
}))

function IntegrationsLayout ({ children }) {
  const classes = useStyles()

  return (
    <Box
      sx={{
        flexGrow: 1,
        height: '100%'
      }}
      className={classes.container}
    >
      <Grid container style={{ padding: '20px' }}>
        <Text text='Integrations' variant='h1' className={classes.header} />
      </Grid>
      {children}
    </Box>

  )
}

IntegrationsLayout.propTypes = {
  children: PropTypes.node
}

export default IntegrationsLayout
