import React, { Fragment, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core'
import CardVariant from './CardVariant'

const useStyles = makeStyles((theme) => ({
  link: () => ({
    '&:hover': {
      textDecoration: 'none'
    }
  })
}))

const AdvisorViewLink = ({ to, variant, children }) => {
  const classes = useStyles()
  const ContentWrapper = useMemo(() => {
    switch (variant) {
      case 'card':
        return CardVariant
      case 'link':
        return Fragment
      default:
        return Fragment
    }
  }, [variant])

  return (
    <Link className={classes.link} to={to}>
      <ContentWrapper>
        {children}
      </ContentWrapper>
    </Link>
  )
}

AdvisorViewLink.propTypes = {
  to: PropTypes.string,
  variant: PropTypes.oneOf(['card', 'link', undefined]),
  children: PropTypes.node.isRequired
}

AdvisorViewLink.defaultProps = {
  variant: 'card'
}

export default AdvisorViewLink
