import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { createSelector, createSlice } from '@reduxjs/toolkit'
import isEmpty from 'lodash/isEmpty'
import { useAction, useAsyncActionDebounced } from '../utils'
import {
  fetchViewsThreadSections,
  fetchViewThreadSections
} from '../../service'
import { NOTE_TRIGGERED_BY } from '../../constants'

export const initialBaseState = {
  sectionId: undefined,
  modalPosition: undefined,
  openModal: false,
  openNewCommentModal: false,
  triggeredBy: undefined,
  reloadThreads: false,
  filters: [],
  clientId: undefined
}

export const initialState = {
  multiViewSections: {},
  viewSections: {},
  viewId: undefined,
  ...initialBaseState
}

const THREADS = 'threads'
const REPLIES = 'replies'
const VIEW_SECTIONS = 'viewSections'
const MULTI_VIEW_SECTIONS = 'multiViewSections'

function getEntityKeys (sectionId, viewId) {
  const viewSectionKey = !sectionId ? MULTI_VIEW_SECTIONS : VIEW_SECTIONS
  const entityKey = sectionId || viewId
  return {
    viewSectionKey,
    entityKey
  }
}

function isInMultiView (viewSectionKey, state) {
  return (
    viewSectionKey === VIEW_SECTIONS &&
    !isEmpty(state[MULTI_VIEW_SECTIONS]) &&
    !isEmpty(state[MULTI_VIEW_SECTIONS][state.viewId])
  )
}

function modalHandler (isModalOpen, state) {
  if (isModalOpen) {
    if (!state.modalPosition) {
      throw new Error({
        code: 'NoteContext',
        message: 'You must set the "modalPosition" property first'
      })
    }
    switch (state.triggeredBy) {
      case NOTE_TRIGGERED_BY.BOARD: {
        state.openModal = false
        state.reloadThreads = true
        state.triggeredBy = undefined
        break
      }
      case NOTE_TRIGGERED_BY.FULL_VIEW: {
        state.openModal = false
        state.triggeredBy = undefined
        break
      }
      default: {
        state.openModal = true
        break
      }
    }
    state.openNewCommentModal = false
  }
}

function onAddReplyOrThread (state, { payload: { openModal, sectionId, newThread, threadReplies } }) {
  const { viewSectionKey, entityKey } = getEntityKeys(sectionId, state.viewId)
  const propKey = newThread || threadReplies ? THREADS : REPLIES

  modalHandler(openModal, state)

  if (newThread && isEmpty(state[viewSectionKey][entityKey])) {
    state[viewSectionKey][entityKey] = { threads: 1, replies: 0 }
  } else if (threadReplies) {
    state[viewSectionKey][entityKey][propKey] += 1
    state[viewSectionKey][entityKey][REPLIES] += (threadReplies + 1)
  } else {
    state[viewSectionKey][entityKey][propKey] += 1
  }

  if (isInMultiView(viewSectionKey, state) && !isEmpty(state[MULTI_VIEW_SECTIONS][state.viewId][REPLIES])) {
    state[MULTI_VIEW_SECTIONS][state.viewId][REPLIES] += 1
  }
}

function onRemoveReply (state, { payload: { sectionId } }) {
  const { viewSectionKey, entityKey } = getEntityKeys(sectionId, state.viewId)

  if (!isEmpty(state[viewSectionKey]) && state[viewSectionKey][entityKey][REPLIES] && state[viewSectionKey][entityKey][REPLIES] > 0) {
    state[viewSectionKey][entityKey][REPLIES] -= 1
  }

  if (isInMultiView(viewSectionKey, state) && state[MULTI_VIEW_SECTIONS][state.viewId].replies > 0) {
    state[MULTI_VIEW_SECTIONS][state.viewId].replies -= 1
  }
}

function onRemoveThread (state, { payload: { sectionId, replies } }) {
  const { viewSectionKey, entityKey } = getEntityKeys(sectionId, state.viewId)

  if (!isEmpty(state[viewSectionKey]) && !isEmpty(state[viewSectionKey][entityKey])) {
    state[viewSectionKey][entityKey].replies -= replies

    if (state[viewSectionKey][entityKey].threads > 0) {
      state[viewSectionKey][entityKey].threads -= 1
    }
  }

  if (isInMultiView(viewSectionKey, state)) {
    state[MULTI_VIEW_SECTIONS][state.viewId].replies -= replies

    if (state[MULTI_VIEW_SECTIONS][state.viewId].threads > 0) {
      state[MULTI_VIEW_SECTIONS][state.viewId].threads -= 1
    }
  }
}

function addFilter (state, { payload }) {
  if (!state.filters.find((filter) => filter.id === payload.id)) {
    state.filters = [{ ...payload }]
    state.viewId = payload.id
  }
}

function removeFilter (state, { id }) {
  state.filters = state.filters.filter((filter) => filter.id === id)
  state.viewId = undefined
}

function setViewSections (state, { payload: { viewSections, viewId } }) {
  state.viewSections = { ...state.viewSections, ...viewSections }
  state.viewId = viewId
}

function onNotesBoardToggle (state, { payload: { open, view, clearFilter, isMultiView } }) {
  const { path: viewId, name: viewName } = view || {}
  if (open) {
    state.triggeredBy = NOTE_TRIGGERED_BY.BOARD
    if (clearFilter) {
      state.filters = []
    } else if (!isMultiView && !state.filters.length && view) {
      state.filters = [{ id: viewId, name: viewName }]
    }
  } else {
    state.triggeredBy = undefined
    state.filters = []
    state.viewId = undefined
    state.clientId = undefined
  }
}

const noteContextSlice = createSlice({
  name: 'noteContext',
  initialState,
  reducers: {
    setNoteContext: (state, { payload }) => {
      Object.assign(state, payload)
    },
    setViewSections,
    onAddReplyOrThread,
    onNotesBoardToggle,
    onRemoveThread,
    onRemoveReply,
    removeFilter,
    addFilter
  }
})

const { actions } = noteContextSlice

const getNoteContextSlice = (state) => state[noteContextSlice.name]

const getNoteContextWithDefault = createSelector(
  getNoteContextSlice,
  (_, defaultNoteContext) => defaultNoteContext,
  (noteContext, defaultNoteContext) => ({
    ...defaultNoteContext,
    ...noteContext
  })
)

export function useNoteContext (defaultNoteContext) {
  const payloadFn = useCallback(
    (state) => getNoteContextWithDefault(state, defaultNoteContext),
    [defaultNoteContext]
  )
  return useSelector(payloadFn)
}

export function useSetNoteContext () {
  const payloadFn = useCallback((noteContext) => noteContext, [])
  return useAction(actions.setNoteContext, payloadFn)
}

export function useOnAddReplyOrThread () {
  const payloadFn = useCallback((noteContext) => noteContext, [])
  return useAction(actions.onAddReplyOrThread, payloadFn)
}

export function useOnRemoveReply () {
  const payloadFn = useCallback((noteContext) => noteContext, [])
  return useAction(actions.onRemoveReply, payloadFn)
}

export function useOnRemoveThread () {
  const payloadFn = useCallback((noteContext) => noteContext, [])
  return useAction(actions.onRemoveThread, payloadFn)
}

export function useNoteFilterActions () {
  const payloadFn = useCallback((noteContext) => noteContext, [])
  return {
    addNoteFilter: useAction(actions.addFilter, payloadFn),
    removeNoteFilter: useAction(actions.removeFilter, payloadFn)
  }
}

function fetchViewNoteThreadSectionsAction ({
  viewId = null,
  viewIds = null,
  clientId = null
}) {
  return async (dispatch) => {
    try {
      let viewSections = {}
      let multiViewSections = {}
      if (viewIds) {
        const { data } = await fetchViewsThreadSections({
          viewIds: [...new Set(viewIds)].join(','),
          resolved: false,
          clientId
        })
        const initMultiViewSections = viewIds.reduce(
          (prev, curr) => ({ ...prev, [curr]: { threads: 0, replies: 0 } }),
          {}
        )
        multiViewSections = !isEmpty(data.rows)
          ? data.rows.reduce(
            (prev, { viewId, replies, ...rest }) => ({
              ...prev,
              [viewId]: {
                ...rest,
                replies: parseInt(replies)
              }
            }),
            initMultiViewSections
          )
          : initMultiViewSections
      }
      if (viewId) {
        const { data } = await fetchViewThreadSections({ viewId, clientId, resolved: false })
        viewSections = data.rows.reduce(
          (prev, { section, replies, ...rest }) => ({
            ...prev,
            [section]: {
              ...rest,
              replies: parseInt(replies)
            }
          }),
          {}
        )
      }
      dispatch(actions.setNoteContext({ viewId, viewSections, multiViewSections }))
    } catch (err) {
      dispatch(actions.setNoteContext(initialState))
      console.error(err)
    }
  }
}

export function useFetchViewNoteThreadSections () {
  return useAsyncActionDebounced(fetchViewNoteThreadSectionsAction, true)
}

export function useNotesBoardToggle () {
  const payloadFn = useCallback((noteContext) => noteContext, [])
  return useAction(actions.onNotesBoardToggle, payloadFn)
}

function setNotesClientId (clientId) {
  return (dispatch) => dispatch(actions.setNoteContext({ clientId }))
}

export function useSetNotesClientId () {
  const payloadFn = useCallback((clientId) => (clientId), [])
  return useAction(setNotesClientId, payloadFn)
}

export default noteContextSlice
