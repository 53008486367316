import PropTypes from 'prop-types'
import { useOktaAuth } from '@okta/okta-react'
import { usePermissions, useAuthState } from '../../hooks'

const ROLES = ['advisor', 'summit', 'wealth-owner']

const PermissionsContent = ({ allowed, denied, children }) => {
  const { authState: oktaAuthState } = useOktaAuth()
  const authState = useAuthState(oktaAuthState)
  let hasPermissions = true

  const { role, user } = usePermissions(authState.accessToken || {})

  denied.forEach(element => {
    for (const prop in element) {
      if (prop === 'role') {
        const allowedUser = allowed?.find((item) => item?.user === user)
        if (allowedUser) {
          hasPermissions = true
        }

        if (!allowedUser) {
          hasPermissions = !ROLES.includes(element[prop])
        }
      }

      if (prop === 'user') {
        const allowedRole = allowed?.find((item) => item?.role === role)
        if (allowedRole) {
          hasPermissions = true
        }

        if (!allowedRole) {
          hasPermissions = !element[prop] === user
        }
      }
    }
  })

  if (hasPermissions) {
    return children
  }

  return null
}

PermissionsContent.propTypes = {
  children: PropTypes.node,
  allowed: PropTypes.array,
  denied: PropTypes.array
}

PermissionsContent.defaultProps = {
  children: undefined,
  allowed: [],
  denied: []
}

export default PermissionsContent
