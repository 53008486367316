import React from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import DatePeriodTabs from './DatePeriodTabs'

const useStyles = makeStyles(() => ({
  dateTabPeriodsContainer: {
    width: '100%',
    padding: '0px',
    marginBottom: '0.75rem',
    marginTop: '0.75rem'
  }
}))

const WealthJourneyYearSelector = ({
  yearTabs,
  onYearSelected,
  selectedYear,
  onReachNavigationEdge
}) => {
  const classes = useStyles()

  return (
    <div className={classes.dateTabPeriodsContainer}>
      <Grid container>
        <DatePeriodTabs
          tabs={yearTabs}
          onTabStepClick={onYearSelected}
          selectedTabStep={selectedYear}
          onReachNavigationEdge={onReachNavigationEdge}
        />
      </Grid>
    </div>
  )
}

WealthJourneyYearSelector.propTypes = {
  onYearSelected: PropTypes.func.isRequired,
  selectedYear: PropTypes.object,
  onReachNavigationEdge: PropTypes.func.isRequired,
  yearTabs: PropTypes.array
}

WealthJourneyYearSelector.defaultProps = {
  selectedYear: null,
  yearTabs: null
}

export default WealthJourneyYearSelector
