import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Modal from '../../../molecules/Modal'
import Text from '../../../atoms/Text'
import { BUTTON_VARIANT, ICON_NAMES, TEXT_VARIANTS } from '../../../../constants'
import Icon from '../../../atoms/Icon'
import theme from '../../../../theme'
import RoundedButton from '../../../atoms/RoundedButton'

const useStyles = makeStyles((theme) => ({
  modal: {},
  container: {
    padding: '1.5rem',
    margin: 0,
    borderRadius: '5px'
  },
  header: {
    marginBottom: '1.25rem',
    width: '100%',
    display: 'flex',
    alignItems: 'start'
  },
  content: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
}))

const DeleteGoalConfirmModal = ({ goal, isOpen, onClose, onConfirm }) => {
  const classes = useStyles()
  return (
    <>
      <Modal
        open={isOpen}
        onClose={onClose}
        containerClassName={classes.modal}
        width='30rem'
      >
        <div className={classes.container}>
          <div className={classes.header}>
            <div style={{ flexGrow: 1 }}>
              <Text variant={TEXT_VARIANTS.h2}>
                Are you sure you want to delete {goal.shortName}
              </Text>
            </div>
            <button
              type='button'
              onClick={onClose}
              style={{ cursor: 'pointer' }}
            >
              <Icon
                color={theme.palette.summitBlue}
                name={ICON_NAMES.close}
                customSize='1.5rem'
                variant='button'
              />
            </button>
          </div>
          <div className={classes.content}>
            <RoundedButton
              variant={BUTTON_VARIANT.outlined}
              primary
              onClick={onConfirm}
            >
              Delete this goal
            </RoundedButton>
            <RoundedButton
              variant={BUTTON_VARIANT.outlined}
              secondary
              onClick={onClose}
            >
              Cancel
            </RoundedButton>
          </div>
        </div>
      </Modal>
    </>
  )
}

DeleteGoalConfirmModal.propTypes = {
  goal: PropTypes.object,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func
}

export default DeleteGoalConfirmModal
