import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'
import { useLocation } from 'react-router-dom'
import { CALC_TYPES, LEVEL_TYPES } from '../../../../constants'
import { useAvailableDates } from '../../../../redux/slices/appContext'
import { numeralByCase, tableNumberFormatter } from '../../../../utils'
import { TableVirtualizedWithDynamicHeight } from '../../../molecules/Table'

import TableSkeleton from '../../../atoms/TableSkeleton'
import EmptySection from '../../../atoms/EmptySection'
import { useGetGroupedCoreData } from '../../../../api/coreData'

const defaultColumns = [
  { hidden: true },
  { name: 'Client', alignment: 'left', sorteable: false },
  { name: 'Cash', alignment: 'left', sorteable: true },
  { name: 'Cash %', alignment: 'left', sorteable: true },
  { name: 'Total Value', alignment: 'left', sorteable: true }
]

const mapRows = (clientTotals, clientCashKeyValuePair = {}) => {
  const rows = (clientTotals ?? []).map(
    ({ uniqueId, clientId, clientName = 'N/A', endingValue }) => {
      const totalValue = endingValue || 0

      const cashValue = clientCashKeyValuePair[clientId]?.endingValue || 0

      const clientCashPercentage =
        cashValue > 0 && totalValue > 0 ? cashValue / totalValue : 0

      return [
        { value: uniqueId, hidden: true },
        { value: clientName, alignment: 'left' },
        { value: tableNumberFormatter(cashValue), alignment: 'left' },
        {
          value: numeralByCase(clientCashPercentage, '0%', '0.00%'),
          alignment: 'left'
        },
        { value: tableNumberFormatter(totalValue), alignment: 'left' },
        {
          hidden: true,
          showTooltip: true,
          tooltips: [
            '',
            '',
            { title: numeralByCase(cashValue, '0,0.0a', '0,0.0000a') },
            { title: numeralByCase(clientCashPercentage, '0%', '0.0000%') },
            { title: numeralByCase(totalValue, '0,0.0a', '0,0.0000a') }
          ]
        }
      ]
    }
  )
  return rows
}

const AdvisorCashReport = ({ tagId, filterLevel }) => {
  const [availableDates] = useAvailableDates()

  const { pathname } = useLocation()
  const advisorId = pathname.split('/').at(-1)

  const { query: baseQuery, queryOptions } = useMemo(
    () => ({
      query: {
        dateRange: {
          startDate: availableDates.mainDate,
          endDate: availableDates.mainDate
        },
        levelFilters: {
          levelTypes: [LEVEL_TYPES.CLIENT],
          calcType: CALC_TYPES.balance,
          advisorIds: [advisorId]
        }
      },
      queryOptions: {
        queryKey: 'getGroupedCoreDataForCashReport'
      }
    }),
    [advisorId, availableDates.mainDate]
  )

  const { query: cashQuery, queryOptions: cashQueryOptions } = useMemo(
    () => ({
      query: {
        ...baseQuery,
        [filterLevel]: [tagId]
      },
      queryOptions: {
        queryKey: 'getGroupedCoreDataForCash',
        mapper: (data) => {
          return data.reduce(
            (acc, client) => ({
              ...acc,
              [client.clientId]: client
            }),
            {}
          )
        }
      }
    }),
    [baseQuery, filterLevel, tagId]
  )

  const { data: clientCash, isLoading: isLoadingCashReport } = useGetGroupedCoreData(
    cashQuery,
    cashQueryOptions
  )

  const { data: clientTotals, isLoading: isLoadingClientTotals } = useGetGroupedCoreData(
    baseQuery,
    queryOptions
  )

  const rows = useMemo(() => {
    return mapRows(clientTotals, clientCash)
  }, [clientTotals, clientCash])

  if (isLoadingCashReport || isLoadingClientTotals) {
    return <TableSkeleton columns={4} rows={5} />
  }

  if (isEmpty(rows)) {
    return (
      <EmptySection
        title='There is not data to display here yet.'
        description=''
      />
    )
  }

  return (
    <TableVirtualizedWithDynamicHeight labels={defaultColumns} rows={rows} />
  )
}

AdvisorCashReport.propTypes = {
  tagId: PropTypes.number,
  filterLevel: PropTypes.string
}

export default AdvisorCashReport
