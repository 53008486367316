import React from 'react'
import { ResponsiveWrapper } from '@nivo/core'
import Gauge from './Gauge'

function ResponsiveGauge (props) {
  return (
    <ResponsiveWrapper>
      {({ width, height }) => (
        <Gauge width={width} height={height} {...props} />
      )}
    </ResponsiveWrapper>
  )
}

ResponsiveGauge.propTypes = {
  ...Gauge.propTypes
}

ResponsiveGauge.defaultProps = {
  ...Gauge.defaultProps
}

export default ResponsiveGauge
