import { alpha, Box, Button, ButtonGroup } from '@material-ui/core'
import clsx from 'clsx'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import PropTypes from 'prop-types'
import RoundedButton from '../../../components/atoms/RoundedButton'
import RoundedModal from '../../../components/molecules/RoundedModal'
import { useSearchAssetsDebounced } from '../../../hooks'
import { useAppContext } from '../../../redux/slices/appContext'
import { useFilterSubscriptionContext } from '../FilterSubscriptionProvider'
import { useFilterSubscription } from '../useFilterSubscription'
import MultiselectAutocomplete from '../../../components/reusables/Autocompletes/MultiSelect'

const useStyles = makeStyles((theme) => ({
  buttonGroupItem: {
    flexGrow: 1,
    textTransform: 'unset'
  },
  buttonFilled: {
    background: theme.palette.summitBlue,
    color: theme.palette.white,
    fontWeight: 700,
    '&:hover': {
      background: alpha(theme.palette.summitBlue, 0.9)
    }
  },
  successButton: {
    background: theme.palette.summitBlue
  }
}))

const buttonOptions = [
  { value: 'include', label: 'Include' },
  { value: 'exclude', label: 'Exclude' }
]

const AccountAssetModal = ({
  open,
  onClose,
  filterSubscription
}) => {
  const classes = useStyles()
  const appContext = useAppContext()
  const [selectedAssets, setSelectedAssets] = useState([])
  const [toggleState, setToggleState] = useState('include')
  const [hasPopulatedDefaults, setHasPopulatedDefaults] = useState(false)
  const { filters } = useFilterSubscriptionContext()

  const filteredAssetIds = useMemo(() => filters?.levelFilters?.assetIds?.[0]?.value ?? [], [filters?.levelFilters?.assetIds])
  const filteredState = useMemo(() => {
    const currentState = filters?.levelFilters?.assetIds?.[0]?.op ?? null
    return currentState === 'notIn' ? 'exclude' : 'include'
  }, [filters?.levelFilters?.assetIds])

  const { publishFilters } = useFilterSubscription({
    publishKeys: filterSubscription.publish
  })

  const onApply = useCallback(() => {
    publishFilters((prevState) => ({
      ...prevState,
      levelFilters: {
        ...(prevState?.levelFilters ?? {}),
        assetIds: [{
          op: toggleState === 'include' ? 'in' : 'notIn',
          value: selectedAssets.map((asset) => asset.value)
        }]
      }
    }))
    onClose()
  }, [onClose, publishFilters, selectedAssets, toggleState])

  const { options, onChangeQuery, isLoading, isSearchLoading, defaultOptions } =
    useSearchAssetsDebounced({
      query: {
        take: 10,
        filters: {
          clientId: appContext?.clientId
        }
      },
      queryOptions: {
        mapper: (assets = []) => assets.map((asset) => ({
          label: `${asset.assetIdentifier} - ${asset.longName}`,
          value: asset.assetId,
          payload: {
            ...asset
          }
        }))
      },
      defaultAssetIds: filteredAssetIds
    })

  useEffect(() => {
    if (!hasPopulatedDefaults && defaultOptions?.length && !selectedAssets.length) {
      setSelectedAssets(defaultOptions?.filter((asset) => filteredAssetIds.includes(asset.value)) ?? [])
      setHasPopulatedDefaults(true)
    }
  }, [defaultOptions, selectedAssets, filteredAssetIds, hasPopulatedDefaults])

  useEffect(() => {
    if (filteredState) {
      setToggleState(filteredState)
    }
  }, [filteredState])

  return (
    <RoundedModal
      open={open}
      onClose={onClose}
      title='Filter by Assets'
    >
      <ButtonGroup fullWidth>
        {buttonOptions.map((option) => (
          <Button
            variant='outlined'
            size='large'
            className={clsx(classes.buttonGroupItem, { [classes.buttonFilled]: toggleState === option.value })}
            key={option.value}
            onClick={() => setToggleState(option.value)}
          >
            {option.label}
          </Button>
        ))}
      </ButtonGroup>

      <Box mt={2}>
        <MultiselectAutocomplete
          options={options}
          onChange={setSelectedAssets}
          onInputChange={onChangeQuery}
          getOptionLabel={(option) => {
            const trimmedAssetName = option?.payload?.assetName?.length > 5
              ? `${option?.payload?.assetName.substring(0, 5)}...`
              : option?.payload?.assetName
            return `${option?.payload?.assetIdentifier} - ${trimmedAssetName}`
          }}
          isLoading={isLoading || isSearchLoading}
          selectedOptions={selectedAssets}
          defaultValue={filteredAssetIds}
          value={selectedAssets}
          placeholder='Search by Asset Name'
        />
      </Box>

      <Box display='flex' justifyContent='space-around' marginTop='2rem'>
        <Box width='200px' maxWidth='100%'>
          <RoundedButton
            onClick={onClose}
            secondary
            fullWidth
          >
            Cancel
          </RoundedButton>
        </Box>

        <Box width='200px' maxWidth='100%'>
          <RoundedButton
            onClick={onApply}
            primary
            fullWidth
            className={classes.successButton}
          >
            Apply
          </RoundedButton>
        </Box>
      </Box>
    </RoundedModal>
  )
}

AccountAssetModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  filterSubscription: PropTypes.shape({
    publish: PropTypes.arrayOf(PropTypes.string)
  })
}

export default AccountAssetModal
