import React from 'react'
import PropTypes from 'prop-types'
import {
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  makeStyles
} from '@material-ui/core'

const useStyles = makeStyles(() => ({
  popper: {
    zIndex: 1300
  },
  popupContainer: {
    background: '#FFFFFF',
    boxShadow: '0px 4px 12px rgba(24, 27, 53, 0.08)',
    borderRadius: '8px'
  }
}))

const SelectFilterPopper = ({ open, handleClose, anchorEl, children }) => {
  const classes = useStyles()

  return (
    <Popper
      transition
      open={open}
      role={undefined}
      placement='bottom-start'
      className={classes.popper}
      anchorEl={anchorEl}
    >
      {({ TransitionProps }) => {
        return (
          <Grow {...TransitionProps}>
            <ClickAwayListener onClickAway={handleClose}>
              <Paper className={classes.popupContainer}>
                {children}
              </Paper>
            </ClickAwayListener>
          </Grow>
        )
      }}
    </Popper>
  )
}

SelectFilterPopper.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  anchorEl: PropTypes.node,
  children: PropTypes.node
}

export default SelectFilterPopper
