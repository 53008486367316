import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import PresentationTable from '../../../../organisms/PresentationTable'
import { useFormattingContext } from '../../../../organisms/FormattingProvider/FormattingContext'
import FadeIn from '../../../../molecules/Transitions/FadeIn'
import { useColumns } from './columnConfig'

const useStyles = makeStyles((theme) => ({
  analysisTable: {
    minHeight: '300px'
  }
}))

function AllocationAnalysisTable ({ avtData, loading }) {
  const { formatter } = useFormattingContext()
  const { columns, defaultSort } = useColumns({ formatter })
  const classes = useStyles()

  return (
    <FadeIn className={classes.analysisTable} delay={0.3}>
      <PresentationTable
        columns={columns}
        data={avtData?.allocation || []}
        loading={loading}
        defaultSort={defaultSort}
        sortable
        variant='bordered'
      />
    </FadeIn>
  )
}

AllocationAnalysisTable.propTypes = {
  avtData: PropTypes.shape({
    allocation: PropTypes.array
  }),
  loading: PropTypes.bool
}

export default AllocationAnalysisTable
