import { useMutation } from '@tanstack/react-query'
import { completeFirmChange, startFirmChange } from '../service'

export const useStartFirmChangeMutation = () => {
  return useMutation({
    mutationFn: async ({ toFirmId }) => {
      const { data } = await startFirmChange({
        toFirmId
      })

      return data
    }
  })
}

export const useCompleteFirmChangeMutation = () => {
  return useMutation({
    mutationFn: async ({ firmChangeId }) => {
      const { data } = await completeFirmChange({
        firmChangeId
      })

      return data
    }
  })
}
