import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import { Link } from 'react-router-dom'

const PREVIEW_SIZES = {
  maxHeightPx: 210,
  maxWidthPx: 280
}

const useStyles = makeStyles((theme) => ({
  container: {
    opacity: ({ lowlighted, selectable }) =>
      lowlighted && !selectable ? 0.3 : 1,
    boxShadow: '0px 10px 10px -5px rgba(0,0,0,0.5)',
    backgroundColor: theme.palette.common.white,
    maxHeight: `${PREVIEW_SIZES.maxHeightPx}px`,
    maxWidth: `${PREVIEW_SIZES.maxWidthPx}px`,
    width: `${PREVIEW_SIZES.maxWidthPx}px`,
    height: `${PREVIEW_SIZES.maxHeightPx}px`,
    borderRadius: '8px',
    cursor: 'pointer',
    willChange: 'width, height',
    zIndex: 1099,
    textDecoration: 'none !important',
    transition: ({ lowlighted }) =>
      lowlighted
        ? 'border 0.1s ease-in-out, opacity 0.5s linear 0.1s'
        : 'border 0.1s ease-in-out, opacity .3s ease-in-out',
    '&:hover': {
      borderColor: 'rgba(33, 41, 69, 0.5)'
    }
  }
}))

const PreviewLinkContainer = ({
  to,
  position,
  lowlighted,
  selectable,
  children,
  onMouse,
  onClick
}) => {
  const linkRef = useRef(null)
  const classes = useStyles({ position, lowlighted, selectable })

  useEffect(() => {
    const node = linkRef.current
    node?.addEventListener('mouseenter', onMouse)
    node?.addEventListener('mouseleave', onMouse)
    return () => {
      node?.removeEventListener('mouseenter', onMouse)
      node?.removeEventListener('mouseleave', onMouse)
      onMouse({ type: 'mouseleave' })
    }
  }, [onMouse, linkRef])

  const containerClasses = clsx(classes.container, {
    [classes.selectable]: selectable
  })

  if (selectable) {
    return (
      <div className={containerClasses} onClick={onClick}>
        {children}
      </div>
    )
  }

  return (
    <Link
      to={!selectable ? to : null}
      innerRef={linkRef}
      className={containerClasses}
    >
      {children}
    </Link>
  )
}

PreviewLinkContainer.propTypes = {
  to: PropTypes.string,
  position: PropTypes.array,
  lowlighted: PropTypes.bool,
  selectable: PropTypes.bool,
  children: PropTypes.node,
  onMouse: PropTypes.func,
  onClick: PropTypes.func
}

export default PreviewLinkContainer
