import React, { useContext, useMemo } from 'react'
import PropTypes from 'prop-types'
import SydButton from '../../../commonDesign/Button'
import { FirmReportExportContext } from './FirmReportExportContext'

export function FirmReportExportButton ({ report }) {
  const { onExport, exportState } = useContext(FirmReportExportContext)
  const enabled = useMemo(() => {
    return report?.configuration?.export?.key && report?.configuration?.export?.formats?.length > 0
  }, [report])

  if (!enabled) return null

  return (
    <SydButton
      variant='outline'
      icon='fileDownload'
      onClick={() => onExport(report)}
      priority={exportState?.error ? 'warning' : 'main'}
      processing={exportState?.processing}
    >
      Export
    </SydButton>
  )
}

FirmReportExportButton.propTypes = {
  report: PropTypes.shape({
    configuration: PropTypes.object
  })
}

export default FirmReportExportButton
