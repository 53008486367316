import React from 'react'
import PropTypes from 'prop-types'
import { Button, makeStyles } from '@material-ui/core'
import { ICON_NAMES } from '../../../../constants'
import Icon from '../../../atoms/Icon'
import { usePolicy } from '../../../../hooks/usePolicy'

const useStyles = makeStyles((theme) => ({
  editButton: {
    textTransform: 'none',
    borderRadius: '10rem',
    padding: '2px 18px',
    outline: `1.5px solid ${theme.palette.primary.main}`,
    fontWeight: theme.typography.weights.semibold,
    fontSize: theme.typography.fontSizes.medium,
    transition: 'background-color 200ms ease-in-out, color 200ms ease-in-out',
    backgroundColor: ({ editing }) => editing ? theme.palette.primary.main : theme.palette.background.default,
    color: ({ editing }) => !editing ? theme.palette.primary.main : theme.palette.background.default,
    '&:hover': {
      color: theme.palette.primary.main
    }
  }
}))

function EditButton ({ policy, onClick, editing, disabled, style, buttonText }) {
  const classes = useStyles({ editing })
  const allowed = usePolicy(policy)

  if (!allowed) return null

  return (
    <Button
      style={style}
      startIcon={<Icon name={ICON_NAMES.edit} />}
      className={classes.editButton}
      disabled={disabled}
      variant='outlined'
      onClick={onClick}
    >
      {buttonText}
    </Button>
  )
}

EditButton.propTypes = {
  policy: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  editing: PropTypes.bool,
  style: PropTypes.object,
  buttonText: PropTypes.string
}

EditButton.defaultProps = {
  policy: null,
  disabled: false,
  editing: false,
  buttonText: 'Edit'
}

export default EditButton
