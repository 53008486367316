import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import { useAdvisorViewTabGroup } from '../../AdvisorView/components/AdvisorViewTabs/advisorViewTabGroupContext'

const useStyles = makeStyles((theme) => ({
  actions: {
    paddingTop: '1rem',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: '0.5rem',
    alignItems: 'center',
    marginLeft: 'unset',
    '& a': {
      width: '100%'
    },
    [theme.breakpoints.up('md')]: {
      marginLeft: 'auto'
    }
  }
}))

const SectionToggleActions = ({ actions }) => {
  const classes = useStyles()
  const { selectedValue, options } = useAdvisorViewTabGroup()

  const selectedOption = useMemo(
    () => options.find(({ value }) => value === selectedValue),
    [options, selectedValue]
  )

  return (
    <div className={classes.actions}>
      {selectedOption?.payload?.actions || actions}
    </div>
  )
}

SectionToggleActions.propTypes = {
  actions: PropTypes.node
}

export default SectionToggleActions
