import PropTypes from 'prop-types'
import { Grid } from '@material-ui/core'
import { useFieldArray, Controller, useFormContext } from 'react-hook-form'
import clsx from 'clsx'
import { noop } from 'lodash/util'
import get from 'lodash/get'
import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Select from '../../../atoms/Select'
import { BUTTON_SIZES } from '../../../../constants'
import RoundedButton from '../../../atoms/RoundedButton'
import FeeScheduleDetail from './FeeScheduleDetail'

const useStyles = makeStyles((theme) => ({
  feeDetailsContainer: {
    border: '2px solid #E9EAEF',
    borderRadius: '6px',
    padding: '12px',
    marginBottom: '12px'
  },
  row: {
    marginBottom: '10px'
  },
  calcTypeRow: {
    padding: '8px 0',
    '& .fee': {
      display: 'inline-block',
      width: '100%',
      padding: '0 15px',
      textTransform: 'uppercase'
    }
  },
  emptyListMessage: {
    padding: '20px',
    fontWeight: 325,
    fontSize: '14px',
    '& .first-line': {
      fontSize: '16px'
    },
    textAlign: 'center',
    '& strong': {
      fontWeight: 700
    }
  },
  actionButtons: {
    padding: '4px 37px 2px'
  },
  deleteButton: {
    border: 'unset'
  },
  feeDetailsDropdown: {
    border: '1px solid #E9EAEF',
    borderRadius: '36px',
    width: '100%',
    '& .MuiSelect-select': {
      padding: '6px 20px',
      borderRadius: '36px'
    },
    '& .MuiSvgIcon-root': {
      right: '10px',
      position: 'absolute',
      zIndex: -1
    }
  },
  feeDetailsDropdownError: {
    borderColor: '#d50000',
    color: '#d50000',
    '& .MuiSelect-root': {
      color: '#d50000'
    },
    '& .MuiSvgIcon-root': {
      color: '#d50000'
    }
  }
}))

const defaultFeeType = {
  value: null,
  label: '',
  denomination: '',
  isSingleTier: false
}

const FeeScheduleDetailsList = ({
  name = '',
  calcType = null,
  isInEditMode = false,
  onDelete = noop,
  onSelectFeeScheduleType = noop,
  calcTypesOptions = [],
  disabledOptions = []
}) => {
  const {
    getValues,
    control,
    trigger,
    formState: { errors },
    setValue,
    setError,
    setFocus,
    clearErrors
  } = useFormContext()
  const [selectedFeeType, setSelectedFeeType] = useState()
  const [loadData, setLoadData] = useState(true)
  const [feeScheduleTypes, setFeeScheduleTypes] = useState([defaultFeeType])
  const classes = useStyles()
  const {
    remove,
    append,
    fields
  } = useFieldArray({
    name: `${name}.limits`,
    control
  })

  const onDeleteHandler = () => {
    remove()
    setSelectedFeeType(defaultFeeType)
    const hasMultipleLists = onDelete(selectedFeeType.value)
    if (!hasMultipleLists) {
      return setTimeout(() => {
        setError(`${name}.calcType`, { type: 'custom', message: 'Please select an option.' })
      }, 50)
    }
  }

  const getTiers = useCallback(() => {
    const [, parentIndex] = name.split('.')
    const formValues = getValues()
    return formValues.details[parentIndex]?.limits || []
  }, [getValues, name])

  const getLastTier = useCallback(() => {
    const tiers = getTiers()
    return [...tiers]?.pop() || {}
  }, [getTiers])

  const removeTier = useCallback(async (idx) => {
    remove(idx)
    if (idx === 0) {
      setTimeout(() => {
        setValue(`${name}.limits.${idx}.lowerLimit`, '0')
      }, 0)
    }
    await trigger()
  }, [remove, trigger, name, setValue])

  const addTier = useCallback(() => {
    const lastTier = getLastTier()
    const defaultLowerLimitValue = '0'
    const defaultFeeRate = '0'
    const defaultUpperLimitValue = null
    const lowerLimit = `${lastTier.upperLimit ?? defaultLowerLimitValue}`
    const upperLimit = lastTier.upperLimit === undefined ? `${defaultUpperLimitValue}` : null
    append({
      lowerLimit,
      upperLimit,
      feeRate: defaultFeeRate
    })
  }, [append, getLastTier])

  const isSingleTier = useMemo(() => {
    const { isSingleTier } = selectedFeeType || defaultFeeType
    return isSingleTier
  }, [selectedFeeType])

  const validateTierValues = useCallback(idx => {
    const tiers = getTiers()
    const currentTier = tiers[idx] || {}
    const nextTierIdx = idx + 1

    const nextTier = tiers[nextTierIdx]

    if (nextTier) {
      setValue(`${name}.limits.${nextTierIdx}.lowerLimit`, currentTier.upperLimit || '0')

      const hasInvalidUpperLimit = Number(nextTier.upperLimit) <= Number(nextTier.lowerLimit) && nextTier.upperLimit !== null

      if (hasInvalidUpperLimit) {
        setError(`${name}.limits.${nextTierIdx}.upperLimit`, { type: 'custom', message: 'Please update limit.' })
      } else {
        clearErrors(`${name}.limits.${nextTierIdx}.upperLimit`)
      }
      validateTierValues(nextTierIdx)
    }
  }, [getTiers, setValue, name, setError, clearErrors])

  const validateTier = useCallback((idx) => {
    const tiers = getTiers()
    const currentTier = tiers[idx] || {}

    if (isSingleTier) {
      return currentTier.feeRate === '' ? 'Please update fee.' : null
    }

    const isLowerThanLowerLimit = Number(currentTier.upperLimit) !== null && Number(currentTier.upperLimit) <= Number(currentTier.lowerLimit)
    const hasEmptyValue = currentTier.upperLimit === ''

    if (isLowerThanLowerLimit || hasEmptyValue) {
      return 'Please update limit.'
    }
  }, [getTiers, isSingleTier])

  const onChangeListItem = useCallback((idx, event = {}) => {
    const { addNextItem } = event
    if (addNextItem) {
      addTier()
    }
    validateTierValues(idx)
  }, [validateTierValues, addTier])

  const onEmpty = useMemo(() => {
    return (
      <div className={classes.emptyListMessage}>
        {isSingleTier ? (
          <div className='first-line'>
            <strong>Add Fee</strong>
          </div>
        ) : (
          <div className='first-line'>
            <strong>No Fee Details Yet</strong>
          </div>
        )}
        <div>
          Select a <strong>Fee Type</strong> to Begin
        </div>
      </div>
    )
  }, [isSingleTier, classes.emptyListMessage])

  useEffect(() => {
    if (loadData) {
      const selectedFeeType =
        calcTypesOptions.find((type) => type.value === calcType) ||
        defaultFeeType

      setSelectedFeeType({ ...selectedFeeType })

      if (!calcType) {
        setError(`${name}.calcType`, {
          type: 'custom',
          message: 'Please select an option.'
        })
      }
    }

    setFeeScheduleTypes(
      calcTypesOptions.map((item) => ({
        ...item,
        isDisabled: disabledOptions.some((value) => value === item.value)
      }))
    )
    setLoadData(false)
  }, [
    name,
    calcType,
    loadData,
    setError,
    setValue,
    selectedFeeType,
    disabledOptions,
    calcTypesOptions
  ])

  const isLastTierDisabled = useCallback((tierName, isLastTier = false) => {
    const hasErrors = !!get(errors, tierName)
    const tier = getValues(tierName)
    if (isLastTier && !isSingleTier) {
      return hasErrors || (tier && !Number(tier.upperLimit))
    }
    return false
  }, [isSingleTier, errors, getValues])

  return (
    <div className={classes.feeDetailsContainer}>
      <Grid container className={clsx(classes.row, classes.calcTypeRow)} justifyContent='space-between'>
        <Grid item sm={5}>
          {selectedFeeType && !isInEditMode && <span className='fee fee-type'>{selectedFeeType.label}</span>}
          {
            selectedFeeType && isInEditMode &&
              <Controller
                name={`${name}.calcType`}
                control={control}
                defaultValue=''
                render={({ field: { onChange, onBlur }, fieldState: { error, isTouched } }) => {
                  const showError = error && isTouched
                  return (
                    <Select
                      className={clsx(classes.feeDetailsDropdown, {
                        [classes.feeDetailsDropdownError]: showError
                      })}
                      options={feeScheduleTypes.map(
                        ({ value, label, isDisabled }) => ({
                          value,
                          label,
                          isDisabled
                        })
                      )}
                      onBlur={onBlur}
                      defaultValue=''
                      selectedValue={selectedFeeType.value}
                      disabled={!!selectedFeeType.value}
                      displayEmpty
                      displayCheckMark
                      placeholder='Select a Fee Type*'
                      onChange={(event) => {
                        const feeScheduleType = feeScheduleTypes.find(
                          (type) => type.value === event.target.value
                        )
                        setSelectedFeeType(feeScheduleType)
                        onChange(event.target.value)
                        clearErrors(`${name}.calcType`)
                        if (feeScheduleType.isSingleTier) {
                          append({
                            lowerLimit: '0',
                            upperLimit: null,
                            feeRate: '0'
                          })
                        } else {
                          append({
                            lowerLimit: '0',
                            upperLimit: null,
                            feeRate: '0'
                          })
                        }
                        onSelectFeeScheduleType(feeScheduleType.value)
                        setTimeout(
                          () =>
                            setFocus(`${name}.limits.0.feeRate`, {
                              shouldSelect: true
                            }),
                          0
                        )
                      }}
                    />
                  )
                }}
                rules={{ required: true }}
              />
          }
        </Grid>
        <Grid item sm={3} />
      </Grid>
      {fields.length === 0 && selectedFeeType && onEmpty}
      {selectedFeeType && fields.map((feeScheduleDetail, idx) => {
        return (
          <FeeScheduleDetail
            {...feeScheduleDetail}
            name={`${name}.limits.${idx}`}
            onChange={event => onChangeListItem(idx, event)}
            onDelete={() => removeTier(idx)}
            validate={() => validateTier(idx)}
            isInEditMode={isInEditMode}
            isSingleTier={isSingleTier}
            denomination={selectedFeeType.denomination}
            withDropdown={!isSingleTier && idx === fields.length - 1}
            isDisabled={isLastTierDisabled(`${name}.limits.${idx - 1}`, idx === fields.length - 1)}
            key={feeScheduleDetail.id}
          />
        )
      })}
      {isInEditMode && (
        <Grid container justifyContent='space-between'>
          <Grid item>
            <RoundedButton
              outlined
              size={BUTTON_SIZES.small}
              className={clsx(classes.actionButtons, classes.deleteButton)}
              onClick={onDeleteHandler}
            >
              <span>Delete</span>
            </RoundedButton>
          </Grid>
          <Grid item />
        </Grid>
      )}
    </div>
  )
}

FeeScheduleDetailsList.propTypes = {
  name: PropTypes.string.isRequired,
  calcType: PropTypes.number,
  isInEditMode: PropTypes.bool,
  onDelete: PropTypes.func,
  onSelectFeeScheduleType: PropTypes.func,
  disabledOptions: PropTypes.array,
  calcTypesOptions: PropTypes.array
}

export default FeeScheduleDetailsList
