import React from 'react'
import PropTypes from 'prop-types'
import { Box, makeStyles } from '@material-ui/core'
import isEmpty from 'lodash/isEmpty'
import ClientCardIntegrationButtons from '../../ClientCardView/ClientCard/ClientCardIntegrationButtons'
import { useContextContainer } from '../../../../../abundanceEngine/components/ContextContainer'
import { useAppContext } from '../../../../../redux/slices/appContext'
import { CLIENT_VIEW_CONTEXT } from '../..'

const useStyles = makeStyles(() => ({
  container: {
    position: 'inherit',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  }
}))

const ClientOptionsCell = ({ row: { original: client } }) => {
  const classes = useStyles()
  const { isAdvisor } = useAppContext()
  const [{ integrations }] = useContextContainer(CLIENT_VIEW_CONTEXT)

  const {
    showSalesforceClientInfo,
    showWealthBoxClientInfo,
    showRedTailClientInfo,
    isCrmAuthorized
  } = integrations

  const showIntegration = isAdvisor && isCrmAuthorized

  if (!showIntegration) return null

  return (
    <div className={classes.container}>
      <Box mr='0.5rem' />
      <ClientCardIntegrationButtons
        clientId={client.clientId}
        disabled={isEmpty(client.balance)}
        clientSalesforceId={client?.balance?.salesforceId}
        showSalesforceClientInfo={showSalesforceClientInfo}
        showWealthBoxClientInfo={showWealthBoxClientInfo}
        showRedTailClientInfo={showRedTailClientInfo}
      />
    </div>
  )
}

ClientOptionsCell.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      clientId: PropTypes.number,
      balance: PropTypes.object
    })
  })
}

export default ClientOptionsCell
