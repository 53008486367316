import { makeStyles, Tooltip } from '@material-ui/core'
import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { noop } from 'lodash'
import Icon from '../../../../atoms/Icon'
import theme from '../../../../../theme'
import { ADMIN_ROUTES, ICON_NAMES } from '../../../../../constants'
import { useBoolean } from '../../../../../hooks'

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    width: '3.5rem',
    justifyContent: 'space-between'
  },
  button: {
    width: '1rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    '&:disabled': {
      cursor: 'default',
      pointerEvents: 'none',
      opacity: 0.15
    }
  }
}))

export const BatchRunStatuses = Object.freeze({
  Created: 1,
  Resolving: 2,
  Completed: 3,
  CompletedWithErrors: 4,
  Error: 5,
  Shared: 6
})

const BatchRunActions = ({
  row: {
    original: { title, clientId, status, batchRunId }
  },
  onSetPostToClientVault = noop
}) => {
  const classes = useStyles()
  const [isLoading] = useBoolean(false)
  const [isError] = useBoolean(false)

  const onPostToClientVault = useCallback(async (value) => {
    onSetPostToClientVault({ batchRunId })
  }, [batchRunId, onSetPostToClientVault])

  const isShareable = status === BatchRunStatuses.Completed

  const isShareButtonEnabled = useMemo(() => {
    return !isLoading && isShareable && !isError
  }, [isLoading, isShareable, isError])

  const shareTooltipTitle = useMemo(() => {
    if (isLoading) return 'Loading'
    if (status === BatchRunStatuses.Shared) return 'Already shared'
    if (!isShareable) return 'Can not be shared'
    return 'Share to the Vault'
  }, [isLoading, isShareable, status])

  return (
    <div
      className={classes.container}
    >
      <Tooltip key={'previewTooltip-' + batchRunId} title='View Details' arrow placement='top'>
        <a
          href={ADMIN_ROUTES.REPORTS_BATCH.replace(':batchRunId', batchRunId)}
          key={'view-' + batchRunId}
          className={classes.button}
          type='view'
        >
          <Icon
            color={theme.palette.summitBlue}
            name={ICON_NAMES.list}
            customSize='1.5rem'
            variant='button'
          />
        </a>
      </Tooltip>

      <Tooltip key={'shareTooltip-' + batchRunId} title={shareTooltipTitle} arrow placement='top'>
        <span>
          <button
            key={'share-' + batchRunId}
            className={classes.button}
            type='button'
            onClick={
              !isShareButtonEnabled ? noop : onPostToClientVault
            }
            disabled={!isShareButtonEnabled}
          >
            <Icon
              color={theme.palette.summitBlue}
              name={ICON_NAMES.share}
              customSize='1.5rem'
              variant='button'
            />
          </button>
        </span>
      </Tooltip>
    </div>
  )
}

BatchRunActions.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      title: PropTypes.string,
      batchRunId: PropTypes.number,
      clientId: Promise.number,
      status: PropTypes.number
    })
  }),
  onSetPostToClientVault: PropTypes.func
}

export default BatchRunActions
