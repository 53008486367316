import React, { useCallback, useMemo } from 'react'
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import isEmpty from 'lodash/isEmpty'
import noop from 'lodash/noop'
import { Box, makeStyles } from '@material-ui/core'
import ListCard from '../../../molecules/ListCard'
import EmptySection from '../../../atoms/EmptySection'
import Select from '../../../molecules/Select'
import { CLIENT_BILLING_FORM_NAMES } from '../ClientDetailsView/helpers'
import Skeleton from '../../../atoms/Skeleton'
import FeeOverrideLevelSelect from './FeeOverrideLevelSelect'
import {
  FEE_OVERRIDE_LEVEL_VALUES,
  FEE_OVERRIDE_LEVEL_LABELS,
  FEE_LEVEL_OVERRIDE_OPTIONS,
  FEE_OVERRIDES_FORM_NAMES,
  mapAndValidateLevelOverrideOptions,
  shouldSetFeeOverrideError
} from './helpers'
import RemoveButton from './RemoveButton'

const { feeOverride: FEE_OVERRIDE } = CLIENT_BILLING_FORM_NAMES

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column'
  },
  disabled: {
    opacity: 0.5
  },
  titleContainer: {
    textAlign: 'left',
    marginBottom: theme.spacing(2)
  },
  spaceDivider: {
    minHeight: '1rem',
    width: '100%'
  },
  title: {
    textAlign: 'left',
    fontSize: '1.5rem'
  },
  detailsContent: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  multipleDetailsContent: {
    flexDirection: 'column'
  },
  itemContent: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  itemRow: {
    display: 'flex',
    alignItems: 'center'
  },
  itemAction: {
    cursor: 'pointer',
    marginLeft: '1rem'
  },
  emptyContainer: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    cursor: 'pointer'
  }
}))

const FeeOverridesList = ({
  editMode,
  disabled,
  feeScheduleSelectProps,
  levelOverrideOptions: _levelOverrideOptions
}) => {
  const classes = useStyles()
  const {
    control,
    trigger,
    setValue,
    setError,
    getValues,
    clearErrors,
    formState: { errors, defaultValues }
  } = useFormContext()

  const { fields, append, remove } = useFieldArray({
    control,
    name: FEE_OVERRIDE
  })

  const feeOverrideValues = useWatch({ name: FEE_OVERRIDE })

  const onChangeFeeOverride = useCallback(
    (index, name, levelName) => (value) => {
      const arrayName = `${FEE_OVERRIDE}.${index}.${name}`

      const {
        feeOverrideType,
        feeOverrideLevel,
        feeScheduleOverride
      } = FEE_OVERRIDES_FORM_NAMES

      if (name === feeOverrideType) {
        setValue(`${FEE_OVERRIDE}.${index}.${feeOverrideLevel}`, '', {
          shouldDirty: true
        })
        setValue(`${FEE_OVERRIDE}.${index}.${feeScheduleOverride}`, '', {
          shouldDirty: true
        })
      }

      setValue(arrayName, value, {
        shouldDirty: true
      })

      if (
        value === FEE_OVERRIDE_LEVEL_VALUES.account &&
        defaultValues.accountId
      ) {
        const overrideLevelName = `${FEE_OVERRIDE}.${index}.${feeOverrideLevel}`
        setValue(overrideLevelName, defaultValues.accountId)
      }

      const values = getValues()
      const errors = shouldSetFeeOverrideError(
        arrayName,
        value,
        values,
        levelName,
        index
      )
      for (const error of errors) {
        const { name, message } = error
        if (message) {
          setError(name, { type: 'custom', message })
          trigger(name)
        } else {
          clearErrors(name)
        }
      }
    },
    [
      trigger,
      setValue,
      setError,
      getValues,
      clearErrors,
      defaultValues
    ]
  )

  const feeOverrides = useMemo(() => {
    if (editMode) return feeOverrideValues
    return (feeOverrideValues || []).filter(
      (item) => item.feeLevelOverrideId && item.feeScheduleOverride
    )
  }, [editMode, feeOverrideValues])

  const onAddNewFeeOverride = useCallback(() => {
    append({
      feeLevelOverrideId: '',
      feeOverrideType: '',
      feeOverrideLevel: '',
      feeScheduleOverride: ''
    })
  }, [append])

  const levelOverrideOptions = useMemo(() => {
    return mapAndValidateLevelOverrideOptions(
      feeOverrides,
      _levelOverrideOptions
    )
  }, [feeOverrides, _levelOverrideOptions])

  const excludeLevelIds = useMemo(() => {
    const mapLevelId = ({ feeOverrideLevel }) => feeOverrideLevel
    return feeOverrides.filter(mapLevelId).reduce(
      (acc, { feeOverrideType, feeOverrideLevel }) => ({
        ...acc,
        [feeOverrideType]: [...(acc[feeOverrideType] || []), feeOverrideLevel]
      }),
      {}
    )
  }, [feeOverrides])

  const renderFeeOverrides = useMemo(() => {
    return feeOverrides.map((item, index) => {
      const isLastField = index === feeOverrides.length - 1

      const {
        feeOverrideType: overrideType,
        feeOverrideLevel: levelValue,
        feeScheduleOverride: feeScheduleValue
      } = item

      const { rules: levelOverrideRules } =
        levelOverrideOptions.find(({ value }) => value === overrideType) || {}

      const onChangeOverrideType = onChangeFeeOverride(
        index,
        FEE_OVERRIDES_FORM_NAMES.feeOverrideType
      )
      const onChangeOverrideLevel = onChangeFeeOverride(
        index,
        FEE_OVERRIDES_FORM_NAMES.feeOverrideLevel,
        FEE_OVERRIDE_LEVEL_LABELS[overrideType]
      )
      const onChangeFeeSchedule = onChangeFeeOverride(
        index,
        FEE_OVERRIDES_FORM_NAMES.feeScheduleOverride
      )

      return (
        <div
          key={`fee-override-${index}-${overrideType}`}
          className={clsx(
            classes.detailsContent,
            classes.multipleDetailsContent
          )}
        >
          <div className={classes.itemContent}>
            <Select
              disabled={disabled}
              readOnly={!editMode}
              options={levelOverrideOptions}
              onChange={onChangeOverrideType}
              value={overrideType}
              placeholder='Select Override Level'
              showCheckMarOnSelectedItems
              error={errors.feeOverride?.[index]?.feeOverrideLevelType}
            />
            <div className={classes.itemRow}>
              <Box textAlign='end'>
                {Boolean(overrideType) && (
                  <FeeOverrideLevelSelect
                    disabled={disabled}
                    levelValue={levelValue}
                    levelType={overrideType}
                    onChangeLevel={onChangeOverrideLevel}
                    excludeLevelIds={excludeLevelIds[overrideType]}
                    error={errors.feeOverride?.[index]?.feeOverrideLevel}
                    readOnly={!editMode || levelOverrideRules?.readOnlyValue}
                    defaultLevelId={
                      levelOverrideRules?.defaultValueIfSelected || levelValue
                    }
                    searchQueryOptions={levelOverrideRules?.searchQueryOptions}
                  />
                )}
                {!feeScheduleSelectProps?.isLoading
                  ? (
                    <Select
                      disabled={disabled || !overrideType}
                      readOnly={!editMode}
                      onChange={onChangeFeeSchedule}
                      value={feeScheduleValue}
                      placeholder='Select Fee Schedule'
                      {...feeScheduleSelectProps}
                      showCheckMarOnSelectedItems
                      error={errors.feeOverride?.[index]?.feeScheduleOverride}
                    />
                  ) : <Skeleton height='1.75rem' width='100%' />}
              </Box>
              {editMode && !disabled && (
                <RemoveButton onClick={() => remove(index)} />
              )}
            </div>
          </div>
          {!isLastField && <div className={classes.spaceDivider} />}
        </div>
      )
    })
  }, [
    remove,
    disabled,
    editMode,
    feeOverrides,
    excludeLevelIds,
    onChangeFeeOverride,
    levelOverrideOptions,
    feeScheduleSelectProps,
    classes.itemRow,
    errors.feeOverride,
    classes.itemContent,
    classes.spaceDivider,
    classes.detailsContent,
    classes.multipleDetailsContent
  ])

  const renderFeeOverridesPlaceholder = useMemo(() => {
    if (isEmpty(feeOverrides)) {
      return (
        <EmptySection
          title='No Overrides'
          description={editMode ? 'Click to add' : ''}
          onClick={editMode && !disabled ? onAddNewFeeOverride : noop}
          styles={{ cursor: 'pointer' }}
          containerClassName={clsx({ [classes.disabled]: disabled })}
        />
      )
    }
    return null
  }, [
    disabled,
    editMode,
    feeOverrides,
    onAddNewFeeOverride,
    classes.disabled
  ])

  return (
    <ListCard
      title='Fee Overrides'
      addButtonLabel='Add Override'
      noPadding={isEmpty(fields)}
      onAddClick={onAddNewFeeOverride}
      disableContainerHighlight={!editMode || disabled}
      showAddButton={!isEmpty(fields) && editMode && !disabled}
    >
      {renderFeeOverridesPlaceholder}
      {renderFeeOverrides}
    </ListCard>
  )
}

FeeOverridesList.propTypes = {
  editMode: PropTypes.bool,
  disabled: PropTypes.bool,
  accountOptions: PropTypes.array,
  feeScheduleSelectProps: PropTypes.shape({
    options: PropTypes.array,
    isLoading: PropTypes.bool,
    optionsRenderer: PropTypes.func
  }),
  levelOverrideOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
      rules: PropTypes.shape({
        max: PropTypes.number,
        readOnlyValue: PropTypes.bool,
        defaultValueIfSelected: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number
        ]),
        searchQueryOptions: PropTypes.object
      })
    })
  )
}

FeeOverridesList.defaultProps = {
  editMode: false,
  disabled: false,
  accountOptions: [],
  feeScheduleSelectProps: {
    options: [],
    isLoading: false,
    optionsRenderer: noop
  },
  levelOverrideOptions: FEE_LEVEL_OVERRIDE_OPTIONS
}

export default FeeOverridesList
