import React, { useCallback, useMemo, useState } from 'react'
import { Box, Grid } from '@material-ui/core'
import _isEmpty from 'lodash/isEmpty'
import clsx from 'clsx'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'
import { makeStyles } from '@material-ui/styles'
import PropTypes from 'prop-types'
import NumberFormat from '../../atoms/NumberFormat'
import { DISPLAY_VBS_NUMBER_FORMAT as NUMBER_FORMAT, TITLE_VBS_NUMBER_FORMAT } from '../../../constants'
import EditAccountButton from './EditAccountButton'

const useStyles = makeStyles((theme) => ({
  tooltipPopover: {
    right: 0
  },
  tooltip: {
    transform: 'translate(0, -98%)',
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.14)',
    borderRadius: '1rem',
    maxWidth: 'none',
    padding: '20px 30px',
    zIndex: 1,
    position: 'absolute',
    top: 0,
    right: 0,
    left: 0
  },
  tooltipArrow: {
    position: 'absolute',
    width: 0,
    height: 0,
    borderLeft: '1.5rem solid transparent',
    borderRight: '1.5rem solid transparent',
    borderTop: `1.5rem solid ${theme.palette.white}`,
    bottom: '-0.5rem',
    left: '50%',
    transform: 'translate(-50%, 50%)'
  },
  tooltipBelow: {
    transform: 'translate(0, -2%)',
    top: '100%'
  },
  tooltipArrowBelow: {
    transform: 'translate(-24px,-98%) rotate(180deg)',
    bottom: 'auto',
    top: '4px'
  },
  tooltipTitle: {
    textTransform: 'uppercase',
    marginBottom: '12px',
    color: theme.palette.dustyGray,
    fontSize: '0.875rem'
  },
  tooltipItem: {
    marginBottom: '5px'
  },
  tooltipItemTitle: {
    fontSize: '1rem',
    fontWeight: 'normal',
    color: theme.palette.dustyGray
  },
  tooltipItemSubtitle: {
    fontSize: '1rem',
    color: theme.palette.dustyGray,
    textAlign: 'right'
  },
  tooltipItemHovered: {
    fontWeight: 'bold',
    color: `${theme.palette.common.black} !important`
  }
}))

const BlockTooltip = ({
  blocks,
  tooltipNumberFormat,
  backgroundColor,
  useParenthesis,
  subtitleText,
  itemHovered,
  formatter
}) => {
  const [topAttribute, setTopAttribute] = useState(100)
  const showBelow = useMemo(() => topAttribute < 60, [topAttribute])
  const classes = useStyles()
  const tooltipRef = useCallback(node => {
    if (node !== null) {
      setTopAttribute(node.getBoundingClientRect().top)
    }
  }, [])

  const middle = useMemo(() => Math.ceil(blocks.length / 2), [blocks])
  const columns = useMemo(() => blocks.reduce(
    (acc, block, index) => {
      if (index < middle) {
        return { ...acc, firstColumn: [...acc.firstColumn, block] }
      }
      return { ...acc, secondColumn: [...acc.secondColumn, block] }
    },
    { firstColumn: [], secondColumn: [] }
  ), [blocks, middle])

  return (
    <>
      <div ref={tooltipRef} className={clsx(classes.tooltip, showBelow && classes.tooltipBelow)}>
        <div className={clsx(classes.tooltipArrow, showBelow && classes.tooltipArrowBelow)} />
        <div className={classes.tooltipTitle}>{subtitleText}</div>
        <Grid container spacing={2}>
          {Object.values(columns).map((column, index) => {
            if (_isEmpty(column)) {
              return null
            }
            const hasMultipleColumns = columns.firstColumn.length > 1
            return (
              <Grid
                item
                xs={hasMultipleColumns ? 6 : 12}
                key={`tooltip-column-${index + 1}`}
              >
                {column.map((block) => {
                  let isHovered = false
                  if (!block.showBlock && itemHovered === 'remaining') {
                    isHovered = true
                  }
                  if (block.showBlock && block.accountId === itemHovered) {
                    isHovered = true
                  }

                  return (
                    <div className={classes.tooltipItem} key={block.accountId}>
                      <Grid container spacing={2}>
                        <Grid
                          item
                          xs={12}
                          className={clsx(classes.tooltipItemTitle, {
                            [classes.tooltipItemHovered]: isHovered
                          })}
                        >
                          <Box
                            display='flex'
                            flexDirection='row'
                            alignItems='center'
                            position='relative'
                          >
                            <EditAccountButton accountId={block.accountId} />
                            <Box mr='0.75rem'>
                              <FiberManualRecordIcon
                                style={{
                                  fontSize: 8,
                                  color: backgroundColor,
                                  opacity: isHovered ? 1 : 0.5
                                }}
                              />
                            </Box>
                            {block.title}
                            <Box
                              ml='auto'
                              fontWeight='bold'
                              pr={hasMultipleColumns ? '1rem' : 0}
                            >
                              <NumberFormat
                                title={formatter(
                                  block.endingValue,
                                  tooltipNumberFormat ?? TITLE_VBS_NUMBER_FORMAT
                                )}
                                number={formatter(
                                  block.endingValue,
                                  tooltipNumberFormat ?? NUMBER_FORMAT.SHORT
                                )}
                                skipFormat
                                useParenthesis={useParenthesis}
                              />
                            </Box>
                          </Box>
                        </Grid>
                      </Grid>
                    </div>
                  )
                })}
              </Grid>
            )
          })}
        </Grid>
      </div>
    </>
  )
}

BlockTooltip.propTypes = {
  blocks: PropTypes.array,
  tooltipNumberFormat: PropTypes.string,
  backgroundColor: PropTypes.string,
  useParenthesis: PropTypes.bool,
  subtitleText: PropTypes.string,
  itemHovered: PropTypes.string,
  formatter: PropTypes.func
}

export default BlockTooltip
