import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import SelectWithCheckbox from '../../../components/molecules/Select/SelectWithCheckbox'
import { useFilterSubscription } from '../useFilterSubscription'
import { useFilterSubscriptionContext } from '../FilterSubscriptionProvider'

const GenericStaticSubscriptionFilter = ({
  placeholder,
  prefixLabel,
  options,
  filterSubscription,
  filterKey
}) => {
  const { publishFilters } = useFilterSubscription({
    publishKeys: filterSubscription.publish
  })

  const { filters, initialFilters } = useFilterSubscriptionContext()

  const defaultOptions = useMemo(() => {
    const levelFilters = filters?.levelFilters ?? initialFilters?.levelFilters ?? {}
    const defaultIds = levelFilters?.[filterKey] ?? []
    return options.filter(({ value }) => defaultIds.includes(value))
  }, [
    options,
    filterKey,
    filters?.levelFilters,
    initialFilters?.levelFilters
  ])

  const onChange = (value) => {
    const values = value.map(({ value: val }) => val)
    publishFilters((prevState) => ({
      ...prevState,
      levelFilters: {
        ...(prevState?.levelFilters ?? {}),
        [filterKey]: values
      }
    }))
  }

  return (
    <SelectWithCheckbox
      placeholder={placeholder}
      prefixLabel={prefixLabel}
      options={options}
      onChange={onChange}
      selectedOptions={defaultOptions}
    />
  )
}

const optionsShape = PropTypes.arrayOf(
  PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string
  })
)

GenericStaticSubscriptionFilter.propTypes = {
  filterKey: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  prefixLabel: PropTypes.string,
  options: optionsShape,
  filterSubscription: PropTypes.shape({
    publish: PropTypes.arrayOf(PropTypes.string)
  })
}

GenericStaticSubscriptionFilter.defaultProps = {
  placeholder: '',
  prefixLabel: '',
  options: [],
  filterSubscription: {
    publish: []
  }
}

export default GenericStaticSubscriptionFilter
