import KeyMirror from 'keymirror'

export const SELECT_FILTER_VARIANTS = KeyMirror({
  numerical: null,
  currency: null,
  percentage: null,
  date: null,
  count: null,
  list: null
})
