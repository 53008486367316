import React, { useMemo } from 'react'
import dayjs from 'dayjs'
import numeral from 'numeral'
import { Tooltip } from '@material-ui/core'
import { compareNumericString } from '../../../../utils'

export const cellTemplates = {
  date: ({ value }) => dayjs.utc(value).format('M/D/YYYY'),
  number: ({ value }) => value,
  // eslint-disable-next-line eqeqeq
  units: ({ value }) => (value == 0 ? '--' : numeral(value).format('0,0.00')),
  money: ({ value }) => numeral(value).format('0,0.00'),
  returns: ({ value }) => numeral(value).format('0.00%'),
  tags: ({ value }) => (
    <span>
      {value.map((x) => (
        <span
          style={{
            border: '1px solid gray',
            padding: '2px',
            marginRight: '2px'
          }}
          key={x.transactionCodeTagId}
        >
          {x.displayName}
        </span>
      ))}
    </span>
  ),
  noWrap: ({ value }) => (
    <Tooltip title={value}>
      <span
        style={{
          width: '20rem',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          position: 'relative',
          display: 'inline-block'
        }}
      >
        {value}
      </span>
    </Tooltip>
  )
}

export const defaultColumnConfig = {
  columns: [
    { id: 'valueDate', Header: 'Date', accessor: 'valueDate', Cell: cellTemplates.date },
    { id: 'accountNumber', Header: 'Acct Number', accessor: 'accountNumber' },
    { id: 'assetName', Header: 'Asset Name', accessor: 'assetName', Cell: cellTemplates.noWrap },
    { id: 'assetIdentifier', Header: 'Symbol', accessor: 'assetIdentifier' },
    { id: 'custodianName', Header: 'Custodian', accessor: 'custodianName' },
    { id: 'transactionDescription', Header: 'Description', accessor: 'transactionDescription', disableSortBy: true },
    { id: 'marketValue', Header: 'Value', accessor: 'marketValue', type: 'money', alignRight: true },
    { id: 'units', Header: 'Units', accessor: 'units', Cell: cellTemplates.units, alignRight: true, sortType: compareNumericString },
    { id: 'transactionCodeTags', Header: 'Tags', accessor: 'transactionCodeTags', Cell: cellTemplates.tags, disableSortBy: true, alignRight: true },
    // Hidden columns
    { id: 'accountName', Header: 'Account Name', accessor: 'accountName', hidden: true },
    { id: 'cusip', Header: 'CUSIP', accessor: 'cusip', hidden: true },
    { id: 'assetId', Header: 'Asset ID', accessor: 'assetId', hidden: true },
    { id: 'tradeDate', Header: 'Trade Date', accessor: 'tradeDate', hidden: true, Cell: cellTemplates.date },
    { id: 'settleDate', Header: 'Settle Date', accessor: 'settleDate', hidden: true, Cell: cellTemplates.date },
    { id: 'adjustedMarketValue', Header: 'Value - Cash Impact', accessor: 'adjustedMarketValue', type: 'money', alignRight: true, hidden: true }

  ],
  defaultSort: [{ id: 'valueDate', desc: true }]
}

export const columnConfigMap = {
  default: defaultColumnConfig
}

const columnTemplateConfigurations = {
  string: (col) => col,
  money: (col) => ({
    ...col,
    Cell: cellTemplates.money,
    alignRight: true,
    sortType: compareNumericString
  })
}

export const useColumnConfig = (config = 'default') => {
  return useMemo(() => {
    const normalized = config.toLowerCase()

    const columnConfig = (normalized in columnConfigMap)
      ? columnConfigMap[normalized]
      : columnConfigMap.default

    return {
      columns: columnConfig.columns.map((x) => {
        const type = x.type in columnTemplateConfigurations
          ? x.type
          : 'string'
        return {
          ...x,
          ...columnTemplateConfigurations[type](x)
        }
      }),
      defaultSort: columnConfig.defaultSort
    }
  }, [config])
}
