import React, { useMemo } from 'react'
import { darken } from '@material-ui/core'
import PropTypes from 'prop-types'

const PyramidDivider = ({
  sourceColor,
  index,
  pyramidWidth,
  height = 21,
  angleRatio = 1.52,
  isPulsing = false
}) => {
  const angleOffset = useMemo(() => (height * angleRatio).toFixed(0), [height, angleRatio])

  const dimensions = useMemo(() => ({
    width: (pyramidWidth * 0.63) + ((index - 1) * 188) + (angleOffset / 2),
    height: height
  }), [angleOffset, height, index, pyramidWidth])
  const color = useMemo(() => darken(sourceColor, 0.2), [sourceColor])

  return (
    <svg
      width={dimensions.width}
      height={dimensions.height}
      viewBox={`0 0 ${dimensions.width} ${dimensions.height}`}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      style={{ display: 'block', margin: '0 auto', position: 'relative', left: angleOffset / 4 }}
      className={isPulsing ? 'MuiSkeleton-pulse' : ''}
    >
      <path
        d={`M${angleOffset} ${dimensions.height}L0 0H${dimensions.width - angleOffset}L${dimensions.width} ${dimensions.height}H`}
        fill={color ?? '#333'}
      />
    </svg>
  )
}

PyramidDivider.propTypes = {
  sourceColor: PropTypes.string,
  index: PropTypes.number,
  height: PropTypes.number,
  angleRatio: PropTypes.number,
  pyramidWidth: PropTypes.number,
  isPulsing: PropTypes.bool
}

export default PyramidDivider
