import { ClickAwayListener, IconButton, InputBase } from '@material-ui/core'
import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Icon from '../../../atoms/Icon'
import { ICON_NAMES } from '../../../../constants'
import Text from '../../../atoms/Text'

const QuickEditShortName = ({ classes, initialValue, onChange, defaultIsEditable = false }) => {
  const [isEditable, setIsEditable] = useState(defaultIsEditable)
  const [value, setValue] = useState(initialValue)

  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  const toggleIsEditable = useCallback((forceState) => {
    const state = forceState ?? !isEditable
    setIsEditable(state)
  }, [isEditable])

  const onSaveChange = useCallback(() => {
    onChange({ shortName: value })
    toggleIsEditable(false)
  }, [onChange, toggleIsEditable, value])

  const onReset = useCallback(() => {
    setValue(initialValue)
    toggleIsEditable(false)
  }, [initialValue, toggleIsEditable])

  return (
    <>
      {isEditable ? (
        <ClickAwayListener onClickAway={onSaveChange}>
          <div className={classes.inputContainer}>
            <InputBase
              value={value}
              onChange={(e) => setValue(e.target.value)}
              onKeyUp={e => e.key === 'Enter' && onSaveChange()}
              classes={{ input: classes.quickEditInput }}
              autoFocus
            />
            <IconButton
              classes={{ root: classes.iconButton }}
              onClick={onSaveChange}
              color='inherit'
            >
              <Icon name={ICON_NAMES.check} customSize='1.2rem' />
            </IconButton>
            <IconButton
              onClick={onReset}
              color='inherit'
            >
              <Icon name={ICON_NAMES.close} customSize='1.2rem' />
            </IconButton>
          </div>
        </ClickAwayListener>
      ) : (
        <div
          className={classes.container}
        >
          <Text text={value} className={classes.quickEditDisplay} />
          <IconButton
            onClick={() => toggleIsEditable(true)}
            color='inherit'
          >
            <Icon name={ICON_NAMES.edit} customSize='1.2rem' />
          </IconButton>
        </div>
      )}
    </>
  )
}

QuickEditShortName.propTypes = {
  classes: PropTypes.objectOf(PropTypes.shape({
    container: PropTypes.string,
    inputContainer: PropTypes.string,
    quickEditInput: PropTypes.string,
    quickEditDisplay: PropTypes.string
  })),
  initialValue: PropTypes.string,
  onChange: PropTypes.func,
  defaultIsEditable: PropTypes.bool
}

export default QuickEditShortName
