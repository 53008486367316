import React from 'react'
import PropTypes from 'prop-types'
import { isString } from 'lodash'
import clsx from 'clsx'
import { makeStyles, useMediaQuery, useTheme } from '@material-ui/core'
import SectionActions from './SectionActions'

const useStyles = makeStyles(() => ({
  container: {
    width: '100%'
  },
  title: {
    fontSize: '1.25rem'
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '1rem',
    paddingBottom: '1.25rem'
  }
}))

const SectionTitle = ({ title, titleAdornment, actions }) => {
  const classes = useStyles()
  const theme = useTheme()
  const matchesBreakpoint = useMediaQuery(theme.breakpoints.up('md'))

  if (!title) return null

  return (
    <div className={classes.container}>
      <div
        className={clsx(classes.titleContainer, {
          [classes.title]: isString(title)
        })}
      >
        {title}
        {titleAdornment}
        {actions && matchesBreakpoint && <SectionActions actions={actions} />}
      </div>
    </div>
  )
}

SectionTitle.propTypes = {
  actions: PropTypes.node,
  titleAdornment: PropTypes.node,
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired
}

export default SectionTitle
