
import { useMemo } from 'react'
import { useAccountStatusDetailsSearch } from '../../../../api/coreData'

const getDetailsQuery = (query = {}) => {
  const skip = query?.skip ?? 0
  const take = query?.take ?? 100
  const sort = query?.sort
  const includes = {
    custodian: true,
    asOfDayValues: true,
    accountStatus: true
  }
  return {
    ...query,
    includes,
    skip,
    take,
    sort,
    resultType: 'details'
  }
}

const mapSort = (sorts) => sorts?.map(({ id, desc }) => ({ field: id, dir: desc ? 'desc' : 'asc' }))

export const useClientAccounts = ({
  pageIndex,
  pageSize,
  sort,
  filters
}) => {
  const query = useMemo(() => {
    return getDetailsQuery({
      skip: pageIndex * pageSize,
      take: pageSize,
      sort: mapSort(sort),
      filters
    })
  }, [pageSize, pageIndex, sort, filters])

  const {
    data,
    isLoading,
    isPreviousData,
    error
  } = useAccountStatusDetailsSearch(query)

  return {
    data: isLoading ? [] : data.data,
    loading: isLoading || isPreviousData,
    error
  }
}
