import React, { useCallback, useState } from 'react'
import { makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import SearchBarInput from '../../../molecules/SearchBar/SearchBarInput'
import { useDebouncedCallback } from '../../../../hooks'

const useStyles = makeStyles(() => ({
  container: {
    maxWidth: '26rem',
    marginBottom: '1rem'
  }
}))

const TasksSearchBar = ({ onChange, isLoading }) => {
  const classes = useStyles()
  const [queryText, setQueryText] = useState('')

  const onChangeQueryDebounced = useDebouncedCallback(
    useCallback(
      (value) => {
        onChange(value)
      },
      [onChange]
    )
  )

  const onChangeQuery = useCallback(
    (value) => {
      setQueryText(value)
      onChangeQueryDebounced(value)
    },
    [onChangeQueryDebounced]
  )

  return (
    <div className={classes.container}>
      <SearchBarInput
        value={queryText}
        loading={isLoading}
        setValue={onChangeQuery}
        placeholder='Search by client name'
      />
    </div>
  )
}

TasksSearchBar.propTypes = {
  onChange: PropTypes.func,
  isLoading: PropTypes.bool
}

export default TasksSearchBar
