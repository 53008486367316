import React from 'react'
import CommentEditor from './CommentEditor'
import { useComments } from './CommentContext'

function NewCommentPlaceholder () {
  const { editing, stopAdding, finishAdding } = useComments()

  if (editing?.commentKey !== 'new') {
    return null
  }

  return (
    <CommentEditor onSubmit={finishAdding} onCancel={stopAdding} />
  )
}

NewCommentPlaceholder.propTypes = {
}

export default NewCommentPlaceholder
