import React, { useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { CircularProgress, makeStyles, Grid } from '@material-ui/core'
import { useManualPositionBatchDetails } from '../../../../../api/accounts'
import FadeIn from '../../../../molecules/Transitions/FadeIn'
import { useFormattingContext } from '../../../../organisms/FormattingProvider/FormattingContext'
import Icon from '../../../../atoms/Icon'
import PersonalSpace from '../../shared/PersonalSpace'

const useStyles = makeStyles((theme) => ({
  detail: {
    padding: '0 20px'
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    gap: '10px',
    alignItems: 'center'
  },
  section: {
    border: `1px solid ${theme.palette.gray.dark}`,
    borderRadius: '8px',
    padding: '10px',
    '& table td': {
      padding: '0 5px'
    }
  }
}))

function BatchDetail () {
  const classes = useStyles()
  const { formatter } = useFormattingContext()
  const { batchId } = useParams()
  const [refetchInterval, setRefetchInterval] = useState()
  const { data, isLoading } = useManualPositionBatchDetails(+batchId, { refetchInterval })

  const status = data?.batch?.status

  useMemo(() => {
    setRefetchInterval(status === 'Running' ? 5000 : undefined)
  }, [status])

  if (isLoading) {
    return (
      <FadeIn className={classes.detail} key='loading'>
        <CircularProgress />
      </FadeIn>
    )
  }

  return (
    <FadeIn className={classes.detail} key='detail'>
      <header className={classes.header}>
        <h2>Batch {data.batch.batchId}</h2>
        {status === 'Running' ? (<CircularProgress size={16} />) : null}
        {status === 'Complete' ? (<Icon name='check' color='green' customSize={20} />) : null}
        {status === 'Error' ? (<Icon name='close' color='red' customSize={20} />) : null}
        {status === 'Stalled' ? (<Icon name='close' color='red' customSize={20} />) : null}
      </header>
      <Grid container spacing={2}>
        <Grid item lg={6} xs={12}>
          <div className={classes.section}>
            <table>
              <tbody>
                <tr>
                  <td>Created By</td>
                  <td>{data.batch.createdBy}</td>
                </tr>
                <tr>
                  <td>Created At</td>
                  <td>{formatter(data.batch.createdDate, '&M/D/YY h:mm a')}</td>
                </tr>
                <tr>
                  <td>Status</td>
                  <td>{data.batch.status ?? '--'}</td>
                </tr>
                <tr>
                  <td>Record Count</td>
                  <td>{formatter(data.batch.recordCount, '0,0')}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </Grid>
        <Grid item lg={6} xs={12}>
          <div className={classes.section}>
            <table>
              <thead>
                <tr>
                  <th>Task ID</th>
                  <th>Task Order</th>
                  <th>Task Type</th>
                  <th>Status</th>
                  <th>Last Updated</th>
                </tr>
              </thead>
              <tbody>
                {data.tasks.map(t => (
                  <tr key={t.taskId}>
                    <td>{t.taskId}</td>
                    <td>{t.taskPriority}</td>
                    <td>{t.taskType}</td>
                    <td>
                      {t.isError ? (
                        <Icon name='close' color='red' />
                      ) : t.isComplete ? (
                        <Icon name='check' color='green' />
                      ) : '--'}
                    </td>
                    <td>{formatter(t.updatedDate, '&M/D/YY h:mm a')}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </Grid>
      </Grid>
      <PersonalSpace />
    </FadeIn>
  )
}

export default BatchDetail
