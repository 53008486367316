import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { $getRoot, CLEAR_HISTORY_COMMAND } from 'lexical'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { TRANSFORMERS, $convertFromMarkdownString } from '@lexical/markdown'
import { $generateNodesFromDOM } from '@lexical/html'

const OnSetInitialStatePlugin = ({ initialState, isMarkdown }) => {
  const [editor] = useLexicalComposerContext()

  useEffect(() => {
    if (isMarkdown) {
      editor.update(() => {
        $convertFromMarkdownString(initialState, TRANSFORMERS)
      })
    } else {
      if (typeof initialState === 'string') {
        editor.update(() => {
          const parser = new DOMParser()
          const dom = parser.parseFromString(initialState, 'text/html')
          const nodes = $generateNodesFromDOM(editor, dom)

          const selection = $getRoot().select()
          selection.insertNodes(nodes)

          editor.dispatchCommand(CLEAR_HISTORY_COMMAND, undefined)
        })
      } else if (initialState?.editorState) {
        // Restore from SerializedEditorState
        const editorState = editor.parseEditorState(initialState.editorState)
        editor.setEditorState(editorState)
        editor.dispatchCommand(CLEAR_HISTORY_COMMAND, undefined)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return null
}

OnSetInitialStatePlugin.propTypes = {
  initialState: PropTypes.any,
  isMarkdown: PropTypes.bool
}

OnSetInitialStatePlugin.defaultProps = {
  initialState: null,
  isMarkdown: false
}

export default OnSetInitialStatePlugin
