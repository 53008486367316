import React from 'react'
import PropTypes from 'prop-types'
import { IconButton, makeStyles, Tooltip } from '@material-ui/core'
import Icon from '../../atoms/Icon'
import { useStatusReportContext } from './statusReportContext'

const useStyles = makeStyles((theme) => ({
  actionContainer: {
    position: 'relative'
  },
  actions: {
    position: 'absolute',
    top: 0,
    right: 0,
    zIndex: 10
  }
}))

function StatusReportActions ({ children, expandAll }) {
  const classes = useStyles()
  const { setReportExpanded, reportExpanded } = useStatusReportContext()

  return (
    <div className={classes.actionContainer}>
      <div className={classes.actions}>
        {expandAll ? (
          <Tooltip title={`${reportExpanded ? 'Collapse' : 'Expand'} All`} placement='top'>
            <IconButton onClick={() => setReportExpanded(x => !x)}>
              <Icon name={reportExpanded ? 'collapse' : 'expand'} />
            </IconButton>
          </Tooltip>
        ) : null}
      </div>
      {children}
    </div>
  )
}

StatusReportActions.propTypes = {
  children: PropTypes.node,
  expandAll: PropTypes.bool
}

export default StatusReportActions
