/* eslint-disable no-console */
/* eslint-disable no-plusplus */
/* eslint-disable max-len */
/* eslint-disable comma-dangle */
/* eslint-disable no-use-before-define */

// Run Monte Carlo (Iterations)

const runSimulation = async (simulationDetails, assumptionsData) => {
  const returnData = {
    simulationDetails,
    simulationData: [],
    percentiles: [],
    processTime: 0,
    successRate: 0
  }

  const startTime = Date.now()
  const iterations = [...Array(simulationDetails.iterations)]

  const dataPeriods = initializePeriods(simulationDetails, assumptionsData)

  // REWORK periods

  iterations.forEach((iteration, i) => {
    const returns = simulateReturns(i, simulationDetails, dataPeriods)
    // TODO: Filter assumption data
    const values = calcReturnValues(simulationDetails, returns, assumptionsData)
    const endValue = values[values.length - 1].endValue
    const cr = values[values.length - 1].cumulativeReturn
    const cr1 = values[values.length - 30].cumulativeReturn
    const cr3 = values[values.length - 28].cumulativeReturn
    const cr5 = values[values.length - 26].cumulativeReturn
    const cr10 = values[values.length - 21].cumulativeReturn
    let lastFundDate = values[values.length - 1].periodDate
    if (endValue === 0) {
      lastFundDate = values.filter(x => x.endValue === 0)[0].periodDate
    }
    const simData = {
      iteration: i,
      endValue,
      cr10,
      cr,
      cr5,
      cr1,
      cr3,
      lastFundDate,
      values
    }
    returnData.simulationData.push(simData)
  })
  const endTime = Date.now()
  returnData.processTime = endTime - startTime

  const q90 = { percentile: '90', data: percentile(returnData.simulationData, 0.90) }
  const q75 = { percentile: '75', data: percentile(returnData.simulationData, 0.75) }
  const q70 = { percentile: '70', data: percentile(returnData.simulationData, 0.70) }
  const q67 = { percentile: '67', data: percentile(returnData.simulationData, 0.667) }
  const q60 = { percentile: '60', data: percentile(returnData.simulationData, 0.60) }
  const q50 = { percentile: '50', data: percentile(returnData.simulationData, 0.50) }
  const q33 = { percentile: '33', data: percentile(returnData.simulationData, 0.333) }
  const q30 = { percentile: '30', data: percentile(returnData.simulationData, 0.30) }
  const q25 = { percentile: '25', data: percentile(returnData.simulationData, 0.25) }
  const q10 = { percentile: '10', data: percentile(returnData.simulationData, 0.10) }
  const q05 = { percentile: '5', data: percentile(returnData.simulationData, 0.05) }

  // console.log('q75', q75)

  const successes = returnData.simulationData.filter((x) => x.endValue > 0)
  returnData.successRate = successes.length / iterations.length
  returnData.percentiles.push(q90, q75, q70, q67, q60, q50, q33, q30, q25, q10, q05)

  return returnData
}

const initializePeriods = (simulationDetails, assumptionsData) => {
  const simulationData = []

  const dataPeriods = [...Array(simulationDetails.periods)]
  dataPeriods.forEach((_, i) => {
    const periodDate = addToDate(simulationDetails.startDate, simulationDetails.frequency, i) //  new Date(simulationDetails.startDate); //.format('YYYY-MM-DD'));
    const stopDate = addToDate(simulationDetails.startDate, simulationDetails.frequency, i + 1) //  new Date(simulationDetails.startDate); //.format('YYYY-MM-DD'));

    // filter is SLOW - for 100 iterations, takes 150ms vs 15ms
    const flows = assumptionsData.filter((y) => {
      return new Date(y.valueDate) >= periodDate && new Date(y.valueDate) < stopDate
    })

    simulationData.push({
      period: i,
      periodDate,
      periodReturn: -100,
      flows
    })
  })

  return simulationData
}

const calcReturnValues = (simulationDetails, returns, assumptionsData) => {
  let endValue = simulationDetails.startValue * 1
  let cumulativeReturn = 0
  const values = []
  returns.forEach((x) => {
    const startValue = endValue * 1

    endValue *= (1 + x.periodReturn)
    if (endValue !== 0) {
      cumulativeReturn = ((1 + cumulativeReturn) * (1 + x.periodReturn)) - 1
    }

    // flows before applying the returns - need to add customized timings (adds at end, et al)
    x.flows.forEach((flow) => {
      endValue += flow.value
    })

    endValue = (endValue * 1 < 0 ? 0 : endValue)

    values.push({ startValue, endValue, period: x.period, periodDate: x.periodDate, periodReturn: x.randomNumber, cumulativeReturn })
  })

  return values
}

// simulateReturns(mean, stdev, correlation[], periods)
const simulateReturns = (iteration, simulationDetails, dataPeriods) => {
  const { mean, standardDeviation, correlations } = simulationDetails
  const returnPeriods = dataPeriods.map((period) => {
    const randomNumber = gaussianRandom(mean, standardDeviation, correlations)
    const newPeriod = period
    newPeriod.periodReturn = randomNumber
    return newPeriod
  })

  return returnPeriods
}

// const gaussianRandom = (mean, sigma, correlation) => {
//   const u = Math.random() * 0.682
//   return ((u % 1e-8 > 5e-9 ? 1 : -1) * (Math.sqrt(-Math.log(Math.max(1e-9, u))) - 0.618)) * 1.618 * sigma + mean
// }

const addToDate = (startDate, addType, addAmount) => {
  const sourceDate = new Date(startDate)
  const year = sourceDate.getFullYear()
  const month = sourceDate.getMonth()
  const day = sourceDate.getDate()
  let newDate = new Date(sourceDate)

  if (addType === 'y') {
    newDate = new Date(year + addAmount, month, day)
  }

  return newDate
}

const percentile = (arr, q) => {
  const sorted = [...arr].sort((a, b) => {
    if (a.endValue === b.endValue) {
      return a.lastFundDate - b.lastFundDate
    }
    return a.endValue - b.endValue
  })
  const pos = (sorted.length - 1) * q
  const base = Math.floor(pos)
  // console.log(q, sorted[base])
  const returnData = getGeometricReturn(sorted[base].values)
  sorted[base].returnData = returnData
  return sorted[base]
}

const getGeometricReturn = arr => {
  const totalReturn = arr[arr.length - 1].cumulativeReturn
  // console.log('totalReturn', totalReturn)
  const geoReturn = Math.pow((totalReturn + 1), 1 / arr.length) - 1
  // console.log('geoReturn', geoReturn)
  return { totalReturn, geoReturn }
}

const boxMullerRandom = () => {
  let generate = true
  let value0 = 0.0
  let value1 = 0.0
  let result

  if (generate) {
    let x1 = 0.0
    let x2 = 0.0
    let w = 0.0

    do {
      // Math.random() gives value on range [0, 1) but
      // the Polar Form expects [-1, 1].
      x1 = (2.0 * Math.random()) - 1.0
      x2 = (2.0 * Math.random()) - 1.0
      w = (x1 * x1) + (x2 * x2)
    } while (w >= 1.0)

    w = Math.sqrt((-2.0 * Math.log(w)) / w)

    value0 = x1 * w
    value1 = x2 * w

    result = value0
  } else {
    result = value1
  }

  generate = !generate
  return result
}

const gaussianRandom = (mean, stddev, correlations) => {
  const value = boxMullerRandom()
  return ((value * stddev) + mean)
}

const exports = {
  runSimulation,
  gaussianRandom
}

export default exports
