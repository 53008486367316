import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Box } from '@material-ui/core'
import { useController } from 'react-hook-form'
import useSearchClientsDebounced from '../../../../../hooks/useSearchClientsDebounced'
import SydReadOnly from '../../../../commonDesign/SydReadOnly'
import SydAutocomplete from '../../../../commonDesign/SydAutocomplete'
import Skeleton from '../../../../atoms/Skeleton'
import SydLabel from '../../../../commonDesign/SydLabel'

const FamilyRateAssignment = ({ name = 'addExtraBalance', editing, control }) => {
  const controller = useController({ name, control })
  const [value, setValue] = useState(controller.formState.defaultValues?.addExtraBalance)
  const { defaultOptions, options, onChangeQuery, isLoading, isSearchLoading } = useSearchClientsDebounced({
    defaultIds: controller.formState.defaultValues?.addExtraBalance
  })

  const handleOnChange = useCallback((val) => {
    controller.field.onChange(val)
    setValue(val)
  }, [controller.field])

  useEffect(() => {
    if (defaultOptions && editing) {
      setValue(defaultOptions)
    }
  }, [defaultOptions, editing])

  if (isLoading) {
    return (
      <>
        <Box display='flex' flexDirection='column' gridGap='10px'>
          <Skeleton width='150px' height='25px' />
          <Skeleton width='650px' height='55px' />
        </Box>
      </>
    )
  }

  return (
    <Box maxWidth='650px' mt='16px'>
      <SydLabel label='Family Rate Assignment' />
      {!editing ? (
        <SydReadOnly>
          {defaultOptions ? defaultOptions?.map(option => option.payload?.longName ?? option.label).join(', ') : 'None'}
        </SydReadOnly>
      ) : (
        <>
          <SydAutocomplete
            name='addExtraBalance'
            options={options}
            defaultOptions={defaultOptions}
            defaultValue={defaultOptions}
            value={value}
            isLoading={isSearchLoading}
            onInputChange={(e, val) => onChangeQuery(val)}
            onChange={(e, val) => handleOnChange(val)}
            placeholder='Search for clients'
            multiple
          />
        </>
      )}
    </Box>
  )
}
FamilyRateAssignment.propTypes = {
  name: PropTypes.string,
  editing: PropTypes.bool,
  control: PropTypes.object
}
export default FamilyRateAssignment
