import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import ProgressBar from '../../atoms/ProgressBar'
import { useFormattingContext } from '../FormattingProvider/FormattingContext'

const useStyles = makeStyles((theme) => ({
  barItem: {
    margin: '.5rem 1.5rem'
  },
  subTitle: {
    marginTop: '.1rem',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: theme.palette.summitBlue
  }
}))

const useAllocationBarItem = ({ value, valueFormat, balance, balanceFormat, label }) => {
  const { formatter } = useFormattingContext()
  const balanceLabel = useMemo(() => {
    return `${label || '?'} ${formatter(+balance, balanceFormat)}`
  }, [label, balance, balanceFormat, formatter])

  const valueLabel = useMemo(() => {
    return formatter(value, valueFormat)
  }, [value, valueFormat, formatter])

  return {
    balanceLabel,
    valueLabel
  }
}

const AllocationBarChartItem = ({ value, valueFormat, balance, balanceFormat, label, color }) => {
  const classes = useStyles()
  const { balanceLabel, valueLabel } = useAllocationBarItem({
    value,
    valueFormat,
    balance,
    balanceFormat,
    label
  })

  return (
    <div className={classes.barItem}>
      <ProgressBar
        squared
        color={color}
        backgroundColor='lightGrey'
        percentage={value * 100}
      />
      <div className={classes.subTitle}>
        <div>{balanceLabel}</div>
        <div>{valueLabel}</div>
      </div>
    </div>
  )
}

AllocationBarChartItem.propTypes = {
  value: PropTypes.number,
  valueFormat: PropTypes.string,
  balance: PropTypes.number,
  balanceFormat: PropTypes.string,
  label: PropTypes.string,
  color: PropTypes.string
}

AllocationBarChartItem.defaultProps = {
  valueFormat: 'allocation',
  balanceFormat: 'marketValue'
}

export default AllocationBarChartItem
