import PropTypes from 'prop-types'

function getPropIsRequired (propTypes, propName) {
  const fakeProps = {}
  fakeProps[propName] = null
  const error = PropTypes.checkPropTypes(propTypes, fakeProps)
  return !!error
}

export function extractTypes (component) {
  const typeMap = {}
  // eslint-disable-next-line react/forbid-foreign-prop-types
  if (!component?.propTypes) return null
  // eslint-disable-next-line react/forbid-foreign-prop-types
  const propTypes = component.propTypes
  Object.keys(propTypes).forEach((propName) => {
    const type = getPropType(propTypes, propName)
    const required = getPropIsRequired(propTypes, propName)
    typeMap[propName] = {
      type: type,
      required: required
    }
  })
  return typeMap
}

function getPropType (propTypes, propName) {
  const fakeProps = {}
  fakeProps[propName] = {}

  const error = checkPropTypes(propTypes, fakeProps, 'prop')
  // extract type from error string
  if (error !== undefined) {
    const EXPECTED_TYPE_PATTERN = /expected (.*)/i
    const propType = error.toString().match(EXPECTED_TYPE_PATTERN)?.[1]
    return propType?.replace(/`|\./g, '')
  } else {
    // no error - it is string
    return 'string'
  }
}

/**
 * Check if the values match with the type specs
 * Return a type error message or null
 *
 * @param {object} typeSpecs Map of name to a ReactPropType
 * @param {object} values Runtime values that need to be type-checked
 * @param {string} location e.g. "prop", "context", "child context"
 * @param {string} componentName Name of the component for error messages.
 * @param {?Function} getStack Returns the component stack.
 */
function checkPropTypes (typeSpecs, values, location, componentName, getStack) {
  const ReactPropTypesSecret = require('prop-types/lib/ReactPropTypesSecret')
  var name = componentName || 'React class'
  for (var typeSpecName in typeSpecs) {
    // eslint-disable-next-line no-prototype-builtins
    if (typeSpecs?.hasOwnProperty(typeSpecName)) {
      var error
      if (typeof typeSpecs[typeSpecName] !== 'function') {
        return (
          name +
          ': ' +
          location +
          ' type `' +
          typeSpecName +
          '` is ' +
          'invalid; it must be a function, usually from React.PropTypes.'
        )
      } else {
        // Prop type validation may throw. In case they do, catch and save the
        // exception as the error.
        try {
          error = typeSpecs[typeSpecName](
            values,
            typeSpecName,
            componentName,
            location,
            null,
            ReactPropTypesSecret
          )
        } catch (ex) {
          error = ex
        }
      }
      if (error && !(error instanceof Error)) {
        return (
          name +
          ': type specification of ' +
          location +
          ' `' +
          typeSpecName +
          '` is invalid; the type checker function must ' +
          'return `null` or an `Error` but returned a ' +
          typeof error +
          '. ' +
          'You may have forgotten to pass an argument to the type checker ' +
          'creator (arrayOf, instanceOf, objectOf, oneOf, oneOfType, and ' +
          'shape all require an argument).'
        )
      }
      if (error instanceof Error) {
        var stack = (getStack && getStack()) || ''
        return 'Failed ' + location + ' type: ' + error.message + stack
      }
    }
  }
}
