const defaultParse = (value) => {
  const numeric = Number(value)
  if (!isNaN(numeric)) return numeric

  if (value === 'true') return true
  if (value === 'false') return false

  return value
}

export const parseUrlSearchParams = (history, parse = defaultParse) => {
  const searchParams = new URLSearchParams(history.location.search)
  const entries = searchParams.entries()
  const searchObj = {}
  for (const [key, value] of entries) {
    if (key in searchObj) {
      searchObj[key].push(parse(value))
    } else {
      searchObj[key] = [parse(value)]
    }
  }

  return searchObj
}
