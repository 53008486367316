import { makeStyles } from '@material-ui/core'

export const mappingOptions = [
  { label: 'Not Set', value: '' },
  { label: 'Constant', value: 'constant' },
  { label: 'Direct', value: 'direct' },
  { label: 'Expression', value: 'expression' },
  { label: 'Translation', value: 'translation' }
]

export const sourceOptions = [
  { label: 'Not Set', value: '' },
  { label: 'Constant', value: 'constant' },
  { label: 'Key Datapoint', value: 'kdp' },
  { label: 'Group Membership', value: 'gm' }
]

export const useDialogStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    fontWeight: theme.typography.weights.light,
    '& strong': {
      fontWeight: theme.typography.weights.bold
    }
  },
  group: {
    display: 'flex',
    flexDirection: 'row',
    gap: '10px',
    width: '100%',
    '& > *': {
      flex: '1 0 auto'
    }
  },
  textarea: {
    width: '100%',
    marginBottom: '10px',
    resize: 'none',
    height: '300px'
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  list: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: theme.layout.padding.thick,
    paddingBottom: theme.layout.padding.thick
  },
  listItem: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.layout.padding.dense,
    borderRadius: theme.layout.radius.round,
    transition: 'background-color .2s ease-in-out',
    '&:hover': {
      backgroundColor: theme.palette.gray.dark
    }
  },
  formtings: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.layout.gap.loose
  },
  info: {
    fontSize: theme.typography.fontSizes.xl,
    fontWeight: theme.typography.weights.bold,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: theme.layout.margin.thick,
    borderBottom: `1px solid ${theme.palette.gray.dark}`
  }
}))
