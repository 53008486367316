import React from 'react'
import PropTypes from 'prop-types'

import MuiDialogContent from '@material-ui/core/DialogContent'

const Body = ({ children, className }) => {
  return (
    <MuiDialogContent className={className}>
      {children}
    </MuiDialogContent>
  )
}

Body.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
}

export default React.memo(Body)
