import { useQuery } from '@tanstack/react-query'
import { fetchTransactions } from '../service'
import { useAppContext } from '../redux/slices/appContext'

export const useTransactions = (query) => {
  const { userId } = useAppContext()
  return useQuery({
    queryKey: ['transactions', userId, query],
    queryFn: async () => {
      const { data } = await fetchTransactions(query)

      return data
    }
  })
}
