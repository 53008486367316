import React, { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import noop from 'lodash/noop'
import PropTypes from 'prop-types'
import { useDropzone } from 'react-dropzone'
import { useTheme } from '@material-ui/core'
import isFunction from 'lodash/isFunction'
import { MAX_FILES_DOCUMENT_VAULT } from '../../constants'

const DragAndDropContext = createContext({})

export const useDragAndDrop = () => {
  return useContext(DragAndDropContext)
}

const getRawStyles = (theme, containerStyle) => ({
  dragAndDropContainerBaseStyle: {
    flex: 1,
    height: '100%',
    width: '100%',
    display: 'flex',
    position: 'relative',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '2rem',
    borderWidth: 2,
    borderRadius: 4,
    borderColor: theme.palette.cloudBurst,
    borderStyle: 'dashed',
    backgroundColor: theme.palette.white,
    outline: 'none',
    ...containerStyle
  },
  activeStyle: {
    borderColor: theme.palette.gray.darker,
    backgroundColor: theme.palette.gray.darker
  },
  acceptStyle: {
    borderColor: theme.palette.success.A500,
    backgroundColor: theme.palette.success.A50,
    borderStyle: 'dashed',
    borderWidth: 2
  },
  rejectStyle: {
    borderColor: theme.palette.error.A500,
    backgroundColor: theme.palette.error.A50,
    borderStyle: 'dashed',
    borderWidth: 2
  }
})

const ERROR_TIMEOUT = 3000

const DragAndDropInputArea = ({
  acceptedFileTypes,
  children,
  containerStyle,
  maxFiles,
  persistDropRejected,
  onDrop,
  onDropAccepted,
  onLoadAcceptedFiles,
  validator
}) => {
  const [persistFileRejectionError, setPersistFileRejectionError] = useState(
    false
  )
  const errorInterval = useRef()

  const onDropRejected = useCallback(
    (fileRejections) => {
      if (fileRejections.length && persistDropRejected) {
        setPersistFileRejectionError(true)
        errorInterval.current = setTimeout(
          () => setPersistFileRejectionError(false),
          ERROR_TIMEOUT
        )
      }
    },
    [persistDropRejected]
  )

  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    fileRejections,
    open,
    isDragActive,
    isDragAccept,
    isDragReject
  } = useDropzone({
    noClick: true,
    noKeyboard: true,
    maxFiles,
    validator,
    onDrop,
    onDropAccepted,
    onDropRejected,
    accept: acceptedFileTypes
  })

  const theme = useTheme()
  const styles = getRawStyles(theme, containerStyle)
  const style = useMemo(() => {
    const {
      activeStyle,
      acceptStyle,
      rejectStyle,
      dragAndDropContainerBaseStyle
    } = styles

    return {
      ...dragAndDropContainerBaseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject || (persistDropRejected && persistFileRejectionError)
        ? rejectStyle
        : {})
    }
  }, [
    isDragActive,
    isDragReject,
    isDragAccept,
    styles,
    persistDropRejected,
    persistFileRejectionError
  ])

  useEffect(() => {
    return () => clearTimeout(errorInterval.current)
  }, [errorInterval])

  useEffect(() => {
    if (acceptedFiles.length) {
      onLoadAcceptedFiles(acceptedFiles)
    }
  }, [acceptedFiles, onLoadAcceptedFiles])

  return (
    <DragAndDropContext.Provider
      value={{
        openFileSelect: open,
        isDragActive,
        isDragAccept,
        fileRejections,
        showFileRejections: persistFileRejectionError
      }}
    >
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />
        {isFunction(children)
          ? children({
            openUploadFileWindow: open,
            isDragActive,
            isDragAccept,
            fileRejections,
            persistFileRejectionError
          })
          : children}
      </div>
    </DragAndDropContext.Provider>
  )
}

DragAndDropInputArea.propTypes = {
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  maxFiles: PropTypes.number,
  validator: PropTypes.func,
  onDrop: PropTypes.func,
  onDropAccepted: PropTypes.func,
  containerStyle: PropTypes.object,
  onDragAccepted: PropTypes.func,
  onDropRejected: PropTypes.func,
  onLoadAcceptedFiles: PropTypes.func,
  persistDropRejected: PropTypes.bool,
  acceptedFileTypes: PropTypes.arrayOf(PropTypes.string)
}

DragAndDropInputArea.defaultProps = {
  maxFiles: MAX_FILES_DOCUMENT_VAULT,
  children: noop,
  validator: noop,
  onDrop: noop,
  onDropAccepted: noop,
  onLoadAcceptedFiles: noop,
  containerStyle: null,
  onDragAccepted: noop,
  onDropRejected: noop,
  persistDropRejected: false,
  acceptedFileTypes: ['application/pdf']
}

export default DragAndDropInputArea
