import React, { useCallback, useRef, useState } from 'react'
import noop from 'lodash/noop'
import PropTypes from 'prop-types'
import { Button, makeStyles, Switch } from '@material-ui/core'
import Text from '../../../../atoms/Text'

const useStyles = makeStyles((theme) => ({
  tableActions: {
    display: 'flex',
    alignItems: 'center',
    gap: '1rem',
    '& button': {
      cursor: 'pointer'
    }
  }
}))

const ReportRunRefreshActions = ({
  loading,
  onRefetch,
  intervalInSeconds
}) => {
  const classes = useStyles()

  const isPolling = useRef(false)
  const secondsRemaining = useRef(intervalInSeconds)
  const [intervalInstance, setRefreshIntervalInstance] = useState(0)

  const processTimeout = useCallback(async () => {
    // eslint-disable-next-line no-debugger
    if (!isPolling.current) return
    secondsRemaining.current = secondsRemaining.current - 1
    if (secondsRemaining.current <= 0) {
      secondsRemaining.current = intervalInSeconds
      await onRefetch()
    }
    // eslint-disable-next-line no-debugger
    setRefreshIntervalInstance(
      setTimeout(processTimeout, 1000)
    )
  }, [intervalInSeconds, isPolling, onRefetch])

  const initCountdown = useCallback(() => {
    isPolling.current = true

    clearTimeout(intervalInstance)
    setRefreshIntervalInstance(0)
    secondsRemaining.current = intervalInSeconds

    setRefreshIntervalInstance(
      setTimeout(processTimeout, 1000)
    )
  }, [intervalInSeconds, intervalInstance, processTimeout])

  const destroyCountdown = useCallback(() => {
    isPolling.current = false
    if (!intervalInstance) return
    clearInterval(intervalInstance)
    setRefreshIntervalInstance(null)
  }, [intervalInstance, isPolling])

  const toggleCountdown = useCallback(() => {
    if (isPolling.current) {
      destroyCountdown()
    } else {
      initCountdown()
    }
  }, [isPolling, destroyCountdown, initCountdown])

  return (
    <div className={classes.tableActions}>
      {!isPolling.current ? (
        <Button
          onClick={loading ? noop : onRefetch}
          style={{ opacity: loading ? 0.5 : 1, pointerEvents: loading ? 'none' : 'auto' }}
          disabled={loading}
          variant='text'
          size='small'
        >
          {loading ? 'Loading...' : 'Refresh'}
        </Button>
      ) : (
        <div style={{ opacity: 0.5 }}>
          <Text
            text={loading
              ? 'Loading...'
              : `Refreshing in ${secondsRemaining.current} second${secondsRemaining.current === 1 ? '' : 's'}`}
            variant='body2'
          />
        </div>
      )}

      <Button
        type='button'
        variant='text'
        color='primary'
        size='small'
        onClick={() => toggleCountdown()}
        className={classes.button}
      >
        <span style={{ marginRight: '.25rem' }}>Autorefresh</span>
        <Switch checked={isPolling.current} size='small' />
      </Button>
    </div>
  )
}

ReportRunRefreshActions.propTypes = {
  loading: PropTypes.bool,
  onRefetch: PropTypes.func,
  intervalInSeconds: PropTypes.number
}

ReportRunRefreshActions.intervalInSeconds = {
  onRefetch: noop,
  loading: false,
  intervalInSeconds: 10
}

export default ReportRunRefreshActions
