import React from 'react'
import PropTypes from 'prop-types'
import { Box, makeStyles } from '@material-ui/core'

const useStyles = makeStyles(() => ({
  title: {
    margin: 0,
    fontSize: '1rem',
    fontWeight: 'bold'
  }
}))

const WidgetWrapperTitle = ({ children, style, ...titleProps }) => {
  const classes = useStyles()

  if (!children) {
    return null
  }

  return (
    <Box component='h1' className={classes.title} style={style} {...titleProps}>
      {children}
    </Box>
  )
}

WidgetWrapperTitle.propTypes = {
  style: PropTypes.object,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
}

export default WidgetWrapperTitle
