import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import SectionTitle from '../Section/SectionTitle'
import { useAdvisorViewTabGroup } from '../../AdvisorView/components/AdvisorViewTabs/advisorViewTabGroupContext'
import SectionToggleOptions from './SectionToggleOptions'

const SectionToggleTitle = ({ title, actions }) => {
  const { selectedValue, options } = useAdvisorViewTabGroup()

  const selectedOption = useMemo(
    () => options.find(({ value }) => value === selectedValue),
    [options, selectedValue]
  )

  return (
    <SectionTitle
      title={selectedOption?.payload?.title || title}
      actions={selectedOption?.payload?.actions || actions}
      titleAdornment={<SectionToggleOptions />}
    />
  )
}

SectionToggleTitle.propTypes = {
  title: PropTypes.string,
  actions: PropTypes.node
}

export default SectionToggleTitle
