import React from 'react'
import PropTypes from 'prop-types'
import {
  Backdrop,
  CircularProgress,
  Typography,
  makeStyles,
  useTheme
} from '@material-ui/core'
import { useAppContext } from '../../redux/slices/appContext'

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.layers.overlay
  },
  innerContent: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    '& > *:not(:last-child)': {
      marginRight: '1rem'
    }
  }
}))

/**
 * Covers up the screen while we do things that look janky
 * @return {JSX.Element}
 * @constructor
 */
const PrintViewModestyCurtain = ({ text, show }) => {
  const theme = useTheme()
  const classes = useStyles(theme)
  const { isPrintView } = useAppContext()

  return (
    <Backdrop
      className={classes.backdrop}
      open={isPrintView || show}
      style={{
        backgroundColor: theme.palette.background.default
      }}
    >
      <div className={classes.innerContent}>
        <Typography variant='h1'>{text}</Typography>
        <CircularProgress />
      </div>
    </Backdrop>
  )
}

PrintViewModestyCurtain.propTypes = {
  text: PropTypes.string,
  show: PropTypes.bool
}

PrintViewModestyCurtain.defaultProps = {
  text: 'Exporting View',
  show: false
}

export default PrintViewModestyCurtain
