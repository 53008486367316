import React, { forwardRef, useImperativeHandle, useMemo } from 'react'
import PropTypes from 'prop-types'
import { makeStyles, Grid } from '@material-ui/core'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import FadeIn from '../../../../molecules/Transitions/FadeIn'
import SydLabel, { hookFormErrorAdapter } from '../../../../commonDesign/SydLabel'
import SydInput from '../../../../commonDesign/SydInput'

const useStyles = makeStyles((theme) => ({
  userInfoForm: {
    maxWidth: '800px',
    display: 'flex',
    justifyContent: 'center',
    padding: '10px',
    '& p': {
      color: `${theme.palette.primary.dark}`
    }
  }
}))

const useFormValues = (user) => {
  const defaultValues = useMemo(() => {
    return {
      userId: user.userId,
      externalUserId: user.externalUserId,
      firstName: user?.firstName ?? '',
      lastName: user?.lastName ?? ''
    }
  }, [user])

  const formMethods = useForm({
    mode: 'onChange',
    defaultValues
  })

  return formMethods
}

const AdvisorUserInfoForm = forwardRef(function AdvisorUserInfoForm ({ user }, ref) {
  const classes = useStyles()
  const form = useFormValues(user)
  const { handleSubmit, formState, ...formValues } = form

  useImperativeHandle(ref, () => ({
    handleSubmit
  }), [handleSubmit])

  return (
    <FormProvider handleSubmit={handleSubmit} formState={formState} {...formValues}>
      <FadeIn className={classes.userInfoForm}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <Controller
              name='firstName'
              rules={{ required: { value: true, message: 'Required' }, maxLength: { value: 30, message: 'Too long' } }}
              render={({ field, fieldState }) => (
                <SydLabel
                  label='First Name'
                  required description='Required, 30 max characters'
                  error={hookFormErrorAdapter(form, fieldState)}
                >
                  <SydInput size='sm' placeholder='First Name' {...field} />
                </SydLabel>
              )}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <Controller
              name='lastName'
              rules={{ required: { value: true, message: 'Required' }, maxLength: { value: 30, message: 'Too long' } }}
              render={({ field, fieldState }) => (
                <SydLabel
                  label='Last Name'
                  required description='Required, 30 max characters'
                  error={hookFormErrorAdapter(form, fieldState)}
                >
                  <SydInput size='sm' placeholder='Last Name' {...field} />
                </SydLabel>
              )}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <SydLabel
              label='Email'
            >
              <SydInput size='sm' value={user.email} disabled />
            </SydLabel>
          </Grid>
          <Grid item xs={12} md={12}>
            <SydLabel
              label='External User Id'
            >
              <SydInput size='sm' value={user.externalUserId} disabled />
            </SydLabel>
          </Grid>
        </Grid>
      </FadeIn>
    </FormProvider>
  )
})

AdvisorUserInfoForm.propTypes = {
  user: PropTypes.shape({
    userId: PropTypes.number,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    externalUserId: PropTypes.string
  })
}

export default AdvisorUserInfoForm
