import React from 'react'
import PropTypes from 'prop-types'
import { CSVLink } from 'react-csv'
import { lighten, makeStyles } from '@material-ui/core'

import { ICON_NAMES, TEXT_VARIANTS } from '../../constants'
import theme from '../../theme'
import Icon from './Icon'

const useStyles = makeStyles((theme) => ({
  base: ({ variant, customSize }) => ({
    cursor: 'pointer',
    backgroundColor: 'transparent',
    boxSizing: 'content-box',
    borderRadius: '50%',
    padding: '5px',
    '&:hover': {
      backgroundColor: '#f1f1f1'
    },
    fontSize: customSize || theme.typography[variant].fontSize,
    width: customSize || theme.typography[variant].fontSize,
    maxHeight: customSize || theme.typography[variant].fontSize
  }),
  disabled: {
    color: `${lighten(theme.palette.summitBlue, 0.5)} !important`
  }
}))

const CSVButton = ({
  customSize,
  data,
  disabled,
  fileName,
  iconName,
  variant,
  children
}) => {
  const classes = useStyles({ customSize, variant })

  if (children) {
    if (disabled) return children
    return <CSVLink data={data} filename={fileName}>{children}</CSVLink>
  }

  if (disabled) {
    return (
      <Icon
        additionalClasses={classes.disabled}
        color={theme.palette.summitBlue}
        name={iconName}
        variant={variant}
        customSize={customSize}
      />
    )
  }

  return (
    <CSVLink data={data} filename={fileName}>
      <div className={classes.base}>
        <Icon
          color={theme.palette.summitBlue}
          customSize={customSize}
          name={iconName}
          variant={variant}
        />
      </div>
    </CSVLink>
  )
}

CSVButton.propTypes = {
  customSize: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.any),
  disabled: PropTypes.bool,
  fileName: PropTypes.string,
  iconName: PropTypes.string,
  variant: PropTypes.string,
  children: PropTypes.any
}

CSVButton.defaultProps = {
  customSize: undefined,
  data: [],
  disabled: false,
  iconName: ICON_NAMES.download,
  fileName: 'summit_export.csv',
  variant: TEXT_VARIANTS.h1,
  children: undefined
}

export default CSVButton
