import { useEffect, useMemo, useState } from 'react'

export const useQueriesMaxResultVersion = (results) => {
  const [safeResult, setSafeResult] = useState(results)

  const maxResultVersion = useMemo(() => {
    return results.reduce((prev, cur) => Math.max(prev, cur.dataUpdatedAt), 0)
  }, [results])

  useEffect(() => {
    setSafeResult(results)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setSafeResult, maxResultVersion])

  return safeResult
}
