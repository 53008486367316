import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import { useAdvisorStatusSummary } from '../../../../../api/users'
import { useFormattingContext } from '../../../../organisms/FormattingProvider/FormattingContext'
import SectionHeader from '../../shared/SectionHeader'
import SectionScreen from '../../shared/SectionScreen'

const mapStatusDescription = (status) => {
  switch (status?.toUpperCase()) {
    case 'NOT AVAILABLE':
      return 'The user is not available to the system. This is unexpected.'
    case 'STAGED':
      return 'The user has been created but not otherwise activated.'
    case 'PROVISIONED':
      return 'The user has been created but not invited.'
    case 'PENDING USER ACTION':
      return 'The user needs to complete an action to activate or reactivate their account.'
    case 'ACTIVE':
      return 'The user is active.'
    case 'RECOVERY':
      return 'The previously active user is in password recovery mode. User action required.'
    case 'PASSWORD RESET':
      return 'The previously active user is in password recovery mode. User action required.'
    case 'PASSWORD EXPIRED':
      return 'The user\'s password is expired.'
    case 'SUSPENDED':
      return 'The user is prevented from signing in.'
    case 'DEPROVISIONED':
      return 'The user is deactivated.'
    default:
      return ''
  }
}

const useStyles = makeStyles((theme) => ({
  statusSummary: {
    '& td': {
      padding: '10px',
      fontSize: theme.typography.fontSizes.xl,
      fontWeight: theme.typography.weights.light
    },
    '& tr > td:first-child': {
      backgroundColor: theme.palette.gray.main,
      fontWeight: theme.typography.weights.semibold
    }
  }
}))

function SecuritySummarySection ({ userId }) {
  const classes = useStyles()
  const { data, isLoading } = useAdvisorStatusSummary(userId)
  const { formatter } = useFormattingContext()

  if (isLoading) {
    return null
  }

  return (
    <SectionScreen sectionName='security_summary'>
      <SectionHeader text='Security Summary' />
      <table className={classes.statusSummary}>
        <tr>
          <td>Status</td>
          <td>{data.statusSummary?.status}</td>
          <td>{mapStatusDescription(data.statusSummary?.status)}</td>
        </tr>
        <tr>
          <td>Last Login</td>
          <td>{formatter(data.statusSummary?.lastLogin, '&MM/DD/YYYY hh:mm:ss a')}</td>
          <td>{formatter(data.statusSummary?.lastLogin, 'ß')}</td>
        </tr>
        <tr>
          <td>Created On</td>
          <td>{formatter(data.statusSummary?.createdOn, '&MM/DD/YYYY hh:mm:ss a')}</td>
          <td>{formatter(data.statusSummary?.createdOn, 'ß')}</td>
        </tr>
        <tr>
          <td>Last Status Change</td>
          <td>{formatter(data.statusSummary?.lastStatusChange, '&MM/DD/YYYY hh:mm:ss a')}</td>
          <td>{formatter(data.statusSummary?.lastStatusChange, 'ß')}</td>
        </tr>
        <tr>
          <td>Last Password Change</td>
          <td>{formatter(data.statusSummary?.lastPasswordChange, '&MM/DD/YYYY hh:mm:ss a')}</td>
          <td>{formatter(data.statusSummary?.lastPasswordChange, 'ß')}</td>
        </tr>
      </table>
    </SectionScreen>
  )
}

SecuritySummarySection.propTypes = {
  userId: PropTypes.number.isRequired
}

export default SecuritySummarySection
