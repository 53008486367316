import React, { useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import { Divider, makeStyles } from '@material-ui/core'
import { useSetNoteContext } from '../../redux/slices/noteContext'
import { useNoteThreadContext } from '../../contexts/noteThreadContext'
import NoteThread from './NoteThread'
import NoteNewReply from './NoteNewReply'
import NoteThreadTitle from './NoteThreadTitle'
import NoteThreadOverview from './NoteThreadOverview'
import NoteThreadSkeleton from './NoteThreadSkeleton'

const useStyles = makeStyles((theme) => ({
  wrapper: {
    borderRadius: '0.5rem',
    backgroundColor: theme.palette.white
  },
  threadsWrapper: {
    maxHeight: '31rem',
    overflow: 'auto'
  }
}))

export const NoteThreads = ({ handleOnClose, modalActions, modalContainerRef }) => {
  const classes = useStyles()
  const {
    threads,
    isLoading,
    isNoteEmpty,
    activeThread,
    setActiveThread
  } = useNoteThreadContext()

  const setNoteContext = useSetNoteContext()

  const onAddNewThread = useCallback(
    () => setNoteContext({ openModal: false, openNewCommentModal: true }),
    [setNoteContext]
  )

  useEffect(() => {
    if (modalActions?.current) {
      modalActions.current.updatePosition()
    }
  })

  if (isNoteEmpty && !isLoading) {
    return <NoteNewReply isThreadInitializer openModalOnSaveComment />
  }

  if (activeThread.threadId) {
    return (
      <div>
        <NoteThread
          embedded
          handleOnClose={handleOnClose}
          modalContainerRef={modalContainerRef}
          withMaxHeight
          handleOnBack={() => setActiveThread(null)}
        />
        <Divider />
        <NoteNewReply
          threadId={activeThread.threadId}
          threadResolved={activeThread.resolved}
          disablePostButton={isLoading}
          isThreadInitializer={false}
        />
      </div>
    )
  }

  return (
    <div className={classes.wrapper}>
      <NoteThreadTitle
        title='Threads'
        onAdd={onAddNewThread}
        onClose={handleOnClose}
      />
      <Divider />
      <div className={classes.threadsWrapper}>
        {threads.length ? (
          threads.map((thread, index) => (
            <React.Fragment key={`note-thread-overview-${index}`}>
              <NoteThreadOverview
                thread={thread}
                handleOnThreadDetails={() => setActiveThread(thread)}
              />
              {threads.length - 1 !== index && <Divider />}
            </React.Fragment>
          ))
        ) : (
          <NoteThreadSkeleton repeat={threads.length || 1} />
        )}
      </div>
    </div>
  )
}

NoteThreads.propTypes = {
  handleOnClose: PropTypes.func,
  modalActions: PropTypes.object,
  modalContainerRef: PropTypes.object
}

NoteThreads.defaultProps = {
  handleOnClose: noop,
  modalActions: null,
  modalContainerRef: null
}

export default NoteThreads
