import React from 'react'
import { makeStyles } from '@material-ui/core'
import FadeIn from '../../../molecules/Transitions/FadeIn'
import { useWealthOwnerStatusSummary } from '../../../../api/users'
import { useWealthOwnerDetails } from './ClientDetailsFormContext'

const useStyles = makeStyles((theme) => ({
  statusBadge: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.white,
    padding: '4px 8px',
    borderRadius: '.4rem',
    fontWeight: theme.typography.weights.semibold,
    fontSize: theme.typography.fontSizes.sm
  },
  reset: {
    display: 'flex',
    maxWidth: '200px'
  }
}))

function ExperienceSection () {
  const { wealthOwner } = useWealthOwnerDetails()
  const classes = useStyles()
  const { data, isLoading } = useWealthOwnerStatusSummary(wealthOwner.userId)

  if (isLoading) {
    return <div className={classes.reset} />
  }

  return (
    <FadeIn className={classes.reset}>
      <div className={classes.statusBadge}>
        {data.statusSummary.status}
      </div>
    </FadeIn>
  )
}

export default ExperienceSection
