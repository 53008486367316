import React, { useContext, useMemo } from 'react'
import PropTypes from 'prop-types'

const ToolProviderContext = React.createContext({})

export const useToolContext = () => {
  return useContext(ToolProviderContext)
}

export const useTool = (name) => {
  const ctx = useContext(ToolProviderContext)

  return ctx[name]
}

function ToolProvider ({ children, name, tool }) {
  const toolContext = useToolContext()
  const value = useMemo(() => {
    return {
      ...(toolContext || {}),
      [name]: tool
    }
  }, [name, tool, toolContext])

  return (
    <ToolProviderContext.Provider value={value}>
      {children}
    </ToolProviderContext.Provider>
  )
}

ToolProvider.propTypes = {
  children: PropTypes.node,
  name: PropTypes.string,
  tool: PropTypes.any
}

export default ToolProvider
