import { useMemo } from 'react'
import isEmpty from 'lodash/isEmpty'
import { useClientBalances, useSearchClients } from '../../../../api/clients'
import { useAppContext } from '../../../../redux/slices/appContext'
import { CALC_TYPES } from '../../../../constants'
import { mapSearch, mapSort } from '../helpers'
import { useContextContainer } from '../../../../abundanceEngine/components/ContextContainer'
import { CLIENT_VIEW_CONTEXT } from '..'

export const useClients = ({
  pageIndex,
  pageSize,
  sort,
  searchText
}) => {
  const { availableDates } = useAppContext()
  const [{ clientTagFilters }] = useContextContainer(CLIENT_VIEW_CONTEXT)

  const queryFilters = useMemo(() => {
    const groupIds = clientTagFilters.map(({ groupIds }) => ({
      value: groupIds,
      op: 'in',
      combine: 'and'
    }))
    return !isEmpty(groupIds) ? { groupIds } : {}
  }, [clientTagFilters])

  const query = useMemo(() => {
    return {
      skip: pageIndex * pageSize ?? 0,
      take: pageSize ?? 100,
      sort: mapSort(sort),
      textSearch: mapSearch(searchText),
      filters: queryFilters,
      includes: {
        groups: true,
        avatars: true
      }
    }
  }, [pageIndex, pageSize, sort, searchText, queryFilters])

  const { data, isLoading, error } = useSearchClients(query)

  const queryTotal = useMemo(
    () => ({
      resultType: 'total',
      textSearch: mapSearch(searchText),
      filters: queryFilters
    }),
    [searchText, queryFilters]
  )
  const { data: { total = 0 } = {}, isLoading: isTotalLoading } =
    useSearchClients(queryTotal)

  const { query: clientBalanceQuery, queryOptions } = useMemo(() => {
    if (!isEmpty(data?.data)) {
      const clients = data?.data
      return {
        query: {
          clientIds: clients.map(({ clientId }) => clientId),
          calcType: CALC_TYPES.balance,
          startDate: availableDates.min,
          endDate: availableDates.max
        },
        queryOptions: {
          enabled: true,
          mapper: (data) =>
            data.reduce(
              (acc, client) => ({ ...acc, [client.clientId]: client }),
              {}
            )
        }
      }
    }
    return { query: {}, queryOptions: { enabled: false } }
  }, [data, availableDates])

  const { data: clientBalances } = useClientBalances(clientBalanceQuery, queryOptions)

  /** conciliates clients with their balances  */
  const clients = useMemo(() => {
    if (isEmpty(clientBalances)) return data?.data

    return (data?.data || []).map((client) => {
      const clientBalance = clientBalances[client.clientId]
      return {
        ...client,
        balance: clientBalance
      }
    })
  }, [data, clientBalances])

  const isTransitioning = isLoading || isTotalLoading

  return {
    total,
    error,
    clients: isTransitioning ? [] : clients,
    isLoading: isTransitioning
  }
}

export default useClients
