import React from 'react'
import { makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'

const useStyles = makeStyles((theme) => ({
  section: {
    marginBottom: theme.layout.margin.medium,
    padding: theme.layout.padding.thin
  }
}))

function Section ({ children }) {
  const classes = useStyles()

  return (
    <div className={classes.section}>{children}</div>
  )
}

Section.propTypes = {
  children: PropTypes.node
}

export default Section
