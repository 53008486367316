import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import PillButtonGroup from '../../../../molecules/PillButtonGroup'
import { useAdvisorViewTabGroup } from './advisorViewTabGroupContext'

const useStyles = makeStyles(() => ({
  advisorTabs: ({ variant }) => {
    if (variant === 'offset') {
      return {
        background: '#ffffff',
        padding: '1rem 2rem 2rem 2rem'
      }
    }

    return ({
      background: '#ffffff',
      padding: '0'
    })
  }
}))

const TabButtons = ({ variant }) => {
  const classes = useStyles({ variant })
  const { selectedValue, selectValue, options } = useAdvisorViewTabGroup()

  return (
    <PillButtonGroup
      selectedValue={selectedValue}
      onSelect={selectValue}
      className={classes.advisorTabs}
    >
      {options.map(o => (
        <PillButtonGroup.Option key={o.value} value={o.value}>
          {o.payload}
        </PillButtonGroup.Option>
      ))}
    </PillButtonGroup>
  )
}

TabButtons.propTypes = {
  variant: PropTypes.oneOf(['normal', 'offset'])
}

TabButtons.defaultProps = {
  variant: 'normal'
}

export default TabButtons
