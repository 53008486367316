import React from 'react'
import PropTypes from 'prop-types'

class VisualizationErrorBoundary extends React.Component {
  constructor (props) {
    super(props)
    this.state = { error: undefined }
  }

  static getDerivedStateFromError (error) {
    return { error }
  }

  componentDidCatch (error, { componentStack }) {
    const { name, object } = this.props

    // name: Name of which ErrorBoundary this is
    // object: The view, container, component, layout that placed the ErrorBoundary
    console.error(`Caught error in boundary ${name}`, '\n', error, '\n\ncomponentStack\n', componentStack, '\n\nobject\n', object)
    this.setState({ error })
  }

  render () {
    const { error } = this.state
    const { children } = this.props

    if (error) {
      return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
          There was an error while trying to display the visualization.
        </div>
      )
    }

    return children
  }
}

VisualizationErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  object: PropTypes.shape({})
}

VisualizationErrorBoundary.defaultProps = {
  object: undefined,
  refresh: false
}

export default VisualizationErrorBoundary
