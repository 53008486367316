import { useRef } from 'react'
import debounce from 'lodash/debounce'
import { DEFAULT_DEBOUNCE_DELAY_IN_MILLISECONDS } from '../constants'

function useDebouncedCallback (
  callback,
  delay = DEFAULT_DEBOUNCE_DELAY_IN_MILLISECONDS,
  options = { trailing: true, leading: false }
) {
  const debouncedCallback = useRef(debounce(callback, delay, options))
  return debouncedCallback.current
}

export default useDebouncedCallback
