import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Hideable from '../components/Hideable'

const useStyles = makeStyles((theme) => ({
  fieldMappings: {
    borderBottom: `1px solid ${theme.palette.gray.dark}`
  },
  fieldMapping: {
    marginBottom: '.5rem',
    paddingLeft: '.5rem',
    borderLeft: `2px solid ${theme.palette.gray.dark}`
  },
  mappingTable: {
    width: '100%',
    fontWeight: '200',
    '& > thead > tr > th': {
      textAlign: 'left'
    }
  },
  codeBlock: {
    backgroundColor: theme.palette.gray.main,
    padding: '1rem',
    whiteSpace: 'pre'
  }
}))

const ObjectFieldMappings = ({ mapping }) => {
  const hasMappings = !!mapping.fieldMappings.length
  const classes = useStyles()

  return (
    <section className={classes.fieldMappings}>
      <Hideable label='Field Mappings'>
        {!hasMappings && <div>No Field Mappings</div>}
        {hasMappings && (
          <table className={classes.mappingTable}>
            <thead>
              <tr>
                <th>Destination Path</th>
                <th>Mapping Type</th>
                <th>Source Path</th>
              </tr>
            </thead>
            <tbody>
              {mapping.fieldMappings.map((fm, index) => (
                <tr key={`fm_${index}`}>
                  <td>{fm.destinationPath}</td>
                  <td>{fm.mappingType}</td>
                  <td>{fm.sourcePath}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </Hideable>
    </section>
  )
}

ObjectFieldMappings.propTypes = {
  mapping: PropTypes.shape({
    fieldMappings: PropTypes.arrayOf(PropTypes.shape({
      sourcePath: PropTypes.string,
      destinationPath: PropTypes.string,
      mappingType: PropTypes.string
    }))
  })
}

export default ObjectFieldMappings
