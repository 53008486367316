import React from 'react'
import PropTypes from 'prop-types'
import { useSalesforceMeetings } from '../../../../../../api/salesforce'
import { useAppContext } from '../../../../../../redux/slices/appContext'
import { useRefetchData } from '../../../../../../hooks/useRefetchData'

const SalesforceMeetingsProvider = ({ name, query, children, index }) => {
  const {
    data,
    isLoading,
    error,
    refetch
  } = useSalesforceMeetings(query, { functionName: name, index })
  const { refetchData } = useAppContext()
  useRefetchData(refetchData, refetch)

  if (error) {
    return <div>Sorry, there was an error</div>
  }

  if (isLoading) {
    return <div>Loading...</div>
  }

  return children(data, { isLoading, error })
}

SalesforceMeetingsProvider.propTypes = {
  name: PropTypes.string,
  query: PropTypes.object,
  children: PropTypes.func.isRequired,
  index: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
}

SalesforceMeetingsProvider.defaultProps = {
  index: 0
}

export default SalesforceMeetingsProvider
