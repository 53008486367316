import React, { useCallback, useContext, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { useStartOutboundSso } from '../../../api/users/sso'
import OutboundSsoTransition from './OutboundSsoTransition'

function openLink (linkUrl) {
  const link = document.createElement('a')
  link.href = linkUrl
  link.target = '_blank'
  link.rel = 'noopener noreferrer'
  link.style.visibility = 'hidden'
  document.body.appendChild(link)
  link.click()
  setTimeout(() => {
    document.body.removeChild(link)
  }, 5000)
}

export const OutboundSsoContext = React.createContext({})

export const useOutboundSso = () => {
  return useContext(OutboundSsoContext)
}

function OutboundSsoProvider ({ children }) {
  const { mutateAsync: startSso } = useStartOutboundSso()
  const [processing, setProcessing] = useState(false)
  const [provider, setProvider] = useState(null)
  const [newTab, setNewTab] = useState(true)
  const [link, setLink] = useState(null)
  const [error, setError] = useState(null)

  const stateRef = useRef({
    isCancelled: false,
    openedOn: null
  })

  const close = useCallback(() => {
    setProcessing(false)
    setProvider(null)
    setNewTab(null)
    stateRef.current.isCancelled = true
  }, [setProcessing, setProvider, setNewTab, stateRef])

  const start = useCallback(async (provider, options = {}) => {
    const sameTab = options.sameTab ?? false
    setProvider(options.ssoProvider)
    setNewTab(!sameTab)
    setProcessing(true)
    setError(null)
    try {
      stateRef.current.openedOn = null
      stateRef.current.isCancelled = false
      const result = await startSso(provider)
      if (stateRef.current.isCancelled) {
        console.debug('OutboundSsoProvider: operation was cancelled')
        return
      }
      if (result.status === 'pass') {
        setLink(result.data.redirectUrl)
        stateRef.current.openedOn = new Date()
        if (sameTab) {
          window.location.href = result.data.redirectUrl
        } else {
          openLink(result.data.redirectUrl)
        }
        setTimeout(() => {
          if (stateRef.current.isCancelled) return
          close()
        }, 30000)
      } else if (result.status === 'fail') {
        setError(result.message)
      } else {
        setError('Something went wrong while trying to open this link. Please try again later.')
      }
    } catch (err) {
      console.error(err)
      setError('Something went wrong while trying to open this link. Please try again later.')
    }
  }, [startSso, setProcessing, setProvider, setNewTab, stateRef, close, setError])

  const value = useMemo(() => ({ start }), [start])

  return (
    <OutboundSsoContext.Provider value={value}>
      {children}
      <OutboundSsoTransition
        processing={processing}
        onClose={close}
        provider={provider}
        newTab={newTab}
        link={link}
        error={error}
      />
    </OutboundSsoContext.Provider>
  )
}

OutboundSsoProvider.propTypes = {
  children: PropTypes.node
}

export default OutboundSsoProvider
