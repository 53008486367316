import React, { createContext, useContext, useMemo } from 'react'
import PropTypes from 'prop-types'
import { get, isArray, isEmpty, isFunction, isNumber } from 'lodash'

const COMBINES = {
  OR: 'or',
  AND: 'and'
}

const OPERATIONS = {
  EQUAL: 'eq',
  NOT_EQUAL: 'neq',
  GREATER_THAN: 'gt',
  GREATER_THAN_EQUAL: 'gte',
  LOWER_THAN: 'lt',
  LOWER_THAN_EQUAL: 'lte',
  IN: 'in',
  NOT_IN: 'notIn'
}

const AGGREGATIONS = {
  AVG: 'avg',
  MIN: 'min',
  MAX: 'max',
  COUNT: 'count',
  SUM: 'sum'
}

function applyOperation (
  sourceValue,
  targetValue,
  operation = OPERATIONS.EQUAL
) {
  if (operation === OPERATIONS.EQUAL) {
    return sourceValue === targetValue
  }
  if (operation === OPERATIONS.NOT_EQUAL) {
    return sourceValue !== targetValue
  }
  if (operation === OPERATIONS.GREATER_THAN) {
    return sourceValue > targetValue
  }
  if (operation === OPERATIONS.GREATER_THAN_EQUAL) {
    return sourceValue >= targetValue
  }
  if (operation === OPERATIONS.LOWER_THAN) {
    return sourceValue < targetValue
  }
  if (operation === OPERATIONS.LOWER_THAN_EQUAL) {
    return sourceValue <= targetValue
  }
  if (operation === OPERATIONS.IN) {
    return targetValue.includes(sourceValue)
  }
  if (operation === OPERATIONS.NOT_IN) {
    return !targetValue.includes(sourceValue)
  }
}

/**
 *
 * @param {Array<object>} data - List of objects
 * @param {object} filters - { endingValue: [{ value: 10, op: 'gte' }, { value: 30, op: 'lte' }] }
 * @returns Array<object>
 */
function applyFilters (data, filters) {
  if (isEmpty(filters)) return data

  const filteredList = data.filter((item) => {
    const shouldFilter = Object.entries(filters).reduce(
      (acc, [field, filterSpec]) => {
        const filterResult = filterSpec.reduce((filterAcc, filter) => {
          const { value, op, combine = COMBINES.AND } = filter

          const itemValue = get(item, field, undefined)
          const operationResult = applyOperation(itemValue, value, op)

          if (combine === COMBINES.AND) {
            return filterAcc && operationResult
          }
          if (combine === COMBINES.OR) {
            return filterAcc || operationResult
          }
          return filterAcc
        }, true)
        return acc && filterResult
      },
      true
    )
    return shouldFilter
  })
  return filteredList
}

/**
 *
 * @param {Array<object>} data - List of objects
 * @param {object} aggregationSpec - List of aggregations - { count: 'fieldId' }
 * @returns number
 */
function applyAggregations (data, aggregationSpec) {
  if (isEmpty(aggregationSpec)) return data

  const [[aggregation, field]] = Object.entries(aggregationSpec)

  if (aggregation === AGGREGATIONS.COUNT) {
    return new Set(data.map((item) => {
      const value = get(item, field, undefined)
      if (value === undefined) {
        console.error(`Query List Context: property [${field}] does not exists in the given object`)
      }
      return value
    })).size
  }

  // only numbers are allowed for the following aggregations
  const fieldData = data.map((item) => get(item, field)).filter(isNumber)

  if (aggregation === AGGREGATIONS.AVG) {
    const total = fieldData.reduce((acc, item) => acc + item, 0)
    return total / fieldData.length
  }
  if (aggregation === AGGREGATIONS.SUM) {
    return fieldData.reduce((acc, item) => acc + item, 0)
  }
  if (aggregation === AGGREGATIONS.MIN) {
    return Math.min(...fieldData)
  }
  if (aggregation === AGGREGATIONS.MAX) {
    return Math.max(...fieldData)
  }
}

const typeOfComponent = (component) => {
  return (
    component?.props?.__TYPE ||
    component?.type
      ?.toString()
      .replace('Symbol(react.fragment)', 'react.fragment') ||
    undefined
  )
}

export const QueryListContext = createContext()

export const useQueryListContext = () => {
  return useContext(QueryListContext)
}

export const useQueryList = (rawData, filters, aggregation) => {
  return useMemo(() => {
    if (
      isEmpty(rawData) ||
      (typeof rawData === 'object' && !Array.isArray(rawData))
    ) {
      return { rawData, data: rawData }
    }
    const filtersResult = applyFilters(rawData, filters)
    const aggregationsResult = applyAggregations(filtersResult, aggregation)
    return {
      rawData,
      data: aggregationsResult
    }
  }, [rawData, filters, aggregation])
}

/**
 * Queries a list of Objects
 * @param {object} filters - { endingValue: [{ value: '0.25', op: 'gte' }] }
 * @param {object} aggregation - { sum: 'endingValue' }
 * @param {Array<string>} childrenTypes - allowed children types - ['ToDo', 'div', 'react.fragment']
 * @param {string} injectResultInProp - name of the children prop used to inject context
 * @returns {Array<object>} data - List of objects to be transformed
 */
export const QueryListContextProvider = ({
  filters,
  aggregation,
  injectResultInProp,
  filterChildrenTypes,
  data: rawData,
  children: _children
}) => {
  const contextValue = useQueryList(rawData, filters, aggregation)

  const children = useMemo(() => {
    if (isFunction(_children)) {
      return _children(contextValue?.data)
    }
    // for one child injects the context on the given property
    if (injectResultInProp && !isArray(_children)) {
      return React.cloneElement(_children, {
        [injectResultInProp]: contextValue?.data
      })
    }
    // filters out those unwanted nodes and inject the context on valid ones
    if (isArray(_children) && !isEmpty(filterChildrenTypes) && injectResultInProp) {
      const [child] = React.Children.toArray(_children).filter(
        (child) => filterChildrenTypes.indexOf(typeOfComponent(child)) === -1
      )
      return React.cloneElement(child, {
        [injectResultInProp]: contextValue?.data
      })
    }
    return _children
  }, [_children, contextValue, filterChildrenTypes, injectResultInProp])

  return (
    <QueryListContext.Provider value={contextValue}>
      {children}
    </QueryListContext.Provider>
  )
}

QueryListContextProvider.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  filters: PropTypes.object,
  aggregation: PropTypes.object,
  children: PropTypes.node.isRequired,
  injectResultInProp: PropTypes.string,
  filterChildrenTypes: PropTypes.arrayOf(PropTypes.string)
}

QueryListContextProvider.defaultProps = {
  filterChildrenTypes: ['react.fragment']
}

export default QueryListContextProvider
