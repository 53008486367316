import { makeStyles } from '@material-ui/core'
import isEmpty from 'lodash/isEmpty'
import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { useDates } from '../../../../hooks/useDates'
import BlocksListSkeleton from './BlocksListSkeleton'

const useStyles = makeStyles(() => ({
  container: {
    flex: 1
  }
}))

const BlocksContainer = ({ children, isLoading }) => {
  const classes = useStyles()
  const [containerBoundingRect, setContainerBoundingRect] = useState(null)
  const { loadingAvailableDates } = useDates()

  const onClientCardsContainerRefChange = useCallback((node) => {
    if (!isEmpty(node)) {
      const styles = window.getComputedStyle(node)
      const padding =
        parseFloat(styles.paddingLeft) + parseFloat(styles.paddingRight)
      const rect = node.getBoundingClientRect()

      setContainerBoundingRect({
        height: rect.height,
        width: rect.width - padding,
        containerRef: node
      })
    }
  }, [])

  return (
    <div className={classes.container} ref={onClientCardsContainerRefChange}>
      {isLoading || !containerBoundingRect || loadingAvailableDates ? (
        <BlocksListSkeleton />
      ) : (
        children(containerBoundingRect)
      )}
    </div>
  )
}

BlocksContainer.propTypes = {
  children: PropTypes.func,
  isLoading: PropTypes.bool
}

BlocksContainer.defaultProps = {
  children: undefined,
  isLoading: false
}

export default BlocksContainer
