import React, { useCallback } from 'react'
import { InputLabel, makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import { useSearchClassificationTagsDebounced, useToggle } from '../../../../../hooks'
import SearchBar from '../../../../molecules/SearchBar'
import Text from '../../../../atoms/Text'

const useStyles = makeStyles(() => ({
  title2: {
    fontSize: '1.2rem',
    color: '#000',
    fontWeight: '600',
    marginTop: '1rem',
    marginBottom: '0.625rem',
    '& span': {
      color: '#db3131'
    }
  },
  searchBar: {
    '& input': {
      fontSize: '1rem !important'
    }
  }
}))

const ClassificationTagExposurePicker = ({ setExposureTarget }) => {
  const classes = useStyles()

  const { options, onChangeQuery, isSearchLoading } =
    useSearchClassificationTagsDebounced()

  const [touched, setTouched] = useToggle()

  const handleChange = useCallback((value) => {
    if (!touched) {
      setTouched(true)
    }
    onChangeQuery(value)
  }, [touched, setTouched, onChangeQuery])

  return (
    <>
      <InputLabel className={classes.title2} required>
        Choose Asset Tag
      </InputLabel>
      {!isSearchLoading && touched && (
        <Text text='Displaying first 10 results. Search to see additional results' />
      )}
      <SearchBar
        variant='outlined'
        placeholder='Search for asset tag name'
        options={options}
        onChange={handleChange}
        loading={isSearchLoading}
        className={classes.searchBar}
        onClear={() => setExposureTarget('')}
        onSelectOption={(_, option) => setExposureTarget(option)}
      />
    </>
  )
}

ClassificationTagExposurePicker.propTypes = {
  setExposureTarget: PropTypes.func
}

export default ClassificationTagExposurePicker
