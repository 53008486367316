import React from 'react'
import PropTypes from 'prop-types'

const ColorIcon = ({ enabled, color }) => {
  if (!enabled) return null

  if (!color) {
    return null
  }

  return (
    <span
      style={{ backgroundColor: color }}
      className='__color-icon'
    >
      &nbsp;
    </span>
  )
}

ColorIcon.propTypes = {
  enabled: PropTypes.bool,
  color: PropTypes.any
}

export default ColorIcon
