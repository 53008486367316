import { useMemo } from 'react'
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import isEmpty from 'lodash/isEmpty'
import first from 'lodash/first'
import { postNamedQuery, fetchEntryImage, fetchWealthJourneySeriesData, postNamedCommand } from '../service'
import { useAppContext } from '../redux/slices/appContext'

export const useWealthJourneyQuery = (key, query, options = {}) => {
  const { userId } = useAppContext()
  const { queryName = 'searchEntries', mapper, enabled = true } = options
  return useQuery({
    queryKey: ['wealthJourney', userId, key, queryName, query],
    queryFn: async () => {
      const { data } = await postNamedQuery('wealthJourney', queryName, query)
      return data
    },
    select: mapper,
    enabled
  })
}

export const useMilestoneImage = (milestone) => {
  const { userId } = useAppContext()
  const [entryId, imageUrl] = useMemo(() => {
    if (!milestone) return [null, null]

    const { entryId, entryJson } = milestone
    const { imageUrl, isPreview } = !isEmpty(entryJson?.images)
      ? first(entryJson.images)
      : {}

    if (!imageUrl) return ''
    if (isPreview) return [null, imageUrl]

    return [entryId, imageUrl]
  }, [milestone])

  return useQuery({
    queryKey: ['milestoneImage', userId, entryId, imageUrl],
    queryFn: async () => {
      if (!entryId || !imageUrl) return null

      const { data } = await fetchEntryImage(entryId, imageUrl)
      return data?.downloadUrl
    }
  })
}

export const useSearchMeetings = (query, options = {}) => {
  const { userId } = useAppContext()
  const { mapper, enabled = true } = options
  return useQuery({
    queryKey: ['wealthJourney-meetings', userId, query],
    queryFn: async () => {
      const { data } = await postNamedQuery('wealthJourney', 'searchMeetings', query)
      return data
    },
    select: mapper,
    enabled
  })
}

export const useSearchMilestones = (query, options = {}) => {
  const { userId } = useAppContext()
  const { mapper, enabled = true } = options
  return useQuery({
    queryKey: ['wealthJourney-milestones', userId, query],
    queryFn: async () => {
      const { data } = await postNamedQuery('wealthJourney', 'searchEntries', {
        ...query,
        filters: {
          ...(query?.filters || {}),
          entryTypeId: [{ op: 'eq', value: 1 }]
        }
      })
      return data
    },
    select: mapper,
    enabled
  })
}

export const useSearchTasks = (query, options = {}) => {
  const { userId } = useAppContext()
  const { mapper, enabled = true } = options
  return useQuery({
    queryKey: ['wealthJourney-tasks', userId, query],
    queryFn: async () => {
      const { data } = await postNamedQuery('wealthJourney', 'searchTasks', query)
      return data
    },
    select: mapper,
    enabled
  })
}

export const useWealthJourneyEntries = (query, options = {}) => {
  const { userId } = useAppContext()
  const { enabled = true } = options
  const queryKey = JSON.stringify(query)
  return useQuery({
    queryKey: ['wealth-journey-entries', userId, queryKey],
    queryFn: async () => {
      if (query?.take === 'all') {
        const results = []
        const pageSize = 1000

        let keepGoing = true
        while (keepGoing) {
          const res = await postNamedQuery('users', 'searchEntries', {
            ...query,
            skip: pageSize * results.length,
            take: pageSize
          })
          results.push(res.data)
          keepGoing = res.data.length === pageSize
        }

        return results.flat()
      } else {
        const res = await postNamedQuery('wealthJourney', 'searchEntries', query)
        return res.data
      }
    },
    enabled
  })
}

export const useWealthJourneySeriesData = (query, options = {}) => {
  const { enabled = true } = options
  const queryKey = JSON.stringify(query)
  return useQuery({
    queryKey: ['wealth-journey-series', queryKey],
    queryFn: async () => {
      const { data } = await fetchWealthJourneySeriesData(query)

      return data
    },
    enabled
  })
}

export const useWealthJourneyEntryTypes = () => {
  return useQuery({
    queryKey: ['wealth-journey-entry-types'],
    queryFn: async () => {
      const { data } = await postNamedQuery('wealthJourney', 'get-entry-types', {})

      return data?.entryTypes || []
    }
  })
}

export const useWealthJourneyImage = (clientId, image) => {
  return useQuery({
    queryKey: ['wealth-journey-image', clientId, image],
    queryFn: async () => {
      if (!clientId || !image) return null
      const { data } = await postNamedQuery('wealthJourney', 'get-image', {
        clientId,
        image
      })

      return data
    }
  })
}

export const useCreateEntry = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async (command) => {
      const { data } = await postNamedCommand('wealthJourney', 'create-entry', command)

      return data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['wealth-journey-entries'], refetchType: 'all' }).catch(console.error)
    }
  })
}

export const useModifyEntry = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async (command) => {
      const { data } = await postNamedCommand('wealthJourney', 'modify-entry', command)

      return data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['wealth-journey-entries'], refetchType: 'all' }).catch(console.error)
    }
  })
}

export const useRemoveEntry = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async (command) => {
      const { data } = await postNamedCommand('wealthJourney', 'remove-entry', command)

      return data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['wealth-journey-entries'], refetchType: 'all' }).catch(console.error)
    }
  })
}

export const useCreateImageUpload = () => {
  return useMutation({
    mutationFn: async (command) => {
      const { data } = await postNamedCommand('wealthJourney', 'create-image-upload', command)

      return data
    }
  })
}
