import React from 'react'
import { Box } from '@material-ui/core'
import Skeleton from '../../../atoms/Skeleton'
import { SKELETON_VARIANTS } from '../../../../constants'

const ScopeDisplaySkeleton = () => {
  return (
    <Box
      display='flex'
      flexDirection='row'
      alignItems='center'
      style={{ gap: '2rem ' }}
    >
      <Skeleton width='5rem' height='5rem' variant={SKELETON_VARIANTS.circle} />
      <Skeleton height='3rem' width='10rem' />
    </Box>
  )
}

export default ScopeDisplaySkeleton
