import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import {
  IconButton,
  CircularProgress,
  Dialog as MUIDialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@material-ui/core'
import { BUTTON_VARIANT, ICON_NAMES } from '../../constants'
import RoundedButton from '../atoms/RoundedButton'
import Icon from '../atoms/Icon'

const useStyles = makeStyles(() => ({
  button: {
    borderRadius: '30px',
    fontWeight: 800,
    textTransform: 'capitalize',
    padding: '14px 16px',
    width: '280px',
    marginRight: '10px',
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none'
    }
  },
  dialog: {
    textAlign: 'center',
    position: 'relative'
  },
  paper: {
    maxWidth: '490px',
    borderRadius: '20px',
    padding: '46px 30px 42px'
  },
  actions: {
    flex: '0 0 auto',
    display: 'flex',
    padding: '8px',
    marginTop: '5px',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  close: {
    position: 'absolute',
    top: '23px',
    right: '23px'
  }
}))

const Dialog = ({
  actions,
  body,
  handleClose,
  open,
  title,
  Transition,
  BackdropProps,
  classes: _classes
}) => {
  const classes = useStyles()

  return (
    <MUIDialog
      open={open}
      onClose={handleClose}
      classes={{
        root: classes.dialog,
        paper: classes.paper,
        ..._classes
      }}
      TransitionComponent={Transition}
      BackdropProps={BackdropProps}
    >
      <IconButton
        onClick={handleClose}
        color='inherit'
        classes={{
          root: classes.close
        }}
      >
        <Icon name={ICON_NAMES.close} customSize='1.5rem' />
      </IconButton>
      <DialogTitle id='alert-dialog-title'>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-dialog-description'>
          {body}
        </DialogContentText>
      </DialogContent>
      <DialogActions
        classes={{
          root: classes.actions
        }}
      >
        {actions?.map(
          ({
            additionalClassName,
            autofocus = false,
            disabled,
            handleClick,
            label,
            loading = false,
            primary,
            variant
          }) => (
            <RoundedButton
              primary={primary}
              autoFocus={autofocus}
              disabled={disabled}
              className={`${classes.button} ${additionalClassName}`}
              key={`${label}-button`}
              onClick={handleClick}
              variant={variant}
            >
              {loading ? (
                <CircularProgress color='secondary' size={20} />
              ) : (
                label
              )}
            </RoundedButton>
          )
        )}
      </DialogActions>
    </MUIDialog>
  )
}

Dialog.propTypes = {
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      additionalClassName: PropTypes.string,
      autofocus: PropTypes.bool,
      disabled: PropTypes.bool,
      handleClick: PropTypes.func,
      label: PropTypes.string,
      loading: PropTypes.bool,
      primary: PropTypes.bool,
      variant: PropTypes.oneOf(Object.values(BUTTON_VARIANT))
    })
  ).isRequired,
  body: PropTypes.any.isRequired,
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  title: PropTypes.any.isRequired,
  Transition: PropTypes.node,
  classes: PropTypes.object,
  BackdropProps: PropTypes.object
}

export default Dialog
