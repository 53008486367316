import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import { useCustomDataset } from '../../contexts/CustomDatasetContext'
import cellTemplates from './cellTemplates'

const useStyles = makeStyles((theme) => ({
  baseTable: {
    margin: '12px 12px 24px 12px',
    '& th, td': {
      textAlign: 'left',
      margin: '3px 6px',
      padding: '3px 6px'
    }
  }
}))

const useColumnConfigurator = (rows, columnConfig) => {
  const result = useMemo(() => {
    if (columnConfig?.length > 0) {
      return columnConfig.map((c, index) => ({
        key: `column_${index}`,
        name: c.name || c.field,
        type: c.type || 'string',
        field: c.field,
        idField: c.idField || 'field',
        style: c.style,
        cellTemplate: cellTemplates[c.type || 'default'](c, c.format),
        cellKey: x => `r_${x.idField}_c_${x.field}`
      }))
    } else {
      if (rows.length > 0) {
        return Object.keys(rows[0]).map((c, index) => ({
          key: `column_${index}`,
          name: c,
          type: 'string',
          field: c,
          idField: 'field',
          cellTemplate: x => x[c],
          cellKey: x => `r_${x.idField}_c_${x.field}`
        }))
      }
    }
  }, [rows, columnConfig])
  return result
}

const TestResearchTable = ({
  columnConfig,
  datasetIndex
}) => {
  const classes = useStyles()
  const dataset = useCustomDataset()
  const rows = useMemo(() => dataset.isDone ? dataset.value[datasetIndex] : [], [dataset, datasetIndex])
  const columns = useColumnConfigurator(rows, columnConfig)

  return (
    <div>
      <main>
        <table className={classes.baseTable}>
          <thead>
            <tr>
              {columns && columns.length > 0 && columns.map(c => (
                <th key={c.key} style={c.style}>{c.name}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {rows && rows.length > 0 && rows.map((r, rowIndex) => {
              return (
                <tr key={`r_${rowIndex}`}>
                  {columns && columns.length && columns.map(c => {
                    return (
                      <td key={`${c.name}_${rowIndex}`} style={c.style}>{c.cellTemplate(r)}</td>
                    )
                  })}
                </tr>
              )
            }
            )}
          </tbody>
        </table>
      </main>
    </div>
  )
}

TestResearchTable.propTypes = {
  datasetIndex: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  columnConfig: PropTypes.array
}

TestResearchTable.defaultProps = {
  datasetIndex: 0,
  columnConfig: []
}

export default TestResearchTable
