import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Box } from '@material-ui/core'
import Text from '../../../atoms/Text'
import NumberTitle from '../components/NumberTitle'
import { useAvailableDates } from '../../../../redux/slices/appContext'
import SafeDate from '../../../../utils/levelDateHelper'
import { useSearchAccountsMultiple } from '../../../../api/accounts'

const NewAccountsPreview = ({ advisorId }) => {
  const [availableDates] = useAvailableDates()
  const query = useMemo(() => {
    return {
      totalAccounts: {
        resultType: 'total',
        filters: {
          createdDate: [
            {
              op: 'gte',
              value: new SafeDate(availableDates).subtract().day(30)
            }
          ]
        }
      },
      totalUnassignedAccounts: {
        filters: {
          hasClientsAssigned: false
        },
        resultType: 'total'
      }
    }
  }, [advisorId, availableDates]) // eslint-disable-line react-hooks/exhaustive-deps

  const { isLoading, data } = useSearchAccountsMultiple(query)

  const accountsData = useMemo(() => ({
    newAccounts: data?.totalAccounts?.total || 0,
    unAssignedAccounts: data?.totalUnassignedAccounts?.total || 0
  }), [data])

  return (
    <Box display='flex' flexDirection='column'>
      <Box display='flex' mb='1.5rem'>
        <Text
          text='New Accounts'
          variant='body2'
          customFontSize='1.5rem'
          customFontWeight='bold'
        />
      </Box>
      <Box display='flex' justifyContent='space-between' width='100%'>
        <NumberTitle
          title={accountsData.newAccounts}
          subtitle='In last 30 days'
          isLoading={isLoading}
          titleProps={{ customFontSize: '3.5rem' }}
          containerProps={{ justifyContent: 'end' }}
        />
        <NumberTitle
          title={accountsData.unAssignedAccounts}
          subtitle='Unassigned accounts'
          isLoading={isLoading}
          containerProps={{ justifyContent: 'end' }}
          isPositiveNumber={false}
          includePrefixSymbol={false}
          precision='0,0'
        />
      </Box>
    </Box>
  )
}

NewAccountsPreview.propTypes = {
  advisorId: PropTypes.number
}

export default NewAccountsPreview
