import React from 'react'
import PropTypes from 'prop-types'

import MuiDialogActions from '@material-ui/core/DialogActions'

const Actions = ({ children, className }) => {
  return (
    <MuiDialogActions className={className}>
      {children}
    </MuiDialogActions>
  )
}

Actions.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
}

export default React.memo(Actions)
