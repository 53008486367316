import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import ViewTabs from '../../../../organisms/AdvisorView/components/AdvisorViewTabs'
import { usePolicies } from '../../../../../hooks'
import GeneralTab from './GeneralTab'
import CompositionTab from './CompositionTab'

const tabOptions = {
  general: {
    value: 'general',
    label: 'Details'
  },
  composition: {
    value: 'composition',
    label: 'Model Composition'
  },
  assignments: {
    value: 'assignments',
    label: 'Assignments'
  }
}

const useTabOptions = () => {
  const policies = usePolicies()
  return useMemo(() => {
    const result = {}
    if (policies.admin_models_view) {
      result.general = tabOptions.general
    }
    if (policies.admin_models_view) {
      result.composition = tabOptions.composition
    }

    return result
  }, [policies])
}

function ComponentModelTabs ({ disabled }) {
  const tabs = useTabOptions()

  return (
    <div>
      <ViewTabs.Group tabOptions={tabs}>
        <div>
          <ViewTabs.Links
            disabled={disabled}
            tabsKey='admin_component_model_details'
          />
        </div>
        {tabs.general ? (
          <ViewTabs.Tab value='general'>
            <GeneralTab />
          </ViewTabs.Tab>
        ) : null}
        {tabs.composition ? (
          <ViewTabs.Tab value='composition'>
            <CompositionTab />
          </ViewTabs.Tab>
        ) : null}
      </ViewTabs.Group>
    </div>
  )
}

ComponentModelTabs.propTypes = {
  disabled: PropTypes.bool
}

ComponentModelTabs.defaultProps = {
  disabled: false
}

export default ComponentModelTabs
