import React from 'react'
import PropTypes from 'prop-types'
import Select from '../../../molecules/Select'

const CalendarYearSelector = ({ value, options, onChange }) => {
  return (
    <Select
      variant='outlined'
      options={options}
      value={value}
      onChange={onChange}
      fullWidth
      showCheckMarOnSelectedItems
    />
  )
}

CalendarYearSelector.propTypes = {
  value: PropTypes.string,
  options: PropTypes.array,
  onChange: PropTypes.func
}

export default CalendarYearSelector
