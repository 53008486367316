import React from 'react'
import { Switch, useRouteMatch } from 'react-router-dom'
import { SecureRoute } from '@okta/okta-react'
import ErrorBoundary from '../../molecules/ErrorBoundary'
import FormattingProvider from '../../organisms/FormattingProvider'
import { useHideSideMenuEffect } from '../../../redux/slices/appContext'
import NotAvailable from '../../organisms/NotAvailable'
import { useIsDenaliLabsActive } from '../../../hooks/featureChecks'
import IndexView from './IndexView'

const DenaliLabsIndex = () => {
  const { path } = useRouteMatch()
  useHideSideMenuEffect([])
  const isDenaliLabsActive = useIsDenaliLabsActive()

  if (!isDenaliLabsActive) {
    return <NotAvailable />
  }

  return (
    <ErrorBoundary name='Denali Labs'>
      <FormattingProvider>
        <Switch>
          <SecureRoute path={path} exact>
            <IndexView root={path} />
          </SecureRoute>
        </Switch>
      </FormattingProvider>
    </ErrorBoundary>
  )
}

export default DenaliLabsIndex
