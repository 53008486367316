import React from 'react'
import dayjs from 'dayjs'
import MilestoneIcon from '../../../../molecules/MilestoneIcon'
import { MILESTONE_CATEGORY_OPTIONS } from '../../../../../constants'

const getCategoryDisplayName = (category) => {
  const option = MILESTONE_CATEGORY_OPTIONS.find(x => x.key === category)
  return option?.label ?? '-'
}

export const cellTemplates = {
  /* eslint-disable-next-line jsx-a11y/anchor-is-valid */
  link: (onClick, accessor) => ({ value }) => (<a href='#' onClick={onClick(value)}>{accessor(value)}</a>),
  date: ({ value }) => dayjs.utc(value).format('M/D/YYYY'),
  icon: ({ value }) => <MilestoneIcon variant='small' milestone={value} />,
  milestoneCategory: ({ value }) => getCategoryDisplayName(value)
}

export const defaultColumnConfig = [
  { Header: 'Client', accessor: row => row.client, id: 'clientLink' },
  { Header: 'Milestone Target Date', accessor: 'entryDate', Cell: cellTemplates.date },
  { Header: 'Milestone Category', accessor: row => row.category?.name ?? row.entryJson?.category ?? '-', id: 'milestoneCategory', Cell: cellTemplates.milestoneCategory },
  { Header: 'Icon', accessor: row => row, id: 'milestoneIcon', Cell: cellTemplates.icon },
  { Header: 'Milestone Title', accessor: row => row.entryJson?.title ?? '-', id: 'milestoneTitle' },
  { Header: '# of Attachments', accessor: row => row.entryJson?.attachments?.length ?? '-', id: 'milestoneAttachmentCount' },
  { Header: '# of Tasks', accessor: row => row.entryJson?.tasks?.length ?? '-', id: 'milestoneTaskCount' }
]

export const columnConfigMap = {
  default: defaultColumnConfig
}

export const getColumnConfig = (config = 'default') => {
  const normalized = config.toLowerCase()
  return (normalized in columnConfigMap)
    ? columnConfigMap[normalized]
    : columnConfigMap.default
}

export const applyTemplate = (columnConfig, id, template) => {
  return columnConfig.map(column => {
    if (column.id === id) {
      return {
        ...column,
        Cell: template
      }
    }
    return column
  })
}
