import React, { useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'
import OperationalTable, { useOperationalTable } from '../../../organisms/OperationalTable'
import { useTransactionsSearch } from '../../../../api/coreData'
import { useAppContext } from '../../../../redux/slices/appContext'
import { useGroupingContext } from '../../../organisms/GroupingProvider/GroupingContext'
import { useAutoOperationalTableContext } from '../../../organisms/OperationalTable/AutoOperationalTableContext'

const mapSort = (sorts) =>
  sorts?.map(({ id, desc }) => ({ field: id, dir: desc ? 'desc' : 'asc' })) ?? [
    { field: 'valueDate', dir: 'desc' }
  ]

const pickFilter = (restFilters, defaultFilters) => {
  const _defaultFilters = Object.entries(defaultFilters || {})
  const _restFilters = restFilters || {}
  const _defaultedFilters = _defaultFilters.reduce((prev, [key, value]) => {
    if (key in _restFilters && !isEmpty(_restFilters[key])) {
      prev[key] = _restFilters[key]
    } else {
      prev[key] = value
    }

    return prev
  }, {})

  return Object.entries(_restFilters).reduce((prev, [key, value]) => {
    if (key in _defaultedFilters && !isEmpty(_defaultedFilters[key])) {
      prev[key] = _defaultedFilters[key]
    } else {
      prev[key] = value
    }

    return prev
  }, _defaultedFilters)
}

const buildQuery = ({
  dateRange,
  defaultFilter,
  filters,
  pageIndex,
  pageSize,
  sort,
  resultType,
  clientId,
  enableFirmWideView
}) => {
  const { textSearch, ...restFilters } = filters
  const baseQuery = {
    includes: {
      transactionCodeTags: true
    },
    filters: {
      ...pickFilter(restFilters, defaultFilter),
      valueDate: [
        { op: 'gte', value: dateRange.startDate },
        { op: 'lte', value: dateRange.endDate }
      ],
      ...(enableFirmWideView ? {} : { clientId })
    },
    textSearch,
    skip: (pageSize ?? 0) * (pageIndex ?? 0),
    take: pageSize,
    sort: mapSort(sort),
    resultType
  }

  return baseQuery
}

const useTransactionDetailsQuery = ({
  dateRange,
  enabled,
  defaultFilter,
  pageIndex,
  pageSize,
  sort,
  filters,
  enableFirmWideView
}) => {
  const { clientId } = useAppContext()
  const query = useMemo(
    () => {
      if (!enabled || !dateRange) {
        return null
      }
      return buildQuery({
        dateRange,
        defaultFilter,
        pageIndex,
        pageSize,
        sort,
        filters,
        clientId,
        enableFirmWideView
      })
    }, [
      dateRange,
      enabled,
      defaultFilter,
      filters,
      pageIndex,
      pageSize,
      sort,
      clientId,
      enableFirmWideView
    ]
  )
  const { data, isLoading, error } = useTransactionsSearch(query)

  return useMemo(
    () => ({
      data: isLoading || error ? [] : data,
      isLoading,
      error
    }),
    [data, isLoading, error]
  )
}

const useTransactionTotal = ({
  dateRange,
  enabled,
  defaultFilter,
  filters,
  enableFirmWideView
}) => {
  const { clientId } = useAppContext()
  const query = useMemo(
    () => {
      // TODO: find a way to show firm wide transactions counting without timing-out
      if (!enabled || !dateRange || enableFirmWideView) {
        return null
      }
      return buildQuery({
        dateRange,
        defaultFilter,
        resultType: 'total',
        filters,
        clientId,
        enableFirmWideView
      })
    },
    [enabled, dateRange, defaultFilter, filters, clientId, enableFirmWideView]
  )

  const { data, isLoading, error } = useTransactionsSearch(query)

  return useMemo(
    () => ({
      data: isLoading || error ? null : data?.total,
      isLoading,
      error
    }),
    [data, isLoading, error]
  )
}

const TransactionsTable = ({
  columns,
  dateRange,
  enabled,
  defaultFilter,
  defaultSort,
  defaultPageSize: _defaultPageSize,
  filters,
  enableFirmWideView
}) => {
  const { tableProps } = useAutoOperationalTableContext()
  const { setPerformanceTableProps } = useGroupingContext()

  const {
    defaultPageSize,
    pageIndex,
    pageSize,
    sort,
    onPagingChange,
    onSortingChange,
    onTableModeChange
  } = useOperationalTable({ defaultSort, defaultPageSize: _defaultPageSize })

  const { data, isLoading } = useTransactionDetailsQuery({
    dateRange,
    enabled,
    defaultFilter,
    pageIndex,
    pageSize,
    sort,
    filters,
    enableFirmWideView
  })

  const { data: _total } = useTransactionTotal({
    dateRange,
    enabled,
    defaultFilter,
    filters,
    enableFirmWideView
  })

  useEffect(() => {
    if (tableProps) {
      setPerformanceTableProps((prevState) => ({
        ...prevState,
        ...tableProps
      }))
    }
  }, [tableProps, setPerformanceTableProps])

  const total = useMemo(() => {
    // TODO: temporary until we figure a way to get the actual size of the data
    // if firms wide view is enabled then we mock its total value
    return enableFirmWideView ? defaultPageSize + 1 : _total
  }, [_total, defaultPageSize, enableFirmWideView])

  return (
    <OperationalTable
      mode='auto'
      columns={columns}
      data={data ?? []}
      autoLimit={defaultPageSize}
      defaultPageSize={defaultPageSize}
      defaultSort={defaultSort}
      itemName='Transactions'
      loading={isLoading}
      total={total}
      showTotalItemsLabel={false}
      onSortingChange={onSortingChange}
      onPagingChange={onPagingChange}
      onTableModeChange={onTableModeChange}
      autoSticky
    />
  )
}

TransactionsTable.propTypes = {
  columns: PropTypes.array.isRequired,
  defaultSort: PropTypes.array.isRequired,
  /** Tells us if the table should fetch data or not */
  enabled: PropTypes.bool,
  dateRange: PropTypes.object,
  defaultFilter: PropTypes.object,
  filters: PropTypes.object,
  enableFirmWideView: PropTypes.bool,
  defaultPageSize: PropTypes.number
}

TransactionsTable.defaultProps = {
  defaultFilter: {},
  filters: {},
  enableFirmWideView: false,
  defaultPageSize: 500
}

TransactionsTable.Wrapper = OperationalTable.Wrapper
TransactionsTable.SuperHeader = OperationalTable.SuperHeader
TransactionsTable.Search = OperationalTable.Search

export default TransactionsTable
