import { makeStyles } from '@material-ui/core'

export const sizeMap = {
  xsmall: 400,
  small: 600,
  medium: 700,
  xmedium: 1000,
  large: 1300,
  full: '100%'
}

export const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    justifyContent: 'center',
    padding: '48px',
    alignItems: ({ position }) => position === 'top' ? 'flex-start' : 'center'
  },
  modalExpanded: {
    alignItems: 'start',
    '& $modalInner': {
      height: '100%',
      width: '100% !important',
      transition: 'width 0.3s ease-in-out'
    }
  },
  modalInner: ({ width, isRounded }) => ({
    width,
    background: theme.palette.white,
    borderRadius: isRounded ? '20px' : 0,
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    maxWidth: '100%',
    maxHeight: '100%',
    margin: '0 auto',
    transition: 'width 0.3s ease-in-out',
    outline: 'none'
  }),
  modalHeader: {
    padding: '28px 28px 0',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'start'
  },
  modalBody: ({ bodyPadding }) => ({
    padding: bodyPadding,
    overflowY: 'auto',
    flex: 1
  })
}))
