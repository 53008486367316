import React from 'react'
import PropTypes from 'prop-types'
import { ChromePicker } from 'react-color'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(() => ({
  container: {
    position: 'relative'
  },
  content: {
    position: 'absolute',
    zIndex: '2'
  },
  colorPickerContainer: {
    position: 'fixed',
    top: '0px',
    right: '0px',
    bottom: '0px',
    left: '0px'
  }
}))

const ColorPickerDialog = ({ value, onClick, onChange }) => {
  const classes = useStyles()
  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <div className={classes.colorPickerContainer} onClick={onClick} />
        <ChromePicker color={value} onChange={onChange} />
      </div>
    </div>
  )
}

ColorPickerDialog.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  onClick: PropTypes.func
}

export default ColorPickerDialog
