import React, { useCallback, useEffect } from 'react'
import { useGroupingContext } from '../../GroupingProvider/GroupingContext'
import AlternativesReportTable, { alternativesReportTableDefaultProps, alternativesReportTablePropTypes } from './AlternativesReportTable'

const AlternativesReportTableWrapper = (props) => {
  const { selectedGrouping, groupsExpanded, performanceTableProps, setPerformanceTableProps } = useGroupingContext()

  useEffect(() => {
    const hiddenColumns = performanceTableProps?.hiddenColumns?.[selectedGrouping?.key]
    if (hiddenColumns) {
      performanceTableProps.setHiddenColumns(hiddenColumns)
    }
  }, [performanceTableProps, selectedGrouping?.key])

  const tableRef = useCallback(
    (node) => {
      if (node !== null) {
        setPerformanceTableProps(prevState => ({ ...prevState, ...node }))
      }
    },
    [setPerformanceTableProps]
  )

  return (
    <AlternativesReportTable
      ref={tableRef}
      {...props}
      expanded={groupsExpanded}
    />
  )
}
AlternativesReportTableWrapper.propTypes = alternativesReportTablePropTypes
AlternativesReportTableWrapper.defaultProps = alternativesReportTableDefaultProps

export default AlternativesReportTableWrapper
