import { useCallback } from 'react'
import { useHolidays } from '../api/coreData'
import { getNearestWorkableAsOfDate } from '../components/pages/admin/billing/runBilling/helpers'

const useWorkableDates = () => {
  const { data: holidays } = useHolidays()

  const getNearestWorkableDate = useCallback(
    ({ date, searchBackwards = true }) =>
      getNearestWorkableAsOfDate(holidays, date, searchBackwards),
    [holidays]
  )

  return {
    holidays,
    getNearestWorkableDate
  }
}

export default useWorkableDates
