import { ClickAwayListener, IconButton } from '@material-ui/core'
import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Icon from '../../../atoms/Icon'
import { ICON_NAMES, TEXT_VARIANTS } from '../../../../constants'
import Text from '../../../atoms/Text'
import Editor from '../../WYSIWYGEditor'

const QuickEditDescription = ({ classes, initialValue, onChange, defaultIsEditable = false }) => {
  const [isEditable, setIsEditable] = useState(defaultIsEditable)
  const [value, setValue] = useState(initialValue)

  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  const toggleIsEditable = useCallback((forceState) => {
    const state = forceState ?? !isEditable
    setIsEditable(state)
  }, [isEditable])

  const onSaveChange = useCallback(() => {
    onChange({ description: value })
    toggleIsEditable(false)
  }, [onChange, toggleIsEditable, value])

  const onReset = useCallback(() => {
    setValue(initialValue)
    toggleIsEditable(false)
  }, [initialValue, toggleIsEditable])

  return (
    <>
      {isEditable ? (
        <ClickAwayListener onClickAway={onSaveChange}>
          <div className={classes.inputContainer}>
            <Editor
              defaultValue={initialValue}
              placeholder='Enter in a description...'
              onChange={setValue}
            />
            <IconButton
              classes={{ root: classes.iconButton }}
              onClick={onSaveChange}
              color='inherit'
            >
              <Icon name={ICON_NAMES.check} customSize='1.2rem' />
            </IconButton>
            <IconButton
              onClick={onReset}
              color='inherit'
            >
              <Icon name={ICON_NAMES.close} customSize='1.2rem' />
            </IconButton>
          </div>
        </ClickAwayListener>
      ) : (
        <div
          className={classes.container}
          style={{ fontStyle: value ? 'normal' : 'italic' }}
        >
          <Text
            variant={TEXT_VARIANTS.body1}
            text={value ?? 'No description'}
            color={value ? 'inherit' : '#666'}

          >
            {value}
          </Text>
          <IconButton
            onClick={() => toggleIsEditable(true)}
            color='inherit'
          >
            <Icon name={ICON_NAMES.edit} customSize='1.2rem' additionalClasses={classes.editIcon} />
          </IconButton>
        </div>
      )}
    </>
  )
}

QuickEditDescription.propTypes = {
  classes: PropTypes.objectOf(PropTypes.shape({
    container: PropTypes.string,
    inputContainer: PropTypes.string,
    editIcon: PropTypes.string
  })),
  initialValue: PropTypes.string,
  onChange: PropTypes.func,
  defaultIsEditable: PropTypes.bool
}

export default QuickEditDescription
