import React, { createContext, useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useBoolean } from '../../../../../../hooks'

const SystemReportsContext = createContext({})

const SystemReportsProvider = ({ children }) => {
  const [exportReportMeta, setExportReportMeta] = useState()
  const [isExportDialogOpen, toggleExportDialog] = useBoolean()

  useEffect(() => {
    if (!isExportDialogOpen) {
      setExportReportMeta()
    }
  }, [isExportDialogOpen])

  return (
    <SystemReportsContext.Provider
      value={{
        isExportDialogOpen,
        toggleExportDialog,
        exportReportMeta,
        setExportReportMeta,
        reportRecentTableProps: {
          isRefetchIntervalEnabled: true,
          disableRowClick: true
        }
      }}
    >
      {children}
    </SystemReportsContext.Provider>
  )
}

export const useSystemReportsContext = () => {
  return useContext(SystemReportsContext)
}

SystemReportsProvider.propTypes = {
  children: PropTypes.node
}

export default SystemReportsProvider
