import React, { useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import clsx from 'clsx'
import { useWealthJourney } from '../WealthJourneyProvider'
import FadeIn from '../../../../molecules/Transitions/FadeIn'
import { useAncestorSize } from '../SizeAware'
import EntryListItem from './EntryListItem'

dayjs.extend(utc)

const useStyles = makeStyles((theme) => ({
  entryList: ({ listDirection, height }) => {
    if (listDirection === 'column') {
      return {
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'auto',
        height: height,
        maxHeight: height,
        paddingBottom: '20px',
        position: 'relative',

        '& .wj-entry-container': {
          paddingTop: theme.layout.padding.p5,
          paddingBottom: theme.layout.padding.p5,
          paddingLeft: theme.layout.padding.p10,
          paddingRight: theme.layout.padding.p10,
          transition: 'background-color .2s ease-in-out',
          '&.__yearSelected': {
            backgroundColor: `color-mix(in srgb, ${theme.palette.primary.main} 20%, transparent)`
          }
        }
      }
    } else if (listDirection === 'row') {
      return {
        display: 'flex',
        flexDirection: 'row',
        overflowX: 'auto',
        paddingTop: theme.layout.padding.p20,
        paddingBottom: theme.layout.padding.p20,
        paddingLeft: theme.layout.padding.p50,
        paddingRight: theme.layout.padding.p50,

        '& .wj-entry-container': {
          padding: theme.layout.padding.p10,
          transition: 'background-color .2s ease-in-out',
          '&.__yearSelected': {
            backgroundColor: `color-mix(in srgb, ${theme.palette.primary.main} 25%, transparent)`
          }
        }
      }
    }
  }
}))

function EntryItem ({ entry, selectedEntry, selectedYear, entryComponents, onClick, options, defaultComponentType, allowEdit }) {
  const selected = selectedEntry?.entryId === entry.entryId
  const yearSelected = selectedYear === entry.year
  const entryTypeCode = entry.entryType?.entryTypeCode ?? 'unknown'
  const type = entryComponents[entryTypeCode] || defaultComponentType

  return (
    <FadeIn className={clsx('wj-entry-container', { __yearSelected: options?.disableYearHighlight ? false : yearSelected })} data-year={entry.year}>
      <EntryListItem
        type={type}
        entry={entry}
        selected={selected}
        onClick={onClick}
        allowEdit={allowEdit}
      />
    </FadeIn>
  )
}

EntryItem.propTypes = {
  entry: PropTypes.shape({
    entryId: PropTypes.number,
    entryDate: PropTypes.string,
    entryTypeId: PropTypes.number,
    entryText: PropTypes.string,
    entryJson: PropTypes.object,
    year: PropTypes.number,
    entryType: PropTypes.shape({
      entryTypeCode: PropTypes.string
    })
  }),
  selectedEntry: PropTypes.any,
  selectedYear: PropTypes.number,
  onClick: PropTypes.func,
  entryComponents: PropTypes.object,
  options: PropTypes.shape({
    disableYearHighlight: PropTypes.bool
  }),
  defaultComponentType: PropTypes.string,
  allowEdit: PropTypes.bool
}

function EntryList ({
  entryTypes,
  listDirection,
  entryComponents,
  options,
  autoSelect,
  defaultComponentType,
  height,
  allowEdit
}) {
  const {
    entries,
    entriesLoading,
    selectedYear,
    selectedEntry,
    setSelectedEntry,
    entryTypes: availableEntryTypes
  } = useWealthJourney()

  const filteredEntries = useMemo(() => {
    return entries.filter(x => {
      if (!entryTypes?.length) return availableEntryTypes.some(aet => aet.entryTypeId === x.entryTypeId)

      return entryTypes.some(y => {
        const entryType = availableEntryTypes.find(aet => aet.entryTypeCode === y)
        return entryType.entryTypeId === x.entryTypeId
      })
    }).sort((a, b) => {
      const aDate = dayjs(a.entryDate)
      const bDate = dayjs(b.entryDate)
      if (aDate.isSame(bDate)) return 0
      return listDirection === 'row'
        ? aDate.diff(bDate, 'day')
        : bDate.diff(aDate, 'day')
    })
  }, [entries, entryTypes, availableEntryTypes, listDirection])

  const { size, elementRef } = useAncestorSize()
  const classes = useStyles({ listDirection, height: size.remainingSpace ? size.remainingSpace - 22 : height })

  // optional auto-select "first" item
  useEffect(() => {
    if (autoSelect && filteredEntries?.length) {
      const filteredEntry = filteredEntries.find(x => selectedEntry?.entryId === x.entryId)
      if (!filteredEntry) {
        const isHorizontal = listDirection === 'row'
        setSelectedEntry(filteredEntries.at(isHorizontal ? -1 : 0), isHorizontal ? 'instant' : 'smooth')
      }
    }
  }, [autoSelect, selectedEntry, filteredEntries, setSelectedEntry, listDirection])

  if (entriesLoading) return null

  return (
    <FadeIn>
      <div ref={elementRef} className={classes.entryList}>
        {filteredEntries.map((entry) => (
          <EntryItem
            key={entry.entryId}
            entry={entry}
            selectedEntry={selectedEntry}
            selectedYear={selectedYear}
            onClick={() => setSelectedEntry(entry)}
            entryComponents={entryComponents}
            defaultComponentType={defaultComponentType}
            options={options}
            allowEdit={allowEdit}
          />
        ))}
      </div>
    </FadeIn>
  )
}

EntryList.propTypes = {
  entryTypes: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.oneOf(['milestone', 'activity', 'meeting']),
      PropTypes.string
    ])
  ),
  listDirection: PropTypes.oneOf(['row', 'column']),
  entryComponents: PropTypes.shape({
    milestone: PropTypes.oneOf(['card', 'horizontal-card', 'horizontal-compact'])
  }),
  options: PropTypes.shape({
    disableYearHighlight: PropTypes.bool
  }),
  autoSelect: PropTypes.bool,
  defaultComponentType: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  allowEdit: PropTypes.bool
}

EntryList.defaultProps = {
  listDirection: 'row',
  entryComponents: {
    milestone: 'card'
  },
  options: {
    disableYearHighlight: false
  },
  autoSelect: true,
  defaultComponentType: 'card',
  height: '90vh',
  allowEdit: false
}

export default EntryList
