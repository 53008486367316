import React, { useMemo, useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { Grid, Box, darken } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import WealthJourneyMilestones from '../../../molecules/WealthJourneyMilestones'
import WealthJourneyMilestoneForm from '../../../molecules/WealthJourneyMilestoneForm'
import WealthJourneyYearSelector from '../../../molecules/WealthJourneyYearSelector'
import Text from '../../../atoms/Text'
import { BUTTON_VARIANT, TEXT_VARIANTS } from '../../../../constants'
import { useToggle } from '../../../../hooks'
import { useSetAppContext } from '../../../../redux/slices/appContext'
import Skeleton from '../../../atoms/Skeleton'
import Dialog from '../../../molecules/Dialog'
import { clearSuppression, setSuppression } from '../../../../utils/globalSuppression'
import { useWealthJourneyPermissions } from '../shared/hooks'
import SydButton from '../../../commonDesign/Button'
import entryType, { entryTypeIdentifier, entryTypePropType, ACTIONS } from './entryType'

const useStyles = makeStyles((theme) => ({
  content: {
    display: 'flex',
    justifyContent: 'center'
  },
  header: {
    width: '100%',
    margin: '24px 0'
  },
  cancelButton: {
    backgroundColor: theme.palette.common.white,
    color: '#212945',
    border: '2px solid #212945',
    '&:hover': {
      backgroundColor: theme.palette.gray.light
    }
  },
  submitButton: {
    backgroundColor: '#FADBD7',
    color: '#D44333',
    '&:hover': {
      backgroundColor: darken('#FADBD7', 0.1)
    }
  }
}))

const WealthJourneyMilestoneContent = ({
  selectedEntryType,
  entries,
  entriesLoading,
  selectedEntry,
  onEditEntry,
  onAddEntry,
  onDeleteEntry,
  onEntrySelected,
  yearSelection,
  taskTitle,
  taskButtonLabel,
  theming
}) => {
  const classes = useStyles()
  const setAppContext = useSetAppContext()

  const canEditMilestones = useWealthJourneyPermissions(entryType.MILESTONES, ACTIONS.EDIT)

  const [showForm, , setShowFormOn, setShowFormOff] = useToggle(false)
  const [showModal, , setShowModalOn, setShowModalOff] = useToggle(false)
  const [addMode, setAddMode] = useState(false)

  const handleDelete = useCallback(() => {
    if (selectedEntry) onDeleteEntry(selectedEntry.entryId)
    setShowModalOff()
  }, [onDeleteEntry, selectedEntry, setShowModalOff])

  const setEditModeOn = useCallback(() => {
    setAppContext({ isFullViewEditModeOn: true })
    setSuppression('wheel', true)
    setShowFormOn()
  }, [setAppContext, setShowFormOn])

  const setAddModeOn = useCallback(() => {
    setAddMode(true)
    setEditModeOn()
  }, [setAddMode, setEditModeOn])

  const setEditModeOff = useCallback(() => {
    setAppContext({ isFullViewEditModeOn: false })
    clearSuppression('wheel')
    setAddMode(false)
    setShowFormOff()
  }, [setAppContext, setShowFormOff, setAddMode])

  const dialogTitle = useMemo(() => (
    <Text
      text='Archive Milestone?'
      customFontSize='1.5rem'
      color='#212945'
      customFontWeight={600}
    />
  ), [])

  const dialogBody = useMemo(() => (
    <Text customFontSize='1rem' color='#212945'>
      Are you sure you want to archive this milestone?
    </Text>
  ), [])

  const actions = useMemo(() => ([
    {
      additionalClassName: classes.cancelButton,
      disabled: false,
      handleClick: setShowModalOff,
      label: 'Cancel',
      variant: BUTTON_VARIANT.outlined
    },
    {
      additionalClassName: classes.submitButton,
      autofocus: true,
      disabled: false,
      handleClick: handleDelete,
      label: 'Yes, archive it',
      loading: false
    }
  ]), [setShowModalOff, handleDelete, classes.cancelButton, classes.submitButton])

  const filteredEntries = useMemo(() => {
    return (entries ?? []).filter(x => (x.entryTypeId === entryTypeIdentifier.MILESTONES))
  }, [entries])

  if (selectedEntryType !== entryType.MILESTONES) return null

  return (
    <Grid container className={classes.content}>
      <WealthJourneyYearSelector {...yearSelection} />
      <Grid className={classes.header} item>
        <Box display='flex' flexDirection='row' justifyContent='space-between' alignItems='center'>
          <Text text='Milestones' variant={TEXT_VARIANTS.h1} />
          {canEditMilestones && (
            <SydButton
              variant='primary'
              onClick={setAddModeOn}
              size='xs'
              disabled={showForm}
              icon='add'
            >
              Add Milestone
            </SydButton>
          )}
        </Box>
      </Grid>
      {entriesLoading && (
        <Skeleton height='270px' width='100%' />
      )}
      {!entriesLoading && !showForm && (
        <WealthJourneyMilestones
          entries={filteredEntries}
          selectedEntry={selectedEntry}
          onEntrySelected={onEntrySelected}
          onEditEntry={setEditModeOn}
          onDeleteEntry={setShowModalOn}
          taskTitle={taskTitle}
          taskButtonLabel={taskButtonLabel}
          theming={theming}
        />
      )}
      {canEditMilestones && showForm && (
        <WealthJourneyMilestoneForm
          entries={filteredEntries}
          selectedEntry={addMode ? null : selectedEntry}
          onAddEntry={onAddEntry}
          onEditEntry={onEditEntry}
          onClose={setEditModeOff}
          taskTitle={taskTitle}
          taskButtonLabel={taskButtonLabel}
        />
      )}
      <Dialog
        actions={actions}
        body={dialogBody}
        handleClose={setShowModalOff}
        open={showModal}
        title={dialogTitle}
      />
    </Grid>
  )
}

WealthJourneyMilestoneContent.propTypes = {
  selectedEntryType: entryTypePropType.isRequired,
  entries: PropTypes.array,
  selectedEntry: PropTypes.object,
  entriesLoading: PropTypes.bool,
  onEntrySelected: PropTypes.func.isRequired,
  onEditEntry: PropTypes.func.isRequired,
  onAddEntry: PropTypes.func.isRequired,
  onDeleteEntry: PropTypes.func.isRequired,
  yearSelection: PropTypes.shape({
    onYearSelected: PropTypes.func.isRequired,
    selectedYear: PropTypes.object,
    onReachNavigationEdge: PropTypes.func.isRequired,
    yearTabs: PropTypes.array
  }).isRequired,
  taskTitle: PropTypes.string,
  taskButtonLabel: PropTypes.string,
  theming: PropTypes.object
}

WealthJourneyMilestoneContent.defaultProps = {
  selectedMilestone: null,
  entriesLoading: false
}

export default React.memo(WealthJourneyMilestoneContent)
