import React, { useCallback } from 'react'
import { makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import {
  useSearchClassificationTagsDebounced,
  useToggle
} from '../../../../../hooks'
import Autocomplete from '../Autocomplete'

const useStyles = makeStyles(() => ({
  title2: {
    fontSize: '1.2rem',
    color: '#000',
    fontWeight: '600',
    marginTop: '1rem',
    marginBottom: '0.625rem',
    '& span': {
      color: '#db3131'
    }
  },
  searchBar: {
    '& input': {
      fontSize: '1rem !important'
    }
  }
}))

const ClassificationTagExposurePicker = ({
  value,
  limitTags,
  onChange,
  renderTags,
  hasError
}) => {
  const classes = useStyles()

  const { options, onChangeQuery, isSearchLoading } =
    useSearchClassificationTagsDebounced({ })

  const [touched, setTouched] = useToggle()

  const handleChange = useCallback(
    (value) => {
      if (!touched) {
        setTouched(true)
      }
      onChangeQuery(value)
    },
    [touched, setTouched, onChangeQuery]
  )

  return (
    <>
      <Autocomplete
        multiple
        hasError={hasError}
        renderTags={renderTags}
        limitTags={limitTags}
        value={value}
        options={options ?? []}
        onInputChange={handleChange}
        onChange={onChange}
        loading={isSearchLoading}
        className={classes.searchBar}
        onClear={() => onChange('')}
        onSelectOption={(_, option) => onChange(option)}
      />
    </>
  )
}

ClassificationTagExposurePicker.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.array.isRequired,
  renderTags: PropTypes.func.isRequired,
  limitTags: PropTypes.number.isRequired,
  hasError: PropTypes.bool
}

export default ClassificationTagExposurePicker
