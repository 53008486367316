import React from 'react'
import {
  Container,
  Grid,
  Box,
  Divider,
  makeStyles
} from '@material-ui/core'
import Skeleton from '../../../../atoms/Skeleton'
import { SKELETON_VARIANTS } from '../../../../../constants'

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '1.5rem',
    height: '100%'
  },
  start: {
    marginLeft: '-16px'
  },
  skeleton: {
    transformOrigin: 'top'
  }
}))

const SourceSkeleton = () => {
  const classes = useStyles()

  return (
    <Container fixed maxWidth={false} className={classes.container}>
      <Grid container spacing={2}>
        <Grid item md={10} xs={12}>
          <Grid container spacing={4}>
            <Grid item md={4} xs={12} className={classes.start}>
              <Skeleton
                variant={SKELETON_VARIANTS.text}
                width='100%'
                height='88px'
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <Skeleton
                variant={SKELETON_VARIANTS.text}
                width='100%'
                height='88px'
              />
            </Grid>
            <Grid item md={1} xs={12}>
              <Skeleton
                variant={SKELETON_VARIANTS.text}
                width='100%'
                height='88px'
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Divider className={classes.start} />

      <Box marginLeft='-16px' marginTop='0.75rem'>
        <Skeleton
          variant={SKELETON_VARIANTS.text}
          width='100%'
          height='100vh'
          className={classes.skeleton}
        />
      </Box>
    </Container>
  )
}

export default SourceSkeleton
