import { useMemo } from 'react'
import { useAllocationVsTargetQuery } from '../../../../api/rebalancer'
import { useClientDates } from '../../../../api/coreData'

export const useAvtData = (clientId) => {
  const { data: dates, isLoading: datesLoading } = useClientDates(clientId)
  const avtQuery = useMemo(() => {
    if (datesLoading) return null
    return ({
      // always on rebalancing target model
      // always for [client, rebalancing target model class]
      // always on endingValue
      // always on as-of-date
      levelTypes: ['client', 'assetClassTag'],
      allocator: 'endingValue',
      allocationGrouping: ['client'],
      compareTo: ['rebalancing'],
      filters: {
        clientId
      },
      dateRange: {
        startDate: dates.max,
        endDate: dates.max
      }
    })
  }, [datesLoading, dates, clientId])
  const { data, isLoading: queryLoading, error } = useAllocationVsTargetQuery(avtQuery)

  return {
    data,
    isLoading: queryLoading || datesLoading,
    error,
    dates
  }
}
