import { isArray, isEmpty, isNil, isString } from 'lodash'
import { getSearchQuery } from '../../../../hooks/helpers'

export const parseUrlArrayParam = (param) => {
  if (!param) return []
  if (!isString(param?.[0]) && isArray(param)) {
    return param.filter(Boolean)
  }
  return (param?.[0] || '').split(',').filter(Boolean)
}

export const cleanEmptyParams = (urlSearchParams) => {
  return Object.entries(urlSearchParams).reduce((acc, [key, value]) => {
    return {
      ...acc,
      [key]: !value || isNil(value) ? '' : value
    }
  }, {})
}

export const mapTransactionsFilters = (transactionCodeTag, filters, searchText) => {
  const { transactionCodeTagId } = transactionCodeTag || {}
  const tagIds = !isEmpty(filters?.tagIds) ? filters?.tagIds : []

  const transactionCodeTagIds = [...tagIds]
  if (transactionCodeTagId) {
    transactionCodeTagIds.push(transactionCodeTagId)
  }

  const assetIds = !isEmpty(filters?.assetIds) ? filters?.assetIds : []

  const custodianIds = !isEmpty(filters?.custodianIds)
    ? filters?.custodianIds
    : []

  const groupIds = !isEmpty(filters?.groupIds)
    ? filters?.groupIds
    : []

  return {
    transactionCodeTagId: !isEmpty(transactionCodeTagIds)
      ? [{ op: 'in', value: transactionCodeTagIds }]
      : undefined,
    assetId: !isEmpty(assetIds)
      ? [{ op: 'in', value: assetIds }]
      : undefined,
    custodianId: !isEmpty(custodianIds)
      ? [{ op: 'in', value: custodianIds }]
      : undefined,
    groupId: !isEmpty(groupIds)
      ? [{ op: 'in', value: groupIds }]
      : undefined,
    textSearch: getSearchQuery(['accountNumber', 'accountName', 'assetName'], searchText)
  }
}
