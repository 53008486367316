import React from 'react'
import { makeStyles } from '@material-ui/core'
import FadeIn from '../../molecules/Transitions/FadeIn'
import Logo from './Logo'

const useStyles = makeStyles((theme) => ({
  loading: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.layout.gap.loose,
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: theme.typography.fontSizes.xxl,
    fontWeight: theme.typography.weights.light,
    minHeight: 'calc(100vh - 300px)'
  }
}))

function LoadingView () {
  const classes = useStyles()
  return (
    <FadeIn className={classes.loading} delay={2} duration={2}>
      <Logo />
      <div>Loading...</div>
    </FadeIn>
  )
}

export default LoadingView
