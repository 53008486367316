import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Grid, makeStyles, Typography } from '@material-ui/core'
import { useGroupSearch } from '../../../../api/groups'
import Skeleton from '../../../atoms/Skeleton'
import GroupInfo from './GroupInfo'

const useStyles = makeStyles((theme) => ({
  section: {
    backgroundColor: ({ backgroundImage }) => backgroundImage
      ? undefined
      : theme.palette.white,
    backgroundImage: ({ backgroundImage }) => backgroundImage
      ? `url("${backgroundImage}")`
      : undefined,
    padding: '0 1rem 1rem',
    marginBottom: '1rem',
    borderRadius: '8px',
    border: `2px solid ${theme.palette.lightGrey}`,
    boxShadow: '0px 10px 10px -5px rgba(0,0,0,0.5)',
    '& .__header': {
      padding: '1rem',
      marginBottom: '1rem'
    }
  }
}))

const GroupSection = ({ name, levelTypeId, size }) => {
  const classes = useStyles()
  const query = useMemo(() => {
    if (!levelTypeId) return null

    return {
      take: 100,
      filters: {
        levelTypeId: [
          { op: 'eq', value: levelTypeId }
        ]
      },
      sort: [
        { field: 'longName', dir: 'asc' }
      ]
    }
  }, [levelTypeId])
  const { data, isLoading } = useGroupSearch(query)

  return (
    <Grid className={classes.section} item xs={size}>
      <Grid container>
        <Grid className='__header' item xs={12}>
          <Typography variant='h1'>{name}</Typography>
        </Grid>
        {isLoading ? (
          <Skeleton width='100%' height='30px' />
        ) : data.data.map(g => (
          <Grid key={g.groupId} item xs={2}>
            <GroupInfo group={g} />
          </Grid>
        ))}
      </Grid>
    </Grid>
  )
}

GroupSection.propTypes = {
  name: PropTypes.string.isRequired,
  levelTypeId: PropTypes.number,
  size: PropTypes.number
}

GroupSection.defaultProps = {
  size: 12
}

export default GroupSection
