import React, { useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { ADMIN_ROUTES, FEATURE_FLAG, WEALTHBOX_INTEGRATION } from '../../../../constants'
import { useFeatureFlag } from '../../../../redux/slices/appConfig'
import WealthboxManageView from './Wealthbox/WealthboxManageView'

function ManageIntegration () {
  const pathParams = useParams()
  const history = useHistory()
  const {
    active: INTEGRATIONS,
    extras: INTEGRATIONS_EXTRAS = { active: [] }
  } = useFeatureFlag(FEATURE_FLAG.INTEGRATIONS)

  useEffect(() => {
    const integration = pathParams?.integration
    if (integration && (!INTEGRATIONS || !INTEGRATIONS_EXTRAS.active.includes(integration))) {
      history.push(ADMIN_ROUTES.INTEGRATIONS)
    }
  }, [INTEGRATIONS, INTEGRATIONS_EXTRAS, history, pathParams?.integration])

  return (
    <>
      {pathParams?.integration === WEALTHBOX_INTEGRATION && (
        <WealthboxManageView />
      )}
    </>
  )
}

export default ManageIntegration
