import React, { memo, useMemo } from 'react'
import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'
import uniqBy from 'lodash/uniqBy'
import { Box } from '@material-ui/core'
import {
  ALERT_SEVERITY,
  ALERT_VARIANT,
  DOCUMENT_VAULT_REJECTION_MESSAGES
} from '../../constants'
import Alert from '../atoms/Alert'
import { fileRejections } from '../../prop-types'

const DocumentVaultFileRejectionMessageList = ({
  fileRejections,
  withMarginBottom
}) => {
  const errorCodes = uniqBy(
    fileRejections.reduce(
      (prev, fileRejection) => [...prev, ...fileRejection.errors],
      []
    ),
    'code'
  )

  const errorsKeyMap = useMemo(() => Object
    .values(DOCUMENT_VAULT_REJECTION_MESSAGES)
    .reduce((acc, { code, message }) => ({
      ...acc,
      [code]: message
    }), {}), [])

  return (
    !isEmpty(fileRejections) &&
    errorCodes.map(({ code }, index) => {
      const marginBottom =
        withMarginBottom && errorCodes.length - 1 === index ? 2 : 0
      return (
        <Box mt={2} mb={marginBottom} key={code}>
          <Alert variant={ALERT_VARIANT.filled} severity={ALERT_SEVERITY.error}>
            {errorsKeyMap[code]}
          </Alert>
        </Box>
      )
    })
  )
}

DocumentVaultFileRejectionMessageList.propTypes = {
  fileRejections,
  withMarginBottom: PropTypes.bool
}

DocumentVaultFileRejectionMessageList.defaultProps = {
  fileRejections: [],
  withMarginBottom: false
}

export default memo(DocumentVaultFileRejectionMessageList)
