import React, { useCallback } from 'react'
import { makeStyles } from '@material-ui/core'
import { noop } from 'lodash'
import PropTypes from 'prop-types'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import Text from '../../../../atoms/Text'
import Select from '../../../../molecules/Select'
import {
  DATE_RANGE_CUSTOM_OPTION_KEYS,
  getCalendarYearOptions,
  getCustomDateRangeOptionSelected,
  parseCalendarYearDateRangeRawValue,
  useKeyedRelativeDateRanges
} from '../../../../molecules/RelativeDateSelect'
import { useAppContext } from '../../../../../redux/slices/appContext'
import { modifyByIndex, removeByIndex } from '../../../../../utils'
import { useGroupingContext } from '../GroupingContext'
import CalendarYearSelector from '../CalendarYearSelector'
import CustomDateRangeSelector from '../CustomDateRangeSelector'
import DragAndDropListItem from '../DragAndDropListItem'

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.typography.pxToRem(4)
  },
  subtitle: {
    marginBottom: '1rem',
    fontSize: theme.typography.pxToRem(14)
  },
  selectorContainer: {
    width: '100%',
    display: 'flex',
    minWidth: 0,
    flexDirection: 'column'
  },
  customDateRangeSelector: {
    marginTop: '0.5rem'
  }
}))

const { CUSTOM, CALENDAR_YEAR } = DATE_RANGE_CUSTOM_OPTION_KEYS

const GroupingDateRangeSelector = ({ dateRanges, setDateRanges }) => {
  const classes = useStyles()
  const context = useGroupingContext()
  const {
    groupingDateRanges,
    groupingDateRangeOptions
  } = context

  const { availableDates } = useAppContext()

  const { options: performanceDateRangeOptions } = useKeyedRelativeDateRanges(
    groupingDateRanges,
    availableDates,
    groupingDateRangeOptions
  )

  const onChangeDateRange = useCallback((value, index) => {
    setDateRanges((prevState = []) => {
      return modifyByIndex(index, prevState, [value])
    })
  }, [setDateRanges])

  const onReorderDateRanges = useCallback((dragIndex, hoverIndex) => {
    setDateRanges((prevItems = []) => {
      const newItems = [...prevItems]
      const dragItem = newItems[dragIndex]
      newItems.splice(dragIndex, 1)
      newItems.splice(hoverIndex, 0, dragItem)
      return newItems
    })
  }, [setDateRanges])

  const getCalendarYearOptionsFormatted = useCallback((index) => {
    const calendarYearOptions = getCalendarYearOptions({
      keyFormatter: (value) => `${CALENDAR_YEAR}_${index}_${value}`
    })
    return calendarYearOptions
  }, [])

  const getAvailableOptionsByIndex = useCallback(
    (index) => {
      const disabledDateRanges = removeByIndex(index, dateRanges)
      return performanceDateRangeOptions.map((option) => ({
        ...option,
        disabled: disabledDateRanges.includes(option.value)
      }))
    },
    [dateRanges, performanceDateRangeOptions]
  )

  return (
    <>
      <Text
        text='Click and drag to reorder the columns'
        className={classes.subtitle}
      />
      <DndProvider backend={HTML5Backend}>
        {dateRanges.map((dateRangeKey, index) => {
          const selectedCustomOption = getCustomDateRangeOptionSelected(dateRangeKey)

          let customDateRangeKey = null
          if (selectedCustomOption) {
            customDateRangeKey = selectedCustomOption.value
          }

          return (
            <DragAndDropListItem
              id={dateRangeKey}
              key={dateRangeKey}
              index={index}
              value={dateRangeKey}
              onMoveItem={onReorderDateRanges}
            >
              <div className={classes.selectorContainer}>
                <Select
                  variant='outlined'
                  value={customDateRangeKey || dateRangeKey}
                  options={getAvailableOptionsByIndex(index)}
                  onChange={(value) => onChangeDateRange(value, index)}
                  fullWidth
                  showCheckMarOnSelectedItems
                />
                {customDateRangeKey === CALENDAR_YEAR && (
                  <div className={classes.customDateRangeSelector}>
                    <CalendarYearSelector
                      value={dateRanges[index]}
                      options={getCalendarYearOptionsFormatted(index)}
                      onChange={(rawValue) => {
                        const { key, value } =
                          parseCalendarYearDateRangeRawValue(rawValue)
                        onChangeDateRange(`${key}_${index}_${value}`, index)
                      }}
                    />
                  </div>
                )}
                {customDateRangeKey === CUSTOM && (
                  <div className={classes.customDateRangeSelector}>
                    <CustomDateRangeSelector
                      value={dateRanges[index]}
                      onChange={(...args) => {
                        const value = `${CUSTOM}_${index}_${args.join('_')}`
                        onChangeDateRange(value, index)
                      }}
                    />
                  </div>
                )}
              </div>
            </DragAndDropListItem>
          )
        })}
      </DndProvider>
    </>
  )
}

GroupingDateRangeSelector.propTypes = {
  dateRanges: PropTypes.array,
  setDateRanges: PropTypes.func
}

GroupingDateRangeSelector.defaultProps = {
  dateRanges: [],
  setDateRanges: noop
}

export default GroupingDateRangeSelector
