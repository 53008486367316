import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import PropTypes from 'prop-types'
import { Box } from '@material-ui/core'
import noop from 'lodash/noop'
import FeeOverridesList from '../../FeeOverrides'
import {
  FEE_LEVEL_OVERRIDE_OPTIONS,
  FEE_OVERRIDE_LEVEL_VALUES,
  findOverrideOption,
  mapFeeOverrides
} from '../../FeeOverrides/helpers'
import { AccountFormSection, useAccountFormContext } from '../AccountFormProvider'
import SectionHeader from '../../shared/SectionHeader'
import EditButton from '../../shared/EditButton'
import { ACCOUNTS } from '../../../../../policies/admin'
import { useFeeSchedule } from '../../../../../api/billing'
import { mapFeeScheduleOptions } from '../helpers'
import MenuItem from '../../ClientDetailsView/MenuItem'
import SubmitButtons from '../SubmitButtons'
import { updateAccountLevelFeeOverrides } from '../../../../../service'
import Alert from '../../../../atoms/Alert'
import { ALERT_SEVERITY, ALERT_VARIANT } from '../../../../../constants'
import { mapFormDefaultValues } from './AccountBillingDetails'

const AccountBillingFeeOverrides = ({
  filterAccountsByAssignedToClientIds,
  onSave = noop
}) => {
  const { account, sectionEditing, cancelEdit, editSection, editing } = useAccountFormContext()
  const isEditing = sectionEditing('account_billing_fee_overrides')

  const [isSubmitting, setIsSubmitting] = useState(false)

  const formMethods = useForm({
    mode: 'onChange',
    defaultValues: {}
  })
  const {
    reset,
    handleSubmit,
    setError,
    formState: { errors, isValid }
  } = formMethods

  const defaultValues = useMemo(() => {
    return mapFormDefaultValues(account)
  }, [account])

  useEffect(() => {
    reset(defaultValues)
  }, [reset, defaultValues])

  const feeScheduleOptionsRenderer = useCallback(
    ({ payload: feeSchedule }, onChange, checkedIcon) => {
      const { id, name, description } = feeSchedule
      const item = { id, name, description }
      return <MenuItem item={item} checked={checkedIcon} onChange={onChange} />
    },
    []
  )

  const {
    data: feeScheduleOptions = [],
    isLoading: isLoadingFeeScheduleOptions
  } = useFeeSchedule(mapFeeScheduleOptions)

  const feeScheduleSelectProps = useMemo(
    () => ({
      options: feeScheduleOptions,
      isLoading: isLoadingFeeScheduleOptions,
      optionsRenderer: feeScheduleOptionsRenderer
    }),
    [
      feeScheduleOptions,
      feeScheduleOptionsRenderer,
      isLoadingFeeScheduleOptions
    ]
  )

  const levelOverrideOptions = useMemo(() => {
    const assetClassOption = findOverrideOption(FEE_OVERRIDE_LEVEL_VALUES.class)
    const accountOption = findOverrideOption(FEE_OVERRIDE_LEVEL_VALUES.account)
    const positionOption = findOverrideOption(FEE_OVERRIDE_LEVEL_VALUES.position)
    return [
      ...FEE_LEVEL_OVERRIDE_OPTIONS.filter(
        ({ value }) =>
          value !== accountOption.value && value !== positionOption.value && value !== assetClassOption.value
      ),
      {
        ...assetClassOption,
        rules: {
          ...assetClassOption.rules,
          searchQueryOptions: {
            query: {
              filters: { classificationTagTypeId: 51 }
            }
          }
        }
      },
      {
        ...positionOption,
        rules: {
          ...positionOption.rules,
          searchQueryOptions: {
            queryOptions: {
              levelType: 'account',
              levelId: defaultValues.accountId
            },
            defaultPositionId: defaultValues?.feeOverride?.map(feeOverride => feeOverride.feeOverrideLevel)
          }
        }
      },
      {
        ...accountOption,
        rules: {
          max: 1,
          readOnlyValue: true,
          defaultValueIfSelected: defaultValues.accountId,
          searchQueryOptions: {
            assignedToClientIds: filterAccountsByAssignedToClientIds,
            defaultAccountIds: [defaultValues.accountId]
          }
        }
      }
    ]
  }, [defaultValues, filterAccountsByAssignedToClientIds])

  const onCancel = useCallback(() => {
    cancelEdit()
    reset(defaultValues)
  }, [cancelEdit, defaultValues, reset])

  const onSubmit = useCallback(async (values) => {
    setIsSubmitting(true)
    try {
      const removeFeeOverrides = defaultValues.feeOverride?.filter(feeOverride =>
        !values.feeOverride.some(override => override.feeOverrideLevel === feeOverride.feeOverrideLevel)
      )
      await updateAccountLevelFeeOverrides(account.accountId, {
        feeOverrides: mapFeeOverrides(values.feeOverride),
        removeFeeOverrides: removeFeeOverrides.map(feeOverride => feeOverride.feeLevelOverrideId)
      })
      cancelEdit()
      onSave()
    } catch (err) {
      setError('form', { message: 'Issue saving. Try again' })
      console.error(err)
    }
    setIsSubmitting(false)
  }, [account.accountId, cancelEdit, defaultValues.feeOverride, onSave, setError])

  return (
    <AccountFormSection section='account_billing_fee_overrides'>
      <SectionHeader text='Fee Overrides'>
        <EditButton
          policy={ACCOUNTS.editBillingOverrides}
          editing={isEditing}
          onClick={() => editSection({
            section: 'account_billing_fee_overrides'
          })}
          disabled={editing}
        />
      </SectionHeader>
      {errors?.form && (
        <Box mb={2}>
          <Alert variant={ALERT_VARIANT.standard} severity={ALERT_SEVERITY.error}>
            {errors.form.message}
          </Alert>
        </Box>
      )}
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FeeOverridesList
            editMode={isEditing}
            levelOverrideOptions={levelOverrideOptions}
            feeScheduleSelectProps={feeScheduleSelectProps}
          />
          <SubmitButtons
            editMode={isEditing}
            onCancel={onCancel}
            isSubmitting={isSubmitting}
            isFormValid={isValid}
          />
        </form>
      </FormProvider>
    </AccountFormSection>
  )
}

AccountBillingFeeOverrides.propTypes = {
  filterAccountsByAssignedToClientIds: PropTypes.arrayOf(PropTypes.number),
  onSave: PropTypes.func
}

export default AccountBillingFeeOverrides
