import React from 'react'
import PropTypes from 'prop-types'
import OperationalTable from '../../../../../../organisms/OperationalTable'
import { AutoOperationalTableContextProvider } from '../../../../../../organisms/OperationalTable/AutoOperationalTableContext'
import GroupingProvider from '../../../../../../organisms/GroupingProvider'

const ReportRecentTableContainer = ({ children }) => {
  return (
    <GroupingProvider configurationKey='bobiReportRecentTable'>
      <AutoOperationalTableContextProvider>
        <OperationalTable.Wrapper>{children}</OperationalTable.Wrapper>
      </AutoOperationalTableContextProvider>
    </GroupingProvider>
  )
}

ReportRecentTableContainer.propTypes = {
  children: PropTypes.node
}

export default ReportRecentTableContainer
