import { useMemo } from 'react'
import numeral from 'numeral'
import PlanAssumption from './PlanAssumption'

export const useAssumptions = (
  currentPlan,
  clientBalance,
  setClientBalance,
  currentPlanDispatch,
  assumptions
) => {
  const availableAssumptionMap = useMemo(() => {
    const values = {
      endingValue: clientBalance ?? 0,
      portfolioGrowthModel: currentPlan?.assumptions?.portfolioGrowthModel,
      inflationModel: currentPlan?.assumptions?.inflationModel
    }

    return {
      endingValue: {
        label: 'Starting Portfolio Value',
        isPlanAssumption: false,
        display: PlanAssumption,
        value: values.endingValue,
        displayValue: numeral(values.endingValue).format('$0,000'),
        editingDisplayValue: numeral(values.endingValue).format('0,000[.]0'),
        startAdornment: '$',
        onChangeHandler: (value) => setClientBalance(value)
      },
      portfolioGrowthModel: {
        label: 'Portfolio Growth',
        isPlanAssumption: true,
        display: PlanAssumption,
        value: values.portfolioGrowthModel?.growthFactor ?? 0,
        editingDisplayValue: numeral((values.portfolioGrowthModel?.growthFactor ?? 0) * 100).format('0.0[000]'),
        displayValue: numeral(values.portfolioGrowthModel?.growthFactor ?? 0).format('0.0[000]%'),
        endAdornment: '%',
        displayProps: {
          step: 0.1
        },
        onChangeHandler: (value) => {
          if (isNaN(value)) {
            return values.portfolioGrowthModel
          }
          return {
            portfolioGrowthModel: { ...values.portfolioGrowthModel, growthFactor: value / 100 }
          }
        }
      },
      inflationModel: {
        label: 'Inflation',
        isPlanAssumption: true,
        display: PlanAssumption,
        value: values.inflationModel?.growthFactor ?? 0,
        editingDisplayValue: numeral((values.inflationModel?.growthFactor ?? 0) * 100).format('0.0[000]'),
        displayValue: numeral(values.inflationModel?.growthFactor ?? 0).format('0.0[000]%'),
        endAdornment: '%',
        displayProps: {
          step: 0.1
        },
        onChangeHandler: (value) => {
          if (isNaN(value)) {
            return values.inflationModel
          }
          return {
            inflationModel: { ...(values.inflationModel ?? {}), growthFactor: value / 100 }
          }
        }
      }
    }
  }, [clientBalance, currentPlan?.assumptions, setClientBalance])

  return useMemo(() => Object.entries(assumptions).map(([key, { label: labelOverride }]) => {
    const assumptionDefault = availableAssumptionMap[key] ?? null

    return {
      ...assumptionDefault,
      key,
      assumptionKey: key,
      ...(labelOverride && { label: labelOverride })
    }
  }).filter(row => row), [assumptions, availableAssumptionMap])
}
