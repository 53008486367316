import React, { useCallback } from 'react'
import { makeStyles } from '@material-ui/core'
import RoundedButton from '../../../atoms/RoundedButton'
import { BUTTON_SIZES } from '../../../../constants'
import { useContextualClientContext } from '../context/ContextualClientContext'
import { useSetCurrentClient } from '../../../../redux/slices/appContext'
import { findReplaceString } from '../../../../utils'

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'row-reverse',
    padding: '1.5rem 1rem 0 1rem',
    gap: '0.5rem'
  }
}))

const ClientActions = () => {
  const classes = useStyles()
  const setCurrentClient = useSetCurrentClient()
  const { actionButtons, client } = useContextualClientContext()

  const onNavigateTo = useCallback(
    ({ to: path, shouldSetClientGlobally }) => {
      if (shouldSetClientGlobally && client) {
        setCurrentClient(client.clientId)
      }
      const pathWithParams = findReplaceString(path, 'clientId', client.clientId)
      window.open(pathWithParams, '_blank')
    },
    [client, setCurrentClient]
  )

  return (
    <div className={classes.container}>
      {actionButtons.map((action) => {
        return (
          <RoundedButton
            key={action.to}
            onClick={() => onNavigateTo(action)}
            size={BUTTON_SIZES.extraSmall}
            variant='outlined'
            className={classes.button}
          >
            {action.label}
          </RoundedButton>
        )
      })}
    </div>
  )
}

export default ClientActions
