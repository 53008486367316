import {
  withStyles,
  Tab as MuiTab,
  Tabs as MuiTabs,
  Box
} from '@material-ui/core'
import React from 'react'

export const Tabs = withStyles((theme) => ({
  root: { marginRight: 'auto' },
  indicator: {
    backgroundColor: theme.primary,
    height: theme.typography.pxToRem(4)
  }
}))(MuiTabs)

export const Tab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    fontStyle: 'normal',
    color: theme.palette.dimGray,
    lineHeight: '1.4',
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: '1rem',
    marginRight: theme.spacing(1),
    '&:focus': {
      opacity: 1
    },
    '&:hover': {
      color: theme.palette.darkJungle,
      fontWeight: 'bold'
    },
    '& .MuiTab-wrapper': {
      opacity: 1
    },
    '&.Mui-selected': {
      fontWeight: 'bold'
    }
  }
}))(MuiTab)

export const TabWrapper = withStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: ({ noMargin }) => noMargin ? 0 : theme.typography.pxToRem(16),
    borderBottom: '2px solid #E7E7E7'
  }
}))(({ noMargin, ...props }) => <Box {...props} />)

Tabs.Wrapper = TabWrapper

Tabs.Tab = Tab

export default Tabs
