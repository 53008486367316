import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useTheme } from '@material-ui/core'
import { isArray, isString } from 'lodash'
import { useFeatureScheduleExposure } from '../../../../api/billing'
import Metric from './Metric'
import InlineDividedMetric from './InlineDividedMetric'

const FeeSchedulesMetric = ({ valueFormat, redirectTo }) => {
  const theme = useTheme()

  const { missingFeesQuery, duplicateFeesQuery, queryOptions } = useMemo(() => {
    return {
      missingFeesQuery: {
        totalCount: {
          operator: 'eq',
          value: 0
        },
        resultType: 'total'
      },
      duplicateFeesQuery: {
        totalCount: {
          operator: 'gt',
          value: 1
        },
        resultType: 'total'
      },
      queryOptions: {
        mapper: (data) => data?.body?.total ?? 0
      }
    }
  }, [])

  const { data: totalMissingFees, isLoading: isLoadingMissingFees } =
    useFeatureScheduleExposure(missingFeesQuery, queryOptions)

  const { data: totalDuplicateFees, isLoading: isLoadingDuplicateFees } =
    useFeatureScheduleExposure(duplicateFeesQuery, queryOptions)

  return (
    <Metric.Wrapper redirectTo={isString(redirectTo) ? redirectTo : null}>
      <InlineDividedMetric justifyContentEvenly>
        <Metric
          value={totalMissingFees}
          valueFormat={valueFormat}
          description='Missing Fees'
          isLoading={isLoadingMissingFees}
          redirectTo={isArray(redirectTo) ? redirectTo?.[0] : undefined}
        />
        <Metric
          value={totalDuplicateFees}
          valueFormat={valueFormat}
          isLoading={isLoadingDuplicateFees}
          valueColor={theme.palette.error.primary}
          description='Duplicate Fees'
          redirectTo={isArray(redirectTo) ? redirectTo?.[1] : undefined}
        />
      </InlineDividedMetric>
    </Metric.Wrapper>
  )
}

FeeSchedulesMetric.propTypes = {
  valueFormat: PropTypes.string,
  redirectTo: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string)
  ])
}

FeeSchedulesMetric.defaultProps = {
  valueFormat: 'human',
  valueDescription: 'in the last 30 days',
  redirectTo: undefined
}

export default FeeSchedulesMetric
