import React from 'react'
import PropTypes from 'prop-types'
import SydInput from '../../../../../commonDesign/SydInput'
// import useDebouncedValue from '../../../../../../hooks/useDebounceValue'

function LinkFieldInput (props) {
  // const val = useDebouncedValue(props.value, 2000)
  // const urlInfo = useMemo(() => {
  //   try {
  //     return new URL(val)
  //   } catch (err) {
  //     return null
  //   }
  // }, [val])

  return (
    <>
      <SydInput size='sm' {...props} />
    </>
  )
}

LinkFieldInput.propTypes = {
  value: PropTypes.any
}

export default LinkFieldInput
