import { IconButton, darken, makeStyles, Tooltip } from '@material-ui/core'
import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { useContextContainer } from '../../../../../abundanceEngine/components/ContextContainer'
import { CLIENT_VIEW_CONTEXT } from '../..'
import FastAvatar from '../../../../molecules/FastAvatar'

const useStyles = makeStyles((theme) => ({
  iconButton: {
    position: 'inherit',
    padding: '0.75rem',
    backgroundColor: theme.palette.seaFoam,
    '&:hover': {
      backgroundColor: darken(theme.palette.seaFoam, 0.1)
    }
  }
}))

const PractifiButton = ({ clientSalesforceId, disabled }) => {
  const classes = useStyles()
  const [, setClientViewContext] = useContextContainer(CLIENT_VIEW_CONTEXT)

  const onOpenDrawer = useCallback(() => {
    setClientViewContext((prevState) => ({
      ...prevState,
      drawerOpen: true
    }))
  }, [setClientViewContext])

  const onClick = useCallback((e) => {
    e.preventDefault()
    e.stopPropagation()
    onOpenDrawer()
    setClientViewContext((prevState) => ({
      ...prevState,
      integrationsInfo: { clientId: clientSalesforceId }
    }))
  }, [clientSalesforceId, onOpenDrawer, setClientViewContext])

  return (
    <Tooltip title='Show Practifi Information'>
      <IconButton
        className={classes.iconButton}
        onClick={onClick}
        disabled={disabled}
        size='small'
      >
        <FastAvatar size='xs' avatarUrl='/practifi-icon.png' />
      </IconButton>
    </Tooltip>
  )
}

PractifiButton.propTypes = {
  disabled: PropTypes.bool,
  clientSalesforceId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

export default PractifiButton
