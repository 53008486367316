import Page from './layout/Page'
import Header from './layout/Header'
import AssumptionSelection from './AssumptionSelection'
import DynamicPlanningPLanLineChart from './PlanLineChart'
import GoalCategoryDetailList from './GoalCategories/DetailList'
import MonteCarloStochasticModel from './MonteCarlos/StochasticModel'
import ContextWrapper from './ContextWrapper'
import PlanSelect from './Plans/PlanSelect'
import Pyramid from './Pyramid'
import DynamicPlanningChartSwitcher from './ChartSwitcher'
import BetaTag from './BetaTag'

export const DynamicPlanning = {
  DP_Page: Page,
  DP_Header: Header,
  DP_PlanSelect: PlanSelect,
  DP_AssumptionSelection: AssumptionSelection,
  DP_LineChart: DynamicPlanningPLanLineChart,
  DP_CategoryDetailList: GoalCategoryDetailList,
  DP_MonteCarloStochasticModel: MonteCarloStochasticModel,
  DP_ContextWrapper: ContextWrapper,
  DP_Pyramid: Pyramid,
  DP_ChartSwitcher: DynamicPlanningChartSwitcher,
  DP_BetaTag: BetaTag
}
