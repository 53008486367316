import { useMemo } from 'react'
import { compareNumericString } from '../../../../../utils'

const sharedAttrs = { alignRight: true, width: 50 }

export const defaultColumnConfig = {
  columns: [
    { Header: 'Name', accessor: 'name' },
    { id: 'allocation', Header: 'Allocation', accessor: 'allocation', ...sharedAttrs, format: 'percentage', sortType: compareNumericString },
    { id: 'allocationToParent', Header: 'Rel Alloc', accessor: 'allocationToParent', ...sharedAttrs, format: 'percentage', sortType: compareNumericString }
  ],
  defaultSort: [{ id: 'allocation', desc: true }]
}

export const useColumns = ({
  formatter,
  columnConfig = defaultColumnConfig
}) => {
  return useMemo(() => {
    const { columns, defaultSort } = columnConfig
    const mapColumns = columns => {
      if (!columns) return undefined
      return columns.map(column => {
        const formatString = column.format

        return {
          ...column,
          Cell: ({ value }) => formatter(value, formatString),
          columns: mapColumns(column.columns)
        }
      })
    }
    const templatedColumns = mapColumns(columns)

    return {
      columns: templatedColumns,
      defaultSort
    }
  }, [columnConfig, formatter])
}
