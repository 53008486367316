import { makeStyles } from '@material-ui/core'

export const useDialogStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    fontWeight: theme.typography.weights.light,
    '& strong': {
      fontWeight: theme.typography.weights.bold
    }
  },
  group: {
    display: 'flex',
    flexDirection: 'row',
    gap: '10px',
    width: '100%',
    flexWrap: 'wrap',
    '& > *': {
      flex: '1 0 300px'
    }
  },
  feeCalcTypeRadio: {
    padding: '4px 9px'
  },
  compare: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.layout.gap.dense,
    width: '100%',
    alignItems: 'center',
    '& > *:first-child, & > *:last-child': {
      width: '50%'
    }
  },
  confirm: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.layout.gap.loose,
    fontSize: theme.typography.fontSizes.xl
  },
  textarea: {
    width: '100%',
    marginBottom: '10px',
    resize: 'none',
    height: '300px'
  },
  actions: {
    marginTop: theme.layout.margin.thick,
    display: 'flex',
    justifyContent: 'flex-end'
  },
  additionalInfo: {
    minHeight: '200px',
    flex: '1 0 200px',
    marginTop: theme.layout.margin.thick
  },
  editorWrapper: {
    border: `1px solid ${theme.palette.gray.main}`,
    marginBottom: theme.layout.margin.thick,
    maxWidth: '100%'
  },
  error: {
    backgroundColor: theme.palette.danger.main,
    color: theme.palette.danger.contrast,
    flex: '1 0 auto',
    padding: theme.layout.padding.medium,
    borderRadius: theme.layout.radius.round,
    fontSize: theme.typography.fontSizes.md,
    lineHeight: '20px',
    marginRight: theme.layout.margin.thin
  }
}))
