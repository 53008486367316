import React, { useCallback, useImperativeHandle, useState } from 'react'
import RoundedModal from '../../../../../molecules/RoundedModal'
import RuleBuilderForm from './Form'

const RuleBuilderDialog = React.forwardRef(function RuleBuilderDialog (_, ref) {
  const [dialogState, setDialogState] = useState({ open: false, processing: false })
  const close = useCallback(() => {
    setDialogState({ open: false, processing: false })
  }, [setDialogState])

  useImperativeHandle(ref, () => ({
    open: (rules) => setDialogState({ open: true, processing: false, rules })
  }), [setDialogState])

  return (
    <RoundedModal
      title='Tagging Rules'
      open={dialogState.open}
      onClose={close}
      position='top'
      size='large'
      disablePortal={false}
    >
      <div>
        {dialogState.open ? (<RuleBuilderForm rules={dialogState.rules} onComplete={close} onCancel={close} />) : null}
      </div>
    </RoundedModal>
  )
})

RuleBuilderDialog.propTypes = {
}

export default RuleBuilderDialog
