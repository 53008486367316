import './wdyr'
import React from 'react'
import ReactDOM from 'react-dom'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import DayjsUtils from '@date-io/dayjs'
import { Provider } from 'react-redux'
import './assets/fonts.css'
import './assets/summitwealth.css'
import './assets/editor.css'
import { BrowserRouter as Router } from 'react-router-dom'
import BrandProvider from './providers/BrandProvider'
import OktaApp from './OktaApp'
import * as serviceWorker from './serviceWorker'
import configureStore from './redux'
import { applyBrand, getSavedBrand } from './applyBrand'

try {
  const brand = getSavedBrand()
  if (brand) {
    applyBrand(brand)
  }
} catch (err) {
  console.error(err, 'error applying saved brand on initialize')
}

const store = configureStore()

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <MuiPickersUtilsProvider utils={DayjsUtils}>
        <Router>
          <BrandProvider>
            <OktaApp />
          </BrandProvider>
        </Router>
      </MuiPickersUtilsProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('summitwealth')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
