import React from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { useSetCurrentClient } from '../../../../../redux/slices/appContext'
import ClientAvatarWithName from '../../../../molecules/ClientAvatarWithName'

const ClientNameCell = ({
  row: {
    original: client
  }
}) => {
  const history = useHistory()
  const setCurrentClient = useSetCurrentClient()

  return (
    <ClientAvatarWithName
      client={client} onClick={(e) => {
        setCurrentClient(client?.clientId)
        history.push(client?.redirectPath ?? '/')
      }}
    />
  )
}

ClientNameCell.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      clientId: PropTypes.number,
      profilePic: PropTypes.string,
      longName: PropTypes.string,
      shortName: PropTypes.string,
      redirectPath: PropTypes.string
    })
  })
}

export default ClientNameCell
