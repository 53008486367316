import React, { useMemo } from 'react'
import keyMirror from 'keymirror'
import { Link } from 'react-router-dom'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { isEmpty, truncate } from 'lodash'
import { ADMIN_ROUTES } from '../../../../constants'
dayjs.extend(utc)

export const OPTIONS_KEYS = keyMirror({
  accounts: null,
  accountsNotAssigned: null
})

export const cellTemplates = {
  /* eslint-disable-next-line jsx-a11y/anchor-is-valid */
  drilldownLink:
    (accessor) => {
      return ({ row }) => {
        return (
          <Link
            onClick={(event) => event.stopPropagation()}
            to={`${ADMIN_ROUTES.CLASSIFY_ASSETS}/${row.original.assetId}`}
          >
            {accessor(row.original)}
          </Link>
        )
      }
    }
}
export const assetCussipAccesor = (row) => row.cusip

export const MAX_CHARACTERS = 30

export const CHARACTER_SEPARATOR = ' '

export const defaultSort = [{ id: 'longName', desc: false }]

export const DYNAMIC_FILTER_PREFIX = 'dynamic_filter'

export const getDynamicFilterColumnId = (id = '') => {
  return `${DYNAMIC_FILTER_PREFIX}_${id}`
}

export const COLUMN_IDS = {
  ASSET_TYPE: getDynamicFilterColumnId('assetType')
}

export const defaultColumnConfig = {
  columns: [
    {
      Header: 'Identifier',
      accessor: 'assetIdentifier',
      id: 'assetIdentifier',
      Cell: cellTemplates.drilldownLink(row => row.assetIdentifier)
    },
    {
      Header: 'Symbol',
      accessor: 'symbol',
      id: 'symbol'
    },
    {
      Header: 'CUSIP',
      accessor: 'cusip',
      id: 'cusip',
      Cell: cellTemplates.drilldownLink(assetCussipAccesor)
    },
    {
      Header: 'Asset Name',
      accessor: (row) => {
        return truncate(row.assetName || 'No Name Available', {
          length: MAX_CHARACTERS,
          separator: CHARACTER_SEPARATOR
        })
      },
      id: 'assetName'
    },
    {
      Header: 'Display Name',
      accessor: (row) => {
        return truncate(row.displayName || '--', {
          length: MAX_CHARACTERS,
          separator: CHARACTER_SEPARATOR
        })
      },
      id: 'displayName'
    },
    {
      Header: 'Name',
      accessor: (row) => {
        return truncate(row.longName || '--', {
          length: MAX_CHARACTERS,
          separator: CHARACTER_SEPARATOR
        })
      },
      id: 'longName'
    },
    {
      Header: 'Type',
      accessor: 'assetType',
      id: COLUMN_IDS.ASSET_TYPE
    }
  ],
  defaultSort: [{ id: 'longName', desc: false }]
}

const findClassificationTagTypeById = (tagTypes, tagTypeId) => {
  return tagTypes.find(({ classificationTagTypeId }) => {
    return classificationTagTypeId === tagTypeId
  })
}

export const getClassificationTagColumnId = ({
  classificationTagTypeId,
  classificationType
}) => {
  const dynamicIdKey = getDynamicFilterColumnId(classificationTagTypeId)
  const id = `${dynamicIdKey}_${classificationType}`
  return id
}

export const useColumns = (
  assets,
  classificationTagTypes,
  defaultColumnConfig,
  visibleFilters,
  searchParams
) => {
  return useMemo(() => {
    if (isEmpty(classificationTagTypes) || isEmpty(assets)) {
      return defaultColumnConfig
    }
    const classificationTagColumns = classificationTagTypes.map(
      (classificationTagType) => {
        const {
          tags,
          payload: { longName, classificationType, classificationTagTypeId }
        } = classificationTagType

        const hidden = !isEmpty(searchParams?.classificationTagIds)
          ? !tags.find(({ tagId }) => searchParams.classificationTagIds.includes(tagId))
          : []

        const columnId = getClassificationTagColumnId({
          classificationType,
          classificationTagTypeId
        })

        return {
          id: columnId,
          Header: longName,
          disableSortBy: true,
          accessor: (row) => {
            const classification = findClassificationTagTypeById(
              row.classificationTags,
              classificationTagTypeId
            )
            return truncate(classification?.longName, {
              length: MAX_CHARACTERS,
              separator: CHARACTER_SEPARATOR
            })
          },
          hidden: hidden && !visibleFilters.includes(columnId)
        }
      }
    )
    return {
      ...defaultColumnConfig,
      columns: [
        ...(defaultColumnConfig?.columns || []),
        ...classificationTagColumns
      ]
    }
  }, [
    assets,
    visibleFilters,
    defaultColumnConfig,
    classificationTagTypes,
    searchParams?.classificationTagIds
  ])
}

export const getVisibleFilterColIds = (searchParams, classificationTagTypes) => {
  const { classificationTagIds } = searchParams || {}

  if (!classificationTagIds?.length) return []

  return classificationTagTypes.reduce(
    (acc, { payload, tags }) => {
      const { classificationType, classificationTagTypeId } = payload
      const tagIds = tags.map(({ tagId }) => tagId)

      if (!tagIds.some(id => classificationTagIds.includes(id))) {
        return acc
      }
      const columnId = getClassificationTagColumnId({
        classificationType,
        classificationTagTypeId
      })
      return [...acc, columnId]
    }, [])
}

export const mapAssetTypeOptions = (assetTypes) => {
  return assetTypes.map(({ assetTypeId, displayName }) => ({
    value: assetTypeId,
    label: displayName
  }))
}

export const COLUMN_FILTER_MAPPING = {
  [COLUMN_IDS.ASSET_TYPE]: 'assetTypeFilters'
}
