import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import { useCurrentTab } from '../../../organisms/AdvisorView/components/AdvisorViewTabs'
import { useAppContext } from '../../../../redux/slices/appContext'
import LinkButton from '../../../commonDesign/LinkButton'
import FadeIn from '../../../molecules/Transitions/FadeIn'

const useStyles = makeStyles((theme) => ({
  actions: {
    marginLeft: 'auto',
    marginRight: '20px',
    display: 'flex',
    gap: '0.5rem'
  }
}))

function IndexViewActions ({ root }) {
  const classes = useStyles()
  const tabValue = useCurrentTab()
  const { isSummitUser } = useAppContext()

  if (tabValue === 'dataIn') {
    return (
      <FadeIn className={classes.actions}>
        <LinkButton to={`${root}/upload-file`} icon='cloudUpload'>Upload File</LinkButton>
        {isSummitUser ? (
          <LinkButton to={`${root}/jobs`} icon='clock'>Jobs</LinkButton>
        ) : null}
        {isSummitUser ? (
          <LinkButton to={`${root}/targets`} icon='location'>Targets</LinkButton>
        ) : null}
      </FadeIn>
    )
  }

  return null
}

IndexViewActions.propTypes = {
  root: PropTypes.string
}

export default IndexViewActions
