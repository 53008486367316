import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { Box, IconButton, Modal as MuiModal, Slide } from '@material-ui/core'
import { noop } from 'lodash'
import clsx from 'clsx'
import { ICON_NAMES, TEXT_VARIANTS } from '../../../constants'
import Text from '../../atoms/Text'
import Icon from '../../atoms/Icon'
import { sizeMap, useStyles } from './styles'

const Transition = React.forwardRef(function Transition (props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})
const defaultTransitionDuration = { enter: 200, exit: 200 }

const SydModal = ({
  disablePortal = true,
  open = false,
  allowMaximize = false,
  size = 'medium',
  title = '',
  subtitle = '',
  children,
  onClose = noop,
  position = 'middle',
  isRounded = true,
  onExpand = noop,
  bodyPadding = '16px 28px 28px'
}) => {
  const classes = useStyles({
    width: sizeMap[size] ?? sizeMap.medium,
    position,
    isRounded,
    bodyPadding
  })
  const [isExpanded, setIsExpanded] = useState(false)
  const toggleIsExpanded = useCallback((overrideValue) => {
    const newVal = overrideValue ?? !isExpanded
    setIsExpanded(newVal)
    onExpand(newVal)
  }, [isExpanded, onExpand])

  return (
    <MuiModal
      disablePortal={disablePortal}
      onClose={onClose}
      open={open}
      aria-labelledby='modal-title'
      aria-describedby='modal-description'
      disableEnforceFocus
      className={clsx(classes.modal, { [classes.modalExpanded]: isExpanded })}
    >
      <Transition appear in={open} timeout={defaultTransitionDuration} role='none presentation'>
        <div className={classes.modalInner}>
          <div className={classes.modalHeader}>
            <Box>
              <Text variant={TEXT_VARIANTS.h2} customFontSize='24px'>{title}</Text>
              {!!subtitle?.length && <Text text={subtitle} variant={TEXT_VARIANTS.body1} customFontSize='16px' />}
            </Box>
            <Box mt='-16px' display='flex' gridGap='16px'>
              {allowMaximize && (
                <IconButton
                  onClick={() => toggleIsExpanded()}
                  title={isExpanded ? 'Minimize' : 'Maximize'}
                  edge='end'
                >
                  <Icon customSize='20px' name={isExpanded ? ICON_NAMES.collapse : ICON_NAMES.expand} />
                </IconButton>
              )}
              <IconButton
                onClick={onClose}
                title='Close'
                edge='end'
              >
                <Icon customSize='20px' name={ICON_NAMES.close} />
              </IconButton>
            </Box>
          </div>
          {open ? (
            <div className={classes.modalBody}>
              {children}
            </div>
          ) : null}
        </div>
      </Transition>
    </MuiModal>
  )
}

SydModal.propTypes = {
  disablePortal: PropTypes.bool,
  open: PropTypes.bool,
  allowMaximize: PropTypes.bool,
  size: PropTypes.oneOf(['xsmall', 'small', 'medium', 'xmedium', 'large', 'full']),
  position: PropTypes.oneOf(['top', 'middle']),
  title: PropTypes.string,
  subtitle: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  onClose: PropTypes.func,
  isRounded: PropTypes.bool,
  onExpand: PropTypes.func,
  bodyPadding: PropTypes.string
}
export default SydModal
