import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import numeral from 'numeral'
import { makeStyles } from '@material-ui/core/styles'
import isNumber from 'lodash/isNumber'
import isString from 'lodash/isString'
import noop from 'lodash/noop'
import { childrenSchema } from '../../prop-types'

const useStyles = makeStyles(() => ({
  container: ({ onClick }) => ({
    cursor: onClick ? 'pointer' : 'auto'
  })
}))

const getNumeralFormat = (number, format, decimals, digitsThreshold, useParenthesis = false) => {
  if (!format) return ''

  const numberFormatted = numeral(number).format(format)
  const [formattedNumberPrefix] = numberFormatted.split('.')
  const prefixWithOnlyNums = formattedNumberPrefix.replace(/\D/g, '')
  const numberLength = prefixWithOnlyNums.length

  const numberOfDecimals = new Array(decimals).fill('0').join('')
  let newFormat = format

  if (newFormat.indexOf('.') !== -1) {
    const [formatPrefix, formatSuffix] = newFormat.split('.')
    const suffix = decimals === 0 || numberLength >= digitsThreshold
      ? ''
      : `.${numberOfDecimals}`

    newFormat = `${formatPrefix}${suffix}${formatSuffix.replace(/0/g, '')}`
  }

  if (numberLength < digitsThreshold) {
    const lastIndexDigit = newFormat.lastIndexOf('0')
    newFormat = `${newFormat.slice(0, lastIndexDigit + 1)}.[0]${newFormat.slice(lastIndexDigit + 1)}`
  }

  if (useParenthesis && number < 0) return `(${newFormat})`

  return newFormat
}

const NumberFormat = ({
  title,
  number,
  format,
  skipFormat,
  decimals,
  children,
  digitsThreshold,
  onClick,
  useParenthesis,
  prefix
}) => {
  const classes = useStyles({ onClick })

  const handleClick = useCallback(() => onClick(format), [format, onClick])

  const numberFormatted = useMemo(() => {
    if (isNumber(number)) {
      if (skipFormat) return number
      return numeral(number).format(getNumeralFormat(number, format, decimals, digitsThreshold, useParenthesis))
    }
    if (isString(number)) {
      return number
    }
    return null
  }, [format, number, decimals, digitsThreshold, skipFormat, useParenthesis])

  return (
    <span
      {...(title ? { title: isNumber(title) ? title.toString() : title } : {})}
      className={classes.container}
      onClick={handleClick}
    >
      {prefix}
      {numberFormatted || children}
    </span>
  )
}

NumberFormat.propTypes = {
  title: PropTypes.string,
  skipFormat: PropTypes.bool,
  number: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  format: PropTypes.string,
  decimals: PropTypes.number,
  children: childrenSchema,
  digitsThreshold: PropTypes.number,
  onClick: PropTypes.func,
  useParenthesis: PropTypes.bool,
  prefix: PropTypes.string
}

NumberFormat.defaultProps = {
  title: null,
  skipFormat: false,
  number: undefined,
  format: '',
  decimals: 1,
  children: undefined,
  digitsThreshold: 3,
  onClick: noop,
  useParenthesis: false,
  prefix: null
}

export default NumberFormat
