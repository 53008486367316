import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useAdvisorHome } from '../AdvisorHomeContext'
import { useAvailableDates } from '../../../../redux/slices/appContext'
import { CALC_TYPES } from '../../../../constants'
import { useGetGroupedCoreData } from '../../../../api/coreData'
import Metric from './Metric'

const MarketValueMetric = ({ title, valueFormat, ...metricProps }) => {
  const { scope } = useAdvisorHome()
  const [availableDates] = useAvailableDates()

  const query = useMemo(
    () => {
      const scopeFilters = {}
      if (scope) {
        scopeFilters.advisorIds = scope.values
      }
      return {
        dateRange: {
          startDate: availableDates.min,
          endDate: availableDates.max
        },
        levelFilters: {
          levelTypes: ['user'],
          calcType: CALC_TYPES.balance,
          ...scopeFilters
        }
      }
    },
    [availableDates.max, availableDates.min, scope]
  )

  const { data, isLoading, error } = useGetGroupedCoreData(query)

  const value = useMemo(() => {
    if (error || isLoading) return null
    return data?.at(0)?.endingValue
  }, [error, isLoading, data])

  return (
    <Metric
      header={title}
      isLoading={isLoading}
      valueSize='3.5rem'
      value={value}
      valueFormat={valueFormat}
      {...metricProps}
    />
  )
}

MarketValueMetric.propTypes = {
  title: PropTypes.string,
  valueFormat: PropTypes.string
}

MarketValueMetric.defaultProps = {
  title: 'Market Value',
  valueFormat: 'human'
}

export default MarketValueMetric
