import React, { createContext, useCallback, useContext, useMemo, useState } from 'react'
import PropTypes from 'prop-types'

export const ClientDetailsFormContext = createContext({ })

export const useClientDetails = () => {
  return useContext(ClientDetailsFormContext)
}

export const useSectionEditing = (sectionName) => {
  const { editing, section } = useClientDetails()
  return useMemo(() => {
    return editing && section === sectionName
  }, [editing, section, sectionName])
}

const useClientDetailsValue = ({ client, refetch, deleteClient, defaultState }) => {
  const [formState, setFormState] = useState(defaultState)

  const editSection = useCallback(({
    section,
    saveDescription = 'Save',
    onSave
  }) => {
    setFormState((prevState) => ({
      ...prevState,
      editing: prevState.editing ? prevState.section !== section : true,
      section: prevState.editing ? prevState.section !== section ? section : null : section,
      saveDescription,
      onSave
    }))
  }, [setFormState])

  const cancelEdit = useCallback(() => {
    setFormState((prevState) => ({
      ...prevState,
      editing: false,
      section: null,
      saveDescription: null,
      onSave: null
    }))
  }, [setFormState])

  return useMemo(() => ({
    client,
    refetchClient: refetch,
    deleteClient,
    editSection,
    cancelEdit,
    ...formState
  }), [client, editSection, cancelEdit, formState, refetch, deleteClient])
}

export const ClientDetailsForm = ({ client, refetch, deleteClient, children, defaultState }) => {
  const value = useClientDetailsValue({
    client,
    refetch,
    defaultState,
    deleteClient
  })

  return (
    <ClientDetailsFormContext.Provider value={value}>
      {children}
    </ClientDetailsFormContext.Provider>
  )
}

ClientDetailsForm.propTypes = {
  defaultState: PropTypes.shape({
    editing: PropTypes.bool,
    section: PropTypes.string,
    saveDescription: PropTypes.string,
    onSave: PropTypes.func
  }),
  client: PropTypes.object,
  children: PropTypes.node,
  refetch: PropTypes.func,
  deleteClient: PropTypes.func
}

ClientDetailsForm.defaultProps = {
  defaultState: {
    editing: false,
    section: null,
    saveDescription: null,
    onSave: null
  }
}
