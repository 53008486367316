import React, { useEffect, useMemo, useState } from 'react'
import { makeStyles } from '@material-ui/core'
import { TEXT_VARIANTS } from '../../../../../constants'
import OperationalTable, { useOperationalTable } from '../../../../organisms/OperationalTable'
import Text from '../../../../atoms/Text'
import SnackAlert from '../../../../molecules/SnackAlert/SnackAlert'
import PostToVaultModal from '../PostToVault/PostToVaultModal'
import ReportPreviewModal from '../ReportPreview/ReportPreviewModal'
import ReportRunRefreshActions from '../ReportRunRefreshActions'
import { useListReportRuntimeConfigurationData, useListReportTemplatesData } from '../hooks'
import ReportSectionHeader from '../ReportSectionHeader'
import { reportStatusClasses } from '../classes'
import { useAppContext } from '../../../../../redux/slices/appContext'
import { useBatchRunsColumnsConfig, useListBatchRunsData } from './hooks'
import CreateBatchForm from './CreateBatchForm'

const useStyles = makeStyles(() => ({
  container: {
    padding: '1rem',
    display: 'flex',
    flex: '1',
    flexDirection: 'column'
  },
  statusContainer: {
    width: '6rem'
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '1rem 0 0'
  },
  tableHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  ...reportStatusClasses
}))

const BatchRunsView = () => {
  const { isSummitUser } = useAppContext()
  const classes = useStyles()
  const [previewUrl, setPreviewUrl] = useState('')
  const [postToVaultData, setPostToVaultData] = useState({})
  const [alert, setAlert] = useState({})

  // template Data
  const {
    data: templates,
    loading: isTemplatesLoading,
    templatesById,
    refetch: refetchTemplates,
    isStale: isTemplatesStale
  } = useListReportTemplatesData({
    offset: 0,
    limit: 1000,
    sort: ''
  })

  useEffect(() => {
    if (isTemplatesStale) {
      refetchTemplates()
    }
  }, [isTemplatesStale, refetchTemplates])

  const {
    data: runtimeConfigurations,
    loading: isRuntimeConfigurationsLoading,
    refetch: fetchRuntimeConfiguration,
    runtimeConfigurationById,
    isStale: isRuntimeConfigurationsStale
  } = useListReportRuntimeConfigurationData({
    offset: 0,
    limit: 1000,
    sort: ''
  })

  useEffect(() => {
    if (isRuntimeConfigurationsStale) {
      fetchRuntimeConfiguration()
    }
  }, [isRuntimeConfigurationsStale, fetchRuntimeConfiguration])

  const reportsColumnConfig = useBatchRunsColumnsConfig({
    classes,
    onSetPreviewUrl: (htmlUrl) => setPreviewUrl(htmlUrl),
    onSetPostToClientVault: ({ clientId, title, batchRunId }) => setPostToVaultData({ clientId, title, batchRunId })
  })

  const {
    pageIndex,
    pageSize,
    sort,
    onPagingChange,
    onSortingChange
  } = useOperationalTable(reportsColumnConfig)

  const options = useMemo(() => ({
    pageIndex,
    pageSize,
    sort,
    filterToUser: !isSummitUser
  }), [pageIndex, pageSize, sort, isSummitUser])

  const { data, loading, refetch } = useListBatchRunsData(options)

  const formattedData = useMemo(() => {
    if (!Array.isArray(data)) {
      return []
    }
    return data?.map((batchRun) => ({
      ...batchRun,
      templateName: templatesById[`${batchRun.batchTemplateId}`]?.name,
      runtimeConfigurationName: runtimeConfigurationById[`${batchRun.runtimeConfigurationId}`]?.name
    }))
  }, [data, runtimeConfigurationById, templatesById])

  const onPostToVaultSubmit = async (alert) => {
    if (alert) {
      setAlert(alert)
    }
    setPostToVaultData({})
    await refetch()
  }

  return (
    <div className={classes.container}>
      <ReportSectionHeader />
      <CreateBatchForm
        templates={templates}
        isTemplatesLoading={isTemplatesLoading}
        runtimeConfigurations={runtimeConfigurations}
        isRuntimeConfigurationsLoading={isRuntimeConfigurationsLoading}
        onSubmit={refetch}
        allowConfigurationSelection={isSummitUser}
        allowRawClientIdEntry
        allowManage={isSummitUser}
      />

      <div className={classes.tableHeader}>
        <Text text='Batch Runs' variant={TEXT_VARIANTS.h2} />
        <ReportRunRefreshActions intervalInSeconds={10} loading={loading} onRefetch={refetch} />
      </div>

      <PostToVaultModal
        data={postToVaultData}
        onSubmit={onPostToVaultSubmit}
        onClose={() => setPostToVaultData({})}
      />

      <ReportPreviewModal
        previewUrl={previewUrl}
        onSubmit={refetch}
        onClose={() => setPreviewUrl('')}
      />

      <OperationalTable.Wrapper>
        <OperationalTable
          mode='server'
          columns={reportsColumnConfig.columns}
          data={formattedData}
          defaultPageSize={100}
          defaultSort={reportsColumnConfig.defaultSort}
          itemName='Reports'
          loading={loading}
          total={data?.length || 0}
          onSortingChange={onSortingChange}
          onPagingChange={onPagingChange}
        />
      </OperationalTable.Wrapper>
      <SnackAlert alert={alert} />
    </div>
  )
}

export default BatchRunsView
