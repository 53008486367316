import React, { useCallback, useState, useEffect, useMemo } from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, Box } from '@material-ui/core'
import { AddCircle } from '@material-ui/icons'
import { useHistory, useParams } from 'react-router-dom'
import {
  Controller,
  useFieldArray,
  useForm,
  FormProvider
} from 'react-hook-form'
import Divider from '../../../atoms/Divider'
import RoundedButton from '../../../atoms/RoundedButton'
import {
  getFeeSchedule,
  createFeeSchedule,
  updateFeeSchedule
} from '../../../../service'
import { useCheckPolicy, useToggle } from '../../../../hooks'
import Select from '../../../atoms/Select'
import FormInputInlineEdit from '../../../molecules/FormInputInlineEdit'
import TableSkeleton from '../../../atoms/TableSkeleton'
import { useSetAppContext } from '../../../../redux/slices/appContext'
import { useFeeCalcTypes, useFeeMethods } from '../../../../api/billing'
import {
  FEE_SCHEDULE_TYPES_MINIMUM_FEE_VALUE,
  BUTTON_SIZES
} from '../../../../constants'
import { BILLING } from '../../../../policies/admin'
import FeeScheduleDetailsList from './FeeScheduleDetailsList'
import {
  getFeeDetailsFromLimits,
  getMinimumFeeFromDetails,
  getUnavailableFeeTypes,
  mapDefaultFeeScheduleDetails,
  mapFeeCalcTypeOptions,
  mapFeeMethodOptions,
  normalizeDetails
} from './helpers'

const useStyles = makeStyles((theme) => ({
  container: {
    marginLeft: 0,
    marginTop: 0,
    marginBottom: 0,
    paddingRight: '30px'
  },
  headerContent: {
    display: 'flex',
    flexDirection: 'row'
  },
  headerItem: {
    padding: '1rem'
  },
  leadingHeaderItem: {
    flex: '0 0 50%'
  },
  divider: {
    margin: `${theme.spacing(4)}px 0`
  },
  footerDivider: {
    margin: 0
  },
  title: {
    '& input': {
      fontSize: '34px',
      fontWeight: '325'
    },
    '& input[disabled]': {
      fontWeight: 'bolder'
    },
    '& .MuiFormControl-root': {
      marginLeft: '0'
    }
  },
  description: {
    fontSize: '24px',
    '& textarea': {
      fontSize: '24px',
      fontWeight: '325',
      height: 'inherit',
      lineHeight: '2rem'
    },
    '& textarea[disabled]': {
      fontWeight: 'bolder'
    },
    '& .MuiFormControl-root': {
      marginLeft: '0'
    }
  },
  subtitle: {
    fontSize: '14px',
    fontWeight: 'bolder'
  },
  smallText: {
    fontSize: '14px',
    marginBottom: '15px'
  },
  invisible: {
    visibility: 'hidden'
  },
  topTitle: {
    marginBottom: '35px',
    justifyContent: 'space-between'
  },
  rowTitle: {
    padding: '5px 0',
    fontSize: '24px'
  },
  onEditActionButtonsContainer: {
    textAlign: 'center'
  },
  onEditActionButtons: {
    padding: '4px 70px 2px'
  },
  addFeeButton: {
    borderRadius: '8px',
    border: 'unset',
    backgroundColor: '#EEF0F8',
    padding: '8px'
  },
  addFeeButtonIcon: {
    width: '18px',
    height: '18px',
    marginLeft: '10px',
    verticalAlign: 'sub'
  },
  feeMethodContainer: {
    border: '2px solid #E9EAEF',
    borderRadius: '6px',
    padding: '15px 20px',
    marginBottom: '12px',
    fontSize: '14px',
    '& .error-message': {
      color: '#d50000',
      fontSize: '11px',
      fontWeight: '100',
      paddingLeft: '22px',
      paddingTop: '6px'
    }
  },
  feeMethodDropdown: {
    border: '1px solid #E9EAEF',
    borderRadius: '36px',
    width: '100%',
    '& .MuiSelect-select': {
      padding: '6px 20px',
      borderRadius: '36px'
    },
    '& .MuiSvgIcon-root': {
      right: '10px',
      position: 'absolute',
      zIndex: -1
    }
  },
  feeMethodDropdownError: {
    borderColor: '#d50000',
    color: '#d50000',
    '& .MuiSelect-root': {
      color: '#d50000'
    },
    '& .MuiSvgIcon-root': {
      color: '#d50000'
    }
  }
}))

const defaultDetail = { calcType: null, limits: [] }

const FeeScheduleDetails = () => {
  const classes = useStyles()
  const history = useHistory()
  const canEditFeeSchedules = useCheckPolicy(BILLING.editFeeSchedules)
  const [loadData, setLoadData] = useState(true)
  const [displaySkeleton, setDisplaySkeleton] = useState(false)
  const [isNew, setIsNew] = useState(false)
  const [currentFee, setCurrentFee] = useState(null)
  const [feeDetails, setFeeDetails] = useState([])
  const [selectedFeeMethod, setSelectedFeeMethod] = useState(null)
  const [disabledOptions, setDisabledOptions] = useState([])
  const [isEditModeEnabled, toggleEditMode] = useToggle(false)
  const params = useParams()
  const feeScheduleId = useMemo(() => {
    if (params.id === 'new') return null
    return params.id
  }, [params])
  const setAppContext = useSetAppContext()

  const [feeSchedule, setFeeSchedule] = useState({
    longName: '',
    feeMethodId: null,
    description: '',
    clientCount: null,
    accountCount: null,
    details: [defaultDetail]
  })

  const methods = useForm({
    mode: 'all',
    defaultValues: {
      longName: '',
      feeMethodId: null,
      description: '',
      details: [defaultDetail]
    }
  })

  const {
    formState: { isDirty, isValid },
    control,
    reset,
    getValues,
    watch,
    setError,
    setFocus,
    clearErrors
  } = methods

  useEffect(() => {
    if (history.location?.pathname.includes('new') && !isEditModeEnabled) {
      toggleEditMode()
      setIsNew(true)
      setSelectedFeeMethod({
        label: '',
        value: null
      })
      setError('feeMethodId', {
        type: 'custom',
        message: 'Please add a Fee Method.'
      })
    }
  }, [history, toggleEditMode, isEditModeEnabled, setError])

  const { data: feeMethodOptions = [] } = useFeeMethods(mapFeeMethodOptions)
  const { data: feeCalcTypeOptions = [], isFetching: isFetchingFeeCalcTypeOptions } = useFeeCalcTypes(mapFeeCalcTypeOptions)

  const loadEditView = useCallback(
    async (setFeeSchedule) => {
      if (!feeScheduleId) return null
      try {
        const { data } = await getFeeSchedule(feeScheduleId)

        const feeSchedule = {
          ...data,
          details: mapDefaultFeeScheduleDetails(
            data,
            feeCalcTypeOptions
          )
        }

        const disableFeeScheduleTypes = feeSchedule.details.reduce(
          (acc, { calcType }) => [
            ...acc,
            ...getUnavailableFeeTypes(calcType, feeCalcTypeOptions)
          ],
          feeSchedule.details.map(({ calcType }) => calcType)
        )

        if (feeSchedule.minimumFee) {
          feeSchedule.details.push({
            calcType: FEE_SCHEDULE_TYPES_MINIMUM_FEE_VALUE,
            limits: [
              {
                lowerLimit: '0',
                upperLimit: null,
                feeRate: `${feeSchedule.minimumFee}`
              }
            ]
          })
          disableFeeScheduleTypes.push(FEE_SCHEDULE_TYPES_MINIMUM_FEE_VALUE)
        }

        setSelectedFeeMethod(
          feeMethodOptions.find(
            (feeMethod) => feeMethod.value === feeSchedule.feeMethodId
          )
        )
        setDisabledOptions([...new Set(disableFeeScheduleTypes)])

        setCurrentFee({
          details: feeSchedule.details,
          description: feeSchedule.description,
          longName: feeSchedule.longName,
          feeMethodId: feeSchedule.feeMethodId
        })

        setAppContext({
          breadCrumbAliases: { [feeScheduleId]: feeSchedule.longName }
        })

        const fees = getFeeDetailsFromLimits(feeSchedule.details)
        setFeeDetails(fees)

        reset({
          feeScheduleId,
          details: feeSchedule.details,
          description: feeSchedule.description,
          longName: feeSchedule.longName,
          feeMethodId: feeSchedule.feeMethodId
        })

        setFeeSchedule({ ...feeSchedule })
      } catch (error) {
        console.error('Edit FeeScheduleDetails', error)
        setFeeSchedule(error)
      }
    },
    [feeScheduleId, feeCalcTypeOptions, feeMethodOptions, setAppContext, reset]
  )

  const singleCallback = useCallback(
    async (safeSetState) => {
      if (loadData && !feeScheduleId) {
        setTimeout(() => {
          setFocus('longName')
        }, 0)
      }
      if (loadData && feeScheduleId) {
        await loadEditView(safeSetState)
      }
      if (loadData) {
        setLoadData(false)
      }
    },
    [loadData, feeScheduleId, loadEditView, setFocus]
  )

  const { remove, append, fields } = useFieldArray({
    name: 'details',
    control
  })

  useEffect(() => {
    const subscription = watch(({ details }, { name }) => {
      if (name?.startsWith('details')) {
        const fees = getFeeDetailsFromLimits(details)
        setFeeDetails(fees)
      }
    })
    return () => subscription.unsubscribe()
  }, [watch])

  const createFee = useCallback(
    async (e) => {
      e.preventDefault()
      setDisplaySkeleton(true)
      const payload = {
        ...getValues(),
        details: feeDetails
      }
      payload.minimumFee = getMinimumFeeFromDetails(payload.details)
      payload.details = normalizeDetails(payload.details, feeCalcTypeOptions)
      payload.shortName = payload.longName
      try {
        const { data: createdFee } = await createFeeSchedule(payload)
        setLoadData(true)
        return history.replace(
          `/admin/billing/fee-schedules/${createdFee.feeScheduleId}`
        )
      } catch (e) {
        console.error('createFee', e)
        setDisplaySkeleton(false)
      }
    },
    [history, getValues, feeDetails, feeCalcTypeOptions]
  )

  const updateFee = useCallback(
    async (e) => {
      e.preventDefault()
      setDisplaySkeleton(true)
      const payload = {
        ...getValues(),
        details: [...feeDetails]
      }
      payload.minimumFee = getMinimumFeeFromDetails(payload.details)
      payload.details = normalizeDetails(payload.details, feeCalcTypeOptions)
      payload.shortName = payload.longName
      const feeScheduleId = payload.feeScheduleId
      try {
        await updateFeeSchedule(feeScheduleId, payload)
        toggleEditMode()
        setDisplaySkeleton(false)
        setLoadData(true)
      } catch (e) {
        console.error('updateFee', e)
        setDisplaySkeleton(false)
      }
    },
    [getValues, feeDetails, toggleEditMode, feeCalcTypeOptions]
  )

  const onEdit = useCallback(() => {
    toggleEditMode()
  }, [toggleEditMode])

  const onCancel = useCallback(() => {
    toggleEditMode()
    if (isNew) {
      return history.replace('/admin/billing/fee-schedules')
    }
    reset(currentFee || feeSchedule)
  }, [toggleEditMode, isNew, currentFee, feeSchedule, history, reset])

  const addFee = useCallback(() => {
    return append(defaultDetail)
  }, [append])

  const onSelectFeeScheduleType = useCallback(
    (feeTypeValue) => {
      const disableFeeTypeValues = getUnavailableFeeTypes(
        feeTypeValue,
        feeCalcTypeOptions
      )
      setDisabledOptions((prevDisabledOptions) => {
        return [...prevDisabledOptions, ...disableFeeTypeValues, feeTypeValue]
      })
    },
    [feeCalcTypeOptions]
  )

  const onDeleteFeeDetail = useCallback(
    (idx, feeTypeValue) => {
      const hasMultipleLists = fields.length > 1
      if (hasMultipleLists) {
        remove(idx)
      }
      const disableFeeTypeValues = getUnavailableFeeTypes(
        feeTypeValue,
        feeCalcTypeOptions
      )
      setDisabledOptions((prevOptions) => {
        return prevOptions.filter(
          (value) =>
            value !== feeTypeValue && !disableFeeTypeValues.includes(value)
        )
      })
      return hasMultipleLists
    },
    [feeCalcTypeOptions, fields, remove]
  )

  const renderSkeleton = useCallback(() => {
    return (
      <Grid
        container
        spacing={4}
        style={{ padding: '100px 50px 100px 20px' }}
        className={classes.container}
      >
        <TableSkeleton />
      </Grid>
    )
  }, [classes.container])

  const feeDetailsValues = watch('details')

  const disableSaveButton = useMemo(() => {
    return (
      !isDirty ||
      !isValid ||
      feeDetails.length === 0 ||
      feeDetailsValues.some((d) => d.limits.length === 0) ||
      feeDetailsValues.filter(
        (d) => d.calcType !== FEE_SCHEDULE_TYPES_MINIMUM_FEE_VALUE
      ).length === 0
    )
  }, [isDirty, feeDetails, feeDetailsValues, isValid])

  const disableAddFeeDetailButton = useMemo(() => {
    const feeDetailValues = feeDetailsValues.map(({ calcType }) => calcType)
    const selectedFeeDetails = feeCalcTypeOptions.filter(({ value }) =>
      feeDetailValues.includes(value)
    )
    const singleTierFeeDetail = selectedFeeDetails.find(
      ({ isSingleTier, value }) =>
        isSingleTier && value !== FEE_SCHEDULE_TYPES_MINIMUM_FEE_VALUE
    )
    const multiTierFeeDetail = selectedFeeDetails.find(
      ({ isSingleTier }) => !isSingleTier
    )
    const isDefaultFeeDetailSelected = selectedFeeDetails.find(
      ({ value }) => value === FEE_SCHEDULE_TYPES_MINIMUM_FEE_VALUE
    )
    const areAllFeeTypesSelected =
      (singleTierFeeDetail || multiTierFeeDetail) && isDefaultFeeDetailSelected

    return (
      feeDetailsValues.some((d) => !d.calcType) ||
      areAllFeeTypesSelected ||
      disabledOptions.length === 0
    )
  }, [feeDetailsValues, feeCalcTypeOptions, disabledOptions.length])

  useEffect(() => {
    if (!isFetchingFeeCalcTypeOptions) {
      singleCallback(setFeeSchedule)
    }
  }, [feeSchedule.isLoaded, isFetchingFeeCalcTypeOptions, setFeeSchedule, singleCallback])

  return (
    <Box sx={{ flexGrow: 1, height: '100%' }}>
      {(loadData || displaySkeleton) && renderSkeleton()}
      {!loadData && !displaySkeleton && (
        <FormProvider {...methods}>
          <form
            onSubmit={isNew ? createFee : updateFee}
            style={{ height: '100%', position: 'relative' }}
          >
            <Box
              sx={{
                height: isEditModeEnabled ? '89.6%' : 'auto',
                overflow: 'hidden',
                overflowY: 'scroll'
              }}
            >
              <Grid container spacing={4} className={classes.container}>
                {/* TOP */}
                <Grid item xs={12}>
                  <Grid container spacing={10} className={classes.topTitle}>
                    <Grid item xs={4} className={classes.title}>
                      <div
                        className={clsx(
                          classes.smallText,
                          isEditModeEnabled ? null : classes.invisible
                        )}
                      >
                        Fee Schedule Name*
                      </div>
                      <FormInputInlineEdit
                        control={control}
                        name='longName'
                        width='100%'
                        rules={{ required: true }}
                        isInEditMode={isEditModeEnabled}
                      />
                    </Grid>
                    <Grid item>
                      {canEditFeeSchedules && !isNew && (
                        <RoundedButton
                          outlined
                          size={BUTTON_SIZES.small}
                          onClick={onEdit}
                          disabled={isEditModeEnabled}
                        >
                          Edit
                        </RoundedButton>
                      )}
                    </Grid>
                  </Grid>
                  <div className={classes.headerContent}>
                    <div
                      className={clsx(
                        classes.headerItem,
                        classes.leadingHeaderItem
                      )}
                    >
                      <div className={classes.smallText}>Description</div>
                      <div className={classes.description}>
                        <FormInputInlineEdit
                          multiline
                          width='100%'
                          name='description'
                          control={control}
                          rules={{ required: false }}
                          isInEditMode={isEditModeEnabled}
                        />
                      </div>
                    </div>
                    <div className={classes.headerItem}>
                      <div className={classes.smallText}>Total Clients</div>
                      <div className={classes.description}>
                        {feeSchedule.clientCount || 0}
                      </div>
                    </div>
                    <div className={classes.headerItem}>
                      <div className={classes.smallText}>Total Accounts</div>
                      <div className={classes.description}>
                        {feeSchedule.accountCount || 0}
                      </div>
                    </div>
                  </div>
                  <Divider additionalClasses={classes.divider} />
                </Grid>

                {/* Bottom */}
                <Grid container className={classes.container} spacing={4}>
                  <Grid item xs={12} md={6}>
                    <Grid container className={classes.rowTitle}>
                      Fee Details
                    </Grid>
                    {fields.map((detail, idx) => (
                      <Grid item key={detail.id}>
                        <FeeScheduleDetailsList
                          name={`details.${idx}`}
                          calcType={detail.calcType}
                          isInEditMode={isEditModeEnabled}
                          calcTypesOptions={feeCalcTypeOptions}
                          disabledOptions={disabledOptions}
                          onSelectFeeScheduleType={onSelectFeeScheduleType}
                          onDelete={(type) => onDeleteFeeDetail(idx, type)}
                        />
                      </Grid>
                    ))}
                    {isEditModeEnabled && (
                      <Grid item>
                        <RoundedButton
                          size={BUTTON_SIZES.small}
                          fullWidth
                          className={classes.addFeeButton}
                          disabled={disableAddFeeDetailButton}
                          onClick={addFee}
                        >
                          <span>Add Fee Detail</span>
                          <span>
                            <AddCircle className={classes.addFeeButtonIcon} />
                          </span>
                        </RoundedButton>
                      </Grid>
                    )}
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Grid container className={classes.rowTitle}>
                      <Grid item>Fee Method</Grid>
                    </Grid>
                    <Grid container className={classes.feeMethodContainer}>
                      <Grid item xs={6}>
                        {!isEditModeEnabled && selectedFeeMethod && (
                          <span>{selectedFeeMethod.label}</span>
                        )}
                        {isEditModeEnabled && selectedFeeMethod && (
                          <Controller
                            name='feeMethodId'
                            control={control}
                            selectedValue={selectedFeeMethod.value}
                            render={({
                              field: { onChange, onBlur },
                              fieldState: { error, isTouched }
                            }) => {
                              const showError = error && isTouched
                              return (
                                <>
                                  <Select
                                    className={clsx(classes.feeMethodDropdown, {
                                      [classes.feeMethodDropdownError]:
                                        showError
                                    })}
                                    onBlur={onBlur}
                                    defaultValue=''
                                    options={feeMethodOptions}
                                    displayEmpty
                                    selectedValue={selectedFeeMethod.value}
                                    onChange={(event) => {
                                      setSelectedFeeMethod(
                                        feeMethodOptions.find(
                                          (method) =>
                                            method.value === event.target.value
                                        )
                                      )
                                      onChange(event.target.value)
                                      clearErrors('feeMethodId')
                                    }}
                                    displayCheckMark
                                    placeholder='Select a Fee Method*'
                                  />
                                  {error?.message && showError && (
                                    <div className='error-message'>
                                      {error?.message}
                                    </div>
                                  )}
                                </>
                              )
                            }}
                            rules={{ required: true }}
                          />
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
            {isEditModeEnabled && (
              <Box
                sx={{
                  height: 92,
                  position: 'absolute',
                  bottom: 0,
                  width: '100%',
                  bgcolor: '#fff',
                  boxShadow: '0px -4px 18px rgba(36, 36, 44, 0.04);'
                }}
              >
                <Divider additionalClasses={classes.footerDivider} />
                <Grid
                  container
                  justifyContent='space-around'
                  spacing={4}
                  className={classes.container}
                  style={{ paddingTop: '14px' }}
                >
                  <Grid item md={2} />
                  <Grid item>
                    <RoundedButton
                      outlined
                      size={BUTTON_SIZES.small}
                      onClick={onCancel}
                      className={classes.onEditActionButtons}
                    >
                      Cancel
                    </RoundedButton>
                  </Grid>
                  <Grid item>
                    <RoundedButton
                      primary
                      size={BUTTON_SIZES.small}
                      type='submit'
                      className={classes.onEditActionButtons}
                      disabled={disableSaveButton}
                    >
                      Save
                    </RoundedButton>
                  </Grid>
                  <Grid item md={2} />
                </Grid>
              </Box>
            )}
          </form>
        </FormProvider>
      )}
    </Box>
  )
}

export default FeeScheduleDetails
