import { useMemo, createContext, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { isArray } from 'lodash'
import { parseUrlSearchParams } from '../../../../../utils/parseUrlSearchParams'
import { isJson } from '../../../../../utils'
import { useBookOfBusinessReport } from '../../../../../api/bobi'
import { getAdditionalData } from '../helpers'

export const useReportResult = ({
  columnConfig: _columnConfig
}) => {
  const history = useHistory()

  const urlSearchParams = useMemo(() => {
    return parseUrlSearchParams(history)
  }, [history])

  const queryParams = useParams()

  const reportParams = useMemo(
    () => {
      const filters = isJson(urlSearchParams?.filters)
        ? JSON.parse(urlSearchParams?.filters)
        : []
      return {
        filters,
        dataSets: urlSearchParams?.dataSets?.length ? urlSearchParams.dataSets[0].split(',') : [],
        exposureType: urlSearchParams?.exposureType?.[0] || 'asset',
        exposureTarget: urlSearchParams?.exposureTarget?.[0] || null,
        levelType: urlSearchParams?.levelType?.[0] || 'client',
        startDate: urlSearchParams?.startDate?.[0] || null,
        endDate: urlSearchParams?.endDate?.[0] || null,
        dateRangePreset: urlSearchParams?.dateRangePreset?.[0] || null,
        minDailyStartDate: urlSearchParams?.minDailyStartDate?.[0] || null,
        includeTags: urlSearchParams?.includeTags?.[0] || false,
        outputFilters: isJson(urlSearchParams?.outputFilters)
          ? JSON.parse(urlSearchParams?.outputFilters)
          : {},
        metadata: isJson(urlSearchParams?.metadata)
          ? JSON.parse(urlSearchParams?.metadata)
          : {}
      }
    },
    [urlSearchParams]
  )

  const normalizedRequest = useMemo(() => {
    const { filters: reportFilters } = reportParams

    const filters = !isArray(reportFilters)
      ? reportFilters
      : reportFilters.reduce((acc, { value, type }) => {
        if (value?.length && typeof value[0] === 'object') {
          value = value.map((v) => v.value)
        }
        acc[type] = value
        return acc
      }, {})

    if (!filters?.additionalData) {
      filters.additionalData = getAdditionalData(reportParams)
    }

    return {
      _raw: {
        ...reportParams,
        filters,
        rawFilters: reportFilters
      },
      asOfDate: reportParams.endDate,
      dataSets: reportParams.dataSets,
      filters,
      levelType: reportParams.levelType,
      relativeDateRange: reportParams.dateRangePreset,
      includeTags: reportParams.includeTags,
      metadata: reportParams.metadata
    }
  }, [reportParams])

  const shouldCheckExisting = (urlSearchParams?.shouldCheckExisting?.[0] ?? true) && !queryParams?.bobiOutputId

  const {
    data: bobiResult,
    isLoading: bobiResultLoading,
    error
  } = useBookOfBusinessReport(normalizedRequest, shouldCheckExisting)

  const result = {
    reportParams,
    bobiResult: {
      ...(bobiResult ?? {}),
      bobiOutputId: bobiResult?.bobiOutputId ?? queryParams?.bobiOutputId
    },
    bobiResultLoading,
    bobiResultError: error
  }

  return result
}

export const useReportContext = () => {
  return useContext(ReportContext)
}

export const ReportContext = createContext({
  reportParams: {},
  bobiResult: {},
  bobiResultLoading: false,
  bobiResultError: null
})
