import React, { useCallback, useImperativeHandle, useState } from 'react'
import PropTypes from 'prop-types'
import RoundedModal from '../../../../../molecules/RoundedModal'
import { useAccountFormContext } from '../../AccountFormProvider'
import TaxStatusForm from './TaxStatusForm'

const TaxStatusDialog = React.forwardRef(function TaxStatusDialog ({ taxStatusOptions, loading }, ref) {
  const [dialogState, setDialogState] = useState({ open: false, processing: false })
  const close = useCallback(() => {
    setDialogState({ open: false, processing: false })
  }, [setDialogState])

  useImperativeHandle(ref, () => ({
    open: (item) => setDialogState({ open: true, processing: false, parent: item })
  }), [setDialogState])

  const { account } = useAccountFormContext()

  return (
    <RoundedModal
      title='Assign Tax Status'
      open={dialogState.open}
      onClose={close}
      position='middle'
      disablePortal={false}
    >
      {loading ? (
        <div>Please wait</div>
      ) : (
        <TaxStatusForm account={account} taxStatusOptions={taxStatusOptions} onCancel={close} onComplete={close} />
      )}
    </RoundedModal>
  )
})

TaxStatusDialog.propTypes = {
  taxStatusOptions: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.number,
    label: PropTypes.string
  })),
  loading: PropTypes.bool
}

export default TaxStatusDialog
