import React, { useCallback, useMemo } from 'react'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { useTheme } from '@material-ui/core'
import { area } from 'd3-shape'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'

dayjs.extend(utc)
dayjs.extend(isSameOrAfter)
dayjs.extend(isSameOrBefore)

export const useAreaHighlightLayer = ({
  selectedYear,
  start,
  end,
  onSelect
}) => {
  const theme = useTheme()
  const years = useMemo(() => {
    const result = []
    for (let s = dayjs.utc(start).startOf('year'); s.isSameOrBefore(end, 'year'); s = s.add(1, 'year').startOf('year')) {
      result.push({
        year: s.year(),
        beginning: s.startOf('year'),
        end: s.add(1, 'year').startOf('year')
      })
    }
    return result
  }, [start, end])

  return useCallback(({ series, xScale, innerHeight }) => {
    const areaGenerator = (s, e) => area()
      .x(d => xScale(d.data.x))
      .y0(() => innerHeight)
      .y1(() => 0)
      .defined(d => {
        const isAfter = dayjs.utc(d.data.x).isSameOrAfter(s, 'month')
        const isBefore = dayjs.utc(d.data.x).isSameOrBefore(e, 'month')

        return isAfter && isBefore
      })

    const mergedSeries = series.reduce((p, c) => [...p, ...c.data], []).sort((a, b) => dayjs(a.x).diff(b.x))

    return (
      <g>
        {years.map(y => (
          <path
            key={y.year}
            onClick={() => onSelect(y.year)}
            style={{ cursor: 'pointer' }}
            d={areaGenerator(y.beginning, y.end)(mergedSeries)}
            fill={theme.palette.primary.main}
            fillOpacity={selectedYear === y.year ? 0.1 : 0}
          />
        ))}
      </g>
    )
  }, [years, selectedYear, theme, onSelect])
}
