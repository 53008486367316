
import PropTypes from 'prop-types'
import { CHART_LEGEND_ANCHORS, CHART_LEGEND_DIRECTIONS, CHART_LEGEND_ITEM_DIRECTIONS } from '../constants'

export const axisShape = {
  // Format of y axis values
  format: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  // x axis custom formats
  formats: PropTypes.object,
  // Title of the axis
  legend: PropTypes.string,
  // Legend offset from axis measured in pixels
  legendOffset: PropTypes.number,
  // Tick padding measured in pixels
  tickPadding: PropTypes.number,
  // Tick rotation measured in degrees
  tickRotation: PropTypes.number,
  // Tick size measured in pixels
  tickSize: PropTypes.number,
  tickValues: PropTypes.number
}

export const axisShapeDefaults = {
  format: undefined,
  legend: undefined,
  legendOffset: undefined,
  tickPadding: undefined,
  tickRotation: undefined,
  tickSize: undefined,
  tickValues: 5
}

export const chartRootShape = {
  // Margin of the entire chart measured in pixels
  margin: PropTypes.shape({
    top: PropTypes.number,
    bottom: PropTypes.number,
    left: PropTypes.number,
    right: PropTypes.number
  }),
  // The height of the LineChart containe
  height: PropTypes.string
}

export const chartRootShapeDefaults = {
  margin: { top: 50, right: 60, bottom: 50, left: 60 },
  height: undefined
}

export const legendType = {
  // Anchor of the legend
  anchor: PropTypes.oneOf(Object.values(CHART_LEGEND_ANCHORS)),
  // Legend direction
  direction: PropTypes.oneOf(Object.values(CHART_LEGEND_DIRECTIONS)),
  // Direction of the items
  itemDirection: PropTypes.oneOf(Object.values(CHART_LEGEND_ITEM_DIRECTIONS)),
  // Height of each item in the legend measured in pixels
  itemHeight: PropTypes.number.isRequired,
  // Spacing between each item of the legend measured in pixels
  itemSpacing: PropTypes.number,
  // Width of each item in the legend measured in pixels
  itemWidth: PropTypes.number.isRequired,
  // whether if items are justified according itemDirection or centered
  justify: PropTypes.bool,
  // Item symbol size measured in pixels
  symbolSize: PropTypes.number,
  // Translation of the legend in the X axis measured in pixels
  translateX: PropTypes.number,
  // Translation of the legend in the Y axis measured in pixels
  translateY: PropTypes.number
}

export const legendTypeDefaults = {
  anchor: undefined,
  direction: undefined,
  itemDirection: undefined,
  itemSpacing: undefined,
  justify: undefined,
  symbolSize: undefined,
  translateX: undefined,
  translateY: undefined
}

export const themeShape = {
  grid: PropTypes.shape({
    line: PropTypes.shape({
      strokeWidth: PropTypes.number
    })
  })
}
