import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import isEmpty from 'lodash/isEmpty'
import Text from '../atoms/Text'
import EmptySection from '../atoms/EmptySection'
import Avatar from '../atoms/Avatar'
import FamilyTreeNode from '../atoms/FamilyTreeNode'
import { useAppContext } from '../../redux/slices/appContext'
import { useFetchState } from '../../hooks'
import { fetchClient } from '../../service'
import Skeleton from '../atoms/Skeleton'
import { SKELETON_VARIANTS, SIZE_VARIANTS } from '../../constants'
import FamilyTree from './FamilyTree'

const nodesData = (lastName, isLoading) => [
  {
    id: 'ypu71w9_Q',
    gender: 'female',
    payload: {
      avatarUrl: 'carter-barnes.png',
      title: `Carter ${lastName || 'Smith'}`,
      subtitle: '26 yrs old',
      isLoading
    },
    parents: [
      {
        id: 'TsyAkbF89',
        type: 'blood'
      },
      {
        id: 'T54Km7uOC',
        type: 'blood'
      }
    ],
    children: [],
    siblings: [],
    spouses: []
  },
  {
    id: 'ypu71w9_Q1',
    gender: 'female',
    payload: {
      avatarUrl: 'jada-barnes.png',
      title: `Jada ${lastName || 'Smith'}`,
      subtitle: '24 yrs old',
      isLoading
    },
    parents: [
      {
        id: 'TsyAkbF89',
        type: 'blood'
      },
      {
        id: 'T54Km7uOC',
        type: 'blood'
      }
    ],
    children: [],
    siblings: [],
    spouses: []
  },
  {
    id: 'ypu71w9_Q2',
    gender: 'male',
    payload: {
      avatarUrl: 'jordan-barnes.png',
      title: `Jordan ${lastName || 'Smith'}`,
      subtitle: '21 yrs old',
      isLoading
    },
    parents: [
      {
        id: 'TsyAkbF89',
        type: 'blood'
      },
      {
        id: 'T54Km7uOC',
        type: 'blood'
      }
    ],
    children: [],
    siblings: [],
    spouses: []
  },
  {
    id: 'ypu71w9_Q3',
    gender: 'male',
    payload: {
      avatarUrl: 'max.png',
      title: 'Max',
      subtitle: '',
      isLoading
    },
    parents: [
      {
        id: 'TsyAkbF89',
        type: 'blood'
      },
      {
        id: 'T54Km7uOC',
        type: 'blood'
      }
    ],
    children: [],
    siblings: [],
    spouses: []
  },
  {
    id: 'T54Km7uOC',
    gender: 'male',
    payload: {
      avatarUrl: 'harrison-barnes.png',
      title: `Harrison ${lastName || 'Smith'}`,
      subtitle: '53 yrs old',
      isLoading
    },
    parents: [],
    children: [
      {
        id: 'ypu71w9_Q',
        type: 'blood'
      },
      {
        id: 'ypu71w9_Q1',
        type: 'blood'
      },
      {
        id: 'ypu71w9_Q2',
        type: 'blood'
      },
      {
        id: 'ypu71w9_Q3',
        type: 'blood'
      }
    ],
    siblings: [],
    spouses: [
      {
        id: 'TsyAkbF89',
        type: 'married'
      }
    ]
  },
  {
    id: 'TsyAkbF89',
    gender: 'female',
    payload: {
      avatarUrl: 'brittany-barnes.png',
      title: `Brittany ${lastName || 'Smith'}`,
      subtitle: '49 yrs old',
      isLoading
    },
    parents: [],
    children: [
      {
        id: 'ypu71w9_Q',
        type: 'blood'
      },
      {
        id: 'ypu71w9_Q1',
        type: 'blood'
      },
      {
        id: 'ypu71w9_Q2',
        type: 'blood'
      },
      {
        id: 'ypu71w9_Q3',
        type: 'blood'
      }
    ],
    siblings: [],
    spouses: [
      {
        id: 'T54Km7uOC',
        type: 'married'
      }
    ]
  }
]

const nodesParents = [
  {
    id: 'T54Km7uOC',
    gender: 'male',
    payload: {
      avatarUrl: 'harrison-barnes.png',
      title: 'Harrison'
    },
    parents: [],
    children: [],
    siblings: [],
    spouses: [
      {
        id: 'TsyAkbF89',
        type: 'married'
      }
    ]
  },
  {
    id: 'TsyAkbF89',
    gender: 'female',
    payload: {
      avatarUrl: 'brittany-barnes.png',
      title: 'Brittany'
    },
    parents: [],
    children: [],
    siblings: [],
    spouses: [
      {
        id: 'T54Km7uOC',
        type: 'married'
      }
    ]
  }
]

const useStyles = makeStyles(() => ({
  nodeContainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  avatar: {
    margin: '0 auto'
  },
  description: {
    marginTop: '0.25rem',
    textAlign: 'center'
  }
}))

const WIDTH = 120
const HEIGHT = 120
const HEIGHT_PARENTS_ONLY = 80

const HouseHoldFamilyTree = ({
  parentsOnly
}) => {
  const classes = useStyles()
  const { clientId } = useAppContext()

  const { client, loading } = useFetchState(useCallback(async (setSafeState) => {
    try {
      const { data: client } = await fetchClient(clientId)
      setSafeState({ client })
    } catch (err) {
      console.error(err)
      setSafeState({ error: err })
    }
  }, [clientId]))

  const nodes = useMemo(() => {
    const { familyName } = client || {}
    const familyNameArr = familyName?.split(/(\s+)/).filter(word => word.trim())
    const lastName = familyNameArr?.slice(0, familyNameArr.length - 1).join(' ') || ''
    return parentsOnly
      ? nodesParents
      : nodesData(lastName, loading)
  }, [parentsOnly, client, loading])

  const renderNodeContent = useCallback(
    ({ parents, payload: { avatarUrl, title, subtitle, isLoading } }) => {
      const avatarSize = parentsOnly ? SIZE_VARIANTS.medium : parents.length ? SIZE_VARIANTS.medium : SIZE_VARIANTS.large
      return (
        <div className={classes.nodeContainer}>
          <div className={classes.avatar}>
            <Avatar size={avatarSize} src={avatarUrl} avatarLetters={title} customSize='48px' />
          </div>
          <div className={classes.description}>
            {isLoading ? (
              <Skeleton height='1rem' width='100%' variant={SKELETON_VARIANTS.text} />
            ) : (
              <Text customFontSize='0.65rem' customFontWeight='bold' text={title} />
            )}
            <Text customFontSize='0.65rem' text={subtitle} />
          </div>
        </div>
      )
    }, [classes.avatar, classes.description, classes.nodeContainer, parentsOnly]
  )

  const renderNode = useCallback(
    (node) =>
      (
        <FamilyTreeNode
          key={node.id}
          node={node}
          style={{
            transform: `translate(calc(${node.left * (WIDTH / 2)}px + 20%), calc(${node.top * (parentsOnly ? HEIGHT_PARENTS_ONLY : HEIGHT / 2)}px + 15%))`
          }}
        >
          {renderNodeContent(node)}
        </FamilyTreeNode>
      ), [renderNodeContent, parentsOnly])

  if (isEmpty(nodes)) return <EmptySection />

  return (
    <FamilyTree
      nodes={nodes}
      width={WIDTH}
      height={parentsOnly ? HEIGHT_PARENTS_ONLY : HEIGHT}
      rootId={nodes[0].id}
      renderNode={renderNode}
    />
  )
}

HouseHoldFamilyTree.propTypes = {
  parentsOnly: PropTypes.bool
}

HouseHoldFamilyTree.defaultProps = {
  parentsOnly: false
}

export default HouseHoldFamilyTree
