import React from 'react'
import noop from 'lodash/noop'
import {
  IconButton,
  useTheme,
  makeStyles,
  Badge
} from '@material-ui/core'
import PropTypes from 'prop-types'
import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline'
import clsx from 'clsx'
import isNumber from 'lodash/isNumber'
import { useAppContext } from '../../redux/slices/appContext'
import { useFeatureFlag } from '../../redux/slices/appConfig'
import { FEATURE_FLAG } from '../../constants'

const useStyles = makeStyles((theme) => ({
  notificationBadgeIcon: {
    backgroundColor: theme.palette.success.A700,
    color: theme.palette.success.A700
  }
}))

const MessageBadge = ({ toggleNotesSideNav }) => {
  const { isAdvisor, notificationsCounter } = useAppContext()
  const classes = useStyles()
  const theme = useTheme()
  const { active: SHOW_ADD_COMMENT_FEATURE } = useFeatureFlag(FEATURE_FLAG.SHOW_ADD_COMMENT_FEATURE)

  if (!SHOW_ADD_COMMENT_FEATURE) return null

  return (
    <IconButton
      aria-label='notes board'
      aria-haspopup='true'
      onClick={toggleNotesSideNav}
    >
      <Badge
        variant='dot'
        overlap='rectangular'
        classes={{
          badge: clsx({
            [classes.notificationBadgeIcon]: isNumber(notificationsCounter) && notificationsCounter > 0 && !isAdvisor
          })
        }}
      >
        <ChatBubbleOutlineIcon sx={{ fontSize: 12 }} style={{ color: theme.palette.black }} />
      </Badge>
    </IconButton>
  )
}

MessageBadge.propTypes = {
  toggleNotesSideNav: PropTypes.func
}

MessageBadge.defaultProps = {
  toggleNotesSideNav: noop
}

export default MessageBadge
