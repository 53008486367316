import { useMemo } from 'react'
import { first, isEmpty } from 'lodash'
import { useSearchClients } from '../../../../api/clients'

const useClientInfo = (clientId, query = {}) => {
  const clientInfoQuery = useMemo(() => {
    return {
      query: {
        filters: {
          ...(query?.filters || {}),
          clientId: [{ value: clientId, op: 'eq' }]
        },
        includes: {
          ...(query?.includes || {}),
          profileJSON: true,
          integrations: true,
          groups: true
        }
      },
      queryOptions: {
        mapper: ({ data }) => (!isEmpty(data) ? first(data) : null),
        enabled: Boolean(clientId)
      }
    }
  }, [clientId, query])

  const { data: client, isLoading } = useSearchClients(
    clientInfoQuery.query,
    clientInfoQuery.queryOptions
  )

  return { client, isLoading }
}

export default useClientInfo
