import { makeStyles } from '@material-ui/core'

export const mappingOptions = [
  { label: 'Not Set', value: '' },
  { label: 'Constant', value: 'constant' },
  { label: 'Direct', value: 'direct' },
  { label: 'Expression', value: 'expression' },
  { label: 'Translation', value: 'translation' }
]

export const sourceOptions = [
  { label: 'Not Set', value: '' },
  { label: 'Constant', value: 'constant' },
  { label: 'Key Datapoint', value: 'kdp' },
  { label: 'Group Membership', value: 'gm' }
]

export const useDialogStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    fontWeight: theme.typography.weights.light,
    '& strong': {
      fontWeight: theme.typography.weights.bold
    }
  },
  group: {
    display: 'flex',
    flexDirection: 'row',
    gap: '10px',
    width: '100%',
    '& > *': {
      flex: '1 0 auto'
    }
  },
  textarea: {
    width: '100%',
    marginBottom: '10px',
    resize: 'none',
    height: '300px'
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  list: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: theme.layout.padding.thick,
    paddingBottom: theme.layout.padding.thick
  },
  tableItem: {
    '&:hover': {
      backgroundColor: theme.palette.gray.dark
    }
  },
  listItem: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.layout.padding.dense,
    borderRadius: theme.layout.radius.round,
    transition: 'background-color .2s ease-in-out',
    '&:hover': {
    }
  },
  formtings: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.layout.gap.loose,
    '& > *:first-child': {
      flex: '1 0 auto'
    }
  },
  formTable: {
    borderCollapse: 'collapse',
    '& tr th': {
      textAlign: 'left'
    },
    '& tr td': {
      transition: 'background-color .2s ease-in-out',
      padding: theme.layout.padding.dense
    },
    '& tr:hover td': {
      backgroundColor: theme.palette.gray.dark
    },
    '& tr td:last-child': {
      textAlign: 'right'
    }
  },
  info: {
    fontSize: theme.typography.fontSizes.xl,
    fontWeight: theme.typography.weights.bold,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: theme.layout.margin.thick,
    borderBottom: `1px solid ${theme.palette.gray.dark}`
  }
}))

export function getNumberOrDefault (str) {
  const result = Number.parseInt(str)
  if (isNaN(result)) return 0

  return result || 0
}

export const componentModelItem = (x, value) => ({
  ...x,
  levelTypeId: 2000,
  levelId: x.componentModelId,
  name: x.name,
  identifier: null,
  levelType: 'Component Model',
  weight: getNumberOrDefault(value)
})

export const assetItem = (x, value) => ({
  ...x,
  levelTypeId: 3,
  levelId: x.assetId,
  name: x.assetName,
  identifier: x.assetIdentifier,
  levelType: 'Asset',
  weight: getNumberOrDefault(value)
})

export const tagItem = (x, value) => ({
  ...x,
  levelTypeId: 6,
  levelId: x.classificationTagId,
  name: x.longName,
  identifier: x.classificationTagTypeLongName,
  levelType: 'Classification',
  weight: getNumberOrDefault(value)
})

export const modelItem = (x, value) => ({
  ...x,
  weight: getNumberOrDefault(value)
})
