import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { makeStyles, Tooltip } from '@material-ui/core'
import Icon from '../../atoms/Icon'
import { ICON_NAMES } from '../../../constants'

const useStyles = makeStyles(() => ({
  viewToggleContainer: {
    display: 'flex',
    gap: '1rem'
  },
  button: {
    opacity: 0.5,
    cursor: 'pointer',
    '&.active': {
      opacity: 1
    }
  }
}))

const mapOptions = (options) => {
  const iconMap = new Map([
    ['visual-balance-view', ICON_NAMES.vbsTreeView],
    ['split-view', ICON_NAMES.vbsListView]
  ])

  return options.map((option) => {
    const value = option.replace(/\s+/g, '-').toLowerCase()
    return {
      value: value,
      label: option,
      icon: iconMap.get(value) ?? ICON_NAMES.vbsTreeView
    }
  })
}

const ViewToggles = ({ options: _options, selectedValue, onChange }) => {
  const classes = useStyles()
  const options = mapOptions(_options)

  useEffect(() => {
    if (!options.some(row => row.value === selectedValue)) {
      onChange(options[0]?.value ?? 'visual-balance-view')
    }
  }, [onChange, selectedValue, options])

  return (
    <div className={classes.viewToggleContainer}>
      {options.map(option =>
        <Tooltip
          key={'tooltip-' + option.value}
          title={option.label}
          arrow
          placement='bottom'
        >
          <span>
            <button
              className={`${classes.button} ${option.value === selectedValue ? 'active' : ''}`}
              key={option.value}
              type='button'
              onClick={
                () => onChange(option.value)
              }
            >
              <Icon
                key={option.value}
                name={option.icon}
                customSize='2rem'
              />
            </button>
          </span>
        </Tooltip>
      )}
    </div>
  )
}

ViewToggles.propTypes = {
  options: PropTypes.arrayOf(PropTypes.string),
  selectedValue: PropTypes.string,
  onChange: PropTypes.func
}

export default ViewToggles
