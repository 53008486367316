import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { makeStyles, IconButton } from '@material-ui/core'
import Icon from '../../components/atoms/Icon'
import { CONFIRMATION_TYPE, ICON_NAMES } from '../../constants'
import Tooltip from '../../components/atoms/Tooltip'
import ConfirmationModal from '../../components/molecules/ConfirmationModal'
import { useBoolean } from '../../hooks'
import { useFilterSubscriptionContext } from './FilterSubscriptionProvider'

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: '1rem',
    padding: '1rem 0'
  },
  resetButton: {
    padding: '0.25rem 0.5rem'
  }
}))

const MODAL_BACKDROP_STYLES = {
  borderRadius: '0.15rem'
}

const FilterSubscriptionContainer = ({
  children,
  showResetButton,
  resetButtonTooltipTitle,
  resetModalConfirmationText
}) => {
  const classes = useStyles()
  const [modalOpen, toggleModalOpen] = useBoolean()
  const { resetFilters } = useFilterSubscriptionContext()

  const onConfirmFiltersReset = useCallback(() => {
    resetFilters()
    toggleModalOpen.off()
  }, [resetFilters, toggleModalOpen])

  return (
    <div className={classes.container}>
      {children}
      {showResetButton && (
        <>
          <Tooltip title={resetButtonTooltipTitle}>
            <IconButton
              className={classes.resetButton}
              onClick={toggleModalOpen.on}
            >
              <Icon name={ICON_NAMES.sync} customSize='1.25rem' />
            </IconButton>
          </Tooltip>
          <ConfirmationModal
            width='25rem'
            open={modalOpen}
            container={null}
            buttonSize='small'
            buttonLabelCancel='Cancel'
            buttonLabelConfirm='Confirm'
            onCancel={toggleModalOpen.off}
            onConfirm={onConfirmFiltersReset}
            backdropStyles={MODAL_BACKDROP_STYLES}
            confirmationType={CONFIRMATION_TYPE.Primary}
            confirmationText={resetModalConfirmationText}
          />
        </>
      )}
    </div>
  )
}

FilterSubscriptionContainer.propTypes = {
  children: PropTypes.node,
  showResetButton: PropTypes.bool,
  resetButtonTooltipTitle: PropTypes.string,
  resetModalConfirmationText: PropTypes.string
}

FilterSubscriptionContainer.defaultProps = {
  showResetButton: true,
  resetButtonTooltipTitle: 'Reset filters',
  resetModalConfirmationText: 'Are you sure you want to clear all filter settings?'
}

export default FilterSubscriptionContainer
