export const reportStatusClasses = {
  status: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '3px 12px 0px',
    gap: '10px',
    width: 'auto',
    maxWidth: '350px',
    height: '26px',
    background: '#E8F0FD',
    border: '1px solid #DBE8FF',
    borderRadius: '8px'
  },
  inProgress: {
    background: '#E8F0FD'
  },
  success: {
    color: '#368759',
    backgroundColor: '#F0F9F4',
    border: '1px solid #DAF0E3'
  },
  warning: {
    color: '#E57373',
    backgroundColor: '#FAEAEA',
    border: '1px solid #E57373'
  },
  shared: {
    color: '#AAAAAA',
    backgroundColor: '#EEEEEE',
    border: '1px solid #AAAAAA'
  }
}
