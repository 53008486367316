import React, { useCallback, useMemo, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import clsx from 'clsx'
import { darken } from '@material-ui/core/styles'
import { lighten } from '@material-ui/core'
import Collapse from '@material-ui/core/Collapse'
import theme from '../../../theme'
import { TITLE_VBS_NUMBER_FORMAT } from '../../../constants'
import { useFormattingContext } from '../FormattingProvider/FormattingContext'

const useStyles = makeStyles((theme) => ({
  sectionedBarContainer: {
    width: '100%',
    display: 'block'
  },
  sectionedBarInner: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    height: '150px',
    borderRadius: '8px',
    overflow: 'hidden'
  },
  sectionBarOtherInner: ({ dividerColor }) => ({
    borderRadius: 0,
    borderTop: `3px solid ${dividerColor}`,
    '&:first-child': {
      borderTopLeftRadius: '8px',
      borderTopRightRadius: '8px',
      borderTop: 'none'
    },
    '&:last-child': {
      borderBottomLeftRadius: '8px',
      borderBottomRightRadius: '8px'
    }
  }),
  otherSectionContainer: {
    position: 'relative',
    display: 'flex',
    flexWrap: 'nowrap',
    padding: '16px',
    borderWidth: '3px',
    borderStyle: 'solid',
    borderRadius: '8px',
    margin: '24px 0 0',
    justifyContent: 'space-between',
    flexDirection: 'column',
    '& >:first-child': {
      borderTop: 'none'
    }
  },
  sectionedBarBlock: ({ dividerColor }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'end',
    alignItems: 'start',
    padding: '0 10px',
    height: '100%',
    cursor: 'pointer',
    borderRight: `3px solid ${dividerColor}`,
    fontSize: '16px',
    minWidth: '150px',
    flexGrow: 1,
    '&:last-child': {
      border: 'none'
    }
  }),
  sectionedBarOtherBlock: {
    fontSize: '12px !important'
  },
  sectionedBarBlockContent: {
    padding: '.5rem',
    width: '100%',
    margin: '0 0 .5rem',
    borderRadius: '8px'
  },
  selectedStripedBarBlockContent: {
    background: 'rgba(255,255,255,.7)'
  },
  sectionedBarTitle: {
    fontSize: '1.75em'
  },
  sectionedBarSubtitle: {
    fontSize: '1em',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '100%',
    whiteSpace: 'nowrap'
  },
  arrowIcon: {
    position: 'absolute',
    bottom: '100%',
    width: '30px',
    height: '24px',
    borderLeft: '23px solid transparent',
    borderRight: '23px solid transparent',
    borderBottom: `23px solid ${theme.palette.summitBlue}`
  },
  innerArrowIcon: {
    left: '-23px',
    top: '5px',
    position: 'relative',
    borderLeft: '23px solid transparent',
    borderRight: '23px solid transparent',
    borderBottom: `23px solid ${theme.palette.summitBlue}`
  }
}))

const SectionedBarBlock = ({
  title,
  subTitle,
  backgroundColor: _backgroundColor = theme.palette.summitBlue,
  isSelected = false,
  onClick = noop,
  ratio,
  isOtherBlock = false,
  isStriped = false
}) => {
  const classes = useStyles({ dividerColor: darken(_backgroundColor, 0.45) })
  const width = Math.min(+ratio * 100, 100).toFixed(2)
  const backgroundColorRaw = useMemo(() => (
    isSelected
      ? darken(_backgroundColor, 0.45)
      : _backgroundColor
  ), [isSelected, _backgroundColor])
  const backgroundColor = useMemo(() => {
    if (isStriped) {
      const stripeColor = lighten(backgroundColorRaw, 0.8)
      return `repeating-linear-gradient(45deg, ${backgroundColorRaw}, ${backgroundColorRaw} 10px, ${stripeColor} 10px, ${stripeColor} 20px)`
    }
    return backgroundColorRaw
  }, [isStriped, backgroundColorRaw])

  const textColor = useMemo(() => {
    return isSelected && isStriped ? '#000' : theme.palette.getContrastText(backgroundColorRaw)
  }, [backgroundColorRaw, isSelected, isStriped])

  return (
    <div
      onClick={onClick}
      className={clsx(classes.sectionedBarBlock, isOtherBlock && classes.sectionedBarOtherBlock)}
      style={{
        width: `${width}%`,
        background: backgroundColor,
        color: textColor
      }}
    >
      <div className={clsx(classes.sectionedBarBlockContent, isStriped && isSelected && classes.selectedStripedBarBlockContent)}>
        <div className={classes.sectionedBarTitle}>
          {title}
        </div>
        <div className={classes.sectionedBarSubtitle}>
          {subTitle?.length && subTitle}
        </div>
      </div>
    </div>
  )
}

SectionedBarBlock.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  backgroundColor: PropTypes.string,
  onClick: PropTypes.func,
  ratio: PropTypes.number,
  isSelected: PropTypes.bool,
  isOtherBlock: Promise.bool,
  isStriped: PropTypes.bool
}

const SectionedBar = ({
  onClick,
  backgroundColor = theme.palette.summitBlue,
  selectedBlockId,
  blocks,
  useStripedBackground,
  maxBlocksPerGroup = 5,
  sizeKey = 'endingValue',
  titleKey = 'endingValue',
  selectKey = 'accountId',
  titleFormat = TITLE_VBS_NUMBER_FORMAT,
  subTitleKey = 'accountName',
  subTitleFormat = null,
  otherLabel = 'Other'
}) => {
  const classes = useStyles({ dividerColor: darken(backgroundColor, 0.45) })
  const { formatter } = useFormattingContext()

  const [showOtherGroups, setShowOtherGroups] = useState(false)

  const handleOnClick = useCallback((block, isOtherBlock) => {
    if (block[selectKey] === 'other') {
      onClick(null)
      setShowOtherGroups(!showOtherGroups)
      return
    }
    if (!isOtherBlock) {
      setShowOtherGroups(false)
    }
    onClick(block)
  }, [onClick, selectKey, showOtherGroups])

  const normalizedBlocks = useMemo(() => {
    const total = blocks.reduce((acc, block) => (acc + block[sizeKey]), 0)
    return blocks
      .map(block => ({
        ...block,
        title: titleFormat ? formatter(block[titleKey], titleFormat) : block[titleKey],
        subTitle: (subTitleFormat ? formatter(block[subTitleKey], subTitleFormat) : block[subTitleKey]) ?? null,
        backgroundColor: (block.colorField ?? backgroundColor),
        isStriped: useStripedBackground,
        ratio: (block[sizeKey] / total)
      }))
  }, [blocks, sizeKey, titleFormat, formatter, titleKey, subTitleFormat, subTitleKey, useStripedBackground, backgroundColor])

  const otherBlocks = useMemo(() => normalizedBlocks.slice(maxBlocksPerGroup), [normalizedBlocks, maxBlocksPerGroup])
  const primaryBlocks = useMemo(() => normalizedBlocks.slice(0, maxBlocksPerGroup + 1), [normalizedBlocks, maxBlocksPerGroup])

  const otherToggleBlock = useMemo(() => {
    const otherBlockTotal = otherBlocks.reduce((acc, block) => (acc + block[sizeKey]), 0)
    const fullTotal = primaryBlocks.reduce((acc, block) => (acc + block[sizeKey]), 0)
    if (otherBlocks.length === 1) {
      return otherBlocks[0]
    }
    if (!otherBlocks.length) {
      return null
    }

    return {
      [selectKey]: 'other',
      title: titleFormat ? formatter(otherBlockTotal, titleFormat) : otherBlockTotal,
      subTitle: otherLabel,
      backgroundColor,
      ratio: (Math.abs(otherBlockTotal) / fullTotal),
      total: otherBlockTotal
    }
  }, [backgroundColor, formatter, otherBlocks, primaryBlocks, selectKey, sizeKey, titleFormat, otherLabel])

  const getOtherBlockGroups = useCallback((otherBlocks) => {
    if (!otherBlocks.length) {
      return []
    }

    const groupedBlocks = otherBlocks.reduce((acc, block) => {
      const lastGroup = acc[acc.length - 1]
      if (!lastGroup || lastGroup.length > maxBlocksPerGroup) {
        acc.push([block])
      } else {
        lastGroup.push(block)
      }
      return acc
    }, [])

    return groupedBlocks.map(group => {
      const total = group.reduce((acc, block) => (acc + Math.abs(block[sizeKey])), 0)
      return group.map(block => ({
        ...block,
        ratio: Math.abs(block[sizeKey]) / total
      }))
    })
  }, [sizeKey, maxBlocksPerGroup])

  const blockGroups = useMemo(() => {
    return {
      display: [
        ...primaryBlocks,
        ...(otherToggleBlock ? [otherToggleBlock] : [])
      ],
      other: getOtherBlockGroups(otherBlocks)
    }
  }, [getOtherBlockGroups, otherBlocks, otherToggleBlock, primaryBlocks])

  return (
    <div>
      <div className={classes.sectionedBarContainer}>
        <div className={classes.sectionedBarInner}>
          {blockGroups.display?.map(block => {
            return (
              <SectionedBarBlock
                key={block[selectKey]}
                title={block.title}
                subTitle={block.subTitle}
                backgroundColor={block.backgroundColor}
                isStriped={block.isStriped}
                onClick={() => handleOnClick(block)}
                isSelected={selectedBlockId === block[selectKey] || (block[selectKey] === 'other' && showOtherGroups)}
                ratio={block.ratio}
              />
            )
          })}
        </div>
      </div>
      {blockGroups.other?.length > 0 && (
        <Collapse in={showOtherGroups}>
          <div
            className={classes.otherSectionContainer}
            style={{
              borderColor: backgroundColor,
              fontSize: '12px',
              backgroundColor: lighten(backgroundColor, 0.7)
            }}
          >
            <div>
              {blockGroups.other.map((group, index) => (
                <div
                  key={index}
                  className={clsx(classes.sectionedBarInner, classes.sectionBarOtherInner)}
                  style={{ height: 'auto' }}
                >
                  {group.map(block => (
                    <SectionedBarBlock
                      key={block[selectKey]}
                      title={block.title}
                      subTitle={block.subTitle}
                      backgroundColor={block.backgroundColor}
                      isStriped={block.isStriped}
                      onClick={() => handleOnClick(block, true)}
                      isSelected={selectedBlockId === block[selectKey]}
                      ratio={block.ratio}
                      isOtherBlock
                    />
                  ))}
                </div>
              ))}
            </div>
            <div
              className={classes.arrowIcon}
              style={{ right: '50px', borderBottomColor: backgroundColor }}
            >
              <div className={classes.innerArrowIcon} style={{ borderBottomColor: lighten(backgroundColor, 0.7) }} />
            </div>
          </div>
        </Collapse>
      )}
    </div>
  )
}

SectionedBar.propTypes = {
  onClick: PropTypes.func,
  backgroundColor: PropTypes.string,
  selectedBlockId: PropTypes.string,
  blocks: PropTypes.array,
  useStripedBackground: PropTypes.bool,
  maxBlocksPerGroup: PropTypes.number,
  sizeKey: PropTypes.string,
  titleKey: PropTypes.string,
  selectKey: PropTypes.string,
  titleFormat: PropTypes.string,
  subTitleKey: PropTypes.string,
  subTitleFormat: PropTypes.string,
  otherLabel: PropTypes.string
}

export default SectionedBar
