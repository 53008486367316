import React, { useCallback, useMemo, useState } from 'react'
import { makeStyles } from '@material-ui/core'
import { useDates } from '../../../../../hooks/useDates'
import GroupingProvider from '../../../../organisms/GroupingProvider'
import AvailableDatePicker from '../../../../molecules/AvailableDatePicker'
import GroupingOptions from '../../../../organisms/GroupingProvider/GroupingOptions'
import { useAccountStatusTotals } from '../../hooks/accountStatus'
import AccountStatusTable from './AccountStatusTable'
import { filterOptions } from './filterOptions'
import AccountStatusHeader from './Header'

import { accountStatusPropTypes } from './AccountStatusProps'

const useStyles = makeStyles((theme) => ({
  actions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  status: {
    marginBottom: '0.5rem'
  },
  dateControls: {
    display: 'flex',
    alignItems: 'flex-end',
    marginTop: '1.5rem'
  },
  tableHeader: {
    zIndex: 1
  }
}))

const useDetailsPage = (dataSourceIds) => {
  const [selectedFilter, setSelectedFilter] = useState(filterOptions.stale)
  const onFilterSelected = useCallback((v) => setSelectedFilter(v), [setSelectedFilter])

  const { result } = useAccountStatusTotals(dataSourceIds)

  const headerProps = useMemo(() => ({
    selectedFilter,
    onFilterSelected,
    totals: result
  }), [selectedFilter, onFilterSelected, result])
  return {
    headerProps,
    total: result ? result[selectedFilter.key]?.total ?? null : null
  }
}

const AccountStatusTableContainer = ({ dataSourceIds, columnsConfig, format }) => {
  const classes = useStyles()
  const [searchText, setSearchText] = useState('')

  const detailsData = useDetailsPage(dataSourceIds)
  const { headerProps } = detailsData

  const { availableDates } = useDates()

  const onSearchTextChange = useCallback(
    (value) => {
      setSearchText(value)
    },
    [setSearchText]
  )

  return (
    <>
      <GroupingProvider>
        <AccountStatusTable.Wrapper className={classes.table}>
          <AccountStatusTable.SuperHeader className={classes.tableHeader}>
            <div className={classes.dateControls}>
              <AvailableDatePicker
                value={availableDates.max}
                format='[As of] MMMM DD, YYYY'
                type='date'
                extraStyles={{
                  fontSize: '0.875rem',
                  cursor: 'pointer',
                  display: 'block'
                }}
                disableCalendar
              />
            </div>
            <AccountStatusHeader className={classes.status} {...headerProps} />
            <div className={classes.actions}>
              <AccountStatusTable.Search
                onChange={onSearchTextChange}
                placeholder='Search account ID, name or number'
                delay={1000}
              />
              <GroupingOptions
                hideExpandCollapseButton
                hideCustomizeColumnsDateRangeSelectors
                hideCustomizeColumnsColumnHiding
                hideCustomizeColumnsButton
              />
            </div>
          </AccountStatusTable.SuperHeader>
          <AccountStatusTable
            dataSourceIds={dataSourceIds}
            detailsData={detailsData}
            searchText={searchText}
            columnsConfig={columnsConfig}
            format={format}
          />
        </AccountStatusTable.Wrapper>
      </GroupingProvider>
    </>
  )
}

AccountStatusTableContainer.propTypes = accountStatusPropTypes

export default AccountStatusTableContainer
