import { makeStyles, MenuItem, TextField } from '@material-ui/core'
import React from 'react'
import PropTypes from 'prop-types'
import Icon from '../../../atoms/Icon'
import { ICON_NAMES, TEXT_VARIANTS } from '../../../../constants'
import Text from '../../../atoms/Text'

const useStyles = makeStyles(() => ({
  root: {
    paddingBottom: 0
  },
  icon: {
    position: 'absolute',
    right: 0,
    top: '50%',
    pointerEvents: 'none',
    margin: '-0.5rem 0 0'
  }
}))

const QuickEditSelect = ({
  value,
  options,
  onChange,
  disabled = false
}) => {
  const classes = useStyles()

  return (
    <TextField
      select
      value={value}
      onChange={onChange}
      SelectProps={{
        MenuProps: { disablePortal: true },
        classes: { root: classes.root },
        IconComponent: () => (
          <Icon
            name={ICON_NAMES.chevronDown}
            variant={TEXT_VARIANTS.body1}
            additionalClasses={classes.icon}
          />
        ),
        disableUnderline: true
      }}
      disabled={disabled}
    >
      {options.map(({ value, label }) => (
        <MenuItem key={value} value={value}>
          <Text variant={TEXT_VARIANTS.body1}>{label}</Text>
        </MenuItem>
      ))}
    </TextField>
  )
}

QuickEditSelect.propTypes = {
  value: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired
  })).isRequired,
  onChange: PropTypes.func,
  disabled: PropTypes.bool
}

export default QuickEditSelect
