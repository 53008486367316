const baseInputStyles = (theme) => ({
  backgroundColor: 'transparent',
  fontFamily: theme.typography.fontFamily,
  fontWeight: 400,
  width: '100%'
})

const sizes = {
  sm: (theme) => ({
    padding: '2px 10px 2px 10px',
    minHeight: '32px',
    ...baseInputStyles(theme),
    lineHeight: '32px',
    fontSize: theme.typography.fontSizes.lg
  }),
  md: (theme) => ({
    padding: '2px 10px 2px 10px',
    minHeight: '32px',
    ...baseInputStyles(theme),
    lineHeight: '32px',
    fontSize: theme.typography.fontSizes.xl
  }),
  lg: (theme) => ({
    padding: '9px 10px 9px 15px',
    minHeight: '54px',
    ...baseInputStyles(theme),
    lineHeight: '32px',
    fontSize: theme.typography.fontSizes.xxl
  })
}

export const mapInputStyles = (theme, rest) => ({ disabled, size, minWidth }) => ({
  ...rest,
  ...sizes[size](theme),
  backgroundColor: disabled ? theme.palette.gray.lighter : theme.palette.white,
  ...(minWidth && { minWidth })
})
