import PropTypes from 'prop-types'
import { useAppContext } from '../../redux/slices/appContext'

const SummitOnlyContent = ({ children }) => {
  const { isSummitUser } = useAppContext()

  if (isSummitUser) return typeof children === 'function' ? children() : children

  return null
}

SummitOnlyContent.propTypes = {
  children: PropTypes.oneOfType([PropTypes.func.isRequired, PropTypes.node])
}

export default SummitOnlyContent
