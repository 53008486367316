import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import OperationalTable from '../../../organisms/OperationalTable'
import { useContextContainer } from '../../../../abundanceEngine/components/ContextContainer'
import { CLIENT_VIEW_CONTEXT } from '..'
import ClientSearchBar from '../components/ClientSearchBar'
import ClientViewTypeToggle from '../components/ClientViewTypeToggle'
import ClientGroupTagFilters from '../components/ClientGroupTagFilters'
import ClientsTable from './ClientsTable'

const useStyles = makeStyles((theme) => ({
  tableHeader: {
    zIndex: 1,
    backgroundColor: theme.palette.white
  },
  headerContainer: {
    padding: '1rem 0'
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: '1rem',
    '& .__search-input': {
      marginBottom: '1rem'
    },
    '& .__view-type-toggle': {
      marginLeft: 'auto'
    }
  }
}))

const ClientListView = ({ columnsConfig }) => {
  const classes = useStyles()
  const [
    {
      hideClientCardTagFilters,
      disableClientCardTagFilters
    }
  ] = useContextContainer(CLIENT_VIEW_CONTEXT)

  return (
    <OperationalTable.Wrapper>
      <OperationalTable.SuperHeader className={classes.tableHeader}>
        <div className={classes.headerContainer}>
          <div className={classes.header}>
            <ClientSearchBar />
            <div className='__view-type-toggle'>
              <ClientViewTypeToggle />
            </div>
          </div>
          {!hideClientCardTagFilters && (
            <ClientGroupTagFilters disabled={disableClientCardTagFilters} />
          )}
        </div>
      </OperationalTable.SuperHeader>
      <ClientsTable columnsConfig={columnsConfig} />
    </OperationalTable.Wrapper>
  )
}

ClientListView.propTypes = {
  columnsConfig: PropTypes.object
}

export default ClientListView
