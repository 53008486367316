import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@material-ui/core'
import { useFormattingContext } from '../../../organisms/FormattingProvider/FormattingContext'
import FadeIn from '../../../molecules/Transitions/FadeIn'

const authenticatorKey = 'google_otp'

function GoogleAuthenticatorFactor ({ authenticators }) {
  const { formatter } = useFormattingContext()

  const { allowed, lastEnrollmentDate, enrollment } = useMemo(() => {
    if (!authenticators) return { allowed: false }
    const authenticator = authenticators.find(x => x.key === authenticatorKey)
    if (!authenticator) return { allowed: false }

    const enrollment = authenticator._embedded.enrollments.at(0)
    if (!enrollment) return { allowed: false }

    return {
      allowed: authenticator.enrollable,
      lastEnrollmentDate: enrollment?.created,
      authenticator,
      enrollment
    }
  }, [authenticators])

  if (!allowed) return null

  return (
    <>
      <Grid item xs={12} md={4}>
        <FadeIn className='__factor-card'>
          <div className='__factor-header'>
            <div className='__factor-name'>Google Authenticator</div>
            <div className='__factor-description'>You are enrolled in Google Authenticator.</div>
          </div>
          <div className='__factor-action'>
            {enrollment ? (<div className='__factor-subtext'>Enrolled on {formatter(lastEnrollmentDate, '@MM/DD/YYYY')}</div>) : null}
          </div>
        </FadeIn>
      </Grid>
    </>
  )
}

GoogleAuthenticatorFactor.propTypes = {
  authenticators: PropTypes.array
}

export default GoogleAuthenticatorFactor
