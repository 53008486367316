import React from 'react'
import keyMirror from 'keymirror'
import { Link } from 'react-router-dom'
import truncate from 'lodash/truncate'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import {
  ADMIN_ROUTES,
  FEE_SCHEDULE_TYPES_MINIMUM_FEE_LABEL,
  FEE_SCHEDULE_TYPES_MINIMUM_FEE_VALUE,
  FEE_VALUE_TYPES_BPS_VALUE,
  FEE_VALUE_TYPES_DOLLAR_VALUE
} from '../../../../constants'
import { BPSToNumber, numberToBPS } from '../../../../utils'

dayjs.extend(utc)

export const OPTIONS_KEYS = keyMirror({
  feeSchedule: null,
  noFeeSchedule: null,
  multipleFeeSchedule: null
})

export const getFeeDetailsFromLimits = (details) => {
  return details.reduce((acc, detail) => {
    detail.limits.forEach((limit) => {
      limit.feeCalcTypeId = detail.calcType
    })
    acc.push(...detail.limits)
    return acc
  }, [])
}

export const normalizeDetails = (details = [], calcTypes = []) => {
  const normalized = []
  details.forEach((detail) => {
    const { denomination, isSingleTier } = calcTypes.find(
      ({ value }) => value === detail.feeCalcTypeId
    )
    const isBPS = denomination === FEE_VALUE_TYPES_BPS_VALUE
    const feeRate = isBPS
      ? BPSToNumber(detail.feeRate)
      : Number(detail.feeRate)
    if (detail.feeCalcTypeId !== FEE_SCHEDULE_TYPES_MINIMUM_FEE_VALUE) {
      normalized.push({
        feeScheduleDetailId: detail.feeScheduleDetailId,
        lowerLimit: isSingleTier ? '0' : Number(detail.lowerLimit),
        upperLimit: isSingleTier ? null : Number(detail.upperLimit),
        feeCalcTypeId: Number(detail.feeCalcTypeId),
        feeRate
      })
    }
  })
  return normalized
}

export const getMinimumFeeFromDetails = (details) => {
  let minimumFee = null
  details.forEach((detail) => {
    if (detail.feeCalcTypeId === FEE_SCHEDULE_TYPES_MINIMUM_FEE_VALUE) {
      minimumFee = Number(detail.feeRate)
    }
  })
  return minimumFee
}

export const getUnavailableFeeTypes = (feeTypeValue, feeTypeOptions) => {
  const isDefaultFeeTypeIncluded =
    feeTypeValue === FEE_SCHEDULE_TYPES_MINIMUM_FEE_VALUE

  const feeTypes = feeTypeOptions.filter(
    ({ value }) => value !== FEE_SCHEDULE_TYPES_MINIMUM_FEE_VALUE
  )
  const selectedFeeType = feeTypes.find(
    ({ value }) => value === feeTypeValue
  )
  const unavailableFeeTypes = selectedFeeType
    ? feeTypes.filter(
      ({ isSingleTier }) => isSingleTier !== selectedFeeType?.isSingleTier
    )
    : []
  const unavailableFeeTypeValues = unavailableFeeTypes.map(
    ({ value }) => value
  )
  if (isDefaultFeeTypeIncluded) {
    unavailableFeeTypeValues.push(FEE_SCHEDULE_TYPES_MINIMUM_FEE_VALUE)
  }
  return unavailableFeeTypeValues
}

export const mapDefaultFeeScheduleDetails = (feeSchedule, calcTypes) => {
  return (feeSchedule.details || []).reduce(
    (
      acc,
      { feeRate, feeCalcTypeId, feeScheduleDetailId, lowerLimit, upperLimit }
    ) => {
      const { denomination } = calcTypes.find(
        ({ value }) => value === feeCalcTypeId
      ) || {}

      const tier = {
        feeScheduleDetailId: feeScheduleDetailId,
        lowerLimit: (lowerLimit || 0).toString(),
        upperLimit: upperLimit ? upperLimit.toString() : null,
        feeRate:
          denomination === FEE_VALUE_TYPES_BPS_VALUE
            ? numberToBPS(feeRate).toString()
            : (feeRate || '0').toString()
      }
      const detail = acc.find((feeType) => feeType.calcType === feeCalcTypeId)

      if (detail) {
        detail.limits.push(tier)
      } else {
        acc.push({
          limits: [tier],
          calcType: feeCalcTypeId
        })
      }
      return acc
    },
    []
  )
}

export const mapFeeMethodOptions = (feeMethods) =>
  feeMethods.map(({ feeMethodId, name }) => ({
    value: feeMethodId,
    label: name
  }))

export const mapFeeCalcTypeOptions = calcTypes => {
  const options = calcTypes.map(
    ({ feeCalcTypeId, name, denomination, extraJSON }) => ({
      value: feeCalcTypeId,
      label: name,
      denomination,
      isSingleTier: extraJSON?.isSingleTier || false
    })
  )
  options.push({
    label: FEE_SCHEDULE_TYPES_MINIMUM_FEE_LABEL,
    value: FEE_SCHEDULE_TYPES_MINIMUM_FEE_VALUE,
    denomination: FEE_VALUE_TYPES_DOLLAR_VALUE,
    isSingleTier: true
  })
  return options
}

const cellTemplates = {
  drilldownLink: (accessor) => ({ row }) => (
    <Link
      onClick={(event) => event.stopPropagation()}
      to={`${ADMIN_ROUTES.ACCOUNTS}/${row.original.accountId}`}
      target='_blank'
      rel='noopener noreferrer'
    >
      {accessor(row.original)}
    </Link>
  ),
  date: ({ value }) => value ? dayjs.utc(value).format('M/D/YYYY') : ''
}

const MAX_CHARACTERS = 30
const CHARACTER_SEPARATOR = ' '
const accountNumberAccesor = (row) => row.longNumber

export const noFeeScheduleColumnConfig = {
  columns: [
    { Header: 'Account ID', accessor: 'accountId', id: 'accountId' },
    { Header: 'Account Number', accessor: accountNumberAccesor, id: 'longNumber', Cell: cellTemplates.drilldownLink(accountNumberAccesor) },
    { Header: 'Account Name', accessor: (row) => truncate(row.longName, { length: MAX_CHARACTERS, separator: CHARACTER_SEPARATOR }), id: 'longName' },
    { Header: 'Custodian Name', accessor: 'custodian', id: 'custodian' },
    { Header: 'As Of Date', accessor: 'asOfDate', Cell: cellTemplates.date, disableSortBy: true }
  ],
  defaultSort: [
    { id: 'longName', desc: false }
  ]
}

export const multipleFeeScheduleColumnConfig = {
  columns: [
    { Header: 'Account ID', accessor: 'accountId', id: 'accountId' },
    { Header: 'Account Number', accessor: accountNumberAccesor, id: 'longNumber', Cell: cellTemplates.drilldownLink(accountNumberAccesor) },
    { Header: 'Account Name', accessor: (row) => truncate(row.longName, { length: MAX_CHARACTERS, separator: CHARACTER_SEPARATOR }), id: 'longName' },
    { Header: 'Custodian Name', accessor: 'custodian', id: 'custodian' },
    { Header: 'As Of Date', accessor: 'asOfDate', Cell: cellTemplates.date, disableSortBy: true },
    { Header: '# of Account Fee Schedules', id: 'AcctFeeScheduleCt', accessor: 'AcctFeeScheduleCt', disableSortBy: true },
    { Header: '# of Client Fee Schedules', id: 'ClientFeeScheduleCt', accessor: 'ClientFeeScheduleCt', disableSortBy: true }
  ],
  defaultSort: [
    { id: 'longNumber', desc: false }
  ]
}
