import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { Box, CircularProgress } from '@material-ui/core'
import dayjs from 'dayjs'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import SydModal from '../../../../commonDesign/SydModal'
import SydButton from '../../../../commonDesign/Button'
import SydLabel, { hookFormErrorAdapter } from '../../../../commonDesign/SydLabel'
import SydInput from '../../../../commonDesign/SydInput'
import SydDatePicker from '../../../../commonDesign/SydDatePicker'
import { useHolidays } from '../../../../../api/coreData'
import { disableHolidaysAndWeekends } from '../../../../../utils'
import Alert from '../../../../atoms/Alert'
import Text from '../../../../atoms/Text'
import { TEXT_VARIANTS } from '../../../../../constants'
import { postCloseAccount } from '../../../../../service'

const AccountCloseModal = ({
  showModal,
  onClose,
  onSuccess,
  account
}) => {
  const { data: holidays, isLoading: isLoadingHolidays } = useHolidays()
  const [submitError, setSubmitError] = useState(null)

  const formMethods = useForm({
    defaultValues: {
      closeReason: '',
      closeDate: dayjs.utc(account.asOfDate).format('YYYY-MM-DD'),
      accountId: account.accountId
    },
    mode: 'onChange'
  })

  const {
    clearErrors,
    reset: resetForm,
    control,
    formState,
    handleSubmit,
    ...formValues
  } = formMethods

  const onSubmitHandler = async (data) => {
    clearErrors()
    setSubmitError(null)
    const { accountId, closeReason, closeDate: _closeDate } = data
    const closeDate = dayjs.utc(_closeDate).format('YYYY-MM-DD')

    try {
      await postCloseAccount({ accountId, closeReason, closeDate, forceCloseUpdatingAccounts: true })
      resetForm()
      onSuccess()
    } catch (err) {
      onHandleError(err)
    }
  }

  const onHandleError = useCallback(() => {
    setSubmitError('There was an issue closing this account.  Please try again or contact an admin.')
  }, [])

  const shouldDisableDate = useCallback(
    (date) => {
      return disableHolidaysAndWeekends(date, holidays) || date.isAfter(dayjs(account.asOfDate).add(1, 'day'))
    },
    [account.asOfDate, holidays]
  )

  return (
    <SydModal
      disablePortal={false}
      open={showModal}
      onClose={onClose}
      title={`Close Reason needed for: ${account.accountName}`}
      subtitle='This account has a balance and requires a close reason'
    >
      <FormProvider handleSubmit={handleSubmit} formState={formState} {...formValues}>
        <form method='post' action='#' onSubmit={onSubmitHandler}>
          {submitError && (
            <Box mb={2}>
              <Alert severity='error'>
                <Text customFontWeight={700}>{submitError}</Text>
              </Alert>
            </Box>
          )}
          <Alert severity='info'>
            <Text customFontWeight={700}>Closing an account cannot be undone and will:</Text>
            <ul style={{ padding: '0 0 0 16px', margin: '4px 0 0 0' }}>
              <li><Text variant={TEXT_VARIANTS.body2}>Enter closing withdrawals for this account on the day prior to the requested close date</Text></li>
              <li><Text variant={TEXT_VARIANTS.body2}>Bring this account's balance to $0 on the close date</Text></li>
              <li><Text variant={TEXT_VARIANTS.body2}>Stop reconciliation of this account moving forward</Text></li>
            </ul>
          </Alert>
          <Box mt={4}>
            <Controller
              name='closeReason'
              control={control}
              rules={{
                required: 'Close reason is required when an account is funded'
              }}
              render={({ field: { ref, ...field }, fieldState }) => (
                <SydLabel
                  label='Close Reason' error={hookFormErrorAdapter(formMethods, fieldState)}
                >
                  <SydInput
                    type='text'
                    placeholder='Enter close reason'
                    {...field}
                    disabled={formState.isSubmitting}
                  />
                </SydLabel>
              )}
            />
          </Box>
          <Box mt={2}>
            <Controller
              name='closeDate'
              control={control}
              rules={{
                required: 'Enter a close date to close this account on'
              }}
              render={({ field: { ref, ...field }, fieldState }) => (
                <SydLabel label='Close Date' error={hookFormErrorAdapter(formMethods, fieldState)}>
                  {isLoadingHolidays ? <CircularProgress /> : (
                    <SydDatePicker
                      name={field.name}
                      shouldDisableDate={shouldDisableDate}
                      defaultDate={dayjs()}
                      disabled={formState.isSubmitting}
                      {...field}
                    />
                  )}
                </SydLabel>
              )}
            />
          </Box>
          <Box
            mt={2}
            display='flex'
            gridGap={10}
            justifyContent='space-between'
          >
            <SydButton
              variant='secondary'
              onClick={onClose}
            >
              Cancel
            </SydButton>
            <SydButton
              variant='primary'
              disabled={formState.isSubmitting || isLoadingHolidays}
              onClick={handleSubmit(onSubmitHandler)}
            >
              Close Account
            </SydButton>
          </Box>
        </form>
      </FormProvider>
    </SydModal>
  )
}

AccountCloseModal.propTypes = {
  showModal: PropTypes.bool,
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
  account: PropTypes.object
}

export default AccountCloseModal
