import { makeStyles, useTheme } from '@material-ui/core'
import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { RUN_DISPLAY_STATUS, RUN_REPORT_DISPLAY_STATUS } from './helpers'

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    gap: '8px',
    alignItems: 'center',
    flexWrap: 'wrap',
    maxWidth: '170px',
    justifyContent: 'space-between'
  },
  reportStatus: {
    flexBasis: '85px',
    textAlign: 'center',
    color: '#fff',
    padding: '4px 12px',
    borderRadius: '8px'
  }
}))

const CellReportStatus = ({
  row: {
    original: { reportStatus, status }
  }
}) => {
  const theme = useTheme()
  const classes = useStyles()

  const colors = useMemo(() => ({
    ERROR: theme.palette.error.main,
    GENERATING_EXPORTS: theme.palette.secondary.main
  }), [theme])

  const reportStatusText = useMemo(() => {
    return RUN_REPORT_DISPLAY_STATUS[reportStatus] ?? reportStatus
  }, [reportStatus])

  const statusText = useMemo(() => {
    return RUN_DISPLAY_STATUS[status] ?? status
  }, [status])

  return (
    <div className={classes.container}>
      {!['PROCESSING', 'ERROR'].includes(reportStatus) && (
        <div className={classes.status}>
          {statusText}
        </div>
      )}
      {reportStatus !== 'PROCESSED' && (
        <div className={classes.reportStatus} style={{ backgroundColor: colors[reportStatus] ?? theme.palette.primary.main }}>
          {reportStatusText}
        </div>
      )}
    </div>
  )
}

CellReportStatus.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      reportStatus: PropTypes.string,
      status: PropTypes.string
    })
  })
}

export default CellReportStatus
