import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { CircularProgress, Grid, makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import isEmpty from 'lodash/isEmpty'
import useClientTasks from '../../hooks/useClientTasks'
import { useTasksContext } from '../../TasksContext'
import { useAdvisorHome } from '../../../AdvisorHome/AdvisorHomeContext'
import { TASK_TABS } from '../../TasksContextProvider'
import TasksSearchBar from '../TasksSearchBar'
import TaskCard from './TaskCard'
import CardTaskSkeleton from './TaskCard/CardTaskSkeleton'

const useStyles = makeStyles(() => ({
  loading: {
    position: 'absolute',
    bottom: '1rem',
    left: '50%',
    transform: 'translateX(-50%)'
  }
}))

const TasksCards = ({ pageSize }) => {
  const classes = useStyles()
  const { scope } = useAdvisorHome()

  const {
    selectedTab,
    containerViewRef,
    selectedAsOfDate,
    overdueStartDate,
    excludeTaskStatuses,
    selectedFeaturedFilter
  } = useTasksContext()

  const [queryText, setQueryText] = useState('')

  const queryConfig = useMemo(() => {
    const { startDate, endDate } = selectedFeaturedFilter || {}

    const filters = TASK_TABS.MY_CLIENTS === selectedTab && !isEmpty(scope?.values)
      ? { groupIds: [{ op: 'in', value: scope.values }] }
      : null

    const query = {
      pageSize,
      filters,
      startDate,
      endDate,
      queryText,
      includes: {
        status: [{ op: 'notIn', value: excludeTaskStatuses }]
      }
    }
    if (!startDate && !endDate) {
      return {
        ...query,
        startDate: overdueStartDate || dayjs().year(1900).toISOString(),
        endDate: selectedAsOfDate
      }
    }
    return query
  }, [
    scope,
    pageSize,
    queryText,
    selectedTab,
    overdueStartDate,
    selectedAsOfDate,
    excludeTaskStatuses,
    selectedFeaturedFilter
  ])

  const { data, isLoading, fetchNextPage } = useClientTasks(queryConfig)

  const onScroll = useCallback(() => {
    if (containerViewRef) {
      const { scrollTop, scrollHeight, clientHeight } = containerViewRef
      const isNearBottom = scrollTop + clientHeight >= scrollHeight

      if (isNearBottom) {
        fetchNextPage()
      }
    }
  }, [containerViewRef, fetchNextPage])

  useEffect(() => {
    if (containerViewRef) {
      containerViewRef.addEventListener('scroll', onScroll)
      return () => {
        containerViewRef.removeEventListener('scroll', onScroll)
      }
    }
  }, [containerViewRef, onScroll])

  if (isEmpty(data) && isLoading) {
    return <CardTaskSkeleton />
  }

  return (
    <>
      <TasksSearchBar
        onChange={setQueryText}
        isLoading={isLoading}
      />
      <Grid container spacing={4}>
        {data.map(({ tasks, taskCount, ...client }) => {
          return (
            <Grid key={client.clientId} item xs={12} md={6} lg={4}>
              <TaskCard tasks={tasks} client={client} taskCount={taskCount} />
            </Grid>
          )
        })}
        {isLoading && (
          <div className={classes.loading}>
            <CircularProgress />
          </div>
        )}
      </Grid>
    </>
  )
}

TasksCards.propTypes = {
  pageSize: PropTypes.number
}

TasksCards.defaultProps = {
  pageSize: 10000
}

export default TasksCards
