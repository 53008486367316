import React from 'react'
import PropTypes from 'prop-types'
import GroupedCoreBarChart from '../GroupedCoreBarChart'
import MaturityLadderBarChart, {
  MaturityLadderBarChartDefaultProps,
  maturityLadderBarChartPropTypes
} from './maturityLadderChart'

const MaturityLadder = ({ title, height, width, ...chartProps }) => {
  return (
    <GroupedCoreBarChart.Container title={title} height={height} width={width}>
      <MaturityLadderBarChart {...chartProps} />
    </GroupedCoreBarChart.Container>
  )
}

MaturityLadder.propTypes = {
  ...maturityLadderBarChartPropTypes,
  title: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

MaturityLadder.defaultProps = {
  ...MaturityLadderBarChartDefaultProps,
  title: 'Maturity Ladder',
  height: '400px',
  width: '100%'
}

export default MaturityLadder
