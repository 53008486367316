import { useQuery } from '@tanstack/react-query'
import { useAppContext } from '../redux/slices/appContext'
import { postNamedQuery } from '../service'
import { QUERY_KEYS } from './queryKeys'

export const useSearchKeyDatesMultiple = (query, options = {}) => {
  const { userId } = useAppContext()
  const { mapper = null } = options
  return useQuery({
    queryKey: [QUERY_KEYS.searchKeyDatesMultiple, userId, query],
    queryFn: async () => {
      const { data } = await postNamedQuery(
        'levels',
        'searchKeyDatesMultiple',
        query
      )
      return data
    },
    select: mapper
  })
}
