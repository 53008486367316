import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import LabeledValue from '../components/LabeledValue'
import Hideable from '../components/Hideable'

const useStyles = makeStyles((theme) => ({
  source: {
    borderBottom: `1px solid ${theme.palette.gray.dark}`,
    paddingBottom: '.5rem'
  },
  codeBlock: {
    backgroundColor: theme.palette.gray.main,
    padding: '1rem',
    whiteSpace: 'pre'
  }
}))

const ObjectMappingSources = ({ mapping }) => {
  const classes = useStyles()
  const batchSize = useCallback((item) => {
    if (item === null) {
      return 'None'
    }
    return item
  }, [])

  const noSources = mapping.sources.length ? null : <div>No Sources</div>

  return (
    <section className={classes.source}>
      <Hideable label='Sources'>
        {noSources}
        {mapping.sources.map(source => (
          <div key={source.objectSourceId}>
            <LabeledValue label='objectSourceId'>{source.objectSourceId}</LabeledValue>
            <LabeledValue label='Default Batch Size'>{batchSize(source.defaultBatchSize)}</LabeledValue>
            <div className={classes.codeBlock}>
              <code dangerouslySetInnerHTML={{ __html: source.query }} />
            </div>
          </div>
        ))}
      </Hideable>
    </section>
  )
}

ObjectMappingSources.propTypes = {
  mapping: PropTypes.shape({
    sources: PropTypes.arrayOf(PropTypes.shape({
      objectSourceId: PropTypes.number,
      query: PropTypes.string,
      defaultBatchSize: PropTypes.number
    }))
  })
}

export default ObjectMappingSources
