import React from 'react'
import { makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import RoundedModal from '../../molecules/RoundedModal'
import PersonalSpace from '../../molecules/PersonalSpace'
import FadeIn from '../../molecules/Transitions/FadeIn'

const useStyles = makeStyles((theme) => ({
  transitionBody: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: theme.layout.gap.loose,
    fontSize: theme.typography.fontSizes.xl
  },
  logo: {
    maxHeight: '10vh'
  },
  minor: {
    fontWeight: theme.typography.weights.light
  },
  linkButton: {
    textTransform: 'none',
    borderRadius: '10rem',
    fontWeight: theme.typography.weights.semibold,
    transition: 'background-color 200ms ease-in-out, color 200ms ease-in-out',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '8px',
    backgroundColor: theme.palette.primary.main,
    outline: `1.5px solid ${theme.palette.primary.main}`,
    color: `${theme.palette.primary.contrast} !important`,
    stroke: theme.palette.primary.contrast,
    '&:hover': {
      backgroundColor: theme.palette.primary.hover,
      color: `${theme.palette.primary.contrast} !important`
    },
    '&:disabled': {
      backgroundColor: theme.palette.primary.disabled
    },
    '& .__progress': {
      color: theme.palette.primary.contrast,
      stroke: theme.palette.primary.contrast
    },
    padding: '4px 24px',
    minHeight: '32px',
    lineHeight: '32px',
    fontSize: theme.typography.fontSizes.xl
  },
  linkContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.layout.gap.dense,
    alignItems: 'center',
    justifyContent: 'center'
  },
  error: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrast,
    padding: theme.layout.padding.thick,
    borderRadius: theme.layout.radius.round
  }
}))

function OutboundSsoTransition ({ processing, onClose, provider, newTab, link, error }) {
  const classes = useStyles()
  const providerName = provider ? provider?.name : ''
  const logoUrl = provider ? provider?.logoUrl : null
  return (
    <RoundedModal
      title='Redirecting'
      open={processing}
      onClose={onClose}
      disablePortal={false}
    >
      <div className={classes.transitionBody}>
        {error ? (
          <div className={classes.error}>{error}</div>
        ) : (
          <>
            {providerName ? (
              <div>Taking you to <strong>{providerName}</strong></div>
            ) : null}
            {logoUrl ? (
              <img className={classes.logo} src={logoUrl} alt={providerName} />
            ) : null}
            {newTab ? (<div className={classes.minor}>This will open in a new window</div>) : null}
            {link ? (
              <FadeIn className={classes.linkContainer} delay={5} duration={1}>
                Window not opening? <span><a className={classes.linkButton} href={link} target='_blank' rel='noopener noreferrer'>Click here to proceed</a></span>
              </FadeIn>
            ) : (<div>&nbsp;</div>)}
          </>
        )}
      </div>
      <PersonalSpace size={35} />
    </RoundedModal>
  )
}

OutboundSsoTransition.propTypes = {
  processing: PropTypes.bool,
  onClose: PropTypes.func,
  provider: PropTypes.object,
  newTab: PropTypes.bool,
  link: PropTypes.string,
  error: PropTypes.string
}

export default OutboundSsoTransition
