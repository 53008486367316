import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useAdvisorHome } from '../../AdvisorHome/AdvisorHomeContext'
import { DenaliFilterContextProvider } from './DenaliFilterContextProvider'

export function AdvisorHomeScopeFilter ({ column, children }) {
  const { scope } = useAdvisorHome()
  const _value = useMemo(() => {
    return {
      membershipFilter: scope?.values?.length ? {
        ahScope: {
          path: [scope.groupTypeId, 201],
          column,
          from: scope.values
        }
      } : null,
      filter: null
    }
  }, [scope, column])

  return (
    <DenaliFilterContextProvider value={_value}>
      {children}
    </DenaliFilterContextProvider>
  )
}

AdvisorHomeScopeFilter.propTypes = {
  column: PropTypes.string,
  children: PropTypes.node
}

AdvisorHomeScopeFilter.defaultProps = {
  column: 'clientId'
}
