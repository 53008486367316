/* eslint-disable react/jsx-handler-names */
import React, { useCallback, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import {
  Table as MuiTableCore,
  TableBody,
  TableCell as MuiTableCellCore,
  TableHead,
  TableRow
} from '@material-ui/core'
import dayjs from 'dayjs'
import { useForm } from 'react-hook-form'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import isObject from 'lodash/isObject'
import last from 'lodash/last'
import AutoSizer from 'react-virtualized-auto-sizer'
import { FixedSizeList as List } from 'react-window'
import isEmpty from 'lodash/isEmpty'
import { TABLE_DATE_FORMAT, TABLE_VARIANTS, TEXT_ALIGNMENTS, TEXT_VARIANTS } from '../../constants'
import Text from '../atoms/Text'
import TableCell from '../atoms/TableCell'
import TableCellHeader from '../atoms/TableCellHeader'
import withDynamicHeight from '../../HOCs/withDynamicHeight'
import { simpleTableLabelDefaults } from '../../prop-types/tables'

const MuiTable = ({ elementType, ...props }) => (
  <MuiTableCore
    {...props}
    {...(elementType ? { component: elementType } : {})}
  />
)
const MuiTableRow = ({ elementType, ...props }) => (
  <TableRow {...props} {...(elementType ? { component: elementType } : {})} />
)
const MuiTableHead = ({ elementType, ...props }) => (
  <TableHead {...props} {...(elementType ? { component: elementType } : {})} />
)
const MuiTableBody = ({ elementType, ...props }) => (
  <TableBody {...props} {...(elementType ? { component: elementType } : {})} />
)
const MuiTableCell = ({ elementType, ...props }) => (
  <MuiTableCellCore
    {...props}
    {...(elementType ? { component: elementType } : {})}
  />
)

const defaultWithRowComments = false

const {
  colSpan: defaultLabelColSpan
} = simpleTableLabelDefaults

const virtualizedStyle = {
  form: {
    display: 'block',
    flex: 1,
    width: '100%'
  },
  table: {
    height: '100%',
    width: '100%'
  },
  tbody: {
    width: '100%',
    height: '100%',
    display: 'table-row'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    boxSizing: 'border-box',
    minWidth: '100%',
    width: '100%'
  },
  finalRow: {
    position: 'absolute',
    bottom: '-3.75rem'
  }
}

const useStyles = makeStyles((theme) => ({
  row: ({ virtualized }) => ({
    cursor: 'pointer',
    ...(virtualized ? virtualizedStyle.row : {})
  }),
  rowHeader: ({ virtualized }) => ({
    '& th': {
      backgroundColor: theme.palette.wildSand
    },
    border: 'none',
    ...(virtualized
      ? {
        '& div': {
          backgroundColor: theme.palette.wildSand
        }
      }
      : {})
  }),
  finalRowCell: {
    paddingTop: '30px'
  },
  finalRow: ({ virtualized }) => (virtualized ? virtualizedStyle.finalRow : {}),
  input: {
    ...theme.typography.body2,
    height: '100%',
    width: 'calc(100% - 4px)',
    resize: 'none',
    textAlign: 'center',
    margin: '0',
    padding: '2px',
    border: '0',
    verticalAlign: 'middle',
    color: theme.palette.coral
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1
  },
  tableContainer: ({ stickyHeader }) => ({
    ...(stickyHeader
      ? { width: '100%', overflowX: 'auto' }
      : { height: '100%' })
  }),
  form: ({ stickyHeader, virtualized }) => ({
    ...(virtualized ? virtualizedStyle.form : { width: '100%' }),
    ...(stickyHeader ? { display: 'flex', flex: 1, minHeight: 0 } : {})
  }),
  table: ({ virtualized }) => {
    let styles = {}
    if (virtualized) {
      styles = {
        ...styles,
        ...virtualizedStyle.table
      }
    }
    return styles
  },
  tbody: ({ virtualized, variant }) => {
    let styles = {}
    if (variant === TABLE_VARIANTS.floatingHeader) {
      styles = {
        ...styles,
        borderRadius: '0.375rem'
      }
    }
    if (virtualized) {
      styles = { ...styles, ...virtualizedStyle.tbody }
    }
    return styles
  },
  headerRow: {},
  thead: {},
  list: {}
}))

const compareStrings = (stringA, stringB) => {
  const strA = stringA.toUpperCase()
  const strB = stringB.toUpperCase()
  if (strA < strB) return -1
  if (strA > strB) return 1
  return 0
}

const compareDates = (stringA, stringB) => {
  const dateA = dayjs(stringA, TABLE_DATE_FORMAT)
  const dateB = dayjs(stringB, TABLE_DATE_FORMAT)
  if (dateA.isBefore(dateB)) return -1
  if (dateA.isAfter(dateB)) return 1
  return 0
}

function descendingComparator (rowA, rowB, orderBy) {
  const propIndex = rowA[orderBy]?.hidden
    ? rowA.findIndex((row, index) => index >= orderBy && !row?.hidden)
    : orderBy

  const valueA = rowA[propIndex] ? rowA[propIndex]?.rawValue || rowA[propIndex]?.value : rowA[propIndex]
  const valueB = rowB[propIndex] ? rowB[propIndex]?.rawValue || rowB[propIndex]?.value : rowB[propIndex]

  if (
    (valueA && rowA[propIndex].isDate) ||
    (valueB && rowB[propIndex].isDate)
  ) {
    return compareDates(valueA, valueB)
  }
  if (typeof valueA === 'string' && typeof valueB === 'string') {
    return compareStrings(valueA, valueB)
  }
  return valueA - valueB
}

function getComparator (order, orderBy) {
  return order === 'desc'
    ? (rowA, rowB) => descendingComparator(rowA, rowB, orderBy)
    : (rowA, rowB) => -descendingComparator(rowA, rowB, orderBy)
}

function sortTable (array, comparator) {
  const sorted = [...array]
  sorted.sort(comparator)
  return sorted
}

function renderComment (
  index,
  classes,
  { register, isFinalRow, withRowComments = defaultWithRowComments } = {}
) {
  if (!withRowComments) return null

  const key = `comment-${index}`
  return (
    <MuiTableCell
      align={TEXT_ALIGNMENTS.center}
      elementType={null}
      key={key}
      className={clsx({ [classes.finalRowCell]: isFinalRow })}
      padding='none'
    >
      {/* TODO: when we have comments in the data we should show them with defaultValue */}
      <textarea name={key} ref={register} className={classes.input} />
    </MuiTableCell>
  )
}

const getTableRow = ({
  row,
  index,
  onClick,
  classes,
  options,
  variant,
  style = null,
  elementType = null,
  virtualized = false
}) => {
  const tableRowProps = {
    className: clsx(classes.row, { [classes.finalRow]: options.isFinalRow }),
    ...(style ? { style } : {}),
    onClick: (event) => onClick(event, row)
  }
  return (
    <MuiTableRow
      key={`row-${index}`}
      hover
      elementType={elementType}
      {...tableRowProps}
    >
      {row.map((cell, index) => {
        const cellOptions = isObject(last(row)) ? last(row) : null
        return (
          <TableCell
            cell={cell}
            variant={variant}
            elementType={elementType}
            tooltip={
              cellOptions && cellOptions.showTooltip
                ? cellOptions.tooltips[index]
                : null
            }
            disabledEdit={options.disabledEdit}
            disabledDelete={options.disabledDelete}
            isFirstCell={index === 0}
            isLastCell={index === row.length - 1}
            isFinalRow={options.isFinalRow}
            key={`content-${index}`}
            onHandleDelete={options.handleDelete}
            onHandleEdit={options.handleEdit}
            onHandleCheck={options.handleCheckCell}
            virtualized={virtualized}
            checkedCells={options.checkedCells}
            showDeleteButton={cellOptions?.showDeleteButton}
          />
        )
      })}
      {renderComment(index, classes, options)}
    </MuiTableRow>
  )
}

const renderVirtualizedRow = ({ index, style, data }) => {
  const {
    items,
    onClick,
    classes,
    options,
    virtualized,
    elementType,
    rowProps: { rowHeight },
    checkedCells
  } = data
  const row = items[index]
  return getTableRow({
    row,
    index,
    onClick,
    classes,
    options,
    virtualized,
    elementType,
    style: { ...style, height: rowHeight },
    checkedCells
  })
}

function renderHead (
  labels,
  classes,
  options,
  elementType,
  virtualized,
  variant
) {
  const {
    order,
    orderBy,
    handleRequestSort,
    withRowComments = defaultWithRowComments
  } = options

  return (
    <MuiTableRow
      elementType={elementType}
      className={clsx(classes.rowHeader, classes.row, classes.headerRow)}
    >
      {labels.map((labelConfig, index) => {
        const isLastCell = index === labels.length - 1
        return (
          <TableCellHeader
            key={`${labelConfig.name}-${index}`}
            index={index}
            tableVariant={variant}
            virtualized={virtualized}
            {...{
              order,
              orderBy,
              isLastCell,
              elementType,
              handleRequestSort,
              ...labelConfig
            }}
          />
        )
      })}
      {withRowComments && (
        <MuiTableCell
          tableVariant={variant}
          elementType={elementType}
          align={TEXT_ALIGNMENTS.center}
          colSpan={defaultLabelColSpan}
          key='comment'
          className={classes.cell}
        >
          <Text text='Comments' variant={TEXT_VARIANTS.subtitle2} />
        </MuiTableCell>
      )}
    </MuiTableRow>
  )
}

function Table ({
  elementType,
  disabledDelete,
  disabledEdit,
  finalRow,
  handleEdit,
  handleDelete,
  handleCheckCell,
  labels,
  onRowClick,
  rows,
  withRowComments,
  stickyHeader,
  virtualized,
  checkedCells,
  virtualizeProps,
  skipOrderBy,
  variant,
  isLoading,
  emptySection
}) {
  const classes = useStyles({ stickyHeader, virtualized, variant })

  const { register, handleSubmit } = useForm()
  const onSubmit = useCallback((data) => console.log(data), [])

  const [order, setOrder] = useState('desc')
  const [orderBy, setOrderBy] = useState(0)

  const handleRequestSort = useCallback(
    (property) => {
      const isAsc = orderBy === property && order === 'asc'
      setOrder(isAsc ? 'desc' : 'asc')
      setOrderBy(property)
    },
    [order, orderBy]
  )

  const renderedHead = useMemo(() => {
    const options = {
      order,
      orderBy,
      withRowComments,
      handleRequestSort
    }
    return renderHead(
      labels,
      classes,
      options,
      elementType,
      virtualized,
      variant
    )
  }, [
    order,
    labels,
    orderBy,
    variant,
    elementType,
    classes,
    virtualized,
    withRowComments,
    handleRequestSort
  ])

  const rowOptions = useMemo(
    () => ({
      register,
      handleEdit,
      disabledEdit,
      handleDelete,
      handleCheckCell,
      handleSubmit,
      disabledDelete,
      withRowComments,
      checkedCells
    }),
    [
      register,
      handleEdit,
      handleCheckCell,
      disabledEdit,
      handleDelete,
      handleSubmit,
      disabledDelete,
      withRowComments,
      checkedCells
    ]
  )

  const rowsSorted = useMemo(
    () => (skipOrderBy ? rows : sortTable(rows, getComparator(order, orderBy))),
    [rows, order, orderBy, skipOrderBy]
  )

  const renderedBody = useMemo(
    () =>
      rowsSorted.map((row, index) =>
        getTableRow({
          row,
          index,
          classes,
          elementType,
          onClick: onRowClick,
          options: rowOptions
        })
      ),
    [rowsSorted, classes, rowOptions, onRowClick, elementType]
  )

  const renderedVirtualizedBody = useMemo(() => {
    if (isEmpty(rowsSorted) && emptySection && !isLoading) {
      return emptySection
    }
    return (
      <AutoSizer>
        {({ height, width }) => {
          return (
            <List
              order={order}
              sortBy={orderBy}
              width={width}
              height={height}
              className={classes.list}
              itemCount={rowsSorted.length}
              itemSize={virtualizeProps.rowHeight}
              itemKey={(index, data) => data.items[index][0].value}
              itemData={{
                classes,
                elementType,
                virtualized,
                items: rowsSorted,
                options: rowOptions,
                onClick: onRowClick,
                rowProps: virtualizeProps
              }}
            >
              {renderVirtualizedRow}
            </List>
          )
        }}
      </AutoSizer>
    )
  }, [
    order,
    orderBy,
    classes,
    rowsSorted,
    rowOptions,
    onRowClick,
    elementType,
    virtualized,
    virtualizeProps,
    emptySection,
    isLoading
  ])

  const renderedFinalRow = useMemo(
    () =>
      finalRow &&
      getTableRow({
        classes,
        elementType,
        row: finalRow,
        onClick: noop,
        index: rows.length,
        options: { isFinalRow: true, withRowComments, register }
      }),
    [classes, finalRow, register, rows.length, withRowComments, elementType]
  )

  const renderedTableBody = useMemo(
    () => (virtualized ? renderedVirtualizedBody : renderedBody),
    [renderedBody, virtualized, renderedVirtualizedBody]
  )

  return (
    // TODO: save comments somewhere with a submit comments prop
    // could be with watch or getValues
    // https://react-hook-form.com/api#useForm
    // https://react-hook-form.com/faqs#watchvsgetValuesvsstate
    <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
      <div className={classes.tableContainer}>
        <MuiTable
          elementType={elementType}
          className={classes.table}
          aria-label='simple table'
          stickyHeader={stickyHeader}
        >
          <MuiTableHead elementType={elementType} className={classes.thead}>
            {renderedHead}
          </MuiTableHead>
          <MuiTableBody elementType={elementType} className={classes.tbody}>
            {renderedTableBody}
            {renderedFinalRow}
          </MuiTableBody>
        </MuiTable>
      </div>
    </form>
  )
}

MuiTable.propTypes = { elementType: PropTypes.string }
MuiTableRow.propTypes = { elementType: PropTypes.string }
MuiTableHead.propTypes = { elementType: PropTypes.string }
MuiTableBody.propTypes = { elementType: PropTypes.string }
MuiTableCell.propTypes = { elementType: PropTypes.string }

Table.propTypes = {
  checkedCells: PropTypes.any,
  disabledDelete: PropTypes.any,
  disabledEdit: PropTypes.any,
  finalRow: PropTypes.any,
  handleEdit: PropTypes.any,
  handleDelete: PropTypes.any,
  handleCheckCell: PropTypes.any,
  labels: PropTypes.any,
  rows: PropTypes.any,
  withRowComments: PropTypes.any,
  onRowClick: PropTypes.any,
  stickyHeader: PropTypes.any,
  elementType: PropTypes.any,
  virtualized: PropTypes.any,
  virtualizeProps: PropTypes.any,
  skipOrderBy: PropTypes.any,
  variant: PropTypes.any,
  emptySection: PropTypes.any,
  isLoading: PropTypes.any
}

Table.defaultProps = {
  virtualizeProps: {
    rowHeight: 58,
    tableHeaderHeight: 70,
    maxRowsBeforeScroll: 10,
    useParentHeight: true
  },
  skipOrderBy: false
}

export const TableVirtualizedWithDynamicHeight = withDynamicHeight(Table)

export default Table
