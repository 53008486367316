import React, { useCallback, useMemo, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import Skeleton from '../../../atoms/Skeleton'
import RoundedButton from '../../../atoms/RoundedButton'
import { BUTTON_SIZES, BUTTON_VARIANT } from '../../../../constants'
import { useDynamicPlanning } from '../DynamicPlanningContext'
import { postNamedCommand } from '../../../../service'
import { useAppContext } from '../../../../redux/slices/appContext'
import SaveSandboxModal from './SaveSandboxModal'
import DeletePlanModal from './DeletePlanModal'

const useStyles = makeStyles(() => ({

  actionsContainer: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    gap: '1rem'
  },
  createActionsContainer: {
    display: 'flex',
    gap: '1rem'
  }
}))

const PlanEditActions = () => {
  const classes = useStyles()
  const {
    isLoadingData,
    currentPlan,
    linkedPlan,
    setCurrentPlanId,
    refetchPlans,
    postUpdatePlan,
    createSandboxFromPlan,
    planStatusMap,
    isSandbox
  } = useDynamicPlanning()
  const { clientId } = useAppContext()

  const [showSaveSandboxModal, setShowSaveSandboxModal] = useState(false)
  const [showDeletePlanModal, setShowDeletePlanModal] = useState(false)

  const [isLoading, setIsLoading] = useState(false)

  const markPlanAsActive = useCallback(async () => {
    setIsLoading(true)
    await postNamedCommand('planning', 'markPlanAsActive', {
      planId: currentPlan.planId,
      levelId: clientId,
      levelTypeId: 201
    })
    await refetchPlans()
    setIsLoading(false)
  }, [clientId, currentPlan, refetchPlans])

  const createNewSandboxPlan = useCallback(async () => {
    setIsLoading(true)

    const sandboxPlan = createSandboxFromPlan(currentPlan)
    const newPlan = await postUpdatePlan(sandboxPlan)
    setCurrentPlanId(newPlan.planId)
    setIsLoading(false)
  }, [createSandboxFromPlan, currentPlan, postUpdatePlan, setCurrentPlanId])

  const createPlan = useCallback(async () => {
    setIsLoading(true)
    await postUpdatePlan(currentPlan)
    await refetchPlans()
    setIsLoading(false)
  }, [currentPlan, postUpdatePlan, refetchPlans])

  const activeButtonsSet = useMemo(() => {
    const returnMap = new Set()
    if (!currentPlan) {
      return returnMap
    }
    if (typeof currentPlan.planId === 'string') {
      returnMap.add('CREATE_PLAN')
      return returnMap
    }
    if (currentPlan.isNew) {
      return returnMap
    }
    if (linkedPlan) {
      returnMap.add('PLAN_TOGGLE')
    }
    if (!linkedPlan && !isSandbox(currentPlan)) {
      returnMap.add('CREATE_SANDBOX')
    }
    if (isSandbox(currentPlan)) {
      returnMap.add('SAVE_SANDBOX')
    }
    if (currentPlan?.planStatusId === planStatusMap.ALTERNATE?.planStatusId) {
      returnMap.add('MAKE_ACTIVE')
    }
    if (currentPlan?.planStatusId !== planStatusMap.ACTIVE?.planStatusId) {
      returnMap.add('DELETE_PLAN')
    }
    return returnMap
  }, [currentPlan, isSandbox, linkedPlan, planStatusMap.ACTIVE?.planStatusId, planStatusMap.ALTERNATE?.planStatusId])

  if (isLoadingData) {
    return (
      <div className={classes.createActionsContainer}>
        <Skeleton width='100px' height='2rem' />
        <Skeleton width='100px' height='2rem' />
      </div>
    )
  }

  return (
    <>
      <div className={classes.actionsContainer}>
        {activeButtonsSet.has('PLAN_TOGGLE') && (
          <div className={classes.createActionsContainer}>
            <RoundedButton
              variant={BUTTON_VARIANT.outlined}
              secondary
              size={BUTTON_SIZES.extraSmall}
              onClick={() => setCurrentPlanId(linkedPlan.planId)}
            >
              {linkedPlan.planStatusKey === 'SANDBOX' ? 'View Edits' : 'View Original'}
            </RoundedButton>
          </div>
        )}

        {activeButtonsSet.has('CREATE_SANDBOX') && (
          <div className={classes.createActionsContainer}>
            <RoundedButton
              variant={BUTTON_VARIANT.outlined}
              secondary
              size={BUTTON_SIZES.extraSmall}
              onClick={createNewSandboxPlan}
              disabled={isLoading}
            >
              {!isLoading ? 'Edit plan' : 'Creating Editing Plan...'}
            </RoundedButton>
          </div>
        )}

        {activeButtonsSet.has('SAVE_SANDBOX') && (
          <div className={classes.createActionsContainer}>
            <RoundedButton
              variant={BUTTON_VARIANT.outlined}
              primary
              size={BUTTON_SIZES.extraSmall}
              onClick={() => setShowSaveSandboxModal(true)}
            >
              Commit Plan Edits
            </RoundedButton>
          </div>
        )}

        {activeButtonsSet.has('CREATE_PLAN') && (
          <div className={classes.createActionsContainer}>
            <RoundedButton
              variant={BUTTON_VARIANT.outlined}
              tertiary
              size={BUTTON_SIZES.extraSmall}
              onClick={createPlan}
            >
              {!isLoading ? 'Save New Plan' : 'Saving...'}
            </RoundedButton>
          </div>
        )}

        {activeButtonsSet.has('DELETE_PLAN') && (
          <div className={classes.createActionsContainer}>
            <RoundedButton
              variant={BUTTON_VARIANT.outlined}
              tertiary
              size={BUTTON_SIZES.extraSmall}
              onClick={() => setShowDeletePlanModal(true)}
            >
              {!isLoading ? currentPlan.planStatusKey === 'SANDBOX' ? 'Delete Edits' : 'Delete Plan' : 'Loading...'}
            </RoundedButton>
          </div>
        )}

        {activeButtonsSet.has('MAKE_ACTIVE') && (
          <div className={classes.createActionsContainer}>
            <RoundedButton
              variant={BUTTON_VARIANT.outlined}
              primary
              size={BUTTON_SIZES.extraSmall}
              onClick={markPlanAsActive}
            >
              {!isLoading ? 'Make this plan active' : 'Updating...'}
            </RoundedButton>
          </div>
        )}
      </div>

      {showSaveSandboxModal && (
        <SaveSandboxModal
          isOpen
          onClose={() => setShowSaveSandboxModal(false)}
        />
      )}
      {showDeletePlanModal && (
        <DeletePlanModal
          isOpen
          onClose={() => setShowDeletePlanModal(false)}
        />
      )}
    </>
  )
}

export default PlanEditActions
