import React from 'react'
import { useParams } from 'react-router-dom'
import { makeStyles } from '@material-ui/core'
import FadeIn from '../../molecules/Transitions/FadeIn'
import { useHideSideMenuEffect } from '../../../redux/slices/appContext'
import FirmReport from './FirmReport'

const useStyles = makeStyles((theme) => ({
  firmReporting: {
    padding: '5px 2rem'
  }
}))

function FirmReporting () {
  const classes = useStyles()
  const { reportId } = useParams()
  useHideSideMenuEffect([reportId])

  return (
    <FadeIn className={classes.firmReporting}>
      <FirmReport reportId={reportId} />
    </FadeIn>
  )
}

export default FirmReporting
