import { useRebalancerSettings } from '../RebalancerSettingsProvider'
import { useClientDates } from '../../../../api/coreData'
import { useClientAvailableRebalancerCash } from '../../../../api/rebalancer'

export const useAvailableCash = (clientId) => {
  const settings = useRebalancerSettings()
  const { data: dates } = useClientDates(clientId)
  const { data: cashResponse, isLoading: cashLoading } = useClientAvailableRebalancerCash(clientId, dates?.max, settings)

  return {
    cashResponse,
    cashLoading
  }
}
