import React, { useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import AbundanceEngineView from '../../../abundanceEngine/AbundanceEngineView'
import { useAppContext, useSetAppContext } from '../../../redux/slices/appContext'
import { ADVISOR_VIEW_PATH } from '../../../constants'

const AdvisorViewRoute = () => {
  const { id, subView } = useParams()

  const [viewPath, fallbackViewPath] = useMemo(() => {
    if (typeof subView !== 'undefined') {
      return [`${subView}-${id}`, subView]
    }
    if (id) {
      return [`${ADVISOR_VIEW_PATH}-${id}`, ADVISOR_VIEW_PATH]
    }
    return [ADVISOR_VIEW_PATH, ADVISOR_VIEW_PATH]
  }, [id, subView])

  const { isFullViewOpen } = useAppContext()
  const setAppContext = useSetAppContext()

  useEffect(() => {
    setAppContext({ isFullViewOpen: isFullViewOpen })
    return () => setAppContext({ isFullViewOpen: false })
  }, [isFullViewOpen, setAppContext])

  useEffect(() => {
    setAppContext({ advisorId: +id, overContentOnDesktop: true, isSidebarOpen: false })
    return () => setAppContext({ overContentOnDesktop: false })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewPath, fallbackViewPath, id])

  return (
    <AbundanceEngineView
      viewPath={viewPath}
      fallbackViewPath={fallbackViewPath}
      advisorId={id}
    />
  )
}

export default AdvisorViewRoute
