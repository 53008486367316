import React, { useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { isArray, isEmpty } from 'lodash'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import {
  useQueryBookOfBusinessReport
} from '../../../../api/bobi'
import OperationalTable, {
  useOperationalTable
} from '../../../organisms/OperationalTable'
import { useGroupingContext, useSetTableProps } from '../../../organisms/GroupingProvider/GroupingContext'
import { useAutoOperationalTableContext } from '../../../organisms/OperationalTable/AutoOperationalTableContext'
import { isNumeric } from '../../../../utils'
import { DIALOG_STORAGE_KEYS as STR_KEYS } from '../../../organisms/GroupingProvider/GroupingCustomizeColumnsDialog/helpers'
import { reportParamsShape, reportResultShape } from './reportParams'
import { escapeString, getAdditionalData } from './helpers'
import { useColumns } from './columnConfig'
import ReportResultsEmptyState from './ReportResultsEmptyState'
import { useReportFiltersContext } from './ReportFiltersProvider/ReportFiltersProvider'
import { getOutputQueryFilters } from './filterHelpers'

dayjs.extend(utc)

export const getBookOfBusinessReportParams = (reportParams) => {
  const { filters: reportFilters } = reportParams

  const filters = !isArray(reportFilters)
    ? reportFilters
    : reportFilters.reduce((acc, { value, type }) => {
      if (value?.length && typeof value[0] === 'object') {
        value = value.map((v) => v.value)
      }
      acc[type] = value
      return acc
    }, {})

  if (!filters?.additionalData) {
    filters.additionalData = getAdditionalData(reportParams)
  }

  return {
    _raw: {
      ...reportParams,
      filters,
      rawFilters: reportFilters
    },
    asOfDate: reportParams.endDate,
    dataSets: reportParams.dataSets,
    filters,
    levelType: reportParams.levelType,
    relativeDateRange: reportParams.dateRangePreset,
    includeTags: reportParams.includeTags,
    metadata: reportParams.metadata
  }
}

const useReportResults = ({
  reportResult
}) => {
  const { bobiResult, bobiResultLoading, bobiResultError, reportParams } = reportResult

  const { queryText, columnFiltersVisibility } = useReportFiltersContext()

  const outputReportParams = useMemo(() => {
    const queryFilters = getOutputQueryFilters(columnFiltersVisibility)
    const normalizedQueryText = escapeString(queryText || '').trim().toLowerCase()
    const and = []
    const or = []
    if (normalizedQueryText) {
      const textFilters = [
        `LOWER(o.levelName) LIKE '%${normalizedQueryText}%'`,
        `o.balance.accountNumber LIKE '%${normalizedQueryText}%'`
      ]

      if (isNumeric(normalizedQueryText)) {
        textFilters.push(`o.levelId = ${normalizedQueryText}`)
      }

      and.push(`(${textFilters.join(' OR ')})`)
    }

    const decode = (text) => {
      const el = document.createElement('textarea')
      el.innerHTML = text
      return el.value
    }

    if (!isEmpty(queryFilters)) {
      and.push(...queryFilters.map(decode))
    }

    if (!isEmpty(reportParams?.outputFilters)) {
      or.push(...(reportParams?.outputFilters?.or?.map(decode) || []))
      and.push(...(reportParams?.outputFilters?.and?.map(decode) || []))
    }

    return {
      order: { field: 'levelId', asc: true },
      filters: {
        and,
        or
      }
    }
  }, [columnFiltersVisibility, queryText, reportParams.outputFilters])

  const query = useMemo(() => {
    if (bobiResult) {
      return {
        ...outputReportParams,
        bobiOutputId: bobiResult.bobiOutputId,
        output: 'json'
      }
    }
    return null
  }, [bobiResult, outputReportParams])

  const {
    data: queryResult,
    isInitialLoading: queryResultLoading
  } = useQueryBookOfBusinessReport(query, {
    enabled: bobiResult?.status === 'completed' && Boolean(bobiResult.bobiOutputId)
  })

  const itemName = useMemo(() => {
    switch (reportParams.levelType) {
      case 'account':
        return 'Accounts'
      case 'client':
        return 'Clients'
      default:
        return 'Items'
    }
  }, [reportParams.levelType])

  return {
    data: queryResult,
    total: null,
    isLoading: queryResultLoading || bobiResultLoading || !Array.isArray(queryResult),
    error: bobiResultError,
    itemName
  }
}

const ReportResultsTable = ({
  reportResult,
  isLoading: externalIsLoading,
  defaultPageSize
}) => {
  const { configurationKey } = useGroupingContext()
  const { setPerformanceTableProps } = useGroupingContext()
  const { tableProps } = useAutoOperationalTableContext()

  const storageConfigKey = configurationKey
    ? `${STR_KEYS.COLUMNS_DND_ORDER_TOGGLER}_${configurationKey}`
    : undefined

  const {
    pageSize,
    onPagingChange,
    onSortingChange,
    onTableModeChange
  } = useOperationalTable({
    defaultPageSize
  })

  const { data, total, isLoading, itemName } = useReportResults({
    pageSize,
    reportResult
  })

  const { columns, columnFilters, defaultSort } = useColumns(
    data,
    reportResult.reportParams,
    storageConfigKey
  )

  const showEmptyState = isEmpty(data) && !isLoading
  const { setColumnFilterVisibility } = useReportFiltersContext()

  useEffect(() => {
    if (!isEmpty(columnFilters) && !isLoading) {
      setColumnFilterVisibility((prevState) => {
        return Object.entries(columnFilters).reduce((acc, [key, value]) => {
          return {
            ...acc,
            [key]: {
              ...(acc?.[key] || {}),
              ...(prevState?.[key] || {}),
              ...value
            }
          }
        }, {})
      })
    }
  }, [isLoading, columnFilters, setColumnFilterVisibility])

  useSetTableProps({
    data,
    isLoading,
    columns,
    tableProps,
    setTableProps: setPerformanceTableProps
  })

  return (
    <>
      <OperationalTable
        autoSticky
        mode='auto'
        columns={columns}
        data={data ?? []}
        defaultPageSize={defaultPageSize}
        defaultSort={defaultSort}
        itemName={itemName}
        loading={isLoading || externalIsLoading}
        total={total}
        onSortingChange={onSortingChange}
        onPagingChange={onPagingChange}
        onTableModeChange={onTableModeChange}
        hideFooter={showEmptyState}
        footNote='The page displays your first 1,000 results, while any filters or sorts are applied to the entire dataset. Please export this table to view all rows at once.'
      />
      {showEmptyState && <ReportResultsEmptyState />}
    </>
  )
}

ReportResultsTable.propTypes = {
  reportResult: reportResultShape,
  reportParams: reportParamsShape,
  defaultPageSize: PropTypes.number,
  isLoading: PropTypes.bool
}

ReportResultsTable.defaultProps = {
  pageSize: 100
}

ReportResultsTable.Wrapper = OperationalTable.Wrapper
ReportResultsTable.SuperHeader = OperationalTable.SuperHeader

export default ReportResultsTable
