import React, { useMemo, useRef } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import SydButton from '../../../../../commonDesign/Button'
import { usePolicy } from '../../../../../../hooks/usePolicy'
import {
  useAssignClientAccountGroup,
  useAssignClientAccountGroupAccounts,
  useCreateClientAccountGroup, useRenameClientAccountGroup
} from '../../../../../../api/groups'
import GroupCard from '../GroupCard'
import ActionMenu from '../ActionMenu'
import NewGroupDialog from './NewGroupDialog'
import AddGroupDialog from './AddGroupDialog'
import ManageMembershipDialog from './ManageMembershipDialog'
import RenameGroupDialog from './RenameGroupDialog'

const useStyles = makeStyles((theme) => ({
  groupList: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.layout.gap.medium
  }
}))

function GroupMenu ({ group, renameRef }) {
  const actions = useMemo(() => {
    return [
      { icon: 'edit', label: 'Rename', action: () => renameRef.current.open(group) }
    ]
  }, [group, renameRef])

  return (
    <ActionMenu actions={actions} />
  )
}

GroupMenu.propTypes = {
  group: PropTypes.any,
  renameRef: PropTypes.any
}

function Accounts ({ accounts }) {
  const filteredAccounts = useMemo(() => {
    return accounts.filter(x => x.isAssigned)
  }, [accounts])

  if (!filteredAccounts.length) {
    return (
      <div className='__accounts'>
        <div className='__missing'>
          &lt;No Client Accounts Assigned&gt;
        </div>
      </div>
    )
  }

  return (
    <div className='__accounts'>
      <table className='__account'>
        <tbody>
          {filteredAccounts.map(a => (
            <tr key={a.accountId}>
              <td>{a.accountNumber}</td>
              <td>{a.accountName}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

Accounts.propTypes = {
  accounts: PropTypes.arrayOf(PropTypes.shape({
    accountId: PropTypes.number,
    accountName: PropTypes.string,
    accountNumber: PropTypes.string,
    ordinal: PropTypes.number
  }))
}

function Groups ({ groups, groupType, manageRef, renameRef, canEdit }) {
  if (!groups.length) {
    return (
      <div className='__missing'>
        &lt;Not Assigned&gt;
      </div>
    )
  }

  return (
    <div className='__groups'>
      {groups.map(g => (
        <div className='__account-group' key={g.groupId}>
          <header className='__account-group-header'>
            <div className='__header-group'>
              <div>{g.longName}</div>
              {canEdit ? (
                <div>
                  <SydButton
                    size='xs' icon='folder'
                    onClick={() => manageRef.current.open(groupType, g)}
                  >Manage
                  </SydButton>
                </div>
              ) : null}
            </div>
            {canEdit ? (
              <div className='__header-group'>
                <GroupMenu group={g} renameRef={renameRef} />
              </div>
            ) : null}
          </header>
          <Accounts accounts={g.accounts} />
        </div>
      ))}
    </div>
  )
}

Groups.propTypes = {
  groups: PropTypes.arrayOf(PropTypes.shape({
    groupId: PropTypes.number,
    shortName: PropTypes.string,
    longName: PropTypes.string
  })),
  groupType: PropTypes.shape({
    longName: PropTypes.string
  }),
  manageRef: PropTypes.any,
  renameRef: PropTypes.any,
  canEdit: PropTypes.bool
}

function Hierarchy ({ client, groupTypes }) {
  const classes = useStyles()
  const canEdit = usePolicy('admin_edit_client_acct_tags')
  const canAdd = usePolicy('admin_assign_client_acct_groups')
  const { mutateAsync: createGroup } = useCreateClientAccountGroup()
  const { mutateAsync: addGroup } = useAssignClientAccountGroup()
  const { mutateAsync: assignAccounts } = useAssignClientAccountGroupAccounts()
  const { mutateAsync: renameGroup } = useRenameClientAccountGroup()
  const newGroupRef = useRef()
  const addGroupRef = useRef()
  const manageRef = useRef()
  const renameRef = useRef()

  return (
    <div className={classes.groupList}>
      {groupTypes.map(gt => (
        <GroupCard
          className={classes.card}
          key={gt.groupTypeId}
          title={gt.longName}
          description={gt.description}
          showActions={canEdit}
          outstandingAccounts={gt.outstandingAccounts}
          actions={(
            <>
              {canAdd ? (<SydButton size='xs' icon='search' onClick={() => addGroupRef.current.open(gt)}>Add</SydButton>) : null}
              <SydButton size='xs' icon='add' onClick={() => newGroupRef.current.open(gt)}>New</SydButton>
            </>
          )}
        >
          <Groups
            groups={gt.groups}
            groupType={gt}
            manageRef={manageRef}
            renameRef={renameRef}
            canEdit={canEdit}
          />
        </GroupCard>
      ))}
      <NewGroupDialog ref={newGroupRef} client={client} onCreate={createGroup} />
      <AddGroupDialog ref={addGroupRef} client={client} onAdd={addGroup} />
      <RenameGroupDialog ref={renameRef} client={client} onComplete={renameGroup} />
      <ManageMembershipDialog ref={manageRef} client={client} onComplete={assignAccounts} />
    </div>
  )
}

Hierarchy.propTypes = {
  client: PropTypes.shape({
    longName: PropTypes.string
  }),
  groupTypes: PropTypes.arrayOf(PropTypes.shape({
    groupTypeId: PropTypes.number,
    shortName: PropTypes.string,
    longName: PropTypes.string,
    uniqueMembers: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
    clientSpecific: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
    groups: PropTypes.arrayOf(PropTypes.shape({
      groupId: PropTypes.number,
      shortName: PropTypes.string,
      longName: PropTypes.string,
      accounts: PropTypes.arrayOf(PropTypes.shape({
        accountId: PropTypes.number,
        accountName: PropTypes.string,
        accountNumber: PropTypes.string,
        ordinal: PropTypes.number
      }))
    }))
  }))
}

export default Hierarchy
