import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import {
  Grid,
  Box,
  FormLabel,
  makeStyles,
  useTheme
} from '@material-ui/core'
import dayjs from 'dayjs'

import utc from 'dayjs/plugin/utc'
import Text from '../../../../atoms/Text'
import Dialog from '../../../../molecules/Dialog/Dialog'
import RoundedButton from '../../../../atoms/RoundedButton'
import DatePicker from '../../../../molecules/Datepicker'

import { BUTTON_SIZES, INTERNAL_DATE_FORMAT } from '../../../../../constants'
import { getNewGroup } from '../utils'

dayjs.extend(utc)

const useStyles = makeStyles((theme) => ({
  dialogHeader: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '0',
      paddingTop: '0',
      '& > h2 > p': {
        fontSize: '1.125rem !important'
      }
    }
  },
  dialogActions: {
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      '& > div': {
        flexDirection: 'column-reverse'
      }
    }
  },
  dialogBody: {
    padding: '0 5rem',
    marginTop: '2.5rem',
    [theme.breakpoints.down('sm')]: {
      padding: '0.5rem 1.5rem'
    }
  },
  label: {
    color: theme.palette.darkJungle,
    marginBottom: '0.5rem',
    fontSize: '0.75rem',
    lineHeight: '19px',
    fontWeight: 'bold',
    display: 'block',
    textAlign: 'left'
  }
}))

function SetEndDateDialog ({
  setGroups,
  openedDialog,
  onCloseDialog,
  disabledDateRange,
  onChangeEffectiveDate
}) {
  const classes = useStyles()
  const theme = useTheme()
  const [endDate, setEndDate] = useState(null)

  const onSetEndDate = useCallback((value) => {
    setEndDate(dayjs.utc(value).format(INTERNAL_DATE_FORMAT))
  }, [])

  const onSaveEndDate = useCallback(() => {
    setGroups(prevGroups => {
      const lastGroup = { ...prevGroups[prevGroups.length - 1], endDate }
      const newGroup = getNewGroup()
      return [...prevGroups.slice(0, -1), lastGroup, newGroup]
    })
    onChangeEffectiveDate(true)
    setEndDate(null)
    onCloseDialog()
  }, [setGroups, endDate, onCloseDialog, onChangeEffectiveDate])

  return (
    <Dialog size='sm' open={openedDialog} onClose={onCloseDialog}>
      <Dialog.Header className={classes.dialogHeader}>
        <Text
          text='Add Group'
          color={theme.palette.summitBlue}
          customFontSize='1.5rem'
          customFontWeight='900'
        />
      </Dialog.Header>
      <Dialog.Body className={classes.dialogBody}>
        <Grid container>
          <Box marginBottom='3rem'>
            <Text color={theme.palette.summitBlue} customFontSize='1rem'>
              To begin, first provide the <b>End Date</b> for the previous
              benchmark group.
            </Text>
          </Box>
          <Box width='100%' marginBottom='5rem'>
            <FormLabel className={classes.label}>End Date</FormLabel>
            <DatePicker
              disableFuture
              value={endDate}
              onChange={(value) => onSetEndDate(value)}
              textValue={endDate ?? '--'}
              disabledDateRange={disabledDateRange}
            />
          </Box>
        </Grid>
      </Dialog.Body>
      <Dialog.Actions className={classes.dialogActions}>
        <Grid container spacing={4} justifyContent='center'>
          <Grid item lg={6} xs={12}>
            <RoundedButton
              fullWidth
              onClick={onCloseDialog}
              size={BUTTON_SIZES.large}
            >
              Cancel
            </RoundedButton>
          </Grid>
          <Grid item lg={6} xs={12}>
            <RoundedButton
              primary
              fullWidth
              size={BUTTON_SIZES.large}
              disabled={!endDate}
              onClick={onSaveEndDate}
            >
              Save
            </RoundedButton>
          </Grid>
        </Grid>
      </Dialog.Actions>
    </Dialog>
  )
}

SetEndDateDialog.propTypes = {
  setGroups: PropTypes.func,
  openedDialog: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func,
  onChangeEffectiveDate: PropTypes.func,
  disabledDateRange: PropTypes.object
}

export default SetEndDateDialog
