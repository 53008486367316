import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import FadeIn from '../../../../molecules/Transitions/FadeIn'
import ClientsListContextProvider from './ClientsListContext'
import ClientsTableWrapper from './tableWrapper'
import ClientsListHeader from './header'
import ClientsTable from './table'
import { defaultColumnConfig, ADMIN_CLIENTS_PATH } from './helpers'

function ClientListTab ({ urlFiltersMap }) {
  const history = useHistory()
  const onClickRow = useCallback((row) => {
    if (row.original) {
      history.push(`${ADMIN_CLIENTS_PATH}/${row.original.clientId}`)
    }
  }, [history])

  return (
    <FadeIn>
      <ClientsListContextProvider
        urlFiltersMap={urlFiltersMap}
        defaultColumnConfig={defaultColumnConfig}
      >
        <ClientsTableWrapper>
          <ClientsTable
            header={<ClientsListHeader />}
            onClickRow={onClickRow}
          />
        </ClientsTableWrapper>
      </ClientsListContextProvider>
    </FadeIn>
  )
}

ClientListTab.propTypes = {
  urlFiltersMap: PropTypes.object
}

ClientListTab.defaultProps = {
  urlFiltersMap: { a: { filterKey: 'groupIds', op: 'eq' } }
}

export default ClientListTab
