import React from 'react'
import PropTypes from 'prop-types'
import { usePolicy } from '../../../../hooks/usePolicy'
import StyledButton from '../shared/StyledButton'

function DeleteButton ({ policy, onClick, disabled, style, text }) {
  const allowed = usePolicy(policy)

  if (!allowed) return null

  return (
    <StyledButton
      priority='danger'
      icon='delete'
      disabled={disabled}
      variant='outlined'
      onClick={onClick}
    >
      {text}
    </StyledButton>
  )
}

DeleteButton.propTypes = {
  text: PropTypes.string,
  policy: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  style: PropTypes.object
}

DeleteButton.defaultProps = {
  text: 'Delete',
  policy: null,
  disabled: false
}

export default DeleteButton
