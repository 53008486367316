import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import ViewTabs from '../../../organisms/AdvisorView/components/AdvisorViewTabs'
import { usePolicies } from '../../../../hooks'
import IndexViewActions from './IndexViewActions'

const tabOptions = {
  dataIn: {
    value: 'dataIn',
    label: 'Data In'
  },
  dataOut: {
    value: 'dataOut',
    label: 'Data Out'
  },
  denali: {
    value: 'denali',
    label: 'Denali'
  }
}

const useTabOptions = () => {
  const policies = usePolicies()
  return useMemo(() => {
    const result = {}
    if (policies.data_home_view_data_in) {
      result.dataIn = tabOptions.dataIn
    }
    if (policies.data_home_view_data_out) {
      result.dataOut = tabOptions.dataOut
    }
    if (policies.data_home_view_denali) {
      result.denali = tabOptions.denali
    }

    return result
  }, [policies])
}

function IndexViewTabs ({
  onTabChange,
  disabled,
  root,
  dataInTab,
  dataOutTab,
  denaliTab
}) {
  const tabs = useTabOptions()

  return (
    <div>
      <ViewTabs.Group tabOptions={tabs} onChange={onTabChange}>
        <div>
          <ViewTabs.Links
            disabled={disabled}
            tabsKey='data_home_index'
            rightAdornment={<IndexViewActions root={root} />}
            noHistory
          />
        </div>
        {tabs.dataIn ? (
          <ViewTabs.Tab value='dataIn'>
            {dataInTab}
          </ViewTabs.Tab>
        ) : null}
        {tabs.dataOut ? (
          <ViewTabs.Tab value='dataOut'>
            {dataOutTab}
          </ViewTabs.Tab>
        ) : null}
        {tabs.denali ? (
          <ViewTabs.Tab value='denali'>
            {denaliTab}
          </ViewTabs.Tab>
        ) : null}
      </ViewTabs.Group>
    </div>
  )
}

IndexViewTabs.propTypes = {
  onTabChange: PropTypes.func,
  disabled: PropTypes.bool,
  root: PropTypes.string,
  dataInTab: PropTypes.node.isRequired,
  dataOutTab: PropTypes.node.isRequired,
  denaliTab: PropTypes.node.isRequired
}

IndexViewTabs.defaultProps = {
  onTabChange: () => {},
  disabled: false
}

export default IndexViewTabs
