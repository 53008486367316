import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { Box, makeStyles } from '@material-ui/core'
import { isArray, isEmpty } from 'lodash'
import { Skeleton } from '@material-ui/lab'
import { BUTTON_VARIANT, CALC_TYPES, LEVEL_TYPES } from '../../constants'
import { useFetchState } from '../../hooks'
import useFetchAssets from '../../hooks/useFetchAssets'
import fastDeepEqual from '../../utils/fastDeepEqual'
import RoundedButton from '../atoms/RoundedButton'
import Tag from '../atoms/Tag'
import WebOnlyContent from '../atoms/WebOnlyContent'
import PieProgressBars from './PieProgressBars'

const useStyles = makeStyles(() => ({
  pieChartToggleButton: {
    alignSelf: 'center',
    marginTop: '3rem'
  }
}))

const mapToggleAttribute = (attribute, showPrimary) => {
  if (isArray(attribute)) {
    const [primaryAttribute, secondaryAttribute] = attribute
    return showPrimary ? primaryAttribute : secondaryAttribute
  }
  return attribute
}

const PortfolioAllocationPieProgressChart = ({
  viewContext,
  setViewContext,
  showFilteredTag,
  fetchParams,
  toggleTitles,
  toggleButtonLabels,
  pieProgressBarsChartProps,
  showLegends
}) => {
  const classes = useStyles()
  const [isLoading, setIsLoading] = useState(true)
  const { baseFetchAssets } = useFetchAssets()

  const fetchPieChartData = useCallback(
    async (setSafeState) => {
      setIsLoading(true)
      const levelTypes =
        viewContext.assetClassType === 'class'
          ? [LEVEL_TYPES.ASSET_CLASS_TAG]
          : [LEVEL_TYPES.SUBCLASS_TAG]

      const data = await baseFetchAssets({
        startDate: viewContext.mainDate,
        endDate: viewContext.mainDate,
        levelTypes,
        calcType: CALC_TYPES.balance,
        ...(isEmpty(fetchParams) ? {} : fetchParams)
      })
      setIsLoading(false)
      setSafeState({
        pieChartData: data.sort(
          (a, b) => parseFloat(b.ordinal) + parseFloat(a.ordinal)
        )
      })
    },
    [
      baseFetchAssets,
      fetchParams,
      viewContext.assetClassType,
      viewContext.mainDate
    ]
  )

  const { pieChartData = [] } = useFetchState(fetchPieChartData)

  if (isLoading) {
    return (
      <Box display='flex' flexDirection='column' alignItems='center'>
        <Box alignSelf='flex-start'>
          <Skeleton variant='text' width='8rem' height='3rem' />
        </Box>
        <Skeleton variant='circle' width='16rem' height='16rem' />
        <Box marginTop='3rem'>
          <Skeleton variant='rect' width='10rem' height='2rem' />
        </Box>
      </Box>
    )
  }

  return (
    <Box display='flex' flexDirection='column'>
      <PieProgressBars
        pie
        showLegends={viewContext.assetClassType === 'class' || showLegends}
        activeId={viewContext.assetClassId}
        title={mapToggleAttribute(
          toggleTitles,
          viewContext.assetClassType === 'class'
        )}
        progressBarsDataSet={pieChartData.map((assetClass) => {
          return {
            id: assetClass.assetClassId,
            balance: assetClass.balance,
            color: assetClass.color,
            title: assetClass.name || assetClass.names?.tagName,
            percentage: assetClass?.assetClassPercentage
              ? assetClass.assetClassPercentage * 100 || 0
              : 0,
            ordinal: assetClass.ordinal
          }
        })}
        assetClassType={viewContext.assetClassType}
        showTitleAdornment={false}
        titleAdornment={showFilteredTag && <Tag label='Filtered' />}
        {...pieProgressBarsChartProps}
      />

      <WebOnlyContent>
        <RoundedButton
          variant={BUTTON_VARIANT.text}
          className={classes.pieChartToggleButton}
          onClick={() =>
            setViewContext({
              assetClassType:
                viewContext.assetClassType === 'class' ? 'subclass' : 'class'
            })}
        >
          {mapToggleAttribute(
            toggleButtonLabels,
            viewContext.assetClassType === 'class'
          )}
        </RoundedButton>
      </WebOnlyContent>
    </Box>
  )
}

PortfolioAllocationPieProgressChart.defaultProps = {
  pieProgressBarsChartProps: {}
}

PortfolioAllocationPieProgressChart.propTypes = {
  viewContext: PropTypes.object,
  setViewContext: PropTypes.func,
  showFilteredTag: PropTypes.bool,
  fetchParams: PropTypes.object,
  toggleTitles: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string)
  ]),
  toggleButtonLabels: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string)
  ]),
  pieProgressBarsChartProps: PropTypes.object,
  showLegends: PropTypes.bool
}

export default React.memo(PortfolioAllocationPieProgressChart, (a, b) =>
  fastDeepEqual(a, b, { compareFunctions: false })
)
