import React, { useCallback } from 'react'
import { makeStyles } from '@material-ui/core'
import ViewTypeToggle from '../../ClientCardsViewV2/components/ViewTypeToggle'
import { useTasksContext } from '../TasksContext'
import AsOfDatePicker from './AsOfDatePicker'
import TasksFeaturedFilters from './TasksFeaturedFilters'

const useStyles = makeStyles(() => ({
  header: {
    padding: '1rem 0 0 0.5rem',
    '& .__section': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center'
    },
    '& .__featuredFilters': {
      padding: '1rem 0'
    }
  }
}))

const TasksViewHeader = () => {
  const classes = useStyles()
  const { viewType, setViewType } = useTasksContext()

  const onToggleViewType = useCallback(
    (_viewType) => {
      if (!_viewType) return
      if (_viewType !== viewType) {
        setViewType(_viewType)
      }
    },
    [viewType, setViewType]
  )

  return (
    <div className={classes.header}>
      <div className='__section'>
        <AsOfDatePicker />
        <ViewTypeToggle value={viewType} onChange={onToggleViewType} />
      </div>
      <div className='__featuredFilters'>
        <TasksFeaturedFilters />
      </div>
    </div>
  )
}

export default TasksViewHeader
