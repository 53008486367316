import React from 'react'
import { Box, makeStyles } from '@material-ui/core'
import SearchBarInput from '../../../../molecules/SearchBar/SearchBarInput'
import { useUsersListContext } from './UserListContext'

const useStyles = makeStyles(() => ({
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  content: {
    display: 'flex'
  },
  filters: {
    display: 'flex',
    alignItems: 'center',
    padding: '0.75rem 0'
  },
  searchInput: {
    marginBottom: '0.5rem'
  },
  switchContainer: {
    display: 'flex',
    marginLeft: 'auto',
    gap: '1rem',
    padding: '0 1rem 0 0',
    alignItems: 'center'
  }
}))

const UsersListHeader = () => {
  const classes = useStyles()

  const {
    isLoading,
    searchText,
    operationalTable: { onSearchTextChange }
  } = useUsersListContext()

  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <Box width='20rem'>
          <SearchBarInput
            value={searchText}
            loading={isLoading}
            setValue={onSearchTextChange}
            searchInputClassName={classes.searchInput}
            placeholder='Search Users'
          />
        </Box>
      </div>
    </div>
  )
}

UsersListHeader.propTypes = {
}

UsersListHeader.defaultProps = {
  tableOptions: {
    hideGroupingsUnderline: true,
    hideCustomizeColumnsDateRangeSelectors: true,
    hideCustomizeColumnsColumnHiding: false,
    hideCustomizeColumnsButton: false,
    hideExpandCollapseButton: true,
    hideExportButton: false
  }
}

export default UsersListHeader
