import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import LabeledValue from '../components/LabeledValue'

const useStyles = makeStyles((theme) => ({
  destination: {}
}))

const DestinationDetail = ({ destination }) => {
  const classes = useStyles()

  if (!destination) return null

  return (
    <div className={classes.destination}>
      <h3>Destination</h3>
      <div>
        <LabeledValue label='Name'>{destination.name}</LabeledValue>
        <LabeledValue label='Schema'>{destination.schema}</LabeledValue>
        <LabeledValue label='Table'>{destination.table}</LabeledValue>
        <LabeledValue label='Fields'>
          <ul>
            {destination.fields.map(field => (
              <li key={field}>{field}</li>
            ))}
          </ul>
        </LabeledValue>
      </div>
    </div>
  )
}

DestinationDetail.propTypes = {
  destination: PropTypes.shape({
    name: PropTypes.string,
    schema: PropTypes.string,
    table: PropTypes.string,
    fields: PropTypes.arrayOf(PropTypes.string)
  })
}

export default DestinationDetail
