import React from 'react'
import PropTypes from 'prop-types'
import { Button, makeStyles } from '@material-ui/core'
import noop from 'lodash/noop'
import Icon from '../../atoms/Icon'
import { ICON_NAMES } from '../../../constants'

const useStyles = makeStyles((theme) => ({
  pagingButtons: {
    display: 'flex',
    gap: '1rem',
    '& .__navigation-item span': {
      fontSize: '0.875rem',
      fontWeight: 'bold',
      color: '#212945',
      textTransform: 'capitalize'
    },
    '& .__navigation-item button': {
      padding: '6px 12px'
    },
    '& .__navigation-item button[disabled]': {
      opacity: '0.6'
    }
  }
}))

const PagingButtons = ({
  hasPrev,
  hasNext,
  onPrev,
  onNext
}) => {
  const classes = useStyles()

  return (
    <div className={classes.pagingButtons}>
      <div className='__navigation-item'>
        <Button
          disabled={!hasPrev}
          onClick={onPrev}
          startIcon={<Icon name={ICON_NAMES.arrowBack} color='#212945' />}
        >
          Prev
        </Button>
      </div>
      <div className='__navigation-item'>
        <Button
          disabled={!hasNext}
          onClick={onNext}
          endIcon={<Icon name={ICON_NAMES.arrowForward} color='#212945' />}
        >
          Next
        </Button>
      </div>
    </div>
  )
}

PagingButtons.propTypes = {
  hasPrev: PropTypes.bool,
  hasNext: PropTypes.bool,
  onPrev: PropTypes.func,
  onNext: PropTypes.func
}

PagingButtons.defaultProps = {
  onPrev: noop,
  onNext: noop
}

export default PagingButtons
