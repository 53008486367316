import React from 'react'
import PropTypes from 'prop-types'
import OperationalTable, { useOperationalTable } from '../../../components/organisms/OperationalTable'

const columnConfig = [
  { Header: 'ID', accessor: 'levelId', type: 'string' },
  { Header: 'Acct', accessor: 'accountName', type: 'string' },
  { Header: 'Acct#', accessor: 'accountNumber', type: 'string' },
  { Header: 'Tax Status', accessor: 'taxStatusId', type: 'string' },
  { Header: 'Quantity', accessor: 'quantity', type: 'number' },
  { Header: 'Unit Type', accessor: 'unitType', type: 'string' },
  { Header: 'Custodian', accessor: 'custodianName', type: 'string' }
]

const defaultSort = [{ id: 'levelId', desc: false }]

const WithdrawalResultsTable = ({ data, isLoading }) => {
  useOperationalTable({ defaultSort })

  return (
    <OperationalTable.Wrapper>
      <OperationalTable
        mode='client'
        columns={columnConfig}
        defaultSort={defaultSort}
        data={data}
        total={data?.length}
        hideFooter
        loading={isLoading}
      />
    </OperationalTable.Wrapper>
  )
}
WithdrawalResultsTable.propTypes = {
  data: PropTypes.array,
  isLoading: PropTypes.bool
}

export default WithdrawalResultsTable
