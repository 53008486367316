import React from 'react'
import PropTypes from 'prop-types'
import SydModal from '../../../commonDesign/SydModal'
import AccountsList from './AccountsList'

const ClientModalAccounts = ({
  handleClose,
  open,
  modalTitle = 'Accounts',
  modalSubtitle = null,
  columns,
  filters = {},
  defaultSort
}) => {
  return (
    <SydModal
      open={open}
      onClose={handleClose}
      title={modalTitle}
      subtitle={modalSubtitle ?? ''}
      disablePortal={false}
      allowMaximize
      size='xmedium'
      bodyPadding='0 28px 28px'
    >
      <AccountsList
        filters={filters}
        columns={columns}
        defaultSort={defaultSort}
      />
    </SydModal>
  )
}

ClientModalAccounts.propTypes = {
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  modalTitle: PropTypes.string,
  modalSubtitle: PropTypes.string,
  filters: PropTypes.object,
  columns: PropTypes.array,
  defaultSort: PropTypes.array
}

export default ClientModalAccounts
