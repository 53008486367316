import React, { useEffect, useState } from 'react'

import { makeStyles } from '@material-ui/core'
import { ADMIN_ROUTES, TEXT_VARIANTS } from '../../../../constants'
import Text from '../../../atoms/Text'

const useStyles = makeStyles(() => ({

  titleContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    padding: '1rem 0 0',
    gap: '1rem',
    '& a': {
      opacity: 0.25,
      '&.active': {
        opacity: 1
      }
    }
  }

}))

const ReportSectionHeader = () => {
  const classes = useStyles()
  const [activeTab, setActiveTab] = useState('reports')

  useEffect(() => {
    const path = window.location.pathname
    if (path.indexOf(ADMIN_ROUTES.REPORTS_BATCHES) >= 0) {
      setActiveTab('batches')
    } else {
      setActiveTab('reports')
    }
  }, [])

  return (
    <div className={classes.titleContainer}>
      <a
        href={ADMIN_ROUTES.REPORTS}
        className={activeTab === 'reports' ? 'active' : undefined}
      >
        <Text
          text='Run Reports'
          variant={TEXT_VARIANTS.h1}

        />
      </a>
      <a
        href={ADMIN_ROUTES.REPORTS_BATCHES}
        className={activeTab === 'batches' ? 'active' : undefined}
      >
        <Text
          text='Batch Runs'
          variant={TEXT_VARIANTS.h1}

        />
      </a>
    </div>
  )
}

export default ReportSectionHeader
