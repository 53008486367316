import React from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { useMediaQuery } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  advisorViewLayoutSecondaryContent: {
    width: '467px',
    backgroundColor: theme.palette.gray.main,
    padding: '1.75rem 1.5rem'
  }
}))

const SecondaryContent = ({ children, className, style }) => {
  const classes = useStyles()
  const theme = useTheme()
  const matchesBreakpoint = useMediaQuery(theme.breakpoints.down('md'))

  if (matchesBreakpoint) {
    return null
  }

  return (
    <div
      className={clsx(classes.advisorViewLayoutSecondaryContent, className)}
      style={style}
    >
      {children}
    </div>
  )
}

SecondaryContent.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  style: PropTypes.object
}

export default SecondaryContent
