import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import FadeIn from '../../components/molecules/Transitions/FadeIn'

const useStyles = makeStyles((theme) => ({
  utilityProgress: {
    display: 'flex',
    height: '100vh',
    width: '100wv',
    backgroundColor: theme.palette.white,
    justifyContent: 'center',
    alignItems: 'center'
  }
}))

function UtilityProgress ({ message }) {
  const classes = useStyles()

  return (
    <FadeIn className={classes.utilityProgress}>
      <h1>{message}</h1>
    </FadeIn>
  )
}

UtilityProgress.propTypes = {
  message: PropTypes.string.isRequired
}

export default UtilityProgress
