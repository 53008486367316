import React, { useMemo } from 'react'
import { Link, useHistory } from 'react-router-dom'
import FadeIn from '../../../molecules/Transitions/FadeIn'
import OperationalTable from '../../../organisms/OperationalTable'
import { ADMIN_ROUTES } from '../../../../constants'
import { useComponentModels } from '../../../../api/rebalancer'

const cellTemplates = {
  link: (accessor) => ({ row }) => (
    <Link
      onClick={(event) => event.stopPropagation()}
      to={`${ADMIN_ROUTES.COMPONENT_MODEL_DETAILS}/${row.original.componentModelId}`}
    >
      {accessor(row.original) || 'Link'}
    </Link>
  )
}

export const defaultColumnConfig = {
  columns: [
    { Header: 'Id', accessor: 'componentModelId', type: 'number', Cell: cellTemplates.link(row => row.componentModelId) },
    { Header: 'Name', accessor: 'name', type: 'string', Cell: cellTemplates.link(row => row.name) },
    { Header: 'Description', accessor: 'description', type: 'string' }
  ],
  defaultSort: [{ id: 'componentModelId', desc: false }]
}

function ComponentModelsListTab () {
  const query = useMemo(() => {
    return { take: 200 }
  }, [])
  const history = useHistory()
  const { data, isLoading } = useComponentModels(query)

  return (
    <FadeIn style={{ marginTop: '20px' }}>
      <OperationalTable
        variant='v2'
        mode='client'
        itemName='Component Models'
        data={data || []}
        loading={isLoading}
        total={data?.length}
        columns={defaultColumnConfig.columns}
        defaultSort={defaultColumnConfig.defaultSort}
        onRowClick={(row) => history.push(`${ADMIN_ROUTES.COMPONENT_MODEL_DETAILS}/${row.original.componentModelId}`)}
      />
    </FadeIn>
  )
}

ComponentModelsListTab.propTypes = {
}

ComponentModelsListTab.defaultProps = {
}

export default ComponentModelsListTab
