import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import ClientAvatar from '../../../../atoms/ClientAvatar'

const useStyles = makeStyles((theme) => ({
  item: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: '.5rem'
  },
  icon: {
    marginRight: '1rem'
  },
  name: {
    fontSize: '1rem',
    fontWeight: '600'
  }
}))

const ClientItem = ({ client }) => {
  const classes = useStyles()

  return (
    <div className={classes.item}>
      <div className={classes.icon}>
        <ClientAvatar
          client={client}
          src={client.profilePic}
        />
      </div>
      <div className={classes.name}>{client.familyName}</div>
    </div>
  )
}

ClientItem.propTypes = {
  client: PropTypes.object.isRequired
}

export default ClientItem
