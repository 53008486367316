import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Chip } from '@material-ui/core'
import { EXPOSURE_TYPES } from '../helpers'
import AssetExposurePicker from './AssetExposurePicker'
import ClassificationTagExposurePicker from './ClassificationTagExposurePicker'

const EXPOSURE_PICKER_COMPONENTS = {
  [EXPOSURE_TYPES.ASSET]: AssetExposurePicker,
  [EXPOSURE_TYPES.CLASSIFICATION_TAG]: ClassificationTagExposurePicker
}

const getExposurePicker = (exposureType) => EXPOSURE_PICKER_COMPONENTS[exposureType]

const ExposurePicker = ({
  exposureType,
  exposureTarget,
  limitTags,
  renderTagsMaxText,
  setExposureTarget,
  onChange,
  value,
  hasError
}) => {
  const ExposurePickerType = useMemo(
    () => getExposurePicker(exposureType),
    [exposureType]
  )

  const renderTags = useCallback((value, getTagProps) => {
    return value.map((v, index) => {
      const label = (() => {
        const l = v.label
        if (l.length > renderTagsMaxText) {
          return `${l.slice(0, renderTagsMaxText + 1)}...`
        }

        return l
      })()

      return (<Chip {...getTagProps({ index })} label={label} key={label} size='medium' />)
    })
  }, [renderTagsMaxText])

  return (
    <ExposurePickerType
      value={value}
      hasError={hasError}
      onChange={onChange}
      limitTags={limitTags}
      renderTags={renderTags}
      setExposureTarget={setExposureTarget}
    />
  )
}

ExposurePicker.propTypes = {
  exposureType: PropTypes.string,
  exposureTarget: PropTypes.array,
  setExposureTarget: PropTypes.func,
  renderTagsMaxText: PropTypes.number,
  onChange: PropTypes.func,
  limitTags: PropTypes.number,
  value: PropTypes.array,
  hasError: PropTypes.bool
}

ExposurePicker.defaultProps = {
  renderTagsMaxText: 10,
  limitTags: 2,
  hasError: false
}

export default ExposurePicker
