import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { Box } from '@material-ui/core'
import isEmpty from 'lodash/isEmpty'
import { useHistory } from 'react-router-dom'
import { useMilestonesContext } from '../../MilestonesContext'
import ViewBlocks from '../../../ViewListAndBlockComponents/ViewBlocks'
import MilestonesFilters from '../MilestonesFilters'
import MilestonesEmptyState from '../MilestonesEmptyState'
import { useSetCurrentClient } from '../../../../../redux/slices/appContext'
import MilestoneCard from './MilestoneCard'

const MilestonesCards = ({ minColumnWidth, rowHeight }) => {
  const history = useHistory()
  const setCurrentClient = useSetCurrentClient()
  const {
    isLoading,
    paginationData,
    infiniteMilestones,
    fetchNextMilestonesPage,
    milestoneClickRedirectTo
  } = useMilestonesContext()

  const onClickMilestone = useCallback((milestone) => {
    if (milestoneClickRedirectTo) {
      milestone?.client && setCurrentClient(milestone.client.clientId)
      history.push(milestoneClickRedirectTo + `?milestoneId=${milestone.entryId}`)
    }
  }, [history, setCurrentClient, milestoneClickRedirectTo])

  const cellComponent = useCallback(
    (milestone) => {
      return (
        <MilestoneCard
          milestone={milestone}
          onClick={() => onClickMilestone(milestone)}
        />
      )
    },
    [onClickMilestone]
  )

  return (
    <ViewBlocks
      header={
        <>
          <Box top={0} zIndex={1} position='sticky' bgcolor='#FFF'>
            <MilestonesFilters />
          </Box>
          {!isLoading && isEmpty(infiniteMilestones) && (
            <MilestonesEmptyState />
          )}
        </>
      }
      data={infiniteMilestones}
      useCellMeasurer
      rowHeight={rowHeight}
      isLoading={isLoading}
      minColumnWidth={minColumnWidth}
      rowCount={infiniteMilestones.length}
      fetchNextPage={fetchNextMilestonesPage}
      cellComponent={cellComponent}
      pageSize={paginationData.take}
    />
  )
}

MilestonesCards.propTypes = {
  minColumnWidth: PropTypes.number,
  rowHeight: PropTypes.number
}

MilestonesCards.defaultProps = {
  minColumnWidth: 270,
  rowHeight: 330
}

export default MilestonesCards
