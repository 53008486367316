import React, { useCallback, useMemo } from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import advanced from 'dayjs/plugin/advancedFormat'
import { Tooltip, makeStyles } from '@material-ui/core'
import Icon from '../../../atoms/Icon'
import { ICON_NAMES } from '../../../../constants'

dayjs.extend(timezone)
dayjs.extend(utc)
dayjs.extend(advanced)

const useStyles = makeStyles((theme) => ({
  meeting: {
    background: '#ffffff',
    borderRadius: '8px',
    padding: '0.875rem 1.5rem',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    border: '1px solid #e0e0e0'
  },
  meetingIcon: {
    marginRight: '1rem'
  },
  name: {
    fontSize: '1rem',
    fontWeight: '600'
  },
  meetingTime: {
    '& > *': {
      fontSize: '0.8rem',
      color: '#999999',
      marginRight: '.5rem'
    }
  },
  clickableMeeting: {
    cursor: 'pointer',
    transition: 'border 0.3s linear'
  }
}))

const Meeting = ({ meeting, linkBaseUrl }) => {
  const classes = useStyles()
  const { start, end, date, timezone, meetingId, url } = useMemo(() => {
    return {
      meetingId: meeting.meetingId,
      date: dayjs(meeting.startDate).format('ddd M/DD'),
      start: dayjs(meeting.startDate).format('h:mm'),
      end: dayjs(meeting.endDate).format('h:mm'),
      timezone: dayjs(meeting.endDate).format('z'),
      url: linkBaseUrl ? `${linkBaseUrl}/${meeting.meetingId}/view` : null
    }
  }, [meeting, linkBaseUrl])

  const isClickable = useMemo(
    () => meetingId && linkBaseUrl,
    [meetingId, linkBaseUrl]
  )

  const onClickMeetingHandler = useCallback(() => {
    if (isClickable) {
      window.open(url, '_blank', 'noreferrer')
    }
  }, [isClickable, url])

  return (
    <Tooltip title={meeting.subject}>
      <div
        className={clsx(
          classes.meeting,
          isClickable ? classes.clickableMeeting : null
        )}
        onClick={onClickMeetingHandler}
      >
        <div className={classes.meetingIcon}>
          <Icon name={ICON_NAMES.calendar} customSize='1.5rem' />
        </div>
        <div>
          <div className={classes.name}>{meeting?.client?.longName || ''}</div>
          <div className={classes.meetingTime}>
            <span>{date}</span>
            <span>
              {start} - {end} {timezone}
            </span>
          </div>
        </div>
      </div>
    </Tooltip>
  )
}

Meeting.propTypes = {
  meeting: PropTypes.object.isRequired,
  linkBaseUrl: PropTypes.string
}

Meeting.defaultProps = {
  linkBaseUrl: null
}

export default Meeting
