import { useState, useCallback } from 'react'

const useAccordion = (defaultExpanded) => {
  const [expanded, setExpanded] = useState(defaultExpanded ?? false)
  const onChange = useCallback((_, isExpanded) => setExpanded(isExpanded), [setExpanded])

  return {
    expanded,
    onChange,
    defaultExpanded
  }
}

export default useAccordion
