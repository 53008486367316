import React, { forwardRef, useCallback, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import Text from '../../../../atoms/Text'
import RightPanel from '../../../../organisms/RightPanel'
import { TEXT_VARIANTS } from '../../../../../constants'
import { useClientModelInfo } from '../../hooks/useClientModelInfo'
import InstructionsContent from './InstructionsContent'

export const useInstructionsPanel = () => {
  const ref = useRef()
  const onSettingsClick = useCallback(() => {
    if (ref.current) {
      ref.current.open()
    }
  }, [])

  return {
    ref,
    onSettingsClick
  }
}

const InstructionsPanel = forwardRef(({ clientId, title }, ref) => {
  const { client, model, loading } = useClientModelInfo(clientId)
  const [open, setOpen] = useState(false)
  const toggleOpen = useCallback(() => {
    setOpen(o => !o)
  }, [setOpen])
  useEffect(() => {
    if (ref) {
      ref.current = {
        open: () => setOpen(true),
        close: () => setOpen(false)
      }
    }
  }, [ref, setOpen])

  return (
    <RightPanel
      open={open}
      title={<Text text={title} variant={TEXT_VARIANTS.h2} />}
      toggleOpen={toggleOpen}
      width='400px'
      height='100%'
      showCloseButton
    >
      {loading ? (
        <InstructionsContent.Loading />
      ) : (
        <InstructionsContent client={client} model={model} />
      )}
    </RightPanel>
  )
})

InstructionsPanel.propTypes = {
  clientId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  title: PropTypes.string
}

InstructionsPanel.defaultProps = {
  title: 'Model Instructions'
}

export default InstructionsPanel
