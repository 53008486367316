import { useCallback, useEffect, useState } from 'react'
import logo from '../../../assets/summit010720_2.png'
import config from '../../../config'
import { applyBrand } from '../../../applyBrand'

const { REACT_APP_S3_PUBLIC_RESOURCES_URL } = process.env

const DEFAULT_INFO = {
  backgroundPath: '/RiverBack.png',
  logoPath: logo,
  title: 'Connecting your wealth to a life well-lived.',
  brand: {
    firmName: 'Summit Wealth',
    tagLing: 'Connecting your wealth to a life well-lived.'
  }
}

export const useFirmInfo = () => {
  const [firmInfo, setFirmInfo] = useState(null)
  const subdomain = config.subdomain

  const fetchFirmInfo = useCallback(async (subdomain) => {
    try {
      const response = await fetch(`${REACT_APP_S3_PUBLIC_RESOURCES_URL}/${subdomain}/details.json`)
      if (response.status !== 200) {
        setFirmInfo(DEFAULT_INFO)
        return
      }
      const data = await response.json()
      setFirmInfo({
        backgroundPath: data?.backgroundUrl ?? `${REACT_APP_S3_PUBLIC_RESOURCES_URL}/${subdomain}/login-bg.png`,
        logoPath: data?.logoUrl ?? `${REACT_APP_S3_PUBLIC_RESOURCES_URL}/${subdomain}/logo.png`,
        title: data.title,
        brand: data.brand,
        data
      })
    } catch (err) {
      console.error(err)
    }
  }, [setFirmInfo])

  useEffect(() => {
    if (firmInfo?.brand) {
      applyBrand(firmInfo.brand)
    }
  }, [firmInfo])

  useEffect(() => {
    if (subdomain?.includes('localhost')) {
      setFirmInfo(DEFAULT_INFO)
    } else {
      fetchFirmInfo(subdomain).catch(console.error)
    }
  }, [subdomain, fetchFirmInfo])

  return firmInfo
}
