import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import { useSearchAdvisorUsers } from '../../../../../../api/users'
import Loading from '../../../../../molecules/Loading'
import SydSelectBox from '../../../../../commonDesign/SydSelectBox'

const useStyles = makeStyles((theme) => ({
  loader: {
    border: `1px solid ${theme.palette.gray.darker}`,
    borderRadius: theme.layout.radius.loose
  }
}))

const query = {
  take: 1000
}

const labelAccessor = ['label', 'email', 'userType', 'internal']
function AdvisorValueEditor ({ value, onChange, disabled }) {
  const classes = useStyles()
  const { data, isLoading } = useSearchAdvisorUsers(query)
  const options = useMemo(() => {
    return (data || []).map(x => ({
      label: `${x.firstName} ${x.lastName}`,
      internal: x.internal ? '(Internal)' : '',
      email: x.email,
      value: x.externalUserId,
      userType: x.userTypeId === 2 ? 'Advisor' : 'Wealth Owner'
    }))
  }, [data])

  if (isLoading) {
    return (
      <div className={classes.loader}>
        <Loading size={16} />
      </div>
    )
  }

  return (
    <SydSelectBox
      value={value}
      onChange={onChange}
      labelAccessor={labelAccessor}
      options={options}
      disabled={disabled}
    />
  )
}

AdvisorValueEditor.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func,
  disabled: PropTypes.bool
}

export default AdvisorValueEditor
