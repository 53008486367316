import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { isEmpty } from 'lodash'
import CarouselSlider from '../../../../molecules/CarouselSlider'
import GroupTagFiltersSkeleton from '../../../../molecules/GroupTagFilters/GroupTagFiltersSkeleton'
import SelectWithCheckbox from '../../../../molecules/Select/SelectWithCheckbox'
import { modifyByIndex, removeByIndex } from '../../../../../utils'
import { useClassifyAssetsContext } from '../ClassifyAssetsContext'
import { getClassificationTagColumnId } from '../helpers'

const mapTagTypesToOptions = (tagTypes) => {
  if (isEmpty(tagTypes)) return []
  return tagTypes.reduce((acc, { tagTypeId, tagTypeName, tags }) => {
    return {
      ...acc,
      [tagTypeId]: {
        value: tagTypeId,
        label: tagTypeName,
        options: (tags || []).map(({ tagId, tagName }) => ({
          value: tagId,
          label: tagName
        }))
      }
    }
  }, {})
}

const AssetFilterSelectors = ({ disableSlider }) => {
  const {
    assetsFilters,
    visibleFilters,
    onChangeFilters,
    classificationTagTypes,
    isLoadingClassifyAssets: isLoading
  } = useClassifyAssetsContext()

  const onChangeFilter = useCallback(
    (classificationTagType) => (classificationTag) => {
      onChangeFilters((prevState) => {
        const classificationTagIds = classificationTag.map(
          ({ value }) => value
        )
        const classificationFilterIndex =
          prevState?.classificationFilters?.findIndex(
            ({ tagTypeId }) => tagTypeId === classificationTagType.tagTypeId
          )

        let classificationFilters = prevState?.classificationFilters || []

        if (classificationTagIds?.length) {
          classificationFilters = modifyByIndex(
            classificationFilterIndex || 0,
            prevState?.classificationFilters || [],
            {
              tagTypeId: classificationTagType.tagTypeId,
              tagTypeName: classificationTagType.tagTypeName,
              tagIds: classificationTagIds
            }
          )
        } else {
          classificationFilters = removeByIndex(
            classificationFilterIndex || 0,
            prevState?.classificationFilters || []
          )
        }
        return {
          ...prevState,
          classificationFilters
        }
      })
    },
    [onChangeFilters]
  )

  const tagOptions = useMemo(() => {
    return mapTagTypesToOptions(classificationTagTypes)
  }, [classificationTagTypes])

  const renderAssetFilters = useMemo(() => {
    const classificationFilters = assetsFilters?.classificationFilters || []
    return (classificationTagTypes || []).reduce((acc, tagTypeOption) => {
      const { tagTypeName, tagTypeId, payload } = tagTypeOption

      const options = tagOptions[tagTypeId].options

      const selectedOptions = options.filter(({ value }) => {
        return classificationFilters.some(({ tagIds }) =>
          tagIds.includes(value)
        )
      })

      if (isEmpty(options)) return acc

      const tagId = getClassificationTagColumnId(payload)
      if (!visibleFilters.includes(tagId)) return acc

      acc.push(
        <SelectWithCheckbox
          key={tagTypeId}
          placeholder={tagTypeName}
          prefixLabel={tagTypeName}
          options={options}
          onChange={onChangeFilter(tagTypeOption)}
          selectedOptions={selectedOptions}
        />
      )
      return acc
    }, [])
  }, [
    assetsFilters?.classificationFilters,
    classificationTagTypes,
    onChangeFilter,
    visibleFilters,
    tagOptions
  ])

  if (isLoading) {
    return <GroupTagFiltersSkeleton />
  }

  if (disableSlider) {
    return renderAssetFilters
  }

  return <CarouselSlider>{renderAssetFilters}</CarouselSlider>
}

AssetFilterSelectors.propTypes = {
  disableSlider: PropTypes.bool
}

AssetFilterSelectors.defaultProps = {
  disableSlider: false
}

export default AssetFilterSelectors
