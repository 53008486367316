import React from 'react'
import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'
import ClientsList from '../ClientsList'
import { useRecentClients } from '../../../../api/clients'
import { ICON_NAMES } from '../../../../constants'
import AdvisorHomeEmptySection from '../AdvisorHomeEmptySection'

const ClientsRecentActivity = ({ limit, emptySectionLabel }) => {
  const { data = [], isLoading } = useRecentClients(limit)

  if (isEmpty(data) && !isLoading) {
    return (
      <AdvisorHomeEmptySection
        icon={ICON_NAMES.paperGlassMagnifier}
        title='No data available'
        description={emptySectionLabel}
        borderedContent
      />
    )
  }

  return (
    <ClientsList
      clients={data}
      isLoading={isLoading}
    />
  )
}

ClientsRecentActivity.propTypes = {
  limit: PropTypes.number,
  emptySectionLabel: PropTypes.string
}

ClientsRecentActivity.defaultProps = {
  limit: 5,
  emptySectionLabel: ''
}

export default ClientsRecentActivity
