import React, { useMemo } from 'react'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { capitalize } from 'lodash'
import { useFormattingContext } from '../../../../../../organisms/FormattingProvider/FormattingContext'
import { DATE_FORMAT_US_LONG } from '../../../../../../../constants'
import { datePresetOptions } from '../../../../../../molecules/RelativeDateSelect'
import { capitalizeFirstLetter } from '../../../../../../../utils'
import ReportActions from './cells/ReportActions'

dayjs.extend(utc)

const datePresetByKeyValue = datePresetOptions.reduce((acc, { value, label }) => {
  acc[value] = label
  return acc
}, {})

const templates = {
  nonUtcDate: ({ cell }) => {
    if (!cell.value) return '--'

    return dayjs(cell.value).format(`${DATE_FORMAT_US_LONG} hh:mm a`)
  },
  date: ({ cell }) => {
    if (!cell.value) return '--'
    return dayjs.utc(cell.value).format(`${DATE_FORMAT_US_LONG} hh:mm`)
  },
  dateOnly: ({ cell }) => {
    if (!cell.value) return '--'
    return dayjs.utc(cell.value).format(DATE_FORMAT_US_LONG)
  },
  reportActions: ({ row }) => {
    const { original: { bobiOutputId, name } } = row
    return <ReportActions bobiOutputId={bobiOutputId} fileName={name} />
  },
  uppercase: ({ value }) => {
    return capitalize(value)
  },
  filters: ({ value }) => {
    const filterParts = value.split(',').reduce((acc, part) => {
      if (part.indexOf('additional') !== -1) {
        return acc
      }
      acc.push(capitalizeFirstLetter(part.trim()))
      return acc
    }, [])
    return filterParts.join(', ')
  },
  relativeDateRange: ({ value, cell: { row } }) => {
    const { original } = row
    const relativeDateRange = original?.inputParams?.original?.relativeDateRange
    const dateRangeKey = value?.relativeDateRange || relativeDateRange
    const dateRangeLabel = datePresetByKeyValue?.[dateRangeKey]
    return dateRangeLabel
  }
}

export const defaultColumnConfig = {
  columns: [
    { Header: 'Run Date', accessor: 'runDate', id: 'runDate', Cell: templates.nonUtcDate },
    { Header: 'Report Level', accessor: 'levelType', id: 'reportLevel' },
    { Header: 'Filter By', accessor: 'filters', id: 'filterBy', Cell: templates.filters },
    { Header: 'Data Sets', accessor: 'dataSets', id: 'dataSets' },
    { Header: 'Date Range', accessor: 'relativeDateRange', id: 'dateRange', Cell: templates.relativeDateRange },
    { Header: 'As of Date', accessor: 'asOfDate', id: 'asOfDate', Cell: templates.dateOnly },
    { Header: 'Run By', accessor: 'runBy', id: 'runBy' }
  ],
  defaultSort: [{ id: 'runDate', desc: true }]
}

export const useColumns = ({ columnsConfig = defaultColumnConfig }) => {
  const { formatter } = useFormattingContext()

  return useMemo(() => {
    const { columns, defaultSort } = columnsConfig
    const templatedColumns = columns.map((column) => {
      if (column.format) {
        return {
          ...column,
          Cell: ({ value }) => formatter(value, column.format)
        }
      }
      return column
    })

    return {
      columns: templatedColumns,
      defaultSort
    }
  }, [columnsConfig, formatter])
}
