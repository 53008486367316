import React, { useCallback, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Tooltip, makeStyles } from '@material-ui/core'
import { useFormattingContext } from '../FormattingProvider/FormattingContext'

const useStyles = makeStyles((theme) => ({
  metric: {
    userSelect: 'none',
    fontSize: '1.4rem',
    padding: ({ featured }) =>
      featured ? '0rem 2rem 0rem 1rem' : '1.5rem 1rem .5rem',
    '& .__metric-value': {
      fontSize: ({ featured }) => (featured ? '3.4rem' : '1.4rem'),
      textAlign: 'left'
    },
    '& .__metric-name': {
      color: theme.palette.gray.darker,
      fontSize: '.8rem',
      textAlign: 'left'
    },
    borderRadius: '0.25rem',
    '&:hover': {
      cursor: ({ alternateFormat }) => alternateFormat ? 'pointer' : 'inherit',
      backgroundColor: ({ alternateFormat }) => alternateFormat ? 'rgba(0, 0, 0, 0.04)' : 'inherit'
    }
  }
}))

const Metric = ({ name, value, format, tooltipTitle, alternateFormat, featured }) => {
  const classes = useStyles({ featured, alternateFormat })
  const { formatter } = useFormattingContext()
  const [showAlternateFormat, setShowAlternateFormat] = useState(false)
  const onClick = useCallback(() => {
    if (alternateFormat) {
      setShowAlternateFormat(p => !p)
    }
  }, [alternateFormat, setShowAlternateFormat])
  const formattedValue = useMemo(() => {
    return formatter(value, showAlternateFormat ? alternateFormat : format)
  }, [showAlternateFormat, formatter, value, format, alternateFormat])

  const metric = useMemo(() => {
    return (
      <div className={classes.metric} onClick={onClick}>
        <div className='__metric-value'>{formattedValue}</div>
        <div className='__metric-name'>{name}</div>
      </div>
    )
  }, [classes.metric, formattedValue, name, onClick])

  if (tooltipTitle) {
    return <Tooltip title={tooltipTitle}>{metric}</Tooltip>
  }
  return metric
}

Metric.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  format: PropTypes.string,
  alternateFormat: PropTypes.string,
  featured: PropTypes.bool,
  tooltipTitle: PropTypes.string
}

Metric.defaultProps = {
  featured: false,
  format: 'human',
  tooltipTitle: undefined
}

export default Metric
