import React, { useCallback, useMemo, useRef, useState } from 'react'
import { IconButton } from '@material-ui/core'
import PropTypes from 'prop-types'
import { useManagerSearch } from '../../../../../../api/accounts'
import OperationalTable from '../../../../../organisms/OperationalTable'
import DebouncedInput from '../../../../../molecules/DebouncedInput'
import ErrorBoundary from '../../../../../molecules/ErrorBoundary'
import Icon from '../../../../../atoms/Icon'
import SydButton from '../../../../../commonDesign/Button'
import { useDialogStyles } from '../../useDialogStyles'

const cellTemplates = {
  assign: (onClick, processing) => (props) => (
    <SydButton
      size='sm'
      icon='add'
      disabled={processing && props.row.original.managerId !== processing}
      processing={processing && props.row.original.managerId === processing}
      onClick={(e) => onClick(props, e)}
    >
      Assign
    </SydButton>
  )
}

export const defaultColumnConfig = {
  columns: [
    { Header: 'Id', accessor: 'managerId', type: 'number', cellTemplate: 'assign' },
    { Header: 'Name', accessor: 'name', type: 'string' },
    { Header: 'Symbol', accessor: 'symbol', type: 'string' }
  ],
  defaultSort: [{ id: 'managerId', desc: false }]
}

const useColumns = (onAssign, processing) => {
  const onClick = useCallback((props, e) => {
    e.preventDefault()
    e.stopPropagation()

    onAssign(props.row.original.managerId)
  }, [onAssign])

  return useMemo(() => {
    return [...defaultColumnConfig.columns].map(x => {
      if (x.cellTemplate) {
        x.Cell = cellTemplates[x.cellTemplate](onClick, processing)
      }
      return x
    })
  }, [onClick, processing])
}

function ManagerSearchTable ({ onAssign, processing }) {
  const classes = useDialogStyles()
  const searchRef = useRef()
  const [search, setSearch] = useState()
  const query = useMemo(() => {
    const result = {
      take: 100
    }

    if (search) {
      result.textSearch = {
        name: { op: 'contains', value: search },
        symbol: { op: 'contains', value: search }
      }
    }

    return result
  }, [search])
  const { data: managers, isLoading } = useManagerSearch(query)
  const columns = useColumns(onAssign, processing)

  return (
    <ErrorBoundary name='Managers Search Table'>
      <OperationalTable.Wrapper>
        <div className={classes.controlGroup}>
          <DebouncedInput
            ref={searchRef}
            onChange={setSearch}
            placeholder='Search Managers'
          />
          <IconButton onClick={() => searchRef.current.reset()}>
            <Icon name='close' customSize={20} />
          </IconButton>
        </div>
        <OperationalTable
          data={managers?.data || []}
          variant='v2'
          loading={isLoading}
          hideFooter
          columns={columns}
          defaultSort={defaultColumnConfig.defaultSort}
        />
      </OperationalTable.Wrapper>
    </ErrorBoundary>
  )
}

ManagerSearchTable.propTypes = {
  onAssign: PropTypes.func,
  processing: PropTypes.bool
}

export default ManagerSearchTable
