import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import FadeIn from '../../../../molecules/Transitions/FadeIn'

const useStyles = makeStyles((theme) => ({
  headerInfoDisplay: {},
  longName: {
    fontSize: theme.typography.fontSizes.h1,
    fontWeight: theme.typography.weights.light,
    fontFamily: theme.typography.families.bold
  },
  subHeader: {
    display: 'flex',
    flexDirection: 'row',
    gap: '10px'
  },
  otherDetails: {
    display: 'flex',
    flexDirection: 'row',
    gap: '10px',
    margin: '10px 0'
  },
  description: {
    fontWeight: theme.typography.weights.light
  }
}))

function HeaderInfoDisplay ({ client }) {
  const classes = useStyles()

  return (
    <FadeIn className={classes.headerInfoDisplay}>
      <div className={classes.longName}>{client.longName}</div>
      <div className={classes.subHeader}>
        <span>{client.shortName}</span>
        {client.clientAbbreviation ? (<span>{client.clientAbbreviation}</span>) : null}
      </div>
      <div className={classes.otherDetails}>
        <div className={classes.description}>
          {client.description}
        </div>
      </div>
    </FadeIn>
  )
}

HeaderInfoDisplay.propTypes = {
  client: PropTypes.shape({
    clientId: PropTypes.number,
    longName: PropTypes.string,
    shortName: PropTypes.string,
    clientAbbreviation: PropTypes.string,
    description: PropTypes.string
  })
}

export default HeaderInfoDisplay
