import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { useClient } from '../../../../../api/clients'
import Avatar from '../../../../atoms/Avatar'

const useStyles = makeStyles(theme => ({
  clientDisplay: {
    width: '460px',
    height: '230px',
    padding: '24px 0',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '24px',
    '& .__client-name': {
      fontSize: '26px'
    }
  }
}))

const ClientDisplay = ({ clientId }) => {
  const classes = useStyles()
  const { data, isLoading, error } = useClient(clientId)

  if (error) {
    return (
      <div className={classes.clientDisplay}>
        {error.message}
      </div>
    )
  }

  return (
    <div className={classes.clientDisplay}>
      <Avatar
        showSkeleton={isLoading}
        src={data?.profilePic}
        customSize='128px'
        avatarLetters={data?.clientAbbreviation}
      />
      <div className='__client-name'>{data?.longName}</div>
    </div>
  )
}

ClientDisplay.propTypes = {
  clientId: PropTypes.number
}

export default ClientDisplay
