import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import head from 'lodash/head'
import isEmpty from 'lodash/isEmpty'
import { makeStyles } from '@material-ui/core/styles'
import { Box } from '@material-ui/core'
import { } from '../../../hooks'
import { useAlternatives } from '../../../api/coreData'
import { useAppContext } from '../../../redux/slices/appContext'
import PieChart from '../../molecules/PieChart'
import Text from '../../atoms/Text'
import Skeleton from '../../atoms/Skeleton'
import { useFormattingContext } from '../FormattingProvider/FormattingContext'
import { SKELETON_VARIANTS } from '../../../constants'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    padding: '20px',
    width: '100%',
    justifyContent: 'center'
  },
  chartContainer: ({ chartWidth }) => ({
    width: chartWidth,
    position: 'relative'
  }),
  mainLabel: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  list: ({ chartHeight }) => ({
    display: 'flex',
    flexDirection: 'column',
    height: chartHeight,
    justifyContent: 'space-around'
  }),
  listItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    marginBottom: '30px'
  },
  tooltip: {
    backgroundColor: theme.palette.common.white,
    padding: '5px'
  },
  emptyContainer: {
    height: '300px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}))

const useAlternativesQuery = ({ defaultFilter, defaultLevelType }) => {
  const { clientId, availableDates, loadingAvailableDates } = useAppContext()
  const query = useMemo(() => loadingAvailableDates ? null : {
    dateRange: {
      startDate: availableDates.mainDate,
      endDate: availableDates.mainDate
    },
    filters: {
      ...(defaultFilter || {}),
      clientId: clientId
    },
    levelType: defaultLevelType
  }, [clientId, availableDates, loadingAvailableDates, defaultFilter, defaultLevelType])

  const { data, isLoading } = useAlternatives(query)

  return {
    data: data || [],
    isLoading
  }
}

const CAPITAL_CALL_FIELDS = {
  commitedCapital: {
    key: 'committedCapital',
    defaultLabel: 'Commitment'
  },
  capitalCalled: {
    key: 'capitalCalled',
    defaultLabel: 'Called'
  },
  remainingCapital: {
    key: 'remainingCapital',
    defaultLabel: 'Remaining'
  }
}

const AlternativesProgressPieChart = ({
  chartHeight,
  chartWidth,
  colors,
  defaultFilter,
  defaultLevelType,
  emptyMessage,
  format,
  labels
}) => {
  const classes = useStyles({ chartWidth, chartHeight })
  const { formatter } = useFormattingContext()
  const { data = [], isLoading } = useAlternativesQuery({ defaultFilter, defaultLevelType })

  const tooltip = useCallback(({ datum }) => {
    const tooltipText = formatter(datum.value, format.percentageCalledTooltip)
    return (
      <Box className={classes.tooltip}><Text text={tooltipText} /></Box>
    )
  }, [formatter, format.percentageCalledTooltip, classes.tooltip])

  const { pieData = [], itemsData = [], mainData = {} } = useMemo(() => {
    if (isLoading) return {}
    if (isEmpty(data)) return {}
    const firstResult = head(data)
    const mainData = {
      value: formatter(firstResult.percentageCalled, format.percentageCalled),
      label: labels.percentageCalled || 'Called'
    }
    const pieData = [{
      value: 1 - firstResult.percentageCalled,
      color: '#F4F4F5',
      id: 'pecentageNotCalled'
    }, {
      value: firstResult.percentageCalled,
      color: colors.percentageCalled || '#212945',
      label: mainData.label,
      id: 'pecentageCalled'
    }]
    const itemsData = Object.values(CAPITAL_CALL_FIELDS).map((field) => (
      {
        key: field.key,
        label: labels[field.key] || field.defaultLabel,
        value: formatter(firstResult[field.key], format[field.key])
      }
    ))
    return { pieData, itemsData, mainData }
  }, [data, isLoading, labels, format, formatter, colors])

  const loadingState = useMemo(() => {
    return (
      <Box className={classes.container}>
        <Box className={classes.chartContainer}>
          <Skeleton height={chartHeight} width={chartHeight} variant={SKELETON_VARIANTS.circle} />
        </Box>
        <div className={classes.list}>
          {Array(3).fill(0).map((_, index) => (
            <div className={classes.listItem} key={`progress-Skeleton-${index}`}>
              <Skeleton height='53px' width='100px' />
            </div>
          ))}
        </div>
      </Box>
    )
  }, [classes, chartHeight])

  if (isLoading) {
    return loadingState
  }

  if (isEmpty(data)) {
    return (
      <Box className={classes.emptyContainer}>
        <Text text={emptyMessage} />
      </Box>
    )
  }

  return (
    <Box className={classes.container}>
      <Box className={classes.chartContainer}>
        <PieChart
          data={pieData}
          useDataColors
          enableArcLabels={false}
          innerRadius={0.8}
          height={chartHeight}
          tooltip={tooltip}
        />
        <div className={classes.mainLabel}>
          <Text text={mainData.value} color='#212945' customFontSize='48px' />
          <Text text={mainData.label} color='#141929' />
        </div>
      </Box>
      <div className={classes.list}>
        {itemsData.map((item) => (
          <div className={classes.listItem} key={item.key}>
            <Text text={item.value} color='#212945' customFontSize='30px' />
            <Text text={item.label} color='#666666' />
          </div>
        ))}
      </div>
    </Box>
  )
}

AlternativesProgressPieChart.propTypes = {
  chartHeight: PropTypes.string,
  chartWidth: PropTypes.string,
  colors: PropTypes.shape({
    percentageCalled: PropTypes.string
  }),
  defaultFilter: PropTypes.object,
  defaultLevelType: PropTypes.string,
  emptyMessage: PropTypes.string,
  format: PropTypes.shape({
    capitalCalled: PropTypes.string,
    commitedCapital: PropTypes.string,
    percentageCalled: PropTypes.string,
    percentageCalledTooltip: PropTypes.string,
    remainingCapital: PropTypes.string
  }),
  labels: PropTypes.object
}

AlternativesProgressPieChart.defaultProps = {
  chartHeight: '400px',
  chartWidth: '600px',
  colors: {},
  defaultFilter: {},
  defaultLevelType: 'client',
  emptyMessage: 'No data on capital calls',
  labels: {},
  format: {}
}

export default AlternativesProgressPieChart
