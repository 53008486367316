import { makeStyles } from '@material-ui/core'

export const useDialogStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    fontWeight: theme.typography.weights.light,
    '& strong': {
      fontWeight: theme.typography.weights.bold
    },
    marginBottom: '20px'
  },
  group: {
    display: 'flex',
    flexDirection: 'row',
    gap: '10px',
    width: '100%',
    '& > *': {
      flex: '1 0 auto'
    }
  },
  textarea: {
    width: '100%',
    marginBottom: '10px',
    resize: 'none',
    height: '300px'
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '10px'
  },
  controlGroup: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  }
}))
