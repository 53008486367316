import React, { useCallback, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import isArray from 'lodash/isArray'
import isEmpty from 'lodash/isEmpty'
import { Tooltip } from '@material-ui/core'
import Select from '../../../../molecules/Select'
import {
  DATE_RANGE_CUSTOM_OPTION_KEYS,
  getCustomDateRangeOptionSelected, parseCalendarYearDateRangeRawValue, parseCustomDateRangeRawValue, useRelativeDateRanges
} from '../../../../molecules/RelativeDateSelect'
import { useGroupingContext } from '../../PerformanceGroupings/GroupingContext'
import { modifyByIndex } from '../../../../../utils'
import { useAppContext } from '../../../../../redux/slices/appContext'
import { BUTTON_SIZES } from '../../../../../constants'
import Icon from '../../../../atoms/Icon'
import { localStorageHelper } from '../../../../../utils/localStorageHelper'
import { getGroupingStorageConfigKey } from './helpers'

dayjs.extend(utc)

const DATE_RANGE_FORMAT = 'MM/DD/YYYY'

const getCustomDateRangeDisplayLabel = (value, selectedCustomOption) => {
  let customOptionLabel = selectedCustomOption.label
  if (selectedCustomOption.value.startsWith(DATE_RANGE_CUSTOM_OPTION_KEYS.CUSTOM)) {
    const { startDate, endDate } = parseCustomDateRangeRawValue(value)
    customOptionLabel = `${dayjs
      .utc(startDate)
      .format(DATE_RANGE_FORMAT)} - ${dayjs
      .utc(endDate)
      .format(DATE_RANGE_FORMAT)}`
  }
  if (selectedCustomOption.value === DATE_RANGE_CUSTOM_OPTION_KEYS.CALENDAR_YEAR) {
    const { value: calendarYear } = parseCalendarYearDateRangeRawValue(value)
    customOptionLabel = calendarYear
  }
  return customOptionLabel
}

const DateRangeSelectorCell = ({ column, columns }) => {
  const { availableDates } = useAppContext()
  const {
    selectedGrouping,
    configurationKey,
    groupingDateRanges,
    setGroupingDateRanges,
    groupingDateRangeOptions,
    setTempGroupingDateRanges,
    setIsGroupingDateRangePicker,
    shareLocalStorageAcrossGroupings
  } = useGroupingContext()

  const configKey = useMemo(() => {
    const groupingKey = shareLocalStorageAcrossGroupings
      ? undefined
      : selectedGrouping?.key

    return getGroupingStorageConfigKey(configurationKey, groupingKey)
  }, [configurationKey, selectedGrouping, shareLocalStorageAcrossGroupings])

  useEffect(() => {
    if (configKey) {
      let config = localStorageHelper.load(configKey) || []
      if (!isEmpty(config)) {
        if (isArray(config)) {
          config = config.reduce((p, c) => {
            p[c] = c
            return p
          }, {})
          // throw new Error(`[${configKey}] Storage structure not supported`)
        }
        // if there was a change, then store the config
        if (!Object.values(config).every((item, index) => groupingDateRanges[index] === item)) {
          localStorageHelper.store(configKey, groupingDateRanges)
        }
      } else {
        config = groupingDateRanges
        localStorageHelper.store(configKey, config)
      }
    }
  }, [configKey, groupingDateRanges])

  const { options: performanceDateRangeOptions } = useRelativeDateRanges(
    Object.values(groupingDateRanges), // shape is {}, not [] as hook expects
    availableDates,
    groupingDateRangeOptions
  )

  const groupingDateRangeId = useMemo(() => {
    return column.dateRangeKey || column.originalId
  }, [column])

  const onChangeCustomDateRange = useCallback((selectedCustomOption, index) => {
    setTempGroupingDateRanges((prevState) => {
      const dateRangeValue = prevState[index]
      const customOption = getCustomDateRangeOptionSelected(dateRangeValue)
      if (customOption && selectedCustomOption.value === customOption.value) {
        return prevState
      }
      return modifyByIndex(index, prevState, [selectedCustomOption.value])
    })
    setIsGroupingDateRangePicker.on()
  },
  [setTempGroupingDateRanges, setIsGroupingDateRangePicker]
  )

  const onChangeDateRange = useCallback((value, index) => {
    const selectedCustomOption = getCustomDateRangeOptionSelected(value)
    if (selectedCustomOption) {
      onChangeCustomDateRange(selectedCustomOption, index)
    } else {
      setGroupingDateRanges((prevState) => {
        setTempGroupingDateRanges((prevTempState) => {
          return {
            ...prevTempState,
            [index]: value
          }
        })
        return {
          ...prevState,
          [index]: value
        }
      })
    }
  }, [setGroupingDateRanges, onChangeCustomDateRange, setTempGroupingDateRanges])

  const selectedOption = useMemo(() => {
    const groupingDateRangeValue = groupingDateRanges[groupingDateRangeId]

    const selectedCustomOption = getCustomDateRangeOptionSelected(groupingDateRangeValue)
    if (selectedCustomOption) {
      const customOptionLabel = getCustomDateRangeDisplayLabel(groupingDateRangeValue, selectedCustomOption)
      return {
        ...selectedCustomOption,
        value: groupingDateRangeValue,
        displayLabel: customOptionLabel
      }
    }
    return { value: groupingDateRangeValue }
  }, [groupingDateRanges, groupingDateRangeId])

  const dateRangeOptions = useMemo(() => {
    const options = performanceDateRangeOptions
    const disabledDateRanges = []

    return options.map((dateRangeOption) => {
      const disabled = disabledDateRanges.includes(dateRangeOption.value)
      const customOption = getCustomDateRangeOptionSelected(
        dateRangeOption.value
      )
      const selectedValue = selectedOption.value
      const optionValue = dateRangeOption.value

      const optionOverride =
        (customOption && selectedValue?.startsWith(optionValue)) ||
        (!customOption && selectedValue === optionValue)
          ? selectedOption
          : {}

      return {
        ...dateRangeOption,
        ...optionOverride,
        disabled
      }
    })
  }, [
    selectedOption,
    performanceDateRangeOptions
  ])

  const resolvedDateRange = useMemo(() => {
    const perfRanges = column.resolvedDates?.performanceRanges
    if (!perfRanges) return ''

    const dateRange = (selectedOption.value in perfRanges) ? perfRanges[selectedOption.value] : null
    if (!dateRange) return ''

    return `${dayjs(dateRange.startDate).format(DATE_RANGE_FORMAT)} to ${dayjs(dateRange.endDate).format(DATE_RANGE_FORMAT)}`
  }, [selectedOption.value, column.resolvedDates])

  const style = useMemo(() => {
    return {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: column.alignRight ? 'flex-end' : 'center',
      alignItems: 'center',
      gap: '10px'
    }
  }, [column])

  return (
    <div style={style} className='__date-range-selector'>
      <Select
        value={selectedOption.value}
        options={dateRangeOptions}
        onChange={(value) => onChangeDateRange(value, groupingDateRangeId)}
        size={BUTTON_SIZES.small}
        showCheckMarOnSelectedItems
      />
      {resolvedDateRange ? (
        <Tooltip
          enterDelay={0}
          title={resolvedDateRange}
          arrow
          placement='top'
          classes={{
            tooltip: 'big_tooltip'
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Icon name='info' />
          </div>
        </Tooltip>
      ) : null}
    </div>
  )
}

DateRangeSelectorCell.propTypes = {
  column: PropTypes.object,
  columns: PropTypes.array
}

export default DateRangeSelectorCell
