import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(() => ({
  container: {
    background: 'white',
    color: 'inherit',
    fontSize: 'inherit',
    borderRadius: '2px',
    boxShadow: 'rgb(0 0 0 / 25%) 0px 1px 2px',
    padding: 12
  },
  content: {
    whiteSpace: 'pre',
    display: 'flex',
    alignItems: 'center'
  },
  span: ({ color }) => ({
    display: 'block',
    width: '12px',
    height: '12px',
    background: color,
    marginRight: '7px'
  })
}))

const ChartTooltip = ({ label, color }) => {
  const classes = useStyles({ color })
  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <span className={classes.span} />
        <span>{label}</span>
      </div>
    </div>
  )
}

ChartTooltip.propTypes = {
  label: PropTypes.string,
  color: PropTypes.string
}

export default ChartTooltip
