import React, { useCallback, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Tooltip } from '@material-ui/core'
import { useAppContext } from '../../../redux/slices/appContext'
import {
  useComponentsOfChangeV3Multiple,
  useNormalizeRelativeDates
} from '../../../api/coreData'
import PresentationTable from '../PresentationTable'
import { useFormattingContext } from '../FormattingProvider/FormattingContext'
import { useWidgetContext } from '../../molecules/WidgetWrapper'
import { defaultCustomOptions, defaultOptions } from '../../molecules/RelativeDateSelect'
import { localStorageHelper } from '../../../utils/localStorageHelper'
import ToolLink from '../ToolProvider/ToolLink'
import DateRangeSelectorCell from './DateRangeSelectorCell'

function applyPaths (paramObj, paths, baseObj = {}) {
  const result = { ...baseObj }

  paths.forEach(({ accessor, ...overrides }) => {
    const keys = accessor.split('.')
    let target = result

    keys.forEach((key, idx) => {
      if (idx === keys.length - 1) {
        target[key] = { ...(target[key] || {}), ...paramObj }
        Object.entries(overrides).forEach(([k, override]) => {
          target[key][k] = paramObj[override]
        })
      } else {
        if (!target[key]) target[key] = {}
        target = target[key]
      }
    })
  })

  return result
}

function mergeTrees (obj) {
  function mergeNodes (existingNodes, newNodes, key) {
    newNodes.forEach(newNode => {
      let existingNode = existingNodes.find(node => node.title === newNode.title)
      if (!existingNode) {
        existingNode = { ...newNode, value: undefined, title: newNode.title, children: [] }
        existingNodes.push(existingNode)
      }

      // Set the value for the current top-level key
      existingNode[key] = newNode.value

      // Recursively merge children
      if (newNode.children && newNode.children.length > 0) {
        if (!existingNode.children) existingNode.children = []
        mergeNodes(existingNode.children, newNode.children, key)
      }
    })
  }

  const result = []

  for (const key in obj) {
    mergeNodes(result, obj[key], key)
  }

  return result
}

function useGetRequestDates (dateRanges, availableDates, scopeFilter) {
  const dateRangeRequest = useMemo(() => ({
    asOfDate: availableDates.mainDate,
    levelFilters: {
      ...(scopeFilter || {})
    },
    relativeRanges: Object.values(dateRanges)
  }), [dateRanges, availableDates.mainDate, scopeFilter])

  const { data: normalizedDates, isLoading: datesLoading } = useNormalizeRelativeDates(dateRangeRequest)
  const normalizedDateRanges = useMemo(() => {
    if (datesLoading || !normalizedDates) return null
    return Object.entries(dateRanges).reduce((prev, [key, value]) => {
      const keyValue = typeof value === 'string' ? value : value.key
      const nd = normalizedDates[keyValue]
      prev[key] = {
        ...nd,
        ...(nd?.value || {}),
        value: undefined
      }
      return prev
    }, {})
  }, [normalizedDates, dateRanges, datesLoading])

  return { datesLoading, normalizedDateRanges }
}

const useComponentsOfChangeData = ({
  requestBase,
  levelType,
  dateRanges,
  dateConfig,
  defaultFilter
}) => {
  const { clientId, availableDates } = useAppContext()
  const scopeFilter = useMemo(() => {
    return {
      clientIds: [clientId],
      levelTypes: [levelType]
    }
  }, [clientId, levelType])

  const { normalizedDateRanges, datesLoading } = useGetRequestDates(dateRanges, availableDates, scopeFilter)

  const { subscribedFilters } = useWidgetContext()

  const queryMap = useMemo(() => {
    if (datesLoading) return null

    const _requestBase = { ...(requestBase || {}) }

    // We are turning the dictionary of date ranges, into a dictionary of requests
    return Object.entries(normalizedDateRanges).reduce((prev, [key, value]) => {
      const tableDateRanges = applyPaths(value, dateConfig, _requestBase?.tableDateRanges)
      prev[key] = {
        ...(_requestBase || {}),
        levelFilters: {
          ...(defaultFilter || {}),
          ...(subscribedFilters?.levelFilters || {}),
          ...(scopeFilter || {})
        },
        tableDateRanges
      }
      return prev
    }, {})
  }, [scopeFilter, requestBase, dateConfig, datesLoading, normalizedDateRanges, defaultFilter, subscribedFilters])

  // The hook expects a map of requests
  const { data, isLoading } = useComponentsOfChangeV3Multiple(queryMap, { enabled: !!queryMap })

  const tableData = useMemo(() => {
    function renameChildren (c) {
      if (!c) return c
      const clone = { ...c }
      clone.subRows = clone.children ? clone.children.map(renameChildren) : []
      clone._next = clone.subRows?.length > 0 ? '' : null
      delete clone.children
      return clone
    }

    return mergeTrees(data).map(renameChildren)
  }, [data])

  return {
    data: tableData,
    isLoading,
    normalizedDateRanges
  }
}

const useColumns = ({
  dateRanges,
  onDateRangeChange,
  normalizedDateRanges,
  valueFormat,
  dateRangeColumnProps,
  title,
  canChangeDateRange,
  dateRangeOptions
}) => {
  const { formatter } = useFormattingContext()
  const columns = useMemo(() => {
    const result = [{
      id: 'title',
      Header: title,
      accessor: 'title',
      Cell: (d) => {
        let content = <div>{d.value}</div>

        const tooltip = d.row?.original?.tooltip
        if (tooltip) {
          content = (
            <Tooltip title={tooltip}>
              {content}
            </Tooltip>
          )
        }

        return content
      }
    }]
    const entries = Object.entries(dateRanges)
    entries.forEach(([key]) => {
      const label = normalizedDateRanges?.[key]?.label || ''
      result.push({
        id: `value_${key}`,
        accessor: key,
        ...(dateRangeColumnProps || {}),
        Cell: (d) => {
          const format = d.row?.original?.format || valueFormat
          let content = formatter(d.value, format)
          if (d.row?.original?.tool) {
            const dateRange = normalizedDateRanges?.[key]
            const toolArgs = {
              ...(d.row?.original?.toolArgs || {}),
              startDate: dateRange?.startDate,
              endDate: dateRange?.endDate
            }
            content = (
              <ToolLink tool={d.row.original.tool} toolArgs={toolArgs}>
                {content}
              </ToolLink>
            )
          }

          return content
        },
        Header: (row) => {
          return canChangeDateRange && label ? (
            <DateRangeSelectorCell
              dateRangeKey={key}
              dateRangeOptions={dateRangeOptions}
              values={normalizedDateRanges}
              column={row.column}
              onChange={onDateRangeChange}
            />
          ) : label
        }
      })
    })

    return result
  }, [
    dateRanges, normalizedDateRanges, dateRangeColumnProps,
    title, canChangeDateRange, dateRangeOptions, formatter,
    onDateRangeChange, valueFormat
  ])

  return { columns }
}

const ComponentsOfChangeTableV3 = ({
  variant,
  requestBase,
  levelType,
  valueFormat,
  dateRanges,
  dateConfig,
  dateRangeColumnProps,
  title,
  defaultFilter,
  canChangeDateRange,
  dateRangeOptions,
  configurationKey
}) => {
  const [dateRangeValues, setDateRangeValues] = useState(localStorageHelper.loadOrDefault(configurationKey, dateRanges))
  const onDateRangeChange = useCallback((key, value) => {
    setDateRangeValues((prev) => {
      const newValue = {
        ...prev,
        [key]: value
      }
      localStorageHelper.tryStore(configurationKey, newValue)
      return newValue
    })
  }, [setDateRangeValues, configurationKey])

  const { data, isLoading, normalizedDateRanges } = useComponentsOfChangeData({
    requestBase,
    levelType,
    dateRanges: dateRangeValues,
    dateConfig,
    defaultFilter
  })

  const { columns } = useColumns({
    title,
    dateRanges: dateRangeValues,
    onDateRangeChange,
    normalizedDateRanges,
    valueFormat,
    dateRangeColumnProps,
    canChangeDateRange,
    dateRangeOptions
  })

  return (
    <PresentationTable.Wrapper>
      <PresentationTable
        variant={variant}
        loading={isLoading}
        columns={columns}
        data={data}
        expandable
        defaultExpanded
      />
    </PresentationTable.Wrapper>
  )
}

ComponentsOfChangeTableV3.propTypes = {
  variant: PropTypes.string,
  requestBase: PropTypes.object,
  defaultFilter: PropTypes.object,
  levelType: PropTypes.string,
  valueFormat: PropTypes.string,
  dateConfig: PropTypes.arrayOf(PropTypes.shape({
    accessor: PropTypes.string
  })),
  dateRanges: PropTypes.objectOf(PropTypes.string),
  dateRangeColumnProps: PropTypes.object,
  title: PropTypes.string,
  canChangeDateRange: PropTypes.bool,
  dateRangeOptions: PropTypes.array,
  configurationKey: PropTypes.string
}

ComponentsOfChangeTableV3.defaultProps = {
  defaultFilter: {},
  levelType: 'client',
  valueFormat: 'human',
  dateRanges: {
    default: 'YTD'
  },
  dateRangeColumnProps: {
    alignRight: true
  },
  title: 'Components of Change',
  canChangeDateRange: false,
  dateRangeOptions: [...defaultOptions, ...defaultCustomOptions]
}

export default ComponentsOfChangeTableV3
