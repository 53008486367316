import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Button, IconButton, makeStyles } from '@material-ui/core'
import { useRouteMatch } from 'react-router-dom'
import {
  useCustodians
} from '../../../../../api/coreData'
import { defaultOptions } from '../../../../molecules/RelativeDateSelect'
import { reportParamsShape, reportResultShape } from '../reportParams'
import {
  levelDatesQuery
} from '../helpers'
import Icon from '../../../../atoms/Icon'
import { ICON_NAMES } from '../../../../../constants'
import SearchBarInput from '../../../../molecules/SearchBar/SearchBarInput'
import { useReportFiltersContext } from '../ReportFiltersProvider/ReportFiltersProvider'
import useBobiOfflineExport from '../useBobiOfflineExport'
import ResultsFiltersSummary from './ResultsFiltersSummary'
import ResultsTableFilters from './ResultsTableFilters'

const COLUMN_ORDINALS = {
  'Client ID': 0,
  'Client Name': 1,
  'Account ID': 2,
  'Account Name': 3,
  'Account Number': 4
}

const useStyles = makeStyles(() => ({
  container: {
    paddingTop: '1rem',
    width: '100%',
    minHeight: '64px'
  },
  header: {
    gap: '1rem',
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: '0.5rem',
    '& .__summary': {
      display: 'flex',
      justifyContent: 'space-between'
    },
    '& .__actions': {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',
      justifyContent: 'space-between',
      '& .__search-input': {
        maxWidth: '30rem',
        width: '100%'
      }
    },
    '& .__export-button': {
      display: 'flex'
    },
    '& .__button': {
      borderRadius: '2rem',
      border: '1.5px solid #212945',
      backgroundColor: '#FFF',
      textTransform: 'none',
      padding: '0.5rem 1rem',
      maxHeight: '2.5rem',
      '& > span': {
        fontSize: '0.75rem',
        lineHeight: '0.75rem',
        color: '#212945',
        fontWeight: 'bold',
        display: 'flex',
        gap: '0.5rem'
      }
    }
  }
}))

const useRerunBobi = (reportParams) => {
  const { path, url } = useRouteMatch()
  const onClick = useCallback(() => {
    const params = new URLSearchParams({
      ...reportParams,
      filters: JSON.stringify(reportParams.filters),
      shouldCheckExisting: false
    })
    window.location.href = `${url}?${params.toString()}`
  }, [reportParams, url])

  return {
    path,
    onClick
  }
}

const ReportResultsTitle = ({
  reportResult: { reportParams, bobiResult },
  setIsLoadingTrue,
  setIsLoadingFalse,
  isLoading,
  rightAdornment
}) => {
  const classes = useStyles()
  const { queryText, setQueryText } = useReportFiltersContext()
  const { onClick } = useRerunBobi(reportParams)

  const {
    data: custodians,
    isLoading: loadingCustodians
  } = useCustodians(levelDatesQuery)

  useMemo(() => {
    if (loadingCustodians || !custodians) return []
    const allOption = [
      {
        key: 'all',
        value: 0,
        label: 'All'
      }
    ]
    const options = [
      ...allOption,
      ...custodians.map((custodian) => ({
        key: custodian.custodianCode,
        value: custodian.custodianId,
        label: custodian.custodianName
      }))
    ]
    return options
  }, [custodians, loadingCustodians])

  const { onExport } = useBobiOfflineExport({
    afterExport: setIsLoadingFalse,
    beforeExport: setIsLoadingTrue,
    bobiOutputId: bobiResult.bobiOutputId,
    columnOrdinals: COLUMN_ORDINALS,
    fileName: 'Report.csv'
  })

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <div className='__summary'>
          <ResultsFiltersSummary reportParams={reportParams} />
          <Button className='__button' onClick={onClick} disabled={isLoading}>
            <Icon name={ICON_NAMES.sync} customSize='1.25rem' />
            Reprocess Report
          </Button>
        </div>
        <div className='__actions'>
          <div className='__search-input'>
            <SearchBarInput
              disabled={isLoading}
              placeholder={`Search by ${reportParams.levelType}`}
              value={queryText}
              onClear={setQueryText}
              onChange={setQueryText}
            />
          </div>
          <div className='__export-button'>
            <IconButton onClick={onExport} disabled={isLoading}>
              <Icon
                name={ICON_NAMES.download}
                customSize='1.5rem'
                color='black'
              />
            </IconButton>
            {rightAdornment}
          </div>
        </div>
        <ResultsTableFilters />
      </div>
    </div>
  )
}

ReportResultsTitle.propTypes = {
  reportResult: reportResultShape,
  reportParams: reportParamsShape,
  rightAdornment: PropTypes.node,
  isLoading: PropTypes.bool,
  setIsLoadingTrue: PropTypes.func,
  setIsLoadingFalse: PropTypes.func
}

ReportResultsTitle.defaultProps = {
  dateRangeOptions: defaultOptions.filter(({ value }) =>
    ['L7D', 'L30D', 'MTD', 'QTD', 'YTD'].includes(value)
  )
}

export default ReportResultsTitle
