import React, { useMemo } from 'react'
import { Grid } from '@material-ui/core'
import { Link } from 'react-router-dom'
import FadeIn from '../../../molecules/Transitions/FadeIn'
import { useListFirmReportStatus, usePrefetchFirmReportMetrics } from '../../../../api/coreData'
import PageContainer from '../components/PageContainer'
import DataHomeItem from '../shared/DataHomeItem'
import DataHomeItemGroup from '../shared/DataHomeItemGroup'

const query = {}

const useFirmReports = () => {
  const { data, isLoading } = useListFirmReportStatus(query)
  const grouped = useMemo(() => {
    if (isLoading) return []
    const groups = data.reports.reduce((groups, item) => {
      const categoryId = item.firmReportCategoryId ?? 0
      if (!(categoryId in groups)) {
        groups[categoryId] = {
          name: item.firmReportCategoryName ?? 'Firm Reports',
          categoryId,
          ordinal: item.firmReportCategoryOrdinal ?? 0,
          reports: []
        }
      }

      groups[categoryId].reports.push(item)
      return groups
    }, {})

    const result = Object.values(groups)
    result.forEach(r => {
      const groupedReports = r.reports.reduce((g, r) => {
        if (!(r.reportId in g)) {
          g[r.reportId] = {
            ...r,
            latestAsOfDate: r.reportAsOfDate,
            availableAsOfDates: []
          }
        }

        const gItem = g[r.reportId]
        gItem.availableAsOfDates.push(r.reportAsOfDate)
        if (gItem.latestAsOfDate < r.reportAsOfDate) {
          gItem.latestAsOfDate = r.reportAsOfDate
        }
        return g
      }, {})

      r.reports = Object.values(groupedReports)
    })
    result.sort((a, b) => a.ordinal - b.ordinal)

    return result
  }, [isLoading, data])

  return {
    grouped,
    data,
    isLoading
  }
}

function DataOut () {
  const { grouped, isLoading } = useFirmReports()
  const prefetch = usePrefetchFirmReportMetrics()

  if (isLoading) {
    return null
  }

  return (
    <PageContainer>
      <FadeIn>
        <Grid item xs={12}>
          {grouped.map(g => (
            <DataHomeItemGroup key={g.firmReportCategoryId} name={g.name}>
              {g.reports.map(r => (
                <Link key={r.reportId} to={`/data-home/out/${r.configuration?.defaultToSummary ? 's' : 'r'}/${r.reportId}${r.configuration?.defaultFilter ? `?filter=${r.configuration.defaultFilter}` : ''}`}>
                  <DataHomeItem name={r.name} imageUrl={r.imageUrl} onHover={() => prefetch(r.reportId)} />
                </Link>
              ))}
            </DataHomeItemGroup>
          ))}
        </Grid>
      </FadeIn>
    </PageContainer>
  )
}

export default DataOut
