import { useMemo } from 'react'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { useSearchClients } from '../../../../../api/clients'

dayjs.extend(utc)

const getDetailsQuery = (query = {}) => {
  const skip = query?.skip ?? 0
  const take = query?.take ?? 100
  const sort = query?.sort
  return {
    ...query,
    skip,
    take,
    sort
  }
}

export const mapSort = (sorts) =>
  sorts?.map(({ id, desc }) => ({ field: id, dir: desc ? 'desc' : 'asc' }))

const textSearchFields = ['shortName', 'longName']

export const mapSearch = (searchText) =>
  searchText
    ? textSearchFields.reduce((prev, field) => {
      prev[field] = [{ op: 'contains', value: searchText }]
      return prev
    }, {})
    : {}

export const useClients = ({
  pageIndex,
  pageSize,
  sort,
  searchText,
  filters,
  includes,
  onSuccess,
  options
}) => {
  const query = useMemo(() => {
    return getDetailsQuery({
      skip: pageIndex * pageSize || 0,
      take: pageSize,
      sort: mapSort(sort),
      textSearch: mapSearch(searchText),
      filters,
      includes
    })
  }, [pageSize, pageIndex, sort, searchText, filters, includes])

  const { data, isLoading, isPreviousData, error, refetch, isRefetching } =
    useSearchClients(query, options, onSuccess)

  return {
    data: isLoading ? [] : data.data,
    loading: isLoading || isPreviousData,
    error,
    refetch,
    isRefetching
  }
}
