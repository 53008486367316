import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import { TablePositioningContextProvider } from '../StickySuperHeader/TablePositioningContext'
import { AutoOperationalTableContextProvider } from './AutoOperationalTableContext'

const useStyles = makeStyles((theme) => ({
  tableWrapper: {
    position: 'relative',
    minHeight: '100%'
  }
}))

const TableWrapper = ({
  className,
  children,
  shouldRefreshHeaderPositioning
}) => {
  const classes = useStyles()
  return (
    <AutoOperationalTableContextProvider>
      <TablePositioningContextProvider
        shouldRefreshHeaderRect={shouldRefreshHeaderPositioning}
      >
        <div className={clsx(classes.tableWrapper, className)}>{children}</div>
      </TablePositioningContextProvider>
    </AutoOperationalTableContextProvider>
  )
}

TableWrapper.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  shouldRefreshHeaderPositioning: PropTypes.bool
}

export default TableWrapper
