import React from 'react'
import SelectFilter from '../../../../molecules/SelectFilter'
import CarouselSlider from '../../../../molecules/CarouselSlider'
import { useReportFiltersContext } from '../ReportFiltersProvider/ReportFiltersProvider'

const ResultsTableFilters = () => {
  const {
    columnFiltersVisibility,
    setColumnFilterVisibility
  } = useReportFiltersContext()

  const onChangeSelectFilter = (filterMeta) => (payload) => {
    setColumnFilterVisibility((prevState) => {
      return {
        ...prevState,
        [filterMeta.id]: {
          ...(prevState?.[filterMeta.id] || {}),
          value: payload ?? null
        }
      }
    })
  }

  return (
    <CarouselSlider>
      {Object.entries(columnFiltersVisibility)
        .filter(([_, { visible }]) => visible)
        .map(([columnId, columnFilter]) => {
          const filterDefaultValue = columnFilter.value
          return (
            <SelectFilter
              key={columnId}
              placeholder={columnFilter.label}
              variant={columnFilter.filterType}
              onChange={onChangeSelectFilter(columnFilter)}
              filterProps={{
                defaultValue: filterDefaultValue?.value,
                defaultRangeValue: filterDefaultValue?.rangeValue,
                defaultOperatorValue: filterDefaultValue?.optionValue
              }}
            />
          )
        })}
    </CarouselSlider>
  )
}

export default ResultsTableFilters
