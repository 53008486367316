import React, { useCallback, useImperativeHandle, useState } from 'react'
import RoundedModal from '../../../../../molecules/RoundedModal'
import { useDialogStyles } from '../../useDialogStyles'
import SydButton from '../../../../../commonDesign/Button'
import { useAccountFormContext } from '../../AccountFormProvider'
import { useAssignAccountManagerMutation } from '../../../../../../api/accounts'
import ManagerSearchTable from './ManagerSearchTable'

const AssignManagerDialog = React.forwardRef(function AssignManagerDialog (_, ref) {
  const classes = useDialogStyles()
  const [dialogState, setDialogState] = useState({ open: false, processing: false })
  const close = useCallback(() => {
    setDialogState({ open: false, processing: false })
  }, [setDialogState])

  useImperativeHandle(ref, () => ({
    open: (item) => setDialogState({ open: true, processing: false, parent: item })
  }), [setDialogState])

  const { account } = useAccountFormContext()
  const { mutateAsync: assignManager } = useAssignAccountManagerMutation()
  const [processing, setProcessing] = useState(false)
  const onAssign = useCallback(async (managerId) => {
    try {
      setProcessing(managerId)
      await assignManager({
        accountId: account.accountId,
        managerId
      })
      close()
    } catch (err) {
      console.error(err)
      alert('There was a problem saving the manager assignment')
    } finally {
      setProcessing(false)
    }
  }, [account, close, assignManager])

  return (
    <RoundedModal
      title='Assign a Manager'
      open={dialogState.open}
      onClose={close}
      position='top'
      size='large'
      disablePortal={false}
    >
      <ManagerSearchTable onAssign={onAssign} processing={processing} />
      <div className={classes.actions}>
        <SydButton disabled={processing} variant='ghost' size='lg' onClick={close}>Cancel</SydButton>
      </div>

    </RoundedModal>
  )
})

AssignManagerDialog.propTypes = {}

export default AssignManagerDialog
