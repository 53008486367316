import React from 'react'
import PropTypes from 'prop-types'
import Icon from '../../../../atoms/Icon'
import { ICON_NAMES } from '../../../../../constants'

const ViewEnabledFlagCell = ({ enabled }) => {
  return (
    <Icon
      name={enabled ? ICON_NAMES.check : ICON_NAMES.block}
      color={enabled ? '#4caf50' : '#f44336'}
    />
  )
}

ViewEnabledFlagCell.propTypes = {
  enabled: PropTypes.bool
}

export default ViewEnabledFlagCell
