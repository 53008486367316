import React, { useCallback, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { useTheme, makeStyles } from '@material-ui/core/styles'
import dayjs from 'dayjs'
import clsx from 'clsx'
import Icon from '../../atoms/Icon'
import { useDocumentDownload } from '../../../api/documentVault'
import { usePolicy } from '../../../hooks/usePolicy'
import { useComments } from './CommentContext'

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: ({ disabled }) => disabled === 'removed' ? 'none' : 'flex',
    flexDirection: 'row',
    gap: '10px',
    height: '40px',
    margin: '0 -9px 10px -9px'
  },
  hoverable: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '10px',
    borderRadius: '4px',
    outline: '1px solid transparent',
    transition: 'background .3s ease-in-out .1s, outline .2s ease-in-out .1s',
    '&:hover': {
      background: theme.palette.gray.main,
      outline: `1px solid ${theme.palette.gray.dark}`,
      cursor: 'pointer'
    },
    '&.__disabled': {
      background: theme.palette.gray.dark,
      color: theme.palette.gray.darker,
      '&:hover': {
        cursor: 'wait'
      }
    }
  },
  attachment: {
    height: '100%',
    flex: '1 0',
    padding: '8px 9px'
  },
  info: {
    flex: '1 0'
  },
  name: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between'
  },
  details: {
    fontWeight: '100',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  trash: {
    height: '100%',
    width: '50px'
  }
}))

function Attachment ({ attachment }) {
  const theme = useTheme()
  const canRemoveAttachments = usePolicy('attachments_remove')

  const { removeAttachment } = useComments()
  const [disabled, setDisabled] = useState()
  const handleRemove = useCallback(async () => {
    if (disabled) {
      return
    }
    setDisabled('wait')
    try {
      await removeAttachment(attachment)
      setDisabled('removed')
    } catch (err) {
      alert('Error: Couldn\'t Remove the selected document attachment.')
      setDisabled(null)
    }
  }, [attachment, disabled, setDisabled, removeAttachment])

  const { mutateAsync: download } = useDocumentDownload()
  const handleDownload = useCallback(async () => {
    if (disabled) {
      return
    }
    setDisabled('wait')
    try {
      await download(attachment.document, false)
    } finally {
      setDisabled(null)
    }
  }, [attachment, download, disabled, setDisabled])

  const createdOn = useMemo(() => {
    return dayjs.utc(attachment.document.createdDate).format('MM/DD/YYYY')
  }, [attachment])
  const classes = useStyles({ disabled })

  return (
    <div className={classes.wrapper}>
      <div
        className={clsx(classes.hoverable, classes.attachment, { __disabled: disabled === 'wait' })}
        role='button'
        onClick={handleDownload}
        title='Download Document'
      >
        <Icon
          name='document'
          roundedBackground={theme.palette.gray.main}
        />
        <div className={classes.info}>
          <div className={classes.name}>
            <div>{attachment.document.name}</div>
          </div>
          <div className={classes.details}>
            <span>{createdOn}</span>
            <span>{attachment.document.documentType}</span>
          </div>
        </div>
      </div>
      {canRemoveAttachments ? (
        <div
          className={clsx(classes.hoverable, classes.trash, { __disabled: disabled === 'wait' })}
          role='button'
          onClick={handleRemove}
          title='Remove Attachment'
        >
          <Icon customSize={18} name='delete' />
        </div>
      ) : null}
    </div>
  )
}

Attachment.propTypes = {
  attachment: PropTypes.shape({
    attachmentId: PropTypes.number,
    levelTypeId: PropTypes.number,
    levelId: PropTypes.number,
    documentId: PropTypes.string,
    document: PropTypes.shape({
      name: PropTypes.string,
      thumbnailKey: PropTypes.string,
      documentType: PropTypes.string,
      createdDate: PropTypes.string
    })
  })
}

export default Attachment
