import React from 'react'
import PropTypes from 'prop-types'
import Metric from './Metric'

const AssetExposureMetric = ({
  valueFormat,
  valueDescription,
  defaultDateRange
}) => {
  // TODO: add some data fetching here
  return (
    <Metric.Wrapper>
      <Metric
        value={0}
        valueFormat={valueFormat}
        description={valueDescription}
      />
    </Metric.Wrapper>
  )
}

AssetExposureMetric.propTypes = {
  valueFormat: PropTypes.string,
  valueDescription: PropTypes.string,
  defaultDateRange: PropTypes.string
}

AssetExposureMetric.defaultProps = {
  valueFormat: 'human',
  defaultDateRange: 'L7D',
  valueDescription: 'in the last 30 days'
}

export default AssetExposureMetric
