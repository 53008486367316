import React, { useCallback, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { FormProvider, useFieldArray, useForm } from 'react-hook-form'
import { Box } from '@material-ui/core'
import SydButton from '../../../../../../commonDesign/Button'
import { useEditAccountBillingAccountsMutation } from '../../../../../../../api/billing'
import { useDialogStyles } from '../../common'
import SydReadOnly from '../../../../../../commonDesign/SydReadOnly'
import AccountBillingAccountRow from '../../../../AccountDetails/BillingTab/AccountBillingAccountRow'
import Switch from '../../../../../../molecules/Switch'
import Text from '../../../../../../atoms/Text'

const useSubmitter = (form, onComplete, account) => {
  const { handleSubmit } = form
  const [processing, setProcessing] = useState(false)
  const [error, setError] = useState(null)
  const { mutateAsync: editBillingAccounts } = useEditAccountBillingAccountsMutation()
  const onSubmit = useCallback(async (formData) => {
    const totalPercentage = formData.billingAccounts.reduce((acc, ba) => (
      acc + (ba.distributionType === 'PERCENTAGE' ? parseInt(ba.distributionValue, 10) : 0)
    ), 0)
    if (totalPercentage > 100) {
      setError('Total percentage cannot be greater than 100%')
      return
    }

    if (formData.billingAccounts.some(ba => !ba.billingAccountId && ba.billingAccountType === 'summit')) {
      setError('Billing account required for Accounts from Summit')
      return
    }

    if (formData.billingAccounts.some(ba => !ba.externalBillingAccount?.length && ba.billingAccountType === 'external')) {
      setError('External billing account text required for External Billing Accounts')
      return
    }

    if (formData.billingAccounts.some(ba => (ba.distributionValue === '' || ba.distributionValue === null) && ba.distributionType !== 'REMAINING')) {
      setError('A value is required for billing accounts that are set to percentage or fixed amount')
      return
    }

    const _billingAccounts = formData.billingAccounts.map(billingAccount => {
      const { billingAccountType, billingAccountId, externalBillingAccount, distributionType, distributionValue } = billingAccount
      return {
        billingAccountId: billingAccountType === 'summit' ? billingAccountId : null,
        externalBillingAccount: billingAccountType === 'external' ? externalBillingAccount : null,
        isPayByInvoice: billingAccountType === 'invoice',
        distributionType: distributionType ?? 'PERCENTAGE',
        distributionValue: distributionValue ?? '100',
        ...(formData.billingAccounts.length === 1 && {
          distributionType: 'PERCENTAGE',
          distributionValue: 100
        })
      }
    })

    const command = {
      accountId: account.accountId,
      billingAccounts: _billingAccounts
    }

    try {
      setError(null)
      setProcessing(true)
      const result = await editBillingAccounts(command)
      if (result?.statusCode === 500) {
        throw new Error('Failed to edit billing start date')
      }
      onComplete(result)
    } catch (err) {
      setError(err?.toString())
    } finally {
      setProcessing(false)
    }
  }, [editBillingAccounts, setProcessing, onComplete, account?.accountId, setError])

  const submitter = useCallback(async (e) => {
    const onValid = async (form) => {
      await onSubmit(form)
    }
    const onInvalid = (errors) => {
      console.error(errors)
    }

    const handler = handleSubmit(onValid, onInvalid)
    await handler(e)
  }, [handleSubmit, onSubmit])

  return {
    submitter,
    processing,
    error
  }
}

function EditBillingStartDateForm ({ account, defaultClientIdsToFilter, billingAccounts, onCancel, onComplete }) {
  const classes = useDialogStyles()
  const [limitClientIds, setLimitClientIds] = useState(true)

  const _billingAccounts = useMemo(() => {
    return billingAccounts?.map(billingAccount => {
      let billingAccountType = 'external'
      if (billingAccount.isPayByInvoice) {
        billingAccountType = 'invoice'
      } else if (billingAccount.billingAccountId) {
        billingAccountType = 'summit'
      }

      return {
        ...billingAccount,
        billingAccountType
      }
    }) ?? []
  }, [billingAccounts])

  const form = useForm({
    mode: 'onChange',
    defaultValues: {
      billingAccounts: _billingAccounts
    }
  })
  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: 'billingAccounts'
  })

  const { submitter, processing, error } = useSubmitter(form, onComplete, account)
  const addNewBillingAccount = useCallback(() => {
    const totalExistingPercentage = fields?.reduce((acc, ba) => (
      acc + (ba.distributionType === 'PERCENTAGE' ? ba.distributionValue : 0)
    ), 0)
    append([{
      accountId: account.accountId,
      accountName: null,
      accountNumber: null,
      billingAccountType: 'summit',
      distributionType: 'PERCENTAGE',
      distributionValue: Math.max(0, 100 - totalExistingPercentage),
      externalBillingAccount: null,
      billingAccountId: null
    }], { shouldFocus: false })
  }, [account.accountId, append, fields])

  const clientIdsToFilter = useMemo(() => {
    return (!defaultClientIdsToFilter?.length || !limitClientIds)
      ? null : defaultClientIdsToFilter
  }, [defaultClientIdsToFilter, limitClientIds])

  const limitClientIdsToggle = useMemo(() => {
    return fields.some(ba => ba.billingAccountType === 'summit')
  }, [fields])

  return (
    <>
      {!!defaultClientIdsToFilter?.length && limitClientIdsToggle && (
        <Box my={2} display='flex' gridGap='10px' alignItems='center'>
          <Switch
            checked={limitClientIds}
            onChange={(e, val) => setLimitClientIds(val)}
          />
          <Text variant='body'>Filter Accounts to Family Member Accounts</Text>
        </Box>
      )}
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(submitter)}>
          <Box display='flex' flexDirection='column' gridGap='16px'>
            {fields.map((item, index) => (
              <Box key={index} flexGrow={1}>
                <AccountBillingAccountRow
                  index={index}
                  isEditing
                  showDetails={fields?.length > 1}
                  formMethods={form}
                  onAddBillingAccount={addNewBillingAccount}
                  assignedToClientIds={clientIdsToFilter}
                  onRemoveBillingAccount={() => remove(index)}
                />
              </Box>
            ))}
            {!fields.length && (
              <Box display='flex' alignItems='center' gridGap='16px'>
                <Box style={{ width: '300px' }}>
                  <SydReadOnly className={classes.readOnly}>No billing accounts</SydReadOnly>
                </Box>
              </Box>
            )}
            {fields.length !== 1 && (
              <Box>
                <SydButton
                  variant='outline'
                  onClick={addNewBillingAccount}
                  icon='add'
                  size='sm'
                >
                  Add Billing Account
                </SydButton>
              </Box>
            )}
          </Box>
        </form>
      </FormProvider>
      <div className={classes.actions}>
        {error ? (<div className={classes.error}>{error}</div>) : null}
        <SydButton disabled={processing} variant='ghost' size='md' onClick={onCancel}>Cancel</SydButton>
        <SydButton
          variant='primary'
          size='md'
          onClick={submitter}
          processing={processing}
        >
          Save
        </SydButton>
      </div>
    </>
  )
}

EditBillingStartDateForm.propTypes = {
  account: PropTypes.shape({
    accountId: PropTypes.number,
    billingStartDate: PropTypes.string
  }),
  defaultClientIdsToFilter: PropTypes.array,
  billingAccounts: PropTypes.array,
  onComplete: PropTypes.func,
  onCancel: PropTypes.func
}

export default EditBillingStartDateForm
