import React, { useMemo } from 'react'
import dayjs from 'dayjs'
import numeral from 'numeral'

export const cellTemplates = {
  /* eslint-disable-next-line jsx-a11y/anchor-is-valid */
  link: (onClick, accessor) => ({ value }) => (<a href='#' onClick={onClick(value)}>{accessor(value)}</a>),
  date: ({ value }) => dayjs.utc(value).format('M/D/YYYY'),
  number: ({ value }) => value,
  // eslint-disable-next-line eqeqeq
  units: ({ value }) => value == 0 ? '--' : numeral(value).format('0,0.00'),
  money: ({ value }) => numeral(value).format('0,0.00'),
  returns: ({ value }) => numeral(value).format('0.00%'),
  tags: ({ value }) => (
    <span>{value.map(x => (<span style={{ border: '1px solid gray', padding: '2px', marginRight: '2px' }} key={x.transactionCodeTagId}>{x.displayName}</span>))}</span>
  ),
  noWrap: ({ value }) => (
    <span style={{ whiteSpace: 'nowrap' }}>{value}</span>
  )
}

export const defaultColumnConfig = {
  columns: [
    { Header: 'Date', accessor: 'valueDate', Cell: cellTemplates.date },
    { Header: 'Acct Number', accessor: 'accountNumber' },
    { Header: 'Asset Name', accessor: 'assetName', Cell: cellTemplates.noWrap },
    { Header: 'Symbol', accessor: 'assetIdentifier' },
    { Header: 'Custodian', accessor: 'custodianName' },
    { Header: 'Description', accessor: 'transactionDescription', disableSortBy: true },
    { Header: 'Value', accessor: 'marketValue', Cell: cellTemplates.money, alignRight: true },
    { Header: 'Units', accessor: 'units', Cell: cellTemplates.units, alignRight: true },
    { Header: 'Tags', accessor: 'transactionCodeTags', Cell: cellTemplates.tags, disableSortBy: true, alignRight: true }
  ],
  defaultSort: [{ id: 'valueDate', desc: true }]
}

export const columnConfigMap = {
  default: defaultColumnConfig
}

export const useColumnConfig = (config = 'default') => {
  return useMemo(() => {
    const normalized = config.toLowerCase()
    return (normalized in columnConfigMap)
      ? columnConfigMap[normalized]
      : columnConfigMap.default
  }, [config])
}
