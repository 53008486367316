import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  sectionTitle: {
    marginBottom: theme.layout.margin.thin,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: theme.layout.gap.medium,
    lineHeight: '20px'
  }
}))

function SectionTitle ({ children, title }) {
  const classes = useStyles()

  return (
    <div className={classes.sectionTitle}>
      <div>{title}</div>
      <div>{children}</div>
    </div>
  )
}

SectionTitle.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string
}

export default SectionTitle
