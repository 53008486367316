import KeyMirror from 'keymirror'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import pick from 'lodash/pick'
import { CSV_CELL_TYPES, INTERNAL_DATE_FORMAT, TEXT_ALIGNMENTS, TEXT_VARIANTS } from '../../../../constants'
import { mapFeeSchedule } from '../ClientDetailsView/helpers'
import { parseBadJSon } from '../../../../utils'
import {
  editAdminAccount,
  manageAccountClientsAssociation,
  postNamedCommand,
  saveAccountTags,
  updateAccountBillingInfo
} from '../../../../service'
import { filterRemovedFeeOverrides, mapFeeOverrides } from '../FeeOverrides/helpers'
import { getGroupedTagsBody } from '../LevelTags/helpers'

dayjs.extend(utc)

const ACCOUNT_DATE_FORMAT = 'MM/DD/YYYY'

export const ACCOUNT_FORM_NAMES = KeyMirror({
  accountId: null,
  accountLongName: null,
  accountLongNumber: null,
  accountDisplayName: null,
  accountDisplayNumber: null,
  closedDate: null,
  feeOverride: null,
  feeScheduleType: null,
  taxStatusId: null,
  tags: null,
  managedStatusId: null,
  discretionaryStatusId: null,
  billingStatusId: null,
  performanceStatusId: null,
  positionsManagedStatusId: null,
  positionsDiscretionaryStatusId: null,
  positionsBillingStatusId: null,
  positionsPerformanceStatusId: null,
  billingStartDate: null,
  performanceStartDate: null
})

export const mapAccount = (data = {}, billingInfo = {}, tags = []) => {
  const { data: accounts = [] } = data
  const [account] = accounts
  const { account: accountBillingInfo } = billingInfo
  return {
    ...account,
    billingInfo: { ...accountBillingInfo },
    tags
  }
}

export const getAccountQuery = (accountId) => {
  return {
    skip: 0,
    take: 1,
    includes: {
      custodian: true,
      managers: true
    },
    filters: {
      accountId: [{ op: 'eq', value: accountId }]
    }
  }
}

export const formatDate = (date, format = ACCOUNT_DATE_FORMAT) => {
  if (!date) return '-'
  const dateObj = dayjs(date, INTERNAL_DATE_FORMAT)
  return dateObj.isValid() ? dateObj.format(format) : '-'
}

const LABELS_COMMON_PROPS = {
  alignment: TEXT_ALIGNMENTS.left,
  colSpan: 1,
  variant: TEXT_VARIANTS.h3
}

export const CLIENT_LABELS = [
  {
    ...LABELS_COMMON_PROPS,
    name: 'Name'
  },
  {
    ...LABELS_COMMON_PROPS,
    alignment: TEXT_ALIGNMENTS.right,
    name: ''
  }
]

export const clientRowsMapper =
  (actionsRenderer) =>
    ({ clients, editMode, onAssignUnAssign }) => {
      return clients.map((client) => {
        const { id, name, imageUrl, clientAbbreviation, shortName } = client
        return [
          {
            alignment: TEXT_ALIGNMENTS.left,
            avatarSource: imageUrl,
            value: name,
            withAvatar: true,
            isPublicAvatar: true,
            csvData: { type: CSV_CELL_TYPES.string },
            clientAbbreviation,
            shortName
          },
          {
            alignment: TEXT_ALIGNMENTS.right,
            value: editMode
              ? actionsRenderer(() =>
                onAssignUnAssign({
                  clientId: id,
                  isAssign: false,
                  client
                })
              )
              : '',
            valueId: id,
            csvData: { type: CSV_CELL_TYPES.string }
          }
        ]
      })
    }

export const getSearchProp = (value, operation = 'contains') => {
  return [{ op: operation, value }]
}

export const mapFeeScheduleOptions = (feeSchedules) => {
  const feeOverrideOptions = feeSchedules.map((feeSchedule) => {
    const { feeScheduleId, longName } = feeSchedule
    return {
      label: longName,
      value: feeScheduleId,
      payload: mapFeeSchedule(feeSchedule)
    }
  })

  return feeOverrideOptions
}

export const mapAccountOptions = ({ data: accounts }) => {
  return accounts.map((account) => {
    const {
      accountId,
      displayName,
      longName,
      shortName,
      shortNumber,
      custodian
    } = account

    const name = displayName || longName || shortName || ''

    return {
      label: name,
      value: accountId,
      payload: {
        id: accountId,
        name: displayName || longName || shortName || '',
        description: shortNumber,
        imageUrl: custodian ? custodian[0]?.imageUrl : ''
      }
    }
  })
}

export const getAccountsSearchQuery = (query) => {
  if (!query.trim()) return {}
  return ['longName', 'shortName'].reduce(
    (acc, key) => ({
      ...acc,
      [key]: getSearchProp(query)
    }),
    {}
  )
}

export const DEFAULT_ACCOUNTS_PAGINATION = { take: 50, skip: 0 }

export const mapClientsMultiQueryResponse = (data) => {
  const {
    assignedClients: { data: clients },
    totalAssignedClients: { total }
  } = data

  return { clients, total }
}

export const mapClientsResponse = (data) => {
  return data?.data || []
}

export const mapClients = (clients) =>
  clients.map(({ clientId, longName, profilePic, extras, clientAbbreviation, shortName }) => {
    const profileData = extras ? parseBadJSon(extras) : {}
    const imageUrl = profilePic || profileData?.profilePic || ''
    return {
      id: clientId,
      name: longName,
      imageUrl,
      clientAbbreviation,
      shortName
    }
  })

export async function saveAccountBillingInfo ({
  account,
  accountDefaultValues
}) {
  const {
    feeScheduleType,
    feeOverride: feeOverrides
  } = account

  const deletedFeeOverrideIds = filterRemovedFeeOverrides(
    accountDefaultValues.feeOverride,
    feeOverrides
  )

  const deletedFeeScheduleId =
    accountDefaultValues?.feeScheduleType && !feeScheduleType
      ? accountDefaultValues?.feeScheduleType
      : null

  await updateAccountBillingInfo(accountDefaultValues.accountId, {
    feeScheduleId: feeScheduleType,
    feeOverrides: mapFeeOverrides(feeOverrides),
    removeFeeOverrides: deletedFeeOverrideIds,
    removeFeeScheduleId: deletedFeeScheduleId
  })
}

export async function saveAccountData ({
  account,
  accountDefaultValues,
  selectedClients
}) {
  const {
    accountId,
    accountDisplayName,
    accountDisplayNumber,
    feeScheduleType,
    feeOverride: feeOverrides,
    tags
  } = account

  const { assigned, unAssigned } = selectedClients

  const deletedFeeOverrideIds = filterRemovedFeeOverrides(
    accountDefaultValues.feeOverride,
    feeOverrides
  )

  const deletedFeeScheduleId =
    accountDefaultValues?.feeScheduleType && !feeScheduleType
      ? accountDefaultValues?.feeScheduleType
      : null

  const accountTags = getGroupedTagsBody(tags, accountDefaultValues.tags)

  const [{ data: accountUpdated }] = await Promise.all([
    editAdminAccount(accountId, {
      displayName: accountDisplayName?.trim() || '',
      displayNumber: accountDisplayNumber,
      taxStatusId: account.taxStatusId
    }),
    manageAccountClientsAssociation(accountId, {
      associateClientIds: assigned.map(({ id }) => id),
      removeClientIds: unAssigned.map(({ id }) => id)
    }),
    updateAccountBillingInfo(accountId, {
      feeScheduleId: feeScheduleType,
      feeOverrides: mapFeeOverrides(feeOverrides),
      removeFeeOverrides: deletedFeeOverrideIds,
      removeFeeScheduleId: deletedFeeScheduleId
    }),
    saveAccountTags(accountId, accountTags)
  ])
  return accountUpdated
}

export async function saveAccountStatus ({
  account,
  newValues
}) {
  await postNamedCommand('accounts', 'updateAccountDefaultStatus', {
    accountId: account.accountId,
    managedStatusId: account.managedStatusId,
    discretionaryStatusId: account.discretionaryStatusId,
    taxStatusId: account.taxStatusId,
    billingStatusId: account.billingStatusId,
    performanceStatusId: account.performanceStatusId,
    ...(newValues.taxStatusId && { taxStatusId: newValues.taxStatusId }),
    ...(newValues.managedStatusId !== null ? { managedStatusId: newValues.managedStatusId } : {}),
    ...(newValues.discretionaryStatusId !== null ? { discretionaryStatusId: newValues.discretionaryStatusId } : {}),
    ...(newValues.performanceStatusId !== null ? { performanceStatusId: newValues.performanceStatusId } : {})
  })
}

export async function saveAccountBillingStartDate ({
  accountId,
  billingStartDate
}) {
  await postNamedCommand('accounts', 'updateAccountBillingStartDate', {
    accountId,
    billingStartDate
  })
}

const getPreviousValues = (id, data) => {
  return {
    positionId: id,
    managedStatusId: data[id]?.managedStatusId,
    discretionaryStatusId: data[id]?.discretionaryStatusId,
    performanceStatusId: data[id]?.performanceStatusId,
    taxStatusId: data[id]?.taxStatusId,
    billingStatusId: data[id]?.billingStatusId
  }
}

const bodyAttributes = [
  'positionId',
  'managedStatusId',
  'discretionaryStatusId',
  'performanceStatusId',
  'taxStatusId',
  'billingStatusId'
]

export async function savePositionsStatus ({
  positionsIds,
  managedStatusId,
  discretionaryStatusId,
  performanceStatusId,
  isAllChecked,
  data
}) {
  let body = {}
  if (isAllChecked) {
    body = Object.values(data).map((position) => ({
      ...pick(position, bodyAttributes),
      ...(managedStatusId !== null ? { managedStatusId } : {}),
      ...(discretionaryStatusId !== null ? { discretionaryStatusId } : {}),
      ...(performanceStatusId !== null ? { performanceStatusId } : {})
    }))
  } else {
    body = positionsIds.map((id) => ({
      ...getPreviousValues(id, data),
      ...(managedStatusId !== null ? { managedStatusId } : {}),
      ...(discretionaryStatusId !== null ? { discretionaryStatusId } : {}),
      ...(performanceStatusId !== null ? { performanceStatusId } : {})
    }))
  }
  await postNamedCommand('accounts', 'updatePositionStatus', body)
}

export const ACCOUNTS_MEMBER_LEVEL_ID = 1

export const levelDatesQuery = { levelType: 'user' }
