import React from 'react'
import PropTypes from 'prop-types'
import Icon from '../../atoms/Icon'
import { ICON_NAMES } from '../../../constants'

const ExpandyIcon = ({ enabled, expanded, loading, className }) => {
  if (!enabled) return null

  if (loading) {
    return (
      <Icon additionalClasses={className} name={ICON_NAMES.circle} />
    )
  }

  if (expanded) {
    return (
      <Icon additionalClasses={className} name={ICON_NAMES.chevronDown} />
    )
  }

  return (
    <Icon additionalClasses={className} name={ICON_NAMES.chevronRight} />
  )
}

ExpandyIcon.propTypes = {
  enabled: PropTypes.bool,
  expanded: PropTypes.bool,
  loading: PropTypes.bool,
  className: PropTypes.string
}

export default ExpandyIcon
