import React from 'react'
import PropTypes from 'prop-types'
import { animated, useSpring } from 'react-spring'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  container: ({ backgroundImage }) => ({
    backgroundColor: theme.palette.background.paper,
    margin: 'inherit',
    position: 'absolute',
    width: '100%',
    height: '100%',
    minHeight: '100%',
    overflowY: 'auto',
    top: 0,
    backgroundImage: `url(${backgroundImage})` || 'url("RiverBack_50.png")',
    backgroundPosition: 'bottom center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
  }),
  content: {
    padding: '3.8rem 2rem 0',
    position: 'relative',
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between'
  }
}))

const AdvisorViewHomeLayout = ({ backgroundImage, children }) => {
  const classes = useStyles({ backgroundImage })
  const springs = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 }
  })

  return (
    <animated.div className={classes.container} style={springs}>
      <div className={classes.content}>
        {children}
      </div>
    </animated.div>
  )
}

AdvisorViewHomeLayout.propTypes = {
  backgroundImage: PropTypes.string,
  children: PropTypes.node
}

export default AdvisorViewHomeLayout
