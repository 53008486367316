import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import LabeledValue from '../components/LabeledValue'

const useStyles = makeStyles((theme) => ({
  objective: {
    padding: '1rem',
    border: `2px solid ${theme.palette.gray.darker}`,
    backgroundColor: ({ status }) => {
      switch (status) {
        case 'processing':
          return theme.palette.gray.main
        case 'finished':
          return theme.palette.green.light
        case 'error':
          return theme.palette.red.light
        default:
          return theme.palette.white
      }
    },
    marginBottom: '.5rem',
    '& > main': {
      display: 'flex',
      flexDirection: 'row'
    },
    '& > main > section': {
      flex: '1 0',
      width: '50%',
      maxWidth: '50%'
    },
    '& > main > section pre': {
      padding: '1rem .5rem',
      backgroundColor: theme.palette.gray.main,
      overflowX: 'scroll'
    }
  }
}))

const Objective = ({ objective }) => {
  const classes = useStyles(objective)

  return (
    <div className={classes.objective}>
      <main>
        <section>
          <header>
            <h3>Objective: {objective.ingestionStepId}</h3>
          </header>
          <LabeledValue label='Status'>{objective.status}</LabeledValue>
          <LabeledValue label='Objective Type'>{objective.objectiveType}</LabeledValue>
          <LabeledValue label='Ordinal'>{objective.ordinal}</LabeledValue>
          <LabeledValue label='Object Mapping ID'>{objective.objectMappingId}</LabeledValue>
        </section>
        <section>
          <LabeledValue label='Step Data'>
            <pre>
              {JSON.stringify(objective.stepData, null, 2)}
            </pre>
          </LabeledValue>
        </section>
      </main>
      {objective.status === 'error' && (
        <h3>❗{objective.stepData?.lastError || 'Unknown Error'}</h3>
      )}
    </div>
  )
}

Objective.propTypes = {
  objective: PropTypes.shape({
    ingestionBatchObjectiveId: PropTypes.number,
    ingestionStepId: PropTypes.string,
    objectMappingId: PropTypes.number,
    status: PropTypes.string,
    ordinal: PropTypes.number,
    stepData: PropTypes.any,
    objectiveType: PropTypes.string
  })
}

export default Objective
