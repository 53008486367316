import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles, alpha } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => {
  return ({
    dataHomeItem: {
      borderRadius: '12px',
      minHeight: '180px',
      width: '220px',
      padding: '.5rem',
      border: `2px solid ${alpha(theme.palette.primary.main, 0.3)}`,
      boxShadow: '0px 7px 7px -5px rgba(0,0,0,0.5)',
      backgroundColor: theme.palette.white,
      backgroundImage: ({ imageUrl }) => imageUrl ? `url(${imageUrl})` : undefined,
      backgroundPosition: 'center 25%',
      backgroundSize: '100%',
      backgroundRepeat: 'no-repeat',
      position: 'relative',
      transition: 'border .3s ease',
      '& > *:hover': {
        textDecoration: 'none !important'
      },
      '&:hover': {
        border: `2px solid ${theme.palette.primary.main} !important`,
        '& .__ds-title': {
          height: '54px'
        }
      },

      '& .__ds-title': {
        position: 'absolute',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        bottom: -2,
        left: 0,
        height: '36px',
        color: theme.palette.white,
        width: '100%',
        fontSize: '0.85rem',
        padding: '.5rem',
        borderBottomLeftRadius: '12px',
        borderBottomRightRadius: '12px',
        backgroundColor: theme.palette.primary.main,
        transition: 'height .3s ease-in-out',
        overflow: 'auto'
      }
    }
  })
})

const DataHomeItem = ({ name, imageUrl, onHover }) => {
  const classes = useStyles({ imageUrl })

  return (
    <div className={classes.dataHomeItem} onMouseOver={onHover}>
      <div className='__ds-title'>{name}</div>
    </div>
  )
}

DataHomeItem.propTypes = {
  name: PropTypes.string.isRequired,
  imageUrl: PropTypes.string,
  onHover: PropTypes.func
}

DataHomeItem.defaultProps = {
  onHover: () => {}
}

export default DataHomeItem
