import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useOktaAuth } from '@okta/okta-react'
import {
  Typography,
  TextField,
  Box,
  Button,
  InputAdornment
} from '@material-ui/core'
import PersonOutlineIcon from '@material-ui/icons/PersonOutline'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import { Controller, useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import clsx from 'clsx'
import { useToggle } from '../../../hooks'
import { getRecoveryQuestion } from '../../../service'
import SecurityQuestionsForm from '../../organisms/SecurityQuestionsForm'
import { emailRules, validatePassword } from '../admin/common'
import { parseUrlSearchParams } from '../../../utils/parseUrlSearchParams'
import BouncingDotsLoader from '../../atoms/BouncingDotsLoader'
import FadeIn from '../../molecules/Transitions/FadeIn'
import { useFirmInfo } from './hooks'
import { usePublicStyles } from './usePublicStyles'

const getInputProps = ({ classes, adornmentIcon: AdornmentIcon }) => ({
  startAdornment: (
    <InputAdornment position='start' className={classes.inputIcon}>
      <AdornmentIcon fontSize='large' />
    </InputAdornment>
  ),
  className: classes.inputBase,
  classes: {
    input: classes.input
  },
  disableUnderline: true
})

function LoginView ({ loginError }) {
  const history = useHistory()
  const { oktaAuth } = useOktaAuth()
  const [hasError, setHasError] = useState(null)
  const [signInText, setSignInText] = useState('Sign In')
  const [loading, , setLoadingOn, setLoadingOff] = useToggle()
  const [showSecurityForm, , setShowSecurityFormOn] = useToggle()
  const firmInfo = useFirmInfo()
  const classes = usePublicStyles()
  const [authInfo, setAuthInfo] = useState(null)

  const {
    handleSubmit,
    control,
    formState: { isSubmitting, isDirty, isValid, errors }
  } = useForm({
    mode: 'onChange',
    defaultValues: { email: '', password: '' }
  })

  const params = parseUrlSearchParams(history)
  const fromLogin = params?.fromLogin?.[0] || false
  useEffect(() => {
    if (fromLogin) {
      oktaAuth.signInWithRedirect({
        prompt: 'none'
      }).catch(_ => {
        history.replace('/')
      })
    }
  }, [history, oktaAuth, fromLogin])

  const onSubmitHandler = useCallback(
    async ({ email, password }) => {
      try {
        setHasError(false)
        setLoadingOn()
        setSignInText('Signing In Securely...')

        const { sessionToken, user } = await oktaAuth.signInWithCredentials({
          username: email,
          password
        })

        const { data: { question: recoveryQuestion } } = await getRecoveryQuestion({
          id: user.id
        })

        if (recoveryQuestion) {
          oktaAuth.signInWithRedirect({ sessionToken, originalUri: window.location.pathname })
        } else if (sessionToken) {
          setAuthInfo({ email, sessionToken, password })
          setShowSecurityFormOn()
        }
      } catch {
        setLoadingOff()
        setSignInText('Sign In')
        setHasError(true)
      }
    },
    [
      oktaAuth,
      setLoadingOn,
      setLoadingOff,
      setShowSecurityFormOn
    ]
  )

  useEffect(() => {
    isDirty && isValid && setHasError(false)
  }, [isValid, isDirty])

  if (!firmInfo) {
    return null
  }
  const { logoPath, title } = firmInfo

  if (fromLogin) {
    return (
      <FadeIn>
        <Box
          display='flex'
          flexDirection='column'
          alignItems='center'
          width='100%'
        >
          {logoPath && <img src={logoPath} alt='logo' className={classes.logo} />}
        </Box>
        <Typography component='h2' className={classes.title}>{title}</Typography>
        <BouncingDotsLoader />
      </FadeIn>
    )
  }

  if (showSecurityForm) {
    return (
      <FadeIn>
        <SecurityQuestionsForm
          logoPath={logoPath}
          authInfo={authInfo}
          isLoginFlow
        />
      </FadeIn>
    )
  }

  return (
    <>
      <FadeIn className={classes.formWrapper}>
        <Box
          display='flex'
          flexDirection='column'
          alignItems='center'
          width='100%'
        >
          {logoPath && <img src={logoPath} alt='logo' className={classes.logo} />}
        </Box>
        <Typography component='h2' className={classes.title}>{title}</Typography>
        <form onSubmit={handleSubmit(onSubmitHandler)} className={classes.form}>
          <div
            className={clsx(classes.field, {
              [classes.fieldError]: errors.email
            })}
          >
            <Controller
              name='email'
              render={({ field: { onChange } }) => (
                <TextField
                  fullWidth
                  autoFocus
                  color='secondary'
                  autoComplete='email'
                  placeholder='Enter Email'
                  error={Boolean(errors.email)}
                  onChange={onChange}
                  InputProps={getInputProps({
                    classes,
                    adornmentIcon: PersonOutlineIcon
                  })}
                />)}
              control={control}
              rules={emailRules}
            />
          </div>
          <div
            className={clsx(classes.field, {
              [classes.fieldError]: errors.password
            })}
          >
            <Controller
              name='password'
              render={({ field: { onChange } }) => (
                <TextField
                  fullWidth
                  autoFocus
                  type='password'
                  placeholder='Password'
                  error={Boolean(errors.password)}
                  onChange={onChange}
                  InputProps={getInputProps({
                    classes,
                    adornmentIcon: LockOutlinedIcon
                  })}
                />)}
              control={control}
              rules={{ validate: (password) => validatePassword({ password }) }}
            />
          </div>
          {(hasError || loginError) && (
            <Typography className={classes.errorMessage}>
              Your email or password is incorrect.
            </Typography>
          )}
          <Button
            type='submit'
            fullWidth
            variant='outlined'
            className={classes.signInButton}
            onClick={handleSubmit}
            disabled={!isDirty || !isValid || loading || isSubmitting}
            classes={{
              disabled: classes.disabled
            }}
          >
            {signInText}
          </Button>
        </form>
        <Typography
          component='h3'
          className={classes.forgotPassword}
          onClick={() => history.push('/forgot-password')}
        >
          Forgot your password?
        </Typography>
      </FadeIn>
    </>
  )
}

LoginView.propTypes = {
  loginError: PropTypes.bool
}

LoginView.defaultProps = {
  loginError: false
}

export default LoginView
