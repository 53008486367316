import { Box, CircularProgress, makeStyles } from '@material-ui/core'
import React, { useCallback, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import { noop } from 'lodash'
import clsx from 'clsx'
import utc from 'dayjs/plugin/utc'
import { createBillingRunExports, getBillingRunFile } from '../../../../../service'
import { getFileExtension } from '../../../../../utils'
import SydButton from '../../../../commonDesign/Button'
import Icon from '../../../../atoms/Icon'
import { ICON_NAMES } from '../../../../../constants'
import { useContextContainer } from '../../../../../abundanceEngine/components/ContextContainer'
import Text from '../../../../atoms/Text'
import { BILLING_RUNS_CONTEXT_KEY, REPORT_TYPE_NAMES } from './helpers'

dayjs.extend(utc)

const BILLING_RUN_STATUSES = {
  PENDING: 'PENDING'
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '5px',
    maxWidth: '200px'
  },
  link: {
    display: 'block'
  },
  disabled: {
    cursor: 'not-allowed !important',
    opacity: '0.7'
  },
  regenerateCta: {
    width: '100%',
    background: theme.palette.gray.light,
    border: `1px solid ${theme.palette.gray.darker}`,
    padding: '6px 12px',
    marginBottom: '4px',
    borderRadius: '12px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
    gap: '8px'
  }
}))

const CellDownload = ({
  row: {
    original: { levelType, status, files, adjustmentStatus, billingRunId }
  }
}) => {
  const classes = useStyles()
  const [{ refetchBillingRuns }] = useContextContainer(BILLING_RUNS_CONTEXT_KEY)
  const [loadingIds, setLoadingIds] = useState([])

  const onClick = useCallback(
    async (event, billingRunFileId, fileName) => {
      event.preventDefault()
      setLoadingIds((loadingIds) => [...new Set([...loadingIds, billingRunFileId])])

      const { data: fileMeta } = await getBillingRunFile(billingRunFileId)
      if (!fileMeta.downloadLink) {
        setLoadingIds((loadingIds) => ({ ...loadingIds, [billingRunFileId]: true }))
        return
      }

      const fileExtension = getFileExtension(fileName, false)
      const link = document.createElement('a')
      link.href = fileMeta.downloadLink

      const levelName = levelType?.toLowerCase()
      const dateFormatted = dayjs().format('YYYY-MM-DD')
      link.download = `run_billing_report_${levelName}_${dateFormatted}.${fileExtension}`
      document.body.appendChild(link)

      link.click()
      link.remove()
      setLoadingIds((loadingIds) => loadingIds.filter(x => x !== billingRunFileId))
    },
    [levelType]
  )

  const hasFeeAdjustmentAfterMaxExport = useMemo(() => {
    const { maxExportDate, maxFeeAdjustmentDate } = adjustmentStatus ?? {}
    const maxExportDateObj = dayjs.utc(maxExportDate)
    const maxFeeAdjustmentDateObj = dayjs.utc(maxFeeAdjustmentDate)

    if (!maxExportDateObj.isValid() || !maxFeeAdjustmentDateObj.isValid()) {
      return false
    }

    return maxExportDateObj.isBefore(maxFeeAdjustmentDateObj)
  }, [adjustmentStatus])

  const onClickCreateBillingExports = useCallback(async () => {
    try {
      setLoadingIds((loadingIds) => [...new Set([...loadingIds, 'createExports'])])
      await createBillingRunExports({ billingRunId })
      await new Promise((resolve) => setTimeout(resolve, 3000))
      refetchBillingRuns()
      setLoadingIds((loadingIds) => loadingIds.filter(x => x !== 'createExports'))
    } catch (err) {
      console.error(err)
    }
  }, [billingRunId, refetchBillingRuns])

  if (!files) {
    return null
  }

  return (
    <div className={classes.container}>
      <div>
        {hasFeeAdjustmentAfterMaxExport && (
          <div className={classes.regenerateCta}>
            <Text variant='body2' text='This Billing run has fee adjustments since last export.' />
            <SydButton
              variant='primary'
              size='sm'
              onClick={() => onClickCreateBillingExports()}
            >
              <Box display='flex' gridGap='10px' maxWidth='200px'>
                {loadingIds.includes('createExports') ? (
                  <>
                    <CircularProgress size='14px' />
                    <span>Loading...</span>
                  </>
                ) : (
                  <div>
                    Regenerate Exports
                  </div>
                )}
              </Box>
            </SydButton>
          </div>
        )}
      </div>
      {(files || []).map(({ billingRunFileId, type, fileName }) => {
        const disabled = status === BILLING_RUN_STATUSES.PENDING
        const loading = loadingIds?.includes(billingRunFileId)
        const reportName = REPORT_TYPE_NAMES?.[type] ?? type
        return (
          <SydButton
            variant='outline'
            size='xs'
            key={billingRunFileId}
            href='#'
            disabled={disabled || loading}
            className={clsx(classes.link, {
              [classes.disabled]: disabled
            })}
            onClick={
              disabled || loading ? noop : (event) => onClick(event, billingRunFileId, fileName)
            }
          >
            <Box display='flex' gridGap='10px'>
              {loading ? <CircularProgress size='14px' /> : <Icon name={ICON_NAMES.download} />}
              {reportName}
            </Box>
          </SydButton>
        )
      })}
    </div>
  )
}

CellDownload.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      billingRunId: PropTypes.number,
      levelType: PropTypes.string,
      status: PropTypes.string,
      files: PropTypes.array,
      adjustmentStatus: PropTypes.shape({
        maxExportDate: PropTypes.string,
        maxFeeAdjustmentDate: PropTypes.string
      })
    })
  })
}

export default CellDownload
