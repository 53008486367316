import { DAYJS_OPERATIONS, DAYJS_UNIT_TYPES } from '../constants'
import { getSafeDate } from '../utils'

class SafeDate {
  constructor ({ min, max, mainDate }) {
    this.minDate = min
    this.maxDate = max
    this.mainDate = mainDate
  }

  add () {
    this.operation = DAYJS_OPERATIONS.ADD
    return this
  }

  subtract () {
    this.operation = DAYJS_OPERATIONS.SUBTRACT
    return this
  }

  startOf () {
    this.operation = DAYJS_OPERATIONS.START_OF
    return this
  }

  _applyUnitType (unitValue, unitType) {
    this.unitType = unitType
    if (unitValue !== undefined) {
      return this.build(unitValue)
    }
    return this
  }

  day (unitValue) {
    return this._applyUnitType(unitValue, DAYJS_UNIT_TYPES.DAY)
  }

  month (unitValue) {
    return this._applyUnitType(unitValue, DAYJS_UNIT_TYPES.MONTH)
  }

  year (unitValue) {
    return this._applyUnitType(unitValue, DAYJS_UNIT_TYPES.YEAR)
  }

  quarter (unitValue) {
    return this._applyUnitType(unitValue, DAYJS_UNIT_TYPES.QUARTER)
  }

  build (unitValue) {
    return getSafeDate(
      {
        mainDate: this.mainDate,
        minDate: this.min,
        maxDate: this.max
      },
      {
        operation: this.operation,
        unitType: this.unitType,
        unitValue: this.unitValue || unitValue
      }
    )
  }
}

export default SafeDate
