import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'
import { CALC_TYPES, LEVEL_TYPES } from '../../constants'
import { useFetchState } from '../../hooks'
import { useAppContext } from '../../redux/slices/appContext'
import { fetchHoldingReport } from '../../service'
import {
  mapHoldingsTableRows,
  mapHoldingsTableTotalRow
} from '../../utils/tableHelper'
import PerformanceAllocationTable from './PerformanceAllocationTable'

const portfolioHoldingsTableColumns = [
  { alignment: 'left', name: 'Holdings' },
  { name: 'Allocation' },
  { colSpan: 2, name: 'Market Value' }
]

const PortfolioAllocationHoldingsTable = ({
  viewFilters,
  viewContext,
  fetchParams,
  allowDownloadTable
}) => {
  const appContext = useAppContext()
  const { assetClassId, mainDate } = viewContext

  const fetchHoldingsReportData = useCallback(
    async (setSafeState) => {
      const {
        ASSET_CLASSES,
        SUBCLASSES,
        ASSETS_SUBCLASS,
        ASSETS: _ASSETS
      } = viewFilters

      const ASSETS = ASSETS_SUBCLASS || _ASSETS
      const assetClassTagIds = ASSET_CLASSES ? [ASSET_CLASSES?.id] : []
      const subclassTagIds = SUBCLASSES ? [SUBCLASSES?.id] : []
      const assetIds =
        ASSETS || ASSETS_SUBCLASS ? [ASSETS?.id || ASSETS_SUBCLASS?.id] : []

      const levelTypes = [
        LEVEL_TYPES.CLIENT,
        ASSET_CLASSES && LEVEL_TYPES.ASSET_CLASS_TAG,
        SUBCLASSES && LEVEL_TYPES.SUBCLASS_TAG,
        LEVEL_TYPES.ASSETS
      ].filter(Boolean)

      let {
        data: { assets, total }
      } = await fetchHoldingReport({
        startDate: mainDate,
        endDate: mainDate,
        clientIds: [appContext.clientId],
        assetClassTagIds,
        subclassTagIds,
        assetIds,
        levelTypes,
        calcType: CALC_TYPES.balance,
        ...(isEmpty(fetchParams) ? {} : fetchParams)
      })

      assets = assets.sort(
        (assetA, assetB) =>
          parseFloat(assetB.ordinal) + parseFloat(assetA.ordinal)
      )
      setSafeState({
        holdingsReportRows: mapHoldingsTableRows(assets, total, assetClassId),
        holdingsReportTotalRows: mapHoldingsTableTotalRow(total)
      })
    },
    [
      appContext.clientId,
      assetClassId,
      viewFilters,
      fetchParams,
      mainDate
    ]
  )

  const {
    holdingsReportRows = [],
    holdingsReportTotalRows = [],
    loading
  } = useFetchState(fetchHoldingsReportData)

  if (loading) {
    return null
  }

  return (
    <PerformanceAllocationTable
      rows={holdingsReportRows}
      columns={portfolioHoldingsTableColumns}
      finalRow={holdingsReportTotalRows}
      allowDownload={allowDownloadTable}
      assetFilters={viewFilters}
      accountCategoryIds={fetchParams?.accountCategoryIds}
    />
  )
}

PortfolioAllocationHoldingsTable.propTypes = {
  viewFilters: PropTypes.object,
  viewContext: PropTypes.object,
  fetchParams: PropTypes.object,
  allowDownloadTable: PropTypes.bool
}

export default PortfolioAllocationHoldingsTable
