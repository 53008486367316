import React from 'react'
import PropTypes from 'prop-types'
import OperationalTable from '../../organisms/OperationalTable'
import { useTransactionsContext } from './TransactionsProvider'
import { useTransactionsColumns } from './TransactionsColumnContext'

const TransactionsTable = ({
  variant
}) => {
  const {
    transactions,
    transactionsLoading,
    counts,
    onPagingChange,
    onSortingChange
  } = useTransactionsContext()

  const columnConfig = useTransactionsColumns()

  return (
    <>
      <OperationalTable
        variant={variant}
        mode='server'
        columns={columnConfig.columns}
        data={transactions}
        defaultSort={columnConfig.defaultSort}
        itemName='Transactions'
        total={counts?.total}
        loading={transactionsLoading}
        onPagingChange={onPagingChange}
        onSortingChange={onSortingChange}
        onExpandChange={() => {}}
        showTotalItemsLabel
      />
    </>
  )
}

TransactionsTable.propTypes = {
  variant: PropTypes.string
}

TransactionsTable.defaultProps = {
  variant: 'v2',
  defaultFilter: {},
  enableFirmWideView: false
}

TransactionsTable.Wrapper = OperationalTable.Wrapper
TransactionsTable.SuperHeader = OperationalTable.SuperHeader
TransactionsTable.Search = OperationalTable.Search

export default TransactionsTable
