import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import { Blade } from '../shared/Blade'
import SydButton from '../../../../../commonDesign/Button'
import { useClientReviewContext } from '../ClientReviewContext'
import { usePolicy } from '../../../../../../hooks/usePolicy'
import OverrideList from './OverrideList'

const useStyles = makeStyles((theme) => ({
  overrides: {
    padding: theme.layout.padding.medium
  },
  actions: {
    padding: theme.layout.padding.medium,
    display: 'flex',
    flexDirection: 'row-reverse',
    borderBottom: `1px solid ${theme.palette.gray.darker}`
  }
}))

function ClassOverridesDetails ({ overrides }) {
  const classes = useStyles()
  const canEditOverrides = usePolicy('billing_edit_overrides')
  const cr = useClientReviewContext()

  if (!overrides) {
    return null
  }

  return (
    <Blade>
      {canEditOverrides ? (
        <div className={classes.actions}>
          <SydButton size='xs' icon='add' onClick={() => cr.dialogs.addClassOverride()}>Add Class Override</SydButton>
        </div>
      ) : null}
      <div className={classes.overrides}>
        {!overrides?.length ? (
          <div className='__no-content'>
            No Class Overrides
          </div>
        ) : (
          <OverrideList
            overrides={overrides}
            display={a => (
              <span>{a.classificationTagName}</span>
            )}
          />
        )}
      </div>
    </Blade>
  )
}

ClassOverridesDetails.propTypes = {
  overrides: PropTypes.any
}

export default ClassOverridesDetails
