import React from 'react'
import { lighten, makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'
import { useContextualClientContext } from '../context/ContextualClientContext'
import { getRandomColor } from '../../../../utils'
import Text from '../../../atoms/Text'
import Skeleton from '../../../atoms/Skeleton'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '0.5rem',
    maxWidth: '26rem'
  },
  item: {
    padding: '0.25rem 0.5rem',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '0.5rem',
    gap: '6px',
    border: '1px solid #E7E7E7',
    background: theme.palette.white,
    fontWeight: 'bold'
  }
}))

const ClientTags = ({ emptySectionLabel }) => {
  const classes = useStyles()
  const { client, isLoading } = useContextualClientContext()

  if (isLoading) {
    return (
      <div className={classes.container}>
        <Skeleton variant='text' width='4rem' height='2rem' />
        <Skeleton variant='text' width='4rem' height='2rem' />
        <Skeleton variant='text' width='4rem' height='2rem' />
      </div>
    )
  }

  if (isEmpty(client?.groups) && !isLoading && emptySectionLabel) {
    return <Text text={emptySectionLabel} />
  }

  return (
    <div className={classes.container}>
      {client?.groups?.map((group) => {
        const fallbackColor = getRandomColor(500)
        const color = group?.color || fallbackColor || '#000'
        const lightenColor = lighten(color, 0.9)

        return (
          <div
            key={group.groupId}
            className={classes.item}
            style={{
              backgroundColor: lightenColor,
              border: `1px solid ${color}`
            }}
          >
            {group.name}
          </div>
        )
      })}
    </div>
  )
}

ClientTags.propTypes = {
  emptySectionLabel: PropTypes.string
}

ClientTags.defaultProps = {
  emptySectionLabel: 'No tags'
}

export default ClientTags
