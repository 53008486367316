import React, { useEffect, useMemo, useState } from 'react'
import { makeStyles } from '@material-ui/core'
import { Link, useParams } from 'react-router-dom'
import dayjs from 'dayjs'
import { ADMIN_ROUTES, ALERT_SEVERITY, BUTTON_VARIANT, ICON_NAMES, TEXT_VARIANTS } from '../../../../../constants'
import Text from '../../../../atoms/Text'
import { useBoolean } from '../../../../../hooks'
import OperationalTable, { useOperationalTable } from '../../../../organisms/OperationalTable'
import SnackAlert from '../../../../molecules/SnackAlert/SnackAlert'
import {
  cellTemplates,
  useListReportRuntimeConfigurationData,
  useListReportsData,
  useListReportTemplatesData,
  useReportsColumnConfig
} from '../hooks'
import PostToVaultModal from '../PostToVault/PostToVaultModal'
import ReportPreviewModal from '../ReportPreview/ReportPreviewModal'
import ReportRunRefreshActions from '../ReportRunRefreshActions'
import Icon from '../../../../atoms/Icon'
import { reportStatusClasses } from '../classes'
import ReportSectionHeader from '../ReportSectionHeader'
import { getBatchDownload } from '../../../../../service'
import RoundedButton from '../../../../atoms/RoundedButton'
import { batchStatusMap, useGetBatchRunData } from './hooks'
import { BatchRunStatuses } from './BatchRunActions'

const useStyles = makeStyles(() => ({
  container: {
    padding: '1rem',
    display: 'flex',
    flex: '1',
    flexDirection: 'column'
  },
  statusContainer: {
    width: '6rem',
    display: 'inline-block'
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    padding: '1rem 0 0',
    gap: '1rem',
    '& a': {
      opacity: 0.25,
      '&.active': {
        opacity: 1
      }
    }
  },
  pageHeader: {
    margin: '2rem 0 0.25rem'
  },
  batchOverview: {
    display: 'flex',
    justifyContent: 'space-between',
    background: '#eee',
    padding: '1rem',
    margin: '0 0 1rem',
    borderRadius: '.6rem',
    border: '1px solid #AAAAAA',
    '& p': {
      display: 'inline'
    }
  },
  tableHeader: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between'
  },
  backlink: {
    display: 'flex',
    alignItems: 'center',
    margin: '0 0 .5rem'
  },
  ...reportStatusClasses
}))

const BatchDetailView = () => {
  const params = useParams()
  const classes = useStyles()
  const [previewUrl, setPreviewUrl] = useState('')
  const [postToVaultData, setPostToVaultData] = useState({})
  const [alert, setAlert] = useState({})

  // template Data
  const {
    templatesById,
    refetch: refetchTemplates,
    isStale: isTemplatesStale
  } = useListReportTemplatesData({
    offset: 0,
    limit: 1000,
    sort: ''
  })
  useEffect(() => {
    if (isTemplatesStale) {
      refetchTemplates()
    }
  }, [isTemplatesStale, refetchTemplates])

  const { runtimeConfigurationById } = useListReportRuntimeConfigurationData({
    offset: 0,
    limit: 1000,
    sort: ''
  })

  // Reports Table
  const reportsColumnConfig = useReportsColumnConfig({
    classes,
    includeRunBy: false,
    includeTemplate: false,
    onPostRerunReport: () => refetchData(),
    onSetPreviewUrl: (htmlUrl) => setPreviewUrl(htmlUrl),
    onSetPostToClientVault: ({ clientId, title, reportRunId }) => setPostToVaultData({ clientId, title, reportRunId })
  })
  const {
    pageIndex,
    pageSize,
    sort,
    onPagingChange,
    onSortingChange
  } = useOperationalTable(reportsColumnConfig)

  const options = useMemo(() => ({
    batchRunId: params.batchRunId,
    additionalColumns: 'clientId,clientName',
    excludeBatchRuns: false,
    pageIndex,
    pageSize,
    sort
  }), [params, pageIndex, pageSize, sort])

  const {
    data,
    loading,
    refetch: refetchData
  } = useListReportsData(options)

  // Get Batch Run Data
  const {
    data: batchRun,
    loading: isBatchRunLoading,
    refetch: refetchBatchRun
  } = useGetBatchRunData({ batchRunId: params.batchRunId })

  // Loading State
  const isPageLoading = useMemo(() => !!(loading || isBatchRunLoading), [loading, isBatchRunLoading])

  const triggerRefetch = async () => {
    return await Promise.all([refetchData(), refetchBatchRun()])
  }

  const onPostToVaultSubmit = async (alert) => {
    if (alert) {
      setAlert(alert)
    }
    setPostToVaultData({})
    await refetchData()
  }

  const [isDownloadLoading, setIsDownloadLoading] = useBoolean(false)

  const onClickDownloadButton = async () => {
    try {
      setIsDownloadLoading.on()
      const { data } = await getBatchDownload(batchRun.batchRunId, { forceNew: false })
      setIsDownloadLoading.off()

      if (!data?.fileLocation) {
        throw new Error('Issue getting batch download')
      }
      return window.open(data.fileLocation, '_blank')
    } catch (err) {
      setIsDownloadLoading.off()
      setAlert({
        openAlert: true,
        alertMessage: 'Issue getting batch download.  Please try again',
        alertSeverity: ALERT_SEVERITY.error
      })
    }
  }

  const showDownloadButton = useMemo(() => (
    [BatchRunStatuses.Completed, BatchRunStatuses.CompletedWithErrors].includes(batchRun?.status)
  ), [batchRun?.status])

  return (
    <div className={classes.container}>
      <ReportSectionHeader />

      <Text text='Report Details' variant={TEXT_VARIANTS.h2} className={classes.pageHeader} />
      <div className={classes.batchOverview}>
        <div>
          <div>
            <Text text='Batch Status: ' variant={TEXT_VARIANTS.body} customFontWeight='bold' />
            {
              (!batchRun || isBatchRunLoading)
                ? 'Loading...'
                : cellTemplates.status({
                  classes,
                  statusMap: batchStatusMap
                })({ row: { original: batchRun } })
            }
          </div>
          <div>
            <Text text='As of Date: ' variant={TEXT_VARIANTS.body} customFontWeight='bold' />
            <Text
              text={isBatchRunLoading ? 'Loading...' : dayjs(batchRun.inputParams?.endDate).format('M/D/YYYY')}
              variant={TEXT_VARIANTS.body}
            />
          </div>
          <div>
            <Text text='Template: ' variant={TEXT_VARIANTS.body} customFontWeight='bold' />
            <Text
              text={isBatchRunLoading ? 'Loading...' : templatesById[`${batchRun?.batchTemplateId}`]?.name}
              variant={TEXT_VARIANTS.body}
            />
          </div>
          <div>
            <Text text='Runtime Configuration: ' variant={TEXT_VARIANTS.body} customFontWeight='bold' />
            <Text
              text={isBatchRunLoading ? 'Loading...' : runtimeConfigurationById[`${batchRun?.runtimeConfigurationId}`]?.name}
              variant={TEXT_VARIANTS.body}
            />
          </div>
        </div>
        <div>
          {showDownloadButton && (
            <RoundedButton
              type='button'
              onClick={() => onClickDownloadButton()}
              disabled={isDownloadLoading || data?.fileLocation}
              primary
              variant={BUTTON_VARIANT.contained}
            >
              {isDownloadLoading ? 'Generating download....' : 'Download batch'}
            </RoundedButton>
          )}
        </div>
      </div>

      <div className={classes.tableHeader}>
        <div>
          <Link to={ADMIN_ROUTES.REPORTS_BATCHES} className={classes.backlink}>
            <Icon name={ICON_NAMES.arrowBack} />
            <Text text='Return to List' variant={TEXT_VARIANTS.subtitle1} />
          </Link>
          <Text text='Reports in Batch' variant={TEXT_VARIANTS.h2} />
        </div>
        <ReportRunRefreshActions
          intervalInSeconds={10}
          loading={isPageLoading}
          onRefetch={triggerRefetch}
        />
      </div>

      <PostToVaultModal
        data={postToVaultData}
        onSubmit={onPostToVaultSubmit}
        onClose={() => setPostToVaultData({})}
      />

      <ReportPreviewModal
        previewUrl={previewUrl}
        onSubmit={refetchData}
        onClose={() => setPreviewUrl('')}
      />

      <OperationalTable.Wrapper>
        <OperationalTable
          mode='server'
          columns={reportsColumnConfig.columns}
          data={data}
          defaultPageSize={100}
          defaultSort={reportsColumnConfig.defaultSort}
          itemName='Reports'
          loading={loading}
          total={data?.length || 0}
          onSortingChange={onSortingChange}
          onPagingChange={onPagingChange}
        />
      </OperationalTable.Wrapper>
      <SnackAlert alert={alert} />
    </div>
  )
}

export default BatchDetailView
