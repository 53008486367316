import React, { createContext, useCallback, useContext, useEffect, useLayoutEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom'
import { isEmpty, isFunction } from 'lodash'
import { useAssetSearch, useAssetTypes, useClassificationTagTypes } from '../../../../api/coreData'
import { useOperationalTable } from '../../../organisms/OperationalTable'
import { parseSearchParams, stringifySearchParams } from '../../../../utils'
import { useDeepCompareMemo } from '../../../../hooks'
import { localStorageHelper } from '../../../../utils/localStorageHelper'
import { DIALOG_STORAGE_KEYS as STR_KEYS } from '../../../organisms/GroupingProvider/GroupingCustomizeColumnsDialog/helpers'
import { mapOptionsFromSearchParams } from '../Accounts/AccountsListTab/helpers'
import { useTotalClassifyAssets } from './hooks'
import { COLUMN_FILTER_MAPPING, COLUMN_IDS, getVisibleFilterColIds, mapAssetTypeOptions, useColumns } from './helpers'

export const ClassifyAssetsContext = createContext()

export const useClassifyAssetsContext = () => {
  const contextValue = useContext(ClassifyAssetsContext)
  return contextValue
}

const mapSort = (sorts) => {
  return sorts?.map(({ id, desc }) => ({
    field: id,
    dir: desc ? 'desc' : 'asc'
  }))
}

const textSearchFields = ['assetName', 'displayName', 'longName', 'assetIdentifier', 'cusip', 'symbol']

const mapSearch = (searchText) => {
  if (!searchText) return {}
  return textSearchFields.reduce((prev, field) => {
    prev[field] = [{ op: 'contains', value: searchText }]
    return prev
  }, {})
}

const classificationTagQuery = {
  query: {
    includes: {
      classificationTags: true
    },
    resultType: 'details'
  },
  queryOptions: {
    mapper: (data) => {
      return data.map((datum) => {
        return {
          tagTypeId: datum.classificationTagTypeId,
          tagTypeName: datum.longName || datum.shortName,
          payload: datum,
          tags: (datum?.classificationTags || []).map(tag => {
            return {
              tagName: tag.longName || tag.shortName,
              tagId: tag.classificationTagId,
              payload: tag
            }
          })
        }
      })
    }
  }
}

const mapAssetsFiltersFromTagIds = (tagIds, tagTypes) => {
  const tagTypeFilters = tagTypes.reduce((acc, tagType) => {
    const tags = tagType.tags.filter(({ tagId }) => tagIds.includes(tagId))
    if (isEmpty(tags)) return acc
    return {
      ...acc,
      [tagType.tagTypeId]: {
        tagTypeId: tagType.tagTypeId,
        tagTypeName: tagType.tagTypeName,
        tagIds: tags.map(({ tagId }) => tagId)
      }
    }
  }, [])
  return Object.values(tagTypeFilters)
}

const initialState = {
  classificationFilters: [],
  ...Object.values(COLUMN_FILTER_MAPPING).reduce(
    (acc, filterKey) => {
      return { ...acc, [filterKey]: [] }
    }, {})
}

const ClassifyAssetsContextProvider = ({
  children,
  urlFiltersMap,
  defaultColumnConfig,
  localStorageConfigurationKey
}) => {
  const history = useHistory()
  const location = useLocation()
  const [visibleFilters, setVisibleFilters] = useState([
    COLUMN_IDS.ASSET_TYPE
  ])
  const [assetsFilters, setAssetsFilters] = useState(initialState)

  const {
    data: classificationTagTypes,
    isLoading: isLoadingClassificationTagTypes
  } = useClassificationTagTypes(
    classificationTagQuery.query,
    classificationTagQuery.queryOptions
  )

  const operationalTable = useOperationalTable({
    defaultSort: defaultColumnConfig.defaultSort
  })

  const { data: classifyAssetsTotal } = useTotalClassifyAssets({
    textSearch: operationalTable.searchText
  })

  const currentSearchParams = useMemo(() => {
    return new URLSearchParams(location.search)
  }, [location.search])

  const searchParams = useDeepCompareMemo(() => {
    const params = parseSearchParams(currentSearchParams)
    return !isEmpty(params) ? params : null
  }, [currentSearchParams])

  const { url } = useRouteMatch()

  useLayoutEffect(
    function initVisibleFilters () {
      const configuration = localStorageHelper.load(
        `${STR_KEYS.COLUMNS_DND_ORDER_TOGGLER}_${localStorageConfigurationKey}`
      )
      if (configuration) {
        const { columnOrder } = configuration
        setVisibleFilters([...columnOrder])
      }
    },
    [localStorageConfigurationKey]
  )

  const updateUrlSearchParams = useCallback(() => {
    const {
      classificationFilters,
      assetTypeFilters
    } = assetsFilters

    if (isEmpty(assetTypeFilters) && isEmpty(classificationFilters)) {
      return
    }

    const classificationTagIds = Object
      .values(classificationFilters)
      .map(({ tagIds }) => tagIds)
      .flat()

    const assetTypesFilterIds = assetTypeFilters.map(({ value }) => value)

    const searchParams = {
      ...(!isEmpty(classificationTagIds)
        ? { classificationFilters: classificationTagIds }
        : {}),
      ...(!isEmpty(assetTypesFilterIds)
        ? { assetTypeFilters: assetTypesFilterIds }
        : {})
    }

    const params = new URLSearchParams(
      !isEmpty(searchParams) ? stringifySearchParams(searchParams) : {}
    )

    history.replace({
      pathname: url,
      search: params.toString()
    })
  }, [assetsFilters, history, url])

  useEffect(() => {
    // update URL params on each filter change
    updateUrlSearchParams()
  }, [updateUrlSearchParams])

  const urlFilters = useMemo(() => {
    if (!searchParams || isEmpty(searchParams)) return null

    return Object.entries(searchParams).reduce(
      (acc, [paramKey, paramValue]) => {
        if (!urlFiltersMap[paramKey] || !paramValue) return acc
        const { filterKey, op } = urlFiltersMap[paramKey]
        return { ...acc, [filterKey]: [{ op, value: paramValue }] }
      },
      {}
    )
  }, [searchParams, urlFiltersMap])

  const onChangeFilters = useCallback(
    (filtersState) => {
      setAssetsFilters((prevState) => {
        const state = isFunction(filtersState)
          ? filtersState(prevState)
          : filtersState

        // intercepts filters state change and clears url if none are selected
        if (Object.values(state).every(isEmpty)) {
          history.replace({ pathname: url })
        }
        return state
      })
    },
    [history, url]
  )

  const assetsQueryParams = useMemo(() => {
    const skip = operationalTable.pageSize * operationalTable.pageIndex || 0
    const take = operationalTable.pageSize
    const classificationTagId = assetsFilters?.classificationFilters
      ?.filter(({ tagIds }) => !isEmpty(tagIds))
      .map(({ tagIds }) => {
        return { op: 'in', value: tagIds, combine: 'and' }
      })

    const assetTypeIds = assetsFilters?.assetTypeFilters.map(
      ({ value }) => value
    )

    return {
      query: {
        skip,
        take,
        sort: mapSort(operationalTable.sort),
        textSearch: mapSearch(operationalTable.searchText),
        includes: {
          classificationTags: true,
          assetType: true
        },
        filters: {
          ...(urlFilters ?? {}),
          ...(!isEmpty(classificationTagId) ? { classificationTagId } : {}),
          ...(!isEmpty(assetTypeIds) ? { assetTypeId: assetTypeIds } : {})
        }
      }
    }
  }, [
    urlFilters,
    assetsFilters,
    operationalTable.searchText,
    operationalTable.pageIndex,
    operationalTable.pageSize,
    operationalTable.sort
  ])

  const { data: assets = [], isLoading } = useAssetSearch(
    assetsQueryParams.query
  )

  const showAssetTypeFilter = visibleFilters.includes(COLUMN_IDS.ASSET_TYPE)

  const { data: assetTypes = [], isLoading: isLoadingAssetTypes } = useAssetTypes({
    enabled: showAssetTypeFilter,
    mapper: mapAssetTypeOptions
  })

  useEffect(() => {
    // use URL to set filters on page load
    if (isLoadingClassificationTagTypes || isEmpty(assets)) {
      return
    }
    if (!searchParams) {
      return
    }
    setAssetsFilters((prevState) => {
      const classificationFilters = isEmpty(prevState?.classificationFilters)
        ? mapAssetsFiltersFromTagIds(
          searchParams?.classificationFilters || [],
          classificationTagTypes
        )
        : prevState.classificationFilters

      const assetTypeFilters = mapOptionsFromSearchParams(
        searchParams,
        'assetTypeFilters',
        prevState,
        assetTypes
      )

      // collect URL filter params and show its corresponding filter control
      const visibleFilterColIds = getVisibleFilterColIds(
        searchParams,
        classificationTagTypes
      )

      if (!isEmpty(visibleFilterColIds)) {
        setVisibleFilters((prevVisibleFiltersState) => {
          return [
            ...new Set([...prevVisibleFiltersState, ...visibleFilterColIds])
          ]
        })
      }

      return {
        ...prevState,
        assetTypeFilters,
        classificationFilters
      }
    })
  }, [
    assets,
    assetTypes,
    searchParams,
    classificationTagTypes,
    isLoadingClassificationTagTypes
  ])

  const columnConfig = useColumns(
    assets,
    classificationTagTypes,
    defaultColumnConfig,
    visibleFilters,
    searchParams
  )

  const value = useMemo(() => {
    return {
      columnConfig,
      visibleFilters,
      setVisibleFilters,
      isLoading,
      assets,
      onChangeFilters,
      classifyAssetsTotal,
      operationalTable,
      classificationTagTypes,
      isLoadingClassificationTagTypes,
      assetsFilters,
      setAssetsFilters,
      assetTypes,
      isLoadingAssetTypes,
      localStorageConfigurationKey
    }
  }, [
    isLoading,
    columnConfig,
    assets,
    assetTypes,
    isLoadingAssetTypes,
    visibleFilters,
    operationalTable,
    onChangeFilters,
    classificationTagTypes,
    classifyAssetsTotal,
    isLoadingClassificationTagTypes,
    assetsFilters,
    setAssetsFilters,
    localStorageConfigurationKey
  ])

  return (
    <ClassifyAssetsContext.Provider value={value}>
      {children}
    </ClassifyAssetsContext.Provider>
  )
}

ClassifyAssetsContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
  defaultColumnConfig: PropTypes.object.isRequired,
  localStorageConfigurationKey: PropTypes.string,
  urlFiltersMap: PropTypes.object
}

ClassifyAssetsContextProvider.defaultProps = {
  localStorageConfigurationKey: 'adminAssetsListView',
  urlFiltersMap: {}
}

export default ClassifyAssetsContextProvider
