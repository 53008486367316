import React from 'react'
import SydModal from '../commonDesign/SydModal'

const RoundedModal = ({ children, ...props }) => {
  return (
    <SydModal {...props}>
      {children}
    </SydModal>
  )
}

RoundedModal.propTypes = SydModal.propTypes
RoundedModal.defaultProps = SydModal.defaultProps
export default RoundedModal
