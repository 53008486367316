import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import Select from '../../../molecules/Select'
import ScopeDisplaySkeleton from './ScopeDisplaySkeleton'
import { ScopeDisplayItem } from './ScopeDisplayItem'
import ScopeMenuItem from './ScopeMenuItem'

const useStyles = makeStyles(() => ({
  item: {
    cursor: 'pointer',
    padding: '0.5rem 0.75rem',
    display: 'flex',
    gap: '0.5rem',
    flexDirection: 'row',
    alignItems: 'center',
    '&:hover': {
      background: '#F5F6F8'
    }
  }
}))

const GroupScopeSelector = ({ onChange, label, options, pinnedOptions, isLoading, value, isValid, hideAvatars, size }) => {
  const classes = useStyles()
  const labelRenderer = useCallback(({ selectedValue }) => {
    if (!isValid) {
      return (
        <ScopeDisplayItem
          name='Select an Option'
          hideAvatar={hideAvatars}
          size={size}
        />
      )
    }

    if (!selectedValue) return null

    return (
      <ScopeDisplayItem
        name={selectedValue.label}
        avatarSrc={selectedValue?.payload?.avatarUrl}
        label={label}
        abbreviation={selectedValue?.payload?.abbreviation}
        hideAvatar={hideAvatars}
        size={size}
      />
    )
  }, [label, isValid, hideAvatars, size])

  const optionsRenderer = useCallback((option, onChange) => {
    return (
      <ScopeMenuItem
        key={option.value}
        value={option.value}
        label={option.label}
        onChange={onChange}
        avatarUrl={option.payload?.avatarUrl}
        className={classes.item}
        abbreviation={option.payload?.abbreviation}
        hideAvatar={hideAvatars}
      />
    )
  }, [classes.item, hideAvatars])

  if (isLoading) {
    return <ScopeDisplaySkeleton />
  }

  return (
    <div>
      <Select
        labelRenderer={labelRenderer}
        value={value}
        onChange={(_, selectedOption) => {
          onChange(selectedOption)
        }}
        options={options}
        pinnedOptions={pinnedOptions}
        optionsRenderer={optionsRenderer}
      />
    </div>
  )
}

GroupScopeSelector.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func,
  label: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
    payload: PropTypes.object
  })),
  pinnedOptions: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
    payload: PropTypes.object
  })),
  isLoading: PropTypes.bool,
  myAdvisor: PropTypes.shape({
    groupId: PropTypes.string,
    shortName: PropTypes.string,
    longName: PropTypes.string,
    extras: PropTypes.shape({
      profileData: PropTypes.shape({
        profilePic: PropTypes.string
      })
    })
  }),
  isValid: PropTypes.bool,
  hideAvatars: PropTypes.bool,
  size: PropTypes.oneOf(['sm', 'lg'])
}

GroupScopeSelector.defaultProps = {
  label: undefined
}

export default GroupScopeSelector
