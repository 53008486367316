import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { AdvisorViewTabGroupContext, useAdvisorViewTabGroupValues } from './advisorViewTabGroupContext'

const TabGroup = ({ children, tabOptions, onChange }) => {
  const tabContext = useAdvisorViewTabGroupValues(tabOptions)

  useEffect(() => {
    onChange && onChange(tabContext.selectedValue)
  }, [onChange, tabContext.selectedValue])

  return (
    <AdvisorViewTabGroupContext.Provider value={tabContext}>
      {children}
    </AdvisorViewTabGroupContext.Provider>
  )
}

TabGroup.propTypes = {
  tabOptions: PropTypes.object,
  children: PropTypes.node,
  onChange: PropTypes.func
}

export default TabGroup
