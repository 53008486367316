import React from 'react'
import MuiAutocomplete from '@material-ui/lab/Autocomplete'
import { makeStyles } from '@material-ui/styles'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import SearchInput from '../SearchInput'

const useStyles = makeStyles((theme) => ({
  autocomplete: {
    '& .MuiFormControl-root': {
      transition: 'box-shadow .25s ease-in-out'
    },
    '&.Mui-focused': {
      '& .MuiFormControl-root': {
        boxShadow: `0 0 0 2px ${theme.palette.summitBlue}`,
        transition: 'box-shadow .25s ease-in-out'
      }
    }
  }
}))

/**
 * Facade around Mui Autocomplete to use for server based autocomplete
 * - selections display as chips
 * - is multiselect
 * @param {string} placeholder
 * @param {array[any]} value
 * @param {array[{value: any, label: string}]} options
 * @param {boolean} isLoading
 * @param {array[any]} defaultValue
 * @param {function} [getOptionLabel]
 * @param {function} [renderOption]
 * @param {function} [onChange]
 * @param {function} [onInputChange]
 * @returns {JSX.Element}
 */
const MultiSelectAutocomplete = ({
  placeholder = 'Search',
  value,
  options,
  isLoading,
  defaultValue = [],
  getOptionLabel = (opt) => opt.label,
  renderOption = (opt) => opt.label,
  onChange = noop,
  onInputChange = noop
}) => {
  const classes = useStyles()

  return (
    <>
      <MuiAutocomplete
        id='tags-standard'
        multiple
        className={classes.autocomplete}
        options={options ?? []}
        getOptionLabel={getOptionLabel}
        getOptionSelected={(opt, value) => value.value === opt.value}
        defaultValue={defaultValue}
        loading={isLoading}
        filterOptions={(x) => x}
        onInputChange={(event, val) => onInputChange(val)}
        onChange={(_, val) => onChange(val)}
        renderInput={(params) => (
          SearchInput({ placeholder, params })
        )}
        renderOption={renderOption}
        value={value}
        forcePopupIcon={false}
      />
    </>
  )
}
MultiSelectAutocomplete.propTypes = {
  placeholder: PropTypes.string,
  value: PropTypes.arrayOf(PropTypes.any),
  options: PropTypes.arrayOf(PropTypes.any),
  isLoading: PropTypes.bool,
  defaultValue: PropTypes.arrayOf(PropTypes.any),
  getOptionLabel: PropTypes.func,
  renderOption: PropTypes.func,
  onChange: PropTypes.func,
  onInputChange: PropTypes.func
}

export default MultiSelectAutocomplete
