import React, { useCallback, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { makeStyles } from '@material-ui/core'
import FadeIn from '../../../../molecules/Transitions/FadeIn'
import { useSectionEditing, useStatusTemplateContext } from '../StatusTemplateProvider'
import SectionScreen from '../../shared/SectionScreen'
import SectionHeader from '../../shared/SectionHeader'
import EditButton from '../../shared/EditButton'
import PersonalSpace from '../../shared/PersonalSpace'
import SydLabel from '../../../../commonDesign/SydLabel'
import SydInput from '../../../../commonDesign/SydInput'
import SaveCancelFooter from '../../shared/SaveCancelFooter'
import { useModifyStatusTemplateDetailsMutation } from '../../../../../api/groups'

const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    maxWidth: '900px'
  }
}))

const useSubmitter = (form, onComplete) => {
  const { handleSubmit } = form
  const [processing, setProcessing] = useState(false)
  const { mutateAsync: modifyTemplate } = useModifyStatusTemplateDetailsMutation()
  const onSubmit = useCallback(async (formData) => {
    const command = {
      statusTemplateId: formData.statusTemplateId,
      name: formData.name,
      description: formData.description
    }

    try {
      setProcessing(true)
      const result = await modifyTemplate(command)
      onComplete(result)
    } finally {
      setProcessing(false)
    }
  }, [modifyTemplate, setProcessing, onComplete])

  const submitter = useCallback(async (e) => {
    const onValid = async (form) => {
      await onSubmit(form)
    }
    const onInvalid = (errors) => {
      console.error(errors)
    }

    const handler = handleSubmit(onValid, onInvalid)
    await handler(e)
  }, [handleSubmit, onSubmit])

  return {
    submitter,
    processing
  }
}

function GeneralTab () {
  const classes = useStyles()
  const { editing, editSection, statusTemplate, cancelEdit } = useStatusTemplateContext()
  const sectionIsEditing = useSectionEditing('general')
  const form = useForm({
    mode: 'onChange',
    defaultValues: {
      statusTemplateId: statusTemplate.statusTemplateId,
      name: statusTemplate.name,
      description: statusTemplate.description
    }
  })
  const { submitter, processing } = useSubmitter(form, cancelEdit)

  return (
    <>
      <FadeIn>
        <SectionScreen editing={editing} sectionIsEditing={sectionIsEditing}>
          <SectionHeader text='Status Template Information'>
            <div>
              <EditButton
                policy='admin_edit_clientinfo'
                editing={editing}
                onClick={() => editSection({
                  section: 'general'
                })}
              />
            </div>
          </SectionHeader>
          <div className={classes.form}>
            <Controller
              name='name'
              control={form.control}
              render={(f) => (
                <SydLabel label='Name' required>
                  <SydInput
                    disabled={!sectionIsEditing}
                    {...f.field}
                  />
                </SydLabel>
              )}
            />
            <Controller
              name='description'
              control={form.control}
              render={(f) => (
                <SydLabel label='Description' required>
                  <SydInput
                    disabled={!sectionIsEditing}
                    {...f.field}
                  />
                </SydLabel>
              )}
            />
          </div>
          <PersonalSpace />
        </SectionScreen>
      </FadeIn>
      {sectionIsEditing ? (
        <SaveCancelFooter
          saveText='Save Details'
          onCancel={cancelEdit}
          onSave={submitter}
          processing={processing}
          disabled={!form.formState.isValid}
        />
      ) : null}
    </>
  )
}

GeneralTab.propTypes = {
}

export default GeneralTab
