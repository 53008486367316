import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import { useFormattingContext } from '../../../organisms/FormattingProvider/FormattingContext'

const useStyles = makeStyles((theme) => ({
  featured: {
    display: 'flex',
    flexDirection: 'column',
    gap: '5px',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    '& .__metric-value': {
      fontSize: theme.typography.fontSizes.h1
    },
    '& .__metric-desc': {
      fontWeight: theme.typography.weights.light,
      marginBottom: '-10px'
    }
  },
  additional: {
    display: 'flex',
    flexDirection: 'row',
    gap: '5px',
    justifyContent: 'flex-end',
    '& .__metric-value': {
      fontSize: theme.typography.fontSizes.lg
    },
    '& .__metric-desc': {
      fontWeight: theme.typography.weights.light
    }
  }
}))

function SummaryMetric ({ metric }) {
  const classes = useStyles()
  const { formatter } = useFormattingContext()

  return (
    <div className={metric.featured ? classes.featured : classes.additional}>
      <div className='__metric-desc'>{metric.description}</div>
      <div className='__metric-value'>{formatter(metric.value, metric.format)}</div>
    </div>
  )
}

SummaryMetric.propTypes = {
  metric: PropTypes.object
}

export default SummaryMetric
