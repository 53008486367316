import React, { useCallback, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { makeStyles } from '@material-ui/core'
import FadeIn from '../../../../../molecules/Transitions/FadeIn'
import SectionScreen from '../../../shared/SectionScreen'
import SectionHeader from '../../../shared/SectionHeader'
import EditButton from '../../../shared/EditButton'
import PersonalSpace from '../../../shared/PersonalSpace'
import SydLabel from '../../../../../commonDesign/SydLabel'
import SydInput from '../../../../../commonDesign/SydInput'
import SaveCancelFooter from '../../../shared/SaveCancelFooter'
import { useSectionEditing, useTargetModelContext } from '../TargetModelProvider'
import { useModifyTargetModelMutation } from '../../../../../../api/rebalancer'
import Loader from '../../Loader'
import { useClassificationTagTypeOptions } from '../../useClassificationTagTypeOptions'
import Select from '../../../../../molecules/Select'

const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    maxWidth: '900px'
  }
}))

const useSubmitter = (form, onComplete) => {
  const { handleSubmit } = form
  const [processing, setProcessing] = useState(false)
  const { mutateAsync: modifyTargetModel } = useModifyTargetModelMutation()
  const onSubmit = useCallback(async (formData) => {
    const command = {
      targetModel: {
        targetModelId: formData.targetModelId,
        name: formData.name,
        description: formData.description
      }
    }

    try {
      setProcessing(true)
      const result = await modifyTargetModel(command)
      onComplete(result)
    } finally {
      setProcessing(false)
    }
  }, [modifyTargetModel, setProcessing, onComplete])

  const submitter = useCallback(async (e) => {
    const onValid = async (form) => {
      await onSubmit(form)
    }
    const onInvalid = (errors) => {
      console.error(errors)
    }

    const handler = handleSubmit(onValid, onInvalid)
    await handler(e)
  }, [handleSubmit, onSubmit])

  return {
    submitter,
    processing
  }
}

function GeneralTab () {
  const classes = useStyles()
  const { editing, editSection, targetModel, cancelEdit } = useTargetModelContext()
  const sectionIsEditing = useSectionEditing('general')
  const { reset, ...form } = useForm({
    mode: 'onChange',
    defaultValues: {
      targetModelId: targetModel.targetModelId,
      name: targetModel.name,
      description: targetModel.description,
      classificationTagTypeId: targetModel.classificationTagTypeId
    }
  })
  const { submitter, processing } = useSubmitter(form, cancelEdit)
  const onCancel = useCallback(() => {
    reset()
    cancelEdit()
  }, [cancelEdit, reset])
  const { options, isLoading } = useClassificationTagTypeOptions()

  if (isLoading) return <Loader />

  return (
    <>
      <FadeIn>
        <SectionScreen editing={editing} sectionIsEditing={sectionIsEditing}>
          <SectionHeader text='Target Model Information'>
            <div>
              <EditButton
                policy='admin_models_edit'
                editing={editing}
                onClick={() => editSection({
                  section: 'general'
                })}
              />
            </div>
          </SectionHeader>
          <div className={classes.form}>
            <Controller
              name='name'
              control={form.control}
              render={(f) => (
                <SydLabel label='Name' required>
                  <SydInput
                    disabled={!sectionIsEditing}
                    {...f.field}
                  />
                </SydLabel>
              )}
            />
            <Controller
              name='description'
              control={form.control}
              render={(f) => (
                <SydLabel label='Description' required>
                  <SydInput
                    disabled={!sectionIsEditing}
                    {...f.field}
                  />
                </SydLabel>
              )}
            />
            <Controller
              name='classificationTagTypeId'
              rules={{ required: true }}
              control={form.control}
              render={({ field, fieldState }) => (
                <SydLabel label='Classification Tag Type' required description='&nbsp;'>
                  <Select disabled options={options} variant='outlined-rounded' fullWidth {...field} />
                </SydLabel>
              )}
            />
          </div>
          <PersonalSpace />
        </SectionScreen>
      </FadeIn>
      {sectionIsEditing ? (
        <SaveCancelFooter
          saveText='Save Details'
          onCancel={onCancel}
          onSave={submitter}
          processing={processing}
          disabled={!form.formState.isValid}
        />
      ) : null}
    </>
  )
}

GeneralTab.propTypes = {
}

export default GeneralTab
