import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Box, makeStyles } from '@material-ui/core'
import {
  useAbundanceEngineParameters,
  useParameterReplacement
} from '../../../../../hooks/useAbundanceEngineParameters'
import Text from '../../../../atoms/Text'
import SalesforceTaskProvider from './SalesforceTaskProvider'
import GroupByOwner from './GroupByOwner'
import GroupByClient from './GroupByClient'

const useStyles = makeStyles(() => ({
  groupingContainer: {
    maxWidth: '100%',
    overflowX: ({ otherOptions }) => otherOptions?.wrap === 'nowrap' ? 'auto' : undefined
  }
}))

const groupByMap = {
  owner: GroupByOwner,
  client: GroupByClient
}

const SalesforceTasksPreview = ({ datasetName, title, take, startDate, endDate, groupBy, groupLimit, sort, sortDir, otherOptions, containerStyle }) => {
  const classes = useStyles({ otherOptions })
  const { advisorId } = useAbundanceEngineParameters()
  const [start, end] = useParameterReplacement([startDate, endDate])

  const query = useMemo(() => ({
    advisorId,
    startDate: start,
    endDate: end
  }), [start, end, advisorId])

  const Grouping = useMemo(() => (groupBy in groupByMap) ? groupByMap[groupBy] : GroupByOwner, [groupBy])

  return (
    <Box height='100%' width='100%' style={containerStyle}>
      <Box mb='1.5rem'>
        <Text variant='body2' customFontSize='1.2rem'>
          {title}
        </Text>
      </Box>
      <SalesforceTaskProvider name={datasetName} query={query}>
        {(result) => (
          <div className={classes.groupingContainer}>
            <Grouping take={take} data={result} displayType='summary' groupLimit={groupLimit} sort={sort} sortDir={sortDir} {...otherOptions} />
          </div>
        )}
      </SalesforceTaskProvider>
    </Box>
  )
}

SalesforceTasksPreview.propTypes = {
  datasetName: PropTypes.string,
  take: PropTypes.number,
  title: PropTypes.string,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  groupBy: PropTypes.oneOf(['owner', 'client']),
  groupLimit: PropTypes.number,
  sort: PropTypes.string,
  sortDir: PropTypes.oneOf([1, -1]),
  otherOptions: PropTypes.object,
  containerStyle: PropTypes.object
}

SalesforceTasksPreview.defaultProps = {
  datasetName: 'SalesforceOpenTasks',
  take: 10,
  title: 'Tasks',
  startDate: '$lastYearStart',
  endDate: '$in7days',
  groupBy: 'owner',
  groupLimit: 1000,
  sort: undefined,
  sortDir: undefined,
  otherOptions: {},
  containerStyle: { padding: '1rem', width: '100%', height: '100%' }
}

export default SalesforceTasksPreview
