import React, { useCallback } from 'react'
// import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { Button, Grid } from '@material-ui/core'
import { useHistory, useParams } from 'react-router-dom'
import { useGetPlatformDataSourceById } from '../../../../api/ingestion'
import Skeleton from '../../../atoms/Skeleton'
import { useAnalyzer, useFileDownloader } from '../hooks'
import PageContainer from '../components/PageContainer'
import FieldValue from './FieldValue'
import SourceSchema from './SourceSchema'

const useStyles = makeStyles(theme => ({
  container: {
    padding: '0 2rem',
    '& .__datasourcedetails_header': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      '& > *': {
        marginRight: '.5rem'
      }
    },
    animation: '$fadeIn .25s linear'
  },
  header: {
    fontSize: '2rem'
  },
  tableContent: {
    // overflow: 'scroll'
  },
  actionButton: {
    borderRadius: '30px',
    fontWeight: 800,
    textTransform: 'capitalize',
    padding: '6px 12px',
    backgroundColor: '#212945',
    fontSize: '0.85rem',
    color: '#FFFFFF',
    '&:hover': {
      color: '#212945'
    }
  },
  '@keyframes fadeIn': {
    '0%': {
      opacity: 0
    },
    '100%': {
      opacity: 1
    }
  }
}))

const useDataSourceDetails = () => {
  const { platformDataSourceId } = useParams()
  const { data: dataSource, isLoading: isDataSourceLoading } = useGetPlatformDataSourceById(platformDataSourceId)
  const { download } = useFileDownloader(platformDataSourceId)
  const { analyze } = useAnalyzer(platformDataSourceId)

  return {
    isDataSourceLoading,
    dataSource,
    download,
    analyze
  }
}

const DataSourceDetails = () => {
  const classes = useStyles()
  const history = useHistory()
  const { isDataSourceLoading, dataSource, download, analyze } = useDataSourceDetails()
  const onQueryDatasource = useCallback(() => {
    if (isDataSourceLoading || !dataSource) return

    history.push(`/data-home/query/${dataSource.platformDataSourceId}`)
  }, [history, isDataSourceLoading, dataSource])

  if (isDataSourceLoading) {
    return (
      <PageContainer>
        <Skeleton height='900px' />
      </PageContainer>
    )
  }

  return (
    <PageContainer className={classes.container}>
      <Grid item xs={12}>
        <header className='__datasourcedetails_header'>
          <h1 style={{ marginRight: '1rem' }}>{dataSource.name}</h1>
          <Button type='button' className={classes.actionButton} onClick={onQueryDatasource}>Query</Button>
          <Button type='button' className={classes.actionButton} onClick={analyze}>Analyze</Button>
          <Button type='button' className={classes.actionButton} onClick={download}>Download</Button>
        </header>
      </Grid>
      <Grid item xs={12}>
        <section style={{ display: 'flex' }}>
          <FieldValue label='Description'>{dataSource.description}</FieldValue>
          <FieldValue label='Type'>{dataSource.type}</FieldValue>
          <FieldValue label='Analysis Count'>{dataSource.metadata?.analyzedCount ?? '--'}</FieldValue>
        </section>
      </Grid>
      <Grid item xs={12}>
        <section>
          <SourceSchema dataSource={dataSource} />
        </section>
      </Grid>
    </PageContainer>
  )
}

DataSourceDetails.propTypes = {
}

export default DataSourceDetails
