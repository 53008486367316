import React, { useCallback, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Box, IconButton, makeStyles } from '@material-ui/core'
import dayjs from 'dayjs'
import noop from 'lodash/noop'
import { isEmpty } from 'lodash'
import Text from '../atoms/Text'
import Icon from '../atoms/Icon'
import { ICON_NAMES, SKELETON_VARIANTS } from '../../constants'
import { deleteDocument, getDocument } from '../../service'
import { useAppContext } from '../../redux/slices/appContext'
import Skeleton from '../atoms/Skeleton'

const useStyles = makeStyles(() => ({
  previewContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    padding: '0.5rem 0rem'
  },
  previewIcon: {
    paddingRight: '0.75rem'
  },
  previewContent: {
    minWidth: '12rem',
    paddingRight: '0.75rem'
  }
}))

export const downloadDocument = (vaultDocument) => {
  if (!vaultDocument?.downloadUrl) {
    return
  }
  const link = document.createElement('a')
  link.href = vaultDocument.downloadUrl
  link.download = vaultDocument.name
  document.body.appendChild(link)
  link.click()
  link.remove()
}

const useDocument = (documentId) => {
  const appContext = useAppContext()
  const [loading, setLoading] = useState(false)
  const [vaultDocument, setVaultDocument] = useState(null)
  const loadDocument = useCallback(
    async ({ documentId, clientId }) => {
      try {
        setLoading(true)
        const doc = await getDocument(clientId, documentId, false)
        setVaultDocument(doc?.data)
      } catch (err) {
        console.error(
          `There was a problem loading a document ${documentId}: `,
          err
        )
      } finally {
        setLoading(false)
      }
    },
    [setLoading, setVaultDocument]
  )

  const download = useCallback(
    () => downloadDocument(vaultDocument),
    [vaultDocument]
  )

  useEffect(() => {
    loadDocument({ documentId, clientId: appContext.clientId })
  }, [documentId, appContext.clientId, loadDocument])

  const onDeleteDocument = useCallback(async () => {
    try {
      if (isEmpty(vaultDocument)) return
      await deleteDocument(appContext.clientId, vaultDocument.id)
    } catch (err) {
      console.error(err)
    }
  }, [vaultDocument, appContext.clientId])

  return {
    loading,
    download,
    onDeleteDocument,
    document: vaultDocument
  }
}

const AttachmentItem = ({ documentId, readOnly, onDelete: _onDelete }) => {
  const classes = useStyles()
  const { document, loading, download, onDeleteDocument } =
    useDocument(documentId)

  const onDelete = useCallback((event) => {
    event.stopPropagation()
    onDeleteDocument()
    if (_onDelete) _onDelete()
  }, [_onDelete, onDeleteDocument])

  const updatedAt = useMemo(() => {
    return document
      ? dayjs.utc(document.updatedAt).format('MMM D, YYYY')
      : null
  }, [document])

  const renderDocumentMeta = useMemo(() => {
    if (loading) {
      return (
        <>
          <Box mb='0.5rem'>
            <Skeleton height='1rem' variant={SKELETON_VARIANTS.rect} />
          </Box>
          <Skeleton
            height='1rem'
            width='80%'
            variant={SKELETON_VARIANTS.rect}
          />
        </>
      )
    }
    return (
      <>
        <Text text={document?.name} customFontSize='0.875rem' color='#212945' />
        <Text
          color='#898D9B'
          customFontSize='0.625rem'
          text={`Last Updated ${updatedAt}`}
        />
      </>
    )
  }, [document, loading, updatedAt])

  return (
    <div
      key={document?.name}
      onClick={download}
      className={classes.previewContainer}
    >
      <div className={classes.previewIcon}>
        <Icon
          color='#212945'
          customSize='1rem'
          roundedBackground='#F5F6FB'
          name={ICON_NAMES.document}
        />
      </div>
      <div className={classes.previewContent}>{renderDocumentMeta}</div>
      {!readOnly && (
        <div className={classes.previewAction}>
          <IconButton disabled={loading} onClick={onDelete}>
            <Icon name={ICON_NAMES.close} customSize='1rem' color='#212945' />
          </IconButton>
        </div>
      )}
    </div>
  )
}

AttachmentItem.propTypes = {
  documentId: PropTypes.string.isRequired,
  readOnly: PropTypes.bool,
  onDelete: PropTypes.func
}

AttachmentItem.defaultProps = {
  readOnly: false,
  onDelete: noop
}

export default AttachmentItem
