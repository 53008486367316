import React from 'react'
import PropTypes from 'prop-types'
import { useSpring, animated } from 'react-spring'
import { makeStyles } from '@material-ui/core'
import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
  container: ({ backgroundImage }) => ({
    backgroundColor: theme.palette.background.paper,
    margin: 'inherit',
    position: 'absolute',
    width: '100%',
    height: '100%',
    minHeight: '100%',
    overflowY: 'auto',
    top: 0,
    backgroundImage: `url(${backgroundImage})` || 'url("RiverBack_50.png")',
    backgroundPosition: 'bottom center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
  }),
  content: {
    position: 'relative',
    height: '100%',
    width: '100%'
  },
  [theme.breakpoints.down('xs')]: {
    content: {
      display: 'flex',
      flexDirection: 'column',
      gap: '1rem',
      minHeight: '100%',
      height: 'auto',
      margin: '1rem 0',
      padding: '0 1rem'
    }
  }
}))

const DashboardLayout = ({ backgroundImage, backgroundStyles, children, className, contentClassName }) => {
  const classes = useStyles({ backgroundImage })
  const springs = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 }
  })

  const containerClassNames = clsx(classes.container, { [className]: Boolean(className) })
  const contentClassNames = clsx(classes.content, { [contentClassName]: Boolean(contentClassName) })

  return (
    <animated.div className={containerClassNames} style={{ ...springs, ...(backgroundStyles || {}) }}>
      <div className={contentClassNames}>{children}</div>
    </animated.div>
  )
}

DashboardLayout.propTypes = {
  backgroundImage: PropTypes.string,
  backgroundStyles: PropTypes.object,
  children: PropTypes.node,
  className: PropTypes.string,
  contentClassName: PropTypes.string
}

export default DashboardLayout
