import React from 'react'
import { Redirect, Switch } from 'react-router-dom'
import { SecureRoute } from '@okta/okta-react'
import { useHideSideMenuEffect } from '../../../redux/slices/appContext'
import FormattingProvider from '../../organisms/FormattingProvider'
import NotAvailable from '../../organisms/NotAvailable'
import { useIsRebalancerActive } from '../../../hooks/featureChecks'
import StartView from './StartView'
import ResultsView from './ResultsView'
import WorkflowView from './WorkflowView'
import AnalysisView from './AnalysisView'
import { RebalancerSettingsProvider } from './RebalancerSettingsProvider'
import RebalancerLayout from './components/RebalancerLayout'
import SelectClientView from './SelectClientView'
import ModelView from './ModelView'

const formattingConfig = {
  marketValue: '(0,0)',
  percentage: '0.0%'
}

const RebalancerIndex = () => {
  useHideSideMenuEffect([])
  const rebalancerIsActive = useIsRebalancerActive()

  if (!rebalancerIsActive) {
    return (
      <NotAvailable />
    )
  }

  return (
    <RebalancerSettingsProvider>
      <FormattingProvider configuration={formattingConfig}>
        <Switch>
          <SecureRoute path='/rebalancer' exact>
            <Redirect to='/rebalancer/start' />
          </SecureRoute>
          <SecureRoute path='/rebalancer/start' exact>
            <RebalancerLayout>
              <StartView />
            </RebalancerLayout>
          </SecureRoute>
          <SecureRoute path='/rebalancer/start/workflow' exact>
            <RebalancerLayout>
              <WorkflowView />
            </RebalancerLayout>
          </SecureRoute>
          <SecureRoute path='/rebalancer/start/client' exact>
            <RebalancerLayout>
              <SelectClientView />
            </RebalancerLayout>
          </SecureRoute>
          <SecureRoute path='/rebalancer/results/:rebalancingRunId' exact>
            <ResultsView />
          </SecureRoute>
          <SecureRoute path='/rebalancer/model/:targetModelId' exact>
            <RebalancerLayout>
              <ModelView />
            </RebalancerLayout>
          </SecureRoute>
          <SecureRoute path='/rebalancer/analysis'>
            <RebalancerLayout>
              <AnalysisView />
            </RebalancerLayout>
          </SecureRoute>
        </Switch>
      </FormattingProvider>
    </RebalancerSettingsProvider>
  )
}

export default RebalancerIndex
