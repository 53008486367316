import React from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@material-ui/core'
import TeamMember from './TeamMember'

function ClientTeamDisplay ({ data }) {
  if (!data?.length) {
    return (
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <div style={{ fontStyle: 'italic', fontWeight: 100 }}>No Client Team Members</div>
        </Grid>
      </Grid>
    )
  }

  return (
    <Grid container spacing={1}>
      {(data || []).map(ctm => (
        <React.Fragment key={ctm.teamMemberId}>
          <Grid item xs={6}>
            <TeamMember key={ctm.teamMemberId} teamMember={ctm} />
          </Grid>
          <Grid item xs={6}>&nbsp;</Grid>
        </React.Fragment>
      ))}
    </Grid>
  )
}

ClientTeamDisplay.propTypes = {
  data: PropTypes.array
}

export default ClientTeamDisplay
