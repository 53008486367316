import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core'
import { useThingContext } from '../ThingContext'
import Thing from '../Thing'

const useStyles = makeStyles((theme) => ({
  thingSection: {
    display: 'flex',
    flexDirection: 'column',
    gap: '5px'
  }
}))

function ThingSection ({ category, className, style }) {
  const classes = useStyles()
  const { data, isLoading } = useThingContext()

  const categoryData = useMemo(() => {
    if (!data?.things) return []

    if (!category) return data.things

    return data.things.filter(x => x.category === category)
  }, [category, data])

  if (isLoading) return null

  return (
    <div className={clsx(classes.thingSection, className)} style={style}>
      {categoryData.map(x => (
        <Thing key={x.thingId} thing={x} />
      ))}
    </div>
  )
}

ThingSection.propTypes = {
  category: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object
}

export default ThingSection
