import React, { useMemo } from 'react'
import { Box, makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import SearchBarInput from '../../../molecules/SearchBar/SearchBarInput'
import GroupingPicker from '../../../organisms/GroupingProvider/GroupingPicker'
import { useClassifyAssetsContext } from './ClassifyAssetsContext'
import AssetFilterSelectors from './filters/AssetFilterSelectors'
import { DYNAMIC_FILTER_PREFIX } from './helpers'
import AssetStaticFilters from './filters/AssetStaticFilters'

const useStyles = makeStyles(() => ({
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  content: {
    display: 'flex'
  },
  filters: {
    display: 'flex',
    alignItems: 'center',
    padding: '0.75rem 0'
  },
  searchInput: {
    marginBottom: '0.5rem'
  }
}))

const ClassifyAssetsHeader = ({ tableOptions }) => {
  const classes = useStyles()

  const {
    searchText,
    isLoading,
    visibleFilters,
    operationalTable
  } = useClassifyAssetsContext()

  const { onSearchTextChange } = operationalTable

  const hasVisibleDynamicFilters = useMemo(() => {
    return visibleFilters.filter(filter => filter.startsWith(DYNAMIC_FILTER_PREFIX)).length > 0
  }, [visibleFilters])

  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <Box width='20rem'>
          <SearchBarInput
            value={searchText}
            loading={isLoading}
            setValue={onSearchTextChange}
            searchInputClassName={classes.searchInput}
            placeholder='Search by asset name or identifier'
          />
        </Box>
        <Box ml='auto'>
          <GroupingPicker
            options={{
              ...tableOptions,
              hideExportTypePicker: true,
              hideGroupingsUnderline: true,
              hideExpandCollapseButton: true,
              hideCustomizeColumnsButton: false,
              hideCustomizeColumnsColumnHiding: false
            }}
          />
        </Box>
      </div>
      {hasVisibleDynamicFilters && (
        <div className={classes.filters}>
          <Box mr={2}>
            <AssetStaticFilters />
          </Box>
          <AssetFilterSelectors />
        </div>
      )}
    </div>
  )
}

ClassifyAssetsHeader.propTypes = {
  tableOptions: PropTypes.object
}

export default ClassifyAssetsHeader
