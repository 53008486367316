import { useCallback, useRef } from 'react'
const useDebounce = (func, { timeout = 300 } = {}) => {
  const timerRef = useRef(0)
  return useCallback((...args) => {
    clearTimeout(timerRef.current)
    timerRef.current = setTimeout(() => {
      func(...args)
    }, timeout)
  }, [func, timeout, timerRef])
}

export default useDebounce
