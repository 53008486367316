import React, { useState } from 'react'
import PropTypes from 'prop-types'
import TitleWithSubtitle from '../../molecules/TitleWithSubtitle'
import Text from '../../atoms/Text'
import NumberFormat from '../../atoms/NumberFormat'

import { numeralByCase } from '../../../utils'

import theme from '../../../theme'

const NetWorth = ({ vbsInfo }) => {
  const [format, setFormat] = useState('$0,0a')

  const netWorthValue = vbsInfo?.reduce((acc, item) => acc + item.details.reduce((detailsAcc, detail) => detailsAcc + detail.endingValue, 0), 0) || 0
  const netWorthTitle = numeralByCase(netWorthValue)
  const onClickNetWorth = () => setFormat(format === '$0,0a' ? '$0,0' : '$0,0a')

  const netWorthNumber = <NumberFormat title={netWorthTitle} number={netWorthValue} format={format} onClick={onClickNetWorth} />

  return (
    <TitleWithSubtitle
      titleElement={
        <Text
          text={netWorthNumber}
          textTransform='uppercase'
          customFontSize='2.5rem'
          variant='body2'
        />
      }
      subtitleElement={
        <Text
          text='Net worth'
          customFontSize='1rem'
          color={theme.palette.cloudBurst}
        />
      }
      alignment='flex-start'
    />
  )
}

NetWorth.propTypes = {
  vbsInfo: PropTypes.array
}

export default NetWorth
