import React, { useCallback } from 'react'
import { makeStyles, IconButton } from '@material-ui/core'
import EyeIcon from '@material-ui/icons/Visibility'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import Icon from '../../../../../../../atoms/Icon'
import { useReportRecentContext } from '../../ReportRecentProvider'

import useBobiOfflineExport from '../../../../useBobiOfflineExport'

const COLUMN_ORDINALS = {
  'Client ID': 0,
  'Client Name': 1,
  'Account ID': 2,
  'Account Name': 3,
  'Account Number': 4
}

const useStyles = makeStyles({
  container: {
    display: 'flex',
    gap: '.5rem',
    alignItems: 'center'
  },
  iconButton: {
    padding: '.25rem',
    cursor: 'pointer'
  }
})

const ReportActions = ({
  fileName,
  bobiOutputId,
  hideViewReportButton,
  downloadButtonDisabled
}) => {
  const classes = useStyles()
  const history = useHistory()
  const {
    isLoading,
    toggleIsLoading
  } = useReportRecentContext()

  const viewReportHandler = useCallback(() => {
    const lastSlash = history.location.pathname.lastIndexOf('/')
    const path = history.location.pathname.substring(0, lastSlash)
    history.push(`${path}/bobi/results/${bobiOutputId}`)
  }, [bobiOutputId, history])

  const { onExport: downloadHandler } = useBobiOfflineExport({
    afterExport: useCallback(() => toggleIsLoading.off(), [toggleIsLoading]),
    beforeExport: useCallback(() => toggleIsLoading.on(), [toggleIsLoading]),
    bobiOutputId,
    columnOrdinals: COLUMN_ORDINALS,
    fileName,
    onError: useCallback((msg, e) => console.error(msg, e), [])
  })

  return (
    <div className={classes.container}>
      {!hideViewReportButton && (
        <IconButton
          size='small'
          onClick={viewReportHandler}
          className={classes.iconButton}
          // disabled until we figure out how to fetch report
          // params and not only the report itself
          disabled
        >
          <EyeIcon style={{ fontSize: '1.25rem' }} />
        </IconButton>
      )}
      <IconButton
        size='small'
        onClick={downloadHandler}
        className={classes.iconButton}
        disabled={isLoading || downloadButtonDisabled}
      >
        <Icon name='download' customSize='1.25rem' />
      </IconButton>
    </div>
  )
}

ReportActions.propTypes = {
  fileName: PropTypes.string,
  bobiOutputId: PropTypes.number,
  hideViewReportButton: PropTypes.bool,
  downloadButtonDisabled: PropTypes.bool
}

ReportActions.defaultProps = {
  hideViewReportButton: true,
  downloadButtonDisabled: false
}

export default ReportActions
