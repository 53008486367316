import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import PrintOnlyContent from '../atoms/PrintOnlyContent'
import CurrentClient from '../atoms/CurrentClient'
import Logo from '../atoms/Logo'

const useStyles = makeStyles(() => ({
  container: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '1rem 1rem 2rem 1rem',
    width: '100%'
  },
  printLogo: {
    height: '3rem',
    float: 'left'
  },
  currentClientContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    flex: '1 0'
  }
}))

const PrintViewHeader = () => {
  const classes = useStyles()

  return (
    <PrintOnlyContent>
      <div className={classes.container}>
        <Logo className={classes.printLogo} />
        <div className={classes.currentClientContainer}>
          <CurrentClient />
        </div>
      </div>
    </PrintOnlyContent>
  )
}

export default PrintViewHeader
