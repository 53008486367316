import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import { makeStyles } from '@material-ui/core/styles'
import MilestoneIcon from '../../../molecules/MilestoneIcon'
import Tooltip from '../../../atoms/Tooltip'

export const useStyles = makeStyles((theme) => ({
  container: ({ disabled }) => ({
    opacity: disabled ? 0.5 : 1,
    position: 'relative',
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'center',
    justifyContent: 'flex-start',
    fontSize: '1rem',
    width: '14rem',
    height: '300px',
    borderRadius: '8px',
    padding: '1.625rem 1rem 1rem 1rem',
    border: `2px solid ${theme.palette.gray.lighter}`,
    boxSizing: 'border-box',
    cursor: 'pointer',
    backgroundColor: 'rgba(238, 240, 248, 0.6)',
    transition:
      'border-color .25s ease-in-out, background-color .25s ease-in-out',
    '&:hover': {
      borderColor: disabled ? theme.palette.baseBackground.main : '#141929',
      backgroundColor: '#FFFFFF',
      cursor: disabled ? 'initial' : 'pointer',
      boxShadow: '0px 4px 24px 0px rgba(15, 17, 49, 0.12)'
    }
  }),
  title: {
    fontSize: '1rem',
    fontWeight: 700,
    textAlign: 'center',
    paddingTop: '1.25rem',
    overflow: 'hidden'
  },
  target: {
    fontSize: '0.75rem',
    textAlign: 'center',
    position: 'absolute',
    opacity: '0.5',
    bottom: '1rem'
  },
  icon: {
    width: '5.5rem',
    height: '5.5rem',
    fontSize: '2rem',
    marginTop: '0.5rem',
    color: theme.palette.summitBlue,
    backgroundColor: theme.palette.baseBackground.avatar
  }
}))

const MilestoneCard = ({
  milestone,
  onSelect,
  selectable,
  selected,
  disabled,
  header,
  tooltipTitle
}) => {
  const classes = useStyles({ disabled, selectable })

  const targetDate = useMemo(
    () => dayjs.utc(milestone.entryDate).format('MMM YYYY'),
    [milestone.entryDate]
  )
  const isSelected = useMemo(
    () => selected?.entryId === milestone.entryId,
    [selected, milestone]
  )
  const onClick = useCallback(
    () => !isSelected && onSelect(milestone),
    [milestone, onSelect, isSelected]
  )

  const callbackRef = useCallback(
    (inputElement) => {
      if (inputElement && isSelected) {
        inputElement.focus()
      }
    },
    [isSelected]
  )

  return (
    <Tooltip title={tooltipTitle}>
      <div className={classes.container} onClick={onClick} ref={callbackRef}>
        {header}
        <MilestoneIcon className={classes.icon} milestone={milestone} />
        <div className={classes.title}>{milestone.entryJson.title}</div>
        <div className={classes.target}>Target: {targetDate}</div>
      </div>
    </Tooltip>
  )
}

MilestoneCard.propTypes = {
  /** The milestone to represent */
  milestone: PropTypes.object.isRequired,

  /** Callback when the card is selected / clicked on */
  onSelect: PropTypes.func.isRequired,

  /** The selected milestone, the card appears selected */
  selected: PropTypes.object,

  /** Determines if the card is selectable */
  selectable: PropTypes.bool,
  disabled: PropTypes.bool,
  header: PropTypes.node,
  tooltipTitle: PropTypes.string
}

MilestoneCard.defaultProps = {
  onSelect: () => {},
  equalitySelector: (provided, active) => {
    if (!provided) return false
    if (!active) return false
    return provided.entryId === active.entryId
  },
  selected: null,
  disabled: false,
  selectable: true,
  header: undefined,
  tooltipTitle: undefined
}

export default MilestoneCard
