import React, { useMemo } from 'react'
import { makeStyles } from '@material-ui/core'
import SectionScreen from '../SectionScreen'
import { useWealthOwnerDetails } from '../ClientDetailsFormContext'
import SectionHeader from '../../shared/SectionHeader'
import { useWealthOwnerStatusReport } from '../../../../../api/users'
import { useFormattingContext } from '../../../../organisms/FormattingProvider/FormattingContext'
import FadeIn from '../../../../molecules/Transitions/FadeIn'

const useStyles = makeStyles((theme) => ({
  statusDetails: {
    '& td': {
      padding: '10px',
      fontSize: theme.typography.fontSizes.md,
      fontWeight: theme.typography.weights.light
    },
    '& th': {
      padding: '10px',
      fontSize: theme.typography.fontSizes.md,
      fontWeight: theme.typography.weights.semibold,
      textAlign: 'left',
      borderBottom: `1px solid ${theme.palette.gray.dark}`
    }
  },
  empty: {
    border: `2px solid ${theme.palette.gray.main}`,
    padding: '2rem',
    fontSize: theme.typography.fontSizes.h5
  }
}))

const mapDevice = (log) => {
  return (log?.client?.userAgent?.os)
    ? `${log?.client?.device} [${log?.client.userAgent.os}]`
    : log?.client?.device || '--'
}

const mapCity = (log) => {
  return `${log?.client?.location?.city} ${log?.client?.location?.state}`
}

const mapCountry = (log) => {
  return log?.client?.location?.country
}

function SecurityDetailsSection () {
  const { wealthOwner } = useWealthOwnerDetails()
  const { formatter } = useFormattingContext()
  const classes = useStyles()
  const { data, isLoading } = useWealthOwnerStatusReport(wealthOwner.userId)
  const { logs, hasLogs } = useMemo(() => {
    const hasLogs = !!data?.statusReport?.logs?.length
    const logs = data?.statusReport?.logs || []
    logs.sort((a, b) => {
      const ad = a.eventDateUtc
      const bd = b.eventDateUtc
      return bd.localeCompare(ad)
    })
    return { logs, hasLogs }
  }, [data])

  const { emails, hasEmails } = useMemo(() => {
    const hasEmails = !!data?.statusReport?.emailLogs?.length
    const emails = data?.statusReport?.emailLogs || []
    emails.sort((a, b) => {
      const ad = a.eventDateUtc
      const bd = b.eventDateUtc
      return bd.localeCompare(ad)
    })
    return { emails, hasEmails }
  }, [data])

  if (isLoading) {
    return null
  }

  return (
    <FadeIn>
      <SectionScreen sectionName='security_details'>
        <SectionHeader text='Security Events (previous 30 days, max 100 events)' />
        {hasLogs ? (
          <table className={classes.statusDetails}>
            <thead>
              <tr>
                <th>Event Date</th>
                <th>Event Type</th>
                <th>Outcome</th>
                <th>Outcome Reason</th>
                <th>Device</th>
                <th>Location</th>
                <th>Country</th>
                <th>IP Address</th>
              </tr>
            </thead>
            <tbody>
              {logs.map((log, index) => (
                <tr key={index}>
                  <td>{formatter(log.eventDateUtc, '&MM/DD/YYYY hh:mm:ss a')}</td>
                  <td>{log.displayMessage}</td>
                  <td>{log.outcome?.result}</td>
                  <td>{log.outcome?.reason}</td>
                  <td>{mapDevice(log)}</td>
                  <td>{mapCity(log)}</td>
                  <td>{mapCountry(log)}</td>
                  <td>{log.client?.ipAddress}</td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <div className={classes.empty}>No Events</div>
        )}
      </SectionScreen>
      <SectionScreen sectionName='security_emails'>
        <SectionHeader text='Email Events (previous 30 days, max 100 events)' />
        {hasEmails ? (
          <table className={classes.statusDetails}>
            <thead>
              <tr>
                <th>Event Date</th>
                <th>Event Type</th>
                <th>Delivery</th>
                <th>Delivery Status</th>
                <th>To</th>
                <th>Reason</th>
              </tr>
            </thead>
            <tbody>
              {emails.map((log, index) => (
                <tr key={index}>
                  <td>{formatter(log.eventDateUtc, '&MM/DD/YYYY hh:mm:ss a')}</td>
                  <td>{log.displayMessage}</td>
                  <td>{log.outcome?.result}</td>
                  <td>{log.outcome?.reason}</td>
                  <td>{log.target?.map(x => x.displayName).join(', ')}</td>
                  <td>{log.reason}</td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <div className={classes.empty}>No Events</div>
        )}
      </SectionScreen>
    </FadeIn>
  )
}

export default SecurityDetailsSection
