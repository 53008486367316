import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useFirmReportSummary } from '../../../api/coreData'
import { useDenaliFilter } from './filters/DenaliFilterContextProvider'

function replaceObjectPaths (str, obj, defaultValue = '') {
  return str.replace(/{{(.*?)}}/g, (match, path) => {
    const parts = path.split('.')
    let currentObj = obj
    for (let i = 0; i < parts.length - 1; i++) {
      if (!currentObj[parts[i]]) {
        // Default to an empty string when property not found
        return defaultValue
      }
      currentObj = currentObj[parts[i]]
    }

    return currentObj[parts.pop()] || defaultValue
  })
}

function MetricTemplate ({ reportId, text, className, style }) {
  const { membershipFilter } = useDenaliFilter()
  const { data, isLoading } = useFirmReportSummary(reportId, { membershipFilter })

  const value = useMemo(() => {
    if (!data?.summary?.length) return null
    const dv = data.summary.reduce((p, c) => {
      p[c.key] = c
      return p
    }, {})
    return replaceObjectPaths(text, dv)
  }, [data, text])

  if (isLoading) {
    return null
  }

  return (
    <span className={className} style={style}>{value}</span>
  )
}

MetricTemplate.propTypes = {
  reportId: PropTypes.string,
  text: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object
}

export default MetricTemplate
