import { useEffect } from 'react'
import { createSelector, createSlice } from '@reduxjs/toolkit'
import { useDispatch, useSelector } from 'react-redux'
import { postNamedQuery } from '../../service'
import { loadable } from '../../utils/loadable'
import { useAppContext } from './appContext'

const initialState = {
  current: null
}

const clientContextSlice = createSlice({
  name: 'clientContext',
  initialState,
  reducers: {
    setCurrentClient: (state, { payload }) => ({
      ...state,
      current: payload
    })
  }
})

export const { actions } = clientContextSlice

/** Selectors */
const selectSlice = state => state[clientContextSlice.name]
const createSliceSelector = (selector) => createSelector(selectSlice, selector)
export const selectCurrentClient = createSliceSelector(slice => slice.current)

/** Thunks */
export const thunks = {
  loadCurrentClient: (clientId) => async (dispatch, getState) => {
    const current = selectCurrentClient(getState())
    if (loadable.isLoading(current)) {
      return
    }

    const currentValue = loadable.getValue(current)
    if (currentValue?.clientId === clientId) {
      return
    }

    try {
      dispatch(actions.setCurrentClient(loadable.loading()))
      const { data } = await postNamedQuery('levels', 'get-client-by-id', {
        clientId,
        resolveAvatar: true
      })
      dispatch(actions.setCurrentClient(loadable.done(data.client)))
    } catch (err) {
      console.error(err)
      dispatch(actions.setCurrentClient(loadable.error(err)))
    }
  }
}

/** Hooks */
export const useCurrentClient = () => {
  const dispatch = useDispatch()
  const { clientId } = useAppContext()

  useEffect(() => {
    dispatch(thunks.loadCurrentClient(clientId))
  }, [dispatch, clientId])

  const current = useSelector(selectCurrentClient)
  return [
    loadable.isLoading(current),
    loadable.getValue(current),
    loadable.getError(current)
  ]
}

export default clientContextSlice
