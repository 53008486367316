import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import { useAppContext } from '../../../../redux/slices/appContext'
import { useGainLossSearch } from '../../../../api/coreData'
import CostBasisSummaryTile from './CostBasisSummaryTile'
import { COST_BASIS_PERIOD_BUCKET_KEYS, useTileMapping } from './costBasisTileConfig'

const useStyles = makeStyles(() => ({
  costBasisSummary: {
    margin: '1rem 0',
    display: 'grid',
    gridTemplateColumns: ({ tileMapping }) => {
      return (tileMapping || []).reduce((p, c) => {
        p.push(`minmax(${c.minWidth || '150px'}, ${c.width || '1fr'})`)
        return p
      }, []).join(' ')
    },
    gridGap: '20px'
  }
}))

const useCostBasisSummary = ({
  dateRange,
  enabled,
  costBasisType,
  defaultFilter,
  levelFilters,
  taxStatusId
}) => {
  const { clientId } = useAppContext()
  const query = useMemo(() => {
    return enabled ? {
      levelFilters,
      filters: {
        ...(defaultFilter || {}),
        clientId,
        taxStatusId
      },
      dateRange,
      levelType: 'client',
      costBasisType,
      take: 1
    } : null
  }, [dateRange, enabled, costBasisType, clientId, defaultFilter, levelFilters, taxStatusId])

  const { data, isLoading, error } = useGainLossSearch(query, {
    enabled
  })

  const mappedData = useMemo(() => {
    if (isLoading || error) {
      return null
    }
    return data[0]
  }, [data, isLoading, error])

  return {
    data: mappedData,
    isLoading,
    error
  }
}

const CostBasisSummary = ({
  format,
  enabled,
  dateRange,
  taxStatusId,
  costBasisType,
  defaultFilter,
  levelFilters,
  tilesVisibility,
  summaryFormatConfiguration
}) => {
  const { data, isLoading, error } = useCostBasisSummary({
    dateRange,
    enabled,
    costBasisType,
    defaultFilter,
    levelFilters,
    taxStatusId
  })

  const tileMapping = useTileMapping(costBasisType)
  const classes = useStyles({ tileMapping })

  if (error) {
    return null
  }

  return (
    <div className={classes.costBasisSummary}>
      {tileMapping.map((tile) => {
        const tileVisibility = tilesVisibility?.[costBasisType] || {}
        const periodBucketKey = COST_BASIS_PERIOD_BUCKET_KEYS?.[tile.key]
        const periodTileVisibility = tileVisibility?.[periodBucketKey]
        if (periodTileVisibility === false) {
          return null
        }
        return (
          <CostBasisSummaryTile
            key={tile.key}
            title={tile.key}
            tileMap={tile}
            item={data}
            loading={isLoading}
            type={costBasisType}
            format={format}
            summaryTileVisibility={periodTileVisibility}
            summaryFormatConfiguration={summaryFormatConfiguration}
          />
        )
      })}
    </div>
  )
}

CostBasisSummary.propTypes = {
  costBasisType: PropTypes.oneOf(['realized', 'unrealized', 'combined'])
    .isRequired,
  dateRange: PropTypes.object,
  enabled: PropTypes.bool,
  defaultFilter: PropTypes.object,
  levelFilters: PropTypes.object,
  taxStatusId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  format: PropTypes.string,
  summaryFormatConfiguration: PropTypes.object,
  tilesVisibility: PropTypes.object
}

CostBasisSummary.defaultProps = {
  defaultFilter: {}
}

export default CostBasisSummary
