import { useQuery } from '@tanstack/react-query'
import { useAppContext } from '../../redux/slices/appContext'
import { getToken } from '../../service'
import config from '../../config'

// Okta Auth API puts the authorization server in the URL and that isn't the right url for this
// Maybe consider using it when we upgrade it

export const useMyAuthenticatorEnrollments = () => {
  const { userId: caller } = useAppContext()
  // eslint-disable-next-line no-unused-vars
  return useQuery({
    queryKey: ['get-my-okta-authenticator-enrollments', caller],
    queryFn: async () => {
      const token = getToken()
      const url = `${config.oktaUrl}/idp/myaccount/authenticators?expand=enrollments`
      const options = {
        method: 'GET',
        headers: {
          Accept: 'application/json; okta-version=1.0.0',
          Authorization: `Bearer ${token}`
        }
      }
      const response = await fetch(url, options)
      if (response.status === 200) {
        return await response.json()
      }

      return null
    }
  })
}
