import React, { useCallback, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { useQuery } from '@tanstack/react-query'
import { Button, Divider, Grid } from '@material-ui/core'
import { postNamedCommand, postNamedQuery } from '../../../../service'
import BouncingDotsLoader from '../../../atoms/BouncingDotsLoader'
import Skeleton from '../../../atoms/Skeleton'
import { QUERY_KEYS } from '../../../../api/queryKeys'
import { USER_TYPE } from '../../../../constants'
import RoleViewsEditSortable from './RoleViewsEditSortable'

const mobileTagNames = ['mobileWheel']

const RoleViewsEdit = ({ role }) => {
  const { data: roleViews, isFetching: isFetchingRoleViews, refetch: refetchRoleViews } = useQuery({
    queryKey: [QUERY_KEYS.roleViews, role?.roleId],
    queryFn: async () => {
      const { data } = await postNamedQuery('abundanceEngine', 'getLevelViews', { levelId: role?.roleId, levelTypeId: 1002 })
      return data.sort((a, b) => a.ordinal - b.ordinal)
    },
    enabled: !!role?.roleId
  })

  const { data: taggedViews, isFetching: isFetchingTaggedViews } = useQuery({
    queryKey: [QUERY_KEYS.taggedViews],
    queryFn: async () => {
      const { data } = await postNamedQuery('abundanceEngine', 'getViewsByTags')
      return data
    },
    enabled: !!role?.roleId
  })

  const isFetching = useMemo(() => isFetchingRoleViews || isFetchingTaggedViews, [isFetchingRoleViews, isFetchingTaggedViews])

  const [navigationViews, setNavigationViews] = useState([])
  const [mobileViews, setMobileViews] = useState([])
  const [isModified, setIsModified] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (roleViews) {
      setNavigationViews(roleViews?.filter(view => !mobileTagNames.includes(view?.tagName)))
      setMobileViews(roleViews?.filter(view => mobileTagNames.includes(view?.tagName)))
      setIsModified(false)
    }
  }, [roleViews])

  const availableNavigationViews = useMemo(() => {
    return taggedViews?.filter((view) => {
      return !mobileTagNames.includes(view?.tagName) &&
        !navigationViews?.find(v => v?.viewId === view?.viewId) &&
        view.showInNavigation &&
        (role?.assignableToUserType === USER_TYPE.ADVISOR || view?.tagName === 'wheel')
    })
  }, [taggedViews, navigationViews, role?.assignableToUserType])
  const availableMobileViews = useMemo(() => (
    taggedViews?.filter(view => mobileTagNames.includes(view?.tagName) && !mobileViews?.find(v => v?.viewId === view?.viewId))
  ), [taggedViews, mobileViews])

  const reset = useCallback(() => {
    setIsModified(false)
    setNavigationViews(roleViews?.filter(view => !mobileTagNames.includes(view?.tagName)))
    setMobileViews(roleViews?.filter(view => mobileTagNames.includes(view?.tagName)))
  }, [roleViews])

  const submitChanges = useCallback(async () => {
    setIsLoading(true)
    const views = [...navigationViews, ...mobileViews]
    await postNamedCommand(
      'abundanceEngine',
      'updateLevelViews',
      {
        levelId: role?.roleId,
        levelTypeId: 1002,
        views: views.map(({ viewId, ordinal, isDefault }) => ({ viewId, ordinal, isDefault }))
      })
    await refetchRoleViews()
    setIsLoading(false)
  }, [navigationViews, mobileViews, role?.roleId, refetchRoleViews])

  const maxSelections = useMemo(() => {
    return role?.assignableToUserType === USER_TYPE.WEALTH_OWNER ? 1 : undefined
  }, [role?.assignableToUserType])

  if (isFetching) {
    return (
      <Grid container spacing={4} style={{ marginTop: '1rem' }}>
        <Grid item sm={6} xs={12}>
          <Skeleton height='200px' />
        </Grid>
        <Grid item sm={6} xs={12}>
          <Skeleton height='200px' />
        </Grid>
      </Grid>
    )
  }

  return (
    <div style={{ position: 'relative' }}>
      {isLoading && (
        <div style={{ position: 'absolute', top: '50%', left: 0, right: 0 }}>
          <BouncingDotsLoader />
        </div>
      )}
      <Grid container spacing={4}>
        <Grid item sm={6} xs={12}>
          <div style={{ marginTop: '1rem' }}>
            <RoleViewsEditSortable
              keyName='navigationViews'
              title='Web Views'
              subtitle='Will show in the left sidenav & control web default home page'
              selectedViews={navigationViews}
              availableViews={availableNavigationViews}
              onUpdate={(newVal) => {
                setNavigationViews(newVal)
                setIsModified(true)
              }}
              maxSelections={maxSelections}
            />
          </div>
        </Grid>
        <Grid item sm={6} xs={12}>
          <div style={{ marginTop: '1rem' }}>
            <RoleViewsEditSortable
              keyName='mobileViews'
              title='Mobile Views'
              subtitle='Used for mobile app'
              selectedViews={mobileViews}
              availableViews={availableMobileViews}
              onUpdate={(newVal) => {
                setMobileViews(newVal)
                setIsModified(true)
              }}
              maxSelections={maxSelections}
            />
          </div>
        </Grid>
      </Grid>
      <Divider style={{ margin: '1rem 0' }} />
      <Grid container justifyContent='space-between'>
        <Grid item>
          <Button onClick={reset} variant='contained' disabled={!isModified}>Reset</Button>
        </Grid>
        <Grid item>
          <Button variant='contained' color='primary' disabled={!isModified} onClick={submitChanges}>Save Changes</Button>
        </Grid>
      </Grid>
    </div>
  )
}

RoleViewsEdit.propTypes = {
  role: PropTypes.object
}

export default RoleViewsEdit
