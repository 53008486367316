import React, { useCallback, useImperativeHandle, useState } from 'react'
import PropTypes from 'prop-types'
import RoundedModal from '../../../../../molecules/RoundedModal'
import ManageMembershipForm from './ManageMembershipForm'

const ManageMembershipDialog = React.forwardRef(function ManageMembershipDialog ({ onComplete, client }, ref) {
  const [dialogState, setDialogState] = useState({ open: false, processing: false })
  const close = useCallback(() => {
    // We keep the parent here because it says "undefined" on the modal otherwise
    setDialogState((p) => ({ ...p, open: false, processing: false }))
  }, [setDialogState])

  const complete = useCallback(async (result) => {
    await onComplete({
      groupId: dialogState.group.groupId,
      groupTypeId: dialogState.groupType.groupTypeId,
      clientId: client.clientId,
      accountIds: result.accountIds
    })
    close()
  }, [close, dialogState, client, onComplete])

  useImperativeHandle(ref, () => ({
    open: (groupType, group) => setDialogState({ open: true, processing: false, groupType, group })
  }), [setDialogState])

  return (
    <RoundedModal
      title={`${dialogState?.groupType?.longName} / ${dialogState?.group?.longName}`}
      open={dialogState.open}
      onClose={close}
      size='large'
      position='top'
      disablePortal={false}
    >
      <ManageMembershipForm
        client={client}
        groupType={dialogState?.groupType}
        group={dialogState?.group}
        onComplete={complete}
        onClose={close}
      />
    </RoundedModal>
  )
})

ManageMembershipDialog.propTypes = {
  onComplete: PropTypes.func,
  client: PropTypes.object
}

export default ManageMembershipDialog
