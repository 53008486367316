import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import LabeledValue from '../components/LabeledValue'

const useStyles = makeStyles((theme) => ({
  header: {
    padding: '1rem',
    backgroundColor: theme.palette.gray.main,
    borderBottom: `1px solid ${theme.palette.gray.dark}`
  }
}))

const ObjectMappingHeader = ({ mapping }) => {
  const classes = useStyles()

  return (
    <header className={classes.header}>
      <LabeledValue label='Destination Type'>{mapping.destinationType}</LabeledValue>
      <LabeledValue label='Mapping ID'>{mapping.objectMappingId}</LabeledValue>
      <LabeledValue label='Target ID'>{mapping.ingestionTargetId ? mapping.ingestionTargetId : '<NULL>'}</LabeledValue>
    </header>
  )
}

ObjectMappingHeader.propTypes = {
  mapping: PropTypes.shape({
    objectMappingId: PropTypes.number,
    destinationType: PropTypes.string,
    ingestionTargetId: PropTypes.number
  })
}

export default ObjectMappingHeader
