import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Button, Tooltip } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import WithSkeleton from '../../../molecules/WithSkeleton'
import Text from '../../../atoms/Text'
import NumberFormat from '../../../atoms/NumberFormat'
import { numeralByCase } from '../../../../utils'
import { SKELETON_VARIANTS, TEXT_VARIANTS } from '../../../../constants'
import { useAppContext } from '../../../../redux/slices/appContext'
import { useAccountSearchMultiple } from '../../../../api/coreData'
import { useToggle } from '../../../../hooks'
import ClientModalAccounts from './ClientModalAccounts'

const useStyles = makeStyles((theme) => ({
  button: {
    backgroundColor: 'transarent',
    borderRadius: '50% !important',
    border: 'none',
    padding: '3px',
    minWidth: '35px',
    textAlign: 'center',
    textDecoration: 'none',
    display: 'inline-block'
  },
  warning: {
    backgroundColor: theme.palette.yellowOrange,
    '&:hover': {
      backgroundColor: '#c69026'
    }
  }
}))

const ClientAccounts = ({
  label,
  title,
  subtitle,
  filters = {},
  columns,
  defaultSort
}) => {
  const classes = useStyles()

  const { clientId } = useAppContext()
  const [showModal, , setShowModalOn, setShowModalOff] = useToggle(false)

  const query = useMemo(() => ({
    assignedAccounts: {
      resultType: 'total',
      filters: {
        assignedToClientIds: [clientId],
        ...(filters ?? {})
      }
    },
    hasExceptions: {
      resultType: 'total',
      filters: {
        assignedToClientIds: [clientId],
        hasException: true,
        ...(filters ?? {})
      }
    },
    isAutomatedNotUpdating: {
      resultType: 'total',
      filters: {
        assignedToClientIds: [clientId],
        isAutomatedNotUpdating: true,
        ...(filters ?? {})
      }
    }
  }), [clientId, filters])
  const { data, error, isLoading } = useAccountSearchMultiple(query)
  const [accountCount, showWarning] = useMemo(() => {
    if (error || isLoading) return [null, null]
    const total = data?.assignedAccounts?.total
    const totalHasExceptions = data?.hasExceptions?.total || 0
    const totalIsAutomatedNotUpdating = data?.isAutomatedNotUpdating?.total || 0
    return [total, totalHasExceptions > 0 || totalIsAutomatedNotUpdating > 0]
  }, [data, error, isLoading])

  const [singularTitle, pluralizedTitle] = useMemo(() => {
    if (Array.isArray(title)) {
      if (title.length > 1) {
        return title
      } else if (title.length === 1) {
        return [title[0], title[0]]
      }
      return [null, null]
    }
    return [title, title]
  }, [title])

  const [singularLabel, pluralizedLabel] = useMemo(() => {
    if (Array.isArray(label)) {
      if (label.length > 1) {
        return label
      } else if (label.length === 1) {
        return [label[0], label[0]]
      }
      return [null, null]
    }
    return [label, label]
  }, [label])

  if (error) return null

  return (
    <div className='__clientMetricValue'>
      <WithSkeleton
        showSkeleton={isLoading}
        skeletonWidth='75px'
        skeletonHeight='54px'
        skeletonVariant={SKELETON_VARIANTS.rect}
      >
        <Tooltip title='Accounts at a glance'>
          <Button
            onClick={setShowModalOn}
            classes={{ root: clsx([classes.button, { [classes.warning]: showWarning }]) }}
          >
            <Text
              variant={TEXT_VARIANTS.h3}
              text={
                <NumberFormat
                  title={numeralByCase(accountCount, '0')}
                  number={accountCount}
                  format='0'
                />
              }
            />
          </Button>
        </Tooltip>
        <Text text={accountCount === 1 ? singularLabel : pluralizedLabel} />
      </WithSkeleton>
      <ClientModalAccounts
        open={showModal}
        modalTitle={accountCount === 1 ? singularTitle : pluralizedTitle}
        modalSubtitle={subtitle}
        filters={filters}
        columns={columns}
        defaultSort={defaultSort}
        handleClose={setShowModalOff}
      />
    </div>
  )
}

ClientAccounts.propTypes = {
  title: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string
  ]),
  label: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string
  ]),
  subtitle: PropTypes.string,
  filters: PropTypes.object,
  columns: PropTypes.array,
  defaultSort: PropTypes.array
}

ClientAccounts.defaultProps = {
  label: ['Account', 'Accounts'],
  title: ['Account', 'Accounts']
}

export default ClientAccounts
