import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import {
  List,
  ListItem,
  Box,
  Popper,
  Divider,
  Fade,
  ClickAwayListener,
  makeStyles
} from '@material-ui/core'
import { useOktaAuth } from '@okta/okta-react'
import { useAuthState } from '../../hooks'
import { useSetCurrentClient } from '../../redux/slices/appContext'
import { useCurrentClient } from '../../redux/slices/clientContext'
import { useSearchClients } from '../../api/clients'
import Icon from '../atoms/Icon'
import Text from '../atoms/Text'
import {
  DEFAULT_SEARCH_RECENTS_CLIENTS_PAGE_SIZE,
  ICON_NAMES, LOCAL_STORAGE_KEYS,
  TEXT_VARIANTS
} from '../../constants'
import { localStorageHelper } from '../../utils/localStorageHelper'
import MessageBadge from './MessageBadge'
import FastAvatar from './FastAvatar'

const useStyles = makeStyles((theme) => ({
  users: {
    '&:hover': {
      cursor: 'pointer'
    }
  },
  avatar: {
    '&:not(:first-child)': {
      marginLeft: '-0.8rem'
    }
  },
  popover: {
    marginTop: '5px',
    backgroundColor: theme.palette.white,
    padding: '1.25rem',
    borderRadius: '10px',
    zIndex: 10000
  },
  userOpt: {
    padding: 0,
    marginTop: '1.25rem',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  divider: {
    margin: '0.938rem 0'
  },
  actions: {
    padding: 0,
    '&:hover': {
      cursor: 'pointer',
      '&[disabled]': {
        cursor: 'auto'
      }
    },
    '&:last-child': {
      marginTop: '0.938rem'
    }
  },
  notificationBadgeIcon: {
    backgroundColor: theme.palette.success.A700,
    color: theme.palette.success.A700
  },
  usersList: {
    paddingBottom: 0
  }
}))

const ItemAction = ({ onClickAction, icon, text, classes, disabled = false }) => {
  return (
    <ListItem
      onClick={onClickAction}
      className={classes.actions}
      disabled={disabled}
    >
      <Box display='flex' alignItems='center' gridGap='0.625rem'>
        <Icon name={icon} customColor='black' customSize='1.375rem' />
        <Text text={text} variant={TEXT_VARIANTS.body1} />
      </Box>
    </ListItem>
  )
}

const WealthOwnerNavigation = ({ toggleNotesSideNav }) => {
  const { authState: oktaAuthState } = useOktaAuth()
  const authState = useAuthState(oktaAuthState)
  const setCurrentClient = useSetCurrentClient()
  const [, currentClient] = useCurrentClient()
  const {
    data,
    isLoading
  } = useSearchClients({
    take: DEFAULT_SEARCH_RECENTS_CLIENTS_PAGE_SIZE,
    includes: {
      avatars: true
    }
  })
  const clients = data?.data || []
  const classes = useStyles()
  const history = useHistory()

  const fullName = authState.idToken?.claims?.name || ''
  const email = authState.idToken?.claims?.email || ''

  const [anchorEl, setAnchorEl] = useState(null)
  const [open, setOpen] = React.useState(false)

  const signOut = useCallback(() => {
    localStorageHelper.removeItems(Object.values(LOCAL_STORAGE_KEYS))
    history.replace('/logout')
  }, [history])

  const openMenu = (event) => {
    setOpen((prev) => !prev)
    setAnchorEl(event.currentTarget)
  }

  const closeMenu = () => {
    setOpen(false)
    setAnchorEl(null)
  }

  const goToPreferences = () => {
    history.push('/preferences')
    closeMenu()
  }

  const selectClient = useCallback((clientId) => {
    if (clientId === currentClient.clientId) return

    setCurrentClient(clientId)
    closeMenu()
  }, [setCurrentClient, currentClient.clientId])

  if (isLoading) {
    return (
      <Box display='flex' alignItems='center' width='250px' />
    )
  }

  return (
    <ClickAwayListener onClickAway={closeMenu}>
      <Box display='flex' alignItems='center'>
        <Box marginRight='0.625rem'>
          <MessageBadge toggleNotesSideNav={toggleNotesSideNav} />
        </Box>
        <Box
          display='flex'
          alignItems='center'
          className={classes.users}
          onClick={openMenu}
        >
          {
            clients?.map((client, index, clients) => {
              return (
                <Box
                  display='flex'
                  alignItems='center'
                  className={classes.avatar}
                  style={{ zIndex: clients.length - index }} key={`avatar-container-${index}`}
                >
                  <FastAvatar size='xs' abbreviation={client.clientAbbreviation} avatarUrl={client.avatarUrl} />
                </Box>
              )
            })
          }
          <Icon
            customSize='1.2rem'
            name={ICON_NAMES.arrowDown}
            customColor='doveGray'
          />
        </Box>
        <Box>
          <Popper open={open} anchorEl={anchorEl} placement='bottom' transition className={classes.popover}>
            {({ TransitionProps }) => (
              <Fade {...TransitionProps} timeout={200}>
                <Box>
                  <Box>
                    <Text text={fullName} variant={TEXT_VARIANTS.h3} />
                    <Text text={email} variant={TEXT_VARIANTS.body1} />
                  </Box>
                  <Box>
                    <List component='nav'>
                      {
                        clients?.sort((a, b) => a.shortName.localeCompare(b.shortName))
                          .map((client) => {
                            const { clientId, longName: familyName, clientAbbreviation, avatarUrl } = client

                            return (
                              <ListItem
                                key={clientId}
                                onClick={() => selectClient(clientId)}
                                className={classes.userOpt}
                              >
                                <Box display='flex' alignItems='center' gridGap='0.625rem'>
                                  <FastAvatar size='sm' abbreviation={clientAbbreviation} avatarUrl={avatarUrl} />
                                  <Text text={familyName} variant={TEXT_VARIANTS.body1} customFontWeight={currentClient.clientId === clientId ? 'bold' : ''} />
                                </Box>
                              </ListItem>
                            )
                          })
                      }
                    </List>
                  </Box>
                  <Divider className={classes.divider} />
                  <List component='nav' className={classes.usersList}>
                    <ItemAction
                      onClickAction={goToPreferences}
                      icon='settings'
                      text='Preferences'
                      classes={classes}
                    />
                    <ItemAction
                      onClickAction={signOut}
                      icon='logout'
                      text='Logout'
                      classes={classes}
                    />
                  </List>
                </Box>
              </Fade>
            )}
          </Popper>
        </Box>
      </Box>
    </ClickAwayListener>
  )
}

WealthOwnerNavigation.propTypes = {
  toggleNotesSideNav: PropTypes.func
}

ItemAction.propTypes = {
  onClickAction: PropTypes.func,
  icon: PropTypes.string,
  text: PropTypes.string,
  classes: PropTypes.object,
  disabled: PropTypes.bool
}

export default WealthOwnerNavigation
