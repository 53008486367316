import { useCallback, useEffect, useMemo, useState } from 'react'
import { first, get, isEmpty, isFunction, last } from 'lodash'
import dayjs from 'dayjs'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import utc from 'dayjs/plugin/utc'
import { useAppContext } from '../../../redux/slices/appContext'
import { useDeepCompareMemo, useFetchState, useToggle } from '../../../hooks'
import { getHistoricalRegisterIdentifiers } from '../../../service'
import { CALC_TYPES, DATE_TYPES, DAYJS_UNIT_TYPES, LEVEL_TYPES, CHART_CURVE_INTERPOLATIONS, BUTTON_SIZES } from '../../../constants'
import { capitalizeFirstLetter, isNullOrUndefined } from '../../../utils'
import { DATE_RANGE_CUSTOM_OPTION_KEYS, useRelativeDateRange } from '../../molecules/RelativeDateSelect'
import { localStorageHelper } from '../../../utils/localStorageHelper'
import { useCoreSeriesData, useNormalizeDates } from '../../../api/coreData'

dayjs.extend(isSameOrBefore)
dayjs.extend(utc)

const lineChartMappers = {
  axisLeft: {
    percentagesMapper: function () {
      return this.isReturnOrBenchmark
        ? (value) => Math.trunc(value * 100 * 100) / 100 + '%'
        : '.2s'
    }
  },
  identifiersMapper: {
    skipCumulativeBenchmarkMapper: function () {
      return this.options.filter(
        (identifier) => identifier.value !== 'clientBenchmark'
      )
    }
  },
  lineData: {
    summaryMapper: function () {
      return this.values
        ? [
          {
            id: 'Summary',
            color: this.color || 'green',
            data: this.values.map(({ value, label }) => ({
              x: label,
              y: value
            }))
          }
        ]
        : []
    }
  }
}

const DEFAULT_BREAKPOINTS = {
  day: 0,
  month: 185,
  quarter: 730,
  year: 1460
}

const getDateIntervalByDateRange = (startDate, endDate, breakPoints = DEFAULT_BREAKPOINTS) => {
  const diffInDays = Math.floor(
    dayjs.utc(endDate).diff(startDate, 'day', true)
  )
  if (!isNullOrUndefined(breakPoints.year) && diffInDays > breakPoints.year) return DATE_TYPES.year
  if (!isNullOrUndefined(breakPoints.quarter) && diffInDays > breakPoints.quarter) return DATE_TYPES.quarter
  if (!isNullOrUndefined(breakPoints.month) && diffInDays > breakPoints.month) return DATE_TYPES.month

  return DATE_TYPES.day
}

const getCustomDataInterval = ({
  identifier,
  minDate,
  minDailyStartDate,
  selectedDate,
  selectedDataInterval
}) => {
  if (!minDailyStartDate) return selectedDataInterval

  const isHistoryAvailable = dayjs
    .utc(minDate)
    .isBefore(minDailyStartDate, DAYJS_UNIT_TYPES.DAY)
  if (!isHistoryAvailable) return selectedDataInterval

  const isDateBeforeMinDailyStartDate = dayjs
    .utc(selectedDate)
    .isBefore(minDailyStartDate, DAYJS_UNIT_TYPES.DAY)

  if (
    selectedDataInterval === DATE_TYPES.day &&
    isDateBeforeMinDailyStartDate &&
    identifier !== 'cumulativeReturn'
  ) {
    return DATE_TYPES.month
  }
  if (
    (selectedDataInterval === DATE_TYPES.month &&
      !isDateBeforeMinDailyStartDate) ||
    identifier === 'cumulativeReturn'
  ) {
    return DATE_TYPES.day
  }
  return selectedDataInterval
}

const calculateGetDateInterval = ({
  value,
  availableDates,
  selectedDateRange,
  selectedIdentifier,
  selectedGraphDataValue
}) => {
  const { startDate: start } = selectedDateRange || {}
  const { min, mainDate, minDailyStartDate } = availableDates
  const { startDate } = selectedGraphDataValue || {}
  const selectedStartDate = start || startDate || mainDate

  return getCustomDataInterval({
    selectedDate: selectedStartDate,
    identifier: selectedIdentifier,
    minDate: min,
    minDailyStartDate,
    selectedDataInterval: value
  })
}

const useDateInterval = ({
  selectedIdentifier,
  availableDates,
  selectedDateRange,
  selectedGraphDataValue,
  defaultDateInterval,
  intervalBreakpoints
}) => {
  const [selectedDataInterval, setSelectedDataInterval] = useState(
    defaultDateInterval || getDateIntervalByDateRange(
      selectedGraphDataValue.startDate,
      selectedGraphDataValue.endDate,
      intervalBreakpoints
    )
  )

  const onSelectDataInterval = useCallback(
    (value, range, event, automaticDataInterval = false) => {
      let dataInterval = value
      if (automaticDataInterval) {
        dataInterval = calculateGetDateInterval({
          value,
          availableDates,
          selectedDateRange,
          selectedIdentifier,
          selectedGraphDataValue
        })
      }
      setSelectedDataInterval(dataInterval)
    },
    [
      availableDates,
      selectedDateRange,
      selectedIdentifier,
      selectedGraphDataValue
    ]
  )

  return {
    selectedDataInterval,
    setSelectedDataInterval,
    onSelectDataInterval
  }
}

const getStartDateForCumulativeReturn = ({
  selectedStartDate,
  selectedEndDate,
  adjustedStartDate
}) => {
  if (!adjustedStartDate) return selectedStartDate

  const isStartDateBeforeMinDailyStartDate = dayjs
    .utc(selectedStartDate)
    .isBefore(adjustedStartDate, DATE_TYPES.day)

  if (isStartDateBeforeMinDailyStartDate) {
    const isEndDateBeforeMinDailyStartDate = dayjs
      .utc(selectedEndDate)
      .isBefore(adjustedStartDate, DATE_TYPES.day)

    if (!isEndDateBeforeMinDailyStartDate) return adjustedStartDate
  }
  return selectedStartDate
}

const getBenchmarkSeries = (dataPointConfig, seriesInfo) => {
  const { key: dataPointKey, lineChartConfig } = dataPointConfig
  return seriesInfo.names.benchmarkNames.map((benchmarkName, index) => {
    const benchmarkColor = seriesInfo.names.benchmarkColors.at(index)
    const benchmarkData = seriesInfo.series[dataPointKey]
    return {
      id: benchmarkName,
      color: benchmarkColor,
      lineChartConfig: lineChartConfig || {},
      data: benchmarkData.map((seriesData) => {
        return {
          x: seriesData.label,
          y: seriesData.value[index]
        }
      })
    }
  })
}

export const useLineChart = ({
  identifiersMapper,
  assetFilters,
  series,
  lineChartProps,
  onGraphDateChange,
  defaultFilter,
  dateRangeOptions,
  defaultValues,
  configurationStorageKey,
  dateIntervalOptions,
  intervalBreakpoints
}) => {
  const { availableDates, clientId } = useAppContext()
  const [selectedDateRange, setSelectedDateRange] = useState(defaultValues.dateRange)
  const [selectedGraphCurve, setSelectedGraphCurve] = useState(defaultValues.graphCurve)
  const [selectedGraphDate, setSelectedGraphDate] = useState(defaultValues.graphDate)
  const [selectedIdentifier, setSelectedIdentifier] = useState(defaultValues.identifier)

  const [
    showDateRangePicker,
    ,
    toggleDateRangePickerOn,
    toggleDateRangePickerOff
  ] = useToggle()

  const { dateRange: selectedGraphDataValue, options: graphDateRangeOptions } =
    useRelativeDateRange(selectedGraphDate, availableDates, dateRangeOptions)

  const { selectedDataInterval, onSelectDataInterval } = useDateInterval({
    availableDates,
    selectedDateRange,
    selectedIdentifier,
    selectedGraphDataValue,
    intervalBreakpoints
  })

  useEffect(() => {
    if (configurationStorageKey) {
      const payload = {
        dateRange: selectedDateRange,
        dataInterval: selectedDataInterval,
        graphCurve: selectedGraphCurve,
        graphDate: selectedGraphDate,
        identifier: selectedIdentifier
      }
      if (Object.values(payload).every((val) => val !== undefined)) {
        localStorageHelper.store(configurationStorageKey, payload)
      }
    }
  }, [
    selectedDateRange,
    selectedGraphCurve,
    selectedGraphDate,
    selectedIdentifier,
    selectedDataInterval,
    configurationStorageKey
  ])

  const { key, startDate, endDate, minDailyStartDate, minPerformanceStartDate } = useMemo(() => {
    const { mainDate, minDailyStartDate, minPerformanceStartDate } = availableDates
    const { key, startDate: start, endDate: end } = selectedDateRange || {}
    return {
      key,
      startDate: start || selectedGraphDataValue?.startDate || mainDate,
      endDate: end || selectedGraphDataValue?.endDate || mainDate,
      minDailyStartDate,
      minPerformanceStartDate
    }
  }, [
    availableDates,
    selectedDateRange,
    selectedGraphDataValue?.endDate,
    selectedGraphDataValue?.startDate
  ])

  const dataParams = useDeepCompareMemo(() => {
    let selectedStartDate = startDate
    if (
      ['cumulativeBenchmark', 'cumulativeReturn'].includes(selectedIdentifier) &&
      (minDailyStartDate || minPerformanceStartDate)
    ) {
      selectedStartDate = getStartDateForCumulativeReturn({
        selectedStartDate: startDate,
        selectedEndDate: endDate,
        adjustedStartDate: minPerformanceStartDate ?? minDailyStartDate
      })
    }
    const selectedSeries = series.find(({ identifier }) => {
      return selectedIdentifier === identifier
    })
    const dataPoints = selectedSeries?.dataPoints.map(({ key }) => key)

    return {
      key,
      endDate,
      startDate: selectedStartDate,
      dataPoints
    }
  }, [
    key,
    defaultFilter,
    availableDates,
    selectedDateRange,
    selectedIdentifier,
    selectedGraphDataValue
  ])

  /** Resolve line chart identifiers */
  const { historicalIdentifierOptions, loading: identifiersLoading } =
    useFetchState(
      useCallback(
        async (safeSetState) => {
          try {
            const { data } = await getHistoricalRegisterIdentifiers()
            const options = data.map(({ name, value }) => ({
              value,
              label: name
            }))
            const historicalIdentifierOptions =
              get(
                lineChartMappers.identifiersMapper,
                identifiersMapper,
                undefined
              ) || identifiersMapper
            safeSetState({
              historicalIdentifierOptions: historicalIdentifierOptions
                ? historicalIdentifierOptions.call({ options })
                : options
            })
          } catch (err) {
            console.error(err)
          }
        },
        [identifiersMapper]
      )
    )

  const normalizedDatesQuery = useMemo(() => {
    const { key, startDate, endDate } = dataParams
    return {
      query: {
        dateRanges: { seriesDateRange: { key, startDate, endDate } }
      },
      queryOptions: {}
    }
  }, [dataParams])

  const {
    data: normalizedDateRanges,
    isLoading: isLoadingNormalizedDates
  } = useNormalizeDates(
    normalizedDatesQuery.query,
    normalizedDatesQuery.queryOptions
  )

  const coreSeriesQuery = useMemo(() => {
    const {
      ASSET_CLASSES,
      SUBCLASSES,
      ASSETS_SUBCLASS,
      ASSETS: _ASSETS
    } = assetFilters

    const ASSETS = ASSETS_SUBCLASS || _ASSETS
    const assetClassTagIds = ASSET_CLASSES ? [ASSET_CLASSES?.id] : []
    const subclassTagIds = SUBCLASSES ? [SUBCLASSES?.id] : []
    const assetIds = ASSETS ? [ASSETS?.id] : []

    const { dataPoints } = dataParams
    const { seriesDateRange } = normalizedDateRanges || {}
    return {
      query: {
        dateRange: seriesDateRange,
        levelFilters: {
          identifier: selectedIdentifier,
          dateType: selectedDataInterval,
          assetIds,
          subclassTagIds,
          assetClassTagIds,
          clientIds: [clientId],
          calcType: CALC_TYPES.timeSeries,
          levelTypes: [
            LEVEL_TYPES.CLIENT,
            ASSET_CLASSES && LEVEL_TYPES.ASSET_CLASS_TAG,
            ASSETS_SUBCLASS && LEVEL_TYPES.SUBCLASS_TAG,
            ASSETS && LEVEL_TYPES.ASSETS
          ].filter(Boolean),
          benchmark: {
            benchmarkType: LEVEL_TYPES.CLIENT
          },
          ...defaultFilter
        },
        options: {
          dataPoints
        }
      },
      queryOptions: {
        enabled: !isLoadingNormalizedDates
      }
    }
  }, [
    clientId,
    dataParams,
    defaultFilter,
    assetFilters,
    selectedIdentifier,
    selectedDataInterval,
    normalizedDateRanges,
    isLoadingNormalizedDates
  ])

  /** Get series data from api */
  const { data: seriesInfo, isLoading } = useCoreSeriesData(
    coreSeriesQuery.query,
    coreSeriesQuery.queryOptions
  )

  const baseLineChartProps = useMemo(() => {
    if (!seriesInfo?.series) return {}

    const isReturnOrBenchmark =
      dataParams.dataPoints.includes('cumulativeReturnNOF') ||
      dataParams.dataPoints.includes('cumulativeReturn') ||
      dataParams.dataPoints.includes('benchmarks.benchmarkValue')

    const selectedSeries = series.find(
      ({ identifier }) => selectedIdentifier === identifier
    )
    const findDataPoint = (dataPoint) => {
      return selectedSeries.dataPoints.find(({ key }) => key === dataPoint)
    }

    let { axisLeft, lineData } = lineChartProps
    if (!isEmpty(lineChartProps.lineData)) {
      const lineDataMapper =
        get(lineChartMappers.lineData, lineChartProps.lineData, undefined) ||
        lineChartProps.lineData

      lineData = lineDataMapper.call({
        values: seriesInfo.series[selectedIdentifier]
      })
    }

    lineData = dataParams.dataPoints.reduce((acc, dataPoint) => {
      const seriesConfig = findDataPoint(dataPoint)
      if (seriesConfig.benchmarks) {
        const benchmarkSeries = getBenchmarkSeries(seriesConfig, seriesInfo)
        return [...acc, ...benchmarkSeries]
      }
      acc.push({
        id: seriesConfig?.label || seriesConfig.key,
        color: seriesConfig.color,
        lineChartConfig: seriesConfig?.lineChartConfig || {},
        data: seriesInfo.series[dataPoint].map(({ value, label }) => ({
          x: label,
          y: value
        }))
      })
      return acc
    }, [])

    if (isEmpty(axisLeft)) {
      axisLeft = {
        format: isReturnOrBenchmark
          ? (value) => {
            return Math.trunc(value * 100 * 100) / 100 + '%'
          }
          : '$.2s'
      }
    } else {
      const axisLeftFormat = get(
        lineChartMappers.axisLeft,
        axisLeft.format,
        axisLeft.format
      )

      axisLeft = {
        ...axisLeft,
        format: isFunction(axisLeftFormat)
          ? axisLeftFormat.call({ isReturnOrBenchmark })
          : axisLeftFormat
      }
    }

    return {
      data: [first(lineData)],
      width: '100%',
      height: '404px',
      fontFamily: 'Gotham-Book',
      areaOpacity: 1,
      margin: {
        top: 20,
        right: 20,
        bottom: 60,
        left: 60
      },
      axisBottom: { tickRotation: -40 },
      tooltipFormatter: isReturnOrBenchmark ? 'DATE_PERCENTAGE' : '',
      yScale: {
        type: 'linear',
        min: 'auto',
        max: 'auto'
      },
      ...lineChartProps,
      axisLeft: {
        tickValues: 5,
        ...axisLeft
      },
      lineData,
      curve: selectedGraphCurve,
      primaryLineConfigOverrides: first(lineData)?.lineChartConfig || {},
      secondaryLineConfigOverrides: last(lineData)?.lineChartConfig || {}
    }
  }, [
    dataParams,
    series,
    lineChartProps,
    seriesInfo,
    selectedIdentifier,
    selectedGraphCurve
  ])

  const onDateRangeChange = useCallback((startDate, endDate) => {
    setSelectedDateRange({ startDate, endDate })
  }, [])

  const onSelectIdentifier = useCallback((value) => {
    setSelectedIdentifier(value)

    // automatically set date interval when dataset type changes
    const dataInterval = getDateIntervalByDateRange(
      selectedGraphDataValue.startDate,
      selectedGraphDataValue.endDate
    )
    onSelectDataInterval(dataInterval, false)
  }, [
    onSelectDataInterval,
    selectedGraphDataValue.endDate,
    selectedGraphDataValue.startDate
  ])

  const onAvailableDateSelect = useCallback(
    (value, selectedOption) => {
      setSelectedGraphDate(value)
      if (value === DATE_RANGE_CUSTOM_OPTION_KEYS.CUSTOM) {
        toggleDateRangePickerOn()
      } else {
        setSelectedDateRange(null)
        toggleDateRangePickerOff()

        const startDate = selectedOption.getStartDate(availableDates)
        const dataInterval = getDateIntervalByDateRange(startDate, endDate, intervalBreakpoints)
        onSelectDataInterval(dataInterval, false)

        onGraphDateChange && onGraphDateChange(value, selectedOption)
      }
    },
    [
      endDate,
      availableDates,
      onGraphDateChange,
      onSelectDataInterval,
      toggleDateRangePickerOn,
      toggleDateRangePickerOff,
      intervalBreakpoints
    ]
  )

  const chartCurveOptions = Object.values(CHART_CURVE_INTERPOLATIONS).map(
    (curveType) => ({
      key: curveType,
      value: curveType,
      label: capitalizeFirstLetter(curveType)
    })
  )

  const onSelectGraphCurve = useCallback(
    (value) => {
      if (value in CHART_CURVE_INTERPOLATIONS) {
        setSelectedGraphCurve(value)
      } else {
        setSelectedGraphCurve(CHART_CURVE_INTERPOLATIONS.natural)
      }
    },
    [setSelectedGraphCurve]
  )

  return {
    historicalIdentifierOptions,
    identifiersLoading,
    seriesInfo,
    loading: isLoading,
    showDateRangePicker,
    selectedIdentifier,
    baseLineChartProps,
    availableDates,
    onAvailableDateSelect,
    onSelectIdentifier,
    dateInterval: {
      value: selectedDataInterval || DATE_TYPES.day,
      onChange: onSelectDataInterval,
      options: dateIntervalOptions,
      size: BUTTON_SIZES.small,
      showCheckMarOnSelectedItems: true
    },
    chartCurve: {
      value: selectedGraphCurve || CHART_CURVE_INTERPOLATIONS.natural,
      onChange: onSelectGraphCurve,
      options: chartCurveOptions,
      size: BUTTON_SIZES.small,
      showCheckMarOnSelectedItems: true
    },
    onDateRangeChange,
    selectedGraphDate,
    graphDateRangeOptions
  }
}
