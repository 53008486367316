import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import SelectWithCheckbox from '../Select/SelectWithCheckbox'

const GroupTagFilter = ({
  groupTypeId,
  groups,
  label,
  disabled,
  selectedTags,
  onChange: _onChange,
  ...props
}) => {
  const onChange = useCallback(
    (options) => {
      _onChange(groupTypeId, options)
    },
    [groupTypeId, _onChange]
  )
  return (
    <SelectWithCheckbox
      disabled={disabled}
      placeholder={label}
      prefixLabel={label}
      options={groups}
      onChange={onChange}
      selectedOptions={selectedTags}
      {...props}
    />
  )
}

GroupTagFilter.propTypes = {
  disabled: PropTypes.bool,
  groupTypeId: PropTypes.number,
  groups: PropTypes.array,
  label: PropTypes.string,
  onChange: PropTypes.func,
  selectedTags: PropTypes.object
}

export default GroupTagFilter
