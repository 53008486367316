import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'

const useStyles = makeStyles((theme) => ({
  floatingContent: {
    padding: '1rem'
  }
}))

const FloatingContent = ({ children, loading }) => {
  const classes = useStyles()

  if (loading) {
    return (
      <section className={classes.floatingContent}>
        <Skeleton height='10rem' width='100%' />
      </section>
    )
  }

  return (
    <section className={classes.floatingContent}>
      {children}
    </section>
  )
}

FloatingContent.propTypes = {
  children: PropTypes.node,
  loading: PropTypes.bool
}

export default FloatingContent
