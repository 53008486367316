import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import SydButton from '../../../../commonDesign/Button'
import { usePolicy } from '../../../../../hooks/usePolicy'
import FeeScheduleTiers from './FeeScheduleTiers'
import Section from './shared/Section'
import SectionTitle from './shared/SectionTitle'
import { useClientReviewContext } from './ClientReviewContext'

const useStyles = makeStyles((theme) => ({
  fsBody: {
    padding: `0 ${theme.layout.padding.medium}`
  },
  fs: {
    marginTop: theme.layout.margin.medium,
    border: `1px solid ${theme.palette.gray.darker}`,
    borderRadius: theme.layout.radius.tight,
    '&:hover': {
      cursor: 'pointer'
    },
    '& .__title': {
      padding: `${theme.layout.padding.thin}`,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between'
    },
    '&.__selected': {
      outline: `2px solid ${theme.palette.primary.main}`
    }
  }
}))

function FeeSchedules ({ feeSchedules, client, selectedDetail, onSelectDetail }) {
  const classes = useStyles()
  const crContext = useClientReviewContext()
  const canAssignFeeSchedules = usePolicy('billing_assign_fee_schedules')

  if (!feeSchedules) {
    return null
  }

  if (!feeSchedules.length) {
    return (
      <Section>
        <SectionTitle title='Fee Schedules'>
          {canAssignFeeSchedules ? (
            <SydButton size='xs' icon='add' onClick={() => crContext.dialogs.addFeeSchedule.current.open(client)}>Add</SydButton>
          ) : null}
        </SectionTitle>
        <div className='__no-content'>
          <span>No Fee Schedules Assigned</span>
        </div>
      </Section>
    )
  }

  return (
    <Section>
      <SectionTitle title='Fee Schedules'>
        {canAssignFeeSchedules ? (
          <SydButton size='xs' icon='add' onClick={() => crContext.dialogs.addFeeSchedule.current.open(client)}>Add</SydButton>
        ) : null}
      </SectionTitle>
      <div className={classes.fsBody}>
        {feeSchedules.map(fs => (
          <div
            key={fs.feeScheduleId}
            onClick={() => onSelectDetail({ type: 'feeSchedule', detail: fs })}
            className={clsx(classes.fs, { __selected: selectedDetail?.type === 'feeSchedule' && selectedDetail?.detail?.feeScheduleId === fs.feeScheduleId })}
          >
            <div className='__title'>
              <span>{fs.longName}</span>
              {fs.overrideCount ? (<span className='__danger'>+{fs.overrideCount} Overrides</span>) : null}
            </div>
            <FeeScheduleTiers feeSchedule={fs} />
          </div>
        ))}
      </div>
    </Section>
  )
}

FeeSchedules.propTypes = {
  client: PropTypes.object,
  feeSchedules: PropTypes.array,
  selectedDetail: PropTypes.any,
  onSelectDetail: PropTypes.func
}

export default FeeSchedules
