import { useMemo } from 'react'
import { isEmpty, isString, isNumber, concat, times, maxBy, isArray, isPlainObject } from 'lodash'
import numeral from 'numeral'
import { CSV_CELL_TYPES } from '../constants'

function getCellValue ({ value = '', csvData, exportValue }) {
  if (isEmpty(csvData)) {
    return exportValue || value
  } else {
    const { originalValue, type } = csvData
    const mainValue = originalValue || value
    switch (type) {
      case CSV_CELL_TYPES.string:
      case CSV_CELL_TYPES.component:
        return mainValue
      case CSV_CELL_TYPES.percentage:
        return numeral(mainValue * 100).format('0.0')
      case CSV_CELL_TYPES.number:
        return numeral(mainValue).format('0.00')
      default:
        return mainValue
    }
  }
}

function getLiteralLabelRow (row) {
  const literalRow = row.reduce((rowAcc, cell) => {
    if (cell === undefined) return rowAcc
    if (isNumber(cell) || isString(cell)) {
      rowAcc.push(cell)
    } else {
      const {
        name,
        hidden = false,
        isSelect = false,
        selectProps = {},
        dateRange,
        colSpan = 1
      } = cell
      if (!hidden) {
        if (dateRange && dateRange.startDate && dateRange.endDate) {
          rowAcc.push(`${dateRange.startDate} - ${dateRange.endDate}`)
        }
        if (isSelect && !isEmpty(selectProps)) {
          rowAcc.push(selectProps.selectedValue)
        } else {
          if (!isPlainObject(name)) {
            rowAcc.push(name)
          }
        }
        if (isNumber(colSpan) && colSpan > 1) {
          new Array(colSpan - 1).fill('').forEach((item) => {
            rowAcc.push(item)
          })
        }
      }
    }
    return rowAcc
  }, [])
  return literalRow
}

function getLiteralDataRow (row) {
  const literalRow = row.reduce((rowAcc, cell) => {
    if (isNumber(cell) || isString(cell)) {
      rowAcc.push(cell)
    } else {
      const { hidden = false, editableProps = false, colSpan = 1 } = cell
      if (!hidden && !editableProps) {
        const finalValue = getCellValue(cell)
        rowAcc.push(finalValue)
        if (isNumber(colSpan) && colSpan > 1) {
          new Array(colSpan - 1).fill('').forEach((item) => {
            rowAcc.push(item)
          })
        }
      }
    }
    return rowAcc
  }, [])
  return literalRow
}

function getDataFromSimpleTable ({ rows, labels, finalRow }) {
  const exportLabels = [getLiteralLabelRow(labels)]
  return rows.reduce((acc, row, index) => {
    const literalRow = getLiteralDataRow(row)
    acc.push(literalRow)
    if (index === rows.length - 1 && !isEmpty(finalRow)) {
      const exportFinalRow = getLiteralDataRow(finalRow)
      acc.push(exportFinalRow)
    }
    return acc
  }, exportLabels)
}

function getHeaderIdentation (maxLevel, nestedHeaders = []) {
  if (maxLevel < 1) return []
  if (isEmpty(nestedHeaders)) return times(maxLevel, () => '')
  return nestedHeaders.reduce((acc, item, index) => {
    if (index <= maxLevel) {
      acc.push(item)
    }
    return acc
  }, [])
}

function getLabelsFromCollapsibleTable ({ identation, labels, maxLevel, nestedHeaders }) {
  const headerIdentation = getHeaderIdentation(maxLevel, nestedHeaders)
  if (!isArray(labels[0])) return concat(headerIdentation, getLiteralLabelRow(labels))
  return labels.map((row, index) => {
    if (index === labels.length - 1) return concat(headerIdentation, getLiteralLabelRow(row))
    return concat(identation, getLiteralLabelRow(row))
  })
}

function generateInstructionsToPrint (rows, level = 0) {
  const instructions = rows.reduce((acc, row) => {
    const rowInfo = row.reduce((rowAcc, cell, indexCell) => {
      const { hidden = false, isExpandible = false, rows = [], colSpan } = cell
      const finalValue = getCellValue(cell)
      if (!hidden) {
        if (indexCell === 0) {
          rowAcc.mainValue = finalValue
          rowAcc.mainValueColSpan = colSpan
        } else {
          rowAcc.rest.push(finalValue)
          if (isNumber(colSpan) && colSpan > 1) {
            new Array(colSpan - 1).fill('').forEach((item) => {
              rowAcc.rest.push(item)
            })
          }
        }
      }
      if (hidden && isExpandible && !isEmpty(rows)) {
        const childRows = generateInstructionsToPrint(rows, level + 1)
        rowAcc.children = childRows
      }
      return rowAcc
    }, { mainValueColSpan: 1, mainValue: '', rest: [], level, children: [], parents: [] })
    acc.push({ ...rowInfo, level })
    if (isEmpty(!rowInfo.children)) {
      rowInfo.children.forEach((childRow) => {
        acc.push({ ...childRow, parents: [rowInfo.mainValue, ...childRow.parents] })
      })
    }
    return acc
  }, [])
  return instructions
}

function getDataFromInstructions (instructions, maxLevel) {
  return instructions.map((row) => {
    const difMaxLevel = maxLevel - row.level
    const identation = difMaxLevel > 0 ? times(difMaxLevel, () => 'Total') : []
    const mainValueColSpan = row.mainValueColSpan > 1 ? times(row.mainValueColSpan - 1, () => '') : []
    return concat(row.parents, row.mainValue, mainValueColSpan, identation, row.rest)
  })
}

function getAsOfDateRow (asOfDate) {
  return asOfDate ? [`As of ${asOfDate}`] : []
}

function getDataFromCollapsibleTable ({ rows, labels, finalRow, multiHeaderRows, asOfDate, nestedHeaders }) {
  const instructions = generateInstructionsToPrint(rows)
  if (isEmpty(instructions)) return []
  const asOfDateRow = getAsOfDateRow(asOfDate)
  const maxLevel = maxBy(instructions, (value) => value.level)
  const identation = maxLevel.level > 0 ? times(maxLevel.level, () => '') : []
  const exportLabels = getLabelsFromCollapsibleTable({ identation, labels, maxLevel: maxLevel.level, nestedHeaders })
  const exportData = getDataFromInstructions(instructions, maxLevel.level)
  if (finalRow) {
    const exportFinalRow = concat(identation, getLiteralDataRow(finalRow))
    return concat([asOfDateRow], multiHeaderRows ? exportLabels : [exportLabels], exportData, [exportFinalRow])
  }
  return concat([asOfDateRow], multiHeaderRows ? exportLabels : [exportLabels], exportData)
}

export default function useCSVData ({
  rows,
  finalRow,
  labels,
  loading = false,
  expansible = false,
  disabled = false,
  multiHeaderRows = false,
  asOfDate,
  nestedHeaders = []
}) {
  return useMemo(() => {
    if (disabled || loading || isEmpty(rows)) return []
    try {
      const finalExport = expansible
        ? getDataFromCollapsibleTable({ rows, finalRow, labels, multiHeaderRows, asOfDate, nestedHeaders })
        : getDataFromSimpleTable({ rows, finalRow, labels })
      return finalExport
    } catch (e) {
      console.error(e)
      return ['error']
    }
  }, [rows, finalRow, loading, labels, expansible, disabled, multiHeaderRows, asOfDate, nestedHeaders])
}
