import React from 'react'
import { makeStyles } from '@material-ui/core'
import { useMilestonesContext } from '../MilestonesContext'
import { VIEW_TYPES } from '../../ClientCardsViewV2/helpers'
import MilestonesViewHeader from './MilestonesViewHeader'
import MilestonesTable from './MilestonesTable'
import MilestonesCards from './MilestonesCards'

const useStyles = makeStyles(() => ({
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column'
  }
}))

const MilestonesViewContent = () => {
  const classes = useStyles()
  const { viewType } = useMilestonesContext()
  return (
    <div className={classes.container}>
      <MilestonesViewHeader />
      {viewType === VIEW_TYPES.LIST && <MilestonesTable />}
      {viewType === VIEW_TYPES.BLOCKS && <MilestonesCards />}
    </div>
  )
}

export default MilestonesViewContent
