import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, useTheme } from '@material-ui/core'
import isEmpty from 'lodash/isEmpty'
import noop from 'lodash/noop'
import EmptySection from '../../../atoms/EmptySection'
import WealthJourneyLine from '../../../molecules/WealthJourneyLineV2'
import Text from '../../../atoms/Text'
import { entryTypePropType } from './entryType'

const useStyles = ({ hideMarketValue }) => {
  const theme = useTheme()
  const styles = makeStyles(() => ({
    lineGraphContainer: {
      height: hideMarketValue ? '150px' : '15rem',
      width: '100%',
      minWidth: '13.5rem',
      padding: '0px',
      marginBottom: '1rem'
    },
    emptyState: {
      width: '100%',
      padding: '0 1.5rem'
    },
    title: {
      color: theme.palette.cloudBurst,
      fontWeight: 700,
      fontSize: '0.8rem',
      marginTop: '0rem'
    }
  }))()

  return [styles, theme]
}

const emptyStateStyles = {
  padding: '7rem 2rem',
  borderRadius: '0.75rem'
}

const WealthJourneyChart = ({
  loading,
  chartData,
  chartTitle,
  selectedTabStep,
  appContext,
  entries,
  selectedEntry,
  onEntrySelected,
  yearsInRange,
  selectedEntryType,
  filterEntryTypesOnChart,
  includeMeetings,
  includeMilestones,
  hideMarketValue,
  hideActiveDatePeriodMarker,
  theming
}) => {
  const [classes] = useStyles({ hideMarketValue })

  if (loading) {
    return (
      <div className={classes.emptyState}>
        <EmptySection styles={emptyStateStyles} />
      </div>
    )
  }

  if (isEmpty(chartData)) {
    return (
      <div className={classes.emptyState}>
        <EmptySection description='No data available' styles={emptyStateStyles} />
      </div>
    )
  }

  return (
    <div className={classes.lineGraphContainer}>
      {!hideMarketValue && (
        <Grid item xs={12}>
          <Text
            text={chartTitle}
            className={classes.title}
          />
        </Grid>
      )}
      <WealthJourneyLine
        data={chartData}
        activeDatePeriod={selectedTabStep}
        appContext={appContext}
        entries={entries}
        selectedEntry={selectedEntry}
        onEntrySelected={onEntrySelected}
        yearsInRange={yearsInRange}
        selectedEntryType={selectedEntryType}
        filterEntryTypesOnChart={filterEntryTypesOnChart}
        includeMeetings={includeMeetings}
        includeMilestones={includeMilestones}
        hideMarketValue={hideMarketValue}
        hideActiveDatePeriodMarker={hideActiveDatePeriodMarker}
        theming={theming}
      />
    </div>
  )
}

WealthJourneyChart.propTypes = {
  loading: PropTypes.bool.isRequired,
  chartData: PropTypes.object,
  chartTitle: PropTypes.string,
  selectedTabStep: PropTypes.any,
  appContext: PropTypes.object.isRequired,
  entries: PropTypes.array.isRequired,
  onEntrySelected: PropTypes.func,
  selectedEntry: PropTypes.object,
  yearsInRange: PropTypes.array,
  selectedEntryType: entryTypePropType.isRequired,
  filterEntryTypesOnChart: PropTypes.bool,
  includeMeetings: PropTypes.bool,
  includeMilestones: PropTypes.bool,
  hideMarketValue: PropTypes.bool,
  hideActiveDatePeriodMarker: PropTypes.bool,
  theming: PropTypes.object
}

WealthJourneyChart.defaultProps = {
  chartData: null,
  selectedTabStep: null,
  selectedEntry: null,
  onEntrySelected: noop,
  yearsInRange: [],
  filterEntryTypesOnChart: false,
  includeMeetings: false,
  includeMilestones: false,
  chartTitle: 'MARKET VALUE',
  hideMarketValue: false,
  hideActiveDatePeriodMarker: false
}

export default WealthJourneyChart
