import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { ResponsiveBar } from '@nivo/bar'
import { useTheme } from '@material-ui/core'
import { useAppContext, useAvailableDates } from '../../../../redux/slices/appContext'
import { useAlternatives } from '../../../../api/coreData'
import { useFormattingContext } from '../../FormattingProvider/FormattingContext'

function AltsProjectionChart ({
  numProjectionPeriods,
  levelTypes,
  padding,
  chartProps,
  highColor,
  lowColor,
  highMarginFactor,
  lowMarginFactor,
  containerStyle
}) {
  const [availableDates] = useAvailableDates()
  const theme = useTheme()
  const { formatter } = useFormattingContext()
  const { clientId } = useAppContext()
  const query = useMemo(() => {
    const { mainDate, min } = availableDates
    const startDate = min || mainDate
    const endDate = mainDate

    return {
      dateRange: {
        startDate,
        endDate
      },
      filters: {
        clientId
      },
      levelType: levelTypes,
      includes: {
        cashFlowProjections: {
          numPeriods: numProjectionPeriods
        }
      }
    }
  }, [numProjectionPeriods, availableDates, clientId, levelTypes])

  const { data, isLoading, error } = useAlternatives(query)

  // This regroups the data into the format the bar chart needs
  const groupedData = useMemo(() => {
    if (isLoading || error) return []

    const projections = [...Array(numProjectionPeriods).keys()].map(x => x + 1)
    const result = projections.reduce((p, c) => {
      const keys = data.reduce((pd, d) => {
        pd[d.levelName] = +d[`projectedCashFlow${c}`]
        return pd
      }, {})

      p.push({
        period: c,
        ...keys
      })

      return p
    }, [])

    const excluded = ['period', 'color']

    const minValue = result.reduce((agg, period) => {
      const sum = Object.entries(period).filter(([key, value]) => !excluded.includes(key) && value < 0).reduce((x, [key, value]) => x + value, 0)
      return (sum < agg) ? sum : agg
    }, -1)

    const maxValue = result.reduce((agg, period) => {
      const sum = Object.entries(period).filter(([key, value]) => !excluded.includes(key) && value > 0).reduce((x, [key, value]) => x + value, 0)
      return (sum > agg) ? sum : agg
    }, 0)

    return {
      data: result,
      keys: Object.keys(data.reduce((pd, p) => {
        pd[p.levelName] = 1
        return pd
      }, {})),
      minValue: minValue * lowMarginFactor,
      maxValue: maxValue * highMarginFactor
    }
  }, [data, isLoading, error, numProjectionPeriods, highMarginFactor, lowMarginFactor])

  if (isLoading) {
    return null
  }

  if (error) {
    return <div>{error?.toString() ?? ''}</div>
  }

  return (
    <div style={containerStyle}>
      <ResponsiveBar
        theme={{
          fontFamily: theme.typography.fontFamily,
          fontSize: '12px'
        }}
        data={groupedData.data}
        groupMode='stacked'
        indexBy='period'
        keys={groupedData.keys}
        label={() => null}
        margin={{ top: 50, right: 100, bottom: 50, left: 100 }}
        padding={padding}
        maxValue={groupedData.maxValue}
        minValue={groupedData.minValue}
        valueScale={{ type: 'linear' }}
        indexScale={{ type: 'band', round: true }}
        colors={d => d.value > 0 ? highColor : lowColor}
        axisBottom={{
          format: (d) => `${d} Year${d !== 1 ? 's' : ''}`
        }}
        axisLeft={{
          format: (d) => formatter(d, 'human')
        }}
        enableGridX={false}
        enableGridY={false}
        {...chartProps}
      />
    </div>
  )
}

AltsProjectionChart.propTypes = {
  numProjectionPeriods: PropTypes.number,
  levelTypes: PropTypes.array,
  padding: PropTypes.number,
  chartProps: PropTypes.object,
  highColor: PropTypes.string,
  lowColor: PropTypes.string,
  highMarginFactor: PropTypes.number,
  lowMarginFactor: PropTypes.number,
  containerStyle: PropTypes.object
}

AltsProjectionChart.defaultProps = {
  numProjectionPeriods: 5,
  levelTypes: ['client', 'asset'],
  padding: 0.5,
  chartProps: {},
  highColor: '#90a5be',
  lowColor: '#9e3533',
  highMarginFactor: 1.2,
  lowMarginFactor: 2.5,
  containerStyle: { height: '300px', width: '100%' }

}

export default AltsProjectionChart
