import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Accordion, AccordionDetails, AccordionSummary, Box, IconButton, Tooltip, makeStyles } from '@material-ui/core'
import { isEmpty } from 'lodash'
import { CLIENTS_CHILDREN_TYPE, CLIENT_CONVERSATION_FILTERS, ICON_NAMES, LOCAL_STORAGE_KEYS } from '../../../../constants'
import { useBoolean, useDeepCompareMemo } from '../../../../hooks'
import { useContextContainer } from '../../../../abundanceEngine/components/ContextContainer'
import { CLIENT_VIEW_CONTEXT } from '..'
import Select from '../../../molecules/Select'
import { useRecentClients, useSearchClients } from '../../../../api/clients'
import ClientViewTypeToggle from '../components/ClientViewTypeToggle'
import Icon from '../../../atoms/Icon'
import { localStorageHelper } from '../../../../utils/localStorageHelper'
import { useBookmarks } from '../../../../api/groups'
import { mapBookmarks, mapClientBalancesToKeyValue } from '../helpers'
import useFetchClientBalances from '../hooks/useClientBalances'
import ClientHighlightedList from './ClientHighlightedList'
import ClientCard from './ClientCard'

const DEFAULT_PAGE_SIZE_CARD_RECENT = 5

const CLIENT_CARD_TYPES = [
  { label: 'Bookmarked', value: CLIENTS_CHILDREN_TYPE.bookmarked },
  { label: 'Recently Viewed', value: CLIENTS_CHILDREN_TYPE.recentlyViewed }
]

const CLIENT_CONVERSATION_FILTER_OPTIONS = [
  { label: 'Last 30 days', value: CLIENT_CONVERSATION_FILTERS.last30Days }
]

const [selectedCardConversationFilter] = CLIENT_CONVERSATION_FILTER_OPTIONS

const useStyles = makeStyles(() => ({
  container: {},
  filterTitle: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  listContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap'
  },
  filterOptions: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: 'auto'
  },
  clientTypeSelectorLabel: {
    fontSize: '1.25rem',
    fontWeight: 'bold'
  },
  accordion: {
    boxShadow: 'none',
    backgroundColor: 'white',
    '& .MuiAccordionSummary-root': {
      padding: '0 0'
    },
    '& .MuiAccordionSummary-content': {
      margin: '0.5rem 0'
    }
  }
}))

const clientBookmarksQuery = {
  levelType: 'clients'
}

const ClientCardRecentInteractions = () => {
  const [
    {
      columnWidth,
      realColumnWidth,
      defaultHighlightedCardType,
      hideHighlightedExpandCollapseButton
    },
    setContextContainer
  ] = useContextContainer(CLIENT_VIEW_CONTEXT)

  const [expanded, setExpanded] = useBoolean(
    localStorageHelper.load(
      LOCAL_STORAGE_KEYS.clientCardRecentInteractionsExpanded
    ) || hideHighlightedExpandCollapseButton
  )

  const classes = useStyles({ columnWidth: realColumnWidth || columnWidth })

  const [selectedCardType, setCardType] = useState(
    localStorageHelper.load(
      LOCAL_STORAGE_KEYS.clientCardRecentInteractionsType
    ) || defaultHighlightedCardType
  )

  useEffect(() => {
    localStorageHelper.store(
      LOCAL_STORAGE_KEYS.clientCardRecentInteractionsExpanded,
      expanded
    )
  }, [expanded])

  useEffect(() => {
    localStorageHelper.store(
      LOCAL_STORAGE_KEYS.clientCardRecentInteractionsType,
      selectedCardType
    )
  }, [selectedCardType])

  const onClientCardTypeChange = useCallback((value) => {
    setCardType(value)
  }, [])

  const {
    data: recentlyViewedCards = [],
    isLoading: isLoadingRecentlyViewedClients
  } = useRecentClients(DEFAULT_PAGE_SIZE_CARD_RECENT)

  const { data: bookmarks = [] } = useBookmarks(clientBookmarksQuery)

  const clientBookmarkQuery = useMemo(() => {
    const clientBookmarked = bookmarks.map(({ levelId }) => levelId)
    return {
      query: {
        filters: {
          clientId: [{ op: 'in', value: clientBookmarked }]
        },
        includes: {
          avatars: true
        }
      },
      queryOptions: {
        enabled: !isEmpty(bookmarks),
        mapper: ({ data }) => data
      }
    }
  }, [bookmarks])

  const { data: bookmarkedCards = [], isLoading: loadingBookmarkedClients } =
    useSearchClients(
      clientBookmarkQuery.query,
      clientBookmarkQuery.queryOptions
    )

  const isLoadingBookmarkedClients =
    loadingBookmarkedClients && !isEmpty(bookmarks)

  useEffect(() => {
    if (!isEmpty(bookmarks)) {
      setContextContainer((prevState) => ({
        ...prevState,
        clientBookmarks: {
          ...prevState.clientBookmarks,
          ...mapBookmarks(bookmarks)
        }
      }))
    }
  }, [bookmarks, setContextContainer])

  const clientBalancesQuery = useDeepCompareMemo(() => {
    const clientIds = (
      selectedCardType === CLIENTS_CHILDREN_TYPE.recentlyViewed
        ? recentlyViewedCards
        : bookmarkedCards
    ).map(({ clientId }) => clientId)

    return {
      query: { clientIds },
      options: {
        mapper: mapClientBalancesToKeyValue,
        enabled: !isEmpty(clientIds)
      }
    }
  }, [selectedCardType, recentlyViewedCards, bookmarkedCards])

  const { data: clientBalances = [], isLoading } = useFetchClientBalances(
    clientBalancesQuery.query,
    clientBalancesQuery.options
  )

  useEffect(() => {
    if (!isLoading) {
      setContextContainer((prevState) => {
        return {
          ...prevState,
          clientBalances: {
            ...prevState.clientBalances,
            ...clientBalances
          }
        }
      })
    }
  }, [clientBalances, isLoading, setContextContainer])

  return (
    <div className={classes.container}>
      <Accordion expanded={expanded} classes={{ root: classes.accordion }}>
        <AccordionSummary id='client-cards-accordion'>
          <div className={classes.filterTitle}>
            <Select
              labelClassName={classes.clientTypeSelectorLabel}
              value={selectedCardType}
              options={CLIENT_CARD_TYPES}
              onChange={onClientCardTypeChange}
            />
            {!hideHighlightedExpandCollapseButton && (
              <Tooltip title={`${expanded ? 'Collapse' : 'Expand'} Section`}>
                <IconButton
                  onClick={(event) => {
                    event.stopPropagation()
                    setExpanded.toggle()
                  }}
                >
                  <Icon
                    name={
                      expanded ? ICON_NAMES.unfoldLess : ICON_NAMES.unfoldMore
                    }
                    customSize='1.5rem'
                    color='black'
                  />
                </IconButton>
              </Tooltip>
            )}
            <div className={classes.filterOptions}>
              <Select
                disabled
                options={CLIENT_CONVERSATION_FILTER_OPTIONS}
                value={selectedCardConversationFilter.value}
              />
              <Box mx='1rem' />
              <ClientViewTypeToggle />
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className={classes.listContainer}>
            {selectedCardType === CLIENTS_CHILDREN_TYPE.recentlyViewed ? (
              <ClientHighlightedList
                clients={recentlyViewedCards}
                cardComponent={ClientCard}
                isLoading={isLoadingRecentlyViewedClients}
                emptyPlaceholderTitle='No recently viewed clients found'
              />
            ) : (
              <ClientHighlightedList
                clients={bookmarkedCards}
                cardComponent={ClientCard}
                isLoading={isLoadingBookmarkedClients}
                emptyPlaceholderTitle='No bookmarked clients found'
              />
            )}
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  )
}

export default ClientCardRecentInteractions
