import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { makeStyles, Grid, Typography, Divider } from '@material-ui/core'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import get from 'lodash/get'
import Card from '../../../../molecules/Card'
import TypeChip from '../../shared/TypeChip'
import FlexRow from '../../../../molecules/FlexRow'
import { useAssignedAttachmentsByClient } from '../../../../../api/documentVault'
import EntryMenu from '../EntryMenu'
import TaskListItem from './TaskListItem'
import EntryAttachmentList from './EntryAttachmentList'
import EntryContentField from './EntryContentField'

dayjs.extend(utc)

const useStyles = makeStyles(theme => ({
  entryContent: {
    '& p': {
      fontSize: theme.typography.fontSizes.xxl,
      fontWeight: theme.typography.weights.light
    }
  },
  description: {
    fontSize: theme.typography.fontSizes.xxl
  },
  taskList: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.layout.gap.g10
  }
}))

function GenericEntryContent ({ entry }) {
  const classes = useStyles()
  const { data: attachmentsData } = useAssignedAttachmentsByClient(1500, entry.entryId, entry.clientId)
  const entryFields = useMemo(() => {
    return (entry.entryType?.attributes || []).map(attr => {
      return {
        value: get(entry, attr.accessor),
        key: attr.accessor,
        label: attr.label,
        description: attr.description,
        ordinal: attr.ordinal,
        data: attr.data,
        type: attr.type
      }
    }).filter(entry => !!entry.value)
      .sort((a, b) => a.ordinal - b.ordinal)
  }, [entry])

  const title = entry.entryJson?.title || entry.entryText
  const tasks = entry.entryJson?.tasks || []
  const attachments = attachmentsData?.attachments || []
  const hasLeft = entryFields.length
  const hasRight = tasks.length > 0 || attachments.length > 0
  const hasContent = hasLeft || hasRight
  const [targetDate] = useMemo(
    () => {
      const entryDate = dayjs.utc(entry.entryDate)
      return [entryDate.format('MMM D, YYYY')]
    },
    [entry?.entryDate]
  )

  return (
    <Card className={classes.entryContent}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <FlexRow style={{ justifyContent: 'space-between' }}>
            <Typography variant='h2'>{title}</Typography>
            <FlexRow>
              <TypeChip entryTypeId={entry.entryTypeId} />
              <EntryMenu entry={entry} size='24px' />
            </FlexRow>
          </FlexRow>
          <FlexRow>
            <div className={classes.date}>{targetDate}</div>
          </FlexRow>
        </Grid>
        {hasContent ? (
          <Grid item xs={12}>
            <Divider />
          </Grid>
        ) : null}
        <Grid item xs={8}>
          {hasLeft ? (
            <Grid container spacing={1}>
              {entryFields.map(ef => (
                <Grid key={ef.key} item xs={12}>
                  <EntryContentField field={ef} />
                </Grid>
              ))}
            </Grid>
          ) : null}
        </Grid>
        <Grid item xs={4}>
          {hasRight ? (
            <Grid container spacing={3}>
              {attachments.length ? (
                <Grid item xs={12}>
                  <Typography variant='h3'>Attachments</Typography>
                  <Card>
                    <EntryAttachmentList attachments={attachments} />
                  </Card>
                </Grid>
              ) : null}
              {tasks.length ? (
                <Grid item xs={12}>
                  <Typography variant='h3'>Tasks</Typography>
                  <Card>
                    <div className={classes.taskList}>
                      {tasks.map(t => (
                        <TaskListItem key={t.id} task={t} />
                      ))}
                    </div>
                  </Card>
                </Grid>
              ) : null}
            </Grid>
          ) : null}
        </Grid>
      </Grid>
    </Card>
  )
}

GenericEntryContent.propTypes = {
  entry: PropTypes.object.isRequired
}

export default GenericEntryContent
