import React, { useMemo } from 'react'
import { makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import MilestoneIcon from '../../../../molecules/MilestoneIcon'
import Text from '../../../../atoms/Text'

const useStyles = makeStyles((theme) => ({
  container: {
    opacity: ({ disabled }) => (disabled ? 0.5 : 1),
    position: 'relative',
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'center',
    justifyContent: 'flex-start',
    fontSize: '1rem',
    width: 'auto',
    maxWidth: '230px',
    minWidth: '210px',
    height: '280px',
    borderRadius: '8px',
    padding: '1.625rem 1rem 1rem 1rem',
    borderStyle: 'solid',
    borderWidth: '3px',
    borderColor: theme.palette.baseBackground.main,
    boxSizing: 'border-box',
    margin: 'auto',
    textAlign: 'center',
    cursor: ({ clickable }) => (clickable ? 'pointer' : 'none'),
    '&:hover': {
      borderColor: ({ disabled }) =>
        disabled ? theme.palette.baseBackground.main : theme.palette.indigoBlue,
      cursor: ({ disabled }) => (disabled ? 'initial' : 'pointer')
    }
  },
  title: {
    fontSize: '1rem',
    fontWeight: 700,
    textAlign: 'center',
    paddingTop: '1.25rem',
    overflow: 'hidden'
  },
  target: {
    fontSize: '0.75rem',
    textAlign: 'center',
    position: 'absolute',
    opacity: '0.5',
    bottom: '1rem'
  },
  icon: {
    width: '5.5rem',
    height: '5.5rem',
    fontSize: '2rem',
    marginTop: '0.5rem',
    color: theme.palette.summitBlue,
    backgroundColor: theme.palette.baseBackground.avatar
  }
}))

const MilestoneCard = ({ milestone, disabled, onClick }) => {
  const classes = useStyles({ disabled, clickable: Boolean(onClick) })

  const targetDate = useMemo(
    () => dayjs.utc(milestone.entryDate).format('MMM YYYY'),
    [milestone.entryDate]
  )

  return (
    <div className={classes.container} onClick={onClick}>
      <Text
        className={classes.milestoneHeader}
        text={milestone?.client?.longName || ''}
      />
      <MilestoneIcon className={classes.icon} milestone={milestone} />
      <div className={classes.title}>
        {milestone.entryJson.title}
      </div>
      <div className={classes.target}>
        Target: {targetDate}
      </div>
    </div>
  )
}

MilestoneCard.propTypes = {
  milestone: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  onClick: PropTypes.func
}

export default MilestoneCard
