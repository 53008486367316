const planSorting = (planA, planB, activePlanId) => {
  if (planA.planId === activePlanId) return -4
  if (planB.planId === activePlanId) return 4
  if (planA.originPlanId === activePlanId) return -3
  if (planB.originPlanId === activePlanId) return 3
  if (planA.planId === planB.originPlanId) return -2
  if (planB.planId === planA.originPlanId) return 2
  return planA.shortName.localeCompare(planB.shortName)
}

export function PlansReducer (state, action) {
  switch (action.type) {
    case 'SET_PLANS': {
      const activePlanId = action.payload.find(plan => plan.planStatusKey === 'ACTIVE')?.planId
      return action.payload.sort((planA, planB) => planSorting(planA, planB, activePlanId))
    }

    case 'ADD_PLAN': {
      const activePlanId = state.find(plan => plan.planStatusKey === 'ACTIVE')?.planId
      return [
        ...state,
        action.payload
      ].sort((planA, planB) => planSorting(planA, planB, activePlanId))
    }

    case 'UPDATE_PLAN': {
      return [...state.map(plan => {
        if (
          (typeof plan.planId === 'string' && plan.planId === 'NEW_' + action.payload.originPlanId) ||
          plan.planId === action.payload.planId
        ) {
          return { ...plan, ...action.payload }
        }
        return plan
      })]
    }

    case 'UPDATE_GOAL': {
      return [...state.map(row => {
        if (row.planId === action.payload.planId) {
          return {
            ...row,
            goals: row.goals.map(goal =>
              goal.goalId === action.payload.goalId
                ? { ...goal, ...action.payload }
                : goal
            )
          }
        }
        return row
      })]
    }

    case 'ADD_GOAL': {
      return [...state.map(row => {
        if (row.planId === action.payload.planId) {
          return {
            ...row,
            goals: [...row.goals, action.payload]
          }
        }
        return row
      })]
    }

    case 'DELETE_GOAL': {
      return [...state.map(row => {
        if (row.planId === action.payload.planId) {
          return {
            ...row,
            goals: row.goals.filter(goal => goal.goalId !== action.payload.goalId)
          }
        }
        return row
      })]
    }

    default:
      return state
  }
}
