import React from 'react'
import { makeStyles } from '@material-ui/core'
import { useMilestonesContext } from '../MilestonesContext'
import Select from '../../../molecules/Select'

const useStyles = makeStyles(() => ({
  select: {
    '& p': {
      fontSize: '1.5rem !important',
      fontWeight: 'bold'
    }
  }
}))

const DateRangeSelector = () => {
  const classes = useStyles()
  const { setDateRange, selectedDateRange, dateRangeOptions } = useMilestonesContext()

  return (
    <Select
      options={dateRangeOptions}
      value={selectedDateRange}
      className={classes.select}
      onChange={setDateRange}
      showCheckMarOnSelectedItems
    />
  )
}

export default DateRangeSelector
