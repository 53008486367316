import React, { useRef } from 'react'
import { IconButton } from '@material-ui/core'
import SydLabel from '../../../../../commonDesign/SydLabel'
import SydInput from '../../../../../commonDesign/SydInput'
import Icon from '../../../../../atoms/Icon'
import { useAccountFormContext } from '../../AccountFormProvider'
import { useCheckPolicy } from '../../../../../../hooks'
import { ACCOUNTS } from '../../../../../../policies/admin'
import DisplayNameDialog from './DisplayNameDialog'

function DisplayNameInput () {
  const { account } = useAccountFormContext()
  const dialogRef = useRef()
  const canEditDisplayName = useCheckPolicy(ACCOUNTS.editDisplayName)

  return (
    <>
      <SydLabel label='Display Name'>
        <div className='__control-group'>
          <SydInput disabled value={account.displayName} />
          {canEditDisplayName ? (
            <div>
              <IconButton onClick={e => dialogRef.current.open(e)}>
                <Icon customSize={20} name='edit' />
              </IconButton>
            </div>
          ) : null}
        </div>
      </SydLabel>
      <DisplayNameDialog ref={dialogRef} />
    </>
  )
}

DisplayNameInput.propTypes = {
}

export default DisplayNameInput
