import React, {
  forwardRef,
  useCallback,
  useEffect, useImperativeHandle,
  useMemo,
  useRef,
  useState
} from 'react'
import PropTypes from 'prop-types'
import { Button, makeStyles } from '@material-ui/core'
import OperationalTable from '../../../../organisms/OperationalTable'
import { useFormattingContext } from '../../../../organisms/FormattingProvider/FormattingContext'
import Icon from '../../../../atoms/Icon'
import DebouncedInput from '../../../../molecules/DebouncedInput'
import { usePolicy } from '../../../../../hooks/usePolicy'
import { useListAssignedClientPeople } from '../../../../../api/clients'
import { useSearchAllPeopleMultiple, useSearchAssignedPeopleMultiple } from '../../../../../api/people'
import ExperienceCell from './ExperienceCell'
import UserCell from './UserCell'
import { useClientPersonsActions, ClientPersonsTableContext } from './ClientPersonsTableContext'

const EditActionCell = ({ value }) => {
  const { added, removed, onAdd, onRemove, assignedPeople } = useClientPersonsActions()
  const action = useMemo(() => {
    const assigned = assignedPeople.some(x => x.personId === value)
    if (assigned && removed.includes(value)) {
      return (
        <Button
          className='__action-button __removed'
          variant='outlined'
          startIcon={<Icon name='checkCircle' />}
          onClick={() => onRemove(value)}
        >
          Removed
        </Button>
      )
    }
    if (assigned) {
      return (
        <Button
          className='__action-button'
          variant='outlined'
          startIcon={<Icon name='removeCircle' />}
          onClick={() => onRemove(value)}
        >
          Remove
        </Button>
      )
    }
    if (added.includes(value)) {
      return (
        <Button
          className='__action-button __added'
          variant='outlined'
          startIcon={<Icon name='checkCircle' />}
          onClick={() => onAdd(value)}
        >
          Added
        </Button>
      )
    }
    return (
      <Button
        className='__action-button'
        variant='outlined'
        startIcon={<Icon name='addCircle' />}
        onClick={() => onAdd(value)}
      >
        Add
      </Button>
    )
  }, [added, removed, onAdd, onRemove, assignedPeople, value])
  return (
    <div>
      {action}
    </div>
  )
}

EditActionCell.propTypes = {
  value: PropTypes.any
}

const defaultColumnConfig = {
  columns: [
    {
      id: 'firstName',
      accessor: 'firstName',
      Header: 'First Name',
      width: 200
    },
    {
      id: 'lastName',
      accessor: 'lastName',
      Header: 'Last Name',
      width: 200
    },
    {
      id: 'personType',
      accessor: 'personType.shortName',
      Header: 'Client Role',
      width: 200
    },
    {
      id: 'primaryPhone',
      accessor: 'primaryPhoneNumber',
      Header: 'Primary Phone',
      width: 200
    },
    {
      id: 'userEmail',
      accessor: 'user.email',
      Header: 'User Login',
      Cell: UserCell,
      width: 200
    },
    {
      id: 'experienceName',
      accessor: 'experience.name',
      Header: 'Experience',
      Cell: ExperienceCell,
      width: 200
    }
  ],
  defaultSort: [{ id: 'firstName', desc: false }]
}

const editingColumns = [{
  id: 'actions',
  accessor: 'personId',
  Header: 'Actions',
  width: 200,
  Cell: EditActionCell
}]

export const useColumns = ({ editing }) => {
  const { formatter } = useFormattingContext()

  return useMemo(() => {
    const { columns, defaultSort } = defaultColumnConfig
    const allColumns = editing ? [...columns, ...editingColumns] : columns
    const templatedColumns = allColumns.map((column) => {
      if (column?.Cell) return column

      return {
        ...column,
        editing,
        Cell: ({ value }) => formatter(value, column.format)
      }
    })

    return {
      columns: templatedColumns,
      defaultSort
    }
  }, [formatter, editing])
}

/**
 * People who can edit use one type of search that can search all people
 * People who cannot edit have to use another that can only search assigned people
 * @param query
 * @return {{isLoading: false | true, data: *, error: unknown}|{isLoading: false | true, data: *, error: unknown}}
 */
const usePersonSearch = (query) => {
  const canEdit = usePolicy('admin_edit_client_persons')
  const { data: searchAllResults, isFetching: searchAllResultsLoading, error: allError } = useSearchAllPeopleMultiple(query, { enabled: canEdit })
  const { data: searchAssignedResults, isFetching: searchAssignedResultsLoading, error: assignedError } = useSearchAssignedPeopleMultiple(query, { enabled: !canEdit })

  return useMemo(() => {
    if (canEdit) {
      return {
        data: searchAllResults,
        isLoading: searchAllResultsLoading,
        error: allError
      }
    }
    return {
      data: searchAssignedResults,
      isLoading: searchAssignedResultsLoading,
      error: assignedError
    }
  }, [canEdit, searchAllResults, searchAssignedResults, searchAllResultsLoading, searchAssignedResultsLoading, allError, assignedError])
}

const useClientPersons = (client, editing) => {
  const [search, setSearch] = useState('')
  const { data: assigned, isLoading: assignedLoading } = useListAssignedClientPeople(client.clientId)

  const query = useMemo(() => {
    const searchValue = search || undefined
    const paramsFilter = (searchValue && editing) ? undefined : {
      assignedToClientIds: [client.clientId]
    }
    return {
      assignedPeople: {
        includes: {
          users: true,
          experiences: true,
          personTypes: true
        },
        filters: paramsFilter,
        textSearch: {
          firstName: searchValue ? [{ op: 'contains', value: searchValue }] : undefined,
          lastName: searchValue ? [{ op: 'contains', value: searchValue }] : undefined
        }
      },
      searchTotal: {
        resultType: 'total',
        filters: paramsFilter
      }
    }
  }, [client.clientId, search, editing])

  const { data: searchResults, isLoading: searchResultsLoading, error } = usePersonSearch(query, editing)

  const { people, total } = useMemo(() => {
    if (searchResultsLoading) {
      return {
        people: [],
        total: null
      }
    }
    return {
      people: searchResults.assignedPeople.data,
      total: searchResults.searchTotal.total
    }
  }, [searchResults, searchResultsLoading])

  const [added, setAdded] = useState([])
  const [removed, setRemoved] = useState([])
  const onAdd = useCallback((personId) => {
    setAdded(p => p.includes(personId) ? p.filter(x => x !== personId) : [...p, personId])
  }, [setAdded])
  const onRemove = useCallback((personId) => {
    setRemoved(p => p.includes(personId) ? p.filter(x => x !== personId) : [...p, personId])
  }, [setRemoved])

  return {
    people,
    total,
    isLoading: searchResultsLoading || assignedLoading,
    error,
    setSearch,
    added,
    removed,
    onAdd,
    onRemove,
    assignedPeople: assigned?.peopleAssignments
  }
}

const useStyles = makeStyles((theme) => ({
  clientPeopleTable: {
    marginTop: '10px',

    '& .__action-button': {
      textTransform: 'none',
      borderRadius: '1rem'
    },
    '& .__removed': {
      color: theme.palette.danger.main
    },
    '& .__added': {
      color: theme.palette.success.main
    },
    '& .__people-cell': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      minHeight: '36px'
    }
  },
  search: {
    maxWidth: '300px'
  }
}))

const ClientPeopleTable = forwardRef(function ({
  client,
  editing,
  onCreateUser,
  onAssignRole
}, ref) {
  const {
    people, // the search results
    total, // the total number of people assigned
    isLoading, // is any query loading?
    setSearch, // search account text
    added,
    removed,
    onAdd,
    onRemove,
    assignedPeople // the assigned people list
  } = useClientPersons(client, editing)
  const columnConfig = useColumns({ editing })
  const searchRef = useRef()
  useEffect(() => {
    searchRef.current?.clear()
  }, [editing, searchRef])
  const onChange = useCallback((newValue) => {
    setSearch(newValue)
  }, [setSearch])
  const classes = useStyles()
  const providerValue = useMemo(() => {
    return { added, removed, onAdd, onRemove, assignedPeople, editing, onCreateUser, onAssignRole }
  }, [added, removed, onAdd, onRemove, assignedPeople, editing, onCreateUser, onAssignRole])

  useImperativeHandle(ref, () => {
    return {
      added,
      removed
    }
  }, [added, removed])

  return (
    <ClientPersonsTableContext.Provider value={providerValue}>
      <OperationalTable.Wrapper className={classes.clientPeopleTable}>
        <DebouncedInput
          className={classes.search}
          ref={searchRef}
          onChange={onChange}
          placeholder={editing ? 'Search for People to Add' : 'Search Assigned People'}
        />
        <OperationalTable
          loading={isLoading}
          mode='client'
          columns={columnConfig.columns || []}
          defaultSort={columnConfig.defaultSort || []}
          data={people}
          total={total}
          itemName='People'
          hideFooter
        />
      </OperationalTable.Wrapper>
    </ClientPersonsTableContext.Provider>
  )
})

ClientPeopleTable.propTypes = {
  client: PropTypes.shape({
    clientId: PropTypes.number
  }),
  editing: PropTypes.bool,
  onCreateUser: PropTypes.func,
  onAssignRole: PropTypes.func
}

ClientPeopleTable.defaultProps = {
  editing: false
}

export default ClientPeopleTable
