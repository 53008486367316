import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import WealthJourneyV1 from './WealthJourney/v1'
import WealthJourneyV2 from './WealthJourney/v2'

const NewWealthJourneyHome = ({ version, ...rest }) => {
  const wjComponent = useMemo(() => {
    switch (version) {
      case 'v2':
        return <WealthJourneyV2 {...rest} />
      default:
        return <WealthJourneyV1 {...rest} />
    }
  }, [version, rest])

  return wjComponent
}

NewWealthJourneyHome.propTypes = {
  lineChartDataIdentifier: PropTypes.string.isRequired,
  version: PropTypes.string
}

NewWealthJourneyHome.defaultProps = {
  lineChartDataIdentifier: 'endingValue',
  version: 'v1'
}

export default NewWealthJourneyHome
