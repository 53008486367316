import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  makeStyles
} from '@material-ui/core'
import { isEmpty, noop } from 'lodash'
import SaveCancelButtons from '../../../../molecules/SaveCancelButtons'
import { ICON_NAMES } from '../../../../../constants'
import Text from '../../../../atoms/Text'
import Icon from '../../../../atoms/Icon'

const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    padding: '1.5rem 1.5rem 1rem 1.5rem'
  },
  title: {
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(24),
    lineHeight: theme.typography.pxToRem(29)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(2),
    color: theme.primary
  },
  dialogActions: {
    justifyContent: 'center'
  },
  buttonsGroup: {
    padding: '1rem !important'
  }
}))

const CustomizeColumnsDialog = ({
  children,
  title,
  isOpen,
  toggler,
  maxWidth,
  onConfirm,
  isLoading,
  actions,
  primaryButtonLabel,
  showContentDivider,
  secondaryButtonLabel,
  onCancel: _onCancel
}) => {
  const classes = useStyles()

  const onCancel = useCallback(() => {
    _onCancel()
    toggler?.off()
  }, [_onCancel, toggler])

  return (
    <Dialog
      open={isOpen}
      onClose={toggler.off}
      maxWidth={maxWidth || (showContentDivider ? 'md' : 'xs')}
      fullWidth
    >
      <DialogTitle className={classes.dialogTitle}>
        <IconButton
          aria-label='close'
          className={classes.closeButton}
          onClick={toggler.off}
        >
          <Icon name={ICON_NAMES.close} customSize='1.25rem' />
        </IconButton>
        <Text text={title} className={classes.title} />
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
      {!isEmpty(actions) ? actions : (
        <DialogActions className={classes.dialogActions}>
          <SaveCancelButtons
            containerClassName={classes.buttonsGroup}
            onCancel={onCancel}
            onSave={onConfirm}
            isSubmitting={isLoading}
            primaryButtonLabel={primaryButtonLabel}
            secondaryButtonLabel={secondaryButtonLabel}
            // TODO: disabled until we decide how export should behave
            // disabledPrimaryButton
          />
        </DialogActions>
      )}
    </Dialog>
  )
}

CustomizeColumnsDialog.propTypes = {
  children: PropTypes.node.isRequired,
  showContentDivider: PropTypes.bool,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
  title: PropTypes.string,
  maxWidth: PropTypes.string,
  primaryButtonLabel: PropTypes.string,
  secondaryButtonLabel: PropTypes.string,
  isOpen: PropTypes.bool,
  toggler: PropTypes.object,
  isLoading: PropTypes.bool,
  actions: PropTypes.node
}

CustomizeColumnsDialog.defaultProps = {
  title: 'Customize Columns',
  maxWidth: undefined,
  primaryButtonLabel: 'Apply',
  secondaryButtonLabel: 'Cancel',
  isOpen: false,
  toggler: {},
  onCancel: noop,
  isLoading: false
}

export default CustomizeColumnsDialog
