import React from 'react'
import PropTypes from 'prop-types'
import { Box, makeStyles } from '@material-ui/core'
import noop from 'lodash/noop'
import clsx from 'clsx'
import Text from './Text'

const useStyles = makeStyles(() => ({
  container: ({ extraStyles }) => ({
    flex: '1',
    height: '100%',
    width: '100%',
    display: 'flex',
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    background: 'white',
    borderRadius: '0.25rem',
    padding: '1rem',
    ...extraStyles
  }),
  title: ({ titleStyles }) => titleStyles
}))

function EmptySection ({
  styles,
  title,
  header,
  titleStyles,
  description,
  onClick,
  emptyDescription,
  containerClassName
}) {
  const classes = useStyles({
    extraStyles: styles,
    titleStyles
  })

  return (
    <div
      className={clsx(classes.container, {
        [containerClassName]: !!containerClassName
      })}
      onClick={onClick}
    >
      {header}
      <Text
        text={title}
        customFontSize='1rem'
        customFontWeight='600'
        className={classes.title}
      />
      {description && !emptyDescription && (
        <Box mt={1}>
          <Text text={description} customFontSize='0.875rem' />
        </Box>
      )}
    </div>
  )
}

EmptySection.propTypes = {
  header: PropTypes.node,
  styles: PropTypes.object,
  title: PropTypes.string,
  emptyDescription: PropTypes.bool,
  description: PropTypes.string,
  titleStyles: PropTypes.object,
  onClick: PropTypes.func,
  containerClassName: PropTypes.string
}

EmptySection.defaultProps = {
  header: undefined,
  styles: {},
  title: '',
  titleStyles: {},
  onClick: noop,
  emptyDescription: false,
  description: 'Data is loading...',
  containerClassName: ''
}

export default EmptySection
