import React from 'react'
import { Box } from '@material-ui/core'
import { useDispatch } from 'react-redux'
import clsx from 'clsx'
import { setRouteSelection, useAddAccountContext } from '../../../redux/slices/addAccountContext'
import Text from '../../atoms/Text'
import useStyles from './styles'

const RouteSelection = () => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const addAccountContext = useAddAccountContext()

  return (
    <Box display='flex' gridGap='16px'>
      {addAccountContext.routes?.map((route) => (
        <Box flex={1} key={route.key}>
          <button
            className={clsx(classes.squaredButton, classes.routeSelection)}
            onClick={() => dispatch(setRouteSelection({ routeSelection: route.key }))}
          >
            <Text variant='h3'>{route.label}</Text>
            <Text variant='body2'><span style={{ whiteSpace: 'pre-wrap' }}>{route.description}</span></Text>
          </button>
        </Box>
      ))}
    </Box>
  )
}
export default RouteSelection
