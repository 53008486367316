import { useCallback, useEffect, useState } from 'react'
import useWindowResize from './useWindowResize'

const useHorizontalOverflow = (enabled = true) => {
  const [containerRef, setContainerRef] = useState()
  const windowSize = useWindowResize()
  const [hasHorizontalOverflow, setHasHorizontalOverflow] = useState(false)

  useEffect(() => {
    if (containerRef) {
      const node = containerRef
      const hasHorizontalOverflow = node && node.clientWidth < node.scrollWidth
      setHasHorizontalOverflow(enabled && hasHorizontalOverflow)
    }
  }, [containerRef, enabled, windowSize.width])

  const containerRefCallback = useCallback((node) => {
    if (node !== null) {
      setContainerRef(node)
    }
  }, [])

  return { hasHorizontalOverflow, containerRefCallback }
}

export default useHorizontalOverflow
