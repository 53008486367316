import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import { capitalize } from 'lodash'
import Text from '../../../atoms/Text'
import Avatar from '../../../atoms/Avatar'
import Tooltip from '../../../atoms/Tooltip'

const useStyles = makeStyles(() => ({
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    gap: '0.625rem'
  },
  details: {
    display: 'flex',
    flexDirection: 'column'
  },
  label: {
    width: '9rem',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }
}))

const PersonNode = ({ person }) => {
  const classes = useStyles()
  const fullName = `${person?.firstName}${person?.lastName ? ` ${person?.lastName}` : ''}`
  return (
    <div className={classes.container}>
      <Avatar src={person?.profilePic} />
      <div className={classes.details}>
        <Tooltip title={fullName}>
          <span>
            <Text
              className={classes.label}
              text={fullName}
              customFontWeight='bold'
            />
          </span>
        </Tooltip>
        <Text text={capitalize(person?.personType?.longName)} />
      </div>
    </div>
  )
}

PersonNode.propTypes = {
  person: PropTypes.shape({
    profilePic: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    personType: PropTypes.shape({
      longName: PropTypes.string
    })
  })
}

export default PersonNode
