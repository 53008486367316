import React from 'react'
import dayjs from 'dayjs'
import { isNumber, isEmpty, has, last, isArray } from 'lodash'
import numeral from 'numeral'
import {
  ASSET_FILTERS,
  DAYJS_OPERATIONS,
  DAYJS_UNIT_TYPES,
  TABLE_CELL_FORMATS,
  CSV_CELL_TYPES,
  LEVEL_TYPE_BY_ASSET_FILTER,
  TEXT_ALIGNMENTS,
  TEXT_VARIANTS
} from '../constants'
import { getToolTip, numeralByCase, truncate, tableNumberFormatter, insertAtIndex, getSafeDate, compose, isNumeric } from '../utils'

export const appendHeldEntirePeriodTitle = (
  prefixTitle,
  heldEntirePeriod
) => {
  if (!isNumber(heldEntirePeriod)) return prefixTitle

  const notHeldEntirePeriod = heldEntirePeriod === 0

  const heldEntirePeriodTitle = notHeldEntirePeriod
    ? 'Not held entire period'
    : ''

  return `${prefixTitle}\n${heldEntirePeriodTitle}`
}

const getAssetReturnTitles = (...assets) => {
  const assetReturnTitlesFormatted = assets.reduce(
    (acc, asset, index) => {
      const {
        balanceChangePercentage: assetReturn
      } = asset || {}

      return {
        ...acc,
        [index]: format(assetReturn, TABLE_CELL_FORMATS.PERCENTAGE.LONG)
      }
    },
    {}
  )
  return Object.values(assetReturnTitlesFormatted)
}

const getAssetReturnValues = (rowConfig, ...assets) => {
  const { showDashesWhenNotHeldEntirePeriod } = rowConfig

  const assetReturnsFormatted = assets.reduce((acc, asset, index) => {
    const {
      heldEntirePeriod,
      balanceChangePercentage: assetReturn = 0
    } = asset || {}

    if (
      heldEntirePeriod !== undefined &&
      isNumber(heldEntirePeriod) &&
      heldEntirePeriod === 0 &&
      showDashesWhenNotHeldEntirePeriod) {
      return {
        ...acc,
        [index]: '-'
      }
    }

    return {
      ...acc,
      [index]: assetReturn
    }
  }, {})

  return Object.values(assetReturnsFormatted)
}

const format = (num = 0, formatWithDecimals, formatWithoutDecimals) => {
  const fmt = num % 1 !== 0 ? formatWithDecimals : formatWithoutDecimals
  return numeral(num).format(fmt)
}

const getAssetPercentage = (
  { balance, balanceChangePercentage },
  total,
  showOnlyPercentages
) => {
  if (showOnlyPercentages) {
    return balanceChangePercentage
  }
  if (isNumber(total) && total > 0) {
    return balance / total
  }
  return 0
}

const numberFormattedCell = (
  value,
  rawValue,
  format = TABLE_CELL_FORMATS,
  alignment = TEXT_ALIGNMENTS.center,
  isPercentage = false
) => {
  const formatType = isPercentage ? format.PERCENTAGE : format.NUMBER

  return {
    value: numeralByCase(value, formatType.SHORT, formatType.LONG),
    rawValue: rawValue || value,
    alignment
  }
}

const formatCellNumber = (num, formatter = null, displayWhenZero = false) => {
  if (!isNumber(num)) return '-'
  if (!displayWhenZero && num === 0) return '-'
  return formatter ? formatter(num) : num
}

const orderAssetsBy = (assets) => {
  return assets.sort(
    (
      { name: assetsNameA, names: assetNamesA },
      { name: assetsNameB, names: assetNamesB }
    ) => {
      if (assetsNameA && assetsNameB) {
        return assetsNameA.localeCompare(assetsNameB)
      }
      const { assetName: assetNameA, assetClassTagName: assetClassTagNameA } =
        assetNamesA
      const { assetName: assetNameB, assetClassTagName: assetClassTagNameB } =
        assetNamesB

      if (assetClassTagNameA && assetClassTagNameB) {
        return assetClassTagNameA.localeCompare(assetClassTagNameB)
      }
      if (assetNameA && assetNameB) {
        return assetNameA.localeCompare(assetNameB)
      }
      return -1
    }
  )
}

const getSummarySubclassesColumns = ({
  rowId,
  asset,
  assetName,
  rowConfig,
  classType
}) => [
  { width: '4%' },
  {
    width: '26%',
    alignment: TEXT_ALIGNMENTS.left,
    variant: TEXT_VARIANTS.subtitle2,
    value: assetName,
    csvData: { type: CSV_CELL_TYPES.string }
  },
  { width: '70%' },
  {
    hidden: true,
    value: rowId,
    text: assetName,
    assetIds: {
      assetClassTagId: asset.ids?.assetClassTagId || null,
      subclassTagId: asset.ids?.subclassTagId || null
    },
    showTooltip: false,
    hideChevron: true,
    ...rowConfig,
    classType
  }
]

const getSummaryColumns = ({
  rowId,
  asset,
  assetPercentage,
  assetName,
  rowConfig
}) => [
  {
    width: '8%',
    alignment: TEXT_ALIGNMENTS.center,
    value: formatCellNumber(assetPercentage, (num) =>
      numeralByCase(
        num,
        TABLE_CELL_FORMATS.PERCENTAGE.SHORT,
        TABLE_CELL_FORMATS.PERCENTAGE.SHORT
      )
    ),
    hideIfExpanded: true,
    csvData: {
      type: CSV_CELL_TYPES.percentage,
      originalValue: assetPercentage
    }
  },
  {
    alignment: TEXT_ALIGNMENTS.left,
    variant: TEXT_VARIANTS.subtitle2,
    value: assetName,
    width: '20%',
    csvData: {
      type: CSV_CELL_TYPES.string
    }
  },
  {
    width: '8%',
    value: numeral(asset.units).format(TABLE_CELL_FORMATS.NUMBER.COMMAS_INTEGER),
    hideIfExpanded: true,
    csvData: {
      type: CSV_CELL_TYPES.number,
      originalValue: asset.units
    }
  },
  {
    width: '8%',
    value: numeral(asset.balance).format(TABLE_CELL_FORMATS.NUMBER.MID),
    hideIfExpanded: true,
    alignment: TEXT_ALIGNMENTS.center,
    csvData: {
      type: CSV_CELL_TYPES.number,
      originalValue: asset.balance
    }
  },
  {
    width: '8%',
    value: isNumber(asset.units) && asset.units !== 0 ? numeral(asset.balance / asset.units).format(TABLE_CELL_FORMATS.NUMBER.MID) : 0,
    hideIfExpanded: true,
    csvData: {
      type: CSV_CELL_TYPES.number,
      originalValue: isNumber(asset.units) && asset.units !== 0 ? asset.balance / asset.units : 0
    }
  },
  {
    width: '8%',
    value: isNumber(asset?.costBasis) && asset?.costBasis !== 0 ? numeral(asset?.costBasis).format(TABLE_CELL_FORMATS.NUMBER.MID) : 0,
    hideIfExpanded: true,
    csvData: {
      type: CSV_CELL_TYPES.number,
      originalValue: isNumber(asset?.costBasis) && asset?.costBasis !== 0 ? asset?.costBasis : 0
    }
  },
  {
    width: '8%',
    value: isNumber(asset?.unitCost) && asset?.unitCost !== 0 ? numeral(asset?.unitCost).format(TABLE_CELL_FORMATS.NUMBER.MID) : 0,
    hideIfExpanded: true,
    csvData: {
      type: CSV_CELL_TYPES.number,
      originalValue: isNumber(asset?.unitCost) && asset?.unitCost !== 0 ? asset?.unitCost : 0
    }
  },
  {
    width: '8%',
    value: isNumber(asset?.unrealizedGain) && asset?.unrealizedGain !== 0 ? numeral(asset?.unrealizedGain).format(TABLE_CELL_FORMATS.NUMBER.MID) : 0,
    hideIfExpanded: true,
    csvData: {
      type: CSV_CELL_TYPES.number,
      originalValue: isNumber(asset?.unrealizedGain) && asset?.unrealizedGain !== 0 ? asset?.unrealizedGain : 0
    }
  },
  {
    width: '8%',
    value: isNumber(asset?.marketData?.annualIncome) && asset?.marketData?.annualIncome !== 0 ? numeral(asset?.marketData?.annualIncome).format(TABLE_CELL_FORMATS.NUMBER.MID) : 0,
    hideIfExpanded: true,
    csvData: {
      type: CSV_CELL_TYPES.number,
      originalValue: isNumber(asset?.marketData?.annualIncome) && asset?.marketData?.annualIncome !== 0 ? asset?.marketData?.annualIncome : 0
    }
  },
  {
    width: '8%',
    value: formatCellNumber(asset?.marketData?.dividendYield, (num) =>
      numeralByCase(
        num,
        TABLE_CELL_FORMATS.PERCENTAGE.SHORT,
        TABLE_CELL_FORMATS.PERCENTAGE.SHORT
      )
    ),
    hideIfExpanded: true,
    csvData: {
      type: CSV_CELL_TYPES.percentage,
      originalValue: asset?.marketData?.dividendYield || 0
    }
  },
  {
    hidden: true,
    value: rowId,
    text: assetName,
    assetIds: {
      assetClassTagId: asset.ids?.assetClassTagId || null,
      subclassTagId: asset.ids?.subclassTagId || null
    },
    showTooltip: true,
    hideChevron: true,
    tooltips: [
      getToolTip(assetPercentage, TABLE_CELL_FORMATS.PERCENTAGE),
      '',
      { title: asset.units },
      { title: asset.balance },
      { title: asset.balance / asset.units },
      { title: asset?.costBasis },
      { title: asset?.unitCost },
      { title: asset?.unrealizedGain },
      { title: asset?.marketData?.annualIncome },
      getToolTip(
        asset?.marketData?.dividendYield,
        TABLE_CELL_FORMATS.PERCENTAGE
      )
    ],
    ...rowConfig
  }
]

const getTimeFramesColumns = ({
  rowId,
  asset,
  assetPercentage,
  secondColumn,
  thirdColumn,
  rowConfig,
  balanceInformationData
}) => {
  const [
    balanceInformation4Column,
    balanceInformation5Column,
    balanceInformation6Column,
    balanceInformation7Column,
    balanceInformation8Column
  ] = Object.values(balanceInformationData).map(
    (balanceInformationData) =>
      balanceInformationData.find(
        ({ ids }) =>
          ids?.assetClassTagId === rowId || ids?.subclassTagId === rowId
      ),
    []
  )
  const name = asset.names.assetClassTagName || asset.name || asset.names.assetName

  const assetReturnValues = getAssetReturnValues(
    rowConfig,
    asset,
    secondColumn,
    thirdColumn,
    balanceInformation4Column,
    balanceInformation5Column,
    balanceInformation6Column,
    balanceInformation7Column,
    balanceInformation8Column
  )

  const assetReturnTitles = getAssetReturnTitles(
    asset,
    secondColumn,
    thirdColumn,
    balanceInformation4Column,
    balanceInformation5Column,
    balanceInformation6Column,
    balanceInformation7Column,
    balanceInformation8Column
  )

  return [
    {
      alignment: TEXT_ALIGNMENTS.left,
      variant: TEXT_VARIANTS.subtitle2,
      value: name,
      width: '20%',
      csvData: { type: CSV_CELL_TYPES.string }
    },
    {
      width: '10%',
      value: assetReturnValues[0],
      payload: { ...asset, isValueReturn: true },
      csvData: {
        type: CSV_CELL_TYPES.percentage,
        originalValue: assetPercentage
      }
    },
    {
      width: '10%',
      value: assetReturnValues[1],
      payload: { ...secondColumn, isValueReturn: true },
      csvData: {
        type: CSV_CELL_TYPES.percentage,
        originalValue: secondColumn?.balanceChangePercentage
      }
    },
    {
      width: '10%',
      value: assetReturnValues[2],
      payload: { ...thirdColumn, isValueReturn: true },
      csvData: {
        type: CSV_CELL_TYPES.percentage,
        originalValue: thirdColumn?.balanceChangePercentage
      }
    },
    {
      width: '10%',
      value: assetReturnValues[3],
      payload: { ...balanceInformation4Column, isValueReturn: true },
      csvData: {
        type: CSV_CELL_TYPES.percentage,
        originalValue: balanceInformation4Column?.balanceChangePercentage
      }
    },
    {
      width: '10%',
      value: assetReturnValues[4],
      payload: { ...balanceInformation5Column, isValueReturn: true },
      csvData: {
        type: CSV_CELL_TYPES.percentage,
        originalValue: balanceInformation5Column?.balanceChangePercentage
      }
    },
    {
      width: '10%',
      value: assetReturnValues[5],
      payload: { ...balanceInformation6Column, isValueReturn: true },
      csvData: {
        type: CSV_CELL_TYPES.percentage,
        originalValue: balanceInformation6Column?.balanceChangePercentage
      }
    },
    {
      width: '10%',
      value: assetReturnValues[6],
      payload: { ...balanceInformation7Column, isValueReturn: true },
      csvData: {
        type: CSV_CELL_TYPES.percentage,
        originalValue: balanceInformation7Column?.balanceChangePercentage
      }
    },
    {
      width: '10%',
      value: assetReturnValues[7],
      payload: { ...balanceInformation8Column, isValueReturn: true },
      csvData: {
        type: CSV_CELL_TYPES.percentage,
        originalValue: balanceInformation8Column?.balanceChangePercentage
      }
    },
    {
      hidden: true,
      value: rowId,
      text: name,
      type: !asset.assetId ? 'assetClasses' : 'assets',
      showTooltip: true,
      tooltips: [
        '',
        {
          title: assetReturnTitles[0],
          format: TABLE_CELL_FORMATS.PERCENTAGE.LONG
        },
        {
          title: assetReturnTitles[1],
          format: TABLE_CELL_FORMATS.PERCENTAGE.LONG
        },
        {
          title: assetReturnTitles[2],
          format: TABLE_CELL_FORMATS.PERCENTAGE.LONG
        },
        {
          title: assetReturnTitles[3],
          format: TABLE_CELL_FORMATS.PERCENTAGE.LONG
        },
        {
          title: assetReturnTitles[4],
          format: TABLE_CELL_FORMATS.PERCENTAGE.LONG
        },
        {
          title: assetReturnTitles[5],
          format: TABLE_CELL_FORMATS.PERCENTAGE.LONG
        },
        {
          title: assetReturnTitles[6],
          format: TABLE_CELL_FORMATS.PERCENTAGE.LONG
        },
        {
          title: assetReturnTitles[7],
          format: TABLE_CELL_FORMATS.PERCENTAGE.LONG
        }
      ],
      ...rowConfig
    }
  ]
}

const getColumns = ({
  rowId,
  asset,
  assetPercentage,
  assetName,
  secondColumn,
  thirdColumn,
  rowConfig
}) => {
  const {
    annualizedReturn,
    balanceChangePercentage: lastPeriodReturn
  } = thirdColumn || {}

  const annualizedReturnTitle = `Annualized: ${format(
    annualizedReturn,
    TABLE_CELL_FORMATS.PERCENTAGE.LONG
  )}`

  const cumulativeReturnTitle = `Cumulative: ${format(
    lastPeriodReturn,
    TABLE_CELL_FORMATS.PERCENTAGE.LONG
  )}`

  const lastPeriodReturnTitle =
    thirdColumn?.annualizedReturn > 0
      ? annualizedReturnTitle
      : cumulativeReturnTitle

  const assetReturnValues = getAssetReturnValues(
    rowConfig,
    secondColumn,
    thirdColumn
  )
  const assetReturnTitles = getAssetReturnTitles(secondColumn)

  return [
    {
      alignment: TEXT_ALIGNMENTS.left,
      variant: TEXT_VARIANTS.subtitle2,
      value: assetName,
      width: '28%',
      csvData: { type: CSV_CELL_TYPES.string }
    },
    {
      width: '12%',
      value: asset.balance || 0,
      csvData: { type: CSV_CELL_TYPES.number }
    },
    {
      width: '12%',
      value: assetPercentage || 0,
      csvData: { type: CSV_CELL_TYPES.percentage }
    },
    {
      width: '12%',
      value: secondColumn?.balanceChange || 0,
      csvData: { type: CSV_CELL_TYPES.number }
    },
    {
      width: '12%',
      value: assetReturnValues[0],
      payload: { ...secondColumn, isValueReturn: true },
      csvData: { type: CSV_CELL_TYPES.percentage }
    },
    {
      width: '12%',
      value: thirdColumn?.balanceChange || 0,
      csvData: { type: CSV_CELL_TYPES.number }
    },
    {
      width: '12%',
      value: assetReturnValues[1],
      payload: { ...thirdColumn, isValueReturn: true },
      csvData: { type: CSV_CELL_TYPES.percentage }
    },
    {
      hidden: true,
      value: rowId,
      text: assetName,
      type: !asset.assetId ? 'assetClasses' : 'assets',
      assetIds: {
        assetClassTagId: asset.ids?.assetClassTagId || null,
        subclassTagId: asset.ids?.subclassTagId || null
      },
      showTooltip: true,
      tooltips: [
        '',
        {
          title: numeralByCase(asset.balance || 0),
          skipFormat: true
        },
        getToolTip(assetPercentage, TABLE_CELL_FORMATS.PERCENTAGE),
        {
          title: numeralByCase(secondColumn?.balanceChange || 0),
          skipFormat: true
        },
        {
          title: assetReturnTitles[0],
          format: TABLE_CELL_FORMATS.PERCENTAGE.LONG
        },
        {
          title: numeralByCase(thirdColumn?.balanceChange || 0),
          skipFormat: true
        },
        {
          title: lastPeriodReturnTitle,
          format: TABLE_CELL_FORMATS.PERCENTAGE.LONG
        }
      ],
      ...rowConfig
    }
  ]
}

const getSummaryFinalRow = ({
  totalWeight,
  totalBalance,
  totalCostBasis,
  totalAnnualIncome,
  totalUNrealizedGain
}) => [
  { value: totalWeight, alignment: TEXT_ALIGNMENTS.center, csvData: { type: CSV_CELL_TYPES.number } },
  { value: '', csvData: { type: CSV_CELL_TYPES.string } },
  { value: '', csvData: { type: CSV_CELL_TYPES.string } },
  {
    value: numeral(totalBalance).format(TABLE_CELL_FORMATS.NUMBER.MID),
    alignment: TEXT_ALIGNMENTS.center,
    csvData: {
      type: CSV_CELL_TYPES.number,
      originalValue: totalBalance
    }
  },
  { value: '', csvData: { type: CSV_CELL_TYPES.string } },
  {
    value: numeral(totalCostBasis).format(TABLE_CELL_FORMATS.NUMBER.MID),
    csvData: {
      type: CSV_CELL_TYPES.number,
      originalValue: totalCostBasis
    }
  },
  { value: '', csvData: { type: CSV_CELL_TYPES.string } },
  {
    value: numeral(totalUNrealizedGain).format(TABLE_CELL_FORMATS.NUMBER.MID),
    csvData: {
      type: CSV_CELL_TYPES.number,
      originalValue: totalUNrealizedGain
    }
  },
  {
    value: numeral(totalAnnualIncome).format(TABLE_CELL_FORMATS.NUMBER.MID),
    csvData: {
      type: CSV_CELL_TYPES.number,
      originalValue: totalAnnualIncome
    }
  },
  { value: '', csvData: { type: CSV_CELL_TYPES.string } },
  {
    hidden: true,
    showTooltip: true,
    tooltips: [
      {
        title: format(
          totalWeight,
          TABLE_CELL_FORMATS.PERCENTAGE.LONG,
          TABLE_CELL_FORMATS.PERCENTAGE.SHORT
        ),
        format: TABLE_CELL_FORMATS.PERCENTAGE.SHORT
      },
      '',
      '',
      { title: totalBalance },
      '',
      { title: totalCostBasis },
      '',
      { title: totalUNrealizedGain },
      { title: totalAnnualIncome },
      ''
    ]
  }
]

const mapAssetFinalRow = (data, classType) => {
  if (isEmpty(data)) return []
  const {
    balanceInformationToday = [],
    balanceInformation2Column = [],
    balanceInformation3Column = []
  } = data
  switch (classType) {
    case ASSET_FILTERS.ACCOUNTS: {
      return balanceInformationToday.map((datum, index) => {
        const { names } = datum

        const { benchmarkValue: benchmarkValueSecondCol } =
          balanceInformation2Column[index]

        const { benchmarkValue: benchmarkValueThirdCol } =
          balanceInformation3Column[index]

        const benchmarkName = names.benchmarkLongName || names.benchmarkShortName

        // ME-1681 - If no benchmark assigned or available, do not display the benchmark.
        if (!benchmarkName) return null

        const name = benchmarkName || 'N/A'
        return [
          {
            value: `Benchmark: ${name}`,
            colSpan: 1,
            alignment: TEXT_ALIGNMENTS.left,
            csvData: { type: CSV_CELL_TYPES.string }
          },
          { value: '', csvData: { type: CSV_CELL_TYPES.string } },
          { value: '', csvData: { type: CSV_CELL_TYPES.string } },
          { value: '', csvData: { type: CSV_CELL_TYPES.string } },
          {
            value: numeralByCase(
              benchmarkValueSecondCol || 0,
              TABLE_CELL_FORMATS.PERCENTAGE.SHORT,
              TABLE_CELL_FORMATS.PERCENTAGE.MID
            ),
            csvData: {
              type: CSV_CELL_TYPES.percentage,
              originalValue: benchmarkValueSecondCol || 0
            }
          },
          { value: '', csvData: { type: CSV_CELL_TYPES.string } },
          {
            value: numeralByCase(
              benchmarkValueThirdCol || 0,
              TABLE_CELL_FORMATS.PERCENTAGE.SHORT,
              TABLE_CELL_FORMATS.PERCENTAGE.MID
            ),
            csvData: {
              type: CSV_CELL_TYPES.percentage,
              originalValue: benchmarkValueThirdCol || 0
            }
          },
          {
            hidden: true,
            showTooltip: true,
            tooltips: [
              '',
              '',
              '',
              '',
              {
                title: numeralByCase(
                  benchmarkValueSecondCol || 0,
                  TABLE_CELL_FORMATS.PERCENTAGE.SHORT,
                  TABLE_CELL_FORMATS.PERCENTAGE.LONG
                ),
                skipFormat: true
              },
              '',
              {
                title: numeralByCase(
                  benchmarkValueThirdCol || 0,
                  TABLE_CELL_FORMATS.PERCENTAGE.SHORT,
                  TABLE_CELL_FORMATS.PERCENTAGE.LONG
                ),
                skipFormat: true
              }
            ]
          }
        ]
      }).filter(set => !!set)
    }
    default:
      return []
  }
}

const mapPerformanceTableRows =
  ({ data, totalBalance, showOnlyPercentages, rowConfig }) =>
    (idMapper, nameMapper) => {
      const {
        balanceInformationToday = [],
        balanceInformation2Column = [],
        balanceInformation3Column = [],
        ...balanceInformationData
      } = data

      if (isEmpty(balanceInformationToday)) return []

      return balanceInformationToday.map((asset) => {
        const rowId = idMapper(asset)
        const secondColumn = findByEntityTagId(
          balanceInformation2Column,
          rowId,
          idMapper
        )
        const thirdColumn = findByEntityTagId(
          balanceInformation3Column,
          rowId,
          idMapper
        )
        const assetName = nameMapper(asset)
        const assetPercentage = getAssetPercentage(
          asset,
          totalBalance,
          showOnlyPercentages
        )
        return isEmpty(balanceInformationData)
          ? getColumns({
            rowId,
            asset,
            assetPercentage,
            assetName,
            secondColumn,
            thirdColumn,
            rowConfig
          })
          : getTimeFramesColumns({
            rowId,
            asset,
            assetPercentage,
            secondColumn,
            thirdColumn,
            balanceInformationData,
            rowConfig
          })
      })
    }

const mapGeneric = (strategyConfig) => ({
  data,
  rowConfig = {},
  showOnlyPercentages = false,
  totalBalance = 0
}) => {
  const { balanceInformationToday = [] } = data
  if (isEmpty(balanceInformationToday)) return []

  const mapRows = mapPerformanceTableRows({
    data,
    totalBalance,
    showOnlyPercentages,
    rowConfig
  })

  return mapRows(
    ({ ids }) => ids[strategyConfig.idField],
    ({ names, name }) => strategyConfig.nameFields.reduce((prev, cur) => prev || names[cur], null) || name
  )
}

const mapAccountsAccountObjectives = ({ data, rowConfig }) => {
  const { balanceInformationToday } = data

  return balanceInformationToday.map((performanceData) => {
    const {
      ids: { accountObjectiveGroupId },
      names: { accountObjectiveLongName, accountObjectiveName }
    } = performanceData

    const name = accountObjectiveName || accountObjectiveLongName
    return [
      {
        alignment: 'left',
        variant: 'subtitle2',
        value: name,
        width: '20%',
        csvData: {
          value: name,
          type: CSV_CELL_TYPES.string
        }
      },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      {
        hidden: true,
        isExpandible: true,
        value: accountObjectiveGroupId,
        text: name,
        showTooltip: false,
        ...rowConfig
      }
    ]
  })
}

const mapHoldingsAssetClasses = ({ data, rowConfig, totalBalance }) => {
  const { balanceInformationToday } = data

  return balanceInformationToday.map((performanceData) => {
    const {
      ids: { assetClassTagId, assetId },
      names: { assetName, assetLongName, assetClassTagName, assetClassTagLongName },
      units,
      balance,
      balanceChange
    } = performanceData

    const assetPercentage = totalBalance !== 0 ? balance / totalBalance : 0

    const className = assetClassTagName || assetClassTagLongName
    const holdingName = assetName || assetLongName
    const name = holdingName || className
    const id = assetId || assetClassTagId

    return [
      {
        alignment: 'left',
        variant: 'subtitle2',
        value: name,
        width: '20%',
        csvData: {
          value: name,
          type: CSV_CELL_TYPES.string
        }
      },
      {
        value: units,
        width: '20%',
        csvData: { type: CSV_CELL_TYPES.number }
      },
      {
        value: balanceChange,
        width: '20%',
        csvData: { type: CSV_CELL_TYPES.number }
      },
      {
        value: assetPercentage,
        width: '20%',
        csvData: { type: CSV_CELL_TYPES.number }
      },
      {
        value: balance,
        width: '20%',
        csvData: { type: CSV_CELL_TYPES.number }
      },
      {
        hidden: true,
        isExpandible: true,
        value: id,
        text: name,
        showTooltip: true,
        tooltips: [
          '',
          {
            title: numeralByCase(units || 0, '0,0', '0,0'),
            skipFormat: true
          },
          {
            title: numeralByCase(balanceChange || 0),
            skipFormat: true
          },
          getToolTip(assetPercentage, TABLE_CELL_FORMATS.PERCENTAGE),
          {
            title: numeralByCase(balance || 0),
            skipFormat: true
          }
        ],
        ...rowConfig
      }
    ]
  })
}

const mapAssetClasses = ({
  data,
  rowConfig = {},
  showOnlyPercentages = false,
  totalBalance = 0
}) => {
  const { balanceInformationToday = [] } = data
  if (isEmpty(balanceInformationToday)) return []

  const { classType } = rowConfig

  const mapRows = mapPerformanceTableRows({
    data,
    totalBalance,
    showOnlyPercentages,
    rowConfig
  })

  switch (classType.type) {
    case ASSET_FILTERS.ACCOUNTS: {
      return mapAssetClassesByAccount({
        data,
        rowConfig,
        totalBalance
      })
    }
    case ASSET_FILTERS.ASSET_CLASSES: {
      return mapRows(
        ({ ids }) => ids.assetClassTagId,
        ({ names, name }) => names.assetClassTagName || name
      )
    }
    case ASSET_FILTERS.SUBCLASSES: {
      return mapRows(
        ({ ids }) => ids.subclassTagId,
        ({ names }) => names.subclassTagName || names.assetName
      )
    }
    case ASSET_FILTERS.ASSETS_SUBCLASS:
    case ASSET_FILTERS.ASSETS: {
      return mapRows(
        ({ ids }) => {
          return (
            ids?.assetId || ids?.classificationTagId || ids?.assetClassTagId
          )
        },
        ({ names, name, assetName }) => {
          return name || assetName || names?.assetName || names?.tagName
        }
      )
    }
    case ASSET_FILTERS.SUMMARY_ASSETS_CLASSES:
    case ASSET_FILTERS.SUMMARY_ASSETS: {
      return balanceInformationToday.map((asset) => {
        const assetName = asset.name || asset.names.assetName
        const assetPercentage = asset.assetPercentage
        const rowId = asset.assetId || asset.ids.assetId
        return getSummaryColumns({
          rowId,
          asset,
          assetPercentage,
          assetName,
          rowConfig
        })
      })
    }
    case ASSET_FILTERS.SUMMARY_SUBCLASSES: {
      return balanceInformationToday.map((asset) => {
        const assetName =
          asset.names.tagName ||
          asset.names.subclassTagName ||
          asset.names.assetClassTagName
        const rowId = asset.ids?.classificationTagId
        return getSummarySubclassesColumns({
          rowId,
          asset,
          assetName,
          rowConfig,
          classType: ASSET_FILTERS.SUMMARY_ASSETS
        })
      })
    }
    case ASSET_FILTERS.ASSET_LIQUIDITY: {
      return mapRows(
        ({ ids }) => ids.assetLiquidityTagId,
        ({ names }) =>
          names.assetLiquidityTagLongName || names.assetLiquidityTagName
      )
    }
    default:
      return []
  }
}

const getResearchTable = (data) => {
  return {
    columns: [
      { alignment: 'left', name: 'Level Name', sorteable: true },
      { alignment: 'left', name: 'Date', sorteable: true },
      { alignment: 'left', name: 'Units', sorteable: true },
      { alignment: 'left', name: 'Ending Value', sorteable: true },
      { alignment: 'left', name: 'Additions', sorteable: true },
      { alignment: 'left', name: 'Withdrawals', sorteable: true },
      { alignment: 'left', name: 'Previous Units', sorteable: true },
      { alignment: 'left', name: 'Previous Value', sorteable: true }
    ],
    rows: data.map(
      ({
        uniqueId,
        levelName,
        date,
        units,
        endingValue,
        additions,
        withdrawals,
        previousUnits,
        previousValue
      }) => {
        return [
          { value: uniqueId, hidden: true },
          { value: levelName, alignment: 'left', csvData: { type: CSV_CELL_TYPES.string } },
          { value: date, alignment: 'left', csvData: { type: CSV_CELL_TYPES.string } },
          { value: units, alignment: 'left', csvData: { type: CSV_CELL_TYPES.number } },
          { value: endingValue, alignment: 'left', csvData: { type: CSV_CELL_TYPES.number } },
          { value: additions, alignment: 'left', csvData: { type: CSV_CELL_TYPES.number } },
          { value: withdrawals, alignment: 'left', csvData: { type: CSV_CELL_TYPES.number } },
          { value: previousUnits, alignment: 'left', csvData: { type: CSV_CELL_TYPES.number } },
          { value: previousValue, alignment: 'left', csvData: { type: CSV_CELL_TYPES.number } }
        ]
      }
    )
  }
}

const getTransactionsTableConfig = (data, addExtraColumn = false) => {
  const columns = [
    { width: '10%', alignment: 'left', name: 'Date', sorteable: true },
    {
      width: addExtraColumn ? '15%' : '25%',
      alignment: 'left',
      name: 'Account',
      sorteable: true
    },
    { width: '10%', alignment: 'left', name: 'Action', sorteable: true },
    { width: '10%', alignment: 'left', name: 'Symbol', sorteable: true },
    {
      width: addExtraColumn ? '10%' : '15%',
      alignment: 'left',
      name: 'Asset Name',
      sorteable: true
    },
    { width: '10%', alignment: 'center', name: 'Quantity', sorteable: true },
    {
      width: '10%',
      alignment: 'center',
      name: 'Market Value',
      sorteable: true
    },
    { width: '10%', alignment: 'left', name: 'Details', sorteable: true }
  ]

  if (addExtraColumn) {
    columns.splice(1, 0, {
      width: '15%',
      alignment: 'left',
      name: 'Client',
      sorteable: true
    })
  }

  return {
    columns,
    rows: data.map(
      ({
        positionId,
        transactionCodeId,
        valueDate,
        displayName: accountDisplayName,
        accountName: accountLongName,
        shortName: accountShortName,
        action,
        symbol,
        shortName,
        units: _units,
        marketValue,
        clientLongName
      }) => {
        const units = parseFloat(_units)
        const row = [
          { value: `${positionId}-${transactionCodeId}`, hidden: true },
          { width: '10%', value: dayjs(valueDate).format('MM/DD/YYYY'), alignment: 'left', csvData: { type: CSV_CELL_TYPES.string } },
          { width: addExtraColumn ? '15%' : '25%', value: accountDisplayName || accountLongName || accountShortName, alignment: 'left', csvData: { type: CSV_CELL_TYPES.string } },
          { width: '10%', value: action, alignment: 'left', csvData: { type: CSV_CELL_TYPES.string } },
          { width: '10%', value: symbol || 'N/A', alignment: 'left', csvData: { type: CSV_CELL_TYPES.string } },
          { width: addExtraColumn ? '10%' : '15%', value: shortName, alignment: 'left', csvData: { type: CSV_CELL_TYPES.string } },
          { width: '10%', value: truncate(units), alignment: 'center', csvData: { type: CSV_CELL_TYPES.string } },
          { width: '10%', value: truncate(marketValue), alignment: 'center', csvData: { type: CSV_CELL_TYPES.number } },
          { width: '10%', value: '', alignment: 'left', csvData: { type: CSV_CELL_TYPES.string } }
        ]

        if (addExtraColumn) {
          row.splice(2, 0, { width: '15%', value: clientLongName, alignment: 'left', csvData: { type: CSV_CELL_TYPES.string } })
        }
        return row
      }
    )
  }
}

const getVisualBalanceDrilldownTable = (data) => {
  return data.map((holding) => {
    return [
      { value: `${holding.accountId}-${holding.assetId}`, hidden: true },
      { value: holding.assetName, width: '20%', alignment: 'left', csvData: { type: CSV_CELL_TYPES.string } },
      { value: '', width: '20%', csvData: { type: CSV_CELL_TYPES.string } },
      { value: '', width: '20%', csvData: { type: CSV_CELL_TYPES.string } },
      {
        value: numeralByCase(
          holding?.allocationPercentage,
          TABLE_CELL_FORMATS.PERCENTAGE.SHORT,
          TABLE_CELL_FORMATS.PERCENTAGE.MID
        ),
        width: '20%',
        alignment: 'right',
        csvData: {
          type: CSV_CELL_TYPES.percentage,
          originalValue: holding?.allocationPercentage
        }
      },
      {
        value: holding?.endingValue ? numeralByCase(
          holding?.endingValue,
          '0,0a',
          '0,0.0a'
        ) : '-',
        width: '20%',
        alignment: 'right',
        csvData: {
          type: CSV_CELL_TYPES.number,
          originalValue: holding?.endingValue || 0
        }
      },
      {
        hidden: true,
        showTooltip: true,
        tooltips: [
          '',
          '',
          '',
          '',
          {
            title: numeralByCase(
              holding?.allocationPercentage || 0,
              TABLE_CELL_FORMATS.PERCENTAGE.SHORT,
              TABLE_CELL_FORMATS.PERCENTAGE.LONG
            )
          },
          {
            title: numeralByCase(
              holding?.endingValue || 0,
              '0,0.0a',
              '0,0.0000a'
            )
          }
        ]
      }
    ]
  })
}

const getVisualBalanceDrilldownTableFinalRow = (data) => {
  const holdingsAllocationPercentage = data.reduce(
    (acc, holding) => acc + holding.allocationPercentage,
    0
  )
  const holdingsEndingValue = data.reduce(
    (acc, holding) => acc + holding.endingValue,
    0
  )
  return [
    { value: 'Total', alignment: 'left', width: '20%', csvData: { type: CSV_CELL_TYPES.string } },
    { value: '', width: '20%', csvData: { type: CSV_CELL_TYPES.string } },
    { value: '', width: '20%', csvData: { type: CSV_CELL_TYPES.string } },
    {
      value: numeralByCase(
        holdingsAllocationPercentage,
        TABLE_CELL_FORMATS.PERCENTAGE.SHORT,
        TABLE_CELL_FORMATS.PERCENTAGE.MID
      ),
      alignment: 'right',
      width: '20%',
      csvData: {
        type: CSV_CELL_TYPES.percentage,
        originalValue: holdingsAllocationPercentage
      }
    },
    {
      value: holdingsEndingValue ? numeralByCase(
        holdingsEndingValue,
        '0,0a',
        '0,0.0a'
      ) : '-',
      alignment: 'right',
      width: '20%',
      csvData: {
        type: CSV_CELL_TYPES.number,
        originalValue: holdingsEndingValue
      }
    },
    {
      hidden: true,
      showTooltip: true,
      tooltips: [
        '',
        '',
        '',
        {
          title: numeralByCase(
            holdingsAllocationPercentage,
            TABLE_CELL_FORMATS.PERCENTAGE.SHORT,
            TABLE_CELL_FORMATS.PERCENTAGE.LONG
          ),
          format: TABLE_CELL_FORMATS.PERCENTAGE.LONG
        },
        {
          title: numeralByCase(
            holdingsEndingValue,
            '0,0.0a',
            '0,0.0000a'
          )
        }
      ]
    }
  ]
}

const mapAssetClassesTotalsRow = (data) => {
  const [
    [, totalBalanceInformationToday = []],
    [, totalBalanceInformationSecondColumn = []],
    [, totalBalanceInformationThirdColumn = []]
  ] = data

  const calculateTotalBalance = (totalBalance) => {
    return totalBalance.reduce(
      (
        acc,
        { balance, balanceChange, balanceChangePercentage, localPercentage = 1 }
      ) => {
        return {
          balance: acc.balance + balance,
          balanceChange: acc.balanceChange + balanceChange,
          balanceChangePercentage:
            acc.balanceChangePercentage +
            localPercentage * balanceChangePercentage
        }
      },
      { balance: 0, balanceChange: 0, balanceChangePercentage: 0 }
    )
  }
  const totalBalance = calculateTotalBalance(totalBalanceInformationToday)
  const totalBalanceSecondColumn = calculateTotalBalance(
    totalBalanceInformationSecondColumn
  )
  const totalBalanceThirdColumn = calculateTotalBalance(
    totalBalanceInformationThirdColumn
  )
  const format = (num = 0, fmt) => {
    return numeral(num).format(fmt)
  }
  return [
    { value: 'Total', variant: 'subtitle2', alignment: 'left', csvData: { type: CSV_CELL_TYPES.string } },
    { value: totalBalance?.balance, csvData: { type: CSV_CELL_TYPES.number } },
    { value: numeral(1).format(TABLE_CELL_FORMATS.PERCENTAGE.SHORT), csvData: { type: CSV_CELL_TYPES.string } },
    { value: totalBalanceSecondColumn?.balanceChange, csvData: { type: CSV_CELL_TYPES.number } },
    { value: totalBalanceSecondColumn?.balanceChangePercentage, csvData: { type: CSV_CELL_TYPES.percentage } },
    { value: totalBalanceThirdColumn?.balanceChange, csvData: { type: CSV_CELL_TYPES.number } },
    { value: totalBalanceThirdColumn?.balanceChangePercentage, csvData: { type: CSV_CELL_TYPES.percentage } },
    {
      hidden: true,
      showTooltip: true,
      tooltips: [
        '',
        { title: numeralByCase(totalBalance?.balance || 0), skipFormat: true },
        numeral(1).format(TABLE_CELL_FORMATS.PERCENTAGE.SHORT),
        {
          title: numeralByCase(totalBalanceSecondColumn?.balanceChange || 0),
          skipFormat: true
        },
        {
          title: format(
            totalBalanceSecondColumn?.balanceChangePercentage,
            TABLE_CELL_FORMATS.PERCENTAGE.MID
          ),
          format: TABLE_CELL_FORMATS.PERCENTAGE.LONG
        },
        {
          title: numeralByCase(totalBalanceThirdColumn?.balanceChange || 0),
          skipFormat: true
        },
        {
          title: format(
            totalBalanceThirdColumn?.balanceChangePercentage,
            TABLE_CELL_FORMATS.PERCENTAGE.MID
          ),
          format: TABLE_CELL_FORMATS.PERCENTAGE.LONG
        }
      ]
    }
  ]
}

const getDirectValues = (balanceInformation) => {
  return balanceInformation[0] || {}
}

const getDefaultValue = (item) => item?.annualizedReturn

const mapDefaultFinalRow = (data, configColumns, accessor = getDefaultValue) => {
  const cols = (data || []).map(([, colData = []]) => {
    const c = getDirectValues(colData)
    const value = numeral(accessor(c)).format(TABLE_CELL_FORMATS.PERCENTAGE.SHORT)
    return {
      value,
      title: value,
      csvData: {
        type: CSV_CELL_TYPES.number
      }
    }
  })
  return [
    { value: 'Total', variant: 'subtitle2', alignment: 'left', csvData: { type: CSV_CELL_TYPES.string } },
    ...cols,
    {
      hidden: true,
      showTooltip: true,
      tooltips: [
        '',
        ...cols
      ],
      configColumns
    }
  ]
}

const mapHoldingsAssetClassesFinalRow = (data, configColumns) => {
  const [
    [, totalBalanceInformationToday = []]
  ] = data
  const [totals] = totalBalanceInformationToday
  return [
    {
      value: 'Total',
      variant: 'subtitle2',
      alignment: 'left',
      csvData: { type: CSV_CELL_TYPES.string }
    },
    {
      value: totals.units,
      csvData: { type: CSV_CELL_TYPES.number }
    },
    {
      value: totals.balanceChange || 0,
      csvData: { type: CSV_CELL_TYPES.number }
    },
    {
      value: numeral(1).format(TABLE_CELL_FORMATS.PERCENTAGE.SHORT),
      csvData: { type: CSV_CELL_TYPES.string }
    },
    {
      value: totals.balance || 0,
      csvData: { type: CSV_CELL_TYPES.percentage }
    },
    {
      hidden: true,
      showTooltip: true,
      tooltips: [
        '',
        {
          title: numeralByCase(totals.units || 0, '0,0', '0,0'),
          skipFormat: true
        },
        {
          title: numeralByCase(totals.balanceChange || 0),
          skipFormat: true
        },
        {
          title: numeral(1).format(TABLE_CELL_FORMATS.PERCENTAGE.SHORT)
        },
        {
          title: numeralByCase(totals.balance || 0),
          skipFormat: true
        }
      ],
      configColumns
    }
  ]
}

const mapAssetClassesFinalRow = (data, configColumns) => {
  const [
    [, totalBalanceInformationToday = []],
    [, totalBalanceInformationSecondColumn = []],
    [, totalBalanceInformationThirdColumn = []]
  ] = data

  const totalBalance = getDirectValues(totalBalanceInformationToday)
  const totalBalanceSecondColumn = getDirectValues(
    totalBalanceInformationSecondColumn
  )
  const totalBalanceThirdColumn = getDirectValues(
    totalBalanceInformationThirdColumn
  )
  const format = (num = 0, fmt) => {
    return numeral(num).format(fmt)
  }

  const FORMAT_TO_DECIMAL_CURRENCY = '$0,0.00'

  return [
    { value: 'Total', variant: 'subtitle2', alignment: 'left', csvData: { type: CSV_CELL_TYPES.string } },
    { value: totalBalance?.balance || 0, csvData: { type: CSV_CELL_TYPES.number } },
    { value: numeral(1).format(TABLE_CELL_FORMATS.PERCENTAGE.SHORT), csvData: { type: CSV_CELL_TYPES.string } },
    { value: totalBalanceSecondColumn?.balanceChange || 0, csvData: { type: CSV_CELL_TYPES.number } },
    { value: totalBalanceSecondColumn?.balanceChangePercentage || 0, csvData: { type: CSV_CELL_TYPES.percentage } },
    { value: totalBalanceThirdColumn?.balanceChange || 0, csvData: { type: CSV_CELL_TYPES.number } },
    { value: totalBalanceThirdColumn?.balanceChangePercentage || 0, csvData: { type: CSV_CELL_TYPES.percentage } },
    {
      hidden: true,
      showTooltip: true,
      tooltips: [
        '',
        {
          title: format(totalBalance?.balance, FORMAT_TO_DECIMAL_CURRENCY)
        },
        {
          title: numeral(1).format(TABLE_CELL_FORMATS.PERCENTAGE.SHORT)
        },
        {
          title: format(totalBalanceSecondColumn?.balanceChange, FORMAT_TO_DECIMAL_CURRENCY)
        },
        {
          title: format(
            totalBalanceSecondColumn?.balanceChangePercentage,
            TABLE_CELL_FORMATS.PERCENTAGE.LONG
          ),
          format: TABLE_CELL_FORMATS.PERCENTAGE.LONG
        },
        {
          title: format(totalBalanceThirdColumn?.balanceChange, FORMAT_TO_DECIMAL_CURRENCY)
        },
        {
          title: format(
            totalBalanceThirdColumn?.balanceChangePercentage,
            TABLE_CELL_FORMATS.PERCENTAGE.LONG
          ),
          format: TABLE_CELL_FORMATS.PERCENTAGE.LONG
        }
      ],
      configColumns
    }
  ]
}

const getShouldClearExistingFilters = (assetFilters, rowAssetsConfig) => {
  return Object.values(assetFilters)
    .map(({ payload }) => ({ ...(payload?.assetTree || {}) }))
    .reduce((acc, assetFilter) => {
      if (acc) return acc
      for (const prop in assetFilter) {
        if (
          has(assetFilter, prop) &&
          has(rowAssetsConfig, prop) &&
          assetFilter[prop] !== rowAssetsConfig[prop]
        ) {
          return true
        }
      }
      return acc
    }, false)
}

const getRowConfigs = (rows, rowIndexes, rowConfigs = []) => {
  const rowIndex = rowIndexes.shift()
  if (isEmpty(rows)) {
    return rowConfigs
  }
  const rowConfig = last(rows[rowIndex])
  rowConfigs.push(rowConfig)
  if (isEmpty(rowIndexes)) {
    return rowConfigs
  }
  return getRowConfigs(rowConfig?.rows, rowIndexes, rowConfigs)
}

const mapAccountObjectives = ({
  data,
  rowConfig = {},
  totalBalance = 0
}) => {
  const {
    balanceInformationToday,
    balanceInformation2Column,
    balanceInformation3Column
  } = data

  return balanceInformationToday.map(
    ({ names, ids, balance, endingValue }, index) => {
      const assetPercentage = balance / totalBalance
      const secondColumn = balanceInformation2Column[index]
      const thirdColumn = balanceInformation3Column[index]

      const assetReturnValues = getAssetReturnValues(
        rowConfig,
        secondColumn,
        thirdColumn
      )
      const assetReturnTitles = getAssetReturnTitles(secondColumn, thirdColumn)

      return [
        {
          value: names.accountObjectiveName,
          alignment: 'left',
          variant: 'subtitle2',
          csvData: { type: CSV_CELL_TYPES.string }
        },
        {
          value: endingValue,
          width: '12%',
          csvData: { type: CSV_CELL_TYPES.number }
        },
        {
          value: numeralByCase(
            assetPercentage,
            TABLE_CELL_FORMATS.PERCENTAGE.SHORT,
            TABLE_CELL_FORMATS.PERCENTAGE.SHORT
          ),
          width: '12%',
          csvData: {
            originalValue: assetPercentage,
            type: CSV_CELL_TYPES.percentage
          }
        },
        {
          value: secondColumn?.balanceChange,
          width: '12%',
          csvData: { type: CSV_CELL_TYPES.number }
        },
        {
          value: assetReturnValues[0],
          payload: { ...secondColumn, isValueReturn: true },
          width: '12%',
          csvData: {
            originalValue: secondColumn?.balanceChangePercentage,
            type: CSV_CELL_TYPES.percentage
          }
        },
        {
          value: thirdColumn?.balanceChange,
          width: '12%',
          csvData: { type: CSV_CELL_TYPES.number }
        },
        {
          value: assetReturnValues[1],
          payload: { ...thirdColumn, isValueReturn: true },
          width: '12%',
          csvData: {
            originalValue: thirdColumn?.balanceChangePercentage,
            type: CSV_CELL_TYPES.percentage
          }
        },
        {
          hidden: true,
          isExpandible: true,
          showTooltip: true,
          hasFinalRows: true,
          value: ids.accountObjectiveGroupId,
          tooltips: [
            { title: names.accountObjectiveName },
            {
              title: numeralByCase(endingValue || 0),
              skipFormat: true
            },
            getToolTip(assetPercentage, TABLE_CELL_FORMATS.PERCENTAGE),
            {
              title: numeralByCase(secondColumn?.balanceChange || 0),
              skipFormat: true
            },
            {
              title: assetReturnTitles[0],
              format: TABLE_CELL_FORMATS.PERCENTAGE.LONG
            },
            {
              title: numeralByCase(thirdColumn?.balanceChange || 0),
              skipFormat: true
            },
            {
              title: assetReturnTitles[1],
              format: TABLE_CELL_FORMATS.PERCENTAGE.LONG
            }
          ],
          ...rowConfig
        }
      ]
    }
  )
}

const findByEntityTagId = (assets, assetTargetId, mapId) => {
  return assets.find((asset) => {
    const assetId = mapId(asset)
    return assetId === assetTargetId
  })
}

const mapAssetClassesByAccount = ({
  data,
  rowConfig = {},
  totalBalance = 0
}) => {
  const {
    balanceInformationToday,
    balanceInformation2Column,
    balanceInformation3Column
  } = data

  return balanceInformationToday.map((performanceData) => {
    const {
      ids: { accountId },
      names: { accountName, displayName },
      balance,
      endingValue,
      accountNumber
    } = performanceData

    const assetPercentage = balance / totalBalance
    const secondColumn = findByEntityTagId(
      balanceInformation2Column,
      accountId,
      ({ ids }) => ids.accountId
    )
    const thirdColumn = findByEntityTagId(
      balanceInformation3Column,
      accountId,
      ({ ids }) => ids.accountId
    )

    const assetReturnValues = getAssetReturnValues(
      rowConfig,
      secondColumn,
      thirdColumn
    )

    const assetReturnTitles = getAssetReturnTitles(
      secondColumn,
      thirdColumn
    )

    return [
      {
        alignment: 'left',
        variant: 'subtitle2',
        value: displayName || accountName,
        width: '28%',
        csvData: {
          value: displayName || accountName,
          type: CSV_CELL_TYPES.string
        }
      },
      {
        value: endingValue,
        width: '12%',
        csvData: { type: CSV_CELL_TYPES.number }
      },
      {
        value: assetPercentage,
        width: '12%',
        csvData: { type: CSV_CELL_TYPES.percentage }
      },
      {
        value: secondColumn?.balanceChange,
        width: '12%',
        csvData: { type: CSV_CELL_TYPES.number }
      },
      {
        value: assetReturnValues[0],
        payload: { ...secondColumn, isValueReturn: true },
        width: '12%',
        csvData: { type: CSV_CELL_TYPES.percentage }
      },
      {
        value: thirdColumn?.balanceChange,
        width: '12%',
        csvData: { type: CSV_CELL_TYPES.number }
      },
      {
        value: assetReturnValues[1],
        payload: { ...thirdColumn, isValueReturn: true },
        width: '12%',
        csvData: { type: CSV_CELL_TYPES.percentage }
      },
      {
        hidden: true,
        isExpandible: true,
        value: accountId,
        text: displayName || accountName,
        showTooltip: true,
        tooltips: [
          { title: accountNumber || '' },
          { title: numeralByCase(endingValue || 0), skipFormat: true },
          getToolTip(assetPercentage, TABLE_CELL_FORMATS.PERCENTAGE),
          {
            title: numeralByCase(secondColumn?.balanceChange || 0),
            skipFormat: true
          },
          {
            title: assetReturnTitles[0],
            format: TABLE_CELL_FORMATS.PERCENTAGE.LONG
          },
          {
            title: numeralByCase(thirdColumn?.balanceChange || 0),
            skipFormat: true
          },
          {
            title: assetReturnTitles[1],
            format: TABLE_CELL_FORMATS.PERCENTAGE.LONG
          }
        ],
        ...rowConfig
      }
    ]
  })
}

function hasBalanceDataSameLength (balanceInformationReport) {
  const acc = {
    maxLength: -1,
    indexOf: -1
  }
  balanceInformationReport.forEach(([, element], index) => {
    if (element.length > acc.maxLength) {
      acc.maxLength = element.length
      acc.indexOf = index
    }
  })

  return acc
}

function injectMissingRowsIfAny (balanceInformationReport) {
  const { maxLength, indexOf } = hasBalanceDataSameLength(
    balanceInformationReport
  )

  let [[, balanceInformationAsOfToday]] = balanceInformationReport
  if (maxLength === balanceInformationAsOfToday.length) {
    return balanceInformationAsOfToday
  }
  const [, balanceInformationReference] = balanceInformationReport[indexOf]

  const balanceInformationAsOfDateDiff = balanceInformationReference.filter(
    ({ ids: { uniqueId } }) =>
      !balanceInformationAsOfToday.find(
        ({ ids: { uniqueId: nestUniqueId } }) => uniqueId === nestUniqueId
      )
  )
  balanceInformationAsOfDateDiff.forEach((balanceInformationData) => {
    const balanceInfoIndex = balanceInformationReference.indexOf(
      balanceInformationData
    )
    balanceInformationAsOfToday = insertAtIndex(
      balanceInfoIndex,
      balanceInformationAsOfToday,
      {
        ...balanceInformationReference[balanceInfoIndex],
        balance: 0,
        balanceChange: 0,
        balanceChangePercentage: 0
      }
    )
  })
  return balanceInformationAsOfToday
}

const findRowConfig = (rowConfig, configKey) => {
  if (!rowConfig) return null
  if (rowConfig[configKey]) {
    return rowConfig[configKey]
  }
  const { parentConfig } = rowConfig
  if (parentConfig) {
    return findRowConfig(parentConfig, configKey)
  }
  return null
}

const fetchFilterKeyByLevelType = Object.entries(
  LEVEL_TYPE_BY_ASSET_FILTER
).reduce(
  (acc, [classType, levelType]) => ({
    ...acc,
    [classType]: {
      levelType,
      levelTypeFilterKey: `${levelType}Ids`
    }
  }),
  {}
)

const getFilterKey = (filterName) => {
  if (filterName in fetchFilterKeyByLevelType) {
    return fetchFilterKeyByLevelType[filterName]
  }

  return {
    levelType: filterName,
    levelTypeFilterKey: `${filterName}Ids`
  }
}

export const getFetchParamsByLevelType = (
  { type: classType },
  filterValue = [],
  fetchParams
) => {
  const { levelType, levelTypeFilterKey } = getFilterKey(classType)

  filterValue = !isEmpty(filterValue) ? [filterValue] : fetchParams[levelTypeFilterKey]

  fetchParams = {
    ...fetchParams,
    [levelTypeFilterKey]: filterValue || []
  }
  return { params: fetchParams, levelType }
}

const getClassTypeHierarchyFetchParams = (
  config,
  fetchParams = {},
  includeTargetLevelType = true
) => {
  const { classType, value } = config
  const { levelType, params } = getFetchParamsByLevelType(
    classType,
    value,
    fetchParams
  )
  fetchParams = { ...params }
  if (isEmpty(fetchParams?.levelTypes) && includeTargetLevelType) {
    fetchParams = {
      ...params,
      levelTypes: [levelType]
    }
  }
  const { parentConfig } = config
  if (!parentConfig || !includeTargetLevelType) return fetchParams
  return getClassTypeHierarchyFetchParams(parentConfig, fetchParams)
}

async function fetchClassTypesStrategy ({
  config,
  fetcher,
  baseFetchParams,
  includeTargetLevelType
}) {
  const fetchParams = getClassTypeHierarchyFetchParams(
    config,
    baseFetchParams,
    includeTargetLevelType
  )
  const assets = await fetcher(fetchParams)
  return assets
}

const getAdvisorAllocationTableColumns = (availableDates, isClientTable) => {
  return [
    [
      { colSpan: 4, name: '' },
      {
        colSpan: 2,
        isSelect: true,
        selectProps: {
          selectType: 'date',
          sourceKey: 'balanceInformationFirstColumn',
          selectedValue: getSafeDate(availableDates, {
            operation: DAYJS_OPERATIONS.SUBTRACT,
            unitType: DAYJS_UNIT_TYPES.MONTH,
            unitValue: 3
          })
        }
      }
    ],
    [
      { alignment: 'left', name: isClientTable ? 'NAME' : 'CLIENT' },
      { alignment: 'left', name: isClientTable ? 'ASSET NAME' : 'ACCOUNT' },
      { alignment: 'right', name: 'QUANTITY' },
      { name: 'TOTAL VALUE' },
      { name: 'NET GAIN' },
      { name: 'RETURN' }
    ]
  ]
}

const mapHoldingsTableRows = (assets, total, assetClassId) => {
  return assets.map((asset) => {
    const relativeAssetPercentage = total !== 0 ? asset.balance / total : 0
    const assetPercentageNumeral = assetClassId
      ? asset.assetPercentage
      : asset.assetClassPercentage || relativeAssetPercentage || 0
    const { names } = asset
    const name = names.assetName || names.assetClassTagName || names.tagName
    return [
      {
        alignment: 'left',
        variant: 'subtitle2',
        width: '28%',
        value: name,
        csvData: { type: CSV_CELL_TYPES.string }
      },
      { width: '60%', value: assetPercentageNumeral, csvData: { type: CSV_CELL_TYPES.percentage } },
      { width: '12%', value: asset.balance, csvData: { type: CSV_CELL_TYPES.number } },
      {
        hidden: true,
        isExpandible: false,
        value: asset.assetId || asset.assetClassId,
        text: name,
        type: !asset.assetId ? 'assetClasses' : 'assets',
        showTooltip: true,
        tooltips: [
          '',
          {
            title: format(assetPercentageNumeral, TABLE_CELL_FORMATS.PERCENTAGE.MID),
            format: TABLE_CELL_FORMATS.PERCENTAGE.LONG
          },
          {
            title: numeralByCase(asset.balance || 0),
            skipFormat: true
          }
        ]
      }
    ]
  })
}

const mapHoldingsTableTotalRow = (totalBalance) => {
  return [
    {
      value: 'Total',
      variant: 'subtitle2',
      alignment: 'left',
      colSpan: 1,
      csvData: { type: CSV_CELL_TYPES.string }
    },
    1,
    { value: totalBalance || '-', csvData: { type: CSV_CELL_TYPES.number, originalValue: totalBalance || 0 } },
    {
      hidden: true,
      showTooltip: true,
      tooltips: [
        '',
        {
          title: format(1, TABLE_CELL_FORMATS.PERCENTAGE.SHORT),
          format: TABLE_CELL_FORMATS.PERCENTAGE.SHORT
        },
        {
          title: numeralByCase(totalBalance || 0),
          skipFormat: true
        }
      ]
    }
  ]
}

const hydrateDate = (dateSpec, availableDates) => {
  const { suffix, ...dateSpecs } = dateSpec
  if (suffix) {
    const selectedValue = `${getSafeDate(availableDates, dateSpecs)}${
      suffix || ''
    }`
    return selectedValue
  }
  return getSafeDate(availableDates, dateSpecs)
}

const hydrateColumnsSelectProps = (columns, availableDates, columnsFormat) => {
  const mapColumn = (col, isLastMultiHeaderRow, columnIndex) => {
    const addSelectProps = column => {
      if (column?.selectProps?.selectedValue) {
        const {
          selectProps: {
            selectedValue: { suffix, ...selectedValueProps }
          }
        } = column

        const selectedValue = `${getSafeDate(
          availableDates,
          selectedValueProps
        )}${suffix || ''}`

        return {
          ...column,
          selectProps: {
            ...column.selectProps,
            selectedValue
          }
        }
      }

      return column
    }

    const addFormatColumn = column => {
      if (isLastMultiHeaderRow) {
        const [, secondColumns] = columns
        const filteredColumns = secondColumns.filter(item => !item.hidden).filter(item => item.name)
        if (column.name) {
          if (typeof columnsFormat === 'string') {
            return {
              ...column,
              config: {
                cellFormat: columnsFormat
              }
            }
          }
          const formatIndex = columnIndex - (secondColumns.length - filteredColumns.length)
          return {
            ...column,
            config: {
              cellFormat: columnsFormat ? columnsFormat[formatIndex] : null
            }
          }
        }
        return column
      }
      return column
    }

    const columnConfig = compose(addSelectProps, addFormatColumn)(col)

    return columnConfig
  }

  return (columns || []).map((column, columnIndex) => {
    const isLastMultiHeaderRow = isArray(column) && columns.length - 1 === columnIndex

    return isArray(column)
      ? column.map((column, index) => mapColumn(column, isLastMultiHeaderRow, index))
      : mapColumn(column, isLastMultiHeaderRow)
  })
}

const getPortfolioSummaryRows = (portfolioData) => {
  return portfolioData.map((assetClass) => {
    const format = (num = 0, fmt) => {
      return numeral(num).format(fmt)
    }
    const { assetClassPercentage, assetPercentage } = assetClass

    const assetPercentageNumeral =
      assetClassPercentage || assetPercentage || 0.0

    const { name, assetClassTagName, assetName, tagName } = assetClass
    const entityName = name || assetClassTagName || assetName || tagName

    const { assetId, assetClassId, assetClassTagId } = assetClass
    const entityId = assetId || assetClassId || assetClassTagId

    return [
      {
        alignment: 'left',
        variant: 'subtitle2',
        width: '8%',
        value: entityName,
        csvData: { type: CSV_CELL_TYPES.string }
      },
      {
        value: assetClass.balance,
        hidden: true,
        hideIfExpanded: true,
        csvData: { type: CSV_CELL_TYPES.number }
      },
      {
        width: '20%',
        value: '',
        hideIfExpanded: true,
        csvData: { type: CSV_CELL_TYPES.string }
      },
      {
        width: '8%',
        value: '',
        hideIfExpanded: true,
        csvData: { type: CSV_CELL_TYPES.string }
      },
      {
        width: '8%',
        value: '',
        hideIfExpanded: true,
        csvData: { type: CSV_CELL_TYPES.string }
      },
      {
        width: '8%',
        value: '',
        hideIfExpanded: true,
        csvData: { type: CSV_CELL_TYPES.string }
      },
      {
        width: '8%',
        value: '',
        hideIfExpanded: true,
        csvData: { type: CSV_CELL_TYPES.string }
      },
      {
        width: '8%',
        value: '',
        hideIfExpanded: true,
        csvData: { type: CSV_CELL_TYPES.string }
      },
      {
        width: '8%',
        value: '',
        hideIfExpanded: true,
        csvData: { type: CSV_CELL_TYPES.string }
      },
      {
        width: '8%',
        value: '',
        hideIfExpanded: true,
        csvData: { type: CSV_CELL_TYPES.string }
      },
      {
        width: '8%',
        value: '',
        hideIfExpanded: true,
        csvData: { type: CSV_CELL_TYPES.string }
      },
      {
        hidden: true,
        hideChevron: true,
        isExpandible: true,
        isExpanded: true,
        value: entityId,
        text: entityName,
        classType: 'SUMMARY_SUBCLASSES',
        showTooltip: true,
        tooltips: [
          {
            title: format(
              assetPercentageNumeral,
              TABLE_CELL_FORMATS.PERCENTAGE.MID
            ),
            format: TABLE_CELL_FORMATS.PERCENTAGE.LONG
          },
          '',
          {
            title: format(assetClass?.units, '0,0a'),
            format: '0.0000a'
          },
          {
            title: format(assetClass?.endingValue, '0,0a'),
            format: '0.0000a'
          },
          {
            title: format(assetClass?.endingValue / assetClass.units, '0a'),
            format: '0.0000a'
          },
          {
            title: format(0, '0a'),
            format: '0.0000a'
          },
          {
            title: format(0, '0a'),
            format: '0.0000a'
          },
          {
            title: format(0, '0a'),
            format: '0.0000a'
          },
          {
            title: format(0, '0a'),
            format: '0.0000a'
          },
          {
            title: format(
              assetClass?.returnValue,
              TABLE_CELL_FORMATS.PERCENTAGE.MID
            ),
            format: TABLE_CELL_FORMATS.PERCENTAGE.LONG
          }
        ]
      }
    ]
  })
}

const getPortfolioFinalRow = (portfolioData) => {
  const totalWeight = portfolioData.reduce(
    (acc, { assetClassPercentage }) => acc + assetClassPercentage,
    0
  )

  const totalBalance = portfolioData.reduce(
    (acc, { balance }) => acc + balance,
    0
  )

  const totalCostBasis = 0
  const totalUNrealizedGain = 0
  const totalAnnualIncome = 0

  const format = (num = 0, formatWithDecimals, formatWithoutDecimals) => {
    const fmt = num % 1 !== 0 ? formatWithDecimals : formatWithoutDecimals
    return numeral(num).format(fmt)
  }

  return [
    {
      value: totalWeight,
      variant: 'subtitle2',
      alignment: 'center',
      csvData: { type: CSV_CELL_TYPES.number }
    },
    {
      value: '',
      csvData: { type: CSV_CELL_TYPES.string }
    },
    {
      value: '',
      csvData: { type: CSV_CELL_TYPES.string }
    },
    {
      value: numeral(totalBalance).format('0,0.00').toString(),
      alignment: 'center',
      csvData: { type: CSV_CELL_TYPES.number, originalValue: totalBalance }
    },
    {
      value: '',
      csvData: { type: CSV_CELL_TYPES.string }
    },
    {
      value: totalCostBasis,
      alignment: 'right',
      csvData: { type: CSV_CELL_TYPES.number }
    },
    {
      value: '',
      csvData: { type: CSV_CELL_TYPES.string }
    },
    {
      value: totalUNrealizedGain,
      alignment: 'right',
      csvData: { type: CSV_CELL_TYPES.number }
    },
    {
      value: totalAnnualIncome,
      alignment: 'right',
      csvData: { type: CSV_CELL_TYPES.number }
    },
    {
      value: '',
      csvData: { type: CSV_CELL_TYPES.string }
    },
    {
      hidden: true,
      showTooltip: true,
      tooltips: [
        {
          title: format(
            totalWeight, TABLE_CELL_FORMATS.PERCENTAGE.LONG,
            TABLE_CELL_FORMATS.PERCENTAGE.SHORT
          ),
          format: TABLE_CELL_FORMATS.PERCENTAGE.LONG
        },
        '',
        '',
        {
          title: format(totalBalance, '0.0000a', '0a'),
          format: '0.0000a'
        },
        '',
        {
          title: format(totalCostBasis, '0.0000a', '0a'),
          format: '0.0000a'
        },
        '',
        {
          title: format(totalUNrealizedGain, '0.0000a', '0a'),
          format: '0.0000a'
        },
        {
          title: format(totalAnnualIncome, '0.0000a', '0a'),
          format: '0.0000a'
        },
        ''
      ]
    }
  ]
}

const getNetFlowsTable = (data, hideFlows) => {
  const columns = [
    { hidden: true },
    { name: 'Client', alignment: 'left', sorteable: false },
    { name: 'Net Flows', alignment: 'left', sorteable: true }
  ]

  if (!hideFlows) {
    columns.push(
      { name: 'Inflows', alignment: 'left', sorteable: true },
      { name: 'Outflows', alignment: 'left', sorteable: true }
    )
  }

  return {
    columns,
    rows: data.map(
      ({
        uniqueId: id,
        clientName,
        additions: flowIn,
        withdrawals: flowOut,
        netFlows: flowChange
      }) => {
        const row = [
          { value: id, hidden: true },
          { value: clientName, alignment: 'left', csvData: { type: CSV_CELL_TYPES.string } },
          { value: tableNumberFormatter(flowChange || 0), alignment: 'left', csvData: { type: CSV_CELL_TYPES.number, originalValue: flowChange || 0 } }
        ]

        if (!hideFlows) {
          row.push(
            { value: tableNumberFormatter(flowIn || 0), alignment: 'left', csvData: { type: CSV_CELL_TYPES.number, originalValue: flowIn || 0 } },
            { value: `-${tableNumberFormatter(flowOut || 0)}`, alignment: 'left', csvData: { type: CSV_CELL_TYPES.number, originalValue: flowOut || 0 } }
          )
        }

        return row
      }
    )
  }
}

export const formatScientificNotation = ({ number, digits = 10 }) => {
  if (!isNumeric(number)) return NaN
  return parseFloat(Number(number).toFixed(digits))
}

const formatCellValue = (value, format) => {
  let formattedValue

  if (!isNumber(value)) return value

  const formattedBigValue = value?.toString()?.includes('e')
    ? formatScientificNotation({ number: value, digits: 2 })
    : value

  if (format) {
    formattedValue = numeral(formattedBigValue).format(format)
  } else {
    formattedValue = isNumber(value)
      ? tableNumberFormatter(formattedBigValue)
      : formattedBigValue
  }

  return formattedValue
}

const formatCell = (cell, { cellFormat }) => {
  if (!cellFormat) return cell

  if (has(cell, 'value') && isNumber(cell?.value)) {
    cell.value = numeral(cell.value).format(cellFormat)
  }
  if (isNumber(cell)) {
    cell = numeral(cell).format(cellFormat)
  }

  return cell
}

const formatColumn = (columns, column, value, columnsFormat) => {
  const findColumnIndex = () => columns.findIndex((col) => col.Header === column.Header)
  const index = findColumnIndex()

  if (columnsFormat[index]) {
    return format(value, columnsFormat[index])
  }

  return tableNumberFormatter(value)
}

const cellFormat = (cell, columns, columnsFormat) => {
  const { value } = cell
  return <div><span title={numeralByCase(value)}>{formatColumn(columns, cell.column, value, columnsFormat)}</span></div>
}

const updateExpandableTable = ({ childData, id, rows }) => {
  const insertChildRows = ({ existingRows, subRowsToInsert, path }) => {
    const id = path[0]

    const isBaseRow = path.length === 1

    if (isBaseRow) {
      return existingRows.map((row, index) => {
        const isMatchedRowWithoutSubRows = index === Number(id) && !row.subRows

        if (isMatchedRowWithoutSubRows) {
          return {
            ...row,
            subRows: subRowsToInsert
          }
        }

        return row
      })
    }

    return existingRows.map((row, index) => {
      const isMatchedRowWithSubRows = index === Number(id) && row.subRows

      if (isMatchedRowWithSubRows) {
        const [, ...updatedPath] = path

        return {
          ...row,
          subRows: insertChildRows({
            existingRows: row.subRows,
            subRowsToInsert,
            path: updatedPath
          })
        }
      }

      return row
    })
  }

  const { data } = childData

  return insertChildRows({
    existingRows: rows,
    subRowsToInsert: data,
    path: id.split('.')
  })
}

const formatTableTopsRows = (asOfDateData = [], firstColumnData, isClientSelected = true) => {
  return asOfDateData.map((balanceInformation, index) => {
    const balanceInformationFirst = firstColumnData[index] || {}

    const name =
      balanceInformation.names?.clientName ||
      balanceInformation.names?.clientLongName ||
      ''

    const assetName = balanceInformation.names?.assetName || '-'
    const units = balanceInformation.units || '-'

    const accountName =
      balanceInformation.names?.accountName ||
      balanceInformation.names?.displayName ||
      '-'

    return {
      name,
      units,
      totalValue: balanceInformation.balance,
      netGain: balanceInformationFirst.balanceChange,
      assetName: isClientSelected ? assetName : accountName,
      netReturn: {
        balanceChangePercentage: balanceInformationFirst.balanceChangePercentage,
        balanceChangePositive: balanceInformationFirst.balanceChangePositive
      }
    }
  })
}

export {
  hydrateDate,
  getColumns,
  formatCell,
  orderAssetsBy,
  getRowConfigs,
  mapGeneric,
  mapAssetClasses,
  getResearchTable,
  mapAssetFinalRow,
  getSummaryColumns,
  getSummaryFinalRow,
  numberFormattedCell,
  getTimeFramesColumns,
  mapAccountObjectives,
  mapHoldingsTableTotalRow,
  injectMissingRowsIfAny,
  mapHoldingsTableRows,
  mapDefaultFinalRow,
  mapAssetClassesFinalRow,
  mapAssetClassesByAccount,
  mapAssetClassesTotalsRow,
  hydrateColumnsSelectProps,
  getTransactionsTableConfig,
  getSummarySubclassesColumns,
  getShouldClearExistingFilters,
  getVisualBalanceDrilldownTable,
  getAdvisorAllocationTableColumns,
  getVisualBalanceDrilldownTableFinalRow,
  getPortfolioSummaryRows,
  getPortfolioFinalRow,
  getNetFlowsTable,
  findRowConfig,
  formatCellValue,
  fetchClassTypesStrategy,
  formatColumn,
  cellFormat,
  updateExpandableTable,
  formatTableTopsRows,
  mapHoldingsAssetClasses,
  mapHoldingsAssetClassesFinalRow,
  mapAccountsAccountObjectives
}
