import React from 'react'
import PropTypes from 'prop-types'
import { Typography } from '@material-ui/core'
import TitleField from './TitleField'
import PreformattedTextField from './PreformattedTextField'
import MarkdownField from './MarkdownField'
import LinkField from './LinkField'
import NumberField from './NumberField'
import EmbeddedContentField from './EmbeddedContentField'
import DateField from './DateField'

function EntryContentField ({ field }) {
  let component = (
    <div>{field.value}</div>
  )

  if (field.type === 'title') {
    component = <TitleField field={field} />
  }

  if (field.type === 'text') {
    component = <PreformattedTextField field={field} />
  }

  if (field.type === 'markdown') {
    component = <MarkdownField field={field} />
  }

  if (field.type === 'link') {
    component = <LinkField field={field} />
  }

  if (field.type === 'number') {
    component = <NumberField field={field} />
  }

  if (field.type === 'embedded') {
    component = <EmbeddedContentField field={field} />
  }

  if (field.type === 'date') {
    component = <DateField field={field} />
  }

  if (field.data?.showLabel) {
    return (
      <>
        <Typography variant='h3' style={{ marginBottom: '10px' }}>{field.label}</Typography>
        {component}
      </>
    )
  }

  return (
    <>
      {component}
    </>
  )
}

EntryContentField.propTypes = {
  field: PropTypes.shape({
    type: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.any,
    data: PropTypes.shape({
      showLabel: PropTypes.bool
    })
  })
}

export default EntryContentField
