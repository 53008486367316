import PropTypes from 'prop-types'
import { calcPositivePercentage } from '../../../utils'

const MIN_HEIGHT_PERCENTAGE = 10

export const VisualBalanceLabelShape = PropTypes.shape({
  chart: PropTypes.shape({
    blocksTitle: PropTypes.string,
    totalTitle: PropTypes.string
  }),
  drillDown: PropTypes.shape({
    backButton: PropTypes.string,
    other: PropTypes.string
  }),
  emptyState: PropTypes.shape({
    title: PropTypes.string,
    subtitle: PropTypes.string
  }),
  otherAssets: PropTypes.shape({
    title: PropTypes.string
  })
})

export const VisualBalanceSectionsShape = PropTypes.shape({
  main: PropTypes.arrayOf(PropTypes.number),
  bottom: PropTypes.arrayOf(PropTypes.number),
  side: PropTypes.arrayOf(PropTypes.number),
  hidden: PropTypes.arrayOf(PropTypes.number)
})

// Prop Type Shapes
const VisualBalanceAccountShape = {
  accountId: PropTypes.number,
  shortNumber: PropTypes.string,
  accountLongName: PropTypes.string,
  accountName: PropTypes.string,
  displayName: PropTypes.string,
  displayNumber: PropTypes.string,
  dataSourceId: PropTypes.number,
  custodianId: PropTypes.number,
  custodianCode: PropTypes.string,
  custodianName: PropTypes.string,
  custodianImageUrl: PropTypes.string,
  lastDataAvailable: PropTypes.string,
  endingValue: PropTypes.number
}
export const VisualBalanceSubgroupShape = {
  accountCategoryGroupId: PropTypes.number,
  accountCategoryName: PropTypes.string,
  accountCategoryLongName: PropTypes.string,
  accountCategoryLevelTypeId: PropTypes.number,
  colorField: PropTypes.string,
  displayName: PropTypes.string,
  accounts: PropTypes.arrayOf(PropTypes.shape(VisualBalanceAccountShape)),
  total: PropTypes.number
}
const VisualBalanceSectionShape = {
  clientId: PropTypes.number,
  clientName: PropTypes.string,
  clientLongName: PropTypes.string,
  groupId: PropTypes.number,
  groupLongName: PropTypes.string,
  groupColorField: PropTypes.string,
  subgroups: PropTypes.arrayOf(PropTypes.shape(VisualBalanceSubgroupShape)),
  total: PropTypes.number
}
export const VisualBalanceDataShape = {
  side: PropTypes.arrayOf(PropTypes.shape(VisualBalanceSectionShape)),
  core: PropTypes.arrayOf(PropTypes.shape(VisualBalanceSectionShape)),
  bottom: PropTypes.arrayOf(PropTypes.shape(VisualBalanceSectionShape)),
  other: PropTypes.arrayOf(PropTypes.shape(VisualBalanceSectionShape))
}
/** Holding Table Props */
export const VisualBalanceHoldingTableColumnsShape = PropTypes.shape({
  accessor: PropTypes.string.isRequired,
  Header: PropTypes.string.isRequired,
  format: PropTypes.string,
  alignRight: PropTypes.bool,
  disableSortBy: PropTypes.bool
})
export const VisualBalanceHoldingTableDefaultSortShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  desc: PropTypes.bool.isRequired
})

/** Format Props */
export const VisualBalanceFormatsShape = PropTypes.shape({
  list: PropTypes.shape({
    overview: PropTypes.string,
    details: PropTypes.string
  }),
  tree: PropTypes.shape({
    overview: PropTypes.string,
    details: PropTypes.string
  }),
  netWorth: PropTypes.shape({
    primary: PropTypes.string,
    alternate: PropTypes.string
  })
})

/**
 * @typedef {Object} VisualBalanceSubgroups
 * @property {number} total - total value of accounts
 * @property {number} [percentage] - Percentage of section this subgroup is
 */

/**
 * @typedef {Object} VisualBalanceGroup
 * @property {VisualBalanceSubgroups[]} subgroups - Subgroups inside this section
 * @property {number} clientId - client id for records
 * @property {string} clientName - client name for records
 * @property {string} clientLongName - client long name for records
 * @property {number} groupId - id of the group for these records
 * @property {string} groupLongName - long name of the group for the records
 * @property {string} groupColorField - color to display for summary block
 * @property {number} total - total value of records
 * @property {number} [percentage] - Percentage of section this subgroup is
 */

/**
 * @typedef {Object} SectionParams
 * @property {number[]} core
 * @property {number[]} bottom
 * @property {number[]} side
 * @property {number[]} other
 */

/**
 * @typedef {Object} VisualBalanceQueryParams
 * @property {string} startDate
 * @property {string} endDate
 * @property {string} dateType
 * @property {number[]} clientIds
 * @property {number[]} splitAssetClassTagIds
 * @property {string} calcType
 */

/**
 * Get Percentage of total, with minimum threshold
 * @param {number} value
 * @param {number} total
 * @param {number} minThreshold
 * @return {number}
 */
export const getPercentageOfTotal = (value, total, minThreshold = MIN_HEIGHT_PERCENTAGE) => {
  let percentage = calcPositivePercentage(value, total)
  if (percentage < minThreshold) {
    percentage = minThreshold
  }
  return percentage
}

/**
 * Normalize Each Section's Data
 * @param section {VisualBalanceGroup[]}
 * @param splitAssetClassTagIds {number[]}
 * @param accountSortingPriority {{key: string, dir: string}[]}
 * @return {VisualBalanceGroup[]}
 */
const normalizeSectionData = (section, splitAssetClassTagIds, accountSortingPriority) => {
  const sectionTotal = section.reduce((acc, row) => Math.abs(row.total) + acc, 0)

  return section.map((group) => {
    group.percentage = getPercentageOfTotal(group.total, sectionTotal)
    group.subgroups = group.subgroups.map(subgroup => {
      subgroup.percentage = getPercentageOfTotal(subgroup.total, group.total)
      subgroup.accounts = subgroup.accounts.sort((a, b) => {
        if (!accountSortingPriority?.length) {
          return Math.abs(b.endingValue) - Math.abs(a.endingValue)
        }
        for (let i = 0; i < accountSortingPriority.length; i++) {
          const { key, dir = 'DESC' } = accountSortingPriority[i]
          if (a[key] !== b[key]) {
            return dir === 'DESC'
              ? Math.abs(b[key]) - Math.abs(a[key])
              : Math.abs(a[key]) - Math.abs(b[key])
          }
        }
        return 0
      })
      return subgroup
    }).sort((rowA, rowB) => {
      if (splitAssetClassTagIds.includes(rowA.assetClassTagId)) {
        return 1
      }
      if (splitAssetClassTagIds.includes(rowB.assetClassTagId)) {
        return -1
      }
      if (rowA.ordinal && rowB.ordinal && rowA.ordinal !== rowB.ordinal) {
        return rowA.ordinal - rowB.ordinal
      }
      return rowB.total - rowA.total
    })

    return group
  })
}

/**
 * Normalize The Full VBS Info
 * @param data {VisualBalanceGroup[]}
 * @param sections {SectionParams}
 * @param splitAssetClassTagIds {number[]}
 * @param accountSortingPriority {{key: string, dir: string}[]}
 * @return {{core: VisualBalanceGroup[], side: VisualBalanceGroup[], other: VisualBalanceGroup[], bottom: VisualBalanceGroup[]}}
 */
export const normalizeData = (data, sections, splitAssetClassTagIds, accountSortingPriority) => {
  const returnSections = {
    core: [],
    bottom: [],
    side: [],
    other: []
  }

  Object.keys(returnSections).forEach(key => {
    returnSections[key] = data?.filter(row => {
      if (key === 'core') {
        return sections.core
          ? sections.core?.includes(row.groupId)
          : ![sections.bottom,
            sections.side,
            sections.other].flat().includes(row.groupId)
      }
      return sections[key]?.includes(row.groupId)
    })

    returnSections[key] = normalizeSectionData(returnSections[key], splitAssetClassTagIds, accountSortingPriority)
  })

  return returnSections
}
