import React, { useCallback, useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core'
import { isString } from 'lodash'
import { useGroupingContext } from '../GroupingContext'
import CheckBoxItem from '../../../../molecules/CheckBoxItem'
import { modifyByIndex } from '../../../../../utils'

const useStyles = makeStyles(() => ({
  item: {
    padding: 0,
    marginBottom: '0.5rem'
  }
}))

const mapColumnHiddenProps = (columns) => {
  return columns.map((column) => {
    const hiddenProps = column.getToggleHiddenProps()
    const label =
      (isString(column.Header) && column.Header) ||
      (isString(column.header) && column.header)
    return {
      id: column.id,
      label,
      checked: hiddenProps.checked
    }
  })
}

const CustomizeColumnsToggler = () => {
  const classes = useStyles()
  const { performanceTableProps, setPerformanceTableProps } =
    useGroupingContext()

  const [columnItems, setColumnItems] = useState(
    mapColumnHiddenProps(performanceTableProps?.allColumns || [])
  )

  const onChangeItem = useCallback((id, checked) => {
    setColumnItems((prevState) => {
      const item = prevState.find(({ id: _id }) => _id === id)
      const itemIndex = prevState.indexOf(item)
      return modifyByIndex(itemIndex, prevState, { ...item, checked })
    })
  }, [])

  useEffect(() => {
    setPerformanceTableProps((prevState) => ({
      ...prevState,
      columnHiddenProps: [...columnItems]
    }))
  }, [columnItems, setPerformanceTableProps])

  return columnItems.map(({ id, label, checked }) => (
    <CheckBoxItem
      key={id}
      checked={checked}
      containerClassName={classes.item}
      onChange={(event) => onChangeItem(id, event.target.checked)}
    >
      {label}
    </CheckBoxItem>
  ))
}

export default CustomizeColumnsToggler
