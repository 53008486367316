import React from 'react'
import { useHistory } from 'react-router-dom'
import { ROLES } from '../../../../../policies/admin'
import { useCheckPolicy } from '../../../../../hooks'
import { ADMIN_ROUTES, USER_TYPE } from '../../../../../constants'
import EmptySection from '../../../../atoms/EmptySection'
import RoleNewCreateForm from './RoleNewCreateForm'

function AdvisorRoleNew () {
  const history = useHistory()
  const canCreate = useCheckPolicy(ROLES.editAdvisorRoles)

  if (!canCreate) {
    return (
      <EmptySection
        title='Access Denied'
        description='You do not have sufficient permissions to access this resource'
      />
    )
  }

  return (
    <>
      <RoleNewCreateForm
        title='Create a new role'
        onSave={(roleId) => history.replace(`${ADMIN_ROUTES.ROLES}/${roleId}`)}
        onCancel={(roleId) => history.replace(`${ADMIN_ROUTES.ROLES}`)}
        userTypeId={USER_TYPE.ADVISOR}
      />
    </>
  )
}

export default AdvisorRoleNew
