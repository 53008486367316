import React, { useCallback, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { FormProvider, useForm } from 'react-hook-form'
import { Box, makeStyles } from '@material-ui/core'
import { isEmpty } from 'lodash'
import { manageAccountClientsAssociation } from '../../../../../service.js'
import ListContainer from '../../../../molecules/ListContainer'
import { useBoolean } from '../../../../../hooks'
import { ICON_NAMES, TABLE_VARIANTS } from '../../../../../constants'
import CheckBoxList from '../../../../organisms/CheckBoxList'
import EmptySection from '../../../../atoms/EmptySection'
import SearchBarInput from '../../../../molecules/SearchBar/SearchBarInput'
import Text from '../../../../atoms/Text'
import { TableVirtualizedWithDynamicHeight } from '../../../../molecules/Table'
import Icon from '../../../../atoms/Icon'
import Skeleton from '../../../../atoms/Skeleton'
import ClientAvatar from '../../../../atoms/ClientAvatar'
import FadeIn from '../../../../molecules/Transitions/FadeIn'
import SectionHeader from '../../shared/SectionHeader'
import { CLIENT_LABELS, clientRowsMapper } from '../helpers'
import SubmitButtons from '../SubmitButtons'
import EditButton from '../../shared/EditButton'
import { useAccountFormContext } from '../AccountFormProvider'

const useStyles = makeStyles(() => ({
  titleAdornment: {
    display: 'flex',
    color: '#9296A5',
    flexDirection: 'row',
    '& > span': {
      color: '#5A5E6D',
      marginLeft: '0.25rem'
    }
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },
  checkBoxList: {
    maxHeight: '20rem',
    overflowY: 'auto'
  },
  checkBoxItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%'
  },
  checkboxItemAvatar: {
    marginRight: '0.5rem'
  },
  checkboxItemTitle: {
    fontWeight: '600',
    fontSize: '1rem',
    color: '#141929'
  },
  selectedItemContainer: {
    display: 'flex',
    alignItems: 'center',
    border: '2px solid #EEF0F8',
    borderRadius: '6px',
    padding: '0.75rem 1rem'
  },
  spaceDivider: {
    minHeight: '1rem',
    width: '100%'
  },
  addContainer: {
    height: 'unset !important'
  }
}))

const renderClientRowRightItem = (onClickRemove) => {
  return (
    <Box display='flex' flexDirection='row' alignItems='center'>
      {onClickRemove && (
        <Box ml='1rem' style={{ cursor: 'pointer' }} onClick={onClickRemove}>
          <Icon
            name={ICON_NAMES.removeCircle}
            color='#D44333'
            customSize='1.25rem'
          />
        </Box>
      )}
    </Box>
  )
}

const mapClientRows = clientRowsMapper(renderClientRowRightItem)

const AccountClientsAssignment = ({
  loading,
  onSave,
  clientsAssigned,
  selectedClients,
  onSearchClients,
  clientsUnAssigned,
  searchResultOptions,
  onAssignUnAssignClient,
  setDisableEdit
}) => {
  const { account, cancelEdit, ...accountForm } = useAccountFormContext()
  const sectionEditing = accountForm.sectionEditing('assigned_clients')
  const editMode = sectionEditing

  const classes = useStyles({ editMode })
  const [formLoading, setFormLoading] = useBoolean()
  const [searchMode, setSearchMode] = useBoolean()

  useEffect(() => {
    return () => setDisableEdit.off()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const formMethods = useForm({
    mode: 'onChange',
    defaultValues: {}
  })

  const {
    handleSubmit,
    formState: { isValid }
  } = formMethods

  const onSubmit = useCallback(
    async () => {
      try {
        setFormLoading.on()
        const { assigned, unAssigned } = selectedClients
        await manageAccountClientsAssociation(account.accountId, {
          associateClientIds: assigned.map(({ id }) => id),
          removeClientIds: unAssigned.map(({ id }) => id)
        })
        onSave()
      } catch (err) {
        console.error(err)
      } finally {
        setDisableEdit.off()
        cancelEdit()
        setFormLoading.off()
      }
    },
    [
      selectedClients,
      setFormLoading,
      cancelEdit,
      account,
      onSave,
      setDisableEdit
    ]
  )

  const onCancel = useCallback(() => {
    setDisableEdit.off()
    cancelEdit()
  }, [cancelEdit, setDisableEdit])

  const renderCheckboxBody = useCallback(
    (_, client) => {
      return (
        <div className={classes.checkBoxItem}>
          <ClientAvatar
            client={client}
            src={client.imageUrl}
            customSize='2rem'
            customClassName={classes.checkboxItemAvatar}
          />
          <Text text={client.name} className={classes.checkboxItemTitle} />
        </div>
      )
    },
    [
      classes.checkBoxItem,
      classes.checkboxItemTitle,
      classes.checkboxItemAvatar
    ]
  )

  const renderUnAssignedClientsChecked = useMemo(() => {
    const clients = selectedClients.assigned

    return clients.map((client, index) => {
      const isLastItem = clients.length - 1 === index
      const { id, name } = client

      return (
        <React.Fragment key={id}>
          <div className={classes.selectedItemContainer}>
            <ClientAvatar
              client={client}
              customSize='2rem'
            />
            <Box ml='1rem'>
              <Text text={name} customFontWeight='600' />
            </Box>
            <Box ml='auto'>
              {renderClientRowRightItem(() =>
                onAssignUnAssignClient({
                  client,
                  clientId: id,
                  isAssign: false
                })
              )}
            </Box>
          </div>
          {!isLastItem && <div className={classes.spaceDivider} />}
        </React.Fragment>
      )
    })
  }, [
    classes.spaceDivider,
    onAssignUnAssignClient,
    selectedClients.assigned,
    classes.selectedItemContainer
  ])

  const renderAddClientsBody = useMemo(() => {
    if (searchMode) {
      if (!isEmpty(searchResultOptions)) {
        return (
          <CheckBoxList
            defaultItems={searchResultOptions}
            onItemChange={(id, checked, payload) =>
              onAssignUnAssignClient({
                clientId: id,
                isAssign: checked,
                client: payload
              })}
            renderCheckboxBody={renderCheckboxBody}
          />
        )
      } else if (clientsUnAssigned.isEmpty) {
        return (
          <EmptySection
            title='No Clients Found'
            description=''
            extraStyles={{ padding: '1rem' }}
          />
        )
      }
    }
    return renderUnAssignedClientsChecked
  }, [
    searchMode,
    renderCheckboxBody,
    searchResultOptions,
    onAssignUnAssignClient,
    clientsUnAssigned.isEmpty,
    renderUnAssignedClientsChecked
  ])

  const clientsAssignedRows = useMemo(() => {
    if (!editMode) {
      return mapClientRows({ clients: clientsAssigned.data, editMode: false })
    }
    const stagingClients = [
      ...selectedClients.unAssigned,
      ...selectedClients.assigned
    ].reduce(
      (acc, client) => ({
        ...acc,
        [client.id]: { ...client }
      }),
      {}
    )
    const clients = clientsAssigned.data.filter(
      ({ id }) => !stagingClients[id]
    )
    const clientRows = mapClientRows({
      clients,
      editMode: true,
      onAssignUnAssign: onAssignUnAssignClient
    })
    return clientRows
  }, [editMode, selectedClients, clientsAssigned.data, onAssignUnAssignClient])

  const onClickClient = useCallback(
    (_, row) => {
      if (editMode) return
      const rowConfig = row[row.length - 1] || {}
      const url = `${window.location.origin}/admin/clients/${rowConfig?.valueId}`
      window.open(url, '_blank')
    },
    [editMode]
  )

  const renderClientsAssignedTable = useMemo(() => {
    const clientsTable = (
      <TableVirtualizedWithDynamicHeight
        rows={clientsAssignedRows}
        onRowClick={onClickClient}
        elementType='div'
        minHeight='30rem'
        virtualized
        variant={TABLE_VARIANTS.floatingHeader}
        labels={CLIENT_LABELS}
        isLoading={clientsAssigned.loading}
        emptySection={
          <EmptySection
            title='No Clients Assigned Yet'
            description='Use the search bar above to add clients. Once you’ve added
clients, they will appear here.'
            styles={{ height: '100%' }}
          />
        }
      />
    )
    if (editMode) {
      return (
        <ListContainer title='ASSIGNED CLIENTS'>
          {clientsTable}
        </ListContainer>
      )
    }
    return clientsTable
  }, [clientsAssignedRows, onClickClient, clientsAssigned, editMode])

  if (loading) {
    return (
      <Skeleton
        width='100%'
        height='200px'
        className={classes.skeleton}
      />
    )
  }

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FadeIn className={classes.column}>
          <SectionHeader text='Assigned Clients'>
            <EditButton
              policy='admin_edit_acct_client'
              editing={sectionEditing}
              onClick={() => accountForm.editSection({
                section: 'assigned_clients'
              })}
            />
            <div className={classes.titleAdornment}>
              <Text text={clientsAssigned.total || '0'} />
              <span>
                <Text text='Total' />
              </span>
            </div>
          </SectionHeader>
          {editMode && (
            <ListContainer
              title='ADD CLIENTS'
              onClickAway={setSearchMode.off}
              containerClassName={classes.addContainer}
            >
              <>
                <SearchBarInput
                  onChange={onSearchClients}
                  onFocus={setSearchMode.on}
                  loading={clientsUnAssigned.loading}
                  placeholder='Search by client name'
                />
                <div className={classes.checkBoxList}>{renderAddClientsBody}</div>
              </>
            </ListContainer>
          )}
          <div className={classes.spaceDivider} />
          {renderClientsAssignedTable}
        </FadeIn>
        <SubmitButtons
          editMode={editMode}
          onCancel={onCancel}
          isSubmitting={formLoading}
          isFormValid={isValid}
        />
      </form>
    </FormProvider>
  )
}

AccountClientsAssignment.propTypes = {
  loading: PropTypes.bool,
  onSave: PropTypes.func,
  onSearchClients: PropTypes.func,
  selectedClients: PropTypes.object,
  clientsAssigned: PropTypes.object,
  clientsUnAssigned: PropTypes.object,
  searchResultOptions: PropTypes.array,
  onAssignUnAssignClient: PropTypes.func,
  setDisableEdit: PropTypes.object
}

export default AccountClientsAssignment
