import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { makeStyles, Tooltip } from '@material-ui/core'
import { useOktaAuth } from '@okta/okta-react'
import { groupBy, isEmpty, noop } from 'lodash'
import InsertEmoticonIcon from '@material-ui/icons/InsertEmoticon'
import clsx from 'clsx'
import { useAppContext } from '../../redux/slices/appContext'
import {
  createReplyReaction,
  createThreadReaction,
  deleteReplyReaction,
  deleteThreadReaction
} from '../../service'
import Text from '../atoms/Text'
import { useToggle, useAuthState } from '../../hooks'
import { EMOJI_PICKER_THEMES } from '../../constants'
import { noteReactions } from '../../prop-types'
import EmojiPicker from './EmojiPicker'

const useStyles = makeStyles(() => ({
  container: {
    margin: '0.5rem 0rem',
    display: 'flex',
    flexDirection: 'row'
  },
  reactionContainer: {
    height: '1.5rem',
    width: '2.75rem',
    display: 'flex',
    lineHeight: '1px',
    flexDirection: 'row',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    borderRadius: '1rem',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '1rem',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.12)',
      cursor: 'pointer'
    },
    marginRight: '0.25rem'
  },
  reactionCounter: {
    lineHeight: '1px',
    marginLeft: '0.25rem'
  },
  addReactionContainer: {
    '& > svg': {
      width: '1.25rem'
    }
  },
  reactionDisabled: {
    opacity: '0.75',
    cursor: 'not-allowed',
    '&:hover': {
      backgroundColor: 'white !important',
      cursor: 'not-allowed'
    }
  }
}))

const NoteReactionsSection = ({
  isReply,
  replyId,
  threadId,
  reactions,
  onAddReaction,
  onRemoveReaction,
  disableReactionAddition
}) => {
  const classes = useStyles()
  const appContext = useAppContext()
  const { authState: oktaAuthState } = useOktaAuth()
  const authState = useAuthState(oktaAuthState)
  const [emojiPickerOpen, toggleEmojiPicker, , toggleEmojiPickerOff] = useToggle()

  const onReactionClick = useCallback(
    async ({ reaction, reactionsGrouped = [] }) => {
      try {
        toggleEmojiPickerOff()
        const reactedBy = reactionsGrouped.find(({ createdBy, createdByName }) =>
          appContext.userId === createdBy && createdByName === (authState.idToken?.claims?.name || ''))

        if (reactedBy) {
          const { reactionId } = reactedBy
          if (isReply && replyId) {
            onRemoveReaction({ replyId, reactionId })
            await deleteReplyReaction(replyId, reactionId)
          } else if (threadId) {
            onRemoveReaction({ threadId, reactionId })
            await deleteThreadReaction(threadId, reactionId)
          }
        } else if (isEmpty(reactions.find(({ reaction: _reaction, createdBy }) => {
          return _reaction === reaction && appContext.userId === createdBy
        }))) {
          const reactionBody = {
            reaction,
            clientId: appContext.clientId,
            createdBy: appContext.userId,
            createdByName: authState.idToken?.claims?.name || ''
          }

          if (isReply && replyId) {
            const { data: replyReaction } = await createReplyReaction(replyId, reactionBody)
            onAddReaction({ replyId, reaction: replyReaction })
          } else if (threadId) {
            const { data: threadReaction } = await createThreadReaction(threadId, reactionBody)
            onAddReaction({ threadId, reaction: threadReaction })
          }
        }
      } catch (err) {
        console.error(err)
      }
    },
    [
      isReply,
      replyId,
      threadId,
      reactions,
      authState,
      onAddReaction,
      onRemoveReaction,
      appContext.userId,
      appContext.clientId,
      toggleEmojiPickerOff
    ]
  )

  const reactionsGroupedByUser = useMemo(() => {
    return !isEmpty(reactions)
      ? Object
        .entries(groupBy(reactions, 'reaction'))
        .map(([reaction, reactions]) => {
          const createdByNames = reactions.map(val => val.createdByName)
          return {
            reaction,
            counter: reactions.length,
            tooltip: `${reactions.length === 1
              ? createdByNames.join(',')
              : createdByNames.join(', ').replace(/, ([^,]*)$/, ' and $1')} reacted`,
            reactions: reactions
          }
        })
      : []
  }, [reactions])

  return (
    <div className={classes.container}>
      {reactionsGroupedByUser.map((reactionData) => {
        const { reaction, counter, tooltip, reactions: reactionsGrouped } = reactionData
        return (
          <Tooltip key={reaction} title={tooltip} placement='top'>
            <span
              key={reaction}
              onClick={() => onReactionClick({ reaction, reactionsGrouped })}
              className={classes.reactionContainer}
            >
              {reaction}
              {counter > 1 && (
                <Text
                  customFontWeight='bold'
                  customFontSize='0.75rem'
                  text={counter}
                  className={classes.reactionCounter}
                />
              )}
            </span>
          </Tooltip>
        )
      })}
      {!isEmpty(reactionsGroupedByUser) &&
        <EmojiPicker
          open={emojiPickerOpen}
          toggle={disableReactionAddition ? noop : toggleEmojiPicker}
          theme={EMOJI_PICKER_THEMES.light}
          onSelect={(reaction) => onReactionClick({ reaction: reaction.native })}
          childrenContainerClassName={classes.emojiIconContainer}
        >
          <Tooltip title='Add reaction...' placement='top'>
            <span className={clsx(classes.addReactionContainer, classes.reactionContainer, {
              [classes.reactionDisabled]: disableReactionAddition
            })}
            >
              <InsertEmoticonIcon />
            </span>
          </Tooltip>
        </EmojiPicker>}
    </div>
  )
}

NoteReactionsSection.propTypes = {
  isReply: PropTypes.bool.isRequired,
  replyId: PropTypes.number,
  threadId: PropTypes.number,
  reactions: noteReactions,
  onAddReaction: PropTypes.func,
  onRemoveReaction: PropTypes.func,
  disableReactionAddition: PropTypes.bool
}

NoteReactionsSection.defaultProps = {
  replyId: undefined,
  threadId: undefined,
  reactions: [],
  onAddReaction: noop,
  onRemoveReaction: noop,
  disableReactionAddition: false
}

export default NoteReactionsSection
