import React from 'react'
import ComponentsOfChangePivotTableWrapper, { componentsOfChangePivotTableWrapper } from './TableWrapper'

const ComponentsOfChangePivotTable = (props) => {
  return <ComponentsOfChangePivotTableWrapper {...props} />
}

ComponentsOfChangePivotTable.propTypes = componentsOfChangePivotTableWrapper

export default ComponentsOfChangePivotTable
