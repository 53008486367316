import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import DefaultLayout from '../../../../abundanceEngine/layouts/DefaultLayout'

const useStyles = makeStyles(() => ({
  dynamicPlanningPage: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: 'calc(100vh - 60px)',
    padding: '2em 0',
    width: '100%',
    margin: '0 auto'
  }
}))

const Page = ({ children, style }) => {
  const classes = useStyles()

  return (
    <DefaultLayout>
      <div className={classes.dynamicPlanningPage} style={style}>
        {children}
      </div>
    </DefaultLayout>
  )
}

Page.propTypes = {
  children: PropTypes.node,
  style: PropTypes.object
}

export default Page
