import React, { createContext, useContext, useState } from 'react'
import PropTypes from 'prop-types'

const ReportFiltersContext = createContext()

export const useReportFiltersContext = () => {
  const context = useContext(ReportFiltersContext)
  return context
}

const ReportFiltersProvider = ({ children }) => {
  const [queryText, setQueryText] = useState('')
  const [columnFiltersVisibility, setColumnFilterVisibility] = useState({})

  return (
    <ReportFiltersContext.Provider
      value={{
        queryText,
        setQueryText,
        columnFiltersVisibility,
        setColumnFilterVisibility
      }}
    >
      {children}
    </ReportFiltersContext.Provider>
  )
}

ReportFiltersProvider.propTypes = {
  children: PropTypes.node
}

export default ReportFiltersProvider
