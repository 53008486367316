import React, { useCallback, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Controller, useForm } from 'react-hook-form'
import SydLabel from '../../../../commonDesign/SydLabel'
import Select from '../../../../molecules/Select'
import SydButton from '../../../../commonDesign/Button'
import { useModifyStatusTemplateItemMutation } from '../../../../../api/groups'
import { useDialogStyles } from './common'

const useSubmitter = (form, onComplete, templateItem, statusTemplate) => {
  const { handleSubmit } = form
  const [processing, setProcessing] = useState(false)
  const { mutateAsync: modifyItem } = useModifyStatusTemplateItemMutation()
  const onSubmit = useCallback(async (formData) => {
    const parent = statusTemplate.items.find(x => x.statusTemplateItemId === formData.parent)
    const path = parent ? [...parent.path, parent.codeName] : []

    const command = {
      item: {
        ...templateItem,
        path
      }
    }

    try {
      setProcessing(true)
      const result = await modifyItem(command)
      onComplete(result)
    } finally {
      setProcessing(false)
    }
  }, [modifyItem, setProcessing, onComplete, templateItem, statusTemplate])

  const submitter = useCallback(async (e) => {
    const onValid = async (form) => {
      await onSubmit(form)
    }
    const onInvalid = (errors) => {
      console.error(errors)
    }

    const handler = handleSubmit(onValid, onInvalid)
    await handler(e)
  }, [handleSubmit, onSubmit])

  return {
    submitter,
    processing
  }
}

function MoveTemplateItemForm ({ templateItem, statusTemplate, onCancel, onComplete }) {
  const classes = useDialogStyles()

  const [parentOptions, currentParent] = useMemo(() => {
    const items = [...statusTemplate.items].sort((a, b) => a.displayName.localeCompare(b.displayName))
    const parentOptions = items.filter(x => x.codeName !== templateItem.codeName).map(ti => ({
      label: ti.displayName,
      value: ti.statusTemplateItemId
    }))
    parentOptions.push({
      label: 'Root',
      value: -1
    })

    const parentCode = templateItem?.path?.length ? templateItem.path.at(-1) : null
    const currentParent = statusTemplate.items.find(x => x.codeName === parentCode)?.statusTemplateItemId || -1

    return [parentOptions, currentParent]
  }, [statusTemplate, templateItem])

  const form = useForm({
    mode: 'onChange',
    defaultValues: {
      parent: currentParent
    }
  })

  const { submitter, processing } = useSubmitter(form, onComplete, templateItem, statusTemplate)

  return (
    <>
      <div className={classes.form}>
        <Controller
          name='parent' control={form.control}
          render={(f => (
            <SydLabel label='Parent Item'>
              <Select fullWidth variant='outlined-rounded' options={parentOptions} {...f.field} />
            </SydLabel>
          ))}
        />
      </div>
      <div className={classes.actions}>
        <SydButton disabled={processing} variant='ghost' size='lg' onClick={onCancel}>Cancel</SydButton>
        <SydButton
          variant='primary'
          size='lg'
          onClick={submitter}
          processing={processing}
        >
          Save
        </SydButton>
      </div>
    </>
  )
}

MoveTemplateItemForm.propTypes = {
  templateItem: PropTypes.object,
  statusTemplate: PropTypes.object,
  onComplete: PropTypes.func,
  onCancel: PropTypes.func
}

export default MoveTemplateItemForm
