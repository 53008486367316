import React, { useCallback, useMemo, useEffect } from 'react'
import noop from 'lodash/noop'
import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'
import { useTheme } from '@material-ui/core'
import DocumentVaultEmptyState from '../../molecules/DocumentVaultEmptyState'
import DocumentVaultUploadFilePreview from '../../molecules/DocumentVaultUploadFilePreview'
import DragAndDropInputArea from '../../molecules/DragAndDropInputArea'
import { DOCUMENT_VAULT_ACCEPTED_FILES } from '../../../constants'
import { useFileEvents, useValidator } from './hooks'

const DocumentVaultFileUpload = ({
  children,
  clientId,
  containerStyle,
  showBackButton,
  backButtonLabel,
  onBackClick,
  onFinish,
  onFilesChange,
  persistDropRejected,
  hasChildren
}) => {
  const theme = useTheme()

  const {
    files,
    setFiles,
    onDeleteFile,
    onFileAccepted
  } = useFileEvents(clientId)

  useEffect(() => {
    onFilesChange(files)
  }, [files, onFilesChange])

  const containerStyles = useMemo(
    () => ({
      position: 'absolute',
      inset: 0,
      ...(!isEmpty(files)
        ? {
          borderWidth: 1,
          borderStyle: 'solid',
          borderColor: theme.palette.gray.darker
        }
        : {}),
      ...((hasChildren && isEmpty(files)) || !hasChildren
        ? containerStyle
        : {})
    }),
    [files, theme, hasChildren, containerStyle]
  )

  const onDragAndDropValidator = useValidator(files)

  const onFinishHandler = useCallback((uploadedDocuments) => {
    setFiles([])
    onFinish(uploadedDocuments)
  }, [onFinish, setFiles])

  const renderDocumentEmptyState = useCallback(
    ({ openUploadFileWindow, persistFileRejectionError, fileRejections }) => (
      <DocumentVaultEmptyState
        onBackClick={onBackClick}
        backButtonLabel={backButtonLabel}
        showBackButton={showBackButton}
        fileRejections={fileRejections}
        openUploadFileWindow={openUploadFileWindow}
        showFileRejectionsMessage={persistFileRejectionError}
      />
    ),
    [onBackClick, showBackButton, backButtonLabel]
  )

  const renderLoadedDocuments = useCallback(
    ({
      openUploadFileWindow,
      isDragActive,
      isDragAccept,
      fileRejections,
      persistFileRejectionError
    }) => (
      <DocumentVaultUploadFilePreview
        files={files}
        clientId={clientId}
        onFinish={onFinishHandler}
        fileRejections={fileRejections}
        onDeleteFile={onDeleteFile}
        openUploadFileWindow={openUploadFileWindow}
        showFileRejectionsMessage={persistFileRejectionError}
        showPreviewPlaceHolder={isDragActive && isDragAccept}
      />
    ),
    [files, clientId, onFinishHandler, onDeleteFile]
  )

  const renderInputAreaContent = useMemo(() => {
    if (hasChildren && isEmpty(files) && children !== undefined) {
      return children
    }
    return isEmpty(files) ? renderDocumentEmptyState : renderLoadedDocuments
  }, [hasChildren, children, files, renderDocumentEmptyState, renderLoadedDocuments])

  return (
    <DragAndDropInputArea
      validator={onDragAndDropValidator}
      acceptedFileTypes={DOCUMENT_VAULT_ACCEPTED_FILES}
      onDropAccepted={onFileAccepted}
      containerStyle={containerStyles}
      persistDropRejected={persistDropRejected}
    >
      {renderInputAreaContent}
    </DragAndDropInputArea>
  )
}

DocumentVaultFileUpload.propTypes = {
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  clientId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  containerStyle: PropTypes.object,
  showBackButton: PropTypes.bool,
  backButtonLabel: PropTypes.string,
  onBackClick: PropTypes.func,
  onFinish: PropTypes.func,
  onFilesChange: PropTypes.func,
  persistDropRejected: PropTypes.bool,
  hasChildren: PropTypes.bool
}

DocumentVaultFileUpload.defaultProps = {
  children: undefined,
  clientId: undefined,
  containerStyle: undefined,
  showBackButton: false,
  backButtonLabel: 'Back to files',
  onBackClick: noop,
  onFinish: noop,
  onFilesChange: noop,
  persistDropRejected: true,
  hasChildren: false
}

export default DocumentVaultFileUpload
