import React from 'react'
import { useHistory } from 'react-router-dom'
import { Security } from '@okta/okta-react'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { QueryClientProvider } from '@tanstack/react-query'
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js'
import defaultQueryClient from './api/defaultQueryClient'
import ErrorBoundary from './components/molecules/ErrorBoundary'
import config from './config'
import App from './App'
import LoginRouter from './LoginRouter'

const issuer = config.subdomain ? `https://${config.subdomain}.${config.issuerRoot}` : `https://${config.issuerRoot}`
console.trace('subdomain', config.subdomain, 'issuer', issuer)

const oktaAuth = new OktaAuth({
  ...config.oktaAuthConfig,
  authorizeUrl: `${issuer}/v1/authorize`,
  logoutUrl: `${issuer}/v1/logout`,
  issuer: `https://${config.issuerRoot}`
})

function OktaApp () {
  const history = useHistory()

  const customAuthHandler = () => {
    history.push('/login')
  }

  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    history.replace(toRelativeUrl(originalUri, window.location.origin))
  }

  return (
    <ErrorBoundary name='Okta Application level' refresh>
      <Security
        oktaAuth={oktaAuth}
        onAuthRequired={config.useCustomLogin ? customAuthHandler : undefined}
        restoreOriginalUri={restoreOriginalUri}
      >
        <QueryClientProvider client={defaultQueryClient}>
          <ReactQueryDevtools initialIsOpen={false} />
          {config.useCustomLogin ? <App /> : <LoginRouter />}
        </QueryClientProvider>
      </Security>
    </ErrorBoundary>
  )
}

export default OktaApp
