import React from 'react'
import PropTypes from 'prop-types'
import SydButton from '../../../../../commonDesign/Button'
import { useFormattingContext } from '../../../../../organisms/FormattingProvider/FormattingContext'
import Section from '../shared/Section'
import { useClientReviewContext } from '../ClientReviewContext'
import { useCheckPolicy } from '../../../../../../hooks'
import { ACCOUNTS } from '../../../../../../policies/admin'

function AccountInfo ({ account }) {
  const { formatter } = useFormattingContext()
  const cr = useClientReviewContext()
  const canEditBillingStartDate = useCheckPolicy(ACCOUNTS.editBillingStartDate)

  if (!account) {
    return null
  }

  return (
    <Section>
      <header>{account.accountName}</header>
      <div>
        <table>
          <tbody>
            <tr>
              <td>Acct #</td>
              <td>{account.accountNumber}</td>
            </tr>
            <tr>
              <td>As of date</td>
              <td>{formatter(account.asOfDate, 'date')}</td>
              <td />
            </tr>
            <tr>
              <td>Last Data Source Date</td>
              <td>{formatter(account.lastDataSourceDate, 'date')}</td>
              <td />
            </tr>
            <tr>
              <td>Billing Start Date</td>
              <td>{formatter(account.billingStartDate, 'date')}</td>
              <td>
                {canEditBillingStartDate ? (
                  <SydButton size='xs' icon='edit' onClick={() => cr.dialogs.editBillingStartDate(account)}>Edit</SydButton>
                ) : null}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </Section>
  )
}

AccountInfo.propTypes = {
  account: PropTypes.any
}

export default AccountInfo
