import React, { createContext, useContext, useMemo } from 'react'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import PropTypes from 'prop-types'
import { isEmpty } from 'lodash'
import { useParameterReplacement } from '../../../../hooks/useAbundanceEngineParameters'
import { useSearchKeyDatesMultiple } from '../../../../api/keyDates'
import { useAdvisorHome } from '../AdvisorHomeContext'

dayjs.extend(utc)

const KeyDatesContext = createContext()

const nonRecurringKeyDatesQuery = ({ startDate, endDate, limit, extraFilters }) => ({
  includes: extraFilters?.includes || {},
  filters: {
    keyDate: [
      { op: 'gte', value: startDate },
      { op: 'lte', value: endDate }
    ],
    levelTypeId: [{ op: 'in', value: [200, 201] }], // persons, clients
    recurring: [{ op: 'eq', value: 0 }],
    ...(extraFilters?.filters || {})
  },
  take: limit
})

const recurringKeyDatesQuery = ({
  limit,
  endMonth,
  startMonth,
  extraFilters,
  endDayOfMonth,
  startDayOfMonth
}) => ({
  includes: extraFilters?.includes || {},
  filters: {
    keyDateMonth: [
      { op: 'gte', value: startMonth },
      { op: 'lte', value: endMonth }
    ],
    keyDateDay: [
      { op: 'gte', value: startDayOfMonth },
      { op: 'lte', value: endDayOfMonth }
    ],
    levelTypeId: [{ op: 'in', value: [200, 201] }], // persons, clients
    recurring: [{ op: 'eq', value: 1 }],
    ...(extraFilters?.filters || {})
  },
  sort: [{ field: 'keyDateMonth', dir: 'asc' }, { field: 'keyDateDay', dir: 'asc' }],
  take: limit
})

const mapKeyDates = (data) => {
  return data.map((item) => {
    const contact = item?.client || item?.person
    const contactName =
      contact?.clientLongName ||
      contact?.longName ||
      `${contact?.firstName || ''}${contact?.lastName ? ' ' + contact?.lastName : ''}` ||
      ''

    const contactProfilePic = contact?.clientProfilePic || contact?.profilePic
    const contactAbbreviation = contact?.clientAbbreviation

    return {
      dateType: item.name,
      dateText: item.name,
      dateNow: item.keyDate,
      iconName: item.iconName,
      contactName,
      contactProfilePic,
      contactAbbreviation,
      avatarUrl: contact.avatarUrl
    }
  })
}

export const useKeyDates = ({
  limit,
  endDate,
  startDate,
  useRawData,
  extraFilters
}) => {
  const { query, queryOptions } = useMemo(() => {
    const startUtc = dayjs.utc(startDate)
    const endUtc = dayjs.utc(endDate)

    const startDayOfMonth = startUtc.date()
    const endDayOfMonth = endUtc.date()

    const startMonth = startUtc.month() + 1
    const endMonth = endUtc.month() + 1

    return {
      query: {
        recurring: recurringKeyDatesQuery({
          limit,
          endMonth,
          startMonth,
          extraFilters,
          endDayOfMonth,
          startDayOfMonth
        }),
        nonrecurring: nonRecurringKeyDatesQuery({
          limit,
          endDate,
          startDate,
          extraFilters
        })
      },
      queryOptions: {
        mapper: (data) => {
          const {
            recurring: recurringData,
            nonrecurring: nonrecurringData
          } = data || {}
          const recurring = recurringData?.data || []
          const nonrecurring = nonrecurringData?.data || []
          const keyDates = [...recurring, ...nonrecurring]

          return {
            recurring,
            nonrecurring,
            data: useRawData ? keyDates : mapKeyDates(keyDates)
          }
        }
      }
    }
  }, [
    startDate,
    endDate,
    limit,
    useRawData,
    extraFilters
  ])

  const { data, isLoading } = useSearchKeyDatesMultiple(query, queryOptions)
  return { ...data, isLoading }
}

const KeyDatesContextProvider = ({
  children: _children,
  useRawData,
  startDate,
  endDate,
  limit,
  extraFilters
}) => {
  const [start, end] = useParameterReplacement([startDate, endDate])
  const { scope } = useAdvisorHome()

  const filters = useMemo(() => {
    return {
      ...(!isEmpty(scope?.values)
        ? { groupId: [{ op: 'in', value: scope?.values }] }
        : {}),
      ...extraFilters
    }
  }, [extraFilters, scope?.values])

  const keyDateParams = useMemo(() => ({
    startDate: start,
    endDate: end,
    limit,
    useRawData,
    extraFilters: filters
  }), [end, filters, limit, start, useRawData])

  const context = useKeyDates(keyDateParams)

  const children = useMemo(() => {
    return typeof _children === 'function' ? children(context) : _children
  }, [_children, context])

  return (
    <KeyDatesContext.Provider value={context}>
      {children}
    </KeyDatesContext.Provider>
  )
}

export const useKeyDatesContext = () => {
  const context = useContext(KeyDatesContext)
  return context
}

KeyDatesContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
  startDate: PropTypes.string,
  useRawData: PropTypes.bool,
  endDate: PropTypes.string,
  limit: PropTypes.number,
  extraFilters: PropTypes.object
}

KeyDatesContextProvider.defaultProps = {
  useRawData: false,
  startDate: '$yearStart',
  endDate: '$in7days',
  limit: 5,
  extraFilters: {
    includes: {
      clients: true,
      persons: true
    }
  }
}

export default KeyDatesContextProvider
