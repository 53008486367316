import React from 'react'
import PropTypes from 'prop-types'
import NumberFormat from '../../../atoms/NumberFormat'
import Text from '../../../atoms/Text'

const CostBasisSummaryBlock = ({ tooltipTitle, value, title }) => {
  return (
    <div>
      <NumberFormat title={tooltipTitle}>
        <Text
          text={value}
          lineHeight='1'
          variant='body2'
          customFontWeight='bold'
          customFontSize='1.625rem'
        />
      </NumberFormat>
      <div>{title}</div>
    </div>
  )
}

CostBasisSummaryBlock.propTypes = {
  title: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  tooltipTitle: PropTypes.string
}

export default CostBasisSummaryBlock
