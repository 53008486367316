import React from 'react'
import { Link } from 'react-router-dom'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { first, isEmpty, last } from 'lodash'
import { capitalizeFirstLetter, removeByIndex } from '../../../../../utils'
import { UNASSIGNED_OPTION } from '../../Accounts/AccountsListTab/useGroupTypeColumns'

dayjs.extend(utc)

export const ADMIN_CLIENTS_PATH = '/admin/clients'

const cellTemplates = {
  /* eslint-disable-next-line jsx-a11y/anchor-is-valid */
  drilldownLink: (accessor) => ({ row }) => (
    <Link
      onClick={(event) => event.stopPropagation()}
      to={`${ADMIN_CLIENTS_PATH}/${row.original.clientId}`}
    >
      {accessor(row.original)}
    </Link>
  )
}
const longNameAccesor = (row) => row.longName

export const DYNAMIC_FILTER_PREFIX = 'dynamic_filter'

export const getFilterColumnId = (id = '') => `${DYNAMIC_FILTER_PREFIX}_${id}`

export const mapGroupTypeFilterId = (groupTypeId = '') =>
  getFilterColumnId(`groupType_${groupTypeId}`)

export const getDynamicColumnIds = (columns) => {
  return columns
    .filter((col) => col.startsWith(getFilterColumnId()))
    .map((col) => last(col.split('_')))
    .filter(Boolean)
}

export const COLUMN_IDS = {
  FEE_SCHEDULES: getFilterColumnId('feeSchedules'),
  BENCHMARKS: getFilterColumnId('benchmark')
}

export const defaultColumnConfig = {
  columns: [
    { Header: 'Client ID', accessor: 'clientId', id: 'clientId' },
    {
      Header: 'Long Name',
      accessor: longNameAccesor,
      id: 'longName',
      Cell: cellTemplates.drilldownLink(longNameAccesor)
    },
    { Header: 'Short Name', accessor: 'shortName', id: 'shortName' },
    { Header: 'Wealth Mission', accessor: 'description', id: 'description' },
    // { Header: 'Group Types', accessor: '', id: 'groupTypes' },
    {
      Header: 'Benchmark',
      accessor: ({ benchmarks }) => {
        return benchmarks
          ?.map(({ displayName, longName }) => displayName || longName)
          .join(',') || 'Unassigned'
      },
      id: COLUMN_IDS.BENCHMARKS,
      hidden: true
    },
    {
      Header: 'Fee Schedules',
      accessor: ({ feeSchedules }) => {
        return feeSchedules
          ?.map(({ shortName, longName }) => longName || shortName)
          .join(',') || 'Unassigned'
      },
      id: COLUMN_IDS.FEE_SCHEDULES,
      hidden: true
    },
    {
      Header: 'External ID',
      accessor: ({ integrations }) => {
        return (
          Object.entries(integrations || {}).reduce(
            (acc, [integrationKey, integrationId]) =>
              `${acc ? acc + ', ' : acc}${capitalizeFirstLetter(
                integrationKey
              )}: ${integrationId}`,
            '',
            ''
          ) || ''
        )
      },
      id: 'integrations',
      hidden: true
    }
  ],
  defaultSort: [{ id: 'longName', desc: false }]
}

export const mapGroupIdsToTagFilters = (groupIds, groupTypes) => {
  const groupTypeOptions = groupTypes.reduce(
    (acc, filter) => [
      ...acc,
      {
        groupIds: filter.groups.map(({ value }) => value),
        groupName: filter.label,
        groupTypeId: filter.value
      }
    ],
    []
  )
  return groupTypeOptions.reduce((acc, option) => {
    const groupOptionIds = option.groupIds.filter(
      (groupId) => groupIds.indexOf(groupId) !== -1
    )
    if (isEmpty(groupOptionIds)) return acc
    return [...acc, { ...option, groupIds: groupOptionIds }]
  }, [])
}

export const mapFlagFilter = (options) => {
  if (options.length === 1) {
    const value = first(options)?.value
    return value === 'assigned'
  }
  return undefined
}

export const transformTagFiltersToGroupQueryParams = (tagFilters) => {
  const queryParams = tagFilters.reduce((acc, { groupIds, groupTypeId }) => {
    const unassignedIndex = groupIds.indexOf(UNASSIGNED_OPTION.value)
    // If 'unassigned' option is not found, use the entire 'groupIds'.
    if (unassignedIndex === -1) {
      acc.groupIds.push({
        value: groupIds,
        op: 'in',
        combine: 'and'
      })
    } else {
      // Exclude 'unassigned' option and use the rest if any remain.
      const filteredGroupIds = removeByIndex(unassignedIndex, groupIds)
      if (filteredGroupIds.length > 0) {
        acc.groupIds.push({
          value: filteredGroupIds,
          op: 'in',
          combine: 'and'
        })
      }
      // if unassigned option is found, add missingGroupType filter
      acc.missingGroupType = [{
        value: [groupTypeId],
        op: 'in',
        combine: 'and'
      }]
    }
    return acc
  }, { groupIds: [], missingGroupType: undefined })

  return queryParams
}
