import React from 'react'
import PropTypes from 'prop-types'
import { Box, makeStyles } from '@material-ui/core'
import Text from '../../atoms/Text'
import FastAvatar from '../FastAvatar'

const useStyles = makeStyles(() => ({
  avatar: {
    position: 'inherit'
  },
  cell: {
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  }
}))

const ClientAvatarWithName = ({ client, avatarSize, onClick }) => {
  const classes = useStyles()

  return (
    <Box position='inherit' onClick={onClick}>
      <div className={classes.cell}>
        <FastAvatar
          size='md'
          avatarUrl={client?.avatarUrl}
          abbreviation={client?.clientAbbreviation}
        />
        <Box mr='0.5rem' />
        <Text text={client?.longName || client?.shortName} />
      </div>
    </Box>
  )
}

ClientAvatarWithName.propTypes = {
  client: PropTypes.object,
  onClick: PropTypes.func,
  avatarSize: PropTypes.string
}

ClientAvatarWithName.defaultProps = {
  avatarSize: '2.5rem'
}

export default ClientAvatarWithName
