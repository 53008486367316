import React from 'react'
import { Link } from 'react-router-dom'

function NotFound () {
  return (
    <div>
      <div>The view could not be found</div>
      <div>
        <Link to='/'>Go Home</Link>
      </div>
    </div>
  )
}

export default NotFound
