import React from 'react'
import PropTypes from 'prop-types'
import CustomizeColumnsDateRangeDialog from './CustomizeColumnsDateRangeDialog'
import CustomizeColumnsDragOrderToggler from './CustomizeColumnsDragOrderToggler'

export const CUSTOMIZE_COLUMNS_VARIANT = {
  DATE_RANGE_SELECTORS: 'DATE_RANGE_SELECTORS',
  VISIBILITY_TOGGLER_AND_DATE_RANGE_SELECTORS: 'V_AND_D',
  DND_VISIBILITY_TOGGLER: 'DnD_VISIBILITY_TOGGLER'
}

const {
  DATE_RANGE_SELECTORS,
  DND_VISIBILITY_TOGGLER,
  VISIBILITY_TOGGLER_AND_DATE_RANGE_SELECTORS
} = CUSTOMIZE_COLUMNS_VARIANT

const GroupingCustomizeColumnsDialog = ({ variant }) => {
  if (variant === DATE_RANGE_SELECTORS) {
    return (
      <CustomizeColumnsDateRangeDialog
        hideColumnHiding
        hideDateRangeSelectors={false}
      />
    )
  }

  if (variant === VISIBILITY_TOGGLER_AND_DATE_RANGE_SELECTORS) {
    <CustomizeColumnsDateRangeDialog
      hideColumnHiding={false}
      hideDateRangeSelectors={false}
    />
  }

  if (variant === DND_VISIBILITY_TOGGLER) {
    return <CustomizeColumnsDragOrderToggler />
  }

  return null
}

GroupingCustomizeColumnsDialog.propTypes = {
  variant: PropTypes.string
}

GroupingCustomizeColumnsDialog.defaultProps = {
  variant: CUSTOMIZE_COLUMNS_VARIANT.DATE_RANGE_SELECTORS
}

export default GroupingCustomizeColumnsDialog
