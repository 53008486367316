import React, { useCallback, useImperativeHandle, useState } from 'react'
import RoundedModal from '../../../../../molecules/RoundedModal'
import ModifyVisibilityForm from './form'

const ModifyVisibilityDialog = React.forwardRef(function ModifyVisibilityDialog (_, ref) {
  const [dialogState, setDialogState] = useState({ open: false, processing: false })
  const close = useCallback(() => {
    setDialogState({ open: false, processing: false })
  }, [setDialogState])

  const complete = useCallback(async () => {
    close()
  }, [close])

  useImperativeHandle(ref, () => ({
    open: (user) => setDialogState({ open: true, processing: false, user })
  }), [setDialogState])

  return (
    <RoundedModal
      title='Modify User Visibility'
      open={dialogState.open}
      onClose={close}
      position='top'
      disablePortal={false}
    >
      <div>
        {dialogState.open ? (<ModifyVisibilityForm user={dialogState.user} onComplete={complete} onCancel={close} />) : null}
      </div>
    </RoundedModal>
  )
})

ModifyVisibilityDialog.propTypes = {
}

export default ModifyVisibilityDialog
