import React from 'react'
import { Grid } from '@material-ui/core'
import PageContainer from '../components/PageContainer'
import DataHomeHeader from '../shared/DataHomeHeader'
import UploadForm from './UploadForm'

const UploadFile = () => {
  return (
    <PageContainer>
      <Grid item xs={12}>
        <DataHomeHeader>Upload File</DataHomeHeader>
      </Grid>
      <Grid item xs={12}>
        <UploadForm />
      </Grid>
    </PageContainer>
  )
}

export default UploadFile
