import React, { useCallback, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { CircularProgress, makeStyles } from '@material-ui/core'
import DoneIcon from '@material-ui/icons/Done'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined'
import LabelOutlinedIcon from '@material-ui/icons/LabelOutlined'
import CheckIcon from '@material-ui/icons/Check'
import CloseIcon from '@material-ui/icons/Close'
import noop from 'lodash/noop'
import clsx from 'clsx'
import { uploadDocument } from '../../service'
import { getFileExtension } from '../../utils'
import InputInlineEdit from './InputInlineEdit'
import InputSearchTagChip from './InputSearchTagChip'

const useStyles = makeStyles((theme) => ({
  container: ({ showPlaceHolder, progress, isDocumentUploaded }) => ({
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '0.5rem',
    backgroundColor: theme.palette.white,
    padding: '1rem',
    border: `1px solid ${theme.palette.gray.dark}`,
    width: '34rem',
    minHeight: '6.625rem',
    height: 'auto',
    position: 'relative',
    '& svg': {
      fontSize: '1.5rem !important'
    },
    ...(showPlaceHolder
      ? {
        backgroundColor: theme.palette.gray.dark,
        minHeight: '3rem',
        '& svg': {
          fontSize: '1.5rem !important',
          opacity: '0.3'
        }
      }
      : {}),
    ...(progress !== 0 && !isDocumentUploaded
      ? {
        background: `linear-gradient(to right, ${theme.palette.ghost} ${progress}%, #ffffff 0%)`
      }
      : {})
  }),
  row: {
    display: 'flex',
    flex: '1 1 50%',
    alignItems: 'center',
    zIndex: 2
  },
  cardPlaceHolder: {
    alignItems: 'end'
  },
  cardLoadingPlaceHolder: {
    alignSelf: 'center'
  },
  cardTitle: {
    marginRight: 'auto'
  },
  checkIcon: {
    color: theme.palette.emerald,
    marginRight: '0.5rem'
  },
  errorIcon: {
    color: theme.palette.error.main,
    marginRight: '0.5rem'
  },
  icon: {
    marginLeft: 'auto',
    cursor: 'pointer'
  }
}))

const DocumentVaultFileCard = ({
  file,
  tags,
  clientId,
  showPlaceHolder,
  showLoadingPlaceHolder,
  onAddTag,
  onDeleteFile,
  onUpdateDocument,
  documentSpecification
}) => {
  const [documentTitle, setDocumentTitle] = useState(getFileExtension(file?.name, true))
  const [documentUpload, setDocumentUpload] = useState({
    progress: null,
    uploaded: false,
    hasError: false,
    aborted: false
  })
  const [documentUploadRequest, setDocumentUploadRequest] = useState(null)

  const classes = useStyles({
    showPlaceHolder,
    progress: documentUpload.progress,
    isDocumentUploaded: documentUpload.uploaded
  })

  const documentSpecificationId = useMemo(
    () => documentSpecification?.id || null,
    [documentSpecification]
  )

  useEffect(() => {
    if (showPlaceHolder || !documentSpecificationId) return
    const isDocumentUploadCompleted = documentUpload.progress === 100
    const isDocumentUploadFailed =
      documentUpload.aborted || documentUpload.hasError
    if (isDocumentUploadCompleted || isDocumentUploadFailed) {
      onUpdateDocument({
        id: documentSpecificationId,
        uploadCompleted: isDocumentUploadCompleted,
        cancelled: isDocumentUploadFailed
      })
    }
  }, [
    showPlaceHolder,
    onUpdateDocument,
    documentUpload.hasError,
    documentUpload.progress,
    documentUpload.aborted,
    documentSpecificationId
  ])

  useEffect(() => {
    if (showPlaceHolder) return
    const xhr = new XMLHttpRequest()
    setDocumentUploadRequest(xhr)
    return () => {
      if (xhr) {
        xhr.abort()
      }
    }
  }, [showPlaceHolder])

  const onUploadFileProgress = useCallback(
    (event) =>
      setDocumentUpload((prevState) => ({
        ...prevState,
        progress: (event.loaded / event.total) * 100
      })),
    []
  )

  const onAbortDocumentUpload = useCallback(
    () =>
      setDocumentUpload((prevState) => ({
        ...prevState,
        aborted: true,
        progress: 0
      })),
    []
  )

  const uploadDocumentFile = useCallback(
    async (file) => {
      if (!documentSpecification || !documentUploadRequest) return
      const { uploadUrl, name, tags, id } = documentSpecification
      try {
        await uploadDocument(
          uploadUrl,
          file,
          onUploadFileProgress,
          onAbortDocumentUpload,
          documentUploadRequest
        )
        setDocumentUpload((prevState) => ({
          ...prevState,
          uploaded: true
        }))
        onUpdateDocument({ id, name, tags })
      } catch (error) {
        setDocumentUpload({
          progress: 0,
          uploaded: false,
          hasError: true
        })
        console.error('There was an error trying to upload the file', error)
      } finally {
        setDocumentUploadRequest(null)
      }
    },
    [
      onUpdateDocument,
      onUploadFileProgress,
      onAbortDocumentUpload,
      documentUploadRequest,
      documentSpecification
    ]
  )

  const onChangeDocumentTitleHandler = useCallback(
    (e) => setDocumentTitle(({ name: e.target.value, extension: documentTitle.extension })),
    [documentTitle.extension]
  )

  const onEditDocumentTitleHandler = useCallback(
    (title) => {
      if (!documentSpecification) return
      onUpdateDocument({ id: documentSpecification.id, name: `${title.trim()}.${documentTitle.extension}` })
    },
    [documentTitle.extension, documentSpecification, onUpdateDocument]
  )

  const onEditTagsHandler = useCallback(
    (tags) => {
      if (!documentSpecification) return
      onUpdateDocument({ id: documentSpecification.id, tags })
    },
    [documentSpecification, onUpdateDocument]
  )

  useEffect(() => {
    uploadDocumentFile(file)
  }, [file, uploadDocumentFile])

  const renderDocumentUploadStatusIcon = useMemo(() => {
    if (documentUpload.hasError || documentUpload.aborted) {
      return <CloseIcon className={classes.errorIcon} />
    }
    if (documentUpload.uploaded) {
      return <CheckIcon className={classes.checkIcon} />
    }
    return null
  }, [documentUpload, classes.checkIcon, classes.errorIcon])

  if (showLoadingPlaceHolder) {
    return (
      <div className={classes.container}>
        <div className={clsx(classes.row, classes.cardLoadingPlaceHolder)}>
          <CircularProgress color='primary' />
        </div>
      </div>
    )
  }

  if (showPlaceHolder) {
    return (
      <div className={classes.container}>
        <div className={clsx(classes.row, classes.cardPlaceHolder)}>
          <DescriptionOutlinedIcon />
        </div>
      </div>
    )
  }

  return (
    <div className={classes.container}>
      <div className={classes.row}>
        <DescriptionOutlinedIcon />
        <InputInlineEdit
          showEditIcon
          icon={DoneIcon}
          value={documentTitle.name}
          placeHolderValue={`${documentTitle.name}.${documentTitle.extension}`}
          width='100%'
          onIconClickHandler={onEditDocumentTitleHandler}
          onChangeHandler={onChangeDocumentTitleHandler}
        />
        {renderDocumentUploadStatusIcon}
        {documentUploadRequest && !documentUpload.aborted ? (
          <CloseIcon
            className={classes.icon}
            onClick={(e) => documentUploadRequest.abort()}
          />
        ) : (
          <DeleteOutlineIcon className={classes.icon} onClick={onDeleteFile} />
        )}
      </div>
      <div className={classes.row}>
        <LabelOutlinedIcon hidden />
        <InputSearchTagChip
          options={tags}
          clientId={clientId}
          onAddTag={onAddTag}
          onChange={onEditTagsHandler}
        />
      </div>
    </div>
  )
}

DocumentVaultFileCard.propTypes = {
  clientId: PropTypes.number,
  file: PropTypes.object,
  tags: PropTypes.array,
  showPlaceHolder: PropTypes.bool,
  showLoadingPlaceHolder: PropTypes.bool,
  onAddTag: PropTypes.func,
  onDeleteFile: PropTypes.func,
  onUpdateDocument: PropTypes.func,
  documentSpecification: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    tags: PropTypes.arrayOf(PropTypes.number),
    clientId: PropTypes.number,
    uploadUrl: PropTypes.string
  })
}

DocumentVaultFileCard.defaultProps = {
  clientId: undefined,
  file: { name: '' },
  tags: [],
  showPlaceHolder: false,
  showLoadingPlaceHolder: false,
  onAddTag: noop,
  onDeleteFile: noop,
  onUpdateDocument: noop,
  documentSpecification: null
}

export default DocumentVaultFileCard
