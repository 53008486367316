import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  statusBadge: {
    margin: '0 1rem',
    padding: '.3rem',
    borderRadius: '1rem',
    backgroundColor: theme.palette.darkGrey,
    fontSize: '0.9rem',
    color: theme.palette.getContrastText(theme.palette.darkGrey)
  }
}))

const StatusBadge = ({ isActive }) => {
  const classes = useStyles()

  return (
    <span className={classes.statusBadge}>{isActive ? 'Active' : 'Inactive'}</span>
  )
}

StatusBadge.propTypes = {
  isActive: PropTypes.bool.isRequired
}

export default StatusBadge
