import React from 'react'
import FadeIn from '../../../../molecules/Transitions/FadeIn'
import ErrorBoundary from '../../../../molecules/ErrorBoundary'
import TargetAssignmentSection from './TargetAssignmentSection'

function TargetsTab () {
  return (
    <ErrorBoundary name='Targets Tab'>
      <FadeIn>
        <TargetAssignmentSection />
      </FadeIn>
    </ErrorBoundary>
  )
}

TargetsTab.propTypes = {
}

export default TargetsTab
