import React, { useMemo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useParams } from 'react-router-dom'
import dayjs from 'dayjs'
import { useIngestionPublishingRunById } from '../../../../api/ingestion'
import LabeledValue from '../components/LabeledValue'

const TIME_FORMAT = 'M/D/YYYY hh:mm:ss.SSS A z'
const useStyles = makeStyles((theme) => ({
  results: {
    padding: '1rem',
    '& > header': {
      borderBottom: '1px solid black',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between'
    },
    '& .__error': {
      color: theme.palette.error.primary
    }
  }
}))

const PublishingResultsIndex = () => {
  const classes = useStyles()
  const { resultId } = useParams()
  const { data, isLoading } = useIngestionPublishingRunById(resultId, { refetch: true })
  const headerText = useMemo(() => {
    return data?.extraData?.policy?.name || 'Unknown'
  }, [data])

  if (isLoading) {
    return (
      <div className={classes.results}>Loading...</div>
    )
  }

  return (
    <div className={classes.results}>
      <header>
        <h2>
          <span>{headerText}</span>
        </h2>
      </header>
      <main>
        <div>
          <LabeledValue label='Publishing Run ID'>{data.publishingRunId}</LabeledValue>
          <LabeledValue label='Create'>{data.isCreate ? 'Yes' : 'No'}</LabeledValue>
          <LabeledValue label='Update'>{data.isUpdate ? 'Yes' : 'No'}</LabeledValue>
          <LabeledValue label='Sequence ID'>{data.sequenceIdentifier}</LabeledValue>
        </div>
        {data?.extraData?.created && (
          <LabeledValue label='Created On'>{dayjs(data.extraData.created).format(TIME_FORMAT)}</LabeledValue>
        )}
        {data?.extraData?.exposureComplete && (
          <LabeledValue label='Expsoure Completed On'>{dayjs(data.extraData.exposureComplete).format(TIME_FORMAT)}</LabeledValue>
        )}
        {data?.extraData?.marked && (
          <LabeledValue label='Marked On'>{dayjs(data.extraData.marked).format(TIME_FORMAT)}</LabeledValue>
        )}
        {data?.extraData?.postProcessing && (
          <LabeledValue label='Post Processes Started On'>{dayjs(data.extraData.postProcessing).format(TIME_FORMAT)}</LabeledValue>
        )}
        {data?.extraData?.completed && (
          <>
            <LabeledValue label='Completed On'>{dayjs(data.extraData.completed).format(TIME_FORMAT)}</LabeledValue>
            <LabeledValue label='Create Count'>{data.extraData.createCount}</LabeledValue>
            <LabeledValue label='Update Count'>{data.extraData.updateCount}</LabeledValue>
          </>
        )}
        {data?.extraData?.error && (
          <div className='__error'>
            <h2>❗ERROR❗</h2>
            <pre>
              {data.extraData.error}
            </pre>
          </div>
        )}
      </main>
    </div>
  )
}

export default PublishingResultsIndex
