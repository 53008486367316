import React, { useMemo } from 'react'
import { Divider, makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import { useAvailableDates } from '../../../../redux/slices/appContext'
import { useGetGroupedCoreData } from '../../../../api/coreData'
import TableSkeleton from '../../../atoms/TableSkeleton'
import { CALC_TYPES, LEVEL_TYPES } from '../../../../constants'
import { getReturnStatus } from '../../../../utils'
import Text from '../../../atoms/Text'
import ClientCardBalanceIndicator from '../../ClientCardsViewV2/ClientCardView/ClientCard/ClientCardBalanceIndicator'
import { useAdvisorHome } from '../AdvisorHomeContext'
import { useFormattingContext } from '../../FormattingProvider/FormattingContext'

const useStyles = makeStyles(() => ({
  container: {
    height: '100%',
    width: '100%',
    padding: '1rem 1.5rem'
  },
  item: {
    display: 'flex',
    '& p': {
      fontWeight: 'bold'
    },
    '& .__title-container ': {
      fontSize: '0.875rem',
      color: '#141929'
    },
    '& .__title-copy': {
      color: '#666',
      fontSize: '0.875rem'
    },
    '& .__details': {
      marginLeft: 'auto',
      fontWeight: 'bold',
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'right'
    },
    '& .__indicator': {
      display: 'flex'
    }
  },
  divider: {
    margin: '1.125rem 0',
    color: '#E7E7E7'
  }
}))

const TopAssets = ({ limit }) => {
  const classes = useStyles()
  const { scope } = useAdvisorHome()
  const [availableDates] = useAvailableDates()
  // eslint-disable-next-line no-unused-vars
  const { formatter } = useFormattingContext()

  const query = useMemo(
    () => {
      const scopeFilters = {}
      if (scope) {
        scopeFilters.advisorIds = scope.values
      }

      return {
        dateRange: {
          startDate: availableDates.minDate,
          endDate: availableDates.mainDate
        },
        levelFilters: {
          limit: [limit],
          levelTypes: [LEVEL_TYPES.ASSETS, LEVEL_TYPES.ASSET_CLASS_TAG],
          calcType: CALC_TYPES.balance,
          orderBy: ['endingValue DESC'],
          ...scopeFilters
        }
      }
    },
    [scope, limit, availableDates]
  )

  const { data: assets, error, isLoading: loading } = useGetGroupedCoreData(query)

  if (loading) {
    return (
      <div className={classes.container}>
        <TableSkeleton showHeader={false} columns={1} rows={5} rowHeight='3rem' />
      </div>
    )
  }

  if (error) {
    return (
      <div className={classes.container}>
        <div>{error.toString()}</div>
      </div>
    )
  }

  return (
    <div className={classes.container}>
      {assets.map(
        ({
          assetId,
          assetName,
          endingValue,
          Symbol: symbol,
          returnValue,
          netChange: balanceChange
        }, index) => {
          const balanceChangeStatus = getReturnStatus(returnValue)
          const getAmountText = (valuePass) => {
            let formatPass = '0.0a'
            if (Math.abs(valuePass) >= 100000000 || (Math.abs(valuePass) >= 20000 && Math.abs(valuePass) < 1000000) || Math.abs(valuePass) < 1000) {
              formatPass = '0a'
            }
            return formatter(valuePass, formatPass)
          }
          return (
            <>
              <div key={assetId} className={classes.item}>
                <div className='__title-container'>
                  <Text text={assetName} />
                  <Text text={symbol} className='__title-copy' />
                </div>
                <div className='__details'>
                  <Text text={getAmountText(endingValue)} />
                  <div className='__indicator'>
                    <Text text={getAmountText(balanceChange)} />
                    <ClientCardBalanceIndicator
                      indicatorSize='1rem'
                      balanceChangeStatus={balanceChangeStatus}
                    />
                  </div>
                </div>
              </div>
              {index !== assets.length - 1 && <Divider className={classes.divider} />}
            </>
          )
        }
      )}
    </div>
  )
}

TopAssets.propTypes = {
  limit: PropTypes.number
}

TopAssets.defaultProps = {
  limit: 5
}

export default TopAssets
