import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import Icon from '../../../atoms/Icon'
import { useRequirementState } from './useRequirementValidator'

const useStyles = makeStyles((theme) => ({
  requirement: {
    display: 'flex',
    flexDirection: 'row',
    fontSize: theme.typography.fontSizes.xl,
    alignItems: 'baseline',
    lineHeight: '32px',
    gap: '10px',
    '& .__icon': {
      minWidth: '15px'
    }
  },
  pass: {
    color: theme.palette.success.main,
    stroke: theme.palette.success.main
  },
  fail: {
    color: theme.palette.danger.main,
    stroke: theme.palette.danger.main
  }
}))

const useRequirementLabel = (rule) => {
  return useMemo(() => {
    const ruleType = rule[0]
    const ruleTarget = rule[1]
    switch (ruleType) {
      case 'minLength':
        return `At least ${ruleTarget} characters`
      case 'minLowerCase':
        return `At least ${ruleTarget} lowercase letter(s)`
      case 'minUpperCase':
        return `At least ${ruleTarget} uppercase letter(s)`
      case 'minNumber':
        return `At least ${ruleTarget} number(s)`
      case 'minSymbol':
        return `At least ${ruleTarget} special character(s)`
      case 'excludeUserName':
        return 'No parts of your username'
      case 'excludeCommon':
        return 'No Common Passwords'
      case 'minAgeMinutes':
        return `Must be at least ${ruleTarget} minutes since your last password change`
      case 'historyCount':
        return `Cannot be the same as one of your ${ruleTarget} last password(s)`
      default:
        return ''
    }
  }, [rule])
}

function RequirementValidator ({ rule, value }) {
  const classes = useStyles()
  const requirementLabel = useRequirementLabel(rule)
  const requirementState = useRequirementState(rule, value)

  if (requirementState === 'ignore') return null

  return (
    <div className={classes.requirement}>
      <span className='__icon'>
        {requirementState !== 'defer' ? (
          requirementState === 'pass'
            ? <Icon name='check' additionalClasses={classes.pass} />
            : <Icon name='close' additionalClasses={classes.fail} />
        ) : null}
      </span>
      <span>{requirementLabel}</span>
    </div>
  )
}

RequirementValidator.propTypes = {
  rule: PropTypes.array,
  value: PropTypes.string
}

export default RequirementValidator
