import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import EmptySection from '../../../atoms/EmptySection'
import Icon from '../../../atoms/Icon'
import { ICON_NAMES } from '../../../../constants'

const useStyles = makeStyles(() => ({
  container: {
    borderRadius: '2rem',
    padding: '1rem',
    textAlign: 'center'
  },
  borderedContent: {
    borderRadius: '0.75rem',
    border: '2px solid #E9EAEF',
    minWidth: '16rem'
  }
}))

const AdvisorHomeEmptySection = ({
  icon,
  title,
  iconSize,
  className,
  description,
  borderedContent,
  displayHorizontally
}) => {
  const classes = useStyles()
  return (
    <div
      className={clsx(classes.container, {
        [classes.borderedContent]: borderedContent,
        [className]: Boolean(className)
      })}
    >
      <EmptySection
        header={
          icon &&
          icon in ICON_NAMES && <Icon name={icon} customSize={iconSize} />
        }
        title={title}
        description={description}
        displayHorizontally={displayHorizontally}
      />
    </div>
  )
}

AdvisorHomeEmptySection.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string,
  iconSize: PropTypes.string,
  className: PropTypes.string,
  description: PropTypes.string,
  borderedContent: PropTypes.bool,
  displayHorizontally: PropTypes.bool
}

AdvisorHomeEmptySection.defaultProps = {
  icon: '',
  title: '',
  iconSize: '5rem',
  description: '',
  borderedContent: false,
  displayHorizontally: false
}

export default AdvisorHomeEmptySection
