import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import { Box, makeStyles } from '@material-ui/core'
import Text from '../../../atoms/Text'
import WealthJourneyMilestoneCard from '../../../molecules/WealthJourneyMilestoneCard'
import WealthJourneyQueryLoader from '../components/WealthJourneyQueryLoader'

const useStyles = makeStyles((theme) => ({
  summary: {
    height: '100%',
    width: '95%',
    padding: '1rem'
  },
  milestoneContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(230px, max-content))',
    justifyContent: 'center',
    gridGap: '2rem'
  },
  milestoneClientHeader: {
    textAlign: 'center',
    margin: 'auto',
    maxWidth: '275px'
  }
}))

const queryKey = 'upcoming_milestones'
const UpcomingMilestonesSummary = ({ title, take }) => {
  const classes = useStyles()
  const query = useMemo(() => ({
    take,
    includes: {
      client: true
    },
    filters: {
      entryTypeId: [
        { op: 'eq', value: 1 }
      ],
      entryDate: [
        { op: 'gte', value: dayjs.utc().startOf('day').toISOString() }
      ]
    },
    sort: [
      { field: 'entryDate', dir: 'asc' }
    ]
  }), [take])

  return (
    <Box className={classes.summary}>
      <Box mb='1.5rem'>
        <Text variant='body2' customFontSize='1.2rem'>
          {title}
        </Text>
      </Box>
      <WealthJourneyQueryLoader queryKey={queryKey} query={query}>
        {(result) => (
          <div className={classes.milestoneContainer}>
            {result.data.map((milestone) => (
              <div key={milestone.entryId} className={classes.milestoneClientHeader}>
                <div>{milestone.client.longName}</div>
                <WealthJourneyMilestoneCard
                  key={milestone.entryId}
                  milestone={milestone}
                  selectable={false}
                />
              </div>
            ))}
          </div>
        )}
      </WealthJourneyQueryLoader>
    </Box>
  )
}

UpcomingMilestonesSummary.propTypes = {
  title: PropTypes.node,
  take: PropTypes.number
}

UpcomingMilestonesSummary.defaultProps = {
  title: 'Upcoming Milestones',
  take: 5
}

export default UpcomingMilestonesSummary
