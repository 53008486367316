import React, { useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import Drawer from '@material-ui/core/Drawer'
import { makeStyles } from '@material-ui/core/styles'
import { IconButton } from '@material-ui/core'
import clsx from 'clsx'
import { DRAWER_WIDTH, ICON_NAMES } from '../../constants'
import Icon from '../atoms/Icon'
import { clearSuppression, setSuppression } from '../../utils/globalSuppression'

const useStyles = makeStyles((theme) => ({
  content: ({ height }) => ({
    height: height || 'auto',
    overflow: 'auto'
  }),
  panel: ({ width }) => ({
    backgroundColor: theme.palette.common.white,
    overflowY: 'hidden',
    paddingTop: '5px',
    width: width || DRAWER_WIDTH
  }),
  panelTitle: {
    padding: '1rem 1.5rem',
    borderBottomStyle: 'solid',
    borderBottomWidth: '1px',
    borderColor: theme.palette.gray.dark,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '&.__large': {
      fontSize: '22px'
    }
  },
  closeButton: {
    marginLeft: 'auto'
  }
}))

const onClickHandler = (event) => {
  event.stopPropagation()
}

function RightPanel ({
  open,
  toggleOpen,
  title,
  subtitle,
  skipHeader,
  children,
  width,
  height,
  showCloseButton,
  contentClassName,
  titleClassName,
  suppressArrows
}) {
  const classes = useStyles({ height, width })
  const handleClose = useCallback((e) => {
    if (e.type === 'keydown' && e.key === 'Escape') {
      e.preventDefault()
      e.stopPropagation()
      toggleOpen()
    } else {
      toggleOpen()
    }
  }, [toggleOpen])

  useEffect(() => {
    if (open) {
      setSuppression('wheel', suppressArrows)
      return () => {
        clearSuppression('wheel')
      }
    }
  }, [open, suppressArrows])

  return (
    <Drawer
      classes={{ paper: classes.panel }}
      anchor='right'
      open={open}
      onClose={handleClose}
      onClick={onClickHandler}
      ModalProps={{
        BackdropProps: { invisible: true }
      }}
    >
      <>
        {!skipHeader && (
          <div className={clsx(classes.panelTitle, titleClassName)}>
            {title}
            {subtitle}
            {showCloseButton && (
              <IconButton
                aria-label='close'
                size='medium'
                className={classes.closeButton}
                onClick={toggleOpen}
              >
                <Icon name={ICON_NAMES.close} customSize='1.2rem' />
              </IconButton>
            )}
          </div>
        )}
        <div
          className={clsx(classes.content, {
            [contentClassName]: Boolean(contentClassName)
          })}
        >
          {children}
        </div>
      </>
    </Drawer>
  )
}

RightPanel.propTypes = {
  children: PropTypes.node.isRequired,
  height: PropTypes.string,
  open: PropTypes.bool.isRequired,
  skipHeader: PropTypes.bool,
  toggleOpen: PropTypes.func,
  title: PropTypes.node,
  subtitle: PropTypes.node,
  width: PropTypes.string,
  showCloseButton: PropTypes.bool,
  contentClassName: PropTypes.string,
  titleClassName: PropTypes.string,
  suppressArrows: PropTypes.bool
}

RightPanel.defaultProps = {
  height: undefined,
  skipHeader: false,
  title: undefined,
  subtitle: undefined,
  toggleOpen: undefined,
  width: undefined,
  showCloseButton: false,
  contentClassName: undefined,
  titleClassName: undefined,
  suppressArrows: true
}

export default RightPanel
