import { useCallback, useMemo } from 'react'
import { useInfiniteQuery, useMutation, useQueries, useQuery, useQueryClient } from '@tanstack/react-query'
import {
  postNamedCommand,
  postNamedQuery
} from '../service'
import { useAppContext } from '../redux/slices/appContext'
import { QUERY_KEYS } from './queryKeys'
import { useQueriesMaxResultVersion } from './helpers'

export const useListPlatformDataSources = () => {
  const { userId } = useAppContext()
  return useQuery({
    queryKey: [QUERY_KEYS.ingestionListPlatformDataSources, userId],
    queryFn: async () => {
      const { data } = await postNamedQuery('ingestion', 'listPlatformDataSources', {})
      return data
    }
  })
}

export const useListPlatformDataSourcesInvalidator = () => {
  const client = useQueryClient()
  return useCallback(async () => {
    try {
      await client.invalidateQueries({ queryKey: [QUERY_KEYS.ingestionListPlatformDataSources] })
    } catch (err) {
      console.error(err, 'failed to invalidate query')
    }
  }, [client])
}

export const useGetPlatformDataSourceById = (platformDataSourceId) => {
  const { userId } = useAppContext()
  return useQuery({
    queryKey: [QUERY_KEYS.ingestionGetPlatformDataSourceById, userId, platformDataSourceId],
    queryFn: async () => {
      const { data } = await postNamedQuery('ingestion', 'getPlatformDataSourceById', {
        platformDataSourceId
      })
      return data
    }
  })
}

export const useQueryPlatformDataSource = (query) => {
  const { userId } = useAppContext()
  return useQuery({
    queryKey: [QUERY_KEYS.ingestionQueryPlatformDataSource, userId, query],
    queryFn: async () => {
      const { data } = await postNamedQuery('ingestion', 'queryPlatformDataSource', query)
      return data
    }
  })
}

export const useQueryPlatformDataSources = (queries, options = {}) => {
  const { userId } = useAppContext()
  const { mapper = null, enabled } = options

  const queryMap = useMemo(() => {
    return queries.map((query) => {
      return {
        queryKey: [QUERY_KEYS.ingestionQueryPlatformDataSource, userId, query, query?.key],
        queryFn: async () => {
          if (!query) return null
          const { data } = await postNamedQuery(
            'ingestion',
            'queryPlatformDataSource',
            query
          )
          return query?.key ? { key: query.key, data } : data
        },
        select: mapper,
        enabled
      }
    })
  }, [enabled, mapper, queries, userId])

  const results = useQueries({ queries: queryMap })
  return useQueriesMaxResultVersion(results)
}

export const useListIngestionJobs = (options = {}) => {
  const { userId } = useAppContext()

  return useQuery({
    queryKey: [QUERY_KEYS.ingestionListJobs, userId],
    queryFn: async () => {
      const { data } = await postNamedQuery('ingestion', 'listIngestionJobs', {})
      return data
    }
  })
}

export const useSearchIngestionTargets = (query, options = {}) => {
  const { userId } = useAppContext()

  return useQuery({
    queryKey: [QUERY_KEYS.ingestionSearchTargets, userId, query],
    queryFn: async () => {
      const { data } = await postNamedQuery('ingestion', 'searchIngestionTargets', query)
      return data
    }
  })
}

export const useIngestionJobById = (ingestionJobId, options = {}) => {
  const { userId } = useAppContext()

  return useQuery({
    queryKey: [QUERY_KEYS.ingestionJobById, userId, ingestionJobId],
    queryFn: async () => {
      const { data } = await postNamedQuery('ingestion', 'getIngestionJobById', {
        ingestionJobId
      })
      return data
    }
  })
}

export const useIngestionJobByIdIncludeMappings = (ingestionJobId, options = {}) => {
  const { userId } = useAppContext()

  return useQuery({
    queryKey: [QUERY_KEYS.ingestionJobByIdWithPublishing, userId, ingestionJobId],
    queryFn: async () => {
      const { data } = await postNamedQuery('ingestion', 'getIngestionJobById', {
        ingestionJobId,
        include: {
          objectMappings: true
        }
      })
      return data
    }
  })
}

export const useObjectMappingById = (objectMappingId, options = {}) => {
  const { userId } = useAppContext()

  return useQuery({
    enabled: !!objectMappingId,
    queryKey: [QUERY_KEYS.ingestionObjectMappingById, userId, objectMappingId],
    queryFn: async () => {
      const { data } = await postNamedQuery('ingestion', 'getObjectMappingById', {
        objectMappingId
      })

      return data
    }
  })
}

export const usePublishingPolicySearch = (query, options = {}) => {
  const { userId } = useAppContext()

  return useQuery({
    queryKey: [QUERY_KEYS.ingestionPublishingPolicySearch, userId, query],
    queryFn: async () => {
      const { data } = await postNamedQuery('ingestion', 'searchPublishingPolicies', query)

      return data
    }
  })
}

export const useIngestionTargetById = (ingestionTargetId, options = {}) => {
  const { userId } = useAppContext()

  return useQuery({
    queryKey: [QUERY_KEYS.ingestionTargetById, userId, ingestionTargetId],
    queryFn: async () => {
      const { data } = await postNamedQuery('ingestion', 'getIngestionTargetById', {
        ingestionTargetId,
        includes: {
          publishingPolicies: true
        }
      })

      return data
    }
  })
}

export const useCountIngestionItemsToBeCreated = (ingestionTargetId, publishingPolicyId) => {
  const { userId } = useAppContext()

  return useQuery({
    queryKey: [QUERY_KEYS.ingestionCountCreatingItems, userId, ingestionTargetId, publishingPolicyId],
    queryFn: async () => {
      const { data } = await postNamedQuery('ingestion', 'getTargetsToBeCreated', {
        ingestionTargetId,
        publishingPolicyId,
        resultType: 'total'
      })

      return data?.total
    }
  })
}

export const useIngestionItemsToBeCreated = (ingestionTargetId, publishingPolicyId, take = 500) => {
  const { userId } = useAppContext()

  return useInfiniteQuery({
    queryKey: [QUERY_KEYS.ingestionPreviewCreatingItems, userId, ingestionTargetId, publishingPolicyId, take],
    queryFn: async ({ pageParam = 0 }) => {
      const { data } = await postNamedQuery('ingestion', 'getTargetsToBeCreated', {
        ingestionTargetId,
        publishingPolicyId,
        skip: pageParam,
        take
      })

      return data
    },
    getNextPageParam: (lastPage, pages) => lastPage.next
  })
}

export const useCountIngestionItemsToBeUpdated = (ingestionTargetId, publishingPolicyId) => {
  const { userId } = useAppContext()

  return useQuery({
    queryKey: [QUERY_KEYS.ingestionCountUpdatingItems, userId, ingestionTargetId, publishingPolicyId],
    queryFn: async () => {
      const { data } = await postNamedQuery('ingestion', 'getTargetsToBeUpdated', {
        ingestionTargetId,
        publishingPolicyId,
        resultType: 'total'
      })

      return data?.total
    }
  })
}

export const useIngestionItemsToBeUpdated = (ingestionTargetId, publishingPolicyId, take = 500) => {
  const { userId } = useAppContext()

  return useInfiniteQuery({
    queryKey: [QUERY_KEYS.ingestionPreviewUpdatingItems, userId, ingestionTargetId, publishingPolicyId, take],
    queryFn: async ({ pageParam = 0 }) => {
      const { data } = await postNamedQuery('ingestion', 'getTargetsToBeUpdated', {
        ingestionTargetId,
        publishingPolicyId,
        skip: pageParam,
        take
      })

      return data
    },
    getNextPageParam: (lastPage, pages) => lastPage.next
  })
}

export const useIngestionPublishMutation = () => {
  return useMutation({
    mutationFn: async ({ ingestionTargetId, publishingPolicyId }) => {
      const { data } = await postNamedCommand('ingestion', 'publish', {
        ingestionTargetId,
        publishingPolicyId,
        create: true,
        update: true,
        dryRun: false
      })

      return data
    }
  })
}

export const useIngestionStartJobMutation = () => {
  return useMutation({
    mutationFn: async ({ ingestionJobId }) => {
      const { data } = await postNamedCommand('ingestion', 'createIngestionBatch', {
        ingestionJobId,
        parameters: {},
        dryRun: false
      })

      return data
    }
  })
}

export const useIngestionRestartJobMutation = () => {
  return useMutation({
    mutationFn: async ({ ingestionBatchId }) => {
      const { data } = await postNamedCommand('ingestion', 'retryIngestionBatch', {
        ingestionBatchId
      })

      return data
    }
  })
}

export const useIngestionCancelJobMutation = () => {
  return useMutation({
    mutationFn: async ({ ingestionBatchId }) => {
      const { data } = await postNamedCommand('ingestion', 'cancelIngestionBatch', {
        ingestionBatchId
      })

      return data
    }
  })
}

export const useIngestionPublishingRunById = (publishingRunId, options = {}) => {
  const { userId } = useAppContext()

  return useQuery({
    queryKey: [QUERY_KEYS.ingestionPublishingRunById, userId, publishingRunId],
    queryFn: async () => {
      const { data } = await postNamedQuery('ingestion', 'getPublishingRunById', {
        publishingRunId
      })

      return data
    },
    refetchInterval: (data) => {
      if (data?.extraData?.error) return undefined
      if (data?.extraData?.completed) return undefined
      if (options.refetch) return options?.interval || 5000

      return undefined
    }
  })
}

export const useIngestionBatchById = (ingestionBatchId, options = {}) => {
  const { userId } = useAppContext()

  return useQuery({
    queryKey: [QUERY_KEYS.ingestionBatchById, userId, ingestionBatchId],
    queryFn: async () => {
      const { data } = await postNamedQuery('ingestion', 'getIngestionBatchById', {
        ingestionBatchId
      })

      return data
    },
    refetchInterval: (data) => {
      if (data?.status === 'error') return undefined
      if (data?.status === 'finished') return undefined
      if (options.refetch) return options?.interval || 5000

      return undefined
    }
  })
}

export const useIngestionBatchStatus = (query = null, options = {}) => {
  const { userId } = useAppContext()

  return useQuery({
    queryKey: [QUERY_KEYS.ingestionBatchStatus, userId, query],
    queryFn: async () => {
      const { data } = await postNamedQuery('ingestion', 'getBatchStatus', query || {})

      return data
    },
    refetchInterval: () => {
      if (options.refetch) return options?.interval || 30000

      return undefined
    }
  })
}
