import React, { useMemo } from 'react'
import dayjs from 'dayjs'
import numeral from 'numeral'

export const cellTemplates = {
  /* eslint-disable-next-line jsx-a11y/anchor-is-valid */
  date: ({ value }) => dayjs.utc(value).format('M/D/YYYY'),
  number: ({ value }) => value,
  // eslint-disable-next-line eqeqeq
  units: ({ value }) => numeral(value).format('0,0.00'),
  money: ({ value }) => numeral(value).format('0,0.00'),
  percentage: ({ value }) => numeral(value).format('0.00%'),
  tags: ({ value }) => (
    <span>
      {value.map((x) => (
        <span
          style={{
            border: '1px solid gray',
            padding: '2px',
            marginRight: '2px'
          }}
          key={x.transactionCodeTagId}
        >
          {x.displayName}
        </span>
      ))}
    </span>
  ),
  noWrap: ({ value }) => <span style={{ whiteSpace: 'nowrap' }}>{value}</span>
}

export const cellTemplateWrapper = (cellTemplate, decorators) => {
  const { useSymbolForZeroOrNull } = decorators
  return ({ value, ...row }) => {
    let valueFormatted = value
    if (useSymbolForZeroOrNull) {
      valueFormatted = !value ? useSymbolForZeroOrNull : value
      if (valueFormatted !== value) {
        return cellTemplates.noWrap({ value: valueFormatted })
      }
    }
    return cellTemplate({ ...row, value: valueFormatted })
  }
}

export const defaultColumnConfig = {
  client: {
    columns: [
      { Header: 'Client Id', accessor: 'clientId' },
      { Header: 'Name', accessor: 'clientName' },
      { Header: 'As Of', accessor: 'maxAsOfDate', template: 'date' },
      { Header: 'Account Count', accessor: 'exposedAccountCount', alignRight: true },
      { Header: 'Market Value', accessor: r => r.balance?.endingValue, id: 'endingValue', alignRight: true, template: 'money' },
      { Header: 'Net Gain', accessor: r => r.performance?.netChange, id: 'netChange', alignRight: true, template: 'money' },
      { Header: 'Return', accessor: r => r.performance?.cumulativeReturn, id: 'return', alignRight: true, template: 'percentage' }
    ],
    defaultSort: [{ id: 'return', desc: true }]
  },
  account: {
    columns: [
      { Header: 'Account Id', accessor: 'accountId' },
      { Header: 'Name', accessor: 'accountName' },
      { Header: 'Number', accessor: 'accountNumber' },
      { Header: 'Custodian', accessor: 'custodian' },
      { Header: 'As Of', accessor: 'asOfDate', template: 'date' },
      { Header: 'Market Value', accessor: r => r.balance?.endingValue, id: 'endingValue', alignRight: true, template: 'money' },
      { Header: 'Net Gain', accessor: r => r.performance?.netChange, id: 'netChange', alignRight: true, template: 'money' },
      { Header: 'Return', accessor: r => r.performance?.cumulativeReturn, id: 'return', alignRight: true, template: 'percentage' }
    ],
    defaultSort: [{ id: 'return', desc: true }]
  }
}

export const getExposureColumnConfig = (dataSetType = 'balance', useSymbolForZeroOrNull) => ({
  client: {
    columns: [
      { Header: 'Client ID', accessor: 'clientId' },
      { Header: 'Client Name', accessor: 'clientName' },
      { Header: 'As of Date', accessor: 'maxAsOfDate', template: 'date' },
      { Header: 'Account Count', accessor: 'exposedAccountCount', alignRight: true },
      { Header: 'Allocation', accessor: r => r.allocation, id: 'allocation', alignRight: true, template: 'percentage' },
      ...(
        dataSetType === 'balance'
          ? [
            { Header: 'Ending Value', accessor: r => r.exposedBalance?.endingValue, id: 'endingValue', alignRight: true, template: 'money' },
            { Header: 'Units', accessor: r => r.exposedBalance?.units, id: 'units', alignRight: true, template: 'money' }
          ]
          : [
            { Header: 'Ending Value', accessor: r => r.exposedBalance?.endingValue, id: 'endingValue', alignRight: true, template: 'money' },
            { Header: 'Units', accessor: r => r.exposedBalance?.units, id: 'units', alignRight: true, template: 'money' },
            { Header: 'Net Gain', accessor: r => r.exposedPerformance?.netChange, id: 'netChange', alignRight: true, template: 'money' },
            { Header: 'Return', accessor: r => r.exposedPerformance?.cumulativeReturn, id: 'return', alignRight: true, template: 'percentage' }
          ]
      )
    ],
    decorators: { useSymbolForZeroOrNull },
    defaultSort: [{ id: 'return', desc: true }]
  },
  account: {
    columns: [
      { Header: 'Account ID', accessor: 'accountId' },
      { Header: 'Account Number', accessor: 'accountNumber' },
      { Header: 'Account Name', accessor: 'accountName' },
      { Header: 'Custodian', accessor: 'custodian' },
      { Header: 'Account Category', accessor: 'accountCategory' },
      { Header: 'As of Date', accessor: 'asOfDate', template: 'date' },
      { Header: 'Allocation', accessor: r => r.allocation, id: 'allocation', alignRight: true, template: 'percentage' },
      ...(
        dataSetType === 'balance'
          ? [
            { Header: 'Ending Value', accessor: r => r.exposedBalance?.endingValue, id: 'endingValue', alignRight: true, template: 'money' },
            { Header: 'Units', accessor: r => r.exposedBalance?.units, id: 'units', alignRight: true, template: 'money' }
          ]
          : [
            { Header: 'Ending Value', accessor: r => r.exposedBalance?.endingValue, id: 'endingValue', alignRight: true, template: 'money' },
            { Header: 'Units', accessor: r => r.exposedBalance?.units, id: 'units', alignRight: true, template: 'money' },
            { Header: 'Net Gain', accessor: r => r.exposedPerformance?.netChange, id: 'netChange', alignRight: true, template: 'money' },
            { Header: 'Return', accessor: r => r.exposedPerformance?.cumulativeReturn, id: 'return', alignRight: true, template: 'percentage' }
          ]
      )
    ],
    decorators: { useSymbolForZeroOrNull },
    defaultSort: [{ id: 'return', desc: true }]
  }
})

export const useColumns = (levelType, columnConfig = defaultColumnConfig) => {
  return useMemo(() => {
    const { columns, defaultSort, decorators } = (columnConfig[levelType] || { columns: [] })
    const templatedColumns = columns.map(c => {
      if ('template' in c) {
        const template = cellTemplateWrapper(cellTemplates[c.template], decorators)
        return {
          ...c,
          Cell: template
        }
      }
      return c
    })

    return {
      columns: templatedColumns,
      defaultSort
    }
  }, [levelType, columnConfig])
}
