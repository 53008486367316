import { ClickAwayListener, makeStyles } from '@material-ui/core'
import React from 'react'
import PropTypes from 'prop-types'
import { noop } from 'lodash'
import clsx from 'clsx'
import { ICON_NAMES } from '../../constants'
import Text from '../atoms/Text'
import Icon from '../atoms/Icon'
import { useToggle } from '../../hooks'

const useStyles = makeStyles(() => ({
  container: {
    width: '100%'
  },
  header: {
    background: '#F6F6F6',
    borderRadius: '4px',
    marginBottom: '1rem',
    textAlign: 'left',
    padding: '0.5rem 1rem',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    '& > svg': {
      marginLeft: '0.5rem',
      marginBottom: '0.15rem'
    }
  },
  title: {
    fontSize: '0.875rem',
    color: '#141929',
    fontWeight: '600'
  },
  content: ({ showAddButton, focused, noPadding }) => ({
    border: '2px solid #E9EAEF',
    borderRadius: '6px',
    padding: noPadding ? 0 : '1.8rem',
    ...(showAddButton ? { marginBottom: '1rem' } : {}),
    ...(focused
      ? {
        background: '#FFFFFF',
        border: '2px solid #212945',
        boxShadow: '0px 4px 16px rgba(18, 16, 43, 0.2)',
        borderRadius: '6px'
      }
      : {})
  }),
  addButton: {
    width: '100%',
    background: '#EEF0F8',
    borderRadius: '4px',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0.75rem',
    '& > svg': {
      fontSize: '1rem',
      marginLeft: '0.625rem'
    }
  },
  buttonLabel: {
    fontSize: '1rem',
    color: '#141929',
    fontWeight: '600'
  }
}))

const ListCard = ({
  title,
  children,
  noPadding,
  onAddClick,
  showAddButton,
  addButtonLabel,
  containerClassName,
  disableContainerHighlight
}) => {
  const [focused, , toggleFocusedOn, toggleFocusedOff] = useToggle()
  const classes = useStyles({
    showAddButton,
    noPadding,
    focused: focused && !disableContainerHighlight
  })

  return (
    <div
      className={clsx(classes.container, {
        [containerClassName]: !!containerClassName
      })}
    >
      <div className={classes.header}>
        <Text text={title} className={classes.title} />
        <Icon name={ICON_NAMES.info} />
      </div>
      <ClickAwayListener onClickAway={toggleFocusedOff}>
        <div onClick={toggleFocusedOn}>
          <div className={classes.content}>{children}</div>
          {showAddButton && (
            <div className={classes.addButton} onClick={onAddClick}>
              <Text text={addButtonLabel} className={classes.buttonLabel} />
              <Icon name={ICON_NAMES.addCircle} customSize='1.125rem' />
            </div>
          )}
        </div>
      </ClickAwayListener>
    </div>
  )
}

ListCard.defaultProps = {
  title: '',
  children: null,
  onAddClick: noop,
  noPadding: false,
  showAddButton: false,
  addButtonLabel: 'Add',
  containerClassName: '',
  disableContainerHighlight: false
}

ListCard.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  noPadding: PropTypes.bool,
  onAddClick: PropTypes.func,
  showAddButton: PropTypes.bool,
  addButtonLabel: PropTypes.string,
  containerClassName: PropTypes.string,
  disableContainerHighlight: PropTypes.bool
}

export default ListCard
