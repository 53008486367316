import React, { useCallback, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import isEmpty from 'lodash/isEmpty'

const useStyles = makeStyles(() => ({
  tableContainer: ({ height, minHeight }) => ({
    height: height || '15rem',
    width: '100%',
    minHeight: minHeight || 'unset'
  }),
  container: {
    height: '100%',
    display: 'flex',
    flex: '1',
    flexDirection: 'column'
  }
}))

const withDynamicHeight = (TableComponent) => {
  const TableVirtualizedWithDynamicHeight = ({
    rows,
    height,
    minHeight,
    virtualizeProps,
    ...props
  }) => {
    const classes = useStyles({ height, minHeight })
    const [containerRect, setContainerRect] = useState()

    const containerRef = useCallback((node) => {
      if (node !== null) {
        setContainerRect(node.parentElement.getBoundingClientRect())
      }
    }, [])

    const virtualizedTableContainerHeight = useMemo(() => {
      if (!isEmpty(rows)) {
        const dataLength = rows.length
        const {
          rowHeight,
          useParentHeight,
          tableHeaderHeight,
          maxRowsBeforeScroll
        } = virtualizeProps || {}

        if (useParentHeight && containerRect) {
          return containerRect.height
        }

        const tableHeight =
          dataLength > maxRowsBeforeScroll
            ? rowHeight * maxRowsBeforeScroll
            : dataLength * rowHeight

        return tableHeight + tableHeaderHeight
      }
    }, [rows, virtualizeProps, containerRect])

    return (
      <div
        ref={containerRef}
        className={classes.tableContainer}
        style={{ height: height || virtualizedTableContainerHeight }}
      >
        <div className={classes.container}>
          <TableComponent
            {...props}
            virtualized
            elementType='div'
            virtualizeProps={virtualizeProps}
            rows={rows}
          />
        </div>
      </div>
    )
  }

  TableVirtualizedWithDynamicHeight.propTypes = {
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    minHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    rows: PropTypes.array,
    virtualizeProps: PropTypes.object
  }

  TableVirtualizedWithDynamicHeight.defaultProps = {
    virtualizeProps: {
      rowHeight: 58,
      tableHeaderHeight: 70,
      maxRowsBeforeScroll: 10,
      useParentHeight: true
    }
  }

  return TableVirtualizedWithDynamicHeight
}

withDynamicHeight.propTypes = {
  TableComponent: PropTypes.node
}

export default withDynamicHeight
