import React from 'react'
import ConfigurableView from '../../ConfigurableView'
import BillingClientReview from './BillingClientReview'

function ClientReviewIndex () {
  return (
    <ConfigurableView viewPath='@admin-billing-client-review'>
      <BillingClientReview />
    </ConfigurableView>
  )
}

export default ClientReviewIndex
