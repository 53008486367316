import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import clsx from 'clsx'

const useStyles = makeStyles(() => ({
  container: {
    height: '100%',
    width: '100%',
    padding: '0.5rem',
    display: 'flex',
    flexDirection: 'column',
    gap: '0.625rem'
  }
}))

const FilterContainer = ({ children, className }) => {
  const classes = useStyles()
  const classNames = clsx(classes.container, { [className]: Boolean(className) })
  return <div className={classNames}>{children}</div>
}

FilterContainer.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
}

export default FilterContainer
