import React, { useCallback, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'
import { makeStyles } from '@material-ui/core'
import AdvisorHomeEmptySection from '../../../AdvisorHome/AdvisorHomeEmptySection'
import { ICON_NAMES } from '../../../../../constants'
import OperationalTable, { useOperationalTable } from '../../../OperationalTable'
import { useTasksContext } from '../../TasksContext'
import useTasks from '../../hooks/useTasks'
import TasksSearchBar from '../TasksSearchBar'
import { useColumnConfig } from './columnConfig'

const useStyles = makeStyles(() => ({
  container: {
    '& table[role="table"]': {
      minWidth: 'unset !important'
    },
    '& table > thead > tr > th': {
      backgroundColor: '#f8f8f8',
      padding: '12px',
      // marginBottom: '12px',
      verticalAlign: 'bottom',
      fontSize: '0.85rem'
    },
    '& table > tbody > tr > td': {
      verticalAlign: 'top',
      padding: '18px 12px',
      fontSize: '0.85rem'
    },
    '& table > thead': {
      boxShadow: 'none'
    }
  }
}))

const TasksTable = ({ columnConfig }) => {
  const { columns, defaultSort } = useColumnConfig(columnConfig)

  const {
    sort,
    paginationData,
    emptyStateLabel,
    overdueStartDate,
    setPaginationData,
    relativeDateRanges,
    excludeTaskStatuses,
    selectedFeaturedFilter
  } = useTasksContext()

  const classes = useStyles()
  const [queryText, setQueryText] = useState('')

  const tasksQueryConfig = useMemo(
    () => ({
      sort,
      queryText,
      overdueStartDate,
      relativeDateRanges,
      excludeTaskStatuses,
      pagination: paginationData,
      featuredFilter: selectedFeaturedFilter
    }),
    [
      sort,
      queryText,
      paginationData,
      overdueStartDate,
      relativeDateRanges,
      excludeTaskStatuses,
      selectedFeaturedFilter
    ]
  )

  const { tasks, tasksTotal, isLoading } = useTasks(tasksQueryConfig)

  const {
    defaultPageSize,
    onTableModeChange,
    onSortingChange: _onSortingChange
  } = useOperationalTable({
    defaultSort,
    defaultPageSize: paginationData.take
  })

  const onPagingChange = useCallback(
    ({ pageSize, pageIndex }) => {
      setPaginationData((prevState) => ({
        ...prevState,
        skip: pageSize * pageIndex
      }))
    },
    [setPaginationData]
  )

  const onSortingChange = useCallback(
    (sortBy) => {
      _onSortingChange(sortBy)
      const sort = sortBy.map(({ id, desc }) => ({
        field: id,
        dir: desc ? 'desc' : 'asc'
      }))
      setPaginationData((prevState) => ({
        ...prevState,
        sort: sort
      }))
    },
    [_onSortingChange, setPaginationData]
  )

  return (
    <>
      <TasksSearchBar onChange={setQueryText} isLoading={isLoading} />
      <OperationalTable.Wrapper className={classes.container}>
        {!isLoading && isEmpty(tasks) ? (
          <AdvisorHomeEmptySection
            title='No tasks'
            iconSize='10rem'
            icon={ICON_NAMES.paperTasks}
            description={emptyStateLabel}
          />
        ) : (
          <OperationalTable
            mode='auto'
            itemName='Tasks'
            columns={columns}
            data={tasks}
            loading={isLoading}
            total={tasksTotal}
            defaultSort={defaultSort}
            autoLimit={defaultPageSize}
            onPagingChange={onPagingChange}
            defaultPageSize={defaultPageSize}
            onSortingChange={onSortingChange}
            onTableModeChange={onTableModeChange}
            autoSticky
          />
        )}
      </OperationalTable.Wrapper>
    </>
  )
}

TasksTable.propTypes = {
  columnConfig: PropTypes.object
}

TasksTable.defaultProps = {
  columnConfig: undefined
}

export default TasksTable
