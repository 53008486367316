import React, { createContext, useCallback, useContext, useMemo, useState } from 'react'
import PropTypes from 'prop-types'

export const TargetModelContext = createContext({ })

export const useTargetModelContext = () => {
  return useContext(TargetModelContext)
}

export const useSectionEditing = (sectionName) => {
  const { editing, section } = useTargetModelContext()
  return useMemo(() => {
    return editing && section === sectionName
  }, [editing, section, sectionName])
}

const useProviderValue = ({ targetModel, isFetching }) => {
  const [formState, setFormState] = useState({
    editing: false,
    section: null
  })

  const editSection = useCallback(({
    section
  }) => {
    setFormState((prevState) => ({
      ...prevState,
      editing: !prevState.editing,
      section: !prevState.editing ? section : null
    }))
  }, [setFormState])

  const cancelEdit = useCallback(() => {
    setFormState((prevState) => ({
      ...prevState,
      editing: false,
      section: null
    }))
  }, [setFormState])

  return useMemo(() => ({
    targetModel,
    editSection,
    cancelEdit,
    isFetching,
    ...formState
  }), [targetModel, editSection, cancelEdit, formState, isFetching])
}

export const TargetModelProvider = ({ targetModel, isFetching, children }) => {
  const value = useProviderValue({
    targetModel,
    isFetching
  })

  return (
    <TargetModelContext.Provider value={value}>
      {children}
    </TargetModelContext.Provider>
  )
}

TargetModelProvider.propTypes = {
  targetModel: PropTypes.object,
  children: PropTypes.node,
  isFetching: PropTypes.bool
}

TargetModelProvider.defaultProps = {
}
