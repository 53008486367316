/**
 * Maps a value within a given range to degrees on a semicircle.
 *
 * @param {number} start - The start value of the range.
 * @param {number} end - The end value of the range.
 * @param {number} value - The value to map, guaranteed to be within the bounds of [start, end].
 * @returns {number} - The degree value corresponding to the position on the semicircle.
 */
export function mapToSemicircleDegrees (start, end, value) {
  if (value === undefined) return null
  if (value === null) return null
  if (start === end) {
    throw new Error('Start and end values cannot be the same.')
  }

  const proportion = (value - start) / (end - start)
  const degrees = proportion * 180

  return degrees
}

/**
 * Translates the center point of a circle to the edge of a semicircle.
 *
 * @param {number} degrees - The degree value representing the position on the semicircle.
 * @param {number} radius - The radius of the semicircle.
 * @returns {Object} - The new (x, y) coordinates of the center point of the circle.
 */
export function translateToSemicircleEdge (degrees, radius) {
  if (degrees === undefined) return { x: 0, y: 0 }
  if (degrees === null) return { x: 0, y: 0 }
  // Convert degrees to radians
  const radians = (Math.PI / 180) * degrees

  // Calculate the new x and y coordinates
  const x = radius * Math.cos(radians)
  const y = radius * Math.sin(radians)

  return { x, y }
}

export const interpolatePosition = (radius, x, y) => ({
  ix: (val) => x - translateToSemicircleEdge(val, radius).x,
  iy: (val) => y - translateToSemicircleEdge(val, radius).y
})
