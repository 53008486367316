import dayjs from 'dayjs'
import { useCallback, useEffect, useState } from 'react'
import { DATE_PRESET, INTERNAL_DATE_FORMAT } from '../constants'

const { useAvailableDates } = require('../redux/slices/appContext')

function getOptionByPresetValueKey (
  { mainDate, min },
  datePresetKey,
  fmt = INTERNAL_DATE_FORMAT
) {
  const baseDate = dayjs(mainDate)
  switch (datePresetKey) {
    case DATE_PRESET.Y:
      return baseDate.subtract(1, 'day').format(fmt)
    case DATE_PRESET.TW:
      return baseDate.startOf('week').format(fmt)
    case DATE_PRESET.L7D:
      return baseDate.subtract(7, 'day').format(fmt)
    case DATE_PRESET.L30:
      return baseDate.subtract(30, 'day').format(fmt)
    case DATE_PRESET.L3:
      return baseDate.subtract(3, 'month').format(fmt)
    case DATE_PRESET.L6:
      return baseDate.subtract(6, 'month').format(fmt)
    case DATE_PRESET.L12:
      return baseDate.subtract(1, 'year').format(fmt)
    case DATE_PRESET.MD:
      return baseDate.startOf('month').format(fmt)
    case DATE_PRESET.QD:
      return baseDate.startOf('quarter').format(fmt)
    case DATE_PRESET.YD:
      return baseDate.startOf('year').format(fmt)
    case DATE_PRESET.SI:
      return dayjs(min).add(1, 'day').format(fmt)
    default:
      return baseDate.format(fmt)
  }
}

const useDatePresetOptions = (presetKey) => {
  const [availableDates] = useAvailableDates()
  const [dateValue, setDateValue] = useState(
    getOptionByPresetValueKey(availableDates, presetKey)
  )

  useEffect(() => {
    setDateValue((prevDateValue) =>
      presetKey === 'custom'
        ? prevDateValue
        : getOptionByPresetValueKey(availableDates, presetKey)
    )
  }, [presetKey, availableDates])

  const getDateByPresetValueKey = useCallback(
    (presetKey, availableDateOverrides = {}) => {
      return getOptionByPresetValueKey(
        { ...availableDates, ...availableDateOverrides },
        presetKey
      )
    },
    [availableDates]
  )

  return { dateValue, availableDates, getDateByPresetValueKey }
}

export default useDatePresetOptions
