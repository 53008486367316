import React from 'react'
import PropTypes from 'prop-types'
import FadeIn from '../../../molecules/Transitions/FadeIn'
import NLQIndex from '../NLQ'
import NLQ2Index from '../NLQ2'
import IndexViewTabs from './IndexViewTabs'

function DataHomeIndexView ({ root }) {
  return (
    <FadeIn>
      <IndexViewTabs
        root={root}
        nlqTab={<NLQIndex />}
        nlq2Tab={<NLQ2Index />}
      />
    </FadeIn>
  )
}

DataHomeIndexView.propTypes = {
  root: PropTypes.any
}

export default DataHomeIndexView
