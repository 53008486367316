import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import LabeledValue from '../components/LabeledValue'
import Hideable from '../components/Hideable'

const useStyles = makeStyles((theme) => ({
  transformation: {
    borderBottom: `1px solid ${theme.palette.gray.dark}`,
    paddingBottom: '.5rem'
  },
  codeBlock: {
    backgroundColor: theme.palette.gray.main,
    padding: '1rem',
    whiteSpace: 'pre'
  },
  item: {
    marginBottom: '.5rem',
    paddingLeft: '.5rem',
    borderLeft: `2px solid ${theme.palette.gray.dark}`
  }
}))

const ObjectMappingTransformations = ({ mapping }) => {
  const classes = useStyles()
  const sortedTransformations = useMemo(() => {
    return [...mapping.transformations].sort((a, b) => {
      return a.ordinal - b.ordinal
    })
  }, [mapping.transformations])
  const noItems = sortedTransformations.length ? null : <div>No Transformations</div>

  return (
    <section className={classes.transformation}>
      <Hideable label='Transformations'>
        {noItems}
        {sortedTransformations.map(transformation => (
          <div key={transformation.objectTransformationId} className={classes.item}>
            <LabeledValue label='objectTransformationId'>{transformation.objectTransformationId}</LabeledValue>
            <LabeledValue label='Transformation Type'>{transformation.transformationType}</LabeledValue>
            <LabeledValue label='Source Path'>{transformation.sourcePath}</LabeledValue>
            <LabeledValue label='Ordinal'>{transformation.ordinal}</LabeledValue>
            <div className={classes.codeBlock}>
              <code dangerouslySetInnerHTML={{ __html: transformation.transformationExpression }} />
            </div>
          </div>
        ))}
      </Hideable>
    </section>
  )
}

ObjectMappingTransformations.propTypes = {
  mapping: PropTypes.shape({
    transformations: PropTypes.arrayOf(PropTypes.shape({
      objectTransformationId: PropTypes.number,
      transformationType: PropTypes.string,
      transformationExpression: PropTypes.string,
      sourcePath: PropTypes.string,
      ordinal: PropTypes.number
    }))
  })
}

export default ObjectMappingTransformations
