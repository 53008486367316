import React from 'react'
import PropTypes from 'prop-types'
import SydInput from '../../../../../commonDesign/SydInput'

function StringValueEditor ({ value, onChange, disabled }) {
  return (
    <SydInput value={value} onChange={onChange} disabled={disabled} />
  )
}

StringValueEditor.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func,
  disabled: PropTypes.bool
}

export default StringValueEditor
