import { useMemo, useRef } from 'react'
import fastDeepEqual from '../utils/fastDeepEqual'

function useDeepCompareMemoize (value) {
  const ref = useRef()

  if (!fastDeepEqual(value, ref.current)) {
    ref.current = value
  }
  return ref.current
}

function useDeepCompareMemo (callback, dependencies) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo(callback, useDeepCompareMemoize(dependencies))
}

export default useDeepCompareMemo
