import { useCallback } from 'react'
import { createSelector, createSlice } from '@reduxjs/toolkit'
import { useSelector } from 'react-redux'
import { fetchAppConfig, postNamedQuery } from '../../service'
import { useAsyncAction } from '../utils'
import { FEATURE_FLAG } from '../../constants'
import { QUERY_KEYS } from '../../api/queryKeys'
import queryClient from '../../api/defaultQueryClient'

export const initialState = {
  homeViewPath: '/views/home',
  firmColors: undefined,
  mainSearchViewPath: undefined,
  clientsViewPath: '/clients',
  navLogoStyles: undefined,
  showHome: undefined,
  featureFlags: Object.keys(FEATURE_FLAG).reduce((acc, key) => ({
    ...acc,
    [key]: {
      name: key,
      active: false
    }
  }), {})
}

function reduceFeatureFlags (featureFlags = []) {
  return featureFlags.reduce(
    (acc, featureFlag) => ({
      ...acc,
      [featureFlag.name]: featureFlag
    }),
    {}
  )
}

async function fetchHomeViewPath (defaultPath) {
  try {
    const { data: views } = await fetchUserViews()
    return views?.find(({ isDefault }) => isDefault)?.path || defaultPath
  } catch (err) {
    return defaultPath
  }
}

export async function fetchUserViews () {
  return await queryClient.fetchQuery({
    queryKey: [QUERY_KEYS.userViews],
    queryFn: () => postNamedQuery('abundanceEngine', 'getUserViews')
  })
}

const viewsSlice = createSlice({
  name: 'appConfig',
  initialState,
  reducers: {
    setAppConfig: (state, { payload }) => {
      state.firmColors = payload?.config?.firmColors
      state.mainSearchViewPath = payload?.config?.mainSearchViewPath
      state.clientsViewPath = payload?.config?.clientsViewPath
      state.navLogoStyles = payload?.config?.navLogoStyles
      state.showHome = payload?.config?.showHome
      state.featureFlags = reduceFeatureFlags(payload?.featureFlags ?? [])
      state.homeViewPath = payload?.config?.homeViewPath
      state.pageConfig = payload
    }
  }
})

const { actions } = viewsSlice

const getAppConfigSlice = (state) => state[viewsSlice.name]

const getHomeViewPath = createSelector(
  getAppConfigSlice,
  ({ homeViewPath }) => homeViewPath
)

const getNavLogoStyles = createSelector(
  getAppConfigSlice,
  ({ navLogoStyles }) => navLogoStyles || {}
)

const getMainSearchViewPath = createSelector(
  getAppConfigSlice,
  ({ mainSearchViewPath }) => mainSearchViewPath
)

const getClientsViewPath = createSelector(
  getAppConfigSlice,
  ({ clientsViewPath }) => clientsViewPath
)

const getFirmsColors = createSelector(
  getAppConfigSlice,
  ({ firmColors }) => firmColors
)

const getShowHome = createSelector(
  getAppConfigSlice,
  ({ showHome }) => showHome
)

const getFeatureFlags = createSelector(getAppConfigSlice, ({ featureFlags = {} }) => featureFlags)

const getFeatureFlagByKey = createSelector(
  getFeatureFlags,
  (_, featureKey) => featureKey,
  (featureFlags, featureKey) => featureFlags[featureKey] || {}
)

export function useHomeViewPath () {
  return useSelector(getHomeViewPath)
}

export function useMainSearchViewPath () {
  return useSelector(getMainSearchViewPath)
}

export function useClientsViewPath () {
  return useSelector(getClientsViewPath)
}

export function useFirmColors () {
  return useSelector(getFirmsColors)
}

export function useNavLogoStyles () {
  return useSelector(getNavLogoStyles)
}

export function useShowHome () {
  return useSelector(getShowHome)
}

export function useAppConfig () {
  return useSelector(getAppConfigSlice)
}

function fetchAppConfigAction () {
  return async (dispatch) => {
    const { data: appConfig } = await fetchAppConfig()
    const { config, featureFlags, brand } = appConfig

    let homeViewPath = initialState.homeViewPath
    if (featureFlags?.find(({ name, active }) => name === 'ABUNDANCE_ENGINE_VIEW_BY_LEVEL' && active)) {
      const userHomeViewPath = await fetchHomeViewPath(initialState.homeViewPath)
      homeViewPath = !userHomeViewPath.startsWith('/views/') ? `/views/${userHomeViewPath}` : userHomeViewPath
    }

    dispatch(actions.setAppConfig({
      config: {
        ...(config || {}),
        clientsViewPath: initialState.clientsViewPath,
        homeViewPath
      },
      featureFlags,
      brand
    }))
  }
}

export function useFetchAppConfig () {
  return useAsyncAction(fetchAppConfigAction)
}

export function useFeatureFlag (featureKey) {
  const payloadFn = useCallback((state) => getFeatureFlagByKey(state, featureKey), [
    featureKey
  ])

  return useSelector(payloadFn)
}

export function useFeatureFlags () {
  return useSelector(getFeatureFlags)
}

export default viewsSlice
