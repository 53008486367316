import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { IconButton } from '@material-ui/core'
import { usePolicy } from '../../../../../hooks/usePolicy'
import { ADMIN_ROUTES } from '../../../../../constants'
import Icon from '../../../../atoms/Icon'
import { useClientPersonsActions } from './ClientPersonsTableContext'

function ExperienceCell ({ value, row }) {
  const { editing, onAssignRole } = useClientPersonsActions()
  const canEditRoles = usePolicy('admin_edit_wo_roles')
  const canViewWealthOwners = usePolicy('admin_view_wo_users')
  const roleId = row.original?.experience?.roleId
  if (canEditRoles && roleId && !editing) {
    return (
      <div className='__people-cell'>
        <Link to={`${ADMIN_ROUTES.ROLES}/${roleId}`}>{value}</Link>
        {(!canViewWealthOwners || editing) ? null : (
          <IconButton onClick={() => onAssignRole({ ...row.original })}>
            <Icon name='edit' />
          </IconButton>
        )}
      </div>
    )
  }
  if (roleId) {
    return (
      <div className='__people-cell'>
        <span>{value}</span>
        {(!canViewWealthOwners || editing) ? null : (
          <IconButton onClick={() => onAssignRole({ ...row.original })}>
            <Icon name='edit' />
          </IconButton>
        )}
      </div>
    )
  }
  return <div className='__people-cell'>--</div>
}

ExperienceCell.propTypes = {
  value: PropTypes.any,
  row: PropTypes.any
}

export default ExperienceCell
