import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  metricGroup: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.layout.gap.loose,
    flexWrap: 'wrap',
    justifyContent: 'space-evenly'
  }
}))

function MetricGroup ({ children }) {
  const classes = useStyles()

  return (
    <div className={classes.metricGroup}>
      {children}
    </div>
  )
}

MetricGroup.propTypes = {
  children: PropTypes.node
}

export default MetricGroup
