import React from 'react'
import PropTypes from 'prop-types'
import StatusReportContextProvider from './statusReportContext'

function StatusReportProvider ({ children, config }) {
  return (
    <StatusReportContextProvider config={config}>
      {children}
    </StatusReportContextProvider>
  )
}

StatusReportProvider.propTypes = {
  children: PropTypes.node,
  config: PropTypes.shape({
    filtersAt: PropTypes.arrayOf(PropTypes.number)
  })
}

export default StatusReportProvider
