import React from 'react'
import PropTypes from 'prop-types'
import ErrorComponent from '../atoms/ErrorComponent'

class ErrorBoundary extends React.Component {
  constructor (props) {
    super(props)
    this.state = { error: undefined }
  }

  static getDerivedStateFromError (error) {
    return { error }
  }

  componentDidCatch (error, { componentStack }) {
    const { name, object } = this.props
    // name: Name of which ErrorBoundary this is
    // object: The view, container, component, layout that placed the ErrorBoundary
    console.error(`Catched error in boundary ${name}`, '\n', error, '\n\ncomponentStack\n', componentStack, '\n\nobject\n', object)
    this.setState({ error })
  }

  render () {
    const { error } = this.state
    const { children, refresh } = this.props

    if (error) return <ErrorComponent error={error} refresh={refresh} />

    return children
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  object: PropTypes.shape({}),
  refresh: PropTypes.bool
}

ErrorBoundary.defaultProps = {
  object: undefined,
  refresh: false
}

export default ErrorBoundary
