import React, { createContext, useCallback, useContext, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
export const HomeDashboardContext = createContext({})

export const useHomeDashboard = () => {
  return useContext(HomeDashboardContext)
}

export const useGetViewConfig = (id) => {
  const { viewConfig } = useContext(HomeDashboardContext)

  return id in viewConfig ? viewConfig[id] : {}
}

export const useGetViewConfigByPath = (path) => {
  const { viewConfig } = useContext(HomeDashboardContext)

  return Object.values(viewConfig).find(x => x.path === path)
}

export const useIndexPath = () => {
  const { rootUrl, viewConfig } = useContext(HomeDashboardContext)

  return `${rootUrl}/${Object.values(viewConfig).at(0)?.path ?? ''}`
}

export const HomeDashboardProvider = ({ value, children }) => {
  const [expanded, setExpanded] = useState(false)
  const toggleExpanded = useCallback(() => {
    setExpanded(x => !x)
  }, [setExpanded])

  const _value = useMemo(() => {
    return {
      ...value,
      expanded,
      toggleExpanded
    }
  }, [value, expanded, toggleExpanded])

  return (
    <HomeDashboardContext.Provider value={_value}>
      {children}
    </HomeDashboardContext.Provider>
  )
}

HomeDashboardProvider.propTypes = {
  value: PropTypes.object.isRequired,
  children: PropTypes.node
}
