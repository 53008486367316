import React, { createContext, useContext, useMemo, useState } from 'react'
import PropTypes from 'prop-types'

export const AutoOperationalTableContext = createContext({})

export const useAutoOperationalTableContext = () => {
  return useContext(AutoOperationalTableContext)
}

export const AutoOperationalTableContextProvider = ({ children }) => {
  const [tableProps, setTableProps] = useState(null)

  const value = useMemo(() => ({
    tableProps,
    setTableProps
  }), [tableProps])

  return (
    <AutoOperationalTableContext.Provider value={value}>
      {children}
    </AutoOperationalTableContext.Provider>
  )
}

AutoOperationalTableContextProvider.propTypes = {
  children: PropTypes.node
}
