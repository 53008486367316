import React, { createContext, useContext, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { parseUrlSearchParams } from '../../utils/parseUrlSearchParams'

const FilterContext = createContext(null)

export const useFilterContext = () => {
  return (useContext(FilterContext))
}

export const useFilterContextValues = ({ filter, mode, urlParams }) => {
  const contextValue = useFilterContext()
  const history = useHistory()

  const queryParams = useMemo(() => parseUrlSearchParams(history), [history])

  return useMemo(() => {
    let result = {}
    if (mode === 'default') {
      result = contextValue ?? filter ?? {}
    }
    if (mode === 'replace') {
      result = filter ?? {}
    }
    if (mode === 'add') {
      result = {
        ...contextValue,
        ...(filter ?? {})
      }
    }
    if (urlParams) {
      result = {
        ...result,
        ...queryParams
      }
    }

    return result
  }, [filter, contextValue, queryParams, mode, urlParams])
}

const FilterContextProvider = ({ filter, mode, urlParams, children }) => {
  const contextValue = useFilterContextValues({ filter, mode, urlParams })
  return (
    <FilterContext.Provider value={contextValue}>
      {children}
    </FilterContext.Provider>
  )
}

FilterContextProvider.propTypes = {
  filter: PropTypes.object,

  /** If true, context adds filters together */
  mode: PropTypes.oneOf(['add', 'replace', 'default']),

  /** If true, tries to parse url querystring params */
  urlParams: PropTypes.bool,

  children: PropTypes.node
}

FilterContextProvider.defaultProps = {
  mode: 'default',
  urlParams: false
}

export default FilterContextProvider
