import React from 'react'
import { makeStyles } from '@material-ui/core'
import SectionHeader from '../../../shared/SectionHeader'
import { useAdvisorStatusSummary } from '../../../../../../api/users'
import { useAdvisorUserDetails } from '../../AdvisorUserFormContext'
import SectionScreen from '../../../shared/SectionScreen'
import InviteAction from './InviteAction'
import ResetUserAction from './ResetUserAction'

const useStyles = makeStyles((theme) => ({
  userInfoSection: {
    position: 'relative',
    padding: '10px'
  },
  actions: {
    '& > *': {
      marginBottom: theme.layout.margin.thick,
      border: `1px solid ${theme.palette.gray.darker}`,
      borderRadius: theme.layout.radius.loose,
      padding: theme.layout.padding.medium
    },
    '& .__row': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      gap: '20px',
      marginBottom: '20px'
    },
    '& .__disabled': {
      color: theme.palette.gray.darker
    }
  }
}))

function WealthOwnerActionsSection () {
  const classes = useStyles()
  const { user, editing } = useAdvisorUserDetails()
  const { data: status, isLoading } = useAdvisorStatusSummary(user.userId)

  return (
    <SectionScreen editing={editing} className={classes.userInfoSection}>
      <SectionHeader text='Actions' />
      <div className={classes.actions}>
        <InviteAction user={user} isLoading={isLoading} status={status?.statusSummary?.status} />
        <ResetUserAction user={user} isLoading={isLoading} status={status?.statusSummary?.status} />
      </div>
    </SectionScreen>
  )
}

export default WealthOwnerActionsSection
