import { useMemo } from 'react'
import dayjs from 'dayjs'
import isEmpty from 'lodash/isEmpty'
import { useWealthJourneyQuery } from '../../../../api/wealthJourney'
import { useAdvisorHome } from '../../AdvisorHome/AdvisorHomeContext'
import { mapSearch } from '../../ClientCardsViewV2/helpers'

const queryKey = 'tasks'
const totalQueryKey = 'tasks-total'

const useTasks = ({
  sort,
  queryText,
  pagination,
  featuredFilter,
  overdueStartDate,
  relativeDateRanges,
  excludeTaskStatuses
}) => {
  const { scope } = useAdvisorHome()

  const queryFilters = useMemo(() => {
    const { startDate, endDate } = featuredFilter || {}
    const filters = {
      status: [{ op: 'notIn', value: excludeTaskStatuses }]
    }

    if (startDate && endDate) {
      filters.dueDate = [
        { op: 'gte', value: startDate },
        { op: 'lte', value: endDate }
      ]
    } else {
      filters.dueDate = [
        { op: 'gte', value: overdueStartDate || dayjs().year(1900).toISOString() },
        { op: 'lte', value: relativeDateRanges.yesterday }
      ]
    }
    if (!isEmpty(scope?.values)) {
      filters.groupIds = [{ op: 'in', value: scope.values }]
    }
    return filters
  }, [
    scope,
    featuredFilter,
    overdueStartDate,
    excludeTaskStatuses,
    relativeDateRanges.yesterday
  ])

  const { query, queryOptions } = useMemo(() => {
    const { skip, take } = pagination
    return {
      query: {
        skip,
        take,
        includes: {
          client: true
        },
        sort,
        filters: queryFilters,
        ...(queryText?.trim()
          ? { textSearch: mapSearch(queryText, ['clientLongName']) }
          : {})
      },
      queryOptions: {
        queryName: 'searchTasks',
        mapper: ({ data }) => data
      }
    }
  }, [pagination, queryFilters, queryText, sort])

  const { data, isLoading } = useWealthJourneyQuery(
    queryKey,
    query,
    queryOptions
  )

  const { query: queryTotal, queryOptions: queryTotalOptions } = useMemo(() => {
    return {
      query: {
        filters: queryFilters,
        resultType: 'total'
      },
      queryOptions: {
        queryName: 'searchTasks',
        mapper: ({ total }) => total
      }
    }
  }, [queryFilters])

  const { data: tasksTotal, isLoading: isLoadingTotal } = useWealthJourneyQuery(
    totalQueryKey,
    queryTotal,
    queryTotalOptions
  )

  return {
    tasks: data || [],
    isLoading: isLoading || isLoadingTotal,
    tasksTotal
  }
}

export default useTasks
