import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'

import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import FastAvatar from '../../../molecules/FastAvatar'

dayjs.extend(utc)

const useStyles = makeStyles(() => ({
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '1.5rem 1rem',
    textAlign: 'center',
    gap: '0.75rem',
    borderRadius: '0.5rem',
    justifyContent: 'center'
  },
  date: {
    fontSize: '1rem',
    color: '#212945',
    fontWeight: '600'
  },
  title: {
    fontSize: '1.1rem',
    fontWeight: '400'
  },
  caption: {
    fontSize: '0.8rem',
    color: '#212945'
  }
}))

const KeyDateItem = ({
  contactName,
  dateNow,
  dateText,
  clientAbbreviation,
  avatarUrl
}) => {
  const classes = useStyles()
  return (
    <div className={classes.container}>
      <FastAvatar
        size='lg'
        avatarUrl={avatarUrl}
        abbreviation={clientAbbreviation}
      />
      <div className={classes.title}>{contactName}</div>
      <div className={classes.date}>
        {dayjs.utc(dateNow).format('MMM D, ddd')}
      </div>
      <div className={classes.caption}>{dateText}</div>
    </div>
  )
}

KeyDateItem.propTypes = {
  contactName: PropTypes.string,
  dateNow: PropTypes.string,
  dateText: PropTypes.string,
  clientAbbreviation: PropTypes.string,
  avatarUrl: PropTypes.string
}

export default KeyDateItem
