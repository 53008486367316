import React, { useMemo } from 'react'
import { makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import OperationalTable, { useOperationalTable } from '../../OperationalTable'
import { useAppContext } from '../../../../redux/slices/appContext'
import { useFormattingContext } from '../../FormattingProvider/FormattingContext'
import { defaultColumns, getColumnConfig } from './helpers'
import { useClientAccounts } from './hooks'

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '0'
  }
}))

function AccountsList ({
  filters,
  columns: _columns = defaultColumns,
  defaultSort
}) {
  const { clientId } = useAppContext()
  const { formatter } = useFormattingContext()
  const columns = useMemo(() => (
    getColumnConfig(_columns, formatter, defaultSort)
  ), [_columns, defaultSort, formatter])

  const {
    defaultPageSize,
    sort,
    onPagingChange,
    onSortingChange,
    onTableModeChange
  } = useOperationalTable(columns.defaultSort)
  const classes = useStyles()

  const { data, loading } = useClientAccounts({
    pageIndex: 0,
    pageSize: 500,
    sort,
    filters: {
      assignedToClientIds: [clientId],
      ...(filters ?? {})
    }
  })

  return (
    <div className={classes.container}>
      <OperationalTable.Wrapper>
        <OperationalTable
          mode='client'
          columns={columns.columns}
          data={data}
          defaultPageSize={defaultPageSize}
          defaultSort={columns.defaultSort}
          itemName='Accounts'
          loading={loading}
          onSortingChange={onSortingChange}
          onPagingChange={onPagingChange}
          onTableModeChange={onTableModeChange}
          hideFooter
        />
      </OperationalTable.Wrapper>
    </div>
  )
}

AccountsList.propTypes = {
  filters: PropTypes.object,
  columns: PropTypes.array,
  defaultSort: PropTypes.array
}

export default AccountsList
