import PropTypes from 'prop-types'

export const accountStatusPropTypes = {
  dataSourceIds: PropTypes.arrayOf(PropTypes.number),
  columnsConfig: PropTypes.shape({
    columns: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      Header: PropTypes.string,
      accessor: PropTypes.any,
      disableSortBy: PropTypes.bool
    })),
    defaultSort: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      desc: PropTypes.bool,
      asc: PropTypes.bool
    }))
  }),
  format: PropTypes.object
}

export const accountStatusDefaultProps = {
  columnsConfig: {
    columns: [
      { Header: 'ByAll Account ID', accessor: 'longNumber', id: 'accountId' },
      { Header: 'ByAll Account Number', accessor: 'displayNumber', id: 'byAllAccount' },
      { Header: 'Account Name', accessor: 'longName', id: 'accountName' },
      { Header: 'Latest Balance', accessor: 'asOfDayValues.endingValue', id: 'endingValue', disableSortBy: true, format: 'currency' },
      { Header: 'As of Date', accessor: 'asOfDate', format: 'date' },
      { Header: 'Account Category', accessor: 'accountCategory.longName', id: 'accountCategoryName' },
      { Header: '# Assigned Clients', accessor: 'assignedClientCount', disableSortBy: true },
      { Header: 'Closed Date', accessor: 'closeDate', id: 'closedDate' }
    ],
    defaultSort: [{ id: 'accountName', desc: false }]
  },
  format: {}
}
