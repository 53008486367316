import React, { useCallback, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import Box from '@material-ui/core/Box'
import Table from '@material-ui/core/Table'
import TableRow from '@material-ui/core/TableRow'
import Collapse from '@material-ui/core/Collapse'
import TableBody from '@material-ui/core/TableBody'
import TableHead from '@material-ui/core/TableHead'
import TableFooter from '@material-ui/core/TableFooter'
import IconButton from '@material-ui/core/IconButton'
import MuiTableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import clsx from 'clsx'
import { CircularProgress } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'
import { first, isObject, isEmpty, noop, last, isArray } from 'lodash'
import NumberFormat from '../atoms/NumberFormat'
import Select from '../atoms/Select'
import Text from '../atoms/Text'
import CSVButton from '../atoms/CSVButton'
import { insertDataAtIndex } from '../../utils'
import { TABLE_CELL_TYPE, TEXT_VARIANTS, DATE_TIME_PICKER_TYPES } from '../../constants'
import { useCSVData } from '../../hooks'
import { useAppContext } from '../../redux/slices/appContext'
import TableSkeleton from '../atoms/TableSkeleton'
import Skeleton from '../atoms/Skeleton'
import WebOnlyContent from '../atoms/WebOnlyContent'
import { findRowConfig, formatCell, formatCellValue } from '../../utils/tableHelper'
import {
  tableCell,
  tableCellDefaults,
  CollapsibleTableColumnsPropTypes,
  CollapsibleTableRowsPropTypes,
  rowProps,
  rowDefaults,
  insertColumnsAtShape,
  insertColumnsAtDefaults,
  simpleTableLabelDefaults
} from '../../prop-types/tables'
import AvailableDateSelect from './AvailableDateSelect'

const {
  alignment: defaultLabelAlignment,
  colSpan: defaultLabelColSpan
} = simpleTableLabelDefaults

const useStyles = makeStyles((theme) => ({
  header: {
    backgroundColor: theme.palette.porcelain,
    '& > tr > th': {
      fontWeight: 'bold',
      borderBottom: 'none !important'
    }
  },
  footer: {
    backgroundColor: theme.palette.porcelain,
    '& > tr > td': {
      color: theme.palette.black,
      borderBottom: 'none !important'
    },
    '& > tr > td > p': {
      fontSize: 16,
      fontWeight: 'bold'
    },
    '& > tr > td > h6': {
      fontSize: 16,
      fontWeight: 'bold'
    }
  },
  headerCell: {
    padding: '0.75rem'
  },
  row: {
    cursor: 'pointer'
  },
  headerFirstCell: {
    width: '1rem',
    padding: '0 !important'
  },
  csvButtonContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  viewWrapper: {
    width: '100%',
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: '1em',
      height: '1em',
      backgroundColor: 'transparent'
    },
    '&::-webkit-scrollbar-track': {
      borderRadius: '20px',
      backgroundColor: 'transparent'
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '20px',
      backgroundColor: theme.palette.primary.dark,
      border: '3px solid white'
    }
  },
  noOverflow: {
    overflow: 'hidden'
  },
  skeletonCell: {
    paddingRight: '0 !important',
    paddingLeft: '0 !important',
    borderBottom: 'none'
  }
}))

const useRowStyles = makeStyles((theme) => ({
  row: {
    cursor: 'pointer'
  },
  firstCell: {
    minWidth: '2.5rem',
    width: '2.5rem',
    padding: '0',
    textAlign: 'center',
    borderBottom: 'none'
  },
  nestedCell: {
    padding: 0,
    borderBottom: 'none'
  },
  disabled: {
    opacity: 0.4,
    cursor: 'not-allowed'
  },
  childrenTableRow: {
    '&:last-child th, &:last-child td': {
      borderBottom: '0 !important'
    }
  },
  headerFirstCell: {
    width: '1rem',
    padding: '0 !important'
  },
  emptyRow: {
    padding: '1rem',
    textAlign: 'center',
    backgroundColor: theme.palette.gray.lighter
  }
}))

const useCellStyles = makeStyles((theme) => ({
  iconContainer: {
    paddingRight: '0.5rem'
  },
  firstNestedCell: ({ deepLevel, alignment }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: alignment || 'left',
    paddingLeft: deepLevel ? `${deepLevel * 2}rem` : '0'
  }),
  root: ({ withDivider, withBorder, width, isOnRootRow, rowIndex, extraStyles }) => ({
    position: 'relative',
    ...(extraStyles || {}),
    ...(withDivider ? {
      '&::before': {
        top: 0,
        left: 0,
        width: '50%',
        content: '""',
        position: 'absolute',
        transform: 'translate(50%, -50%)',
        borderBottom: `2px solid ${theme.palette.black}`
      }
    } : {}),
    ...(withBorder ? {} : { borderBottom: 'none' }),
    ...(width && rowIndex ? {
      ...(rowIndex ? { width } : {}),
      minWidth: '2.5rem',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whitespace: 'nowrap'
    } : {}),
    ...(isOnRootRow ? {} : {}),
    '& > *': {
      textTransform: 'unset !important',
      lineHeight: '1.4 !important'
    }
  })
}))

const getRowDisabledProperties = (rows) => {
  const rowConfigs = rows
    .map(row => row[row.length - 1])
    .filter(rowConfig => isObject(rowConfig))

  const isExpanded = rowConfigs.some(config => config?.isExpanded)
  const isLoading = rowConfigs.some(config => config?.isLoading)
  return { isExpanded, isLoading }
}

export const getCellValues = (cell) => !isObject(cell) ? { value: cell } : cell

const TableCell = ({
  cell,
  tooltip,
  rowIndex,
  deepLevel,
  isLastRow,
  withBorder,
  isOnRootRow,
  isRowLoading,
  onExpandRowClick,
  cellFormatter,
  isLoading,
  isFirstCell,
  isLastCell,
  cellOptions
}) => {
  const { isExpanded, hideChevron, isExpandible } = cellOptions
  const {
    type = TABLE_CELL_TYPE.NUMBER,
    value,
    width,
    hidden,
    variant,
    extraStyles,
    withDivider,
    colSpan = 1,
    hideIfExpanded,
    alignment = defaultLabelAlignment,
    payload = {}
  } = getCellValues(cell)

  const classes = useCellStyles({
    width,
    rowIndex,
    alignment,
    deepLevel,
    withBorder,
    isOnRootRow,
    withDivider,
    extraStyles
  })

  if (hidden) return null

  const formattedValue = formatCellValue(value, cell.cellFormat)

  const cellValue =
    tooltip && tooltip?.title ? (
      <NumberFormat
        title={tooltip.title}
        format={tooltip.format}
        number={formattedValue}
        skipFormat={tooltip.skipFormat}
      />
    ) : (
      formattedValue
    )

  let cellChildren = (
    <Text
      text={hideIfExpanded && isExpanded ? '' : cellValue}
      variant={variant}
    />
  )

  if (type === TABLE_CELL_TYPE.COMPONENT) {
    cellChildren = cellValue
  }

  if (cellFormatter && !isFirstCell && !isLastCell) {
    cellChildren = cellFormatter(cell, tooltip, cellOptions, payload)
  }

  return (
    <MuiTableCell classes={{ root: classes.root }} colSpan={colSpan}>
      <Box
        textAlign={alignment}
        className={clsx({
          [classes.firstNestedCell]: !rowIndex && !hideChevron
        })}
      >
        {isExpandible && !isLastRow && !rowIndex && !hideChevron && !isLoading && (
          <div className={classes.iconContainer}>
            <IconButton
              size='small'
              aria-label='expand row'
              onClick={onExpandRowClick}
              disabled={isRowLoading}
            >
              {isExpanded ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
            </IconButton>
          </div>
        )}
        {isLoading ? <Skeleton /> : cellChildren}
      </Box>
    </MuiTableCell>
  )
}

const renderTableHeaderHelper = (columns, classes, onHeaderFilterChange = noop, emptyLeadingCell = false) => {
  if (isEmpty(columns)) return null
  return (
    <TableRow>
      {emptyLeadingCell && <MuiTableCell className={classes.headerFirstCell} />}
      {columns.map((column, index) => {
        const {
          name,
          width,
          hidden,
          isSelect,
          colSpan = defaultLabelColSpan,
          alignment = defaultLabelAlignment,
          variant = TEXT_VARIANTS.subtitle2,
          selectProps: { onChange: handleOnChange, ...selectProps } = {}
        } = column

        if (hidden) return null

        if (selectProps.selectType === DATE_TIME_PICKER_TYPES.date) {
          return (
            <MuiTableCell
              key={index}
              align={alignment}
              colSpan={colSpan}
              className={classes.headerCell}
              style={{ ...(width ? { width } : {}) }}
            >
              <AvailableDateSelect
                textVariant={variant}
                options={selectProps?.options || []}
                onChange={(...args) => {
                  if (onHeaderFilterChange) {
                    onHeaderFilterChange({
                      ...selectProps,
                      onChange: handleOnChange
                    }, ...args)
                  } else {
                    handleOnChange(...args)
                  }
                }}
                initialValue={selectProps.defaultValue}
                selectedValue={selectProps.selectedValue}
              />
            </MuiTableCell>
          )
        }

        return (
          <MuiTableCell
            key={index}
            align={alignment}
            colSpan={colSpan}
            className={classes.headerCell}
            style={{ ...(width ? { width } : {}) }}
          >
            {isSelect
              ? (
                <Select
                  textVariant={variant}
                  options={selectProps.options}
                  onChange={handleOnChange}
                  defaultValue={selectProps.defaultValue}
                  selectedValue={selectProps.selectedValue}
                  closestValueFinder={selectProps.closestValueFinder}
                />
              )
              : (<Text text={name} variant={variant} />)}
          </MuiTableCell>
        )
      })}
    </TableRow>)
}

const renderTableRowsHelper = ({
  rows = [],
  rowIndexes = [],
  onRowClick = noop,
  onExpandRow = noop,
  isRoot = true,
  someLoading = false,
  someExpanded = false,
  isLastRow = false,
  showEmptyRowOnExpandIfEmpty,
  isLoading,
  cellFormatter
}) => {
  return rows.map((row, index) => {
    return (
      <Row
        key={index}
        row={row}
        isRoot={isRoot}
        rowIndex={index}
        onClick={onRowClick}
        isLastRow={isLastRow}
        isLoading={isLoading}
        onExpandRow={onExpandRow}
        someLoading={someLoading}
        someExpanded={someExpanded}
        cellFormatter={cellFormatter}
        rowIndexes={[...rowIndexes, index]}
        showEmptyRowOnExpandIfEmpty={showEmptyRowOnExpandIfEmpty}
      />
    )
  })
}

function getRowConfig (row) {
  return isObject(last(row)) ? last(row) : null
}

function Row ({
  row,
  isRoot,
  onClick,
  rowIndex,
  isLoading: _isLoading,
  isLastRow,
  rowIndexes,
  someLoading,
  onExpandRow,
  someExpanded,
  cellFormatter,
  showEmptyRowOnExpandIfEmpty
}) {
  const tableClasses = useStyles()
  const classes = useRowStyles({ deepLevel: rowIndexes.length - 1 })

  const cellOptions = useMemo(() => getRowConfig(row), [row])

  const cellConfig = findRowConfig(cellOptions, 'configColumns') || []

  const filteredColumns =
    !isEmpty(cellConfig) && isArray(cellConfig)
      ? cellConfig.filter((item) => !item.hidden)
      : []

  const { cells, childrenRows } = row.reduce(
    (acc, cell, index) => {
      const configColumns = filteredColumns[index]
      const columnConfig = configColumns?.config || {}

      const { isExpandible = false } = cell || {}

      if (isLastRow) {
        const cellFormatted = formatCell(cell, columnConfig)
        acc.cells.push(cellFormatted)
        return acc
      }
      if (isExpandible) {
        acc.childrenRows = cell
      }
      const isConfigCell = acc.cellsLength - 1 === index

      acc.cells.push({
        ...cell,
        ...columnConfig,
        ...(isConfigCell
          ? {
            rowIndex,
            rowIndexes: rowIndexes.join(',')
          }
          : {})
      })
      return acc
    },
    { cells: [], cellsLength: row.length, childrenRows: null }
  )

  const onExpandRowClick = useCallback(
    (e) => {
      e.stopPropagation()
      const { rowIndex, rowIndexes, isExpanded } = getRowConfig(cells)
      onExpandRow({
        rowIndexes: rowIndexes.split(',').map(Number),
        isExpanded: !isExpanded,
        rowIndex,
        row: cells
      })
    },
    [cells, onExpandRow]
  )

  const onRowClick = useCallback(
    (e) => {
      e.stopPropagation()
      const { rowIndex, rowIndexes, isExpanded } = getRowConfig(cells)
      onClick(e, cells, {
        rowIndexes: rowIndexes.split(',').map(Number),
        isExpanded: !isExpanded,
        rowIndex
      })
    },
    [cells, onClick]
  )

  const isRowDisabled = useMemo(() =>
    someExpanded && !cellOptions?.isExpanded && isRoot,
  [someExpanded, cellOptions, isRoot])

  const isRowLoading = useMemo(() => {
    const { isLoading } = getRowDisabledProperties(childrenRows?.rows || [])

    return cellOptions?.isExpandible && (cellOptions?.isLoading || someLoading || isLoading)
  }, [cellOptions, someLoading, childrenRows])

  const renderCells = useMemo(() => cells.map((cell, index) => {
    let tooltip = null
    if (
      cellOptions &&
      cellOptions?.showTooltip &&
      isObject(cellOptions.tooltips[index])
    ) {
      tooltip = cellOptions.tooltips[index]
    }

    const isFirstCell = index === 0
    const isLastCell = cells.length - 1 === index

    return (
      <TableCell
        key={index}
        rowIndex={index}
        withBorder={false}
        isOnRootRow={isRoot}
        cell={cell}
        tooltip={tooltip}
        isLoading={_isLoading}
        isLastCell={isLastCell}
        isFirstCell={isFirstCell}
        cellOptions={cellOptions}
        isRowLoading={isRowLoading}
        cellFormatter={cellFormatter}
        deepLevel={rowIndexes.length - 1}
        onExpandRowClick={onExpandRowClick}
      />
    )
  }), [
    cells,
    cellOptions,
    isRoot,
    rowIndexes,
    _isLoading,
    isRowLoading,
    cellFormatter,
    onExpandRowClick
  ])

  return (
    <React.Fragment key={rowIndex}>
      <TableRow
        hover
        onClick={isRowLoading ? noop : onRowClick}
        className={clsx(classes.row, { [classes.disabled]: isRowDisabled })}
      >
        {renderCells}
      </TableRow>
      <TableRow className={clsx({ [classes.disabled]: isRowDisabled })}>
        <MuiTableCell className={classes.nestedCell} colSpan={cells.length + 1}>
          <Collapse in={cellOptions?.isExpanded} timeout='auto' unmountOnExit>
            {!isEmpty(childrenRows?.rows)
              ? (
                <Table aria-label='sub-table'>
                  <TableHead>
                    {renderTableHeaderHelper(childrenRows?.columns, classes)}
                  </TableHead>
                  <TableBody>
                    {renderTableRowsHelper({
                      rows: childrenRows?.rows,
                      onRowClick: onClick,
                      isRoot: false,
                      cellFormatter,
                      someExpanded,
                      onExpandRow,
                      rowIndexes
                    })}
                  </TableBody>
                  {!isEmpty(childrenRows?.finalRow) && (
                    <TableFooter classes={{ root: tableClasses.footer }}>
                      {renderTableRowsHelper({
                        rows: childrenRows?.finalRow,
                        isLastRow: true,
                        rowIndexes
                      })}
                    </TableFooter>
                  )}
                </Table>
              )
              : (
                isRowLoading
                  ? (
                    <Box my={4} textAlign='center'>
                      <CircularProgress />
                    </Box>
                  )
                  : (showEmptyRowOnExpandIfEmpty && (
                    <div className={classes.emptyRow}>
                      <Text text='No data to show' />
                    </div>
                  ))
              )}
          </Collapse>
        </MuiTableCell>
      </TableRow>
    </React.Fragment>
  )
}

const CollapsibleTable = ({
  allowDownload,
  asOfDate,
  rows,
  columns,
  finalRow,
  onRowClick,
  onExpandRow,
  cellFormatter,
  nestedHeaders,
  isBodyLoading,
  headerSection,
  insertColumnsAt,
  multiHeaderRows,
  onHeaderFilterChange,
  showEmptyRowOnExpandIfEmpty
}) => {
  const classes = useStyles()
  const { isPrintView } = useAppContext()

  const [exportRows, setExportRows] = useState([])
  const [exportFinalRow, setExportFinalRow] = useState([])
  const [exportLabels, setExportLabels] = useState([])

  const { isExpanded, isLoading } = useMemo(() =>
    getRowDisabledProperties(rows), [rows])

  const renderTableHeader = useMemo(() => {
    if (isEmpty(columns)) return null
    if (multiHeaderRows) {
      const finalExportColumns = []
      const renderedColumns = columns.map((columnsStack, colIndex) => {
        const { insertAtIndex: index, columns: extraColumns } = insertColumnsAt
        const columnsHydrated = insertDataAtIndex(columnsStack, extraColumns, index)
        finalExportColumns.push(columnsHydrated)
        return (
          <React.Fragment key={colIndex}>
            {renderTableHeaderHelper(columnsHydrated, classes, onHeaderFilterChange)}
          </React.Fragment>
        )
      })
      setExportLabels(finalExportColumns)
      return renderedColumns
    }
    const { insertAtIndex: index, columns: extraColumns } = insertColumnsAt
    const columnsHydrated = insertDataAtIndex(columns, extraColumns, index)
    setExportLabels(columnsHydrated)
    return renderTableHeaderHelper(columnsHydrated, classes, onHeaderFilterChange)
  },
  [columns, insertColumnsAt, multiHeaderRows, onHeaderFilterChange, classes])

  const renderTableRows = useMemo(() => {
    const { rows: extraRows, insertAtIndex: index } = insertColumnsAt
    const rowsHydrated = insertDataAtIndex(rows, extraRows, index)
    setExportRows(rowsHydrated)
    const rowsToRender = !isEmpty(rowsHydrated)
      ? renderTableRowsHelper({
        rows: rowsHydrated,
        onRowClick: isBodyLoading ? noop : onRowClick,
        onExpandRow,
        someLoading: isLoading,
        someExpanded: isExpanded,
        showEmptyRowOnExpandIfEmpty,
        isLoading: isBodyLoading,
        cellFormatter
      })
      : null
    return rowsToRender
  }, [
    rows,
    isLoading,
    isExpanded,
    onRowClick,
    onExpandRow,
    cellFormatter,
    isBodyLoading,
    insertColumnsAt,
    showEmptyRowOnExpandIfEmpty
  ])

  const renderFinalRow = useMemo(() => {
    const { finalRow: finalRowExtras, insertAtIndex: index } = insertColumnsAt
    const finalRowHydrated = insertDataAtIndex(finalRow, finalRowExtras, index)
    setExportFinalRow(finalRowHydrated)

    return !isEmpty(finalRowHydrated)
      ? renderTableRowsHelper({
        rows: [finalRowHydrated],
        isLastRow: true,
        isLoading: isBodyLoading
      })
      : null
  }, [finalRow, insertColumnsAt, isBodyLoading])

  const columnsCounter = useMemo(() => {
    if (isEmpty(columns)) return 0
    const firstColumn = first(columns)
    const columnsRef = isArray(firstColumn) ? firstColumn : columns
    return columnsRef.reduce(
      (acc, { colSpan = 1, hidden }) => (hidden ? acc : acc + colSpan),
      0
    )
  }, [columns])

  const csvData = useCSVData({
    disabled: !allowDownload,
    rows: exportRows,
    labels: exportLabels,
    finalRow: exportFinalRow,
    expansible: true,
    multiHeaderRows,
    asOfDate,
    nestedHeaders
  })

  return (
    <div className={clsx(classes.viewWrapper, { [classes.noOverflow]: isPrintView })}>
      <Box display='flex' flexDirection='row' alignItems='baseline'>
        {headerSection}
        {allowDownload && !isEmpty(csvData) && (
          <WebOnlyContent>
            <div className={classes.csvButtonContainer}>
              <CSVButton data={csvData} />
            </div>
          </WebOnlyContent>
        )}
      </Box>
      <TableContainer className={clsx({ [classes.noOverflow]: isPrintView })}>
        <Table aria-label='collapsible table' style={{ tableLayout: 'auto' }}>
          <TableHead classes={{ root: classes.header }}>
            {renderTableHeader}
          </TableHead>
          <TableBody>
            {isEmpty(rows) && isBodyLoading ? (
              <TableRow>
                <MuiTableCell
                  className={classes.skeletonCell}
                  colSpan={columnsCounter}
                >
                  <TableSkeleton
                    showHeader={false}
                    columns={columnsCounter}
                    rows={5}
                  />
                </MuiTableCell>
              </TableRow>
            ) : (
              renderTableRows
            )}
          </TableBody>
          <TableFooter classes={{ root: classes.footer }}>
            {renderFinalRow}
          </TableFooter>
        </Table>
      </TableContainer>
    </div>
  )
}

TableCell.propTypes = tableCell

TableCell.defaultProps = tableCellDefaults
Row.propTypes = {
  ...rowProps,
  row: CollapsibleTableRowsPropTypes
}

Row.defaultProps = {
  ...rowDefaults,
  row: []
}

CollapsibleTable.propTypes = {
  columns: CollapsibleTableColumnsPropTypes,
  finalRow: PropTypes.arrayOf(PropTypes.any),
  // Function to be called when expanding a row
  onExpandRow: PropTypes.func,
  // Function to be called when clicking a row
  onRowClick: PropTypes.func,
  rows: CollapsibleTableRowsPropTypes,
  insertColumnsAt: PropTypes.shape(insertColumnsAtShape),
  // Show empty row on expand if there's no data
  showEmptyRowOnExpandIfEmpty: PropTypes.bool,
  cellFormatter: PropTypes.func,
  // Enables multiple row headers
  multiHeaderRows: PropTypes.bool,
  // Function to be called when selecting an option on a header select option
  onHeaderFilterChange: PropTypes.func,
  // Table row cells turn into skeletons if true
  isBodyLoading: PropTypes.bool,
  // Allow tables to be exported in CSV format
  allowDownload: PropTypes.bool,
  // Pass down the component a specific date
  asOfDate: PropTypes.string,
  // Pass down nested headers
  nestedHeaders: PropTypes.arrayOf(PropTypes.any),
  // Component to be placed above the table
  headerSection: PropTypes.any
}

CollapsibleTable.defaultProps = {
  columns: [],
  finalRow: null,
  onExpandRow: noop,
  isRoot: false,
  isLastRow: false,
  someLoading: false,
  someExpanded: false,
  rowIndexes: [],
  showEmptyRowOnExpandIfEmpty: true,
  isLoading: false,
  onRowClick: noop,
  rows: [],
  insertColumnsAt: insertColumnsAtDefaults,
  multiHeaderRows: false,
  onHeaderFilterChange: noop,
  isBodyLoading: false,
  allowDownload: false,
  asOfDate: undefined,
  nestedHeaders: undefined,
  headerSection: undefined,
  cellFormatter: null
}

export default CollapsibleTable
