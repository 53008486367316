import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { ICON_NAMES } from '../../../../../constants'
import SydButton from '../../../../commonDesign/Button'
import { useWealthJourney } from '../WealthJourneyProvider'

function WJAddEntryButton ({ text, ...props }) {
  const { addEntry, entryTypes } = useWealthJourney()
  const canEditAtLeastOne = useMemo(() => entryTypes.some(et => et.permissions?.edit === true), [entryTypes])

  if (!canEditAtLeastOne) return null

  return (
    <SydButton icon={ICON_NAMES.add} {...props} onClick={addEntry}>{text}</SydButton>
  )
}

WJAddEntryButton.propTypes = {
  text: PropTypes.string
}

WJAddEntryButton.defaultProps = {
  text: 'New Item'
}

export default WJAddEntryButton
