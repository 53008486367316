import React from 'react'
import PropTypes from 'prop-types'
import NullValue from '../../../../../molecules/NullValue'
import AdvisorValueDisplay from './AdvisorValueDisplay'

function SsoValueDisplay ({ type, value }) {
  if (!type) return null

  if (value === null) {
    return <NullValue value='Not Set' />
  }

  if (type === 'string') {
    return (
      <div>{value}</div>
    )
  }

  if (type === 'advisor') {
    return (
      <AdvisorValueDisplay value={value} />
    )
  }

  return (
    <div>{value}</div>
  )
}

SsoValueDisplay.propTypes = {
  type: PropTypes.oneOf(['string', 'advisor']),
  value: PropTypes.any
}

export default SsoValueDisplay
