import { useHistory } from 'react-router-dom'
import { useMemo } from 'react'
import { parseUrlSearchParams } from '../utils/parseUrlSearchParams'

export const useSearchParam = (name, format = String) => {
  const history = useHistory()
  const queryParams = useMemo(() => {
    return parseUrlSearchParams(history)
  }, [history])

  return useMemo(() => {
    const value = queryParams[name]
    if (!value?.length) return null

    return format ? format(queryParams[name][0]) : queryParams[name][0]
  }, [name, format, queryParams])
}
