import React, { useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import truncate from 'lodash/truncate'
import {
  Container,
  makeStyles,
  withStyles,
  MenuItem,
  FormControl,
  Input,
  Select
} from '@material-ui/core'
import noop from 'lodash/noop'
import CheckIcon from '@material-ui/icons/Check'
import Text from '../../../atoms/Text'
import Skeleton from '../../../atoms/Skeleton'
import SplitButton from '../../../atoms/SplitButton'

import { SKELETON_VARIANTS, TEXT_VARIANTS } from '../../../../constants'

const MAX_CHARACTERS = 20
const CHARACTER_SEPARATOR = ' '

const CustomSelect = withStyles({
  root: {
    borderRadius: '8px',
    padding: '18px 10px'
  }
})(Select)

const CustomFormControl = withStyles({
  root: {
    width: '100%',
    minWidth: '100px',
    borderRadius: '8px',
    '& .Mui-disabled': {
      color: '#141929 !important',
      border: '1px solid #EEF0F8',
      background: 'linear-gradient(0deg, #F5F6F8 0%, #F5F6F8 100%), #FFF'
    }
  }
})(FormControl)

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '1.5rem 0rem',
    height: '100%',
    [theme.breakpoints.down('xs')]: {
      padding: '1.75rem'
    }
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  chip: {
    margin: '0 8px 8px 0'
  },
  title: {
    marginBottom: '5px'
  },
  formControl: {
    margin: '10px 0px'
  },
  confirmError: {
    textAlign: 'left',
    marginTop: '0.375rem'
  },
  menuItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%'
  },
  mainChip: {
    backgroundColor: '#F5F6F7 !important',
    color: `${theme.palette.summitBlue} !important`,
    fontWeight: 600,
    textTransform: 'capitalize',
    borderTopLeftRadius: '4px',
    borderBottomLeftRadius: '4px'
  },
  closeButton: {
    backgroundColor: '#F5F6F7 !important',
    color: `${theme.palette.summitBlue} !important`,
    fontWeight: 600,
    borderTopRightRadius: '4px',
    borderBottomRightRadius: '4px'
  }
}))

function PermissionGroupsSelector ({
  permissionGroups,
  selectedPermissionGroups,
  onSelectValue,
  loading
}) {
  const classes = useStyles()

  const multiOptions = useMemo(() => Object.values(permissionGroups).map((group) => ({
    label: group.name,
    value: group.permissionGroupId
  })), [permissionGroups])

  const handleChange = useCallback((event) => {
    onSelectValue(event.target.value)
  }, [onSelectValue])

  const renderCheckedIcon = useCallback(
    (option) => {
      const selectedValue = selectedPermissionGroups.find((item) => item === option.value)
      if (!selectedValue) return null
      return (
        <div className={classes.adornment}>
          <CheckIcon />
        </div>
      )
    },
    [selectedPermissionGroups, classes.adornment]
  )

  if (loading) {
    return (
      <Container maxWidth={false} className={classes.container}>
        <Skeleton
          width='100%'
          height='200px'
          variant={SKELETON_VARIANTS.rect}
          className={classes.skeleton}
        />
      </Container>
    )
  }

  return (
    <Container maxWidth={false} className={classes.container}>
      <div className={classes.header}>
        <Text text='Permisions Groups' variant={TEXT_VARIANTS.h3} className={classes.title} />
        <Text> Add <strong>Permissions</strong> you'd like this role to include. At least one permission group is required to save.</Text>
      </div>
      <CustomFormControl className={classes.formControl}>
        <CustomSelect
          labelId='demo-mutiple-chip-label'
          id='demo-mutiple-chip'
          multiple
          value={selectedPermissionGroups}
          onChange={handleChange}
          input={<Input id='select-multiple-chip' />}
          renderValue={(selected) => (
            <div className={classes.chips}>
              {selected.map((value) => (
                <div key={value} className={classes.chip}>
                  <SplitButton
                    text={truncate(permissionGroups[value]?.name, { length: MAX_CHARACTERS, separator: CHARACTER_SEPARATOR })}
                    className={classes.filterButtonGroup}
                    buttonMainClassName={classes.mainChip}
                    buttonSecondaryClassName={classes.closeButton}
                    iconFontSize='0.75rem'
                    onClickIcon={() => onSelectValue(value)}
                    tooltipText={permissionGroups[value]?.name}
                    withTooltip
                  />
                </div>
              ))}
            </div>
          )}
        >
          {multiOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              <div className={classes.menuItem}>
                <Text text={option.label} />
                {renderCheckedIcon(option)}
              </div>
            </MenuItem>
          ))}
        </CustomSelect>
      </CustomFormControl>
    </Container>
  )
}

PermissionGroupsSelector.propTypes = {
  permissionGroups: PropTypes.array,
  selectedPermissionGroups: PropTypes.arrayOf(PropTypes.number),
  onSelectValue: PropTypes.func,
  loading: PropTypes.bool
}

PermissionGroupsSelector.defaultValues = {
  permissionGroups: [],
  selectedPermissionGroups: [],
  onSelectValue: noop,
  loading: false
}

export default PermissionGroupsSelector
