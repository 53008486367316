import React, { createContext, useCallback, useContext, useLayoutEffect, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { useLocation, useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min'
import { isEmpty, isFunction } from 'lodash'
import { useOperationalTable } from '../../../../organisms/OperationalTable'
import { useSearchClients } from '../../../../../api/clients'
import { useDeepCompareMemo } from '../../../../../hooks'
import { localStorageHelper } from '../../../../../utils/localStorageHelper'
import { DIALOG_STORAGE_KEYS as STR_KEYS } from '../../../../organisms/GroupingProvider/GroupingCustomizeColumnsDialog/helpers'
import { mergeSearchParams, parseSearchParams, stringifySearchParams } from '../../../../../utils'
import useGroupTypeColumns from '../../Accounts/AccountsListTab/useGroupTypeColumns'
import { assignmentStatusOptions } from '../../../../../constants'
import { COLUMN_IDS, mapFlagFilter, mapGroupIdsToTagFilters, mapGroupTypeFilterId, transformTagFiltersToGroupQueryParams } from './helpers'
import { mapSearch, mapSort } from './hooks'

export const ClientsListContext = createContext()

export const useClientsListContext = () => {
  const contextValue = useContext(ClientsListContext)
  return contextValue
}

const ClientsListContextProvider = ({ children, configurationKey, defaultColumnConfig, urlFiltersMap }) => {
  const history = useHistory()
  const location = useLocation()

  const [visibleFilters, setVisibleFilters] = useState([])

  const {
    defaultPageSize,
    pageIndex,
    pageSize,
    sort,
    searchText,
    onPagingChange,
    onSortingChange,
    onSearchTextChange,
    onTableModeChange
  } = useOperationalTable({
    defaultSort: defaultColumnConfig.defaultSort
  })

  const [clientFilters, setClientFilters] = useState({
    clientTagFilters: [],
    benchmarksFilters: [],
    feeSchedulesFilters: []
  })

  const currentSearchParams = useMemo(() => {
    return new URLSearchParams(location.search)
  }, [location.search])

  const searchParams = useDeepCompareMemo(() => {
    const params = parseSearchParams(currentSearchParams)
    return !isEmpty(params) ? params : null
  }, [currentSearchParams])

  const { url } = useRouteMatch()

  useLayoutEffect(() => {
    const configuration = localStorageHelper.load(
        `${STR_KEYS.COLUMNS_DND_ORDER_TOGGLER}_${configurationKey}`
    )
    if (configuration) {
      const { columnOrder } = configuration
      setVisibleFilters([...columnOrder])
    }
  }, [configurationKey])

  const groupTypeColumnParams = useMemo(() => {
    const { clientTagFilters } = searchParams || {}
    return {
      query: { filters: { memberLevelTypeIds: [201] } },
      tagFilters: clientTagFilters,
      visibleFilters,
      defaultColumnConfig
    }
  }, [defaultColumnConfig, searchParams, visibleFilters])

  const {
    groupTypes,
    isLoadingGroupTypes,
    columnConfig: tableColumnConfig
  } = useGroupTypeColumns(groupTypeColumnParams)

  useEffect(() => {
    // grab filters form URL if any
    if (!isLoadingGroupTypes && !isEmpty(groupTypes)) {
      if (searchParams) {
        setClientFilters((prevState) => {
          const benchmarkFilterIds = searchParams?.benchmarksFilters || []
          const benchmarksFilters = assignmentStatusOptions.filter(({ value }) =>
            benchmarkFilterIds.includes(value)
          )

          const feeSchedulesIds = searchParams?.feeSchedulesFilters || []
          const feeSchedulesFilters = assignmentStatusOptions.filter(({ value }) =>
            feeSchedulesIds.includes(value)
          )

          const clientTagFilters = !isEmpty(prevState?.clientTagFilters)
            ? prevState.clientTagFilters
            : mapGroupIdsToTagFilters(searchParams?.clientTagFilters || [], groupTypes)

          // collect URL filter params and show its corresponding filter control
          let filterColIds = []

          if (!isEmpty(searchParams?.clientTagFilters)) {
            filterColIds = groupTypes.reduce(
              (acc, { groups, value: groupTypeValue }) => {
                const columnId = mapGroupTypeFilterId(groupTypeValue)

                const shouldShowFilter = !!groups.find(({ value }) =>
                  searchParams?.clientTagFilters.includes(value)
                )
                if (!shouldShowFilter) return acc
                return [...acc, columnId]
              },
              []
            )
          }

          if (!isEmpty(searchParams?.benchmarksFilters)) {
            filterColIds.push(COLUMN_IDS.BENCHMARKS)
          }

          if (!isEmpty(searchParams?.feeSchedulesFilters)) {
            filterColIds.push(COLUMN_IDS.FEE_SCHEDULES)
          }

          if (!isEmpty(filterColIds)) {
            setVisibleFilters((prevState) => {
              return [...new Set([...prevState, ...filterColIds])]
            })
          }

          return {
            ...prevState,
            clientTagFilters,
            benchmarksFilters,
            feeSchedulesFilters
          }
        })
      }
    }
  }, [groupTypes, isLoadingGroupTypes, searchParams, configurationKey])

  const updateUrlSearchParams = useCallback(() => {
    const {
      clientTagFilters,
      benchmarksFilters,
      feeSchedulesFilters
    } = clientFilters

    if (
      isEmpty(clientTagFilters) &&
      isEmpty(benchmarksFilters) &&
      isEmpty(feeSchedulesFilters)
    ) {
      return
    }

    const groupFilterIds = clientTagFilters?.reduce(
      (acc, filter) => [...acc, ...filter.groupIds],
      []
    )
    const benchmarksFilterIds = benchmarksFilters.map(({ value }) => value)
    const feeSchedulesFilterIds = feeSchedulesFilters.map(({ value }) => value)

    const searchParams = {
      ...(!isEmpty(groupFilterIds) ? { clientTagFilters: groupFilterIds } : {}),
      ...(!isEmpty(benchmarksFilterIds) ? { benchmarksFilters: benchmarksFilterIds } : {}),
      ...(!isEmpty(feeSchedulesFilterIds) ? { feeSchedulesFilters: feeSchedulesFilterIds } : {})
    }

    const params = new URLSearchParams(!isEmpty(searchParams) ? stringifySearchParams(searchParams) : {})
    const currentParams = new URLSearchParams(history.location.search)
    const mergedParams = mergeSearchParams(currentParams, params).toString()

    history.replace({
      pathname: url,
      search: mergedParams
    })
  }, [clientFilters, history, url])

  useEffect(() => {
    // update URL params on each filter change
    updateUrlSearchParams()
  }, [updateUrlSearchParams])

  const urlFilters = useMemo(() => {
    if (!searchParams || isEmpty(searchParams)) return null

    return Object.entries(searchParams).reduce(
      (acc, [paramKey, paramValue]) => {
        if (!urlFiltersMap[paramKey] || !paramValue) return acc
        const { filterKey, op } = urlFiltersMap[paramKey]
        return { ...acc, [filterKey]: [{ op, value: paramValue }] }
      },
      {}
    )
  }, [searchParams, urlFiltersMap])

  const { query: clientQuery, queryOptions: clientQueryOptions } = useMemo(() => {
    const { groupIds, missingGroupType } = transformTagFiltersToGroupQueryParams(
      clientFilters.clientTagFilters
    )
    const hasBenchmarks = mapFlagFilter(clientFilters.benchmarksFilters)
    const hasFeeSchedules = mapFlagFilter(clientFilters.feeSchedulesFilters)
    return {
      query: {
        sort: mapSort(sort),
        skip: pageIndex * pageSize || 0,
        take: pageSize,
        textSearch: mapSearch(searchText),
        includes: {
          benchmarks: true,
          feeSchedules: true,
          integrations: true,
          groups: true
        },
        options: {
          enabled: !isLoadingGroupTypes
        },
        filters: {
          ...(!isEmpty(groupIds) ? { groupIds } : {}),
          ...(missingGroupType !== undefined ? { missingGroupType } : {}),
          ...(hasBenchmarks !== undefined ? { hasBenchmarks } : {}),
          ...(hasFeeSchedules !== undefined ? { hasFeeSchedules } : {}),
          ...(urlFilters ?? {})
        }
      },
      queryOptions: {
        mapper: ({ data }) => data ?? []
      }
    }
  }, [
    sort,
    pageSize,
    pageIndex,
    searchText,
    urlFilters,
    clientFilters,
    isLoadingGroupTypes
  ])

  const onChangeFilters = useCallback(
    (filters) => {
      setClientFilters((prevState) => {
        const state = isFunction(filters) ? filters(prevState) : filters

        if (Object.values(state).every(isEmpty)) {
          history.replace({ pathname: url })
        }
        return state
      })
    },
    [url, history]
  )

  const { data, isLoading } = useSearchClients(clientQuery, clientQueryOptions)

  const value = useMemo(
    () => ({
      clients: data ?? [],
      isLoading,
      groupTypes,
      isLoadingGroupTypes,
      operationalTable: {
        onPagingChange,
        onSortingChange,
        onSearchTextChange,
        onTableModeChange,
        defaultPageSize
      },
      searchText,
      columnConfig: tableColumnConfig,
      clientFilters,
      setClientFilters,
      visibleFilters,
      setVisibleFilters,
      onChangeFilters,
      configurationKey
    }),
    [
      data,
      isLoading,
      searchText,
      onPagingChange,
      defaultPageSize,
      onSortingChange,
      onTableModeChange,
      onSearchTextChange,
      tableColumnConfig,
      clientFilters,
      groupTypes,
      setClientFilters,
      isLoadingGroupTypes,
      visibleFilters,
      onChangeFilters,
      configurationKey
    ]
  )

  return (
    <ClientsListContext.Provider value={value}>
      {children}
    </ClientsListContext.Provider>
  )
}

ClientsListContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
  defaultColumnConfig: PropTypes.object,
  configurationKey: PropTypes.string,
  urlFiltersMap: PropTypes.object
}

ClientsListContextProvider.defaultProps = {
  configurationKey: 'adminClientListView',
  urlFiltersMap: {}
}

export default ClientsListContextProvider
