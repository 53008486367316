import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import noop from 'lodash/noop'
import { Box, darken } from '@material-ui/core'
import { TEXT_VARIANTS, BUTTON_SIZES, BUTTON_VARIANT } from '../../constants'
import { useSetAppContext } from '../../redux/slices/appContext'
import { makeThemedStyles } from '../../hooks/useThemedStyles'
import WealthJourneyMeeting from '../molecules/WealthJourneyMeeting'
import DatePeriodTabs from '../molecules/DatePeriodTabs'
import Dialog from '../molecules/Dialog'
import Text from '../atoms/Text'
import Skeleton from '../atoms/Skeleton'
import RoundedButton from '../atoms/RoundedButton'
import { useToggle } from '../../hooks'
import { clearSuppression, setSuppression } from '../../utils/globalSuppression'
import WealthJourneyMeetingForm from '../molecules/WealthJourneyMeetingForm'
import entryType, { ACTIONS, entryTypeIdentifier } from './WealthJourney/v2/entryType'
import { useWealthJourneyPermissions } from './WealthJourney/shared/hooks'

const useStyles = makeThemedStyles((theme) => ({
  main: {
    width: '100%'
  },
  meetingSelector: {
    marginBottom: '0.75rem',
    marginTop: '0.75rem'
  },
  emptySection: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '20px 36px',
    border: '2px solid #EEF0F8',
    borderRadius: '12px',
    width: '100%',
    height: '270px'
  },
  notesHeader: {
    width: '100%',
    margin: '36px auto',
    maxWidth: '1200px'
  },
  cancelButton: {
    backgroundColor: theme.palette.common.white,
    color: '#212945',
    border: '2px solid #212945',
    '&:hover': {
      backgroundColor: theme.palette.gray.light
    }
  },
  submitButton: {
    backgroundColor: '#FADBD7',
    color: '#D44333',
    '&:hover': {
      backgroundColor: darken('#FADBD7', 0.1)
    }
  }
}))

const WealthJourneyMeetingList = ({
  entries,
  entriesLoading,
  selectedEntry,
  onEntrySelected,
  onAddEntry,
  onEditEntry,
  onDeleteEntry
}) => {
  const [classes] = useStyles()
  const setAppContext = useSetAppContext()
  const canEditMeetings = useWealthJourneyPermissions(entryType.MEETINGS, ACTIONS.EDIT)
  const [editMode, , toggleEditModeOn, toggleEditModeOff] = useToggle(false)
  const [showForm, , setShowFormOn, setShowFormOff] = useToggle(false)
  const [showModal, , setShowModalOn, setShowModalOff] = useToggle(false)
  const hasNoMeetings = useMemo(() => entries.length === 0, [entries.length])
  const hasManyMeetings = useMemo(() => entries.length > 1, [entries.length])
  const meetingOptions = useMemo(() => {
    return entries.map(e => ({
      label: dayjs.utc(e.entryDate).format('MMM D, YYYY'),
      value: e,
      entryDate: dayjs.utc(e.entryDate)
    }))
  }, [entries])

  const selectedMeeting = useMemo(() => {
    if (selectedEntry?.entryTypeId === entryTypeIdentifier.MEETINGS) {
      return meetingOptions.find(x => x.value === selectedEntry)
    }
    return null
  }, [selectedEntry, meetingOptions])

  const handleDelete = useCallback(() => {
    if (selectedEntry) onDeleteEntry(selectedEntry.entryId)
    setShowModalOff()
  }, [onDeleteEntry, selectedEntry, setShowModalOff])

  const dialogTitle = useMemo(() => (
    <Text
      text='Archive Meeting?'
      customFontSize='1.5rem'
      color='#212945'
      customFontWeight={600}
    />
  ), [])

  const dialogBody = useMemo(() => (
    <Text customFontSize='1rem' color='#212945'>
      Are you sure you want to archive this meeting?
    </Text>
  ), [])

  const actions = useMemo(() => ([
    {
      additionalClassName: classes.cancelButton,
      disabled: false,
      handleClick: setShowModalOff,
      label: 'Cancel',
      variant: BUTTON_VARIANT.outlined
    },
    {
      additionalClassName: classes.submitButton,
      autofocus: true,
      disabled: false,
      handleClick: handleDelete,
      label: 'Yes, archive it',
      loading: false
    }
  ]), [setShowModalOff, handleDelete, classes.cancelButton, classes.submitButton])

  const setSelectedMeeting = useCallback((option) => {
    onEntrySelected(option.value)
  }, [onEntrySelected])

  const setEditModeOn = useCallback(() => {
    setAppContext({ isFullViewEditModeOn: true })
    setSuppression('wheel', true)
    setShowFormOn()
  }, [setAppContext, setShowFormOn])

  const setEditModeOff = useCallback(() => {
    setAppContext({ isFullViewEditModeOn: false })
    clearSuppression('wheel')
    toggleEditModeOff()
    setShowFormOff()
  }, [setAppContext, setShowFormOff, toggleEditModeOff])

  const onClickEditMeeting = useCallback(() => {
    toggleEditModeOn()
    setEditModeOn()
  }, [toggleEditModeOn, setEditModeOn])

  const NotesHeader = useMemo(() => (
    <Box
      className={classes.notesHeader}
      display='flex'
      flexDirection='row'
      justifyContent='space-between'
      alignItems='center'
    >
      <Text text='Meeting Notes' variant={TEXT_VARIANTS.h1} />
      {canEditMeetings && (
        <RoundedButton
          primary
          onClick={setEditModeOn}
          size={BUTTON_SIZES.extraSmall}
          disabled={showForm}
        >
          + Add Notes
        </RoundedButton>
      )}
    </Box>
  ), [showForm, setEditModeOn, classes.notesHeader, canEditMeetings])

  if (entriesLoading) return <Skeleton height='270px' width='100%' />

  if (hasNoMeetings && !showForm) {
    return (
      <>
        {NotesHeader}
        <div className={classes.emptySection}>
          <Text
            text='No Meeting Notes'
            customFontSize='20px'
            color='#242749'
            customFontWeight={600}
          />
          {canEditMeetings && (
            <Text
              customFontSize='16px'
              color='#212945'
            >
              Click <strong>Add Notes</strong> Button to begin
            </Text>
          )}
        </div>
      </>
    )
  }

  return (
    <div className={classes.main}>
      {hasManyMeetings && (
        <div className={classes.meetingSelector}>
          <DatePeriodTabs
            tabs={meetingOptions}
            selectedTabStep={selectedMeeting}
            onTabStepClick={setSelectedMeeting}
          />
        </div>
      )}
      {NotesHeader}
      {showForm && canEditMeetings && (
        <WealthJourneyMeetingForm
          editMode={editMode}
          onClose={setEditModeOff}
          selectedEntry={selectedEntry}
          onAddEntry={onAddEntry}
          onEditEntry={onEditEntry}
          setSelectedMeeting={setSelectedMeeting}
        />
      )}
      {!showForm && selectedMeeting && (
        <WealthJourneyMeeting
          meetingEntry={selectedMeeting.value}
          onEditMeeting={onClickEditMeeting}
          onDeleteMeeting={setShowModalOn}
          canEditMeetings={canEditMeetings}
        />
      )}
      <Dialog
        actions={actions}
        body={dialogBody}
        handleClose={setShowModalOff}
        open={showModal}
        title={dialogTitle}
      />
    </div>
  )
}

WealthJourneyMeetingList.propTypes = {
  entries: PropTypes.array.isRequired,
  entriesLoading: PropTypes.bool,
  selectedEntry: PropTypes.object,
  onEntrySelected: PropTypes.func.isRequired,
  onAddEntry: PropTypes.func,
  onEditEntry: PropTypes.func,
  onDeleteEntry: PropTypes.func
}

WealthJourneyMeetingList.defaultProps = {
  entriesLoading: false,
  selectedEntry: null,
  onAddEntry: noop,
  onEditEntry: noop,
  onDeleteEntry: noop
}

export default WealthJourneyMeetingList
