import React from 'react'
import { makeStyles } from '@material-ui/core'
import ViewTabs from '../../../organisms/AdvisorView/components/AdvisorViewTabs/index'
import { useCheckPolicy } from '../../../../hooks'
import NotAvailable from '../../../organisms/NotAvailable'
import TargetModelsListTab from './TargetModelsListTab'
import HeaderActions from './HeaderActions'
import ComponentModelsListTab from './ComponentModelsListTab'

const tabOptions = {
  'target-models': {
    label: 'Target Models',
    value: 'target-models'
  },
  'component-models': {
    label: 'Component Models',
    value: 'component-models'
  }
}

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '1rem'
  },
  actions: {
    marginLeft: 'auto',
    display: 'flex',
    gap: '0.5rem'
  }
}))

function ModelAdmin () {
  const classes = useStyles()
  const canView = useCheckPolicy('admin_models_view')

  if (!canView) {
    return <NotAvailable />
  }

  return (
    <div className={classes.container}>
      <ViewTabs.Group tabOptions={tabOptions}>
        <ViewTabs.Links
          tabsKey='admin_models_list'
          rightAdornment={<HeaderActions />}
        />
        <ViewTabs.Tab value='target-models'>
          <TargetModelsListTab />
        </ViewTabs.Tab>
        <ViewTabs.Tab value='component-models'>
          <ComponentModelsListTab />
        </ViewTabs.Tab>
      </ViewTabs.Group>
    </div>
  )
}

ModelAdmin.propTypes = {
}

ModelAdmin.defaultProps = {
}

export default ModelAdmin
