import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Switch, useHistory, useRouteMatch } from 'react-router-dom'
import { SecureRoute } from '@okta/okta-react'
import isEmpty from 'lodash/isEmpty'
import { parseUrlSearchParams } from '../../../../utils/parseUrlSearchParams'
import { mergeSearchParams } from '../../../../utils'
import TransactionsTableContainer, { transactionsTableContainerDefaultProps, transactionsTableContainerProps } from './TransactionsTableContainer'
import { cleanEmptyParams, parseUrlArrayParam } from './helpers'

const pickIfNonEmpty = (target, defaultValue) =>
  !isEmpty(target) ? target : defaultValue

const useTransactionsUrlParams = () => {
  const history = useHistory()

  const urlParams = useMemo(() => {
    const searchParams = parseUrlSearchParams(history)
    if (isEmpty(searchParams)) return {}

    const dateRange = searchParams?.dateRange?.[0]
    const transactionCodeTagId = searchParams?.transactionCodeTagId?.[0]

    const tagIds = parseUrlArrayParam(searchParams?.tagIds)
    const assetIds = parseUrlArrayParam(searchParams?.assetIds)
    const custodianIds = parseUrlArrayParam(searchParams?.custodianIds)
    const groupIds = parseUrlArrayParam(searchParams?.a)

    return {
      transactionCodeTagId,
      custodianIds,
      dateRange,
      assetIds,
      tagIds,
      groupIds
    }
  }, [history])

  return urlParams
}

const TransactionsView = (props) => {
  const history = useHistory()
  const urlParams = useTransactionsUrlParams()
  const [urlSearchParams, setUrlSearchParams] = useState(urlParams)

  const { path, url } = useRouteMatch()

  useEffect(() => {
    if (!isEmpty(urlSearchParams)) {
      const cleanedSearchParams = cleanEmptyParams(urlSearchParams)
      const transactionsParams = new URLSearchParams(cleanedSearchParams)
      const currentParams = new URLSearchParams(history.location.search)
      const mergedParams = mergeSearchParams(currentParams, transactionsParams)
      history.replace({ pathname: url, search: mergedParams.toString() })
    }
  }, [history, url, urlSearchParams])

  const onChangeSearchParams = useCallback(
    (searchParams) => {
      const [[key, value]] = Object.entries(searchParams)

      setUrlSearchParams((prevState) => {
        if (prevState[key] && prevState[key] === value) return prevState
        return {
          ...prevState,
          ...searchParams
        }
      })
    },
    []
  )

  const tableProps = useMemo(() => {
    const {
      tagIds,
      assetIds,
      dateRange,
      custodianIds,
      transactionCodeTagId
    } = urlParams
    return {
      defaultDateRange: pickIfNonEmpty(dateRange, props.defaultDateRange),
      defaultTransactionCodeTag: !isEmpty(transactionCodeTagId)
        ? { transactionCodeTagId }
        : null,
      defaultTagValues: pickIfNonEmpty(tagIds, props.defaultTagValues),
      defaultAssetValues: pickIfNonEmpty(assetIds, props.defaultAssetValues),
      defaultCustodianValues: pickIfNonEmpty(custodianIds, props.defaultCustodianValues)
    }
  }, [
    props.defaultCustodianValues,
    props.defaultAssetValues,
    props.defaultDateRange,
    props.defaultTagValues,
    urlParams
  ])

  return (
    <Switch>
      <SecureRoute path={path} exact>
        <TransactionsTableContainer
          {...props}
          {...tableProps}
          filters={urlSearchParams}
          onChangeSearchParams={onChangeSearchParams}
        />
      </SecureRoute>
    </Switch>
  )
}

TransactionsView.propTypes = {
  ...transactionsTableContainerProps
}

TransactionsView.defaultProps = {
  ...transactionsTableContainerDefaultProps
}

export default TransactionsView
