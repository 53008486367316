import React from 'react'
import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom'

const SiteRedirect = ({ to }) => {
  return (
    <Redirect to={to} />
  )
}

SiteRedirect.propTypes = {
  to: PropTypes.string.isRequired
}

export default SiteRedirect
