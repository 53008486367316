import { useMutation, useQuery } from '@tanstack/react-query'
import dayjs from 'dayjs'
import {
  getExport,
  postExport
} from '../service'
import { useAppContext } from '../redux/slices/appContext'
import { QUERY_KEYS } from './queryKeys'

export const exportTypes = {
  REPORT_13F: 'REPORT_13_F'
}

export const useCreateExport = (exportType, options = {}) => {
  return useMutation({
    onSuccess: options.onSuccess,
    mutationFn: async (exportType, options = {}) => {
      const {
        asOfDate: aod,
        format = 'csv',
        formattedDate: asOfDate = dayjs(aod).format('YYYY-MM-DD'),
        ...rest
      } = options

      const { data } = await postExport({
        metadata: {
          ...rest,
          exportType,
          asOfDate,
          format
        }
      })

      return data
    }
  })
}

export const useGetExport = (exportId, options = {}) => {
  const { userId } = useAppContext()
  return useQuery({
    enabled: options?.enabled ?? true,
    queryKey: [QUERY_KEYS.exports, userId, exportId],
    queryFn: async () => {
      const { data } = await getExport(exportId)

      return data
    }
  })
}

export const useListExports = () => {

}
export const useStartExportMutation = () => {
  return useMutation({
    mutationFn: async (metadata) => {
      const { data } = await postExport({
        metadata
      })

      return data
    }
  })
}

/**
 * Because we use this in a callback, it's a mutation
 */
export const useMonitorExportStatusMutation = () => {
  return useMutation({
    mutationFn: async (exportId) => {
      const { data } = await getExport(exportId)

      return data
    }
  })
}

export const useDownloadExportMutation = () => {
  return useMutation({
    mutationFn: async (exportUrl, filename) => {
      try {
        const link = document.createElement('a')
        link.href = exportUrl
        link.download = filename
        document.body.appendChild(link)
        link.click()
        link.remove()
      } catch (error) {
        console.error(error)
      }
    }
  })
}
