import { useMemo, useState } from 'react'
import { isEmpty } from 'lodash'
import {
  MILLISECONDS_BEFORE_SEARCH,
  DEFAULT_SELECT_SEARCH_PAGINATION
} from '../constants'
import { useGroupSearch } from '../api/groups'
import { useWealthJourneyQuery } from '../api/wealthJourney'
import useDebouncedValue from './useDebounceValue'
import { getSearchQuery } from './helpers'

const SEARCH_FIELDS = ['entryText']

const getQueryFilters = ({ query }) => getSearchQuery(SEARCH_FIELDS, query)

const defaultMapper = ({ data }) => {
  return data.map((datum) => ({
    key: datum.entryId,
    value: datum.entryId,
    label: datum.entryText,
    payload: datum
  }))
}

export const ENTRY_TYPES = {
  MILESTONES: 1
}

const key = 'debounced_entries'

const useSearchWealthJourneyEntriesDebounced = (
  entryType,
  query = {},
  queryOptions = {},
  defaultMilestonesIds = [],
  debounceDelay = MILLISECONDS_BEFORE_SEARCH
) => {
  const [queryText, setQuery] = useState('')
  const debouncedQuery = useDebouncedValue(queryText, debounceDelay)

  const { queryParams, queryParamOptions } = useMemo(() => {
    const queryParams = getQueryFilters({
      query: debouncedQuery
    })

    return {
      queryParams: {
        includes: {
          client: true,
          category: true
        },
        ...DEFAULT_SELECT_SEARCH_PAGINATION,
        ...query,
        filters: {
          entryTypeId: [{ op: 'eq', value: entryType }],
          ...(query?.filters || {})
        },
        textSearch: queryParams
      },
      queryParamOptions: {
        mapper: defaultMapper,
        ...queryOptions
      }
    }
  }, [debouncedQuery, entryType, query, queryOptions])

  const queryKey = `${key}_${entryType}`

  const { data, isLoading } = useWealthJourneyQuery(queryKey, queryParams, queryParamOptions)

  const defaultOptionsQuery = useMemo(() => {
    return {
      queryParams: {
        filters: {
          groupIds: defaultMilestonesIds
        }
      },
      queryParamOptions: {
        mapper: defaultMapper,
        enabled: !isEmpty(defaultMilestonesIds)
      }
    }
  }, [defaultMilestonesIds])

  const { data: defaultOptions, isLoading: isLoadingDefaults } = useGroupSearch(
    defaultOptionsQuery.queryParams,
    defaultOptionsQuery.queryParamOptions
  )

  const isTransitioningOptions = isLoading || debouncedQuery !== queryText

  return {
    options: isLoading ? [] : data ?? [],
    isLoading: isLoading || (isLoadingDefaults && !isEmpty(defaultMilestonesIds)),
    onChangeQuery: setQuery,
    isSearchLoading: isTransitioningOptions,
    defaultOptions
  }
}

export default useSearchWealthJourneyEntriesDebounced
