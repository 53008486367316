import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import useToggle from '../../../hooks/useToggle'
import VisualBalanceTreeMapChart from './VisualBalanceTreeMapChart'
import {
  VisualBalanceHoldingTableColumnsShape,
  VisualBalanceHoldingTableDefaultSortShape,
  VisualBalanceLabelShape,
  VisualBalanceSectionsShape
} from './utils'
import VisualBalanceDrillDown from './VisualBalanceDrilldown'
import VisualBalanceOtherAssets from './VisualBalanceOtherAssets'

function VisualBalanceTreeView ({
  client,
  data,
  enableDrilldown,
  height,
  labels,
  onClick,
  onPressAccount,
  width,
  sections,
  splitAssetClassTagIds,
  overviewFormat,
  detailFormat,
  holdingTable,
  showOtherAssetCards,
  maxBlocksPerGroupInDrilldown
}) {
  const [selectedBlock, setSelectedBlock] = useState()
  const [
    showPortfolioBreakdown,
    ,
    togglePortfolioBreakdownOn,
    togglePortfolioBreakdownOff
  ] = useToggle()

  const onBack = useCallback(() => {
    togglePortfolioBreakdownOff()
    onClick(null)
    setSelectedBlock(undefined)
  }, [
    onClick,
    setSelectedBlock,
    togglePortfolioBreakdownOff
  ])

  const onBlockPress = useCallback(
    (item) => {
      if (enableDrilldown) {
        onClick(item)
        togglePortfolioBreakdownOn()
        setSelectedBlock(item)
      }
    },
    [enableDrilldown, onClick, setSelectedBlock, togglePortfolioBreakdownOn]
  )

  if (showPortfolioBreakdown) {
    return (
      <VisualBalanceDrillDown
        height={height}
        onClickBack={onBack}
        onPressAccount={onPressAccount}
        selectedBlock={selectedBlock}
        backButtonText={labels.drillDown?.backButton}
        splitAssetClassTagIds={splitAssetClassTagIds}
        holdingTableColumns={holdingTable.columns}
        holdingTableDefaultSort={holdingTable.defaultSort}
        overviewFormat={overviewFormat}
        maxBlocksPerGroup={maxBlocksPerGroupInDrilldown}
        otherLabel={labels.drillDown?.other}
      />
    )
  }

  return (
    <>
      <VisualBalanceTreeMapChart
        client={client}
        data={data}
        height={height}
        totalTitle={labels.chart?.totalTitle}
        blocksTitle={labels.chart?.blocksTitle}
        onClick={onBlockPress}
        width={width}
        sections={sections}
        splitAssetClassTagIds={splitAssetClassTagIds}
        overviewFormat={overviewFormat}
        detailFormat={detailFormat}
      />
      {showOtherAssetCards && (
        <VisualBalanceOtherAssets
          title={labels.otherAssets?.title}
          data={data?.other}
          format={detailFormat}
        />
      )}
    </>
  )
}

VisualBalanceTreeView.propTypes = {
  client: PropTypes.shape({
    clientAbbreviation: PropTypes.string,
    avatarUrl: PropTypes.string
  }),
  data: PropTypes.objectOf(PropTypes.any),
  enableDrilldown: PropTypes.bool,
  height: PropTypes.number,
  labels: VisualBalanceLabelShape,
  onClick: PropTypes.func,
  onPressAccount: PropTypes.func,
  width: PropTypes.string,
  sections: VisualBalanceSectionsShape,
  splitAssetClassTagIds: PropTypes.arrayOf(PropTypes.number),
  overviewFormat: PropTypes.string,
  detailFormat: PropTypes.string,
  holdingTable: {
    columns: PropTypes.arrayOf(VisualBalanceHoldingTableColumnsShape),
    defaultSort: PropTypes.arrayOf(VisualBalanceHoldingTableDefaultSortShape)
  },
  showOtherAssetCards: PropTypes.bool,
  maxBlocksPerGroupInDrilldown: PropTypes.number
}

VisualBalanceTreeView.defaultProps = {
  data: {},
  enableDrillDown: false,
  height: undefined,
  labels: {},
  onClick: noop,
  onPressAccount: noop,
  width: undefined,
  sections: {},
  splitAssetClassTagIds: []
}

export default VisualBalanceTreeView
