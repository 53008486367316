import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeThemedStyles } from '../../hooks/useThemedStyles'

const useStyles = makeThemedStyles((theme) => ({
  defaultMilestoneDescription: {
    fontWeight: 400,
    fontFamily: theme.typography.fontFamily,
    whiteSpace: 'pre-line',
    wordWrap: 'break-word'
  }
}))

const PlaintextMilestoneDescription = ({ entry, className }) => {
  const [classes] = useStyles()

  if (!entry) return null

  return (
    <pre className={clsx(className, classes.defaultMilestoneDescription)}>
      {entry.description}
    </pre>
  )
}

PlaintextMilestoneDescription.propTypes = {
  entry: PropTypes.object,
  className: PropTypes.string
}

export default PlaintextMilestoneDescription
