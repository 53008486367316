import React, { useCallback, useImperativeHandle, useState } from 'react'
import RoundedModal from '../../../../../../molecules/RoundedModal'
import RemoveOverrideForm from './form'

const RemoveOverrideDialog = React.forwardRef(function RemoveOverrideDialog (_, ref) {
  const [dialogState, setDialogState] = useState({ open: false, processing: false })
  const close = useCallback(() => {
    setDialogState({ open: false, processing: false })
  }, [setDialogState])

  useImperativeHandle(ref, () => ({
    open: (client, override, name) => setDialogState({ open: true, processing: false, client, override, name })
  }), [setDialogState])

  return (
    <RoundedModal
      title='Remove Override'
      open={dialogState.open}
      onClose={close}
      position='top'
      size='xmedium'
      disablePortal={false}
    >
      <div>
        {dialogState.open ? (
          <RemoveOverrideForm
            client={dialogState.client}
            override={dialogState.override}
            name={dialogState.name}
            onComplete={close}
            onCancel={close}
          />
        ) : null}
      </div>
    </RoundedModal>
  )
})

RemoveOverrideDialog.propTypes = {
}

export default RemoveOverrideDialog
