import React, { useCallback, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Box } from '@material-ui/core'
import dayjs from 'dayjs'
import isEmpty from 'lodash/isEmpty'
import {
  CALC_TYPES,
  DAYJS_OPERATIONS,
  DAYJS_UNIT_TYPES,
  DATE_TYPES,
  LEVEL_TYPES,
  TEXT_VARIANTS
} from '../../constants'
import { useFetchState } from '../../hooks'
import { useAppContext, useSetAppContext } from '../../redux/slices/appContext'
import { fetchBalanceInformation } from '../../service'
import {
  getSafeDate,
  mergeBalanceInformation,
  numeralByCase
} from '../../utils'
import NumberFormat from '../atoms/NumberFormat'
import Tag from '../atoms/Tag'
import Text from '../atoms/Text'
import AvailableDatePicker from './AvailableDatePicker'
import FilterList from './FilterList'
import TitleWithSubtitle from './TitleWithSubtitle'

const onAvailableDatesChangeStrategy = {
  default: (date, availableDates, setAppContext, setViewContext) => {
    const mainDate = dayjs(date).format('YYYY-MM-DD')
    setAppContext({
      availableDates: {
        ...availableDates,
        mainDate
      }
    })
    const availableDatesModified = {
      min: availableDates.min,
      max: mainDate,
      mainDate
    }
    setViewContext({
      minClientDate: getSafeDate(availableDatesModified, {
        useMin: true
      }),
      maxClientDate: getSafeDate(availableDatesModified, {
        useMax: true
      }),
      mainDate: dayjs(date).format('YYYY-MM-DD')
    })
  },
  customNearestDate: (date, availableDates, setAppContext, setViewContext) => {
    const mainDate = dayjs(date).format('YYYY-MM-DD')
    setAppContext({
      availableDates: { ...availableDates, mainDate }
    })
    const availableDatesModified = {
      min: availableDates.min,
      max: mainDate,
      mainDate
    }
    const lastQuarter = getSafeDate(availableDatesModified, {
      operation: DAYJS_OPERATIONS.START_OF,
      unitType: DAYJS_UNIT_TYPES.QUARTER
    })
    const lastYear = getSafeDate(availableDatesModified, {
      operation: DAYJS_OPERATIONS.START_OF,
      unitType: DAYJS_UNIT_TYPES.YEAR
    })
    setViewContext({
      minClientDate: getSafeDate(availableDatesModified, { useMin: true }),
      maxClientDate: getSafeDate(availableDatesModified, { useMax: true }),
      mainDate: dayjs(date).format('YYYY-MM-DD'),
      graphDate: getSafeDate(availableDatesModified, {
        operation: DAYJS_OPERATIONS.SUBTRACT,
        unitType: DAYJS_UNIT_TYPES.MONTH,
        unitValue: 12
      }),
      secondColumnDate: lastQuarter,
      selectedSecondColumnDate: lastQuarter + '_QD',
      thirdColumnDate: lastYear,
      selectedThirdColumnDate: lastYear + '_YD',
      streamChartDate: getSafeDate(availableDatesModified, {
        operation: 'subtract',
        unitType: 'month',
        unitValue: 12
      })
    })
  }
}

const PortfolioAllocationTotalBalanceSummary = ({
  viewFilters,
  setViewFilters,
  viewContext,
  titleFormat: _titleFormat,
  setViewContext,
  showFilteredTag,
  fetchParams,
  showFiltersList,
  showInvestableAssetsLabel,
  disableOnClickBalanceFormatToggle,
  onAvailableDatesChangeStrategyKey
}) => {
  const appContext = useAppContext()
  const setAppContext = useSetAppContext()
  const [titleFormat, setTitleFormat] = useState(_titleFormat || '$0,0a')
  const { mainDate, minClientDate } = viewContext

  const getBalanceInformation = useCallback(
    async (setSafeState) => {
      const {
        ASSET_CLASSES,
        SUBCLASSES,
        ASSETS_SUBCLASS,
        ASSETS: _ASSETS
      } = viewFilters
      const ASSETS = ASSETS_SUBCLASS || _ASSETS

      const assetClassTagIds = ASSET_CLASSES ? [ASSET_CLASSES?.id] : []
      const subclassTagIds = SUBCLASSES ? [SUBCLASSES?.id] : []
      const assetIds = ASSETS ? [ASSETS?.id] : []

      const levelTypes = [
        LEVEL_TYPES.CLIENT,
        ASSET_CLASSES && LEVEL_TYPES.ASSET_CLASS_TAG,
        ASSETS && LEVEL_TYPES.ASSETS
      ].filter(Boolean)

      const startDate =
        dayjs.utc(mainDate).valueOf() < dayjs.utc(minClientDate).valueOf()
          ? minClientDate
          : mainDate

      const { data } = await fetchBalanceInformation({
        clientIds: [appContext.clientId],
        startDate,
        endDate: mainDate,
        dateType: DATE_TYPES.all,
        assetClassTagIds,
        subclassTagIds,
        assetIds,
        levelTypes,
        calcType: CALC_TYPES.balance,
        ...(isEmpty(fetchParams) ? {} : fetchParams)
      })
      setSafeState({
        balanceInformation: mergeBalanceInformation(data)
      })
    },
    [viewFilters, mainDate, fetchParams, minClientDate, appContext.clientId]
  )

  const { balanceInformation } = useFetchState(getBalanceInformation)

  const filterItems = useMemo(() => {
    return Object.values(viewFilters).map((filter) => ({
      ...filter,
      onRemoveClick: ({ payload: { classType: propKey } }) => {
        const { [propKey]: filterKey, ...filtersRest } = viewFilters
        setViewFilters(filtersRest)
      }
    }))
  }, [viewFilters, setViewFilters])

  const onAvailableDatePickerChangeStrategy = useCallback(
    (date) => {
      return onAvailableDatesChangeStrategy[onAvailableDatesChangeStrategyKey](
        date,
        appContext.availableDates,
        setAppContext,
        setViewContext
      )
    },
    [
      setAppContext,
      setViewContext,
      appContext.availableDates,
      onAvailableDatesChangeStrategyKey
    ]
  )

  const onNumberFormatTitleClick = useCallback(() => {
    if (!disableOnClickBalanceFormatToggle) {
      setTitleFormat((prevTitleFormat) =>
        prevTitleFormat === '$0,0a' ? '$0,0' : '$0,0a'
      )
    }
  }, [disableOnClickBalanceFormatToggle])

  return (
    <Box>
      <TitleWithSubtitle
        titleElement={
          <AvailableDatePicker
            value={viewContext.mainDate}
            extraStyles={{
              fontSize: '1rem',
              minWidth: '16rem',
              fontFamily: 'Gotham-Book',
              '& input': {
                textAlign: 'left'
              }
            }}
            disableFuture
            onChange={onAvailableDatePickerChangeStrategy}
            type='date'
            format='[As of] MMMM DD, YYYY'
          />
        }
        subtitleElement={
          <Text
            variant='body2'
            customFontFamily='Gotham-Book'
            customFontSize='48px'
            textTransform='none'
            text={
              <NumberFormat
                title={numeralByCase(balanceInformation?.balance || 0)}
                number={balanceInformation?.balance || 0}
                format={titleFormat}
                onClick={onNumberFormatTitleClick}
              />
            }
          />
        }
      />
      {showInvestableAssetsLabel && (
        <Text
          text='Investable Assets'
          variant={TEXT_VARIANTS.h4}
          customFontSize='1rem'
          customFontFamily='Gotham-Book'
        />
      )}
      {showFilteredTag && <Tag label='Filtered' />}
      {showFiltersList && <FilterList filterItems={filterItems} />}
    </Box>
  )
}

PortfolioAllocationTotalBalanceSummary.propTypes = {
  viewFilters: PropTypes.object,
  setViewFilters: PropTypes.func,
  viewContext: PropTypes.object,
  titleFormat: PropTypes.string,
  setViewContext: PropTypes.func,
  showFilteredTag: PropTypes.bool,
  fetchParams: PropTypes.object,
  showFiltersList: PropTypes.bool,
  showInvestableAssetsLabel: PropTypes.bool,
  disableOnClickBalanceFormatToggle: PropTypes.bool,
  onAvailableDatesChangeStrategyKey: PropTypes.string
}

PortfolioAllocationTotalBalanceSummary.defaultProps = {
  showFiltersList: true
}

export default PortfolioAllocationTotalBalanceSummary
