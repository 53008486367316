import React from 'react'
import EmptySection from '../../../../atoms/EmptySection'

function AllAccounts () {
  return (
    <EmptySection title='Not Available' description='This advisor has access to all accounts' />
  )
}

export default AllAccounts
