import React, { useMemo } from 'react'
import { Route, Switch, useParams } from 'react-router-dom'
import { useAdvisorHomeNavigation } from './NavigatationContext'
import AdvisorHomeTemplate from './AdvisorHomeTemplate'
import NotFound from './NotFound'

const flatten = (treeRoot, accumulator = []) => {
  accumulator.push({ ...treeRoot, children: undefined })
  treeRoot.children?.forEach(ch => flatten(ch, accumulator))

  return accumulator
}

function Template () {
  const { template } = useParams()
  const { templateTree } = useAdvisorHomeNavigation()
  const templateMap = useMemo(() => {
    const root = templateTree.find(x => x.pathSegment === template)
    if (!root) return null

    return flatten(root, [])
  }, [template, templateTree])

  return (
    <Switch>
      {templateMap.map(tm => (
        <Route key={tm.advisorViewTemplateId} exact path={tm.path}>
          <AdvisorHomeTemplate config={tm} defaultRedirect={tm.parentPath} />
        </Route>
      ))}
      <Route path='*'>
        <NotFound />
      </Route>
    </Switch>
  )
}

export default Template
