
import React from 'react'
import PropTypes from 'prop-types'

import Skeleton from '../atoms/Skeleton'

const WithSkeleton = ({
  showSkeleton,
  skeletonWidth,
  skeletonHeight,
  skeletonVariant,
  children
}) => {
  if (showSkeleton) {
    return (
      <Skeleton width={skeletonWidth} height={skeletonHeight} variant={skeletonVariant} />
    )
  }
  return (
    <>
      {children}
    </>
  )
}

WithSkeleton.propTypes = {
  showSkeleton: PropTypes.bool.isRequired,
  skeletonWidth: PropTypes.string.isRequired,
  skeletonHeight: PropTypes.string.isRequired,
  skeletonVariant: PropTypes.string.isRequired,
  children: PropTypes.any
}

export default WithSkeleton
