import React, { useCallback, useState, useEffect, useMemo } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useQueryClient } from '@tanstack/react-query'
import dayjs from 'dayjs'
import { Controller, useForm } from 'react-hook-form'
import {
  Container,
  Grid,
  Divider,
  makeStyles,
  useTheme
} from '@material-ui/core'
import { capitalize } from 'lodash'
import TextInput from '../../../../molecules/TextInput'
import Text from '../../../../atoms/Text'
import SnackAlert from '../../../../molecules/SnackAlert/SnackAlert'

import {
  useCreateBlendedBench,
  useBenchmarks
} from '../../../../../api/benchmarks'
import { useCurrentUser } from '../../../../../api/users'
import { useAppContext, useSetViewTitle } from '../../../../../redux/slices/appContext'

import { messageToUse } from '../../common'

import {
  INTERNAL_DATE_FORMAT,
  ADMIN_ROUTES,
  SUCCESS_ALERT,
  ERROR_ALERT
} from '../../../../../constants'
import SydButton from '../../../../commonDesign/Button'
import { QUERY_KEYS } from '../../../../../api/queryKeys'
import { BENCHMARKS } from '../../../../../policies/admin'
import { useCheckPolicy } from '../../../../../hooks'
import SaveCancelButtons from '../../../../molecules/SaveCancelButtons'
import { getNewGroup } from '../utils'
import BlendedSkeleton from './Skeleton'
import Groups from './Groups'
import RecalcButton from './RecalcButton'

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '1.5rem 2.1rem',
    height: '100%',
    [theme.breakpoints.down('xs')]: {
      padding: '1.75rem'
    }
  },
  confirmError: {
    textAlign: 'left',
    marginTop: '0.375rem'
  },
  helperText: {
    fontSize: '0.625rem',
    marginTop: '0.375rem'
  },
  bottomContainer: {
    width: '100%',
    position: 'absolute',
    bottom: '2%',
    right: 0,
    left: 0,
    backgroundColor: theme.palette.white,
    [theme.breakpoints.down('sm')]: {
      position: 'relative',
      flexDirection: 'column-reverse'
    },
    zIndex: 3
  },
  namesContainer: {
    marginBottom: '1.75rem'
  },
  flexItem: {
    display: 'flex',
    alignItems: 'center'
  },
  actions: {
    position: 'absolute',
    bottom: 0,
    left: '50%',
    transform: 'translateX(-50%)'
  }
}))

function hasDuplicates (array) {
  return new Set(array).size !== array.length
}

function getTitle (canEdit, isNew) {
  if (!canEdit) return 'Blended Benchmark'
  return `${isNew ? 'Add' : 'Edit'} Blended Benchmark`
}

function BlendedForm () {
  const classes = useStyles()
  const theme = useTheme()
  const history = useHistory()
  const canEditBlends = useCheckPolicy(BENCHMARKS.editBlends)
  const queryClient = useQueryClient()
  const { benchmarkId = null } = useParams()
  const { firmId, isSummitUser } = useAppContext()

  const isNew = !benchmarkId

  const setViewTitle = useSetViewTitle()

  useEffect(() => {
    setViewTitle(getTitle(canEditBlends, isNew))
    return () => {
      setViewTitle(null)
    }
  }, [isNew, setViewTitle, canEditBlends])

  const [groups, setGroups] = useState([getNewGroup()])

  const [alert, setAlert] = useState({})
  const [editingBlended, setEditingBlended] = useState(false)
  const [canEditStartStop, setCanEditStartStop] = useState(false)

  const {
    handleSubmit,
    register,
    control,
    reset,
    formState: { errors, isValid, isSubmitting }
  } = useForm({
    defaultValues: {},
    mode: 'onChange'
  })

  const onSuccessComponents = useCallback((data) => {
    if (data) {
      const { benchmark, benchmarkComponents, benchmarkComponentIds } = data
      const components = benchmarkComponents ?? benchmarkComponentIds

      if (benchmark) {
        reset({
          displayName: benchmark[0].displayName,
          longName: benchmark[0].longName,
          interval: capitalize(benchmark[0].interval)
        })
      }

      const groups = components
        .sort((a, b) => a.benchmarkComponentId - b.benchmarkComponentId)
        .reduce((acc, current) => {
          const component = {
            componentId: current.componentId,
            componentAllocation: Number(current.componentAllocation),
            ordinal: current.ordinal
          }

          if (Number(current.ordinal) === 1) {
            const group = {
              editMode: false,
              components: [],
              ...(current.startDate && { startDate: dayjs(current.startDate).format(INTERNAL_DATE_FORMAT) }),
              ...(current.endDate && { endDate: dayjs(current.endDate).format(INTERNAL_DATE_FORMAT) })
            }
            group.components.push(component)

            acc.push(group)
          } else {
            const index = acc.length - 1
            acc[index].components.push(component)
          }

          return acc
        }, [])

      setGroups(groups)
      setEditingBlended(false)
      setCanEditStartStop(benchmark?.interval ? benchmark.interval !== 'daily' : false)
    }
  }, [reset])

  const { queryComponents, queryOptions } = useMemo(() => {
    return {
      queryComponents: {
        method: 'getBenchmarkComponents',
        benchmarkId: Number(benchmarkId),
        firmId
      },
      queryOptions: {
        onSuccess: onSuccessComponents,
        enabled: !isNaN(Number(benchmarkId)),
        isActiveSearch: false
      }
    }
  }, [benchmarkId, firmId, onSuccessComponents])

  const { data: currentUser, isLoading: isLoadingUser } = useCurrentUser()

  // Get benchmark components
  const { isLoading: loadingComponents, refetch: refetchComponents } = useBenchmarks(queryComponents, queryOptions)

  function onSuccess ({ data }) {
    onSuccessComponents(data)
  }

  const {
    mutateAsync: createBlendedBench
  } = useCreateBlendedBench(onSuccess)

  const onSubmit = useCallback(async (data) => {
    const { displayName, longName } = data
    const { firstName, lastName } = currentUser
    const newGroups = [...groups]
    const lastGroup = newGroups[newGroups.length - 1]
    let errorMessage = ''
    let invalidComponent = false

    const dates = newGroups.reduce((acc, current) => {
      if (current.startDate) {
        acc.push(current.startDate)
      }

      if (current.startDate !== current.endDate) {
        acc.push(current.endDate)
      }

      return acc
    }, [])

    const hasDuplicatesDates = hasDuplicates(dates)

    const invalidAllocations = newGroups.some(({ components }) => {
      const allocation = components.reduce((acc, current) => {
        const componentAllocation = parseInt((current.componentAllocation * 100).toFixed(2))
        return acc + componentAllocation
      }, 0)
      return allocation !== 100
    })

    for (const group of newGroups) {
      invalidComponent = group.components.some(({ componentId }) => !componentId)
      if (invalidComponent) {
        break
      }
    }

    const isInvalidStartDate = !lastGroup.startDate && !isNew && groups.length > 1

    if (invalidAllocations) {
      errorMessage = 'Benchmark group must equal 100% allocation'
    }

    if (isInvalidStartDate) {
      errorMessage = 'Last group has no start date'
    }

    if (hasDuplicatesDates) {
      errorMessage = 'Date ranges are not correct'
    }

    if (invalidComponent) {
      errorMessage = 'No assigned benchmark'
    }

    if (invalidAllocations || isInvalidStartDate || hasDuplicatesDates || invalidComponent) {
      setAlert({ ...ERROR_ALERT, alertMessage: errorMessage })
      return
    }

    const payload = {
      ...(displayName ? { displayName } : {}),
      longName,
      firmId,
      method: 'upsertBenchmark',
      author: `${firstName} ${lastName}`,
      dates: newGroups.map(({ editMode, ...rest }) => rest)
    }

    if (!isNew) {
      payload.benchmarkId = Number(benchmarkId)
    }

    try {
      const response = await createBlendedBench(payload)

      if (!isNew) {
        setAlert({ ...SUCCESS_ALERT, alertMessage: 'Benchmark edited successfully' })
      } else {
        history.push(`${ADMIN_ROUTES.BENCHMARKS_BLENDED}/${response.data.benchMarkId}`)
      }
    } catch (err) {
      console.error(err)
      setAlert(ERROR_ALERT)
    }
  }, [
    groups,
    createBlendedBench,
    currentUser,
    firmId,
    benchmarkId,
    isNew,
    history
  ])

  const onCancel = useCallback(() => {
    setEditingBlended(false)
    refetchComponents()
  }, [refetchComponents])

  useEffect(() => {
    return () => {
      queryClient.removeQueries([QUERY_KEYS.getBenchmarks])
    }
  }, [queryClient])

  if (loadingComponents && !isNew) {
    return <BlendedSkeleton />
  }

  return (
    <Container maxWidth={false} className={classes.container}>
      <form onSubmit={handleSubmit(onSubmit)}>
        {
          !isNew && canEditBlends && (
            <Grid
              container
              justifyContent='flex-end'
              className={classes.flexItem}
              spacing={1}
            >
              <Grid item>
                <SydButton
                  variant='outline'
                  icon='edit'
                  onClick={() => setEditingBlended(!editingBlended)}
                  disabled={loadingComponents || isLoadingUser}
                >
                  {editingBlended ? 'Editing' : 'Edit'}
                </SydButton>
              </Grid>
              {isSummitUser ? (
                <Grid item>
                  <RecalcButton benchmarkId={benchmarkId} disabled={loadingComponents || isLoadingUser} />
                </Grid>
              ) : null}
            </Grid>
          )
        }
        <Grid container spacing={4} className={classes.namesContainer}>
          <Grid item md={4} xs={12}>
            <Controller
              name='displayName'
              render={() => (
                <TextInput
                  fullWidth
                  name='displayName'
                  placeholder='Display Name'
                  label='Display Name'
                  error={Boolean(errors.displayName)}
                  disabled={!isNew && !editingBlended}
                  {...register('displayName', { maxLength: 100 })}
                />)}
              control={control}
            />
            {errors.displayName ? (
              <Text
                text={messageToUse(errors.displayName)}
                color={theme.palette.error.main}
                className={classes.confirmError}
              />
            ) : (
              <Text
                text='100 max characters'
                color={theme.palette.romanSilver}
                className={classes.helperText}
              />
            )}
          </Grid>
          <Grid item md={4} xs={12}>
            <Controller
              name='longName'
              render={() => (
                <TextInput
                  fullWidth
                  name='longName'
                  placeholder='Full Name'
                  label='Full Name*'
                  error={Boolean(errors.longName)}
                  disabled={!isNew && !editingBlended}
                  {...register('longName', { required: true, maxLength: 100 })}
                />)}
              control={control}
            />
            {errors.longName ? (
              <Text
                text={messageToUse(errors.longName)}
                color={theme.palette.error.main}
                className={classes.confirmError}
              />
            ) : (
              <Text
                text='100 max characters'
                color={theme.palette.romanSilver}
                className={classes.helperText}
              />
            )}
          </Grid>
          <Grid item md={2} xs={12}>
            <Controller
              name='interval'
              render={() => (
                <TextInput
                  fullWidth
                  name='interval'
                  placeholder='Daily'
                  label='Interval'
                  error={Boolean(errors.interval)}
                  disabled
                  {...register('interval')}
                />)}
              control={control}
            />
          </Grid>
        </Grid>

        <Divider />

        <Groups
          groups={groups}
          setGroups={setGroups}
          benchmarkId={benchmarkId}
          canEditStartStop={canEditStartStop}
          firmId={firmId}
          isEditing={editingBlended}
        />
        {canEditBlends && (
          <SaveCancelButtons
            onCancel={onCancel}
            isSubmitting={isSubmitting}
            isFormValid={!(!isValid || isSubmitting || (!editingBlended && !isNew))}
            containerClassName={classes.actions}
          />
        )}
      </form>
      <SnackAlert alert={alert} />
    </Container>
  )
}

BlendedForm.propTypes = {}

export default BlendedForm
