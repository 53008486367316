import React from 'react'
import { CircularProgress, makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'

const useStyles = makeStyles((theme) => ({
  loading: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.layout.padding.thick
  }
}))

function Loading ({ size }) {
  const classes = useStyles()

  return (
    <div className={classes.loading}>
      <CircularProgress size={size} />
    </div>
  )
}

Loading.propTypes = {
  size: PropTypes.number
}

export default Loading
