import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  makeStyles,
  useTheme
} from '@material-ui/core'
import dayjs from 'dayjs'

import numeral from 'numeral'
import Text from '../../../../atoms/Text'

import { DATE_FORMAT_US_LONG } from '../../../../../constants'

const useStyles = makeStyles((theme) => ({
  group: {
    padding: '1.125rem 1.5rem',
    borderRadius: '0.375rem',
    border: `2px solid ${theme.palette.whiteLilac}`
  }
}))

function Group ({ group, minDate, benchmarks }) {
  const classes = useStyles()
  const theme = useTheme()

  let startDate = null
  const endDate = group.endDate ? dayjs(group.endDate).format(DATE_FORMAT_US_LONG) : null

  if (group.startDate) {
    startDate = dayjs(group.startDate).format(DATE_FORMAT_US_LONG)
  }

  if (group.startDate === minDate) {
    startDate = 'Since Inception'
  }

  const effectiveDateLabel = useMemo(() => {
    if (startDate) {
      return `${startDate ?? '-'} - ${endDate ?? 'Present'}`
    }
    return 'Present'
  }, [endDate, startDate])

  return (
    <Box className={classes.group}>
      <Box display='flex' justifyContent='space-between' marginBottom='0.9rem'>
        <Text
          text='Effective Date'
          color={theme.palette.darkJungle}
          customFontSize='1rem'
          customFontWeight='600'
        />
        <Text
          text={effectiveDateLabel}
          color={theme.palette.darkJungle}
          customFontSize='1rem'
        />
      </Box>
      {group.components.map(
        ({ componentAllocation, componentId }, index, components) => {
          const benchmark = benchmarks.find(
            ({ value }) => value === componentId
          )
          const isLastElement = index === components.length - 1
          if (!benchmark) return null

          return (
            <Box
              key={`component-${index}`}
              display='flex'
              justifyContent='space-between'
              marginBottom={isLastElement ? '' : '0.9rem'}
            >
              <Text
                text={benchmark?.label}
                color={theme.palette.darkJungle}
                customFontSize='1rem'
              />
              <Text
                text={numeral(componentAllocation).format('0%')}
                color={theme.palette.darkJungle}
                customFontSize='1rem'
                customFontWeight='600'
              />
            </Box>
          )
        }
      )}
    </Box>
  )
}

Group.propTypes = {
  group: PropTypes.object,
  minDate: PropTypes.any,
  benchmarks: PropTypes.arrayOf(PropTypes.object)
}

export default Group
