import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import PlanEditActions from '../Plans/PlanEditActions'

const useStyles = makeStyles((theme) => ({
  dynamicPlanningHeader: {
    margin: '16px 24px 24px',
    position: 'relative'
  }
}))

const Header = ({ children, className, style }) => {
  const classes = useStyles()
  return (
    <div className={clsx(classes.dynamicPlanningHeader, className)} style={style}>
      {children}
      <PlanEditActions />
    </div>
  )
}

Header.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  style: PropTypes.object
}

export default Header
