import React, { useCallback, useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import {
  Divider,
  makeStyles,
  Menu,
  MenuItem,
  Checkbox,
  Grid,
  Box
} from '@material-ui/core'
import isEmpty from 'lodash/isEmpty'
import {
  FILTER_TYPES, ICON_NAMES
} from '../../constants'
import RoundedButton from '../atoms/RoundedButton'
import Icon from '../atoms/Icon'
import Text from '../atoms/Text'
import SelectWithAutoSearch from '../molecules/SelectWithAutoSearch'

const useStyles = makeStyles((theme) => ({
  filterContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    width: '100%'
  },
  textButton: {
    cursor: 'pointer',
    marginLeft: '8px'
  },
  filterButton: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    width: '100%'
  },
  checkbox: {
    color: `${theme.palette.primary.main} !important`
  }
}))

// TODO: First level of filter should not be hardcoded
const OPTIONS = [
  {
    key: FILTER_TYPES.SYMBOL,
    value: FILTER_TYPES.SYMBOL,
    label: 'Symbol'
  }
]

const initialState = {
  [FILTER_TYPES.SYMBOL]: {
    search: [],
    checked: false
  }
}

const TableFilters = ({
  instructions,
  onSelectFilter,
  onClearAllFilters
}) => {
  const [filters, setFilters] = useState(initialState)

  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const handleClick = useCallback((event) => {
    setAnchorEl(event.currentTarget)
  }, [])

  const handleClose = useCallback(() => {
    setAnchorEl(null)
  }, [])

  const handleCheck = useCallback((key, event) => {
    setFilters({ ...filters, [key]: { checked: event.target.checked, search: [] } })
  }, [filters])

  const handleClearAllFilters = useCallback(() => {
    const clearedFilters = Object.keys(filters).reduce((acc, key) => {
      return {
        ...acc,
        [key]: {
          checked: false,
          search: []
        }
      }
    }, {})
    setFilters(clearedFilters)
    onClearAllFilters()
  }, [filters, setFilters, onClearAllFilters])

  const isFilterChecked = useMemo(() => {
    return Object.values(filters).some((filter) => filter.checked)
  }, [filters])

  const classes = useStyles()

  const handleSubmitSearch = useCallback((key, options) => {
    setFilters({ ...filters, [key]: { ...filters[key], search: options } })
    const filterIds = options.map((option) => option[instructions[key].optionValueKey])
    onSelectFilter(key, filterIds)
  }, [filters, setFilters, instructions, onSelectFilter])

  const renderedPills = useMemo(() => {
    return Object.keys(filters).map((key) => {
      const instruction = instructions[key]
      if (isEmpty(instruction)) return null
      const filter = filters[key]

      return (
        <SelectWithAutoSearch
          options={instruction.options}
          loading={instruction.loading}
          onChange={instruction.onChange}
          onSubmitSearch={(options) => handleSubmitSearch(key, options)}
          optionValueKey={instruction.optionValueKey}
          optionTitleKey={instruction.optionTitleKey}
          optionSubtitleKey={instruction.optionSubtitleKey}
          placeholder={instruction.placeholder}
          title={instruction.label}
          key={key}
          filters={filter.search}
        />
      )
    })
  }, [filters, instructions, handleSubmitSearch])

  return (
    <div className={classes.filterContainer}>
      <div className={classes.filterButton}>
        <RoundedButton
          primary
          onClick={handleClick}
          size='extraSmall'
        >
          <Icon name={ICON_NAMES.filters} />{`  ${'Filter'}`}
        </RoundedButton>
      </div>
      <div className={classes.filterMenu}>
        <Menu
          id='basic-menu'
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button'
          }}
        >
          {OPTIONS.map(option => (
            <MenuItem
              key={option.key}
              value={option.value}
              disabled={option?.isDisabled}
              PaperProps={{
                style: {
                  minHeight: '182px',
                  maxHeight: '200px',
                  minWidth: '160px'
                }
              }}
            >
              <Checkbox
                checked={filters[option.key]?.checked}
                onChange={(event) => handleCheck(option.key, event)}
                inputProps={{ 'aria-label': 'controlled' }}
                classes={{
                  root: classes.checkbox
                }}
              />
              <Text
                text={option.label}
                color='#5A5E6D'
              />
            </MenuItem>
          ))}
          <Divider />
          <MenuItem
            onClick={handleClearAllFilters}
            PaperProps={{
              style: {
                minHeight: '182px',
                maxHeight: 200,
                minWidth: '160px'
              }
            }}
          >
            <Text
              text='Clear All Filters'
              color='red'
            />
          </MenuItem>
        </Menu>
      </div>
      {isFilterChecked && (
        <Grid container>
          <Grid item xs={12}>
            <Box display='flex' flexDirection='row' justifyContent='flex-start' alignItems='baseline'>
              {renderedPills}
              <div className={classes.textButton} onClick={handleClearAllFilters}>
                <Text text='Clear Filters' color='#D44333' customFontSize='12px' />
              </div>
            </Box>
          </Grid>
        </Grid>
      )}
    </div>
  )
}

TableFilters.propTypes = {
  instructions: PropTypes.any,
  onClearAllFilters: PropTypes.func,
  onSelectFilter: PropTypes.func
}

export default TableFilters
