import React from 'react'
import { Grid } from '@material-ui/core'
import FadeIn from '../../../../molecules/Transitions/FadeIn'
import { usePolicy } from '../../../../../hooks/usePolicy'
import WealthOwnerInfoSection from './WealthOwnerInfoSection'
import WealthOwnerActionsSection from './WealthOwnerActionsSection'

function GeneralTab () {
  const canEdit = usePolicy('admin_edit_wo_users')

  return (
    <FadeIn>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <WealthOwnerInfoSection />
        </Grid>
        {canEdit ? (
          <Grid item xs={12} md={6}>
            <WealthOwnerActionsSection />
          </Grid>
        ) : null}
      </Grid>
    </FadeIn>
  )
}

GeneralTab.propTypes = {
}

export default GeneralTab
