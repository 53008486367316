import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import isFunction from 'lodash/isFunction'
import { makeStyles } from '@material-ui/core'
import { isEmpty } from 'lodash'
import { useBookOfBusinessReportContext } from '../../../../contexts/BookOfBusinessReportContext'
import QueryListContextProvider from '../../../../contexts/QueryListContext'
import BookOfBusinessResultsLink from '../../../../contexts/BookOfBusinessReportContext/BookOfBusinessResultsLink'

const useStyles = makeStyles(
  () => ({
    link: {
      '& a': {
        textDecoration: 'none !important'
      }
    }
  }),
  []
)

const BookOfBusinessReportMetric = ({
  to,
  children,
  filters,
  aggregation,
  useOutputCount,
  injectResultInProp,
  filterChildrenTypes
}) => {
  const classes = useStyles()
  const contextValue = useBookOfBusinessReportContext()

  const childrenNode = useMemo(() => {
    if (isFunction(children)) return children(contextValue)
    return children
  }, [children, contextValue])

  const metricValue = useMemo(() => {
    if (useOutputCount) {
      const [count] = contextValue?.data || []
      return count?.total ?? 0
    }
    return !isEmpty(contextValue?.data) ? contextValue?.data : 0
  }, [contextValue?.data, useOutputCount])

  return (
    <div className={classes.link}>
      <BookOfBusinessResultsLink
        to={to}
        reportParameters={contextValue?.rawReportParams}
      >
        <QueryListContextProvider
          data={metricValue}
          filters={filters}
          aggregation={aggregation}
          injectResultInProp={injectResultInProp}
          filterChildrenTypes={filterChildrenTypes}
        >
          {childrenNode}
        </QueryListContextProvider>
      </BookOfBusinessResultsLink>
    </div>
  )
}

BookOfBusinessReportMetric.propTypes = {
  to: PropTypes.string,
  children: PropTypes.node,
  filters: PropTypes.object,
  aggregation: PropTypes.object,
  useOutputCount: PropTypes.bool,
  injectResultInProp: PropTypes.string,
  filterChildrenTypes: PropTypes.arrayOf(PropTypes.string)
}

export default BookOfBusinessReportMetric
