import React, { useEffect } from 'react'
import { useLocation } from 'react-router'
import isEmpty from 'lodash/isEmpty'
import { useAppContext, useSetAppContext } from '../../redux/slices/appContext'
import ErrorBoundary from '../molecules/ErrorBoundary'
import { NoteThreadContextProvider } from '../../contexts/noteThreadContext'
import CommentContextProvider from '../organisms/Comments/CommentContext'
import LoadingView from '../pages/LoadingView'
import { useMainSearchViewPath } from '../../redux/slices/appConfig'
import {
  getClientsNotifications,
  getNotificationsCounter, postNamedCommand,
  postNamedQuery
} from '../../service'
import { useFetchAbundanceEngineConfig } from '../../redux/slices/abundanceEngineContext'
import { WebsocketProvider } from '../../providers/WebsocketProvider'
import AddAccountWrapper from '../organisms/AddAccount/AddAccountWrapper'
import Layout from './Layout'
import RoutedViews from './RoutedViews'

function AppInitializer () {
  const location = useLocation()
  const mainSearchViewPath = useMainSearchViewPath()
  const appContext = useAppContext()
  const setAppContext = useSetAppContext()
  const fetchAbundanceEngineConfig = useFetchAbundanceEngineConfig()

  useEffect(() => {
    async function fetchNotifications (userId) {
      try {
        // The following bootstraps main areas of the site - can any of these be deferred?
        const [
          notificationsCounterResponse,
          clientsNotificationsResponse,
          clientCount
        ] = await Promise.all([
          getNotificationsCounter(userId),
          getClientsNotifications(userId),
          postNamedQuery('levels', 'searchClients', { resultType: 'total' }),
          fetchAbundanceEngineConfig()
        ])

        if (!isEmpty(notificationsCounterResponse) && !isEmpty(clientsNotificationsResponse)) {
          const { data: { total: notificationsCount } } = notificationsCounterResponse
          const { data: notificationsGroupedByClients } = clientsNotificationsResponse
          setAppContext({
            clientsNotifications: notificationsGroupedByClients,
            notificationsCounter: notificationsCount,
            clientCount: clientCount.data?.total ?? 0
          })
        }
      } catch (err) {
        console.error("Couldn't retrieve notifications", err)
      }
    }
    async function registerUserImpression (userId) {
      return await postNamedCommand('users', 'register-impression', {
        platform: 'swsapp-web'
      })
    }

    if (appContext.userId) {
      fetchNotifications(appContext.userId).catch(console.error)
      registerUserImpression(appContext.userId).catch(console.error)
    }
  }, [appContext.userId, setAppContext, fetchAbundanceEngineConfig])

  if (appContext.loadingAvailableDates) return <LoadingView width='100%' height='100%' />

  return (
    <ErrorBoundary name='Application level' refresh>
      <WebsocketProvider>
        <NoteThreadContextProvider
          showCommentsGroupedByClient={appContext.isAdvisor}
          showCurrentClientThreadsByDefault={
            mainSearchViewPath === location.pathname && !location.pathname.startsWith('/admin')
          }
        >
          <AddAccountWrapper />
          <CommentContextProvider>
            <Layout>
              <RoutedViews />
            </Layout>
          </CommentContextProvider>
        </NoteThreadContextProvider>
      </WebsocketProvider>
    </ErrorBoundary>
  )
}

export default AppInitializer
