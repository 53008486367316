import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useTabularDatasetContext } from '../TabularDatasetProviderV1/TabularDatasetContext'
import OperationalTable, { useOperationalTable } from '../OperationalTable'
import { useTabularDatasetConfiguration } from '../TabularDatasetProviderV1'
import { useFormattingContext } from '../FormattingProvider/FormattingContext'
import CellPicker, { getCellComponentType, isCellComponent } from './Cells'

function compareNumericString (rowA, rowB, id, desc) {
  let a = Number.parseFloat(rowA.values[id])
  let b = Number.parseFloat(rowB.values[id])
  if (Number.isNaN(a)) {
    a = desc ? Number.NEGATIVE_INFINITY : Number.POSITIVE_INFINITY
  }
  if (Number.isNaN(b)) {
    b = desc ? Number.NEGATIVE_INFINITY : Number.POSITIVE_INFINITY
  }
  if (a > b) return 1
  if (a < b) return -1
  return 0
}

const mapFormatString = (column) => {
  if (column.formatString) return column
  return {
    ...column,
    formatString: undefined // TODO - should there be a default format string?
  }
}

const mapSortFunction = (column) => ({
  ...column,
  sortType: compareNumericString
})

const identity = x => x

const mapCellAccessor = ({ cellComponent, cellTooltipTitle, ...column }, options) => {
  let cellProps = {}
  if (cellTooltipTitle) {
    cellProps = {
      Cell: CellPicker,
      cellType: 'baseCell',
      cellTooltipTitle
    }
  } else if (isCellComponent(cellComponent)) {
    const component = getCellComponentType(cellComponent)
    cellProps = {
      Cell: CellPicker,
      cellType: component?.type,
      payload: component?.payload,
      formatter: options?.formatter ?? identity
    }
  } else {
    cellProps = {
      Cell: ({ value, column }) => {
        const formatter = options?.formatter ?? identity
        return formatter(value, column.format)
      }
    }
  }
  return {
    ...column,
    ...cellProps
  }
}

const mappingOps = [
  mapFormatString,
  mapCellAccessor,
  mapSortFunction
]

const mapColumn = (column, options = {}, index) => {
  const [result] = mappingOps.reduce(([col, opt], operation) => {
    const colResult = operation(col, opt)
    return [colResult, opt]
  }, [column, options])

  if (Array.isArray(result.columns)) {
    result.index = index
    result.columns = result.columns.map(c => mapColumn(c, options))
  }
  return result
}

const useColumnConfig = (columns, defaultSort = []) => {
  const { formatter } = useFormattingContext()
  const { columns: _columns, defaultSort: _defaultSort } = useMemo(() => {
    const mapped = columns.map((c, index) => mapColumn(c, {
      formatter
    }, index))

    return {
      columns: mapped,
      defaultSort
    }
  }, [columns, defaultSort, formatter])

  return {
    columns: _columns,
    defaultSort: _defaultSort
  }
}

const TabularDatasetTable = ({ columns, defaultSort, children }) => {
  const { configuration } = useTabularDatasetContext()
  const { data, loading } = useTabularDatasetConfiguration(configuration)
  const columnConfig = useColumnConfig(columns, defaultSort)
  const { onSortingChange } = useOperationalTable({ defaultSort: columnConfig.defaultSort })

  return (
    <OperationalTable.Wrapper>
      <OperationalTable.SuperHeader />
      <OperationalTable
        loading={loading}
        columns={columnConfig.columns}
        data={data || []}
        defaultSort={columnConfig.defaultSort}
        onSortingChange={onSortingChange}
        total={data?.length}
      />
    </OperationalTable.Wrapper>
  )
}

TabularDatasetTable.propTypes = {
  columns: PropTypes.array,
  defaultSort: PropTypes.array,
  children: PropTypes.node
}

TabularDatasetTable.defaultProps = {
  defaultSort: []
}

export default TabularDatasetTable
