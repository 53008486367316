import React from 'react'
import PropTypes from 'prop-types'
import { FormattingContext, useFormattingContext } from './FormattingContext'

const FormattingProvider = ({ children, configuration }) => {
  const contextValue = useFormattingContext(configuration)
  return (
    <FormattingContext.Provider value={contextValue}>
      {children}
    </FormattingContext.Provider>
  )
}

FormattingProvider.propTypes = {
  children: PropTypes.node,
  configuration: PropTypes.object
}

export default FormattingProvider
