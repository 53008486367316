import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import {
  TextField,
  Button,
  FormLabel,
  MenuItem,
  CircularProgress
} from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import clsx from 'clsx'
import { Controller, useForm } from 'react-hook-form'
import { SIZE_VARIANTS, TEXT_VARIANTS } from '../../constants'
import { useStyles, messageToUse } from '../pages/admin/common'
import Avatar from '../atoms/Avatar'
import Text from '../atoms/Text'

const getPersonDefaultValues = ({ email, clientsIds, ...rest }) => {
  return {
    email: email || '',
    clientIds: clientsIds ? clientsIds.join(',') : '',
    ...rest
  }
}

function PersonForm ({
  onCancel,
  onSubmit,
  submitText,
  item,
  payload: {
    clients
  }
}) {
  const classes = useStyles()
  const theme = useTheme()
  const { register, handleSubmit, control, formState: { isSubmitting, errors } } = useForm({
    defaultValues: item ? getPersonDefaultValues(item) : { clientId: '', email: '' }
  })

  const clientOptions = useMemo(() => clients.map(({ clientId, shortName }) => ({
    label: shortName,
    value: clientId
  })), [clients])

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
      <div className={classes.container}>
        <div>
          <div className={classes.profilePic}>
            <Avatar size={SIZE_VARIANTS.xLarge} />
          </div>
          {item?.personId && <input type='hidden' {...register('personId')} />}
          {item?.clientsIds && <input type='hidden' {...register('clientIds')} />}
          <div className={classes.field}>
            <FormLabel htmlFor='firstName' className={classes.label}>first name</FormLabel>
            <TextField
              error={Boolean(errors.firstName)}
              id='firstName'
              placeholder='Add Name'
              defaultValue=''
              inputProps={register('firstName', {
                minLength: 2,
                maxLength: 50,
                required: true
              })}
              fullWidth
            />
            {errors.firstName && <Text text={messageToUse(errors.firstName)} color={theme.palette.error.main} />}
          </div>
          <div className={classes.field}>
            <FormLabel htmlFor='lastName' className={classes.label}>last name</FormLabel>
            <TextField
              error={Boolean(errors.lastName)}
              inputProps={register('lastName', {
                minLength: 2,
                maxLength: 50,
                required: true
              })}
              id='lastName'
              placeholder='Add Last Name'
              defaultValue=''
              fullWidth
            />
            {errors.lastName && <Text text={messageToUse(errors.lastName)} color={theme.palette.error.main} />}
          </div>
          <div className={classes.field}>
            <FormLabel htmlFor='email' className={classes.label}>email</FormLabel>
            <Controller
              name='email'
              defaultValue={item?.email || ''}
              render={({ field: { onChange } }) => (
                <TextField
                  error={Boolean(errors.email)}
                  id='email'
                  defaultValue={item?.email || ''}
                  onChange={onChange}
                  placeholder='Add Email'
                  fullWidth
                />)}
              control={control}
              rules={{
                validate: value => {
                  const val = value.trim()
                  if (!val) return true
                  return /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(val)
                }
              }}
            />
            {errors.email && <Text text={messageToUse(errors.email)} color={theme.palette.error.main} />}
          </div>
          {!item &&
            <div className={classes.field}>
              <FormLabel htmlFor='clientId' className={classes.label}>Client</FormLabel>
              <Controller
                name='clientId'
                render={({ field: { onChange } }) => (
                  <TextField
                    error={Boolean(errors.role)}
                    select
                    id='clientId'
                    onChange={onChange}
                    placeholder='Choose a Client'
                    fullWidth
                  >
                    {clientOptions.map(({ value, label }) => (
                      <MenuItem key={value} value={value}>
                        {label}
                      </MenuItem>
                    ))}
                  </TextField>)}
                control={control}
                rules={{ required: true }}
              />
              {errors.clientId && <Text text={messageToUse(errors.clientId)} color={theme.palette.error.main} />}
            </div>}
        </div>
        <div className={classes.actions}>
          <Button onClick={onCancel} disabled={isSubmitting} className={clsx(classes.button, classes.cancelButton)}>
            <Text text='Cancel' variant={TEXT_VARIANTS.h3} />
          </Button>
          <Button type='submit' disabled={isSubmitting} className={clsx(classes.button, classes.submitButton)}>
            {!isSubmitting && <Text text={submitText} variant={TEXT_VARIANTS.h3} />}
            {isSubmitting && (<CircularProgress color='secondary' size={18} />)}
          </Button>
        </div>
      </div>
    </form>
  )
}

PersonForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  submitText: PropTypes.string,
  item: PropTypes.shape({
    personId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    email: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    role: PropTypes.string,
    clientsIds: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number]))
  }),
  payload: PropTypes.shape({
    clients: PropTypes.arrayOf(PropTypes.object)
  })
}

PersonForm.defaultProps = {
  submitText: 'Save',
  item: undefined,
  payload: { clients: [] }
}

export default React.memo(PersonForm)
