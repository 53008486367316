import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { Box, CircularProgress, makeStyles } from '@material-ui/core'
import noop from 'lodash/noop'
import RoundedButton from '../atoms/RoundedButton'
import { BUTTON_VARIANT, TEXT_VARIANTS } from '../../constants'
import Text from '../atoms/Text'
import FadeIn from './Transitions/FadeIn'

const useStyles = makeStyles(() => ({
  actions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '20px 0',
    width: 'fit-content'
  },
  button: {
    borderRadius: '30px',
    fontWeight: 800,
    textTransform: 'capitalize',
    padding: '14px 16px',
    minWidth: '180px'
  }
}))

export const CancelButton = ({ onClick, disabled, className, label, size }) => {
  const classes = useStyles()
  return (
    <RoundedButton
      onClick={onClick}
      disabled={disabled}
      className={clsx(className, classes.button)}
      variant={BUTTON_VARIANT.outlined}
      size={size}
    >
      <Text text={label} variant={TEXT_VARIANTS.h3} customFontFamily='18px' />
    </RoundedButton>
  )
}

CancelButton.propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  label: PropTypes.string,
  size: PropTypes.string
}

export const ConfirmButton = ({ onClick, disabled, className, label, isLoading, size }) => {
  const classes = useStyles()
  return (
    <RoundedButton
      primary
      type='submit'
      onClick={onClick}
      className={clsx(className, classes.button)}
      disabled={disabled}
      size={size}
    >
      {!isLoading && (
        <Text text={label} variant={TEXT_VARIANTS.h3} customFontFamily='18px' />
      )}
      {isLoading && <CircularProgress color='white' size={18} />}
    </RoundedButton>
  )
}

ConfirmButton.propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  label: PropTypes.string,
  isLoading: PropTypes.bool,
  size: PropTypes.string
}

const SaveCancelButtons = ({
  containerClassName,
  onSave,
  onCancel,
  isSubmitting,
  isFormValid,
  spacing,
  secondaryButtonLabel,
  primaryButtonLabel,
  hideCancelButton,
  disabledPrimaryButton
}) => {
  const classes = useStyles()
  return (
    <FadeIn
      className={clsx(classes.actions, {
        [containerClassName]: containerClassName
      })}
    >
      {!hideCancelButton && (
        <CancelButton
          onClick={onCancel}
          disabled={isSubmitting}
          label={secondaryButtonLabel}
          className={classes.button}
        />
      )}
      {!hideCancelButton && <Box margin={spacing} />}
      <ConfirmButton
        onClick={onSave}
        disabled={isSubmitting || !isFormValid || disabledPrimaryButton}
        label={primaryButtonLabel}
        className={classes.button}
        isLoading={isSubmitting}
      />
    </FadeIn>
  )
}

SaveCancelButtons.propTypes = {
  containerClassName: PropTypes.string,
  spacing: PropTypes.string,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  isSubmitting: PropTypes.bool,
  isFormValid: PropTypes.bool,
  primaryButtonLabel: PropTypes.string,
  secondaryButtonLabel: PropTypes.string,
  hideCancelButton: PropTypes.bool,
  disabledPrimaryButton: PropTypes.bool
}

SaveCancelButtons.defaultProps = {
  containerClassName: null,
  spacing: '0.5rem',
  onSave: noop,
  onCancel: noop,
  isSubmitting: false,
  isFormValid: true,
  primaryButtonLabel: 'Save',
  secondaryButtonLabel: 'Cancel',
  hideCancelButton: false,
  disabledPrimaryButton: false
}

export default SaveCancelButtons
