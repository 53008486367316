import React from 'react'
import { useParams } from 'react-router-dom'
import Frame from './Frame'
import { useGetViewConfigByPath } from './HomeDashboardContext'
import AbundanceEngineView from './AbundanceEngineView'

function SubView () {
  const { viewId } = useParams()
  const { hideFrame } = useGetViewConfigByPath(viewId)

  return (
    <Frame key={viewId} hidden={hideFrame}>
      <AbundanceEngineView viewPath={viewId} />
    </Frame>
  )
}

export default SubView
