import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core'
import Avatar from '../../../atoms/Avatar'

const useStyles = makeStyles((theme) => ({
  infoContainer: {
    width: '15rem',
    margin: '.5rem',
    '& .__wrapper': {
      display: 'flex',
      marginBottom: '.2rem',
      flexDirection: 'column',
      alignItems: 'center'
    },
    '& .__nameWrapper': {
      alignSelf: 'center',
      marginTop: '.5rem'
    },
    '& .__name': {
      fontSize: '1rem',
      fontWeight: '400',
      textAlign: 'center'
    },
    '& .__avatar': {
      border: '0px solid transparent',
      boxShadow: '0px 7px 7px -5px rgba(0,0,0,0.5)',
      transition: 'border .3s ease-in-out'
    },
    '&:hover': {
      textDecoration: 'none !important'
    },
    '&:hover .__name': {
      fontWeight: '600'
    },
    '&:hover .__avatar': {
      border: `1px solid ${theme.palette.primary.main} !important`
    }
  }
}))

const AdvisorInfo = ({ advisor }) => {
  const classes = useStyles()

  return (
    <Link to={`/advisor-view/${advisor.groupId}`} className={classes.infoContainer}>
      <div className='__wrapper'>
        <Avatar
          src={advisor.profile?.profileData?.profilePic}
          avatarLetters={advisor.shortName ?? ''}
          customSize='64px'
          isPublicResource
          customClassName='__avatar'
        />
        <div className='__nameWrapper'>
          <div className='__name'>{advisor.longName}</div>
        </div>
      </div>
    </Link>
  )
}

AdvisorInfo.propTypes = {
  advisor: PropTypes.object
}

export default AdvisorInfo
