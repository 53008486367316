import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { loadable } from '../../utils/loadable'
import { fetchCustomDataset } from '../../service'
import { useAbundanceEngineParameters } from '../../hooks/useAbundanceEngineParameters'
import { replaceObjectValues } from '../../utils/replacers'
import { useDeepCompareMemo } from '../../hooks'
import { useAdvisorHome } from '../../components/organisms/AdvisorHome/AdvisorHomeContext'

export const CustomDatasetContext = createContext(loadable.default())

export const useCustomDataset = () => {
  return useContext(CustomDatasetContext)
}

export const useCustomDatasetContextValue = ({
  name,
  parameters,
  type
}) => {
  const [datasetLoadable, setDatasetLoadable] = useState(loadable.default())
  const abundanceParams = useAbundanceEngineParameters()
  const { scope } = useAdvisorHome()
  const _params = useMemo(() => {
    return {
      ...abundanceParams,
      advisorId: scope?.groupTypeId === 301 && scope?.values?.length ? scope.values.at(0) : abundanceParams.advisorId
    }
  }, [abundanceParams, scope])

  const requestParams = useDeepCompareMemo(
    () => replaceObjectValues(parameters, _params),
    [parameters, _params]
  )

  const getDataset = useCallback(async (options) => {
    const {
      name,
      parameters,
      type
    } = options

    try {
      setDatasetLoadable(loadable.loading())
      const request = type === 'custom'
        ? {
          levelFilters: {
            functionName: name,
            parameters
          }
        } : parameters
      const result = await fetchCustomDataset(type, request)

      setDatasetLoadable(loadable.done(result.data))
    } catch (err) {
      setDatasetLoadable(loadable.error(err))
    }
  }, [setDatasetLoadable])

  useEffect(() => {
    getDataset({ name, parameters: requestParams, type }).catch(console.error)
  }, [name, requestParams, getDataset, type])

  return useMemo(() => {
    return {
      isLoading: loadable.isLoading(datasetLoadable),
      isError: loadable.isError(datasetLoadable),
      isDone: loadable.isDone(datasetLoadable),
      error: loadable.getError(datasetLoadable),
      value: loadable.getValue(datasetLoadable),
      scope
    }
  }, [datasetLoadable, scope])
}

const CustomDatasetContextProvider = ({
  children,
  name,
  parameters,
  type
}) => {
  const contextValue = useCustomDatasetContextValue({ name, parameters, type })
  const isRenderFunction = useMemo(() => typeof children === 'function', [children])

  return (
    <CustomDatasetContext.Provider value={contextValue}>
      {isRenderFunction ? children(contextValue) : children}
    </CustomDatasetContext.Provider>
  )
}

CustomDatasetContextProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,

  /** The name of the custom data set */
  name: PropTypes.string.isRequired,
  parameters: PropTypes.object,
  type: PropTypes.string
}

CustomDatasetContextProvider.defaultProps = {
  parameters: {},
  type: 'custom'
}

export default CustomDatasetContextProvider
