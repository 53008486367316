import React, { useCallback, useMemo } from 'react'
import dayjs from 'dayjs'
import clsx from 'clsx'
import noop from 'lodash/noop'
import PropTypes from 'prop-types'
import { useOktaAuth } from '@okta/okta-react'
import { makeStyles, Typography, darken } from '@material-ui/core'
import AttachFileIcon from '@material-ui/icons/AttachFile'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined'
import isEmpty from 'lodash/isEmpty'
import Text from '../atoms/Text'
import Avatar from '../atoms/Avatar'
import { threadSchema } from '../../prop-types'
import RoundedButton from '../../components/atoms/RoundedButton'
import { getAvatarProfilePicture, parseBodyComment, parseUnicodeTextToEmojis } from '../../utils'
import { useAbundanceViewByPath } from '../../redux/slices/abundanceEngineContext'
import {
  useNoteContext,
  useNoteFilterActions
} from '../../redux/slices/noteContext'
import { NOTE_TRIGGERED_BY, BUTTON_SIZES, ICON_NAMES, TEXT_VARIANTS } from '../../constants'
import Icon from '../atoms/Icon'
import { useNoteThreadContext } from '../../contexts/noteThreadContext'
import { editThread } from '../../service'
import { useToggle, usePermissions, useAuthState } from '../../hooks'
import Menu from './Menu'
import InputCommentInlineEdit from './InputCommentInlineEdit'

const useStyles = makeStyles((theme) => ({
  wrapper: ({ resolved }) => ({
    padding: '0rem 0rem 0.5rem 0rem',
    backgroundColor: '#F5F5F5',
    margin: '1.5rem 1rem',
    borderRadius: '0.75rem',
    fontSize: '1rem',
    opacity: (resolved ? 0.5 : 1.0)
  }),
  header: ({ resolved, isPublic }) => {
    let backgroundColor = theme.palette.summitBlue
    if (isPublic) backgroundColor = theme.palette.success.A700
    if (resolved) backgroundColor = theme.palette.summitGrey2
    return {
      display: 'flex',
      cursor: 'pointer',
      alignItems: 'center',
      backgroundColor,
      borderRadius: '0.75rem 0.75rem 0rem 0rem',
      height: '2.5rem',
      marginTop: '0.1rem'
    }
  },
  arrowForward: {
    cursor: 'pointer',
    fontSize: '1.25rem',
    marginLeft: 'auto',
    color: 'white',
    marginRight: '0.5rem'
  },
  threadTitle: {
    margin: '0.2rem 1rem 0rem 1rem',
    width: '100%',
    fontWeight: 'bold',
    color: 'white'
  },
  threadBody: {
    margin: '1rem 1rem 0rem 1rem',
    whiteSpace: 'pre-line',
    overflowWrap: 'break-word'
  },
  threadFooter: {
    alignItems: 'baseline',
    margin: '0 1rem',
    display: 'flex',
    fontWeight: 600
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      fontSize: '1rem',
      marginLeft: '0.25rem'
    },
    '& svg:nth-child(1)': {
      marginRight: '0.2rem'
    }
  },
  pointer: {
    cursor: 'pointer'
  },
  sectionDisabled: {
    opacity: '50%',
    cursor: 'not-allowed'
  },
  filterSection: {
    margin: '0 auto',
    cursor: 'pointer'
  },
  filterItem: {
    maxWidth: '10rem',
    borderRadius: '1rem',
    padding: '3px 6px 2px 6px',
    '&:hover': {
      border: `1px solid ${theme.palette.summitBlue}`
    }
  },
  filterText: {
    textDecoration: 'none',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap !important',
    overflow: 'hidden',
    maxWidth: '10rem'
  },
  avatar: {
    margin: '0 8px'
  },
  notificationBadge: {
    border: 'none',
    minWidth: '40px !important',
    padding: '4px 2px 2px 2px !important',
    fontSize: '0.615rem !important',
    color: theme.palette.white,
    fontWeight: 'bold',
    marginLeft: '0.5rem',
    backgroundColor: theme.palette.success.A500,
    '& > span > svg': {
      marginRight: '0.5rem',
      fontSize: '1rem'
    },
    '&:hover': {
      backgroundColor: theme.palette.success.A700
    },
    '& > span': {
      minWidth: '35px !important'
    }
  },
  menuIcon: {
    display: 'flex',
    marginRight: '0.5rem',
    cursor: 'pointer',
    color: theme.palette.white
  },
  repliesContainer: ({ resolved }) => ({
    display: 'flex',
    width: '20px',
    height: '20px',
    padding: '5px',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: '0.5rem',
    backgroundColor: darken(resolved ? theme.palette.summitGrey2 : theme.palette.summitBlue, 0.3)
  }),
  inlineEditInput: {
    backgroundColor: theme.palette.gray.light,
    borderRadius: '0.5rem',
    padding: '0.5rem !important'
  }
}))

const NoteThreadOverview = ({
  thread,
  onDeleteThread,
  handleOnThreadDetails
}) => {
  const classes = useStyles({ resolved: thread?.resolved, isPublic: thread?.isPublic })
  const view = useAbundanceViewByPath(thread.viewId)
  const { authState: oktaAuthState } = useOktaAuth()
  const authState = useAuthState(oktaAuthState)
  const { oktaId } = usePermissions(authState.accessToken || {})

  const [isLoading, , toggleLoadingOn, toggleLoadingOff] = useToggle(false)
  const [editMode, , toggleEditModeOn, toggleEditModeOff] = useToggle(false)

  const { activeThread, updateThreadById, onResolveThreadHandler } = useNoteThreadContext()

  const { filters, triggeredBy } = useNoteContext()
  const { addNoteFilter } = useNoteFilterActions()

  const renderMenuIcon = useCallback(
    ({ setAnchorEl }) => (
      <div className={classes.menuIcon} onClick={setAnchorEl}>
        <Icon name={ICON_NAMES.threeDots} customSize='1.5rem' />
      </div>
    ),
    [classes.menuIcon]
  )

  const getThreadMenuOptions = useCallback(
    () => [
      {
        iconName: ICON_NAMES.edit,
        label: 'Edit',
        onClick: toggleEditModeOn
      },
      {
        iconName: ICON_NAMES.check,
        label: thread.resolved ? 'Re-activate thread' : 'Mark as resolved',
        onClick: () => onResolveThreadHandler(!thread.resolved)
      },
      {
        iconName: ICON_NAMES.delete,
        label: 'Delete',
        onClick: onDeleteThread,
        color: '#D44333'
      }
    ],
    [thread.resolved, toggleEditModeOn, onDeleteThread, onResolveThreadHandler]
  )

  const renderThreadMenu = useMemo(() => {
    const options = getThreadMenuOptions()
    return activeThread.createdBy === oktaId ? <Menu options={options}>{renderMenuIcon}</Menu> : null
  }, [renderMenuIcon, getThreadMenuOptions, activeThread.createdBy, oktaId])

  const onAddNoteViewFilter = useCallback(() => {
    const { path, name } = view
    addNoteFilter({ id: path, name })
  }, [addNoteFilter, view])

  const repliesLabel = useMemo(() => {
    if (!thread.replies) return ''
    return `${thread.replies}`
  }, [thread.replies])

  const renderedThreadBody = useMemo(() =>
    <Typography
      variant={TEXT_VARIANTS.body3}
      dangerouslySetInnerHTML={{ __html: parseBodyComment(parseUnicodeTextToEmojis(thread?.body || '')) }}
    />, [thread])

  const renderRepliesLabel = useMemo(() => {
    if (thread.replies) {
      return (
        <div className={classes.repliesContainer}>
          <Text
            variant={TEXT_VARIANTS.caption}
            color='white'
            lineHeight='0'
            text={repliesLabel}
          />
        </div>
      )
    }
  }, [classes.repliesContainer, repliesLabel, thread.replies])

  const onEditThreadHandler = useCallback(
    async (bodyText) => {
      try {
        toggleLoadingOn()
        const body = { body: bodyText.trim() }
        await editThread(thread.threadId, body)
        updateThreadById(thread.threadId, body)
      } catch (err) {
        console.error(
          'There was an error trying to save the thread title',
          err
        )
      } finally {
        toggleLoadingOff()
        toggleEditModeOff()
      }
    },
    [
      thread.threadId,
      updateThreadById,
      toggleLoadingOn,
      toggleLoadingOff,
      toggleEditModeOff
    ]
  )

  return (
    <div className={classes.wrapper}>
      <div className={classes.header} onClick={handleOnThreadDetails}>
        <Typography className={classes.threadTitle}>
          {thread.createdByName}
        </Typography>
        {renderRepliesLabel}
        {activeThread.threadId && renderThreadMenu}
        {Boolean(thread.replies) && !activeThread.threadId && <ArrowForwardIcon className={classes.arrowForward} />}
      </div>
      <div className={classes.threadBody}>
        {editMode ? (
          <InputCommentInlineEdit
            isLoading={isLoading}
            onCancel={toggleEditModeOff}
            onSaveChanges={onEditThreadHandler}
            className={classes.inlineEditInput}
            defaultValue={parseUnicodeTextToEmojis(thread?.body)}
          />
        ) : (
          <Text
            variant={TEXT_VARIANTS.body3}
            text={renderedThreadBody}
          />
        )}
      </div>
      <div className={classes.threadFooter}>
        <Text
          variant={TEXT_VARIANTS.caption}
          customFontWeight={600}
          text={dayjs(thread?.createdAt).format('MMM D')}
        />
        {!isEmpty(thread.notifications) && (
          <RoundedButton size={BUTTON_SIZES.extraSmall} className={classes.notificationBadge}>
            New
          </RoundedButton>
        )}
        <div className={classes.filterSection}>
          {isEmpty(filters) && triggeredBy === NOTE_TRIGGERED_BY.BOARD
            ? (
              <div onClick={onAddNoteViewFilter} className={classes.filterItem}>
                <Text
                  customFontSize='0.75rem'
                  customFontWeight='bold'
                  className={classes.filterText}
                  text={view ? `#${view.name}` : '#general'}
                />
              </div>
            ) : null}
        </div>
        <div className={classes.actions}>
          <div className={clsx(classes.actions, classes.sectionDisabled)}>
            <AttachFileIcon />
            <CheckBoxOutlinedIcon />
          </div>
          <Avatar
            src={getAvatarProfilePicture(thread.createdByName || '')} // TODO: get the actual profile picture
            avatarLetters={thread.createdByName || ''}
            customSize='32px'
            customClassName={classes.avatar}
          />
        </div>
      </div>
    </div>
  )
}

NoteThreadOverview.propTypes = {
  thread: threadSchema,
  onDeleteThread: PropTypes.func,
  handleOnThreadDetails: PropTypes.func
}

NoteThreadOverview.defaultProps = {
  thread: null,
  onDeleteThread: noop,
  handleOnThreadDetails: noop
}

export default NoteThreadOverview
