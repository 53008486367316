import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useCustodians } from '../../../../api/coreData'
import CheckboxListFilter from '../FilterComponents/CheckboxListFilter'

const mapDataSourceOptions = (data) => {
  return Object.values(data.reduce((acc, dataSource) => {
    return {
      ...acc,
      [dataSource.dataSourceId]: {
        label: dataSource.name,
        value: dataSource.dataSourceId
      }
    }
  }, {}))
}

const DataSourcesListFilter = ({ defaultValue }) => {
  const query = useMemo(() => ({ mapper: mapDataSourceOptions }), [])
  const { data: custodians } = useCustodians(query)
  return (
    <CheckboxListFilter options={custodians} defaultValue={defaultValue} />
  )
}

DataSourcesListFilter.propTypes = {
  defaultValue: PropTypes.object
}

DataSourcesListFilter.defaultProps = {
  defaultValue: {}
}

export default DataSourcesListFilter
