import React from 'react'
import PropTypes from 'prop-types'
import Minimap from './Minimap'
import { useNavigationManager, DashboardNavigationContext } from './context'

const NavigationProvider = ({ dashboardItems, root, children, hideMinimap }) => {
  const navigationManager = useNavigationManager(dashboardItems, root)
  return (
    <DashboardNavigationContext.Provider value={navigationManager}>
      {children}
      {!hideMinimap && <Minimap />}
    </DashboardNavigationContext.Provider>
  )
}

NavigationProvider.propTypes = {
  dashboardItems: PropTypes.array.isRequired,
  root: PropTypes.string.isRequired,
  children: PropTypes.node,
  hideMinimap: PropTypes.bool
}

export default NavigationProvider
