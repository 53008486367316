import React from 'react'
import { Tooltip as MuiTooltip } from '@material-ui/core'
import PropTypes from 'prop-types'

const Tooltip = ({ children, title, ...props }) => {
  if (!title) return children

  return (
    <MuiTooltip title={title} {...props}>
      {children}
    </MuiTooltip>
  )
}

Tooltip.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node.isRequired
}

Tooltip.defaultProps = {
  title: undefined
}

export default Tooltip
