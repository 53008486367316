import { BUTTON_SIZES } from '../../../constants'

const { small, medium } = BUTTON_SIZES

const fontSize = {
  [small]: '14px',
  [medium]: '16px'
}

const itemPadding = {
  [small]: '1rem',
  [medium]: '1.25rem'
}

const selectStyles = {
  fontSize,
  itemPadding
}

export default selectStyles
