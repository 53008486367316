import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import noop from 'lodash/noop'
import { MAIN_HEADER_HEIGHT } from '../../../constants'
import { VIEW_TYPES } from '../ClientCardsViewV2/helpers'

const useStyles = makeStyles(() => ({
  container: {
    overflowY: 'auto',
    maxHeight: `calc(100vh - ${MAIN_HEADER_HEIGHT}px)`
  },
  cardContainer: {
    height: '100%',
    width: '100%',
    display: 'flex',
    padding: '0 1.5rem 1rem 1.5rem',
    flexDirection: 'column'
  },
  listContainer: {
    height: '100%',
    width: '100%',
    padding: '0 1rem'
  }
}))

const ViewWrapper = ({
  viewType,
  children,
  containerRefCallback
}) => {
  const classes = useStyles()

  const onContainerRefChange = useCallback(
    (node) => {
      if (!node) return
      containerRefCallback(node)
    },
    [containerRefCallback]
  )

  return (
    <div
      className={clsx(classes.container, {
        [classes.listContainer]: viewType === VIEW_TYPES.LIST,
        [classes.cardContainer]: viewType === VIEW_TYPES.BLOCKS
      })}
      ref={onContainerRefChange}
    >
      {children}
    </div>
  )
}

ViewWrapper.propTypes = {
  viewType: PropTypes.string,
  children: PropTypes.node,
  containerRefCallback: PropTypes.func
}

ViewWrapper.defaultProps = {
  viewType: undefined,
  containerRefCallback: noop
}

export default ViewWrapper
