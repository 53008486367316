import dayjs from 'dayjs'
import React, { useCallback, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { useCheckPolicy } from '../../../../../hooks'
import { ACCOUNTS } from '../../../../../policies/admin'
import { saveAccountBillingStartDate } from '../helpers'
import { useAccountFormContext } from '../AccountFormProvider'
import QuickEditDateInput from './QuickEditDateInput'

const BillingStartDateInput = ({
  onSave,
  disableEdit
}) => {
  const { account } = useAccountFormContext()
  const { billingStartDate, closeDate, accountId } = useMemo(() => account ?? {}, [account])

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [error, setError] = useState(null)
  const [isSaved, setIsSaved] = useState(false)

  const userCanEdit = useCheckPolicy(ACCOUNTS.editBillingStartDate)

  const dateRange = useMemo(() => ({
    ...(closeDate && { maxDate: dayjs.utc(closeDate).add(-1, 'day').format('YYYY-MM-DD') })
  }), [closeDate])

  const onSubmit = useCallback(async (values) => {
    setIsSubmitting(true)
    setIsSaved(false)
    setError(null)
    try {
      await saveAccountBillingStartDate({
        accountId: accountId,
        billingStartDate: values.value
      })
      setIsSaved(true)
      onSave(values.value)
    } catch (err) {
      setError(err?.response?.detail || 'An error occurred.  Please try again')
    }
    setIsSubmitting(false)
    setIsSaved(false)
  }, [accountId, onSave])

  return (
    <QuickEditDateInput
      currentValue={billingStartDate}
      label='Billing Start Date'
      dateRange={dateRange}
      onSubmit={onSubmit}
      isSubmitting={isSubmitting}
      isSaved={isSaved}
      error={error}
      userCanEdit={disableEdit || !userCanEdit}
      editModalTitle='Edit Billing Start Date'
      editModalSubtitle='Select a new billing start date'
    />
  )
}

BillingStartDateInput.propTypes = {
  onSave: PropTypes.func,
  disableEdit: PropTypes.bool
}

export default BillingStartDateInput
