import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import noop from 'lodash/noop'
import Select from '../../atoms/Select'

const defaultPageSizeOptions = [
  50,
  100,
  250,
  500,
  1000
]

const useStyles = makeStyles((theme) => ({
  pagingTotals: {
    flex: '1 0 auto',
    '& > *': {
      marginRight: '5px'
    }
  },
  total: {
    color: theme.palette.darkGrey.main,
    fontWeight: '400'
  }
}))

const PagingTotals = ({
  pageSize,
  pageSizeOptions,
  onPageSizeChanged,
  totalItems,
  itemName,
  footNote,
  hidePageSize,
  showTotalItemsLabel
}) => {
  const classes = useStyles()

  const onChange = useCallback(e => {
    onPageSizeChanged && onPageSizeChanged(e.target.value)
  }, [onPageSizeChanged])

  if (footNote) {
    return (
      <div className={classes.pagingTotals}>
        <span>{footNote}</span>
      </div>
    )
  }

  return (
    <div className={classes.pagingTotals}>
      <span>Showing</span>
      {!hidePageSize && (
        <Select
          options={pageSizeOptions}
          selectedValue={pageSize}
          defaultValue={1000}
          onChange={onChange}
        />
      )}
      {totalItems && showTotalItemsLabel ? (
        <span>
          {hidePageSize ? '' : 'of '}
          <span className={classes.total}>{totalItems}</span>
        </span>
      ) : null}
      <span>{itemName}</span>
    </div>
  )
}

PagingTotals.propTypes = {
  pageSize: PropTypes.number.isRequired,
  pageSizeOptions: PropTypes.arrayOf(PropTypes.number),
  totalItems: PropTypes.number,
  itemName: PropTypes.string,
  onPageSizeChanged: PropTypes.func,
  hidePageSize: PropTypes.bool,
  showTotalItemsLabel: PropTypes.bool,
  footNote: PropTypes.string
}

PagingTotals.defaultProps = {
  pageSize: 1000,
  pageSizeOptions: defaultPageSizeOptions,
  itemName: 'Items',
  onPageSizeChanged: noop,
  hidePageSize: false,
  showTotalItemsLabel: false,
  footNote: null
}

export default PagingTotals
