import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { isEmpty } from 'lodash'
import { SELECT_FILTER_VARIANTS } from '../../../molecules/SelectFilter/helpers'

dayjs.extend(utc)

const getKeyFormatter = (variant) => (key) => {
  if (variant?.type === SELECT_FILTER_VARIANTS.list) {
    const keyParts = key.split('.')
    const keyField = variant?.valueKey || keyParts.slice(-1)
    return `${keyParts.slice(0, -1).join('.')}['${keyField}']`
  }
  return key
}

const mapValueByType = (value, type = 'string') => {
  return type === 'string' ? `'${value}'` : value
}

const getValueFormatter = (variant) => (value) => {
  if (variant === SELECT_FILTER_VARIANTS.percentage) {
    return parseFloat(value) / 100
  }
  if (variant?.type === SELECT_FILTER_VARIANTS.list) {
    const values = Object.entries(value).reduce(
      (acc, [key, val]) => (val ? [...acc, key] : acc),
      []
    )
    const options = variant.options
    const itemType = variant.itemType ?? 'string'

    let query = ''
    if (variant?.domain) {
      query = Object.entries(value)
        .reduce((acc, [key, value]) => {
          if (!value) return acc
          return [...acc, key]
        }, [])
        .map((val) => {
          return mapValueByType(val, itemType)
        })
        .join(', ')
    } else {
      query = options
        .filter(({ value }) => values.includes(value))
        .map(({ value }) => mapValueByType(value, itemType))
        .join(', ')
    }

    return `(${query})`
  }
  if (variant === SELECT_FILTER_VARIANTS.date) {
    return `'${dayjs.utc(dayjs(value).format('YYYY-MM-DD')).toISOString()}'`
  }
  return value
}

export const getOutputQueryFilters = (reportFilters) => {
  return Object.entries(reportFilters).reduce((acc, [key, columnFilter]) => {
    const filterValue = columnFilter?.value
    if (isEmpty(filterValue)) {
      return acc
    }
    const { value, rangeValue, optionValue, variant } = filterValue || {}
    const formatValue = getValueFormatter(variant)
    const formatKey = getKeyFormatter(variant)

    if (value) {
      return [
        ...acc,
        `o.${formatKey(key)} ${optionValue} ${formatValue(value)}`
      ]
    }
    const { startValue, endValue } = rangeValue
    const startValueCondition = `o.${key} >= ${formatValue(startValue)}`
    const endValueCondition = `o.${key} <= ${formatValue(endValue)}`
    return [...acc, `${startValueCondition} AND ${endValueCondition}`]
  }, [])
}
