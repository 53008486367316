import React, { useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import clsx from 'clsx'
import { Link, useHistory } from 'react-router-dom'
import {
  Box,
  Drawer,
  Hidden,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
  Divider
} from '@material-ui/core'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import CloseIcon from '@material-ui/icons/Close'
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined'
import MenuIcon from '@material-ui/icons/Menu'
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined'
import { createTheme, makeStyles, useTheme, ThemeProvider } from '@material-ui/core/styles'
import logo from '../../assets/summitLogoGrey.png'
import { SIDE_NAV_WIDTH, LOCAL_STORAGE_KEYS } from '../../constants'
import { useNavLogoStyles } from '../../redux/slices/appConfig'
import Image from '../atoms/Image'
import { useAbundanceViewsInNavigation } from '../../redux/slices/abundanceEngineContext'
import { localStorageHelper } from '../../utils/localStorageHelper'
import { useCheckPolicy } from '../../hooks'
import { ADMIN } from '../../policies/admin'

const smallFontTheme = createTheme({
  typography: {
    body1: {
      fontSize: '12px',
      fontFamily: 'GothamPro,Gotham-Book,"Open Sans",Tahoma,"Segoe UI",sans-serif',
      fontWeight: 400,
      lineHeight: 1.4
    }
  }
})

const useStyles = makeStyles((theme) => ({
  drawer: {
    height: '100vh',
    backgroundColor: theme.palette.background.secondary,
    [theme.breakpoints.up('lg')]: {
      width: SIDE_NAV_WIDTH,
      flexShrink: 0
    }
  },
  drawerPaper: {
    width: SIDE_NAV_WIDTH,
    position: 'static',
    overflow: 'hidden',
    borderRight: `1px solid ${theme.palette.gray.main}`
  },
  drawerOpen: {
    zIndex: '4',
    width: SIDE_NAV_WIDTH,
    position: 'relative',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: 'hidden',
    width: '48px',
    zIndex: '4'
  },
  collapsedDrawer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    height: '100%'
  },
  collapsedDrawerContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%'
  },
  link: {
    textDecoration: 'none',
    color: theme.palette.text.primary
  },
  hide: {
    display: 'none'
  },
  groupDivider: {
    marginTop: 'auto'
  },
  scrollableListGroup: {
    overflowY: 'auto',
    minHeight: '18rem'
  },
  listItem: {
    paddingTop: theme.spacing(0.25),
    paddingBottom: theme.spacing(0.25)
  },
  logo: {
    height: '30px',
    width: '30px',
    marginRight: theme.spacing(2)
  },
  nested: {
    paddingLeft: theme.spacing(4),
    paddingTop: theme.spacing(0.25),
    paddingBottom: theme.spacing(0.25)
  },
  starIcon: {
    minWidth: '30px'
  },
  toolbar: {
    ...theme.mixins.toolbar,
    margin: '0 16px',
    position: 'relative',
    display: 'flex',
    letterSpacing: '6px',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '24px',
    fontWeight: 'bold'
  },
  toolbarNoLogo: {
    ...theme.mixins.toolbar,
    margin: '0 16px',
    position: 'relative',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  logoLink: {
    display: 'flex',
    textDecoration: 'none',
    color: 'inherit'
  },
  sideNavToggle: {
    padding: '8px',
    position: 'absolute',
    right: '-4px'
  },
  navItem: {
    minWidth: '2.5rem'
  },
  sideNavFooter: {
    width: '100%',
    backgroundColor: theme.palette.background.paper
  }
}))

function SideNav ({ isAdvisor, sideNavOpen, toggleSideNav, overContentOnDesktop }) {
  const theme = useTheme()
  const canViewAdmin = useCheckPolicy(ADMIN.viewAdmin)
  const navLogoStyles = useNavLogoStyles()
  const classes = useStyles(navLogoStyles?.additionalStyles ? { logo: navLogoStyles.additionalStyles } : {})
  const hideLogo = navLogoStyles?.hide
  const viewsInNavigation = useAbundanceViewsInNavigation()
  const history = useHistory()

  const signOut = useCallback(() => {
    localStorageHelper.removeItems(Object.values(LOCAL_STORAGE_KEYS))
    history.replace('/logout')
  }, [history])

  const toolbarRendered = useMemo(() => {
    if (hideLogo) {
      return (
        <div className={classes.toolbarNoLogo}>
          <IconButton className={classes.sideNavToggle} onClick={toggleSideNav}>
            <Hidden mdDown implementation='js'>
              <ChevronLeftIcon />
            </Hidden>
            <Hidden lgUp implementation='js'>
              <CloseIcon />
            </Hidden>
          </IconButton>
        </div>
      )
    }
    return (
      <>
        <div className={classes.toolbar}>
          <Link to='/' className={classes.logoLink}>
            {navLogoStyles?.src ? (
              <Image
                showCustomAlt
                alt='SUMMIT'
                src={navLogoStyles.src}
                height='30px'
                width='30px'
                extraStyles={navLogoStyles.additionalStyles}
              />
            ) : (
              <>
                <img src={logo} className={classes.logo} alt='logo' />
                SUMMIT
              </>
            )}
          </Link>
          <IconButton className={classes.sideNavToggle} onClick={toggleSideNav}>
            <Hidden mdDown implementation='js'>
              <ChevronLeftIcon />
            </Hidden>
            <Hidden lgUp implementation='js'>
              <CloseIcon />
            </Hidden>
          </IconButton>
        </div>
      </>
    )
  }, [
    classes.logo,
    classes.logoLink,
    classes.sideNavToggle,
    classes.toolbar,
    classes.toolbarNoLogo,
    hideLogo,
    navLogoStyles,
    toggleSideNav
  ])

  const renderNavigationLink = useCallback(
    ({ path, name, primary }) => {
      return (
        <Link to={`/${primary ? '' : 'views/'}${path}`} key={path} className={classes.link}>
          <ListItem button className={classes.listItem} onClick={noop}>
            <ListItemText
              primary={<Typography component='span'>{` ${name}`}</Typography>}
            />
          </ListItem>
        </Link>
      )
    },
    [classes.link, classes.listItem]
  )

  const drawer = useMemo(
    () => (
      <Box
        position='relative'
        height='100%'
        display='flex'
        flexDirection='column'
      >
        {toolbarRendered}
        <List className={classes.scrollableListGroup}>
          {viewsInNavigation.map(renderNavigationLink)}
        </List>
        <List className={classes.groupDivider}>
          {isAdvisor && canViewAdmin && (
            <>
              <Link to='/admin' className={classes.link}>
                <ListItem button>
                  <ListItemIcon className={classes.navItem}>
                    <SettingsOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText primary='Admin' />
                </ListItem>
              </Link>
            </>
          )}
          <ListItem button onClick={signOut}>
            <ListItemIcon className={classes.navItem}>
              <ExitToAppOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary='Logout' />
          </ListItem>
        </List>
        <List className={classes.sideNavFooter}>
          <Divider />
          <ThemeProvider theme={smallFontTheme}>
            <ListItem
              button
              component='a'
              target='_blank'
              href='https://summitwealth.io/terms'
            >
              <ListItemText primary='Terms of Use' />
            </ListItem>
            <ListItem
              button
              component='a'
              target='_blank'
              href='https://summitwealth.io/privacy'
            >
              <ListItemText primary='Privacy Policy' />
            </ListItem>
          </ThemeProvider>
        </List>
      </Box>
    ),
    [
      signOut,
      isAdvisor,
      toolbarRendered,
      renderNavigationLink,
      viewsInNavigation,
      classes.link,
      classes.navItem,
      classes.groupDivider,
      classes.sideNavFooter,
      classes.scrollableListGroup,
      canViewAdmin
    ]
  )

  const collapsedDrawer = useMemo(() => (
    <div className={classes.collapsedDrawer}>
      <Toolbar>
        <IconButton
          color='inherit'
          aria-label='open drawer'
          onClick={toggleSideNav}
          className={clsx(classes.menuButton, sideNavOpen && classes.hide)}
        >
          <MenuIcon />
        </IconButton>
      </Toolbar>
      <div className={classes.collapsedDrawerContent} />
    </div>
  ), [classes.collapsedDrawer, classes.collapsedDrawerContent, classes.hide, classes.menuButton, sideNavOpen, toggleSideNav])

  return (
    <>
      {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
      <Hidden mdDown implementation='js'>
        <Drawer
          anchor={theme.direction === 'rtl' ? 'right' : 'left'}
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: sideNavOpen,
            [classes.drawerClose]: !sideNavOpen
          })}
          classes={{
            paper: clsx(classes.drawerPaper, {
              [classes.drawerOpen]: sideNavOpen,
              [classes.drawerClose]: !sideNavOpen
            })
          }}
          ModalProps={{
            keepMounted: true // Better open performance on mobile.
          }}
          onClose={toggleSideNav}
          open={sideNavOpen}
          variant={overContentOnDesktop ? 'temporary' : 'permanent'}
        >
          {sideNavOpen ? drawer : collapsedDrawer}
        </Drawer>
      </Hidden>
      <Hidden lgUp implementation='js'>
        <Drawer
          anchor={theme.direction === 'rtl' ? 'right' : 'left'}
          classes={{
            paper: classes.drawerPaper
          }}
          className={classes.drawer}
          ModalProps={{
            keepMounted: true // Better open performance on mobile.
          }}
          onClose={toggleSideNav}
          open={sideNavOpen}
          variant='temporary'
        >
          {drawer}
        </Drawer>
      </Hidden>
    </>
  )
}

SideNav.propTypes = {
  isAdvisor: PropTypes.bool,
  sideNavOpen: PropTypes.bool.isRequired,
  overContentOnDesktop: PropTypes.bool,
  toggleSideNav: PropTypes.func.isRequired
}

SideNav.defaultProps = {
  isAdvisor: false,
  showHome: true,
  overContentOnDesktop: false
}

export default SideNav
