import React, { useCallback, useEffect } from 'react'
import { IconButton, makeStyles } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import Icon from '../../../atoms/Icon'
import RoundedButton from '../../../atoms/RoundedButton'
import { ICON_NAMES, KEYS } from '../../../../constants'
import { useAbundanceEngineGalleryContext } from './abundanceEngineGalleryContext'
import CustomizeDialog from './customizeDialog'

const useStyles = makeStyles(() => ({
  header: {
    position: 'initial',
    top: 0,
    padding: '0.5rem 2rem',
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    backgroundColor: 'white'
  },
  title: {
    color: '#141929',
    fontSize: '1rem',
    margin: 0,
    lineHeight: '2rem'
  },
  actions: {
    display: 'flex',
    marginLeft: 'auto',
    gap: '0.75rem',
    alignItems: 'center'
  },
  dialogPaper: {
    position: 'absolute',
    top: '0',
    right: '0'
  }
}))

const AbundanceEngineGalleryHeader = () => {
  const classes = useStyles()
  const {
    isCustomizeDialogOpen,
    toggleCustomizeDialog,
    togglePresentationMode
  } = useAbundanceEngineGalleryContext()

  const onKeyPress = useCallback(
    (e) => {
      if (e.which === KEYS.ESC) {
        e.stopPropagation()
        e.preventDefault()
        togglePresentationMode.off()
      }
    },
    [togglePresentationMode]
  )

  useEffect(() => {
    window.addEventListener('keydown', onKeyPress, true)
    return () => {
      window.removeEventListener('keydown', onKeyPress)
    }
  }, [onKeyPress])
  const history = useHistory()
  const onBack = useCallback(() => {
    history.push('/admin')
  }, [history])

  return (
    <div className={classes.header}>
      <h1 className={classes.title}>
        <IconButton
          edge='start'
          onClick={onBack}
        >
          <Icon name={ICON_NAMES.chevronLeft} customSize='30px' color='black' />
        </IconButton>
        Gallery
      </h1>
      <div className={classes.actions}>
        <RoundedButton
          size='extraSmall'
          variant='outlined'
          onClick={toggleCustomizeDialog.on}
        >
          Customize
        </RoundedButton>
        <RoundedButton
          size='extraSmall'
          primary
          onClick={togglePresentationMode.toggle}
        >
          Present
        </RoundedButton>
      </div>
      <CustomizeDialog
        open={isCustomizeDialogOpen}
        handleClose={toggleCustomizeDialog.off}
      />
    </div>
  )
}

export default AbundanceEngineGalleryHeader
