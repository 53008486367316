import React, { useCallback, useRef, useState } from 'react'
import { IconButton, makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { ICON_NAMES } from '../../../constants'
import Icon from '../../atoms/Icon'

const useStyles = makeStyles(() => ({
  container: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    maxWidth: '100%',
    overflowX: 'auto',
    gap: '1rem',
    scrollBehavior: 'smooth',
    '&::-webkit-scrollbar': {
      background: 'transparent',
      '-webkit-appearance': 'none',
      width: 0,
      height: 0
    }
  },
  navigationButton: {
    zIndex: 2,
    padding: '0.5rem',
    background: '#FFFFFF',
    opacity: 0.95,
    boxShadow: '0px 0px 10px rgba(8, 9, 27, 0.15)',
    '&:hover': {
      background: '#FFFFFF'
    }
  },
  leftArrow: {
    position: 'fixed',
    left: '1rem'
  },
  rightArrow: {
    position: 'fixed',
    right: '1.5rem'
  }
}))

const CarouselSlider = ({ children, sliceDistance }) => {
  const classes = useStyles()
  const containerRef = useRef()

  const [scrollX, setScrollX] = useState(0)
  const [scrollEnd, setScrollEnd] = useState(false)

  const hasScroll =
    containerRef?.current?.scrollWidth > containerRef?.current?.clientWidth

  const onSlide = useCallback(
    (shift) => {
      containerRef.current.scrollLeft += shift
      setScrollX(scrollX + shift)
      const { offsetWidth, scrollWidth, scrollLeft } = containerRef.current
      const endReached = Math.floor(scrollWidth - scrollLeft) <= offsetWidth
      setScrollEnd(endReached)
    },
    [scrollX]
  )

  const onScroll = useCallback(() => {
    setScrollX(containerRef.current.scrollLeft)
    const { offsetWidth, scrollWidth, scrollLeft } = containerRef.current
    const endReached = Math.floor(scrollWidth - scrollLeft) <= offsetWidth
    setScrollEnd(endReached)
  }, [])

  return (
    <div ref={containerRef} onScroll={onScroll} className={classes.container}>
      {scrollX !== 0 && hasScroll && (
        <IconButton
          className={clsx(classes.leftArrow, classes.navigationButton)}
          onClick={() => onSlide(-sliceDistance)}
        >
          <Icon name={ICON_NAMES.chevronLeft} />
        </IconButton>
      )}
      {children}
      {!scrollEnd && hasScroll && (
        <IconButton
          className={clsx(classes.rightArrow, classes.navigationButton)}
          onClick={() => onSlide(+sliceDistance)}
        >
          <Icon name={ICON_NAMES.chevronRight} customSize='1rem' />
        </IconButton>
      )}
    </div>
  )
}

CarouselSlider.propTypes = {
  children: PropTypes.node.isRequired,
  sliceDistance: PropTypes.number
}

CarouselSlider.defaultProps = {
  sliceDistance: 100
}

export default CarouselSlider
