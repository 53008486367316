import React from 'react'
import { makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import Metric from './Metric'
import TransactionMetric from './TransactionsMetric/TransactionMetric'

const useStyles = makeStyles((theme) => ({
  details: {
    display: 'flex',
    marginLeft: 'auto',
    flexDirection: 'row',
    gap: '2.5rem',
    alignSelf: 'end',
    [theme.breakpoints.down('md')]: {
      marginLeft: 0,
      marginTop: '1rem',
      alignSelf: 'start'
    }
  }
}))

const NetFlowsMetric = ({
  defaultDateRange,
  valueFormat,
  defaultFilters,
  redirectTo
}) => {
  const classes = useStyles()

  const { netFlows, inflows, outflows } = defaultFilters

  return (
    <Metric.Wrapper redirectTo={redirectTo}>
      <TransactionMetric
        valueFormat={valueFormat}
        valueDescription={netFlows?.label}
        defaultDateRange={defaultDateRange}
        transactionCodeTagIds={netFlows?.transactionCodeTagIds}
      />
      <div className={classes.details}>
        <TransactionMetric
          valueFormat={valueFormat}
          valueDescription={inflows?.label}
          defaultDateRange={defaultDateRange}
          transactionCodeTagIds={inflows?.transactionCodeTagIds}
          metricProps={{
            valueSize: '1.625rem',
            includeSymbol: true
          }}
        />
        <TransactionMetric
          valueFormat={valueFormat}
          valueDescription={outflows?.label}
          defaultDateRange={defaultDateRange}
          transactionCodeTagIds={outflows?.transactionCodeTagIds}
          metricProps={{
            valueSize: '1.625rem',
            includeSymbol: true
          }}
        />
      </div>
    </Metric.Wrapper>
  )
}

const transactionCodeTagPropTypes = PropTypes.shape({
  label: PropTypes.string,
  transactionCodeTagIds: PropTypes.arrayOf(PropTypes.number)
})

NetFlowsMetric.propTypes = {
  defaultDateRange: PropTypes.string,
  valueFormat: PropTypes.string,
  defaultFilters: PropTypes.shape({
    netFlows: transactionCodeTagPropTypes,
    inflows: transactionCodeTagPropTypes,
    outflows: transactionCodeTagPropTypes
  }),
  redirectTo: PropTypes.string
}

NetFlowsMetric.defaultProps = {
  defaultDateRange: 'L30D',
  valueFormat: 'human',
  redirectTo: undefined,
  defaultFilters: {
    netFlows: {
      label: 'in the last 30 days',
      transactionCodeTagIds: []
    },
    inflows: {
      label: 'Inflows',
      transactionCodeTagIds: []
    },
    outflows: {
      label: 'Outflows',
      transactionCodeTagIds: []
    }
  }
}

export default NetFlowsMetric
