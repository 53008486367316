import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { useSetNoteContext } from '../../redux/slices/noteContext'
import { NOTE_TRIGGERED_BY } from '../../constants'

const NoteButton = ({ children, viewId, triggeredBy }) => {
  const setNoteContext = useSetNoteContext()
  const onClickHandler = useCallback(
    (e) => {
      const position = e.target.getBoundingClientRect()
      setNoteContext({
        openNewCommentModal: true,
        openModal: false,
        modalPosition: {
          top: position.top,
          left: position.left
        },
        viewId,
        triggeredBy
      })
    },
    [setNoteContext, triggeredBy, viewId]
  )

  return (
    <div onClick={onClickHandler}>
      {children}
    </div>
  )
}

NoteButton.propTypes = {
  // Any thread created would be attached to the specified view id if specified
  viewId: PropTypes.string,
  // Component wrapped with Notes modal click functionality
  children: PropTypes.any,
  // Indicates who was the entity that trigger the notes modal, see note context for more context on default behaviors
  triggeredBy: PropTypes.oneOf(Object.values(NOTE_TRIGGERED_BY))
}

NoteButton.defaultProps = {
  viewId: undefined,
  children: null,
  triggeredBy: NOTE_TRIGGERED_BY.SECTION
}

export default NoteButton
