import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import FastAvatar from '../../../../molecules/FastAvatar'
import MessageHeader from './MessageHeader'

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: '24px',
    marginLeft: '18px',
    gap: '14px'
  },
  message: {
    fontWeight: '100'
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    gap: '5px'
  },
  markdownContainer: {
    maxWidth: '500px',
    whiteSpace: 'pre-wrap',
    '& > *': {
      margin: 0
    }
  }
}))

const useParticipant = (participants, message) => {
  return useMemo(() => {
    const match = participants.find(x => x.participantKey === message.fromParticipantKey)

    return {
      participant: match,
      abbreviation: match ? match.name.substring(0, 1) : null
    }
  }, [participants, message])
}

const Message = ({ message, participants }) => {
  const classes = useStyles()
  const { participant, abbreviation } = useParticipant(participants, message)

  return (
    <div className={classes.container}>
      <div>
        <FastAvatar size='sm' abbreviation={abbreviation} avatarUrl={participant.avatarUrl} avatarType={participant.avatarType} />
      </div>
      <div className={classes.message}>
        <MessageHeader message={message} participant={participant} />
        <ReactMarkdown className={classes.markdownContainer} remarkPlugins={[remarkGfm]}>
          {message.rawMessageBody}
        </ReactMarkdown>
      </div>
    </div>
  )
}

Message.propTypes = {
  message: PropTypes.shape({
    rawMessageBody: PropTypes.string,
    fromParticipantKey: PropTypes.string,
    messageDateUtc: PropTypes.string
  }),
  participants: PropTypes.arrayOf(PropTypes.shape({
    participantKey: PropTypes.string,
    name: PropTypes.string
  }))
}

export default Message
