import React from 'react'
import PropTypes from 'prop-types'
import MilestonesContextProvider from './MilestonesContextProvider'
import MilestonesViewContent from './components/MilestonesViewContent'
import MilestonesViewWrapper from './components/MilestonesViewWrapper'

const MilestonesView = ({
  pageSize,
  defaultSort,
  urlFiltersMap,
  emptyStateLabel,
  defaultDateRange,
  defaultDateRangeOptions,
  milestoneClickRedirectTo
}) => {
  return (
    <MilestonesContextProvider
      pageSize={pageSize}
      defaultSort={defaultSort}
      urlFiltersMap={urlFiltersMap}
      emptyStateLabel={emptyStateLabel}
      defaultDateRange={defaultDateRange}
      defaultDateRangeOptions={defaultDateRangeOptions}
      milestoneClickRedirectTo={milestoneClickRedirectTo}
    >
      <MilestonesViewWrapper>
        <MilestonesViewContent />
      </MilestonesViewWrapper>
    </MilestonesContextProvider>
  )
}

MilestonesView.propTypes = {
  pageSize: PropTypes.number,
  emptyStateLabel: PropTypes.string,
  defaultSort: PropTypes.array,
  milestoneClickRedirectTo: PropTypes.string,
  defaultDateRange: PropTypes.string,
  defaultDateRangeOptions: PropTypes.array,
  urlFiltersMap: PropTypes.object
}

MilestonesView.defaultProps = {
  emptyStateLabel: 'No data found',
  pageSize: 100,
  defaultSort: [{ field: 'entryId', dir: 'asc' }],
  defaultDateRange: 'MTD',
  defaultDateRangeOptions: ['MTD', 'YTD'],
  urlFiltersMap: { a: { filterKey: 'groupIds', op: 'eq' } }
}

export default MilestonesView
