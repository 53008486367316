import { useCallback } from 'react'
import dayjs from 'dayjs'
import { postExport, getExport } from '../../../../../service'

const sleep = (timeout) => new Promise((resolve) => setTimeout(resolve, timeout))

const getReadyExport = async (exportId, maxRetries = 100, sleepTime = 2000) => {
  const doneStatuses = new Set(['READY', 'ERROR'])
  for (let i = 0; i < maxRetries; i++) {
    const { data: exportResult } = await getExport(exportId)
    if (!exportResult) return null

    if (doneStatuses.has(exportResult?.status)) return exportResult

    await sleep(sleepTime)
  }
}

const downloadExport = (url, fileName, target = '_self') => {
  const link = document.createElement('a')
  link.setAttribute('href', url)
  link.setAttribute('target', target)
  link.setAttribute('download', fileName)
  link.style.visibility = 'hidden'
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

/**
 *
 * @param {ExportOptions} exportOptions
 * @return {{onExport: ((function(): Promise<void>)|*)}}
 */
const useOfflineExport = (exportOptions) => {
  const onExport = useCallback(async () => {
    const {
      afterExport = () => {},
      beforeExport = () => {},
      onError,
      fileName,
      format = 'csv'
    } = exportOptions

    try {
      beforeExport()

      const { data: exportResult } = await postExport({
        metadata: {
          exportType: 'MANUAL_POSITIONS_EXPORT',
          asOfDate: dayjs().format('YYYY-MM-DD'),
          fileName: fileName ?? undefined,
          format
        }
      })

      const exportData = await getReadyExport(exportResult.exportId)
      if (exportData?.status === 'ERROR') {
        onError('There was an error processing the export', new Error('export error'))
        return
      }

      // TODO: What should we do in this case
      if (exportData?.status !== 'READY') {
        console.info('Export is still pending after max retries')
        return
      }

      downloadExport(exportData.url, fileName)
      afterExport()
    } catch (err) {
      console.error('An error occurred while trying to export the bobi output')
      onError('There was an error processing the export', err)
    }
  }, [exportOptions])

  return {
    onExport
  }
}

export default useOfflineExport

/**
 * @typedef ExportOptions
 * @property {string} fileName
 * @property {('csv'|'json')} [format='csv']
 * @property {function(string, Error)} onError
 * @property {function()} [beforeExport]
 * @property {function()} [afterExport]
 */
