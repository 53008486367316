import React, { forwardRef, useImperativeHandle, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { Grid, makeStyles } from '@material-ui/core'
import Select from '../../../../molecules/Select'

const useStyles = makeStyles((theme) => ({
  personalSpace: {
    outline: (props) => props.editing ? `2px solid ${theme.palette.primary.main}` : '0px solid transparent',
    transition: 'outline 200ms ease-in-out',
    padding: '2px'
  },
  formLayout: {
    maxWidth: '800px'
  },
  wrap: {
  },
  experience: {
    width: '100%'
  }
}))

const useFormValues = (wo) => {
  const defaultValues = useMemo(() => ({
    userId: wo.userId || '',
    roleId: wo.role?.roleId || ''
  }), [wo])
  return useForm({
    mode: 'onChange',
    reValidateMode: 'onSubmit',
    criteriaMode: 'all',
    defaultValues
  })
}

const AssignExperienceForm = forwardRef(function AssignExperienceForm ({ wealthOwner, roleOptions, editing }, ref) {
  const classes = useStyles({ editing })
  const { handleSubmit, ...formValues } = useFormValues(wealthOwner)
  useImperativeHandle(ref, () => {
    return {
      handleSubmit
    }
  }, [handleSubmit])

  return (
    <FormProvider handleSubmit={handleSubmit} {...formValues}>
      <div className={classes.wrap}>
        <Grid className={classes.formLayout} container spacing={3}>
          <Grid item xs={12}>
            <div className={classes.personalSpace}>
              <Controller
                name='roleId'
                render={({ field, fieldState }) => (
                  <Select
                    className={classes.experience}
                    placeholder={editing ? 'Select an Experience' : 'No Experience Assigned'}
                    options={roleOptions}
                    onChange={field.onChange}
                    value={field.value}
                    readOnly={!editing}
                    showCheckMarOnSelectedItems
                    error={(fieldState.isDirty || fieldState.isTouched) && fieldState.error}
                  />
                )}
              />
            </div>
          </Grid>
        </Grid>
      </div>
    </FormProvider>
  )
})

AssignExperienceForm.propTypes = {
  wealthOwner: PropTypes.shape({
    userId: PropTypes.number,
    role: PropTypes.shape({
      roleId: PropTypes.number
    })
  }),
  roleOptions: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.number
  })),
  editing: PropTypes.bool
}

AssignExperienceForm.defaultProps = {
  editing: false
}

export default AssignExperienceForm
