const baseInputStyles = (theme) => ({
  backgroundColor: 'transparent',
  fontFamily: theme.typography.fontFamily,
  width: '100%',
  border: 'none',
  '&:focus': {
    outline: 'none'
  },
  '&::placeholder': {
    opacity: '0.2',
    fontWeight: 'bold'
  }
})

const sizes = {
  sm: (theme) => ({
    padding: '2px 10px 2px 10px',
    minHeight: '32px',
    '& > input': {
      ...baseInputStyles(theme),
      lineHeight: '32px',
      fontSize: theme.typography.fontSizes.xl
    }
  }),
  lg: (theme) => ({
    padding: '9px 10px 9px 15px',
    minHeight: '32px',
    '& > input': {
      lineHeight: '32px',
      ...baseInputStyles(theme),
      fontSize: theme.typography.fontSizes.xl
    }
  })
}

export const mapInputStyles = (theme, rest) => ({ disabled, size, minWidth }) => ({
  ...rest,
  ...sizes[size](theme),
  backgroundColor: disabled ? theme.palette.gray.lighter : theme.palette.white,
  ...(minWidth && { minWidth })
})
