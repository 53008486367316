import React, { useMemo } from 'react'
import {
  InputBase,
  MenuItem,
  InputAdornment,
  Select as MUISelect,
  CircularProgress,
  Checkbox
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import CheckIcon from '@material-ui/icons/Check'
import { useAppContext } from '../../redux/slices/appContext'
import { selectDefaults, selectProps } from '../../prop-types'
import { TEXT_VARIANTS, ICON_NAMES } from '../../constants'
import { themeConfiguration } from '../../theme'
import Icon from './Icon'

const textVariants = Object.keys(TEXT_VARIANTS).reduce((acc, key) => {
  return {
    ...acc,
    [key]: themeConfiguration.typography[key]
  }
}, {})

const useStyles = makeStyles((theme) => ({
  checkBoxRoot: { marginLeft: 'auto', color: 'green' },
  enabledMenuItem: {
    backgroundColor: theme.palette.summitGrey1
  },
  disabledMenuItem: {
    backgroundColor: theme.palette.seashell
  },
  container: {
    padding: '0.25rem 1.5rem 0.25rem 0',
    '&:not([aria-expanded="true"]) .MuiCheckbox-root': { display: 'none' }
  },
  disabled: {
    color: 'rgba(0, 0, 0, 0.36)'
  },
  selectAdornment: {
    marginRight: theme.spacing(1)
  },
  paper: {
    background: theme.palette.white,
    '& li': {
      background: theme.palette.white
    },
    boxShadow: '0px 4px 12px rgba(24, 27, 53, 0.08)',
    borderRadius: '12px'
  },
  icon: ({ loading }) => (loading ? { display: 'none' } : {}),
  ...textVariants
}))

const Select = ({
  defaultValue,
  disabled,
  className,
  inputClassName,
  onChange,
  options,
  selectedValue,
  textVariant,
  loading,
  displayEmpty,
  closestValueFinder,
  displayCheckMark,
  iconFontSize,
  placeholder,
  onBlur
}) => {
  const classes = useStyles({ loading })
  const { isPrintView } = useAppContext()

  const value = useMemo(() => {
    const val = selectedValue || defaultValue
    const closesValue = closestValueFinder
      ? closestValueFinder(val, options) || val
      : val
    return closesValue
  }, [options, selectedValue, defaultValue, closestValueFinder])

  const icon = isPrintView
    ? () => null
    : () => (
      <Icon
        name={ICON_NAMES.chevronDown}
        variant={textVariant}
        customSize={iconFontSize}
      />
    )

  return (
    <MUISelect
      MenuProps={{
        classes: {
          paper: displayCheckMark ? classes.paper : undefined
        }
      }}
      onChange={onChange}
      className={className}
      displayEmpty={displayEmpty}
      classes={{
        root: clsx(
          classes.container,
          { [classes.disabled]: disabled },
          { [classes[textVariant]]: textVariant },
          { [inputClassName]: !!inputClassName }
        ),
        icon: classes.icon
      }}
      value={value}
      renderValue={(value !== '' && value !== null) ? undefined : () => placeholder}
      {...(loading
        ? {
          endAdornment: (
            <InputAdornment
              className={classes.selectAdornment}
              position='end'
            >
              <CircularProgress size={16} />
            </InputAdornment>
          )
        }
        : {
          IconComponent: icon
        })}
      input={<InputBase disabled={disabled || loading} />}
      onBlur={onBlur}
    >
      {options?.map((option) => {
        return (
          <MenuItem
            key={option.key || option.value || option}
            value={option.value || option}
            className={classes[textVariant]}
            disabled={option?.isDisabled}
            classes={{
              root: option.isDisabled
                ? classes.enabledMenuItem
                : classes.disabledMenuItem
            }}
          >
            {option.startAdormentIcon && (
              <Icon {...option.startAdormentIconProps} />
            )}
            {option.label || option}
            {displayCheckMark && value === option.value && (
              <Checkbox
                classes={{
                  root: classes.checkBoxRoot
                }}
                icon={<CheckIcon />}
                checkedIcon={<CheckIcon />}
              />
            )}
          </MenuItem>
        )
      })}
    </MUISelect>
  )
}

Select.propTypes = selectProps

Select.defaultProps = selectDefaults

export default Select
