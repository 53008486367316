import { compareNumericString, compareOrdinal, compareString } from '../../../utils'

export const sortTypes = {
  compareNumericString,
  compareOrdinal,
  compareString
}

export function createAccessor (accessor) {
  if (accessor?.includes('.')) {
    return (row) => accessor.split('.').reduce((p, c) => p ? p[c] : p, row)
  }
  return accessor
}

export const mapHeaderAccessor = (column) => {
  return ({
    ...column,
    Header: createAccessor(column.header)
  })
}

export const mapSortFunction = (column) => {
  const sortType = sortTypes?.[column?.sortType]
  return {
    ...column,
    sortType: sortType ?? compareNumericString
  }
}

export const mapTitle = (column, options) => {
  const title = options?.title ?? ''
  return (title && column.id === 'title') ? {
    ...column,
    header: title
  } : column
}

export const mapFormatString = (column) => {
  if (column.formatString) return column
  return {
    ...column,
    formatString: undefined // TODO - should there be a default format string?
  }
}

const identity = x => x
const defaultCell = (options) => ({
  Cell: ({ value, column }) => {
    const formatter = options?.formatter ?? identity
    return formatter(value, column.format)
  }
})

export const mapCellAccessor = (cellTypes = {}) => (column, options) => {
  const cellProps = (column.cellType in cellTypes) ? cellTypes[column.cellType] : defaultCell(options)
  return {
    ...column,
    accessor: createAccessor(column.accessor, options),
    ...cellProps
  }
}

export const createColumnMapper = (mappingOps) => {
  const mapColumn = (column, options = {}, index) => {
    const [result] = mappingOps.reduce(([col, opt], operation) => {
      const colResult = operation(col, opt)
      return [colResult, opt]
    }, [column, options])

    if (Array.isArray(result.columns)) {
      result.index = index
      result.columns = result.columns.map(c => mapColumn(c, options))
    }
    return result
  }

  return mapColumn
}
