import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import { makeStyles } from '@material-ui/core'
import RightPanel from '../../RightPanel'
import Text from '../../../atoms/Text'
import { BUTTON_SIZES, TEXT_VARIANTS } from '../../../../constants'
import { useContextualClientContext } from '../context/ContextualClientContext'
import RoundedButton from '../../../atoms/RoundedButton'
import Icon from '../../../atoms/Icon'
import { getIntegrationTitle } from '../helpers'

const useStyles = makeStyles(() => ({
  closeButton: {
    width: '8rem'
  },
  title: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '0.5rem'
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
    height: 'calc(100% - 10rem)'
  },
  footer: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    padding: '1.5rem',
    textAlign: 'center',
    backgroundColor: 'white',
    boxShadow: '0px 0px 14px 0px #1412250F'
  },
  poweredByLegend: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '2rem',
    marginTop: 'auto',
    '& > span': {
      fontSize: '0.85rem',
      marginBottom: '0.5rem'
    }
  }
}))

const ClientIntegrationsDrawer = ({
  open,
  title,
  children,
  toggleOpen,
  showPoweredByLegend
}) => {
  const classes = useStyles()
  const { clientIntegrationData } = useContextualClientContext()

  const integrationMeta = useMemo(() => {
    if (!clientIntegrationData) return null
    return getIntegrationTitle(clientIntegrationData.key)
  }, [clientIntegrationData])

  return (
    <RightPanel
      open={open}
      title={<Text text={title} variant={TEXT_VARIANTS.h2} />}
      toggleOpen={toggleOpen}
      width='400px'
      height='100%'
      showCloseButton
      contentClassName={classes.content}
    >
      {children}
      {showPoweredByLegend && integrationMeta && (
        <div className={classes.poweredByLegend}>
          <span>Powered By</span>
          {integrationMeta.largeIconUrl ? (
            <img style={{ width: integrationMeta.largeIconSize }} src={integrationMeta.largeIconUrl} alt='Practifi Logo' />
          ) : (
            <Icon
              customSize={integrationMeta.largeIconSize}
              name={integrationMeta.largeIcon}
            />
          )}
        </div>
      )}
      <div className={classes.footer}>
        <RoundedButton
          variant='text'
          onClick={toggleOpen}
          size={BUTTON_SIZES.medium}
          className={classes.closeButton}
        >
          Close
        </RoundedButton>
      </div>
    </RightPanel>
  )
}

ClientIntegrationsDrawer.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.string,
  children: PropTypes.func,
  toggleOpen: PropTypes.func,
  showPoweredByLegend: PropTypes.bool
}

ClientIntegrationsDrawer.defaultProps = {
  title: 'CRM Details',
  open: false,
  children: noop,
  toggleOpen: noop,
  integrationType: undefined,
  showPoweredByLegend: false
}

export default ClientIntegrationsDrawer
