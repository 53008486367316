import React, { useCallback, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import {
  useManualClientAvatarUpload
} from '../../../api/special/useAutomaticClientAvatarUpload'
import { useMediaUrl } from '../../../api/media'
import ProfilePictureFileInput from '../../molecules/ProfilePictureFileInput'
import SydInput from '../../commonDesign/SydInput'
import SydButton from '../../commonDesign/Button'
import { useDashboardSettings } from '../ClientDashboard/ClientDashboardContextProvider'
import { useModifyClientInfoMutation } from '../../../api/clients'

const useStyles = makeStyles((theme) => ({
  formActions: {
    display: 'flex',
    flexDirection: 'row',
    gap: '20px',
    justifyContent: 'flex-end'
  },
  avatar: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  formLayout: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px'
  },
  description: {
    color: theme.palette.text.hint,
    padding: '4px'
  },
  wmDescription: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'baseline'
  }
}))
function ClientInfoForm ({
  client,
  onCancel,
  onConfirm,
  canEditWealthMission,
  canEditClientAvatar
}) {
  const classes = useStyles()
  const { settings } = useDashboardSettings()
  const text = useMemo(() => {
    const textValues = settings?.textValues || {}
    return {
      wealthMissionTitle: textValues.wealthMissionTitle || 'Your Wealth Mission',
      wealthMissionDescription: textValues.wealthMissionDescription || 'A short statement on your financial goals',
      wealthMissionPlaceholder: textValues.wealthMissionPlaceholder || 'Your Wealth Mission',
      avatarDescription: textValues.avatarDescription || 'A picture that represents you or your household'
    }
  }, [settings?.textValues])
  const { upload, isUploading, mediaId } = useManualClientAvatarUpload({ client, canEdit: canEditClientAvatar })
  const { data: mediaUrl } = useMediaUrl(client?.extras?.profileData?.profilePicMediaId, {})

  const [processing, setProcessing] = useState(false)
  const { mutateAsync: modifyClient } = useModifyClientInfoMutation()
  const wealthMissionRef = useRef()
  const handleSave = useCallback(async (e) => {
    e.stopPropagation()
    e.preventDefault()

    const updateValues = {
      clientId: client.clientId
    }
    if (canEditWealthMission) {
      updateValues.description = wealthMissionRef.current.value
    }
    if (canEditClientAvatar && mediaId) {
      updateValues.avatarMediaId = mediaId
    }

    try {
      setProcessing(true)
      await modifyClient(updateValues)
      onConfirm && onConfirm(e, updateValues)
    } catch (err) {
      console.error(err)
      alert('There was a problem saving your information. Please try again later.')
      onCancel()
    } finally {
      setProcessing(false)
    }
  }, [wealthMissionRef, canEditClientAvatar, canEditWealthMission, onConfirm, onCancel, client, setProcessing, mediaId, modifyClient])

  return (
    <div className={classes.formLayout}>
      {canEditClientAvatar ? (
        <div className={classes.avatar}>
          <ProfilePictureFileInput
            ignoreDelete
            clientId={client?.clientId}
            disabledOpacity={1}
            isLoading={isUploading}
            placeholderSrc={mediaUrl || client?.avatarUrl}
            onDropAccepted={upload}
            label='Upload'
          />
          <div className={classes.description}>{text.avatarDescription}</div>
        </div>
      ) : null}
      {canEditWealthMission ? (
        <div className={classes.wealthMission}>
          <div className={classes.wmDescription}>
            <span>{text.wealthMissionTitle}</span>
            <span className={classes.description}>{text.wealthMissionDescription}</span>
          </div>
          <SydInput
            ref={wealthMissionRef}
            iconName='wealthJourneyMilestones'
            placeholder={text.wealthMissionPlaceholder}
            defaultValue={client?.description}
          />
        </div>
      ) : null}
      <div className={classes.formActions}>
        <SydButton size='lg' variant='outline' onClick={onCancel} disabled={processing}>Cancel</SydButton>
        <SydButton
          icon='check'
          size='lg'
          variant='primary'
          onClick={handleSave}
          processing={processing}
          disabled={isUploading}
        >
          Save
        </SydButton>
      </div>
    </div>
  )
}

ClientInfoForm.propTypes = {
  client: PropTypes.shape({
    clientId: PropTypes.number,
    extras: PropTypes.object,
    description: PropTypes.string,
    avatarUrl: PropTypes.string
  }),
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
  canEditWealthMission: PropTypes.bool,
  canEditClientAvatar: PropTypes.bool
}

export default ClientInfoForm
