import React, { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import dayjs from 'dayjs'
import { useRebalancingRunReport } from '../../../api/rebalancer'
import RoundedButton from '../../atoms/RoundedButton'
import TradeResultsTable from './TradeResultsTable'
import WithdrawalResultsTable from './WithdrawalResultsTable'
import TradeResultsFilter from './components/TradeResultsFilter'
import useFilters from './hooks/useFilters'
import ClientDisplay from './components/ClientDisplay'
import AvailableCashMetric from './components/AvailableCashMetric'
import { useAvailableCash } from './hooks/useAvailableCash'
import TotalTradesMetric from './components/TotalTradesMetric'

const useStyles = makeStyles((theme) => ({
  resultsView: {
    padding: '0 5rem',
    width: '100%',
    '& > header > *:nth-child(2)': {
      paddingLeft: '0 !important'
    }
  },
  button: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    textTransform: 'unset'
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  headerActions: {
    display: 'flex',
    flexDirection: 'column',
    '& > h3': {
      margin: '.5rem 0'
    }
  },
  headerControls: {
    '& > *:first-child ': {
      marginRight: '1rem'
    }
  },
  section: {
    marginBottom: '2rem'
  },
  metrics: {
    display: 'flex',
    padding: '.5rem 0',
    '& > *:first-child': {
      paddingLeft: '0 !important',
      borderRight: '1px solid #c8c8c8'
    }
  }
}))

const searchFields = ['accountName', 'accountNumber', 'assetName']

const filterOptions = (symbolOptions, custodianOptions) => [
  {
    key: 'taxStatusId',
    label: 'Tax Status',
    values: [
      {
        value: 1,
        label: 'Taxable'
      },
      {
        value: 2,
        label: 'Tax Deferred'
      },
      {
        value: 3,
        label: 'Non-Taxable'
      },
      {
        value: 4,
        label: 'Not Defined'
      }
    ]
  },
  {
    key: 'action',
    label: 'Type',
    values: [
      {
        value: 'S',
        label: 'Sell'
      },
      {
        value: 'B',
        label: 'Buy'
      }
    ]
  },
  {
    key: 'assetIdentifier',
    label: 'Symbol',
    values: symbolOptions.map(o => ({
      value: o,
      label: o
    }))
  },
  {
    key: 'custodianName',
    label: 'Custodian',
    values: custodianOptions.map(o => ({
      value: o,
      label: o
    }))
  }
]

const mappings = {
  taxStatusId: {
    1: 'Taxable',
    2: 'Tax Deferred',
    3: 'Non-Taxable',
    4: 'Not Defined'
  },
  action: {
    S: 'Sell',
    B: 'Buy'
  }
}

const mapData = (tradeData, mappings) => {
  const mappingEntries = Object.entries(mappings)
  return tradeData.map(data => {
    return mappingEntries.reduce((acc, [key, value]) => {
      const {
        [key]: prevValue,
        ...rest
      } = acc
      return {
        ...rest,
        [key]: value[prevValue]
      }
    }, data)
  })
}

const resolveKey = (key, trades) => [...trades.map(({ [key]: k }) => k)
  .reduce((acc, curr) => {
    if (!acc.has(curr)) {
      acc.add(curr)
    }

    return acc
  }, new Set())]

const ResultsView = () => {
  const classes = useStyles()
  const { rebalancingRunId } = useParams()
  const {
    data,
    isLoading
  } = useRebalancingRunReport(rebalancingRunId)

  const tradeData = useMemo(() => data?.trades ?? [], [data])

  const symbolOptions = useMemo(() => {
    return resolveKey('assetIdentifier', tradeData)
  }, [tradeData])

  const custodianOptions = useMemo(() => {
    return resolveKey('custodianName', tradeData)
  }, [tradeData])

  const options = filterOptions(symbolOptions, custodianOptions)
  const {
    applyFilters,
    onChangeFilters,
    getSelectedFilters,
    onChangeSearch,
    onClearSearch
  } = useFilters({ searchFields })

  const trades = useMemo(() => {
    return mapData(applyFilters(tradeData), mappings)
  }, [applyFilters, tradeData])

  const {
    cashResponse,
    cashLoading
  } = useAvailableCash(data?.levelId)

  if (isLoading) {
    return null
  }

  return (
    <div className={classes.resultsView}>
      <header>
        <div className={classes.header}>
          <ClientDisplay
            clientId={data?.levelId}
          />
          <div className={classes.headerActions}>
            <h3>As of {dayjs(data.createdDate).format('MMMM D, YYYY')}</h3>
            <div className={classes.headerControls}>
              <RoundedButton variant='outlined'>Export</RoundedButton>
              <RoundedButton variant='contained' primary>Submit Trades</RoundedButton>
            </div>
          </div>
        </div>

        <div className={classes.metrics}>
          <TotalTradesMetric
            totalTrades={tradeData.length}
            loading={isLoading}
          />
          <AvailableCashMetric
            cashData={cashResponse}
            loading={cashLoading}
          />
        </div>
      </header>
      <section className={classes.section}>
        <TradeResultsFilter
          getSelectedFilters={getSelectedFilters}
          onChangeFilters={onChangeFilters}
          options={options}
          onChangeSearch={onChangeSearch}
          onClearSearch={onClearSearch}
        />
      </section>
      <section className={classes.section}>
        <div>Trades</div>
        <TradeResultsTable data={trades} isLoading={isLoading} />
      </section>
      {(data?.withdrawals
        ? (
          <section className={classes.section}>
            <div>Withdrawals</div>
            <WithdrawalResultsTable data={data?.withdrawals ?? []} isLoading={isLoading} />
          </section>
        )
        : null
      )}
    </div>
  )
}

export default ResultsView
