import React, { useEffect, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import first from 'lodash/first'
import isEmpty from 'lodash/isEmpty'
import { useAbundanceEngineViewContentById } from '../../../api/abundanceEngine'
import AbundanceParser from '../../../abundanceEngine/AbundanceParser'
import { parseUrlSearchParams } from '../../../utils/parseUrlSearchParams'
import AbundanceEngineContextProvider from '../../../abundanceEngine/components/AbundanceParameterContextProvider'
import { useTitleEffect } from '../../../redux/slices/appContext'
import { LOCAL_STORAGE_KEYS } from '../../../constants'
import { isJson } from '../../../utils'
import AdvisorHomeContextProvider from './AdvisorHomeContextProvider'

const useStyles = makeStyles({
  root: {
    height: '100%',
    width: '100%'
  }
})

function replaceUrlQueryWithArrayParams (
  arrayParams,
  location,
  preserveCurrentSearch = false
) {
  const currentParams = Object.fromEntries([
    ...new URLSearchParams(location.search)
  ])
  const params = arrayParams.reduce(
    (acc, { pathSegment, values }) => ({ ...acc, [pathSegment]: values }),
    preserveCurrentSearch ? currentParams : {}
  )
  const urlParams = new URLSearchParams(params)
  return `${location.pathname}?${urlParams.toString()}`
}

const useScopeFromQueryString = (config) => {
  const history = useHistory()

  const scopeObject = useMemo(() => {
    const search = history.location.search
    const params = parseUrlSearchParams({ location: { search } })

    const appliedScopes = config.scopes.reduce((prev, cur) => {
      prev.push({
        ...cur,
        values: (params[cur.pathSegment] || []).filter(Boolean)
      })
      return prev
    }, [])

    const scope = appliedScopes?.[0] ?? null

    if (scope && isEmpty(scope?.values)) {
      const scopeValues = localStorage.getItem(
        LOCAL_STORAGE_KEYS.advisorHomeScopeValues
      )
      if (scopeValues && isJson(scopeValues)) {
        scope.values = JSON.parse(scopeValues)
      }
    }
    return { scope }
  }, [history.location.search, config])

  /** rewrite parameter array */
  useEffect(() => {
    if (scopeObject.scope) {
      history.replace(
        replaceUrlQueryWithArrayParams([scopeObject.scope], history.location, true)
      )
    }
  }, [history, scopeObject])

  return scopeObject
}

const AdvisorHomeTemplate = ({ config }) => {
  const classes = useStyles()
  const { data, isLoading, error } = useAbundanceEngineViewContentById(config.viewId)
  const scope = useScopeFromQueryString(config)
  useTitleEffect(config.name, config.parentPath)

  const abundanceEngineViewParams = useMemo(
    () => {
      const values = scope?.scope?.values ?? []
      return {
        advisorId: !isEmpty(values) ? first(values) : null
      }
    },
    [scope]
  )

  if (error) return <div>Whoopsie</div>
  if (isLoading) return null

  return (
    <AdvisorHomeContextProvider scope={scope}>
      <AbundanceEngineContextProvider additionalParams={abundanceEngineViewParams}>
        <AbundanceParser
          jsx={data.content}
          className={classes.root}
        />
      </AbundanceEngineContextProvider>
    </AdvisorHomeContextProvider>
  )
}

AdvisorHomeTemplate.propTypes = {
  config: PropTypes.shape({
    name: PropTypes.string,
    viewId: PropTypes.number,
    parentPath: PropTypes.string,
    scopes: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        groupTypeId: PropTypes.number,
        pathSegment: PropTypes.string
      })
    )
  }).isRequired
}

export default AdvisorHomeTemplate
