import React, { createContext, useContext } from 'react'
import PropTypes from 'prop-types'

export const NavigationContext = createContext({})

export const useAdvisorHomeNavigation = () => {
  return useContext(NavigationContext)
}

export function NavigationProvider ({ templateConfiguration, children }) {
  return (
    <NavigationContext.Provider value={templateConfiguration}>
      {children}
    </NavigationContext.Provider>
  )
}

NavigationProvider.propTypes = {
  templateConfiguration: PropTypes.any,
  children: PropTypes.node
}
