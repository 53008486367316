import React, { useCallback, useState } from 'react'
import { makeStyles, Grid } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { Controller, useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { usePolicy } from '../../../../../hooks/usePolicy'
import FadeIn from '../../../../molecules/Transitions/FadeIn'
import { ADMIN_ROUTES } from '../../../../../constants'
import SaveCancelFooter from '../../shared/SaveCancelFooter'
import SydLabel, { hookFormErrorAdapter } from '../../../../commonDesign/SydLabel'
import { useCreateManagerMutation } from '../../../../../api/accounts'
import SydInput from '../../../../commonDesign/SydInput'
import Icon from '../../../../atoms/Icon'

const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    justifyContent: 'center',
    padding: '10px',
    '& p': {
      color: `${theme.palette.primary.dark}`
    }
  },
  wrap: {
    maxWidth: '900px',
    margin: '20px'
  },
  noAccess: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '300px',
    fontSize: theme.typography.fontSizes.h1
  },
  inputLabel: {
    color: theme.palette.black,
    fontWeight: 600,
    '& span': {
      color: theme.palette.black
    }
  },
  header: {
    fontSize: theme.typography.fontSizes.xl,
    marginBottom: '20px'
  },
  middle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%'
  }
}))

function NewManager () {
  const classes = useStyles()
  const canCreate = usePolicy('admin_edit_managers')
  const history = useHistory()
  const { mutateAsync: createManager } = useCreateManagerMutation()

  const form = useForm({
    defaultValues: { name: '', symbol: '' }
  })
  const { handleSubmit } = form
  const [processing, setProcessing] = useState(false)
  const onSave = useCallback(async (e) => {
    const onSuccess = async (form) => {
      const commandBody = {
        manager: {
          name: form.name || null,
          symbol: form.symbol || null
        }
      }

      setProcessing(true)
      try {
        const saveResult = await createManager(commandBody)
        if (saveResult?.manager?.managerId) {
          history.replace(`${ADMIN_ROUTES.MANAGER_DETAILS}/${saveResult.manager.managerId}`)
        } else {
          history.replace(`${ADMIN_ROUTES.ACCOUNTS}?tab=managers`)
        }
      } finally {
        setProcessing(false)
      }
    }
    const onError = (errors) => {
      // eslint-disable-next-line no-throw-literal
      throw { validationErrors: errors }
    }
    const handler = handleSubmit(onSuccess, onError)
    await handler(e)
  }, [handleSubmit, createManager, history, setProcessing])

  const onCancel = useCallback(() => { history.push(`${ADMIN_ROUTES.ACCOUNTS}?tab=managers`) }, [history])

  if (!canCreate) {
    return (
      <FadeIn className={classes.noAccess}>
        You do not have access to this resource
      </FadeIn>
    )
  }

  return (
    <FadeIn className={classes.form}>
      <div className={classes.wrap}>
        <header className={classes.header}>
          Fill out the following fields to create a Manager
        </header>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <Controller
              name='name'
              rules={{ required: true, maxLength: 80 }}
              control={form.control}
              render={({ field, fieldState }) => (
                <SydLabel label='Name' required description='Max 80 Characters' error={hookFormErrorAdapter(form, fieldState)}>
                  <SydInput placeholder='Name' {...field} />
                </SydLabel>
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <div className={classes.middle}>
              <Alert severity='warning' icon={<Icon name='warning' customSize={20} />} style={{ flex: 1 }}>
                <span>Avoid using marketable security symbols</span>
              </Alert>
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name='symbol'
              rules={{ required: true, maxLength: 30 }}
              control={form.control}
              render={({ field, fieldState }) => (
                <SydLabel label='Symbol' required description='Max 30 Characters' error={hookFormErrorAdapter(form, fieldState)}>
                  <SydInput placeholder='Sybol' {...field} />
                </SydLabel>
              )}
            />
          </Grid>
        </Grid>
      </div>
      <SaveCancelFooter
        onCancel={onCancel}
        onSave={onSave}
        processing={processing}
        disabled={!form.formState.isValid}
        saveText='Create Manager'
      />
    </FadeIn>
  )
}

export default NewManager
