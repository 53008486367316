import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useSearchAdvisorUsers } from '../../../../../../api/users'
import Loading from '../../../../../molecules/Loading'

function AdvisorValueDisplay ({ value }) {
  const query = useMemo(() => ({
    filters: {
      externalUserId: value
    },
    take: 1
  }), [value])
  const { data, isLoading } = useSearchAdvisorUsers(query)

  if (isLoading) {
    return (
      <div>
        <Loading size={16} />
      </div>
    )
  }

  if (data?.length === 1) {
    const advisor = data.at(0)
    return (
      <div>{advisor.firstName} {advisor.lastName} ({advisor.email})</div>
    )
  }

  return (
    <div>{value}</div>
  )
}

AdvisorValueDisplay.propTypes = {
  value: PropTypes.any
}

export default AdvisorValueDisplay
