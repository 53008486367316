import React, { useCallback, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Box, makeStyles } from '@material-ui/core'
import { FormProvider, useFieldArray, useForm } from 'react-hook-form'
import noop from 'lodash/noop'
import { AccountFormSection, useAccountFormContext } from '../AccountFormProvider'
import SubmitButtons from '../SubmitButtons'
import { ACCOUNTS } from '../../../../../policies/admin'
import EditButton from '../../shared/EditButton'
import SydReadOnly from '../../../../commonDesign/SydReadOnly'
import SectionHeader from '../../shared/SectionHeader'
import { updateAccountBillingAccounts } from '../../../../../service'
import { ALERT_SEVERITY, ALERT_VARIANT, ICON_NAMES } from '../../../../../constants'
import Alert from '../../../../atoms/Alert'
import SydButton from '../../../../commonDesign/Button'
import Skeleton from '../../../../atoms/Skeleton'
import AccountBillingAccountRow from './AccountBillingAccountRow'

const useStyles = makeStyles((theme) => ({
  readOnly: {
    fontSize: '14px',
    fontWeight: 400
  }
}))

const AccountBillingAccounts = ({
  onSave = noop
}) => {
  const { account, sectionEditing, cancelEdit, editing, editSection } = useAccountFormContext()
  const isEditing = sectionEditing('account_billing_accounts')
  const [error, setError] = useState(null)
  const classes = useStyles()

  const [isSubmitting, setIsSubmitting] = useState(false)
  const isLoading = useMemo(() => !Object.keys(account?.billingInfo)?.length, [account])

  const billingAccounts = useMemo(() => {
    return account?.billingInfo?.billingAccounts?.map(billingAccount => {
      let billingAccountType = 'external'
      if (billingAccount.isPayByInvoice) {
        billingAccountType = 'invoice'
      }
      if (billingAccount.billingAccountId) {
        billingAccountType = 'summit'
      }

      return {
        ...billingAccount,
        billingAccountType
      }
    }) ?? []
  }, [account])

  const formMethods = useForm({
    mode: 'onChange',
    defaultValues: {
      billingAccounts: []
    }
  })

  const {
    control,
    handleSubmit,
    reset
  } = formMethods

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'billingAccounts'
  })

  useEffect(() => {
    if (billingAccounts?.length) {}
    reset({ billingAccounts })
  }, [reset, account, billingAccounts])

  const onSubmit = useCallback(async (values) => {
    setError(null)
    setIsSubmitting(true)

    const totalPercentage = values.billingAccounts.reduce((acc, ba) => (
      acc + (ba.distributionType === 'PERCENTAGE' ? parseInt(ba.distributionValue, 10) : 0)
    ), 0)
    if (totalPercentage > 100) {
      setError('Total percentage cannot be greater than 100%')
      setIsSubmitting(false)
      return
    }

    if (values.billingAccounts.some(ba => !ba.billingAccountId && ba.billingAccountType === 'summit')) {
      setError('Billing account required for Accounts from Summit')
      setIsSubmitting(false)
      return
    }

    if (values.billingAccounts.some(ba => !ba.externalBillingAccount?.length && ba.billingAccountType === 'external')) {
      setError('External billing account text required for External Billing Accounts')
      setIsSubmitting(false)
      return
    }

    if (values.billingAccounts.some(ba => (ba.distributionValue === '' || ba.distributionValue === null) && ba.distributionType !== 'REMAINING')) {
      setError('A value is required for billing accounts that are set to percentage or fixed amount')
      setIsSubmitting(false)
      return
    }

    try {
      const billingAccounts = values.billingAccounts.map(billingAccount => {
        const { billingAccountType, billingAccountId, externalBillingAccount, distributionType, distributionValue } = billingAccount
        return {
          billingAccountId: billingAccountType === 'summit' ? billingAccountId : null,
          externalBillingAccount: billingAccountType === 'external' ? externalBillingAccount : null,
          isPayByInvoice: billingAccountType === 'invoice',
          distributionType: distributionType ?? 'PERCENTAGE',
          distributionValue: distributionValue ?? '100',
          ...(values.billingAccounts.length === 1 && {
            distributionType: 'PERCENTAGE',
            distributionValue: 100
          })
        }
      })

      await updateAccountBillingAccounts(account.accountId, { billingAccounts })
      cancelEdit()
      onSave()
    } catch (err) {
      setError('Issue saving billing accounts. Try again')
    }
    setIsSubmitting(false)
  }, [account.accountId, cancelEdit, setError, onSave])

  const onCancel = useCallback(() => {
    reset()
    cancelEdit()
    setError(null)
  }, [reset, cancelEdit])

  const addNewBillingAccount = useCallback(() => {
    const totalExistingPercentage = fields?.reduce((acc, ba) => (
      acc + (ba.distributionType === 'PERCENTAGE' ? ba.distributionValue : 0)
    ), 0)
    append([{
      accountId: account.accountId,
      accountName: null,
      accountNumber: null,
      billingAccountType: 'summit',
      distributionType: 'PERCENTAGE',
      distributionValue: Math.max(0, 100 - totalExistingPercentage),
      externalBillingAccount: null,
      billingAccountId: null
    }], { shouldFocus: false })
  }, [account.accountId, append, fields])

  return (
    <AccountFormSection section='account_billing_accounts'>
      <SectionHeader text='Billing Accounts'>
        <EditButton
          policy={ACCOUNTS.editBillingAccounts}
          editing={isEditing}
          onClick={() => editSection({
            section: 'account_billing_accounts'
          })}
          disabled={editing}
        />
      </SectionHeader>

      {error?.length && (
        <Box mb={2}>
          <Alert variant={ALERT_VARIANT.standard} severity={ALERT_SEVERITY.error}>
            {error}
          </Alert>
        </Box>
      )}
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box display='flex' flexDirection='column' gridGap='16px'>
            {isLoading ? (
              <Box display='flex' alignItems='center' gridGap='16px'>
                <Skeleton width='300px' height='48px' />
                <Skeleton width='300px' height='48px' />
              </Box>
            ) : (
              <>
                {fields.map((item, index) => (
                  <Box key={index} flexGrow={1}>
                    <AccountBillingAccountRow
                      index={index}
                      isEditing={isEditing}
                      showDetails={fields?.length > 1}
                      formMethods={formMethods}
                      onAddBillingAccount={addNewBillingAccount}
                      onRemoveBillingAccount={() => remove(index)}
                    />
                  </Box>
                ))}
                {!fields.length && (
                  <Box display='flex' alignItems='center' gridGap='16px'>
                    <Box style={{ width: '300px' }}>
                      <SydReadOnly className={classes.readOnly}>No billing accounts</SydReadOnly>
                    </Box>
                  </Box>
                )}
                {fields.length !== 1 && isEditing && (
                  <Box>
                    <SydButton
                      variant='outline'
                      onClick={addNewBillingAccount}
                      icon={ICON_NAMES.add}
                    >
                      Add Billing Account
                    </SydButton>
                  </Box>
                )}
                <SubmitButtons
                  editMode={isEditing}
                  onCancel={onCancel}
                  isSubmitting={isSubmitting}
                />
              </>
            )}
          </Box>
        </form>
      </FormProvider>
    </AccountFormSection>
  )
}

AccountBillingAccounts.propTypes = {
  onSave: PropTypes.func
}

export default AccountBillingAccounts
