import React, { useCallback, useRef } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { TextField } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import ConversationButton from '../../components/ConversationButton'
import { useCreateConversationMutation, useDefaultParticipants } from '../../../../../api/conversations'
import ParticipantSelector from './ParticipantSelector'

const useStyles = makeStyles(theme => ({
  createForm: {
    display: 'flex',
    width: '100%',
    height: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    fontSize: '20px',
    padding: '50px'
  },
  main: {
    width: '80%',
    '& .MuiTextField-root': {
      width: '100%'
    }
  },
  footer: {
    margin: '20px 0',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    gap: '20px',
    width: '80%'
  }
}))

const CreateConversationForm = ({ clientId }) => {
  const classes = useStyles()
  const conversationNameRef = useRef()
  const participantsRef = useRef()
  const history = useHistory()
  const { data, isLoading, error } = useDefaultParticipants(clientId)

  const handleCancel = useCallback((e) => {
    e.stopPropagation()
    e.preventDefault()

    if (history.location.state?.from) {
      history.goBack()
    } else {
      history.replace(`/conversations/c/${clientId}`)
    }
  }, [history, clientId])

  const { mutateAsync: createConversation } = useCreateConversationMutation()
  const handleSubmit = useCallback(async (e) => {
    e.stopPropagation()
    e.preventDefault()

    const name = conversationNameRef.current.value
    const userIds = participantsRef.current.getUserIds()
    if (!name) {
      alert('You must select a name')
      return
    }
    if (!userIds?.length) {
      alert('You must select at least one participant')
      return
    }

    try {
      const response = await createConversation({
        name,
        clientId,
        includedWealthOwners: userIds
      })
      history.push(`/conversations/c/${clientId}/t/${response.conversationKey}`)
    } catch (err) {
      console.error(err)
    }
  }, [clientId, createConversation, history])

  if (isLoading) {
    return (
      <div className={classes.createForm}>
        Preparing
      </div>
    )
  }

  if (error) {
    return (
      <div className={classes.createForm}>
        {error.message}
      </div>
    )
  }

  return (
    <div className={classes.createForm}>
      <header><h1>Start a new conversation</h1></header>
      <main className={classes.main}>
        <div>
          <h5>What's on your mind?</h5>
          <TextField
            variant='outlined'
            autoFocus
            inputRef={conversationNameRef}
            placeholder='Enter your topic here'
          />
        </div>
        <ParticipantSelector ref={participantsRef} participants={data} />
      </main>
      <footer className={classes.footer}>
        <ConversationButton onClick={handleCancel}>Cancel</ConversationButton>
        <ConversationButton primary onClick={handleSubmit}>Contact</ConversationButton>
      </footer>
    </div>
  )
}

CreateConversationForm.propTypes = {
  clientId: PropTypes.number
}

export default CreateConversationForm
