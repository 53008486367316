import React from 'react'
import { Box, makeStyles, Typography } from '@material-ui/core'
import { animated, useSpring } from 'react-spring'
import { TEXT_VARIANTS } from '../../../../constants'
import { useCurrentClient } from '../../../../api/clients'
import FastAvatar from '../../../molecules/FastAvatar'

const useStyles = makeStyles((theme) => ({
  headerFamilySection: theme.layout.headerFamily,
  headerFamilyTitle: theme.layout.headerFamilyTitle
}))

const CurrentClient = () => {
  const classes = useStyles()
  const springs = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    leave: { opacity: 0 }
  })
  const { data: client, isLoading } = useCurrentClient()

  if (isLoading) {
    return null
  }

  return (
    <animated.div className={classes.headerFamilySection} style={springs}>
      <FastAvatar
        isLoading={isLoading}
        avatarUrl={client?.avatarUrl}
        abbreviation={client?.clientAbbreviation}
        size='md'
      />
      <Box ml={1}>
        <Typography className={classes.headerFamilyTitle} variant={TEXT_VARIANTS.h2} noWrap>
          {client?.longName}
        </Typography>
      </Box>
    </animated.div>
  )
}

export default CurrentClient
