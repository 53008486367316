import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import SourceSchemaTable from './SourceSchemaTable'

const useStyles = makeStyles(theme => ({
  sourceSchema: {
    marginBottom: '10rem'
  }
}))

const SourceSchema = ({ dataSource }) => {
  const classes = useStyles()
  const schema = useMemo(() => {
    const objSchema = dataSource?.metadata?.schema
    if (!objSchema) return []
    return Object.entries(objSchema).map(([key, value]) => ({
      key,
      ...value
    }))
  }, [dataSource])

  return (
    <div className={classes.sourceSchema}>
      <header className='__source-schema-header'>
        <h3>Source Fields <span>({schema.length})</span></h3>
      </header>
      <SourceSchemaTable schema={schema} />
    </div>
  )
}

SourceSchema.propTypes = {
  dataSource: PropTypes.object
}

export default SourceSchema
