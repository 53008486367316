
import { useMemo } from 'react'
import isEmpty from 'lodash/isEmpty'
import { useQueryClient, useMutation } from '@tanstack/react-query'
import { useAssetSearch, useAssetsHoldings } from '../../../../api/coreData'
import { QUERY_KEYS } from '../../../../api/queryKeys'
import { CALC_TYPES, DATE_TYPES, LEVEL_TYPES } from '../../../../constants'
import { updateAsset } from '../../../../service'

const getDetailsQuery = (query = {}) => {
  const skip = query?.skip ?? 0
  const take = query?.take ?? 100
  const sort = query?.sort
  const includes = {
    classificationTags: true,
    assetType: true
  }
  return {
    ...query,
    includes,
    skip,
    take,
    sort,
    resultType: 'details'
  }
}

export const useAsset = ({
  assetId
}) => {
  const query = useMemo(() => {
    return getDetailsQuery({
      filters: {
        assetId
      }
    })
  }, [assetId])

  const {
    data = [],
    isLoading,
    error
  } = useAssetSearch(query)

  const asset = isEmpty(data) ? {} : data[0]
  return {
    data: isLoading ? {} : asset,
    loading: isLoading,
    error
  }
}

export function useUpdateAsset () {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (parameters) => {
      return updateAsset(parameters.assetId, parameters.body)
    },
    onMutate: async (parameters) => {
      // Snapshot the previous value
      const prevAssets = queryClient.getQueryData(
        [QUERY_KEYS.searchAssets],
        { exact: false }
      )
      const asset = prevAssets.find(
        ({ assetId }) => assetId === parameters.assetId
      )

      const newAsset = {
        ...asset || {},
        displayName: parameters.body.displayName
      }

      // Modify cache to reflect this optimistic update
      queryClient.setQueryData([QUERY_KEYS.searchAssets], [newAsset])

      // Return a snapshot so we can rollback in case of failure
      return { prevAssets }
    },
    // If the mutation fails, use the context we returned above
    onError: (error, newAsset, { prevAssets }) => {
      // Rollback the changes using the snapshot
      queryClient.setQueryData(
        [QUERY_KEYS.searchAssets],
        prevAssets
      )
      console.error(error, newAsset)
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.searchAssets]
      })
    }
  })
}

export const useHoldings = ({
  pageIndex,
  pageSize,
  startDate,
  endDate,
  assetId
}) => {
  const query = useMemo(() => {
    return {
      levelFilters: {
        dateType: DATE_TYPES.all,
        assetIds: [assetId],
        levelTypes: [
          LEVEL_TYPES.ACCOUNTS
        ],
        calcType: CALC_TYPES.balance,
        offset: `${pageIndex}`,
        limit: `${pageSize}`
      },
      dateRange: {
        startDate,
        endDate
      }
    }
  }, [pageSize, pageIndex, startDate, endDate, assetId])

  const {
    data,
    isLoading,
    isPreviousData,
    error
  } = useAssetsHoldings(query)

  return {
    data: isLoading ? [] : data,
    loading: isLoading || isPreviousData,
    error
  }
}
