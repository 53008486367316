import React from 'react'
import { Divider, IconButton, makeStyles } from '@material-ui/core'
import { useAdvisorViewTabGroup } from '../../AdvisorView/components/AdvisorViewTabs/advisorViewTabGroupContext'
import { ICON_NAMES } from '../../../../constants'
import Icon from '../../../atoms/Icon'
import Tooltip from '../../../atoms/Tooltip'

const useStyles = makeStyles(() => ({
  toggleActions: {
    display: 'flex',
    flexDirection: 'row',
    '& > hr': {
      height: '3rem',
      margin: '0 0.25rem'
    }
  }
}))

const SectionToggleOptions = () => {
  const classes = useStyles()
  const { selectValue, selectedValue, options } = useAdvisorViewTabGroup()

  return (
    <div className={classes.toggleActions}>
      {options.map((option, index) => {
        return (
          <React.Fragment key={option.value}>
            <Tooltip title={option?.payload?.tooltipTitle}>
              <IconButton onClick={() => selectValue(option.value)}>
                <Icon
                  name={
                    option?.payload && option?.payload?.icon in ICON_NAMES
                      ? option?.payload?.icon
                      : ICON_NAMES.accountCircle
                  }
                  color={option.value === selectedValue ? '#141929' : '#898D9B'}
                  customSize='1.5rem'
                />
              </IconButton>
            </Tooltip>
            {index !== options.length - 1 && <Divider orientation='vertical' />}
          </React.Fragment>
        )
      })}
    </div>
  )
}

SectionToggleOptions.propTypes = {

}

export default SectionToggleOptions
