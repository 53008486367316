import React, { useCallback, useImperativeHandle, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import RoundedModal from '../../../../../molecules/RoundedModal'
import { useComponentModelContext } from '../ComponentModelProvider'
import EditComponentModelForm from './EditComponentModelForm'

const EditComponentModelDialog = React.forwardRef(function EditComponentModelDialog ({ onEdit }, ref) {
  const [dialogState, setDialogState] = useState({ open: false, processing: false })
  const { componentModel } = useComponentModelContext()
  const close = useCallback(() => {
    setDialogState({ open: false, processing: false })
  }, [setDialogState])

  const complete = useCallback((componentModel) => {
    close()
    onEdit(componentModel, dialogState.index)
  }, [close, onEdit, dialogState])

  useImperativeHandle(ref, () => ({
    open: (item, index) => setDialogState({ open: true, processing: false, item, index })
  }), [setDialogState])

  const item = useMemo(() => {
    return dialogState.item
  }, [dialogState])

  return (
    <RoundedModal
      title='Edit Component Model'
      open={dialogState.open}
      onClose={close}
      position='top'
      disablePortal={false}
    >r
      <div>
        <EditComponentModelForm onComplete={complete} onClose={close} item={item} classificationTagTypeId={componentModel.classificationTagTypeId} />
      </div>
    </RoundedModal>
  )
})

EditComponentModelDialog.propTypes = {
  onEdit: PropTypes.func.isRequired
}

export default EditComponentModelDialog
