import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { Controller, useForm } from 'react-hook-form'
import SydLabel, { hookFormErrorAdapter } from '../../../../../commonDesign/SydLabel'
import SydButton from '../../../../../commonDesign/Button'
import { useModifyAccountDisplayNameMutation } from '../../../../../../api/accounts'
import { useDialogStyles } from '../../useDialogStyles'
import SydInput from '../../../../../commonDesign/SydInput'

const useSubmitter = (form, onComplete) => {
  const { handleSubmit } = form
  const [processing, setProcessing] = useState(false)
  const { mutateAsync: setTaxStatus } = useModifyAccountDisplayNameMutation()
  const onSubmit = useCallback(async (formData) => {
    const command = {
      accountId: formData.accountId,
      displayName: formData.displayName || null
    }

    try {
      setProcessing(true)
      const result = await setTaxStatus(command)
      onComplete(result)
    } finally {
      setProcessing(false)
    }
  }, [setTaxStatus, setProcessing, onComplete])

  const submitter = useCallback(async (e) => {
    const onValid = async (form) => {
      await onSubmit(form)
    }
    const onInvalid = (errors) => {
      console.error(errors)
    }

    const handler = handleSubmit(onValid, onInvalid)
    await handler(e)
  }, [handleSubmit, onSubmit])

  return {
    submitter,
    processing
  }
}

function DisplayNameForm ({ account, onCancel, onComplete }) {
  const classes = useDialogStyles()
  const form = useForm({
    mode: 'onChange',
    defaultValues: {
      accountId: account.accountId,
      displayName: account.displayName
    }
  })

  const { submitter, processing } = useSubmitter(form, onComplete)

  return (
    <>
      <div className={classes.form}>
        <div className={classes.group}>
          <Controller
            name='displayName' control={form.control}
            rules={{ maxLength: { value: 100, message: 'Max 100 Characters' } }}
            render={({ field, fieldState }) => (
              <SydLabel label='Account Display Name' description='Max 100 Characters' error={hookFormErrorAdapter(form, fieldState)}>
                <SydInput placeholder='Enter a Dislpay Name' {...field} />
              </SydLabel>
            )}
          />
        </div>
      </div>
      <div className={classes.actions}>
        <SydButton disabled={processing} variant='ghost' size='lg' onClick={onCancel}>Cancel</SydButton>
        <SydButton
          variant='primary'
          size='lg'
          onClick={submitter}
          processing={processing}
          disabled={!form.formState.isValid}
        >
          Save
        </SydButton>
      </div>
    </>
  )
}

DisplayNameForm.propTypes = {
  account: PropTypes.object,
  onComplete: PropTypes.func,
  onCancel: PropTypes.func
}

export default DisplayNameForm
