import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { Controller, useForm } from 'react-hook-form'
import SydButton from '../../../../../../commonDesign/Button'
import {
  useClientSummary,
  useEditFamilyRateMutation
} from '../../../../../../../api/billing'
import { useDialogStyles } from '../../common'
import Loading from '../../../../../../molecules/Loading'
import SydLabel from '../../../../../../commonDesign/SydLabel'
import MultiSelectBox from '../../shared/MultiSelectBox'
import FeeScheduleTiers from '../../FeeScheduleTiers'
import RemoveList from './RemoveList'

const useSubmitter = (form, onComplete, client, feeSchedule) => {
  const { handleSubmit } = form
  const [processing, setProcessing] = useState(false)
  const [error, setError] = useState(null)
  const { mutateAsync: editFamilyRate } = useEditFamilyRateMutation()
  const onSubmit = useCallback(async (formData) => {
    const command = {
      clientId: client.clientId,
      feeScheduleId: feeSchedule.feeScheduleId,
      familyRateMembers: formData.familyRateMembers
    }

    try {
      setError(null)
      setProcessing(true)
      const result = await editFamilyRate(command)
      if (result?.statusCode === 500) {
        throw new Error('Failed to add fee schedule')
      }
      onComplete(result)
    } catch (err) {
      setError(err?.toString())
    } finally {
      setProcessing(false)
    }
  }, [editFamilyRate, setProcessing, onComplete, client, setError, feeSchedule?.feeScheduleId])

  const submitter = useCallback(async (e) => {
    const onValid = async (form) => {
      await onSubmit(form)
    }
    const onInvalid = (errors) => {
      console.error(errors)
    }

    const handler = handleSubmit(onValid, onInvalid)
    await handler(e)
  }, [handleSubmit, onSubmit])

  return {
    submitter,
    processing,
    error
  }
}

function EditFamilyRateForm ({ client, feeSchedule, onCancel, onComplete, baseQuery }) {
  const classes = useDialogStyles()
  const form = useForm({
    mode: 'onChange',
    defaultValues: {
      clientId: client.clientId,
      feeScheduleId: feeSchedule.feeScheduleId,
      familyRateMembers: feeSchedule.familyRateMembers || []
    }
  })

  const { data, isLoading } = useClientSummary(baseQuery)
  const { submitter, processing, error } = useSubmitter(form, onComplete, client, feeSchedule)
  if (isLoading) {
    return <Loading />
  }

  return (
    <>
      <div className={classes.form}>
        <div className={classes.group}>
          <div>
            <div>{feeSchedule.longName}</div>
            <FeeScheduleTiers feeSchedule={feeSchedule} />
          </div>
        </div>
        <div className={classes.group}>
          <Controller
            name='familyRateMembers' control={form.control}
            render={(f => (
              <SydLabel label='Available Clients' style={{ width: '50%', marginRight: '10px' }}>
                <MultiSelectBox
                  options={data}
                  valueAccessor='clientId'
                  labelAccessor='longName'
                  {...f.field}
                />
              </SydLabel>
            ))}
          />
          <Controller
            name='familyRateMembers' control={form.control}
            render={(f => (
              <SydLabel label='Selected Clients' style={{ width: '50%', paddingRight: '20px' }}>
                <RemoveList
                  options={data}
                  valueAccessor='clientId'
                  labelAccessor='longName'
                  {...f.field}
                />
              </SydLabel>
            ))}
          />
        </div>
      </div>
      <div className={classes.actions}>
        {error ? (<div className={classes.error}>{error}</div>) : null}
        <SydButton disabled={processing} variant='ghost' size='md' onClick={onCancel}>Cancel</SydButton>
        <SydButton
          variant='primary'
          size='md'
          onClick={submitter}
          processing={processing}
        >
          Save
        </SydButton>
      </div>
    </>
  )
}

EditFamilyRateForm.propTypes = {
  baseQuery: PropTypes.object,
  client: PropTypes.shape({
    clientId: PropTypes.number
  }),
  feeSchedule: PropTypes.shape({
    feeScheduleId: PropTypes.number,
    longName: PropTypes.string,
    familyRateMembers: PropTypes.array
  }),
  onComplete: PropTypes.func,
  onCancel: PropTypes.func
}

export default EditFamilyRateForm
