import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'
import { makeStyles } from '@material-ui/core/styles'
import { Box, Grid } from '@material-ui/core'
import { useAlternatives } from '../../../api/coreData'
import { useAppContext } from '../../../redux/slices/appContext'
import { LEVEL_TYPES } from '../../../constants'
import Text from '../../atoms/Text'
import Avatar from '../../atoms/Avatar'
import Skeleton from '../../atoms/Skeleton'
import { useFormattingContext } from '../FormattingProvider/FormattingContext'

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: '20px'
  },
  emptyContainer: {
    height: '300px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  list: {
    display: 'flex',
    width: '100%',
    overflow: 'auto',
    flexDirection: 'column'
  },
  listItem: {
    border: '2px solid #EEF0F8',
    borderRadius: '15px',
    alignItems: 'center',
    marginBottom: '25px',
    padding: '21px 24px'
  },
  assetName: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  avatar: {
    marginRight: '1rem'
  },
  listItemValue: {
    textAlign: 'right'
  },
  title: {
    marginBottom: '36px'
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start'
  },
  date: {
    marginTop: '8px'
  }
}))

const useAlternativesQuery = ({ defaultFilter, top }) => {
  const { clientId, availableDates, loadingAvailableDates } = useAppContext()
  const query = useMemo(() => loadingAvailableDates ? null : {
    dateRange: {
      startDate: availableDates.mainDate,
      endDate: availableDates.mainDate
    },
    levelType: LEVEL_TYPES.ASSETS,
    resultType: 'details',
    filters: {
      ...(defaultFilter || {}),
      clientId: clientId,
      onDeck: true
    },
    sort: [
      { field: 'capitalCalled', dir: 'desc' }
    ],
    take: top
  }, [clientId, availableDates, loadingAvailableDates, defaultFilter, top])

  const { data, isLoading } = useAlternatives(query)

  return {
    data: data || [],
    isLoading
  }
}

const TopOnDeck = ({
  defaultFilter,
  emptyMessage,
  format,
  title,
  top
}) => {
  const classes = useStyles()
  const { formatter } = useFormattingContext()
  const { data = [], isLoading } = useAlternativesQuery({ defaultFilter, top })

  const dataSet = useMemo(() => {
    if (isLoading || isEmpty(data)) return []
    return data.map((item) => ({
      id: item.levelId,
      title: item.assetName,
      committedCapital: formatter(item.committedCapital, format.committedCapital),
      firstCommitDate: formatter(item.firstCommitDate, format.firstCommitDate)
    }))
  }, [data, isLoading, formatter, format])

  const loadingState = useMemo(() => {
    return (
      <Box className={classes.container}>
        <Skeleton height='30px' width='200px' className={classes.title} />
        <Box className={classes.list}>
          {Array(top).fill(0).map((_, index) => (
            <Grid contaier className={classes.listItem} key={`skeleton-${index}`}>
              <Skeleton height='60px' />
            </Grid>
          ))}
        </Box>
      </Box>
    )
  }, [top, classes])

  if (isLoading) {
    return loadingState
  }

  if (isEmpty(dataSet)) {
    return (
      <Box className={classes.emptyContainer}>
        <Text text={emptyMessage} />
      </Box>
    )
  }

  return (
    <Box className={classes.container}>
      <Text
        text={title}
        customFontSize='24px'
        customFontFamily='GothamPro-Bold'
        color='#141929'
        className={classes.title}
      />
      <Box className={classes.list}>
        {dataSet.map(
          ({ id, title, committedCapital, firstCommitDate }) => {
            return (
              <Grid container xs={12} className={classes.listItem} key={id}>
                <Grid item xs={8} className={classes.assetName}>
                  <Avatar
                    src=''
                    customSize='50px'
                    avatarLetters={title}
                    useOneInitial
                    customClassName={classes.avatar}
                  />
                  <Box className={classes.titleContainer}>
                    <Text text={title} customFontSize='1rem' color='#141929' />
                    <Text text={firstCommitDate} color='#212945' className={classes.date} />
                  </Box>
                </Grid>
                <Grid item xs={4} className={classes.listItemValue}>
                  <Text text={committedCapital} customFontSize='20px' color='#212945' />
                </Grid>
              </Grid>
            )
          }
        )}
      </Box>
    </Box>
  )
}

TopOnDeck.propTypes = {
  defaultFilter: PropTypes.object,
  emptyMessage: PropTypes.string,
  format: PropTypes.shape({
    firstCommitDate: PropTypes.string,
    committedCapital: PropTypes.string
  }),
  title: PropTypes.string,
  top: PropTypes.number
}

TopOnDeck.defaultProps = {
  defaultFilter: {},
  emptyMessage: 'No current investments on deck',
  format: {},
  title: 'Up next',
  top: 3
}

export default TopOnDeck
