import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core'
import { useHideSideMenuEffect, useSetAppContext } from '../../../../redux/slices/appContext'
import AbundanceEngineGallery from './galleryView'
import AbundanceEngineGalleryContextProvider from './abundanceEngineGalleryContext'

const useStyles = makeStyles(() => ({
  container: {
    position: 'relative',
    width: '100%',
    height: '100%',
    overflowY: 'hidden'
  }
}))

const AbundanceEngineGalleryView = () => {
  const classes = useStyles()
  const setAppContext = useSetAppContext()
  useHideSideMenuEffect()
  useEffect(() => {
    setAppContext({ presentationMode: true, isSidebarOpen: false })
    return () => {
      setAppContext({ presentationMode: false })
    }
  }, [setAppContext])

  return (
    <div className={classes.container}>
      <AbundanceEngineGalleryContextProvider>
        <AbundanceEngineGallery />
      </AbundanceEngineGalleryContextProvider>
    </div>
  )
}

export default AbundanceEngineGalleryView
