import React, { useCallback, useMemo, useRef, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import PropTypes from 'prop-types'
import { Controller, useForm } from 'react-hook-form'
import { Checkbox } from '@material-ui/core'
import SydButton from '../../../../../commonDesign/Button'
import { useCreateEntry } from '../../../../../../api/wealthJourney'
import SydModalActions from '../../../../../commonDesign/SydModal/SydModalActions'
import SydInput from '../../../../../commonDesign/SydInput'
import { useAppContext } from '../../../../../../redux/slices/appContext'
import { useWealthJourney } from '../../WealthJourneyProvider'
import SydLabel from '../../../../../commonDesign/SydLabel'
import Loading from '../../../../../molecules/Loading'
import SydDatePicker from '../../../../../commonDesign/SydDatePicker'
import SydSelect from '../../../../../commonDesign/SydSelect'

const useSubmitter = (form, onComplete, addAnotherRef, reset) => {
  const { handleSubmit } = form
  const [processing, setProcessing] = useState(false)
  const [error, setError] = useState(null)
  const { mutateAsync: createEntry } = useCreateEntry()
  const onSubmit = useCallback(async (formData) => {
    const command = {
      ...formData
    }

    try {
      setError(null)
      setProcessing(true)
      const result = await createEntry(command)
      if (result?.statusCode === 500) {
        throw new Error('Failed to add entry')
      }
      // eslint-disable-next-line no-debugger
      if (!addAnotherRef.current.checked) {
        onComplete(result)
      } else {
        reset()
      }
    } catch (err) {
      setError(err?.toString())
    } finally {
      setProcessing(false)
    }
  }, [createEntry, setProcessing, onComplete, setError, addAnotherRef, reset])

  const submitter = useCallback(async (e) => {
    const onValid = async (form) => {
      await onSubmit(form)
    }
    const onInvalid = (errors) => {
      console.error(errors)
    }

    const handler = handleSubmit(onValid, onInvalid)
    await handler(e)
  }, [handleSubmit, onSubmit])

  return {
    submitter,
    processing,
    error
  }
}

function AddEntryForm ({ onCancel, onComplete }) {
  const { clientId } = useAppContext()
  const addAnotherRef = useRef(null)
  const { entryTypes, entriesLoading: isLoading } = useWealthJourney()
  const form = useForm({
    mode: 'onChange',
    defaultValues: {
      clientId,
      entryText: '',
      entryDate: new Date(),
      entryTypeId: entryTypes?.length ? entryTypes.at(0).entryTypeId : ''
    }
  })

  const { reset } = form
  const _reset = useCallback(() => {
    reset({
      clientId,
      entryText: '',
      entryDate: new Date(),
      entryTypeId: entryTypes?.length ? entryTypes.at(0).entryTypeId : ''
    })
  }, [reset, clientId, entryTypes])

  const entryTypeOptions = useMemo(() => {
    return (entryTypes || []).filter(et => et.permissions?.edit === true).map(et => ({
      label: et.entryTypeName,
      value: et.entryTypeId
    }))
  }, [entryTypes])
  const { submitter, processing, error } = useSubmitter(form, onComplete, addAnotherRef, _reset)

  if (isLoading) {
    return <Loading />
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Controller
          name='entryDate'
          control={form.control}
          render={(f) => (
            <SydLabel label='Date'>
              <SydDatePicker {...f.field} />
            </SydLabel>
          )}
        />
      </Grid>
      <Grid item xs={6}>
        <Controller
          name='entryTypeId'
          control={form.control}
          render={(f) => (
            <SydLabel label='Type'>
              <SydSelect options={entryTypeOptions} {...f.field} />
            </SydLabel>
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name='entryText'
          control={form.control}
          render={(f) => (
            <SydLabel label='Title'>
              <SydInput {...f.field} />
            </SydLabel>
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <SydModalActions>
          {error ? (<div className='__error'>{error}</div>) : null}
          <div>
            <Checkbox inputRef={addAnotherRef} />
            Add Another
          </div>
          <SydButton disabled={processing} variant='ghost' size='md' onClick={onCancel}>Cancel</SydButton>
          <SydButton
            variant='primary'
            size='md'
            onClick={submitter}
            processing={processing}
          >
            Save
          </SydButton>
        </SydModalActions>
      </Grid>
    </Grid>
  )
}

AddEntryForm.propTypes = {
  onComplete: PropTypes.func,
  onCancel: PropTypes.func
}

export default AddEntryForm
