import React from 'react'
import PropTypes from 'prop-types'
import { Grid, makeStyles } from '@material-ui/core'
import Skeleton from '../../../../../atoms/Skeleton'
import { SKELETON_VARIANTS } from '../../../../../../constants'

const useStyles = makeStyles(() => ({
  item: {
    borderRadius: '0.75rem !important',
    border: '2px solid #E9EAEF',
    background: '#FFF',
    boxShadow: 'none',
    padding: '0.75rem',
    display: 'flex',
    flexDirection: 'row',
    gap: '1rem',
    width: '100%'
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.5rem',
    width: '100%'
  }
}))

const CardTaskSkeleton = ({ length }) => {
  const classes = useStyles()
  return (
    <Grid container spacing={2}>
      {Array(length)
        .fill(0)
        .map((_, index) => {
          return (
            <Grid key={index} item xs={12} md={6} lg={4}>
              <div className={classes.item}>
                <Skeleton
                  variant={SKELETON_VARIANTS.circle}
                  width='4.5rem'
                  height='4rem'
                />
                <div className={classes.content}>
                  <Skeleton variant={SKELETON_VARIANTS.rect} height='1.5rem' />
                  <Skeleton
                    variant={SKELETON_VARIANTS.rect}
                    height='1.5rem'
                    width='80%'
                  />
                </div>
              </div>
            </Grid>
          )
        })}
    </Grid>
  )
}

CardTaskSkeleton.propTypes = {
  length: PropTypes.number
}

CardTaskSkeleton.defaultProps = {
  length: 9
}

export default CardTaskSkeleton
