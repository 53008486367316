import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import { mapThemingColor } from '../organisms/WealthJourney/shared/mapThemingColor'
import MilestoneIcon from './MilestoneIcon'

export const useStyles = makeStyles((theme) => ({
  container: ({ disabled, selectable }) => ({
    opacity: disabled ? 0.5 : 0.75,
    position: 'relative',
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'center',
    justifyContent: 'flex-start',
    fontSize: '1rem',
    width: 'auto',
    maxWidth: '230px',
    minWidth: '210px',
    height: '280px',
    background: theme.palette.baseBackground.main,
    borderRadius: '8px',
    margin: '24px 24px',
    padding: '24px 24px 12px 24px',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: theme.palette.baseBackground.main,
    boxSizing: 'border-box',
    '&:hover': selectable ? {
      borderColor: disabled ? theme.palette.baseBackground.main : theme.palette.indigoBlue,
      cursor: disabled ? 'initial' : 'pointer'
    } : undefined
  }),
  containerSelected: {
    opacity: '1 !important',
    boxShadow: '4px 4px 18px 6px rgb(0, 0, 0, 0.2) !important',
    borderColor: `${theme.palette.white} !important`,
    background: `${theme.palette.white} !important`
  },
  mileStoneTitle: {
    fontSize: '1rem',
    fontWeight: 700,
    textAlign: 'center',
    height: '7rem',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  mileStoneTarget: {
    fontSize: '0.75rem',
    textAlign: 'center',
    position: 'absolute',
    top: 'calc(100% - 2.5rem)'
  },
  icon: {
    width: '5.5rem',
    height: '5.5rem',
    fontSize: '2rem',
    marginTop: '0.5rem',
    color: ({ theming }) => mapThemingColor(theming?.milestone?.iconColor, theme, theme.palette.summitBlue),
    backgroundColor: theme.palette.baseBackground.avatar
  },
  iconSelected: {
    background: ({ theming }) => mapThemingColor(theming?.milestone?.iconBackgroundColor, theme, theme.palette.cloudBurst),
    color: theme.palette.white,
    outlineColor: ({ theming }) => mapThemingColor(theming?.milestone?.iconBackgroundColor, theme, theme.palette.cloudBurst),
    outlineWidth: '0rem',
    outlineStyle: 'solid'
  }
})
)

const WealthJourneyMilestoneCard = ({
  milestone,
  onSelect,
  selectable,
  selected,
  disabled,
  theming
}) => {
  const classes = useStyles({ disabled, selectable, theming })
  const targetDate = useMemo(() => dayjs.utc(milestone.entryDate).format('MMM YYYY'), [milestone.entryDate])
  const isSelected = useMemo(() => selected?.entryId === milestone.entryId, [selected, milestone])
  const onClick = useCallback(() => !isSelected && onSelect(milestone), [milestone, onSelect, isSelected])

  const iconClasses = useMemo(() => clsx(classes.icon, {
    [classes.iconSelected]: isSelected
  }), [classes.icon, classes.iconSelected, isSelected])
  const frameClasses = useMemo(() => clsx(classes.container, {
    [classes.containerSelected]: isSelected
  }), [classes.container, classes.containerSelected, isSelected])

  const callbackRef = useCallback(inputElement => {
    if (inputElement && isSelected) {
      inputElement.focus()
    }
  }, [isSelected])

  return (
    <div className={frameClasses} onClick={onClick} ref={callbackRef}>
      <MilestoneIcon className={iconClasses} milestone={milestone} />
      <div className={classes.mileStoneTitle}>
        {milestone.entryJson.title}
      </div>
      <div className={classes.mileStoneTarget}>
        Target: {targetDate}
      </div>
    </div>
  )
}

WealthJourneyMilestoneCard.propTypes = {
  /** The milestone to represent */
  milestone: PropTypes.object.isRequired,

  /** Callback when the card is selected / clicked on */
  onSelect: PropTypes.func.isRequired,

  /** The selected milestone, the card appears selected */
  selected: PropTypes.object,

  /** Determines if the card is selectable */
  selectable: PropTypes.bool,
  disabled: PropTypes.bool,
  theming: PropTypes.shape({
    milestone: PropTypes.shape({
      iconColor: PropTypes.string,
      iconBackgroundColor: PropTypes.string
    })
  })
}

WealthJourneyMilestoneCard.defaultProps = {
  onSelect: () => {},
  equalitySelector: (provided, active) => {
    if (!provided) return false
    if (!active) return false
    return provided.entryId === active.entryId
  },
  selected: null,
  disabled: false,
  selectable: true
}

export default WealthJourneyMilestoneCard
