import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import NumberFormat from '../../../../atoms/NumberFormat'
import { numeralByCase } from '../../../../../utils'
import { useAppContext } from '../../../../../redux/slices/appContext'
import { useCoreDataDataset } from '../../../../../api/coreData'
import ClientMetricContainer from './ClientMetricContainer'

const ClientMarketValue = ({ title }) => {
  const { clientId, availableDates } = useAppContext()
  const coreDataQuery = useMemo(() => ({
    levelFilters: {
      calcType: 'balance',
      dateType: 'all',
      clientIds: [clientId],
      levelTypes: ['client'],
      V2: true
    },
    dateRange: {
      startDate: availableDates.min,
      endDate: availableDates.max
    }
  }), [clientId, availableDates.min, availableDates.max])
  const { data, isLoading, error } = useCoreDataDataset(coreDataQuery)
  const marketValue = useMemo(() => {
    if (isLoading || error) return null

    return data.length
      ? data[0].length
        ? data[0][0].endingValue
        : null
      : null
  }, [isLoading, error, data])
  if (error) return null

  return (
    <ClientMetricContainer title={title} isLoading={isLoading}>
      <NumberFormat
        title={numeralByCase(marketValue)}
        number={marketValue}
        format='($0,0a)'
      />
    </ClientMetricContainer>
  )
}

ClientMarketValue.propTypes = {
  title: PropTypes.string
}

ClientMarketValue.defaultProps = {
  title: 'Market Value'
}

export default ClientMarketValue
