import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import Tooltip from '../../../atoms/Tooltip'

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '0.75rem 1rem',
    '&:hover': {
      background: '#F5F6F7',
      borderRadius: '0.25rem'
    }
  }
}))

const AdvisorHomeItem = ({ children, tooltipTitle }) => {
  const classes = useStyles()
  return (
    <Tooltip title={tooltipTitle}>
      <div className={classes.container}>{children}</div>
    </Tooltip>
  )
}

AdvisorHomeItem.propTypes = {
  children: PropTypes.node,
  tooltipTitle: PropTypes.string
}

export default AdvisorHomeItem
