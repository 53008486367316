import React from 'react'
import PropTypes from 'prop-types'
import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import loadingImage from '../../assets/summitLoad.gif'

const useStyles = makeStyles(() => ({
  loadingView: ({ width, height, styles }) => ({
    display: 'flex',
    width: width || '100vw',
    height: height || '100vh',
    justifyContent: 'center',
    alignItems: 'center',
    ...(styles || {})
  })
}))

function LoadingView ({ width, height, styles }) {
  const classes = useStyles({ width, height, styles })

  return (
    <Box xs={12} className={classes.loadingView}>
      <img src={loadingImage} alt='Loading...' />
    </Box>
  )
}

LoadingView.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  styles: PropTypes.object
}

LoadingView.defaultProps = {
  width: undefined,
  height: undefined,
  styles: undefined
}

export default LoadingView
