import React from 'react'
import PropTypes from 'prop-types'
import { Link } from '@material-ui/core'
import { useHistory } from 'react-router-dom'

const ViewPathCell = ({ row }) => {
  const history = useHistory()

  const onClickLink = (e) => {
    e.preventDefault()
    history.push(`/views/home/${row?.original?.path}`)
  }

  return (
    <Link href='#' onClick={onClickLink}>
      {row?.original?.path}
    </Link>
  )
}

ViewPathCell.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      path: PropTypes.string
    })
  })
}

export default ViewPathCell
