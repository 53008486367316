import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core'
import InsertEmoticonIcon from '@material-ui/icons/InsertEmoticon'
import noop from 'lodash/noop'
import isObject from 'lodash/isObject'
import Tooltip from '@material-ui/core/Tooltip'
import { emojiIndex } from 'emoji-mart'
import { useOktaAuth } from '@okta/okta-react'
import isEmpty from 'lodash/isEmpty'
import { useToggle, useAuthState } from '../../hooks'
import { EMOJI_PICKER_THEMES } from '../../constants'
import { localStorageHelper } from '../../utils/localStorageHelper'
import {
  createReplyReaction,
  createThreadReaction,
  deleteReplyReaction,
  deleteThreadReaction
} from '../../service'
import { useAppContext } from '../../redux/slices/appContext'
import { noteReactions } from '../../prop-types'
import EmojiPicker from './EmojiPicker'

const useStyles = makeStyles((theme) => ({
  container: {
    right: '3rem',
    width: 'auto',
    top: '0rem',
    height: 'auto',
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    borderRadius: '0.5rem',
    padding: '0rem',
    border: '0px solid rgba(255, 0, 0, 0.12)',
    backgroundColor: theme.palette.white,
    '& svg': {
      fontSize: '1.25rem',
      cursor: 'pointer'
    }
  },
  emojiIcon: {
    fontSize: '1rem',
    lineHeight: '1px',
    width: '1.5rem',
    height: '1.5rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '0.35rem',
    cursor: 'pointer',
    '&:hover': {
      color: '#E0C06A'
    }
  },
  emojiIconContainer: {
    display: 'flex',
    '&:svg': {
      fontSize: '1.25rem'
    }
  },
  emojiIconDisabled: {
    opacity: '0.75',
    cursor: 'not-allowed !important',
    '& > svg': {
      cursor: 'not-allowed !important'
    }
  }
}))

const NoteReactionsMenuSection = ({
  isReply,
  replyId,
  threadId,
  reactions,
  onAddReaction,
  onRemoveReaction,
  disableReactionAddition
}) => {
  const classes = useStyles()
  const appContext = useAppContext()
  const { authState: oktaAuthState } = useOktaAuth()
  const authState = useAuthState(oktaAuthState)
  const [emojiPickerOpen, toggleEmojiPicker, , toggleEmojiPickerOff] = useToggle()
  const [topFavoriteEmojis, setTopFavoriteEmojis] = useState([])
  const [isLoading, , toggleIsLoadingOn, toggleIsLoadingOff] = useToggle()

  useEffect(() => {
    async function getTopFavoriteEmojis () {
      const emojisFrequentlyUsed = localStorageHelper.load('emoji-mart.frequently')
      if (emojisFrequentlyUsed) {
        const topEmojisFrequentlyUsed = Object
          .fromEntries(Object.entries(emojisFrequentlyUsed).sort((a, b) => b[1] - a[1]).slice(0, 3))

        const topEmojis = Object
          .keys(topEmojisFrequentlyUsed)
          .reduce((acc, emojiKey) => [
            ...acc,
            ...emojiIndex.search(emojiKey).map(({ id, native, colons, name }) => ({ id, native, colons, name }))
          ], [])

        setTopFavoriteEmojis(topEmojis.slice(0, 3))
      }
    }

    getTopFavoriteEmojis()
  }, [])

  const onReactionClick = useCallback(
    async (reaction = []) => {
      try {
        toggleIsLoadingOn()
        toggleEmojiPickerOff()
        const reactedBy = reactions.find(({ reaction: reacted, createdBy, createdByName }) =>
          appContext.userId === createdBy &&
          reaction.native === reacted &&
          createdByName === (authState.idToken?.claims?.name || ''))

        if (reactedBy) {
          const { reactionId } = reactedBy
          if (isReply && replyId) {
            onRemoveReaction({ replyId, reactionId })
            await deleteReplyReaction(replyId, reactionId)
          } else if (threadId) {
            onRemoveReaction({ threadId, reactionId })
            await deleteThreadReaction(threadId, reactionId)
          }
        } else if (isEmpty(reactions.find(({ reaction: _reaction, createdBy }) => {
          return _reaction === reaction && appContext.userId === createdBy
        }))) {
          const reactionNative = isObject(reaction) ? reaction.native : reaction
          const reactionBody = {
            reaction: reactionNative,
            clientId: appContext.clientId,
            createdBy: appContext.userId,
            createdByName: authState.idToken?.claims?.name || ''
          }
          if (isReply && replyId) {
            const { data: replyReaction } = await createReplyReaction(replyId, reactionBody)
            onAddReaction({ replyId, reaction: replyReaction })
          } else if (threadId) {
            const { data: threadReaction } = await createThreadReaction(threadId, reactionBody)
            onAddReaction({ threadId, reaction: threadReaction })
          }
        }
      } catch (err) {
        console.error(err)
      } finally {
        toggleIsLoadingOff()
      }
    },
    [
      isReply,
      replyId,
      threadId,
      reactions,
      authState,
      onAddReaction,
      onRemoveReaction,
      toggleIsLoadingOn,
      toggleIsLoadingOff,
      appContext.userId,
      appContext.clientId,
      toggleEmojiPickerOff
    ]
  )

  return (
    <div className={classes.container}>
      {topFavoriteEmojis.map(emoji => (
        <Tooltip key={emoji.name} title={emoji.name} placement='top'>
          <span
            className={clsx(classes.emojiIcon, {
              [classes.emojiIconDisabled]: disableReactionAddition
            })}
            onClick={() => disableReactionAddition || isLoading ? noop : onReactionClick(emoji.native)}
          >
            {emoji.native}
          </span>
        </Tooltip>
      ))}
      <EmojiPicker
        open={emojiPickerOpen}
        toggle={disableReactionAddition ? noop : toggleEmojiPicker}
        theme={EMOJI_PICKER_THEMES.light}
        onSelect={onReactionClick}
        childrenContainerClassName={classes.emojiIconContainer}
      >
        <Tooltip title='Find another reaction' placement='top'>
          <span className={clsx(classes.emojiIcon, {
            [classes.emojiIconDisabled]: disableReactionAddition
          })}
          >
            <InsertEmoticonIcon />
          </span>
        </Tooltip>
      </EmojiPicker>
    </div>
  )
}

NoteReactionsMenuSection.propTypes = {
  isReply: PropTypes.bool.isRequired,
  replyId: PropTypes.number,
  threadId: PropTypes.number,
  reactions: noteReactions,
  onAddReaction: PropTypes.func,
  onRemoveReaction: PropTypes.func,
  disableReactionAddition: PropTypes.bool
}

NoteReactionsMenuSection.defaultProps = {
  replyId: undefined,
  threadId: undefined,
  reactions: [],
  onAddReaction: noop,
  onRemoveReaction: noop,
  disableReactionAddition: false
}

export default NoteReactionsMenuSection
