import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import SelectWithCheckbox from '../../../../molecules/Select/SelectWithCheckbox'
import SearchBar from '../../../../molecules/SearchBar'
import useDebounce from '../../hooks/useDebounce'

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column'
  },
  search: {
    display: 'flex',
    width: '20rem',
    paddingBottom: '1rem'
  },
  filters: {
    display: 'flex',
    flexDirection: 'row',
    gap: '.5rem'
  },
  searchInput: {
    marginBottom: '0.5rem'
  }
})

const TradeResultFilter = (props) => {
  const {
    label,
    selectedOptions,
    options,
    onChange
  } = props

  return (
    <SelectWithCheckbox
      placeholder={label}
      prefixLabel={label}
      options={options}
      onChange={onChange}
      selectedOptions={selectedOptions}
    />
  )
}

TradeResultFilter.propTypes = {
  label: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  selectedOptions: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired
}

const TradeResultsFilter = (props) => {
  const classes = useStyles(props)
  const {
    onChangeFilters,
    getSelectedFilters,
    options,
    onChangeSearch,
    onClearSearch
  } = props

  const _getSelectedFilters = useCallback((type) => {
    const typeOptions = options.find(o => o.key === type)
    return (getSelectedFilters(type) ?? [])
      .map(f => typeOptions.values.find(v => v.value === f))
  }, [getSelectedFilters, options])

  const _onChangeSearch = useDebounce(onChangeSearch)

  return (
    <div className={classes.container}>
      <div className={classes.search}>
        <SearchBar
          className={classes.searchInput}
          onChange={_onChangeSearch}
          onClear={onClearSearch}
          placeholder='Search by Account or Asset'
        />
      </div>
      <div className={classes.filters}>
        {options.map(opt => (
          <TradeResultFilter
            key={opt.key}
            label={opt.label}
            options={opt.values}
            selectedOptions={_getSelectedFilters(opt.key)}
            onChange={onChangeFilters(opt.key)}
          />
        ))}
      </div>
    </div>
  )
}

TradeResultsFilter.propTypes = {
  getSelectedFilters: PropTypes.func,
  onChangeFilters: PropTypes.func,
  options: PropTypes.arrayOf({
    key: PropTypes.string,
    label: PropTypes.string,
    values: PropTypes.arrayOf({
      label: PropTypes.string,
      value: PropTypes.any
    })
  }),
  onChangeSearch: PropTypes.func,
  onClearSearch: PropTypes.func
}

export default TradeResultsFilter
