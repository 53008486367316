import React from 'react'
import PropTypes from 'prop-types'
import { Box, useTheme } from '@material-ui/core'
import { ICON_NAMES } from '../../constants'
import Text from './Text'
import Icon from './Icon'
import NumberFormat from './NumberFormat'

const AssetReturnCell = ({
  tooltip,
  showArrow = true,
  balanceChangePositive,
  balanceChangePercentage
}) => {
  const theme = useTheme()
  return (
    <Box
      display='flex'
      flexDirection='row'
      alignItems='center'
      justifyContent='start'
    >
      <Text
        customFontWeight='bold'
        text={
          <NumberFormat
            title={tooltip.title}
            format={tooltip.format}
            number={balanceChangePercentage}
          />
        }
      />
      {showArrow && (
        <Box ml='0.5rem' display='flex' alignItems='center' justifyContent='center'>
          <Icon
            customSize='1rem'
            color={
              balanceChangePositive
                ? theme.palette.success.A500
                : theme.palette.error.A500
            }
            name={balanceChangePositive ? ICON_NAMES.up : ICON_NAMES.down}
          />
        </Box>
      )}
    </Box>
  )
}

AssetReturnCell.propTypes = {
  tooltip: PropTypes.shape({
    title: PropTypes.string,
    format: PropTypes.string
  }),
  showArrow: PropTypes.bool,
  balanceChangePositive: PropTypes.bool,
  balanceChangePercentage: PropTypes.number
}

export default React.memo(AssetReturnCell)
