import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { useParams } from 'react-router-dom'
import PageContainer from '../components/PageContainer'
import ConversationList from './ConversationList'
import ConversationThread from './ConversationThread'

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    width: 'calc(100vw - 2rem)'
  }
}))

const ConversationDashboard = ({ isNew }) => {
  const { conversationKey, clientId } = useParams()
  const classes = useStyles()

  return (
    <PageContainer>
      <div className={classes.container}>
        <ConversationList clientId={+clientId} selectedConversation={conversationKey} isNew={isNew} />
        <ConversationThread clientId={+clientId} selectedConversation={conversationKey} isNew={isNew} />
      </div>
    </PageContainer>
  )
}

ConversationDashboard.propTypes = {
  isNew: PropTypes.bool
}

ConversationDashboard.defaultProps = {
  isNew: false
}

export default ConversationDashboard
