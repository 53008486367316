import React, { useMemo } from 'react'
import { deepCopyWithoutOverrideSource } from '../utils'

const mergePropsWithDefaultProps = (Component, defaultProps) => (props) => {
  const propsMergedWithDefaults = useMemo(() => {
    return deepCopyWithoutOverrideSource(defaultProps, props)

    /*
     * We only merge defaultProps with props the first time
     * any further update shouldn't be intercepted
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return <Component {...propsMergedWithDefaults} />
}

export default mergePropsWithDefaultProps
