import { makeStyles } from '@material-ui/core'
import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { noop } from 'lodash'
import { ICON_NAMES } from '../../../constants'
import Icon from '../../atoms/Icon'

const useStyles = makeStyles(() => ({
  container: {
    flex: 1
  },
  content: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  item: {
    flex: 1,
    textAlign: 'center',
    padding: '0.875rem 2rem',
    background: '#F1F2F4',
    border: '3px solid #D7DCE1',
    fontSize: '1.125rem',
    fontWeight: 'bold',
    cursor: 'pointer'
  },
  selected: {
    background: '#FFF',
    border: '3px solid #212945 !important',
    transition: 'all 300ms ease-in-out'
  },
  disabled: {
    opacity: '0.5'
  },
  icon: {
    marginRight: '0.625rem'
  },
  footnote: {
    display: 'flex',
    marginTop: '0.625rem'
  }
}))

const BlockButtonItem = ({ children, isSelected, onSelect, footnote, disabled }) => {
  const classes = useStyles()
  return (
    <div className={classes.container}>
      <div
        className={clsx('_item', classes.item, {
          [classes.selected]: isSelected,
          [classes.disabled]: disabled
        })}
        onClick={disabled ? noop : onSelect}
      >
        <div className={classes.content}>
          {isSelected && (
            <Icon
              name={ICON_NAMES.checkCircle}
              customSize='1.25rem'
              additionalClasses={classes.icon}
            />
          )}
          {children}
        </div>
      </div>
      {footnote && (
        <span className={classes.footnote}>
          {footnote}
        </span>
      )}
    </div>
  )
}

BlockButtonItem.propTypes = {
  children: PropTypes.node,
  isSelected: PropTypes.bool,
  onSelect: PropTypes.func,
  disabled: PropTypes.bool,
  footnote: PropTypes.oneOfType([PropTypes.node, PropTypes.string])
}

export default BlockButtonItem
