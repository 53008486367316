import React, { useMemo, useRef } from 'react'
import { Divider, Grid, makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import SectionScreen from '../SectionScreen'
import SectionHeader from '../../shared/SectionHeader'
import FadeIn from '../../../../molecules/Transitions/FadeIn'
import { useGetUserSsoAuthorizations, useListSsoProviders } from '../../../../../api/users/sso'
import Loading from '../../../../molecules/Loading'
import Card from '../../../../molecules/Card'
import { useWealthOwnerDetails } from '../ClientDetailsFormContext'
import Tooltip from '../../../../atoms/Tooltip'
import Icon from '../../../../atoms/Icon'
import SsoValueDisplay from '../../shared/Integrations/SsoValueDisplay'
import EditSsoAssignmentDialog from './EditSsoAssignmentDialog'
import SsoProviderActions from './SsoProviderActions'
import ViewUserActivityDialog from './ViewUserActivityDialog'

const useStyles = makeStyles((theme) => ({
  status: {
    display: 'flex',
    gap: theme.layout.gap.dense,
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.gray.darker,
    fontStyle: 'italic',
    fontSize: theme.typography.fontSizes.xl,
    '&.__enabled': {
      color: theme.palette.success.main
    }
  },
  header: {
    display: 'flex',
    gap: theme.layout.gap.loose
  },
  content: {
    marginTop: theme.layout.margin.thick
  },
  logoMini: {
    maxHeight: '50px'
  },
  divider: {
    marginTop: theme.layout.margin.thick
  },
  attributeTitle: {
    backgroundColor: theme.palette.gray.main,
    padding: theme.layout.padding.medium,
    fontSize: theme.typography.fontSizes.lg,
    '& .__attribute-title-container': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      gap: theme.layout.gap.dense
    },
    minWidth: '200px'
  },
  attributeValue: {
    minWidth: '300px',
    textAlign: 'right',
    padding: theme.layout.padding.medium
  },
  valueTable: {
    border: `1px solid ${theme.palette.gray.main}`,
    width: '100%'
  }
}))

function OutboundSsoSection () {
  const classes = useStyles()
  const editRef = useRef(null)
  const viewActivityRef = useRef(null)
  const { wealthOwner } = useWealthOwnerDetails()
  const { data: providerResponse, isLoading: providersLoading } = useListSsoProviders()
  const { data: authResponse, isFetching: authLoading } = useGetUserSsoAuthorizations(wealthOwner.userId)
  const data = useMemo(() => {
    if (!providerResponse || !authResponse) {
      return []
    }
    return providerResponse.providers.map(p => {
      const userAuthorization = authResponse.authorizations.find(z => z.providerId === p.providerId && z.levelTypeId === 1001) ?? null
      const roleAuthorization = authResponse.authorizations.find(z => z.providerId === p.providerId && z.levelTypeId === 1002) ?? null
      const effectiveAuthorization = userAuthorization || roleAuthorization

      return ({
        ...p,
        userAuthorization,
        roleAuthorization,
        authorizedVia: userAuthorization?.enabled ? 'user' : roleAuthorization?.enabled ? 'role' : null,
        attributes: (p.data?.additionalAttributes ?? []).map((cur) => ({
          ...cur,
          value: effectiveAuthorization?.configuration?.[cur.mapTo] || null
        })),
        hasAttributes: p.data?.additionalAttributes?.length > 0
      })
    })
  }, [providerResponse, authResponse])

  if (providersLoading || authLoading) {
    return <Loading />
  }

  return (
    <FadeIn>
      <SectionScreen sectionName='outbound_sso'>
        <SectionHeader text='Single Sign Ons' />
        <Grid container spacing={2}>
          {(data || []).map(provider => (
            <Grid item xs={12} md={6} xl={4} key={provider.providerId}>
              <Card>
                <div className={classes.header}>
                  {provider.logoUrl ? (
                    <img className={classes.logoMini} src={provider.logoUrl} alt={provider.name} />
                  ) : null}
                  <SectionHeader split slim text={provider.name} key={provider.providerId} subText={provider.description}>
                    <div className={clsx(classes.status, { __enabled: provider.authorizedVia })}>
                      <span>{provider.authorizedVia ? 'Enabled' : 'Disabled'}</span>
                      <span>{provider.authorizedVia === 'role' ? 'via Role Assignment' : null}</span>
                    </div>
                    <SsoProviderActions
                      onEdit={() => editRef.current.open(provider)}
                      onViewActivity={() => viewActivityRef.current.open(provider)}
                    />
                  </SectionHeader>
                </div>

                {provider.hasAttributes ? (
                  <>
                    <Divider className={classes.divider} />
                    <div className={classes.content}>
                      <table className={classes.valueTable}>
                        <tbody>
                          {provider.attributes.map(attr => (
                            <tr key={attr.mapTo}>
                              <td className={classes.attributeTitle}>
                                <div className='__attribute-title-container'>
                                  <span>{attr.name}</span>
                                  {attr.description ? (
                                    <Tooltip title={attr.description}>
                                      <span>
                                        <Icon name='info' customSize={20} />
                                      </span>
                                    </Tooltip>
                                  ) : null}
                                </div>
                              </td>
                              <td className={classes.attributeValue}>
                                <SsoValueDisplay type={attr.type} value={attr.value} />
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </>
                ) : null}
              </Card>
            </Grid>
          ))}
        </Grid>
      </SectionScreen>
      <EditSsoAssignmentDialog userId={wealthOwner.userId} ref={editRef} />
      <ViewUserActivityDialog user={wealthOwner} ref={viewActivityRef} />
    </FadeIn>
  )
}

export default OutboundSsoSection
