import keyMirror from 'keymirror'

export const FEE_OVERRIDE_LEVELS = {
  account: {
    label: 'Account',
    value: 'ACCOUNT'
  },
  class: {
    label: 'Asset Classes',
    value: 'ASSET_CLASS'
  },
  position: {
    label: 'Position',
    value: 'POSITION'
  }
}

export const FEE_OVERRIDE_LEVEL_VALUES = Object.entries(FEE_OVERRIDE_LEVELS).reduce(
  (acc, [key, value]) => ({ ...acc, [key]: value.value }),
  {}
)

export const FEE_OVERRIDE_LEVEL_LABELS = Object.entries(FEE_OVERRIDE_LEVELS).reduce(
  (acc, [key, value]) => ({ ...acc, [key]: value.label }),
  {}
)

export const FEE_LEVEL_OVERRIDE_OPTIONS = Object.values(FEE_OVERRIDE_LEVELS).map(
  ({ label, value }) => ({ label, value, rules: { max: 50 } })
)

export const findOverrideOption = (value) => {
  return FEE_LEVEL_OVERRIDE_OPTIONS.find(x => value === x.value)
}

export const FEE_OVERRIDES_FORM_NAMES = keyMirror({
  feeOverrideType: null,
  feeOverrideLevel: null,
  feeScheduleOverride: null
})

export const FEE_OVERRIDE_FORM_NAME = keyMirror({
  feeOverride: null
})

export const getFeeOverrideCounters = (feeOverrides) => {
  return feeOverrides.reduce((acc, { feeOverrideType }) => {
    return feeOverrideType
      ? {
        ...acc,
        [feeOverrideType]: (acc[feeOverrideType] || 0) + 1
      }
      : acc
  }, {})
}

export const mapAndValidateLevelOverrideOptions = (feeOverrides, levelOptions) => {
  const overrideCounters = getFeeOverrideCounters(feeOverrides)
  const { levelOverrideOptions } = levelOptions.reduce(
    (acc, levelOption) => {
      const { value: levelValue, rules: levelRules } = levelOption
      const overrideCount = acc.overrideCounters[levelValue] || 0
      const levelOverrideRules = levelRules

      if (overrideCount >= levelOverrideRules.max) {
        acc.levelOverrideOptions.push({
          ...levelOption,
          disabled: true
        })
      } else {
        acc.levelOverrideOptions.push(levelOption)
      }
      return acc
    },
    { levelOverrideOptions: [], overrideCounters }
  )
  return levelOverrideOptions
}

const mapErrors = (errors) => {
  return Object.entries(errors).map(([key, value]) => ({
    name: key,
    message: value
  }))
}

export const mapFeeOverrides = (feeOverrides) =>
  feeOverrides.map(
    ({ feeOverrideType, feeOverrideLevel, feeScheduleOverride }) => ({
      level: feeOverrideType,
      levelId: feeOverrideLevel,
      feeScheduleId: feeScheduleOverride
    })
  )

export const filterRemovedFeeOverrides = (sourceFeeOverrides, targetFeeOverrides) => {
  const mapId = ({ feeLevelOverrideId }) => feeLevelOverrideId
  const sourceFeeOverrideIds = sourceFeeOverrides.map(mapId)
  const targetFeeOverridesIds = targetFeeOverrides.map(mapId)
  const deletedFeeOverrideIds = sourceFeeOverrideIds.filter(
    feeOverrideId => !targetFeeOverridesIds.includes(feeOverrideId)
  )
  return deletedFeeOverrideIds
}

export const shouldSetFeeOverrideError = (
  name,
  value,
  values,
  levelName = '',
  fieldIndex = null
) => {
  const { feeOverride: feeOverrideName } = FEE_OVERRIDE_FORM_NAME

  const { feeOverrideType, feeOverrideLevel, feeScheduleOverride } =
    FEE_OVERRIDES_FORM_NAMES

  const feeOverridePrefix = `${feeOverrideName}.${fieldIndex}`
  const feeOverrideNames = [
    `${feeOverridePrefix}.${feeOverrideType}`,
    `${feeOverridePrefix}.${feeOverrideLevel}`,
    `${feeOverridePrefix}.${feeScheduleOverride}`
  ]

  const errors = feeOverrideNames.reduce(
    (acc, overrideName) => ({
      ...acc,
      [overrideName]: null
    }),
    {}
  )

  if (!value) return mapErrors(errors)

  if (fieldIndex !== null) {
    const [
      feeOverrideTypeName,
      feeOverrideLevelName,
      feeScheduleOverrideName
    ] = feeOverrideNames

    const isFeeOverrideType = feeOverrideTypeName === name
    const isFeeOverrideLevel = feeOverrideLevelName === name
    const isFeeScheduleOverride = feeScheduleOverrideName === name

    const feeOverride = values.feeOverride[fieldIndex]

    if (isFeeOverrideType && !feeOverride.feeOverrideLevel) {
      errors[feeOverrideLevelName] = `Fee ${levelName} override is required`
    }
    if (isFeeOverrideType && !feeOverride.feeScheduleOverride) {
      errors[feeScheduleOverrideName] = 'Fee schedule override is required'
    }
    if (isFeeOverrideLevel && !feeOverride.feeScheduleOverride) {
      errors[feeScheduleOverrideName] = 'Fee schedule override is required'
    }
    if (isFeeScheduleOverride && !feeOverride.feeOverrideLevel) {
      errors[feeOverrideLevelName] = `Fee ${levelName} override is required`
    }
  }
  return mapErrors(errors)
}
