import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import PropTypes, { bool } from 'prop-types'
import { Box } from '@material-ui/core'
import { useDynamicPlanning } from '../DynamicPlanningContext'
import Skeleton from '../../../atoms/Skeleton'
import Text from '../../../atoms/Text'
import { TEXT_VARIANTS } from '../../../../constants'
import Tag from '../../../atoms/Tag'
import { pluralize } from '../../../../utils'
import theme from '../../../../theme'
import { runSimulation } from '../PlanLineChart/utils'
import CategorySuccessChart from './CategorySuccessChart'

const GoalCategoryOverviewItem = ({ goalCategory, showSuccessChart = true }) => {
  const {
    currentPlan,
    goalCategories,
    clientBalance,
    displayDates,
    isLoadingData
  } = useDynamicPlanning()
  const [monteCarloData, setMonteCarloData] = useState(null)
  const [isProcessing, setIsProcessing] = useState(false)

  const categoryGoals = useMemo(() => (
    currentPlan?.goals?.filter((goal) => goal.goalCategoryId === goalCategory.categoryId) ?? []
  ), [goalCategory.categoryId, currentPlan])

  const goalSummary = useMemo(() => {
    const goalNames = categoryGoals.map(goal => goal.shortName)
    if (!goalNames.length) {
      return 'No goals for this category yet'
    }
    if (goalNames.length <= 1) {
      return goalNames.join(',')
    }
    if (goalNames.length === 2) {
      return goalNames.join(' and ')
    }
    return goalNames.slice(0, -1).join(', ') + ', and ' + goalNames.slice(-1)
  }, [categoryGoals])

  const calcParams = useMemo(() => {
    if (!currentPlan?.assumptions || isLoadingData) {
      return null
    }

    const previousCategoryIds = goalCategories?.filter(category => category.ordinal <= goalCategory.ordinal).map(category => category.categoryId)
    const activeGoals = currentPlan.goals?.filter((goal) => previousCategoryIds.includes(goal.goalCategoryId))

    return {
      clientBalance, currentPlan, activeGoals, displayDates
    }
  }, [currentPlan, isLoadingData, goalCategories, clientBalance, displayDates, goalCategory.ordinal])

  const updateMonteCarlo = useCallback(async (calcParams) => {
    if (!calcParams) {
      return
    }
    setMonteCarloData(await runSimulation(calcParams))
  }, [])

  const timerRef = useRef(0)
  const debouncedData = useCallback((calcParams) => {
    clearTimeout(timerRef.current)
    setIsProcessing(true)
    timerRef.current = setTimeout(async () => {
      if (!calcParams) return
      await updateMonteCarlo(calcParams)
      setIsProcessing(false)
    }, 500)
  }, [updateMonteCarlo])

  useEffect(() => {
    if (!calcParams) {
      setMonteCarloData(null)
    } else {
      debouncedData(calcParams)
    }
  }, [calcParams, debouncedData])

  const successRange = useMemo(() => {
    if (!monteCarloData?.successRate) {
      return {
        min: 0,
        max: 0
      }
    }
    return {
      min: monteCarloData.successRate,
      max: Math.min(monteCarloData.successRate * 1.1, 1)
    }
  }, [monteCarloData?.successRate])

  if (isLoadingData) {
    return (
      <>
        <Skeleton height='200px' />
      </>
    )
  }

  return (
    <Box display='flex' flex='1 0 0' height='100%' gridGap='2rem' alignItems='center' padding='1.5rem 2rem'>
      <Box>
        {showSuccessChart && (
          <CategorySuccessChart
            range={successRange}
            color={goalCategory.primaryColor}
            isUpdating={isProcessing}
          />
        )}
      </Box>
      <Box flex='1 0 0' display='flex' flexDirection='column' height='100%'>
        <Box flex='1 0 0'>
          <Text variant={TEXT_VARIANTS.h2} text={goalCategory.shortName} />
          <Box margin='.5rem 0' fontStyle={!categoryGoals?.length ? 'italic' : 'inherit'}>
            <Text variant={TEXT_VARIANTS.body} text={goalSummary} />
          </Box>
        </Box>
        <Tag
          backgroundColor={theme.palette.lightGrey}
          color={theme.palette.lightBlack}
          borderRadius='10px'
          padding='.125rem 1rem'
          label={categoryGoals.length + ' ' + pluralize(categoryGoals.length, 'Goal', 's')}
        />
      </Box>
    </Box>
  )
}

GoalCategoryOverviewItem.propTypes = {
  goalCategory: PropTypes.objectOf({
    categoryId: PropTypes.number,
    shortName: PropTypes.string,
    primaryColor: PropTypes.string,
    displayCreateButton: PropTypes.bool
  }),
  showSuccessChart: bool
}

export default GoalCategoryOverviewItem
