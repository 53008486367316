import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Grid, useTheme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import TableSkeleton from '../../../atoms/TableSkeleton'
import WealthJourneyCategory from '../../../molecules/WealthJourneyCategoryV2'
import WealthJourneyYearSelector from '../../../molecules/WealthJourneyYearSelector'
import entryType, { entryTypePropType } from './entryType'

const useStyles = () => {
  const theme = useTheme()
  const styles = makeStyles(() => ({
    containerSkeleton: {
      height: '24rem',
      width: '100%',
      border: `2px solid ${theme.palette.grayDone}`,
      borderRadius: '0.75rem',
      padding: '1.5rem',
      display: 'flex',
      flexDirection: 'column'
    },
    highlightArea: {
      padding: '1.5rem'
    }
  }))()

  return [styles, theme]
}

const WealthJourneyActivityContent = ({
  selectedEntryType,
  categoriesLoading,
  categories,
  entries,
  entriesLoading,
  onAdd,
  onEdit,
  onDelete,
  yearSelection
}) => {
  const [classes] = useStyles()
  const loading = useMemo(() => entriesLoading || categoriesLoading, [entriesLoading, categoriesLoading])
  if (selectedEntryType !== entryType.ACTIVITIES) return null

  return (
    <Grid container>
      <WealthJourneyYearSelector {...yearSelection} />
      {loading
        ? [...Array(4)].map((_, i) => (
          <Grid key={i} className={classes.highlightArea} xs={6} item>
            <div className={classes.containerSkeleton}>
              <TableSkeleton columns={1} rows={5} />
            </div>
          </Grid>
        ))
        : categories.map(({ categoryId: id, categoryName: name }) => {
          return (
            <Grid key={id} className={classes.highlightArea} xs={6} item>
              <WealthJourneyCategory
                title={name}
                categoryId={id}
                entryDateYear={yearSelection.selectedYear.value}
                isLoading={loading}
                entries={entries}
                onAdd={onAdd}
                onEdit={onEdit}
                onDelete={onDelete}
              />
            </Grid>
          )
        })}
    </Grid>
  )
}

WealthJourneyActivityContent.propTypes = {
  selectedEntryType: entryTypePropType.isRequired,
  categoriesLoading: PropTypes.bool.isRequired,
  categories: PropTypes.array,
  entries: PropTypes.array,
  entriesLoading: PropTypes.bool.isRequired,
  onAdd: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  yearSelection: PropTypes.shape({
    onYearSelected: PropTypes.func.isRequired,
    selectedYear: PropTypes.object,
    onReachNavigationEdge: PropTypes.func.isRequired,
    yearTabs: PropTypes.array
  }).isRequired
}

export default React.memo(WealthJourneyActivityContent)
