import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import SydButton from '../../../../../../commonDesign/Button'
import { useDialogStyles } from '../../common'
import { usePermanentlyDeleteWealthOwner } from '../../../../../../../api/users'

const useSubmitter = (form, onComplete, user) => {
  const { handleSubmit } = form
  const [processing, setProcessing] = useState(false)
  const [error, setError] = useState(null)
  const { mutateAsync: destroy } = usePermanentlyDeleteWealthOwner()
  const onSubmit = useCallback(async (formData) => {
    try {
      setError(null)
      setProcessing(true)
      const result = await destroy(user.userId)
      if (result?.statusCode === 500) {
        throw new Error('Failed to delete user')
      }
      onComplete(result)
    } catch (err) {
      setError(err?.toString())
    } finally {
      setProcessing(false)
    }
  }, [destroy, setProcessing, onComplete, user, setError])

  const submitter = useCallback(async (e) => {
    const onValid = async (form) => {
      await onSubmit(form)
    }
    const onInvalid = (errors) => {
      console.error(errors)
    }

    const handler = handleSubmit(onValid, onInvalid)
    await handler(e)
  }, [handleSubmit, onSubmit])

  return {
    submitter,
    processing,
    error
  }
}

function DeleteUserForm ({ user, onCancel, onComplete }) {
  const classes = useDialogStyles()
  const form = useForm({
    mode: 'onChange',
    defaultValues: {
      userId: user.userId
    }
  })

  const { submitter, processing, error } = useSubmitter(form, onComplete, user)

  return (
    <div className={classes.form}>
      <div>
        <p>Are you sure you want to permanently delete <strong>{user.email}</strong>?</p>
        <p><strong>This action cannot be undone and will allow the user's email to be re-used</strong></p>
      </div>
      <div className={classes.actions}>
        {error ? (<div className={classes.error}>{error}</div>) : null}
        <SydButton disabled={processing} variant='ghost' size='md' onClick={onCancel}>Cancel</SydButton>
        <SydButton
          variant='primary'
          size='md'
          onClick={submitter}
          priority='warning'
          processing={processing}
        >
          Permanently Delete
        </SydButton>
      </div>
    </div>
  )
}

DeleteUserForm.propTypes = {
  user: PropTypes.shape({
    userId: PropTypes.number,
    email: PropTypes.number,
    firstName: PropTypes.string,
    lastName: PropTypes.string
  }),
  onComplete: PropTypes.func,
  onCancel: PropTypes.func
}

export default DeleteUserForm
