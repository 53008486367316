import { makeStyles } from '@material-ui/core'

export const useDialogStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    fontWeight: theme.typography.weights.light,
    '& strong': {
      fontWeight: theme.typography.weights.bold
    },
    '& .__description': {
      fontWeight: theme.typography.weights.light,
      fontStyle: 'italic',
      fontSize: theme.typography.fontSizes.md
    },
    '& .__label': {
      fontWeight: theme.typography.weights.bold,
      fontSize: theme.typography.fontSizes.xl
    },
    '& .__radio': {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center'
    },
    '& .__rowGroup': {
      display: 'flex',
      flexDirection: 'row',
      gap: theme.layout.gap.dense
    }
  },
  group: {
    display: 'flex',
    flexDirection: 'row',
    gap: '10px',
    width: '100%',
    '& > *': {
      flex: '1 0 auto'
    }
  },
  textarea: {
    width: '100%',
    marginBottom: '10px',
    resize: 'none',
    height: '300px'
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  formtings: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.layout.gap.loose,
    '& > *:first-child': {
      flex: '1 0 auto'
    }
  },
  formTable: {
    borderCollapse: 'collapse',
    '& tr th': {
      textAlign: 'left'
    },
    '& tr td': {
      transition: 'background-color .2s ease-in-out',
      padding: theme.layout.padding.dense
    },
    '& tr:hover td': {
      backgroundColor: theme.palette.gray.dark
    },
    '& tr td:last-child': {
      textAlign: 'right'
    }
  },
  checkTable: {
    width: '100%',
    borderCollapse: 'collapse',
    '& tr th': {
      textAlign: 'left'
    },
    '& tr td': {
      transition: 'background-color .2s ease-in-out',
      padding: theme.layout.padding.dense
    },
    '& tr:hover td': {
      backgroundColor: theme.palette.gray.dark,
      cursor: 'pointer'
    },
    '& tr td:nth-child(1)': {
      textAlign: 'center',
      width: '40px',
      height: '30px',
      '& span.MuiButtonBase-root': {
        padding: 0
      }
    },
    '& tr td:nth-child(2)': {
      fontWeight: theme.typography.weights.bold
    }
  },
  list: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: theme.layout.padding.thick,
    paddingBottom: theme.layout.padding.thick
  },
  inputLabel: {
    '& input': {
      color: theme.palette.primary.main
    }
  }
}))
