import React, { useState, useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import clsx from 'clsx'
import isEmpty from 'lodash/isEmpty'
import toNumber from 'lodash/toNumber'
import numeral from 'numeral'
import { makeStyles, darken, Box, useTheme } from '@material-ui/core'
import orderBy from 'lodash/orderBy'
import { useAppContext } from '../../redux/slices/appContext'
import { themeConfiguration } from '../../theme'
import { ICON_NAMES, TEXT_VARIANTS, ORDER } from '../../constants'
import ProgressBar from '../atoms/ProgressBar'
import Text from '../atoms/Text'
import Tuple from '../atoms/Tuple'
import Icon from '../atoms/Icon'
import NumberFormat from '../atoms/NumberFormat'
import PieChart from './PieChart'

const DEFAULT_COLOR = '#cecece'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%'
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    marginBottom: '1.75rem'
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
    width: '100%'
  },
  footer: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '1.75rem'
  },
  switchers: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  switch: {
    cursor: 'pointer',
    marginLeft: '15px'
  },
  progressBar: {
    marginBottom: '1.5rem',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.palette.seaFoam50
    }
  },
  percentage: {
    position: 'relative',
    top: '-36px',
    right: '16px'
  },
  barContainer: {
    display: 'flex',
    width: '100%',
    overflow: 'auto',
    flexDirection: 'column',
    maxHeight: '404px'
  },
  pieContainer: {
    display: 'flex',
    width: '100%'
  },
  legends: {
    paddingLeft: '1rem'
  },
  legend: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    height: '54px',
    marginBottom: '14px',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.palette.seaFoam
    }
  },
  legendColor: {
    width: '4px',
    height: '54px',
    marginRight: '1rem'
  },
  balance: {
    width: '100%',
    display: 'flex',
    flexFlow: 'row wrap',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: '0.75rem'
  },
  emptyStateContainer: {
    textAlign: 'center'
  },
  emptyStateTitle: {
    fontWeight: 'bold'
  },
  contentTitle: {
    marginBottom: '1rem'
  },
  range: {
    marginTop: '0.25rem'
  },
  inactiveIcon: {
    opacity: 0.5
  },
  inactiveLegend: {
    opacity: 0.2
  }
}))

const customTooltipStyle = {
  container: {
    background: 'white',
    color: 'inherit',
    fontSize: 'inherit',
    borderRadius: '2px',
    boxShadow: 'rgb(0 0 0 / 25%) 0px 1px 2px',
    padding: 12
  },
  content: {
    whiteSpace: 'pre',
    display: 'flex',
    alignItems: 'center'
  },
  span: ({ color }) => ({
    display: 'block',
    width: '12px',
    height: '12px',
    background: color,
    marginRight: '7px'
  })
}

const PieProgressBars = ({
  pie,
  title,
  onClick,
  activeId,
  dataOrder,
  showHeader,
  showLegends,
  useDataColors,
  titleAdornment,
  labelsFontSize,
  singleColorOverride,
  progressBarsDataSet,
  orderByOrdinal,
  showTitleAdornment
}) => {
  const theme = useTheme()
  const classes = useStyles()
  const [showPie, setShowPie] = useState(pie)
  const { isPrintView } = useAppContext()

  const getColor = useCallback(
    (color, index) => {
      if (singleColorOverride) { return darken(singleColorOverride, toNumber(`0.${index}`)) }
      if (!isEmpty(color) && color !== '') return color
      return DEFAULT_COLOR
    },

    [singleColorOverride]
  )

  const totalBalance = useMemo(
    () => progressBarsDataSet.reduce((acc, { balance }) => acc + balance, 0),
    [progressBarsDataSet]
  )
  const pieChartData = useMemo(
    () =>
      orderBy(
        progressBarsDataSet.map(
          ({ id, color, title, balance, ordinal }, index) => ({
            id,
            balance,
            ordinal,
            label: title,
            value: Math.abs((balance / totalBalance) * 100),
            color: getColor(color, index)
          })
        ),
        [orderByOrdinal ? 'ordinal' : 'balance'],
        [dataOrder]
      ),
    [progressBarsDataSet, getColor, dataOrder, orderByOrdinal, totalBalance]
  )

  const legends = useMemo(() => {
    if (!showLegends) return null
    return (
      <div className={classes.legends}>
        {pieChartData.map(({ id, label, balance, color }) => (
          <div
            key={`legend-${id}`}
            onClick={() => onClick({ id, label })}
            className={clsx(classes.legend, {
              [classes.inactiveLegend]: activeId && activeId !== id
            })}
          >
            <div
              className={classes.legendColor}
              style={{ backgroundColor: color }}
            >
              &nbsp;
            </div>
            <div className={classes.legendText}>
              <Text
                text={label}
                variant={TEXT_VARIANTS.body1}
                customFontWeight={600}
                customFontSize={labelsFontSize || '0.75rem'}
              />
              <Text
                text={
                  <NumberFormat
                    title={numeral(balance).format('$0,0')}
                    format='$0,0a'
                    number={balance}
                  />
                }
                variant={TEXT_VARIANTS.body1}
                customFontSize={labelsFontSize || '0.75rem'}
                color={theme.palette.doveGray}
              />
            </div>
          </div>
        ))}
      </div>
    )
  }, [
    onClick,
    activeId,
    showLegends,
    pieChartData,
    labelsFontSize,
    theme.palette.doveGray,
    classes.legend,
    classes.legends,
    classes.legendText,
    classes.legendColor,
    classes.inactiveLegend
  ])

  const getTooltipTemplate = useCallback(
    ({
      datum: {
        data: { label, color }
      }
    }) => {
      return (
        <div style={customTooltipStyle.container}>
          <div style={customTooltipStyle.content}>
            <span style={customTooltipStyle.span({ color })} />
            <span>{label}</span>
          </div>
        </div>
      )
    },
    []
  )

  const pieRendered = useMemo(
    () => (
      <div className={classes.pieContainer}>
        <PieChart
          tooltip={getTooltipTemplate}
          animate={false}
          arcLabel={(datum) =>
            datum.value > 10 ? numeral(datum.value / 100).format('0%') : ''}
          data={pieChartData}
          onClick={(datum, event) => {
            const { children } = event.currentTarget.parentElement
            for (const item of children) {
              item.style.opacity = 0.5
            }
            event.currentTarget.style.opacity = 1
            onClick({ id: datum.data.id, label: datum.label })
          }}
          innerRadius={0.01}
          padAngle={1.5}
          width='80%'
          sortByValue={!orderByOrdinal}
          useDataColors={useDataColors}
        />
        {showPie && legends}
      </div>
    ),
    [
      classes.pieContainer,
      pieChartData,
      onClick,
      showPie,
      legends,
      getTooltipTemplate,
      useDataColors,
      orderByOrdinal
    ]
  )

  const progressBarRendered = useMemo(
    () => (
      <div className={classes.barContainer}>
        {progressBarsDataSet.map(
          ({ id, color, title, percentage, balance }) => {
            return (
              <div
                key={`${title}-${percentage}`}
                className={classes.progressBar}
                onClick={() => onClick({ id, label: title })}
              >
                <ProgressBar
                  squared
                  color={singleColorOverride || color || DEFAULT_COLOR}
                  backgroundColor={themeConfiguration.palette.seaFoam50}
                  percentage={percentage}
                />
                <div className={classes.balance}>
                  <Tuple
                    title={title}
                    withDivider={false}
                    customFontSize='1rem'
                    content={
                      <NumberFormat
                        title={numeral(balance).format('$0,0')}
                        format='$0,0a'
                        number={balance}
                      />
                    }
                  />
                  <Text
                    text={numeral(percentage / 100).format('0%')}
                    variant={TEXT_VARIANTS.h3}
                    customFontSize='1rem'
                    className={classes.percentage}
                  />
                </div>
              </div>
            )
          }
        )}
      </div>
    ),
    [
      classes.progressBar,
      classes.balance,
      classes.percentage,
      classes.barContainer,
      progressBarsDataSet,
      onClick,
      singleColorOverride
    ]
  )

  if (isEmpty(progressBarsDataSet)) {
    return (
      <div className={classes.emptyStateContainer}>
        <Text
          text='No allocations'
          customFontWeight='bold'
          variant={TEXT_VARIANTS.h6}
        />
        <Text
          variant={TEXT_VARIANTS.body1}
          textTransform='none'
          text={
            <>
              There is no data to display here yet.
              <br />
              Perhaps you need to connect some
              <br />
              accounts to this client.
            </>
          }
        />
      </div>
    )
  }

  return (
    <div className={classes.container}>
      {showHeader && (
        <div className={classes.header}>
          <Text
            customFontSize='1rem'
            text={title}
            color={theme.palette.cloudBurst}
            variant={TEXT_VARIANTS.subtitle2}
          />
          {showTitleAdornment && titleAdornment && (
            <Box mr='auto' ml='1rem'>
              {titleAdornment}
            </Box>
          )}
          {!isPrintView && (
            <div className={classes.switchers}>
              <div className={classes.switch} onClick={() => setShowPie(false)}>
                <Icon
                  name={ICON_NAMES.alignTextLeft}
                  customSize='1.125rem'
                  padding='0.875rem'
                  additionalClasses={showPie ? classes.inactiveIcon : ''}
                />
              </div>
              <div className={classes.switch} onClick={() => setShowPie(true)}>
                <Icon
                  name={ICON_NAMES.pieChart}
                  customSize='1.125rem'
                  padding='0.875rem'
                  additionalClasses={showPie ? '' : classes.inactiveIcon}
                />
              </div>
            </div>
          )}
        </div>
      )}
      <div className={classes.content}>
        {showPie ? pieRendered : progressBarRendered}
      </div>
    </div>
  )
}

PieProgressBars.propTypes = {
  activeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  labelsFontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  title: PropTypes.string,
  pie: PropTypes.bool,
  showLegends: PropTypes.bool,
  onClick: PropTypes.func,
  progressBarsDataSet: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    title: PropTypes.string,
    balance: PropTypes.number,
    ordinal: PropTypes.number,
    additionalClasses: PropTypes.string,
    animate: PropTypes.bool,
    backgroundColor: PropTypes.string,
    color: PropTypes.string,
    percentage: PropTypes.number.isRequired,
    showPercentage: PropTypes.bool,
    squared: PropTypes.bool,
    thresholdLabel: PropTypes.string,
    thresholds: PropTypes.arrayOf(PropTypes.number)
  })),
  useDataColors: PropTypes.bool,
  singleColorOverride: PropTypes.string,
  dataOrder: PropTypes.oneOf(Object.values(ORDER)),
  orderByOrdinal: PropTypes.bool,
  titleAdornment: PropTypes.any,
  showTitleAdornment: PropTypes.bool,
  showHeader: PropTypes.bool
}

PieProgressBars.defaultProps = {
  activeId: undefined,
  labelsFontSize: undefined,
  title: '',
  pie: false,
  showLegends: true,
  onClick: noop,
  progressBarsDataSet: [],
  useDataColors: true,
  singleColorOverride: undefined,
  dataOrder: ORDER.DESC,
  orderByOrdinal: false,
  titleAdornment: undefined,
  showTitleAdornment: false,
  showHeader: true
}

export default PieProgressBars
