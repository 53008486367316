import React from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'

import { LexicalComposer } from '@lexical/react/LexicalComposer'
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin'
import { ContentEditable } from '@lexical/react/LexicalContentEditable'
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin'
import { AutoFocusPlugin } from '@lexical/react/LexicalAutoFocusPlugin'
import { TabIndentationPlugin } from '@lexical/react/LexicalTabIndentationPlugin'
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary'
import { HeadingNode, QuoteNode } from '@lexical/rich-text'
import { TableCellNode, TableNode, TableRowNode } from '@lexical/table'
import { ListItemNode, ListNode } from '@lexical/list'
import { CodeHighlightNode, CodeNode } from '@lexical/code'
import { AutoLinkNode, LinkNode } from '@lexical/link'
import { LinkPlugin } from '@lexical/react/LexicalLinkPlugin'
import { ListPlugin } from '@lexical/react/LexicalListPlugin'
import { MarkdownShortcutPlugin } from '@lexical/react/LexicalMarkdownShortcutPlugin'
import { TRANSFORMERS } from '@lexical/markdown'

import clsx from 'clsx'
import ToolbarPlugin from './plugins/ToolbarPlugin'
import editorTheme from './editorTheme'
import CodeHighlightPlugin from './plugins/CodeHighlightPlugin'
import AutoLinkPlugin from './plugins/AutoLinkPlugin'
import OnSetInitialStatePlugin from './plugins/OnSetInitialStatePlugin'
import OnCustomChangePlugin from './plugins/OnCustomChangePlugin'

const editorConfig = {
  // The editor theme
  theme: editorTheme,
  // Handling of errors during update
  onError (error) {
    console.error(error)
  },
  // Any custom nodes go here
  nodes: [
    HeadingNode,
    ListNode,
    ListItemNode,
    QuoteNode,
    CodeNode,
    CodeHighlightNode,
    TableNode,
    TableCellNode,
    TableRowNode,
    AutoLinkNode,
    LinkNode
  ]
}

const Editor = ({
  isMarkdown,
  onChange,
  defaultValue,
  placeholder,
  className
}) => {
  return (
    <LexicalComposer initialConfig={editorConfig}>
      <div className={clsx(className, 'editor-container')}>
        <ToolbarPlugin isMarkdown />
        <div className='editor-inner'>
          <RichTextPlugin
            contentEditable={<ContentEditable className='editor-input' />}
            placeholder={<div className='editor-placeholder'>{placeholder}</div>}
            ErrorBoundary={LexicalErrorBoundary}
          />
          <OnCustomChangePlugin onChange={onChange} />
          <OnSetInitialStatePlugin initialState={defaultValue} isMarkdown={isMarkdown} />
          <HistoryPlugin />
          <AutoFocusPlugin />
          <CodeHighlightPlugin />
          <ListPlugin />
          <LinkPlugin />
          <AutoLinkPlugin />
          <TabIndentationPlugin />
          <MarkdownShortcutPlugin transformers={TRANSFORMERS} />
        </div>
      </div>
    </LexicalComposer>
  )
}

Editor.propTypes = {
  className: PropTypes.string,
  defaultValue: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  isMarkdown: PropTypes.bool,
  onChange: PropTypes.func,
  placeholder: PropTypes.string
}

Editor.defaultProps = {
  defaultValue: {},
  isMarkdown: false,
  onChange: noop,
  placeholder: '...'
}

export default Editor
