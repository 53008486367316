import { isEmpty, camelCase, sortBy } from 'lodash'
import { useMemo } from 'react'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { useWealthJourneyQuery } from '../../../../api/wealthJourney'
import { getRelativeDateRanges } from '../../../../hooks/useAbundanceEngineParameters'
import { replaceValues } from '../../../../utils/replacers'

dayjs.extend(utc)

const queryKey = 'multi-tasks-metrics'

const getDateString = (date, relativeDateRanges) => {
  return dayjs(date).isValid() ? date : replaceValues(date, relativeDateRanges)
}

const mapMetricsSpec = (metrics, relativeDateRanges) => {
  return metrics.reduce((acc, { key, label, startDate, endDate, order, ...rest }) => {
    const metricKey = camelCase(label)
    return {
      ...acc,
      [metricKey]: {
        ...rest,
        key,
        label,
        order,
        startDate: getDateString(startDate, relativeDateRanges),
        endDate: getDateString(endDate, relativeDateRanges)
      }
    }
  }, {})
}

const getTotalQuery = ({
  startDate,
  endDate,
  extraFilters,
  excludeTaskStatuses
}) => {
  return {
    filters: {
      dueDate: [
        { op: 'gte', value: startDate },
        { op: 'lte', value: endDate }
      ],
      status: [{ op: 'notIn', value: excludeTaskStatuses }],
      ...(!isEmpty(extraFilters) ? extraFilters : {})
    },
    resultType: 'total'
  }
}

const mapTaskMetrics = (data, metricsSpec, overdueLabel) => {
  if (!data) return []
  return sortBy(
    Object.entries(data).map(([metricKey, metric]) => {
      const metricSpec = metricsSpec[metricKey]
      const label = metricSpec?.label || overdueLabel
      return {
        ...metric,
        ...(metricSpec?.key ? { key: metricSpec?.key } : {}),
        label,
        order: metricSpec?.order || 999,
        overdue: label === overdueLabel,
        tooltipTitle: metricSpec?.tooltipTitle
      }
    }),
    'order'
  )
}

/**
 *
 * @param {array} metricsSpec
 *  example: [{ label: 'Metric, startDate: '$today', endDate: '$today', order: 1 }]
 * @param {object} extraFilters
 */
export const useTasksMetics = (metricsConfig = {}) => {
  const { query, queryOptions } = useMemo(() => {
    const {
      metricsSpec: rawMetricsSpec = [],
      extraFilters = {},
      includeOverdue = false,
      overdueLabel = 'Overdue',
      asOfDate,
      excludeTaskStatuses,
      overdueStartDate
    } = metricsConfig

    const relativeDateRanges = getRelativeDateRanges(asOfDate)

    const initialQuery = includeOverdue
      ? {
        overdue: getTotalQuery({
          startDate: overdueStartDate || dayjs.utc().year(1900).toISOString(),
          endDate: dayjs.utc(relativeDateRanges.yesterday).endOf('day').format(),
          extraFilters,
          excludeTaskStatuses
        })
      }
      : {}

    const metricsSpec = mapMetricsSpec(rawMetricsSpec, relativeDateRanges)

    return {
      query: Object.entries(metricsSpec).reduce(
        (acc, [requestKey, { startDate, endDate }]) => {
          return {
            ...acc,
            [requestKey]: getTotalQuery({
              startDate,
              endDate,
              extraFilters,
              excludeTaskStatuses
            })
          }
        },
        initialQuery
      ),
      queryOptions: {
        queryName: 'searchTasksMultiple',
        mapper: (data) => mapTaskMetrics(data, metricsSpec, overdueLabel)
      }
    }
  }, [metricsConfig])

  const {
    data,
    isLoading,
    error
  } = useWealthJourneyQuery(queryKey, query, queryOptions)

  return { data, isLoading, error }
}
