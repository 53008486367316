import React, { useCallback, useRef } from 'react'
import { makeStyles } from '@material-ui/core'
import { useWealthOwnerDetails, useSectionEditing } from '../ClientDetailsFormContext'
import ErrorBoundary from '../../../../molecules/ErrorBoundary'
import FadeIn from '../../../../molecules/Transitions/FadeIn'
import { useUpdateWealthOwnerMutation } from '../../../../../api/users'
import { useInvalidateClientSearch } from '../../../../../api/clients'
import SectionScreen from '../SectionScreen'
import SectionHeader from '../../shared/SectionHeader'
import EditButton from '../EditButton'
import PersonalSpace from '../../shared/PersonalSpace'
import WealthOwnerClientsTable from './WealthOwnerClientsTable'

const useStyles = makeStyles((theme) => ({
  clientsSection: {
    position: 'relative',
    padding: '10px',
    transition: 'outline-color 300ms ease-in-out',
    minHeight: '500px'
  }
}))

const TAB_SECTION = 'clients'

function ClientsTab () {
  const { wealthOwner, editSection } = useWealthOwnerDetails()
  const editing = useSectionEditing(TAB_SECTION)
  const { mutateAsync: updateWealthOwner } = useUpdateWealthOwnerMutation()
  const invalidateClientSearch = useInvalidateClientSearch()
  const formRef = useRef()
  const onSave = useCallback(async () => {
    try {
      await updateWealthOwner({
        user: {
          userId: wealthOwner.userId,
          externalUserId: wealthOwner.externalUserId
        },
        addAuthorizations: formRef.current.added.map(a => ({ levelTypeId: 201, levelId: a })),
        removeAuthorizations: formRef.current.removed.map(r => ({ levelTypeId: 201, levelId: r }))
      })
      await invalidateClientSearch()
    } catch (err) {
      console.error(err)
      alert('An unexpected error has occurred')
    }
  }, [wealthOwner, formRef, updateWealthOwner, invalidateClientSearch])

  const classes = useStyles({ editing })

  return (
    <ErrorBoundary name='Wealth Owner Clients Tab'>
      <SectionScreen sectionName={TAB_SECTION} className={classes.clientsSection}>
        <SectionHeader text='Assigned Clients'>
          <div>
            <EditButton
              policy='admin_edit_wo_users'
              editing={editing}
              onClick={() => editSection({
                section: TAB_SECTION,
                saveDescription: 'Save Client Assignments',
                onSave
              })}
              buttonText='Edit Assignments'
            />
          </div>
        </SectionHeader>
        <FadeIn>
          <WealthOwnerClientsTable
            ref={formRef}
            wealthOwner={wealthOwner}
            editing={editing}
          />
        </FadeIn>
        <PersonalSpace />
      </SectionScreen>
    </ErrorBoundary>
  )
}

ClientsTab.propTypes = {
}

export default ClientsTab
