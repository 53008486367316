import React from 'react'
import PropTypes from 'prop-types'
import { useAppContext } from '../../../redux/slices/appContext'
import { useClientThings } from '../../../api/things'
import EmptySection from '../../atoms/EmptySection'
import { ThingContext } from './ThingContext'

function ThingProvider ({ children, noData }) {
  const { clientId } = useAppContext()
  const things = useClientThings(clientId)

  return (
    <ThingContext.Provider value={things}>
      {things.isLoading ? null : (
        things?.data?.things?.length ? children : noData
      )}
    </ThingContext.Provider>
  )
}

ThingProvider.propTypes = {
  children: PropTypes.node,
  noData: PropTypes.node
}

ThingProvider.defaultProps = {
  noData: (
    <EmptySection
      title='No Data Available'
      description=''
    />
  )
}

export default ThingProvider
