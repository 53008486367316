import React, { useEffect } from 'react'
import { useDashboardSettings } from '../ClientDashboardContextProvider'
import Logo from '../components/Logo'
import { useDashboardNavigation } from '../NavigationProvider/context'
import ClientInformation from '../components/ClientInformation'
import { useSetShowHeaderBackButton } from '../../../../redux/slices/appContext'
import { useIsConversationsActive } from '../../../pages/conversations/hooks'
import DashboardTile from '../components/DashboardTile'
import DashboardLayout from './DashboardLayout'

const WheelLayout = () => {
  const { settings, items, highlightedView, changeHighlightedView, clientAccountSettings } = useDashboardSettings()
  const setShowHeaderBackButton = useSetShowHeaderBackButton()
  const navMan = useDashboardNavigation()
  const conversationsEnabled = useIsConversationsActive(settings.conversations)
  const { backgroundImage, logo, metricConfig = {}, backgroundStyles = {} } = settings

  useEffect(() => {
    setShowHeaderBackButton(false)
  }, [setShowHeaderBackButton])

  return (
    <DashboardLayout backgroundImage={backgroundImage} backgroundStyles={backgroundStyles}>
      <Logo settings={logo} />
      <ClientInformation
        lowlighted={!!highlightedView}
        conversations={conversationsEnabled}
        metricConfig={metricConfig}
        clientAccountSettings={clientAccountSettings}
      />
      {items.map((item) => (
        <DashboardTile
          key={item.view}
          position={navMan.getViewPosition(item.view)}
          lowlighted={highlightedView ? highlightedView !== item.view : false}
          onHoverChange={changeHighlightedView}
          {...item}
        />
      ))}
    </DashboardLayout>
  )
}

export default WheelLayout
