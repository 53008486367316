import React, { useCallback, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { first } from 'lodash'
import { Box, makeStyles } from '@material-ui/core'
import PageSizePicker from '../../AdvisorView/views/SalesforcePreview/PageSizePicker'
import { useAdvisorHome } from '../AdvisorHomeContext'
import { useParameterReplacement } from '../../../../hooks/useAbundanceEngineParameters'
import { useLeadAdvisors } from '../../../../api/groups'
import OwnerTasks from '../../AdvisorView/views/SalesforcePreview/OwnerTasks'
import Text from '../../../atoms/Text'

const mapTaskOwners = (data, linkBaseUrl, detailPage) => {
  const taskOwners = data?.reduce((acc, { tasks }) => [...acc, ...tasks], [])
  const tasks = taskOwners.reduce(
    (acc, {
      ownerId,
      photoUrl,
      taskOwner,
      taskOwnerUserId,
      ownerFirstName,
      ownerLastName,
      tasks
    }) => {
      const tasksFormatted = tasks.map(
        ({ longName, title, description, ...task }) => ({
          ...task,
          subject: title || description,
          clientLongName: longName,
          url: linkBaseUrl ? `${linkBaseUrl}/${task.taskId}/view` : null
        })
      )
      const taskOwnerId = taskOwnerUserId || ownerId
      const taskOwnerName = taskOwner || ownerFirstName

      const ownerTasks = [
        ...(acc?.[taskOwnerId]?.tasks || []),
        ...tasksFormatted
      ]

      return {
        ...acc,
        [taskOwnerId]: {
          ownerId: taskOwnerId,
          photoUrl,
          taskCount: ownerTasks.length,
          hasMoreTasks: ownerTasks.length > detailPage.pageSize.value,
          firstName: taskOwnerName,
          lastName: ownerLastName || taskOwnerName,
          avatarLetters: taskOwnerName,
          lastInitial: (ownerLastName || taskOwnerName)?.substring(0, 1),
          tasks: ownerTasks
        }
      }
    },
    {}
  )
  return Object.values(tasks)
}

const useDetailPage = (defaultTake) => {
  const [take, setTake] = useState(defaultTake)
  const onSelect = useCallback(
    (value) => {
      setTake(value)
    },
    [setTake]
  )

  return useMemo(
    () => ({
      pageSize: {
        value: take,
        onSelect
      }
    }),
    [take, onSelect]
  )
}

const useStyles = makeStyles(() => ({
  container: {
    padding: '1rem'
  },
  content: {
    display: 'flex',
    flexWrap: 'wrap',
    overflow: 'scroll'
  }
}))

const TasksDetailsPreview = ({
  title,
  take,
  endDate,
  startDate,
  dataSourceKey,
  linkBaseUrl
}) => {
  const classes = useStyles()
  const detailPage = useDetailPage(take)
  const [start, end] = useParameterReplacement([startDate, endDate])

  const { scope } = useAdvisorHome()
  const advisorGroupId = useMemo(() => first(scope?.values), [scope])

  const { query, queryOptions } = useMemo(() => {
    return {
      query: {
        take,
        includes: {
          tasks: {
            ...(dataSourceKey ? { source: dataSourceKey } : {}),
            activityDate: [
              { value: start, op: 'gt' },
              { value: end, op: 'lt' }
            ],
            dueDate: [
              { value: start, op: 'gt' },
              { value: end, op: 'lt' }
            ]
          }
        },
        ...(advisorGroupId
          ? {
            filters: {
              leadAdvisorId: [{ value: advisorGroupId, op: 'eq' }]
            }
          }
          : {})
      },
      queryOptions: {
        mapper: ({ data = [] }) => {
          return mapTaskOwners(data, linkBaseUrl, detailPage)
        }
      }
    }
  }, [take, dataSourceKey, start, end, advisorGroupId, linkBaseUrl, detailPage])

  const { data, isLoading } = useLeadAdvisors(query, queryOptions)

  const tasksOwners = useMemo(() => {
    return (data ?? []).map(({ tasks, ...rest }) => ({
      ...rest,
      tasks: tasks.slice(0, detailPage.pageSize.value)
    }))
  }, [data, detailPage.pageSize.value])

  if (isLoading) {
    return <div className={classes.container}>...loading</div>
  }

  return (
    <div className={classes.container}>
      <Box mb={2}>
        <Text customFontSize='1.5rem'>{title}</Text>
      </Box>
      <PageSizePicker {...detailPage.pageSize} />
      <div className={classes.content}>
        {tasksOwners?.map((owner) => {
          return (
            <OwnerTasks
              key={owner.ownerId}
              owner={owner}
              take={detailPage.pageSize.value}
            />
          )
        })}
      </div>
    </div>
  )
}

TasksDetailsPreview.propTypes = {
  title: PropTypes.string,
  take: PropTypes.number,
  endDate: PropTypes.string,
  startDate: PropTypes.string,
  dataSourceKey: PropTypes.string,
  linkBaseUrl: PropTypes.string
}

TasksDetailsPreview.defaultProps = {
  title: 'Tasks due in the next 7 days',
  take: 10,
  dataSourceKey: null,
  startDate: '$quarterStart',
  endDate: '$in7days',
  linkBaseUrl: null
}

export default TasksDetailsPreview
