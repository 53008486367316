import React, { forwardRef, useImperativeHandle } from 'react'
import PropTypes from 'prop-types'
import { Controller, useForm } from 'react-hook-form'
import { Grid, makeStyles } from '@material-ui/core'
import get from 'lodash.get'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import Editor from '../../../../../organisms/WYSIWYGEditor'
import Card from '../../../../../molecules/Card'
import EmptySection from '../../../../../atoms/EmptySection'

const useStyles = makeStyles((theme) => ({
  experience: {
    width: '100%'
  },
  editorWrapper: {
    '& .__card': {
      padding: 0,
      overflow: 'clip'
    },
    '& .editor-container .editor-input': {
      backgroundColor: theme.palette.gray.light
    },
    '& .editor-container .toolbar': {
      borderBottom: `1px solid ${theme.palette.gray.darker}`
    }
  },
  markdownContainer: {
    fontWeight: theme.typography.weights.light
  }
}))

const BioForm = forwardRef(function ContactInfoForm ({ user, teamMember, bioAttribute, editing }, ref) {
  const classes = useStyles({ editing })

  const form = useForm({
    mode: 'onChange',
    defaultValues: {
      userId: user.userId,
      teamMember: teamMember || {
        userId: user.userId,
        bio: ''
      }
    }
  })
  const { handleSubmit, reset } = form
  useImperativeHandle(ref, () => {
    return {
      handleSubmit,
      reset
    }
  }, [handleSubmit, reset])

  const defaultBioValue = get(teamMember, bioAttribute.accessor)

  return (
    <div className={classes.wrap}>
      <Grid className={classes.formLayout} container spacing={1}>
        <Grid item xs={12}>
          <Controller
            name={`teamMember.${bioAttribute.accessor}`} control={form.control}
            render={(f => (
              <div className={classes.editorWrapper}>
                {editing ? (
                  <Card className='__card'>
                    <Editor
                      defaultValue={defaultBioValue}
                      placeholder={bioAttribute.data?.placeholder || 'Enter a short Bio'}
                      isMarkdown
                      onChange={f.field.onChange}
                    />
                  </Card>
                ) : (
                  <Card>
                    {defaultBioValue ? (
                      <ReactMarkdown className={classes.markdownContainer} remarkPlugins={[remarkGfm]} value={defaultBioValue}>
                        {defaultBioValue || ''}
                      </ReactMarkdown>
                    ) : (
                      <EmptySection title='' description='Nothing Here Yet' />
                    )}
                  </Card>
                )}
              </div>
            ))}
          />
        </Grid>
      </Grid>
    </div>
  )
})

BioForm.propTypes = {
  user: PropTypes.shape({
    userId: PropTypes.number
  }),
  teamMember:
  PropTypes.shape({
    bio: PropTypes.string
  }),
  editing:
  PropTypes.bool,
  bioAttribute:
  PropTypes.shape({
    accessor: PropTypes.string,
    label: PropTypes.string,
    type: PropTypes.string,
    description: PropTypes.string,
    data: PropTypes.object
  })
}

BioForm.defaultProps = {
  editing: false
}

export default BioForm
