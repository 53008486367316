import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { Controller, useForm } from 'react-hook-form'
import SydLabel from '../../../../../commonDesign/SydLabel'
import SydButton from '../../../../../commonDesign/Button'
import { useModifyAccountTaxStatusMutation } from '../../../../../../api/accounts'
import Select from '../../../../../molecules/Select'
import { useDialogStyles } from '../../useDialogStyles'

const useSubmitter = (form, onComplete) => {
  const { handleSubmit } = form
  const [processing, setProcessing] = useState(false)
  const { mutateAsync: setTaxStatus } = useModifyAccountTaxStatusMutation()
  const onSubmit = useCallback(async (formData) => {
    const command = {
      accountId: formData.accountId,
      taxStatusId: formData.taxStatusId
    }

    try {
      setProcessing(true)
      const result = await setTaxStatus(command)
      onComplete(result)
    } finally {
      setProcessing(false)
    }
  }, [setTaxStatus, setProcessing, onComplete])

  const submitter = useCallback(async (e) => {
    const onValid = async (form) => {
      await onSubmit(form)
    }
    const onInvalid = (errors) => {
      console.error(errors)
    }

    const handler = handleSubmit(onValid, onInvalid)
    await handler(e)
  }, [handleSubmit, onSubmit])

  return {
    submitter,
    processing
  }
}

function TaxStatusForm ({ account, taxStatusOptions, onCancel, onComplete }) {
  const classes = useDialogStyles()
  const form = useForm({
    mode: 'onChange',
    defaultValues: {
      accountId: account.accountId,
      taxStatusId: account.taxStatusId
    }
  })

  const { submitter, processing } = useSubmitter(form, onComplete)

  return (
    <>
      <div className={classes.form}>
        <div className={classes.group}>
          <Controller
            name='taxStatusId' control={form.control}
            render={(f => (
              <SydLabel label='Account Tax Status'>
                <Select fullWidth variant='outlined-rounded' options={taxStatusOptions} showCheckMarOnSelectedItems {...f.field} />
              </SydLabel>
            ))}
          />
        </div>
      </div>
      <div className={classes.actions}>
        <SydButton disabled={processing} variant='ghost' size='lg' onClick={onCancel}>Cancel</SydButton>
        <SydButton
          variant='primary'
          size='lg'
          onClick={submitter}
          processing={processing}
        >
          Save
        </SydButton>
      </div>
    </>
  )
}

TaxStatusForm.propTypes = {
  account: PropTypes.object,
  taxStatusOptions: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.number,
    label: PropTypes.string
  })),
  onComplete: PropTypes.func,
  onCancel: PropTypes.func
}

export default TaxStatusForm
