import React from 'react'
import { makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'

const useStyles = makeStyles(() => ({
  trailingSpace: {
    minHeight: ({ size }) => `${size}px`
  }
}))
function PersonalSpace ({ size }) {
  const classes = useStyles({ size })
  return (
    <div className={classes.trailingSpace} />
  )
}

PersonalSpace.propTypes = {
  size: PropTypes.number
}

PersonalSpace.defaultProps = {
  size: 100
}

export default PersonalSpace
