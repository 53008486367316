import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { useTool } from './index'

function ToolLink ({ tool, toolArgs, children }) {
  const t = useTool(tool)
  const cn = `tool-${tool}`
  const handleClick = useCallback((e) => {
    t.open(toolArgs)
    e.preventDefault()
    e.stopPropagation()
  }, [t, toolArgs])

  if (t) {
    return (
      <div className={clsx('clickable', cn)} role='button' onClick={handleClick}>
        {children}
      </div>
    )
  }

  return (
    <div className={clsx(cn)}>
      {children}
    </div>
  )
}

ToolLink.propTypes = {
  children: PropTypes.node,
  tool: PropTypes.string,
  toolArgs: PropTypes.any
}

export default ToolLink
