import { useState, useCallback, useEffect } from 'react'
import { modifyByIndex, removeByIndex } from '../utils'
import { saveEntryImage, uploadDocument } from '../service'
import { useAppContext } from '../redux/slices/appContext'

const useMilestone = ({ milestone: _milestone }) => {
  const appContext = useAppContext()
  const [milestone, setMilestone] = useState(
    _milestone || {
      entryJson: { attachments: [], tasks: [] },
      entryId: null
    }
  )

  const [entryImageUploadMeta, setEntryImageUploadMeta] = useState({
    progress: null,
    uploaded: false,
    hasError: false,
    aborted: false
  })

  const [uploadImageRequest, setUploadImageRequest] = useState(null)

  useEffect(() => {
    if (_milestone?.entryId) {
      setMilestone({ ..._milestone })
      if (_milestone.entryJson?.images?.length) {
        setEntryImageUploadMeta((prevState) => ({
          ...prevState,
          uploaded: true
        }))
      }
    }
  }, [_milestone])

  useEffect(() => {
    const xhr = new XMLHttpRequest()
    setUploadImageRequest(xhr)
    return () => {
      if (xhr) {
        xhr.abort()
      }
    }
  }, [])

  const findTaskIndex = useCallback((task, tasks) => {
    const taskIndex = tasks.findIndex(
      ({ id, title, description }) => {
        if (task?.id) {
          return task.id === id
        }
        return task.title === title || task.description === description
      })
    return taskIndex
  }, [])

  const onAddDocument = useCallback((ids) => {
    setMilestone((prevMilestone) => {
      const attachments = prevMilestone?.entryJson?.attachments || []
      return {
        ...prevMilestone,
        entryJson: {
          ...prevMilestone.entryJson,
          attachments: [...attachments, ...ids]
        }
      }
    })
  }, [])

  const onRemoveAttachment = useCallback(({ id: attachmentId }) => {
    setMilestone((prevMilestone) => {
      const attachments = prevMilestone?.entryJson?.attachments || []
      const attachmentIdex = attachments.indexOf(attachmentId)
      return {
        ...prevMilestone,
        entryJson: {
          ...prevMilestone.entryJson,
          attachments: removeByIndex(attachmentIdex, attachments)
        }
      }
    })
  }, [])

  const onAddTask = useCallback(() => {
    setMilestone((prevMilestone) => {
      const tasks = prevMilestone?.entryJson?.tasks || []
      const taskId = `${Math.floor(Math.random() * 90 + 10)}`
      return {
        ...prevMilestone,
        entryJson: {
          ...prevMilestone.entryJson,
          tasks: [...tasks, { id: taskId, isNew: true }]
        }
      }
    })
  }, [])

  const onRemoveTask = useCallback(
    (task) => {
      setMilestone((prevMilestone) => {
        const tasks = prevMilestone?.entryJson?.tasks || []
        const taskIndex = findTaskIndex(task, tasks)

        return {
          ...prevMilestone,
          entryJson: {
            ...prevMilestone.entryJson,
            tasks: removeByIndex(taskIndex, tasks)
          }
        }
      })
    },
    [findTaskIndex]
  )

  const onCompleteTask = useCallback(
    ({ status, ...taskRest }) => {
      setMilestone((prevMilestone) => {
        const tasks = prevMilestone?.entryJson?.tasks || []
        const taskIndex = findTaskIndex(taskRest, tasks)
        const task = tasks[taskIndex]

        return {
          ...prevMilestone,
          entryJson: {
            ...prevMilestone.entryJson,
            tasks: modifyByIndex(taskIndex, tasks, { ...task, status })
          }
        }
      })
    },
    [findTaskIndex]
  )

  const onChangeTaskBody = useCallback(
    ({ description, ...taskRest }) => {
      setMilestone((prevMilestone) => {
        const tasks = prevMilestone?.entryJson?.tasks || []
        const taskIndex = findTaskIndex(taskRest, tasks)
        const task = tasks[taskIndex]

        return {
          ...prevMilestone,
          entryJson: {
            ...prevMilestone.entryJson,
            tasks: modifyByIndex(taskIndex, tasks, {
              ...task,
              description
            })
          }
        }
      })
    },
    [findTaskIndex]
  )

  const _onAbortDocumentUpload = useCallback(
    () =>
      setEntryImageUploadMeta((prevState) => ({
        ...prevState,
        aborted: true,
        progress: 0
      })),
    []
  )

  const _onUploadFileProgress = useCallback(
    (event) =>
      setEntryImageUploadMeta((prevState) => ({
        ...prevState,
        progress: (event.loaded / event.total) * 100
      })),
    []
  )

  const onAbortImageUpload = useCallback(() => {
    if (!uploadImageRequest.aborted) {
      uploadImageRequest.abort()
    }
  }, [uploadImageRequest])

  const onAddImage = useCallback(async (file) => {
    try {
      const { clientId } = appContext
      let { entryId } = milestone
      // if is a creation of a milestone
      // then we need to send clientId and an eventId of 0
      if (!entryId) {
        entryId = 0
      }

      const { data } = await saveEntryImage(entryId, {
        fileName: file.name,
        clientId: clientId
      })

      const { newFileName, uploadUrl } = data

      await uploadDocument(
        uploadUrl,
        file,
        _onUploadFileProgress,
        _onAbortDocumentUpload,
        uploadImageRequest
      )
      setEntryImageUploadMeta((prevState) => ({
        ...prevState,
        uploaded: true
      }))
      return { newFileName, uploadUrl }
    } catch (err) {
      setEntryImageUploadMeta({
        progress: 0,
        uploaded: false,
        hasError: true
      })
      console.log(err)
    }
  }, [
    milestone,
    uploadImageRequest,
    appContext,
    _onUploadFileProgress,
    _onAbortDocumentUpload
  ])

  return {
    tasks: milestone?.entryJson?.tasks || [],
    attachments: milestone?.entryJson?.attachments || [],
    setMilestone,
    onAddDocument,
    onRemoveAttachment,
    onAddTask,
    onRemoveTask,
    onCompleteTask,
    onChangeTaskBody,
    onAddImage,
    entryImageUploadMeta,
    onAbortImageUpload
  }
}

export default useMilestone
