import React, { useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import { SecureRoute } from '@okta/okta-react'
import { Switch, useHistory, useRouteMatch } from 'react-router-dom'
import ErrorBoundary from '../../../molecules/ErrorBoundary'
import { useSetShowHeaderBackButton } from '../../../../redux/slices/appContext'
import { defaultOptions } from '../../../molecules/RelativeDateSelect'
import SummitOnlyContent from '../../../atoms/SummitOnlyContent'
import ReportResults from './ReportResults'
import ReportList from './ReportList'
import ReportViews from './ReportViews'

const useStyles = makeStyles(() => ({
  bobbi: {
    padding: 0,
    height: '100%'
  }
}))

export const useBookOfBusinessReport = () => {
  const history = useHistory()
  const { path, url } = useRouteMatch()
  const onFormSubmit = useCallback(reportParameters => {
    const params = new URLSearchParams(reportParameters)
    history.push(`${url}/results?${params.toString()}`)
  }, [history, url])

  return {
    path,
    onFormSubmit
  }
}

// bobi
const BookOfBusinessReport = ({
  columnConfig,
  tableConfigOptions,
  showCustodianFilter,
  useSymbolInsteadOfZero,
  defaultDateRangeOptions
}) => {
  const classes = useStyles()
  const { path } = useBookOfBusinessReport()
  const setShowHeaderBackButton = useSetShowHeaderBackButton()

  useEffect(() => {
    setShowHeaderBackButton(true)
  }, [setShowHeaderBackButton])

  return (
    <div className={classes.bobbi}>
      <ErrorBoundary name='Exposure Report'>
        <Switch>
          <SecureRoute path={path} exact>
            <ReportViews defaultDateRangeOptions={defaultDateRangeOptions} />
          </SecureRoute>
          <SecureRoute path={`${path}/results/:resultOutputId?`}>
            <ReportResults
              columnConfig={columnConfig}
              tableConfigOptions={tableConfigOptions}
              useSymbolInsteadOfZero={useSymbolInsteadOfZero}
              showCustodianFilter={showCustodianFilter}
            />
          </SecureRoute>
          <SecureRoute path={`${path}/list`}>
            <SummitOnlyContent>
              <ReportList />
            </SummitOnlyContent>
          </SecureRoute>
        </Switch>
      </ErrorBoundary>
    </div>
  )
}

BookOfBusinessReport.propTypes = {
  columnConfig: PropTypes.object,
  useSymbolInsteadOfZero: PropTypes.string,
  showCustodianFilter: PropTypes.bool,
  tableConfigOptions: PropTypes.object,
  defaultDateRangeOptions: PropTypes.array
}

BookOfBusinessReport.defaultProps = {
  columnConfig: undefined,
  useSymbolInsteadOfZero: '--',
  showCustodianFilter: false,
  tableConfigOptions: {
    hideCustomizeColumnsColumnHiding: false,
    hideCustomizeColumnsButton: false,
    hideExpandCollapseButton: true,
    hideGroupingsUnderline: true,
    hideExportTypePicker: true,
    hideExportButton: false
  },
  defaultDateRangeOptions: defaultOptions.filter(({ key }) => key !== 'SI')
}

export default BookOfBusinessReport
