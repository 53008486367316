/* eslint-disable react/jsx-key */
import React, { forwardRef, useCallback, useImperativeHandle } from 'react'
import PropTypes from 'prop-types'
import { useColumnOrder, useExpanded, useFlexLayout, usePagination, useSortBy, useTable } from 'react-table'
import { LinearProgress } from '@material-ui/core'
import noop from 'lodash/noop'
import clsx from 'clsx'
import { useSticky } from 'react-table-sticky'
import StickySuperHeader from '../../StickySuperHeader'
import { useTablePositioningContext } from '../../StickySuperHeader/TablePositioningContext'
import Icon from '../../../atoms/Icon'
import { ICON_NAMES } from '../../../../constants'
import { useDeepCompareMemo, useHorizontalOverflow } from '../../../../hooks'
import Tooltip from '../../../atoms/Tooltip'
import ExpandyIcon from './ExpandyIcon'
import TableWrapper from './TableWrapper'
import { useTableStyles } from './tableStyles'
import ColorIcon from './ColorIcon'

/**
 * @param isHeadingCell
 * @param {{ noDefaultTooltip: boolean }} nitpicks
 * @returns {(function(*): (*|string|string))|*}
 */
const benchmarkRenderer = (isHeadingCell = false, nitpicks = {}) => (tableContext) => {
  if (tableContext.column.benchmark) {
    const cells = tableContext.column.benchmark(tableContext.cell.row.original, tableContext.column)
    return cells instanceof Array
      ? cells.map((cell) => nitpicks?.noDefaultTooltip ? (
        <div className='__benchmark'>{cell}</div>
      ) : (
        <Tooltip title={isHeadingCell ? cell : undefined}>
          <div className='__benchmark'>{cell}</div>
        </Tooltip>
      ))
      : ''
  }
  return ''
}

/**
 * @param cellValue
 * @param {{ noDefaultTooltip: boolean }} nitpicks
 * @returns {JSX.Element}
 */
const cellRenderer = (cellValue, nitpicks = {}) => {
  if (nitpicks?.noDefaultTooltip) {
    return (<div className='__cell'>{cellValue ?? '--'}</div>)
  }

  return (
    <Tooltip title={cellValue ?? undefined}>
      <div className='__cell'>{cellValue ?? '--'}</div>
    </Tooltip>
  )
}

/**
 * Table exclusively for use in the PerformanceTableV2.
 * Assumptions are that the data is mainly client-side
 * @param columns
 * @param data
 * @param {bool} expandable - does the table have expandable rows?
 * @param {function} onRowClick - callback when a row gets clicks
 * @param {bool} loading - is the table loading? controls the horizontal loading indicator
 * @param {bool} sortable - is the table client-size sortable?
 * @param {[]} defaultSort - the default sort configuration for the table
 * @param autoSticky
 * @param singleLevel
 * @param showExpandIcon - show the expand icon if true / force hide if false
 * @param showColorIcon
 * @param depthStyles - styles to apply to depth levels on expandable tables
 * @param ref
 * @return {JSX.Element}
 * @constructor
 */
const PerformancePresentationTable = ({
  columns,
  data,
  expandable = false,
  onRowClick = noop,
  loading = false,
  sortable = false,
  defaultSort = [],
  autoSticky = false,
  singleLevel = false,
  showExpandIcon = true,
  showColorIcon = false,
  depthStyles,
  styleVariant,
  nitpicks
}, ref) => {
  const { hasHorizontalOverflow, containerRefCallback } = useHorizontalOverflow(autoSticky)

  const { superHeaderRect } = useTablePositioningContext()
  const classes = useTableStyles({
    columns,
    expandable,
    loading,
    superHeaderRect,
    hasHorizontalOverflow,
    singleLevel,
    depthStyles,
    glassFactor: nitpicks?.glassFactor
  })
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    flatRows,
    flatHeaders,
    allColumns,
    setHiddenColumns,
    setColumnOrder
  } = useTable(
    {
      columns,
      data,
      manualPagination: true,
      manualExpandedKey: 'expanded',
      disableSortBy: !sortable,
      disableSortRemove: true,
      pageCount: -1,
      autoResetPage: false,
      autoResetSortBy: false,
      autoResetExpanded: true, // expanded state will change
      initialState: {
        sortBy: defaultSort
      }
    },
    useSortBy,
    useExpanded,
    usePagination,
    useFlexLayout,
    useSticky,
    useColumnOrder
  )

  const tableProps = useDeepCompareMemo(
    () => ({
      flatHeaders,
      flatRows,
      allColumns,
      setColumnOrder,
      setHiddenColumns
    }),
    [flatHeaders, flatRows]
  )

  useImperativeHandle(ref, () => tableProps, [tableProps])

  const rowClickHandler = useCallback(row => () => {
    if (expandable) {
      row.toggleRowExpanded()
    }
    if (onRowClick) {
      onRowClick(row)
    }
  }, [expandable, onRowClick])

  return (
    <div className={(styleVariant in classes) ? classes[styleVariant] : classes.presentationTableRC}>
      <LinearProgress classes={{ root: classes.sparkRoot }} variant='indeterminate' />
      <div ref={containerRefCallback} className='__table __sticky' {...getTableProps()}>
        <div className='__thead'>
          {headerGroups.map(headerGroup => {
            return (
              <div className='__headerGroup' {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, ci, all) => (
                  <div
                    className={clsx(
                      '__th',
                        `__${column.align ?? 'center'}`,
                        `__depth_${column.depth}`,
                        {
                          __last: column.depth > 0 && ci !== (all.length - 1) && column.id === column.parent.columns.at(-1)?.id,
                          __first: column.depth > 0 && ci > 0 && column.id === column.parent.columns.at(0)?.id
                        }
                    )}
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                  >
                    {column.render('Header')}
                    {sortable && column.isSorted
                      ? (
                        <span className='__sort-icon'>
                          {column.isSortedDesc
                            ? <Icon name={ICON_NAMES.down} />
                            : <Icon name={ICON_NAMES.up} />}
                        </span>
                      ) : null}
                  </div>
                ))}
              </div>
            )
          })}
        </div>
        <div className='__tbody' {...getTableBodyProps()}>
          {page.map(row => {
            prepareRow(row)
            const rowProps = row.getRowProps()
            return (
              <div
                key={rowProps.key}
                className={clsx('__tr', {
                  __child: (row.depth > 0),
                  __expanded: (row.isExpanded),
                  __expandable: expandable
                }, `__depth_${row.depth}`)}
              >
                <div className='__tr-wrapper' {...rowProps}>
                  {row.cells.map((cell, index, all) => {
                    return (
                      <div
                        onClick={rowClickHandler(row)}
                        className={clsx('__td', {
                          __right: cell.column.align === 'right',
                          __last: cell.column.depth > 0 && index !== (all.length - 1) && cell.column.id === cell.column.parent.columns.at(-1)?.id,
                          __first: cell.column.depth > 0 && index > 0 && cell.column.id === cell.column.parent.columns.at(0)?.id
                        })}
                        {...cell.getCellProps()}
                      >
                        {index === 0 && expandable ? (
                          <div className='__expand-container'>
                            <ExpandyIcon
                              className='__expand-handle' enabled={showExpandIcon && row.original?._next !== null}
                              expanded={row.isExpanded} loading={row.original?._subRowsFetching}
                            />
                            <ColorIcon
                              enabled={showColorIcon}
                              color={row.original.color}
                            />
                            <div className='__expand-item'>
                              <div>{cellRenderer(cell.render('Cell'), nitpicks)}</div>
                              {(row.original.hasBenchmark)
                                ? cell.render(benchmarkRenderer(true))
                                : null}
                            </div>
                          </div>
                        ) : (
                          <div>
                            <div>{cellRenderer(cell.render('Cell'), nitpicks)}</div>
                            {(row.original.hasBenchmark)
                              ? cell.render(benchmarkRenderer(false))
                              : null}
                          </div>
                        )}
                      </div>
                    )
                  })}
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

PerformancePresentationTable.propTypes = {
  columns: PropTypes.array,
  data: PropTypes.array,
  expandable: PropTypes.bool,
  loading: PropTypes.bool,
  onRowClick: PropTypes.func,
  sortable: PropTypes.bool,
  defaultSort: PropTypes.array,
  autoSticky: PropTypes.bool,
  singleLevel: PropTypes.bool,
  showExpandIcon: PropTypes.bool,
  showColorIcon: PropTypes.bool,
  depthStyles: PropTypes.object,
  styleVariant: PropTypes.string,
  nitpicks: PropTypes.shape({
    noDefaultTooltip: PropTypes.bool,
    glassFactor: PropTypes.number
  })
}

PerformancePresentationTable.defaultProps = {
  showExpandIcon: true,
  showColorIcon: false,
  styleVariant: 'default',
  nitpicks: {}
}

export default Object.assign(forwardRef(PerformancePresentationTable), {
  Wrapper: TableWrapper,
  SuperHeader: StickySuperHeader
})
