import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import { Box } from '@material-ui/core'
import { useAvailableDates } from '../../redux/slices/appContext'
import DateTimePicker from '../atoms/DateTimePicker'
import useAvailableDatesDefault from '../../hooks/useAvailableDatesDefault'
import { disableHolidaysAndWeekends, disableWeekends } from '../../utils'
import { DATE_TIME_PICKER_TYPES } from '../../constants'
import { useHolidays } from '../../api/coreData'
import Skeleton from '../atoms/Skeleton'

const AvailableDatePicker = ({
  disableFuture,
  extraStyles,
  format,
  label,
  onChange,
  type,
  value,
  className,
  shouldDisableWeekends,
  shouldDisableHolidays,
  disableCalendar
}) => {
  const [availableDates, loadingAvailableDates] = useAvailableDates()
  const componentExtraStyles = { ...(extraStyles || {}), width: '100%' }
  const { maxDate: defaultValue, maxDate, minDate } = useAvailableDatesDefault(availableDates)

  const { data: holidays, isLoading: loadingHolidays } = useHolidays({ enabled: shouldDisableHolidays })

  const shouldDisableDate = useCallback((date) => {
    if (!shouldDisableHolidays && !shouldDisableWeekends) return false
    if (!shouldDisableHolidays && shouldDisableWeekends) {
      return disableWeekends(date)
    }
    return disableHolidaysAndWeekends(date, holidays)
  }, [holidays, shouldDisableHolidays, shouldDisableWeekends])

  return (
    <>
      {(shouldDisableHolidays && loadingHolidays) ? (
        <Box mx='auto' width='300px'>
          <Skeleton />
        </Box>
      ) : (
        <DateTimePicker
          type={type}
          value={value}
          label={label}
          format={format}
          onChange={onChange}
          className={className}
          defaultValue={defaultValue}
          disableFuture={disableFuture}
          disabled={loadingAvailableDates || disableCalendar}
          extraStyles={componentExtraStyles}
          disabledDateRange={{ min: minDate, max: maxDate }}
          shouldDisableDate={shouldDisableDate}
        />
      )}
    </>
  )
}

AvailableDatePicker.propTypes = {
  disableFuture: PropTypes.bool,
  extraStyles: PropTypes.any,
  format: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  type: PropTypes.oneOf(Object.values(DATE_TIME_PICKER_TYPES)),
  value: PropTypes.string,
  shouldDisableWeekends: PropTypes.bool,
  shouldDisableHolidays: PropTypes.bool,
  className: PropTypes.string,
  disableCalendar: PropTypes.bool
}

AvailableDatePicker.defaultProps = {
  disableFuture: false,
  extraStyles: undefined,
  format: 'MM/DD/YYYY',
  label: null,
  onChange: noop,
  type: DATE_TIME_PICKER_TYPES.date,
  value: undefined,
  shouldDisableWeekends: true,
  shouldDisableHolidays: true,
  className: undefined,
  disableCalendar: false
}

export default AvailableDatePicker
