/* eslint-disable react/jsx-boolean-value */
import React from 'react'
import PropTypes from 'prop-types'
import { ResponsiveLine } from '@nivo/line'
// import { values } from 'lodash'

const commonProperties = {
  animate: true
}

const ResearchLine = (props) => {
  const DashedSolidLine = ({ series, lineGenerator, xScale, yScale }) => {
    return series.map(({ id, data, color, lineOnly }, index) => (
      <path
        key={id}
        d={lineGenerator(
          data.map((d) => ({
            x: xScale(d.data.x),
            y: yScale(d.data.y)
          }))
        )}
        fill='none'
        // stroke={color}
        stroke='#E2E2E2'
        style={
          index === 2
            ? {
              // simulate line will dash stroke when index is even
              strokeWidth: 2,
              strokeDasharray: '12, 6'
            }
            : {
              // simulate line with solid stroke
              strokeWidth: 0
            // eslint-disable-next-line react/jsx-indent
            }
        }
      />
    ))
  }

  const minValue = () => {
    const minReturn = props.data.reduce((min, series) => {
      return series.data.reduce((innerMin, values) => {
        return Math.min(innerMin, values.y)
      }, min)
    }, 0)

    return minReturn < 0 ? minReturn * 1.2 : minReturn * 0.8
  }

  return (
    <ResponsiveLine
      {...commonProperties}
      data={props.data}
      curve='monotoneX'
      margin={{ top: 30, right: 30, bottom: 80, left: 45 }}
      xScale={{
        type: 'time',
        format: '%Y-%m-%d',
        precision: 'day'
      }}
      xFormat='time: %m-%d-%Y'
      yScale={{
        type: 'linear',
        stacked: false,
        // format: '%',
        // max: 0.2,
        min: minValue()
      }}
      yFormat='%'
      axisLeft={{
        format: '.0%',
        legend: 'return',
        legendOffset: 24,
        // format: '.2s',
        tickValues: 5
      }}
      axisBottom={{
        format: '%m-%Y',
        legend: '',
        legendOffset: -12
      }}
      onClick={(data) => {
        props.clickSeries(data)
        // console.log(
        // `all the people that ${data.id} for ${data.key} = ${JSON.stringify(data)}`
        // )
      }}
      isInteractive={true}
      layers={['grid', 'markers', 'axes', 'areas', 'crosshair', 'lines', 'points', 'slices', 'mesh', 'legends', DashedSolidLine]}
      // colors={d => d.color}
      lineWidth={2}
      // lineWidth={3}
      activeLineWidth={6}
      inactiveLineWidth={1}
      inactiveOpacity={0.15}
      // enableArea={true}
      enableGridX={false}
      pointSize={0}
      gridYValues={3}
      areaOpacity={0.85}
      useMesh={true}
      motionStiffness={175}
      motionDamping={25}
      theme={{
        grid: {
          line: {
            stroke: '#E2E2E2',
            strokeWidth: 1
          }
        },
        axis: {
          legend: {
            text: {
              fontFamily: 'GothamPro',
              fontWeight: 700
            }
          },
          ticks: {
            text: {
              fontFamily: 'GothamPro',
              fontWeight: 700,
              fontSize: 13
            }
          }
        }
      }}
      legends={[
        {
          anchor: 'bottom',
          direction: 'row',
          justify: false,
          translateX: 0,
          translateY: 80,
          itemsSpacing: 0,
          itemDirection: 'left-to-right',
          itemWidth: 80,
          itemHeight: 20,
          itemOpacity: 1,
          symbolSize: 6,
          symbolShape: 'circle',
          symbolBorderColor: 'rgba(0, 0, 0, .5)',
          effects: [
            {
              on: 'hover',
              style: {
                itemBackground: 'rgba(0, 0, 0, .03)',
                itemOpacity: 1
              }
            }
          ],
          onClick: (data) => {
            props.clickSeries(data)
            // console.log(
            // `all the people that ${data.id} for ${data.key} = ${JSON.stringify(data)}`
            // )
          }

        }
      ]}
    />
  )
}

ResearchLine.propTypes = {
  data: PropTypes.array,
  clickSeries: PropTypes.func
}

export default ResearchLine
