import React, { useCallback, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { alpha, Box, Button } from '@material-ui/core'
import clsx from 'clsx'
import Text from '../../../components/atoms/Text'
import { ICON_NAMES, TEXT_VARIANTS } from '../../../constants'
import Icon from '../../../components/atoms/Icon'
import { useFilterSubscription } from '../useFilterSubscription'
import { useFilterSubscriptionContext } from '../FilterSubscriptionProvider'
import AccountAssetModal from '../AccountAssetModal'
import { useAssetSearch } from '../../../api/coreData'

const useStyles = makeStyles((theme) => ({
  button: {
    background: '#FFFFFF',
    border: '1px solid #ECECEC',
    borderRadius: '8px',
    padding: '6px 8px',
    transition: 'opacity .25s ease-in-out'
  },
  placeholder: {
    fontStyle: 'normal',
    textTransform: 'none',
    fontWeight: 'bold',
    fontSize: '0.8125rem'
  },
  stateText: {
    fontStyle: 'normal',
    textTransform: 'none',
    fontWeight: 'bold',
    fontSize: '0.625rem'
  },
  buttonFilled: {
    background: theme.palette.summitBlue,
    color: theme.palette.white,
    fontWeight: 700,
    height: '2.15rem',
    '&:hover': {
      background: alpha(theme.palette.summitBlue, 0.9)
    }
  },
  disabledButton: {
    pointerEvents: 'none',
    opacity: 0.8,
    transition: 'opacity .25s ease-in-out'
  }
}))

const AccountAssetSubscriptionFilter = ({ filterSubscription, buttonLabel }) => {
  const { filters } = useFilterSubscriptionContext()
  const classes = useStyles()
  const [showAccountAssetModal, setShowAccountAssetModal] = useState(false)
  const filteredAssetIds = useMemo(() => filters?.levelFilters?.assetIds?.[0]?.value ?? [], [filters?.levelFilters?.assetIds])
  const filteredState = useMemo(() => {
    const filteredState = filters?.levelFilters?.assetIds?.[0]?.op
    if (!filteredState) return null
    return filteredState === 'in' ? 'include' : 'exclude'
  }, [filters?.levelFilters?.assetIds])

  const { publishFilters } = useFilterSubscription({
    publishKeys: filterSubscription.publish
  })

  const {
    data: selectedAssets,
    isLoading: isSelectedAssetsLoading
  } = useAssetSearch({
    filters: { assetId: [...filteredAssetIds] }
  }, { enabled: !!filteredAssetIds?.length })

  const currentStateText = useMemo(() => {
    if (!filteredAssetIds?.length > 0 || !filteredState) {
      return null
    }
    let returnText = `${filteredState === 'include' ? 'Including' : 'Excluding'} `
    if (selectedAssets?.length) {
      const displayAsset = selectedAssets[0]
      const trimmedAssetName = displayAsset.assetName?.length > 5
        ? `${displayAsset.assetName.substring(0, 5)}...`
        : displayAsset.assetName
      returnText += `${displayAsset.assetIdentifier} - ${trimmedAssetName}`
    }
    if (selectedAssets?.length > 1) {
      returnText += ` +${selectedAssets?.length - 1}`
    }
    return returnText
  }, [filteredAssetIds?.length, filteredState, selectedAssets])

  const clearFilters = useCallback((e) => {
    e.stopPropagation()
    e.preventDefault()

    publishFilters((prevState) => ({
      ...prevState,
      levelFilters: {
        ...(prevState?.levelFilters ?? {}),
        assetIds: []
      }
    }))
  }, [publishFilters])

  return (
    <>
      <Button
        disableRipple
        variant='outlined'
        onClick={() => setShowAccountAssetModal(true)}
        className={clsx(classes.button, {
          [classes.buttonFilled]: !!filteredAssetIds?.length,
          [classes.disabledButton]: !!filteredAssetIds?.length && isSelectedAssetsLoading
        })}
      >
        <Box display='flex' alignItems='center' gridGap='.5rem'>
          <Text
            text={buttonLabel}
            variant={TEXT_VARIANTS.subtitle1}
            className={classes.placeholder}
          />
          {currentStateText && (
            <Text
              text={currentStateText}
              variant={TEXT_VARIANTS.h6}
              className={classes.stateText}
            />
          )}
          {!!filteredAssetIds?.length && (
            <Box
              display='flex'
              height='2rem'
              alignItems='center'
              margin='-1rem'
              padding='1rem'
              onClick={(e) => clearFilters(e)}
            >
              <Icon name={ICON_NAMES.close} />
            </Box>
          )}
        </Box>
      </Button>

      {showAccountAssetModal && (
        <AccountAssetModal
          open={showAccountAssetModal}
          onClose={() => setShowAccountAssetModal(false)}
          filterSubscription={filterSubscription}
        />
      )}
    </>
  )
}

AccountAssetSubscriptionFilter.propTypes = {
  filterSubscription: PropTypes.shape({
    publish: PropTypes.arrayOf(PropTypes.string)
  }),
  buttonLabel: PropTypes.string
}

AccountAssetSubscriptionFilter.defaultProps = {
  filterSubscription: {
    publish: ['levelFilters']
  },
  buttonLabel: 'Assets'
}

export default AccountAssetSubscriptionFilter
