import PropTypes from 'prop-types'
import TabGroup from './TabGroup'
import TabButtons from './TabButtons'
import Tab from './Tab'
import TabList from './TabList'
import TabLinks from './TabLinks'

export const useCurrentTab = () => {
  const tabs = useCurrentTab()
  return tabs.selectedValue
}

const SydTabs = ({ children }) => {
  return children
}

SydTabs.propTypes = {
  children: PropTypes.node
}

SydTabs.Group = TabGroup
SydTabs.Buttons = TabButtons
SydTabs.List = TabList
SydTabs.Tab = Tab
SydTabs.Links = TabLinks

export default SydTabs
