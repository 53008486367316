import React, { useCallback, useEffect, useMemo, useState } from 'react'
import dayjs from 'dayjs'
import isEmpty from 'lodash/isEmpty'
import { CircularProgress, ClickAwayListener, Grid, IconButton, Input, InputAdornment, makeStyles } from '@material-ui/core'
import uniqBy from 'lodash/uniqBy'
import { animated, useSpring } from 'react-spring'
import { noop } from 'lodash'
import Icon from '../atoms/Icon'
import Select from '../atoms/Select'
import LoadingView from '../pages/LoadingView'
import RoundedButton from '../atoms/RoundedButton'
import ErrorComponent from '../atoms/ErrorComponent'
import DocumentVaultFileUpload from '../organisms/DocumentVaultFileUpload'
import { useFetchState, useToggle } from '../../hooks'
import { fetchDocuments, getDocument } from '../../service'
import { BUTTON_VARIANT, DEFAULT_TAGS, TAGS, BUTTON_SIZES, ICON_NAMES } from '../../constants'
import { useAppContext, useSetAppContext } from '../../redux/slices/appContext'
import PillsGroup from '../molecules/PillsGroup'
import { capitalizeFirstLetter, removeByIndex } from '../../utils'
import Text from '../atoms/Text'
import useDebouncedCallback from '../../hooks/useDebouncedCallback'
import { useFirmDocuments } from '../../api/documentVault'
import DocumentVaultTagRowsList from './DocumentVaultTagRowsList'

const useStyles = makeStyles(() => ({
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    marginTop: '1rem',
    marginBottom: '2.5rem'
  },
  uploadButton: {
    padding: '4px 12px',
    minWidth: '8rem'
  },
  icon: {
    display: 'flex',
    marginRight: '0.25rem'
  },
  select: {
    marginRight: '14px'
  },
  view: {
    padding: '20px 31px',
    width: '100%',
    height: '100%'
  },
  actions: {
    flexGrow: 1
  },
  tagOptions: {
    marginRight: 'auto',
    maxWidth: '80%'
  },
  searchInputPlaceholder: {
    cursor: 'pointer',
    margin: '0 1.5rem 0 0',
    display: 'flex',
    alignItems: 'center'
  },
  rightActionsContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row-reverse'
  }
}))

const LOADING_VIEW_STYLES = {
  position: 'fixed',
  top: '50%',
  transform: 'translateY(-50%)',
  height: '100%',
  width: '100%'
}

const getDocumentListMainDragAndDropContainerStyles = (isModalOpen) => ({
  ...(isModalOpen
    ? { position: 'initial', display: 'block' }
    : { position: 'relative' }),
  padding: 0,
  borderWidth: 2,
  borderStyle: 'dashed',
  marginBottom: '1.5rem',
  borderColor: 'white'
})

const ALL_OPTION = 'all Documents'

// TO-DO retrieve years from endpoint
const YEARS_OPTIONS = [
  {
    label: 'All years',
    value: ALL_OPTION
  },
  {
    label: '2023',
    value: dayjs().year(2023)
  },
  {
    label: '2022',
    value: dayjs().year(2022)
  },
  {
    label: '2021',
    value: dayjs().year(2021)
  },
  {
    label: '2020',
    value: dayjs().year(2020)
  },
  {
    label: '2019',
    value: dayjs().year(2019)
  }
]

function mapDocumentsByTags (documents, tags) {
  const result = tags.reduce((acc, { id, name: tagName }) => {
    return {
      ...acc,
      [tagName]: {
        id,
        items: documents.filter((document) =>
          document.tags.map(({ name }) => name).includes(tagName)
        )
      }
    }
  }, {})
  const resultSorted = Object.keys(result)
    .sort((tagA, tagB) => tagA.localeCompare(tagB))
    .reduce((accumulated, curr) => {
      accumulated[curr] = result[curr]
      return accumulated
    }, {})

  return {
    [TAGS.recents]: { items: documents.slice(0, 5) },
    ...resultSorted,
    [TAGS.others]: {
      items: documents.filter((document) => isEmpty(document.tags))
    }
  }
}

function createTagsOptions (clientTags) {
  const tags = [
    ...DEFAULT_TAGS,
    ...clientTags.map(({ name }) => name)
  ].sort((tagA, tagB) => tagA.localeCompare(tagB))
  return [ALL_OPTION, ...tags].reduce((acc, tag) => {
    return !isEmpty(tag)
      ? [...acc, { label: capitalizeFirstLetter(tag), value: tag, key: tag }]
      : acc
  }, [])
}

function getClientTags (documents) {
  const rawTags = documents.reduce((acc, document) => {
    return [...acc, ...document.tags]
  }, [])
  return uniqBy(rawTags, 'id')
}

function filterByYear (documentsByTags, year) {
  return Object.keys(documentsByTags).reduce((acc, tag) => {
    const currTag = documentsByTags[tag]
    return {
      ...acc,
      [tag]: {
        id: currTag.id,
        items: currTag.items.filter(
          (document) =>
            dayjs(document.createdAt).get('year') === year.get('year')
        )
      }
    }
  }, {})
}

function filterByTag (documentsByTags, tags) {
  return tags.reduce((acc, tag) => {
    return { ...acc, [tag]: documentsByTags[tag] }
  }, {})
}

const MILLISECONDS_BEFORE_SEARCHING_FOR_DOCUMENTS = 1000

const DocumentVault = () => {
  const classes = useStyles()
  const appContext = useAppContext()
  const setAppContext = useSetAppContext()
  const [onFinishCounter, setOnFinishCounter] = useState(0)
  const [selectedTags, setSelectedTags] = useState([ALL_OPTION])
  const [selectedYear, setSelectedYear] = useState(ALL_OPTION)
  const [queryDocument, setQueryDocument] = useState('')
  const [showSearchInput, toggleSearchInput, , toggleSearchInputOff] = useToggle()
  const [documentSearchResult, setDocumentSearchResult] = useState({})
  const [searching, , toggleSearchingOn, toggleSearchingOff] = useToggle()
  const [isModalOpen, , toggleIsModalOpenOn, toggleIsModalOpenOff] = useToggle()
  const [uploadFile, toggleUploadFilesValue, , toggleUploadFilesOffValue] = useToggle()
  const [uploadMainArea, , toggleUploadMainAreaOn, toggleUploadMainAreaOff] = useToggle()

  useEffect(() => {
    setAppContext({
      overviewFullViewContainerStyle: uploadFile || uploadMainArea ? { border: 'none' } : {},
      disableOverviewKeyNavigation: uploadFile || uploadMainArea
    })
    return () =>
      setAppContext({
        overviewFullViewContainerStyle: {},
        disableOverviewKeyNavigation: false
      })
  }, [uploadFile, uploadMainArea, setAppContext])

  const handleYearChange = useCallback((event) => {
    setSelectedYear(event.target.value)
  }, [])

  const refreshDocumentsList = useCallback(
    () => setOnFinishCounter((prevState) => prevState + 1),
    []
  )

  const { data: firmDocuments } = useFirmDocuments()

  const {
    error,
    loading,
    documentsByTags = {},
    tagsOptions = [],
    noDocuments = true
  } = useFetchState(
    useCallback(
      async (safeSetState) => {
        try {
          const { data } = await fetchDocuments(appContext.clientId)
          const clientTags = getClientTags(data)
          const documentsByTags = mapDocumentsByTags(data, clientTags)
          safeSetState({
            documentsByTags,
            onFinishCounter,
            tagsOptions: createTagsOptions(clientTags),
            noDocuments: isEmpty(data) && isEmpty(firmDocuments)
          })
        } catch (error) {
          console.error(error)
          safeSetState({ error })
        }
      },
      [appContext.clientId, onFinishCounter, firmDocuments]
    )
  )

  const onSearchDocuments = useCallback(async (query) => {
    try {
      toggleSearchingOn()
      const { data } = await fetchDocuments(appContext.clientId, { search: query })
      const clientTags = getClientTags(data)
      const documentsByTags = mapDocumentsByTags(data, clientTags)
      setDocumentSearchResult(documentsByTags)
    } catch (err) {
      console.error(err)
    } finally {
      toggleSearchingOff()
    }
  }, [appContext.clientId, toggleSearchingOn, toggleSearchingOff])

  const onSearchDocumentsDebounced = useDebouncedCallback(onSearchDocuments, MILLISECONDS_BEFORE_SEARCHING_FOR_DOCUMENTS)

  const onSearchInputChange = useCallback(({ target: { value } }) => {
    const normalizedValue = (value || '').trim()
    setQueryDocument(normalizedValue)
    if (!isEmpty(normalizedValue)) {
      onSearchDocumentsDebounced(value)
    } else {
      setDocumentSearchResult({})
    }
  }, [onSearchDocumentsDebounced])

  const toggleUploadFiles = useCallback(() => {
    toggleUploadFilesValue()
    refreshDocumentsList()
    toggleUploadFilesOffValue()
  }, [toggleUploadFilesValue, toggleUploadFilesOffValue, refreshDocumentsList])

  const filterDocumentsByTag = useMemo(() => {
    const documents = isEmpty(documentSearchResult) ? documentsByTags : documentSearchResult
    let filtered = documents
    if (!selectedTags.includes(ALL_OPTION) && selectedTags.every(tag => Object.keys(documents).includes(tag))) {
      filtered = filterByTag(documents, selectedTags)
    }
    if (selectedYear !== ALL_OPTION) {
      filtered = filterByYear(filtered, selectedYear)
    }
    return filtered
  }, [selectedYear, selectedTags, documentsByTags, documentSearchResult])

  const handleClickDocument = useCallback(
    async (documentId, isPreview = true) => {
      try {
        const { data } = await getDocument(appContext.clientId, documentId, isPreview)
        if (isPreview) {
          window.open(data.downloadUrl)
        } else {
          const link = document.createElement('a')
          link.href = data.downloadUrl
          link.download = data.name
          document.body.appendChild(link)
          link.click()
          link.remove()
        }
      } catch (error) {
        console.error(error)
      }
    },
    [appContext.clientId]
  )

  const documentVaultFileUploadContainerStyle = useMemo(
    () => {
      if (noDocuments) {
        return {
          height: 'auto',
          width: 'calc(100% - 15px)',
          inset: '3rem 8px 8px',
          ...(appContext.isOverviewFullScreen
            ? {
              height: 'calc(100% - 2rem)',
              inset: '0'
            } : {})
        }
      }
      if (appContext.isOverviewFullScreen) {
        return {
          height: 'calc(100% - 2rem)'
        }
      }
      return {}
    },
    [noDocuments, appContext.isOverviewFullScreen]
  )

  const onMainAreaFileChange = useCallback(
    (files) => {
      isEmpty(files) ? toggleUploadMainAreaOff() : toggleUploadMainAreaOn()
    },
    [toggleUploadMainAreaOn, toggleUploadMainAreaOff]
  )

  const onDocumentListModalOpen = useCallback((isModalOpen) => {
    if (isModalOpen) {
      toggleIsModalOpenOn()
    } else {
      toggleIsModalOpenOff()
    }
  }, [toggleIsModalOpenOn, toggleIsModalOpenOff])

  const onTagFilterClick = useCallback(
    (tagValue) => setSelectedTags(prevState => {
      const allSelectedIndex = prevState.indexOf(ALL_OPTION)
      const tags = allSelectedIndex !== -1
        ? removeByIndex(allSelectedIndex, prevState)
        : prevState

      const tagIndex = tags.indexOf(tagValue)
      if (tagIndex !== -1) {
        const newTags = removeByIndex(tagIndex, tags)
        return newTags.length ? newTags : [ALL_OPTION]
      }
      return [...tags, tagValue]
    }),
    []
  )

  const tagOptions = useMemo(() =>
    isEmpty(tagsOptions)
      ? []
      : Object.values(tagsOptions
        .reduce((acc, { label, value }) => {
          if (value === ALL_OPTION) {
            return {
              ...acc,
              [label]: {
                id: 0,
                label,
                selected: selectedTags.includes(ALL_OPTION) && selectedTags.length === 1,
                onClick: () => setSelectedTags([ALL_OPTION])
              }
            }
          }
          const tagData = documentsByTags[value]
          const tagsCounter = acc[label] ? acc[label].counter + tagData.items.length : tagData.items.length
          let tagLabel = `${label}${tagsCounter > 1 ? ` (${tagsCounter})` : ''}`

          if (filterDocumentsByTag[value] && selectedYear !== ALL_OPTION) {
            tagLabel = `${label} (${filterDocumentsByTag[value].items.length})`
          }

          return {
            ...acc,
            [label]: {
              id: tagData.id || tagLabel,
              label: tagLabel,
              counter: tagsCounter,
              onClick: () => onTagFilterClick(value),
              selected: selectedTags.includes(value)
            }
          }
        }, {})), [selectedTags, selectedYear, tagsOptions, documentsByTags, filterDocumentsByTag, onTagFilterClick])

  const searchInputSpringStyles = useSpring({
    to: { opacity: 1, width: '100%' },
    from: { opacity: 0, width: '0%' }
  })

  const renderedDocumentSearchInput = useMemo(() => {
    return (
      <ClickAwayListener onClickAway={toggleSearchInputOff}>
        <div className={classes.searchInputPlaceholder} onClick={showSearchInput || searching ? noop : toggleSearchInput}>
          <IconButton onClick={!showSearchInput || searching ? noop : toggleSearchInput}>
            {searching
              ? <CircularProgress size={20} />
              : <Icon name={ICON_NAMES.search} customSize='1.25rem' />}
          </IconButton>
          {!showSearchInput ? <Text text='Documents' /> : (
            <animated.div style={searchInputSpringStyles}>
              <Input
                value={queryDocument} disabled={searching} onChange={onSearchInputChange} autoFocus endAdornment={
                  !isEmpty(queryDocument)
                    ? (
                      <InputAdornment>
                        <IconButton onClick={() => onSearchInputChange({ target: { value: null } })}>
                          <Icon name={ICON_NAMES.close} customSize='1.25rem' />
                        </IconButton>
                      </InputAdornment>
                    ) : null
                }
              />
            </animated.div>
          )}
        </div>
      </ClickAwayListener>
    )
  }, [
    searching,
    queryDocument,
    showSearchInput,
    toggleSearchInput,
    onSearchInputChange,
    toggleSearchInputOff,
    searchInputSpringStyles,
    classes.searchInputPlaceholder
  ])

  if (loading) return <LoadingView styles={LOADING_VIEW_STYLES} />

  if (error) return <ErrorComponent error={error} />

  if (uploadFile || noDocuments) {
    return (
      <DocumentVaultFileUpload
        onFinish={toggleUploadFiles}
        showBackButton={!noDocuments}
        clientId={appContext.clientId}
        onBackClick={toggleUploadFilesOffValue}
        containerStyle={documentVaultFileUploadContainerStyle}
      />
    )
  }

  return (
    <DocumentVaultFileUpload
      hasChildren
      onFinish={toggleUploadFiles}
      showBackButton={!noDocuments}
      clientId={appContext.clientId}
      onFilesChange={onMainAreaFileChange}
      onBackClick={toggleUploadFilesOffValue}
      containerStyle={getDocumentListMainDragAndDropContainerStyles(isModalOpen)}
    >
      <div className={classes.view}>
        <div className={classes.titleContainer}>
          <div className={classes.actions}>
            <Grid container>
              <Grid item xs={12} md={6}>
                <PillsGroup
                  pills={tagOptions}
                  size={BUTTON_SIZES.small}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <div className={classes.rightActionsContainer}>
                  <RoundedButton
                    primary
                    variant={BUTTON_VARIANT.outlined}
                    className={classes.uploadButton}
                    onClick={toggleUploadFilesValue}
                  >
                    <span className={classes.icon}>
                      <Icon name={ICON_NAMES.addCircle} customSize='1rem' />
                    </span>
                    <Text customFontWeight='bold' lineHeight='inherit' text='Add Docs' />
                  </RoundedButton>
                  <div className={classes.select}>
                    <Select
                      options={YEARS_OPTIONS}
                      onChange={handleYearChange}
                      selectedValue={selectedYear}
                    />
                  </div>
                  {renderedDocumentSearchInput}
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
        <DocumentVaultTagRowsList
          tags={filterDocumentsByTag}
          onModalOpen={onDocumentListModalOpen}
          handleClickDocument={handleClickDocument}
          refreshDocumentsList={refreshDocumentsList}
        />
      </div>
    </DocumentVaultFileUpload>
  )
}

export default DocumentVault
