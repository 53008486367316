import { useTooltip } from '@nivo/tooltip'
import { createElement, useCallback, useMemo } from 'react'
import { useFormattingContext } from '../../FormattingProvider/FormattingContext'

export const useTooltipEvents = (marker, tooltip) => {
  const { formatter } = useFormattingContext()
  const { showTooltipFromEvent, hideTooltip } = useTooltip()

  const datum = useMemo(() => {
    return ({
      ...marker,
      data: marker.data.data,
      id: marker.data.data.index,
      formattedValue: formatter(marker.value, marker.format || 'percentage')
    })
  }, [formatter, marker])

  const handleMouseEnter = useCallback((event) => {
    showTooltipFromEvent(createElement(tooltip, datum), event)
  }, [datum, showTooltipFromEvent, tooltip])

  const handleMouseLeave = useCallback(() => {
    hideTooltip()
  }, [hideTooltip])

  const handleMouseMove = useCallback((event) => {
    showTooltipFromEvent(createElement(tooltip, datum), event)
  }, [datum, showTooltipFromEvent, tooltip])

  return {
    handleMouseEnter,
    handleMouseLeave,
    handleMouseMove
  }
}
