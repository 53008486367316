import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import { isEmpty, noop } from 'lodash'
import SelectWithCheckbox from '../../../molecules/Select/SelectWithCheckbox'
import { useCustodians } from '../../../../api/coreData'
import Skeleton from '../../../atoms/Skeleton'
import { useBoolean, useSearchAssetsDebounced } from '../../../../hooks'
import SelectWithCheckboxAndSearch from '../../../molecules/Select/SelectWithCheckboxAndSearch'
import { BUTTON_SIZES } from '../../../../constants'
import useSearchTransactionTagsDebounced from '../../../../hooks/useSearchTransactionTagsDebounced'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    gap: '1rem'
  }
}))

const custodianQueryOptions = {
  mapper: (data) => {
    return data.map((datum) => ({
      key: datum.custodianCode,
      value: datum.custodianId,
      label: datum.custodianName
    }))
  }
}

const mapDefaultSelectedOptions = (defaultValues, options) => {
  if (isEmpty(defaultValues)) return []
  const values = defaultValues.map(Number)
  return (options || [])
    .filter(({ value }) => values.includes(value))
    .reduce((acc, option) => ({ ...acc, [option.value]: option }), {})
}

const TransactionTableFilters = ({
  defaultCustodianValues,
  defaultAssetValues,
  defaultTagValues,
  onChangeFilters,
  defaultFilter
}) => {
  const classes = useStyles()
  const [tagSearchMode, setTagSearchMode] = useBoolean()
  const [assetSearchMode, setAssetSearchMode] = useBoolean()
  const { data: custodians, isLoading: loadingCustodians } = useCustodians(
    custodianQueryOptions
  )

  const transactionsQuery = useMemo(() => {
    return {
      query: {
        filters: defaultFilter ?? {}
      },
      defaultTransactionIds: defaultTagValues
    }
  }, [defaultFilter, defaultTagValues])

  const {
    options: transactionTagOptions = [],
    defaultOptions: transactionDefaultOptions = [],
    isLoading: isLoadingTransactionTags,
    onChangeQuery: onChangeTransactionTagsQuery,
    isSearchLoading: isTagSearchLoading
  } = useSearchTransactionTagsDebounced(transactionsQuery)

  const assetsQuery = useMemo(() => {
    return { defaultAssetIds: defaultAssetValues }
  }, [defaultAssetValues])

  const {
    options: assetOptions = [],
    defaultOptions: assetDefaultOptions = [],
    isLoading: isLoadingAssets,
    onChangeQuery: onChangeAssetsQuery,
    isSearchLoading: isAssetSearchLoading
  } = useSearchAssetsDebounced(assetsQuery)

  const onChange = useCallback(
    (searchParamKey) => (searchParamValue) => {
      const searchParamValues = searchParamValue.map(({ value }) => value)
      onChangeFilters({ [searchParamKey]: searchParamValues })
    },
    [onChangeFilters]
  )

  const onChangeCustodian = useMemo(() => onChange('custodianIds'), [onChange])
  const onChangeTag = useMemo(() => onChange('tagIds'), [onChange])
  const onChangeAsset = useMemo(() => onChange('assetIds'), [onChange])

  const defaultCustodianOptions = useMemo(() => {
    return mapDefaultSelectedOptions(defaultCustodianValues, custodians)
  }, [defaultCustodianValues, custodians])

  const defaultTagOptions = useMemo(() => {
    const options = [...transactionDefaultOptions, ...transactionTagOptions]
    return mapDefaultSelectedOptions(defaultTagValues, options)
  }, [defaultTagValues, transactionTagOptions, transactionDefaultOptions])

  const defaultAssetOptions = useMemo(() => {
    const options = [...assetDefaultOptions, ...assetOptions]
    return mapDefaultSelectedOptions(defaultAssetValues, options)
  }, [defaultAssetValues, assetOptions, assetDefaultOptions])

  return (
    <div className={classes.container}>
      {loadingCustodians ? (
        <Skeleton height='2rem' width='8rem' />
      ) : (
        <SelectWithCheckbox
          disabled={loadingCustodians}
          placeholder='Custodian'
          prefixLabel='Custodian'
          options={custodians}
          onChange={onChangeCustodian}
          defaultSelectedOptions={defaultCustodianOptions}
          size={BUTTON_SIZES.small}
        />
      )}

      {!tagSearchMode && isLoadingTransactionTags ? (
        <Skeleton height='2rem' width='8rem' />
      ) : (
        <SelectWithCheckboxAndSearch
          placeholder='Tags'
          searchPlaceholder='Search by tag name'
          options={transactionTagOptions}
          onChange={onChangeTag}
          disabled={isLoadingTransactionTags}
          onClear={onChangeTransactionTagsQuery}
          loading={isTagSearchLoading}
          isLoading={isTagSearchLoading}
          onBlur={setTagSearchMode.off}
          onFocus={setTagSearchMode.on}
          onQueryChange={onChangeTransactionTagsQuery}
          defaultSelectedOptions={defaultTagOptions}
          defaultOptions={transactionDefaultOptions}
          size={BUTTON_SIZES.small}
        />
      )}

      {!assetSearchMode && isLoadingAssets ? (
        <Skeleton height='2rem' width='8rem' />
      ) : (
        <SelectWithCheckboxAndSearch
          placeholder='Assets'
          searchPlaceholder='Search by symbol'
          options={assetOptions}
          onChange={onChangeAsset}
          disabled={isLoadingAssets}
          onClear={onChangeAssetsQuery}
          loading={isAssetSearchLoading}
          isLoading={isAssetSearchLoading}
          onQueryChange={onChangeAssetsQuery}
          onBlur={setAssetSearchMode.off}
          onFocus={setAssetSearchMode.on}
          defaultSelectedOptions={defaultAssetOptions}
          defaultOptions={assetDefaultOptions}
          size={BUTTON_SIZES.small}
        />
      )}
    </div>
  )
}

TransactionTableFilters.propTypes = {
  onChangeFilters: PropTypes.func,
  defaultCustodianValues: PropTypes.object,
  defaultTagValues: PropTypes.object,
  defaultAssetValues: PropTypes.object,
  defaultFilter: PropTypes.object
}

TransactionTableFilters.defaultProps = {
  onChangeFilters: noop,
  defaultCustodianValues: {},
  defaultAssetValues: {},
  defaultTagValues: {},
  defaultFilter: {}
}

export default TransactionTableFilters
