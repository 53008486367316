import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core'
import { useThingContext } from '../ThingContext'
import Thing from '../Thing'

const useStyles = makeStyles((theme) => ({
  thingSection: {
    display: 'flex',
    flexDirection: 'column',
    gap: '5px'
  }
}))

function SingleThing ({ code, showHeader, className, style }) {
  const classes = useStyles()
  const { data, isLoading } = useThingContext()

  const theThing = useMemo(() => {
    if (!data?.things) return null

    return data.things.find(x => x.code === code)
  }, [code, data])

  if (isLoading) return null

  return (
    <div className={clsx(classes.thingSection, className)} style={style}>
      <Thing thing={theThing} showHeader={showHeader} />
    </div>
  )
}

SingleThing.propTypes = {
  code: PropTypes.string.isRequired,
  showHeader: PropTypes.bool,
  className: PropTypes.string,
  style: PropTypes.object
}

SingleThing.propTypes = {
  showHeader: true
}

export default SingleThing
