import React, { useMemo, useState } from 'react'
import { Divider, makeStyles } from '@material-ui/core'
import { Alert, AlertTitle } from '@material-ui/lab'
import { BUTTON_SIZES, TEXT_VARIANTS } from '../../../../../constants'
import OperationalTable, { useOperationalTable } from '../../../../organisms/OperationalTable'
import Text from '../../../../atoms/Text'
import { useListReportRuntimeConfigurationData, useListReportTemplatesData } from '../hooks'
import RoundedButton from '../../../../atoms/RoundedButton'
import RightPanel from '../../../../organisms/RightPanel'
import SnackAlert from '../../../../molecules/SnackAlert/SnackAlert'
import { useAppContext } from '../../../../../redux/slices/appContext'
import TemplateConfigurationsForm from './TemplateConfigurationsForm'
import { useTemplatesConfigColumnConfig } from './hooks'

const useStyles = makeStyles(() => ({
  container: {
    padding: '1rem',
    display: 'flex',
    flex: '1',
    flexDirection: 'column'
  },
  statusContainer: {
    display: 'flex',
    justifyContent: 'flex-start'
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '1rem 0'
  },
  tableHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  backLink: {
    display: 'flex',
    marginBottom: '.5rem'
  },
  status: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '3px 12px 0px',
    gap: '10px',
    width: 'auto',
    maxWidth: '350px',
    height: '26px',
    background: '#E8F0FD',
    border: '1px solid #DBE8FF',
    borderRadius: '8px'
  },
  success: {
    color: '#368759',
    backgroundColor: '#F0F9F4',
    border: '1px solid #DAF0E3'
  },
  warning: {
    color: '#E57373',
    backgroundColor: '#FAEAEA',
    border: '1px solid #E57373'
  }
}))

const TemplateConfiguration = () => {
  const { isSummitUser } = useAppContext()

  const [isOpen, setIsOpen] = useState(false)
  const classes = useStyles()
  const [editConfigurationData, setEditConfigurationData] = useState({})
  const [alert, setAlert] = useState({})

  const initialFormState = {
    runtimeConfigurationId: null,
    name: '',
    content: '',
    templates: []
  }

  const updateTemplateConfigurationForm = (data) => {
    setEditConfigurationData({ ...data, templateIds: data.templates?.map((template) => template.templateId) })
    setIsOpen(!!Object.keys(data)?.length)
  }

  const templateConfigColumnConfig = useTemplatesConfigColumnConfig({ classes, updateTemplateConfigurationForm })
  const {
    defaultPageSize,
    pageIndex,
    pageSize,
    sort,
    onPagingChange,
    onSortingChange,
    onTableModeChange
  } = useOperationalTable({ defaultPageSize: 100, ...templateConfigColumnConfig.defaultSort })

  const options = useMemo(() => ({
    pageIndex,
    pageSize,
    sort
  }), [pageIndex, pageSize, sort])

  const { data, loading, refetch } = useListReportRuntimeConfigurationData(options)
  const { data: templates, loading: isTemplatesLoading } = useListReportTemplatesData({
    offset: 0,
    limit: 1000,
    sort: ''
  })

  const configurationSubmitted = (alert) => {
    if (alert) {
      setAlert(alert)
    }
    refetch()
    updateTemplateConfigurationForm({})
  }

  return (
    (isSummitUser ? (
      <div className={classes.container}>
        <div className={classes.titleContainer}>
          <Text text='Manage Runtime Configurations' variant={TEXT_VARIANTS.h1} />
          <RoundedButton
            primary size={BUTTON_SIZES.small}
            onClick={() => updateTemplateConfigurationForm(initialFormState)}
          >
            Create New Configuration
          </RoundedButton>
        </div>

        <RightPanel
          open={isOpen}
          title={
            <Text
              text={editConfigurationData.runtimeConfigurationId ? 'Edit Configuration' : 'Create Configuration'}
              variant={TEXT_VARIANTS.h2}
            />
          }
          width='850px'
        >
          <TemplateConfigurationsForm
            editConfigurationData={editConfigurationData}
            onSetEditConfigurationData={updateTemplateConfigurationForm}
            onSubmit={configurationSubmitted}
            isTemplatesLoading={isTemplatesLoading}
            templates={templates}
          />
        </RightPanel>

        <Divider />

        <OperationalTable.Wrapper>
          <OperationalTable
            mode='server'
            columns={templateConfigColumnConfig.columns}
            data={data}
            defaultPageSize={defaultPageSize}
            defaultSort={templateConfigColumnConfig.defaultSort}
            itemName='Templates'
            loading={loading}
            total={data?.length || 0}
            onSortingChange={onSortingChange}
            onPagingChange={onPagingChange}
            onTableModeChange={onTableModeChange}
          />
        </OperationalTable.Wrapper>
        <SnackAlert alert={alert} />
      </div>
    ) : (
      <div className={classes.container}>
        <Alert
          icon={false}
          severity='warning'
        >
          <AlertTitle>Access Denied</AlertTitle>
          You do not have access to reports
        </Alert>
      </div>
    ))
  )
}

export default TemplateConfiguration
