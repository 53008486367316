import { useMemo } from 'react'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

dayjs.extend(utc)

const FORMAT = 'YYYY-MM-DD'

function useAvailableDatesDefault (availableDates) {
  return useMemo(() => {
    if (availableDates?.max && availableDates?.min) {
      return {
        maxDate: availableDates.max,
        minDate: availableDates.min,
        mainDate: availableDates.mainDate,
        format: FORMAT
      }
    }
    const yesterday = dayjs(availableDates.mainDate).subtract(1, 'day').format(FORMAT)
    return {
      maxDate: yesterday,
      minDate: yesterday,
      mainDate: availableDates.mainDate,
      format: FORMAT
    }
  }, [availableDates])
}

export default useAvailableDatesDefault
