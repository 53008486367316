import React from 'react'
import { makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'

const useStyles = makeStyles((theme) => ({
  actions: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.layout.gap.medium,
    justifyContent: 'flex-end',
    margin: `${theme.layout.margin.thick} ${theme.layout.margin.thin} 0`
  }
}))

function DialogActions ({ children }) {
  const classes = useStyles()

  return (
    <div className={classes.actions}>
      {children}
    </div>
  )
}

DialogActions.propTypes = {
  children: PropTypes.node
}

export default DialogActions
