import React from 'react'
import PropTypes from 'prop-types'
import { Box, makeStyles } from '@material-ui/core'
import Text from '../../../atoms/Text'
// import { useDynamicPlanning } from '../DynamicPlanningContext'
import { TEXT_VARIANTS } from '../../../../constants'
import BouncingDotsLoader from '../../../atoms/BouncingDotsLoader'

const useStyles = makeStyles(() => ({
  captions: {
    display: 'block'
  },
  alternateCaption: {
    display: 'block',
    marginTop: '.6em',
    opacity: 0.5
  }
}))

const MonteCarloData = ({ primaryData, isProcessing }) => {
  // const { linkedPlan, isSandbox } = useDynamicPlanning()
  const classes = useStyles()

  const successRate = (data) => {
    const successArray = [
      Math.max(0, (data.successRate * 100)).toFixed(0),
      Math.min((data.successRate * 100) * 1.1, 100).toFixed(0)
    ]
    if (successArray[0] === successArray[1]) return successArray[0] + '%'
    return successArray.join('-') + '%'
  }

  // const secondaryDataText = useMemo(() => {
  //   if (!linkedPlan?.planStatusId) return ''
  //   return isSandbox(linkedPlan) ? 'Plan Edits:' : 'Original plan:'
  // }, [linkedPlan, isSandbox])

  if (!primaryData) {
    return (
      <Box margin='-2rem 0 0'>
        <BouncingDotsLoader />
        <Text className={classes.alternateCaption} variant={TEXT_VARIANTS.body} text='Calculating...' customFontSize='10px' />
      </Box>
    )
  }
  return (
    <Box>
      <Text variant={TEXT_VARIANTS.h2} customFontSize='40px' lineHeight='1em'>
        {successRate(primaryData)}
      </Text>
      <div>
        <Text className={classes.captions} variant={TEXT_VARIANTS.body} lineHeight='1rem'>
          with 0-10% adjustment needed
        </Text>
        {/* {secondaryData && ( */}
        {/*  <Text className={classes.alternateCaption} variant={TEXT_VARIANTS.caption} lineHeight='1rem'> */}
        {/*    {secondaryDataText} {successRate(secondaryData)} */}
        {/*  </Text> */}
        {/* )} */}
        {isProcessing && (
          <Text className={classes.alternateCaption} variant={TEXT_VARIANTS.body} text='Calculating...' customFontSize='10px' />
        )}
      </div>
    </Box>
  )
}

MonteCarloData.propTypes = {
  primaryData: PropTypes.object,
  // secondaryData: PropTypes.object,
  isProcessing: PropTypes.bool
}

export default MonteCarloData
