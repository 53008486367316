import React from 'react'
import PropTypes from 'prop-types'
import OutboundSsoLink from '../../../OutboundSso/OutboundSsoLink'

const SsoTile = React.forwardRef(function SsoTile ({ providerId, className, children, sameTab }, ref) {
  return (
    <OutboundSsoLink provider={providerId} ref={ref} className={className} sameTab={sameTab}>
      {children}
    </OutboundSsoLink>
  )
})

SsoTile.propTypes = {
  providerId: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
  sameTab: PropTypes.bool
}

SsoTile.defaultProps = {
  sameTab: false
}

export default SsoTile
