export const getLayoutSettings = ({ backgroundImagePreview, logoPreview, logo }) => {
  return {
    overContentOnDesktop: false,
    backgroundImage: backgroundImagePreview || '/RiverBack_50.png',
    logo: {
      logoPosition: 'topLeft',
      src: logoPreview || '/demoLogo.png',
      isPublicResource: true,
      additionalStyles: {
        top: 'auto',
        bottom: '36px',
        left: '32px',
        width: '180px',
        height: 'auto',
        opacity: '1'
      }
    }
  }
}
