import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  portrait: {
    display: 'inline-flex',
    flexDirection: 'column',
    border: `1px solid ${theme.palette.gray.dark}`,
    borderRadius: theme.layout.radius.loose,
    padding: `${theme.layout.padding.p20} ${theme.layout.padding.p10}`,
    gap: theme.layout.gap.g10,
    minWidth: '160px',
    alignItems: 'center',
    justifyContent: 'center'
  }
}))

function DebugTeamMember ({ teamMember }) {
  const classes = useStyles()

  return (
    <div className={classes.portrait}>
      <pre>{JSON.stringify(teamMember, null, 2)}</pre>
    </div>
  )
}

DebugTeamMember.propTypes = {
  teamMember: PropTypes.object.isRequired
}

export default DebugTeamMember
