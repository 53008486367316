import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { LoginCallback, SecureRoute } from '@okta/okta-react'
import Signout from './components/pages/publicViews/Signout'
import SummitApp from './SummitApp'
import ActivationRedirect from './components/pages/publicViews/ActivationRedirect'

function LoginRouter () {
  return (
    <Switch>
      <Route path='/login/callback' exact>
        <LoginCallback />
      </Route>
      <Route path='/activation'>
        <ActivationRedirect />
      </Route>
      <Route path='/reset-user'>
        <ActivationRedirect />
      </Route>
      <Route path='/logout' exact>
        <Signout />
      </Route>
      <SecureRoute path='/'>
        <SummitApp />
      </SecureRoute>
    </Switch>
  )
}

export default LoginRouter
