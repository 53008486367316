import { useCallback, useEffect, useMemo, useState } from 'react'
import get from 'lodash/get'
import { useCoreTableDataMultiple, useNormalizeDates } from '../../../../api/coreData'
import { useAppContext } from '../../../../redux/slices/appContext'

/**
 * Transforms a list of groupings ['client', 'accountObjective', 'account']
 * into a list of groupings, grouped by depth - used for requesting to core data
 * example output: [['client'], ['client', 'accountObjective'], ['client', 'accountObjective', 'account']
 * @param {[]} groupings
 */
const extractLevelGroupings = (groupings) => {
  const result = []
  for (let i = 0; i < groupings.length; ++i) {
    const inner = []
    for (let j = 0; j <= i; ++j) {
      inner.push(groupings[j])
    }
    result.push(inner)
  }

  return result
}

const compareDepth = (a, b) => b.depth - a.depth
const sortByOrdinal = (a, b) => {
  if ('ordinal' in a) {
    return a.ordinal - b.ordinal
  }
  if ('levelName' in a) {
    return a.levelName.localeCompare(b.levelName)
  }
  return 0
}

const mutateTreeItem = (tree, item, itemOverrides = {}) => {
  tree.forEach(ti => {
    if (ti.uniqueId === item.uniqueId) {
      Object.assign(ti, itemOverrides)
      return
    }
    if (ti.subRows?.length) {
      mutateTreeItem(ti.subRows, item, itemOverrides)
    }
  })
}

const aggregateChildren = (id, item) => {
  if (item.subRows?.length) {
    const result = item.subRows.reduce((agg, row) => {
      const inter = aggregateChildren(id, row)
      if (isNaN(inter)) return agg
      if (inter === null) return agg

      return agg + aggregateChildren(id, row)
    }, null)
    item[id] = result
    return result
  }

  return item[id]
}

/**
 * Recursively maps a flattened list of items into a tree-like structure
 */
const mapSubRows = ({
  parent,
  items,
  allItems,
  depth = 1,
  levelGroupings
}) => {
  if (depth > 10) return []
  return items.reduce((prev, cur) => {
    const nextDepth = depth + 1
    const children = allItems.filter(x => x.depth === (depth + 1) && x.uniqueId.startsWith(cur.uniqueId))

    const subRows = mapSubRows({
      parent: cur,
      items: children,
      allItems,
      depth: nextDepth,
      levelGroupings
    })

    prev.push({
      ...cur,
      subRows,
      _subRowsFetched: !!subRows?.length,
      _next: subRows?.length ? true : null
    })
    return prev
  }, [])
}

/** Take a total value and apply an allocation to a tree of items */
const mapApplyAllocation = (item, valueAccessor, total) => {
  if (!total) return

  const value = +valueAccessor(item)
  item.allocation = value / total
  if (Array.isArray(item.subRows)) {
    item.subRows.forEach(subItem => mapApplyAllocation(subItem, valueAccessor, total))
  }
}

const mapApplyIgnore = (item, fields, ignoreGroupings) => {
  if (!fields?.length) return
  if (!ignoreGroupings?.length) return

  if (ignoreGroupings.includes(item.levelType)) {
    fields.forEach(field => {
      delete item[field]
    })
  }
  if (Array.isArray(item.subRows)) {
    item.subRows.forEach(subItem => mapApplyIgnore(subItem, fields, ignoreGroupings))
  }
}

export const usePivotData = ({
  /** A filter to apply to all requests to core data */
  defaultFilter,

  /** The grouping hierarchy */
  groupings,

  /** The path to get to the datapoint we are pivoting on */
  pivotAccessor,

  /** The grouping in the grouping hierarchy to pivot on */
  pivotGroup,

  /** The path to the item in the response that identifies membership to the pivot group */
  groupIdentifierAccessor,

  /** The depth to expand the table to on initial load */
  initialExpandDepth = 1,

  /** rowFilters is for post processing rows */
  rowFilters = undefined,

  /** CALCULATE ALLOCATION VS TOTAL */
  allocationAccessor,

  /** Groupings to remove values from */
  ignoreGroupings
}) => {
  const findGroupingId = useCallback((item) => get(item, groupIdentifierAccessor), [groupIdentifierAccessor])

  // STEP 1 - Get Normalized Dates
  const { availableDates, clientId, loadingAvailableDates } = useAppContext()
  const normalizedDatesQuery = useMemo(() => {
    return {
      query: {
        dateRanges: {
          balanceRange: {
            startDate: availableDates.mainDate,
            endDate: availableDates.mainDate
          }
        }
      },
      options: {}
    }
  }, [availableDates])

  const normalizedDatesResult = useNormalizeDates(normalizedDatesQuery.query, normalizedDatesQuery.options)

  // STEP 2 - Prepare requests
  const levelGroupings = useMemo(() => extractLevelGroupings(groupings), [groupings])
  const baseFilter = useMemo(() => ({
    clientIds: clientId
  }), [clientId])
  const createRequestForGrouping = useCallback((levelTypes, filters) => {
    return {
      levelFilters: {
        ...(defaultFilter || {}),
        ...baseFilter,
        levelTypes
      },
      rowFilters: rowFilters ?? undefined,
      tableDateRanges: {
        balanceColumns: {
          balance: normalizedDatesResult?.data?.balanceRange
        }
      }
    }
  }, [baseFilter, normalizedDatesResult, defaultFilter, rowFilters])

  const applyAllocation = useCallback((items) => {
    if (!allocationAccessor) return items
    const accessor = (x) => allocationAccessor.split('.').reduce((p, c) => p ? p[c] : p, x)
    if (Array.isArray(items)) {
      items.forEach(item => {
        const total = +accessor(item)
        mapApplyAllocation(item, accessor, total)
      })
    }

    return items
  }, [allocationAccessor])

  const applyIgnore = useCallback((items, fields) => {
    if (!ignoreGroupings?.length) return items

    if (Array.isArray(items)) {
      items.forEach(item => mapApplyIgnore(item, fields, ignoreGroupings))
    }
    return items
  }, [ignoreGroupings])

  // STEP 3 - Send Requests
  const baseQueries = useMemo(() => {
    if (loadingAvailableDates || normalizedDatesResult.isLoading) return []

    // Get the groupings for the first two levels at most. This should be safe if there is only one level
    // TODO - need to ensure that requests are in the correct order to be able to identify results
    return levelGroupings.map(g => createRequestForGrouping(g))
  }, [
    levelGroupings,
    loadingAvailableDates,
    normalizedDatesResult.isLoading,
    createRequestForGrouping
  ])

  /** Get the results for all the queries */
  const { results } = useCoreTableDataMultiple(baseQueries, {
    enabled: !!baseQueries && !normalizedDatesResult.isLoading
  })

  const isAnyLevelLoading = useMemo(() =>
    results.reduce((aggregate, { isLoading }) => aggregate || isLoading, false),
  [results])

  // STEP 4 - Map the Results
  const transformedResults = useMemo(() => {
    if (isAnyLevelLoading) return { data: [], pivotColumns: [] }
    if (!results?.length) return { data: [], pivotColumns: [] }

    const identified = results.map((result, i) => {
      const levelGrouping = levelGroupings.at(i)
      const grouping = levelGrouping.at(-1)
      return ({
        abovePivot: !levelGrouping.includes(pivotGroup),
        belowPivot: levelGrouping.includes(pivotGroup) && grouping !== pivotGroup,
        grouping,
        data: result.data
      })
    })

    // The pivot group might be broken down into several groups, merge them
    const groupedPivotGroupData = identified.find(x => x.grouping === pivotGroup)?.data || []
    const pivotGroupData = Object.values(groupedPivotGroupData.reduce((p, c) => {
      if (!(c.levelId in p)) {
        p[c.levelId] = c
      }

      return p
    }, {}))
    pivotGroupData.sort(sortByOrdinal)

    // we can use this to remap values for things that are "under" the pivoting group
    const pivotGroupings = pivotGroupData.map(x => ({
      header: x.levelName,
      id: `${x.levelName}/value`,
      accessor: `value_${x.levelName}_${x.levelId}`
    }))

    // For items below the pivot, we re-bucket the individual items into unique groups
    const below = identified.filter(x => x.belowPivot).map(result => {
      result.data = Object.values(result.data.reduce((p, c) => {
        if (!(c.levelId in p)) {
          p[c.levelId] = { ...c }
          pivotGroupData.forEach(pg => {
            c.value = null
            c[`value_${pg.levelName}_${pg.levelId}`] = null
            c[`id_${pg.levelName}_${pg.levelId}`] = `${pg.levelName}_${pg.levelId}`
          })
        }

        // member group pivot
        const mgp = pivotGroupData.find(x => x.levelId === findGroupingId(c))
        if (mgp) {
          const sub = p[c.levelId]
          const subValue = get(c, pivotAccessor)
          sub[`value_${mgp.levelName}_${mgp.levelId}`] = subValue
          sub.value = (sub.value || 0) + subValue
        }
        return p
      }, {}))

      return result
    })

    // For items above the pivot, attribute totals
    const above = identified.filter(x => x.abovePivot).map((result) => {
      result.data = result.data.map(x => {
        // We shouldn't need to do this because of the aggregation in the linking step
        pivotGroupData.forEach(() => {
          x.value = get(x, pivotAccessor)
        })
        return x
      })

      return result
    })

    const flattened = [...above, ...below].map(x => x.data).reduceRight((p, c, i) => {
      const mapped = (c || []).map(x => ({
        depth: i,
        expanded: i < initialExpandDepth - 1,
        ...x,
        grouping: levelGroupings.at(i).at(-1)
      }))
      return [...p, ...mapped]
    }, []).sort(compareDepth)

    const linkedResults = flattened.reduce((p, c, i, array) => {
      if (c.depth > 0) return p

      const children = array.filter(x => x.uniqueId.startsWith(c.uniqueId))
      const nextLevel = children.filter(x => x.depth === 1)

      // FIRST WE PROJECT AND LINK CHILDREN DOWN
      c.subRows = mapSubRows({
        parent: c,
        items: nextLevel,
        allItems: children,
        depth: 1,
        levelGroupings
      })

      // THEN WE PULL AGGREGATED DATA BACK UP
      if (c.subRows?.length) {
        // get the line item total
        c.value = c.subRows.reduce((xp, xc) => {
          return (xp || 0) + get(xc, pivotAccessor)
        }, null)

        // get rollup totals
        pivotGroupData.forEach(pg => {
          aggregateChildren(`value_${pg.levelName}_${pg.levelId}`, c)
        })
      }

      c._subRowsFetched = !!c.subRows?.length
      c._next = c.subRows?.length ? true : null
      p.push(c)
      return p
    }, [])

    let finalResult = applyAllocation(linkedResults || [])

    // Ignore levels that want to be ignored
    const ignoreFields = ['value', 'allocation']
    pivotGroupData.forEach(pg => {
      ignoreFields.push(`value_${pg.levelName}_${pg.levelId}`)
    })
    // eslint-disable-next-line no-self-assign
    finalResult = applyIgnore(finalResult || [], ignoreFields)

    return {
      data: finalResult,
      pivotColumns: pivotGroupings
    }
  }, [isAnyLevelLoading, results, levelGroupings, initialExpandDepth, pivotAccessor, pivotGroup, findGroupingId, applyAllocation, applyIgnore])

  /** We are storing the initial tree results in state, and will be modifying the tree on callbacks */
  const [tableData, setTableData] = useState([])
  useEffect(() => {
    setTableData(transformedResults.data)
  }, [setTableData, transformedResults.data])

  // Event handlers
  const onLevelExpand = useCallback(async ({ original: item }) => {
    const nextQuery = item?._next
    // There is no next level to expand to in this case
    if (!nextQuery) return
    if (item?._subRowsFetched) {
      setTableData(prevState => {
        const updated = [...prevState]
        mutateTreeItem(updated, item, {
          expanded: !item.expanded
        })
        return updated
      })
    }
  }, [setTableData])

  return {
    data: tableData || [],
    pivotColumns: transformedResults.pivotColumns,
    isLoading: normalizedDatesResult.isLoading || isAnyLevelLoading,
    onLevelExpand
  }
}
