import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import GroupingProvider from '../../../organisms/GroupingProvider'
import OperationalTable from '../../../organisms/OperationalTable'
import { useClassifyAssetsContext } from './ClassifyAssetsContext'
import { COLUMN_FILTER_MAPPING, getDynamicFilterColumnId } from './helpers'

const ClassifyAssetsTableWrapper = ({ children }) => {
  const { localStorageConfigurationKey, setVisibleFilters, onChangeFilters } =
    useClassifyAssetsContext()

  const onSaveTableConfiguration = useCallback(({ columns, hiddenColumns }) => {
    // show or hide filters based on column visibility
    const dynamicFilterColPrefix = getDynamicFilterColumnId()
    const visibleColFilters = columns.filter((col) =>
      col.startsWith(dynamicFilterColPrefix)
    )
    setVisibleFilters(visibleColFilters)

    onChangeFilters(({ classificationFilters, ...prevFilters }) => {
      const filterColIds = hiddenColumns.filter((col) =>
        col.startsWith(dynamicFilterColPrefix)
      )
      const filters = Object.entries(COLUMN_FILTER_MAPPING).reduce(
        (acc, [colKey, filterKey]) => {
          return {
            ...acc,
            [filterKey]: !hiddenColumns.includes(colKey)
              ? prevFilters[filterKey]
              : []
          }
        }, {})
      return {
        ...filters,
        classificationFilters: classificationFilters.filter(({ tagTypeId }) => {
          return !filterColIds.includes(tagTypeId.toString())
        })
      }
    })
  }, [onChangeFilters, setVisibleFilters])

  return (
    <GroupingProvider
      configurationKey={localStorageConfigurationKey}
      onApplyClick={onSaveTableConfiguration}
      options={{
        hideExportTypePicker: true,
        hideAddAllColumnsButton: true
      }}
    >
      <OperationalTable.Wrapper>{children}</OperationalTable.Wrapper>
    </GroupingProvider>
  )
}

ClassifyAssetsTableWrapper.propTypes = {
  children: PropTypes.node.isRequired
}

export default ClassifyAssetsTableWrapper
