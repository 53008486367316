import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { isEmpty } from 'lodash'
import { useTransactionCodeTags } from '../../../../pages/views/Transactions/FeaturedFilters'
import { useRelativeDateRange } from '../../../../molecules/RelativeDateSelect'
import { useAvailableDates } from '../../../../../redux/slices/appContext'
import EmptySection from '../../../../atoms/EmptySection'
import Metric from '../Metric'
import { useAdvisorHome } from '../../AdvisorHomeContext'

const transactionCodeTagMapper = (data) => {
  return data?.length
    ? data.reduce((acc, { transactionCount }) => acc + transactionCount, 0)
    : 0
}

const TransactionMetric = ({
  valueFormat,
  valueDescription,
  defaultDateRange,
  pageSize,
  defaultFilter,
  enableFirmWideView,
  transactionCodeTagIds,
  metricProps,
  redirectTo
}) => {
  const [availableDates, loadingAvailableDates] = useAvailableDates()
  const { dateRange } = useRelativeDateRange(defaultDateRange, availableDates)

  const { scope } = useAdvisorHome()

  const queryFilter = useMemo(
    () => ({
      ...defaultFilter,
      ...(!isEmpty(transactionCodeTagIds)
        ? {
          transactionCodeTagId: [{
            op: 'in', value: transactionCodeTagIds
          }]
        }
        : {})
    }),
    [defaultFilter, transactionCodeTagIds]
  )

  const queryCountFilter = useMemo(() => {
    if (isEmpty(scope?.values)) {
      return defaultFilter
    }
    return {
      ...defaultFilter,
      groupId: [{ op: 'in', value: scope?.values }]
    }
  }, [scope, defaultFilter])

  const {
    data: transactionsCount,
    isLoading,
    error
  } = useTransactionCodeTags({
    dateRange,
    pageSize,
    enableFirmWideView,
    enabled: true,
    defaultFilter: queryFilter,
    defaultCountFilter: queryCountFilter,
    mapper: transactionCodeTagMapper
  })

  if (error) {
    return <EmptySection title={error?.message} description='' />
  }

  return (
    <Metric
      value={transactionsCount || '-'}
      isLoading={loadingAvailableDates || isLoading}
      valueFormat={valueFormat}
      description={valueDescription}
      redirectTo={redirectTo}
      {...metricProps}
    />
  )
}

TransactionMetric.propTypes = {
  metricProps: PropTypes.object,
  valueFormat: PropTypes.string,
  pageSize: PropTypes.number,
  defaultFilter: PropTypes.object,
  valueDescription: PropTypes.string,
  defaultDateRange: PropTypes.string,
  enableFirmWideView: PropTypes.bool,
  redirectTo: PropTypes.string,
  transactionCodeTagIds: PropTypes.arrayOf(PropTypes.number)
}

TransactionMetric.defaultProps = {
  metricProps: {},
  valueFormat: 'default',
  pageSize: 5,
  defaultFilter: {},
  defaultDateRange: 'L30D',
  valueDescription: 'in the last 30 days',
  enableFirmWideView: true,
  transactionCodeTagId: null,
  redirectTo: undefined
}

export default TransactionMetric
