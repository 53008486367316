import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core'
import { useAppContext } from '../../../redux/slices/appContext'
import { useLatestClientStatusReport } from '../../../api/groups'
import { useFormattingContext } from '../FormattingProvider/FormattingContext'
import FadeIn from '../../molecules/Transitions/FadeIn'

const useStyles = makeStyles((theme) => ({
  asOfDate: {
    fontWeight: theme.typography.weights.light
  }
}))

function StatusReportAsOfDate ({ format, className, style, prefixText }) {
  const classes = useStyles()
  const { clientId } = useAppContext()
  const { data, isLoading } = useLatestClientStatusReport(clientId)
  const { formatter } = useFormattingContext()

  if (isLoading) return null

  if (!data?.report?.asOfDate) return null

  return (
    <FadeIn className={clsx(classes.asOfDate, className)} style={style}>{prefixText}{formatter(data.report.asOfDate, format)}</FadeIn>
  )
}

StatusReportAsOfDate.propTypes = {
  format: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
  prefixText: PropTypes.string
}

StatusReportAsOfDate.defaultProps = {
  prefixText: 'As of ',
  format: '@MMM D, YYYY'
}

export default StatusReportAsOfDate
