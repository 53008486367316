import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { makeStyles, Box } from '@material-ui/core'
import { noop, isEmpty } from 'lodash'
import RoundedButton from '../atoms/RoundedButton'
import { BUTTON_SIZES, ICON_NAMES, TASK_STATUS } from '../../constants'
import Text from '../atoms/Text'
import Icon from '../atoms/Icon'
import TaskItem from './TaskItem'

const useStyles = makeStyles(() => ({
  container: {
    height: '100%'
  },
  title: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '0.875rem'
  },
  content: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    padding: '1rem 0.75rem 1rem 0.5rem',
    border: '2px solid #EEF0F8',
    borderRadius: '0.75rem'
  },
  button: {
    padding: '0.5rem 1rem',
    alignSelf: 'flex-start'
  },
  buttonContent: {
    display: 'flex',
    alignItems: 'end'
  },
  buttonIconContainer: {
    display: 'flex',
    mr: '0.25rem'
  },
  emptySection: ({ readOnly }) => ({
    position: 'relative',
    width: '100%',
    ...(!readOnly ? { cursor: 'pointer' } : {})
  }),
  emptyLabel: {
    position: 'absolute',
    width: '100%',
    top: 0,
    bottom: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  }
}))

const TasksList = ({
  title,
  tasks,
  readOnly,
  buttonLabel,
  onAddTask,
  onRemoveTask,
  onCompleteTask,
  onChangeTaskBody
}) => {
  const classes = useStyles({ readOnly })

  const showTitle = useMemo(() => {
    if (!readOnly) return true
    return !isEmpty(tasks)
  }, [readOnly, tasks])

  const renderTasks = useMemo(() => {
    if (isEmpty(tasks)) {
      if (readOnly) return null
      return (
        <Box className={classes.emptySection}>
          <TaskItem isLoading disabled />
          <Box className={classes.emptyLabel}>
            <Text
              text={`No ${title}`}
              customFontSize='16px'
              customFontWeight={600}
              color='#212945'
            />
          </Box>
        </Box>
      )
    }
    return tasks.map((task, index) => {
      const onChangeBody = ({ target: { value } }) =>
        onChangeTaskBody({ ...task, description: value })

      const onComplete = () =>
        onCompleteTask({
          ...task,
          status:
            task.status === TASK_STATUS.complete
              ? TASK_STATUS.incomplete
              : TASK_STATUS.complete
        })

      const onRemove = () => onRemoveTask(task)

      return (
        <TaskItem
          key={`${task?.id}-${index}`}
          task={task}
          readOnly={readOnly}
          onRemove={onRemove}
          onComplete={onComplete}
          onChangeBody={onChangeBody}
        />
      )
    })
  }, [
    title,
    tasks,
    readOnly,
    onRemoveTask,
    onCompleteTask,
    onChangeTaskBody,
    classes.emptyLabel,
    classes.emptySection
  ])

  const renderAddTaskButton = useMemo(() => {
    if (readOnly) return null
    return (
      <RoundedButton
        onClick={onAddTask}
        className={classes.button}
        size={BUTTON_SIZES.extraSmall}
      >
        <div className={classes.buttonContent}>
          <div className={classes.buttonIconContainer}>
            <Icon color='#212945' customSize='1rem' name={ICON_NAMES.add} />
          </div>
          <Text
            text={buttonLabel}
            lineHeight='1'
            customFontWeight='bold'
            customFontSize='inherit'
          />
        </div>
      </RoundedButton>
    )
  }, [
    readOnly,
    onAddTask,
    buttonLabel,
    classes.button,
    classes.buttonContent,
    classes.buttonIconContainer
  ])

  return (
    <div className={classes.container}>
      <div className={classes.title}>
        {showTitle && (
          <Text
            text={title}
            customFontWeight='bold'
            customFontSize='1.25rem'
            color='#212945'
          />
        )}
      </div>
      <div className={classes.content}>{renderTasks}</div>
      {renderAddTaskButton}
    </div>
  )
}

TasksList.propTypes = {
  readOnly: PropTypes.bool,
  title: PropTypes.string,
  buttonLabel: PropTypes.string,
  onAddTask: PropTypes.func,
  onRemoveTask: PropTypes.func,
  onCompleteTask: PropTypes.func,
  onChangeTaskBody: PropTypes.func,
  tasks: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      description: PropTypes.string,
      status: PropTypes.string,
      title: PropTypes.string,
      isNew: PropTypes.bool
    })
  )
}

TasksList.defaultProps = {
  readOnly: false,
  title: 'Tasks',
  onAddTask: noop,
  onRemoveTask: noop,
  onCompleteTask: noop,
  onChangeTaskBody: noop,
  buttonLabel: 'Add Task',
  tasks: []
}

export default TasksList
