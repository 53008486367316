import React, { useCallback, useImperativeHandle, useMemo, useState } from 'react'
import RoundedModal from '../../../../molecules/RoundedModal'
import { useStatusTemplateContext } from '../StatusTemplateProvider'
import MoveTemplateItemForm from './MoveTemplateItemForm'

const MoveTemplateItemDialog = React.forwardRef(function MoveTemplateItemDialog (_, ref) {
  const [dialogState, setDialogState] = useState({ open: false, processing: false })
  const close = useCallback(() => {
    setDialogState({ open: false, processing: false })
  }, [setDialogState])

  useImperativeHandle(ref, () => ({
    open: (item) => setDialogState({ open: true, processing: false, item })
  }), [setDialogState])

  const templateItem = useMemo(() => {
    return dialogState.item
  }, [dialogState])
  const { statusTemplate } = useStatusTemplateContext()

  return (
    <RoundedModal
      title={`Move Template Item ${templateItem?.statusTemplateItemId}`}
      open={dialogState.open}
      onClose={close}
      position='top'
      disablePortal={false}
    >
      <div>
        {templateItem ? (
          <MoveTemplateItemForm
            templateItem={templateItem}
            statusTemplate={statusTemplate}
            onCancel={close}
            onComplete={close}
          />
        ) : null}
      </div>
    </RoundedModal>
  )
})

MoveTemplateItemDialog.propTypes = {
}

export default MoveTemplateItemDialog
