import React from 'react'
import PropTypes from 'prop-types'
import { Skeleton as MuiSkeleton } from '@material-ui/lab'
import { SKELETON_ANIMATIONS, SKELETON_VARIANTS } from '../../constants'

const DEFAULT_WIDTH = '100%'
const DEFAULT_HEIGHT = '20px'

const Skeleton = ({ animation, height: propHeight, variant, width: propWidth, className }) => {
  const width = typeof propWidth === 'string' ? propWidth : DEFAULT_WIDTH
  const height = typeof propHeight === 'string' ? propHeight : DEFAULT_HEIGHT
  return (
    <MuiSkeleton
      animation={animation}
      height={height}
      variant={variant}
      width={width}
      className={className}
    />)
}

Skeleton.propTypes = {
  animation: PropTypes.oneOf(Object.values(SKELETON_ANIMATIONS)),
  height: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  variant: PropTypes.oneOf(Object.values(SKELETON_VARIANTS)),
  width: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  className: PropTypes.string
}

Skeleton.defaultProps = {
  animation: SKELETON_ANIMATIONS.pulse,
  height: DEFAULT_HEIGHT,
  variant: SKELETON_VARIANTS.rect,
  width: DEFAULT_WIDTH,
  className: ''
}

export default Skeleton
