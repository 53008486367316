/* eslint eqeqeq: 0 */
const fastDeepEqual = (a, b, extraParams) => {
  if (a === b) return true

  const { compareFunctions = true } = extraParams || {}

  if (a && b && typeof a === 'object' && typeof b === 'object') {
    if (a.constructor !== b.constructor) return false

    let length, i
    if (Array.isArray(a)) {
      length = a.length
      if (length != b.length) return false
      for (i = length; i-- !== 0;) { if (!fastDeepEqual(a[i], b[i], extraParams)) return false }
      return true
    }

    if (a instanceof Map && b instanceof Map) {
      if (a.size !== b.size) return false
      for (i of a.entries()) if (!b.has(i[0])) return false
      for (i of a.entries()) { if (!fastDeepEqual(i[1], b.get(i[0]), extraParams)) return false }
      return true
    }

    if (a instanceof Set && b instanceof Set) {
      if (a.size !== b.size) return false
      for (i of a.entries()) if (!b.has(i[0])) return false
      return true
    }

    if (ArrayBuffer.isView(a) && ArrayBuffer.isView(b)) {
      length = a.length
      if (length != b.length) return false
      for (i = length; i-- !== 0;) if (a[i] !== b[i]) return false
      return true
    }

    if (a.constructor === RegExp) { return a.source === b.source && a.flags === b.flags }
    if (a.valueOf !== Object.prototype.valueOf) { return a.valueOf() === b.valueOf() }
    if (a.toString !== Object.prototype.toString) { return a.toString() === b.toString() }

    const keys = Object.keys(a)
    length = keys.length
    if (length !== Object.keys(b).length) return false

    for (i = length; i-- !== 0;) { if (!Object.prototype.hasOwnProperty.call(b, keys[i])) return false }

    for (i = length; i-- !== 0;) {
      const key = keys[i]

      if (key === '_owner' && a.$$typeof) {
        // React-specific: avoid traversing React elements' _owner.
        //  _owner contains circular references
        // and is not needed when comparing the actual elements (and not their owners)
        continue
      }
      if (!fastDeepEqual(a[key], b[key], extraParams)) return false
    }
    return true
  }

  if (!compareFunctions && typeof a === 'function' && typeof a === 'function' && a !== b) {
    return true
  }
  // true if both NaN, false otherwise
  /* eslint-disable no-self-compare */
  return a !== a && b !== b
}

export default fastDeepEqual
