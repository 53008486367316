import React, {
  forwardRef,
  useCallback,
  useEffect, useImperativeHandle,
  useMemo,
  useRef,
  useState
} from 'react'
import PropTypes from 'prop-types'
import { Button, makeStyles } from '@material-ui/core'
import OperationalTable from '../../../../organisms/OperationalTable'
import { useFormattingContext } from '../../../../organisms/FormattingProvider/FormattingContext'
import Icon from '../../../../atoms/Icon'
import DebouncedInput from '../../../../molecules/DebouncedInput'
import { useSearchClientsMultiple } from '../../../../../api/clients'
import { useListWealthOwnerClients } from '../../../../../api/users'
import { useWealthOwnerClientsActions, WealthOwnerClientsTableContext } from './WealthOwnerClientsTableContext'
import ClientInfoCell from './ClientInfoCell'

const EditActionCell = ({ value }) => {
  const { added, removed, onAdd, onRemove, assignedClients } = useWealthOwnerClientsActions()
  const action = useMemo(() => {
    const assigned = assignedClients.some(x => x.clientId === value)
    if (assigned && removed.includes(value)) {
      return (
        <Button
          className='__action-button __removed'
          variant='outlined'
          startIcon={<Icon name='checkCircle' />}
          onClick={() => onRemove(value)}
        >
          Removed
        </Button>
      )
    }
    if (assigned) {
      return (
        <Button
          className='__action-button'
          variant='outlined'
          startIcon={<Icon name='removeCircle' />}
          onClick={() => onRemove(value)}
        >
          Remove
        </Button>
      )
    }
    if (added.includes(value)) {
      return (
        <Button
          className='__action-button __added'
          variant='outlined'
          startIcon={<Icon name='checkCircle' />}
          onClick={() => onAdd(value)}
        >
          Added
        </Button>
      )
    }
    return (
      <Button
        className='__action-button'
        variant='outlined'
        startIcon={<Icon name='addCircle' />}
        onClick={() => onAdd(value)}
      >
        Add
      </Button>
    )
  }, [added, removed, onAdd, onRemove, assignedClients, value])
  return (
    <div>
      {action}
    </div>
  )
}

EditActionCell.propTypes = {
  value: PropTypes.any
}

const defaultColumnConfig = {
  columns: [
    {
      id: 'shortName',
      accessor: 'shortName',
      Header: 'Family Name',
      width: 200,
      Cell: ClientInfoCell
    },
    {
      id: 'longName',
      accessor: 'longName',
      Header: 'Display Name',
      width: 200,
      Cell: ClientInfoCell
    },
    {
      id: 'accountCount',
      accessor: 'accountCount',
      Header: 'Assigned Accounts',
      width: 200
    }
  ],
  defaultSort: [{ id: 'shortName', desc: false }]
}

const editingColumns = [{
  id: 'actions',
  accessor: 'clientId',
  Header: 'Actions',
  width: 200,
  Cell: EditActionCell
}]

export const useColumns = ({ editing }) => {
  const { formatter } = useFormattingContext()

  return useMemo(() => {
    const { columns, defaultSort } = defaultColumnConfig
    const allColumns = editing ? [...columns, ...editingColumns] : columns
    const templatedColumns = allColumns.map((column) => {
      if (column?.Cell) return column

      return {
        ...column,
        editing,
        Cell: ({ value }) => formatter(value, column.format)
      }
    })

    return {
      columns: templatedColumns,
      defaultSort
    }
  }, [formatter, editing])
}

const useClientSearch = (query) => {
  return useSearchClientsMultiple(query)
}

const useWealthOwnerClients = (wo, editing) => {
  const [search, setSearch] = useState('')
  const { data: assigned, isLoading: assignedLoading } = useListWealthOwnerClients(wo.userId)
  const query = useMemo(() => {
    const searchValue = search || undefined

    // If we are editing the form, show everone, otherwise limit to the wealth owner assignments
    const paramsFilter = (searchValue && editing) ? undefined : {
      assignToUserIds: [wo.userId]
    }
    return {
      assignedClients: {
        includes: {
          accountCount: true
        },
        filters: paramsFilter,
        textSearch: {
          longName: searchValue ? [{ op: 'contains', value: searchValue }] : undefined,
          shortName: searchValue ? [{ op: 'contains', value: searchValue }] : undefined
        }
      },
      searchTotal: {
        resultType: 'total',
        filters: paramsFilter
      }
    }
  }, [wo.userId, search, editing])

  const { data: searchResults, isLoading: searchResultsLoading, error } = useClientSearch(query, editing)

  const { clients, total } = useMemo(() => {
    if (searchResultsLoading) {
      return {
        clients: [],
        total: null
      }
    }
    return {
      clients: searchResults.assignedClients.data,
      total: searchResults.searchTotal.total
    }
  }, [searchResults, searchResultsLoading])

  const [added, setAdded] = useState([])
  const [removed, setRemoved] = useState([])
  const onAdd = useCallback((personId) => {
    setAdded(p => p.includes(personId) ? p.filter(x => x !== personId) : [...p, personId])
  }, [setAdded])
  const onRemove = useCallback((personId) => {
    setRemoved(p => p.includes(personId) ? p.filter(x => x !== personId) : [...p, personId])
  }, [setRemoved])

  return {
    clients,
    total,
    isLoading: searchResultsLoading || assignedLoading,
    error,
    setSearch,
    added,
    removed,
    onAdd,
    onRemove,
    assignedClients: assigned?.userClients
  }
}

const useStyles = makeStyles((theme) => ({
  table: {
    marginTop: '10px',

    '& .__action-button': {
      textTransform: 'none',
      borderRadius: '1rem'
    },
    '& .__removed': {
      color: theme.palette.danger.main
    },
    '& .__added': {
      color: theme.palette.success.main
    },
    '& .__info-cell': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      minHeight: '36px'
    }
  },
  search: {
    maxWidth: '300px'
  }
}))

const WealthOwnerClientsTable = forwardRef(function ({
  wealthOwner,
  editing
}, ref) {
  const {
    clients, // the search results
    total, // the total number of people assigned
    isLoading, // is any query loading?
    setSearch, // search account text
    added,
    removed,
    onAdd,
    onRemove,
    assignedClients // the assigned people list
  } = useWealthOwnerClients(wealthOwner, editing)
  const columnConfig = useColumns({ editing })
  const searchRef = useRef()
  useEffect(() => {
    searchRef.current?.clear()
  }, [editing, searchRef])
  const onChange = useCallback((newValue) => {
    setSearch(newValue)
  }, [setSearch])
  const classes = useStyles()
  const providerValue = useMemo(() => {
    return { added, removed, onAdd, onRemove, assignedClients, editing }
  }, [added, removed, onAdd, onRemove, assignedClients, editing])

  useImperativeHandle(ref, () => {
    return {
      added,
      removed
    }
  }, [added, removed])

  return (
    <WealthOwnerClientsTableContext.Provider value={providerValue}>
      <OperationalTable.Wrapper className={classes.table}>
        <DebouncedInput
          className={classes.search}
          ref={searchRef}
          onChange={onChange}
          placeholder={editing ? 'Search for Clients to Add' : 'Search Assigned Clients'}
        />
        <OperationalTable
          loading={isLoading}
          mode='client'
          columns={columnConfig.columns || []}
          defaultSort={columnConfig.defaultSort || []}
          data={clients}
          total={total}
          itemName='Clients'
          hideFooter
        />
      </OperationalTable.Wrapper>
    </WealthOwnerClientsTableContext.Provider>
  )
})

WealthOwnerClientsTable.propTypes = {
  wealthOwner: PropTypes.shape({
    userId: PropTypes.number
  }),
  editing: PropTypes.bool
}

WealthOwnerClientsTable.defaultProps = {
  editing: false
}

export default WealthOwnerClientsTable
