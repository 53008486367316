import { useMemo } from 'react'

export const useEntryTypeFilter = (entries, entryTypes) => {
  return useMemo(() => {
    return (entries || []).filter(entry => {
      if (!entryTypes?.length) return true
      return entryTypes.includes(entry.entryType.entryTypeCode)
    })
  }, [entries, entryTypes])
}
