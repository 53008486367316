import React, { useMemo, useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { CircularProgress, makeStyles } from '@material-ui/core'
import { useDropzone } from 'react-dropzone'
import noop from 'lodash/noop'
import { ICON_NAMES } from '../../../../../constants'
import Icon from '../../../../atoms/Icon'
import Image from '../../../../atoms/Image'
import { useCreateImageUpload, useWealthJourneyImage } from '../../../../../api/wealthJourney'
import { uploadDocument } from '../../../../../service'
import FadeIn from '../../../../molecules/Transitions/FadeIn'

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  borderRadius: '100%',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
  width: '6.25rem',
  height: '6.25rem',
  position: 'relative',
  overflow: 'hidden',
  background: 'transparent',
  border: '1px solid',
  borderColor: 'transparent',
  cursor: 'pointer'
}

const hoverStyles = {
  background: 'rgba(223, 230, 255, 0.8)'
}

const focusedStyle = {
  borderColor: '#2196f3'
}

const acceptStyle = {
  borderColor: '#00e676'
}

const rejectStyle = {
  borderColor: '#ff1744'
}

const useStyles = makeStyles((theme) => ({
  iconContainer: {
    transition: 'all .2s ease-in-out',
    borderColor: theme.palette.primary.main
  },
  container: {
    width: 'fit-content'
  },
  icon: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-65%)'
  },
  label: {
    color: 'white',
    backgroundColor: '#212945',
    bottom: '0',
    textAlign: 'center',
    position: 'absolute',
    fontSize: '0.75rem',
    padding: '0.1875rem 0 0.5rem 0',
    width: '100%'
  },
  contentButtonBackground: {
    position: 'absolute',
    top: '0',
    zIndex: 1,
    height: '100%',
    width: '100%',
    background: 'rgba(223, 230, 255, 0.7)'
  },
  centeredButton: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)'
  }
}))

function AvatarInput ({
  clientId,
  label,
  disabled,
  disabledOpacity,
  acceptFiles,
  onChange,
  value
}) {
  const classes = useStyles()
  const [hovered, setHovered] = useState(false)
  const [filePreview, setFilePreview] = useState(null)
  const { mutateAsync: createImageUpload } = useCreateImageUpload()
  const [processing, setProcessing] = useState(false)

  const { data: avatarImage } = useWealthJourneyImage(clientId, value)

  const onDropAccepted = useCallback(async ([file]) => {
    setHovered(false)
    setProcessing(true)
    try {
      if (file) {
        const preview = URL.createObjectURL(file)
        // URL.revokeObjectURL(preview)
        setFilePreview(preview)
      }
      const uploadDetails = await createImageUpload({
        clientId,
        fileName: file.name
      })

      const xhr = new XMLHttpRequest()
      await uploadDocument(uploadDetails.uploadUrl, file, (event) => {
        if (event.total === event.loaded) {
          setProcessing(false)
        }
      }, () => {
        setProcessing(false)
      }, xhr)

      onChange?.(uploadDetails.newFileName)
    } catch (err) {
      setProcessing(false)
    }
  }, [createImageUpload, setHovered, clientId, onChange, setProcessing])

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
    useDropzone({
      accept: acceptFiles,
      maxFiles: 1,
      onDropAccepted,
      disabled
    })

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(disabled ? { opacity: disabledOpacity } : {}),
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {})
    }),
    [isFocused, isDragAccept, isDragReject, disabled, disabledOpacity]
  )

  const onDeletePicture = useCallback((event) => {
    event.stopPropagation()
    setFilePreview(null)
    onChange(null)
  }, [onChange, setFilePreview])

  const showHover = useMemo(() => {
    if (processing) return false
    return hovered && (filePreview || avatarImage?.downloadUrl)
  }, [processing, hovered, filePreview, avatarImage?.downloadUrl])

  const onMouseEnter = useCallback(() => !disabled && setHovered(true), [disabled])
  const onMouseLeave = useCallback(() => setHovered(false), [])
  const containerStyle = {
    ...style,
    ...(hovered ? hoverStyles : {}),
    background: 'rgb(223, 230, 255)'
  }

  return (
    <div
      className={classes.container}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <div {...getRootProps({ style: containerStyle })} className={classes.iconContainer}>
        <input {...getInputProps()} />
        {processing ? (
          <div className={classes.contentButtonBackground}>
            <div className={classes.centeredButton}>
              <CircularProgress />
            </div>
          </div>
        ) : null}
        {showHover ? (
          <FadeIn className={classes.contentButtonBackground} onClick={onDeletePicture}>
            <div className={classes.centeredButton}>
              <Icon name={ICON_NAMES.delete} customSize='2rem' color='#212945' />
            </div>
          </FadeIn>
        ) : null}
        {(avatarImage?.downloadUrl || filePreview) ? (
          <Image
            isPublicResource
            extraStyles={baseStyle}
            src={avatarImage?.downloadUrl || filePreview}
            onLoad={() => {
              if (filePreview) {
                URL.revokeObjectURL(filePreview)
              }
            }}
          />
        ) : (
          <FadeIn className={classes.icon}>
            {filePreview ? null : <Icon customSize='2rem' color='#212945' name={ICON_NAMES.camera} />}
          </FadeIn>
        )}
        {label && (<span className={classes.label}>{label}</span>)}
      </div>
    </div>
  )
}

AvatarInput.propTypes = {
  clientId: PropTypes.number,
  ignoreDelete: PropTypes.bool,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  acceptFiles: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
  value: PropTypes.string,
  disabledOpacity: PropTypes.number
}

AvatarInput.defaultProps = {
  ignoreDelete: false,
  label: 'Image',
  disabled: false,
  acceptFiles: ['image/*'],
  onDropAccepted: noop,
  onRemovePicture: noop,
  disabledOpacity: 0.5
}

export default AvatarInput
