import React from 'react'
import dayjs from 'dayjs'
import Avatar from '@material-ui/core/Avatar'

export const cellTemplates = {
  /* eslint-disable-next-line jsx-a11y/anchor-is-valid */
  subjectLink: (props) => {
    const { row } = props
    const { original } = row
    return original.url
      ? <a href={original.url} target='_blank' rel='noreferrer'>{original.subject}</a>
      : original.subject ?? null
  },
  date: ({ value }) => dayjs.utc(value).format('M/D'),
  icon: ({ value }) => <Avatar variant='small' src={value} />,
  noBreak: ({ value }) => <span style={{ wordBreak: 'keep-all', whiteSpace: 'nowrap' }}>{value}</span>
}

export const groupByOwnerColumnConfig = [
  { Header: 'Client', accessor: row => { return getLastName(row.clientLongName) }, id: 'clientName' },
  { Header: 'Subject', accessor: 'subject', Cell: cellTemplates.subjectLink },
  { Header: 'Due', accessor: 'dueDate', Cell: cellTemplates.date }
]

export const groupByClientColumnConfig = [
  {
    Header: 'Owner',
    accessor: row => `${row.ownerFirstName} ${row.ownerLastName?.substring(0, 1)}`,
    id: 'owner name',
    Cell: cellTemplates.noBreak
  },
  { Header: 'Subject', accessor: 'subject', Cell: cellTemplates.subjectLink },
  { Header: 'Due', accessor: 'dueDate', Cell: cellTemplates.date }
]

const getLastName = (clientLongName) => {
  const nameArray = clientLongName.split(' ')
  let lastName = nameArray[nameArray.length - 1]
  if (lastName.toLowerCase() === 'jr' || lastName.toLowerCase() === 'jr.') {
    lastName = `${nameArray[nameArray.length - 2]} ${lastName}`
  }
  return lastName
}
