import React, { useCallback, useImperativeHandle, useState } from 'react'
import PropTypes from 'prop-types'
import RoundedModal from '../../../../../molecules/RoundedModal'
import ModifyRestrictionsForm from './form'

const ModifyRestrictionsDialog = React.forwardRef(function ModifyRestrictionsDialog ({ onComplete }, ref) {
  const [dialogState, setDialogState] = useState({ open: false, processing: false })
  const close = useCallback(() => {
    setDialogState({ open: false, processing: false })
  }, [setDialogState])

  const complete = useCallback(async () => {
    onComplete()
    close()
  }, [close, onComplete])

  useImperativeHandle(ref, () => ({
    open: (client) => setDialogState({ open: true, processing: false, client })
  }), [setDialogState])

  return (
    <RoundedModal
      title='Modify Client Visibility Level'
      open={dialogState.open}
      onClose={close}
      position='top'
      disablePortal={false}
    >
      <div>
        {dialogState.open ? (<ModifyRestrictionsForm client={dialogState.client} onComplete={complete} onCancel={close} />) : null}
      </div>
    </RoundedModal>
  )
})

ModifyRestrictionsDialog.propTypes = {
  onComplete: PropTypes.func
}

export default ModifyRestrictionsDialog
