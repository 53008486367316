import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@material-ui/core'
import { useCurrentClient } from '../../../api/clients'
import FieldAccessor, { useStyles } from './FieldAccessor'

function ProfileFields ({ fieldConfig, spacing, style }) {
  const classes = useStyles()
  const { data, isLoading } = useCurrentClient()
  const fieldMap = useMemo(() => {
    return Object.entries(fieldConfig).map(([key, value]) => {
      return { key, xs: 12, ...value }
    })
  }, [fieldConfig])

  if (isLoading) {
    return null
  }

  return (
    <Grid container className={classes.fields} spacing={spacing} style={style}>
      {fieldMap.map(item => (
        <FieldAccessor {...item} key={item.key} item={data} />
      ))}
    </Grid>
  )
}

ProfileFields.propTypes = {
  fieldConfig: PropTypes.object,
  spacing: PropTypes.number,
  style: PropTypes.object
}

ProfileFields.defaultProps = {
  spacing: 3
}

export default ProfileFields
