import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import { useAppContext } from '../../../redux/slices/appContext'
import { useSearchClients } from '../../../api/clients'
import { useFormattingContext } from '../FormattingProvider/FormattingContext'
import PresentationTable from '../PresentationTable'

const useStyles = makeStyles((theme) => ({
}))

const useMetrics = (datapoints) => {
  const { clientId } = useAppContext()
  const query = useMemo(() => {
    return {
      limit: 1,
      filters: {
        clientId: [{ op: 'eq', value: clientId }]
      },
      includes: {
        keyDatapoints: datapoints.map(x => x.code)
      }
    }
  }, [datapoints, clientId])

  const { data, isLoading } = useSearchClients(query)

  const { formatter } = useFormattingContext()
  const formattedValues = useMemo(() => {
    if (!data?.data?.length) return []
    const kdps = data.data.at(0).keyDatapoints
    return datapoints.map(dp => {
      const match = kdps[dp.code]
      if (!match) return null
      return {
        name: match.displayName,
        value: formatter(match.value, dp.format)
      }
    }).filter(x => x)
  }, [data, datapoints, formatter])

  return {
    data,
    isLoading,
    formattedValues
  }
}

export const defaultColumnConfig = {
  columns: [
    { Header: 'Name', accessor: 'name' },
    { Header: 'Value', accessor: 'value' }
  ],
  defaultSort: [{ id: 'name', desc: false }]
}

function KDPTable ({ datapoints, className, style }) {
  const classes = useStyles()
  const { formattedValues, isLoading } = useMetrics(datapoints)

  return (
    <div className={clsx(className, classes.kdpTable)} style={style}>
      <PresentationTable
        data={formattedValues}
        loading={isLoading}
        columns={defaultColumnConfig.columns}
        defaultSort={defaultColumnConfig.defaultSort}
      />
    </div>
  )
}

KDPTable.propTypes = {
  datapoints: PropTypes.arrayOf(PropTypes.shape({
    code: PropTypes.string,
    format: PropTypes.string
  })),
  className: PropTypes.string,
  style: PropTypes.any
}

export default KDPTable
