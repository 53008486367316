import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

const ViewLink = React.forwardRef(function ViewLink ({ to, children, className }, ref) {
  return (
    <Link to={to} innerRef={ref} className={className}>
      {children}
    </Link>
  )
})

ViewLink.propTypes = {
  to: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node
}

export default ViewLink
