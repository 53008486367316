import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Container, Grid } from '@material-ui/core'
import { useOktaAuth } from '@okta/okta-react'
import { useFeatureFlag } from '../../../../redux/slices/appConfig'
import useIsDataHomeActive from '../../../../hooks/useIsDataHomeActive'
import {
  ASSETS,
  BILLING,
  REPORTS,
  INTEGRATIONS,
  DATA_HOME,
  ADVISOR,
  ROLES
} from '../../../../policies/admin'
import {
  ADMIN_ROUTES,
  DATA_HOME_ROUTES,
  FEATURE_FLAG,
  ICON_NAMES
} from '../../../../constants'
import { useAuthState, usePermissions, useCheckPolicy } from '../../../../hooks'
import CardTile from '../CardTile'
import { useCanViewDataMaintenance } from '../dataMaintenance'

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '1.5rem',
    '& svg': {
      fill: theme.palette.summitBlue
    }
  },
  content: {
    flexGrow: 1
  }
}))

function AdminView () {
  const classes = useStyles()
  const { authState: oktaAuthState } = useOktaAuth()
  const authState = useAuthState(oktaAuthState)
  const { isSummitUser } = usePermissions(authState.accessToken || {})
  const canViewAssets = useCheckPolicy(ASSETS.viewAssets)
  const canViewDataHome = useCheckPolicy(DATA_HOME.viewDataHome)
  const canRunReports = useCheckPolicy(REPORTS.runReports)
  const canRunBilling = useCheckPolicy(BILLING.runBilling)
  const canViewFeeSchedules = useCheckPolicy(BILLING.viewFeeSchedules)
  const canViewIntegrations = useCheckPolicy(INTEGRATIONS.viewIntegrations)
  const canViewAdvisor = useCheckPolicy(ADVISOR.viewAdvisor)
  const canViewRoles = useCheckPolicy(ROLES.viewRoles)
  const canViewClients = useCheckPolicy('admin_view_clients')
  const canViewAccounts = useCheckPolicy('admin_view_accts')
  const canViewManagers = useCheckPolicy('admin_view_managers')
  const canViewModelManagement = useCheckPolicy('admin_models_view')
  const assetClassificationFeature = useFeatureFlag(
    FEATURE_FLAG.ASSET_CLASSIFICATION_SETUP
  )
  const { active: isBillingActive } = useFeatureFlag(
    FEATURE_FLAG.SHOW_BILLING_INFO
  )
  const { active: isAdvisorActive } = useFeatureFlag(
    FEATURE_FLAG.ADVISOR_AND_ROLE_SETUP
  )
  const { active: isReportsActive } = useFeatureFlag(FEATURE_FLAG.REPORTS)
  const isDataHomeActive = useIsDataHomeActive()
  const {
    active: isIntegrationsActive,
    extras: { disableAdminView = false }
  } = useFeatureFlag(FEATURE_FLAG.INTEGRATIONS)
  const canViewStatusReports = useCheckPolicy('admin_view_status_templates')
  const canViewDataMaintenance = useCanViewDataMaintenance()

  const billingPath = !isBillingActive ? ADMIN_ROUTES.ADMIN : ADMIN_ROUTES.BILLING
  const integrationsPath =
    !isIntegrationsActive || disableAdminView
      ? ADMIN_ROUTES.ADMIN
      : ADMIN_ROUTES.INTEGRATIONS

  let index = 0

  return (
    <Container maxWidth='xl' className={classes.container}>
      <div className={classes.content}>
        <Grid container spacing={3}>
          {canViewClients ? (
            <Grid item xs={12} md={3}>
              <CardTile
                index={index++}
                to={`${ADMIN_ROUTES.CLIENTS}?tab=clients`}
                icon={ICON_NAMES.clients}
                title='Clients'
              />
            </Grid>
          ) : null}
          {canViewAccounts || canViewManagers ? (
            <Grid item xs={12} md={3}>
              <CardTile
                index={index++}
                to={ADMIN_ROUTES.ACCOUNTS}
                icon={ICON_NAMES.accounts}
                title='Accounts'
              />
            </Grid>
          ) : null}
          {isAdvisorActive && canViewAdvisor && (
            <Grid item xs={12} md={3}>
              <CardTile
                index={index++}
                to={ADMIN_ROUTES.ADVISORS}
                icon={ICON_NAMES.persons}
                title='Advisors'
              />
            </Grid>
          )}
          {isAdvisorActive && !canViewAdvisor && canViewRoles && (
            <Grid item xs={12} md={3}>
              <CardTile
                index={index++}
                to={ADMIN_ROUTES.ROLES}
                icon={ICON_NAMES.persons}
                title='Roles'
              />
            </Grid>
          )}
          {canViewAssets && (
            <Grid item xs={12} md={3}>
              <CardTile
                index={index++}
                to={ADMIN_ROUTES.CLASSIFY_ASSETS}
                icon={ICON_NAMES.folders}
                title='Assets'
                disabled={!assetClassificationFeature?.active}
              />
            </Grid>
          )}
          {isBillingActive && (canViewFeeSchedules || canRunBilling) && (
            <Grid item xs={12} md={3}>
              <CardTile
                index={index++}
                to={billingPath}
                icon={ICON_NAMES.bill}
                title='Billing'
              />
            </Grid>
          )}
          {isIntegrationsActive && !disableAdminView && canViewIntegrations && (
            <Grid item xs={12} md={3}>
              <CardTile
                index={index++}
                to={integrationsPath}
                icon={ICON_NAMES.layers}
                title='Integrations'
              />
            </Grid>
          )}
          {isReportsActive && canRunReports && (
            <Grid item xs={12} md={3}>
              <CardTile
                index={index++}
                to={ADMIN_ROUTES.REPORTS}
                icon={ICON_NAMES.file}
                title='Reports'
              />
            </Grid>
          )}
          {isSummitUser && (
            <Grid item xs={12} md={3}>
              <CardTile
                index={index++}
                to={ADMIN_ROUTES.ABUNDANCE_ENGINE_VIEWS}
                icon={ICON_NAMES.database}
                title='Abundance Engine Views'
              />
            </Grid>
          )}
          {isSummitUser && (
            <Grid item xs={12} md={3}>
              <CardTile
                index={index++}
                to={ADMIN_ROUTES.ABUNDANCE_ENGINE_GALLERY}
                icon={ICON_NAMES.database}
                title='Abundance Engine Gallery'
              />
            </Grid>
          )}
          {isDataHomeActive && canViewDataHome && (
            <Grid item xs={12} md={3}>
              <CardTile
                index={index++}
                to={DATA_HOME_ROUTES.DATA_HOME}
                icon={ICON_NAMES.dataHome}
                title='Data Home'
              />
            </Grid>
          )}
          {canViewStatusReports && (
            <Grid item xs={12} md={3}>
              <CardTile
                index={index++}
                to={ADMIN_ROUTES.STATUS_REPORTS}
                icon={ICON_NAMES.document}
                title='Status Reports'
              />
            </Grid>
          )}
          {canViewModelManagement && (
            <Grid item xs={12} md={3}>
              <CardTile
                index={index++}
                to={ADMIN_ROUTES.TARGET_MODELS}
                icon={ICON_NAMES.document}
                title='Models'
              />
            </Grid>
          )}
          {canViewDataMaintenance ? (
            <Grid item xs={12} md={3}>
              <CardTile
                index={index++}
                to={ADMIN_ROUTES.DATA_MAINTENANCE}
                icon={ICON_NAMES.database}
                title='Data Maintenance'
              />
            </Grid>
          ) : null}
        </Grid>
      </div>
    </Container>
  )
}

export default AdminView
