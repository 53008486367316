import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import ErrorBoundary from '../../../molecules/ErrorBoundary'
import { useFirmReportConfiguration, useFirmReportSummary } from '../../../../api/coreData'
import FirmReportName from '../FirmReportName'
import { useFormattingContext } from '../../../organisms/FormattingProvider/FormattingContext'
import SummaryGroup from './SummaryGroup'

const useStyles = makeStyles((theme) => ({
  header: {
    marginBottom: '20px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  right: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '20px'
  },
  summaryContent: {
    borderTop: `1px solid ${theme.palette.gray.dark}`,
    boxShadow: `0 5px 5px -5px ${theme.palette.gray.dark} inset`,
    paddingTop: '20px',
    display: 'flex',
    flexDirection: 'row',
    gap: '30px',
    justifyContent: 'center'
  }
}))

// This reconciles the settings in the configuration with the results from the data
function useReconciledSummary (configuration, data) {
  return useMemo(() => {
    if (!configuration?.summary) return null
    if (!data?.summary) return null

    const filterKeys = Object.entries(configuration.summary).reduce((result, [key, value]) => {
      const match = data.summary.find(s => s.key === key)
      const summaryItem = {
        ...match,
        metrics: Object.entries(value).reduce((res, [mKey, mValue]) => {
          const mMatch = (mKey in match.metrics) ? match.metrics[mKey] : null
          res.push({
            key: mKey,
            value: mMatch,
            ...mValue
          })
          return res
        }, [])
      }
      summaryItem.metrics.sort((a, b) => a.ordinal - b.ordinal)
      result.push(summaryItem)
      return result
    }, [])

    filterKeys.sort((a, b) => a.ordinal - b.ordinal)
    return filterKeys
  }, [data, configuration])
}

const useSummaryData = (reportId, pageIndex, pageSize) => {
  const { data: configuration, isLoading: configurationLoading, error: configurationError } = useFirmReportConfiguration(reportId)

  const dataQuery = useMemo(() => {
    if (!configuration) return null
    return {
      skip: pageIndex,
      take: pageSize
    }
  }, [configuration, pageIndex, pageSize])
  const { data, isFetching, error: dataError } = useFirmReportSummary(reportId, { enabled: !!dataQuery, ...dataQuery })

  const grouped = useReconciledSummary(configuration, data)

  return {
    configuration,
    data: grouped || [],
    isLoading: configurationLoading,
    isFetching,
    error: configurationError || dataError
  }
}

function FirmReportSummary ({ reportId }) {
  const classes = useStyles()
  const { formatter } = useFormattingContext()

  const { configuration, data, isLoading } = useSummaryData(reportId, 0, 10)

  if (isLoading) {
    return null
  }

  return (
    <div className={classes.summary}>
      <ErrorBoundary>
        <div className={classes.header}>
          <div>
            <FirmReportName configuration={configuration} />
            <div>As of {formatter(configuration.asOfDate, '@MM/DD/YYYY')}</div>
          </div>
        </div>
        <div className={classes.summaryContent}>
          {data.map(group => (
            <SummaryGroup key={group.key} group={group} reportId={reportId} />
          ))}
        </div>
      </ErrorBoundary>
    </div>
  )
}

FirmReportSummary.propTypes = {
  reportId: PropTypes.string
}

export default FirmReportSummary
