import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { useFormattingContext } from '../../../../organisms/FormattingProvider/FormattingContext'

const mapEngine = (engine) => {
  switch (engine?.toLowerCase()) {
    case 'lifeyield':
      return 'LifeYield'
    default:
      return 'Test Engine'
  }
}

const mapLotDisposition = (val) => {
  switch (val?.toLowerCase()) {
    case 'lyta':
      return 'Tax-Aware'
    case 'fifo':
      return 'FIFO'
    case 'hico':
      return 'Highest Cost'
    default:
      return '--'
  }
}

const useStyles = makeStyles((theme) => ({
  instructions: {
    marginTop: '10px',
    padding: '10px 25px',
    '& header': {
      marginBottom: '10px',
      fontSize: '.9rem'
    },
    '& > *:not(:last-child)': {
      borderBottom: `1px solid ${theme.palette.gray.dark}`,
      paddingBottom: '10px',
      marginBottom: '20px'
    }
  },
  detailTable: {
    width: '100%',
    fontWeight: 100,
    '& tr > td': {
      lineHeight: '22px'
    },
    '& tr > td:last-child': {
      textAlign: 'right'
    }
  },
  equiv: {
    border: `1px solid ${theme.palette.gray.dark}`,
    padding: '4px 7px',
    backgroundColor: theme.palette.gray.light,
    borderRadius: '5px'
  }
}))

const InstructionsContent = ({ model, moneyFormat, numberFormat, percentFormat }) => {
  const classes = useStyles()
  const { formatter } = useFormattingContext()
  const equivalents = useMemo(() => {
    if (!model.instructionData?.useEquivalents) {
      return null
    }

    return Object.entries(model.instructionData.equivalentGroup || {}).reduce((p, [key, value]) => {
      p.push({ key, value })
      return p
    }, [])
  }, [model.instructionData])

  return (
    <article className={classes.instructions}>
      <section>
        <header>{model.name}</header>
        <div>{model.description}</div>
        <table className={classes.detailTable}>
          <tbody>
            <tr>
              <td>Engine</td>
              <td>{mapEngine(model.rebalancingEngine)}</td>
            </tr>
            <tr>
              <td>Instruction Set</td>
              <td>{model.instructionName}</td>
            </tr>
          </tbody>
        </table>
      </section>
      <section>
        <header>Gain Limits</header>
        <table className={classes.detailTable}>
          <tbody>
            <tr>
              <td>Total Gain Limit</td>
              <td>{formatter(model.instructionData?.gainLimit, moneyFormat)}</td>
            </tr>
            <tr>
              <td>Short-Term Gain Limit</td>
              <td>{formatter(model.instructionData?.shortTermGainLimit, moneyFormat)}</td>
            </tr>
            <tr>
              <td>Long-Term Gain Limit</td>
              <td>{formatter(model.instructionData?.longTermGainLimit, moneyFormat)}</td>
            </tr>
          </tbody>
        </table>
      </section>
      <section>
        <header>Trading Inputs</header>
        <table className={classes.detailTable}>
          <tbody>
            <tr>
              <td>Minimum Trade Value</td>
              <td>{formatter(model.instructionData?.minimumTradeValue, moneyFormat)}</td>
            </tr>
            <tr>
              <td>Minimum Trade Quantity</td>
              <td>{formatter(model.instructionData?.minimumTradeQuantity, numberFormat)}</td>
            </tr>
            <tr>
              <td>Tax Lot Disposition Method</td>
              <td>{mapLotDisposition(model.instructionData?.lotDispositionMethod)}</td>
            </tr>
          </tbody>
        </table>
      </section>
      <section>
        <header>Other Instructions</header>
        <table className={classes.detailTable}>
          <tbody>
            <tr>
              <td>Use Cash First</td>
              <td>{model.instructionData?.useCashFirst ? 'Yes' : 'No'}</td>
            </tr>
            <tr>
              <td>Enable Equivalents</td>
              <td>{model.instructionData?.useEquivalents ? 'Yes' : 'No'}</td>
            </tr>
            <tr>
              <td>Capital Gains Tax Rate</td>
              <td>{formatter(model.instructionData?.capitalGainsTax, percentFormat)}</td>
            </tr>
            <tr>
              <td>Ordinary Income Tax</td>
              <td>{formatter(model.instructionData?.ordinaryIncomeTax, percentFormat)}</td>
            </tr>
          </tbody>
        </table>
      </section>
      {equivalents === null ? null : (
        <section>
          <header>Equivalents</header>
          <table className={classes.detailTable}>
            <tbody>
              {equivalents.map(e => (
                <tr key={e.key}>
                  <td><span className={classes.equiv}>{e.key}</span></td>
                  <td>=</td>
                  <td><span className={classes.equiv}>{e.value}</span></td>
                </tr>
              ))}
            </tbody>
          </table>
        </section>
      )}
    </article>
  )
}

InstructionsContent.Loading = () => {
  return (
    <div>Loading....</div>
  )
}

InstructionsContent.propTypes = {
  model: PropTypes.object,
  moneyFormat: PropTypes.string,
  numberFormat: PropTypes.string,
  percentFormat: PropTypes.string
}

InstructionsContent.defaultProps = {
  moneyFormat: '$0,0.00',
  numberFormat: '0,0',
  percentFormat: '0.0%'
}

export default InstructionsContent
