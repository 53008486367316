import React from 'react'
import PropTypes from 'prop-types'
import Metric from '../Metric'
import TransactionMetric from './TransactionMetric'

const TransactionsMetric = ({
  redirectTo,
  ...metricProps
}) => {
  return (
    <Metric.Wrapper redirectTo={redirectTo}>
      <TransactionMetric {...metricProps} />
    </Metric.Wrapper>
  )
}

TransactionsMetric.propTypes = {
  redirectTo: PropTypes.string
}

TransactionsMetric.defaultProps = {
  redirectTo: null
}

export default TransactionsMetric
