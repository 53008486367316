import React, { useMemo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Container,
  Grid
} from '@material-ui/core'
import { useCheckPolicy } from '../../../hooks'
import { ADMIN_ROUTES } from '../../../constants'
import Text from '../../atoms/Text'
import { BILLING } from '../../../policies/admin'
import CardTile from './CardTile'

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '1.5rem'
  },
  content: {
    flexGrow: 1
  },
  header: {
    marginBottom: '1.25rem'
  }
}))

const BillingView = () => {
  const classes = useStyles()
  const canViewFeeSchedules = useCheckPolicy(BILLING.viewFeeSchedules)
  const canRunBilling = useCheckPolicy(BILLING.runBilling)
  const clientReview = useCheckPolicy(BILLING.clientReview)
  const canViewDetails = useCheckPolicy(BILLING.viewDetails)

  const GridItem = ({ to, name, title }) => useMemo(() => (
    <Grid item xs={12} md={3}>
      <CardTile to={to} icon={name} title={title} />
    </Grid>
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ), [classes])

  return (
    <Container maxWidth='xl' className={classes.container}>
      <Text text='Billing' variant='h1' className={classes.header} />
      <div className={classes.content}>
        <Grid container spacing={3}>
          {canViewFeeSchedules && <GridItem to={ADMIN_ROUTES.BILLING_FEE_SCHEDULES} name='document' title='Fee Schedules' />}
          {canRunBilling && <GridItem to={ADMIN_ROUTES.BILLING_RUN} name='currency' title='Run Billing' />}
          {clientReview && <GridItem to={ADMIN_ROUTES.BILLING_CLIENT_REVIEW} name='accountCircle' title='Client Review' />}
          {canViewDetails && <GridItem to={ADMIN_ROUTES.BILLING_RUN_DETAILS} name='bill' title='Billing Run Details' />}
        </Grid>
      </div>
    </Container>
  )
}

export default BillingView
