import PropTypes from 'prop-types'
import React, { useCallback } from 'react'
import Select from '../../../molecules/Select'
import { BUTTON_SIZES } from '../../../../constants'
import { useChartOptions } from './ChartOptionsProvider'

function IntervalSelect ({
  options
}) {
  const { options: values, setOptions } = useChartOptions()
  const setValue = useCallback((value) => {
    setOptions({
      interval: value
    })
  }, [setOptions])

  return (
    <Select
      size={BUTTON_SIZES.small}
      value={values?.interval}
      onChange={setValue}
      readOnly={options.length <= 1}
      options={options}
    />
  )
}

IntervalSelect.propTypes = {
  options: PropTypes.array
}

IntervalSelect.defaultProps = {
  options: [
    { label: 'Auto', value: 'auto' },
    { label: 'Annually', value: 'year' },
    { label: 'Quarterly', value: 'quarter' },
    { label: 'Monthly', value: 'month' },
    { label: 'Daily', value: 'day' }
  ],
  defaultValue: 'auto'
}

export default IntervalSelect
