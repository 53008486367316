import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(() => ({
  container: {
    flex: '1 1 0%',
    width: '100%',
    margin: 'inherit',
    display: 'flex',
    padding: '30px 20px 0px',
    flexWrap: 'nowrap',
    alignItems: 'center',
    justifyContent: 'center'
  },
  content: {
    width: '100%',
    display: 'flex',
    padding: '0px 40px',
    flexFlow: 'column wrap',
    maxWidth: '1600px',
    alignItems: 'center',
    justifyContent: 'center'
  }
}))

const DefaultLayout = ({ children }) => {
  const classes = useStyles()
  return (
    <div className={classes.container}>
      <div className={classes.content}>{children}</div>
    </div>
  )
}

DefaultLayout.propTypes = {
  children: PropTypes.node.isRequired
}

export default DefaultLayout
