const status = {
  DEFAULT: 'default',
  LOADING: 'loading',
  ERROR: 'error',
  DONE: 'done'
}

export const loadable = {
  default: () => ({
    status: status.DEFAULT
  }),
  loading: (init = null) => ({
    status: status.LOADING,
    value: init
  }),
  error: (err) => ({
    status: status.ERROR,
    error: err
  }),
  done: (value) => ({
    status: status.DONE,
    value
  }),
  isLoading: maybeLoadable => maybeLoadable?.status === status.LOADING,
  isError: maybeLoadable => maybeLoadable?.status === status.ERROR,
  isDone: maybeLoadable => maybeLoadable?.status === status.DONE,
  getError (maybeLoadable) {
    return this.isError(maybeLoadable) && maybeLoadable.error
  },
  getValue (maybeLoadable) {
    return this.isDone(maybeLoadable) && maybeLoadable.value
  }
}
