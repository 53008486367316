import React from 'react'
import { SKELETON_VARIANTS } from '../../../../../constants'
import Skeleton from '../../../../atoms/Skeleton'
import { useStyles } from '.'

const ClientValuesCardSkeleton = () => {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <Skeleton
        height='4.5rem'
        width='4.5rem'
        variant={SKELETON_VARIANTS.circle}
      />
      <Skeleton height='2.5rem' variant={SKELETON_VARIANTS.text} />
      <Skeleton height='1.5rem' variant={SKELETON_VARIANTS.text} />
    </div>
  )
}

export default ClientValuesCardSkeleton
