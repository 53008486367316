import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles((theme) => ({
  squaredButton: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '80px',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '8px',
    padding: '8px 16px',
    fontSize: '14px',
    cursor: 'pointer',
    opacity: 1,
    gap: '8px',
    transitionDuration: '0.2s',
    '&:hover': {
      opacity: 0.8
    }
  },
  routeSelection: {
    border: `2px dashed ${theme.palette.primary.main ?? theme.palette.summitBlue}`,
    padding: '1rem',
    height: '100%',
    '&:hover': {
      color: theme.palette.getContrastText(theme.palette.primary.main ?? theme.palette.summitBlue),
      backgroundColor: theme.palette.primary.main ?? theme.palette.summitBlue,
      borderColor: theme.palette.primary.main ?? theme.palette.summitBlue,
      opacity: 1
    }
  },
  modalInner: {
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
    gap: '16px'
  },
  modalInnerHeader: {
    textAlign: 'center'
  },
  modalInnerContent: {
    flex: 1,
    maxHeight: '100%'
  },
  modalInnerFooter: {
    display: 'flex',
    justifyContent: 'center',
    gap: '16px',
    padding: '10px 0 2px'
  },
  otherCategoriesTitle: {
    margin: '0 0 16px'
  },
  colorCircle: {
    width: '16px',
    height: '16px',
    borderRadius: '50%'
  },
  divider: {
    margin: '0 0 16px'
  }
}))

export default useStyles
