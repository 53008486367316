import React from 'react'
import PropTypes from 'prop-types'
import { BasicTooltip } from '@nivo/tooltip'

function RangeTooltip ({ datum }) {
  return (
    <BasicTooltip
      id={datum.id}
      enableChip
      color={datum.color}
    />
  )
}

RangeTooltip.propTypes = {
  datum: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    formattedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    color: PropTypes.string
  })
}

export default RangeTooltip
