import { noop } from 'lodash'
import { createContext, useContext } from 'react'

export const MilestonesContext = createContext({
  milestones: [],
  selectedDateRange: null,
  setDateRange: noop
})

export const useMilestonesContext = () => {
  const contextValue = useContext(MilestonesContext)
  return contextValue
}
