import React from 'react'
import FadeIn from '../../../../molecules/Transitions/FadeIn'
import ErrorBoundary from '../../../../molecules/ErrorBoundary'
import AssignmentSection from './AssignmentSection'

function StatusReportTab () {
  return (
    <ErrorBoundary name='Status Template Tab'>
      <FadeIn>
        <AssignmentSection />
      </FadeIn>
    </ErrorBoundary>
  )
}

StatusReportTab.propTypes = {
}

export default StatusReportTab
