import { useCallback, useMemo, useState } from 'react'

const useFilters = ({ searchFields }) => {
  const [selectedFilters, setSelectedFilters] = useState({})
  const [searchValue, setSearchValue] = useState('')

  const applyFilters = useCallback((data) => {
    return data.filter(d => {
      const matchesSelectedFilters = Object.entries(selectedFilters).reduce((acc, curr) => {
        if (!acc) return acc

        const [key, values] = curr

        return values.indexOf((d[key])) >= 0
      }, true)

      let matchesSearchInput = true
      const searchVal = searchValue.toLowerCase()
      if (searchFields?.length && searchValue) {
        matchesSearchInput = searchFields.reduce(
          (acc, curr) => acc || d[curr]?.toLowerCase()?.includes(searchVal),
          false
        )
      }

      return matchesSelectedFilters && matchesSearchInput
    })
  }, [selectedFilters, searchFields, searchValue])

  const onChangeFilters = useCallback((type) => (value) => {
    setSelectedFilters(({
      [type]: _,
      ...prevState
    }) => value.length
      ? ({
        ...prevState,
        [type]: value?.map(v => v.value) ?? []
      })
      : prevState)
  }, [setSelectedFilters])

  const getSelectedFilters = useCallback((type) => {
    return selectedFilters[type]
  }, [selectedFilters])

  const onChangeSearch = useCallback((searchValue) => {
    setSearchValue(searchValue)
  }, [setSearchValue])

  const onClearSearch = useCallback(() => {
    onChangeSearch('')
  }, [onChangeSearch])

  return useMemo(() => ({
    applyFilters,
    onChangeFilters,
    getSelectedFilters,
    onChangeSearch,
    onClearSearch
  }), [applyFilters, onChangeFilters, getSelectedFilters, onChangeSearch, onClearSearch])
}

export default useFilters

/**
 * @typedef FilterOptions
 * @property {string[]} searchFields
 */
