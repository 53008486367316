import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import VarianceMetric from './VarianceMetric'
import ValueMetric from './ValueMetric'
import CashAvailableMetric from './AvailableCashMetric'

const useStyles = makeStyles((theme) => ({
  modelStats: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    margin: '1rem 0',
    '& > .__loaded:not(:first-child)': {
      borderLeft: `2px solid ${theme.palette.gray.dark}`
    },
    '& > *:first-child': {
      paddingLeft: '0 !important'
    },
    '& .MuiButton-text': {
      textTransform: 'none'
    }
  }
}))

function ModelStats ({ avtData, cashData, loading, includeVariance, includeValue, includeCash }) {
  const classes = useStyles()

  return (
    <section className={classes.modelStats}>
      {includeVariance ? <VarianceMetric avtData={avtData} loading={loading} /> : null}
      {includeValue ? <ValueMetric cashData={cashData} loading={loading} /> : null}
      {includeCash ? <CashAvailableMetric cashData={cashData} loading={loading} /> : null}
    </section>
  )
}

ModelStats.propTypes = {
  avtData: PropTypes.object,
  cashData: PropTypes.object,
  loading: PropTypes.bool,
  includeVariance: PropTypes.bool,
  includeValue: PropTypes.bool,
  includeCash: PropTypes.bool
}

export default ModelStats
