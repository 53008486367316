import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import noop from 'lodash/noop'

const useStyles = makeStyles(() => ({
  parent: {
    position: 'relative',
    '&:hover': {
      cursor: 'pointer'
    },
    '& > .hover': {
      position: 'absolute',
      top: 0,
      opacity: 0,
      '&:hover': {
        opacity: 1
      }
    },
    '& > .no-hover': {
      opacity: 1,
      '&:hover': {
        opacity: 0
      }
    }
  }
}))

function Remove ({ children, onClick }) {
  const classes = useStyles()

  return (
    <div className={classes.parent} onClick={onClick}>
      {children}
    </div>
  )
}

function Hover ({ children }) {
  return (
    <div className='hover'>{children}</div>
  )
}

function NoHover ({ children }) {
  return (
    <div className='no-hover'>{children}</div>
  )
}

Remove.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func
}

Remove.defaultProps = {
  onClick: noop
}

Hover.propTypes = {
  children: PropTypes.node
}

NoHover.propTypes = {
  children: PropTypes.node
}

Remove.Hover = Hover
Remove.NoHover = NoHover

export default Remove
