import { makeStyles } from '@material-ui/core'
import React, { useCallback } from 'react'
import { useContextContainer } from '../../../../abundanceEngine/components/ContextContainer'
import { CLIENT_VIEW_CONTEXT } from '..'
import ViewTypeToggle from './ViewTypeToggle'

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    '& .header-actions': {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row'
    }
  },
  title: {
    fontWeight: 'bold',
    fontSize: '2.5rem'
  }
}))

const ClientViewTypeToggle = () => {
  const classes = useStyles()
  const [{ viewType }, setClientViewContext] = useContextContainer(CLIENT_VIEW_CONTEXT)

  const onChangeViewType = useCallback((selectedViewType) => {
    if (!selectedViewType) return
    setClientViewContext(prevState => ({ ...prevState, viewType: selectedViewType }))
  }, [setClientViewContext])

  return (
    <div className={classes.container}>
      <div className='header-actions'>
        <ViewTypeToggle value={viewType} onChange={onChangeViewType} />
      </div>
    </div>
  )
}

export default ClientViewTypeToggle
