import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  empty: {
    display: 'flex',
    width: '100%',
    height: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '20px',
    padding: '50px'
  }
}))

const EmptyState = () => {
  const classes = useStyles()

  return (
    <div className={classes.empty}>
      To get started, select a conversation or create a new one
    </div>
  )
}

export default EmptyState
