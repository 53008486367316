import React, { forwardRef, useCallback, useEffect, useMemo, useState } from 'react'
import { MenuItem, Select } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'

const useStyles = makeStyles((theme) => {
  const optionGroupStyles = {
    '& > header': {
      fontSize: '10px',
      marginBottom: '3px'
    },
    '& .MuiInputBase-input': {
      fontSize: '14px',
      lineHeight: '14px',
      paddingTop: '7px',
      minWidth: '180px'
    },
    '& .MuiInputBase-input.Mui-disabled': {
      color: `${theme.palette.gray.dark} !important`
    }
  }

  return (
    {
      options: {
        display: 'flex',
        flexDirection: 'row',
        gap: '20px',
        margin: '0 0 20px',
        justifyContent: 'center'
      },
      optionGroup: {
        ...optionGroupStyles
      },
      taxFilterGroup: ({ taxFilterSelectable }) => ({
        ...optionGroupStyles,
        color: taxFilterSelectable ? optionGroupStyles.color : `${theme.palette.gray.dark} !important`
      })
    }
  )
})

const RaiseCashOptions = forwardRef(({ loading }, ref) => {
  const [mode, setMode] = useState('opportunistic')
  const [scope, setScope] = useState('full')
  const [taxabilityType, setTaxabilityType] = useState(3)
  const handleSelect = useCallback((setter) => (e) => {
    setter(e.target.value)
  }, [])
  useEffect(() => {
    if (ref) {
      ref.current = { mode, scope, taxabilityType }
    }
  }, [ref, mode, scope, taxabilityType])
  const taxFilterSelectable = useMemo(() => scope === 'taxStatus', [scope])
  const classes = useStyles({ taxFilterSelectable })

  if (loading) return null

  return (
    <section className={classes.options}>
      <div className={classes.optionGroup}>
        <header>Rebalance Mode</header>
        <Select defaultValue='opportunistic' label='Rebalancing Mode' onChange={handleSelect(setMode)} value={mode}>
          <MenuItem value='full'>Full Rebalance</MenuItem>
          <MenuItem value='opportunistic'>Opportunistic Rebalance</MenuItem>
          <MenuItem value='none'>No Rebalance</MenuItem>
        </Select>
      </div>
      <div className={classes.optionGroup}>
        <header>Scope</header>
        <Select defaultValue='full' label='Scope' onChange={handleSelect(setScope)} value={scope}>
          <MenuItem value='full'>All Accounts</MenuItem>
          <MenuItem value='taxStatus'>Tax-Status-Limited</MenuItem>
        </Select>
      </div>
      <div className={classes.taxFilterGroup}>
        <header>Tax Filter</header>
        <Select
          defaultValue={3}
          label='Tax Status'
          onChange={handleSelect(setTaxabilityType)}
          value={taxabilityType}
          disabled={!taxFilterSelectable}
        >
          <MenuItem value={1}>Taxable</MenuItem>
          <MenuItem value={2}>Tax-Deferred</MenuItem>
          <MenuItem value={3}>Non-Taxable</MenuItem>
        </Select>
      </div>
    </section>
  )
})

RaiseCashOptions.propTypes = {
  loading: PropTypes.bool
}

export default RaiseCashOptions
