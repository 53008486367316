import isEmpty from 'lodash/isEmpty'
import { useMemo } from 'react'
import { useMediaUrl } from '../api/media'
import { useCurrentUser as useFetchCurrentUser } from '../api/users'
import useIdentity from './useIdentity'

const useCurrentUser = () => {
  const { data, isLoading } = useFetchCurrentUser()

  const hasMedia = Boolean(!isEmpty(data) && data?.avatarMediaId)

  const { mediaId, mediaQueryOptions } = useMemo(() => {
    return {
      mediaId: data?.avatarMediaId,
      mediaQueryOptions: {
        enabled: hasMedia
      }
    }
  }, [data, hasMedia])

  const { data: mediaUrl, isLoading: isLoadingMediaUrl } = useMediaUrl(
    mediaId,
    mediaQueryOptions
  )

  const userIdentity = useIdentity()

  const isTransitioning = isLoading || (isLoadingMediaUrl && hasMedia)

  return {
    data: isTransitioning
      ? undefined
      : { ...userIdentity, ...data, profilePictureUrl: mediaUrl },
    isLoading: isTransitioning
  }
}

export default useCurrentUser
