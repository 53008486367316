import React, { useState } from 'react'
import { animated, useSpring } from 'react-spring'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  container: ({ styles }) => ({
    position: 'relative',
    margin: '5px',
    cursor: 'pointer',
    zIndex: 100,
    ...styles
  }),
  card: () => ({
    boxShadow: '0px 10px 10px -5px rgba(0,0,0,0.05)',
    backgroundColor: theme.palette.common.white,
    padding: '10px',
    borderRadius: '10px',
    cursor: 'pointer',
    border: `2px solid ${theme.palette.gray.main}`,
    width: '100%',
    height: '100%',
    display: 'flex'
  })
}))

const Preview = ({
  children,
  onPreviewClick,
  previewId,
  styles
}) => {
  const classes = useStyles({ styles })
  const [isHovered, setIsHovered] = useState(false)

  const onMouseEnter = () => setIsHovered(true)
  const onMouseLeave = () => setIsHovered(false)
  const onClickPreview = (e) => {
    onPreviewClick({ activeId: previewId, posX: e.pageX, posY: e.pageY })
  }

  const { border } = useSpring({
    from: {
      border: '2px solid rgba(33, 41, 69, 0.2)'
    },
    to: {
      border: isHovered ? '2px solid rgba(33, 41, 69, 0.8)' : '2px solid rgba(33, 41, 69, 0.2)'
    }
  })

  return (
    <animated.div
      className={clsx(classes.container, classes.card)}
      style={{ border }}
      onClick={onClickPreview}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {children}
    </animated.div>
  )
}

Preview.propTypes = {
  children: PropTypes.node.isRequired,
  previewId: PropTypes.string.isRequired,
  onPreviewClick: PropTypes.func,
  styles: PropTypes.object
}

Preview.defaultProps = {
  onPreviewClick: noop,
  onClosePreview: noop
}

export default Preview
