import React from 'react'
import { makeStyles } from '@material-ui/core'
import { useTasksContext } from '../TasksContext'
import { VIEW_TYPES } from '../../ClientCardsViewV2/helpers'
import ViewTab from '../../../organisms/AdvisorView/components/AdvisorViewTabs/index'
import TasksViewHeader from './TasksViewHeader'
import TasksTable from './TasksTable'
import TasksCards from './TasksCards'
import TasksTabContent from './TasksTabContent'

const useStyles = makeStyles(() => ({
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column'
  },
  content: {
    padding: '1rem 0'
  }
}))

const TasksViewContent = () => {
  const classes = useStyles()
  const { viewType, tabOptions } = useTasksContext()

  return (
    <div className={classes.container}>
      <TasksViewHeader />
      <ViewTab.Group tabOptions={tabOptions}>
        <ViewTab.List />
        <TasksTabContent>
          <div className={classes.content}>
            {viewType === VIEW_TYPES.LIST && <TasksTable />}
            {viewType === VIEW_TYPES.BLOCKS && <TasksCards />}
          </div>
        </TasksTabContent>
      </ViewTab.Group>
    </div>
  )
}

export default TasksViewContent
