import { DATE_PRESET } from '../../../../constants'

export const DATE_RANGE_OPTIONS = [
  { label: 'Last 7 Days', value: DATE_PRESET.L7D },
  { label: 'Last 30 Days', value: DATE_PRESET.L30 },
  { label: 'Month to Date', value: DATE_PRESET.MD },
  { label: 'Quarter to Date', value: DATE_PRESET.QD },
  { label: 'Year to Date', value: DATE_PRESET.YD },
  { label: 'Custom Range', value: null, disabled: true }
]

export const EXPOSURE_REPORT_CONTEXT_KEY = 'EXPOSURE_REPORT_CONTEXT_KEY'

export const EXPOSURE_TYPES = {
  ASSET: 'asset',
  CLASSIFICATION_TAG: 'classificationTag'
}

export const DATA_SET_TYPES = {
  BALANCE: 'balance',
  BALANCE_AND_PERFORMANCE: 'balanceAndPerformance'
}

export const EXPOSURE_TYPE_ID_KEY = {
  [EXPOSURE_TYPES.ASSET]: 'assetId',
  [EXPOSURE_TYPES.CLASSIFICATION_TAG]: 'classificationTagId'
}

export const getAssetExposureName = (exposureType, asset) => {
  if (exposureType === EXPOSURE_TYPES.ASSET) {
    return `${asset?.assetIdentifier} - ${asset?.longName}`
  }
  if (exposureType === EXPOSURE_TYPES.CLASSIFICATION_TAG) {
    return asset.longName
  }
  return null
}

export const levelDatesQuery = { levelType: 'user' }
