import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import { useLocation } from 'react-router-dom'
import RelativeLink from '../../../atoms/RelativeLink'
import { mergeSearchParams } from '../../../../utils'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    padding: '1rem',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column'
    }
  },
  clickable: {
    cursor: 'pointer'
  },
  link: {
    textDecoration: 'none !important',
    '& > *': {
      textDecoration: 'none !important'
    }
  }
}))

const MetricWrapper = ({
  children,
  className,
  redirectTo,
  preserveSearchParams
}) => {
  const classes = useStyles()
  const location = useLocation()

  const containerClassName = clsx(classes.container, {
    [className]: Boolean(className),
    [classes.clickable]: Boolean(redirectTo)
  })

  const { to, searchParams } = useMemo(() => {
    if (!preserveSearchParams) {
      return location.search
    }
    if (!redirectTo) return { to: null }
    const [to, urlParams] = redirectTo?.split('?')
    const params = new URLSearchParams(urlParams)
    const currentParams = new URLSearchParams(location.search)
    const searchParams = mergeSearchParams(params, currentParams).toString()
    return { to, searchParams }
  }, [
    redirectTo,
    location.search,
    preserveSearchParams
  ])

  if (to) {
    return (
      <RelativeLink
        to={{ pathname: to, search: searchParams }}
        className={classes.link}
      >
        <div className={containerClassName}>{children}</div>
      </RelativeLink>
    )
  }

  return (
    <div className={classes.link}>
      <div className={containerClassName}>{children}</div>
    </div>
  )
}

MetricWrapper.propTypes = {
  redirectTo: PropTypes.string,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  preserveSearchParams: PropTypes.bool
}

MetricWrapper.defaultProps = {
  preserveSearchParams: true
}

export default MetricWrapper
