import clsx from 'clsx'
import React from 'react'
import PropTypes from 'prop-types'
import { TableCell, TableSortLabel } from '@material-ui/core'
import { TEXT_VARIANTS, TEXT_ALIGNMENTS } from '../../constants'
import { simpleTableLabelDefaults } from '../../prop-types/tables'
import { selectPropTypes } from '../../prop-types'
import { useCellStyles } from './TableCell'
import Text from './Text'
import Select from './Select'

const {
  alignment: defaultLabelAlignment,
  colSpan: defaultLabelColSpan
} = simpleTableLabelDefaults

const TableCellHeader = ({
  name,
  index,
  order,
  width,
  orderBy,
  hidden,
  spacing,
  isSelect,
  sorteable,
  isLastCell,
  selectProps,
  tableVariant,
  elementType,
  virtualized,
  handleRequestSort,
  classes: _classes,
  colSpan = defaultLabelColSpan,
  alignment = defaultLabelAlignment,
  variant = TEXT_VARIANTS.subtitle2
}) => {
  const classes = Object.assign(
    {},
    useCellStyles({ virtualized, width, alignment, variant: tableVariant }),
    _classes
  )

  if (hidden) {
    return null
  }

  const isSortable = sorteable && colSpan === 1

  return (
    <TableCell
      align={alignment}
      colSpan={colSpan}
      variant={tableVariant}
      elementType={elementType}
      style={{ width: spacing }}
      className={clsx(classes.cell, {
        [classes.noPadding]: true,
        [classes.firstCell]: index === 0,
        [classes.lastCell]: isLastCell
      })}
      sortDirection={isSortable && orderBy === index ? order : false}
    >
      {isSortable ? (
        <TableSortLabel
          className={classes.headSortLabel}
          active={orderBy === index}
          direction={orderBy === index && order === 'asc' ? 'asc' : 'desc'}
          onClick={() => handleRequestSort(index)}
        >
          <Text text={name} variant={variant} />
        </TableSortLabel>
      ) : isSelect ? (
        <Select
          defaultValue={selectProps.defaultValue}
          options={selectProps.options}
          onChange={selectProps.onChange}
          selectedValue={selectProps.selectedValue}
          closestValueFinder={selectProps.closestValueFinder}
          textVariant={variant}
        />
      ) : (
        <div className={classes.headLabel}>
          <Text text={name} variant={variant} />
        </div>
      )}
    </TableCell>
  )
}

TableCellHeader.propTypes = {
  name: PropTypes.string,
  index: PropTypes.number,
  order: PropTypes.string,
  width: PropTypes.string,
  orderBy: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  hidden: PropTypes.bool,
  spacing: PropTypes.string,
  isSelect: PropTypes.bool,
  sorteable: PropTypes.bool,
  isLastCell: PropTypes.bool,
  selectProps: selectPropTypes,
  elementType: PropTypes.string,
  virtualized: PropTypes.bool,
  handleRequestSort: PropTypes.func,
  classes: PropTypes.object,
  colSpan: PropTypes.number,
  tableVariant: PropTypes.string,
  alignment: PropTypes.oneOf(Object.keys(TEXT_ALIGNMENTS)),
  variant: PropTypes.oneOf(Object.keys(TEXT_VARIANTS))
}

export default TableCellHeader
