import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import ResponsiveGauge from '../../nivo/Gauge/ResponsiveGauge'
import { useAppContext } from '../../../redux/slices/appContext'
import { useSearchClients } from '../../../api/clients'
import { useGroupTypeSearch } from '../../../api/groups'
import FadeIn from '../../molecules/Transitions/FadeIn'
import Skeleton from '../../atoms/Skeleton'

const useStyles = makeStyles((theme) => ({
  keyDatapointGauge: {
    height: '100%',
    '&.__empty': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: '22px'
    }
  }
}))

const useGaugeData = (groupTypeId, keyDatapoint, otherProps = {}) => {
  const { clientId } = useAppContext()
  const { markerColor, valueFormat, markerRadius } = otherProps
  const queries = useMemo(() => {
    return {
      client: {
        limit: 1,
        filters: {
          clientId: [{ op: 'eq', value: clientId }]
        },
        includes: {
          keyDatapoints: [keyDatapoint]
        }
      },
      groupType: {
        limit: 1,
        filters: {
          groupTypeId: [{ op: 'eq', value: groupTypeId }]
        },
        includes: {
          groupBreakpoints: true
        }
      }
    }
  }, [groupTypeId, keyDatapoint, clientId])
  const clientResponse = useSearchClients(queries.client)
  const groupTypeResponse = useGroupTypeSearch(queries.groupType)

  const marker = useMemo(() => {
    if (clientResponse.isLoading) return null
    const c = clientResponse.data?.data.length ? clientResponse.data.data.at(0) : null
    if (!c) return null
    const dp = keyDatapoint in c.keyDatapoints ? c.keyDatapoints[keyDatapoint] : null
    if (!dp) return null

    return {
      id: dp.displayName,
      value: dp.value,
      color: markerColor,
      format: valueFormat,
      radius: markerRadius
    }
  }, [clientResponse.data, clientResponse.isLoading, keyDatapoint, markerColor, markerRadius, valueFormat])

  const ranges = useMemo(() => {
    if (groupTypeResponse.isLoading) return null
    const groupType = groupTypeResponse.data?.data.length ? groupTypeResponse.data.data.at(0) : null
    if (groupType === null) return null

    return groupType.groupBreakpoints?.map(bp => ({
      id: bp.breakpointName,
      start: +bp.lowerLimit,
      end: +bp.upperLimit,
      color: bp.colorField
    })) ?? null
  }, [groupTypeResponse.data, groupTypeResponse.isLoading])

  return {
    marker,
    ranges,
    isLoading: clientResponse.isLoading || groupTypeResponse.isLoading,
    error: clientResponse.error || groupTypeResponse.error
  }
}

function KeyDatapointGauge ({
  style,
  className,
  groupTypeId,
  keyDatapoint,
  noDataMessage,
  ...otherProps
}) {
  const classes = useStyles()
  const { marker, ranges, isLoading } = useGaugeData(groupTypeId, keyDatapoint, otherProps)

  if (isLoading) {
    return (
      <FadeIn duration={5} delay={5} style={style} className={clsx(className, classes.keyDatapointGauge)}>
        <Skeleton width='100%' height='100%' />
      </FadeIn>
    )
  }

  if (!marker || !ranges) {
    return (
      <FadeIn style={style} className={clsx(className, classes.keyDatapointGauge, '__empty')}>
        <div>{noDataMessage}</div>
      </FadeIn>
    )
  }

  return (
    <FadeIn duration={1} style={style} className={clsx(className, classes.keyDatapointGauge)}>
      <ResponsiveGauge
        marker={marker}
        ranges={ranges}
        {...otherProps}
      />
    </FadeIn>
  )
}

KeyDatapointGauge.propTypes = {
  style: PropTypes.any,
  className: PropTypes.string,
  groupTypeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  keyDatapoint: PropTypes.string,
  markerColor: PropTypes.string,
  markerRadius: PropTypes.number,
  noDataMessage: PropTypes.string,
  valueFormat: PropTypes.string
}

KeyDatapointGauge.defaultProps = {
  markerColor: 'red',
  markerRadius: 20,
  noDataMessage: 'No Data Available',
  valueFormat: 'percentage'
}

export default KeyDatapointGauge
