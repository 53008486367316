import React, { useImperativeHandle, useState } from 'react'
import { Grid } from '@material-ui/core'
import SydModal from '../../../commonDesign/SydModal'
import TransactionsTable from '../TransactionsTable'

const TransactionsPopUpDialog = React.forwardRef(function (props, ref) {
  const [isOpen, setIsOpen] = useState(false)

  useImperativeHandle(ref, () => ({
    open: () => {
      setIsOpen(true)
    }
  }), [setIsOpen])

  return (
    <SydModal
      title='View Transactions'
      open={isOpen}
      onClose={() => setIsOpen(false)}
      size='full'
      bodyPadding={0}
    >
      <Grid container spacing={1}>
        <Grid item xs={12} style={{ padding: '0 20px' }}>
          <TransactionsTable />
        </Grid>
      </Grid>
    </SydModal>
  )
})

TransactionsPopUpDialog.propTypes = {
}

TransactionsPopUpDialog.defaultProps = {
}

export default TransactionsPopUpDialog
