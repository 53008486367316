import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import OperationalTable from '../../../organisms/OperationalTable'
import { useGroupingContext } from '../../../organisms/GroupingProvider/GroupingContext'
import { useAutoOperationalTableContext } from '../../../organisms/OperationalTable/AutoOperationalTableContext'
import { useClassifyAssetsContext } from './ClassifyAssetsContext'

const useStyles = makeStyles(() => ({
  header: {
    paddingTop: '1rem',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  }
}))

const ClassifyAssetsTable = ({ header, onClickRow }) => {
  const classes = useStyles()

  const {
    assets,
    isLoading,
    columnConfig,
    operationalTable,
    isLoadingClassificationTagTypes
  } = useClassifyAssetsContext()

  const { setPerformanceTableProps } = useGroupingContext()
  const { tableProps } = useAutoOperationalTableContext()

  const tableColsInSync =
      columnConfig.columns.length <= tableProps?.allColumns?.length

  useEffect(() => {
    if (!isLoading && !isLoadingClassificationTagTypes && tableColsInSync) {
      setPerformanceTableProps(tableProps)
    }
  }, [
    tableProps,
    isLoading,
    tableColsInSync,
    isLoadingClassificationTagTypes,
    setPerformanceTableProps
  ])

  useEffect(() => {
    if (tableProps?.setHiddenColumns) {
      const hiddenColumns = columnConfig.columns.reduce(
        (acc, col) => (col.hidden ? [...acc, col.id] : acc),
        []
      )
      tableProps.setHiddenColumns(hiddenColumns)
    }
  }, [tableProps, columnConfig.columns])

  const {
    searchText,
    onTableModeChange,
    onSortingChange,
    defaultPageSize,
    onPagingChange
  } = operationalTable

  return (
    <>
      <OperationalTable.SuperHeader className={classes.headspace}>
        {header}
      </OperationalTable.SuperHeader>
      <OperationalTable
        mode='server'
        itemName='Assets'
        data={assets}
        loading={isLoading}
        searchText={searchText}
        onRowClick={onClickRow}
        columns={columnConfig.columns}
        onPagingChange={onPagingChange}
        defaultPageSize={defaultPageSize}
        onSortingChange={onSortingChange}
        onTableModeChange={onTableModeChange}
        defaultSort={columnConfig.defaultSort}
      />
    </>
  )
}

ClassifyAssetsTable.propTypes = {
  header: PropTypes.node,
  onClickRow: PropTypes.func
}

export default ClassifyAssetsTable
