import { makeStyles } from '@material-ui/core'
import React from 'react'
import PropTypes from 'prop-types'
import SaveCancelButtons from '../../../molecules/SaveCancelButtons'

const useStyles = makeStyles((theme) => ({
  formActions: {
    position: 'fixed',
    bottom: '1rem',
    left: '50%',
    padding: '0',
    transform: 'translateX(-50%)',
    width: 'fit-content',
    zIndex: 10
  }
}))

const SubmitButtons = ({
  onCancel,
  isSubmitting,
  isValid,
  editMode
}) => {
  const classes = useStyles()
  if (!editMode) return null

  return (
    <SaveCancelButtons
      containerClassName={classes.formActions}
      onCancel={onCancel}
      isSubmitting={isSubmitting}
      isFormValid={isValid}
    />
  )
}

SubmitButtons.propTypes = {
  onCancel: PropTypes.func,
  isSubmitting: PropTypes.any,
  isValid: PropTypes.bool,
  editMode: PropTypes.any
}

export default SubmitButtons
