import * as MaterialUILibrary from '@material-ui/core'
import AdvisorAllocationTable from '../components/organisms/AdvisorAllocationTable'
import HistoricalPerformanceTable from '../components/organisms/HistoricalPerformanceTable'
import DynamicPlanning from '../components/molecules/PlanHome'
import { DynamicPlanning as DynamicPlanningV2 } from '../components/organisms/DynamicPlanning'
import WealthJourney from '../components/organisms/NewWealthJourneyHome'
import AdvisorView from '../components/organisms/AdvisorView/main'
import AdvisorViewTabs from '../components/organisms/AdvisorView/components/AdvisorViewTabs'
import DocumentVault from '../components/molecules/DocumentVault'
import Skeleton from '../components/atoms/Skeleton'
import AdvisorFullView from '../components/organisms/AdvisorView/components/AdvisorFullView'
import Text from '../components/atoms/Text'
import AdvisorTransactionsTable from '../components/organisms/AdvisorView/views/TransactionsTableReport'
import AdvisorTopClientsPreview from '../components/organisms/AdvisorView/previews/TopClients'
import AdvisorTopAssetsPreview from '../components/organisms/AdvisorView/previews/TopAssetsSummary'
import AdvisorCashReportSummary from '../components/organisms/AdvisorView/previews/CashReportSummary'
import AdvisorTransactionsSummaryPreview from '../components/organisms/AdvisorView/previews/TransactionsSummary'
import AdvisorCashReport from '../components/organisms/AdvisorView/views/CashReport'
import AdvisorNetFlowsReport from '../components/organisms/AdvisorView/views/NetFlowsReport'
import AdvisorTopClients from '../components/organisms/AdvisorView/views/TopClients'
import PortfolioSummary from '../components/organisms/PortolioSummary/PortolioSummary'
import PortfolioReviewView from '../components/organisms/PortfolioReviewView'
import PortfolioAllocationView from '../components/organisms/PortfolioAllocationView'
import RssFeed from '../components/organisms/RssFeed'
import VisualBalanceSheet from '../components/organisms/VisualBalanceSheet'
import VisualBalanceSheetV2 from '../components/organisms/VisualBalanceSheetV2'
import Household from '../components/organisms/Household/Household'
import NumberTitle from '../components/organisms/AdvisorView/components/NumberTitle'
import PieProgressBars from '../components/molecules/PieProgressBars'
import Image from '../components/atoms/Image'
import AdvisorGoalsReport from '../components/organisms/AdvisorView/views/GoalsReport'
import PermissionsContent from '../components/atoms/PermissionsContent'
import ClientCardsView from '../components/organisms/ClientCardsView/index'
import NewAccountsPreview from '../components/organisms/AdvisorView/previews/NewAccountsPreview'
import NewAccountsReport from '../components/organisms/AdvisorView/views/NewAccountsReport'
import CustomDataset from '../contexts/CustomDatasetContext'
import CustomDatasetJoin from '../contexts/CustomDatasetContext/Join'
import TestResearchTable from '../components/research/TestResearchTable'
import TileList from '../components/research/TileList'
import AccountStatusPreview from '../components/organisms/AdvisorView/previews/AccountStatus'
import AccountStatusView from '../components/organisms/AdvisorView/views/AccountStatus'
import UpcomingMilestonesSummary from '../components/organisms/AdvisorView/previews/UpcomingMilestonesSummary'
import MountMonitor from '../components/organisms/AdvisorView/MountMonitor'
import MilestonesListView from '../components/organisms/AdvisorView/views/Milestones'
import SalesforceStagedTaskDetails
  from '../components/organisms/AdvisorView/views/SalesforcePreview/TaskDetailsDatasetAdapter'
import SalesforceUpcomingMeetings from '../components/organisms/AdvisorView/views/SalesforcePreview/UpcomingMeetings'
import SalesforceTasksPreview from '../components/organisms/AdvisorView/views/SalesforcePreview/TasksPreview'
import RecentClientsPreview from '../components/organisms/AdvisorView/previews/RecentClients'
import EmptyCard from '../components/organisms/AdvisorView/components/EmptyCard'
import EmptySection from '../components/atoms/EmptySection'
import FloatingContent from '../components/organisms/AdvisorView/components/FloatingContent'
import NetFlowsPreview from '../components/organisms/AdvisorView/previews/NetFlowsPreview'
import AccountObjectivesPreview from '../components/organisms/AdvisorView/previews/AccountObjectivesPreview'
import TransactionsPreview from '../components/organisms/AdvisorView/previews/TransactionsPreview'
import AdvisorViewLink from '../components/organisms/AdvisorView/components/AdvisorViewLink'
import ClientDashboard from '../components/organisms/ClientDashboard'
import AdvisorViewHome from '../components/organisms/AdvisorView/AdvisorViewHome'
import AdvisorViewDashboardLayout from '../components/organisms/AdvisorView/Layouts/Dashboard'
import ExportRoot from '../components/molecules/ExportButton/ExportRoot'
import AdvisorViewFrameLayout from '../components/organisms/AdvisorView/Layouts/Frame'
import FilterContextProvider from '../contexts/FilterContext'
import TransactionsDetails from '../components/organisms/AdvisorView/views/Transactions/Details'
import PerformanceAllocationTable from '../components/molecules/PerformanceAllocationTable'
import TransactionsView from '../components/pages/views/Transactions'
import CostBasisView from '../components/pages/views/CostBasis'
import ExposureReportView from '../components/pages/views/ExposureReport'
import BookOfBusinessReportView from '../components/pages/views/BookOfBusinessReport'
import AdvisorMenuOptions from '../components/organisms/AdvisorView/components/AdvisorMenuOptions'
import ComponentsOfChangeTable from '../components/molecules/ComponentsOfChangeTable'
import ComponentsOfChangeTableV2 from '../components/organisms/ComponentsOfChangeTableV2'
import WealthMission from '../components/molecules/WealthMission'
import PerformanceTableV2 from '../components/organisms/PerformanceTableV2'
import performanceV3Components from '../components/organisms/PerformanceTableV3/abundanceEngine'
import FormattingProvider from '../components/organisms/FormattingProvider'
import GroupingProvider from '../components/organisms/GroupingProvider'
import GroupingPicker from '../components/organisms/GroupingProvider/GroupingPicker'
import GroupingTab from '../components/organisms/GroupingProvider/GroupingTab'
import ClientComponentMetrics from '../components/organisms/ClientComponentMetrics'
import TopHoldings from '../components/organisms/TopHoldings'
import AllocationChart from '../components/organisms/AllocationChart'
import AlternativesProgressPieChart from '../components/organisms/AlternativesView/AlternativesProgressPieChart'
import AltsProjectionChart from '../components/organisms/AlternativesView/AltsProjectionChart'
import TopInFlight from '../components/organisms/AlternativesView/TopInFlight'
import TopOnDeck from '../components/organisms/AlternativesView/TopOnDeck'
import FullyFunded from '../components/organisms/AlternativesView/FullyFunded'
import AsOfDateSelector from '../components/organisms/AsOfDateSelector'
import AlternativesTable
  from '../components/organisms/AlternativesView/AlternativesTable/AlternativesReportTableWrapper'
import PlatformDataSourceTable from '../components/organisms/PlatformDataSourceTable'
import PlatformDataSourceItem from '../components/organisms/PlatformDataSourceItem'
import SummitOnlyContent from '../components/atoms/SummitOnlyContent'
import AdvisorOnlyContent from '../components/atoms/AdvisorOnlyContent'
import { TabularDatasetProvider } from '../components/organisms/TabularDatasetProviderV1'
import TabularDatasetTable from '../components/organisms/TabularDatasetTableV1'
import * as AssetTearSheet from '../components/organisms/AssetTearSheet'
import ClientCardsViewV2 from '../../src/components/organisms/ClientCardsViewV2'
import CardTile from '../components/pages/admin/CardTile'
import ResearchHome from '../components/organisms/ResearchView/ResearchHome'
import { AdvisorHomeLayout } from '../components/organisms/AdvisorHomeLayout'
import { AdvisorHomeComponents } from '../components/organisms/AdvisorHome'
import MaturityLadder from '../components/molecules/MaturityLadderChart'
import GroupedCoreBarChart from '../components/molecules/GroupedCoreBarChart'
import ContextualClientComponents from '../components/organisms/ContextualClientInfo/contextualClientComponents'
import MilestonesView from '../components/organisms/MilestonesView'
import TasksView from '../components/organisms/TasksView'
import BenchmarksTable from '../components/molecules/BenchmarksTable'
import BouncingDotsLoader from '../components/atoms/BouncingDotsLoader'
import TasksDetailsPreview from '../components/organisms/AdvisorHome/TasksPreview/TasksDetailsPreview'
import KeyDatesContextProvider from '../components/organisms/AdvisorHome/KeyDates/KeyDatesContextProvider'
import KeyDatesLayout from '../components/organisms/AdvisorHome/KeyDates/KeyDatesLayout'
import AllocationHistoryChart from '../components/molecules/AllocationHistoryChart'
import WealthMissionView, { WealthMissionViewComponents } from '../components/organisms/WealthMissionView'
import wealthJourneyComponents from '../components/organisms/WealthJourney/abundanceEngine'
import SiteRedirect from '../components/molecules/SiteRedirect'
import TargetAllocationTable from '../components/organisms/TargetAllocationTable'
import TargetAllocationChart from '../components/organisms/TargetAllocationChart'
import ComponentsOfChangePivotTable from '../components/molecules/ComponentsOfChangePivotTable'
import ResponsiveGauge from '../components/nivo/Gauge/ResponsiveGauge'
import KeyDatapointGauge from '../components/organisms/KeyDatapointGauge'
import KDPMetric from '../components/organisms/KeyDatapoints/KDPMetric'
import KDPTable from '../components/organisms/KeyDatapoints/KDPTable'
import PerformanceLineChart from '../components/organisms/PerformanceLineChart'
import ClientStatusReport from '../components/organisms/StatusReport/ClientStatusReport'
import ClientStatusReportSummary from '../components/organisms/StatusReport/ClientStatusReportSummary'
import StatusReportProvider from '../components/organisms/StatusReport/StatusReportProvider'
import StatusReportActions from '../components/organisms/StatusReport/StatusReportActions'
import StatusReportAsOfDate from '../components/organisms/StatusReport/StatusReportAsOfDate'
import FadeIn from '../components/molecules/Transitions/FadeIn'
import * as WidgetContainer from '../components/molecules/WidgetWrapper'
import FilterSubscriptionComponents from '../contexts/FilterSubscriptionContext'
import CurrentClientTemplatedText from '../components/molecules/CurrentClientTemplatedText'
import thingComponents from '../components/organisms/Things/abundanceEngine'
import PerformancePivotTable from '../components/organisms/PerformanceTableV2/PerformancePivotTable'
import projectedIncomeComponents from '../components/organisms/ProjectedIncomeChart/abundanceEngine'
import baseBarChartComponents from '../components/organisms/BaseBarChart/abundanceEngine'
import AddAccount from '../components/organisms/AddAccount'
import denaliAnalysisComponents from '../components/organisms/DenaliAnalysis/abundanceEngine'
import homeDashboardComponents from '../components/organisms/HomeDashboard/abundanceEngine'
import clientComponents from '../components/organisms/ClientInsight/abundanceEngine'
import sydComponents from '../components/commonDesign/abundanceEngine'
import performanceLineComponents from '../components/organisms/PerformanceLineChart/v4/abundanceEngine'
import transactionsComponents from '../components/organisms/Transactions/abundanceEngine'
import targetAllocationChartComponents from '../components/organisms/TargetAllocationChart/abundanceEngine'
import componentsOfChangeV3Module from '../components/organisms/ComponentsOfChangeV3/abundanceEngine'
import Card from '../components/molecules/Card'
import ByAllAccountsWidget from '../components/organisms/ByAllAccountsWidget'
import AccountStatusToggle from '../components/organisms/AccountStatus'
import AccountStatusDetailsModal from '../components/organisms/AccountStatus/AccountStatusDetailsModal'
import BillingClientReview from '../components/pages/admin/billing/clientReview/BillingClientReview'
import AdvisorHomeScopeProvider from '../components/organisms/AdvisorHome/AdvisorHomeScopeProvider'
import Icon from '../components/atoms/Icon'
import outboundSsoComponents from '../components/organisms/OutboundSso/abundanceEngine'
import FlexRow from '../components/molecules/FlexRow'
import PersonalSpace from '../components/molecules/PersonalSpace'
import AccountsList from '../components/pages/admin/Accounts/AccountsListTab/AccountsList'
import PrintViewLayout from './components/PrintViewLayout'

export const abundanceComponents = {
  FlexRow,
  PersonalSpace,
  Card,
  AdvisorAllocationTable,
  HistoricalPerformanceTable,
  PortfolioReviewView,
  DynamicPlanning,
  ...DynamicPlanningV2,
  WealthJourney,
  VisualBalanceSheet,
  VisualBalanceSheetV2,
  DocumentVault,
  Household,
  AdvisorView,
  PortfolioSummary,
  AccountStatusView,
  AccountStatusPreview,
  AccountObjectivesPreview,
  AdvisorCashReport,
  AdvisorNetFlowsReport,
  AdvisorGoalsReport,
  AdvisorViewHeader: AdvisorView.Header,
  AdvisorViewDivider: AdvisorView.Divider,
  AdvisorViewTabButtons: AdvisorViewTabs.Buttons,
  AdvisorViewTabList: AdvisorViewTabs.List,
  AdvisorViewTabLinks: AdvisorViewTabs.Links,
  AdvisorViewTabGroup: AdvisorViewTabs.Group,
  AdvisorViewTab: AdvisorViewTabs.Tab,
  AdvisorTopClientsPreview,
  AdvisorTopAssetsPreview,
  AdvisorTransactionsSummaryPreview,
  AdvisorTransactionsTable,
  AdvisorTopClients,
  MilestonesListView,
  PortfolioAllocationView,
  UpcomingMilestonesSummary,
  PieProgressBars,
  NumberTitle,
  Skeleton,
  Image,
  AdvisorFullView,
  AdvisorCashReportSummary,
  Text,
  RssFeed,
  PermissionsContent,
  ClientCardsView,
  NetFlowsPreview,
  NewAccountsPreview,
  NewAccountsReport,
  TestResearchTable,
  TileList,
  CustomDataset,
  CustomDatasetJoin,
  MountMonitor,
  SalesforceStagedTaskDetails,
  SalesforceUpcomingMeetings,
  SalesforceTasksPreview,
  TransactionsPreview,
  RecentClientsPreview,
  FloatingContent,
  EmptyCard,
  AdvisorViewLink,
  ClientDashboard,
  ClientDashboardItem: ClientDashboard.Item,
  AdvisorViewHome,
  DashboardLayout: AdvisorViewDashboardLayout,
  DashboardContainer: AdvisorViewDashboardLayout.Container,
  DashboardItem: AdvisorViewDashboardLayout.Item,
  FrameLayout: AdvisorViewFrameLayout,
  FilterContextProvider,
  ExportRoot,
  TransactionsDetails,
  PerformanceAllocationTable,
  PerformanceTableV2,
  FormattingProvider,
  GroupingProvider,
  GroupingPicker,
  GroupingTab,
  ComponentsOfChangeTable,
  ComponentsOfChangeTableV2,
  ClientComponentMetrics,
  AllocationChart,
  AlternativesProgressPieChart,
  AltsProjectionChart,
  TopInFlight,
  TopOnDeck,
  FullyFunded,
  PerformanceLineChart,
  AsOfDateSelector,
  TopHoldings,
  CostBasisView,
  TransactionsView,
  ExposureReportView,
  BookOfBusinessReportView,
  AdvisorMenuOptions,
  WealthMission,
  AlternativesTable,
  PlatformDataSourceTable,
  PlatformDataSourceItem,
  SummitOnlyContent,
  AdvisorOnlyContent,
  TabularDatasetProvider,
  TabularDatasetTable,
  AssetTearSheet,
  ...AssetTearSheet,
  ...AdvisorHomeLayout,
  ...AdvisorHomeComponents,
  ...ContextualClientComponents,
  ...WealthMissionViewComponents,
  ...targetAllocationChartComponents,
  ClientCardsViewV2,
  CardTile,
  ResearchHome,
  MaturityLadder,
  GroupedCoreBarChart: GroupedCoreBarChart,
  GroupedCoreBarChartContainer: GroupedCoreBarChart.Container,
  MilestonesView,
  TasksView,
  BenchmarksTable,
  BouncingDotsLoader,
  TasksDetailsPreview,
  KeyDatesProvider: KeyDatesContextProvider,
  KeyDatesLayout,
  AllocationHistoryChart,
  TargetAllocationChart,
  TargetAllocationTable,
  WealthMissionView,
  SiteRedirect,
  ComponentsOfChangePivotTable,
  KeyDatapointGauge,
  KDPMetric,
  KDPTable,
  StatusReportProvider,
  StatusReportActions,
  ClientStatusReport,
  ClientStatusReportSummary,
  StatusReportAsOfDate,
  CurrentClientTemplatedText,
  FadeIn,
  ResponsiveGauge,
  ...WidgetContainer,
  ...FilterSubscriptionComponents,
  ...thingComponents,
  ...projectedIncomeComponents,
  ...baseBarChartComponents,
  ...denaliAnalysisComponents,
  ...homeDashboardComponents,
  ...clientComponents,
  ...sydComponents,
  ...performanceLineComponents,
  ...outboundSsoComponents,
  ...wealthJourneyComponents,
  ...transactionsComponents,
  ...performanceV3Components,
  ...componentsOfChangeV3Module,
  PerformancePivotTable,
  AddAccount,
  ByAllAccountsWidget,
  AccountStatusToggle,
  AccountStatusDetailsModal,
  BillingClientReview,
  AccountsList,
  AdvisorHomeScopeProvider,
  EmptySection,
  Icon
}

const abundanceComponentsRegister = {
  ...MaterialUILibrary,
  ...abundanceComponents
}

export default Object.keys(abundanceComponentsRegister).reduce((acc, key) => {
  acc[key] = PrintViewLayout(abundanceComponentsRegister[key])
  return acc
}, {})
