/* eslint-disable react/jsx-key */
import React from 'react'
import PropTypes from 'prop-types'
import { useTable, useSortBy } from 'react-table'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  baseTable: {
    margin: '12px 12px 24px 12px',
    '& th, td': {
      textAlign: 'left',
      margin: '3px 6px',
      padding: '3px 6px'
    }
  }
}))

const MilestoneTable = ({ columns, data }) => {
  const tableConfig = useTable({ columns, data }, useSortBy)
  const classes = useStyles()

  return (
    <table {...tableConfig.getTableProps()} className={classes.baseTable}>
      <thead>
        {tableConfig.headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                {column.render('Header')}
                <span>
                  {column.isSorted
                    ? column.isSortedDesc
                      ? ' 🔽'
                      : ' 🔼'
                    : ''}
                </span>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...tableConfig.getTableBodyProps()}>
        {tableConfig.rows.map((row, i) => {
          tableConfig.prepareRow(row)
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map(cell => (
                <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
              ))}
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

MilestoneTable.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object),
  data: PropTypes.arrayOf(PropTypes.object)
}

export default MilestoneTable
