import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import FadeIn from '../../molecules/Transitions/FadeIn'
import StatusDisplay from './StatusDisplay'

const useStyles = makeStyles((theme) => ({
  card: {
    border: `1.5px solid ${theme.palette.gray.darker}`,
    borderRadius: '10px',
    padding: '16px 24px',
    width: ({ cardWidth }) => cardWidth,
    minWidth: '280px',
    transition: 'outline 200ms ease-in-out',
    outline: ({ selected }) => selected ? `2px solid ${theme.palette.summitBlue}` : '1px solid transparent',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  number: {
    fontSize: '56px'
  },
  description: {
    fontSize: '22px'
  },
  top: {
    display: 'flex',
    flexDirection: 'row',
    gap: '15px',
    alignItems: 'baseline'
  }
}))

function StatusCard ({ statusInfo, count, selected, onClick, index, cardWidth }) {
  const classes = useStyles({ selected, cardWidth })

  return (
    <FadeIn delay={index * 0.1} className={classes.card} onClick={onClick}>
      <div className={classes.top}>
        <div className={classes.number}>{count}</div>
        <StatusDisplay statusInfo={statusInfo} size={40} useIcon />
      </div>
      <div className={classes.description}>{statusInfo.displayName}</div>
    </FadeIn>
  )
}

StatusCard.propTypes = {
  statusInfo: PropTypes.shape({
    color: PropTypes.string,
    displayName: PropTypes.string,
    icon: PropTypes.string
  }),
  count: PropTypes.number,
  selected: PropTypes.bool,
  onClick: PropTypes.func,
  index: PropTypes.number,
  cardWidth: PropTypes.string
}

StatusCard.defaultProps = {
  selected: false,
  index: 0,
  cardWidth: 'calc(50% - 8px)'
}

export default StatusCard
