import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(() => ({
  rotateAnimation: {
    animation: '$spin 2s linear infinite'
  },
  '@keyframes spin': {
    '0%': {
      transform: 'rotate(360deg)'
    },
    '100%': {
      transform: 'rotate(0deg)'
    }
  }
}))

const useSpinClassName = () => {
  const classes = useStyles()
  return classes.rotateAnimation
}

export default useSpinClassName
