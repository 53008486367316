import PropTypes from 'prop-types'
import { Box } from '@material-ui/core'
import React from 'react'
import Text from '../../../components/atoms/Text'
import Avatar from '../../../components/atoms/Avatar'

const CellProps = {
  value: PropTypes.string,
  row: PropTypes.object
}

export const CustodianImageCell = ({ value, row }) => {
  if (!value) {
    return ''
  }
  return (
    <Box style={{ marginLeft: 0, cursor: 'pointer' }} display='flex' alignItems='center' justifyContent='center'>
      <Avatar
        src={value}
        alt={row.original.custodian.name}
        avatarLetters={row.original.custodian.name}
        isPublicResource
        customSize='1.5rem'
      />
    </Box>
  )
}
CustodianImageCell.propTypes = CellProps

export const ContentCell = ({ value, row }) => {
  if (!value) {
    return ''
  }

  const isParentGroup = !!row.original.subRows
  const textProps = {
    customFontSize: '0.875rem',
    lineHeight: '1.5rem'
  }
  if (isParentGroup) {
    textProps.textTransform = 'uppercase'
    textProps.customFontWeight = 700
  }
  return (
    <Box style={{ marginLeft: 0, cursor: !isParentGroup ? 'pointer' : '', width: '100%' }}>
      <Text {...textProps} text={value} />
    </Box>
  )
}
ContentCell.propTypes = CellProps
