import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles, alpha } from '@material-ui/core'
import clsx from 'clsx'
import FastAvatar from '../../../molecules/FastAvatar'

const sizes = (theme) => ({
  sm: {
    padding: '2px 0',
    '& .__title': {
      fontSize: theme.typography.fontSizes.xxl,
      lineHeight: '35px'
    },
    '& .__label': {
      lineHeight: '10px',
      fontSize: theme.typography.fontSizes.lg
    }
  },
  lg: {
    padding: '5px 0',
    '& .__title': {
      fontSize: theme.typography.fontSizes.h2,
      lineHeight: '35px'
    },
    '& .__label': {
      lineHeight: '20px',
      fontSize: theme.typography.fontSizes.h4
    }
  }
})

const useStyles = makeStyles((theme) => ({
  scopeDisplay: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    gap: theme.layout.gap.medium,
    alignItems: 'center',
    '& .__label': {
      textAlign: 'left',
      lineHeight: '20px',
      fontSize: theme.typography.fontSizes.h4,
      color: alpha(theme.palette.text.primary, 0.5)
    },
    '& .__title': {
      fontWeight: 'bold'
    }
  },
  ...(sizes(theme))
}))

const mapAvatarSize = (size) => {
  switch (size) {
    case 'sm': return 'lg'
    case 'lg': return 'sl'
    default: return 'sl'
  }
}

export const ScopeDisplayItem = ({ name, label, avatarSrc, abbreviation, size, hideAvatar }) => {
  const classes = useStyles({ label, hideAvatar })

  if (!name) return null

  return (
    <div className={clsx(classes.scopeDisplay, (size in classes) ? classes[size] : classes.lg)}>
      {hideAvatar ? null : (
        <div className='__left'>
          <FastAvatar
            size={mapAvatarSize(size)}
            abbreviation={abbreviation}
            avatarUrl={avatarSrc}
          />
        </div>
      )}
      <div className='__right'>
        {label && <div className='__label'>{label}</div>}
        <div className='__title'>{name}</div>
      </div>
    </div>
  )
}

ScopeDisplayItem.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  avatarSrc: PropTypes.string,
  abbreviation: PropTypes.string,
  hideAvatar: PropTypes.bool,
  size: PropTypes.oneOf(['sm', 'lg'])
}
