import React, { useMemo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import { useSearchRebalancerClients } from '../../../../api/rebalancer'

const useStyles = makeStyles((theme) => ({
  assigned: {
    display: 'inline-flex',
    flexDirection: 'column',
    // justifyContent: 'center',
    alignItems: 'center',
    // backgroundColor: theme.palette.gray.main,
    border: `1.5px solid ${theme.palette.gray.main}`,
    borderRadius: '5px',
    padding: '5px 20px',
    '& > main': {
      fontSize: '22px'
    }
  }
}))

function AssignedMetric ({ targetModelId }) {
  const classes = useStyles()
  const query = useMemo(() => ({
    filters: {
      targetModelId
    },
    resultType: 'total'
  }), [targetModelId])
  const { data, isLoading } = useSearchRebalancerClients(query)
  const info = useMemo(() => {
    const total = data && data.length ? data[0].total : null
    return {
      total,
      label: total === 1 ? 'Client' : 'Clients'
    }
  }, [data])

  if (isLoading) {
    return null
  }

  return (
    <div>
      <div className={classes.assigned}>
        <main>{info.total}</main>
        <footer>{info.label} Assigned</footer>
      </div>
    </div>
  )
}

AssignedMetric.propTypes = {
  targetModelId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
}

export default AssignedMetric
