import React, { useCallback, useImperativeHandle, useMemo, useState } from 'react'
import RoundedModal from '../../../../molecules/RoundedModal'
import SydButton from '../../../../commonDesign/Button'
import { useRemoveStatusTemplateItemMutation } from '../../../../../api/groups'
import { useDialogStyles } from './common'

const DeleteTemplateItemDialog = React.forwardRef(function DeleteTemplateItemDialog (_, ref) {
  const classes = useDialogStyles()
  const [dialogState, setDialogState] = useState({ open: false, processing: false })
  const close = useCallback(() => {
    setDialogState({ open: false, processing: false })
  }, [setDialogState])

  useImperativeHandle(ref, () => ({
    open: (item) => setDialogState({ open: true, processing: false, item })
  }), [setDialogState])

  const templateItem = useMemo(() => {
    return dialogState.item
  }, [dialogState])

  const { mutateAsync: removeItem } = useRemoveStatusTemplateItemMutation()

  const handleRemove = useCallback(async () => {
    try {
      setDialogState(p => ({ ...p, processing: true }))
      await removeItem(templateItem)
      close()
    } catch (err) {
      console.error(err)
      setDialogState(p => ({ ...p, err, processing: false }))
    }
  }, [removeItem, templateItem, setDialogState, close])

  return (
    <RoundedModal
      title={`Delete Template Item ${templateItem?.statusTemplateItemId}`}
      open={dialogState.open}
      onClose={close}
      position='top'
      disablePortal={false}
    >
      <div className={classes.dialogBody}>
        <div className={classes.form}>
          <p><span>Code Name: </span><span>{templateItem?.codeName}</span></p>
          <p><span>Display Name: </span><span>{templateItem?.displayName}</span></p>
          <p>This will delete the status template item <strong>permanently</strong>.</p>
        </div>
        <div className={classes.actions}>
          <SydButton disabled={dialogState.processing} variant='ghost' size='lg' onClick={close}>Cancel</SydButton>
          <SydButton
            variant='primary'
            priority='warning'
            size='lg'
            onClick={handleRemove}
            processing={dialogState.processing}
          >
            Delete Item
          </SydButton>
        </div>
      </div>
    </RoundedModal>
  )
})

DeleteTemplateItemDialog.propTypes = {
}

export default DeleteTemplateItemDialog
