import React, { useCallback, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Container, Grid, InputLabel, makeStyles } from '@material-ui/core'
import { useForm } from 'react-hook-form'
import dayjs from 'dayjs'
import { useHistory } from 'react-router-dom'
import utc from 'dayjs/plugin/utc'
import { camelCase, isEmpty, isObject } from 'lodash'
import Select from '../../../molecules/Select'
import SaveCancelButtons from '../../../molecules/SaveCancelButtons'
import { CLIENTS_MEMBER_LEVEL_ID, INTERNAL_DATE_FORMAT } from '../../../../constants'
import { getDefaultDateRangeOptions } from '../../../molecules/RelativeDateSelect'
import MultiSelect from '../../../molecules/MultiSelect'
import { useGroupTypeSearch } from '../../../../api/groups'
import { useLevelDates } from '../../../../api/coreData'
import { DATA_SET_TYPES, EXPOSURE_TYPES, defaultDataSetOptions, getGroupTypeFiltersQuery, levelDatesQuery, mapGroupTypeFilters } from './helpers'
import DateSelector from './DateSelector'
import ReportParameterControl from './ReportParameterControl'
import FilterPicker from './FilterPicker'
import { useLastBusinessDate } from './useLastBusinessDate'

dayjs.extend(utc)

const useStyles = makeStyles(() => ({
  title: {
    fontWeight: '600',
    fontSize: '2.125rem',
    textAlign: 'center'
  },
  title2: {
    fontSize: '1.2rem',
    color: '#000',
    fontWeight: '600',
    marginTop: '1rem',
    marginBottom: '0.625rem',
    '& span': {
      color: '#db3131'
    }
  },
  saveButtons: {
    margin: '0 auto'
  },
  select: {
    padding: '0.75rem',
    width: '100%'
  },
  multiSelect: {

  },
  checkbox: {
    paddingTop: '2.5rem',
    fontSize: '1rem',
    '& svg': {
      height: '2rem',
      width: '2rem'
    },
    '& span': {
      fontSize: '1rem'
    }
  },
  footnote: {
    fontSize: '1rem',
    fontWeight: '325',
    color: '#707689',
    '& strong': {
      color: 'black'
    }
  }
}))

const filterDatasetsValues = (values, dataSetTypes) => {
  return values.filter((value) => !dataSetTypes.includes(value))?.join(',') ?? ''
}

const exposureTypes = [...new Set(Object.values(EXPOSURE_TYPES))]

const nonExposureTypes = ['ugl', 'rgl']

export const EMPTY_STATE_TYPE = 'emptyState'

const ReportParameterForm = ({
  exposureLevelOptions,
  dataSetOptions,
  dateRangeOptions,
  onSubmit,
  defaultValues,
  promptTitle
}) => {
  const classes = useStyles()
  const history = useHistory()

  const {
    data: availableDates,
    isLoading: isLoadingAvailableDates
  } = useLevelDates(levelDatesQuery)

  const {
    lastBusinessDate,
    isLoading: isLoadingLastBusinessDay
  } = useLastBusinessDate(availableDates)

  const isLoadingLevelDates = isLoadingAvailableDates || isLoadingLastBusinessDay

  const submissionMapper = useCallback(
    ({ dateRange, exposureTarget, dataSets, asOfDate, ...values }) => {
      const dateRangeOptions = getDefaultDateRangeOptions()
      const selectedOption = dateRangeOptions.find(
        ({ value }) => value === dateRange
      )
      const selectedStartDate = selectedOption.getStartDate({
        mainDate: asOfDate
      })
      const startDate = dataSets.includes(DATA_SET_TYPES.BALANCE)
        ? asOfDate
        : selectedStartDate

      const minStartDate = availableDates?.minDailyStartDate || availableDates?.min

      const rawFilters = JSON.parse(values.filters)
      const hasFilters = !isEmpty(
        rawFilters.filter(({ value }) => value.length)
      )

      const filters = hasFilters
        ? rawFilters.reduce((acc, { type, value }) => {
          if (type === EMPTY_STATE_TYPE) return acc

          let entityType = 'groupType'
          if (type === EXPOSURE_TYPES.CLASSIFICATION_TAG) {
            entityType = EXPOSURE_TYPES.CLASSIFICATION_TAG
          }
          if (type === EXPOSURE_TYPES.ASSET) {
            entityType = EXPOSURE_TYPES.ASSET
          }

          if (type === 'classificationTag') {
            const classificationTags = value.map((v) => {
              return {
                type: `${camelCase(v.payload.classificationType)}TagIds`,
                value: [isObject(v) ? v.value : v],
                entityType
              }
            })

            return [...acc, ...classificationTags]
          }

          return [
            ...acc,
            {
              type: `${camelCase(type)}Ids`,
              value: value.map((val) => (isObject(val) ? val.value : val)),
              entityType
            }
          ]
        }, [])
        : []

      onSubmit({
        ...values,
        dataSets,
        includeTags: true,
        filters: JSON.stringify(filters),
        exposureTarget: exposureTarget?.value,
        dateRangePreset: dateRange,
        startDate,
        endDate: asOfDate,
        minDailyStartDate: minStartDate || startDate
      })
    },
    [onSubmit, availableDates]
  )

  const {
    handleSubmit,
    watch,
    setValue,
    setError,
    reset,
    formState,
    clearErrors
  } = useForm({ defaultValues })

  useEffect(() => {
    if (!isLoadingLastBusinessDay) {
      reset({
        ...defaultValues,
        asOfDate: dayjs
          .utc(
            lastBusinessDate?.endDate ||
              availableDates?.max ||
              defaultValues?.asOfDate
          )
          .toISOString()
      })
    }
  }, [
    reset,
    defaultValues,
    availableDates,
    isLoadingLastBusinessDay,
    lastBusinessDate?.endDate
  ])

  const {
    setDataSets,
    setAsOfDate,
    setDateRange,
    setLevelType,
    setFilters
  } = useMemo(() => {
    return {
      setDataSets: (value) => setValue('dataSets', value?.join(',') ?? ''),
      setAsOfDate: (value) => setValue('asOfDate', dayjs.utc(value).format('YYYY-MM-DD')),
      setDateRange: (value) => setValue('dateRange', value),
      setLevelType: (value) => setValue('levelType', value),
      setFilters: (value) => setValue('filters', JSON.stringify(value))
    }
  }, [setValue])

  const [
    levelType,
    dataSets,
    dateRange,
    asOfDate,
    filters
  ] = watch([
    'levelType',
    'dataSets',
    'dateRange',
    'asOfDate',
    'filters'
  ])

  useEffect(() => {
    const hasEmptyFilters = JSON.parse(filters)?.some(
      (filter) => isEmpty(filter?.value) && exposureTypes.includes(filter.type)
    )
    if (hasEmptyFilters) {
      setError('filters', { type: 'custom' })
    } else {
      clearErrors('filters')
    }
  }, [filters, setError, clearErrors])

  const { query, queryOptions } = useMemo(
    () => ({
      query: getGroupTypeFiltersQuery(levelType),
      queryOptions: { mapper: mapGroupTypeFilters }
    }),
    [levelType]
  )

  const { data: groupTypeOptions = [] } = useGroupTypeSearch(
    query,
    queryOptions
  )

  const { hasAtLeastOneClientGroupType, hasExposure } = useMemo(() => {
    const dataSetFilters = JSON.parse(filters)
    const hasExposure = dataSetFilters.some(({ type }) =>
      exposureTypes.includes(type)
    )
    const groupTypes = groupTypeOptions.reduce(
      (acc, gt) => ({ ...acc, [gt.value]: gt.payload }),
      {}
    )
    const hasAtLeastOneClientGroupType = dataSetFilters.some(({ type }) => {
      const { memberLevelTypeId } = groupTypes?.[type] || {}
      return memberLevelTypeId === CLIENTS_MEMBER_LEVEL_ID
    })

    return { hasAtLeastOneClientGroupType, hasExposure }
  }, [filters, groupTypeOptions])

  useEffect(function overrideDatasetsSelection () {
    const dataSetsValue = dataSets.split(',')
    const hasNonExposureDatasets = dataSetsValue.some((dataSet) => nonExposureTypes.includes(dataSet))
    const shouldDisableNonExposureTypes = hasExposure || hasAtLeastOneClientGroupType

    let newDataSetsValue = null
    if (shouldDisableNonExposureTypes && hasNonExposureDatasets) {
      newDataSetsValue = filterDatasetsValues(dataSetsValue, nonExposureTypes) || 'balance'
    } else if (dataSets.includes('ugl') || dataSets.includes('rgl')) {
      newDataSetsValue = filterDatasetsValues(dataSetsValue, ['balance', 'performance'])
    }
    if (newDataSetsValue) {
      setValue('dataSets', newDataSetsValue)
    }
  }, [dataSets, filters, setValue, hasExposure, hasAtLeastOneClientGroupType])

  useEffect(() => {
    if (!dataSets) {
      setDataSets(defaultValues.dataSets?.split(','))
    }
  }, [dataSets, defaultValues.dataSets, setDataSets])

  const multiSelectRenderValue = useCallback((selected) => {
    const values = selected?.map(s => dataSetOptions.find(o => o.value === s))
      .filter(d => !!d)
      .map(d => d.label)
      .join(', ')

    return values
  }, [dataSetOptions])

  const dataSetsSplit = dataSets?.split(',') ?? []
  const includesPerformance = dataSetsSplit.includes('performance')
  const includesRgl = dataSetsSplit.includes('rgl')

  const shouldDisableDateRange = !includesPerformance && !includesRgl

  const disableDatasetOptions = useCallback(
    (value, option) => {
      if (value.includes('ugl') || value.includes('rgl')) {
        const disabledBalance = option.value === 'balance'
        const disablePerformance = option.value === 'performance'

        return disabledBalance || disablePerformance
      }
      const shouldDisabledUgl = hasExposure || hasAtLeastOneClientGroupType
      const shouldDisableRgl = hasExposure || hasAtLeastOneClientGroupType

      const disableUgl = shouldDisabledUgl && option.value === 'ugl'
      const disableRgl = shouldDisableRgl && option.value === 'rgl'

      return disableUgl || disableRgl
    },
    [hasAtLeastOneClientGroupType, hasExposure]
  )

  return (
    <Container maxWidth='md' style={{ paddingTop: '1rem' }}>
      <form onSubmit={handleSubmit(submissionMapper)}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div className={classes.title}>{promptTitle}</div>
          </Grid>
          <Grid item xs={12}>
            <ReportParameterControl
              title='Report Level'
              value={levelType}
              onSelectOption={setLevelType}
              options={exposureLevelOptions}
            />
          </Grid>
          <Grid item xs={12}>
            <InputLabel className={classes.title2}>Filters</InputLabel>
            <FilterPicker
              value={filters ? JSON.parse(filters) : []}
              onChange={setFilters}
              levelType={levelType}
              selectClasses={classes.select}
              filterTypeOptions={groupTypeOptions}
              hasError={Boolean(formState.errors?.filters)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <InputLabel className={classes.title2}>Datasets</InputLabel>
            <MultiSelect
              value={dataSets?.split(',') ?? []}
              onChange={setDataSets}
              options={dataSetOptions}
              renderValue={multiSelectRenderValue}
              optionKeySelector={(o) => o.value}
              optionValueSelector={(o) => o.value}
              optionDisplaySelector={(o) => o.label}
              shouldDisableOption={disableDatasetOptions}
            />
          </Grid>
          <Grid container item spacing={2}>
            <Grid item xs={12}>
              <InputLabel className={classes.title2}>Date Range</InputLabel>
            </Grid>
            <Grid item xs={6}>
              <Select
                className={classes.select}
                disabled={shouldDisableDateRange}
                variant='outlined'
                value={dateRange}
                onChange={setDateRange}
                options={dateRangeOptions}
                showCheckMarOnSelectedItems
              />
            </Grid>
            <Grid item xs={6}>
              <DateSelector
                value={dayjs.utc(asOfDate).format(INTERNAL_DATE_FORMAT)}
                onChange={setAsOfDate}
                disabledDateRange={{
                  min: lastBusinessDate?.startDate,
                  max: lastBusinessDate?.endDate
                }}
                disabled={isLoadingLevelDates}
                isLoading={isLoadingLevelDates}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <SaveCancelButtons
              spacing='0.75rem'
              primaryButtonLabel='Run'
              hideCancelButton
              isSubmitting={formState.isSubmitting}
              isFormValid={formState.isFormValid}
              onCancel={() => history.goBack()}
              containerClassName={classes.saveButtons}
            />
          </Grid>
        </Grid>
      </form>
    </Container>
  )
}

const exposureOptionPropType = PropTypes.arrayOf(
  PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string
  })
)

ReportParameterForm.propTypes = {
  exposureLevelOptions: exposureOptionPropType,
  dataSetOptions: exposureOptionPropType,
  dateRangeOptions: exposureOptionPropType,
  defaultValues: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  promptTitle: PropTypes.string
}

export const defaultProps = {
  exposureLevelOptions: [
    {
      label: 'Account',
      value: 'account'
    },
    {
      label: 'Client',
      value: 'client'
    }
  ],
  exposureTypeOptions: [
    {
      label: 'Asset',
      value: 'asset'
    },
    {
      label: 'Asset Tag',
      value: 'classificationTag'
    }
  ],
  dataSetOptions: defaultDataSetOptions,
  defaultValues: {
    levelType: 'account',
    dataSets: 'balance',
    dateRange: 'L7D',
    asOfDate: '2023-01-01',
    includeTags: false,
    filters: JSON.stringify([{ type: EMPTY_STATE_TYPE, value: [] }])
  },
  promptTitle: 'Run Book of Business Report'
}

ReportParameterForm.defaultProps = defaultProps

export default ReportParameterForm
