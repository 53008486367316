import { withStyles } from '@material-ui/core/styles'
import Slider from '../../../atoms/Slider'

const StyledSlider = withStyles({
  root: {
    color: '#212945',
    height: 2
  },
  thumb: {
    height: 12,
    width: 12,
    backgroundColor: '#fff',
    border: '4px solid currentColor',
    marginTop: -5,
    marginLeft: -6,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit'
    }
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% - 12px)',
    '& *': {
      background: '#212945  ',
      color: '#ffffff'
    }
  },
  track: {
    height: 2,
    borderRadius: 2
  },
  rail: {
    height: 2,
    borderRadius: 2,
    color: '#d0d0d0'
  }
})(Slider)

export default StyledSlider
