import React, { useCallback, useMemo, useState } from 'react'
import { Box, CircularProgress, makeStyles } from '@material-ui/core'
import { useOktaAuth } from '@okta/okta-react'
import PropTypes from 'prop-types'
import { Controller, useForm } from 'react-hook-form'
import { useChangeMySecurityQuestionMutation, useMySecurityQuestions } from '../../../../api/users'
import FadeIn from '../../../molecules/Transitions/FadeIn'
import SydButton from '../../../commonDesign/Button'
import PasswordInput from '../shared/PasswordInput'
import SydLabel from '../../../commonDesign/SydLabel'
import SydInput from '../../../commonDesign/SydInput'
import Select from '../../../molecules/Select'

const useStyles = makeStyles((theme) => ({
  securityQuestionForm: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: '15px',
    '& .__form': {
      flex: '1 0 auto',
      minWidth: '400px',
      marginBottom: '20px'
    },
    '& .__header': {
      fontSize: theme.typography.fontSizes.h5,
      fontWeight: theme.typography.weights.bold
    }
  },
  errors: {
    display: 'flex',
    gap: '10px',
    flexWrap: 'wrap',
    marginBottom: '15px',
    '& > .__error': {
      padding: '1px 10px',
      borderRadius: '4px',
      fontSize: theme.typography.fontSizes.xl,
      backgroundColor: theme.palette.danger.main,
      color: theme.palette.danger.contrast
    }
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    gap: '20px'
  }
}))

const useQuestionOptions = (data) => useMemo(() => {
  if (!data) return []

  const result = data.questions.map(q => ({
    label: q.questionText,
    value: q.question
  }))

  result.unshift({
    label: 'Pick your own question',
    value: 'custom'
  })

  return result
}, [data])

const useSubmitter = (form, questionOptions, onClose) => {
  const { mutateAsync: changeQuestions } = useChangeMySecurityQuestionMutation()
  const [submitState, setSubmitState] = useState({ processing: false })
  const { handleSubmit } = form
  const onSubmit = useCallback(async (e) => {
    const onSuccess = async (formData) => {
      setSubmitState({ processing: true })
      const commandBody = {
        password: formData.password,
        questionText: formData.questionText,
        question: formData.question || undefined,
        answer: formData.answer
      }
      if (commandBody.question !== 'custom') {
        commandBody.questionText = questionOptions.find(x => x.value === commandBody.question).label
      }
      const saveResult = await changeQuestions(commandBody)
      console.log(saveResult)

      if (saveResult.success) {
        onClose()
      } else {
        setSubmitState({
          processing: false,
          submitErrors: saveResult.error?.errorCauses?.map(e => e.errorSummary) ?? ['Something went wrong setting your security question']
        })
      }
    }

    const onError = (errors) => {
      // eslint-disable-next-line no-throw-literal
      throw { validationErrors: errors }
    }
    const handler = handleSubmit(onSuccess, onError)
    await handler(e)
  }, [handleSubmit, setSubmitState, changeQuestions, onClose, questionOptions])

  return {
    submitState,
    onSubmit
  }
}

function SecurityQuestionForm ({ onClose, currentEnrollment }) {
  const classes = useStyles()
  // Used for the hidden input
  const { authState } = useOktaAuth()
  const form = useForm({
    mode: 'onChange',
    defaultValues: {
      password: '',
      questionText: '',
      question: null,
      answer: ''
    }
  })
  const question = form.watch('question')

  // Used to drive the rules on the page
  const { data, isLoading, error: loadingError } = useMySecurityQuestions()
  const questionOptions = useQuestionOptions(data)
  const { submitState, onSubmit } = useSubmitter(form, questionOptions, onClose)

  if (loadingError) {
    return (
      <div className={classes.formContainer}>
        <Box display='flex' justifyContent='center' alignItems='center' width='100%' height='300px'>
          There was a problem loading this information.
        </Box>
      </div>
    )
  }

  if (isLoading) {
    return (
      <div className={classes.formContainer}>
        <Box display='flex' justifyContent='center' alignItems='center' width='100%' height='300px'>
          <CircularProgress size={50} />
        </Box>
      </div>
    )
  }

  return (
    <div className={classes.formContainer}>
      {submitState.submitErrors ? (
        <FadeIn className={classes.errors}>
          {submitState.submitErrors.map(err => (
            <div key={err} className='__error'>{err}</div>
          ))}
        </FadeIn>
      ) : null}
      <FadeIn className={classes.securityQuestionForm}>
        <div className='__form'>
          <input type='hidden' autoComplete='username' value={authState.idToken.claims.email} />
          {currentEnrollment ? (
            <SydLabel label='Your Current Question' description='&nbsp;'>
              <SydInput disabled defaultValue={currentEnrollment.profile.question} />
            </SydLabel>
          ) : null}
          <Controller
            name='password'
            control={form.control}
            rules={{ required: 'Required' }}
            render={({ field, fieldState }) => (
              <PasswordInput
                label='Current Password'
                autoComplete='current-password'
                error={(form.formState.isSubmitted || fieldState.isDirty || fieldState.isTouched) && fieldState.error}
                {...field}
              />
            )}
          />
          <Controller
            name='question'
            control={form.control}
            render={({ field, fieldState }) => (
              <SydLabel label='Pick a Question' description='&nbsp;'>
                <Select
                  variant='outlined-rounded'
                  fullWidth
                  options={questionOptions}
                  {...field}
                />
              </SydLabel>
            )}
          />
          <Controller
            name='questionText'
            control={form.control}
            rules={{ validate: (x) => question === 'custom' && !x.length ? 'Required' : true }}
            render={({ field, fieldState }) => {
              return question === 'custom' ? (
                <SydLabel
                  label='Custom Question Text'
                  description='&nbsp;'
                  error={(form.formState.isSubmitted || fieldState.isDirty || fieldState.isTouched) && fieldState.error?.message}
                  required
                >
                  <SydInput autoComplete='off' placeholder='Custom Security Question' {...field} />
                </SydLabel>
              ) : null
            }}
          />
          <Controller
            name='answer'
            control={form.control}
            rules={{ required: 'Required' }}
            render={({ field, fieldState }) => (
              <SydLabel
                label='Answer'
                description='&nbsp;'
                error={(form.formState.isSubmitted || fieldState.isDirty || fieldState.isTouched) && fieldState.error?.message}
                required
              >
                <SydInput placeholder='Security Question Answer' autoComplete='off' {...field} />
              </SydLabel>
            )}
          />
        </div>
      </FadeIn>
      <div className={classes.actions}>
        <SydButton variant='outline' onClick={onClose} disabled={submitState.processing}>Cancel</SydButton>
        <SydButton
          variant='primary'
          disabled={isLoading || !form.formState.isValid}
          onClick={onSubmit}
          processing={submitState.processing}
        >
          Submit
        </SydButton>
      </div>
    </div>
  )
}

SecurityQuestionForm.propTypes = {
  onClose: PropTypes.func,
  currentEnrollment: PropTypes.object
}

export default SecurityQuestionForm
