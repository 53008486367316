import React from 'react'
import { makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'

const useStyles = makeStyles(theme => ({
  nullValue: {
    color: theme.palette.gray.darker
  }
}))

function NullValue ({ value }) {
  const classes = useStyles()

  return (
    <span className={classes.nullValue}>{value}</span>
  )
}

NullValue.propTypes = {
  value: PropTypes.any
}

NullValue.defaultProps = {
  value: <>&lt;null&gt;</>
}

export default NullValue
