import React, { useCallback, useMemo } from 'react'
import { makeStyles } from '@material-ui/core'
import { useHistory, useParams } from 'react-router-dom'
import { useDeleteClientMutation, useInvalidateClientSearch, useSearchClients } from '../../../../api/clients'
import EmptySection from '../../../atoms/EmptySection'
import { ADMIN_ROUTES } from '../../../../constants'
import { usePolicy } from '../../../../hooks/usePolicy'
import ClientDetailsHeader from './Header'
import ClientDetailTabs from './ClientDetailTabs'
import { ClientDetailsForm } from './ClientDetailsFormContext'
import SaveCancelFooter from './SaveCancelFooter'

const useStyles = makeStyles({
  clientDetailsPage: {
    margin: '1rem'
  }
})

const useClientId = () => {
  const { id } = useParams()
  return isNaN(+id) ? [-1] : [+id]
}

const useDeleteClient = (clientId) => {
  const { mutateAsync: deleteClient, isLoading: deleting } = useDeleteClientMutation()
  const invalidateSearch = useInvalidateClientSearch()
  const history = useHistory()
  const handleDelete = useCallback(async () => {
    await deleteClient(clientId)
    await invalidateSearch()
    history.push(ADMIN_ROUTES.CLIENTS)
  }, [clientId, deleteClient, invalidateSearch, history])

  return { handleDelete, deleting }
}

function ClientDetails () {
  const classes = useStyles()
  const [clientId] = useClientId()
  const canView = usePolicy('admin_view_clients')
  const { data, isLoading, refetch } = useSearchClients({
    filters: {
      clientId
    },
    includes: {
      avatar: true,
      groups: true
    }
  })
  const { handleDelete, deleting } = useDeleteClient(clientId)

  const client = useMemo(() => {
    return data?.data?.length ? data.data[0] : null
  }, [data])

  if (!canView) {
    return (
      <EmptySection
        title='Access Denied'
        description='You do not have sufficient permissions to access this resource'
      />
    )
  }

  if (isLoading) {
    return null
  }

  if (!client) {
    return (
      <EmptySection
        title='Client Not Found'
        description=''
      />
    )
  }

  if (deleting) {
    return (
      <EmptySection
        title='Deleting Client'
        description='Deletion in progress'
      />
    )
  }

  return (
    <div className={classes.clientDetailsPage}>
      <ClientDetailsForm
        client={client}
        refetch={refetch}
        deleteClient={handleDelete}
      >
        <ClientDetailsHeader />
        <ClientDetailTabs />
        <SaveCancelFooter />
      </ClientDetailsForm>
    </div>
  )
}

ClientDetails.propTypes = {
}

export default ClientDetails
