import React from 'react'
import PropTypes from 'prop-types'
import PresentationTable from '../PerformancePresentationTable'
import { usePivotData } from './usePivotData'
import { usePivotColumns } from './usePivotColumns'

const defaultColumns = [
  { header: 'Name', accessor: 'levelName', align: 'left', width: 500 },
  { header: '*', format: 'marketValue', align: 'right' },
  { header: 'Allocation', format: 'allocation', align: 'right', accessor: 'allocation' },
  { header: 'Total', accessor: 'value', align: 'right', format: 'marketValue' }
]

const defaultDefaultSort = [{ id: 'levelName', desc: false }]

const PerformancePivotTable = ({
  defaultFilter,
  groupings,
  pivotAccessor,
  pivotGroup,
  groupIdentifierAccessor,
  allocationAccessor,
  initialExpandDepth,
  rowFilters,
  columnConfig,
  defaultSort,
  ignoreGroupings
}) => {
  const { data, pivotColumns, isLoading, onLevelExpand } = usePivotData({
    defaultFilter,
    groupings,
    pivotAccessor,
    pivotGroup,
    groupIdentifierAccessor,
    allocationAccessor,
    initialExpandDepth,
    rowFilters,
    ignoreGroupings
  })
  const effectiveColumns = usePivotColumns(pivotColumns, columnConfig)

  return (
    <PresentationTable.Wrapper>
      <PresentationTable
        styleVariant='legacy'
        data={data || []}
        loading={isLoading}
        columns={effectiveColumns}
        defaultSort={defaultSort}
        expandable
        onRowClick={onLevelExpand}
      />
    </PresentationTable.Wrapper>
  )
}

PerformancePivotTable.propTypes = {
  defaultFilter: PropTypes.object,
  groupings: PropTypes.arrayOf(PropTypes.string).isRequired,
  pivotAccessor: PropTypes.string,
  pivotGroup: PropTypes.string,
  groupIdentifierAccessor: PropTypes.string,
  allocationAccessor: PropTypes.string,
  initialExpandDepth: PropTypes.number,
  rowFilters: PropTypes.arrayOf(PropTypes.shape({
    accessor: PropTypes.string,
    op: PropTypes.oneOf(['eq', 'ne', 'gt', 'gte', 'lt', 'lte', 'exists', 'notExists']),
    value: PropTypes.any
  })),
  columnConfig: PropTypes.object,
  defaultSort: PropTypes.array,
  ignoreGroupings: PropTypes.array
}

PerformancePivotTable.defaultProps = {
  initialExpandDepth: 2,
  columnConfig: defaultColumns,
  defaultSort: defaultDefaultSort,
  allocationAccessor: 'value',
  ignoreGroupings: []
}

export default PerformancePivotTable
