import React from 'react'
import PropTypes from 'prop-types'
import PerformanceLine from './PerformanceLine'
import { useInterceptedOptions } from './ChartOptionsProvider'

function LineChartSubscriber ({ force, ...props }) {
  const interceptedProps = useInterceptedOptions(props, force)
  return (
    <PerformanceLine {...interceptedProps} />
  )
}

LineChartSubscriber.propTypes = {
  force: PropTypes.arrayOf(PropTypes.string)
}

LineChartSubscriber.defaultProps = {
  force: ['dateRange', 'interval', 'valueFormat', 'tooltipValueFormat', 'series']
}

export default LineChartSubscriber
