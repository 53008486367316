import React from 'react'
import PropTypes from 'prop-types'
import { Accordion, AccordionDetails, AccordionSummary, makeStyles } from '@material-ui/core'
import { useFormattingContext } from '../FormattingProvider/FormattingContext'
import useAccordion from '../../../hooks/useAccordion'
import Icon from '../../atoms/Icon'

const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    gap: theme.layout.gap.medium
  },
  clientMeeting: {
    flex: '1 0 auto',
    fontWeight: theme.typography.weights.light,
    '& .__title': {
      flex: '1',
      display: 'flex',
      wordBreak: 'break-all',
      flexDirection: 'row',
      justifyContent: 'space-between',
      gap: theme.layout.gap.medium,
      fontWeight: theme.typography.weights.bold
    },
    '& .__org': {
      flex: '1',
      fontSize: theme.typography.fontSizes.md
    }
  },
  detail: {
    overflowX: 'auto',
    backgroundColor: theme.palette.gray.main
  },
  meetingAccordion: {
    paddingRight: 0,
    width: '100%',
    background: 'none',
    boxShadow: 'none',

    '& .Mui-expanded': {
      margin: '0'
    },
    '& .__summary-content': {
      margin: 0
    },
    '& .__summary-root': {
      padding: 0
    },
    '& .__summary-root-expanded': {
      padding: 0
    }
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  datetime: {
    textAlign: 'right',
    '& .__date': {
      fontWeight: theme.typography.weights.bold
    },
    '& .__time': {
      fontWeight: theme.typography.weights.light,
      fontSize: theme.typography.fontSizes.md,
      display: 'flex',
      flexDirection: 'row',
      gap: theme.layout.gap.dense
    }
  }
}))

const summaryClasses = { root: '__summary-root', content: '__summary-content' }
const summaryClassesExpanded = { root: '__summary-root-expanded', content: '__summary-content' }

function ClientMeeting ({ meeting }) {
  const classes = useStyles()
  const { formatter } = useFormattingContext()
  const accordion = useAccordion()
  return (
    <Accordion {...accordion} className={classes.meetingAccordion}>
      <AccordionSummary classes={accordion.expanded ? summaryClassesExpanded : summaryClasses}>
        <div className={classes.wrapper}>
          <div className={classes.icon}>
            <Icon name='calendar' customSize={20} />
          </div>
          <div className={classes.clientMeeting}>
            <div className='__title'>
              {meeting.subject}
            </div>
            <div className='__org'>
              Organizer: {meeting.organizer}
            </div>
          </div>
          <div className={classes.datetime}>
            <div className='__date'>
              <div>{formatter(meeting.startDate, '@ddd M/DD')}</div>
            </div>
            <div className='__time'>
              <div>{formatter(meeting.startDate, '@h:mma')}</div>
              <div>to</div>
              <div>{formatter(meeting.endDate, '@h:mma')}</div>
              <div>{formatter(meeting.startDate, '@z')}</div>
            </div>
          </div>
        </div>
      </AccordionSummary>
      <AccordionDetails className={classes.detail}>
        <pre>{meeting.description}</pre>
      </AccordionDetails>
    </Accordion>
  )
}

ClientMeeting.propTypes = {
  meeting: PropTypes.shape({
    meetingId: PropTypes.number,
    clientId: PropTypes.number,
    subject: PropTypes.string,
    description: PropTypes.string,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    organizer: PropTypes.string,
    organizerUserId: PropTypes.number,
    meetingJson: PropTypes.object,
    isRecurring: PropTypes.bool,
    externalId: PropTypes.string
  })
}

export default ClientMeeting
