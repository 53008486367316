import isEmpty from 'lodash/isEmpty'

const getSearchProp = (value, operation = 'contains') => {
  return [{ op: operation, value }]
}

export const getSearchQuery = (fields, query) => {
  if (isEmpty(query?.trim())) return {}

  return fields.reduce(
    (acc, key) => ({
      ...acc,
      [key]: getSearchProp(query)
    }),
    {}
  )
}
