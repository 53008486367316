import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { Button } from '@material-ui/core'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'

const useStyles = makeStyles(theme => ({
  button: {
    backgroundColor: ({ primary }) => primary ? theme.palette.primary.main : undefined,
    color: ({ primary }) => primary ? theme.palette.primary.contrastText : undefined,
    borderRadius: '20px',
    padding: '10px 20px',
    fontFamily: theme.typography.fontFamily,
    textTransform: 'unset',
    '&:hover': {
      backgroundColor: ({ primary }) => primary ? theme.palette.secondary.main : undefined,
      color: ({ primary }) => primary ? theme.palette.secondary.contrastText : undefined
    }
  }
}))

const useIcon = (iconName) => {
  return useMemo(() => {
    if (iconName === 'add') {
      return <AddCircleOutlineIcon />
    }
    return null
  }, [iconName])
}

const ConversationButton = ({ onClick, children, iconName, primary, disabled }) => {
  const classes = useStyles({ primary })
  const icon = useIcon(iconName)

  return (
    <Button
      startIcon={icon}
      className={classes.button}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </Button>
  )
}

ConversationButton.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.node,
  iconName: PropTypes.oneOf(['add']),
  primary: PropTypes.bool,
  disabled: PropTypes.bool
}

ConversationButton.defaultProps = {
  primary: false,
  disabled: false
}

export default ConversationButton
