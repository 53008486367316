import React, { useMemo } from 'react'
import { makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import Text from '../../atoms/Text'
import { ICON_NAMES, TEXT_VARIANTS } from '../../../constants'
import Icon from '../../atoms/Icon'
import { useFormattingContext } from '../FormattingProvider/FormattingContext'
import theme from '../../../theme'
import NumberFormat from '../../atoms/NumberFormat'

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    paddingTop: '2rem'
  },
  divider: {
    width: '100%',
    backgroundImage: 'repeating-linear-gradient(90deg, #dedede, #dedede 1.25rem, transparent 1.25rem, transparent 2.5rem)',
    height: '3px'
  },
  title: {
    marginTop: '2rem'
  },
  innerContainer: {
    display: 'flex',
    alignItems: 'stretch',
    padding: '2rem 0',
    gap: '3.333%',
    flexWrap: 'wrap',
    justifyContent: 'start',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'space-between'
    }
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    border: `2px solid ${theme.palette.grey[200]}`,
    borderRadius: '0.5rem',
    flexGrow: 1,
    width: '22.5%',
    maxWidth: '22.5%',
    minWidth: '250px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: theme.spacing(1),
    padding: '3rem 1rem',
    marginBottom: '2rem',
    [theme.breakpoints.down('xs')]: {
      minWidth: '100%'
    }
  },
  cardImage: ({ backgroundColor, backgroundImage }) => ({
    width: '4rem',
    height: '4rem',
    borderRadius: '50%',
    backgroundColor: !backgroundImage?.length ? backgroundColor : theme.palette.grey[200],
    backgroundImage: backgroundImage ? `url(${backgroundImage})` : 'none',
    backgroundSize: backgroundImage?.indexOf('.svg') >= 0 ? '2.25rem' : 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    color: theme.palette.getContrastText(backgroundColor),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '0 auto'
  }),
  cardContent: {
    textAlign: 'center'
  }
}))

const VisualBalanceOtherAssetCardImage = ({ icon, image, backgroundColor }) => {
  const classes = useStyles({ backgroundColor, backgroundImage: image })
  if (image) {
    return (
      <div className={classes.cardImage} />
    )
  }
  return (
    <div className={classes.cardImage}>
      <Icon name={icon} variant='line' customSize='2rem' />
    </div>
  )
}
VisualBalanceOtherAssetCardImage.propTypes = {
  icon: PropTypes.number,
  image: PropTypes.string,
  backgroundColor: PropTypes.string
}

const VisualBalanceOtherAssetCard = ({ endingValue, name, image, format, backgroundColor }) => {
  const classes = useStyles({ backgroundColor })
  const { formatter } = useFormattingContext()
  const icon = ICON_NAMES.file

  return (
    <div className={classes.card}>
      <VisualBalanceOtherAssetCardImage image={image} icon={icon} backgroundColor={backgroundColor} />

      <div className={classes.cardContent}>
        <Text variant={TEXT_VARIANTS.h1} customFontSize='1.25rem'>
          <NumberFormat title={formatter(endingValue, '0,0.00')} number={formatter(endingValue, format)} skipFormat />
        </Text>
        <Text variant={TEXT_VARIANTS.body} customFontSize='0.875rem' text={name} />
      </div>
    </div>
  )
}
VisualBalanceOtherAssetCard.propTypes = {
  endingValue: PropTypes.number,
  name: PropTypes.string,
  image: PropTypes.string,
  format: PropTypes.string,
  backgroundColor: PropTypes.string
}

const VisualBalanceOtherAssets = ({ title, data, format }) => {
  const cards = useMemo(() => {
    return data.flatMap((item) => {
      return item.subgroups.flatMap(group => group.accounts)
    })
  }, [data])

  const backgroundColor = useMemo(() => {
    return data?.[0]?.groupColorField ?? theme.palette.summitBlue
  }, [data])

  const classes = useStyles({ backgroundColor })

  if (!cards?.length) {
    return (<> </>)
  }

  return (
    <div className={classes.container}>
      <div className={classes.divider} />

      <Text customFontSize='1.5rem' customFontWeight='bold' text={title} className={classes.title} />

      <div className={classes.innerContainer}>
        {cards?.map((item, index) => (
          <VisualBalanceOtherAssetCard
            key={`otherAssetCard-${index}`}
            name={item.displayName || item.accountName || item.accountLongName}
            endingValue={item.endingValue}
            format={format}
            image={item.custodianImageUrl ?? undefined}
            backgroundColor={backgroundColor}
          />
        ))}
      </div>
    </div>
  )
}

VisualBalanceOtherAssets.propTypes = {
  title: PropTypes.string,
  data: PropTypes.array,
  format: PropTypes.string
}

VisualBalanceOtherAssets.defaultProps = {
  title: 'Other Assets',
  data: []
}

export default VisualBalanceOtherAssets
