import React from 'react'
import { makeStyles } from '@material-ui/core'

const tooltipStyles = makeStyles((theme) => ({
  customTooltipContainer: {
    background: theme.palette.white,
    padding: '0.5rem 0.75rem',
    border: `1px solid ${theme.palette.gray.light}`,
    minWidth: '300px',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.layout.gap.dense,
    '& .__points': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      gap: theme.layout.gap.dense
    },
    '& .__label-color': {
      display: 'inline-block',
      width: '12px',
      height: '12px'
    }
  }
}))

/* eslint-disable react/prop-types */
const Tooltip = ({ formatter, tooltip, data, valueFormat }) => ({ slice }) => {
  const classes = tooltipStyles()
  return (
    <div className={classes.customTooltipContainer}>
      {slice.points.length ? <div>{formatter(slice.points[0].data.x, tooltip?.xFormat)}</div> : null}
      {slice.points.map((point, i) => {
        const match = data.find(x => x.id === point.serieId)
        return (
          <div className='__points' key={i}>
            <span className='__label-color' style={{ backgroundColor: point.color }} />
            <span>{match?.label ?? match?.title}</span>
            <span>{formatter(point.data.y, valueFormat ?? tooltip?.yFormat)}</span>
          </div>
        )
      })}
    </div>
  )
}
/* eslint-enable react/prop-types */

export default Tooltip
