import { useMemo } from 'react'

function hasLowerCase (str, n) {
  const matches = str.match(/[a-z]/g) || []
  return matches.length >= n
}

function hasUpperCase (str, n) {
  const matches = str.match(/[A-Z]/g) || []
  return matches.length >= n
}

function hasNumbers (str, n) {
  const matches = str.match(/[0-9]/g) || []
  return matches.length >= n
}

function hasSpecialChars (str, N) {
  // Escaping special characters that are part of the regex syntax
  const specialCharsRegex = /[!"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~]/g
  const matches = str.match(specialCharsRegex) || []
  return matches.length >= N
}

const validators = {
  minLength: (target) => (value) => {
    return value.length >= target
  },
  minLowerCase: (target) => (value) => {
    return hasLowerCase(value, target)
  },
  minUpperCase: (target) => (value) => {
    return hasUpperCase(value, target)
  },
  minNumber: (target) => (value) => {
    return hasNumbers(value, target)
  },
  minSymbol: (target) => (value) => {
    return hasSpecialChars(value, target) ? 'pass' : 'fail'
  }
}

export const createRuleValidators = (rules) => {
  return rules.reduce((result, [key, target]) => {
    if (key in validators) {
      result[key] = validators[key](target)
    }
    return result
  }, {})
}

export const useRequirementState = (rule, value) => {
  return useMemo(() => {
    const ruleType = rule[0]
    const ruleTarget = rule[1]
    if (!ruleTarget) return 'ignore'
    switch (ruleType) {
      case 'minLength':
        if (!value?.length) return 'fail'
        return value.length >= ruleTarget ? 'pass' : 'fail'
      case 'minLowerCase':
        if (!value?.length) return 'fail'
        return hasLowerCase(value, ruleTarget) ? 'pass' : 'fail'
      case 'minUpperCase':
        if (!value?.length) return 'fail'
        return hasUpperCase(value, ruleTarget) ? 'pass' : 'fail'
      case 'minNumber':
        if (!value?.length) return 'fail'
        return hasNumbers(value, ruleTarget) ? 'pass' : 'fail'
      case 'minSymbol':
        if (!value?.length) return 'fail'
        return hasSpecialChars(value, ruleTarget) ? 'pass' : 'fail'
      case 'excludeUserName':
      case 'excludeCommon':
      case 'minAgeMinutes':
      case 'historyCount':
        return 'defer'
      default:
        return 'ignore'
    }
  }, [rule, value])
}
