import PropTypes from 'prop-types'
import { useAppContext } from '../../redux/slices/appContext'

const PrintOnlyContent = ({ children, force }) => {
  const appContext = useAppContext()

  if (appContext.isPrintView || force) {
    return children
  }

  return null
}

PrintOnlyContent.propTypes = {
  children: PropTypes.node,
  force: PropTypes.bool
}

PrintOnlyContent.defaultProps = {
  children: undefined,
  force: false
}

export default PrintOnlyContent
