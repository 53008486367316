import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import Text from '../../atoms/Text'

const useStyles = makeStyles((theme) => ({
  '@keyframes shadeyIn': {
    from: {
      boxShadow: '0px 5px 5px -2px rgba(0,0,0,0)'
    },
    to: {
      boxShadow: '0px 5px 5px -2px rgba(0,0,0,0.25)'
    }
  },
  container: {
    border: `1px solid ${theme.palette.gray.darker}`,
    minHeight: '3rem',
    padding: '1.5rem 2rem',
    borderRadius: '5px',
    minWidth: '10rem',
    maxWidth: '15rem',
    width: '100%',
    transition:
      'border-color .25s ease-in-out, background-color .25s ease-in-out',
    animationDirection: 'reverse',
    alignSelf: 'stretch',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    '&:hover': {
      cursor: 'pointer',
      border: `1px solid ${theme.palette.primary.main}`,
      animation: '$shadeyIn .25s ease-in-out forwards',
      boxShadow: '0px 5px 5px -2px rgba(0,0,0,0.25)'
    },
    '&:active': {
      backgroundColor: theme.palette.gray.light,
      transition: 'background-color 0s'
    },
    '&.__selected': {
      outline: `1px solid ${theme.palette.primary.dark}`,
      background: 'linear-gradient(0deg, #F5F6F8 0%, #F5F6F8 100%), #FFF'
    },
    '& .__children': {
      paddingTop: '0.625rem'
    }
  }
}))

const FeaturedFiltersItem = ({
  displayName,
  isSelected,
  onClick,
  children
}) => {
  const classes = useStyles()
  return (
    <div
      className={clsx(classes.container, {
        __selected: isSelected
      })}
      onClick={onClick}
    >
      <Text
        text={displayName}
        lineHeight='1'
        variant='body2'
        customFontWeight='bold'
        customFontSize='1.625rem'
      />
      {children && <div className='__children'>{children}</div>}
    </div>
  )
}

FeaturedFiltersItem.propTypes = {
  displayName: PropTypes.string,
  onClick: PropTypes.func,
  isSelected: PropTypes.bool,
  children: PropTypes.node
}

export default FeaturedFiltersItem
