import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  divider: {
    border: `1px solid ${theme.palette.gray.light}`,
    width: '100%'
  }
}))

function Divider ({ additionalClasses }) {
  const classes = useStyles()
  return <hr className={clsx(classes.divider, additionalClasses)} />
}

Divider.propTypes = {
  additionalClasses: PropTypes.string
}

Divider.defaultProps = {
  additionalClasses: ''
}

export default Divider
