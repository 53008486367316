import React from 'react'
import PropTypes from 'prop-types'
import { Box, makeStyles } from '@material-ui/core'
import isString from 'lodash/isString'
import Text from '../../atoms/Text'

const useStyles = makeStyles(() => ({
  title: {
    fontSize: '0.875rem'
  }
}))

const BarChartContainer = ({ title, height, width, children }) => {
  const classes = useStyles()
  return (
    <Box height={height} width={width}>
      {isString(title) ? (
        <Text className={classes.title} text={title} />
      ) : (
        title
      )}
      {children}
    </Box>
  )
}

BarChartContainer.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

BarChartContainer.defaultProps = {
  title: '',
  height: '400px',
  width: '100%'
}

export default BarChartContainer
