import { useMemo } from 'react'
import { ContentCell, CustodianImageCell } from './TableCells'

const columns = [
  {
    Header: 'Accounts',
    id: 'levelName',
    accessor: 'levelName',
    width: 600,
    Cell: ContentCell
  },
  {
    Header: 'Account #',
    id: 'accountNumber',
    accessor: 'accountNumber',
    align: 'left',
    width: 80,
    Cell: ContentCell
  },
  {
    Header: 'Custodian',
    id: 'custodianImage',
    accessor: 'custodian.imageUrl',
    align: 'center',
    width: 80,
    Cell: CustodianImageCell
  },
  {
    Header: 'As of Date',
    id: 'asOfDate',
    accessor: 'asOfDate',
    align: 'left',
    width: 80,
    Cell: ContentCell
  }
]

export const useColumns = (columnKeys = ['levelName', 'accountNumber', 'custodianImage', 'asOfDate']) => {
  return useMemo(() => {
    return columns.filter((column) => columnKeys.includes(column.id))
  }, [columnKeys])
}

export const useGetSearchQuery = (groupTypeId, clientId) => {
  return useMemo(() => {
    if (!clientId) return null

    return {
      filters: {
        hasGroupTypeAssigned: [groupTypeId],
        assignedToClientIds: [clientId]
      },
      includes: {
        custodian: true,
        groups: [groupTypeId]
      }
    }
  }, [groupTypeId, clientId])
}
