import React, { useRef } from 'react'
import { makeStyles } from '@material-ui/core'
import SectionScreen from '../SectionScreen'
import { useClientDetails } from '../ClientDetailsFormContext'
import SydButton from '../../../../commonDesign/Button'
import { usePolicy } from '../../../../../hooks/usePolicy'
import ModifyRestrictionsDialog from './ModifyRestrictionsDialog'

const useStyles = makeStyles((theme) => ({
  userInfoSection: {
    position: 'relative',
    padding: '10px 0'
  },
  item: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.layout.gap.medium,
    alignItems: 'center',
    padding: theme.layout.padding.thick,
    fontWeight: theme.typography.weights.light,
    '& header': {
      fontWeight: theme.typography.weights.bold,
      fontSize: theme.typography.fontSizes.xl
    }
  },
  normal: {
    fontSize: theme.typography.fontSizes.xl
  }
}))

function mapRestriction (client) {
  return client.restricted ? 'Restricted' : 'Standard'
}

function ActionsSection () {
  const classes = useStyles()
  const { client, refetchClient } = useClientDetails()
  const canEdit = usePolicy('admin_edit_client_restriction')
  const modifyRestrictionRef = useRef()

  return (
    <SectionScreen sectionName='actions' className={classes.userInfoSection}>
      <div className={classes.item}>
        <header>Client Visibility Level</header>
        {canEdit ? (
          <div>
            <SydButton
              variant='outline'
              icon='edit'
              size='sm'
              onClick={() => modifyRestrictionRef.current.open(client)}
            >
              {mapRestriction(client)}
            </SydButton>
          </div>
        ) : (
          <div className={classes.normal}>
            <em>{mapRestriction(client)}</em>
          </div>
        )}
        <ModifyRestrictionsDialog ref={modifyRestrictionRef} onComplete={refetchClient} />
      </div>
    </SectionScreen>
  )
}

export default ActionsSection
