import { InputAdornment, TextField } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import PropTypes from 'prop-types'
import React from 'react'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles((theme) => ({
  searchInput: {
    border: '1px solid #ECECEC',
    borderRadius: '8px',
    padding: '6px 8px',
    minWidth: '100px'
  },
  searchInputInner: {
    minWidth: '100px !important'
  }
}))

const SearchInput = ({
  placeholder = '',
  params
}) => {
  const classes = useStyles()
  return (
    <TextField
      {...params}
      placeholder={placeholder}
      InputProps={{
        ...params.InputProps,
        classes: {
          input: classes.searchInputInner
        },
        startAdornment: (
          <>
            <InputAdornment position='start'>
              <SearchIcon />
            </InputAdornment>
            {params.InputProps.startAdornment}
          </>
        ),
        disableUnderline: true
      }}
      classes={{
        root: classes.searchInput
      }}
    />
  )
}
SearchInput.propTypes = {
  placeholder: PropTypes.string,
  params: PropTypes.object.isRequired
}

export default SearchInput
