import React, { useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'
import noop from 'lodash/noop'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, Box, useTheme } from '@material-ui/core'
import clsx from 'clsx'
import first from 'lodash/first'
import { BLOCK_TYPES, SIZE_VARIANTS } from '../../constants'
import {
  calcPositivePercentage,
  numeralByCase,
  tableNumberFormatter,
  createDataTestId
} from '../../utils'
import NumberFormat from '../atoms/NumberFormat'
import Avatar from '../atoms/Avatar'
import Text from '../atoms/Text'
import TreeMapBlock from './TreeMapBlock'

const STANDARD_HEIGHT = 700
const BLOCKS_MARGIN = 15
const MIN_HEIGHT_PERCENTAGE = 10

const useStyles = makeStyles((theme) => ({
  container: ({ totalHeight }) => ({
    flexGrow: 1,
    padding: '1rem',
    minHeight: totalHeight
  }),
  stripeBackground: {
    borderRadius: '0.5rem',
    background:
      'repeating-linear-gradient(45deg, #dedede, #dedede 5px, #FFF 5px, #FFF 10px)'
  },
  treeDividerContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    margin: '0 0.5rem',
    alignItems: 'center'
  },
  treeDividerLine: {
    width: '1.25rem',
    borderBottom: '3px solid #DEDEDE'
  },
  treeDividerBranches: {
    width: '1.5rem',
    height: '100%',
    borderTopLeftRadius: '1rem',
    borderTopRightRadius: 0,
    borderBottomLeftRadius: '1rem',
    borderBottomRightRadius: 0,
    borderRight: 0,
    border: '3px solid #DEDEDE'
  },
  blockContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  blockTitleContainer: {
    display: 'flex',
    minWidth: '12rem',
    paddingLeft: '1.5rem',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  blockTitle: {
    lineHeight: '1.2',
    fontSize: '1.25rem'
  },
  blockSubTitle: {
    fontWeight: 400,
    lineHeight: '1.0',
    fontSize: '0.75rem',
    marginTop: '0.25rem',
    color: theme.palette.dustyGray
  },
  horizontalBlock: {
    marginBottom: `${BLOCKS_MARGIN}px`
  },
  topContainer: ({ topContainerHeight, topContainerMargin }) => ({
    height: topContainerHeight,
    marginBottom: topContainerMargin ? `${BLOCKS_MARGIN * 2}px` : '0px'
  }),
  bottomContainer: ({ bottomContainerHeight }) => ({
    height: bottomContainerHeight,
    marginTop: `${BLOCKS_MARGIN * 2}px`
  })
}))

function getBlocksEndingValue (blocks) {
  return blocks.reduce((acc, block) => {
    if (!isEmpty(block?.details)) {
      return (
        acc +
        block.details.reduce(
          (detailsAcc, blockDetail) => detailsAcc + blockDetail.endingValue,
          0
        )
      )
    }
    return acc + block.endingValue
  }, 0)
}

function getBlocksData (data, blockType) {
  const blocks = data
    .filter((block) => block.blockType === blockType)
    .map((block) => ({
      ...block,
      endingValue: !isEmpty(block?.details)
        ? block.details.reduce(
          (detailsAcc, blockDetail) => detailsAcc + blockDetail.endingValue,
          0
        )
        : block.endingValue
    }))
  const value = getBlocksEndingValue(blocks)
  return [value ? blocks : [], value]
}

function getBlocksWithPercentage (blocks, total) {
  const blocksWithPercentage = blocks.map((block) => {
    const originalPercentage = calcPositivePercentage(
      block.endingValue || 0,
      total
    )
    const percentage =
      originalPercentage !== 0 && originalPercentage < MIN_HEIGHT_PERCENTAGE
        ? MIN_HEIGHT_PERCENTAGE
        : originalPercentage
    return { ...block, percentage, originalPercentage }
  })
  return blocksWithPercentage
}

function processData (data, height) {
  const [coreBlocks, coreValue] = getBlocksData(data, BLOCK_TYPES.core)
  const [sideBlocks, sideValue] = getBlocksData(data, BLOCK_TYPES.side)
  const [bottomBlocks, bottomValue] = getBlocksData(data, BLOCK_TYPES.bottom)
  const bottomPositiveValue = Math.abs(bottomValue)
  const totalAssetsValue = coreValue + sideValue
  const totalAssetsRatio = 1
  const totalBottomRatio = bottomPositiveValue / totalAssetsValue

  const cashValue = coreBlocks
    .filter((x) => x.levelId === 2 && x.levelType === 51)
    .reduce((acc, block) => {
      const returnValue = acc + block.endingValue
      return returnValue
    }, 0)

  const newCoreBlocks = coreBlocks.filter(
    (source, i, block) =>
      block.findIndex((x) => x.levelId === source.levelId) === i
  )

  const cashItem = coreBlocks.findIndex((x) => x.levelId === 2)
  if (newCoreBlocks[cashItem]) {
    newCoreBlocks[cashItem].endingValue = cashValue
  }

  const coreBlocksWithPercentages = getBlocksWithPercentage(
    newCoreBlocks,
    totalAssetsValue
  )
  const sideBlocksWithPercentages = getBlocksWithPercentage(
    sideBlocks,
    totalAssetsValue
  )
  const bottomBlocksWithPercentages = getBlocksWithPercentage(
    bottomBlocks,
    bottomValue
  )
  const totalAssetsBlocks =
    coreBlocksWithPercentages.length + sideBlocksWithPercentages.length
  const totalBottomBlocks = bottomBlocksWithPercentages.length

  const topContainerHeight =
    height * totalAssetsRatio + (totalAssetsBlocks - 1) * BLOCKS_MARGIN
  const bottomContainerHeight =
    bottomValue === 0
      ? 0
      : height * totalBottomRatio + (totalBottomBlocks - 1) * BLOCKS_MARGIN

  const totalHeight =
    bottomValue === 0
      ? topContainerHeight
      : topContainerHeight + bottomContainerHeight + 2 * BLOCKS_MARGIN

  return {
    bottomBlocks: bottomBlocksWithPercentages,
    bottomContainerHeight: `${bottomContainerHeight}px`,
    bottomValue,
    coreBlocks: coreBlocksWithPercentages,
    sideBlocks: sideBlocksWithPercentages,
    topContainerHeight: `${topContainerHeight}px`,
    totalAssetsValue,
    totalHeight: `${totalHeight}px`
  }
}

const formatNumber = (value) => {
  return numeralByCase(value, '0,0a', '0,0.0a')
}

function TreeMapChart ({
  clientName,
  data,
  height,
  liabilitiesBlockLabel,
  totalTitle,
  blocksTitle,
  totalBlockLabel,
  onClick,
  profilePic,
  totalBackgroundColor,
  totalLiabilitiesBackgroundColor,
  width
}) {
  const theme = useTheme()
  const fixedHight = height || STANDARD_HEIGHT
  const {
    bottomBlocks,
    bottomContainerHeight,
    bottomValue,
    coreBlocks,
    sideBlocks,
    topContainerHeight,
    totalAssetsValue,
    totalHeight
  } = useMemo(() => processData(data, fixedHight), [data, fixedHight])

  const classes = useStyles({
    bottomContainerHeight,
    topContainerHeight,
    topContainerMargin: !(bottomValue === 0),
    totalHeight,
    width
  })
  const renderedDivider = useMemo(
    () => (
      <Box className={classes.treeDividerContainer}>
        <Box className={classes.treeDividerLine} />
        <Box className={classes.treeDividerBranches} />
      </Box>
    ),
    [
      classes.treeDividerLine,
      classes.treeDividerBranches,
      classes.treeDividerContainer
    ]
  )

  const renderBlockTitle = useCallback(
    ({ blockValue, subtitle, isLiabilityBlock = false }) => {
      let title = numeralByCase(blockValue)
      let number = formatNumber(blockValue)
      if (isLiabilityBlock || blockValue < 0) {
        title = `(${numeralByCase(Math.abs(blockValue))})`
        number = `(${tableNumberFormatter(Math.abs(blockValue))})`
      }
      return (
        <div className={classes.blockTitleContainer}>
          <div className={classes.blockTitle}>
            <NumberFormat title={title} number={number} skipFormat />
          </div>
          <div className={classes.blockSubTitle}>{subtitle}</div>
        </div>
      )
    },
    [classes.blockTitle, classes.blockSubTitle, classes.blockTitleContainer]
  )

  const renderedTotalAssetsBlock = useMemo(() => {
    const backgroundColor = totalBackgroundColor || '#C4E4D3'
    return (
      <div key='totalAssets' className={classes.blockContainer}>
        <TreeMapBlock
          titleText={
            <Box color={theme.palette.getContrastText(backgroundColor)}>
              <NumberFormat
                title={numeralByCase(totalAssetsValue)}
                number={formatNumber(totalAssetsValue)}
                skipFormat
                useParenthesis
              />
            </Box>
          }
          subtitleText={totalBlockLabel}
          backgroundColor={backgroundColor}
          value={100}
          blockValue={totalAssetsValue}
          dataTestId='total-assets'
        />
      </div>
    )
  }, [theme, classes.blockContainer, totalBackgroundColor, totalAssetsValue, totalBlockLabel])

  const renderedCoreBlocks = useMemo(() => {
    return (
      <div className={classes.blockContainer}>
        {sideBlocks.map((block) => {
          const backgroundColor = block?.blockColor || first(block.details)?.blockColor
          return (
            <div
              key={block.levelId}
              className={classes.horizontalBlock}
              style={{ height: `${block.percentage}%` }}
            >
              <Box height='100%' display='flex' flexDirection='row'>
                <TreeMapBlock
                  titleText={
                    <NumberFormat
                      title={numeralByCase(block.endingValue)}
                      number={formatNumber(block.endingValue)}
                      skipFormat
                    />
                  }
                  showDetails
                  details={block.details}
                  value={block.percentage}
                  subtitleText={block.title}
                  blockValue={block.endingValue}
                  backgroundColor={backgroundColor}
                />
                {renderBlockTitle({
                  blockValue: block.endingValue,
                  subtitle: block.title
                })}
              </Box>
            </div>
          )
        })}
        {coreBlocks.map((block, index) => {
          if (block.endingValue === 0) return null
          const height = `${block.percentage}%`

          const isLastBlock = index === coreBlocks.length - 1
          const backgroundColor = block?.blockColor || first(block.details)?.blockColor
          return (
            <div
              className={clsx({ [classes.horizontalBlock]: !isLastBlock })}
              key={block.levelId}
              style={{ height }}
              onClick={() => onClick({ ...block, height: block.percentage })}
            >
              <Box height='100%' display='flex' flexDirection='row'>
                <TreeMapBlock
                  titleText={
                    <NumberFormat
                      title={numeralByCase(block.endingValue)}
                      number={formatNumber(block.endingValue)}
                      skipFormat
                    />
                  }
                  showDetails
                  details={block.details}
                  value={block.percentage}
                  subtitleText={block.title}
                  blockValue={block.endingValue}
                  backgroundColor={backgroundColor}
                  dataTestId={createDataTestId(block.title, '-')}
                />
                {renderBlockTitle({
                  blockValue: block.endingValue,
                  subtitle: block.title
                })}
              </Box>
            </div>
          )
        })}
      </div>
    )
  }, [onClick, classes, sideBlocks, coreBlocks, renderBlockTitle])

  const renderedLiabilitiesBlocks = useMemo(() => {
    return (
      <div className={classes.blockContainer}>
        {bottomBlocks.map((block, index) => {
          const isLastBlock = index === bottomBlocks.length - 1
          return (
            <div
              key={block.levelId}
              className={clsx({ [classes.horizontalBlock]: !isLastBlock })}
              style={{
                height: `${block.percentage}%`
              }}
            >
              <Box height='100%' display='flex' flexDirection='row'>
                <TreeMapBlock
                  titleText={
                    <NumberFormat
                      title={numeralByCase(
                        Math.abs(block.endingValue),
                        '($0,0)',
                        '($0,0.00)'
                      )}
                      number={formatNumber(block.endingValue)}
                      skipFormat
                    />
                  }
                  showDetails
                  useStripedBackground
                  details={block.details}
                  value={block.percentage}
                  blockValue={block.endingValue}
                  backgroundColor={theme.palette.silver}
                  detailsBackgroundColor={theme.palette.white}
                  subtitleText={block.title}
                  dataTestId={createDataTestId(block.title, '-')}
                />
                {renderBlockTitle({
                  subtitle: block.title,
                  isLiabilityBlock: true,
                  blockValue: block.endingValue
                })}
              </Box>
            </div>
          )
        })}
      </div>
    )
  }, [
    theme,
    bottomBlocks,
    renderBlockTitle,
    classes.blockContainer,
    classes.horizontalBlock
  ])

  const renderedMainLiabilitiesBlock = useMemo(() => {
    const title = `(${numeralByCase(Math.abs(bottomValue))})`
    const number = `(${formatNumber(Math.abs(bottomValue))})`
    return (
      <div
        key='totalLiabilities'
        className={classes.stripeBackground}
        style={{ height: '100%' }}
      >
        <TreeMapBlock
          titleText={<NumberFormat title={title} number={number} skipFormat />}
          subtitleText={liabilitiesBlockLabel}
          backgroundColor={totalLiabilitiesBackgroundColor}
          value={100}
          blockValue={bottomValue}
          detailsBackgroundColor={theme.palette.white}
          useStripedBackground
          dataTestId='totalLiabilities'
        />
      </div>
    )
  }, [
    theme,
    classes.stripeBackground,
    bottomValue,
    liabilitiesBlockLabel,
    totalLiabilitiesBackgroundColor
  ])

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={1} />
            <Grid item xs={11}>
              <Grid container>
                <Grid item xs={2}>
                  <Box ml='6.8rem'>
                    <Text
                      customFontSize='1.5rem'
                      customFontWeight='bold'
                      text={totalTitle}
                    />
                  </Box>
                </Grid>
                <Grid item xs={10}>
                  <Box ml='11.5rem'>
                    <Text
                      customFontSize='1.5rem'
                      customFontWeight='bold'
                      text={blocksTitle}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container className={classes.container}>
        <Grid item xs={1}>
          <Box
            height='100%'
            display='flex'
            alignItems='center'
            justifyContent='center'
          >
            <Avatar
              size={SIZE_VARIANTS.xLarge}
              src={profilePic}
              customSize='100px'
              avatarLetters={clientName}
              useOneInitial
            />
          </Box>
        </Grid>
        <Grid item xs={11}>
          <Box
            height='100%'
            display='flex'
            flexDirection='row'
            position='relative'
          >
            {renderedDivider}
            <Grid container>
              <Grid item xs={12}>
                <Grid
                  container
                  name='top-total-container'
                  className={classes.topContainer}
                >
                  <Grid
                    name='total-assets-container'
                    item
                    xs={2}
                    style={{ height: '100%' }}
                  >
                    {renderedTotalAssetsBlock}
                  </Grid>
                  <Grid item xs={10} name='top-container'>
                    <Box
                      height='100%'
                      display='flex'
                      flexDirection='row'
                      position='relative'
                    >
                      {renderedDivider}
                      {renderedCoreBlocks}
                    </Box>
                  </Grid>
                  {!isEmpty(bottomBlocks) && (
                    <Box
                      my={3}
                      height={0}
                      width='100% !important'
                      style={{
                        opacity: 0.2,
                        marginBottom: `${BLOCKS_MARGIN * 2}px`
                      }}
                      className={classes.treeDividerLine}
                    />
                  )}
                </Grid>
              </Grid>
              {!isEmpty(bottomBlocks) && (
                <Grid
                  item
                  xs={12}
                  name='bottom-container'
                  className={classes.bottomContainer}
                >
                  <Grid container style={{ height: '100%' }}>
                    <Grid item xs={2}>
                      {renderedMainLiabilitiesBlock}
                    </Grid>
                    <Grid item xs={10}>
                      <Box
                        height='100%'
                        display='flex'
                        flexDirection='row'
                        position='relative'
                      >
                        {renderedDivider}
                        {renderedLiabilitiesBlocks}
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </>
  )
}

TreeMapChart.propTypes = {
  clientName: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.any),
  height: PropTypes.string,
  liabilitiesBlockLabel: PropTypes.string,
  totalBlockLabel: PropTypes.string,
  totalTitle: PropTypes.string,
  blocksTitle: PropTypes.string,
  onClick: PropTypes.func,
  profilePic: PropTypes.string,
  totalBackgroundColor: PropTypes.string,
  totalLiabilitiesBackgroundColor: PropTypes.string,
  width: PropTypes.string
}

TreeMapChart.defaultProps = {
  clientName: '',
  data: [],
  height: undefined,
  liabilitiesBlockLabel: 'Leverage',
  totalBlockLabel: 'Assets',
  totalTitle: 'Breakdown',
  blocksTitle: 'Assets & Liabilities',
  onClick: noop,
  profilePic: undefined,
  totalBackgroundColor: undefined,
  totalLiabilitiesBackgroundColor: undefined,
  width: undefined
}

export default TreeMapChart
