import React from 'react'
import { makeStyles } from '@material-ui/core'
import { useCheckPolicy } from '../../../../../hooks'
import { ACCOUNTS } from '../../../../../policies/admin'
import EmptySection from '../../../../atoms/EmptySection'
import ConfigurableView from '../../ConfigurableView'
import AccountsList from './AccountsList'

const useStyles = makeStyles(() => ({
  container: {
    padding: '1rem'
  }
}))

const AccountsView = () => {
  const classes = useStyles()
  const canViewAccounts = useCheckPolicy(ACCOUNTS.viewAccounts)

  if (!canViewAccounts) {
    return (
      <EmptySection
        title='Access Denied'
        description='You do not have sufficient permissions to access this resource'
      />
    )
  }

  return (
    <div className={classes.container}>
      <ConfigurableView viewPath='@admin-accounts-list'>
        <AccountsList />
      </ConfigurableView>
    </div>
  )
}

AccountsView.propTypes = {}

export default AccountsView
