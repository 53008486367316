import React, { useMemo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import FadeIn from '../../molecules/Transitions/FadeIn'
import BalanceIcon from './icons/BalanceIcon'
import CurrencyExchangeIcon from './icons/CurrencyExchangeIcon'
import SavingsIcon from './icons/SavingsIcon'
import OptionCard from './OptionCard'
import SwapVerticalIcon from './icons/SwapVerticalIcon'
import AgricultureIcon from './icons/AgricultureIcon'
import ClientDisplay from './components/ClientDisplay'
import { usePreloadClientAnalysis } from './hooks/usePreloadClientAnalysis'
import LastSessionLink from './components/LastSessionLink'

const useStyles = makeStyles({
  '@keyframes topOptionsIn': {
    '0%': {
      transform: 'translateX(100px)'
    },
    '100%': {
      transform: 'translateX(0)'
    }
  },
  '@keyframes subOptionsIn': {
    '0%': {
      transform: 'translateX(-100px)'
    },
    '100%': {
      transform: 'translateX(0)'
    }
  },
  workflowView: {
    width: '100%'
  },
  guidance: {
    fontWeight: '600',
    fontSize: '4.5rem',
    lineHeight: '5rem',
    textShadow: '2px 2px 5px rgba(0,0,0,0.1)',
    padding: 0,
    margin: 0
  },
  guidanceSubtext: {
    fontSize: '1.2rem',
    padding: '1rem 0rem',
    margin: 0,
    textAlign: 'left',
    fontWeight: '100'
  },
  options: {
    display: 'flex',
    justifyContent: 'center',
    padding: '3rem 0 15px',
    animation: '$topOptionsIn .3s ease-in-out .1s'
  },
  optionsContainer: {
    display: 'flex',
    width: '35rem',
    height: '14rem',
    gap: '15px',
    justifyContent: 'space-evenly'
  },
  subOptions: {
    display: 'flex',
    justifyContent: 'center',
    animation: '$subOptionsIn .3s ease-in-out .1s'
  },
  subOptionsContainer: {
    display: 'flex',
    gap: '15px',
    width: '35rem',
    height: '9.5rem',
    justifyContent: 'space-evenly'
  },
  header: {
    height: '60px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  }
})

const WorkflowView = (props) => {
  const history = useHistory()
  const classes = useStyles(props)
  const { workflowOptions, subOptions } = props

  const clientId = useMemo(() => {
    const url = new URL(window.location)

    return +url.searchParams.get('clientId')
  }, [])
  usePreloadClientAnalysis(clientId)

  const mainOptions = useMemo(() => {
    return workflowOptions.map(wfo => ({
      ...wfo,
      to: `/rebalancer/analysis/${wfo.value}${history.location.search}`
    }))
  }, [history, workflowOptions])

  const minorOptions = useMemo(() => {
    return subOptions.map(wfo => ({
      ...wfo,
      to: `/rebalancer/analysis/${wfo.value}${history.location.search}`
    }))
  }, [history, subOptions])

  return (
    <div className={classes.workflowView}>
      <div className={classes.header}>
        <ClientDisplay clientId={clientId} />
        <LastSessionLink clientId={clientId} />
      </div>
      <FadeIn>
        <h1 className={classes.guidance}>Next, select what you'd like to do...</h1>
      </FadeIn>
      <FadeIn delay={0.1}>
        <p className={classes.guidanceSubtext}>
          Choose the workflow and we'll walk you through analysis and trade review
        </p>
      </FadeIn>
      <FadeIn delay={0.2}>
        <div className={classes.options}>
          <div className={classes.optionsContainer}>
            {mainOptions.map((opt, i) => (
              <OptionCard
                to={opt.to}
                outlined
                icon={opt.icon}
                key={opt.value}
                title={opt.title}
                size='lg'
                fullSize
              />
            ))}
          </div>
        </div>
      </FadeIn>
      <FadeIn delay={0.3}>
        <div className={classes.subOptions}>
          <div className={classes.subOptionsContainer}>
            {minorOptions.map((opt) => (
              <OptionCard
                to={opt.to}
                outlined
                allowHover
                fullSize
                size='md'
                icon={opt.icon}
                key={opt.value}
                title={opt.title}
              />
            ))}
          </div>
        </div>
      </FadeIn>
    </div>
  )
}

WorkflowView.propTypes = {
  workflowOptions: PropTypes.array,
  subOptions: PropTypes.array
}

WorkflowView.defaultProps = {
  workflowOptions: [
    {
      title: 'Rebalance',
      value: 'rebalance',
      icon: BalanceIcon
    },
    {
      title: 'Raise Cash',
      value: 'raiseCash',
      icon: SavingsIcon
    }
  ],
  subOptions: [
    {
      title: 'Distribute Cash',
      value: 'distributeCash',
      icon: CurrencyExchangeIcon
    },
    {
      title: 'Tax Loss Harvest',
      value: 'taxLossHarvest',
      icon: AgricultureIcon
    },
    {
      title: 'Security Substitution',
      value: 'securitySubstitution',
      icon: SwapVerticalIcon
    }
  ]
}

export default WorkflowView
