import React from 'react'
import PropTypes from 'prop-types'
import { Box } from '@material-ui/core'
import Text from '../../../../atoms/Text'
import RecentClientsProvider from './RecentClientsProvider'
import ClientItem from './ClientItem'

const RecentClients = ({ take, title }) => {
  return (
    <Box height='100%' width='100%'>
      <Box mb='1.5rem'>
        <Text variant='body2' customFontSize='1.2rem'>
          {title}
        </Text>
      </Box>
      <RecentClientsProvider take={take}>
        {(clients) => (
          <div>
            {clients.map(client => (
              <ClientItem key={client.clientId} client={client} />
            ))}
          </div>
        )}
      </RecentClientsProvider>
    </Box>
  )
}

RecentClients.propTypes = {
  take: PropTypes.number,
  title: PropTypes.string
}

RecentClients.defaultProps = {
  take: 5,
  title: 'Recent Clients'
}

export default RecentClients
