import React from 'react'
import { useMilestonesContext } from '../MilestonesContext'
import DateTimePicker from '../../../atoms/DateTimePicker'
import { disableHolidaysAndWeekends } from '../../../../utils'

const pickerStyles = {
  fontSize: '1rem',
  minWidth: '16rem',
  fontFamily: 'Gotham-Book',
  '& input': {
    textAlign: 'left'
  }
}

const AsOfDatePicker = () => {
  const { selectedAsOfDate, setAsOfDate } = useMilestonesContext()

  return (
    <DateTimePicker
      type='date'
      format='[As of] MMMM DD, YYYY'
      disableFuture={false}
      onChange={setAsOfDate}
      value={selectedAsOfDate}
      extraStyles={pickerStyles}
      defaultValue={selectedAsOfDate}
      shouldDisableDate={disableHolidaysAndWeekends}
    />
  )
}
export default AsOfDatePicker
