import React, { useMemo, useState } from 'react'
import { Divider, makeStyles } from '@material-ui/core'

import { Alert, AlertTitle } from '@material-ui/lab'
import { useOktaAuth } from '@okta/okta-react'
import { BUTTON_SIZES, TEXT_VARIANTS } from '../../../../../constants'
import OperationalTable, { useOperationalTable } from '../../../../organisms/OperationalTable'
import Text from '../../../../atoms/Text'
import { useListReportTemplatesData } from '../hooks'
import RightPanel from '../../../../organisms/RightPanel'
import { useAuthState, usePermissions } from '../../../../../hooks'
import RoundedButton from '../../../../atoms/RoundedButton'
import SnackAlert from '../../../../molecules/SnackAlert/SnackAlert'
import TemplateForm from './TemplateForm'
import { useTemplatesColumnConfig } from './hooks'

const useStyles = makeStyles(() => ({
  container: {
    padding: '1rem',
    display: 'flex',
    flex: '1',
    flexDirection: 'column'
  },
  statusContainer: {
    display: 'flex',
    justifyContent: 'flex-start'
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'start',
    padding: '1rem 0 0',
    marginBottom: '2rem'
  },
  backLink: {
    display: 'flex',
    marginBottom: '.5rem'
  },
  tableHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  status: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '3px 12px 0px',
    gap: '10px',
    width: 'auto',
    maxWidth: '350px',
    height: '26px',
    background: '#E8F0FD',
    border: '1px solid #DBE8FF',
    borderRadius: '8px'
  },
  success: {
    color: '#368759',
    backgroundColor: '#F0F9F4',
    border: '1px solid #DAF0E3'
  },
  warning: {
    color: '#E57373',
    backgroundColor: '#FAEAEA',
    border: '1px solid #E57373'
  }
}))

const Templates = () => {
  // auth & summit check
  const { authState: oktaAuthState } = useOktaAuth()
  const authState = useAuthState(oktaAuthState)
  const { isSummitUser } = usePermissions(authState.accessToken || {})

  const initialFormState = {
    templateId: null,
    name: '',
    content: ''
  }

  const [isOpen, setIsOpen] = useState(false)
  const classes = useStyles()
  const [editTemplateData, setEditTemplateData] = useState({})
  const [alert, setAlert] = useState({})

  const updateTemplateForm = (data) => {
    setEditTemplateData(data)
    setIsOpen(!!Object.keys(data)?.length)
  }

  const submittedTemplate = async alert => {
    if (alert) {
      setAlert(alert)
    }
    updateTemplateForm({})
    await refetch()
  }

  const templatesColumnConfig = useTemplatesColumnConfig({ classes, updateTemplateForm })
  const {
    defaultPageSize,
    pageIndex,
    pageSize,
    sort,
    onPagingChange,
    onSortingChange,
    onTableModeChange
  } = useOperationalTable(templatesColumnConfig.defaultSort)

  const options = useMemo(() => ({
    pageIndex,
    pageSize,
    sort
  }), [pageIndex, pageSize, sort])

  const { data, loading, refetch } = useListReportTemplatesData(options)

  return (
    (isSummitUser ? (
      <div className={classes.container}>
        <div className={classes.titleContainer}>
          <Text text='Manage Templates' variant={TEXT_VARIANTS.h1} />
          <RoundedButton primary size={BUTTON_SIZES.small} onClick={() => updateTemplateForm(initialFormState)}>
            Create New Template
          </RoundedButton>
        </div>

        <RightPanel
          open={isOpen}
          title={
            <Text
              text={editTemplateData.templateId ? 'Edit Template' : 'Create Template'}
              variant={TEXT_VARIANTS.h2}
            />
          }
          width='850px'
        >
          <TemplateForm
            editTemplateData={editTemplateData}
            onSubmit={submittedTemplate}
            onSetEditTemplateData={updateTemplateForm}
          />
        </RightPanel>

        <Divider />

        <OperationalTable.Wrapper>
          <OperationalTable
            mode='server'
            columns={templatesColumnConfig.columns}
            data={data}
            defaultPageSize={defaultPageSize}
            defaultSort={templatesColumnConfig.defaultSort}
            itemName='Templates'
            loading={loading}
            total={data?.length || 0}
            onSortingChange={onSortingChange}
            onPagingChange={onPagingChange}
            onTableModeChange={onTableModeChange}
          />
        </OperationalTable.Wrapper>
        <SnackAlert alert={alert} />
      </div>
    ) : (
      <div className={classes.container}>
        <Alert
          icon={false}
          severity='warning'
        >
          <AlertTitle>Access Denied</AlertTitle>
          You do not have access to customize templates
        </Alert>
      </div>
    ))
  )
}

export default Templates
