import { noop } from 'lodash'
import { createContext, useContext } from 'react'

export const ContextualClientContext = createContext({
  client: undefined,
  setContextualClientId: noop
})

export const useContextualClientContext = () => {
  const contextValue = useContext(ContextualClientContext)
  return contextValue
}
