import React from 'react'
import PropTypes from 'prop-types'
import { useTooltipEvents } from './useTooltipEvents'

// eslint-disable-next-line react/prop-types
function ShadedTargetMarker ({ bar, tooltip, xScale, accessor }) {
  const { handleMouseEnter, handleMouseMove, handleMouseLeave } = useTooltipEvents(bar, tooltip)
  const value = bar.data.data[accessor]

  return (
    <rect
      className='__target-box'
      x={xScale(0)}
      y={bar.y}
      width={xScale(value)}
      height={bar.height}
      rx={2}
      ry={2}
      onMouseEnter={handleMouseEnter}
      onMouseMove={handleMouseMove}
      onMouseLeave={handleMouseLeave}
    />
  )
}

ShadedTargetMarker.propTypes = {
  bar: PropTypes.any
}

function ShadedTargetMarkerLayer ({ bars, xScale, tooltip, accessor }) {
  return (
    <>
      {bars.map((b) => (
        <ShadedTargetMarker
          key={b.key}
          bar={b}
          tooltip={tooltip}
          accessor={accessor}
          xScale={xScale}
        />
      ))}
    </>
  )
}

ShadedTargetMarkerLayer.propTypes = {
  bars: PropTypes.array,
  tooltip: PropTypes.any,
  xScale: PropTypes.func,
  accessor: PropTypes.string
}

export default ShadedTargetMarkerLayer
