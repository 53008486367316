import React from 'react'
import PropTypes from 'prop-types'
import { alpha, makeStyles } from '@material-ui/core'
import clsx from 'clsx'

const cardVariants = {
  default: (theme) => ({
    border: `2px solid ${theme.palette.gray.dark}`,
    borderRadius: theme.layout.radius.loose,
    backgroundColor: theme.palette.background.card,
    boxShadow: theme.layout.shadow.medium,
    padding: theme.layout.padding.thick
  }),
  outline: (theme) => ({
    border: `2px solid ${theme.palette.gray.darker}`,
    borderRadius: theme.layout.radius.loose,
    backgroundColor: theme.palette.background.clear,
    boxShadow: theme.layout.shadow.medium,
    padding: theme.layout.padding.thick
  }),
  frosted: (theme) => ({
    position: 'relative',
    border: `2px solid ${theme.palette.gray.dark}`,
    borderRadius: theme.layout.radius.loose,
    backgroundColor: alpha(theme.palette.background.default, 0.5),
    boxShadow: theme.layout.shadow.medium,
    padding: theme.layout.padding.thick,
    '&::before': {
      backdropFilter: 'blur(10px)',
      content: '""',
      position: 'absolute',
      display: 'block',
      width: '100%',
      height: '100%',
      top: 0,
      left: 0,
      zIndex: -1
    }
  }),
  clear: (theme) => ({
    padding: theme.layout.padding.thick
  })
}

const useStyles = makeStyles((theme) => ({
  card: ({ variant }) => ({
    ...(cardVariants[variant](theme) || cardVariants.default(theme))
  })
}))

function Card ({ variant, children, className, style, ...rest }) {
  const classes = useStyles({ variant })

  return (
    <div className={clsx(classes.card, className)} style={style} {...rest}>
      {children}
    </div>
  )
}

Card.propTypes = {
  variant: PropTypes.oneOf(['default', 'outline', 'frosted', 'clear']),
  children: PropTypes.node,
  className: PropTypes.string,
  style: PropTypes.object
}

Card.defaultProps = {
  variant: 'default'
}

export default Card
