import React from 'react'
import { SvgIcon } from '@material-ui/core'
import PropTypes from 'prop-types'

const IconPropTypes = {
  fillColor: PropTypes.string
}

const IconDefaultProps = {
  fillColor: '#000000'
}

const AgricultureFilledIcon = (props) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg' enableBackground='new 0 0 24 24' height='24px' viewBox='0 0 24 24'
      width='24px' fill={props.fillColor}
    >
      <g>
        <rect fill='none' height='24' width='24' />
      </g>
      <g>
        <g>
          <path
            d='M19.5,12c0.93,0,1.78,0.28,2.5,0.76V8c0-1.1-0.9-2-2-2h-6.29l-1.06-1.06l1.41-1.41l-0.71-0.71L9.82,6.35l0.71,0.71 l1.41-1.41L13,6.71V9c0,1.1-0.9,2-2,2h-0.54c0.95,1.06,1.54,2.46,1.54,4c0,0.34-0.04,0.67-0.09,1h3.14 C15.3,13.75,17.19,12,19.5,12z'
          />
          <path
            d='M19.5,13c-1.93,0-3.5,1.57-3.5,3.5s1.57,3.5,3.5,3.5s3.5-1.57,3.5-3.5S21.43,13,19.5,13z M19.5,18 c-0.83,0-1.5-0.67-1.5-1.5s0.67-1.5,1.5-1.5s1.5,0.67,1.5,1.5S20.33,18,19.5,18z'
          />
          <path d='M4,9h5c0-1.1-0.9-2-2-2H4C3.45,7,3,7.45,3,8C3,8.55,3.45,9,4,9z' />
          <path
            d='M9.83,13.82l-0.18-0.47L10.58,13c-0.46-1.06-1.28-1.91-2.31-2.43l-0.4,0.89l-0.46-0.21l0.4-0.9C7.26,10.13,6.64,10,6,10 c-0.53,0-1.04,0.11-1.52,0.26l0.34,0.91l-0.47,0.18L4,10.42c-1.06,0.46-1.91,1.28-2.43,2.31l0.89,0.4l-0.21,0.46l-0.9-0.4 C1.13,13.74,1,14.36,1,15c0,0.53,0.11,1.04,0.26,1.52l0.91-0.34l0.18,0.47L1.42,17c0.46,1.06,1.28,1.91,2.31,2.43l0.4-0.89 l0.46,0.21l-0.4,0.9C4.74,19.87,5.36,20,6,20c0.53,0,1.04-0.11,1.52-0.26l-0.34-0.91l0.47-0.18L8,19.58 c1.06-0.46,1.91-1.28,2.43-2.31l-0.89-0.4l0.21-0.46l0.9,0.4C10.87,16.26,11,15.64,11,15c0-0.53-0.11-1.04-0.26-1.52L9.83,13.82z M7.15,17.77c-1.53,0.63-3.29-0.09-3.92-1.62c-0.63-1.53,0.09-3.29,1.62-3.92c1.53-0.63,3.29,0.09,3.92,1.62 C9.41,15.38,8.68,17.14,7.15,17.77z'
          />
        </g>
      </g>
    </svg>
  )
}

AgricultureFilledIcon.propTypes = IconPropTypes
AgricultureFilledIcon.defaultProps = IconDefaultProps

const AgricultureOutlinedIcon = (props) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg' enableBackground='new 0 0 24 24' height='24px' viewBox='0 0 24 24'
      width='24px' fill={props.fillColor}
    >
      <g>
        <rect fill='none' height='24' width='24' />
      </g>
      <g>
        <g>
          <path d='M4,9h5c0-1.1-0.9-2-2-2H4C3.45,7,3,7.45,3,8C3,8.55,3.45,9,4,9z' />
          <path
            d='M22,14.06V8c0-1.1-0.9-2-2-2h-6.29l-1.06-1.06l1.41-1.41l-0.71-0.71L9.82,6.35l0.71,0.71l1.41-1.41L13,6.71V9 c0,1.1-0.9,2-2,2H8.96c-0.22-0.16-0.45-0.3-0.69-0.43l-0.4,0.89l-0.46-0.21l0.4-0.9C7.26,10.13,6.64,10,6,10 c-0.53,0-1.04,0.11-1.52,0.26l0.34,0.91l-0.47,0.18L4,10.42c-1.06,0.46-1.91,1.28-2.43,2.31l0.89,0.4l-0.21,0.46l-0.9-0.4 C1.13,13.74,1,14.36,1,15c0,0.53,0.11,1.04,0.26,1.52l0.91-0.34l0.18,0.47L1.42,17c0.46,1.06,1.28,1.91,2.31,2.43l0.4-0.89 l0.46,0.21l-0.4,0.9C4.74,19.87,5.36,20,6,20c0.53,0,1.04-0.11,1.52-0.26l-0.34-0.91l0.47-0.18L8,19.58 c1.06-0.46,1.91-1.28,2.43-2.31l-0.89-0.4l0.21-0.46l0.9,0.4c0.1-0.26,0.18-0.54,0.24-0.82h5.16C16.03,16.16,16,16.33,16,16.5 c0,1.93,1.57,3.5,3.5,3.5s3.5-1.57,3.5-3.5C23,15.55,22.62,14.69,22,14.06z M6,18c-1.66,0-3-1.34-3-3s1.34-3,3-3s3,1.34,3,3 S7.66,18,6,18z M10.87,14c-0.04-0.18-0.08-0.35-0.13-0.52l-0.91,0.34l-0.18-0.47L10.58,13c0,0,0.42,0,0.42,0c2.21,0,4-1.79,4-4V8 h5v5.05C19.84,13.03,19.67,13,19.5,13c-0.95,0-1.81,0.38-2.44,1H10.87z M19.5,18c-0.83,0-1.5-0.67-1.5-1.5s0.67-1.5,1.5-1.5 s1.5,0.67,1.5,1.5S20.33,18,19.5,18z'
          />
        </g>
      </g>
    </svg>
  )
}

AgricultureOutlinedIcon.propTypes = IconPropTypes
AgricultureOutlinedIcon.defaultProps = IconDefaultProps

const AgricultureIcon = (props) => {
  const {
    fillColor,
    ...rest
  } = props

  const Icon = props.variant === 'filled' ? AgricultureFilledIcon : AgricultureOutlinedIcon
  return (
    <SvgIcon {...rest}>
      <Icon fillColor={fillColor} />
    </SvgIcon>
  )
}

AgricultureIcon.propTypes = {
  variant: PropTypes.oneOf(['filled', 'outlined']),
  fillColor: PropTypes.string
}

AgricultureIcon.defaultProps = IconDefaultProps

export default AgricultureIcon
