import { useCallback, useState } from 'react'
import { useIsMounted } from './useIsMounted'

export default function useToggle (initialValue = false) {
  const isMounted = useIsMounted()
  const [value, setValue] = useState(initialValue)
  const toggleValue = useCallback(
    () => isMounted && setValue((prevValue) => !prevValue),
    [isMounted]
  )
  const toggleValueOn = useCallback(() => setValue(true), [])
  const toggleValueOff = useCallback(() => setValue(false), [])
  return [value, toggleValue, toggleValueOn, toggleValueOff]
}
