import React from 'react'
import { makeStyles, Grid, alpha } from '@material-ui/core'
import clsx from 'clsx'
import PropTypes from 'prop-types'

const useStyles = makeStyles((theme) => ({
  type: {
    outline: `1px solid ${theme.palette.gray.darker}`,
    borderRadius: theme.layout.radius.round,
    padding: theme.layout.padding.thick,
    fontSize: theme.typography.fontSizes.xxl,
    transition: 'outline .2s ease-in-out, background-color .2s ease-in-out',
    '&:hover': {
      backgroundColor: theme.palette.gray.main,
      cursor: 'pointer'
    },
    '&.__selected': {
      outline: `2px solid ${theme.palette.primary.main}`,
      backgroundColor: alpha(theme.palette.selected, 0.15),
      '&:hover': {
        outline: `2px solid ${theme.palette.primary.main}`
      }
    },
    '& article': {
      fontWeight: theme.typography.weights.light
    }
  }
}))

const DataAccessSelector = React.forwardRef(function DataAccessSelector ({ value, onChange }, _) {
  const classes = useStyles()

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <div
          className={clsx(classes.type, { __selected: value === 'all' })}
          onClick={() => onChange('all')}
          role='button'
        >
          <header>All Accounts Access</header>
          <article>The user will be able to see all clients and accounts with no restrictions.</article>
        </div>
      </Grid>
      <Grid item xs={12}>
        <div
          className={clsx(classes.type, { __selected: value === 'unrestricted' })}
          onClick={() => onChange('unrestricted')}
          role='button'
        >
          <header>Unrestricted Access</header>
          <article>
            <p>The user will be able to see all clients with the exception of restricted clients.</p>
            <p>Restricted clients must be explicitly assigned to the user.</p>
          </article>
        </div>
      </Grid>
      <Grid item xs={12}>
        <div
          className={clsx(classes.type, { __selected: value === 'limited' })}
          onClick={() => onChange('limited')}
          role='button'
        >
          <header>Limited Access</header>
          <article>The user is limited to a specific set of accessible clients.</article>
        </div>
      </Grid>
    </Grid>
  )
})

DataAccessSelector.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func
}

export default DataAccessSelector
