import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { Box, Grid, makeStyles, useTheme } from '@material-ui/core'
import { TEXT_VARIANTS } from '../../../constants'
import Text from '../../atoms/Text'
import ListItem from '../../molecules/ListItem'
import NumberFormat from '../../atoms/NumberFormat'
import { useFormattingContext } from '../FormattingProvider/FormattingContext'
import { VisualBalanceDataShape } from './utils'
import EditAccountButton from './EditAccountButton'

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row'
  },
  sectionBlock: {
    '& + div': {
      marginTop: '1.5rem'
    }
  },
  totalBlock: {
    height: '100%',
    padding: '2.5rem 1.75rem',
    position: 'relative',
    borderRadius: '.5rem',
    display: 'flex',
    alignItems: 'center'
  },
  totalBlockInner: {
    textAlign: 'left'
  },
  lastAssetBlockRow: {
    backgroundColor: theme.palette.gray.lighter,
    padding: '6px 4px !important'
  },
  assetTotalBlock: {
    padding: '6px 4px',
    backgroundColor: theme.palette.gray.lighter
  },
  otherAssetsContainer: {
    border: `3px solid ${theme.palette.gray.lighter}`,
    borderRadius: '1rem'
  },
  content: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row'
  },
  totalContainer: {
    width: '167px',
    backgroundColor: '#D8EDE2',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  divider: {
    opacity: 0.2,
    margin: '2rem 0',
    width: '100%',
    borderBottom: '3px solid #DEDEDE'
  },
  [theme.breakpoints.down('xs')]: {
    totalBlock: {
      padding: '0.5rem',
      display: 'flex',
      justifyContent: 'center'
    },
    totalBlockInner: {
      '& h4': {
        fontSize: '2rem !important'
      },
      '& p': {
        fontSize: '0.75rem !important'
      }
    },
    groupName: {
      marginTop: '1rem'
    }
  }
}))

function VisualBalanceSide ({
  data,
  width,
  height,
  overviewFormat,
  detailFormat
}) {
  const theme = useTheme()
  const classes = useStyles({ height, width })
  const { core, bottom, side, other } = data
  const { formatter } = useFormattingContext()

  const renderAssetBlock = useCallback(
    ({
      displayName: blockTitle,
      total,
      accounts: details = []
    }) => {
      return (
        <Box key={`${blockTitle}`} mb='2rem'>
          <Box mb={1}>
            <Text
              variant={TEXT_VARIANTS.subtitle1}
              customFontWeight='bold'
              customFontSize='1rem'
              text={blockTitle}
            />
          </Box>
          {
            details.map(({
              endingValue, accountId, displayName, accountName, accountLongName
            }) => (
              <ListItem
                key={`asset-block-${accountId}`}
                leftElement={
                  <Box position='relative'>
                    <Text text={displayName || accountName || accountLongName} />
                    <EditAccountButton accountId={accountId} />
                  </Box>
                }
                rightElement={
                  <Text
                    customFontWeight='normal'
                    text={
                      <NumberFormat
                        number={formatter(endingValue, detailFormat)}
                        title={formatter(endingValue, detailFormat)}
                        skipFormat
                      />
                    }
                  />
                }
              />
            ))
          }
          <ListItem
            additionalClasses={classes.assetTotalBlock}
            key={`asset-total-${blockTitle}`}
            noBorderBottom
            rightElement={
              <Text
                customFontWeight='bold'
                text={
                  <NumberFormat
                    number={formatter(total, detailFormat)}
                    title={formatter(total, detailFormat)}
                    skipFormat
                  />
                }
              />
            }
          />
          <Box />
        </Box>
      )
    },
    [classes.assetTotalBlock, detailFormat, formatter]
  )

  const renderSection = useCallback(({ total, groupLongName, groupColorField, subgroups }) => {
    const backgroundColor = groupColorField || '#C4E4D3'
    const color = theme.palette.getContrastText(
      backgroundColor || theme.palette.black
    )

    return (
      <Grid container className={classes.sectionBlock}>
        <Grid item md={4} sm={2} xs={12}>
          <div className={classes.totalBlock} style={{ backgroundColor, color }}>
            <div className={classes.totalBlockInner}>
              <Text
                customFontSize='2.5rem'
                customFontWeight='bold'
                variant={TEXT_VARIANTS.h4}
                text={
                  <NumberFormat
                    number={formatter(total, overviewFormat)}
                    title={formatter(total, overviewFormat)}
                    skipFormat
                  />
                }
              />
              <Text customFontSize='1.25rem' variant={TEXT_VARIANTS.body1} text={groupLongName} />
            </div>
          </div>
        </Grid>
        <Grid item md={8} sm={10} xs={12}>
          <Box sx={{ margin: { sm: '0 2rem' } }}>
            <Box display='flex' mb='1rem'>
              <Text
                text={groupLongName}
                className={classes.groupName}
                customFontSize='1.75rem'
                customFontWeight='bold'
                variant={TEXT_VARIANTS.h4}
              />
            </Box>
            {subgroups.map((assetBlock) => {
              return renderAssetBlock(assetBlock)
            })}
          </Box>
        </Grid>
      </Grid>
    )
  }, [theme.palette, classes.sectionBlock, classes.totalBlock, classes.totalBlockInner, classes.groupName, formatter, overviewFormat, renderAssetBlock])

  return (
    <Grid container style={{ marginTop: '3rem' }}>
      <Grid item xs={12} md={6}>
        {side.map(section => renderSection(section))}
        {core.map(section => renderSection(section))}
      </Grid>
      <Grid item xs={12} md={6}>
        {bottom.map(section => renderSection(section))}
        {!!bottom.length && !!other.length && <Box className={classes.divider} />}
        {other.map(section => renderSection(section))}
      </Grid>
    </Grid>
  )
}

VisualBalanceSide.propTypes = {
  data: PropTypes.shape(VisualBalanceDataShape).isRequired,
  height: PropTypes.string,
  width: PropTypes.string,
  overviewFormat: PropTypes.string,
  detailFormat: PropTypes.string
}

VisualBalanceSide.defaultProps = {
  data: [],
  height: undefined,
  width: undefined
}

export default VisualBalanceSide
