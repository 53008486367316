import React, { useCallback, useImperativeHandle, useMemo, useState } from 'react'
import RoundedModal from '../../../../../../molecules/RoundedModal'
import EditBillingAccountsForm from './form'

const EditBillingAccountsDialog = React.forwardRef(function EditBillingAccountsDialog (_, ref) {
  const [dialogState, setDialogState] = useState({ open: false, processing: false })
  const close = useCallback(() => {
    setDialogState({ open: false, processing: false })
  }, [setDialogState])

  useImperativeHandle(ref, () => ({
    open: (clientId, account, billingAccounts, familyRates) => setDialogState({ open: true, processing: false, clientId, account, billingAccounts, familyRates })
  }), [setDialogState])

  const familyRateMemberIds = useMemo(() => {
    return [...new Set([
      dialogState.clientId,
      ...(Object.values(dialogState?.familyRates ?? {})?.flatMap(val => val.map(fs => fs.clientId)) ?? [])
    ])]
  }, [dialogState])

  return (
    <RoundedModal
      title='Edit Billing Accounts'
      subtitle={'For ' + dialogState.account?.accountName}
      open={dialogState.open}
      onClose={close}
      position='top'
      size='large'
      disablePortal={false}
    >
      <div>
        {dialogState.open ? (
          <EditBillingAccountsForm
            account={dialogState.account}
            defaultClientIdsToFilter={familyRateMemberIds}
            billingAccounts={dialogState.billingAccounts}
            onComplete={close}
            onCancel={close}
          />
        ) : null}
      </div>
    </RoundedModal>
  )
})

EditBillingAccountsDialog.propTypes = {
}

export default EditBillingAccountsDialog
