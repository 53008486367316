import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  badge: {
    border: `1px solid ${theme.palette.gray.dark}`,
    fontSize: '10px',
    fontWeight: 100,
    // color: theme.palette.gray.A700,
    padding: '2px',
    borderRadius: '2px',
    marginRight: '5px',
    backgroundColor: theme.palette.gray.light
  }
}))

function PurposeBadge ({ enabled, purpose }) {
  const classes = useStyles()

  if (!enabled) return <div>Disabled</div>

  return (
    <span className={classes.badge}>
      {purpose}
    </span>
  )
}

PurposeBadge.propTypes = {
  enabled: PropTypes.bool,
  purpose: PropTypes.string
}

export default PurposeBadge
