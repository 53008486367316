import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(() => ({
  container: {
    padding: '2.5rem',
    color: '#212945'
  }
}))

const WealthMissionViewContainer = ({ children }) => {
  const classes = useStyles()
  return <div className={classes.container}>{children} </div>
}

WealthMissionViewContainer.propTypes = {
  children: PropTypes.node.isRequired
}

export default WealthMissionViewContainer
