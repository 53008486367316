import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core'
import { useCheckSsoAccess } from '../../../api/users/sso'
import { useOutboundSso } from './OutboundSsoProvider'

const useStyles = makeStyles((theme) => ({
  ssoLink: {
    transition: 'filter .3s ease-in-out',
    filter: 'opacity(100%)',
    '&:hover': {
      cursor: 'pointer'
    },
    '&.__disabled': {
      filter: 'opacity(30%)',
      pointerEvents: 'none',
      '&:hover': {
        cursor: 'default'
      }
    }
  }
}))

const OutboundSsoLink = React.forwardRef(function OutboundSsoLink ({
  children,
  provider,
  sameTab,
  className
}, ref) {
  const { data, isLoading } = useCheckSsoAccess(provider)
  const osp = useOutboundSso()

  const handleClick = useCallback(async () => {
    if (isLoading || !data.hasAccess) return
    return await osp.start(provider, { sameTab, ssoProvider: data.ssoProvider })
  }, [osp, provider, sameTab, data, isLoading])
  const disabled = isLoading || !data?.hasAccess
  const classes = useStyles({ disabled })

  return (
    <div
      ref={ref}
      className={clsx(classes.ssoLink, className, { __disabled: disabled })}
      role='link'
      onClick={handleClick}
    >
      {children}
    </div>
  )
})

OutboundSsoLink.propTypes = {
  children: PropTypes.node,
  provider: PropTypes.string,
  sameTab: PropTypes.bool,
  className: PropTypes.string
}

OutboundSsoLink.defaultProps = {
  sameTab: false
}

export default OutboundSsoLink
