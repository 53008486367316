
import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import truncate from 'lodash/truncate'
import numeral from 'numeral'
import {
  Checkbox
} from '@material-ui/core'
import Text from '../../../atoms/Text'
import { useAssetsHoldings } from '../../../../api/coreData'
import { LEVEL_TYPES } from '../../../../constants'
dayjs.extend(utc)

export const useHoldings = ({
  pageIndex,
  pageSize,
  startDate,
  accountId
}) => {
  const query = useMemo(() => {
    return {
      levelFilters: {
        levelId: accountId,
        levelType: LEVEL_TYPES.ACCOUNTS,
        includeEndingValue: true,
        skip: `${pageIndex}`,
        take: `${pageSize}`,
        asOfDate: startDate
      }
    }
  }, [pageSize, pageIndex, accountId, startDate])

  const {
    data,
    isLoading,
    isPreviousData,
    error,
    refetch
  } = useAssetsHoldings(query)

  return {
    data: isLoading ? [] : data,
    loading: isLoading || isPreviousData,
    error,
    refetch
  }
}

const AssetNameCell = ({
  editMode,
  classes,
  allSelected,
  onSelectAll,
  disabled
}) => {
  if (!editMode) return 'Asset Name'
  return (
    <span className={classes.checkboxCell}>
      <Checkbox
        disabled={disabled}
        checked={allSelected}
        onChange={onSelectAll}
        name='assetName'
        classes={{
          root: classes.checkbox
        }}
      />
      <Text text='Asset Name' />
    </span>
  )
}

AssetNameCell.propTypes = {
  classes: PropTypes.string,
  allSelected: PropTypes.bool,
  onSelectAll: PropTypes.bool,
  disabled: PropTypes.bool,
  editMode: PropTypes.bool
}

const cellTemplates = {
  checkbox: ({ checkedCells, onHandleCheck, classes, accessor, editMode, allSelected }) => {
    return ({ row: { original }, row }) => {
      if (!editMode) return accessor(row)
      return (
        <span className={classes.checkboxCell}>
          <Checkbox
            checked={checkedCells.includes(original.positionId) || allSelected}
            onChange={(event) => onHandleCheck(original.positionId, event.target.checked)}
            name={`cell-${original.positionId}`}
            classes={{
              root: classes.checkbox
            }}
          />
          <Text>
            {accessor(row)}
          </Text>
        </span>
      )
    }
  },
  date: ({ value }) => value ? dayjs.utc(value).format('M/D/YYYY') : '',
  money: ({ value }) => <span style={{ display: 'block', padding: '0 5px', width: '100%', textAlign: 'right' }}>{numeral(value).format('0,0.00')}</span>
}
const MAX_CHARACTERS = 30
const CHARACTER_SEPARATOR = ' '

const assetNameAccessor = (row) => truncate(row.assetName || row.assetLongName || row.shortName, { length: MAX_CHARACTERS, separator: CHARACTER_SEPARATOR })
const assetNameAccessorFromOriginal = (row) => truncate(row.original.assetName || row.original.assetLongName || row.original.shortName, { length: MAX_CHARACTERS, separator: CHARACTER_SEPARATOR })
const managedStatusAccessor = (row) => row.managedStatusId === 1 ? 'Managed' : 'Unmanaged'
const discretionaryStatusAccessor = (row) => row.discretionaryStatusId === 1 ? 'Discretionary' : 'Non-Discretionary'
const performingStatusAccessor = (row) => row.performanceStatusId === 1 ? 'Performing' : 'Non-Performing'

export const usePositionColumns = ({
  onSelect,
  onSelectAll,
  selected,
  allSelected,
  classes,
  editMode
}) => {
  return useMemo(() => {
    return {
      columns: [
        {
          Header: AssetNameCell({ classes, allSelected, onSelectAll, editMode }),
          accessor: assetNameAccessor,
          id: 'assetName',
          disableSortBy: true,
          Cell: cellTemplates.checkbox({
            checkedCells: selected,
            onHandleCheck: onSelect,
            classes,
            accessor: assetNameAccessorFromOriginal,
            editMode,
            allSelected
          })
        },
        { Header: 'Asset Symbol', accessor: 'symbol', id: 'symbol', disableSortBy: true },
        { Header: 'CUSIP', accessor: 'cusip', id: 'cusip', disableSortBy: true },
        { Header: 'Price', accessor: 'estimatedPrice', id: 'estimatedPrice', disableSortBy: true, alignRight: true },
        { Header: 'Units', accessor: 'units', id: 'units', disableSortBy: true, alignRight: true },
        { Header: 'Value', accessor: 'endingValue', id: 'endingValue', Cell: cellTemplates.money, disableSortBy: true, alignRight: true },
        { Header: 'Managed Status', accessor: managedStatusAccessor, id: 'managedStatusId', disableSortBy: true },
        { Header: 'Discretionary Status', accessor: discretionaryStatusAccessor, id: 'discretionaryStatusId', disableSortBy: true },
        { Header: 'Performing Status', accessor: performingStatusAccessor, id: 'performanceStatusId', disableSortBy: true }
      ],
      defaultSort: [
        { id: 'longName', desc: false }
      ]
    }
  }, [classes, onSelect, selected, allSelected, onSelectAll, editMode])
}
