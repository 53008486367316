import React from 'react'
import { CircularProgress } from '@material-ui/core'
import PropTypes from 'prop-types'
import { useAbundanceEngineViewByPathName } from '../../../api/abundanceEngine'
import FadeIn from '../../molecules/Transitions/FadeIn'
import AbundanceEngineContextProvider from '../../../abundanceEngine/components/AbundanceParameterContextProvider'
import AbundanceParser from '../../../abundanceEngine/AbundanceParser'

function ConfigurableView ({ viewPath, children }) {
  const { data: viewData, isFetching } = useAbundanceEngineViewByPathName(viewPath)

  if (isFetching) {
    return (
      <FadeIn key={`loading-${viewPath}`} delay={1000} duration={1000}>
        <CircularProgress />
      </FadeIn>
    )
  }

  if (viewData) {
    return (
      <AbundanceEngineContextProvider>
        <AbundanceParser
          jsx={viewData?.jsxView}
          jsxComponentsDefaultProps={viewData.defaultProps}
        />
      </AbundanceEngineContextProvider>
    )
  }

  return children
}

ConfigurableView.propTypes = {
  viewPath: PropTypes.string,
  children: PropTypes.node
}

export default ConfigurableView
