import { useCallback } from 'react'
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom'
import isEmpty from 'lodash/isEmpty'
import { mergeSearchParams, parseSearchParams, stringifySearchParams } from '../utils'
import useDeepCompareMemo from './useDeepCompareMemoize'

const getSearchParamsNormalized = (params) => {
  const normalizedParams = !isEmpty(params) ? stringifySearchParams(params) : {}
  return new URLSearchParams(normalizedParams)
}

const useSyncWithUrlParams = () => {
  const history = useHistory()
  const location = useLocation()

  const replaceUrlSearchParams = useCallback(
    (params) => {
      const newParams = getSearchParamsNormalized(params)
      const searchParams = new URLSearchParams(newParams)
      history.replace({ search: searchParams.toString() })
    },
    [history]
  )

  const { url } = useRouteMatch()

  const replaceUrlSearchParamsAndKeepPrevValues = useCallback(
    (params) => {
      const currentParams = new URLSearchParams(history.location.search)
      const newParams = getSearchParamsNormalized(params)

      const mergedParams = mergeSearchParams(
        currentParams,
        newParams
      ).toString()

      history.replace({
        pathname: url,
        search: mergedParams
      })
    },
    [url, history]
  )

  const searchParams = useDeepCompareMemo(() => {
    const currentSearchParams = new URLSearchParams(location.search)
    const params = parseSearchParams(currentSearchParams)
    return !isEmpty(params) ? params : {}
  }, [location.search])

  return {
    urlSearchParams: searchParams,
    replaceUrlSearchParams,
    replaceUrlSearchParamsAndKeepPrevValues
  }
}

export default useSyncWithUrlParams
