import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'
import { makeStyles } from '@material-ui/core'
import { Link, useLocation } from 'react-router-dom'
import { orderBy, first } from 'lodash'
import { useLeadAdvisors } from '../../../../api/groups'
import { useParameterReplacement } from '../../../../hooks/useAbundanceEngineParameters'
import AdvisorHomeEmptySection from '../AdvisorHomeEmptySection'
import { ICON_NAMES } from '../../../../constants'
import { useAdvisorHome } from '../AdvisorHomeContext'
import TasksPreviewSkeleton from './TasksPreviewSkeleton'
import OwnerTaskItem from './OwnerTaskItem'

const useStyles = makeStyles(() => ({
  container: {
    gap: '0.5rem',
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    overflowX: 'auto',
    padding: '1rem'
  }
}))

const mapTasks = (data) => {
  return data.reduce((acc, { taskCount }) => {
    return [...acc, ...(taskCount || [])]
  }, [])
}

const mapTaskOwners = (data) => {
  return data.reduce(
    (acc, {
      ownerId,
      taskOwnerUserId,
      taskOwner,
      ownerFirstName,
      ownerLastName,
      photoUrl,
      taskCount
    }) => {
      return {
        ...acc,
        [taskOwnerUserId || ownerId]: {
          ownerId: taskOwnerUserId || ownerId,
          firstName: ownerFirstName || taskOwner,
          lastName: ownerLastName,
          photoUrl,
          taskCount
        }
      }
    },
    {}
  )
}

const TopTasksPreview = ({
  to,
  limit,
  endDate,
  startDate,
  dataSourceKey,
  emptyStateLabel
}) => {
  const classes = useStyles()
  const location = useLocation()
  const [start, end] = useParameterReplacement([startDate, endDate])

  const { scope } = useAdvisorHome()
  const advisorGroupId = useMemo(() => first(scope?.values), [scope])

  const { query, queryOptions } = useMemo(() => {
    return {
      query: {
        take: limit,
        includes: {
          taskCount: {
            ...(dataSourceKey ? { source: dataSourceKey } : {}),
            activityDate: [
              { value: start, op: 'gt' },
              { value: end, op: 'lt' }
            ],
            dueDate: [
              { value: start, op: 'gt' },
              { value: end, op: 'lt' }
            ]
          }
        },
        ...(advisorGroupId
          ? {
            filters: {
              leadAdvisorId: [{ value: advisorGroupId, op: 'eq' }]
            }
          }
          : {})
      },
      queryOptions: {
        mapper: ({ data = [] }) => {
          const tasks = mapTasks(data)
          const owners = mapTaskOwners(tasks)
          return orderBy(Object.values(owners), 'taskCount', 'desc')
        }
      }
    }
  }, [dataSourceKey, advisorGroupId, limit, end, start])

  const { data = [], isLoading } = useLeadAdvisors(query, queryOptions)

  const tasksOwners = useMemo(() => {
    return (
      <div className={classes.container}>
        {(data ?? []).map((datum) => {
          return <OwnerTaskItem key={datum.ownerId} owner={datum} />
        })}
      </div>
    )
  }, [classes.container, data])

  if (isEmpty(data) && !isLoading) {
    return (
      <AdvisorHomeEmptySection
        title='No tasks'
        icon={ICON_NAMES.paperTasks}
        description={emptyStateLabel}
      />
    )
  }

  if (isLoading) {
    return <TasksPreviewSkeleton />
  }

  if (!to) {
    return (
      <Link to={{ pathname: to, search: location.search }}>
        {tasksOwners}
      </Link>
    )
  }

  return tasksOwners
}

TopTasksPreview.propTypes = {
  to: PropTypes.string,
  limit: PropTypes.number,
  endDate: PropTypes.string,
  startDate: PropTypes.string,
  emptyStateLabel: PropTypes.string,
  dataSourceKey: PropTypes.string
}

TopTasksPreview.defaultProps = {
  to: '#',
  limit: 50,
  dataSourceKey: null,
  startDate: '$lastYearStart',
  endDate: '$in7days',
  emptyStateLabel: 'Looks like you have no upcoming tasks'
}

export default TopTasksPreview
