import React from 'react'
import FadeIn from '../../../../molecules/Transitions/FadeIn'
import PersonalSpace from '../../shared/PersonalSpace'
import OutboundSsoSection from './OutboundSsoSection'

function IntegrationsTab () {
  return (
    <FadeIn>
      <OutboundSsoSection />
      <PersonalSpace />
    </FadeIn>
  )
}

IntegrationsTab.propTypes = {
}

export default IntegrationsTab
