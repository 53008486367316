import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import GroupingProvider from '../../organisms/GroupingProvider'
import GroupingPicker from '../../organisms/GroupingProvider/GroupingPicker'
import { defaultOptions, parseRelativeDateKeyFromAccessor } from '../RelativeDateSelect'
import BenchmarksTableWrapper from './BenchmarksTableWrapper'

const defaultDateRangeOptions = [
  ...defaultOptions,
  { value: 'CLY', label: 'Calendar Year' },
  { value: 'CTM', label: 'Custom' }
]

const BenchmarkReturnsTable = ({
  options,
  dateRangeOptions,
  configurationStorageKey,
  ...props
}) => {
  const dateRanges = useMemo(() => {
    const { columnConfig } = props
    if (!columnConfig?.columns) {
      return []
    }
    return columnConfig?.columns?.reduce((acc, column) => {
      if (!column.accessor) {
        return acc
      }
      const dateRangeKey = parseRelativeDateKeyFromAccessor(column.accessor)
      if (dateRangeKey) {
        acc.push(dateRangeKey)
      }
      return acc
    }, [])
  }, [props])

  return (
    <GroupingProvider
      configurationKey={configurationStorageKey}
      groupingDateRanges={dateRanges}
      groupingDateRangeOptions={dateRangeOptions}
    >
      <GroupingPicker options={options} />
      <BenchmarksTableWrapper {...props} dateRanges={dateRanges} />
    </GroupingProvider>
  )
}

BenchmarkReturnsTable.propTypes = {
  dateRangeOptions: PropTypes.array,
  options: PropTypes.shape({
    hideCustomizeColumnsButton: PropTypes.bool,
    hideExpandCollapseButton: PropTypes.bool,
    hideGroupingsUnderline: PropTypes.bool,
    hideExportButton: PropTypes.bool
  }),
  configurationStorageKey: PropTypes.string,
  columnConfig: PropTypes.object
}

BenchmarkReturnsTable.defaultProps = {
  dateRangeOptions: defaultDateRangeOptions,
  options: {
    hideCustomizeColumnsButton: false,
    hideExpandCollapseButton: true,
    hideGroupingsUnderline: false,
    hideExportButton: false
  },
  configurationStorageKey: undefined
}

export default BenchmarkReturnsTable
