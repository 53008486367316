import StickySuperHeader from '../StickySuperHeader'
import AutoOperationalTable from './AutoOperationalTable'
import SearchBar from './SearchBar'
import TableWrapper from './TableWrapper'
import { useOperationalTable as _useOperationalTable } from './useOperationalTable'

AutoOperationalTable.Search = SearchBar
AutoOperationalTable.SuperHeader = StickySuperHeader
AutoOperationalTable.Wrapper = TableWrapper

export default AutoOperationalTable
export const Search = SearchBar
export const SuperHeader = StickySuperHeader
export const Wrapper = TableWrapper
export const useOperationalTable = _useOperationalTable
