import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
  markdownOverride: {
    fontFamily: [
      'GothamPro',
      'Gotham-Book',
      '"Open Sans"',
      'Tahoma',
      '"Segoe UI"',
      'sans-serif'
    ].join(','),
    fontWeight: '400'
  }
}))

function MarkdownField ({ field }) {
  const classes = useStyles()
  return (
    <ReactMarkdown className={clsx('markdown-body', classes.markdownOverride)} children={field.value} remarkPlugins={[remarkGfm]} />
  )
}

MarkdownField.propTypes = {
  field: PropTypes.shape({
    value: PropTypes.any
  })
}

export default MarkdownField
