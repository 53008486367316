import React, { useCallback, useMemo, useState } from 'react'
import noop from 'lodash/noop'
import {
  alpha,
  IconButton,
  useTheme,
  makeStyles
} from '@material-ui/core'
import PropTypes from 'prop-types'
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined'
import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline'
import { animated, useSpring } from 'react-spring'
import { useHistory, useLocation } from 'react-router'
import isNumber from 'lodash/isNumber'
import { useToggle } from '../../hooks'
import { useAppContext, useSetCurrentClient } from '../../redux/slices/appContext'
import { postNamedQuery } from '../../service'
import { useClientsViewPath } from '../../redux/slices/appConfig'
import {
  BUTTON_SIZES,
  DEFAULT_SEARCH_CLIENTS_PAGE_SIZE,
  DEFAULT_SEARCH_RECENTS_CLIENTS_PAGE_SIZE,
  ICON_NAMES,
  ADMIN_ROUTES,
  ADVISOR_VIEW_PATH,
  CLIENTS_CARDS_PATH
} from '../../constants'
import { useRecentClients } from '../../api/clients'
import Icon from '../atoms/Icon'
import RoundedButton from '../atoms/RoundedButton'
import AutoCompleteSearchInput from './AutoCompleteSearchInput'
import MessageBadge from './MessageBadge'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center'
  },
  notificationBadge: {
    border: 'none',
    padding: '4px 10px 2px 8px !important',
    color: theme.palette.white,
    backgroundColor: theme.palette.success.A500,
    '& > span > svg': {
      marginRight: '0.5rem',
      fontSize: '1rem'
    },
    '&:hover': {
      backgroundColor: theme.palette.success.A700
    }
  },
  search: ({ showSearchInput, showSearchInputToggle }) => ({
    display: 'flex',
    position: 'relative',
    zIndex: '1102',
    height: '2.8rem',
    borderRadius: '1.5rem',
    backgroundColor: alpha(theme.palette.gray.dark, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.gray.dark, 0.25)
    },
    ...(!showSearchInputToggle ? { marginRight: '2rem' } : {}),
    ...(showSearchInput ? { marginLeft: '0.5rem', marginRight: '2rem' } : {})
  }),
  searchContainer: {
    display: 'flex'
  },
  actions: {
    display: 'flex'
  },
  inputRoot: {
    color: 'inherit',
    minWidth: '0 !important'
  },
  inputInput: {
    padding: `${theme.spacing(1, 1, 1, 1)} !important`,
    transition: theme.transitions.create('width'),
    width: '100%',
    minWidth: '0 !important'
  }
}))

const MainNavigationMenu = ({
  toggleProfileSideNav,
  toggleNotesSideNav,
  showGearOption,
  showSearchInputToggle
}) => {
  const history = useHistory()
  const location = useLocation()
  const clientsViewPath = useClientsViewPath()
  const { isAdvisor, notificationsCounter, clientCount } = useAppContext()
  const { data: recentClients } = useRecentClients(DEFAULT_SEARCH_RECENTS_CLIENTS_PAGE_SIZE)
  const setCurrentClient = useSetCurrentClient()
  const [clients, setClients] = useState([])
  const [showSearchInput, toggleSearchInput] = useToggle()
  const [searching, , toggleSearchingOn, toggleSearchingOff] = useToggle()
  const classes = useStyles({ showSearchInput, showSearchInputToggle })
  const theme = useTheme()

  const searchInputSpringStyles = useSpring({
    opacity: showSearchInput ? 1 : 0,
    width: showSearchInput ? '300px' : '0px'
  })

  const onSelectClient = useCallback(
    (client) => {
      setCurrentClient(client.clientId)
      toggleSearchInput()

      const pathname = location.pathname

      const shouldRedirectToHome = pathname.includes(ADMIN_ROUTES.ADMIN) ||
        pathname.includes(`/${ADVISOR_VIEW_PATH}`) ||
        pathname.includes(CLIENTS_CARDS_PATH)

      if (shouldRedirectToHome) {
        history.push('/')
      }
    }, [
      setCurrentClient,
      toggleSearchInput,
      history,
      location
    ]
  )

  const onSearchClients = useCallback(
    async (value = '') => {
      try {
        toggleSearchingOn()
        const { data: clients } = await postNamedQuery('levels', 'searchClients', {
          textSearch: {
            longName: value ? [{ op: 'contains', value }] : undefined,
            shortName: value ? [{ op: 'contains', value }] : undefined
          },
          take: DEFAULT_SEARCH_CLIENTS_PAGE_SIZE,
          includes: {
            avatars: true
          }
        })

        setClients(clients.data)
      } catch (err) {
        console.error(err)
      } finally {
        toggleSearchingOff()
      }
    },
    [toggleSearchingOn, toggleSearchingOff]
  )

  const navigateToClientCards = useCallback(
    () => history.push(clientsViewPath),
    [history, clientsViewPath]
  )

  const autoCompleteSearchInput = useMemo(() => (
    <AutoCompleteSearchInput
      startAsOpen
      showPrimaryButton={isAdvisor}
      recentOptionTitleKey='shortName'
      recentOptionValueKey='clientId'
      optionTitleKey='longName'
      optionValueKey='clientId'
      placeholder='Search for clients'
      primaryButtonLabel='View all Clients'
      noOptionsFoundLabel='No Clients Found'
      options={clients}
      recentOptions={recentClients}
      loading={searching}
      onChange={onSearchClients}
      onSelectOption={onSelectClient}
      onSearchClick={toggleSearchInput}
      onPrimaryButtonClick={navigateToClientCards}
    />
  ), [clients, recentClients, searching, onSelectClient, onSearchClients, toggleSearchInput, navigateToClientCards, isAdvisor])

  const shouldShowClientSearch = useMemo(() => {
    if (!isAdvisor) {
      return clientCount > 1
    }
    return true
  }, [isAdvisor, clientCount])
  const searchInput = useMemo(() => {
    if (!shouldShowClientSearch) return null
    return showSearchInputToggle
      ? (
        <>
          {!showSearchInput
            ? (
              <IconButton onClick={toggleSearchInput} data-testid='search-client-btn'>
                <Icon name={ICON_NAMES.search} customSize='1.25rem' color={theme.palette.black} />
              </IconButton>
            ) : (
              <animated.div className={classes.search} style={searchInputSpringStyles}>
                {autoCompleteSearchInput}
              </animated.div>
            )}
        </>
      )
      : (
        <div className={classes.search}>
          {autoCompleteSearchInput}
        </div>
      )
  }, [
    classes.search,
    theme.palette.black,
    showSearchInput,
    toggleSearchInput,
    showSearchInputToggle,
    autoCompleteSearchInput,
    searchInputSpringStyles,
    shouldShowClientSearch
  ])

  const notificationsBadge = useMemo(() => {
    if (isNumber(notificationsCounter) && notificationsCounter > 0 && isAdvisor) {
      return (
        <RoundedButton
          size={BUTTON_SIZES.extraSmall}
          onClick={navigateToClientCards}
          className={classes.notificationBadge}
        >
          <ChatBubbleOutlineIcon fontSize='small' style={{ color: theme.palette.white }} />
          {notificationsCounter} New
        </RoundedButton>
      )
    }
    return null
  }, [
    isAdvisor,
    notificationsCounter,
    navigateToClientCards,
    theme.palette.white,
    classes.notificationBadge
  ])

  return (
    <div className={classes.container}>
      {notificationsBadge}
      <div className={classes.searchContainer}>
        {searchInput}
      </div>
      <div className={classes.actions}>
        <MessageBadge toggleNotesSideNav={toggleNotesSideNav} />
        {showGearOption && (
          <IconButton
            edge='end'
            aria-label='account of current user'
            aria-haspopup='true'
            onClick={toggleProfileSideNav}
          >
            <SettingsOutlinedIcon fontSize='small' style={{ color: theme.palette.black }} />
          </IconButton>
        )}
      </div>
    </div>
  )
}

MainNavigationMenu.propTypes = {
  showGearOption: PropTypes.bool,
  showSearchInputToggle: PropTypes.bool,
  toggleNotesSideNav: PropTypes.func,
  toggleProfileSideNav: PropTypes.func
}

MainNavigationMenu.defaultProps = {
  showGearOption: true,
  showSearchInputToggle: false,
  toggleNotesSideNav: noop,
  toggleProfileSideNav: noop
}

export default MainNavigationMenu
