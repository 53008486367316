import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import FadeIn from '../../molecules/Transitions/FadeIn'
import { useHomeDashboard } from './HomeDashboardContext'

const useStyles = makeStyles((theme) => ({
  logo: {
    padding: theme.layout.padding.medium,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'end',
    '& img': {
      height: ({ height }) => height
    }
  }
}))

function Logo ({ height, style, href }) {
  const { logoSrc, logoAlt } = useHomeDashboard()
  const classes = useStyles({ height })

  if (href) {
    return (
      <FadeIn style={style} className={classes.logo}>
        <a href={href} target='_blank' rel='noopener noreferrer'>
          <img src={logoSrc} alt={logoAlt} />
        </a>
      </FadeIn>
    )
  }

  return (
    <FadeIn style={style} className={classes.logo}>
      <img src={logoSrc} alt={logoAlt} />
    </FadeIn>
  )
}

Logo.propTypes = {
  style: PropTypes.object,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  href: PropTypes.string
}

Logo.defaultProps = {
  alt: 'Logo',
  height: '200px'
}

export default Logo
