import querystring from 'querystring'
import isEmpty from 'lodash/isEmpty'
import config from './config'
import {
  CONTENT_DISPOSITION_TYPES,
  DEFAULT_HEADER_KEYS,
  REDTAIL_INTEGRATION,
  REDTAIL_INTEGRATION_ERROR_STATUS_CODE,
  REDTAIL_INTEGRATION_SUCCESS_STATUS_CODE,
  SALESFORCE_INTEGRATION,
  SALESFORCE_INTEGRATION_ERROR_STATUS_CODE,
  SALESFORCE_INTEGRATION_SUCCESS_STATUS_CODE,
  WEALTHBOX_INTEGRATION,
  WEALTHBOX_INTEGRATION_ERROR_STATUS_CODE,
  WEALTHBOX_INTEGRATION_SUCCESS_STATUS_CODE
} from './constants'

let TOKEN = ''

const DEFAULT_ERROR_MESSAGE =
  'There was a problem processing your request, please try again later'

let DEFAULT_HEADERS = {
  [DEFAULT_HEADER_KEYS.ACCEPT]: 'application/json',
  [DEFAULT_HEADER_KEYS.CONTENT_TYPE]: 'application/json'
}

export const methods = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  DELETE: 'DELETE'
}

export const methodsWithPayload = [methods.POST]

export function buildPath (path, parameters = {}, queryStringParameters = {}) {
  let builtPath = path
  Object.keys(parameters).forEach((parameterName) => {
    builtPath = builtPath.replace(
      `:${parameterName}`,
      parameters[parameterName]
    )
  })
  if (Object.keys(queryStringParameters).length > 0) {
    builtPath += '?' + querystring.stringify(queryStringParameters)
  }
  return builtPath
}

export function setDefaultHeaders (headers, overrideDefaultHeaders = false) {
  DEFAULT_HEADERS = {
    ...(!overrideDefaultHeaders ? DEFAULT_HEADERS : {}),
    ...headers
  }
}

export function setToken (token) {
  TOKEN = token
}

export function getToken () {
  return TOKEN
}

function xmlHttpRequest (
  url,
  options = {},
  onProgress,
  onAbort,
  xhrRequest = null
) {
  const {
    headers,
    method,
    body
  } = options
  const xhr = xhrRequest || new XMLHttpRequest()
  return new Promise((resolve, reject) => {
    xhr.open(method || 'get', url)
    for (const k in headers || {}) {
      xhr.setRequestHeader(k, headers[k])
    }
    xhr.onload = (e) => resolve(e.target)
    xhr.onerror = reject
    if (xhr.upload && onProgress) {
      xhr.upload.onprogress = onProgress
    }
    if (onAbort) {
      xhr.onabort = onAbort
    }
    xhr.send(body)
  })
}

function buildApiError (response, json) {
  const message = json.message || DEFAULT_ERROR_MESSAGE
  const apiError = new Error(message)
  apiError.code = response.status
  apiError.description = response.statusText
  apiError.response = json
  return apiError
}

/**
 *
 * @param {SavedRequest} request
 * @param options
 * @return {Promise<any>}
 */
export const authFetchRaw = async (request, options = {}) => {
  const url = !isEmpty(request.query)
    ? buildPath(request.target, {}, request.query)
    : request.target

  const finalOptions = {
    ...options
  }

  const Authorization = `Bearer ${TOKEN}`
  finalOptions.headers = {
    ...DEFAULT_HEADERS,
    Authorization,
    ...(request.headers ?? {})
  }

  finalOptions.method = request.method
  if (request.method !== 'get' && request.method !== 'head') {
    finalOptions.body = JSON.stringify(request.body)
  }

  const response = await fetch(url, finalOptions)
  const responseBody = await response.clone().json().catch(() => response.text())
  if (!response.ok) throw buildApiError(response, responseBody)

  return {
    data: responseBody,
    response
  }
}

export async function authFetch (path, options = {}, searchParams = {}) {
  const skipParseResponse = options.skipParseResponse || false

  const url = !isEmpty(searchParams)
    ? buildPath(`${config.apiProxyUrl}${path}`, {}, searchParams)
    : `${config.apiProxyUrl}${path}`

  const Authorization = `Bearer ${TOKEN}`
  const finalOptions = { ...options }
  const isDeleteMethod = options.method === methods.DELETE
  finalOptions.headers = {
    ...DEFAULT_HEADERS,
    Authorization,
    ...(options.headers || {})
  }

  if (options.body) finalOptions.body = JSON.stringify(options.body)
  const response = await fetch(url, finalOptions)

  const responseBody = (isDeleteMethod || response.status === 204 || skipParseResponse)
    ? {}
    : await response.clone().json().catch(() => response.text())
  if (!response.ok) throw buildApiError(response, responseBody)
  return {
    data: responseBody,
    response
  }
}

function authGet (path, options, searchParams) {
  return authFetch(path, {
    ...options,
    method: methods.GET
  }, searchParams)
}

function authPost (path, body, options) {
  return authFetch(path, {
    ...options,
    body,
    method: methods.POST
  })
}

function authPut (path, body, options) {
  return authFetch(path, {
    ...options,
    body,
    method: methods.PUT
  })
}

function authDelete (path, options, searchParams) {
  return authFetch(path, {
    ...options,
    method: methods.DELETE
  }, searchParams)
}

// eslint-disable-next-line no-unused-vars
function authSave (path, body = {}, options, method = methods.POST, searchParams = {}) {
  return authFetch(path, {
    ...options,
    body,
    method
  }, searchParams)
}

export function fetchAppConfig () {
  return authGet('/abundance-engine/config')
}

export function createAbundanceEngineView (body) {
  return authSave('/abundance-engine/views', body)
}

export function editAbundanceEngineView (viewId, body) {
  return authSave(`/abundance-engine/views/${viewId}`, body, {}, methods.PUT)
}

export function deleteAbundanceEngineView (viewId) {
  return authDelete(`/abundance-engine/views/${viewId}`)
}

export function fetchLevelDates (params, abortController = {}) {
  return authGet('/level-dates', abortController, params)
}

export function fetchClients (params) {
  return authGet('/clients', {}, params)
}

export function fetchClient (id) {
  return authGet(`/clients/${id}`)
}

export function fetchClientBalances (params) {
  return authGet('/clients-balance', {}, params)
}

export function createClient (body, params) {
  return authSave('/clients', body, {}, methods.POST, params)
}

export function createClientSkipInvite (body) {
  return authSave('/v2/clients', body)
}

export function editClient (id, body, params) {
  return authSave(`/clients/${id}`, body, {}, methods.PUT, params)
}

export function deleteClient (id) {
  return authDelete(`/clients/${id}`)
}

export function fetchUsers (params) {
  return authGet('/users', {}, params)
}

// fetchUser look for the user externalId
export function fetchUser (id, params) {
  return authGet(`/users/${id}`, {}, params)
}

export function createAdvisor (body) {
  return authSave('/advisors', body)
}

export function createAdvisorSkipInvite (body) {
  return authSave('/v2/advisors', body)
}

export function createClientUser (body) {
  return authSave('/users/clients', body)
}

export function inviteUser (userId) {
  return authSave(`/users/${userId}/invite`, {}, {}, methods.POST)
}

export function editUser (id, body) {
  return authSave(`/users/${id}`, body, {}, methods.PUT)
}

export function updateMe (body) {
  return authSave('/users/me', body, {}, methods.PUT)
}

export function changePassword (userId, body) {
  return authSave(`/auth/users/${userId}/password`, body, {}, methods.PUT)
}

export function deleteUser (id) {
  return authDelete(`/users/${id}`)
}

export function reactivateUser (userId) {
  return authGet(`/auth/users/${userId}/reactivate`)
}

export function forgotPassword (body) {
  return authSave('/v2/auth/forgot/password', body, {}, methods.PUT)
}

export function resetPassword (body) {
  return authSave('/v2/auth/reset/password', body)
}

export function getSecurityQuestions (params) {
  return authGet('/v2/auth/users/questions', {}, params)
}

export function getRecoveryQuestion (params) {
  return authGet('/v2/auth/users/question', {}, params)
}

export function setUserPassword (body) {
  return authSave('/auth/users/password', body)
}

export function setRecoveryQuestion (body) {
  return authSave('/v2/user/recovery-question', {
    ...body,
    email: undefined
  })
}

export function checkRecoveryToken (token) {
  return authGet(`/v2/auth/recovery/${token}`, token)
}

export function resetUser (body) {
  return authSave('/v2/reset/user', body, {}, methods.POST)
}

export function acceptTerms () {
  return authPost('/v2/user/accept-terms')
}

export function createClientActivity (authUserId, body) {
  return authSave(`/users/${authUserId}/client-activities`, body)
}

export function getClientActivities (searchParams, abortController) {
  return authGet('/clients/activity', abortController, searchParams)
}

export function fetchAdminAccounts (searchParams) {
  return authGet('/admin-accounts', {}, searchParams)
}

export function editAdminAccount (id, body) {
  return authSave(`/admin-accounts/${id}`, body, {}, methods.PUT)
}

export function manageAccountClientsAssociation (accountId, body) {
  return authSave(`/accounts/${accountId}/clients`, body)
}

export function fetchPersons (searchParams) {
  return authGet('/persons', {}, searchParams)
}

export function createPerson (body) {
  return authSave('/persons', body)
}

export function editPerson (id, body) {
  return authSave(`/persons/${id}`, body, {}, methods.PUT)
}

export function deletePerson (clientId, personId) {
  return authDelete(`/persons/${personId}`, {}, { clientId })
}

export function fetchViewsThreadSections (searchParams) {
  return authGet('/notes/views/sections', {}, searchParams)
}

export function fetchViewThreadSections (searchParams) {
  return authGet('/notes/sections', {}, searchParams)
}

export function fetchThreads (abortController, searchParams) {
  return authGet('/notes/threads', abortController, searchParams)
}

export function fetchReplies (threadId, abortController) {
  return authGet(`/notes/threads/${threadId}/replies`, abortController)
}

export function createThreadReaction (threadId, body) {
  return authSave(`/notes/threads/${threadId}/reactions`, body)
}

export function createReplyReaction (replyId, body) {
  return authSave(`/notes/replies/${replyId}/reactions`, body)
}

export function deleteThreadReaction (threadId, reactionId) {
  return authDelete(`/notes/threads/${threadId}/reactions/${reactionId}`)
}

export function deleteReplyReaction (replyId, reactionId) {
  return authDelete(`/notes/replies/${replyId}/reactions/${reactionId}`)
}

export function createThread (body, params) {
  return authSave('/notes/threads', body, {}, undefined, params)
}

export function saveThreadReply (threadId, body, params) {
  return authSave(`/notes/threads/${threadId}/replies`, body, {}, undefined, params)
}

export function deleteThreadReply (threadId, replyId) {
  return authDelete(`/notes/threads/${threadId}/replies/${replyId}`)
}

export function editThreadReply (threadId, replyId, body) {
  return authSave(`/notes/threads/${threadId}/replies/${replyId}`, body, {}, methods.PUT)
}

export function getNotificationsCounter (userId) {
  return authGet(`/notes/notifications/${userId}/count`)
}

export function getNotificationsByClients (userId, abortController, params) {
  return authGet(`/notes/notifications/clients/${userId}`, abortController, params)
}

export function saveNotificationInteraction (userId, body) {
  return authSave(`/notes/notifications/interactions/${userId}`, body)
}

export function getClientsNotifications (userId, abortController, params) {
  return authGet(`/notes/notifications/clients/${userId}`, abortController, params)
}

export function getResource (resourceId, abortController) {
  return authGet(`/resources/image/${resourceId}`, abortController)
}

export function editThread (threadId, body) {
  return authSave(`/notes/threads/${threadId}`, body, {}, methods.PUT)
}

export function deleteThread (threadId) {
  return authDelete(`/notes/threads/${threadId}`)
}

export function getClientsThreadsOverview (abortController) {
  return authGet('/notes/clients', abortController)
}

export async function createDocuments (clientId, body) {
  return authSave(`/vault/${clientId}/documents`, body)
}

export async function editDocuments (clientId, body) {
  return authSave(`/vault/${clientId}/documents`, body, {}, methods.PUT)
}

export async function editDocument (clientId, documentId, body) {
  return authSave(
    `/vault/${clientId}/documents/${documentId}`,
    body,
    {},
    methods.PUT
  )
}

export async function deleteDocument (clientId, documentId) {
  return authDelete(`/vault/${clientId}/documents/${documentId}`)
}

export async function searchTag (clientId, query) {
  return authGet(`/vault/${clientId}/tags?search=${query}`)
}

export async function getTags () {
  return authGet('/vault/tags')
}

export async function saveTag (clientId, body) {
  return authSave(`/vault/${clientId}/tags`, body)
}

export async function uploadDocument (
  url,
  document,
  onProgressCallback,
  onAbortCallback,
  xhrRequest = null
) {
  const file = new Blob([document])
  const options = {
    method: 'put',
    body: file
  }
  const response = await xmlHttpRequest(
    url,
    options,
    onProgressCallback,
    onAbortCallback,
    xhrRequest
  )
  return response
}

export function fetchDocuments (clientId, params) {
  return authGet(`/vault/${clientId}/documents`, {}, params)
}

export function getDocument (clientId, documentId, isPreview = true) {
  const params = { contentDisposition: isPreview ? CONTENT_DISPOSITION_TYPES.INLINE : CONTENT_DISPOSITION_TYPES.ATTACHMENT }
  return authGet(`/vault/${clientId}/documents/${documentId}`, {}, params)
}

export function deleteTag (tagId) {
  return authDelete(`/vault/tags/${tagId}`)
}

export function editTag (tagId, body) {
  return authSave(`/vault/tags/${tagId}`, body, {}, methods.PUT)
}

export function getHistoricalRegister (params) {
  return authGet('/historical-register', {}, params)
}

export function fetchWealthJourneySeriesData (params) {
  return authPost('/wealth-journey/series-data', params)
}

export function getAssetClassesV2 (params) {
  return authGet('/v2/asset-classes', {}, params)
}

export function getAssetClasses (params, abortController = {}) {
  return authGet('/asset-classes', abortController, params)
}

export function getAssetsV2 (id, params, abortController) {
  return authGet(`/asset-classes/${id}`, abortController, params)
}

export function getAssetsWithExtras (params, abortController) {
  return authGet('/asset-classes/extra-info', abortController, params)
}

export function getPerformance (params, abortController) {
  return authGet('/performance-information', abortController, params)
}

export function getTeamMembers (clientId, abortController) {
  return authGet(`/team-members/client/${clientId}`, abortController)
}

export function getTeamMember (userId, abortController) {
  return authGet(`/team-members/user/${userId}`, abortController)
}

export function createTeamMember (body) {
  return authPost('/team-members', body)
}

export function editTeamMember (teamMemberId, body) {
  return authPut(`/team-members/${teamMemberId}`, body)
}

export function getAssetClassificationTagTypes (abortController) {
  return authGet('/asset-classes/classification-tag-types', abortController)
}

export function getAssetsClassifications (params) {
  return authGet('/asset-classes/classifications', {}, params)
}

export function createAssetClassificationTag (body) {
  return authSave('/asset-classes/classification-tag', body)
}

export function editAssetClassificationTag (id, body) {
  return authSave(`/asset-classes/classification-tag/${id}`, body, {}, methods.PUT)
}

export function assetClassificationBulkUpdate (body) {
  return authSave('/asset-classes/bulk', body, {}, methods.PUT)
}

export function getAssetsPerformance (params) {
  return authGet('/asset-classes/performance', {}, params)
}

export function fetchPortfolioSummaryReport (params) {
  return authGet('/asset-classes/portfolio-summary/report', null, params)
}

export function fetchWealthJourneyCategories (abortController, params) {
  return postNamedQuery('wealthJourney', 'list-categories', params || {}, abortController)
  // return authGet('/wealth-journey/categories', abortController, params)
}

export function fetchWealthJourneyEntries (params, abortController) {
  return postNamedQuery('wealthJourney', 'list-entries', params || {}, abortController)
}

export function editWealthJourneyEntry (id, body) {
  return postNamedCommand('wealthJourney', 'modify-entry', {
    ...body,
    entryId: id
  })
}

export function deleteWealthJourneyEntry (id) {
  return postNamedCommand('wealthJourney', 'remove-entry', {
    entryId: id
  })
}

export function createWealthJourneyEntry (body) {
  return postNamedCommand('wealthJourney', 'create-entry', body)
  // return authSave('/wealth-journey/entries', body)
}

export function fetchAssetsSymbols (params) {
  return authGet('/assets/symbols', {}, params)
}

export function fetchSalesforceClient (params) {
  return authGet('/salesforce/client', {}, params)
}

export function fetchWealthboxClient (params) {
  return authGet('/v2/integrations/wealthbox/client', {}, params)
}

export function fetchRedtailClient (params) {
  return authGet('/v2/integrations/redtail/client', {}, params)
}

export function fetchTransactions (params, abortController = {}) {
  return authGet('/transactions', abortController, params)
}

export function fetchHistoricalPerformanceReport (body, params, abortController) {
  return authSave('/asset-classes/performance-report', body, abortController, methods.POST, params)
}

export function getHistoricalRegisterIdentifiers (params, abortController = {}) {
  return authGet('/historical-register/identifiers', abortController, params)
}

export function fetchGroup (groupId, abortController) {
  return authGet(`/v2/groups/${groupId}`, abortController)
}

export function getFeeSchedules (searchParams) {
  return authGet('/v2/billing/fee-schedules', {}, searchParams)
}

export function createFeeSchedule (body = {}) {
  return authPost('/v2/billing/fee-schedules', body)
}

export function getFeeSchedule (feeScheduleId) {
  return authGet(`/v2/billing/fee-schedules/${feeScheduleId}`, {})
}

export function updateFeeSchedule (feeScheduleId, body = {}) {
  return authPut(`/v2/billing/fee-schedules/${feeScheduleId}`, body)
}

export function deleteFeeSchedule (feeScheduleId) {
  return authDelete(`/v2/billing/fee-schedules/${feeScheduleId}`)
}

export function getFeeMethods () {
  return authGet('/v2/billing/fee-methods', {}, {})
}

export function getFeeCalcTypes () {
  return authGet('/v2/billing/fee-calc-types', {}, {})
}

export function getClientFeeAssignments (searchParams) {
  return authGet('/v2/billing/client-assignments', {}, searchParams)
}

export function updateBillingRunReport (runId, reportType, body = {}) {
  return authSave(`/v2/billing/runs/${runId}/reports/${reportType}`, body, {}, methods.PUT)
}

export function fetchBalanceInformation (params, abortController = {}) {
  return authGet('/balance-information', abortController, params)
}

export function fetchAllocationHistory (params, abortController) {
  return authGet('/asset-classes/allocation-history', abortController, params)
}

export function fetchHoldingReport (params, abortController) {
  return authGet('/holdings-report', abortController, params)
}

export function fetchAssets (params, abortController) {
  return authGet('/assets', abortController, params)
}

export function getVisualBalanceInfo (params, abortController) {
  return authGet('/visual-balance-information', abortController, params)
}

export function getVisualBalanceInfoV2 (params, abortController) {
  return authPost('/v2/visual-balance-information', params, abortController)
}

export function getRoleTypes () {
  return authGet('/role-types')
}

export function fetchClientsBalanceActivity (params, abortController) {
  return authGet('/clients-balance/activity', abortController, params)
}

export function fetchCoreSeriesData (seriesRequest, abortController) {
  return authPost('/core-data/series-data', seriesRequest, abortController)
}

export function getBenchmarks (body = {}) {
  return authPost('/v2/benchmarks', body)
}

/**
 * Fetches a dataset
 * @param {string} datasetType
 * @param {object} datasetRequest
 * @param abortController
 * @return {Promise<{data: {}|any, response: Response}>}
 */
export function fetchCustomDataset (datasetType, datasetRequest, abortController = null) {
  return authPost(`/core-data/dataset/${datasetType}`, datasetRequest, abortController)
}

export function saveEntryImage (entryId, body, clientId) {
  return postNamedCommand('wealthJourney', 'create-entry-image', {
    entryId,
    ...body
  })
}

export function fetchEntryImage (entryId, imageId) {
  return postNamedQuery('wealthJourney', 'get-entry-image', {
    entryId,
    image: imageId
  })
}

export function postNamedQuery (domain, name, body, abortController) {
  if (!domain) throw new Error('domain is required')
  if (!name) throw new Error('name is required')

  const path = `/query/${domain}/${name}`
  return authPost(path, body, abortController)
}

export function postNamedCommand (domain, name, body, abortController) {
  if (!domain) throw new Error('domain is required')
  if (name !== '' && !name) throw new Error('name is required')

  let path = `/command/${domain}`
  if (name !== '') {
    path = `${path}/${name}`
  }

  // const path = `/command/${domain}/${name}`
  return authPost(path, body, abortController)
}

export function startFirmChange (body, abortController) {
  return authPost('/v2/user/start-firm-change', body, abortController)
}

export function completeFirmChange (body, abortController) {
  return authPost('/v2/user/complete-firm-change', body, abortController)
}

export function saveMedia (body) {
  return authSave('/media', body)
}

export function getMedia (mediaId) {
  return authGet(`/media/${mediaId}`)
}

export function getHolidays (params, abortController) {
  return authGet('/holidays', params, abortController)
}

export function getIntegrationAuthorizations () {
  return authGet('/v2/integrations/authorizations')
}

export function listIntegrations () {
  return authGet('/v2/integrations')
}

export function setActiveIntegrations (activeIntegrations = []) {
  return authPost('/v2/integrations/activate', activeIntegrations)
}

export function wealthboxAuthorize (code = '') {
  return authPost('/v2/integrations/wealthbox/authorize', { code })
}

export function wealthboxSyncDataOnDemand () {
  return authGet('/v2/integrations/wealthbox/sync-data')
}

export function wealthboxGetUnlinkedTags () {
  return authGet('/v2/integrations/wealthbox/unlinked-tags')
}

export function wealthboxSyncLinkedTags (upsert = [], remove = []) {
  return authPost('/v2/integrations/wealthbox/sync-linked-tags', { upsert, remove })
}

export function salesforceAuthorize (code = '') {
  return authPost('/v2/integrations/salesforce/authorize', { code })
}

export function salesforceSyncDataOnDemand () {
  return authGet('/v2/integrations/salesforce/sync-data')
}

export function redtailAuthorize (username = '', password = '') {
  return authPost('/v2/integrations/redtail/authorize', { username, password })
}

export function redtailSyncDataOnDemand () {
  return authGet('/v2/integrations/redtail/sync-data')
}

export function uploadAccountAssets (clientId) {
  return authPost('/v2/integrations/redtail/upload-account-assets', { clientId })
}

export function getRedtailSyncStatus (clientId) {
  return authGet(`/v2/integrations/redtail/sync-status/${clientId}`)
}

export function getBillingSystemConfig () {
  return authGet('/v2/billing/systemConfig')
}

export function getWealthMissionValues (params) {
  return authGet('/v2/wealth-mission-values', {}, params)
}

export function getWealthMissionValueDefaults (params) {
  return authGet('/v2/wealth-mission-values/defaults', {}, params)
}

export function saveWealthMissionValue (body) {
  return authSave('/v2/wealth-mission-values', body)
}

export function editWealthMissionValue (id, body) {
  return authSave(`/v2/wealth-mission-values/${id}`, body, {}, methods.PUT)
}

export function deleteWealthMissionValue (id) {
  return authDelete(`/v2/wealth-mission-values/${id}`)
}

export const integrations = {
  [WEALTHBOX_INTEGRATION]: {
    authorize: wealthboxAuthorize,
    syncOnDemand: wealthboxSyncDataOnDemand,
    syncLinkedTags: wealthboxSyncLinkedTags,
    getUnlinkedTags: wealthboxGetUnlinkedTags,
    status: {
      success: WEALTHBOX_INTEGRATION_SUCCESS_STATUS_CODE,
      error: WEALTHBOX_INTEGRATION_ERROR_STATUS_CODE
    }
  },
  [SALESFORCE_INTEGRATION]: {
    authorize: salesforceAuthorize,
    syncOnDemand: salesforceSyncDataOnDemand,
    status: {
      success: SALESFORCE_INTEGRATION_SUCCESS_STATUS_CODE,
      error: SALESFORCE_INTEGRATION_ERROR_STATUS_CODE
    }
  },
  [REDTAIL_INTEGRATION]: {
    authorize: redtailAuthorize,
    syncOnDemand: redtailSyncDataOnDemand,
    uploadAccountAssets: uploadAccountAssets,
    syncStatus: getRedtailSyncStatus,
    status: {
      success: REDTAIL_INTEGRATION_SUCCESS_STATUS_CODE,
      error: REDTAIL_INTEGRATION_ERROR_STATUS_CODE
    }
  },
  getAuthorizations: getIntegrationAuthorizations,
  listIntegrations,
  setActiveIntegrations
}

export function updateAccountBillingInfo (accountId, body) {
  return authSave(`/v2/billing/accounts/${accountId}`, body, {}, methods.PUT)
}

export function updateAccountLevelFeeOverrides (accountId, body) {
  return authSave(`/v2/billing/accounts/${accountId}/fee-overrides`, body, {}, methods.PUT)
}

export function updateAccountFeeSchedules (accountId, body) {
  return authSave(`/v2/billing/accounts/${accountId}/fee-schedules`, body, {}, methods.PUT)
}

export function updateAccountBillingAccounts (accountId, body) {
  return authSave(`/v2/billing/accounts/${accountId}/billing-accounts`, body, {}, methods.POST)
}

export function getAccountBillingInfo (accountId) {
  return authGet(`/v2/billing/accounts/${accountId}`)
}

export function getClientBillingInfo (clientId) {
  return authGet(`/v2/billing/clients/${clientId}`)
}

export function putClientBillingInfo (clientId, billingInfo) {
  return authPut(`/v2/billing/clients/${clientId}`, billingInfo)
}

export function createBillingRun (body) {
  return authSave('/v2/billing/runs', body)
}

export function createBillingRunExports (body = {}) {
  return authSave('/v2/billing/billing-run/export', body)
}

export function getBillingRuns (body) {
  return authPost('/v2/billing/query/runs', body)
}

export function updateBillingRun (runId, body) {
  return authSave(`/v2/billing/runs/${runId}`, body, {}, methods.PUT)
}

export function getBillingRun (billingRunId, format, params) {
  return authGet(buildPath('/v2/billing/runs/:billingRunId', { billingRunId }, { format }), params)
}

export function saveAccountTags (accountId, body) {
  return authSave(`/v2/groups/accounts/${accountId}/tags`, body)
}

export function getBillingRunFile (billingRunFileId) {
  return authGet(`/v2/billing/runs/files/${billingRunFileId}`)
}

export function runReport (templateId, body) {
  return authSave(`/v2/run-report/${templateId}`, body, {}, methods.POST)
}

export function runReportBatch (templateId, body) {
  return authSave(`/v2/batches/run/${templateId}`, body, {}, methods.POST)
}

export function getReports (params) {
  return authGet('/v2/reports/', {}, params)
}

export function getReport (reportRunId, params) {
  return authGet(`/v2/reports/${reportRunId}`, {}, params)
}

export function createReportTemplate (body) {
  return authSave('/v2/reports/templates/', body, {}, methods.POST)
}

export function updateReportTemplate (templateId, body) {
  return authPut(`/v2/reports/templates/${templateId}`, body, {}, methods.PUT)
}

export function getReportTemplates (params) {
  return authGet('/v2/reports/templates/', {}, params)
}

export function getReportTemplate (templateId, params) {
  return authGet(`/v2/reports/templates/${templateId}`, {}, params)
}

export function getReportRuntimeConfigurations (params) {
  return authGet('/v2/reports/runtime-configurations/', {}, params)
}

export function getReportRuntimeConfiguration (runtimeConfigurationId, params) {
  return authGet(`/v2/reports/runtime-configurations/${runtimeConfigurationId}`, {}, params)
}

export function createReportRuntimeConfiguration (body) {
  return authSave('/v2/reports/runtime-configurations/', body, {}, methods.POST)
}

export function updateReportRuntimeConfiguration (runtimeConfigurationId, body) {
  return authPut(`/v2/reports/runtime-configurations/${runtimeConfigurationId}`, body, {}, methods.PUT)
}

export function postReport (reportRunId, body) {
  return authPost(`/v2/reports/post/${reportRunId}`, body, {})
}

export function postReportBatch (batchRunId, body) {
  return authPost(`/v2/batches/post/${batchRunId}`, body, {})
}

export function getBatchRuns (params) {
  return authGet('/v2/batches/', {}, params)
}

export function getBatchRun (batchRunId, params) {
  return authGet(`/v2/batches/${batchRunId}`, {}, params)
}

export function getBatchDownload (batchRunId, params) {
  return authGet(`/v2/batches/download/${batchRunId}`, {}, params)
}

export function getAssetTearSheet (assetId) {
  return authGet(`/v2/assets/${assetId}/tearsheet`)
}

export function updateAsset (assetId, body) {
  return authPut(`/v2/assets/${assetId}`, body, {})
}

export function fetchBookmarks (params) {
  return authGet('/v2/bookmarks', {}, params)
}

export function saveBookmark (body) {
  return authPost('/v2/bookmarks', body)
}

export function removeBookmark (bookmarkId) {
  return authDelete(`/v2/bookmarks/${bookmarkId}`)
}

export function postPolicyCheck (body) {
  return authPost('/v2/policy/check', body)
}

export function postPoliciesCheck (body) {
  return authPost('/v2/policies/check', body)
}

export function getAccountsHealthCheck (params) {
  return authGet('/v2/accounts/health-check/', {}, params)
}

export function postCloseAccount (closeAccountRequest) {
  return authPost('/v2/accounts/close', closeAccountRequest)
}

export function getBobiOutput (bobiOutputId) {
  return authGet(`/v2/bobi/bobiOutputs/${bobiOutputId}`)
}

export function postExport (body) {
  return authPost('/v2/exports', body)
}

export function getExport (exportId) {
  return authGet(`/v2/exports/${exportId}`)
}
