import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Button, Grid, makeStyles, Box } from '@material-ui/core'
import { isEmpty } from 'lodash'
import Select from '../../../../molecules/Select'
import MultiSelect from '../../../../molecules/MultiSelect'
import { EXPOSURE_TYPES, getGroupTypesQuery } from '../helpers'
import ExposurePicker from '../exposurePicker'
import { useGroupTypeSearch } from '../../../../../api/groups'
import { ICON_NAMES, LEVEL_TYPES } from '../../../../../constants'
import Icon from '../../../../atoms/Icon'
import RemoveButton from '../../../../../components/pages/admin/FeeOverrides/RemoveButton'
import { EMPTY_STATE_TYPE } from '../ReportParameterForm'
import Tooltip from '../../../../atoms/Tooltip'
import FilterOption from './FilterOption'

const useStyles = makeStyles((theme) => ({
  row: {
    paddingBottom: '1.5rem',
    alignItems: 'center'
  },
  filterComponentContent: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '1rem',
    width: '100%'
  },
  roundedButton: {
    border: `2px solid ${theme.palette.primary.main}`,
    borderRadius: 25,
    padding: '0.45rem 1rem',
    textTransform: 'none',
    fontWeight: 'bold',
    fontSize: '0.875rem',
    alignSelf: 'flex-start',
    marginLeft: 'auto',
    '& .MuiButton-label': {
      fontSize: '0.75rem'
    }
  }
}))

const createOnChange = (key, componentValue, onChange) => (i) => (value) => {
  const begin = componentValue.slice(0, i) ?? []
  const end = componentValue.slice(i + 1) ?? []

  const item = key === 'type' ? { [key]: value, value: [] } : { ...componentValue[i], [key]: value }

  onChange([...begin, item, ...end])
}

const resolveComponent = (type, levelType, selectedOption) => {
  switch (type) {
    case EMPTY_STATE_TYPE: {
      return null
    }
    case EXPOSURE_TYPES.ASSET:
    case EXPOSURE_TYPES.CLASSIFICATION_TAG:
      // eslint-disable-next-line react/prop-types
      return props => <ExposurePicker exposureType={type} {...props} value={props.value ?? []} />
    default:
      return props => {
        const groupTypeId = selectedOption?.payload?.groupTypeId

        const groupTypeQuery = useMemo(() => {
          return getGroupTypesQuery(levelType, parseInt(groupTypeId))
        }, [groupTypeId])

        const { data = [], isLoading } = useGroupTypeSearch(
          groupTypeQuery.query,
          groupTypeQuery.queryOptions
        )
        const renderValue = useCallback((val) => {
          const labels = val.map(v => data.find(d => d.value === v)?.label)
            .filter(v => !!v)

          return labels.join(', ')
        }, [data])

        return (
          <MultiSelect
            {...props}
            renderValue={renderValue}
            disabled={isLoading}
            options={data}
            placeholder='Select an option'
            optionDisplaySelector={(o) => o.label}
            optionsKeySelector={(o) => o.key}
            optionValueSelector={(o) => o.value}
          />
        )
      }
  }
}

const mutuallyExclusive = new Set([EXPOSURE_TYPES.ASSET, EXPOSURE_TYPES.CLASSIFICATION_TAG])

const FilterPicker = ({
  selectClasses,
  filterTypeOptions,
  value,
  onChange,
  hasError,
  levelType
}) => {
  const classes = useStyles()
  const onChangeFilterType = createOnChange('type', value, onChange)
  const onChangeFilterValue = createOnChange('value', value, onChange)

  const onAddRow = useCallback(() => {
    onChange([...value, {
      type: EMPTY_STATE_TYPE,
      value: []
    }])
  }, [value, onChange])

  const onRemoveRow = (i) => (e) => {
    e.preventDefault()

    const begin = value.slice(0, i) ?? []
    const end = value.slice(i + 1) ?? []

    if (i === 0) {
      onChange(end)
    } else {
      onChange([...begin, ...end])
    }
  }

  const exclusiveVal = value.find((v) => mutuallyExclusive.has(v.type))

  const shouldDisable = filterTypeOptions
    .filter((o) => !exclusiveVal || !mutuallyExclusive.has(o.value))
    .every((o) => value.find((v) => v.type === o.value)) ||
    value.length >= filterTypeOptions.length

  const optionsRenderer = useCallback((option, onChange, checkedIcon) => {
    const { label, payload, disabled, value } = option || {}

    let caption = ''
    if (payload) {
      caption =
        payload?.memberLevelTypeLongName.toLowerCase() === LEVEL_TYPES.ACCOUNTS
          ? 'account'
          : 'client'
    }

    return (
      <FilterOption
        key={label}
        label={label}
        value={value || option}
        disabled={disabled}
        caption={caption}
        onChange={onChange}
        checkedIcon={checkedIcon}
      />
    )
  }, [])

  const renderRemoveButton = useCallback((onClick) => {
    return (
      <Tooltip title='Remove filter'>
        <RemoveButton onClick={onClick} />
      </Tooltip>
    )
  }, [])

  return (
    <div>
      <Grid container>
        {value.map((rowValue, i) => {
          const options = filterTypeOptions.filter((o) => {
            if (rowValue.type === o.value) {
              return true
            }

            if (exclusiveVal && !mutuallyExclusive.has(rowValue.type)) {
              return !mutuallyExclusive.has(o.value)
            }

            return !value.find((v) => v.type === o.value)
          })
          const type = rowValue.type
          const selectedOption = options.find(({ value }) => value === type)
          const Component = resolveComponent(type, levelType, selectedOption)
          const onChange = onChangeFilterType(i)

          if (!rowValue.type) {
            onChange(type)
          }

          return (
            <Grid
              key={`${rowValue.type}-${i}`}
              container
              item
              xs={12}
              spacing={3}
              className={classes.row}
            >
              <Grid item xs={4}>
                <Select
                  className={selectClasses}
                  fullWidth
                  variant='outlined'
                  value={type}
                  disabled={isEmpty(options)}
                  options={options}
                  onChange={onChange}
                  optionsRenderer={optionsRenderer}
                  showCheckMarOnSelectedItems
                />
              </Grid>
              {Component ? (
                <Grid item xs={8} direction='row'>
                  <div className={classes.filterComponentContent}>
                    <Box width='100%'>
                      <Component
                        value={rowValue.value}
                        hasError={hasError}
                        onChange={onChangeFilterValue(i)}
                      />
                    </Box>
                    {(value.length >= 1 ||
                      value.length >= filterTypeOptions.length) &&
                      renderRemoveButton(onRemoveRow(i))}
                  </div>
                </Grid>
              ) : (
                value.length >= 1 && renderRemoveButton(onRemoveRow(i))
              )}
            </Grid>
          )
        })}
        <div>
          <Button
            variant='outlined'
            onClick={onAddRow}
            disabled={shouldDisable}
            className={classes.roundedButton}
            startIcon={<Icon customSize='1.2rem' name={ICON_NAMES.add} />}
          >
            Add Filter
          </Button>
        </div>
      </Grid>
    </div>
  )
}

FilterPicker.propTypes = {
  filterTypeOptions: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  selectClasses: PropTypes.string,
  value: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      value: PropTypes.any,
      componentProvider: PropTypes.func.isRequired
    })
  ),
  hasError: PropTypes.bool,
  levelType: PropTypes.string
}

FilterPicker.defaultProps = {
  filterTypeOptions: [
    {
      value: 'accountCategory',
      label: 'Account Category'
    },
    {
      value: EXPOSURE_TYPES.ASSET,
      label: 'Asset'
    },
    {
      value: EXPOSURE_TYPES.CLASSIFICATION_TAG,
      label: 'Asset Tag'
    }
  ],
  value: [
    {
      type: {
        type: 'accountCategory',
        label: 'Account Category'
      },
      value: [21, 22]
    }
  ],
  hasError: false
}

export default FilterPicker
