import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { ResponsiveBar } from '@nivo/bar'
import { makeStyles } from '@material-ui/core/styles'
import { useTheme } from '@material-ui/core'
import { useFormattingContext } from '../../../../organisms/FormattingProvider/FormattingContext'
import Skeleton from '../../../../atoms/Skeleton'

const useStyles = makeStyles((theme) => ({
  chartArea: {
    height: ({ numItems }) => `${(numItems || 1) * 100}px`,
    minHeight: '300px',
    marginBottom: '30px',
    '& .__target-box': {
      fill: 'url(#dots-pattern)',
      stroke: theme.palette.gray.darker,
      strokeWidth: 2
    },
    '& text': {
      fontFamily: theme.typography.fontFamily
    }
  },
  loader: {
    minHeight: '300px',
    padding: '20px'
  }
}))

const markerLayer = ({ bars, xScale }) => {
  return bars.map(d => {
    const value = d.data.data.allocationTotal * +d.data.data.rebalancingTarget
    if (isNaN(value)) return null
    return (
      <rect
        className='__target-box'
        key={d.key}
        y={d.y}
        height={d.height}
        x={xScale(0)}
        rx={2}
        ry={2}
        width={xScale(value)}
      />
    )
  })
}

function AllocationAnalysisChart ({ avtData, loading }) {
  const { formatter } = useFormattingContext()
  const theme = useTheme()
  const graphInfo = useMemo(() => {
    if (loading) {
      return []
    }

    // eslint-disable-next-line no-unused-vars
    const maxValue = avtData.allocation.reduce((p, d) => {
      const projected = d.allocationTotal * +d.rebalancingTarget
      const compareValue = projected > d.allocationValue ? projected : d.allocationValue
      return p > compareValue ? p : compareValue
    }, 0) * 1.1

    return {
      layers: ['grid', 'axes', 'bars', 'markers', markerLayer, 'legends', 'annotations'],
      maxValue,
      numItems: avtData.allocation.length
    }
  }, [avtData, loading])
  const labelFormatter = useCallback(d => {
    return formatter(d.value, 'marketValue')
  }, [formatter])
  const classes = useStyles({ numItems: graphInfo.numItems })

  if (loading) {
    return (
      <div className={classes.loader}>
        <Skeleton height='200px' />
      </div>
    )
  }

  return (
    <div className={classes.chartArea}>
      <ResponsiveBar
        theme={{
          fontFamily: theme.typography.fontFamily,
          fontSize: '12px'
        }}
        data={avtData?.allocation || []}
        keys={['allocationValue']}
        indexBy='levelName'
        layout='horizontal'
        colors={{ scheme: 'nivo' }}
        layers={graphInfo.layers}
        padding={0.2}
        margin={{ top: 50, right: 130, bottom: 50, left: 200 }}
        label={labelFormatter}
        maxValue={graphInfo.maxValue}
        defs={[
          {
            id: 'dots-pattern',
            type: 'patternLines',
            spacing: 7,
            rotation: -45,
            lineWidth: 2,
            background: 'transparent',
            color: 'rgba(100, 100, 100, .3)'
          },
          {
            id: 'gradientC',
            type: 'linearGradient',
            gradientTransform: 'rotate(90 0.5 0.5)',
            colors: [
              { offset: 0, color: 'rgba(0, 153, 255, .2)' },
              { offset: 100, color: 'rgba(0, 153, 255, 1)' }
            ]
          }
        ]}
        fill={[
          { match: '*', id: 'gradientC' }
        ]}
        borderRadius={2}
        borderWidth={1.5}
        labelSkipWidth={100}
        enableGridX
        enableGridY={false}
        axisBottom={{
          tickRotation: -45,
          format: (d) => formatter(d, '0A')
        }}
      />
    </div>
  )
}

AllocationAnalysisChart.propTypes = {
  avtData: PropTypes.shape({
    allocation: PropTypes.array
  }),
  loading: PropTypes.bool
}

export default AllocationAnalysisChart
