import { Tooltip, makeStyles } from '@material-ui/core'
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab'
import React from 'react'
import ViewListIcon from '@material-ui/icons/ViewList'
import ViewModuleIcon from '@material-ui/icons/ViewModule'
import PropTypes from 'prop-types'
import { VIEW_TYPES } from '../helpers'

const useStyles = makeStyles(() => ({
  container: {
    marginLeft: 'auto'
  }
}))

const ViewTypeToggle = ({ value, onChange, options }) => {
  const classes = useStyles()
  return (
    <div className={classes.container}>
      <ToggleButtonGroup
        exclusive
        value={value}
        onChange={(_, nextView) => onChange(nextView)}
      >
        {options.map((option) => {
          return (
            <Tooltip key={option.value} title={option?.tooltipTitle}>
              <ToggleButton value={option.value} aria-label={option.value}>
                {option.icon}
              </ToggleButton>
            </Tooltip>
          )
        })}
      </ToggleButtonGroup>
    </div>
  )
}

ViewTypeToggle.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string,
    icon: PropTypes.node,
    tooltipTitle: PropTypes.string
  }))
}

ViewTypeToggle.defaultProps = {
  defaultValue: VIEW_TYPES.BLOCKS,
  options: [
    {
      value: VIEW_TYPES.LIST,
      tooltipTitle: 'List View',
      icon: <ViewListIcon />
    },
    {
      value: VIEW_TYPES.BLOCKS,
      tooltipTitle: 'Blocks View',
      icon: <ViewModuleIcon />
    }
  ]
}

export default ViewTypeToggle
