import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { Box, Divider, alpha, IconButton, useTheme, ClickAwayListener, CircularProgress } from '@material-ui/core'
import isEmpty from 'lodash/isEmpty'
import noop from 'lodash/noop'
import clsx from 'clsx'
import { ICON_NAMES, TEXT_VARIANTS, KEYS } from '../../constants'
import RoundedButton from '../atoms/RoundedButton'
import Icon from '../atoms/Icon'
import Text from '../atoms/Text'
import useToggle from '../../hooks/useToggle'
import useDebouncedCallback from '../../hooks/useDebouncedCallback'
import SplitButton from '../atoms/SplitButton'

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative'
  },
  textButton: {
    cursor: 'pointer'
  },
  content: ({ open, width }) => ({
    display: 'flex',
    flexDirection: 'row',
    position: 'absolute',
    top: 0,
    left: 0,
    background: theme.palette.white,
    borderRadius: '1.5rem',
    width: '340px',
    maxWidth: '340px',
    borderBottom: '1px solid #949494',
    borderBottomLeftRadius: '0 !important',
    borderBottomRightRadius: '0 !important',
    ...(open ? {
      borderBottomLeftRadius: '0',
      borderBottomRightRadius: '0'
    } : {
      borderBottomLeftRadius: '0.75rem',
      borderBottomRightRadius: '0.75rem'
    }),
    ...(width ? { maxWidth: width, width } : {})
  }),
  label: {
    display: 'block'
  },
  input: {
    width: '100%',
    border: 'none',
    fontSize: '1rem',
    borderTopRightRadius: '0.75rem',
    borderBottomRightRadius: '0.75rem',
    '&:focus': {
      outline: 'none'
    },
    '&:-webkit-input-placeholder': {
      opacity: '0.2',
      fontWeight: 'bold'
    }
  },
  listContainer: {
    padding: '1rem 0rem'
  },
  listbox: ({ width }) => ({
    display: 'flex',
    flexDirection: 'column',
    width: width || '20rem',
    zIndex: 1,
    padding: '0rem 1.125rem',
    margin: '0.5rem 0 \'0.5rem\' 0 !important',
    listStyle: 'none',
    background: theme.palette.white,
    overflow: 'auto',
    maxHeight: 250,
    '& li[data-focus="true"]': {
      backgroundColor: alpha(theme.palette.gray.dark, 0.15),
      color: 'white',
      cursor: 'pointer'
    },
    '& li:active': {
      backgroundColor: alpha(theme.palette.gray.dark, 0.45),
      color: 'white'
    }
  }),
  listOption: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0.5rem 0',
    justifyContent: 'center',
    alignItems: 'flex-start',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: alpha(theme.palette.gray.dark, 0.15),
      color: 'white',
      cursor: 'pointer'
    }
  },
  listOptionHover: {
    backgroundColor: alpha(theme.palette.gray.dark, 0.15),
    color: 'white',
    cursor: 'pointer'
  },
  listOptionTitle: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden'
  },
  optionsContainer: ({ width }) => ({
    zIndex: '1302',
    top: '2.7rem',
    position: 'absolute',
    background: theme.palette.white,
    boxShadow: '0px 20px 24px rgba(0, 0, 0, 0.12)',
    borderBottomRightRadius: '1.5rem',
    borderBottomLeftRadius: '1.5rem',
    width: '340px',
    ...(width ? { width } : {})
  }),
  emptyStateContainer: {
    top: '0.1rem !important'
  },
  loading: {
    display: 'flex',
    height: '4rem',
    alignItems: 'center',
    justifyContent: 'center'
  },
  filterButtonGroup: {
    padding: '10px 8px'
  },
  filterButtonMain: {
    padding: '0px 10px 0px 10px',
    background: '#F1F2F4 !important',
    color: '#2E3038 !important',
    borderTopLeftRadius: '20px !important',
    borderBottomLeftRadius: '20px !important',
    '&:hover': {
      backgroundColor: `${theme.palette.porcelain} !important`
    }
  },
  filterButtonSecondary: {
    padding: '0px 10px 0px 10px',
    background: '#F1F2F4 !important',
    color: '#2E3038 !important',
    borderTopRightRadius: '20px',
    borderBottomRightRadius: '20px',
    '&:hover': {
      backgroundColor: `${theme.palette.porcelain} !important`
    }
  }
}))

const AutoCompleteSearchMultipleInput = ({
  autoFocus,
  width,
  options,
  loading,
  className,
  contentClassName,
  optionsContainerClassName,
  startAsOpen,
  placeholder,
  recentOptions,
  optionTitleKey,
  optionValueKey,
  optionSubtitleKey,
  recentOptionTitleKey,
  recentOptionValueKey,
  onSearchClick,
  primaryButtonLabel,
  onChange: onCustomChange,
  onSubmitSearch,
  noOptionsFoundLabel,
  existingSelectedOptions,
  onClose
}) => {
  const theme = useTheme()
  const inputRef = useRef(null)
  const [searchText, setSearchText] = useState('')
  const [selectedOptions, setSelectedOptions] = useState(existingSelectedOptions)
  const [optionIndex, setOptionIndex] = useState(0)
  const [touched, setTouched] = useToggle()
  const [open, , toggleOpenOn, toggleOpenOff] = useToggle(startAsOpen)

  const classes = useStyles({ open, width })
  const onChangeDebounced = useDebouncedCallback(onCustomChange)

  const onSelectOption = useCallback(
    (option) => {
      if (selectedOptions.some((selectedOption) => selectedOption[optionValueKey] === option[optionValueKey])) return null
      setSelectedOptions([...selectedOptions, option])
      setSearchText('')
      if (inputRef.current) {
        inputRef.current.blur()
      }
    },
    [setSelectedOptions, selectedOptions, optionValueKey]
  )

  const clearSelectedOptions = useCallback(() => {
    setSelectedOptions([])
  }, [setSelectedOptions])

  const removeOption = useCallback((option) => {
    setSelectedOptions((prevSelectedOptions) => {
      return prevSelectedOptions.filter((op) => op[optionValueKey] !== option[optionValueKey])
    })
  }, [setSelectedOptions, optionValueKey])

  const handleSubmitSearch = useCallback(() => {
    onSubmitSearch(selectedOptions)
    inputRef.current && inputRef.current.blur()
    toggleOpenOff()
    onClose()
  }, [selectedOptions, onSubmitSearch, onClose, toggleOpenOff])

  const onKeyHandler = useCallback(
    (event) => {
      const { keyCode: key } = event
      if (key === KEYS.ENTER) {
        const option = options[optionIndex] || recentOptions[optionIndex]
        if (option) {
          onSelectOption(option)
        }
      } else if (key === KEYS.ARROW_UP) {
        setOptionIndex(prevOptionIndex => prevOptionIndex <= 0 ? prevOptionIndex : prevOptionIndex - 1)
      } else if (key === KEYS.ARROW_DOWN) {
        setOptionIndex(prevOptionIndex => {
          const defaultOptions = options.length ? options : recentOptions
          return prevOptionIndex >= defaultOptions.length ? prevOptionIndex : prevOptionIndex + 1
        })
      }
    },
    [optionIndex, options, recentOptions, onSelectOption]
  )

  const onEsc = useCallback(
    (event) => {
      if (event.keyCode === KEYS.ESC) {
        inputRef.current && inputRef.current.blur()
        toggleOpenOff()
      }
    },
    [toggleOpenOff]
  )

  useEffect(() => {
    document.addEventListener('keydown', onEsc, false)
    return () => {
      document.removeEventListener('keydown', onEsc, false)
    }
  }, [onEsc])

  const onSearchIconClick = useCallback(
    () => {
      toggleOpenOff()
      onSearchClick()
    },
    [toggleOpenOff, onSearchClick]
  )

  const onChange = useCallback(
    (e) => {
      if (!touched) {
        setTouched(true)
      }
      setSearchText(e.value)
      onChangeDebounced(e.target.value)
    },
    [setTouched, touched, onChangeDebounced]
  )

  const actionsButtons = useMemo(() =>
    <Box mx='1rem' mb='1rem' display='flex' flexDirection='row' justifyContent='space-between' alignItems='baseline'>
      <div className={classes.textButton} onClick={clearSelectedOptions}>
        <Text text='Clear' color='red' />
      </div>
      <RoundedButton primary size='extraSmall' onClick={handleSubmitSearch}>
        {primaryButtonLabel}
      </RoundedButton>
    </Box>,
  [classes.textButton, primaryButtonLabel, handleSubmitSearch, clearSelectedOptions])

  const emptyState = useMemo(() =>
    <div className={clsx(classes.optionsContainer, classes.emptyStateContainer, {
      [optionsContainerClassName]: Boolean(optionsContainerClassName)
    })}
    >
      <div>
        <ul className={classes.listbox}>
          <Box textAlign='center'>
            <Text color={theme.palette.dustyGray} customFontWeight='bold' text={noOptionsFoundLabel} />
          </Box>
        </ul>
        {actionsButtons}
      </div>
    </div>,
  [
    actionsButtons,
    theme.palette.dustyGray,
    classes.listbox,
    classes.optionsContainer,
    classes.emptyStateContainer,
    noOptionsFoundLabel,
    optionsContainerClassName
  ])

  const renderOptionsList = useCallback((options, showRecents = false) => {
    const optionLabelKey = showRecents ? recentOptionTitleKey : optionTitleKey
    const optionValKey = showRecents ? recentOptionValueKey : optionValueKey
    return (
      <>
        <ul className={classes.listbox}>
          {loading
            ? <div className={classes.loading}><CircularProgress /></div>
            : (options || []).map((option, index) => {
              const label = option[optionLabelKey]
              const subtitle = option[optionSubtitleKey]
              return (
                <li
                  key={option[optionValKey]}
                  className={clsx(classes.listOption, { [classes.listOptionHover]: optionIndex === index })}
                  onClick={() => onSelectOption(option, index)}
                  onMouseOver={() => setOptionIndex(index)}
                >
                  <Text color='#212945' customFontSize='0.875rem' text={label} customFontWeight={600} />
                  {subtitle && <Text color='#212945' customFontSize='0.75' text={subtitle} />}
                </li>
              )
            })}
        </ul>
        {actionsButtons}
      </>
    )
  }, [
    loading,
    optionIndex,
    actionsButtons,
    optionTitleKey,
    optionValueKey,
    optionSubtitleKey,
    onSelectOption,
    recentOptionTitleKey,
    recentOptionValueKey,
    classes.loading,
    classes.listbox,
    classes.listOption,
    classes.listOptionHover
  ])

  return (
    <ClickAwayListener onClickAway={toggleOpenOff}>
      <div className={clsx(classes.container, { [className]: Boolean(className) })}>
        <div className={clsx(classes.content, { [contentClassName]: Boolean(contentClassName) })}>
          <IconButton onClick={onSearchIconClick} className={classes.label}>
            <Icon name={ICON_NAMES.search} customSize='1.25rem' color='black' />
          </IconButton>
          {selectedOptions.map((option) => (
            <SplitButton
              key={option[optionValueKey]}
              text={option[optionTitleKey]}
              className={classes.filterButtonGroup}
              buttonMainClassName={classes.filterButtonMain}
              buttonSecondaryClassName={classes.filterButtonSecondary}
              iconFontSize='0.75rem'
              onClickIcon={() => removeOption(option)}
            />
          ))}
          <input
            autoFocus={autoFocus}
            ref={inputRef}
            className={classes.input}
            placeholder={placeholder}
            value={searchText}
            onChange={onChange}
            onFocus={toggleOpenOn}
            onKeyDown={onKeyHandler}
          />
        </div>
        {open && (
          <div className={classes.optionsContainer}>
            <Divider light />
            <div className={classes.listContainer}>
              {isEmpty(options) && !isEmpty(recentOptions) && (
                <>
                  {!loading && (
                    <Box mx='1rem' pb='0.5rem'>
                      <Text
                        variant={TEXT_VARIANTS.button}
                        color={theme.palette.dustyGray}
                        customFontWeight='bold'
                        text='RECENTS'
                      />
                    </Box>
                  )}
                  {renderOptionsList(recentOptions, true)}
                </>
              )}
              {!isEmpty(options) && renderOptionsList(options)}
              {isEmpty(options) && isEmpty(recentOptions) && emptyState}
            </div>
          </div>
        )}
      </div>
    </ClickAwayListener>
  )
}

AutoCompleteSearchMultipleInput.propTypes = {
  autoFocus: PropTypes.bool,
  options: PropTypes.array,
  recentOptions: PropTypes.array,
  placeholder: PropTypes.string,
  loading: PropTypes.bool,
  onChange: PropTypes.func,
  onSearchClick: PropTypes.func,
  onSubmitSearch: PropTypes.func,
  optionTitleKey: PropTypes.string.isRequired,
  optionValueKey: PropTypes.string.isRequired,
  optionSubtitleKey: PropTypes.string.isRequired,
  recentOptionTitleKey: PropTypes.string.isRequired,
  recentOptionValueKey: PropTypes.string.isRequired,
  primaryButtonLabel: PropTypes.string.isRequired,
  startAsOpen: PropTypes.bool,
  className: PropTypes.string,
  contentClassName: PropTypes.string,
  noOptionsFoundLabel: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  optionsContainerClassName: PropTypes.string,
  existingSelectedOptions: PropTypes.array,
  onClose: PropTypes.func
}

AutoCompleteSearchMultipleInput.defaultProps = {
  autoFocus: true,
  options: [],
  recentOptions: [],
  placeholder: '',
  loading: false,
  onChange: noop,
  onSearchClick: noop,
  onSubmitSearch: noop,
  startAsOpen: false,
  className: undefined,
  contentClassName: undefined,
  noOptionsFoundLabel: 'No options found',
  width: undefined,
  optionsContainerClassName: '',
  existingSelectedOptions: [],
  onClose: noop
}

export default AutoCompleteSearchMultipleInput
