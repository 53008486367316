import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { makeStyles } from '@material-ui/core/styles'
import { themeConfiguration } from '../../theme'
import Text from './Text'

dayjs.extend(utc)

const useStyles = makeStyles(() => ({
  thumbnail: {
    backgroundColor: '#EEF0F8',
    width: '32px',
    height: '36px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  }
}))

function DateThumbnail ({ date }) {
  const classes = useStyles()
  const parsedDate = useMemo(() => dayjs(date), [date])
  return (
    <div className={classes.thumbnail}>
      <Text text={parsedDate.format('D')} customFontSize='0.75rem' color={themeConfiguration.palette.cloudBurst} />
      <Text text={parsedDate.format('MMM')} customFontSize='0.5rem' color={themeConfiguration.palette.cloudBurst} />
    </div>
  )
}

DateThumbnail.propTypes = {
  date: PropTypes.string.isRequired
}

export default DateThumbnail
