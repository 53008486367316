const LEVEL_TYPE_LABELS = {
  ACCOUNT: 'Account',
  CLIENT: 'Client'
}

const CellLevelType = ({ value }) => {
  return LEVEL_TYPE_LABELS[value] ?? value
}

export default CellLevelType
