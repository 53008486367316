import React from 'react'
import { makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import { animated, useSpring } from '@react-spring/web'
import { Route, Switch } from 'react-router-dom'
import SubView from './SubView'
import { useHomeDashboard } from './HomeDashboardContext'
import IndexRedirect from './IndexRedirect'

const useStyles = makeStyles((theme) => ({
  navigation: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.layout.gap.medium,
    padding: `${theme.layout.padding.none} ${theme.layout.padding.loose}`,
    minHeight: 'calc(100vh - 70px)'
  },
  left: {
    transformOrigin: 'left'
  },
  leftContent: ({ navigationWidth, sticky }) => ({
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: '3px',
    paddingRight: '3px',
    gap: theme.layout.gap.medium,
    width: navigationWidth,
    minWidth: navigationWidth,
    maxWidth: navigationWidth,
    ...(sticky ? { position: 'sticky', top: '70px' } : {})
  }),
  right: {
    transformOrigin: 'right'
  }
}))

function Navigation ({ children, navigationWidth, sticky }) {
  const { rootUrl, expanded } = useHomeDashboard()
  const classes = useStyles({ navigationWidth, expanded, sticky })
  const [cardWidth] = useSpring(() => ({
    from: {
      width: expanded ? `calc(100% - ${navigationWidth}px)` : 'calc(100% - 0px)'
    },
    to: {
      width: expanded ? 'calc(100% - 0px)' : `calc(100% - ${navigationWidth}px)`
    }
  }), [expanded, navigationWidth])
  const [navWidth] = useSpring(() => ({
    from: {
      maxWidth: expanded ? navigationWidth : 0,
      minWidth: expanded ? navigationWidth : 0,
      opacity: expanded ? 1 : 0
    },
    to: {
      maxWidth: expanded ? 0 : navigationWidth,
      minWidth: expanded ? 0 : navigationWidth,
      opacity: expanded ? 0 : 1
    }
  }), [expanded, navigationWidth])

  return (
    <div className={classes.navigation}>
      <animated.div className={classes.left} style={navWidth}>
        <div className={classes.leftContent}>
          {children}
        </div>
      </animated.div>
      <animated.div className={classes.right} style={cardWidth}>
        <Switch>
          <Route path={rootUrl} exact>
            <IndexRedirect />
          </Route>
          <Route path={`${rootUrl}/:viewId`}>
            <SubView />
          </Route>
        </Switch>
      </animated.div>
    </div>
  )
}

Navigation.propTypes = {
  children: PropTypes.node,
  navigationWidth: PropTypes.number,
  sticky: PropTypes.bool
}

Navigation.defaultProps = {
  navigationWidth: 450
}

export default Navigation
