import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { childrenSchema } from '../../../prop-types'

const useStyles = makeStyles(() => ({
  cardListContainer: {
    display: 'flex',
    flexFlow: 'row wrap',
    alignItems: 'flex-start',
    width: '100%'
  }
}))

function ClientCardList ({
  children,
  onClick
}) {
  const classes = useStyles()

  return (
    <div className={classes.cardListContainer} onClick={onClick}>
      {children}
    </div>
  )
}

ClientCardList.propTypes = {
  children: childrenSchema.isRequired,
  onClick: PropTypes.func
}

ClientCardList.defaultProps = {
  onClick: undefined
}

export default ClientCardList
