import isEmpty from 'lodash/isEmpty'
import React from 'react'
import PropTypes from 'prop-types'
import EmptySection from '../../../atoms/EmptySection'
import CardListSkeleton from '../components/CardListSkeleton'

const ClientHighlightedList = ({
  clients,
  isLoading,
  itemClassName,
  emptyPlaceholderTitle,
  skeletonContainerClassName,
  cardComponent: CardComponent
}) => {
  if (isLoading) {
    return (
      <CardListSkeleton
        cardListLength={4}
        itemClassName={itemClassName}
        containerClassName={skeletonContainerClassName}
      />
    )
  }

  if (!isLoading && isEmpty(clients)) {
    return (
      <EmptySection
        title={emptyPlaceholderTitle}
        description=''
        titleStyles={{
          fontSize: '1rem !important'
        }}
      />
    )
  }
  return clients.map((client) => (
    <div key={client.clientId} className={itemClassName}>
      <CardComponent {...client} />
    </div>
  ))
}
ClientHighlightedList.propTypes = {
  clients: PropTypes.array,
  isLoading: PropTypes.bool,
  itemClassName: PropTypes.string,
  cardComponent: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
  emptyPlaceholderTitle: PropTypes.string,
  skeletonContainerClassName: PropTypes.string
}

ClientHighlightedList.defaultProps = {
  clients: [],
  isLoading: false,
  emptyPlaceholderTitle: 'No clients found'
}

export default ClientHighlightedList
