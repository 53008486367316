import { Rating } from '@material-ui/lab'
import React from 'react'
import PropTypes from 'prop-types'
import { SvgIcon, makeStyles } from '@material-ui/core'

const useStyles = makeStyles(() => ({
  iconEmpty: {
    color: '#FFF'
  }
}))

const Icon = (props) => {
  return (
    <SvgIcon {...props}>
      <path
        d='M10.666 1.15504C10.5371 0.906218 10.2802 0.75 10 0.75C9.71978 0.75 9.46293 0.906218 9.33404 1.15504L7.04042 5.58302L1.89653 6.2995C1.60971 6.33945 1.37152 6.54095 1.28459 6.81719C1.19766 7.09342 1.27753 7.39502 1.48978 7.59203L5.18922 11.0257L4.31786 15.8671C4.26756 16.1466 4.37941 16.4305 4.60681 16.6006C4.83421 16.7707 5.13816 16.7977 5.39202 16.6705L10 14.3613L14.608 16.6705C14.8618 16.7977 15.1658 16.7707 15.3932 16.6006C15.6206 16.4305 15.7324 16.1466 15.6821 15.8671L14.8108 11.0257L18.5102 7.59203C18.7225 7.39502 18.8023 7.09342 18.7154 6.81719C18.6285 6.54095 18.3903 6.33945 18.1035 6.2995L12.9596 5.58302L10.666 1.15504Z'
        stroke='#FFC043'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </SvgIcon>
  )
}

const Bookmark = ({ name, value, onChange }) => {
  const classes = useStyles()
  return (
    <Rating
      name={`${name}-bookmark-start`}
      max={1}
      classes={{ iconEmpty: classes.iconEmpty }}
      icon={<Icon />}
      value={value}
      onChange={onChange}
    />
  )
}

Bookmark.propTypes = {
  // needed to avoid repeating elements with same ID
  // (avoids issues with event targeting to unexpected element)
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  value: PropTypes.number,
  onChange: PropTypes.func
}

export default Bookmark
