import React, { createContext, useContext, useMemo, useRef } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import FadeIn from '../../../../molecules/Transitions/FadeIn'
import { useSearchWealthOwners } from '../../../../../api/users'
import OperationalTable from '../../../../organisms/OperationalTable'
import SydButton from '../../../../commonDesign/Button'
import DeleteUserDialog from './dialogs/DeleteUserDialog'
import ReactivateUserDialog from './dialogs/ReactivateUserDialog'

const useStyles = makeStyles((theme) => ({
  deactivated: {
    padding: theme.layout.padding.medium
  }
}))

const query = {
  filters: {
    deactivated: true
  },
  includes: {
    role: true
  },
  take: 200
}

export const defaultColumnConfig = {
  columns: [
    { Header: 'User ID', accessor: 'userId', id: 'userId' },
    { Header: 'First Name', accessor: 'firstName', id: 'firstName' },
    { Header: 'Last Name', accessor: 'lastName', id: 'lastName' },
    { Header: 'Email', accessor: 'email', id: 'email' },
    { Header: 'Experience', accessor: 'role.name', id: 'experienceName' },
    { Header: '', accessor: 'userId', id: 'actions1', Cell: Reactivator },
    { Header: '', accessor: 'userId', id: 'actions2', Cell: Deleter }
  ],
  defaultSort: [{ id: 'lastName', desc: false }]
}

const DeactivatedContext = createContext({})

function Deleter ({ row }) {
  const { deleteUser } = useContext(DeactivatedContext)
  return (
    <SydButton
      size='sm'
      icon='delete'
      variant='outline'
      priority='warning'
      onClick={() => deleteUser(row.original)}
    >Permanently Delete
    </SydButton>
  )
}

Deleter.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.any
  })
}

function Reactivator ({ row }) {
  const { reactivateUser } = useContext(DeactivatedContext)
  return (
    <SydButton
      size='sm'
      icon='sync'
      variant='outline'
      onClick={() => reactivateUser(row.original)}
    >
      Reactivate
    </SydButton>
  )
}

Reactivator.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.any
  })
}

function DeactivatedUsers () {
  const classes = useStyles()
  const deleteUser = useRef()
  const reactivateUser = useRef()
  const contextValue = useMemo(() => {
    return {
      deleteUser: (user) => deleteUser.current.open(user),
      reactivateUser: (user) => reactivateUser.current.open(user)
    }
  }, [])
  const { data, isFetching } = useSearchWealthOwners(query)

  return (
    <DeactivatedContext.Provider value={contextValue}>
      <FadeIn key='deactivated_wealth_owners' className={classes.deactivated}>
        <OperationalTable
          mode='client'
          variant='v2'
          itemName='Deactivated Wealth Owners'
          data={data || []}
          loading={isFetching}
          total={(data || []).length}
          columns={defaultColumnConfig.columns}
          defaultSort={defaultColumnConfig.defaultSort}
        />
      </FadeIn>
      <ReactivateUserDialog ref={reactivateUser} />
      <DeleteUserDialog ref={deleteUser} />
    </DeactivatedContext.Provider>
  )
}

DeactivatedUsers.propTypes = {
}

DeactivatedUsers.defaultProps = {
}

export default DeactivatedUsers
