import { useMutation, useQuery } from '@tanstack/react-query'
import { useAppContext } from '../redux/slices/appContext'
import { editDocuments, getDocument, postNamedCommand, postNamedQuery } from '../service'
import { QUERY_KEYS } from './queryKeys'

export const useSearchVaultDocuments = (query, options = {}) => {
  const { userId } = useAppContext()
  const { mapper, enabled = true } = options
  return useQuery({
    cacheTime: 1000 * 5, // 5 sec
    queryKey: [QUERY_KEYS.vaultDocuments, userId, query],
    queryFn: async () => {
      const { data } = await postNamedQuery(
        'documentVault',
        'searchDocuments',
        query
      )
      return data
    },
    select: mapper,
    enabled
  })
}

export const useAssignedAttachmentsByClient = (levelTypeId, levelId, clientId) => {
  const { userId } = useAppContext()
  return useQuery({
    enabled: !!(levelTypeId && levelId),
    queryKey: ['get-client-attachments-by-assignment', userId, levelTypeId, levelId, clientId],
    queryFn: async () => {
      const { data } = await postNamedQuery('documentVault', 'get-client-attachments-by-assignment', {
        levelTypeId,
        levelId,
        clientId
      })

      return data
    }
  })
}

export const useDocumentDownload = () => {
  const { clientId } = useAppContext()
  return useMutation({
    mutationFn: async (doc, isPreview = false) => {
      try {
        const { data } = await getDocument(clientId, doc.documentId, isPreview)
        if (isPreview) {
          window.open(data.downloadUrl)
        } else {
          const link = document.createElement('a')
          link.href = data.downloadUrl
          link.download = data.name
          document.body.appendChild(link)
          link.click()
          link.remove()
        }

        return data
      } catch (error) {
        console.error(error)
      }
    }
  })
}

export const useAddAttachment = () => {
  return useMutation({
    mutationFn: async ({ levelTypeId, levelId, documentId }) => {
      const { data } = await postNamedCommand('documentVault', 'create-attachment', {
        attachment: {
          levelTypeId,
          levelId,
          documentId
        }
      })

      return data
    }
  })
}

export const useAddMultipleAttachments = () => {
  return useMutation({
    mutationFn: async ({ levelTypeId, levelId, documents }) => {
      const attachments = documents.map(d => ({
        levelTypeId,
        levelId,
        documentId: d.id
      }))
      const { data } = await postNamedCommand('documentVault', 'create-attachments', {
        attachments
      })
      return data
    }
  })
}

export const useRemoveAttachment = () => {
  return useMutation({
    mutationFn: async ({ levelTypeId, levelId, documentId }) => {
      const { data } = await postNamedCommand('documentVault', 'remove-attachment', {
        attachment: {
          levelTypeId,
          levelId,
          documentId
        }
      })

      return data
    }
  })
}

export const useFinalizeDocumentUploads = () => {
  return useMutation({
    mutationFn: async ({ clientId, files }) => {
      const documents = files.map(f => ({
        id: f.documentSpecification?.id,
        uploadCompleted: f.status?.uploaded,
        cancelled: f.status?.aborted ?? false,
        name: f.name,
        tags: f.tags ?? []
      }))

      const { data } = await editDocuments(clientId, documents)

      return data
    }
  })
}

export const useFirmDocuments = () => {
  const appContext = useAppContext()
  return useQuery({
    queryKey: ['list-firm-documents', appContext.firmId, appContext.userId],
    queryFn: async () => {
      const { data } = await postNamedQuery('vaultV2', 'listDocuments', {
        levelTypeId: 1000000,
        levelId: [appContext.firmId],
        includeUrl: true,
        includeThumbnailUrl: true,
        contentDisposition: 'inline'
      })

      return data
    }
  })
}
