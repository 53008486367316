import React, { useMemo, useCallback, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { Stream } from '@nivo/stream'
import { makeStyles } from '@material-ui/styles'
import AutoSizer from 'react-virtualized-auto-sizer'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import last from 'lodash/last'
import noop from 'lodash/noop'
import { axisShape, chartRootShape } from '../../../prop-types/nivoCharts'
import Text from '../../atoms/Text'
import Tooltip from './Tooltip'
import StreamChartLegend from './StreamChartLegend'

dayjs.extend(customParseFormat)

const useStyles = makeStyles(() => ({
  container: ({ height }) => ({
    display: 'flex',
    flexDirection: 'column',
    height,
    width: '100%'
  }),
  content: ({ height }) => ({
    height,
    flex: '1 1 100%',
    position: 'relative'
  }),
  legendsContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    flexWrap: 'wrap',
    justifyContent: 'center'
  },
  legendItem: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    margin: '0 1rem 0.5rem 0',
    '& span': {
      fontSize: '0.625rem'
    },
    pointerEvents: 'none'
  },
  legendCircle: {
    height: '0.75rem',
    width: '0.75rem',
    borderRadius: '100%',
    marginRight: '0.5rem'
  },
  tooltipTitle: {
    fontSize: '0.75rem',
    fontWeight: 'bold',
    padding: '0.25rem 0.25rem'
  }
}))

const mapSeriesName = ({ id, name }) => `${id}_${name}`

const flatAndMapSeries = series => series
  .sort((seriesA, seriesB) => seriesA.ordinal - seriesB.ordinal)
  .reduce((acc, { id, name, color, data }) => ([
    ...acc,
    ...data.map(({ label, value }) => ({ id, name, color, label, value }))
  ]), [])

const mapAndSortXAxisTicks = (series) => {
  const uniqueXAxisTicks = [
    ...new Set(
      series.reduce((acc, { name, data }) => ([
        ...acc,
        ...data.map(datum => ({ ...datum, name }))
      ]), []).map(({ label }) => label)
    )
  ]

  return uniqueXAxisTicks.sort(
    (xAxisTickA, xAxisTickB) => {
      const dateA = dayjs(xAxisTickA).valueOf()
      const dateB = dayjs(xAxisTickB).valueOf()
      return dateA - dateB
    }
  )
}

/* eslint-disable react/prop-types */
const tooltipRenderer = ({ chartWidthRef, filterEmptyCategories, classes, ordinalMap, data }) => (props) => {
  const { slice: { stack, x, index } = {}, layer } = props
  const isFirstHalf = x < (chartWidthRef.current || 0) / 2
  const containerStyles = isFirstHalf
    ? { transform: 'translate(0, 0)' }
    : { transform: 'translate(-100%, 0)' }

  if (layer) {
    return (
      <Tooltip.Container style={containerStyles}>
        <Tooltip label={layer.label} color={layer.color} />
      </Tooltip.Container>
    )
  }

  const mapped = stack.filter((item) => filterEmptyCategories ? Boolean(item.value) : item)
    .map(item => {
      // hack: the stack data does not have the id of the item
      const id = item.layerId.split('_').at(0)
      return ({
        ...item,
        ordinal: (id in ordinalMap) ? ordinalMap[id] : Number.MAX_SAFE_INTEGER
      })
    })

  mapped.sort((a, b) => a.ordinal - b.ordinal)
  return (
    <Tooltip.Container style={containerStyles}>
      <Text className={classes.tooltipTitle}>{data[index].xAxisTick}</Text>
      {mapped.map(({ layerLabel, formattedValue, color, ordinal }) => (
        <Tooltip
          key={`${ordinal}-${layerLabel}-${formattedValue}`}
          label={layerLabel}
          value={formattedValue}
          color={color}
        />
      ))}
    </Tooltip.Container>
  )
}
/* eslint-enable react/prop-types */

const StreamChart = ({
  animate,
  series,
  curve,
  height,
  showLegends,
  margin,
  axisTop,
  axisRight,
  axisBottom,
  axisBottomFormatter,
  axisLeft,
  fillOpacity,
  tooltipFormat,
  tooltipLabel,
  filterEmptyCategories
}) => {
  const chartWidthRef = useRef()
  const classes = useStyles({ height })
  const [streamChartContainerRect, setStreamChartContainerRect] = useState(null)

  const streamChartContainerRef = useCallback(
    (node) =>
      setStreamChartContainerRect(node ? node.getBoundingClientRect() : null),
    []
  )

  const { data, keys, colors, xAxisTickValues } = useMemo(() => {
    const flatSeries = flatAndMapSeries(series)
    const colors = series.map(({ color }) => color)
    const xAxisTickValues = mapAndSortXAxisTicks(series)
    const dataSetDefaultKeyShape = series.reduce((acc, seriesData) => ({
      ...acc,
      [mapSeriesName(seriesData)]: null
    }), {})

    const { data } = xAxisTickValues.reduce((acc, xAxisTick) => {
      const dataMatches = acc.dataSet.filter(({ label }) => label === xAxisTick)
      const keyValueData = dataMatches.reduce((dataAcc, item) => {
        const { id, name, value } = item
        const seriesName = mapSeriesName({ id, name })
        return {
          ...dataAcc,
          [seriesName]: value + (dataAcc?.[seriesName] || 0)
        }
      }, {})

      acc.data = {
        ...acc.data,
        [xAxisTick]: {
          ...acc.initialDataSetShape,
          ...(acc.data[xAxisTick] || {}),
          ...keyValueData,
          xAxisTick
        }
      }
      return acc
    }, {
      data: {},
      dataSet: flatSeries,
      initialDataSetShape: dataSetDefaultKeyShape
    })

    return {
      data: Object.values(data),
      keys: Object.keys(dataSetDefaultKeyShape),
      colors,
      xAxisTickValues
    }
  }, [series])

  const tickValues = useMemo(() => {
    if (!streamChartContainerRect) return []
    const innerWidth = streamChartContainerRect.width

    const gridWidth = Math.ceil(innerWidth / xAxisTickValues.length)
    const tickDistance = Math.floor((innerWidth * 0.25) / gridWidth)

    return tickDistance === 0
      ? xAxisTickValues
      : xAxisTickValues.filter((_, i) => i % tickDistance === 0)
  }, [xAxisTickValues, streamChartContainerRect])

  const customAxisBottomFormat = useCallback(
    (value) => {
      const tickIndex = tickValues.indexOf(xAxisTickValues[value])
      const label = tickIndex !== -1 ? tickValues[tickIndex] : ''
      return axisBottomFormatter ? axisBottomFormatter(label) : label
    },
    [tickValues, xAxisTickValues, axisBottomFormatter]
  )

  const tooltipLabelFormatter = useCallback((tooltipData) => {
    return tooltipLabel
      ? tooltipLabel(tooltipData)
      : last(tooltipData?.id.split('_'))
  }, [tooltipLabel])

  const ordinalMap = useMemo(() => {
    return series.reduce((prev, cur) => {
      prev[cur.id] = cur.ordinal ?? Number.MAX_SAFE_INTEGER
      return prev
    }, {})
  }, [series])

  const renderTooltip = useMemo(() => tooltipRenderer({
    chartWidthRef,
    classes,
    filterEmptyCategories,
    ordinalMap,
    data
  }), [chartWidthRef, classes, filterEmptyCategories, ordinalMap, data])

  return (
    <div className={classes.container}>
      <div ref={streamChartContainerRef} className={classes.content}>
        <AutoSizer>
          {({ height, width }) => {
            chartWidthRef.current = width
            return (
              <Stream
                height={height}
                width={width}
                colorBy='index'
                offsetType='expand'
                curve={curve}
                margin={margin}
                keys={keys}
                data={data}
                colors={colors}
                animate={animate}
                tooltip={renderTooltip}
                stackTooltip={renderTooltip}
                fillOpacity={fillOpacity}
                axisTop={axisTop}
                axisLeft={axisLeft}
                axisRight={axisRight}
                valueFormat={tooltipFormat}
                label={tooltipLabelFormatter}
                axisBottom={{
                  ...axisBottom,
                  format: customAxisBottomFormat
                }}
              />
            )
          }}
        </AutoSizer>
      </div>
      <StreamChartLegend
        enabled={showLegends}
        classes={classes}
        keys={keys}
        series={series}
        colors={colors}
      />
    </div>
  )
}

StreamChart.propTypes = {
  ...chartRootShape,
  animate: PropTypes.bool,
  series: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.string.isRequired,
      color: PropTypes.string,
      ordinal: PropTypes.number,
      data: PropTypes.arrayOf(
        PropTypes.shape({
          value: PropTypes.number,
          label: PropTypes.string,
          date: PropTypes.string
        })
      )
    })
  ),
  curve: PropTypes.string,
  showLegends: PropTypes.bool,
  axisTop: PropTypes.shape(axisShape),
  axisRight: PropTypes.shape(axisShape),
  axisBottom: PropTypes.shape(axisShape),
  axisLeft: PropTypes.shape(axisShape),
  fillOpacity: PropTypes.number,
  tooltipFormat: PropTypes.func,
  tooltipLabel: PropTypes.func,
  axisBottomFormatter: PropTypes.func,
  filterEmptyCategories: PropTypes.bool
}

StreamChart.defaultProps = {
  margin: { top: 10, right: 0, bottom: 35, left: 40 },
  height: '36rem',
  animate: false,
  series: [],
  curve: 'linear',
  showLegends: true,
  axisTop: null,
  axisRight: null,
  axisBottom: {},
  axisLeft: {},
  fillOpacity: 0.85,
  tooltipFormat: noop,
  tooltipLabel: null,
  axisBottomFormatter: null,
  filterEmptyCategories: true
}

export default StreamChart
