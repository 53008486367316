import React from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@material-ui/core'
import Avatar from '../../../../atoms/Avatar'
import TaskDetailsTable from './TaskDetailsTable'
import { groupByClientColumnConfig } from './columnConfigs'

const ClientTasks = ({ client, type, take }) => {
  if (type === 'summary') {
    return (
      <Grid style={{ width: '350px', margin: '.5rem' }}>
        <Grid style={{ display: 'flex', marginBottom: '.2rem' }}>
          <Avatar
            src={client.photoUrl}
            avatarLetters={client.name}
            customSize='48px'
          />
          <div style={{ alignSelf: 'center', marginLeft: '1rem' }}>
            <div style={{ fontSize: '1rem', fontWeight: '400' }}>{client.name}</div>
            <div style={{ fontSize: '0.75rem', color: '#999999' }}>{client.taskCount} Tasks</div>
          </div>
        </Grid>
      </Grid>
    )
  }

  return (
    <Grid key={client.clientId} style={{ width: '450px', margin: '1rem' }}>
      <Grid style={{ display: 'flex', marginBottom: '1rem' }}>
        <Avatar
          src={client.photoUrl}
          avatarLetters={client.name}
          customSize='64px'
          isPublicResource
        />
        <div style={{ alignSelf: 'center', marginLeft: '1rem' }}>
          <div style={{ fontSize: '1.5rem', fontWeight: '700' }}>{client.name}</div>
          <div style={{ fontSize: '0.75rem', color: '#999999' }}>{client.hasMoreTasks ? `Showing ${take} tasks of ${client.taskCount}` : ''}</div>
        </div>
      </Grid>
      <TaskDetailsTable data={client.tasks} columns={groupByClientColumnConfig} />
    </Grid>
  )
}

ClientTasks.propTypes = {
  client: PropTypes.object.isRequired,
  type: PropTypes.oneOf(['summary', 'details']),
  take: PropTypes.number.isRequired
}

ClientTasks.defaultProps = {
  type: 'details'
}

export default ClientTasks
