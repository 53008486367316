import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import DebouncedInput from '../../molecules/DebouncedInput'
import { useTransactionsContext } from './TransactionsProvider'

function TransactionsTextSearch ({
  className,
  style,
  placeholder,
  delay,
  iconName,
  searchFields
}) {
  const { setTextFilter } = useTransactionsContext()
  const onChange = useCallback((value) => {
    const filterValue = searchFields.reduce((prev, curField) => {
      prev[curField.field] = [{ op: curField.op, value: value }]
      return prev
    }, {})
    setTextFilter(filterValue)
  }, [setTextFilter, searchFields])

  return (
    <DebouncedInput
      className={className}
      style={style}
      placeholder={placeholder}
      delay={delay}
      iconName={iconName}
      onChange={onChange}
    />
  )
}

TransactionsTextSearch.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  placeholder: PropTypes.string,
  delay: PropTypes.number,
  iconName: PropTypes.string,
  searchFields: PropTypes.array
}

TransactionsTextSearch.defaultProps = {
  placeholder: 'Search by asset name or account',
  searchFields: [
    { field: 'assetName', op: 'contains' },
    { field: 'accountNumber', op: 'contains' },
    { field: 'accountName', op: 'contains' }
  ]
}

export default TransactionsTextSearch
