import PropTypes from 'prop-types'
import React, { useCallback, useMemo } from 'react'
import { defaultOptions } from '../../../molecules/RelativeDateSelect'
import Select from '../../../molecules/Select'
import { BUTTON_SIZES } from '../../../../constants'
import { useChartOptions } from './ChartOptionsProvider'

function DateRangeSelect ({
  dateRangeOptions,
  dateRangeConfig
}) {
  const options = useMemo(() => {
    return dateRangeConfig.filter(x => !dateRangeOptions || dateRangeOptions.some(y => x.key.toLowerCase() === y.toLowerCase())).map(x => ({
      label: x.label,
      value: x.value
    }))
  }, [dateRangeConfig, dateRangeOptions])
  const { options: values, setOptions } = useChartOptions()
  const setValue = useCallback((value) => {
    setOptions({
      dateRange: value
    })
  }, [setOptions])

  return (
    <Select
      size={BUTTON_SIZES.small}
      value={values?.dateRange}
      onChange={setValue}
      readOnly={options.length <= 1}
      options={options}
    />
  )
}

DateRangeSelect.propTypes = {
  dateRangeOptions: PropTypes.array,
  dateRangeConfig: PropTypes.array
}

DateRangeSelect.defaultProps = {
  dateRangeConfig: defaultOptions
}

export default DateRangeSelect
