import React from 'react'
import PropTypes from 'prop-types'
import { Box, Divider, makeStyles } from '@material-ui/core'
import { noop } from 'lodash'
import clsx from 'clsx'
import { useBoolean } from '../../../../../hooks'
import Bookmark from '../../../../atoms/Bookmark'
import Text from '../../../../atoms/Text'
import CardSkeleton from '../../components/CardSkeleton'
import Tooltip from '../../../../atoms/Tooltip'
import FastAvatar from '../../../../molecules/FastAvatar'
import ClientCardFooter from './ClientCardFooter'

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: 'white',
    padding: ({ noPadding }) => (noPadding ? '' : '1rem'),
    height: '18rem',
    display: 'flex',
    width: '100%'
  },
  content: ({ loading }) => ({
    position: 'relative',
    margin: 'auto',
    width: '13rem',
    textAlign: 'center',
    height: '100%',
    background: '#FAFAF9',
    alignItems: 'center',
    borderRadius: '12px',
    display: 'flex',
    flexDirection: 'column',
    padding: '1.75rem',
    justifyContent: 'center',
    border: `2px solid ${theme.palette.gray.lighter}`,
    transition:
      'border-color .25s ease-in-out, background-color .25s ease-in-out',
    '&:hover': loading
      ? {}
      : {
        background: theme.palette.common.white,
        border: '2px solid #141929',
        cursor: 'pointer'
      }
  }),
  titleContainer: {
    minHeight: '3rem',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  title: {
    fontStyle: 'normal',
    fontSize: '1rem',
    lineHeight: '1.4',
    color: '#212945'
  },
  divider: {
    margin: '0.5rem 0 0.875rem 0',
    width: '60%',
    border: '1px solid #EEEEEE'
  },
  skeletonContainer: {
    width: '100%',
    height: '18rem',
    display: 'inline-flex'
  },
  bookmark: {
    position: 'absolute',
    top: '0.5rem',
    right: '0.5rem'
  }
}))

const ClientCardItem = ({
  client,
  onClick,
  loading,
  footer,
  noPadding,
  tooltipTitle,
  disableHover,
  clientBookmarked,
  onBookmarkChange
}) => {
  const classes = useStyles({ loading, noPadding })
  const [hovered, setHovered] = useBoolean()

  if (loading) {
    return (
      <div className={clsx(classes.container, classes.skeletonContainer)}>
        <div className={classes.content}>
          <CardSkeleton />
        </div>
      </div>
    )
  }

  return (
    <Tooltip title={tooltipTitle}>
      <div className={classes.container}>
        <div
          className={classes.content}
          onMouseEnter={setHovered.on}
          onMouseLeave={setHovered.off}
          onClick={onClick}
        >
          {(hovered && !disableHover) || clientBookmarked ? (
            <div className={classes.bookmark}>
              <Bookmark
                name={client?.clientId || ''}
                value={Boolean(clientBookmarked)}
                onChange={onBookmarkChange}
              />
            </div>
          ) : null}
          <FastAvatar
            size='lg'
            avatarUrl={client?.avatarUrl}
            abbreviation={client?.clientAbbreviation}
          />
          <Box mb='0.875rem' />
          <div className={classes.titleContainer}>
            <Text
              className={classes.title}
              text={client?.longName || client?.shortName}
              customFontWeight='bold'
            />
          </div>
          <Divider className={classes.divider} />
          {footer || <ClientCardFooter client={client} isHovered={hovered} />}
        </div>
      </div>
    </Tooltip>
  )
}

ClientCardItem.propTypes = {
  client: PropTypes.object,
  disableHover: PropTypes.bool,
  onClick: PropTypes.func,
  loading: PropTypes.bool,
  footer: PropTypes.node,
  clientBookmarked: PropTypes.bool,
  onBookmarkChange: PropTypes.func,
  noPadding: PropTypes.bool,
  tooltipTitle: PropTypes.string
}

ClientCardItem.propTypes = {
  client: {},
  disableHover: false,
  onClick: noop,
  loading: false,
  footer: undefined,
  noPadding: false,
  clientBookmarked: false,
  onBookmarkChange: noop,
  tooltipTitle: undefined
}

export default ClientCardItem
