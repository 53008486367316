import React, { useCallback, useMemo } from 'react'
import dayjs from 'dayjs'
import PropTypes from 'prop-types'
import { ResponsiveBar } from '@nivo/bar'
import { BasicTooltip } from '@nivo/tooltip'
import { makeStyles } from '@material-ui/core'
import { useAppContext } from '../../../redux/slices/appContext'
import { useProjectedIncomeV2 } from '../../../api/coreData'
import FadeIn from '../../molecules/Transitions/FadeIn'
import { useFormattingContext } from '../FormattingProvider/FormattingContext'
import { useWidgetContext } from '../../molecules/WidgetWrapper'
import BarChartSkeleton from '../../atoms/BarChartSkeleton'

const useStyles = makeStyles((theme) => ({
  chart: {
    height: ({ height }) => height ? `${height}` : '300px',
    width: '100%',
    '& text': {
      fontFamily: `${theme.typography.fontFamily} !important`
    }
  }
}))

const useProjectedIncomeChartData = ({ period, relativeValue, relativePeriod }) => {
  const { clientId, availableDates } = useAppContext()

  const { normalizedLevelFilters } = useWidgetContext()
  const filters = useMemo(() => {
    return Object.assign({}, { clientId }, (normalizedLevelFilters ?? {}))
  }, [clientId, normalizedLevelFilters])

  const query = useMemo(() => {
    return {
      query: {
        levelTypes: ['client', 'account', 'asset'],
        period,
        filters: filters,
        dateRange: {
          startDate: availableDates.mainDate,
          endDate: dayjs(availableDates.mainDate).add(relativeValue, relativePeriod).endOf(period).format('YYYY-MM-DD')
        }
      },
      options: {
        mapper: (data) => {
          return data.data?.map(item => ({
            bonds: item.bonds,
            dividends: item.dividends,
            dividendsEx: item.dividendsEx,
            bucket: item.bucket
          })) ?? []
        }
      }
    }
  }, [period, availableDates.mainDate, relativeValue, relativePeriod, filters])

  const { data, isLoading } = useProjectedIncomeV2(query.query, query.options)

  return {
    data, isLoading
  }
}

function ProjectedIncomeChart ({ keys, period, colors, valueFormat, labelFormat, bucketFormat, labels, relativeValue, relativePeriod, height }) {
  const { data, isLoading } = useProjectedIncomeChartData({ period, relativeValue, relativePeriod })
  const classes = useStyles({ height })
  const colorValues = useMemo(() => keys.reduce((result, k) => {
    result.push(colors[k])
    return result
  }, []), [colors, keys])

  const { formatter } = useFormattingContext()
  const formatValue = useCallback((d) => {
    return d.value === 0 ? null : formatter(d.value, valueFormat)
  }, [formatter, valueFormat])
  const formatLeftAxis = useCallback((value) => {
    return value === 0 ? null : formatter(value, labelFormat)
  }, [formatter, labelFormat])
  const formatBottomAxis = useCallback((value) => {
    return formatter(value, bucketFormat)
  }, [formatter, bucketFormat])

  if (isLoading) return <BarChartSkeleton height={height} barCount={8} />

  return (
    <FadeIn className={classes.chart}>
      <ResponsiveBar
        data={data ?? []}
        indexBy='bucket'
        keys={keys}
        margin={{ top: 50, right: 130, bottom: 60, left: 60 }}
        axisLeft={{
          tickSize: 5,
          tickValues: 4,
          tickPadding: 15,
          tickRotation: 0,
          truncateTickAt: 0,
          format: formatLeftAxis
        }}
        axisBottom={{
          tickSize: 0,
          tickPadding: 10,
          tickRotation: -45,
          legendOffset: 32,
          truncateTickAt: 0,
          format: formatBottomAxis
        }}
        colors={colorValues}
        label={formatValue}
        tooltip={(d) => (
          <BasicTooltip
            id={labels[d.id]}
            enableChip
            color={d.color}
            value={formatValue(d)}
          />
        )}
      />
    </FadeIn>
  )
}

ProjectedIncomeChart.propTypes = {
  keys: PropTypes.arrayOf(PropTypes.oneOf(['bonds', 'dividends', 'dividendsEx'])),
  period: PropTypes.oneOf(['day', 'month', 'year']),
  colors: PropTypes.shape({
    bonds: PropTypes.string,
    dividends: PropTypes.string,
    dividendsEx: PropTypes.string
  }),
  labels: PropTypes.shape({
    bonds: PropTypes.string,
    dividends: PropTypes.string,
    dividendsEx: PropTypes.string
  }),
  valueFormat: PropTypes.string,
  bucketFormat: PropTypes.string,
  labelFormat: PropTypes.string,
  relativeValue: PropTypes.number,
  relativePeriod: PropTypes.oneOf(['day', 'month', 'year']),
  height: PropTypes.any
}

ProjectedIncomeChart.defaultProps = {
  keys: ['bonds', 'dividendsEx'],
  period: 'month',
  colors: {
    bonds: '#a8a8b9',
    dividends: '#75ad75',
    dividendsEx: '#5851b6'
  },
  labels: {
    bonds: 'Interest',
    dividends: 'Dividends',
    dividendsEx: 'Dividends'
  },
  valueFormat: '$0.00',
  bucketFormat: '@MMM \'YY',
  labelFormat: '$0A',
  relativeValue: 1,
  relativePeriod: 'year'
}

export default ProjectedIncomeChart
