import React, { useMemo, useState } from 'react'
import { Box, Card, Divider, Grid } from '@material-ui/core'
import EmptySection from '../atoms/EmptySection'
import { useAppContext } from '../../redux/slices/appContext'
import { ICON_NAMES, TEXT_VARIANTS } from '../../constants'
import Icon from '../atoms/Icon'
import Text from '../atoms/Text'
import SearchBarInput from '../molecules/SearchBar/SearchBarInput'

const CustomIconsView = () => {
  const { isSummitUser } = useAppContext()

  const [searchText, setSearchText] = useState('')

  const filteredIcons = useMemo(() => {
    return Object.keys(ICON_NAMES).filter((iconName) => {
      return iconName.toLowerCase().includes(searchText.toLowerCase())
    })
  }, [searchText])

  if (!isSummitUser) {
    return (
      <EmptySection
        title='Access Denied'
        description='You do not have sufficient permissions to access this resource'
      />
    )
  }

  return (
    <Box display='flex' flexDirection='column' height='calc(100vh - 60px)'>
      <Box px={2}>
        <SearchBarInput
          placeholder='Search by icon name'
          onChange={setSearchText}
          onClear={() => setSearchText('')}
        />

        <Box my={2}>
          <Divider />
        </Box>
      </Box>

      <Box px={2} flexGrow={1} overflow='scroll'>
        <Grid container spacing={2}>
          {filteredIcons.map((iconName) => (
            <Grid item xs={6} sm={4} md={3} lg={2} xl={1} key={iconName}>
              <Card style={{ padding: '.5rem', textAlign: 'center' }}>
                <Box height='2.25rem'>
                  <Icon name={iconName} customSize='2rem' />
                </Box>
                <div>
                  <Text variant={TEXT_VARIANTS.caption}>{iconName}</Text>
                </div>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  )
}

export default CustomIconsView
