import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  actions: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: '0.5rem',
    alignItems: 'center',
    marginLeft: 'unset',
    '& a': {
      width: '100%'
    },
    [theme.breakpoints.up('md')]: {
      marginLeft: 'auto'
    }
  }
}))

const SectionActions = ({ actions }) => {
  const classes = useStyles()
  return (
    <div className={classes.actions}>
      {actions}
    </div>
  )
}

SectionActions.propTypes = {
  actions: PropTypes.node
}

export default SectionActions
