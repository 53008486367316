import React, { useRef } from 'react'
import { styled } from '@material-ui/core'
import { DatePicker } from '@material-ui/pickers'
import Card from '../../../../molecules/Card'
import Select from '../../../../molecules/Select'
import RuleBuilderDialog from './RuleBuilder/Dialog'
import { useTransactionTagging } from './TransactionTaggingContext'

const HeaderBar = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: theme.layout.gap.medium,
  alignItems: 'center'
}))

const dateRangeOptions = [
  { label: 'As Of Date', value: 'AOD' },
  { label: 'Last 7 Days', value: 'L7D' },
  { label: 'Last 30 Days', value: 'L30D' }
]

function Header () {
  const { client, dateRange, setDateRange, asOf, setAsOf } = useTransactionTagging()
  const ruleBuilderRef = useRef(null)
  return (
    <Card>
      <HeaderBar>
        {client ? (
          <>
            <div>{client.longName}</div>
            <div>
              <DatePicker value={asOf} onChange={(val) => setAsOf(val.format('YYYY-MM-DD'))} />
            </div>
            <div>
              <Select options={dateRangeOptions} value={dateRange} onChange={setDateRange} />
            </div>
          </>
        ) : (
          <div>Choose a Client</div>
        )}
      </HeaderBar>
      <RuleBuilderDialog ref={ruleBuilderRef} />
    </Card>
  )
}

export default Header
