import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import Text from '../../../../atoms/Text'

const useStyles = makeStyles(() => ({
  container: {
    gap: '0.5rem',
    display: 'flex',
    flexDirection: 'column',
    '& .__title': {
      fontSize: '0.75rem',
      fontWeight: 'bold'
    },
    '& .__details': {
      fontSize: '1rem'
    }
  }
}))

const FilterSummaryItem = ({ title, details }) => {
  const classes = useStyles()
  return (
    <div className={classes.container}>
      <Text className='__title'>
        {title}
      </Text>
      <Text className='__details'>
        {details}
      </Text>
    </div>
  )
}

FilterSummaryItem.propTypes = {
  title: PropTypes.string,
  details: PropTypes.string
}

export default FilterSummaryItem
