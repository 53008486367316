const mapBorderWidth = (theme, features) => {
  switch (features?.border) {
    case 'none':
      return '0'
    case 'thick':
      return '3px'
    case 'normal':
    default:
      return '1px'
  }
}

export const mapCommonStyles = (theme) => (obj) => ({
  boxShadow: ({ features }) => features?.shadow && features.shadow in theme.layout.shadow ? theme.layout.shadow[features.shadow] : theme.layout.shadow.none,
  borderStyle: 'solid',
  borderWidth: ({ features }) => mapBorderWidth(theme, features),
  '& .__attributes .__url': {
    fontWeight: theme.typography.weights.bold
  },
  ...obj
})
