import { ICON_NAMES } from '../../../../constants'

export const DATE_TYPES = {
  BIRTHDAY: 'birthday',
  CLIENT_ANNIVERSARY: 'clientanniversary',
  WEDDING_ANNIVERSARY: 'weddinganniversary'
}

export const DATE_TYPE_COLORS = {
  [DATE_TYPES.BIRTHDAY]: '#FAFACC99',
  [DATE_TYPES.CLIENT_ANNIVERSARY]: '#33CCFF99',
  DEFAULT: '#F0F0F899'
}

export const DATE_TYPE_ICONS = {
  [DATE_TYPES.BIRTHDAY]: ICON_NAMES.party,
  [DATE_TYPES.CLIENT_ANNIVERSARY]: ICON_NAMES.champagneGlass,
  [DATE_TYPES.WEDDING_ANNIVERSARY]: ICON_NAMES.champagneGlass,
  DEFAULT: ICON_NAMES.calendar
}

export const CUSTOM_DATASET_LAYOUT = {
  CARDS: 'cards',
  LIST: 'list'
}
