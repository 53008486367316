import React, { Fragment, useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { Button } from '@material-ui/core'
import { useCountIngestionItemsToBeUpdated, useIngestionItemsToBeUpdated } from '../../../../api/ingestion'
import Hideable from '../components/Hideable'
import parseCellValue from './parseCellValue'

const useStyles = makeStyles((theme) => ({
  tbu: {
    marginBottom: '1rem',
    borderBottom: `2px solid ${theme.palette.gray.dark}`,
    '& table': {
      width: '100%',
      '& th': {
        textAlign: 'left'
      },
      fontWeight: 200
    },
    '& .__replaced': {
      color: 'red',
      textDecoration: 'line-through'
    }
  }
}))

const ItemsToBeUpdated = ({ targetId, policyId }) => {
  const classes = useStyles()
  const { data: total } = useCountIngestionItemsToBeUpdated(targetId, policyId)
  const { data, isLoading, fetchNextPage, hasNextPage, error } = useIngestionItemsToBeUpdated(targetId, policyId)
  const columns = useMemo(() => {
    return (data?.pages?.length && data?.pages.at(0).targets?.length)
      ? Object.keys(data.pages.at(0).targets.at(0))
      : []
  }, [data])
  const cellTemplate = useCallback((cell) => {
    if (cell.update) {
      return (
        <div title={cell.rule}>
          <div className='__replaced'>{cell.old === null ? '<null>' : parseCellValue(cell.old)}</div>
          <div>{cell.new === null ? '<null>' : parseCellValue(cell.new)}</div>
        </div>
      )
    }
    return <span title={cell.rule}>{cell.old === null ? '<null>' : parseCellValue(cell.old)}</span>
  }, [])

  if (isLoading) {
    return <div className={classes.tbu}>Loading...</div>
  }

  if (error) {
    return <div>There was an error loading the update preview data: {error.message}</div>
  }

  return (
    <div className={classes.tbu}>
      <Hideable label={`Updates (${total})`}>
        <table>
          <thead>
            <tr>
              {columns.map((c) => (
                <th key={c}>{c}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.pages.map((page, pIndex) => (
              <Fragment key={pIndex}>
                {page.targets.map((x, i) => (
                  <tr key={`row_${i}`}>
                    {columns.map((c) => (
                      <td key={c}>{cellTemplate(x[c])}</td>
                    ))}
                  </tr>
                ))}
              </Fragment>
            ))}
          </tbody>
          <tfoot>
            {(hasNextPage && (
              <Button color='primary' variant='contained' onClick={() => fetchNextPage()}>Fetch More</Button>
            ))}
          </tfoot>
        </table>
      </Hideable>
    </div>
  )
}

ItemsToBeUpdated.propTypes = {
  targetId: PropTypes.number,
  policyId: PropTypes.number
}

export default ItemsToBeUpdated
