import React, { useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import { SecureRoute } from '@okta/okta-react'
import { Switch, useHistory, useRouteMatch } from 'react-router-dom'
import ErrorBoundary from '../../../molecules/ErrorBoundary'
import { useSetShowHeaderBackButton } from '../../../../redux/slices/appContext'
import ReportParameterForm from './ReportParameterForm'
import ReportResults from './ReportResults'

const useStyles = makeStyles((theme) => ({
  exposureReport: {
    padding: 0,
    height: '100%'
  }
}))

const useExposureReport = () => {
  const history = useHistory()
  const { path, url } = useRouteMatch()
  const onFormSubmit = useCallback(reportParameters => {
    const params = new URLSearchParams(reportParameters)
    history.push(`${url}/results?${params.toString()}`)
  }, [history, url])

  return {
    path,
    onFormSubmit
  }
}

const ExposureReport = ({ columnConfig, useSymbolInsteadOfZero, showCustodianFilter }) => {
  const classes = useStyles()
  const { path, onFormSubmit } = useExposureReport()
  const setShowHeaderBackButton = useSetShowHeaderBackButton()
  useEffect(() => {
    setShowHeaderBackButton(true)
  }, [setShowHeaderBackButton])

  return (
    <div className={classes.exposureReport}>
      <ErrorBoundary name='Exposure Report'>
        <Switch>
          <SecureRoute path={path} exact>
            <ReportParameterForm onSubmit={onFormSubmit} />
          </SecureRoute>
          <SecureRoute path={`${path}/results`}>
            <ReportResults
              columnConfig={columnConfig}
              useSymbolInsteadOfZero={useSymbolInsteadOfZero}
              showCustodianFilter={showCustodianFilter}
            />
          </SecureRoute>
        </Switch>
      </ErrorBoundary>
    </div>
  )
}

ExposureReport.propTypes = {
  columnConfig: PropTypes.object,
  useSymbolInsteadOfZero: PropTypes.string,
  showCustodianFilter: PropTypes.bool
}

ExposureReport.defaultProps = {
  columnConfig: undefined,
  useSymbolInsteadOfZero: '--',
  showCustodianFilter: false
}

export default ExposureReport
