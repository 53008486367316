import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import FadeIn from '../../../../molecules/Transitions/FadeIn'
import StatusBadge from '../StatusBadge'

const useStyles = makeStyles((theme) => ({
  headerInfoDisplay: {},
  featured: {
    fontSize: theme.typography.fontSizes.h1,
    fontWeight: theme.typography.weights.light,
    fontFamily: theme.typography.families.bold
  },
  subHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '10px',
    minHeight: '30px'
  },
  otherDetails: {
    display: 'flex',
    flexDirection: 'row',
    gap: '10px',
    margin: '10px 0'
  },
  description: {
    fontWeight: theme.typography.weights.light
  }
}))

function HeaderInfoDisplay ({ wealthOwner }) {
  const classes = useStyles()
  const fullName = useMemo(() => {
    return `${wealthOwner.firstName} ${wealthOwner.lastName}`
  }, [wealthOwner])

  return (
    <FadeIn className={classes.headerInfoDisplay}>
      <div className={classes.featured}>{fullName}</div>
      <div className={classes.subHeader}>
        <span>{wealthOwner.email}</span>
        <StatusBadge />
      </div>
    </FadeIn>
  )
}

HeaderInfoDisplay.propTypes = {
  wealthOwner: PropTypes.shape({
    userId: PropTypes.number,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    externalUserId: PropTypes.string
  })
}

export default HeaderInfoDisplay
