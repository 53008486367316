import { useMemo } from 'react'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { evaluateRule } from './ruleEvaluator'

dayjs.extend(utc)

function getClassificationsFromRules (rules) {
  return rules.flowClassificationRules.reduce((prev, cur) => {
    prev[cur.classifyAs] = []
    return prev
  }, {})
}

function classifyTransactions (transactions, rules) {
  return transactions.map(t => ({
    ...t,
    adjustedMarketValue: Number(t.adjustedMarketValue),
    flowMarketValue: Number(t.flowMarketValue),
    rules: rules.flowClassificationRules.filter(rule => evaluateRule(t, rule.if))
  }))
}

function findCandidateTransactions (transactions, classifications) {
  return transactions
    .filter(t => !!t.rules.length)
    .reduce((acc, cur) => {
      // find rule matches to the transaction
      cur.rules.forEach(rule => {
        acc[rule.classifyAs].push(cur)
      })
      return acc
    }, classifications)
}

function findOtherTransactions (transactions) {
  return transactions.filter(t => !t.rules.length)
}

function matchPositionsToTransactions (positions, candidateTransactions, rules) {
  return positions.filter(p => !!p.netAdditionsNOF)
    .map(position => {
      // group the transactions by the rules they match with the position
      const groups = Object.entries(candidateTransactions)
        .reduce((acc, [classifyAs, candidateTransactions]) => {
          const groupedTransactions = candidateTransactions.filter(t => evaluateRule({
            transaction: t,
            position
          }, rules.transactionMatchingRule))
          acc[classifyAs] = {
            transactions: groupedTransactions,
            aggregate: groupedTransactions.reduce((prev, cur) => {
              prev.adjustedMarketValue += +cur?.adjustedMarketValue || 0
              prev.flowMarketValue += +cur?.flowMarketValue || 0
              prev.count += 1
              return prev
            }, {
              adjustedMarketValue: 0,
              flowMarketValue: 0,
              count: 0
            })
          }

          return acc
        }, {})

      const tbd = Object.keys(groups).reduce((acc, cur) => {
        return (+acc) - (+groups[cur].aggregate.flowMarketValue)
      }, +position.netAdditionsNOF)

      return {
        position,
        groups,
        tbd
      }
    })
}

function aggregateMatchedPositions (accountMatches) {
  const clientGrouped = accountMatches.reduce((prev, cur) => {
    const matchingDate = prev.find(d => d.position.minDate === cur.position.minDate)
    if (!matchingDate) {
      prev.push({
        position: {
          minDate: cur.position.minDate,
          netAdditionsNOF: cur.position.netAdditionsNOF
        },
        groups: structuredClone(cur.groups)
      })
      return prev
    }
    matchingDate.position.netAdditionsNOF += +cur.position.netAdditionsNOF || 0
    Object.keys(cur.groups).forEach(group => {
      cur.groups[group].transactions.forEach(t => {
        matchingDate.groups[group].transactions.push(t)
      })
    })

    return prev
  }, [])

  // roll up the tbd stat
  clientGrouped.forEach(item => {
    Object.keys(item.groups).forEach(group => {
      item.groups[group].aggregate = item.groups[group].transactions.reduce((prev, cur) => {
        prev.adjustedMarketValue += +cur?.adjustedMarketValue || 0
        prev.flowMarketValue += +cur?.flowMarketValue || 0
        prev.count += 1
        return prev
      }, {
        adjustedMarketValue: 0,
        flowMarketValue: 0,
        count: 0
      })
    })
    item.tbd = Object.keys(item.groups).reduce((acc, cur) => {
      return (+acc) - (+item.groups[cur].aggregate.flowMarketValue)
    }, item.position.netAdditionsNOF)
  })

  return clientGrouped
}

function negate (t2) {
  return {
    ...t2,
    adjustedMarketValue: -t2.adjustedMarketValue,
    flowMarketValue: -t2.flowMarketValue
  }
}

export const useIdentifyFlows = (transactions, positions, rules) => {
  return useMemo(() => {
    if (!rules) {
      return {}
    }
    // run our scoping rules against the list of transactions
    const classifications = getClassificationsFromRules(rules)
    const classified = classifyTransactions(transactions, rules)
    const candidates = findCandidateTransactions(classified, classifications)
    const other = findOtherTransactions(classified)

    // if the account position is 0, skip it
    const accountMatches = matchPositionsToTransactions(positions, candidates, rules)
    const clientGrouped = aggregateMatchedPositions(accountMatches)

    // tag the transactions
    Object.keys(classifications).forEach(group => {
      const groupTransactions = candidates[group]
      groupTransactions.forEach(t => {
        if (group === 'Expenses') {
          if (t.flowMarketValue < 0) {
            t.suggestion = 'expenses-out'
          } else if (t.flowMarketValue > 0) {
            t.suggestion = 'expenses-in'
          }
        } else if (group === 'Flows') {
          if (t.flowMarketValue < 0) {
            t.suggestion = 'withdrawal'
          } else if (t.flowMarketValue > 0) {
            t.suggestion = 'contribution'
          }
        }
      })
      if (group === 'Flows') {
        const universe = [...groupTransactions]
        const matched = []

        rules.intraFlowMatchingRules.forEach(rule => {
          universe.forEach(t1 => {
            universe.forEach(t2 => {
              if (matched.includes(t1.transactionId)) {
                return
              }
              if (t1.transactionId === t2.transactionId || matched.includes(t2.transactionId)) {
                return
              }
              if (evaluateRule({ t1, t2: negate(t2) }, rule)) {
                matched.push(t1.transactionId)
                matched.push(t2.transactionId)
                t1.suggestion = t1.flowMarketValue >= 0 ? 'intra-client-flow-in' : 'intra-client-flow-out'
                t2.suggestion = t2.flowMarketValue >= 0 ? 'intra-client-flow-in' : 'intra-client-flow-out'
              }
            })
          })
        })
      }
    })

    return {
      classifications: Object.keys(classifications),
      accountMatches: accountMatches,
      clientMatches: clientGrouped,
      candidates,
      other
    }
  }, [transactions, positions, rules])
}
