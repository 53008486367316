import React, { useCallback, useImperativeHandle, useState } from 'react'
import PropTypes from 'prop-types'
import RoundedModal from '../../../../../molecules/RoundedModal'
import AddGroupForm from './AddGroupForm'

const AddGroupDialog = React.forwardRef(function AddGroupDialog ({ onAdd, client }, ref) {
  const [dialogState, setDialogState] = useState({ open: false, processing: false })
  const close = useCallback(() => {
    // We keep the parent here because it says "undefined" on the modal otherwise
    setDialogState((p) => ({ ...p, open: false, processing: false }))
  }, [setDialogState])

  const complete = useCallback(async (result) => {
    await onAdd({
      groupId: result.groupId,
      groupTypeId: dialogState.parent.groupTypeId,
      clientId: client.clientId
    })
    close()
  }, [close, dialogState, client, onAdd])

  useImperativeHandle(ref, () => ({
    open: (item) => setDialogState({ open: true, processing: false, parent: item })
  }), [setDialogState])

  return (
    <RoundedModal
      title={`Add Existing ${dialogState?.parent?.longName}`}
      open={dialogState.open}
      onClose={close}
      size='xmedium'
      position='top'
      disablePortal={false}
    >
      <AddGroupForm onComplete={complete} onClose={close} groupTypeId={dialogState?.parent?.groupTypeId} client={client} />
    </RoundedModal>
  )
})

AddGroupDialog.propTypes = {
  onAdd: PropTypes.func,
  client: PropTypes.object
}

export default AddGroupDialog
