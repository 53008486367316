import { makeStyles } from '@material-ui/core'
import { mapButtonStyles } from '../shared/mapButtonStyles'

export const useButtonStyles = makeStyles((theme) => ({
  commonDesignButton: mapButtonStyles(theme, {
    textTransform: 'none',
    borderRadius: '10rem',
    padding: '2px 18px',
    minHeight: '32px',
    lineHeight: '32px',
    fontWeight: theme.typography.weights.semibold,
    fontSize: theme.typography.fontSizes.medium,
    transition: 'background-color 200ms ease-in-out, color 200ms ease-in-out'
  })
}))
