import React from 'react'
import { makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import { useIsFetching } from '@tanstack/react-query'
import { useContextContainer } from '../../../../abundanceEngine/components/ContextContainer'
import ReportResultsTable from './ReportResultsTable'
import ReportResultsTitle from './ReportResultsTitle'
import { EXPOSURE_REPORT_CONTEXT_KEY } from './helpers'

const useStyles = makeStyles(() => ({
  reportResults: {
    padding: 0
  }
}))

const ReportResultsContainer = ({ columns, defaultSort, showCustodianFilter }) => {
  const classes = useStyles()
  const [reportContext] = useContextContainer(EXPOSURE_REPORT_CONTEXT_KEY)
  const isFetching = useIsFetching()

  return (
    <ReportResultsTable.Wrapper
      className={classes.reportResults}
      shouldRefreshHeaderPositioning={!isFetching}
    >
      <ReportResultsTable.SuperHeader>
        <ReportResultsTitle reportParams={reportContext} showCustodianFilter={showCustodianFilter} />
      </ReportResultsTable.SuperHeader>
      <ReportResultsTable
        columns={columns}
        reportParams={reportContext}
        defaultSort={defaultSort}
      />
    </ReportResultsTable.Wrapper>
  )
}

ReportResultsContainer.propTypes = {
  columns: PropTypes.array,
  defaultSort: PropTypes.array,
  showCustodianFilter: PropTypes.bool
}

export default ReportResultsContainer
