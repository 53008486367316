import React from 'react'
import Icon from '../../atoms/Icon'

export const cellTypeMap = {
  boolean: ({ value }) => value ? <Icon name='check' /> : <Icon name='close' />
}

export const defaultTableColumns = [
  {
    Header: 'Account Name',
    accessor: 'accountName'
  },
  {
    Header: 'Account Number',
    accessor: 'accountNumber'
  },
  {
    Header: 'Is Error',
    accessor: 'isError',
    cellType: 'boolean'
  },
  {
    Header: 'Status Message',
    accessor: 'statusData'
  }
]

export const defaultLabels = {
  buttonLabel: ['Account with error', 'Accounts with errors'],
  modalTitle: 'Accounts with errors',
  noUserActionAvailable: '',
  userActionToggle: 'Update credentials'
}
