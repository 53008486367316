import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { isFunction, noop, isEmpty } from 'lodash'
import { useBoolean } from '../../../hooks'
import { localStorageHelper } from '../../../utils/localStorageHelper'
import { getGroupingStorageConfigKey } from '../PerformanceTableV2/PerformancePresentationTable/Cells/helpers'
import { mapVisibleColumns } from './GroupingCustomizeColumnsDialog/helpers'

export const GroupingContext = createContext({
  groupings: [],
  selectGrouping: () => {},
  selectedGrouping: null,
  defaultGrouping: null,
  setIsGroupingDateRangePicker: {
    on: noop,
    off: noop,
    toggle: noop
  },
  groupsExpanded: false,
  isGroupingDateRangePickerOpen: false,
  groupingDateRanges: [],
  performanceTableProps: undefined,
  setPerformanceTableProps: undefined,
  groupingDateRangeOptions: undefined
})

export const useSetTableProps = ({
  data,
  isLoading,
  columns,
  tableProps,
  setTableProps
}) => {
  const visibleColumnIds = mapVisibleColumns(tableProps?.allColumns)

  useEffect(() => {
    const tablePropInSync = columns.length >= visibleColumnIds?.length && !isEmpty(visibleColumnIds)
    const loading = isEmpty(data) || isLoading

    if (!loading && isFunction(setTableProps) && tablePropInSync) {
      setTableProps(tableProps)
    }
  }, [
    data,
    columns,
    isLoading,
    tableProps,
    setTableProps,
    visibleColumnIds
  ])
}

const getDefaultSubstitutionOptions = (
  configKey,
  { enabled, substitutionOptions: defaultConfig = [] } = {}
) => {
  if (!configKey || !enabled) return defaultConfig

  const defaultOptions = localStorageHelper.load(configKey)
  const defaultAndConfigAreEqual = defaultOptions?.reduce(
    (acc, option, index) => {
      return acc && option?.defaultValue === defaultConfig[index]?.defaultValue
    }, true
  )
  if (defaultOptions && defaultAndConfigAreEqual) {
    return defaultOptions
  }
  return defaultConfig
}

const useGroupingColumnConfigurations = (
  configurationKey,
  defaultColumnConfigurations
) => {
  const configKey = useMemo(
    () => getGroupingStorageConfigKey(configurationKey, 'columnConfigs'),
    [configurationKey]
  )
  const [columnConfigurations, setColumnConfigurations] = useState({
    ...defaultColumnConfigurations,
    substitutionOptions: getDefaultSubstitutionOptions(
      configKey,
      defaultColumnConfigurations
    )
  })
  useEffect(
    function persistColumnConfigurations () {
      if (
        defaultColumnConfigurations.enabled &&
        !isEmpty(columnConfigurations.substitutionOptions)
      ) {
        localStorageHelper.store(
          configKey,
          columnConfigurations.substitutionOptions
        )
      }
    },
    [columnConfigurations, configKey, defaultColumnConfigurations.enabled]
  )
  return [columnConfigurations, setColumnConfigurations]
}

const getDefaultGroupingDateRanges = (selectedGrouping, groupingDateRanges) => {
  if (selectedGrouping?.value?.groupingDateRanges) {
    return [...(selectedGrouping?.value?.groupingDateRanges || [])]
  }
  return [...groupingDateRanges]
}

export const useGroupingContext = () => {
  return useContext(GroupingContext) || {}
}

export const useGroupingContextOptions = (
  groupingOptions,
  groupsExpanded,
  groupingDateRanges,
  groupingDateRangeOptions,
  configurationKey,
  onApplyClick,
  shareLocalStorageAcrossGroupings,
  options,
  defaultColumnConfigurations
) => {
  /** We default to the first available grouping */
  const defaultGrouping = useMemo(() => {
    if (isEmpty(groupingOptions)) return {}
    const [key, value] = Object.entries(groupingOptions)?.at(0)
    return { key, value }
  }, [groupingOptions])

  const [selectedGrouping, setSelectedGrouping] = useState(defaultGrouping)

  const defaultDateRanges = useMemo(() => {
    const groupingKey = shareLocalStorageAcrossGroupings
      ? undefined
      : selectedGrouping.key

    const configKey = getGroupingStorageConfigKey(
      configurationKey,
      groupingKey
    )

    if (!configKey) return groupingDateRanges

    const configuration = localStorageHelper.load(configKey) || []
    return !isEmpty(configuration) ? configuration : groupingDateRanges
  }, [
    configurationKey,
    groupingDateRanges,
    selectedGrouping.key,
    shareLocalStorageAcrossGroupings
  ])

  const selectGrouping = useCallback((groupingName) => {
    if (groupingName in groupingOptions) {
      setSelectedGrouping({ key: groupingName, value: groupingOptions[groupingName] })
    }
  }, [setSelectedGrouping, groupingOptions])

  const groupings = useMemo(() => {
    if (isEmpty(groupingOptions)) {
      return []
    }
    return Object.entries(groupingOptions).map(([key, value]) => ({ key, value }))
  }, [groupingOptions])

  const [groupingsExpanded, setGroupingsExpanded] = useBoolean(groupsExpanded)
  const [isGroupingDateRangePickerOpen, setIsGroupingDateRangePicker] = useBoolean()
  const [dateRanges, setDateRanges] = useState(
    getDefaultGroupingDateRanges(selectGrouping, defaultDateRanges)
  )
  const [tempDateRanges, setTempDateRanges] = useState(dateRanges)

  const [performanceTableProps, setPerformanceTableProps] = useState()

  useEffect(() => {
    // if there isn't a global set of date ranges, then look for one at group level
    const groupDateRanges = getDefaultGroupingDateRanges(
      selectedGrouping,
      defaultDateRanges
    )
    setDateRanges(groupDateRanges)
    setTempDateRanges(groupDateRanges)
  }, [selectedGrouping, defaultDateRanges])

  const [columnConfigurations, setColumnConfigurations] =
    useGroupingColumnConfigurations(
      configurationKey,
      defaultColumnConfigurations
    )

  return {
    options,
    groupsExpanded: groupingsExpanded,
    setGroupsExpanded: setGroupingsExpanded,
    defaultGrouping,
    selectedGrouping,
    selectGrouping,
    groupings,
    groupingDateRanges: dateRanges,
    setGroupingDateRanges: setDateRanges,
    tempGroupingDateRanges: tempDateRanges,
    setTempGroupingDateRanges: setTempDateRanges,
    defaultGroupingDateRanges: groupingDateRanges,
    isGroupingDateRangePickerOpen,
    setIsGroupingDateRangePicker,
    performanceTableProps,
    setPerformanceTableProps,
    groupingDateRangeOptions,
    configurationKey,
    onApplyClick,
    shareLocalStorageAcrossGroupings,
    columnConfigurations,
    setColumnConfigurations
  }
}
