import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import MuiAvatar from '@material-ui/core/Avatar'
import { useMilestoneImage } from '../../../../../../api/wealthJourney'
import { TEXT_VARIANTS } from '../../../../../../constants'
import Icon from '../../../../../atoms/Icon'

const useStyles = makeStyles((theme) => ({
  variantSmall: {
    width: '2rem',
    height: '2rem'
  }
}))

const EntryIcon = ({ entry, className, variant, color }) => {
  const classes = useStyles()
  const iconClass = useMemo(() => {
    let variantClass
    if (variant === 'small') {
      variantClass = classes.variantSmall
    }
    return clsx(className, variantClass)
  }, [variant, classes, className])

  const icon = useMemo(() => {
    if (!entry) return null
    if (entry.entryType?.defaultIcon) {
      return (
        <Icon name={entry.entryType.defaultIcon} variant={TEXT_VARIANTS.h1} customSize='40px' />
      )
    }
    return <span>{entry.entryText?.length && entry.entryText[0]}</span>
  }, [entry])

  const { data: imageSrc } = useMilestoneImage(entry)

  return (
    <MuiAvatar
      className={iconClass}
      src={imageSrc || entry.entryType?.defaultAvatarUrl}
      alt={entry?.entryText}
      style={{ backgroundColor: color }}
    >
      {icon}
    </MuiAvatar>
  )
}

EntryIcon.propTypes = {
  color: PropTypes.string,
  entry: PropTypes.shape({
    entryText: PropTypes.string,
    entryType: PropTypes.shape({
      defaultIcon: PropTypes.string,
      defaultAvatarUrl: PropTypes.string
    })
  }),
  className: PropTypes.string,
  variant: PropTypes.oneOf(['small', undefined])
}

export default EntryIcon
