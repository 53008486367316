import React from 'react'
import PropTypes from 'prop-types'
import SummitOnlyContent from '../../../atoms/SummitOnlyContent'

class PreviewErrorBoundary extends React.Component {
  constructor (props, context) {
    super(props, context)
    this.state = {}
  }

  componentDidCatch (error, errorInfo) {
    // name: Name of which ErrorBoundary this is
    // object: The view, container, component, layout that placed the ErrorBoundary
    console.error('Preview Error', error, errorInfo)
    this.setState({ error })
  }

  render () {
    const { error } = this.state
    const { children, errorMsg } = this.props

    if (error) {
      return (
        <div>
          <span>{errorMsg}</span>
          <SummitOnlyContent>
            <>
              <span> - </span>
              <span>{JSON.stringify(error.toString())}</span>
            </>
          </SummitOnlyContent>
        </div>
      )
    }

    return children
  }
}

PreviewErrorBoundary.propTypes = {
  children: PropTypes.node,
  errorMsg: PropTypes.string
}

PreviewErrorBoundary.defaultProps = {
  errorMsg: 'There was an error rendering this preview'
}

export default PreviewErrorBoundary
