import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles, Box } from '@material-ui/core'

import Text from '../../atoms/Text'
import Icon from '../../atoms/Icon'

import { TEXT_VARIANTS, ICON_NAMES } from '../../../constants'

const useStyles = makeStyles(() => ({
  checklistContainer: ({ noBackground }) => ({
    backgroundColor: noBackground ? 'none' : 'rgba(255, 255, 255, 0.75)',
    padding: '1.125rem',
    borderRadius: '0.5rem',
    marginBottom: '1.5rem',
    '& > div:not(:last-child)': {
      marginBottom: '0.938rem'
    }
  }),
  checkContainer: {
    gap: '1rem'
  },
  checkOpacity: {
    opacity: '50%'
  }
}))

const ChecklistItem = ({ typeError, text, classes }) => (
  <Box display='flex' alignItems='center' className={classes.checkContainer}>
    <Icon name={ICON_NAMES.customCheck} customColor={typeError ? undefined : 'mediumSeaGreen'} />
    <Text
      text={text}
      variant={TEXT_VARIANTS.body1}
      className={typeError ? '' : classes.checkOpacity}
    />
  </Box>
)

const PasswordRequirements = ({ errors, noBackground }) => {
  const classes = useStyles({ noBackground })

  return (
    <Box
      display='flex'
      flexDirection='column'
      alignItems='flex-start'
      boxShadow={noBackground ? 0 : 1}
      className={classes.checklistContainer}
      width='100%'
    >
      <Box>
        <Text text='Password Requirements:' variant={TEXT_VARIANTS.h4} customFontWeight='bold' />
      </Box>
      <ChecklistItem
        typeError={errors.stringLength}
        text='Between 8 and 30 characters'
        classes={classes}
      />
      <ChecklistItem
        typeError={errors.lowercase}
        text='At least one lowercase letter'
        classes={classes}
      />
      <ChecklistItem
        typeError={errors.uppercase}
        text='At least one uppercase letter'
        classes={classes}
      />
      <ChecklistItem
        typeError={errors.digit}
        text='At least one digit (0-9)'
        classes={classes}
      />
      <ChecklistItem
        typeError={errors.invalidPassword}
        text='Does not contain part of username'
        classes={classes}
      />
    </Box>
  )
}

PasswordRequirements.propTypes = {
  errors: PropTypes.shape({
    stringLength: PropTypes.bool,
    lowercase: PropTypes.bool,
    uppercase: PropTypes.bool,
    digit: PropTypes.bool,
    invalidPassword: PropTypes.bool
  }),
  noBackground: PropTypes.bool
}

PasswordRequirements.defaultProps = {
  noBackground: false
}

ChecklistItem.propTypes = {
  typeError: PropTypes.bool,
  text: PropTypes.string,
  classes: PropTypes.object
}

export default PasswordRequirements
