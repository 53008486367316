import React from 'react'
import { Box, Button, Grid, makeStyles, useTheme } from '@material-ui/core'
import PropTypes from 'prop-types'
import { ICON_NAMES } from '../../constants'
import Icon from '../atoms/Icon'
import Image from '../atoms/Image'
import Text from '../atoms/Text'

const useStyles = makeStyles((theme) => ({
  redirectButton: {
    borderRadius: '2rem',
    paddingRight: '1.5rem',
    paddingLeft: '1rem',
    fontWeight: 'bold',
    color: theme.palette.laser
  }
}))

const initialState = {
  title: 'What\'s new at Brighton Jones',
  subtitle: <>Brighton Jones aligns your wealth, passions, <br /> and purpose so you can pursue your richer life</>,
  sections: [
    {
      title: 'Latest articles from the team',
      articles: [
        {
          title: 'Defined Benefit Plans turn Your Savings into a Pension-Type Retirement',
          headerDetails: 'May 03, 2022 | 2 min read',
          content: 'Before defined contribution plans such as 401(k)s became the favorite, defined benefit plans were the preferred retirement savings choice for employers.',
          redirectLabel: 'Read more',
          imageSrc: 'rss-feed-05.jpeg'
        },
        {
          title: 'Why Savvy Investors Avoid the Temptation of Trends',
          headerDetails: 'April 25, 2022 | 2 min read',
          content: 'Thematic investing--whether it\'s in the newest company public offering or in some new sector of the market or some new technology--isn\'t all it\'s...',
          redirectLabel: 'Read more',
          imageSrc: 'rss-feed-03.jpeg'
        },
        {
          title: 'How You can Help Teens Make Better Life and Money Choices',
          headerDetails: 'April 21, 2022 | 3 min read',
          content: 'Your\'re invited to join Brighton Jones and CHOICES Education Group in volunteering to help boost financial literacy among middle school students.',
          redirectLabel: 'Read more',
          imageSrc: 'rss-feed-06.jpeg'
        }
      ]
    },
    {
      title: 'Upcoming events and webinars',
      articles: [
        {
          title: 'Common Investor Biases and How to Avoid Them',
          headerDetails: 'May 25, 2022 | 12:00 pm - 1:00 pm PT',
          redirectLabel: 'Register Now',
          imageSrc: 'rss-feed-01.jpeg'
        },
        {
          title: 'Fin Ed. Investing',
          headerDetails: 'May 10, 2022',
          redirectLabel: 'Watch Now',
          imageSrc: 'rss-feed-02.png'
        },
        {
          title: 'Aligning Your Money With Your Values, Purpose, and Passions',
          headerDetails: 'May 4, 2022',
          redirectLabel: 'Watch Now',
          imageSrc: 'rss-feed-04.png'
        }
      ]
    }
  ]
}

const RssFeed = ({ isAbundanceEngine }) => {
  const { title, subtitle, sections } = initialState
  const theme = useTheme()
  const classes = useStyles()
  return (
    <div>
      <Box my='1.75rem'>
        <Text text={title} customFontSize='2.5rem' customFontWeight='bold' />
      </Box>
      <Box mb='3.75rem'>
        <Text text={subtitle} customFontSize='1.5rem' customFontWeight='normal' />
      </Box>
      {sections.map(({ title: titleSection, articles }, index) => {
        return (
          <div key={`${index}-${titleSection}`}>
            <Box mb='0.5rem'>
              <Text
                text={titleSection}
                customFontWeight='bold'
                customFontSize='1.75rem'
              />
            </Box>
            <Grid container spacing={3}>
              {articles.map(({ title: articleTitle, content, redirectLabel, imageSrc, headerDetails }, index) => {
                return (
                  <Grid item xs key={`${index}-${articleTitle}`}>
                    <Box mb='2rem' textAlign='center'>
                      <Image
                        isPublicResource
                        src={isAbundanceEngine ? `../${imageSrc}` : imageSrc}
                        alt={articleTitle}
                        extraStyles={{ maxWidth: '100%', maxHeight: '22rem' }}
                      />
                    </Box>
                    <Box px='3rem'>
                      <Box mb='1rem'>
                        <Text
                          text={headerDetails}
                          color={theme.palette.gray.A700}
                          customFontSize='0.75rem'
                          customFontWeight='normal'
                        />
                      </Box>
                      <Box mb='1rem'>
                        <Text
                          text={articleTitle}
                          customFontSize='1.25rem'
                          customFontWeight='bold'
                          customFontFamily="'GothamPro-Bold'"
                        />
                      </Box>
                      {content && <Text text={content} customFontSize='1rem' />}
                      <Box mt='1rem' mb='2rem'>
                        <Button
                          startIcon={<Icon name={ICON_NAMES.navigateNext} customSize='1.5rem' />}
                          className={classes.redirectButton}
                        >
                          {redirectLabel}
                        </Button>
                      </Box>
                    </Box>
                  </Grid>
                )
              })}
            </Grid>
          </div>
        )
      })}
    </div>
  )
}

RssFeed.propTypes = {
  isAbundanceEngine: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
}

RssFeed.defaultProps = {
  isAbundanceEngine: false
}

export default RssFeed
