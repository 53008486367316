import React from 'react'
import PropTypes from 'prop-types'
import { useWealthJourney } from '../WealthJourneyProvider'
import Loading from '../../../../molecules/Loading'

function WJLoader ({ children }) {
  const { entriesLoading } = useWealthJourney()

  if (entriesLoading) {
    return (
      <Loading />
    )
  }
  return children
}

WJLoader.propTypes = {
  children: PropTypes.node.isRequired
}

export default WJLoader
