import { useCallback } from 'react'
import { createSlice, createSelector } from '@reduxjs/toolkit'
import { useSelector } from 'react-redux'
import { useAction } from '../utils'

const initialState = {
  viewId: undefined
}

const viewContextSlice = createSlice({
  name: 'viewContext',
  initialState,
  reducers: {
    setViewContext: (state, { payload }) => {
      Object.assign(state, payload)
    }
  }
})

const { actions } = viewContextSlice

const getViewContextSlice = (state) => state[viewContextSlice.name]

const getViewContextWithDefault = createSelector(
  getViewContextSlice,
  (_, defaultViewContext) => defaultViewContext,
  (viewContext, defaultViewContext) => ({
    ...defaultViewContext,
    ...viewContext
  })
)

export function useViewContext (defaultViewContext) {
  const payloadFn = useCallback(
    (state) => getViewContextWithDefault(state, defaultViewContext), [defaultViewContext]
  )
  return useSelector(payloadFn)
}

export function useSetViewContext () {
  const payloadFn = useCallback((viewContext) => (viewContext), [])
  return useAction(actions.setViewContext, payloadFn)
}

export default viewContextSlice
