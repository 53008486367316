import React, { useMemo } from 'react'
import {
  Box,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText
} from '@material-ui/core'
import PropTypes from 'prop-types'
import { CALC_TYPES, LEVEL_TYPES, TEXT_VARIANTS } from '../../../../constants'
import { useAvailableDates } from '../../../../redux/slices/appContext'
import { numeralByCase } from '../../../../utils'
import NumberFormat from '../../../atoms/NumberFormat'
import TableSkeleton from '../../../atoms/TableSkeleton'
import Text from '../../../atoms/Text'
import { useAssets } from '../../../../api/coreData'

const AdvisorTopAssetsPreview = ({ advisorId }) => {
  const [availableDates] = useAvailableDates()

  const query = useMemo(() => {
    const q = {
      limit: 10,
      startDate: availableDates.minDate,
      endDate: availableDates.mainDate,
      levelTypes: [LEVEL_TYPES.ASSETS, LEVEL_TYPES.ASSET_CLASS_TAG],
      calcType: CALC_TYPES.balance,
      orderBy: 'endingValue DESC'
    }
    if (advisorId) {
      q.advisorIds = [advisorId]
    }
    return q
  }, [advisorId, availableDates])

  const {
    data: assets,
    error,
    isLoading: loading
  } = useAssets(query)

  const topAssetSummary = useMemo(() => {
    if (loading) {
      return (
        <TableSkeleton
          showHeader={false}
          columns={2}
          rows={8}
        />
      )
    }

    if (error) {
      return <div>{error.toString()}</div>
    }

    return assets.map(({ assetId, names, endingValue }) => {
      return (
        <ListItem key={assetId} style={{ padding: '0.5rem 0rem' }}>
          <ListItemText>
            <Text
              text={names.assetName}
              variant={TEXT_VARIANTS.h3}
              color='#141929'
              customFontWeight={600}
            />
          </ListItemText>
          <ListItemSecondaryAction>
            <Text
              text={
                <NumberFormat
                  title={numeralByCase(endingValue, '0,0.0a', '0,0.0000a')}
                  number={numeralByCase(endingValue, '0,0.0a', '0,0.0a')}
                  skipFormat
                />
              }
              variant={TEXT_VARIANTS.h3}
              color='#141929'
            />
          </ListItemSecondaryAction>
        </ListItem>
      )
    })
  }, [loading, assets, error])

  return (
    <Box height='100%' width='100%' style={{ padding: '1rem' }}>
      <Box mb='1.5rem'>
        <Text text='Top Assets' variant='body2' customFontSize='1.2rem' />
      </Box>
      <List>{topAssetSummary}</List>
    </Box>
  )
}

AdvisorTopAssetsPreview.propTypes = {
  advisorId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

export default AdvisorTopAssetsPreview
