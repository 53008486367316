import React, { useMemo } from 'react'
import dayjs from 'dayjs'
import { Tooltip, withStyles } from '@material-ui/core'

const WiderTooltip = withStyles({
  tooltip: {
    maxWidth: '50rem'
  }
})(Tooltip)

export const cellTemplates = {
  date: ({ value }) => dayjs.utc(value).format('M/D/YYYY'),
  count: ({ value }) => value?.length || '-',
  bold: ({ value }) => <div style={{ fontWeight: 'bold' }}>{value}</div>,
  noWrap: ({ value }) => <div style={{ textWrap: 'nowrap', fontWeight: 'bold' }}>{value}</div>,
  clientName: ({ value }) => value.longName || value.shortName ? value?.longName || value?.shortName : '-',
  titleCaseText: ({ value }) => {
    const result = value.replace(/([A-Z])/g, ' $1')
    return result.charAt(0).toUpperCase() + result.slice(1)
  },
  longText: ({ value }) => {
    const processDescription = (description) => {
      if (description) {
        return description.replaceAll('\r', '').split('\n').map((line, i) => {
          console.log('line', line)
          return (
            <div key={`line${i}`}>{line}</div>
          )
        })
      }
      return ''
    }

    if (!value) return ''
    if (value?.length < 500) return <div>{processDescription(value)}</div>
    return (
      <WiderTooltip title={value}>
        <div>{processDescription(value)}</div>
      </WiderTooltip>
    )
  }
}

export const defaultColumnConfig = {
  columns: [
    { id: 'dueDate', Header: 'Due Date', width: 60, accessor: 'dueDate', Cell: cellTemplates.date },
    { id: 'client', Header: 'Client', width: 150, accessor: 'client', Cell: cellTemplates.clientName },
    { id: 'title', Header: 'Title', width: 100, accessor: 'title', Cell: cellTemplates.bold },
    { id: 'description', Header: 'Description', width: 300, accessor: 'description', Cell: cellTemplates.longText },
    { id: 'status', Header: 'Status', width: 50, accessor: 'status', Cell: cellTemplates.noWrap }
  ],
  defaultSort: [{ id: 'targetDate', desc: true }]
}

const columnTemplateConfigurations = {
  string: (col) => col
}

export const useColumnConfig = (columnConfig = defaultColumnConfig) => {
  return useMemo(() => {
    return {
      columns: columnConfig.columns.map((x) => {
        const type = x.type in columnTemplateConfigurations ? x.type : 'string'
        return {
          ...x,
          ...columnTemplateConfigurations[type](x)
        }
      }),
      defaultSort: columnConfig.defaultSort
    }
  }, [columnConfig])
}
