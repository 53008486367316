import React, { useEffect, useRef, useCallback, useMemo } from 'react'
import { useController } from 'react-hook-form'
import PropTypes from 'prop-types'
import { noop } from 'lodash/util'
import { numberFormatters } from '../../utils'
import InputInlineEdit from './InputInlineEdit'

const FormInputInlineEdit = ({
  name,
  control,
  isInEditMode = false,
  initialValue = null,
  width,
  maskedValueFormatter = null,
  onChange = noop,
  rules = {},
  autoFocus,
  type,
  min,
  max,
  placeholder,
  multiline,
  step
}) => {
  const {
    fieldState: { error, isTouched },
    field: { onChange: onChangeFormField, value, onBlur, ref }
  } = useController({
    name,
    control,
    rules,
    defaultValue: initialValue || ''
  })

  const onKeyDownHandler = useMemo(() => {
    if (min >= 0 && type === 'number') {
      return (evt) =>
        ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault()
    }

    if (type === 'number') {
      return (evt) => ['e', 'E', '+'].includes(evt.key) && evt.preventDefault()
    }
  }, [min, type])

  const maskedValueFormatterFunc = useMemo(() => {
    if (maskedValueFormatter) {
      const fn = numberFormatters[maskedValueFormatter]
      if (!fn) {
        throw new Error(`Invalid formatter "${maskedValueFormatter}"`)
      }
      return fn
    }
  }, [maskedValueFormatter])

  const onChangeHandler = useCallback(
    (event) => {
      onChangeFormField(event)
      onChange(event)
    },
    [onChangeFormField, onChange]
  )

  const inputRef = useRef()

  useEffect(() => {
    /* eslint-disable no-unused-expressions  */
    const currentRef = inputRef.current
    if (isInEditMode) {
      currentRef?.setEditModeOn()
    } else {
      currentRef?.setEditModeOff()
    }
  }, [isInEditMode, inputRef])

  return (
    <InputInlineEdit
      ref={(e) => {
        if (e && e.children) {
          ref(e.children[0].children[0])
        } else {
          ref()
        }
        inputRef.current = e
      }}
      multiline={multiline}
      name={name}
      value={value}
      initialValue={value}
      hideActionButtons
      onChangeHandler={onChangeHandler}
      onKeyDownHandler={onKeyDownHandler}
      error={error && isTouched}
      useErrorToValidate
      width={width}
      type={type}
      onBlur={onBlur}
      autoFocus={autoFocus}
      min={min}
      max={max}
      step={step}
      placeholder={placeholder}
      placeHolderValue={
        maskedValueFormatterFunc ? maskedValueFormatterFunc(value) : value
      }
    />
  )
}

FormInputInlineEdit.propTypes = {
  isInEditMode: PropTypes.bool,
  autoFocus: PropTypes.bool,
  control: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  initialValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  maskedValueFormatter: PropTypes.string,
  onChange: PropTypes.func,
  rules: PropTypes.object,
  type: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number,
  placeholder: PropTypes.string,
  multiline: PropTypes.bool
}

FormInputInlineEdit.defaultProps = {
  isInEditMode: false,
  initialValue: null,
  width: undefined,
  maskedValueFormatter: null,
  onChange: noop,
  rules: {},
  type: undefined,
  autoFocus: undefined,
  min: undefined,
  max: undefined,
  step: undefined,
  placeholder: undefined,
  multiline: false
}
export default FormInputInlineEdit
