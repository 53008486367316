import { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useAdvisorViewTabGroup } from './advisorViewTabGroupContext'

const Tab = ({ value, equalityComparer, index, children }) => {
  const { selectedValue } = useAdvisorViewTabGroup()
  const isSelected = useMemo(() => {
    if (index && !selectedValue) return true

    return equalityComparer(value, selectedValue)
  }, [value, selectedValue, equalityComparer, index])

  const childrenIsFunc = useMemo(() => typeof children === 'function', [children])

  return isSelected
    ? childrenIsFunc
      ? children()
      : children
    : null
}

Tab.propTypes = {
  value: PropTypes.any.isRequired,
  equalityComparer: PropTypes.func,
  index: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]).isRequired
}

Tab.defaultProps = {
  equalityComparer: (a, b) => a === b,
  index: false
}

export default Tab
