import { Box, makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import React from 'react'
import { useHistory } from 'react-router-dom/cjs/react-router-dom'
import Text from '../../atoms/Text'
import Icon from '../../atoms/Icon'

const useStyles = makeStyles(() => ({
  backButton: {
    zIndex: 2,
    cursor: 'pointer',
    position: 'absolute',
    top: '1rem',
    left: '1rem',
    display: 'flex',
    alignItems: 'center',
    '& > svg': {
      marginRight: '1rem'
    }
  }
}))

const AssetTearBackButton = ({ label, icon, redirectPath, ...props }) => {
  const classes = useStyles()
  const history = useHistory()
  const onClick = () => history.push(redirectPath)
  return (
    <Box className={classes.backButton} onClick={onClick} {...props}>
      <Icon name='back' customSize='1rem' />
      <Text text={label} />
    </Box>
  )
}

AssetTearBackButton.propTypes = {
  label: PropTypes.string,
  icon: PropTypes.string,
  redirectPath: PropTypes.string
}

AssetTearBackButton.defaultProps = {
  label: 'Back to Alternatives',
  icon: 'back',
  redirectPath: '/'
}

export default AssetTearBackButton
