import React from 'react'
import PropTypes from 'prop-types'
import {
  AbundanceEngineParameterContext,
  useAbundanceEngineParameterContextManager
} from '../../hooks/useAbundanceEngineParameters'

const AbundanceEngineContextProvider = ({ children, additionalParams }) => {
  const manager = useAbundanceEngineParameterContextManager(additionalParams)

  return (
    <AbundanceEngineParameterContext.Provider value={manager}>
      {children}
    </AbundanceEngineParameterContext.Provider>
  )
}

AbundanceEngineContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
  additionalParams: PropTypes.object
}

AbundanceEngineContextProvider.defaultProps = {
  additionalParams: {}
}

export default AbundanceEngineContextProvider
