import { Box, makeStyles } from '@material-ui/core'
import React from 'react'
import Text from '../atoms/Text'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center'
  },
  content: {
    width: 'auto'
  },
  circle: {
    width: '8rem',
    height: '8rem',
    borderRadius: '8rem',
    margin: '0 auto',
    backgroundColor: theme.palette.silver
  }
}))

const NoteEmpty = () => {
  const classes = useStyles()
  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <Box mx='auto' px={4} textAlign='center'>
          <div className={classes.circle} />
          <Box mt={1}>
            <Text
              customFontSize='1rem'
              customFontWeight='bold'
              text='No threads to show'
            />
          </Box>
        </Box>
      </div>
    </div>
  )
}

export default NoteEmpty
