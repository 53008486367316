import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { makeStyles, Grid } from '@material-ui/core'
import get from 'lodash/get'
import { useFormattingContext } from '../FormattingProvider/FormattingContext'

export const useStyles = makeStyles((theme) => ({
  fields: {
    padding: `${theme.layout.padding.medium}`,
    '& .__field': {
    },
    '& .__title': {
    },
    '& .__value': {
      fontWeight: theme.typography.weights.light
    }
  }
}))

function FieldAccessor ({ item, accessor, title, format, ...rest }) {
  const { formatter } = useFormattingContext()
  const value = useMemo(() => {
    return formatter(get(item, accessor), format)
  }, [item, accessor, formatter, format])

  return (
    <Grid item className='__field' {...rest}>
      <div className='__title'>{title}</div>
      <div className='__value'>{value}</div>
    </Grid>
  )
}

FieldAccessor.propTypes = {
  item: PropTypes.object,
  accessor: PropTypes.string,
  title: PropTypes.string,
  format: PropTypes.string
}

export default FieldAccessor
