import React from 'react'
import { ResponsiveSankey } from '@nivo/sankey'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import { useTargetModelVisualizationData } from '../../../../api/rebalancer'
import Skeleton from '../../../atoms/Skeleton'
import { useFormattingContext } from '../../../organisms/FormattingProvider/FormattingContext'
import FadeIn from '../../../molecules/Transitions/FadeIn'
import VisualizationErrorBoundary from '../components/VisualizationErrorBoundary'

const useStyles = makeStyles((theme) => ({
  diagram: {
    width: '100%',
    minHeight: '500px',
    height: '500px',
    border: `1px solid ${theme.palette.gray.main}`,
    '&.__error': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: '16px'
    }
  }
}))

// const blues = Array(50).fill(50).map((el, index) => `rgb(100, 100, ${((index * 2) + 150)})`)

function TargetModelSankeyDiagram ({ targetModelId }) {
  const classes = useStyles()
  const { formatter } = useFormattingContext()
  const { data, isLoading, error } = useTargetModelVisualizationData(targetModelId)
  // const findTooltip = useCallback(({ link }) => {
  //   return <SankeyLinkTooltip link={link} />
  // }, [])

  if (error) {
    return (
      <div className={`${classes.diagram} __error`}>
        Sorry, There was a problem visualizing the model
      </div>
    )
  }

  if (isLoading) {
    return (
      <div className={classes.diagram}>
        <Skeleton width='100%' height='100%' />
      </div>
    )
  }

  return (
    <FadeIn className={classes.diagram}>
      <VisualizationErrorBoundary name='sankey'>
        <ResponsiveSankey
          colors={node => node.nodeColor}
          data={data}
          valueFormat={x => formatter(x / 10000, 'percentage')}
          label={(node => node.name)}
          margin={{ top: 20, right: 40, bottom: 20, left: 20 }}
        />
      </VisualizationErrorBoundary>
    </FadeIn>
  )
}

TargetModelSankeyDiagram.propTypes = {
  targetModelId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
}

export default TargetModelSankeyDiagram
