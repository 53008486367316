import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import RoundedButton from '../../../atoms/RoundedButton'
import AllocationAnalysisTable from '../components/AllocationAnalysisTable'
import ClientDisplay from '../components/ClientDisplay'
import AllocationAnalysisChart from '../components/AllocationAnalysisChart'
import ClientAsOfDate from '../components/ClientAsOfDate'
import InlineError from '../components/InlineError'
import { useSubmitRebalance } from '../../../../api/rebalancer'
import InstructionsPanel, { useInstructionsPanel } from '../components/InstructionsPanel'
import ModelStats from '../components/ModelStats'
import { useAvailableCash } from '../hooks/useAvailableCash'
import { useAvtData } from './useAvtData'

const useStyles = makeStyles((theme) => ({
  flow: {
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start'
  },
  button: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    textTransform: 'unset'
  },
  buttonGroup: {
    display: 'flex',
    flexDirection: 'row',
    gap: '15px'
  }
}))

function RebalanceFlow ({ clientId }) {
  const classes = useStyles()
  const { mutateAsync: submitRebalance, isLoading: isSubmitting, error: submitError } = useSubmitRebalance()
  const history = useHistory()
  const handleSubmit = useCallback(async () => {
    const result = await submitRebalance({
      flow: 'rebalance',
      levelTypeId: 201,
      levelId: clientId
    })

    history.push(`/rebalancer/results/${result.rebalancingRunId}`)
  }, [clientId, submitRebalance, history])
  const { data: avtData, isLoading } = useAvtData(clientId)
  const { cashResponse, cashLoading } = useAvailableCash(clientId)
  const { ref: instructionsRef, onSettingsClick } = useInstructionsPanel()

  return (
    <div className={classes.flow}>
      <InlineError clientId={clientId} error={submitError} />
      <div className={classes.header}>
        <ClientDisplay clientId={clientId} />
        <div>
          <ClientAsOfDate clientId={clientId} />
          <div className={classes.buttonGroup}>
            <RoundedButton
              variant='outlined'
              onClick={onSettingsClick}
              disabled={isSubmitting}
            >
              Instructions
            </RoundedButton>
            <RoundedButton
              variant='contained'
              primary
              onClick={handleSubmit}
              disabled={isSubmitting}
            >
              {isSubmitting ? 'Submitting...' : 'Rebalance'}
            </RoundedButton>
          </div>
        </div>
      </div>
      <div>
        <ModelStats
          avtData={avtData}
          cashData={cashResponse}
          loading={isLoading || cashLoading}
          includeVariance
          includeCash
          includeValue
        />
        <AllocationAnalysisChart avtData={avtData} loading={isLoading} />
      </div>
      <AllocationAnalysisTable avtData={avtData} loading={isLoading} />
      <InstructionsPanel ref={instructionsRef} clientId={clientId} />
    </div>
  )
}

RebalanceFlow.propTypes = {
  clientId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
}

export default RebalanceFlow
