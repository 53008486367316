import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { CircularProgress } from '@material-ui/core'
import SydButton from '../../../../../commonDesign/Button'
import DebouncedInput from '../../../../../molecules/DebouncedInput'
import SydLabel from '../../../../../commonDesign/SydLabel'
import { useGroupSearch } from '../../../../../../api/groups'
import { useDialogStyles } from '../common'

function AddGroupForm ({ onComplete, groupTypeId, client }) {
  const classes = useDialogStyles()

  const [searchText, setSearchText] = useState(client?.shortName)
  const query = useMemo(() => {
    return {
      filters: {
        levelTypeId: [{ op: 'eq', value: groupTypeId }]
      },
      textSearch: {
        longName: [{ op: 'contains', value: searchText }],
        shortName: [{ op: 'contains', value: searchText }]
      },
      take: 20
    }
  }, [searchText, groupTypeId])
  const { data, isFetching } = useGroupSearch(query)
  const results = data?.data || []

  return (
    <>
      <div className={classes.form}>
        <div className={classes.formtings}>
          <SydLabel label='Search'>
            <DebouncedInput
              onChange={setSearchText}
              placeholder='Search Group Name'
              style={{ height: 'unset', minHeight: '54px' }}
            />
          </SydLabel>
        </div>
        <div>
          {isFetching ? (
            <CircularProgress />
          ) : results.length ? (
            <div className={classes.list}>
              <table className={classes.formTable}>
                <thead>
                  <tr>
                    <th>Group ID</th>
                    <th>Name</th>
                    <th>&nbsp;</th>
                  </tr>
                </thead>
                <tbody>
                  {results.map(x => (
                    <tr key={x.groupId}>
                      <td>{x.groupId}</td>
                      <td>{x.longName}</td>
                      <td>
                        <SydButton size='xs' variant='primary' onClick={() => { onComplete(x) }}>Add</SydButton>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <div>No Results</div>
          )}
        </div>
      </div>
    </>
  )
}

AddGroupForm.propTypes = {
  onComplete: PropTypes.func,
  groupTypeId: PropTypes.string,
  client: PropTypes.object
}

export default AddGroupForm
