import React, { useCallback, useMemo } from 'react'
import { makeStyles } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import OperationalTable, { useOperationalTable } from '../../../organisms/OperationalTable'
import { ADMIN_ROUTES } from '../../../../constants'
import CSVButton from '../../../atoms/CSVButton'
import ExportCsvButton from '../../../molecules/ExportCsvButton'
import { noFeeScheduleColumnConfig } from './helpers'
import { useFeatureScheduleExposureData, useFeeScheduleExposureCSVData } from './hooks'
import FeeScheduleSelector from './FeeScheduleSelector'

const useStyles = makeStyles(() => ({
  container: {
    padding: '1rem'
  },
  headspace: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end'
  }
}))

const NO_FEE_SCHEDULE_FILTER = {
  operator: 'eq',
  value: 0
}

function NoFeeScheduleAccounts () {
  const history = useHistory()
  const {
    defaultPageSize,
    pageIndex,
    pageSize,
    sort,
    onPagingChange,
    onSortingChange,
    onTableModeChange
  } = useOperationalTable(noFeeScheduleColumnConfig.defaultSort)
  const classes = useStyles()

  const onClickRow = useCallback((row) => {
    if (row.original) {
      history.push(`${ADMIN_ROUTES.ACCOUNTS}/${row.original.accountId}`)
    }
  }, [history])

  const options = useMemo(() => ({
    pageIndex,
    pageSize,
    sort,
    totalCount: NO_FEE_SCHEDULE_FILTER
  }), [pageIndex, pageSize, sort])

  const { data, loading } = useFeatureScheduleExposureData(options)

  const csvData = useFeeScheduleExposureCSVData({
    data,
    columns: noFeeScheduleColumnConfig.columns
  })

  return (
    <div className={classes.container}>
      <FeeScheduleSelector />
      <OperationalTable.SuperHeader className={classes.headspace}>
        <CSVButton data={csvData} fileName='no_fee_schedule_export'>
          <ExportCsvButton />
        </CSVButton>
      </OperationalTable.SuperHeader>
      <OperationalTable.Wrapper>
        <OperationalTable
          mode='server'
          columns={noFeeScheduleColumnConfig.columns}
          data={data}
          defaultPageSize={defaultPageSize}
          defaultSort={noFeeScheduleColumnConfig.defaultSort}
          itemName='Accounts'
          loading={loading}
          total={data.length}
          onSortingChange={onSortingChange}
          onPagingChange={onPagingChange}
          onTableModeChange={onTableModeChange}
          onRowClick={onClickRow}
        />
      </OperationalTable.Wrapper>
    </div>
  )
}

export default NoFeeScheduleAccounts
