/* eslint-disable react/jsx-key */
import React from 'react'
import PropTypes from 'prop-types'
import { useSortBy, useTable } from 'react-table'
import { makeStyles } from '@material-ui/core'
// eslint-disable-next-line no-unused-vars
import dayjs from 'dayjs'

const useStyles = makeStyles((theme) => ({
  baseTable: {
    margin: '0',
    '& th, td': {
      textAlign: 'left',
      margin: '3px 6px',
      padding: '3px 6px'
    }
  }
}))

const TaskDetailsTable = ({ columns, data }) => {
  const tableConfig = useTable({ columns, data }, useSortBy)
  const classes = useStyles()

  return (
    <table {...tableConfig.getTableProps()} className={classes.baseTable}>
      <thead>
        {tableConfig.headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                {column.render('Header')}
                <span>
                  {column.isSorted
                    ? column.isSortedDesc
                      ? ' 🔽'
                      : ' 🔼'
                    : ''}
                </span>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...tableConfig.getTableBodyProps()}>
        {tableConfig.rows.map((row, i) => {
          tableConfig.prepareRow(row)
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map(cell => {
                let cellColor = ''
                if (cell.column.id.toLowerCase().indexOf('subject') !== -1) {
                  if (row.original.service !== 'General') {
                    cell.value = `${cell.value} [${row.original.service}]`
                  }
                }
                if (cell.column.id.toLowerCase().indexOf('date') !== -1) {
                  if (dayjs(cell.value) < dayjs('2023-02-12') && dayjs(cell.value) > dayjs('2023-02-10')) {
                    // eslint-disable-next-line no-debugger
                    // debugger
                    console.log('dayjs.utc(cell.value).utc()', dayjs.utc(cell.value).subtract(dayjs(dayjs().format('YYYY-MM-DD'))), dayjs(cell.value).subtract(dayjs()).hour() < 24)
                    // console.log('', dayjs.utc(cell.value).subtract())
                    // console.log('now ...utc', cell.value, dayjs().format('YYYY-MM-DD'), dayjs(dayjs(cell.value).format('YYYY-MM-DD')), dayjs(cell.value), dayjs.utc(cell.value).utc(), dayjs(cell.value).utc(), dayjs(dayjs().format('YYYY-MM-DD')))
                  }
                  if (dayjs.utc(cell.value) < dayjs.utc(dayjs().format('YYYY-MM-DD'))) {
                    cellColor = 'red'
                    // console.log('cell.value red', cell.value)
                  } else if (dayjs(cell.value) < dayjs(dayjs().add(1, 'day').format('YYYY-MM-DD'))) {
                    cellColor = 'green'
                    if (row.original.clientId === 3261) {
                      console.log('cell.value green', cell.value, row)
                    }
                  }
                }
                return (
                  <td {...cell.getCellProps(
                    cellColor !== ''
                      ? {
                        style: { color: cellColor }
                      } : {}
                  )}
                  >{cell.render('Cell')}
                  </td>
                )
              })}
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

TaskDetailsTable.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object),
  data: PropTypes.arrayOf(PropTypes.object)
}

export default TaskDetailsTable
