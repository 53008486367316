import React from 'react'
import { makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import noop from 'lodash/noop'
import { useCheckPolicy } from '../../../../../hooks'
import { ACCOUNTS } from '../../../../../policies/admin'
import FadeIn from '../../../../molecules/Transitions/FadeIn'
import { ACCOUNT_FORM_NAMES } from '../helpers'
import AccountBillingAccounts from './AccountBillingAccounts'
import AccountBillingFeeOverrides from './AccountBillingFeeOverrides'
import AccountBillingFeeSchedules from './AccountBillingFeeSchedules'

const useStyles = makeStyles((theme) => ({
  container: {
    marginLeft: 'auto',
    display: 'flex',
    flexDirection: 'column'
  },
  spaceDivider: {
    minHeight: '1rem',
    width: '100%'
  },

  billingContainer: {
    padding: '1.7rem 0'
  }
}))

export const mapFormDefaultValues = (account) => {
  if (!account) return null

  const { billingInfo, billingStartDate = {} } = account

  const feeOverrides = (billingInfo?.feeOverrides || []).map(
    ({
      feeLevelOverrideId,
      overrideLevelType,
      overrideLevelId,
      feeScheduleId,
      billingStartDate
    }) => ({
      feeLevelOverrideId,
      feeOverrideType: overrideLevelType,
      feeOverrideLevel: overrideLevelId,
      feeScheduleOverride: feeScheduleId,
      billingStartDate: billingStartDate
    })
  )

  const billingAccountId = billingInfo?.billingAccount?.accountId || ''

  return {
    [ACCOUNT_FORM_NAMES.accountId]: account.accountId,
    [ACCOUNT_FORM_NAMES.feeOverride]: feeOverrides,
    [ACCOUNT_FORM_NAMES.billingAccount]: billingAccountId,
    [ACCOUNT_FORM_NAMES.externalBillingAccount]: billingAccountId
      ? null
      : billingInfo?.billingAccount?.externalBillingAccount || billingInfo?.externalBillingAccount || '',
    [ACCOUNT_FORM_NAMES.feeScheduleType]: billingInfo?.feeSchedule?.feeScheduleId || '',
    [ACCOUNT_FORM_NAMES.billingStartDate]: billingStartDate ? dayjs.utc(billingStartDate).format('MM/DD/YY') : null
  }
}

const AccountBillingDetails = ({
  filterAccountsByAssignedToClientIds = [],
  onSave = noop,
  disabled = false
}) => {
  const classes = useStyles()
  const userCanViewBilling = useCheckPolicy(ACCOUNTS.viewAccountBilling)

  return (
    <FadeIn className={classes.container}>
      {userCanViewBilling && (
        <>
          <AccountBillingAccounts onSave={onSave} />
          <div className={classes.spaceDivider} />

          <AccountBillingFeeSchedules
            onSave={onSave}
            disabled={disabled}
          />
          <div className={classes.spaceDivider} />

          <AccountBillingFeeOverrides
            filterAccountsByAssignedToClientIds={filterAccountsByAssignedToClientIds}
            onSave={onSave}
          />
        </>
      )}
    </FadeIn>
  )
}

AccountBillingDetails.propTypes = {
  filterAccountsByAssignedToClientIds: PropTypes.arrayOf(PropTypes.number),
  disabled: PropTypes.bool,
  onSave: PropTypes.func
}
export default AccountBillingDetails
