import React from 'react'
import { makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import Text from '../../atoms/Text'
import { ICON_NAMES, TEXT_VARIANTS } from '../../../constants'
import Icon from '../../atoms/Icon'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    width: '100%',
    maxWidth: '66rem',
    border: `2px solid ${theme.palette.grey[200]}`,
    borderRadius: '0.5rem',
    justifyContent: 'center',
    margin: '3rem auto 0'
  },
  innerContainer: {
    display: 'flex',
    flexBasis: 'content',
    alignItems: 'center',
    padding: '3rem',
    gap: '2rem'
  }
}))

function VisualBalanceEmptyState ({ title, subtitle }) {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <div className={classes.innerContainer}>
        <div>
          <Icon name={ICON_NAMES.vbsTreeColored} variant='line' key='emptyStateIcon' />
        </div>
        <div className={classes.content}>
          <Text variant={TEXT_VARIANTS.h1} customFontSize='1.25rem'>{title}</Text>
          <Text variant={TEXT_VARIANTS.body} customFontSize='1rem'>{subtitle}</Text>
        </div>
      </div>
    </div>
  )
}

VisualBalanceEmptyState.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string
}

VisualBalanceEmptyState.defaultProps = {
  title: 'No Assets or Liabilities Yet!',
  subtitle: 'Check back later to view a snapshot of your balance sheet.'
}

export default VisualBalanceEmptyState
