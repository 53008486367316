import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { ContextContainerProvider } from '../../../abundanceEngine/components/ContextContainer'
import AssetTearWrapper from './AssetTearWrapper'
import Documents from './AssetTearDocuments'
import BackButton from './AssetTearBackButton'
import Paragraph from './AssetTearParagraph'
import Title from './AssetTearTitle'
import Avatar from './AssetTearAvatar'
import Text from './AssetTearText'
import Banner from './AssetTearBanner'

export const ASSET_TEAR_CONTEXT = 'ASSET_TEAR_CONTEXT'

const AssetTearSheet = ({ children }) => {
  const contextState = useMemo(
    () => ({
      name: ASSET_TEAR_CONTEXT,
      initialState: {}
    }),
    []
  )

  return (
    <ContextContainerProvider context={contextState}>
      <AssetTearWrapper>{children}</AssetTearWrapper>
    </ContextContainerProvider>
  )
}

AssetTearSheet.propTypes = {
  children: PropTypes.node.isRequired
}

export const AssetTearDocuments = Documents
export const AssetTearBackButton = BackButton
export const AssetTearParagraph = Paragraph
export const AssetTearTitle = Title
export const AssetTearAvatar = Avatar
export const AssetTearText = Text
export const AssetTearBanner = Banner
export const AssetTearContext = AssetTearSheet

export default AssetTearSheet
