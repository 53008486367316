import React, { useCallback } from 'react'
import { IconButton, makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import { animated, useSpring } from 'react-spring'
import { useSetNotesClientId } from '../../../../../../redux/slices/noteContext'

import Icon from '../../../../../atoms/Icon'
import Skeleton from '../../../../../atoms/Skeleton'
import { SKELETON_VARIANTS } from '../../../../../../constants'
import { CLIENT_VIEW_CONTEXT } from '../../..'
import { useContextContainer } from '../../../../../../abundanceEngine/components/ContextContainer'
import { useAppContext, useSetIsNotesBoardOpen } from '../../../../../../redux/slices/appContext'
import ClientCardIntegrationButtons from '../ClientCardIntegrationButtons'
import ClientBalance from './ClientBalance'

const useStyles = makeStyles(() => ({
  footer: {
    transition: 'all 0.2s linear 0s',
    display: 'flex',
    flexDirection: 'row'
  },
  actions: {
    gap: '0.25rem',
    display: 'flex',
    flexDirection: 'row',
    transition: 'all 0.2s ease'
  },
  balance: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '22px',
    lineHeight: '26px',
    color: '#212945'
  }
}))

const ClientCardFooter = ({ isHovered, client }) => {
  const classes = useStyles()
  const setNotesClientId = useSetNotesClientId()
  const setIsNotesBoardOpen = useSetIsNotesBoardOpen()

  const [
    {
      integrations,
      clientBalances,
      isLoadingBalances,
      hideNoteCommentsButton
    } = {}
  ] = useContextContainer(CLIENT_VIEW_CONTEXT, false)

  const { isAdvisor } = useAppContext()
  const {
    showSalesforceClientInfo,
    showWealthBoxClientInfo,
    showRedTailClientInfo,
    showPractifiClientInfo,
    isCrmAuthorized
  } = integrations || {}

  const showIntegration = isAdvisor && isCrmAuthorized

  const clientBalance = clientBalances?.[client?.clientId]

  const actionsProps = useSpring({
    from: {
      opacity: 0
    },
    to: {
      opacity: isHovered ? 1 : 0
    }
  })

  const onCommentClick = useCallback(
    async (e) => {
      e.stopPropagation()
      setNotesClientId(client.clientId)
      setIsNotesBoardOpen(true)
    },
    [client, setNotesClientId, setIsNotesBoardOpen]
  )

  const showActions =
    ((showSalesforceClientInfo ||
      showWealthBoxClientInfo ||
      showRedTailClientInfo ||
      showPractifiClientInfo) &&
      showIntegration) ||
    !hideNoteCommentsButton

  if (isLoadingBalances) {
    return (
      <Skeleton variant={SKELETON_VARIANTS.text} height='2.5rem' width='4rem' />
    )
  }

  return (
    <>
      {!isHovered || !showActions ? (
        <ClientBalance {...clientBalance} />
      ) : (
        <animated.div className={classes.actions} style={actionsProps}>
          {!hideNoteCommentsButton && (
            <IconButton onClick={onCommentClick}>
              <Icon name='chat' customSize='1.125rem' padding='1rem' />
            </IconButton>
          )}
          {showIntegration && (
            <ClientCardIntegrationButtons
              {...client}
              showSalesforceClientInfo={showSalesforceClientInfo}
              showWealthBoxClientInfo={showWealthBoxClientInfo}
              showRedTailClientInfo={showRedTailClientInfo}
              showPractifiClientInfo={showPractifiClientInfo}
            />
          )}
        </animated.div>
      )}
    </>
  )
}

ClientCardFooter.propTypes = {
  client: PropTypes.shape({
    clientId: PropTypes.number,
    endingValue: PropTypes.number,
    balanceChangeStatus: PropTypes.string
  }),
  isHovered: PropTypes.bool
}

export default ClientCardFooter
