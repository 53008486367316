import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { LinkViewTabGroupContext, useLinkViewTabGroupValues } from './LinkViewTabGroupContext'

const TabGroup = ({ children, tabOptions, onChange }) => {
  const tabContext = useLinkViewTabGroupValues(tabOptions)

  useEffect(() => {
    onChange && onChange(tabContext.selectedValue)
  }, [onChange, tabContext.selectedValue])

  return (
    <LinkViewTabGroupContext.Provider value={tabContext}>
      {children}
    </LinkViewTabGroupContext.Provider>
  )
}

TabGroup.propTypes = {
  tabOptions: PropTypes.object,
  children: PropTypes.node,
  onChange: PropTypes.func
}

export default TabGroup
