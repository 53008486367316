import React from 'react'
import FadeIn from '../../../../molecules/Transitions/FadeIn'
import ExperienceSection from './ExperienceSection'

function GeneralTab () {
  return (
    <FadeIn>
      <ExperienceSection />
    </FadeIn>
  )
}

GeneralTab.propTypes = {
}

export default GeneralTab
