import React, { useMemo, useState, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import { CircularProgress, makeStyles } from '@material-ui/core'
import { useDropzone } from 'react-dropzone'
import noop from 'lodash/noop'
import { ICON_NAMES } from '../../constants'
import Icon from '../atoms/Icon'
import Image from '../atoms/Image'

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  borderRadius: '100%',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
  width: '6.25rem',
  height: '6.25rem',
  position: 'relative',
  overflow: 'hidden',
  background: 'rgb(223, 230, 255)',
  border: '1px solid',
  borderColor: 'transparent',
  cursor: 'pointer'
}

const hoverStyles = {
  background: 'rgba(223, 230, 255, 0.8)'
}

const focusedStyle = {
  borderColor: '#2196f3'
}

const acceptStyle = {
  borderColor: '#00e676'
}

const rejectStyle = {
  borderColor: '#ff1744'
}

const useStyles = makeStyles(() => ({
  container: {
    width: 'fit-content'
  },
  icon: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-65%)'
  },
  label: {
    color: 'white',
    backgroundColor: '#212945',
    bottom: '0',
    textAlign: 'center',
    position: 'absolute',
    fontSize: '0.75rem',
    padding: '0.1875rem 0 0.5rem 0',
    width: '100%'
  },
  contentButtonBackground: {
    position: 'absolute',
    top: '0',
    zIndex: 1,
    height: '100%',
    width: '100%',
    background: 'rgba(223, 230, 255, 0.7)'
  },
  centeredButton: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)'
  }
}))

function ProfilePictureFileInput ({
  ignoreDelete,
  label,
  disabled,
  disabledOpacity,
  isLoading,
  acceptFiles,
  placeholderSrc,
  onDropAccepted: _onDropAccepted,
  onRemovePicture
}) {
  const classes = useStyles()
  const [hovered, setHovered] = useState(false)
  const [file, setFile] = useState(null)

  const onDropAccepted = useCallback(async ([file]) => {
    setFile(
      Object.assign(file, {
        preview: URL.createObjectURL(file)
      })
    )
    setHovered(false)
  }, [])

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
    useDropzone({
      accept: acceptFiles,
      maxFiles: 1,
      onDropAccepted,
      disabled
    })

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(disabled ? { opacity: disabledOpacity } : {}),
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {})
    }),
    [isFocused, isDragAccept, isDragReject, disabled, disabledOpacity]
  )

  useEffect(() => {
    return () => {
      if (file && file?.preview) {
        URL.revokeObjectURL(file.preview)
      }
    }
  }, [file])

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    _onDropAccepted(file)
  }, [file])
  /* eslint-enable react-hooks/exhaustive-deps */

  const onDeletePicture = useCallback((event) => {
    event.stopPropagation()
    setFile(null)
    onRemovePicture()
  }, [onRemovePicture])

  const renderContent = useMemo(() => {
    if (file || placeholderSrc) {
      return (
        <Image
          isPublicResource
          extraStyles={baseStyle}
          src={file?.preview || placeholderSrc}
          onLoad={() => {
            URL.revokeObjectURL(file.preview)
          }}
        />
      )
    }
    return (
      <div className={classes.icon}>
        <Icon customSize='2rem' color='#212945' name={ICON_NAMES.camera} />
      </div>
    )
  }, [classes.icon, placeholderSrc, file])

  const renderHoverContent = useMemo(() => {
    if (ignoreDelete) return null
    if (isLoading) return null
    if (!(hovered && (file || placeholderSrc))) return null
    return (
      <div className={classes.contentButtonBackground} onClick={onDeletePicture}>
        <div className={classes.centeredButton}>
          <Icon name={ICON_NAMES.delete} customSize='2rem' color='#212945' />
        </div>
      </div>
    )
  }, [
    ignoreDelete,
    hovered,
    file,
    isLoading,
    placeholderSrc,
    onDeletePicture,
    classes.centeredButton,
    classes.contentButtonBackground
  ])

  const renderIsLoadingContent = useMemo(() => {
    if (!isLoading || !file) return null
    return (
      <div className={classes.contentButtonBackground}>
        <div className={classes.centeredButton}>
          <CircularProgress />
        </div>
      </div>
    )
  }, [
    file,
    isLoading,
    classes.centeredButton,
    classes.contentButtonBackground
  ])

  const onMouseEnter = useCallback(() => !disabled && setHovered(true), [disabled])
  const onMouseLeave = useCallback(() => setHovered(false), [])
  const containerStyle = { ...style, ...(hovered ? hoverStyles : {}) }

  return (
    <div
      className={classes.container}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <div {...getRootProps({ style: containerStyle })}>
        <input {...getInputProps()} />
        {renderIsLoadingContent}
        {renderHoverContent}
        {renderContent}
        {label && (<span className={classes.label}>{label}</span>)}
      </div>
    </div>
  )
}

ProfilePictureFileInput.propTypes = {
  ignoreDelete: PropTypes.bool,
  label: PropTypes.string,
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool,
  placeholderSrc: PropTypes.string,
  acceptFiles: PropTypes.arrayOf(PropTypes.string),
  onDropAccepted: PropTypes.func,
  onRemovePicture: PropTypes.func,
  disabledOpacity: PropTypes.number
}

ProfilePictureFileInput.defaultProps = {
  ignoreDelete: false,
  label: 'Image',
  isLoading: false,
  disabled: false,
  placeholderSrc: null,
  acceptFiles: ['image/*'],
  onDropAccepted: noop,
  onRemovePicture: noop,
  disabledOpacity: 0.5
}

export default ProfilePictureFileInput
