import React from 'react'
import { makeStyles } from '@material-ui/core'
import { useComponentModelContext } from '../ComponentModelProvider'

const useStyles = makeStyles((theme) => ({
  header: {
    marginBottom: '20px',
    '& .__name': {
      fontSize: theme.typography.fontSizes.h3
    },
    '& .__desc': {
      fontWeight: theme.typography.weights.light
    }
  }
}))

function ComponentModelHeader () {
  const classes = useStyles()
  const { componentModel } = useComponentModelContext()
  return (
    <div className={classes.header}>
      <div className='__name'>{componentModel.name}</div>
      <div className='__desc'>{componentModel.description}</div>
    </div>
  )
}

export default ComponentModelHeader
