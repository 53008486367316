import React from 'react'
import { Box, makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import { useContextContainer } from '../../../abundanceEngine/components/ContextContainer'
import Image from '../../atoms/Image'
import { ASSET_TEAR_CONTEXT } from '.'

const useStyles = makeStyles(() => ({
  banner: {
    height: '16rem',
    maxWidth: '100%',
    '& > img': {
      maxWidth: '100%'
    }
  }
}))

const AssetTearBanner = ({ srcKey, ...props }) => {
  const classes = useStyles()
  const [{ data }] = useContextContainer(ASSET_TEAR_CONTEXT)
  return (
    <Box className={classes.banner} {...props}>
      <Image
        isPublicResource
        src={srcKey ? data?.[srcKey] : data?.banner}
        alt='asset banner'
      />
    </Box>
  )
}

AssetTearBanner.propTypes = {
  srcKey: PropTypes.string
}

AssetTearBanner.defaultProps = {
  srcKey: ''
}

export default AssetTearBanner
