import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import MuiAvatar from '@material-ui/core/Avatar'
import { MILESTONE_CATEGORIES, ICON_NAMES, TEXT_VARIANTS } from '../../constants'
import Icon from '../atoms/Icon'
import { useMilestoneImage } from '../../api/wealthJourney'

const categoryIconMap = {
  [MILESTONE_CATEGORIES.MILESTONE]: <Icon name={ICON_NAMES.bookmark} variant={TEXT_VARIANTS.h1} />,
  [MILESTONE_CATEGORIES.REAL_ESTATE]: <Icon name={ICON_NAMES.homeIcon} variant={TEXT_VARIANTS.h1} />,
  [MILESTONE_CATEGORIES.BIRTH]: <Icon name={ICON_NAMES.birth} variant={TEXT_VARIANTS.h1} />,
  [MILESTONE_CATEGORIES.RETIREMENT]: <Icon name={ICON_NAMES.workOff} variant={TEXT_VARIANTS.h1} />,
  [MILESTONE_CATEGORIES.TRAVEL]: <Icon name={ICON_NAMES.travelExplore} variant={TEXT_VARIANTS.h1} />,
  [MILESTONE_CATEGORIES.SAVINGS]: <Icon name={ICON_NAMES.savings} variant={TEXT_VARIANTS.h1} />
}

const useStyles = makeStyles((theme) => ({
  variantSmall: {
    width: '2rem',
    height: '2rem'
  }
}))

const MilestoneIcon = ({ milestone, className, variant, color }) => {
  const classes = useStyles()
  const iconClass = useMemo(() => {
    let variantClass
    if (variant === 'small') {
      variantClass = classes.variantSmall
    }
    return clsx(className, variantClass)
  }, [variant, classes, className])
  // TODO: Find a way to tell us which icon to display
  const icon = useMemo(() => {
    if (!milestone) return null
    const entry = milestone.entryJson
    if (entry.category && categoryIconMap[entry.category]) {
      return categoryIconMap[entry.category]
    }
    return <span>{entry.title?.length && entry.title[0]}</span>
  }, [milestone])

  const { data: imageSrc } = useMilestoneImage(milestone)

  return (
    <MuiAvatar className={iconClass} src={imageSrc} alt={milestone?.entryJson?.title} style={{ backgroundColor: color }}>
      {icon}
    </MuiAvatar>
  )
}

MilestoneIcon.propTypes = {
  color: PropTypes.string,
  milestone: PropTypes.object.isRequired,
  className: PropTypes.string,
  variant: PropTypes.oneOf(['small', undefined])
}

export default MilestoneIcon
