import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles, Grid, TextField } from '@material-ui/core'
import FadeIn from '../../../../molecules/Transitions/FadeIn'

const useStyles = makeStyles((theme) => ({
  userInfoForm: {
    maxWidth: '800px',
    display: 'flex',
    justifyContent: 'center',
    padding: '10px',
    '& p': {
      color: `${theme.palette.primary.dark}`
    }
  },
  inputLabel: {
    color: theme.palette.black,
    fontWeight: 600,
    '& span': {
      color: theme.palette.black
    }
  }
}))

function ClientInfoDisplay ({ client }) {
  const classes = useStyles()
  const labelProps = {
    className: classes.inputLabel
  }

  return (
    <FadeIn className={classes.userInfoForm}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12}>
          <TextField
            label='Display Name'
            fullWidth
            disabled
            value={client.shortName}
            InputProps={{ disableUnderline: true }}
            InputLabelProps={labelProps}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            label='Full Name'
            fullWidth
            disabled
            value={client.longName}
            InputProps={{ disableUnderline: true }}
            InputLabelProps={labelProps}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            label='Wealth Mission'
            fullWidth
            disabled
            value={client.description || '--'}
            InputProps={{ disableUnderline: true }}
            InputLabelProps={labelProps}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            label='Client Abbreviation'
            fullWidth
            disabled
            value={client.clientAbbreviation || '--'}
            InputProps={{ disableUnderline: true }}
            InputLabelProps={labelProps}
          />
        </Grid>
      </Grid>
    </FadeIn>
  )
}

ClientInfoDisplay.propTypes = {
  client: PropTypes.shape({
    clientId: PropTypes.number,
    shortName: PropTypes.string,
    longName: PropTypes.string,
    description: PropTypes.string,
    clientAbbreviation: PropTypes.string
  })
}

export default ClientInfoDisplay
