import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Box } from '@material-ui/core'
import Text from '../../../atoms/Text'
import AdvisorHomeItem from './Item'

const AdvisorHomeLinkItem = ({ title, to, tooltipTitle }) => {
  return (
    <Link to={{ pathname: to }} target='_blank' rel='noreferrer'>
      <AdvisorHomeItem tooltipTitle={tooltipTitle}>
        <Box display='flex' flexDirection='column'>
          <Text
            text={title}
            customFontSize='0.875rem'
            customFontWeight='bold'
          />
          <Text text={to} color='#666' customFontSize='0.75rem' />
        </Box>
      </AdvisorHomeItem>
    </Link>
  )
}

AdvisorHomeLinkItem.propTypes = {
  title: PropTypes.string,
  to: PropTypes.string,
  tooltipTitle: PropTypes.string
}

AdvisorHomeLinkItem.defaultProps = {
  tooltipTitle: 'Open link'
}

export default AdvisorHomeLinkItem
