import ContextualClientDrawer from '../ClientCardsViewV2/components/ContextualClientDrawer'
import ContextualClientWrapper from './ContextualClientWrapper'
import ClientActions from './components/ClientActions'
import ClientCategory from './components/ClientCategory'
import ClientInfo from './components/ClientInfo'
import ClientIntegrationMapper from './components/ClientIntegrationMapper'
import ClientMeetings from './components/ClientMeetings'
import ClientTags from './components/ClientTags'
import ClientTasks from './components/ClientTasks'

const ContextualClientComponents = {
  CC_Wrapper: ContextualClientWrapper,
  CC_Drawer: ContextualClientDrawer,
  CC_ClientInfo: ClientInfo,
  CC_CategoryContainer: ClientCategory,
  CC_IntegrationMapper: ClientIntegrationMapper,
  CC_Actions: ClientActions,
  CC_Tags: ClientTags,
  CC_Meetings: ClientMeetings,
  CC_Tasks: ClientTasks
}

export const ContextualClient = {
  Wrapper: ContextualClientWrapper,
  Drawer: ContextualClientDrawer,
  Info: ClientInfo,
  Category: ClientCategory,
  IntegrationMapper: ClientIntegrationMapper,
  Actions: ClientActions,
  Tags: ClientTags
}

export default ContextualClientComponents
