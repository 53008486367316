import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles
} from '@material-ui/core'
import { CALC_TYPES, TEXT_VARIANTS } from '../../../../constants'
import { useAvailableDates } from '../../../../redux/slices/appContext'
import { numeralByCase } from '../../../../utils'
import Avatar from '../../../atoms/Avatar'
import NumberFormat from '../../../atoms/NumberFormat'
import TableSkeleton from '../../../atoms/TableSkeleton'
import Text from '../../../atoms/Text'
import { useClientBalances } from '../../../../api/coreData'

const useStyles = makeStyles(() => ({
  customSize: {
    fontSize: '1rem'
  }
}))

const AdvisorTopClientsPreview = ({ advisorId }) => {
  const [availableDates] = useAvailableDates()
  const classes = useStyles()

  const query = useMemo(() => ({
    take: 5,
    advisorIds: advisorId ? [advisorId] : undefined,
    startDate: availableDates.mainDate,
    endDate: availableDates.mainDate,
    calcType: CALC_TYPES.balance,
    orderBy: 'endingValue DESC'
  }), [advisorId, availableDates.mainDate])

  const {
    data: clients,
    isLoading: loading
  } = useClientBalances(query)

  const topClientItemsRendered = useMemo(() => {
    if (loading) {
      return (
        <TableSkeleton
          firstColumnWithAvatar
          showHeader={false}
          columns={1}
          rows={5}
        />
      )
    }

    const topClients = clients ? [...clients] : []
    topClients.sort((a, b) => a.endingValue > b.endingValue ? -1 : 1)

    return topClients.map((client) => {
      const avatarLetters = client.clientAbbreviation || client.shortName || ''
      const useAbbreviation = !!client.clientAbbreviation
      return (
        <ListItem key={client.clientId} style={{ padding: '0.5rem 0rem' }}>
          <ListItemAvatar>
            <Avatar
              avatarLetters={avatarLetters}
              src={client.profilePic}
              useAbbreviation={useAbbreviation}
              useOneInitial
            />
          </ListItemAvatar>
          <ListItemText>
            <Text
              text={client.shortName}
              variant={TEXT_VARIANTS.h3}
            />
          </ListItemText>
          <ListItemSecondaryAction className={classes.customSize}>
            <NumberFormat
              title={numeralByCase(client.endingValue, '0,0.0a', '0,0.0000a')}
              number={numeralByCase(client.endingValue, '0,0.0a', '0,0.0a')}
              skipFormat
            />
          </ListItemSecondaryAction>
        </ListItem>
      )
    })
  }, [loading, clients, classes])

  return (
    <Box height='100%' width='100%' style={{ padding: '1rem' }}>
      <Box mb='1.5rem'>
        <Text text='Top Clients' variant='body2' customFontSize='1.2rem' />
      </Box>
      <List dense>{topClientItemsRendered}</List>
    </Box>
  )
}

AdvisorTopClientsPreview.propTypes = {
  advisorId: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.number.isRequired
  ])
}

AdvisorTopClientsPreview.defaultProps = {
  advisorId: ''
}

export default AdvisorTopClientsPreview
