import ScopeDisplay from './ScopeDisplay'
import ScopePicker from './ScopePicker'
import Metrics from './Metrics'
import UpcomingMilestonesSummary from './UpcomingMilestones'
import UpcomingMeetings from './UpcomingMeetings'
import TopClients from './TopClients'
import TopAssets from './TopAssets'
import NetFlowsMetric from './Metrics/NetFlowsMetric'
import CashReportMetric from './Metrics/CashReportMetric'
import TransactionsMetric from './Metrics/TransactionsMetric'
import AssetExposureMetric from './Metrics/AssetExposureMetric'
import InlineDividedMetric from './Metrics/InlineDividedMetric'
import NewAccountsMetric from './Metrics/NewAccountsMetric'
import FeeSchedulesMetric from './Metrics/FeeSchedulesMetric'
import AccountStatusMetric from './Metrics/AccountStatusMetric'
import Metric from './Metrics/Metric'
import BookmarkedClients from './BookmarkedClients'
import ClientsRecentActivity from './ClientsRecentActivity'
import AdvisorHomeLink from './AdvisorHomeLink'
import AdvisorMessages from './AdvisorMessages'
import TaskMetricsGroup from './Metrics/TaskMetricsGroup'
import ClientMap from './ClientMap'
import TopDocuments from './TopDocuments'
import SalesforceTasksPreview from './TasksPreview/index'
import AdvisorHomeList from './AdvisorHomeList'
import AdvisorHomeCustomDatasetLayout from './AdvisorHomeCustomDatasetLayout'
import KeyDateCardItem from './KeyDates/KeyDateCardItem'
import KeyDateListItem from './KeyDates/KeyDateListItem'
import BookOfBusinessReport from './BobiReport'
import TagInsightsMetric from './Metrics/TagInsightsMetric'

export const AdvisorHomeComponents = {
  ScopeDisplay: ScopeDisplay,
  ScopePicker: ScopePicker,
  AH_SummaryMetrics: Metrics,
  AH_UpcomingMilestones: UpcomingMilestonesSummary,
  AH_UpcomingMeetings: UpcomingMeetings,
  AH_Link: AdvisorHomeLink,
  AH_Metric: Metric,
  AH_MetricContainer: Metric.Wrapper,
  AH_TopAssets: TopAssets,
  AH_TopClients: TopClients,
  AH_NetFlowsMetric: NetFlowsMetric,
  AH_CashReportMetric: CashReportMetric,
  AH_TransactionsMetric: TransactionsMetric,
  AH_AssetExposureMetric: AssetExposureMetric,
  AH_InlineMetricContainer: InlineDividedMetric,
  AH_NewAccountsMetric: NewAccountsMetric,
  AH_FeeSchedulesMetric: FeeSchedulesMetric,
  AH_AccountStatusMetric: AccountStatusMetric,
  AH_BookmarkedClientsList: BookmarkedClients,
  AH_ClientsRecentActivityList: ClientsRecentActivity,
  AH_Messages: AdvisorMessages,
  AH_TaskMetricsGroup: TaskMetricsGroup,
  AH_ClientMap: ClientMap,
  AH_TopDocuments: TopDocuments,
  AH_SalesforceTasksPreview: SalesforceTasksPreview,
  AH_List: AdvisorHomeList,
  AH_ListItem: AdvisorHomeList.Item,
  AH_ListLinkItem: AdvisorHomeList.LinkItem,
  AH_CustomDatasetLayout: AdvisorHomeCustomDatasetLayout,
  AH_CustomDatasetLayoutCard: AdvisorHomeCustomDatasetLayout.Card,
  AH_KeyDateCardItem: KeyDateCardItem,
  AH_KeyDateListItem: KeyDateListItem,
  AH_BobiReport: BookOfBusinessReport,
  AH_TagInsightsMetric: TagInsightsMetric
}
