import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import { useContextContainer } from '../../../../abundanceEngine/components/ContextContainer'
import { CLIENT_VIEW_CONTEXT } from '..'
import { VIEW_TYPES } from '../helpers'
import { MAIN_HEADER_HEIGHT } from '../../../../constants'

const useStyles = makeStyles(() => ({
  container: {
    overflowY: 'auto',
    maxHeight: `calc(100vh - ${MAIN_HEADER_HEIGHT}px)`
  },
  cardContainer: {
    height: '100%',
    width: '100%',
    display: 'flex',
    padding: '0 1.5rem 1rem 1.5rem',
    flexDirection: 'column'
  },
  listContainer: {
    height: '100%',
    width: '100%',
    padding: '0 1rem'
  }
}))

const ClientViewWrapper = ({ children }) => {
  const classes = useStyles()
  const [{ viewType }, setContextContainer] = useContextContainer(CLIENT_VIEW_CONTEXT)

  const onClientCardsContainerRefChange = useCallback((node) => {
    if (!node) return
    setContextContainer(prevState => ({
      ...prevState,
      clientsContainerRef: node
    }))
  }, [setContextContainer])

  return (
    <div
      className={clsx(classes.container, {
        [classes.listContainer]: viewType === VIEW_TYPES.LIST,
        [classes.cardContainer]: viewType === VIEW_TYPES.BLOCKS
      })}
      ref={onClientCardsContainerRefChange}
    >
      {children}
    </div>
  )
}

ClientViewWrapper.propTypes = {
  children: PropTypes.node.isRequired
}
export default ClientViewWrapper
