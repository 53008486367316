import React from 'react'
import { makeStyles } from '@material-ui/core'
import { useDashboardSettings } from '../../../../organisms/ClientDashboard/ClientDashboardContextProvider'
import RoundedButton from '../../../../atoms/RoundedButton'
import { useAbundanceEngineGalleryContext } from '../abundanceEngineGalleryContext'
import Logo from '../../../../organisms/ClientDashboard/components/Logo'
import AbundanceEngineGalleryHeader from '../header'
import PreviewLink from './previewLink'
import AbundanceEngineGalleryContainer from './container'
import AbundanceEngineGalleryFooter from './footer'

const useStyles = makeStyles(() => ({
  container: {
    zIndex: 0,
    minHeight: 0,
    display: 'flex',
    flexDirection: 'column'
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: '2.5rem',
    padding: '0 0 2.5rem 0',
    marginBottom: ({ presentationMode }) => presentationMode ? '13rem' : '6rem',
    minHeight: 0,
    justifyContent: 'center'
  },
  header: {
    padding: '2rem 2rem'
  },
  headerContent: {
    px: '4rem',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  logo: {
    padding: '2.5rem 0 0 0'
  },
  button: {
    marginLeft: 'auto',
    backgroundColor: '#73C496',
    '&:hover': {
      backgroundColor: '#4A986C'
    }
  }
}))

const List = () => {
  const {
    items,
    settings,
    highlightedView,
    changeHighlightedView
  } = useDashboardSettings()

  const {
    wheelEditMode,
    presentationMode,
    toggleWheelEditMode,
    selectedViews,
    setSelectedViews
  } = useAbundanceEngineGalleryContext()

  const classes = useStyles({ presentationMode })
  const { backgroundImage, logo: logoSettings } = settings

  const onGenerateWheel = () => {
    setSelectedViews([])
    toggleWheelEditMode.toggle()
  }

  const layoutHeader = presentationMode ? (
    <div className={classes.header}>
      <div className={classes.headerContent}>
        <Logo
          settings={{
            ...logoSettings,
            top: '1rem',
            left: '2rem',
            position: 'relative',
            isPublicResource: true
          }}
        />
        <RoundedButton
          size='extraSmall'
          className={classes.button}
          disabled={wheelEditMode}
          onClick={onGenerateWheel}
        >
          Generate Wheel
        </RoundedButton>
      </div>
    </div>
  ) : (
    <AbundanceEngineGalleryHeader />
  )

  const footer = wheelEditMode ? <AbundanceEngineGalleryFooter /> : null

  const onSelectView = (viewId) => {
    setSelectedViews(prevState => {
      const isViewSelected = prevState[viewId]
      return { ...prevState, [viewId]: !isViewSelected }
    })
  }

  return (
    <AbundanceEngineGalleryContainer
      backgroundImage={backgroundImage}
      className={classes.container}
      header={layoutHeader}
      footer={footer}
    >
      {!presentationMode && (
        <div className={classes.logo}>
          <Logo
            settings={{
              ...logoSettings,
              position: 'relative',
              isPublicResource: true
            }}
          />
        </div>
      )}
      <div className={classes.content}>
        {items.map((item) => {
          return (
            <PreviewLink
              key={item.view}
              onHoverChange={changeHighlightedView}
              lowlighted={highlightedView ? highlightedView !== item.view : false}
              selectable={wheelEditMode}
              selected={selectedViews[item.view]}
              onSelectItem={() => onSelectView(item.view)}
              {...item}
            />
          )
        })}
      </div>
    </AbundanceEngineGalleryContainer>
  )
}

export default List
