import React from 'react'
import FadeIn from '../../../../molecules/Transitions/FadeIn'
import ErrorBoundary from '../../../../molecules/ErrorBoundary'
import KdpSection from './KdpSection'

function KdpTab () {
  return (
    <ErrorBoundary name='Account KDP Tab'>
      <FadeIn>
        <KdpSection />
      </FadeIn>
    </ErrorBoundary>
  )
}

KdpTab.propTypes = {
}

export default KdpTab
