import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Box, Divider } from '@material-ui/core'
import { useDynamicPlanning } from '../DynamicPlanningContext'
import { useAssumptions } from './hooks'

const AssumptionSelection = ({ assumptions, showStepToggles }) => {
  const {
    currentPlan,
    clientBalance,
    setClientBalance,
    currentPlanDispatch
  } = useDynamicPlanning()

  const displayAssumptions = useAssumptions(
    currentPlan,
    clientBalance,
    setClientBalance,
    currentPlanDispatch,
    assumptions
  )

  return (
    <Box display='flex' justifyContent='space-between' alignItems='center'>
      {
        displayAssumptions.map(({ display: AssumptionDisplay, key, ...props }, index) => {
          return (
            <Fragment key={key}>
              <Box key={key} style={{ minWidth: '100px' }}>
                <AssumptionDisplay {...props} showStepToggles={showStepToggles} />
              </Box>
              {index < (displayAssumptions.length - 1) && <Divider orientation='vertical' flexItem />}
            </Fragment>
          )
        })
      }
    </Box>
  )
}

AssumptionSelection.propTypes = {
  assumptions: PropTypes.object,
  showStepToggles: PropTypes.bool
}

AssumptionSelection.defaultProps = {
  assumptions: {
    endingValue: {
      label: 'Starting Portfolio Value'
    },
    splitDate: {
      label: 'Retire Year'
    },
    portfolioGrowthModel: {
      label: 'Portfolio Growth'
    },
    inflationModel: {
      label: 'Inflation'
    }
  },
  showStepToggles: true
}

export default AssumptionSelection
