import React, { useMemo } from 'react'
import { makeStyles } from '@material-ui/core'
import ViewTabs from '../../../organisms/AdvisorView/components/AdvisorViewTabs/index'
import { usePolicies } from '../../../../hooks'
import HeaderActions from './HeaderActions'
import AccountsListTab from './AccountsListTab'
import ManagersListTab from './ManagersListTab'

const tabOptions = {
  accounts: {
    label: 'Accounts',
    value: 'accounts'
  },
  managers: {
    label: 'Managers',
    value: 'managers'
  }
}

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '1rem'
  }
}))

const useTabOptions = () => {
  const policies = usePolicies()
  return useMemo(() => {
    const result = {}
    if (policies.admin_view_accts) {
      result.accounts = tabOptions.accounts
    }
    if (policies.admin_view_managers) {
      result.managers = tabOptions.managers
    }
    return result
  }, [policies])
}

function AccountsView () {
  const classes = useStyles()
  const displayTabOptions = useTabOptions()

  return (
    <div className={classes.container}>
      <ViewTabs.Group tabOptions={displayTabOptions}>
        <ViewTabs.Links
          tabsKey='admin_accounts_list'
          rightAdornment={<HeaderActions />}
        />
        <ViewTabs.Tab value='accounts'>
          <AccountsListTab />
        </ViewTabs.Tab>
        <ViewTabs.Tab value='managers'>
          <ManagersListTab />
        </ViewTabs.Tab>
      </ViewTabs.Group>
    </div>
  )
}

AccountsView.propTypes = {
}

AccountsView.defaultProps = {
}

export default AccountsView
