import React, { useCallback, useMemo } from 'react'
import { makeStyles } from '@material-ui/core'
import { useHistory, useParams } from 'react-router-dom'
import EmptySection from '../../../atoms/EmptySection'
import { ADMIN_ROUTES } from '../../../../constants'
import { usePolicy } from '../../../../hooks/usePolicy'
import { useDeactivateAdvisorMutation, useSearchAdvisorUsers } from '../../../../api/users'
import AdvisorUserHeader from './Header'
import { AdvisorUserDetailsProvider } from './AdvisorUserFormContext'
import AdvisorUserDetailTabs from './AdvisorUserDetailTabs'

const useStyles = makeStyles({
  detailsPage: {
    margin: '1rem'
  }
})

const useExternalUserId = () => {
  const { externalUserId } = useParams()
  return !externalUserId ? '-1' : externalUserId
}

const useDeleteUser = (user) => {
  const { mutateAsync: deleteUser, isLoading: isDeleting } = useDeactivateAdvisorMutation()
  const history = useHistory()
  const handleDelete = useCallback(async () => {
    await deleteUser(user)
    history.push(`${ADMIN_ROUTES.ADVISORS}?tab=users`)
  }, [user, deleteUser, history])

  return { handleDelete, isDeleting }
}

const useAdvisor = () => {
  const externalUserId = useExternalUserId()
  const query = useMemo(() => ({
    filters: {
      externalUserId
    },
    includes: {
      role: true,
      avatars: true
    }
  }), [externalUserId])
  const { data, isLoading, refetch } = useSearchAdvisorUsers(query)

  const advisorUser = useMemo(() => {
    return data?.length ? data[0] : null
  }, [data])

  const { handleDelete, isDeleting } = useDeleteUser(advisorUser)

  return {
    advisorUser,
    isLoading,
    refetch,
    handleDelete,
    isDeleting
  }
}

function AdvisorUserDetails () {
  const classes = useStyles()
  const canView = usePolicy('admin_view_advisor_users')
  const { advisorUser, isLoading, refetch, handleDelete, isDeleting } = useAdvisor()

  if (!canView) {
    return (
      <EmptySection
        title='Access Denied'
        description='You do not have sufficient permissions to access this resource'
      />
    )
  }

  if (isLoading) {
    return null
  }

  if (!advisorUser) {
    return (
      <EmptySection
        title='Advisor User Not Found'
        description=''
      />
    )
  }

  if (isDeleting) {
    return (
      <EmptySection
        title='Deactivating Advisor'
        description='Deactivation in progress'
      />
    )
  }

  return (
    <div className={classes.detailsPage}>
      <AdvisorUserDetailsProvider
        advisorUser={advisorUser}
        refetch={refetch}
        deleteAdvisorUser={handleDelete}
      >
        <AdvisorUserHeader />
        <AdvisorUserDetailTabs />
      </AdvisorUserDetailsProvider>
    </div>
  )
}

AdvisorUserDetails.propTypes = {
}

export default AdvisorUserDetails
