import React, { useCallback, useMemo } from 'react'
import { isFunction } from 'lodash'
import { useContextContainer } from '../../../../abundanceEngine/components/ContextContainer'
import { CLIENT_VIEW_CONTEXT } from '..'
import { useGroupTypeSearch } from '../../../../api/groups'
import { getGroupTypesQuery } from '../../../pages/admin/Accounts/AccountsListTab/useGroupTypeColumns'
import GroupTagFilters from '../../../molecules/GroupTagFilters'
import { CLIENTS_MEMBER_LEVEL_ID } from '../../../../constants'

const ClientGroupTagFilters = () => {
  const [
    { clientTagFilters },
    setClientViewContext
  ] = useContextContainer(CLIENT_VIEW_CONTEXT)

  const { query, queryOptions } = useMemo(
    () =>
      getGroupTypesQuery({
        filters: {
          memberLevelTypeIds: [CLIENTS_MEMBER_LEVEL_ID]
        }
      }),
    []
  )

  const { data: groupTypes, isLoading } = useGroupTypeSearch(
    query,
    queryOptions
  )

  const onChangeGroupTagFilters = useCallback((tagFilters) => {
    setClientViewContext(prevState => {
      const clientTagFilters = isFunction(tagFilters)
        ? tagFilters(prevState?.clientTagFilters ?? [])
        : tagFilters

      return {
        ...prevState,
        clientTagFilters
      }
    })
  }, [setClientViewContext])

  return (
    <GroupTagFilters
      groupTypes={groupTypes}
      isLoading={isLoading}
      tagFilters={clientTagFilters}
      setTagFilters={onChangeGroupTagFilters}
    />
  )
}

export default ClientGroupTagFilters
