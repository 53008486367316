import { useCallback, useEffect, useMemo } from 'react'
import { useBoolean } from '../../../../hooks'
import { FEATURE_FLAG, REDTAIL_INTEGRATION } from '../../../../constants'
import { integrations as IntegrationsUtilService } from '../../../../service'
import { useRedtailSyncStatus } from '../../../../api/redtail'
import { useFeatureFlag } from '../../../../redux/slices/appConfig'

const useRedTailDataSync = (clientId, refetchIntervalInMilliseconds = 3000) => {
  const [syncInProgress, setSyncInProgress] = useBoolean(true)
  const { active: REDTAIL_UPDATE_CLIENT_ACCOUNTS } = useFeatureFlag(
    FEATURE_FLAG.REDTAIL_UPDATE_CLIENT_ACCOUNTS
  )

  const syncRedTailAccounts = useCallback(async () => {
    if (!clientId) return
    setSyncInProgress.on()
    try {
      await IntegrationsUtilService[REDTAIL_INTEGRATION].uploadAccountAssets(
        clientId
      )
    } catch (err) {
      console.error(err)
      setSyncInProgress.off()
    }
  }, [clientId, setSyncInProgress])

  const queryOptions = useMemo(
    () => ({
      enabled: Boolean(syncInProgress && clientId),
      refetchInterval: syncInProgress
        ? refetchIntervalInMilliseconds
        : undefined
    }),
    [clientId, syncInProgress, refetchIntervalInMilliseconds]
  )

  const { data, error } = useRedtailSyncStatus(clientId, queryOptions)

  useEffect(() => {
    const { updateInProgress } = data || {}
    if (!updateInProgress || error) {
      setSyncInProgress.off()
    }
  }, [data, error, setSyncInProgress])

  return {
    isSyncFeatureActive: REDTAIL_UPDATE_CLIENT_ACCOUNTS,
    syncStatus: data,
    isSyncInProgress: syncInProgress,
    syncRedTailAccounts
  }
}

export default useRedTailDataSync
