/* eslint-disable */
import { useMemo } from 'react'
import { FEE_OVERRIDE_LEVEL_VALUES } from './LevelPicker/helpers'
import { useSearchAccounts } from '../../../../../api/accounts'
import { useClassificationTags } from '../../../../../api/coreData'
import { useSearchPositions } from '../../../../../api/positions'

/**
 * Hook for getting fee override assignment names
 */
export const useOverrideAssignments = (client, billingInfo, isLoading) => {
  const {
    accounts,
    positions,
    assetClasses
  } = useMemo(() => {
    if (isLoading) {
      const disabled = { query: {}, enabled: false }
      return { accounts: disabled, positions: disabled, assetClasses: disabled }
    }

    const accounts = billingInfo.client.feeOverrides.filter(o => o.overrideLevelType === FEE_OVERRIDE_LEVEL_VALUES.account)
    const positions = billingInfo.client.feeOverrides.filter(o => o.overrideLevelType === FEE_OVERRIDE_LEVEL_VALUES.position)
    const assetClasses = billingInfo.client.feeOverrides.filter(o => o.overrideLevelType === FEE_OVERRIDE_LEVEL_VALUES.class)

    return {
      accounts: {
        assignments: accounts,
        enabled: accounts.length > 0,
        query: {
          filters: {
            accountId: accounts.map(x => x.overrideLevelId)
          },
          take: accounts.length
        }
      },
      positions: {
        assignments: positions,
        enabled: positions.length > 0,
        query: {
          levelFilters: {
            levelType: 'client',
            levelId: client.clientId,
            take: positions.length,
            filters: {
              positionId: { op: 'in', value: positions.map(x => x.overrideLevelId) }
            }
          }
        }
      },
      assetClasses: {
        assignments: assetClasses,
        enabled: assetClasses.length > 0,
        query: {
          filters: {
            classificationTagTypeId: 51,
            classificationTagId: assetClasses.map(x => x.overrideLevelId)
          },
          take: assetClasses.length
        }
      }
    }
  }, [client, billingInfo, isLoading])

  const accountSearch = useSearchAccounts(accounts.query, {
    enabled: accounts?.enabled || false
  })
  const assetClassSearch = useClassificationTags(assetClasses.query, {
    enabled: assetClasses?.enabled || false
  })
  const positionSearch = useSearchPositions(positions.query, {
    enabled: positions?.enabled || false
  })

  const merged = useMemo(() => {
    const data = []
    if (isLoading) {
      return {
        data,
        isLoading: true
      }
    }

    const accountsLoading = accounts.enabled && accountSearch.isLoading
    const positionsLoading = positions.enabled && positionSearch.isLoading
    const classesLoading = assetClasses.enabled && assetClassSearch.isLoading
    if (accountsLoading || positionsLoading || classesLoading) {
      return {
        data,
        isLoading: true
      }
    }

    accounts.assignments.forEach(acct => {
      const account = accountSearch.data.data.find(x => x.accountId === acct.overrideLevelId)
      data.push({
        ...acct,
        label: account?.accountName,
        value: account?.accountId
      })
    })

    positions.assignments.forEach(pos => {
      const position = positionSearch.data.find(x => x.positionId === pos.overrideLevelId)
      data.push({
        ...pos,
        label: position?.assetIdentifier,
        value: position?.positionId
      })
    })

    assetClasses.assignments.forEach(ac => {
      const assetClass = assetClassSearch.data.find(x => x.classificationTagId === ac.overrideLevelId)
      data.push({
        ...ac,
        label: assetClass?.longName,
        value: assetClass?.classificationTagId
      })
    })

    return {
      data,
      isLoading: false
    }
  }, [
    isLoading,
    accountSearch.isLoading,
    accountSearch.data,
    accounts.enabled,
    accounts.assignments,
    positionSearch.isLoading,
    positionSearch.data,
    positions.enabled,
    positions.assignments,
    assetClassSearch.isLoading,
    assetClassSearch.data,
    assetClasses.enabled,
    assetClasses.assignments
  ])

  return merged
}
