import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState
} from 'react'
import PropTypes from 'prop-types'
import { isEmpty, noop } from 'lodash'
import { useBoolean } from '../../../hooks'
import SelectFilterPopper from './SelectFilterPopper'
import SelectFilterButton from './SelectFilterButton'
import { SELECT_FILTER_VARIANTS } from './helpers'

const SelectFilterContext = createContext({})

export const useSelectFilterContext = () => {
  const contextValue = useContext(SelectFilterContext)
  return contextValue
}

const SelectFilterProvider = ({
  disabled,
  isLoading,
  placeholder,
  children,
  onChange,
  variant
}) => {
  const anchorRef = useRef(null)
  const [open, toggleOpen] = useBoolean()
  const [displayLabel, setDisplayLabel] = useState()
  const [filterMeta, setFilterMeta] = useState()

  const handleToggle = () => {
    if (!disabled) {
      toggleOpen.toggle()
    }
  }

  const onClearFilters = useCallback(() => {
    setDisplayLabel('')
    setFilterMeta(undefined)
    onChange(undefined)
    toggleOpen.off()
  }, [onChange, toggleOpen])

  const onApplyFilters = useCallback((filters) => {
    // eslint-disable-next-line no-debugger
    // debugger
    if (!isEmpty(filters)) {
      const { displayLabel, ...payload } = filters
      setDisplayLabel(displayLabel)
      onChange({ ...payload, variant })
      setFilterMeta({ variant, ...filters })
    }
    toggleOpen.off({ ...filters })
  }, [onChange, toggleOpen, variant])

  const handleClose = useCallback(
    (event) => {
      if (anchorRef.current && anchorRef.current.contains(event?.target)) {
        return
      }
      toggleOpen.off()
    },
    [toggleOpen]
  )

  const selectFilterContextValue = useMemo(() => {
    return {
      handleCloseFilter: handleClose,
      placeholder,
      displayLabel,
      setFilterMeta,
      onApplyFilters,
      onClearFilters,
      setDisplayLabel
    }
  }, [handleClose, placeholder, displayLabel, onClearFilters, onApplyFilters])

  return (
    <div>
      <SelectFilterButton
        ref={anchorRef}
        open={open}
        label={displayLabel}
        disabled={disabled}
        isLoading={isLoading}
        placeholder={placeholder}
        handleToggle={handleToggle}
        onClearFilters={onClearFilters}
        showClearButton={!isEmpty(filterMeta)}
      />
      <SelectFilterPopper
        open={open}
        handleClose={handleClose}
        anchorEl={anchorRef.current}
      >
        <SelectFilterContext.Provider value={selectFilterContextValue}>
          {children}
        </SelectFilterContext.Provider>
      </SelectFilterPopper>
    </div>
  )
}

SelectFilterProvider.propTypes = {
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  placeholder: PropTypes.string,
  children: PropTypes.node,
  onChange: PropTypes.func,
  variant: PropTypes.oneOfType([
    PropTypes.oneOf(Object.values(SELECT_FILTER_VARIANTS)),
    PropTypes.shape({
      type: PropTypes.oneOf(Object.values(SELECT_FILTER_VARIANTS)),
      valueKey: PropTypes.string,
      options: PropTypes.array
    })

  ])
}

SelectFilterProvider.defaultProps = {
  placeholder: '',
  onChange: noop
}

export default SelectFilterProvider
