import { useGetTags } from '../../../../../api/reports'

export const useGetTagsData = () => {
  const {
    data,
    isLoading,
    isPreviousData,
    error,
    refetch
  } = useGetTags()

  return {
    data: isLoading ? [] : data?.data,
    loading: isLoading || isPreviousData,
    error,
    refetch
  }
}
