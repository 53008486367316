import React, { useCallback, useMemo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory, useParams } from 'react-router-dom'
import { Button } from '@material-ui/core'
import { useIngestionPublishMutation, useIngestionTargetById } from '../../../../api/ingestion'
import ItemsToBeCreated from './ItemsToBeCreated'
import ItemsToBeUpdated from './ItemsToBeUpdated'
import PreviewErrorBoundary from './PreviewErrorBoundary'

const useStyles = makeStyles((theme) => ({
  targetDetailsIndex: {
    padding: '1rem',
    '& > header': {
      borderBottom: '1px solid black',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between'
    },
    '& .__description': {
      padding: '.5rem 0',
      color: theme.palette.darkGrey
    }
  },
  actions: {
    '& > *': {
      marginLeft: '1rem'
    }
  }
}))

const PublishPreviewIndex = () => {
  const classes = useStyles()
  const { targetId, policyId } = useParams()
  const { data: target, isLoading } = useIngestionTargetById(targetId)
  const headerText = useMemo(() => {
    if (isLoading) return 'Publish Preview'
    return `Publish Preview: ${target.name}`
  }, [target, isLoading])
  const history = useHistory()
  const handleCancel = useCallback((e) => {
    e.preventDefault()
    e.stopPropagation()

    history.push(`/data-home/targets/${targetId}`)
  }, [targetId, history])
  const { mutateAsync, isLoading: isSubmitting } = useIngestionPublishMutation()
  const handlePublish = useCallback(async (e) => {
    e.preventDefault()
    e.stopPropagation()

    const result = await mutateAsync({
      ingestionTargetId: targetId,
      publishingPolicyId: policyId
    })

    console.debug(result, 'publishing result')
    history.push(`/data-home/publishing/results/${result.run.publishingRunId}`)
  }, [targetId, policyId, mutateAsync, history])

  return (
    <div className={classes.targetDetailsIndex}>
      <header>
        <h2>
          <span>{headerText}</span>
        </h2>
        <div className={classes.actions}>
          <Button color='primary' variant='contained' disabled={isSubmitting} onClick={handlePublish}>Publish</Button>
          <Button variant='contained' disabled={isSubmitting} onClick={handleCancel}>Cancel</Button>
        </div>
      </header>
      <main>
        {isSubmitting && (<h2>Publishing...</h2>)}
        <PreviewErrorBoundary errorMsg='Error showing create preview'>
          <ItemsToBeCreated policyId={+policyId} targetId={+targetId} />
        </PreviewErrorBoundary>
        <PreviewErrorBoundary errorMsg='Error showing update preview'>
          <ItemsToBeUpdated policyId={+policyId} targetId={+targetId} />
        </PreviewErrorBoundary>
      </main>
    </div>
  )
}

export default PublishPreviewIndex
