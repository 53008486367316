import { useQuery } from '@tanstack/react-query'
import { useAppContext } from '../redux/slices/appContext'
import { postPoliciesCheck } from '../service'
import { QUERY_KEYS } from './queryKeys'

export const useCheckPolicies = () => {
  const { userId } = useAppContext()
  return useQuery({
    queryKey: [QUERY_KEYS.policies, userId],
    queryFn: async () => {
      if (!userId) {
        throw new Error('no userId')
      }
      try {
        const { data = {} } = await postPoliciesCheck()
        return data.data
      } catch (err) {
        return {}
      }
    },
    enabled: !!userId
  })
}
