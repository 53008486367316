import React, { forwardRef, useCallback, useImperativeHandle, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Tooltip, Grid, makeStyles, CircularProgress } from '@material-ui/core'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import { useSearchAccountsMultiple } from '../../../../../api/accounts'
import SydInput from '../../../../commonDesign/SydInput'
import SydButton from '../../../../commonDesign/Button'
import Card from '../../../../molecules/Card'

const useStyles = makeStyles((theme) => ({
  table: {
    tableLayout: 'auto',
    '& th': {
      textAlign: 'left',
      padding: theme.layout.padding.medium
    },
    '& tbody td': {
      fontWeight: theme.typography.weights.light,
      padding: `0 ${theme.layout.margin.medium}`
    }
  },
  form: {
    marginTop: theme.layout.padding.thick,
    display: 'inline-block'
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.layout.gap.medium
  },
  ordinal: {
    maxWidth: '100px',
    minWidth: '100px'
  }
}))

const useClientAccounts = (client) => {
  const query = useMemo(() => {
    return {
      assignedAccounts: {
        includes: {
          ordinal: {
            groupLevelTypeId: 201,
            groupId: client.clientId
          }
        },
        filters: {
          assignedToClientIds: [client.clientId]
        },
        take: 1000
      }
    }
  }, [client.clientId])

  const { data: searchResults, isLoading: searchResultsLoading, error } = useSearchAccountsMultiple(query)

  const { accounts } = useMemo(() => {
    if (searchResultsLoading) {
      return {
        accounts: []
      }
    }

    const sorted = [...searchResults.assignedAccounts.data].sort((a, b) => a.ordinal - b.ordinal)
    return {
      accounts: sorted
    }
  }, [searchResults, searchResultsLoading])

  return {
    accounts,
    isLoading: searchResultsLoading,
    error
  }
}

const FormBody = forwardRef(function FormBody ({ accounts }, ref) {
  const classes = useStyles()
  const form = useForm({
    mode: 'onChange',
    defaultValues: {
      accounts
    }
  })

  const { fields: accountFields, move, replace } = useFieldArray({
    control: form.control,
    name: 'accounts'
  })

  const itemFns = useCallback((index, length) => {
    return {
      down: () => {
        const next = index + 1 >= length ? 0 : index + 1
        return move(index, next)
      },
      up: () => {
        const next = index - 1 < 0 ? length - 1 : index - 1
        return move(index, next)
      },
      top: () => move(index, 0),
      bottom: () => move(index, length - 1)
    }
  }, [move])

  const reIndex = useCallback(() => {
    const replacement = accountFields.map((a, i) => ({
      ...a,
      ordinal: i + 1
    }))
    replace(replacement)
  }, [accountFields, replace])

  const accountValues = form.watch('accounts')
  const sortByOrdinal = useCallback(() => {
    const replacement = [...accountValues].sort((a, b) => a.ordinal - b.ordinal)
    replace(replacement)
  }, [accountValues, replace])
  const sortByName = useCallback(() => {
    const replacement = [...accountValues].sort((a, b) => a.accountName.localeCompare(b.accountName))
    replace(replacement)
  }, [accountValues, replace])

  const getAccounts = useCallback(() => {
    return accountValues.map(a => ({
      accountId: a.accountId,
      ordinal: a.ordinal
    }))
  }, [accountValues])

  useImperativeHandle(ref, () => ({
    getAccounts
  }), [getAccounts])

  return (
    <div className={classes.form}>
      <Card className={classes.actions}>
        <Tooltip title='Sets the ordinal value to the index displayed on the row'>
          <span>
            <SydButton size='sm' onClick={reIndex}>Index &rarr; Ordinal</SydButton>
          </span>
        </Tooltip>
        <Tooltip title='Sorts the list by the assigned ordinals'>
          <span>
            <SydButton size='sm' onClick={sortByOrdinal}>Sort By Ordinal</SydButton>
          </span>
        </Tooltip>
        <Tooltip title='Sorts the list by the account name'>
          <span>
            <SydButton size='sm' onClick={sortByName}>Sort By Name</SydButton>
          </span>
        </Tooltip>
      </Card>
      <table className={classes.table}>
        <thead>
          <tr>
            <th>Index</th>
            <th>Ordinal</th>
            <th>Move</th>
            <th>Account</th>
          </tr>
        </thead>
        <tbody>
          {accountFields.map((a, i) => {
            const fns = itemFns(i, accountFields.length)
            return (
              <tr key={a.id}>
                <td className='__index'>{i + 1}</td>
                <td className='__input'>
                  <Controller
                    name={`accounts.${i}.ordinal`} control={form.control} render={({ field }) => (
                      <SydInput className={classes.ordinal} size='sm' {...field} type='number' />
                    )}
                  />
                </td>
                <td className='__action'>
                  <div className={classes.actions}>
                    <SydButton size='xs' onClick={fns.up}>Up</SydButton>
                    <SydButton size='xs' onClick={fns.down}>Down</SydButton>
                    <SydButton size='xs' onClick={fns.top}>Top</SydButton>
                    <SydButton size='xs' onClick={fns.bottom}>Bottom</SydButton>
                  </div>
                </td>
                <td>{a.accountName}</td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
})

FormBody.propTypes = {
  accounts: PropTypes.array
}

function OrdinalForm ({ client, formRef }) {
  const { accounts, isLoading, error } = useClientAccounts(client)

  if (isLoading) {
    return <CircularProgress />
  }

  if (error) {
    return <div>{error}</div>
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <FormBody ref={formRef} accounts={accounts} />
      </Grid>
    </Grid>
  )
}

OrdinalForm.propTypes = {
  client: PropTypes.object,
  formRef: PropTypes.any
}

export default OrdinalForm
