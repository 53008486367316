import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Controller, useWatch } from 'react-hook-form'
import { useClientDetails } from '../../ClientDetailsFormContext'
import FeeOverrideLevelSelect from './FeeOverrideLevelSelect'
import {
  FEE_LEVEL_OVERRIDE_OPTIONS, FEE_OVERRIDE_LEVEL_VALUES, findOverrideOption, mapAndValidateLevelOverrideOptions
} from './helpers'

const useQueryOptions = (assigned, levelType) => {
  const { client } = useClientDetails()
  return useMemo(() => {
    const assetClassOption = findOverrideOption(FEE_OVERRIDE_LEVEL_VALUES.class)
    const positionOption = findOverrideOption(FEE_OVERRIDE_LEVEL_VALUES.position)
    const accountOption = findOverrideOption(FEE_OVERRIDE_LEVEL_VALUES.account)
    const options = FEE_LEVEL_OVERRIDE_OPTIONS.filter(({ value }) => value !== accountOption.value && value !== positionOption.value && value !== assetClassOption.value)
    const defaultClassIds = assigned.filter(x => x.overrideLevelType === FEE_OVERRIDE_LEVEL_VALUES.class).map(x => x.value)
    const defaultAccountIds = assigned.filter(x => x.overrideLevelType === FEE_OVERRIDE_LEVEL_VALUES.account).map(x => x.value)
    const defaultPositionId = assigned.filter(x => x.overrideLevelType === FEE_OVERRIDE_LEVEL_VALUES.position).map(x => x.value)
    const schema = [
      ...options,
      {
        ...assetClassOption,
        rules: {
          ...assetClassOption.rules,
          searchQueryOptions: {
            defaultClassificationTagIds: defaultClassIds,
            query: { filters: { classificationTagTypeId: 51 } }
          }
        }
      },
      {
        ...accountOption,
        rules: {
          ...accountOption.rules,
          searchQueryOptions: {
            assignedToClientIds: [client.clientId],
            defaultAccountIds,
            query: {
              filters: { assignedToClientIds: [client.clientId] }
            }
          }
        }
      },
      {
        ...positionOption,
        rules: {
          ...positionOption.rules,
          searchQueryOptions: {
            queryOptions: {
              levelType: 'client',
              levelId: client.clientId
            },
            defaultPositionId
          }
        }
      }
    ]

    const validated = mapAndValidateLevelOverrideOptions(assigned, schema)
    return validated.find(x => x.value === levelType)
  }, [client.clientId, assigned, levelType])
}

function LevelPicker ({
  name,
  readOnly,
  assigned
}) {
  const levelType = useWatch({ name: `${name}.overrideLevelType` })
  const levelId = useWatch({ name: `${name}.overrideLevelId` })
  const assignedValue = useMemo(() => {
    return assigned.find(x => x.overrideLevelType === levelType && x.value === levelId)
  }, [assigned, levelType, levelId])

  const queryOptions = useQueryOptions(assigned, levelType)

  if (!levelType) return null

  return (
    <>
      <Controller
        name={`${name}.overrideLevelId`}
        rules={{ required: true }}
        render={({ field, fieldState }) => (
          <FeeOverrideLevelSelect
            levelType={levelType}
            levelValue={field.value}
            onChangeLevel={field.onChange}
            searchQueryOptions={queryOptions.rules.searchQueryOptions}
            readOnly={readOnly}
            assigned={assignedValue}
            error={(fieldState.isTouched || fieldState.isDirty) && fieldState.error}
          />
        )}
      />
    </>
  )
}

LevelPicker.propTypes = {
  name: PropTypes.string,
  readOnly: PropTypes.bool,
  assigned: PropTypes.arrayOf(PropTypes.shape({
    levelType: PropTypes.string,
    feeScheduleOverrideId: PropTypes.number,
    levelId: PropTypes.number,
    levelName: PropTypes.string
  }))
}

LevelPicker.defaultProps = {
  readOnly: false
}

export default LevelPicker
