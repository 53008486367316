import React from 'react'
import PropTypes from 'prop-types'
import { useTooltipEvents } from './useTooltipEvents'

// eslint-disable-next-line react/prop-types
function FloatingTargetMarker ({ bar, tooltip, xScale, accessor, markerWidth, compareTo }) {
  const { handleMouseEnter, handleMouseMove, handleMouseLeave } = useTooltipEvents(bar, tooltip)
  const value = bar.data.data[accessor] || 0

  // eslint-disable-next-line react/prop-types
  const purpose = compareTo?.at(0)
  const highBound = purpose ? bar.data.data[`${purpose}TBHigh`] : null
  const lowBound = purpose ? bar.data.data[`${purpose}TBLow`] ?? 0 : null
  const tolerance = highBound || lowBound

  return (
    <g>
      {tolerance && (value > 0) ? (
        <rect
          className='__floating-tolerance-marker'
          x={xScale(lowBound) - (markerWidth / 2)}
          width={markerWidth}
          y={bar.y - 5}
          height={bar.height + 10}
          onMouseEnter={handleMouseEnter}
          onMouseMove={handleMouseMove}
          onMouseLeave={handleMouseLeave}
        />
      ) : null}
      {tolerance && highBound ? (
        <rect
          className='__floating-tolerance-marker'
          x={xScale(highBound) - (markerWidth / 2)}
          width={markerWidth}
          y={bar.y - 5}
          height={bar.height + 10}
          onMouseEnter={handleMouseEnter}
          onMouseMove={handleMouseMove}
          onMouseLeave={handleMouseLeave}
        />
      ) : null}
      <rect
        className='__floating-target-marker'
        x={xScale(value) - (markerWidth / 2)}
        width={markerWidth}
        y={bar.y - 6}
        height={bar.height + 12}
        onMouseEnter={handleMouseEnter}
        onMouseMove={handleMouseMove}
        onMouseLeave={handleMouseLeave}
      />
    </g>
  )
}

FloatingTargetMarker.propTypes = {
  bar: PropTypes.any,
  markerWidth: PropTypes.number
}

FloatingTargetMarker.defaultProps = {
  markerWidth: 10
}

function FloatingTargetMarkerLayer ({ bars, xScale, tooltip, accessor, compareTo }) {
  return (
    <>
      {bars.map((b) => (
        <FloatingTargetMarker
          key={b.key}
          bar={b}
          tooltip={tooltip}
          accessor={accessor}
          xScale={xScale}
          compareTo={compareTo}
        />
      ))}
    </>
  )
}

FloatingTargetMarkerLayer.propTypes = {
  bars: PropTypes.array,
  tooltip: PropTypes.any,
  xScale: PropTypes.func,
  accessor: PropTypes.string,
  compareTo: PropTypes.array
}

export default FloatingTargetMarkerLayer
