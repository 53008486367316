import React, { useCallback } from 'react'
import { IconButton } from '@material-ui/core'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import Icon from '../../atoms/Icon'
import { ICON_NAMES } from '../../../constants'
import {
  setEditingAccountId,
  setStepNavigation,
  setToggleIsOpen,
  useSelectIsAccountIdEditable
} from '../../../redux/slices/addAccountContext'

const EditAccountButton = ({ accountId }) => {
  const dispatch = useDispatch()
  const selectIsAccountIdEditable = useSelectIsAccountIdEditable()
  const isEditable = useSelector(state => selectIsAccountIdEditable(state, accountId))

  const onClick = useCallback((e) => {
    e.preventDefault()
    e.stopPropagation()

    dispatch(setEditingAccountId({ accountId }))
    dispatch(setStepNavigation({ key: 'manual_updateAccount' }))
    dispatch(setToggleIsOpen({ isOpen: true }))
  }, [accountId, dispatch])

  if (!isEditable) {
    return null
  }

  return (
    <IconButton
      size='small'
      onClick={onClick}
      style={{
        position: 'absolute',
        left: 0,
        top: '50%',
        margin: '-13px 0 0 -28px',
        padding: '6px'
      }}
    >
      <Icon name={ICON_NAMES.edit} />
    </IconButton>
  )
}

EditAccountButton.propTypes = {
  accountId: PropTypes.number.isRequired
}

export default EditAccountButton
