import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import Bookmark from '../../../../atoms/Bookmark'
import useClientBookmarks from '../../hooks/useClientBookmarks'

const useStyles = makeStyles(() => ({
  cell: {
    cursor: 'pointer',
    textAlign: 'center'
  }
}))

const ClientBookmarkCell = ({ row: { original: client } }) => {
  const classes = useStyles()

  const { clientBookmarked, persistClientBookmark } =
    useClientBookmarks({
      clientId: client.clientId
    })

  return (
    <div className={classes.cell}>
      <Bookmark
        name={client.clientId}
        value={clientBookmarked}
        onChange={(event, value) => {
          event.stopPropagation()
          persistClientBookmark(value)
        }}
      />
    </div>
  )
}

ClientBookmarkCell.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      clientId: PropTypes.number
    })
  })
}

export default ClientBookmarkCell
