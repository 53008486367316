import isArray from 'lodash/isArray'
import get from 'lodash/get'

const resolveHeaderValue = (column) => {
  const headerValueKeys = ['header', 'Header']
  const valueKey = headerValueKeys.find(valueKey => get(column, valueKey, undefined))
  return column[valueKey] || ''
}

const getColumnsFlattened = (column, columns = []) =>
  column.parent
    ? getColumnsFlattened(column.parent, [...columns, column])
    : [...columns, column]

const mapHeaders = (columns, columnFormatter, includeAllColumns) => {
  const columnsFlattened = columns.reduce(
    (acc, col) => [...acc, ...getColumnsFlattened(col)],
    []
  )
  const columnsFormatted = columnsFlattened.reduce((acc, column) => {
    let value = resolveHeaderValue(column)
    value = columnFormatter ? columnFormatter(value, column) : value

    if (column.totalVisibleHeaderCount === 0 && !includeAllColumns) {
      return acc
    }

    const totalVisibleHeaderCount = column?.totalVisibleHeaderCount
      ? column.totalVisibleHeaderCount - 1
      : 0

    const headerLabel =
      (acc[column.depth] || []).includes(value) && column.depth === 0
        ? ''
        : value

    const rows = [
      ...(acc[column.depth] || []),
      headerLabel,
      ...Array(totalVisibleHeaderCount).fill(' ')
    ]
    acc[column.depth] = rows
    return acc
  }, [])

  return columnsFormatted
}

const invalidCell = [undefined, null, NaN]

const mapCellArray = (cellValues = []) => {
  return cellValues
    .map(({ displayName, shortName, name }) => displayName || shortName || name)
    .join(', ')
}

const mapRows = (rows, formatter, includeAllColumns) => {
  return rows.reduce((acc, row) => {
    const cells = (row.allCells || []).reduce((acc, cell) => {
      if (cell?.column?.totalVisibleHeaderCount === 0 && !includeAllColumns) {
        return acc
      }

      if (isArray(cell?.value)) {
        const value = mapCellArray(cell?.value)
        acc.push(value || cell?.column?.defaultValue || '')
      } else {
        let value = formatter ? formatter(cell) : cell?.value
        value = invalidCell.includes(value) ? '--' : value
        acc.push(value)
      }
      return acc
    }, [])
    acc.push(cells)
    return acc
  }, [])
}

export const mapTableDataToCSV = (
  performanceTableProps,
  formatter,
  columnFormatter,
  includeAllColumns = false
) => {
  const { allColumns = [], flatRows = [] } = performanceTableProps || {}
  return [
    ...mapHeaders(allColumns, columnFormatter, includeAllColumns),
    ...mapRows(flatRows, formatter, includeAllColumns)
  ]
}
