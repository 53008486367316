import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useSearchParam } from '../../../hooks/useSearchParam'
import FadeIn from '../../molecules/Transitions/FadeIn'
import FirmReport from '../../pages/firmReporting/FirmReport'

function DenaliReport ({ reportId, columns, hideExport, defaultFilter, layout }) {
  const _searchFilter = useSearchParam('filter')
  const _filter = useMemo(() => {
    if (defaultFilter) return defaultFilter
    if (_searchFilter) return _searchFilter
    return null
  }, [_searchFilter, defaultFilter])
  const _col = columns?.length ? columns : undefined

  return (
    <FadeIn>
      <FirmReport
        reportId={reportId}
        defaultFilter={_filter}
        columns={_col}
        hideExport={hideExport}
        layout={layout}
      />
    </FadeIn>
  )
}

DenaliReport.propTypes = {
  reportId: PropTypes.string,
  columns: PropTypes.array,
  hideExport: PropTypes.bool,
  defaultFilter: PropTypes.string,
  layout: PropTypes.oneOf(['vertical', 'horizontal'])
}

DenaliReport.propTypes = {
  hideExport: false,
  layout: 'vertical'
}

export default DenaliReport
