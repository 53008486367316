import React, { useCallback, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import {
  Checkbox,
  ListItemIcon,
  ListItemText,
  MenuItem,
  makeStyles
} from '@material-ui/core'
import { isEmpty } from 'lodash'
import { useSelectFilterContext } from '../SelectFilterProvider'
import Tooltip from '../../../atoms/Tooltip'
import FilterContainer from './FilterContainer'
import FilterButton from './FilterButton'

const useStyles = makeStyles((theme) => ({
  content: {
    display: 'block',
    maxHeight: '20rem',
    minWidth: '15rem',
    overflowY: 'auto',
    padding: 0
  },
  buttonGroup: {
    gap: '0.5rem',
    display: 'flex',
    flexDirection: 'row'
  },
  item: {
    padding: '0.25rem !important'
  },
  checkbox: {
    color: `${theme.palette.primary.main} !important`
  },
  label: {
    color: 'inherit',
    fontWeight: '600',
    fontSize: '0.875rem',
    textTransform: 'none',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }
}))

const getOptionsLabel = (options) => {
  const [leadingOption, ...restOptions] = options
  if (!leadingOption) return ''
  return `${leadingOption}${
    !restOptions?.length ? '' : `, +${restOptions.length}`
  }`
}

const CheckboxListFilter = ({
  options,
  defaultValue
}) => {
  const classes = useStyles()
  const [checkedOptions, setCheckedOptions] = useState(defaultValue)
  const { onClearFilters, onApplyFilters } = useSelectFilterContext()

  const optionLabels = useMemo(() => {
    return options.reduce((acc, option) => {
      return { ...acc, [option.value]: option.label }
    }, {})
  }, [options])

  const onApply = useCallback(() => {
    const selectedOptions = Object.entries(optionLabels).reduce(
      (acc, [value, label]) => {
        if (!checkedOptions[value]) return acc
        return [...acc, label]
      },
      []
    )
    const displayLabel = getOptionsLabel(selectedOptions)

    const rangeValue = Object.entries(checkedOptions).reduce(
      (acc, [key, value]) => {
        if (!value) return acc
        return [...acc, key]
      },
      []
    )
    const filterMeta = {
      value: checkedOptions,
      rangeValue
    }
    if (isEmpty(checkedOptions)) return

    onApplyFilters({
      displayLabel,
      optionValue: 'in',
      ...filterMeta
    })
  }, [checkedOptions, optionLabels, onApplyFilters])

  const onSelectOption = (option) => {
    const { value } = option
    setCheckedOptions((prevState) => {
      return {
        ...prevState,
        [value]: !prevState?.[value]
      }
    })
  }

  const filterActions = useMemo(() => {
    return (
      <div className={classes.buttonGroup}>
        <FilterButton onClick={onClearFilters} color='#D44333'>
          Clear
        </FilterButton>
        <FilterButton onClick={onApply}>Apply</FilterButton>
      </div>
    )
  }, [classes.buttonGroup, onApply, onClearFilters])

  return (
    <FilterContainer className={classes.container}>
      <div className={classes.content}>
        {options.map((option) => {
          const { label, value, disabled = false } = option
          const selectedOption = checkedOptions?.[value] || false
          return (
            <MenuItem
              key={value}
              className={classes.item}
              disabled={disabled}
              onClick={() => onSelectOption(option)}
            >
              <ListItemIcon>
                <Checkbox
                  className={classes.checkbox}
                  checked={selectedOption}
                  disabled={disabled}
                />
              </ListItemIcon>
              <Tooltip title={label}>
                <ListItemText
                  primary={label}
                  classes={{ primary: classes.label }}
                />
              </Tooltip>
            </MenuItem>
          )
        })}
      </div>
      {filterActions}
    </FilterContainer>
  )
}

CheckboxListFilter.propTypes = {
  options: PropTypes.array,
  defaultValue: PropTypes.object
}

CheckboxListFilter.defaultProps = {
  options: [],
  defaultValue: {}
}

export default CheckboxListFilter
