import React, { useCallback } from 'react'
import { makeStyles } from '@material-ui/core'
import { useBoolean } from '../../../../hooks'
import { useAppContext } from '../../../../redux/slices/appContext'
import ClientValueCard from './ClientValuesCard'
import ClientValuesTag from './ClientValuesCard/ClientValuesTag'
import AddClientValueCard from './AddClientValueCard'
import useWealthMissionValue from './useWealthMissionValueOptions'

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '1rem',
    marginRight: '-1rem'
  },
  item: {
    display: 'flex',
    alignItems: 'stretch',
    width: 'calc(50% - 1rem)'
  }
}))

const ClientValues = () => {
  const classes = useStyles()
  const { clientId } = useAppContext()
  const [showNewValuesForm, toggleNewValuesForm] = useBoolean()
  const { missionValues, isLoading, refetchWealthMissionValues } = useWealthMissionValue()

  const renderFooter = useCallback(({ goalsCount, milestonesCount }) => {
    if (!goalsCount && !milestonesCount) return null
    return (
      <>
        {milestonesCount && (
          <ClientValuesTag>{`${milestonesCount} milestones`}</ClientValuesTag>
        )}
        {goalsCount && (
          <ClientValuesTag color='#09F'>{`${goalsCount} goals`}</ClientValuesTag>
        )}
      </>
    )
  }, [])

  const onSaveWealthMissionValue = useCallback(() => {
    toggleNewValuesForm.off()
    refetchWealthMissionValues()
  }, [refetchWealthMissionValues, toggleNewValuesForm])

  const onDeleteWealthMissionValue = useCallback(() => {
    refetchWealthMissionValues()
  }, [refetchWealthMissionValues])

  if (isLoading) {
    return (
      <div className={classes.container}>
        {Array(6).fill(0).map((_, index) => {
          return (
            <div key={index} className={classes.item}>
              <ClientValueCard isLoading />
            </div>
          )
        })}
      </div>
    )
  }

  return (
    <div className={classes.container}>
      {missionValues?.map((clientValueItem, index) => {
        return (
          <div key={index} className={classes.item}>
            <ClientValueCard
              {...clientValueItem}
              clientId={clientId}
              footer={renderFooter(clientValueItem)}
              onEditWealthValue={onSaveWealthMissionValue}
              onDeleteWealthValue={onDeleteWealthMissionValue}
            />
          </div>
        )
      })}
      <div className={classes.item}>
        <AddClientValueCard
          showForm={showNewValuesForm}
          showFormToggle={toggleNewValuesForm}
          onSave={onSaveWealthMissionValue}
        />
      </div>
    </div>
  )
}

ClientValues.propTypes = {}

export default ClientValues
