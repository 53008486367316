import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import dayjs from 'dayjs'
import { useAppContext } from '../../../redux/slices/appContext'
import { useSearchMilestones } from '../../../api/wealthJourney'
import MiniMilestone from './MiniMilestone'

const useStyles = makeStyles((theme) => ({
  milestones: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.layout.gap.dense
  },
  noInfo: {
    padding: theme.layout.padding.thick,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.palette.gray.darker,
    fontWeight: theme.typography.weights.light
  }
}))

function ClientMilestones ({ relativeFrom, relativeTo, period, take }) {
  const classes = useStyles()
  const { clientId } = useAppContext()
  const query = useMemo(() => {
    const start = dayjs().add(relativeFrom, period).format('YYYY-MM-DD')
    const end = dayjs().add(relativeTo, period).format('YYYY-MM-DD')

    return {
      take,
      filters: {
        startDate: [{ op: 'gte', value: start }, { op: 'lte', value: end }],
        clientId: [{ op: 'eq', value: clientId }]
      },
      sort: [{ field: 'entryDate', dir: 'asc' }]
    }
  }, [relativeFrom, relativeTo, period, take, clientId])
  const { data, isLoading } = useSearchMilestones(query)

  if (isLoading) return null

  if (!data?.data?.length) {
    return (
      <div className={classes.milestones}>
        <div className={classes.noInfo}>No Milestones</div>
      </div>
    )
  }

  return (
    <div className={classes.milestones}>
      {data.data.map(milestone => (
        <MiniMilestone key={milestone.entryId} milestone={milestone} />
      ))}
    </div>
  )
}

ClientMilestones.propTypes = {
  relativeFrom: PropTypes.number,
  relativeTo: PropTypes.number,
  period: PropTypes.oneOf(['day', 'week', 'month', 'year']),
  take: PropTypes.number
}

ClientMilestones.defaultProps = {
  take: 3
}

export default ClientMilestones
