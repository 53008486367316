import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { EXPOSURE_TYPES } from '../helpers'
import AssetExposurePicker from './AssetExposurePicker'
import ClassificationTagExposurePicker from './ClassificationTagExposurePicker'

const EXPOSURE_PICKER_COMPONENTS = {
  [EXPOSURE_TYPES.ASSET]: AssetExposurePicker,
  [EXPOSURE_TYPES.CLASSIFICATION_TAG]: ClassificationTagExposurePicker
}

const getExposurePicker = (exposureType) => EXPOSURE_PICKER_COMPONENTS[exposureType]

const ExposurePicker = ({ exposureType, setExposureTarget }) => {
  const ExposurePickerType = useMemo(
    () => getExposurePicker(exposureType),
    [exposureType]
  )
  return <ExposurePickerType setExposureTarget={setExposureTarget} />
}

ExposurePicker.propTypes = {
  exposureType: PropTypes.string,
  setExposureTarget: PropTypes.func
}

export default ExposurePicker
