import React, { useCallback, useImperativeHandle, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import RoundedModal from '../../../../../molecules/RoundedModal'
import { useComponentModelContext } from '../ComponentModelProvider'
import EditTagForm from './EditTagForm'

const EditTagDialog = React.forwardRef(function EditTagDialog ({ onEdit }, ref) {
  const { componentModel } = useComponentModelContext()
  const [dialogState, setDialogState] = useState({ open: false, processing: false })
  const close = useCallback(() => {
    setDialogState({ open: false, processing: false })
  }, [setDialogState])

  const complete = useCallback((componentModel) => {
    close()
    onEdit(componentModel, dialogState.index)
  }, [close, onEdit, dialogState])

  useImperativeHandle(ref, () => ({
    open: (item, index) => setDialogState({ open: true, processing: false, item, index })
  }), [setDialogState])

  const item = useMemo(() => {
    return dialogState.item
  }, [dialogState])

  return (
    <RoundedModal
      title='Edit Classification Tag'
      open={dialogState.open}
      onClose={close}
      position='top'
      disablePortal={false}
    >
      <div>
        <EditTagForm onComplete={complete} classificationTagTypeId={componentModel.classificationTagTypeId} item={item} onCancel={close} />
      </div>
    </RoundedModal>
  )
})

EditTagDialog.propTypes = {
  onEdit: PropTypes.func.isRequired
}

export default EditTagDialog
