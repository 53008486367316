import React, { useState, useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import ArrowBackOutlinedIcon from '@material-ui/icons/ArrowBack'
import { makeStyles } from '@material-ui/core/styles'
import first from 'lodash/first'
import { isArray } from 'lodash'
import { CALC_TYPES, LEVEL_TYPES } from '../../../constants'
import { useViewContext } from '../../../redux/slices/viewContext'
import { useAppContext } from '../../../redux/slices/appContext'
import { useFetchState } from '../../../hooks'
import { numeralByCase } from '../../../utils'
import { getVisualBalanceDrilldownTable, getVisualBalanceDrilldownTableFinalRow } from '../../../utils/tableHelper'
import { getPerformance } from '../../../service'
import TreeMapSpecificBlock from '../../molecules/TreeMapSpecificBlock'
import NumberFormat from '../../atoms/NumberFormat'
import Text from '../../atoms/Text'
import TableSkeleton from '../../atoms/TableSkeleton'
import { TableVirtualizedWithDynamicHeight } from '../../molecules/Table'
import { useContextContainer } from '../../../abundanceEngine/components/ContextContainer'
import { VBS_CONTEXT_KEY } from '.'

const TABLE_LABELS = [
  { name: 'HOLDINGS', alignment: 'left' },
  { name: '' },
  { name: '' },
  { name: 'ALLOCATION', alignment: 'right' },
  { name: 'TOTAL VALUE', alignment: 'right' }
]

const compareAllocationPercentage = (a, b) =>
  parseFloat(b.allocationPercentage) - parseFloat(a.allocationPercentage)

const useStyles = makeStyles(() => ({
  backButton: {
    cursor: 'pointer',
    flexDirection: 'row',
    alignItems: 'center',
    height: '100%',
    marginRight: '10px',
    display: 'flex'
  },
  blockChart: () => ({
    width: '100%',
    display: 'flex',
    flexDirection: 'column'
  }),
  topContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
    height: '56px',
    marginBottom: '37px'
  },
  bottomContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  tableContainer: {
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
    marginTop: '50px'
  }
}))

function VisualBalanceDrillDown ({
  height,
  onClickBack,
  onPressAccount,
  selectedBlock,
  backButtonText,
  width
}) {
  const appContext = useAppContext()
  const viewContext = useViewContext()
  const [{ includeCash, cashTagIds }] = useContextContainer(VBS_CONTEXT_KEY)

  const classes = useStyles({ height, width })
  const [selectedAccountId, setSelectedAccountId] = useState(undefined)
  const [fetching, setFetching] = useState(true)

  const onBack = useCallback(() => {
    setSelectedAccountId(undefined)
    onPressAccount(null)
    onClickBack()
  }, [
    onClickBack,
    onPressAccount,
    setSelectedAccountId
  ])

  const { drillDownHoldings = [], loading } = useFetchState(
    useCallback(
      async (setSafeState) => {
        if (fetching) {
          const _cashTagIds = isArray(cashTagIds) ? cashTagIds : [cashTagIds]
          const isCashBlock = _cashTagIds.includes(selectedBlock.levelId)
          const accountIds = selectedAccountId ? [selectedAccountId] : []
          const accountCategoryIds =
            !selectedAccountId && selectedBlock?.AccountCategoryGroupId
              ? [selectedBlock?.AccountCategoryGroupId]
              : []

          try {
            const params = {
              accountIds,
              accountCategoryIds,
              calcType: CALC_TYPES.balance,
              endDate: viewContext.mainDate,
              startDate: viewContext.mainDate,
              clientIds: [appContext.clientId],
              assetClassTagIds: isCashBlock ? _cashTagIds : [],
              levelTypes: [LEVEL_TYPES.ASSETS, LEVEL_TYPES.ASSET_CLASS_TAG],
              excludeTagIds: includeCash && !isCashBlock ? _cashTagIds : []
            }
            const res = await getPerformance(params)
            setSafeState({ drillDownHoldings: res.data })
            setFetching(false)
          } catch (err) {
            console.error(err)
            setFetching(false)
            setSafeState({ error: err })
          }
        }
      },
      [
        fetching,
        cashTagIds,
        includeCash,
        selectedAccountId,
        appContext.clientId,
        viewContext.mainDate,
        selectedBlock.levelId,
        selectedBlock.AccountCategoryGroupId
      ]
    )
  )

  const drillDownBlock = useMemo(() => {
    if (!selectedBlock) return null
    const backgroundColor = selectedBlock?.blockColor || first(selectedBlock.details)?.blockColor
    return (
      <div className={classes.blockChart}>
        <div className={classes.topContainer}>
          <div onClick={onBack} className={classes.backButton}>
            <ArrowBackOutlinedIcon />
            <Text customFontSize='1rem' text={`Back to ${backButtonText}`} />
          </div>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div style={{ marginRight: '6px' }}>
              <Text
                customFontSize='2rem'
                customFontWeight='bold'
                text={
                  <NumberFormat
                    number={numeralByCase(
                      selectedBlock.endingValue,
                      '0,0a',
                      '0,0.0a'
                    )}
                    title={numeralByCase(selectedBlock.endingValue)}
                    skipFormat
                  />
                }
              />
            </div>
            <Text customFontSize='2rem' text={` in ${selectedBlock.title}`} />
          </div>
          <div />
        </div>
        <div className={classes.bottomContainer}>
          <div
            style={{ height: '15rem' }}
            key={`drilldown-${selectedBlock.levelId}`}
          >
            <TreeMapSpecificBlock
              onClick={(item) => {
                setSelectedAccountId(item?.levelId)
                setFetching(true)
                onPressAccount(item)
              }}
              selectedBlock={selectedAccountId}
              details={selectedBlock.details}
              backgroundColor={backgroundColor}
            />
          </div>
        </div>
      </div>
    )
  }, [
    selectedBlock,
    selectedAccountId,
    classes.blockChart,
    classes.topContainer,
    classes.backButton,
    classes.bottomContainer,
    onBack,
    setSelectedAccountId,
    onPressAccount,
    backButtonText
  ])

  const rows = useMemo(() => {
    if (!drillDownHoldings) return []
    return getVisualBalanceDrilldownTable(drillDownHoldings.sort(compareAllocationPercentage))
  }, [drillDownHoldings])

  const finalRow = useMemo(() => {
    if (!drillDownHoldings) return []
    return getVisualBalanceDrilldownTableFinalRow(drillDownHoldings)
  }, [drillDownHoldings])

  const drilldownTable = useMemo(() => {
    return (
      <div className={classes.tableContainer}>
        <TableVirtualizedWithDynamicHeight
          labels={TABLE_LABELS}
          rows={rows}
          finalRow={finalRow}
          skipOrderBy
        />
      </div>
    )
  }, [classes.tableContainer, rows, finalRow])

  return (
    <>
      {drillDownBlock}
      {loading || fetching ? (
        <div className={classes.tableContainer}>
          <TableSkeleton firstColumnWithAvatar={false} columns={3} />
        </div>
      )
        : drilldownTable}
    </>
  )
}

VisualBalanceDrillDown.propTypes = {
  height: PropTypes.string,
  onClickBack: PropTypes.func,
  onPressAccount: PropTypes.func,
  selectedBlock: PropTypes.shape({
    AccountCategoryGroupId: PropTypes.number,
    blockColor: PropTypes.string,
    details: PropTypes.arrayOf(PropTypes.any),
    endingValue: PropTypes.number,
    levelId: PropTypes.number,
    title: PropTypes.string
  }),
  width: PropTypes.string,
  backButtonText: PropTypes.string
}

VisualBalanceDrillDown.defaultProps = {
  drillDownHoldings: [],
  height: undefined,
  onClickBack: noop,
  onPressAccount: noop,
  selectedBlock: undefined,
  width: undefined,
  backButtonText: 'Breakdown'
}

export default VisualBalanceDrillDown
