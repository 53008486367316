import React, { useMemo, useRef } from 'react'
import { IconButton } from '@material-ui/core'
import SydLabel from '../../../../../commonDesign/SydLabel'
import SydInput from '../../../../../commonDesign/SydInput'
import Icon from '../../../../../atoms/Icon'
import { useAccountFormContext } from '../../AccountFormProvider'
import { useListTaxStatusOptions } from '../../../../../../api/accounts'
import TaxStatusDialog from './TaxStatusDialog'

function TaxStatusInput () {
  const { account } = useAccountFormContext()
  const taxStatusRef = useRef()
  const { data, isLoading } = useListTaxStatusOptions()
  const [selectedOption, options] = useMemo(() => {
    if (!data?.taxStatusOptions?.length) {
      return ['', []]
    }
    const selected = data.taxStatusOptions.find(x => x.taxStatusTypeId === account.taxStatusId)?.longName
    const options = data.taxStatusOptions.map(x => ({
      label: x.longName,
      value: x.taxStatusTypeId
    }))

    return [selected, options]
  }, [data, account.taxStatusId])

  return (
    <>
      <SydLabel label='Tax Status'>
        <div className='__control-group'>
          <SydInput disabled value={selectedOption} />
          <div>
            <IconButton onClick={e => taxStatusRef.current.open(e)}>
              <Icon customSize={20} name='edit' />
            </IconButton>
          </div>
        </div>
      </SydLabel>
      <TaxStatusDialog taxStatusOptions={options} ref={taxStatusRef} loading={isLoading} />
    </>
  )
}

TaxStatusInput.propTypes = {
}

export default TaxStatusInput
