import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import RightPanel from '../RightPanel'
import { useComments } from './CommentContext'
import AttachmentPanelBody from './AttachmentPanelBody'
import Title from './Title'

const useStyles = makeStyles((theme) => ({
  panel: {
    position: 'relative',
    height: '100%',
    '& .__title': {
      padding: '20px 1.5rem',
      fontSize: '14px'
    }
  }
}))

function AttachmentsPanel () {
  const classes = useStyles()
  const {
    open,
    title,
    toggleOpen
  } = useComments()

  return (
    <RightPanel
      open={open === 'attachments-only'}
      title='Attachments'
      toggleOpen={toggleOpen}
      width='600px'
      height='100%'
      showCloseButton
      titleClassName='__large'
    >
      <div className={classes.panel}>
        <Title />
        <AttachmentPanelBody
          title={title}
        />
      </div>
    </RightPanel>
  )
}

export default AttachmentsPanel
