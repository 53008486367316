import React from 'react'
import PropTypes from 'prop-types'
import {
  InfiniteLoader,
  List as FixedSizeList,
  AutoSizer
} from 'react-virtualized'

import { makeStyles } from '@material-ui/core/styles'
import { Box, CircularProgress } from '@material-ui/core'

import noop from 'lodash/noop'

import Text from '../atoms/Text'
import Skeleton from '../atoms/Skeleton'
import { SKELETON_VARIANTS } from '../../constants'
import ExpandableTable from './ExpandableTable'

const useStyles = makeStyles((theme) => ({
  infiniteRow: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.porcelain
    },
    '& > .td': {
      padding: '1rem',
      fontSize: '0.75rem',
      textAlign: 'left'
    }
  },
  loading: {
    position: 'absolute',
    left: 0,
    right: 0
  },
  skeleton: {
    margin: '5px'
  }
}))

const ReactTableInfinite = ({
  columns,
  data,
  loadMoreRows,
  rowCount,
  height,
  threshold,
  batchSize,
  completeHeight,
  isLoading,
  isRowLoading
}) => {
  const classes = useStyles()

  const isRowLoaded = ({ index }, rows) => {
    return !!rows[index]
  }

  const RenderRow = (rowArgs, rows, prepareRow) => {
    const { key, index, style, isScrolling } = rowArgs
    const row = rows[index]

    if (!row) {
      return <div key={key} className='td'>Loading</div>
    }

    prepareRow(row)

    const isLoading = isRowLoading[row.id]

    return (
      <>
        <div key={key} className={classes.infiniteRow} {...row.getRowProps({ style })}>
          {
            row.cells.map((cell, i) => {
              const Cell = () => (
                <div {...cell.getCellProps()} className='td' key={`cell-${i}`}>
                  <Text
                    text={cell.render('Cell', {
                      isLoading: isRowLoading[row.id],
                      isExpanded: row?.isExpanded
                    })}
                    variant='subtitle2'
                  />
                </div>
              )

              return (
                <>
                  {
                    isScrolling
                      ? <Skeleton height='62px' width='100%' variant={SKELETON_VARIANTS.text} className={classes.skeleton} />
                      : <Cell />
                  }
                </>
              )
            })
          }
          {
            isLoading
              ? <Box my={4} textAlign='center' className={classes.loading}><CircularProgress /></Box>
              : null
          }
        </div>
      </>
    )
  }

  return (
    <ExpandableTable
      data={data}
      columns={columns}
      isLoading={isLoading}
      renderBody={(rows, prepareRow) => {
        return (
          <InfiniteLoader
            // know if row is loaded
            isRowLoaded={rowArgs => isRowLoaded(rowArgs, rows)}
            loadMoreRows={loadMoreRows}
            rowCount={rowCount}
            threshold={threshold}
            minimumBatchSize={batchSize}
          >
            {({ onRowsRendered, registerChild }) => (
              <AutoSizer disableHeight>
                {({ width }) => (
                  <FixedSizeList
                    className='border'
                    height={height ?? 600}
                    onRowsRendered={onRowsRendered}
                    ref={registerChild}
                    rowCount={rowCount}
                    rowHeight={62}
                    rowRenderer={rowArgs => RenderRow(rowArgs, rows, prepareRow)}
                    width={width}
                    style={completeHeight ? { minHeight: '100vh' } : {}}
                  />
                )}
              </AutoSizer>
            )}
          </InfiniteLoader>
        )
      }}
    />
  )
}

ReactTableInfinite.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  loadMoreRows: PropTypes.func.isRequired,
  threshold: PropTypes.number,
  batchSize: PropTypes.number,
  rowCount: PropTypes.number.isRequired,
  height: PropTypes.number,
  completeHeight: PropTypes.bool,
  isLoading: PropTypes.bool,
  isRowLoading: PropTypes.object
}

ReactTableInfinite.defaultProps = {
  completeHeight: false,
  threshold: 20,
  batchSize: 20,
  isLoading: false,
  loadMoreRows: noop,
  isRowLoading: {}
}

export default ReactTableInfinite
