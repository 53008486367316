import React, { useMemo, useState } from 'react'
import { Box } from '@material-ui/core'
import OperationalTable from '../../components/organisms/OperationalTable'
import SearchBarInput from '../../components/molecules/SearchBar/SearchBarInput'
import { abundanceComponents } from '../abundanceComponentsRegister'
import DefaultPropsCell from './DefaultPropsCell'
import PropTypesCell from './PropTypesCell'
import { extractTypes } from './helpers'

const columns = [
  {
    Header: 'Component Name',
    accessor: 'name'
  },
  {
    Header: 'Properties',
    accessor: 'propTypes',
    Cell: PropTypesCell
  },
  {
    Header: 'Default Values',
    accessor: 'defaultProps',
    Cell: DefaultPropsCell
  }
]

const defaultSort = [{ id: 'name', desc: false }]

const AbundanceEngineComponentsTable = () => {
  const [searchText, setSearchText] = useState('')

  const components = useMemo(() => {
    const data = Object.entries(abundanceComponents)
      .map(([key, value]) => {
        const propTypes = extractTypes(value)
        const defaultProps = value?.defaultProps
        return {
          name: key,
          propTypes,
          defaultProps,
          tempSearchField: `
            ${key} ${JSON.stringify(propTypes)} ${JSON.stringify(defaultProps)}}
          `.toLowerCase()
        }
      })

    if (searchText.trim()) {
      return data.filter(({ tempSearchField }) => {
        return tempSearchField.indexOf(searchText.toLowerCase()) !== -1
      })
    }
    return data
  }, [searchText])

  return (
    <OperationalTable.Wrapper>
      <OperationalTable.SuperHeader>
        <Box pt='0.25rem' maxWidth='25rem'>
          <SearchBarInput
            value={searchText}
            onChange={setSearchText}
            placeholder='Search by component or property names'
          />
        </Box>
      </OperationalTable.SuperHeader>
      <OperationalTable
        mode='client'
        total={components.length}
        columns={columns}
        data={components}
        defaultSort={defaultSort}
        hideFooter={false}
        showTotalItemsLabel
        itemName='Components'
      />
    </OperationalTable.Wrapper>
  )
}

export default AbundanceEngineComponentsTable
