import React, { useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'
import { Box, Grid, makeStyles } from '@material-ui/core'
import { numeralByCase, tableNumberFormatter } from '../../../utils'
import { BLOCK_TYPES, TEXT_VARIANTS } from '../../../constants'
import { visualBalanceDataTypeShape } from '../../../prop-types'
import Text from '../../atoms/Text'
import ListItem from '../../molecules/ListItem'
import NumberFormat from '../../atoms/NumberFormat'

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row'
  },
  totalAssetsBlock: {
    height: '100%',
    backgroundColor: 'rgba(196, 228, 211, 0.3)',
    textAlign: 'center',
    padding: '2.5rem 1.75rem'
  },
  lastAssetBlockRow: {
    backgroundColor: theme.palette.gray.lighter,
    padding: '6px 4px !important'
  },
  assetTotalBlock: {
    minWidth: '10rem',
    minHeight: '10rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.gray.lighter
  },
  otherAssetsContainer: {
    border: `3px solid ${theme.palette.gray.lighter}`,
    borderRadius: '1rem'
  },
  content: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row'
  },
  totalContainer: {
    width: '167px',
    backgroundColor: '#D8EDE2',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  }
}))

function getBlocksEndingValue (blocks) {
  return blocks.reduce((acc, block) => acc + block.endingValue, 0)
}

function getBlocksData (data, blockTypes) {
  const blocks = data
    .filter(block => blockTypes.includes(block.blockType))
    .map(block => ({
      ...block,
      endingValue: !isEmpty(block?.details)
        ? block.details.reduce((detailsAcc, blockDetail) => (detailsAcc + blockDetail.endingValue), 0)
        : block.endingValue
    }))

  const value = getBlocksEndingValue(blocks)
  return [value ? blocks : [], value]
}

function processData (data, doubleDistance) {
  const [coreBlocks, coreValue] = getBlocksData(data, [BLOCK_TYPES.core, BLOCK_TYPES.side])
  const [otherAssetBlocks, otherAssetValue] = getBlocksData(data, [BLOCK_TYPES.others])
  const [liabilityBlocks, liabilityValue] = getBlocksData(data, [BLOCK_TYPES.bottom])

  const totalValue = coreValue + otherAssetValue

  return {
    liabilityBlocks,
    liabilityValue,
    totalValue,
    coreBlocks,
    otherAssetBlocks,
    otherAssetValue
  }
}

function VisualBalanceSide ({
  data,
  width,
  height,
  formatToPrecisionValue,
  assetsTitle,
  assetsBlockTitle,
  liabilitiesTitle,
  otherAssetsTitle
}) {
  const classes = useStyles({ height, width })
  const {
    totalValue,
    coreBlocks,
    liabilityValue,
    liabilityBlocks,
    otherAssetBlocks
  } = useMemo(() => processData(data), [data])

  const renderAssetBlock = useCallback(
    ({
      levelId,
      title: blockTitle,
      details = [],
      showLastRow = true,
      showBorderBottom = true
    }, isLastBlock) => {
      let totalValue = 0
      if (showLastRow) {
        totalValue = getBlocksEndingValue(details)
      }
      return (
        <Box key={`${levelId}-${blockTitle}`} mb={isLastBlock ? '0' : '2rem'}>
          <Box mb={1}>
            <Text
              variant={TEXT_VARIANTS.subtitle1}
              customFontWeight='bold'
              customFontSize='1rem'
              text={blockTitle}
            />
          </Box>
          {
            details.map(({ levelId, title, endingValue, categoryName }) => (
              <ListItem
                key={`asset-block-${levelId}-${categoryName}`}
                noBorderBottom={!showBorderBottom}
                leftElement={<Text text={title} />}
                rightElement={
                  <Text
                    customFontWeight='normal'
                    text={
                      <NumberFormat
                        number={tableNumberFormatter(endingValue, formatToPrecisionValue)}
                        title={numeralByCase(endingValue)}
                        skipFormat
                      />
                    }
                  />
                }
              />
            ))
          }
          {showLastRow && (
            <ListItem
              additionalClasses={classes.lastAssetBlockRow}
              noBorderBottom
              rightElement={
                <Text
                  customFontWeight='bold'
                  text={
                    <NumberFormat
                      number={tableNumberFormatter(totalValue, formatToPrecisionValue)}
                      title={numeralByCase(totalValue)}
                      skipFormat
                    />
                  }
                />
              }
            />
          )}
        </Box>
      )
    },
    [classes.lastAssetBlockRow, formatToPrecisionValue]
  )

  const renderAssetSideBlock = useCallback(
    ({ totalValue, assetSideBlocks, blockTitle }) => {
      return (
        <Grid container>
          <Grid item xs={12}>
            <Box display='flex'>
              <div className={classes.assetTotalBlock}>
                <Box display='flex' flexDirection='column' alignItems='center'>
                  <Text
                    variant={TEXT_VARIANTS.h4} customFontSize='2rem' customFontWeight='bold' text={
                      <NumberFormat
                        number={tableNumberFormatter(totalValue, formatToPrecisionValue)}
                        title={numeralByCase(totalValue)}
                        skipFormat
                      />
                    }
                  />
                  <Text text={blockTitle} />
                </Box>
              </div>
              <Box ml={4} width='100%'>
                <Box mb={4}>
                  <Text
                    text={blockTitle}
                    customFontSize='2.5rem'
                    customFontWeight='bold'
                    variant={TEXT_VARIANTS.h4}
                  />
                </Box>
                {assetSideBlocks.map(assetBlock => renderAssetBlock({
                  ...assetBlock,
                  showLastRow: false,
                  showBorderBottom: false
                }))}
              </Box>
            </Box>
          </Grid>
        </Grid>
      )
    },
    [renderAssetBlock, classes.assetTotalBlock, formatToPrecisionValue]
  )

  return (
    <Grid container>
      <Grid item xs={12} md={6}>
        <Grid container>
          <Grid item xs={4}>
            <div className={classes.totalAssetsBlock}>
              <Text
                customFontSize='2rem'
                customFontWeight='bold'
                variant={TEXT_VARIANTS.h4}
                text={
                  <NumberFormat
                    number={tableNumberFormatter(totalValue, formatToPrecisionValue)}
                    title={numeralByCase(totalValue)}
                    skipFormat
                  />
                }
              />
              <Text variant={TEXT_VARIANTS.body1} text={assetsBlockTitle} />
            </div>
          </Grid>
          <Grid item xs={8}>
            <Box mx={4}>
              <Box display='flex' mb='2rem'>
                <Text
                  text={assetsTitle}
                  customFontSize='2.5rem'
                  customFontWeight='bold'
                  variant={TEXT_VARIANTS.h4}
                />
              </Box>
              <Box>
                {coreBlocks.map((assetBlock, index) => {
                  const isLastBlock = index === coreBlocks.length - 1
                  return renderAssetBlock(assetBlock, isLastBlock)
                })}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={6}>
        <Box mx={4}>
          <Box mb={6}>
            {renderAssetSideBlock({
              blockTitle: liabilitiesTitle,
              totalValue: liabilityValue,
              assetSideBlocks: liabilityBlocks
            })}
          </Box>
          <div className={classes.otherAssetsContainer}>
            <Box px={3} pt={3}>
              <Box display='flex' mb={isEmpty(otherAssetBlocks) ? '1rem' : '2rem'}>
                <Text
                  variant={TEXT_VARIANTS.h4}
                  customFontSize='2.5rem'
                  customFontWeight='bold'
                  text={otherAssetsTitle}
                />
              </Box>
              <Box>
                {otherAssetBlocks.map(assetBlock => renderAssetBlock(assetBlock))}
              </Box>
            </Box>
          </div>
        </Box>
      </Grid>
    </Grid>
  )
}

VisualBalanceSide.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    ...visualBalanceDataTypeShape,
    details: PropTypes.arrayOf(PropTypes.shape(visualBalanceDataTypeShape))
  })),
  height: PropTypes.string,
  width: PropTypes.string,
  formatToPrecisionValue: PropTypes.bool,
  assetsTitle: PropTypes.string,
  assetsBlockTitle: PropTypes.string,
  liabilitiesTitle: PropTypes.string,
  otherAssetsTitle: PropTypes.string
}

VisualBalanceSide.defaultProps = {
  data: [],
  height: undefined,
  width: undefined,
  formatToPrecisionValue: undefined,
  assetsTitle: 'Assets',
  assetsBlockTitle: 'Assets',
  liabilitiesTitle: 'Liabilities',
  otherAssetsTitle: 'Other Assets'
}

export default VisualBalanceSide
