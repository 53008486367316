import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useFormattingContext } from '../../organisms/FormattingProvider/FormattingContext'
import TooltipTitleCell from '../../organisms/PerformanceTableV2/PerformancePresentationTable/Cells/TooltipTitleCell'

const HeaderCell = ({ column }) => {
  if (!column?.tooltip) return column.header ?? null
  return (
    <TooltipTitleCell
      column={{
        tooltipTitle: column?.tooltip,
        header: column.header
      }}
    />
  )
}
HeaderCell.propTypes = {
  column: PropTypes.shape({
    header: PropTypes.string,
    tooltip: PropTypes.string
  })
}

const Cell = ({ value, cell }) => {
  const { column } = cell
  const { formatter } = useFormattingContext()

  const cellValue = column.format
    ? formatter(value, column.format)
    : value

  if (!column?.cellTooltip) return cellValue ?? null

  let dateRangeTitle = null

  const {
    startDate, endDate, groupBy
  } = cell?.row?.original || {}

  if (column?.cellTooltipFormat && endDate && startDate && value === groupBy) {
    dateRangeTitle = `${startDate} - ${endDate}`
  }

  return (
    <TooltipTitleCell
      column={{
        tooltipTitle:
          dateRangeTitle ??
          (value && typeof column?.cellTooltipFormat === 'string'
            ? formatter(value, column?.cellTooltipFormat)
            : value),
        header: cellValue
      }}
    />
  )
}
Cell.propTypes = {
  value: PropTypes.string,
  cell: PropTypes.shape({
    row: PropTypes.object,
    column: PropTypes.shape({
      format: PropTypes.string,
      cellTooltip: PropTypes.string,
      cellTooltipFormat: PropTypes.string
    })
  })
}

export const useColumns = (columnsConfig) => {
  return useMemo(() => {
    const { columns, defaultSort } = columnsConfig
    const templatedColumns = columns.map((column) => {
      return {
        ...column,
        Header: HeaderCell,
        Cell: Cell
      }
    })

    return {
      columns: templatedColumns,
      defaultSort
    }
  }, [columnsConfig])
}
