import React from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@material-ui/core'
import AdvisorProfile from './AdvisorProfile'
import AdvisorAnalytics from './AdvisorAnalytics'

const AdvisorViewHeader = ({
  advisorId,
  className,
  imageLinkUrl,
  imageLinkSrc,
  imageLinkStyles
}) => {
  return (
    <Grid container item xs={12} className={className}>
      <Grid item xs={6}>
        <AdvisorProfile advisorId={advisorId} />
      </Grid>
      <Grid item xs={6}>
        <AdvisorAnalytics
          advisorId={advisorId}
          imageLinkUrl={imageLinkUrl}
          imageLinkSrc={imageLinkSrc}
          imageLinkStyles={imageLinkStyles}
        />
      </Grid>
    </Grid>
  )
}

AdvisorViewHeader.propTypes = {
  advisorId: PropTypes.number,
  className: PropTypes.string,
  imageLinkUrl: PropTypes.string,
  imageLinkSrc: PropTypes.string,
  imageLinkStyles: PropTypes.object
}

export default AdvisorViewHeader
