import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
import ConversationButton from '../../components/ConversationButton'

const useStyles = makeStyles(theme => ({
  createButton: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    padding: '20px'
  }
}))

const CreateConversationButton = ({ clientId, isNew }) => {
  const classes = useStyles()
  const history = useHistory()
  const handleCreate = useCallback((e) => {
    e.preventDefault()
    e.stopPropagation()
    history.push(`/conversations/c/${clientId}/new`, { from: window.location.pathname })
  }, [history, clientId])

  if (isNew) {
    return (
      <div className={classes.createButton}>
        Creating...
      </div>
    )
  }

  return (
    <div className={classes.createButton}>
      <ConversationButton
        primary
        iconName='add'
        onClick={handleCreate}
      >
        Add something new
      </ConversationButton>
    </div>
  )
}

CreateConversationButton.propTypes = {
  clientId: PropTypes.number,
  isNew: PropTypes.bool
}

CreateConversationButton.defaultProps = {
  isNew: false
}

export default CreateConversationButton
