import React, { useCallback } from 'react'
import PropTypes from 'prop-types'

import { getAssetsWithExtras } from '../../../../service'
import { useAvailableDates } from '../../../../redux/slices/appContext'
import { useAbortController, useFetchState } from '../../../../hooks'

import { getRandomThemeColor } from '../../../../utils'
import { SKELETON_VARIANTS, CALC_TYPES } from '../../../../constants'

import PieProgressBars from '../../../molecules/PieProgressBars'
import Skeleton from '../../../atoms/Skeleton'

const mapPieChartData = (accountObjectives) => {
  const accountObjectivesTotalBalance = accountObjectives.reduce(
    (acc, { balance }) => acc + balance,
    0
  )
  const accountObjectivesData = accountObjectives.map(({ id, balance, ordinal, names, color, extras2 }) => ({
    id,
    balance,
    ordinal,
    color: color || extras2?.color || getRandomThemeColor(),
    title: Object.values(names)[0],
    percentage: Math.abs((balance / accountObjectivesTotalBalance) * 100)
  })
  )
  return accountObjectivesData
}

const AdvisorGoalsReport = ({ showHeader, advisorId, levelType, type }) => {
  const abortController = useAbortController()
  const [availableDates] = useAvailableDates()

  const fetchPieData = useCallback(
    async ({ mainDate }, abortController) => {
      return await getAssetsWithExtras(
        {
          startDate: mainDate,
          endDate: mainDate,
          levelTypes: [levelType],
          advisorIds: [advisorId],
          calcType: CALC_TYPES.balance,
          type
        },
        abortController
      )
    },
    [advisorId, levelType, type]
  )

  const { loading, pieData = [] } = useFetchState(
    useCallback(
      async (safeSetState) => {
        try {
          const signal = abortController()

          const { data: pieData } = await fetchPieData(availableDates, { signal })

          safeSetState({
            pieData: mapPieChartData(pieData)
          })
        } catch (err) {
          safeSetState({ loading: false })
          console.error(err)
        }
      },
      [
        availableDates,
        fetchPieData,
        abortController
      ]
    )
  )

  if (loading) {
    return <Skeleton width='260px' height='260px' variant={SKELETON_VARIANTS.circle} />
  }

  return (
    <PieProgressBars
      pie
      showHeader={showHeader}
      progressBarsDataSet={pieData}
    />
  )
}

AdvisorGoalsReport.propTypes = {
  showHeader: PropTypes.bool,
  advisorId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  type: PropTypes.string.isRequired,
  levelType: PropTypes.string.isRequired
}

AdvisorGoalsReport.defaultProps = {
  showHeader: false,
  type: '',
  filter: ''
}

export default AdvisorGoalsReport
