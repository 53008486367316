import React from 'react'
import PropTypes from 'prop-types'
import Text from '../../../atoms/Text'
import FastAvatar from '../../../molecules/FastAvatar'

function ScopeMenuItem ({ className, label, value, onChange, avatarUrl, abbreviation, hideAvatar }) {
  return (
    <div
      className={className}
      onClick={(event) => onChange(event, value)}
    >
      {hideAvatar ? null : (
        <FastAvatar
          avatarUrl={avatarUrl}
          size='sm'
          abbreviation={abbreviation}
        />
      )}
      <Text text={label} />
    </div>
  )
}

ScopeMenuItem.propTypes = {
  className: PropTypes.string,
  label: PropTypes.any,
  value: PropTypes.any,
  onChange: PropTypes.func,
  avatarUrl: PropTypes.string,
  abbreviation: PropTypes.string,
  hideAvatar: PropTypes.bool
}

export default ScopeMenuItem
