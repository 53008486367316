import React, { useMemo, useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { Box, makeStyles, Tab, Tabs } from '@material-ui/core'
import { camelCase, first, has, isObject } from 'lodash'
import PerformanceAllocationTable from '../../molecules/PerformanceAllocationTable'
import { useAppContext } from '../../../redux/slices/appContext'
import { hydrateColumnsSelectProps } from '../../../utils/tableHelper'
import { defaultColumns } from '../../../prop-types/tables'

export function getTablePropName (labelProp = { label: '', propName: '' }) {
  if (!isObject(labelProp)) {
    return {
      label: labelProp,
      propName: camelCase(`${labelProp.toLowerCase()}TableProps`)
    }
  }
  return labelProp
}

function a11yProps (index) {
  return {
    id: `tab-${index}`,
    'aria-controls': `performance-allocation-table-tabpanel-${index}`
  }
}

const useStyles = makeStyles(() => ({
  tabs: {
    minHeight: 'unset'
  },
  tab: {
    borderRadius: '2rem',
    minHeight: '1.5rem',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.02)'
    }
  },
  tabText: {
    fontWeight: 'bold'
  },
  tabActive: {
    backgroundColor: 'rgba(0, 0, 0, 0.04)'
  }
}))

const tabIndicatorStyles = {
  color: 'transparent'
}

const PerformanceTableContainer = ({
  tabs: _tabs,
  baseTableProps,
  tableViewColumnsFormat,
  ...restProps
}) => {
  const classes = useStyles()
  const appContext = useAppContext()
  const defaultActiveTab = getTablePropName(first(_tabs)).propName
  const [activeTab, setActiveTab] = useState(defaultActiveTab)

  const handleChange = useCallback((_, newValue) => {
    setActiveTab(newValue)
  }, [])

  const activeTableProps = useMemo(() => {
    let tableProps = {
      ...baseTableProps,
      ...(restProps[activeTab] || {})
    }
    if (has(tableProps, 'columns')) {
      tableProps = {
        ...tableProps,
        columns: hydrateColumnsSelectProps(
          tableProps.columns,
          appContext.availableDates,
          tableViewColumnsFormat
        )
      }
    } else {
      tableProps = {
        ...tableProps,
        columns: hydrateColumnsSelectProps(
          defaultColumns,
          appContext.availableDates,
          tableViewColumnsFormat
        )
      }
    }
    return tableProps
  }, [
    activeTab,
    restProps,
    baseTableProps,
    tableViewColumnsFormat,
    appContext.availableDates
  ])

  const tabButtons = useMemo(() => {
    return _tabs.map((labelValue, index) => {
      const { label, propName } = getTablePropName(labelValue)
      return (
        <Tab
          key={`${propName}-${index}`}
          classes={{
            root: classes.tab,
            wrapper: classes.tabText,
            selected: classes.tabActive
          }}
          label={label}
          value={propName}
          {...a11yProps(index)}
        />
      )
    })
  }, [_tabs, classes])

  return (
    <Box width='100%'>
      <PerformanceAllocationTable
        headerSection={
          <Box my={2}>
            <Tabs
              classes={{ root: classes.tabs }}
              value={activeTab}
              onChange={handleChange}
              TabIndicatorProps={tabIndicatorStyles}
              aria-label='performance table tabs'
            >
              {tabButtons}
            </Tabs>
          </Box>
        }
        {...activeTableProps}
      />
    </Box>
  )
}

PerformanceTableContainer.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        label: PropTypes.string,
        propName: PropTypes.string
      })
    ])
  ),
  baseTableProps: PropTypes.object,
  tableViewColumnsFormat: PropTypes.array
}

PerformanceTableContainer.defaultProps = {
  tabs: [],
  baseTableProps: {
    disableFinalRow: false
  },
  tableViewColumnsFormat: []
}

export default PerformanceTableContainer
