import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import { makeStyles } from '@material-ui/core'
import Modal from '../../../../molecules/Modal'
import Text from '../../../../atoms/Text'
import { ICON_NAMES, TEXT_VARIANTS } from '../../../../../constants'
import Icon from '../../../../atoms/Icon'
import theme from '../../../../../theme'

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh'
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    flex: '0 1',
    padding: '1rem 0',
    alignItems: 'center',
    maxWidth: '900px',
    width: '95%',
    margin: '0 auto'
  },
  iframeContainer: {
    flex: '1 0',
    width: '100%',
    position: 'relative'
  },
  iframe: {
    display: 'block',
    border: 'none',
    width: '100%',
    height: '100%',
    minHeight: '1100px',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0
  }
}))

const ReportPreviewModal = ({
  previewUrl,
  onClose
}) => {
  const classes = useStyles()
  const isOpen = useMemo(() => !!previewUrl?.length, [previewUrl])

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      width='80%'
      maxWidth='1000px'
    >
      <div className={classes.container}>
        <div className={classes.header}>
          <Text variant={TEXT_VARIANTS.h2}>Preview Report</Text>
          <button
            type='button'
            onClick={onClose}
            style={{ cursor: 'pointer' }}
          >
            <Icon
              color={theme.palette.summitBlue}
              name={ICON_NAMES.close}
              customSize='1.5rem'
              variant='button'
            />
          </button>
        </div>
        <div className={classes.iframeContainer}>
          <iframe src={previewUrl} title='Preview Report' className={classes.iframe} />
        </div>
      </div>
    </Modal>
  )
}

ReportPreviewModal.propTypes = {
  previewUrl: PropTypes.string,
  onClose: PropTypes.func
}

ReportPreviewModal.defaultProps = {
  previewUrl: '',
  onSubmit: noop
}

export default ReportPreviewModal
