import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import noop from 'lodash/noop'
import clsx from 'clsx'

import { useFeatureFlag } from '../../redux/slices/appConfig'
import { useAppContext } from '../../redux/slices/appContext'

import RoundedButton from '../atoms/RoundedButton'
import NotesBoard from '../organisms/NotesBoard'
import SalesforceBoard from '../organisms/SalesforceBoard'
import { useToggle } from '../../hooks'

import {
  FEATURE_FLAG,
  BUTTON_VARIANT,
  BUTTON_SIZES,
  WEALTHBOX_INTEGRATION,
  SALESFORCE_INTEGRATION
} from '../../constants'
import WealthboxBoard from '../organisms/WealthboxBoard'

const useStyles = makeStyles((theme) => ({
  embeddedContainer: {
    width: '100%',
    height: '100%',
    maxHeight: `${window.innerHeight / 1.2}px`,
    overflowX: 'hidden',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: '1em',
      height: '1em',
      backgroundColor: 'transparent'
    },
    '&::-webkit-scrollbar-track': {
      borderRadius: '20px',
      backgroundColor: 'transparent'
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '20px',
      backgroundColor: theme.palette.gray.dark,
      border: '3px solid white'
    }
  },
  activeBtn: {
    backgroundColor: `${theme.palette.darkBlue} !important`,
    color: theme.palette.white
  },
  btn: {
    marginRight: '10px'
  }
}))

const EmbeddedNotesBoard = ({ salesforceId }) => {
  const classes = useStyles()

  const { isAdvisor } = useAppContext()
  const [showCommentsBoard, , toggleShowCommentsBoardOn, toggleShowCommentsBoardOff] = useToggle(true)
  const { active: INTEGRATIONS, extras: INTEGRATIONS_LIST = { active: [] } } = useFeatureFlag(FEATURE_FLAG.INTEGRATIONS)
  const SHOW_WEALTHBOX_CLIENT_INFO = INTEGRATIONS && INTEGRATIONS_LIST.active.includes(WEALTHBOX_INTEGRATION)
  // const { active: SHOW_SALESFORCE_CLIENT_INFO } = useFeatureFlag(FEATURE_FLAG.SHOW_SALESFORCE_CLIENT_INFO)
  const SHOW_SALESFORCE_CLIENT_INFO = INTEGRATIONS && INTEGRATIONS_LIST.active.includes(SALESFORCE_INTEGRATION)
  const isCRMActive = SHOW_SALESFORCE_CLIENT_INFO || SHOW_WEALTHBOX_CLIENT_INFO

  if (!isCRMActive || !isAdvisor) {
    return (
      <div className={classes.embeddedContainer}>
        <NotesBoard open embedded onClose={noop} showClose={false} />
      </div>
    )
  }

  return (
    <div className={classes.embeddedContainer}>
      <RoundedButton
        variant={BUTTON_VARIANT.text}
        size={BUTTON_SIZES.small}
        className={clsx(classes.btn, { [classes.activeBtn]: showCommentsBoard })}
        onClick={toggleShowCommentsBoardOn}
      >
        Comments
      </RoundedButton>
      <RoundedButton
        variant={BUTTON_VARIANT.text}
        size={BUTTON_SIZES.small}
        className={clsx('', { [classes.activeBtn]: !showCommentsBoard })}
        onClick={toggleShowCommentsBoardOff}
      >
        {SHOW_SALESFORCE_CLIENT_INFO ? 'Salesforce' : 'Wealthbox'}
      </RoundedButton>

      {showCommentsBoard && (<NotesBoard open embedded onClose={noop} showClose={false} />)}
      {!showCommentsBoard && SHOW_SALESFORCE_CLIENT_INFO && (<SalesforceBoard salesforceId={salesforceId} />)}
      {!showCommentsBoard && SHOW_WEALTHBOX_CLIENT_INFO && (<WealthboxBoard wealthboxAccountId={salesforceId} />)}

    </div>
  )
}

EmbeddedNotesBoard.propTypes = {
  salesforceId: PropTypes.string.isRequired
}

EmbeddedNotesBoard.defaultProps = {
  salesforceId: ''
}

export default React.memo(EmbeddedNotesBoard)
