import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  labeledValue: {
    '& > .__label': {
      display: 'inline-block',
      minWidth: '10rem',
      marginRight: '1rem'
    },
    '& > .__value': {
      fontWeight: 200
    }
  }
}))

const LabeledValue = ({ label, children }) => {
  const classes = useStyles()

  return (
    <div className={classes.labeledValue}>
      <span className='__label'>{label}</span>
      <span className='__value'>{children}</span>
    </div>
  )
}

LabeledValue.propTypes = {
  label: PropTypes.string.isRequired,
  children: PropTypes.node
}

export default LabeledValue
