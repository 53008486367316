import React from 'react'
import PropTypes from 'prop-types'
import Tag from '../../atoms/Tag'
import theme from '../../../theme'

const BetaTag = ({
  id = 'dynamic-planning-beta-tag',
  text = 'Beta',
  styles = {},
  backgroundColor = theme.palette.summitBlue
}) => {
  return (
    <div id={id} style={styles}>
      <Tag color='#fff' backgroundColor={backgroundColor} label={text} clickable />
    </div>
  )
}

BetaTag.propTypes = {
  id: PropTypes.string,
  text: PropTypes.string,
  styles: PropTypes.object,
  backgroundColor: PropTypes.string
}

export default BetaTag
