import React, { useMemo } from 'react'
import { compareNumericString, compareOrdinal } from '../../../utils'
import { useFormattingContext } from '../FormattingProvider/FormattingContext'

const sharedAttrs = { alignRight: true, width: 50 }

const typeLookup = {
  numeric: {
    alignRight: true,
    width: 50,
    sortType: compareNumericString
  },
  ordinal: {
    sortType: compareOrdinal
  },
  boolean: {
    alignRight: true,
    width: 25,
    format: (x) => x ? 'Y' : 'N'
  }
}

export const defaultColumnConfig = {
  columns: [
    { Header: 'Name', accessor: 'levelName', sortType: compareOrdinal },
    {
      id: 'actuals',
      Header: 'Actual',
      alignCenter: true,
      columns: [
        { id: 'marketValue', Header: 'Value', accessor: 'allocationValue', ...sharedAttrs, format: 'marketValue', sortType: compareNumericString },
        { id: 'marketAllocation', Header: '%', accessor: 'allocation', ...sharedAttrs, format: 'percentage', sortType: compareNumericString }
      ]
    },
    {
      id: 'targets',
      Header: 'Target',
      alignCenter: true,
      columns: [
        { id: 'targetValue', Header: 'Value', accessor: 'rebalancingValue', ...sharedAttrs, format: 'marketValue', sortType: compareNumericString },
        { id: 'targetAllocation', Header: '%', accessor: 'rebalancingTarget', ...sharedAttrs, format: 'percentage', sortType: compareNumericString }
      ]
    },
    {
      id: 'diffs',
      Header: 'Difference',
      alignCenter: true,
      columns: [
        { id: 'diffValue', Header: 'Value', accessor: 'rebalancingValueDiff', ...sharedAttrs, format: 'marketValue', sortType: compareNumericString },
        { id: 'diffAllocation', Header: '%', accessor: 'rebalancingAllocDiff', ...sharedAttrs, format: 'percentage', sortType: compareNumericString }
      ]
    }
  ],
  defaultSort: [{ id: 'levelName', desc: false }]
}

const formatBoolean = (val) => {
  if ([null, undefined].includes(val)) return ''

  return val ? 'Yes' : 'No'
}

/* eslint-disable react/prop-types */
const pickCell = (type, formatter, formatString) => {
  switch (type?.toLowerCase()) {
    case 'boolean':
      return ({ value }) => <div>{formatBoolean(value)}</div>
    default:
      return ({ value }) => <div>{formatter(value, formatString)}</div>
  }
}
/* eslint-enable react/prop-types */

export const useColumns = (columnConfig = defaultColumnConfig) => {
  const { formatter } = useFormattingContext()
  return useMemo(() => {
    const { columns, defaultSort } = columnConfig

    const mapColumns = columns => {
      if (!columns) return undefined
      return columns.map(column => {
        const formatString = column.format
        const type = column.type in typeLookup ? typeLookup[column.type] : {}
        return {
          ...column,
          ...type,
          type: undefined,
          // eslint-disable-next-line react/prop-types
          Cell: pickCell(column.type, formatter, formatString),
          columns: mapColumns(column.columns)
        }
      })
    }
    const templatedColumns = mapColumns(columns)

    return {
      columns: templatedColumns,
      defaultSort
    }
  }, [columnConfig, formatter])
}
