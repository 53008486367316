import { makeStyles } from '@material-ui/core'
import { mapButtonStyles } from '../shared/mapButtonStyles'

export const useLinkButtonStyles = makeStyles((theme) => ({
  commonDesignLinkButton: mapButtonStyles(theme, {
    textTransform: 'none',
    borderRadius: '10rem',
    padding: '2px 18px',
    fontWeight: theme.typography.weights.semibold,
    fontSize: theme.typography.fontSizes.medium,
    transition: 'background-color 200ms ease-in-out, color 200ms ease-in-out',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '8px'
  }),
  noUnderline: {
    '&:hover': {
      textDecoration: 'none !important'
    }
  }
}))
