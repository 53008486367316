export const getNewGroup = () => {
  return {
    editMode: true,
    components: [
      {
        componentAllocation: 0
      }
    ]
  }
}
