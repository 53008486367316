import React from 'react'
import { IconButton } from '@material-ui/core'
import Icon from '../../atoms/Icon'
import { ICON_NAMES } from '../../../constants'
import { useExportContext } from './ExportProvider'

const ExportButton = () => {
  const { toPdf, enabled } = useExportContext()

  if (!enabled) return null

  return (
    <IconButton
      aria-label='expand full view'
      aria-haspopup='true'
      color='inherit'
      onClick={toPdf}
    >
      <Icon
        customSize='1.2rem'
        name={ICON_NAMES.downloadView}
      />
    </IconButton>
  )
}

export default ExportButton
