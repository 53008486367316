import { useMemo } from 'react'
import TemplateConfigurationsActions from './TemplateConfigurationsActions'

export const useTemplatesConfigColumnConfig = ({ classes = {}, updateTemplateConfigurationForm }) => {
  return useMemo(() => ({
    columns: [
      { Header: 'Config Name', accessor: 'name', id: 'name', disableSortBy: false },
      {
        Header: 'Created By',
        accessor: 'createdBy',
        id: 'createdBy',
        disableSortBy: false,
        Cell: cell => cell.row.original.createdByFirstName + ' ' + cell.row.original.createdByLastName
      },
      {
        Header: 'Actions',
        accessor: 'runtimeConfigurationId',
        id: 'runtimeConfigurationId',
        disableSortBy: true,
        Cell: (cell) => TemplateConfigurationsActions({ row: cell.row, updateTemplateConfigurationForm })
      }
    ],
    defaultSort: [
      { id: 'createdDate', desc: true }
    ]
  }), [updateTemplateConfigurationForm])
}
