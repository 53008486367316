import React, { useCallback, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useLocation, useHistory } from 'react-router-dom'
import first from 'lodash/first'
import Tabs from '../../../../molecules/Tabs'
import { localStorageHelper } from '../../../../../utils/localStorageHelper'
import { LOCAL_STORAGE_KEYS } from '../../../../../constants'
import { useAdvisorViewTabGroup } from './advisorViewTabGroupContext'

const useTabHistory = ({ tabsKey, searchParamKey }) => {
  const { selectedValue, selectValue, options } = useAdvisorViewTabGroup()

  const history = useHistory()
  const location = useLocation()

  const localStorageKey = `${tabsKey}_${LOCAL_STORAGE_KEYS.tabLink}`
  const searchParams = useMemo(() => new URLSearchParams(location.search), [location.search])

  const selectedTabParam = useMemo(() => {
    return searchParams.has(searchParamKey)
      ? searchParams.get(searchParamKey)
      : localStorageHelper.load(localStorageKey) || null
  }, [searchParams, searchParamKey, localStorageKey])

  const onSelectValue = useCallback((value) => {
    selectValue(value)
    localStorageHelper.store(localStorageKey, value)
  }, [selectValue, localStorageKey])

  useEffect(() => {
    if (!selectedTabParam) {
      onSelectValue(first(options)?.value)
    } else if (selectedValue !== selectedTabParam) {
      onSelectValue(selectedTabParam)
    }
  }, [onSelectValue, selectedValue, selectedTabParam, options])

  const onChangeTab = useCallback((_, value) => {
    const currentSearch = Object.fromEntries([...searchParams])
    const params = new URLSearchParams({ ...currentSearch, tab: value })
    history.replace(`${location.pathname}?${params.toString()}`)
    onSelectValue(value)
  }, [history, location.pathname, onSelectValue, searchParams])

  return {
    onChangeTab,
    selectedValue,
    options
  }
}

/**
 * Tab Links
 * @param {string} key - used as localStorage key prefix
 * @param {JSX.Element} rightAdornment
 */
const TabLinks = ({
  tabsKey,
  searchParamKey,
  rightAdornment,
  disabled
}) => {
  const tabHistory = useTabHistory({
    tabsKey,
    searchParamKey: searchParamKey ?? `${tabsKey}_tab`
  })

  return (
    <Tabs.Wrapper noMargin>
      <Tabs
        value={tabHistory.selectedValue}
        indicatorColor='primary'
        textColor='primary'
        onChange={tabHistory.onChangeTab}
      >
        {tabHistory.options.map((o) => {
          return (
            <Tabs.Tab
              key={o.value}
              value={o.value}
              label={o.payload?.label || o.payload}
              disabled={o.payload?.disabled || disabled}
            />
          )
        })}
      </Tabs>
      {rightAdornment}
    </Tabs.Wrapper>
  )
}

TabLinks.propTypes = {
  tabsKey: PropTypes.string,
  searchParamKey: PropTypes.string,
  rightAdornment: PropTypes.node,
  disabled: PropTypes.bool
}

TabLinks.defaultProps = {
  tabsKey: '',
  rightAdornment: undefined,
  disabled: false
}

export default TabLinks
