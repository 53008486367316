import React, { forwardRef, useCallback, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import StyledDialog from '../../shared/StyledDialog'
import { useClientDetails } from '../ClientDetailsFormContext'
import { usePersonTypeOptions } from './usePersonTypeOptions'
import CreatePersonForm from './CreatePersonForm'

const useStyles = makeStyles((theme) => ({
  assignmentForm: {
    minHeight: '400px'
  }
}))

/**
 * Handles passing the form data into the onConfirm handler
 * @param onConfirm
 * @return {{formRef: React.MutableRefObject<undefined>, submitting: boolean, submitHandler: (function({invite: *}): function(*): Promise<void>)}}
 */
const useSubmitter = (onConfirm) => {
  const formRef = useRef()
  const [submitting, setSubmitting] = useState(false)
  const submitHandler = useCallback(async (e) => {
    const onValid = async (form) => {
      setSubmitting(true)
      try {
        await onConfirm({
          person: {
            firstName: form.person?.firstName || null,
            lastName: form.person?.lastName || null,
            email: form.person?.email || null,
            personTypeId: form.person?.personTypeId || false
          },
          clientIds: form.clientIds || []
        })
      } finally {
        setSubmitting(false)
      }
    }
    const onInvalid = (errors) => {
      console.error(errors)
      alert('There was a problem with your submission')
    }

    const handler = formRef.current.handleSubmit(onValid, onInvalid)
    await handler(e)
  }, [onConfirm, formRef, setSubmitting])

  return {
    submitting,
    submitHandler,
    formRef
  }
}

// Sorry that the form is so complicated. The place where the submit buttons are doesn't work well with the form
const CreatePersonDialog = forwardRef(function CreatePersonDialog ({ onConfirm }, ref) {
  const classes = useStyles()
  const { client } = useClientDetails()

  const { data: personTypeOptions, isLoading } = usePersonTypeOptions()
  const { formRef, submitHandler, submitting } = useSubmitter(onConfirm)

  return (
    <StyledDialog
      ref={ref}
      confirmText='Create Person'
      onConfirm={submitHandler}
      title='Create Person'
      size='md'
      processing={submitting}
      disabled={isLoading}
    >
      <div className={classes.assignmentForm}>
        {(isLoading) ? (
          <div>Please Wait</div>
        ) : (
          <CreatePersonForm
            ref={formRef}
            client={client}
            personTypeOptions={personTypeOptions}
          />
        )}
      </div>
    </StyledDialog>
  )
})

CreatePersonDialog.propTypes = {
  onConfirm: PropTypes.func
}

export default CreatePersonDialog
