import React, { useState, useMemo, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  Grid
} from '@material-ui/core'
import isEmpty from 'lodash/isEmpty'
import uniq from 'lodash/uniq'
import {
  LEVEL_TYPES,
  FILTER_TYPES,
  CALC_TYPES
} from '../../constants'
import { useToggle } from '../../hooks'
import useAbortController from '../../hooks/useAbortController'
import { useAppContext } from '../../redux/slices/appContext'
import { fetchAssetsSymbols, getAssetClassesV2 } from '../../service'
import {
  removeEmptyProps,
  formatTableRowValue
} from '../../utils'
import RoundedButton from '../atoms/RoundedButton'
import Text from '../atoms/Text'
import ReactTableInfinite from '../molecules/InfiniteTable'
import AssetReturnCell from '../atoms/AssetReturnCell'
import TableFilters from '../organisms/TableFilters'

import { getAdvisorAllocationTableColumns, formatCellValue, formatTableTopsRows } from '../../utils/tableHelper'
import useDateRangeTableFilter from '../../hooks/useDateRangeTableFilter'
import useExpandibleTable from '../../hooks/useExpandibleTable'

const DEFAULT_SEARCH_SYMBOLS_PAGE_SIZE = 10

const REPORT_DATE_RANGES = [
  {
    sourceKey: 'balanceInformationFirstColumn',
    unitValue: 3,
    unitType: 'month',
    operation: 'subtract'
  }
]

const AdvisorAllocationTable = ({
  dateRanges: _dateRanges,
  columns: _columns,
  fetchParams,
  advisorId
}) => {
  const abortController = useAbortController()
  const [dateRanges] = useState(_dateRanges || REPORT_DATE_RANGES)
  const { availableDates } = useAppContext()
  const [dataLevel, setDataLevel] = useState([LEVEL_TYPES.CLIENT])
  const [assetSymbols, setAssetSymbols] = useState([])
  const [isClientSelected, , setClientSelectedOn, setClientSelectedOff] = useToggle(true)
  const [loading, , toggleLoadingOn, toggleLoadingOff] = useToggle(true)
  const [searching, , toggleSearchingOn, toggleSearchingOff] = useToggle()
  const [errors, , toggleErrorsOn, toggleErrorsOff] = useToggle()

  const [selectedFilters, setSelectedFilters] = useState([])

  const { rows, columns, setRows, setColumns, modifyColumns } =
    useExpandibleTable({
      columns: !isEmpty(_columns)
        ? _columns
        : getAdvisorAllocationTableColumns(availableDates, isClientSelected)
    })

  useEffect(() => {
    if (setColumns) {
      setColumns(getAdvisorAllocationTableColumns(availableDates, isClientSelected))
    }
  }, [isClientSelected, setColumns, availableDates])

  const onClickClients = useCallback(() => {
    setDataLevel([LEVEL_TYPES.CLIENT])
    setClientSelectedOn()
  }, [setClientSelectedOn])

  const onClickAccounts = useCallback(() => {
    setDataLevel([LEVEL_TYPES.CLIENT, LEVEL_TYPES.ACCOUNTS])
    setClientSelectedOff()
  }, [setClientSelectedOff])

  const balanceInformationAsOfDateRange = useMemo(() => {
    return {
      startDate: availableDates.max,
      endDate: availableDates.max
    }
  }, [availableDates])

  const {
    // onHeaderFilterChange,
    dateRangeFiltersHydrated,
    isCustomDateRangeSelected
  } = useDateRangeTableFilter({
    columns,
    setColumns,
    modifyColumns,
    balanceInformationDateFilters: dateRanges
  })

  const fetchAssets = useCallback(
    async (filters, dataLevel) => {
      if (isCustomDateRangeSelected) {
        return
      }
      toggleErrorsOff()
      try {
        toggleLoadingOn()
        const signal = abortController()

        const { assetClassTagIds, assetIds } = filters.reduce(
          (acc, { id, filterType }) => {
            if (filterType === FILTER_TYPES.ASSET_CLASSES) {
              acc.assetClassTagIds.push(id)
            }
            if (filterType === FILTER_TYPES.SYMBOL) {
              acc.assetIds.push(id)
            }
            return acc
          },
          { assetClassTagIds: [], assetIds: [] }
        )

        const filterLevels = !isEmpty(assetIds) ? [LEVEL_TYPES.ASSETS] : []

        const commonParams = {
          levelTypes: [...dataLevel, ...filterLevels],
          assetIds: uniq(assetIds),
          assetClassTagIds,
          advisorIds: advisorId ? [advisorId] : []
        }

        const [{ data: balanceInformationAsOfDate }, { data: balanceInformationFirstColumn }] = await Promise.all([
          getAssetClassesV2(removeEmptyProps({
            ...commonParams,
            startDate: balanceInformationAsOfDateRange.startDate,
            endDate: balanceInformationAsOfDateRange.endDate,
            calcType: CALC_TYPES.balance
          }), signal),
          ...dateRangeFiltersHydrated.map(({ startDate, endDate }) => (getAssetClassesV2(removeEmptyProps({
            ...commonParams,
            startDate,
            endDate,
            calcType: CALC_TYPES.performance
          }), signal)))
        ])

        const rowsFormatted = formatTableTopsRows(
          balanceInformationAsOfDate,
          balanceInformationFirstColumn,
          isClientSelected
        )

        setRows(rowsFormatted)
      } catch (err) {
        console.error(err)
        toggleErrorsOn()
      } finally {
        toggleLoadingOff()
      }
    },
    [
      advisorId,
      isClientSelected,
      toggleErrorsOn,
      toggleErrorsOff,
      setRows,
      toggleLoadingOn,
      toggleLoadingOff,
      abortController,
      dateRangeFiltersHydrated,
      isCustomDateRangeSelected,
      balanceInformationAsOfDateRange
    ]
  )

  useEffect(() => {
    fetchAssets(selectedFilters, dataLevel)
  }, [selectedFilters, fetchAssets, dataLevel])

  const onSearchSymbol = useCallback(
    async (value = '') => {
      try {
        toggleSearchingOn()
        const { data: symbols } = await fetchAssetsSymbols({
          search: value,
          take: DEFAULT_SEARCH_SYMBOLS_PAGE_SIZE
        })
        setAssetSymbols(symbols)
      } catch (err) {
        console.error(err)
      } finally {
        toggleSearchingOff()
      }
    },
    [toggleSearchingOn, toggleSearchingOff]
  )

  const onRemoveAllFilters = useCallback(() => {
    setSelectedFilters([])
  }, [])

  const onAddFilter = useCallback((filterType, filtersIds) => {
    const newFilters = filtersIds.map((filterId) => ({
      id: filterId,
      filterType
    }))
    setSelectedFilters(newFilters)
  }, [])

  const instructions = useMemo(() => {
    return {
      [FILTER_TYPES.SYMBOL]: {
        options: assetSymbols,
        loading: searching,
        onChange: onSearchSymbol,
        optionValueKey: 'assetId',
        optionTitleKey: 'symbol',
        optionSubtitleKey: 'shortName',
        placeholder: 'Search for a symbol',
        label: 'Symbol'
      }
    }
  }, [
    assetSymbols,
    searching,
    onSearchSymbol
  ])

  // TODO Create date filter
  const COLUMNS = [
    {
      Header: 'Name',
      accessor: 'name'
    },
    {
      accessor: 'assetName',
      Header: 'Asset'
    },
    {
      accessor: 'units',
      Header: 'Quantity'
    },
    {
      accessor: 'totalValue',
      Header: 'Total Value',
      Cell: (cell) => {
        return formatCellValue(cell.value) ?? 0
      }
    },
    {
      accessor: 'netGain',
      Header: 'Net Gain',
      Cell: (cell) => {
        return formatCellValue(cell.value) ?? 0
      }
    },
    {
      accessor: 'netReturn',
      Header: 'Return',
      Cell: (cell) => {
        return (
          <AssetReturnCell
            tooltip={{
              title: formatTableRowValue(
                cell.value.balanceChangePercentage,
                '0.0000%',
                '0%'
              ),
              format: '0.0000%'
            }}
            balanceChangePositive={
              cell.value.balanceChangePositive
            }
            balanceChangePercentage={
              cell.value.balanceChangePercentage
            }
          />
        )
      }
    }
  ]

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Box display='flex' flexDirection='row'>
          <RoundedButton
            primary={isClientSelected}
            onClick={onClickClients}
            size='extraSmall'
          >
            Clients
          </RoundedButton>
          <Box ml={1}>
            <RoundedButton
              primary={!isClientSelected}
              onClick={onClickAccounts}
              size='extraSmall'
            >
              Accounts
            </RoundedButton>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box display='flex' flexDirection='row'>
          <TableFilters
            instructions={instructions}
            onSelectFilter={onAddFilter}
            onClearAllFilters={onRemoveAllFilters}
          />
        </Box>
      </Grid>
      {errors && (
        <Grid item xs={12}>
          <Box ml='10px' display='flex' flexDirection='row' alignItems='flex-start' justifyContent='flex-start'>
            <Text text='An error ocurred, please try again' color='red' />
          </Box>
        </Grid>
      )}
      <Grid item xs={12}>
        <ReactTableInfinite
          columns={COLUMNS}
          data={rows}
          isLoading={loading}
          rowCount={rows.length}
        />
      </Grid>
    </Grid>
  )
}

AdvisorAllocationTable.propTypes = {
  fetchParams: PropTypes.object,
  dateRanges: PropTypes.array,
  columns: PropTypes.array,
  advisorId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
}

export default AdvisorAllocationTable
