import React, { useCallback, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles, InputAdornment } from '@material-ui/core'
import Text from '../../../atoms/Text'
import { useSelectFilterContext } from '../SelectFilterProvider'
import FilterContainer from './FilterContainer'
import FilterSelect from './FilterSelect'
import FilterInput from './FilterInput'
import FilterButton from './FilterButton'

const COMPARISON_OPERATORS = {
  '=': 'Equal to',
  '>': 'Greater than',
  '<': 'Less than',
  '>=': 'Greater than or equal to',
  '<=': 'Less than or equal to',
  '!=': 'Not Equal to'
}

const defaultComparisonOptions = Object.entries(COMPARISON_OPERATORS).map(
  ([key, value]) => ({ label: value, value: key })
)

const inputProps = { type: 'number', min: 0 }

const useStyles = makeStyles(() => ({
  content: {
    minWidth: '0',
    display: 'flex',
    flexDirection: 'row',
    gap: '0.625rem',
    alignItems: 'center'
  },
  customSelect: {
    width: '100% !important'
  },
  buttonGroup: {
    gap: '0.5rem',
    display: 'flex',
    flexDirection: 'row'
  }
}))

const initialRangeValue = { startValue: '', endValue: '' }

const mapFilterPayload = ({
  value = '',
  optionValue,
  rangeValue = initialRangeValue
} = {}) => {
  return { value, optionValue, rangeValue }
}

const addAdornments = (startAdornment, endAdornment) => (value) => {
  return `${startAdornment || ''}${value}${endAdornment || ''}`
}

const getRangeValueLabel = (rangeValue, { startAdornment, endAdornment }) => {
  const labelWithAdornments = addAdornments(startAdornment, endAdornment)
  const startValueLabel = labelWithAdornments(rangeValue.startValue)
  const endValueLabel = labelWithAdornments(rangeValue.endValue)
  const displayLabel = `${startValueLabel}-${endValueLabel}`
  return displayLabel
}

const ComparisonOperatorsFilter = ({
  placeholder,
  startAdornment,
  endAdornment,
  comparisonOptions,
  defaultValue,
  defaultRangeValue,
  defaultOperatorValue
}) => {
  const classes = useStyles()
  const { onClearFilters, onApplyFilters } = useSelectFilterContext()

  const [value, setValue] = useState(defaultValue)
  const [rangeValue, setRangeValue] = useState(defaultRangeValue)
  const [selectedValue, setSelectedValue] = useState(defaultOperatorValue)

  const isCustomOptionSelected = selectedValue === 'custom'

  const inputEndAdornment = endAdornment
    ? <InputAdornment position='end'>{endAdornment}</InputAdornment>
    : undefined

  const inputStartAdornment = startAdornment
    ? <InputAdornment position='start'>{startAdornment}</InputAdornment>
    : undefined

  const onApply = useCallback(() => {
    const selectedOption = comparisonOptions.find(
      ({ value: _value }) => _value === selectedValue
    )
    if (!selectedOption) return

    let displayLabel = ''
    let filterMeta = null
    if (rangeValue.startValue && rangeValue.endValue) {
      displayLabel = getRangeValueLabel(rangeValue, {
        startAdornment,
        endAdornment
      })
      filterMeta = mapFilterPayload({
        rangeValue,
        optionValue: selectedValue
      })
    } else if (value) {
      displayLabel = `${selectedOption.label} ${value}`
      filterMeta = mapFilterPayload({
        value,
        rangeValue,
        optionValue: selectedValue
      })
    }
    onApplyFilters({ displayLabel, ...filterMeta })
  }, [
    value,
    rangeValue,
    endAdornment,
    selectedValue,
    onApplyFilters,
    startAdornment,
    comparisonOptions
  ])

  const filterActions = useMemo(() => {
    return (
      <div className={classes.buttonGroup}>
        <FilterButton onClick={onClearFilters} color='#D44333'>
          Clear
        </FilterButton>
        <FilterButton onClick={onApply}>Apply</FilterButton>
      </div>
    )
  }, [classes.buttonGroup, onApply, onClearFilters])

  const onChangeRangeValues = (rangeKey) => (e) => {
    const value = e.target.value
    setRangeValue((prevState) => {
      return {
        ...prevState,
        [rangeKey]: value
      }
    })
  }

  if (isCustomOptionSelected) {
    return (
      <FilterContainer>
        <div className={classes.content}>
          <FilterSelect
            fullWidth
            value={selectedValue}
            onChange={setSelectedValue}
            options={comparisonOptions}
            className={classes.customSelect}
          />
          {!isCustomOptionSelected && (
            <FilterInput
              value={value}
              placeholder={placeholder}
              onChange={(e) => setValue(e.target.value)}
              inputProps={inputProps}
              startAdornment={inputStartAdornment}
              endAdornment={inputEndAdornment}
            />
          )}
        </div>
        {isCustomOptionSelected && (
          <div className={classes.content}>
            <FilterInput
              value={rangeValue.startValue}
              placeholder={placeholder}
              onChange={onChangeRangeValues('startValue')}
              inputProps={inputProps}
              startAdornment={inputStartAdornment}
              endAdornment={inputEndAdornment}
            />
            <Text text='-' customFontWeight='bold' />
            <FilterInput
              value={rangeValue.endValue}
              placeholder={placeholder}
              onChange={onChangeRangeValues('endValue')}
              inputProps={inputProps}
              startAdornment={inputStartAdornment}
              endAdornment={inputEndAdornment}
            />
          </div>
        )}
        {filterActions}
      </FilterContainer>
    )
  }

  return (
    <FilterContainer>
      <div className={classes.content}>
        <FilterSelect
          value={selectedValue}
          onChange={setSelectedValue}
          options={comparisonOptions}
          className={isCustomOptionSelected ? classes.customSelect : null}
        />
        <FilterInput
          value={value}
          placeholder={placeholder}
          onChange={(e) => setValue(e.target.value)}
          inputProps={inputProps}
          startAdornment={inputStartAdornment}
          endAdornment={inputEndAdornment}
        />
      </div>
      {filterActions}
    </FilterContainer>
  )
}

ComparisonOperatorsFilter.propTypes = {
  placeholder: PropTypes.string,
  comparisonOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    })
  ),
  endAdornment: PropTypes.string,
  startAdornment: PropTypes.string,
  defaultValue: PropTypes.string,
  defaultRangeValue: PropTypes.object,
  defaultOperatorValue: PropTypes.string
}

ComparisonOperatorsFilter.defaultProps = {
  placeholder: '0,000.00',
  comparisonOptions: [
    ...defaultComparisonOptions,
    { label: 'Custom', value: 'custom' }
  ],
  endAdornment: undefined,
  startAdornment: undefined,
  defaultValue: '',
  defaultRangeValue: initialRangeValue,
  defaultOperatorValue: defaultComparisonOptions?.[0]?.value
}

export default ComparisonOperatorsFilter
