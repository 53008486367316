import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { Box } from '@material-ui/core'
import { isEmpty } from 'lodash'
import { useHistory } from 'react-router-dom'
import OperationalTable, {
  useOperationalTable
} from '../../../OperationalTable'
import { useMilestonesContext } from '../../MilestonesContext'
import DateRangeSelector from '../DateRangeSelector'
import MilestonesSearchBar from '../MilestonesSearchBar'
import MilestonesEmptyState from '../MilestonesEmptyState'
import { useSetCurrentClient } from '../../../../../redux/slices/appContext'
import { useColumnConfig } from './columnConfig'

const MilestonesTable = ({ columnConfig }) => {
  const history = useHistory()
  const setCurrentClient = useSetCurrentClient()
  const { columns, defaultSort } = useColumnConfig(columnConfig)
  const {
    milestones,
    isLoading,
    milestonesTotal,
    paginationData,
    setPaginationData,
    milestoneClickRedirectTo
  } = useMilestonesContext()

  const {
    defaultPageSize,
    onTableModeChange,
    onSortingChange: _onSortingChange
  } = useOperationalTable({
    defaultSort,
    defaultPageSize: paginationData.take
  })

  const onRowClick = useCallback(({ original }) => {
    if (milestoneClickRedirectTo) {
      original?.client && setCurrentClient(original.client.clientId)
      history.push(milestoneClickRedirectTo)
    }
  }, [history, setCurrentClient, milestoneClickRedirectTo])

  const onSortingChange = useCallback(
    (sortBy) => {
      _onSortingChange(sortBy)
      const sort = sortBy.map(({ id, desc }) => ({
        field: id,
        dir: desc ? 'desc' : 'asc'
      }))
      setPaginationData((prevState) => ({
        ...prevState,
        sort: sort
      }))
    },
    [_onSortingChange, setPaginationData]
  )

  const onPagingChange = useCallback(
    ({ pageSize, pageIndex }) => {
      setPaginationData((prevState) => ({
        ...prevState,
        skip: pageSize * pageIndex
      }))
    },
    [setPaginationData]
  )

  return (
    <OperationalTable.Wrapper>
      <OperationalTable.SuperHeader>
        <Box py={2}>
          <DateRangeSelector />
        </Box>
        <MilestonesSearchBar />
      </OperationalTable.SuperHeader>
      {!isLoading && isEmpty(milestones) ? (
        <MilestonesEmptyState />
      ) : (
        <OperationalTable
          mode='auto'
          itemName='Milestones'
          columns={columns}
          data={milestones}
          loading={isLoading}
          onRowClick={onRowClick}
          total={milestonesTotal}
          defaultSort={defaultSort}
          showTotalItemsLabel={false}
          autoLimit={defaultPageSize}
          onPagingChange={onPagingChange}
          defaultPageSize={defaultPageSize}
          onSortingChange={onSortingChange}
          onTableModeChange={onTableModeChange}
          autoSticky
        />
      )}
    </OperationalTable.Wrapper>
  )
}

MilestonesTable.propTypes = {
  columnConfig: PropTypes.object
}

MilestonesTable.defaultProps = {
  columnConfig: undefined
}

export default MilestonesTable
