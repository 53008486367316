import { useCallback, useEffect, useMemo, useReducer } from 'react'
import { capitalizeFirstLetter } from '../utils'

const initState = {
  total: 0,
  data: [],
  loading: false,
  isEmpty: false
}

const initialState = {
  assignedEntities: { ...initState },
  unAssignedEntities: { ...initState },
  selectedEntities: { assigned: [], unAssigned: [] }
}

const filterEntitiesById = (entities, entityId) => {
  return entities.filter(({ id }) => id !== parseInt(entityId))
}

const reducer = (state, action) => {
  if (action.type === 'reset') {
    return {
      ...state,
      unAssignedEntities: { ...initialState.unAssignedEntities },
      selectedEntities: { ...initialState.selectedEntities }
    }
  }
  return {
    ...state,
    [action.type]: {
      ...state[action.type],
      ...action.payload
    }
  }
}

const useAssignUnAssignEntities = ({ resetState = false }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  const { assignedEntities, unAssignedEntities, selectedEntities } = state

  const { setAssignedEntities, setUnAssignedEntities, setSelectedEntities } =
    useMemo(() => {
      const actions = Object.keys(initialState).reduce(
        (acc, key) => {
          const keyAccessor = `set${capitalizeFirstLetter(key)}`
          return {
            ...acc,
            [keyAccessor]: (payload) => dispatch({ type: key, payload })
          }
        },
        {}
      )
      return actions
    }, [])

  useEffect(() => {
    if (resetState) {
      dispatch({ type: 'reset' })
    }
  }, [resetState, setSelectedEntities, setUnAssignedEntities])

  const assignedEntitiesKeyValuePair = useMemo(() => {
    return assignedEntities.data.reduce(
      (acc, entity) => ({
        ...acc,
        [entity.id]: { ...entity }
      }),
      {}
    )
  }, [assignedEntities.data])

  const searchResultOptions = useMemo(() => {
    const selectedAccountIds = selectedEntities.assigned.map(({ id }) => id)
    const unAssignedItems = unAssignedEntities.data.filter(
      ({ id }) => !assignedEntitiesKeyValuePair[id]
    )
    const options = unAssignedItems.map((account) => {
      const { id } = account
      const checked = selectedAccountIds.includes(id)
      return {
        id,
        checked,
        payload: { ...account }
      }
    })
    return options
  }, [
    assignedEntitiesKeyValuePair,
    unAssignedEntities.data,
    selectedEntities.assigned
  ])

  const persistEntitiesSelection = useCallback(() => {
    const { assigned, unAssigned } = selectedEntities

    const assignedIds = assigned.map(({ id }) => id)
    const unAssignedIds = unAssigned.map(({ id }) => id)

    const entities = [
      ...[...assignedEntities.data, ...assigned].filter(
        ({ id }) => !unAssignedIds.includes(id)
      ),
      ...[...unAssignedEntities.data, ...unAssigned].filter(({ id }) =>
        assignedIds.includes(id)
      )
    ]
    const entitiesMap = new Map()
    entities.forEach((item) => entitiesMap.set(item.id, item))
    const newAccountsAssigned = [...entitiesMap.values()]

    setAssignedEntities({
      data: newAccountsAssigned,
      total: newAccountsAssigned.length
    })
  }, [
    selectedEntities,
    setAssignedEntities,
    assignedEntities.data,
    unAssignedEntities.data
  ])

  const onAssignUnAssignEntity = useCallback(
    ({ entityId, isAssign, entity }) => {
      const {
        unAssigned: prevUnassigned,
        assigned: prevAssigned
      } = selectedEntities

      const unAssigned = isAssign
        ? filterEntitiesById(prevUnassigned, entityId)
        : [...prevUnassigned, { ...entity }]

      const assigned = isAssign
        ? [...prevAssigned, { ...entity }]
        : filterEntitiesById(prevAssigned, entityId)

      setSelectedEntities({ assigned, unAssigned })
    },
    [selectedEntities, setSelectedEntities]
  )

  return {
    assignedEntities,
    setAssignedEntities,
    unAssignedEntities,
    setUnAssignedEntities,
    selectedEntities,
    setSelectedEntities,
    onAssignUnAssignEntity,
    persistEntitiesSelection,
    searchResultOptions
  }
}

export default useAssignUnAssignEntities
