import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles, Tooltip } from '@material-ui/core'
import { useFormattingContext } from '../../../../../organisms/FormattingProvider/FormattingContext'
import Section from '../shared/Section'

const useStyles = makeStyles((theme) => ({
  table: {
    borderCollapse: 'collapse',
    width: '100%',
    '& tr td': {
      fontWeight: theme.typography.weights.light
    },
    '& tr td:first-child': {
      paddingLeft: theme.layout.padding.thin
    },
    '& tbody tr.__selected td': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText
    }
  }
}))

function PositionSummaryList ({ positions }) {
  const classes = useStyles()
  const { formatter } = useFormattingContext()
  if (!positions?.length) {
    return null
  }

  return (
    <Section>
      <header>Positions</header>
      <table className={classes.table}>
        <thead>
          <tr>
            <th>Asset Name</th>
            <th>Asset Identifier</th>
            <th>Class</th>
            <th>Units</th>
            <th>Ending Value</th>
          </tr>
        </thead>
        <tbody>
          {positions.map(p => (
            <tr key={p.assetId}>
              <td>
                <Tooltip title={p.assetName} placement='top'>
                  <div>{p.assetName?.slice(0, 50)}</div>
                </Tooltip>
              </td>
              <td>{p.assetIdentifier}</td>
              <td>{p.classification}</td>
              <td>{formatter(p.units, 'number')}</td>
              <td>{formatter(p.endingValue, 'marketValue')}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </Section>
  )
}

PositionSummaryList.propTypes = {
  positions: PropTypes.array
}

export default PositionSummaryList
