import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles, Grid, TextField } from '@material-ui/core'
import FadeIn from '../../../../molecules/Transitions/FadeIn'

const useStyles = makeStyles((theme) => ({
  userInfoForm: {
    maxWidth: '800px',
    display: 'flex',
    justifyContent: 'center',
    padding: '10px',
    '& p': {
      color: `${theme.palette.primary.dark}`
    }
  },
  inputLabel: {
    color: theme.palette.black,
    fontWeight: 600,
    '& span': {
      color: theme.palette.black
    }
  }
}))

function AdvisorUserInfoDisplay ({ user }) {
  const classes = useStyles()
  const labelProps = {
    className: classes.inputLabel
  }

  return (
    <FadeIn className={classes.userInfoForm}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12}>
          <TextField
            label='First Name'
            fullWidth
            disabled
            value={user.firstName}
            InputProps={{ disableUnderline: true }}
            InputLabelProps={labelProps}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            label='Last Name'
            fullWidth
            disabled
            value={user.lastName}
            InputProps={{ disableUnderline: true }}
            InputLabelProps={labelProps}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            label='Email'
            fullWidth
            disabled
            value={user.email}
            InputProps={{ disableUnderline: true }}
            InputLabelProps={labelProps}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            label='External User Id'
            fullWidth
            disabled
            value={user.externalUserId}
            InputProps={{ disableUnderline: true }}
            InputLabelProps={labelProps}
          />
        </Grid>
      </Grid>
    </FadeIn>
  )
}

AdvisorUserInfoDisplay.propTypes = {
  user: PropTypes.shape({
    userId: PropTypes.number,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    externalUserId: PropTypes.string
  })
}

export default AdvisorUserInfoDisplay
