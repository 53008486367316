import KeyMirror from 'keymirror'

export const WEALTH_JOURNEY_POLICIES = KeyMirror({
  wj_view_milestones: null,
  wj_edit_milestones: null,
  wj_view_mtgnotes: null,
  wj_edit_mtgnotes: null,
  wj_view_activities: null,
  wj_edit_activities: null
})
