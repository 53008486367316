import React, { createElement, useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import {
  setStepNavigation,
  setToggleIsOpen,
  useAddAccountContext,
  useGetCurrentStep,
  useGetPreviousStep
} from '../../../redux/slices/addAccountContext'
import SydModal from '../../commonDesign/SydModal'
import SydButton from '../../commonDesign/Button'
import { clearSuppression, setSuppression } from '../../../utils/globalSuppression'

const AddAccountModal = () => {
  const dispatch = useDispatch()
  const [isExpanded, setIsExpanded] = useState(false)

  const addAccountContext = useAddAccountContext()
  const { isOpen, selectedCategory } = addAccountContext
  const currentStep = useGetCurrentStep()
  const previousStep = useGetPreviousStep()

  const onClose = useCallback(() => {
    dispatch(setToggleIsOpen({ isOpen: false }))
  }, [dispatch])

  const currentTitle = useMemo(() => {
    let title = currentStep?.title ?? 'Manage Accounts'
    const subtitle = currentStep?.subtitle ?? null
    if (currentStep?.key === 'manual_editDetails' && selectedCategory) {
      title = `${title} in ${selectedCategory.shortName}`
    }
    return {
      title,
      subtitle
    }
  }, [currentStep, selectedCategory])

  useEffect(() => {
    if (isOpen) {
      setSuppression('wheel', true)
    } else {
      clearSuppression('wheel')
    }
  }, [isOpen])

  return (
    <>
      <SydModal
        open={isOpen}
        onClose={onClose}
        size='xmedium'
        title={currentTitle.title}
        subtitle={currentTitle.subtitle}
        allowMaximize
        onExpand={setIsExpanded}
      >
        {previousStep && (
          <div style={{ marginTop: '-1rem' }}>
            <SydButton
              onClick={() => dispatch(setStepNavigation({ key: previousStep.key }))}
              icon='back'
              variant='ghost'
              size='sm'
            >
              Back to {previousStep.title}
            </SydButton>
          </div>
        )}
        {currentStep && createElement(currentStep.component, { isExpanded })}
      </SydModal>
    </>
  )
}

export default AddAccountModal
