import React, { useCallback, useImperativeHandle, useMemo, useState } from 'react'
import RoundedModal from '../../../../molecules/RoundedModal'
import EditTemplateItemForm from './EditTemplateItemForm'

const EditTemplateItemDialog = React.forwardRef(function EditTemplateItemDialog (_, ref) {
  const [dialogState, setDialogState] = useState({ open: false, processing: false })
  const close = useCallback(() => {
    setDialogState({ open: false, processing: false })
  }, [setDialogState])

  useImperativeHandle(ref, () => ({
    open: (item) => setDialogState({ open: true, processing: false, item })
  }), [setDialogState])

  const templateItem = useMemo(() => {
    return dialogState.item
  }, [dialogState])

  return (
    <RoundedModal
      title={`Edit Template Item ${templateItem?.statusTemplateItemId}`}
      open={dialogState.open}
      onClose={close}
      position='top'
      disablePortal={false}
    >
      <div>
        {templateItem ? (
          <EditTemplateItemForm templateItem={templateItem} onCancel={close} onComplete={close} />
        ) : null}
      </div>
    </RoundedModal>
  )
})

EditTemplateItemDialog.propTypes = {
}

export default EditTemplateItemDialog
