import React, { useMemo } from 'react'
import dayjs from 'dayjs'
import numeral from 'numeral'
import { first, isEmpty } from 'lodash'
import { localStorageHelper } from '../../../../utils/localStorageHelper'
import { toTitleCase } from './helpers'

export const cellTemplates = {
  /* eslint-disable-next-line jsx-a11y/anchor-is-valid */
  date: ({ value }) => dayjs.utc(value).format('M/D/YYYY'),
  altDate: ({ value }) => dayjs.utc(value).format('MM/DD/YYYY'),
  number: ({ value }) => value,
  // eslint-disable-next-line eqeqeq
  units: ({ value }) => numeral(value).format('0,0.00'),
  money: ({ value }) => numeral(value).format('0,0.00'),
  dollar: ({ value }) => numeral(value).format('$0,000.00'),
  percentage: ({ value }) => numeral(value).format('0.00%'),
  // wholePercentage: ({ value }) => numeral(value).format('0%'),
  tags: ({ value }) => (
    <span>
      {value.map((x) => (
        <span
          style={{
            border: '1px solid gray',
            padding: '2px',
            marginRight: '2px'
          }}
          key={x.transactionCodeTagId}
        >
          {x.displayName}
        </span>
      ))}
    </span>
  ),
  noWrap: ({ value }) => <span style={{ whiteSpace: 'nowrap' }}>{value}</span>
}

export const cellTemplateWrapper = (cellTemplate, decorators) => {
  const { useSymbolForZeroOrNull } = decorators
  return ({
    value,
    ...row
  }) => {
    let valueFormatted = value
    if (useSymbolForZeroOrNull) {
      valueFormatted = !value ? useSymbolForZeroOrNull : value
      if (valueFormatted !== value) {
        return cellTemplates.noWrap({ value: valueFormatted })
      }
    }
    return cellTemplate({
      ...row,
      value: valueFormatted
    })
  }
}

export const defaultColumnConfig = {
  client: {
    columns: [
      {
        Header: 'Client Id',
        accessor: 'clientId'
      },
      {
        Header: 'Name',
        accessor: 'clientName'
      },
      {
        Header: 'As Of',
        accessor: 'maxAsOfDate',
        template: 'date',
        alignRight: true
      },
      {
        Header: 'Account Count',
        accessor: 'exposedAccountCount',
        alignRight: true
      },
      {
        Header: 'Market Value',
        accessor: r => r.balance?.endingValue,
        id: 'endingValue',
        alignRight: true,
        template: 'money'
      },
      {
        Header: 'Net Gain',
        accessor: r => r.performance?.netChange,
        id: 'netChange',
        alignRight: true,
        template: 'money'
      },
      {
        Header: 'Return',
        accessor: r => r.performance?.cumulativeReturn,
        id: 'return',
        alignRight: true,
        template: 'percentage'
      }
    ],
    defaultSort: [{
      id: 'return',
      desc: true
    }]
  },
  account: {
    columns: [
      {
        Header: 'Account Id',
        accessor: 'accountId'
      },
      {
        Header: 'Name',
        accessor: 'accountName'
      },
      {
        Header: 'Number',
        accessor: 'accountNumber'
      },
      {
        Header: 'Custodian',
        accessor: 'custodian'
      },
      {
        Header: 'As Of',
        accessor: 'asOfDate',
        template: 'date',
        rightAlign: true
      },
      {
        Header: 'Market Value',
        accessor: r => r.balance?.endingValue,
        id: 'endingValue',
        alignRight: true,
        template: 'money'
      },
      {
        Header: 'Net Gain',
        accessor: r => r.performance?.netChange,
        id: 'netChange',
        alignRight: true,
        template: 'money'
      },
      {
        Header: 'Return',
        accessor: r => r.performance?.cumulativeReturn,
        id: 'return',
        alignRight: true,
        template: 'percentage'
      }
    ],
    defaultSort: [{
      id: 'return',
      desc: true
    }]
  }
}

const getLevelColumns = (reportParams) => {
  console.log('getLevelColumns(reportParams)', reportParams)
  const levelType = Array.isArray(reportParams.levelType)
    ? first(reportParams.levelType)
    : reportParams.levelType

  if (levelType === 'account') {
    return [
      {
        Header: 'Account ID',
        accessor: (r) => {
          if ('accountId' in r) {
            return r.accountId
          }

          return r.levelId ?? r.balance?.accountId
        },
        id: 'levelId',
        // not really used by the table, keep it so
        // we know which accessors are being used
        accessors: ['levelId', 'balance.accountId']
      },
      {
        Header: 'Account Name',
        accessor: (r) => {
          if ('accountName' in r) {
            return r.accountName
          }

          return r.levelName ?? r.balance?.accountName
        },
        id: 'levelName',
        // not really used by the table, keep it so
        // we know which accessors are being used
        accessors: ['levelName', 'balance.accountName']
      },
      {
        Header: 'Account #',
        accessor: (r) => {
          const rdr = reportParams.dateRangePreset

          if ('accountNumber' in r) {
            return r.accountNumber
          } else if ('performance' in r) {
            return r.performance.accountNumber
          } else if ('balance' in r) {
            return r.balance.accountNumber
          } else if ('ugl' in r) {
            return r.ugl.accountNumber
          } else if ('rgl' in r) {
            return r.rgl.accountNumber
          } else if ((rdr in r) && ('accountNumber' in r[rdr])) {
            return r[rdr].accountNumber
          }

          return null
        },
        resolveAccessor (reportParams) {
          const balanceOnly = reportParams.dataSets.length === 1 && reportParams.dataSets.includes('balance')
          if (balanceOnly) {
            return 'balance.accountNumber'
          } else if (reportParams.dataSets.includes('performance')) {
            return 'performance.accountNumber'
          } else if (reportParams.dataSets.includes('ugl')) {
            return 'ugl.accountNumber'
          } else if (reportParams.dataSets.includes('rgl')) {
            return 'rgl.accountNumber'
          }

          return this.accessors[0]
        },
        id: 'accountNumber',
        // not really used by the table, keep it so
        // we know which accessors are being used
        accessors: [
          'accountNumber',
          'performance.accountNumber',
          'balance.accountNumber',
          'ugl.accountNumber',
          'rgl.accountNumber'
        ]
      }
    ]
  }

  if (levelType === 'client') {
    return [
      {
        Header: 'Client ID',
        accessor: (r) => {
          if ('levelId' in r) {
            return r.levelId
          } else if ('performance' in r) {
            return r.performance.clientId
          } else if ('balance' in r) {
            return r.balance.clientId
          }

          return null
        },
        // not really used by the table, keep it so
        // we know which accessors are being used
        accessors: ['levelId', 'performance.clientId', 'balance.clientId'],
        resolveAccessor (reportParams) {
          const balanceOnly = reportParams.dataSets.length === 1 && reportParams.dataSets.includes('balance')
          if (balanceOnly) {
            return 'balance.clientId'
          }

          return this.accessors[0]
        }
      },
      {
        Header: 'Client Name',
        accessor: (r) => {
          if ('levelName' in r) {
            return r.levelName
          } else if ('performance' in r) {
            return r.performance.clientName
          } else if ('balance' in r) {
            return r.balance.clientName
          }
          return null
        },
        // not really used by the table, keep it so
        // we know which accessors are being used
        accessors: [
          'levelName',
          'performance.clientName',
          'balance.clientName'
        ],
        resolveAccessor (reportParams) {
          const balanceOnly = reportParams.dataSets.length === 1 && reportParams.dataSets.includes('balance')
          if (balanceOnly) {
            return 'balance.clientName'
          }

          return this.accessors[0]
        }
      }
    ]
  }

  throw new Error('Invalid level type')
}

const replaceColumnIdWithDateRangePreset = (
  dateRangePreset,
  columns,
  dataSetKey,
  includeDateRangeIntoColHeader = false
) => {
  const dateRangePresets = Array.isArray(dateRangePreset)
    ? dateRangePreset
    : [dateRangePreset]

  return dateRangePresets.reduce((acc, dateRange) => {
    const performanceColumns = Object.fromEntries(
      Object.entries(columns).map(([key, { label, ...value }]) => {
        const dateRangePresetKey = key.replace(dataSetKey, dateRange)
        let header = label
        if (label && includeDateRangeIntoColHeader) {
          header = `${dateRange} ${label}`
        }
        return [dateRangePresetKey, { ...value, label: header }]
      })
    )
    return {
      ...acc,
      ...performanceColumns
    }
  }, {})
}

export const getDataSetColumns = ({ reportParams }, settings, data = []) => {
  const { includeDateRangeIntoColHeader = false } = settings || {}
  let columns = []
  let hiddenColumns = []
  const { dataSets, dateRangePreset } = reportParams

  if (dataSets.includes('balance')) {
    const [defaultCols, nonDefaultCols] = getDefaultColumns(balanceColumns)
    hiddenColumns = hiddenColumns.concat(nonDefaultCols)
    columns = columns.concat(defaultCols)
  }
  if (dataSets.includes('performance')) {
    const [defaultCols, nonDefaultCols] = getDefaultColumns(
      replaceColumnIdWithDateRangePreset(
        dateRangePreset,
        performanceColumns,
        'performance',
        includeDateRangeIntoColHeader
      )
    )
    hiddenColumns = hiddenColumns.concat(nonDefaultCols)
    columns = columns.concat(defaultCols)
  }
  if (dataSets.includes('balance') || dataSets.includes('performance')) {
    if (reportParams.levelType === 'account') {
      const accountAdditionalColumns = getAccountsAdditionalColumns('balance', data)
      const [defaultCols, nonDefaultCols] = getDefaultColumns(accountAdditionalColumns)
      hiddenColumns = hiddenColumns.concat(nonDefaultCols)
      columns = columns.concat(defaultCols)
    }
  }
  if (dataSets.includes('ugl')) {
    const [defaultCols, nonDefaultCols] = getDefaultColumns(uglColumns)
    hiddenColumns = hiddenColumns.concat(nonDefaultCols)
    columns = columns.concat(defaultCols)
  }
  if (dataSets.includes('rgl')) {
    const [defaultCols, nonDefaultCols] = getDefaultColumns(
      replaceColumnIdWithDateRangePreset(dateRangePreset, rglColumns, 'rgl')
    )
    hiddenColumns = hiddenColumns.concat(nonDefaultCols)
    columns = columns.concat(defaultCols)
  }
  if (dataSets.includes('projectedIncome')) {
    const [defaultCols, nonDefaultCols] = getDefaultColumns(projectedIncomeColumns)
    hiddenColumns = hiddenColumns.concat(nonDefaultCols)
    columns = columns.concat(defaultCols)
  }
  return [columns, hiddenColumns]
}

export const mapColumnTemplates = (columns, decorators) =>
  columns.map((c) => {
    if ('template' in c) {
      const template = cellTemplateWrapper(
        cellTemplates[c.template],
        decorators
      )
      return {
        ...c,
        Cell: template
      }
    }
    return c
  })

const getTagOptions = (data, levelType) => {
  const tags = data.reduce((acc, datum) => {
    const keyValueTags = Object.entries(datum?.tags?.[levelType]).reduce(
      (prev, [tagKey, tagValue]) => {
        return {
          ...prev,
          [tagKey]: {
            ...(prev?.[tagKey] || {}),
            [tagValue]: null
          }
        }
      },
      acc
    )
    return {
      ...acc,
      ...keyValueTags
    }
  }, {})
  return Object.entries(tags).reduce((acc, [tag, value]) => ({
    ...acc,
    [tag]: Object.keys(value).map(val => ({
      /* this used to be forced to be camelCase, but filtering broke because of that
         the back-end expects that the value being searched for matches exactly.
       */
      value: val,
      label: val
    }))
  }), {})
}

export const getTagColumns = (data, levelType) => {
  if (isEmpty(data)) return []

  const [{ tags = {} }] = data
  if (isEmpty(tags)) return []

  const tagOptions = getTagOptions(data, levelType)

  const tagColumns = Object.keys(tags[levelType] || {}).map((key) => {
    const accessor = `tags.${levelType}.${key}`
    const filterType = tagOptions?.[key] ? {
      type: 'list',
      options: tagOptions?.[key]
    } : null

    return {
      id: accessor,
      accessor,
      Header: toTitleCase(key),
      hidden: true, // hiding tag columns by default
      ...(filterType ? { filterType } : {})
    }
  })
  return tagColumns
}

export const getReportColumns = (reportParams, extraColumns, settings, data = []) => {
  const [defaultColumns, hiddenColumns] = getDataSetColumns({ reportParams }, settings, data)

  const baseColumns = [
    ...getLevelColumns(reportParams),
    ...defaultColumns,
    ...hiddenColumns.map((col) => ({ ...col, hidden: true })),
    ...(extraColumns ?? [])
  ]

  return [baseColumns, hiddenColumns]
}

const getStoredTableConfig = (storageKey) => {
  const tableConfiguration = localStorageHelper.load(storageKey) || {}
  const { hiddenColumns = [], columnOrder = [] } = tableConfiguration || {}
  return { hiddenColumns, columnOrder }
}

const getColumnFilters = (columns, storageConfigKey, levelType) => {
  const { hiddenColumns = [], columnOrder = [] } = getStoredTableConfig(storageConfigKey)

  const hiddenColumnsByLevelType = hiddenColumns?.filter((colId) =>
    colId.includes(levelType)
  )

  const visibleColumnIds = columns.reduce((acc, col) => {
    if (col?.hidden) return acc
    return [...acc, col.id]
  }, [])

  const columnFilters = columns.reduce((acc, col) => {
    if (!col?.filterType) return acc

    return {
      ...acc,
      [col.id]: {
        id: col.id,
        label: col.Header,
        filterType: col.filterType,
        visible:
          visibleColumnIds.includes(col.id) ||
          (
            columnOrder.includes(col.id) &&
            !hiddenColumnsByLevelType.includes(col.id)
          ) ||
          !col.hidden
      }
    }
  }, [])

  return columnFilters
}

export const useColumns = (data, reportParams, storageConfigKey) => {
  const tagColumns = useMemo(() => {
    if (isEmpty(data)) return []
    const { levelType } = reportParams
    return getTagColumns(data, levelType)
  }, [data, reportParams])

  const columnConfig = useMemo(() => {
    const { includeTags, metadata } = reportParams
    const defaultSort = [{
      id: 'levelId',
      desc: false
    }]

    if (isEmpty(tagColumns) && includeTags) {
      return { columns: [], defaultSort }
    }
    const [baseColumns, hiddenColumns] = getReportColumns(reportParams, tagColumns, {}, data)
    const decorators = { useSymbolForZeroOrNull: '--' }

    const templatedColumns = mapColumnTemplates(baseColumns, decorators)

    const { columns: metaColumnPresets } = metadata

    const columnFilterPresets = getColumnFilters(
      templatedColumns,
      storageConfigKey,
      reportParams.levelType
    )

    const { columnOrder: columnPresets } = getStoredTableConfig(
      storageConfigKey
    )

    const columns = templatedColumns.map((column) => {
      let hidden = column?.hidden

      if (metaColumnPresets && metaColumnPresets.includes(column.id)) {
        hidden = false
      }

      if (columnPresets && columnPresets.includes(column.id) && hidden === undefined) {
        hidden = false
      }

      return {
        ...column,
        hidden
      }
    })

    return {
      columns,
      defaultSort,
      hiddenColumns,
      columnFilters: columnFilterPresets
    }
  }, [data, reportParams, tagColumns, storageConfigKey])

  return columnConfig
}

export const balanceColumns = {
  'balance.assetClassLongName': {
    label: 'Asset Class (Long Name)',
    default: false,
    exclude: true
  },
  'balance.assetClassName': {
    label: 'Asset Class Name',
    default: false,
    exclude: true
  },
  'balance.allocation': {
    label: 'Allocation',
    default: false,
    exclude: true,
    template: 'percentage',
    filterType: 'percentage',
    alignRight: true
  },
  'balance.benchmarkValue': {
    label: 'Benchmark Value',
    default: false,
    exclude: true,
    template: 'percentage',
    filterType: 'percentage',
    alignRight: true
  },
  'balance.endingValue': {
    label: 'Ending Value',
    default: true,
    template: 'money',
    filterType: 'numerical',
    alignRight: true
  },
  'balance.income': {
    label: 'Income',
    default: false,
    exclude: true,
    template: 'money',
    filterType: 'numerical',
    alignRight: true
  },
  'balance.transactionCount': {
    label: 'Transaction Count',
    default: false,
    exclude: true,
    template: 'units',
    alignRight: true
  },
  'balance.units': {
    label: 'Units',
    default: false,
    template: 'units',
    exclude: true,
    alignRight: true
  },
  'balance.withdrawals': {
    label: 'Withdrawals',
    default: false,
    exclude: true,
    template: 'money',
    filterType: 'numerical',
    alignRight: true
  },
  'balance.additions': {
    label: 'Additions',
    default: false,
    exclude: true,
    template: 'money',
    filterType: 'numerical',
    alignRight: true
  },
  'balance.accrual': {
    label: 'Accrual',
    default: false,
    template: 'units',
    alignRight: true,
    filterType: 'numerical'
  }
}

export const exposedBalanceColumns = {
  'exposedBalance.assetClassLongName': {
    label: 'Asset Class (Long Name)',
    default: false,
    exclude: true
  },
  'exposedBalance.assetClassName': {
    label: 'Asset Class Name',
    default: false,
    exclude: true
  },
  'exposedBalance.allocation': {
    label: 'Allocation',
    default: false,
    exclude: true,
    template: 'percentage',
    filterType: 'percentage',
    alignRight: true
  },
  'exposedBalance.benchmarkValue': {
    label: 'Benchmark Value',
    default: false,
    exclude: true,
    template: 'percentage',
    filterType: 'percentage',
    alignRight: true
  },
  'exposedBalance.endingValue': {
    label: 'Ending Value',
    default: true,
    template: 'money',
    filterType: 'numerical',
    alignRight: true
  },
  'exposedBalance.income': {
    label: 'Income',
    default: false,
    template: 'money',
    filterType: 'numerical',
    exclude: true,
    alignRight: true
  },
  'exposedBalance.transactionCount': {
    label: 'Transaction Count',
    default: false,
    template: 'units',
    exclude: true,
    alignRight: true
  },
  'exposedBalance.units': {
    label: 'Units',
    default: false,
    template: 'units',
    exclude: true,
    alignRight: true
  },
  'exposedBalance.withdrawals': {
    label: 'Withdrawals',
    default: false,
    exclude: true,
    template: 'money',
    filterType: 'numerical',
    alignRight: true
  },
  'exposedBalance.additions': {
    label: 'Additions',
    default: false,
    exclude: true,
    template: 'money',
    filterType: 'numerical',
    alignRight: true
  },
  'exposedBalance.accrual': {
    label: 'Accrual',
    default: false,
    alignRight: true,
    template: 'units',
    filterType: 'numerical'
  }
}

const getAccountsAdditionalColumnOptions = (data) => {
  const { taxStatuses, accountRegistrations } =
    data.reduce((acc, { balance }) => {
      acc.accountRegistrations = {
        ...acc.accountRegistrations,
        [balance.accountRegistration]: balance.accountRegistration
      }
      acc.taxStatuses = {
        ...acc.taxStatuses,
        [balance.taxStatusId]: balance.taxStatusLongName
      }
      return acc
    }, {})

  const mapOptions = (options) => {
    if (isEmpty(options)) return []
    return Object.entries(options).map(([value, label]) => ({
      value,
      label
    }))
  }

  return {
    taxStatuses: mapOptions(taxStatuses),
    accountRegistrations: mapOptions(accountRegistrations)
  }
}

export const getAccountsAdditionalColumns = (dataSet, data = []) => {
  const accountAdditionalColumnOptions = getAccountsAdditionalColumnOptions(data)
  return {
    [`${dataSet}.accountStartDate`]: {
      label: 'Start Date',
      default: false,
      template: 'date',
      alignRight: true,
      filterType: 'date'
    },
    [`${dataSet}.performanceStartDate`]: {
      label: 'Performance Start Date',
      default: false,
      template: 'date',
      alignRight: true,
      filterType: 'date'
    },
    [`${dataSet}.dailyStartDate`]: {
      label: 'Daily Start Date',
      default: false,
      template: 'date',
      alignRight: true,
      filterType: 'date'
    },
    [`${dataSet}.managedStatusName`]: {
      label: 'Managed Status',
      default: false,
      filterType: {
        type: 'list',
        options: [
          { value: 'Managed', label: 'Managed' },
          { value: 'Unmanaged', label: 'Unmanaged' }
        ]
      }
    },
    [`${dataSet}.discretionaryStatusName`]: {
      label: 'Discretionary Status',
      default: false,
      filterType: {
        type: 'list',
        options: [
          { value: 'Discretionary', label: 'Discretionary' },
          { value: 'Non-Discretionary', label: 'Non-Discretionary' }
        ]
      }
    },
    [`${dataSet}.performanceStatusName`]: {
      label: 'Performing Status',
      default: false,
      filterType: {
        type: 'list',
        options: [
          { value: 'Performing', label: 'Performing' },
          { value: 'Non-Performing', label: 'Non-Performing' }
        ]
      }
    },
    [`${dataSet}.taxStatusLongName`]: {
      label: 'Tax Status',
      default: false,
      filterType: {
        type: 'list',
        itemType: 'number',
        valueKey: 'taxStatusId',
        options: accountAdditionalColumnOptions.taxStatuses
      }
    },
    [`${dataSet}.custodianName`]: {
      label: 'Custodian',
      default: false,
      filterType: {
        type: 'list',
        itemType: 'number',
        valueKey: 'custodianId',
        domain: 'custodians'
      }
    },
    [`${dataSet}.dataSourceName`]: {
      label: 'Data Source',
      default: false,
      filterType: {
        type: 'list',
        itemType: 'number',
        valueKey: 'dataSourceId',
        domain: 'dataSources'
      }
    },
    [`${dataSet}.accountRegistration`]: {
      label: 'Account Registration Code',
      default: false,
      filterType: {
        type: 'list',
        options: accountAdditionalColumnOptions.accountRegistrations
      }
    }
  }
}

export const performanceColumns = {
  'performance.levelCount': {
    label: 'Day Count',
    default: false,
    template: 'units',
    exclude: true,
    alignRight: true
  },
  'performance.closeDate': {
    label: 'Close Date',
    default: false,
    template: 'altDate',
    filterType: 'date',
    alignRight: true
  },
  'performance.netFlows': {
    label: 'Net Additions',
    default: false,
    template: 'units',
    filterType: 'numerical',
    alignRight: true
  },
  'performance.netChange': {
    label: 'Net Gain GOF',
    default: true,
    template: 'units',
    filterType: 'numerical',
    alignRight: true
  },
  'performance.netChangeNOF': {
    label: 'Net Gain NOF',
    default: false,
    template: 'units',
    filterType: 'numerical',
    alignRight: true
  },
  'performance.previousAccrual': {
    label: 'Previous Accrual',
    default: false,
    exclude: true,
    template: 'units',
    filterType: 'numerical',
    alignRight: true
  },
  'performance.accrual': {
    label: 'Accrual',
    default: false,
    exclude: true,
    alignRight: true,
    template: 'units',
    filterType: 'numerical'
  },
  'performance.previousUnits': {
    label: 'Previous Units',
    default: false,
    exclude: true,
    template: 'number',
    filterType: 'numerical',
    alignRight: true
  },
  'performance.units': {
    label: 'Units',
    default: false,
    template: 'number',
    exclude: true,
    alignRight: true
  },
  'performance.additions': {
    label: 'Additions',
    default: false,
    exclude: true,
    template: 'units',
    filterType: 'numerical',
    alignRight: true
  },
  'performance.withdrawals': {
    label: 'Withdrawals',
    default: false,
    exclude: true,
    template: 'units',
    filterType: 'numerical',
    alignRight: true
  },
  'performance.fee': {
    label: 'Fee',
    default: false,
    template: 'units',
    filterType: 'numerical',
    alignRight: true
  },
  'performance.income': {
    label: 'Income',
    default: false,
    template: 'units',
    filterType: 'numerical',
    alignRight: true
  },
  'performance.adjustedNetAdditions': {
    label: 'Net Additions (Adjusted)',
    default: false,
    template: 'money',
    filterType: 'numerical',
    alignRight: true
  },
  'performance.cumulativeReturn': {
    label: 'Return (Cumulative GOF)',
    default: false,
    template: 'percentage',
    filterType: 'percentage',
    alignRight: true
  },
  'performance.cumulativeReturnNOF': {
    label: 'Return (Cumulative NOF)',
    default: false,
    template: 'percentage',
    filterType: 'percentage',
    alignRight: true
  },
  'performance.cumulativeBenchmark': {
    label: 'Benchmark (Cumulative)',
    default: false,
    exclude: true,
    template: 'percentage',
    filterType: 'percentage',
    alignRight: true
  },
  'performance.annualizedReturn': {
    label: 'Return (Annualized GOF)',
    default: true,
    template: 'percentage',
    filterType: 'percentage',
    alignRight: true
  },
  'performance.annualizedReturnNOF': {
    label: 'Return (Annualized NOF)',
    default: false,
    template: 'percentage',
    filterType: 'percentage',
    alignRight: true
  },
  'performance.annualizedBenchmark': {
    label: 'Benchmark (Annualized)',
    default: false,
    exclude: true,
    template: 'percentage',
    filterType: 'percentage',
    alignRight: true
  }
}

export const exposedPerformanceColumns = {
  'exposedPerformance.levelCount': {
    label: 'Day Count',
    default: false,
    template: 'units',
    exclude: true,
    alignRight: true
  },
  'exposedPerformance.closeDate': {
    label: 'Close Date',
    default: false,
    template: 'altDate',
    filterType: 'date',
    alignRight: true
  },
  'exposedPerformance.netFlows': {
    label: 'Net Additions',
    default: false,
    template: 'units',
    filterType: 'numerical',
    alignRight: true
  },
  'exposedPerformance.netChange': {
    label: 'Net Gain GOF',
    default: true,
    template: 'units',
    filterType: 'numerical',
    alignRight: true
  },
  'exposedPerformance.netChangeNOF': {
    label: 'Net Gain NOF',
    default: false,
    template: 'units',
    filterType: 'numerical',
    alignRight: true
  },
  'exposedPerformance.previousAccrual': {
    label: 'Previous Accrual',
    default: false,
    exclude: true,
    template: 'units',
    alignRight: true
  },
  'exposedPerformance.accrual': {
    label: 'Accrual',
    default: false,
    exclude: true,
    template: 'units',
    alignRight: true,
    filterType: 'numerical'
  },
  'exposedPerformance.previousUnits': {
    label: 'Previous Units',
    default: false,
    template: 'number',
    exclude: true,
    alignRight: true
  },
  'exposedPerformance.units': {
    label: 'Units',
    default: false,
    template: 'number',
    exclude: true,
    alignRight: true
  },
  'exposedPerformance.additions': {
    label: 'Additions',
    default: false,
    exclude: true,
    template: 'units',
    filterType: 'numerical',
    alignRight: true
  },
  'exposedPerformance.withdrawals': {
    label: 'Withdrawals',
    default: false,
    exclude: true,
    template: 'units',
    filterType: 'numerical',
    alignRight: true
  },
  'exposedPerformance.fee': {
    label: 'Fee',
    default: false,
    template: 'units',
    filterType: 'numerical',
    alignRight: true
  },
  'exposedPerformance.income': {
    label: 'Income',
    default: false,
    template: 'units',
    filterType: 'numerical',
    alignRight: true
  },
  'exposedPerformance.adjustedNetAdditions': {
    label: 'Net Additions (Adjusted)',
    default: false,
    template: 'money',
    filterType: 'numerical',
    alignRight: true
  },
  'exposedPerformance.cumulativeReturn': {
    label: 'Return (Cumulative GOF)',
    default: false,
    template: 'percentage',
    filterType: 'percentage',
    alignRight: true
  },
  'exposedPerformance.cumulativeReturnNOF': {
    label: 'Return (Cumulative NOF)',
    default: false,
    template: 'percentage',
    filterType: 'percentage',
    alignRight: true
  },
  'exposedPerformance.cumulativeBenchmark': {
    label: 'Benchmark (Cumulative)',
    default: false,
    exclude: true,
    template: 'percentage',
    filterType: 'percentage',
    alignRight: true
  },
  'exposedPerformance.annualizedReturn': {
    label: 'Return (Annualized GOF)',
    default: true,
    template: 'percentage',
    filterType: 'percentage',
    alignRight: true
  },
  'exposedPerformance.annualizedReturnNOF': {
    label: 'Return (Annualized NOF)',
    default: false,
    template: 'percentage',
    filterType: 'percentage',
    alignRight: true
  },
  'exposedPerformance.annualizedBenchmark': {
    label: 'Benchmark (Annualized)',
    default: false,
    exclude: true,
    template: 'percentage',
    filterType: 'percentage',
    alignRight: true
  }
}

export const uglColumns = {
  'ugl.endingValue': {
    label: 'Ending Value',
    default: false,
    template: 'money',
    filterType: 'numerical',
    alignRight: true
  },
  'ugl.costBasisAdjusted': {
    label: 'Cost Basis',
    default: true,
    template: 'money',
    filterType: 'numerical',
    alignRight: true
  },
  'ugl.shortTermUnrealizedLoss': {
    label: 'ST Unrealized Loss',
    default: false,
    template: 'money',
    filterType: 'numerical',
    alignRight: true
  },
  'ugl.longTermUnrealizedLoss': {
    label: 'LT Unrealized Loss',
    default: false,
    template: 'money',
    filterType: 'numerical',
    alignRight: true
  },
  'ugl.shortTermUnrealizedGain': {
    label: 'ST Unrealized Gain',
    default: false,
    template: 'money',
    filterType: 'numerical',
    alignRight: true
  },
  'ugl.longTermUnrealizedGain': {
    label: 'LT Unrealized Gain',
    default: false,
    template: 'money',
    filterType: 'numerical',
    alignRight: true
  },
  'ugl.totalUnrealizedGain': {
    label: 'Total Unrealized Gain',
    default: false,
    template: 'money',
    filterType: 'numerical',
    alignRight: true
  },
  'ugl.totalUnrealizedLoss': {
    label: 'Total Unrealized Loss',
    default: false,
    template: 'money',
    filterType: 'numerical',
    alignRight: true
  },
  'ugl.shortTermUGL': {
    label: 'ST UGL',
    default: true,
    template: 'money',
    filterType: 'numerical',
    alignRight: true
  },
  'ugl.longTermUGL': {
    label: 'LT UGL',
    default: true,
    template: 'money',
    filterType: 'numerical',
    alignRight: true
  },
  'ugl.totalUGL': {
    label: 'Total UGL',
    default: true,
    template: 'money',
    filterType: 'numerical',
    alignRight: true
  },
  'ugl.percentGainLoss': {
    label: '% Gain / Loss',
    default: true,
    template: 'percentage',
    filterType: 'percentage',
    alignRight: true
  }
}

export const rglColumns = {
  'rgl.costBasisAdjusted': {
    label: 'Cost Basis',
    default: true,
    template: 'money',
    filterType: 'numerical',
    alignRight: true
  },
  'rgl.proceeds': {
    label: 'Proceeds',
    default: true,
    template: 'money',
    filterType: 'numerical',
    alignRight: true
  },
  'rgl.shortTermRealizedLoss': {
    label: 'ST Realized Loss',
    default: false,
    template: 'money',
    filterType: 'numerical',
    alignRight: true
  },
  'rgl.longTermRealizedLoss': {
    label: 'LT Realized Loss',
    default: false,
    template: 'money',
    filterType: 'numerical',
    alignRight: true
  },
  'rgl.shortTermRealizedGain': {
    label: 'ST Realized Gain',
    default: false,
    template: 'money',
    filterType: 'numerical',
    alignRight: true
  },
  'rgl.longTermRealizedGain': {
    label: 'LT Realized Gain',
    default: false,
    template: 'money',
    filterType: 'numerical',
    alignRight: true
  },
  'rgl.totalRealizedGain': {
    label: 'Total Realized Gain',
    default: false,
    template: 'money',
    filterType: 'numerical',
    alignRight: true
  },
  'rgl.totalRealizedLoss': {
    label: 'Total Realized Loss',
    default: false,
    template: 'money',
    filterType: 'numerical',
    alignRight: true
  },
  'rgl.shortTermRGL': {
    label: 'ST RGL',
    default: true,
    template: 'money',
    filterType: 'numerical',
    alignRight: true
  },
  'rgl.longTermRGL': {
    label: 'LT RGL',
    default: true,
    template: 'money',
    filterType: 'numerical',
    alignRight: true
  },
  'rgl.totalRGL': {
    label: 'Total RGL',
    default: true,
    template: 'money',
    filterType: 'numerical',
    alignRight: true
  },
  'rgl.percentGainLoss': {
    label: '% Gain / Loss',
    default: true,
    template: 'percentage',
    filterType: 'percentage',
    alignRight: true
  }
}

const projectedIncomeColumns = {
  'projectedIncome.assetId': {
    label: 'Asset ID',
    default: false
  },
  'projectedIncome.assetIdentifier': {
    label: 'Asset Identifier',
    default: true
  },
  'projectedIncome.assetName': {
    label: 'Asset Name',
    default: true
  },
  'projectedIncome.paymentFrequency': {
    label: 'Payment Frequency',
    default: false
  },
  'projectedIncome.coupon': {
    label: 'Coupon Rate',
    default: false,
    template: 'percentage',
    filterType: 'percentage',
    alignRight: true
  },
  'projectedIncome.maturity': {
    label: 'Maturity',
    default: false,
    template: 'units',
    filterType: 'numerical',
    alignRight: true
  },
  'projectedIncome.issueDate': {
    label: 'Issue Date',
    default: false,
    template: 'altDate',
    filterType: 'date',
    alignRight: true
  },
  'projectedIncome.datedDate': {
    label: 'Dated Date',
    default: false,
    template: 'altDate',
    filterType: 'date',
    alignRight: true
  },
  'projectedIncome.paymentsPerYear': {
    label: 'Payments per Year',
    default: false,
    template: 'units',
    filterType: 'numerical',
    alignRight: true
  },
  'projectedIncome.interestStartDate': {
    label: 'Interest Start Date',
    default: false,
    template: 'altDate',
    filterType: 'date',
    alignRight: true
  },
  'projectedIncome.daysUntilMaturity': {
    label: 'Days Until Maturity',
    default: false,
    template: 'units',
    filterType: 'numerical',
    alignRight: true
  },
  'projectedIncome.daysUntilInterestStart': {
    label: 'Days Until Interest Start',
    default: false,
    template: 'units',
    filterType: 'numerical',
    alignRight: true
  },
  'projectedIncome.daysInPaymentCycle': {
    label: 'Days In Payment',
    default: false,
    template: 'units',
    filterType: 'numerical',
    alignRight: true
  },
  'projectedIncome.paymentsRemaining': {
    label: 'Payments Remaining',
    default: false,
    template: 'units',
    filterType: 'numerical',
    alignRight: true
  },
  'projectedIncome.paymentExDate': {
    label: 'Payment Ex Date',
    default: false,
    template: 'altDate',
    filterType: 'date',
    alignRight: true
  },
  'projectedIncome.dividendRate': {
    label: 'Dividend Rate',
    default: false,
    template: 'percentage',
    filterType: 'percentage',
    alignRight: true
  },
  'projectedIncome.endingValue': {
    label: 'Ending Value',
    default: false,
    template: 'units',
    filterType: 'numerical',
    alignRight: true
  },
  'projectedIncome.units': {
    label: 'Units',
    default: false,
    template: 'dollar',
    filterType: 'currency',
    alignRight: true
  },
  'projectedIncome.annualIncome': {
    label: 'Annual Income',
    default: true,
    template: 'units',
    filterType: 'numerical',
    alignRight: true
  },
  'projectedIncome.yield': {
    label: 'Yield',
    default: true,
    template: 'percentage',
    filterType: 'percentage',
    alignRight: true
  },
  'projectedIncome.projectedAnnualIncome': {
    label: 'Projected Annual Income',
    default: false,
    template: 'dollar',
    filterType: 'currency',
    alignRight: true
  }
}

export const bookOfBusinessReportColumns = {
  ...balanceColumns,
  ...exposedBalanceColumns,
  ...performanceColumns,
  ...exposedPerformanceColumns,
  ...uglColumns,
  ...rglColumns,
  ...projectedIncomeColumns
}

export const getDefaultColumns = (columnSet) => {
  const mapColumn = ([key, { label, ...rest }]) => ({
    ...rest,
    accessor: key,
    id: key,
    Header: label
  })

  const columns = Object.entries(columnSet).filter(
    ([_, entry]) => !entry.exclude
  )

  const defaultColumns = columns
    .filter(([_, entry]) => entry.default)
    .map(mapColumn)

  const nonDefaultColumns = columns
    .filter(([_, entry]) => !entry.default)
    .map(mapColumn)

  return [defaultColumns, nonDefaultColumns]
}
