import React, { useState, useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Box } from '@material-ui/core'
import noop from 'lodash/noop'
import Skeleton from '../../../atoms/Skeleton'
import { useAbundanceEngineParameters } from '../../../../hooks/useAbundanceEngineParameters'
import FullView from './FullView'
import Preview from './Preview'

const initialState = {
  activeId: undefined,
  posX: 0,
  posY: 0
}

const AdvisorFullView = ({
  viewId,
  loading,
  children,
  maxWidth,
  disableOnClick,
  viewContainerStyles,
  advisorId: _advisorId
}) => {
  const [activeState, setActiveState] = useState(initialState)
  const [fullViewOpen, setFullViewOpen] = useState(false)
  const abundanceParams = useAbundanceEngineParameters()

  const advisorId = useMemo(() => {
    return _advisorId ?? abundanceParams.advisorId
  }, [_advisorId, abundanceParams.advisorId])

  const { activeId, posX, posY } = activeState

  const setPreviewActive = useCallback(({ activeId, posX = 0, posY = 0 }) => {
    setActiveState({ activeId, posX, posY })
    setTimeout(() => setFullViewOpen(true), 0)
  }, [])

  const onCloseFullView = useCallback(() => {
    setActiveState(initialState)
  }, [])

  if (loading) {
    return <Skeleton height='3rem' width='100%' />
  }

  return (
    <>
      {!loading && (
        <Box p='1rem' height='100%' width='100%'>
          <Preview
            key={`preview-${viewId}`}
            previewId={viewId}
            onPreviewClick={disableOnClick ? noop : setPreviewActive}
            styles={{ height: '100%', margin: 'unset' }}
          >
            <Box p='0.375rem' width='100%'>
              {children}
            </Box>
          </Preview>
        </Box>
      )}
      <FullView
        activeId={activeId}
        advisorId={advisorId}
        fromPosX={posX}
        fromPosY={posY}
        open={fullViewOpen}
        onCloseFullView={onCloseFullView}
        setOpen={setFullViewOpen}
        hideClientInfoFromHeader
        maxWidth={maxWidth}
        viewContainerStyles={viewContainerStyles}
      />
    </>
  )
}

AdvisorFullView.propTypes = {
  children: PropTypes.node.isRequired,
  viewId: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  disableOnClick: PropTypes.bool,
  advisorId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  maxWidth: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl', false]),
  viewContainerStyles: PropTypes.object
}

AdvisorFullView.defaultProps = {
  loading: false,
  disableOnClick: false,
  maxWidth: 'xl',
  viewContainerStyles: {}
}

export default AdvisorFullView
