import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import OperationalTable from '../../../../organisms/OperationalTable'
import GroupingProvider from '../../../../organisms/GroupingProvider'
import { useClientsListContext } from './ClientsListContext'
import { COLUMN_IDS, getDynamicColumnIds, getFilterColumnId } from './helpers'

const ClientsTableWrapper = ({ children }) => {
  const { onChangeFilters, setVisibleFilters, configurationKey } =
    useClientsListContext()

  const onSaveTableConfiguration = useCallback(
    ({ columns, hiddenColumns }) => {
      // show or hide filters based on column visibility
      const filterColPrefix = getFilterColumnId()
      const visibleColFilters = columns.filter((col) =>
        col.startsWith(filterColPrefix)
      )
      setVisibleFilters(visibleColFilters)

      // if a group type filter column is hidden, we remove its filter too
      onChangeFilters(({
        clientTagFilters,
        benchmarksFilters,
        feeSchedulesFilters,
        ...prevState
      }) => {
        const filterColIds = getDynamicColumnIds(hiddenColumns)
        return {
          ...prevState,
          clientTagFilters: clientTagFilters.filter(({ groupTypeId }) => {
            return !filterColIds.includes(groupTypeId.toString())
          }),
          benchmarksFilters: !hiddenColumns.includes(COLUMN_IDS.BENCHMARKS)
            ? benchmarksFilters
            : [],
          feeSchedulesFilters: !hiddenColumns.includes(COLUMN_IDS.FEE_SCHEDULES)
            ? feeSchedulesFilters
            : []
        }
      })
    },
    [onChangeFilters, setVisibleFilters]
  )

  return (
    <GroupingProvider
      configurationKey={configurationKey}
      onApplyClick={onSaveTableConfiguration}
      options={{
        hideExportTypePicker: true,
        hideAddAllColumnsButton: true
      }}
    >
      <OperationalTable.Wrapper>{children}</OperationalTable.Wrapper>
    </GroupingProvider>
  )
}

ClientsTableWrapper.propTypes = {
  children: PropTypes.node.isRequired
}

export default ClientsTableWrapper
