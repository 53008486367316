import { useCallback, useEffect, useState } from 'react'

import { useAvailableDates } from '../redux/slices/appContext'
import { postNamedQuery } from '../service'
import SafeDate from '../utils/levelDateHelper'
import useToggle from './useToggle'
import useAbortController from './useAbortController'

const API_LIMIT = 500
const TAKE_LIMIT = 100

const useFetchNewAccounts = (areAssignedAccounts) => {
  const abortController = useAbortController()
  const [availableDates] = useAvailableDates()
  const [isLoading, , setIsLoadingOn, setIsLoadingOff] = useToggle()

  const [limitReached, setLimitReached] = useState(false)
  const [rows, setRows] = useState([])
  const [limits, setLimits] = useState({
    skip: 0,
    take: API_LIMIT
  })
  const [rowCount, setRowCount] = useState(0)
  const [filters] = useState(() => {
    if (areAssignedAccounts) {
      return {
        createdDate: [
          {
            op: 'gte',
            value: new SafeDate(availableDates).subtract().day(30)
          }
        ]
      }
    }

    return {
      hasClientsAssigned: false
    }
  })

  const updateLimits = useCallback(() => {
    setLimits((limits) => {
      return {
        skip: limits.skip === 0 ? API_LIMIT + TAKE_LIMIT : limits.skip + TAKE_LIMIT,
        take: TAKE_LIMIT
      }
    })
  }, [])

  const fetchTotalAccounts = useCallback(async () => {
    return await postNamedQuery('levels', 'searchAccounts', {
      totalAccounts: {
        resultType: 'total',
        filters
      }
    }, abortController)
  }, [abortController, filters])

  const fetchAccounts = useCallback(async () => {
    return await postNamedQuery('levels', 'searchAccounts',
      {
        accounts: {
          ...limits,
          includes: {
            assignedClients: true
          },
          filters
        }
      },
      abortController
    )
  }, [limits, abortController, filters])

  const loadMoreRows = useCallback(async () => {
    const { skip } = limits

    if (limitReached) {
      return
    }

    if (skip > rowCount) {
      setLimitReached(true)
    }

    updateLimits()

    const { data: totalAccounts } = await fetchAccounts()
    const { accounts: { data } } = totalAccounts

    setRows([...rows, ...data])
  }, [limitReached, limits, rowCount, rows, updateLimits, fetchAccounts])

  useEffect(() => {
    async function getAccounts () {
      try {
        setIsLoadingOn()

        const { data: totalData } = await fetchTotalAccounts()
        const { data: totalAccounts } = await fetchAccounts()

        const { totalAccounts: { total } } = totalData
        const { accounts: { data } } = totalAccounts

        setRows(data)
        setRowCount(total)
        updateLimits()
      } catch (error) {
        console.log(error)
      } finally {
        setIsLoadingOff()
      }
    }

    if (limits.skip === 0) {
      getAccounts()
    }
  }, [fetchAccounts, fetchTotalAccounts, limits.skip, updateLimits, setIsLoadingOff, setIsLoadingOn])

  return {
    isLoading,
    rows,
    rowCount,
    loadMoreRows
  }
}

export default useFetchNewAccounts
