import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import Skeleton from '../../../../atoms/Skeleton'
import FamilyTreeContainer from './container'
import FamilyTreeLevelContainer from './levelContainer'
import FamilyTreeNode from './node'

const useStyles = makeStyles(() => ({
  skeleton: {
    borderRadius: '100px'
  }
}))

const FamilyTreeSkeleton = ({ levels, nodes }) => {
  const classes = useStyles()
  return (
    <div>
      <FamilyTreeContainer>
        {Array.from({ length: levels }, (_, index) => (
          <FamilyTreeLevelContainer
            key={index}
            hasChildren={index !== levels - 1}
          >
            {Array.from({ length: nodes - index }, (_, i) => (
              <FamilyTreeNode key={i} noPadding>
                <Skeleton
                  height='2.5rem'
                  width='100%'
                  className={classes.skeleton}
                />
              </FamilyTreeNode>
            ))}
          </FamilyTreeLevelContainer>
        ))}
      </FamilyTreeContainer>
    </div>
  )
}

FamilyTreeSkeleton.propTypes = {
  levels: PropTypes.number,
  nodes: PropTypes.number
}

FamilyTreeSkeleton.defaultProps = {
  levels: 3,
  nodes: 3
}

export default FamilyTreeSkeleton
