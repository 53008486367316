import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { TRANSFORMERS, $convertToMarkdownString } from '@lexical/markdown'

const OnCustomChangePlugin = ({ onChange }) => {
  const [editor] = useLexicalComposerContext()

  useEffect(() => {
    const removeUpdateListener = editor.registerUpdateListener(
      ({ editorState }) => {
        editorState.read(() => {
          onChange($convertToMarkdownString(TRANSFORMERS))
        })
      }
    )
    return () => {
      removeUpdateListener()
    }
  }, [editor, onChange])

  return null
}

OnCustomChangePlugin.propTypes = {
  onChange: PropTypes.func.isRequired
}

export default OnCustomChangePlugin
