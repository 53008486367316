import { useCallback } from 'react'
import dayjs from 'dayjs'
import { useAppContext, useSetAppContext } from '../redux/slices/appContext'
import { useSetViewContext } from '../redux/slices/viewContext'
import { INTERNAL_DATE_FORMAT } from '../constants'

export const useDates = () => {
  const appContext = useAppContext()
  const setAppContext = useSetAppContext()
  // TODO - do we even need view context?
  const setViewContext = useSetViewContext()
  const onAvailableDateChange = useCallback((date) => {
    setAppContext({
      availableDates: {
        ...appContext.availableDates,
        mainDate: dayjs(date).format(INTERNAL_DATE_FORMAT)
      }
    })
    setViewContext({ mainDate: dayjs(date).format(INTERNAL_DATE_FORMAT) })
  }, [appContext.availableDates, setAppContext, setViewContext])

  return {
    availableDates: appContext?.availableDates,
    loadingAvailableDates: appContext?.loadingAvailableDates,
    onAvailableDateChange
  }
}
