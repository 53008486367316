import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import BookOfBusinessReportProvider from '../../../../contexts/BookOfBusinessReportContext'
import BookOfBusinessReportMetric from './BobiReportMetric'

const BookOfBusinessReport = ({
  to,
  limit,
  children,
  filters,
  loading,
  asOfDate,
  dateRange,
  aggregation,
  reportParams,
  useOutputCount,
  reportOutputParams,
  injectResultInProp,
  filterChildrenTypes
}) => {
  const reportParamsModified = useMemo(() => {
    // AH BOBI widgets save outputParams in metadata by default
    // this helps to replicate any filter changes applied to the report output
    return {
      ...reportParams,
      metadata: {
        ...reportParams.metadata,
        outputParams: {
          ...reportOutputParams
        }
      }
    }
  }, [reportParams, reportOutputParams])

  return (
    <BookOfBusinessReportProvider
      limit={limit}
      loading={loading}
      asOfDate={asOfDate}
      dateRange={dateRange}
      reportParams={reportParamsModified}
      useOutputCount={useOutputCount}
      reportOutputParams={reportOutputParams}
    >
      <BookOfBusinessReportMetric
        to={to}
        filters={filters}
        aggregation={aggregation}
        useOutputCount={useOutputCount}
        injectResultInProp={injectResultInProp}
        filterChildrenTypes={filterChildrenTypes}
      >
        {children}
      </BookOfBusinessReportMetric>
    </BookOfBusinessReportProvider>
  )
}

BookOfBusinessReport.propTypes = {
  to: PropTypes.string,
  limit: PropTypes.number,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
  reportParams: PropTypes.object,
  dateRange: PropTypes.shape({
    startDate: PropTypes.string,
    endDate: PropTypes.string
  }),
  injectResultInProp: PropTypes.string,
  filters: PropTypes.object,
  aggregation: PropTypes.object,
  loading: PropTypes.node,
  filterChildrenTypes: PropTypes.arrayOf(PropTypes.string),
  reportOutputParams: PropTypes.object,
  useOutputCount: PropTypes.bool,
  asOfDate: PropTypes.string
}

BookOfBusinessReport.defaultProps = {
  useOutputCount: true
}

export default BookOfBusinessReport
