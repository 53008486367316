import React, { createContext, useContext, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useGetRebalancerSettings } from '../../../../api/rebalancer'

const RebalancerSettingsContext = createContext({})

export const useRebalancerSettings = () => {
  return useContext(RebalancerSettingsContext)
}

export const RebalancerSettingsProvider = ({ children }) => {
  const { data, isLoading } = useGetRebalancerSettings()
  const settings = useMemo(() => isLoading ? {} : data, [data, isLoading])

  return (
    <RebalancerSettingsContext.Provider value={settings}>
      {children}
    </RebalancerSettingsContext.Provider>
  )
}

RebalancerSettingsProvider.propTypes = {
  children: PropTypes.node
}
