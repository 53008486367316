import React from 'react'
import { useParams } from 'react-router-dom'
import { makeStyles } from '@material-ui/core'
import { usePolicy } from '../../../../hooks/usePolicy'
import EmptySection from '../../../atoms/EmptySection'
import { useStatusTemplate } from '../../../../api/groups'
import StatusDetailTabs from './StatusDetailTabs'
import { StatusTemplateProvider } from './StatusTemplateProvider'
import StatusTemplateHeader from './Header'

const useStyles = makeStyles({
  statusTemplatePage: {
    margin: '1rem'
  }
})

const useStatusTemplateId = () => {
  const { templateId } = useParams()
  return isNaN(+templateId) ? [-1] : [+templateId]
}

function StatusTemplateDetails () {
  const classes = useStyles()
  const [statusTemplateId] = useStatusTemplateId()
  const canView = usePolicy('admin_view_status_templates')
  const { data: statusTemplate, isLoading, isFetching } = useStatusTemplate(statusTemplateId)

  if (!canView) {
    return (
      <EmptySection
        title='Access Denied'
        description='You do not have sufficient permissions to access this resource'
      />
    )
  }

  if (isLoading) {
    return null
  }

  if (!statusTemplate) {
    return (
      <EmptySection
        title='Status Template Not Found'
        description=''
      />
    )
  }

  return (
    <div className={classes.statusTemplatePage}>
      <StatusTemplateProvider statusTemplate={statusTemplate} isFetching={isFetching}>
        <StatusTemplateHeader />
        <StatusDetailTabs />
      </StatusTemplateProvider>
    </div>
  )
}

export default StatusTemplateDetails
