import { makeStyles } from '@material-ui/core/styles'
import isEmpty from 'lodash/isEmpty'
import { alpha } from '@material-ui/core'
import { ALERT_SEVERITY, MAX_WORD_LENGTH_ALLOWED_ON_USERNAME, TEXT_VARIANTS } from '../../../constants'

export const useStyles = makeStyles((theme) => ({
  content: {
    padding: '0 2rem'
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center'
  },
  button: {
    borderRadius: '30px',
    fontWeight: 800,
    textTransform: 'capitalize',
    padding: '18px 50px',
    width: '150px'
  },
  cancelButton: {
    backgroundColor: theme.palette.lightCoral,
    color: theme.palette.error.main,
    '&:hover': {
      backgroundColor: '#f3b3be'
    }
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'stretch',
    height: '100%'
  },
  label: {
    ...theme.typography[TEXT_VARIANTS.subtitle2],
    color: 'inherit'
  },
  termsLabel: {
    ...theme.typography[TEXT_VARIANTS.body2],
    color: 'inherit'
  },
  field: {
    marginBottom: '30px'
  },
  editor: {
    height: '100%'
  },
  form: {
    height: '100%',
    display: 'flex'
  },
  leftElement: {
    marginRight: '5px'
  },
  inline: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start'
  },
  passwordButton: {
    padding: '8px 15px'
  },
  profilePic: {
    height: '150px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  submitButton: {
    backgroundColor: theme.palette.gray.light,
    color: theme.palette.lightBlack,
    '&:hover': {
      backgroundColor: theme.palette.gray.dark
    }
  },
  placeholderText: {
    color: alpha(theme.palette.black, 0.4)
  },
  fullWidth: {
    width: '100%'
  },
  roundedButton: {
    color: theme.palette.mirage,
    borderRadius: 25,
    padding: '15px',
    textTransform: 'none',
    fontWeight: 'bold',
    fontSize: '0.75rem',
    alignSelf: 'flex-start'
  }
}))

export const errorAlert = {
  openAlert: true,
  alertMessage: 'An error has occurred. Try again',
  alertSeverity: ALERT_SEVERITY.error
}

export const initialAlertState = {
  alertMessage: '',
  alertSeverity: ALERT_SEVERITY.success,
  openAlert: false
}

export const successAlert = {
  openAlert: true,
  alertMessage: 'Action realized successfully',
  alertSeverity: ALERT_SEVERITY.success
}

export const warningAlert = {
  openAlert: true,
  alertMessage: 'Action realized successfully',
  alertSeverity: ALERT_SEVERITY.warning
}

export const namesInputRules = {
  minLength: 2,
  maxLength: 50,
  required: true
}

export const numberInputRules = {
  min: 1,
  max: 100,
  step: 1,
  required: true
}

export const emailRules = {
  pattern: /^[\w-.+]+@([\w-]+\.)+[\w-]{2,4}$/,
  required: true
}

export const validatePassword = ({
  password,
  confirmPassword = undefined,
  email = undefined
}) => {
  if (confirmPassword && password !== confirmPassword) {
    return 'Passwords does not match'
  }
  if (password.length < 8) {
    return 'Password must be at least 8 characters long.'
  } else if (password.length > 30) {
    return 'Password must be less than 30 characters long.'
  } else if (!/^(?=.*\d).+$/.test(password)) {
    return 'Password must contain at least one digit.'
  } else if (!/^(?=.*?[a-z]).+$/.test(password)) {
    return 'Password must contain at least one lowercase letter.'
  } else if (!/^(?=.*?[A-Z]).+$/.test(password)) {
    return 'Password must contain at least one uppercase letter.'
  }
  if (email) {
    const emailParts = email
      .split(/[,._#@]+/)
      .slice(0, -1)
      .filter(word => word.length >= MAX_WORD_LENGTH_ALLOWED_ON_USERNAME)
    if (emailParts.some((item) => password.toLowerCase().includes(item.toLowerCase()))) {
      return "Password must not contain parts of the user's login."
    }
  }
}

export const extractMessages = (errors, onlyFirst = false) => {
  if (isEmpty(errors)) return null
  const errorMessages = Object.keys(errors).map(
    (errorKey) => errors[errorKey].message
  )
  return onlyFirst ? errorMessages[0] : errorMessages
}

export const messageToUse = ({ type, ref: { name } }, rules) => {
  const { min = 0, max = 9999 } = rules || {}
  switch (type) {
    case 'required':
      return 'This field is required'
    case 'minLength':
      return 'Your input is shorter than the minimum length'
    case 'maxLength':
      return 'Your input is longer than the maximum length'
    case 'pattern':
      return `Your input should be a valid ${name}`
    case 'min':
      return `Value must be greater than or equal to ${min}`
    case 'max':
      return `Value must be less than or equal to ${max}`
    case 'menuDefaultOption':
      return 'Please select an option'
    default:
      return 'Invalid input'
  }
}

export const USER_TYPES = {
  admin: { label: 'Advisor', value: 'advisor' },
  client: { label: 'Wealth Owner', value: 'client' }
}
