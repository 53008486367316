import React from 'react'
import { makeStyles } from '@material-ui/core'
import { useCheckPolicy } from '../../../../../hooks'
import FastAvatar from '../../../../molecules/FastAvatar'
import { useClientDetails } from '../ClientDetailsFormContext'
import ProfilePictureFileInput from '../../../../molecules/ProfilePictureFileInput'
import { useMediaUrl } from '../../../../../api/media'
import SectionScreen from '../SectionScreen'
import { useAutomaticClientAvatarUpload } from '../../../../../api/special/useAutomaticClientAvatarUpload'

const useStyles = makeStyles((theme) => ({
  profilePicture: {
    padding: '0 20px'
  }
}))

function ProfilePicture () {
  const { client } = useClientDetails()
  const classes = useStyles()
  const canEdit = useCheckPolicy('admin_edit_clientinfo')
  const { autoUpload, isLoading } = useAutomaticClientAvatarUpload({ client, canEdit })
  const { data: mediaUrl } = useMediaUrl(client?.extras?.profileData?.profilePicMediaId, {})

  return (
    <SectionScreen sectionName='profile_pic' className={classes.profilePicture}>
      {(canEdit) ? (
        <ProfilePictureFileInput
          ignoreDelete
          clientId={client?.clientId}
          disabledOpacity={1}
          isLoading={isLoading}
          placeholderSrc={mediaUrl}
          onDropAccepted={autoUpload}
          label='Upload'
        />
      ) : (
        <FastAvatar
          size='xl'
          avatarUrl={mediaUrl}
          abbreviation={client.clientAbbreviation}
        />
      )}
    </SectionScreen>
  )
}

ProfilePicture.propTypes = {
}

export default ProfilePicture
