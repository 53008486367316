import React from 'react'
import PropTypes from 'prop-types'
import { Box, makeStyles } from '@material-ui/core'
import Divider from '../../../atoms/Divider'
import SummitOnlyContent from '../../../atoms/SummitOnlyContent'
import WebOnlyContent from '../../../atoms/WebOnlyContent'
import Select from '../../../atoms/Select'
import { REDTAIL_INTEGRATION, SALESFORCE_INTEGRATION, WEALTHBOX_INTEGRATION } from '../../../../constants'

const useStyles = makeStyles((theme) => ({
  activeCrmDropdown: {
    border: '1px solid #E9EAEF',
    borderRadius: '36px',
    width: '200px',
    '& .MuiSelect-select': {
      padding: '6px 20px',
      borderRadius: '36px'
    },
    '& .MuiSvgIcon-root': {
      right: '10px',
      position: 'absolute',
      zIndex: -1
    }
  },
  footerDivider: {
    margin: 0
  }
}))

const activeCrmOptions = [
  {
    value: '0',
    label: 'None'
  },
  {
    value: SALESFORCE_INTEGRATION,
    label: 'Salesforce'
  },
  {
    value: WEALTHBOX_INTEGRATION,
    label: 'Wealthbox'
  },
  {
    value: REDTAIL_INTEGRATION,
    label: 'Redtail'
  },
  {
    value: 'practifi',
    label: 'Practifi'
  }
]

function IntegrationsSwitcher ({ canSwitchCrm, canEditIntegrations, activeCrm, onActiveCrmChange }) {
  const classes = useStyles()

  if (!canSwitchCrm || !canEditIntegrations) return null

  return (
    <SummitOnlyContent>
      {() => (
        <Box
          sx={{
            height: 92,
            position: 'absolute',
            bottom: 0,
            width: '100%',
            bgcolor: '#fff',
            boxShadow: '0px -4px 18px rgba(36, 36, 44, 0.04);'
          }}
        >
          <Divider additionalClasses={classes.footerDivider} />
          <WebOnlyContent>
            <div style={{ padding: '25px 20px' }}>
              <strong>Active CRM: </strong>
              <Select
                className={classes.activeCrmDropdown}
                options={activeCrmOptions}
                selectedValue={activeCrm}
                onChange={onActiveCrmChange}
                displayEmpty
                displayCheckMark
                placeholder='Select CRM'
              />
            </div>
          </WebOnlyContent>
        </Box>
      )}
    </SummitOnlyContent>
  )
}

IntegrationsSwitcher.propTypes = {
  canSwitchCrm: PropTypes.bool,
  canEditIntegrations: PropTypes.bool,
  activeCrm: PropTypes.string,
  onActiveCrmChange: PropTypes.func
}

export default IntegrationsSwitcher
