import React, { useCallback, useImperativeHandle, useState } from 'react'
import PropTypes from 'prop-types'
import RoundedModal from '../../../../../molecules/RoundedModal'
import AssignSingleSmallGroupForm from './AssignSingleSmallGroupForm'
import AssignSingleLargeGroupForm from './AssignSingleLargeGroupForm'
import AssignMultipleSmallGroupForm from './AssignMultipleSmallGroupForm'
import AssignMultipleLargeGroupForm from './AssignMultipleLargeGroupForm'

const AssignGroupDialog = React.forwardRef(function AssignGroupDialog ({ onCreate, client }, ref) {
  const [dialogState, setDialogState] = useState({ open: false, processing: false })
  const close = useCallback(() => {
    // We keep the parent here because it says "undefined" on the modal otherwise
    setDialogState((p) => ({ ...p, open: false, processing: false }))
  }, [setDialogState])

  const complete = useCallback(async (result) => {
    await onCreate({
      groupIds: result.groupIds,
      groupTypeId: dialogState.parent.groupTypeId,
      clientId: client.clientId
    })
    close()
  }, [close, dialogState, client, onCreate])

  useImperativeHandle(ref, () => ({
    open: (item) => setDialogState({ open: true, processing: false, parent: item })
  }), [setDialogState])

  return (
    <RoundedModal
      title={`${dialogState?.parent?.longName}`}
      open={dialogState.open}
      onClose={close}
      position='top'
      disablePortal={false}
    >
      {!!dialogState?.parent?.uniqueMembers && dialogState?.parent?.groupCount <= 10 ? (
        <AssignSingleSmallGroupForm client={client} groupType={dialogState?.parent} onComplete={complete} onClose={close} />
      ) : !!dialogState?.parent?.uniqueMembers && dialogState?.parent?.groupCount > 10 ? (
        <AssignSingleLargeGroupForm client={client} groupType={dialogState?.parent} onComplete={complete} onClose={close} />
      ) : !dialogState?.parent?.uniqueMembers && dialogState?.parent?.groupCount <= 10 ? (
        <AssignMultipleSmallGroupForm client={client} groupType={dialogState?.parent} onComplete={complete} onClose={close} />
      ) : !dialogState?.parent?.uniqueMembers && dialogState?.parent?.groupCount > 10 ? (
        <AssignMultipleLargeGroupForm client={client} groupType={dialogState?.parent} onComplete={complete} onClose={close} />
      ) : (
        <div>Sorry, This group has a condition that makes it not assignable.</div>
      )}
    </RoundedModal>
  )
})

AssignGroupDialog.propTypes = {
  onCreate: PropTypes.func,
  client: PropTypes.object
}

export default AssignGroupDialog
