import React, { useMemo } from 'react'
import get from 'lodash.get'
import PropTypes from 'prop-types'
import { makeStyles, Tooltip } from '@material-ui/core'
import clsx from 'clsx'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import { featureProps, schemaProps } from '../commonProps'
import { useTeamMemberDrawer } from '../TeamMemberDrawer'

const useStyles = makeStyles((theme) => ({
  bioDisabled: {},
  bioActive: {
    '&:hover': {
      cursor: 'pointer'
    }
  },
  description: {
    fontSize: theme.typography.fontSizes.lg
  },
  descriptionWrap: {
    '&.MuiTooltip-popper': {
      '& .MuiTooltip-tooltipPlacementTop': {
        minWidth: '30vw',
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
        border: `2px solid ${theme.palette.gray.darker}`,
        boxShadow: theme.layout.shadow.deep,
        lineHeight: '18px',
        padding: `${theme.layout.padding.thin} ${theme.layout.padding.thick}`
      },
      '& .MuiTooltip-tooltipPlacementBottom': {
        minWidth: '30vw',
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
        border: `2px solid ${theme.palette.gray.darker}`,
        boxShadow: theme.layout.shadow.deep,
        lineHeight: '18px',
        padding: `${theme.layout.padding.thin} ${theme.layout.padding.thick}`
      },
      '& .__more': {
        width: '100%',
        textAlign: 'center'
      }
    }
  }
}))

function BioArea ({ teamMember, features, schema, children, className }) {
  const classes = useStyles()
  const bioEnabled = useMemo(() => features?.showBio && schema?.attributes?.some(x => x.attributeId === 'bio'), [features, schema])
  const bioValue = useMemo(() => {
    const attribute = schema?.attributes?.find(x => x.attributeId === 'bio')
    return attribute ? get(teamMember, attribute.accessor) : ''
  }, [schema, teamMember])
  const { open } = useTeamMemberDrawer()

  if (!bioEnabled || !bioValue) {
    return (
      <div className={clsx(classes.bioDisabled, className)}>
        {children}
      </div>
    )
  }

  return (
    <Tooltip
      placement='top'
      enterDelay={750}
      title={(
        <div>
          <h3>About {teamMember.firstName}</h3>
          <ReactMarkdown className={classes.description} children={bioValue} remarkPlugins={[remarkGfm]} />
        </div>
      )}
      classes={{
        popper: classes.descriptionWrap
      }}
    >
      <div role='button' onClick={() => open(teamMember, schema)} className={clsx(classes.bioActive, className)}>
        {children}
      </div>
    </Tooltip>
  )
}

BioArea.propTypes = {
  className: PropTypes.string,
  teamMember: PropTypes.object.isRequired,
  features: featureProps,
  schema: schemaProps,
  children: PropTypes.node
}

export default BioArea
