import React, { useCallback, useState, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import {
  Grid,
  Box,
  FormControlLabel,
  FormLabel,
  Button,
  makeStyles,
  useTheme
} from '@material-ui/core'
import clsx from 'clsx'
import dayjs from 'dayjs'

import utc from 'dayjs/plugin/utc'
import findLast from 'lodash/findLast'
import isEmpty from 'lodash/isEmpty'
import numeral from 'numeral'
import Text from '../../../../atoms/Text'
import Checkbox from '../../../../molecules/Checkbox'
import DatePicker from '../../../../molecules/Datepicker'
import Select from '../../../../molecules/Select'
import NumericInput from '../../../../molecules/NumericInput'
import Icon from '../../../../atoms/Icon'
import Remove from '../../Users/Remove'

import { useBenchmarks } from '../../../../../api/benchmarks'

import {
  ICON_NAMES,
  INTERNAL_DATE_FORMAT,
  BENCHMARKS
} from '../../../../../constants'
import { useWorkableDates } from '../../../../../hooks'
import { getNewGroup } from '../utils'
import { useLevelDates } from '../../../../../api/coreData'
import Group from './Group'
import SetEndDateDialog from './SetEndDateDialog'

dayjs.extend(utc)

const useStyles = makeStyles((theme) => ({
  components: {
    marginTop: '1.125rem'
  },
  header: {
    marginBottom: '0.5rem',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  effectiveDate: {
    position: 'relative',
    top: '1px',
    fontSize: '0.875rem',
    color: theme.palette.darkJungle,
    fontWeight: 600
  },
  label: {
    color: theme.palette.darkJungle,
    fontSize: '0.75rem',
    lineHeight: '19px',
    fontWeight: 'bold',
    display: 'block',
    marginBottom: '0.5rem'
  },
  benchmarkSelect: {
    width: '100% !important',
    padding: '16px 14px',
    border: '2px solid #EEF0F8',
    opacity: '1'
  },
  pillBtn: {
    borderRadius: 25,
    padding: '8px 16px',
    textTransform: 'none',
    fontWeight: 'bold',
    fontSize: '0.75rem'
  },
  pillPrimary: {
    border: `1.5px solid ${theme.palette.summitBlue}`,
    color: theme.palette.darkJungle
  },
  pillSecondary: {
    backgroundColor: theme.palette.summitBlue,
    color: theme.palette.white,
    '&:hover': {
      backgroundColor: theme.palette.summitBlue
    },
    '&:disabled': {
      backgroundColor: theme.palette.romanSilver,
      color: theme.palette.white
    }
  },
  pillDelete: {
    background: 'linear-gradient(0deg, rgba(250, 219, 215, 0.50) 0%, rgba(250, 219, 215, 0.50) 100%), #FFF',
    color: theme.palette.error.primary
  },
  pillMaxHeight: {
    maxHeight: '1.875rem'
  },
  addBenchmark: {
    marginTop: '1rem'
  },
  removeComponent: {
    display: 'flex',
    alignItems: 'center'
  },
  group: {
    minHeight: '280px'
  },
  editingGroup: {
    minHeight: '350px'
  }
}))

const PERCENTAGE_LIMIT = 100

const levelDateQuery = {
  levelType: 'user'
}

function Groups ({
  groups,
  setGroups,
  benchmarkId,
  canEditStartStop,
  firmId,
  isEditing
}) {
  const classes = useStyles()
  const theme = useTheme()

  const { data: levelDates } = useLevelDates(levelDateQuery)
  const min = levelDates?.min

  const [benchmarkOpts, setBenchmarkOpts] = useState([])
  const [effectiveDate, setEffectiveDate] = useState(false)
  const [openedDialog, setOpenedDialog] = useState(false)

  const { getNearestWorkableDate } = useWorkableDates()

  const isNew = benchmarkId === null

  const { data: benchmarksOptions, isLoading: loadingBenchmarkOpts } =
    useBenchmarks({
      method: 'benchmarkNameSearch',
      filters: { type: BENCHMARKS.SOURCE },
      firmId
    })

  const createGroups = useCallback((groupIndex) => {
    const newGroups = [...groups]
    const group = newGroups[groupIndex]

    return {
      newGroups,
      group
    }
  }, [groups])

  const getNextNearestWorkableDate = useCallback(
    ({ date, searchBackwards = false, days = 1 }) => {
      if (!date || isEmpty(date)) return null
      const nextDate = dayjs
        .utc(date)
        .add(days, 'day')
        .format(INTERNAL_DATE_FORMAT)

      const workableDate = getNearestWorkableDate({
        date: nextDate,
        searchBackwards
      })
      return workableDate
    },
    [getNearestWorkableDate]
  )

  const minDate = useMemo(
    () => getNextNearestWorkableDate({ date: min }),
    [min, getNextNearestWorkableDate]
  )

  const onAddGroup = useCallback(() => {
    const lastGroup = groups[groups.length - 1]

    if (!lastGroup.endDate) {
      setOpenedDialog(true)
      return
    }

    setGroups((prev) => {
      const newGroup = getNewGroup()
      if (effectiveDate) {
        const lastGroup = prev?.[prev.length - 1]
        const defaultStartDate = getNextNearestWorkableDate({
          date: lastGroup?.endDate
        })

        return [
          ...prev,
          {
            ...newGroup,
            startDate: defaultStartDate
          }
        ]
      }
      return [...prev, { ...newGroup }]
    })
  }, [
    groups,
    setGroups,
    effectiveDate,
    getNextNearestWorkableDate
  ])

  const onRemoveGroup = useCallback((groupIndex) => {
    const { newGroups } = createGroups(groupIndex)

    newGroups.splice(groupIndex, 1)
    setGroups(newGroups)
  }, [setGroups, createGroups])

  const onAddBenchmark = useCallback((groupIndex) => {
    const { newGroups, group } = createGroups(groupIndex)

    group.components.push({
      componentAllocation: 0
    })
    setGroups(newGroups)
  }, [setGroups, createGroups])

  const onRemoveBenchmark = useCallback((groupIndex, componentIndex) => {
    const { newGroups, group } = createGroups(groupIndex)

    group.components.splice(componentIndex, 1)
    setGroups(newGroups)
  }, [setGroups, createGroups])

  const onUpdateBenchmarkAllocation = useCallback((value, groupIndex, componentIndex) => {
    const { newGroups, group } = createGroups(groupIndex)

    group.components[componentIndex].componentAllocation = Number(value) / 100
    setGroups(newGroups)
  }, [setGroups, createGroups])

  const onSelectBenchmark = useCallback((value, groupIndex, componentIndex) => {
    const { newGroups, group } = createGroups(groupIndex)

    group.components[componentIndex] = {
      ...group.components[componentIndex],
      componentId: value,
      ordinal: componentIndex + 1
    }

    setGroups(newGroups)
  }, [setGroups, createGroups])

  const onSelectDates = useCallback((value, attribute, groupIndex) => {
    const { newGroups, group } = createGroups(groupIndex)

    group[attribute] = dayjs.utc(value).format(INTERNAL_DATE_FORMAT)
    setGroups(newGroups)
  }, [setGroups, createGroups])

  const onClickEdit = useCallback((groupIndex) => {
    const { newGroups, group } = createGroups(groupIndex)

    group.editMode = !group.editMode
    setGroups(newGroups)
  }, [setGroups, createGroups])

  const onChangeEffectiveDate = useCallback(
    (checked) => {
      setGroups((prevGroups) => {
        return prevGroups.reduce((acc, group, index, groups) => {
          if (index === 0 && !group.startDate) {
            group.startDate = minDate
          }
          const lastGroup = groups?.[index - 1]

          if (!group?.startDate && lastGroup) {
            group.startDate = getNextNearestWorkableDate({
              date: lastGroup.endDate
            })
          }
          return [...acc, { ...group }]
        }, [])
      })
      setEffectiveDate(checked)
    },
    [setGroups, minDate, getNextNearestWorkableDate]
  )

  const onCloseDialog = useCallback(() => { setOpenedDialog(false) }, [])

  useEffect(() => {
    if (benchmarksOptions?.length) {
      const options = benchmarksOptions
        .map(({ benchmarkId, longName, displayName }) => ({
          label: longName || displayName,
          value: benchmarkId
        }))
      setBenchmarkOpts(options)
    }
  }, [benchmarksOptions])

  useEffect(() => {
    if (groups[0].startDate) {
      setEffectiveDate(true)
    }
  }, [groups])

  const disabledEndDateRange = useMemo(() => {
    const lastValidGroup = findLast(groups, (group) => group.startDate)
    return {
      min: getNextNearestWorkableDate({ date: lastValidGroup?.startDate })
    }
  }, [getNextNearestWorkableDate, groups])

  return (
    <Grid container spacing={4} className={classes.components}>
      <Grid item xs={12} className={classes.header}>
        <Box>
          <Text
            text='Components'
            color={theme.palette.darkJungle}
            customFontSize='1.375rem'
            customFontWeight='600'
          />
          <Text
            text='Group benchmarks together and identify their weight to establish the benchmark component.'
            color={theme.palette.dimGray}
            customFontSize='0.875rem'
          />
        </Box>
        {
          isEditing && (
            <Box>
              <Button
                className={clsx(classes.pillBtn, classes.pillSecondary)}
                variant='outlined'
                disabled={isNew}
                onClick={onAddGroup}
                startIcon={<Icon customSize='1rem' name={ICON_NAMES.add} />}
              >
                Add Group
              </Button>
            </Box>
          )
        }
      </Grid>
      {
        groups?.map((group, groupIndex) => {
          const { components } = group
          const isLastElement = groupIndex === groups.length - 1
          const startDate = groups[groupIndex]?.startDate
          const endDate = groups[groupIndex]?.endDate
          const previousGroup = groups?.[groupIndex - 1] || null

          if (!isEditing && !isNew) {
            return (
              <Grid
                item
                md={7}
                xs={12}
                className={isLastElement && groups.length > 2 ? classes.group : ''}
                key={`group-${groupIndex}`}
              >
                <Group group={group} minDate={minDate} benchmarks={benchmarkOpts} />
              </Grid>
            )
          }

          if (isEditing && !group.editMode) {
            const isActiveEditItem = groups.some(({ editMode }) => editMode === true)

            return (
              <Grid
                item
                md={7}
                xs={12}
                className={isLastElement && groups.length > 1 ? classes.group : ''}
                key={`group-${groupIndex}`}
              >
                <Box marginBottom='1.2rem' display='flex' justifyContent='space-between'>
                  <Text
                    text='Benchmark Group'
                    color={theme.palette.romanSilver}
                    customFontSize='1rem'
                    textTransform='uppercase'
                    customFontWeight='600'
                  />
                  <Button
                    className={clsx(classes.pillBtn, classes.pillPrimary, classes.pillMaxHeight)}
                    variant='outlined'
                    disabled={isActiveEditItem}
                    onClick={() => onClickEdit(groupIndex)}
                    startIcon={<Icon color={theme.palette.summitBlue} customSize='0.875rem' name={ICON_NAMES.edit} />}
                  >
                    Edit
                  </Button>
                </Box>
                <Group group={group} minDate={minDate} benchmarks={benchmarkOpts} />
              </Grid>
            )
          }

          return (
            <Grid
              item
              md={7}
              xs={12}
              key={`component-${groupIndex}`}
              className={isLastElement && groups.length > 2 ? classes.editingGroup : ''}
            >
              <Box marginBottom='1.2rem' display='flex' justifyContent='space-between'>
                <Text
                  text='Benchmark Blend'
                  color={theme.palette.romanSilver}
                  customFontSize='1rem'
                  textTransform='uppercase'
                  customFontWeight='600'
                />
                {
                  ((isEditing && group.editMode) || groups.length > 1) && (
                    <Box display='flex' gridGap='0.625rem'>
                      <Button
                        className={clsx(classes.pillBtn, classes.pillPrimary, classes.pillMaxHeight)}
                        variant='outlined'
                        onClick={() => onClickEdit(groupIndex)}
                        startIcon={<Icon color={theme.palette.summitBlue} customSize='1rem' name={ICON_NAMES.edit} />}
                      >
                        Edit
                      </Button>
                      {
                        groups.length > 1 && (
                          <Button
                            className={clsx(classes.pillBtn, classes.pillDelete, classes.pillMaxHeight)}
                            variant='outlined'
                            onClick={() => onRemoveGroup(groupIndex)}
                          >
                            Delete
                          </Button>
                        )
                      }
                    </Box>
                  )
                }
              </Box>
              {
                ((groupIndex === 0 && isEditing && !groups[0]?.startDate) || isNew) && canEditStartStop && (
                  <Box marginLeft='0.1rem' marginBottom='0.5rem'>
                    <FormControlLabel
                      control={<Checkbox value={effectiveDate} onChange={(event) => onChangeEffectiveDate(event.target.checked)} />}
                      label={<span className={classes.effectiveDate}>Set effective date</span>}
                      labelPlacement='end'
                    />
                  </Box>
                )
              }
              {
                effectiveDate && (
                  <Grid container spacing={4}>
                    <Grid item xl={3} md={4} xs={12}>
                      <FormLabel
                        className={classes.label}
                      >
                        Start Date
                      </FormLabel>
                      <DatePicker
                        disableFuture
                        value={startDate}
                        onChange={(value) => onSelectDates(value, 'startDate', groupIndex)}
                        textValue={startDate ? startDate === minDate ? 'Since Inception' : startDate : '--'}
                        disabledDateRange={{
                          min: getNextNearestWorkableDate({ date: previousGroup?.endDate }),
                          max: getNextNearestWorkableDate({ date: endDate, days: -1, searchBackwards: true })
                        }}
                      />
                    </Grid>
                    <Grid item xl={3} md={4} xs={12}>
                      <FormLabel
                        className={classes.label}
                      >
                        End Date
                      </FormLabel>
                      <DatePicker
                        disableFuture
                        value={endDate}
                        onChange={(value) => onSelectDates(value, 'endDate', groupIndex)}
                        textValue={endDate ?? 'Present'}
                        disabledDateRange={{
                          min: getNextNearestWorkableDate({ date: startDate }),
                          max: null
                        }}
                      />
                    </Grid>
                  </Grid>
                )
              }
              {
                components.map(({ componentAllocation, componentId }, componentIndex) => (
                  <Grid container spacing={4} key={`component-${componentIndex}`}>
                    <Grid item xl={6} md={8} xs={12}>
                      <Select
                        placeholder='Select a benchmark'
                        variant='outlined'
                        size='medium'
                        options={benchmarkOpts}
                        onChange={(value) => onSelectBenchmark(value, groupIndex, componentIndex)}
                        value={componentId}
                        disabled={!benchmarkOpts.length}
                        loadingOpts={loadingBenchmarkOpts}
                        readOnly={loadingBenchmarkOpts}
                        className={classes.benchmarkSelect}
                        fullWidth
                        preventOptionsOverflowContainer
                      />
                    </Grid>
                    <Grid item xs={12} md={2}>
                      <NumericInput
                        suffix='%'
                        onValueChange={({ value }) => {
                          onUpdateBenchmarkAllocation(value, groupIndex, componentIndex)
                        }}
                        isAllowed={(values) => {
                          const { floatValue } = values
                          return floatValue <= PERCENTAGE_LIMIT
                        }}
                        defaultValue={componentAllocation ? numeral(componentAllocation).format('0%') : 0}
                      />
                    </Grid>
                    {
                      components.length > 1 && (
                        <Grid item xs={12} md={1} className={classes.removeComponent}>
                          <Remove onClick={() => onRemoveBenchmark(groupIndex, componentIndex)}>
                            <Remove.NoHover>
                              <Icon name={ICON_NAMES.circleMinus} />
                            </Remove.NoHover>
                            <Remove.Hover>
                              <Icon name={ICON_NAMES.circleMinusHover} />
                            </Remove.Hover>
                          </Remove>
                        </Grid>
                      )
                    }
                  </Grid>
                ))
              }
              <Grid container className={classes.addBenchmark}>
                <Button
                  className={clsx(classes.pillBtn, classes.pillPrimary)}
                  variant='outlined'
                  onClick={() => onAddBenchmark(groupIndex)}
                  startIcon={<Icon customSize='1rem' name={ICON_NAMES.add} />}
                >
                  Add Benchmark
                </Button>
              </Grid>
            </Grid>
          )
        })
      }
      <SetEndDateDialog
        setGroups={setGroups}
        openedDialog={openedDialog}
        onCloseDialog={onCloseDialog}
        disabledDateRange={disabledEndDateRange}
        onChangeEffectiveDate={onChangeEffectiveDate}
      />
    </Grid>
  )
}

Groups.propTypes = {
  groups: PropTypes.arrayOf(PropTypes.object),
  setGroups: PropTypes.func,
  benchmarkId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  canEditStartStop: PropTypes.bool,
  firmId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isEditing: PropTypes.bool
}

export default Groups
