import React, { useCallback, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import head from 'lodash/head'
import { Grid, useTheme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import isEmpty from 'lodash/isEmpty'
import first from 'lodash/first'
import { CALC_TYPES, DATE_FORMAT, DATE_TAB_PERIODS } from '../../../../constants'
import { fetchWealthJourneyCategories, getHistoricalRegister } from '../../../../service'
import EmptySection from '../../../atoms/EmptySection'
import DatePeriodTabs from '../../../molecules/DatePeriodTabs'
import WealthJourneyLine from '../../../molecules/WealthJourneyLine'
import WealthJourneyCategory from '../../../molecules/WealthJourneyCategory'
import { useToggle } from '../../../../hooks'
import TableSkeleton from '../../../atoms/TableSkeleton'
import { insertAtIndex } from '../../../../utils'
import { useAppContext } from '../../../../redux/slices/appContext'
import DefaultLayout from '../../../../abundanceEngine/layouts/DefaultLayout'

const useStyles = (theme) => {
  return makeStyles(() => ({
    lineGraphContainer: {
      height: '15.5rem',
      width: '100%',
      minWidth: '13.5rem',
      padding: '0px',
      marginBottom: '2.25rem'
    },
    dateTabPeriodsContainer: {
      width: '100%',
      padding: '0px',
      marginBottom: '0.75rem'
    },
    todo: {
      marginBottom: '1.5rem'
    },
    done: {
      color: theme.palette.grayDone
    },
    highlightArea: {
      padding: '1.5rem'
    },
    emptyState: {
      width: '100%',
      padding: '0 1.5rem'
    },
    containerSkeleton: {
      height: '24rem',
      width: '100%',
      border: `2px solid ${theme.palette.grayDone}`,
      borderRadius: '0.75rem',
      padding: '1.5rem',
      display: 'flex',
      flexDirection: 'column'
    }
  }))
}

const emptyStateStyles = {
  padding: '7rem 2rem',
  borderRadius: '0.75rem'
}

const getChartConfig = (data, color) => ({
  id: 'portfolio value',
  data,
  color,
  strokeWidth: 5
})

const FREQUENCY = DATE_TAB_PERIODS.YEARS
const REQUEST_DATE_FORMAT = 'YYYY-MM-DD'

const getDefaultDatePeriodTabs = (currentYear, numberOfItems) => {
  let datePeriods = [{ label: currentYear.toString(), value: currentYear }]
  const datePeriodsLength = Math.floor(numberOfItems / 2)

  let nextYear = currentYear + 1
  let prevYear = currentYear - 1

  for (let i = 0; i < datePeriodsLength; i += 1, nextYear += 1, prevYear -= 1) {
    datePeriods = [
      { label: prevYear.toString(), value: prevYear },
      ...datePeriods,
      { label: nextYear.toString(), value: nextYear }
    ]
  }
  return datePeriods
}

const DEFAULT_STARTING_YEAR = 2022
const DEFAULT_DATE_PERIODS_LENGTH = 5

const NewWealthJourneyHome = ({
  lineChartDataIdentifier,
  version
}) => {
  const theme = useTheme()
  const classes = useStyles(theme)()
  const appContext = useAppContext()
  const [categories, setCategories] = useState([])
  const [categoriesLoading, , toggleCategoriesLoadingOn, toggleCategoriesLoadingOff] = useToggle(true)

  const [datePeriodTabs, setDatePeriodTabs] = useState(getDefaultDatePeriodTabs(DEFAULT_STARTING_YEAR, DEFAULT_DATE_PERIODS_LENGTH))
  const [selectedTabStep, setSelectedTabStep] = useState(first(datePeriodTabs))

  const [rawData, setRawData] = useState([])

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    async function fetchData ({ value }) {
      setLoading(true)
      const startDate = dayjs(`${value}-01-01`).startOf(FREQUENCY).format(REQUEST_DATE_FORMAT)
      const endDate = dayjs(`${value}-01-01`).endOf(FREQUENCY).format(REQUEST_DATE_FORMAT)

      const { data = [] } = await getHistoricalRegister({
        identifier: lineChartDataIdentifier,
        startDate,
        endDate,
        dateType: 'day',
        levelTypes: ['client'],
        clientIds: [appContext.clientId],
        calcType: CALC_TYPES.timeSeries
      })

      // Convert to month dataset because getHistoricalRegister() dateType: 'month' doesn't return day or year
      const newMonthDataSet = []
      let currentMonth = dayjs(data[0]?.values[0]).month()
      let currentYear = dayjs(data[0]?.values[0]).year()
      let previousItem = {}
      if (data[0]) {
        data[0].values.reduce((acc, item) => {
          if (dayjs(item.label).month() !== currentMonth || dayjs(item.label).year() !== currentYear) {
            currentMonth = dayjs(item.label).month()
            currentYear = dayjs(item.label).year()
            if (previousItem.label) {
              newMonthDataSet.push(previousItem)
            }
          }
          previousItem = item
          return acc
        }, 0)
      }

      newMonthDataSet.push(data[0]?.values[data[0]?.values.length - 1])
      if (data[0]) {
        data[0].values = newMonthDataSet
      }

      const summary = head(data)

      if (summary && summary.values) {
        setRawData(summary.values.map((item) => item))
      } else {
        setRawData([])
      }

      setLoading(false)
    }

    if (selectedTabStep) {
      fetchData(selectedTabStep)
    }
  }, [
    appContext.clientId,
    selectedTabStep,
    lineChartDataIdentifier
  ])

  const fetchCategories = useCallback(async () => {
    try {
      toggleCategoriesLoadingOn()
      const { data: wealthJourneyCategories } = await fetchWealthJourneyCategories()
      const wealthJourneyCategoriesOrdered = wealthJourneyCategories.sort(
        (categoryA, categoryB) => categoryA.ordinal - categoryB.ordinal
      )
      setCategories(wealthJourneyCategoriesOrdered)
    } catch (err) {
      console.error(err)
    } finally {
      toggleCategoriesLoadingOff()
    }
  }, [toggleCategoriesLoadingOn, toggleCategoriesLoadingOff])

  useEffect(() => {
    fetchCategories()
  }, [fetchCategories])

  const chartData = useMemo(
    () => {
      return rawData.map(({ label: x, value: y }) => {
        return { x: dayjs(x).format(DATE_FORMAT), y }
      })
    },
    [rawData]
  )

  const onReachNavigationEdge = useCallback(
    ({ prevEdgeReached, nextEdgeReached }, goToSlide, currentSlide) => {
      setDatePeriodTabs(prevDatePeriods => {
        let tabStepIndex = 0
        let value = null
        if (prevEdgeReached) {
          const [{ value: firstValue }] = prevDatePeriods
          const newValue = firstValue - 1
          value = newValue
        } else if (nextEdgeReached) {
          const { value: lastValue } = prevDatePeriods[prevDatePeriods.length - 1]
          tabStepIndex = prevDatePeriods.length
          const newValue = lastValue + 1
          value = newValue
          goToSlide(currentSlide + 1)
        }
        const newDatePeriodTab = { label: value.toString(), value }
        const datePeriods = insertAtIndex(tabStepIndex, prevDatePeriods, newDatePeriodTab)
        return datePeriods
      })
    }, [])

  const onTabStepClick = useCallback((tabStepItem) => {
    setLoading(true)
    setSelectedTabStep(tabStepItem)
  }, [])

  const renderChart = () => {
    if (loading) {
      return (
        <div className={classes.emptyState}>
          <EmptySection styles={emptyStateStyles} />
        </div>
      )
    }

    if (isEmpty(chartData)) {
      return (
        <div className={classes.emptyState}>
          <EmptySection description='No data available' styles={emptyStateStyles} />
        </div>
      )
    }

    return (
      <div className={classes.lineGraphContainer}>
        <WealthJourneyLine
          data={getChartConfig(chartData, theme.palette.jordyBlue)}
          activeDatePeriod={selectedTabStep}
        />
      </div>
    )
  }

  return (
    <DefaultLayout>
      {renderChart()}
      <div className={classes.dateTabPeriodsContainer}>
        <Grid container>
          <DatePeriodTabs
            tabs={datePeriodTabs}
            onTabStepClick={onTabStepClick}
            selectedTabStep={selectedTabStep}
            onReachNavigationEdge={onReachNavigationEdge}
          />
        </Grid>
      </div>
      <Grid container>
        {categoriesLoading
          ? [...Array(4)].map((_, i) => (
            <Grid key={i} className={classes.highlightArea} xs={6} item>
              <div className={classes.containerSkeleton}>
                <TableSkeleton columns={1} rows={5} />
              </div>
            </Grid>
          ))
          : categories.map(({ categoryId: id, categoryName: name }) => {
            return (
              <Grid key={id} className={classes.highlightArea} xs={6} item>
                <WealthJourneyCategory
                  title={name}
                  categoryId={id}
                  entryDateYear={selectedTabStep.value}
                  isLoading={categoriesLoading}
                />
              </Grid>
            )
          })}
      </Grid>
    </DefaultLayout>
  )
}

NewWealthJourneyHome.propTypes = {
  lineChartDataIdentifier: PropTypes.string.isRequired,
  version: PropTypes.string
}

NewWealthJourneyHome.defaultProps = {
  lineChartDataIdentifier: 'endingValue',
  version: 'v1'
}

export default NewWealthJourneyHome
