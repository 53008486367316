import React from 'react'
import PropTypes from 'prop-types'
import Avatar from '../../../atoms/Avatar'

const OwnerTaskItem = ({ owner }) => {
  return (
    <div style={{ width: '150px', margin: '.5rem' }}>
      <div
        style={{
          display: 'flex',
          marginBottom: '.2rem',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >
        <Avatar
          src={owner.photoUrl}
          avatarLetters={owner.avatarLetters ?? ''}
          customSize='64px'
          isPublicResource
        />
        <div style={{ alignSelf: 'center', marginTop: '.5rem' }}>
          <div
            style={{ fontSize: '1rem', fontWeight: '400', textAlign: 'center' }}
          >
            {owner.firstName} {owner.lastName}
          </div>
          <div
            style={{
              fontSize: '0.75rem',
              color: '#999999',
              textAlign: 'center'
            }}
          >
            {owner.taskCount} {`${owner?.taskCount === 1 ? 'Task' : 'Tasks'}`}
          </div>
        </div>
      </div>
    </div>
  )
}

OwnerTaskItem.propTypes = {
  owner: PropTypes.object
}

OwnerTaskItem.defaultProps = {
  owner: {}
}

export default OwnerTaskItem
