import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Box } from '@material-ui/core'
import { isEmpty, noop } from 'lodash'
import { useOktaAuth } from '@okta/okta-react'
import { TEXT_VARIANTS, DATE_PRESET, BUTTON_SIZES, DATE_TYPES, CHART_CURVE_INTERPOLATIONS } from '../../../constants'
import Tag from '../../atoms/Tag'
import DateRangePicker from '../../molecules/DateRangePicker'
import LineChartNivo from '../../molecules/LineChartNivo'
import Skeleton from '../../atoms/Skeleton'
import WebOnlyContent from '../../atoms/WebOnlyContent'
import EmptySection from '../../atoms/EmptySection'
import Text from '../../atoms/Text'
import Select from '../../molecules/Select'
import { defaultOptions } from '../../molecules/RelativeDateSelect'
import { localStorageHelper } from '../../../utils/localStorageHelper'
import { capitalizeFirstLetter } from '../../../utils'
import usePermissions from '../../../hooks/usePermissions'
import { useAuthState } from '../../../hooks'
import { useWidgetContext } from '../../molecules/WidgetWrapper'
import { useLineChart } from './useLineChart'

const dateIntervalOptions = Object.values(DATE_TYPES).map((dateType) => ({
  key: dateType,
  value: dateType,
  label: capitalizeFirstLetter(dateType)
}))

const LineChartContainerV2 = ({
  lineChartProps,
  showTagLabel,
  series: _series,
  assetFilters,
  defaultGraphDate,
  onGraphDateChange,
  identifiersMapper,
  dateRangeOptions,
  showPlottingOptions,
  defaultFilter: _defaultFilter,
  useSingleSeries,
  seriesColors,
  seriesLabels,
  showSeriesTitle,
  dateIntervalOptions,
  defaultDataInterval,
  configurationStorageKey: _configurationStorageKey,
  intervalBreakpoints
}) => {
  const { authState: oktaAuthState } = useOktaAuth() || {}
  const authState = useAuthState(oktaAuthState)
  const { isSummitUser } = usePermissions(authState.accessToken || {})

  const series = useMemo(() => {
    const findById = (_id) => ({ id }) => id === _id
    return _series.map((item) => {
      const color = seriesColors.find(findById(item.id))
      const label = seriesLabels.find(findById(item.id))
      const seriesOption = { ...color, ...label, ...item }
      return seriesOption
    })
  }, [seriesColors, seriesLabels, _series])

  const configurationStorageKey = _configurationStorageKey
    ? `line_chart_${_configurationStorageKey}`
    : undefined

  const defaultValues = useMemo(() => {
    const defaultConfiguration = {
      dateRange: null,
      dataInterval: defaultDataInterval,
      graphCurve: lineChartProps?.curve ?? CHART_CURVE_INTERPOLATIONS.monotoneX,
      graphDate: defaultGraphDate,
      identifier: useSingleSeries || 'endingValue'
    }
    if (!configurationStorageKey) return defaultConfiguration
    const configuration = localStorageHelper.load(configurationStorageKey)
    return configuration || defaultConfiguration
  }, [
    useSingleSeries,
    defaultGraphDate,
    lineChartProps?.curve,
    defaultDataInterval,
    configurationStorageKey
  ])

  const { subscribedFilters } = useWidgetContext()

  const defaultFilter = useMemo(() => {
    return Object.assign({}, _defaultFilter, subscribedFilters?.levelFilters)
  }, [_defaultFilter, subscribedFilters?.levelFilters])

  const lineChart = useLineChart({
    identifiersMapper,
    assetFilters,
    series,
    lineChartProps,
    onGraphDateChange,
    defaultFilter,
    dateRangeOptions,
    defaultValues,
    configurationStorageKey,
    dateIntervalOptions,
    intervalBreakpoints
  })

  const {
    historicalIdentifierOptions,
    identifiersLoading,
    loading,
    showDateRangePicker,
    onSelectIdentifier,
    selectedIdentifier,
    baseLineChartProps,
    availableDates,
    onAvailableDateSelect,
    onDateRangeChange,
    selectedGraphDate,
    graphDateRangeOptions,
    dateInterval,
    chartCurve
  } = lineChart

  const renderIdentifiersSelector = useMemo(() => {
    if (useSingleSeries) {
      const seriesData = series.find(({ id }) => useSingleSeries === id)
      if (!showSeriesTitle) return null
      return (
        <Text
          variant={TEXT_VARIANTS.body1}
          text={seriesData?.title || seriesData?.label}
        />
      )
    }
    if (identifiersLoading) {
      return <Skeleton height='2rem' width='10rem' />
    }
    return (
      <Select
        size={BUTTON_SIZES.small}
        value={selectedIdentifier}
        options={historicalIdentifierOptions}
        onChange={onSelectIdentifier}
        showCheckMarOnSelectedItems
      />
    )
  }, [
    series,
    showSeriesTitle,
    useSingleSeries,
    identifiersLoading,
    selectedIdentifier,
    onSelectIdentifier,
    historicalIdentifierOptions
  ])
  if (!loading && isEmpty(baseLineChartProps)) {
    return <EmptySection title='No Data Available' emptyDescription />
  }
  return (
    <>
      <Box display='flex' width='100%' alignItems='center'>
        {renderIdentifiersSelector}
        {showTagLabel && (
          <Box ml='1rem'>
            <Tag label='Filtered' />
          </Box>
        )}
        <Box display='flex' flexDirection='row' ml='auto'>
          {showDateRangePicker && (
            <Box mr='2rem'>
              <DateRangePicker
                datePickerWidth='12rem'
                defaultStartDate={null}
                defaultEndDate={null}
                onChange={onDateRangeChange}
                minDate={availableDates.min}
                maxDate={availableDates.max}
              />
            </Box>
          )}
          <WebOnlyContent>
            {showPlottingOptions.dateRange && (
              <Select
                size={BUTTON_SIZES.small}
                value={selectedGraphDate}
                onChange={onAvailableDateSelect}
                options={graphDateRangeOptions}
              />
            )}
            {showPlottingOptions.dateInterval && isSummitUser && (
              <Select {...dateInterval} />
            )}
            {showPlottingOptions.chartCurve && <Select {...chartCurve} />}
          </WebOnlyContent>
        </Box>
      </Box>
      {loading ? (
        <Box mt='2rem' width='100%'>
          <Skeleton height='20rem' width='100%' />
        </Box>
      ) : (
        <LineChartNivo
          {...baseLineChartProps}
          dateInterval={dateInterval.value}
        />
      )}
    </>
  )
}

LineChartContainerV2.propTypes = {
  lineChartProps: PropTypes.object,
  showTagLabel: PropTypes.bool,
  series: PropTypes.array,
  assetFilters: PropTypes.object,
  identifiersMapper: PropTypes.any,
  defaultGraphDate: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
    .isRequired,
  onGraphDateChange: PropTypes.func,
  accountCategoryFilters: PropTypes.array,
  dateRangeOptions: PropTypes.array,
  showPlottingOptions: PropTypes.shape({
    dateInterval: PropTypes.bool,
    chartCurve: PropTypes.bool,
    dateRange: PropTypes.bool
  }),
  levelFilters: PropTypes.shape({
    benchmarkType: PropTypes.string
  }),
  useSingleSeries: PropTypes.string,
  seriesLabels: PropTypes.array,
  seriesColors: PropTypes.array,
  configurationStorageKey: PropTypes.string,
  dateIntervalOptions: PropTypes.shape({
    key: PropTypes.oneOf(Object.values(DATE_TYPES)),
    value: PropTypes.oneOf(Object.values(DATE_TYPES)),
    label: PropTypes.string
  }),
  defaultDataInterval: PropTypes.string,
  showSeriesTitle: PropTypes.bool,
  defaultFilter: PropTypes.object,
  /* Determines the correlation between number of days and a date interval for automatic date intervals */
  intervalBreakpoints: PropTypes.shape({
    day: PropTypes.number,
    month: PropTypes.number,
    quarter: PropTypes.number,
    year: PropTypes.number
  })
}

LineChartContainerV2.defaultProps = {
  lineChartProps: {},
  showTagLabel: false,
  series: [
    {
      id: 'clientBenchmark',
      label: 'Client Benchmark',
      config: { enableArea: false }
    },
    {
      id: 'cumulativeReturn',
      label: 'Return',
      config: { enableArea: false }
    },
    {
      id: 'endingValue',
      label: 'Market Value',
      config: { enableArea: false }
    },
    {
      id: 'adjustedNetAdditions',
      label: 'Net Additions',
      config: { enableArea: false }
    }
  ],
  useSingleSeries: undefined,
  assetFilters: {},
  identifiersMapper: 'skipCumulativeBenchmarkMapper',
  onGraphDateChange: noop,
  defaultGraphDate: DATE_PRESET.L12,
  dateRangeOptions: defaultOptions,
  showPlottingOptions: {
    dateInterval: true,
    chartCurve: false,
    dateRange: true
  },
  levelFilters: {},
  // just for backwards compatibility, remove after we start using the new perf view
  seriesLabels: [],
  seriesColors: [],
  configurationStorageKey: undefined,
  defaultDataInterval: undefined,
  dateIntervalOptions,
  showSeriesTitle: true,
  intervalBreakpoints: undefined
}

export default LineChartContainerV2
