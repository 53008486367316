import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import IconContainer from './IconContainer'

const variantSizes = {
  round: {
    xs: {
      width: '6rem',
      height: '6rem',
      borderRadius: '.6rem',
      fontSize: '.6rem'
    },
    sm: {
      width: '8rem',
      height: '8rem',
      borderRadius: '8rem',
      fontSize: '.8rem'
    },
    md: {
      width: '10rem',
      height: '10rem',
      borderRadius: '10rem',
      fontSize: '1rem'
    },
    lg: {
      width: '15rem',
      height: '15rem',
      borderRadius: '12rem',
      fontSize: '1.3rem',
      spread: {
        '& .__icon-container': {
          width: '80px',
          height: '80px',
          borderRadius: '100px',
          '& svg': {
            width: '40px',
            height: '40px'
          }
        }
      }
    }
  },
  square: {
    xs: {
      width: '6rem',
      height: '6rem',
      borderRadius: '.3rem',
      fontSize: '.6rem'
    },
    sm: {
      width: '10rem',
      height: '10rem',
      borderRadius: '.4rem',
      fontSize: '.6rem'
    },
    md: {
      width: '12rem',
      height: '12rem',
      borderRadius: '.5rem',
      fontSize: '.8rem'
    },
    lg: {
      width: '15rem',
      height: '15rem',
      borderRadius: '.6rem',
      fontSize: '1.3rem',
      spread: {
        '& .__icon-container': {
          width: '80px',
          height: '80px',
          borderRadius: '100px',
          '& svg': {
            width: '40px',
            height: '40px'
          }
        }
      }
    }
  }
}

const useStyles = makeStyles((theme) => ({
  optionCard: props => {
    const { width, height, borderRadius, spread } = variantSizes[props.variant][props.size]

    const retProps = {
      textDecoration: 'none !important',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '1rem',
      outline: `.5px solid ${theme.palette.gray.dark}`,
      transition: 'background-color 0.3s ease-in-out, outline 0.3s ease-in-out, fill 0.3s ease-in-out',
      boxShadow: `1px 2px 10px -9px ${theme.palette.summitBlue}`,
      '&:hover': props.allowHover ? {
        background: 'white',
        outline: `2.5px solid ${theme.palette.summitBlue}`
      } : undefined,
      '& svg': {
        transition: 'fill 100ms ease-in-out'
      },
      ...(spread || {})
    }

    retProps.width = props.fullSize ? '100%' : width
    retProps.height = props.fullSize ? '100%' : height
    retProps.borderRadius = borderRadius

    if (typeof props.justify !== 'undefined') {
      retProps.alignItems = props.justify
    }

    if (props.outlined) {
      retProps.border = '1px solid transparent'
    }

    if (props.active) {
      retProps.borderColor = theme.palette.summitBlue
    }

    if (!props.active) {
      retProps.border = undefined
      retProps.backgroundColor = theme.palette.gray.lighter
    }

    retProps['&:hover svg'] = {
      fill: theme.palette.white
    }

    retProps['&:hover > div'] = {
      backgroundColor: theme.palette.summitBlue
    }

    return retProps
  },
  optionText: props => {
    const { fontSize } = variantSizes[props.variant][props.size]
    return {
      fontSize: fontSize,
      padding: '.5rem 0',
      margin: 0
    }
  }
}))

// 248 248 248 - #f8f8f8

const OptionCard = (props) => {
  const { icon: Icon, onClick, title, active, to } = props

  const classes = useStyles(props)

  return (
    <Link to={to} className={classes.optionCard} onClick={onClick}>
      <IconContainer active={active}>
        <Icon fillColor={active ? '#ffffff' : '#212a43'} />
      </IconContainer>
      <p className={classes.optionText}>{title}</p>
    </Link>
  )
}

OptionCard.propTypes = {
  to: PropTypes.string,
  // eslint-disable-next-line react/no-unused-prop-types
  variant: PropTypes.oneOf(['round', 'square']),
  // eslint-disable-next-line react/no-unused-prop-types
  justify: PropTypes.oneOf(['center', 'start', 'end']),
  // eslint-disable-next-line react/no-unused-prop-types
  outlined: PropTypes.bool,
  // eslint-disable-next-line react/no-unused-prop-types
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  title: PropTypes.string.isRequired,
  icon: PropTypes.element.isRequired,
  onClick: PropTypes.func.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  active: PropTypes.bool,
  // eslint-disable-next-line react/no-unused-prop-types
  allowHover: PropTypes.bool,
  // eslint-disable-next-line react/no-unused-prop-types
  fullSize: PropTypes.bool
}

OptionCard.defaultProps = {
  variant: 'square',
  size: 'md',
  justify: 'center',
  outlined: false,
  allowHover: true,
  fullSize: false
}

export default OptionCard
