import { ResponsiveLine } from '@nivo/line'
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import MonteCarlo from '../../../../hooks/monteCarlo'

const plotGaussianCurve = () => {
  const sampleSize = 10000
  const samples = []
  for (let index = 0; index < sampleSize; index++) {
    const randomNumber = MonteCarlo.gaussianRandom(0.079, 0.079 * 1.5, [])
    // const randomNumber = MonteCarlo.gaussianRandom(0, 1, [])
    samples.push(randomNumber)
  }
  const min = samples.reduce((prev, current) => {
    return (prev < current) ? prev : current
  })
  const max = samples.reduce((prev, current) => {
    return (prev > current) ? prev : current
  })
  // samples.push(min * 100)
  // samples.push(max * 100)
  const groupData = (values, step, min, max) => {
    const groupedData = []
    for (let i = min; i <= max; i = i + step) {
      const stepCount = values.filter(x => x >= i && x < (i + step)).length
      groupedData.push({ x: i, y: stepCount })
    }
    return groupedData
  }
  const groupedData = groupData(samples, 0.01, min, max)
  return [
    {
      id: 'Distribution',
      color: '#2c81F4',
      data: groupedData
    }
  ]
}

const MonteCarloStochasticModel = ({ height = 300, width = 600 }) => {
  const [data] = useState(plotGaussianCurve())

  return (
    <>
      <div
        style={{
          background: 'transparent',
          height,
          width
        }}
      >
        Monte Carlo Stochastic Model {11} ms
        <ResponsiveLine
          animate
          data={data}
          margin={{ top: 30, right: 30, bottom: 30, left: 45 }}
          xScale={{
            type: 'linear',
            tickValues: 10,
            min: -0.45,
            max: 0.55
          }}
          layers={[
            'grid',
            'markers',
            'axes',
            'areas',
            'crosshair',
            'lines',
            'points',
            'slices',
            'mesh',
            'legends'
          ]}
          colors={(d) => d.color}
          lineWidth={3}
          curve='basis'
          enableArea
          enableGridX={false}
          pointSize={0}
          areaOpacity={0.8}
          useMesh
          motionStiffness={175}
          motionDamping={25}
          theme={{
            axis: {
              legend: {
                text: {
                  fontFamily: 'GothamPro',
                  fontWeight: 700
                }
              },
              ticks: {
                text: {
                  fontFamily: 'GothamPro',
                  fontWeight: 700
                }
              }
            }
          }}
        />
      </div>
    </>
  )
}

MonteCarloStochasticModel.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number
}

export default MonteCarloStochasticModel
