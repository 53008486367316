import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import PropTypes from 'prop-types'

const useStyles = makeStyles((theme) => ({
  advisorViewLayoutTabContent: {
    display: 'flex',
    flexDirection: 'row',
    flex: '1 0 auto'
  }
}))

const TabContent = ({ children, className, style }) => {
  const classes = useStyles()
  return (
    <div className={clsx(classes.advisorViewLayoutTabContent, className)} style={style}>
      {children}
    </div>
  )
}

TabContent.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  style: PropTypes.object
}

export default TabContent
