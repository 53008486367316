import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Box, makeStyles } from '@material-ui/core'
import numeral from 'numeral'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { isEmpty, last } from 'lodash'
import {
  BUTTON_SIZES,
  CALC_TYPES,
  DATE_TYPES,
  INTERNAL_DATE_FORMAT,
  LEVEL_TYPES
} from '../../constants'
import { useAppContext } from '../../redux/slices/appContext'
import { useGetGroupedCoreData, useNormalizeDates } from '../../api/coreData'
import Skeleton from '../atoms/Skeleton'
import EmptySection from '../atoms/EmptySection'
import StreamChart from './StreamChart'
import Select from './Select'
import { defaultOptions, useRelativeDateRange } from './RelativeDateSelect'
import { useWidgetContext } from './WidgetWrapper'

dayjs.extend(utc)

const useStyles = makeStyles(() => ({
  accordionSummary: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  options: {
    marginLeft: 'auto'
  }
}))

const mapHistoricalData = (data, groupBy) => {
  const dateBucketValues = data.reduce((acc, datum) => {
    const dateLabel = dayjs.utc(datum.maxDate).format(INTERNAL_DATE_FORMAT)
    return {
      ...acc,
      [dateLabel]: datum.endingValue + (acc?.[dateLabel] || 0)
    }
  }, {})

  const { groups } = data.reduce(
    (acc, datum) => {
      const groupId = datum[`${groupBy}Id`]
      const date = dayjs.utc(datum.maxDate)
      const dateLabel = date.format(INTERNAL_DATE_FORMAT)
      const prevGroup = acc?.groups?.[groupId] || {}

      const value = acc.dateBucketValues[dateLabel]
        ? datum.endingValue / acc.dateBucketValues[dateLabel]
        : 0

      return {
        ...acc,
        groups: {
          ...(acc?.groups || {}),
          [groupId]: {
            id: groupId,
            name: datum?.[`${groupBy}Name`] || '',
            data: [
              ...(prevGroup?.data ?? []),
              { value, label: dateLabel }
            ],
            color: datum[`${groupBy}ColorField`],
            ordinal: datum[`${groupBy}Ordinal`]
          }
        }
      }
    },
    { dateBucketValues }
  )
  return Object.values(groups ?? {})
}

const AllocationHistoryChart = ({
  groupBy,
  axisBottom,
  queryParams,
  defaultDateRange,
  emptySectionLabel,
  showPlottingOptions,
  filterEmptyCategories,
  defaultDateRangeOptions
}) => {
  const classes = useStyles()
  const { availableDates, clientId } = useAppContext()
  const [selectedGraphDate, setSelectedGraphDate] = useState(defaultDateRange)

  const { dateRange: selectedGraphDataValue, options: graphDateRangeOptions } =
    useRelativeDateRange(
      selectedGraphDate,
      availableDates,
      defaultDateRangeOptions
    )

  const normalizedDatesQuery = useMemo(() => {
    return {
      dateRanges: {
        allocationHistoryDateRange: selectedGraphDataValue
      }
    }
  }, [selectedGraphDataValue])

  const {
    data: normalizedDateRanges,
    isLoading: isLoadingNormalizedDates
  } = useNormalizeDates(normalizedDatesQuery)

  const { subscribedFilters } = useWidgetContext()

  const defaultFilter = useMemo(() => {
    return Object.assign({}, queryParams, subscribedFilters?.levelFilters)
  }, [queryParams, subscribedFilters])

  const { query, queryOptions } = useMemo(() => {
    const { mainDate } = availableDates
    const { allocationHistoryDateRange } = normalizedDateRanges || {}
    const levelFilters = {
      clientIds: [clientId],
      dateType: DATE_TYPES.month,
      calcType: CALC_TYPES.timeSeries,
      levelTypes: [LEVEL_TYPES.CLIENT, groupBy],
      ...defaultFilter
    }
    const groupByLevelType = last(levelFilters.levelTypes)
    return {
      query: {
        dateRange: {
          startDate: allocationHistoryDateRange?.startDate || mainDate,
          endDate: allocationHistoryDateRange?.endDate || mainDate
        },
        levelFilters
      },
      queryOptions: {
        mapper: (data) => {
          return mapHistoricalData(data, groupByLevelType)
        },
        enabled: !isLoadingNormalizedDates,
        keepPreviousData: false
      }
    }
  }, [
    groupBy,
    clientId,
    defaultFilter,
    availableDates,
    normalizedDateRanges,
    isLoadingNormalizedDates
  ])

  const { data, isLoading } = useGetGroupedCoreData(query, queryOptions)

  const chartProps = useMemo(() => {
    const { format, ...axisBottomOverrides } = axisBottom
    return {
      axisLeft: {
        format: (value) => {
          const valueFormatted = numeral(value).format('0%')
          return valueFormatted
        }
      },
      tooltipFormat: (value) => {
        const valueFormatted = numeral(value).format('0%')
        return valueFormatted
      },
      axisBottom: axisBottomOverrides,
      axisBottomFormatter: (value) => {
        if (!value) return value
        return dayjs
          .utc(value)
          .format(axisBottom?.format || INTERNAL_DATE_FORMAT)
      }
    }
  }, [axisBottom])

  return (
    <div>
      <Box className={classes.accordionSummary}>
        {showPlottingOptions?.dateRange && (
          <div className={classes.options}>
            <Select
              size={BUTTON_SIZES.small}
              value={selectedGraphDate}
              onChange={setSelectedGraphDate}
              options={graphDateRangeOptions}
              readOnly={graphDateRangeOptions?.length <= 1}
            />
          </div>
        )}
      </Box>
      {!isLoading && !isEmpty(data) && (
        <StreamChart
          series={data}
          {...chartProps}
          filterEmptyCategories={filterEmptyCategories}
        />
      )}
      {!isLoading && isEmpty(data) && (
        <EmptySection
          title={emptySectionLabel}
          description=''
          styles={{ padding: '5rem 2rem' }}
        />
      )}
      {isLoading && <Skeleton height='36rem' width='100%' />}
    </div>
  )
}

AllocationHistoryChart.propTypes = {
  groupBy: PropTypes.string,
  queryParams: PropTypes.object,
  defaultDateRange: PropTypes.string,
  defaultDateRangeOptions: PropTypes.array,
  axisBottom: PropTypes.shape({
    format: PropTypes.string,
    tickRotation: PropTypes.number
  }),
  filterEmptyCategories: PropTypes.bool,
  showPlottingOptions: PropTypes.shape({
    dateRange: PropTypes.bool
  }),
  emptySectionLabel: PropTypes.string
}

AllocationHistoryChart.defaultProps = {
  groupBy: 'assetClassTag',
  queryParams: {},
  defaultDateRange: 'L12M',
  defaultDateRangeOptions: defaultOptions,
  axisBottom: {
    format: 'MM/YY',
    tickRotation: -45
  },
  filterEmptyCategories: true,
  showPlottingOptions: {
    dateRange: true
  },
  emptySectionLabel: 'No Data Available'
}

export default AllocationHistoryChart
