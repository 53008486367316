import React, { createContext, useCallback, useContext, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import SectionScreen from '../shared/SectionScreen'

export const AccountFormContext = createContext({ })

export const useAccountFormContext = () => {
  return useContext(AccountFormContext)
}

export const useSectionEditing = (sectionName) => {
  const { editing, section } = useAccountFormContext()
  return useMemo(() => {
    return editing && section === sectionName
  }, [editing, section, sectionName])
}

const useProviderValue = ({ account, isFetching }) => {
  const [formState, setFormState] = useState({
    editing: false,
    section: null
  })

  const editSection = useCallback(({
    section
  }) => {
    setFormState((prevState) => ({
      ...prevState,
      editing: !prevState.editing,
      section: !prevState.editing ? section : null
    }))
  }, [setFormState])

  const cancelEdit = useCallback(() => {
    setFormState((prevState) => ({
      ...prevState,
      editing: false,
      section: null
    }))
  }, [setFormState])

  return useMemo(() => ({
    account,
    editSection,
    cancelEdit,
    isFetching,
    sectionEditing: (section) => formState.editing && formState.section === section,
    ...formState
  }), [account, editSection, cancelEdit, formState, isFetching])
}

export const AccountFormProvider = ({ account, isFetching, children }) => {
  const value = useProviderValue({
    account,
    isFetching
  })

  return (
    <AccountFormContext.Provider value={value}>
      {children}
    </AccountFormContext.Provider>
  )
}

AccountFormProvider.propTypes = {
  account: PropTypes.object,
  children: PropTypes.node,
  isFetching: PropTypes.bool
}

AccountFormProvider.defaultProps = {
}

export const AccountFormSection = ({ section, children }) => {
  const { editing, sectionEditing } = useAccountFormContext()

  return (
    <SectionScreen editing={editing} sectionIsEditing={sectionEditing(section)}>
      {children}
    </SectionScreen>
  )
}

AccountFormSection.propTypes = {
  section: PropTypes.string,
  children: PropTypes.node
}
