import {
  ICON_NAMES,
  REDTAIL_INTEGRATION,
  SALESFORCE_INTEGRATION,
  WEALTHBOX_INTEGRATION
} from '../../../constants'

export const getIntegrationType = (integrations) => {
  const {
    showRedTailClientInfo,
    showWealthBoxClientInfo,
    showSalesforceClientInfo,
    showPractifiClientInfo
  } = integrations

  if (showSalesforceClientInfo) {
    return SALESFORCE_INTEGRATION
  }
  if (showWealthBoxClientInfo) {
    return WEALTHBOX_INTEGRATION
  }
  if (showRedTailClientInfo) {
    return REDTAIL_INTEGRATION
  }
  if (showPractifiClientInfo) {
    return 'practifi'
  }
  return null
}

export const getIntegrationTitle = (integrationType) => {
  if (integrationType === SALESFORCE_INTEGRATION) {
    return {
      title: 'Salesforce',
      icon: ICON_NAMES.salesforceSmall,
      iconSize: '1.5rem',
      largeIcon: ICON_NAMES.salesforce,
      largeIconSize: '4.5rem'
    }
  }
  if (integrationType === WEALTHBOX_INTEGRATION) {
    return {
      title: 'Wealthbox',
      icon: ICON_NAMES.wealthboxSmall,
      iconSize: '1.5rem',
      largeIcon: ICON_NAMES.wealthbox,
      largeIconSize: '7.5rem'
    }
  }
  if (integrationType === REDTAIL_INTEGRATION) {
    return {
      title: 'Redtail',
      icon: ICON_NAMES.redtailSmall,
      iconSize: '1.5rem',
      largeIcon: ICON_NAMES.redtail,
      largeIconSize: '6.5rem'
    }
  }
  if (integrationType === 'practifi') {
    return {
      title: 'Practifi',
      iconUrl: '/practifi-icon.png',
      iconSize: 'xs',
      largeIconUrl: '/practifi-full-logo.png',
      largeIconSize: '6.5rem'
    }
  }
  return null
}
