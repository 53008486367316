import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import OperationalTable, { useOperationalTable } from '../../../organisms/OperationalTable'
import { useLevelDates } from '../../../../api/coreData'
import { defaultColumnConfig, levelDatesQuery } from './helpers'
import { useHoldings } from './hooks'
dayjs.extend(utc)

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '0',
    width: '100%'
  }
}))

const FORMAT = 'YYYY-MM-DD'

function Holdings ({ assetId }) {
  const {
    defaultPageSize,
    pageIndex,
    pageSize,
    onPagingChange,
    onSortingChange,
    onTableModeChange
  } = useOperationalTable(defaultColumnConfig.defaultSort)
  const classes = useStyles()

  const { data: userLevelDates = {} } = useLevelDates(levelDatesQuery)
  const { data = [], loading } = useHoldings({
    pageIndex,
    pageSize,
    startDate: dayjs(userLevelDates.min).format(FORMAT),
    endDate: dayjs(userLevelDates.max).format(FORMAT),
    assetId
  })

  return (
    <div className={classes.container}>
      <OperationalTable.Wrapper>
        <OperationalTable
          mode='server'
          columns={defaultColumnConfig.columns}
          data={data}
          defaultPageSize={defaultPageSize}
          defaultSort={defaultColumnConfig.defaultSort}
          itemName='Accounts'
          loading={loading}
          total={data.length}
          onPagingChange={onPagingChange}
          onSortingChange={onSortingChange}
          onTableModeChange={onTableModeChange}
        />
      </OperationalTable.Wrapper>
    </div>
  )
}

Holdings.propTypes = {
  assetId: PropTypes.number.isRequired
}

export default Holdings
