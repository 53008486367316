import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Editor from '../WYSIWYGEditor'
import RoundedButton from '../../atoms/RoundedButton'
import FadeIn from '../../molecules/Transitions/FadeIn'

const useStyles = makeStyles((theme) => ({
  editor: {
    '& .__actions': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      borderTop: `1px solid ${theme.palette.gray.dark}`,
      gap: '5px',
      padding: '8px 10px',
      marginBottom: '20px'
    }
  }
}))

const CommentEditor = ({ onSubmit, onCancel, defaultValue }) => {
  const [value, setValue] = useState('')
  const handleSubmit = useCallback(() => {
    onSubmit(value)
  }, [onSubmit, value])
  const classes = useStyles()

  return (
    <FadeIn className={classes.editor}>
      <Editor
        defaultValue={defaultValue}
        placeholder='Enter your comment...'
        isMarkdown
        onChange={setValue}
      />
      <div className='__actions'>
        <RoundedButton primary onClick={handleSubmit}>Submit</RoundedButton>
        <RoundedButton tertiary onClick={onCancel}>Cancel</RoundedButton>
      </div>
    </FadeIn>
  )
}

CommentEditor.propTypes = {
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  defaultValue: PropTypes.any
}

export default CommentEditor
