import React from 'react'
import { useWealthJourney } from '../WealthJourneyProvider'
import PillButtonGroup, { PillButtonGroupOption } from '../../../../molecules/PillButtonGroup'

function WJTabList () {
  const { selectedTab, setSelectedTab, tabOptions } = useWealthJourney()

  return (
    <PillButtonGroup selectedValue={selectedTab} onSelect={setSelectedTab}>
      {tabOptions.map((t) => (
        <PillButtonGroupOption value={t.id} key={t.key}>{t.label}</PillButtonGroupOption>
      ))}
    </PillButtonGroup>
  )
}

WJTabList.propTypes = {}

export default WJTabList
