import React from 'react'
import PropTypes from 'prop-types'
import { Link, useLocation } from 'react-router-dom'
import { Button, makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import Tooltip from '../../../atoms/Tooltip'

const useStyles = makeStyles(() => ({
  button: {
    borderRadius: '2rem',
    border: '1.5px solid #212945',
    backgroundColor: '#FFF',
    textTransform: 'none',
    padding: '0.5rem 1rem',
    '& > span': {
      fontSize: '0.75rem',
      lineHeight: '0.75rem',
      color: '#212945',
      fontWeight: 'bold'
    }
  }
}))

const AdvisorHomeLink = ({ to, tooltipTitle, children, openInNewTab, variant }) => {
  const classes = useStyles()
  const location = useLocation()
  return (
    <Tooltip title={tooltipTitle}>
      <Button
        size='small'
        target={openInNewTab ? '_blank' : '_self'}
        to={{
          pathname: to,
          search: location.search
        }}
        component={Link}
        className={clsx({
          [classes.button]: variant === 'button'
        })}
      >
        {children}
      </Button>
    </Tooltip>
  )
}

AdvisorHomeLink.propTypes = {
  children: PropTypes.node,
  to: PropTypes.string,
  openInNewTab: PropTypes.bool,
  tooltipTitle: PropTypes.string,
  variant: PropTypes.oneOf(['button', 'link'])
}

AdvisorHomeLink.defaultProps = {
  to: '/',
  openInNewTab: false,
  tooltipTitle: undefined,
  variant: 'button'
}

export default AdvisorHomeLink
