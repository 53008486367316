import React, { useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import { useAppContext } from '../../../redux/slices/appContext'
import { useAccountSearch } from '../../../api/coreData'
import Loading from '../../molecules/Loading'
import { useTargetAllocationScope } from './TargetAllocationScopeProvider'

const useStyles = makeStyles(theme => ({
  selectionItem: {
    userSelect: 'none',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    borderRadius: theme.layout.radius.loose,
    padding: `${theme.layout.padding.p5} ${theme.layout.padding.p10}`,
    outline: '1px solid transparent',
    transition: 'outline-color .2s ease-in-out',
    '&:not(.__disabled):hover': {
      cursor: 'pointer',
      outlineColor: theme.palette.primary.main
    },
    '&:not(.__disabled):active': {
      cursor: 'pointer',
      backgroundColor: `color-mix(in srgb, ${theme.palette.primary.main} 20%, transparent)`
    },
    '&.__disabled': {
      color: `color-mix(in srgb, ${theme.palette.text.primary} 50%, transparent)`
    },
    '& .__model-name': {
      fontWeight: theme.typography.weights.light
    },
    '& .__account-name': {
      fontSize: theme.typography.fontSizes.xl
    }
  },
  selected: {
    outline: `1px solid ${theme.palette.primary.main}`,
    backgroundColor: `color-mix(in srgb, ${theme.palette.primary.main} 10%, transparent)`
  },
  list: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.layout.gap.g5,
    padding: theme.layout.gap.g5,
    width: '100%',
    overflowY: 'auto'
  }
}))

function AccountTargetSelectionItem ({ selectedItem, item, onSelect, classes }) {
  const isSelected = selectedItem === item?.accountId
  const disabled = !item.hasTargetModel
  return (
    <div
      onClick={() => !disabled && onSelect(item.accountId)}
      className={clsx(classes.selectionItem, { [classes.selected]: isSelected, __disabled: disabled })}
    >
      <div className='__account-name'>{item.accountName}</div>
      <div className='__model-name'>
        {item.modelName ? item.modelName : <em>No Model Assigned</em>}
      </div>
    </div>
  )
}

AccountTargetSelectionItem.propTypes = {
  selectedItem: PropTypes.number,
  item: PropTypes.shape({
    accountId: PropTypes.number,
    hasTargetModel: PropTypes.bool,
    accountName: PropTypes.string,
    accountNumber: PropTypes.string,
    modelName: PropTypes.string
  }),
  onSelect: PropTypes.func,
  classes: PropTypes.object.isRequired
}

function AccountTargetSelector ({ hasTargetModel, height }) {
  const classes = useStyles()
  const { clientId } = useAppContext()
  const { currentLevelId, setCurrentLevelId } = useTargetAllocationScope()
  const query = useMemo(() => ({
    filters: {
      assignedToClientIds: [clientId]
    },
    includes: {
      targetModels: true
    }
  }), [clientId])
  const { data, isLoading } = useAccountSearch(query, { enabled: !!query })

  const items = useMemo(() => {
    return (data?.data || []).map(acct => ({
      accountId: acct.accountId,
      hasTargetModel: acct.targetModels?.length > 0,
      accountName: `${acct.accountName} ${acct.accountNumber ? ' - ' + acct.accountNumber : ''}`,
      modelName: acct.targetModels?.length ? acct.targetModels.at(0).targetModelName : null
    })).filter(x => hasTargetModel === null || hasTargetModel === x.hasTargetModel).sort((a, b) => {
      return a.hasTargetModel ? -1 : b.hasTargetModel ? 1 : 0
    })
  }, [data, hasTargetModel])

  useEffect(() => {
    const withModels = items.filter(x => x.hasTargetModel)
    if (withModels.length) {
      const firstItem = withModels.at(0)
      if (!currentLevelId) {
        setCurrentLevelId(firstItem.accountId)
      }
    }
  }, [currentLevelId, setCurrentLevelId, items])

  if (isLoading) {
    return <Loading />
  }

  return (
    <div className={classes.list} style={{ height }}>
      {items.map((item) => (
        <AccountTargetSelectionItem
          key={item.accountId}
          selectedItem={currentLevelId}
          item={item}
          onSelect={setCurrentLevelId}
          classes={classes}
        />
      ))}
    </div>
  )
}

AccountTargetSelector.propTypes = {
  hasTargetModel: PropTypes.bool,
  height: PropTypes.string
}

AccountTargetSelector.defaultProps = {
  hasTargetModel: true,
  height: '300px'
}

export default AccountTargetSelector
