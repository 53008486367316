import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { useAppContext } from '../../../../../redux/slices/appContext'
import { localStorageHelper } from '../../../../../utils/localStorageHelper'
import { useFullClientSearch } from '../../../../../api/clients'
import { useClientDates, useGetTransactionRules, useNormalizeRelativeDates } from '../../../../../api/coreData'

export const TransactionTaggingContext = createContext({})

export function useTransactionTagging () {
  return useContext(TransactionTaggingContext)
}

const LOCAL_STORAGE_KEY = 'sws.tt.myClients'
const useOnlyMyClients = () => {
  const { allAccountAccess } = useAppContext()
  let defaultValue = !!allAccountAccess
  try {
    const ls = localStorageHelper.load(LOCAL_STORAGE_KEY)
    defaultValue = [null, undefined].includes(ls) ? !!allAccountAccess : ls
  } catch (err) {
    defaultValue = !!allAccountAccess
  }

  const setMyClientsDefault = useCallback((val) => {
    localStorageHelper.store(LOCAL_STORAGE_KEY, val)
  }, [])

  const [myClients, setMyClients] = useState(defaultValue)
  const _setMyClients = useCallback((val) => {
    setMyClients(val)
    setMyClientsDefault(val)
  }, [setMyClientsDefault, setMyClients])

  return [myClients, _setMyClients]
}

const useClientDateRange = (client, asOf, dateRange) => {
  const { data: clientDates, isLoading: loadingClientDates } = useClientDates(client?.clientId)
  const normalizeRequest = useMemo(() => {
    if (!clientDates?.max) return null

    return ({
      asOfDate: asOf || clientDates.max,
      levelFilters: {
        levelTypes: ['client'],
        clientIds: [client?.clientId]
      },
      relativeRanges: [dateRange]
    })
  }, [client?.clientId, clientDates, dateRange, asOf])
  const { data: relativeDates, isLoading: loadingRelativeDates } = useNormalizeRelativeDates(normalizeRequest, { enabled: !!normalizeRequest })

  return {
    relativeDates,
    clientDates,
    loading: loadingRelativeDates || loadingClientDates
  }
}

const ruleIds = ['flowClassificationRules', 'intraFlowMatchingRules', 'transactionMatchingRule']
const useTransactionRules = () => {
  return useGetTransactionRules(ruleIds)
}

function TransactionTaggingProvider ({ children, clientsBaseQuery }) {
  const [client, setClient] = useState(null)
  const [detail, setDetail] = useState(null)
  const [dateRange, setDateRange] = useState('L7D')
  const [asOf, setAsOf] = useState(null)
  const [onlyMyClients, setOnlyMyClients] = useOnlyMyClients()

  const { data: clientsData, isLoading: clientsLoading, error: clientsError } = useFullClientSearch(clientsBaseQuery)
  const _client = useMemo(() => {
    return (clientsData || []).find(x => client?.clientId === x.clientId)
  }, [client, clientsData])

  const { data: rules, isLoading: rulesLoading } = useTransactionRules()

  // We project the matching value so that if the underlying data is updated, the display component can be updated with the new data
  const _detail = useMemo(() => {
    switch (detail?.type) {
      default: return null
    }
  }, [detail])

  const { relativeDates, clientDates, loading: datesLoading } = useClientDateRange(_client, asOf, dateRange)
  useEffect(() => {
    if (asOf === null && clientDates?.max) {
      setAsOf(clientDates?.max)
    }
  }, [asOf, clientDates, setAsOf])

  const value = useMemo(() => {
    return {
      client: _client,
      detail: _detail,
      clients: {
        data: clientsData,
        isLoading: clientsLoading,
        error: clientsError
      },
      rules: {
        data: rules,
        isLoading: rulesLoading
      },
      dialogs: {
      },
      onlyMyClients,
      setOnlyMyClients,
      selectClient: c => {
        if (c !== _client) {
          setClient(c)
          setDetail(null)
        }
      },
      setDetail,
      dateRange,
      setDateRange,
      relativeDates,
      clientDates,
      datesLoading,
      asOf,
      setAsOf
    }
  }, [
    _client, _detail, setClient, setDetail,
    clientsData, clientsLoading, clientsError,
    dateRange, setDateRange, asOf, setAsOf,
    relativeDates, clientDates, datesLoading,
    rules, rulesLoading,
    onlyMyClients, setOnlyMyClients
  ])

  return (
    <TransactionTaggingContext.Provider value={value}>
      {children}
    </TransactionTaggingContext.Provider>
  )
}

TransactionTaggingProvider.propTypes = {
  children: PropTypes.node,
  clientsBaseQuery: PropTypes.object
}

export default TransactionTaggingProvider
