import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { ClickAwayListener, IconButton, makeStyles } from '@material-ui/core'
import dayjs from 'dayjs'
import Text from '../../../atoms/Text'
import { BUTTON_SIZES, ICON_NAMES, TEXT_VARIANTS } from '../../../../constants'
import RoundedButton from '../../../atoms/RoundedButton'
import { useDynamicPlanning } from '../DynamicPlanningContext'
import Icon from '../../../atoms/Icon'
import { calcFlows } from '../PlanLineChart/utils'
import { postNamedCommand } from '../../../../service'
import QuickEditContribution from './QuickEditContribution'
import DatesEdit from './DatesEdit'
import QuickEditShortName from './QuickEditShortName'
import DeleteGoalConfirmModal from './DeleteGoalConfirmModal'
import QuickEditDescription from './QuickEditDescription'

const useStyles = makeStyles((theme) => ({
  goalEditOuterContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '.5rem',
    margin: '2rem 0 0'
  },
  goalEditContainer: {
    flexGrow: 1,
    padding: '2rem 3rem',
    border: '4px solid ' + theme.palette.gray.main,
    borderRadius: '1.25rem',
    position: 'relative'
  },
  goalEditInnerContainer: {
    display: 'flex',
    gap: '3rem',
    alignItems: 'start',
    justifyContent: 'stretch'
  },
  goalEditInnerItem: {
    flexBasis: '50%'
  },
  goalEditHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  goalNameContainer: {
    display: 'flex',
    gap: '.75rem',
    alignItems: 'center',
    position: 'relative'
  },
  goalNameInputContainer: {
    display: 'flex',
    gap: '.75rem',
    alignItems: 'center',
    flexGrow: 1,
    marginBottom: '10px',
    position: 'relative'
  },
  quickEditInput: {
    fontSize: '2.5rem',
    flexGrow: 1,
    lineHeight: '1.4em',
    height: 'auto',
    padding: 0,
    borderBottom: '3px solid #000'
  },
  quickEditAdornment: {
    fontSize: '2.5rem',
    lineHeight: '1.4em',
    height: 'auto',
    padding: 0,
    width: '1rem'
  },
  quickEditDisplay: {
    fontSize: '2.5rem',
    minHeight: '1.5em',
    borderBottom: '3px solid transparent'
  },
  contributionContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '2rem'
  },
  contributionAmountContainer: {
    flexBasis: '50%'
  },
  dateRangeContainer: {
    flexBasis: '50%'
  },
  editFooter: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '1rem',
    marginTop: '2rem'
  },
  goalOptions: {
    position: 'absolute',
    top: '0.5rem',
    right: '1rem',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    gap: '1rem'
  },
  deleteGoalButton: {
    backgroundColor: theme.palette.error.A100,
    color: theme.palette.error.main
  }
}))

const PlanGoalEdit = () => {
  const {
    currentPlan,
    updateGoal,
    goalCategories,
    currentEditGoalId,
    plans,
    setCurrentEditGoalId,
    displayDates,
    plansDispatcher,
    activeGoalIds,
    goToNextGoal
  } = useDynamicPlanning()
  const { goals } = currentPlan ?? { goals: [] }
  const classes = useStyles()

  const normalizeGoalEditing = (goal) => ({
    ...goal,
    startDate: dayjs.utc(goal.startDate).year(),
    endDate: goal.endDate ? dayjs.utc(goal.endDate).year() : null,
    flowAmount: Math.abs(parseInt(goal.flowAmount)),
    direction: goal.flowAmount < 0 ? 'WITHDRAWAL' : 'CONTRIBUTION'
  })

  const [editingGoal, setEditingGoal] = useState({
    direction: 'WITHDRAWAL',
    frequency: 'ANNUALLY'
  })
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false)

  const onClose = useCallback(() => {
    setCurrentEditGoalId(null)
  }, [setCurrentEditGoalId])

  const currentGoal = useMemo(() => plans.map(plan => plan.goals).flat().find(goal => goal.goalId === currentEditGoalId), [plans, currentEditGoalId])
  const linkedGoal = useMemo(() => plans.map(plan => plan.goals).flat().find(goal => goal.goalId === currentGoal?.originGoalId || goal.originGoalId === currentGoal?.goalId), [plans, currentGoal?.originGoalId, currentGoal?.goalId])

  const goalIdRef = useRef(currentGoal?.goalId)
  const [quickEditMax, setQuickEditMax] = useState(Math.max(
    currentGoal?.flowAmount ? Math.abs(currentGoal.flowAmount) * 2.5 : 0,
    linkedGoal?.flowAmount ? Math.abs(linkedGoal.flowAmount) * 2.5 : 0,
    10000
  ))

  useEffect(() => {
    if (currentGoal?.goalId && goalIdRef.current !== currentGoal?.goalId) {
      setQuickEditMax(Math.max(
        currentGoal?.flowAmount ? Math.abs(currentGoal.flowAmount) * 2.5 : 0,
        linkedGoal?.flowAmount ? Math.abs(linkedGoal.flowAmount) * 2.5 : 0,
        10000
      ))
      goalIdRef.current = currentGoal.goalId
    }
  }, [currentGoal.flowAmount, currentGoal?.goalId, linkedGoal?.flowAmount])

  useEffect(() => {
    if (currentGoal) {
      setEditingGoal(normalizeGoalEditing(currentGoal))
    }
  }, [setEditingGoal, editingGoal.goalId, currentEditGoalId, plans, currentGoal, quickEditMax])

  const planTotalFlowAmount = useMemo(() => {
    if (!currentGoal) return 0
    return calcFlows({
      activeGoals: [currentGoal],
      currentPlan,
      displayDates
    }).reduce((acc, flow) => acc + flow.value, 0)
  }, [currentGoal, currentPlan, displayDates])

  const outflowPercentage = useMemo(() => {
    const totalFlow = goals
      .filter(_goal => activeGoalIds.includes(_goal.goalId) && editingGoal.direction === 'WITHDRAWAL' ? _goal.flowAmount < 0 : _goal.flowAmount >= 0)
      .reduce((acc, goal) => {
        const flowAmount = calcFlows({
          activeGoals: [goal],
          currentPlan,
          displayDates
        }).reduce((acc, flow) => acc + flow.value, 0)

        acc += Math.abs(flowAmount)
        return acc
      }, 0)

    if (totalFlow <= 0) {
      return 1
    }

    return Math.min((Math.abs(planTotalFlowAmount) / totalFlow), 1)
  }, [activeGoalIds, goals, planTotalFlowAmount, editingGoal.direction, currentPlan, displayDates])

  const outflowContent = useMemo(() => {
    const percentage = Math.round(outflowPercentage * 100) + '%'

    return percentage + (
      editingGoal.direction === 'WITHDRAWAL'
        ? ' of Active Outflow Goals'
        : ' of Active Inflow Goals'
    )
  }, [editingGoal.direction, outflowPercentage])

  const onChange = useCallback(async (newData) => {
    if (!currentPlan?.planId) return
    setEditingGoal({ ...editingGoal, ...newData })
    const standardizedGoalData = {
      ...newData
    }
    if (newData.startDate) {
      standardizedGoalData.startDate = dayjs.utc().year(newData.startDate).format('YYYY-MM-DD')
      standardizedGoalData.endDate = newData.endDate ? dayjs.utc().year(newData.endDate).format('YYYY-MM-DD') : null
    }
    if (newData.flowAmount || newData.direction) {
      const direction = newData.direction || editingGoal.direction
      const flowAmount = newData.flowAmount || editingGoal.flowAmount
      standardizedGoalData.flowAmount = direction === 'WITHDRAWAL' ? -Number(flowAmount) : Number(flowAmount)
      delete standardizedGoalData.direction
    }
    if (newData.shortName) {
      standardizedGoalData.shortName = newData.shortName
      standardizedGoalData.longName = newData.shortName
    }
    await updateGoal(currentEditGoalId, currentPlan.planId, standardizedGoalData, !!standardizedGoalData.shortName || !!standardizedGoalData.description)
  }, [currentPlan.planId, editingGoal, updateGoal, currentEditGoalId])

  const deleteGoal = useCallback(async () => {
    setShowDeleteConfirm(false)

    await postNamedCommand('planning', 'deleteGoal', { goalId: currentGoal.goalId })
    onClose()

    plansDispatcher({
      type: 'DELETE_GOAL',
      payload: {
        goalId: currentGoal.goalId,
        planId: currentPlan.planId
      }
    })
  }, [plansDispatcher, currentGoal?.goalId, currentPlan?.planId, onClose])

  const goalCategory = useMemo(() => goalCategories.find(category => category.categoryId === editingGoal.goalCategoryId),
    [editingGoal.goalCategoryId, goalCategories]
  )

  document.addEventListener('keydown', (e) => {
    if (e.key === 'Escape') {
      e.preventDefault()
      e.stopPropagation()
      onClose()
    }
  }, false)

  const onClickAway = useCallback((e) => {
    const preventEditCloseItems = document.querySelectorAll('[data-prevent-edit-close]')
    const elemIsInContainer = [...preventEditCloseItems ?? []].some(elem => elem.contains(e.target))
    if (elemIsInContainer) {
      return
    }
    onClose()
  }, [onClose])

  if (!currentGoal) return null

  return (
    <>
      <ClickAwayListener onClickAway={onClickAway}>
        <div className={classes.goalEditOuterContainer}>
          <IconButton onClick={() => goToNextGoal('PREV')}>
            <Icon name={ICON_NAMES.navigatePrevious} customSize='1.75rem' />
          </IconButton>
          <div className={classes.goalEditContainer}>
            <div className={classes.goalOptions}>
              {editingGoal?.goalId && (
                <RoundedButton
                  size={BUTTON_SIZES.small}
                  onClick={() => setShowDeleteConfirm(true)}
                  className={classes.deleteGoalButton}
                >
                  Delete Goal
                </RoundedButton>
              )}

              <IconButton onClick={onClose}>
                <Icon
                  name={ICON_NAMES.close}
                  customSize='1.75rem'
                />
              </IconButton>
            </div>
            <div className={classes.goalEditInnerContainer}>
              <div className={classes.goalEditInnerItem}>
                <div className={classes.goalEditHeader}>
                  <div className={classes.goalEditHeaderContent}>
                    <Text text={goalCategory?.shortName} variant={TEXT_VARIANTS.subtitle2} />
                    <QuickEditShortName
                      classes={{
                        container: classes.goalNameContainer,
                        inputContainer: classes.goalNameInputContainer,
                        quickEditInput: classes.quickEditInput,
                        quickEditDisplay: classes.quickEditDisplay
                      }}
                      initialValue={editingGoal.shortName}
                      onChange={onChange}
                      defaultIsEditable={currentGoal.isNew}
                    />
                    <Text text={outflowContent} customFontSize='14px' />
                  </div>
                </div>
                <div style={{ marginTop: '1rem' }}>
                  <QuickEditDescription
                    classes={{
                      container: classes.goalNameContainer,
                      inputContainer: classes.goalNameInputContainer,
                      quickEditInput: classes.quickEditInput,
                      quickEditDisplay: classes.quickEditDisplay
                    }}
                    initialValue={editingGoal.description}
                    onChange={onChange}
                    defaultIsEditable={currentGoal.isNew}
                  />
                </div>
              </div>

              <div className={classes.goalEditInnerItem}>
                <div className={classes.contributionContainer}>
                  <div className={classes.contributionAmountContainer}>
                    <QuickEditContribution
                      classes={{
                        input: classes.quickEditInput,
                        display: classes.quickEditDisplay,
                        adornment: classes.quickEditAdornment
                      }}
                      initialValues={{
                        direction: editingGoal.direction,
                        flowAmount: editingGoal.flowAmount,
                        frequency: editingGoal.frequency
                      }}
                      range={{ min: 0, max: quickEditMax }}
                      onChange={onChange}
                      defaultIsEditable={editingGoal.isNew}
                    />
                  </div>

                  <div className={classes.dateRangeContainer}>
                    <DatesEdit
                      classes={{
                        input: classes.quickEditInput,
                        display: classes.quickEditDisplay,
                        adornment: classes.quickEditAdornment
                      }}
                      values={{
                        frequency: editingGoal.frequency,
                        startDate: editingGoal.startDate,
                        endDate: editingGoal.endDate
                      }}
                      range={{
                        min: parseInt(dayjs.utc().add(-10, 'year').format('YYYY')),
                        max: parseInt(dayjs.utc().add(50, 'year').format('YYYY'))
                      }}
                      onChange={onChange}
                      defaultIsEditable={editingGoal.isNew}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <IconButton onClick={() => goToNextGoal('NEXT')}>
            <Icon name={ICON_NAMES.navigateNext} customSize='1.75rem' />
          </IconButton>
        </div>
      </ClickAwayListener>
      <DeleteGoalConfirmModal
        goal={editingGoal}
        isOpen={showDeleteConfirm}
        onClose={() => setShowDeleteConfirm(false)}
        onConfirm={deleteGoal}
      />
    </>

  )
}

export default PlanGoalEdit
