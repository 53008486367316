import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { makeStyles, Menu as MuiMenu } from '@material-ui/core'
import MenuItem from '@material-ui/core/MenuItem'
import { noop } from 'lodash'
import Icon from '../atoms/Icon'
import Text from '../atoms/Text'
import { menuOptions } from '../../prop-types'
import { ICON_NAMES } from '../../constants'

const useStyles = makeStyles((theme) => ({
  paper: {
    minWidth: '11rem',
    borderRadius: '0.5rem',
    border: `1px solid ${theme.palette.cloudBurst}`
  },
  list: {
    padding: '0'
  },
  listItem: {
    paddingTop: '0.75rem',
    paddingBottom: '0.75rem'
  },
  listItemIcon: {
    display: 'flex',
    marginRight: '0.75rem'
  },
  listItemLabel: {
    marginTop: '0.125rem'
  },
  popoverRoot: {
    zIndex: '1302 !important'
  }
}))

const Menu = ({ useIcon, iconName, options, children }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const classes = useStyles()
  const open = Boolean(anchorEl)

  const handleIconClick = useCallback(
    (event) => setAnchorEl(event.currentTarget),
    []
  )

  const handleOnClose = useCallback(() => setAnchorEl(null), [])

  const handleOnItemClick = useCallback(() => {
    handleOnClose()
  }, [handleOnClose])

  return (
    <div>
      {useIcon && (
        <div onClick={handleIconClick}>
          <Icon name={iconName} />
        </div>
      )}
      {children && !useIcon && children({ setAnchorEl: handleIconClick })}

      <MuiMenu
        PopoverClasses={{
          root: classes.popoverRoot
        }}
        classes={{
          paper: classes.paper,
          list: classes.list
        }}
        {...(anchorEl
          ? { anchorEl }
          : {
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right'
            }
          })}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        open={open}
        onClose={handleOnClose}
      >
        {options.map((option) => {
          const { iconName, label, onClick, color, disabled } = option
          return (
            <MenuItem
              key={label}
              disabled={disabled}
              className={classes.listItem}
              onClick={(event) => {
                onClick(option)
                handleOnItemClick(event)
              }}
            >
              {iconName && (
                <div className={classes.listItemIcon}>
                  <Icon
                    customSize='1.125rem'
                    name={iconName}
                    color={color || null}
                  />
                </div>
              )}
              <Text
                text={label}
                color={color || null}
                className={classes.listItemLabel}
              />
            </MenuItem>
          )
        })}
      </MuiMenu>
    </div>
  )
}

Menu.propTypes = {
  useIcon: PropTypes.bool,
  iconName: PropTypes.oneOf(Object.values(ICON_NAMES)),
  options: menuOptions,
  children: PropTypes.func
}

Menu.defaultProps = {
  useIcon: false,
  iconName: ICON_NAMES.threeDots,
  options: [],
  children: noop
}

export default Menu
