import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import SydButton from '../../../../../../commonDesign/Button'
import { useDialogStyles } from '../../common'
import { useReactivateWealthOwner } from '../../../../../../../api/users'

const useSubmitter = (form, onComplete, user) => {
  const { handleSubmit } = form
  const [processing, setProcessing] = useState(false)
  const [error, setError] = useState(null)
  const { mutateAsync: reactivate } = useReactivateWealthOwner()
  const onSubmit = useCallback(async (formData) => {
    try {
      setError(null)
      setProcessing(true)
      const result = await reactivate(user.userId)
      if (result?.statusCode === 500) {
        throw new Error('Failed to reactivate user')
      }
      onComplete(result)
    } catch (err) {
      setError(err?.toString())
    } finally {
      setProcessing(false)
    }
  }, [reactivate, setProcessing, onComplete, user, setError])

  const submitter = useCallback(async (e) => {
    const onValid = async (form) => {
      await onSubmit(form)
    }
    const onInvalid = (errors) => {
      console.error(errors)
    }

    const handler = handleSubmit(onValid, onInvalid)
    await handler(e)
  }, [handleSubmit, onSubmit])

  return {
    submitter,
    processing,
    error
  }
}

function ReactivateUserForm ({ user, onCancel, onComplete }) {
  const classes = useDialogStyles()
  const form = useForm({
    mode: 'onChange',
    defaultValues: {
      userId: user.userId
    }
  })

  const { submitter, processing, error } = useSubmitter(form, onComplete, user)

  return (
    <div className={classes.form}>
      <div>
        <p>This action will reactivate <strong>{user.email}</strong></p>
        <p>
          <span>The user will</span>
          <ul>
            <li>Have their password and security credentials reset</li>
            <li><strong>Be sent an invitation via email</strong> to set up their account</li>
            <li>Following setup which, the user will be able to log in</li>
          </ul>
        </p>
      </div>
      <div className={classes.actions}>
        {error ? (<div className={classes.error}>{error}</div>) : null}
        <SydButton disabled={processing} variant='ghost' size='md' onClick={onCancel}>Cancel</SydButton>
        <SydButton
          variant='primary'
          size='md'
          onClick={submitter}
          processing={processing}
        >
          Reactivate
        </SydButton>
      </div>
    </div>
  )
}

ReactivateUserForm.propTypes = {
  user: PropTypes.shape({
    userId: PropTypes.number,
    email: PropTypes.number,
    firstName: PropTypes.string,
    lastName: PropTypes.string
  }),
  onComplete: PropTypes.func,
  onCancel: PropTypes.func
}

export default ReactivateUserForm
