import React, { useCallback, useMemo, useEffect } from 'react'
import noop from 'lodash/noop'
import PropTypes from 'prop-types'

import { Divider, makeStyles } from '@material-ui/core'
import isEmpty from 'lodash/isEmpty'
import { threadSchema } from '../../prop-types'

import { useToggle } from '../../hooks'
import { deleteThread, saveNotificationInteraction } from '../../service'
import { CONFIRMATION_TYPE, NOTE_NOTIFICATION_INTERACTION_TYPES } from '../../constants'

import { useNoteThreadContext } from '../../contexts/noteThreadContext'
import { useAppContext, useSetNotificationCounter } from '../../redux/slices/appContext'
import NoteComment from './NoteComment'
import NoteThreadTitle from './NoteThreadTitle'
import NoteThreadOverview from './NoteThreadOverview'
import ConfirmationModal from './ConfirmationModal'
import NoteThreadSkeleton from './NoteThreadSkeleton'

const useStyles = makeStyles((theme) => ({
  wrapper: {
    borderRadius: '0.5rem',
    backgroundColor: theme.palette.white
  },
  commentsWrapper: ({ embedded, withMaxHeight }) => ({
    maxHeight: withMaxHeight ? '31rem' : 'none',
    overflow: 'auto',
    height: embedded ? 'inherit' : 'calc((100vh - 52px) - 130px)'
  })
}))

const NoteThread = ({
  handleOnBack,
  handleOnClose,
  withMaxHeight,
  embedded,
  modalContainerRef
}) => {
  const classes = useStyles({ embedded, withMaxHeight })
  const {
    replies,
    isLoading,
    activeThread,
    onRemoveThread,
    removeThreadNotification
  } = useNoteThreadContext()

  const { userId } = useAppContext()
  const setNotificationsCounter = useSetNotificationCounter()

  const [loading, toggleLoading, , toggleLoadingOff] = useToggle()
  const [deleteConfirmModalOpen, toggleDeleteConfirmModal] = useToggle()

  useEffect(() => {
    async function markThreadAsSeen (clientId, threadId, notifications) {
      try {
        const notificationIds = notifications.map(({ notificationId }) => notificationId)
        removeThreadNotification(clientId, threadId, notificationIds)
        const body = {
          notificationIds,
          interactionType: NOTE_NOTIFICATION_INTERACTION_TYPES.SEEN
        }
        await saveNotificationInteraction(userId, body)
        setNotificationsCounter({ decrement: true, clientId })
      } catch (err) {
        console.error('unable to mark thread as seen')
      }
    }
    if (!isEmpty(activeThread.notifications)) {
      markThreadAsSeen(
        activeThread.clientId,
        activeThread.threadId,
        activeThread.notifications
      )
    }
  }, [
    userId,
    activeThread.threadId,
    activeThread.clientId,
    activeThread.notifications,
    setNotificationsCounter,
    removeThreadNotification
  ])

  const onConfirmDeleteThread = useCallback(async () => {
    try {
      toggleLoading()
      onRemoveThread(activeThread.threadId)
      await deleteThread(activeThread.threadId)
      toggleLoading()
    } catch (err) {
      console.error(err)
      toggleLoadingOff()
    }
  }, [onRemoveThread, toggleLoading, toggleLoadingOff, activeThread.threadId])

  const renderDeleteThreadConfirmationModal = useMemo(() => {
    return (
      <ConfirmationModal
        buttonSize='small'
        isLoading={loading}
        open={deleteConfirmModalOpen}
        container={modalContainerRef}
        onConfirm={onConfirmDeleteThread}
        onCancel={toggleDeleteConfirmModal}
        buttonLabelConfirm='Delete'
        buttonLabelCancel="Don't delete"
        confirmationType={CONFIRMATION_TYPE.Danger}
        confirmationText="You are about to delete a thread. Are you sure you'd like to proceed?"
      />
    )
  }, [
    loading,
    modalContainerRef,
    onConfirmDeleteThread,
    deleteConfirmModalOpen,
    toggleDeleteConfirmModal
  ])

  return (
    <div className={classes.wrapper}>
      {renderDeleteThreadConfirmationModal}
      <NoteThreadTitle
        title='Back to Comments'
        onBack={handleOnBack}
        onClose={handleOnClose}
        showBack
        showViewFilters={false}
      />
      <Divider />
      <div className={classes.commentsWrapper}>
        <NoteThreadOverview
          thread={activeThread}
          onDeleteThread={toggleDeleteConfirmModal}
        />
        {!isEmpty(replies) && !isLoading ? (
          replies
            .slice()
            .sort(
              (replyA, replyB) =>
                new Date(replyA.createdAt) - new Date(replyB.createdAt)
            )
            .map((reply, index) => {
              const { threadId, ...replyRest } = reply
              return (
                <React.Fragment key={`thread-reply-${reply.replyId}`}>
                  <NoteComment
                    isReply
                    modalContainerRef={modalContainerRef}
                    isLastNoteComment={replies.length - 1 === index}
                    {...replyRest}
                  />
                </React.Fragment>
              )
            })
        ) : (
          <NoteThreadSkeleton repeat={activeThread.replies} withDivider={false} />
        )}
      </div>
    </div>
  )
}

NoteThread.propTypes = {
  thread: threadSchema,
  replies: PropTypes.array,
  handleOnClose: PropTypes.func,
  handleOnBack: PropTypes.func,
  withMaxHeight: PropTypes.bool,
  embedded: PropTypes.bool,
  addComment: PropTypes.func,
  onRemoveReply: PropTypes.func,
  onRemoveThread: PropTypes.func,
  onAddReaction: PropTypes.func,
  onRemoveReaction: PropTypes.func,
  modalContainerRef: PropTypes.object,
  onResolveThreadHandler: PropTypes.func,
  updateThreadById: PropTypes.func,
  updateThreadReplyById: PropTypes.func
}

NoteThread.defaultProps = {
  thread: null,
  replies: [],
  withMaxHeight: true,
  embedded: false,
  handleOnClose: noop,
  handleOnBack: noop,
  onRemoveReply: noop,
  onRemoveThread: noop,
  onAddReaction: noop,
  onRemoveReaction: noop,
  modalContainerRef: null,
  onResolveThreadHandler: noop,
  updateThreadById: noop,
  updateThreadReplyById: noop
}

export default NoteThread
