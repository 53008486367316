import React from 'react'
import PropTypes from 'prop-types'
import { Box, Divider } from '@material-ui/core'
import Skeleton from '../atoms/Skeleton'
import { SKELETON_VARIANTS } from '../../constants'

const NoteThreadSkeleton = ({ repeat, linesPerSkeleton, withDivider }) => {
  return (
    <Box pl={2} pr={2}>
      {new Array(repeat).fill(0).map((_, index) => {
        return (
          <React.Fragment key={`skeleton-${index}`}>
            <Box pt={2} pb={2}>
              <Box display='flex' alignItems='center' width='100%'>
                <Skeleton
                  variant={SKELETON_VARIANTS.circle}
                  width='36px'
                  height='32px'
                />
                <Box width='100%' pl={1}>
                  <Skeleton
                    variant={SKELETON_VARIANTS.text}
                    width='40%'
                    height='24px'
                  />
                </Box>
              </Box>
              <Box pt={0.5} mb={0.5}>
                {new Array(linesPerSkeleton).fill(0).map((_, indexLine) => (
                  <Skeleton
                    key={`skeleton-line-${indexLine}`}
                    variant={SKELETON_VARIANTS.text}
                    width='100%'
                    height='16px'
                  />
                ))}
              </Box>
            </Box>
            {index + 1 !== repeat && repeat > 1 && withDivider && <Divider />}
          </React.Fragment>
        )
      })}
    </Box>
  )
}

NoteThreadSkeleton.propTypes = {
  repeat: PropTypes.number,
  linesPerSkeleton: PropTypes.number,
  withDivider: PropTypes.bool
}

NoteThreadSkeleton.defaultProps = {
  repeat: 1,
  linesPerSkeleton: 4,
  withDivider: true
}

export default NoteThreadSkeleton
