import React from 'react'
import PropTypes from 'prop-types'
import { Button, makeStyles } from '@material-ui/core'
import { ICON_NAMES } from '../../../../constants'
import Icon from '../../../atoms/Icon'
import { usePolicy } from '../../../../hooks/usePolicy'

const useStyles = makeStyles((theme) => ({
  deleteButton: {
    textTransform: 'none',
    borderRadius: '10rem',
    padding: '2px 18px',
    outline: `1.5px solid ${theme.palette.danger.main}`,
    fontWeight: theme.typography.weights.semibold,
    fontSize: theme.typography.fontSizes.medium,
    transition: 'background-color 200ms ease-in-out, color 200ms ease-in-out',
    backgroundColor: theme.palette.background.default,
    color: theme.palette.danger.main,
    '&:hover': {
      backgroundColor: theme.palette.danger.main,
      color: theme.palette.background.default
    }
  }
}))

function DeleteButton ({ policy, onClick, disabled, style }) {
  const classes = useStyles()
  const allowed = usePolicy(policy)

  if (!allowed) return null

  return (
    <Button
      style={style}
      startIcon={<Icon name={ICON_NAMES.delete} />}
      className={classes.deleteButton}
      disabled={disabled}
      variant='outlined'
      onClick={onClick}
    >
      Delete Client
    </Button>
  )
}

DeleteButton.propTypes = {
  policy: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  style: PropTypes.object
}

DeleteButton.defaultProps = {
  policy: null,
  disabled: false
}

export default DeleteButton
