import React, { createContext, useContext, useMemo } from 'react'
import PropTypes from 'prop-types'
import isFunction from 'lodash/isFunction'
import { isEmpty } from 'lodash'
import dayjs from 'dayjs'
import {
  REPORT_STATUS
} from '../../components/pages/views/BookOfBusinessReport/helpers'
import {
  useBookOfBusinessReport,
  useQueryBookOfBusinessReport
} from '../../api/bobi'
import { useParameterReplacement } from '../../hooks/useAbundanceEngineParameters'
import { useAdvisorHome } from '../../components/organisms/AdvisorHome/AdvisorHomeContext'
import { getBookOfBusinessReportParams } from '../../components/pages/views/BookOfBusinessReport/ReportResultsTable'
import { INTERNAL_DATE_FORMAT } from '../../constants'

export const BookOfBusinessReportContext = createContext()

export const useBookOfBusinessReportContext = () => {
  return useContext(BookOfBusinessReportContext)
}

const useBookOfBusinessReportContextValue = (
  reportParams,
  reportOutputParams,
  dateRange,
  useOutputCount,
  shouldCheckExisting = true
) => {
  const [, endDate, _asOfDate] = dateRange
  const asOfDate = _asOfDate || endDate

  const queryParams = useMemo(() => {
    if (!reportParams) return null

    return getBookOfBusinessReportParams({
      ...reportParams,
      endDate: asOfDate
    })
  }, [asOfDate, reportParams])

  const rawReportParams = useMemo(() => {
    const [startDate, endDate] = dateRange
    return {
      dataSets: reportParams?.dataSets,
      filters: reportParams?.filters,
      levelType: reportParams?.levelType,
      exposureType: reportParams?.exposureType,
      exposureTarget: reportParams?.exposureTarget,
      dateRangePreset: reportParams?.dateRangePreset,
      startDate,
      endDate,
      minDailyStartDate: dateRange?.startDate,
      includeTags: reportParams?.includeTags ?? false,
      outputFilters: reportOutputParams.filters,
      metadata: reportParams.metadata
    }
  }, [reportParams, dateRange, reportOutputParams])

  const {
    data: reportResult,
    isLoading: isLoadingReport,
    error
  } = useBookOfBusinessReport(queryParams, shouldCheckExisting)

  const isReportCompleted = reportResult?.status === REPORT_STATUS.COMPLETED

  const outputQuery = useMemo(() => {
    if (!reportResult) return {}
    return {
      query: {
        bobiOutputId: reportResult.bobiOutputId,
        output: 'json',
        ...reportOutputParams,
        ...(useOutputCount ? { count: true } : {})
      },
      queryOptions: {
        enabled: isReportCompleted
      }
    }
  }, [
    reportResult,
    useOutputCount,
    isReportCompleted,
    reportOutputParams
  ])

  const {
    data: reportQueryResult,
    isInitialLoading: isLoadingReportQueryResult
  } = useQueryBookOfBusinessReport(outputQuery.query, outputQuery.queryOptions)

  const isLoading = isLoadingReportQueryResult

  return {
    error,
    rawReportParams: rawReportParams,
    reportParams: queryParams,
    data: reportQueryResult,
    isLoading:
      isLoading ||
      isLoadingReport ||
      reportResult?.status === REPORT_STATUS.PENDING
  }
}

const formatDate = (date, format = INTERNAL_DATE_FORMAT) => {
  const dateObj = dayjs(date)
  if (!dateObj.isValid()) return null
  return dateObj.format(format)
}

const useReportDates = (dateRange, asOfDate) => {
  const { startDate, endDate } = dateRange
  const [
    startDateReplacement,
    endDateReplacement,
    asOfDateReplacement
  ] = useParameterReplacement([startDate, endDate, asOfDate])
  return [
    formatDate(startDateReplacement),
    formatDate(endDateReplacement),
    formatDate(asOfDateReplacement)
  ]
}

export const BookOfBusinessReportProvider = ({
  children,
  loading,
  asOfDate,
  reportParams,
  useOutputCount,
  reportOutputParams,
  dateRange: _dateRange
}) => {
  const dateRange = useReportDates(_dateRange, asOfDate)
  const { scope } = useAdvisorHome()

  const params = useMemo(
    () => ({
      ...reportParams,
      filters: [
        ...reportParams.filters,
        ...(!isEmpty(scope?.values)
          ? [{ type: 'advisorIds', value: scope.values }]
          : [])
      ]
    }),
    [scope, reportParams]
  )

  const contextValue = useBookOfBusinessReportContextValue(
    params,
    reportOutputParams,
    dateRange,
    useOutputCount
  )

  const childrenNode = useMemo(() => {
    if (isFunction(children)) return children(contextValue)
    return children
  }, [children, contextValue])

  if (contextValue?.isLoading) {
    return loading || '...loading'
  }

  return (
    <BookOfBusinessReportContext.Provider value={contextValue}>
      {childrenNode}
    </BookOfBusinessReportContext.Provider>
  )
}

BookOfBusinessReportProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
  reportParams: PropTypes.shape({
    dataSets: PropTypes.arrayOf(PropTypes.string),
    filters: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string,
        value: PropTypes.array
      })
    ),
    exposureType: PropTypes.string,
    exposureTarget: PropTypes.string,
    levelType: PropTypes.string,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    dateRangePreset: PropTypes.string
  }),
  dateRange: PropTypes.shape({
    startDate: PropTypes.string,
    endDate: PropTypes.string
  }),
  loading: PropTypes.node,
  reportOutputParams: PropTypes.object,
  useOutputCount: PropTypes.bool,
  asOfDate: PropTypes.string
}

BookOfBusinessReportProvider.defaultProps = {
  dateRange: {},
  loading: undefined,
  reportParams: { filters: [] },
  reportOutputParams: {},
  useOutputCount: false
}

export default BookOfBusinessReportProvider
