import React, { useEffect, useMemo, useState } from 'react'
import { makeStyles } from '@material-ui/core'
import { TEXT_VARIANTS } from '../../../../constants'
import Text from '../../../atoms/Text'
import { useBoolean } from '../../../../hooks'
import OperationalTable, { useOperationalTable } from '../../../organisms/OperationalTable'
import SnackAlert from '../../../molecules/SnackAlert/SnackAlert'
import { useAppContext } from '../../../../redux/slices/appContext'
import RunReportForm from './RunReportForm'
import { useListReportsData, useListReportTemplatesData, useReportsColumnConfig } from './hooks'
import PostToVaultModal from './PostToVault/PostToVaultModal'
import ReportPreviewModal from './ReportPreview/ReportPreviewModal'
import ReportRunRefreshActions from './ReportRunRefreshActions'
import ReportSectionHeader from './ReportSectionHeader'
import { reportStatusClasses } from './classes'

const useStyles = makeStyles(() => ({
  container: {
    padding: '1rem',
    display: 'flex',
    flex: '1',
    flexDirection: 'column'
  },
  statusContainer: {
    width: '6rem'
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    padding: '1rem 0 0',
    gap: '1rem',
    '& a': {
      opacity: 0.25,
      '&.active': {
        opacity: 1
      }
    }
  },
  tableHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  ...reportStatusClasses
}))

const ReportsView = () => {
  const { isSummitUser } = useAppContext()

  const classes = useStyles()
  const [previewUrl, setPreviewUrl] = useState('')
  const [postToVaultData, setPostToVaultData] = useState({})
  const [alert, setAlert] = useState({})
  const [isTableLoading, setIsTableLoading] = useBoolean(true)

  // template Data
  const {
    data: templates,
    loading: isTemplatesLoading,
    templatesById,
    refetch: refetchTemplates,
    isStale: isTemplatesStale
  } = useListReportTemplatesData({
    offset: 0,
    limit: 1000,
    sort: ''
  })

  const reportsColumnConfig = useReportsColumnConfig({
    classes,
    isTableLoading: isTableLoading,
    onPostRerunReport: () => refetch(),
    onSetPreviewUrl: (htmlUrl) => setPreviewUrl(htmlUrl),
    onSetPostToClientVault: ({ clientId, title, reportRunId }) => setPostToVaultData({ clientId, title, reportRunId })
  })

  const {
    pageIndex,
    pageSize,
    sort,
    onPagingChange,
    onSortingChange
  } = useOperationalTable(reportsColumnConfig)

  const options = useMemo(() => ({
    pageIndex,
    pageSize,
    sort,
    filterToUser: !isSummitUser
  }), [isSummitUser, pageIndex, pageSize, sort])

  const { data, loading, refetch } = useListReportsData(options)

  const formattedData = useMemo(() => {
    if (!Array.isArray(data)) {
      return []
    }
    return data?.map((report) => ({
      ...report,
      templateName: templatesById[`${report.templateId}`]?.name
    }))
  }, [data, templatesById])

  useEffect(() => {
    if (loading) {
      setIsTableLoading.on()
    } else {
      setIsTableLoading.off()
    }
  }, [loading, setIsTableLoading])

  const onPostToVaultSubmit = async (alert) => {
    if (alert) {
      setAlert(alert)
    }
    setPostToVaultData({})
    await refetch()
  }

  useEffect(() => {
    if (isTemplatesStale) {
      refetchTemplates()
    }
  }, [isTemplatesStale, refetchTemplates])

  return (
    <div className={classes.container}>
      <ReportSectionHeader />
      <RunReportForm
        templates={templates}
        isTemplatesLoading={isTemplatesLoading}
        onSubmit={refetch}
        allowConfigurationSelection={isSummitUser}
        allowManage={isSummitUser}
      />
      <div className={classes.tableHeader}>
        <Text text='Reports' variant={TEXT_VARIANTS.h2} />
        <ReportRunRefreshActions intervalInSeconds={10} loading={loading} onRefetch={refetch} />
      </div>

      <PostToVaultModal
        data={postToVaultData}
        onSubmit={onPostToVaultSubmit}
        onClose={() => setPostToVaultData({})}
      />

      <ReportPreviewModal
        previewUrl={previewUrl}
        onSubmit={refetch}
        onClose={() => setPreviewUrl('')}
      />

      <OperationalTable.Wrapper>
        <OperationalTable
          showTotalItemsLabel={false}
          autoSticky
          mode='server'
          columns={reportsColumnConfig.columns}
          data={formattedData}
          defaultPageSize={100}
          defaultSort={reportsColumnConfig.defaultSort}
          itemName='Reports'
          loading={loading}
          total={data?.length || 0}
          onSortingChange={onSortingChange}
          onPagingChange={onPagingChange}
        />
      </OperationalTable.Wrapper>
      <SnackAlert alert={alert} />
    </div>
  )
}

export default ReportsView
