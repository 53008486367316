import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import Meeting from './Meeting'

const useStyles = makeStyles(() => ({
  container: {
    backgroundColor: 'transparent'
  },
  meeting: {
    padding: '0.5rem 0rem',
    '& :hover': {
      background: '#F5F6F7'
    }
  },
  divider: {
    margin: '0.625rem 1.5rem'
  }
}))

const MeetingList = ({ meetings, take, linkBaseUrl }) => {
  const classes = useStyles()
  const meetingsPage = useMemo(
    () => meetings?.slice(0, take),
    [meetings, take]
  )
  return (
    <div className={classes.container}>
      {meetingsPage.map((meeting, index) => {
        return (
          <div key={meeting.meetingId} className={classes.meeting}>
            <Meeting meeting={meeting} linkBaseUrl={linkBaseUrl} />
          </div>
        )
      })}
    </div>
  )
}

MeetingList.propTypes = {
  meetings: PropTypes.array,
  take: PropTypes.number,
  linkBaseUrl: PropTypes.string
}

MeetingList.defaultProps = {
  take: 5,
  linkBaseUrl: null
}

export default MeetingList
