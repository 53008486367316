export const DIALOG_STORAGE_KEYS = new Proxy(
  {
    COLUMNS_DND_ORDER_TOGGLER: 'columns_dnd_order_toggler',
    DATE_RANGES_DND_ORDER_TOGGLER: 'date_ranges_dnd_order_toggler'
  },
  {
    get: function (obj, prop) {
      if (!obj[prop]) throw Error('Property does not exist')
      return `customize_columns_${obj[prop]}`
    }
  }
)

export const mapVisibleColumns = (columns = []) => {
  return columns.reduce((acc, column) => {
    const hiddenProps = column.getToggleHiddenProps()
    if (!hiddenProps.checked) return acc
    return [...acc, column.id]
  }, [])
}

export const mapHiddenColumns = (columns = []) => {
  return columns.reduce((acc, column) => {
    const hiddenProps = column.getToggleHiddenProps()
    if (hiddenProps.checked) return acc
    return [...acc, column.id]
  }, [])
}

export const mapColumnIds = (columns) => {
  return columns.map(({ id }) => id)
}

export const getHiddenColumnIds = ({ columnHiddenProps }) => {
  return columnHiddenProps.reduce(
    (acc, { id, checked }) => (checked ? acc : [...acc, id]),
    []
  )
}
