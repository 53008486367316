import React, { useCallback, useState } from 'react'
import { Menu, MenuItem, makeStyles, IconButton } from '@material-ui/core'
import PropTypes from 'prop-types'
import { ICON_NAMES } from '../../../../constants'
import Icon from '../../../atoms/Icon'
import { useComments } from '../../Comments/CommentContext'
import { useAppContext } from '../../../../redux/slices/appContext'
import { useWealthJourney } from './WealthJourneyProvider'

const useStyles = makeStyles((theme) => ({
  entryActions: {
  },
  menuItem: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.layout.gap.medium,
    alignItems: 'center'
  }
}))

function EntryMenu ({ entry, size }) {
  const { clientId } = useAppContext()
  const { updateEntry, removeEntry, manageTasks } = useWealthJourney()
  const { showAttachmentsOnly } = useComments()
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState()
  const open = useCallback((e) => {
    e.preventDefault()
    e.stopPropagation()
    setAnchorEl(e.target)
  }, [setAnchorEl])
  const close = useCallback(() => setAnchorEl(null), [])
  const withClose = useCallback((fn) => () => {
    fn()
    close()
  }, [close])
  const canEdit = entry?.entryType?.permissions?.edit ?? false
  const canAttach = entry?.entryType?.permissions?.attach ?? false
  const canManageTasks = entry?.entryType?.permissions?.tasks ?? false
  const canDoSomething = canEdit || canAttach || canManageTasks

  if (!canDoSomething) {
    return null
  }

  return (
    <div className={classes.entryActions}>
      <IconButton aria-controls='more-opts' aria-haspopup='true' onClick={open}>
        <Icon name={ICON_NAMES.vertDots} customSize={size} />
      </IconButton>
      <Menu
        id='entry-actions'
        keepMounted
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={close}
      >
        {canEdit ? (
          <MenuItem onClick={withClose(() => updateEntry(entry))}>
            <div className={classes.menuItem}>
              <Icon name='edit' />
              <div>Edit</div>
            </div>
          </MenuItem>
        ) : null}
        {canAttach ? (
          <MenuItem
            onClick={withClose(() => showAttachmentsOnly({
              clientId,
              title: `Attachments for ${entry.entryText}`,
              levelTypeId: 1500,
              levelId: entry.entryId
            }))}
          >
            <div className={classes.menuItem}>
              <Icon name='document' />
              <div>Attachments</div>
            </div>
          </MenuItem>
        ) : null}
        {canManageTasks ? (
          <MenuItem
            onClick={withClose(() => manageTasks(entry))}
          >
            <div className={classes.menuItem}>
              <Icon name='check' />
              <div>Tasks</div>
            </div>
          </MenuItem>
        ) : null}
        {canEdit ? (
          <hr />
        ) : null}
        {canEdit ? (
          <MenuItem onClick={withClose(() => removeEntry(entry))}>
            <div className={classes.menuItem}>
              <Icon name='delete' />
              <div>Archive</div>
            </div>
          </MenuItem>
        ) : null}
      </Menu>
    </div>
  )
}

EntryMenu.propTypes = {
  entry: PropTypes.object,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

EntryMenu.defaultProps = {
  size: '24px'
}

export default EntryMenu
