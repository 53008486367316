import { useCallback, useState } from 'react'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import {
  fetchAdminAccounts,
  getAccountBillingInfo,
  postNamedCommand,
  postNamedQuery,
  saveAccountTags
} from '../service'
import { useAppContext } from '../redux/slices/appContext'
import { QUERY_KEYS } from './queryKeys'

export const useSearchAccountsMultiple = (query, queryOptions = {}) => {
  const { userId } = useAppContext()
  const { mapper, enabled } = queryOptions
  return useQuery({
    queryKey: [QUERY_KEYS.searchAccountsMultiple, userId, query],
    queryFn: async () => {
      const { data } = await postNamedQuery('levels', 'searchAccountsMultiple', query)
      return data
    },
    select: mapper,
    enabled
  })
}

export const useInvalidateSearchAccountsMultiple = () => {
  const client = useQueryClient()
  const { userId } = useAppContext()
  return useCallback(() => {
    console.debug('invalidating', QUERY_KEYS.searchAccountsMultiple)
    client.invalidateQueries({
      queryKey: [QUERY_KEYS.searchAccountsMultiple, userId],
      refetchType: 'all'
    }).catch(console.error)
  }, [client, userId])
}

export const useSearchAccounts = (query, options = {}) => {
  const { userId } = useAppContext()
  const { enabled = true, mapper = null } = options
  return useQuery({
    queryKey: [QUERY_KEYS.searchAccounts, userId, query],
    queryFn: async ({ signal }) => {
      const { data } = await postNamedQuery(
        'levels',
        'searchAccounts',
        query,
        signal
      )
      return mapper ? mapper(data) : data
    },
    enabled
  })
}

export const useAccountBillingInfo = (query, options = {}) => {
  const { userId } = useAppContext()
  const { enabled = true, mapper = null } = options
  return useQuery({
    queryKey: [QUERY_KEYS.accountBillingInfo, userId, query],
    queryFn: async () => {
      const { data } = await getAccountBillingInfo(query)
      return mapper ? mapper(data) : data
    },
    enabled
  })
}

export const useAccounts = (query, mapper = null) => {
  const { userId } = useAppContext()
  return useQuery({
    queryKey: [QUERY_KEYS.accounts, userId, query],
    queryFn: async () => {
      const { data } = await fetchAdminAccounts(query)
      return mapper ? mapper(data) : data
    }
  })
}

export const useListAssignedClientAccounts = (clientId) => {
  const { userId } = useAppContext()
  return useQuery({
    queryKey: [QUERY_KEYS.listAssignedClientAccounts, userId, clientId],
    queryFn: async () => {
      const { data } = await postNamedQuery('levels', 'list-client-accounts', {
        clientId
      })

      return data
    }
  })
}

export const useInvalidateListAssignedClientAccounts = (clientId) => {
  const client = useQueryClient()
  const { userId } = useAppContext()
  return useCallback(() => {
    console.debug('invalidating', QUERY_KEYS.listAssignedClientAccounts)
    client.invalidateQueries({
      queryKey: [QUERY_KEYS.listAssignedClientAccounts, userId, clientId],
      exact: true
    }).catch(console.error)
  }, [client, clientId, userId])
}

export const useListTaxStatusOptions = () => {
  const { userId } = useAppContext()
  return useQuery({
    queryKey: [QUERY_KEYS.listTaxStatusOptions, userId],
    queryFn: async () => {
      const { data } = await postNamedQuery('accounts', 'list-tax-status-options', {})
      return data
    }
  })
}

export const useModifyAccountTaxStatusMutation = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async ({ accountId, taxStatusId }) => {
      const { data } = await postNamedCommand('accounts', 'updateAccountDefaultStatus', {
        accountId,
        taxStatusId
      })

      return data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.searchAccounts], refetchType: 'all' }).catch(console.error)
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.searchAccountsMultiple], refetchType: 'all' }).catch(console.error)
    }
  })
}

export const useModifyAccountDisplayNameMutation = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async ({ accountId, displayName }) => {
      const { data } = await postNamedCommand('accounts', 'updateAccountDisplayName', {
        accountId,
        displayName
      })

      return data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.searchAccounts], refetchType: 'all' }).catch(console.error)
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.searchAccountsMultiple], refetchType: 'all' }).catch(console.error)
    }
  })
}

export const useModifyAccountTagsMutation = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async ({ accountId, tags }) => {
      const { data } = await saveAccountTags(accountId, tags)

      return data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.searchAccounts], refetchType: 'all' }).catch(console.error)
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.searchAccountsMultiple], refetchType: 'all' }).catch(console.error)
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.searchGroups], refetchType: 'all' }).catch(console.error)
    }
  })
}

export const useModifyAccountPerformanceStartDateMutation = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async ({ accountId, performanceStartDate }) => {
      const { data } = await postNamedCommand('accounts', 'updateAccountPerformanceStartDate', {
        accountId,
        performanceStartDate
      })

      return data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.searchAccounts], refetchType: 'all' }).catch(console.error)
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.searchAccountsMultiple],
        refetchType: 'all'
      }).catch(console.error)
    }
  })
}

export const useAssignAccountManagerMutation = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async ({ accountId, managerId }) => {
      const { data } = await postNamedCommand('accounts', 'assign-account-manager', {
        accountId,
        managerId
      })

      return data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.searchAccounts], refetchType: 'all' }).catch(console.error)
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.searchAccountsMultiple],
        refetchType: 'all'
      }).catch(console.error)
    }
  })
}

export const useRemoveAccountManagerMutation = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async ({ accountId }) => {
      const { data } = await postNamedCommand('accounts', 'remove-account-manager', {
        accountId
      })

      return data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.searchAccounts], refetchType: 'all' }).catch(console.error)
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.searchAccountsMultiple],
        refetchType: 'all'
      }).catch(console.error)
    }
  })
}

export const useManagerSearch = (query, options = {}) => {
  const { userId } = useAppContext()
  const { enabled = true, mapper } = options
  return useQuery({
    queryKey: [QUERY_KEYS.searchManagers, userId, query],
    queryFn: async () => {
      const { data } = await postNamedQuery('accounts', 'search-managers', query)

      return data
    },
    select: mapper,
    enabled
  })
}

export const useManagerById = (managerId, options = {}) => {
  const { userId } = useAppContext()
  const { enabled = true, mapper } = options
  return useQuery({
    queryKey: [QUERY_KEYS.getManagerById, userId, managerId],
    queryFn: async () => {
      const { data } = await postNamedQuery('accounts', 'get-manager-by-id', {
        managerId
      })

      return data
    },
    select: mapper,
    enabled
  })
}

export const useCreateManagerMutation = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async ({ manager }) => {
      const { data } = await postNamedCommand('accounts', 'create-manager', {
        manager
      })

      return data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.searchManagers], refetchType: 'all' }).catch(console.error)
    }
  })
}

export const useModifyManagerMutation = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async ({ manager }) => {
      const { data } = await postNamedCommand('accounts', 'modify-manager', {
        manager
      })

      return data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.searchManagers], refetchType: 'all' }).catch(console.error)
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.getManagerById], refetchType: 'all' }).catch(console.error)
    }
  })
}

export const useListManualPositionBatches = (showAll = false, options = {}) => {
  const { userId } = useAppContext()
  const { enabled = true, mapper, refetchInterval } = options
  return useQuery({
    queryKey: [QUERY_KEYS.listManualPositionBatches, userId, showAll],
    queryFn: async () => {
      const { data } = await postNamedQuery('accounts', 'list-manual-position-batches', {
        showAll
      })

      return data
    },
    refetchInterval,
    select: mapper,
    enabled
  })
}

export const useManualPositionBatchDetails = (batchId, options = {}) => {
  const { userId } = useAppContext()
  const { enabled = true, mapper, refetchInterval } = options
  return useQuery({
    queryKey: [QUERY_KEYS.getManualPositionBatchDetails, userId, batchId],
    queryFn: async () => {
      const { data } = await postNamedQuery('accounts', 'get-manual-position-batch-details', {
        batchId
      })

      return data
    },
    refetchInterval,
    select: mapper,
    enabled
  })
}

function chunkArray (array, chunkSize) {
  const result = []
  for (let i = 0; i < array.length; i += chunkSize) {
    result.push(array.slice(i, i + chunkSize))
  }
  return result
}

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))

export const useCreateManualPositionBatchMutation = () => {
  const queryClient = useQueryClient()
  const [progress, setProgress] = useState({ status: '' })
  const mutation = useMutation({
    mutationFn: async ({ data, tags, groups, name, monitor = 0 }) => {
      if (!data?.length) {
        throw new Error('no data')
      }
      setProgress({ status: 'Creating Batch' })
      const { data: batchResult } = await postNamedCommand('accounts', 'create-manual-position-batch', {
        name,
        tags,
        groups
      })

      const chunks = chunkArray(data, 500)
      for (let i = 0; i < chunks.length; ++i) {
        const chunk = chunks[i]
        setProgress({ status: `Uploading ${i + 1}/${chunks.length}` })
        const { data: chunkResult } = await postNamedCommand('accounts', 'add-manual-position-data', {
          batchId: batchResult.batchId,
          chunk
        })
        console.trace('useCreateBatchMutation', chunkResult)
      }

      setProgress({ status: 'Starting Processing' })
      const { data: startResult } = await postNamedCommand('accounts', 'start-manual-position-batch', {
        batchId: batchResult.batchId
      })

      setProgress({ status: 'Processing Batch', batchId: batchResult.batchId })

      if (monitor) {
        for (let i = 0; i < monitor; ++i) {
          const { data: statusResult } = await postNamedQuery('accounts', 'get-manual-position-batch-details', {
            batchId: batchResult.batchId
          })
          if (statusResult.batch?.isError) {
            setProgress({ status: 'Processing Error', batchId: batchResult.batchId, isError: true })
            return { ...startResult, status: statusResult }
          }
          if (statusResult.batch?.isComplete) {
            setProgress({ status: 'Processing Complete', batchId: batchResult.batchId })
            return { ...startResult, status: statusResult }
          }
          await sleep(5000)
        }
      }

      return startResult
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.listManualPositionBatches], refetchType: 'all' }).catch(console.error)
    }
  })
  return {
    createBatch: mutation.mutateAsync,
    isLoading: mutation.isLoading,
    progress
  }
}

export const useCreateManualPositionMutation = () => {
  const queryClient = useQueryClient()
  const [progress, setProgress] = useState({ status: '' })
  const mutation = useMutation({
    mutationFn: async ({ item, tags, groups, name, monitor = 0 }) => {
      setProgress({ status: 'Creating Manual Position' })
      const { data: batchResult } = await postNamedCommand('accounts', 'create-manual-position-single', {
        name,
        tags,
        groups,
        item
      })

      setProgress({ status: 'Processing Position', batchId: batchResult.batchId })

      if (monitor) {
        for (let i = 0; i < monitor; ++i) {
          const { data: statusResult } = await postNamedQuery('accounts', 'get-create-manual-position-status', {
            batchId: batchResult.batchId
          })
          if (statusResult.batch?.isError) {
            setProgress({ status: 'Processing Error', batchId: batchResult.batchId, isError: true })
            return { ...batchResult, status: statusResult }
          }
          if (statusResult.batch?.isComplete) {
            setProgress({ status: 'Processing Complete', batchId: batchResult.batchId })
            return { ...batchResult, status: statusResult }
          }
          await sleep(5000)
        }
      }

      return batchResult
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.visualBalanceSheet], refetchType: 'all' }).catch(console.error)
    }
  })
  return {
    createPosition: mutation.mutateAsync,
    isLoading: mutation.isLoading,
    progress
  }
}

export const useGetEditableAccounts = (clientIds) => {
  const { userId } = useAppContext()
  return useQuery({
    queryKey: [QUERY_KEYS.getEditableAccounts, userId, clientIds],
    queryFn: async () => {
      const { data } = await postNamedQuery('accounts', 'get-editable-accounts', {
        assignedToClientIds: clientIds
      })

      return data
    },
    select: data => data?.accounts ?? []
  })
}

export const useGetAccountStatuses = (options, query) => {
  const { userId } = useAppContext()
  const { enabled = true, mapper } = options

  return useQuery({
    queryKey: [QUERY_KEYS.getAccountStatuses, userId, query],
    queryFn: async () => {
      const { data } = await postNamedQuery('accounts', 'get-account-statuses', query)
      return data
    },
    select: mapper || (data => data ?? []),
    enabled
  })
}
