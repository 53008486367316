import React, { useCallback, useRef, useState } from 'react'
import { makeStyles } from '@material-ui/core'
import { useAdvisorUserDetails, useSectionEditing } from '../../AdvisorUserFormContext'
import SectionScreen from '../../../shared/SectionScreen'
import SectionHeader from '../../../shared/SectionHeader'
import EditButton from '../../../shared/EditButton'
import { useAssignRoleToAdvisorMutation } from '../../../../../../api/users'
import SaveCancelFooter from '../../../shared/SaveCancelFooter'
import AssignExperienceForm from './AssignExperienceForm'
import { useRoleOptions } from './useRoleOptions'

const useStyles = makeStyles((theme) => ({
  experienceSection: {
    position: 'relative',
    padding: '10px'
  }
}))

function PermissionsSection () {
  const { user, editSection, refetchUser, editing, cancelEdit } = useAdvisorUserDetails()
  const sectionEditing = useSectionEditing('role')
  const { data: roleOptions, isLoading } = useRoleOptions()

  const classes = useStyles()
  const formRef = useRef()
  const { mutateAsync: assignRole } = useAssignRoleToAdvisorMutation()
  const [processing, setProcessing] = useState(false)
  const submitter = useCallback(async (e) => {
    const onValid = async (form) => {
      try {
        setProcessing(true)
        await assignRole({
          userId: form.userId,
          roleId: form.roleId
        })
        await refetchUser()
        cancelEdit()
      } catch (err) {
        console.error('error trying to assign an experience to user', err)
        alert('There was an unexpected problem saving the request.')
        throw err
      } finally {
        setProcessing(false)
      }
    }
    const onInvalid = (errors) => {
      console.error(errors)
    }

    const handler = formRef.current.handleSubmit(onValid, onInvalid)
    await handler(e)
  }, [formRef, assignRole, refetchUser, setProcessing, cancelEdit])

  if (isLoading) {
    return <div>Loading</div>
  }

  return (
    <SectionScreen
      editing={editing}
      sectionIsEditing={sectionEditing}
      className={classes.experienceSection}
    >
      <SectionHeader text='Permissions'>
        <div>
          <EditButton
            policy='admin_edit_advisor_users'
            editing={sectionEditing}
            onClick={() => editSection({
              section: 'role'
            })}
          />
        </div>
      </SectionHeader>
      <AssignExperienceForm
        ref={formRef}
        user={user}
        roleOptions={roleOptions}
        editing={sectionEditing}
      />
      {sectionEditing ? (
        <SaveCancelFooter
          saveText='Save Permissions Assignment'
          onCancel={() => {
            cancelEdit()
            formRef.current.reset()
          }}
          onSave={submitter}
          processing={processing}
        />
      ) : null}
    </SectionScreen>
  )
}

export default PermissionsSection
