import React from 'react'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { useDispatch } from 'react-redux'
import { Box } from '@material-ui/core'
import useStyles from '../styles'
import { setToggleIsOpen, useAddAccountContext } from '../../../../redux/slices/addAccountContext'
import SydButton from '../../../commonDesign/Button'

dayjs.extend(utc)

const AdminAccountSetupLink = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { editingAccountId, labels: { manual_adminAccountSetupLink: setupLabels } } = useAddAccountContext()

  return (
    <div className={classes.modalInner}>
      <Box display='flex' justifyContent='center' alignItems='center' gridGap='18px' mt='18px'>
        <SydButton
          variant='primary'
          size='lg'
          onClick={() => window.open(`/admin/accounts/${editingAccountId}`, '_blank')}
        >
          {setupLabels.adminLinkText}
        </SydButton>
        <SydButton
          variant='secondary'
          size='lg'
          onClick={() => dispatch(setToggleIsOpen('false'))}
        >
          {setupLabels.closeLinkText}
        </SydButton>
      </Box>
    </div>
  )
}
export default AdminAccountSetupLink
