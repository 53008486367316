import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  jobListItem: {
    display: 'block',
    backgroundColor: ({ active }) => active ? theme.palette.white.main : theme.palette.gray.main,
    border: `1px solid ${theme.palette.darkGrey}`,
    maxWidth: '20rem',
    minWidth: '20rem',
    minHeight: '10rem',
    margin: '1rem',
    padding: '0rem 1rem',
    '& .__title': {
    },
    '& .__description': {
      color: theme.palette.darkGrey
    }
  }
}))

const JobListItem = ({ job }) => {
  const classes = useStyles({
    active: job.active
  })
  const to = useMemo(() => {
    return `/data-home/jobs/${job.ingestionJobId}`
  }, [job.ingestionJobId])

  return (
    <Link to={to} className={classes.jobListItem}>
      <div>
        <h3 className='__title'>[{job.ingestionJobId}] {job.name}</h3>
        <div className='__description'>{job.description}</div>
      </div>
    </Link>
  )
}

JobListItem.propTypes = {
  job: PropTypes.shape({
    ingestionJobId: PropTypes.number,
    name: PropTypes.string,
    description: PropTypes.string,
    active: PropTypes.bool
  })
}

export default JobListItem
