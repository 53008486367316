import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import * as htmlToImage from 'html-to-image'
import { jsPDF as JSPdf } from 'jspdf'
import { useAppContext, useSetAppContext } from '../../../redux/slices/appContext'
import { EXPORT_CLASSNAME } from '../../../constants'

const MIN_HEIGHT = 1520

export const ExportContext = createContext({
  enabled: false,
  setEnabled: () => {},
  configureExport: () => {}
})

/** CMD+E to export */
const useHotkey = (toPdf) => {
  const handleKeyPress = useCallback(event => {
    const { key, metaKey } = event

    if (metaKey && key === 'e') {
      toPdf()
    }
  }, [toPdf])

  useEffect(() => {
    window.addEventListener('keydown', handleKeyPress)
    return () => {
      window.removeEventListener('keydown', handleKeyPress)
    }
  }, [handleKeyPress])
}

export const useExportContext = () => {
  return useContext(ExportContext)
}

const findExportNode = (targetClassName = EXPORT_CLASSNAME) => {
  const node = document.getElementsByClassName(targetClassName)
  return node.length ? node[0] : null
}

export const ExportProvider = ({ children }) => {
  const { clientId } = useAppContext()
  const setAppContext = useSetAppContext()
  const [enabled, setEnabled] = useState(false)
  const [targetClassName, setTargetClassName] = useState(EXPORT_CLASSNAME)
  const configureExport = useCallback((isEnabled, targetClassName = EXPORT_CLASSNAME) => {
    setEnabled(isEnabled)
    setTargetClassName(targetClassName)
  }, [setEnabled, setTargetClassName])

  const toPdf = useCallback(() => {
    const childNode = findExportNode(targetClassName)
    if (!childNode) {
      alert('There was a problem exporting the current view')
      console.error('Could not export the current view')
      return
    }

    setAppContext({
      isPrintView: true
    })

    const date = new Date()
    const today = [date.getMonth() + 1, date.getDate(), date.getFullYear()]

    childNode.style.padding = '30px'
    setTimeout(() => {
      htmlToImage.toCanvas(childNode)
        .then((canvas) => {
          const fileName = `${clientId}_export-view-${today.join('-')}.pdf`
          const dataUrl = canvas.toDataURL('image/svg')
          const pdfOptions = {
            orientation: childNode.scrollHeight > MIN_HEIGHT ? 'portrait' : 'landscape',
            unit: 'px',
            format: [childNode.offsetWidth, childNode.scrollHeight],
            compressPdf: true
          }

          childNode.style.padding = '0px'

          const pdf = new JSPdf(pdfOptions)

          const imgProps = pdf.getImageProperties(dataUrl)
          const pdfWidth = pdf.internal.pageSize.getWidth()
          const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width
          pdf.addImage(dataUrl, 'PNG', 0, 0, pdfWidth, pdfHeight)
          pdf.save(fileName)
        })
        .catch(function (error) {
          console.error('Something went wrong generating the pdf', error)
        })
        .finally(() => {
          setAppContext({
            isPrintView: false
          })
        })
    }, 500)
  }, [setAppContext, clientId, targetClassName])
  useHotkey(toPdf)

  const value = useMemo(() => ({
    enabled,
    configureExport,
    toPdf
  }), [enabled, toPdf, configureExport])

  return (
    <ExportContext.Provider value={value}>
      {children}
    </ExportContext.Provider>
  )
}

ExportProvider.propTypes = {
  children: PropTypes.node
}
