import React, { useCallback, useImperativeHandle, useState } from 'react'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import { Grid, makeStyles } from '@material-ui/core'
import SydModal from '../../commonDesign/SydModal'
import SydButton from '../../commonDesign/Button'
import { useAppContext } from '../../../redux/slices/appContext'
import PersonalSpace from '../../molecules/PersonalSpace'
import SydModalActions from '../../commonDesign/SydModal/SydModalActions'
import DateRangePicker from '../../molecules/DateRangePicker'

dayjs.extend(isSameOrBefore)

const useStyles = makeStyles((theme) => ({
  dateRangeInput: {
    background: '#FFFFFF',
    border: `1px solid ${theme.palette.gray.darker}`,
    borderRadius: '4px',
    padding: `${theme.layout.padding.p10} ${theme.layout.padding.p0} ${theme.layout.padding.p10} ${theme.layout.padding.p10}`,
    '& input': {
      padding: '0'
    }
  }
}))

const CustomRangeDialog = React.forwardRef(function ({ onSave }, ref) {
  const classes = useStyles()
  const { availableDates } = useAppContext()
  const [isOpen, setIsOpen] = useState(false)
  const [value, setValue] = useState({
    startDate: null,
    endDate: null
  })
  const handleChange = useCallback((startDate, endDate) => {
    setValue({ startDate, endDate })
  }, [setValue])
  useImperativeHandle(ref, () => ({
    open: () => {
      setIsOpen(true)
      setValue({
        start: null,
        endDate: null
      })
    }
  }), [setIsOpen, setValue])

  const handleSave = useCallback(() => {
    onSave({ key: 'CTM', ...value })
    setIsOpen(false)
  }, [onSave, setIsOpen, value])

  return (
    <SydModal
      disablePortal={false}
      title='Choose Custom Date Range'
      open={isOpen}
      onClose={() => setIsOpen(false)}
      size='small'
    >
      <PersonalSpace size={40} />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <DateRangePicker
            className={classes.dateRangeInput}
            variant='space-evenly'
            onChange={handleChange}
            defaultStartDate={availableDates?.mainDate}
            defaultEndDate={availableDates?.mainDate}
            minDate={availableDates.min}
            maxDate={availableDates.max}
            format='MM/DD/YYYY'
            shouldDisableWeekends
            datePickerWidth='12rem'
            startDateLabel={null}
            endDateLabel={null}
            InputProps={{
              disableUnderline: true,
              error: false
            }}
          />
        </Grid>
      </Grid>
      <PersonalSpace size={40} />
      <SydModalActions>
        <SydButton
          variant='ghost'
          onClick={() => setIsOpen(false)}
        >
          Cancel
        </SydButton>
        <SydButton
          variant='primary'
          onClick={handleSave}
        >
          Select
        </SydButton>
      </SydModalActions>
    </SydModal>
  )
})

CustomRangeDialog.propTypes = {
  onSave: PropTypes.func
}

export default CustomRangeDialog
