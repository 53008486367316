import React, { createContext, useCallback, useContext, useMemo, useState } from 'react'
import PropTypes from 'prop-types'

export const ComponentModelContext = createContext({ })

export const useComponentModelContext = () => {
  return useContext(ComponentModelContext)
}

export const useSectionEditing = (sectionName) => {
  const { editing, section } = useComponentModelContext()
  return useMemo(() => {
    return editing && section === sectionName
  }, [editing, section, sectionName])
}

const useProviderValue = ({ componentModel, isFetching }) => {
  const [formState, setFormState] = useState({
    editing: false,
    section: null
  })

  const editSection = useCallback(({
    section
  }) => {
    setFormState((prevState) => ({
      ...prevState,
      editing: !prevState.editing,
      section: !prevState.editing ? section : null
    }))
  }, [setFormState])

  const cancelEdit = useCallback(() => {
    setFormState((prevState) => ({
      ...prevState,
      editing: false,
      section: null
    }))
  }, [setFormState])

  return useMemo(() => ({
    componentModel,
    editSection,
    cancelEdit,
    isFetching,
    ...formState
  }), [componentModel, editSection, cancelEdit, formState, isFetching])
}

export const ComponentModelProvider = ({ componentModel, isFetching, children }) => {
  const value = useProviderValue({
    componentModel,
    isFetching
  })

  return (
    <ComponentModelContext.Provider value={value}>
      {children}
    </ComponentModelContext.Provider>
  )
}

ComponentModelProvider.propTypes = {
  componentModel: PropTypes.object,
  children: PropTypes.node,
  isFetching: PropTypes.bool
}

ComponentModelProvider.defaultProps = {
}
