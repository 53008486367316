import React, { useCallback, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import Menu from '../../../../molecules/Menu'
import Icon from '../../../../atoms/Icon'
import { ICON_NAMES } from '../../../../../constants'
import { createBillingRunExports, updateBillingRun, updateBillingRunReport } from '../../../../../service'
import { useContextContainer } from '../../../../../abundanceEngine/components/ContextContainer'
import { BILLING_RUNS_CONTEXT_KEY, REPORT_TYPES, RUN_STATUS, RUN_STATUS_LABELS } from './helpers'
import MarkAsFinalConfirmModal from './MarkAsFinalConfirmModal'

const useStyles = makeStyles(() => ({
  iconButton: {
    cursor: 'pointer'
  }
}))

const CellSettingsMenu = ({ row: { original } }) => {
  const classes = useStyles()
  const [showMarkAsFinalModal, setShowMarkAsFinalModal] = useState(null)
  const [{ refetchBillingRuns }] = useContextContainer(BILLING_RUNS_CONTEXT_KEY)

  const onChangeRunStatus = useCallback(async ({ value: { billingRunId } }, status) => {
    try {
      await updateBillingRun(billingRunId, { status })
      await refetchBillingRuns()
    } catch (err) {
      console.error(err)
    }
  }, [refetchBillingRuns])

  const onUpdateBillingRunReport = useCallback(
    async ({ value: { billingRunId } }) => {
      try {
        await updateBillingRunReport(
          billingRunId,
          REPORT_TYPES.CASH_AVAILABLE_REPORT
        )
        refetchBillingRuns()
      } catch (err) {
        console.error(err)
      }
    },
    [refetchBillingRuns]
  )

  const onTriggerExportRerun = useCallback(
    async ({ value: { billingRunId } }) => {
      try {
        await createBillingRunExports({ billingRunId })
        refetchBillingRuns()
      } catch (err) {
        console.error(err)
      }
    },
    [refetchBillingRuns]
  )

  const menuOptions = useMemo(() => {
    return [
      {
        iconName: ICON_NAMES.archive,
        label: RUN_STATUS_LABELS[RUN_STATUS.ARCHIVED],
        onClick: (option) => onChangeRunStatus(option, RUN_STATUS.ARCHIVED),
        value: original
      },
      (original.status !== RUN_STATUS.IN_REVIEW && {
        iconName: ICON_NAMES.bill,
        label: `${RUN_STATUS_LABELS[RUN_STATUS.IN_REVIEW]}`,
        onClick: (option) => onChangeRunStatus(option, RUN_STATUS.IN_REVIEW),
        value: original
      }),
      (original.status !== RUN_STATUS.MARK_AS_FINAL && {
        iconName: ICON_NAMES.check,
        label: RUN_STATUS_LABELS[RUN_STATUS.MARK_AS_FINAL],
        onClick: (option) => setShowMarkAsFinalModal(option.value),
        value: original
      }),
      {
        iconName: ICON_NAMES.update,
        label: 'Update Available Cash',
        onClick: (option) => onUpdateBillingRunReport(option),
        value: original
      },
      ([RUN_STATUS.IN_REVIEW, RUN_STATUS.MARK_AS_FINAL, RUN_STATUS.MARK_AS_FINAL, RUN_STATUS.ACTIVE].includes(original.status) && {
        iconName: ICON_NAMES.folders,
        label: 'Rerun all exports',
        onClick: (option) => onTriggerExportRerun(option),
        value: original
      })
    ].filter(x => x)
  }, [original, onChangeRunStatus, onUpdateBillingRunReport, onTriggerExportRerun])

  return (
    <>
      <Menu options={menuOptions}>
        {({ setAnchorEl }) => (
          <div className={classes.iconButton} onClick={setAnchorEl}>
            <Icon name={ICON_NAMES.threeDots} customSize='1.5rem' color='black' />
          </div>
        )}
      </Menu>
      {!!showMarkAsFinalModal?.billingRunId && (
        <MarkAsFinalConfirmModal
          billingRun={showMarkAsFinalModal}
          onClose={() => setShowMarkAsFinalModal(null)}
          onComplete={async () => {
            setShowMarkAsFinalModal(null)
            await new Promise((resolve) => setTimeout(resolve, 3000))
            await refetchBillingRuns()
          }}
        />
      )}
    </>
  )
}

CellSettingsMenu.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.object
  })
}

export default CellSettingsMenu
