import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { getRandomColor } from '../../../utils'
import AllocationPieChart from './AllocationPieChart'
import AllocationBarChart from './AllocationBarChart'

const sortOrderMapping = {
  ENDINGVALUE_DESC: (a, b) => b.value - a.value,
  ENDINGVALUE_ASC: (a, b) => a.value - b.value,
  ORDINAL_DESC: (a, b) => b.ordinal - a.ordinal,
  ORDINAL_ASC: (a, b) => a.ordinal - b.ordinal
}

const AllocationChartDiscriminator = ({
  data,
  isLoading,
  labelLowerLimit,
  maxItems,
  remainingClassificationsLabel,
  remainingClassificationsColor,
  chartType,
  valueFormat,
  balanceFormat,
  sortOrder,
  showLegend,
  pieInnerRadius,
  pieContainerStyles,
  pieChartHeight
}) => {
  const seriesData = useMemo(() => {
    if (isLoading) return []

    const colors = data.reduce(
      (acc, { levelId }) => ({
        ...acc,
        [levelId]: getRandomColor(500)
      }),
      {}
    )

    const total = data.reduce((p, c) => c.ending.endingValue + p, 0)
    const mapped = data.map((x) => ({
      id: x.uniqueId,
      label: x.levelName,
      value: total ? x.ending.endingValue / total : 0,
      valueFormat: balanceFormat,
      color: x.ending[`${x.levelType}ColorField`] || colors[x.levelId],
      balance: x.ending.endingValue,
      ordinal: x.ending.ordinal ?? x.ordinal,
      original: x
    }))
    mapped.sort(sortOrderMapping[sortOrder])
    if (maxItems >= mapped.length) {
      return mapped
    }

    /** Bucket everything else */
    const topItems = mapped.splice(0, maxItems)
    const bucketedItems = mapped.reduce(
      (prev, cur) => {
        prev.value += cur.value
        prev.balance += cur.balance
        return prev
      },
      {
        id: 'everything_else',
        label: remainingClassificationsLabel,
        value: 0,
        valueFormat: balanceFormat,
        color: remainingClassificationsColor,
        balance: 0,
        ordinal: Number.MAX_SAFE_INTEGER,
        original: null
      }
    )
    return [...topItems, bucketedItems]
  }, [
    data,
    balanceFormat,
    isLoading,
    maxItems,
    remainingClassificationsLabel,
    remainingClassificationsColor,
    sortOrder
  ])

  return chartType === 'pie' ? (
    <AllocationPieChart
      data={seriesData}
      isLoading={isLoading}
      labelLowerLimit={labelLowerLimit}
      maxItems={maxItems}
      valueFormat={valueFormat}
      balanceFormat={balanceFormat}
      showLegend={showLegend}
      innerRadius={pieInnerRadius}
      containerStyles={pieContainerStyles}
      chartHeight={pieChartHeight}
    />
  ) : (
    <AllocationBarChart
      data={seriesData}
      isLoading={isLoading}
      valueFormat={valueFormat}
      balanceFormat={balanceFormat}
      showLegend={showLegend}
    />
  )
}

AllocationChartDiscriminator.propTypes = {
  data: PropTypes.array,
  isLoading: PropTypes.bool,
  labelLowerLimit: PropTypes.number,
  /** The maximum number of items to show, everything else will be bucketed */
  maxItems: PropTypes.number,
  remainingClassificationsLabel: PropTypes.string,
  chartType: PropTypes.oneOf(['pie', 'bar']),
  valueFormat: PropTypes.string,
  balanceFormat: PropTypes.string,
  remainingClassificationsColor: PropTypes.string,
  sortOrder: PropTypes.oneOf([
    'ENDINGVALUE_DESC',
    'ENDINGVALUE_ASC',
    'ORDINAL_DESC',
    'ORDINAL_ASC'
  ]),
  showLegend: PropTypes.bool,
  pieInnerRadius: PropTypes.number,
  pieContainerStyles: PropTypes.object,
  pieChartHeight: PropTypes.number
}

AllocationChartDiscriminator.defaultProps = {
  data: [],
  isLoading: false,
  labelLowerLimit: 0.05,
  maxItems: 5,
  remainingClassificationsLabel: 'Everything Else',
  chartType: 'pie',
  valueFormat: 'allocation',
  balanceFormat: 'marketValue',
  remainingClassificationsColor: getRandomColor(600),
  sortOrder: 'ENDINGVALUE_DESC',
  showLegend: true
}

export default AllocationChartDiscriminator
