import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import { useWealthJourney } from '../WealthJourneyProvider'
import TypeMarker from '../../shared/TypeMarker'

const useStyles = makeStyles(theme => ({
  entryLegend: ({ layout, height, disableFilter }) => {
    const common = {
      '& .__legend-item': {
        userSelect: 'none',
        flex: '1 0 auto',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: theme.layout.gap.g10,
        maxHeight: '40px',
        transition: 'background-color .2s ease-in-out, outline .2s ease-in-out',
        borderRadius: theme.layout.radius.loose,
        outlineWidth: '2px',
        outlineStyle: 'solid',
        outlineColor: 'transparent',
        padding: `${theme.layout.padding.p10} ${theme.layout.padding.p20}`,
        '&:hover': disableFilter ? undefined : {
          backgroundColor: `color-mix(in srgb, ${theme.palette.primary.main} 10%, transparent)`,
          cursor: 'pointer'
        },
        '&:active': disableFilter ? undefined : {
          backgroundColor: `color-mix(in srgb, ${theme.palette.primary.main} 20%, transparent)`,
          cursor: 'pointer'
        },
        '&.__selected': disableFilter ? undefined : {
          outlineColor: `color-mix(in srgb, ${theme.palette.primary.main} 40%, transparent)`
        }
      }
    }
    if (layout === 'column') {
      return {
        height: height,
        maxHeight: height,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        gap: theme.layout.gap.g10,
        ...common
      }
    }
    if (layout === 'row') {
      return {
        display: 'inline-flex',
        flexDirection: 'row',
        gap: theme.layout.gap.g10,
        ...common
      }
    }
  }
}))

function EntryLegend ({ height, layout, disableFilter, entryTypes: manualFilter }) {
  const classes = useStyles({ height, layout, disableFilter })
  const { entryTypeFilter, setEntryTypeFilter, entryTypes, entriesLoading, entries } = useWealthJourney()

  const options = useMemo(() => {
    let _filter = manualFilter
    if (!manualFilter?.length) {
      _filter = Array.from(entries.reduce((acc, entry) => {
        acc.add(entry.entryType.entryTypeCode)
        return acc
      }, new Set()))
    }
    return (entryTypes || []).filter(et => {
      return manualFilter === '*' || _filter.includes(et.entryTypeCode)
    }).map(et => ({
      entryTypeId: et.entryTypeId,
      selected: entryTypeFilter.has(et.entryTypeId),
      label: et.entryTypeName,
      color: et.color
    }))
  }, [entryTypes, entryTypeFilter, manualFilter, entries])

  if (entriesLoading) return null

  return (
    <div className={classes.entryLegend}>
      {options.map(opt => (
        <div
          key={`entryTypeOption_${opt.label}`}
          role={disableFilter ? undefined : 'button'}
          className={clsx('__legend-item', { __selected: opt.selected })}
          onClick={(e) => disableFilter ? null : setEntryTypeFilter(opt.entryTypeId, e.shiftKey)}
        >
          <TypeMarker color={opt.color} size={24} />
          <div>{opt.label}</div>
        </div>
      ))}
    </div>
  )
}

EntryLegend.propTypes = {
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  entryTypes: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.oneOf(['*'])]),
  layout: PropTypes.oneOf(['row', 'column']),
  disableFilter: PropTypes.bool
}

EntryLegend.defaultProps = {
  height: '300px',
  entryTypes: ['milestone', 'meeting'],
  layout: 'column',
  disableFilter: false
}

export default EntryLegend
