import React from 'react'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import { TEXT_VARIANTS } from '../../constants'

const useStyles = makeStyles({
  text: ({
    color,
    customFontFamily,
    customFontSize,
    customFontWeight,
    textTransform,
    lineHeight
  }) => ({
    color: color || 'inherit',
    ...(customFontSize && { fontSize: `${customFontSize} !important` }),
    ...(customFontFamily && { fontFamily: `${customFontFamily} !important` }),
    ...(customFontWeight && { fontWeight: `${customFontWeight} !important` }),
    ...(textTransform && { textTransform: `${textTransform} !important` }),
    ...(lineHeight && { lineHeight: `${lineHeight} !important` })
  })
})

function Text ({
  className,
  children,
  color,
  customFontFamily,
  customFontSize,
  customFontWeight,
  lineHeight,
  rootClassName,
  text,
  textTransform,
  variant
}) {
  const classes = useStyles({
    color,
    customFontSize,
    customFontWeight,
    customFontFamily,
    lineHeight,
    textTransform,
    variant
  })
  return (
    <Typography
      className={clsx({ [className]: !!className })}
      classes={{
        root: clsx(classes.text, { [rootClassName]: !!rootClassName })
      }}
      variant={variant}
    >
      {text || children}
    </Typography>
  )
}

Text.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
  color: PropTypes.string,
  customFontFamily: PropTypes.string,
  customFontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  customFontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  lineHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  rootClassName: PropTypes.string,
  textTransform: PropTypes.string,
  text: PropTypes.any,
  variant: PropTypes.oneOf(Object.values(TEXT_VARIANTS))
}

Text.defaultProps = {
  className: undefined,
  children: undefined,
  color: '',
  customFontFamily: '',
  customFontSize: '',
  customFontWeight: '',
  lineHeight: undefined,
  rootClassName: undefined,
  textTransform: undefined,
  text: undefined,
  variant: TEXT_VARIANTS.body1
}

export default Text
