import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import Icon from '../../atoms/Icon'
import { useLinkButtonStyles } from './useLinkButtonStyles'

function SydLinkButton ({ to, href, style, icon, priority, variant, size, children }) {
  const classes = useLinkButtonStyles({ priority, variant, size })

  if (!to && href) {
    return (
      <a href={href} className={classes.noUnderline}>
        <div className={classes.commonDesignLinkButton}>
          <Icon name={icon} />
          {children}
        </div>
      </a>
    )
  }

  return (
    <Link
      className={classes.noUnderline}
      to={to}
      style={style}
      icon={icon}
      priority={priority}
    >
      <div className={classes.commonDesignLinkButton}>
        <Icon name={icon} />
        {children}
      </div>
    </Link>
  )
}

SydLinkButton.propTypes = {
  priority: PropTypes.oneOf(['main', 'warning']),
  variant: PropTypes.oneOf(['primary', 'secondary', 'outline', 'ghost']),
  icon: PropTypes.string,
  to: PropTypes.string,
  href: PropTypes.string,
  style: PropTypes.object,
  children: PropTypes.node,
  size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl'])
}

SydLinkButton.defaultProps = {
  priority: 'main',
  variant: 'outline',
  icon: null,
  policy: null,
  disabled: false,
  size: 'md'
}

export default SydLinkButton
