import React from 'react'
import PropTypes from 'prop-types'
import SydButton from '../../../../../commonDesign/Button'
import ActionGroup from '../shared/ActionGroup'
import { usePolicy } from '../../../../../../hooks/usePolicy'
import { useClientReviewContext } from '../ClientReviewContext'

function FeeScheduleActions ({ feeSchedule }) {
  const cr = useClientReviewContext()
  const canAssignFeeSchedules = usePolicy('billing_assign_fee_schedules')
  const canEditFamilyRates = usePolicy('billing_edit_family_rates')

  if (!feeSchedule || (!canAssignFeeSchedules && !canEditFamilyRates)) {
    return null
  }

  return (
    <ActionGroup>
      {canAssignFeeSchedules ? (
        <div>
          <SydButton size='sm' icon='sync' onClick={() => cr.dialogs.replaceFeeSchedule(feeSchedule)}>Replace Fee Schedule</SydButton>
        </div>
      ) : null}
      {canEditFamilyRates ? (
        <div>
          <SydButton size='sm' icon='accountCircle' onClick={() => cr.dialogs.editFamilyRate(feeSchedule)}>Edit Family Rate</SydButton>
        </div>
      ) : null}
      {canAssignFeeSchedules ? (
        <div>
          <SydButton size='sm' variant='outline' priority='warning' icon='delete' onClick={() => cr.dialogs.removeFeeSchedule(feeSchedule)}>Remove Fee Schedule</SydButton>
        </div>
      ) : null}
    </ActionGroup>
  )
}

FeeScheduleActions.propTypes = {
  feeSchedule: PropTypes.any
}

export default FeeScheduleActions
