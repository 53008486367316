import dayjs from 'dayjs'

export const mapAccountsToGroups = (accounts) => (
  accounts
    .reduce((acc, account) => {
      Object.entries(account.groups).forEach(([key, values]) => {
        values.forEach((value) => {
          const { groupId, longName, shortName } = value

          const existingGroup = acc.find((group) => group.groupId === groupId && group.groupType === key)
          const { accountName, accountNumber, asOfDate, accountId, custodian } = account
          const accountRow = {
            levelName: accountName,
            accountNumber,
            custodian: custodian ? custodian[0] : {},
            asOfDate: dayjs(asOfDate).format('M/DD/YYYY'),
            accountId,
            _next: null
          }

          if (existingGroup) {
            existingGroup.subRows.push(accountRow)
          } else {
            acc.push({
              levelName: longName,
              levelId: groupId,
              groupId,
              groupType: key,
              longName,
              shortName,
              subRows: [accountRow]
            })
          }
        })
      })
      return acc
    }, [])
    .map(group => ({
      ...group,
      levelName: group.levelName + ` (${group.subRows?.length ?? 0})`
    }))
)
