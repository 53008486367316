import React from 'react'
import PropTypes from 'prop-types'
import { alpha, makeStyles } from '@material-ui/core'
import Card from '../../../molecules/Card'
import FadeIn from '../../../molecules/Transitions/FadeIn'

const useStyles = makeStyles((theme) => ({
  blade: {
    width: '100%',
    padding: 0,
    maxHeight: 'calc(100vh - 100px)',
    minHeight: 'calc(100vh - 100px)',
    height: 'calc(100vh - 100px)',
    overflowY: ({ scrollable }) => scrollable ? 'auto' : 'hidden',
    overflowX: ({ scrollable }) => scrollable ? 'clip' : 'hidden',
    boxShadow: theme.layout.shadow.normal,
    borderColor: alpha(theme.palette.primary.main, 0.5)
  },
  bladeContent: {
    position: 'relative',
    paddingBottom: ({ scrollable }) => scrollable ? '100px' : undefined,
    width: '100%',
    height: '100%'
  }
}))

export function Blade ({ children, scrollable, style }) {
  const classes = useStyles({ scrollable })

  return (
    <Card variant='outline' className={classes.blade} style={style}>
      <FadeIn className={classes.bladeContent}>
        {children}
      </FadeIn>
    </Card>
  )
}

Blade.propTypes = {
  children: PropTypes.node,
  scrollable: PropTypes.bool,
  style: PropTypes.object
}

Blade.defaultProps = {
  scrollable: true
}
