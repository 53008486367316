import React, { useMemo } from 'react'
import { makeStyles } from '@material-ui/core'
import Text from '../../atoms/Text'
import { TEXT_VARIANTS } from '../../../constants'
import FastAvatar from '../../molecules/FastAvatar'
import { useHomeDashboard } from './HomeDashboardContext'

const useStyles = makeStyles((theme) => ({
  clientInformation: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.layout.gap.medium
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center'
  },
  avatar: {
    boxShadow: theme.layout.shadow.normal
  }
}))

const useFamilyName = (client) => {
  return useMemo(() => {
    if (!client?.longName) return 'Please select a Client'
    if (client?.longName.length > 50) {
      return client?.longName.substring(0, client?.longName.lastIndexOf(' '))
    } else {
      return client?.longName
    }
  }, [client])
}

const useDescription = (client) => {
  return useMemo(() => {
    return client?.description
  }, [client])
}

const ClientInformation = () => {
  const { client } = useHomeDashboard()
  const classes = useStyles()
  const familyName = useFamilyName(client)
  const description = useDescription(client)

  return (
    <div className={classes.clientInformation}>
      <div className={classes.center}>
        <FastAvatar
          className={classes.avatar}
          avatarUrl={client?.avatarUrl}
          abbreviation={client?.clientAbbreviation}
          size='xl'
        />
      </div>
      <div className={classes.center}>
        <Text
          variant={TEXT_VARIANTS.h1}
          text={familyName}
          customFontSize='min(32px, 4.5vh)'
        />
      </div>
      <div className={classes.center}>
        <Text
          variant={TEXT_VARIANTS.body1}
          text={description}
        />
      </div>
    </div>
  )
}

ClientInformation.propTypes = {
}

ClientInformation.defaultProps = {
}

export default ClientInformation
