import dayjs from 'dayjs'
import MonteCarlo from '../../../../hooks/monteCarlo'

/**
 * @typedef {Object} Plan
 * @property {string} planId
 * @property {string} shortName
 * @property {string} longName
 * @property {PlanAssumptions} assumptions
 *
 */
/**
 * @typedef {Object} PlanAssumptions
 * @property {PlanAssumption} portfolioGrowthModel
 * @property {PlanAssumption} inflationModel
 */
/**
 * @typedef {Object} PlanAssumption
 * @property {string} growthType
 * @property {number} growthFactor
 * @property {boolean} [adjustReturns]
 */
/**
 * @typedef {Object} Goal
 * @property {string} startDate
 * @property {string} endDate
 * @property {string} frequency
 * @property {number} flowAmount
 * @property {string} goalCategoryId
 */

/**
 * Get Projected Data
 * @param currentPlan {Plan} - current plan
 * @param activeGoals {Goal[]} - active goals to account for
 * @param clientBalance {number} - current client balance
 * @param displayDates {{startDate: string, endDate: string}} - date range to display
 * @param incrementUnit {"day" | "month" | "year"} - increment to create records for
 * @returns {{valueDate: string, value: number}[]} - array of projected values
 */
export const getProjectedData = ({
  currentPlan,
  activeGoals,
  clientBalance,
  displayDates
}, incrementUnit = 'year') => {
  const { startDate, endDate } = displayDates
  const flowSchedule = calcFlows({ activeGoals, currentPlan, displayDates })

  let whileDate = dayjs.utc(startDate).clone().startOf(incrementUnit)
  let currentValue = clientBalance

  const returnData = [{
    valueDate: whileDate.format('YYYY-MM-DD'),
    value: currentValue
  }]
  whileDate = whileDate.add(1, incrementUnit)

  while (whileDate <= endDate) {
    const currentDate = whileDate.clone()
    const currentWithdrawal = flowSchedule.find(x => dayjs.utc(x.valueDate).isSame(currentDate, incrementUnit))

    currentValue = getGrowthValue(currentValue, currentPlan?.assumptions)
    if (currentWithdrawal) {
      currentValue += currentWithdrawal.value
    }

    returnData.push({
      valueDate: currentDate.format('YYYY-MM-DD'),
      value: currentValue
    })
    whileDate = whileDate.add(1, incrementUnit)
  }
  return returnData
}

/**
 * Get Growth Value
 * takes in current value and assumptions object and returns calculated value
 * @param currentValue {number}
 * @param assumptions {PlanAssumptions}
 * @returns {number}
 */
function getGrowthValue (currentValue, assumptions) {
  if (!assumptions) {
    return currentValue
  }
  const { portfolioGrowthModel, inflationModel } = assumptions

  if (portfolioGrowthModel.growthType === 'constant') {
    let growthRate = portfolioGrowthModel.growthFactor
    if (inflationModel && inflationModel.adjustReturns) {
      growthRate = growthRate - inflationModel.growthFactor
    }
    return currentValue * (1 + growthRate)
  }
  return currentValue
}

/**
 * Calculate the flows of the goals over time
 * @param activeGoals
 * @param currentPlan {Plan} - plan to use assumptions from
 * @param displayDates {{startDate: string, endDate: string}} - date range
 * @returns {{valueDate: string, value: number}[]}
 */
export function calcFlows ({ activeGoals, currentPlan, displayDates }) {
  const { startDate, endDate } = displayDates

  // Calculate Value Change
  const returnData = []
  if (!activeGoals.length || !currentPlan) {
    return returnData
  }
  const { assumptions } = currentPlan

  // Map through each goal
  const goalFlows = activeGoals.map((goal) => {
    let currentDate = dayjs.utc(dayjs.utc(startDate) > dayjs.utc(goal.startDate) ? startDate : goal.startDate).clone()
    const flowEndDate = goal.frequency === 'ONETIME'
      ? goal.startDate
      : !goal.endDate || dayjs.utc(goal.endDate) > endDate
        ? endDate
        : dayjs.utc(goal.endDate)

    let calculatedFlowAmount = goal.flowAmount

    const goalFlowData = [{
      flowDate: currentDate.format('YYYY-MM-DD'),
      flowValue: Number(calculatedFlowAmount)
    }]

    while (currentDate.isBefore(flowEndDate, 'year')) {
      currentDate = currentDate.add(1, 'y')

      calculatedFlowAmount = calculatedFlowAmount * (1 + (assumptions?.inflationModel?.growthFactor ?? 0))

      goalFlowData.push({
        flowDate: currentDate.format('YYYY-MM-DD'),
        flowValue: Number(calculatedFlowAmount)
      })
    }

    return goalFlowData
  })

  let whileDate = dayjs.utc(startDate).clone().startOf('year')
  while (whileDate <= endDate) {
    const currentDate = whileDate.clone()

    const totalDateFlow = goalFlows.reduce((acc, flows) => {
      const periodFlowValue = flows
        .filter(x => dayjs.utc(x.flowDate).isSame(currentDate, 'year'))
        .reduce((acc, flow) => {
          return acc + flow.flowValue
        }, 0)
      return acc + periodFlowValue
    }, 0)

    returnData.push({
      valueDate: whileDate.format('YYYY-MM-DD'),
      value: totalDateFlow
    })
    whileDate = whileDate.add(1, 'y')
  }
  return returnData
}

/**
 * Get Line Chart Data
 * Calculates projected data and builds line chart data out of it
 * @param currentPlan {Plan} - current plan to get assumptions from
 * @param [linkedPlan] {Plan} - linked plan to also display graph for
 * @param [linkedPlanActiveGoals] {Goal[]} - linked plan goals to use for calc flows
 * @param activeGoals {Goal[]} - active goals to use for calc flows
 * @param clientBalance {{endingValue: number}} - current client balance
 * @param displayDates {{startDate: string, endDate: string}} - date range to display
 * @param incrementUnit {"day" | "month" | "year"} - increment to create records for
 * @returns {[{color: string, data: {x: *, y: number}[], style: {strokeWidth: string}, id: string, strokeWidthA: string}]}
 */
export const getLineChartData = ({
  currentPlan,
  linkedPlan,
  linkedPlanActiveGoals,
  activeGoals,
  clientBalance,
  displayDates
}, incrementUnit = 'year') => {
  return [{ plan: currentPlan, activeGoals: activeGoals }, {
    plan: linkedPlan,
    activeGoals: linkedPlanActiveGoals
  }].map(({ plan, activeGoals }) => {
    if (!plan) return null
    const data = getProjectedData({
      currentPlan: plan,
      activeGoals,
      clientBalance,
      displayDates
    }, incrementUnit)

    return {
      id: `${plan.planId}_${plan.shortName}`,
      title: plan.shortName,
      sort: plan.planId === currentPlan.planId ? 1 : -1,
      areaOpacity: plan.planId === currentPlan.planId ? 1 : plan.originPlanId ? 0.55 : 0.2,
      strokeWidth: '10',
      color: plan.planStatusColor,
      data: data.map(row => ({ x: row.valueDate, y: Math.max(row.value, 0) }))
    }
  }).filter(x => x).sort((a, b) => a.sort - b.sort)
}

/**
 * Run a Monte Carlo Simulation
 * @param clientBalance {{endingValue: number}} - current client balance
 * @param currentPlan {Plan} - current plan to get assumptions from
 * @param activeGoals {Goal[]} - active goals to use for calc flows
 * @param displayDates {{startDate: string, endDate: string}} - date range to display
 * @returns {Promise<{simulationData: *[], percentiles: *[], successRate: number, simulationDetails, processTime: number}|null>}
 */
export const runSimulation = async ({ clientBalance, currentPlan, activeGoals, displayDates }) => {
  const { startDate, endDate } = displayDates
  if (!clientBalance || !currentPlan?.assumptions) {
    return null
  }
  const { assumptions } = currentPlan
  const periods = dayjs.utc(endDate).diff(startDate, 'year')
  const flowData = calcFlows({
    activeGoals,
    currentPlan,
    displayDates: { startDate, endDate: dayjs.utc(endDate).add(1, 'year') }
  })
  const simulationDetails = {
    frequency: 'y',
    periods: periods,
    startDate: dayjs.utc(startDate).startOf('y'),
    startValue: clientBalance,
    flows: [],
    correlations: [],
    iterations: 5000,
    mean: assumptions?.portfolioGrowthModel?.growthFactor ?? 0,
    standardDeviation: (assumptions?.portfolioGrowthModel?.growthFactor * 1.5) ?? 0,
    planDetails: {
      currentDate: dayjs.utc(startDate).startOf('y'),
      startValue: clientBalance,
      portfolioGrowthModel: assumptions?.portfolioGrowthModel ?? {},
      inflationModel: assumptions?.inflationModel ?? {}
    }
  }

  return await MonteCarlo.runSimulation(simulationDetails, flowData)
}
