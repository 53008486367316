import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { FormLabel, Button } from '@material-ui/core'
import isEmpty from 'lodash.isempty'
import { numeralByCase, tableNumberFormatter } from '../../../utils'
import { SKELETON_VARIANTS, SALESFORCE_ITEMS, TEXT_VARIANTS } from '../../../constants'
import NumberFormat from '../../atoms/NumberFormat'
import Text from '../../atoms/Text'
import Skeleton from '../../atoms/Skeleton'

const useStyles = makeStyles((theme) => ({
  sidePanelContent: {
    padding: '0 1.875rem',
    height: 'calc(100% - 1.25rem)',
    overflow: 'auto'
  },
  header: {
    ...theme.typography[TEXT_VARIANTS.subtitle2],
    color: 'inherit'
  },
  label: {
    ...theme.typography[TEXT_VARIANTS.body1],
    color: 'inherit'
  },
  container: {
    marginBottom: '0.625rem'
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center'
  },
  button: {
    borderRadius: '30px',
    fontWeight: 800,
    textTransform: 'capitalize',
    padding: '1.125rem 3.125rem',
    width: '9.375rem',
    backgroundColor: theme.palette.gray.light,
    color: theme.palette.lightBlack,
    '&:hover': {
      backgroundColor: theme.palette.gray.dark
    }
  }
}))

const SalesforceInfoLoading = () => {
  return (
    <>
      <Skeleton width='100%' height='72px' variant={SKELETON_VARIANTS.text} />
      <Skeleton width='100%' height='72px' variant={SKELETON_VARIANTS.text} />
      <Skeleton width='100%' height='72px' variant={SKELETON_VARIANTS.text} />
      <Skeleton width='100%' height='72px' variant={SKELETON_VARIANTS.text} />
      <Skeleton width='100%' height='72px' variant={SKELETON_VARIANTS.text} />
    </>
  )
}

const SalesforceInfoContent = ({ data, onClose }) => {
  const classes = useStyles()

  return (
    <>
      {
        Object.keys(SALESFORCE_ITEMS).map((key, index) => (
          <div className={classes.container} key={key + index}>
            <h1 className={classes.header}>{SALESFORCE_ITEMS[key].title}</h1>
            <FormLabel htmlFor='accountName' className={classes.label}>
              {
                SALESFORCE_ITEMS[key].isNumber
                  ? <NumberFormat number={tableNumberFormatter(data[key])} title={numeralByCase(data[key])} skipFormat />
                  : data[key] ?? '-'
              }
            </FormLabel>
          </div>
        ))
      }
      <div className={classes.actions}>
        <Button onClick={onClose} className={classes.button}>
          <Text text='Close' variant={TEXT_VARIANTS.h3} />
        </Button>
      </div>
    </>
  )
}

SalesforceInfoContent.propTypes = {
  data: PropTypes.node,
  onClose: PropTypes.func.isRequired
}

function SalesforceInfo ({ data, onClose }) {
  const classes = useStyles()

  const content = useMemo(() => {
    if (isEmpty(data)) {
      return <SalesforceInfoLoading />
    }
    if (data._error) {
      return <div>Error Fetching Content</div>
    }
    if (data._empty) {
      return <div>No Data Available</div>
    }
    return <SalesforceInfoContent data={data} onClose={onClose} />
  }, [data, onClose])

  return (
    <div className={classes.sidePanelContent}>
      {content}
    </div>
  )
}

SalesforceInfo.propTypes = {
  data: PropTypes.node,
  onClose: PropTypes.func
}

SalesforceInfo.defaultProps = {
  data: undefined,
  onClose: undefined
}

export default React.memo(SalesforceInfo)
