import { useMemo } from 'react'
import { isEmpty } from 'lodash'
import { useQueryPlatformDataSources } from '../../../../api/ingestion'

export const useFetchClientIntegrations = (integrationsQuery) => {
  const queries = useMemo(() => {
    if (isEmpty(integrationsQuery)) return []

    return integrationsQuery.map(
      ({ integrationType, ...integrationQuery }) => ({
        ...integrationQuery,
        key: integrationType
      })
    )
  }, [integrationsQuery])

  const results = useQueryPlatformDataSources(queries)

  return useMemo(() => {
    if (isEmpty(results)) return { isLoading: false }
    return results.reduce((acc, { data, isLoading, error }) => {
      if (!data?.key) {
        return {
          ...acc,
          error: {
            ...(acc?.error || {}),
            ...(data?.key ? { [data?.key]: error } : {})
          },
          isLoading: acc?.isLoading || isLoading
        }
      }
      return {
        ...acc,
        data: {
          ...acc?.data,
          [data?.key]: data
        },
        isLoading: false
      }
    }, {})
  }, [results])
}
