import React from 'react'
import PropTypes from 'prop-types'
import { Box, makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import { SKELETON_ANIMATIONS, SKELETON_VARIANTS } from '../../constants'
import Skeleton from './Skeleton'

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column'
  },
  rowHeader: {
    width: '100%',
    marginBottom: '1rem'
  },
  row: {
    margin: '0.75rem 0',
    display: 'flex',
    flexDirection: 'row'
  },
  cell: {
    display: 'flex',
    width: '100%',
    paddingRight: '1.5rem',
    alignItems: 'center'
  },
  lastCell: {
    paddingRight: '0 !important'
  }
}))

const TableSkeleton = ({
  firstColumnWithAvatar,
  showHeader,
  rows,
  columns,
  rowHeight
}) => {
  const classes = useStyles()
  return (
    <div className={classes.container}>
      {showHeader && (
        <div className={classes.rowHeader}>
          <Skeleton height={rowHeight} animation={SKELETON_ANIMATIONS.wave} />
        </div>
      )}
      {new Array(rows).fill(0).map((_, rowIndex) => (
        <div key={rowIndex} className={classes.row}>
          {new Array(columns).fill(0).map((_, columnIndex) => (
            <div
              key={`row-skeleton-${columnIndex}`}
              className={clsx(classes.cell, {
                [classes.lastCell]: columnIndex === columns - 1
              })}
            >
              {columnIndex === 0 && firstColumnWithAvatar ? (
                <Box mr={1}>
                  <Skeleton
                    height={rowHeight}
                    width={rowHeight}
                    variant={SKELETON_VARIANTS.circle}
                  />
                </Box>
              ) : null}
              <Skeleton
                height={rowHeight}
                animation={SKELETON_ANIMATIONS.wave}
              />
            </div>
          ))}
        </div>
      ))}
    </div>
  )
}

TableSkeleton.propTypes = {
  rows: PropTypes.number,
  rowHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  columns: PropTypes.number,
  showHeader: PropTypes.bool,
  firstColumnWithAvatar: PropTypes.bool
}

TableSkeleton.defaultProps = {
  rows: 5,
  columns: 5,
  showHeader: true,
  rowHeight: '20px',
  firstColumnWithAvatar: false
}

export default TableSkeleton
