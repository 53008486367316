import { useMemo } from 'react'
import get from 'lodash/get'
import { getInitials } from '../../../../utils'

export const useTeamMemberFields = (teamMember, fieldConfig, schema) => {
  return useMemo(() => {
    const name = `${teamMember.firstName} ${teamMember.lastName}`
    const role = teamMember.overrideRoleName || teamMember.primaryRoleName
    const abbreviation = getInitials(name)
    const attributes = fieldConfig.map((value) => {
      const _attribute = typeof value === 'string' ? schema?.attributes?.find(x => x.attributeId === value) : value
      return _attribute ? ({
        key: `${_attribute.accessor}_${teamMember.teamMemberId}`,
        value: get(teamMember, _attribute.accessor),
        type: _attribute.type,
        label: _attribute.label,
        config: _attribute.data
      }) : null
    }).filter(Boolean)

    return {
      teamMember: {
        ...teamMember,
        name,
        role,
        abbreviation,
        attributes
      },
      name,
      role,
      abbreviation,
      attributes
    }
  }, [teamMember, fieldConfig, schema])
}
