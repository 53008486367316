import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import React, { useCallback, useMemo } from 'react'
import AccountsContext from './AccountsContext'
import AccountsTable from './table'
import AccountsTableWrapper from './tableWrapper'
import { ADMIN_ACCOUNTS_PATH, defaultColumnConfig } from './helpers'
import AccountsHeader from './header'

const AccountsList = ({
  defaultSort = defaultColumnConfig.defaultSort
}) => {
  const history = useHistory()

  const onClickRow = useCallback(
    (row) => {
      if (row.original) {
        history.push(`${ADMIN_ACCOUNTS_PATH}/${row.original.accountId}`)
      }
    },
    [history]
  )

  const columnConfig = useMemo(() => ({
    ...defaultColumnConfig,
    defaultSort
  }), [defaultSort])

  return (
    <div>
      <AccountsContext defaultColumnConfig={columnConfig}>
        <AccountsTableWrapper>
          <AccountsTable header={<AccountsHeader />} onClickRow={onClickRow} />
        </AccountsTableWrapper>
      </AccountsContext>
    </div>
  )
}

AccountsList.propTypes = {
  defaultSort: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    desc: PropTypes.bool
  }))
}

export default AccountsList
