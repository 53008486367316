import React from 'react'
import { makeStyles } from '@material-ui/core'
import { useCurrentTab } from '../../../organisms/AdvisorView/components/AdvisorViewTabs'
import { ADMIN_ROUTES } from '../../../../constants'
import { useCheckPolicy } from '../../../../hooks'
import SydLinkButton from '../../../commonDesign/LinkButton'

const useStyles = makeStyles((theme) => ({
  actions: {
    marginLeft: 'auto',
    display: 'flex',
    gap: '0.5rem'
  }
}))

function HeaderActions () {
  const classes = useStyles()
  const tabValue = useCurrentTab()
  const canEditStatusReports = useCheckPolicy('admin_edit_status_templates')

  if (tabValue === 'templates' && canEditStatusReports) {
    return (
      <div className={classes.actions}>
        <SydLinkButton
          size='sm'
          variant='outline'
          icon='add'
          to={ADMIN_ROUTES.NEW_STATUS_REPORT_TEMPLATE}
        >
          Add Status Report Template
        </SydLinkButton>
      </div>
    )
  }

  return null
}

export default HeaderActions
