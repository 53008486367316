import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import { MAIN_HEADER_HEIGHT, TEXT_VARIANTS } from '../../../../constants'
import Text from '../../../atoms/Text'
import RightPanel from '../../../organisms/RightPanel'

const useStyles = makeStyles(() => ({
  sidePanelContent: {
    padding: '0 30px',
    height: `calc(100% - ${MAIN_HEADER_HEIGHT}px)`
  }
}))

const ManagementRightPanel = ({ children, open, toggle, title, width, height }) => {
  const classes = useStyles()
  return (
    <RightPanel
      open={open}
      toggleOpen={toggle}
      title={<Text text={title} variant={TEXT_VARIANTS.h2} />}
      width={width}
      height={height}
    >
      <div className={classes.sidePanelContent}>{children}</div>
    </RightPanel>
  )
}

ManagementRightPanel.propTypes = {
  children: PropTypes.node,
  open: PropTypes.bool,
  toggle: PropTypes.func,
  title: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

export default ManagementRightPanel
