import React from 'react'
import PropTypes from 'prop-types'
import OperationalTable, { useOperationalTable } from '../../../components/organisms/OperationalTable'

const columnConfig = [
  { Header: 'Acct #', accessor: 'accountNumber', type: 'string' },
  { Header: 'Acct', accessor: 'accountName', type: 'string' },
  { Header: 'Tax Status', accessor: 'taxStatusId', type: 'string' },
  { Header: 'Type', accessor: 'action', type: 'string' },
  { Header: 'Asset', accessor: 'assetName', type: 'string' },
  { Header: 'Sym', accessor: 'assetIdentifier', type: 'string' },
  { Header: 'Quantity', accessor: 'quantity', type: 'number' },
  { Header: 'Unit Type', accessor: 'unitType', type: 'string' },
  { Header: 'Price', accessor: 'price', type: 'money' },
  { Header: 'Custodian', accessor: 'custodianName', type: 'string' }
]

const defaultSort = [{ id: 'accountNumber', desc: false }]

const TradeResultsTable = ({ data, isLoading }) => {
  useOperationalTable({ defaultSort })

  return (
    <OperationalTable.Wrapper>
      <OperationalTable
        mode='client'
        columns={columnConfig}
        defaultSort={defaultSort}
        data={data}
        total={data?.length}
        hideFooter
        loading={isLoading}
      />
    </OperationalTable.Wrapper>
  )
}
TradeResultsTable.propTypes = {
  data: PropTypes.array,
  isLoading: PropTypes.bool
}

export default TradeResultsTable
