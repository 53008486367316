import React from 'react'
import PropTypes from 'prop-types'

const ClientDashboardItem = ({ children }) => {
  return (
    <div>{children}</div>
  )
}

ClientDashboardItem.propTypes = {
  children: PropTypes.func,
  // eslint-disable-next-line react/no-unused-prop-types
  type: PropTypes.oneOf(['link', 'sso'])
}

ClientDashboardItem.defaultProps = {
  type: 'link'
}

export default ClientDashboardItem
