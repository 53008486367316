import React, { useContext, useMemo } from 'react'
import PropTypes from 'prop-types'

const TargetAllocationScopeContext = React.createContext({
  currentLevelId: null,
  setCurrentLevelId: () => {},
  levelType: 'client'
})

export const useTargetAllocationScope = () => {
  return useContext(TargetAllocationScopeContext)
}

function TargetAllocationScopeProvider ({ levelType, children }) {
  const [currentLevelId, setCurrentLevelId] = React.useState(null)
  const value = useMemo(() => {
    return {
      currentLevelId,
      setCurrentLevelId,
      levelType
    }
  }, [currentLevelId, setCurrentLevelId, levelType])

  return (
    <TargetAllocationScopeContext.Provider value={value}>
      {children}
    </TargetAllocationScopeContext.Provider>
  )
}

TargetAllocationScopeProvider.propTypes = {
  levelType: PropTypes.oneOf(['client', 'account']),
  children: PropTypes.node
}

TargetAllocationScopeProvider.defaultProps = {
  levelType: 'client'
}

export default TargetAllocationScopeProvider
