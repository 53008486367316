import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { usePolicy } from '../../../hooks/usePolicy'
import {
  setConfig,
  setEditableAccounts,
  setLabels,
  setPolicies,
  useAddAccountContext
} from '../../../redux/slices/addAccountContext'
import { useAppContext } from '../../../redux/slices/appContext'
import { useGetEditableAccounts } from '../../../api/accounts'
import AddAccountModal from './AddAccountModal'

const AddAccount = ({
  availableRoutes,
  categorySelectOptions,
  labelOverrides
}) => {
  const { clientId } = useAppContext()
  const dispatch = useDispatch()
  const accountLinkingPolicy = usePolicy('accounts_create_linking')
  const accountManualPolicy = usePolicy('accounts_create_manual')
  const addAccountAppContext = useAddAccountContext()

  useEffect(() => {
    dispatch(setLabels({ labelOverrides }))
    dispatch(setConfig({ availableRoutes, categorySelectOptions }))
  }, [availableRoutes, categorySelectOptions, dispatch, labelOverrides])

  useEffect(() => {
    const policies = {
      byall: accountLinkingPolicy,
      manual: accountManualPolicy
    }
    dispatch(setPolicies({ policies }))
  }, [accountLinkingPolicy, accountManualPolicy, dispatch])

  const { data: editableAccounts, refetch: refetchEditableAccounts } = useGetEditableAccounts([clientId])
  useEffect(() => {
    dispatch(setEditableAccounts({ editableAccounts }))
  }, [dispatch, editableAccounts])

  useEffect(() => {
    if (!addAccountAppContext.isOpen) {
      refetchEditableAccounts()
    }
  }, [refetchEditableAccounts, addAccountAppContext.isOpen])

  if (!addAccountAppContext.isEnabled) {
    return null
  }

  return (
    <>
      <AddAccountModal />
    </>
  )
}

AddAccount.propTypes = {
  availableRoutes: PropTypes.arrayOf(PropTypes.string).isRequired,
  categorySelectOptions: PropTypes.shape({
    allCategoriesTitle: PropTypes.string,
    filteredCategoryIds: PropTypes.arrayOf(PropTypes.number),
    maxFeaturedCategories: PropTypes.number
  }),
  labelOverrides: PropTypes.object
}

export default AddAccount
