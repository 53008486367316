import React, { useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@material-ui/core'
import { useFormContext } from 'react-hook-form'
import clsx from 'clsx'
import { RemoveCircleOutline } from '@material-ui/icons'
import { noop } from 'lodash/util'
import IconButton from '@material-ui/core/IconButton'
import { makeStyles } from '@material-ui/core/styles'
import Select from '../../../atoms/Select'
import FormInputInlineEdit from '../../../molecules/FormInputInlineEdit'
import { feeLimitsDetailFormatter } from '../../../../utils'
import { FEE_VALUE_TYPES_BPS_VALUE } from '../../../../constants'

const useStyles = makeStyles((theme) => ({
  row: {
    marginBottom: '10px'
  },
  rowItem: {
    padding: '0 10px',
    border: '2px solid #E9EAEF',
    height: '50px',
    borderRadius: '6px',
    '&:focus-within': {
      borderColor: '#212945'
    },
    '&:has( .Mui-error)': {
      borderColor: '#d50000 !important'
    },
    '& .Mui-error input': {
      color: '#d50000 !important'
    },
    alignItems: 'center',
    '& .MuiFormHelperText-root.Mui-error': {
      position: 'absolute',
      top: '15px',
      fontSize: '11px'
    },
    '& input': {
      paddingBottom: 0
    },
    '& .MuiTextField-root': {
      margin: 0
    }
  },
  tierLabel: {
    fontSize: '14px',
    fontWeight: 'bolder',
    '& input': {
      fontSize: '14px',
      fontWeight: 'bolder'
    }
  },
  removeListItemButtonContainer: {
    textAlign: 'center'
  },
  removeListItemButton: {
    color: '#D44333',
    padding: '0'
  },
  limitsContainer: {
    alignItems: 'center'
  },
  feeRate: {
    textAlign: 'right',
    '& input': {
      fontWeight: '500',
      textAlign: 'right',
      paddingRight: '2px',
      paddingBottom: 0
    },
    '& input[disabled]': {
      fontSize: '14px'
    }
  },
  lowerLimit: {
    '& input': {
      color: '#898D9B',
      fontWeight: 'bold',
      paddingBottom: 0
    }
  },
  upperLimit: {
    '& input': {
      fontWeight: '500',
      paddingBottom: 0
    }
  },
  dropdown: {
    border: '1px solid #E9EAEF',
    borderRadius: '36px',
    minWidth: '14px',
    '& .MuiSelect-select': {
      padding: '5px',
      height: '14px',
      paddingLeft: '0.8rem',
      paddingRight: '22px',
      borderRadius: '36px',
      fontSize: '12px',
      fontWeight: '600'
    },
    '& .MuiSvgIcon-root': {
      right: '5px',
      top: '6px',
      fontSize: '14px',
      position: 'absolute',
      zIndex: -1
    }
  },
  feeValue: {
    textTransform: 'uppercase'
  }
}))

const OPTION_VALUES = {
  UP_TO: 'up-to',
  PLUS: 'plus'
}

const SELECT_OPTIONS = [
  {
    label: '+',
    value: OPTION_VALUES.PLUS
  },
  {
    label: 'up to',
    value: OPTION_VALUES.UP_TO
  }
]

const FeeScheduleDetail = ({
  name = '',
  lowerLimit,
  upperLimit,
  feeRate,
  isInEditMode = false,
  isSingleTier = false,
  isDisabled = false,
  denomination = '',
  withDropdown = false,
  onDelete = noop,
  onChange = noop,
  validate = noop
}) => {
  const { getValues, control, setValue, setFocus } = useFormContext()
  const classes = useStyles()
  const noUpperLimitValue = null
  const [selectedOption, setSelectedOption] = useState({
    ...SELECT_OPTIONS[upperLimit === noUpperLimitValue ? 0 : 1]
  })

  const isBPS = useMemo(
    () => denomination === FEE_VALUE_TYPES_BPS_VALUE,
    [denomination]
  )

  const shouldRenderUpperLimitSelector =
    (selectedOption.value === OPTION_VALUES.UP_TO && withDropdown) ||
    !withDropdown

  return (
    <Grid
      container
      className={clsx(classes.row, classes.rowItem)}
      justifyContent='space-between'
      alignItems='baseline'
    >
      <Grid item xs={isInEditMode ? 6 : 4}>
        {isInEditMode && !isSingleTier ? (
          <Grid
            container
            justifyContent='space-between'
            className={classes.limitsContainer}
          >
            <Grid item xs className={clsx(classes.lowerLimit)}>
              <FormInputInlineEdit
                control={control}
                initialValue={lowerLimit || '0'}
                name={`${name}.lowerLimit`}
                maskedValueFormatter='numFormatter'
                width='auto'
              />
            </Grid>
            <Grid item xs={3}>
              {withDropdown ? (
                <Select
                  className={clsx(classes.dropdown)}
                  options={SELECT_OPTIONS}
                  defaultValue=''
                  selectedValue={selectedOption.value}
                  disabled={isDisabled}
                  displayEmpty
                  displayCheckMark
                  placeholder='Select'
                  onChange={(event) => {
                    const value = event.target.value
                    setSelectedOption(
                      SELECT_OPTIONS.find((option) => option.value === value)
                    )
                    if (value === OPTION_VALUES.PLUS) {
                      setValue(`${name}.upperLimit`, noUpperLimitValue)
                      onChange(event)
                    } else {
                      setValue(`${name}.upperLimit`, '0')
                      onChange({ ...event, addNextItem: true })
                      setTimeout(
                        () =>
                          setFocus(`${name}.upperLimit`, {
                            shouldSelect: true
                          }),
                        0
                      )
                    }
                  }}
                />
              ) : (
                <div style={{ paddingLeft: '13px' }}>
                  {SELECT_OPTIONS[1].label}
                </div>
              )}
            </Grid>
            <Grid item xs={7} className={clsx(classes.upperLimit)}>
              {shouldRenderUpperLimitSelector && (
                <FormInputInlineEdit
                  control={control}
                  isInEditMode={isInEditMode}
                  initialValue={upperLimit || noUpperLimitValue}
                  name={`${name}.upperLimit`}
                  maskedValueFormatter='numFormatter'
                  placeholder='0'
                  type='number'
                  min={0}
                  onChange={onChange}
                  rules={{ validate }}
                  width='auto'
                />
              )}
            </Grid>
          </Grid>
        ) : isSingleTier ? (
          <Grid
            item
            xs={isInEditMode ? 5 : 'auto'}
            className={isInEditMode ? classes.upperLimit : classes.tierLabel}
          >
            <FormInputInlineEdit
              control={control}
              isInEditMode={isInEditMode}
              initialValue=''
              name={`${name}.feeRate`}
              placeholder='0'
              maskedValueFormatter={isBPS ? undefined : 'numberToUSD'}
              onChange={onChange}
              type='number'
              min={0}
              step='.01'
              rules={{ validate }}
              width='auto'
            />
          </Grid>
        ) : (
          <div className={classes.tierLabel}>
            {feeLimitsDetailFormatter(
              Number(getValues(`${name}.lowerLimit`)),
              Number(getValues(`${name}.upperLimit`))
            )}
          </div>
        )}
      </Grid>
      <Grid item xs={5} className={classes.feeRate}>
        {!isSingleTier && (
          <FormInputInlineEdit
            control={control}
            isInEditMode={isInEditMode && !isDisabled}
            initialValue={feeRate || '0'}
            type='number'
            name={`${name}.feeRate`}
            placeholder='0'
            rules={{ required: true }}
            maskedValueFormatter={isBPS ? undefined : 'numberToUSD'}
            min={0}
            step='.01'
            max={isBPS ? 1000000 : undefined}
            width='auto'
          />
        )}
        <span className={classes.feeValue}>{denomination}</span>
      </Grid>
      {isInEditMode && !isSingleTier && (
        <Grid item xs={1} className={classes.removeListItemButtonContainer}>
          {selectedOption.value === 'up-to' && (
            <IconButton
              color='inherit'
              disableFocusRipple
              disableRipple
              className={classes.removeListItemButton}
              onClick={onDelete}
            >
              <RemoveCircleOutline style={{ fontSize: '20px' }} />
            </IconButton>
          )}
        </Grid>
      )}
    </Grid>
  )
}

FeeScheduleDetail.propTypes = {
  name: PropTypes.string.isRequired,
  lowerLimit: PropTypes.string,
  upperLimit: PropTypes.string,
  feeRate: PropTypes.string,
  denomination: PropTypes.string,
  isSingleTier: PropTypes.bool,
  isInEditMode: PropTypes.bool,
  isDisabled: PropTypes.bool,
  withDropdown: PropTypes.bool,
  onDelete: PropTypes.func,
  onChange: PropTypes.func,
  validate: PropTypes.func
}

export default FeeScheduleDetail
