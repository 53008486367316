import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Box } from '@material-ui/core'
import Text from '../../../../../atoms/Text'
import {
  useAbundanceEngineParameters,
  useParameterReplacement
} from '../../../../../../hooks/useAbundanceEngineParameters'
import SalesforceMeetingsProvider from './SalesforceMeetingProvider'
import MeetingList from './MeetingList'

const UpcomingMeetings = ({ datasetName, take, title, startDate, endDate, linkBaseUrl }) => {
  const { advisorId } = useAbundanceEngineParameters()
  const [start, end] = useParameterReplacement([startDate, endDate])
  const query = useMemo(() => ({
    advisorId,
    startDate: start,
    endDate: end
  }), [start, end, advisorId])

  return (
    <Box height='100%' width='100%'>
      <Box mb='1.5rem'>
        <Text variant='body2' customFontSize='1.2rem'>
          {title}
        </Text>
      </Box>
      <SalesforceMeetingsProvider name={datasetName} query={query}>
        {(result) => (
          <MeetingList meetings={result} take={take} linkBaseUrl={linkBaseUrl} />
        )}
      </SalesforceMeetingsProvider>
    </Box>
  )
}

UpcomingMeetings.propTypes = {
  datasetName: PropTypes.string,
  take: PropTypes.number,
  title: PropTypes.string,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  linkBaseUrl: PropTypes.string
}

UpcomingMeetings.defaultProps = {
  datasetName: 'SalesforceMeetings',
  take: 5,
  title: 'Upcoming Meetings',
  startDate: '$today',
  endDate: '$in30days',
  linkBaseUrl: null
}

export default UpcomingMeetings
