import React, { useState, useCallback, useRef } from 'react'
import noop from 'lodash/noop'
import PropTypes from 'prop-types'
import {
  Box,
  ClickAwayListener,
  Fade,
  InputBase,
  List,
  ListItem,
  makeStyles,
  Paper
} from '@material-ui/core'
import 'emoji-mart/css/emoji-mart.css'
import { emojiIndex } from 'emoji-mart'
import { Mention, MentionsInput } from 'react-mentions'
import {
  EMOJI_SEARCH_CHARACTER,
  TRANSFORM_HORIZONTAL,
  TRANSFORM_VERTICAL
} from '../../constants'
import { popOverTransformOrigin } from '../../prop-types'
import { parseToCommentDisplayedText } from '../../utils'
import reactMentionStyles from './mentions.module.css'

const TRANSFORM_PRESETS = {
  [TRANSFORM_HORIZONTAL.Left]: '-100%',
  [TRANSFORM_HORIZONTAL.Right]: '0',
  [TRANSFORM_VERTICAL.Top]: '-100%',
  [TRANSFORM_VERTICAL.Bottom]: '5%'
}

const defaultMentionOptions = [{
  id: 'summit_wealth',
  display: '@summit'
}]

const useStyles = makeStyles((theme) => ({
  paper: ({ horizontal, vertical, anchorPosition }) => ({
    borderRadius: '0.5rem',
    maxHeight: '15rem',
    transform: `translate(${TRANSFORM_PRESETS[horizontal]}, ${TRANSFORM_PRESETS[vertical]}) !important`,
    transformOrigin: 'unset !important',
    position: 'fixed',
    overflowY: 'auto',
    backgroundColor: theme.palette.white,
    transition: 'all 1s',
    zIndex: '1301',
    inset: 'auto',
    ...(anchorPosition
      ? { marginTop: anchorPosition.top, marginLeft: anchorPosition.left }
      : {})
  }),
  commentMention: {
    color: theme.palette.azureRadiance
  },
  mentionsInput: {
    border: 'none !important',
    '& > textarea': {
      border: 'none !important'
    }
  }
}))

const emojisInitialState = {
  query: '',
  results: [],
  endIndex: 0,
  startIndex: 0,
  mentions: [],
  isEmojiPickerOpen: false,
  anchorPosition: null
}

const InputBaseComment = ({
  value,
  onChange,
  mentionOptions,
  emojisSearchCharacter,
  inputProps,
  popOverTransformOrigin
}) => {
  const textRef = useRef()
  const textInputContainerRef = useRef()
  const [emojis, setEmojis] = useState(emojisInitialState)

  const classes = useStyles({
    ...popOverTransformOrigin,
    anchorPosition: emojis.anchorPosition
  })

  const replaceAt = useCallback(
    (index, replacement, target, replacementLength) => {
      return `${target.substring(0, index)}${replacement}${target.substring(
        index + (replacementLength || replacement.length)
      )}`
    },
    []
  )

  const onChangeHandler = useCallback(
    (e, ...rest) => {
      const [,, mentions] = rest
      const inputValue = parseToCommentDisplayedText(e.target.value, mentions, false)
      const cursorPosition = textRef.current.selectionStart
      const emojiStarterPosition = inputValue.lastIndexOf(
        emojisSearchCharacter,
        cursorPosition
      )

      if (emojiStarterPosition >= 0) {
        const emojiQuery = inputValue
          .substring(emojiStarterPosition, cursorPosition)
          .replace(emojisSearchCharacter, '')

        if (emojiQuery.length > 1) {
          console.log(cursorPosition)
          const emojis = emojiIndex.search(emojiQuery)
          setEmojis((prevState) => ({
            ...prevState,
            mentions,
            results: emojis,
            query: parseToCommentDisplayedText(emojiQuery, mentions, true),
            endIndex: cursorPosition,
            startIndex: emojiStarterPosition,
            isEmojiPickerOpen: true
          }))
        } else {
          setEmojis({ ...emojisInitialState })
        }
      } else {
        setEmojis(prevState => ({ ...prevState, mentions }))
      }
      onChange({
        target: { value: e.target.value, selectionStart: cursorPosition }
      }, mentions)
    },
    [textRef, emojisSearchCharacter, onChange]
  )

  const createInputFieldCopy = useCallback(
    (inputField) => {
      if (!textInputContainerRef.current) return

      const inputCopy = document.createElement('div')
      inputCopy.textContent = inputField.value

      const inputStyles = getComputedStyle(inputField);
      [
        'fontFamily',
        'fontSize',
        'fontWeight',
        'wordWrap',
        'whiteSpace',
        'borderLeftWidth',
        'borderTopWidth',
        'borderRightWidth',
        'borderBottomWidth'
      ].forEach(function (key) {
        inputCopy.style[key] = inputStyles[key]
      })

      inputCopy.style.overflow = 'auto'
      inputCopy.style.width = inputField.offsetWidth + 'px'
      inputCopy.style.height = inputField.offsetHeight + 'px'
      inputCopy.style.position = 'absolute'
      inputCopy.style.left = inputField.offsetLeft + 'px'
      inputCopy.style.top = inputField.offsetTop + 'px'

      textInputContainerRef.current.appendChild(inputCopy)
      return inputCopy
    },
    [textInputContainerRef]
  )

  const getInputCaretPosition = useCallback(
    (inputField) => {
      if (!textInputContainerRef.current) return

      const end = inputField.selectionEnd
      const start = inputField.selectionStart
      const inputCopy = createInputFieldCopy(inputField)

      const range = document.createRange()
      range.setStart(inputCopy.firstChild, start)
      range.setEnd(inputCopy.firstChild, end)

      const selection = document.getSelection()
      selection.removeAllRanges()
      selection.addRange(range)

      const rect = range.getBoundingClientRect()
      textInputContainerRef.current.removeChild(inputCopy)
      inputField.selectionStart = start
      inputField.selectionEnd = end
      inputField.focus()

      const inputFieldRect = inputField.getBoundingClientRect()

      return {
        left: parseInt(rect.left - inputFieldRect.left, 10),
        top: parseInt(rect.top - inputFieldRect.top, 10)
      }
    },
    [textInputContainerRef, createInputFieldCopy]
  )

  const onKeyUpHandler = useCallback(
    (event) => {
      if (!emojis.isEmojiPickerOpen) return

      const position = getInputCaretPosition(event.target)
      if (!position) return

      setEmojis((prevState) => ({
        ...prevState,
        anchorPosition: {
          top: position.top,
          left: position.left
        }
      }))
    },
    [emojis.isEmojiPickerOpen, getInputCaretPosition]
  )

  const onInputClickHandler = useCallback(
    (e) => {
      if (textRef.current && textRef.current.contains(e.target)) {
        onChange({
          target: {
            value,
            selectionStart: e.target.selectionStart
          }
        }, emojis.mentions)
      }
    },
    [emojis.mentions, value, onChange, textRef]
  )

  return (
    <div style={{ position: 'relative' }}>
      {emojis.isEmojiPickerOpen && !!emojis.results.length && (
        <ClickAwayListener
          onClickAway={() => setEmojis({ ...emojisInitialState })}
        >
          <Fade in>
            <Paper className={classes.paper} elevation={1}>
              <List component='nav'>
                {emojis.results.map((emoji, index) => (
                  <ListItem
                    key={`emoji-list-${index}`}
                    button
                    onClick={() => {
                      const textWithEmoji = replaceAt(
                        emojis.startIndex,
                        emoji.native,
                        parseToCommentDisplayedText(value, emojis.mentions, false),
                        emojis.query.length + 1
                      )
                      const outputText = parseToCommentDisplayedText(textWithEmoji, emojis.mentions, true)
                      onChange({
                        target: {
                          value: outputText,
                          selectionStart: emojis.endIndex
                        }
                      }, emojis.mentions)
                      setEmojis({ ...emojisInitialState })
                    }}
                  >
                    <Box
                      overflow='hidden'
                      whiteSpace='nowrap'
                      textOverflow='ellipsis'
                    >
                      {emoji.native} {emoji.colons}
                    </Box>
                  </ListItem>
                ))}
              </List>
            </Paper>
          </Fade>
        </ClickAwayListener>
      )}
      <div ref={textInputContainerRef}>
        <MentionsInput
          value={value}
          inputRef={textRef}
          onKeyUp={onKeyUpHandler}
          onChange={onChangeHandler}
          onClick={onInputClickHandler}
          classNames={reactMentionStyles}
          placeholder={inputProps.placeholder}
        >
          <Mention
            data={mentionOptions}
            className={reactMentionStyles.mentions__mention}
          />
        </MentionsInput>
      </div>
    </div>
  )
}

InputBaseComment.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  mentionOptions: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string
  })),
  emojisSearchCharacter: PropTypes.string,
  inputProps: PropTypes.object,
  popOverTransformOrigin
}

InputBaseComment.defaultProps = {
  value: '',
  textInput: InputBase,
  inputProps: {},
  onChange: noop,
  mentionOptions: defaultMentionOptions,
  emojisSearchCharacter: EMOJI_SEARCH_CHARACTER,
  popOverTransformOrigin: {
    horizontal: TRANSFORM_HORIZONTAL.Right,
    vertical: TRANSFORM_VERTICAL.Top
  }
}

export default InputBaseComment
