import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/styles'
import theme from '../../../../../../../theme'
import Tooltip from '../../../../../../atoms/Tooltip'

const rowStatusIconsMap = {
  stale: {
    color: theme.palette.yellowOrange,
    label: 'Account is stale'
  },
  duplicate: {
    color: theme.palette.yellowOrange,
    label: 'Account is duplicated'
  },
  nonBillable: {
    color: theme.palette.indigoBlue,
    label: 'Account is non-billable'
  }
}

const useStyles = makeStyles(() => ({
  iconContainer: {
    position: 'absolute',
    top: '50%',
    marginTop: '-0.5em',
    left: 'auto',
    right: 'auto',
    display: 'flex',
    '&.__left': {
      right: '100%',
      marginRight: '.6em'
    },
    '&.__right': {
      left: '100%',
      marginLeft: '.6em'
    }
  },
  statusIcon: {
    opacity: 0.8,
    '&:nth-child(n+1)': {
      marginLeft: '-.3em'
    }
  },
  statusCircle: {
    width: '1em',
    height: '1em',
    borderRadius: '50%'
  }
}))

const StatusIcon = ({ statuses: _statuses, alignment }) => {
  const classes = useStyles()

  const statuses = useMemo(() => {
    if (!_statuses) return []
    if (alignment === 'right') {
      return _statuses.filter(x => !['nonBillable'].includes(x))
    }
    return _statuses
  }, [_statuses, alignment])

  return (
    <>
      <div className={clsx(
        classes.iconContainer, {
          __left: alignment === 'left',
          __right: alignment === 'right'
        })}
      >
        {statuses?.map((status, index) => {
          if (!rowStatusIconsMap[status]) return null
          const { color, label } = rowStatusIconsMap[status]
          return (
            <div key={`${index}_${status}`} className={classes.statusIcon}>
              <Tooltip key={`${index}_${status}`} title={label}>
                <div className={classes.statusCircle} style={{ backgroundColor: color }} />
              </Tooltip>
            </div>
          )
        })}
      </div>
    </>
  )
}
StatusIcon.propTypes = {
  statuses: PropTypes.array,
  alignment: PropTypes.string
}

const LevelNameCell = ({ value, row }) => {
  const { statuses } = row.original

  return (
    <div style={{ position: 'relative' }}>
      {!row.canExpand && (
        <StatusIcon statuses={statuses} alignment='left' />
      )}
      {value}
      {row.canExpand && (
        <StatusIcon statuses={statuses} alignment='right' />
      )}
    </div>
  )
}

LevelNameCell.propTypes = {
  value: PropTypes.any,
  row: PropTypes.object
}

export default LevelNameCell
