import React from 'react'
import PropTypes from 'prop-types'
import { Box, makeStyles } from '@material-ui/core'
import { useFieldArray } from 'react-hook-form'
import Icon from '../../../../atoms/Icon'
import RoundedButton from '../../../../atoms/RoundedButton'
import SydLabel from '../../../../commonDesign/SydLabel'
import SydReadOnly from '../../../../commonDesign/SydReadOnly'
import FeeOverride from './FeeOverride'

const DEFAULT_FEE_OVERRIDE = {
  feeLevelOverrideId: '',
  overrideLevelType: '',
  overrideLevelId: '',
  feeScheduleId: ''
}

const useStyles = makeStyles((theme) => ({
  feeOverridesAssignment: {
    marginTop: '16px'
  },
  empty: {
    maxWidth: '440px',
    minWidth: '440px',
    border: `2px solid ${theme.palette.gray.dark}`,
    height: '40px',
    display: 'flex',
    padding: '0 0 0 18px',
    marginBottom: '12px',
    alignItems: 'center',
    fontWeight: theme.typography.weights.semibold,
    fontSize: theme.typography.fontSizes.xl
  }
}))

function FeeOverridesAssignment ({
  name,
  editing,
  feeScheduleOptions,
  assigned
}) {
  const classes = useStyles()
  const { fields, append, remove } = useFieldArray({ name })

  return (
    <div className={classes.feeOverridesAssignment}>
      <SydLabel label='Overrides' />
      <div className={classes.body}>
        {fields.map((field, index) => (
          <FeeOverride
            key={field.id}
            name={`${name}.${index}`}
            editing={editing}
            override={field}
            feeScheduleOptions={feeScheduleOptions}
            onRemove={() => remove(index)}
            assigned={assigned}
          />
        ))}
        {fields.length === 0 ? (
          <Box maxWidth={650} mb='8px'>
            <SydReadOnly>No Overrides</SydReadOnly>
          </Box>
        ) : null}
      </div>
      {editing ? (
        <div>
          <RoundedButton
            variant='outlined'
            startIcon={<Icon name='add' />}
            onClick={() => append(DEFAULT_FEE_OVERRIDE)}
          >
            Add Override
          </RoundedButton>
        </div>
      ) : null}
    </div>
  )
}

FeeOverridesAssignment.propTypes = {
  name: PropTypes.string,
  editing: PropTypes.bool,
  feeScheduleOptions: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.number
  })),
  assigned: PropTypes.arrayOf(PropTypes.shape({
    levelType: PropTypes.string,
    feeScheduleOverrideId: PropTypes.number,
    levelId: PropTypes.number,
    levelName: PropTypes.string
  }))
}

FeeOverridesAssignment.defaultProps = {
  name: 'feeOverrides',
  editing: false
}

export default FeeOverridesAssignment
