import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { IconButton, makeStyles } from '@material-ui/core'
import Icon from '../../../atoms/Icon'
import { useComments } from '../../Comments/CommentContext'

const useStyles = makeStyles((theme) => ({
  thing: {
    display: 'flex',
    flexDirection: 'column',
    gap: '5px',
    border: `1px solid ${theme.palette.gray.dark}`,
    borderRadius: '4px'
  },
  thingHeader: {
    borderBottom: `1px solid ${theme.palette.gray.dark}`,
    padding: '10px 20px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  thingName: {
    fontSize: theme.typography.fontSizes.h5
  },
  thingContent: {
    padding: '10px 20px'
  },
  actions: {
    display: 'flex',
    flexDirection: 'row'
  }
}))

function Thing ({ thing, showHeader }) {
  const classes = useStyles()
  const { showCommentThread, showAttachments } = useComments()
  const openAttachments = useCallback(() => {
    showAttachments({
      clientId: thing.levelId,
      levelTypeId: 279,
      levelId: thing.thingId
    })
  }, [showAttachments, thing])

  const openComments = useCallback(() => {
    showCommentThread({
      clientId: thing.levelId,
      levelTypeId: 279,
      levelId: thing.thingId
    })
  }, [showCommentThread, thing])

  if (!thing) {
    return (
      <div>Nothing</div>
    )
  }

  return (
    <div className={classes.thing}>
      {showHeader ? (
        <div className={classes.thingHeader}>
          <div>
            <div className={classes.thingName}>{thing.name}</div>
          </div>
          <div className={classes.actions}>
            <IconButton onClick={openComments}>
              <Icon name='chat' customSize={16} />
            </IconButton>
            <IconButton onClick={openAttachments}>
              <Icon name='document' customSize={16} />
            </IconButton>
          </div>
        </div>
      ) : null}
      <div className={clsx('__thing-content', classes.thingContent)}>
        <div dangerouslySetInnerHTML={{ __html: thing.content }} />
      </div>
    </div>
  )
}

Thing.propTypes = {
  thing: PropTypes.shape({
    thingId: PropTypes.number,
    levelId: PropTypes.number,
    levelTypeId: PropTypes.number,
    name: PropTypes.string,
    content: PropTypes.string,
    data: PropTypes.object,
    category: PropTypes.string
  }),
  showHeader: PropTypes.bool
}

Thing.defaultProps = {
  showHeader: true
}

export default Thing
