import React from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@material-ui/core'

const AdvisorViewContainer = ({ children, xs }) => {
  return (
    <Grid container xs={xs}>
      {children}
    </Grid>
  )
}

AdvisorViewContainer.propTypes = {
  xs: PropTypes.number,
  children: PropTypes.node
}

AdvisorViewContainer.defaultProps = {
  xs: 12
}

export default AdvisorViewContainer
