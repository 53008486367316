import React, { useCallback, useEffect, useMemo } from 'react'
import { isArray, first, noop, isNumber, isEmpty, last } from 'lodash'
import { Box } from '@material-ui/core'
import PropTypes from 'prop-types'
import CollapsibleTable, { getCellValues } from '../CollapsibleTable'
import { useAppContext } from '../../../redux/slices/appContext'
import { useViewContext } from '../../../redux/slices/viewContext'
import { ASSET_FILTERS, CALC_TYPES, DAYJS_OPERATIONS, DAYJS_UNIT_TYPES, FEATURE_FLAG } from '../../../constants'
import { formatCellValue, getRowConfigs } from '../../../utils/tableHelper'
import fastDeepEqual from '../../../utils/fastDeepEqual'
import { useFeatureFlag } from '../../../redux/slices/appConfig'
import ResearchTable from '../ResearchTable'
import NumberFormat from '../../atoms/NumberFormat'
import usePerformanceTable from './hooks'

const PerformanceAllocationTable = ({
  accountCategoryFilters,
  allowDownload,
  balanceInformationDateFilters,
  disableAssetFilters,
  assetFilters,
  insertColumnsAt,
  showOnlyPercentages,
  showResearchTable,
  headerSection,
  rows: _rows,
  columns: _columns,
  finalRow: _finalRow,
  onRowClick: _onRowClick,
  onExpandRow: _onExpandRow,
  multiHeaderRows,
  disableFinalRow,
  nestedHeaders,
  classTypes,
  showDashesWhenNotHeldEntirePeriod,
  finalRowMapMethod
}) => {
  const appContext = useAppContext()
  const viewContext = useViewContext()

  const { availableDates } = appContext
  const { mainDate } = viewContext

  const asOfDate = useMemo(
    () => mainDate || availableDates.mainDate,
    [mainDate, availableDates.mainDate]
  )

  const { active: isResearchTableActive } = useFeatureFlag(
    FEATURE_FLAG.SHOW_RESEARCH_TABLE
  )

  const {
    rows,
    columns,
    finalRow,
    isLoading,
    onExpandRow,
    dateRangePickers,
    showDateRangePicker,
    onHeaderFilterChange
  } = usePerformanceTable({
    rows: _rows,
    columns: _columns,
    finalRow: _finalRow,
    classTypes,
    assetFilters,
    disableFinalRow,
    multiHeaderRows,
    insertColumnsAt,
    disableAssetFilters,
    showOnlyPercentages,
    accountCategoryFilters,
    balanceInformationDateFilters,
    showDashesWhenNotHeldEntirePeriod,
    finalRowMapMethod
  })
  const onExpandRowClick = useCallback(
    async ({ row, rowIndexes, isExpanded }) => {
      const rowExpandedConfig = await onExpandRow({
        row,
        rowIndexes,
        isExpanded
      })
      _onExpandRow(rowExpandedConfig)
    },
    [_onExpandRow, onExpandRow]
  )

  useEffect(() => {
    async function checkIfRowsAreExpandedByDefault () {
      const [row] = rows
      const rootRowConfig = last(row)
      if (
        !isEmpty(rows) &&
        !rootRowConfig?.isLoading &&
        rootRowConfig?.classType?.type === classTypes?.type &&
        isEmpty(rootRowConfig?.rows)
      ) {
        await Promise.all(
          rows.map(async (row, index) => {
            const { rowConfig } = classTypes || {}
            if (!rowConfig?.isExpanded) {
              return Promise.resolve({})
            }
            return await onExpandRow({
              row,
              rowIndexes: [index],
              isExpanded: rowConfig?.isExpanded
            })
          })
        )
      }
    }
    checkIfRowsAreExpandedByDefault()
  }, [rows, classTypes, onExpandRow])

  const onRowClick = useCallback(
    (e, row, { rowIndexes, ...rowPayload }) => {
      const rowAssetsConfig = getRowConfigs(rows, rowIndexes).reduce(
        (acc, { value, classType }) => ({ ...acc, [classType?.type]: value }),
        {}
      )
      _onRowClick(e, row, {
        rowAssetsConfig,
        ...rowPayload
      })
    },
    [rows, _onRowClick]
  )

  const cellFormatter = useCallback((cell, cellTooltipConfig, _, rawData) => {
    const {
      title: tooltipTitle,
      format: tooltipFormat,
      skipFormat
    } = cellTooltipConfig || {}

    const { value } = getCellValues(cell)
    const { heldEntirePeriod, isValueReturn } = rawData

    const showNotHeldEntirePeriodIndicator =
      isNumber(heldEntirePeriod) && heldEntirePeriod === 0

    const formattedValue = formatCellValue(value, cell.cellFormat)

    return (
      <Box fontWeight='normal'>
        <NumberFormat
          title={tooltipTitle}
          format={tooltipFormat}
          number={formattedValue}
          skipFormat={skipFormat}
        />
        {isValueReturn && showNotHeldEntirePeriodIndicator && (
          <NumberFormat
            title='Not held entire period'
            number='*'
            skipFormat
          />
        )}
      </Box>
    )
  }, [])

  return (
    <>
      {showDateRangePicker && dateRangePickers}
      <CollapsibleTable
        rows={rows}
        columns={columns}
        asOfDate={asOfDate}
        finalRow={finalRow}
        onRowClick={onRowClick}
        onExpandRow={onExpandRowClick}
        isBodyLoading={isLoading}
        headerSection={headerSection}
        nestedHeaders={nestedHeaders}
        allowDownload={allowDownload}
        onHeaderFilterChange={onHeaderFilterChange}
        cellFormatter={cellFormatter}
        multiHeaderRows={multiHeaderRows || isArray(first(columns))}
      />
      {showResearchTable && appContext.isSummitUser && isResearchTableActive && (
        <Box mt={4}>
          <ResearchTable
            assetFilters={assetFilters}
            allowDownload={allowDownload}
          />
          <ResearchTable
            assetFilters={assetFilters}
            allowDownload={allowDownload}
          />
        </Box>
      )}
    </>
  )
}

const assetFilterShape = PropTypes.shape({
  id: PropTypes.string,
  label: PropTypes.string,
  payload: PropTypes.shape({
    classType: PropTypes.oneOf(Object.values(ASSET_FILTERS)),
    assetTree: PropTypes.shape(
      Object.keys(ASSET_FILTERS).reduce(
        (acc, assetFilterKey) => ({
          ...acc,
          [assetFilterKey]: PropTypes.string.isRequired
        }),
        {}
      )
    )
  })
})

const balanceInformationDateFilterShape = PropTypes.shape({
  useMax: PropTypes.bool,
  useMin: PropTypes.bool,
  sourceKey: PropTypes.string,
  unitValue: PropTypes.number,
  unitType: PropTypes.oneOf(Object.values(DAYJS_UNIT_TYPES)),
  operation: PropTypes.oneOf(Object.values(DAYJS_OPERATIONS)),
  payload: PropTypes.shape({
    calcType: PropTypes.oneOf(Object.values(CALC_TYPES))
  })
})

PerformanceAllocationTable.propTypes = {
  nestedHeaders: PropTypes.arrayOf(PropTypes.any),
  allowDownload: PropTypes.bool,
  headerSection: PropTypes.node,
  rows: PropTypes.arrayOf(PropTypes.any),
  columns: PropTypes.arrayOf(PropTypes.any),
  finalRow: PropTypes.arrayOf(PropTypes.any),
  onRowClick: PropTypes.func,
  onExpandRow: PropTypes.func,
  assetFilters: PropTypes.shape(
    Object.keys(ASSET_FILTERS).reduce(
      (acc, assetFilterKey) => ({
        ...acc,
        [assetFilterKey]: assetFilterShape
      }),
      {}
    )
  ),
  disableFinalRow: PropTypes.bool,
  insertColumnsAt: PropTypes.shape({
    insertAtIndex: PropTypes.number,
    rows: PropTypes.array,
    columns: PropTypes.array,
    finalRow: PropTypes.array
  }),
  showResearchTable: PropTypes.bool,
  showOnlyPercentages: PropTypes.bool,
  disableAssetFilters: PropTypes.bool,
  balanceInformationDateFilters: PropTypes.arrayOf(
    balanceInformationDateFilterShape
  ),
  accountCategoryFilters: PropTypes.arrayOf(PropTypes.number),
  multiHeaderRows: PropTypes.bool,
  classTypes: PropTypes.shape({
    type: PropTypes.string.isRequired,
    childType: PropTypes.object
  }),
  showDashesWhenNotHeldEntirePeriod: PropTypes.bool,
  finalRowMapMethod: PropTypes.string
}

PerformanceAllocationTable.defaultProps = {
  nestedHeaders: ['Asset Classes', 'Subclass', 'Asset'],
  allowDownload: false,
  headerSection: undefined,
  rows: [],
  columns: [],
  finalRow: null,
  onRowClick: noop,
  onExpandRow: noop,
  assetFilters: {},
  disableFinalRow: false,
  insertColumnsAt: {
    rows: [],
    columns: [],
    finalRow: [],
    insertAtIndex: 0
  },
  showResearchTable: false,
  showOnlyPercentages: false,
  disableAssetFilters: false,
  balanceInformationDateFilters: [
    {
      sourceKey: 'balanceInformationToday',
      unitValue: 0,
      unitType: 'day',
      payload: {
        calcType: CALC_TYPES.balance
      }
    },
    {
      sourceKey: 'balanceInformation2Column',
      unitValue: 3,
      unitType: 'month',
      operation: 'subtract',
      payload: {
        calcType: CALC_TYPES.performance
      }
    },
    {
      sourceKey: 'balanceInformation3Column',
      unitValue: 12,
      unitType: 'month',
      operation: 'subtract',
      payload: {
        calcType: CALC_TYPES.performance
      }
    }
  ],
  accountCategoryFilters: [],
  multiHeaderRows: false,
  classTypes: { type: ASSET_FILTERS.ASSET_CLASSES },
  showDashesWhenNotHeldEntirePeriod: false,
  finalRowMapMethod: 'asset-classes'
}

export default React.memo(
  PerformanceAllocationTable,
  (prevProps, nextProps) => {
    return fastDeepEqual(prevProps, nextProps, { compareFunctions: false })
  }
)
