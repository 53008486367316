import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

function TeamMemberAttribute ({ className, value, type, config }) {
  if (type === 'mailto') {
    return (
      <a className={clsx('__mailto', className)} href={`mailto:${value}`}>{value}</a>
    )
  }

  if (type === 'tel') {
    return (
      <a className={clsx('__tel', className)} href={`tel:${value}`}>{value}</a>
    )
  }

  if (type === 'sms') {
    return (
      <a className={clsx('__sms', className)} href={`sms:${value}`}>{value}</a>
    )
  }

  if (type === 'url') {
    return (
      <a className={clsx('__url', className)} href={value} target='_blank' rel='noopener noreferrer'>{config?.linkText ? config.linkText : value}</a>
    )
  }

  return (
    <div className={clsx('__default', className)}>
      {value}
    </div>
  )
}

TeamMemberAttribute.propTypes = {
  className: PropTypes.string,
  value: PropTypes.any,
  type: PropTypes.oneOf(['mailto', 'tel', 'sms', 'url']),
  config: PropTypes.object
}

export default TeamMemberAttribute
