import React, { useCallback, useEffect, useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Grid, Container, Box, makeStyles } from '@material-ui/core'
import dayjs from 'dayjs'

import { first, last } from 'lodash'
import Text from '../../atoms/Text'
import RoundedButton from '../../atoms/RoundedButton'
import Select from '../../atoms/Select'
import AvailableDatePicker from '../../molecules/AvailableDatePicker'
import Skeleton from '../../atoms/Skeleton'

import { useSetViewContext, useViewContext } from '../../../redux/slices/viewContext'
import { useSetNoteContext } from '../../../redux/slices/noteContext'
import { useAppContext } from '../../../redux/slices/appContext'
import { fetchClient, getVisualBalanceInfo } from '../../../service'
import { useFetchState } from '../../../hooks'
import useAbortController from '../../../hooks/useAbortController'

import { EXPORT_CLASSNAME, LEVEL_TYPES, SKELETON_VARIANTS } from '../../../constants'

import theme from '../../../theme'
import PrintViewHeader from '../../molecules/PrintViewHeader'
import { ContextContainerProvider } from '../../../abundanceEngine/components/ContextContainer'
import ExportRoot from '../../molecules/ExportButton/ExportRoot'
import VisualBalanceSideBySide from './VisualBalanceSideBySide'
import NetWorth from './NetWorth'
import { labelsPropTypes } from './utils'

const useStyles = makeStyles(() => ({
  datePicker: {
    marginBottom: '1.25rem'
  },
  skeleton: {
    position: 'relative',
    bottom: '10.625rem'
  },
  balanceSheetContainer: {
    marginBottom: '2.5rem',
    marginTop: '1.25rem'
  },
  endContent: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  centerText: {
    textAlign: 'center'
  }
}))

const mapToggleOptions = (options) =>
  options.map((option) => ({
    value: option.replace(/\s+/g, '-').toLowerCase(),
    label: option
  }))

const datePickerStyles = {
  '& input': {
    textAlign: 'left'
  },
  fontSize: '0.875rem'
}

export const VBS_CONTEXT_KEY = 'vbs'

const VisualBalanceSheet = ({
  display,
  tagId,
  enableDrilldown,
  vbsExtraProps,
  includeCash,
  includeSideBlocks,
  showAddAsset,
  toggleOptions: _toggleOptions,
  formatToPrecisionValue,
  showExport,
  wealthMissionPrefix,
  labels
}) => {
  const classes = useStyles()
  const { clientId, availableDates } = useAppContext()
  const setNoteContext = useSetNoteContext()
  const setViewContext = useSetViewContext()
  const viewContext = useViewContext()

  const abortController = useAbortController()
  const [loading, setLoading] = useState(true)

  const { mainDate } = availableDates

  const toggleOptions = useMemo(
    () => mapToggleOptions(_toggleOptions),
    [_toggleOptions]
  )

  const { client, vbsInfo } = useFetchState(
    useCallback(
      async (setSafeState) => {
        const signal = abortController()

        try {
          setLoading(true)

          const [{ data: client }, { data: vbsInfo }] = await Promise.all([
            fetchClient(clientId),
            getVisualBalanceInfo(
              {
                startDate: viewContext.mainDate,
                endDate: viewContext.mainDate,
                clientIds: [clientId],
                levelTypes: [
                  LEVEL_TYPES.CLIENT,
                  LEVEL_TYPES.ACCOUNT_CATEGORY,
                  LEVEL_TYPES.ACCOUNTS
                ],
                tagId: tagId,
                calcType: 'balance',
                includeCash
              },
              { signal }
            )
          ])
          setSafeState({ client, vbsInfo })
        } catch (err) {
          console.error(err)
        } finally {
          setLoading(false)
        }
      },
      [tagId, clientId, includeCash, abortController, viewContext.mainDate]
    )
  )

  const onClickAsset = (e) => {
    const position = e.target.getBoundingClientRect()

    setNoteContext({
      openNewCommentModal: true,
      openModal: false,
      viewId: null,
      modalPosition: { top: position.top, left: position.left },
      triggeredBy: 'ADD_ASSET'
    })
  }

  const onChangeView = (e) => {
    const { value } = e.target

    setViewContext({
      display: value
    })
  }

  const onChangeDate = (date) => {
    setViewContext({
      mainDate: dayjs(date).format('YYYY-MM-DD')
    })
  }

  const onClickVbs = (item) => {
    setViewContext({ selectedBlock: item })
  }

  const onPressAccount = (item) => {
    setViewContext({ selectedAccount: item })
  }

  useEffect(() => {
    const isDefaultValid = toggleOptions.some(({ value }) => value === display)
    setViewContext({
      selectedAccount: {},
      display: isDefaultValid ? display : first(toggleOptions).value,
      selectedBlock: null,
      mainDate
    })
  }, [display, mainDate, toggleOptions, setViewContext])

  const renderPortfolioTitle = useMemo(() => {
    if (
      viewContext.display === first(toggleOptions).value &&
      !viewContext.selectedBlock
    ) {
      <Grid
        container
        direction='row'
        justifyContent='space-between'
        alignItems='center'
      >
        <Grid item>
          <Text
            text='Balance Sheet'
            customFontSize='20px'
            color={theme.palette.cloudBurst}
          />
        </Grid>
      </Grid>
    }
    return null
  }, [toggleOptions, viewContext.display, viewContext.selectedBlock])

  const contextState = useMemo(() => ({
    name: VBS_CONTEXT_KEY,
    initialState: { includeCash, cashTagIds: tagId }
  }), [includeCash, tagId])

  return (
    <ExportRoot enabled={showExport}>
      <ContextContainerProvider context={contextState}>
        <Container className={EXPORT_CLASSNAME} maxWidth='xl'>
          <Box sx={{ flexGrow: 1 }} pt={4}>
            <Grid
              container
              direction='row'
              justifyContent='flex-start'
              alignItems='center'
            >
              <PrintViewHeader />
              {loading ? (
                <>
                  <Skeleton
                    width='100%'
                    height='92px'
                    variant={SKELETON_VARIANTS.text}
                  />
                  <Skeleton
                    width='100%'
                    height='800px'
                    variant={SKELETON_VARIANTS.text}
                    className={classes.skeleton}
                  />
                </>
              ) : (
                <>
                  <Grid
                    container
                    direction='row'
                    justifyContent='flex-start'
                    alignItems='center'
                  >
                    <Grid item xs={4}>
                      <NetWorth vbsInfo={vbsInfo} />
                    </Grid>
                    <Grid item xs={4}>
                      <Text
                        text={`${wealthMissionPrefix} ${
                        client?.description || 'Not available'
                        }`}
                        color={theme.palette.cloudBurst}
                        customFontSize='18px'
                        className={classes.centerText}
                      />
                    </Grid>
                    <Grid item xs={2} className={classes.endContent}>
                      {showAddAsset && (
                        <RoundedButton
                          onClick={onClickAsset}
                          children='Add an Asset'
                          variant='contained'
                          size='small'
                          primary
                        />
                      )}
                    </Grid>
                    <Grid item xs={2} className={classes.endContent}>
                      <Select
                        options={toggleOptions}
                        selectedValue={viewContext.display}
                        onChange={onChangeView}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    direction='row'
                    justifyContent='flex-start'
                    alignItems='center'
                    className={classes.datePicker}
                  >
                    <AvailableDatePicker
                      disableFuture
                      value={viewContext.mainDate}
                      onChange={onChangeDate}
                      type='date'
                      format='[As of] MMMM DD, YYYY'
                      extraStyles={datePickerStyles}
                    />
                  </Grid>
                  {renderPortfolioTitle}
                  <Grid
                    container
                    direction='row'
                    justifyContent='center'
                    alignItems='center'
                    className={classes.balanceSheetContainer}
                  >
                    <VisualBalanceSideBySide
                      includeCash={includeCash}
                      includeSideBlocks={includeSideBlocks}
                      enableDrilldown={enableDrilldown}
                      data={vbsInfo}
                      labels={labels}
                      showDetails={
                        viewContext.display === last(toggleOptions).value
                      }
                      margin={1.25}
                      profilePic={
                        client?.extras?.profileData?.profilePic ||
                      client?.profilePic ||
                      ''
                      }
                      clientName={client?.familyName || ''}
                      onClick={onClickVbs}
                      onPressAccount={onPressAccount}
                      totalBackgroundColor={
                        vbsExtraProps?.totalBackgroundColor || ''
                      }
                      totalLiabilitiesBackgroundColor={
                        vbsExtraProps?.totalLiabilitiesBackgroundColor || ''
                      }
                      formatToPrecisionValue={formatToPrecisionValue}
                    />
                  </Grid>
                </>
              )}
            </Grid>
          </Box>
        </Container>
      </ContextContainerProvider>
    </ExportRoot>
  )
}

VisualBalanceSheet.propTypes = {
  display: PropTypes.string,
  labels: labelsPropTypes,
  wealthMissionPrefix: PropTypes.string,
  tagId: PropTypes.any,
  enableDrilldown: PropTypes.bool,
  vbsExtraProps: PropTypes.object,
  includeCash: PropTypes.bool,
  showAddAsset: PropTypes.bool,
  formatToPrecisionValue: PropTypes.bool,
  includeSideBlocks: PropTypes.bool,
  toggleOptions: PropTypes.arrayOf(PropTypes.string),
  showExport: PropTypes.bool
}

VisualBalanceSheet.defaultProps = {
  display: 'visual-balance-view',
  labels: {},
  tagId: [2],
  enableDrilldown: false,
  includeCash: false,
  showAddAsset: false,
  vbsExtraProps: {},
  formatToPrecisionValue: false,
  includeSideBlocks: true,
  toggleOptions: ['Visual Balance View', 'Split View'],
  showExport: false,
  wealthMissionPrefix: 'Wealth mission:'
}

export default VisualBalanceSheet
