import React, { useCallback, useMemo, useRef } from 'react'
import { makeStyles } from '@material-ui/core'
import SectionHeader from '../../shared/SectionHeader'
import SectionScreen from '../SectionScreen'
import {
  useListClientTargetAssignments,
  useModifyClientTargetAssignmentsMutation,
  useTargetModels
} from '../../../../../api/rebalancer'
import { useClientDetails, useSectionEditing } from '../ClientDetailsFormContext'
import EditButton from '../EditButton'
import TargetAssignmentTable from './TargetAssignmentTable'

const useStyles = makeStyles((theme) => ({
  tagsSection: {
    position: 'relative',
    padding: '10px'
  },
  assignment: {
    fontSize: theme.typography.fontSizes.xl
  }
}))

const defaultQuery = {}

function TargetAssignmentSection () {
  const classes = useStyles()
  const { client, editSection } = useClientDetails()
  const editing = useSectionEditing('target-assignments')
  const { data: assignments, isFetching } = useListClientTargetAssignments(client.clientId)
  const { data: targetModels, isLoading: modelsLoading } = useTargetModels(defaultQuery)
  const { mutateAsync: modifyTargetAssignment } = useModifyClientTargetAssignmentsMutation()

  const formRef = useRef()
  const targetOptions = useMemo(() => {
    if (!targetModels?.length) return []

    return targetModels.map(tm => ({
      label: tm.name,
      value: tm.targetModelId,
      description: tm.description
    }))
  }, [targetModels])

  const onSave = useCallback(async () => {
    const formValues = formRef.current.formValues

    try {
      await modifyTargetAssignment({
        clientId: client.clientId,
        assignments: formValues.assignments
      })
    } catch (errors) {
      console.error('problem saving the assignments', errors)
      // eslint-disable-next-line no-throw-literal
      throw { validationErrors: errors }
    }
  }, [formRef, modifyTargetAssignment, client])

  if (isFetching || modelsLoading) {
    return (
      <div>Loading...</div>
    )
  }

  return (
    <SectionScreen sectionName='target-assignments' className={classes.tagsSection}>
      <SectionHeader text='Target Assignments'>
        <div>
          <EditButton
            policy='admin_edit_clientinfo'
            editing={editing}
            onClick={() => editSection({
              section: 'target-assignments',
              saveDescription: 'Save Target Assignments',
              onSave
            })}
          />
        </div>
      </SectionHeader>
      <TargetAssignmentTable
        ref={formRef}
        editing={editing}
        assignments={assignments}
        options={targetOptions}
      />
    </SectionScreen>
  )
}

export default TargetAssignmentSection
