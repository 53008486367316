import React, { useCallback, useRef, useState } from 'react'
import { makeStyles } from '@material-ui/core'
import { useUpdateAdvisorUserMutation } from '../../../../../api/users'
import SectionHeader from '../../shared/SectionHeader'
import { useAdvisorUserDetails, useSectionEditing } from '../AdvisorUserFormContext'
import SectionScreen from '../../shared/SectionScreen'
import EditButton from '../../shared/EditButton'
import SaveCancelFooter from '../../shared/SaveCancelFooter'
import AdvisorUserInfoForm from './AdvisorUserInfoForm'
import AdvisorUserInfoDisplay from './AdvisorUserInfoDisplay'

const useStyles = makeStyles((theme) => ({
  userInfoSection: {
    position: 'relative',
    padding: '10px'
  }
}))

function AdvisorUserInfoSection () {
  const classes = useStyles()
  const { user, editSection, refetchUser, editing, cancelEdit } = useAdvisorUserDetails()
  const sectionEditing = useSectionEditing('user_info')
  const formRef = useRef()
  const { mutateAsync: saveUser } = useUpdateAdvisorUserMutation()
  const [processing, setProcessing] = useState(false)
  const onSave = useCallback(async (e) => {
    const onValid = async (form) => {
      try {
        setProcessing(true)
        await saveUser({
          user: {
            userId: user.userId,
            externalUserId: user.externalUserId,
            firstName: form.firstName || undefined,
            lastName: form.lastName || undefined
          }
        })
        await refetchUser()
      } catch (err) {
        console.error('error trying to save a wealth owner', err)
        alert('There was an unexpected problem saving the request.')
        throw err
      } finally {
        setProcessing(false)
      }
    }
    const onInvalid = (errors) => {
      console.error(errors)
    }

    const handler = formRef.current.handleSubmit(onValid, onInvalid)
    await handler(e)
  }, [formRef, user, saveUser, refetchUser, setProcessing])

  return (
    <SectionScreen
      editing={editing}
      sectionIsEditing={sectionEditing}
      className={classes.userInfoSection}
    >
      <SectionHeader text='User Information'>
        <div>
          <EditButton
            policy='admin_edit_advisor_users'
            editing={sectionEditing}
            onClick={() => editSection({
              section: 'user_info',
              saveDescription: 'Save User Information',
              onSave
            })}
          />
        </div>
      </SectionHeader>
      {sectionEditing ? (
        <AdvisorUserInfoForm ref={formRef} user={user} />
      ) : (
        <AdvisorUserInfoDisplay user={user} />
      )}
      {sectionEditing ? (
        <SaveCancelFooter
          saveText='Save User Information'
          onCancel={cancelEdit}
          onSave={onSave}
          processing={processing}
        />
      ) : null}

    </SectionScreen>
  )
}

export default AdvisorUserInfoSection
