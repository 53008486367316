import React, { useEffect, useRef, useState } from 'react'
import mstarAggregationConsumerAccountSetup from 'mstar-aggregation-consumer-accountsetup'
import { useDispatch } from 'react-redux'
import Alert from '../../../atoms/Alert'
import { ALERT_SEVERITY, ALERT_VARIANT } from '../../../../constants'
import { useByAllSessionTokens } from '../../../../api/users'
import { postNamedCommand } from '../../../../service'
import { useAppContext } from '../../../../redux/slices/appContext'
import {
  setDataHasChanged,
  setStepContext,
  setStepNavigation,
  useAddAccountContext
} from '../../../../redux/slices/addAccountContext'
import useDebouncedCallback from '../../../../hooks/useDebouncedCallback'
import LoadingPlaceholder from '../LoadingPlaceholder'

const errorStates = {
  badAuthentication: {
    level: 'blocking',
    message: 'There was an issue logging in. Please try again.'
  },
  internalError: {
    level: 'blocking',
    message: 'There was an issue launching this widget.  Please try again'
  }
}

const ByAllAccountLinkingComponent = () => {
  const dispatch = useDispatch()
  const appContext = useAppContext()
  const addAccountContext = useAddAccountContext()
  // eslint-disable-next-line no-unused-vars
  const byAllScript = mstarAggregationConsumerAccountSetup

  const mStarEmbedObject = useRef(null)
  const [errorState, setErrorState] = useState(null)

  const handleEmbedErrors = (event) => {
    const { type } = event

    setErrorState({
      type,
      ...(errorStates[type] ?? {})
    })
  }

  const debouncedTriggerAccountLinking = useDebouncedCallback(async () => {
    dispatch(setDataHasChanged({ dataHasChanged: true }))
    await postNamedCommand('accounts', 'createAccountLinking', {
      levelTypeId: 201,
      levelId: appContext.clientId,
      providerKey: 'BY_ALL_ACCOUNTS',
      inputParams: {}
    }).catch(() => {})
  })

  const handleEmbedEvents = (event) => {
    const { type } = event

    if (type === 'userExit') {
      if (addAccountContext.stepContext?.route) {
        dispatch(setStepContext({ stepContext: null }))
        dispatch(setStepNavigation({ key: 'byall_summaryComponent' }))
        return
      }
    }

    if (type === 'dataChanged') {
      debouncedTriggerAccountLinking()
    }
  }

  useEffect(() => {
    if (!mStarEmbedObject.current) {
      return
    }
    const elem = mStarEmbedObject.current
    elem.addEventListener('badAuthentication', handleEmbedErrors)
    elem.addEventListener('internalError', handleEmbedErrors)
    elem.addEventListener('userExit', handleEmbedEvents)
    elem.addEventListener('dataChanged', handleEmbedEvents)

    return () => {
      elem.removeEventListener('badAuthentication', handleEmbedErrors)
      elem.removeEventListener('internalError', handleEmbedErrors)
      elem.removeEventListener('userExit', handleEmbedEvents)
      elem.removeEventListener('dataChanged', handleEmbedEvents)
    }
  })

  const { data, isFetching: isFetchingTokens } = useByAllSessionTokens()

  return (
    <>
      {isFetchingTokens ? (
        <LoadingPlaceholder />
      ) : errorState ? (
        <Alert variant={ALERT_VARIANT.standard} severity={ALERT_SEVERITY.error}>
          <p style={{ margin: 0 }}>
            Issue adding assets<br />
            <span style={{ fontWeight: 300, fontSize: '.9rem' }}>{errorState?.message ?? 'Something went wrong'}</span>
          </p>
        </Alert>
      ) : (
        <div style={{ margin: '0 -28px -28px' }}>
          <mstar-aggregation-consumer-accountsetup
            ref={mStarEmbedObject}
            auth-context={JSON.stringify({ csrfToken: data?.csrfToken, jsessionId: data?.sessionId })}
            route={addAccountContext.stepContext?.route}
            iframe-style='min-height:60vh;height:100%;max-height:100%;max-width:100%'
            override-css-file={`https://${window.location.host}/byall-component-styles.css`}
            custom-fonts={`https://${window.location.host}/byall-component-fonts.css`}
            translate-filepath={`https://${window.location.host}/byall-component-translations.json`}
          />
        </div>
      )}
    </>
  )
}

export default ByAllAccountLinkingComponent
