import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: theme.typography.fontSizes.h2
  }
}))

function FirmReportName ({ configuration }) {
  const classes = useStyles()

  return (
    <div className={classes.title}>{configuration.report?.name}</div>
  )
}

FirmReportName.propTypes = {
  configuration: PropTypes.shape({
    report: PropTypes.object
  }).isRequired
}

export default FirmReportName
