import React, { useCallback, useMemo } from 'react'
import { Box, makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import { isEmpty, isFunction } from 'lodash'
import SearchBarInput from '../../../../molecules/SearchBar/SearchBarInput'
import GroupingPicker from '../../../../organisms/GroupingProvider/GroupingPicker'
import SelectWithCheckbox from '../../../../molecules/Select/SelectWithCheckbox'
import GroupTagFilters from '../../../../molecules/GroupTagFilters'
import GroupTagFiltersSkeleton from '../../../../molecules/GroupTagFilters/GroupTagFiltersSkeleton'
import { assignmentStatusOptions } from '../../../../../constants'
import { UNASSIGNED_OPTION } from '../../Accounts/AccountsListTab/useGroupTypeColumns'
import { useClientsListContext } from './ClientsListContext'
import { COLUMN_IDS, getDynamicColumnIds } from './helpers'

const useStyles = makeStyles(() => ({
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  content: {
    display: 'flex'
  },
  filters: {
    display: 'flex',
    alignItems: 'center',
    padding: '0.75rem 0'
  },
  searchInput: {
    marginBottom: '0.5rem'
  },
  switchContainer: {
    display: 'flex',
    marginLeft: 'auto',
    gap: '1rem',
    padding: '0 1rem 0 0',
    alignItems: 'center'
  }
}))

const ClientsListHeader = ({ tableOptions }) => {
  const classes = useStyles()

  const {
    isLoading,
    searchText,
    groupTypes,
    clientFilters,
    visibleFilters,
    onChangeFilters,
    isLoadingGroupTypes,
    operationalTable: { onSearchTextChange }
  } = useClientsListContext()

  const visibleGroupTypeFilters = useMemo(() => {
    const visibleFilterIds = getDynamicColumnIds(visibleFilters)

    const groupTypeFilter = (groupTypes || []).map((groupType) => {
      const unAssignedOption = {
        ...UNASSIGNED_OPTION,
        payload: { groupTypeId: groupType.value, groupName: groupType.label }
      }
      return {
        ...groupType,
        groups: [...groupType.groups, unAssignedOption],
        hidden: !visibleFilterIds.includes(groupType.value.toString())
      }
    })
    return groupTypeFilter
  }, [groupTypes, visibleFilters])

  const onChangeFilterFlag = useCallback(
    (filterKey) => (values) => {
      onChangeFilters((prevState) => ({
        ...prevState,
        [filterKey]: isFunction(values)
          ? values(prevState.clientTagFilters)
          : values
      }))
    },
    [onChangeFilters]
  )

  const renderFixedFilters = useMemo(() => {
    if (
      !visibleFilters.includes(COLUMN_IDS.BENCHMARKS) &&
      !visibleFilters.includes(COLUMN_IDS.FEE_SCHEDULES)
    ) {
      return null
    }
    return (
      <Box display='flex'>
        <div className={classes.switchContainer}>
          {visibleFilters.includes(COLUMN_IDS.BENCHMARKS) && (
            <SelectWithCheckbox
              placeholder='Benchmarks'
              prefixLabel='Benchmarks'
              options={assignmentStatusOptions}
              onChange={onChangeFilterFlag('benchmarksFilters')}
              selectedOptions={clientFilters.benchmarksFilters}
            />
          )}
          {visibleFilters.includes(COLUMN_IDS.FEE_SCHEDULES) && (
            <SelectWithCheckbox
              placeholder='Fee Schedules'
              prefixLabel='Fee Schedules'
              options={assignmentStatusOptions}
              onChange={onChangeFilterFlag('feeSchedulesFilters')}
              selectedOptions={clientFilters.feeSchedulesFilters}
            />
          )}
        </div>
      </Box>
    )
  }, [
    visibleFilters,
    onChangeFilterFlag,
    classes.switchContainer,
    clientFilters.benchmarksFilters,
    clientFilters.feeSchedulesFilters
  ])

  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <Box width='20rem'>
          <SearchBarInput
            value={searchText}
            loading={isLoading}
            setValue={onSearchTextChange}
            searchInputClassName={classes.searchInput}
            placeholder='Search by client name'
          />
        </Box>
        <Box ml='auto'>
          <GroupingPicker options={tableOptions} />
        </Box>
      </div>
      {!isEmpty(visibleFilters) && (
        <div className={classes.filters}>
          {isLoadingGroupTypes ? (
            <GroupTagFiltersSkeleton />
          ) : (
            <>
              {renderFixedFilters}
              <GroupTagFilters
                groupTypes={visibleGroupTypeFilters}
                isLoading={isLoadingGroupTypes}
                tagFilters={clientFilters.clientTagFilters}
                setTagFilters={onChangeFilterFlag('clientTagFilters')}
              />
            </>
          )}
        </div>
      )}
    </div>
  )
}

ClientsListHeader.propTypes = {
  tableOptions: PropTypes.object
}

ClientsListHeader.defaultProps = {
  tableOptions: {
    hideGroupingsUnderline: true,
    hideCustomizeColumnsDateRangeSelectors: true,
    hideCustomizeColumnsColumnHiding: false,
    hideCustomizeColumnsButton: false,
    hideExpandCollapseButton: true,
    hideExportButton: false,
    hideExportTypePicker: true
  }
}

export default ClientsListHeader
