import PropTypes from 'prop-types'
import { useAppContext } from '../../redux/slices/appContext'

const WebOnlyContent = ({ children, force }) => {
  const appContext = useAppContext()

  if (!appContext.isPrintView || force) {
    return children
  }

  return null
}

WebOnlyContent.propTypes = {
  children: PropTypes.node,
  force: PropTypes.bool
}

WebOnlyContent.defaultProps = {
  force: false
}

export default WebOnlyContent
