import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { IconButton, makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import Text from '../../../../atoms/Text'
import Icon from '../../../../atoms/Icon'
import { CONFIRMATION_TYPE, ICON_NAMES } from '../../../../../constants'
import { useBoolean } from '../../../../../hooks'
import ClientValuesForm from '../AddClientValueCard/ClientValuesForm'
import ConfirmationModal from '../../../../molecules/ConfirmationModal'
import { deleteWealthMissionValue } from '../../../../../service'
import ClientValuesCardSkeleton from './ClientValuesCardSkeleton'

export const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    borderRadius: '0.875rem',
    border: '1.5px solid #F1F2F3',
    background: '#FFF',
    padding: '1.5rem',
    display: 'flex',
    flexDirection: 'column',
    gap: '0.875rem',
    transition: 'border .3s ease',
    position: 'relative'
  },
  containerHover: ({ isLoading }) => ({
    borderColor: isLoading ? 'transparent' : theme.palette.darkBlue
  }),
  footer: {
    gap: '0.5rem',
    display: 'flex',
    flexDirection: 'row'
  },
  actions: {
    position: 'absolute',
    right: '0.5rem',
    top: '0.5rem'
  }
}))

const MODAL_BACKDROP_STYLES = {
  borderRadius: '0.15rem'
}

const ClientValueCard = ({
  icon,
  title,
  footer,
  clientId,
  isLoading,
  description,
  onEditWealthValue,
  onDeleteWealthValue,
  wealthMissionValueId,
  wealthMissionValueDefaultId
}) => {
  const classes = useStyles({ isLoading })
  const [hover, toggleHover] = useBoolean()
  const [editMode, toggleEditMode] = useBoolean()
  const [modalOpen, toggleModal] = useBoolean()
  const [isDeleting, toggleIsDeleting] = useBoolean()

  const containerClassName = clsx(classes.container, {
    [classes.containerHover]: hover
  })

  const onEditWealthMissionValue = useCallback(() => {
    toggleEditMode.off()
    onEditWealthValue()
  }, [toggleEditMode, onEditWealthValue])

  const cardBody = useMemo(() => {
    if (editMode) {
      return (
        <ClientValuesForm
          onSave={onEditWealthMissionValue}
          onCancel={toggleEditMode.off}
          defaultValues={{
            levelId: clientId,
            levelType: 'clients',
            icon,
            title,
            description,
            wealthMissionValueId,
            wealthMissionValueDefaultId
          }}
        />
      )
    }
    return (
      <>
        <Icon
          name={icon ?? ICON_NAMES.tag}
          customSize='2.5rem'
          padding='1rem'
          roundedBackground='#F5F6F7'
        />
        <Text customFontSize='1.25rem' customFontWeight='bold'>
          {title}
        </Text>
        <Text customFontSize='0.875rem'>{description}</Text>
        {footer && <div className={classes.footer}>{footer}</div>}
      </>
    )
  }, [
    classes.footer,
    toggleEditMode.off,
    icon,
    title,
    footer,
    editMode,
    clientId,
    description,
    wealthMissionValueId,
    onEditWealthMissionValue,
    wealthMissionValueDefaultId
  ])

  const onDeleteWealthMissionValue = useCallback(async () => {
    try {
      toggleIsDeleting.on()
      await deleteWealthMissionValue(wealthMissionValueId)
      onDeleteWealthValue()
    } catch (err) {
      console.error(err)
    } finally {
      toggleIsDeleting.off()
      toggleModal.off()
    }
  }, [
    toggleModal,
    toggleIsDeleting,
    onDeleteWealthValue,
    wealthMissionValueId
  ])

  if (isLoading) {
    return <ClientValuesCardSkeleton />
  }

  return (
    <>
      <div
        className={containerClassName}
        onMouseEnter={toggleHover.on}
        onMouseLeave={toggleHover.off}
      >
        {hover && !editMode && (
          <div className={classes.actions}>
            <IconButton onClick={toggleEditMode.toggle}>
              <Icon name={ICON_NAMES.edit} />
            </IconButton>
            {wealthMissionValueId && (
              <IconButton onClick={toggleModal.on}>
                <Icon name={ICON_NAMES.delete} />
              </IconButton>
            )}
          </div>
        )}
        {cardBody}
      </div>
      <ConfirmationModal
        width='25rem'
        open={modalOpen}
        container={null}
        buttonSize='small'
        isLoading={isDeleting}
        onCancel={toggleModal.off}
        buttonLabelCancel="No, don't delete"
        buttonLabelConfirm='Yes, delete it'
        confirmationText='Are you sure you want to delete this record?'
        backdropStyles={MODAL_BACKDROP_STYLES}
        onConfirm={onDeleteWealthMissionValue}
        confirmationType={CONFIRMATION_TYPE.Danger}
      />
    </>
  )
}

ClientValueCard.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  footer: PropTypes.node,
  icon: PropTypes.string,
  isLoading: PropTypes.bool,
  clientId: PropTypes.number,
  wealthMissionValueId: PropTypes.number,
  wealthMissionValueDefaultId: PropTypes.number,
  onEditWealthValue: PropTypes.func,
  onDeleteWealthValue: PropTypes.func
}

export default ClientValueCard
