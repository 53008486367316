import React from 'react'
import PropTypes from 'prop-types'
import { Box, makeStyles } from '@material-ui/core'
import TableSkeleton from '../../../atoms/TableSkeleton'
import ClientItem from './ClientItem'

const useStyles = makeStyles(() => ({
  listContainer: {
    padding: '0.5rem',
    display: 'flex',
    flexDirection: 'column',
    gap: '0.5rem',
    borderRadius: '0.75rem',
    '& a': {
      textDecoration: 'none !important'
    }
  }
}))

const ClientsList = ({ clients, isLoading }) => {
  const classes = useStyles()

  if (isLoading) {
    return (
      <Box p={2}>
        <TableSkeleton
          firstColumnWithAvatar
          columns={1}
          rows={5}
          showHeader={false}
          rowHeight='2.5rem'
        />
      </Box>
    )
  }

  return (
    <div className={classes.listContainer}>
      {clients.map((client) => {
        return <ClientItem key={client.clientId} client={client} />
      })}
    </div>
  )
}

ClientsList.propTypes = {
  clients: PropTypes.array,
  isLoading: PropTypes.bool
}

export default ClientsList
