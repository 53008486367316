import React from 'react'
import { makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import Text from '../../atoms/Text'
import { useContextContainer } from '../../../abundanceEngine/components/ContextContainer'
import { ASSET_TEAR_CONTEXT } from '.'

const useStyles = makeStyles(() => ({
  title: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    lineHeight: '20px',
    color: '#000000',
    margin: '1.5rem 0'
  }
}))

const AssetTearTitle = ({ title, titleKey, fontSize, ...props }) => {
  const classes = useStyles()
  const [{ data }] = useContextContainer(ASSET_TEAR_CONTEXT)
  return (
    <Text
      text={titleKey ? data?.[titleKey] || '' : title}
      customFontSize={fontSize}
      className={classes.title}
      {...props}
    />
  )
}

AssetTearTitle.propTypes = {
  title: PropTypes.string,
  titleKey: PropTypes.string,
  fontSize: PropTypes.string
}

AssetTearTitle.defaultProps = {
  title: '',
  titleKey: undefined,
  fontSize: '1.25rem'
}

export default AssetTearTitle
