import React from 'react'
import PropTypes from 'prop-types'
import { Button, alpha, makeStyles } from '@material-ui/core'

const useStyles = makeStyles(() => ({
  tag: {
    borderRadius: '30px',
    background: ({ color }) => color,
    color: 'white',
    fontSize: '0.75rem',
    fontWeight: 'bold',
    padding: '0.25rem 0.75rem',
    textTransform: 'lowercase',
    '&:hover': {
      background: ({ color }) => `${alpha(color, 0.75)} !important`
    }
  }
}))

const ClientValuesTag = ({ children, color }) => {
  const classes = useStyles({ color })
  return <Button className={classes.tag}>{children}</Button>
}

ClientValuesTag.propTypes = {
  color: PropTypes.string,
  children: PropTypes.node.isRequired
}

ClientValuesTag.defaultProps = {
  color: '#3AA76D'
}

export default ClientValuesTag
