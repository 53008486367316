import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import Tooltip from '../../../atoms/Tooltip'
import FastAvatar from '../../../molecules/FastAvatar'
import { getRelativeTimeFromDate } from '../../../../utils'

dayjs.extend(utc)

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '0.75rem 1rem',
    gap: '1.5rem',
    borderRadius: '0.75rem 1rem',
    color: '#212945',
    '&:hover': {
      background: '#F5F6F7',
      borderRadius: '0.25rem'
    },
    '& .__content': {
      flex: '1',
      minWidth: 0
    },
    '& .__date': {
      width: 'fit-content',
      fontSize: '0.75rem',
      marginLeft: 'auto',
      fontWeight: 'bold',
      color: theme.palette.gray.A700
    },
    '& .__header': {
      display: 'flex',
      alignItems: 'center',
      whiteSpace: 'nowrap',
      flexDirection: 'row',
      gap: '1rem'
    },
    '& .__title': {
      fontSize: '0.875rem',
      fontWeight: 'bold',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      maxWidth: '80%'
    },
    '& .__icon': {
      marginLeft: 'auto'
    },
    '& .__body': {
      fontSize: '0.875rem',
      marginTop: '0.5rem',
      fontWeight: 'normal',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      color: theme.palette.gray.A600
    }
  }
}))

const MessageItem = ({ date, avatar, title, bodyText, tooltipTitle }) => {
  const classes = useStyles()
  const relativeDate = useMemo(() => getRelativeTimeFromDate(date), [date])

  return (
    <Tooltip title={tooltipTitle}>
      <div className={classes.container}>
        <FastAvatar
          size='md'
          avatarUrl={avatar?.url}
          abbreviation={avatar?.abbreviation}
        />
        <div className='__content'>
          <div className='__header'>
            <div className='__title'>{title}</div>
            <div className='__date'>{relativeDate}</div>
          </div>
          <div className='__body'>{bodyText}</div>
        </div>
      </div>
    </Tooltip>
  )
}

MessageItem.propTypes = {
  date: PropTypes.string,
  avatar: PropTypes.shape({
    url: PropTypes.string,
    abbreviation: PropTypes.string,
    isPublicResource: PropTypes.bool
  }),
  title: PropTypes.string,
  bodyText: PropTypes.string,
  tooltipTitle: PropTypes.string
}

export default MessageItem
