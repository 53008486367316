import {
  Box,
  Checkbox,
  Chip,
  CircularProgress,
  ClickAwayListener,
  Divider,
  FormControlLabel,
  InputBase,
  makeStyles,
  Paper,
  Popover,
  useTheme
} from '@material-ui/core'
import React, {
  useCallback,
  useRef,
  useReducer,
  useMemo,
  useEffect,
  useState
} from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import CloseIcon from '@material-ui/icons/Close'

import AddIcon from '@material-ui/icons/Add'
import keymirror from 'keymirror'
import noop from 'lodash/noop'
import Text from '../atoms/Text'
import { saveTag, searchTag } from '../../service'
import useDebouncedCallback from '../../hooks/useDebouncedCallback'
import { modifyByIndex, removeByIndex } from '../../utils'
import { ICON_NAMES } from '../../constants'
import Icon from '../atoms/Icon'
import { tagOptions } from '../../prop-types'

const useStyles = makeStyles((theme) => ({
  chipsArray: {
    minHeight: '2.5rem',
    height: 'auto',
    display: 'flex',
    justifyContent: 'left',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.5),
    margin: 0,
    alignContent: 'center'
  },
  paperRoot: {
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: '0.5rem',
    padding: '1rem',
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '16rem'
  },
  paper: {
    borderRadius: '0.5rem'
  },
  chip: {
    margin: theme.spacing(0.5),
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.getContrastText(theme.palette.primary.main),
    '& svg': {
      color: theme.palette.white,
      '&:hover': {
        color: theme.palette.white
      }
    }
  },
  placeHolder: {
    fontSize: '0.75rem',
    backgroundColor: 'unset !important',
    color: theme.palette.cadetBlue,
    alignSelf: 'center'
  },
  readOnly: {
    cursor: 'not-allowed !important'
  },
  checkbox: {
    color: `${theme.palette.primary.main} !important`
  },
  searchInput: {
    backgroundColor: theme.palette.gallery,
    border: `1px solid ${theme.palette.gallery}`,
    borderRadius: '50px',
    padding: '2px 12px'
  },
  searchInputFocus: {
    border: `1px solid ${theme.palette.primary.main}`
  },
  icon: {},
  optionsContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '1.5rem',
    maxHeight: '12rem',
    overflowY: 'auto'
  },
  cardFooter: {
    display: 'flex',
    paddingTop: '1rem',
    alignContent: 'center',
    justifyContent: 'center'
  },
  footerAddButton: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      fontSize: '1.5rem'
    }
  },
  closeIcon: {
    marginLeft: '0.5rem',
    cursor: 'pointer'
  },
  circularProgress: {
    color: theme.palette.primary.main
  }
}))

const ACTIONS = keymirror({
  ADD_OPTION: null,
  DELETE_CHIP: null,
  MODAL_TOGGLE: null,
  FILTER_BY_QUERY: null,
  SELECT_OPTION: null
})

const initialState = {
  query: '',
  open: false,
  touched: false,
  chips: [],
  options: [],
  filteredOptions: []
}

const parseTag = ({ id, name }) => ({ id, label: name })

const sortOptions = (options) =>
  options.sort((optionA, optionB) =>
    optionA.label.localeCompare(optionB.label)
  )

const reducer = (prevState, { type: actionType, payload }) => {
  const { options: prevOptions, filteredOptions: prevFilteredOptions } =
    prevState

  switch (actionType) {
    case ACTIONS.ADD_OPTION: {
      const { option } = payload
      return {
        ...prevState,
        options: sortOptions([{ ...option }, ...prevOptions]),
        filteredOptions: sortOptions([{ ...option }, ...prevFilteredOptions])
      }
    }
    case ACTIONS.DELETE_CHIP: {
      const { chips } = prevState
      const { chip } = payload
      const chipIndex = chips.findIndex((_chip) => _chip.id === chip.id)
      const optionIndex = prevOptions.findIndex(({ id }) => id === chip.id)
      return {
        ...prevState,
        chips: removeByIndex(chipIndex, chips),
        options: modifyByIndex(optionIndex, prevOptions, {
          ...prevOptions[optionIndex],
          value: false
        }),
        touched: true,
        filteredOptions: []
      }
    }
    case ACTIONS.MODAL_TOGGLE: {
      const open = !prevState.open
      if (open) {
        return {
          ...prevState,
          open,
          filteredOptions: !prevState.touched ? [] : prevState.filteredOptions
        }
      }

      const chips = prevOptions
        .filter((option) => option.value)
        .map(({ id, label }) => ({ id, label }))

      return {
        ...prevState,
        query: '',
        open,
        chips,
        filteredOptions: []
      }
    }
    case ACTIONS.FILTER_BY_QUERY: {
      const { query, options } = payload
      if (!query && !options.length) {
        return {
          ...prevState,
          query,
          touched: true,
          filteredOptions: []
        }
      }
      const normalizedValue = query.toLowerCase()
      const filteredOptions = prevOptions.filter(
        (option) => option.label.toLowerCase().indexOf(normalizedValue) !== -1
      )
      const filteredOptionIds = filteredOptions.map((option) => option.id)
      const uniqueOptions = options.filter(
        (option) => !filteredOptionIds.includes(option.id)
      )
      return {
        ...prevState,
        query,
        touched: true,
        filteredOptions: sortOptions([...filteredOptions, ...uniqueOptions])
      }
    }
    case ACTIONS.SELECT_OPTION: {
      const { option: selectedOption } = payload

      let filteredOptions = prevFilteredOptions
      if (prevFilteredOptions.length) {
        const filteredOptionIndex = prevFilteredOptions.findIndex(
          (option) => option.id === selectedOption.id
        )
        if (filteredOptionIndex !== -1) {
          filteredOptions = [
            ...prevFilteredOptions.slice(0, filteredOptionIndex),
            {
              ...prevFilteredOptions[filteredOptionIndex],
              value: selectedOption.value
            },
            ...prevFilteredOptions.slice(filteredOptionIndex + 1)
          ]
        }
      }

      let options = prevOptions
      if (prevOptions.length) {
        const optionIndex = prevOptions.findIndex(
          (option) => option.id === selectedOption.id
        )
        if (optionIndex !== -1) {
          options = [
            ...prevOptions.slice(0, optionIndex),
            {
              ...prevOptions[optionIndex],
              value: selectedOption.value
            },
            ...prevOptions.slice(optionIndex + 1)
          ]
        }
      }

      return {
        ...prevState,
        touched: true,
        options,
        filteredOptions
      }
    }
    default: {
      return prevState
    }
  }
}

const InputSearchTagChip = ({
  clientId,
  placeHolder,
  onAddTag,
  onChange,
  options,
  readOnly,
  defaultOptions,
  maxAllowedOptions,
  chipsArrayClassName
}) => {
  const classes = useStyles()
  const theme = useTheme()
  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    options: sortOptions(options),
    chips: [...defaultOptions]
  })
  const [isLoading, setIsLoading] = useState(false)
  const placeHolderElement = useRef()

  useEffect(() => {
    if (state.touched) {
      onChange(state.chips)
    }
  }, [state.chips, state.touched, onChange])

  useEffect(() => {
    if (options.length !== state.options.length) {
      const currentOptionsIds = state.options.map((option) => option.id)
      const [option] = options.filter(
        (option) => !currentOptionsIds.includes(option.id)
      )
      dispatch({
        type: ACTIONS.ADD_OPTION,
        payload: { option: option }
      })
    }
  }, [options, state.options])

  const onDeleteChipHandler = useCallback(
    (chip) =>
      dispatch({
        type: ACTIONS.DELETE_CHIP,
        payload: { chip }
      }),
    []
  )

  const onToggleSearchPopup = useCallback(
    () =>
      dispatch({
        type: ACTIONS.MODAL_TOGGLE
      }),
    []
  )

  const onSearchTag = useCallback(
    async (query) => {
      setIsLoading(true)
      const queryFormatted = query.trim()
      try {
        const {
          data: { rows: tags }
        } = await searchTag(clientId, queryFormatted)
        const tagsFormatted = tags.map(parseTag)
        dispatch({
          type: ACTIONS.FILTER_BY_QUERY,
          payload: {
            query: queryFormatted,
            options: tagsFormatted
          }
        })
      } catch (err) {
        dispatch({
          type: ACTIONS.FILTER_BY_QUERY,
          payload: {
            query: queryFormatted,
            options: []
          }
        })
      } finally {
        setIsLoading(false)
      }
    },
    [clientId]
  )

  const onChangeHandler = useDebouncedCallback(onSearchTag)

  const onOptionCheckHandler = useCallback(
    (option, value) =>
      dispatch({
        type: ACTIONS.SELECT_OPTION,
        payload: {
          option: { ...option, value }
        }
      }),
    []
  )

  const onAddOptionHandler = useCallback(async () => {
    try {
      setIsLoading(true)
      const { data: tag } = await saveTag(clientId, { name: state.query })
      const tagFormatted = parseTag(tag)
      onAddTag(tagFormatted)
      setIsLoading(false)
    } catch (error) {
      console.error(error)
    }
  }, [clientId, state.query, onAddTag])

  const onKeyDown = useCallback(
    (event) => {
      if ((event.ctrlKey || event.metaKey) && event.keyCode === 13) {
        onAddOptionHandler()
      }
    },
    [onAddOptionHandler]
  )

  const maxOptionsReached = useMemo(() => {
    return (
      state.options.filter((option) => option?.value).length >=
        maxAllowedOptions || state.chips.length >= maxAllowedOptions
    )
  }, [state.chips, state.options, maxAllowedOptions])

  const renderOptions = useMemo(() => {
    if (state.query.trim() !== '' && !state.filteredOptions.length) {
      return (
        <div>
          <Text
            customFontSize='1rem'
            customFontWeight='bold'
            text='No matching tags'
          />
          <Box mt={0.5} mb={3}>
            <Text
              lineHeight='1.5rem'
              color={theme.palette.gray.A500}
              text='Try a different keyword or press CMD+Enter to create a new tag.'
            />
          </Box>
          <Divider />
          <div className={classes.cardFooter}>
            <div
              className={classes.footerAddButton}
              onClick={onAddOptionHandler}
            >
              <AddIcon />
              <Box ml={2}>
                <Text text={`Create "${state.query}"`} />
              </Box>
            </div>
          </div>
        </div>
      )
    }
    if (
      !state.options.length &&
      !state.filteredOptions.length &&
      !state.query
    ) {
      return (
        <div>
          <Text
            customFontSize='1rem'
            customFontWeight='bold'
            text='Create your first tag'
          />
          <Box mt={0.5} mb={3}>
            <Text
              lineHeight='1.5rem'
              color={theme.palette.gray.A500}
              text='Start typing and press CMD+Enter to create a new tag.'
            />
          </Box>
        </div>
      )
    }
    return (
      state.filteredOptions.length ? state.filteredOptions : state.options
    ).map((option, index) => {
      return (
        <FormControlLabel
          key={`input-chip-option-${index}`}
          control={
            <Checkbox
              checked={option.value || false}
              onChange={(event) =>
                onOptionCheckHandler(option, event.target.checked)}
              name={option.name}
              classes={{
                root: classes.checkbox
              }}
              disabled={maxOptionsReached && !option.value}
            />
          }
          label={option.label}
        />
      )
    })
  }, [
    state.query,
    state.filteredOptions,
    state.options,
    classes.checkbox,
    classes.cardFooter,
    classes.footerAddButton,
    maxOptionsReached,
    onAddOptionHandler,
    onOptionCheckHandler,
    theme.palette.gray.A500
  ])

  return (
    <div style={{ position: 'relative' }}>
      <ul
        className={clsx(classes.chipsArray, {
          [chipsArrayClassName]: !!chipsArrayClassName
        })}
      >
        {state.chips.map((chip) => {
          return (
            <li
              key={chip.id}
              className={clsx({ [classes.readOnly]: readOnly })}
            >
              <Chip
                size='small'
                label={chip.label}
                className={classes.chip}
                disabled={readOnly}
                deleteIcon={<CloseIcon />}
                onDelete={() => onDeleteChipHandler(chip)}
              />
            </li>
          )
        })}
        <li
          ref={placeHolderElement}
          className={clsx(classes.placeHolder, classes.chip, {
            [classes.readOnly]: readOnly
          })}
          onClick={maxOptionsReached || readOnly ? noop : onToggleSearchPopup}
        >
          {maxOptionsReached && !state.open ? null : placeHolder}
        </li>
        <Popover
          open={state.open}
          anchorEl={() => placeHolderElement.current}
          classes={{
            paper: classes.paper
          }}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
        >
          <ClickAwayListener onClickAway={onToggleSearchPopup}>
            <Paper
              elevation={1}
              classes={{
                root: classes.paperRoot
              }}
            >
              <Box display='flex' alignItems='center'>
                <InputBase
                  classes={{
                    root: classes.searchInput,
                    focused: classes.searchInputFocus
                  }}
                  autoFocus
                  placeholder='Enter tag name'
                  inputProps={{ 'aria-label': 'enter tag name' }}
                  onChange={(event) => onChangeHandler(event.target.value)}
                  onKeyDown={onKeyDown}
                  endAdornment={
                    <div className={classes.icon}>
                      {isLoading ? (
                        <CircularProgress
                          size={22}
                          classes={{
                            colorPrimary: classes.circularProgress
                          }}
                        />
                      ) : (
                        <Icon name={ICON_NAMES.search} customSize='1.25rem' />
                      )}
                    </div>
                  }
                />
                <div
                  className={classes.closeIcon}
                  onClick={onToggleSearchPopup}
                >
                  <Icon name={ICON_NAMES.closeCircle} customSize='1.5rem' />
                </div>
              </Box>
              <div className={classes.optionsContainer}>{renderOptions}</div>
            </Paper>
          </ClickAwayListener>
        </Popover>
      </ul>
    </div>
  )
}

InputSearchTagChip.propTypes = {
  readOnly: PropTypes.bool,
  clientId: PropTypes.number,
  placeHolder: PropTypes.string,
  onAddTag: PropTypes.func,
  onChange: PropTypes.func,
  options: tagOptions,
  defaultOptions: tagOptions,
  maxAllowedOptions: PropTypes.number,
  chipsArrayClassName: PropTypes.string
}

InputSearchTagChip.defaultProps = {
  readOnly: false,
  clientId: undefined,
  placeHolder: 'Add Tags',
  onAddTag: noop,
  onChange: noop,
  options: [],
  defaultOptions: [],
  maxAllowedOptions: 7,
  chipsArrayClassName: undefined
}

export default InputSearchTagChip
