import PropTypes from 'prop-types'
import TabGroup from './TabGroup'
import TabLinks from './TabLinks'

const LinkViewTabs = ({ children }) => {
  return children
}

LinkViewTabs.propTypes = {
  children: PropTypes.node
}

LinkViewTabs.Group = TabGroup
LinkViewTabs.Links = TabLinks

export default LinkViewTabs
