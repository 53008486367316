import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  CircularProgress,
  lighten,
  makeStyles
} from '@material-ui/core'
import clsx from 'clsx'
import { BUTTON_VARIANT, BUTTON_SIZES } from '../../constants'

const getMediumSizeStyles = (noPadding) => ({
  padding: noPadding ? '0' : '6px 22px 6px 22px',
  fontSize: '1rem'
})

const getButtonSizeStyles = (size, noPadding, isLoading) => {
  switch (size) {
    case BUTTON_SIZES.extraSmall: {
      return {
        padding: noPadding
          ? '0'
          : `4px ${isLoading ? 'calc(12px + 1.25rem)' : '10px'} 2px 8px`,
        fontSize: '0.75rem'
      }
    }
    case BUTTON_SIZES.small: {
      return {
        padding: noPadding
          ? '0'
          : `4px ${isLoading ? 'calc(10px + 1.25rem)' : '10px'} 2px 10px`,
        fontSize: '0.875rem'
      }
    }
    case BUTTON_SIZES.large: {
      return {
        padding: noPadding ? '0' : '10px 22px 10px 22px',
        fontSize: '1.25rem',
        borderRadius: 30
      }
    }
    default:
      return getMediumSizeStyles(noPadding)
  }
}

const CIRCULAR_PROGRESS_SIZES = {
  extraSmall: 15,
  small: 15,
  medium: 18,
  large: 20
}

const rightInset = {
  extraSmall: '-1rem',
  small: '-1rem',
  medium: 0,
  large: 0
}

const useStyles = makeStyles((theme) => ({
  base: ({ fullWidth, size, noPadding, isLoading, variant }) => ({
    textTransform: 'none',
    position: 'relative',
    fontWeight: 'bold',
    fontFamily: theme.typography.fontFamily,
    borderRadius: 25,
    padding: '4px 12px',
    boxShadow: 'none',
    ...getButtonSizeStyles(size, noPadding, isLoading),
    ...(fullWidth ? { width: '100%' } : {}),
    ...(variant === BUTTON_VARIANT.text
      ? {
        borderRadius: '1rem',
        backgroundColor: `${theme.palette.porcelain} !important`
      }
      : {}),
    ...(variant === BUTTON_VARIANT.ghost
      ? {
        backgroundColor: 'transparent !important',
        '&:hover': {
          backgroundColor: `${theme.palette.porcelain} !important`
        }
      }
      : {})
  }),
  primary: {
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      background: 'rgba(33, 41, 69, 0.75) !important',
      color: `${theme.palette.getContrastText(
        theme.palette.primary.main
      )} !important`
    },
    '&:disabled': {
      color: `${theme.palette.white} !important`,
      background: `${theme.palette.romanSilver} !important`
    },
    color: `${theme.palette.gray.dark} !important`
  },
  outlined: {
    backgroundColor: theme.palette.white,
    border: `2px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main
  },
  outlinedPrimary: {
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: `${theme.palette.mirage} !important`,
      color: `${theme.palette.getContrastText(
        theme.palette.primary.main
      )} !important`
    },
    color: `${theme.palette.gray.dark} !important`
  },
  secondary: {
    backgroundColor: theme.palette.white,
    '&:hover': {
      borderColor: `${theme.palette.summitBlue} !important`,
      backgroundColor: `${theme.palette.whiteLilac} !important`,
      color: `${theme.palette.summitBlue} !important`
    },
    '&:disabled': {
      color: `${theme.palette.romanSilver} !important`,
      borderColor: `${theme.palette.romanSilver} !important`,
      backgroundColor: `${theme.palette.white} !important`
    },
    color: `${theme.palette.summitBlue} !important`,
    border: `2px solid ${theme.palette.summitBlue}`,
    padding: '4px 22px !important'
  },
  tertiary: {
    backgroundColor: theme.palette.whiteLilac,
    '&:hover': {
      backgroundColor: `${theme.palette.whiteLilac} !important`,
      color: `${theme.palette.darkJungle} !important`
    },
    '&:disabled': {
      color: `${theme.palette.romanSilver} !important`,
      background: 'linear-gradient(0deg, rgba(238, 240, 248, 0.50) 0%, rgba(238, 240, 248, 0.50) 100%), #FFF !important'
    },
    color: `${theme.palette.darkJungle} !important`
  },
  disabled: ({ variant }) => ({
    backgroundColor: `${lighten(theme.palette.primary.main, 0.3)} !important`,
    color: `${theme.palette.white} !important`,
    ...(variant === BUTTON_VARIANT.text
      ? {
        opacity: 0.5,
        backgroundColor: `${theme.palette.white} !important`,
        color: `${theme.palette.getContrastText(
            theme.palette.white
          )} !important`
      }
      : {})
  }),
  circularProgress: ({ size }) => ({
    color: theme.palette.gray.main,
    position: 'absolute',
    display: 'flex',
    top: '50%',
    transform: 'translateY(-50%)',
    right: rightInset[size]
  }),
  label: {
    minWidth: '44px',
    position: 'relative'
  }
}))

const RoundedButton = ({
  id,
  size,
  type,
  noPadding,
  disabled,
  primary,
  secondary,
  tertiary,
  variant,
  onClick,
  children,
  className,
  fullWidth,
  isLoading,
  disabledClassName,
  startIcon
}) => {
  const classes = useStyles({ fullWidth, size, noPadding, isLoading, variant })

  const buttonClassName = useMemo(() => {
    const { contained, outlined } = BUTTON_VARIANT

    const usePrimaryClass = primary && variant === contained
    const useOutlinedPrimaryClass = primary && variant === outlined
    const useSecondaryClass = secondary && variant === contained
    const useTertiaryClass = tertiary && variant === contained
    const useOutlined = variant === outlined

    return clsx(
      {
        [classes.outlined]: useOutlined,
        [classes.primary]: usePrimaryClass,
        [classes.outlinedPrimary]: useOutlinedPrimaryClass,
        [classes.secondary]: useSecondaryClass,
        [classes.tertiary]: useTertiaryClass
      },
      classes.base,
      className
    )
  }, [
    classes.base,
    classes.primary,
    classes.outlined,
    classes.outlinedPrimary,
    classes.secondary,
    classes.tertiary,
    primary,
    secondary,
    tertiary,
    variant,
    className
  ])

  const buttonClasses = useMemo(() => {
    return {
      disabled: clsx(classes.disabled, {
        [disabledClassName]: !!disabledClassName
      }),
      label: classes.label
    }
  }, [classes.label, classes.disabled, disabledClassName])

  return (
    <Button
      id={id}
      {...(size !== BUTTON_SIZES.extraSmall ? { size } : {})}
      variant={variant !== BUTTON_VARIANT.ghost ? variant : undefined}
      onClick={onClick}
      disabled={disabled}
      classes={buttonClasses}
      className={buttonClassName}
      startIcon={startIcon}
      {...(type ? { type } : {})}
    >
      {children}
      {isLoading && (
        <div className={classes.circularProgress}>
          <CircularProgress size={CIRCULAR_PROGRESS_SIZES[size]} />
        </div>
      )}
    </Button>
  )
}

RoundedButton.propTypes = {
  id: PropTypes.string,
  noPadding: PropTypes.bool,
  size: PropTypes.oneOf(Object.values(BUTTON_SIZES)),
  disabled: PropTypes.bool,
  primary: PropTypes.bool,
  secondary: PropTypes.bool,
  tertiary: PropTypes.bool,
  variant: PropTypes.oneOf(Object.values(BUTTON_VARIANT)),
  fullWidth: PropTypes.bool,
  onClick: PropTypes.func,
  children: PropTypes.any,
  className: PropTypes.string,
  isLoading: PropTypes.bool,
  type: PropTypes.string,
  disabledClassName: PropTypes.string,
  startIcon: PropTypes.node
}
RoundedButton.defaultProps = {
  id: undefined,
  noPadding: false,
  size: BUTTON_SIZES.medium,
  disabled: false,
  primary: false,
  secondary: false,
  tertiary: false,
  fullWidth: false,
  variant: BUTTON_VARIANT.contained,
  onClick: undefined,
  children: undefined,
  className: undefined,
  isLoading: false,
  type: undefined,
  disabledClassName: undefined
}

export default RoundedButton
