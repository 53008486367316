import { makeStyles } from '@material-ui/core'
import { useFirmInfo } from './hooks'

/*
 * TREAD CAREFULLY - THESE STYLES HAVE SPECIFIC NAMES THAT ARE USED BY CLIENT LOGIN PAGE OVERRIDES
 * DO NOT CHANGE UNLESS YOU ARE ALSO GOING TO CHANGE ALL THE DEPLOYED JSON FILES
 */
export const useStyles = makeStyles((theme) => {
  const defaults = {
    logo: {
      height: 150
    },
    title: {
      margin: '3rem 0 1.8rem 0',
      fontSize: '1.4rem',
      width: '280px',
      fontWeight: 700,
      textAlign: 'center',
      whiteSpace: 'pre-line'
    },
    resetTitle: {
      margin: '3rem 0 1.8rem 0',
      fontSize: '1.4rem',
      width: '380px',
      fontWeight: 700,
      textAlign: 'center'
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
      alignItems: 'center'
    },
    textField: {
      width: '90%',
      backgroundColor: theme.palette.gray.main
    },
    field: {
      width: '90%',
      backgroundColor: theme.palette.gray.main,
      border: '1px solid transparent',
      marginBottom: '1.5rem'
    },
    inputBase: {
      borderRadius: '0px',
      border: 'none',
      padding: '0.75rem',
      fontSize: '1rem',
      fontWeight: 600
    },
    input: {
      padding: '10px 0 7px',
      '&::placeholder': {
        fontSize: '1rem',
        textOverflow: 'ellipsis !important',
        color: theme.palette.common.black,
        opacity: '60%',
        fontWeight: 600
      }
    },
    inputIcon: {
      marginRight: '6px'
    },
    signInButton: {
      width: '90%',
      margin: theme.spacing(3, 0, 2),
      borderRadius: '30px',
      border: 'solid 3px transparent',
      backgroundColor: theme.palette.summitBlue,
      color: `${theme.palette.white} !important`,
      '&:hover': {
        backgroundColor: `${theme.palette.mirage} !important`,
        color: `${theme.palette.getContrastText(
          theme.palette.summitBlue
        )} !important`
      },
      padding: '10px 0',
      textTransform: 'capitalize',
      fontSize: '1.2rem',
      fontWeight: 600
    },
    disabled: {
      backgroundColor: '#e0e0e099 !important',
      border: 'solid 3px #e0e0e099 !important',
      color: `${theme.palette.gray.A500} !important`
    },
    forgotPassword: {
      fontSize: '.875rem',
      fontWeight: 600,
      marginTop: '1.75rem',
      cursor: 'pointer',
      '&:hover': {
        textDecoration: 'underline'
      }
    },
    fieldError: {
      border: `1px solid ${theme.palette.error.main}`
    },
    errorMessage: {
      fontSize: '1rem',
      fontWeight: 'bold',
      color: '#d50000',
      textShadow: '#FFFFFF99 1px 0 36px'
    },
    formWrapper: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      minWidth: '100%'
    },
    layout: {},
    backToLoginLabel: {
      fontSize: '.875rem',
      fontWeight: 600,
      marginTop: '1.75rem',
      cursor: 'pointer',
      '&:hover': {
        textDecoration: 'underline'
      }
    },
    select: {
      padding: '1.2rem',
      width: '90%',
      color: '#5F5F5F',
      background: '#EEEEEE',
      border: 'none',
      borderRadius: '0',
      maxWidth: '410px'
    },
    fieldContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
      textAlign: 'center',
      marginBottom: '1.5rem'
    },
    signoutMessage: {
      fontSize: theme.typography.fontSizes.h5
    }
  }

  return {
    logo: ({ overrides = {} }) => overrides?.logo ?? defaults.logo,
    title: ({ overrides = {} }) => overrides?.title ?? defaults.title,
    resetTitle: ({ overrides = {} }) => overrides?.resetTitle ?? defaults.resetTitle,
    form: ({ overrides = {} }) => overrides?.form ?? defaults.form,
    field: ({ overrides = {} }) => overrides?.field ?? defaults.field,
    inputBase: ({ overrides = {} }) => overrides?.inputBase ?? defaults.inputBase,
    input: ({ overrides = {} }) => overrides?.input ?? defaults.input,
    inputIcon: ({ overrides = {} }) => overrides?.inputIcon ?? defaults.inputIcon,
    disabled: ({ overrides = {} }) => overrides?.disabled ?? defaults.disabled,
    forgotPassword: ({ overrides = {} }) => overrides?.forgotPassword ?? defaults.forgotPassword,
    fieldError: ({ overrides = {} }) => overrides?.fieldError ?? defaults.fieldError,
    errorMessage: ({ overrides = {} }) => overrides?.errorMessage ?? defaults.errorMessage,
    signInButton: ({ overrides = {} }) => overrides?.signInButton ?? defaults.signInButton,
    formWrapper: ({ overrides = {} }) => overrides?.formWrapper ?? defaults.formWrapper,
    layout: ({ overrides = {} }) => overrides?.layout ?? defaults.layout,
    backToLoginLabel: ({ overrides = {} }) => overrides?.backToLoginLabel ?? defaults.backToLoginLabel,
    select: ({ overrides = {} }) => overrides?.select ?? defaults.select,
    fieldContainer: ({ overrides = {} }) => overrides?.fieldContainer ?? defaults.fieldContainer
  }
})

export const usePublicStyles = () => {
  const firmInfo = useFirmInfo()
  return useStyles({ overrides: firmInfo?.data?.loginStyleOverrides })
}
