import React, { useCallback, useState } from 'react'
import { useHistory } from 'react-router-dom'
import clsx from 'clsx'
import { Button, Grid, makeStyles, withStyles } from '@material-ui/core'
import Tooltip from '@material-ui/core/Tooltip'
import Text from '../../../atoms/Text'
import { useCheckPolicy, useFetchState } from '../../../../hooks'
import { getFeeSchedules } from '../../../../service'
import CardsTable from '../../../molecules/CardsTable'
import TableSkeleton from '../../../atoms/TableSkeleton'
import ErrorComponent from '../../../atoms/ErrorComponent'
import {
  numberToBPS,
  feeLimitsDetailFormatter,
  numberToUSD,
  capitalizeFirstLetter
} from '../../../../utils'
import {
  ADMIN_ROUTES,
  FEE_SCHEDULE_TYPES_MINIMUM_FEE_LABEL,
  FEE_SCHEDULE_TYPES_MINIMUM_FEE_VALUE,
  FEE_VALUE_TYPES_BPS_VALUE,
  FEE_VALUE_TYPES_DOLLAR_VALUE
} from '../../../../constants'
import { BILLING } from '../../../../policies/admin'
import FeeScheduleSelector from './FeeScheduleSelector'

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '1rem',
    height: '100%',
    display: 'flex',
    flex: '1',
    flexDirection: 'column'
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    boxSizing: 'border-box',
    alignItems: 'center',
    padding: `${theme.spacing(1.5)}px ${theme.spacing(0.5)}px`,
    width: '100%'
  },
  button: {
    borderRadius: '30px',
    fontWeight: 800,
    textTransform: 'capitalize'
  },
  addButton: {
    color: theme.palette.common.white,
    padding: '8px 20px 8px 35px',
    backgroundColor: theme.palette.darkBlue,
    '&:hover': {
      backgroundColor: '#171c47b3'
    },
    fontWeight: 600
  },
  disabledButton: {
    cursor: 'not-allowed !important',
    backgroundColor: '#7f8299'
  },
  addButtonSign: {
    fontSize: '26px',
    position: 'absolute',
    top: '-2px',
    left: '12px',
    fontWeight: '400'
  },
  calcType: {
    padding: '10px 0',
    maxWidth: '80%'
  },
  multiTierCalcType: {
    '&:hover': {
      backgroundColor: '#fff',
      textDecoration: 'underline',
      color: '#898D9B'
    }
  }
}))

const COLUMNS = [
  {
    Header: 'Fee ID',
    accessor: 'feeScheduleId'
  },
  {
    Header: 'Fee Name',
    accessor: 'longName'
  },
  {
    Header: 'Description',
    accessor: 'description'
  },
  {
    Header: 'Type',
    accessor: 'type'
  },
  {
    Header: 'Details',
    accessor: 'details'
  },
  {
    Header: '# of Clients',
    accessor: 'clientCount'
  },
  {
    Header: '# of Accounts',
    accessor: 'accountCount'
  }
]

const CalcTypeTooltip = withStyles((theme) => ({
  tooltip: {
    padding: '10px',
    minWidth: '14rem',
    backgroundColor: '#FFF',
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: theme.typography.pxToRem(14),
    border: '1px solid #E9EAEF',
    borderRadius: '8px',
    margin: 0
  }
}))(Tooltip)

const FeeSchedules = () => {
  const classes = useStyles()
  const history = useHistory()
  const canEditFeeSchedule = useCheckPolicy(BILLING.editFeeSchedules)
  const [loadData, setLoadData] = useState(true)

  const tooltipText = useCallback((details) => {
    return details.map((detail) => {
      const { upperLimit, lowerLimit, feeRate, denomination } = detail
      const denominationStr = `${
        denomination === FEE_VALUE_TYPES_BPS_VALUE
          ? denomination.toUpperCase()
          : ''
      }`

      return (
        <Grid
          key={`tooltip-fee-detail-${detail.feeScheduleDetailId}`}
          container
          justifyContent='space-evenly'
          style={{ padding: '5px 0' }}
        >
          <Grid item sm={5} style={{ textAlign: 'right' }}>
            <strong>{feeLimitsDetailFormatter(lowerLimit, upperLimit)}</strong>
          </Grid>
          <Grid item />
          <Grid item style={{ textAlign: 'right' }}>
            {denomination === FEE_VALUE_TYPES_BPS_VALUE
              ? numberToBPS(feeRate)
              : numberToUSD(feeRate)}{' '}
            {denominationStr}
          </Grid>
        </Grid>
      )
    })
  }, [])

  const mapDataToItems = useCallback(
    (item) => {
      const calcTypesWithDetails = Object.values(
        (item?.details || []).reduce((calcTypesWithDetails, detail) => {
          const existing = calcTypesWithDetails[detail.calcTypeId]
          if (existing) {
            existing.details.push(detail)
          } else {
            calcTypesWithDetails[detail.calcTypeId] = {
              calcTypeId: detail.calcTypeId,
              name: detail.calcType,
              isSingleTier: detail.isSingleTier,
              details: [detail]
            }
          }
          return calcTypesWithDetails
        }, {})
      )

      if (item.minimumFee) {
        calcTypesWithDetails.push({
          calcTypeId: FEE_SCHEDULE_TYPES_MINIMUM_FEE_VALUE,
          name: FEE_SCHEDULE_TYPES_MINIMUM_FEE_LABEL,
          isSingleTier: true,
          details: [
            {
              lowerLimit: '0',
              upperLimit: null,
              feeRate: `${item.minimumFee}`
            }
          ]
        })
      }

      const renderLimitRowSection = (
        detail,
        idx,
        isUniqueItem = false,
        isSingleTier = false
      ) => {
        const { upperLimit, lowerLimit, feeRate, denomination } = detail
        const denominationStr = `${
          denomination === FEE_VALUE_TYPES_BPS_VALUE
            ? denomination.toUpperCase()
            : capitalizeFirstLetter(`${FEE_VALUE_TYPES_DOLLAR_VALUE}s`)
        }`

        if (idx === 1) return `... ${denominationStr}`
        if (idx > 1) return null

        return isUniqueItem ? (
          <>
            <strong>
              {!isSingleTier &&
                `${feeLimitsDetailFormatter(lowerLimit, upperLimit)}, `}
              {denomination === FEE_VALUE_TYPES_BPS_VALUE
                ? numberToBPS(feeRate)
                : numberToUSD(feeRate)}
            </strong>
            &nbsp;
            {denominationStr}
          </>
        ) : (
          <strong>
            {feeLimitsDetailFormatter(lowerLimit, upperLimit)},&nbsp;
            {denomination === FEE_VALUE_TYPES_BPS_VALUE
              ? numberToBPS(feeRate)
              : numberToUSD(feeRate)}
          </strong>
        )
      }

      return {
        ...item,
        details: calcTypesWithDetails.map((calcType) => (
          <CalcTypeTooltip
            placement='bottom-start'
            disableHoverListener={
              calcType.details.length <= 1 || calcType.isSingleTier
            }
            key={`calc-type-${calcType.calcTypeId}`}
            title={tooltipText(calcType.details)}
          >
            <div
              className={clsx(
                classes.calcType,
                calcType.details.length > 1 ? classes.multiTierCalcType : null
              )}
            >
              {calcType.details.map((detail, idx) => (
                <span key={`fee-detail-${detail.feeScheduleDetailId}`}>
                  {renderLimitRowSection(
                    detail,
                    idx,
                    calcType.details.length === idx + 1 && idx === 0,
                    calcType.isSingleTier
                  )}
                </span>
              ))}
            </div>
          </CalcTypeTooltip>
        )),
        type: calcTypesWithDetails.map(({ name }, idx) => (
          <div key={`type-${idx}`} className={classes.calcType}>
            {name}
          </div>
        )),
        clientCount: item.clientCount || 0,
        accountCount: item.accountCount || 0
      }
    },
    [classes.calcType, classes.multiTierCalcType, tooltipText]
  )

  const singleCallback = useCallback(
    async (safeSetState) => {
      if (loadData) {
        try {
          const res = await getFeeSchedules()
          const items = res.data.map(mapDataToItems)
          safeSetState({ data: res.data, items })
        } catch (error) {
          console.error(error)
          safeSetState({ error })
        } finally {
          setLoadData(false)
        }
      }
    },
    [mapDataToItems, loadData]
  )

  const { items = [], loading, error } = useFetchState(singleCallback)

  const onRowClick = useCallback(
    (row) => {
      history.push(`/admin/billing/fee-schedules/${row.feeScheduleId}`)
    },
    [history]
  )

  const goToNewFeeView = useCallback(() => {
    if (canEditFeeSchedule) history.push(ADMIN_ROUTES.BILLING_FEE_SCHEDULES_NEW)
  }, [history, canEditFeeSchedule])

  return (
    <div className={classes.container}>
      <div className={classes.titleContainer}>
        <Text text='Fee Schedules' variant='h1' customFontSize='34px' />
      </div>
      <div className={classes.titleContainer}>
        <span />
        {canEditFeeSchedule && (
          <Button
            className={clsx(classes.button, classes.addButton, {
              [classes.disabledButton]: loading || error
            })}
            disabled={loading || error}
            onClick={goToNewFeeView}
          >
            <span className={classes.addButtonSign}>+</span>&nbsp;Fee Schedule
          </Button>
        )}
      </div>
      <FeeScheduleSelector />
      {loading && <TableSkeleton />}
      {error && <ErrorComponent error={error} />}
      {!loading && !error && (
        <CardsTable columns={COLUMNS} data={items} onRowClick={onRowClick} />
      )}
    </div>
  )
}

export default FeeSchedules
