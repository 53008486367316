import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import { ACCOUNT_CATEGORY_BLOCK_TYPES } from '../../../constants'
import { visualBalanceDataTypeShape } from '../../../prop-types'
import VisualBalanceTreeView from './VisualBalanceTreeView'
import VisualBalanceSide from './VisualBalanceSide'
import { labelsPropTypes } from './utils'

function VisualBalanceSideBySide ({
  clientName,
  data,
  enableDrilldown,
  height,
  labels,
  margin,
  onClick,
  onPressAccount,
  profilePic,
  showDetails,
  showOnlyDetails,
  totalBackgroundColor,
  totalLiabilitiesBackgroundColor,
  width,
  formatToPrecisionValue,
  includeSideBlocks
}) {
  const dataFiltered = useMemo(() => {
    return includeSideBlocks
      ? data
      : data.filter(
        ({ blockType }) => blockType !== ACCOUNT_CATEGORY_BLOCK_TYPES.SIDE
      )
  }, [data, includeSideBlocks])

  if (showDetails || showOnlyDetails) {
    return (
      <VisualBalanceSide
        data={data}
        width={width}
        margin={margin}
        height={height}
        assetsTitle={labels.details?.assets}
        liabilitiesTitle={labels.details?.liabilities}
        otherAssetsTitle={labels.details?.other}
        assetsBlockTitle={labels.details?.assetsBlock}
        formatToPrecisionValue={formatToPrecisionValue}
      />
    )
  }

  return (
    <VisualBalanceTreeView
      clientName={clientName}
      data={dataFiltered}
      enableDrilldown={enableDrilldown}
      height={height}
      labels={labels}
      onClick={onClick}
      onPressAccount={onPressAccount}
      profilePic={profilePic}
      totalBackgroundColor={totalBackgroundColor}
      totalLiabilitiesBackgroundColor={totalLiabilitiesBackgroundColor}
      width={width}
    />
  )
}

VisualBalanceSideBySide.propTypes = {
  // The client name initials will be used if the profilePic source fails
  clientName: PropTypes.string,
  // The data to display in the TreeMap
  data: PropTypes.arrayOf(PropTypes.shape({
    ...visualBalanceDataTypeShape,
    details: PropTypes.arrayOf(PropTypes.shape(visualBalanceDataTypeShape))
  })),
  // Toggles VBS breakdown to drilldown view
  enableDrilldown: PropTypes.bool,
  // Height of the chart
  height: PropTypes.string,
  // labels and titles for different places in the view
  labels: labelsPropTypes,
  // Margin between different kinds of blocks
  margin: PropTypes.number,
  // callback triggered after pressing block
  onClick: PropTypes.func,
  // callback triggered to specify when an account is pressed
  onPressAccount: PropTypes.func,
  // source of the image for the avatar in the map
  profilePic: PropTypes.string,
  // Toggles VBS split view to side by side view
  showDetails: PropTypes.bool,
  // Disable VBS view
  showOnlyDetails: PropTypes.bool,
  // 'backgroundColor of total block
  totalBackgroundColor: PropTypes.string,
  // 'backgroundColor of total liabilities block
  totalLiabilitiesBackgroundColor: PropTypes.string,
  // Width of the chart
  width: PropTypes.string,
  // Format for amounts
  formatToPrecisionValue: PropTypes.bool,
  // Whether to include side blocks or not
  includeSideBlocks: PropTypes.bool
}

VisualBalanceSideBySide.defaultProps = {
  clientName: undefined,
  data: [],
  enableDrilldown: false,
  height: undefined,
  labels: {},
  margin: undefined,
  onClick: noop,
  onPressAccount: noop,
  profilePic: undefined,
  showDetails: false,
  showOnlyDetails: false,
  totalBackgroundColor: undefined,
  totalLiabilitiesBackgroundColor: undefined,
  width: undefined,
  formatToPrecisionValue: undefined,
  includeSideBlocks: true
}

export default VisualBalanceSideBySide
