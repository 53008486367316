import ThingProvider from './ThingProvider'
import ThingDebug from './ThingDebug'
import ThingSection from './ThingSection'
import SingleThing from './SingleThing'
import ThingContentStyles from './ThingContentStyles'

const thingModule = {
  SingleThing,
  ThingContentStyles,
  ThingProvider,
  ThingDebug,
  ThingSection
}

export default thingModule
