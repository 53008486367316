import { useEffect } from 'react'
import PropTypes from 'prop-types'

let mount = 0
const MountMonitor = ({ children }) => {
  useEffect(() => {
    const m = ++mount
    console.log('mount', m)
    return () => console.log('unmount', m)
  })

  return children
}

MountMonitor.propTypes = {
  children: PropTypes.any
}

export default MountMonitor
