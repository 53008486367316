import { useEffect, useState } from 'react'

export function useImageLoaded (src) {
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    if (!src) {
      setLoaded(true)
      return
    }

    setLoaded(false)

    let active = true
    const image = new Image()
    image.src = src
    image.onload = () => {
      if (!active) {
        return
      }
      setLoaded('loaded')
    }
    image.onerror = () => {
      if (!active) {
        return
      }
      setLoaded('error')
    }

    return () => {
      active = false
    }
  }, [src])

  return loaded
}
