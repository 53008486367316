import { useCallback, useMemo, useState } from 'react'

const DEFAULT_PAGE_SIZE = 100
export const useOperationalTable = (options) => {
  const { defaultSort, defaultPageSize = DEFAULT_PAGE_SIZE } = options
  const [pageSize, setPageSize] = useState(defaultPageSize)
  const [pageIndex, setPageIndex] = useState(0)
  const [sort, setSort] = useState(defaultSort)
  const [searchText, setSearchText] = useState('')
  const [tableMode, setTableMode] = useState(null)

  const onPagingChange = useCallback(({ pageSize, pageIndex }) => {
    setPageSize(pageSize)
    setPageIndex(pageIndex)
  }, [setPageIndex, setPageSize])

  const onSortingChange = useCallback(sortBy => {
    setSort(sortBy)
  }, [setSort])

  const onSearchTextChange = useCallback(value => {
    setSearchText(value)
  }, [setSearchText])

  const onTableModeChange = useCallback(value => {
    setTableMode(value)
  }, [setTableMode])

  return useMemo(() => ({
    pageSize,
    pageIndex,
    defaultPageSize,
    onPagingChange,
    sort,
    onSortingChange,
    searchText,
    onSearchTextChange,
    tableMode,
    onTableModeChange
  }), [
    pageSize,
    pageIndex,
    onPagingChange,
    sort,
    onSortingChange,
    searchText,
    onSearchTextChange,
    tableMode,
    onTableModeChange,
    defaultPageSize
  ])
}
