import React from 'react'
import PropTypes from 'prop-types'
import { IconButton, makeStyles } from '@material-ui/core'
import dayjs from 'dayjs'
import { ICON_NAMES } from '../../../../constants'
import Icon from '../../../atoms/Icon'
import Tooltip from '../../../atoms/Tooltip'
import { useBoolean } from '../../../../hooks'
import Menu from '../../../molecules/Menu'

const useStyles = makeStyles(() => ({
  container: {
    padding: '1rem 1.5rem',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    cursor: 'pointer',
    borderRadius: '0.25rem',
    position: 'relative',
    '&:hover': {
      background: '#F5F6F7',
      borderRadius: '0.25rem'
    }
  },
  content: {
    width: 'calc(100% - 4rem)'
  },
  icon: {
    paddingRight: '1rem'
  },
  name: {
    fontSize: '1rem',
    fontWeight: '600',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  },
  time: {
    fontSize: '0.75rem',
    color: '#999999',
    marginRight: '.5rem'
  },
  footer: {
    display: 'flex',
    flexDirection: 'row'
  },
  owner: {
    display: 'flex',
    marginLeft: 'auto'
  },
  menu: {
    position: 'absolute',
    right: '0.5rem'
  },
  actionsMenu: {
    backgroundColor: '#F5F6F7'
  }
}))

const DocumentItem = ({ document, options, onClick }) => {
  const classes = useStyles()
  const [hover, toggleHover] = useBoolean()

  return (
    <Tooltip title={document.name} placement='top'>
      <div
        className={classes.container}
        onMouseEnter={toggleHover.on}
        onMouseLeave={toggleHover.off}
        onClick={onClick}
      >
        <div className={classes.icon}>
          <Icon name={ICON_NAMES.document} customSize='1.5rem' />
        </div>
        <div className={classes.content}>
          <div className={classes.name}>{document?.name}</div>
          <div className={classes.footer}>
            <div className={classes.time}>
              {dayjs(document?.created_at).format('MM/DD @ h:mm a')}
            </div>
            <div className={classes.owner}>
              {`${document?.owner?.firstName || ''} ${
                document?.owner?.lastName || ''
              }`}
            </div>
          </div>
        </div>
        {hover && (
          <div className={classes.menu}>
            <Menu options={options}>
              {({ setAnchorEl }) => {
                return (
                  <IconButton
                    className={classes.actionsMenu}
                    onClick={setAnchorEl}
                  >
                    <Icon customSize='1.2rem' name={ICON_NAMES.vertDots} />
                  </IconButton>
                )
              }}
            </Menu>
          </div>
        )}
      </div>
    </Tooltip>
  )
}

DocumentItem.propTypes = {
  document: PropTypes.object,
  onClick: PropTypes.func,
  options: PropTypes.array
}

export default DocumentItem
