import React, { useImperativeHandle } from 'react'
import { Grid } from '@material-ui/core'
import { Controller, useForm } from 'react-hook-form'
import PropTypes from 'prop-types'
import SydLabel, { hookFormErrorAdapter } from '../../../../commonDesign/SydLabel'
import SydInput from '../../../../commonDesign/SydInput'
import Card from '../../../../molecules/Card'
import SydSelect from '../../../../commonDesign/SydSelect'
import { usePolicy } from '../../../../../hooks/usePolicy'
import SectionHeader from '../../shared/SectionHeader'
import DataAccessSelector from '../shared/DataAccessSelector'
import VisibilitySelector from '../shared/VisibilitySelector'

const NewAdvisorForm = React.forwardRef(function NewAdvisorForm ({ roleOptions, defaultRoleOption }, ref) {
  const isInternal = usePolicy('internalOnly')
  const form = useForm({
    mode: 'onChange',
    defaultValues: {
      user: {
        email: '',
        firstName: '',
        lastName: '',
        accessLevel: 'limited',
        internal: false
      },
      role: {
        roleId: defaultRoleOption?.value ?? null
      }
    }
  })

  const { handleSubmit, reset } = form
  useImperativeHandle(ref, () => ({
    reset,
    handleSubmit
  }), [reset, handleSubmit])

  return (
    <Grid container spacing={1}>

      {/* user info */}
      <Grid item xs={12} md={6}>
        <Card>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <SectionHeader slim text='Advisor Information' />
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={form.control}
                name='user.firstName'
                rules={{ required: { value: true, message: 'Required' }, maxLength: { value: 30, message: 'Too long' } }}
                render={({ field, fieldState }) => (
                  <SydLabel
                    label='First Name'
                    required description='Required, 30 max characters'
                    error={hookFormErrorAdapter(form, fieldState)}
                  >
                    <SydInput size='sm' placeholder='First Name' {...field} />
                  </SydLabel>
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={form.control}
                name='user.lastName'
                rules={{ required: { value: true, message: 'Required' }, maxLength: { value: 30, message: 'Too long' } }}
                render={({ field, fieldState }) => (
                  <SydLabel
                    label='Last Name'
                    required description='Required, 30 max characters'
                    error={hookFormErrorAdapter(form, fieldState)}
                  >
                    <SydInput size='sm' placeholder='Last Name' {...field} />
                  </SydLabel>
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={form.control}
                name='user.email'
                rules={{ required: { value: true, message: 'Required' }, maxLength: { value: 255, message: 'Too long' } }}
                render={({ field, fieldState }) => (
                  <SydLabel
                    label='Email Address'
                    required description='Required, 255 max characters, must be an email address'
                    error={hookFormErrorAdapter(form, fieldState)}
                  >
                    <SydInput size='sm' placeholder='Email Address' {...field} />
                  </SydLabel>
                )}
              />
            </Grid>
          </Grid>
        </Card>
      </Grid>

      {/* role assignment */}
      <Grid item xs={12} md={6}>
        <Card>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <SectionHeader slim text='Permissions' />
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={form.control}
                name='role.roleId'
                rules={{ required: { value: true, message: 'A role must be chosen' } }}
                render={({ field, fieldState }) => (
                  <SydSelect
                    placeholder='Select a Role'
                    options={roleOptions}
                    onChange={field.onChange}
                    value={field.value}
                    error={(fieldState.isDirty || fieldState.isTouched) && fieldState.error}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Card>
      </Grid>

      {/* data access level */}
      <Grid item xs={12} md={6}>
        <Card>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <SectionHeader slim text='Data Access Level' />
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={form.control}
                name='user.accessLevel'
                render={({ field, fieldState }) => (
                  <DataAccessSelector {...field} />
                )}
              />
            </Grid>
          </Grid>
        </Card>
      </Grid>

      {/* visibility assignment */}
      {isInternal ? (
        <Grid item xs={12} md={6}>
          <Card>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <SectionHeader slim text='Visibility' />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  control={form.control}
                  name='user.internal'
                  render={({ field, fieldState }) => (
                    <VisibilitySelector {...field} />
                  )}
                />
              </Grid>
            </Grid>
          </Card>
        </Grid>
      ) : null}
    </Grid>
  )
})

NewAdvisorForm.propTypes = {
  roleOptions: PropTypes.array,
  defaultRoleOption: PropTypes.number
}

export default NewAdvisorForm
