import Meetings from './Meetings'
import Milestones from './Milestones'
import Tasks from './Tasks'
import ProfileFields from './ProfileFields'
import PeoplePill from './PeoplePill'
import PeopleFields from './PeopleFields'

const abundanceEngineExports = {
  Client_Meetings: Meetings,
  Client_Milestones: Milestones,
  Client_Tasks: Tasks,
  Client_ProfileFields: ProfileFields,
  Client_PeoplePill: PeoplePill,
  Client_PeopleFields: PeopleFields
}

export default abundanceEngineExports
