import React, { useCallback, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import ToolProvider from '../ToolProvider'
import TransactionsProvider from './TransactionsProvider'
import TransactionsPopUpDialog from './dialogs/TransactionsPopUpDialog'

function TransactionsPopUp ({ children, defaultFilter, ...rest }) {
  const dialogRef = useRef(null)
  const providerRef = useRef(null)
  const [filter, setFilter] = useState(defaultFilter)
  const open = useCallback((val) => {
    dialogRef.current.open(val)
    setFilter({
      ...(defaultFilter || {}),
      ...(val || {})
    })
    providerRef.current.setDateRange({
      key: 'CTM',
      startDate: val?.startDate,
      endDate: val?.endDate
    })
  }, [dialogRef, providerRef, defaultFilter])

  const tool = useMemo(() => {
    return {
      open
    }
  }, [open])

  return (
    <TransactionsProvider ref={providerRef} defaultFilter={filter} {...rest}>
      <ToolProvider name='transactionsPopUp' tool={tool}>
        {children}
      </ToolProvider>
      <TransactionsPopUpDialog ref={dialogRef} />
    </TransactionsProvider>
  )
}

TransactionsPopUp.propTypes = {
  defaultFilter: PropTypes.object,
  children: PropTypes.node
}

TransactionsPopUp.defaultProps = {
}

export default TransactionsPopUp
