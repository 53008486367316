import React, { forwardRef, useCallback, useImperativeHandle, useMemo, useRef } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import CheckBoxList from '../../../../organisms/CheckBoxList'

const useStyles = makeStyles(theme => ({
  participantSelector: {
    '& .__user': {
      padding: 0,
      fontWeight: 100,
      fontSize: '16px'
    }
  }
}))

const ParticipantSelector = forwardRef(({ participants, caption }, ref) => {
  const classes = useStyles()
  const shouldHide = useMemo(() => {
    return participants.wealthOwners.length <= 1
  }, [participants])
  const defaultItems = useMemo(() => {
    return participants.wealthOwners.map(u => ({
      id: u.userId,
      checked: true,
      payload: u
    }))
  }, [participants.wealthOwners])
  const innerRef = useRef()
  useImperativeHandle(ref, () => ({
    getUserIds () {
      if (shouldHide) {
        return defaultItems.map(x => x.id)
      }
      return innerRef.current?.value?.map(([, x]) => x)?.filter(x => x.checked)?.map(x => x.id) ?? []
    }
  }))
  const checkboxBody = useCallback((_, u) => (
    <div>
      <span>{u.firstName}</span>
      <span>&nbsp;</span>
      <span>{u.lastName}</span>
    </div>
  ), [])

  if (shouldHide) {
    return null
  }

  return (
    <div>
      <h5>{caption}</h5>
      <div className={classes.participantSelector}>
        <CheckBoxList
          ref={innerRef}
          defaultItems={defaultItems}
          itemContainerClassName='__user'
          renderCheckboxBody={checkboxBody}
        />
      </div>
    </div>
  )
})

ParticipantSelector.propTypes = {
  caption: PropTypes.string,
  participants: PropTypes.shape({
    wealthOwners: PropTypes.arrayOf(PropTypes.shape({
      userId: PropTypes.number,
      firstName: PropTypes.string,
      lastName: PropTypes.string
    })),
    advisors: PropTypes.arrayOf(PropTypes.shape({
      userId: PropTypes.number,
      firstName: PropTypes.string,
      lastName: PropTypes.string
    }))
  })
}

ParticipantSelector.defaultProps = {
  caption: 'Who would you like to speak with?'
}

export default ParticipantSelector
