import React, { useContext, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { merge } from 'lodash'
import { createTheme, ThemeProvider } from '@material-ui/core/styles'
import defaultTheme from '../../theme'
import { useAppConfig, useFirmColors } from '../../redux/slices/appConfig'
import { applyBrand, saveBrand } from '../../applyBrand'
import config from '../../config'

const BrandContext = React.createContext({
  subdomain: config.subdomain
})

export const useSubdomain = () => {
  return useContext(BrandContext)?.subdomain ?? null
}

function BrandProvider ({ children }) {
  const firmColors = useFirmColors()
  const appConfig = useAppConfig()
  useEffect(() => {
    try {
      if (appConfig?.pageConfig?.brand) {
        const brand = appConfig.pageConfig.brand
        applyBrand(brand)
        saveBrand(brand)
      }
    } catch (err) {
      console.error('unable to set branding', err)
    }
  }, [appConfig])

  const theme = useMemo(() => {
    const { cloudBurst, bluePalette, background } = defaultTheme.palette
    const firmOverrides = {
      palette: {
        primary: {
          main: firmColors?.primary || cloudBurst,
          ...(firmColors?.contrastText ? { contrastText: firmColors?.contrastText } : {})
        },
        secondary: {
          main: firmColors?.secondary || bluePalette.main
        },
        accent: {
          main: firmColors?.accent || background.default
        }
      }
    }
    const brandTheme = appConfig?.pageConfig?.brand?.data?.theme

    return createTheme(merge(defaultTheme, firmOverrides, brandTheme))
  }, [firmColors, appConfig])

  const contextValue = useMemo(() => {
    return {
      subdomain: config.subdomain
    }
  }, [])

  return (
    <BrandContext.Provider value={contextValue}>
      <ThemeProvider theme={theme}>
        {children}
      </ThemeProvider>
    </BrandContext.Provider>
  )
}

BrandProvider.propTypes = {
  children: PropTypes.node
}

export default BrandProvider
