import React from 'react'
import { AutoOperationalTableContextProvider } from '../../../organisms/OperationalTable/AutoOperationalTableContext'
import GroupingProvider from '../../../organisms/GroupingProvider'
import ReportListTable from './ReportListTable'

const ReportListContainer = () => {
  return (
    <GroupingProvider configurationKey='bobiResultList'>
      <AutoOperationalTableContextProvider>
        <ReportListTable.Wrapper>
          <ReportListTable.SuperHeader />
          <ReportListTable />
        </ReportListTable.Wrapper>
      </AutoOperationalTableContextProvider>
    </GroupingProvider>
  )
}

export default ReportListContainer
