import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import FadeIn from '../../molecules/Transitions/FadeIn'
import Skeleton from '../../atoms/Skeleton'
import StatusCard from './StatusCard'
import { useStatusCounts } from './hooks'
import { useStatusReportContext } from './statusReportContext'

const useStyles = makeStyles((theme) => ({
  summary: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: '15px',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%'
  }
}))

function StatusReportSummary ({ report, statuses, loading, cardWidth }) {
  const { currentFilter, toggleFilter, config } = useStatusReportContext()
  const counter = useStatusCounts(report, config?.countItemsAt)
  const sorted = useMemo(() => {
    if (loading) return []
    const result = statuses ? [...statuses] : []
    result.sort((a, b) => b.statusId - a.statusId)
    return result
  }, [statuses, loading])
  const classes = useStyles()

  if (loading) {
    return (
      <FadeIn delay={5} duration={5} className={classes.summary}>
        <Skeleton width='100%' height='100%' />
      </FadeIn>
    )
  }

  return (
    <div className={classes.summary}>
      {sorted.map((s, i) => (
        <StatusCard
          selected={currentFilter === s.statusId}
          onClick={() => toggleFilter(s.statusId)}
          key={s.codeName}
          statusInfo={s}
          count={counter(s.statusId)}
          index={i}
          cardWidth={cardWidth}
        />
      ))}
    </div>
  )
}

StatusReportSummary.propTypes = {
  report: PropTypes.shape({
    items: PropTypes.array
  }),
  statuses: PropTypes.arrayOf(PropTypes.shape({
    statusId: PropTypes.number,
    codeName: PropTypes.string,
    displayName: PropTypes.string,
    color: PropTypes.string
  })),
  loading: PropTypes.bool,
  cardWidth: PropTypes.string
}

StatusReportSummary.defaultProps = {
}

export default StatusReportSummary
