/* eslint-disable react/jsx-boolean-value */
import React from 'react'
import PropTypes from 'prop-types'
import { ResponsiveLine } from '@nivo/line'

const commonProperties = {
  animate: true
}

const MyResponsiveLine = (props) => (
  <ResponsiveLine
    {...commonProperties}
    data={props.data}
    margin={{ top: 30, right: 30, bottom: 30, left: 45 }}
    xScale={{
      type: 'time',
      format: '%Y-%m-%d',
      precision: 'day'
    }}
    xFormat='time:  %Y'
    yScale={{
      type: 'linear',
      stacked: false,
      min: 0
    }}
    yFormat='.2s'
    axisLeft={{
      legend: 'portfolio value',
      legendOffset: 12,
      format: '.2s',
      tickValues: 3
    }}
    axisBottom={{
      format: '%Y',
      legend: '',
      legendOffset: -12
    }}
    layers={['grid', 'markers', 'axes', 'areas', 'crosshair', 'lines', 'points', 'slices', 'mesh', 'legends']}
    colors={d => d.color}
    lineWidth={0}
    enableArea={true}
    enableGridX={false}
    pointSize={0}
    gridYValues={3}
    areaOpacity={0.85}
    useMesh={true}
    motionStiffness={175}
    motionDamping={25}
    theme={{
      grid: {
        line: {
          stroke: '#E2E2E2',
          strokeWidth: 1
        }
      },
      axis: {
        legend: {
          text: {
            fontFamily: 'GothamPro',
            fontWeight: 700
          }
        },
        ticks: {
          text: {
            fontFamily: 'GothamPro',
            fontWeight: 700
          }
        }
      }
    }}
  />
)

MyResponsiveLine.propTypes = {
  data: PropTypes.array
}

export default MyResponsiveLine
