import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import isEmpty from 'lodash/isEmpty'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { TEXT_VARIANTS, ASSET_FILTERS, CALC_TYPES, DAYJS_OPERATIONS, DAYJS_UNIT_TYPES, LEVEL_TYPES } from '../../constants'
import { useAppContext } from '../../redux/slices/appContext'
import { getAssetsPerformance } from '../../service'
import { getSafeDate, removeEmptyProps } from '../../utils'
import { getResearchTable } from '../../utils/tableHelper'
import Text from '../atoms/Text'
import CSVButton from '../atoms/CSVButton'
import { useToggle, useCSVData } from '../../hooks'
import Tag from '../atoms/Tag'
import Table from './Table'

dayjs.extend(utc)

const useStyles = makeStyles((theme) => ({
  container: {
    height: '100%',
    display: 'flex',
    flex: '1',
    flexDirection: 'column'
  },
  rootAccordion: {
    boxShadow: 'none',
    backgroundColor: 'white'
  },
  tableContainer: ({ height }) => ({
    height,
    width: '100%'
  }),
  researchTable: {
    width: '100%',
    display: 'flex',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  researchTableHeader: {
    marginTop: '2rem',
    marginBottom: '0.5rem',
    width: '100%',
    display: 'flex',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  downloadButtonContainer: {
    marginTop: '2rem',
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end'
  },
  chevronIcon: {
    width: '24px',
    fontSize: '24px',
    maxHeight: '24px',
    marginRight: '10px'
  },
  toggleContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  accordionSummary: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  title: {
    color: theme.palette.cloudBurst
  }
}))

const getDateRange = (availableDates) => {
  if (isEmpty(availableDates)) {
    return {
      startDate: dayjs.utc().subtract(3, 'month').format('YYYY-MM-DD'),
      endDate: dayjs.utc().subtract(1, 'day').format('YYYY-MM-DD')
    }
  }
  const startDate = getSafeDate(availableDates, {
    operation: DAYJS_OPERATIONS.SUBTRACT,
    unitType: DAYJS_UNIT_TYPES.MONTH,
    unitValue: 6
  })
  const endDate = getSafeDate(availableDates, { useMax: true })
  return { startDate, endDate }
}

const getAssetPerformanceParams = (assetFilters, clientId) => {
  return Object.values(assetFilters).reduce(
    (acc, { id, payload }) => {
      const { classType, assetTree } = payload
      const assetId = assetTree[classType]
      if (classType === ASSET_FILTERS.ASSET_CLASSES) {
        acc.levelTypes.push(LEVEL_TYPES.ASSET_CLASS_TAG)
        acc.assetClassTagIds.push(assetId)
      } else if (classType === ASSET_FILTERS.ASSETS_SUBCLASS) {
        acc.levelTypes.push(LEVEL_TYPES.SUBCLASS_TAG)
        acc.subclassTagIds.push(assetId)
      } else if (classType === ASSET_FILTERS.ASSETS) {
        acc.levelTypes.push(LEVEL_TYPES.ASSETS)
        acc.assetIds.push(assetId)
      }
      return acc
    },
    {
      clientIds: [clientId],
      assetIds: [],
      accountIds: [],
      levelTypes: [LEVEL_TYPES.CLIENT],
      subclassTagIds: [],
      assetClassTagIds: []
    }
  )
}

const ResearchTable = ({ height, assetFilters, allowDownload }) => {
  const classes = useStyles({ height })
  const { clientId } = useAppContext()
  const { availableDates } = useAppContext()
  const [assetsPerformanceData, setAssetsPerformanceData] = useState([])
  const [showTable, toggleShowTable] = useToggle()

  useEffect(() => {
    async function fetchPerformanceData (assetFilters) {
      const { startDate, endDate } = getDateRange(availableDates)
      const params = {
        ...getAssetPerformanceParams(assetFilters, clientId),
        calcType: CALC_TYPES.timeSeries,
        startDate,
        endDate
      }
      const paramsFormatted = removeEmptyProps(params)
      const { data: performanceData } = await getAssetsPerformance(
        paramsFormatted
      )
      setAssetsPerformanceData(performanceData)
    }

    if (showTable) {
      fetchPerformanceData(assetFilters, clientId)
    }
  }, [assetFilters, availableDates, showTable, clientId])

  const { rows, columns } = useMemo(
    () => getResearchTable(assetsPerformanceData),
    [assetsPerformanceData]
  )

  const csvData = useCSVData({
    disabled: !allowDownload,
    rows: isEmpty(rows) ? [] : rows,
    labels: columns
  })

  return (
    <Accordion expanded={showTable} onChange={toggleShowTable} classes={{ root: classes.rootAccordion }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Box className={classes.accordionSummary}>
          <Text
            text='Research Table'
            className={classes.title}
            customFontSize='1rem'
            customFontWeight='bold'
            customFontFamily='Gotham-Book'
            textTransform='uppercase'
            variant={TEXT_VARIANTS.subtitle1}
          />
          {!isEmpty(assetFilters) && (
            <Box ml='1rem' mr='auto'>
              <Tag label='Filtered' />
            </Box>
          )}
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <div className={classes.tableContainer}>
          <div className={classes.container}>
            {allowDownload && !isEmpty(csvData) && <CSVButton data={csvData} />}
            <Table rows={rows} labels={columns} elementType='div' virtualized />
          </div>
        </div>
      </AccordionDetails>
    </Accordion>
  )
}

ResearchTable.propTypes = {
  allowDownload: PropTypes.bool,
  assetFilters: PropTypes.object,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

ResearchTable.defaultProps = {
  allowDownload: false,
  assetFilters: {},
  height: '60rem'
}

export default ResearchTable
