import React from 'react'
import { useParams } from 'react-router-dom'
import { makeStyles } from '@material-ui/core'
import FadeIn from '../../../molecules/Transitions/FadeIn'
import FirmReportSummary from '../../firmReporting/FirmReportSummary'

const useStyles = makeStyles((theme) => ({
  firmReporting: {
    padding: '5px 2rem'
  }
}))

function FirmSummaryView () {
  const classes = useStyles()
  const { reportId } = useParams()

  return (
    <FadeIn className={classes.firmReporting}>
      <FirmReportSummary reportId={reportId} />
    </FadeIn>
  )
}

export default FirmSummaryView
