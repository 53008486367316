import { useEffect, useMemo, useState } from 'react'
import isEmpty from 'lodash/isEmpty'
import { FEATURE_FLAG, REDTAIL_INTEGRATION, SALESFORCE_INTEGRATION, WEALTHBOX_INTEGRATION } from '../constants'
import { useFeatureFlag } from '../redux/slices/appConfig'
import { getActiveCRMFromIntegrationsArray } from '../utils'
import { integrations } from '../service'

const useIntegrations = (config = {}) => {
  const { includeActiveCrmFlag = false } = config

  const {
    active: INTEGRATIONS,
    extras: INTEGRATIONS_LIST
  } = useFeatureFlag(FEATURE_FLAG.INTEGRATIONS)

  const integrationsList = useMemo(
    () => (!isEmpty(INTEGRATIONS_LIST) ? INTEGRATIONS_LIST : { active: [] }),
    [INTEGRATIONS_LIST]
  )

  const [isCrmAuthorized, setIsCrmAuthorized] = useState(false)

  const [activeCrm] = useState(
    INTEGRATIONS
      ? getActiveCRMFromIntegrationsArray(integrationsList.active)
      : null
  )

  useEffect(() => {
    if (activeCrm && includeActiveCrmFlag) {
      integrations.getAuthorizations().then(({ data }) => {
        setIsCrmAuthorized(data[activeCrm]?.authorized || false)
      }).catch(error => {
        console.error('[Failed to fetch authorizations]', error)
      })
    }
  }, [activeCrm, includeActiveCrmFlag])

  return useMemo(() => {
    const { active: integrationsActive } = integrationsList

    const showWealthBoxClientInfo = integrationsActive.includes(
      WEALTHBOX_INTEGRATION
    )

    const showRedTailClientInfo = integrationsActive.includes(
      REDTAIL_INTEGRATION
    )

    const showSalesforceClientInfo = integrationsActive.includes(
      SALESFORCE_INTEGRATION
    )

    const showPractifiClientInfo = integrationsActive.includes('practifi')

    return {
      showWealthBoxClientInfo: showWealthBoxClientInfo && INTEGRATIONS,
      showRedTailClientInfo: showRedTailClientInfo && INTEGRATIONS,
      showSalesforceClientInfo: showSalesforceClientInfo && INTEGRATIONS,
      showPractifiClientInfo: showPractifiClientInfo && INTEGRATIONS,
      isCrmAuthorized
    }
  }, [isCrmAuthorized, INTEGRATIONS, integrationsList])
}

export default useIntegrations
