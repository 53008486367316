import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { useAdvisorInvitations, useInviteUserMutation } from '../../../../../../api/users'
import SydButton from '../../../../../commonDesign/Button'
import Invitations from './Invitations'

const InviteAction = ({ user, status, isLoading }) => {
  const { mutateAsync: inviteUser, isLoading: inviting } = useInviteUserMutation()
  const { data: userInvites, isFetching: invitationsLoading } = useAdvisorInvitations(user.userId)
  const clickHandler = useCallback(async () => {
    try {
      await inviteUser(user)
    } catch (err) {
      console.error(err)
      alert('There was an unexpected issue inviting the user')
    }
  }, [user, inviteUser])

  const disabled = useMemo(() => {
    if (isLoading || invitationsLoading) return true

    switch (status) {
      case 'NOT AVAILABLE':
      case 'ACTIVE':
      case 'RECOVERY':
        return true
      default:
        return false
    }
  }, [status, isLoading, invitationsLoading])

  const description = useMemo(() => {
    if (isLoading) return ''

    switch (status) {
      case 'ACTIVE':
        return 'User is active.'
      case 'NOT AVAILABLE':
      case 'RECOVERY':
        return 'Action is not currently available.'
      default:
        return `Invite ${user.firstName} ${user.lastName} to use the platform. Activation links are valid for 7 days, and one use. New invitations will invalidate old invitations.`
    }
  }, [status, isLoading, user])

  return (
    <div>
      <div className='__row'>
        <div>
          <SydButton
            icon='mail'
            variant='outline'
            size='sm'
            disabled={disabled}
            processing={inviting}
            onClick={clickHandler}
          >
            Invite User
          </SydButton>
        </div>
        <div className={clsx({ __disabled: disabled })}>
          {description}
        </div>
      </div>
      <div>
        <Invitations invitations={userInvites?.invitations} />
      </div>
    </div>
  )
}

InviteAction.propTypes = {
  user: PropTypes.object,
  status: PropTypes.string,
  isLoading: PropTypes.bool
}

export default InviteAction
