import React from 'react'
import PropTypes from 'prop-types'
import { BasicTooltip } from '@nivo/tooltip'

function MarkerTooltip ({ datum }) {
  return (
    <BasicTooltip
      id={datum.id}
      value={datum.formattedValue}
      enableChip
      color={datum.color}
    />
  )
}

MarkerTooltip.propTypes = {
  datum: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    formattedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    color: PropTypes.string
  })
}

export default MarkerTooltip
