import { Box, Grid, makeStyles, MenuItem, TextField } from '@material-ui/core'
import React, { useCallback, useMemo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { KeyboardDatePicker } from '@material-ui/pickers'
import dayjs from 'dayjs'
import quarterOfYear from 'dayjs/plugin/quarterOfYear'
import { noop } from 'lodash'
import clsx from 'clsx'
import { BUTTON_SIZES, FEATURE_FLAG, ICON_NAMES, INTERNAL_DATE_FORMAT } from '../../../../../constants'
import Text from '../../../../atoms/Text'
import Icon from '../../../../atoms/Icon'
import { createBillingRun, getClientFeeAssignments } from '../../../../../service'
import { useBoolean, useFetchState, useSearchAccountsDebounced } from '../../../../../hooks'
import { useAvailableDates } from '../../../../../redux/slices/appContext'
import OperationTable, { useOperationalTable } from '../../../../organisms/OperationalTable'
import { useBillingRuns } from '../../../../../api/billing'
import { useFeatureFlag } from '../../../../../redux/slices/appConfig'
import { ContextContainerProvider } from '../../../../../abundanceEngine/components/ContextContainer'
import useSearchClientsDebounced from '../../../../../hooks/useSearchClientsDebounced'
import { ConfirmButton } from '../../../../molecules/SaveCancelButtons'
import SydSelect from '../../../../commonDesign/SydSelect'
import SydLabel from '../../../../commonDesign/SydLabel'
import {
  ACCOUNT_LEVEL_VALUE,
  BILLING_RUNS_CONTEXT_KEY,
  CLIENT_LEVEL_VALUE,
  columnsConfig,
  FREQUENCY_OPTIONS,
  getPayloadDates,
  LEVEL_OPTIONS,
  REPORT_STATUS,
  RUN_DISPLAY_STATUS,
  RUN_REPORT_DISPLAY_STATUS,
  RUN_STATUS
} from './helpers'
import FormAutocompleteInput from './FormAutocompleteInput.jsx'
import RefreshToggle from './RefreshToggle'

dayjs.extend(quarterOfYear)

const getFrequencyTagName = (frequency) => {
  return FREQUENCY_OPTIONS.find(({ id }) => id === frequency)?.tagName || ''
}

const mapBillingRuns = (billingRuns) => {
  return billingRuns.reduce(
    (
      { billingRuns, hasPendingBillingRuns: _hasPendingBillingRuns },
      billingRun
    ) => {
      const hasPendingBillingRuns = false

      if (hasPendingBillingRuns) {
        return { billingRuns, hasPendingBillingRuns }
      }

      const createdAt = dayjs(billingRun.createdAt).format('MM/D/YY h:mm a')
      const frequency = getFrequencyTagName(billingRun.frequency)

      billingRuns.push({
        ...billingRun,
        createdAt,
        frequency
      })

      return { billingRuns, hasPendingBillingRuns }
    },
    { billingRuns: [], hasPendingBillingRuns: false }
  )
}

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    width: '100%',
    padding: '2rem'
  },
  rowIcons: {
    display: 'flex',
    flexDirection: 'row',
    gap: '2.0rem'
  },
  title: {
    color: '#212945',
    fontSize: '34px',
    fontStyle: 'normal',
    paddingBottom: '1.5rem'
  },
  itemTextField: {
    borderRadius: '4px',
    background: '#FFF',
    border: '2px solid #D7DCE1',
    padding: '0.25rem 0.25rem',
    '& .MuiSelect-select': {
      padding: '0.5rem !important'
    }
  },
  dateField: {
    '& input': {
      padding: '0.5rem'
    }
  },
  searchInput: {
    padding: '0.35rem 0.5rem'
  },
  formControl: {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.625rem',
    transition: 'all 0.3s ease'
  },
  formSubmit: {
    height: '100%',
    display: 'flex',
    alignItems: 'center'
  },
  select: {
    padding: '0.5rem'
  },
  bold: {
    fontWeight: 'bolder',
    fontSize: '0.9rem'
  },
  labelStyle: {
    color: '#A4A4A4',
    fontSize: '0.75rem'
  },
  titleSettings: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'left',
    boxSizing: 'border-box',
    alignItems: 'center',
    color: '#A4A4A4',
    paddingRight: '5px'
  },
  iconClass: {
    marginRight: '1rem',
    backgroundColor: '#F4F5F6',
    color: '#898D9B',
    borderRadius: '50%'
  },
  itemIcon: {
    display: 'flex',
    padding: '1rem',
    paddingRight: '5rem'
  },
  tableHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: '#fff',
    zIndex: 10
  },
  loadingEllipsis: {
    width: '10rem',
    '&::after': {
      display: 'inline-block',
      animation: '$dotty steps(1,end) 2s infinite',
      content: '""'
    }
  },
  '@keyframes dotty': {
    '0%': { content: '""' },
    '25%': { content: '"."' },
    '50%': { content: '".."' },
    '75%': { content: '"..."' },
    '100%': { content: '""' }
  }
}))

const iconProps = {
  iconColor: '#212945'
}

const textAreaProps = { disableUnderline: true }

const billingRunOptions = { mapper: mapBillingRuns }

const maxDate = dayjs().subtract(1, 'day').format(INTERNAL_DATE_FORMAT)

const statusFilterOptions = Object.entries(RUN_STATUS).map(([key, value]) => ({
  value: value,
  label: RUN_DISPLAY_STATUS[key] ?? key
}))

const reportStatusFilterOptions = Object.entries(REPORT_STATUS).map(([key, value]) => ({
  value: value,
  label: RUN_REPORT_DISPLAY_STATUS[key] ?? key
})).filter(x => !['PROCESSED'].includes(x.value))

const RunBilling = () => {
  const classes = useStyles()
  const [availableDates] = useAvailableDates()
  const [isLoading, setIsLoading] = useBoolean()
  const { active: BILLING_BILL_NEGATIVE_AS_ZERO } = useFeatureFlag(
    FEATURE_FLAG.BILLING_BILL_NEGATIVE_AS_ZERO
  )

  const { pageIndex, pageSize, defaultPageSize, onPagingChange } =
    useOperationalTable({
      defaultSort: columnsConfig.defaultSort,
      defaultPageSize: 50
    })

  const [statusFilters, setStatusFilters] = useState([
    RUN_STATUS.ACTIVE,
    RUN_STATUS.IN_REVIEW,
    RUN_STATUS.MARK_AS_FINAL
  ])
  const [reportStatusFilters, setReportStatusFilters] = useState([])

  const query = useMemo(
    () => {
      return {
        take: pageSize,
        skip: pageIndex * pageSize,
        includes: {
          runBy: true,
          files: true,
          adjustmentStatus: true
        },
        sort: [{ field: 'createdAt', dir: 'desc' }],
        filters: {
          status: statusFilters?.map(filter => ({ op: 'eq', value: filter, combine: 'or' })),
          reportStatus: reportStatusFilters?.map(filter => ({ op: 'eq', value: filter, combine: 'or' }))
        }
      }
    },
    [statusFilters, reportStatusFilters, pageSize, pageIndex]
  )

  const {
    data: { billingRuns = [], hasPendingBillingRuns } = {},
    refetch: refetchBillingRuns,
    isLoading: isLoadingBillingRuns,
    isFetching: isFetchingBillingRuns
  } = useBillingRuns(query, billingRunOptions)

  const {
    watch,
    control,
    setValue,
    handleSubmit,
    formState: { errors }
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      level: 'client',
      frequency: 'Q',
      type: 'standard',
      clients: [],
      accounts: [],
      asOfDate: dayjs.utc().subtract(1, 'quarter').endOf('quarter')
    }
  })

  const fetchClientList = useCallback(async (setSafeState) => {
    try {
      const { data } = await getClientFeeAssignments()
      setSafeState({ totalClients: data.length })
    } catch (error) {
      console.error('fetchClientFeeAssignments')
      console.log(error)
    }
  }, [])

  const { totalClients } = useFetchState(fetchClientList)

  const onSubmitHandler = useCallback(
    async (values) => {
      try {
        setIsLoading.on()
        const { startDate, endDate } = getPayloadDates(
          values.asOfDate,
          values.frequency
        )

        const clientIds = values.clients?.length
          ? values.clients.map((client) => client.value)
          : null

        const accountIds = values.accounts?.length
          ? values.accounts.map((account) => account.value)
          : null

        const calcType = values.level.includes('client') ? 'client' : 'account'

        const payload = {
          asOfDate: values.asOfDate,
          levelFilters: {
            calcType,
            billingFrequency: values.frequency,
            billNegativeAsZero: BILLING_BILL_NEGATIVE_AS_ZERO,
            ...(clientIds ? { clientIds } : {}),
            ...(accountIds ? { accountIds } : {})
          },
          dateRange: {
            startDate,
            endDate
          }
        }
        await createBillingRun(payload)
        refetchBillingRuns()
      } catch (err) {
        console.error(err)
      } finally {
        setIsLoading.off()
      }
    },
    [setIsLoading, refetchBillingRuns, BILLING_BILL_NEGATIVE_AS_ZERO]
  )

  const asOfDate = watch('asOfDate')
  const level = watch('level')

  const renderAsOfDateControl = useCallback(
    ({ field: { onChange } }) => {
      return (
        <KeyboardDatePicker
          onChange={onChange}
          value={asOfDate}
          InputProps={textAreaProps}
          className={clsx(classes.itemTextField, classes.dateField)}
          InputAdornmentProps={{ position: 'start' }}
          maxDate={maxDate}
          minDate={availableDates.min}
        />
      )
    },
    [asOfDate, availableDates.min, classes.dateField, classes.itemTextField]
  )

  const billingContext = useMemo(
    () => ({
      name: BILLING_RUNS_CONTEXT_KEY,
      initialState: { refetchBillingRuns }
    }),
    [refetchBillingRuns]
  )

  const searchParams = useMemo(() => {
    return {
      searchClientParams: {
        queryOptions: { enabled: level === CLIENT_LEVEL_VALUE }
      },
      searchAccountParams: {
        queryOptions: { enabled: level === ACCOUNT_LEVEL_VALUE }
      }
    }
  }, [level])

  const {
    options: clients = [],
    isLoading: isLoadingClients,
    onChangeQuery: onChangeClientsQuery
  } = useSearchClientsDebounced(searchParams.searchClientParams)

  const {
    options: accounts = [],
    isLoading: isLoadingAccounts,
    onChangeQuery: onChangeAccountsQuery
  } = useSearchAccountsDebounced(searchParams.searchAccountParams)

  return (
    <ContextContainerProvider context={billingContext}>
      <Grid container className={classes.root} spacing={2}>
        <Grid item xs={12}>
          <Text text='Run Billing' className={classes.title} variant='h1' />
        </Grid>

        <Grid item xs={12}>
          <form onSubmit={handleSubmit(onSubmitHandler)}>
            <Grid container spacing={4}>
              <Grid item xs={12} md={6} lg={2}>
                <div className={classes.formControl}>
                  <Text text='Level' variant='h3' />
                  <Controller
                    name='level'
                    control={control}
                    defaultValue='client'
                    render={({ field: { onChange } }) => (
                      <TextField
                        select
                        fullWidth
                        className={classes.itemTextField}
                        InputProps={textAreaProps}
                        onChange={onChange}
                        defaultValue='client'
                      >
                        {LEVEL_OPTIONS.map(({ tagName, id }) => (
                          <MenuItem key={id} value={id}>
                            {tagName}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                  />
                  <label>
                    <strong>{totalClients}</strong>
                    <span className={classes.labelStyle}>
                      &nbsp;Total Clients
                    </span>
                  </label>
                </div>
              </Grid>

              <Grid item xs={12} md={6} lg={2}>
                <div className={classes.formControl}>
                  <Text text='Frequency' variant='h3' />
                  <Controller
                    name='frequency'
                    render={({ field: { onChange } }) => (
                      <TextField
                        select
                        className={classes.itemTextField}
                        InputProps={textAreaProps}
                        fullWidth
                        onChange={onChange}
                        defaultValue='Q'
                      >
                        {FREQUENCY_OPTIONS.map(({ tagName, id }) => (
                          <MenuItem key={id} value={id}>
                            {tagName}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                    defaultValue='Q'
                    control={control}
                  />
                </div>
              </Grid>

              {level === CLIENT_LEVEL_VALUE && (
                <Grid item xs={12} md={6} lg={4}>
                  <div className={classes.formControl}>
                    <Text text='Clients' variant='h3' />
                    <FormAutocompleteInput
                      name='clients'
                      placeholder='Search clients'
                      control={control}
                      errors={errors}
                      options={clients}
                      isLoading={isLoadingClients}
                      onInputChange={onChangeClientsQuery}
                      className={classes.itemTextField}
                      classes={{ root: classes.searchInput }}
                      // autocomplete by default filters options by input value, since
                      // we´re doing a server side search disabling that functionality by just returning the options
                      filterOptions={(options) => options}
                      getOptionLabel={(option) => option.label}
                      getOptionSelected={(opt, val) => opt.value === val.value}
                      renderOption={(option) => <Text text={option.label} />}
                    />
                  </div>
                </Grid>
              )}
              {level === ACCOUNT_LEVEL_VALUE && (
                <Grid item xs={12} md={6} lg={4}>
                  <div className={classes.formControl}>
                    <Text text='Accounts' variant='h3' />
                    <FormAutocompleteInput
                      name='accounts'
                      placeholder='Search accounts'
                      control={control}
                      errors={errors}
                      options={accounts}
                      isLoading={isLoadingAccounts}
                      onInputChange={onChangeAccountsQuery}
                      className={classes.itemTextField}
                      classes={{ root: classes.searchInput }}
                      filterOptions={(options) => options}
                      getOptionLabel={(option) => option.label}
                      getOptionSelected={(opt, val) => opt.value === val.value}
                      renderOption={(option) => <Text text={option.label} />}
                    />
                  </div>
                </Grid>
              )}

              <Grid item xs={12} md={6} lg={2}>
                <div className={classes.formControl}>
                  <Text text='As of date' variant='h3' />
                  <Controller
                    name='asOfDate'
                    control={control}
                    value={asOfDate}
                    onChange={(_, date) => {
                      setValue('asOfDate', date)
                      return { value: date }
                    }}
                    render={renderAsOfDateControl}
                  />
                </div>
              </Grid>

              <Grid item xs={12} md={6} lg={2}>
                <div className={classes.formSubmit}>
                  <ConfirmButton
                    isLoading={isLoading}
                    disabled={isLoading}
                    size={BUTTON_SIZES.medium}
                    label='Run Billing'
                  />
                </div>
              </Grid>
            </Grid>

            <Box hidden>
              <div className={classes.titleSettings}>
                <Text
                  text='Default Settings'
                  variant='h3'
                  className={classes.titleSettings}
                />
                <Icon
                  additionalClasses=''
                  name={ICON_NAMES.info}
                  color={iconProps.iconColor}
                />
              </div>

              <div className={classes.rowIcons}>
                <div className={classes.itemIcon}>
                  <Icon
                    additionalClasses={classes.iconClass}
                    name={ICON_NAMES.draftDollar}
                    color={iconProps.iconColor}
                    customSize='1.2rem'
                  />
                  <label className={classes.bold}>Value</label>
                  <label className={classes.labelStyle}>
                    &nbsp;End of Period
                  </label>
                </div>
                <div className={classes.itemIcon}>
                  <Icon
                    additionalClasses={classes.iconClass}
                    name={ICON_NAMES.history}
                    color={iconProps.iconColor}
                    customSize='1.2rem'
                  />
                  <label className={classes.bold}>Time Frame</label>
                  <label className={classes.labelStyle}>&nbsp;In Advance</label>
                </div>
              </div>
            </Box>
          </form>
        </Grid>

        <Grid item xs={12}>
          <OperationTable.Wrapper>
            <OperationTable.SuperHeader className={classes.tableHeader}>
              <RefreshToggle
                loading={isLoadingBillingRuns || isFetchingBillingRuns}
                onRefetch={refetchBillingRuns}
                intervalInSeconds={10}
              />
              <Box display='flex' gridGap='8px'>
                <SydLabel label='Filter Status'>
                  <SydSelect
                    size='sm'
                    placeholder='All statuses'
                    options={statusFilterOptions}
                    value={statusFilters}
                    multiple
                    onChange={(e) => {
                      setStatusFilters(e.target.value)
                    }}
                  />
                </SydLabel>
                <SydLabel label='Filter Report State'>
                  <SydSelect
                    size='sm'
                    multiple
                    defaultValue={[]}
                    value={reportStatusFilters}
                    options={reportStatusFilterOptions}
                    onChange={(e) => setReportStatusFilters(e.target.value)}
                  />
                </SydLabel>
              </Box>
            </OperationTable.SuperHeader>
            <OperationTable
              mode='server'
              columns={columnsConfig.columns}
              data={billingRuns}
              defaultPageSize={defaultPageSize}
              defaultSort={columnsConfig.defaultSort}
              itemName='Billing Runs'
              loading={isLoadingBillingRuns || hasPendingBillingRuns || isFetchingBillingRuns}
              total={billingRuns.length}
              onSortingChange={noop}
              onPagingChange={onPagingChange}
            />
          </OperationTable.Wrapper>
        </Grid>
      </Grid>
    </ContextContainerProvider>
  )
}

export default RunBilling
