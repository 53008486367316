import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import Select from '../../../../molecules/Select'
import SydButton from '../../../../commonDesign/Button'

const useStyles = makeStyles((theme) => ({
  targetModelAssignments: {
    tableLayout: 'auto',
    '& th': {
      backgroundColor: theme.palette.gray.main,
      padding: '10px'
    },
    '& td': {
      padding: '10px',
      minWidth: '300px'
    },
    '& td:not(:first-child)': {
      padding: '10px',
      minWidth: '400px'
    },
    '& .__not-assigned': {
      color: theme.palette.gray.darker
    },
    '& .__remove-button': {
      textTransform: 'none'
    },
    '& .__select-option': {
      minWidth: '300px'
    },
    '& .__select-group': {
      display: 'flex',
      flexDirection: 'row',
      gap: '20px'
    }
  },
  assignment: {
    lineHeight: '50px',
    '& > *:first-child': {
      backgroundColor: theme.palette.gray.main
    }
  },
  assignmentPurpose: {
    fontSize: theme.typography.fontSizes.xl,
    fontWeight: theme.typography.weights.semibold
  },
  value: {
    fontSize: theme.typography.fontSizes.xl,
    fontWeight: theme.typography.weights.light
  },
  fieldWrapper: {
    display: 'flex',
    flexDirection: 'row',
    gap: '10px'
  }
}))

function modifyTargetModelId (purposeId, value) {
  return prev => {
    const index = prev.assignments.findIndex(x => x.purposeId === purposeId)
    const newAssignments = [...prev.assignments]
    newAssignments[index] = { ...newAssignments[index], targetModelId: value }

    return { assignments: newAssignments }
  }
}

const useFormValues = (assignments = [], editing) => {
  const defaultValues = useMemo(() => {
    return {
      assignments
    }
  }, [assignments])

  const [formValues, setFormValues] = useState(defaultValues)
  useEffect(() => {
    setFormValues(defaultValues)
  }, [setFormValues, defaultValues, editing])

  const getFormValue = useCallback((purposeId) => {
    return formValues.assignments.find(x => x.purposeId === purposeId)?.targetModelId
  }, [formValues])

  const setFormValue = useCallback((purposeId) => value => {
    setFormValues(modifyTargetModelId(purposeId, value))
  }, [setFormValues])

  const clearFormValue = useCallback((purposeId) => () => {
    setFormValues(modifyTargetModelId(purposeId, null))
  }, [setFormValues])

  return {
    formValues,
    setFormValues,
    getFormValue,
    setFormValue,
    clearFormValue
  }
}

const TargetAssignmentTable = forwardRef(function TargetAssignmentTable ({ assignments, options, editing }, ref) {
  const classes = useStyles()
  const { formValues, setFormValue, clearFormValue } = useFormValues(assignments, editing)

  useImperativeHandle(ref, () => {
    return {
      formValues
    }
  }, [formValues])

  return (
    <table className={classes.targetModelAssignments}>
      <thead>
        <tr>
          <th>Model Purpose</th>
          <th>Assigned Target Model</th>
        </tr>
      </thead>
      <tbody>
        {(formValues.assignments).map(t => (
          <tr className={classes.assignment} key={t.purposeId}>
            <td className={classes.assignmentPurpose}>{t.purposeName}</td>
            <td className={classes.value}>
              <div className={classes.fieldWrapper}>
                <Select
                  fullWidth
                  variant='outlined-rounded'
                  readOnly={!editing}
                  options={options}
                  value={t.targetModelId}
                  onChange={setFormValue(t.purposeId)}
                />
                {editing ? (
                  <div>
                    <SydButton
                      icon='close'
                      variant='outline'
                      size='sm'
                      onClick={clearFormValue(t.purposeId)}
                    >
                      Clear
                    </SydButton>
                  </div>
                ) : null}
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  )
})

TargetAssignmentTable.propTypes = {
  assignments: PropTypes.array,
  options: PropTypes.array,
  editing: PropTypes.bool
}

TargetAssignmentTable.defaultProps = {
  assignments: [],
  options: [],
  editing: false
}

export default TargetAssignmentTable
