import React from 'react'
import { makeStyles } from '@material-ui/core'
import ProfilePicture from './ProfilePicture'
import HeaderInfo from './HeaderInfo'

const useStyles = makeStyles((theme) => ({
  detailHeader: {
    display: 'flex',
    flexDirection: 'row',
    gap: '50px',
    marginBottom: '15px'
  }
}))

function WealthOwnerDetailsHeader () {
  const classes = useStyles()

  return (
    <header className={classes.detailHeader}>
      <ProfilePicture />
      <HeaderInfo />
    </header>
  )
}

export default WealthOwnerDetailsHeader
