import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import 'react-virtualized/styles.css'
import { makeStyles } from '@material-ui/core'
import CardListContainer from '../components/CardListContainer'
import { useContextContainer } from '../../../../abundanceEngine/components/ContextContainer'
import { CLIENT_VIEW_CONTEXT } from '..'
import ClientSearchBar from '../components/ClientSearchBar'
import Text from '../../../atoms/Text'
import ClientGroupTagFilters from '../components/ClientGroupTagFilters'
import ClientCardList from './ClientCardList'
import ClientCardRecentInteractions from './ClientCardRecentInteractions'

const useStyles = makeStyles(() => ({
  tagFiltersContainer: {
    marginTop: '1rem',
    paddingBottom: '0.5rem'
  },
  header: {
    '& .__title': {
      fontSize: '1.25rem',
      fontWeight: 'bold'
    }
  },
  filters: {
    top: 0,
    zIndex: 1,
    background: '#FFF',
    marginTop: '1rem',
    position: 'sticky',
    '& .__search-input': {
      marginBottom: '0.5rem'
    }
  }
}))

const ClientCardView = ({ columnWidth, pageSize, rowHeight }) => {
  const classes = useStyles()

  const [{ hideClientCardTagFilters, disableClientCardTagFilters }] =
    useContextContainer(CLIENT_VIEW_CONTEXT)

  const header = useMemo(() => {
    return (
      <>
        <ClientCardRecentInteractions />
        <div className={classes.header}>
          <Text text='All Clients' className='__title' />
        </div>
        <div className={classes.filters}>
          <div className='__search-input'>
            <ClientSearchBar />
          </div>
          {!hideClientCardTagFilters && (
            <div className={classes.tagFiltersContainer}>
              <ClientGroupTagFilters disabled={disableClientCardTagFilters} />
            </div>
          )}
        </div>
      </>
    )
  }, [
    classes.header,
    classes.filters,
    classes.tagFiltersContainer,
    disableClientCardTagFilters,
    hideClientCardTagFilters
  ])

  return (
    <>
      <CardListContainer>
        {({ width, height, containerRef }) => {
          const columnCount = Math.floor(width / columnWidth)
          return (
            <ClientCardList
              headerElement={header}
              containerRef={containerRef}
              headerComponent={header}
              height={height}
              width={width}
              pageSize={pageSize}
              rowHeight={rowHeight}
              columnCount={columnCount}
              columnWidth={columnWidth}
            />
          )
        }}
      </CardListContainer>
    </>
  )
}

ClientCardView.propTypes = {
  pageSize: PropTypes.number,
  rowHeight: PropTypes.number,
  columnWidth: PropTypes.number
}

ClientCardView.defaultProps = {
  pageSize: 100,
  rowHeight: 287,
  columnWidth: 262
}

export default ClientCardView
