import React from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@material-ui/core'
import Avatar from '../../../../atoms/Avatar'
import TaskDetailsTable from './TaskDetailsTable'
import { groupByOwnerColumnConfig } from './columnConfigs'

const OwnerTasks = ({ owner, type, take }) => {
  if (type === 'summary') {
    return (
      <div style={{ width: '150px', margin: '.5rem' }}>
        <div
          style={{
            display: 'flex',
            marginBottom: '.2rem',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          <Avatar
            src={owner.photoUrl}
            avatarLetters={owner.firstName ?? ''}
            customSize='64px'
            isPublicResource
          />
          <div style={{ alignSelf: 'center', marginTop: '.5rem' }}>
            <div
              style={{
                fontSize: '1rem',
                fontWeight: '400',
                textAlign: 'center'
              }}
            >
              {owner.firstName} {owner.lastName}
            </div>
            <div
              style={{
                fontSize: '0.75rem',
                color: '#999999',
                textAlign: 'center'
              }}
            >
              {owner.taskCount} {`${owner?.taskCount === 1 ? 'Task' : 'Tasks'}`}
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <Grid key={owner.ownerId} style={{ width: '450px', margin: '1rem' }}>
      <Grid style={{ display: 'flex', marginBottom: '1rem' }}>
        <Avatar
          src={owner.photoUrl}
          avatarLetters={owner.firstName ?? ''}
          customSize='64px'
          isPublicResource
        />
        <div style={{ alignSelf: 'center', marginLeft: '1rem' }}>
          <div style={{ fontSize: '1.5rem', fontWeight: '700' }}>{owner.firstName} {owner.lastInitial}.</div>
          <div style={{ fontSize: '0.75rem', color: '#999999' }}>{owner.hasMoreTasks ? `Showing ${take} tasks of ${owner.taskCount}` : ''}</div>
        </div>
      </Grid>
      <TaskDetailsTable data={owner.tasks} columns={groupByOwnerColumnConfig} />
    </Grid>
  )
}

OwnerTasks.propTypes = {
  owner: PropTypes.object.isRequired,
  type: PropTypes.oneOf(['summary', 'details']),
  take: PropTypes.number.isRequired
}

OwnerTasks.defaultProps = {
  type: 'details'
}

export default OwnerTasks
