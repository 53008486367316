import React, { useMemo, useRef } from 'react'
import { Grid } from '@material-ui/core'
import PropTypes from 'prop-types'
import FadeIn from '../../../molecules/Transitions/FadeIn'
import SydButton from '../../../commonDesign/Button'
import ChangePasswordDialog from './ChangePasswordDialog'

const authenticatorKey = 'okta_password'

function PasswordFactor ({ authenticators }) {
  const dialogRef = useRef()
  const { allowed, canReset, authenticator, enrollment } = useMemo(() => {
    if (!authenticators) return { allowed: false }

    const authenticator = authenticators.find(x => x.key === authenticatorKey)
    if (!authenticator) return { allowed: false }

    const enrollment = authenticator._embedded.enrollments.at(0)
    return {
      allowed: authenticator.enrollable,
      canReset: enrollment.canReset,
      canUnenroll: enrollment.canUnenroll,
      authenticator,
      enrollment
    }
  }, [authenticators])

  if (!allowed) return null

  return (
    <>
      <Grid item xs={12} md={4}>
        <FadeIn className='__factor-card'>
          <div className='__factor-header'>
            <div className='__factor-name'>Password</div>
            <div className='__factor-description'>Change your password by clicking the <strong>Change Password</strong> button.</div>
          </div>
          <div className='__factor-action'>
            <SydButton variant='primary' size='md' disabled={!canReset} onClick={() => dialogRef.current.open()}>Change Password</SydButton>
          </div>
        </FadeIn>
      </Grid>
      <ChangePasswordDialog ref={dialogRef} authenticator={authenticator} enrollment={enrollment} />
    </>
  )
}

PasswordFactor.propTypes = {
  authenticators: PropTypes.array
}

export default PasswordFactor
