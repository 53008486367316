import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import OperationalTable, { useOperationalTable } from '../OperationalTable'
import { useQueryPlatformDataSource } from '../../../api/ingestion'
import { useAppContext } from '../../../redux/slices/appContext'

const usePlatformDataSource = (platformDataSourceId, query, clientContextKey) => {
  const { clientId } = useAppContext()
  const resolvedQuery = useMemo(() => {
    const qr = {
      platformDataSourceId,
      options: {
        flatten: true
      },
      ...(query ?? {})
    }

    if (clientContextKey) {
      qr.filters = qr.filters || []
      qr.filters.push({
        field: clientContextKey,
        op: 'eq',
        value: clientId
      })
    }

    return qr
  }, [platformDataSourceId, query, clientContextKey, clientId])
  const { data, isLoading, error } = useQueryPlatformDataSource(resolvedQuery)

  return {
    data,
    isLoading,
    error
  }
}

const useColumns = (columns, defaultSort) => {
  const result = {}
  result.columns = columns.map(col => {
    return {
      ...col,
      Header: col.header,
      header: undefined
    }
  }, [columns])

  result.defaultSort = defaultSort || []

  return result
}

const PlatformDataSourceTable = ({
  platformDataSourceId,
  query,
  columns,
  defaultSort,
  clientContextKey
}) => {
  const { data, isLoading } = usePlatformDataSource(platformDataSourceId, query, clientContextKey)
  const columnConfig = useColumns(columns, defaultSort)
  const { onSortingChange } = useOperationalTable({ defaultSort: columnConfig.defaultSort || [] })

  return (
    <div>
      <OperationalTable.Wrapper>
        <OperationalTable
          loading={isLoading}
          mode='client'
          columns={columnConfig.columns || []}
          defaultSort={columnConfig.defaultSort || []}
          data={data || []}
          itemName='Records'
          onSortingChange={onSortingChange}
          hideFooter
        />
      </OperationalTable.Wrapper>
    </div>
  )
}

PlatformDataSourceTable.propTypes = {
  platformDataSourceId: PropTypes.number,
  query: PropTypes.object,
  columns: PropTypes.array,
  defaultSort: PropTypes.array,
  clientContextKey: PropTypes.string
}

PlatformDataSourceTable.defaultProps = {
  query: {},
  columns: [],
  defaultSort: [],
  useClientContext: false
}

export default PlatformDataSourceTable
