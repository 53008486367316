import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useMemo } from 'react'
import Select from '../../../molecules/Select'
import { BUTTON_SIZES } from '../../../../constants'
import { useChartOptions } from './ChartOptionsProvider'

function SeriesSelect ({
  options
}) {
  const _opt = useMemo(() => {
    return options.map(opt => ({
      label: opt.label,
      value: opt.id
    }))
  }, [options])
  const { options: values, setOptions } = useChartOptions()

  // We are only setting the id for the selected series
  const setValue = useCallback((value) => {
    setOptions({
      seriesId: value
    })
  }, [setOptions])

  // We useEffect the derived values for the selected ids
  const selected = useMemo(() => {
    return options?.find(x => x.id === values?.seriesId)
  }, [options, values?.seriesId])

  // I dislike this, but I can't think of a better way atm
  useEffect(() => {
    setOptions({
      series: selected?.value,
      valueFormat: selected?.valueFormat,
      tooltipValueFormat: selected?.tooltipValueFormat
    })
  }, [selected, setOptions, values?.seriesId])

  return (
    <Select
      size={BUTTON_SIZES.small}
      value={values?.seriesId}
      onChange={setValue}
      readOnly={_opt.length <= 1}
      options={_opt}
    />
  )
}

SeriesSelect.propTypes = {
  options: PropTypes.array
}

SeriesSelect.defaultProps = {
  options: [
    {
      id: 'marketValue',
      label: 'Market Value',
      valueFormat: '$0.0a',
      tooltipValueFormat: '$0,0.00',
      value: [
        {
          id: 'cumulativeNetAdditions',
          label: 'Net Additions',
          accessor: 'cumulativeNetAdditions',
          color: '#49bd9e',
          strokeWidth: 4,
          options: {}
        },
        {
          id: 'endingValue',
          label: 'Market Value',
          accessor: 'endingValue',
          strokeWidth: 4,
          color: '#2c8186',
          areaOpacity: 0.9,
          fill: '#2c8186',
          options: {
            enableArea: true
          },
          tooltip: {
            xFormat: '@MM/DD/YYYY',
            yFormat: '$0,0.00'
          }
        }
      ]
    },
    {
      id: 'returns',
      label: 'Returns',
      valueFormat: 'returns',
      tooltipValueFormat: '0.00%',
      value: [{
        id: 'cumulativeReturn',
        label: 'Returns',
        accessor: 'cumulativeReturn',
        color: '#2264aa',
        strokeWidth: 4,
        options: {}
      }]
    },
    {
      id: 'benchmarks',
      label: 'Returns with Benchmarks',
      valueFormat: 'returns',
      tooltipValueFormat: '0.00%',
      value: [{
        id: 'cumulativeReturn',
        label: 'Returns',
        accessor: 'cumulativeReturn',
        color: '#2264aa',
        strokeWidth: 4,
        options: {}
      }, {
        accessor: 'benchmarks',
        explode: {
          label: 'benchmarkName',
          color: 'benchmarkColor',
          value: 'cumulativeBenchmark'
        },
        strokeWidth: 4,
        options: {}
      }]
    }
  ],
  defaultValue: 'marketValue'
}

export default SeriesSelect
