import React, { useCallback, useRef, useState } from 'react'
import papa from 'papaparse'
import { makeStyles, CircularProgress } from '@material-ui/core'
import { Redirect } from 'react-router-dom'
import dayjs from 'dayjs'
import SydButton from '../../../../commonDesign/Button'
import FadeIn from '../../../../molecules/Transitions/FadeIn'
import PersonalSpace from '../../shared/PersonalSpace'
import EmptySection from '../../../../atoms/EmptySection'
import { usePolicy } from '../../../../../hooks/usePolicy'
import { useCreateManualPositionBatchMutation } from '../../../../../api/accounts'
import InputTable from './InputTable'

import useOfflineExport from './useOfflineExport'

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '1rem'
  },
  featureUpload: {
    border: `1px solid ${theme.palette.gray.darker}`,
    borderRadius: '8px',
    padding: '10px 20px',
    display: 'flex',
    flexDirection: 'row',
    gap: '20px',
    justifyContent: 'space-between'
  },
  instructions: {
    fontWeight: theme.typography.weights.light
  },
  errors: {
    color: theme.palette.danger.main
  },
  warnings: {
    color: theme.palette.warning.main
  },
  right: {
    display: 'flex',
    justifyContent: 'row',
    gap: '10px',
    height: '100%'
  },
  hero: {
    fontSize: theme.typography.fontSizes.h3,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '10px'
  },
  exportActions: {
    display: 'flex',
    alignItems: 'center',
    gap: '1rem',
    padding: '1rem 0'
  }
}))

function normalize (file, data) {
  const columns = data.meta.fields ?? []

  return {
    name: file.name,
    columns,
    data: data.data
  }
}

function validateFile (file) {
  if (!file) return null
  if (file.type !== 'text/csv') {
    return [`Invalid file type ${file.type}. Must provide a CSV.`]
  }
  return null
}

const requiredHeaders = ['accountNumber', 'accountName', 'assetName', 'assetIdentifier', 'valueDate', 'endingValue']
const optionalHeaders = ['additions', 'withdrawals']

function getContentErrors (file, data) {
  const errors = []

  const columns = data.meta.fields ?? []
  if (!columns.length) {
    errors.push('No column headers')
  }

  requiredHeaders.forEach(rh => {
    if (!columns.includes(rh)) {
      errors.push(`Required column '${rh}' is missing`)
    }
  })

  return errors
}

function getContentWarnings (file, data) {
  const warnings = []

  const columns = data.meta.fields ?? []
  columns.forEach(c => {
    if (!requiredHeaders.includes(c) && !optionalHeaders.includes(c) && !c.startsWith('tag_') && !c.startsWith('group_')) {
      warnings.push(`Column '${c}' will be ignored`)
    }
  })

  return warnings
}

function NewBatch () {
  const classes = useStyles()
  const fileRef = useRef()
  const [disabled, setDisabled] = useState(false)
  const [data, setData] = useState()
  const [errors, setErrors] = useState()
  const [warnings, setWarnings] = useState()
  const [isExporting, setIsExporting] = useState(false)

  const reset = useCallback(() => {
    fileRef.current.value = null
    setData(null)
    setErrors(null)
    setWarnings(null)
    setDisabled(false)
  }, [fileRef, setData, setErrors, setWarnings])

  const onFileChange = useCallback(() => {
    setErrors(null)
    setWarnings(null)
    const file = fileRef.current.files[0]
    const errors = validateFile(file)
    if (errors?.length) {
      setErrors(errors)
      return
    }

    papa.parse(file, {
      skipEmptyLines: true,
      header: true,
      complete: (results) => {
        const contentErrors = getContentErrors(file, results)
        const contentWarnings = getContentWarnings(file, results)
        if (contentErrors?.length) {
          setErrors(contentErrors)
        }
        if (contentWarnings?.length) {
          setWarnings(contentWarnings)
        }
        setData(normalize(file, results))
      }
    })
  }, [fileRef, setData, setErrors, setWarnings])

  const {
    progress,
    createBatch,
    isLoading
  } = useCreateManualPositionBatchMutation()

  const submit = useCallback(async () => {
    const filteredData = data.data.map(item => ({
      accountNumber: item.accountNumber,
      accountName: item.accountName,
      assetName: item.assetName,
      assetIdentifier: item.assetIdentifier,
      valueDate: item.valueDate,
      endingValue: item.endingValue,
      additions: item.additions === '' ? null : item.additions,
      withdrawals: item.withdrawals === '' ? null : item.withdrawals,
      data: Object.entries(item).filter(([key, value]) => key.startsWith('tag_') || key.startsWith('group_')).reduce((prev, [key, value]) => {
        prev[key] = value === '' ? null : value
        return prev
      }, {})
    }))
    try {
      await createBatch({
        name: data.name,
        data: filteredData,
        tags: data.columns.filter(x => x.startsWith('tag_')).map(x => x.replace('tag_', '')),
        groups: data.columns.filter(x => x.startsWith('group_') && !x.endsWith('_ordinal')).map(x => x.replace('group_', ''))
      })
    } catch (err) {
      console.error(err)
      alert('There was a problem uploading the batch')
    }
  }, [data, createBatch])

  const { onExport: exportManualAccounts } = useOfflineExport({
    fileName: `account_export_${dayjs().format('YYYY_MM_DD')}`,
    onError: err => console.log('error exporting', err),
    beforeExport: () => setIsExporting(true),
    afterExport: () => setIsExporting(false)
  })

  // const exportManualAccounts = useCallback(async () => {
  //   try {
  //
  //   } catch (err) {
  //     console.error(err)
  //     alert('There was a problem exporting the batch')
  //   }
  // }, [])

  const canAccess = usePolicy('admin_manual_accounts')

  if (!canAccess) {
    return (
      <FadeIn className={classes.container}>
        <EmptySection
          title='Access Denied'
          description='You do not have sufficient permissions to access this resource'
        />
      </FadeIn>
    )
  }

  if (progress.batchId) {
    return (
      <Redirect to={`/admin/manual-accounts/batch/${progress.batchId}`} />
    )
  }

  if (isLoading) {
    return (
      <FadeIn className={classes.container}>
        <div className={classes.featureUpload}>
          <div className={classes.hero}>
            <CircularProgress />
            <span>{progress.status}</span>
          </div>
        </div>
      </FadeIn>
    )
  }

  return (
    <FadeIn className={classes.container}>
      <div className={classes.featureUpload}>
        <div className={classes.left}>
          <div className={classes.instructions}>
            <p>
              <b>Instructions</b>
            </p>
            <ul>
              <li>Accepted file type: <b>.csv</b></li>
              <li>First row must be column names</li>
              <li>Required
                columns: <b>accountNumber</b>, <b>accountName</b>, <b>assetName</b>, <b>assetIdentifier</b>, <b>valueDate</b>, <b>endingValue</b>
              </li>
              <li>Optional columns: <b>additions</b>, <b>withdrawals</b>, <b>tag columns</b>, <b>group columns</b></li>
              <li>Tag Columns: must be named <b>tag_<em>name</em></b> - where name is the classification tag identifier
              </li>
              <li>Group Columns: must be named <b>group_<em>name</em></b> - where name is the group type identifier</li>
              <li>(Optional) Group Columns can specify ordinals: must be named <b>group_<em>name</em>_ordinal</b> -
                where name is the group type identifier
              </li>
            </ul>
          </div>
          <input type='file' ref={fileRef} onChange={onFileChange} accept='.csv' disabled={disabled || isExporting} />
          <div className={classes.exportActions}>
            <SydButton
              size='sm'
              variant='primary'
              disabled={errors?.length || disabled || isExporting}
              onClick={exportManualAccounts}
            >
              {!isExporting ? 'Export Manual Accounts' : 'Exporting please wait...'}
            </SydButton>
            {isExporting && (
              <>
                <CircularProgress />
                <span>{progress.status}</span>
              </>
            )}
          </div>
          {errors?.length ? (
            <div className={classes.errors}>
              <ul>
                {errors.map((err, i) => (
                  <li key={i}>{err}</li>
                ))}
              </ul>
            </div>
          ) : null}
          {warnings?.length ? (
            <div className={classes.warnings}>
              <ul>
                {warnings.map((err, i) => (
                  <li key={i}>{err}</li>
                ))}
              </ul>
            </div>
          ) : null}
        </div>
        <div className={classes.right}>
          {data?.data ? (
            <>
              <SydButton size='md' variant='outline' icon='close' onClick={reset}>Reset</SydButton>
              <SydButton
                size='md'
                variant='primary'
                icon='check'
                disabled={errors?.length || disabled}
                onClick={submit}
              >
                Looks Good
              </SydButton>

            </>
          ) : null}
        </div>
      </div>
      {data?.data ? (
        <FadeIn className={classes.preview}>
          <InputTable
            data={data}
            title={(<span>Data Preview - ({data?.data?.length} records)</span>)}
            onChange={setData}
            onEditChange={setDisabled}
          />
        </FadeIn>
      ) : null}
      <PersonalSpace />
    </FadeIn>
  )
}

export default NewBatch
