import React, { forwardRef, useCallback, useImperativeHandle, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import DebouncedInput from '../../../molecules/DebouncedInput'
import Select from '../../../molecules/Select'

const operationOptions = [
  { label: 'Contains', value: 'contains' },
  { label: 'Starts With', value: 'startsWith' },
  { label: 'Ends With', value: 'endsWith' },
  { label: 'Exactly Matches', value: 'exact' }
]

const useStyles = makeStyles((theme) => ({
  searchGroup: {
    display: 'flex',
    flexDirection: 'row',
    gap: '5px'
  },
  option: {
    border: `1px solid ${theme.palette.gray.dark}`,
    minWidth: '10rem'
  },
  nameOption: {
    border: `1px solid ${theme.palette.gray.dark}`,
    minWidth: '15rem'
  }
}))

const TextSearch = forwardRef(function TextSearch ({ schema, onChange }, ref) {
  const [currentValue, setCurrentValue] = useState({})
  const { defaultField, options } = useMemo(() => {
    const defaultField = '!any'
    const options = Object.entries(schema.columns)
      .map(([key, value]) => ({ key, value }))
      .filter(({ value }) => value.search === true)
      .sort((a, b) => a.value.ordinal - b.value.ordinal)
      .map(({ key, value }) => ({
        label: value.title,
        value: key
      }))

    options.push({ label: 'Any Field', value: '!any' })

    return {
      defaultField,
      options
    }
  }, [schema])

  const [field, setField] = useState(defaultField)
  const [operation, setOperation] = useState('contains')

  const onSearchChange = useCallback(searchValue => {
    if (field === '!any') {
      const val = (!searchValue || searchValue === '')
        ? {}
        : options.reduce((prev, cur) => {
          prev[cur.value] = [{ op: operation, value: searchValue }]
          return prev
        }, {})
      setCurrentValue(val)
      onChange(val)
      return
    }

    const val = (!searchValue || searchValue === '')
      ? {}
      : {
        [field]: [{ op: operation, value: searchValue }]
      }
    setCurrentValue(val)
    onChange(val)
  }, [onChange, field, operation, setCurrentValue, options])

  useImperativeHandle(ref, () => {
    return {
      value: currentValue
    }
  }, [currentValue])

  const classes = useStyles()

  return (
    <div className={classes.searchGroup}>
      <Select className={classes.nameOption} options={options} value={field} onChange={setField} />
      <Select className={classes.option} options={operationOptions} value={operation} onChange={setOperation} />
      <DebouncedInput
        placeholder='Search'
        onChange={onSearchChange}
      />
    </div>
  )
})

TextSearch.propTypes = {
  schema: PropTypes.shape({
    columns: PropTypes.object
  }),
  onChange: PropTypes.func
}

export default TextSearch
