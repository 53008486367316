import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import {
  getBillingRun,
  getBillingRuns,
  getBillingSystemConfig,
  getFeeCalcTypes,
  getFeeMethods,
  getFeeSchedules,
  postNamedCommand,
  postNamedQuery,
  putClientBillingInfo,
  updateAccountBillingAccounts
} from '../service'
import { useAppContext } from '../redux/slices/appContext'
import { QUERY_KEYS } from './queryKeys'

export const useFeeCalcTypes = (mapper = null) => {
  const { userId } = useAppContext()
  return useQuery({
    queryKey: [QUERY_KEYS.feeCalcTypes, userId],
    queryFn: async () => {
      const { data } = await getFeeCalcTypes()
      return mapper ? mapper(data) : data
    }
  })
}

export const useFeeMethods = (mapper = null) => {
  const { userId } = useAppContext()
  return useQuery({
    queryKey: [QUERY_KEYS.feeMethods, userId],
    queryFn: async () => {
      const { data } = await getFeeMethods()
      return mapper ? mapper(data) : data
    }
  })
}

export const useFeeSchedule = (mapper = null) => {
  const { userId } = useAppContext()
  return useQuery({
    queryKey: [QUERY_KEYS.feeSchedule, userId],
    queryFn: async () => {
      const { data } = await getFeeSchedules()
      return mapper ? mapper(data) : data
    }
  })
}

export const useBillingRuns = (query = {}, options = {}) => {
  const { userId } = useAppContext()
  const { mapper = null } = options
  return useQuery({
    ...options,
    queryKey: [QUERY_KEYS.billingRuns, userId, query],
    queryFn: async () => {
      const { data } = await getBillingRuns(query)
      if (data?.statusCode > 200) {
        throw new Error('Issue getting billing runs')
      }
      return mapper ? mapper(data) : data
    }
  })
}

export const useBillingRun = (billingRunId, options = {}) => {
  const { userId } = useAppContext()
  const { mapper = null } = options
  return useQuery({
    ...options,
    queryKey: [QUERY_KEYS.billingRuns, userId, billingRunId],
    queryFn: async () => {
      const { data } = await getBillingRun(billingRunId)
      return mapper ? mapper(data) : data
    }
  })
}

export const useBillingRunDetails = (query = {}, options = {}) => {
  const { mapper = null } = options
  return useQuery({
    ...options,
    queryKey: [QUERY_KEYS.billingRunDetails, query],
    queryFn: async () => {
      const { data } = await postNamedQuery('billing', 'get-billing-run-details', {
        ...query
      })
      const result = data.body

      return mapper ? mapper(result) : result
    }
  })
}

export const useFeeAdjustment = ({ feeAdjustmentId }, options = {}) => {
  const { userId } = useAppContext()
  const { mapper = null } = options
  return useQuery({
    ...options,
    queryKey: [QUERY_KEYS.billingRunDetails, userId, feeAdjustmentId],
    queryFn: async () => {
      const { data } = await postNamedQuery('billing', 'get-fee-adjustment', { feeAdjustmentId })
      return mapper ? mapper(data) : data
    }
  })
}

export const useFeeAdjustments = (query = {}, options = {}) => {
  const { userId } = useAppContext()
  const { mapper = null } = options
  return useQuery({
    ...options,
    queryKey: [QUERY_KEYS.billingRunDetails, userId, query],
    queryFn: async () => {
      const { data } = await postNamedQuery('billing', 'get-fee-adjustments', query)
      return mapper ? mapper(data) : data
    }
  })
}

export const useFeatureScheduleExposure = (query = {}, options = {}) => {
  const { searchForMultiple, mapper } = options
  const key = searchForMultiple ? QUERY_KEYS.feeScheduleMultipleExposures : QUERY_KEYS.feeScheduleNoExposure
  const { userId } = useAppContext()
  return useQuery({
    queryKey: [key, userId, query],
    queryFn: async () => {
      const { data } = await postNamedQuery(
        'billing',
        'feeSchedulesExposure',
        query
      )
      return data
    },
    select: mapper
  })
}

export const useBillingSystemConfig = () => {
  const { userId } = useAppContext()
  return useQuery({
    queryKey: [QUERY_KEYS.billingSystemConfig, userId],
    queryFn: async () => {
      const { data } = await getBillingSystemConfig()
      return data
    }
  })
}

export const useSaveClientBillingInfoMutation = () => {
  return useMutation({
    mutationFn: async ({ clientId, billingInfo }) => {
      const { data } = await putClientBillingInfo(clientId, billingInfo)

      return data
    }
  })
}

export const useClientSummary = (query) => {
  const { userId } = useAppContext()
  return useQuery({
    queryKey: ['billing.get-client-summary', userId, JSON.stringify(query || null)],
    queryFn: async () => {
      const pages = []
      let skip = 0
      let keepFetching = true
      const take = 1000
      while (keepFetching) {
        const { data } = await postNamedQuery('levels', 'searchClients', {
          ...(query || {}),
          includes: {
            ...(query.includes || {}),
            inUserBookOfBusiness: true
          },
          take,
          skip
        })
        pages.push(data.data)
        if (data.data.length < take) {
          keepFetching = false
        } else {
          skip += take
        }
      }
      return pages.flatMap(x => x)
    }
  })
}

/**
 * This guy refetches a single row from a query with thousands of rows
 * @param queryClient
 * @param clientId
 * @returns {Promise<void>}
 */
async function refreshClientBillingSummary (queryClient, clientId) {
  const cache = queryClient.getQueryCache()
  const summaryQueries = cache.findAll(['billing.get-client-summary'])
  const promises = summaryQueries.map(async query => {
    const prevQuery = JSON.parse(query.queryKey.at(-1))
    const filtered = {
      ...prevQuery,
      filters: {
        ...(prevQuery.filters || {}),
        clientId
      }
    }
    const { data } = await postNamedQuery('levels', 'searchClients', filtered)
    return { key: query.queryKey, data: data?.data?.length ? data.data.at(0) : null }
  })
  const results = await Promise.all(promises)
  results.forEach(({ key, data }) => {
    queryClient.setQueryData(key, (prev) => {
      const copy = prev ? [...prev] : []
      const item = copy.find(x => x.clientId === data?.clientId)
      if (item) {
        Object.assign(item, data)
      }
      return copy
    })
  })
}

export const useClientBillingDetails = (clientId, options = {}) => {
  const { userId } = useAppContext()
  const enabled = options?.enabled ?? true
  return useQuery({
    queryKey: ['billing.get-client-billing-details', userId, clientId],
    queryFn: async () => {
      const { data } = await postNamedQuery('billing', 'get-client-billing-details', {
        clientId
      })
      return data
    },
    enabled
  })
}

export const useAccountBillingDetails = (accountId, options) => {
  const { userId } = useAppContext()
  const enabled = options?.enabled ?? true
  return useQuery({
    queryKey: ['billing.get-account-billing-details', userId, accountId],
    queryFn: async () => {
      const { data } = await postNamedQuery('billing', 'get-account-billing-details', {
        accountId
      })
      return data
    },
    enabled
  })
}

export const useAddClientFeeScheduleMutation = () => {
  const queryClient = useQueryClient()
  const { userId } = useAppContext()
  return useMutation({
    mutationFn: async ({ clientId, feeScheduleId }) => {
      const { data } = await postNamedCommand('billing', 'add-client-fee-schedule', { clientId, feeScheduleId })

      return data
    },
    onSuccess: async (_, { clientId }) => {
      queryClient.invalidateQueries({ queryKey: ['billing.get-client-billing-details', userId, clientId], refetchType: 'all' }).catch(console.error)
      await refreshClientBillingSummary(queryClient, clientId)
    }
  })
}

export const useReplaceClientFeeScheduleMutation = () => {
  const queryClient = useQueryClient()
  const { userId } = useAppContext()
  return useMutation({
    mutationFn: async ({ clientId, feeScheduleId, oldFeeScheduleId }) => {
      const { data } = await postNamedCommand('billing', 'replace-client-fee-schedule', { clientId, oldFeeScheduleId, feeScheduleId })

      return data
    },
    onSuccess: async (_, { clientId }) => {
      queryClient.invalidateQueries({ queryKey: ['billing.get-client-billing-details', userId, clientId], refetchType: 'all' }).catch(console.error)
      await refreshClientBillingSummary(queryClient, clientId)
    }
  })
}

export const useRemoveClientFeeScheduleMutation = () => {
  const queryClient = useQueryClient()
  const { userId } = useAppContext()
  return useMutation({
    mutationFn: async ({ clientId, feeScheduleId }) => {
      const { data } = await postNamedCommand('billing', 'remove-client-fee-schedule', { clientId, feeScheduleId })

      return data
    },
    onSuccess: async (_, { clientId }) => {
      queryClient.invalidateQueries({ queryKey: ['billing.get-client-billing-details', userId, clientId], refetchType: 'all' }).catch(console.error)
      await refreshClientBillingSummary(queryClient, clientId)
    }
  })
}

export const useEditFamilyRateMutation = () => {
  const queryClient = useQueryClient()
  const { userId } = useAppContext()
  return useMutation({
    mutationFn: async ({ clientId, feeScheduleId, familyRateMembers }) => {
      const { data } = await postNamedCommand('billing', 'modify-family-rate-members', { clientId, feeScheduleId, familyRateMembers })

      return data
    },
    onSuccess: (_, { clientId }) => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.accountSearch], refetchType: 'all' }).catch(console.error)
      queryClient.invalidateQueries({ queryKey: ['billing.get-client-billing-details', userId, clientId], refetchType: 'all' }).catch(console.error)
    }
  })
}

export const useEditBillingStartDateMutation = () => {
  const queryClient = useQueryClient()
  const { userId } = useAppContext()
  return useMutation({
    mutationFn: async ({ accountId, billingStartDate }) => {
      const { data } = await postNamedCommand('accounts', 'updateAccountBillingStartDate', {
        accountId,
        billingStartDate
      })

      return data
    },
    onSuccess: (_, { clientId }) => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.accountSearch], refetchType: 'all' }).catch(console.error)
      queryClient.invalidateQueries({ queryKey: ['billing.get-client-billing-details', userId, clientId], refetchType: 'all' }).catch(console.error)
    }
  })
}

export const useEditAccountBillingAccountsMutation = () => {
  const queryClient = useQueryClient()
  const { userId } = useAppContext()
  return useMutation({
    mutationFn: async ({ accountId, billingAccounts }) => {
      const { data } = await updateAccountBillingAccounts(accountId, { billingAccounts })

      return data
    },
    onSuccess: (_, { accountId }) => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.accountSearch], refetchType: 'all' }).catch(console.error)
      queryClient.invalidateQueries({ queryKey: ['billing.get-account-billing-details', userId, accountId], refetchType: 'all' }).catch(console.error)
    }
  })
}

export const useAddClientOverrideMutation = () => {
  const queryClient = useQueryClient()
  const { userId } = useAppContext()
  return useMutation({
    mutationFn: async ({ clientId, accountId, assetClassId, positionId, feeScheduleId, feeCalcTypeId, overrideData }) => {
      const { data } = await postNamedCommand('billing', 'add-client-override', {
        clientId,
        accountId,
        assetClassId,
        positionId,
        feeScheduleId,
        feeCalcTypeId,
        overrideData
      })

      return data
    },
    onSuccess: (_, { clientId }) => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.accountSearch], refetchType: 'all' }).catch(console.error)
      queryClient.invalidateQueries({ queryKey: ['billing.get-client-billing-details', userId, clientId], refetchType: 'all' }).catch(console.error)
    }
  })
}

export const useRemoveClientOverrideMutation = () => {
  const queryClient = useQueryClient()
  const { userId } = useAppContext()
  return useMutation({
    mutationFn: async ({ clientId, feeLevelOverrideId }) => {
      const { data } = await postNamedCommand('billing', 'remove-client-override', {
        clientId,
        feeLevelOverrideId
      })

      return data
    },
    onSuccess: (_, { clientId }) => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.accountSearch], refetchType: 'all' }).catch(console.error)
      queryClient.invalidateQueries({ queryKey: ['billing.get-client-billing-details', userId, clientId], refetchType: 'all' }).catch(console.error)
    }
  })
}
