import React from 'react'
import PropTypes from 'prop-types'
import { Alert as MuiAlert } from '@material-ui/lab'
import { makeStyles } from '@material-ui/core'
import { ALERT_SEVERITY, ALERT_VARIANT } from '../../constants'

const getBorderColor = (theme) => ({
  [ALERT_SEVERITY.error]: theme.palette.error.A100,
  [ALERT_SEVERITY.success]: theme.palette.success.A50,
  [ALERT_SEVERITY.info]: theme.palette.info.main
})

const useStyles = makeStyles((theme) => ({
  alert: ({ severity }) => ({
    minHeight: '3rem',
    borderRadius: '0.750rem',
    fontSize: '1rem',
    padding: '1rem',
    margin: '-4px',
    border: `4px solid ${getBorderColor(theme)[severity]}`
  }),
  alertMessage: {
    padding: '0'
  }
}))

const Alert = ({ children, variant, severity }) => {
  const classes = useStyles({ severity })
  return (
    <MuiAlert
      icon={false}
      variant={variant}
      severity={severity}
      classes={{
        message: classes.alertMessage
      }}
      className={classes.alert}
    >
      {children}
    </MuiAlert>
  )
}

Alert.propTypes = {
  children: PropTypes.any,
  variant: PropTypes.oneOf(Object.values(ALERT_VARIANT)).isRequired,
  severity: PropTypes.oneOf(Object.values(ALERT_SEVERITY)).isRequired
}

Alert.defaultProps = {
  children: null
}

export default Alert
