import { createContext, useContext, useMemo } from 'react'

const DEFAULT_CONFIGURATION = {
  configuration: {
    datasets: {}
  }
}

export const TabularDatasetContext = createContext(DEFAULT_CONFIGURATION)

export const useTabularDatasetContext = (overrideConfiguration) => {
  const contextValue = useContext(TabularDatasetContext)
  return useMemo(() => {
    if (!overrideConfiguration) {
      return contextValue
    }
    // eslint-disable-next-line no-debugger
    const configuration = {
      ...(contextValue?.configuration || DEFAULT_CONFIGURATION),
      ...overrideConfiguration
    }
    return {
      configuration
    }
  }, [contextValue, overrideConfiguration])
}
