import React, { useMemo } from 'react'
import dayjs from 'dayjs'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import FadeIn from '../../../molecules/Transitions/FadeIn'
import FlexRow from '../../../molecules/FlexRow'
import { useWealthJourney } from './WealthJourneyProvider'
import { useEntryTypeFilter } from './useEntryTypeFilter'

const useStyles = makeStyles((theme) => ({
  pickerItem: {
    userSelect: 'none',
    fontWeight: theme.typography.weights.light,

    '&.__selected': {
      fontWeight: theme.typography.weights.bold,
      color: theme.palette.primary.main
    }
  },
  pickList: {
    paddingLeft: theme.layout.padding.p10,
    paddingRight: theme.layout.padding.p10,
    '&:not(:first-child)': {
      borderLeft: `1px solid ${theme.palette.gray.darker}`
    }
  }
}))

function WJDatePeriodPicker2 ({ entryTypes }) {
  const { entries, entriesLoading, selectedYear, setSelectedYear } = useWealthJourney()
  const _entries = useEntryTypeFilter(entries, entryTypes)
  const years = useMemo(() => {
    if (!_entries?.length) return [dayjs().year()]

    const years = _entries.reduce((prev, cur) => {
      const year = dayjs(cur.entryDate).year()
      prev.add(year)

      return prev
    }, new Set())

    return Array.from(years.values()).sort((a, b) => a - b)
  }, [_entries])
  const classes = useStyles()

  if (entriesLoading) return null

  return (
    <FadeIn>
      <FlexRow>
        {years.map((year) => (
          <div key={year} className={classes.pickList}>
            <div
              role='button'
              className={clsx(classes.pickerItem, { __selected: selectedYear === year })}
              onClick={() => setSelectedYear(year)}
            >
              {year}
            </div>
          </div>
        ))}
      </FlexRow>
    </FadeIn>
  )
}

WJDatePeriodPicker2.propTypes = {
  entryTypes: PropTypes.arrayOf(PropTypes.string)
}

export default WJDatePeriodPicker2
