import React from 'react'
import PropTypes from 'prop-types'
import Meeting from './Meeting'

const MeetingList = ({ meetings, take, linkBaseUrl }) => {
  return (
    <div>
      {meetings.slice(0, take).map((meeting) => (
        <div key={meeting.meetingId || meeting.meetingWhatId}>
          <Meeting meeting={meeting} linkBaseUrl={linkBaseUrl} />
        </div>
      ))}
    </div>
  )
}

MeetingList.propTypes = {
  meetings: PropTypes.array,
  take: PropTypes.number,
  linkBaseUrl: PropTypes.string
}

MeetingList.defaultProps = {
  take: 5,
  linkBaseUrl: null
}

export default MeetingList
