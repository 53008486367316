import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { Controller, useForm } from 'react-hook-form'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import SydButton from '../../../../../../commonDesign/Button'
import {
  useEditBillingStartDateMutation
} from '../../../../../../../api/billing'
import { useDialogStyles } from '../../common'
import SydLabel from '../../../../../../commonDesign/SydLabel'
import SydDatePicker from '../../../../../../commonDesign/SydDatePicker'

dayjs.extend(utc)
const dateFormat = 'MM/DD/YYYY'

const useSubmitter = (form, onComplete, account) => {
  const { handleSubmit } = form
  const [processing, setProcessing] = useState(false)
  const [error, setError] = useState(null)
  const { mutateAsync: editBillingStartDate } = useEditBillingStartDateMutation()
  const onSubmit = useCallback(async (formData) => {
    const command = {
      accountId: account.accountId,
      billingStartDate: formData.billingStartDate ? dayjs.utc(formData.billingStartDate).format('YYYY-MM-DD') : null
    }

    try {
      setError(null)
      setProcessing(true)
      const result = await editBillingStartDate(command)
      if (result?.statusCode === 500) {
        throw new Error('Failed to edit billing start date')
      }
      onComplete(result)
    } catch (err) {
      if (err?.code < 500 && err?.response?.detail) {
        setError(err.response.detail)
      } else {
        setError(err?.toString())
      }
    } finally {
      setProcessing(false)
    }
  }, [editBillingStartDate, setProcessing, onComplete, account?.accountId, setError])

  const submitter = useCallback(async (e) => {
    const onValid = async (form) => {
      await onSubmit(form)
    }
    const onInvalid = (errors) => {
      console.error(errors)
    }

    const handler = handleSubmit(onValid, onInvalid)
    await handler(e)
  }, [handleSubmit, onSubmit])

  return {
    submitter,
    processing,
    error
  }
}

function EditBillingStartDateForm ({ account, onCancel, onComplete }) {
  const classes = useDialogStyles()
  const form = useForm({
    mode: 'onChange',
    defaultValues: {
      accountId: account.accountId,
      billingStartDate: account.billingStartDate ? dayjs.utc(account.billingStartDate).format('MM/DD/YYYY') : null
    }
  })

  const { submitter, processing, error } = useSubmitter(form, onComplete, account)

  return (
    <>
      <div className={classes.form}>
        <div className={classes.group}>
          <Controller
            name='billingStartDate' control={form.control}
            render={(f => (
              <>
                <SydLabel label='Billing Start Date' style={{ width: '50%', marginRight: '10px' }}>
                  <div style={{ display: 'flex', flexDirection: 'row', gap: '10px', alignItems: 'center' }}>
                    <SydDatePicker
                      onChange={(val) => f.field.onChange(dayjs.utc(val).format('YYYY-MM-DD'))}
                      value={f.field.value}
                      dateFormat={dateFormat}
                      InputProps={{ disableUnderline: true, className: classes.itemDateInput }}
                      className={classes.itemDateField}
                      InputAdornmentProps={{ position: 'start' }}
                    />
                    <div>
                      <SydButton icon='delete' onClick={() => f.field.onChange(null)}>Clear</SydButton>
                    </div>
                  </div>
                </SydLabel>
              </>
            ))}
          />
        </div>
      </div>
      <div className={classes.actions}>
        {error ? (<div className={classes.error}>{error}</div>) : null}
        <SydButton disabled={processing} variant='ghost' size='md' onClick={onCancel}>Cancel</SydButton>
        <SydButton
          variant='primary'
          size='md'
          onClick={submitter}
          processing={processing}
        >
          Save
        </SydButton>
      </div>
    </>
  )
}

EditBillingStartDateForm.propTypes = {
  account: PropTypes.shape({
    accountId: PropTypes.number,
    billingStartDate: PropTypes.string
  }),
  onComplete: PropTypes.func,
  onCancel: PropTypes.func
}

export default EditBillingStartDateForm
