import React, { useMemo } from 'react'
import { Button, makeStyles, Snackbar } from '@material-ui/core'
import clsx from 'clsx'
import { Alert } from '@material-ui/lab'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import {
  ALERT_SEVERITY,
  DIALOG_ACTIONS,
  MAIN_HEADER_HEIGHT,
  TEXT_ALIGNMENTS,
  TEXT_VARIANTS
} from '../../../../constants'
import ErrorComponent from '../../../atoms/ErrorComponent'
import Text from '../../../atoms/Text'
import Table from '../../../molecules/Table'
import TableSkeleton from '../../../atoms/TableSkeleton'
import ManagementDialog from './managementDialog'
import ManagementRightPanel from './managementRightPanel'

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '1rem',
    height: '100%',
    display: 'flex',
    flex: '1',
    flexDirection: 'column'
  },
  addButton: {
    color: theme.palette.common.white,
    padding: '8px 20px',
    backgroundColor: theme.palette.darkBlue,
    '&:hover': {
      backgroundColor: '#171c47b3'
    },
    fontWeight: 600
  },
  button: {
    borderRadius: '30px',
    fontWeight: 800,
    textTransform: 'capitalize'
  },
  grayButton: {
    backgroundColor: theme.palette.gray.light,
    color: theme.palette.lightBlack,
    '&:hover': {
      backgroundColor: theme.palette.gray.dark
    }
  },
  redButton: {
    backgroundColor: theme.palette.lightCoral,
    color: theme.palette.error.main,
    '&:hover': {
      backgroundColor: '#f3b3be'
    }
  },
  disabledButton: {
    cursor: 'not-allowed !important',
    backgroundColor: '#7f8299'
  },
  sidePanelContent: {
    padding: '0 30px',
    height: `calc(100% - ${MAIN_HEADER_HEIGHT}px)`
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    boxSizing: 'border-box',
    alignItems: 'center',
    padding: `${theme.spacing(1.5)}px ${theme.spacing(0.5)}px`,
    width: '100%'
  }
}))

const LABELS_COMMON_PROPS = {
  alignment: TEXT_ALIGNMENTS.left,
  colSpan: 1,
  sorteable: true,
  variant: TEXT_VARIANTS.h3
}

function ManagementView ({
  addLabel,
  alertMessage,
  alertSeverity,
  dialogAction,
  dialogActionLabel,
  dialogBody,
  dialogOpen,
  dialogTitle,
  disableActions,
  error,
  items,
  onItemClick,
  handleClickAdd,
  handleClickDelete,
  handleClickEdit,
  handleCloseAlert,
  handleDialogAction,
  labels,
  loadingDialog,
  loadingTable,
  openAlert,
  sidePanelContent,
  sidePanelTitle,
  sidePanelOpen,
  title,
  toggleDialog,
  toggleSidePanel,
  virtualizeTable,
  sidePanelWidth = '400px',
  sidePanelHeight = '100%'
}) {
  const classes = useStyles()
  const tableLabels = useMemo(() => labels.map(label => ({ ...LABELS_COMMON_PROPS, ...label })), [labels])

  return (
    <div className={classes.container}>
      <div className={classes.titleContainer}>
        <Text text={title} variant='h1' />
        {addLabel && (
          <Button
            className={clsx(classes.button, classes.addButton, {
              [classes.disabledButton]: disableActions
            })}
            disabled={disableActions}
            onClick={handleClickAdd}
          >
            {addLabel}
          </Button>
        )}
      </div>
      {loadingTable && <TableSkeleton firstColumnWithAvatar />}
      {error && <ErrorComponent error={error} />}
      {!error && !loadingTable && (
        <Table
          disabledDelete={disableActions}
          disabledEdit={disableActions}
          handleDelete={handleClickDelete}
          handleEdit={handleClickEdit}
          labels={tableLabels}
          rows={items}
          virtualized={virtualizeTable}
          onRowClick={onItemClick}
          {...(virtualizeTable ? { elementType: 'div' } : {})}
        />
      )}
      <ManagementRightPanel
        open={sidePanelOpen}
        toggle={toggleSidePanel}
        title={sidePanelTitle}
        width={sidePanelWidth}
        height={sidePanelHeight}
      >
        {sidePanelContent}
      </ManagementRightPanel>
      <Snackbar
        open={openAlert}
        autoHideDuration={6000}
        onClose={handleCloseAlert}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseAlert} severity={alertSeverity}>
          {alertMessage}
        </Alert>
      </Snackbar>
      <ManagementDialog
        body={dialogBody}
        open={dialogOpen}
        title={dialogTitle}
        onClose={toggleDialog}
        loading={loadingDialog}
        dialogType={dialogAction}
        primaryLabel={dialogActionLabel}
        onTriggerAction={handleDialogAction}
      />
    </div>
  )
}

ManagementView.propTypes = {
  addLabel: PropTypes.string,
  alertMessage: PropTypes.string,
  alertSeverity: PropTypes.string,
  dialogAction: PropTypes.string,
  dialogActionLabel: PropTypes.string,
  dialogBody: PropTypes.string,
  dialogOpen: PropTypes.bool,
  dialogTitle: PropTypes.string,
  disableActions: PropTypes.bool,
  error: PropTypes.instanceOf(Error),
  items: PropTypes.array.isRequired,
  onItemClick: PropTypes.func,
  handleClickAdd: PropTypes.func,
  handleClickDelete: PropTypes.func,
  handleClickEdit: PropTypes.func,
  handleCloseAlert: PropTypes.func,
  handleDialogAction: PropTypes.func,
  labels: PropTypes.arrayOf(PropTypes.shape({
    alignment: PropTypes.oneOf(Object.keys(TEXT_ALIGNMENTS)),
    colSpan: PropTypes.number,
    name: PropTypes.string.isRequired,
    sorteable: PropTypes.bool,
    variant: PropTypes.oneOf(Object.keys(TEXT_VARIANTS))
  })).isRequired,
  loadingDialog: PropTypes.bool,
  loadingTable: PropTypes.bool,
  openAlert: PropTypes.bool,
  sidePanelContent: PropTypes.node,
  sidePanelTitle: PropTypes.string,
  sidePanelOpen: PropTypes.bool,
  title: PropTypes.string.isRequired,
  toggleDialog: PropTypes.func,
  toggleSidePanel: PropTypes.func,
  virtualizeTable: PropTypes.bool,
  sidePanelWidth: PropTypes.string,
  sidePanelHeight: PropTypes.string
}

ManagementView.defaultProps = {
  addLabel: undefined,
  alertMessage: '',
  alertSeverity: ALERT_SEVERITY.info,
  dialogAction: DIALOG_ACTIONS.delete,
  onItemClick: noop,
  dialogActionLabel: 'Delete',
  dialogBody: 'This action will generate a permanent change.',
  dialogOpen: false,
  dialogTitle: '',
  disableActions: false,
  error: undefined,
  handleClickAdd: noop,
  handleClickDelete: undefined,
  handleClickEdit: undefined,
  handleCloseAlert: noop,
  handleDialogAction: noop,
  loadingDialog: false,
  loadingTable: false,
  openAlert: false,
  sidePanelContent: null,
  sidePanelTitle: '',
  sidePanelOpen: false,
  toggleDialog: noop,
  toggleSidePanel: noop,
  virtualizeTable: false
}

export default React.memo(ManagementView)
