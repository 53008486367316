import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Link, useParams } from 'react-router-dom'
import { useAdvisorHomeNavigation } from './NavigatationContext'

function ViewItem ({ item }) {
  return (
    <div style={{ paddingLeft: item.hierarchyLevel * 20 }}>
      <div style={{ display: 'flex', flexDirection: 'row', gap: 20 }}>
        <div>{item.name}</div>
        <div><Link to={item.path}>{item.path}</Link></div>
      </div>
      {item.children.map(ch => (
        <ViewItem key={ch.advisorViewTemplateId} item={ch} />
      ))}
    </div>
  )
}

ViewItem.propTypes = {
  item: PropTypes.object
}

function ViewListing () {
  const { template } = useParams()
  const { templateTree } = useAdvisorHomeNavigation()
  const rootTemplate = useMemo(() => {
    return templateTree.find(x => x.pathSegment === template)
  }, [templateTree, template])

  return (
    <div>
      <header>View Listing</header>
      <ViewItem item={rootTemplate} />
    </div>
  )
}

export default ViewListing
