import React, { useCallback, useState } from 'react'
import { makeStyles, Grid } from '@material-ui/core'
import { Controller, useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { usePolicy } from '../../../../../hooks/usePolicy'
import FadeIn from '../../../../molecules/Transitions/FadeIn'
import { ADMIN_ROUTES } from '../../../../../constants'
import SaveCancelFooter from '../../shared/SaveCancelFooter'
import SydLabel, { hookFormErrorAdapter } from '../../../../commonDesign/SydLabel'
import SydInput from '../../../../commonDesign/SydInput'
import { useCreateStatusTemplateMutation } from '../../../../../api/groups'

const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    justifyContent: 'center',
    padding: '10px',
    '& p': {
      color: `${theme.palette.primary.dark}`
    }
  },
  wrap: {
    maxWidth: '900px',
    margin: '20px'
  },
  noAccess: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '300px',
    fontSize: theme.typography.fontSizes.h1
  },
  inputLabel: {
    color: theme.palette.black,
    fontWeight: 600,
    '& span': {
      color: theme.palette.black
    }
  },
  header: {
    fontSize: theme.typography.fontSizes.xl,
    marginBottom: '20px'
  }
}))

function NewStatusTemplate () {
  const classes = useStyles()
  const canCreate = usePolicy('admin_edit_status_templates')
  const history = useHistory()
  const { mutateAsync: createTemplate } = useCreateStatusTemplateMutation()

  const form = useForm({
    defaultValues: { name: '', description: '' }
  })
  const { handleSubmit } = form
  const [processing, setProcessing] = useState(false)
  const onSave = useCallback(async (e) => {
    const onSuccess = async (form) => {
      const commandBody = {
        statusTemplate: {
          name: form.name || null,
          description: form.description || null
        }
      }

      setProcessing(true)
      try {
        const saveResult = await createTemplate(commandBody)
        if (saveResult?.statusTemplate?.statusTemplateId) {
          history.replace(`${ADMIN_ROUTES.STATUS_TEMPLATE_DETAILS}/${saveResult.statusTemplate.statusTemplateId}`)
        } else {
          history.replace(ADMIN_ROUTES.STATUS_REPORTS)
        }
      } finally {
        setProcessing(false)
      }
    }
    const onError = (errors) => {
      // eslint-disable-next-line no-throw-literal
      throw { validationErrors: errors }
    }
    const handler = handleSubmit(onSuccess, onError)
    await handler(e)
  }, [handleSubmit, createTemplate, history, setProcessing])

  const onCancel = useCallback(() => { history.push(ADMIN_ROUTES.STATUS_REPORTS) }, [history])

  if (!canCreate) {
    return (
      <FadeIn className={classes.noAccess}>
        You do not have access to this resource
      </FadeIn>
    )
  }

  return (
    <FadeIn className={classes.form}>
      <div className={classes.wrap}>
        <header className={classes.header}>
          Fill out the following fields to create a Status Report Template
        </header>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <Controller
              name='name'
              rules={{ required: true, maxLength: 64 }}
              control={form.control}
              render={({ field, fieldState }) => (
                <SydLabel label='Name' required description='&nbsp;' error={hookFormErrorAdapter(form, fieldState)}>
                  <SydInput placeholder='Name' {...field} />
                </SydLabel>
              )}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <Controller
              name='description'
              rules={{ required: true, maxLength: 256 }}
              control={form.control}
              render={({ field, fieldState }) => (
                <SydLabel label='Description' required description='&nbsp;' error={hookFormErrorAdapter(form, fieldState)}>
                  <SydInput placeholder='Description' {...field} />
                </SydLabel>
              )}
            />
          </Grid>
        </Grid>
      </div>
      <SaveCancelFooter
        onCancel={onCancel}
        onSave={onSave}
        processing={processing}
        disabled={!form.formState.isValid}
        saveText='Create Status Report Template'
      />
    </FadeIn>
  )
}

export default NewStatusTemplate
