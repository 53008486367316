import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import { useRouteMatch, useHistory } from 'react-router-dom'
import { useGroupingContext, useSetTableProps } from '../../../../../../organisms/GroupingProvider/GroupingContext'
import { useAutoOperationalTableContext } from '../../../../../../organisms/OperationalTable/AutoOperationalTableContext'
import OperationalTable, {
  useOperationalTable
} from '../../../../../../organisms/OperationalTable'
import { useListBobiOutputs } from '../../../../../../../api/bobi'
import { useReportRecentContext } from '../ReportRecentProvider'
import { getBobiOutput } from '../../../../../../../service'
import { useSystemReportsContext } from '../../ReportSavedView/SystemReportsProvider'
import { getBookOfBusinessReportUrl } from '../../../../../../../contexts/BookOfBusinessReportContext/BookOfBusinessResultsLink'
import { useColumns } from './columnConfig'

const useStyles = makeStyles(() => ({
  container: {
    paddingTop: '0.5rem'
  }
}))

const bobiOutputQuery = {
  statuses: ['pending', 'completed'],
  orderBy: [
    {
      field: 'createdAt',
      asc: false
    }
  ],
  paging: {
    limit: 1000,
    offset: 0
  }
}

const ReportRecentTable = ({ columnConfig, defaultPageSize, showSavedRequests }) => {
  const classes = useStyles()

  const {
    isLoading: isReportRecentLoading,
    toggleIsLoading
  } = useReportRecentContext()

  const { reportRecentTableProps } = useSystemReportsContext()
  const { isRefetchIntervalEnabled, disableRowClick } = reportRecentTableProps || {}

  const { tableProps } = useAutoOperationalTableContext()
  const { setPerformanceTableProps } = useGroupingContext()

  const { columns, defaultSort } = useColumns({
    columnsConfig: columnConfig
  })

  const { pageSize, onPagingChange, onSortingChange, onTableModeChange } =
    useOperationalTable({
      defaultPageSize
    })

  const bobiOutputQueryOptions = useMemo(() => {
    const mapper = showSavedRequests
      ? (data) => data.filter(item => item.extra?.savedRequest === true)
      : (data) => data.filter(item => !item.extra || item.extra?.savedRequest === false)

    if (!isRefetchIntervalEnabled) return { mapper }

    return { interval: 10000, mapper }
  }, [isRefetchIntervalEnabled, showSavedRequests])

  const { data = [], isLoading } = useListBobiOutputs(
    bobiOutputQuery,
    bobiOutputQueryOptions
  )

  useSetTableProps({
    data,
    isLoading,
    columns,
    tableProps,
    setTableProps: setPerformanceTableProps
  })

  const { url } = useRouteMatch()
  const history = useHistory()

  const onRowClick = useCallback(
    async (row) => {
      try {
        if (disableRowClick) return
        toggleIsLoading.on()

        const bobiOutputId = row?.original?.bobiOutputId
        const { data: reportData } = await getBobiOutput(bobiOutputId)

        const {
          rawFilters,
          ...reportParams
        } = reportData?.inputParams?.original?._raw

        const reportUrl = getBookOfBusinessReportUrl(`${url}/results`, {
          ...reportParams,
          filters: rawFilters || reportParams.filters
        })
        history.push(reportUrl)
      } finally {
        toggleIsLoading.off()
      }
    },
    [url, history, toggleIsLoading, disableRowClick]
  )

  return (
    <div className={classes.container}>
      <OperationalTable
        mode='auto'
        footNote='The archive captures reports run within the last 7 days. Report runs are archived after this period.'
        data={data}
        columns={columns}
        loading={isLoading || isReportRecentLoading}
        defaultPageSize={pageSize}
        onRowClick={onRowClick}
        onSortingChange={onSortingChange}
        onPagingChange={onPagingChange}
        onTableModeChange={onTableModeChange}
        defaultSort={defaultSort}
      />
    </div>
  )
}

ReportRecentTable.propTypes = {
  defaultPageSize: PropTypes.number,
  columnConfig: PropTypes.object,
  showSavedRequests: PropTypes.bool
}

export default ReportRecentTable
