import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useFormattingContext } from '../../../organisms/FormattingProvider/FormattingContext'
import AnalysisMetric from './AnalysisMetric'

function VarianceMetric ({ purpose, avtData, loading }) {
  const { formatter } = useFormattingContext()
  const value = useMemo(() => {
    if (loading) return ''

    const variance = avtData.statistics[`${purpose}Variance`]

    return formatter(variance, 'percentage')
  }, [purpose, loading, avtData, formatter])

  return (
    <AnalysisMetric loading={loading} metricName='Variance' value={value} />
  )
}

VarianceMetric.propTypes = {
  purpose: PropTypes.string,
  loading: PropTypes.bool,
  avtData: PropTypes.shape({
    statistics: PropTypes.object
  })
}

VarianceMetric.defaultProps = {
  purpose: 'rebalancing'
}

export default VarianceMetric
