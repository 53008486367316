import React from 'react'
import clsx from 'clsx'
import noop from 'lodash/noop'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import { familyTreeNode } from '../../prop-types'

const useStyles = makeStyles(() => ({
  root: {
    position: 'absolute',
    display: 'flex',
    padding: '10px',
    justifyContent: 'center'
  },
  inner: ({ isRoot }) => ({
    display: 'flex',
    flex: 1,
    border: '2px solid rgba(0, 0, 0, .2)',
    borderRadius: '10px 0',
    ...(isRoot ? { borderColor: 'rgba(0, 0, 0, .4)' } : {})
  }),
  innerChildren: {
    display: 'flex',
    flex: 1
  },
  gender: ({ gender }) => ({
    background: gender === 'male' ? '#a4ecff' : '#fdaed8'
  }),
  subTree: ({ gender }) => ({
    position: 'absolute',
    top: '6px',
    right: '14px',
    width: '14px',
    height: '10px',
    border: '1px solid rgba(0, 0, 0, .2)',
    borderRadius: '4px 0',
    cursor: 'pointer',
    background: gender === 'male' ? '#a4ecff' : '#fdaed8'
  })
}))

const FamilyTreeNode = ({
  node,
  style,
  children,
  onSubClick
}) => {
  const classes = useStyles({ ...node })
  return (
    <div className={classes.root} style={style} title={node.id}>
      {children
        ? <div className={classes.innerChildren}>{children}</div>
        : <div className={clsx(classes.inner, classes.gender)} />}
      {node.hasSubTree && <div className={classes.subTree} onClick={() => onSubClick(node.id)} />}
    </div>
  )
}

FamilyTreeNode.propTypes = {
  node: familyTreeNode,
  style: PropTypes.object,
  children: PropTypes.node.isRequired,
  onSubClick: PropTypes.func
}

FamilyTreeNode.defaultProps = {
  node: {
    id: 1,
    hasSubTree: false
  },
  style: {},
  onSubClick: noop
}

export default FamilyTreeNode
