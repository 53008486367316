import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import MuiAutocomplete from '@material-ui/lab/Autocomplete'
import { TextField } from '@material-ui/core'

/**
 * @param {AutocompleteProps}
 * @returns {JSX.Element}
 * @constructor
 */
const Autocomplete = ({
  limitTags,
  multiple,
  options,
  optionLabelSelector,
  onChange,
  onInputChange: _onInputChange,
  renderTags,
  value,
  valueSelector,
  hasError
}) => {
  const [open, setOpen] = useState(false)
  const [inputValue, setInputValue] = useState('')

  const _onChange = (event, value) => {
    onChange(valueSelector(value))
  }

  const onOpen = useCallback(() => {
    setOpen(true)
    setInputValue('')
  }, [setOpen])

  const onClose = useCallback(() => {
    setOpen(false)
  }, [setOpen])

  /**
   * @type {string}
   */

  const onInputChange = useCallback(
    (event) => {
      const value = event?.target?.value ?? ''
      _onInputChange(value)
      setInputValue(value)
    },
    [_onInputChange, setInputValue]
  )

  return (
    <MuiAutocomplete
      limitTags={limitTags}
      multiple={multiple}
      getOptionLabel={optionLabelSelector}
      getOptionSelected={(option, value) => {
        return option.value === value.value
      }}
      renderInput={(params) => (
        <TextField {...params} variant='outlined' fullWidth error={hasError} />
      )}
      renderOption={optionLabelSelector}
      renderTags={renderTags}
      filterOptions={(x) => x}
      value={value}
      inputValue={inputValue}
      options={options}
      onOpen={onOpen}
      onClose={onClose}
      onInputChange={onInputChange}
      onChange={_onChange}
      open={open}
    />
  )
}

Autocomplete.propTypes = {
  limitTags: PropTypes.number,
  multiple: PropTypes.bool,
  onInputChange: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  optionValueSelector: PropTypes.func,
  optionLabelSelector: PropTypes.func,
  optionKeySelector: PropTypes.func,
  renderTags: PropTypes.func,
  value: PropTypes.any.isRequired,
  valueSelector: PropTypes.func,
  inputValueSelector: PropTypes.func,
  hasError: PropTypes.bool
}

const identity = x => x

Autocomplete.defaultProps = {
  inputValueSelector: identity,
  limitTags: 5,
  multiple: false,
  optionValueSelector: identity,
  optionLabelSelector: (option) => option?.label ?? '',
  optionKeySelector: identity,
  valueSelector: identity,
  renderTags: null,
  hasError: false
}

export default Autocomplete

/**
 * @typedef AutocompleteProps
 * @property useOptions
 * @property onChange
 * @property value
 * @property valueSelector
 * @property inputValueSelector
 */
