import React from 'react'
import PropTypes from 'prop-types'
import { ARROW_VARIANTS, ICON_NAMES, TEXT_VARIANTS } from '../../constants'
import TextWithIcon from './TextWithIcon'

const icons = {
  [ARROW_VARIANTS.down]: ICON_NAMES.down,
  [ARROW_VARIANTS.neutral]: ICON_NAMES.remove,
  [ARROW_VARIANTS.up]: ICON_NAMES.up
}

const colors = {
  [ARROW_VARIANTS.down]: 'error.main',
  [ARROW_VARIANTS.neutral]: undefined,
  [ARROW_VARIANTS.up]: 'success.main'
}

const TextWithArrow = ({
  additionalClasses,
  arrowSize,
  arrowVariant,
  reverse,
  text,
  textElement,
  textVariant,
  iconMargin
}) => (
  <TextWithIcon
    additionalClasses={additionalClasses}
    iconName={icons[arrowVariant]}
    iconThemeColor={colors[arrowVariant]}
    iconCustomSize={arrowSize}
    reverse={reverse}
    text={text}
    textElement={textElement}
    textVariant={textVariant}
    iconMargin={iconMargin}
  />
)

TextWithArrow.propTypes = {
  additionalClasses: PropTypes.string,
  arrowSize: PropTypes.string,
  arrowVariant: PropTypes.oneOf(Object.values(ARROW_VARIANTS)),
  reverse: PropTypes.bool,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  textElement: PropTypes.any,
  textVariant: PropTypes.oneOf(Object.values(TEXT_VARIANTS)),
  iconMargin: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

TextWithArrow.defaultProps = {
  additionalClasses: '',
  arrowSize: '',
  arrowVariant: ARROW_VARIANTS.up,
  reverse: false,
  text: '',
  textElement: null,
  textVariant: TEXT_VARIANTS.body2,
  iconMargin: ''
}

export default TextWithArrow
