import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import dayjs from 'dayjs'
import { Link } from 'react-router-dom'
import FastAvatar from '../../../molecules/FastAvatar'

const useStyles = makeStyles(theme => ({
  card: {
    position: 'relative',
    backgroundColor: theme.palette.card.background,
    border: `1.5px solid ${theme.palette.card.border}`,
    height: '100%',
    padding: '22px 24px 14px 24px',
    transition: 'all 0.3s ease-in-out',
    boxShadow: `2px 2px 5px -2px ${theme.palette.card.shadow}`,
    borderRadius: '18px',
    fontWeight: 100,
    fontSize: '12px',
    '&:hover': {
      borderColor: theme.palette.card.borderHigh,
      cursor: 'pointer'
    },
    '&::after': {
      width: '100%',
      height: '100%',
      content: '\'\'',
      position: 'absolute',
      zIndex: -1,
      top: 0,
      left: 0,
      boxShadow: `3px 3px 6px 0px ${theme.palette.card.shadow2}`,
      borderRadius: '18px',
      opacity: 0,
      transition: 'opacity 0.3s ease-in-out'
    },
    '&:hover::after': {
      opacity: 1
    },
    '& .__with-icon': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      gap: '15px'
    },
    '& .__client-name': {
      fontWeight: 600,
      fontSize: '18px'
    },
    '& .__last-message-date': {
      position: 'absolute',
      top: '7px',
      right: '15px',
      fontSize: '10px',
      color: theme.palette.gray.A700
    },
    '& .__message-preview': {
      backgroundColor: theme.palette.card.shadow,
      margin: '15px -24px -14px',
      padding: '10px',
      boxShadow: '1px 3px 5px rgba(0, 0, 0, .1) inset',
      height: '40px',
      borderBottomLeftRadius: '16px',
      borderBottomRightRadius: '16px',
      textWrap: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden'
    }
  },
  cardContainer: {
    width: '400px',
    minHeight: '140px',
    padding: '.5rem',
    '&:hover': {
      textDecoration: 'none'
    }
  }
}))

const ClientConversationCard = ({ clientConversation, showPreview }) => {
  const classes = useStyles()
  const lastMessageDate = useMemo(() => {
    return clientConversation.lastMessageDate ? dayjs.utc(clientConversation.lastMessageDate).fromNow() : ''
  }, [clientConversation.lastMessageDate])

  return (
    <Link to={`/conversations/c/${clientConversation.clientId}/t/${clientConversation.conversationKey}`} className={classes.cardContainer}>
      <div className={classes.card}>
        <div className='__with-icon'>
          <FastAvatar
            size='lg'
            avatarUrl={clientConversation.avatarUrl}
            avatarType={clientConversation.avatarType}
            abbreviation={clientConversation.clientAbbreviation}
          />
          <div>
            <div className='__client-name'>{clientConversation.clientLongName}</div>
            <div>{clientConversation.name}</div>
          </div>
        </div>
        <div className='__last-message-date'>
          {lastMessageDate}
        </div>
        {showPreview ? (
          <div className='__message-preview'>
            {clientConversation.lastMessagePreview}
          </div>
        ) : null}
      </div>
    </Link>
  )
}

ClientConversationCard.propTypes = {
  showPreview: PropTypes.bool,
  clientConversation: PropTypes.shape({
    conversationKey: PropTypes.string,
    name: PropTypes.string,
    statusTypeId: PropTypes.string,
    firstActivityDate: PropTypes.string,
    lastActivityDate: PropTypes.string,
    lastMessageDate: PropTypes.string,
    lastMessagePreview: PropTypes.string,
    clientId: PropTypes.number,
    clientShortName: PropTypes.string,
    clientLongName: PropTypes.string,
    clientAbbreviation: PropTypes.string,
    avatarUrl: PropTypes.string,
    avatarType: PropTypes.string,
    clientAvatarIsPublic: PropTypes.oneOfType([PropTypes.bool, PropTypes.number])
  })
}

ClientConversationCard.defaultProps = {
  showPreview: true
}

export default ClientConversationCard
