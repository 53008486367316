import { createContext, useContext } from 'react'

export const TasksContext = createContext({
  tasks: []
})

export const useTasksContext = () => {
  const contextValue = useContext(TasksContext)
  return contextValue
}
