import React, { useEffect, useMemo } from 'react'
import { Box } from '@material-ui/core'
import PropTypes from 'prop-types'
import Icon from '../../../atoms/Icon'
import { useDynamicPlanning } from '../DynamicPlanningContext'
import Skeleton from '../../../atoms/Skeleton'

const DynamicPlanningChartSwitcher = ({ initialSelection, children }) => {
  const { isLoadingData, selectedChart, setSelectedChart } = useDynamicPlanning()

  const childrenDetails = useMemo(() => children?.map(child => ({
    key: child?.key,
    label: child?.props?.label ?? child?.props?.key,
    icon: child?.props?.icon ?? 'chart'
  })), [children])

  const activeChild = useMemo(() => {
    return children?.find(child => child?.key === selectedChart)
  }, [children, selectedChart])

  useEffect(() => {
    if (!selectedChart) {
      setSelectedChart(initialSelection)
    }
  }, [selectedChart, initialSelection, setSelectedChart])

  return (
    <Box display='flex' flexDirection='column'>
      <Box display='flex' flexDirection='row' justifyContent='flex-end' gridGap='1rem'>
        {childrenDetails?.map(({ key, label, icon }) => (
          <Box
            key={key}
            display='flex'
          >
            {isLoadingData ? <Skeleton key={key} width='2rem' height='2rem' /> : (
              <button
                type='button'
                style={{
                  cursor: 'pointer',
                  opacity: selectedChart === key ? 1 : 0.25
                }}
                title={label}
                onClick={() => setSelectedChart(key)}
              >
                <Icon name={icon} customSize='2rem' />
              </button>
            )}
          </Box>
        ))}
      </Box>
      <Box flex='1 0 0' style={{ minHeight: '450px' }}>
        {activeChild}
      </Box>
    </Box>
  )
}

DynamicPlanningChartSwitcher.propTypes = {
  initialSelection: PropTypes.string,
  children: PropTypes.node
}

export default DynamicPlanningChartSwitcher
