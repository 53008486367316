import WealthJourneyProvider from './v3/WealthJourneyProvider'
import WJLineChart from './v3/WJLineChart'
import EntryList from './v3/EntryList'
import EntryContent from './v3/EntryContent'
import WJDatePeriodPicker2 from './v3/WJDatePeriodPicker2'
import EntryLegend from './v3/EntryLegend'
import WJLoader from './v3/Loader'
import WJTabList from './v3/Tabs/WJTabList'
import WJTab from './v3/Tabs/WJTab'
import WJAddEntryButton from './v3/AddEntryButton'

const WealthJourneyModule = {
  WJ_LineChart: WJLineChart,
  WJ_YearPicker: WJDatePeriodPicker2,
  WJ_YearPicker2: WJDatePeriodPicker2,
  WJ_EntryLegend: EntryLegend,
  WJ_EntryList: EntryList,
  WJ_Content: EntryContent,
  WJ_Loader: WJLoader,
  WJ_TabList: WJTabList,
  WJ_Tab: WJTab,
  WJ_AddEntryButton: WJAddEntryButton,
  WealthJourneyProvider: WealthJourneyProvider
}

export default WealthJourneyModule
