import React from 'react'
import { Grid } from '@material-ui/core'
import PropTypes from 'prop-types'
import { useGroupingContext } from './GroupingContext'

const GroupingTab = ({ tabKey, children }) => {
  const {
    selectedGrouping
  } = useGroupingContext()

  if (selectedGrouping.key !== tabKey) return null

  return (
    <Grid item xs={12} style={{ paddingTop: 0 }}>
      {children}
    </Grid>
  )
}

GroupingTab.propTypes = {
  tabKey: PropTypes.string.isRequired,
  children: PropTypes.node
}

export default GroupingTab
