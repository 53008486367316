import React, { useCallback, useMemo, useState } from 'react'
import { makeStyles } from '@material-ui/core'
import { useCheckPolicy } from '../../../../../hooks'
import FastAvatar from '../../../../molecules/FastAvatar'
import { useWealthOwnerDetails } from '../ClientDetailsFormContext'
import ProfilePictureFileInput from '../../../../molecules/ProfilePictureFileInput'
import { useMediaUrl, useSaveMediaMutation } from '../../../../../api/media'
import { useAppContext } from '../../../../../redux/slices/appContext'
import { uploadDocument } from '../../../../../service'
import SectionScreen from '../SectionScreen'
import { useUpdateWealthOwnerMutation } from '../../../../../api/users'

const useStyles = makeStyles((theme) => ({
  profilePicture: {
    padding: '0 20px'
  }
}))

const useAutomaticUpload = ({ wealthOwner, canEdit }) => {
  const { userId } = useAppContext()
  const { mutateAsync: saveMedia } = useSaveMediaMutation()
  const { mutateAsync: saveUser } = useUpdateWealthOwnerMutation()
  const [uploadState, setUploadState] = useState({
    progress: null,
    uploaded: false,
    hasError: false,
    aborted: false
  })

  const isLoading = useMemo(() => {
    if (uploadState.uploaded) return false
    if (uploadState.aborted) return false

    return !!uploadState.progress
  }, [uploadState])

  const onAbort = useCallback(() => {
    setUploadState((prevState) => ({
      ...prevState,
      aborted: true,
      progress: 0
    }))
  }, [setUploadState])

  const onProgress = useCallback((event) => {
    setUploadState((prevState) => ({
      ...prevState,
      progress: (event.loaded / event.total) * 100
    }))
  }, [setUploadState])

  const autoUpload = useCallback(async (file) => {
    if (!canEdit) return
    if (!file?.name) return

    // STEP 1: Create the media and get the uploadUrl
    const data = await saveMedia({
      createdBy: userId, // TODO: service should handle this, not client
      fileName: file.name
    })

    const {
      mediaId,
      // fileName,
      uploadUrl
      // originalFileName
    } = data

    const xhr = new XMLHttpRequest()
    // STEP 2: Upload the image
    await uploadDocument(
      uploadUrl,
      file,
      onProgress,
      onAbort,
      xhr
    )

    setUploadState(prev => ({ ...prev, uploaded: true }))

    // STEP 3: Confirm the media upload
    // This step doesn't exist right now

    // STEP 4: Update the client
    await saveUser({
      user: {
        userId: wealthOwner.userId,
        externalUserId: wealthOwner.externalUserId,
        avatarMediaId: mediaId
      }
    })
  }, [wealthOwner, canEdit, saveMedia, userId, onAbort, onProgress, saveUser])

  return {
    autoUpload,
    isLoading
  }
}

function ProfilePicture () {
  const { wealthOwner } = useWealthOwnerDetails()
  const classes = useStyles()
  const canEdit = useCheckPolicy('admin_edit_wo_users')
  const { autoUpload, isLoading } = useAutomaticUpload({ wealthOwner, canEdit })
  const { data: mediaUrl } = useMediaUrl(wealthOwner?.avatarMediaId)

  return (
    <SectionScreen sectionName='profile_pic' className={classes.profilePicture}>
      {(canEdit) ? (
        <ProfilePictureFileInput
          ignoreDelete
          disabledOpacity={1}
          isLoading={isLoading}
          placeholderSrc={mediaUrl}
          onDropAccepted={autoUpload}
          label='Upload'
        />
      ) : (
        <FastAvatar
          size='xl'
          avatarUrl={mediaUrl}
        />
      )}
    </SectionScreen>
  )
}

ProfilePicture.propTypes = {
}

export default ProfilePicture
