import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import noop from 'lodash/noop'
import { isEmpty, isString } from 'lodash'
import { BUTTON_SIZES, DATE_FORMAT_US_LONG, TEXT_VARIANTS } from '../../constants'
import Select from './Select'

export const DATE_FORMAT = 'YYYY-MM-DD'

export const mapDateRangesToKeyValue = (relativeDateRangeOptions) => {
  return relativeDateRangeOptions.reduce(
    (acc, { key, dateRange }) => ({
      ...acc,
      [key]: {
        key,
        ...dateRange
      }
    }),
    {}
  )
}

const mapOption = ({ key, label, value, getStartDate }) => ({ key, label, value, getStartDate })

const DATE_PRESETS = {
  LY: (value) => ({
    unit: 'year',
    key: `L${value}Y`,
    label: `Last ${value} Years`,
    getStartDate: ({ mainDate, max }) => dayjs.utc(mainDate ?? max).subtract(value, 'year').format(DATE_FORMAT)
  }),
  LM: (value) => ({
    unit: 'month',
    key: `L${value}M`,
    label: `Last ${value} Years`,
    getStartDate: ({ mainDate, max }) => dayjs.utc(mainDate ?? max).subtract(value, 'month').format(DATE_FORMAT)
  })
}

const getDatePresets = (values, unitType) => {
  return values.map(value => {
    const { key, label, getStartDate } = DATE_PRESETS[unitType](value)
    return mapOption({ key, label, value: key, getStartDate })
  })
}

export const defaultLastNYearOptions = getDatePresets([2, 3, 5, 7], 'LY')

export const defaultOptions = [
  { key: 'Y', label: 'Yesterday', value: 'Y', getStartDate: ({ mainDate, max }) => dayjs.utc(mainDate ?? max).format(DATE_FORMAT) },
  { key: 'TW', label: 'This Week', value: 'TW', getStartDate: ({ mainDate, max }) => dayjs.utc(mainDate ?? max).startOf('week').format(DATE_FORMAT) },
  { key: 'L7D', label: 'Last 7 Days', value: 'L7D', getStartDate: ({ mainDate, max }) => dayjs.utc(mainDate ?? max).subtract(6, 'day').format(DATE_FORMAT) },
  { key: 'L30D', label: 'Last 30 Days', value: 'L30D', getStartDate: ({ mainDate, max }) => dayjs.utc(mainDate ?? max).subtract(29, 'day').format(DATE_FORMAT) },
  { key: 'L3M', label: 'Last 3 Months', value: 'L3M', getStartDate: ({ mainDate, max }) => dayjs.utc(mainDate ?? max).subtract(3, 'month').add(1, 'day').format(DATE_FORMAT) },
  { key: 'L6M', label: 'Last 6 Months', value: 'L6M', getStartDate: ({ mainDate, max }) => dayjs.utc(mainDate ?? max).subtract(6, 'month').add(1, 'day').format(DATE_FORMAT) },
  { key: 'L12M', label: 'Last 12 Months', value: 'L12M', getStartDate: ({ mainDate, max }) => dayjs.utc(mainDate ?? max).subtract(12, 'month').add(1, 'day').format(DATE_FORMAT) },
  { key: 'L1Y', label: 'Last Year', value: 'L1Y', getStartDate: ({ mainDate, max }) => dayjs.utc(mainDate ?? max).subtract(1, 'year').add(1, 'day').format(DATE_FORMAT) },
  { key: 'L2Y', label: 'Last 2 Years', value: 'L2Y', getStartDate: ({ mainDate, max }) => dayjs.utc(mainDate ?? max).subtract(2, 'year').add(1, 'day').format(DATE_FORMAT) },
  { key: 'L3Y', label: 'Last 3 Years', value: 'L3Y', getStartDate: ({ mainDate, max }) => dayjs.utc(mainDate ?? max).subtract(3, 'year').add(1, 'day').format(DATE_FORMAT) },
  { key: 'L4Y', label: 'Last 4 Years', value: 'L4Y', getStartDate: ({ mainDate, max }) => dayjs.utc(mainDate ?? max).subtract(4, 'year').add(1, 'day').format(DATE_FORMAT) },
  { key: 'L5Y', label: 'Last 5 Years', value: 'L5Y', getStartDate: ({ mainDate, max }) => dayjs.utc(mainDate ?? max).subtract(5, 'year').add(1, 'day').format(DATE_FORMAT) },
  { key: 'L6Y', label: 'Last 6 Years', value: 'L6Y', getStartDate: ({ mainDate, max }) => dayjs.utc(mainDate ?? max).subtract(6, 'year').add(1, 'day').format(DATE_FORMAT) },
  { key: 'L7Y', label: 'Last 7 Years', value: 'L7Y', getStartDate: ({ mainDate, max }) => dayjs.utc(mainDate ?? max).subtract(7, 'year').add(1, 'day').format(DATE_FORMAT) },
  { key: 'MTD', label: 'Month to Date', value: 'MTD', getStartDate: ({ mainDate, max }) => dayjs.utc(mainDate ?? max).startOf('month').format(DATE_FORMAT) },
  { key: 'QTD', label: 'Quarter to Date', value: 'QTD', getStartDate: ({ mainDate, max }) => dayjs.utc(mainDate ?? max).startOf('quarter').format(DATE_FORMAT) },
  { key: 'YTD', label: 'Year to Date', value: 'YTD', getStartDate: ({ mainDate, max }) => dayjs.utc(mainDate ?? max).startOf('year').format(DATE_FORMAT) },
  { key: 'SI', label: 'Since Inception', value: 'SI', getStartDate: ({ min }) => dayjs.utc(min).add(1, 'day').format(DATE_FORMAT) },
  { key: 'ASI', label: 'Since Inception', value: 'ASI', getStartDate: ({ min }) => dayjs.utc(min).format(DATE_FORMAT) }
]

export const DATE_RANGE_CUSTOM_OPTION_KEYS = {
  CUSTOM: 'CTM',
  CALENDAR_YEAR: 'CLY'
}

export const getCalendarYearOptions = (options) => {
  const {
    targetYear = dayjs().year(),
    optionsLength = 5,
    keyFormatter = undefined
  } = options || {}

  const startYear = targetYear - optionsLength
  return Array(optionsLength)
    .fill()
    .map((_, i) => {
      const rawValue = startYear + i
      const value = `${DATE_RANGE_CUSTOM_OPTION_KEYS.CALENDAR_YEAR}_${rawValue}`
      const key = keyFormatter
        ? keyFormatter(rawValue)
        : value
      return {
        key,
        label: rawValue.toString(),
        value: key,
        getStartDate: () => dayjs.utc().year(rawValue).startOf('year').format(DATE_FORMAT),
        getEndDate: () => dayjs.utc().year(rawValue).endOf('year').format(DATE_FORMAT)
      }
    })
}

export const defaultCalendarYearOptions = getCalendarYearOptions()

export const defaultCustomOptions = [
  {
    key: DATE_RANGE_CUSTOM_OPTION_KEYS.CALENDAR_YEAR,
    label: 'Calendar Year',
    value: DATE_RANGE_CUSTOM_OPTION_KEYS.CALENDAR_YEAR,
    isCustom: true
  },
  {
    key: DATE_RANGE_CUSTOM_OPTION_KEYS.CUSTOM,
    label: 'Custom',
    value: DATE_RANGE_CUSTOM_OPTION_KEYS.CUSTOM,
    isCustom: true
  }
]

export const parseCalendarYearDateRangeRawValue = (rawValue) => {
  const [key, index, value] = rawValue.split('_')
  return { key, index, value }
}

export const parseCustomDateRangeRawValue = (rawValue) => {
  const [key, index, startDate, endDate] = rawValue.split('_')
  return { key, index, startDate, endDate }
}

/***
 * Parses the relative date key from the accessor string
  * @param {string} accessorString
  * @param {boolean} includeIndex
  * @returns {string}
  */
export const parseRelativeDateKeyFromAccessor = (accessorString, includeIndex = true) => {
  const accessorArray = accessorString.split('_')
  if (accessorArray[0] === DATE_RANGE_CUSTOM_OPTION_KEYS.CALENDAR_YEAR) {
    const [key, index, value] = accessorArray
    return [key, ...(includeIndex ? [index] : []), value].join('_')
  }
  if (accessorArray[0] === DATE_RANGE_CUSTOM_OPTION_KEYS.CUSTOM) {
    const [key, index, startDate, endDate] = accessorArray
    return [key, ...(includeIndex ? [index] : []), startDate, endDate].join('_')
  }
  return defaultOptions.some(row => row.key === accessorArray[0]) ? accessorArray[0] : null
}

export const generateCustomOption = (option, startDate, endDate) => {
  const startDateFormatted = dayjs.utc(startDate).format(DATE_FORMAT_US_LONG)
  const endDateFormatted = dayjs.utc(endDate).format(DATE_FORMAT_US_LONG)
  const label = `${startDateFormatted} - ${endDateFormatted}`
  return {
    ...option,
    label,
    getStartDate: () => dayjs.utc(startDate).format(DATE_FORMAT),
    getEndDate: () => dayjs.utc(endDate).format(DATE_FORMAT)
  }
}

export const generateCalendarYearOption = (option, value) => {
  const label = value.toString()
  return {
    ...option,
    label,
    value: `${option.key}_${value}`,
    getStartDate: () => dayjs.utc().year(value).startOf('year').format(DATE_FORMAT),
    getEndDate: () => dayjs.utc().year(value).endOf('year').format(DATE_FORMAT)
  }
}

export const getCustomDateRangeOptionSelected = (groupingDateRangeValue) => {
  const selectedCustomOption = defaultCustomOptions.find(({ value }) =>
    groupingDateRangeValue?.toString()?.startsWith(value)
  )
  return selectedCustomOption
}

export const defaultDateRangeOptions = [
  ...defaultOptions,
  ...defaultLastNYearOptions,
  ...defaultCustomOptions
]

export const datePresetOptions = [
  ...defaultOptions,
  ...defaultLastNYearOptions,
  ...defaultCalendarYearOptions,
  ...defaultCustomOptions
]

export const getDefaultDateRangeOptions = (options, defaultOptions = defaultDateRangeOptions) => {
  if (isEmpty(options)) return defaultOptions
  if (options?.every(isString)) {
    return options.map((optionKey) =>
      defaultOptions.find(({ key }) => key === optionKey)
    ).filter(Boolean)
  }
  return options
}

export const useRelativeDateRange = (selectedValue, availableDates, options) => {
  const defaultedOptions = useMemo(
    () => getDefaultDateRangeOptions(options),
    [options]
  )

  const selectedOption = useMemo(
    () => {
      const { CALENDAR_YEAR, CUSTOM } = DATE_RANGE_CUSTOM_OPTION_KEYS
      if (selectedValue === CALENDAR_YEAR) return null
      if (selectedValue === CUSTOM) return null

      const isCustomValue = selectedValue?.startsWith(CUSTOM)
      const isCalendarYearValue = selectedValue?.startsWith(CALENDAR_YEAR)

      // ignore CUSTOM but allow its value (e.g. CTM_01_01_2021)
      if (isCustomValue && selectedValue !== CUSTOM) {
        const [key, startDate, endDate] = selectedValue.split('_')
        return generateCustomOption({ key }, startDate, endDate)
      }
      // ignore CALENDAR_YEAR but allow its value (e.g. CLY_2021)
      if (isCalendarYearValue && selectedValue !== CALENDAR_YEAR) {
        const [key, value] = selectedValue.split('_')
        return generateCalendarYearOption({ key }, value)
      }
      return [
        ...datePresetOptions,
        ...defaultedOptions
      ].find((x) => x.value === selectedValue)
    },
    [selectedValue, defaultedOptions]
  )

  const dateRange = useMemo(() => {
    if (availableDates && selectedOption) {
      return {
        key: selectedOption.key,
        startDate: selectedOption.getStartDate(availableDates),
        endDate: selectedOption?.getEndDate
          ? selectedOption?.getEndDate(availableDates)
          : dayjs.utc(availableDates.mainDate).format(DATE_FORMAT)
      }
    }
    return null
  }, [selectedOption, availableDates])

  return {
    value: selectedValue,
    valueLabel: selectedOption?.label,
    dateRange,
    options: defaultedOptions
  }
}

export const useRelativeDateRanges = (selectedValues, availableDates, options) => {
  const defaultedOptions = useMemo(
    () => getDefaultDateRangeOptions(options),
    [options]
  )

  const selectedOptions = useMemo(() => {
    return selectedValues.reduce((acc, selectedValue) => {
      let option = [...datePresetOptions, ...defaultedOptions].find(
        (x) =>
          x.value === selectedValue ||
          (x.isCustom && selectedValue.startsWith(x.value))
      )

      if (option?.isCustom && option.value === DATE_RANGE_CUSTOM_OPTION_KEYS.CUSTOM) {
        const { index, startDate, endDate } = parseCustomDateRangeRawValue(selectedValue)
        option = generateCustomOption({ ...option, key: `${option.key}_${index}` }, startDate, endDate)
      }

      if (option?.isCustom && option.value === DATE_RANGE_CUSTOM_OPTION_KEYS.CALENDAR_YEAR) {
        const { key, index, value: calendarYear } = parseCalendarYearDateRangeRawValue(selectedValue)
        const calendarYearKey = `${key}_${calendarYear}`
        const calendarYearOption = defaultCalendarYearOptions.find(({ value }) => value === calendarYearKey)
        option = { ...calendarYearOption, key: `${option.key}_${index}` }
      }

      return option ? [...acc, option] : acc
    }, [])
  }, [defaultedOptions, selectedValues])

  const dateRanges = useMemo(() => {
    if (!availableDates || !selectedOptions) return null

    return selectedOptions.map((selectedOption) => {
      return {
        key: selectedOption.key,
        label: selectedOption.label,
        dateRange:
          selectedOption.getStartDate
            ? {
              startDate: selectedOption?.getStartDate(availableDates),
              endDate: selectedOption?.getEndDate
                ? selectedOption?.getEndDate(availableDates)
                : dayjs.utc(availableDates.mainDate).format(DATE_FORMAT)
            }
            : null
      }
    }).filter(({ dateRange }) => Boolean(dateRange))
  }, [selectedOptions, availableDates])

  return {
    values: selectedValues,
    dateRanges,
    options: defaultedOptions
  }
}

export const useKeyedRelativeDateRanges = (selectedValues, availableDates, options) => {
  const defaultedOptions = useMemo(
    () => getDefaultDateRangeOptions(options),
    [options]
  )

  const selectedOptions = useMemo(() => {
    return Object.entries(selectedValues).reduce((acc, [selectedKey, selectedValue]) => {
      let option = [...datePresetOptions, ...defaultedOptions].find(
        (x) =>
          x.value === selectedValue ||
          (x.isCustom && selectedValue.startsWith(x.value))
      )

      if (option?.isCustom && option.value === DATE_RANGE_CUSTOM_OPTION_KEYS.CUSTOM) {
        const { index, startDate, endDate } = parseCustomDateRangeRawValue(selectedValue)
        option = generateCustomOption({ ...option, key: `${option.key}_${index}` }, startDate, endDate)
      }

      if (option?.isCustom && option.value === DATE_RANGE_CUSTOM_OPTION_KEYS.CALENDAR_YEAR) {
        const { key, index, value: calendarYear } = parseCalendarYearDateRangeRawValue(selectedValue)
        const calendarYearKey = `${key}_${calendarYear}`
        const calendarYearOption = defaultCalendarYearOptions.find(({ value }) => value === calendarYearKey)
        option = { ...calendarYearOption, key: `${option.key}_${index}` }
      }

      if (option) {
        acc[selectedKey] = option
      }
      return acc
    }, {})
  }, [defaultedOptions, selectedValues])

  const dateRanges = useMemo(() => {
    if (!availableDates || !selectedOptions) return null

    return Object.entries(selectedOptions).reduce((prev, [selectedKey, selectedOption]) => {
      const dateRange = selectedOption.getStartDate
        ? {
          startDate: selectedOption?.getStartDate(availableDates),
          endDate: selectedOption?.getEndDate
            ? selectedOption?.getEndDate(availableDates)
            : dayjs.utc(availableDates.mainDate).format(DATE_FORMAT)
        }
        : null
      if (dateRange) {
        prev[selectedKey] = {
          key: selectedOption.key,
          label: selectedOption.label,
          dateRange
        }
      }

      return prev
    }, {})
  }, [selectedOptions, availableDates])

  return {
    values: selectedValues,
    dateRanges,
    options: defaultedOptions
  }
}

const RelativeDateSelect = ({
  options,
  defaultValue,
  disabled,
  onDateRangeSelected,
  selectedValue,
  availableDates,
  selectClasses
}) => {
  const {
    options: defaultedOptions,
    value
  } = useRelativeDateRange(selectedValue, availableDates, options)

  return (
    <Select
      value={value || defaultValue}
      size={BUTTON_SIZES.small}
      onChange={onDateRangeSelected}
      className={selectClasses}
      options={defaultedOptions}
      disabled={!availableDates || disabled}
      showCheckMarOnSelectedItems
    />
  )
}

RelativeDateSelect.propTypes = {
  disabled: PropTypes.bool,
  onDateRangeSelected: PropTypes.func,
  selectedValue: PropTypes.string,
  defaultValue: PropTypes.string,
  availableDates: PropTypes.shape({
    min: PropTypes.string,
    mainDate: PropTypes.string
  }),
  options: PropTypes.array,
  selectClasses: PropTypes.string
}

RelativeDateSelect.defaultProps = {
  disabled: false,
  onChange: noop,
  selectedValue: undefined,
  textVariant: TEXT_VARIANTS.body1,
  addCustomDateOpt: true,
  defaultValue: undefined,
  selectClasses: undefined
}

export default RelativeDateSelect
