import React, { forwardRef, useCallback, useImperativeHandle, useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import Dialog from '../../../molecules/Dialog/Dialog'
import RoundedButton from '../../../atoms/RoundedButton'

const useStyles = makeStyles((theme) => ({
  button: {
    borderRadius: '30px',
    fontWeight: 800,
    padding: '14px 16px',
    width: '280px',
    marginRight: '10px',
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none'
    }
  },
  cancelBtn: {
    backgroundColor: theme.palette.lightCoral,
    color: theme.palette.error.main,
    '&:hover': {
      backgroundColor: '#f3b3be'
    }
  },
  confirmBtn: {
    backgroundColor: theme.palette.gray.light,
    color: theme.palette.lightBlack,
    '&:hover': {
      backgroundColor: theme.palette.gray.dark
    }
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center'
  }
}))

const StyledDialog = forwardRef(function StyledDialog ({
  onConfirm,
  confirmText,
  onSpecial,
  specialText,
  cancelText,
  children,
  title,
  disabled,
  processing,
  size
}, ref) {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const handleConfirm = useCallback(async (e) => {
    await onConfirm(e)
    setOpen(false)
  }, [setOpen, onConfirm])
  const handleSpecial = useCallback(async () => {
    await onSpecial()
    setOpen(false)
  }, [setOpen, onSpecial])

  useImperativeHandle(ref, () => {
    return {
      open: () => setOpen(true),
      close: () => setOpen(false)
    }
  }, [setOpen])

  return (
    <Dialog
      fullWidth
      size={size}
      open={open}
      onClose={() => setOpen(false)}
      variant='sharp'
    >
      {title ? (
        <Dialog.Header>
          {title}
        </Dialog.Header>
      ) : null}
      <Dialog.Body>
        {children}
      </Dialog.Body>
      <Dialog.Actions>
        <div className={classes.actions}>
          <RoundedButton
            onClick={() => setOpen(false)}
            disabled={disabled || processing}
            isLoading={processing}
            className={clsx(classes.button, classes.cancelBtn)}
          >
            {cancelText}
          </RoundedButton>
          <RoundedButton
            onClick={handleConfirm}
            disabled={disabled || processing}
            isLoading={processing}
            className={clsx(classes.button, classes.confirmBtn)}
          >
            {confirmText}
          </RoundedButton>
          {onSpecial ? (
            <RoundedButton
              onClick={handleSpecial}
              disabled={disabled || processing}
              isLoading={processing}
              className={clsx(classes.button, classes.confirmBtn)}
            >
              {specialText}
            </RoundedButton>
          ) : null}
        </div>
      </Dialog.Actions>
    </Dialog>
  )
})

StyledDialog.propTypes = {
  onConfirm: PropTypes.func,
  onSpecial: PropTypes.func,
  children: PropTypes.node,
  confirmText: PropTypes.string,
  cancelText: PropTypes.string,
  specialText: PropTypes.string,
  title: PropTypes.string,
  disabled: PropTypes.bool,
  processing: PropTypes.bool,
  size: PropTypes.oneOf(['sm', 'md', 'lg', 'xl'])
}

StyledDialog.defaultProps = {
  confirmText: 'OK',
  cancelText: 'Cancel',
  disabled: false,
  processing: false,
  size: 'sm'
}

export default StyledDialog
