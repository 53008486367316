import React, { useCallback, useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Box, useTheme } from '@material-ui/core'
import isEmpty from 'lodash/isEmpty'
import last from 'lodash/last'
import { useFetchState } from '../../hooks'
import useDatePresetOptions from '../../hooks/useDatePresetOptions'
import { useAppContext } from '../../redux/slices/appContext'
import { fetchAssets } from '../../service'
import Select from '../atoms/Select'
import Text from '../atoms/Text'
import {
  DAYJS_OPERATIONS,
  DAYJS_UNIT_TYPES,
  LEVEL_TYPES,
  DATE_RANGES,
  TEXT_VARIANTS
} from '../../constants'
import { getSafeDate } from '../../utils'
import AssetHoldingList from './AssetHoldingList'

const PortfolioAllocationTopHoldingsList = ({
  title,
  viewFilters,
  viewContext,
  fetchParams,
  assetHoldingListProps,
  endingValueDateRange
}) => {
  const theme = useTheme()
  const appContext = useAppContext()
  const { mainDate } = viewContext
  const dateRange = last(endingValueDateRange.split('_'))

  const { dateValue: startDate } = useDatePresetOptions(dateRange)
  const dateRangeLabel = DATE_RANGES.find((item) => item.key === dateRange)?.label

  const SELECT_OPTIONS = [
    { label: `Value - ${dateRangeLabel}`, value: 'endingValue' },
    { label: 'Additions', value: 'additions' },
    {
      label: 'Performance - 3 month',
      value: 'returnValue 3months'
    },
    {
      label: 'Performance - 12 month',
      value: 'returnValue 12months'
    }
  ]

  const [holdingsFilter, setHoldingsFilter] = useState({
    fieldValue: 'endingValue',
    rawValue: 'endingValue',
    dateFilter: getSafeDate(appContext?.availableDates, {
      useMin: true
    })
  })

  const holdingsDateOptions = useMemo(
    () => ({
      '3months': getSafeDate(appContext.availableDates, {
        operation: DAYJS_OPERATIONS.SUBTRACT,
        unitType: DAYJS_UNIT_TYPES.MONTH,
        unitValue: 3
      }),
      '12months': getSafeDate(appContext.availableDates, {
        operation: DAYJS_OPERATIONS.SUBTRACT,
        unitType: DAYJS_UNIT_TYPES.MONTH,
        unitValue: 12
      })
    }),
    [appContext.availableDates]
  )

  const fetchTopHoldings = useCallback(
    async (setSafeState) => {
      const assetClassTagIds = viewFilters?.ASSET_CLASSES
        ? [viewFilters?.ASSET_CLASSES?.id]
        : []

      const subclassTagIds = viewFilters?.ASSETS_SUBCLASS
        ? [viewFilters?.ASSETS_SUBCLASS?.id]
        : []

      const assetIds = viewFilters?.ASSETS
        ? [viewFilters?.ASSETS?.id]
        : []

      const levelTypes = [
        LEVEL_TYPES.CLIENT,
        LEVEL_TYPES.ASSET_CLASS_TAG,
        LEVEL_TYPES.ASSETS
      ]

      const { data: assets } = await fetchAssets({
        limit: 5,
        startDate:
          holdingsFilter.fieldValue === 'endingValue'
            ? startDate
            : holdingsFilter.dateFilter,
        endDate: mainDate,
        clientIds: [appContext.clientId],
        assetClassTagIds,
        subclassTagIds,
        assetIds,
        levelTypes,
        orderBy: holdingsFilter.rawValue,
        calcType:
          holdingsFilter.fieldValue === 'endingValue'
            ? 'balance'
            : 'performance',
        ...(isEmpty(fetchParams) ? {} : fetchParams)
      })
      setSafeState({
        topHoldings: assets.map((asset) => ({
          id: asset.id,
          name: asset.names?.assetName || 'N/A',
          symbol: asset.symbol || 'N/A',
          percentage: {
            value: asset.assetPercentage || 0,
            format: '0%'
          },
          balance: { value: asset.balance || 0 },
          balanceChange: { value: asset.balanceChange || 0 },
          balanceChangeStatus: asset.balanceChangeStatus
        }))
      })
    },
    [
      appContext.clientId,
      holdingsFilter,
      viewFilters,
      fetchParams,
      mainDate,
      startDate
    ]
  )

  const onChangeTopHoldingsSelect = useCallback(
    ({ target: { value } }) => {
      return (([orderBy, dateFilter], value) =>
        setHoldingsFilter({
          fieldValue: value,
          rawValue: orderBy,
          dateFilter: dateFilter
            ? holdingsDateOptions[dateFilter]
            : viewContext.mainDate
        }))(value?.split(' '), value)
    },
    [holdingsDateOptions, viewContext.mainDate]
  )

  const { topHoldings } = useFetchState(fetchTopHoldings)

  return (
    <Box>
      <Box display='flex' flexDirection='row' alignItems='center'>
        <Text
          text={title}
          color={theme.palette.cloudBurst}
          customFontFamily='Gotham-Book'
          customFontSize='1rem'
          textTransform='uppercase'
          variant={TEXT_VARIANTS.subtitle2}
        />
        <Box ml='auto'>
          <Select
            options={SELECT_OPTIONS}
            selectedValue={holdingsFilter.fieldValue}
            onChange={onChangeTopHoldingsSelect}
          />
        </Box>
      </Box>
      <AssetHoldingList
        assetHoldings={topHoldings}
        {...assetHoldingListProps}
      />
    </Box>
  )
}

PortfolioAllocationTopHoldingsList.propTypes = {
  title: PropTypes.string,
  viewFilters: PropTypes.object,
  viewContext: PropTypes.object,
  setViewContext: PropTypes.func,
  fetchParams: PropTypes.object,
  assetHoldingListProps: PropTypes.object,
  endingValueDateRange: PropTypes.object
}

export default PortfolioAllocationTopHoldingsList
