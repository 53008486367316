import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { useHistory } from 'react-router-dom'
import {
  TextField,
  Typography,
  Box
} from '@material-ui/core'
import { Controller, useForm } from 'react-hook-form'
import { useOktaAuth } from '@okta/okta-react'

import Text from '../../atoms/Text'
import RoundedButton from '../../atoms/RoundedButton'
import Select from '../../molecules/Select'

import { BUTTON_SIZES } from '../../../constants'
import { useToggle } from '../../../hooks'
import {
  setUserPassword,
  setRecoveryQuestion,
  getSecurityQuestions
} from '../../../service'
import { usePublicStyles } from '../../pages/publicViews/usePublicStyles'
import FadeIn from '../../molecules/Transitions/FadeIn'

const SecurityQuestionsForm = ({ logoPath, authInfo, password, isLoginFlow }) => {
  const classes = usePublicStyles()
  const history = useHistory()
  const { oktaAuth } = useOktaAuth()

  const [error, setError] = useState(null)
  const [questions, setQuestions] = useState([])
  const [loading, , setLoadingOn, setLoadingOff] = useToggle()

  const inputProps = {
    className: classes.inputBase,
    classes: {
      input: classes.input
    },
    disableUnderline: true
  }

  const loadSecurityQuestion = useCallback(async () => {
    try {
      const { data: questions } = await getSecurityQuestions()

      const securityQuestions = questions.map((item) => ({
        value: item.question,
        label: item.questionText
      }))

      setQuestions(securityQuestions)
    } catch (error) {
      console.error(error)
    }
  }, [])

  useEffect(() => {
    if ((authInfo?.email && authInfo?.activationToken) || isLoginFlow) {
      loadSecurityQuestion()
    }
  }, [loadSecurityQuestion, authInfo, isLoginFlow])

  const {
    handleSubmit,
    control,
    formState: { isSubmitting, isDirty, isValid, errors }
  } = useForm({
    mode: 'onChange',
    defaultValues: { securityQuestion: '', answer: '' }
  })

  const onSubmitPasswordHandler = useCallback(
    async (values) => {
      try {
        setLoadingOn()
        const body = {
          password,
          email: authInfo.email,
          acceptedTC: true,
          activationToken: authInfo.activationToken,
          securityQuestion: values.securityQuestion,
          securityAnswer: values.answer
        }
        await setUserPassword(body)
        history.push('/login')
      } catch (err) {
        setLoadingOff()
        const errorMessage = err.code === 401
          ? 'Token has expired. Please ask your administrator to reactivate your account'
          : 'Set new password failed, please contact your administrator'
        setError(errorMessage)
      }
    },
    [
      setLoadingOn,
      setLoadingOff,
      password,
      authInfo,
      history
    ]
  )

  const onSubmitSecurityQuestion = useCallback(
    async (values) => {
      try {
        setLoadingOn()
        const body = {
          email: encodeURIComponent(authInfo.email),
          securityQuestion: values.securityQuestion,
          securityAnswer: values.answer,
          password: authInfo.password
        }
        await setRecoveryQuestion(body)
        oktaAuth.signInWithRedirect({ sessionToken: authInfo.sessionToken })
      } catch (err) {
        console.error(err)
      } finally {
        setLoadingOff()
      }
    },
    [
      setLoadingOn,
      setLoadingOff,
      oktaAuth,
      authInfo
    ]
  )

  return (
    <FadeIn className={classes.formWrapper}>
      <Box
        display='flex'
        flexDirection='column'
        alignItems='center'
        width='100%'
        marginBottom='50px'
      >
        {logoPath && <img src={logoPath} alt='logo' className={classes.logo} />}
      </Box>
      <Text
        text='Please set a password recovery question and answer'
        className={classes.resetTitle}
      />
      <form onSubmit={handleSubmit(isLoginFlow ? onSubmitSecurityQuestion : onSubmitPasswordHandler)} className={classes.form}>
        <Box className={classes.fieldContainer} width='100%'>
          <Controller
            name='securityQuestion'
            render={({ field: { onChange, value } }) => (
              <Select
                placeholder='Security Question'
                variant='outlined'
                size='medium'
                options={questions}
                onChange={onChange}
                value={value || ''}
                disabled={!questions.length}
                className={classes.select}
                fullWidth
                showCheckMarOnSelectedItems
                preventOptionsOverflowContainer
                customOpts={{
                  whiteHover: true,
                  textCenter: true
                }}
              />)}
            control={control}
            rules={{
              required: 'Choose security question'
            }}
          />
        </Box>
        <Box className={classes.fieldContainer}>
          <div
            className={clsx(classes.field, {
              [classes.fieldError]: errors.confirmPassword
            })}
          >
            <Controller
              name='answer'
              render={({ field: { onChange } }) => (
                <TextField
                  fullWidth
                  autoFocus
                  type='text'
                  onChange={onChange}
                  placeholder='Confirm answer'
                  error={Boolean(errors.answer)}
                  InputProps={inputProps}
                  disabled={!questions.length}
                />)}
              control={control}
              rules={{
                required: 'Answer is required'
              }}
            />
          </div>
        </Box>
        {error && <Typography color='error'>{error}</Typography>}
        <RoundedButton
          primary
          fullWidth
          type='submit'
          size={BUTTON_SIZES.large}
          className={classes.signInButton}
          isLoading={loading}
          disabled={!isDirty || !isValid || loading || isSubmitting}
        >
          Save
        </RoundedButton>
      </form>
    </FadeIn>
  )
}

SecurityQuestionsForm.propTypes = {
  authInfo: PropTypes.object,
  password: PropTypes.string,
  isLoginFlow: PropTypes.bool,
  logoPath: PropTypes.string.isRequired
}

export default SecurityQuestionsForm
