import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Link, useLocation } from 'react-router-dom'

function RelativeLink ({ to, children, ...rest }) {
  const location = useLocation()
  const where = useMemo(() => {
    if (!to) return null

    // handle location descriptor
    if (typeof to === 'function') {
      return to
    }

    if (typeof to === 'string') {
      return to.startsWith('/')
        ? to
        : `${location.pathname}/${to}`
    }

    if ('pathname' in to) {
      return {
        ...to,
        pathname: to.pathname.startsWith('/')
          ? to.pathname
          : `${location.pathname}/${to.pathname}`
      }
    }

    return to
  }, [to, location])

  if (!where) {
    return (
      <div {...rest}>
        {children}
      </div>
    )
  }

  return (
    <Link to={where} {...rest}>
      {children}
    </Link>
  )
}

RelativeLink.propTypes = {
  to: PropTypes.oneOf([
    PropTypes.string,
    PropTypes.shape({
      pathname: PropTypes.string,
      search: PropTypes.string,
      hash: PropTypes.string,
      state: PropTypes.object
    }),
    PropTypes.func
  ]),
  children: PropTypes.node.isRequired
}

export default RelativeLink
