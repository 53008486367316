import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import Icon from '../../atoms/Icon'
import { mapInputStyles } from './styles'

const ICON_SIZES = {
  sm: 18,
  lg: 22
}

const useStyles = makeStyles((theme) => ({
  sydReadOnly: mapInputStyles(theme, {
    minWidth: '300px',
    width: '100%',
    border: `1px solid ${theme.palette.gray.dark}`,
    borderRadius: '4px',
    backgroundColor: theme.palette.white,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    transition: 'outline 200ms ease-in-out',
    outline: '1px solid transparent',
    '&:has(:-internal-autofill-selected)': {
      backgroundColor: 'rgb(232, 240, 254)'
    },
    '&:focus-within': {
      outline: `1px solid ${theme.palette.primary.main}`
    },
    '& .__icon': {
      marginRight: '5px'
    }
  })
}))

function SydReadOnly ({
  className,
  iconName,
  size,
  disabled,
  children,
  minWidth = '300px'
}) {
  const classes = useStyles({ size, disabled, minWidth })

  return (
    <div className={clsx(classes.sydReadOnly, className)}>
      {iconName ? (
        <Icon additionalClasses='__icon' name={iconName} customSize={ICON_SIZES[size]} />
      ) : null}
      <div>
        {children}
      </div>
    </div>
  )
}

SydReadOnly.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  iconName: PropTypes.string,
  disabled: PropTypes.bool,
  size: PropTypes.oneOf(['sm', 'lg']),
  minWidth: PropTypes.string
}

SydReadOnly.defaultProps = {
  size: 'lg'
}

export default SydReadOnly
