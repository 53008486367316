import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import SydModal from '../../commonDesign/SydModal'
import PresentationTable from '../PresentationTable'
import SydButton from '../../commonDesign/Button'
import { setStepContext, setStepNavigation, setToggleIsOpen } from '../../../redux/slices/addAccountContext'
import { useAppContext } from '../../../redux/slices/appContext'
import { useGetAccountStatuses } from '../../../api/accounts'
import LoadingPlaceholder from '../AddAccount/LoadingPlaceholder'
import Alert from '../../atoms/Alert'
import { cellTypeMap, defaultLabels, defaultTableColumns } from './helpers'

const AccountStatusDetailsModal = ({
  open,
  onClose,
  labels = defaultLabels,
  integrationKeys = ['byall'],
  tableColumns: _tableColumns = defaultTableColumns,
  filterToClient = true,
  errorsOnly = true,
  defaultSort = [{ id: 'accountName', desc: true }],
  updateLinkingRoute
}) => {
  const dispatch = useDispatch()
  const appContext = useAppContext()

  const handleOnAccountFix = useCallback((credentialData) => {
    if (!credentialData?.credentialId) {
      return
    }
    const route = `/edit-credential;credentialId=${credentialData?.credentialId}`
    if (updateLinkingRoute) {
      updateLinkingRoute(route)
    } else {
      dispatch(setStepContext({ stepContext: { route } }))
      dispatch(setStepNavigation({ key: 'byall_linkingComponent' }))
      dispatch(setToggleIsOpen({ isOpen: true }))
    }
    onClose()
  }, [dispatch, onClose, updateLinkingRoute])

  const queryOptions = useMemo(() => {
    return {
      enabled: (filterToClient && !!appContext?.clientId) && !!integrationKeys?.length
    }
  }, [appContext?.clientId, integrationKeys, filterToClient])

  const queryParams = useMemo(() => {
    return {
      ...(filterToClient && { assignedToClientIds: [appContext?.clientId] }),
      filters: {
        integrationKey: [{ op: 'in', value: integrationKeys }],
        ...(errorsOnly && { isError: [{ op: 'eq', value: errorsOnly }] })
      },
      includes: {
        actionableUser: true
      },
      resultType: 'rows',
      take: 1000,
      skip: 0
    }
  }, [appContext?.clientId, integrationKeys, errorsOnly, filterToClient])
  const { data, isLoading, isError } = useGetAccountStatuses(queryOptions, queryParams)

  const tableColumns = useMemo(() => ([
    ..._tableColumns.map(column => ({
      ...column,
      ...(column.cellType && cellTypeMap[column.cellType] && { Cell: cellTypeMap[column.cellType] })
    })),
    {
      Header: '',
      accessor: 'userCanAct',
      Cell: (value) => {
        const { credentialData } = value.cell?.row?.original ?? {}
        const userCanAct = value.cell?.value ?? false
        if (!userCanAct || !credentialData?.credentialId) {
          return labels.noUserActionAvailable
        }
        return (
          <SydButton size='sm' variant='primary' onClick={() => handleOnAccountFix(credentialData)}>
            {labels.userActionToggle}
          </SydButton>
        )
      }
    }
  ]), [_tableColumns, handleOnAccountFix, labels.noUserActionAvailable, labels.userActionToggle])

  const tableData = useMemo(() => data?.accounts?.map(account => {
    const actionableUser = account?.actionableUser?.externalUserId ?? null
    const statusData = account?.statusData?.message ?? null
    const userCanAct = account.isError &&
      account.isUserActionable &&
      actionableUser === appContext.userId &&
      ['byall'].includes(account.integrationKey)

    return {
      ...account,
      actionableUser,
      statusData,
      userCanAct
    }
  }), [data, appContext.userId])

  return (
    <SydModal
      open={open}
      onClose={() => onClose()}
      title={labels.modalTitle}
      allowMaximize
      size='xmedium'
    >
      {isLoading
        ? (
          <LoadingPlaceholder />
        ) : isError ? (
          <Alert severity='error'>There was an issue loading this data.  Try again</Alert>
        ) : (
          <PresentationTable.Wrapper>
            <PresentationTable
              data={tableData}
              columns={tableColumns}
              defaultSort={defaultSort}
              sortable
            />
          </PresentationTable.Wrapper>
        )}
    </SydModal>
  )
}

AccountStatusDetailsModal.propTypes = {
  filterToClient: PropTypes.bool,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  labels: PropTypes.shape({
    modalTitle: PropTypes.string
  }),
  integrationKeys: PropTypes.arrayOf(PropTypes.string),
  tableColumns: PropTypes.arrayOf(PropTypes.object),
  errorsOnly: PropTypes.bool,
  defaultSort: PropTypes.arrayOf(PropTypes.object),
  updateLinkingRoute: PropTypes.func
}

export default AccountStatusDetailsModal
