import React, { useCallback, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import first from 'lodash/first'
import clsx from 'clsx'
import isEmpty from 'lodash/isEmpty'
import SelectWithSearch from '../../../molecules/Select/SelectWithSearch'
import Text from '../../../atoms/Text'
import Avatar from '../../../atoms/Avatar'
import { useBoolean, useCurrentUser } from '../../../../hooks'
import { ScopeDisplayItem } from '../ScopeDisplay'
import useSearchGroupsDebounced from '../../../../hooks/useSearchGroupsDebounced'
import Icon from '../../../atoms/Icon'
import { ICON_NAMES } from '../../../../constants'
import ScopeDisplaySkeleton from './ScopeDisplaySkeleton'

const mapGroup = (group) => {
  const { longName, profile, profilePictureUrl } = group
  return {
    name: longName,
    avatarSrc: profile?.profileData?.profilePic || profilePictureUrl
  }
}

const useStyles = makeStyles(() => ({
  scopeDisplay: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '5px 0',
    '& > *': {
      padding: '0 5px'
    }
  },
  item: {
    cursor: 'pointer',
    padding: '0.5rem 0.75rem',
    display: 'flex',
    gap: '0.5rem',
    flexDirection: 'row',
    alignItems: 'center',
    '&:hover': {
      background: '#F5F6F8'
    }
  },
  pinnedItem: {
    padding: '0.75rem 0.75rem'
  }
}))

const ADVISOR_LEVEL_ID = 301

const GroupScopeSelector = ({ scope, onChange, label }) => {
  const classes = useStyles({ hasHeadingLabel: !isEmpty(label) })

  const scopeQuery = useMemo(() => {
    return {
      filters: {
        levelTypeId: [
          {
            value: scope ? scope.groupTypeId : ADVISOR_LEVEL_ID,
            op: 'eq'
          }
        ]
      }
    }
  }, [scope])

  const {
    options: groupOptions,
    isLoading,
    onChangeQuery,
    defaultOptions
  } = useSearchGroupsDebounced({ query: scopeQuery, defaultGroupIds: scope?.values || [] })

  const [isSelectSearchBarFocused, setIsSelectSearchBarFocused] = useBoolean()
  const [selectedGroup, setSelectedGroup] = useState(first(groupOptions))

  const { data: user, isLoading: isLoadingUser } = useCurrentUser()

  const pinnedOptions = useMemo(() => {
    if (!user) return []
    const { userId, fullName, profilePictureUrl } = user
    return [
      {
        value: `${userId}-pinned-value`,
        label: fullName,
        payload: {
          groupId: null,
          longName: fullName,
          profilePictureUrl
        }
      }
    ]
  }, [user])

  const selectorLabelRenderer = useCallback(
    ({ selectedValue }) => {
      if (!selectedValue) return null
      const { payload } = selectedValue
      const group = mapGroup(payload)

      return (
        <div className={classes.displayName}>
          <ScopeDisplayItem
            name={group.name}
            avatarSrc={group.avatarSrc}
            label={label}
            extraStyles={!label ? { alignItems: 'center' } : null}
          />
        </div>
      )
    },
    [label, classes.displayName]
  )

  const selectorOptionsRenderer = useCallback(
    (option, onChange, checkedIcon) => {
      const { payload: group } = option
      if (!group?.groupId) {
        return (
          <div
            className={clsx(classes.item, classes.pinnedItem)}
            onClick={(event) => onChange(event, option.value)}
          >
            <Icon customSize='1.5rem' name={ICON_NAMES.back} />
            <Text customFontWeight='bold'>Back to My View</Text>
          </div>
        )
      }
      const { name, avatarSrc } = mapGroup(group)
      return (
        <div
          key={group.groupId}
          className={classes.item}
          onClick={(event) => onChange(event, option.value)}
        >
          {checkedIcon}
          <Avatar
            customSize='2rem'
            customFontSize='1rem'
            avatarLetters={name}
            isPublicResource
            src={avatarSrc}
          />
          <Text text={name} />
        </div>
      )
    },
    [classes.item, classes.pinnedItem]
  )

  const onClear = useCallback(() => onChangeQuery(''), [onChangeQuery])

  if ((isLoading || isLoadingUser) && !isSelectSearchBarFocused) {
    return <ScopeDisplaySkeleton />
  }

  return (
    <SelectWithSearch
      labelRenderer={selectorLabelRenderer}
      value={selectedGroup?.value}
      defaultValue={first(scope?.values) || first(pinnedOptions)?.value}
      onChange={(_, selectedOption) => {
        onChange(selectedOption?.payload)
        setSelectedGroup(selectedOption)
      }}
      options={groupOptions}
      optionsRenderer={selectorOptionsRenderer}
      isLoading={isLoading}
      onQueryChange={onChangeQuery}
      searchPlaceholder='Search by name'
      onBlur={setIsSelectSearchBarFocused.off}
      onFocus={setIsSelectSearchBarFocused.on}
      onClear={onClear}
      onClose={onClear}
      pinnedOptions={pinnedOptions}
      defaultOptions={defaultOptions}
    />
  )
}

GroupScopeSelector.propTypes = {
  scope: PropTypes.object,
  onChange: PropTypes.func,
  label: PropTypes.string
}

GroupScopeSelector.defaultProps = {
  label: undefined
}

export default GroupScopeSelector
