import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'
import Icon from '../atoms/Icon'

const useStyles = makeStyles(theme => ({
  notAvailable: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '30vh',

    '& .__hero': {
      fontSize: '2rem',
      margin: '2rem'
    }
  }
}))

const NotAvailable = () => {
  const classes = useStyles()

  return (
    <div className={classes.notAvailable}>
      <div><Icon name='security' customSize='8rem' /></div>
      <div className='__hero'>This page is not available</div>
      <div>
        <Link to='/'>Return Home</Link>
      </div>
    </div>
  )
}

export default NotAvailable
