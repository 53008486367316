import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { isFunction } from 'lodash'
import CardsLayout from '../AdvisorHomeCustomDatasetLayout/CardsLayout'
import ListLayout from '../AdvisorHomeCustomDatasetLayout/ListLayout'
import { CUSTOM_DATASET_LAYOUT } from './utils'
import { useKeyDatesContext } from './KeyDatesContextProvider'

const KeyDatesLayout = ({ variant, itemRenderer, emptyStateLabel }) => {
  const { data, isLoading } = useKeyDatesContext()

  const renderItem = useCallback(
    (datum) => {
      if (isFunction(itemRenderer)) {
        return itemRenderer(datum)
      }
      return React.cloneElement(itemRenderer, {
        ...itemRenderer.props,
        ...datum
      })
    },
    [itemRenderer]
  )

  if (variant === CUSTOM_DATASET_LAYOUT.CARDS) {
    return (
      <CardsLayout
        data={data}
        isLoading={isLoading}
        renderItem={renderItem}
        emptyStateLabel={emptyStateLabel}
      />
    )
  }
  if (variant === CUSTOM_DATASET_LAYOUT.LIST) {
    return (
      <ListLayout
        data={data}
        isLoading={isLoading}
        renderItem={renderItem}
        emptyStateLabel={emptyStateLabel}
      />
    )
  }

  return null
}

KeyDatesLayout.propTypes = {
  itemRenderer: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  emptyStateLabel: PropTypes.string,
  variant: PropTypes.oneOf(Object.values(CUSTOM_DATASET_LAYOUT))
}

export default KeyDatesLayout
