import React, { useEffect, useMemo } from 'react'
import { Grid, Container, Box, makeStyles } from '@material-ui/core'
import dayjs from 'dayjs'

import Text from '../../atoms/Text'
import AvailableDatePicker from '../../molecules/AvailableDatePicker'
import Skeleton from '../../atoms/Skeleton'
import PortfolioSummaryTable from '../../molecules/PortfolioSummaryTable'

import { useSetViewContext, useViewContext } from '../../../redux/slices/viewContext'
import { useAppContext } from '../../../redux/slices/appContext'
import { getSafeDate } from '../../../utils'
import { getPortfolioSummaryRows, getPortfolioFinalRow } from '../../../utils/tableHelper'

import { LEVEL_TYPES, SKELETON_VARIANTS, ASSET_FILTERS, TEXT_VARIANTS } from '../../../constants'
import { useGetGroupedCoreData } from '../../../api/coreData'

const useStyles = makeStyles(() => ({
  datePicker: {
    marginBottom: '36px'
  },
  skeleton: {
    position: 'relative',
    bottom: '90px'
  },
  balanceSheetContainer: {
    marginBottom: '40px',
    marginTop: '20px'
  },
  endContent: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  centerText: {
    textAlign: 'center'
  }
}))

const datePickerStyles = {
  '& input': {
    textAlign: 'left'
  },
  fontSize: '1rem',
  fontFamily: 'Gotham-Book'
}

const SUMMARY_TABLE_COLUMNS = [
  { alignment: 'center', name: 'Weight' },
  { alignment: 'left', name: 'Description' },
  { name: 'Quantity' },
  { name: 'Current Value' },
  { name: 'Current Price' },
  { name: 'Cost Basis' },
  { name: 'Unit Cost' },
  { name: 'Unrealized Gain (Loss)' },
  { name: 'Annual Income' },
  { colSpan: 2, name: 'Current Yield' }
]

const PortfolioSummary = () => {
  const classes = useStyles()
  const { clientId, availableDates } = useAppContext()
  const setViewContext = useSetViewContext()
  const viewContext = useViewContext()

  const { mainDate } = availableDates

  const query = useMemo(
    () => ({
      dateRange: {
        startDate: viewContext.mainDate,
        endDate: viewContext.mainDate
      },
      levelFilters: {
        clientIds: [clientId],
        levelTypes: [LEVEL_TYPES.CLIENT, LEVEL_TYPES.ASSET_CLASS_TAG],
        assetClassTagIds: [],
        assetIds: []
      }
    }),
    [clientId, viewContext.mainDate]
  )

  const { data: portfolioSummary = [], isLoading } = useGetGroupedCoreData(query)

  const onChangeDate = (date) => {
    setViewContext({
      mainDate: dayjs(date).format('YYYY-MM-DD')
    })
  }

  const balanceInformationDateFilters = {
    balanceInformationToday: {
      startDate: viewContext.selectedDate,
      endDate: viewContext.mainDate
    }
  }

  useEffect(() => {
    setViewContext({
      assetClassId: null,
      selectedDate: getSafeDate(availableDates, { useMin: true }),
      mainDate
    })
  }, [mainDate, availableDates, setViewContext])

  return (
    <Container maxWidth='xl'>
      <Box sx={{ flexGrow: 1 }} pt={4}>
        <Grid container direction='row' justifyContent='flex-start' alignItems='center' className={classes.container}>
          {
            isLoading ? (
              <>
                <Skeleton width='100%' height='200px' variant={SKELETON_VARIANTS.text} className={classes.skeleton} />
                <Skeleton width='100%' height='200px' variant={SKELETON_VARIANTS.text} className={classes.skeleton} />
                <Skeleton width='100%' height='200px' variant={SKELETON_VARIANTS.text} className={classes.skeleton} />
              </>
            ) : (
              <Grid container direction='row' justifyContent='flex-start' alignItems='center'>
                <Grid item xs={12} className={classes.centerText}>
                  <Text
                    text='Portfolio Summary'
                    customFontSize='24px'
                    customFontWeight={600}
                    variant={TEXT_VARIANTS.body1}
                  />
                </Grid>
                <Grid item xs={12} className={classes.datePicker}>
                  <AvailableDatePicker
                    disableFuture
                    value={viewContext.mainDate}
                    onChange={onChangeDate}
                    type='date'
                    format='[As of] MMMM DD, YYYY'
                    extraStyles={datePickerStyles}
                  />
                </Grid>
                <Grid item xs={12}>
                  <PortfolioSummaryTable
                    forceExpand
                    maxExpandibleDepthLevel={3}
                    classType={ASSET_FILTERS.SUMMARY_SUBCLASSES}
                    balanceInformationDateFilters={balanceInformationDateFilters}
                    columns={SUMMARY_TABLE_COLUMNS}
                    rows={getPortfolioSummaryRows(portfolioSummary)}
                    finalRow={getPortfolioFinalRow(portfolioSummary)}
                  />
                </Grid>
              </Grid>
            )
          }
        </Grid>
      </Box>
    </Container>
  )
}

PortfolioSummary.propTypes = {}

export default PortfolioSummary
