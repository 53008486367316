import React from 'react'
import { InputAdornment, IconButton } from '@material-ui/core'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'

import Icon from '../components/atoms/Icon'

import { ICON_NAMES } from '../constants'

export const getInputProps = (classes, key, toggleVisility, showPasswords) => ({
  startAdornment: (
    <InputAdornment position='start' className={classes.inputIcon}>
      <Icon
        name={ICON_NAMES.lock}
        additionalClasses={classes.customIconSize}
      />
    </InputAdornment>
  ),
  className: classes.inputBase,
  classes: {
    input: classes.input
  },
  disableUnderline: true,
  endAdornment: (
    <InputAdornment position='end'>
      <IconButton
        aria-label='password visibility'
        onClick={() => toggleVisility((key))}
      >
        {showPasswords[key] ? <Visibility /> : <VisibilityOff />}
      </IconButton>
    </InputAdornment>
  )
})

export const passShouldNotContainUser = (username, password) => {
  if (!username || !password) return true

  // eslint-disable-next-line no-useless-escape
  const REGEX_SPECIAL_CHAR = /[\!\#\$\%\^\&\*\)\(\+\=\.\<\>\{\}\[\]\:\;\'\"\|\~\`\_\-]/g

  const user = username.replace(REGEX_SPECIAL_CHAR, '\\$&').toLowerCase()

  const slicedUsername = user.match(/.{3}/g).concat(
    user.substr(1).match(/.{3}/g),
    user.substr(2).match(/.{3}/g)
  )

  return new RegExp(slicedUsername.join('|'), 'i').test(password.toLowerCase())
}
