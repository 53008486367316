import { makeStyles, Tooltip } from '@material-ui/core'
import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { noop } from 'lodash'
import Icon from '../../../../components/atoms/Icon'
import theme from '../../../../theme'
import { getReport, runReport } from '../../../../service'
import { useBoolean } from '../../../../hooks'
import { ICON_NAMES } from '../../../../constants'

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    width: '5.5rem',
    justifyContent: 'space-between'
  },
  button: {
    width: '1rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    '&:disabled': {
      cursor: 'default',
      pointerEvents: 'none',
      opacity: 0.15
    }
  }
}))

const ReportRunStatuses = Object.freeze({
  Created: 1,
  Resolving: 2,
  Rendering: 3,
  Completed: 4,
  Error: 5,
  Shared: 6
})

const ReportRunActions = ({
  row: {
    original: {
      title, templateId, status, reportRunId, runtimeConfigurationId, clientId, endDate
    }
  },
  isTableLoading,
  onPostRerunReport = noop,
  onSetPreviewUrl = noop,
  onSetPostToClientVault = noop
}) => {
  const classes = useStyles()
  const [isActionLoading, setIsActionLoading] = useBoolean(false)
  const [isError, setIsError] = useBoolean(false)

  const isLoading = useMemo(() => isActionLoading || isTableLoading, [isActionLoading, isTableLoading])

  const onPostToClientVault = useCallback(async (value) => {
    onSetPostToClientVault({ clientId, title, reportRunId })
  }, [clientId, title, reportRunId, onSetPostToClientVault])

  const onPreviewDownload = useCallback(async (value) => {
    setIsActionLoading.on()
    setIsError.off()

    const { data } = await getReport(reportRunId, { includeUrls: true })
    setIsActionLoading.off()

    if (!data?.htmlUrl) {
      setIsError.on()
      return
    }

    onSetPreviewUrl(data.htmlUrl)
  }, [setIsError, setIsActionLoading, reportRunId, onSetPreviewUrl])

  const onRerunReport = useCallback(async (value) => {
    setIsActionLoading.on()
    await runReport(templateId, {
      runtimeConfigurationId,
      reportRunId,
      inputParams: {
        clientId,
        startDate: endDate,
        endDate
      }
    })
    onPostRerunReport({ templateId, reportRunId })
    setIsActionLoading.off()
  }, [setIsActionLoading, templateId, runtimeConfigurationId, reportRunId, endDate, clientId, onPostRerunReport])

  const onClick = async () => {
    setIsActionLoading.on()
    setIsError.off()

    const { data } = await getReport(reportRunId, { includeUrls: true })
    setIsActionLoading.off()

    if (!data?.pdfUrl) {
      setIsError.on()
      return
    }
    return window.open(data.pdfUrl, '_blank')
  }

  const isShareable = status === ReportRunStatuses.Completed
  const isDownloadable = [ReportRunStatuses.Completed, ReportRunStatuses.Shared].includes(status)

  const isShareButtonEnabled = useMemo(() => {
    return !isLoading && isShareable && !isError
  }, [isLoading, isShareable, isError])

  const isDownloadButtonEnabled = useMemo(() => {
    return !isLoading && isDownloadable && !isError
  }, [isLoading, isDownloadable, isError])

  const isRerunButtonEnabled = useMemo(() => {
    return !isLoading && status === ReportRunStatuses.Error
  }, [status, isLoading])

  const downloadTooltipTitle = useMemo(() => {
    if (isLoading) return 'Loading'
    if (!isDownloadable) return 'No PDF available'
    return 'Download'
  }, [isLoading, isDownloadable])

  const previewTooltipTitle = useMemo(() => {
    if (isLoading) return 'Loading'
    if (!isShareable) return 'No preview available'
    return 'Preview'
  }, [isLoading, isShareable])

  const shareTooltipTitle = useMemo(() => {
    if (isLoading) return 'Loading'
    if (status === ReportRunStatuses.Shared) return 'Already shared'
    if (!isShareable) return 'Can not be shared'
    return 'Share to the Vault'
  }, [isLoading, isShareable, status])

  const rerunReportTooltipTitle = useMemo(() => {
    if (isRerunButtonEnabled) return 'Rerun report'
    if (status === ReportRunStatuses.Completed) return 'Can not rerun successful report'
    return 'Report run is still running'
  }, [status, isRerunButtonEnabled])

  return (
    <div
      className={classes.container}
    >
      <Tooltip
        key={'downloadTooltip-' + reportRunId}
        title={downloadTooltipTitle}
        arrow
        placement='top'
      >
        <span>
          <button
            key={'download-' + reportRunId}
            className={classes.button}
            type='button'
            onClick={
              !isDownloadButtonEnabled ? noop : (event) => onClick()
            }
            disabled={!isDownloadButtonEnabled}
          >
            <Icon
              color={theme.palette.summitBlue}
              name='fileDownload'
              customSize='1.5rem'
              variant='button'
            />
          </button>
        </span>
      </Tooltip>

      <Tooltip key={'previewTooltip-' + reportRunId} title={previewTooltipTitle} arrow placement='top'>
        <span>
          <button
            key={'preview-' + reportRunId}
            className={classes.button}
            type='button'
            onClick={
              !isShareButtonEnabled ? noop : onPreviewDownload
            }
            disabled={!isShareButtonEnabled}
          >
            <Icon
              color={theme.palette.summitBlue}
              name={ICON_NAMES.file}
              customSize='1.5rem'
              variant='button'
            />
          </button>
        </span>
      </Tooltip>

      <Tooltip key={'shareTooltip-' + reportRunId} title={shareTooltipTitle} arrow placement='top'>
        <span>
          <button
            key={'share-' + reportRunId}
            className={classes.button}
            type='button'
            onClick={
              !isShareButtonEnabled ? noop : onPostToClientVault
            }
            disabled={!isShareButtonEnabled}
          >
            <Icon
              color={theme.palette.summitBlue}
              name={ICON_NAMES.share}
              customSize='1.5rem'
              variant='button'
            />
          </button>
        </span>
      </Tooltip>

      <Tooltip key={'rerunReportTooltip-' + reportRunId} title={rerunReportTooltipTitle} arrow placement='top'>
        <span>
          <button
            key={'rerunReport-' + reportRunId}
            className={classes.button}
            type='button'
            onClick={
              !isRerunButtonEnabled ? noop : () => onRerunReport({ templateId, reportRunId })
            }
            disabled={!isRerunButtonEnabled}
          >
            <Icon
              color={theme.palette.summitBlue}
              name={ICON_NAMES.update}
              customSize='1.5rem'
              variant='button'
            />
          </button>
        </span>
      </Tooltip>
    </div>
  )
}

ReportRunActions.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      templateId: PropTypes.string,
      reportRunId: PropTypes.number,
      status: PropTypes.number,
      runtimeConfigurationId: PropTypes.number,
      clientId: PropTypes.number,
      title: PropTypes.string,
      endDate: PropTypes.string
    })
  }),
  isTableLoading: PropTypes.bool,
  onPostRerunReport: PropTypes.func,
  onSetPreviewUrl: PropTypes.func,
  onSetPostToClientVault: PropTypes.func
}

export default ReportRunActions
