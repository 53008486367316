import React from 'react'
import PropTypes from 'prop-types'
import SydSelect from '../../../../../../commonDesign/SydSelect'
import { RUN_STATUS } from '../../../runBilling/helpers'

const StatusFilter = ({
  onChange,
  value
}) => {
  const statusOptions = [
    { label: 'In Review', value: RUN_STATUS.IN_REVIEW },
    { label: 'Marked as Final', value: RUN_STATUS.MARK_AS_FINAL }
  ]

  return (
    <SydSelect
      size='sm'
      placeholder='Filter Status'
      options={statusOptions}
      value={value}
      multiple
      onChange={(e) => {
        onChange(e.target.value)
      }}
    />
  )
}

StatusFilter.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.array
}

export default StatusFilter
