import React, { forwardRef, useImperativeHandle, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { Grid, makeStyles } from '@material-ui/core'
import Select from '../../../../molecules/Select'

const useStyles = makeStyles((theme) => ({
  personalSpace: {
    minHeight: '100px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%'
  },
  formLayout: {
    maxWidth: '800px'
  },
  wrap: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  experience: {
    width: '100%'
  }
}))

const useFormValues = (person) => {
  const defaultValues = useMemo(() => {
    console.log(person)
    return {
      userId: person?.user?.userId || '',
      roleId: person?.experience?.roleId || ''
    }
  }, [person])
  return useForm({
    mode: 'onChange',
    reValidateMode: 'onSubmit',
    criteriaMode: 'all',
    defaultValues
  })
}

const AssignExperienceForm = forwardRef(function AssignExperienceForm ({ person, roleOptions }, ref) {
  const classes = useStyles()
  const { isSummitUser, handleSubmit, ...formValues } = useFormValues(person)
  useImperativeHandle(ref, () => {
    return {
      handleSubmit
    }
  }, [handleSubmit])

  return (
    <FormProvider handleSubmit={handleSubmit} {...formValues}>
      <div className={classes.wrap}>
        <Grid className={classes.formLayout} container spacing={3}>
          <Grid item xs={12}>
            Assign a new experience for {person.firstName} {person.lastName}
          </Grid>
          <Grid item xs={12}>
            <div className={classes.personalSpace}>
              <Controller
                name='roleId'
                render={({ field, fieldState }) => (
                  <Select
                    className={classes.experience}
                    placeholder='Select an Experience'
                    options={roleOptions}
                    onChange={field.onChange}
                    value={field.value}
                    showCheckMarOnSelectedItems
                    error={(fieldState.isDirty || fieldState.isTouched) && fieldState.error}
                  />
                )}
              />
            </div>
          </Grid>
        </Grid>
      </div>
    </FormProvider>
  )
})

AssignExperienceForm.propTypes = {
  person: PropTypes.shape({
    personId: PropTypes.number,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    user: PropTypes.shape({
      userId: PropTypes.number
    }),
    experience: PropTypes.shape({
      roleId: PropTypes.number
    })
  }),
  roleOptions: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.number
  }))
}

export default AssignExperienceForm
