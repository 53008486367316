import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import dayjs from 'dayjs'
import isArray from 'lodash/isArray'
import { isEmpty } from 'lodash'
import { useLocation } from 'react-router-dom'
import useSearchWealthJourneyEntriesDebounced, {
  ENTRY_TYPES
} from '../../../hooks/useSearchWealthJourneyEntriesDebounced'
import { VIEW_TYPES } from '../ClientCardsViewV2/helpers'
import { getDefaultDateRangeOptions, useRelativeDateRange } from '../../molecules/RelativeDateSelect'
import { useWealthJourneyQuery } from '../../../api/wealthJourney'
import { DATE_FORMAT } from '../../../constants'
import { parseSearchParams } from '../../../utils'
import { MilestonesContext } from './MilestonesContext'

const MilestonesContextProvider = ({
  children,
  pageSize,
  defaultSort,
  urlFiltersMap,
  emptyStateLabel,
  defaultDateRange,
  defaultDateRangeOptions,
  milestoneClickRedirectTo
}) => {
  const location = useLocation()
  const [viewType, setViewType] = useState(VIEW_TYPES.BLOCKS)
  const [selectedDateRange, setDateRange] = useState(defaultDateRange)
  const [selectedAsOfDate, setAsOfDate] = useState(dayjs().toISOString())

  const searchParams = useMemo(
    () => {
      return parseSearchParams(new URLSearchParams(location.search))
    },
    [location.search]
  )

  const urlFilters = useMemo(() => {
    if (!searchParams || isEmpty(searchParams)) return null

    return Object.entries(searchParams).reduce(
      (acc, [paramKey, paramValue]) => {
        if (!urlFiltersMap[paramKey] || !paramValue) return acc
        const { filterKey, op } = urlFiltersMap[paramKey]
        return { ...acc, [filterKey]: [{ op, value: paramValue }] }
      },
      {}
    )
  }, [searchParams, urlFiltersMap])

  const dateLimits = useMemo(
    () => ({
      mainDate: dayjs(selectedAsOfDate).toISOString()
    }),
    [selectedAsOfDate]
  )

  const dateRangeOptions = useMemo(() => {
    const options = getDefaultDateRangeOptions(defaultDateRangeOptions)
    return [
      {
        key: 'AUM',
        label: 'All Upcoming Milestones',
        value: 'AUM',
        getStartDate: () => dayjs.utc(selectedAsOfDate).format(DATE_FORMAT),
        getEndDate: () => dayjs.utc().year(9999).format(DATE_FORMAT)
      },
      ...options
    ]
  }, [defaultDateRangeOptions, selectedAsOfDate])

  const { dateRange } = useRelativeDateRange(
    selectedDateRange,
    dateLimits,
    dateRangeOptions
  )

  const [paginationData, setPaginationData] = useState({
    skip: 0,
    take: pageSize,
    sort: defaultSort,
    hasNextPage: false
  })

  const { query, queryOptions } = useMemo(() => {
    const { skip, take } = paginationData
    return {
      query: {
        filters: {
          entryDate: [
            { op: 'gte', value: dayjs(dateRange.startDate).toISOString() },
            { op: 'lte', value: dayjs(dateRange.endDate).toISOString() }
          ],
          ...(!isEmpty(urlFilters) ? urlFilters : {})
        },
        skip,
        take,
        sort: paginationData.sort
      },
      queryOptions: {
        mapper: ({ data }) =>
          viewType === VIEW_TYPES.BLOCKS ? { [`${skip}-${take}`]: data } : data
      }
    }
  }, [urlFilters, dateRange, viewType, paginationData])

  const {
    options: milestones,
    isLoading,
    isSearchLoading,
    onChangeQuery
  } = useSearchWealthJourneyEntriesDebounced(
    ENTRY_TYPES.MILESTONES,
    query,
    queryOptions
  )

  const [infiniteMilestones, setInfiniteMilestones] = useState([])

  useEffect(() => {
    if (!isLoading && !isArray(milestones)) {
      setInfiniteMilestones(
        Object.entries(milestones).reduce((acc, [key, page]) => {
          return [...acc, ...page]
        }, [])
      )
    }
  }, [milestones, isLoading])

  const { queryTotal, queryTotalOptions } = useMemo(() => {
    return {
      queryTotal: {
        filters: {
          entryTypeId: [{ op: 'eq', value: ENTRY_TYPES.MILESTONES }],
          entryDate: [
            { op: 'gte', value: dayjs(dateRange.startDate).toISOString() },
            { op: 'lte', value: dayjs(dateRange.endDate).toISOString() }
          ],
          ...(!isEmpty(urlFilters) ? urlFilters : {})
        },
        resultType: 'total'
      },
      queryTotalOptions: { mapper: ({ total }) => total }
    }
  }, [urlFilters, dateRange.endDate, dateRange.startDate])

  const { data: total, isLoading: isLoadingTotal } = useWealthJourneyQuery(
    `$total_${ENTRY_TYPES.MILESTONES}`,
    queryTotal,
    queryTotalOptions
  )

  const fetchNextMilestonesPage = useCallback(() => {
    if (isLoading) return

    setPaginationData((prevState) => {
      const { skip, take } = prevState
      const nextOffset = skip + take
      if (skip === nextOffset || nextOffset < skip) {
        return prevState
      }
      if (skip + take > total) {
        return prevState
      }
      return {
        ...prevState,
        skip: nextOffset
      }
    })
  }, [isLoading, total])

  const value = useMemo(
    () => ({
      milestones,
      milestonesTotal: total,
      viewType,
      isLoading: isLoading || isLoadingTotal,
      setViewType,
      setAsOfDate,
      setDateRange,
      onChangeQuery,
      paginationData,
      isSearchLoading,
      emptyStateLabel,
      selectedAsOfDate,
      dateRangeOptions,
      selectedDateRange,
      setPaginationData,
      infiniteMilestones,
      fetchNextMilestonesPage,
      milestoneClickRedirectTo
    }),
    [
      total,
      milestones,
      viewType,
      isLoading,
      setAsOfDate,
      onChangeQuery,
      isLoadingTotal,
      paginationData,
      emptyStateLabel,
      isSearchLoading,
      dateRangeOptions,
      selectedAsOfDate,
      selectedDateRange,
      setPaginationData,
      infiniteMilestones,
      fetchNextMilestonesPage,
      milestoneClickRedirectTo
    ]
  )

  return (
    <MilestonesContext.Provider value={value}>
      {children}
    </MilestonesContext.Provider>
  )
}

MilestonesContextProvider.propTypes = {
  pageSize: PropTypes.number,
  defaultSort: PropTypes.array,
  emptyStateLabel: PropTypes.string,
  defaultDateRange: PropTypes.string,
  defaultDateRangeOptions: PropTypes.array,
  children: PropTypes.node.isRequired,
  milestoneClickRedirectTo: PropTypes.string,
  urlFiltersMap: PropTypes.object
}

export default MilestonesContextProvider
