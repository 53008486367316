import React, { useCallback, useEffect, useMemo } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { LoginCallback, useOktaAuth } from '@okta/okta-react'
import querystring from 'query-string'
import omit from 'lodash/omit'
import isEmpty from 'lodash/isEmpty'
import dayjs from 'dayjs'
import LoadingView from './components/pages/LoadingView'
import ErrorView from './components/pages/ErrorView'
import AppInitializer from './components/templates/AppInitializer'
import { useFetchAppConfig } from './redux/slices/appConfig'
import { useSetViewContext } from './redux/slices/viewContext'
import { fetchUser, setToken } from './service'
import { useAuthState, useFetchState, usePermissions } from './hooks'
import { staticViews } from './staticViews'
import { useSetAppContext, useSetUserInformation } from './redux/slices/appContext'
import AnalyticsProvider from './components/atoms/AnalyticsProvider'
import { ExportProvider } from './components/molecules/ExportButton/ExportProvider'
import RestrictedView from './components/pages/RestrictedView'
import { useCheckPolicies } from './api/policy'
import config from './config'
import SummitUtilityProvider from './providers/SummitUtilityProvider'
import BrandProvider from './providers/BrandProvider'
import LoginContainer from './components/pages/publicViews/LoginContainer'
import OutboundSsoProvider from './components/organisms/OutboundSso/OutboundSsoProvider'

const OMITTED_QUERYSTRING_PARAMETERS = ['subViewId']

/** TODO: put user type on user table */
const findUserType = (groups) => {
  const isSummitUser = groups.reduce(
    (acc, group) => group === 'summit' || acc,
    false
  )

  if (isSummitUser) return 'Summit'

  const isAdvisorUser = groups.reduce(
    (acc, group) => group.includes('advisors') || acc,
    false
  )

  if (isAdvisorUser) return 'Advisor'

  return 'Wealth Owner'
}

function App () {
  const { authState: oktaAuthState, oktaAuth } = useOktaAuth()
  const authState = useAuthState(oktaAuthState)
  const setUserInformation = useSetUserInformation()
  const fetchAppConfig = useFetchAppConfig()
  const setViewContext = useSetViewContext()
  const setAppContext = useSetAppContext()
  const history = useHistory()
  const { isAdvisor, clientId, firmId, isSummitUser, oktaId: userId } = usePermissions(authState.accessToken || {})
  const location = useLocation()

  const publicView = useMemo(
    () => {
      const publicViews = staticViews.filter((view) => view.public)
      if (isEmpty(publicViews)) return null

      return publicViews.find((view) => view.path === location.pathname)
    }, [location.pathname]
  )

  useEffect(() => {
    const queryStringParameters = querystring.parse(location.search)
    const viewContextParameters = omit(queryStringParameters, OMITTED_QUERYSTRING_PARAMETERS)
    setViewContext(viewContextParameters)
    // Only preload viewContext with query string parameters on startup
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { loading: loadingChecking, loginError, userProfile } = useFetchState(
    useCallback(async (setSafeState) => {
      if (isEmpty(authState) || !authState.isAuthenticated) return
      const { accessToken } = authState.accessToken
      setToken(accessToken)
      setSafeState({ loading: false })
      try {
        const user = {
          ...authState.accessToken.claims
        }
        // temporary until claims have been propagated to okta
        if (typeof user.internalId === 'undefined') {
          console.debug('fallback - getting user data from api')
          const { data: apiUser } = await fetchUser(authState.accessToken.claims.uid)
          console.debug('found user info: ', apiUser)
          if (!apiUser) {
            console.error('User not available: logging out')
            setSafeState({ loginError: true })
            oktaAuth.revokeAccessToken()
            oktaAuth.closeSession()
          }
          user.internalId = apiUser.internalId
          user.allAccountsAccess = apiUser.allAccountsAccess
          console.debug('--- fallback done ---')
        }
        // check for terms
        const termAcceptanceIsOutdated = dayjs(user.termsAcceptance).isBefore(config.termsLastUpdateDate)
        const isValidTermsAcceptance = user.termsAcceptance && !termAcceptanceIsOutdated
        if (!isValidTermsAcceptance && !['/accept-terms'].includes(history.location.pathname)) {
          history.replace('/accept-terms')
        }

        setSafeState({
          loginError: false,
          userProfile: {
            userType: findUserType(authState.accessToken.claims.groups),
            userId: user.uid,
            internalId: user.internalId,
            firmId: +user.firmId,
            allAccountsAccess: user.allAccountsAccess,
            email: user.sub,
            termsAcceptance: user.termsAcceptance,
            isValidTermsAcceptance: isValidTermsAcceptance
          }
        })
      } catch (error) {
        console.error('Caught error while starting the application', error)
        setSafeState({ loginError: true })
        oktaAuth.revokeAccessToken()
        oktaAuth.closeSession()
      }
    }, [authState, history, oktaAuth])
  )

  const { loading, error, policies } = useFetchState(
    useCallback(async (safeSetState) => {
      if (isEmpty(authState) || !authState.isAuthenticated || loadingChecking || !userProfile) return
      setAppContext({
        isAdvisor,
        isSummitUser,
        userId,
        firmId,
        userProfile
      })
      setUserInformation({
        userId,
        firmId,
        isAdvisor,
        firmClientId: clientId
      })
      try {
        await fetchAppConfig()
        safeSetState()
      } catch (error) {
        console.error(error)
        safeSetState({ error })
      }
    }, [
      authState,
      firmId,
      userId,
      clientId,
      isAdvisor,
      userProfile,
      isSummitUser,
      setAppContext,
      fetchAppConfig,
      loadingChecking,
      setUserInformation
    ])
  )

  const { isLoading: policiesLoading } = useCheckPolicies()

  const isNonInteractive = useMemo(() => {
    if (authState.isAuthenticated) {
      return authState?.accessToken?.claims?.groups?.includes('non_interactive')
    }
    return false
  }, [authState])

  const loginView = useMemo(() => {
    return (
      <BrandProvider>
        <LoginContainer loginError={loginError} />
      </BrandProvider>
    )
  }, [loginError])

  if (publicView) {
    const PublicView = publicView.Component
    return (
      <BrandProvider>
        <PublicView />
      </BrandProvider>
    )
  }

  if (isEmpty(authState)) {
    console.debug('authState is empty - login callback')
    return <LoginCallback />
  }

  if (!authState.isAuthenticated) {
    console.debug('authState is not authenticated - returning loginView', authState)
    return loginView
  }

  if (loadingChecking || loading || policiesLoading) {
    console.debug(`loading: loadingChecking = ${loadingChecking}, loading = ${loading}, policiesLoading = ${policiesLoading}`)
    return <LoadingView />
  }

  if (authState.error) {
    console.debug('authState is error', authState.error)
    return loginView
  }

  // Non-interactive user will result in error being set because api calls will fail
  if (isNonInteractive) {
    console.debug('Non-interactive user', authState)
    return <RestrictedView />
  }

  if (error) {
    console.debug('general error', error)
    return error.code === 401 || error.code === 403 ? LoadingView : <ErrorView error={error} />
  }

  console.debug('returning main view', authState)
  return (
    <BrandProvider>
      <SummitUtilityProvider currentUser={userProfile}>
        <OutboundSsoProvider>
          <AnalyticsProvider currentUser={userProfile}>
            <ExportProvider>
              <AppInitializer policies={policies} />
            </ExportProvider>
          </AnalyticsProvider>
        </OutboundSsoProvider>
      </SummitUtilityProvider>
    </BrandProvider>
  )
}

export default App
