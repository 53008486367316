import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import truncate from 'lodash/truncate'
import { makeStyles } from '@material-ui/core/styles'
import OperationalTable, { useOperationalTable } from '../../../organisms/OperationalTable'
import { useFormattingContext } from '../../../organisms/FormattingProvider/FormattingContext'

const useStyles = makeStyles(theme => ({
  tableSuperWrapper: {
    position: 'relative',
    '& .__null': {
      color: theme.palette.gray.darker
    }
  }
}))

const NullValue = () => <span className='__null'>&lt;null&gt;</span>
// eslint-disable-next-line react/prop-types
const StringTemplate = ({ value }) => value ? <span title={value}>{truncate(value, 30)}</span> : <NullValue />
const NumberTemplate = (formatter) => ({ value }) => formatter(value, 'number')
const DateTemplate = (formatter) => ({ value }) => formatter(value, 'date')
const BoolTemplate = ({ value }) => value === true ? 'Y' : 'N'
const NullTemplate = () => <NullValue />
// eslint-disable-next-line react/prop-types
const DefaultTemplate = ({ value }) => value?.toString() ?? <NullValue />

const createCellTemplate = (schemaItem, skipFormatting, formatter) => {
  if (skipFormatting) {
    // eslint-disable-next-line react/prop-types
    return ({ value }) => {
      return DefaultTemplate
    }
  }

  switch (schemaItem.type) {
    case 'string':
      return StringTemplate
    case 'number':
      return NumberTemplate(formatter)
    case 'date':
      return DateTemplate(formatter)
    case 'bool':
      return BoolTemplate
    case 'null':
      return NullTemplate
    default:
      return DefaultTemplate
  }
}

const calculateColumnsFromSchema = (schemaArray, skipFormatting, formatter) => {
  const copy = [...schemaArray]
  copy.sort((a, b) => (a.ordinal || 0) - (b.ordinal || 0))
  const columns = copy.map(schemaItem => ({
    Header: schemaItem.key,
    accessor: (row) => row[schemaItem.key],
    id: schemaItem.key,
    Cell: createCellTemplate(schemaItem, skipFormatting, formatter)
  }))
  const defaultSort = columns.length ? [{ id: columns.at(0).id, desc: false }] : []

  return {
    columns,
    defaultSort
  }
}

const useMetadataColumns = (loading, dataSource, skipFormatting) => {
  const { formatter } = useFormattingContext()
  return useMemo(() => {
    if (loading) return { columns: [], defaultSort: [] }
    const objSchema = dataSource.metadata?.schema
    if (!objSchema) return { columns: [], defaultSort: [] }
    const schemaArray = Object.entries(objSchema).map(([key, value]) => ({
      key,
      ...value
    }))

    return calculateColumnsFromSchema(schemaArray, skipFormatting, formatter)
  }, [loading, dataSource, formatter, skipFormatting])
}

const DataSourceTable = ({ dataLoading, dataSourceLoading, data, dataSource, skipFormatting }) => {
  const classes = useStyles()
  const { columns, defaultSort } = useMetadataColumns(dataSourceLoading, dataSource, skipFormatting)
  const {
    // defaultPageSize,
    // pageIndex,
    // pageSize,
    // sort,
    // onPagingChange,
    onSortingChange
  } = useOperationalTable({ defaultSort })

  if (!dataLoading && columns.length === 0) {
    return (<div>Unable to display</div>)
  }

  return (
    <div className={classes.tableSuperWrapper}>
      <OperationalTable.Wrapper>
        <OperationalTable
          loading={dataSourceLoading || dataLoading}
          mode='client'
          columns={columns}
          defaultSort={defaultSort}
          data={data}
          itemName='Records'
          onSortingChange={onSortingChange}
          hideFooter
          variant='v2'
        />
      </OperationalTable.Wrapper>
    </div>
  )
}

DataSourceTable.propTypes = {
  dataSourceLoading: PropTypes.bool,
  dataLoading: PropTypes.bool,
  data: PropTypes.array,
  dataSource: PropTypes.object,
  skipFormatting: PropTypes.bool
}

DataSourceTable.defaultProps = {
  skipFormatting: false
}

export default DataSourceTable
