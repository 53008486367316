import { useMemo, useState } from 'react'
import { isEmpty } from 'lodash'
import { MILLISECONDS_BEFORE_SEARCH, DEFAULT_SELECT_SEARCH_PAGINATION } from '../constants'
import { useGroupSearch } from '../api/groups'
import useDebouncedValue from './useDebounceValue'
import { getSearchQuery } from './helpers'

const SEARCH_FIELDS = ['longName']

const getQueryFilters = ({ query }) => getSearchQuery(SEARCH_FIELDS, query)

const defaultGroupsMapper = ({ data }) => {
  return data.map((datum) => ({
    key: datum.groupId,
    value: datum.groupId,
    label: datum.longName,
    payload: datum
  }))
}

const useSearchGroupsDebounced = ({
  query = {},
  queryOptions = {},
  defaultGroupIds = [],
  debounceDelay = MILLISECONDS_BEFORE_SEARCH
}) => {
  const [queryText, setQuery] = useState('')
  const debouncedQuery = useDebouncedValue(queryText, debounceDelay)

  const { queryParams, queryParamOptions } = useMemo(() => {
    const queryParams = getQueryFilters({
      query: debouncedQuery
    })
    return {
      queryParams: {
        ...query,
        filters: {
          ...queryParams,
          ...(query?.filters || {})
        },
        textSearch: queryParams,
        ...DEFAULT_SELECT_SEARCH_PAGINATION
      },
      queryParamOptions: {
        mapper: defaultGroupsMapper,
        ...queryOptions
      }
    }
  }, [debouncedQuery, query, queryOptions])

  const { data, isLoading } = useGroupSearch(
    queryParams,
    queryParamOptions
  )

  const defaultOptionsQuery = useMemo(() => {
    return {
      queryParams: {
        filters: {
          groupId: [{ op: 'in', value: defaultGroupIds }]
        },
        ...(query?.includes ? { includes: query?.includes } : {})
      },
      queryParamOptions: {
        mapper: defaultGroupsMapper,
        enabled: !isEmpty(defaultGroupIds),
        ...queryOptions
      }
    }
  }, [defaultGroupIds, query, queryOptions])

  const { data: defaultOptions, isLoading: isLoadingDefaults } = useGroupSearch(
    defaultOptionsQuery.queryParams,
    defaultOptionsQuery.queryParamOptions
  )

  const isTransitioningOptions = isLoading || debouncedQuery !== queryText

  return {
    options: data,
    isLoading: isLoading || (isLoadingDefaults && !isEmpty(defaultGroupIds)),
    isLoadingGroups: isLoading,
    isLoadingDefaultGroups: isLoadingDefaults && !isEmpty(defaultGroupIds),
    onChangeQuery: setQuery,
    isSearchLoading: isTransitioningOptions,
    defaultOptions
  }
}

export default useSearchGroupsDebounced
