import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import EmptySection from '../../../atoms/EmptySection'
import Icon from '../../../atoms/Icon'
import { ICON_NAMES } from '../../../../constants'

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    borderRadius: '2rem',
    padding: '4rem 1rem',
    textAlign: 'center',
    margin: '0 auto',
    justifyContent: 'center'
  },
  content: {
    gap: '2rem',
    display: 'flex',
    flexDirection: 'row'
  },
  emptyStateContent: {
    alignItems: 'start'
  }
}))

const ReportResultsEmptyState = ({ icon, title, iconSize, description }) => {
  const classes = useStyles()
  return (
    <div className={classes.container}>
      <div className={classes.content}>
        {icon && icon in ICON_NAMES && (
          <Icon name={icon} customSize={iconSize} />
        )}
        <EmptySection
          title={title}
          description={description}
          containerClassName={classes.emptyStateContent}
        />
      </div>
    </div>
  )
}

ReportResultsEmptyState.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string,
  iconSize: PropTypes.string,
  description: PropTypes.string
}

ReportResultsEmptyState.defaultProps = {
  icon: ICON_NAMES.paperGlassMagnifier,
  iconSize: '8rem',
  title: 'No Data Available',
  description:
    'There is no data matching this criteria, try resetting your filters.'
}

export default ReportResultsEmptyState
