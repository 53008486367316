import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { Box, Divider, alpha, IconButton, useTheme, ClickAwayListener, CircularProgress } from '@material-ui/core'
import isEmpty from 'lodash/isEmpty'
import noop from 'lodash/noop'
import clsx from 'clsx'
import { ICON_NAMES, TEXT_VARIANTS, KEYS } from '../../constants'
import RoundedButton from '../atoms/RoundedButton'
import Icon from '../atoms/Icon'
import Text from '../atoms/Text'
import useToggle from '../../hooks/useToggle'
import useDebouncedCallback from '../../hooks/useDebouncedCallback'
import FastAvatar from './FastAvatar'

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative'
  },
  content: ({ open, width, embeddedInput }) => ({
    display: 'flex',
    flexDirection: 'row',
    position: 'absolute',
    top: 0,
    left: 0,
    background: theme.palette.white,
    borderRadius: '1.5rem',
    ...(open ? {
      borderBottomLeftRadius: '0',
      borderBottomRightRadius: '0'
    } : {
      borderBottomLeftRadius: '1.5rem',
      borderBottomRightRadius: '1.5rem'
    }),
    ...(embeddedInput ? {
      width: '340px',
      maxWidth: '340px',
      borderBottom: '1px solid #949494',
      borderBottomLeftRadius: '0 !important',
      borderBottomRightRadius: '0 !important'
    } : {
      boxShadow: '0px 8px 24px rgba(0, 0, 0, 0.12)',
      width: '20rem',
      maxWidth: '20rem'
    }),
    ...(width ? { maxWidth: width, width } : {})
  }),
  recentsTag: {
    margin: '1.5rem auto 1rem 1rem'
  },
  label: {
    display: 'block'
  },
  input: {
    width: '100%',
    border: 'none',
    fontSize: '1rem',
    borderTopRightRadius: '1.5rem',
    borderBottomRightRadius: '1.5rem',
    fontFamily: theme.typography.fontFamily,
    '&:focus': {
      outline: 'none'
    },
    '&:-webkit-input-placeholder': {
      opacity: '0.2',
      fontWeight: 'bold'
    }
  },
  listContainer: ({ showPrimaryButton }) => ({
    padding: `1rem 0 ${showPrimaryButton ? '1.5rem' : '1rem'} 0`
  }),
  listbox: ({ width, showPrimaryButton }) => ({
    display: 'flex',
    flexDirection: 'column',
    width: width || '20rem',
    zIndex: 1,
    padding: '0 !important',
    margin: `0.5rem 0 ${showPrimaryButton ? '1.5rem' : '0.5rem'} 0 !important`,
    listStyle: 'none',
    background: theme.palette.white,
    overflow: 'auto',
    maxHeight: 250,
    '& li[data-focus="true"]': {
      backgroundColor: alpha(theme.palette.gray.dark, 0.15),
      color: 'white',
      cursor: 'pointer'
    },
    '& li:active': {
      backgroundColor: alpha(theme.palette.gray.dark, 0.45),
      color: 'white'
    }
  }),
  listOption: {
    display: 'flex',
    flexDirection: 'row',
    padding: '0.5rem 0',
    justifyContent: 'left',
    alignItems: 'center',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: alpha(theme.palette.gray.dark, 0.15),
      color: 'white',
      cursor: 'pointer'
    }
  },
  listOptionHover: {
    backgroundColor: alpha(theme.palette.gray.dark, 0.15),
    color: 'white',
    cursor: 'pointer'
  },
  listOptionTitle: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden'
  },
  primaryButton: {
    padding: '0.75rem 22px !important'
  },
  optionsContainer: ({ width, embeddedInput }) => ({
    zIndex: '1302',
    top: '2.7rem',
    position: 'absolute',
    background: theme.palette.white,
    boxShadow: '0px 20px 24px rgba(0, 0, 0, 0.12)',
    borderBottomRightRadius: '1.5rem',
    borderBottomLeftRadius: '1.5rem',
    ...(embeddedInput ? {
      width: '340px'
    } : {}),
    ...(width ? { width } : {})
  }),
  emptyStateContainer: {
    top: '0.1rem !important'
  },
  loading: {
    display: 'flex',
    height: '4rem',
    alignItems: 'center',
    justifyContent: 'center'
  }
}))

const AutoCompleteSearchInput = ({
  autoFocus,
  width,
  options,
  loading,
  className,
  contentClassName,
  inputClassName,
  optionsContainerClassName,
  startAsOpen,
  placeholder,
  recentOptions,
  optionTitleKey,
  optionValueKey,
  recentOptionTitleKey,
  recentOptionValueKey,
  onSearchClick,
  primaryButtonLabel,
  onChange: onCustomChange,
  onSelectOption: onCustomSelectOption,
  onPrimaryButtonClick: onCustomPrimaryButtonClick,
  embeddedInput,
  showPrimaryButton,
  noOptionsFoundLabel,
  clearOnSelectOption
}) => {
  const theme = useTheme()
  const inputRef = useRef(null)
  const [searchText, setSearchText] = useState('')
  const [optionIndex, setOptionIndex] = useState(0)
  const [touched, setTouched] = useToggle()
  const [open, , toggleOpenOn, toggleOpenOff] = useToggle(startAsOpen)

  const classes = useStyles({ open, width, embeddedInput, showPrimaryButton })
  const onChangeDebounced = useDebouncedCallback(onCustomChange)

  const onSelectOption = useCallback(
    (option) => {
      if (clearOnSelectOption) {
        setSearchText('')
      } else {
        setSearchText(option[optionTitleKey])
      }
      toggleOpenOff()
      onCustomSelectOption(option)
      if (inputRef.current) {
        inputRef.current.blur()
      }
    },
    [optionTitleKey, toggleOpenOff, onCustomSelectOption, clearOnSelectOption]
  )

  const onKeyHandler = useCallback(
    (event) => {
      const { keyCode: key } = event
      if (key === KEYS.ENTER) {
        const option = options[optionIndex] || recentOptions[optionIndex]
        if (option) {
          onSelectOption(option)
        }
      } else if (key === KEYS.ARROW_UP) {
        setOptionIndex(prevOptionIndex => prevOptionIndex <= 0 ? prevOptionIndex : prevOptionIndex - 1)
      } else if (key === KEYS.ARROW_DOWN) {
        setOptionIndex(prevOptionIndex => {
          const defaultOptions = options.length ? options : recentOptions
          return prevOptionIndex >= defaultOptions.length ? prevOptionIndex : prevOptionIndex + 1
        })
      }
    },
    [optionIndex, options, recentOptions, onSelectOption]
  )

  const onEsc = useCallback(
    (event) => {
      if (event.keyCode === KEYS.ESC) {
        inputRef.current && inputRef.current.blur()
        toggleOpenOff()
      }
    },
    [toggleOpenOff]
  )

  useEffect(() => {
    document.addEventListener('keydown', onEsc, false)
    return () => {
      document.removeEventListener('keydown', onEsc, false)
    }
  }, [onEsc])

  const onSearchIconClick = useCallback(
    () => {
      toggleOpenOff()
      onSearchClick()
    },
    [toggleOpenOff, onSearchClick]
  )

  const onChange = useCallback(
    (e) => {
      if (!touched) {
        setTouched(true)
      }
      setSearchText(e.value)
      onChangeDebounced(e.target.value)
    },
    [setTouched, touched, onChangeDebounced]
  )

  const onPrimaryButtonClick = useCallback(
    () => {
      toggleOpenOff()
      onCustomPrimaryButtonClick()
    },
    [toggleOpenOff, onCustomPrimaryButtonClick]
  )

  const primaryButton = useMemo(() =>
    <Box mx='1rem'>
      <RoundedButton primary fullWidth className={classes.primaryButton} onClick={onPrimaryButtonClick}>
        {primaryButtonLabel}
      </RoundedButton>
    </Box>,
  [classes.primaryButton, primaryButtonLabel, onPrimaryButtonClick])

  const emptyState = useMemo(() =>
    <div className={clsx(classes.optionsContainer, classes.emptyStateContainer, {
      [optionsContainerClassName]: Boolean(optionsContainerClassName)
    })}
    >
      <div className={classes.listContainer}>
        <ul className={classes.listbox}>
          <Box textAlign='center' mx='1rem'>
            <Text color={theme.palette.dustyGray} customFontWeight='bold' text={noOptionsFoundLabel} />
          </Box>
        </ul>
        {!embeddedInput && showPrimaryButton && primaryButton}
      </div>
    </div>,
  [
    primaryButton,
    theme.palette.dustyGray,
    classes.listbox,
    classes.listContainer,
    classes.optionsContainer,
    classes.emptyStateContainer,
    embeddedInput,
    showPrimaryButton,
    noOptionsFoundLabel,
    optionsContainerClassName
  ])

  const renderOptionsList = useCallback((options, showRecents = false) => {
    const optionLabelKey = showRecents ? recentOptionTitleKey : optionTitleKey
    const optionValKey = showRecents ? recentOptionValueKey : optionValueKey
    return (
      <>
        <ul className={classes.listbox}>
          {loading
            ? <div className={classes.loading}><CircularProgress /></div>
            : (options || []).map((option, index) => {
              const label = option[optionLabelKey]
              return (
                <li
                  key={option[optionValKey]}
                  className={clsx(classes.listOption, { [classes.listOptionHover]: optionIndex === index })}
                  onClick={() => onSelectOption(option, index)}
                  onMouseOver={() => setOptionIndex(index)}
                >
                  <Box mx='1rem'>
                    <FastAvatar
                      size='xs'
                      avatarUrl={option.avatarUrl}
                      abbreviation={option.clientAbbreviation}
                    />
                  </Box>
                  <Text color={theme.palette.black} customFontSize='0.875rem' text={label} />
                </li>
              )
            })}
        </ul>
        {!embeddedInput && showPrimaryButton && primaryButton}
      </>
    )
  }, [
    loading,
    optionIndex,
    primaryButton,
    optionTitleKey,
    optionValueKey,
    onSelectOption,
    recentOptionTitleKey,
    recentOptionValueKey,
    theme.palette.black,
    classes.loading,
    classes.listbox,
    classes.listOption,
    classes.listOptionHover,
    embeddedInput,
    showPrimaryButton
  ])

  return (
    <ClickAwayListener onClickAway={toggleOpenOff} mouseEvent='onMouseDown'>
      <div className={clsx(classes.container, { [className]: Boolean(className) })}>
        <div className={clsx(classes.content, { [contentClassName]: Boolean(contentClassName) })}>
          {!embeddedInput && (
            <IconButton onClick={onSearchIconClick} className={classes.label}>
              <Icon name={ICON_NAMES.search} customSize='1.25rem' color='black' />
            </IconButton>
          )}
          <input
            autoFocus={autoFocus}
            ref={inputRef}
            className={clsx(classes.input, { [inputClassName]: !!inputClassName })}
            placeholder={placeholder}
            value={searchText}
            onChange={onChange}
            onFocus={toggleOpenOn}
            onKeyDown={onKeyHandler}
          />
          {embeddedInput && (
            <IconButton onClick={onSearchIconClick} className={classes.label}>
              <Icon name={ICON_NAMES.search} customSize='1.25rem' color='black' />
            </IconButton>
          )}
        </div>
        {open && (
          <div className={classes.optionsContainer}>
            <Divider light />
            <div className={classes.listContainer}>
              {isEmpty(options) && !isEmpty(recentOptions) && (
                <>
                  {!loading && (
                    <Box mx='1rem' pb='0.5rem'>
                      <Text
                        variant={TEXT_VARIANTS.button}
                        color={theme.palette.dustyGray}
                        customFontWeight='bold'
                        text='RECENTS'
                      />
                    </Box>
                  )}
                  {renderOptionsList(recentOptions, true)}
                </>
              )}
              {!isEmpty(options) && renderOptionsList(options)}
              {isEmpty(options) && isEmpty(recentOptions) && emptyState}
            </div>
          </div>
        )}
      </div>
    </ClickAwayListener>
  )
}

AutoCompleteSearchInput.propTypes = {
  autoFocus: PropTypes.bool,
  options: PropTypes.array,
  recentOptions: PropTypes.array,
  placeholder: PropTypes.string,
  loading: PropTypes.bool,
  onChange: PropTypes.func,
  onSearchClick: PropTypes.func,
  onSelectOption: PropTypes.func,
  onPrimaryButtonClick: PropTypes.func,
  optionTitleKey: PropTypes.string.isRequired,
  optionValueKey: PropTypes.string.isRequired,
  recentOptionTitleKey: PropTypes.string.isRequired,
  recentOptionValueKey: PropTypes.string.isRequired,
  primaryButtonLabel: PropTypes.string,
  startAsOpen: PropTypes.bool,
  embeddedInput: PropTypes.bool,
  showPrimaryButton: PropTypes.bool,
  className: PropTypes.string,
  contentClassName: PropTypes.string,
  inputClassName: PropTypes.string,
  noOptionsFoundLabel: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  optionsContainerClassName: PropTypes.string,
  clearOnSelectOption: PropTypes.bool
}

AutoCompleteSearchInput.defaultProps = {
  autoFocus: true,
  options: [],
  recentOptions: [],
  placeholder: '',
  loading: false,
  onChange: noop,
  onSearchClick: noop,
  onSelectOption: noop,
  onPrimaryButtonClick: noop,
  startAsOpen: false,
  embeddedInput: false,
  showPrimaryButton: false,
  className: undefined,
  contentClassName: undefined,
  inputClassName: undefined,
  noOptionsFoundLabel: 'No options found',
  width: undefined,
  optionsContainerClassName: '',
  clearOnSelectOption: false
}

export default AutoCompleteSearchInput
