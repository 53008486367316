import React, { useCallback, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import { Typography } from '@material-ui/core'
import SydButton from '../../../../../commonDesign/Button'
import SydModalActions from '../../../../../commonDesign/SydModal/SydModalActions'
import { useRemoveEntry } from '../../../../../../api/wealthJourney'

const useSubmitter = (form, onComplete) => {
  const { handleSubmit } = form
  const [processing, setProcessing] = useState(false)
  const [error, setError] = useState(null)
  const { mutateAsync: removeEntry } = useRemoveEntry()
  const onSubmit = useCallback(async (formData) => {
    const command = {
      ...formData
    }

    try {
      setError(null)
      setProcessing(true)
      const result = await removeEntry(command)
      if (result?.statusCode === 500) {
        throw new Error('Failed to remove entry')
      }
      onComplete(result)
    } catch (err) {
      setError(err?.toString())
    } finally {
      setProcessing(false)
    }
  }, [removeEntry, setProcessing, onComplete, setError])

  const submitter = useCallback(async (e) => {
    const onValid = async (form) => {
      await onSubmit(form)
    }
    const onInvalid = (errors) => {
      console.error(errors)
    }

    const handler = handleSubmit(onValid, onInvalid)
    await handler(e)
  }, [handleSubmit, onSubmit])

  return {
    submitter,
    processing,
    error
  }
}

function RemoveEntryForm ({ onCancel, onComplete, entry }) {
  const form = useForm({
    mode: 'onChange',
    defaultValues: {
      ...(entry || {})
    }
  })

  const { submitter, processing, error } = useSubmitter(form, onComplete)

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography variant='h3'><strong>{entry.entryText}</strong></Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant='h3' style={{ fontWeight: '100' }}>Are you sure you want to archive this {entry.entryType?.entryTypeName ?? 'Entry'}?</Typography>
      </Grid>
      <Grid item xs={12}>
        <SydModalActions>
          {error ? (<div className='__error'>{error}</div>) : null}
          <SydButton disabled={processing} variant='ghost' size='md' onClick={onCancel}>Cancel</SydButton>
          <SydButton
            variant='primary'
            size='md'
            onClick={submitter}
            processing={processing}
          >
            Archive
          </SydButton>
        </SydModalActions>
      </Grid>
    </Grid>
  )
}

RemoveEntryForm.propTypes = {
  entry: PropTypes.object,
  onComplete: PropTypes.func,
  onCancel: PropTypes.func
}

export default RemoveEntryForm
