import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import DragAndDropInputArea, { useDragAndDrop } from '../../molecules/DragAndDropInputArea'
import { DOCUMENT_VAULT_ACCEPTED_FILES } from '../../../constants'
import RoundedButton from '../../atoms/RoundedButton'
import DocumentVaultFileRejectionMessageList from '../../molecules/DocumentVaultFileRejectionMessageList'
import { useFinalizeDocumentUploads } from '../../../api/documentVault'
import { useFileEvents, useValidator } from './hooks'
import DocumentChip from './DocumentChip'

const useStyles = makeStyles((theme) => ({
  inlineDropzone: {},
  actions: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    padding: '10px 0',
    gap: '10px',
    transition: 'opacity .3s ease-in-out'
  },
  emptyState: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '10px',
    width: '100%',
    '& .__icon': {
      minWidth: '100px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    '& .__description': {
      flex: '1 0',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: '10px'
    },
    '& .__heading': {
      fontSize: '16px'
    }
  },
  documentList: {
    width: '100%'
  }
}))

/* eslint-disable react/prop-types */

function EmptyState ({ className, openFileSelect }) {
  return (
    <div className={className}>
      <div className='__description'>
        <div className='__heading'>Drag & Drop to Upload Files</div>
        <div>or</div>
        <div>
          <RoundedButton primary onClick={openFileSelect}>Browse Files</RoundedButton>
        </div>
      </div>
    </div>
  )
}

function DocumentList ({ className, files, onDeleteFile, openFileSelect, editFile }) {
  return (
    <div className={className}>
      {files.map(x => (
        <DocumentChip
          key={x.name}
          document={x}
          onDelete={() => onDeleteFile(x.documentSpecification)}
          editFile={editFile}
        />
      ))}
      <div>
        <RoundedButton size='small' tertiary onClick={openFileSelect}>Browse Files</RoundedButton>
      </div>
    </div>
  )
}

function DropzoneBody ({ classes, files, uploading, onDeleteFile, editFile }) {
  const { openFileSelect, fileRejections } = useDragAndDrop()

  if (uploading) {
    return (
      <div>Verifying...</div>
    )
  }

  if (fileRejections?.length) {
    return (
      <div>
        <DocumentVaultFileRejectionMessageList
          fileRejections={fileRejections}
          withMarginBottom
        />
      </div>
    )
  }

  if (files?.length) {
    return (
      <DocumentList
        className={classes.documentList}
        files={files}
        onDeleteFile={onDeleteFile}
        openFileSelect={openFileSelect}
        editFile={editFile}
      />
    )
  }

  return (
    <EmptyState
      className={classes.emptyState}
      openFileSelect={openFileSelect}
    />
  )
}

/* eslint-enable react/prop-types */

function InlineDocumentVaultFileUpload ({ className, clientId, onCancel, onSave, saveText }) {
  const {
    files,
    onDeleteFile,
    onFileAccepted,
    uploading,
    editFile
  } = useFileEvents(clientId, true)
  const classes = useStyles({ files })
  const validator = useValidator(files)
  const { mutateAsync: finalizeUploadAsync, isLoading: finalizing } = useFinalizeDocumentUploads()

  const saveHandler = useCallback(async () => {
    const result = await finalizeUploadAsync({ clientId, files })
    onSave(result)
  }, [finalizeUploadAsync, clientId, onSave, files])

  const disabled = useMemo(() => {
    return finalizing || files.reduce((p, c) => !c.status?.uploaded || p, !files.length)
  }, [files, finalizing])
  const hasFiles = files?.length > 0

  return (
    <div className={clsx(classes.inlineDropzone, className)}>
      <DragAndDropInputArea
        validator={validator}
        acceptedFileTypes={DOCUMENT_VAULT_ACCEPTED_FILES}
        onDropAccepted={onFileAccepted}
      >
        <DropzoneBody
          classes={classes}
          files={files}
          uploading={uploading}
          onDeleteFile={onDeleteFile}
          editFile={editFile}
        />
      </DragAndDropInputArea>
      <div className={classes.actions}>
        {hasFiles ? (
          <RoundedButton size='small' primary onClick={saveHandler} isLoading={finalizing} disabled={disabled}>
            {saveText}
          </RoundedButton>
        ) : null}
        {onCancel ? (
          <RoundedButton size='small' tertiary onClick={onCancel} disabled={disabled}>
            Cancel
          </RoundedButton>
        ) : null}
      </div>
    </div>
  )
}

InlineDocumentVaultFileUpload.propTypes = {
  className: PropTypes.string,
  clientId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  saveText: PropTypes.string
}

InlineDocumentVaultFileUpload.defaultProps = {
  saveText: 'Save'
}

export default InlineDocumentVaultFileUpload
