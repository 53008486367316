import React from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import BlocksInfiniteGrid from './BlocksInfiniteGrid'
import BlocksContainer from './BlocksContainer'
import BlocksListSkeleton from './BlocksListSkeleton'

const ViewBlocks = ({
  data,
  rowHeight,
  minColumnWidth,
  useCellMeasurer,
  isLoading,
  fetchNextPage,
  cellComponent,
  rowCount,
  header
}) => {
  return (
    <BlocksContainer>
      {({ width, containerRef }) => {
        const columnCount = Math.floor(width / minColumnWidth)

        if (isLoading) {
          return (
            <>
              {header}
              <BlocksListSkeleton />
            </>
          )
        }

        return (
          <BlocksInfiniteGrid
            containerRef={containerRef}
            width={width}
            data={data}
            header={header}
            rowCount={rowCount}
            rowHeight={rowHeight}
            isLoading={isLoading}
            columnCount={columnCount}
            fetchNextPage={fetchNextPage}
            cellComponent={cellComponent}
            minColumnWidth={minColumnWidth}
            useCellMeasurer={useCellMeasurer}
          />
        )
      }}
    </BlocksContainer>
  )
}

ViewBlocks.propTypes = {
  data: PropTypes.array,
  header: PropTypes.node,
  rowHeight: PropTypes.number,
  minColumnWidth: PropTypes.number,
  useCellMeasurer: PropTypes.bool,
  isLoading: PropTypes.bool,
  fetchNextPage: PropTypes.func,
  cellComponent: PropTypes.node,
  rowCount: PropTypes.number
}

ViewBlocks.defaultProps = {
  data: [],
  header: undefined,
  rowHeight: 287,
  minColumnWidth: 262,
  useCellMeasurer: false,
  isLoading: false,
  fetchNextPage: noop,
  cellComponent: undefined,
  rowCount: 0
}

export default ViewBlocks
