import React, { useCallback, useMemo, useRef, useState } from 'react'
import { makeStyles } from '@material-ui/core'
import ErrorBoundary from '../../../../molecules/ErrorBoundary'
import { useUpdateAdvisorUserMutation } from '../../../../../api/users'
import { useInvalidateClientSearch } from '../../../../../api/clients'
import PersonalSpace from '../../shared/PersonalSpace'
import { useAdvisorUserDetails, useSectionEditing } from '../AdvisorUserFormContext'
import SectionScreen from '../../shared/SectionScreen'
import SaveCancelFooter from '../../shared/SaveCancelFooter'
import Limited from './Limited'
import AllAccounts from './AllAccounts'
import Unrestricted from './Unrestricted'

const useStyles = makeStyles((theme) => ({
  clientsSection: {
    position: 'relative',
    padding: '10px',
    transition: 'outline-color 300ms ease-in-out',
    minHeight: '500px'
  }
}))

const TAB_SECTION = 'clients'

const useSubmitter = (user, formRef, onComplete) => {
  const invalidateClientSearch = useInvalidateClientSearch()
  const [processing, setProcessing] = useState(false)
  const { mutateAsync: updateAdvisor } = useUpdateAdvisorUserMutation()
  const onSubmit = useCallback(async (formData) => {
    try {
      setProcessing(true)
      await updateAdvisor({
        user: {
          userId: user.userId,
          externalUserId: user.externalUserId
        },
        addAuthorizations: formRef.current.added.map(a => ({ levelTypeId: 201, levelId: a })),
        removeAuthorizations: formRef.current.removed.map(r => ({ levelTypeId: 201, levelId: r }))
      })
      await invalidateClientSearch()
      onComplete()
    } catch (err) {
      console.error(err)
      alert('There was a problem saving your client assignments')
    } finally {
      setProcessing(false)
    }
  }, [updateAdvisor, setProcessing, onComplete, user, formRef, invalidateClientSearch])

  return {
    submitter: onSubmit,
    processing
  }
}

function ClientsTab () {
  const { user, editing, cancelEdit } = useAdvisorUserDetails()
  const sectionEditing = useSectionEditing(TAB_SECTION)
  const formRef = useRef()
  const { submitter, processing } = useSubmitter(user, formRef, cancelEdit)

  const classes = useStyles({ editing })
  const accessType = useMemo(() => {
    if (user.allAccounts) return 'all'
    if (user.allowAllUnrestricted) return 'unrestricted'
    return 'limited'
  }, [user])

  return (
    <ErrorBoundary name='Advisor Clients Tab'>
      <SectionScreen
        editing={editing}
        sectionIsEditing={sectionEditing}
        className={classes.clientsSection}
      >
        {accessType === 'all' ? (
          <AllAccounts />
        ) : accessType === 'unrestricted' ? (
          <Unrestricted formRef={formRef} />
        ) : (
          <Limited formRef={formRef} />
        )}
        <PersonalSpace />
      </SectionScreen>
      {sectionEditing ? (
        <SaveCancelFooter
          saveText='Save Client Assignments'
          onCancel={cancelEdit}
          onSave={submitter}
          processing={processing}
        />
      ) : null}
    </ErrorBoundary>
  )
}

ClientsTab.propTypes = {
}

export default ClientsTab
