import React from 'react'
import { makeStyles } from '@material-ui/core'
import FadeIn from '../../../molecules/Transitions/FadeIn'
import { useListBppReportTypes } from '../../../../api/coreData'
import DataHomeItemGroup from '../shared/DataHomeItemGroup'
import PageContainer from '../components/PageContainer'
import DenaliReportType from './DenaliReportType'

const useStyles = makeStyles((theme) => ({
  reportTypeList: {
    padding: '20px 0',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: '20px'
  }
}))

const query = {}
const useBppReportsByType = () => {
  const { data, isLoading } = useListBppReportTypes(query)
  return {
    data,
    isLoading
  }
}

function DenaliIndex () {
  const classes = useStyles()
  const { data, isLoading } = useBppReportsByType()

  if (isLoading) {
    return null
  }

  return (
    <PageContainer className={classes.bppIndex}>
      <FadeIn>
        <DataHomeItemGroup name='Denali Reports by Type'>
          {data.reportTypes.map(item => (
            <DenaliReportType
              key={item.reportType}
              item={item}
            />
          ))}
        </DataHomeItemGroup>
      </FadeIn>
    </PageContainer>
  )
}

export default DenaliIndex
