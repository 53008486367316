import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'

const delayedDots = (dotsLength) =>
  Array(dotsLength)
    .fill(0)
    .map((_, index) => index * 2)
    .filter(Boolean)
    .reduce(
      (acc, delay, index) => {
        const nthChild = acc.startingIndex + index
        if (index === dotsLength - 2) delete acc.startingIndex
        return {
          ...acc,
          [`& > div:nth-child(${nthChild})`]: {
            animationDelay: `0.${delay}s`
          }
        }
      },
      { startingIndex: 2 }
    )

const useStyles = makeStyles(() => ({
  bouncingLoaderContainer: {
    '& .bouncing-loader': {
      display: 'flex',
      justifyContent: 'center',
      margin: '40px auto',
      '& > div': {
        width: '1rem',
        height: '1rem',
        margin: '3px 6px',
        borderRadius: '50%',
        backgroundColor: '#a3a1a1',
        opacity: 1,
        animation: '$bouncing-loader 0.6s infinite alternate'
      },
      ...delayedDots(3)
    }
  },
  '@keyframes bouncing-loader': {
    to: {
      opacity: '0.1',
      transform: 'translateY(-16px)'
    }
  }
}))

const BouncingDotsLoader = ({ dotsLength }) => {
  const classes = useStyles()
  return (
    <div className={classes.bouncingLoaderContainer}>
      <div className='bouncing-loader'>
        {Array(dotsLength)
          .fill(0)
          .map((_, index) => (
            <div key={index} />
          ))}
      </div>
    </div>
  )
}

BouncingDotsLoader.propTypes = {
  dotsLength: PropTypes.number
}

BouncingDotsLoader.defaultProps = {
  dotsLength: 3
}

export default BouncingDotsLoader
