import React from 'react'
import { makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import CardSkeleton from './CardSkeleton'

const useStyles = makeStyles((theme) => ({
  skeletonContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: '1rem',
    padding: '1rem'
  },
  skeleton: {
    height: '18rem',
    width: '15rem',
    display: 'flex',
    background: '#FAFAF9',
    borderRadius: '12px',
    padding: '1.75rem',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    border: `2px solid ${theme.palette.gray.lighter}`
  }
}))

const CardListSkeleton = ({
  containerClassName,
  cardListLength,
  itemClassName,
  cardComponent: CardComponent
}) => {
  const classes = useStyles()

  return (
    <div
      className={clsx(classes.skeletonContainer, {
        [containerClassName]: Boolean(containerClassName)
      })}
    >
      {Array(cardListLength)
        .fill()
        .map((_, i) => (
          <div
            key={i}
            className={clsx(classes.skeleton, {
              [itemClassName]: Boolean(itemClassName)
            })}
          >
            <CardComponent loading />
          </div>
        ))}
    </div>
  )
}

CardListSkeleton.propTypes = {
  cardListLength: PropTypes.number,
  itemClassName: PropTypes.string,
  containerClassName: PropTypes.string,
  cardComponent: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired
}

CardListSkeleton.defaultProps = {
  cardListLength: 5,
  itemClassName: '',
  containerClassName: '',
  cardComponent: CardSkeleton
}

export default CardListSkeleton
