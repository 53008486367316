import { combineReducers } from 'redux'
import appContext from './appContext'
import appConfig from './appConfig'
import viewContext from './viewContext'
import noteContext from './noteContext'
import abundanceEngineContext from './abundanceEngineContext'
import clientContext from './clientContext'
import addAccountContext from './addAccountContext'

const reducers = {}
const slices = [
  appContext,
  appConfig,
  viewContext,
  noteContext,
  abundanceEngineContext,
  clientContext,
  addAccountContext
]
slices.forEach((slice) => {
  reducers[slice.name] = slice.reducer
})

const appReducer = combineReducers(reducers)

function rootReducer (state, action) {
  return appReducer(state, action)
}

export default rootReducer
