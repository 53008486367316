import { makeStyles } from '@material-ui/core'
import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { CLIENT_VIEW_CONTEXT } from '..'
import { VIEW_TYPES } from '../helpers'
import ClientListView from '../ClientListView'
import ClientCardView from '../ClientCardView'
import { useContextContainer } from '../../../../abundanceEngine/components/ContextContainer'
import { useIntegrations } from '../../../../hooks'
import { ContextualClient } from '../../ContextualClientInfo/contextualClientComponents'

const useStyles = makeStyles(() => ({
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column'
  }
}))

const ClientViewContent = ({ drawer }) => {
  const classes = useStyles()

  const [
    { viewType, pageSize, rowHeight, columnWidth },
    setClientViewContext
  ] = useContextContainer(CLIENT_VIEW_CONTEXT)

  const integrations = useIntegrations({ includeActiveCrmFlag: true })

  useEffect(() => {
    setClientViewContext((prevState) => ({
      ...prevState,
      integrations
    }))
  }, [integrations, setClientViewContext])

  return (
    <div className={classes.container}>
      {viewType === VIEW_TYPES.BLOCKS && (
        <ClientCardView
          pageSize={pageSize}
          rowHeight={rowHeight}
          columnWidth={columnWidth}
        />
      )}
      {viewType === VIEW_TYPES.LIST && <ClientListView />}
      {drawer || (
        <ContextualClient.Wrapper>
          <ContextualClient.Drawer>
            <ContextualClient.Info />
            <ContextualClient.Category title='Tags'>
              <ContextualClient.Tags />
            </ContextualClient.Category>
            <ContextualClient.Category title='General Information'>
              <ContextualClient.IntegrationMapper />
            </ContextualClient.Category>
          </ContextualClient.Drawer>
        </ContextualClient.Wrapper>
      )}
    </div>
  )
}

ClientViewContent.propTypes = {
  drawer: PropTypes.node
}

export default ClientViewContent
