import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  infoContainer: {
    width: '15rem',
    margin: '.5rem',
    '& .__nameWrapper': {
      alignSelf: 'center',
      marginTop: '.5rem'
    },
    '& .__name': {
      fontSize: '1rem',
      fontWeight: '400',
      textAlign: 'center'
    },
    '&:hover': {
      textDecoration: 'none !important'
    },
    '&:hover .__name': {
      fontWeight: '600'
    }
  }
}))

const DefaultGroupInfo = ({ group }) => {
  const classes = useStyles()

  return (
    <Link to={`/advisor-view/${group.groupId}`} className={classes.infoContainer}>
      <div className='__nameWrapper'>
        <div className='__name'>{group.longName}</div>
      </div>
    </Link>
  )
}

DefaultGroupInfo.propTypes = {
  group: PropTypes.object
}

export default DefaultGroupInfo
