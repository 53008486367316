import { createContext, useCallback, useContext, useEffect, useMemo, useReducer, useRef, useState } from 'react'
import { useGoalCategories, useLevelPlans, usePlanStatuses } from '../../../api/planning'
import useFetchClientBalances from '../ClientCardsViewV2/hooks/useClientBalances'
import { CALC_TYPES } from '../../../constants'
import { postNamedCommand } from '../../../service'
import { ActiveGoalIdsReducer } from './reducers/ActiveGoalIdsReducer'
import { PlansReducer } from './reducers/PlansReducer'

export const DynamicPlanningContext = createContext({
  currentPlan: null,
  sandboxPlans: [],
  clientBalance: null,
  activeGoals: []
})

export const useDynamicPlanning = () => {
  return useContext(DynamicPlanningContext)
}

export const useDynamicPlanningValues = ({
  clientId,
  displayDates: _displayDates,
  debounceTimeInMs = 3000,
  defaultAssumptions = {
    inflationModel: { growthType: 'constant', growthFactor: 0.03, adjustReturns: false },
    portfolioGrowthModel: { growthType: 'constant', growthFactor: 0.069 }
  }
}) => {
  const [clientBalance, setClientBalance] = useState(null)
  const [displayDates] = useState(_displayDates)
  const [currentPlanId, setCurrentPlanId] = useState(null)
  const [currentEditGoalId, setCurrentEditGoalId] = useState(null)
  const [selectedChart, setSelectedChart] = useState(null)

  const [activeGoalIds, activeGoalIdsDispatch] = useReducer(ActiveGoalIdsReducer, [], (initArgs) => initArgs)
  const [plans, plansDispatcher] = useReducer(PlansReducer, [], (initArgs) => initArgs)

  const {
    data: originalPlans,
    isLoading: isLoadingPlans,
    refetch: refetchPlans,
    error: plansError
  } = useLevelPlans(
    { levelId: clientId, levelTypeId: 201, include: { includeGoals: true } },
    { enabled: true }
  )

  const {
    data: planStatuses,
    isLoading: isLoadingPlanStatuses
  } = usePlanStatuses({}, { enabled: true })

  const {
    data: goalCategories,
    isLoading: isLoadingCategories
  } = useGoalCategories(
    {},
    { enabled: true, mapper: (data) => data?.sort((a, b) => a.ordinal - b.ordinal) }
  )

  const {
    data: originalClientBalance,
    isLoading: isLoadingOriginalClientBalance
  } = useFetchClientBalances(
    {
      take: 1,
      startDate: displayDates.startDate.format('YYYY-MM-DD'),
      endDate: displayDates.startDate.format('YYYY-MM-DD'),
      calcType: CALC_TYPES.balance,
      orderBy: 'endingValue DESC',
      clientIds: [clientId]
    },
    {
      enabled: !!displayDates,
      mapper: (data) => (data?.[0] ?? {}).endingValue ?? null
    }
  )

  const planStatusMap = useMemo(() => {
    return planStatuses?.reduce((acc, status) => {
      acc[status.planStatusKey] = status
      return acc
    }, {}) ?? {}
  }, [planStatuses])

  const isSandbox = useCallback((plan) => plan.planStatusId === planStatusMap.SANDBOX?.planStatusId ?? false, [planStatusMap])

  const currentPlan = useMemo(
    () => plans?.find((plan) => plan.planId === currentPlanId) ?? null,
    [plans, currentPlanId]
  )

  const activeGoals = useMemo(() => {
    if (!currentPlan?.goals) return []
    return currentPlan.goals.filter((goal) => activeGoalIds.includes(goal.goalId))
  }, [currentPlan?.goals, activeGoalIds])

  const linkedPlan = useMemo(() => {
    if (!currentPlan?.planId) return null
    if (isSandbox(currentPlan) && currentPlan?.originPlanId) {
      return plans.find((plan) => plan.planId === currentPlan.originPlanId) ?? null
    } else {
      return plans.find((plan) => isSandbox(plan) && plan.originPlanId === currentPlan.planId) ?? null
    }
  }, [currentPlan, isSandbox, plans])

  const linkedPlanActiveGoals = useMemo(() => {
    if (!linkedPlan?.goals) {
      return []
    }
    return linkedPlan.goals.filter((goal) =>
      activeGoalIds.includes(goal.goalId)
    )
  }, [linkedPlan, activeGoalIds])

  const isLoadingData = useMemo(() => {
    return isLoadingOriginalClientBalance || isLoadingPlans || isLoadingPlanStatuses
  }, [isLoadingOriginalClientBalance, isLoadingPlanStatuses, isLoadingPlans])

  useEffect(() => {
    if (originalPlans && originalPlans?.length) {
      plansDispatcher({
        type: 'SET_PLANS',
        payload: originalPlans
      })
    }
  }, [clientId, originalPlans])

  useEffect(() => {
    if (!currentPlanId && plans?.length) {
      setCurrentPlanId(
        plans.find((plan) => plan.planStatusKey === 'ACTIVE')?.planId ??
        plans[0].planId
      )
    }
    if (currentPlanId && !plans.find((plan) => plan.planId === currentPlanId)) {
      setCurrentPlanId(null)
    }
  }, [currentPlanId, setCurrentPlanId, plans])

  useEffect(() => {
    if (currentPlan?.goals) {
      const processGoals = (goals) =>
        activeGoalIdsDispatch({
          type: 'TOGGLE_GOAL_IDS',
          payload: goals
            .filter((goal) => goal.goalCategoryId === 1)
            .map((goal) => goal.goalId) ?? [],
          forceState: 'ACTIVE'
        })

      processGoals(currentPlan.goals)
      linkedPlan && processGoals(linkedPlan.goals)
    }
  }, [currentPlan, linkedPlan, originalPlans])

  useEffect(() => {
    if (currentEditGoalId && !currentPlan?.goals.map((goal) => goal.goalId).includes(currentEditGoalId)) {
      const linkedGoal = currentPlan?.goals.find((goal) => goal.originGoalId === currentEditGoalId)
      if (linkedGoal) {
        return setCurrentEditGoalId(linkedGoal.goalId)
      }
      setCurrentEditGoalId(null)
    }
  }, [currentEditGoalId, currentPlan?.goals])

  useEffect(() => {
    if (clientId && originalClientBalance !== clientBalance) {
      setClientBalance(originalClientBalance)
    }
  }, [clientBalance, clientId, originalClientBalance])

  useEffect(() => {
    if (plansError && typeof currentPlan?.planId !== 'string') {
      plansDispatcher({
        type: 'SET_PLANS',
        payload: [{
          planId: 'NEW',
          shortName: '',
          longName: '',
          planStatusId: 1,
          planStatusColor: '#A2BFF3',
          planStatusDisplayName: 'New Plan',
          isNew: true,
          levelId: clientId,
          levelTypeId: 201,
          assumptions: defaultAssumptions
        }]
      })
    }
  }, [clientId, plansError, defaultAssumptions, currentPlan?.planId])

  const createSandboxFromPlan = (planData) => {
    const sandboxStatus = planStatusMap.SANDBOX
    return {
      ...planData,
      goals: planData?.goals.map((goal) => ({
        ...goal,
        originGoalId: goal.goalId
      })) ?? [],
      originPlanId: planData.planId,
      planId: 'NEW_' + planData.planId,
      shortName: `${planData.shortName.substring(0, 23)} - Edit`,
      longName: `${planData.longName.substring(0, 73)} - Edit`,
      planStatusId: sandboxStatus.planStatusId,
      planStatusKey: sandboxStatus.planStatusKey,
      planStatusColor: sandboxStatus.primaryColor,
      planStatusDisplayName: sandboxStatus.displayName,
      isNew: true
    }
  }

  const postUpdatePlan = useCallback(async (planData) => {
    const { data } = await postNamedCommand(
      'planning',
      typeof planData.planId === 'number' ? 'updatePlan' : 'createPlan',
      {
        ...planData,
        levelTypeId: 201,
        levelId: clientId
      })
    plansDispatcher({
      type: typeof planData.planId === 'number' ? 'UPDATE_PLAN' : 'ADD_PLAN',
      payload: {
        ...planData,
        ...data
      }
    })
    return data
  }, [clientId])

  const debouncePostUpdatePlanRef = useRef(0)
  const debouncedPostUpdatePlan = useCallback(async (planData) => {
    clearTimeout(debouncePostUpdatePlanRef.current)
    debouncePostUpdatePlanRef.current = setTimeout(() => {
      postUpdatePlan(planData)
    }, debounceTimeInMs)
  }, [debounceTimeInMs, postUpdatePlan])

  const postUpdateGoal = useCallback(async (goalData, debounce = true) => {
    const { data } = await postNamedCommand('planning', typeof goalData.goalId === 'number' ? 'updateGoal' : 'createGoal', {
      ...goalData,
      levelTypeId: 201,
      levelId: clientId
    })
    return data
  }, [clientId])

  const debouncePostUpdateGoalRef = useRef(0)
  const debouncedPostUpdateGoal = useCallback(async (goalData) => {
    clearTimeout(debouncePostUpdateGoalRef.current)
    debouncePostUpdateGoalRef.current = setTimeout(() => {
      postUpdateGoal(goalData)
    }, debounceTimeInMs)
  }, [debounceTimeInMs, postUpdateGoal])

  const updatePlan = async (planId, _updates, updateOriginal = false) => {
    const updates = { ..._updates }

    if (_updates?.assumptions) {
      updates.assumptions = {
        ...currentPlan?.assumptions,
        ..._updates.assumptions
      }
    }

    const existingPlan = plans.find((plan) => plan.planId === planId)
    if (!existingPlan) return

    let planUpdate = { ...existingPlan, ...updates }

    if (existingPlan.isNew) {
      plansDispatcher({ type: 'UPDATE_PLAN', payload: planUpdate })
      return
    }

    if (!isSandbox(existingPlan) && !updateOriginal) {
      const linkedSandboxPlan = plans.find((plan) => existingPlan.planId === plan.originPlanId && isSandbox(plan))
      if (!linkedSandboxPlan) {
        const newSandboxPlan = createSandboxFromPlan({ ...existingPlan, ..._updates })
        plansDispatcher({ type: 'ADD_PLAN', payload: newSandboxPlan })
        const data = await postUpdatePlan(newSandboxPlan)
        return setCurrentPlanId(data.planId)
      }
      setCurrentPlanId(linkedSandboxPlan.planId)
      planUpdate = { ...linkedSandboxPlan, ...updates }
    }
    plansDispatcher({ type: 'UPDATE_PLAN', payload: planUpdate })
    return await debouncedPostUpdatePlan(planUpdate)
  }

  const updateGoal = async (goalId, planId, updates, updateOriginal = false) => {
    let planToUpdate = plans.find((plan) => plan.planId === planId)

    if (planToUpdate.planStatusKey !== 'SANDBOX' && !updateOriginal) {
      let sandboxPlan = plans.find((plan) => planToUpdate.planId === plan.originPlanId)

      if (!sandboxPlan) {
        sandboxPlan = createSandboxFromPlan({
          ...planToUpdate,
          goals: planToUpdate.goals.map((goal) =>
            goal.goalId === goalId ? { ...goal, ...updates } : goal
          )
        })
        plansDispatcher({ type: 'ADD_PLAN', payload: sandboxPlan })
        sandboxPlan = await postUpdatePlan(sandboxPlan)
      }

      planToUpdate = sandboxPlan
      setCurrentPlanId(planToUpdate.planId)
    }

    if (!goalId) {
      const createGoalData = {
        ...updates,
        planId: planToUpdate.planId
      }

      const data = await postUpdateGoal(createGoalData)
      plansDispatcher({ type: 'ADD_GOAL', payload: data })
      return setCurrentEditGoalId(data.goalId)
    }

    const goalToUpdate =
      planToUpdate.goals.find((goal) => goal.goalId === goalId) ??
      planToUpdate.goals.find((goal) => goal.originGoalId === goalId)

    if (!goalToUpdate) {
      return
    }

    setCurrentEditGoalId(goalToUpdate.goalId)

    const updateData = {
      ...updates,
      goalId: goalToUpdate.goalId,
      planId: planToUpdate.planId
    }

    if (typeof updateData.flowAmount === 'number') {
      updateData.flowAmount = updateData.flowAmount.toString()
    }

    plansDispatcher({ type: 'UPDATE_GOAL', payload: updateData })

    if (!activeGoalIds.includes(updateData.goalId)) {
      const linkedGoal = plans.flatMap(plan => plan.goals).find((goal) =>
        goal.originGoalId === goalToUpdate.goalId ||
        goal.goalId === goalToUpdate.originGoalId
      )
      activeGoalIdsDispatch({
        type: 'TOGGLE_GOAL_IDS',
        payload: [updateData.goalId, ...([linkedGoal && linkedGoal.goalId] ?? [])],
        forceState: 'ACTIVE'
      })
    }

    return await debouncedPostUpdateGoal(updateData)
  }

  const goToNextGoal = useCallback((direction = 'NEXT') => {
    const goalIdArray = currentPlan?.goals?.map(goal => goal.goalId)
    if (!goalIdArray?.length) {
      return
    }
    const currentIndex = goalIdArray.indexOf(currentEditGoalId)
    let nextIndex = direction === 'NEXT' ? currentIndex + 1 : currentIndex - 1
    if (nextIndex < 0) {
      nextIndex = goalIdArray.length - 1
    }
    if (nextIndex >= goalIdArray.length) {
      nextIndex = 0
    }
    setCurrentEditGoalId(goalIdArray[nextIndex])
  }, [currentPlan?.goals, currentEditGoalId])

  return {
    refetchPlans,
    plans,
    plansDispatcher,
    currentPlan,
    currentPlanId,
    setCurrentPlanId,
    originalPlans,
    clientBalance,
    setClientBalance,
    activeGoals,
    activeGoalIds,
    activeGoalIdsDispatch,
    isLoadingData,
    displayDates,
    updatePlan,
    updateGoal,
    createSandboxFromPlan,
    postUpdatePlan,
    planStatusMap,
    planStatuses,
    linkedPlan,
    linkedPlanActiveGoals,
    currentEditGoalId,
    setCurrentEditGoalId,
    goalCategories,
    isLoadingCategories,
    goToNextGoal,
    isSandbox,
    selectedChart,
    setSelectedChart,
    clientId
  }
}
