import { useEffect, useMemo, useState } from 'react'
import { isEmpty } from 'lodash'
import { useAppContext } from '../../../redux/slices/appContext'
import { useSearchGroupsDebounced } from '../../../hooks'
import { getGroupTypesQuery, mapGroupTypes } from '../../../components/pages/admin/Accounts/AccountsListTab/useGroupTypeColumns'
import { useGroupTypeSearch } from '../../../api/groups'
import { localStorageHelper } from '../../../utils/localStorageHelper'
import { getFiltersVisibilityKey, getGroupIds, getGroupTypeKey, mapPinnedOptions } from './helpers'

export const useGroupPinnedOptions = (
  initialFilterState,
  enabledPinnedOptions
) => {
  const appContext = useAppContext()

  const pinnedGroupIds = useMemo(() => {
    return getGroupIds(initialFilterState)
  }, [initialFilterState])

  const searchPinnedGroupsQuery = useMemo(() => {
    return {
      query: {
        filters: {
          assignedToClientIds: [appContext.clientId],
          groupId: [{ op: 'in', value: pinnedGroupIds }]
        },
        includes: { groupType: true }
      },
      queryOptions: {
        enabled: !isEmpty(pinnedGroupIds),
        mapper: mapPinnedOptions(enabledPinnedOptions)
      }
    }
  }, [appContext.clientId, pinnedGroupIds, enabledPinnedOptions])

  const { options: pinnedOptions = [], isFetching: isLoadingPinnedOptions } =
    useSearchGroupsDebounced(searchPinnedGroupsQuery)

  return { pinnedOptions, pinnedGroupIds, isLoadingPinnedOptions }
}

export const useGroupTypes = (
  defaultFilter,
  pinnedGroupIds,
  accountIds,
  shouldFilterByAccounts
) => {
  const groupTypesQuery = useMemo(() => {
    let enabled = false
    if (!isEmpty(defaultFilter)) {
      if (shouldFilterByAccounts) {
        enabled = !isEmpty(accountIds)
      } else {
        enabled = true
      }
    }

    const query = enabled ? {
      filters: {
        ...defaultFilter
      },
      includes: {
        groups: isEmpty(accountIds)
          ? true
          : {
            assignedToAccountIds: [{ op: 'in', value: accountIds }]
          }
      }
    } : null

    const queryOptions = {
      mapper: (data) => {
        return mapGroupTypes(
          data,
          (group) => !pinnedGroupIds.includes(group.groupId)
        )
      },
      enabled
    }
    return getGroupTypesQuery(query, queryOptions)
  }, [
    accountIds,
    defaultFilter,
    pinnedGroupIds,
    shouldFilterByAccounts
  ])

  const { data: groupTypes, isLoading: isLoadingGroupTypes } =
    useGroupTypeSearch(groupTypesQuery.query, groupTypesQuery.queryOptions)

  const groupTypesGroupedByKey = useMemo(() => {
    if (!groupTypes) return {}
    return groupTypes.reduce((acc, groupType) => {
      const groupTypeKey = getGroupTypeKey(groupType.label)
      return {
        ...acc,
        [groupTypeKey]: {
          key: groupTypeKey,
          groupName: groupType.label,
          groupTypeId: groupType.value,
          groupIds: groupType.groups.map(({ value }) => value)
        }
      }
    }, {})
  }, [groupTypes])

  return { groupTypes, isLoadingGroupTypes, groupTypesGroupedByKey }
}

export const useVisibleFilters = (configStorageKey) => {
  const storageKey = getFiltersVisibilityKey(configStorageKey)

  const initialState = configStorageKey
    ? localStorageHelper.load(storageKey) ?? []
    : []

  const [visibleFilters, setVisibleFilters] = useState(initialState)

  useEffect(() => {
    if (configStorageKey) {
      localStorageHelper.store(storageKey, visibleFilters ?? [])
    }
  }, [storageKey, visibleFilters, configStorageKey])

  return {
    visibleFilters,
    setVisibleFilters
  }
}
