import { Box, getLuminance, IconButton, InputBase, makeStyles, MenuItem, Select, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Skeleton from '../../../atoms/Skeleton'
import { ICON_NAMES, TEXT_VARIANTS } from '../../../../constants'
import Text from '../../../atoms/Text'
import { useDynamicPlanning } from '../DynamicPlanningContext'
import Tag from '../../../atoms/Tag'
import Icon from '../../../atoms/Icon'
import PlanMenuItem from './PlanMenuOption'

const useStyles = makeStyles((theme) => ({
  dynamicPlanningHeadingText: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
    display: 'block',
    padding: 0,
    borderBottom: '3px solid transparent'
  },
  dynamicPlanningHeadingInput: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
    display: 'block',
    borderBottom: '3px solid #000'
  },
  planningDropdownContainer: {
    position: 'relative',
    display: 'flex',
    width: '100%',
    padding: '0 1rem 0 0'
  },
  editingLabel: {
    position: 'absolute',
    bottom: '2.8rem',
    lineHeight: '1rem'
  },
  editToggle: {
    position: 'absolute',
    right: '100%',
    top: '50%',
    margin: '-1rem .5rem 0 0',
    cursor: 'pointer',
    opacity: 0.4
  },
  displayPlanName: {
    display: 'flex'
  }
}))

const PlanSelect = ({
  createFirstPlanText = 'Create a new plan to get started using dynamic planning',
  showLastModifiedDate = true,
  lastModifiedPrefix = 'Last Modified: '
}) => {
  const classes = useStyles()
  const {
    currentPlan,
    setCurrentPlanId,
    isLoadingData,
    updatePlan,
    postUpdatePlan,
    plans,
    planStatusMap
  } = useDynamicPlanning()

  const [isEditingPlan, setIsEditingPlan] = useState(!!currentPlan?.isNew)
  const [isUpdating, setIsUpdating] = useState(false)
  const [editingValue, setEditingValue] = useState(null)

  useEffect(() => {
    if (isEditingPlan) {
      setEditingValue(currentPlan?.shortName)
    }
  }, [isEditingPlan, currentPlan?.shortName])

  useEffect(() => {
    if (currentPlan?.isNew) {
      setIsEditingPlan(true)
    } else {
      setIsEditingPlan(false)
    }
  }, [setIsEditingPlan, currentPlan?.isNew])

  const handlePlanUpdate = async () => {
    setIsUpdating(true)
    await postUpdatePlan({ ...currentPlan, shortName: editingValue, longName: editingValue, isNew: false })
    setIsUpdating(false)
    setIsEditingPlan(false)
  }

  useEffect(() => {
    if (currentPlan?.isNew && editingValue && currentPlan?.shortName !== editingValue) {
      updatePlan(currentPlan.planId, { shortName: editingValue, longName: editingValue })
    }
  }, [editingValue, currentPlan?.isNew, currentPlan?.planId, updatePlan, currentPlan?.shortName])

  if (isLoadingData) {
    return (
      <Skeleton width='400px' height='1.75rem' />
    )
  }

  if (isEditingPlan) {
    return (
      <div className={classes.planningDropdownContainer}>
        {!currentPlan?.isNew && (
          <IconButton
            onClick={() => setIsEditingPlan(false)}
            type='button'
            disabled={isUpdating}
            className={classes.editToggle}
          >
            <Icon
              name={ICON_NAMES.close}
              customSize='1.2rem'
            />
          </IconButton>
        )}
        {currentPlan?.isNew && (
          <Text
            variant={TEXT_VARIANTS.subtitle}
            className={classes.editingLabel}
          >
            {
              plans.length <= 1
                ? createFirstPlanText
                : 'Edit the name for the plan edits you are making & confirm assumptions to the right'
            }
          </Text>
        )}
        <Box flex='1 0 300px'>
          <InputBase
            fullWidth
            value={editingValue}
            onChange={(e) => setEditingValue(e.target.value)}
            onKeyUp={e => e.key === 'Enter' && handlePlanUpdate()}
            classes={{ input: classes.dynamicPlanningHeadingInput }}
            autoFocus
          />
        </Box>
        <IconButton
          onClick={handlePlanUpdate}
          type='button'
          disabled={isUpdating}
          style={{ minWidth: 'auto' }}
        >
          <Icon
            name={ICON_NAMES.check}
            customSize='1.2rem'
          />
        </IconButton>
      </div>
    )
  }

  if (!currentPlan) return null

  return (
    <div className={classes.planningDropdownContainer}>
      <IconButton
        onClick={() => setIsEditingPlan(true)}
        type='button'
        className={classes.editToggle}
      >
        <Icon
          name={ICON_NAMES.edit}
          customSize='1.2rem'
        />
      </IconButton>
      <Select
        fullWidth
        disableUnderline
        className={classes.planningDropdown}
        onChange={(e) => setCurrentPlanId(e.target.value)}
        disabled={isLoadingData || !plans?.length}
        defaultValue={currentPlan?.planId}
        value={currentPlan?.planId}
        renderValue={() => (
          <div className={classes.displayPlanName} key={currentPlan.planId}>
            <Typography className={classes.dynamicPlanningHeadingText}>
              {currentPlan.shortName}
            </Typography>
            <Tag
              backgroundColor={currentPlan.planStatusColor}
              color={getLuminance(currentPlan.planStatusColor) > 0.6 ? '#000' : '#fff'}
              label={currentPlan.planStatusDisplayName}
              height='16px'
              margin='4px 12px'
            />
          </div>
        )}
      >
        {plans.filter(row => ![planStatusMap.SANDBOX.planStatusId, planStatusMap.ARCHIVED.planStatusId].includes(row.planStatusId)).map((plan) => (
          <MenuItem key={plan.planId} value={plan.planId}>
            <PlanMenuItem
              key={plan.planId}
              plan={plan}
              showLastModifiedDate={showLastModifiedDate}
              lastModifiedPrefix={lastModifiedPrefix}
            />
          </MenuItem>
        ))}
      </Select>
    </div>
  )
}

PlanSelect.propTypes = {
  createFirstPlanText: PropTypes.string,
  showLastModifiedDate: PropTypes.bool,
  lastModifiedPrefix: PropTypes.string
}

export default PlanSelect
