import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useTheme } from '@material-ui/core'

export const useSubtitleLayer = (value, height) => {
  return useCallback((props) => {
    const { centerX, centerY, innerRadius } = props
    return (
      <SubtitleLayer
        x={centerX}
        y={centerY - (innerRadius / 7)}
        value={value}
        height={height}
      />
    )
  }, [value, height])
}

function SubtitleLayer ({ x, y, value, height }) {
  const theme = useTheme()
  const size = useMemo(() => {
    return `${height / 10}px`
  }, [height])

  return (
    <text
      style={{ userSelect: 'none', fontWeight: 100 }}
      x={x}
      y={y}
      fontFamily={theme.typography.fontFamily}
      fontSize={size}
      textAnchor='middle'
    >
      {value}
    </text>
  )
}

SubtitleLayer.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  value: PropTypes.any,
  height: PropTypes.number
}

export default SubtitleLayer
