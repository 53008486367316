import React, { useCallback, useMemo, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import noop from 'lodash/noop'
import isEmpty from 'lodash/isEmpty'
import isFinite from 'lodash/isFinite'
import {
  InputAdornment,
  InputBase,
  IconButton
} from '@material-ui/core'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import { BUTTON_VARIANT, BUTTON_SIZES, ICON_NAMES } from '../../constants'
import RoundedButton from '../atoms/RoundedButton'
import { useToggle } from '../../hooks'
import { tableNumberFormatter } from '../../utils'
import { useSetAppContext } from '../../redux/slices/appContext'
import Icon from '../atoms/Icon'
import Text from '../atoms/Text'
import Slider from '../atoms/Slider'
import Select from '../atoms/Select'

const useStyles = makeStyles({
  root: {
    width: 250
  },
  alternativeInput: {
    fontSize: '40px',
    paddingBottom: '0px'
  },
  input: {
    fontSize: '40px',
    width: '240px',
    paddingBottom: '0px'
  },
  inputAmount: {
    fontSize: '40px',
    paddingBottom: '0px',
    marginTop: '-4px'
  },
  container: {
    position: 'relative',
    width: '100%',
    padding: '35px 50px',
    border: '4px solid #E1E1E1',
    boxSizing: 'border-box',
    borderRadius: '20px'
  },
  closeButton: {
    position: 'absolute',
    right: 0,
    top: 0
  },
  header: {
    width: '100%',
    marginBottom: '20px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  content: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-end'
  },
  slider: {
    width: '50%',
    padding: '0px 36px 0px 0px'
  },
  goalTitle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    minWidth: '700px'
  },
  titleInput: {
    fontSize: '24px !important'
  },
  separator: {
    margin: '0px 5px'
  },
  sliderTitle: {
    marginBottom: '10px',
    width: '100%'
  },
  sliderDateRangeTitle: {
    marginBottom: '10px',
    width: '100%'
  },
  dateRangeInputs: {
    flexDirection: 'column'
  },
  dateRangeInputsInline: {
    display: 'flex',
    flexDirection: 'row'
  },
  iconButton: {
    '&:hover': {
      backgroundColor: 'transparent'
    }
  }
})

const SummitSlider = withStyles({
  root: {
    color: '#212945',
    height: 2
  },
  thumb: {
    height: 12,
    width: 12,
    backgroundColor: '#fff',
    border: '4px solid currentColor',
    marginTop: -5,
    marginLeft: -6,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit'
    }
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% - 12px)',
    '& *': {
      background: '#212945  ',
      color: '#ffffff'
    }
  },
  track: {
    height: 2,
    borderRadius: 2
  },
  rail: {
    height: 2,
    borderRadius: 2,
    color: '#d0d0d0'
  }
})(Slider)

const CONTRIBUTION_OPTIONS = [{
  label: 'CONTRIBUTION',
  value: 'CONTRIBUTION'
}, {
  label: 'WITHDRAWAL',
  value: 'WITHDRAWAL'
}]

const DATE_TYPES = [{
  label: 'DATE RANGE',
  value: 'Fixed'
}, {
  label: 'DATE',
  value: 'OneTime'
}]

const minYearDistance = 1

const PlanGoalEdit = ({
  tier,
  goal,
  goal: { status, rate, goalName, startDate, endDate },
  goalDateHandler,
  goalValueHandler,
  handleCloseSection,
  handleEditRateType,
  handleEditGoalName
}) => {
  const classes = useStyles()

  const setAppContext = useSetAppContext()
  const [goalNewName, setGoalNewName] = useState(goalName)
  const [editableGoalName, toggleEditableGoalName] = useToggle()
  const [editableRateValue, toggleEditableRateValue] = useToggle()
  const [editableStartDateValue, toggleEditableStartDateValue] = useToggle()
  const [editableRangeDateValue, toggleEditableRangeDateValue] = useToggle()
  const [rateLimits] = useState({ min: 0, max: 5 * Math.abs(rate.rateValue) })
  const [rateMarks] = useState([{ label: '$0', value: 0 }, { label: tableNumberFormatter(5 * Math.abs(rate.rateValue)), value: 5 * Math.abs(rate.rateValue) }])
  const [dateLimits] = useState({ min: dayjs(startDate).year(), max: dayjs(startDate).year() + 60 })
  const [dateMarks] = useState([{ label: dayjs(startDate).year(), value: dayjs(startDate).year() }, { label: dayjs(startDate).year() + 60, value: dayjs(startDate).year() + 60 }])
  const [dateRangeLimits] = useState({ min: dayjs(startDate).year(), max: dayjs(startDate).year() + 30 })
  const [dateRangeMarks] = useState([{ label: dayjs(startDate).year(), value: dayjs(startDate).year() }, { label: dayjs(startDate).year() + 30, value: dayjs(startDate).year() + 30 }])

  const [dateRangeError, setDateRangeError] = useState(false)

  const rateFlowIn = useMemo(() => rate.rateValue > 0, [rate.rateValue])
  const rateAbsValue = useMemo(() => Math.abs(rate.rateValue), [rate.rateValue])
  const finalStartDate = useMemo(() => isEmpty(startDate) ? dayjs().year() : dayjs(startDate).year(), [startDate])
  const finalEndDate = useMemo(() => isEmpty(endDate) ? finalStartDate + 1 : dayjs(endDate).year(), [finalStartDate, endDate])

  const [tempDates, setTempDates] = useState({
    startDate: finalStartDate,
    endDate: finalEndDate
  })

  useEffect(() => {
    setAppContext({
      disableOverviewKeyNavigation: true
    })
    return () =>
      setAppContext({
        disableOverviewKeyNavigation: false
      })
  }, [setAppContext])

  const rateValueTitle = useMemo(() => {
    if (rate.rateType === 'Fixed') return `$${rateAbsValue} / yr`
    return `$${rateAbsValue}`
  }, [rate.rateType, rateAbsValue])
  const contributionTitle = useMemo(() => {
    return rate.rateValue > 0 ? 'CONTRIBUTION' : 'WITHDRAWAL'
  }, [rate.rateValue])

  const isSigleSlider = useMemo(() => {
    return rate.rateType === 'OneTime'
  }, [rate.rateType])

  const handleOnChangeGoalName = useCallback(
    (e) => setGoalNewName(e.target.value),
    [setGoalNewName]
  )

  const handleOnSubmitGoalName = useCallback(() => {
    handleEditGoalName(goalNewName)
    toggleEditableGoalName()
  }, [handleEditGoalName, goalNewName, toggleEditableGoalName]
  )

  const handleOnCloseGoalName = useCallback(() => {
    toggleEditableGoalName()
    setGoalNewName(goalName)
  }, [toggleEditableGoalName, goalName]
  )

  const handleOnClickGoalName = useCallback(() => {
    if (!editableGoalName) toggleEditableGoalName()
  }, [toggleEditableGoalName, editableGoalName])

  const handleOnSelectAmountType = useCallback((event) => {
    if ((rate.rateValue > 0 && event.target.value === 'WITHDRAWAL') || (rate.rateValue < 0 && event.target.value === 'CONTRIBUTION')) {
      goalValueHandler(-1 * rate.rateValue)
    }
  }, [rate.rateValue, goalValueHandler])

  const handleAmountInput = useCallback(
    (event, valuePass) => {
      let newValue = event.target.value
      newValue = newValue * (rateFlowIn ? 1 : -1)
      console.log('newValue', newValue)
      goalValueHandler(newValue * 1)
    },
    [goalValueHandler, rateFlowIn]
  )

  const handleAmountInputSlider = useCallback(
    (event, valuePass) => {
      if (isFinite(valuePass)) {
        console.log('value event, valuePass', valuePass)
        let newValue = valuePass
        newValue = newValue * (rateFlowIn ? 1 : -1)
        console.log('newValue', newValue)
        goalValueHandler(newValue * 1)
      }
    },
    [goalValueHandler, rateFlowIn]
  )

  const handleOnSelectDateType = useCallback((event) => {
    handleEditRateType(event.target.value)
  }, [handleEditRateType])

  const handleSingleYearSlider = useCallback(
    (event, valuePass) => {
      console.log('year event, valuePass', event, valuePass)
      const newGoalYear = (valuePass || event.target.value) * 1
      let newEndDate = goal.endDate
      const newStartDate = `1/1/${newGoalYear}`
      console.log('props.goal.rate.rateType', goal.rate.rateType)
      if (goal.rate.rateType === 'OneTime') {
        newEndDate = newStartDate
      }
      goalDateHandler(goal, { newStartDate, newEndDate })
    },
    [goalDateHandler, goal]
  )

  const handleOnChangeStartDateInput = useCallback((event) => {
    setDateRangeError(false)
    setTempDates({ startDate: event.target.value, endDate: finalEndDate })
  }, [finalEndDate, setDateRangeError])

  const handleOnChangeEndDateInput = useCallback((event) => {
    setDateRangeError(false)
    setTempDates({ startDate: finalStartDate, endDate: event.target.value })
  }, [finalStartDate, setDateRangeError])

  const handleOnSubmitRangeDateInput = useCallback(() => {
    if (tempDates.startDate >= tempDates.endDate) {
      setDateRangeError(true)
    } else {
      setDateRangeError(false)
      goalDateHandler(goal, { newStartDate: `1/1/${tempDates.startDate}`, newEndDate: `1/1/${tempDates.endDate}` })
      toggleEditableRangeDateValue()
    }
  }, [goalDateHandler, toggleEditableRangeDateValue, goal, tempDates.startDate, tempDates.endDate])

  const handleOnCloseRangeDateInput = useCallback(() => {
    setDateRangeError(false)
    setTempDates({ startDate: finalStartDate, endDate: finalEndDate })
    toggleEditableRangeDateValue()
  }, [finalStartDate, finalEndDate, toggleEditableRangeDateValue])

  const handleRangeYearSlider = useCallback(
    (event, newValue, activeThumb) => {
      let newStartDate, newEndDate
      if (activeThumb === 0) {
        newStartDate = `1/1/${Math.min(newValue[0], finalEndDate - minYearDistance)}`
        newEndDate = `1/1/${newValue[1]}`
      } else {
        newStartDate = `1/1/${newValue[0]}`
        newEndDate = `1/1/${Math.max(newValue[1], finalStartDate + minYearDistance)}`
      }

      goalDateHandler(goal, { newStartDate, newEndDate })
    },
    [goalDateHandler, goal, finalEndDate, finalStartDate]
  )

  const amountSlider = useMemo(() => {
    return (
      <div className={classes.slider}>
        <div className={classes.sliderTitle}>
          <Select
            options={CONTRIBUTION_OPTIONS}
            onChange={handleOnSelectAmountType}
            selectedValue={contributionTitle}
          />
        </div>
        {editableRateValue ? (
          <InputBase
            className={classes.alternativeInput}
            defaultValue={rateAbsValue}
            value={rateAbsValue}
            startAdornment={
              <InputAdornment position='start'>
                <div className={classes.inputAmount}>$</div>
              </InputAdornment>
            }
            endAdornment={
              <IconButton
                onClick={toggleEditableRateValue}
                color='inherit'
              >
                <Icon name={ICON_NAMES.close} customSize='1.2rem' />
              </IconButton>
            }
            onChange={handleAmountInput}
            inputProps={{
              style: { fontSize: '40px', fontWeight: 400 },
              step: 10000,
              min: rateAbsValue - 100000,
              max: rateAbsValue + 100000,
              type: 'number',
              'aria-labelledby': 'input-slider'
            }}
          />
        ) : (
          <div onClick={toggleEditableRateValue}>
            <Text text={rateValueTitle} customFontSize='40px' className={classes.alternativeInput} />
          </div>
        )}
        <div style={{ width: '100%', marginTop: '20px' }}>
          <SummitSlider
            defaultValue={rateAbsValue}
            value={rateAbsValue}
            // getAriaValueText={valuetext}
            // value={dayjs(startDate).year()}
            aria-labelledby='discrete-slider'
            valueLabelDisplay='auto'
            onChange={handleAmountInputSlider}
            step={10000}
            marks={rateMarks}
            min={rateLimits.min}
            max={rateLimits.max}
          />
        </div>
      </div>
    )
  }, [
    classes.alternativeInput,
    classes.inputAmount,
    classes.slider,
    classes.sliderTitle,
    contributionTitle,
    editableRateValue,
    handleAmountInput,
    handleAmountInputSlider,
    handleOnSelectAmountType,
    rateAbsValue,
    rateMarks,
    rateLimits,
    rateValueTitle,
    toggleEditableRateValue
  ])

  const dateSingleSlider = useMemo(() => {
    return (
      <>
        {editableStartDateValue ? (
          <InputBase
            className={classes.input}
            defaultValue={finalStartDate}
            value={finalStartDate}
            margin='dense'
            onChange={handleSingleYearSlider}
            style={{ paddingBottom: '0px' }}
            inputProps={{
              style: { fontSize: '40px', fontWeight: 400 },
              step: 1,
              min: dateLimits.min,
              max: dateLimits.max,
              type: 'number',
              'aria-labelledby': 'input-slider'
            }}
            endAdornment={
              <IconButton
                onClick={toggleEditableStartDateValue}
                color='inherit'
              >
                <Icon name={ICON_NAMES.close} customSize='1.2rem' />
              </IconButton>
            }
          />
        ) : (
          <div onClick={toggleEditableStartDateValue}>
            <Text text={finalStartDate} customFontSize='40px' className={classes.input} />
          </div>
        )}
        <div style={{ width: '100%', marginTop: '20px' }}>
          <SummitSlider
            id='year'
            value={finalStartDate}
            // getAriaValueText={valuetext}
            // value={dayjs(startDate).year()}
            aria-labelledby='discrete-slider'
            valueLabelDisplay='auto'
            onChange={handleSingleYearSlider}
            step={1}
            marks={dateMarks}
            min={dateLimits.min}
            max={dateLimits.max}
          />
        </div>
      </>
    )
  }, [
    classes.input,
    dateLimits.max,
    dateLimits.min,
    dateMarks,
    editableStartDateValue,
    handleSingleYearSlider,
    finalStartDate,
    toggleEditableStartDateValue
  ])

  const dateRangeSlider = useMemo(() => {
    return (
      <>
        <div className={classes.sliderDateRangeTitle}>
          {editableRangeDateValue ? (
            <div className={classes.dateRangeInputs}>
              <div className={classes.dateRangeInputsInline}>
                <div>
                  <Text text='Starts in' customFontSize='24px' />
                  <InputBase
                    className={classes.input}
                    defaultValue={finalStartDate}
                    value={tempDates.startDate}
                    margin='dense'
                    onChange={handleOnChangeStartDateInput}
                    style={{ paddingBottom: '0px' }}
                    inputProps={{
                      style: { fontSize: '40px', fontWeight: 400 },
                      step: 1,
                      min: dateRangeLimits.min,
                      max: dateRangeLimits.max,
                      type: 'number',
                      'aria-labelledby': 'input-slider'
                    }}
                  />
                </div>
                <div>
                  <Text text='Ends in' customFontSize='24px' />
                  <InputBase
                    className={classes.input}
                    defaultValue={finalEndDate}
                    value={tempDates.endDate}
                    margin='dense'
                    onChange={handleOnChangeEndDateInput}
                    style={{ paddingBottom: '0px' }}
                    inputProps={{
                      style: { fontSize: '40px', fontWeight: 400 },
                      step: 1,
                      min: dateRangeLimits.min + 1,
                      max: dateRangeLimits.max,
                      type: 'number',
                      'aria-labelledby': 'input-slider'
                    }}
                  />
                </div>
                <IconButton
                  classes={{ root: classes.iconButton }}
                  onClick={handleOnSubmitRangeDateInput}
                  color='inherit'
                >
                  <Icon name={ICON_NAMES.check} customSize='1.2rem' />
                </IconButton>
                <IconButton
                  onClick={handleOnCloseRangeDateInput}
                  color='inherit'
                >
                  <Icon name={ICON_NAMES.close} customSize='1.2rem' />
                </IconButton>
              </div>
              {dateRangeError && (
                <Text text='Dates out of possible range' customFontSize='12px' color='red' />
              )}
            </div>
          ) : (
            <div className={classes.dateRangeInputsInline}>
              <div onClick={toggleEditableRangeDateValue} className={classes.input}>
                <Text text='Starts in' customFontSize='24px' />
                <Text text={finalStartDate} customFontSize='40px' />
              </div>
              <div onClick={toggleEditableRangeDateValue} className={classes.input}>
                <Text text='Ends in' customFontSize='24px' />
                <Text text={finalEndDate} customFontSize='40px' />
              </div>
            </div>
          )}
        </div>
        <div style={{ width: '100%', marginTop: '20px' }}>
          <SummitSlider
            id='year'
            defaultValue={[finalStartDate, finalEndDate]}
            value={[finalStartDate, finalEndDate]}
            aria-labelledby='discrete-slider'
            valueLabelDisplay='auto'
            onChange={handleRangeYearSlider}
            step={1}
            disableSwap
            disabled={editableRangeDateValue}
            min={dateRangeLimits.min}
            max={dateRangeLimits.max}
            marks={dateRangeMarks}
          />
        </div>
      </>
    )
  }, [
    classes.input,
    classes.sliderDateRangeTitle,
    classes.dateRangeInputs,
    classes.dateRangeInputsInline,
    classes.iconButton,
    dateRangeError,
    dateRangeLimits.max,
    dateRangeLimits.min,
    editableRangeDateValue,
    dateRangeMarks,
    handleRangeYearSlider,
    handleOnChangeEndDateInput,
    handleOnChangeStartDateInput,
    handleOnCloseRangeDateInput,
    handleOnSubmitRangeDateInput,
    tempDates.endDate,
    tempDates.startDate,
    finalEndDate,
    finalStartDate,
    toggleEditableRangeDateValue
  ])

  const dateSlider = useMemo(() => {
    return (
      <div className={classes.slider}>
        <div className={classes.sliderTitle}>
          <Select
            options={DATE_TYPES}
            onChange={handleOnSelectDateType}
            selectedValue={rate.rateType}
          />
        </div>
        {isSigleSlider ? dateSingleSlider : dateRangeSlider}
      </div>
    )
  }, [classes.slider, classes.sliderTitle, handleOnSelectDateType, rate.rateType, isSigleSlider, dateSingleSlider, dateRangeSlider])

  return (
    <div className={classes.container}>
      <IconButton
        className={classes.closeButton}
        aria-label='close sections'
        onClick={() => handleCloseSection(goal)}
        color='inherit'
      >
        <Icon name={ICON_NAMES.close} customSize='1.2rem' />
      </IconButton>
      <div className={classes.header}>
        <div>
          <div className={classes.goalTitle}>
            <Text text={tier?.title || ''} customFontSize='24px' />
            <Text text='-' customFontSize='24px' className={classes.separator} />
            {editableGoalName ? (
              <>
                <InputBase
                  value={goalNewName}
                  onChange={handleOnChangeGoalName}
                  inputProps={{
                    style: { fontSize: '24px', fontWeight: 400, paddingBottom: 0 }
                  }}
                />
                <IconButton
                  classes={{ root: classes.iconButton }}
                  onClick={handleOnSubmitGoalName}
                  color='inherit'
                >
                  <Icon name={ICON_NAMES.check} customSize='1.2rem' />
                </IconButton>
                <IconButton
                  onClick={handleOnCloseGoalName}
                  color='inherit'
                >
                  <Icon name={ICON_NAMES.close} customSize='1.2rem' />
                </IconButton>
              </>
            ) : (
              <div onClick={handleOnClickGoalName}>
                <Text text={goalName} customFontSize='24px' />
              </div>
            )}
          </div>
          <Text text='87% of Total Outflows' customFontSize='14px' />
        </div>
        <RoundedButton disabled variant={BUTTON_VARIANT.text} size={BUTTON_SIZES.small}> Delete Goal </RoundedButton>
      </div>
      <div className={classes.content}>
        {amountSlider}
        {dateSlider}
      </div>
    </div>
  )
}

PlanGoalEdit.propTypes = {
  goal: PropTypes.shape({
    goalName: PropTypes.string,
    rate: PropTypes.shape({
      rateValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      rateType: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    }),
    startDate: PropTypes.string,
    status: PropTypes.string
  }),
  tier: PropTypes.shape({
    tierId: PropTypes.string,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    secondSubtitle: PropTypes.string,
    lineColor: PropTypes.string
  }),
  goalValueHandler: PropTypes.func,
  goalDateHandler: PropTypes.func,
  handleCloseSection: PropTypes.func,
  handleEditRateType: PropTypes.func,
  handleEditGoalName: PropTypes.func
}

PlanGoalEdit.propTypes = {
  goal: {
    goalName: undefined,
    rate: {
      rateValue: undefined,
      rateType: undefined
    },
    startDate: undefined,
    status: undefined
  },
  goalValueHandler: undefined,
  goalDateHandler: undefined,
  handleCloseSection: noop,
  handleEditRateType: noop,
  handleEditGoalName: noop
}

export default PlanGoalEdit
