import React, { forwardRef, useCallback, useImperativeHandle, useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import Dialog from '../../../molecules/Dialog/Dialog'
import RoundedButton from '../../../atoms/RoundedButton'
import { useClientDetails } from './ClientDetailsFormContext'

const useStyles = makeStyles((theme) => ({
  button: {
    borderRadius: '30px',
    fontWeight: 800,
    textTransform: 'capitalize',
    padding: '14px 16px',
    width: '280px',
    marginRight: '10px',
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none'
    }
  },
  cancelBtn: {
    backgroundColor: theme.palette.lightCoral,
    color: theme.palette.error.main,
    '&:hover': {
      backgroundColor: '#f3b3be'
    }
  },
  confirmBtn: {
    backgroundColor: theme.palette.gray.light,
    color: theme.palette.lightBlack,
    '&:hover': {
      backgroundColor: theme.palette.gray.dark
    }
  }
}))

const DeleteDialog = forwardRef(function DeleteDialog ({ onConfirm }, ref) {
  const classes = useStyles()
  const { client } = useClientDetails()
  const [open, setOpen] = useState(false)
  const handleConfirm = useCallback(async () => {
    await onConfirm()
    setOpen(false)
  }, [setOpen, onConfirm])

  useImperativeHandle(ref, () => {
    return {
      open: () => setOpen(true),
      close: () => setOpen(false)
    }
  }, [setOpen])

  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <Dialog.Header>
        FULL CLIENT DELETE - Are you sure you want to delete {client?.longName}?
      </Dialog.Header>
      <Dialog.Actions>
        <RoundedButton onClick={() => setOpen(false)} className={clsx(classes.button, classes.cancelBtn)}>
          Cancel
        </RoundedButton>
        <RoundedButton onClick={handleConfirm} className={clsx(classes.button, classes.confirmBtn)}>
          Delete
        </RoundedButton>
      </Dialog.Actions>
    </Dialog>
  )
})

DeleteDialog.propTypes = {
  onConfirm: PropTypes.func
}

export default DeleteDialog
