import React, { useMemo } from 'react'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { capitalize } from 'lodash'
import { useFormattingContext } from '../../../../../../organisms/FormattingProvider/FormattingContext'
import { DATE_FORMAT_US_LONG } from '../../../../../../../constants'
import ReportRecentActions from '../../ReportRecentView/ReportRecentTable/cells/ReportActions'
import SystemReportActions from './cells/ReportActions'

dayjs.extend(utc)

const templates = {
  nonUtcDate: ({ cell }) => {
    if (!cell.value) return '--'

    return dayjs(cell.value).format(`${DATE_FORMAT_US_LONG} hh:mm`)
  },
  date: ({ value }) => {
    return dayjs.utc(value).format(DATE_FORMAT_US_LONG)
  },
  uppercase: ({ value }) => {
    return capitalize(value)
  },
  reportActions: ({ row }) => {
    const { original: { bobiOutputId, name, status } } = row
    const downloadButtonDisabled = status === 'pending'
    return (
      <ReportRecentActions
        fileName={name}
        bobiOutputId={bobiOutputId}
        downloadButtonDisabled={downloadButtonDisabled}
      />
    )
  },
  name: ({ row }) => {
    const { original: { name, extra } } = row
    return extra?.savedRequestName || name
  }
}

export const defaultColumnConfig = {
  columns: [
    { Header: 'Name', accessor: 'name', id: 'name' },
    { Header: 'Created Date', accessor: 'createdAt', Cell: templates.date, id: 'createdDate' },
    { Header: 'Run By', accessor: 'runBy', id: 'runBy' },
    { Header: 'Actions', Cell: SystemReportActions, id: 'actions' }
  ],
  defaultSort: [{ id: '', desc: false }]
}

export const defaultRecentOutputsColumnConfig = {
  columns: [
    { Header: 'Run Date', accessor: 'runDate', id: 'runDate', Cell: templates.nonUtcDate },
    { Header: 'Name', accessor: 'name', id: 'name', Cell: templates.name },
    { Header: 'As of Date', accessor: 'asOfDate', id: 'asOfDate', Cell: templates.date },
    { Header: 'Run By', accessor: 'runBy', id: 'runBy' },
    { Header: 'Status', accessor: 'status', id: 'status', Cell: templates.uppercase },
    { Header: 'Actions', Cell: templates.reportActions, id: 'actions' }
  ],
  defaultSort: [{ id: 'runDate', desc: true }]
}

export const useColumns = ({ columnsConfig = defaultColumnConfig }) => {
  const { formatter } = useFormattingContext()

  return useMemo(() => {
    const { columns, defaultSort } = columnsConfig
    const templatedColumns = columns.map((column) => {
      if (column.format) {
        return {
          ...column,
          Cell: ({ value }) => formatter(value, column.format)
        }
      }
      return column
    })

    return {
      columns: templatedColumns,
      defaultSort
    }
  }, [columnsConfig, formatter])
}
