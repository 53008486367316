import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import dayjs from 'dayjs'
import LabeledValue from '../components/LabeledValue'

// const TIME_FORMAT = 'M/D/YYYY hh:mm:ss.SSS A z'
const useStyles = makeStyles((theme) => ({
  item: {
    display: 'block',
    backgroundColor: ({ status }) => {
      switch (status) {
        case 'processing':
          return theme.palette.gray.main
        case 'finished':
          return theme.palette.green.light
        case 'error':
          return theme.palette.red.light
        default:
          return theme.palette.white
      }
    },
    maxWidth: '30rem',
    border: `1px solid ${theme.palette.darkGrey}`,
    marginBottom: '.5rem',
    padding: '0 1rem .5rem',
    '& .__title': {
    }
  }
}))

const BatchListItem = ({ batch }) => {
  const classes = useStyles(batch)
  const to = useMemo(() => {
    return `/data-home/jobs/${batch.ingestionJobId}/batch/${batch.ingestionBatchId}`
  }, [batch.ingestionJobId, batch.ingestionBatchId])

  return (
    <Link to={to} className={classes.item}>
      <div>
        <h3 className='__title'>[{batch.ingestionBatchId}] {batch.name}</h3>
        <div className='__description'>
          <LabeledValue label='Status'>{batch.status}</LabeledValue>
          <LabeledValue label='Objectives'>{batch.objectiveCount}</LabeledValue>
          <LabeledValue label='Created'>{dayjs(batch.createdDate).fromNow()}</LabeledValue>
          {batch.finishDate && (<LabeledValue label='Finished'>{dayjs(batch.finishDate).fromNow()}</LabeledValue>)}
        </div>
      </div>
    </Link>
  )
}

BatchListItem.propTypes = {
  batch: PropTypes.shape({
    ingestionBatchId: PropTypes.number,
    ingestionJobId: PropTypes.number,
    name: PropTypes.string,
    status: PropTypes.string,
    createdDate: PropTypes.string,
    finishDate: PropTypes.string,
    objectiveCount: PropTypes.number,
    finishedCount: PropTypes.number,
    notStartedCount: PropTypes.number,
    errorCount: PropTypes.number
  })
}

export default BatchListItem
