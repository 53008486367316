import { useMutation, useQuery } from '@tanstack/react-query'
import { postNamedCommand, postNamedQuery } from '../service'
import { useAppContext } from '../redux/slices/appContext'

export const useCommentThreadDetails = (threadKey, levelTypeId, levelId, clientId) => {
  const { userId } = useAppContext()
  return useQuery({
    enabled: threadKey !== 'new' && (!!threadKey || (!!levelTypeId && !!levelId)),
    queryKey: ['get-comment-thread-details', userId, threadKey, levelTypeId, levelId, clientId],
    queryFn: async () => {
      if (threadKey) {
        const { data } = await postNamedQuery('comments', 'get-comment-thread-by-key', {
          threadKey
        })

        return data
      } else if (levelTypeId && levelId) {
        const { data } = await postNamedQuery('comments', 'get-comment-thread-by-assignment', {
          clientId,
          levelTypeId,
          levelId
        })

        return data
      }
    }
  })
}

export const useCreateCommentThread = () => {
  return useMutation({
    mutationFn: async (command) => {
      const { data } = await postNamedCommand('comments', 'create-comment-thread', command)

      return data
    }
  })
}

export const useAddComment = () => {
  return useMutation({
    mutationFn: async (command) => {
      const { data } = await postNamedCommand('comments', 'add-comment', command)

      return data
    }
  })
}

export const useEditComment = () => {
  return useMutation({
    mutationFn: async (command) => {
      const { data } = await postNamedCommand('comments', 'edit-comment', command)

      return data
    }
  })
}

export const useRemoveComment = () => {
  return useMutation({
    mutationFn: async (command) => {
      const { data } = await postNamedCommand('comments', 'remove-comment', command)

      return data
    }
  })
}
