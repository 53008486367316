import React, { useCallback, useImperativeHandle, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import RoundedModal from '../../../../../molecules/RoundedModal'
import ViewUserActivity from './form'

const ViewUserActivityDialog = React.forwardRef(function ViewUserActivityDialog ({ user }, ref) {
  const [dialogState, setDialogState] = useState({ open: false, processing: false })
  const close = useCallback(() => {
    setDialogState({ open: false, processing: false })
  }, [setDialogState])

  const title = useMemo(() => {
    return `${dialogState.ssoProvider?.name || ''} Activity for ${user.firstName} ${user.lastName}`
  }, [user, dialogState])

  useImperativeHandle(ref, () => ({
    open: (ssoProvider) => setDialogState({ open: true, processing: false, ssoProvider })
  }), [setDialogState])

  return (
    <RoundedModal
      title={title}
      open={dialogState.open}
      onClose={close}
      position='top'
      size='large'
      disablePortal={false}
      bodyPadding='0'
    >
      <div>
        {dialogState.open ? (<ViewUserActivity userId={user.userId} sso={dialogState.ssoProvider} onCancel={close} />) : null}
      </div>
    </RoundedModal>
  )
})

ViewUserActivityDialog.propTypes = {
  user: PropTypes.shape({
    userId: PropTypes.number,
    firstName: PropTypes.string,
    lastName: PropTypes.string
  })
}

export default ViewUserActivityDialog
