import React, { useCallback, useMemo } from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import Text from '../../../../atoms/Text'
import Avatar from '../../../../atoms/Avatar'
import { CUSTODIANS, TEXT_VARIANTS } from '../../../../../constants'
import { ACCOUNT_FORM_NAMES } from '../helpers'
import { useAccountFormContext } from '../AccountFormProvider'

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    position: 'relative',
    marginBottom: '10px'
  },
  header: {
    flexDirection: 'column'
  },
  divider: {
    margin: '28px 0px'
  },
  custodianContainer: {
    display: 'flex',
    justifyContent: 'space-around'
  },
  datesContainer: {
    marginBottom: '30px'
  },
  title: {
    color: '#212945',
    lineHeight: '2.5625rem',
    fontSize: '30px'
  },
  subTitleContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '10px'
  },
  subTitle: {
    fontWeight: 350,
    lineHeight: '2rem'
  },
  chip: {
    backgroundColor: theme.palette.gray.main,
    padding: '2px 5px',
    borderRadius: '4px'
  },
  detailsContainer: {
    padding: '6px 10px',
    display: 'flex',
    flexDirection: 'column',
    gap: '10px'
    // marginBottom: '1.5rem'
  },
  nameContainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  detailsTitle: {
    color: '#141929',
    lineHeight: 'normal',
    fontSize: '0.875rem',
    fontWeight: 350
  },
  detailsSubTitle: {
    color: '#141929',
    fontSize: '1rem'
  },
  custodianContent: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  avatar: {
    marginRight: '0.5rem'
  }
}))

export const mapFormDefaultValues = (account) => {
  if (!account) return null

  const extraFields = {}

  if (account.dataSourceId === CUSTODIANS.BY_ALL_ACCOUNTS) {
    extraFields[ACCOUNT_FORM_NAMES.accountDisplayNumber] = account.displayNumber
  }

  return {
    ...extraFields,
    [ACCOUNT_FORM_NAMES.accountId]: account.accountId,
    [ACCOUNT_FORM_NAMES.accountLongName]: account.longName,
    [ACCOUNT_FORM_NAMES.accountDisplayName]: account.displayName || account.longName,
    [ACCOUNT_FORM_NAMES.accountLongNumber]: account.longNumber,
    [ACCOUNT_FORM_NAMES.closedDate]: account?.closeDate || account.startDate
  }
}

const AccountDetailsHeader = ({
  marketValue
}) => {
  const { account } = useAccountFormContext()
  const classes = useStyles()

  const custodian = useMemo(() => {
    const custodian = account?.custodian ? account.custodian[0] : {}
    return {
      name: custodian?.name || '',
      imageUrl: custodian?.imageUrl
    }
  }, [account])

  const renderDetailsBlock = useCallback(
    (title, subtitle) => {
      return (
        <div className={classes.detailsContainer}>
          <Text
            text={title}
            className={classes.detailsTitle}
          />
          <Text
            text={subtitle}
            className={classes.detailsSubTitle}
          />
        </div>
      )
    },
    [
      classes.detailsTitle,
      classes.detailsSubTitle,
      classes.detailsContainer
    ]
  )

  return (
    <div className={classes.container}>
      <Grid container>
        <Grid item xs={12} md={6} lg={8}>
          <div className={classes.nameContainer}>
            <Text
              text={account?.displayName || account?.longName}
              className={classes.title}
              variant={TEXT_VARIANTS.h1}
            />
            <div className={classes.subTitleContainer}>
              <Text
                text={account?.accountNumber}
                className={classes.subTitle}
              />
              {account?.managerId ? (
                <div>
                  <div className={classes.chip}>SMA</div>
                </div>
              ) : null}
            </div>
          </div>
        </Grid>
        <Grid item xs={6} md={3} lg={2}>
          <div className={classes.detailsContainer}>
            <Text
              text='Custodian'
              className={classes.detailsTitle}
            />
            <div className={classes.custodianContent}>
              <Avatar
                customSize='2rem'
                src={custodian.imageUrl}
                customClassName={classes.avatar}
                isPublicResource
                avatarLetters={custodian.name}
              />
              <Text
                text={custodian.name}
                className={classes.detailsSubTitle}
              />
            </div>
          </div>
        </Grid>
        <Grid item xs={6} md={3} lg={2}>
          {renderDetailsBlock('Market Value', marketValue)}
        </Grid>
      </Grid>
    </div>
  )
}

AccountDetailsHeader.propTypes = {
  marketValue: PropTypes.string
}

export default AccountDetailsHeader
