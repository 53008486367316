import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import isEmpty from 'lodash/isEmpty'
import { useAppContext } from '../../../redux/slices/appContext'
import { useCoreTableData } from '../../../api/coreData'
import { useRelativeDateRanges } from '../../molecules/RelativeDateSelect'
import EmptySection from '../../atoms/EmptySection'
import HoldingListItem from './HoldingListItem'

const useStyles = makeStyles((theme) => ({
  topHoldings: {
    marginTop: '2px',
    padding: 0,
    '& .__holding-list-item:not(:last-child)': {
      borderBottom: `1px solid ${theme.palette.gray.light}`
    }
  },
  header: {
    fontSize: '.875rem',
    padding: '6px 12px',
    lineHeight: '30px',
    boxShadow: '0px 10px 6px -6px rgba(24, 27, 53, 0.06)',
    backgroundColor: theme.palette.gray.light
  }
}))

function compareNumericString (rowA, rowB, desc = false) {
  let a = Number.parseFloat(rowA)
  let b = Number.parseFloat(rowB)
  if (Number.isNaN(a)) {
    a = desc ? Number.NEGATIVE_INFINITY : Number.POSITIVE_INFINITY
  }
  if (Number.isNaN(b)) {
    b = desc ? Number.NEGATIVE_INFINITY : Number.POSITIVE_INFINITY
  }
  if (a > b) return 1
  if (a < b) return -1
  return 0
}

const createAccessor = accessor => (item) => accessor.split('.').reduce((p, c) => p ? p[c] : p, item)

const useTopHoldings = ({
  top,
  defaultFilter,
  levelType,
  valueAccessor,
  changeAccessor,
  nameAccessor,
  alternateNameAccessor,
  relativeDateRanges
}) => {
  const { clientId, availableDates, loadingAvailableDates } = useAppContext()
  const { dateRanges } = useRelativeDateRanges(relativeDateRanges, availableDates)

  const query = useMemo(() => {
    if (loadingAvailableDates) return null
    return {
      levelFilters: {
        ...(defaultFilter || {}),
        levelTypes: ['client', levelType],
        clientIds: [clientId]
      },
      tableDateRanges: {
        balanceColumns: {
          beginning: {
            startDate: availableDates.min,
            endDate: availableDates.min
          },
          ending: {
            startDate: availableDates.mainDate,
            endDate: availableDates.mainDate
          }
        },
        performanceColumns: (dateRanges.length) ? dateRanges.reduce((a, x) => {
          a[x.key] = x.dateRange
          return a
        }, {}) : undefined
      }
    }
  }, [availableDates, clientId, levelType, loadingAvailableDates, defaultFilter, dateRanges])

  const { data, isLoading } = useCoreTableData(query, {
    enabled: !!query
  })

  const accessors = useMemo(() => {
    const value = createAccessor(valueAccessor)
    const change = createAccessor(changeAccessor)
    return {
      value,
      change: (item) => (+value(item)) - (+change(item)),
      name: createAccessor(nameAccessor),
      alternateName: createAccessor(alternateNameAccessor),
      sort: (a, b) => {
        return compareNumericString(value(b), value(a), false)
      }
    }
  }, [valueAccessor, nameAccessor, alternateNameAccessor, changeAccessor])

  const filteredData = useMemo(() => {
    if (isLoading || isEmpty(data)) return []
    const sorted = [...(data.filter(x => !!accessors.value(x)))].sort(accessors.sort)
    return sorted.slice(0, top || Number.POSITIVE_INFINITY).map(item => ({
      key: item.uniqueId,
      value: accessors.value(item),
      change: accessors.change(item),
      alternateName: accessors.alternateName(item)?.toString(),
      name: accessors.name(item)
    }))
  }, [top, isLoading, data, accessors])

  return {
    data: filteredData ?? [],
    isLoading
  }
}

const TopHoldings = ({
  title,
  top,
  defaultFilter,
  levelType,
  relativeDateRanges,
  nameAccessor,
  alternateNameAccessor,
  valueAccessor,
  changeAccessor,
  valueFormat,
  changeFormat,
  showChange
}) => {
  const classes = useStyles()
  const { data, isLoading } = useTopHoldings({
    top,
    defaultFilter,
    levelType,
    nameAccessor,
    alternateNameAccessor,
    valueAccessor,
    changeAccessor,
    relativeDateRanges
  })

  if (!isLoading && isEmpty(data)) {
    return <EmptySection title='No data available' emptyDescription />
  }

  return (
    <div className={classes.topHoldings}>
      <div className={classes.header}>
        <span>{title}</span>
      </div>
      <div className='__holding-list'>
        {data.map((holdingItem) => (
          <HoldingListItem
            className='__holding-list-item'
            key={holdingItem.key}
            valueFormat={valueFormat}
            changeFormat={changeFormat}
            showChange={showChange}
            {...holdingItem}
          />
        ))}
      </div>
    </div>
  )
}

TopHoldings.propTypes = {
  title: PropTypes.string,
  top: PropTypes.number,
  defaultFilter: PropTypes.object,
  levelType: PropTypes.string,
  relativeDateRanges: PropTypes.arrayOf(PropTypes.string),
  nameAccessor: PropTypes.string,
  alternateNameAccessor: PropTypes.string,
  valueAccessor: PropTypes.string,
  /** Accessor to the path to determine change (ending - beginning) */
  changeAccessor: PropTypes.string,
  valueFormat: PropTypes.string,
  changeFormat: PropTypes.string,
  showChange: PropTypes.bool
}

TopHoldings.defaultProps = {
  title: 'Top Holdings',
  top: 5,
  defaultFilter: {},
  levelType: 'asset',
  relativeDateRanges: ['QTD'],
  nameAccessor: 'ending.assetName',
  alternateNameAccessor: 'ending.Symbol',
  valueAccessor: 'ending.endingValue',
  changeAccessor: 'beginning.endingValue',
  valueFormat: 'human',
  changeFormat: '+0,0A',
  showChange: false
}

export default TopHoldings
