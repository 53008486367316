import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { Box, useTheme } from '@material-ui/core'
import isEmpty from 'lodash/isEmpty'
import numeral from 'numeral'
import last from 'lodash/last'
import { useFetchState } from '../../hooks'
import { useAppContext } from '../../redux/slices/appContext'
import { fetchBalanceInformation } from '../../service'
import { mergeBalanceInformation, numeralByCase } from '../../utils'
import NumberFormat from '../atoms/NumberFormat'
import Text from '../atoms/Text'
import { CALC_TYPES, DATE_TYPES, LEVEL_TYPES, TEXT_VARIANTS } from '../../constants'
import useDatePresetOptions from '../../hooks/useDatePresetOptions'
import AvailableDateSelect from './AvailableDateSelect'
import TitleWithSubtitle from './TitleWithSubtitle'

const PortfolioAllocationTotalBalanceSummaryDetails = ({
  flowDateSelectedOption: _flowDateSelectedOption,
  viewFilters,
  viewContext,
  fetchParams,
  customDateRanges
}) => {
  const theme = useTheme()
  const appContext = useAppContext()
  const { mainDate } = viewContext
  const [selectedPresetDateOption, setPresetDateOption] = useState(
    last(_flowDateSelectedOption.split('_'))
  )
  const { dateValue: flowDate } = useDatePresetOptions(selectedPresetDateOption)

  const getFlowBalanceInformation = useCallback(
    async (setSafeState) => {
      const assetClassTagIds = viewFilters?.ASSET_CLASSES
        ? [viewFilters?.ASSET_CLASSES?.id]
        : []

      const subclassTagIds = viewFilters?.ASSETS_SUBCLASS
        ? [viewFilters?.ASSETS_SUBCLASS?.id]
        : []

      const assetIds = viewFilters?.ASSETS ? [viewFilters?.ASSETS?.id] : []

      const levelTypes = [
        LEVEL_TYPES.CLIENT,
        viewFilters?.ASSET_CLASSES && LEVEL_TYPES.ASSET_CLASS_TAG,
        viewFilters?.ASSETS_SUBCLASS && LEVEL_TYPES.SUBCLASS_TAG,
        viewFilters?.ASSETS && LEVEL_TYPES.ASSETS
      ].filter(Boolean)

      const { data } = await fetchBalanceInformation({
        startDate: flowDate,
        endDate: mainDate,
        dateType: DATE_TYPES.all,
        clientIds: [appContext.clientId],
        assetClassTagIds,
        subclassTagIds,
        assetIds,
        levelTypes,
        calcType: CALC_TYPES.performance,
        ...(isEmpty(fetchParams) ? {} : fetchParams)
      })
      setSafeState({
        balanceInformation: mergeBalanceInformation(data)
      })
    },
    [appContext.clientId, viewFilters, fetchParams, mainDate, flowDate]
  )

  const { balanceInformation } = useFetchState(getFlowBalanceInformation)
  return (
    <Box display='flex' flexDirection='column' alignItems='end' px={4}>
      <AvailableDateSelect
        extraStyles={{
          fontFamily: 'Gotham-Book',
          color: theme.palette.doveGray
        }}
        selectedValue={selectedPresetDateOption}
        onChange={(_, __, valueKey) =>
          setPresetDateOption(valueKey)}
        addCustomDateOpt={false}
        options={customDateRanges}
      />
      <Box display='flex' flexDirection='row' alignItems='center'>
        <TitleWithSubtitle
          alignment='flex-end'
          titleElement={
            <Text
              color={theme.palette.black}
              variant={TEXT_VARIANTS.body2}
              customFontFamily='Gotham-Book'
              customFontSize='1.5rem'
              text={
                <NumberFormat
                  title={numeralByCase(balanceInformation?.balanceChange || 0)}
                  format='$0.0a'
                  number={balanceInformation?.balanceChange || 0}
                  prefix={balanceInformation?.balanceChangePositive ? '+' : ''}
                />
              }
            />
          }
          subtitleElement={
            <Text
              color={theme.palette.doveGray}
              text='Net gain'
              variant={TEXT_VARIANTS.body1}
              customFontFamily='Gotham-Book'
              customFontSize='0.75rem'
            />
          }
        />
        <Box ml='1rem'>
          <TitleWithSubtitle
            alignment='flex-end'
            titleElement={
              <Text
                color={theme.palette.black}
                variant={TEXT_VARIANTS.body2}
                customFontFamily='Gotham-Book'
                customFontSize='1.5rem'
                text={
                  <NumberFormat
                    title={numeral(
                      balanceInformation?.balanceChangePercentage || 0
                    ).format('0.0000%')}
                    format='0.0%'
                    number={balanceInformation?.balanceChangePercentage || 0}
                    prefix={
                      balanceInformation?.balanceChangePositive ? '+' : ''
                    }
                  />
                }
              />
            }
            subtitleElement={
              <Text
                color={theme.palette.doveGray}
                text='My Return'
                variant={TEXT_VARIANTS.body1}
                customFontFamily='Gotham-Book'
                customFontSize='0.75rem'
              />
            }
          />
        </Box>
      </Box>
    </Box>
  )
}

PortfolioAllocationTotalBalanceSummaryDetails.propTypes = {
  viewFilters: PropTypes.object,
  viewContext: PropTypes.object,
  flowDateSelectedOption: PropTypes.string,
  fetchParams: PropTypes.object,
  customDateRanges: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    label: PropTypes.string
  }))
}

PortfolioAllocationTotalBalanceSummaryDetails.defaultProps = {
  customDateRanges: undefined
}

export default PortfolioAllocationTotalBalanceSummaryDetails
