import { isArray } from 'lodash'

export const getSelectedGroupingConfig = (
  selectedGrouping,
  fallbackGroupings = [],
  fallbackColumnConfig = {}
) => {
  // groupings come directly, not nested or in another property
  if (isArray(selectedGrouping?.value)) {
    return {
      groupings: selectedGrouping?.value ?? fallbackGroupings,
      columnConfig: fallbackColumnConfig
    }
  }
  const {
    groupings,
    columnConfig
  } = selectedGrouping?.value || {}

  return {
    groupings: groupings ?? fallbackGroupings,
    columnConfig: columnConfig ?? fallbackColumnConfig
  }
}
