import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useWidgetContext } from '../../molecules/WidgetWrapper'
import PresentationTable from './PerformancePresentationTable'
import { useGroupingContext } from './PerformanceGroupings/GroupingContext'
import usePerformanceColumns from './usePerformanceColumns'
import usePerformanceTreeData from './usePerformanceTreeData'
import { getSelectedGroupingConfig } from './helpers'

const PerformanceTableV3 = ({
  /** stuff to add to the levelFilters of the perf request by default */
  defaultFilter: _defaultFilter,

  /** A list of levels of which to add benchmarks */
  benchmarks,

  /** Accessor to determine how to allocate allocation */
  allocationAccessor,

  requestBase,

  /** Groupings for table, if not using PerformanceGroupings Provider */
  groupings: _groupings,

  /** Column configuration for table */
  columnConfig: _columnConfig,

  /** Title for table, if wanted inside the table header */
  title,

  /** The amount of groupings to request on initial request */
  initialDepth,

  /** The amount of groupings to expand on initial request */
  initialExpandDepth,

  /** Is the table sortable? */
  sortable,

  /** Some sort of sticky header contraption */
  autoSticky,

  /** Scoped to client or advisor? */
  scope,

  /** Does the grouping represent a single level? Couldn't we figure that out tho? */
  singleLevel,

  /** Show the expand icon on the table row - some people do not like it */
  showExpandIcon,

  /** Show the level color in the level */
  showColorIcon,

  /** Filter rows */
  rowFilters,

  /** Styling variant */
  variant,

  /** Asset ratings (like M or SP) */
  ratings,

  /** Styles applied to a particular depth */
  depthStyles,

  /** Nitpicky client things */
  nitpicks
}) => {
  const {
    setPerformanceTableProps,
    groupsExpanded,
    selectedGrouping,
    groupingDateRanges,
    groupingDateRangeOptions
  } = useGroupingContext()

  // TODO: Consider combining these into something like `const _filter = useWidgetSubscription(_defaultFilter, 'levelFilters')
  const { subscribedFilters } = useWidgetContext()
  const defaultFilter = useMemo(() => {
    return Object.assign({}, _defaultFilter, subscribedFilters?.levelFilters)
  }, [_defaultFilter, subscribedFilters?.levelFilters])

  const { groupings, columnConfig } = useMemo(() => {
    return getSelectedGroupingConfig(
      selectedGrouping,
      _groupings,
      _columnConfig
    )
  }, [
    selectedGrouping,
    _groupings,
    _columnConfig
    // groupingDateRanges
  ])

  const {
    data,
    isLoading,
    onLevelExpand,
    normalizedDateRanges
  } = usePerformanceTreeData({
    groupings,
    benchmarks,
    defaultFilter,
    initialDepth,
    initialExpandDepth,
    allocationAccessor,
    expanded: groupsExpanded,
    dateRangeOptions: groupingDateRangeOptions,
    scope,
    rowFilters,
    ratings,
    dateRanges: groupingDateRanges,
    requestBase
  })

  const { columns, defaultSort } = usePerformanceColumns({
    columnConfig,
    title,
    // groupingDateRanges,
    // setPerformanceDateRanges,
    // performanceDateRangeOptions,
    normalizedDateRanges
    // groupingColumnConfigurations
  })

  const tableRef = useCallback(
    (node) => {
      if (node !== null && setPerformanceTableProps) {
        setPerformanceTableProps((prevState) => ({ ...prevState, ...node }))
      }
    },
    [setPerformanceTableProps]
  )

  return (
    <PresentationTable.Wrapper>
      <PresentationTable
        styleVariant={variant}
        ref={tableRef}
        data={data}
        columns={columns}
        loading={isLoading}
        defaultSort={defaultSort}
        expandable
        onRowClick={onLevelExpand}
        sortable={sortable}
        autoSticky={autoSticky}
        singleLevel={singleLevel}
        showExpandIcon={showExpandIcon}
        showColorIcon={showColorIcon}
        depthStyles={depthStyles}
        nitpicks={nitpicks}
      />
    </PresentationTable.Wrapper>
  )
}

PerformanceTableV3.propTypes = {
  defaultFilter: PropTypes.object,
  groupings: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      levelType: PropTypes.string,
      requestBase: PropTypes.object
    })
  ])),
  benchmarks: PropTypes.arrayOf(PropTypes.string),
  allocationAccessor: PropTypes.string,
  requestBase: PropTypes.object,
  columnConfig: PropTypes.object,
  title: PropTypes.string,
  initialDepth: PropTypes.number,
  initialExpandDepth: PropTypes.number,
  sortable: PropTypes.bool,
  autoSticky: PropTypes.bool,
  scope: PropTypes.string,
  singleLevel: PropTypes.bool,
  showExpandIcon: PropTypes.bool,
  showColorIcon: PropTypes.bool,
  depthStyles: PropTypes.object,
  rowFilters: PropTypes.any,
  variant: PropTypes.oneOf(['default', 'flat', 'legacy', 'glass', 'presentationTableRC']),
  ratings: PropTypes.arrayOf(PropTypes.string),
  nitpicks: PropTypes.shape({
    noDefaultTooltip: PropTypes.bool,
    glassFactor: PropTypes.number
  }),
  select: PropTypes.shape({
    select: PropTypes.object,
    define: PropTypes.object
  })
}

PerformanceTableV3.defaultProps = {
  title: 'Performance',
  benchmarks: [],
  balanceDateRanges: ['balance'],
  beginningValueDateRanges: [],
  rglDateRanges: [],
  displayOptions: {},
  sortable: false,
  includes: [],
  autoSticky: true,
  scope: 'client',
  showExpandIcon: true,
  showColorIcon: false,
  rowFilters: undefined,
  variant: 'default',
  nitpicks: {}
}

export default PerformanceTableV3
