import React from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@material-ui/core'
import get from 'lodash/get'
import { isArray, first, isObject } from 'lodash'
import { useContextualClientContext } from '../context/ContextualClientContext'
import InfoSection from './InfoSection'
import ClientIntegrationMapperSkeleton from './ClientIntegrationMapperSkeleton'

const mapFieldValue = (field, dataSource) => {
  let value = get(dataSource, field.source, '')
  if (isArray(value) && isObject(first(value))) {
    value = value.map((valueItem) =>
      get(valueItem, field.sourceItem, '')
    )
  }
  return value
}

const ClientIntegrationMapper = ({ fieldsMappings, useProfileJSON }) => {
  const {
    client: { profileJSON = {} } = {},
    clientIntegrationData,
    isLoadingClientIntegrationData
  } = useContextualClientContext()

  if (isLoadingClientIntegrationData) {
    return <ClientIntegrationMapperSkeleton />
  }

  return (
    <Grid container spacing={3}>
      {(fieldsMappings?.[clientIntegrationData?.key] || fieldsMappings).map(
        (field, index) => {
          const value = mapFieldValue(field, useProfileJSON ? profileJSON || {} : clientIntegrationData)
          return (
            <Grid key={index} item xs={12} {...field.grid}>
              <InfoSection
                title={field.label}
                placeholder={field.placeholder}
                value={value}
              />
            </Grid>
          )
        }
      )}
    </Grid>
  )
}

ClientIntegrationMapper.propTypes = {
  useProfileJSON: PropTypes.bool,
  isLoading: PropTypes.bool,
  fieldsMappings: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
}

ClientIntegrationMapper.defaultProps = {
  useProfileJSON: false,
  isLoading: false,
  fieldsMappings: [
    {
      label: 'Household Name',
      source: '',
      placeholder: 'household'
    },
    {
      label: 'Emails',
      source: '',
      placeholder: 'email@address.com'
    },
    {
      label: 'Home Phone',
      source: '',
      placeholder: '(555) 555-5555',
      grid: { md: 6 }
    },
    {
      label: 'Mobile',
      source: '',
      placeholder: '(555) 555-5555',
      grid: { md: 6 }
    },
    {
      label: 'Birthday',
      source: '',
      placeholder: '01-01-1900',
      grid: { md: 6 }
    },
    {
      label: 'Anniversary',
      source: '',
      placeholder: '--',
      grid: { md: 6 }
    }
  ]
}

export default ClientIntegrationMapper
