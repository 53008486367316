import dayjs from 'dayjs'
import numeral from 'numeral'
import { isEmpty, truncate } from 'lodash'
import { Link } from 'react-router-dom'
import utc from 'dayjs/plugin/utc'
import { assignmentStatusOptions, TAX_STATUS_TYPES } from '../../../../../constants'
import { getFilterColumnId, mapGroupTypeFilterId } from './useGroupTypeColumns'

dayjs.extend(utc)

export const ADMIN_ACCOUNTS_PATH = '/admin/accounts'

const MAX_CHARACTERS = 30

const CHARACTER_SEPARATOR = ' '

const cellTemplates = {
  drillDownLink: (accessor, targetAccessor) => {
    return ({ row }) => {
      return (
        // eslint-disable-next-line react/react-in-jsx-scope
        <Link
          onClick={(event) => event.stopPropagation()}
          to={`${ADMIN_ACCOUNTS_PATH}/${targetAccessor(row.original)}`}
        >
          {accessor(row.original)}
        </Link>
      )
    }
  },
  date: ({ value }) => {
    return value ? dayjs.utc(value).format('M/D/YYYY') : ''
  },
  joinByCommas: (nameKey, defaultValue) => ({ value }) => {
    return value?.map((item) => item[nameKey]).join(', ') || defaultValue || ''
  },
  discretionary: ({ value }) => {
    return value ? 'Discretionary' : 'Non-Discretionary'
  },
  performanceStatus: ({ value }) => {
    return value ? 'Performing' : 'Non-Performing'
  },
  managedStatus: ({ value }) => {
    return value ? 'Managed' : 'Unmanaged'
  },
  taxStatus: ({ value }) => {
    return TAX_STATUS_TYPES?.[value] ?? ''
  },
  flatDollar: ({ value }) => {
    return numeral(value).format('0,000')
  }
}

const accountNumberAccessor = (row) => row.accountNumber || row.displayNumber || row.longNumber
const linkAccessor = (row) => row.accountId

export const COLUMN_IDS = {
  FEE_SCHEDULES: getFilterColumnId('feeSchedules'),
  BENCHMARKS: getFilterColumnId('benchmark'),
  TAX_STATUS: getFilterColumnId('taxStatus'),
  CLIENTS: getFilterColumnId('clients'),
  CLOSE_DATE: getFilterColumnId('closeDate'),
  TARGET_MODELS: getFilterColumnId('targetModels'),
  CUSTODIANS: getFilterColumnId('custodians')
}

export const taxStatusOptions = Object.entries(TAX_STATUS_TYPES).map(
  ([key, value]) => ({ label: value, value: key })
)

export const defaultColumnConfig = {
  columns: [
    { id: 'accountId', Header: 'Account ID', accessor: 'accountId' },
    { id: 'accountNumber', Header: 'Account Number', accessor: accountNumberAccessor, Cell: cellTemplates.drillDownLink(accountNumberAccessor, linkAccessor) },
    { id: 'longName', Header: 'Account Name', accessor: (row) => truncate(row.longName, { length: MAX_CHARACTERS, separator: CHARACTER_SEPARATOR }) },
    { id: 'displayName', Header: 'Account Display Name', accessor: (row) => truncate(row.displayName, { length: MAX_CHARACTERS, separator: CHARACTER_SEPARATOR }) },
    { id: COLUMN_IDS.CUSTODIANS, Header: 'Custodian Name', accessor: 'custodian', Cell: cellTemplates.joinByCommas('name'), include: 'custodian' },
    { id: COLUMN_IDS.CLIENTS, Header: 'Client', accessor: 'assignedClients', Cell: cellTemplates.joinByCommas('shortName'), include: 'assignedClients' },
    { id: 'startDate', Header: 'Start Date', accessor: 'startDate', Cell: cellTemplates.date },
    { id: 'asOfDate', Header: 'As Of Date', accessor: 'asOfDate', Cell: cellTemplates.date },
    { id: COLUMN_IDS.CLOSE_DATE, Header: 'Close Date', accessor: 'closeDate', Cell: cellTemplates.date },
    { id: 'repCode', Header: 'Rep Code', accessor: 'masterCode' },
    {
      id: COLUMN_IDS.BENCHMARKS,
      hidden: true,
      Header: 'Benchmark',
      accessor: 'benchmarks',
      include: 'benchmarks',
      // used to show on export when value is empty
      defaultValue: 'Unassigned',
      Cell: cellTemplates.joinByCommas('shortName', 'Unassigned')
    },
    {
      id: COLUMN_IDS.FEE_SCHEDULES,
      hidden: true,
      Header: 'Fee Schedule',
      accessor: 'feeSchedules',
      include: 'feeSchedules',
      // used to show on export when value is empty
      defaultValue: 'Unassigned',
      Cell: cellTemplates.joinByCommas('shortName', 'Unassigned')
    },
    { id: 'perfStartDate', hidden: true, Header: 'Performance Start Date', accessor: 'performanceStartDate', Cell: cellTemplates.date },
    { id: COLUMN_IDS.TAX_STATUS, hidden: true, Header: 'Tax Status', accessor: 'taxStatusId', Cell: cellTemplates.taxStatus, include: 'taxStatus' },
    { id: 'accountRegistration', hidden: true, Header: 'Account Registration', accessor: 'accountRegistration' },
    { id: 'dailyStartDate', hidden: true, Header: 'Daily Start Date', accessor: 'dailyStartDate', Cell: cellTemplates.date },
    { id: 'billingStartDate', hidden: true, Header: 'Billing Start Date', accessor: 'billingStartDate', Cell: cellTemplates.date },
    { id: 'displayNumber', hidden: true, Header: 'Display Number', accessor: 'displayNumber' },
    { id: 'endingValue', hidden: true, Header: 'Value', accessor: 'asOfDayValues.endingValue', include: 'asOfDayValues', Cell: cellTemplates.flatDollar, alignRight: true, disableSortBy: true },
    { id: COLUMN_IDS.TARGET_MODELS, hidden: true, Header: 'Model', accessor: 'targetModels', Cell: cellTemplates.joinByCommas('targetModelName'), include: 'targetModels' }
  ],
  defaultSort: [
    { id: 'longName', desc: false }
  ]
}

export const ACCOUNTS_MEMBER_LEVEL_ID = 1

export const mapGroupTypeVisibleColIds = (searchParams, groupTypes) => {
  let filterColIds = []
  if (!isEmpty(searchParams?.tagFilters)) {
    filterColIds = groupTypes.reduce(
      (acc, { groups, value: groupTypeValue }) => {
        const columnId = mapGroupTypeFilterId(groupTypeValue)

        const shouldShowFilter = !!groups.find(({ value }) =>
          searchParams?.tagFilters.includes(value)
        )
        if (!shouldShowFilter) return acc
        return [...acc, columnId]
      },
      []
    )
  }
  for (const [colKey, filterKey] of Object.entries(COLUMN_FILTER_MAPPING)) {
    if (!isEmpty(searchParams[filterKey])) {
      filterColIds.push(colKey)
    }
  }
  return filterColIds
}

export const COLUMN_FILTER_MAPPING = {
  [COLUMN_IDS.BENCHMARKS]: 'benchmarksFilters',
  [COLUMN_IDS.FEE_SCHEDULES]: 'feeSchedulesFilters',
  [COLUMN_IDS.TAX_STATUS]: 'taxStatusFilters',
  [COLUMN_IDS.CLIENTS]: 'hasClientsAssignedFilters',
  [COLUMN_IDS.CLOSE_DATE]: 'closeDateFilters',
  [COLUMN_IDS.TARGET_MODELS]: 'targetModelsFilters',
  [COLUMN_IDS.CUSTODIANS]: 'custodianFilters'
}

export const FILTER_OPTIONS_MAPPING = {
  benchmarksFilters: assignmentStatusOptions,
  feeSchedulesFilters: assignmentStatusOptions,
  taxStatusFilters: taxStatusOptions,
  hasClientsAssignedFilters: assignmentStatusOptions,
  closeDateFilters: assignmentStatusOptions,
  targetModelsFilters: [],
  custodianFilters: []
}

export const mapOptionsFromSearchParams = (
  searchParams,
  paramKey,
  prevFilters,
  options
) => {
  if (!isEmpty(prevFilters?.[paramKey])) {
    return prevFilters?.[paramKey]
  }
  if (isEmpty(options) || !searchParams?.[paramKey]) {
    return []
  }
  return options.filter(({ value }) =>
    searchParams?.[paramKey].includes(value)
  )
}
