import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'
import Icon from '../../../atoms/Icon'
import { useAppContext } from '../../../../redux/slices/appContext'

const useStyles = makeStyles((theme) => ({
  marketValueText: {
    backgroundColor: theme.palette.primary.main,
    width: '36px',
    height: '36px',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '100px',
    marginTop: '5px'
  },
  icon: {
    fill: theme.palette.white
  }
}))

const ClientConversation = ({ text }) => {
  const classes = useStyles()
  const { clientId } = useAppContext()

  return (
    <Link to={`/conversations/c/${clientId}`} className={classes.marketValueText} title={text}>
      <Icon name='chat' additionalClasses={classes.icon} />
    </Link>
  )
}

ClientConversation.propTypes = {
  text: PropTypes.string
}

ClientConversation.defaultProps = {
  text: 'Start Conversation'
}

export default ClientConversation
