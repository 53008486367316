import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { useDownloadExport } from './useDownloadExport'
import { FirmReportExportContext } from './FirmReportExportContext'
import FirmReportExportDialog from './FirmReportExportDialog'

const DEFAULT_STATE = { open: false, report: null }

export function FirmReportExportProvider ({ children, currentFilter, defaultName }) {
  const [exportState, setExportState] = useState(DEFAULT_STATE)
  const onCancel = useCallback(() => setExportState(DEFAULT_STATE), [setExportState])
  const onExport = useCallback((report) => setExportState({ open: true, report }), [setExportState])
  const startExport = useDownloadExport(setExportState, DEFAULT_STATE)
  const onConfirm = useCallback((metadata, options) => {
    return startExport({
      ...metadata,
      parameters: options?.includeFilter ? currentFilter : undefined
    })
  }, [startExport, currentFilter])

  const providerValue = {
    onExport,
    onCancel,
    onConfirm,
    exportState,
    currentFilter,
    defaultName
  }

  return (
    <FirmReportExportContext.Provider value={providerValue}>
      {children}
      <FirmReportExportDialog />
    </FirmReportExportContext.Provider>
  )
}

FirmReportExportProvider.propTypes = {
  children: PropTypes.node,
  currentFilter: PropTypes.object,
  defaultName: PropTypes.string
}

export default FirmReportExportProvider
