import { makeStyles } from '@material-ui/core'
import isEmpty from 'lodash/isEmpty'
import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { useDates } from '../../../../hooks/useDates'
import CardListSkeleton from './CardListSkeleton'

const useStyles = makeStyles(() => ({
  container: {
    flex: 1
  }
}))

const CardListContainer = ({ children }) => {
  const classes = useStyles()
  const [containerBoundingRect, setContainerBoundingRect] = useState(null)
  const { loadingAvailableDates } = useDates()

  const onClientCardsContainerRefChange = useCallback((node) => {
    if (!isEmpty(node)) {
      const styles = window.getComputedStyle(node)
      const padding =
        parseFloat(styles.paddingLeft) + parseFloat(styles.paddingRight)
      const rect = node.getBoundingClientRect()

      setContainerBoundingRect({
        height: rect.height,
        width: rect.width - padding,
        containerRef: node
      })
    }
  }, [])

  return (
    <div className={classes.container} ref={onClientCardsContainerRefChange}>
      {!containerBoundingRect || loadingAvailableDates ? (
        <CardListSkeleton />
      ) : (
        children(containerBoundingRect)
      )}
    </div>
  )
}

CardListContainer.propTypes = {
  children: PropTypes.func
}

CardListContainer.defaultProps = {
  children: undefined
}

export default CardListContainer
