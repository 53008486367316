import { useMemo, useState } from 'react'
import dayjs from 'dayjs'
import { first } from 'lodash'
import { useCoreSeriesData } from '../../../api/coreData'
import { useAppContext } from '../../../redux/slices/appContext'
import { useRelativeDateRange } from '../RelativeDateSelect'

export const useChartGroupedCoreData = ({
  groupings,
  labelFormat,
  defaultFilters,
  defaultDateType,
  defaultDateRange,
  defaultDataPoints
}) => {
  const { clientId, availableDates } = useAppContext()
  const [selectedDataPoint, setSelectedDataPoint] = useState(first(defaultDataPoints))
  const [selectedDateRange, setSelectedDateRange] = useState(defaultDateRange)
  const [selectedDataInterval, setSelectedDataInterval] = useState(defaultDateType)
  const { dateRange } = useRelativeDateRange(selectedDateRange, availableDates)

  const { query, queryOptions } = useMemo(() => {
    return {
      query: {
        levelFilters: {
          dateType: selectedDataInterval,
          clientIds: [clientId],
          levelTypes: groupings,
          calcType: 'timeSeries',
          z_orderBy: ['ordinal ASC'],
          z_levelDatesOnly: true,
          V2: true,
          ...(defaultFilters || {})
        },
        dateRange: {
          startDate: dateRange.startDate,
          endDate: dateRange.endDate
        },
        options: {
          dataPoints: [selectedDataPoint.value]
        }
      },
      queryOptions: {
        mapper: (data) => {
          const series = data.series
          const dataPoint = selectedDataPoint.value
          series[dataPoint] = series[dataPoint].map((seriesDatum) => ({
            ...seriesDatum,
            label: dayjs(seriesDatum.label).format(labelFormat)
          }))
          return series
        }
      }
    }
  }, [
    clientId,
    groupings,
    dateRange,
    labelFormat,
    defaultFilters,
    selectedDataPoint,
    selectedDataInterval
  ])

  const { data, isLoading } = useCoreSeriesData(query, queryOptions)

  return {
    series: data,
    isLoading,
    dataPoint: {
      label: selectedDataPoint.label,
      value: selectedDataPoint.value,
      onChange: setSelectedDataPoint
    },
    dateRange: {
      value: selectedDateRange,
      onChange: setSelectedDateRange
    },
    dataInterval: {
      value: selectedDataInterval,
      onChange: setSelectedDataInterval
    }
  }
}
