import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core'

import Text from '../../../../atoms/Text'

const useStyles = makeStyles((theme) => ({
  '@keyframes shadeyIn': {
    from: {
      boxShadow: '0px 5px 5px -2px rgba(0,0,0,0)'
    },
    to: {
      boxShadow: '0px 5px 5px -2px rgba(0,0,0,0.25)'
    }
  },
  filterContainer: {
    border: `2px solid ${theme.palette.baseBackground.avatar}`,
    minHeight: '3rem',
    padding: '1.5rem 2rem',
    borderRadius: '5px',
    minWidth: '10rem',
    maxWidth: '15rem',
    width: '100%',
    transition: 'border-color .25s ease-in-out, background-color .25s ease-in-out',
    animationDirection: 'reverse',
    '&:hover': {
      cursor: 'pointer',
      border: `2px solid ${theme.palette.primary.main}`,
      animation: '$shadeyIn .25s ease-in-out forwards',
      boxShadow: '0px 5px 5px -2px rgba(0,0,0,0.25)'
    },
    '&:active': {
      backgroundColor: theme.palette.gray.light,
      transition: 'background-color 0s'
    },
    '&.__selected': {
      borderColor: theme.palette.black
    }
  },
  byLine: {
    marginTop: '1rem',
    fontWeight: theme.typography.weights.light
  },
  count: {
    fontWeight: theme.typography.weights.semibold,
    marginRight: '4px'
  }
}))

function StatusFilter ({ info, onClick, selectedItem, options }) {
  const selected = useMemo(() => selectedItem?.key === info?.key, [info, selectedItem])
  const classes = useStyles()
  const option = options[info.key]

  const clickHandler = useCallback((e) => {
    if (typeof onClick === 'function') {
      onClick(option)
      e.preventDefault()
      e.stopPropagation()
    }
  }, [option, onClick])

  const { title, color } = (option || {})
  const { total } = (info || {})

  return (
    <div
      className={clsx(classes.filterContainer, {
        __selected: selected
      })}
      onClick={clickHandler}
    >
      <Text
        text={title}
        color={color}
        lineHeight='1'
        variant='body2'
        customFontWeight='bold'
        customFontSize='1.625rem'
      />
      <div className={classes.byLine}>
        <span className={classes.count}>{total}</span>
        <span>as of today</span>
      </div>
    </div>
  )
};

StatusFilter.propTypes = {
  info: PropTypes.shape({
    key: PropTypes.string,
    total: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  }),
  selectedItem: PropTypes.shape({
    key: PropTypes.string
  }),
  options: PropTypes.shape({
    title: PropTypes.string,
    value: PropTypes.object,
    key: PropTypes.string,
    color: PropTypes.string
  }),
  onClick: PropTypes.func
}

export default StatusFilter
