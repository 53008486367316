import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { setToggleIsOpen, useAddAccountContext } from '../../../redux/slices/addAccountContext'

const AddAccountToggle = ({ children }) => {
  const addAccountContext = useAddAccountContext()
  const dispatch = useDispatch()

  const onClickToggleButton = useCallback((isOpen) => {
    dispatch(setToggleIsOpen({ isOpen: true }))
  }, [dispatch])

  if (!addAccountContext.isEnabled) {
    return null
  }

  return (
    <div onClick={() => onClickToggleButton(null)}>
      {children}
    </div>
  )
}

AddAccountToggle.propTypes = {
  children: PropTypes.node
}
export default AddAccountToggle
