import PropTypes from 'prop-types'

/**
 * Overrides a cell value at a given position; the
 * override {cellValue} is a raw value that is either
 * unformatted or expected to be formatted
 * @returns string
 */
const OverrideCellValue = ({ column, value, row }) => {
  if (!column?.payload || !column?.payload?.position) {
    return value
  }
  const { position, cellValue } = column.payload
  const { rowIndex, depth } = position

  if (rowIndex === row.index && row.depth === depth) {
    return cellValue
  }
  return value
}

OverrideCellValue.propTypes = {
  value: PropTypes.string,
  row: PropTypes.object,
  column: PropTypes.shape({
    payload: PropTypes.shape({
      position: PropTypes.shape({
        // row index of the cell we want to override
        rowIndex: PropTypes.number,
        // row depth of the cell we want to override
        depth: PropTypes.number
      }),
      // cell value that will replace the cell that matches the position criteria
      cellValue: PropTypes.string
    })
  })
}

export default OverrideCellValue
