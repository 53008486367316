import React, { useMemo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import FadeIn from '../../../molecules/Transitions/FadeIn'
import LastSessionLink from './LastSessionLink'

const useStyles = makeStyles((theme) => ({
  inlineError: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%'
  },
  inner: {
    margin: '10px',
    width: '100%',
    outline: `2px solid ${theme.palette.error.light}`,
    color: theme.palette.error.light,
    borderRadius: '10px',
    padding: '15px 30px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  prev: {
    padding: '20px 0 0'
  }
}))

function InlineError ({ error, clientId }) {
  const classes = useStyles()
  const message = useMemo(() => {
    if (!error) return ''

    if (error.message && error.detail) {
      return `${error.message}: ${error.detail}`
    }

    if (error.response?.message && error.response?.detail) {
      return `${error.response.message}: ${error.response.detail}`
    }

    return 'There was a problem processing your request'
  }, [error])

  if (!error) return null

  return (
    <FadeIn>
      <div className={classes.inlineError}>
        <div className={classes.inner}>
          <div>{message}</div>
          <div className={classes.prev}>
            <LastSessionLink clientId={clientId} />
          </div>
        </div>
      </div>
    </FadeIn>
  )
}

InlineError.propTypes = {
  error: PropTypes.any,
  clientId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

export default InlineError
