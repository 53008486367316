import React, { useMemo } from 'react'
import { makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import SectionScreen from '../../SectionScreen'
import { useClientDetails } from '../../ClientDetailsFormContext'
import { useGroupSearch, useGroupTypeSearch } from '../../../../../../api/groups'
import SectionHeader from '../../../shared/SectionHeader'
import FadeIn from '../../../../../molecules/Transitions/FadeIn'
import GroupAssignments from './GroupAssignments'

const useStyles = makeStyles((theme) => ({
  tagsSection: {
    position: 'relative',
    padding: '10px'
  }
}))

const useClientGroups = (client, singleValue, multiValue) => {
  const groupsQuery = useMemo(() => ({
    filters: {
      memberLevelTypeIds: [201],
      memberLevelIds: [client.clientId]
    }
  }), [client.clientId])

  const groupTypesQuery = useMemo(() => ({
    filters: {
      clientSpecific: false,
      memberLevelTypeIds: [201]
    },
    includes: {
      groups: false,
      groupCount: true,
      groupMemberTypes: true
    }
  }), [])

  const { data: groups, isLoading: groupsLoading, refetch } = useGroupSearch(groupsQuery)
  const { data: schema, isLoading: schemaLoading } = useGroupTypeSearch(groupTypesQuery)

  const data = useMemo(() => {
    if (!groups) return []
    if (!schema) return []
    return schema.data.filter(gt => {
      return gt.uniqueMembers
        ? !!singleValue
        : !!multiValue
    }).map(gt => ({
      ...gt,
      groups: groups.data.filter(x => x.levelTypeId === gt.groupTypeId)
    }))
  }, [groups, schema, singleValue, multiValue])

  return {
    groups: groups?.data,
    schema: schema?.data,
    data,
    isLoading: groupsLoading || schemaLoading,
    refetch
  }
}

function ClientGroups ({ singleValue, multiValue, title }) {
  const classes = useStyles()
  const { client } = useClientDetails()
  const { data, isLoading } = useClientGroups(client, singleValue, multiValue)

  if (isLoading) {
    return null
  }

  return (
    <FadeIn>
      <SectionScreen sectionName='client-tags' className={classes.tagsSection}>
        <SectionHeader text={title} />
        <div>
          <GroupAssignments client={client} groupTypes={data} />
        </div>
      </SectionScreen>
    </FadeIn>
  )
}

ClientGroups.propTypes = {
  singleValue: PropTypes.bool,
  multiValue: PropTypes.bool,
  title: PropTypes.string
}

ClientGroups.defaultProps = {
  title: 'Tags'
}

export default ClientGroups
