import React, { useMemo, useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import _isEmpty from 'lodash/isEmpty'
import { animated, useSpring } from 'react-spring'
import { makeStyles, darken } from '@material-ui/core/styles'
import { Grid, Box, useTheme } from '@material-ui/core'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'
import noop from 'lodash/noop'
import { numeralByCase } from '../../utils'
import NumberFormat from '../atoms/NumberFormat'
import { DISPLAY_VBS_NUMBER_FORMAT, TITLE_VBS_NUMBER_FORMAT } from '../../constants'
import { accountDetailsProps } from '../../prop-types'

const DISPLAY_TEXT_INLINE_BREAKPOINT = 15
const DISPLAY_TEXT_BREAKPOINT = 30
const BLOCK_MIN_WIDTH_IN_PERCENTAGE = 6

function checkSmallerThanBreakpoint ({ vertical, value, breakpoint }) {
  return !vertical && value && value < breakpoint
}

function checkDisplayTitles ({ value, vertical }) {
  if (vertical) return true
  return value && value > DISPLAY_TEXT_BREAKPOINT
}

function getStripedColor (color) {
  return `repeating-linear-gradient(45deg, ${color}, ${color} 5px, #FFF 5px, #FFF 10px)`
}

function getBlockBackgroundColor (color, defaultColor, useStripedBackground) {
  let backgroundColor = color || defaultColor
  if (useStripedBackground) {
    backgroundColor = getStripedColor(backgroundColor)
  }
  return backgroundColor
}

const useStyles = makeStyles((theme) => ({
  block: ({
    backgroundColor: _backgroundColor,
    useStripedBackground,
    blockHeight,
    blockWidth,
    displayInLine,
    vertical
  }) => {
    const backgroundColor = getBlockBackgroundColor(
      _backgroundColor,
      theme.palette.gray.A500,
      useStripedBackground
    )
    return {
      padding: vertical ? '1rem' : '0',
      width: blockWidth || '100%',
      height: blockHeight || '100%',
      position: 'relative',
      borderRadius: '0.5rem',
      background: backgroundColor,
      display: 'flex',
      flexDirection: displayInLine ? 'row' : 'column',
      justifyContent: displayInLine ? 'flex-end' : 'center',
      alignItems: displayInLine ? 'center' : 'flex-start'
    }
  },
  text: {
    color: theme.palette.summitBlue
  },
  title: ({
    backgroundColor,
    useStripedBackground,
    detailsBackgroundColor
  }) => ({
    lineHeight: '1.2',
    padding: '0.25rem',
    fontSize: '1.4rem',
    marginLeft: '1.5rem',
    marginTop: '4px',
    ...(useStripedBackground
      ? {}
      : {
        color: theme.palette.getContrastText(
          backgroundColor || theme.palette.black
        )
      }),
    backgroundColor: detailsBackgroundColor || 'initial'
  }),
  subTitle: ({
    backgroundColor,
    useStripedBackground,
    detailsBackgroundColor
  }) => ({
    fontWeight: 400,
    lineHeight: '1.0',
    padding: '0.25rem',
    fontSize: '0.75rem',
    marginLeft: '1.5rem',
    ...(useStripedBackground
      ? {}
      : {
        color: theme.palette.getContrastText(
          backgroundColor || theme.palette.black
        )
      }),
    backgroundColor: detailsBackgroundColor || 'initial'
  }),
  detailBlock: () => ({
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'row'
  }),
  detailBlockItem: ({ backgroundColor: bgColor, useStripedBackground, itemHovered }) => {
    const darkenBgColor = darken(bgColor || theme.palette.gray.A500, 0.3)

    const backgroundColor = getBlockBackgroundColor(
      darkenBgColor,
      theme.palette.gray.A500,
      useStripedBackground
    )

    return {
      position: 'relative',
      padding: '1rem',
      color: darken(bgColor || theme.palette.common.white, 0.3),
      cursor: 'pointer',
      overflow: 'hidden',
      borderRightColor: bgColor ? darken(bgColor, 0.3) : 'transparent',
      borderRightStyle: itemHovered ? 'solid' : 'none',
      borderRight: '3px',
      '&:hover': {
        background: `${backgroundColor} !important`,
        color: `${theme.palette.common.white} !important`
      },
      '&:first-child': {
        borderTopLeftRadius: '8px',
        borderBottomLeftRadius: '8px'
      },
      '&:last-child': {
        borderRight: '0px solid white',
        borderTopRightRadius: '8px',
        borderBottomRightRadius: '8px'
      }
    }
  },
  detailBlockItemContinue: {
    width: '2.5rem !important',
    display: 'flex',
    justifyContent: 'center'
  },
  detailValue: ({ detailsBackgroundColor, backgroundColor }) => ({
    fontSize: '0.75rem',
    position: 'absolute',
    bottom: '0.75rem',
    right: '0.75rem',
    ...(detailsBackgroundColor
      ? {
        backgroundColor: theme.palette.white,
        padding: '0.25rem',
        color: darken(backgroundColor || theme.palette.gray.A500, 0.3)
      }
      : {})
  }),
  tooltipPopover: {
    right: 0
  },
  tooltip: {
    transform: 'translate(0, -98%)',
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.14)',
    borderRadius: '1rem',
    maxWidth: 'none',
    padding: '1.25rem',
    zIndex: 1,
    position: 'absolute',
    top: 0,
    right: 0,
    left: 0
  },
  tooltipArrow: {
    position: 'absolute',
    width: 0,
    height: 0,
    borderLeft: '1.5rem solid transparent',
    borderRight: '1.5rem solid transparent',
    borderTop: `1.5rem solid ${theme.palette.white}`,
    bottom: '-0.5rem',
    left: '50%',
    transform: 'translate(-50%, 50%)'
  },
  tooltipTitle: {
    textTransform: 'uppercase',
    marginBottom: '12px',
    color: theme.palette.dustyGray,
    fontSize: '0.875rem'
  },
  tooltipItem: {
    marginBottom: '5px'
  },
  tooltipItemTitle: {
    fontSize: '1rem',
    fontWeight: 'normal',
    color: theme.palette.dustyGray
  },
  tooltipItemSubtitle: {
    fontSize: '1rem',
    color: theme.palette.dustyGray,
    textAlign: 'right'
  },
  tooltipItemHovered: {
    fontWeight: 'bold',
    color: `${theme.palette.common.black} !important`
  }
}))

const NUMBER_FORMAT = DISPLAY_VBS_NUMBER_FORMAT

function TreeMapBlock ({
  backgroundColor,
  blockHeight,
  blockValue,
  blockWidth,
  dataTestId,
  details,
  detailsBackgroundColor,
  disableShortenBlocks,
  onClick,
  selectedBlock,
  showDetails,
  subtitleText,
  titleText,
  useParenthesis,
  useStripedBackground,
  value,
  vertical
}) {
  const theme = useTheme()
  const [displayInLine, displayTitles] = useMemo(() => {
    const displayInLine = checkSmallerThanBreakpoint({
      vertical,
      value,
      breakpoint: DISPLAY_TEXT_INLINE_BREAKPOINT
    })
    const displayTitles = checkDisplayTitles({ value, vertical })
    return [displayInLine, displayTitles]
  }, [value, vertical])

  const [itemHovered, setItemHovered] = useState(undefined)

  const clearHovered = useCallback(() => setItemHovered(undefined), [])

  const actionsProps = useSpring({
    from: {
      opacity: 0
    },
    to: {
      opacity: itemHovered ? 1 : 0
    }
  })

  const classes = useStyles({
    backgroundColor,
    blockHeight,
    blockValue,
    blockWidth,
    detailsBackgroundColor,
    displayInLine,
    itemHovered,
    showDetails,
    useStripedBackground,
    value,
    vertical
  })

  const [blockDetails, originalBlocks] = useMemo(() => {
    let totalValue = details?.reduce((acc, block) => acc + Math.abs(block.endingValue), 0)
    let { blocks } = [...(details || [])]
      .sort((blockA, blockB) => Math.abs(blockB.endingValue) - Math.abs(blockA.endingValue))
      .reduce(
        (acc, { endingValue, ...block }) => {
          const blockWidth = (100 * Math.abs(endingValue)) / totalValue
          const totalWidth = acc.total + blockWidth
          const shouldDisplayBlock =
            (totalWidth < 100 && blockWidth > BLOCK_MIN_WIDTH_IN_PERCENTAGE) ||
            totalValue === Math.abs(endingValue)

          acc.blocks.push({
            ...block,
            endingValue,
            width: blockWidth,
            showBlock: disableShortenBlocks || shouldDisplayBlock
          })
          acc.total += blockWidth
          return acc
        },
        { total: 0, blocks: [] }
      )

    const originalBlocks = [...blocks]
    if (blocks.some((block) => !block.showBlock)) {
      const { finalBlocks, remainingBlocks } = blocks.reduce(
        (acc, block) => {
          if (block.showBlock) {
            acc.finalBlocks.push(block)
          } else {
            acc.remainingBlocks.push(block)
          }
          return acc
        },
        { finalBlocks: [], remainingBlocks: [] }
      )

      totalValue = finalBlocks.reduce(
        (acc, block) => acc + block.endingValue,
        0
      )
      blocks = finalBlocks.map(({ endingValue, ...block }) => ({
        ...block,
        endingValue,
        width: (100 * endingValue) / totalValue
      }))

      blocks.push({ isRemainingBlocks: true, remainingBlocks })
    }
    return [blocks, originalBlocks]
  }, [details, disableShortenBlocks])

  const renderDetailBlock = useCallback(
    (block, index, total) => {
      const { levelId, endingValue, width, showBlock, isRemainingBlocks } =
        block
      const detailBlockId = levelId || 'remaining'
      let bgColor = backgroundColor || theme.palette.gray.A500
      if (block?.title === selectedBlock) {
        bgColor = darken(bgColor, 0.3)
      }

      bgColor = getBlockBackgroundColor(
        bgColor,
        theme.palette.gray.A500,
        useStripedBackground
      )

      return (
        <React.Fragment key={`detail-block-${detailBlockId}`}>
          <animated.div
            onClick={() => onClick(block)}
            onMouseOver={() => setItemHovered(detailBlockId)}
            onMouseLeave={clearHovered}
            className={clsx(classes.detailBlockItem, {
              [classes.detailBlockItemContinue]: !showBlock
            })}
            style={{
              width: `${width}%`,
              background: bgColor,
              opacity: actionsProps.opacity
            }}
          >
            {itemHovered && !isRemainingBlocks ? (
              <>
                <div className={classes.detailValue}>
                  <NumberFormat
                    title={numeralByCase(
                      endingValue,
                      TITLE_VBS_NUMBER_FORMAT,
                      TITLE_VBS_NUMBER_FORMAT
                    )}
                    number={numeralByCase(
                      endingValue,
                      NUMBER_FORMAT.SHORT,
                      NUMBER_FORMAT.LONG
                    )}
                    skipFormat
                    useParenthesis={useParenthesis}
                  />
                </div>
              </>
            ) : itemHovered ? (
              <div className={classes.detailValue}>. . .</div>
            ) : (
              <div />
            )}
          </animated.div>
        </React.Fragment>
      )
    },
    [
      itemHovered,
      actionsProps.opacity,
      useStripedBackground,
      backgroundColor,
      clearHovered,
      selectedBlock,
      theme,
      onClick,
      useParenthesis,
      classes.detailValue,
      classes.detailBlockItem,
      classes.detailBlockItemContinue
    ]
  )

  const tooltipContent = useMemo(() => {
    const middle = Math.ceil(originalBlocks.length / 2)
    const columns = originalBlocks.reduce(
      (acc, block, index) => {
        if (index < middle) {
          return { ...acc, firstColumn: [...acc.firstColumn, block] }
        }
        return { ...acc, secondColumn: [...acc.secondColumn, block] }
      },
      { firstColumn: [], secondColumn: [] }
    )

    return (
      <div className={classes.tooltip}>
        <div className={classes.tooltipArrow} />
        <div className={classes.tooltipTitle}>{subtitleText}</div>
        <Grid container spacing={2}>
          {Object.values(columns).map((column, index) => {
            if (_isEmpty(column)) {
              return null
            }
            const hasMultipleColumns = columns.firstColumn.length > 1
            return (
              <Grid
                item
                xs={hasMultipleColumns ? 6 : 12}
                key={`tooltip-column-${index + 1}`}
              >
                {column.map((block) => {
                  let isHovered = false
                  if (!block.showBlock && itemHovered === 'remaining') {
                    isHovered = true
                  }
                  if (block.showBlock && block.levelId === itemHovered) {
                    isHovered = true
                  }

                  return (
                    <div className={classes.tooltipItem} key={block.levelId}>
                      <Grid container spacing={2}>
                        <Grid
                          item
                          xs={12}
                          className={clsx(classes.tooltipItemTitle, {
                            [classes.tooltipItemHovered]: isHovered
                          })}
                        >
                          <Box
                            display='flex'
                            flexDirection='row'
                            alignItems='center'
                          >
                            <Box mr='0.75rem'>
                              <FiberManualRecordIcon
                                style={{
                                  fontSize: 8,
                                  color: backgroundColor,
                                  opacity: isHovered ? 1 : 0.5
                                }}
                              />
                            </Box>
                            {block.title}
                            <Box
                              ml='auto'
                              fontWeight='bold'
                              pr={hasMultipleColumns ? '1rem' : 0}
                            >
                              <NumberFormat
                                title={numeralByCase(
                                  block.endingValue,
                                  TITLE_VBS_NUMBER_FORMAT,
                                  TITLE_VBS_NUMBER_FORMAT
                                )}
                                number={numeralByCase(
                                  block.endingValue,
                                  NUMBER_FORMAT.SHORT,
                                  NUMBER_FORMAT.LONG
                                )}
                                skipFormat
                                useParenthesis={useParenthesis}
                              />
                            </Box>
                          </Box>
                        </Grid>
                      </Grid>
                    </div>
                  )
                })}
              </Grid>
            )
          })}
        </Grid>
      </div>
    )
  }, [
    backgroundColor,
    originalBlocks,
    classes.tooltipItem,
    classes.tooltipItemTitle,
    classes.tooltipItemHovered,
    classes.tooltip,
    classes.tooltipTitle,
    classes.tooltipArrow,
    subtitleText,
    itemHovered,
    useParenthesis
  ])

  return (
    <div className={classes.block}>
      {showDetails && (
        <>
          {itemHovered && tooltipContent}
          <div className={classes.detailBlock}>
            {blockDetails.map((block) => renderDetailBlock(block))}
          </div>
        </>
      )}
      {displayTitles && !showDetails && (
        <>
          {displayInLine && (
            <div className={clsx(classes.text, classes.subTitle)}>
              {subtitleText}
            </div>
          )}
          <div className={clsx(classes.text, classes.title)}>{titleText}</div>
          {!displayInLine && (
            <div className={clsx(classes.text, classes.subTitle)}>
              {subtitleText}
            </div>
          )}
        </>
      )}
    </div>
  )
}

TreeMapBlock.propTypes = {
  backgroundColor: PropTypes.string,
  blockHeight: PropTypes.string,
  blockValue: PropTypes.number,
  blockWidth: PropTypes.string,
  dataTestId: PropTypes.string,
  details: PropTypes.arrayOf(PropTypes.shape(accountDetailsProps)),
  detailsBackgroundColor: PropTypes.string,
  disableShortenBlocks: PropTypes.bool,
  onClick: PropTypes.func,
  selectedBlock: PropTypes.string,
  showDetails: PropTypes.bool,
  subtitleText: PropTypes.string,
  titleText: PropTypes.oneOfType([PropTypes.string, PropTypes.any]),
  useParenthesis: PropTypes.bool,
  useStripedBackground: PropTypes.bool,
  value: PropTypes.number,
  vertical: PropTypes.bool
}

TreeMapBlock.defaultProps = {
  backgroundColor: '',
  blockHeight: '',
  blockValue: 0,
  blockWidth: '',
  dataTestId: '',
  details: [],
  detailsBackgroundColor: '',
  disableShortenBlocks: false,
  onClick: noop,
  selectedBlock: '',
  showDetails: false,
  subtitleText: '',
  titleText: '',
  useParenthesis: true,
  useStripedBackground: false,
  value: 0,
  vertical: false
}

export default TreeMapBlock
