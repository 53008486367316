import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useFormattingContext } from '../../../organisms/FormattingProvider/FormattingContext'
import AnalysisMetric from './AnalysisMetric'

function TotalTradesMetric ({ totalTrades, loading }) {
  const { formatter } = useFormattingContext()
  const value = useMemo(() => {
    if (loading) return ''

    return formatter(totalTrades, '0,0')
  }, [loading, totalTrades, formatter])

  return (
    <AnalysisMetric loading={loading} metricName='Total Trades' value={value} />
  )
}

TotalTradesMetric.propTypes = {
  loading: PropTypes.bool,
  totalTrades: PropTypes.number
}

TotalTradesMetric.defaultProps = {
}

export default TotalTradesMetric
