import React from 'react'
import PropTypes from 'prop-types'
import GroupingPicker from '../GroupingProvider/GroupingPicker'
import GroupingProvider from '../GroupingProvider'
import ComponentsOfChangeTableWrapper from './ComponentsOfChangeWrapper'
import { componentsOfChangeTableDefaultProps, componentsOfChangeTableProps } from './ComponentsOfChangeTable'

const ComponentsOfChangeTableV2 = ({
  options,
  defaultDateRanges,
  dateRangeOptions,
  configurationStorageKey,
  ...props
}) => {
  return (
    <GroupingProvider
      configurationKey={configurationStorageKey}
      groupingDateRanges={defaultDateRanges}
      groupingDateRangeOptions={dateRangeOptions}
    >
      <GroupingPicker options={options} />
      <ComponentsOfChangeTableWrapper
        {...props}
        defaultDateRanges={defaultDateRanges}
      />
    </GroupingProvider>
  )
}

ComponentsOfChangeTableV2.propTypes = {
  ...componentsOfChangeTableProps,
  defaultDateRanges: PropTypes.array,
  options: PropTypes.shape({
    hideCustomizeColumnsButton: PropTypes.bool,
    hideExpandCollapseButton: PropTypes.bool,
    hideGroupingsUnderline: PropTypes.bool,
    hideExportButton: PropTypes.bool
  }),
  configurationStorageKey: PropTypes.string
}

ComponentsOfChangeTableV2.defaultProps = {
  ...componentsOfChangeTableDefaultProps,
  defaultDateRanges: ['L7D', 'L30D', 'YTD'],
  options: {
    hideCustomizeColumnsButton: false,
    hideExpandCollapseButton: true,
    hideGroupingsUnderline: true,
    hideExportButton: false
  },
  configurationStorageKey: undefined
}

export default ComponentsOfChangeTableV2
