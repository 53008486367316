import React, { useCallback, useState } from 'react'

import ManagementView from '../managementView'
import { useFetchState } from '../../../../hooks'
import { getClientFeeAssignments } from '../../../../service'
import { CSV_CELL_TYPES, TEXT_ALIGNMENTS } from '../../../../constants'

const labels = [
  { name: 'Client ID' },
  { name: 'Client Name' },
  { name: 'Fee Schedule' }
]

const ClientFeeAssignments = () => {
  const [loadData, setLoadData] = useState(true)

  const mapDataToItems = useCallback(({
    clientId,
    longName,
    fsLongName
  }) => [
    { alignment: TEXT_ALIGNMENTS.left, value: clientId || '-', csvData: { type: CSV_CELL_TYPES.string } },
    { alignment: TEXT_ALIGNMENTS.left, value: longName || '-', csvData: { type: CSV_CELL_TYPES.string } },
    { alignment: TEXT_ALIGNMENTS.left, value: fsLongName || '-', csvData: { type: CSV_CELL_TYPES.string } }
  ], [])

  const singleCallback = useCallback(async (safeSetState) => {
    if (loadData) {
      try {
        const res = await getClientFeeAssignments()
        const items = res.data.map(mapDataToItems)
        safeSetState({ data: res.data, items })
      } catch (error) {
        console.error(error)
        safeSetState({ error })
      } finally {
        setLoadData(false)
      }
    }
  }, [mapDataToItems, loadData])

  const { loading, error, items = [] } = useFetchState(singleCallback)

  return (
    <ManagementView
      error={error}
      items={items}
      labels={labels}
      loadingTable={loading || loadData}
      title='Client Fee Assignments'
      virtualizeTable
    />
  )
}

export default ClientFeeAssignments
