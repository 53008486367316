import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import Tabs from '../../../molecules/Tabs'
import { useGroupingContext } from './GroupingContext'
import GroupingOptions, {
  groupingOptionsDefaultProps,
  groupingOptionsPropTypes
} from './GroupingOptions'

const useStyles = makeStyles(() => ({
  hideGroupingUnderline: {
    borderBottom: 'unset',
    marginBottom: 0
  }
}))

const GroupingPicker = ({ options, groupOptions }) => {
  const classes = useStyles()
  const {
    groupings,
    selectGrouping,
    defaultGrouping,
    selectedGrouping,
    options: { hiddenGroupings = [] }
  } = useGroupingContext()

  const onGroupChange = useCallback(
    (_, groupName) => {
      selectGrouping(groupName)
    },
    [selectGrouping]
  )

  const groupingOptions = useMemo(
    () => ({ ...options, ...(groupOptions[selectedGrouping.key] || {}) }),
    [groupOptions, options, selectedGrouping.key]
  )

  const groupingTabs = useMemo(() => {
    return groupings.filter(({ key }) => !hiddenGroupings.includes(key))
  }, [groupings, hiddenGroupings])

  return (
    <Tabs.Wrapper
      className={clsx({
        [classes.hideGroupingUnderline]: options.hideGroupingsUnderline
      })}
    >
      <Tabs
        defaultValue={defaultGrouping.key}
        value={selectedGrouping.key}
        indicatorColor='primary'
        textColor='primary'
        onChange={onGroupChange}
      >
        {groupingTabs.map(({ key }) => (
          <Tabs.Tab key={key} label={key} value={key} />
        ))}
      </Tabs>
      <GroupingOptions {...groupingOptions} />
    </Tabs.Wrapper>
  )
}

export const groupingPickerPropTypes = PropTypes.shape({
  ...groupingOptionsPropTypes,
  hideGroupingsUnderline: PropTypes.bool
})

export const groupingPickerDefaultProps = {
  ...groupingOptionsDefaultProps,
  hideGroupingsUnderline: false
}

GroupingPicker.propTypes = {
  options: groupingPickerPropTypes,
  /** Options overrides per group where their keys must match the array of groups */
  groupOptions: PropTypes.object
}

GroupingPicker.defaultProps = {
  options: groupingPickerDefaultProps,
  groupOptions: {}
}

export default GroupingPicker
