import React from 'react'
import PropTypes from 'prop-types'
import { useAdvisorHome } from '../../AdvisorHome/AdvisorHomeContext'
import { useMyAdvisor } from '../../../../api/groups'
import UserScopeDisplay from './UserScopeDisplay'
import GroupScopeDisplay from './GroupScopeDisplay'

const MyClientsPicker = ({
  headingLabel,
  includeAdvisors,
  hideAvatars,
  size,
  disableReset
}) => {
  const { scope, setScope } = useAdvisorHome()
  const { isLoading: myAdvisorLoading, data: myAdvisor } = useMyAdvisor()

  if (!scope) {
    return <UserScopeDisplay hideAvatars={hideAvatars} size={size} headingLabel={headingLabel} />
  }

  return (
    <GroupScopeDisplay
      scope={scope}
      setScope={setScope}
      headingLabel={headingLabel}
      includeAdvisors={includeAdvisors}
      myAdvisor={myAdvisor?.advisor}
      isLoading={myAdvisorLoading}
      hideAvatars={hideAvatars}
      size={size}
      disableReset={disableReset}
    />
  )
}

MyClientsPicker.propTypes = {
  headingLabel: PropTypes.string,
  includeAdvisors: PropTypes.bool,
  hideAvatars: PropTypes.bool,
  size: PropTypes.oneOf(['sm', 'lg']),
  disableReset: PropTypes.bool
}

MyClientsPicker.defaultProps = {
  size: 'lg'
}

export default MyClientsPicker
