import { useCallback, useState } from 'react'
import { useIsMounted } from './useIsMounted'

export default function usePasswordErrors (keys) {
  const isMounted = useIsMounted()

  const [passErrors, setPassErrors] = useState(keys)

  const setErrors = useCallback(
    (key, value) => isMounted && setPassErrors((prev) => {
      return {
        ...prev,
        [key]: value
      }
    }), [isMounted]
  )

  return [passErrors, setErrors]
}
