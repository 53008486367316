import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import Section from '../shared/Section'
import { useFormattingContext } from '../../../../../organisms/FormattingProvider/FormattingContext'
import SydButton from '../../../../../commonDesign/Button'
import SectionTitle from '../shared/SectionTitle'
import { useClientReviewContext } from '../ClientReviewContext'
import { usePolicy } from '../../../../../../hooks/usePolicy'

const useStyles = makeStyles((theme) => ({
  billingAccounts: {
    borderCollapse: 'collapse',
    width: '100%',
    '& tr td': {
      fontWeight: theme.typography.weights.light
    },
    '& tr td:first-child': {
      paddingLeft: theme.layout.padding.thin
    },
    '& tbody tr.__selected td': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText
    }
  }
}))

function BillingAccounts ({ account, billingAccounts, familyRates }) {
  const classes = useStyles()
  const { formatter } = useFormattingContext()
  const cr = useClientReviewContext()
  const canEditBillingAccounts = usePolicy('billing_edit_billing_accounts')

  if (!billingAccounts) {
    return null
  }

  return (
    <Section>
      <SectionTitle title='Billing Accounts'>
        {canEditBillingAccounts ? (
          <SydButton icon='edit' size='xs' onClick={() => cr.dialogs.editBillingAccounts(account, billingAccounts, familyRates)}>Edit</SydButton>
        ) : null}
      </SectionTitle>
      <div>
        {!billingAccounts.length ? (
          <div className='__no-content'>
            No Designated Billing Accounts
          </div>
        ) : (
          <table className={classes.billingAccounts}>
            <thead>
              <tr>
                <th>Account ID</th>
                <th>Account Name</th>
                <th>Account Number</th>
                <th>Distribution Type</th>
                <th>Distribution Value</th>
                <th>Pay By Invoice</th>
              </tr>
            </thead>
            <tbody>
              {billingAccounts.map(ba => (
                <tr key={ba.billingAccountId}>
                  <td>{ba.billingAccountId}</td>
                  <td>{ba.accountName}</td>
                  <td>{ba.accountNumber}</td>
                  <td>{ba.distributionType}</td>
                  <td>{formatter(ba.distributionValue, 'number')}</td>
                  <td>{formatter(ba.isPayByInvoice, 'bool')}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </Section>
  )
}

BillingAccounts.propTypes = {
  account: PropTypes.object,
  billingAccounts: PropTypes.array,
  familyRates: PropTypes.object
}

export default BillingAccounts
