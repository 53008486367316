import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { Box, Tooltip, makeStyles } from '@material-ui/core'
import OperationalTable, { useOperationalTable } from '../../../../OperationalTable'
import Text from '../../../../../atoms/Text'
import Icon from '../../../../../atoms/Icon'
import { ICON_NAMES } from '../../../../../../constants'
import { useTasksContext } from '../../../TasksContext'

dayjs.extend(utc)

export const cellTemplates = {
  date: ({ value }) => dayjs.utc(value).format('MM/DD'),
  description: ({ row: { original } }) => {
    const processDescription = (description) => {
      if (description) {
        return description.replaceAll('\r', '').split('\n').map((line, i) => {
          console.log('line', line)
          return (
            <div key={`line${i}`}>{line}</div>
          )
        })
      }
      return ''
    }
    return (
      <Box display='flex' flexDirection='column'>
        <Text text={original.title} customFontWeight='bold' />
        <div>{processDescription(original.description)}</div>
      </Box>
    )
  },
  options: ({ value }) =>
    value && (
      <Tooltip title='Recurring'>
        <Icon name={ICON_NAMES.history} customSize='1.5rem' />
      </Tooltip>
    )
}

export const defaultColumnConfig = {
  columns: [
    { id: 'dueDate', Header: 'Due', width: 20, accessor: 'dueDate', Cell: cellTemplates.date },
    { id: 'description', Header: 'Description', width: 60, accessor: 'description', Cell: cellTemplates.description },
    { id: 'options', Header: '', width: 20, accessor: 'isRecurring', Cell: cellTemplates.options }
  ],
  defaultSort: [{ id: 'dueDate', desc: false }]
}

const columnTemplateConfigurations = {
  string: (col) => col
}

const useColumnConfig = (columnConfig = defaultColumnConfig) => {
  return useMemo(() => {
    return {
      columns: columnConfig.columns.map((x) => {
        const type = x.type in columnTemplateConfigurations ? x.type : 'string'
        return {
          ...x,
          ...columnTemplateConfigurations[type](x)
        }
      }),
      defaultSort: columnConfig.defaultSort
    }
  }, [columnConfig])
}

const useStyles = makeStyles(() => ({
  container: {
    maxHeight: '25rem',
    overflowY: 'auto',
    width: '100%',
    '& table[role="table"]': {
      minWidth: 'unset !important'
    },
    '& table > thead > tr > th': {
      backgroundColor: '#f8f8f8',
      padding: '12px',
      verticalAlign: 'bottom'
    },
    '& table > tbody > tr > td': {
      verticalAlign: 'top',
      padding: '18px 12px'
    },
    '& table > thead': {
      boxShadow: 'none'
    }
  }
}))

const CardTasksTable = ({ columnConfig, limit, tasks }) => {
  const classes = useStyles()
  const { columns, defaultSort } = useColumnConfig(columnConfig)

  const { linkBaseUrl } = useTasksContext()

  const { defaultPageSize, onTableModeChange, onSortingChange } =
    useOperationalTable({
      defaultSort,
      defaultPageSize: limit
    })

  const onRowClick = useCallback(
    ({ original: { taskId } }) => {
      if (linkBaseUrl && taskId) {
        window.open(`${linkBaseUrl}/${taskId}/view`, '_blank', 'noreferrer')
      }
    },
    [linkBaseUrl]
  )

  return (
    <OperationalTable.Wrapper className={classes.container}>
      <OperationalTable
        mode='client'
        itemName='Tasks'
        columns={columns}
        data={tasks}
        loading={false}
        hideFooter
        onRowClick={onRowClick}
        defaultSort={defaultSort}
        defaultPageSize={defaultPageSize}
        onSortingChange={onSortingChange}
        onTableModeChange={onTableModeChange}
      />
    </OperationalTable.Wrapper>
  )
}

CardTasksTable.propTypes = {
  tasks: PropTypes.array,
  limit: PropTypes.number,
  columnConfig: PropTypes.object
}

CardTasksTable.defaultProps = {
  limit: 100,
  tasks: [],
  client: {},
  columnConfig: defaultColumnConfig
}

export default CardTasksTable
