import React, { useCallback } from 'react'
import { makeStyles } from '@material-ui/core'
import ViewTypeToggle from '../../ClientCardsViewV2/components/ViewTypeToggle'
import { useMilestonesContext } from '../MilestonesContext'
import AsOfDatePicker from './AsOfDatePicker'

const useStyles = makeStyles(() => ({
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '1rem 0 0 0.5rem'
  }
}))

const MilestonesViewHeader = () => {
  const classes = useStyles()
  const { viewType, setViewType } = useMilestonesContext()

  const onToggleViewType = useCallback(
    (_viewType) => {
      if (!_viewType) return
      if (_viewType !== viewType) {
        setViewType(_viewType)
      }
    },
    [viewType, setViewType]
  )

  return (
    <div className={classes.header}>
      <AsOfDatePicker />
      <ViewTypeToggle value={viewType} onChange={onToggleViewType} />
    </div>
  )
}

export default MilestonesViewHeader
