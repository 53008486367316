import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Controller, useForm } from 'react-hook-form'
import dayjs from 'dayjs'
import isEmpty from 'lodash/isEmpty'
import quarterOfYear from 'dayjs/plugin/quarterOfYear'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import { Grid, makeStyles, MenuItem, TextField, useTheme } from '@material-ui/core'
import { KeyboardDatePicker } from '@material-ui/pickers'
import noop from 'lodash/noop'
import { useGetClients } from '../../../../api/clients'
import {
  ADMIN_ROUTES,
  ALERT_SEVERITY,
  BUTTON_SIZES,
  DEFAULT_SEARCH_CLIENTS_PAGE_SIZE,
  ERROR_ALERT,
  SUCCESS_ALERT
} from '../../../../constants'
import { runReport } from '../../../../service'
import AutoCompleteSearchInput from '../../../molecules/AutoCompleteSearchInput'
import Text from '../../../atoms/Text'
import RoundedButton from '../../../atoms/RoundedButton'
import SnackAlert from '../../../molecules/SnackAlert/SnackAlert'
import { messageToUse } from '../common'

import { getNearestWorkableAsOfDate } from '../billing/runBilling/helpers'
import { useHolidays } from '../../../../api/coreData'
import Skeleton from '../../../atoms/Skeleton'
import { disableHolidaysAndWeekends } from '../../../../utils'
import { useBoolean } from '../../../../hooks'
import { useAsOfDate, useListReportRuntimeConfigurationData } from './hooks'
import RuntimeConfigOverrides from './ConfigOverrides'

dayjs.extend(isSameOrAfter)
dayjs.extend(quarterOfYear)

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '0',
    height: '100%',
    display: 'flex',
    flex: '1',
    flexDirection: 'column',
    margin: '0 -1rem'
  },
  confirmError: {
    textAlign: 'left',
    marginTop: '0.375rem'
  },
  groupItem: {
    width: '100%',
    padding: '1rem'
  },
  buttonGroupItem: {
    display: 'flex',
    width: '20rem',
    maxWidth: '100%',
    padding: '0 1rem'
  },
  searchContent: {
    backgroundColor: '#F4F5F6',
    borderRadius: '4px !important',
    height: '3em',
    marginTop: '10px',
    borderBottom: 'unset'
  },
  searchInput: {
    backgroundColor: '#F4F5F6',
    padding: '0.625rem'
  },
  searchContainer: {
    height: '3rem'
  },
  itemTextField: {
    backgroundColor: '#F4F5F6',
    borderRadius: '4px',
    paddingTop: '0.625rem',
    paddingLeft: '0.625rem',
    marginTop: '0.625rem'
  },
  noValuePlaceholder: {
    backgroundColor: '#F4F5F6',
    borderRadius: '4px',
    padding: '0.625rem',
    marginTop: '0.625rem',
    color: '#666'
  },
  multilineItemTextField: {
    backgroundColor: '#F4F5F6',
    borderRadius: '4px',
    paddingTop: '0.625rem',
    paddingLeft: '0.625rem',
    marginTop: '0.625rem',
    marginBottom: '0.625rem',
    minHeight: '8rem'
  },
  itemDateField: {
    borderRadius: '4px',
    backgroundColor: '#F4F5F6',
    marginTop: '0.625rem',
    width: '100%',
    height: '3.0em',
    paddingTop: '0.5rem'
  },
  disabledButton: {
    cursor: 'not-allowed !important',
    backgroundColor: '#7f8299'
  },
  buttonGroup: {
    display: 'flex',
    flexWrap: 'wrap',
    alignContent: 'flex-end',
    justifyContent: 'flex-end',
    paddingBottom: '10px'
  },
  labelWithInfo: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  labelInfo: {
    opacity: '.8'
  },
  taggedSelectOption: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '1rem',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '8px',
    marginBottom: '-6px'
  },
  taggedSelectMenu: {
    padding: 0
  },
  taggedSelectMenuItem: {
    display: 'block'
  },
  taggedSelectOptionTags: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '.5rem',
    alignItems: 'center'
  }
}))

const textAreaProps = { disableUnderline: true }
const defaultAsOfDate = dayjs().subtract(1, 'quarter').endOf('quarter')
const defaultMaxDate = dayjs().subtract(1, 'day')

const RunReportForm = ({
  templates,
  isTemplatesLoading,
  onSubmit,
  allowManage,
  allowConfigurationSelection
}) => {
  const classes = useStyles()
  const theme = useTheme()
  const [searchClients, setSearchClients] = useState('')
  const [alert, setAlert] = useState({})
  const [asOfDate, setAsOfDate] = useState(defaultAsOfDate)
  const [clientId, setClientId] = useState()
  const [hasUserSelectedDate, setHasUserSelectedDate] = useBoolean(false)

  const { data: availableDates, isLoading: loadingAvailableDates } = useAsOfDate(clientId)
  const maxAvailableDate = useMemo(() => dayjs(availableDates?.max).add(1, 'day'), [availableDates])

  useEffect(() => {
    if (
      availableDates?.max && (
        dayjs(asOfDate).isAfter(maxAvailableDate, 'day') ||
        (!hasUserSelectedDate && maxAvailableDate !== asOfDate)
      )
    ) {
      setAsOfDate(maxAvailableDate)
      setHasUserSelectedDate.off()
    }
  }, [availableDates, maxAvailableDate, asOfDate, hasUserSelectedDate, setHasUserSelectedDate])

  const { data: clients, isLoading: isLoadingClients, refetch } = useGetClients({
    searchValue: searchClients,
    take: DEFAULT_SEARCH_CLIENTS_PAGE_SIZE
  })

  const onSearchClients = useCallback(async (value) => {
    try {
      setSearchClients(value)
      await refetch()
    } catch (err) {
      console.error(err)
    } finally {
    }
  }, [refetch, setSearchClients])

  const initialFormState = useMemo(() => {
    return {
      templateId: !isEmpty(templates) ? templates[0].templateId : '',
      runtimeConfigurationId: !isEmpty(templates) ? templates[0].defaultRuntimeConfigurationId : '',
      clientId: undefined,
      reportName: !isEmpty(templates) ? templates[0].name : '',
      billingRun: null
    }
  }, [templates])

  const { data: holidays } = useHolidays()
  const nearestWorkableAsOfDate = useMemo(
    () => getNearestWorkableAsOfDate(holidays, defaultAsOfDate),
    [holidays]
  )

  const {
    handleSubmit,
    control,
    register,
    reset,
    clearErrors,
    watch,
    setValue,
    setError,
    formState: { errors, isSubmitting, dirtyFields }
  } = useForm({
    mode: 'onChange',
    defaultValues: initialFormState
  })

  const templateId = watch('templateId')
  const runtimeConfigurationId = watch('runtimeConfigurationId')

  useEffect(() => {
    if (clientId) {
      clearErrors('clientId')
    }
  }, [clientId, clearErrors])

  useEffect(() => {
    const matchingTemplate = templates.find(template => template.templateId === templateId)
    if (!dirtyFields?.reportName && matchingTemplate) {
      setValue('reportName', matchingTemplate?.name)
    }
  }, [setValue, templateId, dirtyFields, templates])

  const currentTemplate = useMemo(() => templates?.find(template => template.templateId === templateId), [templates, templateId])

  // runtime configurations
  const {
    data: runtimeConfigurations,
    loading: isRuntimeConfigurationsLoading,
    refetch: fetchRuntimeConfiguration,
    isStale: isRuntimeConfigurationsStale
  } = useListReportRuntimeConfigurationData({
    templateId: templateId,
    offset: 0,
    limit: 1000,
    sort: ''
  })

  useEffect(() => {
    if (isRuntimeConfigurationsStale) {
      fetchRuntimeConfiguration()
    }
  }, [isRuntimeConfigurationsStale, fetchRuntimeConfiguration])

  useEffect(() => {
    if (templateId &&
      runtimeConfigurations?.length &&
      !runtimeConfigurations.find(runtimeConfig => runtimeConfig.runtimeConfigurationId === runtimeConfigurationId)
    ) {
      const templateDefaultRuntimeConfig = templates.find(template => template.templateId === templateId)?.defaultRuntimeConfigurationId

      // if for some reason it's not in runtime configs then set to first
      if (!runtimeConfigurations.find(runtimeConfig => runtimeConfig.runtimeConfigurationId === runtimeConfigurationId)) {
        setValue('runtimeConfigurationId', runtimeConfigurations[0].runtimeConfigurationId)
      } else {
        setValue('runtimeConfigurationId', templateDefaultRuntimeConfig.runtimeConfigurationId)
      }
    }
  }, [templates, templateId, runtimeConfigurations, runtimeConfigurationId, setValue])

  useEffect(() => {
    reset({
      ...initialFormState,
      templateId: !isEmpty(templates) ? templates[0].templateId : '',
      reportName: !isEmpty(templates) ? templates[0].name : ''
    })
  }, [initialFormState, reset, templates])

  const onSubmitHandler = useCallback(
    async (values) => {
      clearErrors()

      const runtimeConfigurationOverrides = {
        ...(values.billingRun && {
          billingRunId: values.billingRun.billingRunId,
          billingType: values.billingRun.levelType.toLowerCase()
        })
      }

      if (!clientId) {
        return setError('clientId', { type: 'required', message: 'Please select a client' })
      }

      try {
        const body = {
          inputParams: {
            clientId: clientId,
            ...(allowConfigurationSelection && { runtimeConfigurationId: values.runtimeConfigurationId }),
            title: values.reportName,
            startDate: dayjs(asOfDate).format('YYYY-MM-DD'),
            endDate: dayjs(asOfDate).format('YYYY-MM-DD'),
            runtimeConfigurationOverrides
          }
        }

        await runReport(values.templateId, body)
        onSubmit()
        setAlert({
          ...SUCCESS_ALERT,
          alertMessage: 'Run report is in progress',
          alertSeverity: ALERT_SEVERITY.info
        })
      } catch (error) {
        console.error(error)
        setAlert({
          ...ERROR_ALERT,
          alertMessage: 'Something went wrong. Please try again'
        })
        return
      }

      setSearchClients('')
      reset(initialFormState)
    },
    [clearErrors, clientId, reset, initialFormState, setError, allowConfigurationSelection, asOfDate, onSubmit]
  )

  const shouldDisableDate = useCallback(
    (date) => {
      return disableHolidaysAndWeekends(date, holidays)
    },
    [holidays]
  )
  const renderAsOfDateControl = useCallback(
    () => {
      if (!clientId) {
        return (
          <Text
            text='Select a client to get started.'
            variant='body2'
            className={classes.noValuePlaceholder}
          />)
      }
      if (nearestWorkableAsOfDate && !loadingAvailableDates) {
        const onChange = (date) => {
          setAsOfDate(date)
          setHasUserSelectedDate.on()
        }
        return (
          <>
            <KeyboardDatePicker
              onChange={onChange}
              value={asOfDate}
              format='MMMM Do, YYYY'
              defaultDate={asOfDate}
              InputProps={textAreaProps}
              className={classes.itemDateField}
              InputAdornmentProps={{ position: 'start' }}
              maxDate={availableDates?.max ? dayjs(availableDates?.max).add(1, 'day') : defaultMaxDate}
              minDate={availableDates?.min}
              shouldDisableDate={shouldDisableDate}
            />
          </>
        )
      }
      return (
        <Skeleton
          className={classes.itemDateField}
          height='2.3rem'
          width='100%'
        />
      )
    },
    [
      setHasUserSelectedDate,
      clientId,
      nearestWorkableAsOfDate,
      loadingAvailableDates,
      classes.itemDateField,
      classes.noValuePlaceholder,
      asOfDate,
      availableDates,
      shouldDisableDate
    ]
  )

  const renderRuntimeConfigurationControl = useCallback(
    ({ field: { onChange, ...field } }) => {
      if (isRuntimeConfigurationsLoading) {
        return (
          <Skeleton
            className={classes.itemTextField}
            height='2.3rem'
            width='100%'
          />)
      }
      if (!runtimeConfigurations?.length) {
        return (
          <div style={{ marginTop: '.5rem' }}>
            <Text
              text='No configurations are added to this template yet'
              variant='body2'
            />
          </div>
        )
      }
      return (
        <TextField
          select
          fullWidth
          className={classes.itemTextField}
          InputProps={textAreaProps}
          onChange={onChange}
          error={Boolean(errors.runtimeConfigurations)}
          disabled={isTemplatesLoading}
          defaultValue={0}
          {
            ...field
          }
        >
          {runtimeConfigurations.map(({ runtimeConfigurationId, name }) => (
            <MenuItem key={runtimeConfigurationId} value={runtimeConfigurationId}>
              {name}
            </MenuItem>
          ))}
        </TextField>
      )
    }
    ,
    [isRuntimeConfigurationsLoading, runtimeConfigurations, errors.runtimeConfigurations, classes.itemTextField, isTemplatesLoading]
  )

  const renderTemplateControl = useCallback(
    ({ field: { onChange, ...field } }) => {
      if (isTemplatesLoading) {
        return (
          <Skeleton
            className={classes.itemTextField}
            height='2.3rem'
            width='100%'
          />)
      }
      return (
        <TextField
          select
          fullWidth
          className={classes.itemTextField}
          InputProps={textAreaProps}
          onChange={onChange}
          placeholder='Template'
          error={Boolean(errors.templateId)}
          disabled={isTemplatesLoading}
          defaultValue={templates[0]?.templateId}
          {
            ...field
          }
        >
          {templates.map(({ templateId, name }) => (
            <MenuItem key={templateId} value={templateId}>
              {name}
            </MenuItem>
          ))}
        </TextField>
      )
    }
    ,
    [
      errors.templateId,
      classes.itemTextField,
      isTemplatesLoading,
      templates
    ]
  )

  return (
    <div className={classes.container}>
      <form onSubmit={handleSubmit(onSubmitHandler)}>
        <Grid container>
          <Grid item md={4} sm={6} xs={12}>
            <div className={classes.groupItem}>
              <div className={classes.labelWithInfo}>
                <Text text='Template' variant='h3' />
                {allowManage && <Link to={ADMIN_ROUTES.REPORTS_TEMPLATES}>Manage</Link>}
              </div>
              <Controller
                name='templateId'
                render={renderTemplateControl}
                control={control}
                rules={{ required: true }}
              />
              {errors.templateId && (
                <Text
                  text={messageToUse(errors.templateId)}
                  color={theme.palette.error.main}
                  className={classes.confirmError}
                />
              )}
            </div>
          </Grid>

          {allowConfigurationSelection && (
            <Grid item md={4} sm={6} xs={12}>
              <div className={classes.groupItem}>
                <div className={classes.labelWithInfo}>
                  <Text text='Runtime Configuration' variant='h3' />
                  <Link to={ADMIN_ROUTES.REPORTS_TEMPLATES_RUNTIME_CONFIGURATIONS}>Manage</Link>
                </div>
                <Controller
                  name='runtimeConfigurationId'
                  render={renderRuntimeConfigurationControl}
                  control={control}
                />
                {errors.runtimeConfigurationId && (
                  <Text
                    text={messageToUse(errors.runtimeConfigurationId)}
                    color={theme.palette.error.main}
                    className={classes.confirmError}
                  />
                )}
              </div>
            </Grid>
          )}

          <Grid item md={4} sm={6} xs={12}>
            <RuntimeConfigOverrides
              errors={errors}
              classes={classes}
              currentTemplate={currentTemplate}
              values={watch()}
              onChange={({ key, value }) => setValue(key, value)}
            />
          </Grid>

          <Grid item md={allowConfigurationSelection ? 8 : 4} sm={6} xs={12}>
            <div className={classes.groupItem}>
              <Text text='Select Client' variant='h3' />
              <AutoCompleteSearchInput
                embeddedInput
                autoFocus={false}
                startAsOpen={false}
                recentOptionTitleKey='shortName'
                recentOptionValueKey='clientId'
                optionTitleKey='shortName'
                optionValueKey='clientId'
                placeholder='Search for client'
                primaryButtonLabel='View all Clients'
                options={clients}
                loading={isLoadingClients}
                onChange={onSearchClients}
                onSelectOption={(client) => setClientId(client.clientId)}
                contentClassName={classes.searchContent}
                inputClassName={classes.searchInput}
                className={classes.searchContainer}
                width='100%'
              />
              {errors.clientId && (
                <>
                  <Text
                    text={errors.clientId?.message || 'Please select a client'}
                    color={theme.palette.error.main}
                  />
                </>
              )}
            </div>
          </Grid>

          <Grid item md={4} sm={6} xs={6}>
            <div className={classes.groupItem}>
              <Text text='As of date' variant='h3' />
              <Controller
                name='asOfDate'
                control={control}
                value={asOfDate}
                render={renderAsOfDateControl}
              />
              {errors.endDate && (
                <Text
                  text={errors.endDate.message || messageToUse(errors.endDate)}
                  color={theme.palette.error.main}
                  className={classes.confirmError}
                />
              )}
            </div>
          </Grid>

          <Grid item md={allowConfigurationSelection ? 12 : 8} sm={12}>
            <div className={classes.groupItem}>
              <Text text='Report Name' variant='h3' />
              <Controller
                name='reportName'
                render={({ field: { onChange } }) => (
                  <TextField
                    fullWidth
                    autoFocus
                    type='input'
                    placeholder='Enter Report Name'
                    className={classes.itemTextField}
                    InputProps={textAreaProps}
                    onChange={onChange}
                    error={Boolean(errors.reportName)}
                    {...register('reportName', { required: true })}
                  />)}
                control={control}
                rules={{ required: true }}
              />
              {errors.reportName && (
                <Text
                  text={messageToUse(errors.reportName)}
                  color={theme.palette.error.main}
                  className={classes.confirmError}
                />
              )}
            </div>
          </Grid>

          <Grid item md={12}>
            <div className={classes.buttonGroup}>
              <div className={classes.buttonGroupItem}>
                <RoundedButton
                  primary
                  type='submit'
                  size={BUTTON_SIZES.medium}
                  disabled={isSubmitting || isTemplatesLoading}
                  isLoading={isSubmitting}
                  fullWidth
                >
                  Run Report
                </RoundedButton>
              </div>
            </div>
          </Grid>
        </Grid>
      </form>
      <SnackAlert alert={alert} />
    </div>
  )
}

RunReportForm.propTypes = {
  isTemplatesLoading: PropTypes.bool.isRequired,
  templates: PropTypes.any.isRequired,
  onSubmit: PropTypes.func,
  allowManage: PropTypes.bool,
  allowConfigurationSelection: PropTypes.bool
}

RunReportForm.defaultProps = {
  onSubmit: noop,
  allowManage: false,
  allowConfigurationSelection: false
}

export default RunReportForm
