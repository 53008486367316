import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { noop } from 'lodash'
import { useSyncWithUrlParams } from '../../hooks'
import { localStorageHelper } from '../../utils/localStorageHelper'
import { useAppContext } from '../../redux/slices/appContext'

const FilterSubscriptionContext = createContext({
  filters: undefined,
  resetFilters: noop,
  setFilters: noop,
  initialFilterState: {}
})

/**
 * key used to store filters state in local storage; if not provided, local storage will be skipped
 * @param {string} configurationStorageKey
 */
const FilterSubscriptionProvider = ({
  children,
  initialState,
  configurationStorageKey
}) => {
  const { clientId } = useAppContext()
  const { urlSearchParams, replaceUrlSearchParams } = useSyncWithUrlParams()

  const localStorageState = configurationStorageKey
    ? localStorageHelper.load(configurationStorageKey)
    : null

  const defaultState = urlSearchParams ?? localStorageState

  const [filters, setFilters] = useState({ ...initialState, ...defaultState, clientId })
  const [initialFilters, setInitialFilters] = useState(initialState)

  useEffect(() => {
    replaceUrlSearchParams(filters)
    if (configurationStorageKey) {
      localStorageHelper.store(configurationStorageKey, filters)
    }
  }, [filters, replaceUrlSearchParams, configurationStorageKey])

  const resetFilters = useCallback(() => {
    setFilters({ ...initialState })
    setInitialFilters({ ...initialState })
  }, [initialState])

  const value = useMemo(
    () => ({
      filters,
      resetFilters,
      setFilters,
      initialFilterState: initialFilters,
      configStorageKey: configurationStorageKey
    }),
    [
      filters,
      resetFilters,
      initialFilters,
      configurationStorageKey
    ]
  )

  return (
    <FilterSubscriptionContext.Provider value={value}>
      {children}
    </FilterSubscriptionContext.Provider>
  )
}

export const useFilterSubscriptionContext = () => {
  const context = useContext(FilterSubscriptionContext)
  return context
}

FilterSubscriptionProvider.propTypes = {
  children: PropTypes.node.isRequired,
  initialState: PropTypes.object,
  configurationStorageKey: PropTypes.string
}

FilterSubscriptionProvider.defaultProps = {
  initialState: {},
  configurationStorageKey: undefined
}

export default FilterSubscriptionProvider
