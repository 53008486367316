import { Box, Grid } from '@material-ui/core'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { ResponsiveLine } from '@nivo/line'
import { useDynamicPlanning } from '../DynamicPlanningContext'
import { TEXT_VARIANTS } from '../../../../constants'
import Text from '../../../atoms/Text'
import { getLineChartData, runSimulation } from './utils'
import PlanLineGraphSkeleton from './PlanLineGraphSkeleton'
import MonteCarloData from './MonteCarloData'

const DynamicPlanningPLanLineChart = () => {
  const {
    activeGoals,
    clientBalance,
    displayDates,
    currentPlan,
    linkedPlan,
    linkedPlanActiveGoals,
    isLoadingData
  } = useDynamicPlanning()

  const [monteCarloData, setMonteCarloData] = useState(null)
  const [linkedMonteCarloData, setLinkedMonteCarloData] = useState(null)
  const [lineChartData, setLineChartData] = useState([])
  const [isProcessing, setIsProcessing] = useState(false)
  const timerRef = useRef(0)

  const calcParams = useMemo(() => {
    if (!currentPlan?.assumptions || isLoadingData) {
      return null
    }
    return {
      currentPlan,
      activeGoals,
      clientBalance,
      displayDates,
      linkedPlan,
      linkedPlanActiveGoals
    }
  }, [currentPlan, isLoadingData, activeGoals, clientBalance, displayDates, linkedPlan, linkedPlanActiveGoals])

  const updateMonteCarlo = useCallback(async (calcParams) => {
    if (!calcParams) {
      return
    }
    const [primaryResult, linkedResult] = await Promise.all([
      runSimulation(calcParams),
      linkedPlan && runSimulation({
        ...calcParams,
        currentPlan: linkedPlan,
        activeGoals: linkedPlanActiveGoals
      })
    ])

    setMonteCarloData(primaryResult)
    setLinkedMonteCarloData(linkedResult)
  }, [linkedPlan, linkedPlanActiveGoals])

  const updateLineChartData = useCallback((calcParams) => {
    setLineChartData(getLineChartData(calcParams, 'year'))
  }, [setLineChartData])

  const debouncedData = useCallback((calcParams) => {
    clearTimeout(timerRef.current)
    setIsProcessing(true)
    timerRef.current = setTimeout(async () => {
      if (!calcParams) return
      await updateMonteCarlo(calcParams)
      setIsProcessing(false)
    }, 500)
    if (!calcParams) return
    updateLineChartData(calcParams)
  }, [updateLineChartData, updateMonteCarlo])

  useEffect(() => {
    if (!calcParams) {
      setLineChartData([])
      setMonteCarloData(null)
    } else {
      debouncedData(calcParams)
    }
  }, [calcParams, debouncedData])

  const maxXAxis = useMemo(() => {
    if (!currentPlan?.assumptions?.portfolioGrowthModel) return 0
    console.log(lineChartData)
    const allValues = [0]
    lineChartData.forEach(series => {
      series.data.forEach(point => {
        allValues.push(point.y)
      })
    })
    console.log(Math.max(...allValues) * 1.1, allValues)
    return Math.max(...allValues) * 1.1
    // const { assumptions: { portfolioGrowthModel } } = currentPlan
    // const { assumptions: { portfolioGrowthModel: linkedPortfolioGrowthModel } } = linkedPlan || { assumptions: {} }
    // return Math.max(
    //   clientBalance * (1 + portfolioGrowthModel.growthFactor) ** 40,
    //   linkedPortfolioGrowthModel ? clientBalance * (1 + linkedPortfolioGrowthModel.growthFactor) ** 40 : 0
    // )
  }, [currentPlan, lineChartData])
  return (
    <>
      <div style={{ minHeight: '450px' }}>
        {
          isLoadingData ? (
            <PlanLineGraphSkeleton />
          )
            : (
              <div>
                <Grid item xs={12} style={{ height: '450px', width: '100%' }}>
                  <ResponsiveLine
                    animate
                    motionConfig='slow'
                    data={lineChartData}
                    margin={{ top: 30, right: 30, bottom: 30, left: 45 }}
                    curve='monotoneX'
                    xScale={{
                      type: 'time',
                      format: '%Y-%m-%d',
                      precision: 'hour',
                      useUTC: false
                    }}
                    xFormat='time:%Y'
                    yScale={{
                      type: 'linear',
                      stacked: false,
                      min: 0,
                      max: maxXAxis
                    }}
                    yFormat='.2s'
                    axisLeft={{
                      legend: 'portfolio value',
                      legendOffset: 12,
                      format: '.2s',
                      tickValues: 3
                    }}
                    axisBottom={{
                      format: '%Y',
                      legend: '',
                      legendOffset: -12
                    }}
                    layers={['grid', 'markers', 'axes', 'areas', 'crosshair', 'lines', 'points', 'slices', 'mesh', 'legends']}
                    colors={d => d.color}
                    lineWidth={0}
                    enableArea
                    enableGridX={false}
                    pointSize={0}
                    gridYValues={3}
                    useMesh
                    tooltip={point => (
                      <>
                        <Text variant={TEXT_VARIANTS.h3}>
                          {point.point.serieId.split('_')[1]}
                        </Text>
                        <Text variant={TEXT_VARIANTS.h4}>
                          {point.point.data.xFormatted}: ${point.point.data.yFormatted}
                        </Text>
                      </>
                    )}
                    theme={{
                      grid: {
                        line: {
                          stroke: '#E2E2E2',
                          strokeWidth: 1
                        }
                      },
                      axis: {
                        legend: {
                          text: {
                            fontFamily: 'GothamPro',
                            fontWeight: 700
                          }
                        },
                        ticks: {
                          text: {
                            fontFamily: 'GothamPro',
                            fontWeight: 700
                          }
                        }
                      }
                    }}
                  />
                </Grid>
                <Box
                  margin='-7rem 3rem 0 3rem'
                  position='relative'
                  textAlign='right'
                  zIndex={10}
                  display='flex'
                  justifyContent='flex-end'
                >
                  <MonteCarloData
                    primaryData={monteCarloData}
                    secondaryData={linkedMonteCarloData}
                    isProcessing={isProcessing}
                  />
                </Box>
              </div>
            )
        }
      </div>
    </>
  )
}

export default DynamicPlanningPLanLineChart
