import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Controller, useForm } from 'react-hook-form'
import dayjs from 'dayjs'
import isEmpty from 'lodash/isEmpty'
import quarterOfYear from 'dayjs/plugin/quarterOfYear'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import { Grid, makeStyles, MenuItem, TextField, useTheme } from '@material-ui/core'
import { KeyboardDatePicker } from '@material-ui/pickers'
import noop from 'lodash/noop'
import { useQuery } from '@tanstack/react-query'
import { Autocomplete, createFilterOptions } from '@material-ui/lab'
import { ADMIN_ROUTES, ALERT_SEVERITY, BUTTON_SIZES, ERROR_ALERT, SUCCESS_ALERT } from '../../../../../constants'
import Text from '../../../../atoms/Text'
import RoundedButton from '../../../../atoms/RoundedButton'
import SnackAlert from '../../../../molecules/SnackAlert/SnackAlert'
import { messageToUse } from '../../common'
import { getNearestWorkableAsOfDate } from '../../billing/runBilling/helpers'
import { useHolidays } from '../../../../../api/coreData'
import Skeleton from '../../../../atoms/Skeleton'
import { disableHolidaysAndWeekends, pluralize } from '../../../../../utils'
import { useBoolean } from '../../../../../hooks'
import { useListReportRuntimeConfigurationData } from '../hooks'
import { fetchClients, runReportBatch } from '../../../../../service'
import { QUERY_KEYS } from '../../../../../api/queryKeys'
import RuntimeConfigOverrides from '../ConfigOverrides'

dayjs.extend(isSameOrAfter)
dayjs.extend(quarterOfYear)

const useStyles = makeStyles(() => ({
  container: {
    padding: '0',
    height: '100%',
    display: 'flex',
    flex: '1',
    flexDirection: 'column',
    margin: '0 -1rem'
  },
  confirmError: {
    textAlign: 'left',
    marginTop: '0.375rem'
  },
  groupItem: {
    width: '100%',
    padding: '1rem'
  },
  buttonGroupItem: {
    display: 'flex',
    width: '20rem',
    maxWidth: '100%',
    padding: '0 1rem'
  },
  searchContent: {
    border: '1px solid #F4F5F6',
    borderRadius: '4px !important',
    height: 'auto',
    flexWrap: 'wrap'
  },
  searchInput: {
    backgroundColor: '#F4F5F6',
    padding: '0.625rem'
  },
  searchContainer: {
    height: '3rem'
  },
  itemTextField: {
    backgroundColor: '#F4F5F6',
    borderRadius: '4px',
    paddingTop: '0.625rem',
    paddingLeft: '0.625rem',
    marginTop: '0.625rem'
  },
  clientTextField: {
    backgroundColor: '#F4F5F6',
    borderRadius: '4px',
    paddingTop: '0.125rem',
    paddingBottom: '0.125rem',
    paddingLeft: '0.625rem',
    marginTop: '0.125rem'
  },
  clientTextFieldContainer: {
    '& .MuiAutocomplete-tag': {
      fontSize: '.8rem',
      height: 'auto'
    },
    '& .MuiChip-label': {
      padding: '0 .8em'
    },
    '& .MuiChip-deleteIcon': {
      width: '.7em',
      marginRight: 0
    },
    '& .MuiInputBase-input': {
      width: '100%'
    }
  },
  noValuePlaceholder: {
    backgroundColor: '#F4F5F6',
    borderRadius: '4px',
    padding: '0.625rem',
    marginTop: '0.625rem',
    color: '#666'
  },
  multilineItemTextField: {
    backgroundColor: '#F4F5F6',
    borderRadius: '4px',
    paddingTop: '0.625rem',
    paddingLeft: '0.625rem',
    marginTop: '0.625rem',
    marginBottom: '0.625rem',
    minHeight: '8rem'
  },
  itemDateField: {
    borderRadius: '4px',
    backgroundColor: '#F4F5F6',
    marginTop: '0.625rem',
    width: '100%',
    height: '3.0em',
    paddingTop: '0.5rem'
  },
  disabledButton: {
    cursor: 'not-allowed !important',
    backgroundColor: '#7f8299'
  },
  buttonGroup: {
    display: 'flex',
    flexWrap: 'wrap',
    alignContent: 'flex-end',
    justifyContent: 'flex-end',
    paddingBottom: '10px'
  },
  labelWithInfo: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    gap: '1rem'
  },
  labelActions: {
    display: 'flex',
    gap: '.5rem',
    position: 'relative',
    top: '-2px'
  },
  labelInfo: {
    opacity: '.8'
  },
  taggedSelectOption: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '1rem',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    padding: '8px',
    marginBottom: '-6px'
  },
  taggedSelectMenu: {
    padding: 0
  },
  taggedSelectMenuItem: {
    display: 'block'
  },
  taggedSelectOptionTags: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '.5rem',
    alignItems: 'center'
  }
}))

const textAreaProps = { disableUnderline: true }
const defaultAsOfDate = dayjs().subtract(1, 'quarter').endOf('quarter')
const defaultMaxDate = dayjs().subtract(1, 'day')

const CreateBatchForm = ({
  templates,
  isTemplatesLoading,
  onSubmit,
  allowManage,
  allowConfigurationSelection
}) => {
  const classes = useStyles()
  const theme = useTheme()
  const [alert, setAlert] = useState({})

  const [availableDates] = useState(dayjs().subtract(1, 'day'))
  const [loadingAvailableDates] = useBoolean(false)

  const initialFormState = useMemo(() => {
    return {
      templateId: !isEmpty(templates) ? templates[0].templateId : '',
      runtimeConfigurationId: null,
      clients: [],
      reportName: '',
      batchTitle: '',
      asOfDate: defaultAsOfDate,
      billingRun: null
    }
  }, [templates])

  const {
    handleSubmit,
    control,
    register,
    reset,
    clearErrors,
    watch,
    setValue,
    getValues,
    formState: { errors, isSubmitting, dirtyFields }
  } = useForm({
    mode: 'onChange',
    defaultValues: initialFormState
  })

  const templateId = watch('templateId')
  const clientsValue = watch('clients')
  const asOfDateValue = watch('asOfDate')

  const { data: holidays } = useHolidays()
  const nearestWorkableAsOfDate = useMemo(
    () => getNearestWorkableAsOfDate(holidays, asOfDateValue),
    [holidays, asOfDateValue]
  )

  useEffect(() => {
    if (nearestWorkableAsOfDate && nearestWorkableAsOfDate !== asOfDateValue) {
      setValue('asOfDate', nearestWorkableAsOfDate)
    }
  }, [nearestWorkableAsOfDate, asOfDateValue, setValue])

  useEffect(() => {
    const matchingTemplate = templates.find(template => template.templateId === templateId)
    if (!dirtyFields?.reportName && matchingTemplate) {
      setValue('reportName', matchingTemplate?.name)
    }
  }, [setValue, templateId, dirtyFields, templates])

  // runtime configurations
  const {
    data: runtimeConfigurations,
    loading: isRuntimeConfigurationsLoading,
    refetch: fetchRuntimeConfiguration,
    isStale: isRuntimeConfigurationsStale
  } = useListReportRuntimeConfigurationData({
    templateId: templateId,
    offset: 0,
    limit: 1000,
    sort: ''
  })

  useEffect(() => {
    if (isRuntimeConfigurationsStale) {
      fetchRuntimeConfiguration()
    }
  }, [isRuntimeConfigurationsStale, fetchRuntimeConfiguration])

  useEffect(() => {
    reset({
      ...initialFormState,
      templateId: !isEmpty(templates) ? templates[0].templateId : 0,
      reportName: !isEmpty(templates) ? templates[0].name : ''
    })
  }, [initialFormState, reset, templates])

  useEffect(() => {
    const selectedTemplate = templates.find(template => template.templateId === templateId)
    if (selectedTemplate) {
      setValue('runtimeConfigurationId', selectedTemplate.defaultRuntimeConfigurationId)
    }
  }, [templates, templateId, setValue])

  const { data: clients, isLoading: isClientsLoading } = useQuery({
    enabled: true,
    queryKey: [QUERY_KEYS.reportsBatches, 'clients'],
    queryFn: async () => {
      const { data } = await fetchClients()
      return data
    }
  })

  const onSubmitHandler = useCallback(
    async (values) => {
      clearErrors()

      try {
        const runtimeConfigurationId = isNaN(values.runtimeConfigurationId) || !allowConfigurationSelection ? null : values.runtimeConfigurationId

        const runtimeConfigurationOverrides = {
          ...(values.billingRun && {
            billingRunId: values.billingRun.billingRunId,
            billingType: values.billingRun.levelType.toLowerCase()
          })
        }

        const clientIds = values.clients?.map(row => row.clientId)?.filter(row => row) ?? []
        const body = {
          clientIds,
          batchTitle: values.batchTitle,
          inputParams: {
            runtimeConfigurationId,
            runtimeConfigurationOverrides,
            title: values.reportName,
            startDate: dayjs(values.asOfDate).format('YYYY-MM-DD'),
            endDate: dayjs(values.asOfDate).format('YYYY-MM-DD')
          }
        }

        await runReportBatch(values.templateId, body)
        onSubmit()
        setValue('clients', [])
        setAlert({
          ...SUCCESS_ALERT,
          alertMessage: 'Run report is in progress',
          alertSeverity: ALERT_SEVERITY.info
        })
      } catch (error) {
        console.error(error)
        setAlert({
          ...ERROR_ALERT,
          alertMessage: 'Something went wrong. Please try again'
        })
        return
      }

      reset(initialFormState)
    },
    [allowConfigurationSelection, clearErrors, reset, initialFormState, onSubmit, setValue]
  )

  const shouldDisableDate = useCallback(
    (date) => {
      return disableHolidaysAndWeekends(date, holidays)
    },
    [holidays]
  )
  const renderAsOfDateControl = useCallback(
    () => {
      if (nearestWorkableAsOfDate && !loadingAvailableDates) {
        const onChange = (date) => {
          setValue('asOfDate', date)
        }
        return (
          <>
            <KeyboardDatePicker
              onChange={onChange}
              value={getValues('asOfDate')}
              InputProps={textAreaProps}
              className={classes.itemDateField}
              InputAdornmentProps={{ position: 'start' }}
              maxDate={availableDates?.max ?? defaultMaxDate}
              minDate={availableDates?.min}
              shouldDisableDate={shouldDisableDate}
            />
          </>
        )
      }
      return (
        <Skeleton
          className={classes.itemDateField}
          height='2.3rem'
          width='100%'
        />
      )
    },
    [
      nearestWorkableAsOfDate,
      loadingAvailableDates,
      classes.itemDateField,
      getValues,
      availableDates,
      shouldDisableDate,
      setValue
    ]
  )

  const renderClientSelect = useCallback(({ field: { onChange, ...field } }) => {
    const onChangeAutocomplete = (_, value) => {
      onChange(value)
    }
    const autoCompleteFilter = createFilterOptions()

    return (
      <div>
        <Autocomplete
          multiple
          fullWidth
          onChange={onChangeAutocomplete}
          placeholder='Clients'
          error={Boolean(errors.clients)}
          disabled={isClientsLoading}
          options={isClientsLoading ? [] : clients}
          getOptionSelected={(opt, val) => (opt.clientId === val.clientId)}
          filterSelectedOptions
          getOptionLabel={(option) => option.shortName}
          renderOption={(option) => (
            <Text text={option?.longName || option?.shortName} />)}
          filterOptions={(options, params) => autoCompleteFilter(options, params)}
          className={classes.clientTextFieldContainer}
          disableCloseOnSelect
          renderInput={(params) => {
            params.InputProps.disableUnderline = true
            return (
              <TextField
                {...params}
                fullWidth
                className={classes.clientTextField}
                placeholder='Search clients'
              />
            )
          }}
          {...field}
        />
      </div>
    )
  }, [errors.clients, isClientsLoading, clients, classes.clientTextFieldContainer, classes.clientTextField])

  const renderRuntimeConfigurationControl = useCallback(
    ({ field: { onChange, ...field } }) => {
      if (isRuntimeConfigurationsLoading) {
        return (
          <Skeleton
            className={classes.itemTextField}
            height='2.3rem'
            width='100%'
          />)
      }
      if (!runtimeConfigurations?.length) {
        return (
          <div style={{ marginTop: '.5rem' }}>
            <Text
              text='No configurations are added to this template yet'
              variant='body2'
            />
          </div>
        )
      }
      return (
        <TextField
          select
          fullWidth
          className={classes.itemTextField}
          InputProps={textAreaProps}
          onChange={onChange}
          error={Boolean(errors.runtimeConfigurations)}
          disabled={isTemplatesLoading}
          defaultValue={0}
          {
            ...field
          }
        >
          {runtimeConfigurations.map(({ runtimeConfigurationId, name }) => (
            <MenuItem key={runtimeConfigurationId} value={runtimeConfigurationId}>
              {name}
            </MenuItem>
          ))}
        </TextField>
      )
    }
    ,
    [isRuntimeConfigurationsLoading, runtimeConfigurations, errors.runtimeConfigurations, classes.itemTextField, isTemplatesLoading]
  )

  const renderTemplateControl = useCallback(
    ({ field: { onChange, ...field } }) => {
      if (isTemplatesLoading) {
        return (
          <Skeleton
            className={classes.itemTextField}
            height='2.3rem'
            width='100%'
          />)
      }
      return (
        <TextField
          select
          fullWidth
          className={classes.itemTextField}
          InputProps={textAreaProps}
          onChange={onChange}
          placeholder='Template'
          error={Boolean(errors.templateId)}
          disabled={isTemplatesLoading}
          defaultValue={templates ? templates[0]?.templateId : 1}
          {
            ...field
          }
        >
          {templates.map(({ templateId, name }) => (
            <MenuItem key={templateId} value={templateId}>
              {name}
            </MenuItem>
          ))}
        </TextField>
      )
    }
    ,
    [
      errors.templateId,
      classes.itemTextField,
      isTemplatesLoading,
      templates
    ]
  )

  const clientIdCount = useMemo(() => clientsValue?.length ?? 0, [clientsValue])
  const isFormLoading = useMemo(() => isSubmitting || isTemplatesLoading || isClientsLoading, [isSubmitting, isTemplatesLoading, isClientsLoading])
  const currentTemplate = useMemo(() => templates?.find(template => template.templateId === templateId), [templates, templateId])

  return (
    <div className={classes.container}>
      <form onSubmit={handleSubmit(onSubmitHandler)}>
        <Grid container>
          <Grid item md={allowConfigurationSelection ? 4 : 6} sm={6} xs={12}>
            <div className={classes.groupItem}>
              <div className={classes.labelWithInfo}>
                <Text text='Template' variant='h3' />
                {allowManage && <Link to={ADMIN_ROUTES.REPORTS_TEMPLATES}>Manage</Link>}
              </div>
              <Controller
                name='templateId'
                render={renderTemplateControl}
                control={control}
                rules={{ required: true }}
              />
              {errors.templateId && (
                <Text
                  text={messageToUse(errors.templateId)}
                  color={theme.palette.error.main}
                  className={classes.confirmError}
                />
              )}
            </div>
          </Grid>

          {allowConfigurationSelection && (
            <Grid item md={4} sm={6} xs={12}>
              <div className={classes.groupItem}>
                <div className={classes.labelWithInfo}>
                  <Text text='Runtime Configuration' variant='h3' />
                  <Link to={ADMIN_ROUTES.REPORTS_TEMPLATES_RUNTIME_CONFIGURATIONS}>Manage</Link>
                </div>
                <Controller
                  name='runtimeConfigurationId'
                  render={renderRuntimeConfigurationControl}
                  control={control}
                />
                {errors.runtimeConfigurationId && (
                  <Text
                    text={messageToUse(errors.runtimeConfigurationId)}
                    color={theme.palette.error.main}
                    className={classes.confirmError}
                  />
                )}
              </div>
            </Grid>
          )}

          <Grid item md={4} sm={6} xs={12}>
            <RuntimeConfigOverrides
              errors={errors}
              classes={classes}
              currentTemplate={currentTemplate}
              values={watch()}
              onChange={({ key, value }) => setValue(key, value)}
            />
          </Grid>

          <Grid item md={12} sm={12} xs={12}>
            <div className={classes.groupItem}>
              <div className={classes.labelWithInfo}>
                <div className={classes.labelWithInfo}>
                  <Text text='Select Clients' variant='h3' />
                  <span
                    className={classes.labelInfo}
                  >
                    {!clientIdCount
                      ? 'No Clients Selected'
                      : `${pluralize(clientIdCount, `${clientIdCount} Client`)} Selected`}
                  </span>
                </div>
                <div className={classes.labelActions}>
                  <RoundedButton
                    primary
                    type='button'
                    size={BUTTON_SIZES.extraSmall}
                    disabled={isFormLoading || !clientIdCount}
                    isLoading={isSubmitting || isClientsLoading}
                    onClick={() => setValue('clients', [])}
                  >
                    {isClientsLoading ? 'Loading...' : 'Clear'}
                  </RoundedButton>

                  <RoundedButton
                    primary
                    type='button'
                    size={BUTTON_SIZES.extraSmall}
                    disabled={isFormLoading || clientIdCount >= clients.length}
                    isLoading={isSubmitting || isClientsLoading}
                    onClick={() => setValue('clients', clients)}
                  >
                    {isClientsLoading ? 'Loading...' : 'Add all clients'}
                  </RoundedButton>
                </div>
              </div>
              <Controller
                name='clients'
                render={renderClientSelect}
                control={control}
                rules={{ required: true }}
              />
              {errors.clients && (
                <>
                  <Text
                    text={messageToUse(errors.clients)}
                    color={theme.palette.error.main}
                  />
                </>
              )}
            </div>
          </Grid>

          <Grid item md={4} sm={12} xs={12}>
            <div className={classes.groupItem}>
              <Text text='As of date' variant='h3' />
              <Controller
                name='asOfDate'
                control={control}
                value={getValues('asOfDate')}
                render={renderAsOfDateControl}
              />
              {errors.endDate && (
                <Text
                  text={errors.endDate.message || messageToUse(errors.endDate)}
                  color={theme.palette.error.main}
                  className={classes.confirmError}
                />
              )}
            </div>
          </Grid>

          <Grid item md={4} sm={12} xs={12}>
            <div className={classes.groupItem}>
              <Text text='Batch Name' variant='h3' />
              <Controller
                name='batchTitle'
                render={({ field: { onChange } }) => (
                  <TextField
                    fullWidth
                    autoFocus
                    type='input'
                    placeholder='Enter Batch Name'
                    className={classes.itemTextField}
                    InputProps={textAreaProps}
                    onChange={onChange}
                    error={Boolean(errors.batchTitle)}
                    {...register('batchTitle', { required: true })}
                  />)}
                control={control}
                rules={{ required: true }}
                style={{ marginTop: '5px' }}
              />
              {errors.batchTitle && (
                <Text
                  text={messageToUse(errors.batchTitle)}
                  color={theme.palette.error.main}
                  className={classes.confirmError}
                />
              )}
            </div>
          </Grid>

          <Grid item md={4} sm={12}>
            <div className={classes.groupItem}>
              <Text text='Report Name' variant='h3' />
              <Controller
                name='reportName'
                render={({ field: { onChange } }) => (
                  <TextField
                    fullWidth
                    autoFocus
                    type='input'
                    placeholder='Enter Report Name'
                    className={classes.itemTextField}
                    InputProps={textAreaProps}
                    onChange={onChange}
                    error={Boolean(errors.reportName)}
                    {...register('reportName', { required: true })}
                  />)}
                control={control}
                rules={{ required: true }}
              />
              {errors.reportName && (
                <Text
                  text={messageToUse(errors.reportName)}
                  color={theme.palette.error.main}
                  className={classes.confirmError}
                />
              )}
            </div>
          </Grid>

          <Grid item md={12}>
            <div className={classes.buttonGroup}>
              <div className={classes.buttonGroupItem}>
                <RoundedButton
                  primary
                  type='submit'
                  size={BUTTON_SIZES.medium}
                  disabled={isFormLoading}
                  isLoading={isSubmitting}
                  fullWidth
                >
                  Run Report Batch
                </RoundedButton>
              </div>
            </div>
          </Grid>
        </Grid>
      </form>
      <SnackAlert alert={alert} />
    </div>
  )
}

CreateBatchForm.propTypes = {
  isTemplatesLoading: PropTypes.bool.isRequired,
  templates: PropTypes.any.isRequired,
  onSubmit: PropTypes.func,
  allowManage: PropTypes.bool,
  allowConfigurationSelection: PropTypes.bool
}

CreateBatchForm.defaultProps = {
  onSubmit: noop,
  allowManage: false,
  allowConfigurationSelection: false
}

export default CreateBatchForm
