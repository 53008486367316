import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { Box, makeStyles, useTheme } from '@material-ui/core'
import {
  BALANCE_CHANGE_STATUS,
  SIZE_VARIANTS,
  TEXT_VARIANTS
} from '../../constants'
import { numeralByCase, tableNumberFormatter } from '../../utils'
import Avatar from '../atoms/Avatar'
import NumberFormat from '../atoms/NumberFormat'
import Text from '../atoms/Text'
import ListItem from './ListItem'

const useStyles = makeStyles(() => ({
  container: {
    width: '100%'
  },
  listContainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  itemContainer: {
    display: 'flex'
  },
  itemTitleContainer: {
    alignItems: 'flex-start'
  }
}))

const getBalanceChangeStatusColorAndPrefix = (palette, balanceChangeStatus) => {
  switch (balanceChangeStatus) {
    case BALANCE_CHANGE_STATUS.positive:
      return { color: palette.success.main, prefix: '+' }
    case BALANCE_CHANGE_STATUS.negative:
      return { color: palette.error.main, prefix: '' }
    default:
      return { color: palette.gray.A700, prefix: '' }
  }
}

const AssetHoldingList = ({ title, assetHoldings, rightElementCustomFont }) => {
  const classes = useStyles()
  const theme = useTheme()

  const renderBalanceChangeText = useCallback(
    (balanceChange, balanceChangeStatus) => {
      const { color, prefix } = getBalanceChangeStatusColorAndPrefix(
        theme.palette,
        balanceChangeStatus
      )
      return (
        <Text
          text={
            <NumberFormat
              number={`${prefix}${tableNumberFormatter(balanceChange.value)}`}
              title={numeralByCase(balanceChange.value)}
              skipFormat
            />
          }
          customFontSize={rightElementCustomFont}
          color={color}
          customFontWeight='bold'
          customFontFamily='Gotham-Book'
          variant={TEXT_VARIANTS.caption}
        />
      )
    },
    [theme, rightElementCustomFont]
  )

  return (
    <div className={classes.container}>
      <Text
        color={theme.palette.gray.A700}
        text={title}
        variant={TEXT_VARIANTS.subtitle1}
        customFontFamily='Gotham-Book'
        customFontWeight='bold'
      />
      <div className={classes.listContainer}>
        {assetHoldings.map((asset, index) => {
          return (
            <div key={asset.id} className={classes.itemContainer}>
              <Box ml={0} m={1}>
                <Avatar
                  size={SIZE_VARIANTS.small}
                  avatarLetters={asset.name}
                  useOneInitial
                />
              </Box>
              <ListItem
                noBorderBottom={index === assetHoldings.length - 1}
                additionalClasses={classes.itemTitleContainer}
                leftElement={
                  <div>
                    <Text
                      text={asset.name}
                      customFontWeight='bold'
                      customFontSize='0.875rem'
                      customFontFamily='Gotham-Book'
                    />
                    <div>
                      <Text
                        customFontFamily='Gotham-Book'
                        text={asset.symbol}
                        variant={TEXT_VARIANTS.caption}
                      />
                    </div>
                  </div>
                }
                rightElement={
                  <Box display='flex' flexDirection='column' alignItems='end'>
                    <Text
                      text={
                        <NumberFormat
                          number={tableNumberFormatter(asset.balance.value)}
                          title={numeralByCase(asset.balance.value)}
                          skipFormat
                        />
                      }
                      customFontFamily='Gotham-Book'
                      customFontWeight='bold'
                      customFontSize={rightElementCustomFont}
                      variant={TEXT_VARIANTS.caption}
                    />
                    {renderBalanceChangeText(
                      asset.balanceChange,
                      asset.balanceChangeStatus
                    )}
                  </Box>
                }
              />
            </div>
          )
        })}
      </div>
    </div>
  )
}

AssetHoldingList.propTypes = {
  title: PropTypes.string,
  rightElementCustomFont: PropTypes.string,
  assetHoldings: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    symbol: PropTypes.string,
    percentage: PropTypes.shape({
      value: PropTypes.number,
      format: PropTypes.string,
      tooltipFormat: PropTypes.string,
      digits: PropTypes.number
    }),
    balance: PropTypes.shape({
      value: PropTypes.number
    }),
    balanceChange: PropTypes.shape({
      value: PropTypes.number
    }),
    balanceChangeStatus: PropTypes.oneOf(Object.values(BALANCE_CHANGE_STATUS))
  }))
}

AssetHoldingList.defaultProps = {
  assetHoldings: [],
  title: '',
  rightElementCustomFont: undefined
}

export default AssetHoldingList
