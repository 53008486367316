import React, { useCallback, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Line } from '@nivo/line'
import { makeStyles } from '@material-ui/core/styles'
import uniq from 'lodash/uniq'
import isEmpty from 'lodash/isEmpty'
import compact from 'lodash/compact'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import AutoSizer from 'react-virtualized-auto-sizer'
import isArray from 'lodash/isArray'
import noop from 'lodash/noop'
import quarterOfYear from 'dayjs/plugin/quarterOfYear'
import { isNullOrUndefined, modifyByIndex } from '../../utils'
import useWindowResize from '../../hooks/useWindowResize'
import { NIVO_TOOLTIP_FORMATTERS, CHART_CURVE_INTERPOLATIONS, CHART_SCALE_TYPES, DATE_TYPES } from '../../constants'
import { axisShape, chartRootShape, chartRootShapeDefaults, legendType, themeShape } from '../../prop-types/nivoCharts'
import { useFormattingContext } from '../organisms/FormattingProvider/FormattingContext'

dayjs.extend(utc)
dayjs.extend(quarterOfYear)

export const defaultDatasetProps = {
  pointRadius: 0,
  pointHitRadius: 16,
  lineTension: 0
}

const customTooltipStyle = {
  container: {
    background: 'white',
    color: 'inherit',
    fontSize: 'inherit',
    borderRadius: '2px',
    boxShadow: 'rgb(0 0 0 / 25%) 0px 1px 2px'
  },
  content: {
    whiteSpace: 'pre',
    display: 'flex',
    alignItems: 'center'
  },
  span: ({ color }) => ({
    display: 'block',
    width: '12px',
    height: '12px',
    background: color,
    marginRight: '7px'
  })
}

const getTooltipTemplate = (getLabelFormat) => (input) => {
  const {
    serieId,
    data: { xFormatted, y },
    color
  } = input.point
  const label = getLabelFormat(xFormatted, y, serieId)
  return (
    <div
      key={`${serieId}-${xFormatted}-${y}`} style={{
        ...customTooltipStyle.container,
        ...(serieId ? { boxShadow: 'none' } : {})
      }}
    >
      <div style={customTooltipStyle.content}>
        <span style={customTooltipStyle.span({ color })} />
        <span>{label}</span>
      </div>
    </div>
  )
}

const customTooltipFormatter = (formatterType, formatter, valueFormat, percentageFormat, isMultiSeries = false) => {
  const percentageFormatFunc = isMultiSeries
    ? (x, y, seriesName) => `${seriesName}, ${formatter(y, percentageFormat)}`
    : (x, y) => `${x}, ${formatter(y, percentageFormat)}`

  const currencyFormatFunc = isMultiSeries
    ? (x, y, seriesName) => `${seriesName}, ${formatter(y, valueFormat)}`
    : (x, y) => `${x}, ${formatter(y, valueFormat)}`

  switch (formatterType) {
    case NIVO_TOOLTIP_FORMATTERS.DATE_PERCENTAGE: {
      return getTooltipTemplate(percentageFormatFunc)
    }
    default: {
      return getTooltipTemplate(currencyFormatFunc)
    }
  }
}

const mapTickValueDates = ({ dateInterval, tickValueDates }) => {
  if ([DATE_TYPES.month, DATE_TYPES.quarter, DATE_TYPES.year].includes(dateInterval)) {
    return tickValueDates.reduce((acc, tickValue, index) => {
      if (index === 0) return [...acc, tickValue]
      const currDate = dayjs(tickValue)
      const prevDateIndex = acc.length - 1
      const prevDate = dayjs(acc[prevDateIndex])

      if (currDate.year() === prevDate.year()) {
        if (dateInterval === DATE_TYPES.month) {
          if (
            currDate.month() === prevDate.month() &&
            currDate.date() > prevDate.date()
          ) {
            return modifyByIndex(prevDateIndex, acc, tickValue)
          }
        }
        if (dateInterval === DATE_TYPES.quarter) {
          if (
            currDate.quarter() === prevDate.quarter() &&
            currDate.month() > prevDate.month() &&
            currDate.date() > prevDate.date()
          ) {
            return modifyByIndex(prevDateIndex, acc, tickValue)
          }
        }
        if (dateInterval === DATE_TYPES.year) {
          return modifyByIndex(prevDateIndex, acc, tickValue)
        }
      }
      return [...acc, tickValue]
    }, [])
  }
  return tickValueDates
}

const getRangeIntersectionOfDatasets = (data, lineData) => {
  const getEmptyDataPointIndexes = (data) => {
    return data.reduce((acc, datum, index) => {
      if (datum.y !== null) return acc
      return [...acc, index]
    }, [])
  }
  const emptyIndexesFromDataSets = [data, lineData].flatMap((dataSet) =>
    dataSet.flatMap(({ data: dataPoints }) =>
      getEmptyDataPointIndexes(dataPoints)
    )
  )
  const uniqueEmptyIndexes = Array.from(new Set(emptyIndexesFromDataSets))

  const filterDataPoints = (dataSet) => {
    return dataSet.map(({ data, ...dataSetValue }) => ({
      ...dataSetValue,
      data: data.filter((_, index) => !uniqueEmptyIndexes.includes(index))
    }))
  }
  return {
    data: filterDataPoints(data),
    lineData: filterDataPoints(lineData)
  }
}

const useStyles = makeStyles((theme) => ({
  container: ({ height }) => ({
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    height: height || '500px',
    width: '100%'
  }),
  subContainer: ({ height, width }) => ({
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: height || '500px',
    flex: `1 1 ${width || '100%'}`
  }),
  customTooltipContainer: {
    background: theme.palette.white,
    padding: '0.5rem 0.75rem',
    border: `1px solid ${theme.palette.gray.light}`
  }
}))

const TICK_WIDTH = 60
const WINDOWS_RESIZE_DELAY = 500
const gridYValueCount = 4

function LineChartNivo ({
  areaOpacity,
  axisBottom,
  axisLeft,
  axisRight,
  axisTop,
  colors,
  curve,
  data,
  lineData,
  enableArea,
  enableGridX,
  enableGridY,
  enablePointLabel,
  enablePoints,
  fontSize,
  fontFamily,
  height,
  legends,
  lineWidth,
  margin,
  onClick,
  tooltipFormatter,
  pointBorderWidth,
  pointSize,
  theme,
  width,
  xFormat,
  xScale,
  yFormat,
  yMaxValue,
  yMinValue,
  yScale,
  primaryLineConfigOverrides,
  secondaryLineConfigOverrides,
  dateInterval,
  showDataSetsIntersection,
  percentageFormat,
  valueFormat
}) {
  const classes = useStyles({ height, width })
  const windowSize = useWindowResize(WINDOWS_RESIZE_DELAY)
  const [lineChartContainerRect, setLineChartContainerRect] = useState(null)

  const dataPoints = useMemo(() => {
    if (!showDataSetsIntersection || isEmpty(lineData)) {
      return { data, lineData }
    }
    return getRangeIntersectionOfDatasets(data, lineData)
  }, [data, lineData, showDataSetsIntersection])

  const { formatter } = useFormattingContext()
  const customYScale = useMemo(() => {
    const { data, lineData } = dataPoints
    const combinedDataSet = [
      ...[...data.reduce((acc, datum) => [...acc, ...datum.data], [])],
      ...[...lineData.reduce((acc, datum) => [...acc, ...datum.data], [])]
    ]

    if (isEmpty(combinedDataSet)) {
      return yScale
    }
    const dataSetValues = combinedDataSet.map((point) => point.y)
    const minValue = Math.min(...dataSetValues)
    const maxValue = Math.max(...dataSetValues) * 1.1

    let yScaleMinValue = yMinValue

    if (isNullOrUndefined(yScaleMinValue)) {
      if (minValue === 0) {
        const minNonZeroValue = Math.min(
          ...dataSetValues.filter((value) => value !== 0)
        )
        yScaleMinValue = minNonZeroValue * -1
      } else {
        yScaleMinValue = Math.sign(minValue) >= 0 ? minValue * 0.8 : minValue * 1.4
      }
    }

    const yScaleMaxValue = isNullOrUndefined(yMaxValue) ? maxValue : yMaxValue

    return {
      ...yScale,
      min: yScaleMinValue,
      max: yScaleMaxValue
    }
  }, [yScale, dataPoints, yMaxValue, yMinValue])

  const finalYFormat =
    typeof yFormat === 'string' && yFormat === 'custom'
      ? x => formatter(x, valueFormat)
      : yFormat

  const lineChartContainerRef = useCallback(
    (node) =>
      setLineChartContainerRect(node ? node.getBoundingClientRect() : null),
    []
  )

  const tickValues = useMemo(() => {
    if (!lineChartContainerRect) return []
    const innerWidth = lineChartContainerRect.width

    const tickValues = uniq(
      dataPoints.data.reduce(
        (acc, dataset) => acc.concat(dataset.data.map((item) => item.x)),
        []
      )
    )
    const gridWidth = innerWidth / tickValues.length
    const tickDistance = Math.floor(TICK_WIDTH / gridWidth)

    const tickValueDates = mapTickValueDates({
      dateInterval,
      tickValueDates: tickValues
    })

    return {
      dataSet:
        tickDistance === 0
          ? tickValueDates
          : tickValueDates.filter((_, i) => i % tickDistance === 0)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataPoints.data, windowSize.width, lineChartContainerRect])

  const axisBottomHydrated = useMemo(() => {
    if (isEmpty(axisBottom)) {
      return null
    }
    const { formats = {} } = axisBottom
    return {
      tickValues: tickValues.dataSet,
      tickRotation: 45,
      format: function (value) {
        const date = dayjs(value).utc()

        if (dateInterval === DATE_TYPES.day) {
          const format = formats[DATE_TYPES.day]
          return date.format(format ?? 'M/D')
        }

        if (dateInterval === DATE_TYPES.month) {
          const format = formats[DATE_TYPES.month]
          return date.format(format ?? 'MMM/YY')
        }

        if (dateInterval === DATE_TYPES.year) {
          const format = formats[DATE_TYPES.year]
          return date.format(format ?? 'YYYY')
        }

        if (dateInterval === DATE_TYPES.quarter) {
          const format = formats[DATE_TYPES.quarter]
          const quarter = date.quarter()
          return `Q${quarter} ${date.format(format ?? 'YY')}`
        }

        return date.format('MMM/YY')
      },
      ...axisBottom
    }
  }, [axisBottom, tickValues, dateInterval])

  const renderSliceTooltip = useCallback(
    ({ slice }) => {
      return (
        <div className={classes.customTooltipContainer}>
          {slice.points.length > 1 ? <div>{slice.points[0].data.x}</div> : null}
          {slice.points.map((point) =>
            point.y
              ? customTooltipFormatter(
                tooltipFormatter,
                formatter,
                valueFormat,
                percentageFormat,
                slice.points.length > 1
              )({ point })
              : null
          )}
        </div>
      )
    },
    [tooltipFormatter, classes.customTooltipContainer, formatter, valueFormat, percentageFormat]
  )

  const customColors = useMemo(() => {
    if (colors) return colors
    if (isEmpty(dataPoints.data)) return undefined
    const result = compact(dataPoints.data.map((item) => item.color))
    return !isEmpty(result) ? result : undefined
  }, [dataPoints.data, colors])

  const lineCustomColors = useMemo(() => {
    if (colors) return colors
    if (isEmpty(dataPoints.lineData) && !isArray(dataPoints.lineData)) {
      return undefined
    }
    const result = compact(dataPoints.lineData.map((item) => item.color))
    return !isEmpty(result) ? result : undefined
  }, [dataPoints.lineData, colors])

  return (
    <div className={classes.container}>
      <div ref={lineChartContainerRef} className={classes.subContainer}>
        <AutoSizer>
          {({ height, width }) => (
            <Line
              height={height}
              width={width}
              theme={{ fontSize, fontFamily }}
              areaOpacity={areaOpacity}
              axisBottom={axisBottomHydrated}
              axisLeft={axisLeft}
              axisRight={axisRight}
              axisTop={axisTop}
              colors={customColors}
              borderColor={customColors}
              curve={curve}
              data={dataPoints.data}
              areaBaselineValue={customYScale.min}
              enableArea={enableArea}
              enableGridX={enableGridX}
              enableGridY={enableGridY}
              enablePointLabel={enablePointLabel}
              enablePoints={enablePoints}
              legends={legends}
              lineWidth={lineWidth}
              margin={margin}
              onClick={onClick}
              pointBorderWidth={pointBorderWidth}
              pointSize={pointSize}
              useMesh
              xFormat={xFormat}
              xScale={xScale}
              yFormat={finalYFormat}
              yScale={customYScale}
              gridYValues={gridYValueCount}
              {...primaryLineConfigOverrides}
              {...(!isEmpty(theme) ? { theme: { ...theme } } : {})}
            />
          )}
        </AutoSizer>
      </div>
      {dataPoints.data && isArray(dataPoints.lineData) && (
        <div className={classes.subContainer}>
          <AutoSizer>
            {({ height, width }) => (
              <Line
                height={height}
                width={width}
                data={dataPoints.lineData}
                lineWidth={4}
                margin={margin}
                yScale={customYScale}
                axisTop={null}
                axisBottom={null}
                axisLeft={null}
                axisRight={null}
                curve={curve}
                enableArea={false}
                enableGridX={false}
                enableGridY={false}
                enableLabel={false}
                enablePoints={false}
                enablePointLabel={false}
                colors={lineCustomColors}
                useMesh
                enableSlices='x'
                areaBaselineValue={customYScale.min}
                sliceTooltip={renderSliceTooltip}
                {...secondaryLineConfigOverrides}
              />
            )}
          </AutoSizer>
        </div>
      )}
    </div>
  )
}
const commonDataShape = PropTypes.arrayOf(PropTypes.shape({
  // Id of the dataset
  id: PropTypes.string,
  // Color of the dataset
  color: PropTypes.string,
  // Dataset values, an array of 2D points to be plot
  data: PropTypes.arrayOf(PropTypes.shape({
    x: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    y: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
  }))
}))
LineChartNivo.propTypes = {
  ...chartRootShape,
  // If enableArea is true, opacity of the area below the curve. Number between 0 and 1
  areaOpacity: PropTypes.number,
  // Specification of the different axis
  axisBottom: PropTypes.shape(axisShape),
  axisLeft: PropTypes.shape(axisShape),
  axisRight: PropTypes.shape(axisShape),
  axisTop: PropTypes.shape(axisShape),
  // Series color
  colors: PropTypes.arrayOf(PropTypes.string),
  // Curve interpolation. Nivo default is linear
  curve: PropTypes.oneOf(Object.values(CHART_CURVE_INTERPOLATIONS)),
  // The data to display in the LineChartNivo
  data: commonDataShape,
  // The data to display a line overlapping the main graph
  lineData: PropTypes.oneOfType([
    PropTypes.any,
    PropTypes.func,
    commonDataShape
  ]),
  // Display area below the curve
  enableArea: PropTypes.bool,
  // Whether to display X grid
  enableGridX: PropTypes.bool,
  // Whether to display Y grid
  enableGridY: PropTypes.bool,
  // Whether to display a label in the point
  enablePointLabel: PropTypes.bool,
  // Whether to display the points of the curve
  enablePoints: PropTypes.bool,
  // The text font-family if the default variant can't provide the font-family required
  fontFamily: PropTypes.string,
  // Specifies the font-size theme property used globally
  fontSize: PropTypes.string,
  height: PropTypes.string,
  // Chart legends
  legends: PropTypes.arrayOf(PropTypes.shape(legendType)),
  // Line width measured in pixels
  lineWidth: PropTypes.number,
  margin: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  // On click function. The function receives a point and an event params. Example: (point, event) => void
  onClick: PropTypes.func,
  // Custom tooltip formatter key that, when specified, renders an HTML with a set of predefined formatters
  tooltipFormatter: PropTypes.oneOf(Object.values(NIVO_TOOLTIP_FORMATTERS)),
  // Border width of the points measured in pixels. Nivo default 0 px
  pointBorderWidth: PropTypes.number,
  // Point size measured in pixels. Nivo default 6 px
  pointSize: PropTypes.number,
  // Define style for common elements such as labels, axes…
  theme: PropTypes.shape(themeShape),
  width: PropTypes.string,
  // Format to be applied to the x scale
  xFormat: PropTypes.string,
  xScale: PropTypes.shape({
    type: PropTypes.oneOf(Object.values(CHART_SCALE_TYPES))
  }),
  // Format to be applied to the y scale
  yFormat: PropTypes.string,
  // Maximum value shown in the range of the Y axis
  yMaxValue: PropTypes.number,
  // Minimun value shown in the range of the Y axis
  yMinValue: PropTypes.number,
  yScale: PropTypes.shape({
    type: PropTypes.oneOf(Object.values(CHART_SCALE_TYPES)),
    stacked: PropTypes.bool,
    min: PropTypes.string,
    max: PropTypes.string
  }),
  primaryLineConfigOverrides: PropTypes.object,
  secondaryLineConfigOverrides: PropTypes.object,
  dateInterval: PropTypes.oneOf(Object.values(DATE_TYPES)),
  showDataSetsIntersection: PropTypes.bool,
  percentageFormat: PropTypes.string,
  valueFormat: PropTypes.string
}

LineChartNivo.defaultProps = {
  ...chartRootShapeDefaults,
  areaOpacity: undefined,
  axisBottom: {},
  axisLeft: {},
  axisRight: null,
  axisTop: null,
  colors: undefined,
  curve: CHART_SCALE_TYPES.cardinal,
  data: [],
  lineData: [],
  enableArea: false,
  enableGridX: false,
  enableGridY: true,
  enablePointLabel: false,
  enablePoints: false,
  fontFamily: 'Gotham-Book',
  fontSize: '12px',
  height: undefined,
  legends: undefined,
  lineWidth: 4,
  onClick: noop,
  pointBorderWidth: undefined,
  pointSize: undefined,
  tooltipFormatter: undefined,
  theme: undefined,
  width: undefined,
  xFormat: undefined,
  xScale: { type: CHART_SCALE_TYPES.point },
  yFormat: undefined,
  yMaxValue: undefined,
  yMinValue: undefined,
  yScale: { type: CHART_SCALE_TYPES.linear, min: 'auto', max: 'auto' },
  primaryLineConfigOverrides: {},
  secondaryLineConfigOverrides: {},
  dateInterval: DATE_TYPES.day,
  showDataSetsIntersection: false,
  percentageFormat: '0.00%',
  valueFormat: '$0,0A'
}

export default React.memo(LineChartNivo)
