import { isEmpty } from 'lodash'
import { useCallback } from 'react'
import { CALC_TYPES, LEVEL_TYPES } from '../constants'
import { useAppContext } from '../redux/slices/appContext'
import { getAssetClassesV2, getAssetClasses, getAssetsV2 } from '../service'
import { removeEmptyProps } from '../utils'
import useAbortController from './useAbortController'

const useFetchAssets = () => {
  const appContext = useAppContext()
  const abortController = useAbortController()

  const baseFetchAssets = useCallback(
    async ({
      endDate,
      startDate,
      levelTypes = [],
      accountIds = [],
      assetIds = [],
      benchmarkType = undefined,
      subclassTagIds = [],
      assetClassTagIds = [],
      accountCategoryIds = [],
      accountObjectiveIds = [],
      calcType = CALC_TYPES.performance,
      ...params
    }) => {
      const signal = abortController()
      const { data } = await getAssetClassesV2(
        removeEmptyProps({
          ...params,
          startDate,
          endDate,
          accountIds,
          assetIds,
          benchmarkType,
          subclassTagIds,
          assetClassTagIds,
          accountCategoryIds,
          accountObjectiveIds,
          levelTypes: [LEVEL_TYPES.CLIENT, ...levelTypes],
          clientIds: [appContext.clientId],
          calcType
        }),
        { signal }
      )
      return data.rows || data
    },
    [abortController, appContext.clientId]
  )

  const fetchAssetClasses = useCallback(
    async ({
      endDate,
      startDate,
      assetClassId,
      levelTypes = [],
      accountIds = [],
      subclassTagIds = [],
      assetClassTagIds = [],
      accountCategoryIds = [],
      calcType = CALC_TYPES.performance
    }) => {
      const signal = abortController()
      const { data } = await getAssetClasses(
        removeEmptyProps({
          accountCategoryIds,
          levelTypes: [
            LEVEL_TYPES.CLIENT,
            LEVEL_TYPES.ASSET_CLASS_TAG,
            ...levelTypes
          ],
          clientIds: [appContext.clientId],
          subclassTagIds,
          assetClassTagIds: isEmpty(assetClassTagIds)
            ? [assetClassId]
            : assetClassTagIds,
          accountIds,
          startDate,
          endDate,
          calcType
        }),
        { signal }
      )
      return data.rows || data
    },
    [abortController, appContext.clientId]
  )

  const fetchSubclasses = useCallback(
    async ({
      endDate,
      startDate,
      levelTypes = [],
      accountIds = [],
      assetClassTagIds = [],
      accountCategoryIds = [],
      calcType = CALC_TYPES.performance
    }) => {
      const signal = abortController()
      const { data } = await getAssetClasses(
        removeEmptyProps({
          accountCategoryIds,
          clientIds: [appContext.clientId],
          assetClassTagIds,
          levelTypes: [
            LEVEL_TYPES.CLIENT,
            LEVEL_TYPES.SUBCLASS_TAG,
            ...levelTypes
          ],
          accountIds,
          startDate,
          endDate,
          calcType
        }),
        { signal }
      )
      return data
    },
    [abortController, appContext.clientId]
  )

  const fetchAssets = useCallback(
    async ({
      endDate,
      startDate,
      assetClassId,
      accountIds = [],
      levelTypes = [],
      subclassTagIds = [],
      accountCategoryIds = [],
      calcType = CALC_TYPES.performance
    }) => {
      const signal = abortController()
      const { data } = await getAssetsV2(
        assetClassId,
        removeEmptyProps({
          accountCategoryIds,
          assetClassTagIds: [assetClassId],
          clientIds: [appContext.clientId],
          subclassTagIds,
          assetIds: [],
          levelTypes: [
            LEVEL_TYPES.CLIENT,
            LEVEL_TYPES.ASSET_CLASS_TAG,
            LEVEL_TYPES.ASSETS,
            ...levelTypes
          ],
          accountIds,
          startDate,
          endDate,
          calcType
        }),
        { signal }
      )
      return data.assets.sort((a, b) =>
        a.name && b.name
          ? a.name.localeCompare(b.name)
          : a.names?.assetClassTagName.localeCompare(b.names?.assetClassTagName)
      )
    },
    [abortController, appContext.clientId]
  )

  return {
    fetchAssets,
    fetchSubclasses,
    fetchAssetClasses,
    baseFetchAssets
  }
}

export default useFetchAssets
