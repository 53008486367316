import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { makeStyles, useTheme } from '@material-ui/core'
import { isEmpty } from 'lodash'
import AdvisorHomeEmptySection from '../AdvisorHomeEmptySection'
import { ICON_NAMES } from '../../../../constants'
import { useAdvisorHome } from '../AdvisorHomeContext'
import { useTasksMetics } from '../../TasksView/hooks/useTasksMetrics'
import { useParameterReplacement } from '../../../../hooks/useAbundanceEngineParameters'
import Metric from './Metric'
import InlineDividedMetric from './InlineDividedMetric'

const useStyles = makeStyles(() => ({
  container: {
    padding: '2rem 1rem 1rem 1rem'
  }
}))

const TaskMetricsGroup = ({
  metrics,
  valueFormat,
  showOverdue,
  overdueLabel,
  overdueTooltipTitle,
  emptyStateLabel,
  useScope,
  excludeTaskStatuses,
  overdueStartDate: rawOverdueStartDate
}) => {
  const classes = useStyles()
  const theme = useTheme()
  const { scope } = useAdvisorHome()
  const [overdueStartDate] = useParameterReplacement([rawOverdueStartDate])

  const groupIds = useMemo(() => {
    return useScope ? scope?.values ?? [] : []
  }, [useScope, scope])

  const metricsSpec = useMemo(
    () => ({
      metricsSpec: metrics,
      extraFilters: !isEmpty(groupIds)
        ? { groupIds: [{ op: 'in', value: groupIds }] }
        : null,
      includeOverdue: showOverdue,
      overdueLabel,
      overdueStartDate,
      excludeTaskStatuses
    }),
    [
      metrics,
      groupIds,
      showOverdue,
      overdueLabel,
      overdueStartDate,
      excludeTaskStatuses
    ]
  )

  const { data, isLoading } = useTasksMetics(metricsSpec)

  const metricsSkeletons = useMemo(() => {
    return [...metrics, showOverdue ? { label: overdueLabel } : null].filter(
      Boolean
    )
  }, [metrics, overdueLabel, showOverdue])

  if ((!data || data.every(({ total }) => total === 0)) && !isLoading) {
    return (
      <AdvisorHomeEmptySection
        title='No tasks'
        icon={ICON_NAMES.paperTasks}
        description={emptyStateLabel}
      />
    )
  }

  return (
    <div className={classes.container}>
      {isLoading ? (
        <InlineDividedMetric justifyContentEvenly>
          {metricsSkeletons.map(({ label }, index) => {
            return (
              <Metric key={index} value={0} description={label} isLoading />
            )
          })}
        </InlineDividedMetric>
      ) : (
        <InlineDividedMetric justifyContentEvenly>
          {data?.map((metric) => {
            return (
              <Metric
                key={metric.label}
                value={metric.total}
                valueFormat={valueFormat}
                description={metric.label}
                tooltipTitle={
                  metric.overdue ? overdueTooltipTitle : metric?.tooltipTitle
                }
                valueColor={
                  metric.overdue
                    ? theme.palette.error.primary
                    : theme.palette.primary.main
                }
              />
            )
          })}
        </InlineDividedMetric>
      )}
    </div>
  )
}

TaskMetricsGroup.propTypes = {
  metrics: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      startDate: PropTypes.string,
      endDate: PropTypes.string,
      order: PropTypes.number,
      tooltipTitle: PropTypes.string
    })
  ),
  showOverdue: PropTypes.bool,
  valueFormat: PropTypes.string,
  emptyStateLabel: PropTypes.string,
  useScope: PropTypes.bool,
  overdueLabel: PropTypes.string,
  overdueTooltipTitle: PropTypes.string,
  excludeTaskStatuses: PropTypes.arrayOf(PropTypes.string),
  overdueStartDate: PropTypes.string
}

TaskMetricsGroup.defaultProps = {
  metrics: [],
  showOverdue: false,
  valueFormat: null,
  useScope: true,
  overdueLabel: 'Overdue',
  emptyStateLabel: 'Looks like you have no upcoming tasks',
  overdueTooltipTitle: 'View overdue tasks',
  excludeTaskStatuses: ['Completed'],
  overdueStartDate: '$prevYearStart'
}

export default TaskMetricsGroup
