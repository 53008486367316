import React from 'react'
import { useThingContext } from '../ThingContext'

function ThingDebug () {
  // eslint-disable-next-line no-undef
  const { data, isLoading } = useThingContext()

  if (isLoading) return null

  return (
    <div>
      <pre>{JSON.stringify(data, null, 2)}</pre>
    </div>
  )
}

export default ThingDebug
