import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { useGroupingContext } from '../GroupingProvider/GroupingContext'
import ComponentsOfChangeTable, {
  componentsOfChangeTableDefaultProps,
  componentsOfChangeTableProps
} from './ComponentsOfChangeTable'

const ComponentsOfChangeTableWrapper = ({
  options: configOptions,
  ...props
}) => {
  const { groupingDateRanges, setPerformanceTableProps } = useGroupingContext()

  const tableRef = useCallback(
    (node) => {
      if (node !== null) {
        setPerformanceTableProps(node)
      }
    },
    [setPerformanceTableProps]
  )

  return (
    <ComponentsOfChangeTable
      ref={tableRef}
      {...props}
      dateRanges={groupingDateRanges}
    />
  )
}

ComponentsOfChangeTableWrapper.propTypes = {
  ...componentsOfChangeTableProps,
  options: PropTypes.shape({
    hideCustomizeColumnsButton: PropTypes.bool,
    hideExpandCollapseButton: PropTypes.bool,
    hideExportButton: PropTypes.bool
  })
}

ComponentsOfChangeTableWrapper.defaultProps = {
  ...componentsOfChangeTableDefaultProps,
  options: {
    hideCustomizeColumnsButton: false,
    hideExpandCollapseButton: false,
    hideExportButton: false
  }
}

export default ComponentsOfChangeTableWrapper
