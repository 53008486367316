/* eslint-disable react/prop-types */
import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import { Link, useHistory } from 'react-router-dom'
import { useListManualPositionBatches } from '../../../../../api/accounts'
import OperationalTable from '../../../../organisms/OperationalTable'
import Icon from '../../../../atoms/Icon'
import { useFormattingContext } from '../../../../organisms/FormattingProvider/FormattingContext'

const useStyles = makeStyles(() => ({
  batchTable: {
    marginTop: '10px',
    '& .__status': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      gap: '4px'
    }
  }
}))

// eslint-disable react/prop-types

const IdCell = ({ value }) => {
  return <Link onClick={(e) => { e?.stopPropagation() }} to={`/admin/manual-accounts/batch/${value}`}>{value}</Link>
}

const StatusCell = ({ row }) => row?.original?.isError ? (
  <div className='__status'><Icon name='close' color='red' customSize={16} /> Error</div>
) : row?.original?.isComplete ? (
  <div className='__status'><Icon name='check' color='green' customSize={16} /> Done</div>
) : (
  <div className='__status'><Icon name='clock' customSize={16} /> Running</div>
)

const DateCell = ({ value }) => {
  const { formatter } = useFormattingContext()
  return <div>{formatter(value, '&M/D/YY h:mm a')}</div>
}

const NumberCell = ({ value }) => {
  const { formatter } = useFormattingContext()
  return <div>{formatter(value, '0,0')}</div>
}

const ProgressCell = ({ row }) => {
  return <div>{row.original.completedTasks} / {row.original.totalTasks}</div>
}

const columns = [
  { Header: 'Id', accessor: 'batchId', type: 'number', Cell: IdCell },
  { Header: 'Status', id: 'status', accessor: 'isComplete', type: 'string', Cell: StatusCell },
  { Header: 'File Name', id: 'fileName', accessor: 'name', type: 'string' },
  { Header: 'Progress', accessor: 'completedTasks', type: 'number', alignRight: true, Cell: ProgressCell },
  { Header: 'Errored', accessor: 'erroredTasks', type: 'number', alignRight: true },
  { Header: 'Records', accessor: 'recordCount', type: 'number', alignRight: true, Cell: NumberCell },
  { Header: 'Created', accessor: 'createdDate', type: 'string', Cell: DateCell },
  { Header: 'Ran By', accessor: 'createdBy', type: 'string' }
]

const defaultSort = [{ id: 'batchId', desc: true }]

function BatchTable ({ showAll }) {
  const classes = useStyles()
  const { data, isFetching } = useListManualPositionBatches(showAll, { refetchInterval: 30000 })
  const history = useHistory()
  const onClick = useCallback((row) => {
    if (row.original) {
      history.push(`/admin/manual-accounts/batch/${row.original.batchId}`)
    }
  }, [history])

  return (
    <OperationalTable.Wrapper className={classes.batchTable}>
      <OperationalTable
        data={data?.items || []}
        variant='v2'
        loading={isFetching}
        hideFooter
        columns={columns}
        onRowClick={onClick}
        defaultSort={defaultSort}
      />
    </OperationalTable.Wrapper>
  )
}

BatchTable.propTypes = {
  showAll: PropTypes.bool
}

export default BatchTable
