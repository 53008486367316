import React, { forwardRef, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { Input } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useFormattingContext } from '../../../../organisms/FormattingProvider/FormattingContext'
import FadeIn from '../../../../molecules/Transitions/FadeIn'

const useStyles = makeStyles((theme) => ({
  equationWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  equation: {
    display: 'flex',
    flexDirection: 'row',
    gap: '20px',
    fontSize: '18px',
    fontWeight: '100'
    // alignItems: 'center'
  },
  error: {
    color: theme.palette.error.primary,
    fontWeight: '600'
  },
  equationBlock: {
    display: 'flex',
    flexDirection: 'column',
    // justifyContent: 'center',
    '& > header': {
      fontSize: '10px',
      fontWeight: '600',
      align: 'left'
    },
    '& > main': {
      fontFeatureSettings: 'tnum',
      padding: '10px 0',
      align: 'middle'
    }
  },
  symbol: {
    paddingTop: '25px'
  }
}))

const RaiseCashEquation = forwardRef(({ cash, available, loading }, ref) => {
  const classes = useStyles()
  const { formatter } = useFormattingContext()
  const cashInput = useRef()
  const [cashState, setCashState] = useState(0)
  const [errorState, setErrorState] = useState()
  const handleChange = useCallback((e) => {
    if (e.target.value === '') {
      setErrorState(null)
      setCashState(0)
      return
    }
    const val = Number.parseFloat(e.target.value)
    if (isNaN(val)) {
      setErrorState('Must be a valid number')
      cashInput.current.value = cashState
      return
    }
    if (val < 0) {
      setErrorState('Must specify a positive amount')
      return
    }
    if (available && val > available) {
      setErrorState('Can\'t raise more than available market value')
      return
    }
    setErrorState(null)
    setCashState(val)
  }, [setCashState, setErrorState, cashState, available])
  useEffect(() => {
    if (ref) {
      ref.current = cashState
    }
  }, [cashState, ref])
  const estimated = useMemo(() => {
    return cashState + cash
  }, [cashState, cash])

  if (loading) {
    return null
  }

  return (
    <FadeIn className={classes.equationWrapper}>
      <div className={classes.equation}>
        <div className={classes.equationBlock}>
          <header>Cash Available</header>
          <main>{formatter(cash, '$0,0.00')}</main>
        </div>
        <div className={classes.symbol}>+</div>
        <div className={classes.equationBlock}>
          <header>Cash To Raise</header>
          <main>
            <Input
              error={errorState}
              type='text'
              inputRef={cashInput}
              inputMode='numeric'
              placeholder='0'
              onBlur={handleChange}
            />
          </main>
        </div>
        <div className={classes.symbol}>=</div>
        <div className={classes.equationBlock}>
          <header>Estimated Post-Trade Cash</header>
          <main>{formatter(estimated, '$0,0.00')}</main>
        </div>
      </div>
      {errorState ? (
        <div className={classes.error}>{errorState}</div>
      ) : null}
    </FadeIn>
  )
})

RaiseCashEquation.propTypes = {
  cash: PropTypes.number,
  available: PropTypes.number,
  loading: PropTypes.bool
}

export default RaiseCashEquation
