import React, { useCallback, useState } from 'react'
import { Snackbar } from '@material-ui/core'
import PropTypes from 'prop-types'
import { Alert } from '@material-ui/lab'
import { postCloseAccount } from '../../../../../service'
import { useBoolean } from '../../../../../hooks'
import SydButton from '../../../../commonDesign/Button'
import AccountCloseModal from '../AccountCloseModal'
import { usePolicy } from '../../../../../hooks/usePolicy'

const AccountCloseButton = ({ disabled, account, onClosedAccount }) => {
  const userCanCloseFundedAccounts = usePolicy('admin_edit_close_acct_funded')
  const [showAlert, setShowAlert] = useBoolean(false)
  const [showModal, setShowModal] = useBoolean(false)
  const [alertSeverity, setAlertSeverity] = useState('warning')
  const [alertMessage, setAlertMessage] = useState('')
  const [isLoading, setIsLoading] = useBoolean(false)

  const closeAccount = async () => {
    setIsLoading.on()
    await postCloseAccount({ accountId: account.accountId })
      .then(processSuccess)
      .catch(processError)
    setIsLoading.off()
  }

  const processError = useCallback((err) => {
    const { errorCode, message } = err?.response ??
    {
      errorCode: 'UNKNOWN_ERROR',
      message: 'Issue closing account'
    }

    setIsLoading.off()
    if (!errorCode) {
      return
    }

    if (!userCanCloseFundedAccounts) {
      setAlertMessage('Account is funded and cannot be closed. Contact an admin to close this account.')
      setAlertSeverity('warning')
      setShowAlert.on()
      return
    }

    switch (errorCode) {
      case 'CLOSE_REASON_REQUIRED':
        setAlertMessage(message)
        setAlertSeverity('warning')
        setShowAlert.off()
        setShowModal.on()
        break
      case 'ACCOUNT_UPDATING':
        setAlertMessage(message)
        setAlertSeverity('warning')
        setShowAlert.off()
        setShowModal.on()
        break
      default:
        setAlertMessage(message)
        setAlertSeverity('warning')
        setShowAlert.on()
        break
    }
  }, [setIsLoading, setShowAlert, setShowModal, userCanCloseFundedAccounts])

  const processSuccess = useCallback(() => {
    setShowModal.off()
    setShowAlert.off()
    onClosedAccount()
  }, [setShowModal, setShowAlert, onClosedAccount])

  return (
    <>
      {!disabled ? (
        <SydButton
          variant='primary'
          size='sm'
          processing={isLoading}
          onClick={closeAccount}
          disabled={disabled}
        >
          {isLoading ? 'Loading' : 'Close Account'}
        </SydButton>
      ) : '--'}

      <Snackbar
        open={showAlert}
        autoHideDuration={6000}
        onClose={() => setShowAlert.off()}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={() => setShowAlert.off()} severity={alertSeverity}>
          {alertMessage}
        </Alert>
      </Snackbar>

      {userCanCloseFundedAccounts && (
        <AccountCloseModal
          showModal={showModal}
          onClose={() => setShowModal.off()}
          onSuccess={processSuccess}
          account={account}
        />
      )}
    </>
  )
}

AccountCloseButton.propTypes = {
  disabled: PropTypes.bool,
  onClosedAccount: PropTypes.func,
  account: PropTypes.object.isRequired
}

export default AccountCloseButton
