import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import PieChart from '../../molecules/PieChart'
import { useFormattingContext } from '../FormattingProvider/FormattingContext'
import ChartTooltip from '../../molecules/ChartTooltip'
import Skeleton from '../../atoms/Skeleton'
import { SKELETON_VARIANTS } from '../../../constants'
import AllocationPieLegend from './AllocationPieLegend'

const useStyles = makeStyles(() => ({
  chartContainer: ({ containerStyles }) => ({
    display: 'flex',
    flexDirection: 'row',
    gap: '2rem',
    '& :first-child': {
      flex: '3'
    },
    '& :last-child': {
      flex: '1'
    },
    ...(containerStyles || {})
  }),
  skeletonContainer: {
    display: 'flex',
    alignContent: 'space-between',
    justifyContent: 'center',
    gap: '5rem'
  },
  skeletonLegends: {
    display: 'flex',
    flexDirection: 'column',
    gap: '2rem'
  }
}))

const usePieChartSeriesData = ({ labelLowerLimit, valueFormat, balanceFormat }) => {
  const { formatter } = useFormattingContext()
  const findArcLabel = useCallback((data) => {
    return data.value < labelLowerLimit
      ? ''
      : formatter(data.value, valueFormat)
  }, [formatter, labelLowerLimit, valueFormat])

  const findTooltip = useCallback(({ datum }) => {
    const label = `${datum.label}: ${formatter(datum.data.balance, balanceFormat)} (${formatter(datum.data.value, valueFormat)})`
    const color = datum.color

    return <ChartTooltip color={color} label={label} />
  }, [formatter, valueFormat, balanceFormat])

  return {
    findArcLabel,
    findTooltip
  }
}

const AllocationPieChart = ({
  data,
  isLoading,
  labelLowerLimit,
  valueFormat,
  balanceFormat,
  showLegend,
  innerRadius,
  containerStyles,
  chartHeight
}) => {
  const classes = useStyles({ containerStyles })
  const { findArcLabel, findTooltip } = usePieChartSeriesData({ labelLowerLimit, valueFormat, balanceFormat })

  if (isLoading) {
    return (
      <div className={classes.skeletonContainer}>
        <Skeleton
          height={`${chartHeight}px` || '18rem'}
          width={`${chartHeight}px` || '18rem'}
          variant={SKELETON_VARIANTS.circle}
        />
        {showLegend ? (
          <div className={classes.skeletonLegends}>
            <Skeleton height='2rem' width='8rem' />
            <Skeleton height='2rem' width='8rem' />
            <Skeleton height='2rem' width='8rem' />
            <Skeleton height='2rem' width='8rem' />
          </div>
        ) : null}
      </div>
    )
  }

  return (
    <div className={classes.chartContainer}>
      <PieChart
        data={data}
        useDataColors
        arcLabel={findArcLabel}
        tooltip={findTooltip}
        innerRadius={innerRadius}
        height={chartHeight}
      />
      {showLegend ? (
        <AllocationPieLegend data={data} />
      ) : null}
    </div>
  )
}

AllocationPieChart.propTypes = {
  data: PropTypes.array,
  isLoading: PropTypes.bool,
  labelLowerLimit: PropTypes.number,
  valueFormat: PropTypes.string,
  balanceFormat: PropTypes.string,
  showLegend: PropTypes.bool,
  innerRadius: PropTypes.number,
  containerStyles: PropTypes.object,
  chartHeight: PropTypes.number
}

AllocationPieChart.defaultProps = {
  data: [],
  isLoading: false,
  labelLowerLimit: 0.05,
  valueFormat: 'allocation',
  balanceFormat: 'marketValue',
  showLegend: true
}

export default AllocationPieChart
