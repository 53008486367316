import React, { useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import ClientOperationalTable from './ClientOperationalTable'
import ServerOperationalTable from './ServerOperationalTable'
import { operationalTablePropTypes } from './propTypes'

const AutoOperationalTable = ({
  mode,
  expandable,
  total,
  autoLimit,
  columns,
  data,
  defaultPageSize,
  defaultSort,
  itemName,
  loading,
  searchText,
  onSortingChange,
  onPagingChange,
  hideFooter,
  onRowClick,
  onTableModeChange,
  hiddenColumns,
  showTotalItemsLabel,
  autoSticky,
  footNote,
  variant
}) => {
  const tableMode = useMemo(() => {
    if (mode === 'server') return 'server'
    if (mode === 'client') return 'client'
    if (total > autoLimit) return 'server'
    return 'client'
  }, [mode, total, autoLimit])

  useEffect(() => {
    onTableModeChange(tableMode)
  }, [onTableModeChange, tableMode])

  if (tableMode === 'server') {
    return (
      <ServerOperationalTable
        total={total}
        columns={columns}
        data={data}
        defaultPageSize={defaultPageSize}
        defaultSort={defaultSort}
        itemName={itemName}
        loading={loading}
        expandable={expandable}
        onSortingChange={onSortingChange}
        onPagingChange={onPagingChange}
        onRowClick={onRowClick}
        hideFooter={hideFooter}
        hiddenColumns={hiddenColumns}
        showTotalItemsLabel={showTotalItemsLabel}
        autoSticky={autoSticky}
        variant={variant}
      />
    )
  }

  return (
    <ClientOperationalTable
      total={total}
      columns={columns}
      data={data}
      defaultSort={defaultSort}
      itemName={itemName}
      loading={loading}
      searchText={searchText}
      expandable={expandable}
      onRowClick={onRowClick}
      hideFooter={hideFooter}
      autoSticky={autoSticky}
      showTotalItemsLabel={showTotalItemsLabel}
      footNote={footNote}
      variant={variant}
    />
  )
}

AutoOperationalTable.propTypes = {
  mode: PropTypes.oneOf(['auto', 'client', 'server']),
  autoLimit: PropTypes.number,
  onTableModeChange: PropTypes.func,
  showTotalItemsLabel: PropTypes.bool,
  autoSticky: PropTypes.bool,
  ...operationalTablePropTypes
}

AutoOperationalTable.defaultProps = {
  mode: 'auto',
  expandable: false,
  autoLimit: 1000,
  onRowClick: noop,
  onTableModeChange: noop,
  showTotalItemsLabel: true,
  hiddenColumns: [],
  autoSticky: false
}

export default React.memo(AutoOperationalTable)
