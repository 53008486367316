import React, { useCallback, useMemo, useRef, useState } from 'react'
import { makeStyles } from '@material-ui/core'
import set from 'lodash/set'
import get from 'lodash/get'
import { useAdvisorUserDetails, useSectionEditing } from '../../AdvisorUserFormContext'
import SectionScreen from '../../../shared/SectionScreen'
import SectionHeader from '../../../shared/SectionHeader'
import EditButton from '../../../shared/EditButton'
import SaveCancelFooter from '../../../shared/SaveCancelFooter'
import {
  useAdvisorTeamMember,
  useModifyAdvisorTeamMember,
  useTeamMemberSchema
} from '../../../../../../api/groups'
import BioForm from './BioForm'

const useStyles = makeStyles((theme) => ({
  experienceSection: {
    position: 'relative',
    padding: '10px'
  }
}))

function AdvisorUserTeamMemberSection () {
  const { user, editSection, refetchUser, editing, cancelEdit } = useAdvisorUserDetails()
  const sectionEditing = useSectionEditing('bio')
  const { data: _teamMember, isLoading: teamMemberLoading } = useAdvisorTeamMember(user.userId)
  const { data: _schema, isLoading: schemaLoading } = useTeamMemberSchema()

  const classes = useStyles()
  const formRef = useRef()
  const { mutateAsync: modifyAdvisorTeamMember } = useModifyAdvisorTeamMember()
  const [processing, setProcessing] = useState(false)
  const bioAttribute = useMemo(() => _schema?.attributes?.find(a => a.attributeId === 'bio'), [_schema])

  const submitter = useCallback(async (e) => {
    const onValid = async (form) => {
      if (!bioAttribute) {
        alert('There was an unexpected problem saving the request.')
        return
      }

      try {
        setProcessing(true)
        await modifyAdvisorTeamMember({
          userId: form.userId,
          teamMember: set({}, bioAttribute.accessor, get(form.teamMember, bioAttribute.accessor))
        })
        await refetchUser()
        cancelEdit()
      } catch (err) {
        console.error('error trying to update contact information', err)
        alert('There was an unexpected problem saving the request.')
        throw err
      } finally {
        setProcessing(false)
      }
    }
    const onInvalid = (errors) => {
      console.error(errors)
    }

    const handler = formRef.current.handleSubmit(onValid, onInvalid)
    await handler(e)
  }, [formRef, modifyAdvisorTeamMember, refetchUser, setProcessing, cancelEdit, bioAttribute])

  if (teamMemberLoading || schemaLoading) {
    return null
  }

  if (!bioAttribute) {
    return null
  }

  return (
    <SectionScreen
      editing={editing}
      sectionIsEditing={sectionEditing}
      className={classes.experienceSection}
    >
      <SectionHeader text='Advisor Bio' subText='This short bio will be visible to your wealth owners'>
        <div>
          <EditButton
            policy='admin_edit_advisor_users'
            editing={sectionEditing}
            onClick={() => editSection({
              section: 'bio'
            })}
          />
        </div>
      </SectionHeader>
      <BioForm
        ref={formRef}
        user={user}
        teamMember={_teamMember.teamMember}
        bioAttribute={bioAttribute}
        editing={sectionEditing}
      />
      {sectionEditing ? (
        <SaveCancelFooter
          saveText='Save Bio'
          onCancel={() => {
            formRef.current.reset()
            cancelEdit()
          }}
          onSave={submitter}
          processing={processing}
        />
      ) : null}
    </SectionScreen>
  )
}

export default AdvisorUserTeamMemberSection
