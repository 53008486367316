import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { TEXT_VARIANTS, ICON_NAMES } from '../../constants'
import { paletteToThemeColorStrings } from '../../utils'
import { themeConfiguration } from '../../theme'
import Text from '../atoms/Text'
import Icon from '../atoms/Icon'

const useStyles = makeStyles((theme) => ({
  icon: ({ reverse }) => ({
    marginLeft: theme.spacing(0.5),
    ...reverse && {
      marginRight: theme.spacing(0.5)
    }
  }),
  container: ({ reverse }) => ({
    display: 'flex',
    flexDirection: reverse ? 'row-reverse' : 'row',
    justifyContent: 'flex-start',
    alignItems: 'center'
  })
}))

const TextWithIcon = ({
  additionalClasses,
  iconName,
  iconColor,
  iconThemeColor,
  iconCustomSize,
  iconMargin,
  reverse,
  text,
  textElement,
  textVariant
}) => {
  const classes = useStyles({ reverse })
  const icon = (
    <Icon
      additionalClasses={classes.icon}
      name={iconName}
      color={iconColor}
      themeColor={iconThemeColor}
      customSize={iconCustomSize}
      margin={iconMargin}
      variant={textVariant}
    />
  )

  const renderedText = useMemo(() => {
    if (textElement) return textElement
    return (<Text text={text} variant={textVariant} />)
  }, [text, textElement, textVariant])

  return (
    <div className={`${classes.container} ${additionalClasses}`}>
      {renderedText}
      {icon}
    </div>
  )
}

TextWithIcon.propTypes = {
  additionalClasses: PropTypes.string,
  iconName: PropTypes.oneOf(Object.values(ICON_NAMES)).isRequired,
  iconColor: PropTypes.string,
  iconThemeColor: PropTypes.oneOf(paletteToThemeColorStrings(themeConfiguration.palette)),
  iconCustomSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  iconMargin: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  reverse: PropTypes.bool,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  textElement: PropTypes.any,
  textVariant: PropTypes.oneOf(Object.values(TEXT_VARIANTS))
}

TextWithIcon.defaultProps = {
  additionalClasses: '',
  iconColor: '',
  iconThemeColor: undefined,
  iconCustomSize: '',
  iconMargin: '',
  reverse: false,
  text: '',
  textElement: null,
  textVariant: TEXT_VARIANTS.body2
}

export default TextWithIcon
