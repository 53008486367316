import React from 'react'
import PropTypes from 'prop-types'
import {
  TextField,
  FormLabel,
  withStyles,
  makeStyles
} from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  label: {
    color: theme.palette.darkJungle,
    fontSize: '12px',
    lineHeight: '14.5px',
    fontWeight: 'bold',
    marginBottom: '10px',
    display: 'block'
  }
}))

const CustomTextField = withStyles({
  root: {
    borderRadius: '8px',
    '& .Mui-disabled': {
      color: '#141929 !important',
      cursor: 'not-allowed',
      border: '1px solid #EEF0F8',
      background: 'linear-gradient(0deg, #F5F6F8 0%, #F5F6F8 100%), #FFF'
    }
  }
})(TextField)

function CustomTextInput ({
  type,
  label,
  name,
  placeholder,
  fullWidth,
  disabled,
  onChange,
  onBlur,
  inputProps,
  autoComplete,
  error,
  value
}, ref) {
  const classes = useStyles()
  return (
    <>
      {
        label && <FormLabel className={classes.label}>{label}</FormLabel>
      }
      <CustomTextField
        type={type}
        value={value}
        variant='outlined'
        autoComplete={autoComplete}
        fullWidth={fullWidth}
        disabled={disabled}
        name={name}
        placeholder={placeholder}
        onChange={onChange}
        onBlur={onBlur}
        error={Boolean(error)}
        InputProps={inputProps}
        ref={ref}
      />
    </>
  )
}

const TextInput = React.forwardRef(CustomTextInput)

CustomTextInput.propTypes = {
  type: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  autoComplete: PropTypes.string,
  fullWidth: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  inputProps: PropTypes.object,
  error: PropTypes.shape({
    type: PropTypes.string,
    message: PropTypes.string
  }),
  value: PropTypes.string
}

CustomTextInput.defaultProps = {
  type: 'text',
  value: ''
}

export default TextInput
