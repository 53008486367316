import React from 'react'
import { Typography } from '@material-ui/core'
import { TEXT_VARIANTS } from '../../constants'
import { useCurrentClient } from '../../redux/slices/clientContext'

const CurrentClient = () => {
  const [loading, client, error] = useCurrentClient()

  if (loading || error) {
    return null
  }

  return (
    <Typography color='primary' variant={TEXT_VARIANTS.h2} noWrap>
      {client?.familyName}
    </Typography>
  )
}

export default CurrentClient
