import React, {
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react'

import { useLocation, useHistory, useParams } from 'react-router-dom'
import queryString from 'query-string'
import { makeStyles } from '@material-ui/core/styles'
import { Box, Grid } from '@material-ui/core'
import {
  FEATURE_FLAG,
  ICON_NAMES,
  INTEGRATIONS,
  INTEGRATIONS_STATUS_MESSAGES,
  SALESFORCE_INTEGRATION,
  REDTAIL_INTEGRATION,
  WEALTHBOX_INTEGRATION,
  SKELETON_VARIANTS,
  ADMIN_ROUTES,
  INTEGRATION_CRMS,
  SCHWAB_INTEGRATION,
  FIDELITY_INTEGRATION,
  MGP_INTEGRATION,
  RIGHT_CAPITAL_INTEGRATION,
  NITROGEN_INTEGRATION,
  CUSTODIAL_INTEGRATIONS,
  PLANNING_TOOLS_INTEGRATIONS,
  RISKALYZE_INTEGRATION,
  COMPLIANCE_INTEGRATIONS,
  SMARSH_INTEGRATION,
  GLOBAL_RELAY_INTEGRATION,
  RIA_BOX_INTEGRATION,
  I_REBAL_INTEGRATION,
  JUNXURE_INTEGRATION,
  EMONEY_INTEGRATION,
  MONRNINGSTAR_INTEGRATION,
  DST_INTEGRATION
} from '../../../../constants'
import { INTEGRATIONS as POLICY_INTEGRATIONS } from '../../../../policies/admin'
import { integrations } from '../../../../service'
import { useBoolean, useCheckPolicy } from '../../../../hooks'
import { useFeatureFlag } from '../../../../redux/slices/appConfig'
import LoadingView from '../../LoadingView'
import { getActiveCRM, setActiveCRMToIntegrationsArray } from '../../../../utils'
import Skeleton from '../../../atoms/Skeleton'
import IntegrationCard, { CARD_HEIGHT, CARD_WIDTH } from './IntegrationCard'
import RedtailAuthModal from './Redtail/RedtailAuthModal'
import IntegrationsGroup from './IntegrationsGroup'
import IntegrationsLayout from './IntegrationsLayout'
import IntegrationsSwitcher from './IntegrationsSwitcher'

const successSyncCallBack = () => {
  const message = 'Successful Sync of all data'
  console.log(message)
}
const catchCallback = (ex) => { console.error(ex) }

const roundedBackgroundColor = 'unset'

const useStyles = makeStyles((theme) => ({
  container: {
    marginLeft: 0,
    marginTop: 0,
    marginBottom: 0
  },
  header: {
    marginBottom: '1.25rem'
  },
  content: {
    flexGrow: 1
  },
  cardSkeleton: {
    margin: '12px',
    maxHeight: CARD_HEIGHT,
    minWidth: CARD_WIDTH
  },
  icon: {
    padding: 0,
    background: 'unset',
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      marginBottom: theme.spacing(2)
    },
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(1)
    }
  },
  activeCrmDropdown: {
    border: '1px solid #E9EAEF',
    borderRadius: '36px',
    width: '200px',
    '& .MuiSelect-select': {
      padding: '6px 20px',
      borderRadius: '36px'
    },
    '& .MuiSvgIcon-root': {
      right: '10px',
      position: 'absolute',
      zIndex: -1
    }
  },
  footerDivider: {
    margin: 0
  }
}))

const IntegrationsView = () => {
  const classes = useStyles()
  const canEditIntegrations = useCheckPolicy(POLICY_INTEGRATIONS.editIntegrations)
  const [isLoading, setIsLoading] = useBoolean(true)
  const [isRefreshing, setIsRefreshing] = useBoolean()
  const [wealthboxAuthUrl, setWealthboxAuthUrl] = useState(null)
  const [isWealthboxAuthorized, setIsWealthboxAuthorized] = useBoolean()
  const [salesforceAuthUrl, setSalesforceAuthUrl] = useState(null)
  const [isSalesforceAuthorized, setIsSalesforceAuthorized] = useBoolean()
  const [isRedtailAuthorized, setIsRedtailAuthorized] = useBoolean()
  const [isRedtailModalOpen, setIsRedtailModalOpen] = useBoolean()
  const [hasCRMIntegrations, setHasCRMIntegrations] = useState(false)
  const [hasCustodialIntegrations, setHasCustodialIntegrations] = useState(false)
  const [hasPlanningToolsIntegrations, setHasPlaningToolsIntegrations] = useState(false)
  const [hasComplianceIntegrations, setHasComplianceIntegrations] = useState(false)
  const [hasTradingIntegrations, setHasTradingIntegrations] = useState(false)
  const location = useLocation()
  const history = useHistory()
  const pathParams = useParams()
  const {
    extras: {
      active: ACTIVE_INTEGRATIONS = [],
      canSwitchCrm = false
    }
  } = useFeatureFlag(FEATURE_FLAG.INTEGRATIONS)
  const [activeIntegrations] = useState(ACTIVE_INTEGRATIONS)
  const [activeCrm, setActiveCrm] = useState(getActiveCRM(ACTIVE_INTEGRATIONS) || '0')
  const [integrationsList, setIntegrationsList] = useState([])

  const getAuthorizations = useCallback(async () => {
    const { data: payload } = await integrations.getAuthorizations()
    const integrationList = Object.keys(payload)
    setIntegrationsList(integrationList)
    setHasCRMIntegrations(integrationList.some(integration => INTEGRATION_CRMS.includes(integration)))
    setHasCustodialIntegrations(integrationList.some(integration => CUSTODIAL_INTEGRATIONS.includes(integration)))
    setHasPlaningToolsIntegrations(integrationList.some(integration => PLANNING_TOOLS_INTEGRATIONS.includes(integration)))
    setHasComplianceIntegrations(integrationList.some(integration => COMPLIANCE_INTEGRATIONS.includes(integration)))
    setHasTradingIntegrations(integrationList.some(integration => PLANNING_TOOLS_INTEGRATIONS.includes(integration)))

    if (payload[WEALTHBOX_INTEGRATION]) {
      const wealthbox = payload[WEALTHBOX_INTEGRATION]
      const setFlag = wealthbox.authorized ? setIsWealthboxAuthorized.on : setIsWealthboxAuthorized.off
      setFlag()
      setWealthboxAuthUrl(wealthbox.authUrl)
    }

    if (payload[SALESFORCE_INTEGRATION]) {
      const salesforce = payload[SALESFORCE_INTEGRATION]
      const setFlag = salesforce.authorized ? setIsSalesforceAuthorized.on : setIsSalesforceAuthorized.off
      setFlag()
      setSalesforceAuthUrl(salesforce.authUrl)
    }

    if (payload[REDTAIL_INTEGRATION]) {
      const redtail = payload[REDTAIL_INTEGRATION]
      const setFlag = redtail.authorized ? setIsRedtailAuthorized.on : setIsRedtailAuthorized.off
      setFlag()
    }
  }, [
    setIsSalesforceAuthorized,
    setIsWealthboxAuthorized,
    setIsRedtailAuthorized,
    setHasComplianceIntegrations,
    setHasTradingIntegrations
  ])

  useEffect(() => {
    const queryParams = queryString.parse(location?.search || '')
    const integrationName = pathParams?.integration

    if (queryParams?.code && INTEGRATIONS.includes(integrationName)) {
      setIsRefreshing.on()
      const integration = integrations[integrationName]
      integration.authorize(queryParams.code)
        .then((result) => {
          integration.syncOnDemand().then(successSyncCallBack).catch(catchCallback)
          history.push(`/admin/integrations?status=${integration.status.success}`)
        })
        .catch((ex) => {
          console.error(ex)
          history.push(`/admin/integrations?status=${integration.status.error}`)
        })
    } else {
      setIsLoading.on()

      if (queryParams.status) {
        console.log(INTEGRATIONS_STATUS_MESSAGES[queryParams.status])
      }

      getAuthorizations()
        .catch(catchCallback)
        .finally(() => setIsLoading.off())
    }
  }, [
    history,
    setIsLoading,
    setIsRefreshing,
    getAuthorizations,
    location?.search,
    location.pathname,
    pathParams?.integration
  ])

  const onActiveCrmChange = useCallback(({ target: { value } }) => {
    let selected = value

    setActiveCrm(value)

    if (value === '0') {
      selected = null
    }

    const active = setActiveCRMToIntegrationsArray(selected, activeIntegrations)

    integrations.setActiveIntegrations(active)
      .then(() => history.go(0))
      .catch(catchCallback)
  }, [activeIntegrations, history])

  const redirectToWealthboxLoginPage = useCallback(() => {
    window.location.assign(wealthboxAuthUrl)
  }, [wealthboxAuthUrl])

  const redirectToSalesforceLoginPage = useCallback(() => {
    window.location.assign(salesforceAuthUrl)
  }, [salesforceAuthUrl])

  const redirectToRedtailLoginPage = useCallback(() => {
    setIsRedtailModalOpen.on()
  }, [setIsRedtailModalOpen])

  const commonIconProps = useMemo(() => ({
    additionalClasses: classes.icon,
    roundedBackground: roundedBackgroundColor,
    customSize: '150px',
    customHeight: '80px',
    margin: '0 !important'
  }), [classes.icon])

  const salesforceIconProps = useMemo(() => ({
    ...commonIconProps,
    margin: '-10px 0 10px 0 !important',
    padding: '0 !important'
  }), [commonIconProps])

  const fidelityIconProps = useMemo(() => ({
    ...commonIconProps,
    customSize: '150px'
  }), [commonIconProps])

  const riskalyzeIconProps = useMemo(() => ({
    ...commonIconProps,
    customSize: '114px'
  }), [commonIconProps])

  const iRebalIconProps = useMemo(() => ({
    ...commonIconProps,
    customHeight: '70px'
  }), [commonIconProps])

  const onCloseRedtailModalHandler = useCallback((event, reason) => {
    const reasons = ['backdropClick', 'escapeKeyDown']
    if (reasons.includes(reason)) {
      if (isRedtailModalOpen) {
        setIsRedtailModalOpen.off()
      }
    }
  }, [setIsRedtailModalOpen, isRedtailModalOpen])

  const onSuccessRedtailAuth = useCallback(() => {
    setIsLoading.on()

    integrations[REDTAIL_INTEGRATION]
      .syncOnDemand()
      .then(successSyncCallBack)
      .catch(catchCallback)

    getAuthorizations()
      .catch(catchCallback)
      .finally(() => setIsLoading.off())
  }, [getAuthorizations, setIsLoading])

  const manageIntegrationAction = useCallback((integrationName) => {
    history.push(`${ADMIN_ROUTES.INTEGRATIONS}/${integrationName}/manage`)
  }, [history])

  if (isRefreshing) {
    return (
      <IntegrationsLayout>
        <LoadingView
          styles={{
            position: 'absolute',
            inset: '0',
            backgroundColor: '#fff',
            zIndex: 1101
          }}
        />
      </IntegrationsLayout>
    )
  }

  if (isLoading) {
    return (
      <IntegrationsLayout>
        <Grid container style={{ padding: '25px' }}>
          <IntegrationsGroup
            title={<Skeleton width='100px' height='40px' variant={SKELETON_VARIANTS.text} />}
          >
            <Skeleton width={CARD_WIDTH} height={CARD_HEIGHT} className={classes.cardSkeleton} />
            <Skeleton width={CARD_WIDTH} height={CARD_HEIGHT} className={classes.cardSkeleton} />
            <Skeleton width={CARD_WIDTH} height={CARD_HEIGHT} className={classes.cardSkeleton} />
          </IntegrationsGroup>
          <IntegrationsGroup
            title={<Skeleton width='100px' height='40px' variant={SKELETON_VARIANTS.text} />}
          >
            <Skeleton width={CARD_WIDTH} height={CARD_HEIGHT} className={classes.cardSkeleton} />
            <Skeleton width={CARD_WIDTH} height={CARD_HEIGHT} className={classes.cardSkeleton} />
          </IntegrationsGroup>
        </Grid>
      </IntegrationsLayout>
    )
  }

  return (
    <IntegrationsLayout>
      <Box sx={{ p: 3, pb: '60px' }} style={{ display: isLoading || isRefreshing ? 'none' : 'block' }}>
        <IntegrationsGroup title='CRM' display={hasCRMIntegrations}>
          {
            integrationsList.includes('practifi') && (
              <IntegrationCard
                showButton={!!salesforceAuthUrl && canEditIntegrations}
                isAuthorized={isSalesforceAuthorized}
                authAction={redirectToSalesforceLoginPage}
                classes={classes}
                title=''
                subtitle='Sync clients, tasks and meetings'
                iconProps={{ customHeight: '30px' }}
                imgUrl='/practifi-full-logo.png'
                name='practifi'
                hideMenu={!canEditIntegrations}
              />
            )
          }
          {
            integrationsList.includes(SALESFORCE_INTEGRATION) && (
              <IntegrationCard
                showButton={!!salesforceAuthUrl && canEditIntegrations}
                isAuthorized={isSalesforceAuthorized}
                authAction={redirectToSalesforceLoginPage}
                classes={classes}
                title='Salesforce'
                subtitle='Sync clients, tasks and meetings'
                iconProps={salesforceIconProps}
                iconName={ICON_NAMES.salesforce}
                name={SALESFORCE_INTEGRATION}
                hideMenu={!canEditIntegrations}
              />
            )
          }
          {
            integrationsList.includes(WEALTHBOX_INTEGRATION) && (
              <IntegrationCard
                showButton={!!wealthboxAuthUrl && canEditIntegrations}
                isAuthorized={isWealthboxAuthorized}
                displayManageButton={false}
                manageAction={() => manageIntegrationAction(WEALTHBOX_INTEGRATION)}
                authAction={redirectToWealthboxLoginPage}
                classes={classes}
                title='Wealthbox'
                subtitle='Sync clients, tasks and meetings'
                iconProps={commonIconProps}
                iconName={ICON_NAMES.wealthbox}
                name={WEALTHBOX_INTEGRATION}
                hideMenu={!canEditIntegrations}
              />
            )
          }
          {
            integrationsList.includes(REDTAIL_INTEGRATION) && (
              <IntegrationCard
                showButton={canEditIntegrations}
                isAuthorized={isRedtailAuthorized}
                authAction={redirectToRedtailLoginPage}
                classes={classes}
                title='Redtail'
                subtitle='Sync clients, tasks and meetings'
                iconProps={commonIconProps}
                iconName={ICON_NAMES.redtail}
                name={REDTAIL_INTEGRATION}
                hideMenu={!canEditIntegrations}
              />)
          }
          {
            integrationsList.includes(JUNXURE_INTEGRATION) && (
              <IntegrationCard
                comingSoon
                hideMenu
                authAction={redirectToSalesforceLoginPage}
                classes={classes}
                title='AdvisorEngine Junxure'
                subtitle='Sync clients, tasks and meetings'
                iconProps={salesforceIconProps}
                iconName={ICON_NAMES.junxure}
                name={JUNXURE_INTEGRATION}
              />
            )
          }
        </IntegrationsGroup>
        <IntegrationsGroup title='Planning Tools' display={hasPlanningToolsIntegrations}>
          {
            integrationsList.includes(MGP_INTEGRATION) && (
              <IntegrationCard
                isAuthorized
                hideMenu
                classes={classes}
                title='MoneyGuidePro'
                subtitle='Outbound Account Balance & Position Data Feed'
                iconProps={commonIconProps}
                iconName={ICON_NAMES.moneyGuidePro}
                name={MGP_INTEGRATION}
              />
            )
          }
          {
            integrationsList.includes(EMONEY_INTEGRATION) && (
              <IntegrationCard
                isAuthorized
                hideMenu
                classes={classes}
                title='eMoney'
                subtitle='Outbound Account Balance, Tax Lot and Transaction Feed'
                iconProps={riskalyzeIconProps}
                iconName={ICON_NAMES.eMoney}
                name={EMONEY_INTEGRATION}
              />)
          }
          {
            integrationsList.includes(RIGHT_CAPITAL_INTEGRATION) && (
              <IntegrationCard
                hideMenu
                comingSoon
                classes={classes}
                title='RightCapital'
                subtitle='Outbound Account Balance & Position Data Feed'
                iconProps={commonIconProps}
                iconName={ICON_NAMES.rightCapital}
                name={RIGHT_CAPITAL_INTEGRATION}
              />
            )
          }
          {
            integrationsList.includes(NITROGEN_INTEGRATION) && (
              <IntegrationCard
                hideMenu
                comingSoon
                classes={classes}
                title='Nitrogen'
                subtitle='Outbound Account Balance & Position Data Feed'
                iconProps={commonIconProps}
                iconName={ICON_NAMES.nitrogen}
                name={NITROGEN_INTEGRATION}
              />)
          }
          {
            integrationsList.includes(RISKALYZE_INTEGRATION) && (
              <IntegrationCard
                hideMenu
                comingSoon
                classes={classes}
                title='Riskalyze'
                subtitle='Outbound Account Balance & Position Data Feed'
                iconProps={riskalyzeIconProps}
                iconName={ICON_NAMES.riskalyze}
                name={RISKALYZE_INTEGRATION}
              />)
          }
        </IntegrationsGroup>
        <IntegrationsGroup title='Compliance' display={hasComplianceIntegrations}>
          {
            integrationsList.includes(SMARSH_INTEGRATION) && (
              <IntegrationCard
                comingSoon
                hideMenu
                classes={classes}
                title='Smarsh'
                subtitle='Outbound client communication records for archival'
                iconProps={commonIconProps}
                iconName={ICON_NAMES.smarsh}
                name={SMARSH_INTEGRATION}
              />
            )
          }
          {
            integrationsList.includes(GLOBAL_RELAY_INTEGRATION) && (
              <IntegrationCard
                comingSoon
                hideMenu
                classes={classes}
                title='Global Relay'
                subtitle='Outbound client communication records for archival'
                iconProps={fidelityIconProps}
                iconName={ICON_NAMES.globalRelay}
                name={GLOBAL_RELAY_INTEGRATION}
              />
            )
          }
          {
            integrationsList.includes(RIA_BOX_INTEGRATION) && (
              <IntegrationCard
                comingSoon
                hideMenu
                classes={classes}
                title='RIA in a Box'
                subtitle='Outbound client communication records for archival'
                iconName={ICON_NAMES.ria}
                iconProps={commonIconProps}
                name={RIA_BOX_INTEGRATION}
              />
            )
          }
        </IntegrationsGroup>
        <IntegrationsGroup title='Trading' display={hasTradingIntegrations}>
          {
            integrationsList.includes(I_REBAL_INTEGRATION) && (
              <IntegrationCard
                comingSoon
                hideMenu
                classes={classes}
                title='iRebal'
                subtitle='Outbound account, position and tax lot data'
                iconProps={iRebalIconProps}
                iconName={ICON_NAMES.iRebal}
                name={I_REBAL_INTEGRATION}
              />
            )
          }
        </IntegrationsGroup>
        <IntegrationsGroup title='Select Custodians + Aggregation Providers' display={hasCustodialIntegrations}>
          {
            integrationsList.includes(SCHWAB_INTEGRATION) && (
              <IntegrationCard
                isAuthorized
                hideMenu
                classes={classes}
                title='Charles Schwab'
                subtitle='Inbound account, position and transaction data feeds'
                iconProps={commonIconProps}
                iconName={ICON_NAMES.schwab}
                name={SCHWAB_INTEGRATION}
              />
            )
          }
          {
            integrationsList.includes(FIDELITY_INTEGRATION) && (
              <IntegrationCard
                isAuthorized
                hideMenu
                classes={classes}
                title='Fidelity'
                subtitle='Inbound account, position and transaction data feeds'
                iconProps={fidelityIconProps}
                iconName={ICON_NAMES.fidelity}
                name={FIDELITY_INTEGRATION}
              />
            )
          }
          {
            integrationsList.includes(MONRNINGSTAR_INTEGRATION) && (
              <IntegrationCard
                isAuthorized
                hideMenu
                classes={classes}
                title='Morningstar ByAll'
                subtitle='Inbound account, position and transaction data feeds'
                iconProps={fidelityIconProps}
                iconName={ICON_NAMES.morningstar}
                name={MONRNINGSTAR_INTEGRATION}
              />
            )
          }
          {
            integrationsList.includes(DST_INTEGRATION) && (
              <IntegrationCard
                isAuthorized
                hideMenu
                classes={classes}
                title='DST'
                subtitle='Inbound account, position and transaction data feeds'
                iconProps={fidelityIconProps}
                iconName={ICON_NAMES.dst}
                name={DST_INTEGRATION}
              />
            )
          }
        </IntegrationsGroup>
        {isRedtailModalOpen && (
          <RedtailAuthModal
            isAuthorized={isRedtailAuthorized}
            onCloseHandler={onCloseRedtailModalHandler}
            isModalOpen={isRedtailModalOpen}
            onClickAway={setIsRedtailModalOpen.off}
            onSuccessAuthorization={onSuccessRedtailAuth}
          />
        )}
      </Box>
      <IntegrationsSwitcher
        canSwitchCrm={canSwitchCrm}
        canEditIntegrations={canEditIntegrations}
        activeCrm={activeCrm}
        onActiveCrmChange={onActiveCrmChange}
      />
    </IntegrationsLayout>
  )
}

export default IntegrationsView
