import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import { CALC_TYPES, LEVEL_TYPES } from '../../../../constants'
import { useRelativeDateRange } from '../../../molecules/RelativeDateSelect'
import { useAvailableDates } from '../../../../redux/slices/appContext'
import { useGetGroupedCoreData } from '../../../../api/coreData'
import Text from '../../../atoms/Text'
import { useAdvisorHome } from '../AdvisorHomeContext'
import Metric from './Metric'

const useStyles = makeStyles(() => ({
  description: {
    display: 'flex',
    alignItems: 'center',
    gap: '0.25rem'
  }
}))

const CashReportMetric = ({
  defaultDateRange,
  valueFormat,
  valueDescription,
  levelFilter,
  levelFilterId,
  redirectTo
}) => {
  const classes = useStyles()
  const { scope } = useAdvisorHome()
  const [availableDates, loadingAvailableDates] = useAvailableDates()
  const { dateRange } = useRelativeDateRange(defaultDateRange, availableDates)

  const query = useMemo(() => {
    if (!dateRange) return null

    const scopeFilters = {}
    if (scope) {
      scopeFilters.advisorIds = scope.values
      scopeFilters.levelTypes = [LEVEL_TYPES.ADVISOR]
    }

    const additionalFilter =
      levelFilter && levelFilterId ? { [levelFilter]: [levelFilterId] } : {}

    return {
      dateRange: {
        startDate: dateRange.startDate,
        endDate: dateRange.endDate
      },
      levelFilters: {
        ...additionalFilter,
        ...scopeFilters,
        calcType: CALC_TYPES.performance
      }
    }
  }, [scope, dateRange, levelFilter, levelFilterId])

  const { data, isLoading } = useGetGroupedCoreData(query)

  if (loadingAvailableDates) return null

  return (
    <Metric.Wrapper redirectTo={redirectTo}>
      <Metric
        isLoading={isLoading}
        value={data?.endingValue}
        valueFormat={valueFormat}
        description={
          <div className={classes.description}>
            <Metric
              value={data?.flowChange}
              valueSize='1rem'
              valueFormat={valueFormat}
              includeSymbol
            />
            <Text text={valueDescription} />
          </div>
        }
      />
    </Metric.Wrapper>
  )
}

CashReportMetric.propTypes = {
  levelFilter: PropTypes.string,
  levelFilterId: PropTypes.number,
  defaultDateRange: PropTypes.string,
  valueFormat: PropTypes.string,
  valueDescription: PropTypes.string,
  redirectTo: PropTypes.string
}

CashReportMetric.defaultProps = {
  levelFilter: undefined,
  levelFilterId: undefined,
  defaultDateRange: 'L7D',
  valueFormat: 'human',
  valueDescription: 'in the last 7 days',
  redirectTo: undefined
}

export default CashReportMetric
