import { makeStyles } from '@material-ui/core'
import React from 'react'
import PropTypes from 'prop-types'
import CheckIcon from '@material-ui/icons/Check'
import Avatar from '../../../atoms/Avatar'
import Text from '../../../atoms/Text'

const useStyles = makeStyles(() => ({
  container: {
    maxWidth: '30rem',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '1rem 1.5rem',
    cursor: 'pointer',
    '&:hover': {
      background: '#EEF0F8'
    }
  },
  content: {
    marginLeft: '0.75rem'
  },
  secondaryContent: {
    marginLeft: 'auto',
    display: 'flex',
    flexDirection: 'column'
  },
  title: {
    fontSize: '0.875rem',
    fontWeight: '600'
  },
  adornment: ({ checked }) => ({
    marginLeft: 'auto',
    ...(checked ? { '& > svg': { color: '#3AA76D' } } : {})
  })
}))

const MenuItem = ({ item, showAvatar, checked, secondaryContent, onChange }) => {
  const classes = useStyles({ checked })
  const { id, name, imageUrl, description } = item

  return (
    <div className={classes.container} onClick={(event) => onChange(event, id)}>
      {showAvatar && (
        <Avatar customSize='2rem' avatarLetters={name} src={imageUrl} />
      )}
      <div className={classes.content}>
        <Text text={name} className={classes.title} />
        <Text text={description} />
      </div>
      {secondaryContent && (
        <div className={classes.secondaryContent}>
          {secondaryContent}
        </div>
      )}
      {checked && (
        <div className={classes.adornment}>
          <CheckIcon />
        </div>
      )}
    </div>
  )
}

MenuItem.propTypes = {
  item: PropTypes.object,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  showAvatar: PropTypes.bool,
  secondaryContent: PropTypes.node
}

export default MenuItem
