import React, { useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { CircularProgress } from '@material-ui/core'
import SydButton from '../../../../../commonDesign/Button'
import DebouncedInput from '../../../../../molecules/DebouncedInput'
import { useComponentModels } from '../../../../../../api/rebalancer'
import SydLabel from '../../../../../commonDesign/SydLabel'
import SydInput from '../../../../../commonDesign/SydInput'
import { componentModelItem, modelItem, useDialogStyles } from './common'

function EditComponentModelForm ({ onComplete, onCancel, item, classificationTagTypeId }) {
  const classes = useDialogStyles()

  const [searchText, setSearchText] = useState()
  const query = useMemo(() => {
    return {
      filters: {
        classificationTagTypeId
      },
      textFilters: {
        name: [{ op: 'contains', value: searchText }]
      },
      take: 20
    }
  }, [searchText, classificationTagTypeId])
  const { data, isFetching } = useComponentModels(query)
  const weightRef = useRef()

  return (
    <>
      <div className={classes.form}>
        <div className={classes.info}>
          <div>Current Component Model</div>
          <div><span>{item.name}</span></div>
        </div>
        <div className={classes.formtings}>
          <SydLabel label='Search'>
            <DebouncedInput onChange={setSearchText} placeholder='Search Name of Component Model' style={{ height: 'unset', minHeight: '54px' }} />
          </SydLabel>
          <SydLabel label='Weight (bps)' description='E.g. enter 5.25% as 525'>
            <SydInput ref={weightRef} type='number' minWidth='0' defaultValue={item.weight.toString()} />
          </SydLabel>
        </div>
        <div>
          {isFetching ? (
            <CircularProgress />
          ) : data?.length ? (
            <div className={classes.list}>
              {data.map(x => (
                <div className={classes.listItem} key={x.componentModelId}>
                  <div>[{x.componentModelId}] - {x.name}</div>
                  <SydButton
                    size='xs' variant='primary' onClick={() => {
                      onComplete(componentModelItem(x, weightRef.current.value))
                    }}
                  >Replace
                  </SydButton>
                </div>
              ))}
            </div>
          ) : (
            <div>No Results</div>
          )}
        </div>
      </div>
      <div className={classes.actions}>
        <SydButton variant='ghost' size='lg' onClick={onCancel}>Cancel</SydButton>
        <SydButton
          variant='primary'
          size='lg'
          onClick={() => {
            onComplete(modelItem(item, weightRef.current.value))
          }}
        >
          Save
        </SydButton>
      </div>
    </>
  )
}

EditComponentModelForm.propTypes = {
  onComplete: PropTypes.func,
  onCancel: PropTypes.func,
  item: PropTypes.object,
  classificationTagTypeId: PropTypes.number
}

export default EditComponentModelForm
