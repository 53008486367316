import React, { useCallback, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import ArrowBackOutlinedIcon from '@material-ui/icons/ArrowBack'
import { makeStyles } from '@material-ui/core/styles'
import { Box } from '@material-ui/core'
import NumberFormat from '../../atoms/NumberFormat'
import Text from '../../atoms/Text'
import { useFormattingContext } from '../FormattingProvider/FormattingContext'
import {
  VisualBalanceHoldingTableColumnsShape,
  VisualBalanceHoldingTableDefaultSortShape,
  VisualBalanceSubgroupShape
} from './utils'
import VisualBalanceHoldingsTable from './VisualBalanceHoldingsTable'
import SectionedBar from './SectionedBar'

const useStyles = makeStyles((theme) => ({
  backButton: {
    cursor: 'pointer',
    flexDirection: 'row',
    alignItems: 'center',
    height: '100%',
    marginRight: '10px',
    display: 'flex'
  },
  blockChart: () => ({
    width: '100%',
    display: 'flex',
    flexDirection: 'column'
  }),
  topContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
    height: '56px',
    marginBottom: '37px'
  },
  bottomContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  tableContainer: {
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
    marginTop: '50px'
  },
  rightAligned: {
    fontSize: '14px',
    textAlign: 'right',
    padding: '6px 12px',
    fontWeight: 400,
    lineHeight: '30px'
  },
  leftAligned: {
    fontSize: '14px',
    textAlign: 'left',
    padding: '6px 12px',
    fontWeight: 400,
    lineHeight: '30px'
  },
  [theme.breakpoints.down('xs')]: {
    topContainer: {
      marginRight: 90,
      width: 'auto',
      height: 'auto',
      alignItems: 'center',
      '& p': {
        fontSize: '1.5rem !important'
      }
    },
    backButtonText: {
      display: 'none'
    },
    groupContainer: {
      height: 'auto !important'
    }
  }
}))

function VisualBalanceDrillDown ({
  onClickBack,
  onPressAccount,
  selectedBlock,
  backButtonText,
  splitAssetClassTagIds,
  holdingTableColumns,
  holdingTableDefaultSort,
  overviewFormat,
  maxBlocksPerGroup,
  otherLabel
}) {
  const classes = useStyles()
  const [selectedAccountId, setSelectedAccountId] = useState(undefined)
  const { formatter } = useFormattingContext()

  const onBack = useCallback(() => {
    setSelectedAccountId(undefined)
    onPressAccount(null)
    onClickBack()
  }, [
    onClickBack,
    onPressAccount,
    setSelectedAccountId
  ])

  const onClickBlock = useCallback((block) => {
    if (block?.accountId === selectedAccountId) {
      setSelectedAccountId(undefined)
      onPressAccount({})
    } else {
      setSelectedAccountId(block?.accountId)
      onPressAccount(block)
    }
  }, [selectedAccountId, setSelectedAccountId, onPressAccount])

  const drillDownBlock = useMemo(() => {
    if (!selectedBlock) return null
    const backgroundColor = selectedBlock?.colorField || '#C4E4D3'
    return (
      <div className={classes.blockChart}>
        <div className={classes.topContainer}>
          <div onClick={onBack} className={classes.backButton}>
            <ArrowBackOutlinedIcon />
            <Text className={classes.backButtonText} customFontSize='1rem' text={`Back to ${backButtonText}`} />
          </div>
          <Box sx={{ display: { xs: 'block', sm: 'flex' }, flexDirection: 'row' }}>
            <div style={{ marginRight: '6px' }}>
              <Text
                customFontSize='2rem'
                customFontWeight='bold'
                text={
                  <NumberFormat
                    number={formatter(selectedBlock.total, overviewFormat)}
                    title={formatter(selectedBlock.total, overviewFormat)}
                    skipFormat
                  />
                }
              />
            </div>
            <Text customFontSize='2rem' text={` in ${selectedBlock.displayName}`} />
          </Box>
          <div />
        </div>
        <div className={classes.bottomContainer}>
          <div
            className={classes.groupContainer}
            key={`drilldown-${selectedBlock.accountCategoryGroupId ?? selectedBlock.assetClassTagId}`}
          >
            <SectionedBar
              backgroundColor={backgroundColor}
              blocks={selectedBlock.accounts}
              selectedBlockId={selectedAccountId}
              onClick={(item) => onClickBlock(item)}
              titleFormat='0.00a'
              useStripedBackground={selectedBlock.isStriped ?? false}
              maxBlocksPerGroup={maxBlocksPerGroup}
              otherLabel={otherLabel}
            />

          </div>
        </div>
      </div>
    )
  }, [
    selectedBlock,
    selectedAccountId,
    classes.blockChart,
    classes.topContainer,
    classes.backButton,
    classes.bottomContainer,
    classes.backButtonText,
    classes.groupContainer,
    onBack,
    backButtonText,
    formatter,
    onClickBlock,
    overviewFormat,
    maxBlocksPerGroup,
    otherLabel
  ])

  return (
    <>
      {drillDownBlock}
      <VisualBalanceHoldingsTable
        splitAssetClassTagIds={splitAssetClassTagIds}
        columns={holdingTableColumns}
        defaultSort={holdingTableDefaultSort}
      />
    </>
  )
}

VisualBalanceDrillDown.propTypes = {
  onClickBack: PropTypes.func,
  onPressAccount: PropTypes.func,
  selectedBlock: PropTypes.shape(VisualBalanceSubgroupShape),
  backButtonText: PropTypes.string,
  splitAssetClassTagIds: PropTypes.arrayOf(PropTypes.number),
  holdingTableColumns: PropTypes.arrayOf(VisualBalanceHoldingTableColumnsShape),
  holdingTableDefaultSort: PropTypes.arrayOf(VisualBalanceHoldingTableDefaultSortShape),
  overviewFormat: PropTypes.string,
  maxBlocksPerGroup: PropTypes.number,
  otherLabel: PropTypes.string
}

VisualBalanceDrillDown.defaultProps = {
  onClickBack: noop,
  onPressAccount: noop,
  selectedBlock: undefined,
  backButtonText: 'Breakdown',
  otherLabel: 'Other'
}

export default VisualBalanceDrillDown
