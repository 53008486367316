import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import isObject from 'lodash/isObject'
import BaseCell from './BaseCell'
import LinkCell from './LinkCell'

export const CELL_TYPES = {
  link: LinkCell,
  baseCell: BaseCell
}

export const isCellComponent = (cellComponent) => {
  return cellComponent && Object.keys(CELL_TYPES).includes(cellComponent?.type || cellComponent)
}

export const getCellComponentType = (cellComponent) => {
  if (isObject(cellComponent)) {
    const { type, ...payload } = cellComponent
    if (type && Object.keys(CELL_TYPES).includes(type)) {
      return { type, payload }
    }
  }
  return { type: cellComponent, payload: {} }
}

const CellPicker = (props) => {
  const { column } = props

  const CellType = useMemo(() => {
    const cellType = CELL_TYPES[column.cellType]
    if (!cellType) {
      throw Error(`[CellPicker]: cellType {${column?.cellType}} is not a valid option`)
    }
    return cellType
  }, [column.cellType])

  return <CellType {...props} />
}

CellPicker.propTypes = {
  column: PropTypes.shape({
    cellType: PropTypes.oneOf(Object.keys(CELL_TYPES))
  })
}

export default CellPicker
