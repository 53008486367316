import React, { useCallback } from 'react'
import { useContextContainer } from '../../../../abundanceEngine/components/ContextContainer'
import { CLIENT_VIEW_CONTEXT } from '..'
import SearchBar from '../../OperationalTable/SearchBar'

const ClientSearchBar = () => {
  const [, setClientViewContext] = useContextContainer(CLIENT_VIEW_CONTEXT)

  const onSearchChange = useCallback(
    (value) => {
      setClientViewContext((prevState) => ({
        ...prevState,
        queryText: value
      }))
    },
    [setClientViewContext]
  )

  return (
    <SearchBar placeholder='Search client name' onChange={onSearchChange} />
  )
}

export default ClientSearchBar
