import { useCallback, useEffect, useMemo, useState } from 'react'
import isEmpty from 'lodash/isEmpty'
import { useAppContext } from '../../../redux/slices/appContext'
import { useCoreTableDataMultiple, useNormalizeDates } from '../../../api/coreData'
import { useRelativeDateRanges } from '../../molecules/RelativeDateSelect'

/**
 * Transforms a list of groupings ['client', 'accountObjective', 'account']
 * into a list of groupings, grouped by depth - used for requesting to core data
 * example output: [['client'], ['client', 'accountObjective'], ['client', 'accountObjective', 'account']
 * @param {[]} groupings
 */
const extractLevelGroupings = (groupings) => {
  const result = []
  for (let i = 0; i < groupings.length; ++i) {
    const inner = []
    for (let j = 0; j <= i; ++j) {
      inner.push(groupings[j])
    }
    result.push(inner)
  }

  return result
}

/** determines if a benchmark should be applied at a level */
const mapBenchmark = (benchmarks, level) => {
  if (benchmarks.includes(level)) {
    return {
      benchmarkType: level
    }
  }
  return undefined
}

const compareDepth = (a, b) => b.depth - a.depth

/**
 * Recursively maps a flattened list of items into a tree-like structure
 */
const mapSubRows = ({
  parent,
  items,
  allItems,
  depth = 1,
  createRequestForGrouping,
  levelGroupings
}) => {
  if (depth > 10) return []
  return items.reduce((prev, cur) => {
    const nextDepth = depth + 1
    const children = allItems.filter(x => x.depth === (depth + 1) && x.uniqueId.startsWith(cur.uniqueId))

    const maxDepth = levelGroupings.length
    const additionalFilters = {
      ...(parent?._next?.levelFilters || {})
    }
    const _next = nextDepth >= maxDepth ? null : createRequestForGrouping(levelGroupings[nextDepth], {
      ...additionalFilters,
      [`${cur.levelType}Ids`]: [cur.levelId]
    })

    cur._next = _next

    const subRows = mapSubRows({
      parent: cur,
      items: children,
      allItems,
      depth: nextDepth,
      createRequestForGrouping,
      levelGroupings
    })

    prev.push({
      ...cur,
      subRows,
      _subRowsFetched: !!subRows?.length
    })
    return prev
  }, [])
}

/** Take a total value and apply an allocation to a tree of items */
const mapApplyAllocation = (item, valueAccessor, total) => {
  if (!total) return

  const value = +valueAccessor(item)
  item.allocation = value / total
  if (Array.isArray(item.subRows)) {
    item.subRows.forEach(subItem => mapApplyAllocation(subItem, valueAccessor, total))
  }
}

export const updateTreeItemByIndexPath = (tree, initialNodeIndexPath = [], value = {}) => {
  const nodeIndexPath = [...initialNodeIndexPath]
  const nodeIndex = nodeIndexPath.shift()
  if (nodeIndex === undefined) return tree
  if (isEmpty(tree)) return tree

  const node = tree[nodeIndex] || [{}]
  return [
    ...tree.slice(0, nodeIndex),
    {
      ...node,
      subRows: updateTreeItemByIndexPath(
        node.subRows,
        [...nodeIndexPath],
        value
      ),
      ...(nodeIndexPath.length === 0 ? { ...value } : {})
    },
    ...tree.slice(nodeIndex + 1)
  ]
}

/** Recursively searches for an item at a particular depth in a tree */
const findTreeItem = (tree, { depth, uniqueId }, itemIndexPath = []) => {
  const maybeItem = tree.find(x => uniqueId.startsWith(x.uniqueId))
  itemIndexPath.push(tree.indexOf(maybeItem))
  if (!maybeItem) return null
  if (maybeItem.depth === depth) return { item: maybeItem, itemIndexPath }
  return findTreeItem(maybeItem.subRows, { depth, uniqueId }, itemIndexPath)
}

const findAndUpdateTreeItem = (prevTree, item, itemOverrides = {}) => {
  const { itemIndexPath } = findTreeItem(prevTree, item)
  const prevTreeModified = updateTreeItemByIndexPath(prevTree, itemIndexPath, itemOverrides)
  return prevTreeModified
}

const traverseAndUpdateTree = (tree, item) => {
  const treeModified = tree.map(node => ({
    ...node,
    ...item,
    subRows: node.subRows ? traverseAndUpdateTree(node.subRows, item) : []
  }))
  return treeModified
}

const mapIncludes = (includes, availableDates) => {
  return includes.reduce(
    (acc, include) => ({
      ...acc,
      [include]: {
        [include]: {
          startDate: availableDates.mainDate,
          endDate: availableDates.mainDate
        }
      }
    }),
    {}
  )
}

const usePerformanceTreeData = ({
  /** A filter to apply to all requests to core data */
  defaultFilter,

  /** An array of levels defining the tree structure of the table */
  groupings,

  /** An array of levels that should have benchmarks assigned / shown */
  benchmarks,

  /** (Not yet implemented) */
  balanceDateRanges,

  /** An array of relative date range identifiers (MTD, QTD, etc...), table updates if its value changes */
  performanceDateRanges: _performanceDateRanges,

  /** An array of relative date range identifiers (MTD, QTD, etc...) */
  beginningValueDateRanges = [],

  /** An array of relative date range identifiers (MTD, QTD, etc...) */
  defaultPerformanceDateRanges,

  /** A list of relative date range options */
  dateRangeOptions,

  /** An accessor to calculate allocation to total */
  allocationAccessor,

  /** The depth of request the initial data */
  initialDepth = 2,

  /** The depth to expand the table to on initial load */
  initialExpandDepth = 1,

  /** Expands or collapse the whole table */
  expanded = true,

  /** An array of data set keys, included on the grouping request to retrieve the provided datasets */
  includes = [],

  /** An array of relative date range identifier (MTD, QTD, etc...) */
  rglDateRanges: _rglDateRanges = [],

  /** Which default scope to apply the data */
  scope = 'client',

  /** rowFilters is for post processing rows */
  rowFilters = undefined,

  /** ratings is for asset ratings */
  ratings = undefined
}) => {
  const { clientId, advisorId, availableDates, loadingAvailableDates } = useAppContext()
  const [performanceDateRanges, setPerformanceDateRanges] = useState(defaultPerformanceDateRanges)
  const {
    dateRanges: performanceRanges,
    options: performanceDateRangeOptions
  } = useRelativeDateRanges(performanceDateRanges, availableDates, dateRangeOptions)

  const normalizedDatesQuery = useMemo(() => {
    const dateRanges = performanceRanges.reduce((acc, { key, dateRange }) => ({ ...acc, [key]: { key, ...dateRange } }), {
      balanceRange: {
        startDate: availableDates.mainDate,
        endDate: availableDates.mainDate
      }
    })
    return {
      query: { dateRanges },
      queryOptions: {
        mapper: (data) => {
          const { balanceRange, ...performanceRanges } = data
          return {
            balanceRange,
            performanceRanges
          }
        }
      }
    }
  }, [performanceRanges, availableDates])

  const {
    data: normalizedDateRanges,
    isLoading: isLoadingNormalizedDates
  } = useNormalizeDates(
    normalizedDatesQuery.query,
    normalizedDatesQuery.queryOptions
  )

  const { dateRanges: rglDateRanges } = useRelativeDateRanges(_rglDateRanges, availableDates, dateRangeOptions)
  const levelGroupings = useMemo(() => extractLevelGroupings(groupings), [groupings])

  /** (Not Yet Used) */
  const onPerformanceDateRangeSelected = useCallback((index, dr) => {
    setPerformanceDateRanges(prev => {
      const result = [...prev]
      result[index] = dr
      return result
    })
  }, [setPerformanceDateRanges])

  useEffect(() => {
    setPerformanceDateRanges([..._performanceDateRanges])
  }, [_performanceDateRanges])

  /**
   * Creates a request for the current context at a given level grouping
   */
  const createRequestForGrouping = useCallback(
    (levelGrouping, additionalFilters = undefined) => {
      return {
        levelFilters: {
          ...defaultFilter,
          ...(additionalFilters || {}),
          clientIds: scope === 'client' ? [clientId] : [],
          advisorIds: scope === 'advisor' ? [advisorId] : [],
          levelTypes: levelGrouping,
          benchmark: mapBenchmark(benchmarks, levelGrouping.at(-1))
        },
        rowFilters: rowFilters ?? undefined,
        tableDateRanges: {
          balanceColumns: balanceDateRanges.reduce((a, x) => {
            a[x] = {
              startDate: normalizedDateRanges?.balanceRange.startDate,
              endDate: normalizedDateRanges?.balanceRange.endDate
            }
            return a
          }, {}),
          performanceColumns: normalizedDateRanges?.performanceRanges,
          rgl: rglDateRanges?.reduce((a, x) => {
            a[`${x.key}_RGL`] = x.dateRange
            return a
          }, {}),
          ...mapIncludes(includes, availableDates)
        },
        options: {
          includeBeginningValue: beginningValueDateRanges || [],
          includeRatings: ratings || []
        }
      }
    },
    [
      scope,
      includes,
      clientId,
      advisorId,
      benchmarks,
      rglDateRanges,
      defaultFilter,
      availableDates,
      balanceDateRanges,
      normalizedDateRanges,
      beginningValueDateRanges,
      rowFilters,
      ratings
    ]
  )

  /** Does this item have a benchmark? */
  const hasBenchmark = useCallback((item) => {
    if (balanceDateRanges?.length) {
      return !!item[balanceDateRanges.at(0)]?.benchmarkId
    }
    if (performanceDateRanges?.length) {
      return !!item[performanceDateRanges.at(0).key]?.benchmarkId
    }
    return false
  }, [balanceDateRanges, performanceDateRanges])

  const applyAllocation = useCallback((items) => {
    if (!allocationAccessor) return undefined
    const accessor = (x) => allocationAccessor.split('.').reduce((p, c) => p ? p[c] : p, x)
    if (Array.isArray(items)) {
      items.forEach(item => {
        const total = +accessor(item)
        mapApplyAllocation(item, accessor, total)
      })
    }

    return items
  }, [allocationAccessor])

  /**
   * Create requests for up to [initialDepth] levels for the performance table
   */
  const baseQueries = useMemo(() => {
    if (loadingAvailableDates || isLoadingNormalizedDates) return []

    // Get the groupings for the first two levels at most. This should be safe if there is only one level
    const requestBase = levelGroupings.slice(0, initialDepth)
    return requestBase.map(levelGrouping => createRequestForGrouping(levelGrouping))
  }, [
    initialDepth,
    levelGroupings,
    loadingAvailableDates,
    isLoadingNormalizedDates,
    createRequestForGrouping
  ])

  /** Get the results for all the queries */
  const { results, fetchMore, isLoading: isAnyLevelLoading } = useCoreTableDataMultiple(baseQueries, {
    enabled: !!baseQueries && !isLoadingNormalizedDates
  })

  /** Link the initial query results into a tree shape */
  const treeLinkedData = useMemo(() => {
    if (isAnyLevelLoading) return []

    const maxDepth = levelGroupings.length - 1
    // flatten the results, applying the depth to the items
    const flattenedResults = results.map(x => x.data).reduceRight((previousValue, currentValue, currentIndex) => {
      const mapped = (currentValue ?? []).map(item => {
        const additionalFilters = {
          [`${item.levelType}Ids`]: [item.levelId]
        }
        const _next = currentIndex >= maxDepth ? null : createRequestForGrouping(levelGroupings[currentIndex + 1], additionalFilters)
        return ({
          ...item,
          depth: currentIndex,
          expanded: currentIndex < initialExpandDepth - 1,
          _next,
          hasBenchmark: hasBenchmark(item)
        })
      })
      return [...previousValue, ...mapped]
    }, []).sort(compareDepth)

    // this algorithm probably sucks
    const linkedResults = flattenedResults.reduce((previousValue, currentValue, currentIndex, array) => {
      if (currentValue.depth > 0) return previousValue

      const children = array.filter(x => x.uniqueId.startsWith(currentValue.uniqueId))
      const nextLevel = children.filter(x => x.depth === 1)
      currentValue.subRows = mapSubRows({
        parent: currentValue,
        items: nextLevel,
        allItems: children,
        depth: 1,
        createRequestForGrouping,
        levelGroupings,
        maxDepth
      })

      currentValue._subRowsFetched = !!currentValue.subRows?.length
      previousValue.push(currentValue)
      return previousValue
    }, [])

    return applyAllocation(linkedResults)
  }, [results, isAnyLevelLoading, createRequestForGrouping, levelGroupings, hasBenchmark, applyAllocation, initialExpandDepth])

  /** We are storing the initial tree results in state, and will be modifying the tree on callbacks */
  const [statefulResult, setStatefulResult] = useState([])
  useEffect(() => {
    setStatefulResult(treeLinkedData)
  }, [setStatefulResult, treeLinkedData])

  /** Callback for expanding the table or fetching more data */
  const onLevelExpand = useCallback(async ({ original: item }) => {
    const nextQuery = item?._next
    // There is no next level to expand to in this case
    if (!nextQuery) return
    if (item?._subRowsFetched) {
      setStatefulResult(prevState => {
        return findAndUpdateTreeItem(prevState, item, {
          expanded: !item.expanded
        })
      })
      return
    }

    // Set loading state
    setStatefulResult(prevState => {
      return findAndUpdateTreeItem(prevState, item, {
        _subRowsFetching: true
      })
    })

    try {
      // Request more data from core data
      const subRows = await fetchMore(nextQuery)
      const nextDepth = item.depth + 1
      const maxDepth = levelGroupings.length - 1
      const mappedDetails = (subRows || []).map(subDetail => {
        let _next = nextDepth === maxDepth ? null : createRequestForGrouping(levelGroupings[nextDepth + 1], {
          [`${subDetail.levelType}Ids`]: [subDetail.levelId]
        })

        if (_next) {
          // aggregate any previous filter that we may be missing
          _next = {
            ..._next,
            levelFilters: {
              ...item._next.levelFilters,
              ..._next.levelFilters
            }
          }
        }

        return {
          ...subDetail,
          depth: nextDepth,
          expanded: false,
          _next,
          hasBenchmark: hasBenchmark(subDetail)
        }
      })

      // Set final state
      setStatefulResult(prevState => {
        const prevStateModified = findAndUpdateTreeItem(prevState, item, {
          _subRowsFetching: false,
          _subRowsFetched: true,
          expanded: true,
          subRows: mappedDetails || []
        })
        return applyAllocation([...prevStateModified])
      })
    } catch (error) {
      console.error(error)
      setStatefulResult(prevState => {
        return findAndUpdateTreeItem(prevState, item, {
          _subRowsFetching: false
        }) // do not need to apply allocation as state has not really changed
      })
    }
  }, [setStatefulResult, fetchMore, levelGroupings, hasBenchmark, applyAllocation, createRequestForGrouping])

  const onExpandLevels = useCallback((expanded) => {
    setStatefulResult((prevState) => {
      return traverseAndUpdateTree(prevState, { expanded: Boolean(expanded) })
    })
  }, [])

  useEffect(() => {
    onExpandLevels(expanded)
  }, [expanded, onExpandLevels])

  return {
    data: statefulResult ?? [],
    isLoading: isAnyLevelLoading,
    performanceDateRangeOptions,
    setPerformanceDateRanges,
    onPerformanceDateRangeSelected,
    onLevelExpand,
    onExpandLevels,
    normalizedDateRanges
  }
}

export default usePerformanceTreeData
