import React from 'react'
import PropTypes from 'prop-types'
import { ScopeDisplayItem } from './ScopeDisplayItem'

const UserScopeDisplay = ({ headingLabel, size, hideAvatars }) => {
  return (
    <ScopeDisplayItem
      name='All Clients'
      label={headingLabel}
      size={size}
      hideAvatar={hideAvatars}
    />
  )
}

UserScopeDisplay.propTypes = {
  headingLabel: PropTypes.string,
  hideAvatars: PropTypes.bool,
  size: PropTypes.oneOf(['sm', 'lg'])
}

export default UserScopeDisplay
