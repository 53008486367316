import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { Controller, useForm } from 'react-hook-form'
import SydLabel from '../../../../commonDesign/SydLabel'
import SydInput from '../../../../commonDesign/SydInput'
import Select from '../../../../molecules/Select'
import SydButton from '../../../../commonDesign/Button'
import { useCreateStatusTemplateItemMutation } from '../../../../../api/groups'
import { mappingOptions, sourceOptions, useDialogStyles } from './common'

const useSubmitter = (form, onComplete, parent) => {
  const { handleSubmit } = form
  const [processing, setProcessing] = useState(false)
  const { mutateAsync: createItem } = useCreateStatusTemplateItemMutation()
  const onSubmit = useCallback(async (formData) => {
    const path = parent ? [...parent.path, parent.codeName] : []
    const translation = formData.translation ? JSON.parse(formData.translation) : null
    const configData = { ...formData.configData, translation }
    const command = {
      item: {
        statusTemplateItemId: formData.statusTemplateItemId,
        statusTemplateId: formData.statusTemplateId,
        ordinal: formData.ordinal || null,
        codeName: formData.codeName,
        displayName: formData.displayName,
        configData,
        mapping: formData.mapping || null,
        source: formData.source || null,
        path,
        linkReference: formData.linkReference || null
      }
    }

    try {
      setProcessing(true)
      const result = await createItem(command)
      onComplete(result)
    } finally {
      setProcessing(false)
    }
  }, [createItem, setProcessing, onComplete, parent])

  const submitter = useCallback(async (e) => {
    const onValid = async (form) => {
      await onSubmit(form)
    }
    const onInvalid = (errors) => {
      console.error(errors)
    }

    const handler = handleSubmit(onValid, onInvalid)
    await handler(e)
  }, [handleSubmit, onSubmit])

  return {
    submitter,
    processing
  }
}

function AddTemplateItemForm ({ onCancel, onComplete, parent, statusTemplateId }) {
  const classes = useDialogStyles()
  const form = useForm({
    mode: 'onChange',
    defaultValues: {
      statusTemplateId: statusTemplateId,
      ordinal: '',
      codeName: '',
      displayName: '',
      configData: {},
      translation: '',
      mapping: null,
      source: null,
      linkReference: ''
    }
  })

  const sourceType = form.watch('source')
  const mappingType = form.watch('mapping')
  const codeName = form.watch('codeName')

  const { submitter, processing } = useSubmitter(form, onComplete, parent)

  return (
    <>
      <div className={classes.form}>
        <div className={classes.description}>Under {parent?.displayName ?? 'root'}</div>
        <div className={classes.group}>
          <Controller
            name='codeName' control={form.control}
            render={(f => (
              <SydLabel label='Code Name' required>
                <SydInput size='sm' {...f.field} />
              </SydLabel>
            ))}
          />
          <Controller
            name='ordinal' control={form.control}
            render={(f => (
              <SydLabel label='Ordinal' required>
                <SydInput size='sm' {...f.field} />
              </SydLabel>
            ))}
          />
        </div>
        <Controller
          name='displayName' control={form.control}
          render={(f => (
            <SydLabel label='Display Name' required>
              <SydInput size='sm' {...f.field} />
            </SydLabel>
          ))}
        />
        <Controller
          name='linkReference' control={form.control}
          render={(f => (
            <SydLabel label='Link'>
              <SydInput size='sm' {...f.field} />
            </SydLabel>
          ))}
        />
        <div className={classes.group}>
          <Controller
            name='mapping' control={form.control}
            render={(f => (
              <SydLabel label='Mapping'>
                <Select fullWidth variant='outlined-rounded' options={mappingOptions} {...f.field} />
              </SydLabel>
            ))}
          />
          <Controller
            name='source' control={form.control}
            render={(f => (
              <SydLabel label='Display Name'>
                <Select fullWidth variant='outlined-rounded' options={sourceOptions} {...f.field} />
              </SydLabel>
            ))}
          />
        </div>
        <div className={classes.group}>
          <Controller
            name='configData.value' control={form.control}
            render={(f => (
              <SydLabel label='Default Status Value'>
                <SydInput size='sm' {...f.field} />
              </SydLabel>
            ))}
          />
          <Controller
            name='configData.codeName' control={form.control}
            render={(f => (
              <SydLabel label='KDP Code'>
                <SydInput size='sm' disabled={sourceType !== 'kdp'} placeholder={codeName} {...f.field} />
              </SydLabel>
            ))}
          />
        </div>
        <div>
          {mappingType === 'translation' ? (
            <Controller
              name='translation' control={form.control}
              render={(f => (
                <SydLabel label='Translation Mapping'>
                  <textarea className={classes.textarea} {...f.field} />
                </SydLabel>
              ))}
            />) : null}
        </div>
      </div>
      <div className={classes.actions}>
        <SydButton disabled={processing} variant='ghost' size='lg' onClick={onCancel}>Cancel</SydButton>
        <SydButton
          variant='primary'
          size='lg'
          onClick={submitter}
          processing={processing}
        >
          Save
        </SydButton>
      </div>
    </>
  )
}

AddTemplateItemForm.propTypes = {
  statusTemplateId: PropTypes.number,
  parent: PropTypes.object,
  onComplete: PropTypes.func,
  onCancel: PropTypes.func
}

export default AddTemplateItemForm
