import React, { useCallback, useState } from 'react'
import { makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import SaveCancelButtons from '../../../molecules/SaveCancelButtons'
import { useWealthOwnerDetails } from './ClientDetailsFormContext'

const useStyles = makeStyles((theme) => ({
  saveCancel: {
    position: 'fixed',
    backgroundColor: theme.palette.white,
    bottom: 0,
    opacity: 1,
    left: '50%',
    padding: '1rem 0',
    transform: 'translate(-50%, 0)',
    width: '100vw',
    zIndex: 2,
    boxShadow: `0px 0px 10px 0px ${theme.palette.gray.main}`,
    animation: '$pullUp 200ms ease-in'
  },
  '@keyframes pullUp': {
    from: {
      transform: 'translate(-50%, 100%)'
    },
    to: {
      transform: 'translate(-50%, 0)'
    }
  }
}))

function SaveCancelFooter ({ onSave, onCancel }) {
  const { editing, cancelEdit, saveDescription, onSave: contextOnSave } = useWealthOwnerDetails()
  const classes = useStyles()
  const [processing, setProcessing] = useState(false)
  const handleCancel = useCallback(() => onCancel ? onCancel() : cancelEdit(), [onCancel, cancelEdit])

  const saveHandler = useCallback(async () => {
    try {
      setProcessing(true)
      await (onSave || contextOnSave)()
      cancelEdit()
    } catch (error) {
      console.error(error)
      if (error?.validationErrors) {
        // this is a form validation error
        return
      }
    } finally {
      setProcessing(false)
    }
  }, [onSave, contextOnSave, cancelEdit, setProcessing])

  if (!editing) return null

  return (
    <SaveCancelButtons
      primaryButtonLabel={saveDescription}
      containerClassName={classes.saveCancel}
      onCancel={handleCancel}
      onSave={saveHandler}
      isSubmitting={processing}
      isFormValid
    />
  )
}

SaveCancelFooter.propTypes = {
  onSave: PropTypes.func,
  onCancel: PropTypes.func
}

export default SaveCancelFooter
