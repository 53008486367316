import React, { useCallback, useMemo, useRef } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Skeleton from '../../../atoms/Skeleton'

const useStyles = makeStyles((theme) => ({
  dataSourceFilter: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',

    '& button': {
      backgroundColor: theme.palette.gray.main,
      border: `1px solid ${theme.palette.gray.darker}`,
      borderRadius: '2px',
      padding: '0 4px'
    },

    '& select': {
      // backgroundColor: theme.palette.gray.main,
      border: `1px solid ${theme.palette.gray.darker}`,
      fontFamily: 'GothamPro,Gotham-Book,"Open Sans",Tahoma,"Segoe UI",sans-serif',
      borderRadius: '8px',
      padding: '6px 8px 6px 8px'
    },

    '& input': {
      border: `1px solid ${theme.palette.gray.darker}`,
      fontFamily: 'GothamPro,Gotham-Book,"Open Sans",Tahoma,"Segoe UI",sans-serif',
      borderRadius: '8px',
      padding: '6px 8px'
    },

    '& button.__apply-button': {
      borderRadius: '30px',
      fontWeight: 800,
      textTransform: 'capitalize',
      padding: '6px 12px',
      backgroundColor: '#212945',
      fontSize: '0.85rem',
      color: '#FFFFFF',
      border: 'none',
      '&:hover': {
        backgroundColor: '#f0f0f0',
        color: '#212945'
      }
    },

    '& > *': {
      marginRight: '12px'
    }
  }
}))

const DataSourceFilter = ({ loading, dataSource, onFilterChange }) => {
  const classes = useStyles()
  const columnOptions = useMemo(() => {
    if (loading) return []
    const objSchema = dataSource.metadata?.schema ?? {}
    if (!objSchema) return { columns: [], defaultSort: [] }
    return Object.entries(objSchema).map(([key, value]) => ({
      label: key,
      value: key
    }))
  }, [loading, dataSource])
  const columnRef = useRef()
  const opRef = useRef()
  const valueRef = useRef()
  const applyFilter = useCallback(() => {
    const column = columnRef.current.value
    const op = opRef.current.value
    const value = valueRef.current.value

    onFilterChange([{ field: column, op, value }])
  }, [columnRef, opRef, valueRef, onFilterChange])

  const clearFilters = useCallback(() => {
    columnRef.current.value = ''
    valueRef.current.value = ''
    onFilterChange([])
  }, [columnRef, valueRef, onFilterChange])

  if (loading) {
    return (
      <Skeleton width={300} height={20} />
    )
  }

  return (
    <div className={classes.dataSourceFilter}>
      <select ref={columnRef}>
        {columnOptions.map(({ label, value }) => (<option key={value} value={value}>{label}</option>))}
      </select>
      <select ref={opRef}>
        <option value='eq'>Equal To</option>
        <option value='gt'>Greater Than</option>
        <option value='gte'>Greater Than / Equal</option>
        <option value='lt'>Less Than</option>
        <option value='lte'>Less Than / Equal</option>
        <option value='neq'>Not Equal To</option>
        <option value='startsWith'>Starts With</option>
        <option value='endsWith'>Ends With</option>
        <option value='contains'>Contains</option>
        <option value='in'>In</option>
        <option value='notIn'>Not In</option>
        <option value='isnull'>Is Null</option>
        <option value='notnull'>Is Not Null</option>
      </select>
      <input type='text' ref={valueRef} />
      <button className='__apply-button' type='button' onClick={applyFilter}>Apply</button>
      <button className='__apply-button' type='button' onClick={clearFilters}>Clear</button>
    </div>
  )
}

DataSourceFilter.propTypes = {
  loading: PropTypes.bool,
  dataSource: PropTypes.object,
  onFilterChange: PropTypes.func
}

DataSourceFilter.defaultProps = {
  onFilterChange: () => {}
}

export default DataSourceFilter
