import { useEffect, useState } from 'react'
import useDebouncedCallback from './useDebouncedCallback'

function useWindowResize (
  delay = 500,
  options = { leading: false, trailing: true }
) {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined
  })

  const debouncedWindowResizeHandler = useDebouncedCallback(
    () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
      })
    },
    delay,
    options
  )

  useEffect(() => {
    window.addEventListener('resize', debouncedWindowResizeHandler)
    return () => {
      window.removeEventListener('resize', debouncedWindowResizeHandler)
    }
  }, [debouncedWindowResizeHandler])

  return windowSize
}

export default useWindowResize
