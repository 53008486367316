import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { useGetAccountStatuses } from '../../../api/accounts'
import { useAppContext } from '../../../redux/slices/appContext'
import Skeleton from '../../atoms/Skeleton'
import SydButton from '../../commonDesign/Button'
import AccountStatusDetailsModal from './AccountStatusDetailsModal'
import { defaultLabels, defaultTableColumns } from './helpers'

const useStyles = makeStyles((theme) => ({
  toggleContainer: {
    padding: '4px 10px',
    borderRadius: '8px'
  },
  hasError: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText
  }
}))

const AccountStatusToggle = ({
  integrationKeys = ['byall'],
  filterToClient = true,
  errorsOnly = true,
  labels = defaultLabels,
  tableColumns = defaultTableColumns,
  updateLinkingRoute
}) => {
  const classes = useStyles()
  const appContext = useAppContext()
  const [showDetails, setShowDetails] = useState(false)

  const queryOptions = useMemo(() => {
    return {
      enabled: (filterToClient && !!appContext?.clientId) && !!integrationKeys?.length
    }
  }, [appContext?.clientId, integrationKeys, filterToClient])
  const queryParams = useMemo(() => {
    return {
      ...(filterToClient && { assignedToClientIds: [appContext?.clientId] }),
      filters: {
        integrationKey: [{ op: 'in', value: integrationKeys }],
        ...(errorsOnly && { isError: [{ op: 'eq', value: errorsOnly }] })
      },
      resultType: 'total'
    }
  }, [appContext?.clientId, integrationKeys, errorsOnly, filterToClient])
  const { data, isLoading, isError } = useGetAccountStatuses(queryOptions, queryParams)

  const defaultSort = useMemo(() => [{ id: 'accountName', desc: true }], [])

  const totalCount = useMemo(() => {
    return (data?.totals?.errorCount ?? 0) + (data?.totals?.successCount ?? 0)
  }, [data])

  const buttonLabel = useMemo(() => {
    if (totalCount === 1) {
      return labels.buttonLabel?.[0] ?? labels.buttonLabel
    }
    return labels.buttonLabel?.[1] ?? labels.buttonLabel?.[0] ?? labels.buttonLabel
  }, [totalCount, labels.buttonLabel])

  if (isError) {
    return ''
  }

  if (isLoading) {
    return <Skeleton width='140px' height='24px' />
  }

  return (
    <>
      <div className={classes.container}>
        <SydButton
          priority={!data?.totals?.errorCount ? 'main' : 'warning'}
          variant='primary'
          size='sm'
          onClick={() => setShowDetails(true)}
        >
          {totalCount} {buttonLabel}
        </SydButton>
      </div>

      {showDetails && (
        <AccountStatusDetailsModal
          errorsOnly={errorsOnly}
          open={showDetails}
          onClose={() => setShowDetails(false)}
          labels={labels}
          integrationKeys={integrationKeys}
          columns={tableColumns}
          defaultSort={defaultSort}
          updateLinkingRoute={updateLinkingRoute}
        />
      )}
    </>
  )
}

AccountStatusToggle.propTypes = {
  integrationKeys: PropTypes.arrayOf(PropTypes.string),
  filterToClient: PropTypes.bool,
  errorsOnly: PropTypes.bool,
  labels: PropTypes.shape({
    buttonLabel: PropTypes.arrayOf(PropTypes.string),
    modalTitle: PropTypes.string,
    noUserActionAvailable: PropTypes.string,
    userActionToggle: PropTypes.string
  }),
  tableColumns: PropTypes.arrayOf(PropTypes.object),
  updateLinkingRoute: PropTypes.func
}

export default AccountStatusToggle
