import React, { Children, useMemo } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core'
import PillButton from '../atoms/PillButton'

export const PillButtonGroupOption = () => null

PillButtonGroupOption.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  children: PropTypes.node.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  value: PropTypes.any.isRequired
}

const useStyles = makeStyles(() => ({
  pillButtonGroup: {
    display: 'flex'
  }
}))

const PillButtonGroup = ({
  selectedValue,
  onSelect,
  name,
  children,
  valueSelector,
  className
}) => {
  const classes = useStyles()
  const options = useMemo(() => {
    const childOptions = Children.toArray(children)
    const length = childOptions.length
    const optionMap = childOptions.map((child, i) => {
      return ({
        children: child.props.children,
        onClick: () => onSelect(child.props.value),
        isInGroup: length > 1,
        isFirstInGroup: length > 1 && i === 0,
        isLastInGroup: length > 1 && i === (length - 1),
        key: `${name}-${i}`,
        selected: valueSelector(child.props.value, selectedValue)
      })
    })

    return optionMap
  }, [children, name, onSelect, selectedValue, valueSelector])

  return (
    <div className={clsx(classes.pillButtonGroup, className)}>
      {options.map((option, i) => (
        <PillButton key={option.key} {...option}>{option.children}</PillButton>
      ))}
    </div>
  )
}

PillButtonGroup.Option = PillButtonGroupOption

PillButtonGroup.propTypes = {
  /** The selected value of the pill button group */
  selectedValue: PropTypes.any,

  /** The default value of the pill button group (if uncontrolled) */
  defaultValue: PropTypes.any,

  /** callback when a value is selected */
  onSelect: PropTypes.func,

  name: PropTypes.string,

  children: PropTypes.oneOfType([
    PropTypes.shape({
      type: PropTypes.oneOf([PillButtonGroupOption])
    }),
    PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.oneOf([PillButtonGroupOption])
      })
    )
  ]),

  /** Determines an equality function to apply to compare values in the PillButtonGroup */
  valueSelector: PropTypes.func,

  className: PropTypes.string
}

PillButtonGroup.defaultProps = {
  selectedValue: null,
  defaultValue: null,
  onSelect: () => {},
  name: 'PillButtonGroup',
  valueSelector: (value, currentValue) => value === currentValue,
  className: ''
}

export default PillButtonGroup
