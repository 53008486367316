import { first, get, isArray, isEmpty, last, noop } from 'lodash'
import { useCallback, useEffect, useMemo, useState } from 'react'
import {
  fetchClassTypesStrategy,
  getFetchParamsByLevelType,
  injectMissingRowsIfAny,
  mapAccountObjectives,
  mapAccountsAccountObjectives,
  mapAssetClasses,
  mapAssetClassesByAccount,
  mapAssetClassesFinalRow,
  mapAssetFinalRow,
  mapDefaultFinalRow,
  mapGeneric,
  mapHoldingsAssetClasses,
  mapHoldingsAssetClassesFinalRow
} from '../../../utils/tableHelper'
import { useDateRangeTableFilter, useBoolean } from '../../../hooks'
import { ASSET_FILTERS, LEVEL_TYPES } from '../../../constants'
import { useAppContext } from '../../../redux/slices/appContext'
import useFetchAssets from '../../../hooks/useFetchAssets'
import useExpandibleTable from '../../../hooks/useExpandibleTable'

const getConfigColumns = (columns, isMultiHeader) =>
  isMultiHeader ? last(columns) : isArray(first(columns))

const ROW_MAPPERS = {
  HOLDINGS: {
    [ASSET_FILTERS.ASSET_CLASSES]: mapHoldingsAssetClasses,
    [ASSET_FILTERS.ASSETS]: mapHoldingsAssetClasses
  },
  ACCOUNTS: {
    [ASSET_FILTERS.ACCOUNT_OBJECTIVES]: mapAccountsAccountObjectives
  }
}

const TOTAL_ROW_MAPPERS = {
  HOLDINGS: {
    [ASSET_FILTERS.ASSET_CLASSES]: mapHoldingsAssetClassesFinalRow
  }
}

const getMapMethod = (
  classTypes,
  defaultMethod = noop,
  rowMappers = ROW_MAPPERS
) => {
  return get(
    rowMappers,
    `${classTypes?.mapperType}.${classTypes?.type}`,
    defaultMethod
  )
}

const usePerformanceTable = ({
  rows: _rows,
  columns: _columns,
  finalRow: _finalRow,
  insertColumnsAt,
  classTypes,
  assetFilters,
  disableFinalRow,
  multiHeaderRows,
  disableAssetFilters,
  showOnlyPercentages,
  accountCategoryFilters,
  balanceInformationDateFilters,
  showDashesWhenNotHeldEntirePeriod,
  finalRowMapMethod = 'asset-classes'
}) => {
  const appContext = useAppContext()
  const { baseFetchAssets } = useFetchAssets()

  const [totalBalance, setTotalBalance] = useState(0)
  const [isLoading, setIsLoading] = useBoolean()

  const {
    rows,
    columns,
    finalRow,
    setRows,
    setFinalRow,
    setColumns,
    modifyColumns,
    recursivelyUpdateRowByIndex
  } = useExpandibleTable({
    rows: _rows,
    columns: _columns,
    finalRow: _finalRow,
    insertColumnsAt
  })

  const {
    dateRangePickers,
    showDateRangePicker,
    onHeaderFilterChange,
    dateRangeFiltersHydrated,
    isCustomDateRangeSelected
  } = useDateRangeTableFilter({
    columns,
    setColumns,
    modifyColumns,
    balanceInformationDateFilters
  })

  const maxExpandibleDepthLevel = useMemo(() => {
    const getMaxExpandibleDepthLevel = ({ childType }, depth = 1) => {
      if (!childType) return depth
      return getMaxExpandibleDepthLevel(childType, depth + 1)
    }
    const maxExpandibleDepth = getMaxExpandibleDepthLevel(classTypes)
    return maxExpandibleDepth
  }, [classTypes])

  const assetFilterParams = useMemo(() => {
    let filterParams = {
      accountCategoryIds: !isEmpty(accountCategoryFilters)
        ? accountCategoryFilters
        : []
    }
    if (disableAssetFilters) {
      return filterParams
    }
    const {
      ASSET_CLASSES,
      SUBCLASSES,
      ASSETS_SUBCLASS,
      ASSETS: _ASSETS
    } = assetFilters
    const ASSETS = ASSETS_SUBCLASS || _ASSETS

    const assetClassTagIds = ASSET_CLASSES ? [ASSET_CLASSES?.id] : []
    const subclassTagIds = SUBCLASSES ? [SUBCLASSES?.id] : []
    const assetIds = ASSETS ? [ASSETS?.id] : []

    const levelTypes = [
      ASSET_CLASSES && LEVEL_TYPES.ASSET_CLASS_TAG,
      ASSETS && LEVEL_TYPES.ASSETS
    ].filter(Boolean)

    filterParams = {
      assetClassTagIds,
      subclassTagIds,
      assetIds,
      ...filterParams,
      levelTypes
    }
    return filterParams
  }, [assetFilters, disableAssetFilters, accountCategoryFilters])

  const {
    rowConfig,
    mapRootRowsStrategy,
    baseFetchParams,
    baseTotalsFetchParams
  } = useMemo(() => {
    const totalFilterParams = {
      accountCategoryIds: !isEmpty(accountCategoryFilters)
        ? accountCategoryFilters
        : []
    }
    const rowConfig = {
      classType: classTypes,
      isExpandible: maxExpandibleDepthLevel > 1
    }

    if (classTypes.type === ASSET_FILTERS.ACCOUNTS) {
      return {
        rowConfig: {
          ...rowConfig,
          ...classTypes?.rowConfig,
          parentValue: appContext.clientId
        },
        baseFetchParams: assetFilterParams,
        baseTotalsFetchParams: totalFilterParams,
        mapRootRowsStrategy: mapAssetClassesByAccount
      }
    }
    if (classTypes.type === ASSET_FILTERS.ACCOUNT_OBJECTIVES) {
      const mapMethod = getMapMethod(classTypes, mapAccountObjectives)

      const accountObjectivesParams = {
        ...assetFilterParams,
        levelTypes: [LEVEL_TYPES.ACCOUNT_OBJECTIVE]
      }
      return {
        rowConfig: {
          ...rowConfig,
          ...classTypes?.rowConfig
        },
        mapRootRowsStrategy: mapMethod,
        baseFetchParams: accountObjectivesParams,
        baseTotalsFetchParams: totalFilterParams
      }
    }
    if (classTypes.type in ASSET_FILTERS) {
      const mapMethod = getMapMethod(classTypes, mapAssetClasses)

      return {
        rowConfig,
        mapRootRowsStrategy: mapMethod,
        baseFetchParams: assetFilterParams,
        baseTotalsFetchParams: totalFilterParams
      }
    }

    return {
      rowConfig,
      mapRootRowsStrategy: mapGeneric({
        idField: classTypes.type.endsWith('Tag')
          ? `${classTypes.type}Id`
          : `${classTypes.type}GroupId`,
        nameFields: [`${classTypes.type}LongName`, `${classTypes.type}Name`]
      }),
      baseFetchParams: {
        ...assetFilterParams,
        levelTypes: [classTypes.type]
      },
      baseTotalsFetchParams: totalFilterParams
    }
  }, [
    appContext.clientId,
    classTypes,
    assetFilterParams,
    accountCategoryFilters,
    maxExpandibleDepthLevel
  ])

  const fetchChildrenRows = useCallback(
    async (rowConfig) => {
      const balanceInformationDataSet = await Promise.all(
        dateRangeFiltersHydrated.map(
          async ({ sourceKey, startDate, endDate, payload }) => {
            const { calcType } = payload || {}
            const assets = await fetchClassTypesStrategy({
              config: rowConfig,
              fetcher: baseFetchAssets,
              baseFetchParams: {
                endDate,
                startDate,
                calcType,
                accountCategoryIds: accountCategoryFilters
              }
            })
            return [sourceKey, assets]
          }
        )
      )
      const balanceInformation = Object.fromEntries(balanceInformationDataSet)

      const todaysReportHydrated = injectMissingRowsIfAny(
        balanceInformationDataSet
      )
      balanceInformation.balanceInformationToday = todaysReportHydrated

      return balanceInformation
    },
    [baseFetchAssets, dateRangeFiltersHydrated, accountCategoryFilters]
  )

  const fetchChildrenFinalRows = useCallback(
    async (rowConfig) => {
      const {
        classType: { payload },
        parentValue,
        parentConfig: { classType: parentClassType }
      } = rowConfig

      if (isCustomDateRangeSelected || !payload?.hasTotalsRow) {
        return
      }
      const { totalFetchParams = {} } = payload

      const { params: fetchParams } = getFetchParamsByLevelType(
        { type: parentClassType.type },
        parentValue,
        totalFetchParams
      )

      const totalBalanceInformationDateSet = await Promise.all(
        dateRangeFiltersHydrated.map(
          async ({ sourceKey, startDate, endDate, payload }) => {
            const { calcType } = payload || {}
            const balanceInformation = await fetchClassTypesStrategy({
              config: rowConfig,
              fetcher: baseFetchAssets,
              baseFetchParams: {
                startDate,
                endDate,
                calcType,
                ...fetchParams
              },
              includeTargetLevelType: false
            })
            return [sourceKey, balanceInformation]
          }
        )
      )
      return Object.fromEntries(totalBalanceInformationDateSet)
    },
    [baseFetchAssets, dateRangeFiltersHydrated, isCustomDateRangeSelected]
  )

  const getChildrenRows = useCallback(
    async (row, rowIndexes) => {
      const rowConfig = last(row)
      const rowDepth = rowIndexes.length
      const maxDepthLevelReached = maxExpandibleDepthLevel === rowDepth
      const maxChildrenDepthLevelReached =
        maxExpandibleDepthLevel - 1 === rowDepth

      const {
        classType: { childType: childClassType }
      } = rowConfig

      const childRowConfig = {
        isExpandible: !maxChildrenDepthLevelReached,
        isExpanded: false,
        isLoading: false,
        parentConfig: rowConfig,
        parentValue: rowConfig.value,
        classType: childClassType,
        showDashesWhenNotHeldEntirePeriod
      }

      const balanceInformationResponse = await fetchChildrenRows(
        childRowConfig
      )
      const balanceInformationResponseFinalRow = await fetchChildrenFinalRows(
        childRowConfig
      )

      const mapMethod = getMapMethod(childClassType, mapAssetClasses)

      const rowsFormatted = mapMethod({
        data: balanceInformationResponse,
        rowConfig: childRowConfig,
        showOnlyPercentages,
        totalBalance
      })

      const finalRowsFormatted = mapAssetFinalRow(
        balanceInformationResponseFinalRow,
        childClassType.type
      )

      return {
        rowsFormatted,
        finalRowsFormatted,
        maxDepthLevelReached
      }
    },
    [
      totalBalance,
      fetchChildrenRows,
      showOnlyPercentages,
      fetchChildrenFinalRows,
      maxExpandibleDepthLevel,
      showDashesWhenNotHeldEntirePeriod
    ]
  )

  const fetchAssetsReport = useCallback(
    async (rowConfig, baseFetchParams, params = {}) => {
      const balanceInformationReport = await Promise.all(
        dateRangeFiltersHydrated.map(
          async ({ sourceKey, startDate, endDate, payload }) => {
            const { calcType } = payload || {}
            const balanceInformation = await fetchClassTypesStrategy({
              fetcher: baseFetchAssets,
              config: rowConfig,
              baseFetchParams: {
                ...baseFetchParams,
                startDate,
                endDate,
                calcType
              },
              ...params
            })
            return [sourceKey, balanceInformation]
          }
        )
      )
      return balanceInformationReport
    },
    [baseFetchAssets, dateRangeFiltersHydrated]
  )

  const fetchAssetsReports = useCallback(async () => {
    const fetchTotalsAssetsReport = !disableFinalRow
      ? fetchAssetsReport
      : Promise.resolve([])

    const assetsReport = await Promise.all([
      fetchAssetsReport(rowConfig, baseFetchParams),
      fetchTotalsAssetsReport(
        { classType: classTypes },
        baseTotalsFetchParams,
        { includeTargetLevelType: false }
      )
    ])
    return assetsReport
  }, [
    rowConfig,
    classTypes,
    disableFinalRow,
    baseFetchParams,
    fetchAssetsReport,
    baseTotalsFetchParams
  ])

  const mapRootRows = useCallback(
    (balanceInformationReport) => {
      const balanceInformationData = Object.fromEntries(
        balanceInformationReport
      )
      const todaysReportHydrated = injectMissingRowsIfAny(
        balanceInformationReport
      )
      balanceInformationData.balanceInformationToday = todaysReportHydrated
      const todaysReport = balanceInformationData.balanceInformationToday

      const total = todaysReport.reduce(
        (acc, { balance }) => acc + balance,
        0
      )
      const configColumns = getConfigColumns(columns, multiHeaderRows)

      const rows = mapRootRowsStrategy({
        data: balanceInformationData,
        rowConfig: {
          ...rowConfig,
          configColumns,
          showDashesWhenNotHeldEntirePeriod
        },
        showOnlyPercentages,
        totalBalance: total
      })
      return { rows, total }
    },
    [
      columns,
      rowConfig,
      multiHeaderRows,
      showOnlyPercentages,
      mapRootRowsStrategy,
      showDashesWhenNotHeldEntirePeriod
    ]
  )

  const mapTotalRows = useCallback(
    (totalBalanceInformationReport) => {
      const configColumns = getConfigColumns(
        columns,
        multiHeaderRows
      )

      let mapMethod = getMapMethod(classTypes, mapAssetClassesFinalRow, TOTAL_ROW_MAPPERS)

      if (finalRowMapMethod === 'default') {
        mapMethod = mapDefaultFinalRow
      }
      const totalRows = !isEmpty(totalBalanceInformationReport)
        ? mapMethod(
          totalBalanceInformationReport,
          configColumns
        )
        : []
      return totalRows
    },
    [columns, classTypes, multiHeaderRows, finalRowMapMethod]
  )

  const onExpandRow = useCallback(async ({
    row,
    rowIndexes,
    isExpanded,
    rowConfig: _rowConfig = {}
  }) => {
    recursivelyUpdateRowByIndex(
      {
        isExpanded,
        hidden: true,
        isLoading: true,
        isExpandible: true,
        ..._rowConfig
      },
      rowIndexes
    )
    const {
      rowsFormatted,
      finalRowsFormatted = [],
      maxDepthLevelReached
    } = await getChildrenRows(row, rowIndexes)

    recursivelyUpdateRowByIndex(
      {
        isExpanded,
        isLoading: false,
        isExpandible: !maxDepthLevelReached,
        rows: rowsFormatted,
        finalRow: finalRowsFormatted,
        ..._rowConfig
      },
      rowIndexes
    )
    const rowConfig = last(row)
    const rowExpandedConfig = {
      value: rowConfig.value,
      isRootRow: rowIndexes.length === 1
    }
    return rowExpandedConfig
  }, [getChildrenRows, recursivelyUpdateRowByIndex])

  const initializeAssetsReport = useCallback(async () => {
    if (isCustomDateRangeSelected || !isEmpty(_rows)) {
      return
    }
    try {
      setIsLoading.on()

      const [
        balanceInformationReport,
        totalBalanceInformationReport
      ] = await fetchAssetsReports()

      const { rows, total } = mapRootRows(balanceInformationReport)
      const totalRows = mapTotalRows(totalBalanceInformationReport)
      setRows(rows)
      setFinalRow(totalRows)
      setTotalBalance(total)
    } catch (err) {
      console.error(err)
    } finally {
      setIsLoading.off()
    }
  }, [
    _rows,
    setRows,
    mapRootRows,
    mapTotalRows,
    setFinalRow,
    setIsLoading,
    fetchAssetsReports,
    isCustomDateRangeSelected
  ])

  useEffect(() => {
    initializeAssetsReport()
  }, [initializeAssetsReport])

  return {
    rows,
    setRows,
    columns,
    finalRow,
    isLoading,
    onExpandRow,
    getChildrenRows,
    dateRangePickers,
    showDateRangePicker,
    onHeaderFilterChange,
    recursivelyUpdateRowByIndex
  }
}

export default usePerformanceTable
