import React, { createContext, useContext } from 'react'
import PropTypes from 'prop-types'

export const DenaliFilterContext = createContext({ filter: {} })

export const useDenaliFilter = () => {
  return useContext(DenaliFilterContext)
}

export function DenaliFilterContextProvider ({ value, children }) {
  return (
    <DenaliFilterContext.Provider value={value}>
      {children}
    </DenaliFilterContext.Provider>
  )
}

DenaliFilterContextProvider.propTypes = {
  value: PropTypes.any,
  children: PropTypes.node
}

DenaliFilterContextProvider.defaultProps = {
}
