import React from 'react'
import PropTypes from 'prop-types'
import { noop } from 'lodash'
import { defaultOptions } from '../../molecules/RelativeDateSelect'
import { GroupingContext, useGroupingContextOptions } from './GroupingContext'

const GroupingProvider = ({
  children,
  groupings,
  groupsExpanded,
  configurationKey,
  groupingDateRanges,
  groupingDateRangeOptions,
  onApplyClick,
  shareLocalStorageAcrossGroupings,
  options,
  columnConfigurations
}) => {
  const contextValue = useGroupingContextOptions(
    groupings,
    groupsExpanded,
    groupingDateRanges,
    groupingDateRangeOptions,
    configurationKey,
    onApplyClick,
    shareLocalStorageAcrossGroupings,
    options,
    columnConfigurations
  )
  return (
    <GroupingContext.Provider value={contextValue}>
      {children}
    </GroupingContext.Provider>
  )
}

GroupingProvider.propTypes = {
  children: PropTypes.node,
  groupings: PropTypes.object,
  groupsExpanded: PropTypes.bool,
  groupingDateRanges: PropTypes.array,
  groupingDateRangeOptions: PropTypes.array,
  configurationKey: PropTypes.string,
  onApplyClick: PropTypes.func,
  shareLocalStorageAcrossGroupings: PropTypes.bool,
  options: PropTypes.shape({
    hideAddAllColumnsButton: PropTypes.bool,
    hiddenGroupings: PropTypes.arrayOf(PropTypes.string)
  }),
  columnConfigurations: PropTypes.shape({
    enabled: PropTypes.bool,
    substitutionOptions: PropTypes.arrayOf(
      PropTypes.shape({
        // selector's placeholder
        placeholder: PropTypes.string,
        // target accessor to be replaced
        defaultValue: PropTypes.string,
        // options to be replaced with, each option has a value = accessor
        // that will replace the defaultValue
        options: PropTypes.arrayOf(
          PropTypes.shape({
            value: PropTypes.string,
            label: PropTypes.string
          })
        )
      })
    )
  })
}

GroupingProvider.defaultProps = {
  groupsExpanded: true,
  groupingDateRanges: [],
  configurationKey: undefined,
  groupingDateRangeOptions: defaultOptions.map(({ value }) => value),
  onApplyClick: noop,
  shareLocalStorageAcrossGroupings: true,
  options: {
    hideAddAllColumnsButton: false,
    hiddenGroupings: []
  },
  columnConfigurations: {
    enabled: false,
    substitutionOptions: []
  }
}

export default GroupingProvider
