import React from 'react'
import { Slider as MuiSlider } from '@material-ui/core'

function Slider ({
  onChange,
  max,
  min,
  step,
  value,
  ...rest
}) {
  return (
    <MuiSlider
      value={value}
      onChange={onChange}
      step={step}
      min={min}
      max={max}
      {...rest}
    />
  )
}

Slider.propTypes = {
  onChange: undefined,
  max: undefined,
  min: undefined,
  step: undefined,
  value: undefined
}

export default Slider
