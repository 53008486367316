import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(() => ({
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    gap: '0.625rem',
    flexWrap: 'wrap',
    justifyContent: 'center'
  },
  divider: {
    width: '100%',
    border: '2px solid #F1F1F1',
    borderRadius: '0.5rem 0.5rem 0 0',
    height: '0.75rem',
    borderBottom: 'none'
  }
}))

const FamilyTreeLevelContainer = ({ children, hasChildren }) => {
  const classes = useStyles({ hasChildren })
  return (
    <>
      <div className={classes.container}>{children}</div>
      {hasChildren && <div className={classes.divider} />}
    </>
  )
}

FamilyTreeLevelContainer.propTypes = {
  children: PropTypes.node,
  hasChildren: PropTypes.bool
}

export default FamilyTreeLevelContainer
