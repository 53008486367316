import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useAdvisorHome } from '../AdvisorHomeContext'
import { useAvailableDates } from '../../../../redux/slices/appContext'
import { CALC_TYPES, LEVEL_TYPES } from '../../../../constants'
import { useGetGroupedCoreData } from '../../../../api/coreData'
import Metric from './Metric'

const NetWorthMetric = ({ title, valueFormat, ...metricProps }) => {
  const { scope } = useAdvisorHome()
  const [availableDates] = useAvailableDates()

  const query = useMemo(
    () => {
      const scopeFilters = {}
      if (scope) {
        scopeFilters.advisorIds = scope.values
      }
      return {
        dateRange: {
          startDate: availableDates.min,
          endDate: availableDates.max
        },
        levelFilters: {
          levelTypes: [LEVEL_TYPES.CLIENT],
          calcType: CALC_TYPES.balance,
          ...scopeFilters
        }
      }
    },
    [availableDates.max, availableDates.min, scope]
  )

  const { data, isLoading, error } = useGetGroupedCoreData(query)

  const advisorAnalytics = useMemo(() => {
    if (error || isLoading) return null
    return {
      netWorthManaged: data?.reduce(
        (acc, { endingValue }) => endingValue + acc,
        0
      )
    }
  }, [error, isLoading, data])

  return (
    <Metric
      header={title}
      isLoading={isLoading}
      valueSize='3.5rem'
      value={advisorAnalytics?.netWorthManaged}
      valueFormat={valueFormat}
      {...metricProps}
    />
  )
}

NetWorthMetric.propTypes = {
  title: PropTypes.string,
  valueFormat: PropTypes.string
}

NetWorthMetric.defaultProps = {
  title: 'Net Worth',
  valueFormat: 'human'
}

export default NetWorthMetric
