import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import get from 'lodash/get'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core'
import FadeIn from '../../molecules/Transitions/FadeIn'
import { useFormattingContext } from '../FormattingProvider/FormattingContext'
import { useTransactionsContext } from './TransactionsProvider'
import { useTransactionsTotals } from './useTransactionsTotals'

const useStyles = makeStyles((theme) => ({
  filterItem: {
    userSelect: 'none',
    borderRadius: theme.layout.radius.loose,
    border: `1px solid ${theme.palette.gray.darker}`,
    padding: `${theme.layout.padding.p20} ${theme.layout.padding.p30}`,
    transition: 'border-color .2s ease-in-out, box-shadow .2s ease-in-out',
    height: '100%',
    '& .__title': {
      fontSize: theme.typography.fontSizes.h3
    },
    '&:hover': {
      borderColor: theme.palette.primary.main,
      boxShadow: theme.layout.shadow.normal,
      cursor: 'pointer'
    },
    '&.__selected': {
      borderColor: theme.palette.primary.main,
      outline: `1px solid ${theme.palette.primary.main}`
    },
    '& .__metrics': {
      width: '100%'
    },
    '& .__metric_desc': {
      fontWeight: theme.typography.weights.light
    },
    '& .__metric_val': {
      fontWeight: theme.typography.weights.bold,
      textAlign: 'right'
    },
    '&:active': {
      backgroundColor: `color-mix(in srgb, ${theme.palette.primary.main} 10%, transparent)`
    }
  }
}))

function TransactionsFilterItem ({ config, metrics }) {
  const { featuredFilters, setFeaturedFilter, addFeaturedFilter, removeFeaturedFilter, clearFeaturedFilters } = useTransactionsContext()
  const selected = featuredFilters.some(x => x.id === config.id)
  const classes = useStyles()
  const { totals, totalsLoading } = useTransactionsTotals({ filter: config.filter })
  const { formatter } = useFormattingContext()
  const combinedMetrics = useMemo(() => {
    if (!totals) return []

    const _m = [...(metrics || []), ...(config?.metrics || [])]
    return _m.map(metric => ({
      key: `${config.id}_${metric.accessor}`,
      description: metric.description,
      value: formatter(get(totals, metric.accessor), metric.format)
    }))
  }, [metrics, config, totals, formatter])

  return (
    <FadeIn
      className={clsx(classes.filterItem, { __selected: selected })}
      role='button'
      onClick={(e) => {
        return selected
          ? e.shiftKey
            ? removeFeaturedFilter(config)
            : clearFeaturedFilters()
          : e.shiftKey
            ? addFeaturedFilter(config)
            : setFeaturedFilter(config)
      }}
    >
      <div className='__title'>{config.title}</div>
      <div>
        {totalsLoading ? <div>Loading</div> : (
          <table className='__metrics'>
            <tbody>
              {combinedMetrics.map(m => (
                <tr key={m.key}>
                  <td className='__metric_desc'>{m.description}</td>
                  <td className='__metric_val'>{m.value}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </FadeIn>
  )
}

TransactionsFilterItem.propTypes = {
  config: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    filter: PropTypes.object,
    metrics: PropTypes.arrayOf({
      accessor: PropTypes.string,
      description: PropTypes.string,
      format: PropTypes.string
    })
  }),
  metrics: PropTypes.arrayOf({
    accessor: PropTypes.string,
    description: PropTypes.string,
    format: PropTypes.string
  })
}

function TransactionsFilter ({ xs, sm, md, lg, xl, config, metrics }) {
  if (!config) return null

  return (
    <Grid container spacing={1}>
      {config.map(c => (
        <Grid key={c.id} item xs={xs} sm={sm} md={md} lg={lg} xl={xl}>
          <TransactionsFilterItem config={c} metrics={metrics} />
        </Grid>
      ))}
    </Grid>
  )
}

TransactionsFilter.propTypes = {
  xs: PropTypes.number,
  sm: PropTypes.number,
  md: PropTypes.number,
  lg: PropTypes.number,
  xl: PropTypes.number,
  config: PropTypes.array,
  metrics: PropTypes.array
}

TransactionsFilter.defaultProps = {
  metrics: [
    { accessor: 'total', description: 'Total', format: 'number' }
  ]
}

export default TransactionsFilter
