import React from 'react'
import PropTypes from 'prop-types'
import { useMediaQuery, useTheme } from '@material-ui/core'
import SectionContainer from '../Section/SectionContainer'
import SectionToggleGroup from './SectionToggleGroup'
import SectionToggleTitle from './SectionToggleTitle'
import SectionToggleActions from './SectionToggleActions'

const SectionToggle = ({ title, actions, toggleOptions, children }) => {
  const theme = useTheme()
  const matchesBreakpoint = useMediaQuery(theme.breakpoints.up('md'))

  return (
    <SectionToggleGroup toggleOptions={toggleOptions}>
      <SectionContainer>
        <SectionToggleTitle title={title} actions={actions} />
        {children}
        {!matchesBreakpoint && (
          <SectionToggleActions actions={actions} />
        )}
      </SectionContainer>
    </SectionToggleGroup>
  )
}

SectionToggle.propTypes = {
  title: PropTypes.string,
  toggleOptions: PropTypes.object,
  children: PropTypes.node,
  actions: PropTypes.node
}

export default SectionToggle
