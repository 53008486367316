import React, { memo } from 'react'
import PropTypes from 'prop-types'
import Icon from '../../../../atoms/Icon'
import { getClientBalanceIndicator } from '../../helpers'

const ClientCardBalanceIndicator = ({ balanceChangeStatus, indicatorSize }) => {
  const { color, icon } = getClientBalanceIndicator(balanceChangeStatus)
  return <Icon themeColor={color} name={icon} customSize={indicatorSize} />
}

ClientCardBalanceIndicator.propTypes = {
  balanceChangeStatus: PropTypes.string,
  indicatorSize: PropTypes.string
}

ClientCardBalanceIndicator.defaultProps = {
  indicatorSize: '1.5rem'
}

export default memo(ClientCardBalanceIndicator)
