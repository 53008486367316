import React, { useCallback, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { animated, useSpring } from 'react-spring'
import { darken, makeStyles } from '@material-ui/core/styles'
import { useTheme } from '@material-ui/core'
import noop from 'lodash/noop'
import NumberFormat from '../../atoms/NumberFormat'
import { DISPLAY_VBS_NUMBER_FORMAT, TITLE_VBS_NUMBER_FORMAT } from '../../../constants'
import { accountDetailsProps } from '../../../prop-types'
import { useFormattingContext } from '../FormattingProvider/FormattingContext'
import BlockTooltip from './BlockTooltip'

const DISPLAY_TEXT_INLINE_BREAKPOINT = 15
const DISPLAY_TEXT_BREAKPOINT = 30
const BLOCK_MIN_WIDTH_IN_PERCENTAGE = 6

function checkSmallerThanBreakpoint ({ vertical, value, breakpoint }) {
  return !vertical && value && value < breakpoint
}

function checkDisplayTitles ({ value, vertical }) {
  if (vertical) return true
  return value && value > DISPLAY_TEXT_BREAKPOINT
}

function getStripedColor (color) {
  return `repeating-linear-gradient(45deg, ${color}, ${color} 5px, #FFF 5px, #FFF 10px)`
}

function getBlockBackgroundColor (color, defaultColor, useStripedBackground) {
  let backgroundColor = color || defaultColor
  if (useStripedBackground) {
    backgroundColor = getStripedColor(backgroundColor)
  }
  return backgroundColor
}

const useStyles = makeStyles((theme) => ({
  block: ({
    backgroundColor: _backgroundColor,
    useStripedBackground,
    blockHeight,
    blockWidth,
    displayInLine,
    vertical
  }) => {
    const backgroundColor = getBlockBackgroundColor(
      _backgroundColor,
      theme.palette.gray.A500,
      useStripedBackground
    )
    return {
      padding: vertical ? '1rem' : '0',
      width: blockWidth || '100%',
      height: blockHeight || '100%',
      position: 'relative',
      borderRadius: '0.5rem',
      background: backgroundColor,
      display: 'flex',
      flexDirection: displayInLine ? 'row' : 'column',
      justifyContent: displayInLine ? 'flex-end' : 'center',
      alignItems: displayInLine ? 'center' : 'flex-start'
    }
  },
  text: {
    color: theme.palette.summitBlue
  },
  title: ({
    backgroundColor,
    useStripedBackground,
    detailsBackgroundColor
  }) => ({
    lineHeight: '1.2',
    padding: '0.25rem',
    fontSize: '1.4rem',
    marginLeft: '1.5rem',
    marginTop: '4px',
    ...(useStripedBackground
      ? {}
      : {
        color: theme.palette.getContrastText(
          backgroundColor || theme.palette.black
        )
      }),
    backgroundColor: detailsBackgroundColor || 'initial'
  }),
  subTitle: ({
    backgroundColor,
    useStripedBackground,
    detailsBackgroundColor
  }) => ({
    fontWeight: 400,
    lineHeight: '1.0',
    padding: '0 0.25rem .25rem',
    fontSize: '0.75rem',
    marginLeft: '1.5rem',
    ...(useStripedBackground
      ? {}
      : {
        color: theme.palette.getContrastText(
          backgroundColor || theme.palette.black
        )
      }),
    backgroundColor: detailsBackgroundColor || 'initial'
  }),
  detailBlock: () => ({
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'row'
  }),
  detailBlockItem: ({ backgroundColor: bgColor, useStripedBackground, itemHovered }) => {
    const darkenBgColor = darken(bgColor || theme.palette.gray.A500, 0.3)

    const backgroundColor = getBlockBackgroundColor(
      darkenBgColor,
      theme.palette.gray.A500,
      useStripedBackground
    )

    return {
      position: 'relative',
      padding: '1rem',
      color: darken(bgColor || theme.palette.common.white, 0.3),
      cursor: 'pointer',
      overflow: 'hidden',
      borderRightColor: bgColor ? darken(bgColor, 0.3) : 'transparent',
      borderRightStyle: itemHovered ? 'solid' : 'none',
      borderRight: '3px',
      '&:hover': {
        background: `${backgroundColor} !important`,
        color: `${theme.palette.common.white} !important`
      },
      '&:first-child': {
        borderTopLeftRadius: '8px',
        borderBottomLeftRadius: '8px'
      },
      '&:last-child': {
        borderRight: '0px solid white',
        borderTopRightRadius: '8px',
        borderBottomRightRadius: '8px'
      }
    }
  },
  detailBlockItemContinue: {
    width: '2.5rem !important',
    display: 'flex',
    justifyContent: 'center'
  },
  detailValue: ({ detailsBackgroundColor, backgroundColor }) => ({
    fontSize: '0.75rem',
    position: 'absolute',
    bottom: '0.75rem',
    right: '0.75rem',
    ...(detailsBackgroundColor
      ? {
        backgroundColor: theme.palette.white,
        padding: '0.25rem',
        color: darken(backgroundColor || theme.palette.gray.A500, 0.3)
      }
      : {})
  }),
  [theme.breakpoints.down('sm')]: {
    block: {
      padding: '1rem 0 0.5rem !important',
      cursor: 'pointer',
      opacity: '.75'
    },
    title: {
      marginLeft: '.5rem !important',
      fontSize: '1rem !important'
    },
    subTitle: {
      marginLeft: '.5rem !important'
    },
    detailBlockItem: {
      display: 'none'
    }
  }
}))

const NUMBER_FORMAT = DISPLAY_VBS_NUMBER_FORMAT

function TreeMapBlock ({
  backgroundColor,
  blockHeight,
  blockValue,
  blockWidth,
  dataTestId,
  details,
  detailsBackgroundColor,
  disableShortenBlocks,
  onClick,
  selectedBlock,
  showDetails,
  subtitleText,
  titleText,
  useParenthesis,
  useStripedBackground,
  value,
  vertical,
  tooltipNumberFormat,
  detailNumberFormat
}) {
  const theme = useTheme()
  const { formatter } = useFormattingContext()
  const [displayInLine, displayTitles] = useMemo(() => {
    const displayInLine = checkSmallerThanBreakpoint({
      vertical,
      value,
      breakpoint: DISPLAY_TEXT_INLINE_BREAKPOINT
    })
    const displayTitles = checkDisplayTitles({ value, vertical })
    return [displayInLine, displayTitles]
  }, [value, vertical])

  const [itemHovered, setItemHovered] = useState(undefined)

  const clearHovered = useCallback(() => setItemHovered(undefined), [])

  const actionsProps = useSpring({
    from: {
      opacity: 0
    },
    to: {
      opacity: itemHovered ? 1 : 0
    }
  })

  const classes = useStyles({
    backgroundColor,
    blockHeight,
    blockValue,
    blockWidth,
    detailsBackgroundColor,
    displayInLine,
    itemHovered,
    showDetails,
    useStripedBackground,
    value,
    vertical
  })

  const [blockDetails, originalBlocks] = useMemo(() => {
    let totalValue = details?.reduce((acc, block) => acc + Math.abs(block.endingValue), 0)
    let { blocks } = [...(details || [])]
      .reduce(
        (acc, { endingValue, ...block }) => {
          const blockWidth = (100 * Math.abs(endingValue)) / totalValue
          const totalWidth = acc.total + blockWidth
          const shouldDisplayBlock =
            (totalWidth < 100 && blockWidth > BLOCK_MIN_WIDTH_IN_PERCENTAGE) ||
            totalValue === Math.abs(endingValue)

          acc.blocks.push({
            ...block,
            endingValue,
            width: blockWidth,
            showBlock: disableShortenBlocks || shouldDisplayBlock
          })
          acc.total += blockWidth
          return acc
        },
        { total: 0, blocks: [] }
      )

    const originalBlocks = [...blocks]
    if (blocks.some((block) => !block.showBlock)) {
      const { finalBlocks, remainingBlocks } = blocks.reduce(
        (acc, block) => {
          if (block.showBlock) {
            acc.finalBlocks.push(block)
          } else {
            acc.remainingBlocks.push(block)
          }
          return acc
        },
        { finalBlocks: [], remainingBlocks: [] }
      )

      totalValue = finalBlocks.reduce(
        (acc, block) => acc + block.endingValue,
        0
      )
      blocks = finalBlocks.map(({ endingValue, ...block }) => ({
        ...block,
        endingValue,
        width: (100 * endingValue) / totalValue
      }))

      blocks.push({ isRemainingBlocks: true, remainingBlocks })
    }
    return [blocks, originalBlocks]
  }, [details, disableShortenBlocks])

  const renderDetailBlock = useCallback(
    (block, index, total) => {
      const { accountId, endingValue, width, showBlock, isRemainingBlocks } =
        block
      const detailBlockId = accountId || 'remaining'
      let bgColor = backgroundColor || theme.palette.gray.A500
      if (block?.title === selectedBlock) {
        bgColor = darken(bgColor, 0.3)
      }
      const showNumber = !isRemainingBlocks && width > 15

      bgColor = getBlockBackgroundColor(
        bgColor,
        theme.palette.gray.A500,
        useStripedBackground
      )

      return (
        <React.Fragment
          key={`detail-block-${detailBlockId}`}
        >
          <animated.div
            id={`test-${detailBlockId}`}
            onClick={() => onClick(block)}
            onMouseOver={() => setItemHovered(detailBlockId)}
            className={clsx(classes.detailBlockItem, {
              [classes.detailBlockItemContinue]: !showBlock
            })}
            style={{
              width: `${width}%`,
              background: bgColor,
              opacity: actionsProps.opacity
            }}
          >
            {itemHovered && showNumber ? (
              <>
                <div className={classes.detailValue}>
                  <NumberFormat
                    title={formatter(
                      endingValue,
                      detailNumberFormat ?? TITLE_VBS_NUMBER_FORMAT
                    )}
                    number={formatter(
                      endingValue,
                      detailNumberFormat ?? NUMBER_FORMAT.SHORT
                    )}
                    skipFormat
                    useParenthesis={useParenthesis}
                  />
                </div>
              </>
            ) : itemHovered ? (
              <div className={classes.detailValue}>. . .</div>
            ) : (
              <div />
            )}
          </animated.div>
        </React.Fragment>
      )
    },
    [
      itemHovered,
      actionsProps.opacity,
      useStripedBackground,
      backgroundColor,
      selectedBlock,
      theme,
      onClick,
      useParenthesis,
      detailNumberFormat,
      formatter,
      classes.detailValue,
      classes.detailBlockItem,
      classes.detailBlockItemContinue
    ]
  )

  return (
    <div
      className={classes.block}
      onMouseLeave={clearHovered}
    >
      {showDetails && (
        <>
          {itemHovered && (
            <BlockTooltip
              blocks={originalBlocks}
              itemHovered={itemHovered}
              tooltipNumberFormat={tooltipNumberFormat}
              useParenthesis={useParenthesis}
              formatter={formatter}
              backgroundColor={backgroundColor}
            />
          )}
          <div className={classes.detailBlock}>
            {blockDetails.map((block) => renderDetailBlock(block))}
          </div>
        </>
      )}
      {displayTitles && !showDetails && (
        <>
          {displayInLine && (
            <div className={clsx(classes.text, classes.subTitle)}>
              {subtitleText}
            </div>
          )}
          <div className={clsx(classes.text, classes.title)}>{titleText}</div>
          {!displayInLine && (
            <div className={clsx(classes.text, classes.subTitle)}>
              {subtitleText}
            </div>
          )}
        </>
      )}
    </div>
  )
}

TreeMapBlock.propTypes = {
  backgroundColor: PropTypes.string,
  blockHeight: PropTypes.string,
  blockValue: PropTypes.number,
  blockWidth: PropTypes.string,
  dataTestId: PropTypes.string,
  details: PropTypes.arrayOf(PropTypes.shape(accountDetailsProps)),
  detailsBackgroundColor: PropTypes.string,
  disableShortenBlocks: PropTypes.bool,
  onClick: PropTypes.func,
  selectedBlock: PropTypes.string,
  showDetails: PropTypes.bool,
  subtitleText: PropTypes.string,
  titleText: PropTypes.oneOfType([PropTypes.string, PropTypes.any]),
  useParenthesis: PropTypes.bool,
  useStripedBackground: PropTypes.bool,
  value: PropTypes.number,
  vertical: PropTypes.bool,
  tooltipNumberFormat: PropTypes.string,
  detailNumberFormat: PropTypes.string
}

TreeMapBlock.defaultProps = {
  backgroundColor: '',
  blockHeight: '',
  blockValue: 0,
  blockWidth: '',
  dataTestId: '',
  details: [],
  detailsBackgroundColor: '',
  disableShortenBlocks: false,
  onClick: noop,
  selectedBlock: '',
  showDetails: false,
  subtitleText: '',
  titleText: '',
  useParenthesis: true,
  useStripedBackground: false,
  value: 0,
  vertical: false,
  tooltipNumberFormat: '0,0.[00]a',
  detailNumberFormat: '0,0.[00]a'
}

export default TreeMapBlock
