import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'
import isEmpty from 'lodash/isEmpty'
import { Box } from '@material-ui/core'
import { CALC_TYPES, LEVEL_TYPES } from '../../../../constants'
import { useAvailableDates } from '../../../../redux/slices/appContext'
import { getSafeDate } from '../../../../utils'
import TableSkeleton from '../../../atoms/TableSkeleton'
import { TableVirtualizedWithDynamicHeight } from '../../../molecules/Table'
import { getNetFlowsTable } from '../../../../utils/tableHelper'
import EmptySection from '../../../atoms/EmptySection'
import { useGetGroupedCoreData } from '../../../../api/coreData'

const AdvisorNetFlowsReport = ({ hideFlows }) => {
  const [availableDates] = useAvailableDates()
  const startDate = getSafeDate(availableDates, { unitValue: 30 })

  const { pathname } = useLocation()
  const advisorId = pathname.split('/').at(-1)

  const { query, queryOptions } = useMemo(
    () => ({
      query: {
        dateRange: {
          startDate,
          endDate: availableDates.mainDate
        },
        levelFilters: {
          advisorIds: [advisorId],
          levelTypes: [LEVEL_TYPES.CLIENT, LEVEL_TYPES.ADVISOR],
          calcType: CALC_TYPES.performance
        }
      },
      queryOptions: {
        mapper: (data) => {
          if (isEmpty(data)) return { rows: [], columns: [] }
          return getNetFlowsTable(data, hideFlows)
        }
      }
    }),
    [advisorId, availableDates.mainDate, hideFlows, startDate]
  )

  const {
    data: { rows, columns } = { rows: [], columns: [] },
    isLoading
  } = useGetGroupedCoreData(query, queryOptions)

  const tableRendered = useMemo(() => {
    if (isLoading) {
      return <TableSkeleton columns={4} rows={5} />
    }
    if (isEmpty(rows)) {
      return (
        <EmptySection
          title='There is not data to display here yet.'
          description=''
        />
      )
    }
    return (
      <TableVirtualizedWithDynamicHeight
        rows={rows}
        labels={columns}
        spacing='0.5rem'
        skipOrderBy
      />
    )
  }, [isLoading, rows, columns])

  return (
    <Box width='100%'>
      <Box display='flex' height='4rem' flexDirection='row'>
        <Box width='18rem'>
          Last 30 days
        </Box>
      </Box>
      {tableRendered}
    </Box>
  )
}

AdvisorNetFlowsReport.propTypes = {
  hideFlows: PropTypes.bool
}

AdvisorNetFlowsReport.defaultProps = {
  hideFlows: false
}

export default AdvisorNetFlowsReport
