import React, { useCallback, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Controller, useForm } from 'react-hook-form'
import { Grid } from '@material-ui/core'
import SydLabel from '../../../../../commonDesign/SydLabel'
import SydButton from '../../../../../commonDesign/Button'
import { useModifyUserSsoAuthorization } from '../../../../../../api/users/sso'
import SydModalActions from '../../../../../commonDesign/SydModal/SydModalActions'
import EmptySection from '../../../../../atoms/EmptySection'
import Icon from '../../../../../atoms/Icon'
import SsoValueEditor from '../../../shared/Integrations/SsoValueEditor'
import EnablementSelector from './EnablementSelector'

const useSubmitter = (form, onComplete, providerId, userId) => {
  const { handleSubmit } = form
  const [processing, setProcessing] = useState(false)
  const [error, setError] = useState(null)
  const { mutateAsync: modifyAuthorization } = useModifyUserSsoAuthorization()

  const onSubmit = useCallback(async (formData) => {
    const command = {
      userId,
      providerId,
      authorization: {
        enabled: formData.enabled === 'enabled',
        configuration: formData.attributes.reduce((prev, cur) => {
          prev[cur.mapTo] = cur.value === '' ? null : cur.value
          return prev
        }, {})
      }
    }

    try {
      setError(null)
      setProcessing(true)
      const result = await modifyAuthorization(command)
      if (result?.statusCode === 500) {
        throw new Error('Failed to add fee schedule')
      }
      onComplete(result)
    } catch (err) {
      setError(err?.toString())
    } finally {
      setProcessing(false)
    }
  }, [modifyAuthorization, setProcessing, onComplete, userId, providerId, setError])

  const submitter = useCallback(async (e) => {
    const onValid = async (form) => {
      await onSubmit(form)
    }
    const onInvalid = (errors) => {
      console.error(errors)
    }

    const handler = handleSubmit(onValid, onInvalid)
    await handler(e)
  }, [handleSubmit, onSubmit])

  return {
    submitter,
    processing,
    error
  }
}

function EditSsoAssignmentForm ({ sso, userId, onCancel, onComplete }) {
  const form = useForm({
    mode: 'onChange',
    defaultValues: {
      enabled: sso.userAuthorization?.enabled ? 'enabled' : 'disabled',
      attributes: sso.attributes
    }
  })
  const enabled = form.watch('enabled')
  const showRoleMessage = useMemo(() => sso.roleAuthorization?.enabled && enabled !== 'enabled', [sso, enabled])
  const formDisabled = useMemo(() => enabled !== 'enabled', [enabled])
  const { processing, submitter, error } = useSubmitter(form, onComplete, sso.providerId, userId)

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Controller
          control={form.control}
          name='enabled'
          render={({ field }) => (
            <EnablementSelector value={field.value} onChange={field.onChange} />
          )}
        />
      </Grid>
      {showRoleMessage ? (
        <Grid item xs={6}>
          <EmptySection
            header={<div style={{ marginBottom: '20px' }}><Icon name='info' customSize={60} /></div>}
            title='Integration attributes taken from role'
            description='To change per-user, enable the integration at the user-level'
          />
        </Grid>
      ) : (
        <Grid item xs={6}>
          <div>
            <Grid container spacing={2}>
              {sso.attributes.map((attr, index) => (
                <Grid item xs={12} key={attr.mapTo}>
                  <Controller
                    control={form.control}
                    name={`attributes.${index}.value`}
                    render={({ field }) => (
                      <SydLabel label={attr.name} description={attr.description}>
                        <SsoValueEditor
                          disabled={formDisabled}
                          type={attr.type}
                          value={field.value}
                          onChange={field.onChange}
                        />
                      </SydLabel>
                    )}
                  />
                </Grid>
              ))}
            </Grid>
          </div>
        </Grid>
      )}
      <Grid item xs={12}>
        <SydModalActions>
          {error ? (<div className='__error'>{error}</div>) : null}
          <SydButton disabled={processing} variant='ghost' size='md' onClick={onCancel}>Cancel</SydButton>
          <SydButton
            variant='primary'
            size='md'
            onClick={submitter}
            processing={processing}
          >
            Save
          </SydButton>
        </SydModalActions>
      </Grid>
    </Grid>
  )
}

EditSsoAssignmentForm.propTypes = {
  userId: PropTypes.number,
  sso: PropTypes.shape({
    providerId: PropTypes.string,
    userAuthorization: PropTypes.shape({
      enabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.number])
    }),
    roleAuthorization: PropTypes.shape({
      enabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.number])
    }),
    attributes: PropTypes.arrayOf(PropTypes.shape({
      mapTo: PropTypes.string,
      name: PropTypes.string,
      description: PropTypes.string,
      type: PropTypes.oneOf(['string', 'advisor']),
      value: PropTypes.any
    }))
  }),
  onComplete: PropTypes.func,
  onCancel: PropTypes.func
}

export default EditSsoAssignmentForm
