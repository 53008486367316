import React, { forwardRef, useImperativeHandle, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { TextField, Grid, makeStyles } from '@material-ui/core'
import Select from '../../../../molecules/Select'

const useStyles = makeStyles((theme) => ({
  formSwitch: {
    justifyContent: 'flex-end',
    display: 'flex',
    flexDirection: 'row',
    gap: '10px',
    alignItems: 'center'
  },
  formLayout: {
    maxWidth: '800px'
  },
  wrap: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  experience: {
    width: '100%'
  },
  personalSpace: {
    marginBottom: '40px'
  }
}))

const useFormValues = (client) => {
  const defaultValues = useMemo(() => {
    return {
      person: {
        firstName: '',
        lastName: '',
        email: '',
        personTypeId: ''
      },
      clientIds: [client.clientId]
    }
  }, [client.clientId])
  const formValues = useForm({
    mode: 'onChange',
    reValidateMode: 'onSubmit',
    criteriaMode: 'all',
    defaultValues
  })

  return {
    ...formValues
  }
}

const CreatePersonForm = forwardRef(function CreatePersonForm ({ client, personTypeOptions }, ref) {
  const classes = useStyles()
  const { handleSubmit, ...formValues } = useFormValues(client)
  useImperativeHandle(ref, () => {
    return {
      handleSubmit
    }
  }, [handleSubmit])

  return (
    <FormProvider handleSubmit={handleSubmit} {...formValues}>
      <div className={classes.wrap}>
        <Grid className={classes.formLayout} container spacing={3}>
          <Grid className={classes.personalSpace} item xs={12}>
            Please confirm the details for the person. The created person will be added to this client.
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name='person.firstName'
              rules={{ required: true, maxLength: 30 }}
              render={({ field, fieldState }) => (
                <TextField
                  label='First Name'
                  fullWidth
                  helperText='Required, 30 max characters'
                  error={(fieldState.isDirty || fieldState.isTouched) && fieldState.error}
                  inputProps={field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name='person.lastName'
              rules={{ required: true, maxLength: 80 }}
              render={({ field, fieldState }) => (
                <TextField
                  label='Last Name'
                  fullWidth
                  helperText='Required, 80 max characters'
                  error={(fieldState.isDirty || fieldState.isTouched) && fieldState.error}
                  inputProps={field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name='person.email'
              rules={{ required: true, maxLength: 100, email: true }}
              render={({ field, fieldState }) => (
                <TextField
                  label='Email'
                  fullWidth
                  helperText='Required, 100 max characters'
                  error={(fieldState.isDirty || fieldState.isTouched) && fieldState.error}
                  inputProps={field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name='person.personTypeId'
              render={({ field, fieldState }) => (
                <Select
                  className={classes.experience}
                  placeholder='Select a Person Role'
                  options={personTypeOptions}
                  onChange={field.onChange}
                  value={field.value}
                  showCheckMarOnSelectedItems
                  error={(fieldState.isDirty || fieldState.isTouched) && fieldState.error}
                />
              )}
            />
          </Grid>
        </Grid>
      </div>
    </FormProvider>
  )
})

CreatePersonForm.propTypes = {
  client: PropTypes.shape({
    clientId: PropTypes.number
  }),
  personTypeOptions: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.number
  }))
}

export default CreatePersonForm
