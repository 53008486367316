import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'
import clsx from 'clsx'
import FadeIn from '../../../molecules/Transitions/FadeIn'

const useStyles = makeStyles(theme => ({
  dataHomePageContainer: {
    padding: '0 2rem'
    // maxWidth: '1200px',
  }
}))

const PageContainer = ({ children, className }) => {
  const classes = useStyles()
  return (
    <FadeIn>
      <Grid container spacing={1} className={clsx(className, classes.dataHomePageContainer)}>
        {children}
      </Grid>
    </FadeIn>
  )
}

PageContainer.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
}

export default PageContainer
