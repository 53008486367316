import React, { Fragment, useMemo } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { Button } from '@material-ui/core'
import { useCountIngestionItemsToBeCreated, useIngestionItemsToBeCreated } from '../../../../api/ingestion'
import Hideable from '../components/Hideable'
import parseCellValue from './parseCellValue'

const useStyles = makeStyles((theme) => ({
  tbc: {
    marginBottom: '1rem',
    borderBottom: `2px solid ${theme.palette.gray.dark}`,
    '& table': {
      width: '100%',
      '& th': {
        textAlign: 'left'
      },
      fontWeight: 200
    }
  }
}))

const ItemsToBeCreated = ({ targetId, policyId }) => {
  const classes = useStyles()
  const { data: total } = useCountIngestionItemsToBeCreated(targetId, policyId)
  const { data, isLoading, fetchNextPage, hasNextPage, error } = useIngestionItemsToBeCreated(targetId, policyId)
  const columns = useMemo(() => {
    return (data?.pages?.length && data?.pages.at(0).targets?.length)
      ? Object.keys(data.pages.at(0).targets.at(0))
      : []
  }, [data])

  if (isLoading) {
    return <div className={classes.tbc}>Loading...</div>
  }

  if (error) {
    return <div>There was an error loading the create preview data: {error.message}</div>
  }

  return (
    <div className={classes.tbc}>
      <Hideable label={`New Items (${total})`}>
        <table>
          <thead>
            <tr>
              {columns.map((c) => (
                <th key={c}>{c}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.pages.map((page, pIndex) => (
              <Fragment key={pIndex}>
                {page.targets.map((x, i) => (
                  <tr key={`row_${i}`}>
                    {columns.map((c) => (
                      <td key={c}>{parseCellValue(x[c]) ?? '<null>'}</td>
                    ))}
                  </tr>
                ))}
              </Fragment>
            ))}
          </tbody>
          <tfoot>
            {(hasNextPage && (
              <Button color='primary' variant='contained' onClick={() => fetchNextPage()}>Fetch More</Button>
            ))}
          </tfoot>
        </table>
      </Hideable>
    </div>
  )
}

ItemsToBeCreated.propTypes = {
  targetId: PropTypes.number,
  policyId: PropTypes.number
}

export default ItemsToBeCreated
