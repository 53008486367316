import { Box, makeStyles } from '@material-ui/core'
import React from 'react'
import PropTypes from 'prop-types'
import DateRangePicker from '../../../molecules/DateRangePicker'
import { useAppContext } from '../../../../redux/slices/appContext'
import { parseCustomDateRangeRawValue } from '../../../molecules/RelativeDateSelect'
import { DATE_FORMAT_US_LONG } from '../../../../constants'

const useStyles = makeStyles(() => ({
  dateRangeInput: {
    background: '#FFFFFF',
    border: '3px solid #D7DCE1',
    borderRadius: '4px',
    padding: '6px 8px',
    '& input': {
      padding: '0'
    }
  },
  dateRangeInputSmall: {
    fontSize: '0.75rem',
    borderRadius: '4px',
    padding: '0.5rem 0.5rem',
    '& .MuiInputBase-input': {
      fontSize: '0.85rem',
      padding: '0'
    },
    '& .MuiInputAdornment-root': {
      marginLeft: '0'
    },
    '& .MuiButtonBase-root': {
      padding: '0.25rem'
    },
    '& .MuiFormHelperText-root': {
      position: 'absolute',
      bottom: '-1.75rem',
      left: '0',
      width: 'max-content',
      backgroundColor: '#FFFFFF',
      borderRadius: '4px',
      padding: '0.25rem 0.5rem',
      boxShadow: '-1px 1px 1px rgba(0, 0, 0, 0.1)'
    },
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)'
    },
    '& svg': {
      fontSize: '1rem'
    }
  }
}))

const CUSTOM_DATE_SELECTOR_VARIANT = {
  default: 'default',
  small: 'small'
}

const CustomDateRangeSelector = ({
  value,
  variant,
  format,
  divider,
  onChange,
  valueParser,
  ...props
}) => {
  const classes = useStyles()
  const { availableDates } = useAppContext()

  const { startDate = null, endDate = null } = valueParser(value)

  const classNames = {
    [CUSTOM_DATE_SELECTOR_VARIANT.default]: classes.dateRangeInput,
    [CUSTOM_DATE_SELECTOR_VARIANT.small]: classes.dateRangeInputSmall
  }

  return (
    <DateRangePicker
      onChange={onChange}
      defaultStartDate={startDate || availableDates?.mainDate}
      defaultEndDate={endDate || availableDates?.mainDate}
      minDate={availableDates.min}
      maxDate={availableDates.max}
      format={format}
      className={classNames[variant]}
      shouldDisableWeekends
      datePickerWidth='12rem'
      divider={divider || <Box mx='0.25rem' />}
      startDateLabel={null}
      endDateLabel={null}
      InputProps={{
        disableUnderline: true,
        error: false
      }}
      {...props}
    />
  )
}

CustomDateRangeSelector.propTypes = {
  value: PropTypes.string,
  variant: PropTypes.string,
  format: PropTypes.string,
  onChange: PropTypes.func,
  valueParser: PropTypes.func,
  divider: PropTypes.node
}

CustomDateRangeSelector.defaultProps = {
  format: DATE_FORMAT_US_LONG,
  variant: CUSTOM_DATE_SELECTOR_VARIANT.default,
  valueParser: parseCustomDateRangeRawValue
}

export default CustomDateRangeSelector
