import React from 'react'
import { Box, makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import Text from '../../../../atoms/Text'
import { useFormattingContext } from '../../../../organisms/FormattingProvider/FormattingContext'
import { useCheckPolicy } from '../../../../../hooks'
import { ACCOUNTS } from '../../../../../policies/admin'
import SectionHeader from '../../shared/SectionHeader'
import PerformanceDateInput from '../QuickEditInputs/PerformanceDateInput'
import AccountCloseButton from '../QuickEditInputs/AccountCloseButton'
import { AccountFormSection, useAccountFormContext } from '../AccountFormProvider'
import Divider from '../../../../atoms/Divider'
import SydLabel from '../../../../commonDesign/SydLabel'
import BillingStartDateInput from '../QuickEditInputs/BillingStartDateInput'

const useStyles = makeStyles((theme) => ({
  dateList: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '20px',
    flexWrap: 'wrap',
    gap: '16px'
  }
}))

function AccountDatesHeader ({ disabled, onClosedAccount, onBillingSave, onGeneralInfoSave }) {
  const classes = useStyles()
  const { account } = useAccountFormContext()
  const { formatter } = useFormattingContext()

  const canCloseAccounts = useCheckPolicy(ACCOUNTS.closeAccounts)

  return (
    <>
      <Divider />
      <AccountFormSection section='account_dates'>
        <SectionHeader text='Account Dates' />
        <Box className={classes.dateList}>
          <Box>
            <SydLabel label='Start Date' />
            <Text customFontSize='18px' text={formatter(account.startDate, '@MM/DD/YYYY')} />
          </Box>
          <Box>
            <SydLabel label='Daily Start Date' />
            <Text customFontSize='18px' text={account.startDate ? formatter(account.dailyStartDate, '@MM/DD/YYYY') : 'Same as Start Date'} />
          </Box>
          <Box>
            <SydLabel label='Last Updated' />
            <Text customFontSize='18px' text={formatter(account.lastDataSourceDate, '@MM/DD/YYYY')} />
          </Box>
          <Box>
            <SydLabel label='As of Date' />
            <Text customFontSize='18px' text={formatter(account.asOfDate, '@MM/DD/YYYY')} />
          </Box>
          <Box>
            <PerformanceDateInput
              account={account}
              disableEdit={disabled}
              onSave={onGeneralInfoSave}
            />
          </Box>
          <Box>
            <BillingStartDateInput
              disableEdit={disabled}
              onSave={onBillingSave}
            />
          </Box>
          <Box>
            <SydLabel label='Closed Date' />
            {canCloseAccounts && !account.closeDate ? (
              <AccountCloseButton
                disabled={disabled}
                account={account}
                onClosedAccount={onClosedAccount}
              />
            ) : (
              <Text customFontSize='18px' text={formatter(account.closeDate, '@MM/DD/YYYY')} />
            )}
          </Box>
        </Box>
      </AccountFormSection>
    </>
  )
}

AccountDatesHeader.propTypes = {
  disabled: PropTypes.bool,
  onClosedAccount: PropTypes.func,
  onBillingSave: PropTypes.func,
  onGeneralInfoSave: PropTypes.func
}

export default AccountDatesHeader
