import React, { useCallback, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import head from 'lodash/head'
import find from 'lodash/find'
import { useTheme } from '@material-ui/core'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import isBetween from 'dayjs/plugin/isBetween'
import isEmpty from 'lodash/isEmpty'
import noop from 'lodash/noop'
import { CALC_TYPES, DATE_FORMAT } from '../../../../constants'
import {
  createWealthJourneyEntry,
  deleteWealthJourneyEntry,
  editWealthJourneyEntry,
  fetchWealthJourneyCategories,
  fetchWealthJourneyEntries,
  fetchWealthJourneySeriesData
} from '../../../../service'
import { insertAtIndex, modifyByIndex, removeByIndex } from '../../../../utils'
import { useAppContext } from '../../../../redux/slices/appContext'
import DefaultLayout from '../../../../abundanceEngine/layouts/DefaultLayout'
import { useSearchParam } from '../../../../hooks/useSearchParam'
import { useWealthJourneyPermissions } from '../shared/hooks'
import { mapThemingColor } from '../shared/mapThemingColor'
import WealthJourneyChart from './WealthJourneyChart'
import WealthJourneyEntryTypeSelector, { useWealthJourneyEntryTypeSelector } from './WealthJourneyEntryTypeSelector'
import WealthJourneyMilestoneContent from './WealthJourneyMilestoneContent'
import WealthJourneyActivityContent from './WealthJourneyActivityContent'
import WealthJourneyMeetingContent from './WealthJourneyMeetingContent'
import entryType, { ACTIONS, entryTypeIdentifier } from './entryType'

dayjs.extend(isSameOrAfter)
dayjs.extend(isSameOrBefore)
dayjs.extend(isBetween)

const getDefaultDatePeriodTabs = (currentYear, numberOfItems) => {
  let datePeriods = [{ label: currentYear.toString(), value: currentYear }]
  const datePeriodsLength = Math.floor(numberOfItems / 2)

  let nextYear = currentYear + 1
  let prevYear = currentYear - 1

  for (let i = 0; i < datePeriodsLength; i += 1, nextYear += 1, prevYear -= 1) {
    datePeriods = [
      { label: prevYear.toString(), value: prevYear },
      ...datePeriods,
      { label: nextYear.toString(), value: nextYear }
    ]
  }
  return datePeriods
}

const getSeriesDates = ({
  availableDates,
  seriesPastYears,
  seriesFutureYears,
  fixedDateRange,
  hideMarketValue,
  entries
}) => {
  if (!isEmpty(fixedDateRange)) {
    return [dayjs.utc(fixedDateRange[0]), dayjs.utc(fixedDateRange[1])]
  }
  if (hideMarketValue && !isEmpty(entries)) {
    const endDate = dayjs.utc().startOf('year').add(seriesFutureYears || 5, 'year')
    const entryEndDate = dayjs.utc(entries[entries.length - 1].entryDate)
    return [dayjs.utc(entries[0].entryDate).startOf('year'), endDate.isSameOrAfter(entryEndDate) ? endDate : entryEndDate]
  }
  const seriesStartDate = seriesPastYears
    ? dayjs.utc().startOf('year').subtract(seriesPastYears, 'year')
    : dayjs.utc(availableDates.min).startOf('year')
  const seriesEndDate = dayjs.utc().startOf('year').add(seriesFutureYears || 5, 'year')
  return [seriesStartDate, seriesEndDate]
}

const getSeriesValues = (range) => {
  const startValue = dayjs(range?.[0]).startOf('year')
  const endValue = dayjs(range?.[range?.length - 1]).endOf('year')

  return {
    data: [{
      values: [
        {
          periodStart: startValue.format(),
          label: startValue.format('YYYY-MM-DD'),
          endingValue: 1,
          periodEnd: startValue.endOf('month').format()
        },
        {
          periodStart: endValue.startOf('month').format(),
          label: endValue.startOf('month').format('YYYY-MM-DD'),
          endingValue: 1,
          periodEnd: endValue.format()
        }
      ]
    }]
  }
}

const createDefaultTheming = () => ({
  chart: {
    lineColor: 'primary',
    meetingColor: 'default',
    milestoneColor: 'primary'
  },
  milestone: {
    iconColor: 'primaryContrast',
    iconBackgroundColor: 'primary'
  }
})

const useWealthJourneyChart = ({
  appContext,
  lineChartDataIdentifier,
  includeNetAdditions,
  includeProjections,
  seriesPastYears,
  seriesFutureYears,
  fixedDateRange,
  hideMarketValue,
  entries,
  theming
}) => {
  const theme = useTheme()
  const [rawData, setRawData] = useState({
    meta: null,
    series: []
  })
  const [loading, setLoading] = useState(false)
  const [yearsInRange, setYearsInRange] = useState([])

  useEffect(() => {
    async function fetchData () {
      setLoading(true)
      const startDate = dayjs.utc(appContext.availableDates.min)
      const endDate = dayjs.utc(appContext.availableDates.max)
      const [seriesStartDate, seriesEndDate] = getSeriesDates({
        availableDates: appContext.availableDates,
        seriesPastYears,
        seriesFutureYears,
        fixedDateRange,
        hideMarketValue,
        entries
      })
      const range = []
      for (let cy = seriesStartDate; cy.isSameOrBefore(seriesEndDate, 'year'); cy = cy.add(1, 'year').startOf('year')) {
        range.push(dayjs.utc(cy))
      }
      setYearsInRange(range)

      const { data } = hideMarketValue ? getSeriesValues(range) : await fetchWealthJourneySeriesData({
        dateType: 'month',
        levelTypes: ['client'],
        clientIds: [appContext.clientId],
        calcType: CALC_TYPES.timeSeries,
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
        seriesStartDate: seriesStartDate.toISOString(),
        seriesEndDate: seriesEndDate.toISOString(),
        options: {
          includeNetAdditions,
          includeProjections
        }
      })

      setRawData({
        meta: {
          startDate,
          endDate,
          seriesStartDate,
          seriesEndDate
        },
        series: head(data)?.values
      })
      setLoading(false)
    }

    fetchData()
  }, [
    appContext.clientId,
    appContext,
    lineChartDataIdentifier,
    includeNetAdditions,
    includeProjections,
    seriesPastYears,
    seriesFutureYears,
    fixedDateRange,
    setYearsInRange,
    entries,
    hideMarketValue
  ])

  const chartData = useMemo(() => {
    const series = rawData.series.reduce((prev, cur) => {
      prev.endingValue = prev.endingValue ?? []
      prev.endingValue.push({ x: dayjs.utc(cur.label).format(DATE_FORMAT), y: cur.endingValue ?? null })

      if (includeNetAdditions) {
        prev.netAdditions = prev.netAdditions ?? []
        prev.netAdditions.push({ x: dayjs.utc(cur.label).format(DATE_FORMAT), y: cur.netAdditions ?? null })
      }

      if (includeProjections) {
        prev.projections = prev.projections ?? []
        prev.projections.push({ x: dayjs.utc(cur.label).format(DATE_FORMAT), y: cur.projectedValue ?? null })
      }

      return prev
    }, {})

    const result = {
      endingValue: {
        id: 'Portfolio Value',
        data: series.endingValue,
        color: mapThemingColor(theming?.chart?.lineColor, theme, theme.palette.indigoBlue),
        strokeWidth: 3
      }
    }
    if (series.netAdditions) {
      result.netAdditions = {
        id: 'Net Additions',
        data: series.netAdditions,
        color: mapThemingColor(theming?.chart?.netAdditionsColor, theme, theme.palette.dustyGray),
        strokeWidth: 3
      }
    }
    if (series.projections) {
      result.projections = {
        id: 'Projected Value',
        data: series.projections,
        color: mapThemingColor(theming?.chart?.lineColor, theme, theme.palette.indigoBlue),
        strokeWidth: 3,
        options: {
          strokeDasharray: '10,4',
          strokeOpacity: '0.5'
        }
      }
    }
    result.meta = rawData.meta

    return result
  }, [rawData, includeNetAdditions, includeProjections, theme, theming])

  return {
    chartData,
    chartDataLoading: loading,
    yearsInRange
  }
}

const DEFAULT_STARTING_YEAR = dayjs.utc().year()
const DEFAULT_DATE_PERIODS_LENGTH = 5
const useYearSelection = () => {
  const [yearTabs, setYearTabs] = useState(getDefaultDatePeriodTabs(DEFAULT_STARTING_YEAR, DEFAULT_DATE_PERIODS_LENGTH))
  const [selectedYear, setSelectedYear] = useState(yearTabs.find(x => x.value === DEFAULT_STARTING_YEAR))

  const onReachNavigationEdge = useCallback(
    ({ prevEdgeReached, nextEdgeReached }, goToSlide, currentSlide) => {
      setYearTabs(prevDatePeriods => {
        let tabStepIndex = 0
        let value = null
        if (prevEdgeReached) {
          const [{ value: firstValue }] = prevDatePeriods
          const newValue = firstValue - 1
          value = newValue
        } else if (nextEdgeReached) {
          const { value: lastValue } = prevDatePeriods[prevDatePeriods.length - 1]
          tabStepIndex = prevDatePeriods.length
          const newValue = lastValue + 1
          value = newValue
          goToSlide(currentSlide + 1)
        }
        const newDatePeriodTab = { label: value.toString(), value }
        const datePeriods = insertAtIndex(tabStepIndex, prevDatePeriods, newDatePeriodTab)
        return datePeriods
      })
    }, [])

  const onYearSelected = useCallback((tabStepItem) => {
    setSelectedYear(tabStepItem)
  }, [])

  return {
    selectedYear,
    onYearSelected,
    yearTabs,
    onReachNavigationEdge
  }
}

const useWealthJourneyEntries = ({
  appContext,
  selectedYear,
  selectedEntryType
}) => {
  const [entries, setEntries] = useState([])
  const [entriesLoading, setEntriesLoading] = useState(false)

  const canViewEntry = useWealthJourneyPermissions(selectedEntryType, ACTIONS.VIEW)
  const canEditEntry = useWealthJourneyPermissions(selectedEntryType, ACTIONS.EDIT)

  const fetchEntries = useCallback(async () => {
    if (!canViewEntry) {
      setEntries([])
      return null
    }
    try {
      setEntriesLoading(true)
      const { data: wealthJourneyEntries } = await fetchWealthJourneyEntries({
        clientId: appContext.clientId,
        take: 250
      })
      const wealthJourneyEntriesOrdered = wealthJourneyEntries.sort((a, b) => {
        return dayjs.utc(a.entryDate) - dayjs.utc(b.entryDate)
      })
      setEntries(wealthJourneyEntriesOrdered)
    } catch (err) {
      console.error(err)
    } finally {
      setEntriesLoading(false)
    }
  }, [setEntries, appContext.clientId, setEntriesLoading, canViewEntry])

  useEffect(() => {
    fetchEntries()
  }, [fetchEntries])

  const onAddEntry = useCallback(async (entry, done = noop) => {
    if (!canEditEntry) done({})
    try {
      const body = {
        clientId: appContext.clientId,
        ...entry
      }
      const { data } = await createWealthJourneyEntry(body)
      const { entryId } = data
      setEntries(prevEntries => {
        const rawEntries = [...prevEntries, { entryId, ...body }]
        const orderedEntries = rawEntries.sort((a, b) => {
          return dayjs.utc(a.entryDate) - dayjs.utc(b.entryDate)
        })
        return orderedEntries
      })
      done({ entryId })
    } catch (err) {
      console.error(err)
      done(err)
    }
  }, [appContext.clientId, canEditEntry])

  const onEditEntry = useCallback(async ({ entryId: id, ...entryPayload }) => {
    if (!canEditEntry) return null
    try {
      setEntries(prevEntries => {
        const entryIndex = prevEntries.findIndex(({ entryId }) => entryId === id)
        const entry = { ...prevEntries[entryIndex], ...(entryPayload || {}) }
        const modifiedEntry = modifyByIndex(entryIndex, prevEntries, entry)
        return modifiedEntry
      })
      if (!isEmpty(entryPayload)) {
        await editWealthJourneyEntry(id, entryPayload)
      }
    } catch (err) {
      console.error(err)
    }
  }, [canEditEntry])

  const onDeleteEntry = useCallback(async (id) => {
    if (!canEditEntry) return null
    try {
      setEntries(prevEntries => {
        const entryIndex = prevEntries.findIndex(({ entryId }) => entryId === id)
        return removeByIndex(entryIndex, prevEntries)
      })
      await deleteWealthJourneyEntry(id)
    } catch (err) {
      console.error(err)
    }
  }, [canEditEntry])

  const [selectedEntry, setSelectedEntry] = useState(null)
  const onEntrySelected = useCallback(entry => {
    setSelectedEntry(entry)
  }, [setSelectedEntry])

  const [categories, setCategories] = useState([])
  const [categoriesLoading, setCategoriesLoading] = useState(false)

  const fetchCategories = useCallback(async () => {
    try {
      setCategoriesLoading(true)
      const { data: wealthJourneyCategories } = await fetchWealthJourneyCategories()
      const wealthJourneyCategoriesOrdered = wealthJourneyCategories.sort(
        (categoryA, categoryB) => categoryA.ordinal - categoryB.ordinal
      )
      setCategories(wealthJourneyCategoriesOrdered)
    } catch (err) {
      console.error(err)
    } finally {
      setCategoriesLoading(false)
    }
  }, [setCategoriesLoading])

  useEffect(() => {
    fetchCategories()
  }, [fetchCategories])

  // This effect resets the selection when a different year or entry type is selected
  const milestoneIdSearchParam = useSearchParam('milestoneId')
  useEffect(() => {
    if (selectedEntryType === entryType.MILESTONES) {
      const milestones = (entries ?? []).filter(x => x.entryTypeId === entryTypeIdentifier.MILESTONES)

      if (milestoneIdSearchParam) {
        const matchingEntry = milestones.find(x => x.entryId === milestoneIdSearchParam)
        if (matchingEntry) {
          setSelectedEntry(matchingEntry)
          return
        }
      }
      if (milestones?.length > 0) {
        const firstEntry = find(milestones || [], (entry) => (dayjs.utc(entry.entryDate).year() === selectedYear?.value))
        setSelectedEntry(firstEntry || null)
      } else {
        setSelectedEntry(null)
      }
    } else if (selectedEntryType === entryType.MEETINGS) {
      const meetings = (entries ?? []).filter(x => x.entryTypeId === entryTypeIdentifier.MEETINGS)

      if (meetings?.length > 0) {
        const defaultMeeting = meetings.reduce((p, c) => {
          return (dayjs.utc(c.entryDate).isAfter(p.entryDate)) ? c : p
        }, meetings[0])
        setSelectedEntry(defaultMeeting)
      } else {
        setSelectedEntry(null)
      }
    } else if (selectedEntryType === entryType.ACTIVITIES) {
      setSelectedEntry(null)
    }
  }, [setSelectedEntry, entries, selectedEntryType, selectedYear, milestoneIdSearchParam])

  return {
    entries,
    entriesLoading,
    onAddEntry,
    onEditEntry,
    onDeleteEntry,
    selectedEntry,
    onEntrySelected,
    categories,
    categoriesLoading
  }
}

const NewWealthJourneyHomeV2 = ({
  lineChartDataIdentifier,
  includeMeetings,
  includeMilestones,
  includeActivities,
  includeNetAdditions,
  includeProjections,
  filterEntryTypesOnChart,
  seriesPastYears,
  seriesFutureYears,
  fixedDateRange,
  chartTitle,
  taskTitle,
  taskButtonLabel,
  hideMarketValue,
  hideActiveDatePeriodMarker,
  theming
}) => {
  const appContext = useAppContext()
  const _theming = useMemo(() => Object.assign({}, createDefaultTheming(), theming), [theming])
  const yearSelection = useYearSelection()

  const canViewMilestones = useWealthJourneyPermissions(entryType.MILESTONES, ACTIONS.VIEW)
  const canViewActivities = useWealthJourneyPermissions(entryType.ACTIVITIES, ACTIONS.VIEW)
  const canViewMeetings = useWealthJourneyPermissions(entryType.MEETINGS, ACTIONS.VIEW)

  const {
    selectedEntryType,
    onSelectEntryType
  } = useWealthJourneyEntryTypeSelector({
    includeMeetings,
    includeMilestones,
    includeActivities
  })

  const {
    entries,
    entriesLoading,
    onAddEntry,
    onEditEntry,
    onDeleteEntry,
    selectedEntry,
    onEntrySelected,
    categories,
    categoriesLoading
  } = useWealthJourneyEntries({
    appContext,
    selectedYear: yearSelection.selectedYear,
    selectedEntryType
  })

  const {
    chartData,
    chartDataLoading,
    yearsInRange
  } = useWealthJourneyChart({
    appContext,
    lineChartDataIdentifier,
    includeNetAdditions,
    includeProjections,
    fixedDateRange,
    seriesPastYears,
    seriesFutureYears,
    hideMarketValue,
    entries,
    theming: _theming
  })

  // This effect set the entryType when a new entry is selected.
  useEffect(() => {
    if (selectedEntry) {
      switch (selectedEntry.entryTypeId) {
        case entryTypeIdentifier.MILESTONES:
          onSelectEntryType(entryType.MILESTONES)
          break
        case entryTypeIdentifier.MEETINGS:
          onSelectEntryType(entryType.MEETINGS)
          break
        default:
          onSelectEntryType(entryType.ACTIVITIES)
      }
    }
  }, [selectedEntry, onSelectEntryType])

  return (
    <DefaultLayout>
      <WealthJourneyChart
        chartData={chartData}
        chartTitle={chartTitle}
        loading={chartDataLoading}
        appContext={appContext}
        selectedTabStep={yearSelection.selectedYear}
        entries={entries}
        selectedEntry={selectedEntry}
        yearsInRange={yearsInRange}
        selectedEntryType={selectedEntryType}
        filterEntryTypesOnChart={filterEntryTypesOnChart}
        includeMeetings={includeMeetings && canViewMeetings}
        includeMilestones={includeMilestones && canViewMilestones}
        onEntrySelected={onEntrySelected}
        hideMarketValue={hideMarketValue}
        hideActiveDatePeriodMarker={hideActiveDatePeriodMarker}
        theming={_theming}
      />
      <WealthJourneyEntryTypeSelector
        onSelect={onSelectEntryType}
        selectedType={selectedEntryType}
        includeMeetings={includeMeetings && canViewMeetings}
        includeMilestones={includeMilestones && canViewMilestones}
        includeActivities={includeActivities && canViewActivities}
      />
      {canViewMilestones && (
        <WealthJourneyMilestoneContent
          selectedEntryType={selectedEntryType}
          entries={entries}
          entriesLoading={entriesLoading}
          selectedEntry={selectedEntry}
          onAddEntry={onAddEntry}
          onEditEntry={onEditEntry}
          onDeleteEntry={onDeleteEntry}
          onEntrySelected={onEntrySelected}
          yearSelection={yearSelection}
          taskTitle={taskTitle}
          taskButtonLabel={taskButtonLabel}
          theming={_theming}
        />
      )}
      {canViewActivities && (
        <WealthJourneyActivityContent
          selectedEntryType={selectedEntryType}
          categoriesLoading={categoriesLoading}
          categories={categories}
          entries={entries}
          entriesLoading={entriesLoading}
          onAdd={onAddEntry}
          onEdit={onEditEntry}
          onDelete={onDeleteEntry}
          yearSelection={yearSelection}
        />
      )}
      {canViewMeetings && (
        <WealthJourneyMeetingContent
          selectedEntryType={selectedEntryType}
          entries={entries}
          entriesLoading={entriesLoading}
          selectedEntry={selectedEntry}
          onEntrySelected={onEntrySelected}
          onAddEntry={onAddEntry}
          onEditEntry={onEditEntry}
          onDeleteEntry={onDeleteEntry}
        />
      )}
    </DefaultLayout>
  )
}

NewWealthJourneyHomeV2.propTypes = {
  lineChartDataIdentifier: PropTypes.string.isRequired,

  /** If true, meetings will be included in the wealth journey */
  includeMeetings: PropTypes.bool,

  /** If true, milestones will be included in the wealth journey */
  includeMilestones: PropTypes.bool,

  /** If true, activities will be included in the wealth journey */
  includeActivities: PropTypes.bool,

  /** If true, the net additions line will be included in the line chart */
  includeNetAdditions: PropTypes.bool,

  /** If true, the planning projections will be included in the line chart */
  includeProjections: PropTypes.bool,

  /** If true, milestones and meetings will show separately on the line chart */
  filterEntryTypesOnChart: PropTypes.bool,

  /** Sets the wealth journey chart to a fixed date range [start, end] */
  fixedDateRange: PropTypes.arrayOf(PropTypes.string),

  /** Sets the wealth journey chart to a range of year around present date */
  seriesPastYears: PropTypes.number,
  seriesFutureYears: PropTypes.number,
  /** Sets the wealth journey chart title to a specific string */
  chartTitle: PropTypes.string,
  /** Sets the milestone task title to a specific string */
  taskTitle: PropTypes.string,
  /** Sets the milestone task add button label to a specific string */
  taskButtonLabel: PropTypes.string,
  /** If true, the market value is not display in the chart. False by default */
  hideMarketValue: PropTypes.bool,
  /** If true, hides active date period marker */
  hideActiveDatePeriodMarker: PropTypes.bool,

  theming: PropTypes.shape({
    chart: PropTypes.shape({
      lineColor: PropTypes.string,
      netAdditionsColor: PropTypes.string,
      milestoneColor: PropTypes.string,
      futureMilestoneColor: PropTypes.string,
      meetingColor: PropTypes.string
    }),
    milestone: {
      iconColor: PropTypes.string
    }
  })
}

NewWealthJourneyHomeV2.defaultProps = {
  lineChartDataIdentifier: 'endingValue',
  includeMeetings: false,
  includeMilestones: false,
  includeActivities: true,
  includeNetAdditions: false,
  includeProjections: false,
  filterEntryTypesOnChart: false,
  fixedDateRange: null,
  seriesPastYears: undefined,
  seriesFutureYears: undefined,
  chartTitle: undefined,
  taskTitle: undefined,
  taskButtonLabel: undefined,
  hideMarketValue: false,
  hideActiveDatePeriodMarker: false,
  theming: createDefaultTheming()
}

export default NewWealthJourneyHomeV2
