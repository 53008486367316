import React from 'react'
import { TextField } from '@material-ui/core'
import { Controller } from 'react-hook-form'
import PropTypes from 'prop-types'

const FormInputText = ({ name, control, rules, label, ...props }) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({
        field: { onChange, value },
        fieldState: { error }
      }) => (
        <TextField
          helperText={error ? error.message : null}
          size='small'
          error={!!error}
          onChange={onChange}
          value={value}
          fullWidth
          label={label}
          {...props}
        />
      )}
    />
  )
}

FormInputText.propTypes = {
  rules: PropTypes.object,
  name: PropTypes.string,
  control: PropTypes.object,
  label: PropTypes.string
}

export default FormInputText
