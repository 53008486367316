import React from 'react'
import SydDatePicker from '../../../../../commonDesign/SydDatePicker'

function DateFieldInput (props) {
  return (
    <SydDatePicker size='sm' {...props} />
  )
}

export default DateFieldInput
