import { useMemo } from 'react'
import { useAppContext } from '../../../redux/slices/appContext'
import { useTransactionsSearch } from '../../../api/coreData'
import { useClientDateRange } from './useTransactionsData'
import { useTransactionsContext } from './TransactionsProvider'
import { useSubscribedFilters } from './useSubscribedFilters'

export const useTransactionsTotals = ({
  filter
}) => {
  const { clientId } = useAppContext()
  const { asOfDate, defaultFilter, scope, dateRange } = useTransactionsContext()
  const { value: _dateRange, loading: datesLoading } = useClientDateRange(clientId, asOfDate, dateRange)
  const levelFilters = useSubscribedFilters()

  const transactionsQuery = useMemo(() => {
    if (!_dateRange) return null

    return ({
      resultType: 'total',
      levelFilters,
      filters: {
        ...(defaultFilter || {}),
        ...filter,
        valueDate: [
          { op: 'gte', value: _dateRange.startDate },
          { op: 'lte', value: _dateRange.endDate }
        ],
        ...(scope === 'client' ? { clientId } : {})
      }
    })
  }, [_dateRange, clientId, scope, defaultFilter, filter, levelFilters])

  const { data: transactions, isLoading: transactionsLoading } = useTransactionsSearch(transactionsQuery, { enabled: !!transactionsQuery })

  return {
    relativeDates: _dateRange,
    datesLoading,
    totals: transactions || [],
    totalsLoading: transactionsLoading || datesLoading
  }
}
