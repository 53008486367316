import React, { useCallback, useState } from 'react'
import { makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import { useIsFetching } from '@tanstack/react-query'
import GroupingProvider from '../../../organisms/GroupingProvider'
import GroupingPicker from '../../../organisms/GroupingProvider/GroupingPicker'
import { AutoOperationalTableContextProvider } from '../../../organisms/OperationalTable/AutoOperationalTableContext'
import ReportResultsTable from './ReportResultsTable'
import ReportResultsTitle from './ReportResultsTitle'
import { useReportFiltersContext } from './ReportFiltersProvider/ReportFiltersProvider'
import { useReportContext } from './ReportContext'

const useStyles = makeStyles(() => ({
  reportResults: {
    padding: 0
  },
  tableHeader: {
    zIndex: 3
  }
}))

const STORAGE_CONFIG_KEY = 'bookOfBusinessReportTable'

const ReportResultsContainer = ({
  resultOutputId,
  showCustodianFilter,
  shouldCheckExisting
}) => {
  const classes = useStyles()
  const reportContext = useReportContext()
  const isFetching = useIsFetching()
  const [isLoading, setIsLoading] = useState(false)

  const setLoadingFalse = useCallback(() => {
    setIsLoading(false)
  }, [setIsLoading])

  const setLoadingTrue = useCallback(() => {
    setIsLoading(true)
  }, [setIsLoading])

  const { setColumnFilterVisibility } = useReportFiltersContext()

  const customizeColumnsHandler = useCallback(
    ({ columns }) => {
      setColumnFilterVisibility((prevState) => {
        return Object.entries(prevState).reduce((acc, [columnId, columnFilter]) => {
          const isFilterVisible = columns.includes(columnId)
          acc[columnId] = {
            ...columnFilter,
            visible: isFilterVisible
          }
          return acc
        }, {})
      })
    },
    [setColumnFilterVisibility]
  )

  return (
    <GroupingProvider
      configurationKey={STORAGE_CONFIG_KEY}
      onApplyClick={customizeColumnsHandler}
    >
      <AutoOperationalTableContextProvider>
        <ReportResultsTable.Wrapper
          className={classes.reportResults}
          shouldRefreshHeaderPositioning={!isFetching}
        >
          <ReportResultsTable.SuperHeader className={classes.tableHeader}>
            <ReportResultsTitle
              reportResult={reportContext.reportResult}
              setIsLoadingTrue={setLoadingTrue}
              setIsLoadingFalse={setLoadingFalse}
              isLoading={isLoading}
              reportParams={reportContext}
              showCustodianFilter={showCustodianFilter}
              rightAdornment={
                <GroupingPicker options={{
                  ...reportContext.tableConfigOptions,
                  hideExportButton: true
                }}
                />
              }
            />
          </ReportResultsTable.SuperHeader>
          <ReportResultsTable
            reportResult={reportContext.reportResult}
            isLoading={isLoading}
            resultOutputId={resultOutputId}
            reportParams={reportContext}
            shouldCheckExisting={shouldCheckExisting}
          />
        </ReportResultsTable.Wrapper>
      </AutoOperationalTableContextProvider>
    </GroupingProvider>
  )
}

ReportResultsContainer.propTypes = {
  resultOutputId: PropTypes.string,
  showCustodianFilter: PropTypes.bool,
  shouldCheckExisting: PropTypes.bool
}

export default ReportResultsContainer
