import React, { useCallback, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import SelectWithSearch from '../../../molecules/Select/SelectWithSearch'
import {
  useBoolean,
  useSearchAccountsDebounced,
  useSearchClassificationTagsDebounced,
  useSearchPositionsDebounced
} from '../../../../hooks'
import MenuItem from '../ClientDetailsView/MenuItem'
import Skeleton from '../../../atoms/Skeleton'
import { FEE_OVERRIDE_LEVEL_VALUES } from './helpers'

const useSearchLevelType2 = (
  levelType,
  defaultLevelId,
  excludeLevelIds,
  query
) => {
  const queryOptions = useMemo(() => ({
    ...query,
    excludeLevelIds
  }), [excludeLevelIds, query])

  // Choosing from a list of hooks potentially changes the number of hooks, instead use all the potential hooks and disable the ones we don't want
  const accountSearch = useSearchAccountsDebounced({
    defaultLevelId, ...queryOptions, enabled: levelType === FEE_OVERRIDE_LEVEL_VALUES.account
  })
  const assetClassSearch = useSearchClassificationTagsDebounced({
    defaultLevelId, ...queryOptions, enabled: levelType === FEE_OVERRIDE_LEVEL_VALUES.class
  })
  const positionSearch = useSearchPositionsDebounced({
    defaultLevelId, ...queryOptions, enabled: levelType === FEE_OVERRIDE_LEVEL_VALUES.position
  })

  return useMemo(() => {
    if (levelType === FEE_OVERRIDE_LEVEL_VALUES.account) return accountSearch
    if (levelType === FEE_OVERRIDE_LEVEL_VALUES.class) return assetClassSearch
    if (levelType === FEE_OVERRIDE_LEVEL_VALUES.position) return positionSearch
  }, [levelType, accountSearch, assetClassSearch, positionSearch])
  // return useSearchDebounced({ defaultLevelId, ...queryOptions })
}

const FeeOverrideLevelSelect = ({
  error,
  readOnly,
  disabled,
  levelType,
  levelValue,
  onChangeLevel,
  defaultLevelId,
  excludeLevelIds,
  searchQueryOptions
}) => {
  const [selectedOption, setSelectedOption] = useState(levelValue)
  useEffect(() => {
    setSelectedOption(levelValue)
  }, [levelValue, setSelectedOption])
  const [isSearchBarFocused, setIsSearchBarFocused] = useBoolean()

  const onChange = useCallback((value, selectedOption) => {
    if (selectedOption) {
      setSelectedOption(selectedOption)
    }
    onChangeLevel(value)
  }, [onChangeLevel])

  const levelTypeProps = useSearchLevelType2(
    levelType,
    defaultLevelId,
    excludeLevelIds,
    searchQueryOptions
  )

  const positionOptionsRenderer = useCallback(
    ({ payload: position }, onChange, checkedIcon) => {
      const { positionId, assetIdentifier, accountNumber } = position
      const item = { id: positionId, name: assetIdentifier }
      return (
        <MenuItem
          item={item}
          checked={checkedIcon}
          onChange={onChange}
          secondaryContent={`# ${accountNumber}`}
        />
      )
    },
    []
  )

  const selectProps = useMemo(() => {
    const {
      isSearchLoading,
      onChangeQuery,
      ...levelProps
    } = levelTypeProps

    const selectProps = {
      placeholder: 'Select Level',
      options: [],
      ...levelProps,
      isLoading: isSearchLoading,
      onQueryChange: onChangeQuery
    }
    if (FEE_OVERRIDE_LEVEL_VALUES.account === levelType) {
      selectProps.placeholder = 'Select Account'
      selectProps.searchPlaceholder = 'Search by account name or number'
    }
    if (FEE_OVERRIDE_LEVEL_VALUES.class === levelType) {
      selectProps.placeholder = 'Select Asset Class'
      selectProps.searchPlaceholder = 'Search by class name'
    }
    if (FEE_OVERRIDE_LEVEL_VALUES.position === levelType) {
      selectProps.placeholder = 'Select Position'
      selectProps.searchPlaceholder = 'Search by asset name or id'
      selectProps.optionsRenderer = positionOptionsRenderer
    }
    return selectProps
  }, [levelType, levelTypeProps, positionOptionsRenderer])

  const onClearTextHandler = () => {
    selectProps?.onQueryChange('')
  }

  if (levelTypeProps.isLoading && !isSearchBarFocused) {
    return <Skeleton height='1.75rem' width='100%' />
  }

  return (
    <SelectWithSearch
      error={error}
      onBlur={setIsSearchBarFocused.off}
      onFocus={setIsSearchBarFocused.on}
      value={selectedOption}
      disabled={disabled}
      readOnly={readOnly}
      onChange={onChange}
      onClear={onClearTextHandler}
      onClose={onClearTextHandler}
      {...selectProps}
    />
  )
}

FeeOverrideLevelSelect.propTypes = {
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  levelType: PropTypes.string,
  levelId: PropTypes.number,
  error: PropTypes.string,
  levelValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  defaultLevelId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onChangeLevel: PropTypes.func,
  searchQueryOptions: PropTypes.shape({
    query: PropTypes.object
  }),
  excludeLevelIds: PropTypes.arrayOf(PropTypes.number)
}

export default FeeOverrideLevelSelect
