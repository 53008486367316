// Keep alphabetical order if possible

import keymirror from 'keymirror'
import { cyan, lightBlue, purple, yellow } from '@material-ui/core/colors'
import { MAIN_HEADER_HEIGHT as mainHeaderHeight, themeConfiguration } from './theme'

export const ABUNDANCE_ENGINE_PREFIX_PATH = 'abundance-engine'

export const ADVISOR_VIEW_HARD_LIMIT = 30

export const ACCOUNT_CATEGORY_BLOCK_TYPES = {
  CORE: 'core',
  SIDE: 'side',
  BOTTOM: 'bottom',
  OTHER: 'other'
}

export const ALERT_SEVERITY = keymirror({
  error: null,
  success: null,
  warning: null,
  info: null
})

export const ALERT_VARIANT = keymirror({
  standard: null,
  filled: null,
  outlined: null
})

export const ALLOWED_FIRMS = ['101', '104', '105', '106', '109']

export const ANCHOR_ORIGIN = {
  HORIZONTAL: keymirror({
    left: null,
    right: null
  }),
  VERTICAL: keymirror({
    bottom: null,
    top: null
  })
}

export const ASSET_FILTERS = keymirror({
  SUMMARY_ASSETS: null,
  SUMMARY_ASSETS_CLASSES: null,
  SUMMARY_SUBCLASSES: null,
  ASSETS_SUBCLASS: null,
  ASSET_CLASSES: null,
  ACCOUNTS: null,
  ASSETS: null,
  SUMMARY: null,
  SUBCLASSES: null,
  ACCOUNT_OBJECTIVES: null,
  ASSET_LIQUIDITY: null
})

export const ARROW_VARIANTS = keymirror({
  down: null,
  neutral: null,
  up: null
})

export const AVAILABLE_DATA_TYPE = {
  cash: 'Cash',
  equities: 'Equities',
  fixed: 'Fixed',
  funds: 'Funds'
}

export const BALANCE_CHANGE_STATUS = keymirror({
  positive: null,
  neutral: null,
  negative: null
})

export const BENCHMARK_TYPES = {
  ACCOUNT_OBJECTIVE: 'accountObjective',
  CLIENT: 'client'
}

export const BUTTON_VARIANT = keymirror({
  text: null,
  outlined: null,
  contained: null,
  ghost: null
})

export const BUTTON_SIZES = keymirror({
  extraSmall: null,
  small: null,
  medium: null,
  large: null
})

export const BLOCK_TYPES = keymirror({
  core: null,
  side: null,
  bottom: null,
  others: null
})

export const CALC_TYPES = keymirror({
  balance: null,
  timeSeries: null,
  performance: null
})

export const CHART_COLORS = [
  themeConfiguration.palette.success.main,
  themeConfiguration.palette.gray.main,
  themeConfiguration.palette.bluePalette.main,
  lightBlue[200],
  lightBlue[900],
  cyan[300],
  cyan[100],
  purple[300],
  purple[200],
  yellow[400]
]

export const CHART_LEGEND_ANCHORS = {
  bottom: 'bottom',
  bottomLeft: 'bottom-left',
  bottomRight: 'bottom-right',
  center: 'center',
  left: 'left',
  right: 'right',
  top: 'top',
  topLeft: 'top-left',
  topRight: 'top-right'
}

export const CHART_CURVE_INTERPOLATIONS = keymirror({
  basis: null,
  cardinal: null,
  catmullRom: null,
  linear: null,
  monotoneX: null,
  monotoneY: null,
  natural: null,
  step: null,
  stepAfter: null,
  stepBefore: null
})

export const CHART_LEGEND_DIRECTIONS = keymirror({
  column: null,
  row: null
})

export const CHART_LEGEND_ITEM_DIRECTIONS = {
  leftToRight: 'left-to-right',
  rightToLeft: 'right-to-left',
  topToBottom: 'top-to-bottom',
  bottomToLeft: 'bottom-to-left'
}

export const CHART_SCALE_TYPES = keymirror({
  linear: null,
  point: null,
  cardinal: null
})

export const CLASSIFICATION_TYPES_TITLES = {
  assetClass: 'Asset Class',
  subclass: 'Sub Asset Class',
  sector: 'Sector',
  segment: 'Segment',
  toBeClassified: 'To Be Classified'
}

export const CLIENTS_CHILDREN_TYPE = keymirror({
  card: null,
  cardRecent: null,
  cardConversation: null,
  detail: null,
  list: null,
  cardFilters: null,
  recentlyViewed: null,
  bookmarked: null
})

export const CLIENT_CONVERSATION_FILTERS = keymirror({
  last30Days: null
})

export const COLLAPSED_DRAWER_WIDTH = 80
export const COLLAPSED_RIGHT_CONTENT_WIDTH = 50

export const CONFIRMATION_TYPE = keymirror({
  Primary: null,
  Danger: null
})

export const CONTAINER_REQUESTS_CACHE_TIMEOUT = 1500

export const CONTENT_DISPOSITION_TYPES = {
  INLINE: 'inline',
  ATTACHMENT: 'attachment'
}

export const CSV_CELL_TYPES = keymirror({
  string: null,
  number: null,
  percentage: null,
  component: null
})

export const CUSTOM_LEVEL_TYPES = {
  ACCOUNT_CATEGORY: 'accountCategory',
  REVENUE_SEGMENT: 'revenueSegment',
  CLIENT_BUCKET: 'clientBucket',
  REVENUE_RISK: 'revenueRisk',
  LEAD_ADVISOR: 'leadAdvisor',
  CLIENT_TIER: 'clientTier',
  VALUE_STACK: 'valueStack'
}

export const DATE_FORMAT = 'YYYY/MM/DD'
export const INTERNAL_DATE_FORMAT = 'YYYY-MM-DD'
export const US_DATE_FORMAT = 'M/D/YYYY'

export const DATE_FORMAT_SLASH = 'YYYY/MM/DD'
export const DATE_FORMAT_DASH = 'YYYY-MM-DD'
export const DATE_FORMAT_US_SHORT = 'M/D/YYYY'
export const DATE_FORMAT_US_LONG = 'MM/DD/YYYY'

export const DATE_TAB_PERIODS = {
  MONTHS: 'months',
  QUARTERS: 'quarter',
  YEARS: 'year'
}

export const DATE_TIME_PICKER_TYPES = keymirror({
  date: null,
  dateTime: null,
  time: null,
  keyboardDate: null
})

export const DATE_TYPES = keymirror({
  all: null,
  day: null,
  month: null,
  quarter: null,
  year: null
})

export const DAYJS_OPERATIONS = {
  ADD: 'add',
  SUBTRACT: 'subtract',
  START_OF: 'startOf'
}

export const DAYJS_UNIT_TYPES = {
  DAY: 'day',
  MONTH: 'month',
  QUARTER: 'quarter',
  YEAR: 'year'
}

export const DAYS_OF_WEEK = {
  SUNDAY: 0,
  SATURDAY: 6
}

export const DEFAULT_CLIENT_CARDS_PAGE_SIZE = 10

export const DEFAULT_DEBOUNCE_DELAY_IN_MILLISECONDS = 500

export const DEFAULT_HEADER_KEYS = {
  ACCEPT: 'Accept',
  CONTENT_TYPE: 'Content-Type'
}

export const DEFAULT_SEARCH_CLIENTS_PAGE_SIZE = 25

export const DEFAULT_SEARCH_RECENTS_CLIENTS_PAGE_SIZE = 10

export const TAGS = {
  recents: 'recent',
  others: 'untagged'
}
export const DEFAULT_TAGS = [TAGS.recents, TAGS.others]

export const DIALOG_ACTIONS = keymirror({
  delete: null,
  add: null
})

export const DIRECTION = keymirror({
  forward: null,
  backward: null
})

export const DOCUMENT_VAULT_ACCEPTED_FILES = [
  'image/jpeg',
  'image/png',
  'image/gif',

  'application/pdf',
  'application/doc',
  'application/ms-doc',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',

  'application/excel',
  'application/vnd.ms-excel',
  'application/x-excel',
  'application/x-msexcel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',

  'application/mspowerpoint',
  'application/powerpoint',
  'application/vnd.ms-powerpoint',
  'application/x-mspowerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',

  'application/rtf',

  'text/csv',
  'text/plain'
]

export const MAX_FILES_SIZE_DOCUMENT_VAULT = 50000000 // 50mb
export const MAX_FILES_DOCUMENT_VAULT = 5
export const DOCUMENT_VAULT_REJECTION_MESSAGES = {
  NO_EXTENSION: {
    code: 'no-extension',
    message: 'File extension missing.'
  },
  MAX_FILE_SIZE: {
    code: 'max_file_size',
    message: `One of more files are too large. Allowed maximum size is ${MAX_FILES_SIZE_DOCUMENT_VAULT}.`
  },
  FILE_INVALID_TYPE: {
    code: 'file-invalid-type',
    message: 'For now, we only accept .PDF, .GIF, .JPEG, .JPG, .PNG, .DOC, .DOCX, .XLS, .XLSX, .PPT, .PPTX, .TXT, .CSV, and .RTF documents. Please check your files have one of the previous extensions.'
  },
  MAX_FILE_LENGTH: {
    code: 'too-many-files',
    message: `You have exceeded the maximum number of files allowed. (Max files allowed ${MAX_FILES_DOCUMENT_VAULT}).`
  }
}

export const DOCUMENT_VAULT_CONFIRM_MODAL_TYPE = keymirror({
  EDIT_DOCUMENTS: null,
  DOCUMENT_INFO: null,
  DELETE_DOCUMENTS: null,
  DELETE_TAGS: null
})

export const DRAWER_STATUS = keymirror({
  opened: null,
  closed: null
})

export const DRAWER_WIDTH = 410

export const ELEMENT_ALIGNMENTS = {
  center: 'center',
  flexEnd: 'flex-end',
  flexStart: 'flex-start'
}

export const EMOJI_SEARCH_CHARACTER = ':'
export const MENTIONS_SEARCH_CHARACTER = '@'

export const EMOJI_PICKER_THEMES = keymirror({
  auto: null,
  light: null,
  dark: null
})

export const EMOJI_REGEX = /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|[\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|[\ud83c[\ude32-\ude3a]|[\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g

export const EXPORT_CLASSNAME = 'export-content'

export const FEE_VALUE_TYPES_BPS_VALUE = 'bps'
export const FEE_VALUE_TYPES_DOLLAR_VALUE = 'dollar'
export const FEE_SCHEDULE_TYPES_MINIMUM_FEE_LABEL = 'Minimum Fee'
export const FEE_SCHEDULE_TYPES_MINIMUM_FEE_VALUE = 9999999

export const FILTER_TYPES = keymirror({
  ASSET_CLASSES: null,
  SYMBOL: null,
  TOTAL_VALUE: null,
  PLACE_HOLDER: null
})

export const FIRMS_NAME = ['brightonjones']

export const FIRMS = {
  DEMO_FIRM: '101'
}

export const FULL_SCREEN_TIMEOUT_UNTIL_IDLE = 10000

export const FEATURE_FLAG = keymirror({
  ASSET_CLASSIFICATION_SETUP: null,
  ADVISOR_AND_ROLE_SETUP: null,
  CLASSIFIED_ASSETS_SETUP: null,
  CHANGE_ADD_COMMENT_TO_MESSAGE_TEAM_LABEL: null,
  CONVERSATIONS: null,
  SHOW_SALESFORCE_CLIENT_INFO: null,
  USE_ABUNDANCE_ENGINE_VIEW: null,
  SHOW_RESEARCH_TABLE: null,
  SHOW_ADD_COMMENT_FEATURE: null,
  SHOW_BILLING_INFO: null,
  INTEGRATIONS: null,
  REDTAIL_UPDATE_CLIENT_ACCOUNTS: null,
  BILLING_BILL_NEGATIVE_AS_ZERO: null,
  REBALANCER: null,
  REPORTS: null,
  ROLES: null,
  DATA_HOME: null,
  DENALI_LABS: null,
  BENCHMARKS_SETUP: null,
  ABUNDANCE_ENGINE_VIEW_BY_LEVEL: null
})

export const GAIN_LOSS_LEVELS = {
  levelType: 'client',
  child: {
    levelType: 'account',
    attrs: {
      clientId: null
    },
    child: {
      levelType: 'asset',
      attrs: {
        clientId: null,
        accountId: null
      },
      child: {
        levelType: 'taxLot',
        attrs: {
          clientId: null,
          accountId: null,
          assetId: null
        }
      }
    }
  }
}

export const HTTP_STATUS_CODES = {
  SUCCESS: 200,
  NO_CONTENT: 204
}

export const ICON_NAMES = keymirror({
  accountBalanceWallet: null,
  add: null,
  addCircle: null,
  accountCircle: null,
  alignTextLeft: null,
  archive: null,
  arrowBack: null,
  arrowForward: null,
  at: null,
  back: null,
  bank: null,
  bell: null,
  beTheChange: null,
  birth: null,
  bookmark: null,
  bolt: null,
  block: null,
  calendar: null,
  calendarMonth: null,
  camera: null,
  campaign: null,
  champagneGlass: null,
  chart: null,
  chat: null,
  check: null,
  checkCircle: null,
  chevronDown: null,
  chevronUp: null,
  chevronRight: null,
  chevronLeft: null,
  circle: null,
  clock: null,
  close: null,
  closeCircle: null,
  cloud: null,
  cloudUpload: null,
  collapse: null,
  cropLandscape: null,
  cube: null,
  database: null,
  delete: null,
  document: null,
  dollar: null,
  down: null,
  download: null,
  draftDollar: null,
  dragIndicator: null,
  edit: null,
  education: null,
  expand: null,
  feather: null,
  file: null,
  fileDownload: null,
  filters: null,
  folder: null,
  folderPlus: null,
  gift: null,
  globalNetwork: null,
  goToDocument: null,
  group: null,
  homeIcon: null,
  home: null,
  history: null,
  homeOutlined: null,
  homeUser: null,
  image: null,
  info: null,
  infoOutlined: null,
  layer: null,
  layers: null,
  link: null,
  list: null,
  listAlt: null,
  lovedOnes: null,
  mail: null,
  moon: null,
  navigateNext: null,
  navigatePrevious: null,
  near: null,
  pieChart: null,
  phone: null,
  radioUnchecked: null,
  remove: null,
  removeCircle: null,
  queryStats: null,
  quickReference: null,
  savings: null,
  search: null,
  smiley: null,
  square: null,
  storage: null,
  suitcase: null,
  sync: null,
  target: null,
  travelExplore: null,
  threeDots: null,
  trendingDown: null,
  trendingFlat: null,
  trendingUp: null,
  unfoldLess: null,
  unfoldMore: null,
  up: null,
  update: null,
  user: null,
  viewListConfig: null,
  work: null,
  workOff: null,
  book: null,
  salesforceSmall: null,
  practifiSmall: null,
  salesforce: null,
  wealthbox: null,
  wealthboxSmall: null,
  wealthJourneyMilestones: null,
  radioChecked: null,
  redtail: null,
  redtailSmall: null,
  fidelity: null,
  schwab: null,
  moneyGuidePro: null,
  nitrogen: null,
  riskalyze: null,
  rightCapital: null,
  dst: null,
  eMoney: null,
  globalRelay: null,
  junxure: null,
  morningstar: null,
  smarsh: null,
  iRebal: null,
  ria: null,
  accountTreeRounded: null,
  noInfo: null,
  currency: null,
  downloadView: null,
  customCheck: null,
  location: null,
  lock: null,
  arrowDown: null,
  logout: null,
  settings: null,
  security: null,
  export: null,
  fileCopy: null,
  share: null,
  vacations: null,
  vbsTreeView: null,
  vbsListView: null,
  vbsTreeColored: null,
  vertDots: null,
  visibility: null,
  circleMinus: null,
  circleMinusHover: null,
  deactivateUser: null,
  resetUser: null,
  paperGlassMagnifier: null,
  paperCalendar: null,
  paperMilestones: null,
  paperTasks: null,
  paperBookmarkClient: null,
  paperMessageBubbles: null,
  party: null,
  benchmarks: null,
  clients: null,
  usersAdmin: null,
  persons: null,
  tag: null,
  travel: null,
  folders: null,
  bill: null,
  dataHome: null,
  accounts: null,
  pyramid: null,
  warning: null
})

export const KEYS = {
  ESC: 27,
  ENTER: 13,
  ARROW_LEFT: 37,
  ARROW_UP: 38,
  ARROW_RIGHT: 39,
  ARROW_DOWN: 40
}

export const LEVEL_TYPES = {
  CLIENT: 'client',
  ASSETS: 'asset',
  SUBCLASS_TAG: 'subclassTag',
  ASSET_CLASS_TAG: 'assetClassTag',
  ACCOUNTS: 'account',
  ADVISOR: 'advisor',
  ACCOUNT_CATEGORY: 'AccountCategory',
  ACCOUNT_OBJECTIVE: 'accountObjective',
  ASSET_LIQUIDITY: 'assetLiquidityTag'
}

export const LEVEL_TYPE_BY_ASSET_FILTER = {
  SUMMARY_ASSETS: LEVEL_TYPES.ASSETS,
  SUMMARY_ASSETS_CLASSES: LEVEL_TYPES.ASSET_CLASS_TAG,
  SUMMARY_SUBCLASSES: LEVEL_TYPES.SUBCLASS_TAG,
  ASSETS_SUBCLASS: LEVEL_TYPES.ASSETS,
  ASSET_CLASSES: LEVEL_TYPES.ASSET_CLASS_TAG,
  ACCOUNTS: LEVEL_TYPES.ACCOUNTS,
  ASSETS: LEVEL_TYPES.ASSETS,
  SUMMARY: null,
  SUBCLASSES: LEVEL_TYPES.SUBCLASS_TAG,
  ACCOUNT_OBJECTIVES: LEVEL_TYPES.ACCOUNT_OBJECTIVE,
  ASSET_LIQUIDITY: LEVEL_TYPES.ASSET_LIQUIDITY
}

export const LOCAL_STORAGE_KEYS = keymirror({
  isOverviewFullScreenEnabledKey: null,
  availableDates: null,
  clientId: null,
  firmId: null,
  clientCardRecentInteractionsExpanded: null,
  clientCardRecentInteractionsType: null,
  advisorHomeTab: null,
  advisorHomeScopeValues: null,
  tabLink: null,
  aeGalleryConfig: null
})

export const MEETINGS_BODY_TYPES = {
  MARKDOWN: 'markdown'
}

export const MILESTONE_CATEGORIES = {
  MILESTONE: 'milestone',
  REAL_ESTATE: 'realEstate',
  BIRTH: 'birth',
  RETIREMENT: 'retirement',
  TRAVEL: 'travel',
  SAVINGS: 'savings'
}

export const MILESTONE_CATEGORY_OPTIONS = [{
  key: MILESTONE_CATEGORIES.MILESTONE,
  value: MILESTONE_CATEGORIES.MILESTONE,
  label: 'Default',
  startAdormentIcon: true,
  startAdormentIconProps: {
    name: ICON_NAMES.bookmark,
    margin: '0px 10px 0px 0px',
    customSize: '18px'
  }
},
{
  key: MILESTONE_CATEGORIES.REAL_ESTATE,
  value: MILESTONE_CATEGORIES.REAL_ESTATE,
  label: 'Real Estate',
  startAdormentIcon: true,
  startAdormentIconProps: {
    name: ICON_NAMES.homeIcon,
    margin: '0px 10px 0px 0px',
    customSize: '18px'
  }
},
{
  key: MILESTONE_CATEGORIES.BIRTH,
  value: MILESTONE_CATEGORIES.BIRTH,
  label: 'Birth',
  startAdormentIcon: true,
  startAdormentIconProps: {
    name: ICON_NAMES.birth,
    margin: '0px 10px 0px 0px',
    customSize: '18px'
  }
},
{
  key: MILESTONE_CATEGORIES.RETIREMENT,
  value: MILESTONE_CATEGORIES.RETIREMENT,
  label: 'Retirement',
  startAdormentIcon: true,
  startAdormentIconProps: {
    name: ICON_NAMES.workOff,
    margin: '0px 10px 0px 0px',
    customSize: '18px'
  }
},
{
  key: MILESTONE_CATEGORIES.TRAVEL,
  value: MILESTONE_CATEGORIES.TRAVEL,
  label: 'Travel',
  startAdormentIcon: true,
  startAdormentIconProps: {
    name: ICON_NAMES.travelExplore,
    margin: '0px 10px 0px 0px',
    customSize: '18px'
  }
},
{
  key: MILESTONE_CATEGORIES.SAVINGS,
  value: MILESTONE_CATEGORIES.SAVINGS,
  label: 'Savings',
  startAdormentIcon: true,
  startAdormentIconProps: {
    name: ICON_NAMES.savings,
    margin: '0px 10px 0px 0px',
    customSize: '18px'
  }
}
]

export const MAX_COMMENT_INPUT_LENGTH = 500

export const MAX_INLINE_INPUT_LENGTH = 250

export const MAX_NOTE_REACTIONS = 6

export const MAX_WORD_LENGTH_ALLOWED_ON_USERNAME = 3

export const NIVO_TOOLTIP_FORMATTERS = keymirror({
  DATE_PERCENTAGE: null
})

export const NIVO_MOTION_PRESETS = keymirror({
  default: null,
  gentle: null,
  wobbly: null,
  stiff: null,
  slow: null,
  molasses: null
})

export const NOTES_BOARD_HEADERS_FILTER_HEIGHT = 56

export const NOTE_BOARD_TYPES = {
  Active: 1,
  Archived: 0
}

export const NOTE_MENTION_REGEX = /[-[\]{}()*+?.,\\^$|#\s]/g

export const NOTE_MENU_BUTTON_MAX_COMMENTS = 9

export const NOTE_NOTIFICATION_TYPES = keymirror({
  CREATE: null
})

export const NOTE_NOTIFICATION_INTERACTION_TYPES = keymirror({
  SEEN: null
})

export const NOTE_TRIGGERED_BY = keymirror({
  BOARD: null,
  FULL_VIEW: null,
  VIEW: null,
  SECTION: null,
  ADD_ASSET: null
})

export const OKTA_USER_STATUS = {
  staged: 'STAGED',
  provisioned: 'PROVISIONED',
  active: 'ACTIVE',
  recovery: 'RECOVERY'
}

export const ORDER = {
  DESC: 'desc',
  ASC: 'asc'
}

export const ORDER_BY_KEYS = keymirror({
  ordinal: null,
  netFlows: null
})

export const ORDER_BY = Object.keys(ORDER_BY_KEYS).reduce(
  (object, key) => ({
    ...object,
    [key]: (ascending = true) => `${ascending ? 'ASC' : 'DESC'}`
  }), {}
)

export const PERIOD_DATE_FORMAT = {
  START_DATE: 'M/1/YYYY',
  END_DATE: 'M/D/YYYY'
}

export const PREVIEW_TRIGGED_BY = keymirror({
  MOUSE: null,
  KEY: null
})

export const QUARTERS_LABELS = {
  q1: 'Q1',
  q2: 'Q2',
  q3: 'Q3',
  q4: 'Q4'
}

export const RIGHT_CONTENT_WIDTH = 375
export const RIGHT_CONTENT_PADDING = 20

export const SALESFORCE_ITEMS = {
  householdName: {
    title: 'Household Name'
  },
  email: {
    title: 'Email'
  },
  businessEmail: {
    title: 'Business Email'
  },
  homeEmail: {
    title: 'Home Email'
  },
  primaryEmail: {
    title: 'Primary Email'
  },
  homePhone: {
    title: 'Home Phone'
  },
  mobile: {
    title: 'Mobile'
  },
  billableNetWorth: {
    title: 'Billable NetWorth',
    isNumber: true
  },
  clientTenure: {
    title: 'Client Tenure'
  },
  quarterlyInvoiceAmount: {
    title: 'Quarterly Invoice Amount',
    isNumber: true
  },
  annualRevenue: {
    title: 'Annual Revenue',
    isNumber: true
  },
  dateLastClientReview: {
    title: 'Last Client Review'
  },
  daysLastContact: {
    title: 'Days Since Last Contact'
  },
  daysSinceLastClientReview: {
    title: 'Days Since Last Client Review'
  },
  nextReview: {
    title: 'Next Review'
  },
  birthdate: {
    title: 'Birthdate'
  },
  clientAnniversary: {
    title: 'Anniversary'
  }
}

export const WEALTHBOX_ITEMS = {
  householdName: {
    title: 'Household Name',
    path: 'name',
    type: 'text-field'
  },
  email: {
    title: 'Email',
    path: 'email',
    type: 'text-field'
  },
  Benjamin: {
    title: 'Benjamin',
    type: 'text-field',
    path: 'custom_fields.Benjamin.value'
  },
  'Communication Preference': {
    title: 'Communication Preference',
    type: 'single-select',
    path: ['custom_fields', 'Communication Preference', 'value']
  },
  Correspondence: {
    title: 'Correspondence',
    type: 'text-field',
    path: 'custom_fields.Correspondence.value'
  },
  Custodian: {
    title: 'Custodian',
    type: 'multi-check-box',
    path: 'custom_fields.Custodian.value'
  },
  'Fee Schedule': {
    title: 'Fee Schedule',
    type: 'single-select',
    path: ['custom_fields', 'Fee Schedule', 'value']
  },
  'Held Away Assets': {
    title: 'Held Away Assets',
    type: 'text-field',
    path: ['custom_fields', 'Held Away Assets', 'value']
  },
  IPS: {
    title: 'IPS',
    type: 'check-box',
    path: ['custom_fields', 'IPS', 'value']
  },
  'IRA rollover/transfer documentation': {
    title: 'IRA rollover/transfer documentation',
    type: 'check-box',
    path: ['custom_fields', 'IRA rollover/transfer documentation', 'value']
  },
  'Inherited IRA\'s': {
    title: 'Inherited IRA\'s',
    type: 'text-field',
    path: ['custom_fields', 'Inherited IRA\'s', 'value']
  },
  'Management Agreement': {
    title: 'Management Agreement',
    type: 'check-box',
    path: ['custom_fields', 'Management Agreement', 'value']
  },
  'Meeting Frequency': {
    title: 'Meeting Frequency',
    type: 'single-select',
    path: ['custom_fields', 'Meeting Frequency', 'value']
  },
  'Portfolio Objective': {
    title: 'Portfolio Objective',
    type: 'single-select',
    path: ['custom_fields', 'Portfolio Objective', 'value']
  },
  'Risk Profile': {
    title: 'Risk Profile',
    type: 'single-select',
    path: ['custom_fields', 'Risk Profile', 'value']
  },
  homePhone: {
    title: 'Home Phone',
    type: 'text-field',
    path: ''
  },
  mobile: {
    title: 'Mobile',
    type: 'text-field',
    path: ''
  }
}

export const REDTAIL_ACCOUNTS_UPDATED_ITEM_NAME = 'accountsUpdated'

export const REDTAIL_ITEMS = {
  householdName: {
    title: 'Household Name',
    path: 'full_name',
    type: 'text-field'
  },
  category: {
    title: 'Category',
    path: 'category',
    type: 'text-field'
  },
  status: {
    title: 'Status',
    path: 'status',
    type: 'text-field'
  },
  client_since: {
    title: 'Client since',
    path: 'client_since',
    type: 'text-field'
  },
  servicing_advisor: {
    title: 'Servicing advisor',
    path: 'servicing_advisor',
    type: 'text-field'
  },
  writing_advisor: {
    title: 'Writing advisor',
    path: 'writing_advisor',
    type: 'text-field'
  },
  primaryEmail: {
    title: 'Primary Contact email',
    path: ['primary_email', 'address'],
    type: 'text-field'
  },
  primaryPhone: {
    title: 'Primary Contact phone',
    path: ['primary_phone', 'number'],
    type: 'text-field'
  },
  mobilePhone: {
    title: 'Mobile phone',
    path: ['mobile_phone', 'number'],
    type: 'text-field'
  },
  workEmail: {
    title: 'Work email',
    path: ['work_email', 'address'],
    type: 'text-field'
  },
  workPhone: {
    title: 'Work phone',
    path: ['work_phone', 'number'],
    type: 'text-field'
  },
  [REDTAIL_ACCOUNTS_UPDATED_ITEM_NAME]: {
    title: 'Accounts updated in Redtail',
    path: 'lastUpdatedAccountsDate',
    type: 'text-field',
    formatter: (value) => {
      if (value && value !== '-') {
        const date = new Date(value)
        const year = date.toLocaleString('default', { year: 'numeric' })
        const month = date.toLocaleString('default', { month: '2-digit' })
        const day = date.toLocaleString('default', { day: '2-digit' })
        const hour = date.toLocaleTimeString('default', {
          hour: 'numeric',
          minute: 'numeric',
          hour12: true
        })
        return `${year}/${month}/${day} ${hour}`
      }
      return value
    }
  }
}

export const SIDE_NAV_WIDTH = 300

export const SIZE_VARIANTS = keymirror({
  extraSmall: null,
  small: null,
  medium: null,
  large: null,
  xLarge: null,
  fill: null
})

export const SKELETON_ANIMATIONS = keymirror({
  pulse: null,
  wave: null
})
SKELETON_ANIMATIONS.none = false

export const SKELETON_VARIANTS = keymirror({
  circle: null,
  rect: null,
  text: null
})

export const SPEED_DIAL_DIRECTION = {
  Up: 'up',
  Right: 'right',
  Down: 'down',
  Left: 'left'
}

export const TABLE_DATE_FORMAT = 'MMM D, YYYY [at] h:mm a'
export const TRADE_DATE_SHORT_FORMAT = 'M/D/YYYY'

export const TABLE_CELL_FORMATS = {
  PLAIN: {
    MID: '0,0.00'
  },
  NUMBER: {
    SHORT: '0a',
    MID: '0,0.00a',
    LONG: '0.0000a',
    COMMAS_INTEGER: '0,0'
  },
  PERCENTAGE: {
    SHORT: '0%',
    MID: '0.0%',
    NO_SIGN: '0',
    LONG: '0.0000%'
  }
}

export const TABLE_CELL_TYPE = keymirror({
  NUMBER: null,
  COMPONENT: null
})

export const TASK_STATUS = keymirror({
  complete: null,
  incomplete: null
})

export const TEXT_ALIGNMENTS = keymirror({
  center: null,
  left: null,
  right: null
})

export const TEXT_VARIANTS = keymirror({
  h1: null,
  h2: null,
  h3: null,
  h4: null,
  h6: null,
  subtitle1: null,
  subtitle2: null,
  body1: null,
  body2: null,
  caption: null,
  inherit: null,
  button: null,
  overline: null
})

export const THEME_COLORS = {
  blue: themeConfiguration.palette.bluePalette.main,
  green: themeConfiguration.palette.success.main,
  red: themeConfiguration.palette.error.main,
  gold: themeConfiguration.palette.gold,
  lightGreen: themeConfiguration.palette.lightGreen,
  lightGrey: themeConfiguration.palette.lightGrey,
  darkGrey: themeConfiguration.palette.darkGrey,
  shuttleGrey: themeConfiguration.palette.shuttleGrey,
  lavanda: themeConfiguration.palette.lavanda
}

export const TOOLTIP_PLACEMENT = {
  BottomEnd: 'bottom-end',
  BottomStart: 'bottom-start',
  Bottom: 'bottom',
  LeftEnd: 'left-end',
  LeftStart: 'left-start',
  Left: 'left',
  RightEnd: 'right-end',
  RightStart: 'right-start',
  Right: 'right',
  TopEnd: 'top-end',
  TopStart: 'top-start',
  Top: 'top'
}

export const TRANSFORM_HORIZONTAL = keymirror({
  Left: null,
  Right: null
})

export const TRANSFORM_VERTICAL = keymirror({
  Top: null,
  Bottom: null
})

export const USERS_FORM_ACTIONS = {
  ADD: 'ADD',
  ADD_INVITE: 'ADD_INVITE',
  DELETE: 'DELETE'
}

export const USER_ACTIVITIES_TYPES = keymirror({
  clientInteraction: null
})

export const WEALTH_JOURNEY_DATE_PERIODS = 5
export const MAX_WEALTH_JOURNEY_DATE_PERIODS = 8
export const WEALTH_JOURNEY_LINE_DATA_SECTIONS = {
  BEFORE: 'before',
  AFTER: 'after',
  HIGHLIGHT: 'highlight'
}

export const DATE_PRESET = keymirror({
  Y: null,
  TW: null,
  L7D: null,
  L30: null,
  L3: null,
  L6: null,
  L12: null,
  MD: null,
  QD: null,
  YD: null,
  SI: null
})

export const TABLE_VARIANTS = keymirror({
  floatingHeader: null
})

export const DISPLAY_VBS_NUMBER_FORMAT = {
  SHORT: '0,0a',
  LONG: '0,0.0a'
}

export const TITLE_VBS_NUMBER_FORMAT = '0,0.0000'

export const DATE_RANGES = [{
  key: 'Y',
  label: 'Yesterday'
},
{
  key: 'TW',
  label: 'This week'
},
{
  key: 'L7D',
  label: 'Last 7 days'
},
{
  key: 'L30',
  label: 'Last 30 days'
},
{
  key: 'L3',
  label: 'Last 3 months'
},
{
  key: 'L6',
  label: 'Last 6 months'
},
{
  key: 'L12',
  label: 'Last 12 months'
},
{
  key: 'MD',
  label: 'Month to date'
},
{
  key: 'QD',
  label: 'Quarter to date'
},
{
  key: 'YD',
  label: 'Year to date'
}
]

export const WEALTHBOX_TAG_GROUP_TYPE_ID = 252

export const WEALTHBOX_INTEGRATION = 'wealthbox'
export const WEALTHBOX_INTEGRATION_SUCCESS_STATUS_CODE = 'wb_success'
export const WEALTHBOX_INTEGRATION_ERROR_STATUS_CODE = 'wb_error'
export const SALESFORCE_INTEGRATION_SUCCESS_STATUS_CODE = 'sf_success'
export const SALESFORCE_INTEGRATION_ERROR_STATUS_CODE = 'sf_error'

export const REDTAIL_INTEGRATION_SUCCESS_STATUS_CODE = 'rt_success'
export const REDTAIL_INTEGRATION_ERROR_STATUS_CODE = 'rt_error'
export const SALESFORCE_INTEGRATION = 'salesforce'
export const REDTAIL_INTEGRATION = 'redtail'
export const SCHWAB_INTEGRATION = 'schwab'
export const FIDELITY_INTEGRATION = 'fidelity'
export const MGP_INTEGRATION = 'moneyGuidePro'
export const RIGHT_CAPITAL_INTEGRATION = 'rightCapital'
export const NITROGEN_INTEGRATION = 'nitrogen'
export const RISKALYZE_INTEGRATION = 'riskalyze'
export const JUNXURE_INTEGRATION = 'junxure'
export const EMONEY_INTEGRATION = 'eMoney'
export const SMARSH_INTEGRATION = 'smarsh'
export const GLOBAL_RELAY_INTEGRATION = 'globalRelay'
export const RIA_BOX_INTEGRATION = 'riaInABox'
export const I_REBAL_INTEGRATION = 'iRebal'
export const MONRNINGSTAR_INTEGRATION = 'morningstar'
export const DST_INTEGRATION = 'dst'

export const INTEGRATIONS_STATUS_MESSAGES = {
  [WEALTHBOX_INTEGRATION_SUCCESS_STATUS_CODE]: 'Wealthbox CRM authorized.',
  [WEALTHBOX_INTEGRATION_ERROR_STATUS_CODE]: 'Something went wrong with Wealthbox CRM authorization.\n\nPlease try again or contact support.',
  [SALESFORCE_INTEGRATION_SUCCESS_STATUS_CODE]: 'Salesforce CRM authorized.',
  [SALESFORCE_INTEGRATION_ERROR_STATUS_CODE]: 'Something went wrong with Salesforce CRM authorization.\n\nPlease try again or contact support.',
  [REDTAIL_INTEGRATION_SUCCESS_STATUS_CODE]: 'Redtail CRM authorized.',
  [REDTAIL_INTEGRATION_ERROR_STATUS_CODE]: 'Something went wrong with Redtail CRM authorization.\n\nPlease try again or contact support.'
}

export const INTEGRATIONS = [
  SALESFORCE_INTEGRATION,
  WEALTHBOX_INTEGRATION,
  REDTAIL_INTEGRATION,
  MGP_INTEGRATION,
  RIGHT_CAPITAL_INTEGRATION,
  FIDELITY_INTEGRATION,
  NITROGEN_INTEGRATION,
  SCHWAB_INTEGRATION,
  RISKALYZE_INTEGRATION,
  JUNXURE_INTEGRATION,
  EMONEY_INTEGRATION,
  SMARSH_INTEGRATION,
  GLOBAL_RELAY_INTEGRATION,
  RIA_BOX_INTEGRATION,
  I_REBAL_INTEGRATION,
  MONRNINGSTAR_INTEGRATION,
  DST_INTEGRATION
]

export const INTEGRATION_CRMS = [
  SALESFORCE_INTEGRATION,
  WEALTHBOX_INTEGRATION,
  REDTAIL_INTEGRATION,
  JUNXURE_INTEGRATION,
  'practifi'
]

export const CUSTODIAL_INTEGRATIONS = [
  SCHWAB_INTEGRATION,
  FIDELITY_INTEGRATION,
  MONRNINGSTAR_INTEGRATION,
  DST_INTEGRATION
]

export const PLANNING_TOOLS_INTEGRATIONS = [
  MGP_INTEGRATION,
  RIGHT_CAPITAL_INTEGRATION,
  NITROGEN_INTEGRATION,
  RISKALYZE_INTEGRATION,
  EMONEY_INTEGRATION
]

export const COMPLIANCE_INTEGRATIONS = [
  SMARSH_INTEGRATION,
  GLOBAL_RELAY_INTEGRATION,
  RIA_BOX_INTEGRATION
]

export const TRADING_INTEGRATIONS = [
  I_REBAL_INTEGRATION
]

export const VIRTUALIZE_PROPS = {
  rowHeight: 58,
  tableHeaderHeight: 70,
  maxRowsBeforeScroll: 10,
  useParentHeight: true
}

export const X_AXES_POSITIONS = keymirror({
  bottom: null,
  top: null
})

export const Y_AXES_POSITIONS = keymirror({
  left: null,
  right: null
})

export const CUSTODIANS = {
  BY_ALL_ACCOUNTS: 50
}

export const USER_TYPE = {
  WEALTH_OWNER: 1,
  ADVISOR: 2
}

export const DEFAULT_SELECT_SEARCH_PAGINATION = { take: 10, skip: 0 }

export const MILLISECONDS_BEFORE_SEARCH = 1000

export const TAX_STATUS_OPTIONS = [
  { label: 'Taxable', value: 1 },
  { label: 'Tax-Deferred', value: 2 },
  { label: 'Non-Taxable', value: 3 }
]

export const TAX_STATUS_VALUES = {
  1: 'Taxable',
  2: 'Tax-Deferred',
  3: 'Non-Taxable'
}

export const ADMIN_ROUTES = {
  ADMIN: '/admin',
  ADVISORS: '/admin/advisors',
  ADVISORS_NEW: '/admin/advisors-new',
  ROLES: '/admin/roles',
  ROLE_NEW: '/admin/roles/new',
  CLIENTS: '/admin/clients',
  NEW_CLIENT: '/admin/clients/new',
  WEALTH_OWNERS: '/admin/wealth-owners',
  ACCOUNTS: '/admin/accounts',
  ACCOUNTS_NOT_ASSIGNED: '/admin/accountsnotassigned',
  PERSONS: '/admin/persons',
  BILLING: '/admin/billing',
  BILLING_FEE_SCHEDULES: '/admin/billing/fee-schedules',
  BILLING_NO_FEE_SCHEDULES: '/admin/billing/no-fee-schedules',
  BILLING_MULTIPLE_FEE_SCHEDULES: '/admin/billing/multiple-fee-schedules',
  BILLING_FEE_SCHEDULES_NEW: '/admin/billing/fee-schedules-new',
  BILLING_CLIENT_ASSIGNMENTS: '/admin/billing/client-assignments',
  BILLING_RUN: '/admin/billing/run',
  BILLING_CLIENT_REVIEW: '/admin/billing/client-review',
  BILLING_RUN_DETAILS: '/admin/billing/billing-run-details',
  INTEGRATIONS: '/admin/integrations',
  INTEGRATION: '/admin/integrations/:integration',
  MANAGE_INTEGRATION: '/admin/integrations/:integration/manage',
  ASSET_CLASSIFICATIONS_SETUP: '/admin/assetclassificationsetup',
  CLASSIFY_ASSETS: '/admin/manage-assets',
  ABUNDANCE_ENGINE_VIEWS: '/admin/abundance-engine-views',
  ABUNDANCE_ENGINE_GALLERY: '/admin/abundance-engine-gallery',
  REPORTS: '/admin/reports',
  REPORTS_TEMPLATES: '/admin/reports/templates',
  REPORTS_TEMPLATES_RUNTIME_CONFIGURATIONS: '/admin/reports/templates-runtime-configurations',
  REPORTS_BATCHES: '/admin/reports/batches',
  REPORTS_BATCH: '/admin/reports/batches/:batchRunId',
  BENCHMARKS: '/admin/benchmarks',
  BENCHMARKS_BLENDED_NEW: '/admin/benchmarks/blended-new',
  BENCHMARKS_BLENDED: '/admin/benchmarks/blended',
  BENCHMARKS_SOURCE: '/admin/benchmarks/source',
  STATUS_REPORTS: '/admin/status-reports',
  NEW_STATUS_REPORT_TEMPLATE: '/admin/status-reports/template/new',
  STATUS_TEMPLATE_DETAILS: '/admin/status-reports/template',
  MANAGER_DETAILS: '/admin/managers',
  NEW_MANAGER: '/admin/managers/new',
  MODEL_ADMIN: '/admin/models',
  TARGET_MODELS: '/admin/models?tab=target-models',
  COMPONENT_MODELS: '/admin/models?tab=component-models',
  NEW_TARGET_MODEL: '/admin/models/target/new',
  NEW_COMPONENT_MODEL: '/admin/models/component/new',
  TARGET_MODEL_DETAILS: '/admin/models/target',
  COMPONENT_MODEL_DETAILS: '/admin/models/component',
  DATA_MAINTENANCE: '/admin/data-maintenance',
  TRANSACTION_TAGGING: '/admin/data-maintenance/transaction-tagging'
}

export const DATA_HOME_ROUTES = {
  DATA_HOME: '/data-home',
  DATA_SOURCE: '/data-home/source/:platformDataSourceId',
  DATA_QUERY: '/data-home/query/:platformDataSourceId',
  DATA_UPLOAD_FILE: '/data-home/upload-file',
  DATA_COMPLIANCE_REPORTS: '/data-home/compliance-reports',
  DATA_HOME_JOBS: '/data-home/jobs',
  DATA_HOME_JOB_DETAILS: '/data-home/jobs/:jobId',
  DATA_HOME_JOB_RESULTS: '/data-home/jobs/:jobId/batch/:batchId',
  DATA_HOME_TARGETS: '/data-home/targets',
  DATA_HOME_TARGET_DETAILS: '/data-home/targets/:targetId',
  DATA_HOME_PUBLISH_PREVIEW: '/data-home/targets/:targetId/publish/:policyId/preview',
  DATA_HOME_PUBLISH_RESULTS: '/data-home/publishing/results/:resultId'
}

export const PASSWORD_ROUTES = {
  FORGOT: '/forgot-password',
  ACTIVATION: '/activation',
  RECOVER: '/recover-password',
  RESET_USER: '/reset-user'
}

export const API_ERROR_CODES = {
  BAD_REQUEST: 400,
  INTERNAL_SERVER_ERROR: 500,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403
}

export const PASSWORD_ERROR_MESSAGES = {
  OLD_PASSWORD: 'Unable to validate old password',
  CURRENT_PASSWORD: 'Password cannot be your current password',
  PREVIOUS_PASSWORD: 'Password has been used too recently',
  INVALID_ANSWER: 'Unable to validate recovery answer',
  INVALID_PASSWORD: 'The password you entered in incorrect',
  INVALID_FIELD: 'The field cannot be your username'
}

export const ERROR_ALERT = {
  openAlert: true,
  alertMessage: 'Something went wrong.',
  alertSeverity: ALERT_SEVERITY.error
}

export const SUCCESS_ALERT = {
  openAlert: true,
  alertMessage: 'Your account details have been updated.',
  alertSeverity: ALERT_SEVERITY.success
}

export const ADVISOR_VIEW_PATH = 'advisor-view'
export const ADVISOR_HOME_PATH = 'advisor-home'
export const CLIENTS_CARDS_PATH = '/views/clients'

export const FEE_CALC_TYPES = {
  TIERED_SCHEDULE: 1,
  FLAT_SCHEDULE: 2,
  FLOATING_RATE: 3,
  FLAT_DOLLAR: 4
}

export const BENCHMARKS = {
  SOURCE: 'onlySource',
  BLENDED: 'onlyBlended'
}

export const RETURN_STATUS_TYPES = keymirror({
  positive: null,
  neutral: null,
  negative: null
})

export const TAX_STATUS_TYPES = {
  1: 'Taxable',
  2: 'Tax Deferred',
  3: 'Non-Taxable',
  4: 'Not Defined'
}

export const assignmentStatusOptions = [
  { label: 'Assigned', value: 'assigned' },
  { label: 'Unassigned', value: 'unassigned' }
]

export const CLIENTS_MEMBER_LEVEL_ID = 201

export const MAIN_HEADER_HEIGHT = mainHeaderHeight
