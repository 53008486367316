import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { EXPORT_CLASSNAME } from '../../../constants'
import { useExportContext } from './ExportProvider'

const ExportRoot = ({ targetClassName, enabled, children }) => {
  const { configureExport } = useExportContext()
  useEffect(() => {
    if (enabled) {
      configureExport(true, targetClassName)
      return () => {
        configureExport(false)
      }
    } else {
      configureExport(false)
    }
  }, [configureExport, targetClassName, enabled])

  return children
}

ExportRoot.propTypes = {
  targetClassName: PropTypes.string,
  enabled: PropTypes.bool,
  children: PropTypes.node
}

ExportRoot.defaultProps = {
  enabled: true,
  targetClassName: EXPORT_CLASSNAME
}

export default ExportRoot
