import React, { useCallback, useImperativeHandle, useState } from 'react'
import RoundedModal from '../../../../../molecules/RoundedModal'
import { useDialogStyles } from '../../useDialogStyles'
import SydButton from '../../../../../commonDesign/Button'
import { useAccountFormContext } from '../../AccountFormProvider'
import { useRemoveAccountManagerMutation } from '../../../../../../api/accounts'
import SydLabel from '../../../../../commonDesign/SydLabel'
import SydReadOnly from '../../../../../commonDesign/SydReadOnly'

const RemoveManagerDialog = React.forwardRef(function RemoveManagerDialog (_, ref) {
  const classes = useDialogStyles()
  const [dialogState, setDialogState] = useState({ open: false, processing: false })
  const close = useCallback(() => {
    setDialogState({ open: false, processing: false })
  }, [setDialogState])

  useImperativeHandle(ref, () => ({
    open: (item) => setDialogState({ open: true, processing: false, parent: item })
  }), [setDialogState])

  const { account } = useAccountFormContext()
  const { mutateAsync: removeManager } = useRemoveAccountManagerMutation()
  const [processing, setProcessing] = useState(false)
  const onRemove = useCallback(async (managerId) => {
    try {
      setProcessing(managerId)
      await removeManager({
        accountId: account.accountId,
        managerId: account.managerId
      })
      close()
    } catch (err) {
      console.error(err)
      alert('There was a problem removing the manager assignment')
    } finally {
      setProcessing(false)
    }
  }, [account, close, removeManager])

  return (
    <RoundedModal
      title='Remove Manager Assignment'
      open={dialogState.open}
      onClose={close}
      position='top'
      disablePortal={false}
    >
      <div className={classes.form}>
        <SydLabel label='This will remove this Manager Assignment'>
          <SydReadOnly>
            {account?.manager?.name}
          </SydReadOnly>
        </SydLabel>
      </div>
      <div className={classes.actions}>
        <SydButton disabled={processing} variant='ghost' size='lg' onClick={close}>Cancel</SydButton>
        <SydButton processing={processing} priority='warning' variant='primary' size='lg' onClick={onRemove}>Remove Manager</SydButton>
      </div>

    </RoundedModal>
  )
})

RemoveManagerDialog.propTypes = {}

export default RemoveManagerDialog
