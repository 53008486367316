import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTransition, animated, useSpring } from 'react-spring'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import has from 'lodash/has'
import { useOktaAuth } from '@okta/okta-react'
import { makeStyles } from '@material-ui/core/styles'
import { Box, Container, IconButton, Typography } from '@material-ui/core'
import clsx from 'clsx'
import AbundanceEngineView from '../../../../abundanceEngine/AbundanceEngineView'
import { useFeatureFlag } from '../../../../redux/slices/appConfig'
import {
  DIRECTION,
  FEATURE_FLAG,
  NOTE_TRIGGERED_BY,
  ICON_NAMES,
  TEXT_VARIANTS
} from '../../../../constants'
import {
  useAppContext,
  useSetAppContext
} from '../../../../redux/slices/appContext'
import { useSetNoteContext } from '../../../../redux/slices/noteContext'
import { useCurrentClient } from '../../../../redux/slices/clientContext'
import { useAuthState } from '../../../../hooks'
import { capitalizeEachWord } from '../../../../utils'
import Icon from '../../../atoms/Icon'
import MainNavigationMenu from '../../../molecules/MainNavigationMenu'
import ExportButton from '../../../molecules/ExportButton/ExportButton'
import FastAvatar from '../../../molecules/FastAvatar'

const VIEW_TRANSFORM_X = 105

const useStyles = makeStyles((theme) => ({
  container: ({ fullScreen }) => ({
    position: 'fixed',
    zIndex: 1110,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '1.25rem 2.5rem',
    backgroundColor: fullScreen ? theme.palette.white : theme.palette.concrete
  }),
  content: {
    display: 'flex',
    position: 'relative',
    width: '100%',
    height: '100%',
    overflow: 'hidden'
  },
  paper: ({ fullScreen, containerStyle }) => ({
    height: `calc(100% - ${fullScreen ? '1rem' : '2.5rem'})`,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    position: 'relative',
    ...(fullScreen
      ? {}
      : {
        backgroundColor: theme.palette.common.white,
        border: '2px solid rgba(33, 41, 69, 0.3)',
        borderRadius: '0.5rem'
      }),
    ...containerStyle
  }),
  viewWrapper: {
    height: '100%',
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: '1em',
      height: '1em',
      backgroundColor: 'transparent'
    },
    '&::-webkit-scrollbar-track': {
      borderRadius: '20px',
      backgroundColor: 'transparent'
    },
    '&::-webkit-scrollbar-thumb': {
      visibility: 'hidden',
      borderRadius: '20px',
      backgroundColor: theme.palette.gray.dark,
      border: '3px solid white'
    }
  },
  visibleScroll: {
    '&::-webkit-scrollbar-thumb': {
      visibility: 'visible'
    }
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    fontWeight: '600'
  },
  navigationContainer: {
    position: 'fixed',
    left: '50%',
    bottom: '2.5rem',
    transform: 'translateX(-50%)',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  navigation: {
    width: '15rem',
    color: '#FFFFFF',
    backgroundColor: '#212945',
    borderRadius: '24px',
    padding: '8px 12px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '2.5rem',
    opacity: '0.7',
    '&:hover': {
      opacity: 1
    }
  },
  arrow: {
    cursor: 'pointer',
    margin: '0px 10px'
  },
  leftArrow: {
    marginRight: 'auto'
  },
  rightArrow: {
    marginLeft: 'auto'
  },
  actionButtons: ({ fullScreen }) => ({
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    ...(!fullScreen ? { alignSelf: 'flex-end' } : { right: '50px' })
  }),
  actionButtonsSection: {
    width: '100%',
    display: 'flex',
    marginBottom: '1rem',
    height: '1.5rem'
  },
  divider: {
    margin: '0 1rem'
  },
  headerFamilySection: {
    position: 'absolute',
    left: '50%',
    transform: 'translateX(-50%)',
    display: 'flex',
    alignItems: 'center'
  },
  fullViewOptions: {
    backgroundColor: theme.palette.transparent,
    display: 'flex',
    position: 'fixed',
    top: '0.5rem',
    right: '1rem',
    zIndex: 1200,
    borderTopLeftRadius: '30%',
    borderBottomLeftRadius: '30%'
  },
  labelButton: {
    textTransform: 'none',
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  [theme.breakpoints.down('xs')]: {
    container: {
      width: '100%'
    }
  }
}))

const FULLVIEW_TRANS_DURATION = 300

const initialState = {
  currentViewIndex: 0,
  viewName: undefined
}

function FullView ({
  activeId,
  advisorId,
  carouselNavigationDirection,
  containerStyle,
  fromPosX,
  fromPosY,
  hideClientInfoFromHeader,
  setOpen,
  setScrollingOff,
  onCloseFullView,
  open,
  onFullScreenToggle,
  openFullScreen,
  showAdvisor,
  showNavigationControls,
  showScroll,
  maxWidth,
  viewContainerStyles
}) {
  const setAppContext = useSetAppContext()
  const [fullViewState, setFullViewState] = useState(initialState)
  const { authState: oktaAuthState } = useOktaAuth()
  const authState = useAuthState(oktaAuthState)
  const fullName = authState.idToken?.claims?.name || ''
  const { viewName } = fullViewState
  const setNoteContext = useSetNoteContext()
  const classes = useStyles({ fullScreen: openFullScreen, containerStyle })
  const { isProfileSideNavOpen, isNotesSideNavOpen } =
    useAppContext()
  const addCommentLabelFeature = useFeatureFlag(
    FEATURE_FLAG.CHANGE_ADD_COMMENT_TO_MESSAGE_TEAM_LABEL
  )
  const { active: SHOW_ADD_COMMENT_FEATURE } = useFeatureFlag(FEATURE_FLAG.SHOW_ADD_COMMENT_FEATURE)
  const [showExportReportButton, setShowExportReportButton] = useState(false)

  const currentClient = useCurrentClient()
  const [loadingPerson, person] = useMemo(() => {
    if (showAdvisor) {
      return [false, {
        client: { familyName: fullName }
      }]
    }
    return {
      ...currentClient,
      familyName: currentClient.longName
    }
  }, [showAdvisor, fullName, currentClient])

  const abundanceEngineViewRef = useCallback((node) => {
    if (node !== null && node?.viewProps) {
      const { viewProps, viewDefaultProps } = node

      /* TODO: it should be changed to a configuration
       * at view level, it shouldn't be at component level
       */
      const { showExport: defaultShowExport } =
        Object.values(viewDefaultProps || {}).find((componentDefaultProps) =>
          has(componentDefaultProps, 'showExport')
        ) || {}

      const showExport =
        Boolean(viewProps?.showExport) || Boolean(defaultShowExport)

      setShowExportReportButton(showExport)
      setFullViewState((prevState) => {
        return {
          ...prevState,
          viewName: viewProps?.viewName || ''
        }
      })
    }
  }, [])

  useEffect(() => {
    return function cleanup () {
      setScrollingOff()
    }
  }, [setScrollingOff])

  const onClose = useCallback(() => {
    setOpen(false)
    setTimeout(() => onCloseFullView(), FULLVIEW_TRANS_DURATION)
  }, [onCloseFullView, setOpen])

  const [posX, posY] = useMemo(() => {
    const halfWindowX = window.innerWidth / 2
    const halfWindowY = window.innerHeight / 2
    return [halfWindowX - fromPosX, halfWindowY - fromPosY]
  }, [fromPosX, fromPosY])

  const fullViewSpringStyles = useSpring({
    config: {
      duration: FULLVIEW_TRANS_DURATION
    },
    from: {
      opacity: 1
    },
    to: {
      opacity: open ? 1 : 0,
      left: open ? 0 : posX,
      top: open ? 0 : posY,
      width: open ? '100%' : '20%',
      height: open ? '100%' : '20%'
    }
  })

  const navigationSpringStyles = useSpring({
    to: { opacity: showNavigationControls ? 1 : 0 },
    from: { opacity: 1 },
    leave: { opacity: 0 }
  })

  const toggleProfileSideNav = useCallback(
    () => setAppContext({ isProfileSideNavOpen: !isProfileSideNavOpen }),
    [isProfileSideNavOpen, setAppContext]
  )

  const toggleNotesSideNav = useCallback(
    () => setAppContext({ isNotesSideNavOpen: !isNotesSideNavOpen }),
    [isNotesSideNavOpen, setAppContext]
  )

  const headerSection = useMemo(() => {
    if (person?.familyName) {
      const familyName = capitalizeEachWord(person?.familyName)
      return (
        <div className={classes.header}>
          <Box display='flex' alignItems='center'>
            <Typography variant={TEXT_VARIANTS.h2} noWrap>
              {viewName}
            </Typography>
          </Box>
          {!hideClientInfoFromHeader && (
            <div className={classes.headerFamilySection}>
              <FastAvatar
                avatarUrl={person.avatarUrl}
                abbreviation={person.clientAbbreviation}
                isLoading={loadingPerson}
              />
              <Box ml={1}>
                <Typography variant={TEXT_VARIANTS.h2} noWrap>
                  {familyName}
                </Typography>
              </Box>
            </div>
          )}
          {!openFullScreen && (
            <Box ml='auto'>
              <MainNavigationMenu
                showGearOption={false}
                showSearchInputToggle
                toggleNotesSideNav={toggleNotesSideNav}
                toggleProfileSideNav={toggleProfileSideNav}
              />
            </Box>
          )}
        </div>
      )
    } else {
      return (
        <div className={classes.header}>
          <Box display='flex' alignItems='center'>
            <Typography variant={TEXT_VARIANTS.h2} noWrap>
              {viewName}
            </Typography>
          </Box>
        </div>
      )
    }
  }, [
    viewName,
    person,
    openFullScreen,
    toggleNotesSideNav,
    loadingPerson,
    toggleProfileSideNav,
    hideClientInfoFromHeader,
    classes.header,
    classes.headerFamilySection
  ])

  const onCreateNewThreadHandler = useCallback(
    (e) => {
      if (!activeId) return
      const position = e.target.getBoundingClientRect()
      setNoteContext({
        openNewCommentModal: true,
        modalPosition: {
          top: position.top,
          left: position.left
        },
        viewId: activeId || '',
        triggeredBy: NOTE_TRIGGERED_BY.FULL_VIEW
      })
    },
    [setNoteContext, activeId]
  )

  const actionButtons = useMemo(
    () => (
      <animated.div
        className={classes.actionButtons}
        style={navigationSpringStyles}
      >
        {openFullScreen && (
          <>
            <MainNavigationMenu
              showGearOption={false}
              showSearchInputToggle
              toggleNotesSideNav={toggleNotesSideNav}
              toggleProfileSideNav={toggleProfileSideNav}
            />
            <div className={classes.divider} />
          </>
        )}
        <div className={classes.fullViewOptions}>
          {showExportReportButton && <ExportButton />}
          <IconButton
            aria-label='expand full view'
            aria-haspopup='true'
            onClick={onFullScreenToggle}
            color='inherit'
          >
            <Icon
              customSize='1.2rem'
              name={openFullScreen ? ICON_NAMES.collapse : ICON_NAMES.expand}
            />
          </IconButton>
          <IconButton
            {...(openFullScreen ? { edge: 'end' } : {})}
            aria-label='close full view'
            aria-haspopup='true'
            onClick={onClose}
            color='inherit'
          >
            <Icon name={ICON_NAMES.close} customSize='1.2rem' />
          </IconButton>
        </div>
      </animated.div>
    ),
    [
      onClose,
      openFullScreen,
      onFullScreenToggle,
      navigationSpringStyles,
      toggleProfileSideNav,
      toggleNotesSideNav,
      showExportReportButton,
      classes.divider,
      classes.actionButtons,
      classes.fullViewOptions
    ]
  )

  const wrapViewWithContainer = useCallback(
    (viewComponent) => {
      return (
        <div className={clsx(classes.paper)}>
          {!openFullScreen && actionButtons}
          <div
            className={clsx('av-popup-frame', classes.viewWrapper, {
              [classes.visibleScroll]: showScroll
            })}
          >
            {open && viewComponent}
          </div>
        </div>
      )
    },
    [
      open,
      showScroll,
      actionButtons,
      openFullScreen,
      classes.paper,
      classes.viewWrapper,
      classes.visibleScroll
    ]
  )

  const abundanceEngineViewRendered = useMemo(() => {
    if (!activeId) return null
    return wrapViewWithContainer(
      <Container
        maxWidth={maxWidth}
        style={{
          height: '100%',
          position: 'relative',
          ...viewContainerStyles
        }}
      >
        <Box pt={4} width='100%' height='100%'>
          <AbundanceEngineView
            advisorId={advisorId}
            ref={abundanceEngineViewRef}
            viewPath={activeId}
          />
        </Box>
      </Container>
    )
  }, [
    advisorId,
    activeId,
    abundanceEngineViewRef,
    wrapViewWithContainer,
    maxWidth,
    viewContainerStyles
  ])

  const viewTransitions = useTransition(activeId, null, {
    config: { duration: 400 },
    from: {
      opacity: 0,
      transform: `translateX(${
        carouselNavigationDirection === DIRECTION.forward
          ? VIEW_TRANSFORM_X
          : -VIEW_TRANSFORM_X
      }%)`,
      position: 'absolute'
    },
    enter: {
      opacity: 1,
      transform: 'translateX(0%)'
    },
    leave: {
      opacity: 0,
      transform: `translateX(${
        carouselNavigationDirection === DIRECTION.forward
          ? -VIEW_TRANSFORM_X
          : VIEW_TRANSFORM_X
      }%)`,
      position: 'absolute'
    }
  })

  return (
    <animated.div className={classes.container} style={fullViewSpringStyles}>
      <div className={classes.actionButtonsSection}>
        {headerSection}
        {openFullScreen && actionButtons}
      </div>
      <div className={classes.content}>
        {viewTransitions.map(
          ({ item: viewId, props: viewTransitionStyles, key }) => {
            return (
              <animated.div
                key={key}
                style={{
                  ...viewTransitionStyles,
                  flex: '1 0 100%',
                  width: '100%',
                  height: '100%'
                }}
              >
                {abundanceEngineViewRendered}
              </animated.div>
            )
          }
        )}
      </div>
      {
        SHOW_ADD_COMMENT_FEATURE && (
          <animated.div
            style={navigationSpringStyles}
            className={classes.navigationContainer}
            onClick={onCreateNewThreadHandler}
          >
            <div className={classes.navigation}>
              {addCommentLabelFeature.active ? 'Message Team' : 'Add comment'}
            </div>
          </animated.div>
        )
      }
    </animated.div>
  )
}

FullView.propTypes = {
  activeId: PropTypes.string,
  advisorId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  carouselNavigationDirection: PropTypes.oneOf(Object.values(DIRECTION)),
  containerStyle: PropTypes.object,
  fromPosX: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  fromPosY: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  hideClientInfoFromHeader: PropTypes.bool,
  onCloseFullView: PropTypes.func,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  setScrollingOff: PropTypes.func,
  onFullScreenToggle: PropTypes.func,
  openFullScreen: PropTypes.bool,
  showAdvisor: PropTypes.bool,
  showNavigationControls: PropTypes.bool,
  showScroll: PropTypes.bool,
  maxWidth: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl', false]),
  viewContainerStyles: PropTypes.object
}

FullView.defaultProps = {
  activeId: undefined,
  carouselNavigationDirection: DIRECTION.forward,
  containerStyle: undefined,
  fromPosX: 0,
  fromPosY: 0,
  hideClientInfoFromHeader: false,
  onCloseFullView: noop,
  onFullScreenToggle: noop,
  openFullScreen: false,
  showAdvisor: false,
  showNavigationControls: true,
  setScrollingOff: noop,
  showScroll: false,
  maxWidth: 'xl',
  viewContainerStyles: {}
}

export default FullView
