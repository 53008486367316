import React, { useCallback, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Divider, makeStyles, DialogActions, Box } from '@material-ui/core'
import { isEmpty, noop } from 'lodash'
import { useGroupingContext } from '../GroupingContext'
import { localStorageHelper } from '../../../../utils/localStorageHelper'
import { CancelButton, ConfirmButton } from '../../../molecules/SaveCancelButtons'
import Select from '../../../molecules/Select'
import { modifyByIndex } from '../../../../utils'
import GroupingDateRangeSelector from './GroupingDateRangeSelector'
import CustomizeColumnsToggler from './CustomizeColumnsToggler'
import CustomizeColumnsDialog from './CustomizeColumnsDialog'
import { DIALOG_STORAGE_KEYS as STR_KEYS, getHiddenColumnIds } from './helpers'

const useStyles = makeStyles(() => ({
  dialogContent: {
    display: 'flex',
    flexDirection: 'row'
  },
  contentColumn: {
    flex: 1,
    minWidth: 0
  },
  button: {
    padding: '0.75rem 2rem',
    minWidth: 'auto'
  },
  dialogActions: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '1.25rem 1.5rem'
  }
}))

const CustomizeColumnsDateRangeDialog = ({
  onApply,
  onCancel,
  hideColumnHiding,
  hideDateRangeSelectors
}) => {
  const classes = useStyles()
  const context = useGroupingContext()

  const [dateRanges, setDateRanges] = useState([])

  const {
    configurationKey,
    selectedGrouping,
    columnConfigurations,
    setColumnConfigurations,
    performanceTableProps,
    setGroupingDateRanges,
    tempGroupingDateRanges,
    setPerformanceTableProps,
    setTempGroupingDateRanges,
    defaultGroupingDateRanges,
    isGroupingDateRangePickerOpen,
    setIsGroupingDateRangePicker: setDateRangePicker
  } = context

  const [tempColumnConfigs, setTempColumnConfigs] = useState(
    columnConfigurations.enabled ? [...columnConfigurations.substitutionOptions] : []
  )

  const configKey = configurationKey
    ? `${STR_KEYS.DATE_RANGES_DND_ORDER_TOGGLER}_${configurationKey}`
    : undefined

  useEffect(() => {
    setDateRanges([...tempGroupingDateRanges])
  }, [setDateRanges, tempGroupingDateRanges])

  const onApplyFilters = useCallback(() => {
    setGroupingDateRanges([...dateRanges])
    setTempGroupingDateRanges([...dateRanges])

    if (configKey) {
      localStorageHelper.store(configKey, dateRanges)
    }

    if (!isEmpty(performanceTableProps.columnHiddenProps)) {
      const hiddenColumnIds = getHiddenColumnIds(performanceTableProps)
      performanceTableProps.setHiddenColumns(hiddenColumnIds)

      // stores hidden columns selection
      setPerformanceTableProps((prevState) => ({
        ...prevState,
        hiddenColumns: {
          ...prevState?.hiddenColumns,
          [selectedGrouping?.key]: hiddenColumnIds
        }
      }))
    }

    if (!isEmpty(tempColumnConfigs)) {
      setColumnConfigurations((prevState) => ({
        ...prevState,
        substitutionOptions: [...tempColumnConfigs]
      }))
      setTempColumnConfigs([...tempColumnConfigs])
    }

    setDateRangePicker.off()
    onApply()
  }, [
    onApply,
    dateRanges,
    configKey,
    selectedGrouping?.key,
    setDateRangePicker,
    performanceTableProps,
    setGroupingDateRanges,
    setPerformanceTableProps,
    setTempGroupingDateRanges,
    tempColumnConfigs,
    setColumnConfigurations
  ])

  const onResetFilters = useCallback(() => {
    setTempGroupingDateRanges([...defaultGroupingDateRanges])

    if (columnConfigurations?.enabled) {
      const { substitutionOptions = [] } = columnConfigurations
      const initialSubstitutionOptions = substitutionOptions.map((option) => ({
        ...option,
        lastSelectedValue: option.selectedValue,
        selectedValue: option.defaultValue
      }))
      setTempColumnConfigs(initialSubstitutionOptions)
    }
  }, [
    columnConfigurations,
    defaultGroupingDateRanges,
    setTempGroupingDateRanges
  ])

  const onCancelFilters = useCallback(() => {
    setTempGroupingDateRanges([...tempGroupingDateRanges])
    if (columnConfigurations?.enabled) {
      setTempColumnConfigs([...columnConfigurations.substitutionOptions])
    }
    setDateRangePicker.off()
    onCancel()
  }, [
    onCancel,
    setDateRangePicker,
    columnConfigurations,
    tempGroupingDateRanges,
    setTempGroupingDateRanges
  ])

  const showColumnHiding = !hideColumnHiding && performanceTableProps?.allColumns
  const showDateRangeSelectors = !hideDateRangeSelectors && !isEmpty(dateRanges)
  const showContentDivider = showColumnHiding && showDateRangeSelectors

  const contentDivider = showContentDivider && (
    <Divider orientation='vertical' variant='middle' flexItem />
  )

  const columnDragOrderToggler = showColumnHiding && (
    <div className={classes.contentColumn}>
      <CustomizeColumnsToggler />
    </div>
  )

  const dialogActions = (
    <DialogActions className={classes.dialogActions}>
      <CancelButton
        onClick={onCancelFilters}
        label='Cancel'
        className={classes.button}
      />
      <Box ml={8} display='flex' style={{ gap: '1rem' }}>
        <CancelButton
          label='Reset'
          className={classes.button}
          onClick={onResetFilters}
        />
        <ConfirmButton
          onClick={onApplyFilters}
          disabled={false}
          label='Apply'
          isLoading={false}
          className={classes.button}
        />
      </Box>
    </DialogActions>
  )

  const onChangeColumnConfig = useCallback((selectedConfig, selectedValue) => {
    setTempColumnConfigs((prevState) => {
      const colConfigIndex = prevState.indexOf(selectedConfig)
      if (colConfigIndex === -1) {
        return prevState
      }
      const { defaultValue, ...selectedOption } = prevState[colConfigIndex]
      const lastSelectedValue = selectedOption?.selectedValue || defaultValue
      return modifyByIndex(colConfigIndex, prevState, {
        ...selectedConfig,
        lastSelectedValue,
        selectedValue
      })
    })
  }, [setTempColumnConfigs])

  const customizeColumnOptions = useMemo(() => {
    return tempColumnConfigs?.map((colConfig) => {
      const { options, placeholder, selectedValue, defaultValue } = colConfig
      return (
        <Box key={defaultValue} p='0.5rem 0.5rem 0.5rem 3.9rem'>
          <Select
            placeholder={placeholder}
            variant='outlined'
            options={options}
            value={selectedValue}
            onChange={(value) => {
              onChangeColumnConfig(colConfig, value)
            }}
            fullWidth
            showCheckMarOnSelectedItems
          />
        </Box>
      )
    })
  }, [tempColumnConfigs, onChangeColumnConfig])

  return (
    <CustomizeColumnsDialog
      maxWidth='xs'
      onCancel={onResetFilters}
      onConfirm={onApplyFilters}
      toggler={setDateRangePicker}
      isOpen={isGroupingDateRangePickerOpen}
      showContentDivider={showContentDivider}
      actions={dialogActions}
    >
      <div className={classes.dialogContent}>
        {showDateRangeSelectors && (
          <div className={classes.contentColumn}>
            <GroupingDateRangeSelector
              dateRanges={dateRanges}
              setDateRanges={setDateRanges}
            />
            {customizeColumnOptions}
          </div>
        )}
        {contentDivider}
        {columnDragOrderToggler}
      </div>
    </CustomizeColumnsDialog>
  )
}

CustomizeColumnsDateRangeDialog.propTypes = {
  onApply: PropTypes.func,
  onCancel: PropTypes.func,
  hideColumnHiding: PropTypes.bool,
  hideDateRangeSelectors: PropTypes.bool
}

CustomizeColumnsDateRangeDialog.defaultProps = {
  onApply: noop,
  onCancel: noop,
  hideColumnHiding: PropTypes.bool,
  hideDateRangeSelectors: PropTypes.bool
}

export default CustomizeColumnsDateRangeDialog
