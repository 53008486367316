import { createContext, useContext, useMemo, useState } from 'react'

export const AdvisorViewTabGroupContext = createContext({
  selectedValue: null
})

export const useAdvisorViewTabGroup = () => {
  const tabGroupContext = useContext(AdvisorViewTabGroupContext)

  return tabGroupContext
}

export const useAdvisorViewTabGroupValues = (tabOptions) => {
  const { options, defaultValue } = useMemo(() => {
    const entries = Object.entries(tabOptions)
    const options = entries.map(([value, payload]) => ({
      value,
      payload
    }))

    const defaultValue = options.find((val) => !val?.payload?.disabled)?.value

    return {
      options,
      defaultValue
    }
  }, [tabOptions])
  const [selectedValue, setSelectedValue] = useState(defaultValue)

  return useMemo(() => ({
    selectedValue,
    selectValue: setSelectedValue,
    options
  }), [selectedValue, setSelectedValue, options])
}
