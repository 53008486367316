import React, { useMemo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import ClientAvatar from '../../../atoms/ClientAvatar'
import { useCurrentClientModelInfo } from '../hooks/useClientModelInfo'

const useStyles = makeStyles((theme) => ({
  card: {
    textDecoration: 'none !important',
    overflow: 'clip',
    position: 'relative',
    width: '15rem',
    height: '15rem',
    borderRadius: '12rem',
    fontSize: '1.3rem',
    backgroundColor: theme.palette.gray.lighter,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '1rem',
    outline: `.5px solid ${theme.palette.gray.dark}`,
    transition: 'background-color 0.3s ease-in-out, outline 0.3s ease-in-out, fill 0.3s ease-in-out',
    boxShadow: `1px 2px 10px -9px ${theme.palette.summitBlue}`,
    '&:hover': {
      background: 'white',
      outline: `2.5px solid ${theme.palette.summitBlue}`
    },
    '& svg': {
      transition: 'fill 100ms ease-in-out'
    }
  },
  clientName: {
    position: 'absolute',
    top: '35px',
    fontSize: '11px',
    padding: '2px',
    color: theme.palette.gray.darker
  },
  modelName: {
    position: 'absolute',
    top: '185px',
    fontSize: '11px',
    padding: '2px',
    color: theme.palette.gray.darker
  },
  avatar: {
    marginBottom: '10px',
    '& .MuiAvatar-root': {
      width: '80px',
      height: '80px'
    }
  },
  optionText: {
    padding: '.5rem 0 0'
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '10px',
    '& > p': {
      margin: 0,
      color: theme.palette.gray.darker
    }
  }
}))

function CardBody ({ classes, to, client, model }) {
  return (
    <Link to={to} className={classes.card}>
      <div className={classes.avatar}>
        <ClientAvatar client={client} />
      </div>
      <div className={classes.optionText}>
        Current Client
      </div>
      <div className={classes.clientName} title={client.longName}>
        {client.shortName}
      </div>
      <div className={classes.modelName}>
        {model?.name ?? 'No Model Assigned'}
      </div>
    </Link>
  )
}

CardBody.propTypes = {
  classes: PropTypes.any,
  to: PropTypes.string,
  client: PropTypes.any,
  model: PropTypes.any
}

function CurrentClientOptionCard ({ includeSelectClientLink }) {
  const classes = useStyles()
  const { client, model, loading } = useCurrentClientModelInfo()
  const to = useMemo(() => {
    if (loading) return ''
    return `/rebalancer/start/workflow?clientId=${client.clientId}`
  }, [client?.clientId, loading])

  if (loading && !includeSelectClientLink) {
    return (
      <div className={classes.card} />
    )
  }

  if (loading && includeSelectClientLink) {
    return (
      <div className={classes.container}>
        <div className={classes.card} />
        <p>or</p>
        <Link to='/rebalancer/start/client'>Select Client</Link>
      </div>
    )
  }

  if (!includeSelectClientLink) {
    return (
      <CardBody
        classes={classes}
        to={to}
        client={client}
        model={model}
      />
    )
  }

  if (includeSelectClientLink) {
    return (
      <div className={classes.container}>
        <CardBody
          classes={classes}
          to={to}
          client={client}
          model={model}
        />
        <p>or</p>
        <Link to='/rebalancer/start/client'>Select Client</Link>
      </div>
    )
  }
}

CurrentClientOptionCard.propTypes = {
  includeSelectClientLink: PropTypes.bool
}

export default CurrentClientOptionCard
