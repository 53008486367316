import { useCallback, useMemo, useState } from 'react'
import { useAppContext } from '../../redux/slices/appContext'
import { useSaveMediaMutation } from '../media'
import { uploadDocument } from '../../service'

const DEFAULT_STATE = {
  progress: null,
  uploaded: false,
  hasError: false,
  aborted: false,
  started: false,
  mediaId: null
}

export const useMediaUpload = ({ enabled, mediaInfo, onUpload }) => {
  const { userId } = useAppContext()
  const { mutateAsync: saveMedia } = useSaveMediaMutation()
  const [uploadState, setUploadState] = useState(DEFAULT_STATE)

  const isLoading = useMemo(() => {
    if (uploadState.uploaded) return false
    if (uploadState.aborted) return false

    return uploadState.started && (!!uploadState.progress || !!uploadState.started)
  }, [uploadState])

  const onAbort = useCallback(() => {
    setUploadState((prevState) => ({
      ...prevState,
      aborted: true,
      progress: 0
    }))
  }, [setUploadState])

  const onProgress = useCallback((event) => {
    setUploadState((prevState) => ({
      ...prevState,
      progress: (event.loaded / event.total) * 100
    }))
  }, [setUploadState])

  const autoUpload = useCallback(async (file) => {
    if (!enabled) return
    if (!file?.name) return
    setUploadState((prevState) => ({
      ...prevState,
      started: true
    }))

    // STEP 1: Create the media and get the uploadUrl
    const data = await saveMedia({
      createdBy: userId, // TODO: service should handle this, not client
      fileName: file.name,
      ...mediaInfo
    })

    const {
      mediaId,
      // fileName,
      uploadUrl
      // originalFileName
    } = data

    const xhr = new XMLHttpRequest()
    // STEP 2: Upload the image
    await uploadDocument(
      uploadUrl,
      file,
      onProgress,
      onAbort,
      xhr
    )

    if (onUpload) {
      await onUpload(mediaId)
    }

    setUploadState(DEFAULT_STATE)

    // STEP 3: Confirm the media upload
    // This step doesn't exist right now
  }, [enabled, saveMedia, userId, onAbort, onProgress, mediaInfo, onUpload])

  return {
    upload: autoUpload,
    isUploading: isLoading
  }
}
