import React, { useImperativeHandle, useState } from 'react'
import PropTypes from 'prop-types'
import RoundedModal from '../../../molecules/RoundedModal'
import ErrorBoundary from '../../../molecules/ErrorBoundary'
import SecurityQuestionForm from './SecurityQuestionForm'

const SecurityQuestionDialog = React.forwardRef(function SecurityQuestionDialog ({ authenticator, enrollment }, ref) {
  const [open, setOpen] = useState(false)

  useImperativeHandle(ref, () => ({
    open: () => setOpen(true)
  }), [setOpen])

  return (
    <RoundedModal
      title='Set your security question'
      open={open}
      onClose={() => setOpen(false)}
    >
      <ErrorBoundary name='Change Password Form'>
        <SecurityQuestionForm onClose={() => setOpen(false)} currentEnrollment={enrollment} />
      </ErrorBoundary>
    </RoundedModal>
  )
})

SecurityQuestionDialog.propTypes = {
  authenticator: PropTypes.shape({
    id: PropTypes.string,
    key: PropTypes.string,
    name: PropTypes.string,
    enrollable: PropTypes.bool
  }),
  enrollment: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    created: PropTypes.string,
    profile: PropTypes.shape({
      questionKey: PropTypes.string,
      question: PropTypes.string
    })
  })
}

export default SecurityQuestionDialog
