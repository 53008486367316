import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import OperationalTable, { useOperationalTable } from '../../../OperationalTable'
import { useLevelDates, useTransactionsSearch } from '../../../../../api/coreData'
import { useColumnConfig } from './columnConfig'

const useFilteredLevelDates = (filter, defaultDateFilter) => {
  const levelDatesQuery = useMemo(() => {
    if (!filter) {
      return defaultDateFilter
    }
    return filter
  }, [filter, defaultDateFilter])
  const {
    data: dates,
    isLoading: datesLoading
  } = useLevelDates(levelDatesQuery)

  return {
    dates,
    datesLoading
  }
}

const mapSort = (sorts) => sorts?.map(({ id, desc }) => ({ field: id, dir: desc ? 'desc' : 'asc' }))
const buildQuery = (dates, baseFilter, pageIndex, pageSize, sort) => {
  const baseQuery = {
    includes: {
      transactionCodeTags: true
    },
    filters: {
      ...(baseFilter ?? {})
    },
    skip: pageSize * pageIndex,
    take: pageSize,
    sort: mapSort(sort)
  }

  return baseQuery
}

const useTransactionDetailsQuery = (dates, datesLoading, baseFilter, pageIndex, pageSize, sort) => {
  const query = useMemo(() => datesLoading
    ? null
    : buildQuery(dates, baseFilter, pageIndex, pageSize, sort),
  [dates, datesLoading, baseFilter, pageIndex, pageSize, sort])
  const { data, isLoading, error } = useTransactionsSearch(query)

  return useMemo(() => ({
    data: (isLoading || error) ? [] : data,
    isLoading,
    error
  }), [data, isLoading, error])
}

const TransactionDetails = ({ baseFilter, columnConfig, defaultDateFilter }) => {
  const { dates, datesLoading } = useFilteredLevelDates(null, defaultDateFilter)
  const { columns, defaultSort } = useColumnConfig(columnConfig)
  const {
    defaultPageSize,
    pageIndex,
    pageSize,
    sort,
    searchText,
    onPagingChange,
    onSortingChange,
    onTableModeChange
  } = useOperationalTable(defaultSort)
  const { data, isLoading } = useTransactionDetailsQuery(dates, datesLoading, baseFilter, pageIndex, pageSize, sort)

  return (
    <OperationalTable.Wrapper>
      <OperationalTable.SuperHeader />
      <OperationalTable
        mode='server'
        columns={columns}
        data={data}
        defaultPageSize={defaultPageSize}
        defaultSort={defaultSort}
        itemName='Transactions'
        loading={isLoading}
        total={null}
        searchText={searchText}
        onSortingChange={onSortingChange}
        onPagingChange={onPagingChange}
        onTableModeChange={onTableModeChange}
      />
    </OperationalTable.Wrapper>
  )
}

TransactionDetails.propTypes = {
  baseFilter: PropTypes.object,
  columnConfig: PropTypes.oneOfType([PropTypes.string]),
  defaultDateFilter: PropTypes.object
}

TransactionDetails.defaultProps = {
  defaultDateFilter: { levelType: 'user' }
}

export default TransactionDetails
