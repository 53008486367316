import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import { useWealthOwnerDetails } from '../../ClientDetailsFormContext'
import StyledDialog from '../../../shared/StyledDialog'

const ResetUserDialog = forwardRef(function ResetUserDialog ({ onConfirm, processing }, ref) {
  const { wealthOwner } = useWealthOwnerDetails()

  return (
    <StyledDialog
      ref={ref}
      confirmText='Reset User'
      onConfirm={onConfirm}
      title='Reset User'
      size='sm'
      processing={processing}
    >
      Are you sure you want to reset {wealthOwner.firstName} {wealthOwner.lastName} ({wealthOwner.email})?
    </StyledDialog>
  )
})

ResetUserDialog.propTypes = {
  onConfirm: PropTypes.func,
  processing: PropTypes.bool
}

ResetUserDialog.defaultProps = {
  processing: false
}

export default ResetUserDialog
