import { useMutation, useQuery } from '@tanstack/react-query'
import { getMedia, saveMedia } from '../service'
import { useAppContext } from '../redux/slices/appContext'
import { QUERY_KEYS } from './queryKeys'

export const useMediaUrl = (mediaId, options = {}) => {
  const { userId } = useAppContext()
  return useQuery({
    enabled: options?.enabled ?? true,
    queryKey: [QUERY_KEYS.getMedia, userId, mediaId, options?.publicFallback],
    queryFn: async () => {
      if (!mediaId) return options?.publicFallback ?? null

      try {
        const { data } = await getMedia(mediaId)
        return data?.downloadUrl ?? options?.publicFallback ?? null
      } catch (err) {
        if (options?.publicFallback) {
          return options.publicFallback
        } else {
          throw err
        }
      }
    }
  })
}

export const useSaveMediaMutation = () => {
  return useMutation({
    mutationFn: async (command) => {
      const { data } = await saveMedia(command)

      return data
    }
  })
}
