import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'

const useStyles = makeStyles((theme) => ({
  layoutWrapper: {
    display: 'flex',
    justifyContent: 'center'
  },
  layout: {
    flex: '1 0 auto',
    width: 'calc(100vw - 40px)',
    padding: '.5rem 3rem',
    maxWidth: '1600px',
    margin: '5px 20px'
  }
}))

function RebalancerLayout ({ children }) {
  const classes = useStyles()

  return (
    <div className={classes.layoutWrapper}>
      <div className={classes.layout}>
        {children}
      </div>
    </div>
  )
}

RebalancerLayout.propTypes = {
  children: PropTypes.node
}

export default RebalancerLayout
