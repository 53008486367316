import { IconButton, Tooltip } from '@material-ui/core'
import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { ICON_NAMES } from '../../../../../constants'
import Icon from '../../../../atoms/Icon'
import { useGroupingContext } from '../GroupingContext'
import GroupingCustomizeColumnsDialog, { CUSTOMIZE_COLUMNS_VARIANT } from '../GroupingCustomizeColumnsDialog'
import ExportTableDialog from '../ExportTableDialog'
import { useBoolean } from '../../../../../hooks'

dayjs.extend(utc)

const GroupingOptions = ({
  hideCustomizeColumnsDateRangeSelectors,
  hideCustomizeColumnsColumnHiding,
  hideCustomizeColumnsButton,
  hideExpandCollapseButton,
  hideExportTypePicker,
  hideExportButton
}) => {
  const {
    groupsExpanded,
    setGroupsExpanded,
    setIsGroupingDateRangePicker
  } = useGroupingContext()

  const [exportDialogOpen, toggleExportDialog] = useBoolean()

  const customizeColumnsDialogVariant = useMemo(() => {
    const {
      DATE_RANGE_SELECTORS,
      DND_VISIBILITY_TOGGLER,
      VISIBILITY_TOGGLER_AND_DATE_RANGE_SELECTORS
    } = CUSTOMIZE_COLUMNS_VARIANT
    if (!hideCustomizeColumnsColumnHiding) {
      return DND_VISIBILITY_TOGGLER
    }
    if (
      !hideCustomizeColumnsDateRangeSelectors &&
      !hideCustomizeColumnsColumnHiding
    ) {
      return VISIBILITY_TOGGLER_AND_DATE_RANGE_SELECTORS
    }
    if (!hideCustomizeColumnsDateRangeSelectors) {
      return DATE_RANGE_SELECTORS
    }
    return DATE_RANGE_SELECTORS
  }, [
    hideCustomizeColumnsColumnHiding,
    hideCustomizeColumnsDateRangeSelectors
  ])

  return (
    <>
      {!hideExportButton && (
        <>
          <IconButton onClick={toggleExportDialog.toggle}>
            <Icon
              name={ICON_NAMES.download}
              customSize='1.5rem'
              color='black'
            />
          </IconButton>
          <ExportTableDialog
            isOpen={exportDialogOpen}
            toggleDialog={toggleExportDialog}
            hideExportTypePicker={hideExportTypePicker}
          />
        </>
      )}
      {!hideExpandCollapseButton && (
        <Tooltip title='Expand/Collapse Table'>
          <IconButton onClick={setGroupsExpanded.toggle}>
            <Icon
              name={
                groupsExpanded ? ICON_NAMES.unfoldLess : ICON_NAMES.unfoldMore
              }
              customSize='1.5rem'
              color='black'
            />
          </IconButton>
        </Tooltip>
      )}
      {!hideCustomizeColumnsButton && (
        <>
          <Tooltip title='Customize Columns'>
            <IconButton onClick={setIsGroupingDateRangePicker.toggle}>
              <Icon
                name={ICON_NAMES.viewListConfig}
                customSize='1.5rem'
                color='black'
              />
            </IconButton>
          </Tooltip>
          <GroupingCustomizeColumnsDialog
            variant={customizeColumnsDialogVariant}
          />
        </>
      )}
    </>
  )
}

export const groupingOptionsPropTypes = {
  hideCustomizeColumnsDateRangeSelectors: PropTypes.bool,
  hideCustomizeColumnsColumnHiding: PropTypes.bool,
  hideCustomizeColumnsButton: PropTypes.bool,
  hideExpandCollapseButton: PropTypes.bool,
  hideExportTypePicker: PropTypes.bool,
  hideExportButton: PropTypes.bool
}

export const groupingOptionsDefaultProps = {
  hideCustomizeColumnsDateRangeSelectors: false,
  hideCustomizeColumnsColumnHiding: true,
  hideCustomizeColumnsButton: false,
  hideExpandCollapseButton: false,
  hideExportTypePicker: false,
  hideExportButton: false
}

GroupingOptions.propTypes = groupingOptionsPropTypes
GroupingOptions.defaultProps = groupingOptionsDefaultProps

export default GroupingOptions
