import React, { useCallback, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import Image from '../../../atoms/Image'
import Text from '../../../atoms/Text'
import ViewLink from './tiles/ViewLink'
import SsoTile from './tiles/SsoTile'

const PREVIEW_SIZES = {
  maxHeightPx: 220,
  maxWidthPx: 280
}

const useStyles = makeStyles((theme) => ({
  container: {
    opacity: ({ lowlighted }) => lowlighted ? 0.3 : 1,
    position: 'absolute',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    boxShadow: '0px 10px 10px -5px rgba(0,0,0,0.5)',
    backgroundColor: theme.palette.background.paper,
    maxHeight: '24vh',
    maxWidth: `${PREVIEW_SIZES.maxWidthPx}px`,
    width: `${PREVIEW_SIZES.maxWidthPx}px`,
    height: `${PREVIEW_SIZES.maxHeightPx}px`,
    padding: '1rem',
    borderRadius: '8px',
    border: '2px solid rgba(33, 41, 69, 0.3)',
    cursor: 'pointer',
    willChange: 'width, height',
    zIndex: 1099,
    textDecoration: 'none !important',
    top: ({ position }) => (
      `min(calc(8px + 100% - ${PREVIEW_SIZES.maxHeightPx / 2}px), 
       max(calc(8px + ${PREVIEW_SIZES.maxHeightPx / 2}px), 
       calc(50% - ${position[1]}vh)))`
    ),
    left: ({ position }) => `calc(50% + ${position[0]}vw)`,

    transition: ({ lowlighted }) => lowlighted
      ? 'border 0.1s ease-in-out, opacity 0.5s linear 0.1s'
      : 'border 0.1s ease-in-out, opacity .3s ease-in-out',
    '&:hover': {
      borderColor: 'rgba(33, 41, 69, 0.5)'
    },
    '&:hover $smallBackgroundImage': {
      opacity: ({ text }) => text ? 0 : 1
    },
    '&:hover $mainTitle': {
      opacity: 0
    },
    '&:hover $hoverTitleContainer': {
      opacity: 1,
      textDecoration: 'none'
    },
    '&:hover $hoverChildContainer': {
      opacity: 1
    }
  },
  smallChildContainer: {
    position: 'fixed',
    width: '100%',
    height: '100%',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    overflow: 'hidden'
  },
  mainTitle: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    borderRadius: '24px',
    color: theme.palette.primary.contrastText,
    padding: '12px 16px 10px',
    fontSize: '16px',
    fontWeight: '400',
    zIndex: '100',
    textAlign: 'center',
    backgroundColor: theme.palette.primary.main,
    transition: 'opacity 0.3s ease-in-out'
  },
  hoverTitleContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    opacity: 0,
    transition: 'opacity 0.3s ease-in-out'
  },
  hoverChildContainer: {
    width: '100%',
    height: '100%',
    overflowX: 'hidden',
    padding: '0.5rem',
    position: 'relative',
    transition: 'opacity 0.3s ease-in-out',
    opacity: 0,
    '&::-webkit-scrollbar': {
      width: '1em',
      height: '1em',
      backgroundColor: 'transparent'
    },
    '&::-webkit-scrollbar-track': {
      borderRadius: '20px',
      backgroundColor: 'transparent'
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '20px',
      backgroundColor: theme.palette.primary.dark,
      border: '3px solid white'
    }
  },
  textContainer: {
    display: 'flex',
    flex: '1 1 0%',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    fontWeight: 'bold',
    textAlign: 'center'
  },
  smallBackgroundImage: {
    inset: '0',
    margin: '6%',
    opacity: 0.3,
    position: 'absolute',
    justifyContent: 'center',
    transition: 'opacity 0.3s ease-in-out',
    '&:hover': {
      opacity: ({ text }) => text ? 0 : 1
    }
  },
  [theme.breakpoints.down('xs')]: {
    container: {
      position: 'relative',
      maxWidth: '100%',
      width: '100%',
      transform: 'none',
      left: 'inherit !important',
      top: 'inherit !important',
      height: '12rem'
    },
    smallBackgroundImage: {
      display: 'flex'
    },
    smallChildContainer: {
      position: 'absolute'
    }
  },
  primaryText: {
    color: theme.palette.primary.main
  }
}))

const TileContent = ({
  classes,
  smallTitle,
  smallImageSrc,
  title,
  text
}) => {
  return (
    <>
      {smallTitle && (
        <div className={classes.mainTitle}>
          {smallTitle}
        </div>
      )}
      <div className={classes.smallChildContainer}>
        {smallImageSrc ? (
          <div className={classes.smallBackgroundImage}>
            <Image
              isPublicResource={smallImageSrc?.startsWith('https')}
              src={`${smallImageSrc}`}
              height='100%'
              width='100%'
              extraStyles={{ maxHeight: '100%', maxWidth: '100%', objectFit: 'contain' }}
            />
          </div>
        ) : null}
      </div>
      {title && (
        <div className={classes.hoverTitleContainer}>
          <Text
            className={classes.primaryText}
            text={title}
            customFontSize='13px'
            customFontWeight='600'
          />
        </div>
      )}
      <div className={classes.hoverChildContainer}>
        {text ? (
          <div className={classes.textContainer}>
            <Text
              text={text}
              customFontSize='13px'
              customFontWeight='bold'
            />
          </div>
        ) : null}
      </div>
    </>
  )
}

TileContent.propTypes = {
  classes: PropTypes.object.isRequired,
  smallTitle: PropTypes.string,
  smallImageSrc: PropTypes.string,
  theme: PropTypes.shape({}),
  title: PropTypes.string,
  text: PropTypes.string
}

const DashboardTile = ({
  type,
  to,
  title,
  smallImageSrc,
  smallTitle,
  text,
  view,
  position,
  onHoverChange,
  lowlighted,
  ...restProps
}) => {
  const classes = useStyles({ position, lowlighted, text })

  const linkRef = useRef(null)
  const onMouse = useCallback((e) => {
    onHoverChange(view, e.type === 'mouseenter')
  }, [view, onHoverChange])

  useEffect(() => {
    const node = linkRef.current
    node?.addEventListener('mouseenter', onMouse)
    node?.addEventListener('mouseleave', onMouse)
    return () => {
      node?.removeEventListener('mouseenter', onMouse)
      node?.removeEventListener('mouseleave', onMouse)
      onMouse({ type: 'mouseleave' })
    }
  }, [onMouse, linkRef])

  if (type === 'link') {
    return (
      <ViewLink to={to} ref={linkRef} className={classes.container}>
        <TileContent
          classes={classes}
          text={text}
          title={title}
          smallImageSrc={smallImageSrc}
          smallTitle={smallTitle}
        />
      </ViewLink>
    )
  }

  if (type === 'sso') {
    return (
      <SsoTile
        ref={linkRef} className={classes.container} providerId={restProps.providerId}
        sameTab={restProps.sameTab}
      >
        <TileContent
          classes={classes}
          text={text}
          title={title}
          smallImageSrc={smallImageSrc}
          smallTitle={smallTitle}
        />
      </SsoTile>
    )
  }

  return (
    <ViewLink to={to} ref={linkRef} className={classes.container}>
      <TileContent
        classes={classes}
        text={text}
        title={title}
        smallImageSrc={smallImageSrc}
        smallTitle={smallTitle}
      />
    </ViewLink>
  )
}

DashboardTile.propTypes = {
  type: PropTypes.oneOf(['link', 'sso']),
  to: PropTypes.string,
  title: PropTypes.string,
  smallImageSrc: PropTypes.string,
  smallTitle: PropTypes.string,
  text: PropTypes.string,
  view: PropTypes.string,
  position: PropTypes.array,
  onHoverChange: PropTypes.func,
  lowlighted: PropTypes.bool
}

export default DashboardTile
