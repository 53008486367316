import React, { useCallback, useImperativeHandle, useState } from 'react'
import PropTypes from 'prop-types'
import RoundedModal from '../../../../../molecules/RoundedModal'
import RenameGroupForm from './RenameGroupForm'

const RenameGroupDialog = React.forwardRef(function RenameGroupDialog ({ onComplete, client }, ref) {
  const [dialogState, setDialogState] = useState({ open: false, processing: false })
  const close = useCallback(() => {
    // We keep the parent here because it says "undefined" on the modal otherwise
    setDialogState((p) => ({ ...p, open: false, processing: false }))
  }, [setDialogState])

  const complete = useCallback(async (result) => {
    await onComplete({
      ...result,
      groupId: dialogState.group.groupId,
      groupTypeId: dialogState.group.groupTypeId,
      clientId: client.clientId
    })
    close()
  }, [close, dialogState, client, onComplete])

  useImperativeHandle(ref, () => ({
    open: (group) => setDialogState({ open: true, processing: false, group })
  }), [setDialogState])

  return (
    <RoundedModal
      title={`Rename ${dialogState?.group?.longName}`}
      open={dialogState.open}
      onClose={close}
      size='xmedium'
      position='top'
      disablePortal={false}
    >
      <RenameGroupForm onComplete={complete} onClose={close} group={dialogState?.group} groupType={dialogState?.groupType} />
    </RoundedModal>
  )
})

RenameGroupDialog.propTypes = {
  onComplete: PropTypes.func,
  client: PropTypes.object
}

export default RenameGroupDialog
