import React from 'react'
import { makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import { useCurrentClient } from '../../../api/clients'
import Text from '../../atoms/Text'
import { getUserGreeting } from '../../../utils'
import Skeleton from '../../atoms/Skeleton'
import { SKELETON_VARIANTS } from '../../../constants'
import FastAvatar from '../../molecules/FastAvatar'

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '2rem',
    paddingBottom: '2rem'
  },
  header: {
    color: '#212945',
    display: 'flex',
    flexDirection: 'column'
  },
  title: {
    fontSize: '2.25rem',
    whiteSpace: 'nowrap',
    fontWeight: 'bold'
  },
  caption: {
    fontSize: '1rem'
  }
}))

const ClientWelcome = ({ userGreeting }) => {
  const classes = useStyles()
  const { data: client, isLoading } = useCurrentClient()

  return (
    <div className={classes.container}>
      <FastAvatar
        isLoading={isLoading}
        avatarUrl={client?.avatarUrl}
        size='xl'
        abbreviation={client?.clientAbbreviation}
      />
      <div className={classes.header}>
        <Text
          className={classes.caption}
        >{userGreeting || `${getUserGreeting()},`}
        </Text>
        {isLoading ? (
          <Skeleton
            height='3.5rem'
            width='100%'
            variant={SKELETON_VARIANTS.text}
          />
        ) : (
          <Text className={classes.title}>{client?.shortName}</Text>
        )}
      </div>
    </div>
  )
}

ClientWelcome.propTypes = {
  userGreeting: PropTypes.string
}

export default ClientWelcome
