import React, { useMemo } from 'react'
import { Link, useHistory } from 'react-router-dom'
import FadeIn from '../../../../molecules/Transitions/FadeIn'
import OperationalTable from '../../../../organisms/OperationalTable'
import { ADMIN_ROUTES } from '../../../../../constants'
import { useManagerSearch } from '../../../../../api/accounts'

const cellTemplates = {
  link: (accessor) => ({ row }) => (
    <Link
      onClick={(event) => event.stopPropagation()}
      to={`${ADMIN_ROUTES.MANAGER_DETAILS}/${row.original.managerId}`}
    >
      {accessor(row.original) || 'Link'}
    </Link>
  )
}

export const defaultColumnConfig = {
  columns: [
    { Header: 'Id', accessor: 'managerId', type: 'number', Cell: cellTemplates.link(row => row.managerId) },
    { Header: 'Name', accessor: 'name', type: 'string', Cell: cellTemplates.link(row => row.name) },
    { Header: 'Symbol', accessor: 'symbol', type: 'string' }
  ],
  defaultSort: [{ id: 'managerId', desc: false }]
}

function ManagersListTab () {
  const query = useMemo(() => {
    return { take: 200 }
  }, [])
  const history = useHistory()
  const { data, isLoading } = useManagerSearch(query)

  return (
    <FadeIn style={{ marginTop: '20px' }}>
      <OperationalTable
        variant='v2'
        itemName='Managers'
        data={data?.data || []}
        loading={isLoading}
        total={data?.data?.length}
        columns={defaultColumnConfig.columns}
        defaultSort={defaultColumnConfig.defaultSort}
        onRowClick={(row) => history.push(`${ADMIN_ROUTES.MANAGER_DETAILS}/${row.original.managerId}`)}
      />
    </FadeIn>
  )
}

ManagersListTab.propTypes = {
}

ManagersListTab.defaultProps = {
}

export default ManagersListTab
