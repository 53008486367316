import React, { useCallback, useState } from 'react'
import { Box, Checkbox, Modal } from '@material-ui/core'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import RoundedButton from '../../../atoms/RoundedButton'
import Text from '../../../atoms/Text'
import { BUTTON_VARIANT, ICON_NAMES, TEXT_VARIANTS } from '../../../../constants'
import { useDynamicPlanning } from '../DynamicPlanningContext'
import theme from '../../../../theme'
import Icon from '../../../atoms/Icon'
import { postNamedCommand } from '../../../../service'
import TextInput from '../../../molecules/TextInput'
import BouncingDotsLoader from '../../../atoms/BouncingDotsLoader'

const useStyles = makeStyles(() => ({
  modal: {},
  container: {
    padding: '2.5rem 2rem 2.5rem 2.5rem',
    margin: 0,
    borderRadius: '12px',
    position: 'absolute',
    width: '600px',
    maxWidth: '100%',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    outline: 'none'
  },
  header: {
    marginBottom: '2.5rem',
    lineHeight: '1.4',
    width: '100%',
    display: 'flex',
    alignItems: 'start'
  },
  subTitle: {
    marginTop: '0.5rem'
  },
  content: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    gap: '2rem',
    margin: '0rem 0.5rem 1rem 0rem'
  },
  buttonInner: {
    display: 'flex',
    flexDirection: 'column',
    margin: '1rem',
    alignItems: 'center',
    width: '100px'
  },
  actionButton: {
    maxWidth: '200px',
    border: '2px solid #21294533',
    '&:hover': {
      background: '#FFFFFF !important',
      border: '2px solid #212945'
    }
  },
  actionIcon: {
    marginBottom: '1rem'
  },
  headerTitle: {
    fontSize: '1.5rem',
    lineHeight: '1.4'
  },
  baseText: {
    fontSize: '1rem'
  }
}))

const SaveSandboxModal = ({ isOpen, onClose }) => {
  const classes = useStyles()
  const {
    linkedPlan,
    currentPlan,
    refetchPlans,
    setCurrentPlanId,
    postUpdatePlan,
    clientId,
    planStatusMap
  } = useDynamicPlanning()
  const [currentStep, setCurrentStep] = useState(1)
  const [planName, setPlanName] = useState(currentPlan.shortName)
  const [makePlanActive, setMakePlanActive] = useState(false)

  const onSave = useCallback(async (updateOriginal) => {
    setCurrentStep(3)
    let newPlanId = null
    if (updateOriginal) {
      newPlanId = linkedPlan?.planId ?? currentPlan?.planId
      await postNamedCommand('planning', 'updatePlanFromSandbox', { sandboxPlanId: currentPlan.planId })
    } else {
      const planData = await postUpdatePlan({
        ...currentPlan,
        shortName: planName,
        longName: planName,
        planStatusId: planStatusMap.ALTERNATE.planStatusId
      }, false)

      if (makePlanActive) {
        await postNamedCommand('planning', 'markPlanAsActive', {
          planId: planData.planId,
          levelId: clientId,
          levelTypeId: 201
        })
      }
      newPlanId = planData.planId
    }
    await refetchPlans()
    setCurrentPlanId(newPlanId)
    onClose()
  }, [setCurrentPlanId, linkedPlan?.planId, currentPlan, refetchPlans, onClose, postUpdatePlan, planName, planStatusMap.ALTERNATE.planStatusId, makePlanActive, clientId])

  return (
    <Modal
      onClose={onClose}
      open={isOpen}
      aria-labelledby='modal-title'
      aria-describedby='modal-description'
    >
      <div className={classes.container}>
        <div className={classes.header}>
          <div style={{ flexGrow: 1 }}>
            <Text variant={TEXT_VARIANTS.h2} className={classes.headerTitle}>
              {linkedPlan.shortName} - Commit Edits
            </Text>
            <div className={classes.subTitle}>
              <Text variant={TEXT_VARIANTS.subtitle} className={classes.baseText}>
                You can either create a new plan from these edits or update (overwrite) your existing plan
              </Text>
            </div>
          </div>
          <button
            type='button'
            onClick={onClose}
            style={{ cursor: 'pointer' }}
          >
            <Icon
              color={theme.palette.summitBlue}
              name={ICON_NAMES.close}
              customSize='1.5rem'
              variant='button'
            />
          </button>
        </div>
        <div className={classes.content}>
          {currentStep === 1 && (
            <>
              <RoundedButton
                className={classes.actionButton}
                variant={BUTTON_VARIANT.outlined}
                secondary
                onClick={() => setCurrentStep(2)}
              >
                <div className={classes.buttonInner}>
                  <Icon
                    color={theme.palette.summitBlue}
                    name={ICON_NAMES.listAlt}
                    customSize='2rem'
                    margin='0 0 0.5rem 0'
                    variant='button'
                  />
                  <Text variant={TEXT_VARIANTS.body} className={classes.baseText}>
                    Create new plan
                  </Text>
                </div>
              </RoundedButton>
              <RoundedButton
                className={classes.actionButton}
                variant={BUTTON_VARIANT.outlined}
                secondary
                onClick={() => onSave(true)}
              >
                <div className={classes.buttonInner}>
                  <Icon
                    color={theme.palette.summitBlue}
                    name={ICON_NAMES.update}
                    margin='0 0 0.5rem 0'
                    customSize='2rem'
                    variant='button'
                  />
                  <Text variant={TEXT_VARIANTS.body} className={classes.baseText}>
                    Update original plan
                  </Text>
                </div>
              </RoundedButton>
            </>)}
          {currentStep === 2 && (
            <div>
              <Text text='Update Plan Name' variant='h3' />
              <TextInput
                fullWidth
                autoFocus
                type='input'
                placeholder='Enter in plan name'
                value={planName}
                InputProps={{ disableUnderline: true }}
                onChange={(e) => setPlanName(e.target.value)}
                error={false}
                style={{ marginTop: '.5rem' }}
              />
              <Checkbox
                onChange={(e) => setMakePlanActive(e.target.checked)}
                checked={makePlanActive}
                id='makePlanActive'
              />
              <label htmlFor='makePlanActive'>Make this new plan the active plan</label>
              <RoundedButton
                variant={BUTTON_VARIANT.outlined}
                primary
                onClick={() => onSave(false)}
              >
                Save edits as new plan
              </RoundedButton>
            </div>
          )}
          {currentStep === 3 && (
            <Box textAlign='center' margin='0 auto'>
              <BouncingDotsLoader />
              <Text text='Saving' variant={TEXT_VARIANTS.body1} />
            </Box>
          )}
        </div>
      </div>
    </Modal>
  )
}

SaveSandboxModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func
}

export default SaveSandboxModal
