import { useEffect, useRef, useState } from 'react'
import useDebouncedCallback from './useDebouncedCallback'

export default function useDebouncedValue (value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value)
  const onSetValueDebounced = useRef(
    useDebouncedCallback(setDebouncedValue, delay)
  )

  useEffect(() => {
    onSetValueDebounced.current(value)
  }, [value])

  return debouncedValue
}
