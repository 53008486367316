import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import PropTypes from 'prop-types'

const useStyles = makeStyles(() => ({
  advisorViewLayoutPrimaryContent: {
    flex: '1 0',
    padding: '1.75rem',
    overflowX: 'auto'
  }
}))

const PrimaryContent = ({ children, className, style }) => {
  const classes = useStyles()
  return (
    <div className={clsx(classes.advisorViewLayoutPrimaryContent, className)} style={style}>
      {children}
    </div>
  )
}

PrimaryContent.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  style: PropTypes.object
}

export default PrimaryContent
