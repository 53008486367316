import { useMemo } from 'react'
import { useGetBatchRun, useGetBatchRuns } from '../../../../../api/reports'
import { cellTemplates, mapSort } from '../hooks'
import BatchRunActions from './BatchRunActions'

export const batchStatusMap = new Map([
  [1, { label: 'In Progress', class: 'inProgress' }],
  [2, { label: 'In Progress', class: 'inProgress' }],
  [3, { label: 'Completed', class: 'success' }],
  [4, { label: 'Completed with Error', class: 'warning' }],
  [5, { label: 'Error', class: 'warning' }],
  [6, { label: 'Shared', class: 'shared' }]
])

export const useBatchRunsColumnsConfig = ({
  classes = {},
  onSetDetail,
  onSetPostToClientVault
}) => {
  return useMemo(() => ({
    columns: [
      { Header: 'Run Date', accessor: 'createdDate', id: 'createdDate', Cell: cellTemplates.date, disableSortBy: false },
      { Header: 'Batch Name', accessor: 'title', id: 'title', disableSortBy: false },
      { Header: 'As of Date', accessor: 'asOfDate', id: 'asOfDate', Cell: cellTemplates.date, disableSortBy: false },
      { Header: 'Template', accessor: 'templateName', id: 'templateName', disableSortBy: true },
      {
        Header: 'Runtime Configuration',
        accessor: 'runtimeConfigurationName',
        id: 'runtimeConfigurationName',
        disableSortBy: true
      },
      {
        Header: 'Run by',
        accessor: 'createdByLastName',
        id: 'createdByLastName',
        Cell: cellTemplates.createdBy,
        disableSortBy: false
      },
      {
        Header: 'Status',
        accessor: 'statusDescription',
        id: 'status',
        Cell: cellTemplates.status({ classes, statusMap: batchStatusMap })
      },
      {
        Header: 'Actions',
        accessor: 'reportRunId',
        id: 'reportRunId',
        Cell: ({ row }) => BatchRunActions({ row, onSetDetail, onSetPostToClientVault }),
        disableSortBy: true
      }
    ],
    defaultSort: [
      { id: 'createdDate', desc: true }
    ],
    defaultPageSize: 100
  }), [onSetDetail, classes, onSetPostToClientVault])
}

export const useListBatchRunsData = ({
  pageIndex,
  pageSize,
  sort,
  filterToUser
}) => {
  const formattedSort = mapSort(sort)

  const query = useMemo(() => {
    return {
      offset: pageIndex * pageSize,
      limit: pageSize,
      additionalColumns: 'asOfDate',
      filterToUser,
      ...formattedSort
    }
  }, [filterToUser, pageSize, pageIndex, formattedSort])

  const {
    data,
    isLoading,
    isPreviousData,
    isFetching,
    error,
    refetch
  } = useGetBatchRuns(query)

  return {
    data: isLoading ? [] : data?.data ?? [],
    loading: isLoading || isPreviousData || isFetching,
    error,
    refetch
  }
}

export const useGetBatchRunData = ({
  batchRunId,
  pageIndex,
  pageSize,
  sort,
  includeReportRuns,
  additionalColumns
}) => {
  const formattedSort = mapSort(sort)

  const query = useMemo(() => {
    return {
      offset: pageIndex * pageSize,
      limit: pageSize,
      ...formattedSort,
      ...(includeReportRuns && { includeReportRuns }),
      ...(additionalColumns && { additionalColumns })
    }
  }, [pageIndex, pageSize, formattedSort, includeReportRuns, additionalColumns])

  const {
    data,
    isLoading,
    isPreviousData,
    isFetching,
    error,
    refetch
  } = useGetBatchRun(batchRunId, query)

  return {
    data: isLoading ? [] : data?.data ?? [],
    loading: isLoading || isPreviousData || isFetching,
    error,
    refetch
  }
}
