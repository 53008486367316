import React, { useCallback, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import SydButton from '../../../../../../commonDesign/Button'
import {
  useFeeSchedule,
  useRemoveClientOverrideMutation
} from '../../../../../../../api/billing'
import { useDialogStyles } from '../../common'
import FeeScheduleTiers from '../../FeeScheduleTiers'
import Loading from '../../../../../../molecules/Loading'

const useSubmitter = (form, onComplete, client) => {
  const { handleSubmit } = form
  const [processing, setProcessing] = useState(false)
  const [error, setError] = useState(null)
  const { mutateAsync: removeOverride } = useRemoveClientOverrideMutation()
  const onSubmit = useCallback(async (formData) => {
    const command = {
      clientId: client.clientId,
      feeLevelOverrideId: +formData.feeLevelOverrideId
    }

    try {
      setError(null)
      setProcessing(true)
      const result = await removeOverride(command)
      if (result?.statusCode === 500) {
        throw new Error('Failed to remove fee schedule')
      }
      onComplete(result)
    } catch (err) {
      setError(err?.toString())
    } finally {
      setProcessing(false)
    }
  }, [removeOverride, setProcessing, onComplete, client, setError])

  const submitter = useCallback(async (e) => {
    const onValid = async (form) => {
      await onSubmit(form)
    }
    const onInvalid = (errors) => {
      console.error(errors)
    }

    const handler = handleSubmit(onValid, onInvalid)
    await handler(e)
  }, [handleSubmit, onSubmit])

  return {
    submitter,
    processing,
    error
  }
}

function RemoveOverrideForm ({ client, override, name, onCancel, onComplete }) {
  const classes = useDialogStyles()
  const form = useForm({
    mode: 'onChange',
    defaultValues: {
      clientId: client.clientId,
      feeLevelOverrideId: override.feeLevelOverrideId
    }
  })

  const { data: feeSchedules, isLoading } = useFeeSchedule()
  const { submitter, processing, error } = useSubmitter(form, onComplete, client)
  const selected = useMemo(() => {
    return feeSchedules?.find(x => x.feeScheduleId === override.feeScheduleId)
  }, [feeSchedules, override])

  if (isLoading) {
    return (
      <Loading />
    )
  }

  return (
    <>
      <div className={classes.form}>
        <div className={classes.confirm}>
          <div>Are you sure you want to remove this override?</div>
          <div><strong>{name}</strong> &rarr; <strong>{selected.longName}</strong></div>
          <div><FeeScheduleTiers feeSchedule={selected} /></div>
        </div>
      </div>
      <div className={classes.actions}>
        {error ? (<div className={classes.error}>{error}</div>) : null}
        <SydButton disabled={processing} variant='ghost' size='md' onClick={onCancel}>Cancel</SydButton>
        <SydButton
          variant='primary'
          size='md'
          onClick={submitter}
          processing={processing}
        >
          Remove
        </SydButton>
      </div>
    </>
  )
}

RemoveOverrideForm.propTypes = {
  name: PropTypes.node,
  client: PropTypes.shape({
    clientId: PropTypes.number
  }),
  override: PropTypes.shape({
    feeLevelOverrideId: PropTypes.number,
    feeScheduleId: PropTypes.number
  }),
  onComplete: PropTypes.func,
  onCancel: PropTypes.func
}

export default RemoveOverrideForm
