import { Box } from '@material-ui/core'
import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import SydModal from '../../../../commonDesign/SydModal'
import SydButton from '../../../../commonDesign/Button'
import { createBillingRunExports, updateBillingRun } from '../../../../../service'
import Alert from '../../../../atoms/Alert'
import { RUN_STATUS } from './helpers'

const MarkAsFinalConfirmModal = ({
  onComplete,
  onClose,
  billingRun
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)

  const onSubmitMarkAsFinalStatus = useCallback(
    async ({ billingRunId }) => {
      try {
        setIsLoading(true)
        await updateBillingRun(billingRunId, { status: RUN_STATUS.MARK_AS_FINAL })
        await createBillingRunExports({ billingRunId })
        await onComplete()
      } catch (err) {
        setError('Error marking billing run id as final.  Try again.')
        console.error(err)
      } finally {
        setIsLoading(false)
      }
    },
    [onComplete])

  return (
    <SydModal
      open={!!billingRun}
      title={`Mark Billing Run Id ${billingRun.billingRunId} as Final?`}
      subtitle='This will create/recreate all exports & add revenue'
      onClose={onClose}
    >
      {error && <Alert severity='error'>{error}</Alert>}
      <Box display='flex' justifyContent='space-between'>
        <SydButton variant='outline' disabled={isLoading} onClick={onClose}>Cancel</SydButton>
        <SydButton variant='primary' disabled={isLoading} onClick={() => onSubmitMarkAsFinalStatus(billingRun)}>
          {isLoading ? 'Updating' : `Mark Billing Run Id ${billingRun.billingRunId} as Final`}
        </SydButton>
      </Box>
    </SydModal>
  )
}

MarkAsFinalConfirmModal.propTypes = {
  onComplete: PropTypes.func,
  onClose: PropTypes.func,
  billingRun: PropTypes.objectOf(PropTypes.shape({
    billingRunId: PropTypes.number
  }))
}

export default MarkAsFinalConfirmModal
