import React, { forwardRef, useCallback, useImperativeHandle, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import { useSearchAssignedPeople } from '../../../../../api/people'
import StyledDialog from '../../shared/StyledDialog'
import { useClientDetails } from '../ClientDetailsFormContext'
import AssignExperienceForm from './AssignExperienceForm'
import { useRoleOptions } from './useRoleOptions'

const useStyles = makeStyles((theme) => ({
  assignmentForm: {
  }
}))

const mapPerson = (data) => data?.data[0] ?? null

/**
 * Searches for people related to the current person in state
 * @param current
 * @param client
 * @return {UseQueryResult<*, unknown>}
 */
const usePeople = (current, client) => {
  const query = useMemo(() => ({
    filters: {
      assignedToClientIds: [client.clientId],
      userId: { op: 'exists' },
      personId: current ? current.personId : null
    },
    includes: {
      users: true,
      experiences: true
    },
    take: 1
  }), [client, current])

  return useSearchAssignedPeople(query, {
    enabled: !!current,
    mapper: mapPerson
  })
}

/**
 * Handles passing the form data into the onConfirm handler
 * @param onConfirm
 * @return {{formRef: React.MutableRefObject<undefined>, submitting: boolean, submitHandler: (function({invite: *}): function(*): Promise<void>)}}
 */
const useSubmitter = (onConfirm) => {
  const formRef = useRef()
  const [submitting, setSubmitting] = useState(false)
  const submitHandler = useCallback(async (e) => {
    const onValid = async (form) => {
      setSubmitting(true)
      try {
        await onConfirm({
          userId: form.userId,
          roleId: form.roleId
        })
      } finally {
        setSubmitting(false)
      }
    }
    const onInvalid = (errors) => {
      console.error(errors)
    }

    const handler = formRef.current.handleSubmit(onValid, onInvalid)
    await handler(e)
  }, [onConfirm, formRef, setSubmitting])

  return {
    submitting,
    submitHandler,
    formRef
  }
}

// Sorry that the form is so complicated. The place where the submit buttons are doesn't work well with the form
const AssignExperienceDialog = forwardRef(function CreateUserDialog ({ onConfirm }, ref) {
  const classes = useStyles()
  const { client } = useClientDetails()
  const [current, setCurrent] = useState(null)

  const { data, isLoading } = usePeople(current, client)
  const { data: roles, isLoading: rolesLoading } = useRoleOptions()
  const { formRef, submitHandler, submitting } = useSubmitter(onConfirm)

  const innerRef = useRef()
  useImperativeHandle(ref, () => {
    return {
      open: (state) => {
        setCurrent(state)
        innerRef.current?.open()
      },
      close: () => {
        setCurrent(null)
        innerRef.current?.close()
      }
    }
  }, [innerRef, setCurrent])

  return (
    <StyledDialog
      ref={innerRef}
      confirmText='Assign'
      onConfirm={submitHandler}
      title='Assign Experience'
      processing={submitting}
      disabled={isLoading}
    >
      <div className={classes.assignmentForm}>
        {(isLoading || rolesLoading) ? (
          <div>Please Wait</div>
        ) : (
          <AssignExperienceForm
            ref={formRef}
            person={data}
            roleOptions={roles}
          />
        )}
      </div>
    </StyledDialog>
  )
})

AssignExperienceDialog.propTypes = {
  onConfirm: PropTypes.func
}

export default AssignExperienceDialog
