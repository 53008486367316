import React from 'react'
import PropTypes from 'prop-types'
import {
  Container,
  Divider,
  makeStyles
} from '@material-ui/core'
import isEmpty from 'lodash/isEmpty'

import Text from '../../../atoms/Text'
import Skeleton from '../../../atoms/Skeleton'

import { SKELETON_VARIANTS, TEXT_VARIANTS } from '../../../../constants'

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '1.5rem 2.1rem',
    height: '100%',
    [theme.breakpoints.down('xs')]: {
      padding: '1.75rem'
    }
  },
  emptyContainer: {
    padding: '24px',
    borderRadius: '6px',
    border: '2px solid #EEF0F8',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  permissionGroup: {
    borderRadius: '6px',
    border: '2px solid #EEF0F8',
    padding: '24px',
    marginBottom: '20px'
  },
  permission: {
    margin: '12px 0px'
  },
  permissionTitle: {
    color: '#141929',
    fontWeight: 600
  },
  title: {
    marginBottom: '12px'
  }
}))

function PermissionGroupsDetails ({ permissionGroups, loading, isAdmin }) {
  const classes = useStyles()
  if (loading) {
    return (
      <Container maxWidth={false} className={classes.container}>
        <Skeleton
          width='100%'
          height='200px'
          variant={SKELETON_VARIANTS.rect}
        />
      </Container>
    )
  }

  if (isEmpty(permissionGroups)) {
    return (
      <Container maxWidth={false} className={classes.container}>
        <div className={classes.emptyContainer}>
          <Text text='No Permission Groups Assigned' variant={TEXT_VARIANTS.h3} className={classes.title} />
          <Text>At least one permission group is <strong>required</strong> to save</Text>
        </div>
      </Container>
    )
  }

  if (isAdmin) {
    return (
      <Container maxWidth={false} className={classes.container}>
        <div className={classes.permissionGroup}>
          <Text variant={TEXT_VARIANTS.h3} className={classes.title}>Default Administrator Advisor</Text>
          <Text text='Advisor has full access to the app' />
          {!isEmpty(permissionGroups) && (
            <>
              <Divider />
              {permissionGroups.map(permission => (
                <div key={permission.permissionId} className={classes.permission}>
                  <Text className={classes.permissionTitle}>{permission.name}</Text>
                  <Text text={permission.description} />
                </div>
              ))}
            </>
          )}
        </div>
      </Container>
    )
  }

  return (
    <Container maxWidth={false} className={classes.container}>
      {permissionGroups.map(permissionGroup => (
        <div key={permissionGroup.permissionGroupId} className={classes.permissionGroup}>
          <Text variant={TEXT_VARIANTS.h3} className={classes.title}>{permissionGroup.name}</Text>
          <Text text={permissionGroup.description} />
          {!isEmpty(permissionGroup.permissions) && (
            <>
              <Divider />
              {permissionGroup.permissions.map(permission => (
                <div key={permission.permissionId} className={classes.permission}>
                  <Text className={classes.permissionTitle}>{permission.name}</Text>
                  <Text text={permission.description} />
                </div>
              ))}
            </>
          )}
        </div>
      ))}
    </Container>
  )
}

PermissionGroupsDetails.propTypes = {
  permissionGroups: PropTypes.array,
  isAdmin: PropTypes.bool,
  loading: PropTypes.bool
}

PermissionGroupsDetails.defaultValues = {
  permissionGroups: {},
  isAdmin: false
}

export default PermissionGroupsDetails
