import React from 'react'
import PropTypes from 'prop-types'
import { Modal as MuiModal, makeStyles } from '@material-ui/core'

import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
  paper: ({ width, maxWidth }) => ({
    position: 'absolute',
    width: width || 100,
    maxWidth: maxWidth || '100%',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    outline: 'none'
  })
}))

function Modal ({ open, width, maxWidth, children, container, backdropStyles, containerClassName, onClose }) {
  const classes = useStyles({ width, maxWidth })
  return (
    <MuiModal
      disablePortal
      onClose={onClose}
      open={open}
      aria-labelledby='modal-title'
      aria-describedby='modal-description'
      container={container}
      style={{ position: 'absolute' }}
      BackdropProps={{
        style: {
          position: 'absolute',
          ...(backdropStyles || {})
        }
      }}
    >
      <div className={clsx(classes.paper, containerClassName)}>{children}</div>
    </MuiModal>
  )
}

Modal.propTypes = {
  backdropStyles: PropTypes.object,
  open: PropTypes.bool,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  maxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  container: PropTypes.object,
  containerClassName: PropTypes.string,
  onClose: PropTypes.func
}

Modal.defaultProps = {
  backdropStyles: null,
  open: false,
  onClose: undefined,
  width: 100,
  maxWidth: '100%',
  container: null,
  containerClassName: ''
}

export default Modal
