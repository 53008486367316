import React, { useCallback, useEffect, useMemo, useState } from 'react'
import EyeIcon from '@material-ui/icons/Visibility'
import dayjs from 'dayjs'
import { isEmpty } from 'lodash'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core'
import OperationalTable, { useOperationalTable } from '../../../organisms/OperationalTable'
import { useAutoOperationalTableContext } from '../../../organisms/OperationalTable/AutoOperationalTableContext'
import { useGroupingContext } from '../../../organisms/GroupingProvider/GroupingContext'
import { exportCsv, useListBobiOutputs } from '../../../../api/bobi'
import Icon from '../../../atoms/Icon'

const defaultPageSize = 100
const itemName = 'Book of Business Reports'

const useBobiOutputsList = ({ pageSize }) => {
  const {
    isLoading,
    data = []
  } = useListBobiOutputs({}, {
  })

  return {
    isLoading,
    data
  }
}

const useStyles = makeStyles({
  container: {
    display: 'flex',
    gap: '.5rem',
    alignItems: 'center'
  },
  clickable: {
    '&:hover': {
      cursor: 'pointer'
    }
  }
})

const viewIsDisabled = true

// eslint-disable-next-line react/prop-types
const RenderActions = ({ bobiOutputId, name, setIsDownloading }) => {
  const classes = useStyles()
  const history = useHistory()
  console.log('history.location', history.location)
  const onView = useCallback(() => {
    if (viewIsDisabled) return
    const lastSlash = history.location.pathname.lastIndexOf('/')
    const path = history.location.pathname.substring(0, lastSlash)

    history.push(`${path}/results/${bobiOutputId}`)
    // eslint-disable-next-line react/prop-types
  }, [history, bobiOutputId])

  const onDownload = useCallback(async () => {
    setIsDownloading(true)
    try {
      const data = await exportCsv({ bobiOutputId })

      const blob = new Blob([data], { type: 'text/csv' })
      const url = URL.createObjectURL(blob)

      const anchor = document.createElement('a')
      anchor.href = url
      anchor.download = `${name}.csv`

      const clickHandler = () => {
        setTimeout(() => {
          URL.revokeObjectURL(url)
          // eslint-disable-next-line no-restricted-globals
          removeEventListener('click', clickHandler)
        }, 150)
      }

      anchor.addEventListener('click', clickHandler, false)

      anchor.click()
    } finally {
      setIsDownloading(false)
    }
  }, [bobiOutputId, name, setIsDownloading])

  return (
    <div className={classes.container}>
      <div onClick={onView} className={classes.clickable} title='Currently Unavailable'>
        <EyeIcon color='disabled' />
      </div>
      <div onClick={onDownload} className={classes.clickable}>
        <Icon name='download' customSize='20px' />
      </div>
    </div>
  )
}

const renderDateCell = ({ cell }) => {
  if (!cell.value) return '--'

  return dayjs(cell.value).format('YYYY-MM-DD')
}

/**
 *
 * @param {string} s
 * @returns {string}
 */
const uppercase = (s) => {
  if (!s) return ''
  if (s.length === 1) return s.toUpperCase()

  return `${s.charAt(0).toUpperCase()}${s.substring(1)}`
}

const upperCaseCell = ({ cell }) => {
  return uppercase(cell.value)
}

const getColumns = ({ setIsDownloading }) => [
  {
    Header: 'Run Date',
    id: 'runDate',
    accessor: 'runDate',
    Cell: renderDateCell
  },
  {
    Header: 'Report Level',
    id: 'levelType',
    accessor: 'levelType',
    Cell: upperCaseCell
  },
  {
    Header: 'Filtered By',
    id: 'filters',
    accessor: 'filters'
  },
  {
    Header: 'Data Sets',
    id: 'dataSets',
    accessor: 'dataSets'
  },
  {
    Header: 'Date Range',
    id: 'relativeDateRange',
    accessor: 'relativeDateRange'
  },
  {
    Header: 'As of Date',
    id: 'asOfDate',
    accessor: 'asOfDate',
    Cell: renderDateCell
  },
  {
    Header: 'Run By',
    id: 'runBy',
    accessor: 'runBy'
  },
  {
    Header: 'Archives At',
    id: 'archiveAt',
    accessor: 'archiveAt',
    Cell: renderDateCell
  },
  {
    Header: '',
    id: 'actions',
    // eslint-disable-next-line react/prop-types
    Cell: ({ row: { original: { bobiOutputId, name } } }) => {
      return (
        <RenderActions bobiOutputId={bobiOutputId} name={name} setIsDownloading={setIsDownloading} />
      )
    }
  }
]

const defaultSort = [{
  id: 'runDate',
  desc: true
}]

const ReportListTable = (props) => {
  const { setPerformanceTableProps } = useGroupingContext()
  const { tableProps } = useAutoOperationalTableContext()

  const [isDownloading, setIsDownloading] = useState(false)

  const columns = useMemo(() => {
    return getColumns({ setIsDownloading })
  }, [setIsDownloading])

  const {
    pageSize,
    onPagingChange,
    onSortingChange,
    onTableModeChange
  } = useOperationalTable({
    defaultPageSize
  })

  const {
    data,
    isLoading,
    total
  } = useBobiOutputsList({ pageSize })

  useEffect(() => {
    const tablePropInSync = columns.length <= tableProps?.allColumns?.length
    const loading = (!isEmpty(data) && !isLoading) || !tablePropInSync

    if (!loading && typeof setPerformanceTableProps === 'function') {
      setPerformanceTableProps(tableProps)
    }
  }, [isLoading, setPerformanceTableProps, tableProps, data, columns.length])

  return (
    <OperationalTable
      autoSticky
      mode='client'
      columns={columns}
      data={data}
      defaultPageSize={defaultPageSize}
      defaultSort={defaultSort}
      itemName={itemName}
      loading={isLoading || isDownloading}
      total={total}
      onSortingChange={onSortingChange}
      onPagingChange={onPagingChange}
      onTableModeChange={onTableModeChange}
    />
  )
}

ReportListTable.Wrapper = OperationalTable.Wrapper
ReportListTable.SuperHeader = OperationalTable.SuperHeader

export default ReportListTable
