import React, { useCallback, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { useDropzone } from 'react-dropzone'
import { makeStyles, CircularProgress } from '@material-ui/core'
import clsx from 'clsx'
import Image from '../../../../../atoms/Image'
import { ICON_NAMES } from '../../../../../../constants'
import Icon from '../../../../../atoms/Icon'

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
  width: '6.25rem',
  height: '6.25rem',
  position: 'relative',
  overflow: 'hidden',
  background: 'rgb(223, 230, 255)',
  borderColor: 'transparent',
  cursor: 'pointer'
}

const hoverStyles = {
  background: 'rgba(223, 230, 255, 0.8)'
}

const focusedStyle = {
  borderColor: '#2196f3'
}

const acceptStyle = {
  borderColor: '#00e676'
}

const rejectStyle = {
  borderColor: '#ff1744'
}

const useStyles = makeStyles(() => ({
  container: {
    width: 'fit-content',
    backgroundSize: 'auto 100%'
  },
  icon: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)'
  },
  contentButtonBackground: {
    position: 'absolute',
    top: '0',
    zIndex: 1,
    height: '100%',
    width: '100%',
    background: 'rgba(223, 230, 255, 0.7)'
  },
  centeredButton: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)'
  }
}))

const ImagePreviewDnDInput = ({
  file,
  styles,
  containerClass,
  onDropAccepted: _onDropAccepted,
  disabled,
  isLoading,
  acceptFiles,
  placeholderSrc
}) => {
  const classes = useStyles()
  const [hovered, setHovered] = useState(false)

  const onDropAccepted = useCallback(
    async ([file]) => {
      setHovered(false)
      _onDropAccepted(file)
    },
    [_onDropAccepted]
  )

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
    useDropzone({
      accept: acceptFiles,
      maxFiles: 1,
      onDropAccepted,
      disabled
    })

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...styles,
      ...(disabled ? { opacity: 0.5 } : {}),
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {})
    }),
    [styles, isFocused, isDragAccept, isDragReject, disabled]
  )

  useEffect(() => {
    return () => {
      if (file && file?.preview) {
        URL.revokeObjectURL(file.preview)
      }
    }
  }, [file])

  const renderContent = useMemo(() => {
    if (file || placeholderSrc) {
      return (
        <Image
          isPublicResource
          extraStyles={{ ...baseStyle, ...styles }}
          src={file?.preview || placeholderSrc}
          onLoad={() => {
            URL.revokeObjectURL(file?.preview)
          }}
        />
      )
    }
    return (
      <div className={classes.icon}>
        <Icon customSize='2rem' color='#212945' name={ICON_NAMES.image} />
      </div>
    )
  }, [classes.icon, placeholderSrc, file, styles])

  const renderIsLoadingContent = useMemo(() => {
    if (!isLoading || !file) return null
    return (
      <div className={classes.contentButtonBackground}>
        <div className={classes.centeredButton}>
          <CircularProgress />
        </div>
      </div>
    )
  }, [
    file,
    isLoading,
    classes.centeredButton,
    classes.contentButtonBackground
  ])

  const onMouseEnter = useCallback(
    () => !disabled && setHovered(true),
    [disabled]
  )
  const onMouseLeave = useCallback(() => setHovered(false), [])
  const containerStyle = { ...style, ...(hovered ? hoverStyles : {}) }

  const containerClasses = clsx(classes.container, {
    [containerClass]: Boolean(containerClass)
  })

  return (
    <div
      className={containerClasses}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <div {...getRootProps({ style: containerStyle })}>
        <input {...getInputProps()} />
        {renderIsLoadingContent}
        {renderContent}
      </div>
    </div>
  )
}

ImagePreviewDnDInput.propTypes = {
  styles: PropTypes.object,
  file: PropTypes.object,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  onDropAccepted: PropTypes.func,
  containerClass: PropTypes.string,
  placeholderSrc: PropTypes.string,
  acceptFiles: PropTypes.arrayOf(PropTypes.string)
}

ImagePreviewDnDInput.defaultProps = {
  styles: {},
  acceptFiles: ['image/*']
}

export default ImagePreviewDnDInput
