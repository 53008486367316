import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@material-ui/core'
import { useFormattingContext } from '../../../organisms/FormattingProvider/FormattingContext'
import FadeIn from '../../../molecules/Transitions/FadeIn'

const authenticatorKey = 'okta_verify'

function OktaVerifyFactor ({ authenticators }) {
  const { formatter } = useFormattingContext()

  const { allowed, enrollments } = useMemo(() => {
    if (!authenticators) return { allowed: false }
    const authenticator = authenticators.find(x => x.key === authenticatorKey)
    if (!authenticator) return { allowed: false }

    const enrollments = authenticator._embedded.enrollments
    if (!enrollments.length) return { allowed: false }

    return {
      allowed: authenticator.enrollable,
      authenticator,
      enrollments
    }
  }, [authenticators])

  if (!allowed) return null

  return (
    <>
      <Grid item xs={12} md={4}>
        <FadeIn className='__factor-card'>
          <div className='__factor-header'>
            <div className='__factor-name'>Okta Verify</div>
            <div className='__factor-description'>You are enrolled in Okta Verify.</div>
          </div>
          <div className='__factor-details'>
            <div>Devices</div>
            {enrollments.map(e => (
              <div key={e.id} className='__factor-list'>
                <span>{e.profile.deviceName}</span>
                <span>-</span>
                <span>Enrolled on {formatter(e.created, '@MM/DD/YYYY')}</span>
              </div>
            ))}
          </div>
        </FadeIn>
      </Grid>
    </>
  )
}

OktaVerifyFactor.propTypes = {
  authenticators: PropTypes.array
}

export default OktaVerifyFactor
