import React, { useCallback, useEffect, useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import { isEmpty, last, orderBy, first, isNumber, isString, isNaN, noop } from 'lodash'
import { ASSET_FILTERS, CALC_TYPES, LEVEL_TYPES, TABLE_CELL_FORMATS } from '../../constants'
import {
  CollapsibleTableColumnsPropTypes,
  CollapsibleTableRowsPropTypes,
  dateRangeFilterShape,
  insertColumnsAtShape,
  insertColumnsAtDefaults
} from '../../prop-types/tables'
import useExpandibleTable from '../../hooks/useExpandibleTable'
import useFetchAssets from '../../hooks/useFetchAssets'
import { getToolTip, removeByIndex, updateRowByIndex } from '../../utils'
import { getSummaryFinalRow, mapAssetClasses } from '../../utils/tableHelper'
import { fetchPortfolioSummaryReport } from '../../service'
import { useAppContext } from '../../redux/slices/appContext'
import CollapsibleTable from './CollapsibleTable'

const getNumberValueFromCell = (cellValue, returnValue = 0) => {
  if (isString(cellValue)) {
    const value = parseFloat(cellValue.replace(/\$|,|%/g, ''))
    return isNumber(value) && !isNaN(value) ? value : returnValue
  }
  return cellValue
}

const getRowTotals = (rows = [], classType) => {
  return rows.reduce((acc, row) => {
    if (ASSET_FILTERS[classType] === ASSET_FILTERS.SUMMARY_ASSETS_CLASSES || ASSET_FILTERS[classType] === ASSET_FILTERS.SUMMARY_ASSETS) {
      const [
        { value: assetClassPercentage }, , ,
        { value: balance = 0 }, ,
        { value: costBasis = 0 }, ,
        { value: unrealizedGain = 0 },
        { value: annualIncome }
      ] = row
      acc.totalWeight = acc.totalWeight + getNumberValueFromCell(assetClassPercentage)
      acc.totalBalance = acc.totalBalance + getNumberValueFromCell(balance)
      acc.totalCostBasis = acc.totalCostBasis + getNumberValueFromCell(costBasis)
      acc.totalUNrealizedGain = acc.totalUNrealizedGain + getNumberValueFromCell(unrealizedGain)
      acc.totalAnnualIncome = acc.totalAnnualIncome + getNumberValueFromCell(annualIncome)
    }
    return acc
  }, {
    totalWeight: 0,
    totalBalance: 0,
    totalCostBasis: 0,
    totalUNrealizedGain: 0,
    totalAnnualIncome: 0
  })
}

const getFinalRow = (rows, parentRowConfig) => {
  const { classType } = parentRowConfig

  if (ASSET_FILTERS[classType] === ASSET_FILTERS.SUMMARY_ASSETS ||
    ASSET_FILTERS[classType] === ASSET_FILTERS.SUMMARY_ASSETS_CLASSES) {
    const rowTotals = getRowTotals(rows, classType)
    return [getSummaryFinalRow(rowTotals)]
  }
  return []
}

const PortfolioSummaryTable = ({
  balanceInformationDateFilters,
  onRowClick,
  classType,
  insertColumnsAt,
  showOnlyPercentages,
  maxExpandibleDepthLevel,
  rows: _rows,
  columns: _columns,
  finalRow: _finalRow
}) => {
  const { clientId } = useAppContext()
  const { fetchAssetClasses } = useFetchAssets()
  const [isExpandedTabledLoaded, setIsExpandedTableLoading] = useState(false)

  const { rows, columns, finalRow, setRows, setFinalRow } = useExpandibleTable({
    rows: _rows,
    columns: _columns,
    finalRow: _finalRow,
    insertColumnsAt
  })

  const fetchRowChildren = useCallback(async (row) => {
    const rowConfig = last(row)
    const classTypeSource = rowConfig?.classType.type || rowConfig?.classType || classType
    const dateFiltersOrdered = orderBy(Object.keys(balanceInformationDateFilters))

    const balanceInformationDataSet = await Promise.all(dateFiltersOrdered
      .map(async (balanceInformationDateFilterKey) => {
        const { startDate, endDate } = balanceInformationDateFilters[balanceInformationDateFilterKey]

        let assets = []
        if (ASSET_FILTERS[classTypeSource] === ASSET_FILTERS.SUMMARY_SUBCLASSES) {
          assets = await fetchAssetClasses({
            endDate,
            startDate,
            calcType: CALC_TYPES.balance,
            assetClassId: rowConfig.value,
            levelTypes: [LEVEL_TYPES.SUBCLASS_TAG]
          })
        } else if (ASSET_FILTERS[classTypeSource] === ASSET_FILTERS.SUMMARY_ASSETS) {
          const { data: portfolioSummaryReport } = await fetchPortfolioSummaryReport({
            startDate,
            endDate,
            clientId,
            calcType: CALC_TYPES.balance,
            assetClassTagIds: [rowConfig.assetIds?.assetClassTagId],
            subclassTagIds: [rowConfig.assetIds?.subclassTagId]
          })
          assets = portfolioSummaryReport
        }
        return [balanceInformationDateFilterKey, assets]
      }))

    return Object.fromEntries(balanceInformationDataSet)
  }, [
    clientId,
    classType,
    fetchAssetClasses,
    balanceInformationDateFilters
  ])

  const getChildrenRows = useCallback(async (row, rowIndexes) => {
    const balanceInformationResponse = await fetchRowChildren(row, rowIndexes)
    const maxDepthLevelReached = maxExpandibleDepthLevel === rowIndexes.length
    const maxChildrenDepthLevelReached = maxExpandibleDepthLevel - 1 === rowIndexes.length
    const rowsFormatted = mapAssetClasses({
      data: balanceInformationResponse,
      rowConfig: {
        classType: { type: last(row)?.classType || classType },
        ...(maxChildrenDepthLevelReached
          ? { isExpandible: false }
          : { isExpandible: true, isExpanded: false, isLoading: false })
      },
      showOnlyPercentages
    })

    return {
      rowsFormatted,
      maxDepthLevelReached,
      balanceInformationResponse,
      maxChildrenDepthLevelReached
    }
  }, [
    classType,
    fetchRowChildren,
    showOnlyPercentages,
    maxExpandibleDepthLevel
  ])

  const onExpandRow = useCallback(
    async ({ row, rowIndexes, isExpanded }) => {
      setRows(
        updateRowByIndex(rows, rowIndexes, {
          isExpanded,
          hidden: true,
          isLoading: true,
          isExpandible: true
        })
      )

      const { rowsFormatted, maxDepthLevelReached } = await getChildrenRows(row, rowIndexes)

      setRows(
        updateRowByIndex(rows, rowIndexes, {
          isExpanded,
          isLoading: false,
          isExpandible: !maxDepthLevelReached,
          rows: rowsFormatted
        })
      )
    },
    [rows, setRows, getChildrenRows]
  )

  const totalBalance = useMemo(() => rows.reduce((acc, [, { value }]) => value + acc, 0), [rows])

  const fetchPortfolioSummaryAssets = useCallback(async (rootRows, totalBalance, intIndexes = []) => {
    let rowsHydrated = [...rootRows]
    const rowTotals = []

    async function fetchRowsRecursively (rootRows, totalBalance, intIndexes = []) {
      return await Promise.all(rootRows.map(async (row, index) => {
        const indexLevels = [...intIndexes, index]

        const {
          rowsFormatted,
          maxDepthLevelReached,
          maxChildrenDepthLevelReached
        } = await getChildrenRows(row, indexLevels)

        const childrenRows = rowsFormatted
          .map(row => {
            const [weightCell, descriptionCell, quantityCell, balanceCell, ...rowRest] = row
            const cellValue = getNumberValueFromCell(balanceCell.value, undefined)
            if (!cellValue) {
              return row
            }
            const rowConfig = last(rowRest)
            const weightPercentage = totalBalance !== 0
              ? cellValue / totalBalance
              : weightCell.value

            return [
              { ...weightCell, value: weightPercentage },
              descriptionCell,
              quantityCell,
              balanceCell,
              ...removeByIndex(row.length - 1, rowRest),
              {
                ...rowConfig,
                tooltips: [
                  getToolTip(
                    weightPercentage,
                    TABLE_CELL_FORMATS.PERCENTAGE
                  ),
                  ...removeByIndex(0, rowConfig.tooltips)
                ]
              }
            ]
          })

        const rowConfig = last(row)
        const finalRow = getFinalRow(childrenRows, rowConfig)

        if (!isEmpty(finalRow)) rowTotals.push(first(finalRow))

        rowsHydrated = updateRowByIndex(rowsHydrated, indexLevels, {
          isExpanded: true,
          isLoading: false,
          isExpandible: !maxDepthLevelReached,
          rows: childrenRows,
          finalRow
        })
        if (!maxChildrenDepthLevelReached) {
          await fetchRowsRecursively(childrenRows, totalBalance, indexLevels)
        }
      }))
    }
    await fetchRowsRecursively(rowsHydrated, totalBalance, intIndexes)
    return { rowsHydrated, rowTotals }
  }, [
    getChildrenRows
  ])

  useEffect(() => {
    async function fetchPortfolioSummary (rows, totalBalance) {
      setRows(rows.map(row => {
        const rowConfig = last(row)
        return [...row, { ...rowConfig, isLoading: true }]
      }))
      const { rowsHydrated, rowTotals } = await fetchPortfolioSummaryAssets(rows, totalBalance)
      setRows(rowsHydrated)

      if (!isEmpty(finalRow)) {
        const [finalRowWithTaxLots] = getFinalRow(rowTotals, { classType: ASSET_FILTERS.SUMMARY_ASSETS_CLASSES })
        const [totalWeightCell, , , totalBalanceCell] = finalRow
        const [, , , , ...finalRowRest] = finalRowWithTaxLots
        setFinalRow([totalWeightCell, { value: '' }, { value: '' }, totalBalanceCell, ...finalRowRest])
      }
    }
    if (!isEmpty(rows) && !isExpandedTabledLoaded) {
      setIsExpandedTableLoading(true)
      fetchPortfolioSummary(rows, totalBalance)
    }
  }, [
    rows,
    setRows,
    finalRow,
    setFinalRow,
    totalBalance,
    isExpandedTabledLoaded,
    fetchPortfolioSummaryAssets
  ])

  return (
    <CollapsibleTable
      rows={rows}
      columns={columns}
      finalRow={finalRow}
      onRowClick={onRowClick}
      onExpandRow={onExpandRow}
      showEmptyRowOnExpandIfEmpty={false}
    />
  )
}

PortfolioSummaryTable.propTypes = {
  rows: CollapsibleTableRowsPropTypes,
  columns: CollapsibleTableColumnsPropTypes,
  finalRow: PropTypes.arrayOf(PropTypes.any),
  onRowClick: PropTypes.func,
  classType: PropTypes.string,
  insertColumnsAt: PropTypes.shape(insertColumnsAtShape),
  showOnlyPercentages: PropTypes.bool,
  maxExpandibleDepthLevel: PropTypes.number.isRequired,
  balanceInformationDateFilters: PropTypes.shape({
    balanceInformationToday: PropTypes.shape(dateRangeFilterShape),
    ...Array.from(
      { length: 9 },
      (_, i) => `balanceInformation${i + 2}Column`
    ).reduce(
      (acc, balanceInformationKey) => ({
        ...acc,
        [balanceInformationKey]: PropTypes.shape(dateRangeFilterShape)
      }),
      {}
    )
  })
}

PortfolioSummaryTable.propTypes = {
  rows: [],
  columns: [],
  finalRow: undefined,
  onRowClick: noop,
  classType: 'ASSETS',
  insertColumnsAt: insertColumnsAtDefaults,
  showOnlyPercentages: false,
  balanceInformationDateFilters: {}
}

export default PortfolioSummaryTable
