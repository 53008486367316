import React from 'react'
import PropTypes from 'prop-types'
import StringValueEditor from './StringValueEditor'
import AdvisorValueEditor from './AdvisorValueEditor'

function SsoValueEditor ({ type, value, onChange, disabled }) {
  if (!type) return null

  if (type === 'string') {
    return (
      <StringValueEditor value={value} onChange={onChange} disabled={disabled} />
    )
  }

  if (type === 'advisor') {
    return (
      <AdvisorValueEditor value={value} onChange={onChange} disabled={disabled} />
    )
  }

  return (
    <div>Unknown field type</div>
  )
}

SsoValueEditor.propTypes = {
  type: PropTypes.oneOf(['string', 'advisor']),
  value: PropTypes.any,
  onChange: PropTypes.func,
  disabled: PropTypes.bool
}

export default SsoValueEditor
