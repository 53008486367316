import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import PillButtonGroup from '../../../../molecules/PillButtonGroup'
import { filterOptions } from './filterOptions'

const getLabel = (totals, key) => {
  if (!totals) return filterOptions[key].title

  return `${filterOptions[key].title} (${totals[key].total})`
}

const MilestoneDetailsHeader = ({ onFilterSelected, selectedFilter, totals }) => {
  const next7Title = useMemo(() => getLabel(totals, 'next7'), [totals])
  const next30Title = useMemo(() => getLabel(totals, 'next30'), [totals])
  const last30Title = useMemo(() => getLabel(totals, 'last30'), [totals])

  return (
    <div>
      <PillButtonGroup onSelect={onFilterSelected} selectedValue={selectedFilter}>
        <PillButtonGroup.Option value={filterOptions.next7}>{next7Title}</PillButtonGroup.Option>
        <PillButtonGroup.Option value={filterOptions.next30}>{next30Title}</PillButtonGroup.Option>
        <PillButtonGroup.Option value={filterOptions.last30}>{last30Title}</PillButtonGroup.Option>
      </PillButtonGroup>
    </div>
  )
}

MilestoneDetailsHeader.propTypes = {
  onFilterSelected: PropTypes.func.isRequired,
  selectedFilter: PropTypes.object.isRequired,
  totals: PropTypes.shape({
    next7: PropTypes.shape({
      total: PropTypes.number
    }),
    next30: PropTypes.shape({
      total: PropTypes.number
    }),
    last30: PropTypes.shape({
      total: PropTypes.number
    })
  })
}

export default MilestoneDetailsHeader
