import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { Controller, useForm } from 'react-hook-form'
import SydButton from '../../../../../commonDesign/Button'
import SydLabel, { hookFormErrorAdapter } from '../../../../../commonDesign/SydLabel'
import SydInput from '../../../../../commonDesign/SydInput'
import { useDialogStyles } from '../common'

function RenameGroupForm ({ onComplete, onClose, group }) {
  const classes = useDialogStyles()

  const { handleSubmit, ...form } = useForm({
    mode: 'onChange',
    defaultValues: {
      longName: group.longName
    }
  })

  const [processing, setProcessing] = useState(false)
  const onCreate = useCallback(async (e) => {
    const onValid = async (form) => {
      setProcessing(true)
      try {
        await onComplete({
          longName: form.longName,
          shortName: form.longName.slice(0, 30)
        })
      } finally {
        setProcessing(false)
      }
    }
    const onInvalid = (errors) => {
      console.error(errors)
    }

    const handler = handleSubmit(onValid, onInvalid)
    try {
      await handler(e)
    } catch (err) {
      alert('There was a problem renaming the group')
      console.error(err)
      throw err
    }
  }, [onComplete, handleSubmit, setProcessing])

  return (
    <>
      <div className={classes.form}>
        <Controller
          rules={{
            required: { value: true, message: 'Group Name is required' },
            maxLength: { value: 80, message: 'Group Name must be 80 characters or less' }
          }}
          control={form.control} name='longName' render={({ field, fieldState }) => (
            <SydLabel label='Group Name' description='Max 80 Characters' error={hookFormErrorAdapter(form, fieldState)}>
              <SydInput autoFocus {...field} />
            </SydLabel>
          )}
        />
      </div>
      <div className={classes.actions}>
        <SydButton variant='ghost' onClick={onClose}>Cancel</SydButton>
        <SydButton
          variant='primary'
          disabled={!form.formState.isValid}
          onClick={onCreate}
          processing={processing}
        >
          Rename
        </SydButton>
      </div>
    </>
  )
}

RenameGroupForm.propTypes = {
  onComplete: PropTypes.func,
  onClose: PropTypes.func,
  group: PropTypes.shape({
    longName: PropTypes.string
  })
}

export default RenameGroupForm
