import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import {
  Button
} from '@material-ui/core'
import { useGroupingContext } from '../../../organisms/GroupingProvider/GroupingContext'

const useStyles = makeStyles((theme) => ({
  roundedButton: {
    border: `2px solid ${theme.palette.mirage}`,
    color: theme.palette.mirage,
    borderRadius: 25,
    padding: '4px 16px 3px 16px',
    textTransform: 'none',
    fontWeight: 'bold',
    fontSize: '0.75rem',
    alignSelf: 'flex-start',
    marginLeft: 'auto'
  }
}))

const ClassificationTags = ({
  onClick,
  disabledOn,
  children
}) => {
  const classes = useStyles()
  const {
    selectedGrouping
  } = useGroupingContext()

  return (
    <Button
      className={classes.roundedButton}
      disabled={disabledOn === selectedGrouping.value}
      onClick={onClick}
      variant='outlined'
    >
      {children}
    </Button>
  )
}

ClassificationTags.propTypes = {
  children: PropTypes.any.isRequired,
  onClick: PropTypes.func.isRequired,
  disabledOn: PropTypes.string.isRequired
}

export default ClassificationTags
