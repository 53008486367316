import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { Controller, useForm } from 'react-hook-form'
import SydButton from '../../../../commonDesign/Button'
import { useDescribeStatusTemplateItemMutation } from '../../../../../api/groups'
import Editor from '../../../../organisms/WYSIWYGEditor'
import { useDialogStyles } from './common'

const useSubmitter = (form, onComplete) => {
  const { handleSubmit } = form
  const [processing, setProcessing] = useState(false)
  const { mutateAsync: modifyItem } = useDescribeStatusTemplateItemMutation()
  const onSubmit = useCallback(async (formData) => {
    const command = {
      item: {
        statusTemplateItemId: formData.statusTemplateItemId,
        statusTemplateId: formData.statusTemplateId,
        description: formData.description || null
      }
    }

    try {
      setProcessing(true)
      const result = await modifyItem(command)
      onComplete(result)
    } finally {
      setProcessing(false)
    }
  }, [modifyItem, setProcessing, onComplete])

  const submitter = useCallback(async (e) => {
    const onValid = async (form) => {
      await onSubmit(form)
    }
    const onInvalid = (errors) => {
      console.error(errors)
    }

    const handler = handleSubmit(onValid, onInvalid)
    await handler(e)
  }, [handleSubmit, onSubmit])

  return {
    submitter,
    processing
  }
}

function DescribeTemplateItemForm ({ templateItem, onCancel, onComplete }) {
  const classes = useDialogStyles()
  const form = useForm({
    mode: 'onChange',
    defaultValues: {
      statusTemplateItemId: templateItem.statusTemplateItemId,
      statusTemplateId: templateItem.statusTemplateId,
      description: templateItem.description
    }
  })

  const { submitter, processing } = useSubmitter(form, onComplete)

  return (
    <>
      <div className={classes.form}>
        <h4>{templateItem.displayName}</h4>
        <div className={classes.group}>
          <Controller
            name='description' control={form.control}
            render={(f => (
              <div className={classes.editorWrapper}>
                <Editor
                  defaultValue={templateItem.description}
                  placeholder='Enter your description...'
                  isMarkdown
                  onChange={f.field.onChange}
                />
              </div>
            ))}
          />
        </div>

      </div>
      <div className={classes.actions}>
        <SydButton disabled={processing} variant='ghost' size='lg' onClick={onCancel}>Cancel</SydButton>
        <SydButton
          variant='primary'
          size='lg'
          onClick={submitter}
          processing={processing}
        >
          Save
        </SydButton>
      </div>
    </>
  )
}

DescribeTemplateItemForm.propTypes = {
  templateItem: PropTypes.object,
  onComplete: PropTypes.func,
  onCancel: PropTypes.func
}

export default DescribeTemplateItemForm
