import React, { useCallback, useState } from 'react'
import { Modal } from '@material-ui/core'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import RoundedButton from '../../../atoms/RoundedButton'
import Text from '../../../atoms/Text'
import { BUTTON_VARIANT, ICON_NAMES, TEXT_VARIANTS } from '../../../../constants'
import { useDynamicPlanning } from '../DynamicPlanningContext'
import theme from '../../../../theme'
import Icon from '../../../atoms/Icon'
import { postNamedCommand } from '../../../../service'

const useStyles = makeStyles(() => ({
  modal: {},
  container: {
    padding: '1.5rem',
    margin: 0,
    borderRadius: '5px',
    position: 'absolute',
    width: '500px',
    maxWidth: '100%',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    outline: 'none'
  },
  header: {
    marginBottom: '1.25rem',
    width: '100%',
    display: 'flex',
    alignItems: 'start'
  },
  content: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '2rem'
  },
  buttonInner: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  }
}))

const DeletePlanModal = ({ isOpen, onClose }) => {
  const classes = useStyles()
  const { currentPlan, refetchPlans, isSandbox } = useDynamicPlanning()

  const [isLoading, setIsLoading] = useState(false)

  const onSave = useCallback(async () => {
    setIsLoading(true)
    await postNamedCommand('planning', 'deletePlan', { planId: currentPlan.planId })
    await refetchPlans()
    setIsLoading(false)
    onClose()
  }, [currentPlan?.planId, onClose, refetchPlans])

  return (
    <Modal
      onClose={onClose}
      open={isOpen}
      aria-labelledby='modal-title'
      aria-describedby='modal-description'
    >
      <div className={classes.container}>
        <div className={classes.header}>
          <div style={{ flexGrow: 1 }}>
            <Text variant={TEXT_VARIANTS.h2}>
              Are you sure?
            </Text>
            <Text variant={TEXT_VARIANTS.subtitle}>
              {isSandbox(currentPlan)
                ? 'Are you sure you want to delete these edits:'
                : 'Are you sure you would like to delete the plan:'} {currentPlan?.shortName}?
            </Text>
          </div>
          <button
            type='button'
            onClick={onClose}
            style={{ cursor: 'pointer' }}
          >
            <Icon
              color={theme.palette.summitBlue}
              name={ICON_NAMES.close}
              customSize='1.5rem'
              variant='button'
            />
          </button>
        </div>
        <div className={classes.content}>
          <RoundedButton
            variant={BUTTON_VARIANT.outlined}
            secondary
            onClick={onClose}
            disabled={isLoading}
          >
            Cancel
          </RoundedButton>
          <RoundedButton
            variant={BUTTON_VARIANT.outlined}
            primary
            onClick={onSave}
            disabled={isLoading}
          >
            {isLoading ? 'Deleting...' : isSandbox(currentPlan) ? 'Delete Edits' : 'Delete this plan'}
          </RoundedButton>
        </div>
      </div>
    </Modal>
  )
}

DeletePlanModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func
}

export default DeletePlanModal
