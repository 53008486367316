import { useMemo } from 'react'
import { useFormattingContext } from '../FormattingProvider/FormattingContext'

export const defaultColumnConfig = {
  columns: [
    { id: 'levelName', accessor: 'levelName', Header: 'Name', format: 'title', align: 'left', width: 150, maxWidth: 150, toolTipTitle: '', sticky: 'left' },
    { id: 'firstCommitDate', accessor: 'firstCommitDate', Header: 'Commitment Date', format: 'date', align: 'right', width: 80, maxWidth: 80, toolTipTitle: '' },
    { id: 'committedCapital', accessor: 'committedCapital', Header: 'Committed Capital', format: 'human', align: 'right', width: 80, maxWidth: 80, toolTipTitle: '' },
    { id: 'capitalCalled', accessor: 'capitalCalled', Header: 'Capital Called', format: 'marketValue', align: 'right', width: 80, maxWidth: 80, toolTipTitle: '' },
    { id: 'percentageCalled', accessor: 'percentageCalled', Header: '% Called', format: 'percentage', align: 'right', width: 80, maxWidth: 80, toolTipTitle: '' },
    { id: 'remainingCapital', accessor: 'remainingCapital', Header: 'Remaining Capital', format: 'marketValue', align: 'right', width: 80, maxWidth: 80, toolTipTitle: '' },
    { id: 'capitalReturned', accessor: 'capitalReturned', Header: 'Return of Capital', format: 'marketValue', align: 'right', width: 80, maxWidth: 80, toolTipTitle: '' },
    { id: 'income', accessor: 'income', Header: 'Income', format: 'marketValue', align: 'right', width: 80, maxWidth: 80, toolTipTitle: '' },
    { id: 'realizedMultiple', accessor: 'realizedMultiple', Header: 'Realized Multiple', format: 'multiple', align: 'right', width: 80, maxWidth: 80, toolTipTitle: '' },

    { id: 'endingValue', accessor: 'performance.endingValue', Header: 'Ending Value', format: 'human', align: 'right', hidden: true, width: 80, maxWidth: 80, toolTipTitle: '' },
    { id: 'netChange', accessor: 'performance.netChange', Header: 'Net Gain', format: 'human', align: 'right', hidden: true, width: 80, maxWidth: 80, toolTipTitle: '' },
    { id: 'annualizedReturn', accessor: 'performance.annualizedReturn', Header: 'Return', format: 'percentage', hidden: true, align: 'right', width: 80, maxWidth: 80, toolTipTitle: '' },
    { id: 'totalValueMultiple', accessor: 'totalValueMultiple', Header: 'Total Value Multiple', format: 'multiple', hidden: true, align: 'right', width: 80, maxWidth: 80, toolTipTitle: '' },
    { id: 'lastCallDate', accessor: 'lastCallDate', Header: 'Last Capital Call Date', format: 'date', align: 'right', hidden: true, width: 80, maxWidth: 80, toolTipTitle: '' },
    { id: 'lastDataAvailable', accessor: 'performance.lastDataAvailable', Header: 'Last Updated', format: 'date', hidden: true, align: 'right', width: 80, maxWidth: 80, toolTipTitle: '' },
    { id: 'lastReturnDate', accessor: 'lastReturnDate', Header: 'Last ROC Date', format: 'date', align: 'right', hidden: true, width: 80, maxWidth: 80, toolTipTitle: '' },
    { id: 'distribution', accessor: 'distribution', Header: 'Distributions', format: 'human', align: 'right', hidden: true, width: 80, maxWidth: 80, toolTipTitle: '' },
    { id: 'lastIncomeDate', accessor: 'lastIncomeDate', Header: 'Last Income Date', format: 'date', align: 'right', hidden: true, width: 80, maxWidth: 80, toolTipTitle: '' }
  ],
  defaultSort: [
    { id: 'levelName', desc: false }
  ]
}

export const useColumns = ({ columnsConfig = defaultColumnConfig, format }) => {
  const { formatter } = useFormattingContext(format)

  return useMemo(() => {
    const { columns, defaultSort } = columnsConfig
    const templatedColumns = columns.map((column) => {
      return {
        ...column,
        Cell: ({ value }) => formatter(value, column.format)
      }
    })

    return {
      columns: templatedColumns,
      defaultSort
    }
  }, [columnsConfig, formatter])
}
