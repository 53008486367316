import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import Skeleton from '../../../../atoms/Skeleton'
import StatusFilter from './StatusFilter'
import { filterOptions } from './filterOptions'

const useStyles = makeStyles((theme) => ({
  accountStatusHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '2.125rem'
  }
}))

const getDetails = (totals, key) => {
  if (!totals) return key

  return {
    key,
    total: totals[key].total
  }
}

const AccountStatusHeader = ({ className, onFilterSelected, selectedFilter, totals }) => {
  const classes = useStyles()

  return (
    <div className={clsx(classes.accountStatusHeader, className)}>
      {
        Object.keys(filterOptions).map((item) => {
          if (!totals) {
            return <Skeleton key={item} height='111px' width='240px' />
          }

          return (
            <StatusFilter
              key={item}
              info={getDetails(totals, item)}
              selectedItem={selectedFilter}
              onClick={onFilterSelected}
              options={filterOptions}
            />
          )
        })
      }
    </div>
  )
}

AccountStatusHeader.propTypes = {
  className: PropTypes.string,
  onFilterSelected: PropTypes.func.isRequired,
  selectedFilter: PropTypes.object.isRequired,
  totals: PropTypes.shape({
    stale: PropTypes.shape({
      total: PropTypes.number
    }),
    current: PropTypes.shape({
      total: PropTypes.number
    }),
    closed: PropTypes.shape({
      total: PropTypes.number
    })
  })
}

export default AccountStatusHeader
