import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import { makeStyles, Typography } from '@material-ui/core'
import noop from 'lodash/noop'
import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'
import dayjs from 'dayjs'
import {
  CONFIRMATION_TYPE,
  DEFAULT_TAGS,
  DOCUMENT_VAULT_CONFIRM_MODAL_TYPE,
  TAGS,
  ICON_NAMES
} from '../../constants'
import { useToggle } from '../../hooks'
import {
  deleteDocument,
  deleteTag,
  editDocument,
  editTag,
  fetchUser
} from '../../service'
import { useAppContext, useSetAppContext } from '../../redux/slices/appContext'
import Icon from '../atoms/Icon'
import Text from '../atoms/Text'
import { capitalizeFirstLetter, formatBytes } from '../../utils'
import { useFirmDocuments } from '../../api/documentVault'
import ConfirmationModal from './ConfirmationModal'
import DocumentThumbnail from './DocumentThumbnail'
import Menu from './Menu'
import InputInlineEdit from './InputInlineEdit'
import Modal from './Modal'
import DocumentVaultEditDocumentForm from './DocumentVaultEditDocumentForm'

const MODAL_BACKDROP_STYLES = {
  borderRadius: '0.15rem'
}

const useStyles = makeStyles((theme) => ({
  tagRow: {
    marginBottom: '2rem',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'nowrap',
    width: '100%',
    overflowX: 'auto',
    overflowY: 'hidden',
    height: '300px',
    '&::-webkit-scrollbar': {
      width: '1em',
      height: '1em',
      backgroundColor: 'transparent'
    },
    '&::-webkit-scrollbar-track': {
      borderRadius: '20px',
      backgroundColor: 'transparent'
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '20px',
      backgroundColor: theme.palette.gray.dark,
      border: '3px solid white'
    }
  },
  tagRowTitle: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    marginBottom: '0rem',
    height: '1.5rem'
  },
  tagLabel: {
    fontWeight: 600,
    padding: '2px 0 !important',
    fontSize: '1.65rem',
    textTransform: 'capitalize'
  },
  tagRowMenu: {
    display: 'flex',
    marginLeft: '0.5rem',
    cursor: 'pointer'
  },
  document: {
    marginRight: '24px'
  },
  inlineInput: {
    marginLeft: '0 !important',
    marginRight: '0 !important'
  },
  confirmModalContainer: {
    minWidth: '25rem',
    borderRadius: '0.5rem'
  },
  editDocumentContainer: {
    borderRadius: '0.5rem'
  }
}))

const getModalConfirmationText = (type, name) => {
  switch (type) {
    case DOCUMENT_VAULT_CONFIRM_MODAL_TYPE.DELETE_DOCUMENTS:
      return `Are you sure you want to delete the document "${name}"?.`
    case DOCUMENT_VAULT_CONFIRM_MODAL_TYPE.DELETE_TAGS:
      return `Are you sure you want to delete the tag "${name}"?, Files won't be deleted.`
    default:
      return ''
  }
}

const INLINE_EDIT_INPUT_ACTIONS_WIDTH = 105

const initialConfirmModalState = {
  id: null,
  name: null,
  type: null
}

const initialCurrentTagState = {
  id: null,
  name: '',
  nameClientWidth: null
}

const DocumentVaultTagRowsList = ({
  tags,
  onModalOpen,
  handleClickDocument,
  refreshDocumentsList
}) => {
  const classes = useStyles()
  const tagTitlesRef = useRef([])
  const appContext = useAppContext()
  const setAppContext = useSetAppContext()
  const [confirmModalOpen, toggleConfirmModalOpen] = useToggle()
  const [confirmModalLoading, toggleConfirmModalLoading] = useToggle()
  const [currentTag, setCurrentTag] = useState({ ...initialCurrentTagState })
  const [currentConfirmModal, setCurrentConfirmModal] = useState({
    ...initialConfirmModalState
  })

  useEffect(() => {
    if (!confirmModalOpen) {
      onModalOpen(confirmModalOpen)
    }
  }, [confirmModalOpen, onModalOpen])

  useEffect(() => setCurrentTag({ ...initialCurrentTagState }), [tags])

  useEffect(() => {
    setAppContext({ disableOverviewKeyNavigation: Boolean(currentTag.id) })
    return () => setAppContext({ disableOverviewKeyNavigation: false })
  }, [setAppContext, currentTag.id])

  const tagOptions = useMemo(() => {
    return Object.keys(tags).reduce((options, tagKey) => {
      if (!tags[tagKey].id) return options
      options.push({ id: tags[tagKey].id, label: tagKey })
      return options
    }, [])
  }, [tags])

  const onConfirmDelete = useCallback(async () => {
    try {
      toggleConfirmModalLoading()
      if (currentConfirmModal.type === DOCUMENT_VAULT_CONFIRM_MODAL_TYPE.DELETE_DOCUMENTS) {
        await deleteDocument(appContext.clientId, currentConfirmModal.id)
      } else {
        await deleteTag(currentConfirmModal.id)
      }
      toggleConfirmModalLoading()
      toggleConfirmModalOpen()
      refreshDocumentsList()
    } catch (error) {
      console.error(error)
    }
  }, [
    appContext.clientId,
    currentConfirmModal,
    refreshDocumentsList,
    toggleConfirmModalOpen,
    toggleConfirmModalLoading
  ])

  const onConfirmEditDocument = useCallback(
    async (formData) => {
      try {
        onModalOpen(true)
        toggleConfirmModalLoading()
        const { id, fileName: name, tags } = formData
        await editDocument(appContext.clientId, id, {
          name,
          tags
        })
        toggleConfirmModalLoading()
        toggleConfirmModalOpen()
        refreshDocumentsList()
      } catch (error) {
        console.error(error)
      }
    },
    [
      onModalOpen,
      appContext.clientId,
      refreshDocumentsList,
      toggleConfirmModalOpen,
      toggleConfirmModalLoading
    ]
  )

  const onDelete = useCallback(
    ({ value: { name, id, type } }) => {
      onModalOpen(true)
      setCurrentConfirmModal({ id, name, type })
      toggleConfirmModalOpen()
    },
    [toggleConfirmModalOpen, onModalOpen]
  )

  const onDownloadFile = useCallback(
    ({ value: { id } }) => {
      handleClickDocument(id, false)
    },
    [handleClickDocument]
  )

  const onOpenDocumentInfo = useCallback(
    async ({ value }) => {
      onModalOpen(true)
      const { name, id, type, tags, lastViewer = {}, size, createdAt } = value
      const { userId, viewedAt } = lastViewer

      const documentDetails = {
        createdAt: `Uploaded ${dayjs(createdAt).format('MMM D, YYYY')}`,
        size: size && formatBytes(size)
      }
      try {
        if (userId) {
          const { data: lastViewerBy } = await fetchUser(userId)
          const lastViewedBy = `${lastViewerBy.firstName} ${lastViewerBy.lastName}`
          documentDetails.lastViewedBy = `Last viewed by ${lastViewedBy} on ${dayjs(viewedAt).format('MMM D, YYYY')}`
        }
      } catch (err) {
        console.error(err)
      } finally {
        setCurrentConfirmModal({ id, name, type, tags, userId, documentDetails })
      }
      toggleConfirmModalOpen()
    },
    [toggleConfirmModalOpen, onModalOpen]
  )

  const onEditDocument = useCallback(
    ({ value }) => {
      onModalOpen(true)
      const { name, id, type, tags } = value
      setCurrentConfirmModal({ id, name, type, tags })
      toggleConfirmModalOpen()
    },
    [toggleConfirmModalOpen, onModalOpen]
  )

  const renderConfirmationModal = useMemo(() => {
    if (!currentConfirmModal.type) return null
    const { id, type, name, documentDetails } = currentConfirmModal

    const isReadOnlyDocumentModal = DOCUMENT_VAULT_CONFIRM_MODAL_TYPE.DOCUMENT_INFO === type
    if (isReadOnlyDocumentModal || DOCUMENT_VAULT_CONFIRM_MODAL_TYPE.EDIT_DOCUMENTS === type) {
      const title = isReadOnlyDocumentModal ? 'Document Information' : 'Edit document info'
      return (
        <Modal
          open={confirmModalOpen}
          containerClassName={classes.confirmModalContainer}
        >
          <DocumentVaultEditDocumentForm
            title={title}
            tags={tagOptions}
            clientId={appContext.clientId}
            isLoading={confirmModalLoading}
            documentDetails={documentDetails}
            readOnly={isReadOnlyDocumentModal}
            defaultValues={{
              id,
              fileName: currentConfirmModal.name,
              tags: currentConfirmModal.tags.map((tag) => tag.id).join(',')
            }}
            onSubmit={onConfirmEditDocument}
            onCancel={toggleConfirmModalOpen}
            containerClassName={classes.editDocumentContainer}
          />
        </Modal>
      )
    }

    const confirmationText = getModalConfirmationText(type, name)

    return (
      <ConfirmationModal
        width='25rem'
        open={confirmModalOpen}
        buttonSize='small'
        onCancel={toggleConfirmModalOpen}
        isLoading={confirmModalLoading}
        container={null}
        onConfirm={onConfirmDelete}
        buttonLabelCancel="No, don't delete"
        buttonLabelConfirm='Yes, delete it'
        backdropStyles={MODAL_BACKDROP_STYLES}
        confirmationType={CONFIRMATION_TYPE.Danger}
        confirmationText={confirmationText}
      />
    )
  }, [
    tagOptions,
    appContext.clientId,
    classes.editDocumentContainer,
    classes.confirmModalContainer,
    toggleConfirmModalOpen,
    confirmModalLoading,
    currentConfirmModal,
    confirmModalOpen,
    onConfirmDelete,
    onConfirmEditDocument
  ])

  const handleClickFirmDocument = useCallback(
    ({ document: doc, url }) => {
      const link = document.createElement('a')
      link.href = url
      link.download = doc.key
      document.body.appendChild(link)
      link.click()
      link.remove()
    }, [])

  const { data: firmDocuments } = useFirmDocuments()

  const onDownloadFirmDocument = useCallback(({ value }) => {
    const document = firmDocuments?.documents?.find(d => d.document.documentId === value?.document?.documentId)

    if (document) {
      handleClickFirmDocument(document)
    }
  }, [firmDocuments, handleClickFirmDocument])

  const getFirmDocumentThumbnailOptions = useCallback((document) => {
    return [
      {
        iconName: ICON_NAMES.download,
        label: 'Download',
        onClick: onDownloadFirmDocument,
        value: {
          type: DOCUMENT_VAULT_CONFIRM_MODAL_TYPE.DOCUMENT_INFO,
          ...document
        }
      }
    ]
  }, [onDownloadFirmDocument])

  const renderFirmDocuments = useMemo(() => {
    if (firmDocuments?.documents?.length < 1) {
      return ''
    }

    return (
      <div className='firmDocs'>
        <div className={classes.tagRowTitle}>
          <Typography component='div' className={classes.tagLabel}>Firm Documents</Typography>
        </div>
        <div className={classes.tagRow}>
          {firmDocuments?.documents?.map(doc => (
            <div className={classes.document} key={doc.documentId}>
              <DocumentThumbnail
                onClick={() => handleClickFirmDocument(doc)}
                title={doc.document.name}
                src={doc.thumbnailUrl}
                fallbackSrc=''
                updatedAt=''
                menuOptions={getFirmDocumentThumbnailOptions(doc)}
              />
            </div>
          )) ?? []}
        </div>
      </div>
    )
  }, [classes, firmDocuments, handleClickFirmDocument, getFirmDocumentThumbnailOptions])

  const getDocumentThumbnailOptions = useCallback(
    (value) => {
      return appContext.isAdvisor
        ? [
          {
            iconName: ICON_NAMES.info,
            label: 'Info',
            onClick: onOpenDocumentInfo,
            value: {
              ...value,
              type: DOCUMENT_VAULT_CONFIRM_MODAL_TYPE.DOCUMENT_INFO
            }
          },
          {
            iconName: ICON_NAMES.download,
            label: 'Download',
            onClick: onDownloadFile,
            value
          },
          {
            iconName: ICON_NAMES.edit,
            label: 'Edit',
            onClick: onEditDocument,
            value: {
              ...value,
              type: DOCUMENT_VAULT_CONFIRM_MODAL_TYPE.EDIT_DOCUMENTS
            }
          },
          {
            iconName: ICON_NAMES.delete,
            label: 'Delete',
            onClick: onDelete,
            color: '#D44333',
            value: {
              ...value,
              type: DOCUMENT_VAULT_CONFIRM_MODAL_TYPE.DELETE_DOCUMENTS
            }
          }
        ]
        : [{
          iconName: ICON_NAMES.info,
          label: 'Info',
          onClick: onOpenDocumentInfo,
          value: {
            ...value,
            type: DOCUMENT_VAULT_CONFIRM_MODAL_TYPE.DOCUMENT_INFO
          }
        },
        {
          iconName: ICON_NAMES.download,
          label: 'Download',
          onClick: onDownloadFile,
          value
        }]
    },
    [onDelete, onDownloadFile, onEditDocument, onOpenDocumentInfo, appContext.isAdvisor]
  )

  const onEditTag = useCallback(
    ({ value: { id, name } }) => {
      const { nameClientWidth } = tagTitlesRef.current.find(
        ({ id: tagId }) => tagId === id
      )
      setCurrentTag((prevState) => ({
        ...prevState,
        id,
        name,
        nameClientWidth
      }))
    },
    [tagTitlesRef]
  )

  const onEditTagCancel = useCallback(
    () => setCurrentTag({ ...initialCurrentTagState }),
    []
  )

  const createInputFieldCopy = useCallback((inputField) => {
    if (!inputField) return

    const inputCopy = document.createElement('div')
    inputCopy.innerHTML = inputField.value

    const inputStyles = getComputedStyle(inputField);
    [
      'fontFamily',
      'fontSize',
      'fontWeight',
      'wordWrap',
      'whiteSpace',
      'borderLeftWidth',
      'borderTopWidth',
      'borderRightWidth',
      'borderBottomWidth'
    ].forEach(function (key) {
      inputCopy.style[key] = inputStyles[key]
    })

    inputCopy.style.overflow = 'auto'
    inputCopy.style.position = 'absolute'
    inputCopy.style.left = -1000
    inputCopy.style.top = -1000
    inputField.parentElement.appendChild(inputCopy)
    const client = {
      width: inputCopy.clientWidth,
      height: inputCopy.clientHeight
    }
    inputField.parentElement.removeChild(inputCopy)
    return client
  }, [])

  const onChangeTagName = useCallback(
    (event) => {
      const { target: input } = event
      const { width: tagTitleWidth } = createInputFieldCopy(input)
      setCurrentTag({
        id: currentTag.id,
        name: input.value,
        nameClientWidth: tagTitleWidth
      })
    },
    [currentTag.id, createInputFieldCopy]
  )

  const onEditTagConfirm = useCallback(async () => {
    try {
      const { id: tagId, name } = currentTag
      await editTag(tagId, { name })
      refreshDocumentsList()
    } catch (error) {
      console.error(error)
    }
  }, [currentTag, refreshDocumentsList])

  const getTagMenuOptions = useCallback(
    (tag) => {
      const value = {
        ...tag,
        type: DOCUMENT_VAULT_CONFIRM_MODAL_TYPE.DELETE_TAGS
      }
      return [
        {
          iconName: ICON_NAMES.edit,
          label: 'Edit tag',
          onClick: onEditTag,
          value
        },
        {
          iconName: ICON_NAMES.delete,
          label: 'Delete tag',
          onClick: onDelete,
          color: '#D44333',
          value
        }
      ]
    },
    [onDelete, onEditTag]
  )

  const renderMenuIcon = useCallback(
    ({ setAnchorEl }) => (
      <div className={classes.tagRowMenu} onClick={setAnchorEl}>
        <Icon name={ICON_NAMES.threeDots} customSize='1.5rem' />
      </div>
    ),
    [classes.tagRowMenu]
  )

  const setTitleWidths = useCallback((element, index, tagId) => {
    if (!element) return
    const { clientWidth: titleTagWidth } = element
    tagTitlesRef.current[index] = { id: tagId, nameClientWidth: titleTagWidth }
  }, [])

  const tagKeysOrdered = useMemo(() => {
    let tagKeys = Object.keys(tags)
    const recentTagKey = tagKeys.indexOf(TAGS.recents)
    const othersTagKey = tagKeys.indexOf(TAGS.others)
    tagKeys = tagKeys.filter(tag => tag !== TAGS.recents && tag !== TAGS.others)
    tagKeys.sort((tagKeyA, tagKeyB) => tagKeyA.localeCompare(tagKeyB))

    if (recentTagKey !== -1) tagKeys.unshift(TAGS.recents)
    if (othersTagKey !== -1) tagKeys.push(TAGS.others)
    return tagKeys
  }, [tags])

  return (
    <div>
      {renderConfirmationModal}
      {renderFirmDocuments}
      {tagKeysOrdered.map((tag, index) => {
        const { id } = tags[tag]
        return (
          <div key={tag}>
            <div className={classes.tagRowTitle}>
              {id && currentTag.id === id ? (
                <InputInlineEdit
                  width={`${currentTag.nameClientWidth + INLINE_EDIT_INPUT_ACTIONS_WIDTH
                  }px`}
                  value={currentTag.name}
                  autoFocus
                  initInEditMode
                  disableUnderline
                  showEditIcon={false}
                  className={classes.tagLabel}
                  onChangeHandler={onChangeTagName}
                  onIconClickHandler={onEditTagConfirm}
                  onCancelIconClickHandler={onEditTagCancel}
                  textFieldClassName={classes.inlineInput}
                />
              ) : (
                <Typography
                  ref={(element) => setTitleWidths(element, index, id)}
                  component='div'
                  className={classes.tagLabel}
                >
                  {tag}
                </Typography>
              )}
              {!DEFAULT_TAGS.includes(tag) && isEmpty(currentTag.id) && (
                <Menu options={getTagMenuOptions({ id, name: tag })}>
                  {renderMenuIcon}
                </Menu>
              )}
            </div>
            <div className={classes.tagRow}>
              {isEmpty(tags[tag].items) && (
                <Text
                  text={`No document found for the tag ${capitalizeFirstLetter(
                    tag
                  )}`}
                />
              )}
              {!isEmpty(tags[tag].items) &&
                tags[tag].items.map(
                  ({ name, thumbnailSrc, defaultThumbnailSrc, id, tags, updatedAt, size, createdAt, lastViewer }) => (
                    <div className={classes.document} key={id}>
                      <DocumentThumbnail
                        onClick={() => handleClickDocument(id)}
                        title={name}
                        src={thumbnailSrc}
                        fallbackSrc={defaultThumbnailSrc}
                        updatedAt={updatedAt}
                        menuOptions={getDocumentThumbnailOptions({
                          id,
                          name,
                          tags,
                          size,
                          createdAt,
                          lastViewer
                        })}
                      />
                    </div>
                  )
                )}
            </div>
          </div>
        )
      })}
    </div>
  )
}

DocumentVaultTagRowsList.propTypes = {
  onModalOpen: PropTypes.func,
  tags: PropTypes.object,
  handleClickDocument: PropTypes.func,
  refreshDocumentsList: PropTypes.func
}

DocumentVaultTagRowsList.defaultProps = {
  onModalOpen: noop,
  tags: null,
  handleClickDocument: noop,
  refreshDocumentsList: noop
}

export default DocumentVaultTagRowsList
