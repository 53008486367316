import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  listItem: {
    display: 'flex',
    flexDirection: 'row',
    gap: '14px',
    backgroundColor: ({ selected }) => selected ? theme.palette.gray.light : theme.palette.white,
    color: ({ selected }) => selected ? `${theme.palette.summitBlue} !important` : theme.palette.summitBlue,
    width: '460px',
    padding: '18px 24px',
    transition: 'background .25s ease-in-out',
    '&:hover': {
      textDecoration: 'none',
      borderColor: theme.palette.black,
      backgroundColor: theme.palette.gray.light,
      cursor: 'pointer'
    },
    '& .__body': {
    },
    '& .__indicators': {
      width: '8px'
    },
    '& .__title': {
      fontWeight: 600
    }
  },
  lastMessagePreview: {
    fontWeight: 100
  }
}))

const ConversationListItem = ({ item, clientId, selectedConversation }) => {
  const selected = useMemo(() => {
    return selectedConversation === item.conversationKey
  }, [item.conversationKey, selectedConversation])
  const classes = useStyles({ selected })

  return (
    <Link to={`/conversations/c/${clientId}/t/${item.conversationKey}`} replace className={classes.listItem}>
      <div className='__body'>
        <div className='__title'>{item.name}</div>
        <div className={classes.lastMessagePreview}>{item.lastMessagePreview}</div>
      </div>
      <div className='__indicators'>
        &nbsp;
      </div>
    </Link>
  )
}

ConversationListItem.propTypes = {
  clientId: PropTypes.number,
  item: PropTypes.shape({
    conversationKey: PropTypes.string,
    name: PropTypes.string,
    lastMessagePreview: PropTypes.string
  }),
  selectedConversation: PropTypes.string
}

export default ConversationListItem
