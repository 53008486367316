
import { useMemo } from 'react'
import concat from 'lodash/concat'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { useFeatureScheduleExposure } from '../../../../api/billing'

dayjs.extend(utc)

const getDetailsQuery = (query = {}) => {
  const skip = query?.skip ?? 0
  const take = query?.take ?? 100

  return {
    ...query,
    skip,
    take
  }
}

const mapSort = (sorts) => sorts?.map(({ id, desc }) => ({ field: id, dir: desc ? 'desc' : 'asc' }))

export const useFeatureScheduleExposureData = ({
  pageIndex,
  pageSize,
  sort,
  totalCount = undefined,
  searchForMultiple = false
}) => {
  const formattedSort = mapSort(sort)

  const query = useMemo(() => {
    return getDetailsQuery({
      skip: pageIndex * pageSize,
      take: pageSize,
      sort: formattedSort,
      ...totalCount ? { totalCount } : {}
    })
  }, [pageSize, pageIndex, formattedSort, totalCount])

  const {
    data,
    isLoading,
    isPreviousData,
    error
  } = useFeatureScheduleExposure(query, { searchForMultiple })

  return {
    data: isLoading ? [] : data.body,
    loading: isLoading || isPreviousData,
    error
  }
}

export const useFeeScheduleExposureCSVData = ({ data, columns, searchForMultiple = false }) => {
  return useMemo(() => {
    if (!data) return []
    const labels = columns.map(({ Header }) => Header)
    const rows = data.map((account) => {
      return [
        account.accountId || '',
        account.longNumber || '',
        account.longName || '',
        account.custodian || '',
        account.asOfDate ? dayjs.utc(account.asOfDate).format('M/D/YYYY') : '',
        searchForMultiple ? account.AcctFeeScheduleCt || 0 : '',
        searchForMultiple ? account.ClientFeeScheduleCt || 0 : ''
      ]
    })
    return concat([labels], rows)
  }, [data, columns, searchForMultiple])
}
