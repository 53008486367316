import React from 'react'

import AccountStatusTableContainer from './AccountStatusTableContainer'

import { accountStatusPropTypes, accountStatusDefaultProps } from './AccountStatusProps'

const AccountStatusView = (props) => {
  return (
    <AccountStatusTableContainer {...props} />
  )
}

AccountStatusView.propTypes = accountStatusPropTypes
AccountStatusView.defaultProps = accountStatusDefaultProps

export default AccountStatusView
