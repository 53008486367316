import React, { Children, cloneElement, useEffect, useRef, useState } from 'react'
import mstarAggregationConsumerAccountSetup from 'mstar-aggregation-consumer-accountsetup'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { Box } from '@material-ui/core'
import { useAppContext } from '../../redux/slices/appContext'
import { useDebouncedCallback } from '../../hooks'
import { postNamedCommand } from '../../service'
import Alert from '../atoms/Alert'
import { ALERT_SEVERITY, ALERT_VARIANT } from '../../constants'
import { useByAllSessionTokens } from '../../api/users'
import SydButton from '../commonDesign/Button'
import { useClient } from '../../api/clients'
import FastAvatar from '../molecules/FastAvatar'
import Text from '../atoms/Text'
import Skeleton from '../atoms/Skeleton'
import LoadingPlaceholder from './AddAccount/LoadingPlaceholder'

const errorStates = {
  badAuthentication: {
    level: 'blocking',
    message: 'There was an issue logging in. Please try again.'
  },
  internalError: {
    level: 'blocking',
    message: 'There was an issue launching this widget.  Please try again'
  }
}

const useStyles = makeStyles((theme) => ({
  pageContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'linear-gradient(#fff,#f9f9f9)'
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    gridGap: '16px',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    minHeight: '100%'
  },
  widgetContainer: {
    maxWidth: '90%',
    width: '1000px',
    maxHeight: '90vh',
    minHeight: '500px',
    borderRadius: '16px',
    boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.1)',
    position: 'relative',
    overflow: 'hidden',
    background: '#fff'
  },
  loadingContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  },
  widgetInner: {
    width: '100%',
    height: '100%',
    minHeight: '60vh',
    position: 'relative'
  },
  setupContainer: {
    padding: '16px 0'
  },
  backButtonContainer: {
    margin: '0 28px'
  },
  errorContainer: {
    padding: '16px 16px 0'
  },
  clientContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gridGap: '16px'
  }
}))

const ByAllAccountsWidget = ({
  defaultWidget = 'summaryComponent',
  includeClient = true,
  includeClientAvatar = true,
  clientAvatarSize = 'md',
  clientNameSize = '24px',
  children
}) => {
  const classes = useStyles()
  const appContext = useAppContext()
  // eslint-disable-next-line no-unused-vars
  const byAllScript = mstarAggregationConsumerAccountSetup

  const accountSetupWidgetRef = useRef(null)
  const accountSummaryWidgetRef = useRef(null)

  const [errorState, setErrorState] = useState(null)
  const [currentWidget, setCurrentWidget] = useState(defaultWidget)
  const [linkingRoute, setLinkingRoute] = useState(null)

  const debouncedTriggerAccountLinking = useDebouncedCallback(async () => {
    await postNamedCommand('accounts', 'createAccountLinking', {
      levelTypeId: 201,
      levelId: appContext.clientId,
      providerKey: 'BY_ALL_ACCOUNTS',
      inputParams: {}
    }).catch(() => {})
  })

  const handleWidgetError = ({ type }) => {
    setErrorState({ type, ...(errorStates[type] ?? {}) })
  }

  const handleDataChanged = ({ type }) => {
    if (type !== 'dataChanged') return
    debouncedTriggerAccountLinking()
  }

  const handleUserExit = ({ type }) => {
    if (type !== 'userExit') return
    setCurrentWidget('summaryComponent')
  }

  const handleAccountSetup = ({ type, detail }) => {
    if (type !== 'accountSetup') return
    setCurrentWidget('setupComponent')
    setLinkingRoute(detail ?? null)
  }

  useEffect(() => {
    if (!accountSummaryWidgetRef.current) {
      return
    }
    const elem = accountSummaryWidgetRef.current
    elem.addEventListener('badAuthentication', handleWidgetError)
    elem.addEventListener('internalError', handleWidgetError)
    elem.addEventListener('accountSetup', handleAccountSetup)

    return () => {
      elem.removeEventListener('badAuthentication', handleWidgetError)
      elem.removeEventListener('internalError', handleWidgetError)
      elem.removeEventListener('accountSetup', handleAccountSetup)
    }
  })

  useEffect(() => {
    if (!accountSetupWidgetRef.current) {
      return
    }
    const elem = accountSetupWidgetRef.current
    elem.addEventListener('badAuthentication', handleWidgetError)
    elem.addEventListener('internalError', handleWidgetError)
    elem.addEventListener('userExit', handleUserExit)
    elem.addEventListener('dataChanged', handleDataChanged)

    return () => {
      elem.removeEventListener('badAuthentication', handleWidgetError)
      elem.removeEventListener('internalError', handleWidgetError)
      elem.removeEventListener('userExit', handleUserExit)
      elem.removeEventListener('dataChanged', handleDataChanged)
    }
  })

  const { data, isFetching: isFetchingTokens, error: isErrorLoadingIcons } = useByAllSessionTokens()
  const { data: client, isFetching: isFetchingClient } = useClient(appContext.clientId, { enabled: includeClient })

  const handleUpdateLinkingRoute = (route) => {
    setLinkingRoute(route)
    setCurrentWidget('setupComponent')
  }

  return (
    <div className={classes.pageContainer}>
      <div className={classes.contentContainer}>
        {includeClient && (
          <div className={classes.clientContainer}>
            {isFetchingClient ? (<Skeleton height='40px' />)
              : (
                <>
                  {includeClientAvatar && (
                    <FastAvatar
                      size={clientAvatarSize}
                      avatarUrl={client?.avatarUrl}
                      abbreviation={client?.clientAbbreviation}
                    />
                  )}
                  <Text
                    text={client?.longName || client?.shortName}
                    customFontSize={clientNameSize}
                    customFontWeight={700}
                  />
                </>
              )}
          </div>
        )}

        {Children.map(children, child => cloneElement(child, { updateLinkingRoute: handleUpdateLinkingRoute }))}

        <div className={classes.widgetContainer}>
          {errorState && (
            <div className={classes.errorContainer}>
              <Alert variant={ALERT_VARIANT.standard} severity={ALERT_SEVERITY.error}>
                <p style={{ margin: 0 }}>
                  Issue adding assets<br />
                  <span style={{ fontWeight: 300, fontSize: '.9rem' }}>{errorState?.message ?? 'Something went wrong'}</span>
                </p>
              </Alert>
            </div>
          )}
          {isErrorLoadingIcons ? (
            <Box m='24px'>
              <Alert variant={ALERT_VARIANT.standard} severity={ALERT_SEVERITY.error}>
                <p style={{ margin: 0 }}>
                  Issue signing in<br />
                  <span style={{ fontWeight: 300, fontSize: '.9rem' }}>There was an issue getting the credentials required to sign in you in.  Try again.</span>
                </p>
              </Alert>
            </Box>
          ) : (
            <div className={classes.loadingContainer}>
              <LoadingPlaceholder />
            </div>
          )}

          {data && !isFetchingTokens && (
            <div className={classes.widgetInner}>
              {currentWidget === 'setupComponent' ? (
                <div className={classes.setupContainer}>
                  <div className={classes.backButtonContainer}>
                    <SydButton
                      onClick={() => {
                        setLinkingRoute(null)
                        setCurrentWidget('summaryComponent')
                      }}
                      icon='back'
                      variant='ghost'
                      size='md'
                    >
                      Back
                    </SydButton>
                  </div>
                  <mstar-aggregation-consumer-accountsetup
                    ref={accountSetupWidgetRef}
                    auth-context={JSON.stringify({ csrfToken: data?.csrfToken, jsessionId: data?.sessionId })}
                    route={linkingRoute}
                    iframe-style='min-height:60vh;height:100%;max-height:100%;max-width:100%'
                    override-css-file={`https://${window.location.host}/byall-component-styles.css`}
                    custom-fonts={`https://${window.location.host}/byall-component-fonts.css`}
                    translate-filepath={`https://${window.location.host}/byall-component-translations.json`}
                  />
                </div>
              ) : (
                <mstar-aggregation-consumer-accountsummary
                  ref={accountSummaryWidgetRef}
                  auth-context={JSON.stringify({ csrfToken: data?.csrfToken, jsessionId: data?.sessionId })}
                  iframe-style='min-height:60vh;height:100%;max-height:100%;max-width:100%'
                  override-css-file={`https://${window.location.host}/byall-component-styles.css`}
                  custom-fonts={`https://${window.location.host}/byall-component-fonts.css`}
                />
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

ByAllAccountsWidget.propTypes = {
  defaultWidget: PropTypes.string,
  includeClient: PropTypes.bool,
  includeClientAvatar: PropTypes.bool,
  clientAvatarSize: PropTypes.string,
  clientNameSize: PropTypes.string,
  children: PropTypes.node
}

export default ByAllAccountsWidget
