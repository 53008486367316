import React, { useCallback, useMemo } from 'react'
import { isFunction } from 'lodash'
import GroupTagFilters from '../../../../../molecules/GroupTagFilters'
import { useAccountsContext } from '../AccountsContext'
import { getDynamicColumnIds } from '../../../Clients/ClientListTab/helpers'
import { UNASSIGNED_OPTION } from '../useGroupTypeColumns'

const GroupTypeFilters = () => {
  const {
    groupTypes,
    visibleFilters,
    accountsFilters,
    onChangeFilters,
    isLoadingGroupTypes
  } = useAccountsContext()

  const onChangeFilterFlag = useCallback(
    (filterKey) => (values) => {
      onChangeFilters((prevState) => ({
        ...prevState,
        [filterKey]: isFunction(values)
          ? values(prevState.tagFilters)
          : values
      }))
    },
    [onChangeFilters]
  )

  const visibleGroupTypeFilters = useMemo(() => {
    const visibleFilterIds = getDynamicColumnIds(visibleFilters)

    return (groupTypes || []).map((groupType) => {
      const unAssignedOption = {
        ...UNASSIGNED_OPTION,
        payload: {
          groupTypeId: groupType.value,
          groupName: groupType.label
        }
      }
      return {
        ...groupType,
        groups: [...groupType.groups, unAssignedOption],
        hidden: !visibleFilterIds.includes(groupType.value.toString())
      }
    })
  }, [groupTypes, visibleFilters])

  return (
    <GroupTagFilters
      groupTypes={visibleGroupTypeFilters}
      isLoading={isLoadingGroupTypes}
      tagFilters={accountsFilters.tagFilters}
      setTagFilters={onChangeFilterFlag('tagFilters')}
    />
  )
}

export default GroupTypeFilters
