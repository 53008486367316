import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { IconButton, makeStyles } from '@material-ui/core'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import SydLabel from '../../../commonDesign/SydLabel'
import SydInput from '../../../commonDesign/SydInput'

const useStyles = makeStyles((theme) => ({
  passwordInput: {
    display: 'flex',
    flexDirection: 'row',
    gap: '10px'
  }
}))

const PasswordInput = React.forwardRef(function PasswordInput ({ name, label, autoComplete, value, onChange, onBlur, error }, ref) {
  const classes = useStyles()
  const [visible, setVisible] = useState(false)
  return (
    <SydLabel
      label={label}
      required
      description='&nbsp;'
      error={error?.message}
    >
      <div className={classes.passwordInput}>
        <SydInput
          ref={ref}
          name={name}
          type={visible ? 'input' : 'password'}
          autoComplete={autoComplete}
          value={value}
          onBlur={onBlur}
          onChange={onChange}
        />
        <IconButton aria-label='Password Visibility' onClick={() => setVisible(v => !v)}>
          {visible ? <Visibility /> : <VisibilityOff />}
        </IconButton>
      </div>
    </SydLabel>
  )
})

PasswordInput.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  autoComplete: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  error: PropTypes.string
}

export default PasswordInput
