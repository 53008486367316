import React from 'react'
import PropTypes from 'prop-types'
import { SvgIcon } from '@material-ui/core'

const IconPropTypes = {
  fillColor: PropTypes.string
}

const IconDefaultProps = {
  fillColor: '#000000'
}

const SwapVerticalFilledIcon = (props) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' height='24px' viewBox='0 0 24 24' width='24px' fill={props.fillColor}>
      <path d='M0 0h24v24H0z' fill='none' />
      <path d='M16 17.01V10h-2v7.01h-3L15 21l4-3.99h-3zM9 3L5 6.99h3V14h2V6.99h3L9 3z' />
    </svg>
  )
}

SwapVerticalFilledIcon.propTypes = IconPropTypes
SwapVerticalFilledIcon.defaultProps = IconDefaultProps

const SwapVerticalOutlinedIcon = (props) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' height='24px' viewBox='0 0 24 24' width='24px' fill={props.fillColor}>
      <path d='M0 0h24v24H0V0z' fill='none' />
      <path
        d='M16 17.01V10h-2v7.01h-3L15 21l4-3.99h-3zM9 3L5 6.99h3V14h2V6.99h3L9 3zm7 14.01V10h-2v7.01h-3L15 21l4-3.99h-3zM9 3L5 6.99h3V14h2V6.99h3L9 3z'
      />
    </svg>
  )
}

SwapVerticalOutlinedIcon.propTypes = IconPropTypes
SwapVerticalOutlinedIcon.defaultProps = IconDefaultProps

const SwapVerticalIcon = (props) => {
  const {
    fillColor,
    ...rest
  } = props
  const Icon = props.variant === 'filled' ? SwapVerticalFilledIcon : SwapVerticalOutlinedIcon
  return (
    <SvgIcon {...rest}>
      <Icon fillColor={fillColor} />
    </SvgIcon>
  )
}

SwapVerticalIcon.propTypes = {
  fillColor: PropTypes.string,
  variant: PropTypes.oneOf(['filled', 'outlined'])
}

SwapVerticalIcon.defaultProps = IconDefaultProps

export default SwapVerticalIcon
