import React, { useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import { Tooltip } from '@material-ui/core'
import { makeStyles, darken, withStyles } from '@material-ui/core/styles'
import { calcPositivePercentage, numeralByCase } from '../../utils'
import NumberFormat from '../atoms/NumberFormat'
import { DISPLAY_VBS_NUMBER_FORMAT, TITLE_VBS_NUMBER_FORMAT } from '../../constants'
import TitleWithSubtitle from './TitleWithSubtitle'

const MIN_BLOCK_WIDTH = 8
const MAX_TITLE_LENGHT = 15

const CustomTooltip = withStyles((theme) => ({
  arrow: {
    '&:before': {
      boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.14)',
      backgroundColor: 'white'
    }
  },
  tooltip: {
    backgroundColor: theme.palette.common.white,
    fontSize: '1rem',
    color: theme.palette.common.black,
    maxWidth: 700,
    boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.14)',
    borderRadius: '1rem'
  }
}))(Tooltip)

const useStyles = makeStyles((theme) => ({
  block: ({ backgroundColor, blockHeight }) =>
    ({
      width: '100%',
      height: blockHeight || '100%',
      position: 'relative',
      borderRadius: '24px',
      backgroundColor: backgroundColor || theme.palette.primary.A500,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      alignItems: 'center'
    }),
  detailBlock: () => ({
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'row'
  }),
  detailBlockItem: ({ backgroundColor }) => {
    const bgColor = backgroundColor
      ? darken(backgroundColor, 0.3)
      : theme.palette.gray.A500
    return {
      position: 'relative',
      padding: '1rem',
      color: bgColor,
      cursor: 'pointer',
      overflow: 'hidden',
      borderRightColor: bgColor,
      borderRightStyle: 'solid',
      borderRight: '3px',
      '&:hover': {
        backgroundColor: `${bgColor} !important`,
        color: `${theme.palette.common.white} !important`
      },
      '&:first-child': {
        borderTopLeftRadius: '8px',
        borderBottomLeftRadius: '8px'
      },
      '&:last-child': {
        borderRight: '0px solid white',
        borderTopRightRadius: '8px',
        borderBottomRightRadius: '8px'
      }
    }
  },
  detailBlockItemContinue: {
    width: '2rem !important',
    display: 'flex',
    justifyContent: 'center'
  },
  detailTitle: {
    color: theme.palette.common.white,
    position: 'absolute',
    fontSize: '1rem',
    bottom: '32px',
    left: '24px'
  },
  detailValue: {
    fontSize: '1.75rem'
  }
}))

const NUMBER_FORMAT = DISPLAY_VBS_NUMBER_FORMAT

function TreeMapSpecificBlock ({
  backgroundColor,
  blockHeight,
  onClick,
  selectedBlock,
  details,
  dataTestId
}) {
  const classes = useStyles({
    backgroundColor,
    blockHeight
  })

  const blockDetails = useMemo(() => {
    const totalValue = details.reduce((acc, block) => (acc + block.endingValue), 0)
    const { blocks } = [...details]
      .sort((blockA, blockB) => blockB.endingValue - blockA.endingValue)
      .reduce((acc, { endingValue, ...block }) => {
        const originalBlockWidth = calcPositivePercentage(endingValue, totalValue)
        const useMinimumWidth = originalBlockWidth < MIN_BLOCK_WIDTH
        const blockWidth = useMinimumWidth ? MIN_BLOCK_WIDTH : originalBlockWidth
        const showTitle = !useMinimumWidth || block.title.length < MAX_TITLE_LENGHT

        acc.blocks.push({ ...block, endingValue, width: blockWidth, showTitle })
        acc.total += blockWidth
        return acc
      }, { total: 0, blocks: [] })

    return blocks
  }, [details])

  const renderDetailBlock = useCallback((block) => {
    const { levelId, endingValue, width, title, showTitle } = block
    const content = (
      <div
        onClick={() => onClick(block)}
        className={classes.detailBlockItem}
        style={{
          width: `${width}%`,
          backgroundColor:
            block?.title === selectedBlock
              ? darken(backgroundColor, 0.3)
              : backgroundColor
        }}
      >
        <div className={classes.detailTitle}>
          <div className={classes.detailValue}>
            <NumberFormat
              title={numeralByCase(
                endingValue,
                TITLE_VBS_NUMBER_FORMAT,
                TITLE_VBS_NUMBER_FORMAT
              )}
              number={numeralByCase(
                endingValue,
                NUMBER_FORMAT.SHORT,
                NUMBER_FORMAT.LONG
              )}
              skipFormat
            />
          </div>
          {showTitle && title}
        </div>
      </div>
    )
    return (
      <React.Fragment key={`detail-block-${levelId}`}>
        {showTitle ? (
          content
        ) : (
          <CustomTooltip
            arrow
            placement='bottom'
            title={
              <div className={classes.tooltip}>
                <TitleWithSubtitle
                  titleElement={
                    <NumberFormat
                      title={numeralByCase(
                        endingValue,
                        TITLE_VBS_NUMBER_FORMAT,
                        TITLE_VBS_NUMBER_FORMAT
                      )}
                      number={numeralByCase(
                        endingValue,
                        NUMBER_FORMAT.SHORT,
                        NUMBER_FORMAT.LONG
                      )}
                      skipFormat
                    />
                  }
                  subtitle={title}
                />
              </div>
            }
          >
            {content}
          </CustomTooltip>
        )}
      </React.Fragment>
    )
  }, [
    backgroundColor,
    selectedBlock,
    onClick,
    classes.detailTitle,
    classes.detailValue,
    classes.detailBlockItem,
    classes.tooltip
  ])

  return (
    <div
      className={classes.block}
      data-testid={dataTestId}
    >
      <div className={classes.detailBlock}>
        {blockDetails.map((block) => renderDetailBlock(block))}
      </div>
    </div>
  )
}

TreeMapSpecificBlock.propTypes = {
  backgroundColor: PropTypes.string.isRequired,
  blockHeight: PropTypes.string,
  details: PropTypes.arrayOf(PropTypes.any).isRequired,
  dataTestId: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  selectedBlock: PropTypes.any
}

TreeMapSpecificBlock.defaultProps = {
  blockHeight: undefined,
  selectedBlock: undefined
}

export default TreeMapSpecificBlock
