/* eslint-disable react/jsx-boolean-value */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'
import Carousel from 'react-multi-carousel'
import { makeStyles } from '@material-ui/core/styles'
import dayjs from 'dayjs'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import isBetween from 'dayjs/plugin/isBetween'
import noop from 'lodash/noop'
import Text from '../atoms/Text'
import CarouselItem from '../atoms/CarouselItem'
import WealthJourneyMilestoneDetails from '../organisms/WealthJourneyMilestoneDetails'
import WealthJourneyMilestoneCard from './WealthJourneyMilestoneCard'

dayjs.extend(isSameOrAfter)
dayjs.extend(isSameOrBefore)
dayjs.extend(isBetween)

const CAROUSEL_RESPONSIVE = {
  desktopLarge: {
    breakpoint: { max: 6000, min: 1700 },
    items: 5
  },
  desktopMedium: {
    breakpoint: { max: 1700, min: 1400 },
    items: 4
  },
  desktopSmall: {
    breakpoint: { max: 1400, min: 1200 },
    items: 3
  },
  tablet: {
    breakpoint: { max: 1200, min: 900 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 900, min: 0 },
    items: 1
  }
}

const useStyles = makeStyles(() => ({
  emptySection: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '20px 36px',
    border: '2px solid #EEF0F8',
    borderRadius: '12px',
    width: '100%',
    height: '270px'
  },
  carouselContainer: {
    zIndex: '99',
    width: '100%'
  },
  carouselSlider: {
    // flex: '1 1 auto'
  }
}))

// TODO'S:
// 1. Background color for avatar/image
//    selected state
// 2. Add icons/images
//    need discriminator field
// 3. Add Milestone entry
// 4. Details format for active milestone
// 4a. Handle new line \n -> <br /> for active milestone json text (subtitle, description, etc)
// 5. Hover text for milestone in graph
// 6. Add extra years in line graph
// 7. Highlight active year in line graph
// 8. Pill toggle for milestones/activity/meetings - Need color / spacing feedback

const WealthJourneyMilestones = ({
  entries,
  selectedEntry,
  onEditEntry,
  onEntrySelected,
  onDeleteEntry,
  taskTitle,
  taskButtonLabel,
  theming
}) => {
  const classes = useStyles()
  const [carousel, setCarousel] = useState(null)

  if (isEmpty(entries)) {
    return (
      <div className={classes.emptySection}>
        <Text
          text='No Milestones Yet!'
          customFontSize='20px'
          color='#242749'
          customFontWeight={600}
        />
        <Text
          customFontSize='16px'
          color='#212945'
        >
          Click <strong>Add Milestone</strong> Button to begin
        </Text>
      </div>
    )
  }

  return (
    <>
      <div style={{ display: 'flex', width: '100%' }}>
        <Carousel
          ref={(el) => setCarousel(el)}
          arrows
          swipeable
          draggable
          shouldResetAutoplay
          enderArrowsWhenDisabled={false}
          renderButtonGroupOutside={false}
          renderDotsOutside={false}
          responsive={CAROUSEL_RESPONSIVE}
          sliderClass={classes.carouselSlider}
          containerClass={classes.carouselContainer}
          centerMode
        >
          {entries?.length > 0 &&
            entries.map((entry, index) => (
              <CarouselItem
                key={`citem_${entry.entryId}`}
                selected={selectedEntry?.entryId === entry.entryId}
                carousel={carousel}
                carouselIndex={index}
              >
                <WealthJourneyMilestoneCard
                  key={`mk_${entry.entryId}`}
                  milestone={entry}
                  onSelect={onEntrySelected}
                  selected={selectedEntry}
                  theming={theming}
                />
              </CarouselItem>
            ))}
        </Carousel>
      </div>
      {selectedEntry && (
        <WealthJourneyMilestoneDetails
          milestone={selectedEntry}
          onEditMilestone={onEditEntry}
          onDeleteMilestone={onDeleteEntry}
          taskTitle={taskTitle}
          taskButtonLabel={taskButtonLabel}
        />
      )}
    </>
  )
}

WealthJourneyMilestones.propTypes = {
  entries: PropTypes.array,
  selectedEntry: PropTypes.object,
  onEditEntry: PropTypes.func,
  onEntrySelected: PropTypes.func,
  onDeleteEntry: PropTypes.func,
  taskTitle: PropTypes.string,
  taskButtonLabel: PropTypes.string,
  theming: PropTypes.object
}

WealthJourneyMilestones.defaultProps = {
  data: undefined,
  selectedEntry: null,
  onEditEntry: noop,
  onEntrySelected: noop,
  onDeleteEntry: noop
}

export default WealthJourneyMilestones
