import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import { useFormattingContext } from '../../../organisms/FormattingProvider/FormattingContext'
import SydButton from '../../../commonDesign/Button'
import { useBppReportStatus } from '../../../../api/coreData'

function DenaliReportRow ({ report, onExpire, onReRun, onRestart }) {
  const { formatter } = useFormattingContext()
  const [refetchInterval, setRefetchInterval] = useState(null)
  const { data } = useBppReportStatus(report.bppReportId, {
    enabled: !!report.bppReportId,
    refetchInterval
  })
  const taskMetrics = useMemo(() => {
    const result = {
      reportType: report.reportType,
      bppReportId: report.bppReportId,
      asOfDate: report.asOfDate,
      isProcessing: data?.isProcessing ?? report.isProcessing,
      lastStartedDate: data?.lastStartedDate ?? report.lastStartedDate,
      lastFinishedDate: data?.lastFinishedDate ?? report.lastFinishedDate,
      count: data?.taskCount ?? '--',
      completed: data?.tasksCompleted ? `${data.tasksCompleted}/${data.taskCount}` : '--',
      errored: data?.tasksErrored ? `${data.tasksErrored}/${data.taskCount}` : '--'
    }

    result.refetchInterval = result.isProcessing ? 5000 : null
    if (result.isProcessing && result.lastStartedDate) {
      // make sure we aren't wasting time on things that probably should just be expired
      result.refetchInterval = dayjs().diff(report.lastStartedDate, 'hour', true) > 1 ? null : 5000
    }

    return result
  }, [report, data])
  useEffect(() => {
    setRefetchInterval(taskMetrics.refetchInterval)
  }, [taskMetrics.refetchInterval])

  const duration = useMemo(() => {
    return taskMetrics.lastFinishedDate && taskMetrics.lastStartedDate ? `${(dayjs(taskMetrics.lastFinishedDate).diff(taskMetrics.lastStartedDate, 'minutes', true).toFixed(2))} m` : '--'
  }, [taskMetrics.lastStartedDate, taskMetrics.lastFinishedDate])

  return (
    <tr key={report.bppReportId}>
      <td>{taskMetrics.bppReportId}</td>
      <td>{formatter(taskMetrics.asOfDate, '@M/D/YY')}</td>
      <td>{taskMetrics.isProcessing ? 'true' : ''}</td>
      <td>{formatter(taskMetrics.lastStartedDate, 'ß')}</td>
      <td>{duration}</td>
      <td>{taskMetrics.completed}</td>
      <td>{taskMetrics.errored}</td>
      <td>
        <div className='__actions'>
          <SydButton size='xs' onClick={(e) => onReRun(e, taskMetrics)}>Re-run</SydButton>
          <SydButton size='xs' onClick={(e) => onRestart(e, taskMetrics)}>Restart</SydButton>
          <SydButton priority='warning' size='xs' onClick={(e) => onExpire(e, taskMetrics)}>Expire</SydButton>
        </div>
      </td>
      <td>{taskMetrics.refetchInterval}</td>
    </tr>
  )
}

DenaliReportRow.Headers = () => {
  return (
    <tr>
      <th>ReportId</th>
      <th>As Of</th>
      <th>Running</th>
      <th>Last Started</th>
      <th>Duration</th>
      <th>Completed</th>
      <th>Errored</th>
      <th>Actions</th>
    </tr>
  )
}

DenaliReportRow.propTypes = {
  onReRun: PropTypes.func,
  onRestart: PropTypes.func,
  onExpire: PropTypes.func,
  report: PropTypes.shape({
    reportType: PropTypes.string,
    bppReportId: PropTypes.number,
    asOfDate: PropTypes.string,
    isProcessing: PropTypes.string,
    lastStartedDate: PropTypes.string,
    lastFinishedDate: PropTypes.string
  })
}

export default DenaliReportRow
