import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { Grid, makeStyles, Typography } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  section: {
    backgroundColor: ({ backgroundImage }) => backgroundImage
      ? undefined
      : theme.palette.white,
    backgroundImage: ({ backgroundImage }) => backgroundImage
      ? `url("${backgroundImage}")`
      : undefined,
    padding: '1rem',
    marginBottom: '1rem',
    borderRadius: '8px',
    border: `2px solid ${theme.palette.lightGrey}`,
    boxShadow: '0px 10px 10px -5px rgba(0,0,0,0.5)',
    minHeight: '7rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'border-color .3s ease-in-out',
    '&:hover': {
      textDecoration: 'none !important',
      cursor: 'pointer',
      border: `2px solid ${theme.palette.primary.main} !important`
    },
    '& .__linkGroup': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }
  }
}))

const LinkSection = ({ name, backgroundImage, to, size }) => {
  const classes = useStyles({ backgroundImage })
  const history = useHistory()
  const onClick = useCallback(() => {
    history.push(to)
  }, [to, history])
  return (
    <Grid className={classes.section} item xs={size} onClick={onClick}>
      <Typography variant='h1'>{name}</Typography>
    </Grid>
  )
}

LinkSection.propTypes = {
  name: PropTypes.string.isRequired,
  backgroundImage: PropTypes.string,
  to: PropTypes.string,
  size: PropTypes.number
}

LinkSection.defaultProps = {
  size: 12
}

export default LinkSection
