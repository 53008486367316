import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import { TablePositioningContextProvider } from '../../StickySuperHeader/TablePositioningContext'

const useStyles = makeStyles((theme) => ({
  tableWrapper: {
    position: 'relative',
    minHeight: '100%'
  }
}))

const TableWrapper = ({ className, children }) => {
  const classes = useStyles()
  return (
    <TablePositioningContextProvider>
      <div className={clsx(classes.tableWrapper, className)}>
        {children}
      </div>
    </TablePositioningContextProvider>
  )
}

TableWrapper.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node
}

export default TableWrapper
