import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { FormProvider, useForm } from 'react-hook-form'
import { useGroupTypeSearch } from '../../../../../api/groups'
import { getGroupedTagsBody, getGroupTypesQuery, tagTypesQueryOptions } from '../../LevelTags/helpers'
import { useModifyAccountTagsMutation } from '../../../../../api/accounts'
import LevelTags from '../LevelTags'
import SectionHeader from '../../shared/SectionHeader'
import { ACCOUNTS_MEMBER_LEVEL_ID, ACCOUNT_FORM_NAMES } from '../helpers'
import { AccountFormSection, useAccountFormContext } from '../AccountFormProvider'
import SaveCancelFooter from '../../shared/SaveCancelFooter'
import EditButton from '../../shared/EditButton'

const ACCOUNT_TAGS = ACCOUNT_FORM_NAMES.tags
const query = getGroupTypesQuery({ memberLevelTypeId: ACCOUNTS_MEMBER_LEVEL_ID })

const useSubmitter = (form, account, onComplete) => {
  const { handleSubmit } = form
  const [processing, setProcessing] = useState(false)
  const { mutateAsync: saveAccountTags } = useModifyAccountTagsMutation()
  const onSubmit = useCallback(async (formData) => {
    try {
      setProcessing(true)
      const tags = getGroupedTagsBody(formData.tags, account.tags)
      const result = await saveAccountTags({ accountId: account.accountId, tags })
      onComplete(result)
    } catch (err) {
      console.error(err)
      alert('There was a problem saving your tag assignments.')
    } finally {
      setProcessing(false)
    }
  }, [saveAccountTags, setProcessing, onComplete, account])

  const submitter = useCallback(async (e) => {
    const onValid = async (form) => {
      await onSubmit(form)
    }
    const onInvalid = (errors) => {
      console.error(errors)
    }

    const handler = handleSubmit(onValid, onInvalid)
    await handler(e)
  }, [handleSubmit, onSubmit])

  return {
    submitter,
    processing
  }
}

const AccountTags = ({ loading }) => {
  const { data: groupOptions, isLoading } = useGroupTypeSearch(
    query,
    tagTypesQueryOptions
  )

  const { account, cancelEdit, ...accountForm } = useAccountFormContext()
  const sectionEditing = accountForm.sectionEditing('tags')

  const { reset, ...form } = useForm({
    defaultValues: {
      accountId: account.accountId,
      tags: account.tags || []
    }
  })

  const handleReset = useCallback(() => {
    reset({
      accountId: account.accountId,
      tags: account.tags || []
    })
  }, [reset, account])

  useEffect(() => {
    handleReset()
  }, [handleReset])

  const onCancel = useCallback(() => {
    handleReset()
    cancelEdit()
  }, [cancelEdit, handleReset])

  const { processing, submitter } = useSubmitter(form, account, cancelEdit)

  return (
    <FormProvider {...form}>
      <AccountFormSection section='tags'>
        <SectionHeader text='Tags'>
          <EditButton
            editing={sectionEditing}
            policy='admin_edit_acct_tags'
            onClick={() => {
              return accountForm.editSection({
                section: 'tags'
              })
            }}
          />
        </SectionHeader>
        <LevelTags
          name={ACCOUNT_TAGS}
          editMode={sectionEditing}
          isLoading={isLoading || loading}
          tagOptions={groupOptions}
        />
        {sectionEditing ? (
          <SaveCancelFooter
            saveText='Save Account Tags'
            onCancel={onCancel}
            onSave={submitter}
            processing={processing}
          />
        ) : null}
      </AccountFormSection>
    </FormProvider>
  )
}

AccountTags.propTypes = {
  loading: PropTypes.bool
}

export default AccountTags
