import React, { useMemo, useRef, useState } from 'react'
import { noop } from 'lodash'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { Box } from '@material-ui/core'
import RoundedModal from '../../../components/molecules/RoundedModal'
import SearchBarInput from '../../../components/molecules/SearchBar/SearchBarInput'
import { useSearchAccounts } from '../../../api/accounts'
import { useAppContext } from '../../../redux/slices/appContext'
import PresentationTable from '../../../components/organisms/PresentationTable'
import { ADMIN_ROUTES } from '../../../constants'
import EmptySection from '../../../components/atoms/EmptySection'
import { mapAccountsToGroups } from './helpers'
import { useColumns, useGetSearchQuery } from './hooks'

const useStyles = makeStyles(() => ({
  searchInput: {
    background: 'transparent',
    width: 'auto',
    minWidth: '60%'
  }
}))

const GroupTypeInfoModal = ({
  groupTypeId,
  title = 'More info',
  open = false,
  onClose = noop
}) => {
  const appContext = useAppContext()
  const classes = useStyles()
  const [searchText, setSearchText] = useState('')
  const tableRef = useRef(null)

  const searchQuery = useGetSearchQuery(groupTypeId, appContext.clientId)
  const columns = useColumns()

  const { data = [], isLoading, isFetching } = useSearchAccounts(searchQuery, {
    mapper: ({ data }) => mapAccountsToGroups(data),
    enabled: open && !!searchQuery
  })

  const filteredData = useMemo(() => {
    if (!searchText?.length) return data ?? []
    return data.map((group) => {
      const subRows = group.subRows.filter((row) => {
        return row.levelName.toLowerCase().includes(searchText.toLowerCase())
      })
      return { ...group, subRows }
    })
  }, [data, searchText])

  const defaultSort = useMemo(() => {
    if (!columns?.length) return []
    return [{ id: columns[0]?.id, order: 'desc' }]
  }, [columns])

  const onRowClick = (row) => {
    if (!row.original?.accountId) return
    return window.open(`${ADMIN_ROUTES.ACCOUNTS}/${row.original.accountId}`, '_blank')
  }

  return (
    <>
      <RoundedModal
        disablePortal={false}
        open={open}
        onClose={onClose}
        size='large'
        title={title}
        allowMaximize
      >
        {!data?.length && !isFetching ? (
          <EmptySection
            title='No Accounts for this group'
            description=''
          />
        ) : (
          <>
            <Box mb={2}>
              <SearchBarInput
                name={`group-type-search-${groupTypeId}`}
                placeholder='Search by Account'
                searchInputClassName={classes.searchInput}
                value={searchText}
                onChange={setSearchText}
                onClear={() => setSearchText('')}
              />
            </Box>

            <PresentationTable.Wrapper>
              <PresentationTable
                ref={tableRef}
                columns={columns}
                data={filteredData}
                expandable
                loading={isLoading}
                defaultSort={defaultSort}
                sortable
                onRowClick={onRowClick}
                defaultExpanded
              />
            </PresentationTable.Wrapper>
          </>
        )}

      </RoundedModal>
    </>
  )
}

GroupTypeInfoModal.propTypes = {
  groupTypeId: PropTypes.number,
  open: PropTypes.bool,
  title: PropTypes.string,
  onClose: PropTypes.func
}

export default GroupTypeInfoModal
