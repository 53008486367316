import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { TEXT_VARIANTS } from '../../constants'
import Text from './Text'

const useStyles = makeStyles((theme) => ({
  bar: ({ withDivider }) => ({
    ...(withDivider ? {
      width: '1px',
      backgroundColor: theme.palette.lightGrey
    } : {}),
    display: 'inline-block',
    margin: '0 12px',
    height: '20px',
    verticalAlign: 'middle'
  }),
  tuple: {
    display: 'flex'
  }
}))

const Tuple = ({
  customFontSize,
  title,
  content,
  withDivider
}) => {
  const classes = useStyles({ withDivider })

  return (
    <div className={classes.tuple}>
      <Text
        text={title}
        variant={TEXT_VARIANTS.h3}
        customFontSize={customFontSize}
      />
      <div className={classes.bar} />
      <Text
        text={content}
        variant={TEXT_VARIANTS.h3}
        customFontSize={customFontSize}
      />
    </div>
  )
}

Tuple.propTypes = {
  customFontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  title: PropTypes.string.isRequired,
  content: PropTypes.any.isRequired,
  withDivider: PropTypes.bool
}

Tuple.defaultProps = {
  customFontSize: '0.875rem',
  withDivider: false
}

export default React.memo(Tuple)
