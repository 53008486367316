import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useGetUserSsoActivity } from '../../../../../../api/users/sso'
import OperationalTable from '../../../../../organisms/OperationalTable'
import EmptySection from '../../../../../atoms/EmptySection'
import Icon from '../../../../../atoms/Icon'
import { useFormattingContext } from '../../../../../organisms/FormattingProvider/FormattingContext'

const defaultColumnConfig = {
  columns: [
    { Header: 'Activity Date / Time', accessor: 'activityDate', format: '&M/D/YYYY h:mm:ss a' },
    { Header: 'Relative to Now', id: 'relative', accessor: 'activityDate', format: 'ß' },
    { Header: 'Status', accessor: 'status', format: 'title' },
    { Header: 'Message', accessor: 'data.message', format: 'title' }
  ],
  defaultSort: [{ id: 'activityDate', desc: true }]
}

const useColumnConfig = () => {
  const { formatter } = useFormattingContext()
  return useMemo(() => {
    const columns = defaultColumnConfig.columns.map(c => ({
      ...c,
      Cell: ({ value, column }) => {
        return formatter(value, column.format)
      }
    }))
    const defaultSort = defaultColumnConfig.defaultSort

    return { columns, defaultSort }
  }, [formatter])
}

function ViewUserActivity ({ sso, userId }) {
  const { data, isLoading } = useGetUserSsoActivity(userId, sso.providerId)
  const columnConfig = useColumnConfig()

  return (
    <div style={{ position: 'relative', overflow: 'clip' }}>
      <OperationalTable.Wrapper>
        {(!data?.activities?.length && !isLoading) ? (
          <EmptySection
            header={<div style={{ marginBottom: '20px' }}><Icon name='info' customSize={60} /></div>}
            title='No Activity'
            description='In the last 30 days'
          />
        ) : (
          <OperationalTable
            mode='client'
            itemName='Records'
            data={data?.activities || []}
            loading={isLoading}
            total={data?.length}
            columns={columnConfig.columns}
            defaultSort={columnConfig.defaultSort}
          />
        )}
      </OperationalTable.Wrapper>
    </div>
  )
}

ViewUserActivity.propTypes = {
  userId: PropTypes.number,
  sso: PropTypes.shape({
    providerId: PropTypes.string
  })
}

export default ViewUserActivity
