import React, { useState } from 'react'
import PropTypes from 'prop-types'
import TextField from '@material-ui/core/TextField'
import { ButtonBase } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import noop from 'lodash/noop'
import { isEmpty } from 'lodash'
import ColorPickerDialog from '../atoms/ColorPickerDialog'
import useToggle from '../../hooks/useToggle'

export const DEFAULT_CONVERTER = 'rgba_hex'

export const converters = {
  rgba: c => `rgba(${c.rgb.r}, ${c.rgb.g}, ${c.rgb.b}, ${c.rgb.a})`,
  rgb: c => `rgb(${c.rgb.r}, ${c.rgb.g}, ${c.rgb.b})`,
  hex: c => c.hex,

  rgba_rgb: c => c.rgb.a === 1 ? converters.rgb(c) : converters.rgba(c),
  rgba_hex: c => c.rgb.a === 1 ? converters.hex(c) : converters.rgba(c)
}

const useStyles = makeStyles(() => ({
  colorButtonChild: ({ color }) => ({
    minWidth: '1.5rem',
    height: '1.5rem',
    width: '1.5rem',
    border: '0px solid rgb(118, 118, 118)',
    backgroundColor: color,
    borderRadius: '0.25rem'
  }),
  colorButton: {
    margin: '0 0.5rem 0.5rem 0',
    boxShadow: 'rgb(0 0 0 / 35%) 0px 0px 7px',
    borderRadius: '0.25rem'
  }
}))

const InputColorPicker = ({
  id,
  name,
  label,
  value,
  hintText,
  onChange,
  placeholder,
  defaultValue,
  colorConverter,
  TextFieldProps,
  floatingLabelText,
  ...custom
}) => {
  const [showPicker, , toggleShowPickerOn, toggleShowPickerOff] = useToggle()
  const [internalValue, setValue] = useState(defaultValue)
  const classes = useStyles({ color: internalValue || value })

  return (
    <>
      <TextField
        name={name}
        id={id}
        label={floatingLabelText || label}
        placeholder={hintText || placeholder}
        onClick={toggleShowPickerOn}
        value={value === undefined ? internalValue : value}
        onChange={(e) => {
          setValue(e.target.value)
          onChange(e.target.value)
        }}
        {...TextFieldProps}
        {...custom}
        InputProps={{
          ...(!isEmpty(custom?.InputProps) ? custom.InputProps : {}),
          startAdornment: (
            <ButtonBase focusRipple className={classes.colorButton}>
              <span>
                <div className={classes.colorButtonChild} />
              </span>
            </ButtonBase>
          ),
          style: { color: value === undefined ? internalValue : value }
        }}
      />
      {showPicker && (
        <ColorPickerDialog
          value={value === undefined ? internalValue : value}
          onClick={() => {
            toggleShowPickerOff()
            onChange(value)
          }}
          onChange={(c) => {
            const newValue = converters[colorConverter](c)
            setValue(newValue)
            onChange(newValue)
          }}
        />
      )}
    </>
  )
}

InputColorPicker.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  colorConverter: PropTypes.oneOf(Object.keys(converters)),
  name: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  hintText: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  floatingLabelText: PropTypes.string,
  TextFieldProps: PropTypes.shape(TextField.propTypes),
  defaultValue: PropTypes.string
}

InputColorPicker.defaultProps = {
  colorConverter: DEFAULT_CONVERTER,
  onChange: noop
}

export default InputColorPicker
