import React, { useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { animated, useSpring } from 'react-spring'
import { makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import AbundanceEngineView from '../../../../abundanceEngine/AbundanceEngineView'
import { useAppContext, useSetAppContext } from '../../../../redux/slices/appContext'
import { useDashboardSettings } from '../ClientDashboardContextProvider'
import { useDashboardNavigation } from '../NavigationProvider/context'
import { useExportContext } from '../../../molecules/ExportButton/ExportProvider'
import ActionButtons from './ActionButtons'
import CurrentClient from './CurrentClient'

const useStyles = makeStyles((theme) => ({
  container: ({ backgroundImage, expanded }) => {
    const background = expanded
      ? {}
      : {
        backgroundImage: `url(${backgroundImage})` || 'url("RiverBack_50.png")',
        backgroundPosition: 'bottom center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover'
      }
    return ({
      backgroundColor: expanded ? theme.palette.white : theme.palette.background.paper,
      margin: 'inherit',
      padding: expanded ? '3.8rem 0 0' : '3.8rem 2.5rem 0rem',
      position: 'absolute',
      width: '100%',
      height: '100%',
      minHeight: '100%',
      top: 0,
      transition: 'padding .1s ease-in-out, border-color .1s ease-in-out, background-color .2s ease-in-out',
      ...background
    })
  },
  paper: ({ expanded }) => ({
    height: expanded ? '100%' : 'calc(100% - 1rem)',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    border: `2px solid ${expanded ? 'transparent' : 'rgba(33, 41, 69, 0.3)'}`,
    borderRadius: expanded ? '0' : '0.5rem'
  }),
  viewWrapper: {
    marginTop: ({ expanded }) => expanded ? '.8rem' : 0,
    height: '100%',
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: '1em',
      height: '1em',
      backgroundColor: 'transparent'
    },
    '&::-webkit-scrollbar-track': {
      borderRadius: '20px',
      backgroundColor: 'transparent'
    },
    '&::-webkit-scrollbar-thumb': {
      visibility: 'visible',
      borderRadius: '20px',
      backgroundColor: theme.palette.gray.dark,
      border: '3px solid white'
    }
  },
  [theme.breakpoints.down('xs')]: {
    container: {
      padding: '50px 0 0 !important'
    },
    paper: {
      border: 'none !important',
      borderRadius: '0  !important'
    }
  }
}))

const SubViewRoute = () => {
  const { settings, idle, expanded, onExpandToggle, items } = useDashboardSettings()
  const navMan = useDashboardNavigation()
  const { backgroundImage, overContentOnDesktop = true } = settings

  const classes = useStyles({ backgroundImage, expanded })
  const { view, subView } = useParams()
  const viewPath = useMemo(() => {
    if (typeof subView !== 'undefined') {
      return subView
    }
    if (typeof view !== 'undefined') {
      return view
    }
    return 'not-found'
  }, [view, subView])

  const { isFullViewOpen } = useAppContext()
  const setAppContext = useSetAppContext()
  useEffect(() => {
    setAppContext({ isFullViewOpen: isFullViewOpen })
    return () => setAppContext({ isFullViewOpen: false })
  }, [isFullViewOpen, setAppContext])

  useEffect(() => {
    setAppContext({ overContentOnDesktop, isSidebarOpen: false })
    return () => setAppContext({ overContentOnDesktop })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewPath, overContentOnDesktop])

  const { configureExport } = useExportContext()

  useEffect(() => {
    const currentViewItem = items.find(({ view }) => view === viewPath)
    if (currentViewItem?.showExportButton) {
      configureExport(currentViewItem?.showExportButton)
    }
  }, [items, viewPath, configureExport])

  const pageSprings = useSpring({
    from: { left: 100, opacity: 0 },
    to: { left: 0, opacity: 1 }
  })

  return (
    <div className={classes.container}>
      <CurrentClient />
      <animated.div className={clsx(classes.paper, 'size-container')} style={pageSprings}>
        <ActionButtons
          idle={idle}
          expanded={expanded}
          onClose={() => navMan.goHome()}
          onExpandToggle={onExpandToggle}
          className={classes.actionButtons}
        />
        <div className={classes.viewWrapper}>
          <AbundanceEngineView
            viewPath={viewPath}
            fallbackViewPath={viewPath}
          />
        </div>
      </animated.div>
    </div>
  )
}

export default SubViewRoute
