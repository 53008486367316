import { useMutation, useQueryClient } from '@tanstack/react-query'
import { removeBookmark } from '../../../../service'
import { QUERY_KEYS } from '../../../../api/queryKeys'
import { removeByIndex } from '../../../../utils'

const removeBookmarkWrapper = async (bookmarkId) => {
  return await removeBookmark(bookmarkId)
}

function useRemoveBookmarkMutation () {
  const queryClient = useQueryClient()

  const mutation = useMutation({
    mutationFn: removeBookmarkWrapper,
    // When mutate is called:
    onMutate: async (bookmarkId) => {
      // Cancel any outgoing re-fetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({
        queryKey: QUERY_KEYS.bookmarksMultiple
      })
      await queryClient.cancelQueries({
        queryKey: QUERY_KEYS.bookmarks
      })

      // Snapshot the previous value
      const prevBookmarksMultiple = queryClient.getQueryData(
        [QUERY_KEYS.bookmarksMultiple],
        { exact: false }
      )
      const prevBookmarks = queryClient.getQueryData(
        [QUERY_KEYS.bookmarks], {
          exact: false
        })

      // Remove the value
      const bookmarkIndex = prevBookmarks.findIndex(
        ({ bookmarkId: _bookmarkId }) => bookmarkId
      )
      let bookmarks = prevBookmarks
      if (bookmarkIndex !== -1) {
        bookmarks = removeByIndex(bookmarkIndex, prevBookmarks)
      }

      const bookmarkMultipleIndex = prevBookmarksMultiple.findIndex(
        ({ bookmarkId: _bookmarkId }) => bookmarkId
      )
      let bookmarksMultiple
      if (bookmarkMultipleIndex !== -1) {
        bookmarksMultiple = removeByIndex(
          bookmarkMultipleIndex,
          prevBookmarksMultiple
        )
      }

      // Modify cache to reflect this optimistic update
      queryClient.setQueryData([QUERY_KEYS.bookmarks], bookmarks)
      queryClient.setQueryData([QUERY_KEYS.bookmarksMultiple], bookmarksMultiple)

      // Return a snapshot so we can rollback in case of failure
      return { prevBookmarks, prevBookmarksMultiple }
    },
    // If the mutation fails, use the context we returned above
    onError: (error, newBookmark, { prevBookmarks, prevBookmarksMultiple }) => {
      // Rollback the changes using the snapshot
      queryClient.setQueryData(
        [QUERY_KEYS.bookmarksMultiple],
        prevBookmarksMultiple
      )
      queryClient.setQueryData([QUERY_KEYS.bookmarks], prevBookmarks)
      console.error(error, newBookmark)
    },
    onSuccess () {
      // Refetch or invalidate related queries
      queryClient.invalidateQueries({
        queryKey: QUERY_KEYS.bookmarksMultiple
      })
      queryClient.invalidateQueries({
        queryKey: QUERY_KEYS.bookmarks
      })
    }
  })

  return mutation
}

export default useRemoveBookmarkMutation
