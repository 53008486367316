import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles, IconButton } from '@material-ui/core'
import { ICON_NAMES } from '../../../../constants'
import Icon from '../../../atoms/Icon'

const useStyles = makeStyles(() => ({
  itemAction: {
    display: 'flex',
    maxWidth: '2.5rem',
    maxHeight: '2.5rem',
    alignItems: 'center'
  }
}))

const RemoveButton = ({ onClick }) => {
  const classes = useStyles()
  return (
    <IconButton className={classes.itemAction} onClick={onClick}>
      <Icon
        name={ICON_NAMES.removeCircle}
        color='#D44333'
        customSize='1.25rem'
      />
    </IconButton>
  )
}

RemoveButton.propTypes = {
  onClick: PropTypes.func
}

export default RemoveButton
