import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import ScopeDisplay from './ScopeDisplay'

const useStyles = makeStyles((theme) => ({
  scopePicker: {
    flex: '1 0 auto',
    minWidth: '300px',
    marginRight: '10px'
  }
}))

const ScopePicker = ({ headingLabel }) => {
  const classes = useStyles()

  return (
    <div className={classes.scopePicker}>
      <ScopeDisplay headingLabel={headingLabel} />
    </div>
  )
}

ScopePicker.propTypes = {
  headingLabel: PropTypes.string
}

export default ScopePicker
