import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Box, makeStyles } from '@material-ui/core'
import isEmpty from 'lodash/isEmpty'
import { useHistory } from 'react-router-dom'
import { useSearchVaultDocuments } from '../../../../api/documentVault'
import TableSkeleton from '../../../atoms/TableSkeleton'
import AdvisorHomeEmptySection from '../AdvisorHomeEmptySection'
import { ICON_NAMES } from '../../../../constants'
import { getDocument } from '../../../../service'
import { downloadDocument } from '../../../molecules/AttachmentItem'
import DocumentItem from './DocumentItem'

const useStyles = makeStyles(() => ({
  container: {
    gap: '0.5rem',
    display: 'flex',
    padding: '0.5rem',
    borderRadius: '0.75rem',
    flexDirection: 'column'
  }
}))

const TopDocuments = ({
  limit,
  queryFilters,
  emptyStateLabel,
  documentsViewPath
}) => {
  const classes = useStyles()
  const history = useHistory()

  const { query, queryOptions } = useMemo(() => {
    return {
      query: {
        skip: 0,
        take: limit,
        includes: {
          owner: true,
          client: true
        },
        ...(!isEmpty(queryFilters) ? { filters: queryFilters } : {})
      },
      queryOptions: {
        mapper: ({ data }) => data ?? []
      }
    }
  }, [limit, queryFilters])

  const { data, isLoading } = useSearchVaultDocuments(query, queryOptions)

  const onClickDocument = useCallback(
    ({ document, isPreview = false, shouldDownloadDoc = true }) => {
      const { id: documentId, client } = document
      return async () => {
        const { data: document } = await getDocument(
          client.clientId,
          documentId,
          isPreview
        )
        if (!shouldDownloadDoc) return document
        downloadDocument(document)
      }
    },
    []
  )

  const getDocumentOptions = useCallback(
    (document) => {
      return [
        {
          iconName: ICON_NAMES.download,
          label: 'Download',
          onClick: onClickDocument({ document })
        },
        {
          iconName: ICON_NAMES.visibility,
          label: 'Preview document',
          onClick: async () => {
            const { downloadUrl } = await onClickDocument({
              document,
              isPreview: true,
              shouldDownloadDoc: false
            })()
            downloadUrl && window.open(downloadUrl)
          }
        },
        {
          iconName: ICON_NAMES.goToDocument,
          label: 'Go to doc vault',
          onClick: () => {
            const clientId = document?.client?.clientId
            history.push(`/inbound-redirect${documentsViewPath}?client=${clientId}`)
          }
        }
      ]
    },
    [
      history,
      onClickDocument,
      documentsViewPath
    ]
  )

  if (isEmpty(data) && !isLoading) {
    return (
      <AdvisorHomeEmptySection
        icon={ICON_NAMES.paperGlassMagnifier}
        title='No data available'
        description={emptyStateLabel}
        borderedContent
      />
    )
  }

  if (isLoading) {
    return (
      <Box p={2}>
        <TableSkeleton
          firstColumnWithAvatar
          columns={1}
          rows={5}
          showHeader={false}
          rowHeight='2.5rem'
        />
      </Box>
    )
  }

  return (
    <div className={classes.container}>
      {data?.map((document) => {
        return (
          <DocumentItem
            key={document.id}
            document={document}
            options={getDocumentOptions(document)}
          />
        )
      })}
    </div>
  )
}

TopDocuments.propTypes = {
  limit: PropTypes.number,
  queryFilters: PropTypes.object,
  emptyStateLabel: PropTypes.string,
  documentsViewPath: PropTypes.string
}

TopDocuments.defaultProps = {
  limit: 5,
  emptyStateLabel: '',
  documentsViewPath: '#',
  queryFilters: {}
}

export default TopDocuments
