import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import Icon from '../../atoms/Icon'

const useStyles = makeStyles((theme) => ({
  errorView: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.layout.gap.loose,
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: theme.typography.fontSizes.h4,
    minHeight: '300px'
  }
}))

function ErrorView ({ error }) {
  const classes = useStyles()

  return (
    <div className={classes.errorView}>
      <div><Icon name='warning' customSize={50} /></div>
      <div>{error?.toString()}</div>
    </div>
  )
}

ErrorView.propTypes = {
  error: PropTypes.string
}

ErrorView.defaultProps = {
  error: 'There was an error loading the view.'
}

export default ErrorView
