import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import { FEE_CALC_TYPES } from '../../../../../constants'
import { numberToBPS, numberToUSD, pluralize } from '../../../../../utils'
import { useFormattingContext } from '../../../../organisms/FormattingProvider/FormattingContext'

const useStyles = makeStyles((theme) => ({
  feeScheduleTiers: {
    backgroundColor: theme.palette.gray.dark,
    border: `1px solid ${theme.palette.gray.darker}`,
    padding: '10px',
    minWidth: '200px',
    textTransform: 'uppercase'
  },
  detail: {
    width: '100%',
    '& td': {
      padding: '2px 10px'
    }
  },
  value: {
    fontWeight: theme.typography.weights.semibold
  },
  description: {
    fontWeight: theme.typography.weights.light
  }
}))

export function feeLimitsDetailFormatter (formatter, detail, format = '0A') {
  return formatter(detail.lowerLimit, format) + (detail.upperLimit ? ` - ${formatter(detail.upperLimit, format)}` : '+')
}

function FeeScheduleTiers ({ feeSchedule }) {
  const classes = useStyles()
  const { formatter } = useFormattingContext()
  const details = useMemo(() => {
    if (!feeSchedule?.details) {
      return []
    }
    return feeSchedule.details.map(detail => {
      const showTier = detail.calcTypeId === FEE_CALC_TYPES.TIERED_SCHEDULE || detail.calcTypeId === FEE_CALC_TYPES.FLOATING_RATE
      const isDollarRate = detail.calcTypeId === FEE_CALC_TYPES.FLAT_DOLLAR
      const rateNumber = isDollarRate ? detail.feeRate : numberToBPS(detail.feeRate)
      const denomination = detail.denomination
      const feeDescription = isDollarRate
        ? `${numberToUSD(rateNumber)} ${pluralize(rateNumber, denomination, 's')}`
        : `${rateNumber} ${denomination}`
      const formatted = feeLimitsDetailFormatter(formatter, detail)

      return {
        key: detail.feeScheduleDetailId,
        showTier,
        rateNumber,
        denomination,
        feeDescription,
        formatted
      }
    })
  }, [feeSchedule, formatter])

  if (!feeSchedule) return null

  return (
    <div className={classes.feeScheduleTiers}>
      <table className={classes.detail}>
        <tbody>
          {details.map(d => (
            <tr key={d.key}>
              <td className={classes.value}>{d.formatted}</td>
              <td>&rarr;</td>
              <td className={classes.description}>{d.feeDescription}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

FeeScheduleTiers.propTypes = {
  feeSchedule: PropTypes.shape({
    details: PropTypes.arrayOf(PropTypes.shape({
      lowerLimit: PropTypes.number,
      upperLimit: PropTypes.number,
      feeRate: PropTypes.number,
      calcType: PropTypes.string,
      calcTypeId: PropTypes.number,
      feeScheduleDetailId: PropTypes.number,
      denomination: PropTypes.string,
      isSingleTier: PropTypes.bool
    }))
  })
}

export default FeeScheduleTiers
