import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import ReportResultsContainer from './ReportResultContainer'
import ReportFiltersProvider from './ReportFiltersProvider/ReportFiltersProvider'
import { ReportContext, useReportResult } from './ReportContext'

const ReportResults = ({
  columnConfig,
  tableConfigOptions,
  useSymbolInsteadOfZero,
  showCustodianFilter
}) => {
  const reportResult = useReportResult({ showCustodianFilter })
  const queryParams = useParams()

  const contextValue = useMemo(() => {
    return {
      reportResult,
      defaultColumnConfig: columnConfig,
      useSymbolInsteadOfZero,
      tableConfigOptions
    }
  }, [reportResult, columnConfig, tableConfigOptions, useSymbolInsteadOfZero])

  return (
    <ReportContext.Provider value={contextValue}>
      <ReportFiltersProvider>
        <ReportResultsContainer
          resultOutputId={queryParams?.resultOutputId}
          {...reportResult}
        />
      </ReportFiltersProvider>
    </ReportContext.Provider>
  )
}

ReportResults.propTypes = {
  columnConfig: PropTypes.object,
  tableConfigOptions: PropTypes.object,
  useSymbolInsteadOfZero: PropTypes.string,
  showCustodianFilter: PropTypes.bool
}

export default ReportResults
