import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import { makeStyles } from '@material-ui/core'
import WithSkeleton from '../../../molecules/WithSkeleton'
import Text from '../../../atoms/Text'
import AvailableDatePicker from '../../../molecules/AvailableDatePicker'
import { INTERNAL_DATE_FORMAT, SKELETON_VARIANTS, TEXT_VARIANTS } from '../../../../constants'
import { useSetViewContext, useViewContext } from '../../../../redux/slices/viewContext'
import { useAppContext, useSetAppContext } from '../../../../redux/slices/appContext'
import { useCurrentClient } from '../../../../api/clients'
import FastAvatar from '../../../molecules/FastAvatar'
import EditClientInfoButton from '../../ClientInfoModal/EditClientInfoButton'
import { clientAccountSettingsProp } from '../ClientDashboardContextProvider'
import ClientAccounts from './ClientAccounts'
import ClientConversation from './ClientConversation'
import ClientMetric from './metrics'

const useStyles = makeStyles((theme) => ({
  mainInfo: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  avatar: {
    marginTop: '32px',
    marginBottom: '12px'
  },
  familyName: {
    marginBottom: '12px',
    textAlign: 'center'
  },
  wealthMission: {
    textAlign: 'center',
    width: '100%',
    maxWidth: '20rem',
    marginBottom: '18px'
  },
  middleContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center'
  },
  availableDate: {
    marginTop: '10px',
    width: '100%',
    cursor: 'pointer'
  },
  centralChildren: {
    position: 'absolute',
    left: '50%',
    transform: 'translate3d(-50%, -50%, 0)',
    top: '50%',
    maxWidth: '500px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    opacity: ({ lowlighted, isLoading }) => lowlighted || isLoading ? 0.3 : 1,
    transition: ({ lowlighted, isLoading }) => lowlighted || isLoading
      ? 'opacity 0.5s ease-in-out 0s'
      : 'opacity 0.8s ease-in-out',
    '& .__clientMetricValue': {
      margin: '0px 24px',
      textAlign: 'center'
    },
    ...(theme.layout.wheelInfo || {})
  },
  [theme.breakpoints.down('xs')]: {
    centralChildren: {
      position: 'relative',
      top: 'auto',
      left: 'auto',
      transform: 'none',
      maxWidth: '100%'
    }
  }
}))

const useDates = () => {
  const appContext = useAppContext()
  const setAppContext = useSetAppContext()
  const viewContext = useViewContext()
  const setViewContext = useSetViewContext()
  const onAvailableDateChange = useCallback((date) => {
    setAppContext({
      availableDates: {
        ...appContext.availableDates,
        mainDate: dayjs(date).format(INTERNAL_DATE_FORMAT)
      }
    })
    setViewContext({ mainDate: dayjs(date).format(INTERNAL_DATE_FORMAT) })
  }, [appContext.availableDates, setAppContext, setViewContext])

  return [viewContext, onAvailableDateChange]
}

const useFamilyName = (client) => {
  return useMemo(() => {
    if (!client?.longName) return 'Please select a Client'
    if (client?.longName.length > 50) {
      return client?.longName.substring(0, client?.longName.lastIndexOf(' '))
    } else {
      return client?.longName
    }
  }, [client])
}

const useDescription = (client) => {
  return useMemo(() => {
    return client?.description
  }, [client])
}

const ClientInformation = ({
  showRetirementIncome,
  metricConfig,
  lowlighted,
  conversations,
  clientAccountSettings
}) => {
  const { data: client, isLoading } = useCurrentClient()
  const classes = useStyles({ lowlighted, isLoading: !client?.longName })
  const familyName = useFamilyName(client)
  const description = useDescription(client)
  const [viewContext, onAvailableDateChange] = useDates()

  return (
    <div className={classes.centralChildren}>
      <EditClientInfoButton client={client} className={classes.mainInfo}>
        <div className={classes.avatar}>
          <FastAvatar
            isLoading={isLoading}
            avatarUrl={client?.avatarUrl}
            abbreviation={client?.clientAbbreviation}
            size='xl'
          />
        </div>
        <div className={classes.familyName}>
          <WithSkeleton
            showSkeleton={isLoading}
            skeletonWidth='250px'
            skeletonHeight='45px'
            skeletonVariant={SKELETON_VARIANTS.rect}
          >
            <Text
              variant={TEXT_VARIANTS.h1}
              text={familyName}
              customFontSize='min(42px, 4.5vh)'
            />
          </WithSkeleton>
        </div>
        <div className={classes.wealthMission}>
          <WithSkeleton
            showSkeleton={isLoading}
            skeletonWidth='250px'
            skeletonHeight='30px'
            skeletonVariant={SKELETON_VARIANTS.rect}
          >
            <Text
              variant={TEXT_VARIANTS.body1}
              text={description}
            />
          </WithSkeleton>
        </div>
      </EditClientInfoButton>
      <div className={classes.middleContainer}>
        <ClientMetric {...metricConfig} />
        {conversations ? <ClientConversation /> : null}
        <ClientAccounts {...clientAccountSettings ?? {}} />
      </div>
      <div className={classes.availableDate}>
        <AvailableDatePicker
          value={viewContext.mainDate}
          onChange={onAvailableDateChange}
          format='[As of:] MMMM DD, YYYY'
          type='date'
          extraStyles={{ fontSize: '0.875rem', width: '100%', cursor: 'pointer' }}
          disableFuture
        />
      </div>
    </div>
  )
}

ClientInformation.propTypes = {
  showRetirementIncome: PropTypes.bool,
  metricConfig: PropTypes.object,
  lowlighted: PropTypes.bool,
  conversations: PropTypes.bool,
  clientAccountSettings: clientAccountSettingsProp
}

ClientInformation.defaultProps = {
  metricConfig: {},
  showRetirementIncome: true,
  lowlighted: false,
  conversations: false
}

export default ClientInformation
