import { useRef, useState } from 'react'

export default function useBoolean (initialValue = false) {
  const [value, setValue] = useState(initialValue)

  const updateValue = useRef({
    toggle: () => setValue(oldValue => !oldValue),
    on: () => setValue(true),
    off: () => setValue(false)
  })

  return [value, updateValue.current]
}
