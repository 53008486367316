const removeGoalId = (state, payload) => {
  const payloadArray = [payload].flat(2)
  return state.filter((activeGoal) => !payloadArray.includes(activeGoal))
}

const addGoalIds = (state, payload) => {
  const payloadArray = [payload].flat(2)
  return [...new Set([...payloadArray, ...state])]
}

export function ActiveGoalIdsReducer (state, action) {
  switch (action.type) {
    case 'TOGGLE_GOAL_IDS':
      if (action.forceState) {
        if (action.forceState === 'ACTIVE') return addGoalIds(state, action.payload)
        if (action.forceState === 'INACTIVE') return removeGoalId(state, action.payload)
      }

      // check if all values from action.payload exist in state array
      if (action.payload.every((goalId) => state.includes(goalId))) {
        return removeGoalId(state, action.payload)
      }
      return addGoalIds(state, action.payload)

    case 'TOGGLE_GOAL_ID':
      if (state.includes(action.payload)) {
        return removeGoalId(state, action.payload)
      }
      return addGoalIds(state, action.payload)

    case 'ADD_GOAL_ID':
      return addGoalIds(state, action.payload)

    default:
      return state
  }
}
