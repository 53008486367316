import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { TabContext, useTabGroupValues } from './tabContext'

const TabGroup = ({ children, tabOptions, onChange }) => {
  const tabContext = useTabGroupValues(tabOptions)

  useEffect(() => {
    onChange && onChange(tabContext.selectedValue)
  }, [onChange, tabContext.selectedValue])

  return (
    <TabContext.Provider value={tabContext}>
      {children}
    </TabContext.Provider>
  )
}

TabGroup.propTypes = {
  tabOptions: PropTypes.object,
  children: PropTypes.node,
  onChange: PropTypes.func
}

export default TabGroup
