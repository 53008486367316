import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@material-ui/core'
import { useSearchAssignedPeople } from '../../../api/people'
import { useAppContext } from '../../../redux/slices/appContext'
import FieldAccessor, { useStyles } from './FieldAccessor'

const mapPersonTypeName = (person) => person?.personType?.longName ? ` - ${person.personType.longName}` : ''

function PeopleFields ({ fieldConfig, spacing, style }) {
  const classes = useStyles()
  const { clientId } = useAppContext()
  const query = useMemo(() => ({
    filters: {
      assignedToClientIds: [clientId]
    },
    includes: {
      personTypes: true
    }
  }), [clientId])
  const { data, isLoading } = useSearchAssignedPeople(query)
  const fieldMap = useMemo(() => {
    return Object.entries(fieldConfig).map(([key, value]) => {
      return { key, xs: 12, ...value }
    })
  }, [fieldConfig])

  if (isLoading) {
    return null
  }

  return (
    <div style={style}>
      {(data?.data).map(person => (
        <Grid container className={classes.fields} spacing={spacing} key={person.personId}>
          <Grid item xs={12}>
            <div>{person.firstName} {person.lastName} {mapPersonTypeName(person)}</div>
          </Grid>
          {fieldMap.map(item => (
            <FieldAccessor {...item} key={item.key} item={person} />
          ))}
        </Grid>
      ))}
    </div>
  )
}

PeopleFields.propTypes = {
  fieldConfig: PropTypes.object,
  spacing: PropTypes.number,
  style: PropTypes.object
}

PeopleFields.defaultProps = {
  spacing: 3
}

export default PeopleFields
