/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import { Typography, Slider, Grid, InputAdornment, InputBase, Checkbox, TextField } from '@material-ui/core'
import { ResponsiveLine } from '@nivo/line'
import DefaultLayout from '../../../abundanceEngine/layouts/DefaultLayout'
import Text from '../../atoms/Text'
import { useGetGroupedCoreData } from '../../../api/coreData'
import { useAppContext } from '../../../redux/slices/appContext'
import PresentationTable from '../PresentationTable'
import { useFormattingContext } from '../FormattingProvider/FormattingContext'

import config from '../../../config'
import ResearchLine from './ResearchLine'
import { useColumns } from './columnConfig'

const useStyles = makeStyles({
  root: {
    width: 250
  },
  inputTop: {
    fontSize: '24px',
    fontWeight: 700,
    width: '160px',
    paddingBottom: '0px'
  },
  goalsHeader: {
    margin: '24px 0px 24px 0px',
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  headerWrapper: {
    margin: '6px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  filterWrapper: {
    margin: '6px',
    display: 'flex'
  },
  filterItem: {
    margin: '6px',
    padding: '6px 12px',
    border: '2px solid #212945',
    backgroundColor: '#212945',
    color: '#FFFFFF',
    '&:hover': {
      color: '#212945',
      backgroundColor: '#FFFFFF',
      cursor: 'pointer'
    }
  },
  dateWrapper: {
    margin: '6px',
    display: 'flex'
  },
  dateChangeItem: {
    padding: '0px 12px',
    color: '#3399FF',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  monthChangeItem: {
    padding: '0px 4px',
    color: '#3399FF',
    '&:hover': {
      cursor: 'pointer'
    }
  }
})

const ResearchHome = () => {
  const classes = useStyles()

  const { firmId, clientId: currentClientId, availableDates } = useAppContext()
  console.log('useAppContext()', firmId, useAppContext())

  const defaultLevelFilters = {
    dateType: 'day',
    clientIds: [
      currentClientId
    ],
    levelTypes: [
      'client',
      'account'
    ],
    calcType: 'performance'
  }

  const defaultDateFormat = 'YYYY-MM-DD'
  // const initialStartDate =
  const initialLevelFilters = { ...defaultLevelFilters }

  const [months, setMonths] = useState(6)
  const [startDate, setStartDate] = useState(dayjs(availableDates.max).subtract(months, 'month').format(defaultDateFormat))
  const [endDate, setEndDate] = useState(availableDates.max)
  const [levelFilters, setLevelFilters] = useState(initialLevelFilters)

  const queryLine = useMemo(() => ({
    levelFilters: {
      ...levelFilters,
      clientIds: [currentClientId]
    },
    dateRange: {
      startDate,
      endDate
    }
  }), [startDate, endDate, levelFilters, currentClientId])

  const queryLineData = useGetGroupedCoreData(queryLine)
  const queryDetailData = useGetGroupedCoreData({
    ...queryLine,
    levelFilters: {
      ...queryLine.levelFilters,
      levelTypes: ['asset'],
      dateType: 'all'
    }
  })

  const modifiedLevelData = useMemo(() => {
    if (queryDetailData.isLoading) return []
    if (queryDetailData.error) return []
    return queryDetailData.data.map((row) => ({
      ...row,
      levelDisplay: `${row.assetLongName} (${row.Symbol && row.Symbol !== '' ? row.Symbol : row.CUSIP})`,
      firmId
    }))
  }, [firmId, queryDetailData.isLoading, queryDetailData.error, queryDetailData.data])

  const modifiedDetailData = useMemo(() => {
    if (queryLineData.isLoading) return []
    if (queryLineData.error) return []
    return queryLineData.data.map((row) => ({
      ...row,
      firmId
    }))
  }, [firmId, queryLineData.isLoading, queryLineData.error, queryLineData.data])

  const LineContainer = ({ isLoading, error, data }) => {
    if (isLoading) return <div> Loading </div>
    if (error) return <div> Error {JSON.stringify(error)} </div>

    const accountData = mapClientAccounts(data)
    const lineData = accountData.map(accountData =>
      formatDataset(`${accountData.accountId}`, '#21294533', 2, accountData.data, false, 'cumulativeReturn')
    )

    return <ResearchLine data={lineData} clickSeries={seriesClick} />
  }
  LineContainer.propTypes = {
    isLoading: PropTypes.bool,
    error: PropTypes.any,
    data: PropTypes.array
  }

  const LevelDetail = ({ isLoading, error, data }) => {
    if (isLoading) return <div> Loading </div>
    if (error) return <div> Error {JSON.stringify(error)} </div>

    return <AssetDetailTable isLoading={isLoading} data={data} />
  }
  LevelDetail.propTypes = {
    isLoading: PropTypes.bool,
    error: PropTypes.any,
    data: PropTypes.array
  }

  const AssetDetailTable = ({ isLoading, data }) => {
    const { formatter } = useFormattingContext()
    const { columns, defaultSort } = useColumns({
      dataType: 'assetDetail',
      formatter
    })

    // console.log('AssetDetailTable data', data)

    return (
      <PresentationTable
        columns={columns}
        data={data}
        loading={isLoading}
        onRowClick={levelRowClick}
        defaultSort={defaultSort}
        sortable
      />
    )
  }

  AssetDetailTable.propTypes = {
    isLoading: PropTypes.bool,
    data: PropTypes.array
  }

  const AccountDetailTable = ({ isLoading, error, data }) => {
    const { formatter } = useFormattingContext()
    const { columns, defaultSort } = useColumns({
      dataType: 'accountDetail',
      formatter
    })

    if (isLoading) return <div> Loading </div>
    if (error) return <div> Error {JSON.stringify(error)} </div>

    const accountData = mapClientAccounts(data)

    return (
      <div>
        {accountData.map(({ accountId, data }) => (
          <div key={`accountDiv${accountId}`}>
            <div style={{ fontSize: '1rem', margin: '2rem 0 1rem 0' }}>Account {accountId}</div>
            <PresentationTable
              key={`detailTable${accountId}`}
              columns={columns}
              data={data}
              loading={isLoading}
              defaultSort={defaultSort}
              onRowClick={detailRowClick}
              sortable
            />
          </div>
        ))}
      </div>
    )
  }
  AccountDetailTable.propTypes = {
    isLoading: PropTypes.bool,
    error: PropTypes.any,
    data: PropTypes.array
  }

  const mapClientAccounts = (data) => {
    const groupedData = data.reduce((acc, item) => {
      acc[item.accountId] = acc[item.accountId] || []
      acc[item.accountId].push(item)
      return acc
    }, {})

    return Object.entries(groupedData).map(([accountId, data]) => ({ accountId, data }))
  }

  const seriesClick = (data) => {
    setLevelFilters(previous => ({ ...previous, accountIds: [data.serieId || data.id] }))
    try {
      const pointDate = dayjs(data.data.xFormatted).format(defaultDateFormat)
      setStartDate(dayjs(pointDate).add(-5, 'day').format(defaultDateFormat))
      setEndDate(dayjs(pointDate).add(5, 'day').format(defaultDateFormat))
    } catch (error) {
      console.log('seriesClick error', error)
    }
  }

  const levelRowClick = (row) => {
    setLevelFilters(previous => ({ ...previous, assetIds: [row.original.assetId] }))
  }

  const detailRowClick = (row) => {
    const { original: { accountId } } = row
    const valueDate = dayjs.utc(row.original.maxDate).format('YYYY-MM-DD')
    window.open(`${config.mammothUiUrl}/batchData/${firmId}/${accountId}/${valueDate}`, '_blank')
  }

  const handleAccountFilterClick = (event) => {
    try {
      setLevelFilters(prevState => ({
        ...prevState,
        accountIds: prevState.accountIds.filter(id => id * 1 !== parseInt(event.target.id))
      }))
    } catch (error) {
      console.log('handleAccountFilterClick error', error)
    }
  }

  const handleAssetFilterClick = (event) => {
    try {
      setLevelFilters(prevState => ({
        ...prevState,
        assetIds: prevState.assetIds.filter(id => id * 1 !== parseInt(event.target.id))
      }))
    } catch (error) {
      console.log('handleAssetFilterClick error', error)
    }
  }

  const handleMonthClick = (event) => {
    const newMonths = event.target.id
    setMonths(event.target.id)
    setStartDate(dayjs(endDate).add(newMonths * -1, 'month').format(defaultDateFormat))
  }

  const moveDates = (months) => {
    setEndDate(prevDate => dayjs(prevDate).add(months, 'month').format(defaultDateFormat))
    setStartDate(prevDate => dayjs(prevDate).add(months, 'month').format(defaultDateFormat))
  }

  const resetDates = () => {
    setStartDate(dayjs(availableDates.max).subtract(months, 'month').format(defaultDateFormat))
    setEndDate(dayjs(availableDates.max).format(defaultDateFormat))
  }

  const Filters = () => {
    const accountFilters = levelFilters.accountIds || []
    const assetFilters = levelFilters.assetIds || []

    const createFilterItem = (id, prefix, clickHandler) => (
      <div
        className={classes.filterItem}
        key={`${prefix}-${id}`}
        id={id}
        onClick={clickHandler}
      >
        {`${prefix} ${id} X`}
      </div>
    )

    const filters = [
      ...accountFilters.map((id) => createFilterItem(id, 'Account', handleAccountFilterClick)),
      ...assetFilters.map((id) => createFilterItem(id, 'Asset', handleAssetFilterClick))
    ]

    return (
      <div className={classes.headerWrapper}>
        <div className={classes.filterWrapper}>
          {filters.length > 0 ? filters : (<span style={{ color: '#cccccc' }}>No filters applied</span>)}
        </div>
        <div className={classes.filterWrapper}>
          <div>Show&nbsp;</div>
          <div className={classes.monthChangeItem} onClick={handleMonthClick} id={1}>1</div>
          <div className={classes.monthChangeItem} onClick={handleMonthClick} id={3}>3</div>
          <div className={classes.monthChangeItem} onClick={handleMonthClick} id={6}>6</div>
          <div className={classes.monthChangeItem} onClick={handleMonthClick} id={12}>12</div>
          <div className={classes.monthChangeItem} onClick={handleMonthClick} id={36}>36</div>
          <div>&nbsp;months</div>
        </div>
        <div className={classes.filterWrapper}>
          <div className={classes.dateChangeItem} onClick={() => moveDates(months * -1)}>prev {months} mo {'<'} </div>
          <div className={classes.showDate}>
            {`${dayjs(startDate).format('M/D/YY')} - ${dayjs(endDate).format('M/D/YY')}`}
          </div>
          <div className={classes.dateChangeItem} onClick={() => moveDates(months)}> {'>'} next {months} mo</div>
          <div className={classes.dateChangeItem} onClick={() => resetDates()}> reset dates</div>
        </div>
        <div>
          <TextField id='startDate' />
        </div>
      </div>
    )
  }

  function formatDataset (id, color, strokeWidth, data, lineOnly, valueField) {
    const returnData = {
      id,
      color,
      lineOnly,
      strokeWidth: strokeWidth,
      style: {
        strokeWidth
      },
      data: []
    }

    for (let index = 0; index < data.length; index++) {
      const element = data[index]
      const dataPush = {
        x: `${element.year}-${element.month}-${element.day}`,
        y: element[valueField]
      }
      returnData.data.push(dataPush)
    }

    return returnData
  }

  return (
    <DefaultLayout>
      <div style={{ width: '100%' }}>
        <header className='App-header'>
          <Grid
            container
            spacing={0}
            alignItems='center'
            style={{
              padding: '0px  24px',
              justifyContent: 'space-between',
              maxWidth: '1440px',
              width: '100%'
            }}
          >
            <Grid item xs={12} md={4} style={{ padding: '0px 0px' }}>
              <Typography
                id='starting-value-label'
                gutterBottom
                style={{ fontSize: '24px', fontWeight: '700' }}
              >
                Research Home
              </Typography>
            </Grid>
          </Grid>
        </header>
        <Filters />
        <Grid item xs={12} style={{ padding: '12px', textAlign: 'left', marginTop: '2rem', marginBottom: '6rem' }}>
          <Grid item xs={12} style={{ height: '450px', width: '100%' }}>
            <LineContainer isLoading={queryLineData.isLoading} error={queryLineData.error} data={queryLineData.data} />
          </Grid>
          <Grid item xs={12} style={{ width: '100%', marginTop: '3rem' }}>
            <LevelDetail isLoading={queryDetailData.isLoading} error={queryDetailData.error} data={modifiedLevelData} />
          </Grid>
          <Grid item xs={12} style={{ width: '100%', marginTop: '3rem' }}>
            {((levelFilters.accountIds && levelFilters.accountIds.length > 0) || (levelFilters.assetIds && levelFilters.assetIds.length > 0)) &&
              <AccountDetailTable isLoading={queryLineData.isLoading} error={queryLineData.error} data={modifiedDetailData} />}
          </Grid>
        </Grid>
      </div>
    </DefaultLayout>
  )
}

export default ResearchHome
