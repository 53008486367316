import React, { useCallback, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles, Box } from '@material-ui/core'
import { getMedia, saveMedia, uploadDocument } from '../../../../../../service'
import { useAppContext } from '../../../../../../redux/slices/appContext'
import Text from '../../../../../atoms/Text'
import RemoveButton from '../../../FeeOverrides/RemoveButton'
import ImagePreviewDnDInput from './ImagePreviewDnDInput'

const useStyles = makeStyles(() => ({
  container: {
    width: '100%'
  },
  inputContainer: {
    gap: '0.75rem',
    display: 'flex',
    flexDirection: 'row',
    padding: '1rem',
    width: '100%',
    borderRadius: '9.6px',
    alignItems: 'center',
    border: '2.4px solid var(--Grey-100, #EEE)'
  },
  inputMeta: {
    display: 'flex'
  },
  inputDesc: {
    display: 'flex',
    flexDirection: 'column'
  }
}))

function getImageSize (url) {
  const img = document.createElement('img')
  const promise = new Promise((resolve, reject) => {
    img.onload = () => {
      const width = img.naturalWidth
      const height = img.naturalHeight
      resolve({ width, height })
    }
    img.onerror = reject
  })
  img.src = url
  return promise
}

async function includeImageMeta (image, mediaId, downloadUrl) {
  const imgDimensions = await getImageSize(downloadUrl)
  Object.assign(image, {
    mediaId,
    dimensions: imgDimensions,
    preview: downloadUrl
  })
  return image
}

async function getMediaImage (mediaId) {
  const { data: media } = await getMedia(mediaId)
  const { originalFileName, downloadUrl } = media
  const mediaImage = await includeImageMeta(
    { name: originalFileName },
    mediaId,
    downloadUrl
  )
  return mediaImage
}

const entryImageUploadMetaInit = {
  progress: null,
  uploaded: false,
  hasError: false,
  aborted: false
}

const FileUploadInput = ({
  image,
  defaultMediaId,
  onChange: _onChange
}) => {
  const classes = useStyles()
  const { userId } = useAppContext()

  const [imageUrl, setImageUrl] = useState()
  const [entryImageUploadMeta, setEntryImageUploadMeta] = useState(
    entryImageUploadMetaInit
  )
  const [uploadImageRequest, setUploadImageRequest] = useState(null)

  const isImageUploading = useMemo(() => {
    const { uploaded, aborted, hasError, progress } = entryImageUploadMeta
    return !uploaded && !aborted && !hasError && progress !== null
  }, [entryImageUploadMeta])

  useEffect(() => {
    if (defaultMediaId && !imageUrl) {
      getMediaImage(defaultMediaId)
    }
  }, [imageUrl, defaultMediaId, _onChange])

  useEffect(() => {
    const xhr = new XMLHttpRequest()
    setUploadImageRequest(xhr)
    return () => {
      if (xhr) {
        xhr.abort()
      }
    }
  }, [])

  const _onAbortDocumentUpload = useCallback(
    () =>
      setEntryImageUploadMeta((prevState) => ({
        ...prevState,
        aborted: true,
        progress: 0
      })),
    []
  )

  const _onUploadFileProgress = useCallback(
    (event) =>
      setEntryImageUploadMeta((prevState) => ({
        ...prevState,
        progress: (event.loaded / event.total) * 100
      })),
    []
  )

  const onUploadEntryPicture = useCallback(
    async (image) => {
      if (!image) return
      try {
        const { data } = await saveMedia({
          createdBy: userId,
          fileName: image.name
        })
        const { mediaId, uploadUrl } = data

        await uploadDocument(
          uploadUrl,
          image,
          _onUploadFileProgress,
          _onAbortDocumentUpload,
          uploadImageRequest
        )
        const { data: media } = await getMedia(mediaId)

        setEntryImageUploadMeta((prevState) => ({
          ...prevState,
          uploaded: true
        }))

        const { downloadUrl } = media
        image = await includeImageMeta(image, mediaId, downloadUrl)
        setImageUrl(downloadUrl)
        _onChange(image)
      } catch (err) {
        setEntryImageUploadMeta({
          progress: 0,
          uploaded: false,
          hasError: true
        })
        console.error(err)
      }
    },
    [
      userId,
      _onChange,
      _onUploadFileProgress,
      _onAbortDocumentUpload,
      uploadImageRequest
    ]
  )

  const onRemove = useCallback(() => {
    _onChange(null)
    setImageUrl(null)
    setEntryImageUploadMeta({ ...entryImageUploadMetaInit })
  }, [_onChange])

  return (
    <div className={classes.inputContainer}>
      <ImagePreviewDnDInput
        containerClass={!image ? classes.container : null}
        styles={{
          minWidth: '10rem',
          width: image ? 'unset' : '100%'
        }}
        file={image}
        isLoading={isImageUploading}
        placeholderSrc={imageUrl}
        onDropAccepted={onUploadEntryPicture}
      />
      {(image || imageUrl) && (
        <>
          {image && (
            <div className={classes.inputDesc}>
              <Text>{image?.name}</Text>
              <Text customFontWeight='bold'>{`${image?.dimensions?.width}px X ${image?.dimensions?.height}px`}</Text>
            </div>
          )}
          <Box ml='auto'>
            <RemoveButton onClick={onRemove} />
          </Box>
        </>
      )}
    </div>
  )
}

FileUploadInput.propTypes = {
  image: PropTypes.object,
  onChange: PropTypes.func,
  defaultMediaId: PropTypes.string
}

export default FileUploadInput
