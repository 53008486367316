import React from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@material-ui/core'
import { Blade } from '../shared/Blade'
import FeeScheduleInfo from './FeeScheduleInfo'
import FeeScheduleActions from './Actions'
import FeeScheduleDescription from './Description'
import FamilyRate from './FamilyRate'

function FeeScheduleFineDetail ({ detail: feeSchedule, clientInfo }) {
  if (!feeSchedule) {
    return null
  }

  return (
    <Blade>
      <Grid container spacing={1}>
        <Grid item xs={10}>
          <FeeScheduleInfo feeSchedule={feeSchedule} />
        </Grid>
        <Grid item xs={12}>
          <FeeScheduleActions feeSchedule={feeSchedule} />
          <hr />
        </Grid>
        <Grid item xs={12}>
          <FeeScheduleDescription feeSchedule={feeSchedule} />
          <hr />
        </Grid>
        <Grid item xs={12}>
          <FamilyRate feeSchedule={feeSchedule} familyRates={clientInfo.familyRates} />
        </Grid>
      </Grid>
    </Blade>
  )
}

FeeScheduleFineDetail.propTypes = {
  detail: PropTypes.any,
  clientInfo: PropTypes.any
}

export default FeeScheduleFineDetail
