import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core'
import Tooltip from '@material-ui/core/Tooltip'

const PREVIEW_SIZES = { height: 15, width: 20 }

const useStyles = makeStyles((theme) => ({
  viewBox: {
    position: 'absolute',
    backgroundColor: ({ highlighted }) => highlighted
      ? `${theme.palette.selected} !important`
      : theme.palette.dimmed,
    cursor: 'pointer',
    borderRadius: '2px',
    border: ({ highlighted }) => highlighted
      ? '0px solid rgba(33, 41, 69, 0.8) !important'
      : '0px solid rgba(33, 41, 69, 0.2)',
    height: ({ height }) => `${height}px`,
    width: ({ width }) => `${width}px`,
    '&:hover': {
      backgroundColor: `${theme.palette.selected} !important`,
      border: '0px solid rgba(33, 41, 69, 0.8)'
    }
  }
}))

const MinimapLink = ({ item, position, highlighted }) => {
  const classes = useStyles({
    width: PREVIEW_SIZES.width,
    height: PREVIEW_SIZES.height,
    highlighted
  })
  return (
    <Tooltip title={item.title} placement='top'>
      <Link to={item.to}>
        <div
          className={classes.viewBox}
          style={{
            left: `${32 + position[0]}px`,
            top: `${26 - position[1]}px`
          }}
        />
      </Link>
    </Tooltip>
  )
}

MinimapLink.propTypes = {
  item: PropTypes.object,
  position: PropTypes.array,
  highlighted: PropTypes.bool
}

export default MinimapLink
