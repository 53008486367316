import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@material-ui/core'
import Loading from '../../../../molecules/Loading'
import AccountSummaryList from './AccountSummaryList'
import ClientInfo from './ClientInfo'
import FamilyRateSummary from './FamilyRateSummary'
import FeeSchedules from './FeeSchedules'
import FeeOverrides from './FeeOverrides'
import { Blade } from './shared/Blade'
import { useClientReviewContext } from './ClientReviewContext'

function ClientDetailBlade ({ accountsConfig }) {
  const {
    client,
    detail: selectedDetail,
    setDetail: onSelectDetail,
    clientBilling,
    clientAccounts
  } = useClientReviewContext()

  const selectDetail = useCallback((detail) => onSelectDetail({ ...detail, clientInfo: clientBilling.data?.body }), [clientBilling.data, onSelectDetail])

  if (!client) {
    return null
  }

  if (clientBilling.isLoading) {
    return (
      <Blade>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <ClientInfo client={client} />
          </Grid>
          <Grid item xs={12}>
            <Loading />
          </Grid>
        </Grid>
      </Blade>
    )
  }

  return (
    <Blade>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <ClientInfo client={client} />
        </Grid>
        <Grid item xs={6}>
          <FamilyRateSummary familyRates={clientBilling.data?.body?.familyRates} />
        </Grid>
        <Grid item xs={12}>
          <FeeSchedules
            feeSchedules={clientBilling.data?.body?.feeSchedules}
            client={client}
            selectedDetail={selectedDetail}
            onSelectDetail={selectDetail}
          />
        </Grid>
        <Grid item xs={12}>
          <FeeOverrides overrides={clientBilling.data?.body?.overrides} selectedDetail={selectedDetail} onSelectDetail={selectDetail} />
        </Grid>
        <Grid item xs={12}>
          {clientAccounts.isLoading ? (
            <Loading />
          ) : (
            <AccountSummaryList
              accounts={clientAccounts?.data?.data || []}
              selectedDetail={selectedDetail}
              onSelectDetail={selectDetail}
              {...accountsConfig}
            />
          )}
        </Grid>
      </Grid>
    </Blade>
  )
}

ClientDetailBlade.propTypes = {
  accountsConfig: PropTypes.object
}

export default ClientDetailBlade
