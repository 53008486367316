
import React, { useMemo } from 'react'
import { makeStyles } from '@material-ui/core'
import { first } from 'lodash'
import PropTypes from 'prop-types'
import { useSearchClients } from '../../../api/clients'
import Skeleton from '../../atoms/Skeleton'
import { SKELETON_VARIANTS } from '../../../constants'
import FamilyValuesInput from '../../molecules/FamilyValuesInput'
import { useAppContext } from '../../../redux/slices/appContext'

const useStyles = makeStyles(() => ({
  descriptionContainer: {
    marginBottom: '1.5rem'
  },
  description: {
    color: '#212945',
    fontSize: '1rem',
    fontWeight: 325,
    lineHeight: '1.375rem'
  },
  tagsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '0.5rem'
  },
  tag: {
    borderRadius: '2rem',
    background: '#F5F6F7',
    padding: '0.375rem 1rem',
    fontSize: '0.875rem',
    fontWeight: 'bold'
  }
}))

const ClientWealthMission = ({ interestTypeId }) => {
  const classes = useStyles()
  const { clientId } = useAppContext()

  const { query, queryOptions } = useMemo(
    () => ({
      query: {
        includes: {
          groups: {
            levelTypeId: [{ op: 'eq', value: interestTypeId }]
          }
        },
        filters: { clientId }
      },
      queryOptions: {
        mapper: ({ data }) => first(data) ?? {}
      }
    }),
    [clientId, interestTypeId]
  )
  const { data: client, isLoading } = useSearchClients(query, queryOptions)

  if (isLoading) {
    return (
      <div>
        <Skeleton height='2rem' width='30%' variant={SKELETON_VARIANTS.text} />
        <Skeleton height='2rem' variant={SKELETON_VARIANTS.text} />
        <Skeleton height='2rem' variant={SKELETON_VARIANTS.text} />
        <Skeleton height='2rem' width='40%' variant={SKELETON_VARIANTS.text} />
      </div>
    )
  }

  return (
    <div>
      <div className={classes.descriptionContainer}>
        <FamilyValuesInput
          clientId={clientId}
          className={classes.description}
          defaultValue={client?.description}
        />
      </div>
      <div className={classes.tagsContainer}>
        {client?.groups.map(({ groupId, name: clientInterest }) => {
          return (
            <div key={groupId} className={classes.tag}>
              {clientInterest}
            </div>
          )
        })}
      </div>
    </div>
  )
}

ClientWealthMission.propTypes = {
  interestTypeId: PropTypes.number
}

ClientWealthMission.defaultProps = {
  interestTypeId: 275
}

export default ClientWealthMission
