/* eslint-disable no-loop-func */
import dayjs from 'dayjs'

// TODO: Process partial year for projections
// TODO: Process different withdrawal rates/periods

const getPlanData = (planDetails) => {
  let actualData = getActualData()
  let latestPortfolioValue = actualData.data[actualData.data.length - 1].y
  if (planDetails.startValue !== 3600000) {
    latestPortfolioValue = planDetails.startValue
    actualData = getPastData(planDetails.startValue, planDetails.portfolioGrowthModel.growthFactor)
  }
  const returnDataSet = {
    planData: [],
    assumptionsData: [],
    processTime: 0
  }
  const planData = []
  // planData.push(actualData)
  const startDate = planDetails.currentDate

  const startTime = Date.now()
  const withdrawalSchedule = calcFlows(planDetails, planDetails.planGoals)
  returnDataSet.processTime = Date.now() - startTime

  returnDataSet.assumptionsData = withdrawalSchedule
  const portfolioStartDate = dayjs(startDate.format('YYYY-MM-DD'))

  // Calculate portfolio growth for the expected (deterministic) return (for the line graph)
  const projectedData = getProjectedData([], portfolioStartDate, latestPortfolioValue, withdrawalSchedule, planDetails.planEndDate, planDetails.portfolioGrowthModel, planDetails.inflationModel)
  const projectedBeforeRetire = []
  const projectedAfterRetire = []

  projectedData.forEach((x) => {
    if (dayjs(x.valueDate).isSame(planDetails.retireDate)) {
      projectedBeforeRetire.push(x)
      projectedAfterRetire.push(x)
    }
    if (dayjs(x.valueDate).isBefore(planDetails.retireDate)) {
      projectedBeforeRetire.push(x)
    } else {
      projectedAfterRetire.push(x)
    }
  })

  const beforeRetireFormatted = formatDataset('projectedBefore', '#9ABCF8', '10', projectedBeforeRetire)
  planData.push(beforeRetireFormatted)
  const afterRetireFormatted = formatDataset('projectedAfter', '#B5CEFA', '10', projectedAfterRetire)
  planData.push(afterRetireFormatted)

  returnDataSet.planData = planData

  // return planData;
  return returnDataSet
}

// use for fixed withdrawals for inflation as well
function calcFlows (planDetails, planGoals) {
  // Calculate Value Change
  const returnData = []
  const currentDate = planDetails.planStartDate.clone()

  // Map through each goal
  const goalFlows = planGoals.filter(x => x.status === 'on').map((goal) => {
    let loopDate = currentDate.clone()
    const loopGoal = goal
    let currentValue = goal.rate.rateValue
    const goalFlowData = []
    while (loopDate <= planDetails.planEndDate) {
      // Adjust for yearValueBase
      if (goal.rate.yearValueBase && goal.rate.yearValueBase <= loopDate.year()) {
        const growthMethod = planDetails.inflationModel
        currentValue = getGrowthValue(currentValue, growthMethod, false)
      }

      let valueMultiplier = 0

      if (goal.startDate && dayjs(goal.startDate) <= loopDate) {
        if ((goal.endDate === '') || (goal.endDate && dayjs(goal.endDate) >= loopDate)) {
          valueMultiplier = 1
        }
      }

      const flowData = {
        flowDate: loopDate.format('YYYY-MM-DD'),
        flowValue: currentValue * valueMultiplier
      }

      goalFlowData.push(flowData)
      loopDate = loopDate.add(1, 'y')
    }

    loopGoal.flowData = goalFlowData
    return loopGoal
  })

  // TODO: Reduce individual goal flowData to aggregated flowData

  let whileDate = currentDate.clone()
  while (whileDate <= planDetails.planEndDate) {
    const totalDateFlow = goalFlows.reduce((acc, goal) => {
      const compareDate = whileDate.clone()
      const yearCompare = dayjs(compareDate).year()
      const periodFlows = goal.flowData.filter(x => dayjs(x.flowDate).year() === yearCompare)
      const periodFlowValue = periodFlows.reduce((acc, flow) => { return acc + flow.flowValue }, 0)
      return acc + periodFlowValue
    }, 0)

    const totalFlowData = {
      valueDate: whileDate.format('YYYY-MM-DD'),
      value: totalDateFlow
    }

    returnData.push(totalFlowData)
    whileDate = whileDate.add(1, 'y')
  }
  return returnData
}

// use for fixed withdrawals for inflation as well
function getProjectedData (planDetails, startDate, startValue, withdrawals, endDate, growthMethod, adjustForInflation) {
  // Calculate Value Change
  const returnData = []
  let currentDate = dayjs(startDate)
  let currentValue = startValue
  const adjustments = planDetails.adjustments ? planDetails.adjustments : []
  let currentData = {
    valueDate: currentDate.format('YYYY-MM-DD'),
    value: currentValue
  }
  returnData.push(currentData)

  // Set to annual for now
  currentDate = currentDate.add(1, 'y')
  while (currentDate <= endDate) {
    // Calculate value growth
    currentValue = getGrowthValue(currentValue, growthMethod, adjustForInflation)
    // Adjust only for the withdrawal calculation
    if (adjustments.filter(x => x.adjustDate.year() === currentDate.year()).length > 0) {
      const adjustLevel = adjustments.filter(x => x.adjustDate.year() === currentDate.year())[0].adjustLevel
      currentValue = currentValue * (1 + adjustLevel)
    }

    // Process Withdrawals annually for now at the end of the year
    // TODO: Process if withdrawal is within range of current period
    // TODO: Add flag for timing of withdrawal (beginning of period, CURRENT end of period)
    const currentWithdrawal = withdrawals.filter(x => dayjs(x.valueDate).isSame(currentDate))
    if (currentWithdrawal.length > 0) {
      currentValue += currentWithdrawal[0].value
      if (currentValue < 0) {
        currentValue = 0
      }
    }

    currentData = {
      valueDate: currentDate.format('YYYY-MM-DD'),
      value: currentValue
    }
    returnData.push(currentData)
    currentDate = currentDate.add(1, 'y')
  }
  return returnData
}

function getGrowthValue (currentValue, growthMethod, adjustForInflation) {
  let newValue = currentValue * 1
  if (growthMethod.growthType === 'constant') {
    let growthRate = growthMethod.growthFactor * 1
    if (adjustForInflation && adjustForInflation.adjustReturns) {
      growthRate = growthRate - adjustForInflation.growthFactor
    }
    newValue = currentValue * (1 + growthRate)
  }
  return newValue
}

function formatDataset (id, color, strokeWidth, data) {
  const returnData = {
    id,
    color,
    strokeWidthA: strokeWidth,
    style: {
      strokeWidth
    },
    data: []
  }

  for (let index = 0; index < data.length; index++) {
    const element = data[index]
    const dataPush = {
      x: element.valueDate,
      y: element.value
    }
    returnData.data.push(dataPush)
  }

  return returnData
}

function getActualData () {
  const returnData = {
    id: 'actual',
    color: '#42cc6a60',
    style: {
      strokeWidth: '10px'
    },
    data: [
      { x: '2017-01-01', y: 3305760 },
      { x: '2018-01-01', y: 3465015.368 },
      { x: '2019-01-01', y: 3587881.6259124 },
      { x: '2020-01-01', y: 3644478.1136488 }
      // { x: '2019-01-01', y: 3824928.1153049 },
      // { x: '2020-01-01', y: 3859358.98951572 },
    ]
  }

  return returnData
}

const getPastData = (startValue, growthFactor) => {
  const valueData = []
  const chartStartValue = startValue / (Math.pow(1 + growthFactor, 4))
  valueData.push({ x: '2017-01-01', y: chartStartValue })
  let nextValue = chartStartValue * (1 + growthFactor)
  valueData.push({ x: '2018-01-01', y: nextValue })
  nextValue = nextValue * (1 + growthFactor)
  valueData.push({ x: '2019-01-01', y: nextValue })
  valueData.push({ x: '2020-01-01', y: startValue })

  const returnData = {
    id: 'actual',
    color: '#cccccc',
    style: {
      strokeWidth: '10px'
    },
    data: valueData
  }

  return returnData
}

export default getPlanData
