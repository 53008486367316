import React, { useMemo } from 'react'
import { isEmpty } from 'lodash'
import { useRouteMatch } from 'react-router-dom'
import { useAbundanceEngineGalleryContext } from '../abundanceEngineGalleryContext'
import ClientDashboard from '../../../../organisms/ClientDashboard'
import ClientDashboardItem from '../../../../organisms/ClientDashboard/ClientDashboardItem'
import { getLayoutSettings } from '../helpers'
import WheelPreviewContainer from './container'

const WheelPreview = () => {
  const { url } = useRouteMatch()

  const { firmConfig, selectedViews, abundanceEngineViews } =
    useAbundanceEngineGalleryContext()

  const wheelItems = useMemo(() => {
    if (isEmpty(abundanceEngineViews)) return []
    const selectedViewIds = Object.entries(selectedViews).reduce(
      (acc, [viewId, isSelected]) => {
        if (!isSelected) return acc
        return [...acc, viewId]
      },
      []
    )
    return abundanceEngineViews.filter(({ path: viewPath }) =>
      selectedViewIds.includes(viewPath)
    )
  }, [selectedViews, abundanceEngineViews])

  return (
    <WheelPreviewContainer>
      <ClientDashboard settings={getLayoutSettings(firmConfig)} rootUrl={url}>
        {wheelItems.map((item) => {
          return (
            <ClientDashboardItem
              key={item.path}
              title={item.name}
              smallImageSrc={null}
              smallTitle={item.name}
              text={item.description}
              viewPath={item.path}
            />
          )
        })}
      </ClientDashboard>
    </WheelPreviewContainer>
  )
}

export default WheelPreview
