import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import { usePolicy } from '../../../../../../hooks/usePolicy'
import GroupCard from '../GroupCard'
import SydButton from '../../../../../commonDesign/Button'
import { useAssignClientGroupMembership } from '../../../../../../api/groups'
import AssignGroupDialog from './AssignGroupDialog'

const useStyles = makeStyles((theme) => ({
  groupList: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.layout.gap.medium
  },
  card: {
    '& > header': {
      fontWeight: theme.typography.weights.bold,
      fontSize: theme.typography.fontSizes.xxl,
      marginBottom: theme.layout.margin.medium,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between'
    },
    '& .__actions': {
      display: 'flex',
      flexDirection: 'row',
      gap: theme.layout.gap.medium
    },
    '& .__missing': {
      color: theme.palette.gray.darker
    },
    '& .__groups': {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.layout.gap.dense
    },
    '& .__group': {
      fontWeight: theme.typography.weights.light
    },
    '& .__accounts': {
      marginLeft: theme.layout.margin.thick,
      display: 'flex',
      flexDirection: 'column',
      gap: theme.layout.gap.dense
    }
  }
}))

function Groups ({ groups }) {
  if (!groups.length) {
    return (
      <div className='__missing'>
        &lt;Not Assigned&gt;
      </div>
    )
  }

  return (
    <div className='__groups'>
      {groups.map(g => (
        <div className='__group' key={g.groupId}>{g.longName}</div>
      ))}
    </div>
  )
}

Groups.propTypes = {
  groups: PropTypes.arrayOf(PropTypes.shape({
    groupId: PropTypes.number,
    shortName: PropTypes.string,
    longName: PropTypes.string
  }))
}

function GroupAssignments ({ client, groupTypes }) {
  const classes = useStyles()
  const canEdit = usePolicy('admin_edit_client_tags')
  const assignRef = useRef()
  const { mutateAsync: assignGroups } = useAssignClientGroupMembership()

  return (
    <div className={classes.groupList}>
      {groupTypes.map(gt => (
        <GroupCard
          key={gt.groupTypeId}
          title={gt.longName}
          description={gt.description}
          showActions={canEdit}
          actions={(
            <SydButton size='xs' icon='edit' onClick={() => assignRef.current.open(gt)}>Edit</SydButton>
          )}
        >
          <Groups groups={gt.groups} />
        </GroupCard>
      ))}
      <AssignGroupDialog ref={assignRef} client={client} onCreate={assignGroups} />
    </div>
  )
}

GroupAssignments.propTypes = {
  client: PropTypes.shape({
    longName: PropTypes.string
  }),
  groupTypes: PropTypes.arrayOf(PropTypes.shape({
    groupTypeId: PropTypes.number,
    shortName: PropTypes.string,
    longName: PropTypes.string,
    uniqueMembers: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
    clientSpecific: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
    groups: PropTypes.arrayOf(PropTypes.shape({
      groupId: PropTypes.number,
      shortName: PropTypes.string,
      longName: PropTypes.string
    }))
  }))
}

export default GroupAssignments
