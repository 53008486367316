import PropTypes from 'prop-types'
import { useWealthJourney } from '../WealthJourneyProvider'

function WJTab ({ tabKey, children }) {
  const { selectedTab } = useWealthJourney()

  if (tabKey !== selectedTab) return null

  return children
}

WJTab.propTypes = {
  tabKey: PropTypes.string,
  children: PropTypes.node.isRequired
}

export default WJTab
