import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import { camelCase, first, isEmpty } from 'lodash'
import utc from 'dayjs/plugin/utc'
import { VIEW_TYPES } from '../ClientCardsViewV2/helpers'
import { replaceValues } from '../../../utils/replacers'
import { getRelativeDateRanges, useParameterReplacement } from '../../../hooks/useAbundanceEngineParameters'
import { useAdvisorHome } from '../AdvisorHome/AdvisorHomeContext'
import { TasksContext } from './TasksContext'

dayjs.extend(utc)

const mapDateRangeKey = ({ label, startDate, endDate }) =>
  `${camelCase(label)}-${startDate}-${endDate}`

const mapRelativeDateRange = ({ label, startDate, endDate }, relativeDateRanges) => {
  return {
    label,
    value: mapDateRangeKey({ label, startDate, endDate }),
    startDate: dayjs
      .utc(replaceValues(startDate, relativeDateRanges))
      .startOf('day')
      .toISOString(),
    endDate: dayjs
      .utc(replaceValues(endDate, relativeDateRanges))
      .endOf('day')
      .toISOString()
  }
}

const TasksContextProvider = ({
  children,
  pageSize,
  linkBaseUrl,
  defaultSort,
  emptyStateLabel,
  excludeTaskStatuses,
  tabOptions: rawTabOptions,
  featuredFilters: rawFeaturedFilters,
  overdueStartDate: rawOverdueStartDate
}) => {
  const { scope } = useAdvisorHome()
  const [overdueStartDate] = useParameterReplacement([rawOverdueStartDate])
  const [containerViewRef, setContainerViewRef] = useState(null)
  const [viewType, setViewType] = useState(VIEW_TYPES.BLOCKS)

  const [selectedAsOfDate, setAsOfDate] = useState(
    dayjs().utc().startOf('day').toISOString()
  )

  const relativeDateRanges = useMemo(
    () => getRelativeDateRanges(selectedAsOfDate),
    [selectedAsOfDate]
  )

  const [selectedFeaturedFilter, setFeaturedFilter] = useState(
    mapRelativeDateRange(first(rawFeaturedFilters), relativeDateRanges)
  )

  useEffect(() => {
    setFeaturedFilter((prevState) => {
      const rawFilter = rawFeaturedFilters.find(
        (filter) => mapDateRangeKey(filter) === prevState.value
      )
      if (rawFilter.value === prevState.value) return prevState
      return mapRelativeDateRange(rawFilter, relativeDateRanges)
    })
  }, [rawFeaturedFilters, relativeDateRanges])

  const tabOptions = useMemo(() => {
    return Object.entries(rawTabOptions).reduce((acc, [key, option]) => {
      if ('disabled' in option) {
        option.disabled = isEmpty(scope?.values) || option.disabled
      }
      return {
        ...acc,
        [key]: option
      }
    }, {})
  }, [rawTabOptions, scope])

  const [selectedTab, setSelectedTab] = useState(
    first(Object.keys(tabOptions))
  )

  const [paginationData, setPaginationData] = useState({
    skip: 0,
    take: pageSize
  })

  const [sort, setSort] = useState(defaultSort)

  const featuredFilters = useMemo(() => {
    return rawFeaturedFilters.map((filter) =>
      mapRelativeDateRange(filter, relativeDateRanges)
    )
  }, [rawFeaturedFilters, relativeDateRanges])

  const value = useMemo(() => {
    return {
      sort,
      viewType,
      setViewType,
      linkBaseUrl,
      selectedAsOfDate: dayjs
        .utc(selectedAsOfDate)
        .startOf('day')
        .toISOString(),
      setAsOfDate,
      featuredFilters,
      selectedFeaturedFilter,
      setFeaturedFilter,
      tabOptions,
      selectedTab,
      setSelectedTab,
      emptyStateLabel,
      setSort,
      paginationData,
      setPaginationData,
      relativeDateRanges,
      containerViewRef,
      setContainerViewRef,
      excludeTaskStatuses,
      overdueStartDate
    }
  }, [
    sort,
    viewType,
    tabOptions,
    selectedTab,
    linkBaseUrl,
    paginationData,
    emptyStateLabel,
    featuredFilters,
    overdueStartDate,
    containerViewRef,
    selectedAsOfDate,
    relativeDateRanges,
    excludeTaskStatuses,
    selectedFeaturedFilter
  ])

  return (
    <TasksContext.Provider value={value}>{children}</TasksContext.Provider>
  )
}

TasksContextProvider.propTypes = {
  tabOptions: PropTypes.object,
  emptyStateLabel: PropTypes.string,
  children: PropTypes.node.isRequired,
  pageSize: PropTypes.number,
  linkBaseUrl: PropTypes.string,
  defaultSort: PropTypes.arrayOf(PropTypes.object),
  featuredFilters: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      startDate: PropTypes.string,
      endDate: PropTypes.string
    })
  ),
  excludeTaskStatuses: PropTypes.arrayOf(PropTypes.string),
  overdueStartDate: PropTypes.string
}

export const TASK_TABS = {
  MY_CLIENTS: 'myClients',
  ALL_TEAM_TASKS: 'allTeamTasks'
}

TasksContextProvider.defaultProps = {
  linkBaseUrl: undefined,
  featuredFilters: [
    {
      label: 'Due Today',
      startDate: '$today',
      endDate: '$today'
    },
    {
      label: 'This Week',
      startDate: '$today',
      endDate: '$weekEnd'
    },
    {
      label: 'Next Week',
      startDate: '$nextWeekStart',
      endDate: '$nextWeekEnd'
    }
  ],
  pageSize: 100,
  tabOptions: {
    [TASK_TABS.MY_CLIENTS]: { label: 'My Clients', disabled: true },
    [TASK_TABS.ALL_TEAM_TASKS]: { label: 'All Team Tasks' }
  },
  defaultSort: [{ field: 'dueDate', dir: 'asc' }],
  emptyStateLabel: 'Looks like you have no upcoming tasks',
  excludeTaskStatuses: [],
  overdueStartDate: undefined
}

export default TasksContextProvider
