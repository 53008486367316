import { useMemo } from 'react'
import { useQuery } from '@tanstack/react-query'
import { useAppContext } from '../redux/slices/appContext'
import { postNamedCommand, postNamedQuery } from '../service'
import { QUERY_KEYS } from './queryKeys'

const REPORT_STATUS = {
  ERROR: 'error',
  COMPLETED: 'completed'
}

export const useBookOfBusinessReport = (query, shouldCheckExisting = true) => {
  const {
    data: existingResult,
    isInitialLoading: existingResultLoading
  } = useCheckExistingBookOfBusinessReport(query, { enabled: shouldCheckExisting })

  const existingOutput = useMemo(() => {
    if (!existingResultLoading && existingResult) {
      return {
        bobiOutputId: existingResult.bobiOutputId,
        status: existingResult.status,
        statusDescription: existingResult.statusDescription
      }
    }
    return null
  }, [existingResult, existingResultLoading])

  const shouldRunQuery =
    !existingResultLoading &&
    (!existingOutput ||
      existingOutput.status === REPORT_STATUS.ERROR)

  const {
    data: runResult,
    isInitialLoading: runLoading
  } = useRunBookOfBusinessReport(query, { enabled: shouldRunQuery })

  const runOutput = useMemo(() => {
    if (runLoading) return null

    if (existingResult?.status === 'completed') {
      return { bobiOutputId: existingResult.bobiOutputId }
    }
    if (runResult) {
      return {
        bobiOutputId: runResult.bobiOutputId,
        status: runResult.status,
        statusDescription: runResult.statusDescription
      }
    }
  }, [runResult, existingResult, runLoading])

  let status = existingResult?.status
  if (status === REPORT_STATUS.ERROR) {
    status = runOutput?.status
  }

  const shouldQueryBobiStatus =
    !existingResultLoading &&
    !existingResult &&
    !runLoading &&
    status !== REPORT_STATUS.COMPLETED &&
    status !== REPORT_STATUS.ERROR

  const { bobiOutputId, ...other } = existingResult ?? runResult ?? {}

  const { data: bobiStatus, isInitialLoading: bobiStatusLoading } =
    useQueryBobiOutputStatus(
      { bobiOutputId },
      { enabled: Boolean(shouldQueryBobiStatus && !!bobiOutputId) }
    )
  const statusOutput = useMemo(() => {
    if (!bobiStatusLoading && bobiStatus) {
      return bobiStatus
    }

    return null
  }, [bobiStatus, bobiStatusLoading])

  return {
    data: {
      bobiOutputId,
      status: other?.status ?? statusOutput?.status,
      statusDescription:
        other?.statusDescription ?? statusOutput?.statusDescription
    },
    isLoading: existingResultLoading || runLoading || bobiStatusLoading
  }
}

export const useCheckExistingBookOfBusinessReport = (query, options = {}) => {
  const { userId } = useAppContext()
  const { enabled = true, mapper } = options
  return useQuery({
    enabled,
    queryKey: [QUERY_KEYS.bobiCheckExistingQuery, userId, query],
    queryFn: async () => {
      const { data } = (await postNamedQuery('bobi', 'checkExistingQuery', query))
      if (data === '') {
        return null
      }

      return data
    },
    select: mapper
  })
}

export const useRunBookOfBusinessReport = (query, options = {}) => {
  const { userId } = useAppContext()
  const { enabled = true } = options

  return useQuery({
    enabled,
    queryKey: [QUERY_KEYS.bobiRunOfflineQuery, userId, query],
    queryFn: async () => {
      const { data } = await postNamedCommand('bobi', 'runOfflineQuery', query)
      return data
    }
  })
}

export const useQueryBookOfBusinessReport = (query, options = {}) => {
  const { userId } = useAppContext()
  const { enabled = true } = options
  return useQuery({
    enabled,
    queryKey: [QUERY_KEYS.bobiQueryOutput, userId, query],
    queryFn: async () => {
      const { data } = await postNamedQuery('bobi', 'queryBobiOutput', query)
      return data
    }
  })
}

export const useQueryBookOfBusinessReportCount = (query, options = {}) => {
  const { userId } = useAppContext()
  const { enabled = true } = options
  return useQuery({
    enabled,
    queryKey: [QUERY_KEYS.bobiQueryOutputCount, userId, query],
    queryFn: async () => {
      const { data } = await postNamedQuery('bobi', 'queryBobiOutputCount', query)
      return data
    }
  })
}

export const useQueryBobiOutputStatus = (query, options = {}) => {
  const { userId } = useAppContext()
  const { enabled = true } = options
  return useQuery({
    enabled,
    queryKey: [QUERY_KEYS.bobiOutputStatus, userId, query],
    refetchInterval: (data) => {
      if (data?.status !== 'completed' && data?.status !== 'error') {
        return options?.interval || 2000
      }
      return undefined
    },
    queryFn: async () => {
      const { data } = await postNamedQuery('bobi', 'getBobiOutputStatus', query)
      return data
    }
  })
}

export const useListBobiOutputs = (query, options = {}) => {
  const { userId } = useAppContext()
  const { enabled = true, interval, mapper } = options
  return useQuery({
    enabled,
    queryKey: [QUERY_KEYS.bobiOutputList, userId, query],
    queryFn: async () => {
      const { data } = await postNamedQuery('bobi', 'listBobiOutputs', query)

      return data
    },
    select: mapper,
    refetchInterval: interval
  })
}

export const exportJson = async (query) => {
  const { bobiOutputId } = query
  let result = []
  const take = 1000
  let hasMore = true
  const paging = { limit: take, offset: 0 }
  let includeHeader = true

  while (hasMore) {
    const { data } = await postNamedQuery('bobi', 'queryBobiOutput', {
      ...paging,
      bobiOutputId,
      includeHeader,
      output: 'json'
    })
    if (data.length) result = [...result, ...data]

    if (includeHeader) {
      includeHeader = false
    }

    hasMore = data.length > 0

    paging.offset += take
  }

  return result
}

export const exportCsv = async (query, options = {}) => {
  const { bobiOutputId } = query
  let result = ''
  const take = 1000
  let hasMore = true
  const paging = { limit: take, offset: 0 }
  let includeHeader = true

  while (hasMore) {
    const { data } = await postNamedQuery('bobi', 'queryBobiOutput', { ...paging, bobiOutputId, includeHeader, output: 'csv' })
    if (data.length) result = `${result}${data}`

    if (includeHeader) {
      includeHeader = false
    }

    hasMore = data.length > 0

    paging.offset += take
  }

  return result
}

//
// export const useExportCsv = (query, options = {}) => {
//   const { userId } = useAppContext()
//   const { enabled = true } = options
//   return useQuery({
//     enabled,
//     queryKey: [QUERY_KEYS.bobiExportCsv, userId, query],
//     queryFn: async () => {
//       const { bobiOutputId } = query
//       let result = ''
//       const take = 500
//       let hasMore = true
//       const paging = { limit: take, offset: 0 }
//
//       while (hasMore) {
//         const { data } = await postNamedQuery('bobi', 'queryBobiOutput', { bobiOutputId, output: 'csv', paging })
//         if (data.length) result = `${result}${data}`
//
//         hasMore = data.length > 0
//
//         paging.offset += take
//       }
//
//       return result
//     }
//   })
// }

export const useListSavedRequests = (query = {}, options = {}) => {
  const { userId } = useAppContext()
  const { enabled = true, mapper } = options
  return useQuery({
    enabled,
    queryKey: [QUERY_KEYS.bobiOutputList, userId, query],
    queryFn: async () => {
      const { data } = await postNamedQuery('bobi', 'listSavedRequests', query)

      return data
    },
    select: mapper
  })
}
