import React, { useCallback, useEffect, useMemo, useState } from 'react'
import noop from 'lodash/noop'
import PropTypes from 'prop-types'
import { Button, makeStyles, Switch } from '@material-ui/core'
import Text from '../../../atoms/Text'
import { useBoolean } from '../../../../hooks'

const useStyles = makeStyles((theme) => ({
  tableActions: {
    display: 'flex',
    alignItems: 'center',
    gap: '1rem',
    '& button': {
      cursor: 'pointer'
    }
  }
}))

const ReportRunRefreshActions = ({
  loading,
  onRefetch,
  intervalInSeconds
}) => {
  const classes = useStyles()

  const [isPolling, setIsPolling] = useBoolean(false)
  const [secondsRemaining, setSecondsRemaining] = useState(intervalInSeconds)
  const [intervalInstance, setRefreshIntervalInstance] = useState(null)

  const autoRefreshMessage = useMemo(() => {
    if (loading) return 'Loading...'
    return `Refreshing in ${secondsRemaining} second${secondsRemaining === 1 ? '' : 's'}`
  }, [loading, secondsRemaining])

  const shouldRefetch = useMemo(() => {
    return isPolling && secondsRemaining <= 0 && !loading
  }, [loading, isPolling, secondsRemaining])

  const initCountdown = useCallback(() => {
    setIsPolling.on()

    clearInterval(intervalInstance)
    setRefreshIntervalInstance(null)

    setSecondsRemaining(intervalInSeconds)

    const interval = setInterval(
      () => setSecondsRemaining((origSecondsRemaining) => origSecondsRemaining - 1),
      1000
    )

    setRefreshIntervalInstance(interval)
  }, [intervalInSeconds, intervalInstance, setIsPolling])

  const destroyCountdown = useCallback(() => {
    setIsPolling.off()
    clearInterval(intervalInstance)
    setRefreshIntervalInstance(null)
  }, [intervalInstance, setIsPolling])

  const toggleCountdown = useCallback(() => {
    if (isPolling) {
      destroyCountdown()
    } else {
      initCountdown()
    }
  }, [isPolling, destroyCountdown, initCountdown])

  const refetch = useCallback(async () => {
    await onRefetch()
    initCountdown()
  }, [onRefetch, initCountdown])

  useEffect(() => {
    if (shouldRefetch) {
      refetch()
    }
  }, [shouldRefetch, refetch])

  return (
    <div className={classes.tableActions}>
      {!isPolling ? (
        <Button
          onClick={loading ? noop : onRefetch}
          style={{ opacity: loading ? 0.5 : 1, pointerEvents: loading ? 'none' : 'auto' }}
          disabled={loading}
          variant='text'
          size='small'
        >
          {loading ? 'Loading...' : 'Refresh'}
        </Button>
      ) : (
        <div style={{ opacity: 0.5 }}>
          <Text text={autoRefreshMessage} variant='body2' />
        </div>
      )}

      <Button
        type='button'
        variant='text'
        color='primary'
        size='small'
        onClick={() => toggleCountdown()}
        className={classes.button}
      >
        <span style={{ marginRight: '.25rem' }}>Autorefresh</span>
        <Switch checked={isPolling} size='small' />
      </Button>
    </div>
  )
}

ReportRunRefreshActions.propTypes = {
  loading: PropTypes.bool,
  onRefetch: PropTypes.func,
  intervalInSeconds: PropTypes.number
}

ReportRunRefreshActions.intervalInSeconds = {
  onRefetch: noop,
  loading: false,
  intervalInSeconds: 10
}

export default ReportRunRefreshActions
