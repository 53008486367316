import React from 'react'
import { Tooltip, makeStyles } from '@material-ui/core'
import isEmpty from 'lodash/isEmpty'
import PropTypes from 'prop-types'
import { useContextualClientContext } from '../context/ContextualClientContext'
import Icon from '../../../atoms/Icon'
import { ICON_NAMES } from '../../../../constants'
import Text from '../../../atoms/Text'
import AdvisorHomeEmptySection from '../../AdvisorHome/AdvisorHomeEmptySection'

const useStyles = makeStyles(() => ({
  list: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    '& span': {
      fontWeight: 'bold',
      fontSize: '0.875rem'
    }
  },
  item: {
    display: 'flex',
    gap: '0.875rem',
    alignItems: 'center'
  },
  content: {
    display: 'flex',
    flexDirection: 'column'
  },
  description: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '20rem'
  }
}))

const ClientTasks = ({ emptyStateLabel }) => {
  const classes = useStyles()
  const { client: { tasks = [] } = {}, isLoading } =
    useContextualClientContext()

  if (isEmpty(tasks) && !isLoading) {
    return (
      <AdvisorHomeEmptySection
        title='No tasks'
        icon={ICON_NAMES.paperTasks}
        description={emptyStateLabel}
      />
    )
  }

  return (
    <div className={classes.list}>
      {tasks.map((task) => {
        return (
          <div key={task.taskId} className={classes.item}>
            <Icon name={ICON_NAMES.listAlt} customSize='1.2rem' />
            <div className={classes.content}>
              <Text text={task.title} customFontWeight='bold' />
              {task?.description && (
                <Tooltip title={task.description}>
                  <Text
                    text={task.description}
                    className={classes.description}
                    customFontSize='0.875rem'
                  />
                </Tooltip>
              )}
            </div>
          </div>
        )
      })}
    </div>
  )
}

ClientTasks.propTypes = {
  emptyStateLabel: PropTypes.string
}

ClientTasks.defaultProps = {
  emptyStateLabel: 'Looks like you have no upcoming tasks'
}

export default ClientTasks
