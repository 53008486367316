import React from 'react'
import PropTypes from 'prop-types'
import { Box, makeStyles } from '@material-ui/core'
import { isEmpty } from 'lodash'
import TableSkeleton from '../../../atoms/TableSkeleton'
import AdvisorHomeEmptySection from '../AdvisorHomeEmptySection'
import { ICON_NAMES } from '../../../../constants'

const useStyles = makeStyles(() => ({
  list: {
    display: 'flex',
    gap: '0.5rem',
    padding: '0.5rem',
    flexDirection: 'column'
  },
  skeletonsContainer: {
    overflowX: 'auto',
    flexWrap: 'nowrap'
  }
}))

const ListLayout = ({ data, isLoading, renderItem, emptyStateLabel }) => {
  const classes = useStyles()

  if (isLoading) {
    return (
      <Box p={2}>
        <TableSkeleton
          firstColumnWithAvatar
          columns={1}
          rows={5}
          showHeader={false}
          rowHeight='2.5rem'
        />
      </Box>
    )
  }

  if (!isLoading && isEmpty(data)) {
    return (
      <AdvisorHomeEmptySection
        icon={ICON_NAMES.paperGlassMagnifier}
        title='No data available'
        description={emptyStateLabel}
        borderedContent
      />
    )
  }

  return <div className={classes.list}>{data.map(renderItem)}</div>
}

ListLayout.propTypes = {
  data: PropTypes.array,
  isLoading: PropTypes.bool,
  emptyStateLabel: PropTypes.string,
  renderItem: PropTypes.oneOfType([PropTypes.func, PropTypes.node])
}

export default ListLayout
