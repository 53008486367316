import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import { Box } from '@material-ui/core'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import SydModal from '../../../../../../commonDesign/SydModal'
import SydButton from '../../../../../../commonDesign/Button'
import { postNamedCommand } from '../../../../../../../service'
import Alert from '../../../../../../atoms/Alert'
import SydLabel, { hookFormErrorAdapter } from '../../../../../../commonDesign/SydLabel'
import SydInput from '../../../../../../commonDesign/SydInput'

dayjs.extend(utc)

const FeeAdjustmentDeleteConfirmModal = ({
  onClose = noop,
  onSuccess = noop,
  title = 'Are you sure?',
  subtitle = '',
  accountId = null,
  billingRunRecordId = null
}) => {
  const formMethods = useForm({
    defaultValues: {
      adjustmentValue: '',
      adjustmentType: 'FIXED',
      adjustmentReason: ''
    },
    reValidateMode: 'onSubmit'
  })

  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting }
  } = formMethods

  const onSubmit = async (data) => {
    try {
      formMethods.clearErrors()
      const { data: { statusCode } } = await postNamedCommand('billing', 'create-fee-adjustment', {
        accountId,
        billingRunRecordId,
        adjustmentReason: data.adjustmentReason,
        isActive: 0,
        adjustmentValue: 0,
        adjustmentType: 'FIXED'
      })
      if (statusCode > 200) {
        throw new Error('Error submitting command')
      }

      onSuccess(billingRunRecordId)
      onClose()

      formMethods.reset({
        adjustmentValue: '',
        adjustmentType: 'FIXED',
        adjustmentReason: ''
      })
    } catch (err) {
      formMethods.setError('root', { message: 'Issue submitting fee adjustment.  Try again' })
    }
  }

  const handleOnClose = useCallback(() => {
    formMethods.reset({
      adjustmentValue: '',
      adjustmentType: 'FIXED',
      adjustmentReason: ''
    })
    onClose()
  }, [formMethods, onClose])

  return (
    <SydModal
      title={title}
      subtitle={subtitle}
      onClose={handleOnClose}
      open={!!accountId && !!billingRunRecordId}
      size='small'
    >
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          {errors.root?.message?.length && (
            <Box mb={4}>
              <Alert
                severity='error'
              >
                {errors.root?.message}
              </Alert>
            </Box>
          )}
          <Controller
            control={control}
            name='adjustmentReason'
            rules={{
              required: 'Reason is required to delete fee adjustment'
            }}
            render={({ field, fieldState }) => (
              <SydLabel
                label='Adjustment Reason'
                required
                error={hookFormErrorAdapter(formMethods, fieldState)}
              >
                <SydInput
                  {...field}
                  disabled={isSubmitting}
                  type='input'
                  placeholder='Enter reason for deleting'
                  error={hookFormErrorAdapter(formMethods, fieldState)}
                />
              </SydLabel>
            )}
          />
          <Box display='flex' justifyContent='space-between' mt={4}>
            <SydButton
              variant='secondary'
              onClick={onClose}
              disabled={isSubmitting}
            >
              Cancel
            </SydButton>
            <SydButton
              type='submit'
              variant='primary'
              priority='warning'
              onClick={(e) => {
                formMethods.clearErrors('root')
                handleSubmit(onSubmit)(e)
              }}
              disabled={isSubmitting}
            >
              Delete
            </SydButton>
          </Box>
        </form>
      </FormProvider>
    </SydModal>
  )
}

FeeAdjustmentDeleteConfirmModal.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
  accountId: PropTypes.number,
  billingRunRecordId: PropTypes.number
}

export default FeeAdjustmentDeleteConfirmModal
