import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import dayjs from 'dayjs'
import { useFormattingContext } from '../../../../../organisms/FormattingProvider/FormattingContext'

const useStyles = makeStyles((theme) => ({
  invitation: {
  },
  invitationTable: {
    borderCollapse: 'collapse',
    fontFeatureSettings: 'tnum',
    '& thead th': {
      textAlign: 'left',
      paddingLeft: theme.layout.padding.thin,
      paddingRight: theme.layout.padding.thin
    },
    '& tbody td': {
      fontWeight: theme.typography.weights.light,
      paddingLeft: theme.layout.padding.thin,
      paddingRight: theme.layout.padding.thin
    }
  }
}))

function Invitations ({ invitations }) {
  const classes = useStyles()
  const { formatter } = useFormattingContext()
  const _invitations = useMemo(() => {
    return (invitations || []).map((inv, idx) => ({
      ...inv,
      accepted: !!inv.acceptedDate,
      expired: !inv.acceptedDate && dayjs().isAfter(inv.expirationDate),
      outstanding: idx === 0 && !inv.acceptedDate && dayjs().isBefore(inv.expirationDate)
    }))
  }, [invitations])

  if (!_invitations?.length) {
    return null
  }

  return (
    <div className={classes.invitation}>
      <table className={classes.invitationTable}>
        <thead>
          <tr>
            <th>Invited On</th>
            <th>Accepted On</th>
            <th>Status</th>
            <th>Invited By</th>
          </tr>
        </thead>
        <tbody>
          {_invitations.map(ui => (
            <tr key={ui.invitationId}>
              <td>{formatter(ui.createdDate, '@M/D/YY h:mm A')}</td>
              <td>{formatter(ui.acceptedDate, '@M/D/YY h:mm A')}</td>
              <td>{ui.outstanding ? (
                `Expires ${formatter(ui.expirationDate, 'ß')}`
              ) : (
                ui.accepted
                  ? 'Accepted'
                  : ui.expired
                    ? 'Expired'
                    : 'Invalid'
              )}
              </td>
              <td>{ui.createdByUserName}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

Invitations.propTypes = {
  invitations: PropTypes.arrayOf(PropTypes.shape({
    invitationId: PropTypes.number,
    createdDate: PropTypes.string,
    expirationDate: PropTypes.string,
    acceptedDate: PropTypes.string
  }))
}

export default Invitations
