import React, { useCallback, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { Picker } from 'emoji-mart'
import { makeStyles, Popover } from '@material-ui/core'
import data from 'emoji-mart/data/apple.json'
import noop from 'lodash/noop'
import { EMOJI_PICKER_THEMES } from '../../constants'
import 'emoji-mart/css/emoji-mart.css'

const useStyles = makeStyles(() => ({
  popover: {
    zIndex: '1301 !important'
  },
  paper: {
    borderRadius: '0.5rem'
  }
}))

const EmojiPicker = ({ theme, onSelect, open, toggle, children, childrenContainerClassName }) => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState(null)
  const popoverActions = useRef(null)

  useEffect(() => {
    if (popoverActions?.current) {
      popoverActions.current.updatePosition()
    }
  })

  const onToggleOn = useCallback(
    (event) => {
      setAnchorEl(event.currentTarget)
      toggle()
    },
    [toggle]
  )

  const onToggleOff = useCallback(
    () => {
      setAnchorEl(null)
      toggle()
    },
    [toggle]
  )

  return (
    <div>
      <Popover
        keepMounted
        action={popoverActions}
        open={open}
        anchorEl={anchorEl}
        onClose={onToggleOff}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        classes={{
          root: classes.popover,
          paper: classes.paper
        }}
      >
        <Picker
          theme={theme}
          set='apple'
          emoji='point_up'
          title='Pick your emoji...'
          data={data}
          onSelect={onSelect}
        />
      </Popover>
      <div ref={anchorEl} className={childrenContainerClassName} onClick={onToggleOn}>
        {children}
      </div>
    </div>
  )
}

EmojiPicker.propTypes = {
  open: PropTypes.bool,
  theme: PropTypes.string,
  toggle: PropTypes.func,
  onSelect: PropTypes.func,
  children: PropTypes.any.isRequired,
  childrenContainerClassName: PropTypes.string
}

EmojiPicker.defaultProps = {
  open: false,
  theme: EMOJI_PICKER_THEMES.light,
  toggle: noop,
  onSelect: noop,
  childrenContainerClassName: ''
}

export default EmojiPicker
