import AccountSubscriptionFilter from './DomainFilters/AccountSubscriptionFilter'
import GroupSubscriptionFilter from './DomainFilters/GroupSubscriptionFilter'
import GenericStaticSubscriptionFilter from './DomainFilters/StaticSubscriptionFilter'
import FilterSubscriptionContainer from './FilterSubscriptionContainer'
import FilterSubscriptionProvider from './FilterSubscriptionProvider'
import AccountAssetSubscriptionFilter from './DomainFilters/AccountAssetSubscriptionFilter'

const FilterSubscriptionComponents = {
  FilterSubscriptionProvider,
  FilterSubscriptionContainer,
  AccountSubscriptionFilter,
  AccountAssetSubscriptionFilter,
  GroupSubscriptionFilter,
  GenericStaticSubscriptionFilter
}

export default FilterSubscriptionComponents
