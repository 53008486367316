import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import { useQueryPlatformDataSource } from '../../../api/ingestion'
import { useAppContext } from '../../../redux/slices/appContext'

const useStyles = makeStyles((theme) => ({
  platformDataSourceItem: {
    padding: '.2rem'
  }
}))

const usePlatformDataSource = (platformDataSourceId, query, clientContextKey) => {
  const { clientId } = useAppContext()
  const resolvedQuery = useMemo(() => {
    const qr = {
      platformDataSourceId,
      options: {
        flatten: true
      },
      ...(query ?? {})
    }

    if (clientContextKey) {
      qr.filters = qr.filters || []
      qr.filters.push({
        field: clientContextKey,
        op: 'eq',
        value: clientId
      })
    }

    return qr
  }, [platformDataSourceId, query, clientContextKey, clientId])
  const { data, isLoading, error } = useQueryPlatformDataSource(resolvedQuery)

  return {
    data,
    isLoading,
    error
  }
}

const PlatformDataSourceItem = ({
  platformDataSourceId,
  query,
  clientContextKey,
  accessor,
  style,
  className,
  children
}) => {
  const { data, isLoading } = usePlatformDataSource(platformDataSourceId, query, clientContextKey)
  const itemValue = useMemo(() => {
    if (isLoading) {
      return null
    }

    const firstItem = data?.at(0) ?? {}
    return firstItem[accessor]
  }, [data, isLoading, accessor])

  const derivedStyle = useMemo(() => {
    if (typeof style === 'object') {
      return Object.entries(style).reduce((agg, [key, value]) => {
        agg[key] = value
        if (typeof value === 'string' && value === '$value') {
          agg[key] = itemValue
        }
        return agg
      }, {})
    }
    return style
  }, [style, itemValue])

  const derivedValue = useMemo(() => {
    const itemResult = (typeof children === 'function') ? children(itemValue) : itemValue
    // eslint-disable-next-line no-debugger
    return itemResult
  }, [itemValue, children])
  const classes = useStyles()

  if (isLoading || !itemValue) {
    return null
  }

  return (
    <div className={clsx(classes.platformDataSourceItem, className)} style={derivedStyle}>
      {derivedValue}
    </div>
  )
}

PlatformDataSourceItem.propTypes = {
  platformDataSourceId: PropTypes.number,
  query: PropTypes.object,
  clientContextKey: PropTypes.string,
  accessor: PropTypes.string.isRequired,
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func])
}

PlatformDataSourceItem.defaultProps = {
  query: {},
  useClientContext: false,
  template: value => value
}

export default PlatformDataSourceItem
