import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { postNamedCommand, postNamedQuery } from '../service'
import { useAppContext } from '../redux/slices/appContext'
import { useWebsocketSubscription } from '../providers/WebsocketProvider'

export const useListConversations = (query = {}) => {
  const { userId } = useAppContext()
  return useQuery({
    queryKey: ['list-conversations', userId, query],
    queryFn: async () => {
      const { data } = await postNamedQuery('conversations', 'list-conversations', query)

      return data
    }
  })
}

export const useConversationDetails = (conversationKey) => {
  const { userId } = useAppContext()
  return useQuery({
    enabled: !!conversationKey,
    queryKey: ['get-conversation-details', userId, conversationKey],
    queryFn: async () => {
      const { data } = await postNamedQuery('conversations', 'get-conversation-details', {
        conversationKey
      })

      return data
    }
  })
}

export const useSearchClientConversations = (query, queryOptions = {}) => {
  const { userId } = useAppContext()
  const { mapper, enabled = true } = queryOptions
  return useQuery({
    queryKey: ['search-client-conversations', userId, query],
    queryFn: async () => {
      const { data } = await postNamedQuery('conversations', 'search-client-conversations', query)
      return data
    },
    enabled,
    select: mapper
  })
}

export const useCreateConversationMutation = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async ({ name, clientId, includedWealthOwners }) => {
      const { data } = await postNamedCommand('conversations', 'create-conversation', {
        name,
        clientId,
        includedWealthOwners
      })

      return data
    },
    onSuccess: () => {
      queryClient.invalidateQueries('search-client-conversations')
    }
  })
}

export const useAddMessageMutation = () => {
  return useMutation({
    mutationFn: async ({ conversationKey, message }) => {
      const { data } = await postNamedCommand('conversations', 'add-conversation-message', {
        conversationKey,
        message
      })

      return data
    }
  })
}

export const useJoinConversationMutation = () => {
  return useMutation({
    mutationFn: async ({ conversationKey, userId }) => {
      const { data } = await postNamedCommand('conversations', 'add-conversation-participant', {
        conversationKey,
        userId
      })

      return data
    }
  })
}

export const useDefaultParticipants = (clientId) => {
  const { userId } = useAppContext()
  return useQuery({
    queryKey: ['conversations-default-participants', userId, clientId],
    queryFn: async () => {
      const { data } = await postNamedQuery('conversations', 'get-default-participants', {
        clientId
      })

      return data
    }
  })
}

const messageAddedTopic = 'conversation/{conversationKey}/message_added/{messageKey}'
const participantAddedTopic = 'conversation/{conversationKey}/participant_added/{participantKey}'
export const useConversationUpdates = (conversationKey) => {
  const { userId } = useAppContext()
  const queryClient = useQueryClient()
  useWebsocketSubscription((subscribe, unsubscribe) => {
    const handleNewMessage = (topic, tokens) => {
      queryClient.fetchQuery({
        queryKey: ['get-conversation-message', userId, tokens.conversationKey, tokens.messageKey],
        queryFn: async () => {
          const { data } = await postNamedQuery('conversations', 'get-conversation-message', {
            conversationKey: tokens.conversationKey,
            messageKey: tokens.messageKey
          })
          return data
        }
      }).then(newMessage => {
        queryClient.setQueryData(['get-conversation-details', userId, conversationKey], oldData => {
          return {
            ...oldData,
            messages: [...oldData.messages, newMessage]
          }
        })
      })
    }

    const handleNewParticipant = (topic, tokens) => {
      queryClient.fetchQuery({
        queryKey: ['get-conversation-participant', userId, tokens.conversationKey, tokens.participantKey],
        queryFn: async () => {
          const { data } = await postNamedQuery('conversations', 'get-conversation-participant', {
            conversationKey: tokens.conversationKey,
            participantKey: tokens.participantKey
          })
          return data
        }
      }).then(newParticipant => {
        queryClient.setQueryData(['get-conversation-details', userId, conversationKey], oldData => {
          const existingIndex = oldData.participants.findIndex(x => x.participantKey === newParticipant.participantKey)
          if (existingIndex === -1) {
            return {
              ...oldData,
              participants: [...oldData.participants, newParticipant]
            }
          }
          const result = [...oldData.participants]
          result[existingIndex] = newParticipant
          return {
            ...oldData,
            participants: result
          }
        })
      })
    }

    const messageAddedHandle = subscribe(messageAddedTopic, handleNewMessage)
    const participantAddedHandle = subscribe(participantAddedTopic, handleNewParticipant)
    return () => {
      unsubscribe(messageAddedTopic, messageAddedHandle)
      unsubscribe(participantAddedTopic, participantAddedHandle)
    }
  }, [conversationKey])
}
