import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

export const getBookOfBusinessReportUrl = (to, reportParameters) => {
  const { filters, metadata, outputFilters, ...reportParamsRest } =
    reportParameters

  const params = new URLSearchParams({
    ...reportParamsRest,
    filters: JSON.stringify(filters),
    outputFilters: JSON.stringify(outputFilters),
    metadata: JSON.stringify(metadata)
  })
  return `${to}?${params.toString()}`
}

const BookOfBusinessResultsLink = ({ to, reportParameters, children }) => {
  const url = useMemo(() => {
    return getBookOfBusinessReportUrl(to, reportParameters)
  }, [to, reportParameters])
  return <Link to={url}>{children}</Link>
}

BookOfBusinessResultsLink.propTypes = {
  to: PropTypes.string,
  reportParameters: PropTypes.object,
  children: PropTypes.node.isRequired
}

BookOfBusinessResultsLink.defaultProps = {
  to: '/views/bobi/results',
  reportParameters: {}
}

export default BookOfBusinessResultsLink
