import React from 'react'
import PropTypes from 'prop-types'
import SydButton from '../../../../commonDesign/Button'
import { useRecalcBenchmarkMutation } from '../../../../../api/benchmarks'

function RecalcButton ({ benchmarkId, disabled }) {
  const { mutateAsync: recalc, isLoading } = useRecalcBenchmarkMutation()

  return (
    <SydButton
      variant='outline'
      priority='warning'
      icon='refresh'
      onClick={() => recalc(benchmarkId).catch(console.error)}
      processing={isLoading}
      disabled={disabled}
    >
      Recalc
    </SydButton>
  )
}

RecalcButton.propTypes = {
  benchmarkId: PropTypes.number,
  disabled: PropTypes.bool
}

export default RecalcButton
