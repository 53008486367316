import { useQuery } from '@tanstack/react-query'
import { useAppContext } from '../redux/slices/appContext'
import { integrations } from '../service'
import { REDTAIL_INTEGRATION } from '../constants'
import { QUERY_KEYS } from './queryKeys'

export const useRedtailSyncStatus = (clientId, queryOptions) => {
  const { userId } = useAppContext()
  const { mapper, refetchInterval, enabled = true } = queryOptions
  return useQuery({
    queryKey: [QUERY_KEYS.redtailSyncStatus, userId, clientId],
    queryFn: async () => {
      const { data } = await integrations[REDTAIL_INTEGRATION].syncStatus(
        clientId
      )
      return data
    },
    refetchInterval,
    select: mapper,
    enabled
  })
}
