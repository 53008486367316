import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { ContextContainerProvider } from '../../../../abundanceEngine/components/ContextContainer'
import { BOBI_REPORT_LIST_CONTEXT_KEY } from './helpers'
import ReportListContainer from './ReportListContainer'

const ReportList = ({
  columnConfig,
  tableConfigOptions,
  useSymbolInsteadOfZero
}) => {
  const contextState = useMemo(
    () => ({
      name: BOBI_REPORT_LIST_CONTEXT_KEY,
      initialState: {
        defaultColumnConfig: columnConfig,
        useSymbolInsteadOfZero,
        tableConfigOptions
      }
    }),
    [
      columnConfig,
      tableConfigOptions,
      useSymbolInsteadOfZero
    ]
  )

  return (
    <ContextContainerProvider context={contextState}>
      <ReportListContainer />
    </ContextContainerProvider>
  )
}

ReportList.propTypes = {
  columnConfig: PropTypes.object,
  tableConfigOptions: PropTypes.object,
  useSymbolInsteadOfZero: PropTypes.string
}

ReportList.defaultProps = {
  columnConfig: {},
  tableConfigOptions: {
    hideCustomizeColumnsColumnHiding: false,
    hideCustomizeColumnsButton: false,
    hideExpandCollapseButton: true,
    hideGroupingsUnderline: true,
    hideExportTypePicker: true,
    hideExportButton: false
  },
  useSymbolInsteadOfZero: '--'
}

export default ReportList
