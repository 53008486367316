import React from 'react'
import FadeIn from '../../../../molecules/Transitions/FadeIn'

function ReportingTab () {
  return (
    <FadeIn>
      Coming Soon!
    </FadeIn>
  )
}

ReportingTab.propTypes = {
}

export default ReportingTab
