import React, { useCallback, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { Skeleton as MuiSkeleton } from '@material-ui/lab'
import { Box } from '@material-ui/core'
import { SKELETON_ANIMATIONS, SKELETON_VARIANTS } from '../../constants'

const BarElement = () => {
  const [heightAdjustment, setHeightAdjustment] = useState(Math.random())
  const timeout = useRef(null)
  const height = heightAdjustment * 100 + '%'

  const updateHeight = useCallback(() => {
    timeout.current = setTimeout(() => {
      setHeightAdjustment(Math.random())
      updateHeight()
    }, 2000)
  }, [])

  useEffect(() => {
    updateHeight()
    return () => clearTimeout(timeout.current)
  }, [updateHeight])

  return (
    <Box height={height} width='100%' style={{ transition: 'height 2s ease-in-out' }}>
      <MuiSkeleton
        animation='wave'
        height='100%'
        variant='rect'
        width='100%'
      />
    </Box>
  )
}

const BarChartSkeleton = ({ animation, height, variant, width, className, barCount }) => {
  return (
    <Box display='flex' height={height} gridGap='30px' alignItems='end'>
      {Array.from({ length: barCount }).map((_, i) => (
        <BarElement key={i} />
      ))}
    </Box>
  )
}

BarChartSkeleton.propTypes = {
  animation: PropTypes.oneOf(Object.values(SKELETON_ANIMATIONS)),
  height: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  variant: PropTypes.oneOf(Object.values(SKELETON_VARIANTS)),
  width: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  className: PropTypes.string,
  barCount: PropTypes.number
}

BarChartSkeleton.defaultProps = {
  animation: SKELETON_ANIMATIONS.pulse,
  height: '300px',
  variant: SKELETON_VARIANTS.rect,
  width: '100%',
  className: '',
  barCount: 5
}

export default BarChartSkeleton
