import React from 'react'
import PropTypes from 'prop-types'
import SectionContent from '../Section/SectionContent'
import { useAdvisorViewTabGroup } from '../../AdvisorView/components/AdvisorViewTabs/advisorViewTabGroupContext'

const SectionToggleItem = ({ value, children, ...props }) => {
  const { selectedValue } = useAdvisorViewTabGroup()
  if (selectedValue !== value) return null
  return (
    <SectionContent {...props}>{children}</SectionContent>
  )
}

SectionToggleItem.propTypes = {
  value: PropTypes.string,
  children: PropTypes.node
}

export default SectionToggleItem
