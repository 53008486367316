import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import {
  postNamedCommand,
  postNamedQuery
} from '../../service'
import { useAppContext } from '../../redux/slices/appContext'

export const useCheckSsoAccess = (providerId) => {
  return useQuery({
    queryKey: ['users.check-sso-access', providerId],
    enabled: !!providerId,
    queryFn: async () => {
      const { data } = await postNamedQuery('users', 'check-sso-access', {
        providerId
      })

      return data
    }
  })
}

export const useStartOutboundSso = () => {
  const { clientId } = useAppContext()
  return useMutation({
    mutationFn: async (providerId, relay) => {
      const { data } = await postNamedCommand('users', 'start-outbound-sso', {
        providerId,
        relay: {
          clientId,
          ...(relay || {})
        }
      })

      return data
    }
  })
}

export const useListSsoProviders = () => {
  const { userId } = useAppContext()
  return useQuery({
    queryKey: ['users.list-sso-providers', userId],
    queryFn: async () => {
      const { data } = await postNamedQuery('users', 'list-sso-providers', {})

      return data
    }
  })
}

export const useGetUserSsoAuthorizations = (userId) => {
  const { userId: caller } = useAppContext()
  return useQuery({
    queryKey: ['users.get-user-sso-authorizations', caller, userId],
    queryFn: async () => {
      const { data } = await postNamedQuery('users', 'get-user-sso-authorizations', {
        userId
      })

      return data
    }
  })
}

export const useGetRoleSsoAuthorizations = (roleId) => {
  const { userId: caller } = useAppContext()
  return useQuery({
    queryKey: ['users.get-role-sso-authorizations', caller, roleId],
    queryFn: async () => {
      const { data } = await postNamedQuery('users', 'get-role-sso-authorizations', {
        roleId
      })

      return data
    }
  })
}

export const useModifyUserSsoAuthorization = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (command) => {
      const { data } = await postNamedCommand('users', 'modify-user-sso-authorization', {
        userId: command.userId,
        providerId: command.providerId,
        authorization: command.authorization
      })

      return data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['users.get-user-sso-authorizations'], refetchType: 'all' }).catch(console.error)
    }
  })
}

export const useModifyRoleSsoAuthorization = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (command) => {
      const { data } = await postNamedCommand('users', 'modify-role-sso-authorization', {
        roleId: command.roleId,
        providerId: command.providerId,
        authorization: command.authorization
      })

      return data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['users.get-role-sso-authorizations'], refetchType: 'all' }).catch(console.error)
    }
  })
}

export const useGetUserSsoActivity = (userId, providerId) => {
  const { userId: caller } = useAppContext()
  return useQuery({
    queryKey: ['users.get-user-sso-activity', caller, userId, providerId],
    queryFn: async () => {
      const { data } = await postNamedQuery('users', 'get-user-sso-activity', {
        userId,
        providerId
      })

      return data
    }
  })
}
