import React from 'react'
import PropTypes from 'prop-types'
import FadeIn from '../../../../molecules/Transitions/FadeIn'
import PersonalSpace from '../../../../molecules/PersonalSpace'
import OutboundSsoSection from './OutboundSsoSection'

/** as opposed to AdvisorIntegrationsTab */
function WealthOwnerIntegrationsTab ({ isEditing, role }) {
  return (
    <FadeIn>
      <OutboundSsoSection isEditing={isEditing} role={role} />
      <PersonalSpace />
    </FadeIn>
  )
}

WealthOwnerIntegrationsTab.propTypes = {
  isEditing: PropTypes.bool,
  role: PropTypes.shape({
    roleId: PropTypes.number
  })
}

export default WealthOwnerIntegrationsTab
