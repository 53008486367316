import React, { useCallback, useRef } from 'react'
import { useClientDetails, useSectionEditing } from '../ClientDetailsFormContext'
import {
  useClientTeamMembers,
  useModifyClientTeamMembersMutation
} from '../../../../../api/clients'
import SectionScreen from '../SectionScreen'
import SectionHeader from '../../shared/SectionHeader'
import Loading from '../../../../molecules/Loading'
import PersonalSpace from '../../shared/PersonalSpace'
import EditButton from '../EditButton'
import ClientTeamForm from './ClientTeamForm'
import ClientTeamDisplay from './ClientTeamDisplay'

function ClientTeamSection () {
  const { client, editSection, refetchClient } = useClientDetails()
  const editing = useSectionEditing('client_team')
  const { data, isLoading } = useClientTeamMembers(client.clientId)

  const formRef = useRef()
  const { mutateAsync: modifyClientTeamMembers } = useModifyClientTeamMembersMutation()
  const onSave = useCallback(async (e) => {
    const onValid = async (form) => {
      try {
        await modifyClientTeamMembers({
          clientId: client.clientId,
          teamMembers: form.teamMembers.map((x, i) => ({
            ...x,
            ordinal: i
          }))
        })
        await refetchClient()
      } catch (err) {
        console.error('error trying to save client team members', err)
        alert('There was an unexpected problem saving the request.')
        throw err
      }
    }
    const onInvalid = (errors) => {
      console.error(errors)
    }

    const handler = formRef.current.handleSubmit(onValid, onInvalid)
    await handler(e)
  }, [formRef, client, modifyClientTeamMembers, refetchClient])

  if (isLoading) {
    return (
      <Loading />
    )
  }

  return (
    <SectionScreen sectionName='client_team'>
      <SectionHeader text='Client Team'>
        <div>
          <EditButton
            policy='admin_edit_clientinfo'
            editing={editing}
            onClick={() => editSection({
              section: 'client_team',
              saveDescription: 'Save Client Team',
              onSave
            })}
          />
        </div>
      </SectionHeader>
      {editing ? (
        <ClientTeamForm ref={formRef} teamMembers={data} />
      ) : (
        <ClientTeamDisplay data={data} />
      )}
      <PersonalSpace />
    </SectionScreen>
  )
}

export default ClientTeamSection
