import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { useClientDates } from '../../../../../api/coreData'
import AsOfDate from '../../../../molecules/AsOfDate'
import FadeIn from '../../../../molecules/Transitions/FadeIn'

const useStyles = makeStyles((theme) => ({
  container: {
    textAlign: 'right',
    marginBottom: '15px'
  }
}))

function ClientAsOfDate ({ clientId }) {
  const classes = useStyles()
  const { data: dates, isLoading: datesLoading } = useClientDates(clientId)

  if (datesLoading) {
    return (
      <div className={classes.container} />
    )
  }

  return (
    <FadeIn className={classes.container} delay={0.2}>
      <AsOfDate date={dates.max} />
    </FadeIn>
  )
}

ClientAsOfDate.propTypes = {
  clientId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
}

export default ClientAsOfDate
