import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import TargetMappingTable from '../components/TargetMappingTable'
import LabeledValue from '../components/LabeledValue'
import Hideable from '../components/Hideable'

const useStyles = makeStyles((theme) => ({
  targetDetail: {
    borderBottom: `1px solid ${theme.palette.gray.dark}`
  },
  fieldMapping: {
    marginBottom: '.5rem',
    paddingLeft: '.5rem',
    borderLeft: `2px solid ${theme.palette.gray.dark}`
  },
  codeBlock: {
    backgroundColor: theme.palette.gray.main,
    padding: '1rem',
    whiteSpace: 'pre'
  }
}))

const TargetDetail = ({ target }) => {
  const classes = useStyles()
  const hasTarget = target
  const hasTargetColumns = target?.columns?.length > 0

  return (
    <section className={classes.targetDetail}>
      <Hideable label='Target'>
        {!hasTarget && <div>No Target Assigned</div>}
        {hasTarget && (
          <section>
            <LabeledValue label='Target ID'>{target.ingestionTargetId}</LabeledValue>
            <LabeledValue label='Name'>{target.name}</LabeledValue>
            <LabeledValue label='Table'>{target.table}</LabeledValue>
            <LabeledValue label='Schema'>{target.schema}</LabeledValue>
          </section>
        )}
        {hasTarget && !hasTargetColumns && <div>No Target Fields</div>}
        {hasTargetColumns && (
          <TargetMappingTable target={target} />
        )}
      </Hideable>
    </section>
  )
}

TargetDetail.propTypes = {
  target: PropTypes.shape({
    ingestionTargetId: PropTypes.number,
    name: PropTypes.string,
    schema: PropTypes.string,
    table: PropTypes.string,
    columns: PropTypes.arrayOf(PropTypes.shape({
      columnName: PropTypes.string,
      dataType: PropTypes.string,
      nullable: PropTypes.bool,
      min: PropTypes.number,
      max: PropTypes.number,
      defaultValue: PropTypes.string,
      truncate: PropTypes.bool,
      isKey: PropTypes.bool
    }))
  })
}

export default TargetDetail
