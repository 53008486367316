import { ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper, makeStyles } from '@material-ui/core'
import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { noop } from 'lodash'
import Text from '../../atoms/Text'

const useStyles = makeStyles(() => ({
  popper: ({ width }) => ({
    zIndex: 2,
    width: width || 'unset'
  }),
  container: ({ isEmpty }) => ({
    maxHeight: '20rem',
    minWidth: '18rem',
    overflowY: 'auto',
    ...(isEmpty ? { padding: '0 !important' } : {})
  }),
  item: {
    padding: '1.25rem'
  },
  popupContainer: {
    background: '#FFFFFF',
    boxShadow: '0px 4px 12px rgba(24, 27, 53, 0.08)',
    borderRadius: '12px'
  }
}))

const SearchBarOptions = ({
  open,
  setOpen,
  options,
  placement,
  anchorRef,
  onSelectOption,
  disableAutoFocus
}) => {
  const classes = useStyles({ width: anchorRef?.current?.offsetWidth })

  const handleClose = useCallback(
    (event) => {
      if (anchorRef.current && anchorRef.current.contains(event.target)) {
        return
      }
      setOpen.off()
    },
    [setOpen, anchorRef]
  )

  const handleListKeyDown = useCallback(
    (event) => {
      if (event.key === 'Tab') {
        event.preventDefault()
        setOpen.off()
      }
    },
    [setOpen]
  )

  return (
    <Popper
      transition
      open={open}
      role={undefined}
      placement={placement}
      className={classes.popper}
      anchorEl={anchorRef?.current}
    >
      {({ TransitionProps, placement }) => {
        const transformOrigin =
          placement === 'bottom' ? 'center top' : 'center bottom'

        return (
          <Grow {...TransitionProps} style={{ transformOrigin }}>
            <Paper className={classes.popupContainer}>
              <ClickAwayListener onClickAway={handleClose}>
                <div>
                  <MenuList
                    autoFocusItem={!disableAutoFocus && open}
                    disableAutoFocus={disableAutoFocus}
                    disableEnforceFocus={disableAutoFocus}
                    id='menu-list-grow'
                    onKeyDown={handleListKeyDown}
                    className={classes.container}
                  >
                    {options.map((option) => {
                      return (
                        <MenuItem
                          key={`${option.value}-${option.label}`}
                          className={classes.item}
                          value={option.value || option}
                          disabled={option.disabled}
                          onClick={(event) => onSelectOption(event, option)}
                        >
                          <Text text={option.label} customFontWeight='600' />
                        </MenuItem>
                      )
                    })}
                  </MenuList>
                </div>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )
      }}
    </Popper>
  )
}

SearchBarOptions.propTypes = {
  open: PropTypes.bool,
  placement: PropTypes.string,
  options: PropTypes.array,
  anchorRef: PropTypes.object,
  onSelectOption: PropTypes.func,
  setOpen: PropTypes.shape({
    off: PropTypes.func
  }),
  disableAutoFocus: PropTypes.bool
}

SearchBarOptions.defaultProps = {
  open: false,
  options: [],
  anchorRef: {},
  onSelectOption: noop,
  placement: 'bottom-start',
  disableAutoFocus: false
}

export default SearchBarOptions
