import React from 'react'
import PropTypes from 'prop-types'
import { Box, Divider } from '@material-ui/core'
import Skeleton from '../atoms/Skeleton'
import { SKELETON_VARIANTS } from '../../constants'

const NoteThreadClientSkeleton = ({ repeat, withDivider }) => {
  return (
    <Box px={2}>
      {new Array(repeat).fill(0).map((_, index) => {
        return (
          <React.Fragment key={`skeleton-${index}`}>
            <Box py={3}>
              <Box display='flex' alignItems='center' width='100%'>
                <Skeleton
                  variant={SKELETON_VARIANTS.circle}
                  width='36px'
                  height='32px'
                />
                <Box width='100%' pl={1}>
                  <Skeleton
                    variant={SKELETON_VARIANTS.text}
                    width='100%'
                    height='16px'
                  />
                  <Box>
                    <Skeleton
                      variant={SKELETON_VARIANTS.text}
                      width='40%'
                      height='12px'
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
            {index + 1 !== repeat && repeat > 1 && withDivider && <Divider />}
          </React.Fragment>
        )
      })}
    </Box>
  )
}

NoteThreadClientSkeleton.propTypes = {
  repeat: PropTypes.number,
  withDivider: PropTypes.bool
}

NoteThreadClientSkeleton.defaultProps = {
  repeat: 1,
  withDivider: true
}

export default NoteThreadClientSkeleton
