import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import PresentationTable from '../../organisms/PresentationTable'
import { defaultOptions, useRelativeDateRange } from '../RelativeDateSelect'
import { useComponentsOfChangeQuery, useNormalizeDates } from '../../../api/coreData'
import { LEVEL_TYPES } from '../../../constants'
import { useAppContext } from '../../../redux/slices/appContext'
import { useWidgetContext } from '../WidgetWrapper'
import { COMPONENTS_OF_CHANGE, DATA_POINTS_GROUP_BY, getColumns, getQueryDateRanges, mapComponentsOfChangeData } from './helpers'
import { useColumns } from './columnConfig'

dayjs.extend(utc)

const ComponentsOfChangePivotTable = ({
  clientId,
  tableConfig,
  groupBySpec,
  defaultFilter: _defaultFilter,
  dateRangeOptions,
  selectedDateRange
}) => {
  const { availableDates, clientId: _clientId } = useAppContext()

  const { dateRange } = useRelativeDateRange(
    selectedDateRange,
    availableDates,
    dateRangeOptions
  )

  const queryDateRanges = useMemo(() => {
    return getQueryDateRanges(
      dateRange,
      groupBySpec
    )
  }, [dateRange, groupBySpec])

  const normalizedDatesQuery = useMemo(
    () => ({ dateRanges: queryDateRanges }),
    [queryDateRanges]
  )

  const {
    data: normalizedDateRanges,
    isLoading: isLoadingNormalizedDates
  } = useNormalizeDates(normalizedDatesQuery)

  const columnConfig = useMemo(() => {
    const { defaultSort } = tableConfig
    const columns = getColumns(tableConfig, groupBySpec)
    return { columns, defaultSort }
  }, [tableConfig, groupBySpec])

  const { columns, defaultSort } = useColumns(columnConfig)

  const { subscribedFilters } = useWidgetContext()

  const defaultFilter = useMemo(() => {
    return Object.assign({}, _defaultFilter, subscribedFilters?.levelFilters)
  }, [_defaultFilter, subscribedFilters?.levelFilters])

  const { query, queryOptions } = useMemo(() => {
    const components = tableConfig.dataPoints.reduce(
      (acc, { key: dataPoint, params }) => ({
        ...acc,
        [dataPoint]: params ?? true
      }),
      {}
    )
    return {
      query: {
        levelFilters: {
          levelTypes: [LEVEL_TYPES.CLIENT],
          clientIds: [clientId ?? _clientId],
          ...(defaultFilter ?? {})
        },
        dateRange: normalizedDateRanges,
        components
      },
      queryOptions: {
        enabled: !isLoadingNormalizedDates,
        mapper: (data) => mapComponentsOfChangeData(data, groupBySpec)
      }
    }
  }, [
    _clientId,
    clientId,
    groupBySpec,
    defaultFilter,
    normalizedDateRanges,
    tableConfig.dataPoints,
    isLoadingNormalizedDates
  ])

  const { data, isLoading } = useComponentsOfChangeQuery(query, queryOptions)

  return (
    <PresentationTable
      data={data ?? []}
      loading={isLoading}
      columns={columns}
      defaultSort={defaultSort}
    />
  )
}

export const componentsOfChangePivotTable = {
  clientId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  tableConfig: PropTypes.shape({
    dataPoints: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        key: PropTypes.oneOf(Object.values(COMPONENTS_OF_CHANGE))
      })
    ),
    defaultSort: PropTypes.array
  }),
  groupBySpec: PropTypes.shape({
    format: PropTypes.string,
    header: PropTypes.string,
    align: PropTypes.string,
    tooltip: PropTypes.string,
    cellTooltip: PropTypes.bool,
    timeLeapUnit: PropTypes.number,
    cellTooltipFormat: PropTypes.string,
    groupBy: PropTypes.oneOf(Object.values(DATA_POINTS_GROUP_BY))
  }),
  selectedDateRange: PropTypes.string,
  dateRangeOptions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      label: PropTypes.string
    })
  ),
  defaultFilter: PropTypes.object
}

ComponentsOfChangePivotTable.propTypes = componentsOfChangePivotTable

ComponentsOfChangePivotTable.defaultProps = {
  tableConfig: {
    dataPoints: [],
    defaultSort: [
      {
        id: DATA_POINTS_GROUP_BY.year,
        desc: false
      }
    ]
  },
  groupBySpec: {
    format: 'YYYY',
    timeLeapUnit: 1,
    header: 'Year over Year',
    groupBy: DATA_POINTS_GROUP_BY.year,
    align: 'left',
    tooltip: null,
    cellTooltip: true,
    cellTooltipFormat: 'MM/DD/YYYY'
  },
  selectedDateRange: 'SI',
  dateRangeOptions: defaultOptions,
  defaultFilter: undefined
}

export default ComponentsOfChangePivotTable
