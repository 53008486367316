import React, { useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useQueryClient } from '@tanstack/react-query'
import {
  Container,
  Grid,
  Box,
  Divider,
  useTheme,
  makeStyles
} from '@material-ui/core'
import dayjs from 'dayjs'

import TextInput from '../../../../molecules/TextInput'
import Text from '../../../../atoms/Text'

import OperationalTable, { useOperationalTable } from '../../../../organisms/OperationalTable'
import { useSourceBenchmarks, useGetBenchmarkValues } from '../../../../../api/benchmarks'
import { useAppContext } from '../../../../../redux/slices/appContext'
import { getNearestWorkableAsOfDate } from '../../billing/runBilling/helpers'
import { useHolidays } from '../../../../../api/coreData'

import { columnsConfig } from '../columnsConfig'

import { QUERY_KEYS } from '../../../../../api/queryKeys'
import { INTERNAL_DATE_FORMAT, BENCHMARKS } from '../../../../../constants'

import SourceSkeleton from './Skeleton'

const BENCHMARK_VALUES_PAGE_SIZE = 50

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '1.5rem 2.1rem',
    height: '100%',
    [theme.breakpoints.down('xs')]: {
      padding: '1.75rem'
    }
  },
  namesContainer: {
    marginBottom: '1.75rem'
  }
}))

function SourceInfo () {
  const classes = useStyles()
  const theme = useTheme()
  const { benchmarkId } = useParams()
  const { firmId } = useAppContext()
  const queryClient = useQueryClient()
  const { data: holidays } = useHolidays()

  const defaultAsOfDate = dayjs().subtract(1, 'day')
  const { sourceValuesColumns, sourceDefaultSort } = columnsConfig

  const {
    defaultPageSize,
    onPagingChange,
    onSortingChange,
    onTableModeChange
  } = useOperationalTable({ sourceDefaultSort, defaultPageSize: BENCHMARK_VALUES_PAGE_SIZE })

  const nearestWorkableAsOfDate = useMemo(
    () => getNearestWorkableAsOfDate(holidays, defaultAsOfDate),
    [holidays, defaultAsOfDate]
  )

  const endDate = nearestWorkableAsOfDate
  const startDate = dayjs(endDate).subtract(12, 'month').format(INTERNAL_DATE_FORMAT)

  const { queryValues, valuesOpts } = useMemo(() => {
    return {
      queryValues: {
        benchmarkId: Number(benchmarkId),
        startDate,
        endDate
      },
      valuesOpts: {
        enabled: Boolean(endDate)
      }
    }
  }, [
    startDate,
    endDate,
    benchmarkId
  ])

  const { queryComponents, queryOptions } = useMemo(() => {
    return {
      queryComponents: {
        method: 'benchmarkNameSearch',
        filters: { type: BENCHMARKS.SOURCE },
        firmId
      },
      queryOptions: {
        mapper: (data) => {
          return data.find(
            (benchmark) => Number(benchmark.benchmarkId) === Number(benchmarkId)
          ) ?? {}
        },
        enabled: !isNaN(Number(benchmarkId))
      }
    }
  }, [benchmarkId, firmId])

  // Get source benchmarks
  const { data: benchmark, isLoading: loadingComponents } = useSourceBenchmarks(queryComponents, queryOptions)

  // Get benchmark values
  const { data: benchmarkValues, isLoading: loadingBenchmarkValues } = useGetBenchmarkValues(queryValues, valuesOpts)

  useEffect(() => {
    return () => {
      queryClient.removeQueries([QUERY_KEYS.getSourceBencmarks])
    }
  }, [queryClient])

  if (loadingComponents) {
    return <SourceSkeleton />
  }

  return (
    <Container maxWidth={false} className={classes.container}>
      <Grid container spacing={4} className={classes.namesContainer}>
        <Grid item md={4} xs={12}>
          <TextInput
            fullWidth
            name='displayName'
            value={benchmark?.displayName || benchmark?.shortName}
            placeholder={benchmark?.displayName || benchmark?.shortName}
            label='Display Name'
            disabled
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <TextInput
            fullWidth
            name='longName'
            value={benchmark?.longName}
            placeholder={benchmark?.longName}
            label='Full Name'
            disabled
          />
        </Grid>
        <Grid item md={1} xs={12}>
          <TextInput
            fullWidth
            name='identifier'
            value={benchmark?.identifier}
            placeholder={benchmark?.identifier}
            label='Symbol'
            disabled
          />
        </Grid>
      </Grid>

      <Box display='flex' justifyContent='space-between'>
        <Text
          text='Returns'
          color={theme.palette.darkJungle}
          customFontSize='1.25rem'
          customFontWeight='bold'
        />

        <Text
          text='Last 12 months'
          color={theme.palette.darkJungle}
          customFontSize='1rem'
        />
      </Box>

      <Divider />

      <Box marginTop='0.75rem'>
        <OperationalTable.Wrapper>
          <OperationalTable
            mode='client'
            columns={sourceValuesColumns}
            data={benchmarkValues || []}
            defaultPageSize={defaultPageSize}
            defaultSort={sourceDefaultSort}
            itemName='Benchmark Values'
            loading={loadingBenchmarkValues}
            onSortingChange={onSortingChange}
            onPagingChange={onPagingChange}
            onTableModeChange={onTableModeChange}
          />
        </OperationalTable.Wrapper>
      </Box>
    </Container>
  )
}

SourceInfo.propTypes = {}

export default SourceInfo
