import React from 'react'
import { IconButton, makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import Icon from '../../../../atoms/Icon'

const useStyles = makeStyles((theme) => ({
  controlGroup: {
    display: 'flex',
    flexDirection: 'row',
    gap: '10px',
    alignItems: 'center',
    minWidth: '400px',
    '& > *:first-child': {
      flex: 1
    }
  }
}))

const ControlGroup = ({ children, disabled, onClick }) => {
  const classes = useStyles()
  return (
    <div className={classes.controlGroup}>
      {children}
      <IconButton disabled={disabled} onClick={onClick}>
        {disabled ? null : <Icon name='close' />}
      </IconButton>
    </div>
  )
}

ControlGroup.propTypes = {
  children: PropTypes.node,
  disabled: PropTypes.bool,
  onClick: PropTypes.func
}

export default ControlGroup
