import React from 'react'

import PropTypes from 'prop-types'

import { withDownloadButton } from './DownloadPdfButton'

/* eslint react/prop-types: 0 */
const PrintViewLayout = (Component) => (props) => {
  const buttonProps = {
    containerStyles: props.containerStyles || {},
    buttonStyles: props.buttonStyles || {},
    text: props.text || 'Download View',
    fileName: props.fileName || 'view.pdf'
  }

  if (props?.view?.layoutSettings?.id) {
    buttonProps.id = props.view.layoutSettings.id
  }

  const ComponentToRender = props.showPdfButton || props.view?.layoutSettings?.showPdfButton
    ? withDownloadButton(Component, buttonProps)
    : Component
  return <ComponentToRender {...props} />
}

PrintViewLayout.propTypes = {
  showPdfButton: PropTypes.bool,
  containerStyles: PropTypes.object,
  buttonStyles: PropTypes.object,
  text: PropTypes.string.isRequired,
  fileName: PropTypes.string,
  view: PropTypes.object
}

export default PrintViewLayout
