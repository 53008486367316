import React, { useCallback, useState } from 'react'
import {
  Typography,
  TextField,
  InputAdornment,
  Box
} from '@material-ui/core'
import PersonOutlineIcon from '@material-ui/icons/PersonOutline'
import { Controller, useForm } from 'react-hook-form'
import clsx from 'clsx'
import { useHistory } from 'react-router-dom'
import RoundedButton from '../../atoms/RoundedButton'
import { BUTTON_SIZES } from '../../../constants'
import { forgotPassword } from '../../../service'
import { useToggle } from '../../../hooks'
import FadeIn from '../../molecules/Transitions/FadeIn'
import { emailRules } from '../admin/common'
import { useFirmInfo } from './hooks'
import { usePublicStyles } from './usePublicStyles'

const ForgotPasswordView = () => {
  const classes = usePublicStyles()
  const history = useHistory()
  const [loading, , setLoadingOn, setLoadingOff] = useToggle()
  const [successState, setSuccessState] = useState(false)
  const firmInfo = useFirmInfo()

  const {
    handleSubmit,
    control,
    formState: { isSubmitting, isDirty, isValid, errors }
  } = useForm({
    mode: 'onChange',
    defaultValues: { email: '' }
  })

  const onSubmitHandler = useCallback(async ({ email }) => {
    try {
      setLoadingOn()
      const body = { email }
      await forgotPassword(body)
      setSuccessState(true)
    } catch (error) {
      setLoadingOff()
      console.error(error)
    }
  }, [setLoadingOn, setLoadingOff])

  if (!firmInfo) {
    return null
  }
  const { logoPath } = firmInfo

  return (
    <FadeIn className={classes.formWrapper}>
      <Box
        display='flex'
        flexDirection='column'
        alignItems='center'
        width='100%'
      >
        {logoPath && <img src={logoPath} alt='logo' className={classes.logo} />}
      </Box>
      {successState
        ? (
          <p className={classes.resetTitle}>
            An email was sent to you with instructions to set a new password.
          </p>
        )
        : (
          <>
            <p className={classes.resetTitle}>
              Don't worry! Just fill in your email and we'll send
              you a link to reset your password.
            </p>
            <form onSubmit={handleSubmit(onSubmitHandler)} className={classes.form}>
              <div
                className={clsx(classes.field, {
                  [classes.fieldError]: errors.email
                })}
              >
                <Controller
                  name='email'
                  render={({ field: { onChange } }) => (
                    <TextField
                      fullWidth
                      autoFocus
                      autoComplete='email'
                      placeholder='Enter Email'
                      onChange={onChange}
                      error={Boolean(errors.email)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment className={classes.inputIcon}>
                            <PersonOutlineIcon fontSize='large' />
                          </InputAdornment>
                        ),
                        className: classes.inputBase,
                        classes: {
                          input: classes.input
                        },
                        disableUnderline: true
                      }}
                    />)}
                  control={control}
                  rules={emailRules}
                />
              </div>
              <RoundedButton
                primary
                fullWidth
                type='submit'
                isLoading={isSubmitting}
                size={BUTTON_SIZES.large}
                className={classes.signInButton}
                disabled={!isDirty || !isValid || loading || isSubmitting}
              >
                Reset password
              </RoundedButton>
            </form>
          </>
        )}
      <Typography
        component='h3'
        className={classes.backToLoginLabel}
        onClick={() => history.push('/login')}
      >
        Back to Sign in
      </Typography>
    </FadeIn>
  )
}

export default ForgotPasswordView
