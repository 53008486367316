import PropTypes from 'prop-types'
import numeral from 'numeral'

function AccountEndingValueCell ({ value, row }) {
  return (
    numeral(value).format('0,000')
  )
}

AccountEndingValueCell.propTypes = {
  value: PropTypes.any,
  row: PropTypes.any
}

export default AccountEndingValueCell
