import React, { useCallback, useImperativeHandle, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import RoundedModal from '../../../../../molecules/RoundedModal'
import EditAssetForm from './EditAssetForm'

const EditAssetDialog = React.forwardRef(function EditAssetDialog ({ onEdit }, ref) {
  const [dialogState, setDialogState] = useState({ open: false, processing: false })
  const close = useCallback(() => {
    setDialogState({ open: false, processing: false })
  }, [setDialogState])

  const complete = useCallback((componentModel) => {
    close()
    onEdit(componentModel, dialogState.index)
  }, [close, onEdit, dialogState])

  useImperativeHandle(ref, () => ({
    open: (item, index) => setDialogState({ open: true, processing: false, item, index })
  }), [setDialogState])

  const item = useMemo(() => {
    return dialogState.item
  }, [dialogState])

  return (
    <RoundedModal
      title='Edit Asset'
      open={dialogState.open}
      onClose={close}
      size='xmedium'
      position='top'
      disablePortal={false}
    >
      <div>
        <EditAssetForm onComplete={complete} onClose={close} item={item} />
      </div>
    </RoundedModal>
  )
})

EditAssetDialog.propTypes = {
  onEdit: PropTypes.func.isRequired
}

export default EditAssetDialog
