import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { useMemo } from 'react'
import { useAppContext } from '../../../../redux/slices/appContext'
import { CALC_TYPES } from '../../../../constants'
import { useCoreChartData } from '../../../../api/coreData'

dayjs.extend(utc)

export const useWjSeries2 = ({ baseQuery }) => {
  const { clientId, availableDates } = useAppContext()
  const scopeFilter = useMemo(() => {
    return {
      levelTypes: ['client'],
      clientIds: [clientId]
    }
  }, [clientId])

  const query = useMemo(() => {
    const startDate = dayjs.utc(availableDates.min)
    const endDate = dayjs.utc(availableDates.max)

    return {
      ...(baseQuery || {}),
      levelFilters: {
        ...(baseQuery?.levelFilters || {}),
        // who are we getting this data for? a client? a bear?
        ...(scopeFilter || {}),
        calcType: CALC_TYPES.timeSeries
      },
      dateRange: {
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString()
      }
    }
  }, [scopeFilter, availableDates, baseQuery])

  const { data, isLoading } = useCoreChartData(query)

  return {
    data: data?.data || [],
    extents: data?.extents,
    isLoading,
    seriesStartDate: data?.startDate,
    seriesEndDate: data?.endDate,
    dataEndDate: data?.dataEndDate
  }
}
