import React, { useCallback, useRef, useState } from 'react'
import { CircularProgress, makeStyles, useTheme } from '@material-ui/core'
import PropTypes from 'prop-types'
import { useFieldArray, useForm } from 'react-hook-form'
import FadeIn from '../../../../../molecules/Transitions/FadeIn'
import PersonalSpace from '../../../shared/PersonalSpace'
import SydButton from '../../../../../commonDesign/Button'
import SectionScreen from '../../../shared/SectionScreen'
import EditButton from '../../../shared/EditButton'
import SectionHeader from '../../../shared/SectionHeader'
import SaveCancelFooter from '../../../shared/SaveCancelFooter'
import { useFormattingContext } from '../../../../../organisms/FormattingProvider/FormattingContext'
import Card from '../../../../../molecules/Card'
import { useSectionEditing, useComponentModelContext } from '../ComponentModelProvider'
import { useModifyComponentModelItemsMutation } from '../../../../../../api/rebalancer'
import AddComponentModelDialog from './AddComponentModelDialog'
import EditComponentModelDialog from './EditComponentModelDialog'
import AddAssetDialog from './AddAssetDialog'
import AddTagDialog from './AddTagDialog'
import EditAssetDialog from './EditAssetDialog'
import EditTagDialog from './EditTagDialog'

const useStyles = makeStyles((theme) => ({
  tabBody: {
    padding: '20px 0',
    maxWidth: '1400px'
  },
  composition: {
    tableLayout: 'fixed',
    borderCollapse: 'collapse',
    width: '100%',
    '& th': {
      borderBottom: '1px solid black',
      textAlign: 'left',
      fontWeight: theme.typography.weights.bold,
      padding: `0 ${theme.layout.padding.medium}`
    },
    '& tr:nth-child(2n) td': {
      backgroundColor: theme.palette.gray.light
    },
    '& tr.__total > td': {
      padding: theme.layout.padding.medium,
      backgroundColor: theme.palette.background.default,
      borderTop: `1px solid ${theme.palette.gray.darker}`
    },
    '& .__item > td': {
      fontWeight: theme.typography.weights.light,
      padding: theme.layout.padding.medium
    },
    '& .__actions': {
      display: 'flex',
      flexDirection: 'row',
      gap: '10px'
    }
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    height: '50px'
  },
  error: {
    textAlign: 'right',
    color: theme.palette.danger.main,
    backgroundColor: theme.palette.danger.contrast,
    marginBottom: theme.layout.margin.thick
  },
  itemGroup: {
    marginBottom: theme.layout.margin.thick,
    '& header': {
      fontSize: theme.typography.fontSizes.h5,
      padding: theme.layout.padding.medium
    }
  },
  actionGroup: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.layout.gap.medium,
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  grandTotal: {
    fontSize: theme.typography.fontSizes.h5,
    display: 'flex',
    flexDirection: 'row',
    gap: theme.layout.gap.medium,
    alignItems: 'center',
    justifyContent: 'space-between',
    transition: 'background-color .3s ease-in-out'
  }
}))

function ComponentModelItem ({ item, onRemove, index, editing, formatter, onEdit }) {
  return (
    <tr className='__item'>
      <td>{item.componentModelItemId}</td>
      <td>{item.levelId}</td>
      <td>{item.identifier}</td>
      <td>{item.name}</td>
      <td>{item.weight} Bps</td>
      <td> {formatter(item.weight / 10000, percentFormat)}</td>
      {editing ? (
        <td>
          <div className='__actions'>
            <SydButton
              size='sm' variant='outline'
              onClick={() => onEdit(item, index)}
            >Edit
            </SydButton>
            <SydButton size='sm' variant='outline' priority='warning' onClick={onRemove}>Remove</SydButton>
          </div>
        </td>
      ) : <td />}
    </tr>
  )
}

ComponentModelItem.propTypes = {
  item: PropTypes.any,
  onRemove: PropTypes.func,
  onEdit: PropTypes.func,
  index: PropTypes.number,
  editing: PropTypes.bool,
  formatter: PropTypes.func
}

function TableHeader () {
  return (
    <thead>
      <tr>
        <th>Item ID</th>
        <th>Level ID</th>
        <th>Identifier</th>
        <th>Name</th>
        <th>Weight</th>
        <th>%</th>
        <th>Actions</th>
      </tr>
    </thead>
  )
}

const useSubmitter = (form, onComplete) => {
  const { handleSubmit } = form
  const [processing, setProcessing] = useState(false)
  const { mutateAsync: modifyComponentModelItems } = useModifyComponentModelItemsMutation()
  const onSubmit = useCallback(async (formData) => {
    const command = {
      componentModelId: formData.componentModelId,
      items: formData.items
    }

    try {
      setProcessing(true)
      const result = await modifyComponentModelItems(command)
      onComplete(result)
    } finally {
      setProcessing(false)
    }
  }, [modifyComponentModelItems, setProcessing, onComplete])

  const submitter = useCallback(async (e) => {
    const onValid = async (form) => {
      await onSubmit(form)
    }
    const onInvalid = (errors) => {
      console.error(errors)
    }

    const handler = handleSubmit(onValid, onInvalid)
    await handler(e)
  }, [handleSubmit, onSubmit])

  return {
    submitter,
    processing
  }
}

function validate (values) {
  const errors = {}
  const totalWeight = values.items.reduce((p, x) => p + x.weight, 0)
  if (totalWeight !== 10000) {
    errors.items = { type: 'bad-weight', message: 'The total weight must be exactly 10000 Bps' }
  }

  return { values, errors }
}

const percentFormat = '0,0.00%'

function CompositionTab () {
  const classes = useStyles()
  const theme = useTheme()
  const { componentModel, isFetching, editing, editSection, cancelEdit } = useComponentModelContext()

  const { reset, ...form } = useForm({
    mode: 'onChange',
    defaultValues: {
      componentModelId: componentModel.componentModelId,
      items: componentModel.items
    },
    resolver: validate
  })
  const { append, remove, update, fields: items } = useFieldArray({
    control: form.control,
    name: 'items'
  })

  const onAddComponentModel = useCallback((componentModel) => {
    append(componentModel)
  }, [append])

  const onEditComponentModel = useCallback((componentModel, i) => {
    update(i, componentModel)
  }, [update])

  const { submitter, processing } = useSubmitter(form, cancelEdit)
  const onCancel = useCallback(() => {
    reset()
    cancelEdit()
  }, [cancelEdit, reset])

  const editComponentModelRef = useRef()
  const addComponentModelRef = useRef()
  const addAssetRef = useRef()
  const addTagRef = useRef()
  const editAssetRef = useRef()
  const editTagRef = useRef()

  const sectionIsEditing = useSectionEditing('composition')
  const { formatter } = useFormattingContext()

  if (isFetching) {
    return <CircularProgress />
  }

  const _items = items.map((x, i) => ({ index: i, ...x }))
  const assets = _items.filter(x => x.levelTypeId === 3)
  const tags = _items.filter(x => x.levelTypeId === 6)
  const components = _items.filter(x => x.levelTypeId === 2000)
  const assetWeight = assets.reduce((p, c) => p + c.weight, 0)
  const tagWeight = tags.reduce((p, c) => p + c.weight, 0)
  const componentWeight = components.reduce((p, c) => p + c.weight, 0)
  const totalWeight = _items.reduce((p, c) => p + c.weight, 0)
  const assetPercent = formatter(assetWeight / 10000, percentFormat)
  const tagPercent = formatter(tagWeight / 10000, percentFormat)
  const componentPercent = formatter(componentWeight / 10000, percentFormat)
  const totalPercent = formatter(totalWeight / 10000, percentFormat)

  return (
    <>
      <FadeIn className={classes.tabBody}>
        <SectionScreen editing={editing} sectionIsEditing={sectionIsEditing}>
          <SectionHeader text='Component Model Composition'>
            <div>
              <EditButton
                policy='admin_models_edit'
                editing={editing}
                onClick={() => editSection({
                  section: 'composition'
                })}
              />
            </div>
          </SectionHeader>
          <div className={classes.header}>
            <p>
              Here you can modify the composition of the Component Model. Components Models are composed of Component
              Models, Assets, or Classification Tags.
              The weights of the underlying items must add up to 10,000 Bps.
            </p>
          </div>
          <Card className={classes.itemGroup}>
            <div className={classes.actionGroup}>
              <header>Assets</header>
              {sectionIsEditing ? (
                <FadeIn>
                  <SydButton icon='add' size='sm' variant='outline' onClick={() => addAssetRef.current.open(null)}>
                    Add Asset
                  </SydButton>
                </FadeIn>
              ) : null}
            </div>
            <table className={classes.composition}>
              <TableHeader editing={editing} />
              <tbody>
                {assets.map((t) => (
                  <ComponentModelItem
                    index={t.index} key={t.id} item={t} onRemove={() => remove(t.index)}
                    editing={sectionIsEditing} formatter={formatter}
                    onEdit={(...args) => editAssetRef.current.open(...args)}
                  />
                ))}
                <tr className='__total'>
                  <td>Total Assets</td>
                  <td />
                  <td />
                  <td />
                  <td>{assetWeight} Bps</td>
                  <td>{assetPercent}</td>
                  <td />
                </tr>
              </tbody>
            </table>
          </Card>

          <Card className={classes.itemGroup}>
            <div className={classes.actionGroup}>
              <header>Tags</header>
              {sectionIsEditing ? (
                <FadeIn>
                  <SydButton icon='add' size='sm' variant='outline' onClick={() => addTagRef.current.open(null)}>
                    Add Classification Tag
                  </SydButton>
                </FadeIn>
              ) : null}
            </div>
            <table className={classes.composition}>
              <TableHeader editing={editing} />
              <tbody>
                {tags.map((t, i) => (
                  <ComponentModelItem
                    index={t.index} key={t.id} item={t} onRemove={() => remove(t.index)}
                    editing={sectionIsEditing} formatter={formatter}
                    onEdit={(...args) => editTagRef.current.open(...args)}
                  />
                ))}
                <tr className='__total'>
                  <td>Total</td>
                  <td />
                  <td />
                  <td />
                  <td>{tagWeight} Bps</td>
                  <td>{tagPercent}</td>
                  <td />
                </tr>
              </tbody>
            </table>
          </Card>

          <Card className={classes.itemGroup}>
            <div className={classes.actionGroup}>
              <header>Components</header>
              {sectionIsEditing ? (
                <FadeIn>
                  <SydButton icon='add' size='sm' variant='outline' onClick={() => addComponentModelRef.current.open(null)}>Add
                    Component Model
                  </SydButton>
                </FadeIn>
              ) : null}
            </div>
            <table className={classes.composition}>
              <TableHeader editing={editing} />
              <tbody>
                {components.map((t, i) => (
                  <ComponentModelItem
                    index={t.index} key={t.id} item={t} onRemove={() => remove(t.index)}
                    editing={sectionIsEditing} formatter={formatter}
                    onEdit={(...args) => editComponentModelRef.current.open(...args)}
                  />
                ))}
                <tr className='__total'>
                  <td>Total</td>
                  <td />
                  <td />
                  <td />
                  <td>{componentWeight} Bps</td>
                  <td>{componentPercent}</td>
                  {sectionIsEditing ? (<td />) : null}
                </tr>
              </tbody>
            </table>
          </Card>

          <Card
            className={classes.grandTotal} style={{
              backgroundColor: sectionIsEditing
                ? totalWeight !== 10000
                  ? theme.palette.warning.main
                  : theme.palette.success.main
                : theme.palette.background.default
            }}
          >
            <div>Total of All Items</div>
            <div>{totalWeight} Bps ({totalPercent})</div>
          </Card>
          {form.formState.errors.items ? (
            <FadeIn className={classes.error}>{form.formState.errors.items.message}</FadeIn>
          ) : null}

          <PersonalSpace />
          <AddComponentModelDialog onAdd={onAddComponentModel} ref={addComponentModelRef} />
          <AddAssetDialog onAdd={onAddComponentModel} ref={addAssetRef} />
          <AddTagDialog onAdd={onAddComponentModel} ref={addTagRef} />
          <EditComponentModelDialog onEdit={onEditComponentModel} ref={editComponentModelRef} />
          <EditAssetDialog onEdit={onEditComponentModel} ref={editAssetRef} />
          <EditTagDialog onEdit={onEditComponentModel} ref={editTagRef} />
        </SectionScreen>
      </FadeIn>
      {sectionIsEditing ? (
        <SaveCancelFooter
          saveText='Save Model Composition'
          onCancel={onCancel}
          onSave={submitter}
          processing={processing}
          disabled={!form.formState.isValid}
        />
      ) : null}
    </>
  )
}

CompositionTab.propTypes = {}

export default CompositionTab
