import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import KeyMirror from 'keymirror'
import { first, isArray, isEmpty } from 'lodash'
import { useSearchClients } from '../../../../api/clients'
import { useSearchAccounts } from '../../../../api/accounts'
import QueryListContextProvider from '../../../../contexts/QueryListContext'
import { useAdvisorHome } from '../AdvisorHomeContext'
import Metric from './Metric'

const LEVEL_TYPES = KeyMirror({
  accounts: null,
  clients: null
})

const RESULT_TYPES = KeyMirror({
  total: null,
  details: null
})

const mapResult =
  (resultType) =>
    ({ data, total }) => {
      return resultType === RESULT_TYPES.total ? total || 0 : data ?? []
    }

const TagInsightsMetric = ({
  redirectTo,
  levelType,
  levelQuery,
  levelFilters,
  aggregation: _aggregation,
  resultType,
  ...metricProps
}) => {
  const { scope } = useAdvisorHome()

  const { query, clientQueryOptions, accountQueryOptions } = useMemo(() => {
    const groupIds = []

    if (!isEmpty(scope?.values)) {
      groupIds.push({ op: 'in', value: scope?.values, combine: 'and' })
    }

    if (!isEmpty(levelFilters.groupIds)) {
      groupIds.push({ op: 'in', value: levelFilters.groupIds })
    }

    return {
      query: {
        filters: {
          ...levelFilters,
          ...(!isEmpty(groupIds) ? { groupIds } : {})
        },
        resultType,
        ...levelQuery
      },
      clientQueryOptions: {
        mapper: mapResult(resultType),
        enabled: levelType === LEVEL_TYPES.clients
      },
      accountQueryOptions: {
        mapper: mapResult(resultType),
        enabled: levelType === LEVEL_TYPES.accounts
      }
    }
  }, [scope, levelFilters, levelType, levelQuery, resultType])

  const { data: clients, isLoading: isLoadingClients } = useSearchClients(
    query,
    clientQueryOptions
  )
  const { data: accounts, isLoading: isLoadingAccounts } = useSearchAccounts(
    query,
    accountQueryOptions
  )

  const isLoading =
    (isLoadingClients && clientQueryOptions.enabled) ||
    (isLoadingAccounts && accountQueryOptions.enabled)

  const data = useMemo(() => {
    if (levelType === LEVEL_TYPES.clients) return clients
    if (levelType === LEVEL_TYPES.accounts) return accounts
    return undefined
  }, [levelType, clients, accounts])

  const aggregation = useMemo(() => {
    if (resultType === RESULT_TYPES.total) return undefined

    if (!_aggregation && !isEmpty(data) && isArray(data)) {
      const id = Object.keys(first(data)).find(
        (key) => key.indexOf('Id') !== -1
      )
      if (!id) return undefined
      return { count: id }
    }
    return _aggregation
  }, [_aggregation, data, resultType])

  if (resultType === RESULT_TYPES.total) {
    return (
      <Metric.Wrapper redirectTo={redirectTo}>
        <Metric value={data || '-'} isLoading={isLoading} {...metricProps} />
      </Metric.Wrapper>
    )
  }

  return (
    <QueryListContextProvider data={data} aggregation={aggregation}>
      {(value) => (
        <Metric.Wrapper redirectTo={redirectTo}>
          <Metric
            value={value || '-'}
            isLoading={isLoading}
            redirectTo={redirectTo}
            {...metricProps}
          />
        </Metric.Wrapper>
      )}
    </QueryListContextProvider>
  )
}

TagInsightsMetric.propTypes = {
  levelType: PropTypes.oneOf(Object.values(LEVEL_TYPES)).isRequired,
  redirectTo: PropTypes.string,
  levelFilters: PropTypes.object,
  aggregation: PropTypes.object,
  resultType: PropTypes.oneOf(Object.values(RESULT_TYPES)),
  levelQuery: PropTypes.object
}

TagInsightsMetric.defaultProps = {
  redirectTo: undefined,
  levelFilters: {},
  aggregation: undefined,
  levelQuery: {},
  resultType: RESULT_TYPES.total
}

export default TagInsightsMetric
