import { useCallback } from 'react'
import { isArray } from 'lodash'
import dayjs from 'dayjs'
import { getBobiOutput, getExport, postExport, postNamedQuery } from '../../../../service'
import { getReportColumns } from './columnConfig'

const sleep = (ms = 1000) => new Promise((resolve) => setTimeout(resolve, ms))

const getBobiOutputColumns = async (bobiOutputId) => {
  const { data: reportData } = await getBobiOutput(bobiOutputId)
  const { _raw: reportParams } = reportData.inputParams.original

  const { data: distinctTags } = await postNamedQuery('bobi', 'getBobiDistinctEntities', {
    bobiOutputId,
    field: 'tags'
  })

  const columns = []
  distinctTags.forEach(e => {
    columns.push({
      accessor: `tags.${reportParams.levelType}.${e}`,
      label: e
    })
  })

  const settings = { includeDateRangeIntoColHeader: true }

  const [baseColumns] = getReportColumns(reportParams, [], settings)
  // eslint-disable-next-line no-debugger
  baseColumns.forEach(c => {
    if ('resolveAccessor' in c) {
      columns.push({
        accessor: c.resolveAccessor(reportParams),
        label: c.Header
      })
    } else if (isArray(c.accessors)) {
      columns.push({
        accessor: c.accessors[0],
        label: c.Header
      })
    } else {
      columns.push({
        accessor: c.accessor,
        label: c.Header
      })
    }
  })

  return columns
}

const getReadyExport = async (exportId, maxRetries = 100, sleepTime = 2000) => {
  const doneStatuses = new Set(['READY', 'ERROR'])
  for (let i = 0; i < maxRetries; i++) {
    const { data: exportResult } = await getExport(exportId)
    if (!exportResult) return null

    if (doneStatuses.has(exportResult?.status)) return exportResult

    await sleep(sleepTime)
  }
}

const downloadExport = (url, fileName, target = '_self') => {
  const link = document.createElement('a')
  link.setAttribute('href', url)
  link.setAttribute('target', target)
  link.setAttribute('download', fileName)
  link.style.visibility = 'hidden'
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

/**
 *
 * @param {BobiExportOptions} exportOptions
 */
const useBobiOfflineExport = (exportOptions) => {
  const onExport = useCallback(async () => {
    const {
      afterExport,
      beforeExport,
      bobiOutputId,
      columnOrdinals,
      fileName,
      format = 'csv',
      order = {
        field: 'levelId',
        asc: true
      },
      onError
    } = exportOptions

    try {
      beforeExport()

      const columns = await getBobiOutputColumns(bobiOutputId)

      const projections = {
        include: columns.map(col => col.accessor)
      }

      const mappings = columns.reduce((acc, curr) => {
        if (!(curr.accessor in acc)) {
          acc[curr.accessor] = curr.label
        }

        return acc
      }, {})

      const { data: exportResult } = await postExport({
        metadata: {
          exportType: 'BOBI_EXPORT',
          asOfDate: dayjs().format('YYYY-MM-DD'),
          format,
          bobiOutputId,
          projections,
          mappings,
          columnOrdinals,
          order
        }
      })

      const exportData = await getReadyExport(exportResult.exportId)
      if (exportData?.status === 'ERROR') {
        onError('There was an error processing the export', new Error('export error'))
        return
      }

      // TODO: What should we do in this case
      if (exportData?.status !== 'READY') {
        console.info('Export is still pending after max retries')
        return
      }

      downloadExport(exportData.url, fileName)
      afterExport()
    } catch (e) {
      console.error('An error occurred while trying to export the bobi output')
      onError('There was an error processing the export', e)
    }
  }, [exportOptions])

  return {
    onExport
  }
}

export default useBobiOfflineExport

/**
 * @typedef BobiExportOptions
 * @property {number} bobiOutputId
 * @property {Record<string, number>} columnOrdinals
 * @property {string} fileName
 * @property {('csv'|'ndjson'|'json')} format
 * @property {{ field: string, asc: boolean }} order
 * @property {function(string, Error)} onError
 * @property {function()} beforeExport
 * @property {function()} afterExport
 */
