import React, { useState, useCallback, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import {
  Container,
  Box,
  Button,
  makeStyles
} from '@material-ui/core'
import clsx from 'clsx'

import upperCase from 'lodash/upperCase'
import OperationalTable, { useOperationalTable } from '../../../organisms/OperationalTable'
import Icon from '../../../atoms/Icon'

import { useAppContext } from '../../../../redux/slices/appContext'
import { useBenchmarks, mapSearch } from '../../../../api/benchmarks'
import { BENCHMARKS as BENCHMARKS_POLICIES } from '../../../../policies/admin'
import { ADMIN_ROUTES, ICON_NAMES, BENCHMARKS } from '../../../../constants'
import { useCheckPolicy } from '../../../../hooks'
import SearchBarInput from '../../../molecules/SearchBar/SearchBarInput'
import ViewTabs from '../../../organisms/AdvisorView/components/AdvisorViewTabs'
import { columnsConfig } from './columnsConfig'

const BENCHMARKS_PAGE_SIZE = 50

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '1rem',
    height: '100%',
    [theme.breakpoints.down('xs')]: {
      padding: '1.75rem'
    }
  },
  tabsContainer: {
    padding: '1.5rem 0',
    [theme.breakpoints.down('md')]: {
      padding: '1.5rem 0'
    }
  },
  pillBtn: {
    borderRadius: 25,
    padding: '8px 16px',
    textTransform: 'none',
    fontWeight: 'bold',
    fontSize: '0.75rem'
  },
  pillSecondary: {
    backgroundColor: theme.palette.summitBlue,
    color: theme.palette.white,
    '&:hover': {
      backgroundColor: theme.palette.summitBlue
    },
    '&:disabled': {
      backgroundColor: theme.palette.romanSilver,
      color: theme.palette.white
    }
  }
}))

const tabOptions = {
  source: 'Source',
  blended: 'Blended'
}

function Benchmarks () {
  const classes = useStyles()
  const history = useHistory()
  const canEditBlends = useCheckPolicy(BENCHMARKS_POLICIES.editBlends)
  const { firmId } = useAppContext()
  const [dataSourceId, setDataSourceId] = useState(BENCHMARKS.SOURCE)
  const { sourceColumns, blendedColumns, defaultSort } = columnsConfig

  const {
    defaultPageSize,
    searchText,
    onPagingChange,
    onSortingChange,
    onSearchTextChange,
    onTableModeChange
  } = useOperationalTable({ defaultSort, defaultPageSize: BENCHMARKS_PAGE_SIZE })

  const query = useMemo(() => ({
    method: 'benchmarkNameSearch',
    textSearch: mapSearch(searchText),
    filters: { type: dataSourceId },
    firmId
  }), [dataSourceId, firmId, searchText])

  const { data: benchmarks, isLoading: loadingBenchmarks } = useBenchmarks(query)

  const onChangeTab = useCallback((selectedValue) => {
    onSearchTextChange('')
    setDataSourceId(BENCHMARKS[upperCase(selectedValue)])
  }, [onSearchTextChange])

  const onRowClick = useCallback((row) => {
    const benchmarkRootPath = dataSourceId === BENCHMARKS.SOURCE
      ? ADMIN_ROUTES.BENCHMARKS_SOURCE
      : ADMIN_ROUTES.BENCHMARKS_BLENDED

    history.push(`${benchmarkRootPath}/${row.original.benchmarkId}`)
  }, [history, dataSourceId])

  const onAddBlend = useCallback(() => {
    history.push(ADMIN_ROUTES.BENCHMARKS_BLENDED_NEW)
  }, [history])

  return (
    <Container maxWidth={false} className={classes.container}>
      <ViewTabs.Group tabOptions={tabOptions} onChange={onChangeTab}>
        <ViewTabs.Links
          tabsKey='blendedBenchmarks'
          rightAdornment={canEditBlends ? (
            <Button
              className={clsx(classes.pillBtn, classes.pillSecondary)}
              variant='outlined'
              onClick={onAddBlend}
              startIcon={<Icon customSize='1rem' name={ICON_NAMES.add} />}
            >
              Add Blend
            </Button>)
            : null}
        />
      </ViewTabs.Group>
      <Box className={classes.tabsContainer}>
        <OperationalTable.Wrapper>
          <OperationalTable.SuperHeader>
            <Box maxWidth='25rem'>
              <SearchBarInput
                value={searchText}
                loading={loadingBenchmarks}
                setValue={onSearchTextChange}
                placeholder='Search by benchmark name'
              />
            </Box>
          </OperationalTable.SuperHeader>
          <OperationalTable
            mode='server'
            columns={
              dataSourceId === BENCHMARKS.SOURCE
                ? sourceColumns
                : blendedColumns
            }
            data={benchmarks || []}
            defaultPageSize={defaultPageSize}
            defaultSort={defaultSort}
            itemName={
              dataSourceId === BENCHMARKS.SOURCE
                ? 'Source Benchmarks'
                : 'Blended Benchmarks'
            }
            loading={loadingBenchmarks}
            searchText={searchText}
            onSortingChange={onSortingChange}
            onPagingChange={onPagingChange}
            onTableModeChange={onTableModeChange}
            onRowClick={onRowClick}
          />
        </OperationalTable.Wrapper>
      </Box>
    </Container>
  )
}

export default Benchmarks
