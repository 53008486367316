import React, { useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import { useAppContext, useSetAppContext } from '../../../redux/slices/appContext'
import { LEVEL_TYPES } from '../../../constants'
import { getAvailableDatesFormatted } from '../../../utils'
import { useLevelDates } from '../../../api/coreData'
import AdvisorViewHeader from './components/Header'
import AdvisorViewDivider from './components/Divider'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexGrow: 1,
    height: '100%',
    padding: '0'
  },
  skeleton: {
    backgroundColor: theme.palette.gallery,
    minHeight: '10rem',
    borderRadius: '2rem'
  },
  container: {
    flex: 1,
    flexFlow: 'column'
  },
  header: {
    background: '#ffffff',
    padding: '1rem 2rem',
    flex: 0
  },
  divider: {
    flex: 0
  },
  content: {
    flex: 1,
    background: '#f8f8f8',
    padding: '2rem 2rem 2rem 1rem',
    maxWidth: ({ isSidebarOpen, drawerWidth, overContentOnDesktop }) => overContentOnDesktop
      ? '100wv'
      : isSidebarOpen
        ? `calc(100vw - ${drawerWidth}px)`
        : 'calc(100vw - 50px)'
  },
  h100: {
    height: '100%'
  }
}))

const AdvisorView = ({ advisorId, children, overContentOnDesktop }) => {
  const appContext = useAppContext()
  const { isSidebarOpen, drawerWidth } = appContext
  const classes = useStyles({ isSidebarOpen, drawerWidth, overContentOnDesktop })
  const setAppContext = useSetAppContext()
  const childIsRenderFn = useMemo(() => typeof children === 'function', [children])

  const levelDatesQuery = useMemo(() => ({
    levelType: LEVEL_TYPES.ADVISOR,
    advisorIds: [advisorId]
  }), [advisorId])

  const { isLoading: loading, data: advisorAvailableDates } = useLevelDates(levelDatesQuery)

  useEffect(() => {
    if (!loading) {
      setAppContext({
        advisorAvailableDates: getAvailableDatesFormatted(advisorAvailableDates)
      })
    }
  }, [advisorAvailableDates, loading, setAppContext])

  return (
    <div className={classes.root}>
      {childIsRenderFn ? children({
        classes,
        loading,
        advisorId
      }) : children}
    </div>
  )
}

AdvisorView.propTypes = {
  advisorId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  overContentOnDesktop: PropTypes.bool
}
AdvisorView.defaultProps = {
  overContentOnDesktop: true
}

AdvisorView.Header = AdvisorViewHeader
AdvisorView.Divider = AdvisorViewDivider

export default AdvisorView
