import React from 'react'
import PropTypes from 'prop-types'
import { Grid, makeStyles } from '@material-ui/core'
import { useAdvisorViewTemplateConfig } from '../../../../api/abundanceEngine'
import Section from './Section'
import AdvisorViewHomeLayout from './Layout'

const useStyles = makeStyles((theme) => ({
  pageContent: {
    maxWidth: '1600px',
    marginTop: '2rem'
  }
}))

const AdvisorViewHome = ({ templateConfig, backgroundImage }) => {
  const classes = useStyles()
  const { isLoading, data } = useAdvisorViewTemplateConfig(templateConfig)

  if (isLoading) {
    return null
  }

  return (
    <AdvisorViewHomeLayout backgroundImage={backgroundImage}>
      <Grid container className={classes.pageContent} spacing={2}>
        {data.config.sections.map(section => (
          <Section key={section.name} section={section} />
        ))}
      </Grid>
    </AdvisorViewHomeLayout>
  )
}

AdvisorViewHome.propTypes = {
  templateConfig: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  backgroundImage: PropTypes.string
}

AdvisorViewHome.defaultProps = {
  templateConfig: 'default',
  backgroundImage: null
}

export default AdvisorViewHome
