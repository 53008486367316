import { useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom/cjs/react-router-dom'
import isEmpty from 'lodash.isempty'
import { useContextContainer } from '../../../abundanceEngine/components/ContextContainer'
import { parseUrlSearchParams } from '../../../utils/parseUrlSearchParams'
import { useAssetTearSheet } from '../../../api/coreData'
import { ASSET_TEAR_CONTEXT } from '.'

const useAssetTearSheetData = () => {
  const history = useHistory()
  const params = useMemo(() => {
    return parseUrlSearchParams(history)
  }, [history])
  if (isEmpty(params)) {
    history.push('/')
  }
  const { id: [assetId] } = params || [{ id: null }]
  return useAssetTearSheet(assetId)
}

const AssetTearWrapper = ({ children }) => {
  const [, setAssetTierContext] = useContextContainer(ASSET_TEAR_CONTEXT)
  const assetTearSheetData = useAssetTearSheetData()

  useEffect(() => {
    if (!assetTearSheetData.isLoading) {
      setAssetTierContext({
        isLoading: assetTearSheetData.isLoading,
        data: assetTearSheetData.data
      })
    }
  }, [
    assetTearSheetData.isLoading,
    assetTearSheetData.data,
    setAssetTierContext
  ])

  return children
}

AssetTearWrapper.propTypes = {
  children: PropTypes.node
}

export default AssetTearWrapper
