import React from 'react'
import PropTypes from 'prop-types'
import PlaintextMilestoneDescription from './PlaintextMilestoneDescription'

const MilestoneDescription = ({ entry, className }) => {
  if (!entry) return null

  if (!entry.descriptionType || entry.descriptionType === 'default') {
    return <PlaintextMilestoneDescription className={className} entry={entry} />
  }
}

MilestoneDescription.propTypes = {
  entry: PropTypes.object,
  className: PropTypes.string
}

export default MilestoneDescription
