import React, { useCallback, useMemo, useState } from 'react'
import { Box, makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import { BUTTON_VARIANT, BUTTON_SIZES, ICON_NAMES } from '../../constants'
import Icon from '../atoms/Icon'
import Text from '../atoms/Text'
import RoundedButton from '../atoms/RoundedButton'
import TableSkeleton from '../atoms/TableSkeleton'
import WealthJourneyCategoryEntry from './WealthJourneyCategoryEntryV2'

const useStyles = makeStyles((theme) => ({
  content: ({ editMode }) => ({
    fontSize: '1rem',
    fontWeight: '400',
    lineHeight: '1.6',
    overflowY: 'auto',
    height: '100%',
    ...(editMode ? { marginRight: '-1.5rem' } : {})
  }),
  addNoteSection: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    borderRadius: '0.75rem',
    justifyContent: 'center',
    flexDirection: 'column',
    '&:hover': {
      backgroundColor: theme.palette.gray.lighter
    }
  }
}))

const EmptyCategory = ({ classes, onAdd }) => {
  return (
    <Box className={classes.addNoteSection} onClick={onAdd}>
      <Box display='flex' mb='0.5rem'>
        <Icon name={ICON_NAMES.addCircle} customSize='1.5rem' />
      </Box>
      <Text text='Add note' customFontWeight='bold' />
    </Box>
  )
}

EmptyCategory.propTypes = {
  classes: PropTypes.object.isRequired,
  onAdd: PropTypes.func.isRequired
}

const WealthJourneyCategoryBody = ({
  isLoading,
  toggleEditModeOn,
  activities,
  editMode,
  onAddActivity,
  onEditActivity,
  onDeleteActivity,
  canEditActivities
}) => {
  const classes = useStyles({ editMode })
  const [placeholders, setPlaceholders] = useState([])
  const removePlaceholders = useCallback(() => {
    setPlaceholders([])
  }, [])

  const addPlaceholder = useCallback(() => {
    if (!editMode) toggleEditModeOn()
    setPlaceholders(prevActivities => ([
      ...prevActivities,
      { entryId: null, entryText: '', isNewEntry: true }
    ]))
  }, [editMode, toggleEditModeOn])

  const noActivities = useMemo(() => !(activities?.length || placeholders?.length), [placeholders, activities])
  const addActivity = useCallback(entryText => {
    onAddActivity(entryText, removePlaceholders)
  }, [onAddActivity, removePlaceholders])

  if (isLoading) {
    return <TableSkeleton rows={4} columns={1} showHeader={false} />
  }

  if (noActivities) {
    return canEditActivities ? <EmptyCategory classes={classes} onAdd={addPlaceholder} /> : null
  }

  return (
    <>
      <div className={classes.content}>
        {activities.map(entry =>
          <WealthJourneyCategoryEntry
            key={`wealth-journey-entry-${entry.entryId}`}
            entry={entry}
            editModeOn={editMode}
            onEditEntry={onEditActivity}
            onDeleteEntry={onDeleteActivity}
            canEditActivities={canEditActivities}
          />)}
        {placeholders.map(entry => (
          <WealthJourneyCategoryEntry
            key={`wealth-journey-entry-${entry.entryId}`}
            entry={entry}
            editModeOn={editMode}
            onAddEntry={addActivity}
            onCancel={removePlaceholders}
            canEditActivities={canEditActivities}
          />
        ))}
      </div>
      {canEditActivities && editMode && (
        <Box mt='1rem' alignSelf='start'>
          <RoundedButton
            size={BUTTON_SIZES.extraSmall}
            variant={BUTTON_VARIANT.ghost}
            className={classes.uploadButton}
            onClick={addPlaceholder}
            disabled={!!placeholders.length}
          >
            <Box display='flex' mr='0.25rem'>
              <Icon name={ICON_NAMES.addCircle} customSize='1rem' />
            </Box>
            <Text customFontWeight='bold' lineHeight='inherit' text='Add note' />
          </RoundedButton>
        </Box>
      )}
    </>
  )
}

WealthJourneyCategoryBody.propTypes = {
  isLoading: PropTypes.bool,
  toggleEditModeOn: PropTypes.func.isRequired,
  activities: PropTypes.array.isRequired,
  editMode: PropTypes.bool,
  onAddActivity: PropTypes.func.isRequired,
  onEditActivity: PropTypes.func.isRequired,
  onDeleteActivity: PropTypes.func.isRequired,
  canEditActivities: PropTypes.bool.isRequired
}

WealthJourneyCategoryBody.defaultProps = {
  isLoading: false,
  editMode: false
}

export default WealthJourneyCategoryBody
