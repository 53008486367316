import React from 'react'
import EmptySection from '../../../atoms/EmptySection'
import { useMilestonesContext } from '../MilestonesContext'

const MilestonesEmptyState = () => {
  const { emptyStateLabel } = useMilestonesContext()
  return (
    <EmptySection
      title={emptyStateLabel}
      titleStyles={{
        fontSize: '2rem !important',
        fontWeight: 'bold'
      }}
      description=''
    />
  )
}

export default MilestonesEmptyState
