import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import {
  Typography
} from '@material-ui/core'

const useStyles = makeStyles(() => ({
  errorView: {
    display: 'flex',
    width: '100vw',
    height: '100vh',
    justifyContent: 'center',
    alignItems: 'center'
  }
}))

function ErrorView ({ error: { message } }) {
  const classes = useStyles()
  return (
    <div className={classes.errorView}>
      <Typography component='h1'>
        {message}
      </Typography>
    </div>
  )
}

ErrorView.propTypes = {
  error: PropTypes.shape({
    message: PropTypes.string.isRequired
  }).isRequired
}

export default ErrorView
