import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Skeleton from '../../atoms/Skeleton'
import { useAppContext } from '../../../redux/slices/appContext'
import { useClientDates } from '../../../api/coreData'
import { useAllocationVsTargetQuery } from '../../../api/rebalancer'
import TargetAllocationBarChart from './TargetAllocationBarChart'
import { useTargetAllocationScope } from './TargetAllocationScopeProvider'

const useStyles = makeStyles((theme) => ({
  chartArea: {
  },
  loader: {
    minHeight: '300px',
    padding: '20px'
  }
}))

const useTargetAllocationData = ({ grouping, compareTo, allocationGrouping, allocator, targetLocation, defaultFilter }) => {
  const scope = useTargetAllocationScope()
  const { clientId } = useAppContext()
  const { data: dates, isLoading: datesLoading } = useClientDates(clientId)
  const avtQuery = useMemo(() => {
    if (datesLoading) return null
    if (scope.levelType === 'account' && !scope.currentLevelId) return null

    const scopeFilter = {
      clientId
    }
    if (scope.levelType === 'account' && scope.currentLevelId) {
      scopeFilter.accountId = scope.currentLevelId
    }

    return ({
      levelTypes: grouping,
      allocator: allocator,
      allocationGrouping: allocationGrouping,
      targetLocation,
      compareTo,
      filters: {
        ...scopeFilter,
        ...(defaultFilter || {})
      },
      dateRange: {
        startDate: dates.max,
        endDate: dates.max
      }
    })
  }, [datesLoading, dates, grouping, allocationGrouping, allocator, compareTo, targetLocation, defaultFilter, clientId, scope])

  const { data, isLoading: queryLoading, error } = useAllocationVsTargetQuery(avtQuery)

  return {
    data: data || { allocation: [] },
    isLoading: queryLoading || datesLoading,
    error,
    dates
  }
}

function TargetAllocationChart ({
  variant,
  grouping,
  compareTo,
  allocationGrouping,
  allocator,
  valueAccessor,
  valueFormat,
  targetAccessor,
  tickFormat,
  targetLocation,
  defaultFilter,
  ...rest
}) {
  const classes = useStyles()
  const { data, loading } = useTargetAllocationData({
    grouping, compareTo, allocationGrouping, allocator, targetLocation, defaultFilter
  })
  const ChartComponent = useMemo(() => {
    if (loading) return null

    switch (variant) {
      case 'bar':
        return TargetAllocationBarChart
      default:
        return TargetAllocationBarChart
    }
  }, [loading, variant])

  if (loading) {
    return (
      <div className={classes.loader}>
        <Skeleton height='200px' />
      </div>
    )
  }

  return (
    <div className={classes.chartArea}>
      <ChartComponent
        data={data}
        compareTo={compareTo}
        valueAccessor={valueAccessor}
        valueFormat={valueFormat}
        targetAccessor={targetAccessor}
        tickFormat={tickFormat}
        {...rest}
      />
    </div>
  )
}

TargetAllocationChart.propTypes = {
  variant: PropTypes.oneOf(['bar']),
  grouping: PropTypes.array,
  compareTo: PropTypes.array,
  allocationGrouping: PropTypes.array,
  allocator: PropTypes.string,
  valueAccessor: PropTypes.string,
  valueFormat: PropTypes.string,
  targetAccessor: PropTypes.string,
  tickFormat: PropTypes.string,
  targetLocation: PropTypes.object,
  markerType: PropTypes.string,
  defaultFilter: PropTypes.object
}

TargetAllocationChart.defaultProps = {
  variant: 'bar',
  grouping: ['client'],
  compareTo: ['reporting'],
  allocationGrouping: ['client'],
  allocator: 'endingValue',
  targetLocation: {
    levelType: 'client'
  },
  markerType: 'default'
}

export default TargetAllocationChart
