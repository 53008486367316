import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import isEmpty from 'lodash/isEmpty'
import SearchBarInput from '../SearchBar/SearchBarInput'
import Text from '../../atoms/Text'

const useStyles = makeStyles(() => ({
  searchInputClassName: {
    width: '100%',
    border: 'none !important',
    background: '#FFF !important',
    marginBottom: '0 !important',
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    boxShadow: '0px 1px 5px 0px rgba(24, 27, 53, 0.06)'
  },
  emptyState: {
    width: '100%',
    padding: '2rem 2rem',
    textAlign: 'center'
  },
  loadingState: {
    width: '100%',
    padding: '2rem 2rem',
    textAlign: 'center'
  }
}))

const withSearch = (SelectComponent) => {
  const SelectWithSearch = ({
    isLoading,
    onFocus,
    onBlur,
    onClear,
    searchPlaceholder,
    onQueryChange,
    emptyState,
    ...props
  }) => {
    const classes = useStyles()

    const renderEmptyState = useMemo(() => {
      if (
        isLoading ||
        !isEmpty(props?.options) || !isEmpty(props?.defaultOptions)
      ) {
        return null
      }
      if (emptyState) return emptyState
      return (
        <div className={classes.emptyState}>
          <Text customFontWeight='600' text='No data found' />
        </div>
      )
    }, [
      isLoading,
      emptyState,
      props?.options,
      props?.defaultOptions,
      classes.emptyState
    ])

    return (
      <SelectComponent
        {...props}
        options={isLoading ? [] : props?.options}
        optionsHeader={
          <>
            <SearchBarInput
              onClear={onClear}
              onBlur={onBlur}
              onFocus={onFocus}
              loading={isLoading}
              onChange={onQueryChange}
              placeholder={searchPlaceholder}
              searchInputClassName={classes.searchInputClassName}
            />
            {renderEmptyState}
          </>
        }
      />
    )
  }

  SelectWithSearch.propTypes = {
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    isLoading: PropTypes.bool,
    searchPlaceholder: PropTypes.string,
    onQueryChange: PropTypes.func,
    emptyState: PropTypes.node,
    options: PropTypes.array,
    defaultOptions: PropTypes.array,
    onClear: PropTypes.func
  }

  return SelectWithSearch
}

withSearch.propTypes = {
  SelectComponent: PropTypes.node
}

export default withSearch
