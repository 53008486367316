import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import DateRangeSelectorCell from './DateRangeSelectorCell'
import TooltipTitleCell from './TooltipTitleCell'
import BaseCell from './BaseCell'
import HoldingPeriodReturnCell from './HoldingPeriodReturnCell'
import LinkCell from './LinkCell'
import OverrideCellValue from './OverrideCellValue'

export const CELL_TYPES = {
  holdingPeriodReturn: HoldingPeriodReturnCell,
  dateRangeSelector: DateRangeSelectorCell,
  tooltipTitle: TooltipTitleCell,
  link: LinkCell,
  baseCell: BaseCell,
  override: OverrideCellValue
}

const PerformanceCellPicker = (props) => {
  const { column } = props

  const CellType = useMemo(() => {
    const cellType = CELL_TYPES[column.cellType]
    if (!cellType) {
      throw Error(`[PerformanceCellPicker]: cellType {${column?.cellType}} is not a valid option`)
    }
    return cellType
  }, [column.cellType])

  return <CellType {...props} />
}

PerformanceCellPicker.propTypes = {
  column: PropTypes.shape({
    cellType: PropTypes.oneOf(Object.keys(CELL_TYPES))
  })
}

export default PerformanceCellPicker
