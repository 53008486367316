import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'
import find from 'lodash/find'
import filter from 'lodash/filter'
import noop from 'lodash/noop'
import { makeStyles } from '@material-ui/core/styles'
import {
  Grid,
  TextField,
  MenuItem,
  useTheme,
  Tooltip
} from '@material-ui/core'
import Text from '../../../atoms/Text'
import Skeleton from '../../../atoms/Skeleton'
import SplitButton from '../../../atoms/SplitButton'

const useStyles = makeStyles((theme) => ({
  label: {
    marginBottom: '14px',
    display: 'flex',
    justifyContent: 'flex-start'
  },
  field: {
    paddingRight: '50px',
    marginBottom: '24px'
  },
  tag: {
    fontWeight: 'bold',
    maxWidth: 'unset !important'
  },
  skeleton: {
    borderRadius: '1rem'
  },
  pill: {
    marginRight: '10px',
    padding: '3px 0px'
  },
  requiredField: {
    fontSize: '1.25rem',
    color: theme.palette.error.main,
    marginLeft: '5px',
    cursor: 'help'
  }
}))

const ClassificationTags = ({
  editMode,
  loading,
  classificationTagsData,
  assetClassificationTagsTypes,
  onChange,
  onClickRemove,
  selectedClassification
}) => {
  const classes = useStyles()
  const theme = useTheme()

  const isLoading = useMemo(() => {
    return loading ||
    isEmpty(classificationTagsData) ||
    isEmpty(assetClassificationTagsTypes)
  }, [loading, classificationTagsData, assetClassificationTagsTypes])

  const fields = useMemo(() => {
    if (isLoading) return null
    return classificationTagsData.map((classificationTag) => {
      const options = find(assetClassificationTagsTypes, ['id', classificationTag.classificationTagTypeId])?.classificationTags || []
      const value = selectedClassification[classificationTag.classificationTagTypeId]?.classificationTagId || ''
      const isRequired = Boolean(classificationTag.required)
      return (
        <Grid item xs={6} key={classificationTag.classificationTagTypeId} className={classes.field}>
          <div className={classes.label}>
            <Text text={classificationTag.longName} color={theme.palette.darkJungle} customFontSize='1.25rem' />
            {editMode && isRequired && (
              <Tooltip title='This field is required' placement='top'>
                <span className={classes.requiredField}>*</span>
              </Tooltip>
            )}
          </div>
          <TextField
            select
            onChange={(value) => onChange({
              value: value.target.value,
              classificationTagTypeId: classificationTag.classificationTagTypeId,
              isRequired,
              options
            })}
            id={classificationTag.classificationTagTypeId}
            placeholder='Choose classification'
            fullWidth
            disabled={!editMode}
            value={value}
          >
            {options.map(({ tagName, id }) => (
              <MenuItem key={id} value={id}>
                {tagName}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      )
    })
  }, [
    assetClassificationTagsTypes,
    classificationTagsData,
    editMode,
    isLoading,
    onChange,
    selectedClassification,
    classes.label,
    classes.field,
    classes.requiredField,
    theme.palette
  ])

  const renderedTags = useMemo(() => {
    const filteredSelectedClassification = filter(Object.values(selectedClassification), (item) => item.classificationTagId !== '')
    if (loading) {
      return new Array(3).fill(0).map((_, index) => (
        <Skeleton
          key={`skeleton-${index}`}
          width='10rem'
          height='1.5rem'
          className={classes.skeleton}
        />
      ))
    }
    return filteredSelectedClassification.map((classification) => {
      const onClickIcon = classification.isRequired ? noop : () => onClickRemove(classification)
      return (
        <div key={classification.classificationTagId} className={classes.pill}>
          <SplitButton
            readOnly={!editMode}
            buttonClassName={classes.tag}
            iconFontSize='1rem'
            text={classification.name}
            backgroundColor={theme.palette.lighterGrey}
            onClickIcon={onClickIcon}
          />
        </div>
      )
    })
  }, [
    loading,
    editMode,
    classes.tag,
    classes.skeleton,
    classes.pill,
    theme.palette,
    onClickRemove,
    selectedClassification
  ])

  return (
    <Grid container>
      <Grid item xs={6}>
        <Text text='Classification Tags' color={theme.palette.darkJungle} className={classes.label} customFontSize='1.25rem' />
        <Grid container>
          {renderedTags}
        </Grid>
      </Grid>
      <Grid item container xs={6}>
        {fields}
      </Grid>
    </Grid>
  )
}

ClassificationTags.propTypes = {
  editMode: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  classificationTagsData: PropTypes.any.isRequired,
  assetClassificationTagsTypes: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
  onClickRemove: PropTypes.func.isRequired,
  selectedClassification: PropTypes.any.isRequired
}

export default ClassificationTags
