import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import { makeStyles, Switch } from '@material-ui/core'
import { useFeeSchedule } from '../../../../../api/billing'
import DebouncedInput from '../../../../molecules/DebouncedInput'
import FadeIn from '../../../../molecules/Transitions/FadeIn'
import Loading from '../../../../molecules/Loading'
import { Blade } from './shared/Blade'
import MiniTable from './MiniTable'
import { useClientReviewContext } from './ClientReviewContext'

const useStyles = makeStyles((theme) => ({
  clientItem: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.layout.gap.medium,
    border: `1px solid ${theme.palette.gray.dark}`,
    padding: theme.layout.padding.thin,
    borderRadius: theme.layout.radius.tight,
    marginBottom: theme.layout.margin.thin
  },
  filters: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    backgroundColor: theme.palette.gray.main
  },
  search: {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0
  },
  table: {
    overflowY: 'auto',
    paddingBottom: '100px'
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },
  topBar: {
    borderBottom: `1px solid ${theme.palette.gray.darker}`
  },
  noData: {
    padding: theme.layout.padding.thick,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  loadingMessage: {
    textAlign: 'center'
  }
}))

const clientKeyAccessor = client => client?.clientId

function ClientBlade ({ columns, filterOn, hideFilters }) {
  const {
    client: selected,
    selectClient: onSelect,
    clients,
    onlyMyClients,
    setOnlyMyClients
  } = useClientReviewContext()
  const classes = useStyles()

  useFeeSchedule()
  const [filter, setFilter] = useState('')
  const summary = useMemo(() => {
    const lowerFilter = filter.toLowerCase()
    return (clients.data || [])
      .filter(x => (onlyMyClients === true && x.inUserBookOfBusiness === onlyMyClients) || onlyMyClients === false)
      .filter(x => !lowerFilter || (filterOn || []).reduce((prev, cur) => {
        const val = get(x, cur.accessor)?.toString()?.toLowerCase()
        // eslint-disable-next-line eqeqeq
        const res = cur.exact ? val == lowerFilter : val.includes(lowerFilter)
        return prev || res
      }, false))
  }, [filter, clients.data, filterOn, onlyMyClients])

  if (clients.error) {
    return (
      <div className={classes.blade}>
        Sorry, there was an error loading this view
      </div>
    )
  }

  return (
    <Blade scrollable={false}>
      <div className={classes.wrapper}>
        <div className={classes.topBar}>
          <div>
            <DebouncedInput className={classes.search} onChange={setFilter} delay={100} />
          </div>
          {hideFilters ? null : (
            <div className={classes.filters}>
              <span>Only My Clients</span>
              <span>
                <Switch
                  checked={onlyMyClients ?? false}
                  onChange={(e) => setOnlyMyClients(e.target.checked)}
                  color='primary'
                />
              </span>
            </div>
          )}
        </div>
        {clients.isLoading ? (
          <div>
            <Loading />
            <FadeIn delay={5} duration={5} className={classes.loadingMessage}>
              Pre-loading clients for a better experience
            </FadeIn>
          </div>
        ) : summary?.length ? (
          <div className={classes.table}>
            <div>
              <MiniTable
                data={summary}
                columns={columns}
                keyAccessor={clientKeyAccessor}
                onSelect={onSelect}
                selected={selected}
              />
            </div>
          </div>
        ) : (
          <div className={classes.noData}>
            No Clients Available
          </div>
        )}
      </div>
    </Blade>
  )
}

ClientBlade.propTypes = {
  columns: PropTypes.array,
  filterOn: PropTypes.array,
  hideFilters: PropTypes.bool
}

ClientBlade.defaultProps = {
  filterOn: [
    { accessor: 'clientId', exact: true },
    { accessor: 'longName' }
  ],
  hideFilters: false
}

export default ClientBlade
