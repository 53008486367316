import React, { useRef } from 'react'
import { makeStyles } from '@material-ui/core'
import ActionGroup from '../../shared/ActionGroup'
import { useClientDetails, useSectionEditing } from '../ClientDetailsFormContext'
import SectionScreen from '../SectionScreen'
import DeleteButton from '../DeleteButton'
import DeleteDialog from '../DeleteDialog'
import HeaderInfoDisplay from './HeaderInfoDisplay'

const useStyles = makeStyles((theme) => ({
  headerInfo: {
    position: 'relative',
    flex: '1 0'
  }
}))

function HeaderInfo () {
  const classes = useStyles()
  const { client, deleteClient } = useClientDetails()
  const editing = useSectionEditing('header')
  const deleteRef = useRef()

  return (
    <SectionScreen sectionName='header' className={classes.headerInfo}>
      <ActionGroup>
        <DeleteButton
          policy='admin_edit_clientinfo'
          disabled={editing}
          onClick={() => deleteRef.current.open()}
        />
      </ActionGroup>
      <HeaderInfoDisplay client={client} />
      <DeleteDialog
        ref={deleteRef}
        onConfirm={deleteClient}
      />
    </SectionScreen>
  )
}

export default HeaderInfo
