import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import Skeleton from '../../../atoms/Skeleton'
import { SKELETON_VARIANTS } from '../../../../constants'

const useStyles = makeStyles(() => ({
  container: {
    padding: '1.5rem 1rem',
    gap: '0.5rem',
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    overflowX: 'auto'
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    width: '5rem',
    alignItems: 'center'
  }
}))

const TasksPreviewSkeleton = ({ items }) => {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      {Array(items).fill(0).map((_, index) => {
        return (
          <div key={index} className={classes.item}>
            <Skeleton height='4rem' width='4rem' variant={SKELETON_VARIANTS.circle} />
            <Skeleton width='2rem' height='1rem' variant={SKELETON_VARIANTS.rect} />
          </div>
        )
      })}
    </div>
  )
}

TasksPreviewSkeleton.propTypes = {
  items: PropTypes.number
}

TasksPreviewSkeleton.defaultProps = {
  items: 10
}

export default TasksPreviewSkeleton
