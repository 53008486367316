import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { useProtectedResource } from '../../hooks'
import { SKELETON_VARIANTS, TEXT_VARIANTS } from '../../constants'
import Skeleton from './Skeleton'
import Text from './Text'

const useStyles = makeStyles((theme) => ({
  image: ({ width, height, extraStyles }) => ({
    width: width || 'inherit',
    height: height || 'inherit',
    ...extraStyles
  })
}))

function Image ({
  alt,
  extraStyles,
  isPublicResource,
  height,
  showCustomAlt,
  src,
  width
}) {
  const [hasError, setHasError] = useState(false)
  const classes = useStyles({ width, height, extraStyles })
  const { loading, url } = useProtectedResource(src, isPublicResource)

  if (loading) {
    return (
      <Skeleton
        height={height}
        width={width}
        variant={SKELETON_VARIANTS.rect}
      />
    )
  }

  return (
    <>
      {hasError && showCustomAlt
        ? <Text variant={TEXT_VARIANTS.caption} className={classes.image} text={alt} />
        : <img className={classes.image} src={url} alt={alt} onError={() => setHasError(true)} />}
    </>
  )
}

Image.propTypes = {
  alt: PropTypes.string,
  extraStyles: PropTypes.any,
  isPublicResource: PropTypes.bool,
  height: PropTypes.string,
  showCustomAlt: PropTypes.bool,
  src: PropTypes.string,
  width: PropTypes.string
}

Image.defaultProps = {
  alt: 'summit-image',
  extraStyles: {},
  isPublicResource: false,
  height: undefined,
  showCustomAlt: false,
  src: undefined,
  width: undefined
}

export default Image
