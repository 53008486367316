/* eslint-disable react/jsx-key */
import React, { useCallback, useMemo, useRef } from 'react'
import { useColumnOrder, useExpanded, usePagination, useTable } from 'react-table'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { CircularProgress } from '@material-ui/core'
import TableSkeleton from '../../../../../atoms/TableSkeleton'
import ExpandyIcon from '../../../../../organisms/PerformanceTableV2/PerformancePresentationTable/ExpandyIcon'
import SydSelect from '../../../../../commonDesign/SydSelect'
import SydButton from '../../../../../commonDesign/Button'
import Icon from '../../../../../atoms/Icon'
import useStyles from './styles'

const BillingRunDetailsTable = (
  {
    columns,
    data,
    hiddenColumns,
    loading,
    updatingIds = [],
    currentPage,
    pageSize,
    onPageChange,
    onPageSizeChange
  }) => {
  const tableRef = useRef(null)
  const classes = useStyles()

  const pageCount = useMemo(() => Math.ceil(data.total / pageSize), [data.total, pageSize])
  const canPreviousPage = useMemo(() => currentPage > 1, [currentPage])
  const canNextPage = useMemo(() => currentPage < pageCount, [currentPage, pageCount])

  const {
    getTableProps,
    headerGroups,
    getTableBodyProps,
    prepareRow,
    page
  } = useTable({
    columns,
    data: data.details ?? [],
    autoResetExpanded: false,
    initialState: {
      hiddenColumns: hiddenColumns ?? []
    },
    autoResetPage: false,
    autoResetGroupBy: false,
    autoResetSelectedRows: false,
    autoResetFilters: false,
    autoResetRowState: false,
    paginateExpandedRows: true,
    manualPagination: true,
    pageCount
  }, useExpanded, useColumnOrder, usePagination)

  const pageSizeOptions = useMemo(() => {
    if (data?.total <= page.length) {
      return [page.length]
    }
    return [...new Set([page.length ?? 0, 10, 50, 100])].sort()
  }, [page.length, data?.total])

  const rowClickHandler = useCallback(row => () => {
    if (row.subRows?.length) {
      row.toggleRowExpanded()
    }
  }, [])

  return (
    <div ref={tableRef} className={classes.wrapper}>
      {loading ? (
        <TableSkeleton />
      ) : (
        <>
          <table {...getTableProps()} className={classes.table}>
            <thead className={classes.thead}>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()} className={classes.headerRow}>
                  {headerGroup.headers.map((column) => (
                    <th
                      key={column.key}
                      style={{ width: column.width }}
                      className={classes.headerCell}
                    >
                      <div className={classes.headerCellInternal}>
                        <span>{column.render('Header')}</span>
                      </div>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row, i) => {
                prepareRow(row)
                return (
                  <tr
                    {...row.getRowProps()}
                    onClick={rowClickHandler(row)}
                    className={clsx(
                      classes.bodyRow, {
                        __child: (row.depth > 0),
                        __expanded: !!row.isExpanded,
                        __expandable: !!row.subRows?.length,
                        __disabled: row.original?.disabled,
                        __updating: updatingIds?.includes(row?.original?.id)
                      }, `__depth_${row.depth}`)}
                  >
                    {row.cells.map((cell, index) => (
                      <td
                        {...cell.getCellProps()}
                        className={classes.bodyCell}
                      >
                        {index === 0 && !!row.subRows?.length ? (
                          <div className={classes.expandContainer}>
                            {updatingIds?.includes(row?.original?.id)
                              ? <CircularProgress size={16} /> : (
                                <ExpandyIcon
                                  className={classes.expandHandle} enabled={!!row.subRows?.length}
                                  expanded={row.isExpanded}
                                />
                              )}
                            {cell.render('Cell')}
                          </div>
                        ) : cell.render('Cell')}
                      </td>
                    ))}
                  </tr>
                )
              })}
            </tbody>
          </table>
          <div className={classes.footerContainer}>
            <div className={classes.paginationContainer}>
              <div className={classes.pageSelectContainer}>
                <span>Showing</span>
                <div style={{ width: '80px' }}>
                  <SydSelect
                    size='xs'
                    value={page.length}
                    options={pageSizeOptions}
                    onChange={(e) => onPageSizeChange(e.target.value)}
                  />
                </div>
                <span>of</span>
                <span className={classes.totalCount}>{data.total ?? 0}</span>
                <span>Billing Run Records</span>
              </div>
              <div className={classes.paginationContainer}>
                <div className={classes.pageSelectContainer}>
                  <span>Page {currentPage} of {pageCount}</span>
                </div>
              </div>
              <div className='pageIncrementContainer'>
                <SydButton
                  variant='ghost'
                  onClick={() => onPageChange(currentPage - 1)}
                  disabled={!canPreviousPage}
                >
                  <Icon name='chevronLeft' margin='0 6px 0 0' customSize={18} />
                  Prev
                </SydButton>
                <SydButton
                  variant='ghost'
                  onClick={() => onPageChange(currentPage + 1)}
                  disabled={!canNextPage}
                >
                  Next
                  <Icon name='chevronRight' margin='0 0 0 6px' customSize={18} />
                </SydButton>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  )
}

BillingRunDetailsTable.propTypes = {
  columns: PropTypes.array,
  data: PropTypes.objectOf({
    details: PropTypes.array,
    total: PropTypes.number
  }),
  hiddenColumns: PropTypes.array,
  loading: PropTypes.bool,
  updatingIds: PropTypes.array,
  currentPage: PropTypes.number,
  pageSize: PropTypes.number,
  onPageChange: PropTypes.func,
  onPageSizeChange: PropTypes.func
}

export default BillingRunDetailsTable
