import React, { useCallback, useEffect, useImperativeHandle, useState } from 'react'
import { InputBase, InputLabel, makeStyles, withStyles, Grid } from '@material-ui/core'
import PropTypes from 'prop-types'
import { Controller, useForm } from 'react-hook-form'
import get from 'lodash/get'
import BlockButtonGroup from '../../../molecules/BlockButtonGroup'
import { useCSV } from '../../../../hooks'
import SydModal from '../../../commonDesign/SydModal'
import 'jspdf-autotable'
import {
  COLUMN_OPTIONS,
  downloadPDFFile,
  FORMAT_TYPES
} from '../../GroupingProvider/ExportTableDialog/helpers'
import SydModalActions from '../../../commonDesign/SydModal/SydModalActions'
import SydButton from '../../../commonDesign/Button'

const useStyles = makeStyles((theme) => ({
  section: {
    marginBottom: '1.5rem'
  },
  label: {
    fontSize: '1rem',
    color: theme.palette.text.primary,
    '& span': {
      color: theme.palette.error.primary
    }
  }
}))

const Input = withStyles((theme) => ({
  root: {
    width: '100%',
    '&.Mui-error input': {
      borderColor: theme.palette.error.primary
    }
  },
  input: {
    padding: '1rem 0.75rem',
    borderRadius: '4px',
    background: '#F4F5F6',
    border: '2px solid #F4F5F6',
    '&:focus': {
      borderColor: '#212945'
    }
  }
}))(InputBase)

const ExportTableDialog = React.forwardRef(function ({
  defaultValues,
  hideExportTypePicker,
  hideColumnsVisibilityTypePicker
}, ref) {
  const classes = useStyles()
  const [formatType, setFormatType] = useState(FORMAT_TYPES.CSV.value)
  const [columnExportType, setColumnExportType] = useState(COLUMN_OPTIONS.VISIBLE_COLUMNS.value)
  const [isOpen, setIsOpen] = useState(false)
  const [csvData, setCsvData] = useState({
    data: [],
    columnState: {},
    availableColumns: []
  })

  useImperativeHandle(ref, () => ({
    open: (data, columnState, availableColumns) => {
      setIsOpen(true)
      setCsvData({ data, columnState, availableColumns })
    }
  }), [setIsOpen, setCsvData])

  const {
    reset,
    control,
    setFocus,
    register,
    formState: { errors },
    handleSubmit
  } = useForm({
    defaultValues,
    mode: 'onChange',
    reValidateMode: 'onChange'
  })

  useEffect(() => {
    if (!isOpen) {
      reset({ ...defaultValues })
    }
  }, [isOpen, reset, defaultValues])

  useEffect(() => {
    const firstError = Object.keys(errors).reduce((field, fieldError) => {
      return errors[field] ? field : fieldError
    }, null)

    if (firstError) {
      setFocus(firstError)
    }
  }, [errors, setFocus])

  const { downloadCSVFile } = useCSV()

  const onSubmit = useCallback(
    ({ exportFileName }) => {
      const fileExtension = formatType.toLowerCase()
      const fileName = exportFileName
        ? `${exportFileName}.${fileExtension}`
        : `summit_export.${fileExtension}`

      const { data, columnState, availableColumns } = csvData
      const columnConfig = Object.values(columnState)
        .sort((a, b) => a.ordinal - b.ordinal)
        .filter(x => columnExportType === COLUMN_OPTIONS.VISIBLE_COLUMNS.value ? !x.hidden : true)

      // map the header and data rows
      const headers = columnConfig.map(c => c.Header || c.header)
      const dataRows = data.map(d =>
        columnConfig.map(conf => {
          const column = availableColumns.find(x => x.id === conf.id)
          return column ? get(d, column.accessor) : null
        })
      )
      if (formatType === FORMAT_TYPES.CSV.value) {
        downloadCSVFile({
          data: dataRows, headers, fileName
        })
      }
      if (formatType === FORMAT_TYPES.PDF.value) {
        const combinedData = [headers, ...dataRows]
        downloadPDFFile({ data: combinedData, fileName })
      }
      setIsOpen(false)
    },
    [csvData, setIsOpen, downloadCSVFile, formatType, columnExportType]
  )

  return (
    <SydModal
      title='Export Table'
      open={isOpen}
      onClose={() => setIsOpen(false)}
      size='small'
    >
      <Grid container spacing={2}>
        {!hideExportTypePicker && (
          <>
            <Grid item xs={12}>
              <InputLabel className={classes.label}>
                Which format would you like to use?
              </InputLabel>
            </Grid>
            <Grid item xs={12}>
              <BlockButtonGroup
                selectedValue={formatType}
                onSelectOption={setFormatType}
              >
                {Object.values(FORMAT_TYPES).map(({ value, label }) => (
                  <BlockButtonGroup.Option key={value} value={value}>
                    {label}
                  </BlockButtonGroup.Option>
                ))}
              </BlockButtonGroup>
            </Grid>
          </>
        )}
        {!hideColumnsVisibilityTypePicker && (
          <>
            <Grid item xs={12}>
              <InputLabel className={classes.label}>
                What do you want to export?
              </InputLabel>
            </Grid>
            <Grid item xs={12}>
              <BlockButtonGroup
                selectedValue={columnExportType}
                onSelectOption={setColumnExportType}
              >
                {Object.values(COLUMN_OPTIONS).map(({ value, label }) => (
                  <BlockButtonGroup.Option key={value} value={value}>
                    {label}
                  </BlockButtonGroup.Option>
                ))}
              </BlockButtonGroup>
            </Grid>
          </>
        )}
        <Grid item xs={12}>
          <InputLabel className={classes.label} required>
            File Name
          </InputLabel>
        </Grid>
        <Grid item xs={12}>
          <Controller
            name='exportFileName'
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <Input
                {...field}
                placeholder='file name'
                ref={register('exportFileName').ref}
                error={errors?.exportFileName}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <SydModalActions>
            <SydButton variant='ghost' size='md' onClick={() => setIsOpen(false)}>Cancel</SydButton>
            <SydButton
              variant='primary'
              size='md'
              onClick={handleSubmit(onSubmit)}
            >
              Export
            </SydButton>
          </SydModalActions>
        </Grid>
      </Grid>
    </SydModal>
  )
})

ExportTableDialog.propTypes = {
  hideExportTypePicker: PropTypes.bool,
  hideColumnsVisibilityTypePicker: PropTypes.bool,
  defaultValues: PropTypes.object
}

ExportTableDialog.defaultProps = {
  hideExportTypePicker: false,
  hideColumnsVisibilityTypePicker: false,
  defaultValues: {
    exportFileName: ''
  }
}

export default ExportTableDialog
