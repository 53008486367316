import React from 'react'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { useAppContext } from '../../../redux/slices/appContext'
import { DynamicPlanningContext, useDynamicPlanningValues } from './DynamicPlanningContext'

dayjs.extend(utc)

const DynamicPlanningContextWrapper = ({
  defaultAssumptions = {
    inflationModel: { growthType: 'constant', growthFactor: 0.03, adjustReturns: false },
    portfolioGrowthModel: { growthType: 'constant', growthFactor: 0.069 }
  },
  children
}) => {
  const { clientId, availableDates: { mainDate } } = useAppContext()
  const startDate = dayjs.utc(mainDate)
  const endDate = dayjs.utc(mainDate).add(40, 'y')

  const dynamicPlanningValues = useDynamicPlanningValues({
    clientId,
    displayDates: { startDate, endDate },
    defaultAssumptions
  })

  return (
    <DynamicPlanningContext.Provider value={dynamicPlanningValues}>
      {children}
    </DynamicPlanningContext.Provider>
  )
}

DynamicPlanningContextWrapper.propTypes = {
  defaultAssumptions: PropTypes.object,
  children: PropTypes.node
}

export default DynamicPlanningContextWrapper
