import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import SydButton from '../../../../../../commonDesign/Button'
import {
  useRemoveClientFeeScheduleMutation
} from '../../../../../../../api/billing'
import { useDialogStyles } from '../../common'
import FeeScheduleTiers from '../../FeeScheduleTiers'

const useSubmitter = (form, onComplete, client) => {
  const { handleSubmit } = form
  const [processing, setProcessing] = useState(false)
  const [error, setError] = useState(null)
  const { mutateAsync: addFeeSchedule } = useRemoveClientFeeScheduleMutation()
  const onSubmit = useCallback(async (formData) => {
    const command = {
      clientId: client.clientId,
      feeScheduleId: +formData.feeScheduleId
    }

    try {
      setError(null)
      setProcessing(true)
      const result = await addFeeSchedule(command)
      if (result?.statusCode === 500) {
        throw new Error('Failed to remove fee schedule')
      }
      onComplete(result)
    } catch (err) {
      setError(err?.toString())
    } finally {
      setProcessing(false)
    }
  }, [addFeeSchedule, setProcessing, onComplete, client, setError])

  const submitter = useCallback(async (e) => {
    const onValid = async (form) => {
      await onSubmit(form)
    }
    const onInvalid = (errors) => {
      console.error(errors)
    }

    const handler = handleSubmit(onValid, onInvalid)
    await handler(e)
  }, [handleSubmit, onSubmit])

  return {
    submitter,
    processing,
    error
  }
}

function RemoveFeeScheduleForm ({ client, feeSchedule, onCancel, onComplete }) {
  const classes = useDialogStyles()
  const form = useForm({
    mode: 'onChange',
    defaultValues: {
      clientId: client.clientId,
      feeScheduleId: feeSchedule.feeScheduleId
    }
  })

  const { submitter, processing, error } = useSubmitter(form, onComplete, client)

  return (
    <>
      <div className={classes.form}>
        <div className={classes.confirm}>
          <div>Are you sure you want to remove this fee schedule?</div>
          <div><strong>{feeSchedule.longName}</strong></div>
          <div><FeeScheduleTiers feeSchedule={feeSchedule} /></div>
        </div>
      </div>
      <div className={classes.actions}>
        {error ? (<div className={classes.error}>{error}</div>) : null}
        <SydButton disabled={processing} variant='ghost' size='md' onClick={onCancel}>Cancel</SydButton>
        <SydButton
          variant='primary'
          size='md'
          onClick={submitter}
          processing={processing}
        >
          Remove
        </SydButton>
      </div>
    </>
  )
}

RemoveFeeScheduleForm.propTypes = {
  client: PropTypes.shape({
    clientId: PropTypes.number
  }),
  feeSchedule: PropTypes.shape({
    feeScheduleId: PropTypes.number,
    longName: PropTypes.string
  }),
  onComplete: PropTypes.func,
  onCancel: PropTypes.func
}

export default RemoveFeeScheduleForm
