import React from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@material-ui/core'
import { useListPlatformDataSources } from '../../../../api/ingestion'
import Skeleton from '../../../atoms/Skeleton'
import PageContainer from '../components/PageContainer'
import TagGroup from './TagGroup'

const DataSourceLoading = () => {
  return (
    <PageContainer>
      <Grid item xs={12}><Skeleton width='300px' height='30px' /></Grid>
      <Grid item xs={3}><Skeleton width='240px' height='120px' /></Grid>
      <Grid item xs={3}><Skeleton width='240px' height='120px' /></Grid>
      <Grid item xs={3}><Skeleton width='240px' height='120px' /></Grid>
      <Grid item xs={3}><Skeleton width='240px' height='120px' /></Grid>
    </PageContainer>
  )
}

const PlatformDataSources = ({ root }) => {
  const { data: tags, isLoading } = useListPlatformDataSources()

  if (isLoading) {
    return <DataSourceLoading />
  }

  return (
    <PageContainer>
      <Grid item xs={12} md={8} lg={10}>
        {tags.map(tag => (
          <TagGroup key={tag.name} dataSourceTag={tag} root={root} />
        ))}
      </Grid>
    </PageContainer>
  )
}

PlatformDataSources.propTypes = {
  root: PropTypes.string
}

PlatformDataSources.defaultProps = {
  root: '/data-home'
}

export default PlatformDataSources
