import { useMemo, useState } from 'react'
import isEmpty from 'lodash/isEmpty'
import { DEFAULT_SELECT_SEARCH_PAGINATION, MILLISECONDS_BEFORE_SEARCH } from '../constants'
import { useClassificationTags } from '../api/coreData'
import useDebouncedValue from './useDebounceValue'
import { getSearchQuery } from './helpers'

const SEARCH_FIELDS = [
  'classificationType',
  'shortName',
  'longName',
  'classificationTagTypeName',
  'classificationTagTypeLongName'
]

const getQueryFilters = ({ query, excludeLevelIds }) => {
  const filters = {
    classificationTagTypeId: null,
    classificationTagId: null,
    classificationType: null,
    shortName: null,
    longName: null,
    classificationTagTypeName: null,
    classificationTagTypeLongName: null
  }
  const levelFilters = {
    textSearch: {
      shortName: [{ op: 'contains', value: '' }]
    }
  }
  if (query) {
    levelFilters.textSearch = getSearchQuery(SEARCH_FIELDS, query)
  }
  if (!isEmpty(excludeLevelIds)) {
    filters.classificationTagId = [{ op: 'notIn', value: excludeLevelIds }]
  }
  return { filters, levelFilters }
}

const mapClassificationTagTypes = (classes = []) => {
  return classes.map((classTag) => ({
    label: `${classTag.classificationTagTypeName} - ${classTag.longName}`,
    value: classTag.classificationTagId,
    payload: {
      ...classTag
    }
  }))
}

const useSearchClassificationTagsDebounced = (params) => {
  const {
    query = {},
    queryOptions = {},
    defaultClassificationTagIds = [],
    debounceDelay = MILLISECONDS_BEFORE_SEARCH
  } = params || {}

  const [queryText, setQuery] = useState('')
  const debouncedQuery = useDebouncedValue(queryText, debounceDelay)

  const { queryParams, queryParamOptions } = useMemo(() => {
    const { filters, levelFilters } = getQueryFilters({
      query: debouncedQuery
    })
    return {
      queryParams: {
        filters,
        includes: {
          assetCount: true
        },
        resultType: 'details',
        ...DEFAULT_SELECT_SEARCH_PAGINATION,
        sort: [{ field: 'ordinal', dir: 'asc' }],
        ...levelFilters,
        ...query
      },
      queryParamOptions: {
        mapper: mapClassificationTagTypes,
        ...queryOptions
      }
    }
  }, [debouncedQuery, query, queryOptions])

  const { data, isLoading } = useClassificationTags(
    queryParams,
    queryParamOptions
  )

  const defaultOptionsQuery = useMemo(() => {
    return {
      queryParams: {
        ...queryParams,
        filters: {
          ...queryParams.filters,
          classificationTagId: defaultClassificationTagIds
        }
      },
      queryParamOptions: {
        mapper: mapClassificationTagTypes,
        enabled: !isEmpty(defaultClassificationTagIds),
        ...queryOptions
      }
    }
  }, [defaultClassificationTagIds, queryOptions, queryParams])

  const { data: defaultOptions, isLoading: isLoadingDefaults } =
    useClassificationTags(
      defaultOptionsQuery.queryParams,
      defaultOptionsQuery.queryParamOptions
    )

  const isTransitioningOptions = debouncedQuery !== queryText || isLoading

  return {
    options: data,
    isLoading:
      isLoading || (isLoadingDefaults && !isEmpty(defaultClassificationTagIds)),
    onChangeQuery: setQuery,
    isSearchLoading: isTransitioningOptions,
    defaultOptions: defaultOptions ?? []
  }
}

export default useSearchClassificationTagsDebounced
