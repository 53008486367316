import React from 'react'
import PropTypes from 'prop-types'
import { animated, useSpring } from '@react-spring/web'
import { alpha, IconButton, makeStyles } from '@material-ui/core'
import Icon from '../../atoms/Icon'
import { useHomeDashboard } from './HomeDashboardContext'

const useStyles = makeStyles((theme) => ({
  actionButtons: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    alignSelf: 'flex-end'
    // marginTop: ({ expanded }) => expanded ? '-1rem' : 0
  },
  fullViewOptions: {
    // backgroundColor: theme.palette.transparent,
    display: 'flex',
    position: 'absolute',
    top: '-10px',
    right: '-10px',
    zIndex: 1,
    borderRadius: theme.layout.radius.circle,
    backgroundColor: ({ framed }) => framed ? theme.palette.background.default : theme.palette.background.clear,
    border: ({ framed }) => framed ? `1px solid ${alpha(theme.palette.primary.main, 0.5)}` : 'none'
  },
  [theme.breakpoints.down('xs')]: {
    actionButtons: {
      display: 'none'
    }
  }
}))

const ActionButtons = ({ hidden, idle, framed }) => {
  const { toggleExpanded, expanded } = useHomeDashboard()
  const classes = useStyles({ expanded, framed })
  const springs = useSpring({
    to: { opacity: idle ? 0 : 1 },
    from: { opacity: 1 },
    leave: { opacity: 0 }
  })

  if (hidden) return null

  return (
    <animated.div className={classes.actionButtons} style={springs}>
      <div className={classes.fullViewOptions}>
        <IconButton
          aria-label='expand full view'
          aria-haspopup='true'
          onClick={toggleExpanded}
          color='inherit'
        >
          <Icon
            customSize='1.2rem'
            name={expanded ? 'collapse' : 'expand'}
          />
        </IconButton>
      </div>
    </animated.div>
  )
}

ActionButtons.propTypes = {
  framed: PropTypes.bool,
  hidden: PropTypes.bool,
  idle: PropTypes.bool
}

ActionButtons.defaultProps = {
  framed: false,
  hidden: false,
  idle: false
}

export default ActionButtons
