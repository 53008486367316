import { useMemo } from 'react'
import { useAppContext } from '../redux/slices/appContext'

const { REACT_APP_S3_PUBLIC_RESOURCES_URL } = process.env

const resourceTypes = {
  CLIENTS: 'clients',
  IMAGES: 'images',
  PERSONS: 'persons'
}

const createResourceUrl = ({
  firmId,
  resourceType = resourceTypes.IMAGES,
  identifier
}) => `${REACT_APP_S3_PUBLIC_RESOURCES_URL}/${firmId}/${resourceType}/${identifier}`

export const usePublicImageUrl = (identifier) => {
  const { firmId } = useAppContext()

  const resourceUrl = useMemo(() => createResourceUrl({
    firmId,
    identifier
  }), [firmId, identifier])

  return resourceUrl
}
