import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

const StyledLineItem = ({ id, lineGenerator, points, options = {} }) => {
  const path = useMemo(() => lineGenerator(points), [lineGenerator, points])

  // If need animations, look at: https://github.com/plouc/nivo/blob/master/packages/line/src/LinesItem.js
  return (
    <path id={id} d={path} fill='None' {...options} />
  )
}

StyledLineItem.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  points: PropTypes.arrayOf(
    PropTypes.shape({
      x: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      y: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    })
  ),
  options: PropTypes.shape({
    strokeWidth: PropTypes.number.isRequired,
    stroke: PropTypes.string.isRequired,
    strokeDasharray: PropTypes.string,
    strokeOpacity: PropTypes.string
  }),
  lineGenerator: PropTypes.func.isRequired
}

const StyledLines = ({ series, lineGenerator }) =>
  useMemo(() => (series || [])
    .slice(0)
    .reverse()
    .map(({ id, data, strokeWidth, color, options = {} }) => (
      <StyledLineItem
        key={id}
        id={id}
        points={data.map(d => d.position)}
        lineGenerator={lineGenerator}
        options={{
          ...options,
          strokeWidth,
          stroke: color
        }}
      />
    )), [series, lineGenerator])

StyledLines.propTypes = {
  series: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      color: PropTypes.string.isRequired,
      data: PropTypes.arrayOf(
        PropTypes.shape({
          data: PropTypes.shape({
            x: PropTypes.oneOfType([
              PropTypes.string,
              PropTypes.number,
              PropTypes.instanceOf(Date)
            ]),
            y: PropTypes.oneOfType([
              PropTypes.string,
              PropTypes.number,
              PropTypes.instanceOf(Date)
            ])
          }).isRequired,
          position: PropTypes.shape({
            x: PropTypes.number,
            y: PropTypes.number
          }).isRequired
        })
      ).isRequired
    })
  ).isRequired,
  lineGenerator: PropTypes.func.isRequired
}

export default StyledLines
