import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import ViewTabs from '../../../organisms/AdvisorView/components/AdvisorViewTabs'
import { usePolicies } from '../../../../hooks'
import IndexViewActions from './IndexViewActions'

const tabOptions = {
  nlq: {
    value: 'nlq',
    label: 'NLQ'
  },
  nlq2: {
    value: 'nlq2',
    label: 'Denali Assistant'
  }
}

const useTabOptions = () => {
  const policies = usePolicies()
  return useMemo(() => {
    const result = {}
    if (policies.internalOnly) {
      result.nlq = tabOptions.nlq
    }
    if (policies.internalOnly) {
      result.nlq2 = tabOptions.nlq2
    }
    return result
  }, [policies])
}

function IndexViewTabs ({
  onTabChange,
  disabled,
  root,
  nlqTab,
  nlq2Tab
}) {
  const tabs = useTabOptions()

  return (
    <div>
      <ViewTabs.Group tabOptions={tabs} onChange={onTabChange}>
        <div>
          <ViewTabs.Links
            disabled={disabled}
            tabsKey='data_home_index'
            rightAdornment={<IndexViewActions root={root} />}
            noHistory
          />
        </div>
        {tabs.nlq ? (
          <ViewTabs.Tab value='nlq'>
            {nlqTab}
          </ViewTabs.Tab>
        ) : null}
        {tabs.nlq2 ? (
          <ViewTabs.Tab value='nlq2'>
            {nlq2Tab}
          </ViewTabs.Tab>
        ) : null}
      </ViewTabs.Group>
    </div>
  )
}

IndexViewTabs.propTypes = {
  onTabChange: PropTypes.func,
  disabled: PropTypes.bool,
  root: PropTypes.string,
  nlqTab: PropTypes.node.isRequired,
  nlq2Tab: PropTypes.node
}

IndexViewTabs.defaultProps = {
  onTabChange: () => {},
  disabled: false
}

export default IndexViewTabs
