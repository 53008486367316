import PropTypes from 'prop-types'
import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useRouteMatch, useHistory } from 'react-router-dom'
import { createTheme, ThemeProvider } from '@material-ui/core/styles'
import { useBoolean } from '../../../../hooks'
import { useListAbundanceEngineViews } from '../../../../api/abundanceEngine'
import defaultTheme from '../../../../theme'
import { localStorageHelper } from '../../../../utils/localStorageHelper'
import { LOCAL_STORAGE_KEYS } from '../../../../constants'

export const AbundanceEngineGalleryContext = createContext()

const initFirmConfig = localStorageHelper.load(
  LOCAL_STORAGE_KEYS.aeGalleryConfig
) || {
  logoMediaId: null,
  logoPreview: null,
  backgroundImageMediaId: null,
  backgroundImagePreview: null,
  primaryColor: defaultTheme?.palette?.cloudBurst,
  secondaryColor: defaultTheme?.palette?.bluePalette?.main
}

const AbundanceEngineGalleryContextProvider = ({ children }) => {
  const history = useHistory()
  const { url } = useRouteMatch()

  const [isCustomizeDialogOpen, toggleCustomizeDialog] = useBoolean()
  const [presentationMode, togglePresentationMode] = useBoolean()
  const [wheelEditMode, toggleWheelEditMode] = useBoolean()
  const [selectedViews, setSelectedViews] = useState([])

  const [firmConfig, setFirmConfig] = useState(initFirmConfig)

  useEffect(() => {
    localStorageHelper.store(LOCAL_STORAGE_KEYS.aeGalleryConfig, firmConfig)
  }, [firmConfig])

  const queryOptions = useMemo(() => {
    return {
      mapper: (data) => data?.rows
    }
  }, [])
  const { data: abundanceEngineViews } = useListAbundanceEngineViews(
    undefined,
    queryOptions
  )

  const onGenerateWheel = useCallback(() => {
    toggleWheelEditMode.off()
    history.push(`${url}/preview`)
  }, [history, toggleWheelEditMode, url])

  const presentationTheme = useMemo(() => {
    if (!firmConfig) return undefined
    return createTheme({
      ...defaultTheme,
      palette: {
        ...defaultTheme.palette,
        primary: {
          main: firmConfig.primaryColor
        },
        secondary: {
          main: firmConfig.secondaryColor
        }
      }
    })
  }, [firmConfig])

  const value = {
    firmConfig,
    setFirmConfig,
    isCustomizeDialogOpen,
    toggleCustomizeDialog,
    presentationMode,
    togglePresentationMode,
    wheelEditMode,
    toggleWheelEditMode,
    onGenerateWheel,
    selectedViews,
    setSelectedViews,
    abundanceEngineViews
  }

  return (
    <AbundanceEngineGalleryContext.Provider value={value}>
      <ThemeProvider theme={presentationTheme}>{children}</ThemeProvider>
    </AbundanceEngineGalleryContext.Provider>
  )
}

AbundanceEngineGalleryContextProvider.propTypes = {
  children: PropTypes.node
}

export const useAbundanceEngineGalleryContext = () => {
  const context = useContext(AbundanceEngineGalleryContext)
  return context
}

export default AbundanceEngineGalleryContextProvider
