import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { IconButton } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import DeleteIcon from '@material-ui/icons/Delete'
import noop from 'lodash/noop'
import Text from '../atoms/Text'
import InputInlineEdit from './InputInlineEdit'

const useStyles = makeStyles((theme) => ({
  listBullet: {
    height: '0.4rem',
    width: '0.4rem',
    marginTop: '0.75rem',
    marginRight: '0.75rem',
    borderRadius: '100%',
    backgroundColor: theme.palette.black
  },
  textFieldContainer: ({ editMode }) => ({
    width: '100%',
    overflowWrap: 'anywhere',
    ...(editMode ? {
      borderBottom: '1px dashed #dddddd',
      marginRight: '1rem'
    } : {
      marginBottom: '0.25rem',
      paddingTop: '0.25rem'
    })
  }),
  textFieldContent: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row'
  },
  inlineEditTextField: {
    margin: 0,
    padding: 0,
    '& input': {
      minHeight: '2rem',
      fontSize: '0.875rem'
    }
  },
  inlineEditTextAdornmentClassName: {
    height: 'initial',
    maxHeight: 'unset'
  },
  entryItemContainer: {
    display: 'flex',
    flexDirection: 'row'
  },
  icon: {
    fontSize: '1rem !important'
  }
}))

const WealthJourneyCategoryEntry = ({
  entry,
  onAddEntry,
  editModeOn,
  onEditEntry,
  onDeletePlaceHolderNewEntry,
  onDeleteEntry: onCustomDeleteEntry
}) => {
  const classes = useStyles({ editMode: editModeOn })
  const [entryText, setEntryText] = useState(entry?.entryText || '')
  const [editInlineMode, setEditInlineMode] = useState(false)

  const onChangeEntryText = useCallback((e) => {
    const normalizedValue = e.target.value
    setEntryText(normalizedValue)
  }, [])

  const onEditConfirm = useCallback(async (value) => {
    const normalizedValue = value.trim()
    if (entry?.isNewEntry) {
      onAddEntry(normalizedValue)
    } else {
      onEditEntry({ entryId: entry.entryId, entryText: normalizedValue })
    }
  }, [entry.entryId, entry.isNewEntry, onEditEntry, onAddEntry])

  const onDeleteEntry = useCallback(() =>
    onCustomDeleteEntry(entry.entryId),
  [entry.entryId, onCustomDeleteEntry])

  return (
    <div className={classes.entryItemContainer}>
      {!editModeOn && <div className={classes.listBullet} />}
      <div className={classes.textFieldContainer}>
        {editModeOn ? (
          <div className={classes.textFieldContent}>
            <InputInlineEdit
              width='100%'
              autoFocus
              multiline
              showEditIcon
              disableUnderline
              alwaysShowEditButton
              value={entryText}
              initInEditMode={entry?.isNewEntry}
              onIconClickHandler={onEditConfirm}
              onChangeHandler={onChangeEntryText}
              onToggleEditMode={setEditInlineMode}
              onCancelIconClickHandler={onDeletePlaceHolderNewEntry}
              textFieldClassName={classes.inlineEditTextField}
              textFieldAdornmentClassName={classes.inlineEditTextAdornmentClassName}
            />
            {!editInlineMode && (
              <IconButton onClick={onDeleteEntry}>
                <DeleteIcon className={classes.icon} />
              </IconButton>
            )}
          </div>
        ) : <Text customFontSize='1rem' text={entry.entryText} />}
      </div>
    </div>
  )
}

WealthJourneyCategoryEntry.propTypes = {
  entry: PropTypes.shape({
    entryId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    entryText: PropTypes.string,
    isNewEntry: PropTypes.bool
  }),
  editModeOn: PropTypes.bool,
  onDeleteEntry: PropTypes.func,
  onAddEntry: PropTypes.func,
  onEditEntry: PropTypes.func,
  onDeletePlaceHolderNewEntry: PropTypes.func

}

WealthJourneyCategoryEntry.defaultProps = {
  editModeOn: false,
  onDeleteEntry: noop,
  onAddEntry: noop,
  onEditEntry: noop,
  onDeletePlaceHolderNewEntry: noop
}

export default WealthJourneyCategoryEntry
