import React, { useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { ContextContainerProvider } from '../../../abundanceEngine/components/ContextContainer'
import { useSetShowHeaderBackButton } from '../../../redux/slices/appContext'
import { CLIENTS_CHILDREN_TYPE } from '../../../constants'
import { VIEW_TYPES } from './helpers'
import ClientViewContent from './components/ClientViewContent'
import ClientViewWrapper from './components/ClientViewWrapper'

export const CLIENT_VIEW_CONTEXT = 'CLIENT_VIEW_CONTEXT'

const ClientCardsViewV2 = ({ initialState, drawer }) => {
  const setShowHeaderBackButton = useSetShowHeaderBackButton()

  useEffect(() => {
    setShowHeaderBackButton(true)
  }, [setShowHeaderBackButton])

  const contextState = useMemo(
    () => ({
      name: CLIENT_VIEW_CONTEXT,
      initialState: {
        // configuration properties
        viewType: VIEW_TYPES.BLOCKS,
        defaultHighlightedCardType: CLIENTS_CHILDREN_TYPE.recentlyViewed,
        clientTagFilters: [],
        drawerOpen: false,
        pageSize: 100,
        rowHeight: 288,
        columnWidth: 240,
        hideNoteCommentsButton: false,
        hideClientCardTagFilters: false,
        disableClientCardTagFilters: false,
        hideHighlightedExpandCollapseButton: false,
        defaultSort: [{ id: 'longName', desc: false }],
        ...initialState,
        // internal use variables
        queryText: '',
        integrationsInfo: {},
        integrations: {},
        clientBalances: {},
        clientBookmarks: {},
        clientsContainerRef: window
      }
    }),
    [initialState]
  )

  return (
    <ContextContainerProvider context={contextState}>
      <ClientViewWrapper>
        <ClientViewContent drawer={drawer} />
      </ClientViewWrapper>
    </ContextContainerProvider>
  )
}

ClientCardsViewV2.propTypes = {
  initialState: PropTypes.shape({
    viewType: PropTypes.oneOf(Object.values(VIEW_TYPES)),
    queryText: PropTypes.string,
    clientTagFilters: PropTypes.array,
    drawerOpen: PropTypes.bool,
    integrationsInfo: PropTypes.object,
    integrations: PropTypes.object,
    pageSize: PropTypes.number,
    redirectPath: PropTypes.string
  }),
  drawer: PropTypes.node
}

ClientCardsViewV2.defaultProps = {
  initialState: {},
  drawer: undefined
}

export default ClientCardsViewV2
