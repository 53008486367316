import React from 'react'
import { SvgIcon } from '@material-ui/core'
import PropTypes from 'prop-types'

const IconPropTypes = {
  fillColor: PropTypes.string
}

const IconDefaultProps = {
  fillColor: '#000000'
}

const SavingsFilledIcon = (props) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg' enableBackground='new 0 0 24 24' height='24px' viewBox='0 0 24 24'
      width='24px' fill={props.fillColor}
    >
      <rect fill='none' height='24' width='24' />
      <g>
        <path
          d='M19.83,7.5l-2.27-2.27c0.07-0.42,0.18-0.81,0.32-1.15C17.96,3.9,18,3.71,18,3.5C18,2.67,17.33,2,16.5,2 c-1.64,0-3.09,0.79-4,2l-5,0C4.46,4,2,6.46,2,9.5S4.5,21,4.5,21l5.5,0v-2h2v2l5.5,0l1.68-5.59L22,14.47V7.5H19.83z M13,9H8V7h5V9z M16,11c-0.55,0-1-0.45-1-1c0-0.55,0.45-1,1-1s1,0.45,1,1C17,10.55,16.55,11,16,11z'
        />
      </g>
    </svg>
  )
}

SavingsFilledIcon.propTypes = IconPropTypes
SavingsFilledIcon.defaultProps = IconDefaultProps

const SavingsOutlinedIcon = (props) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg' enableBackground='new 0 0 24 24' height='24px' viewBox='0 0 24 24'
      width='24px' fill={props.fillColor}
    >
      <rect fill='none' height='24' width='24' />
      <g>
        <path
          d='M15,10c0-0.55,0.45-1,1-1s1,0.45,1,1c0,0.55-0.45,1-1,1S15,10.55,15,10z M8,9h5V7H8V9z M22,7.5v6.97l-2.82,0.94L17.5,21 L12,21v-2h-2v2l-5.5,0C4.5,21,2,12.54,2,9.5S4.46,4,7.5,4l5,0c0.91-1.21,2.36-2,4-2C17.33,2,18,2.67,18,3.5 c0,0.21-0.04,0.4-0.12,0.58c-0.14,0.34-0.26,0.73-0.32,1.15l2.27,2.27H22z M20,9.5h-1L15.5,6c0-0.65,0.09-1.29,0.26-1.91 C14.79,4.34,14,5.06,13.67,6L7.5,6C5.57,6,4,7.57,4,9.5c0,1.88,1.22,6.65,2.01,9.5L8,19v-2h6v2l2.01,0l1.55-5.15L20,13.03V9.5z'
        />
      </g>
    </svg>
  )
}

SavingsOutlinedIcon.propTypes = IconPropTypes
SavingsOutlinedIcon.defaultProps = IconDefaultProps

const SavingsIcon = (props) => {
  const {
    fillColor,
    ...rest
  } = props

  const Icon = props.variant === 'filled' ? SavingsFilledIcon : SavingsOutlinedIcon

  return (
    <SvgIcon {...rest}>
      <Icon fillColor={fillColor} />
    </SvgIcon>
  )
}

SavingsIcon.propTypes = {
  variant: PropTypes.oneOf(['filled', 'outlined']),
  fillColor: PropTypes.string
}

SavingsIcon.defaultProps = IconDefaultProps

export default SavingsIcon
