import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import DateSingleEdit from './QuickEditDateSingle'
import QuickEditDateRange from './QuickEditDateRange'
import QuickEditSelect from './QuickEditSelect'

const dateRangeOptions = [{
  label: 'DATE RANGE',
  value: 'ANNUALLY'
}, {
  label: 'ON DATE',
  value: 'ONETIME'
}]

const DatesEdit = ({ classes, values, range, onChange, defaultIsEditable = false }) => {
  const onDateRangeChange = useCallback((frequency) => {
    onChange({ frequency })
  }, [onChange])

  return (
    <>

      <QuickEditSelect
        onChange={(e) => onDateRangeChange(e.target.value)}
        options={dateRangeOptions}
        value={values.frequency}
      />

      {values.frequency === 'ONETIME' ? (
        <DateSingleEdit
          classes={classes}
          initialValues={{
            startDate: values.startDate,
            endDate: values.endDate
          }}
          onChange={onChange}
          range={range}
          defaultIsEditable={defaultIsEditable}
        />
      ) : (
        <QuickEditDateRange
          classes={classes}
          initialValues={{
            startDate: values.startDate,
            endDate: values.endDate
          }}
          onChange={onChange}
          range={range}
          defaultIsEditable={defaultIsEditable}
        />
      )}
    </>

  )
}

DatesEdit.propTypes = {
  classes: PropTypes.object,
  values: PropTypes.object,
  range: PropTypes.objectOf(PropTypes.shape({
    min: PropTypes.number,
    max: PropTypes.number
  })),
  onChange: PropTypes.func,
  defaultIsEditable: PropTypes.bool
}

export default DatesEdit
