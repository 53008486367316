import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  dataHomeHeader: {
    padding: '.5rem',
    ...theme.h2
  }
}))

const DataHomeHeader = ({ children }) => {
  const classes = useStyles()

  return (
    <h2 className={classes.tagGroupHeader}>{children}</h2>
  )
}

DataHomeHeader.propTypes = {
  children: PropTypes.node
}

export default DataHomeHeader
