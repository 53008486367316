import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import ClientAvatar from '../../../../../atoms/ClientAvatar'
import Text from '../../../../../atoms/Text'
import TaskCardContainer from './Container'
import CardTasksTable from './CardTasksTable'

const useStyles = makeStyles(() => ({
  header: {
    width: '100%',
    display: 'flex',
    gap: '1rem',
    alignItems: 'center',
    flexDirection: 'row',
    padding: '0.625rem 1rem',
    '& .__details': {
      marginLeft: 'auto'
    },
    '& .__column': {
      display: 'flex',
      flexDirection: 'column'
    }
  }
}))

const TaskCard = ({ tasks, client, taskCount }) => {
  const classes = useStyles()
  return (
    <TaskCardContainer
      header={
        <div className={classes.header}>
          <ClientAvatar client={client} />
          <div className='__column'>
            <Text text={client?.shortName} customFontWeight='bold' />
            <span>
              <strong>{taskCount}</strong> Total tasks
            </span>
          </div>
          <div className='__details'>
            <span>
              <strong>{tasks.length}</strong> Tasks
            </span>
          </div>
        </div>
      }
    >
      <CardTasksTable client={client} tasks={tasks} />
    </TaskCardContainer>
  )
}

TaskCard.propTypes = {
  client: PropTypes.object,
  tasks: PropTypes.array,
  taskCount: PropTypes.number
}

export default TaskCard
