import React, { useCallback, useImperativeHandle, useState } from 'react'
import RoundedModal from '../../../../../../molecules/RoundedModal'
import EditBillingStartDateForm from './form'

const EditBillingStartDateDialog = React.forwardRef(function EditBillingStartDateDialog (_, ref) {
  const [dialogState, setDialogState] = useState({ open: false, processing: false })
  const close = useCallback(() => {
    setDialogState({ open: false, processing: false })
  }, [setDialogState])

  useImperativeHandle(ref, () => ({
    open: (account) => setDialogState({ open: true, processing: false, account })
  }), [setDialogState])

  return (
    <RoundedModal
      title='Edit Billing Start Date'
      open={dialogState.open}
      onClose={close}
      position='top'
      disablePortal={false}
    >
      <div>
        {dialogState.open ? (<EditBillingStartDateForm account={dialogState.account} onComplete={close} onCancel={close} />) : null}
      </div>
    </RoundedModal>
  )
})

EditBillingStartDateDialog.propTypes = {
}

export default EditBillingStartDateDialog
