import React from 'react'
import { Box, Container, makeStyles } from '@material-ui/core'
import { Link } from 'react-router-dom'
import OperationalTable, { useOperationalTable } from '../../../organisms/OperationalTable'
import { defaultRolesColumnConfig } from '../Advisors/helpers'
import { useSearchRolesData } from '../Advisors/hooks'
import { ADMIN_ROUTES, USER_TYPE } from '../../../../constants'
import { useCheckPolicy } from '../../../../hooks'
import EmptySection from '../../../atoms/EmptySection'
import { ROLES } from '../../../../policies/admin'

const useStyles = makeStyles((theme) => ({
  headspace: {
    paddingTop: '1rem',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  container: {
    padding: '1rem',
    height: '100%',
    [theme.breakpoints.down('xs')]: {
      padding: '1.75rem'
    }
  },
  pillBtn: {
    borderRadius: 25,
    padding: '8px 16px',
    textTransform: 'none',
    fontWeight: 'bold',
    fontSize: '0.75rem'
  },
  pillSecondary: {
    backgroundColor: theme.palette.summitBlue,
    color: theme.palette.white,
    '&:hover': {
      backgroundColor: theme.palette.summitBlue
    },
    '&:disabled': {
      backgroundColor: theme.palette.romanSilver,
      color: theme.palette.white
    }
  },
  button: {
    borderRadius: '30px',
    fontWeight: 800
  },
  link: {
    textDecoration: 'none !important'
  }
}))

function RolesWealthOwnerView () {
  const canViewWealthOwnerRoles = useCheckPolicy(ROLES.viewWealthOwnerRoles)

  const {
    defaultPageSize,
    pageIndex,
    pageSize,
    sort,
    searchText,
    onPagingChange,
    onSortingChange,
    onSearchTextChange,
    onTableModeChange,
    tableMode
  } = useOperationalTable(defaultRolesColumnConfig.defaultSort)
  const classes = useStyles()

  const { data = [], loading, count = 0 } = useSearchRolesData({
    pageIndex,
    pageSize,
    sort,
    searchText,
    tableMode,
    assignableToUserType: USER_TYPE.WEALTH_OWNER
  })

  const columnConfig = {
    columns: [
      {
        Header: 'Role Name',
        accessor: 'name',
        id: 'name',
        Cell: (data) => {
          const { row: { original: { roleId, name } } } = data
          return (
            <Link
              onClick={(event) => event.stopPropagation()}
              to={`${ADMIN_ROUTES.WEALTH_OWNERS}/roles/${roleId}`}
            >
              {name}
            </Link>
          )
        }
      },
      { Header: 'Description', accessor: 'description', id: 'description' },
      { Header: 'No. of Users', accessor: 'assignedUserCount', id: 'assignedUserCount', disableSortBy: true }
    ],
    defaultSort: [
      { id: 'name', desc: false }
    ]
  }

  if (!canViewWealthOwnerRoles) {
    return (
      <EmptySection
        title='Access Denied'
        description='You do not have sufficient permissions to access this resource'
      />
    )
  }

  return (
    <Container maxWidth={false} className={classes.container}>
      <Box className={classes.tabsContainer}>
        <OperationalTable.Wrapper>
          <OperationalTable.SuperHeader className={classes.headspace}>
            <OperationalTable.Search
              onChange={onSearchTextChange}
              placeholder='Search by role name'
              delay={250}
            />
          </OperationalTable.SuperHeader>
          <OperationalTable
            mode='server'
            columns={columnConfig.columns}
            data={data}
            defaultPageSize={defaultPageSize}
            defaultSort={columnConfig.defaultSort}
            itemName='Roles'
            loading={loading}
            total={count}
            searchText={searchText}
            onSortingChange={onSortingChange}
            onPagingChange={onPagingChange}
            onTableModeChange={onTableModeChange}
          />
        </OperationalTable.Wrapper>
      </Box>
    </Container>
  )
}

export default RolesWealthOwnerView
