import React, { useRef } from 'react'
import { useSyncExternalStore } from 'use-sync-external-store/shim'
import PropTypes from 'prop-types'

const createSizeStore = (ancestor, selfElement) => {
  let size = {
    ancestor: ancestor ? ancestor.getBoundingClientRect() : { width: 0, height: 0 },
    self: selfElement ? selfElement.getBoundingClientRect() : { width: 0, height: 0 },
    remainingSpace: 0
  }
  const listeners = new Set()

  const updateSize = () => {
    if (ancestor && selfElement) {
      const ancestorRect = ancestor.getBoundingClientRect()
      const selfRect = selfElement.getBoundingClientRect()

      size = {
        ancestor: ancestorRect,
        self: selfRect,
        remainingSpace: ancestorRect.bottom - selfRect.top
      }

      listeners.forEach(listener => listener())
    }
  }

  const subscribe = (listener) => {
    if (!ancestor || !selfElement) {
      console.warn('Ancestor or self element not found. Ensure both elements exist.')
      return () => {}
    }

    listeners.add(listener)
    const observer = new ResizeObserver(updateSize)
    observer.observe(ancestor)
    observer.observe(selfElement)

    return () => {
      listeners.delete(listener)
      observer.disconnect()
    }
  }

  const getSizeSnapshot = () => size

  return { subscribe, getSizeSnapshot }
}

export const useAncestorSize = (ancestorClass = 'size-container') => {
  const elementRef = useRef(null)
  const ancestor = elementRef.current?.closest(`.${ancestorClass}`)

  const sizeStore = React.useMemo(
    () => createSizeStore(ancestor, elementRef.current),
    [ancestor]
  )

  const size = useSyncExternalStore(
    sizeStore.subscribe,
    sizeStore.getSizeSnapshot
  )

  return { size, elementRef }
}

const SizeAwareComponent = ({ ancestorClass }) => {
  const { size, elementRef } = useAncestorSize(ancestorClass)

  return (
    <div ref={elementRef}>
      <p>Ancestor Width: {size.ancestor.width}px</p>
      <p>Ancestor Height: {size.ancestor.height}px</p>
      <p>Self Width: {size.self.width}px</p>
      <p>Self Top: {size.self.top}px</p>
      <p>Self Height: {size.self.height}px</p>
      <p>Remaining Space Between Ancestor Bottom and Self Top: {size.remainingSpace}px</p>
    </div>
  )
}

SizeAwareComponent.propTypes = {
  ancestorClass: PropTypes.string
}

export default SizeAwareComponent
