import React, { useCallback, useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory, useParams } from 'react-router-dom'
import { Button } from '@material-ui/core'
import { useIngestionJobByIdIncludeMappings, useIngestionStartJobMutation } from '../../../../api/ingestion'
import StepItem from './StepItem'
import StatusBadge from './StatusBadge'
import ObjectMappingDetails from './ObjectMappingDetails'

const useStyles = makeStyles((theme) => ({
  jobDetailsIndex: {
    padding: '1rem',
    '& > header': {
      borderBottom: '1px solid black',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center'
    },
    '& .__description': {
      padding: '.5rem 0',
      color: theme.palette.darkGrey
    }
  },
  stepsAndMappings: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    '& .__left, & .__right': {
      flex: '1 0',
      flexShrink: 0,
      width: '50%',
      paddingRight: '1rem'
    },
    '& .__sticky': {
      position: 'sticky',
      top: 0
    }
  }
}))

const JobDetailsIndex = () => {
  const classes = useStyles()
  const { jobId } = useParams()
  const { data: job, isLoading } = useIngestionJobByIdIncludeMappings(jobId)
  const [selectedStep, setSelectedStep] = useState()
  useEffect(() => {
    setSelectedStep(null)
  }, [jobId])
  useEffect(() => {
    if (job?.steps?.length > 0) {
      setSelectedStep(job.steps.at(0))
    }
  }, [jobId, job])
  const { mutateAsync: startJobAsync, isLoading: isSubmitting } = useIngestionStartJobMutation()
  const history = useHistory()
  const startJob = useCallback(async (e) => {
    e.preventDefault()
    e.stopPropagation()

    const result = await startJobAsync({ ingestionJobId: jobId })

    console.debug(result, 'startJobAsync')
    history.push(`/data-home/jobs/${jobId}/batch/${result.ingestionBatchId}`)
  }, [startJobAsync, jobId, history])

  if (isLoading) {
    return (
      <div className={classes.jobDetailsIndex}>Loading...</div>
    )
  }

  if (isSubmitting) {
    return (
      <div className={classes.jobDetailsIndex}>Starting Job...</div>
    )
  }

  return (
    <div className={classes.jobDetailsIndex}>
      <header>
        <div className='__left'>
          <h2>
            <span>{job.name}</span>
            <StatusBadge isActive={job.active} />
          </h2>
          <div className='__description'>
            <textarea readOnly value={job.description} />
          </div>
        </div>
        <div className='__right'>
          <Button color='primary' variant='contained' onClick={startJob}>Start Job</Button>
        </div>
      </header>
      <main className={classes.stepsAndMappings}>
        <div className='__left'>
          <h3>Steps</h3>
          <div className='__sticky'>
            {job.steps.map((s) => (
              <StepItem
                key={s.ingestionStepId}
                step={s}
                onSelect={setSelectedStep}
                selectedStep={selectedStep}
              />
            ))}
          </div>
        </div>
        <div className='__right'>
          <h3>Step Details</h3>
          <ObjectMappingDetails objectMappingId={selectedStep?.objectMappingId} />
        </div>
      </main>
    </div>
  )
}

export default JobDetailsIndex
