import React, { createContext, useCallback, useContext, useMemo, useState } from 'react'
import PropTypes from 'prop-types'

export const ManagerContext = createContext({ })

export const useManagerContext = () => {
  return useContext(ManagerContext)
}

export const useSectionEditing = (sectionName) => {
  const { editing, section } = useManagerContext()
  return useMemo(() => {
    return editing && section === sectionName
  }, [editing, section, sectionName])
}

const useProviderValue = ({ manager, isFetching }) => {
  const [formState, setFormState] = useState({
    editing: false,
    section: null
  })

  const editSection = useCallback(({
    section
  }) => {
    setFormState((prevState) => ({
      ...prevState,
      editing: !prevState.editing,
      section: !prevState.editing ? section : null
    }))
  }, [setFormState])

  const cancelEdit = useCallback(() => {
    setFormState((prevState) => ({
      ...prevState,
      editing: false,
      section: null
    }))
  }, [setFormState])

  return useMemo(() => ({
    manager,
    editSection,
    cancelEdit,
    isFetching,
    ...formState
  }), [manager, editSection, cancelEdit, formState, isFetching])
}

export const ManagerProvider = ({ manager, isFetching, children }) => {
  const value = useProviderValue({
    manager,
    isFetching
  })

  return (
    <ManagerContext.Provider value={value}>
      {children}
    </ManagerContext.Provider>
  )
}

ManagerProvider.propTypes = {
  manager: PropTypes.object,
  children: PropTypes.node,
  isFetching: PropTypes.bool
}

ManagerProvider.defaultProps = {
}
