import { passShouldNotContainUser } from '../utils/passwordHelpers'
import usePasswordErrors from './usePasswordErrors'

export default function useValidatePassword (keys) {
  const [passErrors, setPassErrors] = usePasswordErrors(keys)

  const validatePassword = (password, username) => {
    const invalidLength = password.length < 8 || password.length > 30
    const invalidLower = !/^(?=.*?[a-z]).+$/.test(password)
    const invalidUpper = !/^(?=.*?[A-Z]).+$/.test(password)
    const invalidDigit = !/\d/.test(password)
    const invalidPass = passShouldNotContainUser(username, password)

    setPassErrors('stringLength', invalidLength)
    setPassErrors('lowercase', invalidLower)
    setPassErrors('uppercase', invalidUpper)
    setPassErrors('digit', invalidDigit)
    setPassErrors('invalidPassword', invalidPass)

    const isInvalid = invalidLength || invalidLower || invalidUpper || invalidDigit || invalidPass

    return isInvalid
  }

  return [passErrors, validatePassword]
}
