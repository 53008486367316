import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import groupBy from 'lodash/groupBy'
import { Grid } from '@material-ui/core'
import OwnerTasks from './OwnerTasks'

const GroupByOwner = ({ data, take, sort, sortDir, linkBaseUrl, displayType, groupLimit, wrap }) => {
  const grouped = useMemo(() => {
    if (!data.length) return []
    const owners = Object.entries(groupBy(data, 'ownerId')).map(([key, value]) => {
      return {
        ownerId: key,
        taskCount: value.length,
        hasMoreTasks: value.length > take,
        firstName: value.length ? value[0].ownerFirstName : null,
        lastName: value.length ? value[0].ownerLastName : null,
        lastInitial: value.length ? value[0].ownerLastName?.substring(0, 1) : null,
        photoUrl: value.length ? value[0].photoUrl : null,
        tasks: [...value].slice(0, take).map(t => ({
          ...t,
          url: linkBaseUrl ? `${linkBaseUrl}/${t.taskId}/view` : null
        }))
      }
    })
    owners.sort((a, b) => a[sort] < b[sort] ? -1 * sortDir : sortDir)

    return owners.slice(0, groupLimit)
  }, [data, take, linkBaseUrl, sort, sortDir, groupLimit])

  if (data.length === 0) return null

  return (
    <Grid style={{ display: 'flex', flexWrap: wrap, overflow: 'scroll' }}>
      {grouped.map(owner => (
        <OwnerTasks key={owner.ownerId} owner={owner} take={take} type={displayType} />
      ))}
    </Grid>
  )
}

GroupByOwner.propTypes = {
  data: PropTypes.array,
  take: PropTypes.number,
  linkBaseUrl: PropTypes.string,
  displayType: PropTypes.oneOf(['summary', 'details']),
  sort: PropTypes.oneOf(['taskCount', 'name', 'ownerId']),
  sortDir: PropTypes.oneOf([1, -1]),
  groupLimit: PropTypes.number,
  wrap: PropTypes.string
}

GroupByOwner.defaultProps = {
  take: 10,
  linkBaseUrl: null,
  displayType: 'details',
  sort: 'taskCount',
  sortDir: -1,
  groupLimit: 1000000,
  wrap: 'wrap'
}

export default GroupByOwner
