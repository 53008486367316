import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useAppContext } from '../../../../redux/slices/appContext'
import { DenaliFilterContextProvider } from './DenaliFilterContextProvider'

export function ClientFilter ({ as, children }) {
  const { clientId } = useAppContext()

  const _value = useMemo(() => {
    return {
      filter: {
        [as]: clientId
      }
    }
  }, [clientId, as])

  return (
    <DenaliFilterContextProvider value={_value}>
      {children}
    </DenaliFilterContextProvider>
  )
}

ClientFilter.propTypes = {
  as: PropTypes.string,
  children: PropTypes.node
}

ClientFilter.defaultProps = {
  as: 'clientId'
}
