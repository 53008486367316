import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import { animated, useSpring, easings } from '@react-spring/web'
import ActionButtons from './ActionButtons'

const useStyles = makeStyles((theme) => ({
  frame: ({ hidden }) => hidden
    ? {
      background: 'transparent',
      padding: theme.layout.padding.loose,
      width: '100%',
      minHeight: `calc(100% - ${theme.layout.padding.loose})`,
      transition: 'width 2s ease-in-out',
      overflowX: 'visible',
      overflowY: 'hidden',
      marginBottom: theme.layout.margin.thick
    }
    : {
      background: theme.palette.background.default,
      padding: theme.layout.padding.loose,
      width: 'calc(100% - 0px)',
      // maxWidth: `calc(100% - ${theme.layout.padding.thick})`,
      borderRadius: theme.layout.radius.loose,
      outline: `2px solid ${theme.palette.gray.dark}`,
      boxShadow: theme.layout.shadow.normal,
      minHeight: `calc(100% - ${theme.layout.padding.loose})`,
      transition: 'width 2s ease-in-out',
      overflowX: 'hidden',
      marginBottom: theme.layout.margin.thick
    }
}))

function Frame ({ children, hidden }) {
  const classes = useStyles({ hidden })
  const springs = useSpring({
    config: {
      duration: 250,
      easing: easings.easeInOutSine
    },
    from: {
      opacity: 0,
      transform: 'translateY(5%) scale(0.8)',
      filter: 'blur(100px)'
    },
    to: {
      opacity: 1,
      transform: 'translateY(0) scale(1)',
      filter: 'blur(0)'
    }
  })

  return (
    <animated.div className={classes.frame} style={springs}>
      <ActionButtons framed={hidden} />
      {children}
    </animated.div>
  )
}

Frame.propTypes = {
  children: PropTypes.node,
  hidden: PropTypes.bool
}

Frame.defaultProps = {
  hidden: false
}

export default Frame
