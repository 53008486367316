import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  headerText: {
    fontSize: theme.typography.fontSizes.h4
  },
  subText: {
    fontStyle: 'italic',
    fontWeight: theme.typography.weights.light,
    fontSize: theme.typography.fontSizes.md
  },
  sectionHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: ({ split }) => split ? '100%' : '',
    justifyContent: ({ split }) => split ? 'space-between' : undefined,
    gap: theme.layout.gap.loose,
    margin: ({ slim }) => slim ? '0' : '15px 0'
  },
  splitContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: theme.layout.gap.loose
  }
}))

function SectionHeader ({ children, text, subText, slim, split }) {
  const classes = useStyles({ slim, split })

  return (
    <div className={classes.sectionHeader}>
      <div>
        <div className={classes.headerText}>{text}</div>
        {subText ? (<div className={classes.subText}>{subText}</div>) : null}
      </div>
      {split ? (
        <div className={classes.splitContainer}>
          {children}
        </div>
      ) : (
        <>{children}</>
      )}
    </div>
  )
}

SectionHeader.propTypes = {
  text: PropTypes.string,
  children: PropTypes.node,
  subText: PropTypes.string,
  slim: PropTypes.bool,
  split: PropTypes.bool
}

export default SectionHeader
