import React, { useCallback, useMemo, useState } from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { noop } from 'lodash'
import { Controller, useForm } from 'react-hook-form'
import { Button, ClickAwayListener, Grid, InputAdornment, TextField, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import PersonOutlineIcon from '@material-ui/icons/PersonOutline'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import Modal from '../../../../molecules/Modal'
import Icon from '../../../../atoms/Icon'
import { ICON_NAMES, REDTAIL_INTEGRATION } from '../../../../../constants'
import { useToggle } from '../../../../../hooks'
import { integrations } from '../../../../../service'

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '1.5rem',
    margin: 0,
    height: '50%',
    width: '470px',
    borderRadius: '5px'
  },
  header: {
    marginBottom: '1.25rem'
  },
  content: {
    flexGrow: 1
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    alignItems: 'center'
  },
  icon: {
    padding: 0,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    marginBottom: theme.spacing(3),
    background: 'unset',
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      marginBottom: theme.spacing(2)
    },
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(1)
    }
  },
  largeIcon: {
    padding: `${theme.spacing(3)} 0`,
    marginBottom: theme.spacing(5)
  },
  textField: {
    width: '90%',
    backgroundColor: theme.palette.gray.main
  },
  field: {
    width: '90%',
    backgroundColor: theme.palette.gray.main,
    border: '1px solid transparent',
    marginBottom: '1.5rem'
  },
  inputBase: {
    borderRadius: '0px',
    border: 'none',
    padding: '0.75rem',
    fontSize: '1rem',
    fontWeight: 600
  },
  input: {
    padding: '10px 0 7px',
    '&::placeholder': {
      fontSize: '1rem',
      textOverflow: 'ellipsis !important',
      color: theme.palette.common.black,
      opacity: '60%',
      fontWeight: 600
    }
  },
  inputIcon: {
    marginRight: '6px'
  },
  authButton: {
    width: '90%',
    margin: theme.spacing(3, 0, 2),
    borderRadius: '30px',
    border: 'solid 3px transparent',
    backgroundColor: theme.palette.cloudBurst,
    color: `${theme.palette.gray.A500} !important`,
    '&:hover': {
      backgroundColor: `${theme.palette.mirage} !important`,
      color: `${theme.palette.getContrastText(
        theme.palette.cloudBurst
      )} !important`
    },
    padding: '10px 0',
    textTransform: 'capitalize',
    fontSize: '1.2rem',
    fontWeight: 600
  },
  disabled: {
    backgroundColor: 'transparent !important',
    border: `solid 3px ${theme.palette.lighterGrey} !important`,
    color: `${theme.palette.gray.A500} !important`
  },
  fieldError: {
    border: `1px solid ${theme.palette.error.main}`
  }
}))

const RedtailAuthModal = ({
  isModalOpen,
  isAuthorized,
  onCloseHandler,
  onClickAway,
  onSuccessAuthorization
}) => {
  const classes = useStyles()
  const [loading, , setLoadingOn, setLoadingOff] = useToggle()
  const [hasError, setHasError] = useState(null)

  const {
    handleSubmit,
    control,
    formState: { isSubmitting, isDirty, isValid, errors }
  } = useForm({
    mode: 'onChange',
    defaultValues: { email: '', password: '' }
  })

  const getInputProps = ({ classes, adornmentIcon: AdornmentIcon }) => ({
    startAdornment: (
      <InputAdornment position='start' className={classes.inputIcon}>
        <AdornmentIcon fontSize='large' />
      </InputAdornment>
    ),
    className: classes.inputBase,
    classes: {
      input: classes.input
    },
    disableUnderline: true
  })

  const iconProps = useMemo(() => ({
    additionalClasses: clsx(classes.largeIcon, classes.icon),
    roundedBackground: 'unset',
    customSize: '300px',
    customHeight: '100px',
    margin: '1px !important'
  }), [classes.icon, classes.largeIcon])

  const onSubmitHandler = useCallback(
    async ({
      rtUsername: username,
      rtPassword: password
    }) => {
      try {
        setLoadingOn()
        await integrations[REDTAIL_INTEGRATION].authorize(username, password)
        onClickAway()
        onSuccessAuthorization()
      } catch (err) {
        setHasError(true)
      } finally {
        setLoadingOff()
      }
    },
    [setLoadingOn, onClickAway, onSuccessAuthorization, setLoadingOff]
  )

  return (
    <ClickAwayListener onClickAway={onClickAway}>
      <Modal
        open={isModalOpen}
        containerClassName={classes.container}
        onClose={onCloseHandler}
      >
        <Grid container justifyContent='center'>
          <Icon name={ICON_NAMES.redtail} {...iconProps} />
          <form
            onSubmit={handleSubmit(onSubmitHandler)}
            className={classes.form}
            name='redtail-form'
            id='redtailForm'
          >
            <div
              className={clsx(classes.field, {
                [classes.fieldError]: errors.rtUsername
              })}
            >
              <Controller
                name='rtUsername'
                render={({ field: { onChange } }) => (
                  <TextField
                    fullWidth
                    autoFocus
                    color='secondary'
                    autoComplete='username'
                    id='redtailUsername'
                    placeholder='Username'
                    error={Boolean(errors.rtUsername)}
                    onChange={onChange}
                    InputProps={getInputProps({
                      classes,
                      adornmentIcon: PersonOutlineIcon
                    })}
                  />)}
                control={control}
              />
            </div>
            <div
              className={clsx(classes.field, {
                [classes.fieldError]: errors.rtPassword
              })}
            >
              <Controller
                name='rtPassword'
                render={({ field: { onChange } }) => (
                  <TextField
                    fullWidth
                    type='password'
                    id='redtailPassword'
                    autoComplete='new-password'
                    placeholder='Password'
                    error={Boolean(errors.rtPassword)}
                    onChange={onChange}
                    InputProps={getInputProps({
                      classes,
                      adornmentIcon: LockOutlinedIcon
                    })}
                  />)}
                control={control}
              />
            </div>
            {hasError && (
              <Typography color='error'>
                Your username or password is incorrect.
              </Typography>
            )}
            <Grid container direction='row' justifyContent='center'>
              <Grid item xs={6} style={{ textAlign: 'center' }}>
                <Button
                  type='submit'
                  fullWidth
                  variant='outlined'
                  className={classes.authButton}
                  onClick={handleSubmit}
                  disabled={!isDirty || !isValid || loading || isSubmitting}
                  classes={{
                    disabled: classes.disabled
                  }}
                >
                  {isAuthorized ? 'Reauthorize' : 'Authorize'}
                </Button>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Modal>
    </ClickAwayListener>
  )
}

RedtailAuthModal.propTypes = {
  isModalOpen: PropTypes.bool,
  isAuthorized: PropTypes.bool,
  onCloseHandler: PropTypes.func,
  onClickAway: PropTypes.func,
  onSuccessAuthorization: PropTypes.func
}

RedtailAuthModal.defaultProps = {
  isModalOpen: false,
  isAuthorized: false,
  onCloseHandler: noop,
  onClickAway: noop,
  onSuccessAuthorization: noop
}

export default RedtailAuthModal
