import Page from './Page'
import Header from './Header'
import Footer from './Footer'
import PrimaryContent from './PrimaryContent'
import SecondaryContent from './SecondaryContent'
import TabContent from './TabContent'
import Section from './Section'
import SectionToggle from './SectionToggle'
import SectionToggleItem from './SectionToggle/SectionToggleItem'
import SectionSubtitle from './Section/SectionSubtitle'

export const AdvisorHomeLayout = {
  AH_Page: Page,
  AH_Header: Header,
  AH_Footer: Footer,
  AH_PrimaryContent: PrimaryContent,
  AH_SecondaryContent: SecondaryContent,
  AH_TabContent: TabContent,
  AH_Section: Section,
  AH_SectionToggle: SectionToggle,
  AH_SectionToggleItem: SectionToggleItem,
  AH_SectionSubtitle: SectionSubtitle
}
