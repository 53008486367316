import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import { ICON_NAMES } from '../../../../constants'
import Icon from '../../../atoms/Icon'

const useStyles = makeStyles(theme => ({
  typeIcon: {
    fill: ({ color }) => color ?? theme.palette.primary.main,
    stroke: ({ color }) => color ?? theme.palette.primary.main,
    borderRadius: '2000px',
    boxShadow: theme.layout.shadow.insetNormal
  }
}))

function TypeMarker ({ color, size }) {
  const classes = useStyles({ color })

  return (
    <Icon additionalClasses={classes.typeIcon} name={ICON_NAMES.circle} customSize={size} />
  )
}

TypeMarker.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string
}

TypeMarker.defaultProps = {
  size: 20
}

export default TypeMarker
