import React, { useCallback, useImperativeHandle, useState } from 'react'
import RoundedModal from '../../../../../molecules/RoundedModal'
import { useAccountFormContext } from '../../AccountFormProvider'
import DisplayNameForm from './DisplayNameForm'

const DisplayNameDialog = React.forwardRef(function DisplayNameDialog (_, ref) {
  const [dialogState, setDialogState] = useState({ open: false, processing: false })
  const close = useCallback(() => {
    setDialogState({ open: false, processing: false })
  }, [setDialogState])

  useImperativeHandle(ref, () => ({
    open: (item) => setDialogState({ open: true, processing: false, parent: item })
  }), [setDialogState])

  const { account } = useAccountFormContext()

  return (
    <RoundedModal
      title='Modify Display Name'
      open={dialogState.open}
      onClose={close}
      position='middle'
      disablePortal={false}
    >
      <DisplayNameForm account={account} onCancel={close} onComplete={close} />
    </RoundedModal>
  )
})

DisplayNameDialog.propTypes = {
}

export default DisplayNameDialog
