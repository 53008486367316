import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import Text from '../../../../atoms/Text'

const useStyles = makeStyles(() => ({
  section: {
    backgroundColor: 'white',
    padding: '0.5rem 1.5rem',
    borderBottom: '1.2px solid #EEE'
  },
  title: {
    padding: '0.75rem 0',
    color: '#898D9B',
    fontSize: '1rem',
    fontWeight: 'bold',
    textTransform: 'uppercase'
  },
  content: {
    padding: '1rem 0'
  }
}))

const Section = ({ title, children }) => {
  const classes = useStyles()
  return (
    <div className={classes.section}>
      <Text className={classes.title}>{title}</Text>
      <div className={classes.content}>
        {children}
      </div>
    </div>
  )
}

Section.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node
}

export default Section
