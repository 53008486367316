import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useAssetExposureSearch } from '../../../../api/coreData'
import OperationalTable, { useOperationalTable } from '../../../organisms/OperationalTable'
import { reportParamsShape } from './reportParams'
import { DATA_SET_TYPES } from './helpers'

const getExtraReportParams = ({ dataSet, minDailyStartDate, startDate, endDate }) => {
  const params = {
    heldBetween: {
      startDate: dataSet === DATA_SET_TYPES.BALANCE ? startDate : minDailyStartDate,
      endDate
    }
  }
  return params
}

const useReportResults = ({ reportParams, pageSize }) => {
  const filteredByCustodian = useMemo(() => reportParams.custodian && reportParams.custodian !== 0, [reportParams])
  const query = useMemo(() => {
    return {
      levelType: reportParams.levelType,
      exposureType: reportParams.exposureType,
      exposureTarget: [reportParams.exposureTarget],
      ...filteredByCustodian ? {
        filters: {
          custodianId: {
            op: 'in',
            value: [reportParams.custodian]
          }
        }
      } : {},
      includes: {
        balance: {
          startDate: reportParams.endDate,
          endDate: reportParams.endDate
        },
        ...(reportParams.dataSet === DATA_SET_TYPES.BALANCE_AND_PERFORMANCE
          ? {
            performance: {
              startDate: reportParams.startDate,
              endDate: reportParams.endDate
            }
          }
          : {})
      },
      ...getExtraReportParams(reportParams),
      skip: 0,
      take: pageSize
    }
  }, [reportParams, pageSize, filteredByCustodian])

  const totalsQuery = useMemo(() => {
    return {
      levelType: reportParams.levelType,
      exposureType: reportParams.exposureType,
      exposureTarget: [reportParams.exposureTarget],
      resultType: 'total',
      ...filteredByCustodian ? {
        filters: {
          custodianId: {
            op: 'in',
            value: [reportParams.custodian]
          }
        }
      } : {},
      ...getExtraReportParams(reportParams)
    }
  }, [reportParams, filteredByCustodian])

  const { data, isLoading, error } = useAssetExposureSearch(query)
  const { data: totalData } = useAssetExposureSearch(totalsQuery)
  const itemName = useMemo(() => {
    switch (reportParams.levelType) {
      case 'account':
        return 'Accounts'
      case 'client':
        return 'Clients'
      default:
        return 'Items'
    }
  }, [reportParams.levelType])

  return {
    data: data ?? [],
    total: totalData ? totalData?.total : null,
    isLoading,
    error,
    itemName
  }
}

const ReportResultsTable = ({ reportParams, defaultPageSize, columns, defaultSort }) => {
  const {
    // pageIndex,
    pageSize,
    // sort,
    onPagingChange,
    onSortingChange,
    onTableModeChange
  } = useOperationalTable({ defaultSort, defaultPageSize })

  const {
    data,
    total,
    isLoading,
    itemName
  } = useReportResults({ reportParams, pageSize })

  return (
    <OperationalTable
      mode='auto'
      columns={columns}
      data={data}
      defaultPageSize={defaultPageSize}
      defaultSort={defaultSort}
      itemName={itemName}
      loading={isLoading}
      total={total}
      onSortingChange={onSortingChange}
      onPagingChange={onPagingChange}
      onTableModeChange={onTableModeChange}
    />
  )
}

ReportResultsTable.propTypes = {
  reportParams: reportParamsShape,
  defaultPageSize: PropTypes.number,
  columns: PropTypes.array,
  defaultSort: PropTypes.array
}

ReportResultsTable.defaultProps = {
  pageSize: 100
}

ReportResultsTable.Wrapper = OperationalTable.Wrapper
ReportResultsTable.SuperHeader = OperationalTable.SuperHeader

export default ReportResultsTable
