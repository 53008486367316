import React, { useCallback } from 'react'
import { isFunction } from 'lodash'
import { makeStyles } from '@material-ui/core'
import { useAccountsContext } from '../AccountsContext'
import SelectWithCheckbox from '../../../../../molecules/Select/SelectWithCheckbox'
import { COLUMN_IDS, taxStatusOptions } from '../helpers'
import { assignmentStatusOptions } from '../../../../../../constants'

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex'
  },
  content: {
    display: 'flex',
    marginLeft: 'auto',
    gap: '1rem',
    padding: '0 1rem 0 0',
    alignItems: 'center'
  }
}))

const StaticFilters = () => {
  const classes = useStyles()

  const {
    options,
    visibleFilters,
    onChangeFilters,
    accountsFilters
  } = useAccountsContext()

  const onChangeFilterFlag = useCallback(
    (filterKey) => (values) => {
      onChangeFilters((prevState) => ({
        ...prevState,
        [filterKey]: isFunction(values)
          ? values(prevState.tagFilters)
          : values
      }))
    },
    [onChangeFilters]
  )

  if (!Object.values(COLUMN_IDS).some(val => visibleFilters.includes(val))) {
    return null
  }

  const {
    custodians,
    isLoadingCustodians,
    targetModels,
    defaultTargetModels,
    isLoadingTargetModels
  } = options

  return (
    <div display={classes.container}>
      <div className={classes.content}>
        {visibleFilters.includes(COLUMN_IDS.BENCHMARKS) && (
          <SelectWithCheckbox
            placeholder='Benchmarks'
            prefixLabel='Benchmarks'
            options={assignmentStatusOptions}
            onChange={onChangeFilterFlag('benchmarksFilters')}
            selectedOptions={accountsFilters.benchmarksFilters}
          />
        )}
        {visibleFilters.includes(COLUMN_IDS.FEE_SCHEDULES) && (
          <SelectWithCheckbox
            placeholder='Fee Schedules'
            prefixLabel='Fee Schedules'
            options={assignmentStatusOptions}
            onChange={onChangeFilterFlag('feeSchedulesFilters')}
            selectedOptions={accountsFilters.feeSchedulesFilters}
          />
        )}
        {visibleFilters.includes(COLUMN_IDS.TAX_STATUS) && (
          <SelectWithCheckbox
            placeholder='Tax Status'
            prefixLabel='Tax Status'
            options={taxStatusOptions}
            onChange={onChangeFilterFlag('taxStatusFilters')}
            selectedOptions={accountsFilters.taxStatusFilters}
          />
        )}
        {visibleFilters.includes(COLUMN_IDS.CLIENTS) && (
          <SelectWithCheckbox
            placeholder='Clients'
            prefixLabel='Clients'
            options={assignmentStatusOptions}
            onChange={onChangeFilterFlag('hasClientsAssignedFilters')}
            selectedOptions={accountsFilters.hasClientsAssignedFilters}
          />
        )}
        {visibleFilters.includes(COLUMN_IDS.CLOSE_DATE) && (
          <SelectWithCheckbox
            placeholder='Close Date'
            prefixLabel='Close Date'
            options={assignmentStatusOptions}
            onChange={onChangeFilterFlag('closeDateFilters')}
            selectedOptions={accountsFilters.closeDateFilters}
          />
        )}
        {visibleFilters.includes(COLUMN_IDS.TARGET_MODELS) && (
          <SelectWithCheckbox
            placeholder='Target Models'
            prefixLabel='Target Models'
            options={targetModels}
            disabled={isLoadingTargetModels}
            defaultOptions={defaultTargetModels}
            onChange={onChangeFilterFlag('targetModelsFilters')}
            selectedOptions={accountsFilters.targetModelsFilters}
          />
        )}
        {visibleFilters.includes(COLUMN_IDS.CUSTODIANS) && (
          <SelectWithCheckbox
            placeholder='Custodians'
            prefixLabel='Custodians'
            options={custodians}
            disabled={isLoadingCustodians}
            onChange={onChangeFilterFlag('custodianFilters')}
            selectedOptions={accountsFilters.custodianFilters}
          />
        )}
      </div>
    </div>
  )
}

export default StaticFilters
