import React, { useMemo, useState, useCallback, useEffect, useRef } from 'react'
import { makeStyles, Popover } from '@material-ui/core'
import {
  initialState,
  useNoteContext,
  useSetNoteContext,
  initialBaseState
} from '../../redux/slices/noteContext'
import { useSetAppContext } from '../../redux/slices/appContext'
import { useNoteThreadContext } from '../../contexts/noteThreadContext'
import NoteThreads from './NoteThreads'
import NoteNewReply from './NoteNewReply'

const useStyles = makeStyles((theme) => ({
  popoverRoot: {
    zIndex: '1301 !important'
  },
  paper: {
    borderRadius: '0.5rem',
    maxWidth: '26rem',
    position: 'relative',
    overflowX: 'visible',
    overflowY: 'visible'
  }
}))

const NoteModal = () => {
  const classes = useStyles()
  const [popoverRef, setPopoverRef] = useState(null)
  const popoverActions = useRef(null)
  const noteContext = useNoteContext()
  const setAppContext = useSetAppContext()
  const setNoteContext = useSetNoteContext()
  const { clearNotesContext } = useNoteThreadContext()
  const {
    viewId,
    viewSections,
    multiViewSections,
    sectionId,
    modalPosition,
    openModal,
    openNewCommentModal
  } = noteContext

  useEffect(() => {
    return () => setNoteContext(initialState)
  }, [setNoteContext])

  useEffect(() => {
    const { openNewCommentModal, openModal } = noteContext
    setAppContext({
      disableOverviewKeyNavigation: openNewCommentModal || openModal
    })
    return () => setAppContext({ disableOverviewKeyNavigation: false })
  }, [noteContext, setAppContext])

  const handleOnClose = useCallback(
    () => {
      setNoteContext(initialBaseState)
      clearNotesContext()
    },
    [setNoteContext, clearNotesContext]
  )

  const renderModalContext = useMemo(() => {
    const hasThreads = Boolean(
      !sectionId
        ? multiViewSections[viewId]?.threads || 0
        : viewSections[sectionId]?.threads || 0
    )

    const shouldOpenNewCommentModal = openModal && !hasThreads
    if (openNewCommentModal || shouldOpenNewCommentModal) {
      return <NoteNewReply isThreadInitializer openModalOnSaveComment />
    }
    if (openModal) {
      return (
        <NoteThreads
          modalContainerRef={popoverRef}
          modalActions={popoverActions}
          handleOnClose={handleOnClose}
        />
      )
    }
    return null
  }, [
    viewId,
    viewSections,
    sectionId,
    openModal,
    popoverRef,
    handleOnClose,
    multiViewSections,
    openNewCommentModal
  ])

  return (
    <Popover
      keepMounted
      action={popoverActions}
      open={openModal || openNewCommentModal}
      ref={(ref) => setPopoverRef(ref)}
      anchorReference='anchorPosition'
      anchorPosition={modalPosition}
      classes={{ root: classes.popoverRoot, paper: classes.paper }}
      onClose={handleOnClose}
    >
      {renderModalContext}
    </Popover>
  )
}

export default NoteModal
