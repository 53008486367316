import React, { useCallback } from 'react'
import {
  makeStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton
} from '@material-ui/core'
import Slide from '@material-ui/core/Slide'
import PropTypes from 'prop-types'
import { FormProvider, useForm } from 'react-hook-form'
import RoundedButton from '../../../../atoms/RoundedButton'
import Text from '../../../../atoms/Text'
import { ICON_NAMES } from '../../../../../constants'
import Icon from '../../../../atoms/Icon'
import CustomizePresentationForm from '../customizePresentationForm'
import { useAbundanceEngineGalleryContext } from '../abundanceEngineGalleryContext'

const useStyles = makeStyles(() => ({
  paper: {
    position: 'absolute',
    top: '2rem',
    right: '0',
    borderRadius: '0.75rem',
    minWidth: '25rem',
    padding: '0',
    maxHeight: '90vh'
  },
  backdrop: {
    background: 'transparent'
  },
  dialogTitle: {
    backgroundColor: 'white',
    borderBottom: '1.5px solid #EEE',
    padding: '1.5rem 1.5rem 1rem 1.5rem'
  },
  title: {
    color: '#141929',
    fontSize: '1.5rem',
    fontWeight: 'bold'
  },
  header: {
    width: '100%',
    justifyContent: 'space-between',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  content: {
    padding: 0
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: 'white',
    padding: '1.75rem',
    boxShadow: '0px -4.8px 19.2px 0px rgba(27, 30, 57, 0.06)'
  }
}))

const Transition = React.forwardRef(function Transition (props, ref) {
  return <Slide direction='left' ref={ref} {...props} />
})

const CustomizeDialog = ({ open, handleClose }) => {
  const classes = useStyles()

  const { firmConfig, setFirmConfig } = useAbundanceEngineGalleryContext()

  const formMethods = useForm({
    mode: 'onChange',
    defaultValues: firmConfig
  })

  const { handleSubmit, getValues } = formMethods

  const onSubmit = useCallback(async () => {
    const values = getValues()
    setFirmConfig(values)
    handleClose()
  }, [getValues, handleClose, setFirmConfig])

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Dialog
          open={open}
          onClose={handleClose}
          TransitionComponent={Transition}
          classes={{
            paper: classes.paper
          }}
          BackdropProps={{
            classes: {
              root: classes.backdrop
            }
          }}
        >
          <DialogTitle className={classes.dialogTitle}>
            <div className={classes.header}>
              <Text className={classes.title}>Customize</Text>
              <IconButton
                aria-label='close'
                size='medium'
                className={classes.closeButton}
                onClick={handleClose}
              >
                <Icon name={ICON_NAMES.close} customSize='1.5rem' />
              </IconButton>
            </div>
          </DialogTitle>
          <DialogContent className={classes.content}>
            <CustomizePresentationForm />
          </DialogContent>
          <DialogActions className={classes.actions}>
            <RoundedButton onClick={handleClose} variant='ghost'>
              Cancel
            </RoundedButton>
            <RoundedButton primary type='submit' onClick={onSubmit}>
              Generate Demo
            </RoundedButton>
          </DialogActions>
        </Dialog>
      </form>
    </FormProvider>
  )
}

CustomizeDialog.propTypes = {
  open: PropTypes.bool,
  onSave: PropTypes.func,
  handleClose: PropTypes.func
}

export default CustomizeDialog
