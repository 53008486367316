import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { ResponsiveBar } from '@nivo/bar'
import { BasicTooltip } from '@nivo/tooltip'
import { makeStyles } from '@material-ui/core'
import { useAppContext } from '../../../redux/slices/appContext'
import { useGetGroupedCoreData } from '../../../api/coreData'
import FadeIn from '../../molecules/Transitions/FadeIn'
import { useFormattingContext } from '../FormattingProvider/FormattingContext'
import { useRelativeDateRange } from '../../molecules/RelativeDateSelect'

const useStyles = makeStyles((theme) => ({
  chart: {
    height: ({ height }) => height ? `${height}` : '600px',
    width: '100%',
    '& text': {
      fontFamily: `${theme.typography.fontFamily} !important`
    }
  }
}))

const useBaseChartData = ({ levelTypes, defaultDateRange: selectedDateRange, defaultFilter }) => {
  const { clientId, availableDates } = useAppContext()
  const { dateRange } = useRelativeDateRange(selectedDateRange, availableDates)

  const query = useMemo(() => {
    return {
      query: {
        levelFilters: {
          ...(defaultFilter || {}),
          dateType: 'ALL',
          levelTypes,
          calcType: 'performance',
          clientIds: [clientId]
        },
        dateRange: {
          startDate: dateRange.startDate,
          endDate: dateRange.endDate
        }
      },
      options: {}
    }
  }, [dateRange.startDate, dateRange.endDate, levelTypes, clientId, defaultFilter])

  const { data, isLoading } = useGetGroupedCoreData(query.query, query.options)

  return {
    data, isLoading
  }
}

function BaseBarChart ({
  keys,
  indexBy,
  colorField,
  levelTypes,
  defaultDateRange,
  chartProps,
  valueFormat,
  labelFormat,
  labels,
  height,
  defaultFilter,
  ...rest
}) {
  const { data, isLoading } = useBaseChartData({ levelTypes, defaultDateRange, defaultFilter })
  const classes = useStyles({ height })

  const { formatter } = useFormattingContext()
  const formatValue = useCallback((d) => {
    return d.value === 0 ? null : formatter(d.value, valueFormat)
  }, [formatter, valueFormat])
  const formatLeftAxis = useCallback((value) => {
    return value === 0 ? null : formatter(value, labelFormat)
  }, [formatter, labelFormat])

  if (isLoading) return <div>Chart Loading...</div>

  return (
    <FadeIn className={classes.chart}>
      <ResponsiveBar
        data={data || []}
        indexBy={indexBy}
        keys={keys}
        margin={{ top: 50, right: 130, bottom: 150, left: 100 }}
        axisLeft={{
          tickSize: 5,
          tickValues: 4,
          tickPadding: 15,
          tickRotation: 0,
          truncateTickAt: 0,
          format: formatLeftAxis
        }}
        axisBottom={{
          tickSize: 0,
          tickPadding: 10,
          tickRotation: -45,
          legendOffset: 32,
          truncateTickAt: 0
        }}
        colors={colorField ? ({ data }) => data[colorField] ?? '#99aaf0' : undefined}
        label={formatValue}
        tooltip={(d) => (
          <BasicTooltip
            id={labels[d.id]}
            enableChip
            color={d.color}
            value={formatValue(d)}
          />
        )}
        {...chartProps}
        {...rest}
      />
    </FadeIn>
  )
}

BaseBarChart.propTypes = {
  keys: PropTypes.arrayOf(PropTypes.string),
  indexBy: PropTypes.string,
  colorField: PropTypes.string,
  levelTypes: PropTypes.array,
  defaultDateRange: PropTypes.string,
  chartProps: PropTypes.object,
  labels: PropTypes.object,
  valueFormat: PropTypes.string,
  labelFormat: PropTypes.string,
  defaultFilter: PropTypes.object,
  height: PropTypes.any
}

BaseBarChart.defaultProps = {
  levelTypes: ['client', 'assetClassTag'],
  indexBy: 'assetClassTagName',
  colorField: 'assetClassTagColorField',
  defaultDateRange: 'L12M',
  chartProps: {},
  labels: {
    returnValue: 'Return'
  },
  valueFormat: '0.00%',
  labelFormat: '0.00%',
  keys: ['returnValue'],
  padding: 0.5
}

export default BaseBarChart
