import {
  AppBar,
  Button,
  CircularProgress,
  Dialog as MuiDialog,
  IconButton,
  Slide,
  Toolbar,
  Typography,
  makeStyles,
  Dialog,
  Box
} from '@material-ui/core'
import React from 'react'
import CloseIcon from '@material-ui/icons/Close'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import Text from '../../../atoms/Text'
import { MAIN_HEADER_HEIGHT, TEXT_VARIANTS } from '../../../../constants'
import AbundanceEngineComponentsTable from '../../../../abundanceEngine/AbundanceEngineComponentsTable'
import { useBoolean } from '../../../../hooks'

const Transition = React.forwardRef(function Transition (props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
    background: theme.palette.gray.dark
  },
  title: {
    marginLeft: theme.spacing(2),
    color: 'black',
    flex: 1
  },
  container: ({ isInPreviewMode }) => ({
    padding: '1rem',
    overflow: isInPreviewMode ? 'auto' : 'hidden',
    position: 'relative',
    height: `calc(100% - ${MAIN_HEADER_HEIGHT}px)`
  }),
  button: {
    textTransform: 'capitalize'
  },
  previewButton: {
    marginRight: '1rem'
  },
  closeButton: {
    position: 'absolute',
    right: '0.5rem',
    top: '0.5rem',
    zIndex: 1
  },
  dialog: {
    position: 'relative'
  }
}))

const AbundanceEngineDialog = ({
  open,
  title,
  onSave,
  loading,
  onClose,
  children,
  togglePreviewMode,
  isInPreviewMode
}) => {
  const classes = useStyles({ isInPreviewMode })
  const [showComponentsLibrary, onToggleComponentsLibrary] = useBoolean()

  return (
    <MuiDialog
      fullScreen
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
      className={classes.dialog}
    >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge='start'
            color='black'
            disabled={loading}
            onClick={onClose}
            aria-label='close'
          >
            <CloseIcon />
          </IconButton>
          <Typography variant='h6' className={classes.title}>
            {title}
          </Typography>
          {isInPreviewMode && (
            <Button
              disabled={loading}
              onClick={togglePreviewMode}
              className={classes.button}
            >
              <Text text='Go Back' variant={TEXT_VARIANTS.h3} />
            </Button>
          )}
          {!isInPreviewMode ? (
            <>
              <Button
                disabled={loading}
                onClick={onToggleComponentsLibrary.on}
                className={clsx(classes.button, classes.previewButton)}
              >
                <Text text='Components Library' variant={TEXT_VARIANTS.h3} />
              </Button>
              <Button
                disabled={loading}
                onClick={togglePreviewMode}
                className={clsx(classes.button, classes.previewButton)}
              >
                <Text text='Preview' variant={TEXT_VARIANTS.h3} />
              </Button>
              <Button
                type='submit'
                disabled={loading}
                onClick={onSave}
                className={classes.button}
              >
                {!loading && <Text text='Save' variant={TEXT_VARIANTS.h3} />}
                {loading && <CircularProgress color='primary' size={18} />}
              </Button>
            </>
          ) : null}
        </Toolbar>
      </AppBar>
      <Dialog
        fullWidth
        maxWidth='xl'
        open={showComponentsLibrary}
        onClose={onToggleComponentsLibrary.off}
        aria-labelledby='max-width-dialog-title'
      >
        <Box p={1}>
          <IconButton
            edge='start'
            color='black'
            disabled={loading}
            onClick={onToggleComponentsLibrary.off}
            aria-label='close'
            className={classes.closeButton}
          >
            <CloseIcon />
          </IconButton>
          <AbundanceEngineComponentsTable />
        </Box>
      </Dialog>
      <div className={classes.container}>{children}</div>
    </MuiDialog>
  )
}

AbundanceEngineDialog.propTypes = {
  children: PropTypes.node,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  title: PropTypes.string,
  loading: PropTypes.bool,
  togglePreviewMode: PropTypes.func,
  isInPreviewMode: PropTypes.bool
}

export default AbundanceEngineDialog
