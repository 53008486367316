import React from 'react'
import { useParams } from 'react-router-dom'
import { makeStyles } from '@material-ui/core'
import { usePolicy } from '../../../../../hooks/usePolicy'
import EmptySection from '../../../../atoms/EmptySection'
import { useTargetModelById } from '../../../../../api/rebalancer'
import { TargetModelProvider } from './TargetModelProvider'
import TargetModelHeader from './Header'
import TargetModelTabs from './TargetModelTabs'

const useStyles = makeStyles({
  page: {
    margin: '1rem'
  }
})

const useTargetModelId = () => {
  const { targetModelId } = useParams()
  return isNaN(+targetModelId) ? [-1] : [+targetModelId]
}

function TargetModelDetails () {
  const classes = useStyles()
  const [targetModelId] = useTargetModelId()
  const canView = usePolicy('admin_models_view')
  const { data: targetModel, isLoading, isFetching, error } = useTargetModelById(targetModelId)

  if (!canView) {
    return (
      <EmptySection
        title='Access Denied'
        description='You do not have sufficient permissions to access this resource'
      />
    )
  }

  if (isLoading) {
    return null
  }

  if (error) {
    return (
      <EmptySection
        title='There was an error loading this Target Model'
        description=''
      />
    )
  }

  if (!targetModel) {
    return (
      <EmptySection
        title='Target Model Not Found'
        description=''
      />
    )
  }

  return (
    <div className={classes.page}>
      <TargetModelProvider targetModel={targetModel} isFetching={isFetching}>
        <TargetModelHeader />
        <TargetModelTabs />
      </TargetModelProvider>
    </div>
  )
}

export default TargetModelDetails
