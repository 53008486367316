import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Box } from '@material-ui/core'
import Text from '../../../atoms/Text'
import PieProgressBars from '../../../molecules/PieProgressBars'
import { CALC_TYPES, LEVEL_TYPES } from '../../../../constants'
import { useAvailableDates } from '../../../../redux/slices/appContext'
import { useGetGroupedCoreData } from '../../../../api/coreData'
import { getRandomThemeColor } from '../../../../utils'
import Skeleton from '../../../atoms/Skeleton'

const mapPieChartAccountObjectives = (accountObjectives) => {
  const accountObjectivesTotalBalance = accountObjectives.reduce(
    (acc, { endingValue }) => acc + endingValue,
    0
  )
  const accountObjectivesData = accountObjectives.map(
    ({
      uniqueId: id,
      endingValue,
      ordinal,
      accountObjectiveLongName,
      color
    }) => ({
      id,
      balance: endingValue,
      ordinal,
      color: color || getRandomThemeColor(),
      title: accountObjectiveLongName,
      percentage: Math.abs((endingValue / accountObjectivesTotalBalance) * 100)
    })
  )
  return accountObjectivesData
}

const AccountObjectivesPreview = ({ advisorId, title }) => {
  const [{ mainDate }] = useAvailableDates()
  const query = useMemo(
    () => ({
      dateRange: {
        startDate: mainDate,
        endDate: mainDate
      },
      levelFilters: {
        levelTypes: [LEVEL_TYPES.ACCOUNT_OBJECTIVE],
        advisorIds: [advisorId],
        calcType: CALC_TYPES.balance
      }
    }),
    [advisorId, mainDate]
  )

  const { data, isLoading } = useGetGroupedCoreData(query)

  const chartData = useMemo(() => {
    return isLoading ? [] : mapPieChartAccountObjectives(data)
  }, [isLoading, data])

  return (
    <>
      <Box mb='1.5rem'>
        <Text
          text={title}
          variant='body2'
          customFontSize='1.5rem'
          customFontWeight='bold'
        />
      </Box>
      <Box px='2.5rem'>
        {isLoading ? (
          <Skeleton height='18.5rem' />
        ) : (
          <PieProgressBars
            pie
            showHeader={false}
            progressBarsDataSet={chartData}
          />
        )}
      </Box>
    </>
  )
}

AccountObjectivesPreview.propTypes = {
  advisorId: PropTypes.number,
  title: PropTypes.string
}

AccountObjectivesPreview.defaultProps = {
  title: 'Goals'
}

export default AccountObjectivesPreview
