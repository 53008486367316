import React from 'react'
import PropTypes from 'prop-types'
import { useTable } from 'react-table'
import { makeStyles } from '@material-ui/core'
import noop from 'lodash/noop'

const useStyles = makeStyles((theme) => ({
  cardsTable: {
    fontStyle: 'normal',
    fontWeight: '350',
    fontSize: '14px',
    lineHeight: '17px',
    width: '100%',
    textAlign: 'left',
    borderCollapse: 'separate',
    borderSpacing: '0 14px'
  },
  cardsTableHeader: {
    background: '#F7F8F9'
  },
  cardsTableRow: {
    borderRadius: '8px',
    '&:hover': {
      background: '#F7F8F9',
      cursor: 'pointer'
    },
    '& > td': {
      padding: '15.5px 0',
      borderTop: '1.5px solid #E9EAEF',
      borderBottom: '1.5px solid #E9EAEF',
      minWidth: '14%',
      maxWidth: '200px',
      verticalAlign: 'text-top',
      '&:first-child': {
        padding: '15.5px 0 15.5px 42px',
        borderLeft: '1.5px solid #E9EAEF',
        borderRadius: '8px 0 0 8px'
      },
      '&:last-child': {
        padding: '15.5px 42px 15.5px 0',
        borderRight: '1.5px solid #E9EAEF',
        borderRadius: '0 8px 8px 0'
      }
    },
    '& > th': {
      fontWeight: 'normal',
      padding: '19.5px 0',
      borderTop: '1.5px solid #F7F8F9',
      borderBottom: '1.5px solid #F7F8F9',
      '&:first-child': {
        padding: '19.5px 0 19.5px 42px',
        borderLeft: '1.5px solid #F7F8F9',
        borderRadius: '8px 0 0 8px'
      },
      '&:last-child': {
        padding: '19.5px 42px 19.5px 0',
        borderRight: '1.5px solid #F7F8F9',
        borderRadius: '0 8px 8px 0'
      }
    }
  }
}))

function CardsTable ({ columns = [], data = [], onRowClick = noop }) {
  const classes = useStyles()

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow
  } = useTable({
    columns,
    data
  })

  return (
    <table {...getTableProps()} className={classes.cardsTable}>
      <thead className={classes.cardsTableHeader}>
        {headerGroups.map((headerGroup, index) => (
          <tr {...headerGroup.getHeaderGroupProps()} key={`thead-tr-${index}`} className={classes.cardsTableRow}>
            {headerGroup.headers.map((column, idx) => (
              <th {...column.getHeaderProps()} key={`th-${idx}`}>{column.render('Header')}</th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, index) => {
          prepareRow(row)
          return (
            <tr {...row.getRowProps()} key={`tbody-tr-${index}`} className={classes.cardsTableRow} onClick={() => onRowClick(row.original)}>
              {row.cells.map((cell, idx) => {
                return <td {...cell.getCellProps()} key={`td-${idx}`}>{cell.render('Cell')}</td>
              })}
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

CardsTable.defaultProps = {
  columns: [],
  data: [],
  onRowClick: noop
}

CardsTable.propTypes = {
  columns: PropTypes.array,
  data: PropTypes.array,
  onRowClick: PropTypes.func
}

export default CardsTable
