import React, { useCallback, useRef } from 'react'
import { CircularProgress, makeStyles } from '@material-ui/core'
import dayjs from 'dayjs'
import SectionScreen from '../SectionScreen'
import { useClientDetails, useSectionEditing } from '../ClientDetailsFormContext'
import {
  useGetClientDatapointSchema,
  useGetClientDatapointValues,
  useModifyClientKeyDatapointValues
} from '../../../../../api/groups'
import SectionHeader from '../../shared/SectionHeader'
import PersonalSpace from '../../shared/PersonalSpace'
import KdpDisplay from '../../shared/KdpControls/KdpDisplay'
import FadeIn from '../../../../molecules/Transitions/FadeIn'
import EditButton from '../EditButton'

const useStyles = makeStyles((theme) => ({
  kdpSection: {
    position: 'relative',
    padding: '10px'
  },
  loading: {
    height: '300px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}))

function KdpSection () {
  const classes = useStyles()
  const { client, editSection } = useClientDetails()
  const editing = useSectionEditing('kdp')
  const { data, isLoading, isFetching } = useGetClientDatapointValues(client.clientId)
  const { data: schema, isLoading: isSchemaLoading } = useGetClientDatapointSchema()
  const formRef = useRef()
  const { mutateAsync: modifyKdps } = useModifyClientKeyDatapointValues()
  const onSave = useCallback(async (e) => {
    const onValid = async (formData) => {
      const newValues = schema.schema.filter(s => !s.hidden).map(s => {
        const value = ['', undefined].includes(formData[s.codeName]) ? null : formData[s.codeName]
        switch (s.configurationJson?.type) {
          case 'string':
          case 'text':
          case 'list':
            return { ...s, value }
          case 'integer':
            return { ...s, value: value === null ? null : Number.parseInt(value) }
          case 'fixed':
            return { ...s, value: value === null ? null : Number.parseFloat(value).toFixed(s.configurationJson.length ?? 2) }
          case 'number':
            return { ...s, value: value === null ? null : Number.parseFloat(value) }
          case 'bool':
            return { ...s, value: value === null ? null : Boolean(value) }
          case 'json':
            return { ...s, value: value === null ? null : JSON.parse(value) }
          case 'date':
            return { ...s, value: value === null ? null : dayjs(value).format('YYYY-MM-DD') }
          case 'datetime':
            return { ...s, value: value === null ? null : dayjs(value).toISOString() }
          default:
            return { ...s, value }
        }
      })
      const changed = newValues.reduce((prev, cur) => {
        if (cur.readonly) return prev
        const match = data.values.find(x => x.codeName === cur.codeName)
        if (cur.configurationJson?.type === 'json') {
          const left = match.value ? JSON.stringify(match.value) : null
          const right = match.value ? JSON.stringify(match.value) : null
          if (left !== right) {
            prev.push(cur)
          }
        } else if (match.value !== cur.value) {
          prev.push(cur)
        }
        return prev
      }, [])

      if (!changed.length) {
        console.warn('nothing to save')
        return
      }

      await modifyKdps({
        clientId: client.clientId,
        datapoints: changed
      })
    }

    const onInvalid = (errors) => {
      console.error(errors)
    }

    const handler = formRef.current.form.handleSubmit(onValid, onInvalid)
    await handler(e)
  }, [client, formRef, modifyKdps, schema, data])

  if (isLoading || isSchemaLoading) {
    return <div>Loading</div>
  }

  if (isFetching) {
    return (
      <FadeIn className={classes.loading}>
        <CircularProgress />
      </FadeIn>
    )
  }

  return (
    <SectionScreen sectionName='kdp' className={classes.kdpSection}>
      <SectionHeader text='Key Datapoint Values'>
        <div>
          <EditButton
            policy='admin_edit_key_datapoints'
            editing={editing}
            onClick={() => editSection({
              section: 'kdp',
              saveDescription: 'Save Key Datapoint Values',
              onSave
            })}
          />
        </div>
      </SectionHeader>
      <KdpDisplay
        ref={formRef}
        datapoints={data.values}
        schema={schema.schema}
        editing={editing}
      />
      <PersonalSpace />
    </SectionScreen>
  )
}

export default KdpSection
