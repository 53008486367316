import { useQuery } from '@tanstack/react-query'
import { useAppContext } from '../redux/slices/appContext'
import { postNamedQuery } from '../service'
import { QUERY_KEYS } from './queryKeys'

export const useSearchPositions = (query, options = {}) => {
  const { userId } = useAppContext()
  const { enabled = true, mapper = null } = options
  return useQuery({
    enabled,
    queryKey: [QUERY_KEYS.searchPositions, userId, query],
    queryFn: async () => {
      const { data } = await postNamedQuery('coreData', 'positions', query)
      return mapper ? mapper(data) : data
    }
  })
}
