import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core'
import Text from '../../../atoms/Text'

const useStyles = makeStyles((theme) => ({
  '@keyframes shadeyIn': {
    from: {
      boxShadow: '0px 5px 5px -2px rgba(0,0,0,0)'
    },
    to: {
      boxShadow: '0px 5px 5px -2px rgba(0,0,0,0.25)'
    }
  },
  transactionCodeTagFilter: {
    border: `1px solid ${theme.palette.gray.darker}`,
    minHeight: '3rem',
    padding: '1.5rem 2rem',
    borderRadius: '5px',
    minWidth: '10rem',
    maxWidth: '15rem',
    width: '100%',
    transition: 'border-color .25s ease-in-out, background-color .25s ease-in-out',
    animationDirection: 'reverse',
    '&:hover': {
      cursor: 'pointer',
      border: `1px solid ${theme.palette.primary.main}`,
      animation: '$shadeyIn .25s ease-in-out forwards',
      boxShadow: '0px 5px 5px -2px rgba(0,0,0,0.25)'
    },
    '&:active': {
      backgroundColor: theme.palette.gray.light,
      transition: 'background-color 0s'
    },
    '&.__selected': {
      outline: `1px solid ${theme.palette.primary.dark}`
    }
  },
  byLine: {
    marginTop: '1rem',
    fontWeight: theme.typography.weights.light
  },
  transactionCount: {
    fontWeight: theme.typography.weights.semibold,
    marginRight: '4px'
  }
}))

const TransactionCodeTagFilter = ({ info, dateRangeLabel, onClick, selectedItem }) => {
  const selectable = useMemo(() => typeof onClick === 'function', [onClick])
  const selected = useMemo(() => selectedItem?.transactionCodeTagId === info?.transactionCodeTagId, [info, selectedItem])
  const classes = useStyles({ selectable, selected })
  const clickHandler = useCallback((e) => {
    if (typeof onClick === 'function') {
      onClick(info)
      e.preventDefault()
      e.stopPropagation()
    }
  }, [info, onClick])

  const { displayName, transactionCount } = (info || {})
  return (
    <div
      className={clsx(classes.transactionCodeTagFilter, {
        __selected: selected
      })}
      onClick={clickHandler}
    >
      <div className={classes.title}>
        <Text
          text={displayName}
          lineHeight='1'
          variant='body2'
          customFontWeight='bold'
          customFontSize='1.625rem'
        />
      </div>
      <div className={classes.byLine}>
        <span className={classes.transactionCount}>{transactionCount}</span>
        <span>- {dateRangeLabel}</span>
      </div>
    </div>
  )
}

TransactionCodeTagFilter.propTypes = {
  info: PropTypes.shape({
    transactionCodeTagId: PropTypes.number,
    displayName: PropTypes.string,
    transactionCount: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  }),
  dateRangeLabel: PropTypes.string,
  selectedItem: PropTypes.shape({
    transactionCodeTagId: PropTypes.number
  }),
  onClick: PropTypes.func
}

export default TransactionCodeTagFilter
