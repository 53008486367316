import { useMemo } from 'react'
import { isEmpty } from 'lodash'
import { capitalizeFirstLetter } from '../../../../utils'
import { useFetchClientIntegrations } from './useFetchClientIntegrations'

const useClientIntegrationData = ({
  client,
  integrationType,
  integrationSources
}) => {
  const integrationsQuery = useMemo(() => {
    if (
      !client ||
      isEmpty(integrationSources) ||
      isEmpty(client?.integrations)
    ) {
      return []
    }
    const { filterField, dataSourceId } =
      integrationSources[integrationType] || {}

    const filterIntegrationValue =
      client?.integrations?.[integrationType] ||
      client?.integrations?.[capitalizeFirstLetter(integrationType)]

    return [
      {
        filters: [
          {
            field: filterField,
            op: 'eq',
            value: filterIntegrationValue
          }
        ],
        options: {
          flatten: false
        },
        resultType: 'details',
        platformDataSourceId: dataSourceId,
        integrationType
      }
    ]
  }, [client, integrationSources, integrationType])

  return useFetchClientIntegrations(integrationsQuery)
}

export default useClientIntegrationData
