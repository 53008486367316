import dayjs from 'dayjs'
import get from 'lodash/get'
import { isNumeric } from '../../../../utils'

const DEFAULT_DATE_FORMAT = 'MM-DD-YYYY'

export const replaceDoubleBraces = (str, source, fallback) => {
  const levelType = source?.levelType
  const output = str.replace(/{{(.+?)}}/g, (match, path) => {
    // filter out those templates that don't start with the specified levelType
    if (!match.includes(levelType)) return match

    // remove levelType from the template, it's not part of the actual path
    const rawPath = path.replace(`${levelType}.`, '')
    const value = get(source, rawPath, undefined)

    if (value !== undefined && !isNumeric(value) && dayjs(value).isValid()) {
      return dayjs(value).format(DEFAULT_DATE_FORMAT)
    }

    return value || match
  })
  // if the output is the same then we couldn't find anything to fullfil the template
  if (output === str) {
    return fallback || null
  }
  // if we couldn't find the variables for the template then don't show the result
  if (output.includes('{') && output.includes('}')) {
    return fallback || null
  }
  return output
}
