import React, { Children } from 'react'
import PropTypes from 'prop-types'
import { Box, Divider, makeStyles } from '@material-ui/core'
import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
  container: ({ justifyContentEvenly }) => ({
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      flexFlow: 'column wrap',
      '& > hr': {
        width: '100%',
        height: '2px',
        minHeight: '2px',
        margin: '1.5rem 0'
      }
    },
    ...(justifyContentEvenly
      ? {
        justifyContent: 'space-evenly',
        width: '100%'
      }
      : {}),
    '& .__title-container': {
      justifyContent: 'center'
    },
    '& .__description': {
      textAlign: 'center'
    }
  }),
  divider: {
    margin: '0 1.5rem',
    minHeight: '5rem',
    width: '2px'
  }
}))

const findLastIndexOfNonFragmentElement = (childrenArray) =>
  childrenArray.findLastIndex(
    (child) => !child?.type?.toString()?.includes('react.fragment')
  )

const InlineDividedMetric = ({ children, className, justifyContentEvenly }) => {
  const classes = useStyles({ justifyContentEvenly })
  const childrenArray = Children.toArray(children)
  const lastNonFragmentElementIndex =
    findLastIndexOfNonFragmentElement(childrenArray)

  return (
    <Box
      className={clsx(classes.container, { [className]: Boolean(className) })}
    >
      {childrenArray.reduce((childrenAcc, child, index) => {
        if (child?.type.toString().includes('react.fragment')) {
          return childrenAcc
        }
        return [
          childrenAcc,
          child,
          index < lastNonFragmentElementIndex && (
            <Divider orientation='vertical' className={classes.divider} />
          )
        ]
      }, [])}
    </Box>
  )
}

InlineDividedMetric.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  justifyContentEvenly: PropTypes.bool
}

InlineDividedMetric.defaultProps = {
  className: null,
  justifyContentEvenly: false
}

export default InlineDividedMetric
