import React, { useCallback, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import { useMutation } from '@tanstack/react-query'
import { CALC_TYPES } from '../../../../../constants'
import OperationalTable, { useOperationalTable } from '../../../OperationalTable'
import { useCoreDataDataset, useLevelDates } from '../../../../../api/coreData'
import RelativeDateSelect, { useRelativeDateRange } from '../../../../molecules/RelativeDateSelect'
import AsOfDate from '../../../../molecules/AsOfDate'
import { useFilterContext } from '../../../../../contexts/FilterContext'
import { fetchCustomDataset } from '../../../../../service'
import { useAbundanceEngineParameters } from '../../../../../hooks/useAbundanceEngineParameters'
import { useColumnConfig } from './columnConfig'

const useStyles = makeStyles((theme) => ({
  actions: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '& > *:first-child': {
      flex: '1 1 auto'
    },
    '& > *:not(:last-child)': {
      marginRight: '1rem'
    }
  },
  headspace: {
    paddingTop: '1rem'
  }
}))

const useFilteredLevelDates = (filter, defaultDateFilter) => {
  const { advisorId } = useAbundanceEngineParameters()
  const levelDatesQuery = useMemo(() => {
    if (!filter) {
      return {
        ...defaultDateFilter,
        ...(advisorId ? {
          levelType: 'advisor',
          advisorIds: [advisorId]
        } : {})
      }
    }
    return filter
  }, [filter, defaultDateFilter, advisorId])
  const {
    data: dates,
    isLoading: datesLoading
  } = useLevelDates(levelDatesQuery)

  return {
    dates,
    datesLoading
  }
}

const useSelectedDateRange = (defaultDateRange, availableDates) => {
  const [selectedDateRange, setSelectedDateRange] = useState(defaultDateRange)
  const { dateRange } = useRelativeDateRange(selectedDateRange, availableDates)
  return {
    selectedDateRange,
    setSelectedDateRange,
    dateRange
  }
}

const useTopClientsQuery = (dataFilter, dateRange, datesLoading) => {
  const { advisorId } = useAbundanceEngineParameters()
  const query = useMemo(() => datesLoading ? null : ({
    levelFilters: {
      V2: true,
      levelTypes: ['client'],
      advisorIds: advisorId ? [advisorId] : undefined,
      calcType: CALC_TYPES.performance,
      dateType: 'all',
      ...(dataFilter ?? {})
    },
    dateRange
  }), [datesLoading, dateRange, dataFilter, advisorId])

  const { data, isLoading } = useCoreDataDataset(query, {
    enabled: !datesLoading
  })

  const [childRows, setChildRows] = useState({})
  const mappedData = useMemo(() => (data ? data[0] : []).map(d => ({
    ...d,
    levelName: d.clientName,
    nextRequest: {
      levelFilters: {
        V2: true,
        advisorIds: advisorId ? [advisorId] : undefined,
        levelTypes: ['account'],
        clientIds: [d.clientId],
        calcType: CALC_TYPES.performance,
        dateType: 'all',
        ...(dataFilter ?? {})
      },
      dateRange
    },
    subRows: d.uniqueId in childRows ? childRows[d.uniqueId] : []
  })), [data, dateRange, childRows, dataFilter, advisorId])

  const mutation = useMutation({
    mutationFn: async (row) => {
      const { data } = await fetchCustomDataset('coreData', row.original.nextRequest)
      return data[0].map(x => ({
        ...x,
        levelName: x.accountName,
        canExpand: false,
        _next: null
      }))
    },
    onError: (error, variables, context) => {
      console.error('error loading child data', error, variables, context)
    },
    onSuccess: (data, variables) => {
      const parentId = variables.original.uniqueId
      setChildRows(prev => ({
        ...prev,
        [parentId]: data
      }))
    }
  })

  const fetchNextLevel = useCallback(row => {
    if (row.uniqueId in childRows) return

    mutation.mutate(row)
  }, [childRows, mutation])

  return useMemo(() => ({
    data: mappedData,
    isLoading,
    total: mappedData?.length,
    fetchNextLevel
  }), [mappedData, isLoading, fetchNextLevel])
}

/** This does nothing right now */
const useDataTableExtension = (extensions, data, columns) => {
  return {
    data,
    columns
  }
}

const TopClients = ({
  defaultDateFilter,
  defaultDateRange,
  stickyHeaderStyle,
  columnPreset,
  columnConfig,
  extensions
}) => {
  const classes = useStyles()
  const { columns, defaultSort } = useColumnConfig(columnPreset, columnConfig)
  const { searchText, onSearchTextChange } = useOperationalTable(defaultSort)
  const dataFilter = useFilterContext()
  const { dates, datesLoading } = useFilteredLevelDates(null, defaultDateFilter)
  const { selectedDateRange, setSelectedDateRange, dateRange } = useSelectedDateRange(defaultDateRange, dates)
  const { data, isLoading, total, fetchNextLevel } = useTopClientsQuery(dataFilter, dateRange, datesLoading)
  const { data: extendedData, columns: extendedColumns } = useDataTableExtension(extensions, data, columns)

  return (
    <OperationalTable.Wrapper>
      <OperationalTable.SuperHeader className={classes.headspace}>
        <div className={classes.actions} style={stickyHeaderStyle}>
          <div>
            <OperationalTable.Search
              onChange={onSearchTextChange}
              placeholder='Search client name'
              delay={250}
            />
          </div>
          <RelativeDateSelect
            availableDates={dates}
            selectedValue={selectedDateRange}
            onDateRangeSelected={setSelectedDateRange}
          />
          <AsOfDate date={dates?.max} />
        </div>
      </OperationalTable.SuperHeader>
      <OperationalTable
        mode='client'
        columns={extendedColumns}
        data={extendedData}
        defaultSort={defaultSort}
        itemName='Clients'
        loading={datesLoading || isLoading}
        total={total}
        searchText={searchText}
        expandable
        onRowClick={fetchNextLevel}
      />
    </OperationalTable.Wrapper>
  )
}

TopClients.propTypes = {
  defaultDateFilter: PropTypes.object,
  defaultDateRange: PropTypes.string,
  stickyHeaderStyle: PropTypes.object,
  columnPreset: PropTypes.string,
  columnConfig: PropTypes.shape({
    columns: PropTypes.array,
    defaultSort: PropTypes.array
  }),
  extensions: PropTypes.object
}

TopClients.defaultProps = {
  defaultDateFilter: { levelType: 'user' },
  defaultDateRange: 'QTD',
  stickyHeaderStyle: {},
  columnPreset: 'cumulative',
  extensions: {}
}

export default TopClients
