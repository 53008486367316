import React from 'react'
import { Button, makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import Icon from '../../../atoms/Icon'
import { ICON_NAMES } from '../../../../constants'

const useStyles = makeStyles((theme) => ({
  roundedButton: {
    border: `2px solid ${theme.palette.mirage}`,
    color: theme.palette.mirage,
    borderRadius: 25,
    padding: '4px 16px 3px 16px',
    textTransform: 'none',
    fontWeight: 'bold',
    fontSize: '0.75rem',
    marginLeft: '10px'
  },
  editing: {
    background: '#898D9B',
    border: '2px solid #898D9B',
    color: 'white'
  },
  editIcon: {
    marginRight: '4px'
  }
}))

const EditButton = ({
  onClick,
  editMode,
  disabled
}) => {
  const classes = useStyles()

  return (
    <Button
      className={clsx(classes.roundedButton, { [classes.editing]: editMode })}
      onClick={onClick}
      variant='outlined'
      disabled={disabled}
    >
      <Icon name={ICON_NAMES.edit} additionalClasses={classes.editIcon} />
      {editMode ? 'Editing' : 'Edit'}
    </Button>
  )
}

EditButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  editMode: PropTypes.any.isRequired,
  disabled: PropTypes.bool
}

EditButton.defaultProps = {
  disabled: false
}

export default EditButton
