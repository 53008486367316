import React from 'react'
import PropTypes from 'prop-types'

import { useClientAbbreviation } from '../../../hooks'

const withAbbreviationAvatar = (AvatarComponent) => {
  const AvatarWithAbbreviation = ({
    client,
    ...props
  }) => {
    const [clientAbbreviation, useAbbreviation] = useClientAbbreviation(client)
    const src = props.src || client?.profilePic
    return (
      <AvatarComponent
        {...props}
        avatarLetters={clientAbbreviation}
        useAbbreviation={useAbbreviation}
        src={src}
      />
    )
  }

  AvatarWithAbbreviation.propTypes = {
    client: PropTypes.object,
    ...AvatarComponent.propTypes
  }

  return AvatarWithAbbreviation
}

withAbbreviationAvatar.propTypes = {
  AvatarComponent: PropTypes.node
}

export default withAbbreviationAvatar
