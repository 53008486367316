import React, { useCallback, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'
import { Box } from '@material-ui/core'
import { DATE_PRESET, LEVEL_TYPES, VIRTUALIZE_PROPS } from '../../constants'
import { useFetchState, useCSVData } from '../../hooks'
import {
  useAppContext,
  useAvailableDates
} from '../../redux/slices/appContext'
import { fetchTransactions } from '../../service'
import { getTransactionsTableConfig } from '../../utils/tableHelper'
import EmptySection from '../atoms/EmptySection'
import TableSkeleton from '../atoms/TableSkeleton'
import CSVButton from '../atoms/CSVButton'
import useDatePresetOptions from '../../hooks/useDatePresetOptions'
import { TableVirtualizedWithDynamicHeight } from './Table'
import AvailableDateSelect from './AvailableDateSelect'

const PerformanceTransactionsTable = ({ isHidden, allowDownload, fetchParams }) => {
  const appContext = useAppContext()
  const [availableDates] = useAvailableDates()
  const [selectedValue, setSelectedDateValue] = useState(DATE_PRESET.L7D)
  const { dateValue: date } = useDatePresetOptions(selectedValue)

  const { transactionsTableConfig, loading } = useFetchState(
    useCallback(
      async (setSafeState) => {
        try {
          if (isHidden) return {}
          const { data } = await fetchTransactions({
            startDate: date,
            endDate: availableDates.mainDate,
            levelTypes: [LEVEL_TYPES.CLIENT, LEVEL_TYPES.ACCOUNTS],
            clientIds: [appContext.clientId],
            ...(isEmpty(fetchParams) ? {} : fetchParams)
          })
          const transactionsTableConfig = getTransactionsTableConfig(
            data.sort(
              ({ valueDate: valueDateA }, { valueDate: valueDateB }) => {
                return new Date(valueDateB) - new Date(valueDateA)
              }
            )
          )
          setSafeState({ transactionsTableConfig })
        } catch (err) {
          console.error(err)
          setSafeState({ error: err })
        }
      },
      [appContext.clientId, isHidden, date, availableDates, fetchParams]
    )
  )

  const dateSelectRendered = useMemo(
    () => (
      <AvailableDateSelect
        initialValue={selectedValue}
        selectedValue={selectedValue}
        onChange={(_, __, valueKey) => setSelectedDateValue(valueKey)}
      />
    ),
    [selectedValue]
  )

  const csvData = useCSVData({
    loading,
    disabled: !allowDownload,
    rows: transactionsTableConfig ? transactionsTableConfig.rows : [],
    labels: transactionsTableConfig ? transactionsTableConfig.columns : []
  })

  if (isHidden) {
    return null
  }

  if (loading || isEmpty(transactionsTableConfig)) {
    return <TableSkeleton columns={10} rows={5} />
  }

  if (isEmpty(transactionsTableConfig.rows)) {
    return (
      <Box display='flex' flexDirection='column' width='100%'>
        <Box display='flex' ml='auto' mb='0.25rem'>
          {dateSelectRendered}
          {allowDownload && <CSVButton disabled data={[]} />}
        </Box>
        <EmptySection
          title='There is not data to display here yet.'
          description=''
        />
      </Box>
    )
  }

  return (
    <Box display='flex' flexDirection='column' width='100%'>
      <Box display='flex' ml='auto' mb='0.25rem'>
        {dateSelectRendered}
        {allowDownload && !isEmpty(csvData) && <CSVButton data={csvData} />}
      </Box>
      <TableVirtualizedWithDynamicHeight
        rows={transactionsTableConfig.rows}
        labels={transactionsTableConfig.columns}
        spacing='0.5rem'
        skipOrderBy
        virtualizeProps={{
          ...VIRTUALIZE_PROPS,
          rowHeight: VIRTUALIZE_PROPS.rowHeight * 2
        }}
      />
    </Box>
  )
}

PerformanceTransactionsTable.propTypes = {
  isHidden: PropTypes.bool,
  allowDownload: PropTypes.bool,
  fetchParams: PropTypes.object
}

PerformanceTransactionsTable.defaultProps = {
  isHidden: false,
  allowDownload: false,
  fetchParams: undefined
}

export default PerformanceTransactionsTable
