import React, { useCallback } from 'react'
import { Box, makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import { useQuery } from '@tanstack/react-query'
import { useForm } from 'react-hook-form'
import { disableHolidaysAndWeekends, mapDatesIntoTree } from '../../../../../utils'
import { getHolidays } from '../../../../../service'
import Icon from '../../../../atoms/Icon'
import { ICON_NAMES } from '../../../../../constants'
import SaveCancelButtons from '../../../../molecules/SaveCancelButtons'
import SydModal from '../../../../commonDesign/SydModal'
import SydButton from '../../../../commonDesign/Button'
import SydDatePicker from '../../../../commonDesign/SydDatePicker'
import SydLabel from '../../../../commonDesign/SydLabel'

const useStyles = makeStyles((theme) => ({
  selector: {
    padding: '0px 10px 10px 0'
  },
  editContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  itemDateField: {
    borderRadius: '4px',
    padding: '0.625rem 0px 0.625rem 0.625rem',
    border: '2px solid #EEF0F8',
    background: theme.palette.common.white,
    minHeight: '2.5rem',
    flexGrow: 1
  },
  itemDateInput: {
    '& .MuiInput-input': {
      paddingBottom: 0
    }
  },
  itemText: {
    fontSize: '1rem'
  },
  itemTextField: {
    minHeight: '3rem',
    display: 'flex',
    gap: '1rem',
    alignItems: 'start'
  },
  clearButtonContainer: {
    minWidth: '95px'
  },
  clearButton: {
    borderRadius: 25,
    padding: '4px 16px 3px 16px',
    textTransform: 'none',
    fontWeight: 'bold',
    fontSize: '0.75rem',
    marginLeft: '10px',
    background: theme.palette.summitBlue,
    border: `2px solid ${theme.palette.summitBlue}`,
    color: 'white'
  },
  clearIcon: {
    marginRight: '4px'
  },
  modalFooter: {
    margin: '14px 0 0',
    display: 'flex'
  },
  modalSaveCancelButtons: {
    width: '100%',
    justifyContent: 'space-between'
  }
}))

const QuickEditDateModal = ({
  title,
  subtitle,
  inputLabel,
  value,
  errorMessage = 'test',
  requireBusinessDay,
  allowClear,
  keyName,
  displayFormat,
  dateRange,
  isSubmitting,
  onSubmit,
  onClose
}) => {
  const classes = useStyles()

  const defaultValues = {
    [keyName]: value ? dayjs.utc(value).format('YYYY-MM-DD') : null
  }
  const {
    handleSubmit,
    setValue,
    watch
  } = useForm({ defaultValues })

  const currentValue = watch(keyName)

  const { data: holidays, isLoading: isLoadingHolidays } = useQuery({
    queryKey: ['holidays'],
    queryFn: async () => {
      const { data } = await getHolidays({})

      return mapDatesIntoTree(data)
    },
    enabled: requireBusinessDay
  })
  const shouldDisableDate = useCallback(
    (date) => {
      if (!requireBusinessDay || isLoadingHolidays) {
        return false
      }
      return disableHolidaysAndWeekends(date, holidays)
    },
    [holidays, requireBusinessDay, isLoadingHolidays]
  )

  return (
    <SydModal
      open
      onClose={onClose}
      title={title}
      subtitle={subtitle}
      size='small'
      disableBackdropClick={isSubmitting}
      disableEscapeKeyDown={isSubmitting}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box flexGrow={1}>
          <SydLabel
            label={inputLabel}
            error={!!errorMessage?.length && errorMessage}
          >
            <Box display='flex' alignItems='center' gridGap='14px'>
              <SydDatePicker
                onChange={(val) => setValue(keyName, dayjs.utc(val).format('YYYY-MM-DD'))}
                value={currentValue}
                format={displayFormat}
                defaultDate={value}
                InputProps={{ disableUnderline: true, className: classes.itemDateInput }}
                className={classes.itemDateField}
                InputAdornmentProps={{ position: 'start' }}
                shouldDisableDate={shouldDisableDate}
                {...dateRange}
                error={!!errorMessage?.length}
              />
              {allowClear && (
                <SydButton
                  onClick={e => setValue(keyName, null)}
                  disabled={!currentValue || isSubmitting}
                  variant='secondary'
                >
                  <Icon name={ICON_NAMES.delete} additionalClasses={classes.clearIcon} />
                  Clear
                </SydButton>
              )}
            </Box>
          </SydLabel>
        </Box>
        <div className={classes.modalFooter}>
          <SaveCancelButtons
            onCancel={onClose}
            isSubmitting={isSubmitting}
            onSave={handleSubmit(onSubmit)}
            containerClassName={classes.modalSaveCancelButtons}
          />
        </div>
      </form>
    </SydModal>
  )
}

QuickEditDateModal.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  inputLabel: PropTypes.string,
  value: PropTypes.any,
  errorMessage: PropTypes.string,
  requireBusinessDay: PropTypes.bool,
  allowClear: PropTypes.bool,
  keyName: PropTypes.string,
  displayFormat: PropTypes.string,
  dateRange: PropTypes.objectOf({
    minDate: PropTypes.string,
    maxDate: PropTypes.string
  }),
  onSubmit: PropTypes.func,
  onClose: PropTypes.func,
  isSubmitting: PropTypes.bool
}

export default QuickEditDateModal
