import React from 'react'
import { makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import SydButton from '../../../commonDesign/Button'
import RoundedModal from '../../../molecules/RoundedModal'
import { useFormattingContext } from '../../../organisms/FormattingProvider/FormattingContext'

const useStyles = makeStyles((theme) => ({
  form: {
    margin: '10px 0 50px',
    display: 'flex',
    flexDirection: 'column',
    gap: '50px'
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    gap: '20px'
  }
}))

function ExpireDialog ({ item, open, onCancel, onConfirm, processing, report }) {
  const classes = useStyles()
  const { formatter } = useFormattingContext()

  return (
    <RoundedModal
      title={`Expire ${item.reportType} @ ${formatter(report?.asOfDate, '@M/D/YY')}`}
      open={open}
      onClose={onCancel}
      position='top'
    >
      <div className={classes.dialogBody}>
        <div className={classes.form}>
          <div>This will expire the report and remove its data.</div>
        </div>
        <div className={classes.actions}>
          <SydButton disabled={processing} variant='ghost' size='lg' onClick={onCancel}>Cancel</SydButton>
          <SydButton
            variant='primary'
            priority='warning'
            size='lg'
            onClick={(e) => onConfirm(e, {
              bppReportId: report?.bppReportId
            })}
            processing={processing}
          >
            Confirm
          </SydButton>
        </div>
      </div>
    </RoundedModal>
  )
}

ExpireDialog.propTypes = {
  item: PropTypes.shape({
    reportType: PropTypes.string
  }),
  report: PropTypes.shape({
    bppReportId: PropTypes.number,
    asOfDate: PropTypes.string
  }),
  open: PropTypes.bool,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  processing: PropTypes.bool
}

ExpireDialog.defaultProps = {
  open: false,
  processing: false
}

export default ExpireDialog
