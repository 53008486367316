import React, { useEffect, useRef, useState } from 'react'
import mstarAggregationConsumerAccountSummary from 'mstar-aggregation-consumer-accountsummary'
import { useDispatch } from 'react-redux'
import Alert from '../../../atoms/Alert'
import { ALERT_SEVERITY, ALERT_VARIANT } from '../../../../constants'
import { setStepContext, setStepNavigation } from '../../../../redux/slices/addAccountContext'
import { useByAllSessionTokens } from '../../../../api/users'
import LoadingPlaceholder from '../LoadingPlaceholder'

const errorStates = {
  badAuthentication: {
    level: 'blocking',
    message: 'There was an issue logging in. Please try again.'
  },
  internalError: {
    level: 'blocking',
    message: 'There was an issue launching this widget.  Please try again'
  }
}

const ByAllAccountLinkingComponent = () => {
  const dispatch = useDispatch()
  // eslint-disable-next-line no-unused-vars
  const byAllScript = mstarAggregationConsumerAccountSummary

  const mStarEmbedObject = useRef(null)
  const [errorState, setErrorState] = useState(null)

  const handleEmbedErrors = (event) => {
    const { type } = event

    setErrorState({
      type,
      ...(errorStates[type] ?? {})
    })
  }

  const handleEmbedEvents = (event) => {
    const { type, detail } = event

    if (type === 'accountSetup') {
      dispatch(setStepContext({ stepContext: { route: detail } }))
      dispatch(setStepNavigation({ key: 'byall_linkingComponent' }))
      return
    }

    console.log({ type, event })
  }

  useEffect(() => {
    if (!mStarEmbedObject.current) {
      return
    }
    const elem = mStarEmbedObject.current
    elem.addEventListener('badAuthentication', handleEmbedErrors)
    elem.addEventListener('internalError', handleEmbedErrors)
    elem.addEventListener('userExit', handleEmbedEvents)
    elem.addEventListener('accountSetup', handleEmbedEvents)

    return () => {
      elem.removeEventListener('badAuthentication', handleEmbedErrors)
      elem.removeEventListener('internalError', handleEmbedErrors)
      elem.removeEventListener('userExit', handleEmbedEvents)
      elem.removeEventListener('accountSetup', handleEmbedEvents)
    }
  })

  const { data, isFetching: isFetchingTokens } = useByAllSessionTokens()

  return (
    <>
      {isFetchingTokens ? (
        <LoadingPlaceholder />
      ) : errorState ? (
        <Alert variant={ALERT_VARIANT.standard} severity={ALERT_SEVERITY.error}>
          <p style={{ margin: 0 }}>
            Issue adding assets<br />
            <span style={{ fontWeight: 300, fontSize: '.9rem' }}>{errorState?.message ?? 'Something went wrong'}</span>
          </p>
        </Alert>
      ) : (
        <div style={{ margin: '0 -28px -28px' }}>
          <mstar-aggregation-consumer-accountsummary
            ref={mStarEmbedObject}
            auth-context={JSON.stringify({ csrfToken: data?.csrfToken, jsessionId: data?.sessionId })}
            iframe-style='min-height:60vh;height:100%;max-height:100%;max-width:100%'
            override-css-file={`https://${window.location.host}/byall-component-styles.css`}
            custom-fonts={`https://${window.location.host}/byall-component-fonts.css`}
          />
        </div>
      )}
    </>
  )
}
export default ByAllAccountLinkingComponent
