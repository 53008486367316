import React from 'react'
import PropTypes from 'prop-types'

function LinkField ({ field }) {
  return (
    <a href={field.value} target='_blank' rel='noopener noreferrer'>{field.data?.linkDescription || field.value}</a>
  )
}

LinkField.propTypes = {
  field: PropTypes.shape({
    value: PropTypes.any,
    data: PropTypes.shape({
      linkDescription: PropTypes.string
    })
  })
}

export default LinkField
