import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { alpha, makeStyles } from '@material-ui/core'
import SydButton from '../../../commonDesign/Button'
import {
  useExpireBppReportMutation,
  useListBppReports,
  useRestartBppReportMutation,
  useStartBppMutation
} from '../../../../api/coreData'
import DenaliReportRow from './DenaliReportRow'
import RunDialog from './RunDialog'
import ExpireDialog from './ExpireDialog'

const useStyles = makeStyles((theme) => ({
  reportType: {
    minWidth: '900px',
    padding: '10px 20px',
    borderRadius: '4px',
    outline: `1px solid ${alpha(theme.palette.primary.main, 0.3)}`,
    '& .__header': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between'
    },
    '& .__title': {
      fontWeight: theme.typography.weights.bold
    },
    '& .__description': {
      fontWeight: theme.typography.weights.light
    },
    '& .__reports': {
      marginTop: '5px'
    },
    '& .__actions': {
      display: 'flex',
      flexDirection: 'row',
      gap: '5px'
    },
    '& .__runs': {
      width: '100%',
      '& th': {
        textAlign: 'left'
      },
      '& td': {
        fontWeight: theme.typography.weights.light,
        paddingBottom: '5px'
      }
    }
  }
}))

const useRun = () => {
  const [runState, setRunState] = useState({ open: false, processing: false })
  const { mutateAsync: startBpp } = useStartBppMutation()
  const onCancel = useCallback((e) => {
    e.preventDefault()
    e.stopPropagation()
    setRunState({ open: false, processing: false })
  }, [setRunState])
  const onConfirm = useCallback(async (e, command) => {
    e.preventDefault()
    e.stopPropagation()
    setRunState({ open: true, processing: true })
    try {
      await startBpp(command)
    } catch (e) {
      alert('There was a problem running the bpp report')
      console.error('error running bpp report', e)
    } finally {
      setRunState({ open: false, processing: false })
    }
  }, [startBpp, setRunState])
  const onRun = useCallback((e) => {
    e.preventDefault()
    e.stopPropagation()
    setRunState({ open: true, processing: false })
  }, [setRunState])
  const onReRun = useCallback(async (e, report) => {
    e.preventDefault()
    e.stopPropagation()
    try {
      await startBpp({
        reportType: report.reportType,
        asOfDate: report.asOfDate
      })
    } catch (e) {
      alert('There was a problem running the bpp report')
      console.error('error running bpp report', e)
    }
  }, [startBpp])
  return {
    runState,
    setRunState,
    onCancel,
    onConfirm,
    onRun,
    onReRun
  }
}

const useExpire = (item) => {
  const [expireState, setExpireState] = useState({ open: false, processing: false })
  const { mutateAsync: expireBpp } = useExpireBppReportMutation(item.reportType)
  const onCancel = useCallback((e) => {
    e.preventDefault()
    e.stopPropagation()
    setExpireState({ open: false, processing: false })
  }, [setExpireState])
  const onConfirm = useCallback(async (e, command) => {
    e.preventDefault()
    e.stopPropagation()
    setExpireState(prev => ({ ...prev, open: true, processing: true }))
    try {
      await expireBpp(command)
    } catch (e) {
      alert('There was a problem expiring the bpp report')
      console.error('error expiring bpp report', e)
    } finally {
      setExpireState({ open: false, processing: false })
    }
  }, [expireBpp, setExpireState])
  const onExpire = useCallback((e, report) => {
    e.preventDefault()
    e.stopPropagation()
    setExpireState({ open: true, processing: false, report })
  }, [setExpireState])

  return {
    expireState,
    setExpireState,
    onCancel,
    onConfirm,
    onExpire
  }
}

const useRestart = (item) => {
  const { mutateAsync: restartBpp } = useRestartBppReportMutation(item.reportType)
  const onRestart = useCallback(async (e, report) => {
    e.preventDefault()
    e.stopPropagation()
    try {
      await restartBpp({
        bppReportId: report.bppReportId
      })
    } catch (e) {
      alert('There was a problem restarting the bpp report')
      console.error('error restarting bpp report', e)
    }
  }, [restartBpp])

  return {
    onRestart
  }
}

function DenaliReportType ({ item }) {
  const classes = useStyles()
  const { data, isLoading } = useListBppReports(item.reportType)
  const run = useRun()
  const expire = useExpire(item)
  const restart = useRestart(item)

  return (
    <div className={classes.reportType}>
      <div className='__header'>
        <div>
          <div className='__title'>{item.reportType}</div>
          <div className='__description'>{item.description}</div>
        </div>
        <div>
          <SydButton variant='outline' size='sm' icon='sync' onClick={run.onRun}>Run</SydButton>
        </div>
      </div>
      <div className='__reports'>
        {isLoading ? (
          <div>Loading ...</div>
        ) : (
          <table className='__runs'>
            <thead>
              <DenaliReportRow.Headers />
            </thead>
            <tbody>
              {data.reports.map(r => (
                <DenaliReportRow
                  key={r.bppReportId}
                  report={r}
                  onExpire={expire.onExpire}
                  onReRun={run.onReRun}
                  onRestart={restart.onRestart}
                />
              ))}
            </tbody>
          </table>
        )}
      </div>
      <RunDialog
        item={item}
        onConfirm={run.onConfirm}
        onCancel={run.onCancel}
        {...run.runState}
      />
      <ExpireDialog
        item={item}
        onConfirm={expire.onConfirm}
        onCancel={expire.onCancel}
        {...expire.expireState}
      />
    </div>
  )
}

DenaliReportType.propTypes = {
  item: PropTypes.shape({
    reportType: PropTypes.string,
    description: PropTypes.string,
    configuration: PropTypes.object
  })
}

export default DenaliReportType
