import React from 'react'
import FadeIn from '../../../../molecules/Transitions/FadeIn'
import ErrorBoundary from '../../../../molecules/ErrorBoundary'
import FeeSchedulesSection from './FeeSchedulesSection'

function BillingTab () {
  return (
    <ErrorBoundary name='Billing Tab'>
      <FadeIn>
        <FeeSchedulesSection />
      </FadeIn>
    </ErrorBoundary>
  )
}

BillingTab.propTypes = {
}

export default BillingTab
