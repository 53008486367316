import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import { Box, InputBase, makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import { useToggle } from '../../hooks'
import { BUTTON_VARIANT, MAX_COMMENT_INPUT_LENGTH, BUTTON_SIZES } from '../../constants'
import RoundedButton from '../atoms/RoundedButton'
import InputBaseComment from './InputBaseComment'
import NoteCommentActions from './NoteCommentActions'

const useStyles = makeStyles({
  footer: {
    display: 'flex',
    alignItems: 'center'
  },
  btnGroup: {
    display: 'flex'
  },
  input: {
    fontSize: '0.75rem',
    fontWeight: 'bold',
    lineHeight: '1.43',
    color: 'inherit',
    padding: '0 0 7px 0 !important',
    marginBottom: '0.5rem'
  }
})

const initialState = {
  text: '',
  defaultText: '',
  textCursorPosition: 0
}

const InputCommentInlineEdit = ({
  isLoading,
  className,
  defaultValue,
  onCancel: onCustomCancel,
  onSaveChanges: onCustomSaveChanges
}) => {
  const classes = useStyles()
  const [state, setState] = useState({
    ...initialState,
    text: defaultValue,
    defaultText: defaultValue
  })
  const [emojiPickerOpen, emojiPickerToggle] = useToggle()

  const onEmojiSelectHandler = useCallback(
    (emoji) => {
      const { textCursorPosition: cursorPosition, text } = state
      setState({
        ...state,
        text: [
          text.slice(0, cursorPosition),
          emoji.native,
          text.slice(cursorPosition)
        ].join('')
      })
      emojiPickerToggle()
    },
    [state, emojiPickerToggle]
  )

  const onSaveChanges = useCallback(() => {
    const { text } = state
    onCustomSaveChanges(text)
  }, [state, onCustomSaveChanges])

  const onCancel = useCallback(() => {
    const { defaultText } = state
    onCustomCancel(defaultText)
  }, [state, onCustomCancel])

  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <InputBaseComment
          textInput={InputBase}
          inputProps={{
            autoFocus: true,
            fullWidth: true,
            multiline: true,
            inputProps: { maxLength: MAX_COMMENT_INPUT_LENGTH },
            classes: {
              root: clsx(classes.input, { [className]: !!className })
            }
          }}
          value={state.text}
          onChange={(e) =>
            setState({
              text: e.target.value,
              textCursorPosition: e.target.selectionStart
            })}
        />
      </div>
      <div className={classes.footer}>
        <div className={classes.btnGroup}>
          <RoundedButton
            variant={BUTTON_VARIANT.outlined}
            size={BUTTON_SIZES.extraSmall}
            onClick={onCancel}
          >
            Cancel
          </RoundedButton>
          <Box ml={2} display='flex'>
            <RoundedButton
              isLoading={isLoading}
              onClick={onSaveChanges}
              primary
              disabled={!state.text.trim()}
              size={BUTTON_SIZES.extraSmall}
            >
              Save changes
            </RoundedButton>
          </Box>
        </div>
        <NoteCommentActions
          emojiPickerOpen={emojiPickerOpen}
          toggleEmojiPicker={emojiPickerToggle}
          onEmojiSelectHandler={onEmojiSelectHandler}
        />
      </div>
    </div>
  )
}

InputCommentInlineEdit.propTypes = {
  defaultValue: PropTypes.string,
  onSaveChanges: PropTypes.func,
  onCancel: PropTypes.func,
  isLoading: PropTypes.bool,
  className: PropTypes.string
}

InputCommentInlineEdit.defaultProps = {
  defaultValue: '',
  onSaveChanges: noop,
  onCancel: noop,
  isLoading: false,
  className: ''
}

export default InputCommentInlineEdit
