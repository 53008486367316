import React from 'react'
import {
  useTable,
  useFlexLayout,
  useResizeColumns,
  useSortBy,
  useExpanded
} from 'react-table'
import PropTypes from 'prop-types'
import { Box, CircularProgress, TableSortLabel } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import Text from '../atoms/Text'
import TableSkeleton from '../atoms/TableSkeleton'

const useStyles = makeStyles((theme) => ({
  thead: {
    backgroundColor: theme.palette.porcelain,
    '& > .tr': {
      color: 'inherit',
      outline: 0,
      verticalAlign: 'middle'
    },
    '& > .tr > .th': {
      fontWeight: 'bold',
      borderBottom: 'none !important',
      padding: '0.75rem'
    }
  },
  tbody: {
    '& > .tr': {
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: theme.palette.porcelain
      }
    },
    '& > .tr > .td': {
      padding: '1rem',
      fontSize: '0.75rem',
      textAlign: 'left'
    },
    '& > div': {
      height: '100%'
    }
  },
  tfoot: {
    '& > .tr': {
      cursor: 'pointer',
      backgroundColor: theme.palette.porcelain
    },
    '& > .tr > .td': {
      padding: '1rem',
      fontSize: '0.75rem',
      textAlign: 'left'
    }
  },
  loading: {
    justifyContent: 'center'
  },
  headerCell: {
    padding: '0.75rem'
  },
  row: {
    cursor: 'pointer'
  },
  emptyRow: {
    padding: '1rem',
    textAlign: 'center',
    backgroundColor: theme.palette.gray.lighter
  }
}))

const ExpandableTable = ({
  columns,
  data,
  isRowLoading,
  isLoading,
  renderBody,
  allowInfiniteScroll
}) => {
  const classes = useStyles()
  const defaultColumn = {
    minWidth: 10,
    maxWidth: 400
  }
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    prepareRow,
    state: { expanded }
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      autoResetExpanded: false,
      autoResetSortBy: false
    },
    useFlexLayout,
    useResizeColumns,
    useSortBy,
    useExpanded
  )

  if (!isLoading && !rows.length) {
    return (
      <div className={classes.emptyRow}>
        <Text text='No data to show' />
      </div>
    )
  }

  return (
    <div className='react-table'>
      {
        isLoading
          ? <TableSkeleton showHeader columns={columns.length} rows={10} />
          : (
            <div className='table' {...getTableProps()}>
              <div className={allowInfiniteScroll ? 'thead-infinite' : classes.thead}>
                {headerGroups.map((headerGroup, index) => (
                  <div {...headerGroup.getHeaderGroupProps()} className='tr' key={`thead-${index}`}>
                    {headerGroup.headers.map((column, i) => {
                      const {
                        getHeaderProps,
                        isSorted,
                        isSortedDesc,
                        getSortByToggleProps,
                        disableSortBy
                      } = column

                      const extraClass = isSorted ? isSortedDesc ? 'desc' : 'asc' : ''
                      const { onClick, ...rest } = getHeaderProps(
                        getSortByToggleProps()
                      )

                      return (
                        <div
                          className={`${extraClass} th subtitle2`}
                          key={`th-${i}`}
                          {...column.getHeaderProps()}
                          {...rest}
                          onClick={onClick}
                        >
                          {disableSortBy ? (
                            <Text text={column.render('Header')} variant='subtitle2' />
                          ) : (
                            <TableSortLabel
                              active={isSorted}
                              direction={isSorted ? isSortedDesc ? 'desc' : 'asc' : 'asc'}
                            >
                              <Text text={column.render('Header')} variant='subtitle2' />
                            </TableSortLabel>
                          )}
                        </div>
                      )
                    })}
                  </div>
                ))}
              </div>
              <div {...getTableBodyProps()} className={classes.tbody}>
                {renderBody(rows, prepareRow, isRowLoading, expanded)}
              </div>
              <div className={classes.tfoot}>
                {footerGroups.map((group, groupIndex) => (
                  <div className='tr' {...group.getFooterGroupProps()} key={`group-${groupIndex}`}>
                    {group.headers.map((column, columnIndex) => (
                      <div {...column.getFooterProps()} className='td' key={`column-${columnIndex}`}>
                        <Text text={column.render('Footer')} variant='subtitle2' />
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            </div>
          )
      }
    </div>
  )
}

ExpandableTable.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  onClickRow: PropTypes.func,
  isRowLoading: PropTypes.bool,
  isLoading: PropTypes.bool,
  allowInfiniteScroll: PropTypes.bool,
  renderBody: PropTypes.func
}

ExpandableTable.defaultProps = {
  renderBody: (rows, prepareRow, isRowLoading, expanded) => {
    return rows.map((row, i) => {
      prepareRow(row)
      const isLoading = isRowLoading[row.id]

      return (
        <React.Fragment key={`fragment-${i}`}>
          <div className='tr' {...row.getRowProps()}>
            {
              row.cells.map((cell, index) => {
                return (
                  <div {...cell.getCellProps()} className='td' key={`cell-${index}`}>
                    <Text
                      text={cell.render('Cell', {
                        isLoading: isRowLoading[row.id],
                        isExpanded: expanded[row.id]
                      })}
                      variant='subtitle2'
                    />
                  </div>
                )
              })
            }
          </div>
          {
            isLoading
              ? <Box my={4} textAlign='center'><CircularProgress /></Box>
              : null
          }
        </React.Fragment>
      )
    })
  },
  allowInfiniteScroll: false,
  isRowLoading: false
}

export default React.memo(ExpandableTable)
