import React, { createContext, useContext } from 'react'
import PropTypes from 'prop-types'
import { Chip } from '@nivo/tooltip'
import { useTheme } from '@nivo/core'
import { useFormattingContext } from '../FormattingProvider/FormattingContext'

export const TooltipContext = createContext({})

function BarTooltip ({ data }) {
  const theme = useTheme()
  const { valueAccessor, targetAccessor, valueFormat, targetFormat, allocationColor, targetColor } = useContext(TooltipContext)
  const { formatter } = useFormattingContext()
  const allocationValue = formatter(data[valueAccessor], valueFormat)
  const targetValue = formatter(data[targetAccessor], targetFormat)

  return (
    <div style={theme.tooltip.container}>
      <div style={theme.tooltip.table}>
        <table>
          <tbody>
            <tr>
              <td><Chip color={allocationColor} /></td>
              <td>Allocation</td>
              <td>{allocationValue}</td>
            </tr>
            <tr>
              <td><Chip color={targetColor} /></td>
              <td>Target</td>
              <td>{targetValue}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  )
}

BarTooltip.propTypes = {
  data: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

export default BarTooltip
