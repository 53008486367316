import React, { useCallback, useState } from 'react'
import { makeStyles } from '@material-ui/core'
import SearchBarInput from '../../../molecules/SearchBar/SearchBarInput'
import { useMilestonesContext } from '../MilestonesContext'

const useStyles = makeStyles(() => ({
  container: {
    maxWidth: '26rem',
    marginBottom: '1rem'
  }
}))

const MilestonesSearchBar = () => {
  const classes = useStyles()
  const { onChangeQuery, isSearchLoading } = useMilestonesContext()
  const [queryText, setQueryText] = useState()

  const onChangeQueryText = useCallback(
    (value) => {
      setQueryText(value)
      onChangeQuery(value)
    },
    [onChangeQuery]
  )

  return (
    <div className={classes.container}>
      <SearchBarInput
        value={queryText}
        loading={isSearchLoading}
        setValue={onChangeQueryText}
        placeholder='Search by milestone name or client'
      />
    </div>
  )
}

export default MilestonesSearchBar
