import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { isFunction } from 'lodash'
import { useCustomDataset } from '../../../../contexts/CustomDatasetContext'
import CardItem from './CardItem'
import CardsLayout from './CardsLayout'
import ListLayout from './ListLayout'

const CUSTOM_DATASET_LAYOUT = {
  CARDS: 'cards',
  LIST: 'list'
}

const AdvisorHomeCustomDatasetLayout = ({
  limit,
  variant,
  itemRenderer,
  datasetIndex,
  emptyStateLabel
}) => {
  const dataSet = useCustomDataset()

  const data = useMemo(
    () => dataSet.isDone
      ? dataSet.value[datasetIndex ?? 0]?.slice(0, limit)
      : [],
    [dataSet, datasetIndex, limit]
  )

  const renderItem = useCallback(
    (datum) => {
      if (isFunction(itemRenderer)) {
        return itemRenderer(datum)
      }
      return React.cloneElement(itemRenderer, {
        ...itemRenderer.props,
        ...datum
      })
    },
    [itemRenderer]
  )

  const isLoading = dataSet.isLoading || !dataSet.isDone

  if (variant === CUSTOM_DATASET_LAYOUT.CARDS) {
    return (
      <CardsLayout
        data={data}
        isLoading={isLoading}
        renderItem={renderItem}
        emptyStateLabel={emptyStateLabel}
      />
    )
  }
  if (variant === CUSTOM_DATASET_LAYOUT.LIST) {
    return (
      <ListLayout
        data={data}
        isLoading={isLoading}
        renderItem={renderItem}
        emptyStateLabel={emptyStateLabel}
      />
    )
  }

  return null
}

AdvisorHomeCustomDatasetLayout.propTypes = {
  variant: PropTypes.oneOf(Object.values(CUSTOM_DATASET_LAYOUT)),
  itemRenderer: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  datasetIndex: PropTypes.number,
  emptyStateLabel: PropTypes.string,
  limit: PropTypes.number
}

AdvisorHomeCustomDatasetLayout.Card = CardItem

export default AdvisorHomeCustomDatasetLayout
