import React, { useCallback, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import dayjs from 'dayjs'
import {
  useAssetSearch,
  useClassificationTags,
  useLevelDates,
  useCustodians
} from '../../../../api/coreData'
import Skeleton from '../../../atoms/Skeleton'
import Select from '../../../molecules/Select'
import { useContextContainer } from '../../../../abundanceEngine/components/ContextContainer'
import useDatePresetOptions from '../../../../hooks/useDatePresetOptions'
import { INTERNAL_DATE_FORMAT } from '../../../../constants'
import { defaultOptions, useRelativeDateRange } from '../../../molecules/RelativeDateSelect'
import { useAppContext } from '../../../../redux/slices/appContext'
import { reportParamsShape } from './reportParams'
import {
  DATA_SET_TYPES,
  EXPOSURE_REPORT_CONTEXT_KEY,
  EXPOSURE_TYPES,
  EXPOSURE_TYPE_ID_KEY,
  getAssetExposureName,
  levelDatesQuery
} from './helpers'
import DateSelector from './DateSelector'

const useStyles = makeStyles((theme) => ({
  reportResultsTitle: {
    paddingTop: '1rem',
    width: '100%',
    minHeight: '64px'
  },
  title: {
    fontSize: '2.125rem',
    fontWeight: theme.typography.weights.bold,
    marginBottom: '1.125rem',
    marginTop: '2rem'
  },
  subTitle: {
    display: 'flex',
    flexDirection: 'row',
    fontSize: '.8rem',
    gap: '1rem',
    fontWeight: theme.typography.weights.light,
    marginBottom: '1.25rem'
  },
  selector: {
    height: '100%',
    padding: '0.75rem',
    '& > *': {
      fontSize: '0.75rem'
    }
  }
}))

const makeLevelTypeMessage = (levelType) => {
  switch (levelType) {
    case 'account':
      return 'By Account'
    case 'client':
      return 'By Client'
    default:
      return ''
  }
}

const getExposureTargetSearch = (exposureType) => {
  return exposureType === EXPOSURE_TYPES.ASSET
    ? useAssetSearch
    : useClassificationTags
}

const useResultTitle = ({ exposureType, exposureTarget, levelType }) => {
  const { query, dateRange, levelTypeMessage } = useMemo(() => {
    const filterId = EXPOSURE_TYPE_ID_KEY[exposureType]
    return {
      query: {
        filters: {
          [filterId]: exposureTarget
        },
        take: 1
      },
      levelTypeMessage: makeLevelTypeMessage(levelType)
    }
  }, [exposureType, exposureTarget, levelType])

  const useExposureTarget = useMemo(
    () => getExposureTargetSearch(exposureType),
    [exposureType]
  )

  const { data, isLoading, error } = useExposureTarget(query)

  const assetName = useMemo(() => {
    if (isLoading ?? error) return ''
    const asset = data?.[0]
    return getAssetExposureName(exposureType, asset)
  }, [data, isLoading, error, exposureType])

  return {
    dateRange,
    assetName,
    isLoading,
    levelTypeMessage
  }
}

const ReportResultsTitle = ({
  reportParams,
  showCustodianFilter,
  dateRangeOptions
}) => {
  const classes = useStyles()
  const { assetName, isLoading, levelTypeMessage } =
    useResultTitle(reportParams)

  const { data: userLevelDates } = useLevelDates(levelDatesQuery)
  const { data: custodians, isLoading: loadingCustodians } =
    useCustodians(levelDatesQuery)

  const custodianOptions = useMemo(() => {
    if (loadingCustodians || !custodians) return []
    const allOption = [
      {
        key: 'all',
        value: 0,
        label: 'All'
      }
    ]
    const options = [
      ...allOption,
      ...custodians.map((custodian) => ({
        key: custodian.custodianCode,
        value: custodian.custodianId,
        label: custodian.custodianName
      }))
    ]
    return options
  }, [custodians, loadingCustodians])

  const { getDateByPresetValueKey } = useDatePresetOptions()

  const [{ dateRangePreset, endDate, custodian }, setReportContext] =
    useContextContainer(EXPOSURE_REPORT_CONTEXT_KEY)

  const { availableDates } = useAppContext()
  const { dateRange: selectedDateRange } = useRelativeDateRange(
    dateRangePreset,
    availableDates,
    dateRangeOptions
  )

  useEffect(() => {
    setReportContext(prevState => ({
      ...prevState,
      startDate: selectedDateRange.startDate
    }))
  }, [selectedDateRange, setReportContext])

  const setDateRangePreset = useCallback(
    (value) =>
      setReportContext((prevState) => {
        return {
          ...prevState,
          dateRangePreset: value
        }
      }),
    [setReportContext]
  )

  const setAsOfDate = useCallback(
    (value) =>
      setReportContext((prevState) => {
        const valueFormatted = dayjs(value).format(INTERNAL_DATE_FORMAT)
        const startDate = getDateByPresetValueKey(prevState.dateRangePreset, {
          mainDate: valueFormatted
        })
        return {
          ...prevState,
          startDate,
          endDate: valueFormatted
        }
      }),
    [setReportContext, getDateByPresetValueKey]
  )

  const setCustodian = useCallback(
    (value) =>
      setReportContext((prevState) => ({
        ...prevState,
        custodian: value
      })),
    [setReportContext]
  )

  return (
    <div className={classes.reportResultsTitle}>
      {isLoading ? (
        <Skeleton height='25px' width='100%' />
      ) : (
        <>
          <div className={classes.title}>
            <span>{assetName}</span>
            <span>{levelTypeMessage ? ' / ' : ''}</span>
            <span>{levelTypeMessage}</span>
          </div>
          <div className={classes.subTitle}>
            {reportParams.dataSet === DATA_SET_TYPES.BALANCE_AND_PERFORMANCE && (
              <Select
                prefixLabel='Date Range:'
                value={dateRangePreset}
                onChange={setDateRangePreset}
                variant='outlined-rounded'
                className={classes.selector}
                options={dateRangeOptions}
                disabled={reportParams.dataSet === DATA_SET_TYPES.BALANCE}
                preventOptionsOverflowContainer
                showCheckMarOnSelectedItems
              />
            )}
            <DateSelector
              prefixLabel='As of:'
              value={endDate}
              onChange={setAsOfDate}
              variant='outlined-rounded'
              disabledDateRange={userLevelDates}
            />
            {showCustodianFilter && (
              <Select
                prefixLabel='Custodian:'
                value={custodian}
                placeholder='Select Custodian'
                disabled={loadingCustodians}
                onChange={setCustodian}
                className={classes.selector}
                variant='outlined-rounded'
                options={custodianOptions}
              />
            )}
          </div>
        </>
      )}
    </div>
  )
}

ReportResultsTitle.propTypes = {
  reportParams: reportParamsShape,
  showCustodianFilter: PropTypes.bool,
  dateRangeOptions: PropTypes.object
}

ReportResultsTitle.defaultProps = {
  dateRangeOptions: defaultOptions.filter(({ value }) =>
    ['L7D', 'L30D', 'MTD', 'QTD', 'YTD'].includes(value)
  )
}

export default ReportResultsTitle
