import { useClient } from '../../../../api/clients'
import { useGetAssignedClientModel } from '../../../../api/rebalancer'
import { useAppContext } from '../../../../redux/slices/appContext'

export const useClientModelInfo = (clientId) => {
  const { data: client, isLoading: clientLoading } = useClient(clientId)
  const { data: model, isLoading: modelLoading } = useGetAssignedClientModel(clientId)

  return {
    client,
    model,
    loading: clientLoading || modelLoading
  }
}

export const useCurrentClientModelInfo = () => {
  const { clientId } = useAppContext()
  const { data: client, isLoading: clientLoading } = useClient(clientId)
  const { data: model, isLoading: modelLoading } = useGetAssignedClientModel(clientId)

  return {
    client,
    model,
    loading: clientLoading || modelLoading
  }
}
