import React, { useCallback, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Checkbox } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import noop from 'lodash/noop'
import dayjs from 'dayjs'
import { useDynamicPlanning } from '../DynamicPlanningContext'
import RoundedButton from '../../../atoms/RoundedButton'
import { BUTTON_SIZES, BUTTON_VARIANT } from '../../../../constants'
import Text from '../../../atoms/Text'
import DynamicPlanningGoal from '../PlanGoal'

const useStyles = makeStyles((theme) => ({
  inputTop: {
    fontSize: '24px',
    fontWeight: 700,
    width: '160px',
    paddingBottom: '0px'
  },
  checkbox: {
    color: '#212945 !important',
    backgroundColor: '#F3F3F3 !important'
  },
  categoryContainer: {
    padding: '12px',
    flex: '1 1 0',
    maxWidth: '400px',
    minWidth: '300px'
  },
  categoryHeader: ({ allowToggle }) => ({
    cursor: allowToggle ? 'pointer' : 'inherit',
    display: 'flex',
    flexDirection: 'row',
    minHeight: '3rem'
  }),
  categoryTitle: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  line: ({ primaryColor }) => ({
    width: '100%',
    height: '7px',
    backgroundColor: primaryColor || '#76C39A',
    marginBottom: '24px'
  }),
  goalsContainer: {
    marginBottom: '1rem'
  }
}))

const GoalCategoryDetailItem = ({
  goalCategory,
  onEditGoal,
  allowToggle,
  displayCreateButton = true,
  createButtonText = 'Add Goal'
}) => {
  const { primaryColor, categoryId, shortName } = goalCategory
  const classes = useStyles({ primaryColor, allowToggle })
  const { currentPlan, linkedPlan, activeGoalIds, activeGoalIdsDispatch, updateGoal, selectedChart } = useDynamicPlanning()
  const [isLoading, setIsLoading] = useState(false)

  const categoryGoals = useMemo(() => currentPlan?.goals?.filter((goal) => goal.goalCategoryId === categoryId) ?? [], [categoryId, currentPlan])
  const linkedCategoryGoals = useMemo(() => linkedPlan?.goals?.filter((goal) => goal.goalCategoryId === categoryId) ?? [], [categoryId, linkedPlan])
  const allGoalsAreSelected = useMemo(() => categoryGoals.length > 0 && categoryGoals.every(goal => activeGoalIds.includes(goal.goalId)), [categoryGoals, activeGoalIds])
  const displayToggle = useMemo(() => allowToggle && selectedChart !== 'pyramid', [allowToggle, selectedChart])

  const newGoalObj = useMemo(() => ({
    planId: currentPlan?.planId,
    goalCategoryId: categoryId,
    shortName: 'New goal',
    longName: 'New goal',
    flowAmount: -10000,
    frequency: 'ANNUALLY',
    startDate: dayjs.utc().format('YYYY-MM-DD'),
    endDate: null
  }), [currentPlan, categoryId])

  const createNewGoal = useCallback(async () => {
    setIsLoading(true)
    await updateGoal(null, currentPlan.planId, newGoalObj)
    setIsLoading(false)
  }, [updateGoal, currentPlan, newGoalObj])

  return (
    <div className={classes.categoryContainer}>
      <div
        className={classes.categoryHeader}
        onClick={() => allowToggle && activeGoalIdsDispatch({
          type: 'TOGGLE_GOAL_IDS',
          payload: [...categoryGoals.map(goal => goal.goalId), ...linkedCategoryGoals.map(goal => goal.goalId)],
          forceState: !allGoalsAreSelected ? 'ACTIVE' : 'INACTIVE'
        })}
        data-prevent-edit-close={1}
      >
        {displayToggle && (
          <Checkbox
            checked={!!allGoalsAreSelected}
            onChange={() => noop()}
            name={`checkbox-category-${categoryId}`}
            color='default'
            value={categoryId}
          />
        )}
        <div className={classes.categoryTitle}>
          <Text
            text={shortName}
            customFontSize='20px'
          />
        </div>
      </div>
      <div className={classes.line} />
      <div className={classes.goalsContainer}>
        {categoryGoals.map((goal, index) => {
          return (
            <DynamicPlanningGoal
              goal={goal}
              onEditGoal={(goal) => onEditGoal(goal)}
              key={index}
              allowToggle={displayToggle}
            />
          )
        })}
      </div>
      {displayCreateButton && (
        <RoundedButton
          size={BUTTON_SIZES.extraSmall}
          variant={BUTTON_VARIANT.text}
          onClick={createNewGoal}
          disabled={isLoading}
        >
          <Text
            text={isLoading ? 'Loading...' : '+ ' + createButtonText}
            customFontSize='12px'
          />
        </RoundedButton>
      )}
    </div>
  )
}

GoalCategoryDetailItem.propTypes = {
  goalCategory: PropTypes.objectOf(PropTypes.shape({
    categoryId: PropTypes.number,
    shortName: PropTypes.string,
    primaryColor: PropTypes.string
  })),
  displayCreateButton: PropTypes.bool,
  createButtonText: PropTypes.string,
  onEditGoal: PropTypes.func,
  allowToggle: PropTypes.bool
}

export default GoalCategoryDetailItem
