import React, { createContext, useCallback, useContext, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { useIdleTimer } from 'react-idle-timer'
import { useAppContext, useSetIsOverviewFullScreen } from '../../../redux/slices/appContext'

export const BRAND_LOGO_POSITIONS = {
  bottomLeft: 'bottomLeft',
  bottomRight: 'bottomRight',
  topLeft: 'topLeft',
  topRight: 'topRight'
}

export const defaultBrandLogoStyles = {
  opacity: 0.75,
  width: '150px',
  position: 'absolute',
  zIndex: 100
}

const mapItemProperties = (root, item) => {
  switch (item.props.type) {
    case 'link':
      return {
        to: `${root}/${item.props.viewPath}`,
        view: item.props.viewPath,
        ...item.props
      }
    case 'sso':
      return {
        to: `${root}/${item.props.viewPath}`,
        view: item.props.viewPath,
        ...item.props
      }
    default:
      return {
        to: `${root}/${item.props.viewPath}`,
        view: item.props.viewPath,
        ...item.props
      }
  }
}

function brandLogoStylesByPosition (position) {
  switch (position) {
    case BRAND_LOGO_POSITIONS.bottomRight:
      return {
        bottom: 10,
        right: 15
      }
    case BRAND_LOGO_POSITIONS.topLeft:
      return {
        top: 75,
        left: 15
      }
    case BRAND_LOGO_POSITIONS.topRight:
      return {
        top: 75,
        right: 15
      }
    case BRAND_LOGO_POSITIONS.bottomLeft:
    default:
      return {
        bottom: 10,
        left: 15
      }
  }
}

export function parseBrandLogoStyles (position = BRAND_LOGO_POSITIONS.bottomLeft, additionalStyles = defaultBrandLogoStyles) {
  const logoStyles = brandLogoStylesByPosition(position)
  return {
    ...defaultBrandLogoStyles,
    ...logoStyles,
    ...additionalStyles
  }
}

export const DashboardContext = createContext({})

const IDLE_TIMEOUT_MS = 10000
const useDashboardSettingsValues = (settings, dashboardItems, root, clientAccountSettings) => {
  /* Logo styles */
  const logo = useMemo(() => settings?.logo ?? {}, [settings?.logo])

  const parsedStyles = useMemo(() => {
    const { logoPosition, additionalStyles, isPublicResource, src } = logo
    return {
      ...parseBrandLogoStyles(logoPosition, additionalStyles),
      src,
      isPublicResource
    }
  }, [logo])

  /* Highlighted View - when one view is highlighted, others are lowlighted, also minimap displays which is highlighted */
  const [highlightedView, setHighlightedView] = useState(null)
  const changeHighlightedView = useCallback((view, isHighlighted) => {
    setHighlightedView(isHighlighted ? view : null)
  }, [setHighlightedView])

  /* Idle timeouts - portions of the ui fade away when use is not doing anything for a small period of time */
  const [idle, setIdle] = useState(false)
  const onIdle = useCallback(() => setIdle(true), [setIdle])
  const onActive = useCallback(() => setIdle(false), [setIdle])
  useIdleTimer({
    timeout: IDLE_TIMEOUT_MS,
    onIdle,
    onActive,
    onAction: onActive
  })

  /* Expand / Collapse the view */
  const { isOverviewFullScreen } = useAppContext()
  const setIsOverviewFullScreen = useSetIsOverviewFullScreen()
  const onExpandToggle = useCallback(() => {
    setIsOverviewFullScreen(!isOverviewFullScreen)
  }, [isOverviewFullScreen, setIsOverviewFullScreen])

  return useMemo(() => {
    return {
      settings: {
        ...settings,
        logo: parsedStyles
      },
      items: (dashboardItems ?? []).map((item) => mapItemProperties(root, item)),
      root,
      highlightedView,
      changeHighlightedView,
      idle,
      expanded: isOverviewFullScreen,
      onExpandToggle,
      clientAccountSettings
    }
  }, [settings, parsedStyles, dashboardItems, root, highlightedView, changeHighlightedView, idle, isOverviewFullScreen, onExpandToggle, clientAccountSettings])
}

export const useDashboardSettings = () => {
  return useContext(DashboardContext)
}

const ClientDashboardContextProvider = ({ settings, dashboardItems, root, children, clientAccountSettings }) => {
  const settingsValues = useDashboardSettingsValues(settings, dashboardItems, root, clientAccountSettings)

  return (
    <DashboardContext.Provider value={settingsValues}>
      {children}
    </DashboardContext.Provider>
  )
}

export const clientAccountSettingsProp = PropTypes.shape({
  label: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
  title: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
  subtitle: PropTypes.string,
  filters: PropTypes.object,
  columns: PropTypes.array
})

ClientDashboardContextProvider.propTypes = {
  settings: PropTypes.object.isRequired,
  dashboardItems: PropTypes.array.isRequired,
  root: PropTypes.string,
  children: PropTypes.node,
  clientAccountSettings: clientAccountSettingsProp
}

export default ClientDashboardContextProvider
