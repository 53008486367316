import React, { useImperativeHandle } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { Grid } from '@material-ui/core'
import PropTypes from 'prop-types'
import SydLabel from '../../../../commonDesign/SydLabel'
import Select from '../../../../molecules/Select'

const AssignmentForm = React.forwardRef(function AssignmentForm ({ statusTemplateOptions, client, assignment, disabled }, ref) {
  const form = useForm({
    defaultValues: {
      clientId: client.clientId,
      statusTemplateId: assignment.statusTemplateId
    }
  })

  useImperativeHandle(ref, () => ({ form }), [form])

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={6}>
        <Controller
          name='statusTemplateId'
          control={form.control}
          render={(f) => (
            <SydLabel label='Status Template'>
              <Select
                disabled={disabled}
                options={statusTemplateOptions}
                variant='outlined-rounded'
                fullWidth
                {...f.field}
              />
            </SydLabel>
          )}
        />
      </Grid>
    </Grid>
  )
})

AssignmentForm.propTypes = {
  statusTemplateOptions: PropTypes.array,
  client: PropTypes.object,
  assignment: PropTypes.object,
  disabled: PropTypes.bool
}

export default AssignmentForm
