import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { useComments } from '../Comments/CommentContext'
import Icon from '../../atoms/Icon'
import { useAppContext } from '../../../redux/slices/appContext'
import { usePolicy } from '../../../hooks/usePolicy'
import { useInternalReportContext } from './InternalStatusReportContext'
import StatusReportCommentTitle from './StatusReportCommentTitle'

const useStyles = makeStyles((theme) => ({
  commentButton: {
    transition: 'opacity 200ms ease-in-out',
    opacity: 0,
    '&:hover': {
      cursor: 'pointer'
    }
  },
  wrapper: {
    '&:hover $commentButton': {
      opacity: 1
    }
  }
}))

function EditButton ({ title, item, parent }) {
  const { createCommentThread } = useComments()
  const { onRefetch, statuses } = useInternalReportContext()
  const policyResult = usePolicy('comments_add')
  const classes = useStyles()
  const { clientId } = useAppContext()
  const handleClick = useCallback(() => {
    createCommentThread(title, {
      clientId,
      levelTypeId: 278,
      levelId: item.statusTemplateItemId,
      title: StatusReportCommentTitle,
      titleProps: { item, parent, statuses, clientId },
      onRefetch
    })
  }, [title, clientId, item, createCommentThread, onRefetch, parent, statuses])

  if (policyResult) {
    return (
      <div className={classes.wrapper}>
        <div className={classes.commentButton} role='button' onClick={handleClick}>
          <Icon name='add' />
        </div>
      </div>
    )
  }

  return null
}

EditButton.propTypes = {
  title: PropTypes.string,
  item: PropTypes.any,
  parent: PropTypes.any
}

export default React.memo(EditButton)
