import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column'
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
    gap: '1rem'
  },
  value: {
    marginLeft: 'auto'
  }
}))

const DefaultPropsCell = ({ value }) => {
  const classes = useStyles()

  if (!value) return 'No definition available'

  return (
    <div className={classes.container}>
      {Object.entries(value).map(([key, val]) => {
        return (
          <div key={key} className={classes.container}>
            <div className={classes.content}>
              <div>{key}</div>
              <div className={classes.value}>
                {JSON.stringify(val, null, 2)}
              </div>
            </div>
          </div>
        )
      })}
    </div>
  )
}

DefaultPropsCell.propTypes = {
  value: PropTypes.object
}

export default DefaultPropsCell
