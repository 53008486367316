import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Card, CardActions, CardContent, Grid, Paper, Typography } from '@material-ui/core'
import clsx from 'clsx'
import { noop } from 'lodash'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import Icon from '../../../atoms/Icon'
import Image from '../../../atoms/Image'
import RoundedButton from '../../../atoms/RoundedButton'
import { ALERT_SEVERITY, BUTTON_VARIANT, ICON_NAMES, TEXT_VARIANTS } from '../../../../constants'
import Menu from '../../../molecules/Menu'
import { integrations } from '../../../../service'
import SnackAlert from '../../../molecules/SnackAlert/SnackAlert'
import { useSetAppContext } from '../../../../redux/slices/appContext'

export const CARD_HEIGHT = '320px'
export const CARD_WIDTH = '300px'

const buttonBase = ({
  color,
  backgroundColor,
  hoverColor
}) => ({
  flex: '1 1 50%',
  margin: '0 0.5rem',
  color,
  backgroundColor,
  '&:hover': {
    backgroundColor: hoverColor,
    boxShadow: 'none'
  }
})

const useStyles = makeStyles((theme) => ({
  paper: ({ hideMenu }) => ({
    backgroundColor: theme.palette.ghostWhite,
    '&:hover': {
      cursor: 'default'
    },
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    minHeight: CARD_HEIGHT,
    maxHeight: CARD_HEIGHT,
    height: CARD_HEIGHT
  }),
  card: {
    textDecoration: 'none !important',
    textAlign: 'center'
  },
  cardRoot: ({ hideMenu }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    width: '100%',
    marginTop: hideMenu ? '30px' : 'inherit'
  }),
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '230px'
  },
  primaryButton: buttonBase({
    color: theme.palette.getContrastText(theme.palette.cloudBurst),
    backgroundColor: theme.palette.cloudBurst,
    hoverColor: theme.palette.mirage
  }),
  outlinedButton: buttonBase({
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.ghostWhite
  }),
  disabledHiddenButton: {
    backgroundColor: `${theme.palette.ghostWhite} !important`,
    border: 'unset !important',
    color: `${theme.palette.primary.main} !important`
  },
  largeTitle: {
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(2)
    },
    fontWeight: 500,
    fontSize: '19px'
  },
  mediumTitle: {
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(2)
    }
  },
  cardTitle: {
    marginBottom: '1rem'
  },
  cardDisabled: {
    opacity: '0.4',
    cursor: 'unset !important',
    backgroundColor: theme.palette.gallery,
    '&:hover': {
      backgroundColor: theme.palette.gallery
    },
    '& > a': {
      cursor: 'unset !important'
    }
  },
  menuIcon: {
    display: 'flex',
    float: 'right',
    cursor: 'pointer',
    marginTop: '5px',
    marginRight: '18px',
    color: theme.palette.primary.main
  },
  comingSoonBtn: {
    paddingLeft: 0,
    paddingRight: 0
  }
}))

const IntegrationCard = ({
  iconName,
  iconProps,
  title,
  subtitle,
  showButton,
  authAction,
  isAuthorized,
  manageAction,
  displayManageButton,
  hideMenu,
  comingSoon,
  comingSoonText,
  imgUrl,
  name = ''
}) => {
  const classes = useStyles({ hideMenu })
  const setAppContext = useSetAppContext()
  const [refreshData, setRefreshData] = useState(false)
  const [alert, setAlert] = useState(null)

  const syncOnDemand = useCallback(async () => {
    integrations[name].syncOnDemand()
      .then(() => {
        setAlert({
          openAlert: true,
          alertMessage: 'Refreshing data',
          alertSeverity: ALERT_SEVERITY.success
        })
        setTimeout(() => {
          setRefreshData(true)
          setAlert(null)
        }, 10000)
      })
      .catch((ex) => { console.error(ex) })
  }, [setAlert, name])

  useEffect(() => {
    setAppContext({ refetchData: refreshData })
    return () => setAppContext({ refetchData: false })
  }, [refreshData, setAppContext])

  const renderMenuIcon = useCallback(
    ({ setAnchorEl }) => (
      <div className={classes.menuIcon} onClick={setAnchorEl}>
        <Icon name={ICON_NAMES.threeDots} customSize='1.5rem' />
      </div>
    ),
    [classes.menuIcon]
  )

  const largeTextProps = useMemo(() => ({
    variant: TEXT_VARIANTS.h1,
    className: clsx(classes.cardTitle, classes.largeTitle)
  }), [classes.cardTitle, classes.largeTitle])

  const mediumTextProps = useMemo(() => ({
    variant: TEXT_VARIANTS.h4,
    className: clsx(classes.cardTitle, classes.mediumTitle)
  }), [classes.cardTitle, classes.mediumTitle])

  const getThreadMenuOptions = useCallback(
    () => [
      {
        iconName: ICON_NAMES.database,
        label: 'Refresh Data',
        onClick: syncOnDemand
      },
      {
        iconName: ICON_NAMES.security,
        label: 'Reauthorize',
        onClick: () => {
          if (alert) {
            setAlert(null)
          }
          authAction()
        }
      }
    ],
    [syncOnDemand, authAction, alert]
  )

  const options = getThreadMenuOptions()

  const CardLayout = ({
    children,
    classes,
    disabled,
    onClick
  }) => useMemo(() => (
    <Grid item xs={12} sm={6} md={3} lg={2} style={{ minWidth: CARD_WIDTH, maxWidth: CARD_WIDTH }}>

      <Paper elevation={0} onClick={onClick} className={clsx(classes.paper, { [classes.cardDisabled]: disabled })}>
        <Card elevation={0} className={classes.card} classes={{ root: classes.cardRoot }}>
          {isAuthorized && !hideMenu && <Menu options={options}>{renderMenuIcon}</Menu>}
          <CardContent className={classes.cardContent}>
            {children}
          </CardContent>
        </Card>
        <CardActions style={{ height: '50px' }}>
          {!isAuthorized && !comingSoon && (
            <div style={{ visibility: showButton ? 'visible' : 'hidden' }}>
              <RoundedButton
                variant={BUTTON_VARIANT.outlined}
                className={classes.primaryButton}
                onClick={authAction}
              >
                Authorize
              </RoundedButton>
            </div>
          )}
          {isAuthorized && !comingSoon && (
            <RoundedButton
              variant={BUTTON_VARIANT.outlined}
              className={classes.outlinedButton}
              disabled={!displayManageButton}
              onClick={manageAction}
              disabledClassName={classes.disabledHiddenButton}
            >
              {displayManageButton ? 'Manage' : 'Authorized'}
            </RoundedButton>
          )}
          {comingSoon && (
            <RoundedButton
              variant={BUTTON_VARIANT.outlined}
              className={clsx(classes.outlinedButton, classes.comingSoonBtn)}
              disabled
              disabledClassName={classes.disabledHiddenButton}
            >
              {comingSoonText || 'Coming Soon'}
            </RoundedButton>
          )}
        </CardActions>
      </Paper>
    </Grid>
  ), [
    classes.paper,
    classes.card,
    classes.cardContent,
    classes.cardDisabled,
    classes.cardRoot,
    classes.primaryButton,
    classes.outlinedButton,
    classes.disabledHiddenButton,
    classes.comingSoonBtn,
    children,
    disabled,
    onClick
  ])

  return (
    <CardLayout classes={classes}>
      {imgUrl
        ? (<Image src={imgUrl} isPublicResource height={iconProps?.customHeight} />)
        : (<Icon name={iconName} {...iconProps} />)}
      <Typography {...largeTextProps}>{title}</Typography>
      <Typography {...mediumTextProps}>{subtitle}</Typography>
      {alert && <SnackAlert alert={alert} />}
    </CardLayout>
  )
}

IntegrationCard.propTypes = {
  name: PropTypes.string,
  iconName: PropTypes.string,
  iconProps: PropTypes.object,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  showButton: PropTypes.bool,
  authAction: PropTypes.func,
  isAuthorized: PropTypes.bool,
  isActive: PropTypes.bool,
  manageAction: PropTypes.func,
  displayManageButton: PropTypes.bool,
  hideMenu: PropTypes.bool,
  comingSoon: PropTypes.bool,
  comingSoonText: PropTypes.string,
  imgUrl: PropTypes.string
}

IntegrationCard.defaultProps = {
  name: '',
  iconName: '',
  iconProps: {},
  title: '',
  subtitle: '',
  manageAction: noop,
  displayManageButton: false,
  showButton: false,
  authAction: noop,
  isAuthorized: false,
  hideMenu: false,
  comingSoon: false,
  comingSoonText: undefined,
  imgUrl: undefined
}

export default IntegrationCard
