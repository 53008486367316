import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { Box } from '@material-ui/core'
import TextWithArrow from '../../molecules/TextWithArrow'
import TitleWithSubtitle from '../../molecules/TitleWithSubtitle'
import ClientAvatar from '../../atoms/ClientAvatar'
import Text from '../../atoms/Text'
import NumberFormat from '../../atoms/NumberFormat'
import { numeralByCase } from '../../../utils'

const useStyes = makeStyles(() => ({
  container: {
    margin: '0 0 0.5rem 0',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100%'
  },
  footer: {
    marginTop: 'auto',
    marginBottom: '1rem'
  },
  avatar: {
    margin: '1.875rem 0 0.6875rem'
  },
  body: {
    textAlign: 'center'
  }
}))

const ClientCardBody = ({ client }) => {
  const classes = useStyes()
  const arrowVariant = useMemo(() => {
    const { balanceChangeStatus } = client
    if (balanceChangeStatus === 'positive') return 'up'
    if (balanceChangeStatus === 'negative') return 'down'
    return balanceChangeStatus
  }, [client])

  return (
    <Box className={classes.container}>
      <Box className={classes.avatar}>
        <ClientAvatar
          client={client}
          src={client.profilePic}
          customSize='80px'
          useOneInitial
        />
      </Box>
      <TitleWithSubtitle
        alignment='center'
        title={
          <Text
            color='#212945'
            customFontSize='20px'
            customFontWeight='400'
            className={classes.body}
            text={client.longName}
          />
        }
      />
      <Box className={classes.footer}>
        <TextWithArrow
          arrowVariant={arrowVariant}
          textVariant='h1'
          textElement={
            <Text
              colo='#212945'
              customFontSize='20px'
              customFontWeigh='400'
              text={
                <NumberFormat
                  title={numeralByCase(client.endingValue || 0)}
                  number={client.endingValue || 0}
                  format='$0,0a'
                />
              }
            />
          }
        />
      </Box>
    </Box>
  )
}

ClientCardBody.propTypes = {
  client: PropTypes.shape({
    shortName: PropTypes.string,
    longName: PropTypes.string,
    profilePic: PropTypes.string,
    endingValue: PropTypes.number,
    balanceChangeStatus: PropTypes.string
  })
}

ClientCardBody.defaultProps = {
  client: {}
}

export default ClientCardBody
