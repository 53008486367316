import { useMemo, useState } from 'react'
import isEmpty from 'lodash/isEmpty'
import {
  DEFAULT_SELECT_SEARCH_PAGINATION,
  MILLISECONDS_BEFORE_SEARCH
} from '../constants'
import { useTransactionCodeTagSearch } from '../api/coreData'
import useDebouncedValue from './useDebounceValue'
import { getSearchQuery } from './helpers'

const SEARCH_FIELDS = ['displayName']

const defaultTransactionCodeTagsMapper = (data) => {
  return data.map((datum) => ({
    key: datum.transactionCodeTagId,
    value: datum.transactionCodeTagId,
    label: datum.displayName
  }))
}

const getQueryFilters = ({ query }) => getSearchQuery(SEARCH_FIELDS, query)

const useSearchTransactionTagsDebounced = ({
  query = {},
  queryOptions = {},
  defaultTransactionIds = [],
  debounceDelay = MILLISECONDS_BEFORE_SEARCH
}) => {
  const [queryText, setQuery] = useState('')
  const debouncedQuery = useDebouncedValue(queryText, debounceDelay)

  const { queryParams, queryParamOptions } = useMemo(() => {
    const queryParams = getQueryFilters({
      query: debouncedQuery
    })

    return {
      queryParams: {
        ...query,
        filters: {
          ...queryParams,
          ...(query?.filters || {})
        },
        ...DEFAULT_SELECT_SEARCH_PAGINATION
      },
      queryParamOptions: {
        mapper: defaultTransactionCodeTagsMapper,
        ...queryOptions
      }
    }
  }, [debouncedQuery, query, queryOptions])

  const { data, isLoading } = useTransactionCodeTagSearch(
    queryParams,
    queryParamOptions
  )

  const defaultOptionsQuery = useMemo(() => {
    return {
      queryParams: {
        filters: {
          transactionCodeTagId: defaultTransactionIds
        }
      },
      queryParamOptions: {
        mapper: defaultTransactionCodeTagsMapper,
        enabled: !isEmpty(defaultTransactionIds)
      }
    }
  }, [defaultTransactionIds])

  const { data: defaultOptions, isLoading: isLoadingDefaults } =
    useTransactionCodeTagSearch(
      defaultOptionsQuery.queryParams,
      defaultOptionsQuery.queryParamOptions
    )

  const isTransitioningOptions = isLoading || debouncedQuery !== queryText

  return {
    options: data,
    isLoading: isLoading || (isLoadingDefaults && !isEmpty(defaultTransactionIds)),
    onChangeQuery: setQuery,
    isSearchLoading: isTransitioningOptions,
    defaultOptions
  }
}

export default useSearchTransactionTagsDebounced
