import { useCallback, useEffect, useMemo, useState } from 'react'
import first from 'lodash/first'
import { useAvailableDates } from '../../../redux/slices/appContext'
import { useAlternatives, useAlternativesMultiple } from '../../../api/coreData'
import { updateTreeItemByIndexPath } from '../PerformanceTableV2/usePerformanceTreeData'

const compareDepth = (a, b) => b.depth - a.depth

const findNodeIndexPath = (tree, node, nodeIndexPath = []) => {
  for (let i = 0; i < tree.length; i++) {
    const item = tree[i]
    if (item.levelId === node.levelId) {
      nodeIndexPath.push(i)
      return nodeIndexPath
    }
    if (item?.subRows) {
      const subNodeIndexPath = findNodeIndexPath(item?.subRows, node, [
        ...nodeIndexPath,
        i
      ])
      if (subNodeIndexPath) return subNodeIndexPath
    }
  }
  return null
}

const findAndUpdateTreeItem = (tree, node, nodeOverrides = {}) => {
  const nodeIndexPath = findNodeIndexPath(tree, node)
  return updateTreeItemByIndexPath(tree, nodeIndexPath, nodeOverrides)
}

const findLevelGroupIndex = (groupings, item) => {
  const { levelGroupIndex } = groupings
    .map((levelGroup) => `${levelGroup}Id`)
    .reduce(
      (acc, levelGroupKey) => {
        if (acc.item[levelGroupKey]) {
          acc.levelGroupIndex++
        }
        return acc
      },
      { item, levelGroupIndex: -1 }
    )
  return levelGroupIndex
}

const findItemLevelGroup = (groupings, item) => {
  const levelGroupIndex = findLevelGroupIndex(groupings, item)
  const levelGroup = groupings[levelGroupIndex]
  return levelGroup
}

const getChildren = (groupings, parentItem, allItems) => {
  const levelGroup = findItemLevelGroup(groupings, parentItem)
  if (!levelGroup) return []
  return allItems.filter(
    (item) => item[`${levelGroup}Id`] === parentItem.levelId
  )
}

const getItemNextAdditionalFilters = (groupings, item) => {
  const levelGroupIndex = findLevelGroupIndex(groupings, item)
  const currentLevelGroup = groupings[levelGroupIndex]
  const nextLevelGroup = groupings[levelGroupIndex + 1]
  const additionalFilters = nextLevelGroup
    ? { [`${currentLevelGroup}Id`]: item.levelId }
    : {}
  return additionalFilters
}

const mapSubRows = ({
  parent,
  items,
  allItems,
  depth = 1,
  createRequestForGrouping,
  levelGroupings
}) => {
  if (depth > 10) return []
  return items.reduce((prev, curr) => {
    const nextDepth = depth + 1
    const children = getChildren(levelGroupings, curr, allItems).filter(
      ({ depth: _depth }) => _depth === nextDepth
    )

    const maxDepth = levelGroupings.length
    const levelGroup = findItemLevelGroup(levelGroupings, curr)
    const additionalFilters = {
      ...(parent?._next?.filters || {}),
      [`${levelGroup}Id`]: curr.levelId
    }

    const _next =
      nextDepth >= maxDepth
        ? null
        : createRequestForGrouping(
          levelGroupings[nextDepth],
          additionalFilters
        )

    curr._next = _next

    const subRows = mapSubRows({
      parent: curr,
      items: children,
      allItems,
      depth: nextDepth,
      createRequestForGrouping,
      levelGroupings
    })

    prev.push({
      ...curr,
      subRows,
      _subRowsFetched: !!subRows?.length
    })

    return prev
  }, [])
}

const traverseAndUpdateTree = (tree, item) => {
  const treeModified = tree.map((node) => ({
    ...node,
    ...item,
    subRows: node.subRows ? traverseAndUpdateTree(node.subRows, item) : []
  }))
  return treeModified
}

export const useAlternativesReport = ({
  expanded,
  groupings,
  initialDepth,
  queryParams,
  defaultFilter,
  initialExpandDepth,
  includeProjections,
  numProjectionPeriods
}) => {
  const [availableDates] = useAvailableDates()

  const createRequestForGrouping = useCallback(
    (levelGrouping, additionalFilters = undefined) => {
      const { accountCategoryIds, ...filters } = defaultFilter

      const levelTypeIndex = groupings.indexOf(levelGrouping)
      const levelTypes = groupings.slice(0, levelTypeIndex + 1)
      const { mainDate, min } = availableDates
      const startDate = min || mainDate
      const endDate = mainDate
      return {
        ...queryParams,
        dateRange: {
          startDate: mainDate,
          endDate: mainDate
        },
        filters: {
          ...(additionalFilters || {}),
          ...filters
        },
        includes: {
          ...(accountCategoryIds
            ? { supplementalAccountCategoryId: accountCategoryIds }
            : {}),
          performance: { startDate, endDate },
          balance: { startDate, endDate },
          cashFlowProjections: includeProjections ? {
            numPeriods: numProjectionPeriods
          } : undefined
        },
        levelType: levelTypes
      }
    },
    [availableDates, defaultFilter, queryParams, groupings, includeProjections, numProjectionPeriods]
  )

  const { baseQueries, totalsQuery } = useMemo(() => {
    const groupingRequestBase = groupings.slice(0, initialDepth)
    return {
      baseQueries: groupingRequestBase.map(createRequestForGrouping),
      totalsQuery: {
        ...createRequestForGrouping(first(groupings)),
        resultType: 'total'
      }
    }
  }, [groupings, initialDepth, createRequestForGrouping])

  const { results, fetchMore } = useAlternativesMultiple(baseQueries)

  const { data: totalsData } = useAlternatives(totalsQuery)

  const isAnyLevelLoading = useMemo(() => {
    return results?.reduce((acc, { isLoading }) => acc || isLoading, false)
  }, [results])

  const treeLinkedData = useMemo(() => {
    if (isAnyLevelLoading) return []

    const maxDepth = groupings.length - 1

    const flattenedResults = results
      .map(({ data }) => data)
      .reduceRight((acc, value, index) => {
        const mappedData = value.map((item) => {
          const levelGroupIndex = findLevelGroupIndex(groupings, item)
          const nextLevelGroup = groupings[levelGroupIndex + 1]
          const additionalFilters = getItemNextAdditionalFilters(groupings, item)

          const _next =
            index >= maxDepth && levelGroupIndex === -1
              ? null
              : createRequestForGrouping(nextLevelGroup, additionalFilters)

          return {
            ...item,
            depth: index,
            expanded: index < initialExpandDepth - 1,
            _next
          }
        })
        return [...acc, ...mappedData]
      }, [])
      .sort(compareDepth)

    const linkedResults = flattenedResults.reduce(
      (acc, value, index, array) => {
        if (value.depth > 0) return acc

        const children = getChildren(groupings, value, array)
        const nextLevel = children.filter((x) => x.depth === 1)

        value.subRows = mapSubRows({
          parent: value,
          items: nextLevel,
          allItems: children,
          depth: 1,
          createRequestForGrouping,
          levelGroupings: groupings,
          maxDepth
        })

        value._subRowsFetched = !!value.subRows?.length
        acc.push(value)
        return acc
      },
      []
    )

    return linkedResults
  }, [
    createRequestForGrouping,
    initialExpandDepth,
    isAnyLevelLoading,
    groupings,
    results
  ])

  const [statefulResult, setStatefulResult] = useState([])
  useEffect(() => {
    setStatefulResult(treeLinkedData)
  }, [setStatefulResult, treeLinkedData])

  const onLevelExpand = useCallback(
    async ({ original: item }) => {
      const nextQuery = item?._next

      if (!nextQuery) return
      if (item?._subRowsFetched) {
        setStatefulResult((prevState) => {
          return findAndUpdateTreeItem(prevState, item, {
            expanded: !item.expanded
          })
        })
        return
      }

      // Set loading state
      setStatefulResult((prevState) => {
        return findAndUpdateTreeItem(prevState, item, {
          _subRowsFetching: true
        })
      })

      try {
        // Request more data
        const subRows = await fetchMore(nextQuery)
        const nextDepth = item.depth + 1
        const maxDepth = groupings.length - 1
        const mappedDetails = (subRows || []).map((subDetail) => {
          const additionalFilters = getItemNextAdditionalFilters(
            groupings,
            subDetail
          )
          const _next =
            nextDepth === maxDepth
              ? null
              : createRequestForGrouping(groupings[nextDepth + 1], {
                ...item._next.filters,
                ...additionalFilters
              })
          return {
            ...subDetail,
            depth: nextDepth,
            expanded: false,
            _next
          }
        })

        // Set final state
        setStatefulResult((prevState) => {
          const prevStateModified = findAndUpdateTreeItem(prevState, item, {
            _subRowsFetching: false,
            _subRowsFetched: true,
            expanded: true,
            subRows: mappedDetails || []
          })
          return prevStateModified
        })
      } catch (error) {
        console.error(error)
        setStatefulResult((prevState) => {
          return findAndUpdateTreeItem(prevState, item, {
            _subRowsFetching: false
          })
        })
      }
    },
    [createRequestForGrouping, fetchMore, groupings]
  )

  const onExpandLevels = useCallback((expanded) => {
    setStatefulResult((prevState) => {
      return traverseAndUpdateTree(prevState, {
        expanded: Boolean(expanded)
      })
    })
  }, [])

  useEffect(() => {
    onExpandLevels(expanded)
  }, [expanded, onExpandLevels])

  return {
    total: totalsData?.total,
    data: statefulResult ?? [],
    isLoading: isAnyLevelLoading,
    onLevelExpand
  }
}
