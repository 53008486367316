import React from 'react'
import { Autocomplete } from '@material-ui/lab'
import { TextField, CircularProgress } from '@material-ui/core'
import PropTypes from 'prop-types'

const AutocompleteInput = ({
  name,
  placeholder,
  error,
  options,
  isLoading,
  className,
  classes,
  onChange,
  onInputChange,
  ...props
}) => {
  return (
    <Autocomplete
      multiple
      fullWidth
      options={options}
      loading={isLoading}
      placeholder={placeholder}
      filterSelectedOptions
      onChange={onChange}
      onInputChange={(_, newInputValue) => {
        onInputChange(newInputValue)
      }}
      error={error}
      disableCloseOnSelect
      renderInput={(params) => {
        params.InputProps.disableUnderline = true
        return (
          <TextField
            fullWidth
            {...params}
            className={className}
            placeholder={placeholder}
            classes={classes}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {isLoading ? (
                    <CircularProgress color='inherit' size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              )
            }}
          />
        )
      }}
      {...props}
    />
  )
}

AutocompleteInput.propTypes = {
  name: PropTypes.string,
  control: PropTypes.object,
  errors: PropTypes.object,
  options: PropTypes.array,
  isLoading: PropTypes.bool,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  classes: PropTypes.object,
  onInputChange: PropTypes.func,
  onChange: PropTypes.func,
  error: PropTypes.bool
}

export default AutocompleteInput
