import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import DataHomeHeader from '../shared/DataHomeHeader'

const useStyles = makeStyles(theme => ({
  dataHomeItemGroup: {
    display: 'flex',
    flexDirection: 'column'
  },
  dataHomeItemGroupList: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: '20px'
  }
}))

const DataHomeItemGroup = ({ name, children }) => {
  const classes = useStyles()

  return (
    <div className={classes.dataHomeItemGroup}>
      <DataHomeHeader>{name}</DataHomeHeader>
      <div className={classes.dataHomeItemGroupList}>
        {children}
      </div>
    </div>
  )
}

DataHomeItemGroup.propTypes = {
  name: PropTypes.string,
  children: PropTypes.node
}

export default DataHomeItemGroup
