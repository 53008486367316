import React from 'react'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import { makeStyles } from '@material-ui/core'
import { Link } from 'react-router-dom'
import isEmpty from 'lodash/isEmpty'
import { useContextualClientContext } from '../context/ContextualClientContext'
import Icon from '../../../atoms/Icon'
import { ICON_NAMES } from '../../../../constants'
import AdvisorHomeEmptySection from '../../AdvisorHome/AdvisorHomeEmptySection'

const mapMeetingDate = ({ meetingId, startDate, endDate }, linkBaseUrl) => {
  return {
    date: dayjs(startDate).format('ddd M/DD'),
    start: dayjs(startDate).format('h:mm a'),
    end: dayjs(endDate).format('h:mm a'),
    timezone: dayjs(endDate).format('z'),
    url: linkBaseUrl ? `${linkBaseUrl}/${meetingId}/view` : '#'
  }
}

const useStyles = makeStyles(() => ({
  list: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    '& span': {
      fontWeight: 'bold',
      fontSize: '0.875rem'
    }
  },
  item: {
    display: 'flex',
    gap: '0.875rem',
    alignItems: 'center'
  }
}))

const ClientMeetings = ({ linkBaseUrl, emptyStateLabel }) => {
  const classes = useStyles()
  const { client: { meetings = [] } = {}, isLoading } =
    useContextualClientContext()

  if (isEmpty(meetings) && !isLoading) {
    return (
      <AdvisorHomeEmptySection
        title='No meetings'
        description={emptyStateLabel}
        icon={ICON_NAMES.paperCalendar}
      />
    )
  }

  return (
    <div className={classes.list}>
      {meetings.map((meeting) => {
        const { date, start, end, timezone, url } = mapMeetingDate(
          meeting,
          linkBaseUrl
        )
        return (
          <Link key={meeting.meetingId} to={url} className={classes.item}>
            <Icon name={ICON_NAMES.calendar} customSize='1.2rem' />
            <span>{`${date} ${start} - ${end} ${timezone}`}</span>
          </Link>
        )
      })}
    </div>
  )
}

ClientMeetings.propTypes = {
  linkBaseUrl: PropTypes.string,
  emptyStateLabel: PropTypes.string
}

ClientMeetings.defaultProps = {
  linkBaseUrl: null,
  emptyStateLabel: 'Looks like you have no upcoming meetings'
}

export default ClientMeetings
