import React, { useCallback, useContext, useMemo } from 'react'
import PropTypes from 'prop-types'
import DrawerPanel from './DrawerPanel'

const defaultContextValue = () => ({
  teamMember: null,
  schema: null,
  open: () => {}
})

const TeamMemberDrawerContext = React.createContext(defaultContextValue())

export const useTeamMemberDrawer = () => {
  return useContext(TeamMemberDrawerContext)
}

function TeamMemberDrawer ({ children }) {
  const [teamMember, setTeamMember] = React.useState(null)
  const [schema, setSchema] = React.useState(null)
  const [isOpen, setIsOpen] = React.useState(false)
  const open = useCallback((_teamMember, _schema) => {
    setTeamMember(_teamMember)
    setSchema(_schema)
    setIsOpen(true)
  }, [setTeamMember, setSchema, setIsOpen])

  const close = useCallback(() => {
    setIsOpen(false)
    setTeamMember(null)
    setSchema(null)
  }, [setTeamMember, setSchema, setIsOpen])

  const contextValue = useMemo(() => ({
    teamMember,
    schema,
    isOpen,
    open,
    close
  }), [teamMember, schema, isOpen, open, close])

  return (
    <TeamMemberDrawerContext.Provider value={contextValue}>
      {children}
      <DrawerPanel />
    </TeamMemberDrawerContext.Provider>
  )
}

TeamMemberDrawer.propTypes = {
  children: PropTypes.node.isRequired
}

export default TeamMemberDrawer
