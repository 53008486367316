import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import {
  alpha,
  Button,
  makeStyles,
  ClickAwayListener
} from '@material-ui/core'
import isEmpty from 'lodash/isEmpty'
import { animated, useSpring } from 'react-spring'
import { ICON_NAMES } from '../../constants'
import { useToggle } from '../../hooks'
import Icon from '../atoms/Icon'
import Text from '../atoms/Text'
import AutoCompleteSearchMultipleInput from './AutoCompleteSearchMultipleInput'

const useStyles = makeStyles((theme) => ({
  pillTitle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },
  searchContainer: {
    display: 'flex'
  },
  search: {
    display: 'flex',
    position: 'relative',
    zIndex: '1102',
    height: '2.8rem',
    borderRadius: '1.5rem',
    backgroundColor: alpha(theme.palette.gray.dark, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.gray.dark, 0.25)
    },
    marginLeft: '0.5rem',
    marginRight: '2rem'
  },
  pillSelect: {
    border: '1px solid #D7DCE1',
    borderRadius: '30px',
    padding: '8px 12px',
    gap: '8px'
  },
  subtitle: {
    marginLeft: '8px',
    marginRight: '8px'
  }
}))

const getTitle = ({ title, filters, classes, optionTitleKey }) => {
  let subtitle = 'All'
  if (!isEmpty(filters)) {
    if (filters.length === 1) {
      subtitle = ` ${filters[0][optionTitleKey]}`
    } else if (filters.length === 2) {
      subtitle = ` ${filters[0][optionTitleKey]}, ${filters[1][optionTitleKey]}`
    } else {
      subtitle = ` ${filters[0][optionTitleKey]}, ${filters[1][optionTitleKey]}, +${filters.length - 1}`
    }
  }
  return (
    <div className={classes.pillTitle}>
      <Text text={title} variant='subtitle1' color='#9296A5' customFontSize='10px' />
      <Text text={subtitle} variant='subtitle1' customFontSize='10px' className={classes.subtitle} />
    </div>
  )
}

const SelectWithAutoSearch = ({
  options,
  loading,
  onChange,
  optionValueKey,
  optionTitleKey,
  optionSubtitleKey,
  placeholder,
  onSubmitSearch,
  title,
  filters
}) => {
  const classes = useStyles()
  const [open, toggleOpen] = useToggle()

  const searchInputSpringStyles = useSpring({
    opacity: open ? 1 : 0,
    width: open ? '200px' : '0px'
  })

  const buttonTitle = useMemo(() => {
    return getTitle({ title, filters, classes, optionTitleKey })
  }, [title, filters, classes, optionTitleKey])

  return (
    <div>
      <Button
        id='basic-button'
        onClick={toggleOpen}
        className={classes.pillSelect}
        classes={{
          root: classes.pillSelect
        }}
      >
        {buttonTitle}
        <Icon
          onClick={toggleOpen}
          name={open ? ICON_NAMES.chevronUp : ICON_NAMES.chevronDown}
        />
      </Button>
      {open && (
        <ClickAwayListener onClickAway={toggleOpen}>
          <div className={classes.searchContainer}>
            <animated.div className={classes.search} style={searchInputSpringStyles}>
              <AutoCompleteSearchMultipleInput
                width='16rem'
                optionValueKey={optionValueKey}
                optionTitleKey={optionTitleKey}
                optionSubtitleKey={optionSubtitleKey}
                recentOptionValueKey={optionValueKey}
                recentOptionTitleKey={optionTitleKey}
                placeholder={placeholder}
                options={options}
                loading={loading}
                onChange={onChange}
                onSubmitSearch={onSubmitSearch}
                primaryButtonLabel='Filter'
                existingSelectedOptions={filters}
                onClose={toggleOpen}
                showRecents
              />
            </animated.div>
          </div>
        </ClickAwayListener>
      )}
    </div>
  )
}

SelectWithAutoSearch.propTypes = {
  options: PropTypes.array,
  loading: PropTypes.bool,
  onChange: PropTypes.func,
  optionValueKey: PropTypes.string,
  optionTitleKey: PropTypes.string,
  optionSubtitleKey: PropTypes.string,
  placeholder: PropTypes.string,
  onSubmitSearch: PropTypes.func,
  title: PropTypes.string,
  filters: PropTypes.array
}

SelectWithAutoSearch.defaultProps = {
}

export default SelectWithAutoSearch
