import React from 'react'
import FadeIn from '../../../../molecules/Transitions/FadeIn'
import PersonalSpace from '../../shared/PersonalSpace'
import { useAdvisorUserDetails } from '../AdvisorUserFormContext'
import SecuritySummarySection from './SecuritySummarySection'
import SecurityDetailsSection from './SecurityDetailsSection'

function SecurityTab () {
  const { user } = useAdvisorUserDetails()

  return (
    <FadeIn>
      <SecuritySummarySection userId={user.userId} />
      <SecurityDetailsSection userId={user.userId} />
      <PersonalSpace />
    </FadeIn>
  )
}

export default SecurityTab
