import React, { useMemo, useRef } from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@material-ui/core'
import FadeIn from '../../../molecules/Transitions/FadeIn'
import SydButton from '../../../commonDesign/Button'
import SecurityQuestionDialog from './SecurityQuestionDialog'

const authenticatorKey = 'security_question'

function SecurityQuestionFactor ({ authenticators }) {
  const dialogRef = useRef()

  const { allowed, authenticator, enrollment } = useMemo(() => {
    if (!authenticators) return { allowed: false }
    const authenticator = authenticators.find(x => x.key === authenticatorKey)
    if (!authenticator) return { allowed: false }

    const enrollment = authenticator._embedded.enrollments.at(0)
    return {
      allowed: authenticator.enrollable,
      authenticator,
      enrollment
    }
  }, [authenticators])

  if (!allowed) return null

  const buttonLabel = enrollment ? 'Change Question' : 'Add Question'
  const actionLabel = enrollment ? 'Update your' : 'Add a'
  return (
    <>
      <Grid item xs={12} md={4}>
        <FadeIn className='__factor-card'>
          <div className='__factor-header'>
            <div className='__factor-name'>Security Question</div>
            <div className='__factor-description'>{actionLabel} security question by clicking the <strong>{buttonLabel}</strong> button, note that your password will be required to make this change.</div>
          </div>
          <div className='__factor-action'>
            <SydButton variant='primary' size='md' onClick={() => dialogRef.current.open()}>{buttonLabel}</SydButton>
          </div>
        </FadeIn>
      </Grid>
      <SecurityQuestionDialog ref={dialogRef} authenticator={authenticator} enrollment={enrollment} />
    </>
  )
}

SecurityQuestionFactor.propTypes = {
  authenticators: PropTypes.array
}

export default SecurityQuestionFactor
