import React, { forwardRef, useImperativeHandle, useMemo } from 'react'
import PropTypes from 'prop-types'
import { makeStyles, Grid, TextField } from '@material-ui/core'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import FadeIn from '../../../../molecules/Transitions/FadeIn'

const useStyles = makeStyles((theme) => ({
  userInfoForm: {
    maxWidth: '800px',
    display: 'flex',
    justifyContent: 'center',
    padding: '10px',
    '& p': {
      color: `${theme.palette.primary.dark}`
    }
  },
  inputLabel: {
    color: theme.palette.black,
    fontWeight: 600,
    '& span': {
      color: theme.palette.black
    }
  }
}))

const useFormValues = (wo) => {
  const defaultValues = useMemo(() => {
    return {
      userId: wo.userId,
      externalUserId: wo.externalUserId,
      firstName: wo?.firstName ?? '',
      lastName: wo?.lastName ?? ''
    }
  }, [wo])

  const formMethods = useForm({
    mode: 'onChange',
    defaultValues
  })

  return formMethods
}

const WealthOwnerInfoForm = forwardRef(function WealthOwnerInfoForm ({ wealthOwner }, ref) {
  const classes = useStyles()
  const { handleSubmit, formState, ...formValues } = useFormValues(wealthOwner)
  const labelProps = {
    className: classes.inputLabel
  }

  useImperativeHandle(ref, () => ({
    handleSubmit
  }), [handleSubmit])

  return (
    <FormProvider handleSubmit={handleSubmit} formState={formState} {...formValues}>
      <FadeIn className={classes.userInfoForm}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <Controller
              name='firstName'
              rules={{ required: true, maxLength: 30 }}
              render={({ field, fieldState }) => (
                <TextField
                  label='First Name'
                  fullWidth
                  helperText='Required, 30 max characters'
                  error={(formState.isSubmitted || fieldState.isDirty || fieldState.isTouched) && fieldState.error}
                  inputProps={field}
                  InputLabelProps={labelProps}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <Controller
              name='lastName'
              rules={{ required: true, maxLength: 30 }}
              render={({ field, fieldState }) => (
                <TextField
                  label='Last Name'
                  fullWidth
                  helperText='Required, 30 max characters'
                  error={(formState.isSubmitted || fieldState.isDirty || fieldState.isTouched) && fieldState.error}
                  inputProps={field}
                  InputLabelProps={labelProps}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <TextField
              label='Email'
              fullWidth
              disabled
              value={wealthOwner.email}
              InputProps={{ disableUnderline: true }}
              InputLabelProps={labelProps}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <TextField
              label='External User Id'
              fullWidth
              disabled
              value={wealthOwner.externalUserId}
              InputProps={{ disableUnderline: true }}
              InputLabelProps={labelProps}
            />
          </Grid>
        </Grid>
      </FadeIn>
    </FormProvider>
  )
})

WealthOwnerInfoForm.propTypes = {
  wealthOwner: PropTypes.shape({
    userId: PropTypes.number,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    externalUserId: PropTypes.string
  })
}

export default WealthOwnerInfoForm
