import React from 'react'
import PropTypes from 'prop-types'
import { AdvisorHomeContext, useAdvisorHomeValues } from '../../organisms/AdvisorHome/AdvisorHomeContext'

const AdvisorHomeContextProvider = ({ children, scope }) => {
  const values = useAdvisorHomeValues(scope)

  return (
    <AdvisorHomeContext.Provider value={values}>
      {children}
    </AdvisorHomeContext.Provider>
  )
}

AdvisorHomeContextProvider.propTypes = {
  children: PropTypes.node,
  scope: PropTypes.object
}

export default AdvisorHomeContextProvider
