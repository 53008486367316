import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { usePolicy } from '../../../../../hooks/usePolicy'
import { ADMIN_ROUTES } from '../../../../../constants'
import { useClientAccountsActions } from './ClientAccountsTableContext'

function AccountNameCell ({ value, row }) {
  const { editing } = useClientAccountsActions()
  const canEditAccounts = usePolicy('admin_view_accts')
  const accountId = row.original?.accountId
  if (canEditAccounts && !editing) {
    return (
      <div className='__acct-cell'>
        <Link to={`${ADMIN_ROUTES.ACCOUNTS}/${accountId}`}>{value || '--'}</Link>
      </div>
    )
  }
  return (
    <div className='acct-cell'>
      <span>{value || '--'}</span>
    </div>
  )
}

AccountNameCell.propTypes = {
  value: PropTypes.any,
  row: PropTypes.any
}

export default AccountNameCell
