import React from 'react'
import PropTypes from 'prop-types'
import { useSalesforceTasks } from '../../../../../api/salesforce'
import { useRefetchData } from '../../../../../hooks/useRefetchData'
import { useAppContext } from '../../../../../redux/slices/appContext'

const SalesforceTaskProvider = ({ name, query, children, index }) => {
  const {
    data,
    isLoading,
    error,
    refetch
  } = useSalesforceTasks(query, { functionName: name, index })

  const { refetchData } = useAppContext()
  useRefetchData(refetchData, refetch)

  if (error) {
    return <div>Sorry, there was an error</div>
  }

  if (isLoading) {
    return <div>Loading...</div>
  }

  return children(data, { isLoading, error })
}

SalesforceTaskProvider.propTypes = {
  name: PropTypes.string,
  query: PropTypes.object,
  children: PropTypes.func.isRequired,
  index: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
}

SalesforceTaskProvider.defaultProps = {
  index: 0
}

export default SalesforceTaskProvider
