import React from 'react'
import {
  Box,
  Button,
  CircularProgress,
  makeStyles,
  Typography,
  withStyles
} from '@material-ui/core'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import clsx from 'clsx'
import { CONFIRMATION_TYPE, TEXT_VARIANTS } from '../../constants'
import Modal from './Modal'

const buttonBase = ({ color, backgroundColor, hoverColor }) => ({
  flex: '1 1 50%',
  margin: '0 0.5rem',
  color,
  backgroundColor,
  '&:hover': {
    backgroundColor: hoverColor,
    boxShadow: 'none'
  }
})

const useStyle = makeStyles((theme) => ({
  modalContainer: ({ width }) => ({
    borderRadius: '0.5rem',
    padding: '1.5rem 1rem',
    minWidth: '20rem',
    ...(width ? { width } : {})
  }),
  circularProgress: {
    color: theme.palette.gray.main,
    position: 'absolute',
    marginLeft: '0.5rem',
    right: '10%'
  },
  actions: {
    display: 'inline-flex',
    width: '100%',
    marginTop: '1.5rem'
  },
  dangerButton: buttonBase({
    color: theme.palette.getContrastText(theme.palette.error.primary),
    backgroundColor: theme.palette.error.primary,
    hoverColor: theme.palette.error.dark
  }),
  primaryButton: buttonBase({
    color: theme.palette.getContrastText(theme.palette.cloudBurst),
    backgroundColor: theme.palette.cloudBurst,
    hoverColor: theme.palette.mirage
  }),
  cancelButton: buttonBase({
    color: theme.palette.getContrastText(theme.palette.gray.dark),
    backgroundColor: theme.palette.gray.dark,
    hoverColor: theme.palette.gray.darker
  })
}))

const RoundedButton = withStyles(() => ({
  root: {
    boxShadow: 'none',
    textTransform: 'none',
    paddingRight: '1.5rem',
    paddingLeft: '1.5rem',
    lineHeight: 1.5,
    borderRadius: '3rem',
    fontWeight: 'bold',
    whiteSpace: 'nowrap',
    position: 'relative',
    '&:active': {
      boxShadow: 'none',
      borderColor: '#005cbf'
    }
  }
}))(Button)

const ConfirmationModal = ({
  backdropStyles,
  open,
  width,
  container,
  containerClassName,
  onCancel,
  onConfirm,
  buttonSize,
  isLoading,
  confirmationType,
  confirmationText,
  buttonLabelCancel,
  buttonLabelConfirm
}) => {
  const classes = useStyle({ width })
  return (
    <Modal
      open={open}
      container={container}
      backdropStyles={backdropStyles}
      containerClassName={clsx(classes.modalContainer, { [containerClassName]: !!containerClassName })}
    >
      <Box mx='0.5rem'>
        <Typography variant={TEXT_VARIANTS.body1}>
          {confirmationText}
        </Typography>
      </Box>
      <div className={classes.actions}>
        <RoundedButton
          size={buttonSize}
          className={classes.cancelButton}
          onClick={onCancel}
        >
          {buttonLabelCancel}
        </RoundedButton>
        <RoundedButton
          size={buttonSize}
          className={
            confirmationType === CONFIRMATION_TYPE.Primary
              ? classes.primaryButton
              : classes.dangerButton
          }
          onClick={onConfirm}
        >
          {buttonLabelConfirm}
          {isLoading && (
            <CircularProgress
              className={classes.circularProgress}
              size={15}
            />
          )}
        </RoundedButton>
      </div>
    </Modal>
  )
}

ConfirmationModal.propTypes = {
  backdropStyles: PropTypes.object,
  open: PropTypes.bool,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  container: PropTypes.object,
  containerClassName: PropTypes.string,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
  isLoading: PropTypes.bool,
  buttonSize: PropTypes.string,
  confirmationType: PropTypes.string,
  confirmationText: PropTypes.string,
  buttonLabelCancel: PropTypes.string,
  buttonLabelConfirm: PropTypes.string
}

ConfirmationModal.defaultProps = {
  backdropStyles: null,
  open: false,
  width: 100,
  isLoading: false,
  container: null,
  containerClassName: '',
  onCancel: noop,
  onConfirm: noop,
  buttonSize: '',
  confirmationType: '',
  confirmationText: '',
  buttonLabelCancel: '',
  buttonLabelConfirm: ''
}

export default ConfirmationModal
