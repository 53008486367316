import { useCallback, useState } from 'react'
import isEmpty from 'lodash/isEmpty'
import {
  DOCUMENT_VAULT_REJECTION_MESSAGES,
  MAX_FILES_DOCUMENT_VAULT,
  MAX_FILES_SIZE_DOCUMENT_VAULT
} from '../../../constants'
import { getFileExtension } from '../../../utils'
import { createDocuments, deleteDocument, uploadDocument } from '../../../service'

export const useValidator = (files) => {
  return useCallback((file) => {
    const { name, extension } = getFileExtension(file.name, true)
    if (isEmpty(name) || isEmpty(extension)) {
      return DOCUMENT_VAULT_REJECTION_MESSAGES.NO_EXTENSION
    }
    if (file.size > MAX_FILES_SIZE_DOCUMENT_VAULT) {
      return DOCUMENT_VAULT_REJECTION_MESSAGES.MAX_FILE_SIZE
    }
    if (files.length >= MAX_FILES_DOCUMENT_VAULT) {
      return DOCUMENT_VAULT_REJECTION_MESSAGES.MAX_FILE_LENGTH
    }
    return null
  }, [files.length])
}

export const filterFileEntries = (files, newFileEntries) => {
  const prevFilesNames = files.map((file) => file.name)
  return newFileEntries.filter((file) => !prevFilesNames.includes(file.name))
}

export const useFileEvents = (clientId, autoUpload = false) => {
  const [files, setFiles] = useState([])
  const [uploading, setUploading] = useState(false)

  // TODO - make this a mutation
  const createDocumentPlaceholders = useCallback(async (documents) => {
    try {
      const body = documents.map((file) => ({
        name: file.name,
        type: file.type,
        size: file.size,
        tags: []
      }))
      return await createDocuments(clientId, body)
    } catch (err) {
      return { data: [] }
    }
  }, [clientId])

  /** Updates a file in the array of files */
  const editFile = useCallback((id, mutator) => {
    setFiles(prev => {
      const fileIndex = prev.findIndex(x => x.documentSpecification?.id === id)
      if (fileIndex === -1) return prev

      const copy = [...prev]
      copy[fileIndex] = mutator(copy[fileIndex], fileIndex, prev)

      return copy
    })
  }, [])

  /** Updates the progress of a file in a set of files being uploaded */
  const onProgress = useCallback((id) => (e) => {
    const progress = (e.loaded / e.total) * 100
    editFile(id, f => ({
      ...f,
      status: {
        ...f.status,
        uploaded: progress === 100,
        progress
      }
    }))
  }, [editFile])

  /** Called when the file upload is aborted */
  const onAbort = useCallback((id) => () => {
    editFile(id, f => ({
      ...f,
      status: {
        ...f.status,
        progress: 0,
        aborted: true
      }
    }))
  }, [editFile])

  const uploadFile = useCallback(async (id, file) => {
    try {
      const spec = file.documentSpecification
      const xhr = new XMLHttpRequest()
      await uploadDocument(
        spec.uploadUrl,
        file.file,
        onProgress(id),
        onAbort(id),
        xhr
      )
    } catch (err) {
      console.error(err)
      editFile(id, f => ({
        ...f,
        status: { ...f.status, error: err }
      }))
    }
  }, [editFile, onProgress, onAbort])

  const uploadFiles = useCallback(async (files) => {
    try {
      console.log('uploadFiles', files)
      const tasks = files.map(f => uploadFile(f.documentSpecification.id, f))
      await Promise.all(tasks)
    } catch (err) {
      console.error(err)
    }
  }, [uploadFile])

  const onFileAccepted = useCallback(async (newFiles) => {
    try {
      setUploading(true)
      const newFileEntries = filterFileEntries(files, newFiles)
      if (newFileEntries.length) {
        const { data } = await createDocumentPlaceholders(newFileEntries)

        const newFiles = newFileEntries.map((fileEntry) => ({
          name: fileEntry.name,
          file: fileEntry,
          type: fileEntry.type,
          size: fileEntry.size,
          status: {
            uploaded: false,
            progress: null,
            error: null
          },
          documentSpecification: data.find((documentSpec) => documentSpec.name === fileEntry.name)
        }))

        setFiles((prevFiles) => [...prevFiles, ...newFiles])
        setUploading(false)
        if (autoUpload) {
          uploadFiles(newFiles).catch(console.error)
        }
      }
    } catch (err) {
      setUploading(false)
      console.error(err)
    }
  }, [files, createDocumentPlaceholders, uploadFiles, autoUpload])

  const onDeleteFile = useCallback(async ({ id }) => {
    try {
      deleteDocument(clientId, id).catch(console.error)
      setFiles((prevFiles) => {
        const fileIndex = prevFiles.findIndex(
          (prevFile) => prevFile.documentSpecification.id === id
        )
        return [
          ...prevFiles.slice(0, fileIndex),
          ...prevFiles.slice(fileIndex + 1)
        ]
      })
    } catch (err) {
      console.error(err)
    }
  }, [clientId])

  return {
    onFileAccepted,
    onDeleteFile,
    files,
    setFiles,
    editFile,
    uploading
  }
}
