import React from 'react'
import PropTypes from 'prop-types'
import { ResponsiveTreeMap } from '@nivo/treemap'
import { makeStyles } from '@material-ui/core/styles'
import Skeleton from '../../../atoms/Skeleton'
import VisualizationErrorBoundary from '../components/VisualizationErrorBoundary'
import { useTargetModelVisualizationData } from '../../../../api/rebalancer'
import { useFormattingContext } from '../../../organisms/FormattingProvider/FormattingContext'
import FadeIn from '../../../molecules/Transitions/FadeIn'

const useStyles = makeStyles((theme) => ({
  diagram: {
    width: '100%',
    minHeight: '600px',
    height: '600px',
    border: `1px solid ${theme.palette.gray.main}`,
    '&.__error': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: '16px'
    }
  }
}))

// const blues = Array(50).fill(50).map((el, index) => `rgb(100, 100, ${((index * 2) + 150)})`)

function TargetModelTreeMapDiagram ({ targetModelId }) {
  const classes = useStyles()
  // eslint-disable-next-line no-unused-vars
  const { formatter } = useFormattingContext()
  const { data, isLoading, error } = useTargetModelVisualizationData(targetModelId)

  if (error) {
    return (
      <div className={`${classes.diagram} __error`}>
        Sorry, There was a problem visualizing the model
      </div>
    )
  }

  if (isLoading) {
    return (
      <div className={classes.diagram}>
        <Skeleton width='100%' height='100%' />
      </div>
    )
  }

  return (
    <FadeIn className={classes.diagram}>
      <VisualizationErrorBoundary name='treeMap'>
        <ResponsiveTreeMap
          colors={{ scheme: 'set2' }}
          data={data.tree}
          labelSkipSize={50}
          identity='name'
          value={x => x.children?.length ? 0 : x.absoluteWeight}
          tile='binary'
          valueFormat={x => formatter(x / 10000, 'percentage')}
          label={(d) => `${d.id} ${d.formattedValue}`}
          margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
        />
      </VisualizationErrorBoundary>
    </FadeIn>
  )
}

TargetModelTreeMapDiagram.propTypes = {
  targetModelId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
}

export default TargetModelTreeMapDiagram
