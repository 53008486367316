import { useCallback, useEffect, useMemo, useState } from 'react'
import isEmpty from 'lodash/isEmpty'
import { useContextContainer } from '../../../../abundanceEngine/components/ContextContainer'
import { CLIENT_VIEW_CONTEXT } from '..'
import { useBookmarks } from '../../../../api/groups'
import { mapBookmarks } from '../helpers'

const useFetchClientsBookmarks = () => {
  const [, setContextContainer] = useContextContainer(CLIENT_VIEW_CONTEXT)
  const [clientIds, setClientIds] = useState([])

  const bookmarksQuery = useMemo(() => {
    return {
      query: {
        levelIds: clientIds,
        levelType: 'clients'
      },
      queryOptions: {
        enabled: !isEmpty(clientIds),
        mapper: mapBookmarks
      }
    }
  }, [clientIds])

  const { data: bookmarks, isLoading } = useBookmarks(
    bookmarksQuery.query,
    bookmarksQuery.queryOptions
  )

  useEffect(() => {
    if (!isLoading) {
      setContextContainer((prevState) => ({
        ...prevState,
        isLoadingBookmarks: false,
        clientBookmarks: {
          ...prevState.clientBookmarks,
          ...bookmarks
        }
      }))
    } else {
      setContextContainer((prevState) => ({
        ...prevState,
        isLoadingBookmarks: isLoading
      }))
    }
  }, [isLoading, setContextContainer, bookmarks])

  const fetchClientsBookmarks = useCallback((clientIds) => {
    setClientIds(clientIds)
  }, [])

  return { fetchClientsBookmarks }
}

export default useFetchClientsBookmarks
