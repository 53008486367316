import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import ProfilePictureFileInput from '../../../molecules/ProfilePictureFileInput'
import { useEditMyProfileMutation } from '../../../../api/users'
import { useMediaUpload } from '../../../../api/special/useMediaUpload'
import { useMediaUrl } from '../../../../api/media'
import SnackAlert from '../../../molecules/SnackAlert/SnackAlert'

function ProfilePicture ({ profile }) {
  const [alert, setAlert] = useState({})
  const { mutateAsync: update } = useEditMyProfileMutation()
  const onUpload = useCallback(async (mediaId) => {
    if (mediaId && profile.avatarMediaId !== mediaId) {
      try {
        await update({
          ...profile,
          avatarMediaId: mediaId
        })
        setAlert({
          openAlert: true,
          alertMessage: 'Your Avatar was updated',
          alertSeverity: 'success'
        })
      } catch (err) {
        setAlert({
          openAlert: true,
          alertMessage: 'There was a problem uploading your avatar',
          alertSeverity: 'error'
        })
      }
    }
  }, [update, profile, setAlert])
  const { upload, isUploading } = useMediaUpload({ enabled: !!profile, onUpload })
  const { data: mediaUrl } = useMediaUrl(profile.avatarMediaId)

  return (
    <>
      <ProfilePictureFileInput
        ignoreDelete
        disabledOpacity={1}
        isLoading={isUploading}
        placeholderSrc={mediaUrl}
        onDropAccepted={upload}
        label='Upload'
      />
      <SnackAlert alert={alert} />
    </>
  )
}

ProfilePicture.propTypes = {
  profile: PropTypes.shape({
    avatarMediaId: PropTypes.oneOf([PropTypes.number, PropTypes.string])
  })
}

export default ProfilePicture
