import React, { useCallback, useMemo, useRef } from 'react'
import SectionScreen from '../SectionScreen'
import SectionHeader from '../../shared/SectionHeader'
import EditButton from '../EditButton'
import { useClientDetails, useSectionEditing } from '../ClientDetailsFormContext'
import PersonalSpace from '../../shared/PersonalSpace'
import {
  useAssignClientStatusTemplate,
  useClientStatusTemplateAssignment,
  useSearchStatusTemplates
} from '../../../../../api/groups'
import AssignmentForm from './AssignmentForm'

const query = {
  take: 200
}

function AssignmentSection () {
  const { client, editSection } = useClientDetails()
  const editing = useSectionEditing('status-template-assignment')
  const { data: statusTemplates, isLoading: optionsLoading } = useSearchStatusTemplates(query)
  const { data: assignment, isLoading: assignmentLoading } = useClientStatusTemplateAssignment(client.clientId)
  const { mutateAsync: assignStatusTemplate } = useAssignClientStatusTemplate()
  const formRef = useRef()
  const onSave = useCallback(async (e) => {
    const onValid = async (formData) => {
      await assignStatusTemplate({
        clientId: client.clientId,
        statusTemplateId: formData.statusTemplateId
      })
    }

    const onInvalid = (errors) => {
      console.error(errors)
    }

    const handler = formRef.current.form.handleSubmit(onValid, onInvalid)
    await handler(e)
  }, [client, formRef, assignStatusTemplate])
  const statusTemplateOptions = useMemo(() => {
    if (!statusTemplates?.data) return []

    return statusTemplates.data.map(st => ({
      label: st.name,
      value: st.statusTemplateId
    }))
  }, [statusTemplates])

  if (optionsLoading || assignmentLoading) {
    return null
  }

  return (
    <SectionScreen sectionName='status-template-assignment'>
      <SectionHeader text='Status Template Assignment'>
        <div>
          <EditButton
            policy='edit_client_status_template_assignments'
            editing={editing}
            onClick={() => editSection({
              section: 'status-template-assignment',
              saveDescription: 'Save Assignment',
              onSave
            })}
          />
        </div>
      </SectionHeader>
      <AssignmentForm
        ref={formRef}
        statusTemplateOptions={statusTemplateOptions}
        client={client}
        assignment={assignment}
        disabled={!editing}
      />
      <PersonalSpace />
    </SectionScreen>
  )
}

export default AssignmentSection
