import * as PropType from 'prop-types'
import React, { useEffect, useMemo } from 'react'
import { noop } from 'lodash'
import Text from '../../../../atoms/Text'
import BillingRunOverride from './billingRuns'

const RuntimeConfigOverrides = ({
  currentTemplate = null,
  errors = {},
  classes = {},
  values = {},
  onChange = noop
}) => {
  const templateDatasets = useMemo(() => {
    if (!currentTemplate) {
      return []
    }

    try {
      const templateContent = JSON.parse(currentTemplate.content)
      const datasetArray = templateContent.pages?.flatMap(page =>
        page.sections?.map(section =>
          section.objects?.map(object =>
            Object.entries(object.props ?? {}).filter(([key, value]) => key.toLowerCase().indexOf('dataset') >= 0).map(([key, value]) => value)
          )
        ))
      return [...new Set(datasetArray.flat(3))]
    } catch (err) {
      return []
    }
  }, [currentTemplate])

  const hasBillingOverride = useMemo(() =>
    ['billing', 'invoice', 'billing_fee_override', 'gross_fee_calculation'].some(dataset => templateDatasets.includes(dataset)), [templateDatasets])

  const hasOverrides = useMemo(() => {
    return hasBillingOverride
  }, [hasBillingOverride])

  useEffect(() => {
    if (!hasBillingOverride && values.billingRun) {
      onChange({ key: 'billingRun', value: null })
    }
  }, [hasBillingOverride, onChange, templateDatasets, values.billingRun])

  return (
    <>
      {hasBillingOverride && (
        <BillingRunOverride
          classes={classes}
          error={errors?.billingRun ?? ''}
          value={values.billingRun}
          onChange={onChange}
        />
      )}
      {!hasOverrides && (
        <div className={classes.groupItem}>
          <Text text='Report Customizations' variant='h3' />
          <div style={{ marginTop: '.5rem' }}>
            <Text
              text='The template you have selected does not have any items to customize'
              variant='body2'
            />
          </div>
        </div>
      )}
    </>
  )
}

RuntimeConfigOverrides.propTypes = {
  currentTemplate: PropType.object,
  errors: PropType.object,
  classes: PropType.object,
  values: PropType.object,
  onChange: PropType.func
}

export default RuntimeConfigOverrides
