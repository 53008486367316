import KeyMirror from 'keymirror'
import { createClient, editClient } from '../../../../service'
import { mapFeeOverrides } from '../FeeOverrides/helpers'
import { getGroupedTagsBody } from '../LevelTags/helpers'

export const CLIENT_BILLING_FORM_NAMES = KeyMirror({
  clientId: null,
  longName: null,
  shortName: null,
  description: null,
  feeOverride: null,
  feeScheduleType: null,
  profilePicMediaId: null,
  clientAbbreviation: null,
  tags: null
})

const FORM_NAMES = CLIENT_BILLING_FORM_NAMES

export const mapFeeSchedule = (feeSchedule) => {
  return {
    id: feeSchedule.feeScheduleId,
    name: feeSchedule.longName,
    description: feeSchedule.description,
    details: Object.values(
      feeSchedule.details.reduce(
        (acc, feeDetail) => ({
          ...acc,
          [feeDetail.calcTypeId]: [
            ...(acc[feeDetail.calcTypeId] || []),
            {
              rate: feeDetail.feeRate,
              denomination: feeDetail.denomination,
              limits: {
                lower: feeDetail.lowerLimit,
                upper: feeDetail.upperLimit
              },
              calcTypeId: feeDetail.calcTypeId
            }
          ]
        }),
        {}
      )
    )
  }
}

export const mapFormDefaultValues = (client) => {
  const feeOverrides = (client?.billingInfo?.feeOverrides || []).map(
    ({
      feeLevelOverrideId,
      overrideLevelType,
      overrideLevelId,
      feeScheduleId
    }) => ({
      feeLevelOverrideId,
      feeOverrideType: overrideLevelType,
      feeOverrideLevel: overrideLevelId,
      feeScheduleOverride: feeScheduleId
    })
  )

  return {
    [FORM_NAMES.tags]: client?.tags || [],
    [FORM_NAMES.clientId]: client?.clientId,
    [FORM_NAMES.longName]: client?.longName || '',
    [FORM_NAMES.shortName]: client?.shortName || '',
    [FORM_NAMES.description]: client?.description || '',
    [FORM_NAMES.feeOverride]: feeOverrides,
    [FORM_NAMES.feeScheduleType]: client?.billingInfo?.feeSchedule?.feeScheduleId || '',
    [FORM_NAMES.profilePicMediaId]: client?.extras?.profileData?.profilePicMediaId || '',
    [FORM_NAMES.clientAbbreviation]: client?.clientAbbreviation || ''
  }
}

export const getSearchProp = (value, operation = 'contains') => {
  return [{ op: operation, value }]
}

export async function saveClientData ({
  isNew,
  client,
  clientDefaultValues,
  selectedAccounts
}) {
  const {
    clientId,
    longName,
    shortName,
    description,
    clientAbbreviation,
    feeScheduleType,
    profilePicMediaId,
    feeOverride: feeOverrides,
    tags
  } = client

  const { assigned, unAssigned } = selectedAccounts

  const deletedFeeOverrideIds = clientDefaultValues.feeOverride.filter(({
    feeOverrideType,
    feeOverrideLevel,
    feeLevelOverrideId
  }) => {
    if (!feeOverrides.map(row => row.feeLevelOverrideId).includes(feeLevelOverrideId)) {
      return true
    }
    return feeOverrides.some(({
      feeOverrideType: overrideType,
      feeOverrideLevel: overrideLevel,
      feeLevelOverrideId: overrideId
    }) => {
      return (
        overrideId === feeLevelOverrideId &&
        (
          overrideType !== feeOverrideType ||
          overrideLevel !== feeOverrideLevel
        )
      )
    })
  }).map(({ feeLevelOverrideId }) => feeLevelOverrideId)

  const deletedFeeScheduleId =
    clientDefaultValues?.feeScheduleType && !feeScheduleType
      ? clientDefaultValues?.feeScheduleType
      : null

  const body = {
    longName,
    shortName,
    clientAbbreviation,
    description,
    extras: {
      ...(client?.extras || {}),
      profileData: {
        ...(client?.extras?.profileData || {}),
        profilePicMediaId
      }
    }
  }

  const clientAccountsAssociation = {
    associate: assigned.map(({ id }) => id),
    disable: unAssigned.map(({ id }) => id)
  }

  const clientBillingInfo = {
    feeScheduleId: feeScheduleType,
    feeOverrides: mapFeeOverrides(feeOverrides),
    removeFeeOverrides: deletedFeeOverrideIds,
    removeFeeScheduleId: deletedFeeScheduleId
  }

  const clientTags = getGroupedTagsBody(tags, clientDefaultValues.tags)

  const saveClient = isNew
    ? createClient
    : (body, params) => editClient(clientId, body, params)

  const clientBody = {
    client: body,
    clientAccountsAssociation,
    clientBillingInfo,
    clientTags
  }

  const { data: savedClient } = await saveClient(clientBody)
  return [savedClient, clientBody]
}

export const mapClient = (client = {}, clientBillingInfo = {}, clientTags = []) => {
  const { client: billingInfo = {} } = clientBillingInfo
  return {
    ...client,
    billingInfo,
    tags: clientTags
  }
}

export const mapAccountsMultiQueryResponse = (data) => {
  const {
    assignedAccounts: { data: accounts },
    totalAssignedAccounts: { total }
  } = data

  return { accounts, total }
}

export const CLIENTS_MEMBER_LEVEL_ID = 201
