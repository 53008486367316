import React from 'react'
import PropTypes from 'prop-types'
import { Box, useTheme } from '@material-ui/core'
import noop from 'lodash/noop'
import Text from '../atoms/Text'
import Icon from '../atoms/Icon'
import RoundedButton from '../atoms/RoundedButton'
import { BUTTON_VARIANT, ICON_NAMES, TEXT_VARIANTS } from '../../constants'
import { fileRejections } from '../../prop-types'
import DocumentVaultFileRejectionMessageList from './DocumentVaultFileRejectionMessageList'

const DocumentVaultEmptyState = ({
  fileRejections,
  onBackClick,
  openUploadFileWindow,
  showBackButton,
  backButtonLabel,
  showFileRejectionsMessage
}) => {
  const theme = useTheme()

  return (
    <Box textAlign='center' maxWidth='30rem'>
      <Box mb={2}>
        <Icon
          name={ICON_NAMES.folderPlus}
          color={theme.palette.black}
          customSize={64}
          padding='3rem'
          roundedBackground={theme.palette.gray.light}
        />
        <Box mt={4}>
          {showFileRejectionsMessage && (
            <DocumentVaultFileRejectionMessageList
              withMarginBottom
              fileRejections={fileRejections}
            />
          )}
          <Text
            variant={TEXT_VARIANTS.h1}
            customFontWeight='bold'
            customFontSize='2rem'
            text='Drag & drop your Files here'
          />
        </Box>
        <Box mt={2}>
          <Text
            variant={TEXT_VARIANTS.h4}
            textTransform='none'
            customFontWeight='none'
            customFontSize='1rem'
            text=' or if you prefer'
          />
        </Box>
      </Box>
      <Box display='flex' flexDirection='column' mx={12}>
        <Box mb={2}>
          <RoundedButton primary fullWidth onClick={openUploadFileWindow}>
            Select files to upload
          </RoundedButton>
        </Box>
        {showBackButton && (
          <Box>
            <RoundedButton
              fullWidth
              variant={BUTTON_VARIANT.outlined}
              onClick={onBackClick}
            >
              {backButtonLabel}
            </RoundedButton>
          </Box>
        )}
      </Box>
    </Box>
  )
}

DocumentVaultEmptyState.propTypes = {
  showBackButton: PropTypes.bool,
  backButtonLabel: PropTypes.string,
  onBackClick: PropTypes.func,
  fileRejections,
  openUploadFileWindow: PropTypes.func,
  showFileRejectionsMessage: PropTypes.bool
}

DocumentVaultEmptyState.defaultProps = {
  showBackButton: false,
  backButtonLabel: 'Back to files',
  onBackClick: noop,
  fileRejections: [],
  openUploadFileWindow: noop,
  showFileRejectionsMessage: false
}

export default DocumentVaultEmptyState
