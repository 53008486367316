import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import isEmpty from 'lodash/isEmpty'
import Avatar from '../../atoms/Avatar'
import { useCurrentUser } from '../../../hooks'
import { useAdvisorHome } from './AdvisorHomeContext'
import GroupScopeSelector from './GroupScopeSelector'
import ScopeDisplaySkeleton from './GroupScopeSelector/ScopeDisplaySkeleton'

const useStyles = makeStyles(() => ({
  scopeDisplay: ({ extraStyles, hasHeadingLabel }) => ({
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    alignItems: hasHeadingLabel ? 'end' : 'center',
    padding: '5px 0',
    gap: '2rem',
    ...(extraStyles || {}),
    '& .label': {
      fontSize: '1.5rem',
      position: 'absolute',
      left: '7rem',
      top: 0
    }
  }),
  title: {
    fontSize: '2rem',
    fontWeight: 'bold'
  }
}))

export const ScopeDisplayItem = ({ name, label, avatarSrc, extraStyles }) => {
  const classes = useStyles({ extraStyles, hasHeadingLabel: !isEmpty(label) })

  if (!name) return null

  return (
    <div className={classes.scopeDisplay}>
      {label && <div className='label'>{label}</div>}
      <div>
        <Avatar
          customSize='5rem'
          customFontSize='2rem'
          avatarLetters={name}
          isPublicResource
          src={avatarSrc}
        />
      </div>
      <div className={classes.title}>{name}</div>
    </div>
  )
}

ScopeDisplayItem.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  extraStyles: PropTypes.object,
  avatarSrc: PropTypes.string
}

const GroupScopeDisplay = ({ scope, setScope, headingLabel }) => {
  const onChangeGroupScope = useCallback((group) => {
    const scopeValues = group?.groupId ? [group.groupId] : []

    // setScope sets url state and such appropriately
    setScope({ values: scopeValues })
  }, [setScope])

  return (
    <GroupScopeSelector
      scope={scope}
      label={headingLabel}
      onChange={onChangeGroupScope}
    />
  )
}

GroupScopeDisplay.propTypes = {
  scope: PropTypes.object,
  setScope: PropTypes.func,
  headingLabel: PropTypes.string
}

const UserScopeDisplay = ({ headingLabel }) => {
  const { data: user, isLoading: isLoadingUser } = useCurrentUser()

  if (isLoadingUser) {
    return <ScopeDisplaySkeleton />
  }

  return (
    <ScopeDisplayItem
      name={user?.fullName}
      avatarSrc={user?.profilePictureUrl}
      label={headingLabel}
    />
  )
}

UserScopeDisplay.propTypes = {
  headingLabel: PropTypes.string
}

const ScopeDisplay = ({ headingLabel }) => {
  const { scope, setScope } = useAdvisorHome()

  if (!scope) {
    return <UserScopeDisplay headingLabel={headingLabel} />
  }

  return (
    <GroupScopeDisplay
      scope={scope}
      setScope={setScope}
      headingLabel={headingLabel}
    />
  )
}

ScopeDisplay.propTypes = {
  headingLabel: PropTypes.string
}

export default ScopeDisplay
