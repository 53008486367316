import React, { useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import { isArray, isEmpty, first, isNumber } from 'lodash'
import KeyMirror from 'keymirror'
import { Box } from '@material-ui/core'
import {
  BENCHMARK_TYPES,
  CALC_TYPES,
  TABLE_CELL_FORMATS,
  TABLE_CELL_TYPE,
  TEXT_ALIGNMENTS,
  TEXT_VARIANTS
} from '../../constants'
import useFetchAssets from '../../hooks/useFetchAssets'
import useExpandibleTable from '../../hooks/useExpandibleTable'
import { useToggle, useDateRangeTableFilter } from '../../hooks'
import { useAvailableDates } from '../../redux/slices/appContext'
import fastDeepEqual from '../../utils/fastDeepEqual'
import { numeralByCase, tableNumberFormatter } from '../../utils'
import { formatCellValue, hydrateDate } from '../../utils/tableHelper'
import NumberFormat from '../atoms/NumberFormat'
import CollapsibleTable from './CollapsibleTable'

const TOTAL_CELL_WIDTH = {
  TITLE_CELL: 70,
  BODY_CELL: 30
}

const getCellProps = (propOverrides) => ({
  width: `${TOTAL_CELL_WIDTH.TITLE_CELL}%`,
  alignment: TEXT_ALIGNMENTS.left,
  variant: TEXT_VARIANTS.subtitle2,
  ...propOverrides
})

const getCellConfigProps = (value, valueKey, isPercentage) => {
  const tooltipConfig = isPercentage
    ? {
      title: '',
      skipFormat: true
    }
    : {
      title: numeralByCase(value),
      skipFormat: true
    }
  return {
    hidden: true,
    value: valueKey,
    showTooltip: true,
    type: isPercentage ? TABLE_CELL_TYPE.COMPONENT : TABLE_CELL_TYPE.NUMBER,
    tooltips: ['', tooltipConfig]
  }
}

const COMPONENT_KEYS = KeyMirror({
  beginningValue: null,
  netAdditions: null,
  netGain: null,
  endingValue: null,
  householdReturn: null,
  benchmarkValue: null
})

const TABLE_DATA_CONFIG = [
  { label: 'Beginning Value', valueKey: COMPONENT_KEYS.beginningValue },
  { label: 'Net Additions', valueKey: COMPONENT_KEYS.netAdditions },
  { label: 'Net Gain', valueKey: COMPONENT_KEYS.netGain },
  { label: 'Ending Value', valueKey: COMPONENT_KEYS.endingValue },
  {
    label: 'Household Return',
    valueKey: COMPONENT_KEYS.householdReturn,
    isPercentage: true,
    formatter: (value) =>
      numeralByCase(
        value,
        TABLE_CELL_FORMATS.PERCENTAGE.SHORT,
        TABLE_CELL_FORMATS.PERCENTAGE.MID
      )
  },
  {
    label: 'Client Benchmark',
    valueKey: COMPONENT_KEYS.benchmarkValue,
    hideIfEmpty: true,
    isPercentage: true,
    formatter: (value) =>
      numeralByCase(
        value,
        TABLE_CELL_FORMATS.PERCENTAGE.SHORT,
        TABLE_CELL_FORMATS.PERCENTAGE.MID
      )
  }
]

const renderReturnCellValue = (
  cellValue,
  showDashesWhenNHEP,
  heldEntirePeriodIndicator
) => {
  const showNotHeldIndicator =
    isNumber(heldEntirePeriodIndicator) && heldEntirePeriodIndicator === 0

  const returnValue =
    showDashesWhenNHEP && showNotHeldIndicator ? '-' : cellValue

  const titleFormatted = formatCellValue(
    cellValue,
    TABLE_CELL_FORMATS.PERCENTAGE.LONG
  )
  const numberFormatted = formatCellValue(
    returnValue,
    TABLE_CELL_FORMATS.PERCENTAGE.MID
  )
  return (
    <Box>
      <NumberFormat
        title={titleFormatted}
        number={numberFormatted}
        skipFormat
      />
      {showNotHeldIndicator && (
        <NumberFormat title='Not held entire period' number='*' skipFormat />
      )}
    </Box>
  )
}

const mapComponentsOfChangeRows = (
  data,
  dateRangesSpec,
  hideComponents = {},
  formatComponents = {},
  showDashesWhenNHEP = false
) => {
  return TABLE_DATA_CONFIG.map(
    ({ label, valueKey, formatter, isPercentage, hideIfEmpty }) => {
      const prefixCells = [getCellProps({ value: label })]
      const formatColumn = formatComponents[valueKey]

      if (hideComponents[valueKey]) {
        return []
      }

      if (hideIfEmpty) {
        const isRowEmpty = dateRangesSpec.every(({ sourceKey }) => {
          return !data[sourceKey][valueKey]
        })
        if (isRowEmpty) return []
      }

      if (formatColumn) {
        formatter = (value) => numeralByCase(value, formatColumn, formatColumn)
      }

      const { cells } = dateRangesSpec.reduce(
        (acc, { sourceKey }, index) => {
          const cellValue = data[sourceKey][valueKey]
          const { heldEntirePeriodIndicator } = data[sourceKey]
          const isLastCell = index === acc.dateRangeLength - 1

          const cellConfig = isLastCell
            ? [getCellConfigProps(cellValue, valueKey, isPercentage)]
            : []

          const cellWidth = `${
            TOTAL_CELL_WIDTH.BODY_CELL / acc.dateRangeLength
          }%`

          const exportValue = formatter
            ? formatter(cellValue)
            : tableNumberFormatter(cellValue)

          let value = exportValue

          if (isPercentage) {
            value = renderReturnCellValue(
              cellValue,
              showDashesWhenNHEP,
              heldEntirePeriodIndicator
            )
          }

          const cells = [
            ...acc.cells,
            getCellProps({
              value,
              width: cellWidth,
              alignment: TEXT_ALIGNMENTS.right,
              exportValue
            }),
            ...cellConfig
          ]
          return { ...acc, cells }
        },
        { cells: prefixCells, dateRangeLength: dateRangesSpec.length }
      )

      return cells
    }
  ).filter((row) => !isEmpty(row))
}

const getColumns = (dateRangesSpec, availableDates, customDateRanges) => {
  const suffixColumns = dateRangesSpec.map(
    ({ sourceKey, ...dateRangeSpec }) => {
      const dateValue = hydrateDate(dateRangeSpec, availableDates)
      return {
        isSelect: true,
        selectProps: {
          sourceKey,
          selectType: 'date',
          selectedValue: dateValue,
          options: customDateRanges
        }
      }
    }
  )
  return [
    {
      name: 'Household Activity Summary',
      alignment: TEXT_ALIGNMENTS.left
    },
    ...suffixColumns
  ]
}

const ComponentsOfChangeTable = ({
  accountCategoryFilters,
  allowDownload,
  hideComponents,
  dataFetchDateRangesSpec,
  formatComponents,
  showDashesWhenNotHeldEntirePeriod,
  customDateRanges
}) => {
  const [availableDates] = useAvailableDates()
  const { baseFetchAssets } = useFetchAssets()
  const [isLoading, , toggleIsLoadingOn, toggleIsLoadingOff] = useToggle()

  const { rows, columns, setRows, setColumns, modifyColumns } =
    useExpandibleTable({
      columns: getColumns(dataFetchDateRangesSpec, availableDates, customDateRanges)
    })

  const {
    onHeaderFilterChange,
    dateRangeFiltersHydrated,
    isCustomDateRangeSelected
  } = useDateRangeTableFilter({
    columns,
    setColumns,
    modifyColumns,
    balanceInformationDateFilters: dataFetchDateRangesSpec,
    dateRangeColSpan: 1
  })

  const fetchRootRows = useCallback(async () => {
    if (isCustomDateRangeSelected) {
      return
    }
    try {
      toggleIsLoadingOn()
      const componentsOfChangeByDateRange = await Promise.all(
        dateRangeFiltersHydrated.map(
          async ({ sourceKey, ...dateRangeFilter }) => {
            const { startDate, endDate } = dateRangeFilter

            const fetchParams = {
              startDate,
              endDate,
              benchmarkType: BENCHMARK_TYPES.CLIENT,
              accountCategoryIds: !isEmpty(accountCategoryFilters)
                ? accountCategoryFilters
                : []
            }
            const [balanceResults, performanceResult] = await Promise.all([
              baseFetchAssets({
                ...fetchParams,
                calcType: CALC_TYPES.balance
              }),
              baseFetchAssets({
                ...fetchParams,
                calcType: CALC_TYPES.performance
              })
            ])
            const balance = first(balanceResults)
            const performance = first(performanceResult)

            const netGain = performance?.balanceChange || 0
            const netAdditions = performance?.flowChange || 0
            const beginningValue = performance?.previousValue || 0
            const benchmarkValue = performance?.benchmarkValue || 0
            const householdReturn = performance?.balanceChangePercentage || 0
            const heldEntirePeriodIndicator = performance?.heldEntirePeriod

            const endingValue = balance?.endingValue || 0

            const componentsOfChange = {
              beginningValue,
              netAdditions,
              endingValue,
              netGain,
              householdReturn,
              benchmarkValue,
              heldEntirePeriodIndicator
            }
            return [sourceKey, componentsOfChange]
          }
        )
      )
      const componentsOfChange = Object.fromEntries(
        componentsOfChangeByDateRange
      )
      const rows = mapComponentsOfChangeRows(
        componentsOfChange,
        dateRangeFiltersHydrated,
        hideComponents,
        formatComponents,
        showDashesWhenNotHeldEntirePeriod
      )
      setRows(rows)
    } catch (err) {
      console.error(err)
    } finally {
      toggleIsLoadingOff()
    }
  }, [
    setRows,
    hideComponents,
    formatComponents,
    baseFetchAssets,
    toggleIsLoadingOn,
    toggleIsLoadingOff,
    accountCategoryFilters,
    dateRangeFiltersHydrated,
    isCustomDateRangeSelected,
    showDashesWhenNotHeldEntirePeriod
  ])

  useEffect(() => {
    fetchRootRows()
  }, [fetchRootRows])

  return (
    <CollapsibleTable
      rows={rows}
      columns={columns}
      isBodyLoading={isLoading}
      allowDownload={allowDownload}
      asOfDate={availableDates.mainDate}
      multiHeaderRows={isArray(first(columns))}
      onHeaderFilterChange={onHeaderFilterChange}
    />
  )
}

ComponentsOfChangeTable.propTypes = {
  accountCategoryFilters: PropTypes.arrayOf(PropTypes.number),
  allowDownload: PropTypes.bool,
  dataFetchDateRangesSpec: PropTypes.array,
  hideComponents: PropTypes.shape(
    Object.keys(COMPONENT_KEYS).reduce(
      (acc, componentKey) => ({ ...acc, [componentKey]: PropTypes.bool }),
      {}
    )
  ),
  formatComponents: PropTypes.shape(
    Object.keys(COMPONENT_KEYS).reduce(
      (acc, componentKey) => ({ ...acc, [componentKey]: PropTypes.string }),
      {}
    )
  ),
  showDashesWhenNotHeldEntirePeriod: PropTypes.bool,
  customDateRanges: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    label: PropTypes.string
  }))
}

ComponentsOfChangeTable.defaultProps = {
  accountCategoryFilters: [],
  allowDownload: false,
  dataFetchDateRangesSpec: [{ sourceKey: 'sinceInceptionKey', useMin: true }],
  hideComponents: {
    [COMPONENT_KEYS.benchmarkValue]: true
  },
  formatComponents: {},
  showDashesWhenNotHeldEntirePeriod: false,
  customDateRanges: undefined
}

export default React.memo(ComponentsOfChangeTable, (prevProps, nextProps) => {
  return fastDeepEqual(prevProps, nextProps, { compareFunctions: false })
})
