import React, { useCallback, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Container, Grid, InputLabel, makeStyles } from '@material-ui/core'
import { useForm } from 'react-hook-form'
import dayjs from 'dayjs'
import { useHistory } from 'react-router-dom'
import utc from 'dayjs/plugin/utc'
import Select from '../../../molecules/Select'
import SaveCancelButtons from '../../../molecules/SaveCancelButtons'
import { useSearchAccounts } from '../../../../api/accounts'
import { useSearchClients } from '../../../../api/clients'
import Skeleton from '../../../atoms/Skeleton'
import { INTERNAL_DATE_FORMAT } from '../../../../constants'
import { useLevelDates } from '../../../../api/coreData'
import { defaultOptions, getDefaultDateRangeOptions } from '../../../molecules/RelativeDateSelect'
import { DATA_SET_TYPES, levelDatesQuery } from './helpers'
import DateSelector from './DateSelector'
import ReportParameterControl from './ReportParameterControl'
import ExposurePicker from './exposurePicker'

dayjs.extend(utc)

const useStyles = makeStyles(() => ({
  title: {
    margin: '0 auto 1.5rem auto',
    fontWeight: '600',
    fontSize: '2.125rem',
    textAlign: 'center'
  },
  title2: {
    fontSize: '1.2rem',
    color: '#000',
    fontWeight: '600',
    marginTop: '1rem',
    marginBottom: '0.625rem',
    '& span': {
      color: '#db3131'
    }
  },
  saveButtons: {
    margin: '0 auto'
  },
  select: {
    padding: '0.75rem',
    width: '100%'
  },
  footnote: {
    fontSize: '1rem',
    fontWeight: '325',
    color: '#707689',
    '& strong': {
      color: 'black'
    }
  }
}))

const totalsQuery = { resultType: 'total' }

const ReportParameterForm = ({
  exposureLevelOptions,
  exposureTypeOptions,
  dataSetOptions,
  dateRangeOptions,
  onSubmit,
  defaultValues
}) => {
  const classes = useStyles()
  const history = useHistory()

  const { data: userLevelDates, isLoadingLevelDates } = useLevelDates(levelDatesQuery)

  const accountsTotal = useSearchAccounts(totalsQuery)
  const clientsTotal = useSearchClients(totalsQuery)

  const submissionMapper = useCallback(
    ({ dateRange, exposureTarget, dataSet, asOfDate, ...values }) => {
      const dateRangeOptions = getDefaultDateRangeOptions()
      const selectedOption = dateRangeOptions.find(
        ({ value }) => value === dateRange
      )
      const selectedStartDate = selectedOption.getStartDate({ mainDate: asOfDate })
      const startDate = DATA_SET_TYPES.BALANCE === dataSet
        ? asOfDate
        : selectedStartDate

      const minStartDate = userLevelDates?.minDailyStartDate || useLevelDates?.min

      onSubmit({
        ...values,
        dataSet,
        exposureTarget: exposureTarget?.value,
        dateRangePreset: dateRange,
        startDate,
        endDate: asOfDate,
        minDailyStartDate: minStartDate || startDate
      })
    },
    [onSubmit, userLevelDates]
  )

  const {
    handleSubmit,
    watch,
    setValue,
    register,
    reset
  } = useForm({ defaultValues })

  useEffect(() => {
    if (userLevelDates) {
      reset({
        ...defaultValues,
        asOfDate: dayjs
          .utc(userLevelDates?.max || defaultValues.asOfDate)
          .toISOString()
      })
    }
  }, [userLevelDates, defaultValues, reset])

  register('exposureTarget', { required: true })

  const {
    setDataSet,
    setAsOfDate,
    setDateRange,
    setLevelType,
    setExposureType,
    setExposureTarget
  } = useMemo(() => {
    return {
      setDataSet: (value) => setValue('dataSet', value),
      setAsOfDate: (value) => setValue('asOfDate', dayjs.utc(value).format('YYYY-MM-DD')),
      setDateRange: (value) => setValue('dateRange', value),
      setLevelType: (value) => setValue('levelType', value),
      setExposureType: (value) => setValue('exposureType', value),
      setExposureTarget: (value) => setValue('exposureTarget', value)
    }
  }, [setValue])

  const [
    exposureType,
    levelType,
    dataSet,
    dateRange,
    asOfDate
  ] = watch([
    'exposureType',
    'levelType',
    'dataSet',
    'dateRange',
    'asOfDate',
    'exposureTarget'
  ])

  useEffect(() => {
    setExposureTarget('')
  }, [exposureType, setExposureTarget])

  const exposureLevelOptionsWithFootnotes = useMemo(() => {
    return exposureLevelOptions.map(option => {
      const isTotalsLoading = clientsTotal.isLoading || accountsTotal.isLoading
      let footnote = null
      if (isTotalsLoading) {
        footnote = <Skeleton height='1rem' width='5rem' />
      } else {
        const isLevelTypeClient = option.value === 'client'
        const total = isLevelTypeClient
          ? clientsTotal?.data?.total
          : accountsTotal?.data?.total
        const levelType = isLevelTypeClient ? 'Clients' : 'Accounts'

        footnote = (
          <span>
            <strong>{total || 0}</strong> {`Total ${levelType}`}
          </span>
        )
      }
      return {
        ...option,
        footnote
      }
    })
  }, [exposureLevelOptions, accountsTotal, clientsTotal])

  return (
    <Container maxWidth='md'>
      <form onSubmit={handleSubmit(submissionMapper)}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <div className={classes.title}>Run Asset Exposure</div>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.title2} />
            <ReportParameterControl
              title='Asset Type'
              value={exposureType}
              onSelectOption={setExposureType}
              options={exposureTypeOptions}
            />
          </Grid>
          <Grid item xs={12}>
            <ExposurePicker
              exposureType={exposureType}
              setExposureTarget={setExposureTarget}
            />
          </Grid>
          <Grid item xs={12}>
            <ReportParameterControl
              title='Exposure Level'
              value={levelType}
              onSelectOption={setLevelType}
              options={exposureLevelOptionsWithFootnotes}
            />
          </Grid>
          <Grid item xs={12}>
            <ReportParameterControl
              title='Data Sets'
              value={dataSet}
              onSelectOption={setDataSet}
              options={dataSetOptions}
            />
          </Grid>
          {dataSet === DATA_SET_TYPES.BALANCE_AND_PERFORMANCE && (
            <Grid item xs={12} md={6}>
              <InputLabel className={classes.title2}>Date Range</InputLabel>
              <Select
                className={classes.select}
                disabled={dataSet === DATA_SET_TYPES.BALANCE}
                variant='outlined'
                value={dateRange}
                onChange={setDateRange}
                options={dateRangeOptions}
                showCheckMarOnSelectedItems
              />
            </Grid>
          )}
          <Grid item xs={12} md={6}>
            <InputLabel className={classes.title2}>As of Date</InputLabel>
            {isLoadingLevelDates
              ? <Skeleton height='3.5rem' width='100%' />
              : (
                <DateSelector
                  value={dayjs.utc(asOfDate).format(INTERNAL_DATE_FORMAT)}
                  onChange={setAsOfDate}
                  disabledDateRange={userLevelDates}
                />
              )}
          </Grid>
          <Grid item xs={12}>
            <SaveCancelButtons
              spacing='0.75rem'
              primaryButtonLabel='Run'
              onCancel={() => history.goBack()}
              containerClassName={classes.saveButtons}
            />
          </Grid>
        </Grid>
      </form>
    </Container>
  )
}

const exposureOptionPropType = PropTypes.arrayOf(
  PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string
  })
)

ReportParameterForm.propTypes = {
  exposureLevelOptions: exposureOptionPropType,
  exposureTypeOptions: exposureOptionPropType,
  dataSetOptions: exposureOptionPropType,
  dateRangeOptions: exposureOptionPropType,
  defaultValues: PropTypes.object,
  onSubmit: PropTypes.func.isRequired
}

export const defaultProps = {
  exposureLevelOptions: [
    { label: 'Account', value: 'account' },
    { label: 'Client', value: 'client' }
  ],
  exposureTypeOptions: [
    { label: 'Asset', value: 'asset' },
    { label: 'Asset Tag', value: 'classificationTag' }
  ],
  dataSetOptions: [
    {
      label: 'Balance',
      value: 'balance',
      footnote: 'Market Value and Units'
    },
    {
      label: 'Balance & Performance',
      value: 'balanceAndPerformance',
      footnote: 'Incl. Net Gain and Return'
    }
  ],
  dateRangeOptions: defaultOptions.filter(({ value }) => [
    'L7D',
    'L30D',
    'MTD',
    'QTD',
    'YTD'
  ].includes(value)),
  defaultValues: {
    exposureType: 'asset',
    exposureTarget: '',
    levelType: 'account',
    dataSet: 'balance',
    dateRange: 'L7D',
    asOfDate: '2023-01-01'
  }
}

ReportParameterForm.defaultProps = defaultProps

export default ReportParameterForm
