import React, { useCallback } from 'react'
import ShadedTargetMarkerLayer from './ShadedTargetMarkerLayer'
import FloatingTargetMarkerLayer from './FloatingTargetMarkerLayer'

export const useMarkerLayer = (markerType, accessor, tooltip, compareTo) => {
  return useCallback(({ bars, xScale }) => {
    switch (markerType) {
      case 'shaded':
        return (
          <ShadedTargetMarkerLayer
            bars={bars}
            xScale={xScale}
            accessor={accessor}
            tooltip={tooltip}
            compareTo={compareTo}
          />
        )
      case 'floating':
        return (
          <FloatingTargetMarkerLayer
            bars={bars}
            xScale={xScale}
            accessor={accessor}
            tooltip={tooltip}
            compareTo={compareTo}
          />
        )
      default:
        return (
          <ShadedTargetMarkerLayer
            bars={bars}
            xScale={xScale}
            accessor={accessor}
            tooltip={tooltip}
            compareTo={compareTo}
          />
        )
    }
  }, [markerType, accessor, tooltip, compareTo])
}
