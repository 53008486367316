import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import { makeStyles } from '@material-ui/core'
import isEmpty from 'lodash/isEmpty'
import { useHistory } from 'react-router-dom'
import Text from '../../../atoms/Text'
import { useWealthJourneyQuery } from '../../../../api/wealthJourney'
import CardListSkeleton from '../../ClientCardsViewV2/components/CardListSkeleton'
import ClientCardItem from '../../ClientCardsViewV2/ClientCardView/ClientCard/ClientCardItem'
import AdvisorHomeEmptySection from '../AdvisorHomeEmptySection'
import { ICON_NAMES } from '../../../../constants'
import { useSetCurrentClient } from '../../../../redux/slices/appContext'
import { useAdvisorHome } from '../AdvisorHomeContext'
import MilestoneCard from './MilestoneCard'

const useStyles = makeStyles(() => ({
  summary: {
    height: '100%',
    width: '100%'
  },
  milestoneContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '2rem',
    overflowX: 'auto',
    margin: '2rem'
  },
  milestoneClientHeader: {
    textAlign: 'center',
    margin: 'auto',
    maxWidth: '275px'
  },
  milestoneHeader: {
    fontSize: '0.875rem',
    color: '#212945',
    fontWeight: 'bold',
    paddingBottom: '1rem',
    textAlign: 'center'
  },
  skeleton: {
    width: 'unset',
    maxWidth: '230px',
    minWidth: '210px',
    height: '300px'
  },
  skeletonsContainer: {
    overflowX: 'auto',
    flexWrap: 'nowrap'
  },
  emptyIcon: {
    '& svg': {
      marginBottom: '0.75rem'
    }
  }
}))

const queryKey = 'upcoming_milestones'

const UpcomingMilestonesSummary = ({ take, redirectTo, emptyStateLabel }) => {
  const classes = useStyles()
  const history = useHistory()
  const { scope } = useAdvisorHome()
  const setCurrentClient = useSetCurrentClient()

  const { query, queryOptions } = useMemo(() => {
    return {
      query: {
        take,
        includes: {
          client: true
        },
        filters: {
          entryTypeId: [{ op: 'eq', value: 1 }],
          entryDate: [
            { op: 'gte', value: dayjs.utc().startOf('day').toISOString() }
          ],
          ...(!isEmpty(scope?.values)
            ? {
              groupIds: [{ op: 'in', value: scope?.values }]
            }
            : {})
        },
        sort: [{ field: 'entryDate', dir: 'asc' }]
      },
      queryOptions: {
        mapper: ({ data }) => data ?? []
      }
    }
  }, [take, scope])

  const onClickCard = useCallback(
    (milestone) => {
      if (redirectTo) {
        milestone?.client && setCurrentClient(milestone.client.clientId)
        history.push(redirectTo + `?milestoneId=${milestone.entryId}`)
      }
    },
    [history, redirectTo, setCurrentClient]
  )

  const { data, isLoading, error } = useWealthJourneyQuery(
    queryKey,
    query,
    queryOptions
  )

  if (error) {
    return <AdvisorHomeEmptySection title='Error' />
  }

  if (isEmpty(data) && !isLoading) {
    return (
      <AdvisorHomeEmptySection
        borderedContent
        title='No milestones'
        description={emptyStateLabel}
        className={classes.emptyIcon}
        icon={ICON_NAMES.wealthJourneyMilestones}
      />
    )
  }

  return (
    <div className={classes.summary}>
      <div className={classes.milestoneContainer}>
        {isLoading ? (
          <CardListSkeleton
            cardListLength={5}
            cardComponent={(props) => <ClientCardItem noPadding {...props} />}
            containerClassName={classes.skeletonsContainer}
            itemClassName={classes.skeleton}
          />
        ) : (
          data.map((milestone) => (
            <div key={milestone.entryId}>
              <MilestoneCard
                milestone={milestone}
                selectable={false}
                onSelect={onClickCard}
                tooltipTitle='Go to milestone'
                header={
                  <Text
                    className={classes.milestoneHeader}
                    text={milestone.client.longName}
                  />
                }
              />
            </div>
          ))
        )}
      </div>
    </div>
  )
}

UpcomingMilestonesSummary.propTypes = {
  take: PropTypes.number,
  title: PropTypes.string,
  redirectTo: PropTypes.string,
  emptyStateLabel: PropTypes.string
}

UpcomingMilestonesSummary.defaultProps = {
  take: 5,
  redirectTo: '',
  emptyStateLabel: 'Looks like you don’t have Upcoming Milestones Setup',
  title: 'Upcoming Milestones'
}

export default UpcomingMilestonesSummary
