import React from 'react'
import PropTypes from 'prop-types'
import Section from '../shared/Section'
import Description from '../shared/Description'
import SectionTitle from '../shared/SectionTitle'

function FeeScheduleDescription ({ feeSchedule }) {
  if (!feeSchedule) {
    return null
  }

  return (
    <Section>
      <SectionTitle title='Description' />
      <Description>{feeSchedule.description}</Description>
    </Section>
  )
}

FeeScheduleDescription.propTypes = {
  feeSchedule: PropTypes.any
}

export default FeeScheduleDescription
