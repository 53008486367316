import { IconButton } from '@material-ui/core'
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { BUTTON_SIZES, ICON_NAMES } from '../../../constants'
import Icon from '../../../components/atoms/Icon'
import GroupTypeInfoModal from './GroupTypeInfoModal'

const GroupTypeDetailsModalToggle = ({
  groupTypeId,
  title
}) => {
  const [showInfoModal, setShowInfoModal] = useState(false)

  return (
    <>
      <IconButton
        secondary
        onClick={() => setShowInfoModal(true)}
        size={BUTTON_SIZES.medium}
        padding='.15rem'
        title={title}
      >
        <Icon name={ICON_NAMES.infoOutlined} customSize='1.25rem' margin='-.25rem' />
      </IconButton>

      <GroupTypeInfoModal
        groupTypeId={groupTypeId}
        open={showInfoModal}
        title={title}
        onClose={() => setShowInfoModal(false)}
      />
    </>
  )
}

GroupTypeDetailsModalToggle.propTypes = {
  groupTypeId: PropTypes.number,
  title: PropTypes.string
}

export default GroupTypeDetailsModalToggle
