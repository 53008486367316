import React, { createContext, useCallback, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { useOktaAuth } from '@okta/okta-react'
import { useCompleteFirmChangeMutation, useStartFirmChangeMutation } from '../../api/special'
import UtilityProgress from './UtilityProgress'

export const SummitUtilityProviderContext = createContext({})

export const SummitUtilityProvider = ({
  currentUser,
  children
}) => {
  const { mutateAsync: start } = useStartFirmChangeMutation()
  const { mutateAsync: complete } = useCompleteFirmChangeMutation()
  const oktaAuth = useOktaAuth()
  const [inProcess, setInProcess] = useState(false)

  const providerValue = useMemo(() => {
    console.debug('utility provider', currentUser)

    if (currentUser?.userType === 'Summit') {
      return {
        start,
        complete,
        oktaAuth,
        enabled: true
      }
    }

    return {
      enabled: false
    }
  }, [currentUser, start, complete, oktaAuth])

  const hotKeyHandler = useCallback(async (evt) => {
    if (!providerValue.enabled) {
      return false
    }
    if (evt.ctrlKey && evt.shiftKey && (evt.key === 'F' || evt.code === 'KeyF')) {
      const toFirmId = Number(prompt('Where we going?'))
      if (toFirmId === undefined) {
        return false
      }
      if (Number.isNaN(toFirmId)) {
        alert('That is not a number')
        return false
      }
      setInProcess(true)
      try {
        const { firmChangeId } = await providerValue.start({ toFirmId })
        const result = await providerValue.complete({ firmChangeId })
        if (result) {
          // need to do with redirect to be more stable
          await providerValue.oktaAuth.oktaAuth.token.getWithRedirect()
        }
      } catch (err) {
        console.error(err, err?.summary)
        setInProcess(false)
      }
    }
  }, [providerValue, setInProcess])

  useEffect(() => {
    if (providerValue.enabled) {
      window.addEventListener('keydown', hotKeyHandler)
    }
    return () => {
      window.removeEventListener('keydown', hotKeyHandler)
    }
  }, [providerValue, hotKeyHandler])

  return (
    <SummitUtilityProviderContext.Provider value={providerValue}>
      {inProcess ? (<UtilityProgress message='Please wait, changing instance...' />) : children}
    </SummitUtilityProviderContext.Provider>
  )
}

SummitUtilityProvider.propTypes = {
  currentUser: PropTypes.shape({
    userType: PropTypes.string
  }),
  children: PropTypes.node
}

export default SummitUtilityProvider
