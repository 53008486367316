import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import { useLatestClientStatusReport } from '../../../api/groups'
import StatusDisplay from './StatusDisplay'
import ItemStatusEditor from './ItemStatusEditor'
import { useFindItemInTree, useStatusInfo } from './hooks'

const useStyles = makeStyles((theme) => ({
  commentTitle: {
    padding: '16px 1.5rem',
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
    gap: '10px'
  },
  names: {
    flex: '1 0'
  },
  status: {
  },
  parentTitle: {
    fontWeight: '100',
    paddingBottom: '5px'
  },
  itemTitle: {
    fontSize: '16px'
  },
  option: {
    padding: '15px 15px',
    display: 'flex',
    gap: '10px'
  }
}))

function StatusReportCommentTitle ({ item, parent, statuses, clientId }) {
  const classes = useStyles()
  const { data, isFetching } = useLatestClientStatusReport(clientId)
  const theItem = useFindItemInTree(data?.report?.items ?? [], item.statusReportItemId)
  const statusInfo = useStatusInfo(theItem.status, statuses)

  return (
    <>
      <header className={classes.commentTitle}>
        <StatusDisplay statusInfo={statusInfo} useIcon size={32} />
        <div className={classes.names}>
          {parent ? (<div className={classes.parentTitle}>{parent?.displayName}</div>) : null}
          <div className={classes.itemTitle}>{theItem?.displayName}</div>
        </div>
      </header>
      <ItemStatusEditor
        statusInfo={statusInfo}
        item={theItem}
        statuses={statuses}
        isFetching={isFetching}
      />
    </>
  )
}

StatusReportCommentTitle.propTypes = {
  clientId: PropTypes.number,
  item: PropTypes.shape({
    statusReportItemId: PropTypes.number
  }),
  parent: PropTypes.any,
  statuses: PropTypes.array
}

export default StatusReportCommentTitle
