import React, { useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { isEmpty } from 'lodash'
import OperationalTable, { useOperationalTable } from '../../../organisms/OperationalTable'
import useClients from '../hooks/useClients'
import { useContextContainer } from '../../../../abundanceEngine/components/ContextContainer'
import { CLIENT_VIEW_CONTEXT } from '..'
import { useAutoOperationalTableContext } from '../../OperationalTable/AutoOperationalTableContext'
import { useAppContext } from '../../../../redux/slices/appContext'
import useFetchClientsBookmarks from '../hooks/useFetchClientsBookmarks'
import EmptySection from '../../../atoms/EmptySection'
import useGetUserWheelViewPath from '../hooks/useGetUserWheelViewPath'
import { defaultColumnConfig, useColumns } from './columnConfig'

const ClientsTable = ({ columnsConfig }) => {
  const [{
    pageSize: defaultPageSize,
    hideNoteCommentsButton,
    integrations,
    defaultSort,
    queryText
  }] = useContextContainer(CLIENT_VIEW_CONTEXT)
  const { path: redirectPath, isLoading: isLoadingRedirectPath } = useGetUserWheelViewPath()

  const { tableProps } = useAutoOperationalTableContext()

  const { isAdvisor } = useAppContext()
  const {
    showSalesforceClientInfo,
    showWealthBoxClientInfo,
    showRedTailClientInfo,
    isCrmAuthorized
  } = integrations

  const showIntegration = isAdvisor && isCrmAuthorized

  const showActions =
      ((showSalesforceClientInfo ||
        showWealthBoxClientInfo ||
        showRedTailClientInfo) &&
        showIntegration) ||
      !hideNoteCommentsButton

  useEffect(() => {
    if (tableProps?.setHiddenColumns && !showActions) {
      // assuming this will always be the ID of the options column
      tableProps.setHiddenColumns(['options'])
    }
  }, [tableProps, showActions])

  const {
    pageIndex,
    pageSize,
    sort,
    onPagingChange,
    onSortingChange,
    onTableModeChange,
    tableMode
  } = useOperationalTable({ defaultSort, defaultPageSize })

  const { clients: _clients, total, isLoading } = useClients({
    tableMode,
    sort,
    pageIndex,
    pageSize,
    searchText: queryText
  })

  const clients = useMemo(() => (_clients?.map(c => ({ redirectPath, ...c })) || []), [_clients, redirectPath])

  const { fetchClientsBookmarks } = useFetchClientsBookmarks()

  useEffect(() => {
    if (!isEmpty(clients)) {
      const clientIds = clients.map(({ clientId }) => clientId)
      fetchClientsBookmarks(clientIds)
    }
  }, [fetchClientsBookmarks, clients])

  const { columns } = useColumns({ columnsConfig })

  if (!isLoading && isEmpty(clients)) {
    return (
      <EmptySection
        title='No clients found'
        description=''
        titleStyles={{
          fontSize: '2rem !important',
          fontWeight: 'bold'
        }}
      />
    )
  }

  return (
    <OperationalTable
      mode='server'
      itemName='Clients'
      total={total}
      data={clients}
      columns={columns}
      loading={isLoading || isLoadingRedirectPath}
      searchText=''
      defaultSort={sort}
      onPagingChange={onPagingChange}
      onSortingChange={onSortingChange}
      onTableModeChange={onTableModeChange}
    />
  )
}

ClientsTable.propTypes = {
  columnsConfig: PropTypes.object
}

ClientsTable.defaultProps = {
  columnsConfig: defaultColumnConfig
}

export default ClientsTable
