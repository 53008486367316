import React, { useCallback, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import Select from '../../molecules/Select'
import SydButton from '../../commonDesign/Button'
import { usePolicy } from '../../../hooks/usePolicy'
import { useModifyStatusReportItemStatusMutation } from '../../../api/groups'
import StatusDisplay from './StatusDisplay'

const useStyles = makeStyles((theme) => ({
  statusChange: {
    padding: '0 1.5rem 16px',
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
    gap: '10px',
    '& .__option': {
      padding: '15px 15px',
      display: 'flex',
      gap: '10px'
    }
  },
  option: {
    padding: '15px 15px',
    display: 'flex',
    gap: '10px',
    transform: 'background-color 200ms ease-in-out',
    backgroundColor: theme.palette.paper,
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.palette.gray.main
    }
  },
  label: {
    display: 'flex',
    gap: '10px',
    alignItems: 'center'
  }
}))

const StatusOption = (classes) => (opt, onChange) => {
  return (
    <div className={classes.option} onClick={(e) => onChange(e, opt.value)}>
      <StatusDisplay statusInfo={opt} />
      <span>{opt.displayName}</span>
    </div>
  )
}

/* eslint-disable react/prop-types */
const StatusLabel = (classes) => ({ selectedValue, placeholder }) => {
  if (!selectedValue) {
    return (
      <div className={classes.label}>
        {placeholder}
      </div>
    )
  }

  return (
    <div className={classes.label}>
      <StatusDisplay statusInfo={selectedValue} />
      <span>{selectedValue.displayName}</span>
    </div>
  )
}
/* eslint-enable react/prop-types */

function ItemStatusEditor ({ item, statusInfo, statuses, isFetching }) {
  const classes = useStyles()
  const canEditStatus = usePolicy('status_report_edit')
  const [value, setValue] = useState(statusInfo.statusId)
  const statusOptions = useMemo(() => {
    return statuses.map(s => ({
      ...s,
      value: s.statusId,
      label: s.displayName
    }))
  }, [statuses])
  const { mutateAsync: modifyItemStatus, isLoading } = useModifyStatusReportItemStatusMutation()
  const handleClick = useCallback(async () => {
    try {
      await modifyItemStatus({
        statusReportId: item.statusReportId,
        statusReportItemId: item.statusReportItemId,
        status: value
      })
    } catch (err) {
      console.error('error updating status report status item status', err)
    }
  }, [modifyItemStatus, item, value])

  if (!item) return null
  if (!canEditStatus) return null

  return (
    <div className={classes.statusChange}>
      <Select
        fullWidth
        variant='outlined-rounded'
        options={statusOptions || []}
        optionsRenderer={StatusOption(classes)}
        labelRenderer={StatusLabel(classes)}
        defaultValue={statusInfo.statusId}
        value={value}
        onChange={setValue}
        disabled={isLoading || isFetching}
      />
      <SydButton
        variant='primary'
        size='sm'
        onClick={handleClick}
        processing={isLoading}
        disabled={isFetching}
      >
        Apply
      </SydButton>
    </div>
  )
}

ItemStatusEditor.propTypes = {
  item: PropTypes.shape({
    displayName: PropTypes.string,
    status: PropTypes.number,
    statusReportId: PropTypes.number,
    statusReportItemId: PropTypes.number
  }),
  statusInfo: PropTypes.any,
  statuses: PropTypes.array,
  isFetching: PropTypes.bool
}

export default ItemStatusEditor
