import React, { useCallback, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useGroupSearch } from '../../../../api/groups'
import { getSuppression, setSuppression } from '../../../../utils/globalSuppression'
import GroupScopeSelector from './GroupScopeSelector'

const getInitials = str => {
  if (!str) return null

  const names = str.trim().split(' ')
  if (names.length === 0) return null

  // Ignore the last name if it's a suffix like Jr., Sr., etc.
  const suffixes = ['Jr', 'Sr', 'II', 'III', 'IV']
  const filteredNames = names.filter((name, i) =>
    i < 2 || (i === 2 && !suffixes.includes(name.replace(/\./g, '')))
  )

  const initials = filteredNames.slice(0, 2).map(name => name[0].toUpperCase())

  return initials.join('')
}

const useGroupTypeOptions = (scope, includeAdvisors, myAdvisor) => {
  const scopeQuery = useMemo(() => ({
    filters: {
      levelTypeId: [{
        value: scope ? scope.groupTypeId : 301,
        op: 'eq'
      }]
    },
    sort: [{ field: 'longName', dir: 'asc' }]
  }), [scope])

  const { data, isLoading } = useGroupSearch(scopeQuery, { enabled: includeAdvisors })
  const { options, pinnedOptions } = useMemo(() => {
    const pinnedResult = []
    if (myAdvisor) {
      pinnedResult.push({
        label: 'My Clients',
        value: JSON.stringify({ type: 301, id: myAdvisor.groupId }),
        payload: {
          type: 301,
          id: myAdvisor.groupId,
          avatarUrl: myAdvisor.extras?.profileData?.profilePic,
          abbreviation: 'My'
        }
      })
    }

    pinnedResult.push({
      label: 'All Clients',
      value: JSON.stringify({ type: 1001, id: null }),
      payload: { type: 1001, id: null, abbreviation: 'All' }
    })

    const result = []
    if (includeAdvisors) {
      const d = data?.data || []
      d.filter(x => x.levelTypeId !== 301 || (x.levelTypeId === 301 && x.groupId !== myAdvisor?.groupId)).forEach(g => {
        result.push({
          label: g.longName,
          value: JSON.stringify({ type: g.levelTypeId, id: g.groupId }),
          payload: {
            type: g.levelTypeId,
            id: g.groupId,
            avatarUrl: g.profile?.profileData?.profilePic,
            abbreviation: getInitials(g.longName)
          }
        })
      })
    }

    return {
      options: result,
      pinnedOptions: pinnedResult
    }
  }, [data, myAdvisor, includeAdvisors])

  return { options, pinnedOptions, isLoading }
}

const useOnChangeGroupScope = (scope, setScope) => {
  const onChangeGroupScope = useCallback((group) => {
    const levelTypeId = group.payload.type
    const groupId = group.payload.id

    const scopeValues = levelTypeId === 1001 ? [] : [groupId]

    // setScope sets url state and such appropriately
    setScope({ values: scopeValues })
  }, [setScope])

  const currentValue = useMemo(() => {
    const type = scope?.values?.length ? scope.groupTypeId : 1001
    return JSON.stringify({ type, id: type === 1001 ? null : scope.values?.at(0) })
  }, [scope])

  return { onChangeGroupScope, currentValue }
}

const GroupScopeDisplay = ({
  scope,
  setScope,
  headingLabel,
  includeAdvisors,
  myAdvisor,
  isLoading,
  hideAvatars,
  size,
  disableReset
}) => {
  const { onChangeGroupScope, currentValue } = useOnChangeGroupScope(scope, setScope)
  const { options, pinnedOptions, isLoading: optionsLoading } = useGroupTypeOptions(scope, includeAdvisors, myAdvisor)
  /** If we've selected something we don't have in our list, it is invalid */
  const isValid = useMemo(() => {
    return options.some(x => x.value === currentValue) || pinnedOptions.some(x => x.value === currentValue)
  }, [currentValue, options, pinnedOptions])

  /**
   * If we've selected an invalid selection, then try to reset ONE TIME
   */
  useEffect(() => {
    if (disableReset) return

    const key = 'GroupScopeDisplay'
    const suppression = getSuppression(key)
    if (!suppression && !isValid && !isLoading && !optionsLoading) {
      setSuppression(key, true)
      onChangeGroupScope(pinnedOptions.at(0))
    } else if (!suppression && !isLoading && !optionsLoading) {
      setSuppression(key, true)
    }
  }, [isValid, onChangeGroupScope, pinnedOptions, isLoading, optionsLoading, scope, disableReset])

  return (
    <div>
      <GroupScopeSelector
        onChange={onChangeGroupScope}
        label={headingLabel}
        options={options}
        pinnedOptions={pinnedOptions}
        isLoading={isLoading || optionsLoading}
        value={currentValue}
        isValid={isValid}
        hideAvatars={hideAvatars}
        size={size}
      />
    </div>
  )
}

GroupScopeDisplay.propTypes = {
  scope: PropTypes.object,
  setScope: PropTypes.func,
  headingLabel: PropTypes.string,
  includeAdvisors: PropTypes.bool,
  myAdvisor: PropTypes.shape({
    groupId: PropTypes.string,
    shortName: PropTypes.string,
    longName: PropTypes.string
  }),
  isLoading: PropTypes.bool,
  hideAvatars: PropTypes.bool,
  size: PropTypes.oneOf(['sm', 'lg']),
  disableReset: PropTypes.bool
}

export default GroupScopeDisplay
