// We define the degrees from the center we want the first preview to be displayed.
const firstViewDegree = {
  1: 180,
  2: 180,
  3: 210,
  4: 135,
  5: 162,
  6: 150,
  7: 141.43,
  8: 180
}

// formula to defined the degree of each preview depending on its index
const degPositions = (total, index) => {
  const startAt = firstViewDegree[total]
  return startAt - index * 360 / total
}

function getViewPosition (total, index, fullscreen = true) {
  // position, in degrees, of the preview
  const deg = degPositions(total, index)

  if (index < 0 || index > total || total > 8 || typeof deg !== 'number') return [0, 0]

  // let distanceFromCenter = fullscreen ? 350 : 38

  let distanceFromCenterX = fullscreen ? 36 : 28
  let distanceFromCenterY = fullscreen ? 34 : 20

  // the top preview should be "closer" so it looks like it is at the same distance
  if ([3, 5].includes(total)) {
    distanceFromCenterX = fullscreen ? 28 : 26
    distanceFromCenterY = fullscreen ? 34 : 20
    if ([3, 4].includes(index)) {
      distanceFromCenterX = fullscreen ? 32 : 26
      distanceFromCenterY = fullscreen ? 40 : 24
    }
  }

  if (total === 6) {
    distanceFromCenterX = fullscreen ? 32 : 28
    distanceFromCenterY = fullscreen ? 36 : 22
    if ([1, 4].includes(index)) {
      distanceFromCenterY = fullscreen ? 34 : 22
    }
  }

  if (total === 7) {
    distanceFromCenterX = fullscreen ? 32 : 28
    distanceFromCenterY = fullscreen ? 36 : 22
    if ([3, 6].includes(index)) {
      distanceFromCenterX = fullscreen ? 36 : 30
    }
    if ([4, 5].includes(index)) {
      distanceFromCenterX = fullscreen ? 28 : 28
    }
  }

  if (total === 8) {
    distanceFromCenterX = fullscreen ? 32 : 30
    distanceFromCenterY = fullscreen ? 40 : 22
    if ([2, 6].includes(index)) {
      distanceFromCenterY = fullscreen ? 34 : 22
    }
  }

  const rad = deg * Math.PI / 180
  // distance from the center is measured in px
  // const x = distanceFromCenter * Math.cos(rad)
  // const y = distanceFromCenter * Math.sin(rad)
  const x = distanceFromCenterX * Math.cos(rad)
  const y = distanceFromCenterY * Math.sin(rad)
  return [x, y]
}

export default getViewPosition
