import { useCallback, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import debounce from 'lodash/debounce'
import { DEFAULT_DEBOUNCE_DELAY_IN_MILLISECONDS } from '../constants'

export function useAction (action, payloadFn, extraPayload = undefined) {
  const dispatch = useDispatch()
  const dispatchAction = useCallback(
    (payload) => {
      const finalPayload = extraPayload
        ? { ...extraPayload, ...payloadFn(payload) }
        : payloadFn(payload)
      return dispatch(action(finalPayload))
    },
    [action, dispatch, extraPayload, payloadFn]
  )

  return dispatchAction
}

export function useAsyncAction (action) {
  const dispatch = useDispatch()

  const dispatchAction = useCallback(
    (payload) => dispatch(action(payload)),
    [action, dispatch]
  )
  return dispatchAction
}

export function useAsyncActionDebounced (action) {
  const dispatch = useDispatch()

  const dispatchAction = useMemo(
    () =>
      debounce(
        (payload) => dispatch(action(payload)),
        DEFAULT_DEBOUNCE_DELAY_IN_MILLISECONDS,
        {
          leading: false,
          trailing: true
        }
      ),
    [action, dispatch]
  )
  return dispatchAction
}
