import { useMemo } from 'react'
import { useFormattingContext } from '../components/organisms/FormattingProvider/FormattingContext'

export const useConfigColumns = ({ columnsConfig, format }) => {
  const { formatter } = useFormattingContext(format)

  return useMemo(() => {
    const { columns, defaultSort } = columnsConfig
    const formattedColumns = columns.map((column) => {
      return {
        ...column,
        Cell: ({ value }) => formatter(value, column.format)
      }
    })

    return {
      columns: formattedColumns,
      defaultSort
    }
  }, [columnsConfig, formatter])
}
