import React, { useMemo } from 'react'
import dayjs from 'dayjs'
import numeral from 'numeral'

export const cellTemplates = {
  /* eslint-disable-next-line jsx-a11y/anchor-is-valid */
  link: (onClick, accessor) => ({ value }) => (<a href='#' onClick={onClick(value)}>{accessor(value)}</a>),
  date: ({ value }) => dayjs.utc(value).format('M/D/YYYY'),
  money: ({ value }) => numeral(value).format('0,0'),
  returns: ({ value }) => numeral(value).format('0.00%')
}

function compareNumericString (rowA, rowB, id, desc) {
  let a = Number.parseFloat(rowA.values[id])
  let b = Number.parseFloat(rowB.values[id])
  if (Number.isNaN(a)) {
    a = desc ? Number.NEGATIVE_INFINITY : Number.POSITIVE_INFINITY
  }
  if (Number.isNaN(b)) {
    b = desc ? Number.NEGATIVE_INFINITY : Number.POSITIVE_INFINITY
  }
  if (a > b) return 1
  if (a < b) return -1
  return 0
}

const columnTemplateConfigurators = {
  string: col => col,
  money: col => ({
    ...col,
    Cell: cellTemplates.money,
    alignRight: true,
    sortType: compareNumericString
  }),
  returns: col => ({
    ...col,
    Cell: cellTemplates.returns,
    alignRight: true,
    sortType: compareNumericString
  })
}

export const defaultColumnConfig = {
  columns: [
    { Header: 'Name', accessor: 'levelName', type: 'string' },
    { accessor: 'previousValue', Header: 'Starting Value', type: 'money' },
    { accessor: 'netChange', Header: 'Net Gain', type: 'money' },
    { accessor: 'netAdditions', Header: 'Net Additions', type: 'money' },
    { accessor: 'endingValue', Header: 'Ending Value', type: 'money' },
    { accessor: 'cumulativeReturn', Header: 'Return', type: 'returns' }
  ],
  defaultSort: [{ id: 'endingValue', desc: true }]
}

export const annualizedColumnConfig = {
  columns: [
    { Header: 'Name', accessor: 'levelName', type: 'string' },
    { accessor: 'previousValue', Header: 'Starting Value', type: 'money' },
    { accessor: 'netChange', Header: 'Net Gain', type: 'money' },
    { accessor: 'netAdditions', Header: 'Net Additions', type: 'money' },
    { accessor: 'endingValue', Header: 'Ending Value', type: 'money' },
    { accessor: 'annualizedReturn', Header: 'Return', type: 'returns' }
  ],
  defaultSort: [{ id: 'endingValue', desc: true }]
}

export const columnConfigMap = {
  default: defaultColumnConfig,
  cumulative: defaultColumnConfig,
  annualized: annualizedColumnConfig
}

export const useColumnConfig = (config = 'default', override = null) => {
  const rawColumns = useMemo(() => {
    if (override) return override
    const normalized = config.toLowerCase()
    return (normalized in columnConfigMap)
      ? columnConfigMap[normalized]
      : columnConfigMap.default
  }, [config, override])

  return useMemo(() => {
    return {
      columns: rawColumns.columns.map(x => {
        const type = (x.type ?? 'string') in columnTemplateConfigurators ? x.type : 'string'
        return {
          ...x,
          ...(columnTemplateConfigurators[type](x))
        }
      }),
      defaultSort: rawColumns.defaultSort
    }
  }, [rawColumns])
}
