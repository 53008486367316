import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    position: 'relative'
  },
  progressBar: props => ({
    margin: 0,
    padding: '5px 0',
    height: '32px',
    backgroundColor: props.backgroundColor || theme.palette.seaFoam,
    backgroundSize: '0 100%',
    backgroundRepeat: 'no-repeat',
    borderRadius: props.squared ? 0 : '20px',
    width: '100%',
    ...props.animate && { transition: 'background-size 1.4s ease-in-out' }
  }),
  percentage: props => ({
    ...props.animate && { transition: 'left 1.4s ease-in-out' },
    position: 'absolute',
    top: '4px'
  }),
  thresholdLine: props => ({
    ...props.animate && { transition: 'left 1.4s ease-in-out, opacity 1.4s ease-in-out' },
    position: 'absolute',
    top: '-4.5px',
    bottom: '-4.5px',
    width: '2px',
    boxSizing: 'border-box',
    background: '#999999',
    borderRight: '1px solid ffffff99',
    borderLeft: '1px solid ffffff99'
  }),
  thresholdTopLabel: ({
    top: '-22px !important'
  }),
  thresholdLabel: props => ({
    ...props.animate && { transition: 'left 1.4s ease-in-out, opacity 1.4s ease-in-out' },
    position: 'absolute',
    top: '22px',
    transform: 'translateX(-50%)'
  })
}))

const ProgressBar = ({
  additionalClasses,
  animate,
  backgroundColor,
  color,
  percentage: rawPercentage,
  showPercentage,
  squared,
  thresholdLabel,
  thresholds
}) => {
  const percentage = Number(rawPercentage?.toFixed(0))
  const classes = useStyles({ animate, backgroundColor, squared })
  const [mounted, setMounted] = useState(false)
  const [hidden, setHidden] = useState(false)

  useEffect(() => {
    const timeouts = [
      setTimeout(() => setMounted(true), 10),
      setTimeout(() => setHidden(true), 5000)
    ]
    return () => timeouts.map(clearTimeout)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className={classes.container}>
      {thresholds.map((threshold, idx) => (
        <div
          key={`${idx}`}
          className={classes.thresholdLine}
          style={{ left: `${mounted ? threshold : 0}%`, opacity: threshold && mounted ? 1 : 0 }}
        />
      ))}
      {showPercentage ? (
        <Typography
          component='div'
          className={classes.percentage}
          variant='body2'
          style={{ left: `calc(15px + ${mounted ? percentage : 0}%)` }}
        >
          {percentage}%
        </Typography>
      ) : null}
      {thresholdLabel !== '' && thresholds.length >= 1 ? (
        <Typography
          component='div'
          className={clsx(classes.thresholdLabel, { [classes.thresholdTopLabel]: thresholds.length === 1 })}
          style={{
            left: `${mounted ? (
              thresholds.length >= 1
                ? thresholds.reduce((a, b) => a + b, 0) / thresholds.length
                : thresholds[0]
            ) : 0}%`,
            opacity: hidden && thresholds.length === 1 ? 0 : 1
          }}
        >
          {thresholdLabel}
        </Typography>
      ) : null}
      <div
        className={clsx(classes.progressBar, additionalClasses)}
        style={{
          backgroundSize: `${mounted ? percentage : 0}% 100%`,
          backgroundImage: `linear-gradient(-90deg, transparent 0%, ${color} 0)`
        }}
      />
    </div>

  )
}

ProgressBar.propTypes = {
  additionalClasses: PropTypes.string,
  animate: PropTypes.bool,
  backgroundColor: PropTypes.string,
  color: PropTypes.string,
  percentage: PropTypes.number.isRequired,
  showPercentage: PropTypes.bool,
  squared: PropTypes.bool,
  thresholdLabel: PropTypes.string,
  thresholds: PropTypes.arrayOf(PropTypes.number)
}

ProgressBar.defaultProps = {
  additionalClasses: '',
  animate: false,
  backgroundColor: '',
  color: 'blue',
  showPercentage: false,
  squared: false,
  thresholdLabel: '',
  thresholds: []
}

export default React.memo(ProgressBar)
