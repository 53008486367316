import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import {
  useConversationDetails, useAddMessageMutation, useConversationUpdates, useJoinConversationMutation
} from '../../../../../api/conversations'
import { useInternalUserId } from '../../../../../redux/slices/appContext'
import Message from './Message'
import MessageInput from './MessageInput'
import ThreadHeader from './ThreadHeader'
import EmptyState from './EmptyState'
import CreateConversationForm from './CreateConversationForm'

const useStyles = makeStyles(theme => ({
  conversationPanel: {
    position: 'relative',
    flex: '1 0 auto',
    height: 'calc(100vh - 10rem)'
  },
  scrollable: {
    padding: '.5rem',
    overflowY: 'scroll',
    height: 'calc(100% - 180px)'
  }
}))

const useJoinConversation = (conversationKey) => {
  const internalUserId = useInternalUserId()
  const { mutateAsync: joinConversation, isLoading } = useJoinConversationMutation()
  const handleJoin = useCallback(() => {
    joinConversation({ conversationKey, userId: internalUserId }).catch(console.error)
  }, [joinConversation, conversationKey, internalUserId])

  return {
    handleJoin,
    isLoading
  }
}

const useAddMessage = (conversationKey) => {
  const { mutateAsync: createMessage, isLoading } = useAddMessageMutation()
  const handleAddMessage = useCallback((message) => {
    createMessage({
      conversationKey,
      message
    }).catch(console.error)
  }, [conversationKey, createMessage])

  return {
    handleAddMessage,
    isLoading
  }
}

const ConversationThread = ({ selectedConversation, clientId, isNew }) => {
  const classes = useStyles()
  const { data, isLoading, error } = useConversationDetails(selectedConversation)
  useConversationUpdates(selectedConversation)
  const { handleAddMessage } = useAddMessage(selectedConversation)
  const { handleJoin, isLoading: isJoining } = useJoinConversation(selectedConversation)

  const sortedMessage = useMemo(() => {
    if (data?.messages) {
      const sorted = [...data.messages]
      sorted.sort((b, a) => {
        return new Date(b.messageDateUtc) - new Date(a.messageDateUtc)
      })
      return sorted
    }
    return []
  }, [data?.messages])
  const scrollableRef = useRef()
  useEffect(() => {
    const el = scrollableRef.current
    if (el) {
      el.scrollTop = el.scrollHeight - el.clientHeight
    }
  }, [sortedMessage.length])

  if (selectedConversation && isLoading) {
    return (
      <div className={classes.conversationPanel}>
        &nbsp;
      </div>
    )
  }

  if (error) {
    return (
      <div className={classes.conversationPanel}>
        {error.message}
      </div>
    )
  }

  if (isNew) {
    return (
      <div className={classes.conversationPanel}>
        <CreateConversationForm clientId={clientId} />
      </div>
    )
  }

  if (!selectedConversation) {
    return (
      <div className={classes.conversationPanel}>
        <EmptyState />
      </div>
    )
  }

  return (
    <div className={classes.conversationPanel}>
      <ThreadHeader conversation={data.conversation} />
      <div ref={scrollableRef} className={classes.scrollable}>
        {sortedMessage.map(m => (
          <Message key={m.messageKey} message={m} participants={data.participants} />
        ))}
      </div>
      <MessageInput
        onMessageEntered={handleAddMessage}
        onConversationJoined={handleJoin}
        isJoining={isJoining}
        participants={data.participants}
      />
    </div>
  )
}

ConversationThread.propTypes = {
  selectedConversation: PropTypes.string,
  clientId: PropTypes.number,
  isNew: PropTypes.bool
}

ConversationThread.defaultProps = {
  isNew: false
}

export default ConversationThread
