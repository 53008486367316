import React from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import LoadingView from '../LoadingView'
import { useHideSideMenuEffect } from '../../../redux/slices/appContext'
import DefaultRedirect from './DefaultRedirect'
import { useTemplateConfiguration } from './useTemplateConfiguration'
import { NavigationProvider } from './NavigatationContext'
import ChooseTemplate from './ChooseTemplate'
import Template from './Template'
import NotFound from './NotFound'
import ViewListing from './ViewListing'

const AdvisorHomeIndex = () => {
  const { path } = useRouteMatch()
  useHideSideMenuEffect([])
  const templateConfiguration = useTemplateConfiguration(path)

  if (templateConfiguration.error) {
    return (
      <div>There was an error: {templateConfiguration.error?.toString()}</div>
    )
  }

  if (templateConfiguration.isLoading) {
    return <LoadingView height='100%' />
  }

  return (
    <NavigationProvider templateConfiguration={templateConfiguration}>
      <Switch>
        <Route exact path={`${path}/`}>
          <DefaultRedirect />
        </Route>
        <Route exact path={`${path}/templates`}>
          <ChooseTemplate />
        </Route>
        <Route exact path={`${path}/not-found`}>
          <NotFound />
        </Route>
        <Route exact path={`${path}/:template/views`}>
          <ViewListing />
        </Route>
        <Route path={`${path}/:template/`}>
          <Template />
        </Route>
        <Route path='*'>
          <NotFound />
        </Route>
      </Switch>
    </NavigationProvider>

  )
}

export default AdvisorHomeIndex
