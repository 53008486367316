import React, { useCallback } from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'
import PropTypes from 'prop-types'
import { Grid, makeStyles } from '@material-ui/core'
import Skeleton from '../../../atoms/Skeleton'
import { findFieldIndexById } from '../LevelTags/helpers'
import { useCheckPolicy } from '../../../../hooks'
import { ACCOUNTS } from '../../../../policies/admin'
import FormSelectField from './FormSelectField'

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: '20px',
    marginBottom: '20px'
  },
  selectors: {
    display: 'flex',
    flexDirection: 'columns'
  },
  selector: {
    marginBottom: '18px',
    padding: '0px 10px 10px 0'
  }
}))

const LevelTags = ({ name, isLoading, editMode, tagOptions }) => {
  const classes = useStyles()
  const { control } = useFormContext()
  const { fields, update, insert } = useFieldArray({
    control,
    name
  })
  const userCanEditTags = useCheckPolicy(ACCOUNTS.editAccountTags)

  const onChangeGroupType = useCallback(
    (groupTypeId, index) => ({ target }) => {
      const groupId = target.value
      const { id: fieldId } =
        fields.find(
          ({ groupTypeId: _groupTypeId }) => _groupTypeId === groupTypeId
        ) || {}

      const tag = { groupId, groupTypeId }

      if (fieldId) {
        const fieldIndex = findFieldIndexById(fieldId, fields)
        update(fieldIndex, tag)
      } else {
        insert(index, tag)
      }
    },
    [fields, update, insert]
  )

  if (isLoading) {
    return (
      <div className={classes.selectors}>
        <Skeleton
          width='10rem'
          height='1.5rem'
          className={classes.selector}
        />
        <Skeleton
          width='10rem'
          height='1.5rem'
          className={classes.selector}
        />
        <Skeleton
          width='10rem'
          height='1.5rem'
          className={classes.selector}
        />
      </div>
    )
  }

  return (
    <Grid item container>
      <Grid container item xs={12}>
        {tagOptions?.map(({ label, value, options }, index) => {
          const selectedOption = fields.find(
            ({ groupTypeId }) => groupTypeId === value
          )
          let valueLabel = 'Not assigned'
          if (selectedOption) {
            valueLabel = options.find(option => option.value === selectedOption.groupId).label
          }
          return (
            <FormSelectField
              key={value}
              label={label}
              accessor={label}
              editMode={editMode && userCanEditTags}
              value={selectedOption?.groupId}
              options={options}
              text={valueLabel}
              onChange={onChangeGroupType(value, index)}
            />
          )
        })}
      </Grid>
    </Grid>
  )
}

LevelTags.propTypes = {
  name: PropTypes.string,
  editMode: PropTypes.bool,
  tagOptions: PropTypes.array,
  isLoading: PropTypes.bool
}

export default LevelTags
