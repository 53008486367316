import React from 'react'
import { RUN_DISPLAY_STATUS } from '../../../runBilling/helpers'
import CellActions from '../cells/CellActions'
import CellLevelType from '../cells/CellLevelType'

export const defaultColumnConfig = {
  columns: [
    'billingRunId',
    'createdAt',
    'levelType',
    'asOfDate',
    'startDate',
    'endDate',
    'frequency',
    'status',
    'runBy',
    'actions'
  ],
  defaultSort: [{ id: 'createdAt', desc: true }]
}

export const presetColumnsMap = new Map([
  ['billingRunId', { Header: 'ID', accessor: 'billingRunId' }],
  ['createdAt', { Header: 'Run Date', accessor: 'createdAt', format: '&MM/DD/YY hh:mm a', width: 160 }],
  ['levelType', { Header: 'Level', accessor: 'levelType', Cell: (props, handlers) => (<CellLevelType {...props} {...handlers} />) }],
  ['asOfDate', { Header: 'As of Date', accessor: 'asOfDate', format: '@MM/DD/YY' }],
  ['startDate', { Header: 'Start Date', accessor: 'startDate' }],
  ['endDate', { Header: 'End Date', accessor: 'endDate' }],
  ['frequency', { Header: 'Frequency', accessor: 'frequency' }],
  ['status', { Header: 'Report Status', accessor: (row) => RUN_DISPLAY_STATUS[row.status] ?? row.status }],
  ['runBy', { Header: 'Run By', accessor: (row) => `${row.runBy.firstName} ${row.runBy.lastName}` }],
  ['actions', { Header: 'Details', width: 160, accessor: 'actions', Cell: (props, handlers) => (<CellActions {...props} {...handlers} />) }]
])
