import { configureStore } from '@reduxjs/toolkit'
import reducer from './slices'

function configure ({ initialState = {} } = {}) {
  const store = configureStore({
    reducer,
    preloadedState: initialState,
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
        immutableCheck: false,
        serializableCheck: false
      })
  })
  return store
}

export default configure
