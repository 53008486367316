import React from 'react'
import { Grid } from '@material-ui/core'
import Skeleton from '../../../atoms/Skeleton'

const ClientIntegrationMapperSkeleton = () => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Skeleton variant='text' height='1rem' width='40%' />
        <Skeleton variant='text' height='2rem' width='50%' />
      </Grid>
      <Grid item xs={6}>
        <Skeleton variant='text' width='90%' />
        <Skeleton variant='text' height='2rem' width='80%' />
      </Grid>
      <Grid item xs={6}>
        <Skeleton variant='text' width='90%' />
        <Skeleton variant='text' height='2rem' width='70%' />
      </Grid>
      <Grid item xs={12}>
        <Skeleton variant='text' width='50%' />
        <Skeleton variant='text' height='2rem' width='50%' />
      </Grid>
    </Grid>
  )
}

export default ClientIntegrationMapperSkeleton
