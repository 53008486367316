import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Box, Checkbox, FormControlLabel, FormGroup, Grid, Typography, useTheme } from '@material-ui/core'
import { RemoveCircleOutline } from '@material-ui/icons'
import IconButton from '@material-ui/core/IconButton'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import { useQueryClient } from '@tanstack/react-query'
import RoundedButton from '../../../../atoms/RoundedButton'
import {
  BUTTON_SIZES,
  BUTTON_VARIANT,
  SKELETON_VARIANTS,
  TEXT_VARIANTS,
  WEALTHBOX_TAG_GROUP_TYPE_ID
} from '../../../../../constants'
import InputColorPicker from '../../../../molecules/InputColorPicker'
import Divider from '../../../../atoms/Divider'
import { useGroupTypeSearch } from '../../../../../api/groups'
import { QUERY_KEYS } from '../../../../../api/queryKeys'
import { useAppContext } from '../../../../../redux/slices/appContext'
import { useWealthboxUnlinkedTags } from '../../../../../api/wealthbox'
import { wealthboxSyncLinkedTags } from '../../../../../service'
import Skeleton from '../../../../atoms/Skeleton'

const useStyles = makeStyles((theme) => ({
  container: {
    marginLeft: 0,
    marginTop: 0,
    marginBottom: 0,
    paddingRight: '30px'
  },
  removeTagButton: {
    color: '#D44333',
    padding: '0'
  },
  selectedTag: {},
  selectedTagName: {
    width: '50%'
  },
  selectedTagColor: {
    borderRadius: '5px',
    width: '25%',
    textAlign: 'center'
  },
  selectedTagDeleteBtn: {
    textAlign: 'right'
  },
  selectedTagsTable: {
    width: '100%'
  },
  bucket: {
    padding: '10px',
    border: `1px solid ${theme.palette.gray.main} !important`,
    borderRadius: '5px',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: 1.6
  },
  bucketTitle: {
    color: theme.palette.gray.A600
  },
  checkBoxRoot: {
    color: `${theme.palette.primary.main} !important`
  },
  onEditActionButtons: {
    padding: '4px 70px 2px'
  },
  footerDivider: {
    margin: 0
  }
}))

const mapUnlinkedTags = (tags) => {
  return tags.map(({ name }) => ({
    name,
    color: '#FFFFFF',
    unlinked: true
  }))
}

const mapGroupTypes = ({ data: groupTypes }) => {
  const groupType = groupTypes.find(({ groupTypeId }) => Number(groupTypeId) === WEALTHBOX_TAG_GROUP_TYPE_ID)

  if (groupType) {
    const {
      groupTypeId,
      longName: groupTypeLongName,
      groups
    } = groupType

    return {
      label: groupTypeLongName,
      value: groupTypeId,
      groups: groups.map(({
        groupId,
        longName,
        extras2
      }) => ({
        groupId,
        name: longName,
        color: extras2,
        unlinked: false
      }))
    }
  }

  return null
}

const getQuery = () => {
  return {
    query: {
      includes: {
        groups: true
      },
      filters: {
        groupTypeId: [{
          op: 'eq',
          value: WEALTHBOX_TAG_GROUP_TYPE_ID,
          combine: 'and'
        }]
      }
    },
    queryOptions: { mapper: mapGroupTypes }
  }
}

function WealthboxManageView () {
  const classes = useStyles()
  const [selectedTags, setSelectedTags] = useState([])
  const [isEditing, setIsEditing] = useState(false)

  const {
    query,
    queryOptions
  } = useMemo(() => getQuery(), [])

  const {
    data: groupTypes,
    isFetching: isFetchingGroups
  } = useGroupTypeSearch(query, queryOptions)

  const {
    data: unlinkedTags,
    isFetching: isFetchingUnlinkedTags
  } = useWealthboxUnlinkedTags({ mapper: mapUnlinkedTags })

  const { userId } = useAppContext()
  const theme = useTheme()
  const groupsQueryKey = useMemo(() => [QUERY_KEYS.searchGroupTypes, userId, query], [query, userId])
  const unlinkedTagsQueryKey = useMemo(() => [QUERY_KEYS.wealthboxUnlinkedTags, userId], [userId])
  const queryClient = useQueryClient()

  const invalidateCachedRequests = useCallback(() => {
    return Promise.all([
      queryClient.invalidateQueries({
        queryKey: groupsQueryKey,
        exact: true
      }),
      queryClient.invalidateQueries({
        queryKey: unlinkedTagsQueryKey,
        exact: true
      })
    ])
  }, [queryClient, groupsQueryKey, unlinkedTagsQueryKey])

  const methods = useForm({
    mode: 'all',
    defaultValues: {
      includedTags: [],
      availableTags: []
    }
  })

  const {
    formState: { isDirty },
    control,
    getValues,
    reset
  } = methods

  const {
    remove: removeFromIncludedTags,
    append: appendToIncludedTags,
    fields: includedTagsFields
  } = useFieldArray({
    name: 'includedTags',
    control
  })

  const {
    remove: removeFromAvailableTags,
    append: appendToAvailableTags,
    fields: availableTagsFields
  } = useFieldArray({
    name: 'availableTags',
    control
  })

  const isFetching = useMemo(() => (isFetchingGroups || isFetchingUnlinkedTags), [isFetchingGroups, isFetchingUnlinkedTags])

  useEffect(() => {
    if (groupTypes?.groups && !isFetchingGroups && !isFetchingUnlinkedTags && unlinkedTags) {
      reset({
        availableTags: unlinkedTags,
        includedTags: groupTypes.groups
      })
    }
  }, [groupTypes?.groups, reset, isFetchingGroups, isFetchingUnlinkedTags, unlinkedTags])

  const onDeleteTag = useCallback((tag, idx) => {
    const available = [...getValues('availableTags')]
    available.push(tag)
    const sorted = available.sort((a, b) => a.name.localeCompare(b.name))
    removeFromIncludedTags(idx)
    removeFromAvailableTags(getValues('availableTags'))
    appendToAvailableTags([...sorted])
  }, [removeFromIncludedTags, appendToAvailableTags, removeFromAvailableTags, getValues])

  const handleTagSelection = useCallback((tag) => {
    const isSelection = !selectedTags.find(t => t.name === tag.name)
    if (isSelection) {
      setSelectedTags([...selectedTags, tag])
    } else {
      setSelectedTags([...selectedTags.filter(t => t.name !== tag.name)])
    }
  }, [selectedTags])

  const addSelectedTags = useCallback(() => {
    const included = [...getValues('includedTags'), ...selectedTags]
    const sorted = included.sort((a, b) => a.name.localeCompare(b.name))
    removeFromIncludedTags(getValues('includedTags'))
    appendToIncludedTags([...sorted])
    const selectedTagNames = selectedTags.map(t => t.name)
    const idxsToRemove = []

    availableTagsFields.forEach((tag, idx) => {
      if (selectedTagNames.includes(tag.name)) {
        idxsToRemove.push(idx)
      }
    })

    removeFromAvailableTags(idxsToRemove)
    setSelectedTags([])
  }, [getValues, selectedTags, removeFromIncludedTags, appendToIncludedTags, availableTagsFields, removeFromAvailableTags])

  const handleOnEditAction = useCallback(() => {
    if (isEditing) {
      setSelectedTags([])
    }
    setIsEditing(!isEditing)
  }, [isEditing])

  const onCancel = useCallback(() => {
    if (isEditing) {
      setSelectedTags([])
    }
    setIsEditing(!isEditing)
    reset()
  }, [isEditing, reset])

  const onSave = useCallback(async () => {
    const upsert = getValues('includedTags')
      .map(({
        unlinked,
        groupId,
        name,
        color
      }) => {
        const tag = {
          shortName: name,
          longName: name,
          extras2: color
        }

        if (!unlinked) {
          tag.groupId = groupId
        }

        return tag
      })

    const remove = getValues('availableTags')
      .filter(t => t.unlinked === false)
      .map(t => t.groupId)

    await wealthboxSyncLinkedTags(upsert, remove)
    invalidateCachedRequests().catch(console.error)
    setSelectedTags([])
    setIsEditing(false)
  }, [invalidateCachedRequests, getValues])

  return (
    <Box
      sx={{
        flexGrow: 1,
        height: '100%'
      }} className={classes.container}
    >
      {isFetching && (
        <Grid container style={{ paddingLeft: '20px' }}>
          <Skeleton width='100%' height='72px' variant={SKELETON_VARIANTS.text} />
          <Skeleton width='100%' height='72px' variant={SKELETON_VARIANTS.text} />
          <Skeleton width='100%' height='72px' variant={SKELETON_VARIANTS.text} />
          <Skeleton width='100%' height='72px' variant={SKELETON_VARIANTS.text} />
          <Skeleton width='100%' height='72px' variant={SKELETON_VARIANTS.text} />
        </Grid>
      )}
      {!isFetching && (
        <Box sx={{ p: 3, pb: '97px' }}>
          <Grid container justifyContent='space-between' style={{ marginBottom: '20px' }}>
            <Grid item><Typography variant={TEXT_VARIANTS.h2}>Client Tags</Typography></Grid>
            <Grid item>
              <RoundedButton
                variant={BUTTON_VARIANT.outlined}
                size={BUTTON_SIZES.small}
                disabled={isEditing}
                onClick={handleOnEditAction}
              >{isEditing ? 'Editing' : 'Edit'}
              </RoundedButton>
            </Grid>
          </Grid>
          <Grid container spacing={4} style={{ marginBottom: '20px' }}>
            <Grid item xs={12} sm={6} lg={4}>
              <div className={classes.bucket}>
                <Grid container style={{ marginBottom: '20px' }}>
                  <Typography variant={TEXT_VARIANTS.h6} className={classes.bucketTitle}>AVAILABLE TAGS</Typography>
                </Grid>
                <Grid container direction={isEditing ? 'row' : 'column'}>
                  {isEditing && (
                    <FormGroup
                      style={{
                        width: '100%',
                        paddingLeft: '8px'
                      }}
                    >
                      {availableTagsFields.map((tag, idx) =>
                        <FormControlLabel
                          key={tag.id}
                          control={
                            <Checkbox
                              style={{ padding: 0 }}
                              classes={{
                                root: classes.checkBoxRoot
                              }}
                              checked={!!selectedTags.find(t => t.name === tag.name)}
                              onChange={() => handleTagSelection(tag, idx)}
                            />
                          }
                          name={tag.name}
                          label={<Typography style={{ fontWeight: 700 }}>{tag.name}</Typography>}
                        />
                      )}
                    </FormGroup>
                  )}
                  {!isEditing && availableTagsFields.map(tag => <Grid item key={tag.id}>{tag.name}</Grid>)}
                </Grid>
              </div>
              {isEditing && (
                <Grid container style={{ marginTop: '20px' }} justifyContent='center'>
                  <RoundedButton
                    size={BUTTON_SIZES.small}
                    variant={BUTTON_VARIANT.outlined}
                    onClick={addSelectedTags}
                    disabled={selectedTags.length === 0}
                  >
                    Add Selected
                  </RoundedButton>
                </Grid>
              )}
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <div className={classes.bucket}>
                <Typography variant={TEXT_VARIANTS.h6} className={classes.bucketTitle}>INCLUDED TAGS</Typography>
                <Grid container>
                  <table className={classes.selectedTagsTable}>
                    <tbody>
                      {includedTagsFields.map((tag, idx) => (
                        <tr key={tag.id}>
                          <td className={clsx(classes.selectedTag, classes.selectedTagName)}>{tag.name}</td>
                          <td
                            className={clsx(classes.selectedTag, classes.selectedTagColor)}
                            style={{
                              backgroundColor: isEditing ? 'inherit' : tag.color,
                              color: isEditing ? undefined : theme.palette.getContrastText(tag.color),
                              width: isEditing ? '35%' : '25%',
                              border: isEditing ? undefined : `1px solid ${theme.palette.getContrastText(tag.color)}`
                            }}
                          >
                            {isEditing && (
                              <Controller
                                name={`includedTags.${idx}.color`}
                                control={control}
                                defaultValue={tag.color || '#FFFFFF'}
                                render={({ field: { onChange } }) => {
                                  return (
                                    <InputColorPicker
                                      id='color-picker'
                                      defaultValue={tag.color || '#FFFFFF'}
                                      onChange={(color) => {
                                        if (color) {
                                          onChange(color)
                                        }
                                      }}
                                    />
                                  )
                                }}
                              />
                            )}
                            {!isEditing && tag.color}
                          </td>
                          {isEditing && (
                            <td className={clsx(classes.selectedTag, classes.selectedTagDeleteBtn)}>
                              <IconButton
                                color='inherit'
                                disableFocusRipple
                                disableRipple
                                className={classes.removeTagButton}
                                onClick={() => onDeleteTag(tag, idx)}
                              >
                                <RemoveCircleOutline style={{ fontSize: '20px' }} />
                              </IconButton>
                            </td>
                          )}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </Grid>
              </div>
            </Grid>
          </Grid>
        </Box>
      )}
      {isEditing && (
        <Box
          sx={{
            height: 92,
            position: 'absolute',
            bottom: 0,
            width: '100%',
            bgcolor: '#fff',
            boxShadow: '0px -4px 18px rgba(36, 36, 44, 0.04);'
          }}
        >
          <Divider additionalClasses={classes.footerDivider} />
          <Grid
            container
            justifyContent='space-around'
            spacing={4}
            className={classes.container}
            style={{ paddingTop: '14px' }}
          >
            <Grid item md={2} />
            <Grid item>
              <RoundedButton
                outlined
                size={BUTTON_SIZES.small}
                onClick={onCancel}
                className={classes.onEditActionButtons}
              >
                Cancel
              </RoundedButton>
            </Grid>
            <Grid item>
              <RoundedButton
                primary
                size={BUTTON_SIZES.small}
                disabled={!isDirty}
                onClick={onSave}
                className={classes.onEditActionButtons}
              >
                Save
              </RoundedButton>
            </Grid>
            <Grid item md={2} />
          </Grid>
        </Box>
      )}
    </Box>
  )
}

export default WealthboxManageView
