import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import OperationalTable from '../../../../organisms/OperationalTable'
import { useGroupingContext } from '../../../../organisms/GroupingProvider/GroupingContext'
import { useAutoOperationalTableContext } from '../../../../organisms/OperationalTable/AutoOperationalTableContext'
import { useClientsListContext } from './ClientsListContext'

const useStyles = makeStyles(() => ({
  header: {
    paddingTop: '1rem',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  }
}))

const ClientsTable = ({ header, onClickRow }) => {
  const classes = useStyles()
  const {
    clients,
    isLoading,
    columnConfig,
    operationalTable,
    isLoadingGroupTypes
  } = useClientsListContext()

  const { setPerformanceTableProps } = useGroupingContext()
  const { tableProps } = useAutoOperationalTableContext()

  const tableColsInSync =
    columnConfig.columns.length <= tableProps?.allColumns?.length

  useEffect(() => {
    if (!isLoading && !isLoadingGroupTypes && tableColsInSync) {
      setPerformanceTableProps(tableProps)
    }
  }, [
    tableProps,
    isLoading,
    tableColsInSync,
    isLoadingGroupTypes,
    setPerformanceTableProps
  ])

  useEffect(() => {
    if (tableProps?.setHiddenColumns) {
      const hiddenColumns = columnConfig.columns.reduce(
        (acc, col) => (col.hidden ? [...acc, col.id] : acc),
        []
      )
      tableProps.setHiddenColumns(hiddenColumns)
    }
  }, [tableProps, columnConfig.columns])

  const {
    searchText,
    onPagingChange,
    onSortingChange,
    defaultPageSize,
    onTableModeChange
  } = operationalTable

  return (
    <>
      <OperationalTable.SuperHeader className={classes.header}>
        {header}
      </OperationalTable.SuperHeader>
      <OperationalTable
        mode='server'
        itemName='Clients'
        data={clients}
        loading={isLoading}
        searchText={searchText}
        onRowClick={onClickRow}
        columns={columnConfig.columns}
        onPagingChange={onPagingChange}
        defaultPageSize={defaultPageSize}
        onSortingChange={onSortingChange}
        onTableModeChange={onTableModeChange}
        defaultSort={columnConfig.defaultSort}
      />
    </>
  )
}

ClientsTable.propTypes = {
  header: PropTypes.node,
  onClickRow: PropTypes.func
}

export default ClientsTable
