import { createContext, useContext, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'

export const LinkViewTabGroupContext = createContext({
  selectedValue: null
})

export const useLinkViewTabGroup = () => {
  const tabGroupContext = useContext(LinkViewTabGroupContext)

  return tabGroupContext
}

export const useLinkViewTabGroupValues = (tabOptions) => {
  const location = useLocation()

  const { options, defaultValue } = useMemo(() => {
    const option = tabOptions ? Object.values(tabOptions).find(({ path }) => path === location.pathname) : null
    return {
      options: tabOptions,
      defaultValue: option?.key || null
    }
  }, [location.pathname, tabOptions])

  const [selectedValue, setSelectedValue] = useState(defaultValue)

  return useMemo(() => ({
    selectedValue,
    selectValue: setSelectedValue,
    options
  }), [selectedValue, setSelectedValue, options])
}
