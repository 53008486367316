import React from 'react'
import truncate from 'lodash/truncate'
import { Box, Tooltip } from '@material-ui/core'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import numeral from 'numeral'
import Avatar from '../../../atoms/Avatar'
import Text from '../../../atoms/Text'
import Icon from '../../../atoms/Icon'
import { ICON_NAMES, SIZE_VARIANTS } from '../../../../constants'
import { themeConfiguration } from '../../../../theme'

dayjs.extend(utc)

const getAccountStatusText = (accountStatus) => {
  const { isClosed, hasPreviousValuation, isAutomatedNotUpdating, hasException } = accountStatus || {}
  if (hasException) return 'Account Exception'
  if (isAutomatedNotUpdating) return 'Account not Updating'
  if (hasPreviousValuation) return 'Previous Valuation'
  if (isClosed) return 'Account is closed'
  return undefined
}

const getAccountStatusValues = (accountStatus) => {
  const { isClosed, hasPreviousValuation, isAutomatedNotUpdating, hasException } = accountStatus || {}
  const showStatus = isClosed || hasPreviousValuation || isAutomatedNotUpdating || hasException
  const color = isAutomatedNotUpdating || hasException ? themeConfiguration.palette.yellowOrange : '#0099FF'
  const accountText = getAccountStatusText(accountStatus)
  return [
    showStatus,
    showStatus ? color : undefined,
    accountText
  ]
}

const cellTemplates = {
  default: ({ value }) => <span>{value}</span>,
  truncate: ({ value, column: { maxCharacters = MAX_CHARACTERS, separator = CHARACTER_SEPARATOR } }) => <span>{truncate(value, { length: maxCharacters, separator })}</span>,
  withAvatar: ({ row: { original } }) => {
    const custodianName = original.custodian ? original.custodian[0]?.name : '--'
    return (
      <span style={{ display: 'flex', flexDirection: 'row' }}>
        <Avatar
          src={original.custodian ? original.custodian[0]?.imageUrl : undefined}
          size={SIZE_VARIANTS.extraSmall}
          avatarLetters={custodianName}
          style={{ marginRight: '10px', position: 'static' }}
          isPublicResource
        />
        <Text text={custodianName} />
      </span>
    )
  },
  dateWithStatus: ({ row: { original }, column: { format = 'M/D/YYYY' } }) => {
    const asOfDateText = original.asOfDate ? dayjs.utc(original.asOfDate).format(format) : ''
    const [showStatus, color, accountText] = getAccountStatusValues(original.accountStatus)
    return (
      <span style={{ display: 'flex', flexDirection: 'row', justifyContent: 'end' }}>
        <Box mr={showStatus ? '10px' : '24px'}>
          <Text text={asOfDateText} />
        </Box>
        {showStatus && (
          <Tooltip title={accountText}>
            <div>
              <Icon name={ICON_NAMES.circle} color={color} />
            </div>
          </Tooltip>
        )}
      </span>
    )
  },
  date: ({ value, column: { format = 'M/D/YYYY' } }) => <span>{value ? dayjs.utc(value).format(format) : ''}</span>,
  number: ({ value, column: { format = '0,0a', formatter = numeral } }) => <span style={{ display: 'block', padding: '0 5px', width: '100%', textAlign: 'right' }}>{formatter(value, format)}</span>
}

const customAccessors = {
  truncatedLongName: (row) => truncate(row.displayName || row.longName, { length: MAX_CHARACTERS, separator: CHARACTER_SEPARATOR }),
  custodianName: (row) => row.custodian ? row.custodian[0]?.name : '--',
  endingValue: row => row.asOfDayValues?.endingValue ?? '--'
}

const MAX_CHARACTERS = 30
const CHARACTER_SEPARATOR = ' '

export const defaultColumns = [
  {
    Header: 'Account',
    id: 'accountName',
    cellType: 'truncate'
  },
  {
    Header: 'Custodian',
    id: 'custodianName',
    cellType: 'withAvatar'
  },
  {
    Header: 'Total Value',
    id: 'endingValue',
    cellType: 'number',
    format: '0.0a',
    alignRight: true
  },
  {
    Header: 'As Of Date',
    id: 'asOfDate',
    cellType: 'dateWithStatus',
    format: 'M/D/YYYY',
    alignRight: true
  },
  {
    Header: 'Last Updated',
    id: 'lastDataSourceDate',
    cellType: 'date',
    format: 'M/D/YYYY',
    alignRight: true
  }
]

export const getColumnConfig = (columns, formatter, defaultSort) => {
  const normalizedColumns = [
    ...columns.map((column) => ({
      ...column,
      accessor: customAccessors[column.id] ?? column.id,
      formatter: formatter,
      Cell: cellTemplates[column.cellType] ?? cellTemplates.default
    }))
  ]

  return {
    columns: normalizedColumns,
    defaultSort: [{ id: normalizedColumns[0]?.id, desc: false }],
    ...(defaultSort && { defaultSort })
  }
}
