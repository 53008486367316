import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles, Grid } from '@material-ui/core'
import FadeIn from '../../../../molecules/Transitions/FadeIn'
import { useCheckPolicy } from '../../../../../hooks'
import NotAvailable from '../../../../organisms/NotAvailable'
import ClientBlade from './ClientBlade'
import ClientDetailBlade from './ClientDetailBlade'
import FineDetailBlade from './FineDetailBlade'
import ClientReviewProvider from './ClientReviewContext'

const useStyles = makeStyles((theme) => ({
  page: {
    padding: theme.layout.padding.medium,
    '& .__danger': {
      color: theme.palette.danger.main
    },
    '& .__no-content': {
      color: theme.palette.gray.darker,
      padding: theme.layout.padding.medium,
      fontStyle: 'italic'
    },
    '& .__content': {
      padding: theme.layout.padding.medium
    },
    '& .__table': {
      borderCollapse: 'collapse',
      width: '100%',
      '& thead tr': {
        position: 'sticky',
        top: 0,
        backgroundColor: theme.palette.background.default
      },
      '& thead th': {
        textAlign: 'left'
      },
      '& tr td': {
        fontWeight: theme.typography.weights.light
      }
    }
  },
  clientItem: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.layout.gap.medium,
    border: `1px solid ${theme.palette.gray.dark}`,
    padding: theme.layout.padding.thin,
    borderRadius: theme.layout.radius.tight,
    marginBottom: theme.layout.margin.thin
  }
}))

function BillingClientReview (props) {
  const classes = useStyles()
  const hasAccess = useCheckPolicy('admin_billing_client_review')

  if (!hasAccess) {
    return <NotAvailable />
  }

  return (
    <ClientReviewProvider
      clientsBaseQuery={props.clients.baseQuery}
      accountsBaseQuery={props.accounts.baseQuery}
      editingSettings={props.editingSettings}
    >
      <FadeIn className={classes.page}>
        <Grid container spacing={1}>
          <Grid item md={3}>
            <ClientBlade
              {...props.clients}
            />
          </Grid>
          <Grid item md={5}>
            <ClientDetailBlade
              accountsConfig={props.accounts}
            />
          </Grid>
          <Grid item md={4}>
            <FineDetailBlade />
          </Grid>
        </Grid>
      </FadeIn>
    </ClientReviewProvider>
  )
}

BillingClientReview.propTypes = {
  clients: PropTypes.shape({
    hideFilters: PropTypes.bool,
    baseQuery: PropTypes.object,
    columns: PropTypes.array,
    filterOn: PropTypes.arrayOf(PropTypes.shape({
      accessor: PropTypes.string,
      exact: PropTypes.bool
    }))
  }),
  accounts: PropTypes.shape({
    baseQuery: PropTypes.object,
    columns: PropTypes.array,
    filterOn: PropTypes.arrayOf(PropTypes.shape({
      accessor: PropTypes.string,
      exact: PropTypes.bool
    }))
  }),
  editingSettings: PropTypes.shape({
    canEditFeeCalcTypeId: PropTypes.bool
  })
}

BillingClientReview.defaultProps = {
  clients: {
    hideFilters: false,
    baseQuery: {
      includes: {
        billingStats: true,
        accountCount: true
      }
    },
    columns: [
      { accessor: 'clientId', header: 'ID' },
      { accessor: 'longName', header: 'Name' },
      { accessor: 'accountCount', header: '#ACC', format: 'number', formatOptions: { dashZero: true }, align: 'right' },
      { accessor: 'billingStats.feeScheduleCount', header: '#FS', format: 'number', formatOptions: { dashZero: true }, align: 'right' },
      { accessor: 'billingStats.familyRateCount', header: '#FR', format: 'number', formatOptions: { dashZero: true }, align: 'right' }
    ],
    filterOn: [
      { accessor: 'clientId', exact: true },
      { accessor: 'longName' }
    ]
  },
  accounts: {
    baseQuery: {},
    columns: [
      { accessor: 'accountId', header: 'ID' },
      { accessor: 'accountName', header: 'Acc' },
      { accessor: 'accountNumber', header: 'Acc#' }
    ],
    filterOn: [
      { accessor: 'accountId', exact: true },
      { accessor: 'accountName' },
      { accessor: 'accountNumber' }
    ]
  },
  editingSettings: {
    canEditFeeCalcTypeId: true
  }
}

export default BillingClientReview
