import React, { useCallback, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { Button, Chip, TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import DragAndDropInputArea from '../../../molecules/DragAndDropInputArea'
import { getFileExtension } from '../../../../utils'
import { useFormattingContext } from '../../../organisms/FormattingProvider/FormattingContext'
import { postNamedCommand, postNamedQuery, uploadDocument } from '../../../../service'
import { useListPlatformDataSourcesInvalidator } from '../../../../api/ingestion'

const useStyles = makeStyles(theme => ({
  uploadForm: {
    border: '2px solid rgba(0, 0, 0, .5)',
    borderRadius: '.2rem',
    padding: '2rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',

    '& .__empty': {
      minWidth: '30rem',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      '& > *': {
        margin: '.5rem 0'
      }
    },
    '& .__upload-button': {
      width: '30rem'
    },
    '& .__status-display': {
      minHeight: '10rem',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center'
    }
  },
  fileUpload: {
    width: '60rem',
    height: '20vh',
    '& > *': {
      borderColor: `${theme.palette.gray.dark} !important`
    },
    '&:hover > *': {
      borderColor: `${theme.palette.gray.darker} !important`
    }
  },
  section: {
    margin: '.5rem 0',
    '& > *': {
      minWidth: '30rem',
      margin: '0 .2rem'
    }
  }
}))

const EmptyDropAreaDisplay = ({ openFileUploadWindow }) => {
  return (
    <div className='__empty'>
      <Button variant='contained' onClick={openFileUploadWindow} className='__upload-button'>
        <h3>Upload a File</h3>
      </Button>
      <div>
        <span>Accepted File Types</span>
        <span><Chip label='.csv' /></span>
        <span><Chip label='.json' /></span>
      </div>
    </div>
  )
}

EmptyDropAreaDisplay.propTypes = {
  openFileUploadWindow: PropTypes.func.isRequired
}

const FilledDropAreaDisplay = ({ file }) => {
  const { formatter } = useFormattingContext()
  return (
    <div>
      <div>File Name: {file.name}</div>
      <div>File Size: {formatter(file.size, '0.00b')}</div>
    </div>
  )
}

FilledDropAreaDisplay.propTypes = {
  file: PropTypes.object.isRequired
}

const DropAreaDisplay = ({ openFileUploadWindow, file }) => {
  if (!file) {
    return <EmptyDropAreaDisplay openFileUploadWindow={openFileUploadWindow} />
  }

  return <FilledDropAreaDisplay file={file} />
}

DropAreaDisplay.propTypes = {
  openFileUploadWindow: PropTypes.func.isRequired,
  file: PropTypes.object
}

const StatusDisplay = ({ uploadStatus }) => {
  return (
    <div className='__status-display'>
      <h2>{uploadStatus.message}</h2>
    </div>
  )
}

StatusDisplay.propTypes = {
  uploadStatus: PropTypes.object
}

const UploadForm = () => {
  const classes = useStyles()
  const [file, setFile] = useState()
  const [uploadStatus, setUploadStatus] = useState(null)
  const nameRef = useRef()
  const descriptionRef = useRef()

  const onFileDrop = useCallback((newFiles) => {
    try {
      if (!newFiles?.length) {
        console.log('No files on file drop?')
        return
      }
      const newFile = newFiles.at(0)

      setFile({
        name: newFile.name,
        file: newFile,
        type: newFile.type,
        size: newFile.size
      })
    } catch (err) {
      console.error(err, 'dropping files')
    }
  }, [setFile])

  const validateFile = useCallback((file) => {
    const { name, extension } = getFileExtension(file.name, true)
    if (!name || !extension) {
      return 'File extension required'
    }
    if (file.size > (1000000 * 1000 /* 1gb? */)) {
      return 'Maximum Size Limit for file (1 Gigabyte)'
    }
    return null
  }, [])

  const invalidator = useListPlatformDataSourcesInvalidator()
  const history = useHistory()
  const onSubmit = useCallback(async () => {
    const name = nameRef.current.value
    const description = descriptionRef.current.value

    setUploadStatus({ message: 'Validating...' })
    const createResponse = await postNamedCommand('ingestion', 'createFileUpload', {
      fileName: file.name
    })
    const { fileUploadId, uploadUrl } = createResponse.data

    // upload to s3
    setUploadStatus({ message: 'Uploading...' })

    /**
     * @param {ProgressEvent} progressEvent
     */
    const progress = (progressEvent) => {
      if (progressEvent.lengthComputable) {
        const percentDone = ((progressEvent.loaded / progressEvent.total) * 100).toFixed(0)
        setUploadStatus({ message: `Uploading (${percentDone}%)` })
      }
    }
    const uploadResponse = await uploadDocument(uploadUrl, file.file, progress, console.log)
    console.log(uploadResponse, 'upload response')

    setUploadStatus({ message: 'Creating Dataset...' })
    const confirmResponse = await postNamedCommand('ingestion', 'confirmFileUpload', {
      fileUploadId,
      createDataSource: {
        name,
        description
      }
    })
    console.log(confirmResponse, 'confirm response')

    await invalidator()
    setUploadStatus({ message: 'Analyzing Dataset' })

    let timeoutHandle = null
    const platformDataSourceId = confirmResponse.data.dataSource.platformDataSourceId
    const pollingHandle = setInterval(() => {
      postNamedQuery('ingestion', 'getPlatformDataSourceById', {
        platformDataSourceId
      }).then(({ data }) => {
        if (data.metadata?.schema) {
          history.push(`/data-home/source/${platformDataSourceId}`)
          clearInterval(pollingHandle)
          if (timeoutHandle) {
            clearTimeout(timeoutHandle)
          }
        } else {
          setUploadStatus(prev => ({ message: prev.message + '.' }))
        }
      })
    }, 5000)
    timeoutHandle = setTimeout(() => {
      history.push(`/data-home/source/${platformDataSourceId}`)
      clearInterval(pollingHandle)
    }, 60000)
  }, [nameRef, descriptionRef, file, history, invalidator, setUploadStatus])

  const onCancel = useCallback(() => {
    history.push('/data-home')
  }, [history])

  if (uploadStatus) {
    return (
      <div className={classes.uploadForm}>
        <StatusDisplay uploadStatus={uploadStatus} />
      </div>
    )
  }

  return (
    <div className={classes.uploadForm}>
      <div className={classes.section}>
        <TextField inputRef={nameRef} variant='outlined' label='Dataset Name' required />
        <TextField inputRef={descriptionRef} variant='outlined' label='Description' multiline required maxRows={5} />
      </div>
      <div className={classes.fileUpload}>
        <DragAndDropInputArea
          maxFiles={1}
          acceptedFileTypes={['text/csv', 'application/json']}
          onDropAccepted={onFileDrop}
          validator={validateFile}
        >
          {({ openUploadFileWindow }) => (
            <DropAreaDisplay
              openFileUploadWindow={openUploadFileWindow}
              file={file}
              uploadStatus={uploadStatus}
            />
          )}
        </DragAndDropInputArea>
      </div>
      <div className={classes.section}>
        <Button variant='contained' onClick={onSubmit}>Submit</Button>
        <Button variant='contained' onClick={onCancel}>Cancel</Button>
      </div>
    </div>
  )
}

export default UploadForm
