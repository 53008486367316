import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { usePolicy } from '../../../../../hooks/usePolicy'
import { ADMIN_ROUTES } from '../../../../../constants'
import StyledButton from '../../shared/StyledButton'
import { useClientPersonsActions } from './ClientPersonsTableContext'

function UserCell ({ value, row }) {
  const { editing, onCreateUser } = useClientPersonsActions()
  const canEdit = usePolicy('admin_edit_wo_users')
  const userId = row.original?.user?.externalUserId
  if (canEdit && userId && !editing) {
    return (
      <div className='__people-cell'>
        <Link to={`${ADMIN_ROUTES.WEALTH_OWNERS}/${userId}`}>{value}</Link>
      </div>
    )
  }
  if (!userId && canEdit) {
    return (
      <StyledButton
        icon='add'
        onClick={() => onCreateUser({ ...row.original })}
      >
        Create User
      </StyledButton>
    )
  }
  if (userId) {
    return (
      <div className='__people-cell'>{value}</div>
    )
  }
  return (
    <div className='__people-cell'>--</div>
  )
}

UserCell.propTypes = {
  value: PropTypes.any,
  row: PropTypes.any
}

export default UserCell
