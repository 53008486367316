import React, { useMemo } from 'react'
import { makeStyles } from '@material-ui/core'
import { isEmpty, isNil } from 'lodash'
import PropTypes from 'prop-types'
import { useSearchAssignedPeople } from '../../../../api/people'
import { useAppContext } from '../../../../redux/slices/appContext'
import EmptySection from '../../../atoms/EmptySection'
import FamilyTree from './FamilyTree'
import PersonNode from './PersonNode'

const mapFamilyTree = (persons) => {
  const familyLevels = {}

  for (const person of persons) {
    const { personType } = person
    if (!personType || isNil(personType?.familyTreeDepth)) {
      continue
    }
    const { familyTreeDepth } = personType
    if (!familyLevels[familyTreeDepth]) {
      familyLevels[familyTreeDepth] = [person]
    } else {
      familyLevels[familyTreeDepth].push(person)
    }
  }
  for (const level in familyLevels) {
    familyLevels[level].sort(
      (a, b) => a.personType.ordinal - b.personType.ordinal
    )
  }
  return familyLevels
}

const mapPersons = (data) => {
  if (isEmpty(data?.data)) return {}
  return mapFamilyTree(data.data)
}

const useFamilyTree = (clientId) => {
  const query = useMemo(
    () => ({
      filters: {
        assignedToClientIds: [clientId]
      },
      includes: {
        personTypes: true
      }
    }),
    [clientId]
  )

  return useSearchAssignedPeople(query, {
    enabled: !!clientId,
    mapper: mapPersons
  })
}

const useStyles = makeStyles(() => ({
  container: {
    overflow: 'auto'
  }
}))

const ClientHouseHoldFamilyTree = ({ emptyStateLabel }) => {
  const classes = useStyles()
  const { clientId } = useAppContext()
  const { data: familyTree = {}, isLoading } = useFamilyTree(clientId)

  if (isLoading) {
    return <FamilyTree.Skeleton />
  }

  if (!isLoading && isEmpty(familyTree)) {
    return <EmptySection title={emptyStateLabel} description='' />
  }

  return (
    <div className={classes.container}>
      <FamilyTree.Container>
        {Object.entries(familyTree).map(([level, persons]) => {
          const hasChildren = !!familyTree[parseInt(level) + 1]
          return (
            <FamilyTree.LevelContainer key={level} hasChildren={hasChildren}>
              {persons.map((person) => {
                return (
                  <FamilyTree.Node key={person.personId}>
                    <PersonNode person={person} />
                  </FamilyTree.Node>
                )
              })}
            </FamilyTree.LevelContainer>
          )
        })}
      </FamilyTree.Container>
    </div>
  )
}

ClientHouseHoldFamilyTree.propTypes = {
  emptyStateLabel: PropTypes.string
}

ClientHouseHoldFamilyTree.defaultProps = {
  emptyStateLabel: 'It looks like your family tree is currently empty'
}

export default ClientHouseHoldFamilyTree
