import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'center',
    justifyContent: 'flex-start',
    fontSize: '1rem',
    width: '14rem',
    minWidth: '14rem',
    height: '18.5rem',
    borderRadius: '0.5rem',
    border: `2px solid ${theme.palette.gray.lighter}`,
    boxSizing: 'border-box',
    cursor: 'pointer',
    transition: 'border-color .25s ease-in-out, background-color .25s ease-in-out',
    '&:hover': {
      borderColor: '#141929',
      cursor: 'pointer',
      boxShadow: '0px 4px 24px 0px rgba(15, 17, 49, 0.12)'
    }
  }
}))

const CardItem = ({ children }) => {
  const classes = useStyles()
  return (
    <div className={classes.container}>
      {children}
    </div>
  )
}

CardItem.propTypes = {
  children: PropTypes.node
}

export default CardItem
