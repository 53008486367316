import React, { useState } from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import noop from 'lodash/noop'
import {
  DatePicker,
  TimePicker,
  DateTimePicker as MUIDateTimePicker,
  KeyboardDatePicker
} from '@material-ui/pickers'
import { makeStyles, TextField } from '@material-ui/core'
import isEmpty from 'lodash/isEmpty'
import { DATE_TIME_PICKER_TYPES } from '../../constants'

const DATE_TIME_MUI_TYPES = {
  [DATE_TIME_PICKER_TYPES.date]: DatePicker,
  [DATE_TIME_PICKER_TYPES.dateTime]: MUIDateTimePicker,
  [DATE_TIME_PICKER_TYPES.time]: TimePicker,
  [DATE_TIME_PICKER_TYPES.keyboardDate]: KeyboardDatePicker
}

const useStyles = makeStyles((theme) => ({
  picker: ({ extraStyles, disabled }) => ({
    color: theme.palette.doveGray,
    '& input': {
      textAlign: 'center',
      userSelect: 'none'
    },
    '&:hover': disabled ? {
      backgroundColor: 'transparent !important'
    } : undefined,
    ...extraStyles
  }),
  inputBase: {
    minWidth: '12rem',
    fontSize: 'inherit',
    cursor: 'pointer',
    '&.Mui-disabled': {
      cursor: 'auto'
    }
  },
  inputText: {
    paddingLeft: '1rem !important',
    '& > .MuiInput-root': {
      '& > input': {
        textAlign: 'left'
      }
    }
  }
}))

function DateTimePicker ({
  color,
  defaultValue,
  disableFuture,
  disabled,
  extraStyles,
  format,
  onChange,
  label,
  type,
  value,
  disabledDateRange,
  shouldDisableDate,
  className,
  inputClassName,
  inputProps,
  showInputText,
  textValue,
  placeholder
}) {
  const Picker = DATE_TIME_MUI_TYPES[type]
  const classes = useStyles({ extraStyles, disabled })

  const [open, setOpen] = useState(false)

  return (
    <Picker
      autoOk
      className={clsx(classes.picker, { [className]: !!className })}
      disableFuture={disableFuture}
      disabled={disabled}
      format={format}
      InputProps={{
        classes: {
          root: clsx(classes.inputBase, { [inputClassName]: !!inputClassName }),
          input: clsx(classes.inputBase, {
            [inputClassName]: !!inputClassName
          })
        },
        disableUnderline: true,
        ...inputProps
      }}
      label={label}
      onChange={onChange}
      value={value || defaultValue}
      {...(DATE_TIME_PICKER_TYPES.date === type && !isEmpty(disabledDateRange)
        ? {
          ...(disabledDateRange.min
            ? { minDate: disabledDateRange.min }
            : {}),
          ...(disabledDateRange.max
            ? { maxDate: disabledDateRange.max }
            : {}),
          ...(shouldDisableDate ? { shouldDisableDate } : {})
        }
        : {})}
      maxDateMessage=''
      minDateMessage=''
      color={color}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      placeholder={placeholder}
      {...(showInputText
        ? {
          TextFieldComponent: (props) => (
            <TextField
              {...props}
              className={clsx(classes.picker, classes.inputText, {
                [className]: !!className
              })}
              value={textValue}
              onClick={() => setOpen(true)}
            />
          )
        }
        : {})}
    />
  )
}

DateTimePicker.propTypes = {
  color: PropTypes.string,
  defaultValue: PropTypes.string,
  disableFuture: PropTypes.bool,
  disabled: PropTypes.bool,
  extraStyles: PropTypes.any,
  format: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  type: PropTypes.oneOf(Object.values(DATE_TIME_PICKER_TYPES)),
  value: PropTypes.string,
  disabledDateRange: PropTypes.shape({
    min: PropTypes.string.isRequired,
    max: PropTypes.string.isRequired
  }),
  shouldDisableDate: PropTypes.func,
  className: PropTypes.string,
  inputClassName: PropTypes.string,
  inputProps: PropTypes.any,
  showInputText: PropTypes.bool,
  textValue: PropTypes.string,
  placeholder: PropTypes.string
}

DateTimePicker.defaultProps = {
  color: undefined,
  defaultValue: dayjs().format('YYYY-MM-DD'),
  disableFuture: false,
  disabled: false,
  extraStyles: undefined,
  format: 'MM/DD/YYYY',
  label: null,
  onChange: noop,
  type: DATE_TIME_PICKER_TYPES.date,
  value: undefined,
  disabledDateRange: undefined,
  shouldDisableDate: undefined,
  className: undefined,
  inputClassName: undefined,
  inputProps: undefined,
  showInputText: false,
  placeholder: undefined
}

export default DateTimePicker
