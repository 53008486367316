import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Button } from '@material-ui/core'
import { useIngestionBatchStatus } from '../../../../api/ingestion'
import BatchListItem from './BatchListItem'

const useStyles = makeStyles({
  list: {
    padding: '1rem',
    display: 'flex',
    flexDirection: 'column'
  },
  header: {
    display: 'flex',
    flexDirection: 'row'
  }
})

const BatchList = () => {
  const classes = useStyles()
  // const query = useMemo(() => ({}), [])
  const { data, isFetching, refetch } = useIngestionBatchStatus(null, { refetch: true })

  return (
    <div>
      <header className={classes.header}>
        <h2>Recent Batches</h2>
        <Button size='small' onClick={refetch}>Refresh</Button>
      </header>
      {isFetching ? (
        <div>Loading ...</div>
      ) : (
        <div className={classes.list}>
          {data.map((batch) => (
            <BatchListItem key={batch.ingestionBatchId} batch={batch} />
          ))}
        </div>
      )}
    </div>
  )
}

export default BatchList
