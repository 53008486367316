import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { IconButton, makeStyles } from '@material-ui/core'
import { Controller, useFormContext } from 'react-hook-form'
import Select from '../../../../molecules/Select'
import Icon from '../../../../atoms/Icon'
import LevelPicker from './LevelPicker'

const useStyles = makeStyles((theme) => ({
  outer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    gap: '20px',
    alignItems: 'center'
  },
  override: {
    flex: '1 0 auto',
    border: `2px solid ${theme.palette.gray.dark}`,
    padding: '18px',
    marginBottom: '12px',
    maxWidth: '650px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  left: {
    flex: '1 0'
  },
  right: {
    textAlign: 'right'
  },
  remove: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& .MuiIconButton-root': {
      color: theme.palette.danger.main,
      fill: theme.palette.danger.main
    }
  }
}))

const levelTypeOptions = [
  { label: 'Asset Classes', value: 'ASSET_CLASS' },
  { label: 'Account', value: 'ACCOUNT' },
  { label: 'Positions', value: 'POSITION' }
]

function FeeOverride ({
  name,
  feeScheduleOptions,
  editing,
  onRemove,
  assigned
}) {
  const classes = useStyles()
  const { setValue } = useFormContext()
  const onLevelTypeChange = useCallback((onChange) => (value) => {
    onChange(value)
    setValue(`${name}.overrideLevelId`, '', { shouldDirty: true, shouldTouch: true })
  }, [name, setValue])

  return (
    <div className={classes.outer}>
      <div className={classes.override}>
        <div className={classes.left}>
          <Controller
            name={`${name}.overrideLevelType`}
            rules={{ required: true }}
            render={({ field, fieldState }) => (
              <Select
                value={field.value}
                onChange={onLevelTypeChange(field.onChange)}
                options={levelTypeOptions}
                readOnly={!editing}
                error={(fieldState.isTouched || fieldState.isDirty) && fieldState.error}
              />
            )}
          />
        </div>
        <div className={classes.right}>
          <LevelPicker
            name={name}
            readOnly={!editing}
            assigned={assigned}
          />
          <Controller
            name={`${name}.feeScheduleId`}
            rules={{ required: true }}
            render={({ field, fieldState }) => (
              <Select
                value={field.value}
                onChange={field.onChange}
                options={feeScheduleOptions}
                readOnly={!editing}
                error={(fieldState.isTouched || fieldState.isDirty) && fieldState.error}
              />
            )}
          />
        </div>
      </div>
      <div className={classes.remove}>
        {editing ? (
          <IconButton
            onClick={onRemove}
          >
            <Icon customSize={20} name='removeCircle' />
          </IconButton>
        ) : null}
      </div>
    </div>
  )
}

FeeOverride.propTypes = {
  name: PropTypes.string,
  editing: PropTypes.bool,
  feeScheduleOptions: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.number
  })),
  onRemove: PropTypes.func,
  assigned: PropTypes.arrayOf(PropTypes.shape({
    levelType: PropTypes.string,
    feeScheduleOverrideId: PropTypes.number,
    levelId: PropTypes.number,
    levelName: PropTypes.string
  }))
}

export default FeeOverride
