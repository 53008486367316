const variants = {
  primary: {
    main: (theme) => ({
      backgroundColor: theme.palette.primary.main,
      outline: `1.5px solid ${theme.palette.primary.main}`,
      color: theme.palette.primary.contrast,
      stroke: theme.palette.primary.contrast,
      '&:hover': {
        backgroundColor: theme.palette.primary.hover
      },
      '&:disabled': {
        backgroundColor: theme.palette.primary.disabled
      },
      '& .__progress': {
        color: theme.palette.primary.contrast,
        stroke: theme.palette.primary.contrast
      }
    }),
    warning: (theme) => ({
      backgroundColor: theme.palette.danger.light,
      outline: `1.5px solid ${theme.palette.danger.light}`,
      color: theme.palette.danger.main,
      stroke: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: theme.palette.danger.hover
      },
      '&:disabled': {
        backgroundColor: theme.palette.danger.disabled
      },
      '& .__progress': {
        color: theme.palette.danger.main,
        stroke: theme.palette.danger.main
      }
    })
  },

  secondary: {
    main: (theme) => ({
      backgroundColor: theme.palette.secondary.main,
      outline: `1.5px solid ${theme.palette.secondary.main}`,
      color: theme.palette.secondary.contrast,
      stroke: theme.palette.secondary.contrast,
      '&:hover': {
        backgroundColor: theme.palette.secondary.hover
      },
      '&:disabled': {
        backgroundColor: theme.palette.secondary.disabled
      },
      '& .__progress': {
        color: theme.palette.secondary.contrast,
        stroke: theme.palette.secondary.contrast
      }
    }),
    warning: (theme) => ({
      backgroundColor: theme.palette.danger.light,
      outline: `1.5px solid ${theme.palette.danger.light}`,
      color: theme.palette.danger.main,
      stroke: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: theme.palette.danger.hover
      },
      '&:disabled': {
        backgroundColor: theme.palette.danger.disabled
      },
      '& .__progress': {
        color: theme.palette.danger.main,
        stroke: theme.palette.danger.main
      }
    })
  },

  outline: {
    main: (theme) => ({
      backgroundColor: theme.palette.background.default,
      outline: `1.5px solid ${theme.palette.primary.main}`,
      color: theme.palette.primary.main,
      stroke: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrast,
        stroke: theme.palette.primary.contrast
      },
      '&:disabled': {
        outline: `1.5px solid ${theme.palette.primary.disabled}`,
        color: theme.palette.primary.disabled,
        stroke: theme.palette.primary.disabled
      },
      '& .__progress': {
        color: theme.palette.primary.main,
        stroke: theme.palette.primary.main
      }
    }),
    warning: (theme) => ({
      backgroundColor: theme.palette.background.default,
      outline: `1.5px solid ${theme.palette.danger.main}`,
      color: theme.palette.danger.main,
      stroke: theme.palette.danger.main,
      '&:hover': {
        backgroundColor: theme.palette.danger.main,
        color: theme.palette.danger.contrast,
        stroke: theme.palette.danger.contrast
      },
      '&:disabled': {
        color: theme.palette.danger.disabled,
        stroke: theme.palette.danger.disabled
      },
      '& .__progress': {
        color: theme.palette.danger.main,
        stroke: theme.palette.danger.main
      }
    })
  },

  ghost: {
    main: (theme) => ({
      backgroundColor: 'transparent',
      outline: 'none',
      color: theme.palette.primary.main,
      stroke: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.secondary.contrast,
        stroke: theme.palette.secondary.contrast
      },
      '&:disabled': {
        color: theme.palette.secondary.disabled,
        stroke: theme.palette.secondary.disabled
      },
      '& .__progress': {
        color: theme.palette.primary.main,
        stroke: theme.palette.primary.main
      }
    }),
    warning: (theme) => ({
      backgroundColor: 'transparent',
      outline: 'none',
      color: theme.palette.danger.main,
      stroke: theme.palette.danger.main,
      '&:hover': {
        backgroundColor: theme.palette.danger.hover,
        color: theme.palette.danger.main,
        stroke: theme.palette.danger.main
      },
      '&:disabled': {
        color: theme.palette.danger.disabled,
        stroke: theme.palette.danger.disabled
      },
      '& .__progress': {
        color: theme.palette.danger.main,
        stroke: theme.palette.danger.main
      }
    })
  }
}

const sizes = {
  xs: (theme) => ({
    padding: '2px 12px',
    minHeight: '16px',
    lineHeight: '16px',
    fontSize: theme.typography.fontSizes.xs
  }),
  sm: (theme) => ({
    padding: '2px 20px',
    minHeight: '20px',
    lineHeight: '20px',
    fontSize: theme.typography.fontSizes.md
  }),
  md: (theme) => ({
    padding: '4px 24px',
    minHeight: '32px',
    lineHeight: '32px',
    fontSize: theme.typography.fontSizes.xl
  }),
  lg: (theme) => ({
    padding: '5px 32px',
    minHeight: '40px',
    lineHeight: '40px',
    fontSize: theme.typography.fontSizes.h5
  }),
  xl: (theme) => ({
    padding: '5px 32px',
    minWidth: '300px',
    minHeight: '40px',
    lineHeight: '40px',
    fontSize: theme.typography.fontSizes.h4
  })
}

export const mapButtonStyles = (theme, rest) => ({ variant, priority, size }) => ({
  ...rest,
  ...variants[variant][priority](theme),
  ...sizes[size](theme)
})
