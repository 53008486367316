import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useMemo,
  useState
} from 'react'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import noop from 'lodash/noop'
import { useOktaAuth } from '@okta/okta-react'
import isEmpty from 'lodash/isEmpty'
import { Divider, makeStyles, Typography } from '@material-ui/core'
import groupBy from 'lodash/groupBy'
import { useToggle, usePermissions, useAuthState } from '../../hooks'

import { deleteThreadReply, editThread, editThreadReply } from '../../service'
import { CONFIRMATION_TYPE, MAX_NOTE_REACTIONS, ICON_NAMES, TEXT_VARIANTS } from '../../constants'
import { commentDefaults, commentObject } from '../../prop-types'

import Avatar from '../atoms/Avatar'
import Icon from '../atoms/Icon'
import { useNoteThreadContext } from '../../contexts/noteThreadContext'
import { getAvatarProfilePicture, parseBodyComment, parseUnicodeTextToEmojis } from '../../utils'
import theme from '../../theme'
import ConfirmationModal from './ConfirmationModal'
import NoteReactionsSection from './NoteReactionsSection'
import InputCommentInlineEdit from './InputCommentInlineEdit'
import NoteReactionsMenuSection from './NoteReactionsMenuSection'
import Menu from './Menu'

const useStyles = makeStyles((theme) => ({
  container: {
    overflow: 'hidden',
    fontSize: '1rem',
    display: 'flex',
    flexDirection: 'row'
  },
  header: {
    display: 'flex',
    alignItems: 'center'
  },
  title: {
    padding: '0 1rem',
    '& p': {
      fontSize: '1rem',
      fontWeight: 'bold'
    }
  },
  replyName: {
    fontSize: '14px',
    fontWeight: 'bold'
  },
  timestamp: {
    fontSize: '12px',
    fontWeight: '300',
    color: theme.palette.dustyGray,
    marginLeft: '0.5rem'
  },
  body: ({ isReply }) => ({
    position: 'relative',
    padding: `${isReply ? '0.125rem 1rem 1rem 1rem' : '0.5rem 1rem 1rem 0'}`,
    whiteSpace: 'pre-line',
    overflowWrap: 'break-word',
    width: '100%'
  }),
  inlineEditInput: {
    backgroundColor: theme.palette.gray.light,
    borderRadius: '0.5rem',
    padding: '0.5rem !important'
  },
  menu: {
    marginLeft: 'auto',
    marginRight: '0.5rem',
    cursor: 'pointer'
  },
  verticalLine: ({ isLastNoteComment }) => ({
    marginLeft: 'calc(1rem - 1px)',
    width: '1px',
    height: '100%',
    ...(!isLastNoteComment ? { backgroundColor: theme.palette.gray.main } : {})
  }),
  leftSide: {
    flexDirection: 'column',
    marginLeft: '1rem'
    // TODO: Hold for background image here
  }
}))

const NoteComment = forwardRef((
  {
    replyId,
    isReply,
    createdAt,
    modalContainerRef,
    isLastNoteComment
  },
  ref
) => {
  const classes = useStyles({ isReply, isLastNoteComment })
  const { authState: oktaAuthState } = useOktaAuth()
  const authState = useAuthState(oktaAuthState)
  const { oktaId } = usePermissions(authState.accessToken || {})

  const {
    replies,
    activeThread,
    onAddReaction,
    onRemoveReply,
    onRemoveReaction,
    updateThreadById,
    updateThreadReplyById
  } = useNoteThreadContext()
  const [isLoading, setIsLoading] = useState(false)
  const [editMode, toggleEditMode] = useToggle(false)
  const [showReactions, , toggleReactionsOn, toggleReactionsOff] = useToggle(false)
  const [
    openDeleteConfirmModal,
    ,
    setOpenDeleteConfirmModal,
    setCloseDeleteConfirmModal
  ] = useToggle(false)

  useImperativeHandle(
    ref,
    () => ({
      toggleEditMode
    }),
    [toggleEditMode]
  )

  const { body, threadId, reactions, createdByName, createdBy } = useMemo(() => {
    if (isReply) {
      const reply = replies.find(reply => reply.replyId === replyId)
      return {
        body: reply.body,
        threadId: activeThread.threadId,
        createdByName: reply.createdByName,
        reactions: reply.reactions,
        createdBy: reply.createdBy
      }
    }
    return {
      body: activeThread.body,
      threadId: activeThread.threadId,
      createdByName: activeThread.createdByName,
      createdBy: activeThread.createdBy,
      reactions: activeThread?.reactions || []
    }
  }, [isReply, activeThread, replies, replyId])

  const onDeleteCommentHandler = useCallback(async () => {
    try {
      setIsLoading(true)
      onRemoveReply(threadId, replyId)
      await deleteThreadReply(threadId, replyId)
    } catch (error) {
      console.error('There was a problem trying to delete the comment', error)
    } finally {
      setIsLoading(false)
      setCloseDeleteConfirmModal()
    }
  }, [replyId, threadId, onRemoveReply, setCloseDeleteConfirmModal])

  const onEditThread = useCallback(
    async (bodyText) => {
      try {
        setIsLoading(true)
        const body = { body: bodyText }

        if (isReply) {
          updateThreadReplyById(replyId, body)
          await editThreadReply(threadId, replyId, body)
        } else {
          updateThreadById(threadId, body)
          await editThread(threadId, body)
        }
      } catch (err) {
        console.error(err)
      } finally {
        setIsLoading(false)
        toggleEditMode()
      }
    },
    [isReply, replyId, threadId, updateThreadById, toggleEditMode, updateThreadReplyById]
  )

  const renderMenuIcon = useCallback(
    ({ setAnchorEl }) => (
      <div className={classes.menuIcon} onClick={setAnchorEl}>
        <Icon name={ICON_NAMES.threeDots} customSize='1rem' color={theme.palette.summitGrey2} />
      </div>
    ),
    [classes.menuIcon]
  )

  const getReplyMenuOptions = useCallback(
    () => [
      {
        iconName: ICON_NAMES.edit,
        label: 'Edit',
        onClick: toggleEditMode
      },
      {
        iconName: ICON_NAMES.delete,
        label: 'Delete',
        onClick: setOpenDeleteConfirmModal,
        color: '#D44333'
      }
    ],
    [toggleEditMode, setOpenDeleteConfirmModal]
  )

  const renderThreadMenu = useMemo(() => {
    const options = getReplyMenuOptions()
    return createdBy === oktaId ? <Menu options={options}>{renderMenuIcon}</Menu> : null
  }, [renderMenuIcon, getReplyMenuOptions, createdBy, oktaId])

  const disableReactionAddition = useMemo(() =>
    !isEmpty(reactions) &&
    Object.keys(groupBy(reactions, 'reaction')).length >= MAX_NOTE_REACTIONS, [reactions])

  const noteReactionProps = useMemo(() =>
    ({ isReply, replyId, threadId, reactions, onAddReaction, onRemoveReaction, disableReactionAddition }),
  [isReply, replyId, threadId, reactions, onAddReaction, onRemoveReaction, disableReactionAddition])

  const renderedCommentBody = useMemo(() => (
    <Typography
      variant={TEXT_VARIANTS.body1}
      dangerouslySetInnerHTML={{ __html: parseBodyComment(parseUnicodeTextToEmojis(body)) }}
    />), [body])

  return (
    <div className={classes.container}>
      <div className={classes.leftSide}>
        <Avatar
          src={getAvatarProfilePicture(createdByName || '')} // TODO: use the real profile picture
          avatarLetters={createdByName || ''}
          customSize='32px'
        />
        <div className={classes.verticalLine} />
      </div>
      <div className={classes.body} onMouseEnter={toggleReactionsOn} onMouseLeave={toggleReactionsOff}>
        <div className={classes.header}>
          <Typography className={classes.replyName}>
            {createdByName}
          </Typography>
          <Typography className={classes.timestamp}>
            {createdAt && dayjs(createdAt).format('ddd, MMM D')}
          </Typography>
          {isReply && <div className={classes.menu}>{renderThreadMenu}</div>}
        </div>
        {editMode ? (
          <InputCommentInlineEdit
            isLoading={isLoading}
            onCancel={toggleEditMode}
            onSaveChanges={onEditThread}
            className={classes.inlineEditInput}
            defaultValue={parseUnicodeTextToEmojis(body)}
          />
        ) : (
          <Typography variant={TEXT_VARIANTS.body2}>
            {renderedCommentBody}
          </Typography>
        )}
        {showReactions && !editMode && <NoteReactionsMenuSection {...noteReactionProps} />}
        {!editMode && <NoteReactionsSection {...noteReactionProps} />}
        {!isLastNoteComment && <Divider style={{ marginTop: '1rem' }} />}
      </div>
      {isReply && (
        <ConfirmationModal
          open={openDeleteConfirmModal}
          buttonSize='small'
          onCancel={setCloseDeleteConfirmModal}
          isLoading={isLoading}
          container={modalContainerRef}
          onConfirm={onDeleteCommentHandler}
          buttonLabelCancel="Don't delete"
          buttonLabelConfirm='Delete'
          confirmationType={CONFIRMATION_TYPE.Danger}
          confirmationText="You are about to delete a message. Are you sure you'd like to proceed?"
        />
      )}
    </div>
  )
})

NoteComment.propTypes = {
  threadId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isLastNoteComment: PropTypes.bool,
  onRemoveReply: PropTypes.func,
  isReply: PropTypes.bool,
  updateThreadById: PropTypes.func,
  createdByName: PropTypes.string.isRequired,
  updateThreadReplyById: PropTypes.func,
  onAddReaction: PropTypes.func,
  onRemoveReaction: PropTypes.func,
  ...commentObject
}

NoteComment.defaultProps = {
  onRemoveReply: noop,
  isReply: false,
  updateThreadById: noop,
  updateThreadReplyById: noop,
  onAddReaction: noop,
  onRemoveReaction: noop,
  ...commentDefaults
}

export default NoteComment
