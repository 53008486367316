import React, { useMemo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useSearchIngestionTargets } from '../../../../api/ingestion'
import TargetListItem from './TargetListItem'

const useStyles = makeStyles({
  jobList: {
    padding: '1rem',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap'
  }
})

const TargetList = () => {
  const classes = useStyles()
  const query = useMemo(() => ({}), [])
  const { data, isLoading } = useSearchIngestionTargets(query)

  if (isLoading) {
    return <div>Loading...</div>
  }

  return (
    <div className={classes.jobList}>
      {data.map((target) => (
        <TargetListItem key={target.ingestionTargetId} target={target} />
      ))}
    </div>
  )
}

export default TargetList
