import React, { forwardRef, useImperativeHandle, useMemo } from 'react'
import PropTypes from 'prop-types'
import set from 'lodash/set'
import get from 'lodash/get'
import { Controller, useForm } from 'react-hook-form'
import { Grid, makeStyles } from '@material-ui/core'
import SydLabel, { hookFormErrorAdapter } from '../../../../../commonDesign/SydLabel'
import SydInput from '../../../../../commonDesign/SydInput'
import SydSelect from '../../../../../commonDesign/SydSelect'

const useStyles = makeStyles((theme) => ({
  formLayout: {
    maxWidth: '800px'
  },
  wrap: {
  },
  experience: {
    width: '100%'
  }
}))

const getTeamMemberDefaults = (user, teamMember, attributes) => {
  return teamMember || attributes.reduce((result, attribute) => {
    return set(result, attribute.accessor, get(result, attribute.accessor) || '')
  }, {
    userId: user.userId,
    firstName: user.firstName,
    lastName: user.lastName,
    title: '',
    email: user.email,
    phone1: '',
    phone2: '',
    phone3: '',
    roleTypeId: null
  })
}

const ContactInfoForm = forwardRef(function ContactInfoForm ({ user, teamMember, attributes, roles, editing }, ref) {
  const classes = useStyles({ editing })
  const options = useMemo(() => {
    return roles.map(x => ({
      label: x.longName,
      value: x.roleTypeId
    }))
  }, [roles])

  const form = useForm({
    mode: 'onChange',
    defaultValues: {
      userId: user.userId,
      teamMember: getTeamMemberDefaults(user, teamMember, attributes)
    }
  })
  const { handleSubmit, reset } = form
  useImperativeHandle(ref, () => {
    return {
      handleSubmit,
      reset
    }
  }, [handleSubmit, reset])

  return (
    <div className={classes.wrap}>
      <Grid className={classes.formLayout} container spacing={1}>
        <Grid item xs={12}>
          <div>
            <Controller
              control={form.control}
              name='teamMember.roleTypeId'
              rules={{ required: { value: true, message: 'Must pick a role' } }}
              render={({ field, fieldState }) => (
                <SydLabel
                  label='Role'
                  description='&nbsp;'
                  required
                  error={hookFormErrorAdapter(form, fieldState)}
                >
                  <SydSelect size='sm' placeholder='Role' disabled={!editing} options={options} {...field} />
                </SydLabel>
              )}
            />
          </div>
        </Grid>
        {attributes.map(attribute => (
          <Grid item xs={12} key={attribute.attributeId}>
            <div>
              <Controller
                control={form.control}
                name={`teamMember.${attribute.accessor}`}
                rules={attribute.data?.validation}
                render={({ field, fieldState }) => (
                  <SydLabel
                    label={attribute.label}
                    description={editing ? attribute.data?.instruction : <>&nbsp;</>}
                    error={hookFormErrorAdapter(form, fieldState)}
                  >
                    <SydInput size='sm' placeholder={attribute.data?.placeholder} disabled={!editing} {...field} />
                  </SydLabel>
                )}
              />
            </div>
          </Grid>
        ))}
      </Grid>
    </div>
  )
})

ContactInfoForm.propTypes = {
  user: PropTypes.shape({
    userId: PropTypes.number,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string
  }),
  teamMember: PropTypes.shape({
    title: PropTypes.string,
    email: PropTypes.string,
    phone1: PropTypes.string,
    phone2: PropTypes.string,
    phone3: PropTypes.string,
    roleTypeId: PropTypes.string
  }),
  roles: PropTypes.array,
  editing: PropTypes.bool,
  attributes: PropTypes.arrayOf(PropTypes.shape({
    accessor: PropTypes.string,
    label: PropTypes.string,
    type: PropTypes.string,
    description: PropTypes.string,
    data: PropTypes.object
  }))
}

ContactInfoForm.defaultProps = {
  editing: false
}

export default ContactInfoForm
