import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import { FormLabel, makeStyles } from '@material-ui/core'
import isEmpty from 'lodash.isempty'

import { Link } from 'react-router-dom'
import NoteThreadTitle from '../molecules/NoteThreadTitle'
import NumberFormat from '../atoms/NumberFormat'
import Skeleton from '../atoms/Skeleton'
import Icon from '../atoms/Icon'

import { fetchSalesforceClient } from '../../service'
import {
  HTTP_STATUS_CODES,
  SALESFORCE_ITEMS,
  SKELETON_VARIANTS,
  TEXT_VARIANTS,
  ICON_NAMES
} from '../../constants'
import { numeralByCase, tableNumberFormatter } from '../../utils'

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    padding: '20px'
  },
  infoContainer: {
    display: 'inline-block',
    width: '50%'
  },
  iconContainer: {
    width: '5rem',
    height: '5rem',
    margin: '0 auto'
  },
  header: {
    ...theme.typography[TEXT_VARIANTS.subtitle2],
    color: 'inherit'
  },
  center: {
    ...theme.typography[TEXT_VARIANTS.subtitle2],
    color: 'inherit',
    textAlign: 'center'
  },
  label: {
    ...theme.typography[TEXT_VARIANTS.body1],
    color: 'inherit'
  },
  salesforceAccountLink: {
    margin: '0 100px 0 0',
    fontSize: '12px',
    textDecoration: 'underline'
  }
}))

const TITLE_HEIGHT = 56

const SalesforceBoard = ({ salesforceId }) => {
  const classes = useStyles()

  const [salesforceInfo, setSalesforceInfo] = useState({})
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    async function getSalesforceData () {
      const { response, data } = await fetchSalesforceClient({
        clientId: salesforceId
      })

      if (response.status === HTTP_STATUS_CODES.SUCCESS) {
        setSalesforceInfo(data)
      }

      setIsLoading(false)
    }

    getSalesforceData()
  }, [salesforceId, salesforceInfo])

  const salesforceAccountLink = useMemo(() => {
    return salesforceInfo?.url
      ? <Link to={{ pathname: salesforceInfo.url }} target='_blank' className={classes.salesforceAccountLink}>View in Salesforce</Link>
      : undefined
  }, [salesforceInfo?.url, classes.salesforceAccountLink])

  return (
    <>
      <NoteThreadTitle
        title='Salesforce'
        onClose={noop}
        height={TITLE_HEIGHT}
        showClose={false}
        otherAction={salesforceAccountLink}
      />
      <div className={classes.mainContainer}>

        {isLoading ? (
          <>
            <Skeleton width='100%' height='72px' variant={SKELETON_VARIANTS.text} />
            <Skeleton width='100%' height='72px' variant={SKELETON_VARIANTS.text} />
            <Skeleton width='100%' height='72px' variant={SKELETON_VARIANTS.text} />
            <Skeleton width='100%' height='72px' variant={SKELETON_VARIANTS.text} />
            <Skeleton width='100%' height='72px' variant={SKELETON_VARIANTS.text} />
          </>
        ) : (
          <>
            {
              !isEmpty(salesforceInfo)
                ? Object.keys(SALESFORCE_ITEMS).map((key, index) => (
                  <div className={classes.infoContainer} key={key + index}>
                    <h1 className={classes.header}>{SALESFORCE_ITEMS[key].title}</h1>
                    <FormLabel htmlFor='accountName' className={classes.label}>
                      {
                        SALESFORCE_ITEMS[key].isNumber
                          ? <NumberFormat number={tableNumberFormatter(salesforceInfo[key])} title={numeralByCase(salesforceInfo[key])} skipFormat />
                          : salesforceInfo[key] ?? '-'
                      }
                    </FormLabel>
                  </div>
                ))
                : (
                  <>
                    <div className={classes.iconContainer}>
                      <Icon name={ICON_NAMES.noInfo} customSize='100%' />
                    </div>
                    <h1 className={classes.center}>Missing Information</h1>
                  </>
                )
            }
          </>
        )}
      </div>
    </>

  )
}

SalesforceBoard.propTypes = {
  salesforceId: PropTypes.string.isRequired
}

SalesforceBoard.defaultProps = {
  salesforceId: ''
}

export default React.memo(SalesforceBoard)
