import React, { useEffect, useMemo, useRef } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { ClickAwayListener, IconButton } from '@material-ui/core'
import noop from 'lodash/noop'
import clsx from 'clsx'
import isEmpty from 'lodash/isEmpty'
import Icon from '../atoms/Icon'
import { ICON_NAMES, TASK_STATUS } from '../../constants'
import { useToggle } from '../../hooks'
import InputInlineEdit from './InputInlineEdit'
import TaskItemSkeleton from './TaskItemSkeleton'

const useStyles = makeStyles(() => ({
  container: {
    position: 'relative',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '0.5rem'
    // border: '2px solid #EEF0F8',
    // borderRadius: '0.75rem'
    // marginBottom: '1.25rem'
  },
  containerFocused: {
    border: '2px solid #212945'
  },
  actions: {
    marginLeft: 'auto'
  },
  checkmark: {
    // marginRight: '1rem'
  },
  skeletonText: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  bodyInput: ({ completed }) => ({
    color: completed ? '#898D9B' : '#141929',
    fontSize: '0.875rem'
  })
}))

const TaskItem = ({
  task,
  readOnly,
  isLoading,
  disabled,
  onRemove,
  onComplete,
  onSaveBody,
  onChangeBody,
  bodyPlaceholder,
  isPlaceholderTask,
  taskPlaceholderTitle,
  taskPlaceholderSubtitle,
  onPlaceholderTaskClick
}) => {
  const classes = useStyles({
    completed: task.status === TASK_STATUS.complete
  })
  const inputBodyRef = useRef()
  const [editModeEnabled, , editModeOn, editModeOff] = useToggle(task.isNew)

  const renderRadioIcon = useMemo(() => {
    const iconProps = { customSize: '1.25rem' }
    if (task.status === TASK_STATUS.complete) {
      iconProps.name = ICON_NAMES.checkCircle
      iconProps.color = '#3AA76D'
    } else {
      iconProps.name = ICON_NAMES.radioUnchecked
      iconProps.color = '#212945'
    }
    if (isEmpty(task?.description?.trim())) {
      iconProps.color = '#21294580'
    }
    return <Icon {...iconProps} />
  }, [task])

  useEffect(() => {
    if (inputBodyRef.current) {
      if (editModeEnabled && !readOnly) {
        inputBodyRef.current.setEditModeOn()
      } else {
        inputBodyRef.current.setEditModeOff()
      }
    }
  }, [editModeEnabled, readOnly])

  const containerClasses = clsx(classes.container, {
    [classes.containerFocused]: editModeEnabled && !readOnly
  })

  if (isLoading || isPlaceholderTask) {
    return (
      <TaskItemSkeleton
        onClick={onPlaceholderTaskClick}
        isPlaceholderTask={isPlaceholderTask}
        placeholderTitle={taskPlaceholderTitle}
        placeholderSubtitle={taskPlaceholderSubtitle}
        disabled={disabled}
      />
    )
  }

  return (
    <ClickAwayListener onClickAway={editModeOff}>
      <div className={containerClasses} onClick={editModeOn}>
        <div className={classes.checkmark}>
          <IconButton
            onClick={onComplete}
            disabled={isEmpty(task?.description?.trim()) || readOnly}
          >
            {renderRadioIcon}
          </IconButton>
        </div>
        <InputInlineEdit
          ref={inputBodyRef}
          autoFocus
          multiline
          width='100%'
          disableUnderline
          hideActionButtons
          value={task.description}
          initInEditMode={task.isNew}
          placeholder={bodyPlaceholder}
          className={classes.bodyInput}
          onChangeHandler={onChangeBody}
          onIconClickHandler={onSaveBody}
        />
        {!readOnly && (
          <IconButton className={classes.actions} onClick={onRemove}>
            <Icon
              name={ICON_NAMES.removeCircle}
              customSize='1.25rem'
              color='#D44333'
            />
          </IconButton>
        )}
      </div>
    </ClickAwayListener>
  )
}

TaskItem.propTypes = {
  task: PropTypes.shape({
    id: PropTypes.string,
    description: PropTypes.string,
    status: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    isNew: PropTypes.bool
  }),
  readOnly: PropTypes.bool,
  isLoading: PropTypes.bool,
  onRemove: PropTypes.func,
  onComplete: PropTypes.func,
  onSaveBody: PropTypes.func,
  onChangeBody: PropTypes.func,
  isPlaceholderTask: PropTypes.bool,
  bodyPlaceholder: PropTypes.string,
  onPlaceholderTaskClick: PropTypes.func,
  taskPlaceholderTitle: PropTypes.string,
  taskPlaceholderSubtitle: PropTypes.string,
  disabled: PropTypes.bool
}

TaskItem.defaultProps = {
  task: {},
  readOnly: false,
  isLoading: false,
  onRemove: noop,
  onComplete: noop,
  onSaveBody: noop,
  onChangeBody: noop,
  isPlaceholderTask: false,
  onPlaceholderTaskClick: noop,
  bodyPlaceholder: 'Describe task',
  taskPlaceholderTitle: 'No Tasks',
  taskPlaceholderSubtitle: 'Click to add',
  disabled: false
}

export default TaskItem
