import { useMemo } from 'react'
import { useFormattingContext } from '../../FormattingProvider/FormattingContext'
import ClientNameCell from './Cells/ClientNameCell'
import ClientOptionsCell from './Cells/ClientOptionsCell'
import ClientBookmarkCell from './Cells/ClientBookmarkCell'
import ClientTagsCell from './Cells/ClientTagsCell'

export const defaultColumnConfig = {
  columns: [
    {
      id: 'bookmark',
      accessor: 'bookmark',
      Header: '',
      align: 'center',
      Cell: ClientBookmarkCell
    },
    {
      id: 'name',
      accessor: 'longName',
      Header: 'Client Name',
      format: 'title',
      align: 'left',
      sticky: 'left',
      Cell: ClientNameCell
    },
    {
      id: 'tags',
      Header: 'Tags',
      visibleTagsUntilCounting: 2,
      Cell: ClientTagsCell
    },
    {
      id: 'totalValue',
      accessor: 'balance.endingValue',
      Header: 'Total Account Value',
      format: 'human',
      align: 'right'
    },
    {
      id: 'options',
      accessor: 'options',
      Header: 'Options',
      format: 'human',
      align: 'right',
      Cell: ClientOptionsCell
    }
  ],
  defaultSort: [{ id: 'longName', desc: false }]
}

export const useColumns = ({ columnsConfig = defaultColumnConfig, format }) => {
  const { formatter } = useFormattingContext(format)

  return useMemo(() => {
    const { columns, defaultSort } = columnsConfig
    const templatedColumns = columns.map((column) => {
      if (column?.Cell) return column

      return {
        ...column,
        Cell: ({ value }) => formatter(value, column.format)
      }
    })

    return {
      columns: templatedColumns,
      defaultSort
    }
  }, [columnsConfig, formatter])
}
