import React, { useCallback, useRef } from 'react'
import { makeStyles } from '@material-ui/core'
import { useClientDetails, useSectionEditing } from '../ClientDetailsFormContext'
import ErrorBoundary from '../../../../molecules/ErrorBoundary'
import FadeIn from '../../../../molecules/Transitions/FadeIn'
import { useCreatePersonMutation, useInvalidatePeopleSearch } from '../../../../../api/people'
import { useModifyClientPersonsMutation } from '../../../../../api/clients'
import { useAssignRoleToWealthOwnerMutation, useCreateWealthOwnerMutation } from '../../../../../api/users'
import StyledButton from '../../shared/StyledButton'
import { usePolicy } from '../../../../../hooks/usePolicy'
import SectionScreen from '../SectionScreen'
import SectionHeader from '../../shared/SectionHeader'
import EditButton from '../EditButton'
import PersonalSpace from '../../shared/PersonalSpace'
import ClientPeopleTable from './ClientPeopleTable'
import AssignExperienceDialog from './AssignExperienceDialog'
import CreateUserDialog from './CreateUserDialog'
import CreatePersonDialog from './CreatePersonDialog'

const useStyles = makeStyles((theme) => ({
  peopleSection: {
    position: 'relative',
    padding: '10px',
    transition: 'outline-color 300ms ease-in-out',
    minHeight: '500px'
  }
}))

function PeopleTab () {
  const { client, editSection } = useClientDetails()
  const editing = useSectionEditing('people')
  const { mutateAsync: assignPeople } = useModifyClientPersonsMutation()

  const formRef = useRef()
  const assignRoleRef = useRef()
  const createUserRef = useRef()
  const createPersonRef = useRef()

  const onSave = useCallback(async () => {
    try {
      await assignPeople({
        clientId: client.clientId,
        added: formRef.current.added,
        removed: formRef.current.removed
      })
    } catch (err) {
      console.error(err)
      alert('An unexpected error has occurred')
    }
  }, [client, formRef, assignPeople])

  const { mutateAsync: createWealthOwner } = useCreateWealthOwnerMutation()
  const invalidatePeopleSearch = useInvalidatePeopleSearch()
  const onCreateUser = useCallback(async ({ user, roleId, invite, personId }) => {
    await createWealthOwner({
      user,
      roleId,
      invite,
      clientIds: [client.clientId],
      link: {
        personId
      }
    })
    await invalidatePeopleSearch()
  }, [client, createWealthOwner, invalidatePeopleSearch])

  const { mutateAsync: createPerson } = useCreatePersonMutation()
  const onCreatePerson = useCallback(async ({ person, clientIds }) => {
    try {
      await createPerson({
        person: {
          firstName: person.firstName || null,
          lastName: person.lastName || null,
          email: person.email || null,
          personTypeId: person.personTypeId || null
        },
        clientIds: clientIds || null
      })
      await invalidatePeopleSearch()
    } catch (err) {
      console.error('Error creating person', err)
      alert('There was an unexpected error creating the person.')
      throw err
    }
  }, [createPerson, invalidatePeopleSearch])

  const { mutateAsync: assignRole } = useAssignRoleToWealthOwnerMutation()
  const onAssignRole = useCallback(async ({ userId, roleId }) => {
    await assignRole({
      userId,
      roleId
    })
    await invalidatePeopleSearch()
  }, [invalidatePeopleSearch, assignRole])

  const canCreatePerson = usePolicy('admin_create_persons')
  const classes = useStyles({ editing })

  return (
    <ErrorBoundary name='People Tab'>
      <SectionScreen sectionName='people' className={classes.peopleSection}>
        <SectionHeader text='Included People'>
          <div>
            <EditButton
              policy='admin_edit_client_persons'
              editing={editing}
              onClick={() => editSection({
                section: 'people',
                saveDescription: 'Save People Assignments',
                onSave
              })}
              buttonText='Edit Assignments'
            />
          </div>
          {canCreatePerson ? (
            <StyledButton
              disabled={editing}
              onClick={() => createPersonRef.current.open()}
              icon='add'
            >
              Create Person
            </StyledButton>
          ) : null}
        </SectionHeader>
        <FadeIn>
          <ClientPeopleTable
            ref={formRef}
            client={client}
            editing={editing}
            onCreateUser={(person) => createUserRef.current.open(person)}
            onAssignRole={(person) => assignRoleRef.current.open(person)}
          />
        </FadeIn>
        <PersonalSpace />
      </SectionScreen>
      <AssignExperienceDialog onConfirm={onAssignRole} ref={assignRoleRef} />
      <CreateUserDialog onConfirm={onCreateUser} ref={createUserRef} />
      <CreatePersonDialog onConfirm={onCreatePerson} ref={createPersonRef} />
    </ErrorBoundary>
  )
}

PeopleTab.propTypes = {
}

export default PeopleTab
