import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import FlexRow from '../../../../molecules/FlexRow'
import { ICON_NAMES } from '../../../../../constants'
import Icon from '../../../../atoms/Icon'

const useStyles = makeStyles(theme => ({
  taskListItem: {
    fontSize: theme.typography.fontSizes.xl,
    fontWeight: theme.typography.weights.light
  }
}))

function TaskListItem ({ task }) {
  const classes = useStyles()

  return (
    <div className={classes.taskListItem}>
      <FlexRow style={{ alignItems: 'center', gap: '20px' }}>
        <Icon name={task.status === 'complete' ? ICON_NAMES.radioChecked : ICON_NAMES.radioUnchecked} />
        <div>{task.description}</div>
      </FlexRow>
    </div>
  )
}

TaskListItem.propTypes = {
  task: PropTypes.shape({
    id: PropTypes.number,
    status: PropTypes.oneOf(['complete', 'incomplete']),
    description: PropTypes.string
  })
}

export default TaskListItem
