import React, { useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import ClientIntegrationsDrawer from '../../ContextualClientInfo/components/ClientIntegrationsDrawer'
import { useContextContainer } from '../../../../abundanceEngine/components/ContextContainer'
import { CLIENT_VIEW_CONTEXT } from '..'
import { useContextualClientContext } from '../../ContextualClientInfo/context/ContextualClientContext'

const ContextualClientDrawer = ({ children, showPoweredByLegend }) => {
  const { setContextualClientId } = useContextualClientContext()

  const [{ drawerOpen, integrationsInfo }, setClientViewContext] =
    useContextContainer(CLIENT_VIEW_CONTEXT)

  useEffect(() => {
    if (integrationsInfo?.clientId) {
      setContextualClientId(integrationsInfo?.clientId)
    }
  }, [integrationsInfo?.clientId, setContextualClientId])

  const onToggleDrawer = useCallback(() => {
    setClientViewContext((prevState) => ({
      ...prevState,
      drawerOpen: !prevState.drawerOpen
    }))
  }, [setClientViewContext])

  return (
    <ClientIntegrationsDrawer
      open={drawerOpen}
      toggleOpen={onToggleDrawer}
      showPoweredByLegend={showPoweredByLegend}
    >
      {children}
    </ClientIntegrationsDrawer>
  )
}

ContextualClientDrawer.propTypes = {
  children: PropTypes.node,
  showPoweredByLegend: PropTypes.bool
}

export default ContextualClientDrawer
