import React, { useCallback, useImperativeHandle, useState } from 'react'
import RoundedModal from '../../../../../../molecules/RoundedModal'
import AddAccountOverrideForm from './form'

const AddAccountOverrideDialog = React.forwardRef(function AddAccountOverrideDialog (_, ref) {
  const [dialogState, setDialogState] = useState({ open: false, processing: false })
  const close = useCallback(() => {
    setDialogState({ open: false, processing: false })
  }, [setDialogState])

  useImperativeHandle(ref, () => ({
    open: (client) => setDialogState({ open: true, processing: false, client })
  }), [setDialogState])

  return (
    <RoundedModal
      title='Add Account Override'
      open={dialogState.open}
      onClose={close}
      position='top'
      size='large'
      disablePortal={false}
    >
      <div>
        {dialogState.open ? (<AddAccountOverrideForm client={dialogState.client} onComplete={close} onCancel={close} />) : null}
      </div>
    </RoundedModal>
  )
})

AddAccountOverrideDialog.propTypes = {
}

export default AddAccountOverrideDialog
