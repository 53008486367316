import React, { useCallback, useImperativeHandle, useMemo, useState } from 'react'
import { makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import PropTypes from 'prop-types'
import SydModal from '../../../commonDesign/SydModal'
import DragAndDropListItem from '../../GroupingProvider/DragAndDropListItem'
import Switch from '../../../molecules/Switch'
import SydButton from '../../../commonDesign/Button'

const useStyles = makeStyles((theme) => ({
  actionButton: {
    marginTop: theme.layout.margin.m10,
    width: '100%'
  },
  columnOption: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    '&.__disabled': {
      color: `color-mix(in srgb, ${theme.palette.text.primary} 50%, transparent)`
    }
  }
}))

const CustomizeColumnsDialog = React.forwardRef(function ({ onSave }, ref) {
  const classes = useStyles()
  const [isOpen, setIsOpen] = useState(false)
  const [columns, setColumns] = useState({})
  useImperativeHandle(ref, () => ({
    open: (colState) => {
      setIsOpen(true)
      setColumns(colState)
    }
  }), [setIsOpen, setColumns])

  const columnItems = useMemo(() => Object.values(columns).sort((a, b) => a.ordinal - b.ordinal), [columns])
  const onMoveItem = useCallback((dragIndex, hoverIndex) => {
    setColumns((prevItems) => {
      const asArray = Object.values(prevItems).sort((a, b) => a.ordinal - b.ordinal)
      const newItems = [...asArray]
      const dragItem = newItems[dragIndex]
      newItems.splice(dragIndex, 1)
      newItems.splice(hoverIndex, 0, dragItem)
      return newItems.reduce((prev, cur, index) => {
        prev[cur.id] = {
          ...cur,
          ordinal: index
        }
        return prev
      }, {})
    })
  }, [])

  const toggleItem = useCallback((item) => (e) => {
    const checked = e.target.checked
    setColumns((prevItems) => ({
      ...prevItems,
      [item.id]: {
        ...prevItems[item.id],
        hidden: !checked
      }
    }))
  }, [])

  const handleSave = useCallback(() => {
    onSave(columns)
    setIsOpen(false)
  }, [columns, onSave, setIsOpen])

  return (
    <SydModal
      title='Configure Columns'
      open={isOpen}
      onClose={() => setIsOpen(false)}
      size='small'
    >
      <div>
        <DndProvider backend={HTML5Backend}>
          {columnItems.map((csItem, index) => {
            return (
              <DragAndDropListItem
                id={csItem.id}
                key={csItem.id}
                index={index}
                value={csItem.id}
                onMoveItem={onMoveItem}
              >
                <div className={clsx(classes.columnOption, { __disabled: csItem.hidden })}>
                  <span>
                    {csItem.Header || csItem.header}
                  </span>
                  <span>
                    <Switch
                      checked={!csItem.hidden}
                      onChange={toggleItem(csItem)}
                    />
                  </span>
                </div>
              </DragAndDropListItem>
            )
          })}
        </DndProvider>
        <div className={classes.actions}>
          <SydButton
            variant='primary'
            onClick={handleSave}
            className={classes.actionButton}
          >
            Save
          </SydButton>
        </div>
      </div>
    </SydModal>
  )
})

CustomizeColumnsDialog.propTypes = {
  onSave: PropTypes.func
}

export default CustomizeColumnsDialog
