import dayjs from 'dayjs'

const mapMaturityKeyValues = (data) => {
  return data.reduce((acc, datum) => {
    if (!datum.maturity) return acc
    const maturityYear = dayjs(datum.maturity).year()
    return {
      ...acc,
      [maturityYear]: [...(acc[maturityYear] || []), { ...datum }]
    }
  }, {})
}

const getValue = (assets) =>
  assets.reduce((acc, asset) => acc + asset.endingValue, 0)

export const mapMaturityValues = ({
  data,
  color,
  startYear,
  groupAfterYear
}) => {
  const maturityKeyValues = mapMaturityKeyValues(data)

  const { baseValues, groupedValues } = Object.entries(
    maturityKeyValues
  ).reduce(
    (acc, [maturityYear, assets]) => {
      if (maturityYear < startYear) return acc

      const value = getValue(assets)

      if (groupAfterYear && maturityYear > groupAfterYear.toString()) {
        acc.groupedValues = {
          ...acc.groupedValues,
          ...(!acc.groupedValues ? { label: `${maturityYear} + beyond` } : {}),
          value: (acc?.groupedValues?.value || 0) + value,
          valueColor: color
        }
      } else {
        acc.baseValues.push({
          label: maturityYear,
          value,
          valueColor: color
        })
      }
      return acc
    },
    { baseValues: [], groupedValues: null }
  )

  if (groupedValues) {
    baseValues.push(groupedValues)
  }

  return baseValues
}
