import React from 'react'
import { Redirect } from 'react-router-dom'
import { useIndexPath } from './HomeDashboardContext'

function IndexRedirect () {
  const indexPath = useIndexPath()

  return <Redirect to={indexPath} />
}

export default IndexRedirect
