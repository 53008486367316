import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Icon from '../../atoms/Icon'
import PanelTrigger from './PanelTrigger'

const useStyles = makeStyles((theme) => ({
  attachmentButton: {
    '&:hover': {
      cursor: 'pointer'
    }
  }
}))

function AttachmentButton ({ item, parent }) {
  const classes = useStyles()

  return (
    <PanelTrigger
      className={classes.attachmentButton}
      item={item}
      parent={parent}
      tab='attachments'
    >
      <Icon name='document' />
    </PanelTrigger>
  )
}

AttachmentButton.propTypes = {
  item: PropTypes.any,
  parent: PropTypes.any
}

export default React.memo(AttachmentButton)
