import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { makeStyles, InputLabel, Box } from '@material-ui/core'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import RoundedButton from '../../../../../../atoms/RoundedButton'
import Select from '../../../../../../molecules/Select'
import { useListSavedRequests } from '../../../../../../../api/bobi'
import DateSelector from '../../../DateSelector'
import { useLastBusinessDate } from '../../../useLastBusinessDate'
import { INTERNAL_DATE_FORMAT } from '../../../../../../../constants'
import { postNamedCommand, postNamedQuery } from '../../../../../../../service'
import { useLevelDates } from '../../../../../../../api/coreData'
import { levelDatesQuery } from '../../../helpers'

dayjs.extend(utc)

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    gap: '1rem',
    padding: '1.5rem 0',
    justifyContent: 'center',
    alignItems: 'center'
  },
  datePickerContainer: {
    minWidth: '10rem',
    '& > svg': {
      inset: '0.45rem 1rem 1rem'
    }
  },
  datePicker: {
    padding: '0.47rem 0.5rem',
    '& input': {
      textAlign: 'left'
    }
  },
  label: {
    fontSize: '1rem',
    color: '#141929',
    marginBottom: '0.5rem',
    '& span': {
      color: theme.palette.error.primary
    }
  },
  select: {
    width: '15rem'
  }
}))

const exportTypeOptions = [
  {
    label: 'CSV',
    value: 'CSV'
  }
]

const SystemReportsForm = ({ defaultValues }) => {
  const classes = useStyles()

  const [systemReport, setSystemReport] = useState(defaultValues.reportName)
  const [exportType, setExportType] = useState(defaultValues.exportType)
  const [asOfDate, setAsOfDate] = useState(defaultValues.asOfDate)

  const runHandler = useCallback(async () => {
    try {
      const { data } = await postNamedQuery('bobi', 'rebuildSavedRequest', {
        savedRequestId: systemReport.savedRequestId,
        asOfDate
      })
      const { body: reportBody } = data
      await postNamedCommand('bobi', 'runOfflineQuery', {
        ...reportBody,
        extra: {
          ...(reportBody.extra ?? {}),
          ...(systemReport?.metadata ?? {}),
          savedRequestName: systemReport?.name || null,
          savedRequest: true
        }
      })
    } catch (ex) {
      console.error('[BOBI Report] report creating failed', ex)
    }
  }, [systemReport, asOfDate])

  const savedRequestsQueryParams = useMemo(() => {
    return {
      mapper: (data) => {
        return data
          .map((item) => {
            const {
              name,
              savedRequestId
            } = item
            return {
              label: name,
              value: savedRequestId,
              payload: item
            }
          })
      }
    }
  }, [])

  const {
    data: savedRequestOptions,
    isLoading: isLoadingSavedRequests
  } = useListSavedRequests(undefined, savedRequestsQueryParams)

  const {
    data: availableDates,
    isLoading: isLoadingAvailableDates
  } = useLevelDates(levelDatesQuery)

  const {
    lastBusinessDate,
    isLoading: isLoadingLastBusinessDay
  } = useLastBusinessDate(availableDates)

  const isLoadingLevelDates = isLoadingAvailableDates || isLoadingLastBusinessDay

  useEffect(() => {
    if (!isLoadingLevelDates && lastBusinessDate?.endDate) {
      setAsOfDate(
        dayjs
          .utc(lastBusinessDate.endDate || defaultValues.asOfDate)
          .toISOString()
      )
    }
  }, [lastBusinessDate, defaultValues, isLoadingLevelDates])

  const disableRunButton = !asOfDate || !systemReport || !exportType

  return (
    <div className={classes.container}>
      <div>
        <InputLabel className={classes.label}>Report Name</InputLabel>
        <Box width='27rem'>
          <Select
            variant='outlined'
            placeholder='Select a report'
            value={systemReport?.savedRequestId}
            onChange={(_, selectedOption) =>
              setSystemReport(selectedOption.payload)}
            options={savedRequestOptions}
            disabled={isLoadingSavedRequests}
            showCheckMarOnSelectedItems
            fullWidth
          />
        </Box>
      </div>
      <div>
        <InputLabel className={classes.label}>As of Date</InputLabel>
        <DateSelector
          value={dayjs.utc(asOfDate).format(INTERNAL_DATE_FORMAT)}
          onChange={setAsOfDate}
          disabledDateRange={{
            min: lastBusinessDate?.startDate,
            max: lastBusinessDate?.endDate
          }}
          containerClassName={classes.datePickerContainer}
          className={classes.datePicker}
          disabled={isLoadingLastBusinessDay}
          isLoading={isLoadingLastBusinessDay}
          skeletonProps={{ height: '2.15rem' }}
        />
      </div>
      <div>
        <InputLabel className={classes.label}>Report Type</InputLabel>
        <Select
          value={exportType}
          onChange={setExportType}
          variant='outlined'
          placeholder='Select an export option'
          options={exportTypeOptions}
          showCheckMarOnSelectedItems
        />
      </div>
      <RoundedButton primary onClick={runHandler} disabled={disableRunButton}>
        Run
      </RoundedButton>
    </div>
  )
}

SystemReportsForm.propTypes = {
  defaultValues: PropTypes.object
}

SystemReportsForm.defaultProps = {
  defaultValues: {
    reportName: '',
    asOfDate: '',
    exportType: exportTypeOptions?.[0]?.value
  }
}

export default SystemReportsForm
