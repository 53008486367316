import React from 'react'
import PropTypes from 'prop-types'
import { animated, useSpring } from 'react-spring'
import { IconButton, makeStyles } from '@material-ui/core'
import noop from 'lodash/noop'
import Icon from '../../../atoms/Icon'
import { ICON_NAMES } from '../../../../constants'
import ExportButton from '../../../molecules/ExportButton/ExportButton'

const useStyles = makeStyles((theme) => ({
  actionButtons: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    alignSelf: 'flex-end',
    transition: 'margin-top .1s ease-in-out',
    marginTop: ({ expanded }) => expanded ? '-1rem' : 0
  },
  fullViewOptions: {
    backgroundColor: theme.palette.transparent,
    display: 'flex',
    position: 'absolute',
    top: '0.5rem',
    right: '1rem',
    zIndex: 1,
    borderTopLeftRadius: '30%',
    borderBottomLeftRadius: '30%'
  },
  [theme.breakpoints.down('xs')]: {
    actionButtons: {
      display: 'none'
    }
  }
}))

const ActionButtons = ({ hidden, onClose, idle, onExpandToggle, expanded }) => {
  const classes = useStyles({ expanded })
  const springs = useSpring({
    to: { opacity: idle ? 0 : 1 },
    from: { opacity: 1 },
    leave: { opacity: 0 }
  })

  if (hidden) return null

  return (
    <animated.div className={classes.actionButtons} style={springs}>
      <div className={classes.fullViewOptions}>
        <ExportButton />
        <IconButton
          aria-label='expand full view'
          aria-haspopup='true'
          onClick={onExpandToggle}
          color='inherit'
        >
          <Icon
            customSize='1.2rem'
            name={expanded ? ICON_NAMES.collapse : ICON_NAMES.expand}
          />
        </IconButton>
        <IconButton
          aria-label='close full view'
          aria-haspopup='true'
          onClick={onClose}
          color='inherit'
        >
          <Icon name={ICON_NAMES.close} customSize='1.2rem' />
        </IconButton>
      </div>
    </animated.div>
  )
}

ActionButtons.propTypes = {
  hidden: PropTypes.bool,
  idle: PropTypes.bool,
  expanded: PropTypes.bool,
  onClose: PropTypes.func,
  onExpandToggle: PropTypes.func
}

ActionButtons.defaultProps = {
  hidden: false,
  idle: false,
  expanded: false,
  onClose: noop,
  onExpandTiggle: noop
}

export default ActionButtons
