import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { Box } from '@material-ui/core'
import { ADMIN_ROUTES } from '../../../../../../../constants'
import SydButton from '../../../../../../commonDesign/Button'
import { RUN_STATUS } from '../../../runBilling/helpers'
import { useCheckPolicy } from '../../../../../../../hooks'
import { BILLING } from '../../../../../../../policies/admin'

const CellActions = ({ row: { original }, onTriggerStatusChange }) => {
  const history = useHistory()
  const userCanEdit = useCheckPolicy(BILLING.viewDetails)

  const billingRunIsEditable = useMemo(() =>
    userCanEdit && original.status === RUN_STATUS.IN_REVIEW
  , [userCanEdit, original.status])

  return (
    <>
      <Box display='flex' flexWrap='wrap' justifyContent='space-between' gridGap={8}>
        <SydButton
          onClick={() => history.push(`${ADMIN_ROUTES.BILLING_RUN_DETAILS}/${original.billingRunId}`)}
          variant='secondary'
          size='sm'
        >
          {billingRunIsEditable ? 'Edit Details' : 'View Details'}
        </SydButton>
      </Box>
    </>
  )
}

CellActions.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.object
  }),
  onTriggerStatusChange: PropTypes.func
}

export default CellActions
