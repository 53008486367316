import React from 'react'
import { makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import SydButton from '../../../commonDesign/Button'

const useStyles = makeStyles((theme) => ({
  saveCancel: {
    position: 'fixed',
    backgroundColor: theme.palette.white,
    bottom: 0,
    opacity: 1,
    left: '50%',
    padding: '1rem 0',
    transform: 'translate(-50%, 0)',
    width: '100vw',
    zIndex: 2,
    boxShadow: `0px 0px 10px 0px ${theme.palette.gray.main}`,
    animation: '$pullUp 200ms ease-in',
    display: 'flex',
    flexDirection: 'row',
    gap: '100px',
    alignItems: 'center',
    justifyContent: 'center'
  },
  '@keyframes pullUp': {
    from: {
      transform: 'translate(-50%, 100%)'
    },
    to: {
      transform: 'translate(-50%, 0)'
    }
  }
}))

function SaveCancelFooter ({ onSave, onCancel, processing, disabled }) {
  const classes = useStyles()

  return (
    <div className={classes.saveCancel}>
      <SydButton size='xl' icon='close' variant='outline' onClick={onCancel} disabled={processing}>Cancel</SydButton>
      <SydButton size='xl' icon='check' variant='primary' onClick={onSave} disabled={disabled} processing={processing}>Submit</SydButton>
    </div>
  )
}

SaveCancelFooter.propTypes = {
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  processing: PropTypes.bool,
  disabled: PropTypes.bool
}

export default SaveCancelFooter
