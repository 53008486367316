import React, { useCallback, useState } from 'react'
import { Menu, MenuItem, makeStyles, Button } from '@material-ui/core'
import PropTypes from 'prop-types'
import { ICON_NAMES } from '../../../../constants'
import Icon from '../../../atoms/Icon'
import EditClientInfoButton from '../../ClientInfoModal/EditClientInfoButton'
import { useHomeDashboard } from '../HomeDashboardContext'
import { usePolicy } from '../../../../hooks/usePolicy'

const useStyles = makeStyles((theme) => ({
  clientActions: {
    position: 'absolute',
    top: 0,
    right: 0
  },
  menuItem: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.layout.gap.medium,
    alignItems: 'center'
  }
}))

function ClientActionMenu ({ children }) {
  const { client } = useHomeDashboard()
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState()
  const open = useCallback((e) => {
    setAnchorEl(e.target)
  }, [setAnchorEl])
  const close = useCallback(() => setAnchorEl(null), [])
  const canViewAdmin = usePolicy('admin_view_accts')

  return (
    <>
      <div className={classes.clientActions}>
        <Button aria-controls='more-opts' aria-haspopup='true' onClick={open}>
          <Icon name={ICON_NAMES.threeDots} customSize='1.5rem' />
        </Button>
        <Menu
          id='client-actions'
          keepMounted
          anchorEl={anchorEl}
          open={!!anchorEl}
          onClose={close}
        >
          <MenuItem>
            <EditClientInfoButton client={client} onClose={close}>
              <div className={classes.menuItem}>
                <Icon name='edit' />
                <div>Edit Client Info</div>
              </div>
            </EditClientInfoButton>
          </MenuItem>
          <hr />
          {canViewAdmin ? (
            <MenuItem onClick={close}>
              <a className={classes.menuItem} target='_blank' rel='noopener noreferrer' href={`/admin/clients/${client.clientId}?tab=general`}>
                <Icon name='share' />
                <div>Open in Admin</div>
              </a>
            </MenuItem>
          ) : null}
        </Menu>
      </div>
      {children}
    </>
  )
}

ClientActionMenu.propTypes = {
  children: PropTypes.node
}

export default ClientActionMenu
