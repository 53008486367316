import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import Text from '../../../atoms/Text'

const useStyles = makeStyles(() => ({
  container: {
    paddingBottom: '0.625rem'
  },
  text: {
    fontSize: '1.125rem',
    color: '#212945',
    textTransform: 'uppercase',
    opacity: '0.7',
    fontWeight: 'bold'
  }
}))

const SectionSubtitle = ({ title }) => {
  const classes = useStyles()
  return (
    <div className={classes.container}>
      <Text text={title} className={classes.text} />
    </div>
  )
}

SectionSubtitle.propTypes = {
  title: PropTypes.string
}

export default SectionSubtitle
