import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Box, IconButton, makeStyles } from '@material-ui/core'
import { Controller, useFormContext } from 'react-hook-form'
import Icon from '../../../../atoms/Icon'
import SydLabel from '../../../../commonDesign/SydLabel'
import SydReadOnly from '../../../../commonDesign/SydReadOnly'
import SydAutocomplete from '../../../../commonDesign/SydAutocomplete'
import useDebouncedValue from '../../../../../hooks/useDebounceValue'
import FeeScheduleTiers from './FeeScheduleTiers'

const useStyles = makeStyles((theme) => ({
  feeScheduleAssignment: {},
  body: {
    display: 'flex',
    flexDirection: 'row',
    gap: '10px',
    alignItems: 'start'
  },
  name: {
    maxWidth: '440px',
    minWidth: '440px',
    display: 'flex',
    alignItems: 'center',
    fontWeight: theme.typography.weights.light,
    '& .MuiIconButton-root': {
      color: theme.palette.danger.main,
      fill: theme.palette.danger.main
    }
  }
}))

function FeeScheduleAssignment ({
  name,
  editing,
  feeSchedules,
  feeScheduleOptions
}) {
  const classes = useStyles()
  const { setValue, watch } = useFormContext()
  const currentValue = watch(name)
  const [searchVal, setSearchVal] = useState('')
  const debouncedSearchVal = useDebouncedValue(searchVal, 300)

  const filteredOptions = useMemo(() => {
    return feeScheduleOptions?.filter(option => {
      if (!debouncedSearchVal?.length) return true
      return option?.label?.toLowerCase().includes(debouncedSearchVal.toLowerCase()) ||
        option?.value?.toString().toLowerCase().includes(debouncedSearchVal.toLowerCase())
    })
  }, [feeScheduleOptions, debouncedSearchVal])

  const displayValue = useMemo(() => {
    return feeScheduleOptions?.find(x => x.value === currentValue) ?? null
  }, [currentValue, feeScheduleOptions])

  return (
    <div className={classes.feeScheduleAssignment}>
      <SydLabel label='Fee Schedule' />
      <div className={classes.body}>
        <div className={classes.name}>
          <Controller
            name={name}
            render={({ field, fieldState }) => (
              <Box flexGrow={1}>
                {editing ? (
                  <SydAutocomplete
                    name={field.name}
                    options={filteredOptions}
                    value={displayValue}
                    onChange={(e, val) => field.onChange(val?.value ?? null)}
                    placeholder='Search for fee schedules'
                    onInputChange={(e, val) => setSearchVal(val)}
                  />
                ) : (
                  <SydReadOnly>{feeSchedules.find(x => x.feeScheduleId === field.value)?.longName || 'No Fee Schedule'}</SydReadOnly>
                )}
              </Box>
            )}
          />
          {editing ? (
            <IconButton onClick={() => setValue(name, '', { shouldDirty: true, shouldTouch: true })}>
              <Icon name='removeCircle' />
            </IconButton>
          ) : null}
        </div>
        <Controller
          name={name}
          render={({ field }) => (
            <FeeScheduleTiers feeSchedule={feeSchedules.find(x => x.feeScheduleId === field.value)} />
          )}
        />
      </div>
    </div>
  )
}

FeeScheduleAssignment.propTypes = {
  name: PropTypes.string,
  editing: PropTypes.bool,
  feeSchedules: PropTypes.array,
  feeScheduleOptions: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.number
  }))
}

FeeScheduleAssignment.defaultProps = {
  name: 'feeScheduleId',
  editing: false
}

export default FeeScheduleAssignment
