import { Box, Divider, makeStyles } from '@material-ui/core'
import React from 'react'
import { SKELETON_VARIANTS } from '../../../../constants'
import Skeleton from '../../../atoms/Skeleton'

const useStyles = makeStyles(() => ({
  divider: {
    margin: '0.875rem 0',
    width: '60%',
    border: '1px solid #EEEEEE'
  }
}))

const CardSkeleton = () => {
  const classes = useStyles()
  return (
    <>
      <Skeleton
        variant={SKELETON_VARIANTS.circle}
        width='4rem'
        height='4rem'
      />
      <Box mb='0.875rem' />
      <Skeleton variant={SKELETON_VARIANTS.text} height='1rem' width='100%' />
      <Box mb='0.5rem' />
      <Skeleton
        variant={SKELETON_VARIANTS.text}
        height='0.875rem'
        width='100%'
      />
      <Divider className={classes.divider} />
      <Skeleton
        variant={SKELETON_VARIANTS.react}
        height='1.5rem'
        width='100%'
      />
    </>
  )
}

export default CardSkeleton
