import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { Button } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import LabeledValue from '../components/LabeledValue'
import DestinationDetail from './DestinationDetail'
import PostProcesses from './PostProcesses'
import PublishingFilters from './PublishingFilters'

const useStyles = makeStyles((theme) => ({
  policyItem: {
    backgroundColor: theme.palette.gray.main,
    padding: '0 .5rem',
    marginBottom: '.5rem',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '& > .__wrapper': {
      flex: '1 0 auto',
      paddingBottom: '.7rem'
    },
    '&:hover': {
      cursor: 'pointer'
    }
  },
  actions: {
    display: 'flex',
    minHeight: '10rem',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-around',
    flex: 1,
    '& button': {
      minWidth: '20rem',
      minHeight: '3rem'
    },
    '& .__primary': {
      backgroundColor: theme.palette.summitBlue,
      color: theme.palette.getContrastText(theme.palette.summitBlue)
    },
    '& .__danger': {
      backgroundColor: theme.palette.error.primary,
      color: theme.palette.getContrastText(theme.palette.error.primary)
    }
  }
}))

const PublishingPolicyItem = ({ policy }) => {
  const classes = useStyles()
  const history = useHistory()

  const handlePreviewAndPublish = useCallback((e) => {
    e.preventDefault()
    e.stopPropagation()

    history.push(`/data-home/targets/${policy.ingestionTargetId}/publish/${policy.publishingPolicyId}/preview`)
  }, [history, policy.publishingPolicyId, policy.ingestionTargetId])

  const handlePublish = useCallback((e) => {
    e.preventDefault()
    e.stopPropagation()

    const sure = window.confirm('Are you sure about that?')

    if (sure) {
      alert('Not Yet Implemented! :)')
    }
  }, [])

  return (
    <div className={classes.policyItem}>
      <div className='__wrapper'>
        <h3>
          <span>{policy.name}</span>
        </h3>
        <div>
          <LabeledValue label='Publish Mode'>{policy.publishMode}</LabeledValue>
          <LabeledValue label='Allow Create'>{policy.allowCreate ? 'Yes' : 'No'}</LabeledValue>
          <LabeledValue label='Allow Update'>{policy.allowUpdate ? 'Yes' : 'No'}</LabeledValue>
          <LabeledValue label='Audits Enabled'>{policy.enableAudit ? 'Yes' : 'No'}</LabeledValue>
          <LabeledValue label='Default Update Rule'>{policy.defaultUpdateRule}</LabeledValue>
          <LabeledValue label='Field Update Rules'><pre>{JSON.stringify(policy.fieldUpdateRules, null, 2)}</pre></LabeledValue>
        </div>
        <DestinationDetail destination={policy.destination} />
        <PublishingFilters filters={policy.filters} />
        <PostProcesses postProcesses={policy.postProcesses} />
      </div>
      <div className={classes.actions}>
        <Button className='__primary' variant='contained' onClick={handlePreviewAndPublish}>✅ Preview & Publish</Button>
        <Button className='__danger' variant='contained' onClick={handlePublish}>☠️ Publish Without Preview</Button>
      </div>
    </div>
  )
}

PublishingPolicyItem.propTypes = {
  policy: PropTypes.shape({
    publishingPolicyId: PropTypes.number,
    name: PropTypes.string,
    ingestionTargetId: PropTypes.number,
    publishMode: PropTypes.oneOf(['manual', 'automatic']),
    thresholds: PropTypes.object,
    allowCreate: PropTypes.bool,
    allowUpdate: PropTypes.bool,
    defaultUpdateRule: PropTypes.string,
    fieldUpdateRules: PropTypes.object,
    enableAudit: PropTypes.bool,
    destination: PropTypes.shape({
      name: PropTypes.string,
      schema: PropTypes.string,
      table: PropTypes.string,
      fields: PropTypes.arrayOf(PropTypes.string)
    }),
    filters: PropTypes.arrayOf(PropTypes.shape({
      publishingFilterId: PropTypes.number,
      name: PropTypes.string,
      filterType: PropTypes.string,
      filterText: PropTypes.string,
      enabled: PropTypes.bool
    })),
    postProcesses: PropTypes.arrayOf(PropTypes.shape({
      publishingPostProcessId: PropTypes.number,
      name: PropTypes.string,
      type: PropTypes.string,
      description: PropTypes.string,
      processParams: PropTypes.any,
      enabled: PropTypes.bool,
      ordinal: PropTypes.number
    }))
  })
}

export default PublishingPolicyItem
