import React from 'react'
import PropTypes from 'prop-types'
import { Typography } from '@material-ui/core'

function TitleField ({ field }) {
  return (
    <Typography variant='h3' style={{ fontStyle: 'italic' }}>{field.value}</Typography>
  )
}

TitleField.propTypes = {
  field: PropTypes.shape({
    value: PropTypes.any
  })
}

export default TitleField
