import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import dayjs from 'dayjs'
import Section from './shared/Section'
import MiniTable from './MiniTable'

const useStyles = makeStyles((theme) => ({
  excluded: {
    color: theme.palette.gray.darker,
    borderTop: `1px solid ${theme.palette.gray.darker}`,
    marginTop: theme.layout.margin.medium
  }
}))

const accountKeyAccessor = acct => acct?.accountId

const sortByCloseDate = (a, b) => {
  if (!!a.closeDate && !!b.closeDate) {
    return dayjs(a.closeDate).isSameOrBefore(b.closeDate) ? -1 : 1
  }
  if (a.closeDate) return 1
  if (b.closeDate) return -1
}

function AccountSummaryList ({ accounts, selectedDetail, onSelectDetail, columns }) {
  const classes = useStyles()
  const selected = useMemo(() => {
    if (selectedDetail?.type !== 'account') return null
    return (accounts || []).find(x => selectedDetail?.detail?.accountId === x.accountId) ?? null
  }, [accounts, selectedDetail])

  const [includedInBilling, excludedFromBilling] = useMemo(() => {
    const included = (accounts || []).filter(x => !x.excludeFromBilling).sort(sortByCloseDate)
    const excluded = (accounts || []).filter(x => !!x.excludeFromBilling).sort(sortByCloseDate)

    return [included, excluded]
  }, [accounts])

  if (!accounts?.length) {
    return null
  }

  return (
    <Section>
      <div>
        <header>Accounts</header>
        <MiniTable
          data={includedInBilling}
          columns={columns}
          keyAccessor={accountKeyAccessor}
          selected={selected}
          onSelect={(acct) => onSelectDetail({ type: 'account', detail: acct })}
        />
      </div>
      {excludedFromBilling?.length ? (
        <div className={classes.excluded}>
          <header>Excluded from Billing</header>
          <MiniTable
            lowlighted
            data={excludedFromBilling}
            columns={columns}
            keyAccessor={accountKeyAccessor}
            selected={selected}
            onSelect={(acct) => onSelectDetail({ type: 'account', detail: acct })}
          />
        </div>
      ) : null}
    </Section>
  )
}

AccountSummaryList.propTypes = {
  accounts: PropTypes.array,
  selectedDetail: PropTypes.any,
  onSelectDetail: PropTypes.func,
  columns: PropTypes.array
}

export default AccountSummaryList
