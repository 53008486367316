import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import AddIcon from '@material-ui/icons/Add'
import RoundedButton from '../../atoms/RoundedButton'
import { usePolicy } from '../../../hooks/usePolicy'
import FadeIn from '../../molecules/Transitions/FadeIn'
import { useComments } from './CommentContext'

const useStyles = makeStyles((theme) => ({
  addCommentSection: {
    backgroundColor: theme.palette.white,
    position: 'sticky',
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    padding: '24px'
  }
}))

function AddComment ({ visible, newThread }) {
  const classes = useStyles()
  const enabled = usePolicy('comments_add')
  const { setNewThread, addComment, editing } = useComments()
  const handleAdd = useCallback((e) => {
    return newThread ? setNewThread(e) : addComment(e)
  }, [addComment, newThread, setNewThread])

  if (enabled && !editing && visible) {
    return (
      <FadeIn className={classes.addCommentSection}>
        <RoundedButton
          startIcon={<AddIcon />}
          onClick={handleAdd}
          primary
        >
          Add a Comment
        </RoundedButton>
      </FadeIn>
    )
  }

  return null
}

AddComment.propTypes = {
  visible: PropTypes.bool,
  newThread: PropTypes.bool
}

AddComment.defaultProps = {
  visible: true,
  makeThread: false
}

export default AddComment
