import React from 'react'
import { Link } from 'react-router-dom'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import Avatar from '../../../atoms/Avatar'
import { ADMIN_ROUTES } from '../../../../constants'

dayjs.extend(utc)

export const USERS_PATH = ADMIN_ROUTES.ADVISORS

export const cellTemplates = {
  /* eslint-disable-next-line jsx-a11y/anchor-is-valid */
  drilldownLink: (accessor) => ({ row }) => (
    <Link
      onClick={(event) => event.stopPropagation()}
      to={`${USERS_PATH}/${row.original.externalUserId}`}
    >
      {accessor(row.original)}
    </Link>
  ),
  roleDrilldownLink: (accessor) => ({ row }) => (
    <Link
      onClick={(event) => event.stopPropagation()}
      to={`${ADMIN_ROUTES.ROLES}/${row.original.roleId}`}
    >
      {accessor(row.original)}
    </Link>
  ),
  withAvatar: ({ accessor, classes }) => ({ row }) => (
    <div className={classes.withAvatar}>
      <Avatar
        src={row.original.avatarMediaId}
        avatarLetters={`${row.original.firstName} ${row.original.lastName}`}
        customSize={30}
        customClassName={classes.avatar}
      />
      <span style={{ marginLeft: '10px' }}>{accessor(row.original)}</span>
    </div>
  )
}

export const emailAccesor = (row) => row.email
const nameAccessor = (row) => row.name

export const defaultRolesColumnConfig = {
  columns: [
    { Header: 'Role Name', accessor: nameAccessor, id: 'name', Cell: cellTemplates.roleDrilldownLink(nameAccessor) },
    { Header: 'Description', accessor: 'description', id: 'description' },
    { Header: 'No. of Users', accessor: 'assignedUserCount', id: 'assignedUserCount', disableSortBy: true }
  ],
  defaultSort: [
    { id: 'name', desc: false }
  ]
}
