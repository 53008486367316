import React from 'react'
import PropTypes from 'prop-types'
import numeral from 'numeral'
import { Box, IconButton, useTheme } from '@material-ui/core'
import Icon from '../../../../../../atoms/Icon'
import Text from '../../../../../../atoms/Text'
import Tooltip from '../../../../../../atoms/Tooltip'
import { useCheckPolicy } from '../../../../../../../hooks'
import { BILLING } from '../../../../../../../policies/admin'

const FeeAdjustmentCell = ({
  value,
  row,
  format,
  onSetShowFeeAdjustmentModal,
  onTriggerDelete
}) => {
  const theme = useTheme()
  const canEditDetails = useCheckPolicy(BILLING.editDetails)

  if (row.depth === 0) {
    if (row.original?.statuses?.includes('hasFeeAdjustments')) {
      return (
        <Box display='flex' gridGap='16px' justifyContent='center' alignItems='center'>
          <Icon name='check' color={theme.palette.success.main} customSize='20px' />
        </Box>
      )
    }
    return null
  }

  if (!value && canEditDetails) {
    return (
      <Box display='flex' gridGap='16px' justifyContent='center' alignItems='center'>
        <IconButton size='sm' onClick={() => onSetShowFeeAdjustmentModal(row.original)}>
          <Icon name='add' />
        </IconButton>
      </Box>
    )
  }
  return (
    <Box display='flex' gridGap='8px' justifyContent='center' alignItems='center'>
      {canEditDetails && (
        <IconButton size='sm' onClick={() => onSetShowFeeAdjustmentModal(row.original)}>
          <Icon name='edit' />
        </IconButton>
      )}
      <Tooltip key={row.original.accountId} title={row.original?.adjustmentReason}>
        <Box display='flex' gridGap='4px' justifyContent='center' alignItems='center'>
          <Text text={numeral(value).format(format ?? '$0,000[.]00')} variant='body2' />
          <Icon name='info' customSize={14} color={theme.palette.grey.A200} />
        </Box>
      </Tooltip>
      {canEditDetails && (
        <IconButton size='sm' onClick={() => onTriggerDelete(row.original)}>
          <Icon name='delete' />
        </IconButton>
      )}
    </Box>
  )
}

FeeAdjustmentCell.propTypes = {
  value: PropTypes.any,
  row: PropTypes.object,
  onSetShowFeeAdjustmentModal: PropTypes.func,
  format: PropTypes.string,
  onTriggerDelete: PropTypes.func
}

export default FeeAdjustmentCell
