import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import { makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import InputInlineEdit from '../molecules/InputInlineEdit'
import { editClient } from '../../service'

const useStyles = makeStyles(() => ({
  input: ({ extraStyles }) => ({
    fontSize: '0.75rem',
    ...extraStyles
  }),
  textFieldClassName: {
    marginLeft: '0 !important',
    marginRight: '0 !important'
  }
}))

const FamilyValuesInput = ({
  extraStyles,
  onSave,
  onCancel,
  onChange,
  clientId,
  className,
  defaultValue,
  initInEditMode
}) => {
  const classes = useStyles({ extraStyles })
  const [familyValue, setFamilyValue] = useState(defaultValue)

  const editFamilyValues = useCallback(
    async (familyValues) => {
      if (!clientId) return
      const body = {
        client: { description: familyValues }
      }
      await editClient(clientId, body)
      onSave && onSave(familyValues)
    },
    [clientId, onSave]
  )

  const onChangeHandler = useCallback(
    (e) => {
      const value = e.target.value
      setFamilyValue(value)
      onChange && onChange(value)
    },
    [onChange]
  )

  const onCancelHandler = useCallback(
    (val) => {
      setFamilyValue(val)
      onCancel && onCancel(val)
    },
    [onCancel]
  )

  return (
    <InputInlineEdit
      width='100%'
      multiline
      className={clsx(classes.input, { [className]: !!className })}
      value={familyValue}
      initialValue={familyValue}
      showEditIcon={!initInEditMode}
      initInEditMode={initInEditMode}
      onChangeHandler={onChangeHandler}
      onIconClickHandler={editFamilyValues}
      onCancelIconClickHandler={onCancelHandler}
      textFieldClassName={classes.textFieldClassName}
    />
  )
}

FamilyValuesInput.propTypes = {
  extraStyles: PropTypes.any,
  clientId: PropTypes.number.isRequired,
  onSave: PropTypes.func,
  onChange: PropTypes.func,
  onCancel: PropTypes.func,
  className: PropTypes.string,
  defaultValue: PropTypes.string,
  initInEditMode: PropTypes.bool
}

FamilyValuesInput.defaultProps = {
  extraStyles: {},
  onSave: noop,
  onChange: noop,
  onCancel: noop,
  className: '',
  defaultValue: '',
  initInEditMode: false
}

export default FamilyValuesInput
