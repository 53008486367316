import React, { useCallback, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import isArray from 'lodash/isArray'
import isEmpty from 'lodash/isEmpty'
import { Tooltip } from '@material-ui/core'
import Select from '../../../../molecules/Select'
import { DATE_RANGE_CUSTOM_OPTION_KEYS, datePresetOptions, getCustomDateRangeOptionSelected, parseCalendarYearDateRangeRawValue, parseCustomDateRangeRawValue, useRelativeDateRanges } from '../../../../molecules/RelativeDateSelect'
import { useGroupingContext } from '../../../GroupingProvider/GroupingContext'
import { modifyByIndex } from '../../../../../utils'
import { useAppContext } from '../../../../../redux/slices/appContext'
import { findFirstDateRangeColumnOccurrenceIndex } from '../../usePerformanceColumns'
import { BUTTON_SIZES } from '../../../../../constants'
import Icon from '../../../../atoms/Icon'
import { localStorageHelper } from '../../../../../utils/localStorageHelper'
import { getGroupingStorageConfigKey } from './helpers'

dayjs.extend(utc)

const DATE_RANGE_FORMAT = 'MM/DD/YYYY'

const getCustomDateRangeDisplayLabel = (value, selectedCustomOption) => {
  let customOptionLabel = selectedCustomOption.label
  if (selectedCustomOption.value.startsWith(DATE_RANGE_CUSTOM_OPTION_KEYS.CUSTOM)) {
    const { startDate, endDate } = parseCustomDateRangeRawValue(value)
    customOptionLabel = `${dayjs
      .utc(startDate)
      .format(DATE_RANGE_FORMAT)} - ${dayjs
      .utc(endDate)
      .format(DATE_RANGE_FORMAT)}`
  }
  if (selectedCustomOption.value === DATE_RANGE_CUSTOM_OPTION_KEYS.CALENDAR_YEAR) {
    const { value: calendarYear } = parseCalendarYearDateRangeRawValue(value)
    customOptionLabel = calendarYear
  }
  return customOptionLabel
}

const DateRangeSelectorCell = ({ column, columns }) => {
  const { availableDates } = useAppContext()
  const {
    selectedGrouping,
    configurationKey,
    groupingDateRanges,
    setGroupingDateRanges,
    groupingDateRangeOptions,
    setTempGroupingDateRanges,
    setIsGroupingDateRangePicker,
    shareLocalStorageAcrossGroupings
  } = useGroupingContext()

  const configKey = useMemo(() => {
    const groupingKey = shareLocalStorageAcrossGroupings
      ? undefined
      : selectedGrouping?.key

    return getGroupingStorageConfigKey(configurationKey, groupingKey)
  }, [configurationKey, selectedGrouping, shareLocalStorageAcrossGroupings])

  useEffect(() => {
    if (configKey) {
      let config = localStorageHelper.load(configKey) || []
      if (!isEmpty(config)) {
        if (!isArray(config)) {
          throw new Error(`[${configKey}] Storage structure not supported`)
        }
        if (!config.every((item, index) => groupingDateRanges[index] === item)) {
          localStorageHelper.store(configKey, groupingDateRanges)
        }
      } else {
        config = groupingDateRanges
        localStorageHelper.store(configKey, config)
      }
    }
  }, [configKey, groupingDateRanges])

  const { options: performanceDateRangeOptions } = useRelativeDateRanges(
    groupingDateRanges,
    availableDates,
    groupingDateRangeOptions
  )

  const groupingDateRangeIndex = useMemo(() => {
    const columnIndex = column.index
    if (column?.index === undefined) {
      throw Error('Column index property is needed')
    }
    const startDateRangeColumnIndex = findFirstDateRangeColumnOccurrenceIndex(
      columns,
      datePresetOptions
    )
    const dateRangeRelativeIndex = columnIndex - startDateRangeColumnIndex
    return dateRangeRelativeIndex
  }, [column, columns])

  const onChangeCustomDateRange = useCallback(
    (selectedCustomOption, index) => {
      setTempGroupingDateRanges((prevState) => {
        const dateRangeValue = prevState[index]
        const customOption = getCustomDateRangeOptionSelected(dateRangeValue)
        if (customOption && selectedCustomOption.value === customOption.value) {
          return prevState
        }
        return modifyByIndex(index, prevState, [selectedCustomOption.value])
      })
      setIsGroupingDateRangePicker.on()
    },
    [setTempGroupingDateRanges, setIsGroupingDateRangePicker]
  )

  const onChangeDateRange = useCallback(
    (value, index) => {
      const selectedCustomOption = getCustomDateRangeOptionSelected(value)
      if (selectedCustomOption) {
        onChangeCustomDateRange(selectedCustomOption, index)
      } else {
        setGroupingDateRanges((prevState) => {
          setTempGroupingDateRanges((prevTempState) =>
            modifyByIndex(index, prevTempState, [value])
          )
          return modifyByIndex(index, prevState, [value])
        })
      }
    },
    [
      setGroupingDateRanges,
      onChangeCustomDateRange,
      setTempGroupingDateRanges
    ]
  )

  const selectedOption = useMemo(() => {
    const groupingDateRangeValue = groupingDateRanges[groupingDateRangeIndex]

    const selectedCustomOption = getCustomDateRangeOptionSelected(groupingDateRangeValue)
    if (selectedCustomOption) {
      const customOptionLabel = getCustomDateRangeDisplayLabel(groupingDateRangeValue, selectedCustomOption)
      return {
        ...selectedCustomOption,
        value: groupingDateRangeValue,
        displayLabel: customOptionLabel
      }
    }
    return { value: groupingDateRangeValue }
  }, [groupingDateRanges, groupingDateRangeIndex])

  const dateRangeOptions = useMemo(() => {
    const options = performanceDateRangeOptions
    const disabledDateRanges = groupingDateRanges
      .filter((_, index) => index !== groupingDateRangeIndex)
      .map(
        (dateRangeKey) =>
          options.find(({ value }) => value === dateRangeKey)?.value
      )
    return options.map((dateRangeOption) => {
      const disabled = disabledDateRanges.includes(dateRangeOption.value)
      const customOption = getCustomDateRangeOptionSelected(
        dateRangeOption.value
      )
      const selectedValue = selectedOption.value
      const optionValue = dateRangeOption.value

      const optionOverride =
        (customOption && selectedValue?.startsWith(optionValue)) ||
        (!customOption && selectedValue === optionValue)
          ? selectedOption
          : {}

      return {
        ...dateRangeOption,
        ...optionOverride,
        disabled
      }
    })
  }, [
    selectedOption,
    groupingDateRanges,
    groupingDateRangeIndex,
    performanceDateRangeOptions
  ])

  const resolvedDateRange = useMemo(() => {
    const perfRanges = column.resolvedDates?.performanceRanges
    if (!perfRanges) return ''

    const dateRange = (selectedOption.value in perfRanges) ? perfRanges[selectedOption.value] : null
    if (!dateRange) return ''

    return `${dayjs(dateRange.startDate).format(DATE_RANGE_FORMAT)} to ${dayjs(dateRange.endDate).format(DATE_RANGE_FORMAT)}`
  }, [selectedOption.value, column.resolvedDates])

  const style = useMemo(() => {
    return {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: column.alignRight ? 'flex-end' : 'center',
      alignItems: 'center',
      gap: '10px'
    }
  }, [column])

  return (
    <div style={style}>
      <Select
        value={selectedOption.value}
        options={dateRangeOptions}
        onChange={(value) => onChangeDateRange(value, groupingDateRangeIndex)}
        size={BUTTON_SIZES.small}
        showCheckMarOnSelectedItems
      />
      {resolvedDateRange ? (
        <Tooltip
          enterDelay={0}
          title={resolvedDateRange}
          arrow
          placement='top'
          classes={{
            tooltip: 'big_tooltip'
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Icon name='info' />
          </div>
        </Tooltip>
      ) : null}
    </div>
  )
}

DateRangeSelectorCell.propTypes = {
  column: PropTypes.object,
  columns: PropTypes.array
}

export default DateRangeSelectorCell
