import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { loadable } from '../../utils/loadable'
import { useCustomDatasetContextValue, CustomDatasetContext, useCustomDataset } from './index'

const useJoinDataset = (left, right, on, leftDataSetIndex, rightDataSetIndex) => {
  const [result, setResult] = useState(loadable.loading())
  useEffect(() => {
    if (left.isError || right.isError) {
      setResult(loadable.error(left.error || right.error))
      return
    }
    if (left.isLoading || right.isLoading) {
      setResult(loadable.loading())
      return
    }
    if (!left.isDone && !right.isDone) {
      return
    }

    const leftValue = left.value
    const rightValue = right.value

    // project sub-datasets into pairs
    let pairs = leftValue.map((l, lIndex) => {
      if (lIndex < rightValue.length) {
        return [l, rightValue[lIndex]]
      }
      return null
    }).filter(x => !!x)

    // match specific data to specified rightValue data
    if (leftDataSetIndex > 0 || rightDataSetIndex > 0) {
      pairs = [[leftValue[leftDataSetIndex], rightValue[rightDataSetIndex]]]
    }

    // match rows for pairs
    const joined = pairs.map(([l, r]) => {
      return l.map(lRow => {
        // eslint-disable-next-line eqeqeq
        const rRow = r.find(x => x[on] == lRow[on]) ?? { matched: false }
        return {
          ...rRow,
          ...lRow
        }
      })
    })

    console.log('joined', joined)
    setResult(loadable.done(joined))
  }, [left, right, on, setResult, leftDataSetIndex, rightDataSetIndex])

  return useMemo(() => {
    return {
      isLoading: loadable.isLoading(result),
      isError: loadable.isError(result),
      isDone: loadable.isDone(result),
      error: loadable.getError(result),
      value: loadable.getValue(result)
    }
  }, [result])
}

const JoinDataset = ({ on, name, type, parameters, children, leftDataSetIndex, rightDataSetIndex }) => {
  const leftDataset = useCustomDataset()
  const rightDataset = useCustomDatasetContextValue({ name, parameters, type })
  const joined = useJoinDataset(leftDataset, rightDataset, on, leftDataSetIndex, rightDataSetIndex)
  const isRenderFunction = useMemo(() => typeof children === 'function', [children])

  return (
    <CustomDatasetContext.Provider value={joined}>
      {isRenderFunction ? children(joined) : children}
    </CustomDatasetContext.Provider>
  )
}

JoinDataset.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,

  /** The name of the custom data set */
  name: PropTypes.string.isRequired,
  parameters: PropTypes.object,
  type: PropTypes.string,
  on: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]).isRequired,
  leftDataSetIndex: PropTypes.number,
  rightDataSetIndex: PropTypes.number
}

JoinDataset.defaultProps = {
  leftDataSetIndex: 0,
  rightDataSetIndex: 0
}

export default JoinDataset
