import React, { useCallback, useLayoutEffect, useMemo, useState } from 'react'
import { makeStyles, Button, IconButton } from '@material-ui/core'
import { isEmpty, isString } from 'lodash'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { useGroupingContext } from '../GroupingContext'
import Select from '../../../../molecules/Select'
import Icon from '../../../../atoms/Icon'
import { ICON_NAMES } from '../../../../../constants'
import Tooltip from '../../../../atoms/Tooltip'
import { modifyByIndex, removeByIndex } from '../../../../../utils'
import { localStorageHelper } from '../../../../../utils/localStorageHelper'
import DragAndDropListItem from '../DragAndDropListItem'
import CustomizeColumnsDialog from './CustomizeColumnsDialog'
import { DIALOG_STORAGE_KEYS as STR_KEYS, mapColumnIds, mapVisibleColumns } from './helpers'

const useStyles = makeStyles(() => ({
  squaredButton: {
    marginTop: '1rem',
    width: '100%',
    borderRadius: '0.25rem',
    border: '2px solid #EEF0F8',
    background: 'linear-gradient(0deg, #F7F8F9 0%, #F7F8F9 100%), #EEF0F8',
    '& span': {
      color: '#212945',
      fontSize: '18px',
      textTransform: 'none',
      fontWeight: 600
    },
    '&:hover': {
      border: '2px solid #212945',
      background: '#EEF0F8'
    },
    '&:disabled': {
      cursor: 'not-allowed',
      border: '2px solid #EEF0F8',
      pointerEvents: 'unset',
      background: 'linear-gradient(0deg, #F7F8F9 0%, #F7F8F9 100%), #EEF0F8'
    }
  },
  select: {
    padding: '0.875rem 1rem'
  },
  deleteButton: {
    marginLeft: '0.5rem'
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    gap: '1rem'
  }
}))

const CustomizeColumnsDragOrderToggler = () => {
  const classes = useStyles()
  const {
    onApplyClick,
    options: groupingConfigOptions,
    configurationKey: _configurationKey,
    performanceTableProps,
    isGroupingDateRangePickerOpen,
    setIsGroupingDateRangePicker: setDateRangePicker
  } = useGroupingContext()

  const configurationKey = _configurationKey
    ? `${STR_KEYS.COLUMNS_DND_ORDER_TOGGLER}_${_configurationKey}`
    : undefined

  const [columnItems, setColumnItems] = useState([])

  const options = useMemo(() => {
    return (performanceTableProps?.allColumns || []).map((column) => {
      const label =
        (isString(column.Header) && column.Header) ||
        (isString(column.header) && column.header)
      return {
        label: label.trim() ? label : 'Blank space',
        value: column.id
      }
    })
  }, [performanceTableProps?.allColumns])

  const getOption = useCallback((value) => {
    return options.find(({ value: _value }) => _value === value)
  }, [options])

  const availableOptions = useMemo(() => {
    return options.filter(({ value }) => !columnItems.includes(value))
  }, [options, columnItems])

  const initColumnItems = useCallback(() => {
    let configuration = {}
    if (
      configurationKey &&
      performanceTableProps &&
      !performanceTableProps?.isLoading
    ) {
      configuration = localStorageHelper.load(configurationKey) || {}

      const storedColumnIds = [
        ...(configuration?.hiddenColumns || []),
        ...(configuration?.columnOrder || [])
      ]
      const allColumns = performanceTableProps?.allColumns || []
      const columnIds = mapColumnIds(allColumns)
      // we make sure that what's stored matches our current configuration
      if (
        !columnIds.every((id) => storedColumnIds.indexOf(id) !== -1) ||
        columnIds.length !== storedColumnIds.length
      ) {
        configuration = {}
        localStorageHelper.removeItems([configurationKey])
      }
      if (!isEmpty(configuration)) {
        performanceTableProps.setHiddenColumns(configuration?.hiddenColumns)
        performanceTableProps.setColumnOrder(configuration?.columnOrder)
      }
    }
    setColumnItems((prevState) => {
      const allColumns = mapVisibleColumns(performanceTableProps?.allColumns || [])
      const currColumnsIncluded = prevState.every((col) => allColumns.includes(col))
      if (!isEmpty(prevState) && currColumnsIncluded) return prevState
      if (!isEmpty(configuration?.columnOrder)) { return configuration.columnOrder }
      return allColumns
    })
  }, [configurationKey, performanceTableProps])

  useLayoutEffect(() => { initColumnItems() }, [initColumnItems])

  const onApply = useCallback(() => {
    const hiddenColumnIds = performanceTableProps?.allColumns
      .filter(({ id }) => !columnItems.includes(id))
      .map(({ id }) => id)

    performanceTableProps.setHiddenColumns(hiddenColumnIds)

    const newColumnItems = columnItems.filter(
      (item) => !item.startsWith('temp')
    )
    performanceTableProps.setColumnOrder(newColumnItems)
    setColumnItems(newColumnItems)

    // TODO: stores user's selection if a key is provided, maybe in the future we'll change this
    if (configurationKey) {
      localStorageHelper.store(configurationKey, {
        hiddenColumns: hiddenColumnIds,
        columnOrder: newColumnItems
      })
    }
    setDateRangePicker.off()
    onApplyClick({
      hiddenColumns: hiddenColumnIds,
      columns: newColumnItems
    })
  }, [
    onApplyClick,
    columnItems,
    configurationKey,
    setDateRangePicker,
    performanceTableProps
  ])

  const onReset = useCallback(() => {
    setDateRangePicker.off()
    setColumnItems([])
    initColumnItems()
  }, [setDateRangePicker, initColumnItems])

  const onMoveItem = useCallback((dragIndex, hoverIndex) => {
    setColumnItems((prevItems) => {
      const newItems = [...prevItems]
      const dragItem = newItems[dragIndex]
      newItems.splice(dragIndex, 1)
      newItems.splice(hoverIndex, 0, dragItem)
      return newItems
    })
  }, [])

  const onChangeOption = useCallback(
    (index) => (value) => {
      setColumnItems((prevState) => {
        const selectedColumnIndex = prevState.indexOf(value)
        if (selectedColumnIndex !== -1) {
          const modifiedColumns = modifyByIndex(
            selectedColumnIndex,
            prevState,
            `temp-${selectedColumnIndex}`
          )
          return modifyByIndex(index, modifiedColumns, value)
        }
        return modifyByIndex(index, prevState, value)
      })
    },
    []
  )

  const onAddColumn = useCallback(() => {
    setColumnItems((prevState) => {
      return [...prevState, `temp-${columnItems.length}`]
    })
  }, [columnItems])

  const onAddAllColumns = useCallback(() => {
    setColumnItems((prevState) => {
      const availableValues = availableOptions.map(({ value }) => value)
      return [...prevState, ...availableValues]
    })
  }, [availableOptions])

  const onRemoveColumn = useCallback(
    (index) => () => {
      setColumnItems((prevState) => {
        return removeByIndex(index, prevState)
      })
    },
    []
  )

  const disableAddColumnButton = columnItems.length >= options.length
  const disableAddAllColumnsButton = !availableOptions.length
  const disableRemoveColumnButton = columnItems.length === 1

  return (
    <CustomizeColumnsDialog
      isOpen={isGroupingDateRangePickerOpen}
      toggler={setDateRangePicker}
      maxWidth='xs'
      onCancel={onReset}
      onConfirm={onApply}
    >
      <div>
        <DndProvider backend={HTML5Backend}>
          {columnItems.map((columnId, index) => {
            const option = getOption(columnId)
            return (
              <DragAndDropListItem
                id={columnId}
                key={columnId}
                index={index}
                value={columnId}
                onMoveItem={onMoveItem}
              >
                <Select
                  variant='outlined'
                  fullWidth
                  placeholder='Select additional column'
                  value={option}
                  options={availableOptions}
                  onChange={onChangeOption(index)}
                  showCheckMarOnSelectedItems
                  className={classes.select}
                />
                {!disableRemoveColumnButton && (
                  <Tooltip title='Remove Column'>
                    <IconButton
                      className={classes.deleteButton}
                      onClick={onRemoveColumn(index)}
                    >
                      <Icon name={ICON_NAMES.circleMinus} />
                    </IconButton>
                  </Tooltip>
                )}
              </DragAndDropListItem>
            )
          })}
        </DndProvider>
        <div className={classes.actions}>
          <Button
            onClick={onAddColumn}
            className={classes.squaredButton}
            disabled={disableAddColumnButton}
          >
            Add Column
          </Button>
          {!groupingConfigOptions.hideAddAllColumnsButton && (
            <Button
              onClick={onAddAllColumns}
              className={classes.squaredButton}
              disabled={disableAddAllColumnsButton}
            >
              Add All Columns
            </Button>
          )}
        </div>
      </div>
    </CustomizeColumnsDialog>
  )
}

export default CustomizeColumnsDragOrderToggler
