import { ClickAwayListener, IconButton, InputAdornment, InputBase } from '@material-ui/core'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import Text from '../../../atoms/Text'
import Icon from '../../../atoms/Icon'
import { ICON_NAMES } from '../../../../constants'
import { numeralByCase } from '../../../../utils'
import StyledSlider from './StyledSlider'
import QuickEditSelect from './QuickEditSelect'

const directionOptions = [{
  label: 'CONTRIBUTION',
  value: 'CONTRIBUTION'
}, {
  label: 'WITHDRAWAL',
  value: 'WITHDRAWAL'
}]

const QuickEditContribution = ({ classes, initialValues, range, onChange }) => {
  const [isEditable, setIsEditable] = useState(false)
  const [values, setValues] = useState(initialValues)

  useEffect(() => {
    setValues(initialValues)
  }, [initialValues])

  const marks = useMemo(() => [
    {
      label: numeralByCase(range.min, '0a'),
      value: range.min
    },
    {
      label: numeralByCase(Math.abs(values.flowAmount), '0a'),
      value: Math.abs(values.flowAmount)
    },
    {
      label: numeralByCase(Math.abs(range.max), '0a'),
      value: Math.abs(range.max)
    }
  ], [range, values.flowAmount])

  const onToggleEditable = useCallback(() => {
    const newVal = !isEditable
    setIsEditable(newVal)
  }, [isEditable])

  const cancelEdit = () => {
    setValues(initialValues)
    onToggleEditable()
  }

  const saveEdit = () => {
    if (values.flowAmount !== initialValues.flowAmount || values.direction !== initialValues.direction) {
      onChange(values)
    }
    onToggleEditable()
  }

  const onChangeFlowAmount = (flowAmount) => {
    setValues({ ...values, flowAmount })
    onChange({ flowAmount })
  }
  const onChangeDirection = (direction) => {
    setValues({ ...values, direction })
    onChange({ direction })
  }

  const displayAmount = useMemo(() => {
    let endingAdornment = ''
    if (values.frequency === 'ANNUALLY') {
      endingAdornment = '/yr'
    }
    return numeralByCase(Math.abs(values.flowAmount), '$0,000[.]00a') + endingAdornment
  }, [values])

  return (
    <>
      <QuickEditSelect
        onChange={(e) => onChangeDirection(e.target.value)}
        options={directionOptions}
        value={values.direction}
      />

      <div>
        <Text text='Flow Amount' customFontSize='14px' />
        {isEditable ? (
          <ClickAwayListener onClickAway={saveEdit}>
            <InputBase
              classes={{ input: classes.input }}
              defaultValue={values.flowAmount}
              value={values.flowAmount}
              startAdornment={
                <InputAdornment position='start'>
                  <div className={classes.adornment}>$</div>
                </InputAdornment>
              }
              endAdornment={
                <>
                  <IconButton
                    onClick={saveEdit}
                    color='inherit'
                  >
                    <Icon name={ICON_NAMES.check} customSize='1.2rem' />
                  </IconButton>
                  <IconButton
                    onClick={cancelEdit}
                    color='inherit'
                  >
                    <Icon name={ICON_NAMES.close} customSize='1.2rem' />
                  </IconButton>
                </>
              }
              onChange={(e) => setValues({ ...values, flowAmount: e.target.value })}
              onKeyUp={e => e.key === 'Enter' && saveEdit()}
              inputProps={{
                step: 10000,
                min: 0,
                type: 'number',
                'aria-labelledby': 'input-slider'
              }}
            />
          </ClickAwayListener>
        ) : (
          <div onClick={(e) => onToggleEditable()}>
            <Text
              text={displayAmount}
              className={classes.display}
            />
          </div>
        )}
      </div>
      <div style={{ width: '100%', marginTop: '20px' }}>
        <StyledSlider
          defaultValue={parseInt(values.flowAmount)}
          value={parseInt(values.flowAmount)}
          aria-labelledby='contribution-slider'
          valueLabelDisplay='auto'
          onChange={(e, valuePass) => onChangeFlowAmount(valuePass)}
          step={10000}
          marks={marks}
          min={range.min}
          max={range.max}
        />
      </div>
    </>
  )
}

QuickEditContribution.propTypes = {
  classes: PropTypes.object,
  initialValues: PropTypes.objectOf(PropTypes.shape({
    flowAmount: PropTypes.number,
    direction: PropTypes.string
  })),
  range: PropTypes.objectOf(PropTypes.shape({
    min: PropTypes.number,
    max: PropTypes.number
  })),
  onChange: PropTypes.func
}

export default QuickEditContribution
