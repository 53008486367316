import { Controller } from 'react-hook-form'
import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core'
import React from 'react'
import PropTypes from 'prop-types'
import Card from '../../../../../../molecules/Card'
import SydLabel, { hookFormErrorAdapter } from '../../../../../../commonDesign/SydLabel'
import SydInput from '../../../../../../commonDesign/SydInput'
import { useDialogStyles } from '../../common'

const feeCalcOptions = [
  { value: '1', label: 'Default' },
  { value: '10', label: 'Include in AUM' },
  { value: '11', label: 'Additional Fee' },
  { value: '5', label: 'Partial Amount' }
]

const FeeCalcTypeCard = ({ form }) => {
  const classes = useDialogStyles()
  const feeCalcTypeId = form.watch('feeCalcTypeId')

  return (
    <Card style={{ marginTop: '16px' }}>
      <Controller
        name='feeCalcTypeId'
        control={form.control}
        render={(f => (
          <SydLabel label='Select Fee Calculation Type'>
            <RadioGroup {...f.field}>
              {feeCalcOptions.map((opt, idx) => (
                <FormControlLabel
                  key={idx}
                  value={opt.value}
                  control={<Radio color='primary' size='small' classes={{ root: classes.feeCalcTypeRadio }} />}
                  label={opt.label}
                />
              ))}
            </RadioGroup>
          </SydLabel>
        ))}
      />
      {feeCalcTypeId === '5' && (
        <Controller
          name='overrideAmount'
          control={form.control}
          rules={{ required: 'Override Amount is required' }}
          render={(f => (
            <SydLabel
              label='Override Amount'
              required
              error={hookFormErrorAdapter(form, f.fieldState)}
              description='Amount to lower the billing balance by'
            >
              <SydInput {...f.field} size='sm' />
            </SydLabel>
          ))}
        />
      )}
    </Card>
  )
}

FeeCalcTypeCard.propTypes = {
  form: PropTypes.object.isRequired
}

export default FeeCalcTypeCard
