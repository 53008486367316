import React from 'react'
import PropTypes from 'prop-types'
import { Box, makeStyles } from '@material-ui/core'
import { isNumber } from 'lodash'
import Text from '../../../../../atoms/Text'
import NumberFormat from '../../../../../atoms/NumberFormat'
import ClientCardBalanceIndicator from '../ClientCardBalanceIndicator'
import { numeralByCase } from '../../../../../../utils'

const useStyles = makeStyles(() => ({
  container: {
    transition: 'all 0.2s linear 0s',
    display: 'flex',
    flexDirection: 'row'
  },
  balance: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '22px',
    lineHeight: '26px',
    color: '#212945'
  }
}))

const ClientBalance = ({ endingValue, balanceChangeStatus }) => {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <Text
        className={classes.balance}
        text={
          isNumber(endingValue) ? (
            <NumberFormat
              title={numeralByCase(endingValue || 0)}
              number={endingValue || 0}
              format='$0,0a'
            />
          ) : ('--')
        }
      />
      <Box mr='0.5rem' />
      <ClientCardBalanceIndicator balanceChangeStatus={balanceChangeStatus} />
    </div>
  )
}

ClientBalance.propTypes = {
  endingValue: PropTypes.number,
  balanceChangeStatus: PropTypes.string
}

export default ClientBalance
