import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { ClickAwayListener, IconButton, InputBase } from '@material-ui/core'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import Icon from '../../../atoms/Icon'
import { ICON_NAMES } from '../../../../constants'
import Text from '../../../atoms/Text'
import StyledSlider from './StyledSlider'

const useStyles = makeStyles(() => {
  return {
    container: {
      display: 'flex',
      alignItems: 'center',
      width: '100%'
    },
    inputBlock: {
      flexBasis: '40%'
    },
    actions: {
      flexBasis: '20%'
    }
  }
})

const QuickEditDateSingle = ({ classes, initialValues, range, onChange, defaultIsEditable }) => {
  const layoutClasses = useStyles()

  const [isEditable, setIsEditable] = useState(defaultIsEditable)
  const [values, setValues] = useState(initialValues)

  useEffect(() => {
    setValues(initialValues)
  }, [initialValues])

  const marks = useMemo(() => ([
    { value: range.min, label: range.min },
    { value: values.startDate, label: values.startDate },
    { value: range.max, label: range.max }
  ]), [range, values])

  const onToggleEditable = useCallback(() => {
    const newVal = !isEditable
    setIsEditable(newVal)
  }, [isEditable])

  const cancelEdit = () => {
    setValues(initialValues)
    onToggleEditable()
  }

  const saveEdit = () => {
    onChange(values)
    onToggleEditable()
  }

  const onChangeSlider = (valuePass) => {
    setValues({ startDate: valuePass, endDate: null })
    onChange({ startDate: valuePass, endDate: null })
  }

  return (
    <>
      {isEditable ? (
        <ClickAwayListener onClickAway={saveEdit}>
          <div className={layoutClasses.container}>
            <div className={layoutClasses.inputBlock}>
              <Text text='Occurs on' customFontSize='14px' />
              <InputBase
                classes={{ input: classes.input }}
                defaultValue={values.startDate}
                value={values.startDate}
                margin='dense'
                onChange={(e) => setValues({ ...values, startDate: e.target.value, endDate: null })}
                onKeyUp={e => e.key === 'Enter' && saveEdit()}
              />
            </div>
            <div className={layoutClasses.actions}>
              <IconButton
                classes={{ root: classes.iconButton }}
                onClick={saveEdit}
                color='inherit'
              >
                <Icon name={ICON_NAMES.check} customSize='1.2rem' />
              </IconButton>
              <IconButton
                onClick={cancelEdit}
                color='inherit'
              >
                <Icon name={ICON_NAMES.close} customSize='1.2rem' />
              </IconButton>
            </div>
          </div>
        </ClickAwayListener>
      ) : (
        <div className={layoutClasses.container}>
          <div className={layoutClasses.inputBlock}>
            <div onClick={onToggleEditable} className={classes.display}>
              <Text text='Occurs on' customFontSize='24px' />
              <Text text={values.startDate} customFontSize='40px' />
            </div>
          </div>
        </div>
      )}
      <div style={{ width: '100%', marginTop: '20px' }}>
        <StyledSlider
          defaultValue={values.startDate}
          value={values.startDate}
          aria-labelledby='daterange-slider'
          valueLabelDisplay='auto'
          valueText={(value) => value}
          onChange={(e, valuePass) => onChangeSlider(valuePass)}
          step={1}
          min={range.min}
          max={range.max}
          marks={marks}
        />
      </div>
    </>
  )
}

QuickEditDateSingle.propTypes = {
  classes: PropTypes.object,
  initialValues: PropTypes.objectOf(PropTypes.shape({
    startDate: PropTypes.string,
    endDate: PropTypes.string
  })),
  range: PropTypes.objectOf(PropTypes.shape({
    min: PropTypes.number,
    max: PropTypes.number
  })),
  onChange: PropTypes.func,
  defaultIsEditable: PropTypes.bool
}

export default QuickEditDateSingle
