import React from 'react'
import PropTypes from 'prop-types'
import { Grid, Divider, makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  divider: {
    height: '2px',
    background: theme.palette.gray.light,
    marginTop: '16px',
    marginBottom: '32px'
  },
  sectionName: {
    fontSize: theme.typography.fontSizes.h5,
    color: theme.palette.primary.main
  },
  section: {
    marginBottom: '30px'
  }
}))

function SectionHeading ({ name, children }) {
  const classes = useStyles()

  return (
    <Grid container spacing={2} className={classes.section}>
      <Grid item xs={12}>
        <div className={classes.sectionName}>{name}</div>
        <Divider className={classes.divider} />
      </Grid>
      <Grid item xs={12}>
        {children}
      </Grid>
    </Grid>
  )
}

SectionHeading.propTypes = {
  name: PropTypes.string,
  children: PropTypes.node
}

export default SectionHeading
