import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { useAppContext } from '../../../../redux/slices/appContext'

const useStyles = makeStyles(() => ({
  unauthorized: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '70vh',
    fontSize: '2rem'
  }
}))

const InternalOnly = ({ children }) => {
  const classes = useStyles()
  const { isSummitUser } = useAppContext()

  if (!isSummitUser) {
    return (
      <article className={classes.unauthorized}>
        <div>Unauthorized</div>
      </article>
    )
  }

  return children
}

InternalOnly.propTypes = {
  children: PropTypes.node
}

export default InternalOnly
