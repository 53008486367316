import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import noop from 'lodash/noop'
import { Box, IconButton, Snackbar, makeStyles } from '@material-ui/core'
import { FormProvider, useForm } from 'react-hook-form'
import { Alert as MuiAlert } from '@material-ui/lab'
import CloseIcon from '@material-ui/icons/Close'
import OperationalTable, {
  useOperationalTable
} from '../../../organisms/OperationalTable'
import { BUTTON_SIZES } from '../../../../constants'
import RoundedButton from '../../../atoms/RoundedButton'
import { useListAbundanceEngineViews } from '../../../../api/abundanceEngine'
import { ContextContainerProvider } from '../../../../abundanceEngine/components/ContextContainer'
import { useBoolean } from '../../../../hooks'
import AbundanceEngineViewForm from '../../../../components/organisms/AbundanceEngineViewForm'
import { createAbundanceEngineView, deleteAbundanceEngineView, editAbundanceEngineView } from '../../../../service'
import { errorAlert, initialAlertState, successAlert } from '../common'
import Alert from '../../../atoms/Alert'
import ManagementDialog from '../managementView/managementDialog'
import AbundanceParser from '../../../../abundanceEngine/AbundanceParser'
import ViewPathCell from './cells/ViewPathCell'
import ViewActionsCell from './cells/ViewActionsCell'
import AbundanceEngineDialog from './AbundanceEngineDialog'
import ViewEnabledFlagCell from './cells/ViewEnabledFlagCell'

const useStyles = makeStyles(() => ({
  container: {
    padding: '1rem',
    height: '100%'
  },
  header: {
    display: 'flex'
  }
}))

const mapFormDefaultValues = (selectedView = {}) => {
  return {
    viewName: '',
    viewId: '',
    viewPath: '',
    viewDescription: '',
    viewVersion: undefined,
    viewContent: '',
    viewIsLive: '0',
    showInNavigation: '0',
    ...selectedView
  }
}

const handleSubmitError = (err, editMode) => {
  let error = null
  const genericError = `Something went wrong trying to ${
    editMode ? 'edit' : 'create'
  } the view.`
  if (err?.code === 409) {
    const errorMessage = err.response?.message
    if (errorMessage.includes('name must be unique')) {
      error = 'You already have a view with this name.'
    } else if (
      errorMessage.includes('You already have a live view for this path')
    ) {
      error = 'You already have a live view for this path.'
    } else {
      error = genericError
    }
  } else {
    error = genericError
  }

  return error
}

const handleDeleteError = (err) => {
  let error = 'Something went wrong trying to delete the view.'

  if (err?.code === 409) {
    error =
      'You already have this view as live view, cannot be deleted at the moment.'
  }

  return error
}

export const defaultColumnConfig = {
  columns: [
    { Header: 'View ID', accessor: 'viewId', id: 'viewId' },
    { Header: 'Name', accessor: 'name', id: 'name' },
    {
      id: 'path',
      Header: 'Path',
      accessor: 'path',
      Cell: ViewPathCell
    },
    { Header: 'Version', accessor: 'version', id: 'version' },
    {
      id: 'liveView',
      Header: 'Is Live',
      // eslint-disable-next-line react/prop-types
      Cell: ({ row }) => {
        // eslint-disable-next-line react/prop-types
        const isLiveView = row?.original?.liveView
        return <ViewEnabledFlagCell enabled={isLiveView} />
      }
    },
    {
      id: 'showInNavigation',
      Header: 'Show in navigation',
      // eslint-disable-next-line react/prop-types
      Cell: ({ row }) => {
        // eslint-disable-next-line react/prop-types
        const showInNavigation = row?.original?.showInNavigation
        return <ViewEnabledFlagCell enabled={showInNavigation} />
      }
    },
    {
      id: 'actions',
      Header: 'Actions',
      Cell: ViewActionsCell
    }
  ],
  defaultSort: [{ id: 'name', desc: false }]
}

const queryOptions = {
  mapper: ({ rows }) => {
    return rows
  }
}

function AbundanceEngineView () {
  const classes = useStyles()
  const [selectedView, setSelectedView] = useState()
  const [isSidePanelOpen, setSidePanel] = useBoolean()
  const [isDeleting, setIsDeleting] = useBoolean()
  const [isInPreviewMode, setIsInPreviewMode] = useBoolean()
  const [viewToDelete, setViewToDelete] = useState(null)
  const [alertState, setAlertState] = useState(initialAlertState)
  const { alertMessage, alertSeverity, openAlert } = alertState
  const [error, setError] = useState()

  const searchInputRef = useRef()

  const {
    defaultPageSize,
    searchText,
    onPagingChange,
    onSortingChange,
    onSearchTextChange,
    onTableModeChange
  } = useOperationalTable(defaultColumnConfig.defaultSort)

  const handleCloseAlert = useCallback((_) => {
    setViewToDelete(null)
    setAlertState({ ...initialAlertState, loadData: false })
  }, [])

  const {
    data: views = [],
    isLoading: loadingViews,
    isFetching: fetchingViews,
    refetch: refetchViews
  } = useListAbundanceEngineViews(null, queryOptions)

  const onClose = useCallback(() => {
    setSelectedView()
    handleCloseAlert()
    setSidePanel.off()
  }, [handleCloseAlert, setSidePanel])

  const onSubmit = useCallback(
    async ({
      viewId,
      viewPath,
      viewName,
      viewVersion,
      viewDescription,
      viewContent,
      viewIsLive,
      showInNavigation
    }) => {
      handleCloseAlert()

      let error = null
      const editMode = !!viewId

      try {
        const viewPayload = {
          path: viewPath,
          name: viewName,
          version: Number(viewVersion) || 1,
          description: viewDescription,
          content: viewContent,
          isLive: viewIsLive === '1',
          showInNavigation: showInNavigation === '1'
        }

        editMode
          ? await editAbundanceEngineView(viewId, viewPayload)
          : await createAbundanceEngineView(viewPayload)

        refetchViews()
      } catch (err) {
        setError(err?.message)
        error = handleSubmitError(err, editMode)
      } finally {
        if (error) {
          setAlertState({
            ...errorAlert,
            alertMessage: error
          })
        }
      }

      if (!error) {
        onClose()
        setAlertState({
          ...successAlert,
          alertMessage: `View ${editMode ? 'edited' : 'created'} successfully`
        })
      }
    },
    [onClose, handleCloseAlert, refetchViews]
  )

  const onAddClick = useCallback(() => {
    handleCloseAlert()
    setSidePanel.on()
  }, [setSidePanel, handleCloseAlert])

  const onEditClick = useCallback(
    (tableItem) => {
      handleCloseAlert()
      setSelectedView(tableItem)
      setSidePanel.on(true)
    },
    [setSidePanel, handleCloseAlert]
  )

  const onDeleteClick = useCallback(
    (tableItem) => {
      handleCloseAlert()
      setViewToDelete(tableItem.viewId)
    },
    [handleCloseAlert]
  )

  const onCloseDialog = useCallback(() => {
    setSidePanel.toggle()
    setError()
    handleCloseAlert()
    setSelectedView()
    setIsInPreviewMode.off()
    onSearchTextChange(undefined)
    searchInputRef.current?.clear()
  }, [
    setSidePanel,
    handleCloseAlert,
    setIsInPreviewMode,
    onSearchTextChange
  ])

  const onConfirmDeleteView = useCallback(async () => {
    let error = null
    try {
      if (!isDeleting && viewToDelete) {
        setIsDeleting.on()
        await deleteAbundanceEngineView(viewToDelete)
        refetchViews()
      }
    } catch (err) {
      error = handleDeleteError(err)
      setAlertState({
        ...errorAlert,
        alertMessage: error
      })
    } finally {
      if (!error) {
      }
      setViewToDelete(null)
      setIsDeleting.off()
    }
  }, [setIsDeleting, isDeleting, viewToDelete, refetchViews])

  const viewContext = useMemo(() => {
    return {
      name: 'abundanceEngineView',
      initialState: {
        onEditClick,
        onDeleteClick
      }
    }
  }, [onDeleteClick, onEditClick])

  const methods = useForm({
    defaultValues: mapFormDefaultValues({})
  })

  useEffect(() => {
    methods.reset(mapFormDefaultValues(selectedView))
  }, [methods, selectedView])

  const handleSubmit = useCallback(() => {
    methods.handleSubmit(onSubmit)()
  }, [methods, onSubmit])

  const onTogglePreviewMode = useCallback(() => {
    const formValues = methods.getValues()
    setSelectedView((prevState) => ({
      ...prevState,
      ...formValues
    }))
    setIsInPreviewMode.toggle()
  }, [methods, setIsInPreviewMode])

  const renderContentValidationError = useMemo(() => {
    if (!error) return null
    return (
      <MuiAlert
        severity='error'
        action={
          <IconButton
            aria-label='close'
            color='inherit'
            size='small'
            onClick={() => {
              setError()
            }}
          >
            <CloseIcon fontSize='inherit' />
          </IconButton>
        }
      >
        {error}
      </MuiAlert>
    )
  }, [error])

  return (
    <ContextContainerProvider context={viewContext}>
      <div className={classes.container}>
        <OperationalTable.Wrapper>
          <OperationalTable.SuperHeader className={classes.header}>
            <OperationalTable.Search
              ref={searchInputRef}
              onChange={onSearchTextChange}
              placeholder='Search for a view'
              delay={250}
            />
            <Box ml='auto' mr='0.5rem'>
              <RoundedButton
                size={BUTTON_SIZES.extraSmall}
                onClick={onAddClick}
                primary
              >
                Add View
              </RoundedButton>
            </Box>
          </OperationalTable.SuperHeader>
          <OperationalTable
            mode='client'
            columns={defaultColumnConfig.columns}
            data={views}
            defaultPageSize={defaultPageSize}
            defaultSort={defaultColumnConfig.defaultSort}
            itemName='Abundance Engine Views'
            loading={loadingViews || fetchingViews}
            total={0}
            searchText={searchText}
            onSortingChange={onSortingChange}
            onPagingChange={onPagingChange}
            onTableModeChange={onTableModeChange}
            onRowClick={noop}
          />
        </OperationalTable.Wrapper>
        <AbundanceEngineDialog
          title={selectedView?.viewName || 'Abundance Engine View'}
          open={isSidePanelOpen}
          onClose={onCloseDialog}
          onSave={handleSubmit}
          isInPreviewMode={isInPreviewMode}
          togglePreviewMode={onTogglePreviewMode}
          loading={methods.formState.isSubmitting}
        >
          {isInPreviewMode ? (
            <AbundanceParser jsx={selectedView.viewContent} />
          ) : (
            <FormProvider {...methods}>
              <AbundanceEngineViewForm header={renderContentValidationError} />
            </FormProvider>
          )}
        </AbundanceEngineDialog>
        <Snackbar
          open={openAlert}
          autoHideDuration={6000}
          onClose={handleCloseAlert}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert onClose={handleCloseAlert} severity={alertSeverity}>
            {alertMessage}
          </Alert>
        </Snackbar>
        <ManagementDialog
          title='Are you sure you want to delete this view?'
          open={Boolean(viewToDelete)}
          onClose={handleCloseAlert}
          onToggle={() => {
            setViewToDelete(null)
          }}
          loading={isDeleting}
          onTriggerAction={onConfirmDeleteView}
        />
      </div>
    </ContextContainerProvider>
  )
}

export default AbundanceEngineView
