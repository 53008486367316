import React, { createContext, useContext, useLayoutEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { useOperationalTable } from '../../../../organisms/OperationalTable'
import { localStorageHelper } from '../../../../../utils/localStorageHelper'
import { DIALOG_STORAGE_KEYS as STR_KEYS } from '../../../../organisms/GroupingProvider/GroupingCustomizeColumnsDialog/helpers'
import { useSearchWealthOwners } from '../../../../../api/users'
import { mapSearch, mapSort } from './hooks'

export const UserListContext = createContext()
export const useUsersListContext = () => useContext(UserListContext)
const UsersListContextProvider = ({ children, configurationKey, defaultColumnConfig, urlFiltersMap }) => {
  const [visibleFilters, setVisibleFilters] = useState([])

  const {
    defaultPageSize,
    pageIndex,
    pageSize,
    sort,
    searchText,
    onPagingChange,
    onSortingChange,
    onSearchTextChange,
    onTableModeChange
  } = useOperationalTable({
    defaultSort: defaultColumnConfig.defaultSort
  })

  const [clientFilters, setClientFilters] = useState({
  })

  useLayoutEffect(() => {
    const configuration = localStorageHelper.load(
        `${STR_KEYS.COLUMNS_DND_ORDER_TOGGLER}_${configurationKey}`
    )
    if (configuration) {
      const { columnOrder } = configuration
      setVisibleFilters([...columnOrder])
    }
  }, [configurationKey])

  const { query: userQuery, queryOptions: userQueryOptions } = useMemo(() => {
    return {
      query: {
        sort: mapSort(sort),
        skip: pageIndex * pageSize || 0,
        take: pageSize,
        textSearch: mapSearch(searchText),
        includes: {
          role: true
        },
        filters: {
        }
      },
      queryOptions: {
        enabled: true
      }
    }
  }, [
    sort,
    pageSize,
    pageIndex,
    searchText
  ])

  const { data, isLoading } = useSearchWealthOwners(userQuery, userQueryOptions)
  const value = useMemo(
    () => ({
      users: (data ?? []),
      isLoading,
      operationalTable: {
        onPagingChange,
        onSortingChange,
        onSearchTextChange,
        onTableModeChange,
        defaultPageSize
      },
      searchText,
      columnConfig: defaultColumnConfig,
      clientFilters,
      setClientFilters,
      visibleFilters,
      setVisibleFilters,
      onChangeFilters: () => {},
      configurationKey
    }),
    [
      data,
      isLoading,
      searchText,
      onPagingChange,
      defaultPageSize,
      onSortingChange,
      onTableModeChange,
      onSearchTextChange,
      clientFilters,
      setClientFilters,
      visibleFilters,
      configurationKey,
      defaultColumnConfig
    ]
  )

  return (
    <UserListContext.Provider value={value}>
      {children}
    </UserListContext.Provider>
  )
}

UsersListContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
  defaultColumnConfig: PropTypes.object,
  configurationKey: PropTypes.string,
  urlFiltersMap: PropTypes.object
}

UsersListContextProvider.defaultProps = {
  configurationKey: 'adminClientListUsersView',
  urlFiltersMap: {}
}

export default UsersListContextProvider
