import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import AdvisorInfo from './AdvisorInfo'
import DefaultGroupInfo from './DefaultGroupInfo'

const GroupInfo = ({ group }) => {
  const component = useMemo(() => {
    switch (group.levelTypeId) {
      case 301:
        return <AdvisorInfo advisor={group} />
      default:
        return <DefaultGroupInfo group={group} />
    }
  }, [group])

  return component
}

GroupInfo.propTypes = {
  group: PropTypes.object
}

export default GroupInfo
