import React from 'react'
import { makeStyles } from '@material-ui/core'
import ViewTabs from '../../../organisms/AdvisorView/components/AdvisorViewTabs/index'
import { useCheckPolicy } from '../../../../hooks'
import NotAvailable from '../../../organisms/NotAvailable'
import TemplatesListTab from './TemplatesListTab'
import HeaderActions from './HeaderActions'

const tabOptions = {
  templates: {
    label: 'Status Report Templates',
    value: 'templates'
  }
}

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '1rem'
  },
  actions: {
    marginLeft: 'auto',
    display: 'flex',
    gap: '0.5rem'
  }
}))

function StatusReportsHome () {
  const classes = useStyles()
  const canViewStatusReports = useCheckPolicy('admin_view_status_templates')

  if (!canViewStatusReports) {
    return <NotAvailable />
  }

  return (
    <div className={classes.container}>
      <ViewTabs.Group tabOptions={tabOptions}>
        <ViewTabs.Links
          tabsKey='admin_status_reports_list'
          rightAdornment={<HeaderActions />}
        />
        <ViewTabs.Tab value='templates'>
          <TemplatesListTab />
        </ViewTabs.Tab>
      </ViewTabs.Group>
    </div>
  )
}

StatusReportsHome.propTypes = {
}

StatusReportsHome.defaultProps = {
}

export default StatusReportsHome
