import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { useCurrentClient } from '../../api/clients'
import FadeIn from './Transitions/FadeIn'

function CurrentClientTemplatedText ({ template, style, className, fallbackText }) {
  const { data, isLoading, isError } = useCurrentClient()
  const renderedText = useMemo(() => {
    if (!template) return ''
    return template
      .replace('@longName', data?.longName)
      .replace('@shortName', data?.shortName)
      .replace('@description', data?.description)
      .replace('@familyName', data?.longName)
      .replace('@abbreviation', data?.clientAbbreviation)
  }, [template, data])

  if (isLoading) {
    return null
  }

  if (isError) {
    return (
      <FadeIn className={clsx(className)} style={style}>
        {fallbackText}
      </FadeIn>
    )
  }

  return (
    <FadeIn className={clsx(className)} style={style}>
      {renderedText}
    </FadeIn>
  )
}

CurrentClientTemplatedText.propTypes = {
  template: PropTypes.string.isRequired,
  className: PropTypes.string,
  style: PropTypes.object,
  fallbackText: PropTypes.string
}

CurrentClientTemplatedText.defaultProps = {
  fallbackText: ''
}

export default CurrentClientTemplatedText
