import {
  Grow,
  Paper,
  Popper,
  Button,
  MenuItem,
  MenuList,
  ClickAwayListener,
  makeStyles,
  CircularProgress,
  Divider
} from '@material-ui/core'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import CheckIcon from '@material-ui/icons/Check'
import { isEmpty, isObject } from 'lodash'
import clsx from 'clsx'
import Text from '../../atoms/Text'
import { useBoolean } from '../../../hooks'
import { BUTTON_SIZES } from '../../../constants'
import selectStyles from './helpers'

export const useStyles = makeStyles(() => ({
  container: ({ isEmpty, width }) => ({
    maxHeight: '20rem',
    overflowY: 'auto',
    padding: 0,
    ...(width
      ? { width, maxWidth: 'unset', minWidth: 'unset' }
      : { maxWidth: '25rem', minWidth: '18rem' }),
    ...(isEmpty ? { padding: '0 !important' } : {})
  }),
  button: ({ error, disabled, fullWidth, readOnly }) => ({
    textTransform: 'unset',
    ...(disabled
      ? {
        opacity: 0.5,
        backgroundColor: '#FFF',
        '&:hover': {
          backgroundColor: '#FFF',
          cursor: readOnly ? 'default' : 'pointer'
        }
      }
      : {
        '&:hover': {
          cursor: readOnly ? 'default' : 'pointer'
        }
      }),
    ...(error ? { color: '#D44333' } : {}),
    '& span': {
      display: 'flex',
      justifyContent: 'space-between'
    },
    width: fullWidth ? '100%' : 'unset'
  }),
  popper: {
    zIndex: 1301
  },
  popupContainer: {
    background: '#FFFFFF',
    boxShadow: '0px 4px 12px rgba(24, 27, 53, 0.08)',
    borderRadius: '12px'
  },
  item: ({ customOpts, size }) => ({
    padding: selectStyles.itemPadding[size],
    ...(customOpts?.textCenter
      ? {
        whiteSpace: 'normal',
        textAlign: 'center'
      }
      : null)
  }),
  caret: {
    display: 'flex',
    marginLeft: '0.75rem'
  },
  adornment: {
    marginLeft: '1rem',
    '& > svg': { color: '#3AA76D' }
  },
  outlined: {
    background: '#FFFFFF',
    border: '3px solid #D7DCE1',
    borderRadius: '4px'
  },
  outlinedActive: {
    border: '3px solid #212945',
    background:
      'linear-gradient(0deg, rgba(244, 245, 246, 0.5), rgba(244, 245, 246, 0.5)), #FFFFFF'
  },
  displayValue: {
    display: 'flex',
    flexDirection: 'row',
    '& span:first-child': {
      marginRight: '0.625rem'
    }
  },
  outlinedRounded: {
    background: '#FFFFFF',
    border: '1px solid #D7DCE1',
    borderRadius: '4px'
  },
  buttonContainer: {
    width: 'unset'
  },
  fullWidth: {
    width: '100%'
  },
  baseText: ({ size }) => ({
    color: 'inherit',
    fontWeight: '600',
    fontSize: selectStyles.fontSize[size],
    textTransform: 'none',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }),
  whiteHover: {
    '&:hover': {
      backgroundColor: '#FFF'
    }
  }
}))

const findOption = (value) => ({ value: _value }) => value === _value

const findSelectedValue = (value, options = [], initialOptions = [], pinnedOptions = []) => {
  let selectedOption = null
  const option = options.find(findOption(value))
  if (option) selectedOption = option

  const defaultOption = initialOptions.find(findOption(value))
  if (defaultOption) selectedOption = defaultOption

  const isValueAnOption = isObject(value) && value?.label && value?.value
  if (isValueAnOption) selectedOption = value

  const pinnedOption = pinnedOptions.find(findOption(value))
  if (pinnedOption) selectedOption = pinnedOption

  return selectedOption
}

const Select = ({
  open: _open,
  setOpen: _setOpen,
  size,
  variant,
  error,
  value,
  defaultValue,
  options,
  disabled,
  readOnly,
  placement,
  className,
  placeholder,
  defaultOptions,
  optionsHeader,
  optionsFooter,
  optionsRenderer,
  prefixLabel,
  labelRenderer,
  onClose,
  fullWidth,
  labelClassName,
  allowReInitializeDefaultOptions,
  showCheckMarOnSelectedItems,
  onChange: _onChange,
  preventOptionsOverflowContainer,
  customOpts,
  loadingOpts,
  pinnedOptions,
  caretIconOpen: CaretIconOpen,
  caretIconClose: CaretIconClose
}) => {
  const anchorRef = useRef(null)
  const classes = useStyles({
    error,
    size,
    disabled,
    fullWidth,
    readOnly,
    isEmpty: isEmpty(options),
    width: preventOptionsOverflowContainer && anchorRef.current?.offsetWidth,
    customOpts
  })
  const [selectedValue, setSelectedValue] = useState(null)
  const [internalOpen, setInternalOpen] = useBoolean()
  const [initialOptions, setInitialOptions] = useState(defaultOptions)

  useEffect(() => {
    if (allowReInitializeDefaultOptions) {
      setInitialOptions([...defaultOptions])
    }
  }, [defaultOptions, allowReInitializeDefaultOptions])

  const [open, setOpen] = _open !== undefined
    ? [_open, _setOpen]
    : [internalOpen, setInternalOpen]

  useEffect(() => {
    if (!open && onClose) {
      onClose()
    }
  }, [onClose, open])

  const handleToggle = useCallback(() => {
    if (!disabled && !readOnly) {
      setOpen.toggle()
    }
  }, [readOnly, disabled, setOpen])

  const handleClose = useCallback((event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }
    setOpen.off()
  }, [setOpen])

  const prevOpen = useRef(open)

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus()
    }
    prevOpen.current = open
  }, [open])

  const handleListKeyDown = useCallback((event) => {
    if (event.key === 'Tab' || event.key === 'Escape') {
      event.preventDefault()
      setOpen.off()
    }
  }, [setOpen])

  const getSelectedValue = useCallback((value) => {
    const selectedOption = findSelectedValue(value, options, initialOptions, pinnedOptions)
    return selectedOption
  }, [initialOptions, options, pinnedOptions])

  useEffect(() => {
    // only initialize if there's nothing selected
    const shouldUpdateValue = !selectedValue || (selectedValue && selectedValue?.value !== value)
    if (shouldUpdateValue) {
      const selectedOption = getSelectedValue(defaultValue || value)
      setSelectedValue(selectedOption)
    }
  }, [selectedValue, value, defaultValue, getSelectedValue])

  const onChange = useCallback(
    (event, value) => {
      if (anchorRef.current && anchorRef.current.contains(event.target)) {
        return
      }
      setOpen.off()
      const selectedOption = getSelectedValue(value)
      const eventOverride = {
        ...event,
        target: {
          ...event.target,
          value
        }
      }
      setSelectedValue(selectedOption)
      _onChange(value, selectedOption, eventOverride)
    },
    [setOpen, _onChange, getSelectedValue]
  )

  const renderCheckedIcon = useCallback(
    ({ value }) => {
      if (!showCheckMarOnSelectedItems) return null
      if (value !== selectedValue?.value) return null
      return (
        <div className={classes.adornment}>
          <CheckIcon />
        </div>
      )
    },
    [showCheckMarOnSelectedItems, selectedValue?.value, classes.adornment]
  )

  const renderOptions = useCallback(
    ({ TransitionProps, placement }) => {
      const _renderOptions = (options) => options.map((option) => {
        if (optionsRenderer) {
          return optionsRenderer(option, onChange, renderCheckedIcon(option))
        }

        const renderedLabelDetails = option?.labelDetails
          ? <Text text={option.labelDetails} />
          : null

        return (
          <MenuItem
            key={`${option.value}-${option.label}`}
            className={classes.item}
            value={option.value || option}
            disabled={option.disabled}
            onClick={(event) => onChange(event, option.value)}
          >
            <div>
              <Text text={option.label} className={classes.baseText} customFontWeight='600' />
              {renderedLabelDetails}
            </div>
            {renderCheckedIcon(option)}
          </MenuItem>
        )
      })

      const transformOrigin =
        placement === 'bottom' ? 'center top' : 'center bottom'

      const showPinnedOptions = !isEmpty(pinnedOptions)

      return (
        <Grow {...TransitionProps} style={{ transformOrigin }}>
          <Paper className={classes.popupContainer}>
            <ClickAwayListener onClickAway={handleClose}>
              <div>
                {optionsHeader}
                <MenuList
                  autoFocusItem={open}
                  id='menu-list-grow'
                  onKeyDown={handleListKeyDown}
                  className={classes.container}
                >
                  {showPinnedOptions && _renderOptions(pinnedOptions)}
                  {showPinnedOptions && <Divider style={{ margin: '0' }} />}
                  {_renderOptions(options)}
                </MenuList>
                {optionsFooter}
              </div>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )
    },
    [
      open,
      options,
      onChange,
      handleClose,
      optionsFooter,
      optionsHeader,
      optionsRenderer,
      pinnedOptions,
      handleListKeyDown,
      renderCheckedIcon,
      classes.item,
      classes.baseText,
      classes.container,
      classes.popupContainer
    ]
  )

  const renderBaseText = useCallback(
    (label) => (
      <Text
        text={label}
        className={clsx(classes.baseText, {
          [labelClassName]: Boolean(labelClassName)
        })}
      />
    ),
    [classes.baseText, labelClassName]
  )

  const renderLabel = useMemo(() => {
    if (labelRenderer) {
      return labelRenderer({ selectedValue, placeholder, prefixLabel })
    }
    const displayValue = selectedValue?.displayLabel || selectedValue?.label || placeholder
    if (prefixLabel) {
      return (
        <div className={classes.displayValue}>
          <span>{renderBaseText(prefixLabel)}</span>
          <span>{renderBaseText(displayValue)}</span>
        </div>
      )
    }
    return renderBaseText(displayValue)
  }, [
    placeholder,
    prefixLabel,
    labelRenderer,
    selectedValue,
    renderBaseText,
    classes.displayValue
  ])

  const classNames = useMemo(() => {
    return clsx(classes.button, {
      [classes.outlined]: variant === 'outlined',
      [classes.outlinedRounded]: variant === 'outlined-rounded',
      [classes.outlinedActive]: (variant.startsWith('outlined')) && open,
      [classes.whiteHover]: customOpts?.whiteHover,
      [className]: !!className
    })
  }, [
    className,
    classes.button,
    classes.outlined,
    classes.outlinedActive,
    classes.outlinedRounded,
    classes.whiteHover,
    open,
    variant,
    customOpts
  ])

  return (
    <div className={clsx(classes.buttonContainer, { [classes.fullWidth]: fullWidth })}>
      <Button
        ref={anchorRef}
        disableRipple
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup='true'
        className={classNames}
        onClick={handleToggle}
        endIcon={loadingOpts ? <CircularProgress size={15} /> : null}
      >
        {renderLabel}
        {!readOnly && (
          <div className={classes.caret}>
            {open ? (
              <CaretIconClose fontSize='small' />
            ) : (
              <CaretIconOpen fontSize='small' />
            )}
          </div>
        )}
      </Button>
      <Popper
        transition
        open={open}
        role={undefined}
        placement={placement}
        className={classes.popper}
        anchorEl={anchorRef.current}
      >
        {renderOptions}
      </Popper>
    </div>
  )
}

const itemShape = PropTypes.shape({
  label: PropTypes.string,
  disabled: PropTypes.bool,
  /* If specified then overrides label when option is selected, label is still used for the options menu */
  displayLabel: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  payload: PropTypes.object
})

Select.propTypes = {
  variant: PropTypes.oneOf(['standard', 'outlined', 'outlined-rounded']),
  className: PropTypes.string,
  open: PropTypes.bool,
  setOpen: PropTypes.shape({
    on: PropTypes.func,
    off: PropTypes.func,
    toggle: PropTypes.func
  }),
  error: PropTypes.string,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  labelRenderer: PropTypes.func,
  showCheckMarOnSelectedItems: PropTypes.bool,
  options: PropTypes.arrayOf(itemShape),
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  optionsRenderer: PropTypes.func,
  placement: PropTypes.string,
  optionsHeader: PropTypes.node,
  optionsFooter: PropTypes.node,
  defaultOptions: PropTypes.arrayOf(itemShape),
  prefixLabel: PropTypes.string,
  onClose: PropTypes.func,
  allowReInitializeDefaultOptions: PropTypes.bool,
  preventOptionsOverflowContainer: PropTypes.bool,
  fullWidth: PropTypes.bool,
  labelClassName: PropTypes.string,
  size: PropTypes.oneOf(Object.values(BUTTON_SIZES)),
  customOpts: PropTypes.shape({
    whiteHover: PropTypes.bool,
    textCenter: PropTypes.bool
  }),
  loadingOpts: PropTypes.bool,
  pinnedOptions: PropTypes.arrayOf(itemShape),
  caretIconOpen: PropTypes.any,
  caretIconClose: PropTypes.any
}

Select.defaultProps = {
  open: undefined,
  setOpen: undefined,
  labelRenderer: undefined,
  variant: 'standard',
  className: '',
  error: '',
  readOnly: false,
  disabled: false,
  showCheckMarOnSelectedItems: false,
  onChange: noop,
  placeholder: 'Select an option',
  options: [],
  value: '',
  optionsRenderer: null,
  placement: 'bottom-start',
  optionsHeader: undefined,
  optionsFooter: undefined,
  defaultOptions: [],
  allowReInitializeDefaultOptions: false,
  prefixLabel: null,
  onClose: noop,
  preventOptionsOverflowContainer: false,
  fullWidth: false,
  labelClassName: undefined,
  size: BUTTON_SIZES.medium,
  customOpts: undefined,
  loadingOpts: false,
  pinnedOptions: [],
  caretIconOpen: ExpandMoreIcon,
  caretIconClose: ExpandLessIcon
}

export default Select
