import React, { createContext, useContext, useImperativeHandle, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { useDates } from '../../../hooks/useDates'
import { useOperationalTable } from '../OperationalTable'
import TransactionsColumnProvider from './TransactionsColumnContext'
import { useTransactionsCounts, useTransactionsData } from './useTransactionsData'
import { useSubscribedFilters } from './useSubscribedFilters'

const TransactionsContext = createContext({})

export const useTransactionsContext = () => useContext(TransactionsContext)

const TransactionsProvider = React.forwardRef(function TransactionsProvider ({
  defaultDateRange,
  storageKey,
  scope,
  defaultFilter,
  defaultSort,
  columns,
  children
}, ref) {
  const [dateRange, setDateRange] = useState(defaultDateRange)
  const [featuredFilters, setFeaturedFilters] = useState([])
  const [textFilter, setTextFilter] = useState({})
  const [tableFilter, setTableFilter] = useState({})
  const { availableDates } = useDates()
  const {
    sort,
    onSortingChange,
    pageIndex,
    pageSize,
    onPagingChange
  } = useOperationalTable({ defaultSort })
  useImperativeHandle(ref, () => {
    return {
      setDateRange
    }
  }, [setDateRange])

  const levelFilters = useSubscribedFilters()

  const { transactions, transactionsLoading } = useTransactionsData({
    dateRange,
    asOfDate: availableDates.mainDate,
    featuredFilters,
    levelFilters,
    textFilter,
    tableFilter,
    scope,
    defaultFilter,
    sort,
    pageSize,
    pageIndex
  })

  const { counts, countsLoading } = useTransactionsCounts({
    dateRange,
    asOfDate: availableDates.mainDate,
    featuredFilters,
    levelFilters,
    textFilter,
    tableFilter,
    scope,
    defaultFilter
  })

  const value = useMemo(() => ({
    dateRange,
    setDateRange,
    asOfDate: availableDates.mainDate,

    featuredFilters,
    setFeaturedFilter: (f) => setFeaturedFilters([f]),
    addFeaturedFilter: f => setFeaturedFilters(prev => {
      if (prev.some(x => x.id === f.id)) return prev
      return [...prev, f]
    }),
    removeFeaturedFilter: f => setFeaturedFilters(prev => {
      if (!prev.some(x => x.id === f.id)) return prev
      return prev.filter(x => x.id !== f.id)
    }),
    clearFeaturedFilters: f => setFeaturedFilters([]),

    textFilter,
    setTextFilter,

    tableFilter,
    setTableFilter,

    transactions,
    transactionsLoading: transactionsLoading,
    counts,
    countsLoading,
    defaultFilter,
    scope,

    onPagingChange,
    onSortingChange
  }), [
    dateRange, setDateRange,
    featuredFilters, setFeaturedFilters,
    textFilter, setTextFilter,
    tableFilter, setTableFilter,
    availableDates.mainDate,
    transactions, transactionsLoading,
    counts, countsLoading,
    defaultFilter,
    onPagingChange,
    onSortingChange,
    scope
  ])

  return (
    <TransactionsContext.Provider value={value}>
      <TransactionsColumnProvider storageKey={storageKey} columns={columns} defaultSort={defaultSort}>
        {children}
      </TransactionsColumnProvider>
    </TransactionsContext.Provider>
  )
})

TransactionsProvider.propTypes = {
  scope: PropTypes.oneOf(['client', 'firm']),
  columns: PropTypes.array,
  defaultSort: PropTypes.array,
  defaultFilter: PropTypes.object,
  defaultDateRange: PropTypes.string,
  storageKey: PropTypes.string,
  children: PropTypes.node
}

TransactionsProvider.defaultProps = {
  scope: 'client',
  defaultDateRange: 'L30D'
}

export default TransactionsProvider
