import React from 'react'
import { makeStyles } from '@material-ui/core'
import { useCurrentTab } from '../../../organisms/AdvisorView/components/AdvisorViewTabs'
import { ADMIN_ROUTES, ICON_NAMES } from '../../../../constants'
import { useCheckPolicy } from '../../../../hooks'
import { ROLES } from '../../../../policies/admin'
import SydLinkButton from '../../../commonDesign/LinkButton'

const useStyles = makeStyles((theme) => ({
  roundedButton: {
    border: `2px solid ${theme.palette.primary.main}`,
    borderRadius: 25,
    padding: '0.45rem 1rem',
    textTransform: 'none',
    fontWeight: 'bold',
    fontSize: '0.875rem',
    alignSelf: 'flex-start',
    marginLeft: 'auto',
    '& .MuiButton-label': {
      fontSize: '0.75rem'
    }
  },
  disabled: {
    borderColor: 'rgba(0, 0, 0, 0.12)'
  },
  actions: {
    marginLeft: 'auto',
    display: 'flex',
    gap: '0.5rem'
  }
}))

function HeaderActions () {
  const classes = useStyles()
  const tabValue = useCurrentTab()
  const canEditRoles = useCheckPolicy(ROLES.editWealthOwnerRoles)
  const canEditUsers = useCheckPolicy('admin_edit_wo_users')

  if (tabValue === 'clients') {
    return (
      <div className={classes.actions}>
        <SydLinkButton
          to={ADMIN_ROUTES.NEW_CLIENT}
          size='sm'
          variant='primary'
          icon={ICON_NAMES.add}
        >
          Add Client
        </SydLinkButton>
      </div>
    )
  }

  if (tabValue === 'users' && canEditUsers) {
    return (
      <div className={classes.actions}>
        <SydLinkButton
          variant='outline'
          size='sm'
          to={`${ADMIN_ROUTES.WEALTH_OWNERS}/deactivated`}
          icon={ICON_NAMES.user}
        >
          View Deactivated
        </SydLinkButton>
      </div>
    )
  }

  if (tabValue === 'experiences' && canEditRoles) {
    return (
      <div className={classes.actions}>
        <SydLinkButton
          variant='primary'
          size='sm'
          to={`${ADMIN_ROUTES.WEALTH_OWNERS}/roles/new`}
          icon={ICON_NAMES.add}
        >
          Add Experience
        </SydLinkButton>
      </div>
    )
  }

  return null
}

export default HeaderActions
