import React, { useCallback, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Controller, useForm } from 'react-hook-form'
import SydButton from '../../../../../../commonDesign/Button'
import {
  useFeeSchedule,
  useReplaceClientFeeScheduleMutation
} from '../../../../../../../api/billing'
import { useDialogStyles } from '../../common'
import Loading from '../../../../../../molecules/Loading'
import SydLabel from '../../../../../../commonDesign/SydLabel'
import FeeScheduleTiers from '../../FeeScheduleTiers'
import SelectBox from '../../shared/SelectBox'

const useSubmitter = (form, onComplete, client, oldFeeSchedule) => {
  const { handleSubmit } = form
  const [processing, setProcessing] = useState(false)
  const [error, setError] = useState(null)
  const { mutateAsync: addFeeSchedule } = useReplaceClientFeeScheduleMutation()
  const onSubmit = useCallback(async (formData) => {
    const command = {
      clientId: client.clientId,
      oldFeeScheduleId: oldFeeSchedule.feeScheduleId,
      feeScheduleId: +formData.feeScheduleId
    }

    try {
      setError(null)
      setProcessing(true)
      const result = await addFeeSchedule(command)
      if (result?.statusCode === 500) {
        throw new Error('Failed to replace fee schedule')
      }
      onComplete(result)
    } catch (err) {
      setError(err?.toString())
    } finally {
      setProcessing(false)
    }
  }, [addFeeSchedule, setProcessing, onComplete, client, oldFeeSchedule, setError])

  const submitter = useCallback(async (e) => {
    const onValid = async (form) => {
      await onSubmit(form)
    }
    const onInvalid = (errors) => {
      console.error(errors)
    }

    const handler = handleSubmit(onValid, onInvalid)
    await handler(e)
  }, [handleSubmit, onSubmit])

  return {
    submitter,
    processing,
    error
  }
}

function ReplaceFeeScheduleForm ({ client, feeSchedule: oldFeeSchedule, onCancel, onComplete }) {
  const classes = useDialogStyles()
  const form = useForm({
    mode: 'onChange',
    defaultValues: {
      clientId: client.clientId,
      oldFeeScheduleId: oldFeeSchedule.feeScheduleId,
      feeScheduleId: ''
    }
  })

  const { data, isLoading } = useFeeSchedule()
  const { submitter, processing, error } = useSubmitter(form, onComplete, client, oldFeeSchedule)

  const feeScheduleId = form.watch('feeScheduleId')
  const selected = useMemo(() => {
    return (data || []).find(x => x.feeScheduleId === feeScheduleId)
  }, [feeScheduleId, data])

  if (isLoading) {
    return <Loading />
  }

  return (
    <>
      <div className={classes.form}>
        <div className={classes.group}>
          <Controller
            name='feeScheduleId' control={form.control}
            render={(f => (
              <SydLabel label='Fee Schedule'>
                <SelectBox
                  options={data}
                  valueAccessor='feeScheduleId'
                  labelAccessor='longName'
                  {...f.field}
                />
              </SydLabel>
            ))}
          />
        </div>
      </div>
      {selected ? (
        <div className={classes.additionalInfo}>
          <div className={classes.compare}>
            <div>
              <div>{oldFeeSchedule.longName}</div>
              <FeeScheduleTiers feeSchedule={oldFeeSchedule} />
            </div>
            <div style={{ fontSize: '30px' }}>&rarr;</div>
            <div>
              <div>{selected.longName}</div>
              <FeeScheduleTiers feeSchedule={selected} />
            </div>
          </div>
        </div>
      ) : <div className={classes.additionalInfo} />}
      <div className={classes.actions}>
        {error ? (<div className={classes.error}>{error}</div>) : null}
        <SydButton disabled={processing} variant='ghost' size='md' onClick={onCancel}>Cancel</SydButton>
        <SydButton
          variant='primary'
          size='md'
          onClick={submitter}
          processing={processing}
        >
          Save
        </SydButton>
      </div>
    </>
  )
}

ReplaceFeeScheduleForm.propTypes = {
  client: PropTypes.shape({
    clientId: PropTypes.number
  }),
  feeSchedule: PropTypes.shape({
    feeScheduleId: PropTypes.number,
    longName: PropTypes.string
  }),
  onComplete: PropTypes.func,
  onCancel: PropTypes.func
}

export default ReplaceFeeScheduleForm
