import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import { isEmpty } from 'lodash'
import { useAppContext } from '../../../redux/slices/appContext'
import { useCoreTableData, useNormalizeDates } from '../../../api/coreData'
import Select from '../../molecules/Select'
import EmptySection from '../../atoms/EmptySection'
import { useWidgetContext } from '../../molecules/WidgetWrapper'
import ChartTypeToggle from './ChartTypeToggle'
import AllocationChartDiscriminator from './AllocationChartDiscriminator'

const useStyles = makeStyles(() => ({
  allocationChart: {
    padding: 0
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    lineHeight: '30px',
    fontSize: '.9rem',
    padding: '.5rem 1.5rem'
  },
  footer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '.5rem 1rem',
    '& > span': {
      margin: '0 .5rem'
    }
  },
  title: {
    flex: '1 0',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  titleCopy: {
    paddingRight: '.25rem'
  },
  levelTypeSelector: {
    padding: '0.125rem'
  }
}))

const useAllocationChartData = ({ defaultFilter, defaultLevelType, rowFilters = [] }) => {
  const { clientId, availableDates, loadingAvailableDates } = useAppContext()
  const [levelType, setLevelType] = useState(defaultLevelType)

  const normalizedDatesQuery = useMemo(() => {
    return {
      dateRanges: {
        allocationChartDateRange: {
          key: 'AOD',
          startDate: availableDates.mainDate,
          endDate: availableDates.mainDate
        }
      }
    }
  }, [availableDates.mainDate])

  const {
    data: normalizedDateRanges,
    isLoading: isLoadingNormalizedDates
  } = useNormalizeDates(normalizedDatesQuery)

  const { publishFilters } = useWidgetContext()

  const allocationChartQuery = useMemo(() => {
    const levelFilters = {
      ...(defaultFilter || {}),
      levelTypes: ['client', levelType],
      clientIds: [clientId]
    }

    publishFilters((prevState) => {
      return {
        ...prevState,
        levelFilters
      }
    })

    return {
      query: {
        levelFilters,
        tableDateRanges: {
          balanceColumns: {
            ending: normalizedDateRanges?.allocationChartDateRange
          }
        },
        rowFilters
      },
      queryOptions: {
        enabled: !loadingAvailableDates && !isLoadingNormalizedDates
      }
    }
  }, [
    clientId,
    levelType,
    defaultFilter,
    publishFilters,
    normalizedDateRanges,
    loadingAvailableDates,
    isLoadingNormalizedDates,
    rowFilters
  ])

  const { data, isLoading } = useCoreTableData(
    allocationChartQuery.query,
    allocationChartQuery.queryOptions
  )

  return {
    data: data || [],
    isLoading,
    levelType,
    setLevelType
  }
}

const AllocationChart = ({
  title,
  defaultFilter: _defaultFilter,
  defaultLevelType,
  levelTypes,
  valueFormat,
  balanceFormat,
  maxItems,
  remainingClassificationsLabel,
  remainingClassificationsColor,
  defaultChartType,
  sortOrder,
  showLegend,
  pieInnerRadius,
  pieContainerStyles,
  pieChartHeight,
  hideZeroLevels
}) => {
  const classes = useStyles()

  const { subscribedFilters } = useWidgetContext()

  const defaultFilter = useMemo(() => {
    return Object.assign({}, _defaultFilter, subscribedFilters?.levelFilters)
  }, [_defaultFilter, subscribedFilters?.levelFilters])

  const rowFilters = hideZeroLevels ? [{ accessor: 'ending.endingValue', op: 'gt', value: 0 }] : []

  const {
    data,
    isLoading,
    levelType,
    setLevelType
  } = useAllocationChartData({ defaultFilter, defaultLevelType, levelTypes, rowFilters })

  const [chartType, setChartType] = useState(defaultChartType)

  if (!isLoading && isEmpty(data)) {
    return <EmptySection title='No Data Available' emptyDescription />
  }

  return (
    <div className={classes.allocationChart}>
      <div className={classes.header}>
        <div className={classes.title}>
          <span className={classes.titleCopy}>{title}</span>
          <Select
            value={levelType}
            className={classes.levelTypeSelector}
            onChange={setLevelType}
            options={levelTypes}
            showCheckMarOnSelectedItems
          />
        </div>
        <ChartTypeToggle selected={chartType} onClick={setChartType} />
      </div>
      <AllocationChartDiscriminator
        chartType={chartType}
        data={data}
        isLoading={isLoading}
        valueFormat={valueFormat}
        balanceFormat={balanceFormat}
        maxItems={maxItems}
        remainingClassificationsLabel={remainingClassificationsLabel}
        remainingClassificationsColor={remainingClassificationsColor}
        sortOrder={sortOrder}
        showLegend={showLegend}
        pieInnerRadius={pieInnerRadius}
        pieContainerStyles={pieContainerStyles}
        pieChartHeight={pieChartHeight}
        hideZeroLevels={hideZeroLevels}
      />
    </div>
  )
}

AllocationChart.propTypes = {
  title: PropTypes.string,
  defaultFilter: PropTypes.object,
  defaultLevelType: PropTypes.string,
  levelTypes: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string
  })),
  valueFormat: PropTypes.string,
  balanceFormat: PropTypes.string,
  maxItems: PropTypes.number,
  remainingClassificationsLabel: PropTypes.string,
  remainingClassificationsColor: PropTypes.string,
  defaultChartType: PropTypes.string,
  sortOrder: PropTypes.oneOf([
    'ENDINGVALUE_DESC',
    'ENDINGVALUE_ASC',
    'ORDINAL_DESC',
    'ORDINAL_ASC'
  ]),
  showLegend: PropTypes.bool,
  pieInnerRadius: PropTypes.number,
  pieContainerStyles: PropTypes.object,
  pieChartHeight: PropTypes.number,
  hideZeroLevels: PropTypes.bool
}

AllocationChart.defaultProps = {
  title: 'Allocation by',
  defaultFilter: {},
  defaultLevelType: 'account',
  levelTypes: [
    { label: 'Account', value: 'account' },
    { label: 'Class', value: 'assetClassTag' },
    { label: 'Asset', value: 'asset' }
  ],
  valueFormat: 'allocation',
  balanceFormat: 'marketValue',
  maxItems: 5,
  remainingClassificationsLabel: 'Everything Else',
  remainingClassificationsColor: undefined,
  defaultChartType: 'pie',
  sortOrder: 'ENDINGVALUE_DESC',
  showLegend: true,
  hideZeroLevels: true
}

export default AllocationChart
