import React from 'react'
import PropTypes from 'prop-types'
import TasksContextProvider from './TasksContextProvider'
import TasksViewWrapper from './components/TasksViewWrapper'
import TasksViewContent from './components/TasksViewContent'

const TasksView = ({ tabOptions, overdueStartDate, featuredFilters, linkBaseUrl, excludeTaskStatuses }) => {
  return (
    <TasksContextProvider
      tabOptions={tabOptions}
      linkBaseUrl={linkBaseUrl}
      featuredFilters={featuredFilters}
      overdueStartDate={overdueStartDate}
      excludeTaskStatuses={excludeTaskStatuses}
    >
      <TasksViewWrapper>
        <TasksViewContent />
      </TasksViewWrapper>
    </TasksContextProvider>
  )
}

TasksView.propTypes = {
  tabOptions: PropTypes.object,
  linkBaseUrl: PropTypes.string,
  featuredFilters: PropTypes.array,
  overdueStartDate: PropTypes.string,
  excludeTaskStatuses: PropTypes.array
}

TasksView.defaultProps = {
  overdueStartDate: '$prevYearStart',
  excludeTaskStatuses: ['Completed']
}

export default TasksView
