import React, { createContext, useCallback, useContext, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import { localStorageHelper } from '../../../../utils/localStorageHelper'

export const ChartOptionsContext = createContext({
  options: {},
  setOptions: noop,
  localStorageKey: null
})

export const useChartOptions = () => {
  return useContext(ChartOptionsContext)
}

export const useInterceptedOptions = (obj, force) => {
  const { options } = useChartOptions()
  return useMemo(() => {
    const result = Object.entries(obj).reduce((prev, [key, value]) => {
      prev[key] = (key in options) ? options[key] : value
      return prev
    }, {})

    if (force?.length) {
      force.forEach(f => {
        result[f] = options?.[f] ?? result[f]
      })
    }

    return result
  }, [options, obj, force])
}

function ChartOptionsProvider ({ children, initial, localStorageKey }) {
  const initialValue = useMemo(() => {
    return localStorageHelper.tryLoad(localStorageKey) || initial
  }, [initial, localStorageKey])

  const [options, _setOptions] = useState(initialValue)
  const setOptions = useCallback((opt) => {
    _setOptions(prev => {
      const newValue = {
        ...prev,
        ...(opt || {})
      }
      localStorageHelper.store(localStorageKey, {
        ...prev,
        ...(opt || {})
      })
      return newValue
    })
  }, [_setOptions, localStorageKey])
  const getStorageValue = useCallback((defaultValue) => {
    return localStorageHelper.tryLoad(localStorageKey) || defaultValue
  }, [localStorageKey])
  return (
    <ChartOptionsContext.Provider value={{ options, setOptions, localStorageKey, getStorageValue }}>
      {children}
    </ChartOptionsContext.Provider>
  )
}

ChartOptionsProvider.propTypes = {
  children: PropTypes.node,
  initial: PropTypes.any,
  localStorageKey: PropTypes.string
}

ChartOptionsProvider.defaultProps = {
  initial: {}
}

export default ChartOptionsProvider
