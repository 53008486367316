import React, { useEffect } from 'react'
import { useOktaAuth } from '@okta/okta-react'
import { useHistory } from 'react-router-dom'
import PublicLayout from '../../molecules/PublicLayout'
import { usePublicStyles } from './usePublicStyles'
import { useFirmInfo } from './hooks'

/* When rendered, will sign the user out of the application */
function Signout () {
  const { oktaAuth } = useOktaAuth()
  const history = useHistory()
  const classes = usePublicStyles()
  const firmInfo = useFirmInfo()

  useEffect(() => {
    if (oktaAuth) {
      oktaAuth.signOut({
        revokeAccessToken: true,
        revokeRefreshToken: true,
        clearTokensBeforeRedirect: true
      }).catch(err => {
        console.error('error signing out', err)
        history.push('/')
      })
    }
  }, [oktaAuth, history])

  return (
    <PublicLayout className={classes.layout} backgroundPath={firmInfo?.backgroundPath}>
      <div className={classes.signoutMessage}>Signing out</div>
    </PublicLayout>
  )
}

export default Signout
