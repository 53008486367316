import React, { useCallback } from 'react'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import {
  Button,
  TableCell as MuiTableCellCore,
  Checkbox
} from '@material-ui/core'
import noop from 'lodash/noop'
import {
  SIZE_VARIANTS,
  TABLE_VARIANTS,
  TEXT_ALIGNMENTS,
  TEXT_VARIANTS
} from '../../constants'
import { simpleTableRowCellDefaults } from '../../prop-types/tables'
import Text from '../atoms/Text'
import ClientAvatar from './ClientAvatar'
import NumberFormat from './NumberFormat'

const MuiTableCell = ({ component, ...props }) => (
  <MuiTableCellCore {...props} {...(component ? { component } : {})} />
)

export const useCellStyles = makeStyles((theme) => ({
  cell: ({ virtualized, width, alignment }) => ({
    flex: 1,
    ...(virtualized
      ? {
        flex: width ? `1 1 ${width}` : '1',
        display: 'flex',
        alignItems: 'center'
      }
      : {}),
    ...(width ? { width } : {}),
    ...(alignment ? { justifyContent: alignment } : {}),
    border: 'none'
  }),
  firstCell: {
    borderRadius: '10px 0 0 10px'
  },
  headSortLabel: ({ padding, alignment, virtualized }) => ({
    padding: padding || '12px',
    width: '100%',
    ...(alignment && virtualized
      ? { justifyContent: `${alignment} !important` }
      : {})
  }),
  headLabel: ({ padding, variant }) => ({
    padding: padding || '12px',
    width: '100%',
    ...(variant === TABLE_VARIANTS.floatingHeader
      ? {
        '& > *': {
          fontSize: '0.875rem'
        },
        padding: '0.5rem 1rem 0.675rem 1rem',
        borderRadius: '0.25rem'
      }
      : {})
  }),
  lastCell: {
    borderRadius: '0 10px 10px 0'
  },
  narrowCell: ({ padding }) => ({
    padding: padding || '12px'
  }),
  noPadding: {
    padding: 0
  }
}))

const useStyles = makeStyles((theme) => ({
  avatar: {
    paddingRight: `${theme.spacing(1)}px`
  },
  actionCell: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    border: 'none'
  },
  button: {
    borderRadius: '30px',
    fontWeight: 800,
    textTransform: 'capitalize'
  },
  checkbox: {
    color: `${theme.palette.primary.main} !important`
  },
  linkContainer: {
    textDecoration: 'none',
    color: 'inherit',
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  deleteButton: {
    backgroundColor: theme.palette.lightCoral,
    color: theme.palette.error.main,
    '&:hover': {
      backgroundColor: '#f3b3be'
    }
  },
  disabledButton: {
    cursor: 'not-allowed !important'
  },
  editButton: {
    backgroundColor: theme.palette.gray.light,
    color: theme.palette.lightBlack,
    '&:hover': {
      backgroundColor: theme.palette.gray.dark
    },
    marginRight: '5px'
  },
  finalRowCell: {
    paddingTop: '30px',
    borderBottom: 'none !important'
  },
  inlineCell: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center'
  }
}))

const {
  alignment: defaultRowAlignment,
  avatarSrc: defaultAvatarSrc,
  color: defaultRowColor,
  variant: defaultRowVariant,
  withAvatar: defaultWithAvatar
} = simpleTableRowCellDefaults

const defaultCellValues = {
  alignment: defaultRowAlignment,
  avatarSrc: defaultAvatarSrc,
  color: defaultRowColor,
  variant: defaultRowVariant,
  withAvatar: defaultWithAvatar
}

function getCellValues (cell) {
  if (typeof cell !== 'object') return { ...defaultCellValues, value: cell }
  return { ...defaultCellValues, ...cell }
}

function TableCell ({
  cell,
  checkedCells,
  elementType,
  tooltip,
  disabledDelete,
  disabledEdit,
  isFinalRow,
  isFirstCell,
  isLastCell,
  onHandleDelete,
  onHandleEdit,
  onHandleCheck,
  virtualized,
  showDeleteButton
}) {
  const classes = useStyles()
  const cellClasses = useCellStyles({
    virtualized,
    width: cell.width,
    padding: cell.spacing,
    alignment: cell.alignment
  })

  const onEdit = useCallback(
    (props) => {
      onHandleEdit(props)
    },
    [onHandleEdit]
  )
  const onDelete = useCallback(
    (props) => {
      onHandleDelete(props)
    },
    [onHandleDelete]
  )

  const { hasLink = false, to } = cell || {}
  const Container = hasLink ? Link : 'div'

  if (cell?.isEditableActions) {
    return (
      <MuiTableCell
        component={elementType}
        alignment={TEXT_ALIGNMENTS.left}
        className={clsx(cellClasses.cell, cellClasses.narrowCell, {
          [cellClasses.lastCell]: isLastCell,
          [cellClasses.firstCell]: isFirstCell
        })}
      >
        <Container
          className={clsx({ [classes.container]: hasLink }, classes.actionCell)}
          {...(hasLink && { to })}
        >
          <Button
            className={clsx(classes.button, classes.editButton, {
              [classes.disabledButton]: disabledEdit || cell.disabledEdit
            })}
            disabled={disabledEdit || cell.disabledEdit}
            onClick={() => onEdit(cell.editableProps)}
          >
            Edit
          </Button>
          {showDeleteButton && (
            <Button
              className={clsx(classes.button, classes.deleteButton, { [classes.disabledButton]: disabledDelete || cell.disabledDelete })}
              onClick={() => onDelete(cell.editableProps)}
              disabled={disabledDelete || cell.disabledDelete}
            >
              Delete
            </Button>
          )}
        </Container>
      </MuiTableCell>
    )
  }

  const {
    color,
    value,
    hidden,
    variant,
    alignment,
    withAvatar,
    withCheckbox,
    avatarSource,
    isPublicAvatar = false,
    useOneAvatarInitial,
    cellId,
    clientAbbreviation,
    shortName
  } = getCellValues(cell)

  const client = { clientAbbreviation, shortName }

  if (hidden) {
    return null
  }

  return (
    <MuiTableCell
      align={isFirstCell ? TEXT_ALIGNMENTS.left : alignment}
      component={isFirstCell && !elementType ? 'th' : elementType}
      className={clsx(cellClasses.cell, cellClasses.narrowCell, {
        [classes.finalRowCell]: isFinalRow,
        [cellClasses.lastCell]: isLastCell,
        [cellClasses.firstCell]: isFirstCell
      })}
    >
      <Container
        className={clsx({
          [classes.linkContainer]: hasLink,
          [classes.inlineCell]: withAvatar || withCheckbox
        })}
        {...(hasLink && { to })}
      >
        {withAvatar && (
          <div className={classes.avatar}>
            {avatarSource ? (
              <ClientAvatar
                size={SIZE_VARIANTS.small}
                src={avatarSource}
                isPublicResource={isPublicAvatar}
                client={client}
              />
            ) : (
              <ClientAvatar
                size={SIZE_VARIANTS.small}
                useOneInitial={useOneAvatarInitial}
                isPublicResource={isPublicAvatar}
                client={client}
              />
            )}
          </div>
        )}
        {withCheckbox && (
          <Checkbox
            checked={checkedCells.includes(cellId)}
            onChange={(event) => onHandleCheck(cellId, event.target.checked)}
            name={`cell-${cellId}`}
            classes={{
              root: classes.checkbox
            }}
          />
        )}
        <Text
          text={
            tooltip && tooltip.title ? (
              <NumberFormat
                title={tooltip.title}
                format={tooltip.format}
                number={value}
              />
            ) : value
          }
          variant={isFirstCell || isFinalRow ? TEXT_VARIANTS.body2 : variant}
          customFontSize={isFirstCell || isFinalRow ? '14px' : ''}
          color={color}
        />
      </Container>
    </MuiTableCell>
  )
}

MuiTableCell.propTypes = {
  component: PropTypes.string
}

TableCell.propTypes = {
  checkedCells: PropTypes.arrayOf(PropTypes.any),
  cell: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.shape({
      alignment: PropTypes.oneOf(Object.values(TEXT_ALIGNMENTS)),
      avatarSrc: PropTypes.string,
      color: PropTypes.string,
      editableProps: PropTypes.shape({}),
      hasLink: PropTypes.bool,
      isDate: PropTypes.bool,
      isPublicAvatar: PropTypes.bool,
      isEditableActions: PropTypes.bool,
      to: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      variant: PropTypes.oneOf(Object.values(TEXT_VARIANTS)),
      withAvatar: PropTypes.bool
    })
  ]).isRequired,
  elementType: PropTypes.string,
  disabledDelete: PropTypes.bool,
  disabledEdit: PropTypes.bool,
  isFinalRow: PropTypes.bool,
  isFirstCell: PropTypes.bool,
  isLastCell: PropTypes.bool,
  onHandleEdit: PropTypes.func,
  onHandleDelete: PropTypes.func,
  onHandleCheck: PropTypes.func,
  tooltip: PropTypes.any,
  virtualized: PropTypes.bool,
  showDeleteButton: PropTypes.bool
}

TableCell.defaultProps = {
  checkedCells: [],
  elementType: null,
  disabledDelete: false,
  disabledEdit: false,
  hasLink: false,
  isFirstCell: false,
  isLastCell: false,
  isFinalRow: false,
  onHandleEdit: noop,
  onHandleDelete: noop,
  onHandleCheck: noop,
  to: '',
  tooltip: null,
  virtualized: false,
  showDeleteButton: true
}

export default TableCell
