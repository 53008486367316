import { createContext, useCallback, useContext, useEffect, useMemo } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { KEYS } from '../../../../constants'
import { getSuppression } from '../../../../utils/globalSuppression'
import getViewPosition from './getViewPositions'

export const DashboardNavigationContext = createContext({})

class NavigationManager {
  constructor (data, actions) {
    this.data = data
    this.actions = actions
  }

  getViewPosition (view) {
    if (!this.data) {
      return { top: 0, left: 0 }
    }
    return this.data.find(x => x.view === view).position
  }

  getMiniPosition (view) {
    if (!this.data) {
      return { top: 0, left: 0 }
    }
    return this.data.find(x => x.view === view).miniPosition
  }

  getNextItem (path) {
    return this.data.find(x => path.includes(`/${x.view}`)).next
  }

  getPrevItem (path) {
    return this.data.find(x => path.includes(`/${x.view}`)).prev
  }

  goHome () {
    this.actions.goHome()
  }
}

export const useNavigationManager = (dashboardItems, root) => {
  const history = useHistory()
  const { pathname } = useLocation()

  const goHome = useCallback(() => {
    history.push(root)
  }, [history, root])
  const valuesManager = useMemo(() => {
    const count = dashboardItems.length
    const results = dashboardItems.map((item, index) => {
      return {
        position: getViewPosition(count, index),
        miniPosition: getViewPosition(count, index, false),
        view: item.props.viewPath
      }
    })
    const linkedResults = results.map((item, index) => {
      if (results.length === 1) {
        return {
          ...item,
          next: item.view,
          prev: item.view
        }
      }
      if (index === 0) {
        return {
          ...item,
          next: results[index + 1].view,
          prev: results[results.length - 1].view
        }
      }
      if (index === results.length - 1) {
        return {
          ...item,
          next: results[0].view,
          prev: results[index - 1].view
        }
      }
      return {
        ...item,
        next: results[index + 1].view,
        prev: results[index - 1].view
      }
    })

    const actions = {
      goHome
    }

    return new NavigationManager(linkedResults, actions)
  }, [dashboardItems, goHome])

  const keyHandler = useCallback((evt) => {
    if (getSuppression('wheel')) return

    let isEscape = false
    if ('key' in evt) {
      isEscape = evt.key === 'Escape' || evt.key === 'Esc'
    } else {
      isEscape = evt.keyCode === KEYS.ESC
    }
    const isRoot = pathname === root
    if (!isRoot && isEscape) {
      evt.stopPropagation()
      evt.preventDefault()
      history.push(root)
    }
    if (!isRoot && evt.key === 'ArrowLeft') {
      history.push(valuesManager.getPrevItem(pathname))
    }
    if (!isRoot && evt.key === 'ArrowRight') {
      history.push(valuesManager.getNextItem(pathname))
    }
  }, [history, pathname, root, valuesManager])

  useEffect(() => {
    window.addEventListener('keydown', keyHandler)
    return () => {
      window.removeEventListener('keydown', keyHandler)
    }
  }, [keyHandler])

  return valuesManager
}

export const useDashboardNavigation = () => {
  return useContext(DashboardNavigationContext)
}
