import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { Box, makeStyles } from '@material-ui/core'
import isEmpty from 'lodash/isEmpty'
import numeral from 'numeral'
import { CALC_TYPES, DATE_TYPES, LEVEL_TYPES, TEXT_VARIANTS } from '../../constants'
import { useFetchState } from '../../hooks'
import { useAppContext } from '../../redux/slices/appContext'
import { fetchAllocationHistory } from '../../service'
import Tag from '../atoms/Tag'
import Text from '../atoms/Text'
import StreamChart from './StreamChart'

const useStyles = makeStyles((theme) => ({
  accordionSummary: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  accordionTitle: {
    color: theme.palette.cloudBurst
  }
}))

const PortfolioAllocationHistoryStreamChart = ({
  fetchParams,
  viewFilters,
  viewContext,
  showFilteredTag
}) => {
  const classes = useStyles()
  const appContext = useAppContext()
  const { streamChartDate, assetClassType, mainDate } = viewContext

  const fetchStreamChartData = useCallback(
    async (setSafeState) => {
      const assetClassTagIds =
        viewFilters?.ASSET_CLASSES && assetClassType === 'class'
          ? [viewFilters?.ASSET_CLASSES?.id]
          : []

      const subclassTagIds =
        viewFilters?.ASSETS_SUBCLASS && assetClassType !== 'class'
          ? [viewFilters?.ASSETS_SUBCLASS?.id]
          : []

      const levelTypes = [
        LEVEL_TYPES.CLIENT,
        assetClassType === 'class'
          ? LEVEL_TYPES.ASSET_CLASS_TAG
          : LEVEL_TYPES.SUBCLASS_TAG
      ]

      const { data } = await fetchAllocationHistory({
        startDate: streamChartDate,
        endDate: mainDate,
        clientIds: [appContext.clientId],
        dateType: DATE_TYPES.month,
        assetClassTagIds,
        subclassTagIds,
        levelTypes,
        calcType: CALC_TYPES.timeSeries,
        ...(isEmpty(fetchParams) ? {} : fetchParams)
      })
      setSafeState({
        streamChartData: data.sort(
          (assetA, assetB) =>
            parseFloat(assetB.ordinal) + parseFloat(assetA.ordinal)
        )
      })
    },
    [
      appContext.clientId,
      streamChartDate,
      assetClassType,
      viewFilters,
      fetchParams,
      mainDate
    ]
  )

  const { streamChartData } = useFetchState(fetchStreamChartData)

  return (
    <Box>
      <Box className={classes.accordionSummary}>
        <Text
          text='HISTORICAL ASSET ALLOCATION'
          className={classes.accordionTitle}
          customFontSize='1rem'
          customFontWeight='bold'
          customFontFamily='Gotham-Book'
          textTransform='uppercase'
          variant={TEXT_VARIANTS.subtitle1}
        />
        {showFilteredTag && (
          <Box ml={2}>
            <Tag label='Filtered' />
          </Box>
        )}
      </Box>
      <StreamChart
        series={streamChartData || []}
        axisLeft={{ format: (value) => numeral(value).format('0%') }}
        tooltipFormat={(value) => numeral(value).format('0%')}
      />
    </Box>
  )
}

PortfolioAllocationHistoryStreamChart.propTypes = {
  fetchParams: PropTypes.object,
  viewFilters: PropTypes.object,
  viewContext: PropTypes.object,
  showFilteredTag: PropTypes.bool
}

export default PortfolioAllocationHistoryStreamChart
