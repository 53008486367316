import React, { createContext, useCallback, useContext, useMemo, useState } from 'react'
import PropTypes from 'prop-types'

const StatusReportContext = createContext({})

export const useStatusReportContext = () => {
  return useContext(StatusReportContext)
}

function StatusReportContextProvider ({ children, config }) {
  const [currentFilter, setCurrentFilter] = useState(null)
  const [reportExpanded, setReportExpanded] = useState(false)
  const setFilter = useCallback((value) => {
    setCurrentFilter(value)
  }, [setCurrentFilter])
  const clearFilter = useCallback((value) => {
    setCurrentFilter(null)
  }, [setCurrentFilter])

  const contextManager = useMemo(() => {
    return {
      currentFilter,
      clearFilter,
      setFilter,
      config,
      toggleFilter: (statusId) => {
        if (currentFilter === statusId) {
          clearFilter()
        } else {
          setFilter(statusId)
        }
      },
      filter: (depth) => (statusId) => {
        if (currentFilter === null || currentFilter === undefined) return true

        if (config?.filtersAt?.includes(depth)) {
          return statusId === currentFilter
        }
        return true
      }
    }
  }, [currentFilter, clearFilter, setFilter, config])

  return (
    <StatusReportContext.Provider value={{ ...contextManager, reportExpanded, setReportExpanded }}>
      {children}
    </StatusReportContext.Provider>
  )
}

StatusReportContextProvider.propTypes = {
  children: PropTypes.node,
  config: PropTypes.shape({
    filtersAt: PropTypes.arrayOf(PropTypes.number),
    countItemsAt: PropTypes.arrayOf(PropTypes.number)
  })
}

export default StatusReportContextProvider
