import React, { useCallback, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import DebouncedInput from '../../../molecules/DebouncedInput'
import TextSearch from './TextSearch'
import ValueFilter from './ValueFilter'

const useStyles = makeStyles((theme) => ({
  advanced: {
    display: 'flex',
    flexDirection: 'row',
    gap: '8px',
    justifyContent: 'space-between',
    flexWrap: 'wrap'
  }
}))

function FilterControls ({ schema, onChange }) {
  const [advanced] = useState(true)
  const onMegaSearchChange = useCallback(v => {
    const textSearch = Object.entries(schema.columns).filter(([, x]) => x.search === true).reduce((prev, [key]) => {
      prev[key] = [{ op: 'contains', value: v }]
      return prev
    }, {})

    onChange(textSearch)
  }, [schema, onChange])

  const textRef = useRef()
  const valueRef = useRef()
  const onAdvancedChange = useCallback(() => {
    const textFilter = textRef.current.value
    const valueFilter = valueRef.current.value
    onChange({
      ...textFilter,
      ...valueFilter
    })
  }, [textRef, valueRef, onChange])

  const classes = useStyles()

  if (advanced) {
    return (
      <div className={classes.advanced}>
        <TextSearch ref={textRef} schema={schema} onChange={onAdvancedChange} />
        <ValueFilter ref={valueRef} schema={schema} onChange={onAdvancedChange} />
      </div>
    )
  }

  return (
    <div>
      <DebouncedInput
        placeholder='Search'
        onChange={onMegaSearchChange}
      />
    </div>
  )
}

FilterControls.propTypes = {
  schema: PropTypes.shape({
    columns: PropTypes.object
  }),
  onChange: PropTypes.func
}

export default FilterControls
