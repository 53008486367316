import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Controller, useForm } from 'react-hook-form'
import { Box, Button, CircularProgress, makeStyles } from '@material-ui/core'
import { isEmpty, noop } from 'lodash'
import FormInputText from '../../../../atoms/FormInputText'
import Select from '../../../../molecules/Select'
import Icon from '../../../../atoms/Icon'
import Text from '../../../../atoms/Text'
import { editWealthMissionValue, saveWealthMissionValue } from '../../../../../service'
import useWealthMissionValueOptions from '../useWealthMissionValueOptions'

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.5rem',
    width: '100%'
  },
  actions: {
    display: 'flex',
    gap: '0.5rem'
  },
  row: {
    display: 'flex',
    gap: '1rem',
    alignItems: 'center'
  },
  button: {
    borderRadius: '2rem',
    border: '1.5px solid #212945',
    backgroundColor: '#FFF',
    textTransform: 'none',
    padding: '0.5rem 1rem',
    '& > span': {
      fontSize: '0.75rem',
      lineHeight: '0.75rem',
      color: '#212945',
      fontWeight: 'bold'
    },
    '&:disabled': {
      opacity: '0.7'
    }
  },
  iconOption: {
    padding: '0.5rem 1rem',
    display: 'flex',
    cursor: 'pointer',
    gap: '0.5rem',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  placeholder: {
    color: 'inherit',
    fontWeight: '600',
    fontSize: 'small',
    textTransform: 'none',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }
}))

const defaultIcons = [
  'beTheChange',
  'education',
  'lovedOnes',
  'travel',
  'vacations'
]

const ClientValuesForm = ({
  onSave,
  onCancel,
  defaultValues,
  iconOptions: _iconOptions
}) => {
  const classes = useStyles()
  const { wealthMissionValueDefaults } = useWealthMissionValueOptions()

  const wealthMissionValueDefaultsOptions = useMemo(() => {
    if (isEmpty(wealthMissionValueDefaults)) {
      return []
    }
    return wealthMissionValueDefaults.map(
      ({ title, wealthMissionValueDefaultId }) => ({
        label: title,
        value: wealthMissionValueDefaultId
      })
    )
  }, [
    wealthMissionValueDefaults
  ])

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, isValid, isDirty }
  } = useForm({ defaultValues })

  const iconOptions = useMemo(() => {
    const uniqueIcons = [...new Set([..._iconOptions, ...defaultIcons])]
    return uniqueIcons.map((iconName) => ({
      label: iconName,
      value: iconName
    }))
  }, [_iconOptions])

  const onSubmit = useCallback(
    async (values) => {
      try {
        const { wealthMissionValueId, ...body } = values
        if (wealthMissionValueId) {
          await editWealthMissionValue(wealthMissionValueId, body)
        } else {
          await saveWealthMissionValue(body)
        }
      } catch (exception) {
        console.error(exception)
      } finally {
        onSave()
      }
    },
    [onSave]
  )

  const labelRenderer = useCallback(
    ({ selectedValue, placeholder }) => {
      if (!selectedValue) {
        return <Text className={classes.placeholder} text={placeholder} />
      }
      return (
        <div className={classes.iconOption}>
          <Icon name={selectedValue.value} customSize='2rem' />
        </div>
      )
    },
    [classes.iconOption, classes.placeholder]
  )

  const iconOptionsRenderer = useCallback(
    (option, onChange) => {
      return (
        <div
          onClick={(event) => onChange(event, option.value)}
          className={classes.iconOption}
        >
          {option.label}
          <Icon name={option.value} customSize='2rem' />
        </div>
      )
    },
    [classes.iconOption]
  )

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={classes.container}>
      <FormInputText
        name='title'
        label='Title'
        rules={{ required: true }}
        control={control}
      />
      <div className={classes.row}>
        <Controller
          control={control}
          rules={{ required: false }}
          render={({ field: { onChange, value } }) => (
            <Select
              placeholder='Select an icon'
              labelRenderer={labelRenderer}
              optionsRenderer={iconOptionsRenderer}
              options={iconOptions}
              value={value}
              onChange={(_, __, event) => onChange(event)}
              size='extraSmall'
            />
          )}
          name='icon'
        />
        <Controller
          control={control}
          rules={{ required: true }}
          render={({ field: { onChange, value } }) => (
            <Select
              placeholder='Select a wealth mission value'
              options={wealthMissionValueDefaultsOptions}
              value={value}
              onChange={(_, __, event) => onChange(event)}
              size='extraSmall'
            />
          )}
          name='wealthMissionValueDefaultId'
        />
      </div>
      <FormInputText
        label='Description'
        name='description'
        control={control}
        rows={4}
        maxRows={4}
        multiline
      />
      <div className={classes.actions}>
        <Button
          primary
          type='submit'
          disabled={isSubmitting || !isValid || !isDirty}
          className={classes.button}
        >
          Save
          {isSubmitting && (
            <Box ml='0.5rem'>
              <CircularProgress size='0.75rem' />
            </Box>
          )}
        </Button>
        <Button
          className={classes.button}
          onClick={onCancel}
          disabled={isSubmitting}
        >
          Cancel
        </Button>
      </div>
    </form>
  )
}

ClientValuesForm.propTypes = {
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  iconOptions: PropTypes.array,
  defaultValues: PropTypes.shape({
    levelId: PropTypes.number.isRequired,
    levelType: PropTypes.string.isRequired,
    icon: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string
  }).isRequired
}

ClientValuesForm.defaultProps = {
  onSave: noop,
  onCancel: noop,
  iconOptions: [],
  defaultValues: {
    levelId: null,
    levelType: null,
    icon: null,
    title: '',
    description: '',
    wealthMissionValueId: null,
    wealthMissionValueDefaultId: null
  }
}

export default ClientValuesForm
