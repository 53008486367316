import React, { useMemo, useRef } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import PropTypes from 'prop-types'
import { IconButton, makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import Icon from '../../../../atoms/Icon'
import { ICON_NAMES } from '../../../../../constants'
import { ItemTypes } from './helpers'

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '0.5em',
    width: '100%'
  },
  indicator: {
    marginRight: '1em',
    cursor: 'move'
  },
  dragIconButton: {
    cursor: 'move'
  }
}))

const DragAndDropListItem = ({
  id,
  index,
  children,
  className,
  onMoveItem,
  size = 'medium'
}) => {
  const ref = useRef(null)
  const classes = useStyles()

  const [{ handlerId }, drop] = useDrop({
    accept: ItemTypes.CARD,
    collect (monitor) {
      return {
        handlerId: monitor.getHandlerId()
      }
    },
    hover (item, monitor) {
      if (!ref.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect()
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      // Determine mouse position
      const clientOffset = monitor.getClientOffset()
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }
      // Time to actually perform the action
      onMoveItem(dragIndex, hoverIndex)
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex
    }
  })

  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.CARD,
    item: () => {
      return { id, index }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  })
  const opacity = isDragging ? 0 : 1
  const fontSize = useMemo(() => {
    switch (size) {
      case 'small':
        return '0.5rem'
      case 'medium':
        return '1rem'
      case 'large':
        return '1.5rem'
      default:
        return '1rem'
    }
  }, [size])
  drag(drop(ref))

  return (
    <div
      ref={ref}
      style={{ opacity, fontSize }}
      className={clsx(classes.container, { [className]: Boolean(className) })}
      data-handler-id={handlerId}
    >
      <div className={classes.indicator}>
        <IconButton className={classes.dragIconButton}>
          <Icon name={ICON_NAMES.dragIndicator} />
        </IconButton>
      </div>
      {children}
    </div>
  )
}

DragAndDropListItem.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onMoveItem: PropTypes.func,
  index: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium', 'large'])
}

export default DragAndDropListItem
