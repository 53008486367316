import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import noop from 'lodash/noop'
import isObject from 'lodash/isObject'
import isString from 'lodash/isString'
import { dateSelectDefaults, dateSelectProps, selectItemType } from '../../prop-types'
import { nearestDate } from '../../utils'
import { DATE_PRESET, BUTTON_SIZES } from '../../constants'
import { useAppContext } from '../../redux/slices/appContext'
import useDatePresetOptions from '../../hooks/useDatePresetOptions'
import Select from './Select'
import { getDefaultDateRangeOptions } from './RelativeDateSelect'

const DATE_FORMAT = 'YYYY-MM-DD'

export const getOption = (minDate, key, label, value) => ({
  key,
  label,
  rawValue: value,
  value: `${value}_${key}`,
  isDisabled: dayjs(value).valueOf() < dayjs(minDate).valueOf()
})

const convertToDate = (optionValues) =>
  optionValues.map(({ rawValue }) => {
    return dayjs(rawValue).isValid() ? new Date(rawValue) : rawValue
  })

const DateSelect = ({
  options: customOptions,
  initialValue,
  defaultValue,
  disabled,
  onChange: _onChange,
  selectedValue,
  size,
  addCustomDateOpt,
  showCheckMarOnSelectedItems
}) => {
  const { availableDates: { min } } = useAppContext()
  const { getDateByPresetValueKey } = useDatePresetOptions()
  const customOption = useMemo(() => {
    return {
      key: 'custom',
      label: 'Custom',
      rawValue: 'custom',
      value: 'custom',
      isDisabled: false
    }
  }, [])

  const value = useMemo(() => {
    return selectedValue || dayjs(defaultValue).subtract(1, 'day').format(DATE_FORMAT)
  }, [selectedValue, defaultValue])

  const defaultOptions = useMemo(() => {
    return [
      getOption(min, DATE_PRESET.Y, 'Yesterday', getDateByPresetValueKey(DATE_PRESET.Y)),
      getOption(min, DATE_PRESET.TW, 'This week', getDateByPresetValueKey(DATE_PRESET.TW)),
      getOption(min, DATE_PRESET.L7D, 'Last 7 days', getDateByPresetValueKey(DATE_PRESET.L7D)),
      getOption(min, DATE_PRESET.L30, 'Last 30 days', getDateByPresetValueKey(DATE_PRESET.L30)),
      getOption(min, DATE_PRESET.L3, 'Last 3 months', getDateByPresetValueKey(DATE_PRESET.L3)),
      getOption(min, DATE_PRESET.L6, 'Last 6 months', getDateByPresetValueKey(DATE_PRESET.L6)),
      getOption(min, DATE_PRESET.L12, 'Last 12 months', getDateByPresetValueKey(DATE_PRESET.L12)),
      getOption(min, DATE_PRESET.MD, 'Month to date', getDateByPresetValueKey(DATE_PRESET.MD)),
      getOption(min, DATE_PRESET.QD, 'Quarter to date', getDateByPresetValueKey(DATE_PRESET.QD)),
      getOption(min, DATE_PRESET.YD, 'Year to date', getDateByPresetValueKey(DATE_PRESET.YD)),
      getOption(min, DATE_PRESET.SI, 'Since Inception', getDateByPresetValueKey(DATE_PRESET.SI)),
      addCustomDateOpt && customOption
    ].filter(Boolean)
  },
  [min, addCustomDateOpt, getDateByPresetValueKey, customOption])

  const dateRangeOptions = useMemo(() => {
    if (customOptions.every(isString)) {
      return getDefaultDateRangeOptions(customOptions, defaultOptions)
    }
    return customOptions.map(({ key, label, value }) => {
      let optionValue = value

      if (!value) {
        optionValue = getDateByPresetValueKey(key)
      }

      if (key.toLowerCase() === 'custom') {
        return customOption
      }

      return getOption(min, key, label, optionValue)
    })
  }, [customOptions, min, getDateByPresetValueKey, defaultOptions, customOption])

  const { value: closesValue, defaultValue: closesDefaultValue } = useMemo(() => {
    let tmpValue = value
    let tmpDefaultValue = defaultValue
    let tmpInitialValue = initialValue

    const optionDates = convertToDate(dateRangeOptions)

    tmpValue = dateRangeOptions.find((option) => {
      const [, key] = (isObject(value) ? value?.suffix : value).split('_')
      return (
        option.value.startsWith(value) ||
          key === option.key ||
          option.key === value
      )
    })?.value || tmpValue

    if (!dateRangeOptions.find(option => option.value.startsWith(tmpValue))) {
      const dateValue = new Date(value)
      tmpValue = dateRangeOptions[nearestDate(optionDates, dateValue)]?.value || tmpValue
    }
    if (!dateRangeOptions.find(option => option.value.startsWith(value))) {
      const defaultDateValue = new Date(defaultValue)
      tmpDefaultValue = dateRangeOptions[nearestDate(optionDates, defaultDateValue)]?.value || tmpDefaultValue
    }
    if (dateRangeOptions.find(option => option.key === initialValue)) {
      tmpInitialValue = dateRangeOptions.find((option) => option.key === initialValue).value || initialValue
    }
    return { value: tmpInitialValue || tmpValue, defaultValue: tmpDefaultValue }
  }, [value, dateRangeOptions, defaultValue, initialValue])

  const onChange = useCallback((_, __, event) => {
    _onChange(event)
  }, [_onChange])

  return (
    <Select
      disabled={disabled}
      onChange={onChange}
      options={dateRangeOptions}
      value={closesValue || closesDefaultValue}
      size={size}
      showCheckMarOnSelectedItems={showCheckMarOnSelectedItems}
    />
  )
}

DateSelect.propTypes = {
  ...dateSelectProps,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  selectedValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.any,
    selectItemType
  ]),
  size: PropTypes.oneOf(Object.values(BUTTON_SIZES)),
  addCustomDateOpt: PropTypes.bool,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  showCheckMarOnSelectedItems: PropTypes.bool
}

DateSelect.defaultProps = {
  ...dateSelectDefaults,
  disabled: false,
  onChange: noop,
  selectedValue: undefined,
  size: BUTTON_SIZES.small,
  addCustomDateOpt: true,
  defaultValue: undefined,
  showCheckMarOnSelectedItems: true
}

export default DateSelect
