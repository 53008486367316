import React, { useCallback, useMemo } from 'react'
import { makeStyles, Grid, TextField } from '@material-ui/core'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { usePolicy } from '../../../../../hooks/usePolicy'
import FadeIn from '../../../../molecules/Transitions/FadeIn'
import { ADMIN_ROUTES } from '../../../../../constants'
import { useCreateClientMutation } from '../../../../../api/clients'
import SaveCancelFooter from '../SaveCancelFooter'
import { ClientDetailsForm } from '../ClientDetailsFormContext'

const useStyles = makeStyles((theme) => ({
  newClientForm: {
    display: 'flex',
    justifyContent: 'center',
    padding: '10px',
    '& p': {
      color: `${theme.palette.primary.dark}`
    }
  },
  wrap: {
    maxWidth: '900px',
    margin: '20px'
  },
  noAccess: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '300px',
    fontSize: theme.typography.fontSizes.h1
  },
  inputLabel: {
    color: theme.palette.black,
    fontWeight: 600,
    '& span': {
      color: theme.palette.black
    }
  },
  header: {
    fontSize: theme.typography.fontSizes.xl,
    marginBottom: '20px'
  }
}))

const useFormValues = () => {
  const defaultValues = useMemo(() => {
    return {
      shortName: '',
      longName: '',
      description: '',
      clientAbbreviation: ''
    }
  }, [])

  const formMethods = useForm({
    mode: 'onChange',
    defaultValues
  })

  return formMethods
}

function NewClient () {
  const classes = useStyles()
  const canCreate = usePolicy('admin_edit_clientinfo')
  const { handleSubmit, formState, ...formValues } = useFormValues()
  const history = useHistory()
  const { mutateAsync: createClient } = useCreateClientMutation()
  const labelProps = {
    className: classes.inputLabel
  }

  const onSave = useCallback(async (e) => {
    const onSuccess = async (form) => {
      const commandBody = {
        client: {
          clientAbbreviation: form.clientAbbreviation || null,
          description: form.description || null,
          longName: form.longName || null,
          shortName: form.shortName || null
        }
      }

      const saveResult = await createClient(commandBody)
      history.replace(`${ADMIN_ROUTES.CLIENTS}/${saveResult.clientId}`)
    }
    const onError = (errors) => {
      // eslint-disable-next-line no-throw-literal
      throw { validationErrors: errors }
    }
    const handler = handleSubmit(onSuccess, onError)
    await handler(e)
  }, [handleSubmit, createClient, history])

  const onCancel = useCallback(() => { history.push(ADMIN_ROUTES.CLIENTS) }, [history])

  if (!canCreate) {
    return (
      <FadeIn className={classes.noAccess}>
        You do not have access to this resource
      </FadeIn>
    )
  }

  return (
    <ClientDetailsForm defaultState={{ editing: true, section: 'new', saveDescription: 'Create Client' }}>
      <FormProvider handleSubmit={handleSubmit} formState={formState} {...formValues}>
        <FadeIn className={classes.newClientForm}>
          <div className={classes.wrap}>
            <header className={classes.header}>
              Fill out the following fields to create a client
            </header>
            <Grid container spacing={3}>
              <Grid item xs={12} md={12}>
                <Controller
                  name='shortName'
                  rules={{ required: true }}
                  render={({ field, fieldState }) => (
                    <TextField
                      label='Display Name'
                      fullWidth
                      helperText='Required, 30 max characters'
                      error={(formState.isSubmitted || fieldState.isDirty || fieldState.isTouched) && fieldState.error}
                      inputProps={field}
                      InputLabelProps={labelProps}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <Controller
                  name='longName'
                  rules={{ required: true }}
                  render={({ field, fieldState }) => (
                    <TextField
                      label='Full Name'
                      fullWidth
                      helperText='Required, 80 max characters'
                      error={(formState.isSubmitted || fieldState.isDirty || fieldState.isTouched) && fieldState.error}
                      inputProps={field}
                      InputLabelProps={labelProps}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <Controller
                  name='description'
                  render={({ field, fieldState }) => (
                    <TextField
                      label='Wealth Mission'
                      fullWidth
                      helperText='100 max characters'
                      error={(formState.isSubmitted || fieldState.isDirty || fieldState.isTouched) && fieldState.error}
                      inputProps={field}
                      InputLabelProps={labelProps}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <Controller
                  name='clientAbbreviation'
                  render={({ field, fieldState }) => (
                    <TextField
                      label='Client Abbreviation'
                      fullWidth
                      helperText='3 max characters'
                      error={(formState.isSubmitted || fieldState.isDirty || fieldState.isTouched) && fieldState.error}
                      inputProps={field}
                      InputLabelProps={labelProps}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <SaveCancelFooter
              onSave={onSave}
              onCancel={onCancel}
            />
          </div>
        </FadeIn>
      </FormProvider>
    </ClientDetailsForm>
  )
}

export default NewClient
