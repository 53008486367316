import React from 'react'
import FadeIn from '../../../../molecules/Transitions/FadeIn'
import PersonalSpace from '../../shared/PersonalSpace'
import SecuritySummarySection from './SecuritySummarySection'
import SecurityDetailsSection from './SecurityDetailsSection'

function GeneralTab () {
  return (
    <FadeIn>
      <SecuritySummarySection />
      <SecurityDetailsSection />
      <PersonalSpace />
    </FadeIn>
  )
}

GeneralTab.propTypes = {
}

export default GeneralTab
