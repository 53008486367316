import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'
import DataHomeHeader from '../shared/DataHomeHeader'
import PlatformDataSource from './PlatformDataSource'

const useStyles = makeStyles(theme => ({
  dataSourceTag: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '2rem'
  },
  dataSourceList: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: '20px'
  }
}))

const TagGroup = ({ dataSourceTag, root }) => {
  const classes = useStyles()

  return (
    <div className={classes.dataSourceTag}>
      <DataHomeHeader>{dataSourceTag.name}</DataHomeHeader>
      <div className={classes.dataSourceList}>
        {dataSourceTag.dataSources.map(ds => (
          <Link key={ds.name} to={`${root}/source/${ds.platformDataSourceId}`}>
            <PlatformDataSource dataSource={ds} />
          </Link>
        ))}
      </div>
    </div>
  )
}

TagGroup.propTypes = {
  dataSourceTag: PropTypes.shape({
    name: PropTypes.string,
    dataSources: PropTypes.array
  }).isRequired,
  root: PropTypes.string
}

export default TagGroup
