import React, {
  useCallback,
  useImperativeHandle,
  useMemo,
  useState,
  forwardRef,
  useEffect
} from 'react'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'
import { makeStyles } from '@material-ui/core'
import isEmpty from 'lodash/isEmpty'
import first from 'lodash/first'
import { useAbundanceEngineViewByPathName, useAbundanceEngineViews } from '../../../api/abundanceEngine'
import { useSetAbundanceEngineContext } from '../../../redux/slices/abundanceEngineContext'
import { ABUNDANCE_ENGINE_PREFIX_PATH } from '../../../constants'
import AbundanceEngineContextProvider from '../../../abundanceEngine/components/AbundanceParameterContextProvider'
import AbundanceParser from '../../../abundanceEngine/AbundanceParser'
import ErrorView from './ErrorView'
import LoadingView from './LoadingView'

const useStyles = makeStyles(() => ({
}))

/* Using ...rest objects causes multiple object references to form on subsequent re-renders,
*  Normally this is probably not an issue, but it causes the JSX parser to totally
*  re-mount the abundance engine view */
const AbundanceEngineView = forwardRef((
  { viewPath: _viewPath, className },
  ref
) => {
  const classes = useStyles()
  const location = useLocation()
  const views = useAbundanceEngineViews()
  const setAbundanceEngineContext = useSetAbundanceEngineContext()

  const [viewProps, setViewProps] = useState(null)

  const viewPath = useMemo(() => {
    if (_viewPath) {
      return _viewPath
    }
    const viewPathName = location.pathname
      .substring(`/${ABUNDANCE_ENGINE_PREFIX_PATH}`.length)
      .replace('/', '')

    return viewPathName
  }, [_viewPath, location])

  const requestedViewPath = useMemo(() => {
    const defaultView = first(views)
    return viewPath || defaultView?.path
  }, [viewPath, views])

  const { isLoading: loading, error, data: viewData } = useAbundanceEngineViewByPathName(requestedViewPath)

  const abundanceEngineViewRef = useCallback(
    (node) => {
      if (node !== null) {
        const { ParsedChildren } = node
        const [parsedChild] = ParsedChildren || []
        const viewProperties = {
          viewName: viewData?.name,
          ...(parsedChild?.props || {})
        }
        setViewProps(viewProperties)
      }
    },
    [viewData?.name]
  )

  useEffect(() => {
    if (!isEmpty(viewProps)) {
      const { viewName } = viewProps
      setAbundanceEngineContext({
        currentView: {
          name: viewName,
          path: viewPath
        }
      })
    }
    return () => {
      setAbundanceEngineContext({
        currentView: {
          name: null,
          path: null
        }
      })
    }
  }, [viewProps, viewPath, setAbundanceEngineContext])

  useImperativeHandle(
    ref,
    () => ({
      viewProps,
      viewDefaultProps: viewData?.defaultProps
    }),
    [viewProps, viewData?.defaultProps]
  )

  if (error) return <ErrorView error={error} />

  if (loading) return <LoadingView />

  if (!viewData) return <ErrorView error='This content is currently unavailable.' />

  return (
    <AbundanceEngineContextProvider>
      <AbundanceParser
        jsx={viewData?.jsxView}
        className={className || classes.root}
        ref={abundanceEngineViewRef}
        jsxComponentsDefaultProps={viewData.defaultProps}
      />
    </AbundanceEngineContextProvider>
  )
})

AbundanceEngineView.propTypes = {
  viewPath: PropTypes.string,
  className: PropTypes.string
}

AbundanceEngineView.defaultProps = {
  viewPath: undefined,
  className: undefined
}

export default React.memo(AbundanceEngineView)
