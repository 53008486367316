import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import clsx from 'clsx'

const useStyles = makeStyles(() => ({
  section: {
    display: 'flex',
    flexDirection: 'column',
    margin: '0 0 1.625rem 0'
  }
}))

const SectionContainer = ({ className, style, children }) => {
  const classes = useStyles()
  return (
    <div className={clsx(classes.section, className)} style={style}>
      {children}
    </div>
  )
}

SectionContainer.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  style: PropTypes.object
}

export default SectionContainer
