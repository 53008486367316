import React, { useCallback, useMemo } from 'react'
import { IconButton, Tooltip, makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import { useContextualClientContext } from '../context/ContextualClientContext'
import Avatar from '../../../atoms/Avatar'
import { useClientAbbreviation } from '../../../../hooks'
import Text from '../../../atoms/Text'
import Icon from '../../../atoms/Icon'
import { getIntegrationTitle } from '../helpers'
import { useSetCurrentClient } from '../../../../redux/slices/appContext'
import { ICON_NAMES, REDTAIL_INTEGRATION } from '../../../../constants'
import { replaceDoubleBraces } from '../../../../utils'
import FastAvatar from '../../../molecules/FastAvatar'
import RedTailSyncButton from './RedTailSyncButton'

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '1rem',
    padding: '1.5rem 2rem 2rem'
  },
  title: {
    fontSize: '1.5rem'
  },
  avatar: {
    cursor: 'pointer'
  },
  actions: {
    display: 'flex',
    gap: '1rem',
    '& > button': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
      border: '1px solid transparent',
      '&:hover': {
        border: '1px solid black'
      }
    }
  }
}))

const ClientInfo = ({
  avatarClickRedirectTo,
  editClickRedirectTo
}) => {
  const classes = useStyles()
  const setCurrentClient = useSetCurrentClient()
  const { client, integrationsMeta, clientIntegrationData } = useContextualClientContext()
  const [clientAbbreviation, useAbbreviation] = useClientAbbreviation(client)

  const integrationMeta = useMemo(() => {
    if (!clientIntegrationData) return null
    return getIntegrationTitle(clientIntegrationData.key)
  }, [clientIntegrationData])

  const onNavigateTo = useCallback(
    (path, shouldSetClientGlobally = false) =>
      () => {
        if (!client || !path) return

        if (shouldSetClientGlobally) {
          setCurrentClient(client.clientId)
        }
        const pathWithParams = replaceDoubleBraces(path, client, path)
        window.open(pathWithParams, '_blank', 'noopener noreferrer')
      },
    [client, setCurrentClient]
  )

  const integrationRedirect = useMemo(() => {
    const redirectTo = integrationsMeta?.[clientIntegrationData?.key]?.redirectTo
    if (!redirectTo) return null

    return replaceDoubleBraces(redirectTo, client, redirectTo)
  }, [client, clientIntegrationData?.key, integrationsMeta])

  return (
    <div className={classes.container}>
      <div
        className={classes.avatar}
        onClick={onNavigateTo(avatarClickRedirectTo, true)}
      >
        <Avatar
          useOneInitial
          customSize='4rem'
          src={client?.profilePic}
          avatarLetters={clientAbbreviation}
          useAbbreviation={useAbbreviation}
        />
      </div>
      <Text
        className={classes.title}
        text={client?.longName}
        customFontWeight='bold'
      />
      {clientIntegrationData && (
        <div className={classes.actions}>
          {integrationMeta && (
            <Tooltip title={`Go to ${integrationMeta.title}`}>
              {integrationMeta.iconUrl ? (
                <IconButton onClick={onNavigateTo(integrationRedirect)}>
                  <FastAvatar size={integrationMeta.iconSize} avatarUrl={integrationMeta.iconUrl} />
                </IconButton>
              ) : (
                <IconButton onClick={onNavigateTo(integrationRedirect)}>
                  <Icon
                    name={integrationMeta.icon}
                    customSize={integrationMeta.iconSize}
                  />
                </IconButton>
              )}
            </Tooltip>
          )}
          <Tooltip title='Edit Client'>
            <IconButton onClick={onNavigateTo(editClickRedirectTo)}>
              <Icon name={ICON_NAMES.edit} customSize='1.5rem' />
            </IconButton>
          </Tooltip>
          {clientIntegrationData.key === REDTAIL_INTEGRATION && (
            <RedTailSyncButton
              clientId={client?.clientId}
              integrationName={integrationMeta.title}
            />
          )}
        </div>
      )}
    </div>
  )
}

ClientInfo.propTypes = {
  editClickRedirectTo: PropTypes.string,
  avatarClickRedirectTo: PropTypes.string
}

ClientInfo.defaultProps = {
  avatarClickRedirectTo: '/',
  editClickRedirectTo: '/admin/clients/{{clientId}}'
}

export default ClientInfo
