import React from 'react'
import PropTypes from 'prop-types'
import Tabs from '../../molecules/Tabs'
import { useTabGroup } from './tabContext'

/**
 * Tab Links
 * @param {string} key - used as localStorage key prefix
 * @param {JSX.Element} rightAdornment
 */
const TabLinks = ({
  rightAdornment,
  disabled
}) => {
  const { selectedValue, selectValue, options } = useTabGroup()
  return (
    <Tabs.Wrapper noMargin>
      <Tabs
        value={selectedValue}
        indicatorColor='primary'
        textColor='primary'
        onChange={(_, tabValue) => {
          selectValue(tabValue)
        }}
      >
        {options.map((o) => {
          return (
            <Tabs.Tab
              key={o.value}
              value={o.value}
              label={o.payload?.label || o.payload}
              disabled={o.payload?.disabled || disabled}
            />
          )
        })}
      </Tabs>
      {rightAdornment}
    </Tabs.Wrapper>
  )
}

TabLinks.propTypes = {
  rightAdornment: PropTypes.node,
  disabled: PropTypes.bool
}

TabLinks.defaultProps = {
  rightAdornment: undefined,
  disabled: false
}

export default TabLinks
