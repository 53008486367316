import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Box, IconButton, makeStyles } from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import Skeleton from '../atoms/Skeleton'

const useStyles = makeStyles(() => ({
  title: {
    display: 'flex',
    direction: 'row',
    alignItems: 'center',
    fontSize: '1.5rem',
    fontWeight: '700',
    marginBottom: '1.5rem'
  }
}))

const WealthJourneyCategoryHeader = ({
  isLoading,
  children,
  hovered,
  editMode,
  activitiesEmpty,
  onEdit,
  canEditActivities
}) => {
  const classes = useStyles()
  const showEditIcon = useMemo(() => hovered && !editMode && !activitiesEmpty && !isLoading && canEditActivities, [hovered, editMode, activitiesEmpty, isLoading, canEditActivities])

  if (isLoading) {
    return (
      <div className={classes.title}>
        <Skeleton height='2rem' width='100%' />
      </div>
    )
  }
  return (
    <div className={classes.title}>
      {children}
      {
        showEditIcon && (
          <Box ml='auto'>
            <IconButton aria-label='toggle wealth journey edit mode' size='small' onClick={onEdit}>
              <EditIcon fontSize='small' />
            </IconButton>
          </Box>
        )
      }
    </div>
  )
}

WealthJourneyCategoryHeader.propTypes = {
  isLoading: PropTypes.bool,
  children: PropTypes.node,
  hovered: PropTypes.bool,
  editMode: PropTypes.bool,
  activitiesEmpty: PropTypes.bool,
  onEdit: PropTypes.func.isRequired,
  canEditActivities: PropTypes.bool.isRequired
}

WealthJourneyCategoryHeader.defaultProps = {
  isLoading: false,
  hovered: false,
  editMode: false,
  activitiesEmpty: false
}

export default WealthJourneyCategoryHeader
