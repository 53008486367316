import React from 'react'
import PropTypes from 'prop-types'

import MuiDialogTitle from '@material-ui/core/DialogTitle'

const Header = ({ children, className }) => {
  return (
    <MuiDialogTitle className={className}>
      {children}
    </MuiDialogTitle>
  )
}

Header.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
}

export default React.memo(Header)
