import React, { useCallback, useRef, useState } from 'react'
import { makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import PageContainer from '../../dataHome/components/PageContainer'
import SydInput from '../../../commonDesign/SydInput'
import SydButton from '../../../commonDesign/Button'
import Card from '../../../molecules/Card'
import { useNLQuery, usePostNLQuery } from '../../../../api/denali'
import FadeIn from '../../../molecules/Transitions/FadeIn'

const useStyles = makeStyles((theme) => ({
  nlq: {
    paddingTop: theme.layout.padding.medium,
    paddingBottom: theme.layout.padding.medium,
    backgroundColor: theme.palette.background.paper,
    minHeight: 'calc(100vh - 96px)'
  },
  workArea: {
    margin: '0 auto',
    maxWidth: '1400px',
    width: '100%',
    backgroundColor: theme.palette.background.default
  },
  input: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: theme.layout.gap.medium
  },
  output: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.layout.gap.medium,
    marginBottom: theme.layout.margin.medium
  },
  outputItem: {
    fontWeight: theme.typography.weights.light,
    borderRadius: theme.layout.radius.loose,
    padding: theme.layout.padding.medium,
    border: '1px solid rgba(0, 0, 0, 0.3)'
  },
  prompt: {
    fontWeight: theme.typography.weights.bold,
    marginBottom: theme.layout.margin.medium
  },
  outputTable: {
    marginTop: theme.layout.margin.medium,
    marginBottom: theme.layout.margin.medium,
    borderCollapse: 'collapse',
    '& tr > td': {
      padding: theme.layout.padding.thin,
      border: '1px solid rgba(0, 0, 0, 0.3)'
    },
    '& tr > th': {
      padding: `0 ${theme.layout.padding.thin}`
    }
  }
}))

function NLQResponse ({ response }) {
  const classes = useStyles()
  const { prompt, result } = response
  return (
    <FadeIn className={classes.outputItem}>
      <div className={classes.prompt}>{prompt}</div>
      {result?.explanation ? (
        <div className={classes.explain}>{result.explanation}</div>
      ) : null}
      {result?.data ? (
        <table className={classes.outputTable}>
          <thead>
            <tr>
              {result?.columns?.map((c, i) => (
                <th key={`${c}_${i}`}>{c}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {result.data.map((d, i) => (
              <tr key={i}>
                {Object.entries(d).map(([key, value], pi) => (
                  <td key={`${key}-${pi}-${i}`}>{value}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      ) : null}
      {result?.summaryResult ? (
        <div className={classes.explain}>{result.summaryResult || result.summaryResult}</div>
      ) : null}
    </FadeIn>
  )
}

NLQResponse.propTypes = {
  response: PropTypes.any
}

function NLQIndex () {
  const classes = useStyles()
  const [submitting, setSubmitting] = useState(false)
  const inputRef = useRef()
  const { data, isLoading } = useNLQuery()
  const { mutateAsync: submitNlq } = usePostNLQuery()

  const onSubmit = useCallback(async () => {
    const prompt = inputRef.current.value

    if (prompt) {
      setSubmitting(true)
      try {
        await submitNlq({
          prompt
        })
        inputRef.current.clear()
      } finally {
        setSubmitting(false)
      }
    }
  }, [submitNlq, setSubmitting])

  const handleKeyPress = useCallback((e) => {
    if (e.keyCode === 13 && !e.shiftKey) {
      onSubmit()
    }
  }, [onSubmit])

  if (isLoading) {
    return <div>Loading...</div>
  }

  return (
    <PageContainer className={classes.nlq}>
      <Card className={classes.workArea}>
        <div className={classes.output}>
          {data.map((p, i) => (
            <NLQResponse key={i} response={p} />
          ))}
        </div>
        <div className={classes.input}>
          <SydInput disabled={submitting} size='sm' ref={inputRef} onKeyDown={handleKeyPress} />
          <SydButton
            processing={submitting}
            size='md'
            onClick={onSubmit}
          >
            Submit
          </SydButton>
        </div>
      </Card>
    </PageContainer>
  )
}

export default NLQIndex
