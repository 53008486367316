import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import Text from '../../../atoms/Text'
import Skeleton from '../../../atoms/Skeleton'
import { useFormattingContext } from '../../../organisms/FormattingProvider/FormattingContext'
import {
  COST_BASIS_COMBINED_BUCKETS,
  COST_BASIS_BUCKETS
} from './costBasisTileConfig'
import CostBasisSummaryBlock from './CostBasisSummaryBlock'

const useStyles = makeStyles((theme) => ({
  summaryTile: {
    border: `1px solid ${theme.palette.gray.darker}`,
    minHeight: '3rem',
    padding: '1.5rem 2rem',
    borderRadius: '5px',
    minWidth: '15rem'
  },
  total: {
    padding: 0,
    marginBottom: '1rem'
  },
  components: {
    display: 'flex',
    flexDirection: 'row',
    gap: '20px'
  },
  title: {
    ...theme.typography.body1,
    marginTop: '10px',
    marginBottom: '-10px'
  }
}))

const createCostBasis = ({ tileMap, item, formatter }) => {
  const gainLossValue = tileMap?.gainLoss(item)
  const gainValue = tileMap?.gain(item)
  const lossValue = tileMap?.loss(item)
  return {
    gainLoss: {
      short: formatter(gainLossValue),
      long: formatter(gainLossValue, 'default')
    },
    gain: {
      short: formatter(gainValue),
      long: formatter(gainValue, 'default')
    },
    loss: {
      short: formatter(lossValue),
      long: formatter(lossValue, 'default')
    }
  }
}

const createCombinedCostBasis = ({ tileMap, item, formatter }) => {
  const uglValue = tileMap?.UGL(item)
  const rglValue = tileMap?.RGL(item)
  return {
    UGL: {
      short: formatter(uglValue),
      long: formatter(uglValue, 'default')
    },
    RGL: {
      short: formatter(rglValue),
      long: formatter(rglValue, 'default')
    }
  }
}

const CostBasisSummaryTile = ({
  tileMap,
  item,
  loading,
  title,
  type,
  format: defaultFormat,
  summaryTileVisibility,
  summaryFormatConfiguration
}) => {
  const classes = useStyles()
  const isCombined = type === 'combined'

  const { formatter } = useFormattingContext(summaryFormatConfiguration)

  const tileFormatter = useCallback(
    (value, format = defaultFormat) => {
      return formatter(value, format)
    },
    [defaultFormat, formatter]
  )

  const costBasis = isCombined
    ? createCombinedCostBasis({ tileMap, item, formatter: tileFormatter })
    : createCostBasis({ tileMap, item, formatter: tileFormatter })

  const { costBasisCombinedBuckets, costBasisBuckets } = useMemo(() => {
    const combinedBucketKeys = Object.keys(COST_BASIS_COMBINED_BUCKETS).filter(
      (tileKey) => {
        return summaryTileVisibility?.[tileKey] !== false
      }
    )
    const bucketKeys = Object.keys(COST_BASIS_BUCKETS).filter((tileKey) => {
      return summaryTileVisibility?.[tileKey] !== false
    })
    return {
      costBasisCombinedBuckets: combinedBucketKeys,
      costBasisBuckets: bucketKeys
    }
  }, [summaryTileVisibility])

  if (loading) {
    return <Skeleton height='10rem' width='15rem' />
  }

  return (
    <div className={classes.summaryTile}>
      <div className={classes.total}>
        <Text
          text={title}
          lineHeight='1'
          variant='body2'
          customFontWeight='bold'
          customFontSize='1rem'
        />
      </div>
      <div className={classes.components}>
        {isCombined
          ? costBasisCombinedBuckets.map((tileKey) => (
            <CostBasisSummaryBlock
              key={tileKey}
              title={COST_BASIS_COMBINED_BUCKETS[tileKey]}
              tooltipTitle={costBasis[tileKey].long}
              value={costBasis[tileKey].short}
            />
          ))
          : costBasisBuckets.map((tileKey) => (
            <CostBasisSummaryBlock
              key={tileKey}
              title={COST_BASIS_BUCKETS[tileKey]}
              tooltipTitle={costBasis[tileKey].long}
              value={costBasis[tileKey].short}
            />
          ))}
      </div>
      {tileMap.title ? (
        <div className={classes.title}>{tileMap.title}</div>
      ) : null}
    </div>
  )
}

CostBasisSummaryTile.propTypes = {
  tileMap: PropTypes.object.isRequired,
  title: PropTypes.string,
  item: PropTypes.object,
  loading: PropTypes.bool,
  type: PropTypes.string,
  format: PropTypes.string,
  summaryTileVisibility: PropTypes.object,
  summaryFormatConfiguration: PropTypes.object
}

export default CostBasisSummaryTile
