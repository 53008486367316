import React, { useCallback, useEffect, useState } from 'react'
import { InputBase, InputLabel, makeStyles, withStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import { Controller, useForm } from 'react-hook-form'
import CustomizeColumnsDialog from '../GroupingCustomizeColumnsDialog/CustomizeColumnsDialog'
import BlockButtonGroup from '../../../../molecules/BlockButtonGroup'
import { useGroupingContext } from '../GroupingContext'
import { useCSV } from '../../../../../hooks'
import 'jspdf-autotable'
import { COLUMN_OPTIONS, FORMAT_TYPES, downloadPDFFile, mapCSVData } from './helpers'

const useStyles = makeStyles((theme) => ({
  section: {
    marginBottom: '1.5rem'
  },
  label: {
    fontSize: '1rem',
    color: '#141929',
    marginBottom: '1.5rem',
    '& span': {
      color: theme.palette.error.primary
    }
  }
}))

const Input = withStyles((theme) => ({
  root: {
    width: '100%',
    '&.Mui-error input': {
      borderColor: theme.palette.error.primary
    }
  },
  input: {
    padding: '1rem 0.75rem',
    borderRadius: '4px',
    background: '#F4F5F6',
    border: '2px solid #F4F5F6',
    '&:focus': {
      borderColor: '#212945'
    }
  }
}))(InputBase)

const ExportTableDialog = ({
  isOpen,
  toggleDialog,
  defaultValues,
  hideExportTypePicker,
  hideColumnsVisibilityTypePicker
}) => {
  const classes = useStyles()
  const [formatType, setFormatType] = useState(FORMAT_TYPES.CSV.value)
  const [columnExportType, setColumnExportType] = useState(COLUMN_OPTIONS.VISIBLE_COLUMNS.value)
  const { performanceTableProps, groupingDateRanges } = useGroupingContext()
  const { allColumns, flatRows } = performanceTableProps || {}

  const {
    reset,
    control,
    setFocus,
    register,
    formState: { errors },
    handleSubmit
  } = useForm({
    defaultValues,
    mode: 'onChange',
    reValidateMode: 'onChange'
  })

  useEffect(() => {
    if (!isOpen) {
      reset({ ...defaultValues })
    }
  }, [isOpen, reset, defaultValues])

  useEffect(() => {
    const firstError = Object.keys(errors).reduce((field, fieldError) => {
      return errors[field] ? field : fieldError
    }, null)

    if (firstError) {
      setFocus(firstError)
    }
  }, [errors, setFocus])

  const { downloadCSVFile } = useCSV()

  const getCsvData = useCallback(() => {
    const { ALL_COLUMNS } = COLUMN_OPTIONS
    const shouldIncludeAllColumns = columnExportType === ALL_COLUMNS.value
    if (!allColumns && !flatRows) {
      return []
    }
    return mapCSVData(
      { allColumns, flatRows },
      groupingDateRanges,
      shouldIncludeAllColumns
    )
  }, [columnExportType, groupingDateRanges, allColumns, flatRows])

  const onSubmit = useCallback(
    ({ exportFileName }) => {
      const fileExtension = formatType.toLowerCase()
      const fileName = exportFileName
        ? `${exportFileName}.${fileExtension}`
        : `summit_export.${fileExtension}`

      const csvData = getCsvData()

      if (formatType === FORMAT_TYPES.CSV.value) {
        const [headers, ...data] = csvData
        downloadCSVFile({ data, headers, fileName })
      }
      if (formatType === FORMAT_TYPES.PDF.value) {
        downloadPDFFile({ data: csvData, fileName })
      }
      toggleDialog.off()
    },
    [getCsvData, toggleDialog, downloadCSVFile, formatType]
  )

  return (
    <CustomizeColumnsDialog
      title='Export Table'
      primaryButtonLabel='Export'
      secondaryButtonLabel='Close'
      isOpen={isOpen}
      toggler={toggleDialog}
      onConfirm={handleSubmit(onSubmit)}
      maxWidth='sm'
    >
      {!hideExportTypePicker && (
        <div className={classes.section}>
          <InputLabel className={classes.label}>
            Which format would you like to use?
          </InputLabel>
          <BlockButtonGroup
            selectedValue={formatType}
            onSelectOption={setFormatType}
          >
            {Object.values(FORMAT_TYPES).map(({ value, label }) => (
              <BlockButtonGroup.Option key={value} value={value}>
                {label}
              </BlockButtonGroup.Option>
            ))}
          </BlockButtonGroup>
        </div>
      )}
      {!hideColumnsVisibilityTypePicker && (
        <div className={classes.section}>
          <InputLabel className={classes.label}>
            What do you want to export?
          </InputLabel>
          <BlockButtonGroup
            selectedValue={columnExportType}
            onSelectOption={setColumnExportType}
          >
            {Object.values(COLUMN_OPTIONS).map(({ value, label }) => (
              <BlockButtonGroup.Option key={value} value={value}>
                {label}
              </BlockButtonGroup.Option>
            ))}
          </BlockButtonGroup>
        </div>
      )}
      <div>
        <InputLabel className={classes.label} required>
          File Name
        </InputLabel>
        <Controller
          name='exportFileName'
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <Input
              {...field}
              placeholder='file name'
              ref={register('exportFileName').ref}
              error={errors?.exportFileName}
            />
          )}
        />
      </div>
    </CustomizeColumnsDialog>
  )
}

ExportTableDialog.propTypes = {
  isOpen: PropTypes.bool,
  toggleDialog: PropTypes.object.isRequired,
  hideExportTypePicker: PropTypes.bool,
  hideColumnsVisibilityTypePicker: PropTypes.bool,
  defaultValues: PropTypes.object
}

ExportTableDialog.defaultProps = {
  isOpen: false,
  hideExportTypePicker: false,
  hideColumnsVisibilityTypePicker: false,
  defaultValues: {
    exportFileName: ''
  }
}

export default ExportTableDialog
