import React, { useCallback, useMemo, useState } from 'react'
import { makeStyles } from '@material-ui/core'
import { useFormContext } from 'react-hook-form'
import KeyMirror from 'keymirror'
import Section from '../customizeDialog/section'
import InputColorPicker from '../../../../molecules/InputColorPicker'
import FileUploadInput from './fileUploadInput'

const useStyles = makeStyles(() => ({
  control: {
    marginBottom: '2rem'
  },
  label: {
    color: '#212945',
    fontSize: '1rem',
    fontWeight: 'bold',
    paddingBottom: '0.75rem'
  },
  inputColor: {
    padding: '1rem 1rem 0.75rem 1rem',
    borderRadius: '4.8px',
    border: '2.4px solid #EEE',
    background: '#FFF'
  }
}))

const FILE_INPUT = KeyMirror({
  logo: null,
  backgroundImage: null
})

const CustomizePresentationForm = () => {
  const classes = useStyles()
  const { watch, setValue } = useFormContext()

  const { setLogo, setBackgroundImage, setPrimaryColor, setSecondaryColor } =
    useMemo(() => {
      return {
        setLogo: (value) => {
          setValue('logoMediaId', value?.mediaId)
          setValue('logoPreview', value?.preview)
        },
        setBackgroundImage: (value) => {
          setValue('backgroundImageMediaId', value?.mediaId)
          setValue('backgroundImagePreview', value?.preview)
        },
        setPrimaryColor: (value) => setValue('primaryColor', value),
        setSecondaryColor: (value) => setValue('secondaryColor', value)
      }
    }, [setValue])

  const [
    primaryColor,
    secondaryColor,
    logoMediaId,
    backgroundImageMediaId
  ] = watch([
    'primaryColor',
    'secondaryColor',
    'logoMediaId',
    'backgroundImageMediaId'
  ])

  const [logo, setLogoFile] = useState()
  const [backgroundImage, setBackgroundImageFile] = useState()

  const onChangeFile = useCallback(
    (name) => (value) => {
      if (name === FILE_INPUT.logo) {
        setLogo(value)
        setLogoFile(value)
      }
      if (name === FILE_INPUT.backgroundImage) {
        setBackgroundImage(value)
        setBackgroundImageFile(value)
      }
    },
    [setBackgroundImage, setLogo]
  )

  return (
    <>
      <Section title='logo'>
        <FileUploadInput
          image={logo}
          defaultMediaId={logoMediaId}
          onChange={onChangeFile(FILE_INPUT.logo)}
        />
      </Section>
      <Section title='background image'>
        <FileUploadInput
          image={backgroundImage}
          defaultMediaId={backgroundImageMediaId}
          onChange={onChangeFile(FILE_INPUT.backgroundImage)}
        />
      </Section>
      <Section title='branding'>
        <div className={classes.control}>
          <div className={classes.label}>Primary Color</div>
          <InputColorPicker
            value={primaryColor}
            className={classes.inputColor}
            InputProps={{ disableUnderline: true }}
            onChange={setPrimaryColor}
          />
        </div>
        <div className={classes.control}>
          <div className={classes.label}>Secondary Color</div>
          <InputColorPicker
            value={secondaryColor}
            className={classes.inputColor}
            InputProps={{ disableUnderline: true }}
            onChange={setSecondaryColor}
          />
        </div>
      </Section>
    </>
  )
}

export default CustomizePresentationForm
