import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import { useWealthJourney } from '../WealthJourneyProvider'
import FadeIn from '../../../../molecules/Transitions/FadeIn'
import GenericEntryContent from './GenericEntryContent'

const useStyles = makeStyles(theme => ({
  entryContent: {
    '& .__tbp10': {
      paddingBottom: theme.layout.padding.p10
    }
  }
}))

function EntryContent ({ className, style, entryTypeIds, entryTypes }) {
  const { selectedEntry } = useWealthJourney()
  const classes = useStyles()

  if (!selectedEntry) return null

  if (entryTypeIds?.length && !entryTypeIds.includes(selectedEntry.entryTypeId)) return null
  if (entryTypes?.length && !entryTypes.includes(selectedEntry.entryType.entryTypeCode)) return null

  return (
    <FadeIn key={selectedEntry?.entryId} className={clsx(className, classes.entryContent)} style={style}>
      <GenericEntryContent entry={selectedEntry} />
    </FadeIn>
  )
}

EntryContent.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  entryTypeIds: PropTypes.arrayOf(PropTypes.number),
  entryTypes: PropTypes.arrayOf(PropTypes.string)
}

export default EntryContent
