import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import SydButton from '../../../../../commonDesign/Button'
import { useModifyUserAccessFlags } from '../../../../../../api/users'
import DataAccessSelector from '../../shared/DataAccessSelector'

const useStyles = makeStyles((theme) => ({
  actions: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  group: {
    marginBottom: theme.layout.margin.thick,
    display: 'flex',
    flexDirection: 'column',
    gap: theme.layout.gap.medium
  },
  error: {
    backgroundColor: theme.palette.danger.main,
    color: theme.palette.danger.contrast,
    flex: '1 0 auto',
    padding: theme.layout.padding.medium,
    borderRadius: theme.layout.radius.round,
    fontSize: theme.typography.fontSizes.md,
    lineHeight: '20px',
    marginRight: theme.layout.margin.thin
  },
  type: {
    outline: `1px solid ${theme.palette.gray.darker}`,
    borderRadius: theme.layout.radius.round,
    padding: theme.layout.padding.thick,
    fontSize: theme.typography.fontSizes.xxl,
    transition: 'outline .2s ease-in-out, background-color .2s ease-in-out',
    '&:hover': {
      backgroundColor: theme.palette.gray.main,
      cursor: 'pointer'
    },
    '&.__selected': {
      outline: `4px solid ${theme.palette.primary.main}`,
      '&:hover': {
        outline: `4px solid ${theme.palette.primary.main}`
      }
    },
    '& article': {
      fontWeight: theme.typography.weights.light
    }
  }
}))

function ModifyAccessTypeForm ({ onCancel, onComplete, user }) {
  const classes = useStyles()
  const [accessType, setAccessType] = useState(user.allAccounts ? 'all' : user.allowAllUnrestricted ? 'unrestricted' : 'limited')
  const { mutateAsync: modifyUserAccessFlags } = useModifyUserAccessFlags()
  const [processing, setProcessing] = useState(false)
  const [error, setError] = useState(null)

  return (
    <>
      <div className={classes.form}>
        <div className={classes.group}>
          <DataAccessSelector value={accessType} onChange={setAccessType} />
        </div>
      </div>
      <div className={classes.actions}>
        {error ? (<div className={classes.error}>{error}</div>) : null}
        <SydButton disabled={processing} variant='ghost' size='md' onClick={onCancel}>Cancel</SydButton>
        <SydButton
          variant='primary'
          size='md'
          processing={processing}
          onClick={async () => {
            try {
              setError(null)
              setProcessing(true)
              const command = {
                userId: user.userId,
                allAccounts: accessType === 'all',
                allowAllUnrestricted: accessType === 'unrestricted'
              }
              await modifyUserAccessFlags(command)
              onComplete(command)
            } catch (err) {
              setError('There was a problem setting the user access')
              console.error(err)
            } finally {
              setProcessing(false)
            }
          }}
          icon='warning'
          priority='warning'
        >
          Change Access Level
        </SydButton>
      </div>
    </>
  )
}

ModifyAccessTypeForm.propTypes = {
  onComplete: PropTypes.func,
  onCancel: PropTypes.func,
  user: PropTypes.object
}

export default ModifyAccessTypeForm
