import React from 'react'
import { makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
  actions: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.layout.gap.medium,
    justifyContent: 'flex-end',
    alignItems: 'center',
    '& .__error': {
      flex: '1 0 auto',
      backgroundColor: theme.palette.error.main,
      color: theme.palette.error.contrast,
      padding: theme.layout.padding.medium,
      borderRadius: theme.layout.radius.round
    }
  }
}))

function SydModalActions ({ children, className }) {
  const classes = useStyles()

  return (
    <div className={clsx(className, classes.actions)}>
      {children}
    </div>
  )
}

SydModalActions.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
}

export default SydModalActions
