import React, { useCallback, useMemo, useRef, useState } from 'react'
import { makeStyles } from '@material-ui/core'
import SectionHeader from '../../shared/SectionHeader'
import {
  useListAccountTargetAssignments,
  useModifyAccountTargetAssignmentsMutation,
  useTargetModels
} from '../../../../../api/rebalancer'
import { useAccountFormContext, useSectionEditing } from '../AccountFormProvider'
import SaveCancelFooter from '../../shared/SaveCancelFooter'
import SectionScreen from '../../shared/SectionScreen'
import EditButton from '../../shared/EditButton'
import TargetAssignmentTable from './TargetAssignmentTable'

const useStyles = makeStyles((theme) => ({
  tagsSection: {
    position: 'relative',
    padding: '10px'
  },
  assignment: {
    fontSize: theme.typography.fontSizes.xl
  }
}))

const defaultQuery = {}

function TargetAssignmentSection () {
  const classes = useStyles()
  const { account, editSection, cancelEdit } = useAccountFormContext()
  const sectionEditing = useSectionEditing('target-assignments')
  const { data: assignments, isFetching } = useListAccountTargetAssignments(account.accountId)
  const { data: targetModels, isLoading: modelsLoading } = useTargetModels(defaultQuery)
  const { mutateAsync: modifyTargetAssignment } = useModifyAccountTargetAssignmentsMutation()
  const [processing, setProcessing] = useState(false)

  const formRef = useRef()
  const targetOptions = useMemo(() => {
    if (!targetModels?.length) return []

    return targetModels.map(tm => ({
      label: tm.name,
      value: tm.targetModelId,
      description: tm.description
    }))
  }, [targetModels])

  const onSave = useCallback(async () => {
    const formValues = formRef.current.formValues

    try {
      setProcessing(true)
      await modifyTargetAssignment({
        accountId: account.accountId,
        assignments: formValues.assignments
      })
      cancelEdit()
    } catch (errors) {
      console.error('problem saving the assignments', errors)
      // eslint-disable-next-line no-throw-literal
      throw { validationErrors: errors }
    } finally {
      setProcessing(false)
    }
  }, [formRef, modifyTargetAssignment, account, setProcessing, cancelEdit])

  if (isFetching || modelsLoading) {
    return (
      <div>Loading...</div>
    )
  }

  return (
    <>
      <SectionScreen sectionIsEditing={sectionEditing} editing={sectionEditing} sectionName='target-assignments' className={classes.tagsSection}>
        <SectionHeader text='Target Assignments'>
          <div>
            <EditButton
              policy='admin_edit_acct_models'
              editing={sectionEditing}
              onClick={() => editSection({
                section: 'target-assignments',
                saveDescription: 'Save Target Assignments',
                onSave
              })}
            />
          </div>
        </SectionHeader>
        <TargetAssignmentTable
          ref={formRef}
          editing={sectionEditing}
          assignments={assignments}
          options={targetOptions}
        />
      </SectionScreen>
      {sectionEditing ? (
        <SaveCancelFooter
          onSave={onSave}
          onCancel={cancelEdit}
          processing={processing}
          saveText='Save Target Assignments'
        />) : null}
    </>
  )
}

export default TargetAssignmentSection
