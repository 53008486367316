import React from 'react'
import PropTypes from 'prop-types'
import Text from '../../atoms/Text'
import { useContextContainer } from '../../../abundanceEngine/components/ContextContainer'
import { ASSET_TEAR_CONTEXT } from '.'

const AssetTearText = ({ text, textKey, fontSize, ...props }) => {
  const [{ data }] = useContextContainer(ASSET_TEAR_CONTEXT)
  return (
    <Text
      text={textKey ? data?.[textKey] || '' : text}
      customFontSize={fontSize}
      {...props}
    />
  )
}

AssetTearText.propTypes = {
  text: PropTypes.string,
  textKey: PropTypes.string,
  fontSize: PropTypes.string
}

AssetTearText.defaultProps = {
  text: '',
  textKey: undefined
}

export default AssetTearText
