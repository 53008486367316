import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { CircularProgress, makeStyles } from '@material-ui/core'
import { ICON_NAMES } from '../../../../../constants'
import Icon from '../../../../atoms/Icon'
import { useDocumentDownload } from '../../../../../api/documentVault'
import FadeIn from '../../../../molecules/Transitions/FadeIn'

const useStyles = makeStyles((theme) => ({
  attachment: {
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: theme.layout.gap.g20
  },
  list: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.layout.gap.g10
  }
}))

function EntryAttachmentList ({ attachments }) {
  const classes = useStyles()
  const [processing, setProcessing] = useState(false)
  const { mutateAsync: download } = useDocumentDownload()
  const handleClick = useCallback(async (documentId) => {
    try {
      setProcessing(true)
      await download(documentId)
    } catch (err) {
      console.error(err)
    } finally {
      setProcessing(false)
    }
  }, [download, setProcessing])
  if (!(attachments || [])?.length) {
    return null
  }

  return (
    <FadeIn className={classes.list}>
      {attachments.map(att => (
        <div
          className={classes.attachment}
          key={att.attachmentId}
          onClick={() => handleClick(att)}
        >
          {processing ? <CircularProgress size={16} /> : <Icon name={ICON_NAMES.file} />}
          <div>{att.document.name}</div>
        </div>
      ))}
    </FadeIn>
  )
}

EntryAttachmentList.propTypes = {
  attachments: PropTypes.arrayOf(PropTypes.shape({
    attachmentId: PropTypes.number,
    documentId: PropTypes.string,
    document: PropTypes.shape({
      name: PropTypes.string,
      key: PropTypes.string,
      thumbnailKey: PropTypes.string,
      mimeType: PropTypes.string,
      documentType: PropTypes.string
    })
  }))
}

export default EntryAttachmentList
