import React, { useEffect, useState } from 'react'
import { Box, CircularProgress, useTheme } from '@material-ui/core'
import { useForm } from 'react-hook-form'
import { useHistory, useLocation } from 'react-router-dom'
import SetPasswordForm from '../../organisms/SetPasswordForm'
import SecurityQuestionsForm from '../../organisms/SecurityQuestionsForm'
import { PASSWORD_ROUTES, TEXT_VARIANTS } from '../../../constants'
import { checkRecoveryToken } from '../../../service'
import FadeIn from '../../molecules/Transitions/FadeIn'
import Text from '../../atoms/Text'
import { useFirmInfo } from './hooks'
import { usePublicStyles } from './usePublicStyles'

const ActivationView = () => {
  const location = useLocation()

  const pathname = location.pathname
  const isActivationPath = pathname === PASSWORD_ROUTES.ACTIVATION || pathname === PASSWORD_ROUTES.RESET_USER
  const firmInfo = useFirmInfo()
  const classes = usePublicStyles()
  const [authInfo, setAuthInfo] = useState({
    firstName: '',
    lastName: '',
    email: '',
    activationToken: '',
    recovery_question: {}
  })
  const [isLoading, setIsLoading] = useState(true)
  const theme = useTheme()

  const passwordMethods = useForm({
    mode: 'onChange',
    defaultValues: { password: '', confirmPassword: '', acceptedTC: false }
  })
  const history = useHistory()
  useEffect(() => {
    setIsLoading(true)
    const query = new URLSearchParams(location.search)
    const token = query.get('token')

    checkRecoveryToken(token).then((response) => {
      const { firstName, lastName, login: email } = response.data?.profile ?? {}

      setAuthInfo({
        firstName,
        lastName,
        email,
        recovery_question: response.data?.recovery_question ?? {
          question: '',
          questionText: ''
        },
        activationToken: token
      })
      setIsLoading(false)
    }).catch((_) => {
      history.replace('/')
    })
  }, [location, history])

  const password = passwordMethods.watch('password')

  if (!firmInfo) {
    return null
  }
  const { logoPath } = firmInfo

  if (isLoading) {
    return (
      <FadeIn className={classes.formWrapper}>
        <Box
          display='flex'
          flexDirection='column'
          alignItems='center'
          maxWidth='100%'
          marginBottom='50px'
        >
          {logoPath && <img src={logoPath} alt='logo' className={classes.logo} />}
        </Box>

        <Box marginY='1rem'>
          <CircularProgress colorPrimary={theme.palette.summitBlue} />
        </Box>
        <Text variant={TEXT_VARIANTS.subtitle} text='Loading...' />
      </FadeIn>
    )
  }

  if (passwordMethods.formState.isSubmitSuccessful && isActivationPath) {
    return (
      <FadeIn className={classes.formWrapper}>
        <SecurityQuestionsForm
          authInfo={authInfo}
          password={password}
          logoPath={logoPath}
        />
      </FadeIn>
    )
  }

  return (
    <FadeIn className={classes.formWrapper}>
      <SetPasswordForm formMethods={passwordMethods} authInfo={authInfo} logoPath={logoPath} />
    </FadeIn>
  )
}

export default ActivationView
