import React, { useCallback, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Controller, useForm } from 'react-hook-form'
import { CircularProgress, Radio, RadioGroup } from '@material-ui/core'
import SydButton from '../../../../../commonDesign/Button'
import { useDialogStyles } from '../common'
import SydLabel from '../../../../../commonDesign/SydLabel'
import DebouncedInput from '../../../../../molecules/DebouncedInput'
import { useGroupSearch } from '../../../../../../api/groups'

function AssignSingleLargeGroupForm ({ onComplete, onClose, groupType, client, options }) {
  const classes = useDialogStyles()

  const { handleSubmit, ...form } = useForm({
    mode: 'onChange',
    defaultValues: {
      clientId: client.clientId,
      groupTypeId: groupType.groupTypeId,
      uniqueMembers: !!groupType.uniqueMembers,
      groupId: groupType.groups.at(0)?.groupId?.toString() || null,
      groups: groupType.groups.map(x => x.groupId)
    }
  })

  const [processing, setProcessing] = useState(false)
  const [searchText, setSearchText] = useState(client?.shortName)
  const query = useMemo(() => {
    return {
      filters: {
        levelTypeId: [{ op: 'eq', value: groupType.groupTypeId }]
      },
      textSearch: {
        longName: [{ op: 'contains', value: searchText }]
      },
      take: 10,
      sort: [{ field: 'longName', dir: 'asc' }]
    }
  }, [groupType, searchText])
  // const isMultiValue = useMemo(() => !!groupType.uniqueMembers, [groupType])
  // const selectedValues = useMemo(() => groupType.groups, [groupType])

  const { data, isFetching } = useGroupSearch(query)
  const results = data?.data || []

  const onAssign = useCallback(async (e) => {
    const onValid = async (form) => {
      setProcessing(true)
      try {
        await onComplete({
          groupIds: [+form.groupId]
        })
      } finally {
        setProcessing(false)
      }
    }
    const onInvalid = (errors) => {
      console.error(errors)
    }

    const handler = handleSubmit(onValid, onInvalid)
    try {
      await handler(e)
    } catch (err) {
      alert('There was a problem saving the group assignment')
      console.error(err)
      throw err
    }
  }, [handleSubmit, onComplete])

  return (
    <>
      <div className={classes.form}>
        <div className={classes.formtings}>
          <SydLabel label='Search'>
            <DebouncedInput
              onChange={setSearchText}
              placeholder='Search Group Name'
              style={{ height: 'unset', minHeight: '54px' }}
            />
          </SydLabel>
        </div>
        <div>
          {isFetching ? (
            <CircularProgress />
          ) : results.length ? (
            <Controller
              control={form.control} name='groupId'
              render={({ field }) => (
                <RadioGroup name='groupId' {...field}>
                  <table className={classes.checkTable}>
                    <tbody>
                      {results.map(x => (
                        <tr key={x.groupId} onClick={() => field.onChange(x.groupId.toString())}>
                          <td>
                            <Radio
                              className={classes.inputLabel}
                              value={x.groupId.toString()}
                              color='#ff0000'
                            />
                          </td>
                          <td>{x.longName}</td>
                          <td>{x.description}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </RadioGroup>
              )}
            />
          ) : (
            <div>No Results</div>
          )}
        </div>
      </div>
      <div className={classes.actions}>
        <SydButton variant='ghost' onClick={onClose}>Cancel</SydButton>
        <SydButton
          variant='primary'
          onClick={onAssign}
          processing={processing}
        >
          Assign
        </SydButton>
      </div>
    </>
  )
}

AssignSingleLargeGroupForm.propTypes = {
  onComplete: PropTypes.func,
  onClose: PropTypes.func,
  groupType: PropTypes.shape({
    groupTypeId: PropTypes.number,
    longName: PropTypes.string,
    uniqueMembers: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
    groups: PropTypes.arrayOf(PropTypes.shape({
      groupId: PropTypes.number
    }))
  }),
  client: PropTypes.object,
  options: PropTypes.array
}

export default AssignSingleLargeGroupForm
