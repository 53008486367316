import { useCallback, useEffect, useMemo, useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { postNamedQuery } from '../../../service'
import { useAbundanceEngineParameters } from '../../../hooks/useAbundanceEngineParameters'
import { replaceObjectValues } from '../../../utils/replacers'

function flatten (obj, delimiter = '.', maxDepth = Infinity, currentDepth = 0, prefix = '') {
  const result = {}

  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const newKey = prefix ? `${prefix}${delimiter}${key}` : key

      if (typeof obj[key] === 'object' && obj[key] !== null && currentDepth < maxDepth) {
        const nestedObj = flatten(obj[key], delimiter, maxDepth, currentDepth + 1, newKey)
        Object.assign(result, nestedObj)
      } else {
        result[newKey] = obj[key]
      }
    }
  }

  return result
}

const fetchPlatformDataSource = async (context, queryClient, queryItem) => {
  const queryKey = [
    context.userId,
    'tabularDataset.fetchPlatformDataSource',
    queryItem.query
  ]
  const data = await queryClient.fetchQuery(queryKey, async () => {
    const { data: result } = await postNamedQuery('ingestion', 'queryPlatformDataSource', queryItem.query)
    return {
      name: queryItem.name,
      result
    }
  })

  return data
}

const fetchGetGroupedCoreData = async (context, queryClient, queryItem) => {
  const queryKey = [
    context.userId,
    'tabularDataset.fetchGetGroupedCoreData',
    queryItem.query
  ]
  const data = await queryClient.fetchQuery(queryKey, async () => {
    const { data: result } = await postNamedQuery('coreData', 'getGroupedCoreData', queryItem.query)
    return {
      name: queryItem.name,
      result
    }
  })

  return data
}

const fetchGetCoreTableData = async (context, queryClient, queryItem) => {
  const queryKey = [
    context.userId,
    'tabularDataset.fetchGetCoreTableData',
    queryItem.query
  ]
  const data = await queryClient.fetchQuery(queryKey, async () => {
    const { data: result } = await postNamedQuery('coreData', 'getCoreTableData', queryItem.query)
    return {
      name: queryItem.name,
      result
    }
  })

  return data
}

const fetchGainLoss = async (context, queryClient, queryItem) => {
  const queryKey = [
    context.userId,
    'tabularDataset.fetchGainLoss',
    queryItem.query
  ]
  const data = await queryClient.fetchQuery(queryKey, async () => {
    const { data: result } = await postNamedQuery('coreData', 'gainLoss', queryItem.query)
    return {
      name: queryItem.name,
      result
    }
  })

  return data
}

const fetchAlternatives = async (context, queryClient, queryItem) => {
  const queryKey = [
    context.userId,
    'tabularDataset.fetchAlternatives',
    queryItem.query
  ]
  const data = await queryClient.fetchQuery(queryKey, async () => {
    const { data: result } = await postNamedQuery('coreData', 'alternatives', queryItem.query)
    return {
      name: queryItem.name,
      result
    }
  })

  return data
}

const queryTypeMap = {
  platformDataSource: fetchPlatformDataSource,
  platformDataset: fetchPlatformDataSource,
  getGroupedCoreData: fetchGetGroupedCoreData,
  coreData: fetchGetGroupedCoreData,
  getCoreTableData: fetchGetCoreTableData,
  gainLoss: fetchGainLoss,
  costBasis: fetchGainLoss,
  alternatives: fetchAlternatives
}

const pickOperation = (op, accessor, value) => {
  switch (op) {
    // eslint-disable-next-line eqeqeq
    case 'eq': return (item) => item[accessor] == value
    case 'lt': return (item) => item[accessor] < value
    case 'lte': return (item) => item[accessor] <= value
    case 'gt': return (item) => {
      return item[accessor] > value
    }
    case 'gte': return (item) => item[accessor] >= value
    // eslint-disable-next-line eqeqeq
    case 'neq': return (item) => item[accessor] != value
    case 'exists': return (item) => !!item[accessor]
    default: return () => true
  }
}

const calcs = {
  ratio: ({ numerator, denominator, key }) => (dataset) => {
    dataset.forEach(item => {
      item[key] = item[denominator]
        ? (item[numerator] / item[denominator])
        : null
    })
    return dataset
  },
  filter: ({ accessor, op, value }) => (dataset) => {
    const operation = pickOperation(op, accessor, value)
    return dataset.filter(item => {
      return operation(item)
    })
  }
}

export const useTabularDatasetConfiguration = (configuration) => {
  const abundanceParams = useAbundanceEngineParameters()
  const _configuration = useMemo(() => replaceObjectValues(configuration, abundanceParams),
    [configuration, abundanceParams])
  const queryClient = useQueryClient()
  const [data, setData] = useState([])
  const [loading, setLoading] = useState()
  const loadyBoi = useCallback(async () => {
    console.debug('loading Tabular Dataset', _configuration)
    const datasetNames = Object.keys(_configuration.datasets)
    const primaryDataset = _configuration.primary || datasetNames.at(0)
    console.debug('Primary Dataset: ', primaryDataset)
    if (!primaryDataset) return
    const tasks = Object.entries(_configuration.datasets).map(([name, query]) => {
      if (query.$type in queryTypeMap) {
        return queryTypeMap[query.$type](abundanceParams, queryClient, { name, query })
      }

      return null
    }).filter(x => !!x)

    try {
      setLoading(true)
      const results = await Promise.all(tasks)
      const reduced = results.reduce((luResult, result) => {
        luResult[result.name] = result
        return luResult
      }, {})
      const primaryJoiner = _configuration.datasets[primaryDataset].$join
      const primaryData = (reduced[primaryDataset].result).reduce((allData, primeDatum) => {
        const projected = datasetNames.reduce((p, name) => {
          const dataSet = _configuration.datasets[name]
          const joiner = dataSet.$join
          // eslint-disable-next-line eqeqeq
          const record = reduced[name].result.find(x => x[joiner] == primeDatum[primaryJoiner])
          p[name] = record
          return p
        }, {})
        allData.push(flatten(projected, '_'))
        return allData
      }, [])

      const filteredData = (_configuration.calcs || []).reduce((tempResult, calc) => {
        if (calc?.type in calcs) {
          try {
            return calcs[calc.type](calc)(tempResult)
          } catch (err) {
            return tempResult
          }
        }

        return tempResult
      }, primaryData)

      console.debug('Final Dataset', filteredData)
      setData(filteredData)
    } finally {
      setLoading(false)
    }
  }, [queryClient, _configuration, abundanceParams, setLoading, setData])

  useEffect(() => {
    loadyBoi().catch(console.error)
  }, [loadyBoi])

  return {
    configuration,
    data,
    loading
  }
}
