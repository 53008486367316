import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
  '@keyframes cFadeIn': {
    '0%': {
      opacity: 0
    },
    '100%': {
      opacity: 1
    }
  },
  fader: {
    opacity: 0,
    animationName: '$cFadeIn',
    animationTimingFunction: ({ timing }) => timing,
    animationDuration: ({ duration }) => `${duration}s`,
    animationDelay: ({ delay }) => `${delay}s`,
    animationFillMode: 'forwards'
  }
}))

function FadeIn ({ children, className, delay, duration, timing, ...otherProps }) {
  const classes = useStyles({ duration, delay })

  return (
    <div className={clsx(classes.fader, className)} {...otherProps}>
      {children}
    </div>
  )
}

FadeIn.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  delay: PropTypes.number,
  duration: PropTypes.number,
  timing: PropTypes.string
}

FadeIn.defaultProps = {
  delay: 0,
  duration: 0.3,
  timing: 'ease-in'
}

export default FadeIn
