import { createSelector, createSlice } from '@reduxjs/toolkit'
import { useSelector } from 'react-redux'
import ByAllAccountLinkingComponent
  from '../../components/organisms/AddAccount/ByAllAccounts/ByAllAccountLinkingComponent'
import RouteSelection from '../../components/organisms/AddAccount/RouteSelection'
import SelectCategory from '../../components/organisms/AddAccount/ManualSteps/SelectCategory'
import EditManualDetails from '../../components/organisms/AddAccount/ManualSteps/EditManualDetails'
import ByAllAccountSummaryComponent
  from '../../components/organisms/AddAccount/ByAllAccounts/ByAllAccountSummaryComponent'
import { isNullOrUndefined } from '../../utils'
import AdminAccountSetupLink from '../../components/organisms/AddAccount/ManualSteps/AdminAccountSetupLink'
import appConfig from './appConfig'

const steps = [
  { key: 'selectRoute', component: RouteSelection, hasBackButton: false },
  { route: 'manual', key: 'manual_selectCategory', component: SelectCategory, hasBackButton: true },
  { route: 'manual', key: 'manual_editDetails', component: EditManualDetails, hasBackButton: true },
  { route: 'byall', key: 'byall_summaryComponent', component: ByAllAccountSummaryComponent, hasBackButton: true },
  { route: 'byall', key: 'byall_linkingComponent', component: ByAllAccountLinkingComponent, hasBackButton: true },
  { route: 'manual', key: 'manual_updateAccount', component: EditManualDetails, hasBackButton: false },
  { route: 'manual', key: 'manual_adminAccountSetupLink', component: AdminAccountSetupLink, hasBackButton: false }
]

export const initialState = {
  isEnabled: false,
  flagActive: false,
  config: {
    availableRoutes: [],
    categorySelectOptions: {
      allCategoriesTitle: 'All Categories',
      filteredCategoryIds: [],
      maxFeaturedCategories: 12
    }
  },
  isOpen: false,
  currentRoute: null,
  currentStep: null,
  selectedCategory: null,
  dataHasChanged: false,
  stepContext: null,
  routes: [],
  steps: [],
  policies: {
    byall: false,
    manual: false
  },
  labels: {
    byall: {
      label: 'External Account',
      description: 'Link accounts directly from a financial institution.\n These accounts will update on an ongoing basis.'
    },
    manual: {
      label: 'Manual Entry',
      description: 'Valuations can be manually updated on an ongoing basis.'
    },
    selectRoute: {
      title: 'Select How to Manage Account'
    },
    manual_selectCategory: {
      title: 'Select Category for New Account'
    },
    manual_editDetails: {
      title: 'Enter Details for New Account'
    },
    manual_updateAccount: {
      title: 'Update Account Details'
    },
    manual_adminAccountSetupLink: {
      title: 'Finish setting up this account',
      subtitle: 'Click the link below to finish setting this account up in the admin',
      adminLinkText: 'Continue to Admin',
      closeLinkText: 'Close'
    },
    byall_summaryComponent: {
      title: 'External Account Summary'
    },
    byall_linkingComponent: {
      title: 'Link an account'
    },
    manualCreateForm: {
      adminLinkText: 'View this account in admin',
      assetName: {
        label: 'Name of Asset',
        placeholder: 'Enter an Asset Name',
        description: 'Max 100 Characters',
        required: 'Asset Name required',
        maxLength: 'Max 100 Characters'
      },
      currentBalance: {
        label: 'Current Balance',
        placeholder: null,
        description: null,
        required: 'Must enter a current balance',
        invalid: 'You must enter in a valid balance'
      },
      accountNumber: {
        label: 'Account Number',
        placeholder: 'Enter account number (optional)',
        description: null
      },
      asOfDate: {
        label: 'Date',
        description: null,
        required: 'Date required',
        invalid: 'You must enter in a date prior to your as of date'
      }
    }
  },
  editingAccountId: null,
  editableAccounts: []
}

const addAccountContextSlice = createSlice({
  name: 'addAccountContext',
  initialState,
  reducers: {
    setConfig (state, { payload }) {
      const { availableRoutes, categorySelectOptions } = payload
      const routes = availableRoutes?.map(route => ({ key: route, ...state.labels[route] }))
      const filteredSteps = steps
        .filter((step) => availableRoutes?.includes(step.route) || (availableRoutes?.length > 1 && !step.route))
        .map((step) => ({ ...step, ...state.labels[step.key] }))

      return {
        ...state,
        isEnabled: state.flagActive && !!availableRoutes?.length,
        config: {
          ...(state.config ?? {}),
          availableRoutes,
          categorySelectOptions: {
            ...(state.config?.categorySelectOptions ?? {}),
            ...categorySelectOptions
          }
        },
        steps: filteredSteps,
        currentStep: filteredSteps?.[0]?.key ?? null,
        routes,
        currentRoute: routes?.length === 1 ? routes?.[0]?.key : null
      }
    },
    setLabels (state, { payload }) {
      // deep merge state.labels & payload.labelOverrides
      const combinedLabels = Object.keys(state.labels).reduce((acc, key) => {
        acc[key] = {
          ...state.labels[key],
          ...payload.labelOverrides?.[key]
        }
        return acc
      }, {})

      return {
        ...state,
        labels: combinedLabels
      }
    },
    setToggleIsOpen (state, { payload }) {
      const isOpen = isNullOrUndefined(payload.isOpen) ? !state.isOpen : payload.isOpen
      return {
        ...state,
        isOpen,
        ...(!isOpen && {
          currentStep: state.steps[0]?.key,
          currentRoute: state.steps[0]?.route,
          editingAccountId: null,
          selectedCategory: null
        })
      }
    },
    setRouteSelection (state, { payload: { routeSelection } }) {
      let currentStep = state.currentStep
      if (state.currentRoute !== routeSelection) {
        if (routeSelection) {
          currentStep = state.steps.find(step => step.route === routeSelection)?.key
        } else {
          currentStep = state.steps[0]?.key
        }
      }
      return { ...state, currentRoute: routeSelection, currentStep }
    },
    setStepNavigation: (state, { payload: { stepNavigation, key } }) => {
      const extras = {}

      if (key) {
        if (!state.steps.find(step => step.key === key)?.route) {
          extras.currentRoute = null
        }
        return { ...state, currentStep: key, ...extras }
      }
      const currentIndex = state.steps.findIndex(step => step.key === state.currentStep)
      const newIndex = currentIndex + stepNavigation
      if (newIndex < 0 || newIndex >= state.steps.length) {
        return state
      }
      if (!state.steps[newIndex].route) {
        extras.currentRoute = null
      }
      return { ...state, currentStep: state.steps[newIndex].key, ...extras }
    },
    setStepContext: (state, { payload: { stepContext } }) => ({ ...state, stepContext }),
    setSelectedCategory: (state, { payload: { selectedCategory } }) => ({ ...state, selectedCategory }),
    setDataHasChanged: (state, { payload: { dataHasChanged } }) => ({ ...state, dataHasChanged }),
    setPolicies: (state, { payload: { policies } }) => {
      const filteredRoutes = state.routes?.filter((route) => policies[route.key])
      const filteredSteps = steps
        .filter((step) => filteredRoutes.map(route => route.key)?.includes(step.route) || (filteredRoutes?.length > 1 && !step.route))
        .map((step) => ({ ...step, ...state.labels[step.key] }))
      return {
        ...state,
        isEnabled: state.isEnabled && state.flagActive && !!filteredRoutes?.length,
        routes: filteredRoutes,
        config: {
          ...state.config
        },
        policies,
        steps: filteredSteps,
        ...(filteredRoutes?.length === 1 && {
          currentRoute: filteredRoutes[0].key,
          currentStep: state.steps.find(step => step.route === filteredRoutes[0].key)?.key ?? state.steps[0]?.key
        })
      }
    },
    setEditableAccounts: (state, { payload: { editableAccounts } }) => {
      return {
        ...state,
        editableAccounts
      }
    },
    setEditingAccountId: (state, { payload: { accountId } }) => {
      return {
        ...state,
        editingAccountId: accountId
      }
    },
    resetToInitialStep (state) {
      return {
        ...state,
        currentStep: state.steps[0]?.key,
        currentRoute: state.steps[0]?.route
      }
    }
  },
  extraReducers: builder => {
    builder.addCase(appConfig.actions.setAppConfig, (state, action) => {
      const featureFlag = action.payload?.featureFlags?.find(flag => flag.name === 'MANUALLY_ADD_ACCOUNTS') ?? { active: false, extras: {} }

      return {
        ...state,
        flagActive: featureFlag.active
      }
    })
  }
})

const getAddAccountContextSlice = (state) => state?.[addAccountContextSlice.name]

const getCurrentStep = createSelector(getAddAccountContextSlice, state => state?.steps?.find(step => step.key === state.currentStep))

const getPreviousStep = createSelector(getAddAccountContextSlice, state => {
  const filteredSteps = state.steps.filter(step => step.route === state.currentRoute || (state.routes?.length > 1 && !step.route))
  const currentIndex = filteredSteps.findIndex(step => step.key === state.currentStep)
  const newIndex = Math.max(currentIndex - 1, 0)
  if (newIndex === currentIndex || !filteredSteps[currentIndex]?.hasBackButton) {
    return null
  }
  return filteredSteps[newIndex] ?? state.steps[0]
})
const selectEditableAccounts = createSelector(getAddAccountContextSlice, state => {
  if (!state.policies?.manual) {
    return []
  }
  return state?.editableAccounts ?? []
})
export const useSelectIsAccountIdEditable = () => createSelector(
  [selectEditableAccounts, (state, accountId) => accountId],
  (items, accountId) => {
    return items.some(account => account.accountId === accountId)
  }
)

export const useGetCurrentStep = () => useSelector(getCurrentStep)
export const useGetPreviousStep = () => useSelector(getPreviousStep)

export const useAddAccountContext = () => useSelector(getAddAccountContextSlice)
export const {
  setConfig,
  setToggleIsOpen,
  setRouteSelection,
  setStepNavigation,
  setStepContext,
  setSelectedCategory,
  setDataHasChanged,
  setPolicies,
  setLabels,
  setEditableAccounts,
  setEditingAccountId,
  resetToInitialStep
} = addAccountContextSlice.actions

export default addAccountContextSlice
