import React, { useMemo } from 'react'
import { makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import clsx from 'clsx'
import { commonCardStyles } from '../commonStyles'
import { useWealthJourney } from '../../WealthJourneyProvider'
import EntryMenu from '../../EntryMenu'
import EntryIcon from './EntryIcon'

const useStyles = makeStyles((theme) => ({
  container: {
    userSelect: 'none',
    opacity: ({ disabled }) => (disabled ? 0.5 : 1),
    position: 'relative',
    display: 'flex',
    flexFlow: 'row nowrap',
    gap: theme.layout.gap.g10,
    alignItems: 'center',
    justifyContent: 'flex-start',
    fontSize: '1rem',
    width: 'auto',
    minHeight: '120px',
    borderRadius: '8px',
    ...(commonCardStyles(theme)),
    boxSizing: 'border-box',
    textAlign: 'center',
    transition: 'box-shadow .2s ease-in-out, outline .2s ease-in-out',
    outline: '2px solid transparent',
    cursor: ({ clickable }) => (clickable ? 'pointer' : 'none'),
    '&:hover': {
      outlineColor: ({ disabled }) =>
        disabled ? theme.palette.baseBackground.main : `color-mix(in srgb, ${theme.palette.primary.main} 50%, transparent)`,
      cursor: ({ disabled }) => (disabled ? 'initial' : 'pointer')
    },
    '&:active': {
      backgroundColor: `color-mix(in srgb, ${theme.palette.primary.main} 15%, transparent)`
    },
    '&.__selected': {
      outlineColor: theme.palette.primary.main,
      boxShadow: theme.layout.shadow.deep
    },
    '& .__left': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      paddingLeft: theme.layout.padding.p10,
      paddingRight: theme.layout.padding.p10
    },
    '& .__right': {
      flex: '1 0',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'center',
      overflow: 'hidden',
      paddingLeft: theme.layout.padding.p10,
      paddingRight: theme.layout.padding.p10
    },
    '& .MuiIconButton-root': {
      borderRadius: theme.layout.radius.round
    }
  },
  title: {
    width: '100%',
    fontSize: '1rem',
    fontWeight: 700,
    textAlign: 'left',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  },
  target: {
    fontSize: '0.75rem',
    opacity: '0.5',
    bottom: '1rem'
  },
  icon: {
    width: '5.5rem',
    height: '5.5rem',
    fontSize: '2rem'
  },
  menu: {
    position: 'absolute',
    top: 0,
    right: 0
  }
}))

const HorizontalCard = ({ entry, selected, disabled, onClick, allowEdit }) => {
  const { theming } = useWealthJourney()
  const classes = useStyles({ disabled, clickable: Boolean(onClick), theming })
  const [targetDate] = useMemo(
    () => {
      const entryDate = dayjs.utc(entry.entryDate)
      return [entryDate.format('MMM YYYY')]
    },
    [entry.entryDate]
  )

  return (
    <div
      className={clsx(classes.container, { __selected: selected }, 'wj_entry')}
      data-year={entry.year}
      data-entry-id={entry.entryId}
      onClick={onClick}
    >
      {allowEdit ? (
        <div className={classes.menu}>
          <EntryMenu entry={entry} size='20px' />
        </div>
      ) : null}
      <div className='__left'>
        <EntryIcon className={classes.icon} entry={entry} color={entry.entryType?.color} />
      </div>
      <div className='__right'>
        <div className={classes.title}>
          {entry.entryJson?.title || entry.entryText}
        </div>
        <div className={classes.target}>
          Target: {targetDate}
        </div>
      </div>
    </div>
  )
}

HorizontalCard.propTypes = {
  entry: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  selected: PropTypes.bool,
  onClick: PropTypes.func,
  allowEdit: PropTypes.bool
}

export default HorizontalCard
