import React, { useMemo } from 'react'
import { Redirect } from 'react-router-dom'
import { useAdvisorHomeNavigation } from './NavigatationContext'

const AdvisorHomeDefaultRedirect = () => {
  const { templateTree, root } = useAdvisorHomeNavigation()
  const redirectUrl = useMemo(() => {
    if (templateTree?.length > 1) {
      return `${root}/templates`
    }
    if (templateTree.length === 1) {
      const template = templateTree.at(0)
      return `${root}/${template.pathSegment}`
    }
    return `${root}/not-found`
  }, [templateTree, root])

  return (
    <Redirect to={redirectUrl} />
  )
}

export default AdvisorHomeDefaultRedirect
