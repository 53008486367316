import React from 'react'
// import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { useListIngestionJobs } from '../../../../api/ingestion'
import JobListItem from './JobListItem'

const useStyles = makeStyles({
  jobList: {
    padding: '1rem',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap'
  }
})

const JobList = () => {
  const classes = useStyles()
  const { data, isLoading } = useListIngestionJobs()

  if (isLoading) {
    return <div>Loading...</div>
  }

  return (
    <div className={classes.jobList}>
      {data.map((job) => (
        <JobListItem key={job.ingestionJobId} job={job} />
      ))}
    </div>
  )
}

export default JobList
