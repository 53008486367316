import React, { useMemo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useSearchClientConversations } from '../../../../api/conversations'
import PageContainer from '../components/PageContainer'
import ConversationCardList from './ConversationCardList'

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    width: 'calc(100vw - 2rem)'
  }
}))

const AdvisorOversightIndex = () => {
  const classes = useStyles()
  const query = useMemo(() => {
    return {
      resultType: 'details'
    }
  }, [])
  const { data, isLoading } = useSearchClientConversations(query)

  if (isLoading) {
    return (
      <div>
        &nbsp;
      </div>
    )
  }

  return (
    <PageContainer>
      <div className={classes.container}>
        <ConversationCardList conversations={data.conversations} />
      </div>
    </PageContainer>
  )
}

AdvisorOversightIndex.propTypes = {
}

AdvisorOversightIndex.defaultProps = {
}

export default AdvisorOversightIndex
