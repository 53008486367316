import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import React from 'react'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import DayJsUtils from '@date-io/dayjs'
import PropTypes from 'prop-types'

dayjs.extend(utc)

class DayJsUTCUtils extends DayJsUtils {
  date (value) {
    return dayjs(value).utc()
  }

  format (value, formatString) {
    return dayjs(value).utc().format(formatString)
  }

  parse (value, formatString) {
    return dayjs.utc(value, formatString).toDate()
  }
}

const MuiPickersUtcUtilsProvider = ({ children }) => {
  return (
    <MuiPickersUtilsProvider utils={DayJsUTCUtils}>
      {children}
    </MuiPickersUtilsProvider>
  )
}

MuiPickersUtcUtilsProvider.propTypes = {
  children: PropTypes.node.isRequired
}

export default MuiPickersUtcUtilsProvider
