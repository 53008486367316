import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import dayjs from 'dayjs'
import { useAppContext } from '../../../redux/slices/appContext'
import { useSearchTasks } from '../../../api/wealthJourney'
import { useFormattingContext } from '../FormattingProvider/FormattingContext'

const useStyles = makeStyles((theme) => ({
  tasks: {
    padding: `${theme.layout.padding.medium}`,
    display: 'flex',
    flexDirection: 'column',
    gap: theme.layout.gap.thick,
    '& .__task-item': {
      display: 'flex',
      gap: theme.layout.gap.loose,
      justifyContent: 'space-between',
      padding: theme.layout.padding.thin
    },
    '& .__task-item:not(::last-child)': {
      borderBottom: `1px solid ${theme.palette.gray.dark}`
    },
    '& .__task-left': {
    },
    '& .__task-description': {
      fontWeight: theme.typography.weights.light,
      '& > pre': {
        margin: '0 !important'
      }
    }
  },
  noInfo: {
    padding: theme.layout.padding.thick,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.palette.gray.darker,
    fontWeight: theme.typography.weights.light
  }
}))

function TaskItem ({ title, description, dueDate, dateFormat }) {
  const { formatter } = useFormattingContext()
  const formatDate = useMemo(() => formatter(dueDate, dateFormat), [dueDate, dateFormat, formatter])
  return (
    <div className='__task-item'>
      <div className='__task-left'>
        <div className='__task-title'>{title}</div>
        <div className='__task-description'><pre>{description}</pre></div>
      </div>
      <div className='__task-right'>
        <div className='__task-date'>{formatDate}</div>
      </div>
    </div>
  )
}

TaskItem.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  dueDate: PropTypes.string,
  dateFormat: PropTypes.string
}

function ClientTasks ({ relativeFrom, relativeTo, period, take, dateFormat, baseFilter }) {
  const classes = useStyles()
  const { clientId } = useAppContext()
  const query = useMemo(() => {
    const start = dayjs().add(relativeFrom, period).format('YYYY-MM-DD')
    const end = dayjs().add(relativeTo, period).format('YYYY-MM-DD')

    return {
      take,
      filters: {
        ...(baseFilter || {}),
        dueDate: [{ op: 'gte', value: start }, { op: 'lte', value: end }],
        clientId: [{ op: 'eq', value: clientId }]
      }
    }
  }, [relativeFrom, relativeTo, period, take, clientId, baseFilter])
  const { data, isLoading } = useSearchTasks(query)

  if (isLoading) return null

  if (!data?.data?.length) {
    return (
      <div className={classes.tasks}>
        <div className={classes.noInfo}>No Tasks</div>
      </div>
    )
  }

  return (
    <div className={classes.tasks}>
      {(data.data || []).map(item => (
        <TaskItem key={item.taskId} {...item} dateFormat={dateFormat} />
      ))}
    </div>
  )
}

ClientTasks.propTypes = {
  relativeFrom: PropTypes.number,
  relativeTo: PropTypes.number,
  period: PropTypes.oneOf(['day', 'week', 'month', 'year']),
  take: PropTypes.number,
  dateFormat: PropTypes.string,
  baseFilter: PropTypes.object
}

ClientTasks.defaultProps = {
  dateFormat: '@M/D/YYYY',
  take: 3
}

export default ClientTasks
