import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import noop from 'lodash/noop'
import RoundedButton from '../atoms/RoundedButton'
import { capitalizeFirstLetter } from '../../utils'
import { BUTTON_SIZES } from '../../constants'

const useStyles = makeStyles((theme) => ({
  container: {
    minWidth: '0%',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    margin: '0 -0.375rem'
  },
  pillContainer: {
    margin: '0.375rem'
  },
  pill: {
    boxShadow: 'none'
  },
  disabledPill: {
    color: `${theme.palette.black} !important`,
    backgroundColor: `${theme.palette.gray.light} !important`
  }
}))

const PillsGroup = ({ pills, size, disabled, capitalizeLabels }) => {
  const classes = useStyles()
  return (
    <div className={classes.container}>
      {pills.map(({ id, label, onClick, selected }) => {
        return (
          <div key={id} className={classes.pillContainer}>
            <RoundedButton
              size={size}
              disabled={disabled}
              primary={!!selected}
              onClick={onClick || noop}
              className={classes.pill}
              disabledClassName={disabled ? classes.disabledPill : null}
            >
              {capitalizeLabels ? capitalizeFirstLetter(label) : label}
            </RoundedButton>
          </div>
        )
      })}
    </div>
  )
}

PillsGroup.propTypes = {
  pills: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.string,
    selected: PropTypes.bool,
    onClick: PropTypes.func
  })),
  size: PropTypes.oneOf(Object.keys(BUTTON_SIZES)),
  disabled: PropTypes.bool,
  capitalizeLabels: PropTypes.bool
}

PillsGroup.defaultProps = {
  pills: [],
  size: BUTTON_SIZES.small,
  disabled: false,
  capitalizeLabels: true
}

export default PillsGroup
