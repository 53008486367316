import { useQuery } from '@tanstack/react-query'
import { useAppContext } from '../redux/slices/appContext'
import {
  getBatchRun,
  getBatchRuns,
  getReportRuntimeConfigurations,
  getReports,
  getReportTemplates,
  getTags
} from '../service'
import { QUERY_KEYS } from './queryKeys'

export const useGetReports = (queryParams) => {
  const { userId } = useAppContext()
  return useQuery({
    queryKey: [QUERY_KEYS.reports, userId, queryParams],
    queryFn: async () => {
      const { data } = await getReports(queryParams)
      return { data }
    },
    enabled: !!userId
  })
}

export const useGetReportTemplates = (queryParams) => {
  const { userId } = useAppContext()
  return useQuery({
    queryKey: [QUERY_KEYS.reportTemplates, userId, queryParams],
    queryFn: async () => {
      const { data } = await getReportTemplates(queryParams)
      return { data }
    },
    enabled: !!userId
  })
}

export const useGetReportRuntimeConfigurations = (queryParams) => {
  const { userId } = useAppContext()
  return useQuery({
    queryKey: [QUERY_KEYS.reportRuntimeConfigurations, userId, queryParams],
    queryFn: async () => {
      const { data } = await getReportRuntimeConfigurations(queryParams)
      return { data }
    },
    enabled: !!userId && queryParams.templateId !== 0
  })
}

export const useGetTags = (queryParams) => {
  const { userId } = useAppContext()
  return useQuery({
    queryKey: [QUERY_KEYS.tags, userId, queryParams],
    queryFn: async () => {
      const { data: { rows } } = await getTags()
      return { data: rows }
    },
    enabled: !!userId
  })
}

export const useGetBatchRuns = (queryParams) => {
  const { userId } = useAppContext()
  return useQuery({
    queryKey: [QUERY_KEYS.reports, 'batchRuns', userId, queryParams],
    queryFn: async () => {
      const { data } = await getBatchRuns(queryParams)
      return { data }
    },
    enabled: !!userId
  })
}

export const useGetBatchRun = (batchRunId, queryParams) => {
  const { userId } = useAppContext()
  return useQuery({
    queryKey: [QUERY_KEYS.reports, 'batchRun', batchRunId, userId, queryParams],
    queryFn: async () => {
      const { data } = await getBatchRun(batchRunId, { ...queryParams })
      return { data }
    },
    enabled: !!userId
  })
}
