import React from 'react'
import PropTypes from 'prop-types'
import Section from './shared/Section'

function ClientInfo ({ client }) {
  if (!client) {
    return null
  }

  return (
    <Section>
      <div>
        <div>{client.longName} {client.clientAbbreviation ? `(${client.clientAbbreviation})` : null}</div>
        <div>
          <ul>
            <li><a style={{ color: 'blue' }} href={`/admin/clients/${client.clientId}`} target='_blank' rel='noreferrer noopener'>Client Admin</a></li>
          </ul>
        </div>
      </div>
    </Section>
  )
}

ClientInfo.propTypes = {
  client: PropTypes.any
}

export default ClientInfo
