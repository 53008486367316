import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import { Box } from '@material-ui/core'
import clsx from 'clsx'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import DOMPurify from 'dompurify'
import { makeThemedStyles } from '../../hooks/useThemedStyles'

import 'github-markdown-css/github-markdown-light.css'

import { ICON_NAMES, MEETINGS_BODY_TYPES, TEXT_VARIANTS } from '../../constants'
import Menu from '../molecules/Menu'
import DateThumbnail from '../atoms/DateThumbnail'
import Text from '../atoms/Text'
import Icon from '../atoms/Icon'

const useStyles = makeThemedStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    marginBottom: '48px'
  },
  header: {
    marginBottom: '1rem',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    maxWidth: '1200px'
  },
  dateThumbnail: {
    padding: '0 2rem 0 0'
  },
  iconButton: {
    cursor: 'pointer'
  },
  meetingBody: {
    marginLeft: 'calc(2rem + 32px)',
    width: '100%',
    maxWidth: 'calc(1200px - (2rem + 32px))'
  },
  markdownOverride: {
    fontFamily: [
      'GothamPro',
      'Gotham-Book',
      '"Open Sans"',
      'Tahoma',
      '"Segoe UI"',
      'sans-serif'
    ].join(','),
    fontWeight: '400'
  }
}))

const WealthJourneyMeeting = ({ meetingEntry, onEditMeeting, onDeleteMeeting, canEditMeetings }) => {
  const [classes, theme] = useStyles()
  const markdownClasses = useMemo(() => clsx('markdown-body', classes.markdownOverride), [classes.markdownOverride])

  const [meetingBody, isMarkdown] = useMemo(() => {
    if (meetingEntry.entryJson.bodyType === MEETINGS_BODY_TYPES.MARKDOWN) return [meetingEntry.entryJson.body.replaceAll('&gt;', '>'), true]
    return [meetingEntry.entryJson.body, false]
  }, [meetingEntry.entryJson.body, meetingEntry.entryJson.bodyType])

  const menuOptions = useMemo(() => ([
    {
      iconName: ICON_NAMES.edit,
      label: 'Edit',
      onClick: onEditMeeting,
      value: 'edit'
    },
    {
      iconName: ICON_NAMES.delete,
      color: '#D44333',
      label: 'Archive',
      onClick: onDeleteMeeting,
      value: 'delete'
    }
  ]), [onEditMeeting, onDeleteMeeting])

  const renderMenuIcon = useCallback(({ setAnchorEl }) => {
    return (
      <div className={classes.iconButton} onClick={setAnchorEl}>
        <Icon name={ICON_NAMES.threeDots} customSize='1.5rem' />
      </div>
    )
  }, [classes.iconButton])

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <Box display='flex' flexDirection='row' alignItems='center' justifyContent='flex-start'>
          <div className={classes.dateThumbnail}>
            <DateThumbnail date={meetingEntry.entryDate} />
          </div>
          <Text
            text={meetingEntry.entryJson.title}
            color={theme.palette.cloudBurst}
            variant={TEXT_VARIANTS.h1}
          />
        </Box>
        {canEditMeetings && (
          <Menu options={menuOptions}>{renderMenuIcon}</Menu>
        )}
      </div>
      <main>
        <section className={classes.meetingBody}>
          {isMarkdown
            ? <ReactMarkdown className={markdownClasses} children={meetingBody} remarkPlugins={[remarkGfm]} />
            : <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(meetingBody) }} />}
        </section>
      </main>
    </div>
  )
}

WealthJourneyMeeting.propTypes = {
  meetingEntry: PropTypes.object.isRequired,
  onEditMeeting: PropTypes.func,
  onDeleteMeeting: PropTypes.func,
  canEditMeetings: PropTypes.bool.isRequired
}

WealthJourneyMeeting.defaultProps = {
  onEditMeeting: noop,
  onDeleteMeeting: noop
}

export default WealthJourneyMeeting
