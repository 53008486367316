import { useMutation, useQueryClient } from '@tanstack/react-query'
import { saveBookmark } from '../../../../service'
import { QUERY_KEYS } from '../../../../api/queryKeys'

const saveBookmarkWrapper = async ({ levelType, levelId, ownerUserId }) => {
  const body = {
    levelId,
    levelType,
    ownerUserId
  }
  return await saveBookmark(body)
}

function useSaveBookmarkMutation () {
  const queryClient = useQueryClient()

  const mutation = useMutation({
    mutationFn: saveBookmarkWrapper,
    // When mutate is called:
    onMutate: async (newBookmark) => {
      // Cancel any outgoing re-fetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({
        queryKey: QUERY_KEYS.bookmarksMultiple
      })
      await queryClient.cancelQueries({
        queryKey: QUERY_KEYS.bookmarks
      })

      // Snapshot the previous value
      const prevBookmarksMultiple = queryClient.getQueryData(
        [QUERY_KEYS.bookmarksMultiple],
        { exact: false }
      )
      const prevBookmarks = queryClient.getQueryData([QUERY_KEYS.bookmarks], {
        exact: false
      })

      // Modify cache to reflect this optimistic update
      queryClient.setQueryData(
        [QUERY_KEYS.bookmarksMultiple],
        [newBookmark, ...(prevBookmarksMultiple || [])]
      )
      queryClient.setQueryData(
        [QUERY_KEYS.bookmarks],
        [newBookmark, ...(prevBookmarks || [])]
      )

      // Return a snapshot so we can rollback in case of failure
      return { prevBookmarks, prevBookmarksMultiple }
    },
    // If the mutation fails, use the context we returned above
    onError: (error, newBookmark, { prevBookmarks, prevBookmarksMultiple }) => {
      // Rollback the changes using the snapshot
      queryClient.setQueryData(
        [QUERY_KEYS.bookmarksMultiple],
        prevBookmarksMultiple
      )
      queryClient.setQueryData(
        [QUERY_KEYS.bookmarks],
        prevBookmarks
      )
      console.error(error, newBookmark)
    },
    onSuccess () {
      // Refetch or invalidate related queries
      queryClient.invalidateQueries({
        queryKey: QUERY_KEYS.bookmarksMultiple
      })
      queryClient.invalidateQueries({
        queryKey: QUERY_KEYS.bookmarks
      })
    }
  })

  return mutation
}

export default useSaveBookmarkMutation
