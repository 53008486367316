import React, { forwardRef, useImperativeHandle, useMemo } from 'react'
import PropTypes from 'prop-types'
import { makeStyles, Grid, TextField } from '@material-ui/core'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import FadeIn from '../../../../molecules/Transitions/FadeIn'

const useStyles = makeStyles((theme) => ({
  userInfoForm: {
    maxWidth: '800px',
    display: 'flex',
    justifyContent: 'center',
    padding: '10px',
    '& p': {
      color: `${theme.palette.primary.dark}`
    }
  },
  inputLabel: {
    color: theme.palette.black,
    fontWeight: 600,
    '& span': {
      color: theme.palette.black
    }
  }
}))

const useFormValues = (client) => {
  const defaultValues = useMemo(() => {
    return {
      shortName: client?.shortName ?? '',
      longName: client?.longName ?? '',
      description: client?.description ?? '',
      clientAbbreviation: client?.clientAbbreviation ?? ''
    }
  }, [client])

  const formMethods = useForm({
    mode: 'onChange',
    defaultValues
  })

  return formMethods
}

const ClientInfoForm = forwardRef(function ClientInfoForm ({ client }, ref) {
  const classes = useStyles()
  const { handleSubmit, formState, ...formValues } = useFormValues(client)
  const labelProps = {
    className: classes.inputLabel
  }

  useImperativeHandle(ref, () => ({
    handleSubmit
  }), [handleSubmit])

  return (
    <FormProvider handleSubmit={handleSubmit} formState={formState} {...formValues}>
      <FadeIn className={classes.userInfoForm}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <Controller
              name='shortName'
              rules={{ required: true }}
              render={({ field, fieldState }) => (
                <TextField
                  label='Display Name'
                  fullWidth
                  helperText='Required, 30 max characters'
                  error={(formState.isSubmitted || fieldState.isDirty || fieldState.isTouched) && fieldState.error}
                  inputProps={field}
                  InputLabelProps={labelProps}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <Controller
              name='longName'
              rules={{ required: true }}
              render={({ field, fieldState }) => (
                <TextField
                  label='Full Name'
                  fullWidth
                  helperText='Required, 80 max characters'
                  error={(formState.isSubmitted || fieldState.isDirty || fieldState.isTouched) && fieldState.error}
                  inputProps={field}
                  InputLabelProps={labelProps}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <Controller
              name='description'
              render={({ field, fieldState }) => (
                <TextField
                  label='Wealth Mission'
                  fullWidth
                  helperText='100 max characters'
                  error={(formState.isSubmitted || fieldState.isDirty || fieldState.isTouched) && fieldState.error}
                  inputProps={field}
                  InputLabelProps={labelProps}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <Controller
              name='clientAbbreviation'
              render={({ field, fieldState }) => (
                <TextField
                  label='Client Abbreviation'
                  fullWidth
                  helperText='3 max characters'
                  error={(formState.isSubmitted || fieldState.isDirty || fieldState.isTouched) && fieldState.error}
                  inputProps={field}
                  InputLabelProps={labelProps}
                />
              )}
            />
          </Grid>
        </Grid>
      </FadeIn>
    </FormProvider>
  )
})

ClientInfoForm.propTypes = {
  client: PropTypes.shape({
    clientId: PropTypes.number,
    shortName: PropTypes.string,
    longName: PropTypes.string,
    description: PropTypes.string,
    clientAbbreviation: PropTypes.string
  })
}

export default ClientInfoForm
