import { useCallback, useMemo, useState } from 'react'
import { useAppContext } from '../../redux/slices/appContext'
import { useSaveMediaMutation } from '../media'
import { useSaveClientMutation } from '../clients'
import { uploadDocument } from '../../service'

export const useAutomaticClientAvatarUpload = ({ client, canEdit }) => {
  const { userId } = useAppContext()
  const { mutateAsync: saveMedia } = useSaveMediaMutation()
  const { mutateAsync: saveClient } = useSaveClientMutation()
  const [uploadState, setUploadState] = useState({
    progress: null,
    uploaded: false,
    hasError: false,
    aborted: false
  })

  const isLoading = useMemo(() => {
    if (uploadState.uploaded) return false
    if (uploadState.aborted) return false

    return !!uploadState.progress
  }, [uploadState])

  const onAbort = useCallback(() => {
    setUploadState((prevState) => ({
      ...prevState,
      aborted: true,
      progress: 0
    }))
  }, [setUploadState])

  const onProgress = useCallback((event) => {
    setUploadState((prevState) => ({
      ...prevState,
      progress: (event.loaded / event.total) * 100
    }))
  }, [setUploadState])

  const autoUpload = useCallback(async (file) => {
    if (!canEdit) return
    if (!file?.name) return

    // STEP 1: Create the media and get the uploadUrl
    const data = await saveMedia({
      clientId: client.clientId,
      createdBy: userId, // TODO: service should handle this, not client
      fileName: file.name
    })

    const {
      mediaId,
      // fileName,
      uploadUrl
      // originalFileName
    } = data

    const xhr = new XMLHttpRequest()
    // STEP 2: Upload the image
    await uploadDocument(
      uploadUrl,
      file,
      onProgress,
      onAbort,
      xhr
    )

    setUploadState(prev => ({ ...prev, uploaded: true }))

    // STEP 3: Confirm the media upload
    // This step doesn't exist right now

    // STEP 4: Update the client
    await saveClient({
      clientId: client.clientId,
      client: {
        extras: {
          ...client.extras,
          profileData: {
            ...client.extras?.profileData,
            profilePicMediaId: mediaId
          }
        }
      }
    })
  }, [client, canEdit, saveMedia, userId, onAbort, onProgress, saveClient])

  return {
    autoUpload,
    isLoading
  }
}

export const useManualClientAvatarUpload = ({ client, canEdit }) => {
  const { userId } = useAppContext()
  const { mutateAsync: saveMedia } = useSaveMediaMutation()
  const [uploadState, setUploadState] = useState({
    progress: null,
    uploaded: false,
    hasError: false,
    aborted: false,
    started: false,
    mediaId: null
  })

  const isLoading = useMemo(() => {
    if (uploadState.uploaded) return false
    if (uploadState.aborted) return false

    return uploadState.started && (!!uploadState.progress || !!uploadState.started)
  }, [uploadState])

  const onAbort = useCallback(() => {
    setUploadState((prevState) => ({
      ...prevState,
      aborted: true,
      progress: 0
    }))
  }, [setUploadState])

  const onProgress = useCallback((event) => {
    setUploadState((prevState) => ({
      ...prevState,
      progress: (event.loaded / event.total) * 100
    }))
  }, [setUploadState])

  const autoUpload = useCallback(async (file) => {
    if (!canEdit) return
    if (!file?.name) return
    setUploadState((prevState) => ({
      ...prevState,
      started: true
    }))

    // STEP 1: Create the media and get the uploadUrl
    const data = await saveMedia({
      clientId: client.clientId,
      createdBy: userId, // TODO: service should handle this, not client
      fileName: file.name
    })

    const {
      mediaId,
      // fileName,
      uploadUrl
      // originalFileName
    } = data

    const xhr = new XMLHttpRequest()
    // STEP 2: Upload the image
    await uploadDocument(
      uploadUrl,
      file,
      onProgress,
      onAbort,
      xhr
    )

    setUploadState(prev => ({ ...prev, uploaded: true, mediaId }))

    // STEP 3: Confirm the media upload
    // This step doesn't exist right now
  }, [client, canEdit, saveMedia, userId, onAbort, onProgress])

  return {
    upload: autoUpload,
    isUploading: isLoading,
    mediaId: uploadState.mediaId
  }
}
