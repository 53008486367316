import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useState } from 'react'
import {
  IconButton,
  InputAdornment,
  makeStyles,
  TextField
} from '@material-ui/core'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import Edit from '@material-ui/icons/Edit'
import DoneIcon from '@material-ui/icons/Done'
import clsx from 'clsx'
import { useToggle } from '../../hooks'
import Icon from '../atoms/Icon'
import { MAX_INLINE_INPUT_LENGTH, ICON_NAMES } from '../../constants'

const useStyles = makeStyles((theme) => ({
  textField: ({ width }) => ({
    marginLeft: '0.5rem',
    marginRight: '0.5rem',
    width: width || 300,
    color: 'black',
    fontSize: 30,
    opacity: 1,
    borderBottom: 0,
    '&:before': {
      borderBottom: 0
    }
  }),
  disabled: {
    color: 'black',
    borderBottom: 0,
    '&:before': {
      borderBottom: 0
    }
  },
  underline: {
    '&&&:before': {
      borderBottom: 'none'
    },
    '&&:after': {
      borderBottom: 'none'
    }
  },
  icon: {
    fontSize: '1rem !important'
  }
}))

const InputInlineEdit = forwardRef(({
  name,
  value,
  width,
  multiline,
  className,
  autoFocus,
  showEditIcon,
  defaultValue,
  placeholder,
  initialValue: initValue,
  initInEditMode,
  onChangeHandler,
  disableUnderline,
  onIconClickHandler,
  textFieldClassName,
  alwaysShowEditButton,
  onCancelIconClickHandler,
  textFieldAdornmentClassName,
  icon: DoneIcon,
  placeHolderValue,
  onToggleEditMode = noop,
  onBlur,
  type,
  min,
  max,
  step,
  onKeyDownHandler,
  isControlled,
  hideActionButtons,
  placeHolderIcon: PlaceHolderIcon,
  error = null,
  useErrorToValidate = false
}, ref) => {
  const classes = useStyles({ width })
  const [initialValue, setInitialValue] = useState(initValue)
  const [editMode, , setEditModeOn, setEditModeOff] = useToggle(initInEditMode)
  const [mouseOver, , onMouseEnterHandler, onMouseLeaveHandler] = useToggle()

  const onCancelClickIcon = useCallback(() => {
    onCancelIconClickHandler(initialValue)
    setEditModeOff()
  }, [onCancelIconClickHandler, setEditModeOff, initialValue])

  useImperativeHandle(ref, () => {
    return {
      setEditModeOn,
      setEditModeOff,
      onCancelClick: onCancelClickIcon
    }
  }, [setEditModeOn, setEditModeOff, onCancelClickIcon])

  useEffect(() => {
    onToggleEditMode(editMode)
  }, [editMode, onToggleEditMode])

  const endAdornment = useMemo(() => {
    if ((mouseOver || editMode || alwaysShowEditButton) && !hideActionButtons) {
      const onClickIcon = editMode
        ? () => {
          if (value) {
            onIconClickHandler(value)
            setInitialValue(value)
            setEditModeOff()
          }
        }
        : setEditModeOn

      return (
        <InputAdornment
          position='end'
          className={clsx({ [textFieldAdornmentClassName]: Boolean(textFieldAdornmentClassName) })}
        >
          {
            editMode ? (
              <div>
                <IconButton onClick={onCancelClickIcon}>
                  <Icon name={ICON_NAMES.close} />
                </IconButton>
                <IconButton onClick={onClickIcon}>
                  <DoneIcon className={classes.icon} />
                </IconButton>
              </div>
            ) : (
              showEditIcon && (
                <IconButton onClick={onClickIcon}>
                  <PlaceHolderIcon className={classes.icon} />
                </IconButton>
              )
            )
          }
        </InputAdornment>
      )
    }
    return ''
  }, [
    value,
    classes,
    editMode,
    mouseOver,
    showEditIcon,
    setEditModeOn,
    setEditModeOff,
    onCancelClickIcon,
    onIconClickHandler,
    alwaysShowEditButton,
    textFieldAdornmentClassName,
    hideActionButtons
  ])

  return (
    <TextField
      ref={ref}
      name={name}
      value={!editMode && placeHolderValue ? placeHolderValue : value}
      disabled={!editMode}
      error={useErrorToValidate ? !!error : !value}
      onChange={onChangeHandler}
      helperText={error?.message}
      defaultValue={defaultValue}
      className={clsx(classes.textField, textFieldClassName)}
      onMouseEnter={onMouseEnterHandler}
      onKeyDown={onKeyDownHandler}
      onBlur={onBlur}
      onMouseLeave={onMouseLeaveHandler}
      placeholder={placeholder}
      multiline={multiline}
      type={!editMode && placeHolderValue ? 'text' : type}
      autoFocus={autoFocus}
      InputProps={{
        disableUnderline,
        classes: {
          input: className,
          disabled: classes.disabled,
          ...(editMode ? {} : { underline: classes.underline })
        },
        endAdornment
      }}
      inputProps={{
        maxLength: MAX_INLINE_INPUT_LENGTH,
        min,
        max,
        step
      }}
    />
  )
})

InputInlineEdit.propTypes = {
  name: PropTypes.string,
  autoFocus: PropTypes.bool,
  className: PropTypes.string,
  multiline: PropTypes.bool,
  showEditIcon: PropTypes.bool,
  initialValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  initInEditMode: PropTypes.bool,
  onChangeHandler: PropTypes.func,
  disableUnderline: PropTypes.bool,
  icon: PropTypes.object.isRequired,
  onIconClickHandler: PropTypes.func,
  textFieldClassName: PropTypes.string,
  onCancelIconClickHandler: PropTypes.func,
  placeHolderIcon: PropTypes.object.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  placeHolderValue: PropTypes.string,
  defaultValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  textFieldAdornmentClassName: PropTypes.string,
  alwaysShowEditButton: PropTypes.bool,
  onToggleEditMode: PropTypes.func,
  isControlled: PropTypes.bool,
  hideActionButtons: PropTypes.bool,
  error: PropTypes.object,
  type: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.string,
  onKeyDownHandler: PropTypes.func,
  onBlur: PropTypes.func,
  useErrorToValidate: PropTypes.bool,
  placeholder: PropTypes.string
}

InputInlineEdit.defaultProps = {
  name: undefined,
  value: undefined,
  width: undefined,
  multiline: false,
  defaultValue: undefined,
  initialValue: undefined,
  className: '',
  onChangeHandler: noop,
  onIconClickHandler: noop,
  onBlur: noop,
  icon: DoneIcon,
  placeHolderIcon: Edit,
  autoFocus: false,
  showEditIcon: false,
  initInEditMode: false,
  disableUnderline: false,
  textFieldClassName: undefined,
  onCancelIconClickHandler: noop,
  placeHolderValue: undefined,
  textFieldAdornmentClassName: undefined,
  alwaysShowEditButton: false,
  onToggleEditMode: noop,
  isControlled: false,
  hideActionButtons: false,
  error: null,
  type: undefined,
  min: undefined,
  max: undefined,
  step: undefined,
  onKeyDownHandler: undefined,
  useErrorToValidate: false,
  placeholder: undefined
}

export default InputInlineEdit
