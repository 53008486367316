import React, { useState } from 'react'
import PropTypes from 'prop-types'
import TitleWithSubtitle from '../../molecules/TitleWithSubtitle'
import Text from '../../atoms/Text'

import theme from '../../../theme'
import { VisualBalanceSectionsShape } from './utils'

const NetWorth = ({ vbsInfo, sections, formats, formatter }) => {
  const [format, setFormat] = useState(formats.primary)
  const networthData = [...Object.values(vbsInfo ?? {})]?.flat()?.filter(section => !sections.other.includes(section.groupId))?.flat()

  const netWorthValue = networthData.reduce((acc, item) => acc + item.total, 0) || 0
  const onClickNetWorth = () => setFormat(format === formats.primary ? formats.alternate : formats.primary)

  return (
    <TitleWithSubtitle
      titleElement={
        <span onClick={onClickNetWorth} style={{ cursor: 'pointer' }}>
          <Text
            text={netWorthValue === 0 ? '--' : formatter(netWorthValue, format)}
            textTransform='uppercase'
            customFontSize='2.5rem'
            variant='body2'
          />
        </span>
      }
      subtitleElement={
        <Text
          text='Net worth'
          customFontSize='1rem'
          color={theme.palette.cloudBurst}
        />
      }
      alignment='flex-start'
    />
  )
}

NetWorth.propTypes = {
  vbsInfo: PropTypes.object,
  sections: VisualBalanceSectionsShape,
  formats: PropTypes.shape({
    primary: PropTypes.string,
    alternate: PropTypes.string
  }),
  formatter: PropTypes.func
}

export default NetWorth
