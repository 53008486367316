import React from 'react'
import PropTypes from 'prop-types'
import ViewWrapper from '../../ViewListAndBlockComponents/ViewWrapper'
import { useTasksContext } from '../TasksContext'

const TasksViewWrapper = ({ children }) => {
  const { viewType, setContainerViewRef } = useTasksContext()
  return (
    <ViewWrapper viewType={viewType} containerRefCallback={setContainerViewRef}>
      {children}
    </ViewWrapper>
  )
}

TasksViewWrapper.propTypes = {
  children: PropTypes.node
}

export default TasksViewWrapper
