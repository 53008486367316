import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(() => ({
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    gap: '0.75rem'
  }
}))

const FamilyTreeContainer = ({ children }) => {
  const classes = useStyles()
  return <div className={classes.container}>{children}</div>
}

FamilyTreeContainer.propTypes = {
  children: PropTypes.node
}

export default FamilyTreeContainer
