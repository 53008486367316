import React from 'react'
import PropTypes from 'prop-types'
import { useSpring, animated } from '@react-spring/web'
import { useAnimatedPath, useMotionConfig } from '@nivo/core'

const blendModePropType = PropTypes.oneOf([
  'normal',
  'multiply',
  'screen',
  'overlay',
  'darken',
  'lighten',
  'color-dodge',
  'color-burn',
  'hard-light',
  'soft-light',
  'difference',
  'exclusion',
  'hue',
  'saturation',
  'color',
  'luminosity'
])

const CustomAreaPath = ({ areaBlendMode, areaOpacity, color, fill, path, options }) => {
  const { animate, config: springConfig } = useMotionConfig()

  const animatedPath = useAnimatedPath(path)
  const animatedProps = useSpring({
    color,
    config: springConfig,
    immediate: !animate
  })

  if (!options?.enableArea) return null

  return (
    <animated.path
      d={animatedPath}
      fill={fill || animatedProps.color}
      fillOpacity={areaOpacity}
      strokeWidth={0}
      style={{
        mixBlendMode: areaBlendMode
      }}
    />
  )
}

CustomAreaPath.propTypes = {
  areaBlendMode: blendModePropType.isRequired,
  areaOpacity: PropTypes.number.isRequired,
  color: PropTypes.string,
  fill: PropTypes.string,
  path: PropTypes.string.isRequired,
  options: PropTypes.shape({
    enableArea: PropTypes.bool
  })
}

CustomAreaPath.defaultProps = {
  areaOpacity: 0.2,
  areaBlendMode: 'normal',
  fill: '#aaa'
}

const CustomAreas = ({ areaGenerator, series }) => {
  const computedLines = series.slice(0).reverse()

  return (
    <g>
      {computedLines.map(line => (
        <CustomAreaPath
          key={line.id}
          path={areaGenerator(line.data.map(d => d.position))}
          {...line}
        />
      ))}
    </g>
  )
}

CustomAreas.propTypes = {
  areaGenerator: PropTypes.func.isRequired,
  areaOpacity: PropTypes.number.isRequired,
  areaBlendMode: blendModePropType.isRequired,
  series: PropTypes.arrayOf(PropTypes.object).isRequired
}

export default CustomAreas
