import React, { useMemo } from 'react'
import dayjs from 'dayjs'
import numeral from 'numeral'

export const cellTemplates = {
  /* eslint-disable-next-line jsx-a11y/anchor-is-valid */
  date: ({ value }) => dayjs.utc(value).format('M/D/YYYY'),
  number: ({ value }) => value,
  // eslint-disable-next-line eqeqeq
  units: ({ value }) => value == 0 ? '--' : numeral(value).format('0,0.00'),
  money: ({ value }) => numeral(value).format('0,0.00'),
  percentage: ({ value }) => numeral(value).format('0.00%'),
  tags: ({ value }) => (
    <span>{value.map(x => (<span style={{ border: '1px solid gray', padding: '2px', marginRight: '2px' }} key={x.transactionCodeTagId}>{x.displayName}</span>))}</span>
  ),
  noWrap: ({ value }) => (
    <span style={{ whiteSpace: 'nowrap' }}>{value}</span>
  )
}

const sharedAttrs = { alignRight: true, width: 50 }

export const defaultColumnConfig = {
  realized: {
    columns: [
      { Header: 'Name', accessor: 'levelName', width: 100 },
      { Header: 'Cost Basis', accessor: 'costBasisAdjusted', ...sharedAttrs },
      { Header: 'Proceeds', accessor: 'proceeds', ...sharedAttrs },
      { Header: 'ST RGL', accessor: 'shortTermRGL', ...sharedAttrs },
      { Header: 'LT RGL', accessor: 'longTermRGL', ...sharedAttrs },
      { Header: 'Total RGL', accessor: 'totalRGL', ...sharedAttrs },
      { Header: '% Gain / Loss', accessor: 'percentGainLoss', ...sharedAttrs }
    ],
    defaultSort: [{ id: 'percentGainLoss', desc: true }]
  },
  unrealized: {
    columns: [
      { Header: 'Name', accessor: 'levelName', width: 100 },
      { Header: 'Cost Basis', accessor: 'costBasisAdjusted', ...sharedAttrs },
      { Header: 'Market Value', accessor: 'endingValue', ...sharedAttrs },
      { Header: 'ST UGL', accessor: 'shortTermUGL', ...sharedAttrs },
      { Header: 'LT UGL', accessor: 'longTermUGL', ...sharedAttrs },
      { Header: 'Total UGL', accessor: 'totalUGL', ...sharedAttrs },
      { Header: '% Gain / Loss', accessor: 'percentGainLoss', ...sharedAttrs }
    ],
    defaultSort: [{ id: 'percentGainLoss', desc: true }]
  },
  combined: {
    columns: [
      { Header: 'Name', accessor: 'levelName', width: 100 },
      { Header: 'Cost Basis', accessor: 'costBasisAdjusted', ...sharedAttrs },
      { Header: 'Market Value', accessor: 'endingValue', ...sharedAttrs },
      { Header: 'ST UGL', accessor: 'shortTermUGL', ...sharedAttrs },
      { Header: 'LT UGL', accessor: 'longTermUGL', ...sharedAttrs },
      { Header: 'Total UGL', accessor: 'totalUGL', ...sharedAttrs },
      { Header: 'ST RGL', accessor: 'shortTermRGL', ...sharedAttrs },
      { Header: 'LT RGL', accessor: 'longTermRGL', ...sharedAttrs },
      { Header: 'Total RGL', accessor: 'totalRGL', ...sharedAttrs }
    ],
    defaultSort: [{ id: 'endingValue', desc: true }]
  }
}

export const useColumns = ({
  costBasisType,
  format,
  formatter,
  columnConfig = defaultColumnConfig
}) => {
  return useMemo(() => {
    const { columns, defaultSort } = (columnConfig[costBasisType] || { columns: [] })
    const templatedColumns = columns.map(column => {
      const formatString = format[column.accessor]
        ? format[column.accessor]
        : format.default

      return {
        ...column,
        Cell: ({ value }) => formatter(value, formatString)
      }
    })

    return {
      columns: templatedColumns,
      defaultSort
    }
  }, [costBasisType, columnConfig, format, formatter])
}
