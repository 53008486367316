import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import { Box, makeStyles } from '@material-ui/core'
import noop from 'lodash/noop'
import Avatar from '../atoms/Avatar'
import Text from '../atoms/Text'
import TitleWithSubtitle from '../molecules/TitleWithSubtitle'
import { getAvatarProfilePicture } from '../../utils'
import { BUTTON_SIZES, SIZE_VARIANTS, TEXT_VARIANTS } from '../../constants'
import { useNoteThreadContext } from '../../contexts/noteThreadContext'
import RoundedButton from '../atoms/RoundedButton'

const useStyles = makeStyles((theme) => ({
  wrapper: {
    margin: '1rem',
    padding: '1rem',
    backgroundColor: theme.palette.gray.light,
    borderRadius: '1rem',
    cursor: 'pointer'
  },
  header: {
    display: 'flex',
    alignItems: 'center'
  },
  arrowForward: ({ hasNewNotifications }) => ({
    cursor: 'pointer',
    fontSize: '1.5rem',
    ...(hasNewNotifications ? {} : { marginLeft: 'auto' })
  }),
  notificationBadge: {
    border: 'none',
    minWidth: '40px !important',
    padding: '4px 2px 2px 2px !important',
    fontSize: '0.615rem !important',
    color: theme.palette.white,
    fontWeight: 'bold',
    marginLeft: 'auto',
    marginRight: '1rem',
    backgroundColor: theme.palette.success.A500,
    '& > span > svg': {
      marginRight: '0.5rem',
      fontSize: '1rem'
    },
    '&:hover': {
      backgroundColor: theme.palette.success.A700
    },
    '& > span': {
      minWidth: '35px !important'
    }
  }
}))

const NoteThreadClientOverview = ({ clientThreadOverview }) => {
  const classes = useStyles({ hasNewNotifications: clientThreadOverview.hasNewNotifications })
  const { setActiveClientThreadsOverview } = useNoteThreadContext()

  const threadsCounterLabel = useMemo(() => {
    const threads = clientThreadOverview.threads
    if (!threads) return 'No Threads'
    return `${threads} ${threads > 1 ? 'Threads' : 'Thread'}`
  }, [clientThreadOverview.threads])

  return (
    <div
      className={classes.wrapper}
      onClick={() => setActiveClientThreadsOverview(clientThreadOverview)}
    >
      <div className={classes.header}>
        <Box mr={2}>
          <Avatar
            src={getAvatarProfilePicture(clientThreadOverview.profilePic || '')}
            avatarLetters={clientThreadOverview.familyName || ''}
            size={SIZE_VARIANTS.small}
          />
        </Box>
        <TitleWithSubtitle
          title={
            <Text
              text={clientThreadOverview.familyName}
              customFontSize='0.875rem'
              customFontWeight='bold'
            />
          }
          subtitleElement={<Text text={threadsCounterLabel} customFontSize='0.75rem' />}
          titleVariant={TEXT_VARIANTS.body1}
          subtitleVariant={TEXT_VARIANTS.caption}
        />
        {clientThreadOverview.hasNewNotifications && (
          <RoundedButton
            size={BUTTON_SIZES.extraSmall}
            onClick={noop}
            className={classes.notificationBadge}
          >
            New
          </RoundedButton>
        )}
        <ArrowForwardIcon className={classes.arrowForward} />
      </div>
    </div>
  )
}

NoteThreadClientOverview.propTypes = {
  clientThreadOverview: PropTypes.shape({
    profilePic: PropTypes.string,
    familyName: PropTypes.string,
    threads: PropTypes.number,
    hasNewNotifications: PropTypes.bool
  })
}

NoteThreadClientOverview.defaultProps = {
  clientThreadOverview: null
}

export default NoteThreadClientOverview
