import React, { useCallback, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Controller, useForm } from 'react-hook-form'
import { Grid, makeStyles, MenuItem, Select, TextField, useTheme } from '@material-ui/core'
import isEmpty from 'lodash/isEmpty'
import noop from 'lodash/noop'
import { useQueryClient } from '@tanstack/react-query'
import { ALERT_SEVERITY, BUTTON_SIZES, ERROR_ALERT, SUCCESS_ALERT } from '../../../../../constants'
import { createReportRuntimeConfiguration, updateReportRuntimeConfiguration } from '../../../../../service'
import Text from '../../../../atoms/Text'
import SnackAlert from '../../../../molecules/SnackAlert/SnackAlert'
import { messageToUse } from '../../common'
import RoundedButton from '../../../../atoms/RoundedButton'
import Skeleton from '../../../../atoms/Skeleton'
import AceCodeEditor from '../../../../molecules/AceCodeEditor'
import { QUERY_KEYS } from '../../../../../api/queryKeys'
import { useAppContext } from '../../../../../redux/slices/appContext'

const useStyles = makeStyles(() => ({
  container: {
    padding: '1rem',
    height: '100%',
    display: 'flex',
    flex: '1',
    flexDirection: 'column'
  },
  confirmError: {
    textAlign: 'left',
    marginTop: '0.375rem'
  },
  groupItem: {
    width: '100%',
    padding: '1rem'
  },
  buttonGroup: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between'
  },
  buttonGroupItem: {
    display: 'flex',
    width: '20rem',
    maxWidth: '100%',
    padding: '0 1rem'
  },
  itemTextField: {
    backgroundColor: '#F4F5F6',
    borderRadius: '4px',
    paddingTop: '0.625rem',
    paddingLeft: '0.625rem',
    marginTop: '0.625rem',
    marginBottom: '0.625rem'
  },
  multilineItemTextField: {
    backgroundColor: '#F4F5F6',
    borderRadius: '4px',
    paddingTop: '0.625rem',
    paddingLeft: '0.625rem',
    marginTop: '0.625rem',
    marginBottom: '0.625rem',
    overflow: 'auto'
  },
  editorWrapper: {
    minHeight: '500px',
    marginTop: '.5rem'
  }
}))

const TemplateConfigurationsForm = ({
  editConfigurationData,
  onSubmit,
  onSetEditConfigurationData,
  templates,
  isTemplatesLoading
}) => {
  const classes = useStyles()
  const theme = useTheme()
  const [alert, setAlert] = useState({})
  const { userId } = useAppContext()
  const queryClient = useQueryClient()

  const initialFormState = useMemo(() => {
    return {
      templateIds: [],
      name: '',
      content: ''
    }
  }, [])

  const {
    handleSubmit,
    control,
    register,
    reset,
    clearErrors,
    setError,
    formState: { errors, isSubmitting }
  } = useForm({
    mode: 'onChange',
    defaultValues: initialFormState
  })

  useEffect(() => {
    reset(!isEmpty(editConfigurationData) ? editConfigurationData : initialFormState)
  }, [initialFormState, reset, editConfigurationData])

  const isCreate = useMemo(() => isEmpty(editConfigurationData), [editConfigurationData])

  const cancelEdit = useCallback(() => {
    onSetEditConfigurationData({})
    reset(initialFormState)
  }, [reset, initialFormState, onSetEditConfigurationData])

  const onSubmitHandler = useCallback(
    async (values) => {
      clearErrors()

      let parsedContent
      try {
        parsedContent = values.content?.length
          ? JSON.parse(values.content)
          : {}
      } catch (err) {
        setError('content', { type: 'pattern' })
        return
      }

      try {
        const body = {
          name: values.name,
          content: parsedContent,
          templateIds: values.templateIds ?? []
        }

        values.runtimeConfigurationId
          ? await updateReportRuntimeConfiguration(values.runtimeConfigurationId, body)
          : await createReportRuntimeConfiguration(body)

        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.reportTemplates, userId] })
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.reportRuntimeConfigurations, userId] })

        onSubmit({
          ...SUCCESS_ALERT,
          alertMessage: `Runtime Configuration ${values.name ? 'updated' : 'created'} successfully`,
          alertSeverity: ALERT_SEVERITY.info
        })
      } catch (error) {
        console.error(error)
        setAlert({
          ...ERROR_ALERT,
          alertMessage: 'Something went wrong. Please try again'
        })
        return
      }

      reset(initialFormState)
    },
    [clearErrors, reset, initialFormState, setError, queryClient, userId, onSubmit]
  )

  const renderConfigurationName = useCallback(
    ({ field: { onChange } }) => {
      return (
        <>
          <TextField
            type='input'
            fullWidth
            className={classes.itemTextField}
            onChange={onChange}
            placeholder='Configuration Name'
            error={Boolean(errors.name)}
            disabled={isSubmitting}
            {...register('name', { required: true })}
          />
          {errors?.name && (
            <Text
              text={messageToUse(errors.name)}
              color={theme.palette.error.main}
              className={classes.confirmError}
            />
          )}
        </>
      )
    }, [
      register,
      errors?.name,
      classes.itemTextField,
      classes.confirmError,
      theme.palette.error.main,
      isSubmitting
    ]
  )

  const renderTemplateControl = useCallback(
    ({ field: { onChange, ...field } }) => {
      if (isTemplatesLoading) {
        return (
          <Skeleton
            className={classes.itemTextField}
            height='2.3rem'
            width='100%'
          />)
      }
      return (
        <Select
          multiple
          fullWidth
          className={classes.itemTextField}
          InputProps={{ disableUnderline: true }}
          onChange={onChange}
          placeholder='Template'
          error={Boolean(errors.templateIds)}
          disabled={isTemplatesLoading}
          defaultValue={[templates[0]?.templateId]}
          {
            ...field
          }
        >
          {templates.map(({ templateId, name }) => (
            <MenuItem key={templateId} value={templateId}>
              {name}
            </MenuItem>
          ))}
        </Select>
      )
    }
    ,
    [isTemplatesLoading, classes.itemTextField, errors.templateIds, templates]
  )

  const renderContent = useCallback(
    ({ field: { onChange, value } }) =>
      (
        <div className={classes.editorWrapper}>
          <AceCodeEditor
            tabSize={4}
            mode='markdown'
            id='view-editor'
            name='viewContent'
            value={value}
            defaultValue={initialFormState.content}
            onChange={onChange}
            width='100%'
            rows={20}
            required
          />
          {errors?.content && (
            <Text
              text={messageToUse(errors.content)}
              color={theme.palette.error.main}
              className={classes.confirmError}
            />
          )}
        </div>
      ), [classes.editorWrapper, classes.confirmError, initialFormState.content, errors.content, theme.palette.error.main]
  )

  return (
    <div className={classes.container}>
      <form onSubmit={handleSubmit(onSubmitHandler)}>
        <Grid container md={12}>
          <Grid item md={6} sm={12}>
            <div className={classes.groupItem}>
              <Text text='Name' variant='h3' />
              <Controller
                name='name'
                render={renderConfigurationName}
                control={control}
                rules={{ required: true }}
              />
            </div>
          </Grid>
          <Grid item md={6} sm={12}>
            <div className={classes.groupItem}>
              <Text text='Template(s) to link to' variant='h3' />
              <Controller
                name='templateIds'
                render={renderTemplateControl}
                control={control}
                rules={{ required: false }}
              />
            </div>
          </Grid>
        </Grid>

        <Grid item sm={12}>
          <div className={classes.groupItem}>
            <Text text='Runtime Configuration' variant='h3' />
            <Controller
              name='content'
              render={renderContent}
              control={control}
              rules={{ required: true }}
            />
          </div>
        </Grid>
        <Grid container md={12}>
          <Grid item md={12} className={classes.buttonGroup}>
            <div className={classes.buttonGroupItem}>
              <RoundedButton
                secondary
                size={BUTTON_SIZES.medium}
                disabled={isSubmitting}
                isLoading={isSubmitting}
                fullWidth={1}
                onClick={() => cancelEdit()}
              >
                Cancel
              </RoundedButton>
            </div>
            <div className={classes.buttonGroupItem}>
              <RoundedButton
                primary
                type='submit'
                size={BUTTON_SIZES.medium}
                disabled={isSubmitting}
                isLoading={isSubmitting}
                fullWidth={1}
              >
                {!isCreate ? 'Save Changes' : 'Create Runtime Configuration'}
              </RoundedButton>
            </div>
          </Grid>
        </Grid>
      </form>
      <SnackAlert alert={alert} />
    </div>
  )
}

TemplateConfigurationsForm.propTypes = {
  editConfigurationData: PropTypes.object,
  onSubmit: PropTypes.func,
  templates: PropTypes.any.isRequired,
  onSetEditConfigurationData: PropTypes.func,
  isTemplatesLoading: PropTypes.bool
}

TemplateConfigurationsForm.defaultProps = {
  configurationData: {},
  onSubmit: noop,
  onSetEditConfigurationData: noop
}

export default TemplateConfigurationsForm
