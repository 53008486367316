import React from 'react'
import PropTypes from 'prop-types'
import { AdvisorViewTabGroupContext, useAdvisorViewTabGroupValues } from '../../AdvisorView/components/AdvisorViewTabs/advisorViewTabGroupContext'

const SectionToggleGroup = ({ toggleOptions, children }) => {
  const tabContext = useAdvisorViewTabGroupValues(toggleOptions)
  return (
    <AdvisorViewTabGroupContext.Provider value={tabContext}>
      {children}
    </AdvisorViewTabGroupContext.Provider>
  )
}

SectionToggleGroup.propTypes = {
  toggleOptions: PropTypes.object,
  children: PropTypes.node
}

export default SectionToggleGroup
