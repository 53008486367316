import React from 'react'
import PropTypes from 'prop-types'
import SydLinkButton from '../../commonDesign/LinkButton'

function FirmSummaryLink ({ report }) {
  const reportId = report.configuration.report.reportId
  if (!report.configuration?.summary) {
    return null
  }

  return (
    <SydLinkButton to={`/data-home/out/s/${reportId}`} variant='outline' icon='chart'>Summary</SydLinkButton>
  )
}

FirmSummaryLink.propTypes = {
  report: PropTypes.shape({
    configuration: PropTypes.shape({
      summary: PropTypes.object,
      report: PropTypes.shape({
        reportId: PropTypes.string
      })
    })
  }).isRequired
}

export default FirmSummaryLink
