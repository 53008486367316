import React, { useMemo } from 'react'
import { makeStyles } from '@material-ui/core'
import SydLinkButton from '../../../../commonDesign/LinkButton'
import ViewTabs from '../../../../organisms/AdvisorView/components/AdvisorViewTabs/index'
import FadeIn from '../../../../molecules/Transitions/FadeIn'
import { usePolicies } from '../../../../../hooks'
import EmptySection from '../../../../atoms/EmptySection'
import { usePolicy } from '../../../../../hooks/usePolicy'
import BatchTable from './BatchTable'

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '1rem'
  }
}))

const useTabOptions = () => {
  const policies = usePolicies()
  return useMemo(() => {
    const result = {}
    if (policies.admin_manual_accounts) {
      result.myBatches = {
        label: 'My Batches',
        value: 'myBatches'
      }
    }
    if (policies.admin_manual_accounts_all) {
      result.allBatches = {
        label: 'All Batches',
        value: 'allBatches'
      }
    }
    return result
  }, [policies])
}

function ManualAccounts () {
  const classes = useStyles()
  const tabOptions = useTabOptions()
  const canAccess = usePolicy('admin_manual_accounts')

  if (!canAccess) {
    return (
      <FadeIn className={classes.container}>
        <EmptySection
          title='Access Denied'
          description='You do not have sufficient permissions to access this resource'
        />
      </FadeIn>
    )
  }

  return (
    <FadeIn className={classes.container}>
      <ViewTabs.Group tabOptions={tabOptions}>
        <ViewTabs.Links
          tabsKey='admin_manual_positions_list'
          rightAdornment={(
            <header className={classes.header}>
              <SydLinkButton size='md' variant='primary' icon='add' to='/admin/manual-accounts/batch/new'>New Batch</SydLinkButton>
            </header>
          )}
        />
        <ViewTabs.Tab value='myBatches'>
          <BatchTable />
        </ViewTabs.Tab>
        <ViewTabs.Tab value='allBatches'>
          <BatchTable showAll />
        </ViewTabs.Tab>
      </ViewTabs.Group>
    </FadeIn>
  )
}

export default ManualAccounts
