import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import ViewTabs from '../../../organisms/AdvisorView/components/AdvisorViewTabs'
import { usePolicies } from '../../../../hooks'
import GeneralTab from './GeneralTab'
import AccountTab from './AccountTab'
import BillingTab from './BillingTab'
import PeopleTab from './PeopleTab'
import ReportingTab from './ReportingTab'
import TagsTab from './TagsTab'
import TargetsTab from './TargetsTab'
import StatusReportTab from './StatusReportTab'
import { useClientDetails } from './ClientDetailsFormContext'
import KdpTab from './KdpTab'

const useStyles = makeStyles((theme) => ({
  clientDetailTabs: {},
  viewTabs: {
  }
}))

const tabOptions = {
  general: {
    value: 'general',
    label: 'General Info'
  },
  accounts: {
    value: 'accounts',
    label: 'Accounts'
  },
  billing: {
    value: 'billing',
    label: 'Billing Details'
  },
  people: {
    value: 'people',
    label: 'People & Users'
  },
  reporting: {
    value: 'reporting',
    label: 'Reporting'
  },
  targets: {
    value: 'targets',
    label: 'Targets'
  },
  tags: {
    value: 'tags',
    label: 'Tags'
  },
  kdp: {
    value: 'kdp',
    label: 'Key Datapoints'
  },
  statusReports: {
    value: 'statusReports',
    label: 'Status Reports'
  }
}

const useTabOptions = () => {
  const policies = usePolicies()
  return useMemo(() => {
    const result = {}
    if (policies.admin_view_clients) {
      result.general = tabOptions.general
    }
    if (policies.admin_edit_client_accts) {
      result.accounts = tabOptions.accounts
    }
    if (policies.admin_view_client_billing) {
      result.billing = tabOptions.billing
    }
    if (policies.admin_edit_client_persons) {
      result.people = tabOptions.people
    }
    if (policies.admin_edit_client_reporting) {
      result.reporting = tabOptions.reporting
    }
    if (policies.admin_edit_clientinfo) {
      result.targets = tabOptions.targets
    }
    if (policies.admin_edit_client_tags) {
      result.tags = tabOptions.tags
    }
    if (policies.admin_view_key_datapoints) {
      result.kdp = tabOptions.kdp
    }
    if (policies.edit_client_status_template_assignments) {
      result.statusReports = tabOptions.statusReports
    }
    return result
  }, [policies])
}

function ClientDetailTabs ({ onTabChange, disabled }) {
  const classes = useStyles()
  const { cancelEdit } = useClientDetails()
  const tabs = useTabOptions()

  return (
    <div className={classes.clientDetailTabs}>
      <ViewTabs.Group tabOptions={tabs} onChange={cancelEdit}>
        <div className={classes.viewTabs}>
          <ViewTabs.Links
            disabled={disabled}
            tabsKey='admin_client_details'
          />
        </div>
        {tabs.general ? (
          <ViewTabs.Tab value='general'>
            <GeneralTab />
          </ViewTabs.Tab>
        ) : null}
        {tabs.accounts ? (
          <ViewTabs.Tab value='accounts'>
            <AccountTab />
          </ViewTabs.Tab>
        ) : null}
        {tabs.billing ? (
          <ViewTabs.Tab value='billing'>
            <BillingTab />
          </ViewTabs.Tab>
        ) : null}
        {tabs.people ? (
          <ViewTabs.Tab value='people'>
            <PeopleTab />
          </ViewTabs.Tab>
        ) : null}
        {tabs.reporting ? (
          <ViewTabs.Tab value='reporting'>
            <ReportingTab />
          </ViewTabs.Tab>
        ) : null}
        {tabs.targets ? (
          <ViewTabs.Tab value='targets'>
            <TargetsTab />
          </ViewTabs.Tab>
        ) : null}
        {tabs.tags ? (
          <ViewTabs.Tab value='tags'>
            <TagsTab />
          </ViewTabs.Tab>
        ) : null}
        {tabs.kdp ? (
          <ViewTabs.Tab value='kdp'>
            <KdpTab />
          </ViewTabs.Tab>
        ) : null}
        {tabs.statusReports ? (
          <ViewTabs.Tab value='statusReports'>
            <StatusReportTab />
          </ViewTabs.Tab>
        ) : null}
      </ViewTabs.Group>
    </div>
  )
}

ClientDetailTabs.propTypes = {
  onTabChange: PropTypes.func,
  disabled: PropTypes.bool
}

ClientDetailTabs.defaultProps = {
  onTabChange: () => {},
  disabled: false
}

export default ClientDetailTabs
