import React from 'react'
import PropTypes from 'prop-types'
import SectionHeader from '../../shared/SectionHeader'
import EditButton from '../../shared/EditButton'
import { useAdvisorUserDetails } from '../AdvisorUserFormContext'
import FadeIn from '../../../../molecules/Transitions/FadeIn'
import AdvisorUserClientsTable from './AdvisorUserClientsTable'

function Unrestricted ({ formRef }) {
  const { user, editSection, editing } = useAdvisorUserDetails()

  return (
    <div>
      <SectionHeader text='Assigned Clients'>
        <div>
          <EditButton
            policy='admin_edit_advisor_users'
            editing={editing}
            onClick={() => editSection({
              section: 'clients',
              saveDescription: 'Save Client Assignments'
            })}
            buttonText='Edit Assignments'
          />
        </div>
      </SectionHeader>
      <FadeIn>
        <AdvisorUserClientsTable
          ref={formRef}
          user={user}
          editing={editing}
          onlyRestricted
        />
      </FadeIn>
    </div>
  )
}

Unrestricted.propTypes = {
  formRef: PropTypes.any
}

export default Unrestricted
