import React from 'react'
import PropTypes from 'prop-types'
import { useAppContext } from '../../../redux/slices/appContext'
import { useLatestClientStatusReport } from '../../../api/groups'
import { useStatusReportContext } from './statusReportContext'
import StatusReport from './index'

function ClientStatusReport (props) {
  const { clientId } = useAppContext()
  const { data, isLoading, refetch } = useLatestClientStatusReport(clientId)
  const { reportExpanded } = useStatusReportContext()

  return (
    <StatusReport
      {...props}
      report={data?.report}
      statuses={data?.statuses}
      loading={isLoading}
      onRefetch={refetch}
      expanded={reportExpanded}
    />
  )
}

ClientStatusReport.propTypes = {
  defaultExpanded: PropTypes.bool
}

export default ClientStatusReport
