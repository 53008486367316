import React, { useCallback, useState } from 'react'
import isEmpty from 'lodash/isEmpty'
import { fetchPersons, fetchClients, createPerson, editPerson, deletePerson } from '../../../service'
import { useFetchState, useToggle } from '../../../hooks'
import { CSV_CELL_TYPES, TEXT_ALIGNMENTS } from '../../../constants'
import PersonForm from '../../organisms/PersonForm'
import ManagementView from './managementView'
import {
  errorAlert,
  initialAlertState,
  successAlert,
  warningAlert
} from './common'

const labels = [
  { hidden: true },
  { name: 'Name' },
  { name: 'Client(s)' },
  { name: 'Email' },
  { name: 'Phone' },
  { name: 'Actions', sorteable: false }
]

function PersonsView () {
  const [activePerson, setActivePerson] = useState(undefined)
  const [loadData, setLoadData] = useState(true)

  const [alertState, setAlertState] = useState(initialAlertState)
  const { alertMessage, alertSeverity, openAlert } = alertState
  const [formOpen, toggleForm, setFormOpen, setFormClose] = useToggle(false)
  const [deleteSubmitting, setDeleteSubmitting] = useState(false)
  const [dialogOpen, toggleDialog] = useToggle(false)

  const handleCloseAlert = useCallback((_, reason) => {
    if (reason === 'clickaway') return
    setAlertState({ ...initialAlertState, loadData: false })
  }, [])

  // Listing persons
  const fetchCallback = useCallback(async (safeSetState) => {
    if (loadData) {
      try {
        const { data } = await fetchClients()
        const clientsById = data.reduce(
          (clients, client) => ({ ...clients, [client.clientId]: client.shortName }),
          {}
        )
        const { data: personsData } = await fetchPersons()
        const items = personsData.map(
          ({
            clientsIds,
            email,
            firstName,
            lastName,
            personId,
            phoneNumber,
            profilePic
          }) => [
            { value: personId, hidden: true },
            { alignment: TEXT_ALIGNMENTS.left, avatarSource: profilePic, value: `${firstName} ${lastName}`, withAvatar: true, csvData: { type: CSV_CELL_TYPES.string } },
            { alignment: TEXT_ALIGNMENTS.left, value: isEmpty(clientsIds) ? '' : clientsIds.map((clientId) => ` ${clientsById[clientId] ? clientsById[clientId] : ''}`).join(', '), csvData: { type: CSV_CELL_TYPES.string } },
            { alignment: TEXT_ALIGNMENTS.left, value: email, csvData: { type: CSV_CELL_TYPES.string } },
            { alignment: TEXT_ALIGNMENTS.left, value: phoneNumber, csvData: { type: CSV_CELL_TYPES.string } },
            { isEditableActions: true, editableProps: { personId } }
          ])
        safeSetState({ items, data: personsData, payload: { clients: data } })
      } catch (error) {
        console.error(error)
        safeSetState({ error })
      } finally {
        setLoadData(false)
      }
    }
  }, [loadData])

  const { loading, error, items = [], data = [], payload = {} } = useFetchState(fetchCallback)

  // Adding a new person
  const handleClickAdd = useCallback(() => {
    setActivePerson(undefined)
    setFormOpen()
  }, [setFormOpen])

  const handleCreatePerson = useCallback(async (data) => {
    try {
      await createPerson(data)
      setAlertState({
        ...successAlert,
        alertMessage: 'Person created successfully'
      })
      setLoadData(true)
    } catch (error) {
      console.error(error)
      setAlertState(errorAlert)
    } finally {
      setFormClose()
    }
  }, [setFormClose])

  // Editing a person
  const handleClickEdit = useCallback((tableItem) => {
    const editedPerson = data.find((dataItem) => dataItem.personId === tableItem.personId)
    setActivePerson({ ...editedPerson })
    setFormOpen()
  }, [data, setFormOpen])

  const handleEditPerson = useCallback(async ({ personId, clientIds, ...body }) => {
    try {
      const [clientId] = clientIds.split(',')
      await editPerson(personId, { clientId, ...body })
      setAlertState({
        ...successAlert,
        alertMessage: 'Person edited successfully'
      })
      setLoadData(true)
    } catch (error) {
      console.error(error)
      setAlertState(errorAlert)
    } finally {
      setFormClose()
    }
  }, [setFormClose])

  // Deleting a person
  const handleClickDelete = useCallback((tableItem) => {
    const deletedItem = data.find((dataItem) => dataItem.personId === tableItem.personId)
    setActivePerson({ ...deletedItem, personId: tableItem.personId })
    toggleDialog()
  }, [data, toggleDialog])

  const handleDeletePerson = useCallback(async () => {
    setDeleteSubmitting(true)
    try {
      const { personId, clientsIds } = activePerson
      const [clientId] = clientsIds
      await deletePerson(clientId, personId)

      setAlertState({
        ...warningAlert,
        alertMessage: 'Person deleted successfully'
      })
      setLoadData(true)
    } catch (error) {
      setAlertState(errorAlert)
    } finally {
      setDeleteSubmitting(false)
      toggleDialog()
      setActivePerson(undefined)
    }
  }, [toggleDialog, activePerson])

  return (
    <ManagementView
      addLabel='Add Person'
      alertMessage={alertMessage}
      alertSeverity={alertSeverity}
      dialogOpen={dialogOpen}
      dialogTitle='Are you sure you want to delete this person?'
      error={error}
      items={items}
      handleClickAdd={handleClickAdd}
      handleClickDelete={handleClickDelete}
      handleClickEdit={handleClickEdit}
      handleCloseAlert={handleCloseAlert}
      handleDialogAction={handleDeletePerson}
      labels={labels}
      loadingDialog={deleteSubmitting}
      loadingTable={loading || loadData}
      openAlert={openAlert}
      sidePanelContent={(
        <PersonForm
          onSubmit={activePerson ? handleEditPerson : handleCreatePerson}
          onCancel={setFormClose}
          submitText={activePerson ? 'Edit' : 'Add'}
          item={activePerson}
          payload={payload}
        />
      )}
      sidePanelTitle={activePerson ? 'Edit Person' : 'Add Person'}
      sidePanelOpen={formOpen}
      title='Persons'
      toggleDialog={toggleDialog}
      toggleSidePanel={toggleForm}
      virtualizeTable
    />
  )
}

export default PersonsView
