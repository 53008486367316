import KeyMirror from 'keymirror'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { DATE_FORMAT_US_LONG, DATE_FORMAT_DASH } from '../../../constants'

dayjs.extend(utc)

export const COMPONENTS_OF_CHANGE = KeyMirror({
  beginningValue: null,
  benchmarkReturn: null,
  endingValue: null,
  fees: null,
  income: null,
  netAdditions: null,
  netGain: null,
  return: null
})

export const DATA_POINTS_GROUP_BY = KeyMirror({
  month: null,
  year: null
})

export const getQueryDateRanges = (dateRange, groupBySpec) => {
  const { groupBy, timeLeapUnit } = groupBySpec

  const start = dayjs.utc(dateRange.startDate)
  const end = dayjs.utc(dateRange.endDate)
  let diff = Math.ceil(end.diff(start, groupBy, true))

  let dateRanges = {}
  let lastEndDate = null
  while (diff >= 0) {
    const startDate = lastEndDate ?? start
    let endDate = startDate.endOf(groupBy)
    // prevents to go beyond our starting end date
    if (endDate.isAfter(end, 'day')) {
      diff = 0
      endDate = end
    }
    lastEndDate = endDate.add(1, 'day')
    const key = `${startDate.format(DATE_FORMAT_US_LONG)}-${endDate.format(DATE_FORMAT_US_LONG)}`
    dateRanges = {
      ...dateRanges,
      [key]: {
        // we omit key here because this is year over year
        startDate: startDate.format(DATE_FORMAT_DASH),
        endDate: endDate.format(DATE_FORMAT_DASH)
      }
    }
    diff -= timeLeapUnit
  }
  return dateRanges
}

export const mapComponentsOfChangeData = (data, { groupBy, format }) => {
  if (!data) return []
  const [{ name, type, ...dateKeys }] = data

  return Object.keys(dateKeys).reduce((acc, dateKey) => {
    const dataPointsObj = data.reduce(
      (prev, datum) => ({
        ...prev,
        [datum.type]: datum[dateKey]
      }),
      {}
    )
    const [startKey, endKey] = dateKey.split('-')
    acc.push({
      groupBy: dayjs.utc(startKey).format(format),
      startDate: dayjs.utc(startKey).format(DATE_FORMAT_US_LONG),
      endDate: dayjs.utc(endKey).format(DATE_FORMAT_US_LONG),
      ...dataPointsObj
    })
    return acc
  }, [])
}

export const getColumns = ({ dataPoints }, groupBySpec) => {
  const groupDataByColumn = {
    id: 'groupBy',
    accessor: 'groupBy',
    header: groupBySpec.header,
    align: groupBySpec.align || 'left',
    tooltip: groupBySpec.tooltip,
    cellTooltip: groupBySpec.cellTooltip || true,
    cellTooltipFormat: groupBySpec.cellTooltipFormat || 'MM/DD/YYYY'
  }
  const columns = [
    groupDataByColumn,
    ...dataPoints.map((dataPoint) => {
      return {
        ...dataPoint,
        id: dataPoint.key,
        accessor: dataPoint.key,
        header: dataPoint.label,
        align: dataPoint.align || 'left',
        format: dataPoint.format,
        tooltip: dataPoint.tooltip
      }
    })
  ]
  return columns
}
