import React, { useCallback, useMemo } from 'react'
import { useTheme } from '@material-ui/core'
import PropTypes from 'prop-types'
import { ResponsiveBar } from '@nivo/bar'
import { useFirmReportData } from '../../../api/coreData'
import { useFormattingContext } from '../FormattingProvider/FormattingContext'
import { useDenaliFilter } from './filters/DenaliFilterContextProvider'

const defaultAxisLeft = {
  tickSize: 5,
  tickValues: 4,
  tickPadding: 15,
  tickRotation: 0,
  truncateTickAt: 0
}

const defaultAxisBottom = {
}

const useFormatters = (valueFormat, labelFormat, layout) => {
  const { formatter } = useFormattingContext()

  const valueFormatter = useCallback(d => {
    return formatter(d.value, valueFormat)
  }, [formatter, valueFormat])

  const labelFormatter = useCallback(d => {
    return formatter(d, labelFormat)
  }, [formatter, labelFormat])

  return {
    valueFormatter,
    labelFormatter,
    formatLeft: layout === 'vertical' ? labelFormatter : undefined,
    formatBottom: layout === 'horizontal' ? labelFormatter : undefined
  }
}

function DenaliBarChart ({
  reportId,
  baseQuery,
  indexBy,
  keys,
  valueFormat,
  labelFormat,
  height,
  layout,
  ...chartProps
}) {
  const theme = useTheme()
  const { filter, membershipFilter } = useDenaliFilter()
  const query = useMemo(() => {
    return {
      ...baseQuery,
      membershipFilter,
      filters: {
        ...(filter || {}),
        ...(baseQuery?.filters || {})
      },
      reportId
    }
  }, [reportId, baseQuery, filter, membershipFilter])

  const { data: report, isLoading } = useFirmReportData(query)
  const { valueFormatter, formatLeft, formatBottom } = useFormatters(valueFormat, labelFormat, layout)

  if (isLoading) return null

  return (
    <div style={{ height }}>
      <ResponsiveBar
        theme={{
          fontFamily: theme.typography.fontFamily,
          fontSize: '12px'
        }}
        data={report.data}
        indexBy={indexBy}
        keys={keys}
        label={valueFormatter}
        layout={layout}
        axisLeft={{
          ...(chartProps.axisLeft || defaultAxisLeft),
          format: formatLeft
        }}
        axisBottom={{
          ...(chartProps.axisBottom || defaultAxisBottom),
          format: formatBottom
        }}
        {...chartProps}
      />
    </div>
  )
}

DenaliBarChart.propTypes = {
  reportId: PropTypes.string,
  baseQuery: PropTypes.object,
  indexBy: PropTypes.string,
  keys: PropTypes.array,
  valueFormat: PropTypes.string,
  labelFormat: PropTypes.string,
  layout: PropTypes.oneOf(['horizontal', 'vertical']),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
}

DenaliBarChart.defaultProps = {
  valueFormat: 'marketValue',
  labelFormat: 'human',
  layout: 'vertical',
  height: 300
}

export default DenaliBarChart
