import React, { useRef } from 'react'
import { makeStyles } from '@material-ui/core'
import ActionGroup from '../../shared/ActionGroup'
import { useAdvisorUserDetails, useSectionEditing } from '../AdvisorUserFormContext'
import SectionScreen from '../../shared/SectionScreen'
import SydButton from '../../../../commonDesign/Button'
import DeleteDialog from '../dialogs/DeleteDialog'
import { usePolicy } from '../../../../../hooks/usePolicy'
import HeaderInfoDisplay from './HeaderInfoDisplay'

const useStyles = makeStyles((theme) => ({
  headerInfo: {
    position: 'relative',
    flex: '1 0'
  }
}))

function HeaderInfo () {
  const classes = useStyles()
  const { user, deleteAdvisorUser, editing } = useAdvisorUserDetails()
  const sectionEditing = useSectionEditing('header')
  const deleteRef = useRef()
  const canEdit = usePolicy('admin_edit_advisor_users')

  return (
    <SectionScreen sectionIsEditing={sectionEditing} editing={editing} className={classes.headerInfo}>
      {canEdit ? (
        <ActionGroup>
          <SydButton
            onClick={() => deleteRef.current.open()}
            variant='outline'
            size='sm'
            priority='warning'
            icon='delete'
          >
            Deactivate
          </SydButton>
        </ActionGroup>
      ) : null}
      <HeaderInfoDisplay user={user} />
      <DeleteDialog
        ref={deleteRef}
        onConfirm={deleteAdvisorUser}
      />
    </SectionScreen>
  )
}

export default HeaderInfo
