import { isEmpty } from 'lodash'
import { BALANCE_CHANGE_STATUS, ICON_NAMES } from '../../../constants'

const formatGroupParamName = (groupName) => {
  return `${groupName}Ids`
}

export const getCustomLevelTypeFilters = (customLevelTypeFilters) => {
  return customLevelTypeFilters
    .filter(({ groupName }) => !isEmpty(groupName))
    .reduce(
      (acc, { groupName, groupIds }) => ({
        ...acc,
        [formatGroupParamName(groupName)]: groupIds
      }),
      {}
    )
}

export const getClientBalanceIndicator = (balanceChangeStatus) => {
  const indicatorProps = {
    [BALANCE_CHANGE_STATUS.positive]: {
      icon: ICON_NAMES.up,
      color: 'success.main'
    },
    [BALANCE_CHANGE_STATUS.neutral]: {
      icon: ICON_NAMES.remove,
      color: undefined
    },
    [BALANCE_CHANGE_STATUS.negative]: {
      icon: ICON_NAMES.down,
      color: 'error.main'
    }
  }
  return (
    indicatorProps?.[balanceChangeStatus] ||
    indicatorProps[BALANCE_CHANGE_STATUS.neutral]
  )
}

export const VIEW_TYPES = {
  LIST: 'list',
  BLOCKS: 'blocks'
}

export const mapClients = (pageSize, gridOptions) => (data) => {
  const defaultState = { clients: [], total: 0, hasNextPage: true }
  if (!data?.pages) return defaultState

  const { clients, hasNextPage, total } = data?.pages?.reduce(
    (acc, { data: page }) => {
      return {
        clients: [...acc.clients, ...page],
        total: acc.total + page.length,
        hasNextPage: acc.hasNextPage && page.length === pageSize
      }
    },
    { ...defaultState }
  )
  if (gridOptions) {
    const clientsGrid = []

    const { columnCount } = gridOptions
    for (let i = 0; i < clients.length; i += columnCount) {
      clientsGrid.push(clients.slice(i, i + columnCount))
    }
    return {
      total,
      hasNextPage,
      clients: clientsGrid
    }
  }
  return {
    total,
    clients,
    hasNextPage
  }
}

export const clearClientTagFilters = (
  groupTypeId,
  selectedTags,
  previousTags
) => {
  return isEmpty(selectedTags)
    ? { [groupTypeId]: { ...previousTags[groupTypeId], groupIds: [] } }
    : {}
}

const SEARCH_FIELDS = ['shortName', 'longName']

export const mapSearch = (searchText, searchFields = SEARCH_FIELDS) => {
  if (!searchText) return {}

  return searchFields.reduce(
    (acc, field) => ({
      ...acc,
      [field]: [{ op: 'contains', value: searchText }]
    }),
    {}
  )
}

export const mapSort = (sort = []) => {
  return sort.map(({ id, desc }) => ({
    field: id,
    dir: desc === 'desc' ? 'desc' : 'asc'
  }))
}

export const defaultBalancesQuery = {
  queries: [],
  queryOptions: {
    enabled: false,
    mapper: (data) =>
      data.reduce((acc, client) => ({ ...acc, [client.clientId]: client }), {})
  }
}

export const mapBookmarks = (data) => {
  return data.reduce(
    (acc, bookmark) => ({ ...acc, [bookmark.levelId]: bookmark }),
    {}
  )
}

export const defaultBookmarksQuery = {
  queries: [],
  queryOptions: {
    enabled: false,
    mapper: mapBookmarks
  }
}

export const mapClientBalancesToKeyValue = (data) => {
  return data.reduce(
    (acc, client) => ({ ...acc, [client.clientId]: client }),
    {}
  )
}
