import React, { useCallback, useImperativeHandle, useState } from 'react'
import PropTypes from 'prop-types'
import RoundedModal from '../../../../../molecules/RoundedModal'
import NewGroupForm from './NewGroupForm'

const NewGroupDialog = React.forwardRef(function NewGroupDialog ({ onCreate, client }, ref) {
  const [dialogState, setDialogState] = useState({ open: false, processing: false })
  const close = useCallback(() => {
    // We keep the parent here because it says "undefined" on the modal otherwise
    setDialogState((p) => ({ ...p, open: false, processing: false }))
  }, [setDialogState])

  const complete = useCallback(async (result) => {
    await onCreate({
      ...result,
      groupTypeId: dialogState.parent.groupTypeId,
      clientId: client.clientId
    })
    close()
  }, [close, dialogState, client, onCreate])

  useImperativeHandle(ref, () => ({
    open: (item) => setDialogState({ open: true, processing: false, parent: item })
  }), [setDialogState])

  return (
    <RoundedModal
      title={`Create ${dialogState?.parent?.longName}`}
      open={dialogState.open}
      onClose={close}
      size='xmedium'
      position='top'
      disablePortal={false}
    >
      <NewGroupForm onComplete={complete} onClose={close} />
    </RoundedModal>
  )
})

NewGroupDialog.propTypes = {
  onCreate: PropTypes.func,
  client: PropTypes.object
}

export default NewGroupDialog
