import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'
import { useAdvisorHome } from '../AdvisorHomeContext'
import { useSearchClients } from '../../../../api/clients'
import Metric from './Metric'

const useClientCount = (scope) => {
  const clientQuery = useMemo(() => {
    if (isEmpty(scope?.values)) {
      return {
        resultType: 'total'
      }
    }
    return {
      resultType: 'total',
      filters: {
        groupIds: [{ op: 'in', value: scope.values }]
      }
    }
  }, [scope])

  const { data, isLoading, error } = useSearchClients(clientQuery, { enabled: !!clientQuery })

  return {
    data,
    isLoading,
    error
  }
}

const ClientCountMetric = ({ title, valueFormat, ...metricProps }) => {
  const { scope } = useAdvisorHome()
  const { data, isLoading } = useClientCount(scope)

  return (
    <Metric
      header={title}
      isLoading={isLoading}
      value={data?.total}
      valueSize='3.5rem'
      valueFormat={valueFormat}
      {...metricProps}
    />
  )
}

ClientCountMetric.propTypes = {
  title: PropTypes.string,
  valueFormat: PropTypes.string
}

ClientCountMetric.defaultProps = {
  title: 'Client Base',
  valueFormat: 'number'
}

export default ClientCountMetric
