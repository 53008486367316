export const defaultColumnConfig = {
  columns: [
    { Header: 'User ID', accessor: 'userId', id: 'userId' },
    { Header: 'First Name', accessor: 'firstName', id: 'firstName' },
    { Header: 'Last Name', accessor: 'lastName', id: 'lastName' },
    { Header: 'Email', accessor: 'email', id: 'email' },
    { Header: 'Experience', accessor: 'role.name', id: 'experienceName' }
  ],
  defaultSort: [{ id: 'lastName', desc: false }]
}
