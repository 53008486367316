import { createContext, useContext, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { useAppContext } from '../redux/slices/appContext'
import { replaceValues } from '../utils/replacers'
import { useNormalizeDates } from '../api/coreData'
import { DATE_FORMAT, DATE_FORMAT_DASH } from '../constants'

dayjs.extend(utc)

export const getRelativeDateRanges = (relativeToDate) => {
  const currentDate = relativeToDate || dayjs().utc().format(DATE_FORMAT_DASH)
  const date = dayjs().utc(currentDate)
  return {
    yearStart: date.startOf('year').toISOString(),
    lastYearStart: date.add(-1, 'year').startOf('year').toISOString(),
    yearEnd: date.endOf('year').toISOString(),
    prevYearStart: date.add(-1, 'year').startOf('year').toISOString(),
    prevYearEnd: date.add(-1, 'year').endOf('year').toISOString(),
    quarterStart: date.startOf('quarter').toISOString(),
    quarterEnd: date.endOf('quarter').toISOString(),
    prevQuarterStart: date.add(-1, 'quarter').startOf('quarter').toISOString(),
    prevQuarterEnd: date.add(-1, 'quarter').endOf('quarter').toISOString(),
    monthStart: date.startOf('month').toISOString(),
    monthEnd: date.endOf('month').toISOString(),
    prevMonthStart: date.add(-1, 'month').startOf('month').toISOString(),
    prevMonthEnd: date.add(-1, 'month').endOf('month').toISOString(),
    weekStart: date.startOf('week').toISOString(),
    weekEnd: date.endOf('week').toISOString(),
    prevWeekStart: date.add(-1, 'week').startOf('week').toISOString(),
    prevWeekEnd: date.add(-1, 'week').endOf('week').toISOString(),
    nextWeekStart: date.add(1, 'week').startOf('week').toISOString(),
    nextWeekEnd: date.add(1, 'week').endOf('week').toISOString(),
    today: date.startOf('day').toISOString(),
    yesterday: date.add(-1, 'day').toISOString(),
    in1day: date.add(1, 'day').startOf('day').toISOString(),
    in7days: date.add(7, 'day').startOf('day').toISOString(),
    in30days: date.add(30, 'day').startOf('day').toISOString(),
    in1week: date.add(1, 'week').startOf('day').toISOString(),
    in1month: date.add(1, 'month').startOf('day').toISOString(),
    in1year: date.add(1, 'year').startOf('day').toISOString()
  }
}

export const relativeDateRanges = getRelativeDateRanges()

export const AbundanceEngineParameterContext = createContext({})

export const useAbundanceEngineParameterContextManager = (additionalParams = {}) => {
  const appContext = useAppContext()
  const history = useHistory()

  const queryParams = useMemo(() => {
    const searchParams = new URLSearchParams(history.location.search)
    return Object.fromEntries(searchParams.entries())
  }, [history])

  const lastBusinessDayQuery = useMemo(
    () => {
      const rawDate = dayjs.utc().subtract(1, 'day').format(DATE_FORMAT)
      return {
        query: {
          dateRanges: {
            lastBusinessDate: { startDate: rawDate, endDate: rawDate }
          }
        },
        queryOptions: {
          mapper: (data) => {
            return data?.lastBusinessDate?.endDate
          }
        }
      }
    },
    []
  )
  const { data: lastBusinessDate } = useNormalizeDates(
    lastBusinessDayQuery.query,
    lastBusinessDayQuery.queryOptions
  )

  const result = {
    ...queryParams,
    ...additionalParams,
    externalUserId: appContext.userId,
    userId: appContext.userProfile?.internalId,
    clientId: appContext.clientId,
    advisorId: additionalParams?.advisorId !== undefined
      ? additionalParams?.advisorId
      : appContext.advisorId,
    minDate: appContext.availableDates?.min,
    maxDate: appContext.availableDates?.mainDate ?? appContext.availableDates?.max,
    lastBusinessDay: lastBusinessDate,
    ...relativeDateRanges
  }

  return result
}

export const useAbundanceEngineParameters = () => {
  const parameterContext = useContext(AbundanceEngineParameterContext)

  return parameterContext
}

export const useParameterReplacement = (toReplace) => {
  const abundanceParams = useAbundanceEngineParameters()
  return useMemo(() => replaceValues(toReplace, abundanceParams), [toReplace, abundanceParams])
}
