import KeyMirror from 'keymirror'
import findIndex from 'lodash/findIndex'
import isEmpty from 'lodash/isEmpty'
import orderBy from 'lodash/orderBy'
import dayjs from 'dayjs'
import get from 'lodash/get'
import { modifyByIndex, removeByIndex } from '../../utils'
import {
  activeClientThreadsOverviewInitialState,
  activeThreadInitialState,
  noteThreadContextInitialState
} from '.'

export const ACTIONS = KeyMirror({
  CLEAR: null,
  SET_THREADS: null,
  SET_REPLIES: null,
  SET_CLIENTS_OVERVIEW: null,
  SET_ACTIVE_CLIENT_OVERVIEW: null,
  SET_ACTIVE_THREAD: null,
  ON_ADD_REPLY: null,
  ON_REMOVE_THREAD: null,
  ON_REMOVE_REPLY: null,
  ON_RESOLVE_THREAD: null,
  ON_ADD_REPLY_REACTION: null,
  ON_ADD_THREAD_REACTION: null,
  ON_REMOVE_REPLY_REACTION: null,
  ON_REMOVE_THREAD_REACTION: null,
  SET_IS_LOADING: null,
  TOGGLE_NOTE_EMPTY: null,
  UPDATE_THREAD_BY_ID: null,
  UPDATE_THREAD_REPLY_BY_ID: null,
  FIND_AND_SET_LAST_ACTIVE_THREAD: null,
  TOGGLE_SHOW_ONLY_ACTIVE_THREADS: null,
  SET_ACTIVE_CLIENT_OVERVIEW_BY_CLIENT_ID: null,
  REMOVE_THREAD_NOTIFICATION: null
})

const sortThreads = (threads) => {
  return orderBy(threads, ['resolved', 'updatedAt'], ['asc', 'desc'])
}

const sortClientsThreadsOverview = (clientsThreadsOverview) => {
  return orderBy(
    clientsThreadsOverview,
    [
      ({ lastUpdate }) => (!lastUpdate ? -1 : Date.parse(lastUpdate)),
      ({ lastUnreadNotificationAt }) =>
        !lastUnreadNotificationAt ? -1 : Date.parse(lastUnreadNotificationAt),
      ({ lastThreadUpdatedAt }) =>
        !lastThreadUpdatedAt ? -1 : Date.parse(lastThreadUpdatedAt),
      'familyName'
    ],
    ['desc', 'desc', 'desc', 'asc']
  )
}

const updateClientsThreadOverview = ({
  clientId: _clientId,
  clientsThreadsOverview
}) => {
  if (!_clientId) return clientsThreadsOverview
  const clientThreadOverviewIndex = clientsThreadsOverview.findIndex(
    ({ clientId }) => clientId === _clientId
  )
  const clientsThreadOverview = clientsThreadsOverview[clientThreadOverviewIndex]
  const clientsThreadOverviewModified = modifyByIndex(
    clientThreadOverviewIndex,
    clientsThreadsOverview,
    { ...clientsThreadOverview, lastUpdate: dayjs().format() }
  )
  return sortClientsThreadsOverview(clientsThreadOverviewModified)
}

export function reducer (state, { type: actionType, payload }) {
  try {
    switch (actionType) {
      case ACTIONS.TOGGLE_SHOW_ONLY_ACTIVE_THREADS: {
        return {
          ...state,
          showOnlyActiveThreads: payload
        }
      }
      case ACTIONS.SET_IS_LOADING: {
        return {
          ...state,
          isLoading: payload === undefined ? true : payload
        }
      }
      case ACTIONS.TOGGLE_NOTE_EMPTY: {
        return {
          ...state,
          isNoteEmpty: payload
        }
      }
      case ACTIONS.SET_THREADS: {
        const { threads, showOnlyActiveThreads, client, isLoading } = payload
        const { clientsThreadsOverview } = state
        const thread = get(threads, '0', { clientId: null })

        let clientThreadsOverview = activeClientThreadsOverviewInitialState
        if (thread.clientId && !isEmpty(clientsThreadsOverview)) {
          clientThreadsOverview = clientsThreadsOverview.find(({ clientId }) => {
            return clientId === thread.clientId
          })
        }

        return {
          ...state,
          threads: sortThreads(threads),
          threadsEmpty: isEmpty(threads),
          activeClientThreadsOverview: {
            ...clientThreadsOverview,
            ...client,
            clientId: thread.clientId
          },
          ...(isLoading !== undefined ? { isLoading } : {}),
          ...(showOnlyActiveThreads !== undefined
            ? { showOnlyActiveThreads }
            : {})
        }
      }
      case ACTIONS.SET_REPLIES: {
        const { replies, isLoading } = payload
        return {
          ...state,
          replies: [...replies],
          ...(isLoading !== undefined ? { isLoading } : {})
        }
      }
      case ACTIONS.SET_ACTIVE_THREAD: {
        const thread = payload
        return {
          ...state,
          activeThread: { ...(payload ? thread : activeThreadInitialState) },
          ...(payload ? {} : { replies: [] })
        }
      }
      case ACTIONS.ON_ADD_REPLY_REACTION: {
        const { replies: prevReplies } = state
        const { replyId, reaction } = payload

        const replyIndex = findIndex(prevReplies, ['replyId', replyId])
        const reply = prevReplies[replyIndex]

        return {
          ...state,
          replies: modifyByIndex(replyIndex, prevReplies, {
            ...reply,
            reactions: [...(reply?.reactions || []), { ...reaction }]
          })
        }
      }
      case ACTIONS.ON_ADD_THREAD_REACTION: {
        const { threads: prevThreads } = state
        const { threadId, reaction } = payload

        const threadIndex = findIndex(prevThreads, ['threadId', threadId])
        const thread = prevThreads[threadIndex]

        return {
          ...state,
          threads: modifyByIndex(threadIndex, prevThreads, {
            ...thread,
            reactions: [...(thread?.reactions || []), { ...reaction }]
          })
        }
      }
      case ACTIONS.ON_REMOVE_REPLY_REACTION: {
        const { replies: prevReplies } = state
        const { replyId, reactionId } = payload

        const replyIndex = findIndex(prevReplies, ['replyId', replyId])
        const reply = prevReplies[replyIndex]
        const reactionIndex = findIndex(reply.reactions, [
          'reactionId',
          reactionId
        ])
        if (reactionIndex === -1) return state

        return {
          ...state,
          replies: modifyByIndex(replyIndex, prevReplies, {
            ...reply,
            reactions: removeByIndex(reactionIndex, reply.reactions)
          })
        }
      }
      case ACTIONS.ON_REMOVE_THREAD_REACTION: {
        const { threads: prevThreads } = state
        const { threadId, reactionId } = payload

        const threadIndex = findIndex(prevThreads, ['threadId', threadId])
        const thread = prevThreads[threadIndex]
        const reactionIndex = findIndex(thread.reactions, [
          'reactionId',
          reactionId
        ])
        if (reactionIndex === -1) return state

        return {
          ...state,
          threads: modifyByIndex(threadIndex, prevThreads, {
            ...thread,
            reactions: removeByIndex(reactionIndex, thread.reactions)
          })
        }
      }
      case ACTIONS.FIND_AND_SET_LAST_ACTIVE_THREAD: {
        const { showOnlyActiveThreads, threads } = state
        const activeThreadId = payload

        const latestActiveThreadIndex = threads.findIndex(
          (thread) => thread.threadId === activeThreadId
        )
        const latestActiveThread = threads[latestActiveThreadIndex]

        return {
          ...state,
          activeThread: {
            ...(!latestActiveThread ||
            (latestActiveThread.resolved && showOnlyActiveThreads)
              ? activeThreadInitialState
              : latestActiveThread)
          }
        }
      }
      case ACTIONS.ON_RESOLVE_THREAD: {
        const { archivedThread, isLoading } = payload
        const { threads: prevThreads, showOnlyActiveThreads, clientsThreadsOverview } = state

        const threadIndex = prevThreads.findIndex(
          (thread) => thread.threadId === archivedThread.threadId
        )
        let threads = prevThreads
        if (showOnlyActiveThreads) {
          threads = removeByIndex(threadIndex, prevThreads)
        } else {
          const thread = prevThreads[threadIndex]
          threads = modifyByIndex(threadIndex, prevThreads, {
            ...thread,
            ...archivedThread
          })
        }
        return {
          ...state,
          threads: sortThreads(threads),
          ...(isLoading !== undefined ? { isLoading } : {}),
          clientsThreadsOverview: updateClientsThreadOverview({
            clientId: threads.clientId,
            clientsThreadsOverview
          })
        }
      }
      case ACTIONS.ON_REMOVE_THREAD: {
        const threadId = payload
        const { threads: prevThreads, clientsThreadsOverview } = state

        const threadIndex = findIndex(prevThreads, [
          'threadId',
          parseInt(threadId)
        ])

        return {
          ...state,
          threads: removeByIndex(threadIndex, prevThreads),
          activeThread: { ...activeThreadInitialState },
          clientsThreadsOverview: updateClientsThreadOverview({
            clientId: prevThreads[threadIndex]?.clientId,
            clientsThreadsOverview
          })
        }
      }
      case ACTIONS.ON_REMOVE_REPLY: {
        const { threadId, replyId } = payload
        const { threads: prevThreads, replies: prevReplies, clientsThreadsOverview } = state

        const threadIndex = prevThreads.findIndex(
          (thread) => thread.threadId === parseInt(threadId)
        )
        const thread = prevThreads[threadIndex]

        const replyIndex = prevReplies.findIndex(
          (reply) => reply.replyId === replyId
        )

        return {
          ...state,
          threads: modifyByIndex(threadIndex, prevThreads, {
            ...thread,
            replies: thread.replies - 1
          }),
          replies: removeByIndex(replyIndex, prevReplies),
          clientsThreadsOverview: updateClientsThreadOverview({
            clientId: thread.clientId,
            clientsThreadsOverview
          })
        }
      }
      case ACTIONS.ON_ADD_REPLY: {
        const { threadId, reply } = payload
        const { threads: prevThreads, replies: prevReplies, clientsThreadsOverview } = state

        const threadIndex = prevThreads.findIndex(
          (thread) => thread.threadId === threadId
        )
        const thread = prevThreads[threadIndex]

        return {
          ...state,
          replies: [...prevReplies, { ...reply }],
          threads: modifyByIndex(threadIndex, prevThreads, {
            ...thread,
            resolved: false,
            replies: thread.replies + 1
          }),
          clientsThreadsOverview: updateClientsThreadOverview({
            clientId: thread.clientId,
            clientsThreadsOverview
          })
        }
      }
      case ACTIONS.UPDATE_THREAD_BY_ID: {
        const { threadId, body } = payload
        const { threads: prevThreads, clientsThreadsOverview } = state

        const threadIndex = prevThreads.findIndex(
          (thread) => thread.threadId === threadId
        )
        if (threadIndex === -1) return state
        const thread = prevThreads[threadIndex]
        return {
          ...state,
          threads: sortThreads(
            modifyByIndex(threadIndex, prevThreads, {
              ...thread,
              ...body
            })
          ),
          clientsThreadsOverview: updateClientsThreadOverview({
            clientId: thread.clientId,
            clientsThreadsOverview
          })
        }
      }
      case ACTIONS.UPDATE_THREAD_REPLY_BY_ID: {
        const { replyId, body } = payload
        const { replies: prevReplies, clientsThreadsOverview } = state

        const replyIndex = prevReplies.findIndex(
          (reply) => reply.replyId === replyId
        )
        if (replyIndex === -1) return state
        const reply = prevReplies[replyIndex]
        return {
          ...state,
          replies: modifyByIndex(replyIndex, prevReplies, {
            ...reply,
            ...body
          }),
          clientsThreadsOverview: updateClientsThreadOverview({
            clientId: reply.clientId,
            clientsThreadsOverview
          })
        }
      }
      case ACTIONS.SET_CLIENTS_OVERVIEW: {
        const {
          clientsThreadsOverview,
          isLoading,
          clientsThreadOverviewEmpty
        } = payload
        return {
          ...state,
          clientsThreadsOverview: sortClientsThreadsOverview(
            clientsThreadsOverview
          ),
          ...(isLoading !== undefined ? { isLoading } : {}),
          ...(clientsThreadOverviewEmpty !== undefined
            ? { clientsThreadOverviewEmpty }
            : {})
        }
      }
      case ACTIONS.SET_ACTIVE_CLIENT_OVERVIEW: {
        const { clientThreadsOverview } = payload
        return {
          ...state,
          activeClientThreadsOverview: clientThreadsOverview
        }
      }
      case ACTIONS.SET_ACTIVE_CLIENT_OVERVIEW_BY_CLIENT_ID: {
        const { isLoading, clientsThreadsOverview } = payload
        return {
          ...state,
          ...(isLoading !== undefined ? { isLoading } : {}),
          clientsThreadsOverview: !isEmpty(clientsThreadsOverview)
            ? sortClientsThreadsOverview(clientsThreadsOverview)
            : [],
          clientsThreadOverviewEmpty: isEmpty(clientsThreadsOverview)
        }
      }
      case ACTIONS.REMOVE_THREAD_NOTIFICATION: {
        const { threads: prevThreads, clientsThreadsOverview } = state
        const { clientId, threadId, notificationIds } = payload

        const clientThreadsOverviewIndex = clientsThreadsOverview.findIndex(
          ({ clientId: id }) => id === clientId
        )
        const clientThreadsOverview =
          clientsThreadsOverview[clientThreadsOverviewIndex]

        const threadIndex = prevThreads.findIndex(
          ({ threadId: id }) => id === threadId
        )
        const notifications = prevThreads[threadIndex].notifications.filter(
          ({ notificationId: id }) => !notificationIds.includes(id)
        )

        const thread = {
          ...prevThreads[threadIndex],
          notifications
        }
        const threads = modifyByIndex(threadIndex, prevThreads, thread)
        const hasClientPendingNotifications = threads.some(
          (thread) => !isEmpty(thread?.notifications)
        )
        return {
          ...state,
          threads,
          clientsThreadsOverview: isEmpty(clientsThreadsOverview)
            ? []
            : sortClientsThreadsOverview(
              modifyByIndex(
                clientThreadsOverviewIndex,
                clientsThreadsOverview,
                {
                  ...clientThreadsOverview,
                  hasNewNotifications: hasClientPendingNotifications
                }
              )
            )
        }
      }
      case ACTIONS.CLEAR: {
        return noteThreadContextInitialState
      }
      default: {
        return state
      }
    }
  } catch (err) {
    console.error(err)
    return state
  }
}
