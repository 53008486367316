/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import numeral from 'numeral'
import dayjs from 'dayjs'
import first from 'lodash/first'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import { Grid, InputAdornment, InputBase, Slider, Typography } from '@material-ui/core'
import { ResponsiveLine } from '@nivo/line'
import remove from 'lodash/remove'
import { noop } from 'lodash'
import { useToggle } from '../../hooks'
import getPlanData from '../../hooks/dataFunction'
import MonteCarlo from '../../hooks/monteCarlo'
import { BUTTON_SIZES, BUTTON_VARIANT, CALC_TYPES } from '../../constants'
import RoundedButton from '../atoms/RoundedButton'
import Select from '../atoms/Select'
import Text from '../atoms/Text'
import DefaultLayout from '../../abundanceEngine/layouts/DefaultLayout'
import useFetchClientBalances from '../organisms/ClientCardsViewV2/hooks/useClientBalances'
import { useAppContext } from '../../redux/slices/appContext'
import PlanLine from './PlanLine'
import PlanGoalEdit from './PlanGoalEditAlt'
import PlanTier from './PlanTier'

const GOALS_OPTIONS = [{
  label: 'MY GOALS',
  value: 'myGoals'
}]
// Must have above component declaration for "dragging" to work
const SummitSlider = withStyles({
  root: {
    color: '#212945',
    height: 2
  },
  thumb: {
    height: 12,
    width: 12,
    backgroundColor: '#fff',
    border: '4px solid currentColor',
    marginTop: -5,
    marginLeft: -6,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit'
    }
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% - 12px)'
  },
  track: {
    height: 2,
    borderRadius: 2
  },
  rail: {
    height: 2,
    borderRadius: 2,
    color: '#d0d0d0'
  }
})(Slider)

const tiersInfo = {
  1: {
    tierId: '1',
    title: 'Base Needs',
    subtitle: '',
    secondSubtitle: '',
    lineColor: '#76C39A'
  },
  2: {
    tierId: '2',
    title: 'Aspirations',
    subtitle: '',
    secondSubtitle: '',
    lineColor: '#94E8BB'
  },
  3: {
    tierId: '3',
    title: 'What if',
    subtitle: '',
    secondSubtitle: '',
    lineColor: '#E2FBEE'
  }
}

const initialPlanGoals = [
  {
    goalId: '101',
    goalName: 'Retire in 2026',
    goalType: '',
    startDate: '1/1/2026',
    endDate: '',
    edit: 'false',
    rate: {
      rateType: 'Fixed',
      rateValue: '-300000',
      yearValueBase: '2020'
    },
    customFlows: [],
    adjustForInflation: 'true',
    adjustments: [
      {
        adjustDate: dayjs('1/1/2052'),
        adjustLevel: -0.0
      },
      {
        adjustDate: dayjs('1/1/2062'),
        adjustLevel: -0.0
      }
    ],
    status: 'on',
    tags: [],
    tier: '1',
    modified: 'false',
    originalGoal: {}
  },
  {
    goalId: '102',
    goalName: 'Oliver College',
    goalType: '',
    startDate: '1/1/2039',
    endDate: '1/1/2043',
    edit: 'false',
    rate: {
      rateType: 'Fixed',
      rateValue: '-75000',
      yearValueBase: '2020'
    },
    customFlows: [],
    adjustForInflation: 'true',
    adjustments: [],
    status: 'on',
    tags: [],
    tier: '1',
    modified: 'false',
    originalGoal: {}
  },
  {
    goalId: '103',
    goalName: 'Additional Savings',
    goalType: '',
    startDate: '1/1/2020',
    endDate: '1/1/2026',
    edit: 'false',
    rate: {
      rateType: 'Fixed',
      rateValue: '20000',
      yearValueBase: '2020'
    },
    customFlows: [],
    adjustForInflation: 'true',
    adjustments: [],
    status: 'on',
    tags: [],
    tier: '1',
    modified: 'false',
    originalGoal: {}
  },
  {
    goalId: '114',
    goalName: 'Retire early (2024)',
    goalType: '',
    startDate: '1/1/2024',
    endDate: '12/31/2025',
    edit: 'false',
    rate: {
      rateType: 'Fixed',
      rateValue: '-300000',
      yearValueBase: '2020'
    },
    customFlows: [],
    adjustForInflation: 'true',
    adjustments: [],
    status: 'off',
    tags: [],
    tier: '2',
    modified: 'false',
    originalGoal: {}
  },
  {
    goalId: '104',
    goalName: 'Down Payment on Sonoma Home',
    goalType: '',
    startDate: '1/1/2023',
    endDate: '1/1/2023',
    edit: 'false',
    rate: {
      rateType: 'OneTime',
      rateValue: '-250000',
      yearValueBase: '2020'
    },
    customFlows: [],
    adjustForInflation: 'true',
    adjustments: [],
    status: 'off',
    tags: [],
    tier: '2',
    modified: 'false',
    originalGoal: {}
  },
  {
    goalId: '105',
    goalName: 'Abundance - Travel Fund',
    goalType: '',
    startDate: '1/1/2026',
    endDate: '',
    edit: 'false',
    rate: {
      rateType: 'Fixed',
      rateValue: '-25000',
      yearValueBase: '2020'
    },
    customFlows: [],
    adjustForInflation: 'true',
    adjustments: [],
    status: 'off',
    tags: [],
    tier: '2',
    modified: 'false',
    originalGoal: {}
  },
  {
    goalId: '107',
    goalName: 'Fund Barnes Family LLC',
    goalType: '',
    startDate: '1/1/2026',
    endDate: '',
    edit: 'false',
    rate: {
      rateType: 'Fixed',
      rateValue: '-50000',
      yearValueBase: '2020'
    },
    customFlows: [],
    adjustForInflation: 'true',
    adjustments: [],
    status: 'off',
    tags: [],
    tier: '2',
    modified: 'false',
    originalGoal: {}
  },
  {
    goalId: '106',
    goalName: 'Social Justice Philanthropy',
    goalType: '',
    startDate: '1/1/2020',
    endDate: '1/1/2026',
    edit: 'false',
    rate: {
      rateType: 'Fixed',
      rateValue: '-5000',
      yearValueBase: '2020'
    },
    customFlows: [],
    adjustForInflation: 'true',
    adjustments: [],
    status: 'off',
    tags: [],
    tier: '3',
    modified: 'false',
    originalGoal: {}
  },
  {
    goalId: '108',
    goalName: 'Philanthropy in Retirement',
    goalType: '',
    startDate: '1/1/2026',
    endDate: '',
    edit: 'false',
    rate: {
      rateType: 'Fixed',
      rateValue: '-25000',
      yearValueBase: '2020'
    },
    customFlows: [],
    adjustForInflation: 'true',
    adjustments: [],
    status: 'off',
    tags: [],
    tier: '3',
    modified: 'false',
    originalGoal: {}
  },
  {
    goalId: '109',
    goalName: 'Empty Nest - Downsize House',
    goalType: '',
    startDate: '1/1/2053',
    endDate: '1/1/2053',
    edit: 'false',
    rate: {
      rateType: 'OneTime',
      rateValue: '2000000',
      yearValueBase: '2020'
    },
    customFlows: [],
    adjustForInflation: 'true',
    adjustments: [],
    status: 'off',
    tags: [],
    tier: '3',
    modified: 'false',
    originalGoal: {}
  },
  {
    goalId: '115',
    goalName: 'Pay off mortgage',
    goalType: '',
    startDate: '1/1/2025',
    endDate: '1/1/2025',
    edit: 'false',
    rate: {
      rateType: 'OneTime',
      rateValue: '-1000000',
      yearValueBase: '2020'
    },
    customFlows: [],
    adjustForInflation: 'false',
    adjustments: [],
    status: 'off',
    tags: [],
    tier: '3',
    modified: 'false',
    originalGoal: {}
  }
]

const initialPlanDetails = {
  currentDate: dayjs('1/1/2020'),
  currentAge: 53,
  retireDate: dayjs('1/1/2026'),
  retireAge: 63,
  planStartDate: dayjs('1/1/2020'),
  planEndDate: dayjs('1/1/2067'),
  // retireIncome: 360000,
  startValue: 0,
  periodReturns: [],
  adjustments: [
    {
      adjustDate: dayjs('1/1/2052'),
      adjustLevel: -0.0
    },
    {
      adjustDate: dayjs('1/1/2062'),
      adjustLevel: -0.0
    }
  ],
  skipThreshold: {
    skip: false,
    minYearsBetweenSkip: 1,
    returnThreshold: -0.1
  },
  inflationModel: {
    growthType: 'constant',
    growthFactor: 0.03,
    adjustReturns: false
  },
  portfolioGrowthModel: {
    growthType: 'constant',
    growthFactor: 0.069
  },
  planGoals: initialPlanGoals
}

const useStyles = makeStyles({
  root: {
    width: 250
  },
  inputTop: {
    fontSize: '24px',
    fontWeight: 700,
    width: '160px',
    paddingBottom: '0px'
  },
  goalsHeader: {
    margin: '24px 0px 24px 0px',
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between'
  }
})

const PlanHome = () => {
  const classes = useStyles()

  const simulationDetailsInitial = {
    // mean: 0.07,
    // standardDeviation: 0.10,
    frequency: 'y',
    periods: 100 - initialPlanDetails.currentAge,
    startDate: dayjs('1/1/2020'),
    startValue: initialPlanDetails.startValue,
    flows: [],
    correlations: [],
    iterations: 5000,
    planDetails: initialPlanDetails
  }

  const plotGuassianCurve = () => {
    const sampleSize = 10000
    const samples = []
    for (let index = 0; index < sampleSize; index++) {
      const randomNumber = MonteCarlo.gaussianRandom(0.079, 0.079 * 1.5, [])
      // const randomNumber = MonteCarlo.gaussianRandom(0, 1, [])
      samples.push(randomNumber)
    }
    const min = samples.reduce((prev, current) => {
      return (prev < current) ? prev : current
    })
    const max = samples.reduce((prev, current) => {
      return (prev > current) ? prev : current
    })
    // samples.push(min * 100)
    // samples.push(max * 100)
    const groupData = (values, step, min, max) => {
      const groupedData = []
      for (let i = min; i <= max; i = i + step) {
        const stepCount = values.filter(x => x >= i && x < (i + step)).length
        groupedData.push({ x: i, y: stepCount })
      }
      return groupedData
    }
    const groupedData = groupData(samples, 0.01, min, max)
    const formattedData = [
      {
        id: 'Distribution',
        color: '#2c81F4',
        data: groupedData
      }
    ]
    // groupedData.push({ x: 10000, y: Date.now() - startTime })
    // return samples
    return formattedData
  }

  // const [planData, setPlanData] = useState([]);
  const [simulationDetails, setSimulationDetails] = useState(simulationDetailsInitial)
  const [modelData, setModelData] = useState([])
  //   const [assumptionData, setAssumptionData] = useState([])
  const [monteCarloData, setMonteCarloData] = useState([])
  const [startValue, setStartValue] = useState(simulationDetailsInitial.startValue)
  const [growthFactor, setGrowthFactor] = useState(initialPlanDetails.portfolioGrowthModel.growthFactor)
  const [retireAge, setRetireAge] = useState(initialPlanDetails.retireAge)
  const [retireDate, setRetireDate] = useState(dayjs(initialPlanDetails.currentDate.format('MM-DD-YYYY')).add(initialPlanDetails.retireAge - initialPlanDetails.currentAge, 'y'))
  const [retireIncome, setRetireIncome] = useState(initialPlanDetails.retireIncome)
  const [planDetails, setPlanDetails] = useState(initialPlanDetails)
  const [planGoals, setPlanGoals] = useState(initialPlanGoals)
  const [checkedTiers, setCheckTiers] = useState(['1'])
  const [randomGroupedData, setRandomGroupedData] = useState([])
  const [randomCurveTime, setRandomCurveTime] = useState(0)
  const [openEditSection, toggleEditSection] = useToggle()
  const [editingGoalId, setEditingGoalId] = useState()

  const { clientId, availableDates: { mainDate } } = useAppContext()

  const {
    data: clientEndingValue,
    isLoading: isLoadingClientEndingValue
  } = useFetchClientBalances(
    {
      take: 1,
      startDate: mainDate,
      endDate: mainDate,
      calcType: CALC_TYPES.balance,
      orderBy: 'endingValue DESC',
      clientIds: [clientId]
    },
    {
      enabled: true,
      mapper: (data) => data?.[0]?.endingValue.toFixed(0) ?? null
    }
  )

  useEffect(() => {
    if (clientEndingValue) {
      setStartValue(clientEndingValue)
    }
  }, [clientEndingValue, setStartValue, setPlanDetails, planDetails])

  useEffect(() => {
    if (modelData.planData) {
      // console.log('modelData.planData rs', modelData.planData)
      // console.log('modelData.assumptionData rs', modelData.assumptionsData)
      const simDetailsPass = simulationDetails
      simDetailsPass.mean = planDetails.portfolioGrowthModel.growthFactor
      simDetailsPass.standardDeviation = simDetailsPass.mean * 1.5
      // simDetailsPass.standardDeviation = simDetailsPass.mean * 0.5
      setSimulationDetails(simDetailsPass)

      MonteCarlo.runSimulation(simDetailsPass, modelData.assumptionsData)
        .then(result => setMonteCarloData(result))
    }
  }, [modelData, planDetails.portfolioGrowthModel.growthFactor, simulationDetails])

  useEffect(() => {
    const startTime = Date.now()
    const groupedData = plotGuassianCurve()
    setRandomCurveTime(Date.now() - startTime)
    setRandomGroupedData(groupedData)
  }, [])

  useEffect(() => {
    planDetails.planGoals = planGoals
    planDetails.startValue = clientEndingValue
    const modelData = getPlanData(planDetails)
    simulationDetails.planDetails = planDetails
    setModelData(modelData)
    // console.log('modelData.planData', modelData.planData)
    // setPlanData(modelData.planData);
    // setAssumptionData(modelData.assumptionsData);
    // setPlanData(DataFunction.getPlanData(planDetails));
  }, [clientEndingValue, simulationDetails, planDetails, planGoals, startValue, growthFactor, retireDate, retireIncome, retireAge])

  useEffect(() => {
    // const newPlanDetails = planDetails
    planDetails.startValue = startValue
    simulationDetails.startValue = startValue
    // console.log('newPlanDetails ri', newPlanDetails);
    // setPlanDetails(newPlanDetails)
    // simulationDetails.startValue = newPlanDetails.startValue
  }, [planDetails, simulationDetails, planDetails.startValue, simulationDetails.startValue, startValue])

  // useEffect(() => {
  //   const planDetailsNew = planDetails
  //   console.log('planDetailsNew', planDetailsNew)
  //   planDetailsNew.planGoals = planGoals
  //   setPlanDetails(planDetailsNew)
  // }, [planDetails, planGoals])

  // useEffect(() => {
  //   console.log('monteCarloData', monteCarloData)
  // }, [monteCarloData])

  const growthHandler = (e) => {
    // console.log('e', e.target.value);
    const newGrowthFactor = (e.target.value * 1) / 100
    if (newGrowthFactor > 0.03 && newGrowthFactor < 0.2) {
      setGrowthFactor(newGrowthFactor)
      const newPlanDetails = planDetails
      newPlanDetails.portfolioGrowthModel.growthFactor = newGrowthFactor
      // console.log('newPlanDetails', newPlanDetails)
      // setPlanDetails(newPlanDetails)
    }
  }

  const inflationHandler = (e) => {
    const newInflationFactor = (e.target.value * 1) / 100
    if (newInflationFactor > 0 && newInflationFactor < 0.2) {
      setGrowthFactor(newInflationFactor)
      const newPlanDetails = planDetails
      newPlanDetails.inflationModel.growthFactor = newInflationFactor
    }
  }

  const retireAgeHandler = (e) => {
    // console.log('e', e.target.value);
    const newRetireAge = e.target.value * 1
    if (newRetireAge >= initialPlanDetails.currentAge && newRetireAge <= 150) {
      setRetireAge(newRetireAge)
      const newPlanDetails = planDetails
      newPlanDetails.retireAge = newRetireAge
      const newRetireYear = newRetireAge - initialPlanDetails.currentAge * 1
      // Can't use .add(newRetireYear, 'y') becuase it doesn't work in Safari
      newPlanDetails.retireDate = dayjs(initialPlanDetails.currentDate.format('MM/DD/YYYY')).add(dayjs.duration({ Y: newRetireYear }))
      setPlanDetails(newPlanDetails)
    }
  }

  const runMCHandler = (e) => {
    MonteCarlo.runSimulation(simulationDetails, modelData.assumptionsData)
      .then(result => setMonteCarloData(result))
  }

  const tierStatusHandler = useCallback((tier, tierChecked) => {
    if (tierChecked) {
      setCheckTiers([...checkedTiers, tier])
    } else {
      setCheckTiers(remove(checkedTiers, (element) => (element !== tier)))
    }
    const planGoalsNext = [...planGoals]
    planGoalsNext.filter(x => x.tier === tier).forEach(planGoal => {
      planGoal.status = (tierChecked ? 'on' : 'off')
    })
    setPlanGoals(planGoalsNext)
  }, [checkedTiers, planGoals])

  const goalStatusHandler = useCallback((goal) => {
    const planGoal = planGoals.filter(x => x.goalId === goal.goalId)[0]
    if (planGoal.status === 'on') {
      planGoal.status = 'off'
    } else {
      planGoal.status = 'on'
    }
    const planGoalsNext = [...planGoals]
    planGoalsNext.filter(x => x.goalId === goal.goalId)[0].status = planGoal.status
    setPlanGoals(planGoalsNext)
  }, [planGoals])

  const goalEditHandler = useCallback((goal) => {
    if (editingGoalId === goal.goalId) return
    const planGoalsNext = [...planGoals]
    if (editingGoalId) {
      planGoalsNext.filter(x => x.goalId === editingGoalId)[0].edit = 'false'
    }
    planGoalsNext.filter(x => x.goalId === goal.goalId)[0].edit = 'true'
    setPlanGoals(planGoalsNext)
    setEditingGoalId(goal.goalId)
  }, [planGoals, editingGoalId])

  const onClickEdit = useCallback((goal) => {
    if (!openEditSection) toggleEditSection()
    goalEditHandler(goal)
  }, [goalEditHandler, toggleEditSection, openEditSection])

  const closeEditSectionHandler = useCallback(() => {
    toggleEditSection()
    const planGoalsNext = [...planGoals]
    planGoalsNext.filter(x => x.goalId === editingGoalId)[0].edit = 'false'
    setPlanGoals(planGoalsNext)
    setEditingGoalId(undefined)
  }, [planGoals, toggleEditSection, editingGoalId])

  const goalDateHandler = useCallback((goal, newValue) => {
    const planGoal = planGoals.filter(x => x.goalId === goal.goalId)[0]
    console.log('newValue', newValue)
    planGoal.startDate = newValue.newStartDate
    planGoal.endDate = newValue.newEndDate
    console.log('planGoal', planGoal)
    const planGoalsNext = [...planGoals]
    planGoalsNext.filter(x => x.goalId === goal.goalId)[0].startDate = planGoal.startDate
    planGoalsNext.filter(x => x.goalId === goal.goalId)[0].endDate = planGoal.endDate
    console.log('goal.goalId', goal.goalId)
    if (goal.goalId === '101') {
      console.log('goalDateHandler planDetails.retireDate', planDetails.retireDate)
      planDetails.retireDate = newValue.newStartDate
    }
    setPlanGoals(planGoalsNext)
  }, [planDetails, planGoals])

  const goalValueHandler = useCallback((newValue) => {
    console.log('goalValueHandler newValue', newValue)
    const planGoalsNext = [...planGoals]
    planGoalsNext.filter(x => x.goalId === editingGoalId)[0].rate.rateValue = newValue
    setPlanGoals(planGoalsNext)
  }, [planGoals, editingGoalId])

  //   const retireDateHandler = (e) => {
  //     console.log('e', e.target.value)
  //     try {
  //       const newRetireDate = dayjs(e.target.value)
  //       setRetireDate(newRetireDate)
  //       const newPlanDetails = planDetails
  //       newPlanDetails.retireDate = newRetireDate
  //       // console.log('newPlanDetails rd', newPlanDetails);
  //       setPlanDetails(newPlanDetails)
  //     } catch (e) {

  //     }
  //   }

  const retireIncomeHandler = (e) => {
    // console.log('e', e.target.value);
    const newRetireIncome = e.target.value * 1
    setRetireIncome(newRetireIncome)
    const newPlanDetails = planDetails
    newPlanDetails.retireIncome = newRetireIncome
    // console.log('newPlanDetails ri', newPlanDetails);
    setPlanDetails(newPlanDetails)
  }

  const startValueHandler = (e, newValue) => {
    console.log('e', e.target.value)
    console.log('newValue', newValue)
    const newStartValue = (newValue || e.target.value) * 1
    console.log('newStartValue', newStartValue)
    setStartValue(newStartValue)
  }

  // handleRetireAge
  const handleReturnSlider = (event, newValue) => {
    // console.log('event, newValue', event, newValue);
    const newGrowthFactor = newValue * 1 / 100
    if (newGrowthFactor > 0.03 && newGrowthFactor < 0.2) {
      setGrowthFactor(newGrowthFactor)
      const newPlanDetails = planDetails
      newPlanDetails.portfolioGrowthModel.growthFactor = newGrowthFactor
      // console.log('newPlanDetails', newPlanDetails);
      setPlanDetails(newPlanDetails)
    }
  }

  const handleRetireAgeSlider = (event, newValue) => {
    // console.log('event, newValue', event, newValue);
    const newRetireAge = newValue * 1
    if (newRetireAge >= initialPlanDetails.currentAge && newRetireAge <= 150) {
      setRetireAge(newRetireAge)
      const newPlanDetails = planDetails
      newPlanDetails.retireAge = newRetireAge
      const newRetireYear = newRetireAge - initialPlanDetails.currentAge * 1
      // Can't use .add(newRetireYear, 'y') becuase it doesn't work in Safari
      newPlanDetails.retireDate = dayjs(initialPlanDetails.currentDate.format('MM/DD/YYYY')).add(dayjs.duration({ Y: newRetireYear }))
      // console.log('newPlanDetails ras', newPlanDetails);
      setPlanDetails(newPlanDetails)
    }
  }

  const handleRetireIncomeSlider = (event, newValue) => {
    // console.log('event, newValue', event, newValue);
    const newRetireIncome = newValue * 1
    setRetireIncome(newRetireIncome)
    const newPlanDetails = planDetails
    newPlanDetails.retireIncome = newRetireIncome
    // console.log('newPlanDetails ra', newPlanDetails);
    setPlanDetails(newPlanDetails)
  }

  // const handleStartValueSlider = (event, newValue) => {
  //     // console.log('event, newValue', event, newValue);
  //     const newRetireIncome = newValue * 1
  //     setRetireIncome(newRetireIncome)
  //     const newPlanDetails = planDetails
  //     newPlanDetails.retireIncome = newRetireIncome
  //     // console.log('newPlanDetails ra', newPlanDetails);
  //     setPlanDetails(newPlanDetails)
  // }

  const handleEditRateType = useCallback((newValue) => {
    const planGoalsNext = [...planGoals]
    planGoalsNext.filter(x => x.goalId === editingGoalId)[0].rate.rateType = newValue
    setPlanGoals(planGoalsNext)
  }, [editingGoalId, planGoals])

  const handleEditGoalName = useCallback((newValue) => {
    const planGoalsNext = [...planGoals]
    planGoalsNext.filter(x => x.goalId === editingGoalId)[0].goalName = newValue
    setPlanGoals(planGoalsNext)
  }, [editingGoalId, planGoals])

  const showPercentiles = () => {
    const percentiles = ['10', '25', '50', '75', '90']

    const percentValueReturn = percentiles.map((item) => {
      const itemData = monteCarloData.percentiles.filter(x => x.percentile === item)[0].data
      let itemValue = numeral(itemData.endValue).format('0a').toUpperCase()
      const itemKey = `percentileContainer${item}`
      let valueClass = 'percentValue inRange'
      if (itemData.endValue === 0) {
        itemValue = (dayjs(itemData.lastFundDate).year() - 2019) + initialPlanDetails.currentAge
        itemValue = '(' + itemValue + ' yrs)'
        valueClass = 'percentValue alert'
      }

      return (
        <div key={itemKey} className='percentileContainer'>
          <div className='percentHeader'>{item}%</div>
          <div className='tickMark' />
          <div className='middleLine' />
          <div className='tickMark' />
          <div className={valueClass}>{itemValue}</div>
        </div>
      )
    })

    return percentValueReturn
  }

  const tiersRedered = useMemo(() => {
    if (!simulationDetails.planDetails.planGoals) return null

    const goalsByTiers = simulationDetails.planDetails.planGoals.reduce((acc, planGoal) => {
      if (!acc[planGoal.tier]) {
        return {
          ...acc,
          [planGoal.tier]: [planGoal]
        }
      } else {
        return {
          ...acc,
          [planGoal.tier]: [...acc[planGoal.tier], planGoal]
        }
      }
    }, {})

    return Object.keys(tiersInfo).map((tierId) => (
      <PlanTier
        key={tierId}
        tierInfo={tiersInfo[tierId]}
        isChecked={checkedTiers.includes(tierId)}
        tierStatusHandler={tierStatusHandler}
        goalEditHandler={onClickEdit}
        goalStatusHandler={goalStatusHandler}
        goals={goalsByTiers[tierId]}
      />
    ))
  }, [onClickEdit, goalStatusHandler, tierStatusHandler, simulationDetails.planDetails.planGoals, checkedTiers])

  const goalEditSectionRendered = useMemo(() => {
    if (!simulationDetails.planDetails.planGoals) return null
    if (!openEditSection) return null

    const editGoal = first(simulationDetails.planDetails.planGoals.filter(x => x.edit === 'true'))
    if (!editGoal) return null
    return (
      <Grid item xs={12} style={{ display: 'flex', padding: '6px 0px', fontSize: '14px', alignSelf: 'start', backgroundColor: 'transparent' }}>
        <PlanGoalEdit
          goal={editGoal}
          goalDateHandler={goalDateHandler}
          goalValueHandler={goalValueHandler}
          tier={tiersInfo[editGoal.tier]}
          handleCloseSection={closeEditSectionHandler}
          handleEditRateType={handleEditRateType}
          handleEditGoalName={handleEditGoalName}
        />
      </Grid>
    )
  }, [openEditSection, goalDateHandler, goalValueHandler, closeEditSectionHandler, handleEditGoalName, handleEditRateType, simulationDetails.planDetails.planGoals])

  return (
    <DefaultLayout>
      <div className='App'>
        <header className='App-header'>
          <Grid
            container
            spacing={0}
            alignItems='center'
            style={{
              padding: '0px  24px',
              justifyContent: 'space-between',
              maxWidth: '1440px'
            }}
          >
            <Grid item xs={12} md={4} style={{ padding: '0px 0px' }}>
              <Typography
                id='starting-value-label'
                gutterBottom
                style={{ fontSize: '24px', fontWeight: '700' }}
              >
                Base Retirement Plan
              </Typography>
            </Grid>
            <Grid item xs={4} md={2} style={{ padding: '0px 0px' }}>
              <InputBase
                className={classes.inputTop}
                value={planDetails.startValue}
                margin='dense'
                onChange={startValueHandler}
                startAdornment={
                  <InputAdornment position='start'>$</InputAdornment>
                }
                style={{ width: '180px' }}
                inputProps={{
                  step: 100000,
                  min: initialPlanDetails.startValue - 5000000,
                  max: initialPlanDetails.startValue + 20000000,
                  type: 'number',
                  'aria-labelledby': 'input-slider'
                }}
              />
              <Typography id='starting-value-label' gutterBottom>
                Starting Portfolio Value
              </Typography>
            </Grid>
            <Grid item xs={4} md={2} style={{ padding: '0px' }}>
              <InputBase
                className={classes.inputTop}
                value={(
                  planDetails.portfolioGrowthModel.growthFactor * 100
                ).toFixed(1)}
                margin='dense'
                onChange={growthHandler}
                endAdornment={<InputAdornment position='end'>%</InputAdornment>}
                inputProps={{
                  step: 0.1,
                  min:
                    initialPlanDetails.portfolioGrowthModel.growthFactor * 100 -
                    3,
                  max:
                    initialPlanDetails.portfolioGrowthModel.growthFactor * 100 +
                    3,
                  type: 'number',
                  'aria-labelledby': 'input-slider'
                }}
              />
              <Typography id='discrete-slider' gutterBottom>
                Portfolio Growth
              </Typography>
            </Grid>
            <Grid item xs={4} md={2} style={{ padding: '0px' }}>
              <InputBase
                className={classes.inputTop}
                value={(
                  planDetails.inflationModel.growthFactor * 100
                ).toFixed(1)}
                margin='dense'
                onChange={inflationHandler}
                endAdornment={<InputAdornment position='end'>%</InputAdornment>}
                inputProps={{
                  step: 0.1,
                  min:
                    initialPlanDetails.inflationModel.growthFactor * 100 -
                    3,
                  max:
                    initialPlanDetails.inflationModel.growthFactor * 100 +
                    3,
                  type: 'number',
                  'aria-labelledby': 'input-slider'
                }}
              />
              <Typography id='discrete-slider' gutterBottom>
                Inflation
              </Typography>
            </Grid>
            <Grid item xs={12} style={{ height: '450px', width: '100%' }}>
              <PlanLine data={modelData.planData} />
            </Grid>
            <Grid
              item
              xs={12}
              md={12}
              style={{
                textAlign: 'right',
                marginRight: '48px',
                marginTop: '-148px',
                fontSize: '32px',
                fontWeight: '700',
                color: '#212945',
                zIndex: 100
              }}
            >
              <div
                style={{
                  lineHeight: '1.0',
                  marginTop: '12px',
                  marginBottom: '6px'
                }}
              >
                {(() => {
                  let successRate =
                    monteCarloData.successRate > 0
                      ? monteCarloData.successRate * 100
                      : 0
                  successRate = `${successRate.toFixed(0)}-${Math.min(
                    successRate * 1.1,
                    100
                  ).toFixed(0)}`
                  console.log('successRate', successRate)
                  return successRate
                })()}
                %
              </div>
              <div
                style={{
                  fontSize: '12px',
                  fontWeight: '400',
                  marginTop: '0px'
                }}
                title={`${simulationDetails.iterations} virtual lifetimes - ${monteCarloData.processTime}ms`}
              >
                with 0-10% adjustment needed
              </div>
              {/* <div style={{ fontSize: '10px', color: '#999999', marginTop: '6px' }}>
              @ {simulationDetails.iterations} virtual lifetimes - {monteCarloData.processTime}ms
              <br />@ {monteCarloData.processTime}ms ({modelData.processTime})
            </div> */}
            </Grid>
            <Grid item xs={12} className={classes.goalsHeader}>
              <Select
                options={GOALS_OPTIONS}
                onChange={noop}
                selectedValue='myGoals'
              />
              <RoundedButton
                size={BUTTON_SIZES.small}
                disabled
                variant={BUTTON_VARIANT.text}
              >
                <Text text='' customFontSize='12px' />
              </RoundedButton>
            </Grid>
            {openEditSection && goalEditSectionRendered}
            {tiersRedered}
            <Grid
              item
              xs={12}
              style={{
                padding: '12px',
                textAlign: 'center',
                marginTop: '50px'
              }}
            >
              <div onClick={runMCHandler}>
                Percentiles (
                {(monteCarloData.successRate > 0
                  ? monteCarloData.successRate * 100
                  : 0
                ).toFixed(1)}
                %)
              </div>
              <br />
              {/* TODO: code commented until returns something valid */}
              {/* {monteCarloData.percentiles && monteCarloData.percentiles.length > 0 &&
                        monteCarloData.percentiles.map((item) => {
                          return (
                              <div>{item.percentile} - {numeral(item.data.endValue).format('0a')}</div>
                          )
                        })} */}
              <div>
                {monteCarloData.percentiles && monteCarloData.percentiles.length > 0 && (
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    {showPercentiles()}
                  </div>
                )}
              </div>
            </Grid>
            <Grid item xs={12} style={{ padding: '12px' }}>
              {/* - add years (w/ age, date) for simulation
            <br />
            - add years (w/ age, date) for monte carlo analysis
            <br />
            - add leverage slider/analysis (with interest rates)
            <br /> */}
              Monte Carlo Stochastic Model {randomCurveTime} ms
              <br />
              <div
                style={{
                  background: 'transparent',
                  height: '300px',
                  width: '600px'
                }}
              >
                <ResponsiveLine
                  animate
                  data={randomGroupedData}
                  margin={{ top: 30, right: 30, bottom: 30, left: 45 }}
                  xScale={{
                    type: 'linear',
                    tickValues: 10,
                    min: -0.45,
                    max: 0.55
                  }}
                  // xFormat='time:  %Y'
                  // yScale={{
                  //   type: 'linear',
                  //   stacked: false,
                  //   min: 0
                  // }}
                  // yFormat='.2s'
                  // axisLeft={{
                  //   legend: 'portfolio value',
                  //   legendOffset: 12,
                  //   format: '.2s'
                  //   // format: '.2s'
                  // }}
                  // axisBottom={{
                  //   format: '%Y',
                  //   // tickValues: 'every 2 days',
                  //   legend: '',
                  //   legendOffset: -12
                  // }}
                  layers={[
                    'grid',
                    'markers',
                    'axes',
                    'areas',
                    'crosshair',
                    'lines',
                    'points',
                    'slices',
                    'mesh',
                    'legends'
                  ]}
                  colors={(d) => d.color}
                  lineWidth={3}
                  curve='basis'
                  enableArea
                  enableGridX={false}
                  pointSize={0}
                  areaOpacity={0.8}
                  useMesh
                  motionStiffness={175}
                  motionDamping={25}
                  theme={{
                    axis: {
                      legend: {
                        text: {
                          fontFamily: 'GothamPro',
                          fontWeight: 700
                        }
                      },
                      ticks: {
                        text: {
                          fontFamily: 'GothamPro',
                          fontWeight: 700
                        }
                      }
                    }
                  }}
                />
              </div>
              {randomGroupedData.map((value) => {
                return (
                  <div key={`${value.x}`}>
                    {value.x} {value.y}
                  </div>
                )
              })}
            </Grid>
          </Grid>
        </header>
      </div>
    </DefaultLayout>
  )
}

export default PlanHome
