import React from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import { IconButton } from '@material-ui/core'
import { ICON_NAMES } from '../../constants'
import Icon from '../atoms/Icon'

const ExportCsvButton = ({ onClick }) => {
  return (
    <IconButton onClick={onClick}>
      <Icon
        name={ICON_NAMES.download}
        customSize='1.5rem'
        color='black'
      />
    </IconButton>
  )
}

ExportCsvButton.propTypes = {
  onClick: PropTypes.func
}

ExportCsvButton.defaultProps = {
  onClick: noop
}

export default ExportCsvButton
