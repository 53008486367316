import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { useGetLastSession } from '../../../../api/rebalancer'
import FadeIn from '../../../molecules/Transitions/FadeIn'

const useStyles = makeStyles((theme) => ({
  lastSessionLink: {}
}))

function LastSessionLink ({ clientId }) {
  const classes = useStyles()
  const { data, isLoading } = useGetLastSession(clientId)

  if (isLoading || !data) {
    return null
  }

  return (
    <FadeIn>
      <Link className={classes.lastSessionLink} to={`/rebalancer/results/${data.rebalancingRunId}`}>Previous Session</Link>
    </FadeIn>
  )
}

LastSessionLink.propTypes = {
  clientId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
}

export default LastSessionLink
