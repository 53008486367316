import { useMemo } from 'react'
import { useWidgetContext } from '../../../molecules/WidgetWrapper'

export const useSubscribedFilters = (suffix = 'Id') => {
  const { subscribedFilters } = useWidgetContext()
  return useMemo(() => {
    if (!subscribedFilters?.levelFilters) {
      return {}
    }
    return Object.entries(subscribedFilters?.levelFilters).reduce((prev, [key, value]) => {
      const k = key.toLowerCase()
      if (k.endsWith('id')) {
        const newKey = `${key.slice(0, -2)}${suffix}`
        prev[newKey] = value
        return prev
      }

      if (k.endsWith('ids')) {
        const newKey = `${key.slice(0, -3)}${suffix}`
        prev[newKey] = value
        return prev
      }

      prev[key] = value
      return prev
    }, {})
  }, [subscribedFilters?.levelFilters, suffix])
}
