import PerformancePivotTableV3 from './PerformancePivotTable'
import PerformanceGroupingsV3 from './PerformanceGroupings'
import PerformanceGroupingPickerV3 from './PerformanceGroupings/GroupingPicker'
import PerformanceTableV3 from './index'

const performanceV3Module = {
  PerformanceTableV3,
  PerformancePivotTableV3,
  PerformanceGroupingsV3,
  PerformanceGroupingPickerV3
}

export default performanceV3Module
