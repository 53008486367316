import React, { useCallback, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { useCustomDataset } from '../../../../../contexts/CustomDatasetContext'
import GroupByOwner from './GroupByOwner'
import GroupByClient from './GroupByClient'
import PageSizePicker from './PageSizePicker'

const groupByMap = {
  owner: GroupByOwner,
  client: GroupByClient
}

/** custom hook */
const useDetailPage = (defaultTake) => {
  const [take, setTake] = useState(defaultTake)
  const onSelect = useCallback((value) => {
    setTake(value)
  }, [setTake])

  return useMemo(() => ({
    pageSize: {
      value: take,
      onSelect
    }
  }), [take, onSelect])
}

const TaskDetailsDatasetAdapter = ({ datasetIndex, groupBy, take, linkBaseUrl }) => {
  const dataset = useCustomDataset()
  const detailPage = useDetailPage(take)
  const rows = useMemo(() => dataset.isDone ? dataset.value[datasetIndex] : [], [dataset, datasetIndex])
  const Grouping = useMemo(() => (groupBy in groupByMap) ? groupByMap[groupBy] : GroupByOwner, [groupBy])

  return (
    <div>
      <header>
        <PageSizePicker {...detailPage.pageSize} />
      </header>
      <section>
        <Grouping data={rows} take={detailPage.pageSize.value} linkBaseUrl={linkBaseUrl} />
      </section>
    </div>
  )
}

TaskDetailsDatasetAdapter.propTypes = {
  datasetIndex: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  groupBy: PropTypes.string,
  take: PropTypes.number,
  linkBaseUrl: PropTypes.string
}

TaskDetailsDatasetAdapter.defaultProps = {
  datasetIndex: 0,
  groupBy: 'owner',
  take: 10,
  linkBaseUrl: null
}

export default TaskDetailsDatasetAdapter

// const owners = Object.entries(groupBy(rows, 'ownerId')).map(([key, value]) => {
//   return {
//     ownerId: key,
//     taskCount: value.length
//   }
// })
// owners.sort((a, b) => a.taskCount < b.taskCount ? 1 : -1)
// // console.log('owners', owners)
// // const ownerArray = Object.keys()
// // console.log('rows', rows)
// // console.log('owners', owners)
// return (
//   <Grid style={{ display: 'flex', flexWrap: 'wrap' }}>{rows.length > 0 &&
//     owners.map(owner => {
//       const newRows = rows.filter(x => x.ownerId === owner.ownerId)
//       const filteredRows = newRows.slice(0, 10)
//       // console.log('newRows', newRows[0])
//       return (
//         <Grid key={owner.ownerId} style={{ width: '450px', margin: '1rem' }}>
//           <Grid style={{ display: 'flex', marginBottom: '1rem' }}>
//             <Avatar
//               src={newRows[0].photoUrl}
//               avatarLetters={newRows[0].ownerFirstName || ''}
//               customSize='64px'
//               isPublicResource
//               // customClassName={classes.avatar}
//             />
//             {/* <img src={newRows[0].photoUrl} /> */}
//             <div style={{ alignSelf: 'center', marginLeft: '1rem' }}>
//               <div style={{ fontSize: '1.5rem', fontWeight: '700' }}>{newRows[0].ownerFirstName} {newRows[0].ownerLastName.substring(0, 1)}.</div>
//               <div style={{ fontSize: '0.75rem', color: '#999999' }}>{newRows.length > 10 ? `Showing 10 tasks of ${newRows.length}` : ''}</div>
//             </div>
//
//           </Grid>
//           <TaskDetails data={filteredRows} columns={defaultColumnConfig} />
//         </Grid>
//
//       )
//     }
//     )}
//   </Grid>
// )
