import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import {
  useClientTeamMembers,
  useCurrentClient
} from '../../../../api/clients'
import { useIntegrations } from '../../../../hooks'
import { ContextContainerProvider } from '../../../../abundanceEngine/components/ContextContainer'
import { CLIENT_VIEW_CONTEXT } from '../../ClientCardsViewV2'
import { ContextualClient } from '../../ContextualClientInfo/contextualClientComponents'
import ClientTeamMember from './ClientTeamMember'

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '2rem',
    borderRadius: '0.5rem',
    border: '1px solid #E9EAEF',
    background: '#FFF',
    padding: '1.5rem 0.75rem',
    overflowX: 'auto'
  }
}))

const ClientTeamMembers = ({
  limit,
  showIntegrations,

  /** Should the team member avatars be treated as a absolute or relative url */
  absoluteAvatarUrls
}) => {
  const classes = useStyles()
  const { data: client, isLoading } = useCurrentClient()

  const queryOptions = useMemo(
    () => ({
      enabled: !isLoading,
      mapper: (data) => data?.slice(0, limit) ?? []
    }),
    [limit, isLoading]
  )

  const { data: clientTeamMembers, isLoading: isLoadingTeamMembers } =
    useClientTeamMembers(client?.clientId, queryOptions)

  const integrations = useIntegrations({ includeActiveCrmFlag: true })

  const contextState = useMemo(
    () => ({
      name: CLIENT_VIEW_CONTEXT,
      initialState: {
        drawerOpen: false,
        integrations: {},
        integrationsInfo: {}
      }
    }),
    []
  )

  if (isLoading || isLoadingTeamMembers) {
    return (
      <div className={classes.container}>
        {Array(4)
          .fill(0)
          .map((_, index) => {
            return <ClientTeamMember key={index} isLoading />
          })}
      </div>
    )
  }

  return (
    <ContextContainerProvider context={contextState}>
      <div className={classes.container}>
        {clientTeamMembers.map((teamMember) => {
          const fullName = `${teamMember.firstName} ${teamMember.lastName}`
          const jobTitle = teamMember.title
          return (
            <ClientTeamMember
              key={teamMember.teamMemberId}
              clientId={client.clientId}
              fullName={fullName}
              jobTitle={jobTitle}
              integrations={integrations}
              profilePic={teamMember.profilePic}
              showIntegrations={showIntegrations}
              absoluteAvatarUrls={absoluteAvatarUrls}
            />
          )
        })}
      </div>
      {showIntegrations && (
        <ContextualClient.Wrapper>
          <ContextualClient.Drawer>
            <ContextualClient.Info />
            <ContextualClient.Category title='Tags'>
              <ContextualClient.Tags />
            </ContextualClient.Category>
            <ContextualClient.Category title='General Information'>
              <ContextualClient.IntegrationMapper />
            </ContextualClient.Category>
          </ContextualClient.Drawer>
        </ContextualClient.Wrapper>
      )}
    </ContextContainerProvider>
  )
}

ClientTeamMembers.propTypes = {
  limit: PropTypes.number,
  showIntegrations: PropTypes.bool,
  absoluteAvatarUrls: PropTypes.bool
}

export default ClientTeamMembers
