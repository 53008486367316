import React, { useCallback, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Controller, useForm } from 'react-hook-form'
import SydButton from '../../../../../../commonDesign/Button'
import {
  useAccountBillingDetails,
  useAddClientOverrideMutation,
  useClientBillingDetails,
  useFeeSchedule
} from '../../../../../../../api/billing'
import { useDialogStyles } from '../../common'
import Loading from '../../../../../../molecules/Loading'
import SydLabel from '../../../../../../commonDesign/SydLabel'
import SelectBox from '../../shared/SelectBox'
import FeeScheduleTiers from '../../FeeScheduleTiers'
import FeeCalcTypeCard from '../FeeCalcTypeCard'
import { useClientReviewContext } from '../../ClientReviewContext'

const useSubmitter = (form, onComplete, client) => {
  const { handleSubmit } = form
  const [processing, setProcessing] = useState(false)
  const [error, setError] = useState(null)
  const { mutateAsync: addAccountOverride } = useAddClientOverrideMutation()
  const onSubmit = useCallback(async (formData) => {
    const command = {
      clientId: client.clientId,
      positionId: +formData.positionId,
      feeScheduleId: +formData.feeScheduleId,
      feeCalcTypeId: +formData.feeCalcTypeId,
      overrideData: +formData.feeCalcTypeId === 5 ? { overrideAmount: +formData.overrideAmount } : {}
    }

    try {
      setError(null)
      setProcessing(true)
      const result = await addAccountOverride(command)
      if (result?.statusCode === 500) {
        throw new Error('Failed to add fee schedule')
      }
      onComplete(result)
    } catch (err) {
      setError(err?.toString())
    } finally {
      setProcessing(false)
    }
  }, [addAccountOverride, setProcessing, onComplete, client, setError])

  const submitter = useCallback(async (e) => {
    const onValid = async (form) => {
      await onSubmit(form)
    }
    const onInvalid = (errors) => {
      console.error(errors)
    }

    const handler = handleSubmit(onValid, onInvalid)
    await handler(e)
  }, [handleSubmit, onSubmit])

  return {
    submitter,
    processing,
    error
  }
}

const labelAccessor = ['accountName', 'accountNumber']
const positionLabels = ['assetIdentifier', 'assetName', 'endingValue']
function AddPositionOverrideForm ({ client, onCancel, onComplete }) {
  const classes = useDialogStyles()
  const { editingSettings } = useClientReviewContext()

  const form = useForm({
    mode: 'onChange',
    defaultValues: {
      clientId: client.clientId,
      accountId: '',
      feeScheduleId: '',
      positionId: '',
      feeCalcTypeId: '1'
    }
  })

  const feeScheduleId = form.watch('feeScheduleId')
  const accountId = form.watch('accountId')
  const { data: billingDetails, isLoading: billingDetailsLoading } = useClientBillingDetails(client.clientId)
  const { data: accountDetails, isFetching: accountDetailsLoading } = useAccountBillingDetails(accountId, { enabled: !!accountId })
  const { data: feeSchedules, isLoading: feeSchedulesLoading } = useFeeSchedule()
  const { submitter, processing, error } = useSubmitter(form, onComplete, client)

  const _accounts = useMemo(() => {
    return billingDetails?.body?.accounts || []
  }, [billingDetails])
  const _positions = useMemo(() => {
    const pos = accountDetails?.body?.positions || []
    const overrides = billingDetails.body?.overrides?.positionOverrides || []
    return pos.map(a => ({
      ...a,
      disabled: overrides.some(x => x.positionId === a.positionId)
    }))
  }, [accountDetails, billingDetails])

  const selectedFeeSchedule = useMemo(() => {
    return (feeSchedules || []).find(x => x.feeScheduleId === feeScheduleId)
  }, [feeScheduleId, feeSchedules])

  if (billingDetailsLoading || feeSchedulesLoading) {
    return <Loading />
  }

  return (
    <>
      <div className={classes.form}>
        <div className={classes.group}>
          <Controller
            name='accountId' control={form.control}
            render={(f => (
              <SydLabel label='Select an Account' style={{ width: '50%', marginRight: '10px' }}>
                <SelectBox
                  options={_accounts}
                  valueAccessor='accountId'
                  labelAccessor={labelAccessor}
                  {...f.field}
                />
              </SydLabel>
            ))}
          />
          <Controller
            name='positionId' control={form.control}
            render={(f => (
              <SydLabel label='Select an Position' style={{ width: '50%', paddingRight: '20px' }}>
                {accountDetailsLoading ? (
                  <Loading />
                ) : (
                  <SelectBox
                    options={_positions}
                    valueAccessor='positionId'
                    labelAccessor={positionLabels}
                    {...f.field}
                  />
                )}
              </SydLabel>
            ))}
          />
        </div>
        <div className={classes.group}>
          <Controller
            name='feeScheduleId' control={form.control}
            render={(f => (
              <SydLabel label='Select Fee Schedule' style={{ width: '50%', marginRight: '10px' }}>
                <SelectBox
                  options={feeSchedules}
                  valueAccessor='feeScheduleId'
                  labelAccessor='longName'
                  {...f.field}
                />
              </SydLabel>
            ))}
          />
          <div style={{ width: '50%', paddingRight: '20px' }}>
            {selectedFeeSchedule ? (
              <>
                <div>{selectedFeeSchedule.longName}</div>
                <FeeScheduleTiers feeSchedule={selectedFeeSchedule} />
                {editingSettings.canEditFeeCalcTypeId && <FeeCalcTypeCard form={form} />}
              </>
            ) : null}
          </div>
        </div>
      </div>
      <div className={classes.actions}>
        {error ? (<div className={classes.error}>{error}</div>) : null}
        <SydButton disabled={processing} variant='ghost' size='md' onClick={onCancel}>Cancel</SydButton>
        <SydButton
          variant='primary'
          size='md'
          onClick={submitter}
          processing={processing}
        >
          Save
        </SydButton>
      </div>
    </>
  )
}

AddPositionOverrideForm.propTypes = {
  client: PropTypes.shape({
    clientId: PropTypes.number
  }),
  onComplete: PropTypes.func,
  onCancel: PropTypes.func
}

export default AddPositionOverrideForm
