import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  preformattedTextField: {
    fontWeight: 400,
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.fontSizes.xxl,
    whiteSpace: 'pre-line',
    wordWrap: 'break-word'
  }
}))

function PreformattedTextField ({ field }) {
  const classes = useStyles()
  return (
    <pre className={classes.preformattedTextField}>{field.value}</pre>
  )
}

PreformattedTextField.propTypes = {
  field: PropTypes.shape({
    value: PropTypes.any
  })
}

export default PreformattedTextField
