import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { head } from 'lodash'
import { makeStyles, useTheme } from '@material-ui/core'
import Avatar from '../../../atoms/Avatar'
import {
  useAccountStatusDetails,
  useAccountStatusTotals
} from '../../AdvisorView/hooks/accountStatus'
import Text from '../../../atoms/Text'
import Metric from './Metric'
import InlineDividedMetric from './InlineDividedMetric'

const useStyles = makeStyles((theme) => ({
  container: {
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column'
    }
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '1rem',
    flexDirection: 'row'
  },
  details: {
    marginLeft: 'auto',
    [theme.breakpoints.down('md')]: {
      marginTop: '1rem',
      marginLeft: '0'
    }
  },
  title: {
    fontSize: '1.5rem',
    fontWeight: 'bold'
  }
}))

const AccountStatusMetric = ({
  title,
  valueFormat,
  dataSourceIds,
  redirectTo
}) => {
  const theme = useTheme()
  const classes = useStyles()
  const { loading, result } = useAccountStatusTotals(dataSourceIds)
  const { data: accounts, loading: loadingAccounts } = useAccountStatusDetails(
    dataSourceIds,
    {},
    { pageSize: 1 }
  )

  const custodian = useMemo(() => {
    const accountDetails = head(accounts) || []
    const custodian = head(accountDetails?.custodian) || []

    return {
      name: custodian?.name || '',
      imageUrl: custodian?.dataSourceImageUrl
    }
  }, [accounts])

  const isLoading = loading || loadingAccounts

  return (
    <Metric.Wrapper
      className={classes.container}
      redirectTo={redirectTo}
    >
      <div className={classes.titleContainer}>
        <Avatar
          customSize='3rem'
          src={custodian.imageUrl}
          isPublicResource
          loading={isLoading}
          avatarLetters={custodian.name}
        />
        <Text text={title} className={classes.title} />
      </div>
      <div className={classes.details}>
        <InlineDividedMetric>
          <Metric
            value={result?.current?.total}
            valueFormat={valueFormat}
            description='Current'
            isLoading={isLoading}
          />
          <Metric
            value={result?.stale?.total}
            isLoading={isLoading}
            valueColor={
              result?.stale?.total === 0
                ? theme.palette.romanSilver
                : theme.palette.error.primary
            }
            valueFormat={valueFormat}
            description='Not Current'
          />
        </InlineDividedMetric>
      </div>
    </Metric.Wrapper>
  )
}

AccountStatusMetric.propTypes = {
  title: PropTypes.string,
  valueFormat: PropTypes.string,
  dataSourceIds: PropTypes.arrayOf(PropTypes.number),
  redirectTo: PropTypes.string
}

AccountStatusMetric.defaultProps = {
  title: '',
  dataSourceIds: [],
  valueFormat: 'default',
  redirectTo: undefined
}

export default AccountStatusMetric
