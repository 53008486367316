import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(() => ({
  tooltip: {
    background: 'white',
    color: 'inherit',
    fontSize: 'inherit',
    padding: '0.5rem',
    borderRadius: '0.25rem',
    boxShadow: '0 1px 2px rgba(0, 0, 0, 0.25)',
    position: 'absolute',
    '& .__tooltip-content': {
      display: 'flex',
      padding: '0.25rem'
    },
    '& .__tooltip-item': {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      gap: '1rem',
      '& span': {
        whiteSpace: 'nowrap'
      }
    },
    '& .__tooltip-color': {
      height: '0.75rem',
      width: '0.75rem'
    },
    '& .__tooltip-value': {
      marginLeft: 'auto'
    }
  }
}))

export const TooltipContainer = ({ children, style }) => {
  const classes = useStyles()
  return (
    <div className={classes.tooltip} style={style}>
      {children}
    </div>
  )
}

TooltipContainer.propTypes = {
  children: PropTypes.node.isRequired,
  style: PropTypes.object
}

export const Tooltip = ({ label, value, color }) => {
  return (
    <div className='__tooltip-content'>
      <div className='__tooltip-item'>
        <span className='__tooltip-color' style={{ backgroundColor: color }} />
        <span>
          {label}
          {`${value ? ':' : ''}`}
        </span>
        {value && <span className='__tooltip-value'>{value}</span>}
      </div>
    </div>
  )
}

Tooltip.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  color: PropTypes.string
}

Tooltip.Container = TooltipContainer

export default Tooltip
