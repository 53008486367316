import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { isEmpty } from 'lodash'
import { useContextContainer } from '../../../../abundanceEngine/components/ContextContainer'
import { CALC_TYPES } from '../../../../constants'
import { useClientBalancesMultiple, useInfiniteSearchClients } from '../../../../api/clients'
import { defaultBalancesQuery, defaultBookmarksQuery, mapSearch, mapSort } from '../helpers'
import { CLIENT_VIEW_CONTEXT } from '..'
import { useDebouncedCallback } from '../../../../hooks'
import { useLevelDates } from '../../../../api/coreData'
import { splitArrayInChunks } from '../../../../utils'
import { useBookmarksMultiple } from '../../../../api/groups'

const levelDateQuery = {
  levelType: 'user'
}

const mapFilters = (clientTagFilters) => {
  const groupIds = clientTagFilters.map(({ groupIds }) => ({
    value: groupIds,
    op: 'in',
    combine: 'and'
  }))
  return !isEmpty(groupIds) ? { groupIds } : {}
}

const getBaseQuery = ({ pageSize, sort, clientTagFilters }) => ({
  skip: 0,
  take: pageSize,
  sort: mapSort(sort),
  filters: mapFilters(clientTagFilters),
  textSearch: {},
  includes: {
    avatars: true
  }
})

export const useInfiniteClients = ({
  sort,
  pageSize,
  searchText = '',
  mapper = undefined
}) => {
  const [{ defaultSort, clientTagFilters }, setContextContainer] =
    useContextContainer(CLIENT_VIEW_CONTEXT)

  const defaultQuery = useMemo(
    () => getBaseQuery({ pageSize, sort, clientTagFilters }),
    [sort, pageSize, clientTagFilters]
  )

  const [query, setQuery] = useState(
    getBaseQuery({ pageSize, sort, clientTagFilters })
  )

  const { data: levelDate } = useLevelDates(levelDateQuery)

  const [balancesQuery, setBalancesQuery] = useState(defaultBalancesQuery)
  const [bookmarksQuery, setBookmarksQuery] = useState(defaultBookmarksQuery)

  const queryOptions = useMemo(() => {
    return { mapper }
  }, [mapper])

  const {
    data = { clients: [], total: 0, hasNextPage: false },
    isFetching,
    fetchNextPage: _fetchNextPage
  } = useInfiniteSearchClients(defaultQuery, query.textSearch, query.filters, queryOptions)

  const fetchNextPage = useCallback(() => {
    if (isFetching) return
    if (!data.hasNextPage) return

    setQuery((prevState) => {
      const { skip, take } = prevState
      const nextOffset = skip + take
      if (skip === nextOffset || nextOffset < skip) return prevState

      _fetchNextPage({ pageParam: { ...prevState, skip: nextOffset } })

      return { ...prevState, skip: nextOffset }
    })
  }, [isFetching, data.hasNextPage, _fetchNextPage])

  useEffect(() => {
    setQuery((prevState) => ({
      ...prevState,
      textSearch: mapSearch(searchText)
    }))
  }, [searchText])

  useEffect(() => {
    setQuery((prevState) => ({
      ...prevState,
      filters: mapFilters(clientTagFilters)
    }))
  }, [clientTagFilters])

  const isRowLoaded = useCallback(
    ({ index }) => {
      return !!data.clients?.[index]
    },
    [data.clients]
  )

  const clients = useMemo(() => {
    /** split in batches when pagination changes */
    const clientsFlatten = data?.clients?.flat() || []
    const clientGroups = splitArrayInChunks(clientsFlatten, query.take)
    return { groups: clientGroups, list: clientsFlatten }
  }, [data?.clients, query.take])

  const setQueryDebounced = useRef(useDebouncedCallback(setQuery))

  const setClientBalancesQuery = useCallback(() => {
    if (isEmpty(clients.list)) return
    setBalancesQuery((prevState) => {
      return {
        ...prevState,
        queries: clients.groups.map((clientsGroup) => {
          const clientIds = clientsGroup.map(({ clientId }) => clientId)
          return {
            clientIds,
            calcType: CALC_TYPES.balance,
            startDate: levelDate?.min,
            endDate: levelDate?.max,
            sort: mapSort(defaultSort)
          }
        }),
        queryOptions: {
          ...prevState.queryOptions,
          enabled: true
        }
      }
    })
  }, [clients, defaultSort, levelDate?.max, levelDate?.min])

  useEffect(() => {
    if (!isEmpty(clients.list) && levelDate) {
      setClientBalancesQuery()
    }
  }, [setClientBalancesQuery, clients.list, levelDate])

  const clientBalancesRequest = useClientBalancesMultiple(
    balancesQuery.queries,
    balancesQuery.queryOptions
  )

  const { clientBalances, isLoadingClientBalances } = useMemo(() => {
    return clientBalancesRequest.reduce(
      (acc, request) => ({
        clientBalances: {
          ...(acc?.clientBalances || {}),
          ...(request?.data || {})
        },
        isLoadingClientBalances:
          acc?.isLoadingClientBalances || request?.isLoading
      }),
      {}
    )
  }, [clientBalancesRequest])

  useEffect(() => {
    if (!isLoadingClientBalances) {
      setContextContainer((prevState) => ({
        ...prevState,
        isLoadingBalances: false,
        clientBalances: {
          ...prevState.clientBalances,
          ...clientBalances
        }
      }))
    } else {
      setContextContainer((prevState) => ({
        ...prevState,
        isLoadingBalances: isLoadingClientBalances
      }))
    }
  }, [isLoadingClientBalances, setContextContainer, clientBalances])

  const setClientBookmarksQuery = useCallback(() => {
    if (isEmpty(clients.list)) return
    setBookmarksQuery((prevState) => {
      return {
        ...prevState,
        queries: clients.groups.map((clientsGroup) => {
          const clientIds = clientsGroup.map(({ clientId }) => clientId)
          return { levelIds: clientIds, levelType: 'clients' }
        }),
        queryOptions: {
          ...prevState.queryOptions,
          enabled: true
        }
      }
    })
  }, [clients])

  useEffect(() => {
    setClientBookmarksQuery()
  }, [setClientBookmarksQuery])

  const clientBookmarksRequest = useBookmarksMultiple(
    bookmarksQuery.queries,
    bookmarksQuery.queryOptions
  )

  const { clientBookmarks, isLoadingClientBookmarks } = useMemo(() => {
    return clientBookmarksRequest.reduce(
      (acc, request) => ({
        clientBookmarks: {
          ...(acc?.clientBookmarks || {}),
          ...(request?.data || {})
        },
        isLoadingClientBookmarks:
            acc?.isLoadingClientBookmarks || request?.isLoading
      }),
      {}
    )
  }, [clientBookmarksRequest])

  useEffect(() => {
    if (!isLoadingClientBookmarks) {
      setContextContainer((prevState) => ({
        ...prevState,
        isLoadingBookmarks: false,
        clientBookmarks: {
          ...prevState.clientBookmarks,
          ...clientBookmarks
        }
      }))
    } else {
      setContextContainer((prevState) => ({
        ...prevState,
        isLoadingBookmarks: isLoadingClientBookmarks
      }))
    }
  }, [isLoadingClientBookmarks, setContextContainer, clientBookmarks])

  return {
    isLoading: isFetching,
    isLoadingBalances: isLoadingClientBalances,
    clients: data.clients,
    hasNextPage: data.hasNextPage,
    rowCount: data.total,
    setQuery: setQueryDebounced.current,
    isRowLoaded,
    fetchNextPage
  }
}

export default useInfiniteClients
