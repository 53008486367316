import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { Box, Divider } from '@material-ui/core'
import ArrowBackOutlinedIcon from '@material-ui/icons/ArrowBack'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import { Alert } from '@material-ui/lab'
import isEmpty from 'lodash/isEmpty'
import Text from '../atoms/Text'
import Icon from '../atoms/Icon'
import {
  useNoteContext,
  useNoteFilterActions
} from '../../redux/slices/noteContext'
import SplitButton from '../../components/atoms/SplitButton'
import { NOTE_TRIGGERED_BY, ICON_NAMES } from '../../constants'
import { useNoteThreadContext } from '../../contexts/noteThreadContext'

const useStyles = makeStyles((theme) => ({
  actions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  backButton: {
    cursor: 'pointer',
    marginRight: '10px',
    display: 'flex'
  },
  closeButton: {
    cursor: 'pointer',
    marginLeft: '0.5rem',
    display: 'flex'
  },
  container: ({ height }) => ({
    display: 'flex',
    flexDirection: 'row',
    height: height || 'auto',
    justifyContent: 'space-between',
    boxSizing: 'border-box',
    alignItems: 'center',
    padding: '1rem',
    '& svg': {
      cursor: 'pointer',
      fontSize: '1.5rem'
    }
  }),
  filtersContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    padding: '1rem'
  },
  title: {
    margin: '0 1rem',
    textAlign: 'center',
    flex: 1,
    color: theme.palette.hardcoded,
    fontWeight: 'bold'
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  alertMessage: {
    margin: 'auto'
  },
  switchCheckedIcon: {
    width: '1.25rem',
    height: '1.25rem',
    borderRadius: '1.25rem',
    backgroundColor: theme.palette.white,
    color: theme.palette.emerald,
    '& svg': {
      fontSize: '1.2rem !important'
    }
  },
  threadTitle: {
    fontSize: '1rem',
    fontWeight: '400'
  },
  filterListItem: {
    margin: '5px 0px'
  },
  filterButton: {
    fontSize: '0.7rem !important',
    padding: '2px 11px !important'
  }
}))

const NoteThreadTitle = ({
  height,
  title,
  onAdd,
  onBack,
  onClose,
  otherAction,
  showBack,
  showClose,
  showViewFilters
}) => {
  const classes = useStyles({ height })

  const { activeThread } = useNoteThreadContext()

  const { isThreadResolved } = useMemo(() => ({
    threadId: activeThread.threadId,
    isThreadResolved: activeThread.resolved
  }), [activeThread])

  const { filters, triggeredBy } = useNoteContext()
  const { removeNoteFilter } = useNoteFilterActions()

  return (
    <>
      <div className={classes.container}>
        <div className={classes.titleContainer}>
          {showBack && onBack && (
            <div onClick={onBack} className={classes.backButton}>
              <ArrowBackOutlinedIcon />
            </div>
          )}
          <Text
            textTransform='uppercase'
            customFontSize='0.875rem'
            className={classes.title}
            text={title}
          />
        </div>
        <div className={classes.actions}>
          {onAdd && (
            <Box display='flex' onClick={onAdd}>
              <Icon customSize='2rem' name={ICON_NAMES.add} />
            </Box>
          )}
          {showClose && (
            <div onClick={onClose} className={classes.closeButton}>
              <HighlightOffIcon />
            </div>
          )}
          {otherAction}
        </div>
      </div>
      <Divider />
      {!isEmpty(filters) && triggeredBy === NOTE_TRIGGERED_BY.BOARD && showViewFilters && (
        <div className={classes.filtersContainer}>
          {filters.map(({ id, name }) => (
            <SplitButton
              key={id}
              text={name}
              buttonClassName={classes.filterButton}
              iconFontSize='0.75rem'
              onClickIcon={() => removeNoteFilter(id)}
            />
          ))}
        </div>
      )}
      {isThreadResolved && (
        <Alert
          icon={false}
          severity='success'
          classes={{ message: classes.alertMessage }}
        >
          This thread is resolved.
        </Alert>
      )}
    </>
  )
}

NoteThreadTitle.propTypes = {
  title: PropTypes.string,
  height: PropTypes.number,
  onAdd: PropTypes.func,
  onBack: PropTypes.func,
  onClose: PropTypes.func.isRequired,
  otherAction: PropTypes.node,
  showBack: PropTypes.bool,
  showClose: PropTypes.bool,
  showViewFilters: PropTypes.bool
}

NoteThreadTitle.defaultProps = {
  title: '',
  height: undefined,
  onAdd: undefined,
  onBack: undefined,
  otherAction: undefined,
  showBack: false,
  showClose: true,
  showViewFilters: true
}

export default NoteThreadTitle
