import React, { useMemo } from 'react'
import { alpha, makeStyles, Tooltip } from '@material-ui/core'
import PropTypes from 'prop-types'
import { useAppContext } from '../../../redux/slices/appContext'
import { useSearchAssignedPeople } from '../../../api/people'
import FastAvatar from '../../molecules/FastAvatar'
import FadeIn from '../../molecules/Transitions/FadeIn'

const useStyles = makeStyles((theme) => ({
  peoplePill: {
    backgroundColor: alpha(theme.palette.background.paper, 0.9),
    borderRadius: theme.layout.radius.circle,
    display: 'inline-flex',
    flexDirection: 'row',
    gap: theme.layout.gap.dense,
    padding: `${theme.layout.padding.dense} ${theme.layout.padding.dense}`
  }
}))

function PeoplePill ({ max }) {
  const classes = useStyles()
  const { clientId } = useAppContext()
  const query = useMemo(() => ({
    filters: {
      assignedToClientIds: [clientId]
    },
    includes: {
      personTypes: true
    }
  }), [clientId])
  const { data, isLoading } = useSearchAssignedPeople(query)
  const people = useMemo(() => {
    if (!data?.data?.length) return []
    const mapPerson = p => ({
      ...p,
      abbreviation: p.firstName.slice(0, 1),
      tooltip: `${p.firstName} ${p.lastName} - ${p.personType?.longName}`
    })
    if (data.data.length <= max) return data.data.map(mapPerson)

    const personData = [...data.data]
    const first = personData.slice(0, max - 1).map(mapPerson)
    const last = personData.slice(max)

    return [...first, { personId: 'else', abbreviation: `+${last.length}`, tooltip: `${last.length} Others` }]
  }, [data, max])

  if (isLoading) return null
  if (!people.length) return null

  return (
    <FadeIn className={classes.peoplePill}>
      {people.map(person => (
        <Tooltip key={person.personId} title={person.tooltip}>
          <div>
            <FastAvatar abbreviation={person.abbreviation} size='xs' />
          </div>
        </Tooltip>
      ))}
    </FadeIn>
  )
}

PeoplePill.propTypes = {
  max: PropTypes.number
}

PeoplePill.defaultProps = {
  max: 4
}

export default PeoplePill
