import { useState, useEffect } from 'react'

/**
 * Execute an async fetch for a state of a component with the ability to set
 * the state safely. This means that the state set will only be performed if
 * all of the following are true:
 *
 * - The component is mounted
 * - A new fetch hasn't started
 *
 * Example usage:
 *
 * ```js
 * useFetchState(
 *  useCallback(async setSafeState => {
 *    // obtain data from an api
 *    const data = await apiRequest(id)
 *
 *    // save the data to the state
 *    // the saved state is `{ loading: true }` by default
 *    // calling `setSafeState` changes `loading` to `false`.
 *    setSafeState({ data })
 *
 *    // if the id changes we'll fetch data again, if a previous
 *    // fetch was still running it will be ignored
 *  }, [id]),
 *  { loading: true, data: undefined }
 * )
 * ```
 *
 * @param {Function} fetchFunction A function that performs an async fetch.
 * It will be called with a `setSafeState` param that you can use for
 * setting your state safely.
 * ```js
 * function fetchFunction(setSafeState) {
 *    // you can do any async requests here
 *    // fetchFunction can be async also
 * }
 * ```
 *
 * `setSafeState` as the following signature:
 *
 * ```js
 * function setSafeState(state) {
 *    setState({ loading: false, ...state })
 * }
 * ```
 * @param {Object} [initialState={ loading: true }] An initial state for the fetch.
 * @return {Object} The state that can be modified calling `safeSetState`.
 */
function useFetchState (fetchFunction, initialState = { loading: true }) {
  const [state, setState] = useState(initialState)

  useEffect(() => {
    let isSetStateSafe = true

    function safeSetState (newState) {
      if (isSetStateSafe) {
        const payload = {
          loading: false,
          ...newState
        }

        const newSetState = (state) => {
          setState({ ...payload, ...state, setState: newSetState })
        }

        setState({
          loading: false,
          ...newState,
          setState: newSetState
        })
      }
    }

    fetchFunction(safeSetState)

    return () => {
      isSetStateSafe = false
    }
  }, [fetchFunction])

  return state
}

export default useFetchState
