import React from 'react'
import { useParams } from 'react-router-dom'
import { useCheckPolicy } from '../../../../../hooks'
import NotAvailable from '../../../../organisms/NotAvailable'
import BillingRunDetailsList from './billingRunDetailsList'
import BillingRunDetailsPage from './billingRunDetailsPage'

function BillingRunDetails (props) {
  const hasAccess = useCheckPolicy('admin_billing_run_view_details')
  const params = useParams()

  if (!hasAccess) {
    return <NotAvailable />
  }

  return (
    <>
      {params?.id ? (
        <BillingRunDetailsPage billingRunId={Number(params.id)} {...props} />
      ) : (
        <BillingRunDetailsList {...props} />
      )}

    </>
  )
}
export default BillingRunDetails
