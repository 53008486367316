import React, { useState } from 'react'
import { IconButton, makeStyles, Menu, MenuItem } from '@material-ui/core'
import PropTypes from 'prop-types'
import Icon from '../../../../atoms/Icon'
import { ICON_NAMES } from '../../../../../constants'

const useStyles = makeStyles((theme) => ({
  item: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.layout.gap.dense,
    alignItems: 'center'
  },
  actionMenu: {
    '& .MuiButtonBase-root.MuiIconButton-root': {
      padding: theme.layout.padding.thin
    }
  }
}))

function ActionMenu ({ actions }) {
  const classes = useStyles()
  const [anchorElement, setAnchorElement] = useState(null)
  const close = () => setAnchorElement(null)

  if (actions.length === 0) {
    return null
  }

  return (
    <div className={classes.actionMenu}>
      <IconButton onClick={e => setAnchorElement(e.currentTarget)}>
        <Icon name={ICON_NAMES.vertDots} />
      </IconButton>

      <Menu open={!!anchorElement} anchorEl={anchorElement} onClose={close}>
        {actions.map(action => (
          <MenuItem
            key={action.label}
            className={classes.item}
            dense
            onClick={() => {
              action.action()
              close()
            }}
          >
            {action.icon ? (<span><Icon name={action.icon} /></span>) : null}
            <span>{action.label}</span>
          </MenuItem>
        ))}
      </Menu>
    </div>
  )
}

ActionMenu.propTypes = {
  actions: PropTypes.arrayOf(PropTypes.shape({
    icon: PropTypes.string,
    label: PropTypes.string,
    action: PropTypes.func
  }))
}

ActionMenu.defaultProps = {
  actions: []
}

export default ActionMenu
