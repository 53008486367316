export const COST_BASIS_BUCKETS = {
  gainLoss: 'Gain/Loss',
  gain: 'Gain',
  loss: 'Loss'
}

export const COST_BASIS_COMBINED_BUCKETS = {
  UGL: 'Unrealized',
  RGL: 'Realized'
}

export const COST_BASIS_PERIOD_BUCKETS = {
  shortTerm: 'Short-Term',
  longTerm: 'Long-Term',
  total: 'Total'
}

export const COST_BASIS_PERIOD_BUCKET_KEYS = {
  [COST_BASIS_PERIOD_BUCKETS.shortTerm]: 'shortTerm',
  [COST_BASIS_PERIOD_BUCKETS.longTerm]: 'longTerm',
  [COST_BASIS_PERIOD_BUCKETS.total]: 'total'
}

export const tileMapping = {
  realized: [
    {
      ...COST_BASIS_BUCKETS,
      key: COST_BASIS_PERIOD_BUCKETS.shortTerm,
      gainLoss: (item) => item?.shortTermRGL,
      gain: (item) => item?.shortTermRealizedGain,
      loss: (item) => item?.shortTermRealizedLoss
    },
    {
      ...COST_BASIS_BUCKETS,
      key: COST_BASIS_PERIOD_BUCKETS.longTerm,
      gainLoss: (item) => item?.longTermRGL,
      gain: (item) => item?.longTermRealizedGain,
      loss: (item) => item?.longTermRealizedLoss
    },
    {
      ...COST_BASIS_BUCKETS,
      key: COST_BASIS_PERIOD_BUCKETS.total,
      gainLoss: (item) => item?.totalRGL,
      gain: (item) => item?.totalRealizedGain,
      loss: (item) => item?.totalRealizedLoss
    }
  ],
  unrealized: [
    {
      ...COST_BASIS_BUCKETS,
      key: COST_BASIS_PERIOD_BUCKETS.shortTerm,
      gainLoss: (item) => item?.shortTermUGL,
      gain: (item) => item?.shortTermUnrealizedGain,
      loss: (item) => item?.shortTermUnrealizedLoss
    },
    {
      ...COST_BASIS_BUCKETS,
      key: COST_BASIS_PERIOD_BUCKETS.longTerm,
      gainLoss: (item) => item?.longTermUGL,
      gain: (item) => item?.longTermUnrealizedGain,
      loss: (item) => item?.longTermUnrealizedLoss
    },
    {
      ...COST_BASIS_BUCKETS,
      key: COST_BASIS_PERIOD_BUCKETS.total,
      gainLoss: (item) => item?.totalUGL,
      gain: (item) => item?.totalUnrealizedGain,
      loss: (item) => item?.totalUnrealizedLoss
    }
  ],
  combined: [
    {
      ...COST_BASIS_COMBINED_BUCKETS,
      key: COST_BASIS_PERIOD_BUCKETS.shortTerm,
      UGL: (item) => item?.shortTermUGL,
      RGL: (item) => item?.shortTermRGL
    },
    {
      ...COST_BASIS_COMBINED_BUCKETS,
      key: COST_BASIS_PERIOD_BUCKETS.longTerm,
      UGL: (item) => item?.longTermUGL,
      RGL: (item) => item?.longTermRGL
    },
    {
      ...COST_BASIS_COMBINED_BUCKETS,
      key: COST_BASIS_PERIOD_BUCKETS.total,
      UGL: (item) => item?.totalUGL,
      RGL: (item) => item?.totalRGL
    }
  ]
}

export const useTileMapping = (costBasisType) => {
  return tileMapping[costBasisType]
}
