import React, { useMemo } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { makeStyles } from '@material-ui/core'
import noop from 'lodash/noop'
import { ADMIN_ROUTES } from '../../../../constants'
import PillButtonGroup from '../../../molecules/PillButtonGroup'
import { OPTIONS_KEYS } from './helpers'

const options = [
  {
    path: ADMIN_ROUTES.BILLING_FEE_SCHEDULES,
    text: 'Fee Schedules',
    key: OPTIONS_KEYS.feeSchedule
  },
  {
    path: ADMIN_ROUTES.BILLING_NO_FEE_SCHEDULES,
    text: 'No Fee Schedules',
    key: OPTIONS_KEYS.noFeeSchedule
  },
  {
    path: ADMIN_ROUTES.BILLING_MULTIPLE_FEE_SCHEDULES,
    text: 'Multiple Fee Schedules',
    key: OPTIONS_KEYS.multipleFeeSchedule
  }
]

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    margin: '12px',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center'
  }
}))

const FeeScheduleSelector = () => {
  const classes = useStyles()
  const location = useLocation()
  const selectedValue = useMemo(() => {
    if (location.pathname === ADMIN_ROUTES.BILLING_FEE_SCHEDULES) return OPTIONS_KEYS.feeSchedule
    if (location.pathname === ADMIN_ROUTES.BILLING_NO_FEE_SCHEDULES) return OPTIONS_KEYS.noFeeSchedule
    return OPTIONS_KEYS.multipleFeeSchedule
  }, [location])

  return (
    <div className={classes.container}>
      <PillButtonGroup onSelect={noop} selectedValue={selectedValue}>
        {options.map(option => (
          <PillButtonGroup.Option key={option.key} value={option.key}>
            <Link to={option.path} style={{ textDecoration: 'none' }}>
              {option.text}
            </Link>
          </PillButtonGroup.Option>
        ))}
      </PillButtonGroup>
    </div>
  )
}

export default FeeScheduleSelector
