import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import ReportParameterForm from '../ReportParameterForm'
import Tabs from '../../../../organisms/AdvisorView/components/AdvisorViewTabs'
import { useBookOfBusinessReport } from '..'
import ReportRecentView from './ReportRecentView'
import ReportSavedView from './ReportSavedView'

const useStyles = makeStyles(() => ({
  container: {
    paddingTop: '1rem'
  }
}))

const ReportViews = ({ defaultDateRangeOptions }) => {
  const classes = useStyles()
  const { onFormSubmit } = useBookOfBusinessReport()

  return (
    <div className={classes.container}>
      <Tabs.Group
        tabOptions={{
          run: 'Run',
          saved: 'System Reports'
        }}
      >
        <Tabs.Links tabsKey='bobi_report_views' />
        <Tabs.Tab value='run'>
          <ReportParameterForm
            onSubmit={onFormSubmit}
            dateRangeOptions={defaultDateRangeOptions}
          />
          <ReportRecentView />
        </Tabs.Tab>
        <Tabs.Tab value='saved'>
          <ReportSavedView />
        </Tabs.Tab>
      </Tabs.Group>
    </div>
  )
}

ReportViews.propTypes = {
  defaultDateRangeOptions: PropTypes.array
}

export default ReportViews
