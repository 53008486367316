export const localStorageHelper = {
  load (key) {
    if ([undefined, null].includes(key)) {
      throw new Error('[Local storage helper]: key field is required')
    }
    const stored = localStorage.getItem(key)
    try {
      return stored === null ? null : JSON.parse(stored)
    } catch (err) {
      console.debug(err, 'error getting local storage value for key', key)
      return null
    }
  },
  tryLoad (key) {
    if ([undefined, null].includes(key)) {
      return null
    }
    const stored = localStorage.getItem(key)
    try {
      return stored === null ? null : JSON.parse(stored)
    } catch (err) {
      console.debug(err, 'error getting local storage value for key', key)
      return null
    }
  },
  maybeLoadRaw (key) {
    if ([undefined, null].includes(key)) {
      return null
    }
    const stored = localStorage.getItem(key)
    try {
      return stored
    } catch (err) {
      console.debug(err, 'error getting local storage value for key', key)
      return null
    }
  },
  store (key, value) {
    if (typeof value === 'undefined') return

    localStorage.setItem(key, JSON.stringify(value))
  },
  tryStore (key, value) {
    try {
      if (typeof value === 'undefined') return

      localStorage.setItem(key, JSON.stringify(value))
    } catch (err) {
      console.debug(err, 'error setting local storage value for key', key)
    }
  },
  storeMany (keyValuePair) {
    Object.entries(keyValuePair).forEach(([key, value]) =>
      localStorage.setItem(key, JSON.stringify(value))
    )
  },
  modify (key, fn) {
    this.store(key, fn(this.load(key)))
  },
  removeItems (keys) {
    keys.forEach((key) => localStorage.removeItem(key))
  },
  tryRemove (key) {
    try {
      localStorage.removeItem(key)
    } catch (err) {
      console.debug(err, 'error removing local storage key', key)
    }
  },
  clear () {
    localStorage.clear()
  },
  /** Tries to load an object from local storage */
  loadOrDefault (key, defaults = {}) {
    if (!key) return defaults

    const configuration = this.load(key) || defaults

    return Object.entries(defaults).reduce((prev, [key, value]) => {
      if (key in configuration) {
        prev[key] = configuration[key]
      } else {
        prev[key] = value
      }
      return prev
    }, {})
  }
}
