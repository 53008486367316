import React from 'react'
import PropTypes from 'prop-types'
import { Box, makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  card: {
    boxShadow: '0px 10px 10px -5px rgba(0,0,0,0.05)',
    backgroundColor: theme.palette.common.white,
    padding: '10px',
    borderRadius: '10px',
    cursor: 'pointer',
    border: `2px solid ${theme.palette.gray.main}`,
    width: '100%',
    height: '100%',
    position: 'relative',
    zIndex: 100,
    transition: 'border-color .3s ease-in-out',
    '&:hover': {
      border: `2px solid ${theme.palette.lightBlack}`
    }
  }
}))

const CardVariant = ({ children }) => {
  const classes = useStyles()
  return (
    <Box className={classes.card} height='100%' width='100%'>
      {children}
    </Box>
  )
}

CardVariant.propTypes = {
  children: PropTypes.node
}

export default CardVariant
