import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import TeamMemberCard from '../TeamMemberCard'
import { featureProps } from '../commonProps'
import { useClientTeam } from './Loader'

const defaultFeatures = {
  shadow: 'light',
  showBio: true
}
const withDefaultFeatures = (overrides) => Object.assign({}, defaultFeatures, overrides)
const sortByOrdinal = (teamMembers) => teamMembers.sort((a, b) => a.ordinal - b.ordinal)
const applySkip = (skip) => (teamMembers) => skip ? teamMembers.slice(skip) : teamMembers
const applyTake = (take) => (teamMembers) => take ? teamMembers.slice(0, take) : teamMembers

const useStyles = makeStyles(theme => ({
  teamLayout: ({ listDirection, spacing, noWrap }) => {
    if (listDirection === 'column') {
      return {
        display: 'inline-flex',
        gap: spacing in theme.layout.gap ? theme.layout.gap[spacing] : theme.layout.gap.g5,
        flexDirection: listDirection ?? 'row',
        flexWrap: noWrap ? 'nowrap' : 'wrap',
        overflow: noWrap ? 'auto' : undefined
      }
    } else if (listDirection === 'row') {
      return {
        display: 'flex',
        gap: spacing in theme.layout.gap ? theme.layout.gap[spacing] : theme.layout.gap.g5,
        flexDirection: listDirection ?? 'row',
        flexWrap: noWrap ? 'nowrap' : 'wrap',
        overflow: noWrap ? 'auto' : undefined
      }
    } else if (listDirection === 'grid') {
      return {
        display: 'grid',
        gridTemplateColumns: 'repeat(12, 1fr)',
        rowGap: spacing in theme.layout.gap ? theme.layout.gap[spacing] : theme.layout.gap.g5
      }
    }
  }
}))

function ClientTeam ({
  cardVariant,
  fieldConfig,
  features,
  listDirection,
  spacing,
  className,
  style,
  skip,
  take,
  noWrap
}) {
  const classes = useStyles({ features, listDirection, spacing, noWrap })
  const { data: clientTeam, isLoading: clientTeamLoading, schema } = useClientTeam()
  const _features = useMemo(() => withDefaultFeatures(features), [features])
  const _clientTeam = useMemo(() => [sortByOrdinal, applySkip(skip), applyTake(take)].reduce((res, fn) => fn(res), clientTeam || []), [clientTeam, skip, take])

  if (clientTeamLoading) {
    return null
  }

  return (
    <div className={clsx(className, classes.teamLayout)} style={style}>
      {(_clientTeam || []).map((tm, index) => (
        <TeamMemberCard
          key={tm.teamMemberId}
          variant={cardVariant}
          teamMember={tm}
          fieldConfig={fieldConfig}
          features={_features}
          schema={schema}
          index={index}
        />
      ))}
    </div>
  )
}

ClientTeam.propTypes = {
  cardVariant: TeamMemberCard.propTypes.variant,
  fieldConfig: PropTypes.arrayOf(PropTypes.shape({
    accessor: PropTypes.string.isRequired,
    type: PropTypes.string
  })),
  features: featureProps,
  listDirection: PropTypes.oneOf(['column', 'row', 'grid']),
  spacing: PropTypes.oneOf(['none', 'dense', 'medium', 'loose', 'diluted', 'sparse']),
  className: PropTypes.string,
  style: PropTypes.object,
  skip: PropTypes.number,
  take: PropTypes.number,
  noWrap: PropTypes.bool
}

ClientTeam.defaultProps = {
  cardVariant: TeamMemberCard.defaultProps.variant,
  fieldConfig: [
    { accessor: 'email', type: 'mailto', label: 'Email' }
  ],
  features: defaultFeatures,
  listDirection: 'row',
  spacing: 'dense',
  noWrap: false
}

export default ClientTeam
