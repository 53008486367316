import isEmpty from 'lodash/isEmpty'

export const mapClientsThreadsOverview = (clientsThreadsOverview, clientsNotifications, clients) => {
  return clientsThreadsOverview.reduce((acc, clientThreadsOverview) => {
    const client = clients.find(
      ({ clientId }) => clientId === clientThreadsOverview.clientId
    )

    if (isEmpty(client)) return acc

    const {
      notifications: {
        unread: unreadNotifications = 0,
        lastUnreadNotificationAt
      },
      lastThreadUpdatedAt
    } = clientsNotifications.find(({ clientId: clientNotificationId }) => {
      return clientNotificationId === client.clientId
    }) || { notifications: {} }

    const profilePicture =
      client?.profilePic || client?.extras?.profileData?.profilePic

    return [
      ...acc,
      {
        ...clientThreadsOverview,
        profilePic: profilePicture,
        familyName: client?.familyName || '',
        hasNewNotifications: Boolean(unreadNotifications),
        lastUnreadNotificationAt: lastUnreadNotificationAt,
        lastThreadUpdatedAt
      }
    ]
  }, [])
}
