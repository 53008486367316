import PropTypes from 'prop-types'

export const reportParamsShape = PropTypes.shape({
  levelType: PropTypes.string,
  exposureType: PropTypes.string,
  exposureTarget: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.number),
    PropTypes.number,
    PropTypes.string
  ]),
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  dateRangePreset: PropTypes.string
})

export const reportResultShape = PropTypes.shape({
  bobiResult: PropTypes.shape({
    bobiOutputId: PropTypes.number,
    status: PropTypes.string,
    statusDescription: PropTypes.string
  }),
  bobiResultLoading: PropTypes.bool,
  bobiResultError: PropTypes.any
})
