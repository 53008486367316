import React from 'react'
import PropTypes from 'prop-types'
import { useMilestonesContext } from '../MilestonesContext'
import ViewWrapper from '../../ViewListAndBlockComponents/ViewWrapper'

const MilestonesViewWrapper = ({ children }) => {
  const { viewType } = useMilestonesContext()
  return <ViewWrapper viewType={viewType}>{children}</ViewWrapper>
}

MilestonesViewWrapper.propTypes = {
  children: PropTypes.node
}

export default MilestonesViewWrapper
