import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import { useTablePositioningContext } from './TablePositioningContext'

const useStyles = makeStyles((theme) => ({
  superHeaderWrapper: {
    position: 'sticky',
    top: 0,
    backgroundColor: theme.palette.white,
    paddingBottom: '5px'
  }
}))

const StickySuperHeader = ({ className, children }) => {
  const classes = useStyles()
  const { refCallback } = useTablePositioningContext()
  return (
    <div ref={refCallback} className={clsx(classes.superHeaderWrapper, className)}>
      {children}
    </div>
  )
}

StickySuperHeader.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node
}

export default StickySuperHeader
