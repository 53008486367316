import React from 'react'
import { usePolicy } from '../../../../hooks/usePolicy'
import EmptySection from '../../../atoms/EmptySection'
import AdvisorUserDetails from './AdvisorUserDetails'

function AdvisorUserIndex () {
  const canView = usePolicy('admin_view_advisor_users')

  if (!canView) {
    return (
      <EmptySection
        title='Access Denied'
        description='You do not have sufficient permissions to access this resource'
      />
    )
  }

  return (
    <AdvisorUserDetails />
  )
}

export default AdvisorUserIndex
