import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import { useAvailableDates } from '../../redux/slices/appContext'
import useAvailableDatesDefault from '../../hooks/useAvailableDatesDefault'
import { dateSelectDefaults, dateSelectProps } from '../../prop-types'
import DateSelect from '../molecules/DateSelect'

const FORMAT = 'YYYY-MM-DD'

const AvailableDateSelect = ({
  options,
  onChange,
  selectedValue,
  initialValue,
  textVariant,
  addCustomDateOpt
}) => {
  const [availableDates, loadingAvailableDates] = useAvailableDates()

  const { mainDate: defaultValue, minDate, maxDate } = useAvailableDatesDefault(availableDates)

  const onRestrictChangeBetweenRange = useCallback(
    (event) => {
      const { target: { value } } = event
      const [targetValue, valueKey] = value.split('_')
      const dateValue = dayjs.utc(targetValue)
      const min = dayjs.utc(minDate)
      const max = dayjs.utc(maxDate)

      let dateResult = dateValue.format(FORMAT)
      if (dateValue.valueOf() < min.valueOf()) dateResult = min.format(FORMAT)
      if (dateValue.valueOf() > max.valueOf()) dateResult = max.format(FORMAT)
      return onChange(dateResult, value, valueKey || value)
    },
    [onChange, minDate, maxDate]
  )

  return (
    <DateSelect
      defaultValue={defaultValue}
      initialValue={initialValue}
      options={options}
      disabled={loadingAvailableDates}
      onChange={onRestrictChangeBetweenRange}
      selectedValue={selectedValue}
      textVariant={textVariant}
      addCustomDateOpt={addCustomDateOpt}
    />
  )
}

AvailableDateSelect.propTypes = {
  ...dateSelectProps,
  addCustomDateOpt: PropTypes.bool
}

AvailableDateSelect.defaultProps = {
  ...dateSelectDefaults,
  addCustomDateOpt: true
}

export default AvailableDateSelect
