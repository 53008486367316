import React from 'react'
import PropTypes from 'prop-types'
import { animated, useSpring } from 'react-spring'
import { makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import { EXPORT_CLASSNAME } from '../../../../constants'

const useStyles = makeStyles((theme) => ({
  frameLayoutContainer: {
    backgroundColor: ({ expanded }) => expanded ? theme.palette.white : theme.palette.background.paper,
    margin: 'inherit',
    padding: ({ expanded }) => expanded ? '3.8rem 0 0' : '3.8rem 2.5rem 0rem',
    position: 'absolute',
    width: '100%',
    height: '100%',
    minHeight: '100%',
    top: 0,
    transition: 'padding .1s ease-in-out, border-color .1s ease-in-out, background-color .2s ease-in-out',

    '.av-popup-frame &': {
      paddingTop: '.5rem'
    }
  },
  paper: ({ expanded }) => ({
    height: expanded ? 'calc(100%)' : 'calc(100% - 1rem)',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    border: `2px solid ${expanded ? 'transparent' : 'rgba(33, 41, 69, 0.3)'}`,
    borderRadius: expanded ? '0' : '0.5rem'
  }),
  frameView: {
    marginTop: ({ expanded }) => expanded ? '-.8rem' : 0,
    height: '100%',
    overflow: 'auto',
    overflowX: 'hidden',
    padding: ({ expanded }) => expanded ? '0 1rem' : '1rem',
    '&::-webkit-scrollbar': {
      width: '1em',
      height: '1em',
      backgroundColor: 'transparent'
    },
    '&::-webkit-scrollbar-track': {
      borderRadius: '20px',
      backgroundColor: 'transparent'
    },
    '&::-webkit-scrollbar-thumb': {
      visibility: 'visible',
      borderRadius: '20px',
      backgroundColor: theme.palette.gray.dark,
      border: '3px solid white'
    }
  }
}))

const AdvisorViewFrameLayout = ({ children, expand, frameStyles }) => {
  const classes = useStyles({ expanded: expand })

  const pageSprings = useSpring({
    from: { left: 100, opacity: 0 },
    to: { left: 0, opacity: 1 },
    leave: { left: -100, opacity: 0 }
  })

  return (
    <div className={classes.frameLayoutContainer}>
      <animated.div className={classes.paper} style={pageSprings}>
        <div
          className={clsx(classes.frameView, EXPORT_CLASSNAME)}
          style={frameStyles}
        >
          {children}
        </div>
      </animated.div>
    </div>
  )
}

AdvisorViewFrameLayout.propTypes = {
  children: PropTypes.node,
  expand: PropTypes.bool,
  frameStyles: PropTypes.object
}

AdvisorViewFrameLayout.defaultProps = {
  expand: false,
  frameStyles: {}
}

export default AdvisorViewFrameLayout
