import React from 'react'
import Icon from '../../atoms/Icon'
import FlexRow from '../../molecules/FlexRow'

function FirmReportWarning () {
  return (
    <FlexRow>
      <Icon name='warning' customSize={20} />
      <span>The report completed with warnings</span>
    </FlexRow>
  )
}

FirmReportWarning.propTypes = {
}

export default FirmReportWarning
