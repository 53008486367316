import React, { useCallback, useMemo, useState, useEffect } from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { useOktaAuth } from '@okta/okta-react'
import { InputBase, makeStyles } from '@material-ui/core'
import {
  useNoteContext,
  useOnAddReplyOrThread
} from '../../redux/slices/noteContext'
import { createThread, saveThreadReply } from '../../service'
import { useToggle, useAuthState } from '../../hooks'
import {
  BUTTON_SIZES,
  MAX_COMMENT_INPUT_LENGTH,
  NOTE_MENTION_REGEX,
  NOTE_NOTIFICATION_TYPES,
  TRANSFORM_HORIZONTAL,
  TRANSFORM_VERTICAL,
  NOTE_TRIGGERED_BY
} from '../../constants'
import { parseEmojisTextToUnicode, parseToCommentDisplayedText } from '../../utils'
import { popOverTransformOrigin } from '../../prop-types'
import Avatar from '../atoms/Avatar'
import RoundedButton from '../atoms/RoundedButton'
import { useAppContext } from '../../redux/slices/appContext'
import { useNoteThreadContext } from '../../contexts/noteThreadContext'
import InputBaseComment from './InputBaseComment'
import NoteCommentActions from './NoteCommentActions'

const useStyles = makeStyles((theme) => ({
  wrapper: {
    padding: '0.5rem',
    borderRadius: '0.5rem',
    backgroundColor: theme.palette.white
  },
  postButton: {
    backgroundColor: theme.palette.cloudBurst,
    color: theme.palette.getContrastText(theme.palette.cloudBurst),
    '&:hover': {
      backgroundColor: theme.palette.mirage
    },
    borderRadius: 25
  },
  container: {
    display: 'flex'
  },
  footer: {
    paddingTop: '1rem',
    alignItems: 'center'
  },
  replyInput: {
    paddingLeft: '0.5rem',
    flexDirection: 'column',
    width: '100%'
  },
  threadInputTitle: {
    fontSize: '1rem !important'
  },
  avatar: {
    padding: '0.5rem'
  },
  actions: {
    display: 'flex',
    marginLeft: 'auto',
    '& svg': {
      fontSize: '1rem',
      cursor: 'pointer',
      marginLeft: '0.5rem'
    }
  },
  actionDisabled: {
    opacity: '50%',
    cursor: 'not-allowed !important'
  },
  circularProgress: {
    marginLeft: '0.5rem'
  }
}))

const commentQueryInitialValue = {
  title: '',
  text: '',
  mentions: [],
  submitting: false,
  textCursorPosition: 0
}

const NoteNewReply = ({
  threadId,
  threadResolved,
  avatar,
  placeHolder,
  disablePostButton,
  isThreadInitializer,
  openModalOnSaveComment,
  popOverTransformOrigin
}) => {
  const classes = useStyles()
  const { authState: oktaAuthState } = useOktaAuth()
  const authState = useAuthState(oktaAuthState)
  const appContext = useAppContext()
  const noteContext = useNoteContext()
  const { onAddReply } = useNoteThreadContext()
  const onAddReplyOrThread = useOnAddReplyOrThread()
  const [emojiPickerOpen, emojiPickerToggle] = useToggle()
  const [commentQuery, setCommentQuery] = useState(commentQueryInitialValue)
  const { sectionId, openNewCommentModal, viewId, triggeredBy } = noteContext

  useEffect(() => {
    if (triggeredBy === NOTE_TRIGGERED_BY.ADD_ASSET) {
      setCommentQuery(prevState => {
        return {
          ...prevState,
          text: 'Please add the following asset to my portfolio\nName of asset:\nValue:'
        }
      })
    }
  }, [triggeredBy])

  const saveCommentHandler = useCallback(
    async ({ text, title }) => {
      const reply = parseEmojisTextToUnicode(text)
      const sendNotification = NOTE_MENTION_REGEX.test(text)
      try {
        setCommentQuery((prevState) => ({
          ...prevState,
          isSubmitting: true
        }))

        const body = {
          body: reply,
          clientId: appContext.clientId,
          createdBy: appContext.userId,
          createdByName: authState.idToken?.claims?.name || '',
          notificationType: NOTE_NOTIFICATION_TYPES.CREATE,
          isPublic: true
        }
        if (threadId) {
          const { data: reply } = await saveThreadReply(threadId, {
            ...body,
            title,
            sectionId,
            reactiveThread: threadResolved
          }, { sendNotification })
          onAddReply(threadId, reply)
        } else {
          await createThread({
            ...body,
            title,
            sectionId,
            viewId: viewId ? viewId.toString() : undefined
          }, { sendNotification })
          onAddReplyOrThread({
            sectionId,
            newThread: true,
            openModal: openModalOnSaveComment
          })
        }
      } catch (error) {
        console.error('There was an error trying to save the comment', error)
      } finally {
        setCommentQuery(commentQueryInitialValue)
      }
    },
    [
      viewId,
      threadId,
      sectionId,
      onAddReply,
      threadResolved,
      onAddReplyOrThread,
      appContext.clientId,
      openModalOnSaveComment,
      appContext.userId,
      authState
    ]
  )

  const commentPlaceHolder = useMemo(() => {
    if (placeHolder) return placeHolder
    return openNewCommentModal || !threadId
      ? 'Add your comment to start a new thread.'
      : 'Reply to this thread. Use @ to mention.'
  }, [openNewCommentModal, threadId, placeHolder])

  const onEmojiSelectHandler = useCallback(
    (emoji) => {
      setCommentQuery(prevState => {
        const {
          text,
          mentions,
          textCursorPosition: cursorPosition
        } = prevState

        const displayedText = parseToCommentDisplayedText(text, mentions, false)
        const displayedTextHydrated = [
          displayedText.slice(0, cursorPosition),
          emoji.native,
          displayedText.slice(cursorPosition)
        ].join('')

        const rawText = parseToCommentDisplayedText(displayedTextHydrated, mentions, true)

        return {
          ...prevState,
          text: rawText
        }
      })
      emojiPickerToggle()
    },
    [emojiPickerToggle]
  )

  const onChangeCommentQuery = useCallback(
    (payload) => {
      setCommentQuery({
        ...commentQuery,
        ...payload
      })
    },
    [commentQuery]
  )

  return (
    <div className={classes.wrapper}>
      <div className={classes.container}>
        <div className={classes.avatar}>
          {avatar || <Avatar customSize='32px' avatarLetters={authState.idToken?.claims?.name || ''} />}
        </div>
        <div className={classes.replyInput}>
          {/* Take out title for now, may need it later - RWC 11.29.21 */}
          {/* {isThreadInitializer && (
            <InputBase
              placeholder='Add Title'
              fullWidth
              inputProps={{ maxLength: 250 }}
              value={commentQuery.title}
              className={classes.threadInputTitle}
              onChange={(e) => onChangeCommentQuery({ title: e.target.value })}
            />
          )} */}
          <InputBaseComment
            textInput={InputBase}
            inputProps={{
              fullWidth: true,
              multiline: true,
              rowsMax: 5,
              inputProps: { maxLength: MAX_COMMENT_INPUT_LENGTH },
              placeholder: commentPlaceHolder
            }}
            value={commentQuery.text}
            onChange={(e, mentions) => {
              onChangeCommentQuery({
                text: e.target.value,
                textCursorPosition: e.target.selectionStart,
                mentions
              })
            }}
            popOverTransformOrigin={popOverTransformOrigin}
          />
        </div>
      </div>
      <div className={clsx(classes.container, classes.footer)}>
        <RoundedButton
          primary
          size={BUTTON_SIZES.small}
          isLoading={commentQuery.isSubmitting}
          disabled={
            disablePostButton ||
            !commentQuery.text.trim() ||
            commentQuery.isSubmitting
          }
          onClick={() => saveCommentHandler(commentQuery)}
        >
          Post
        </RoundedButton>
        <NoteCommentActions
          emojiPickerOpen={emojiPickerOpen}
          toggleEmojiPicker={emojiPickerToggle}
          onEmojiSelectHandler={onEmojiSelectHandler}
        />
      </div>
    </div>
  )
}

NoteNewReply.propTypes = {
  threadId: PropTypes.number,
  threadResolved: PropTypes.bool,
  avatar: PropTypes.object,
  placeHolder: PropTypes.string,
  disablePostButton: PropTypes.bool,
  isThreadInitializer: PropTypes.bool,
  openModalOnSaveComment: PropTypes.bool,
  popOverTransformOrigin
}

NoteNewReply.defaultProps = {
  threadResolved: false,
  avatar: null,
  placeHolder: '',
  disablePostButton: false,
  isThreadInitializer: false,
  openModalOnSaveComment: true,
  popOverTransformOrigin: {
    horizontal: TRANSFORM_HORIZONTAL.Right,
    vertical: TRANSFORM_VERTICAL.Top
  }
}

export default NoteNewReply
