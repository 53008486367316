import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { animated, useSpring } from 'react-spring'
import { makeStyles } from '@material-ui/core/styles'
import { IconButton, darken, useTheme } from '@material-ui/core'
import isEmpty from 'lodash.isempty'
import noop from 'lodash/noop'
import clsx from 'clsx'
import Tooltip from '@material-ui/core/Tooltip'
import { childrenSchema } from '../../../prop-types'
import {
  useAppContext,
  useSetIsNotesBoardOpen
} from '../../../redux/slices/appContext'
import { useFeatureFlag } from '../../../redux/slices/appConfig'
import { useSetNotesClientId } from '../../../redux/slices/noteContext'
import {
  HTTP_STATUS_CODES,
  FEATURE_FLAG,
  ICON_NAMES,
  TEXT_VARIANTS,
  WEALTHBOX_INTEGRATION,
  SALESFORCE_INTEGRATION,
  REDTAIL_INTEGRATION, ALERT_SEVERITY
} from '../../../constants'
import { fetchSalesforceClient, fetchWealthboxClient, fetchRedtailClient, integrations } from '../../../service'
import Icon from '../../atoms/Icon'
import Text from '../../atoms/Text'
import RightPanel from '../RightPanel'
import SnackAlert from '../../molecules/SnackAlert/SnackAlert'
import SalesforceInfo from './SalesforceInfo'
import WealthboxInfo from './WealthboxInfo'
import RedtailInfo from './RedtailInfo'

export const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '220px',
    height: '260px',
    background: theme.palette.gray.lighter,
    borderRadius: '8px',
    margin: '0px 50px 40px 0px',
    border: `2px solid ${theme.palette.gray.lighter}`,
    boxSizing: 'border-box',
    '&:hover': {
      background: theme.palette.common.white,
      border: '2px solid rgba(33, 41, 69, 0.24)',
      cursor: 'pointer'
    }
  },
  actions: {
    position: 'absolute',
    bottom: 0,
    height: '50px',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'transparent'
  },
  iconButton: {
    margin: '0 5px',
    padding: '1rem',
    backgroundColor: theme.palette.seaFoam,
    '&:hover': {
      backgroundColor: darken(theme.palette.seaFoam, 0.1)
    }
  },
  iconButtonDisabled: {
    cursor: 'not-allowed !important',
    opacity: '0.5',
    '&:hover': {
      backgroundColor: theme.palette.seaFoam
    }
  },
  notification: {
    backgroundColor: theme.palette.success.A500,
    color: theme.palette.white,
    '&:hover': {
      backgroundColor: `${darken(theme.palette.success.A500, 0.1)} !important`
    }
  },
  notificationIcon: {
    padding: '0.75rem !important',
    position: 'absolute',
    margin: '0 !important',
    right: '0.5rem',
    top: '0.5rem'
  },
  rightPanelSubtitle: {
    margin: '0 0 0 70px',
    fontSize: '12px',
    textDecoration: 'underline'
  },
  redtailRightPanelSubtitle: {
    position: 'absolute',
    right: '25px',
    top: '20px'
  },
  redtailIconScale: {
    transform: 'scale(1.50)'
  }
}))

const getRightPanelTitle = (SHOW_SALESFORCE_CLIENT_INFO, SHOW_WEALTHBOX_CLIENT_INFO, SHOW_REDTAIL_CLIENT_INFO) => {
  let rightPanelTittle = SHOW_SALESFORCE_CLIENT_INFO && 'Salesforce Info'
  rightPanelTittle = (SHOW_WEALTHBOX_CLIENT_INFO) ? 'Wealthbox Info' : rightPanelTittle
  rightPanelTittle = (SHOW_REDTAIL_CLIENT_INFO) ? 'Redtail Info' : rightPanelTittle
  return rightPanelTittle
}

function ClientCard ({
  data,
  children,
  isCrmAuthorized,
  onClick
}) {
  const classes = useStyles()
  const {
    clientsNotifications,
    isAdvisor
  } = useAppContext()

  const {
    active: INTEGRATIONS,
    extras: INTEGRATIONS_LIST = { active: [] }
  } = useFeatureFlag(FEATURE_FLAG.INTEGRATIONS)
  const { active: REDTAIL_UPDATE_CLIENT_ACCOUNTS } = useFeatureFlag(FEATURE_FLAG.REDTAIL_UPDATE_CLIENT_ACCOUNTS)
  const SHOW_WEALTHBOX_CLIENT_INFO = INTEGRATIONS && INTEGRATIONS_LIST.active.includes(WEALTHBOX_INTEGRATION)
  const SHOW_REDTAIL_CLIENT_INFO = INTEGRATIONS && INTEGRATIONS_LIST.active.includes(REDTAIL_INTEGRATION)
  const SHOW_SALESFORCE_CLIENT_INFO = INTEGRATIONS && INTEGRATIONS_LIST.active.includes(SALESFORCE_INTEGRATION)

  const rightPanelTittle = getRightPanelTitle(SHOW_SALESFORCE_CLIENT_INFO, SHOW_WEALTHBOX_CLIENT_INFO, SHOW_REDTAIL_CLIENT_INFO)

  const [isHovered, setIsHovered] = useState(false)
  const [openRightPanel, setOpenRightPanel] = useState(false)
  const [salesforceInfo, setSalesforceInfo] = useState({})
  const [wealthboxInfo, setWealthboxInfo] = useState({})
  const [redtailInfo, setRedtailInfo] = useState({})
  const [alert, setAlert] = useState(null)
  const theme = useTheme()

  const onMouseEnter = () => setIsHovered(true)
  const onMouseLeave = () => setIsHovered(false)

  const setNotesClientId = useSetNotesClientId()
  const setIsNotesBoardOpen = useSetIsNotesBoardOpen()

  const actionsProps = useSpring({
    from: {
      bottom: 0,
      opacity: 0
    },
    to: {
      bottom: isHovered ? 20 : 0,
      opacity: isHovered ? 1 : 0
    }
  })

  const clientInfo = useMemo(() => {
    const {
      clientId,
      salesforceId
    } = data?.client || {}
    return {
      clientId,
      salesforceId
    }
  }, [data])

  const hasNotification = useMemo(() => {
    if (data?.client) {
      const { clientId } = data.client
      const clientNotification = clientsNotifications.find(
        ({ clientId: clientWithNotificationId }) =>
          clientWithNotificationId === clientId
      )
      return (
        clientNotification && clientNotification.notifications.unread !== 0
      )
    }
  }, [data, clientsNotifications])

  const onCommentClick = useCallback(
    async (e) => {
      e.stopPropagation()
      setNotesClientId(clientInfo.clientId)
      setIsNotesBoardOpen(true)
    },
    [clientInfo, setNotesClientId, setIsNotesBoardOpen]
  )

  const onGetExtraInfo = useCallback(async (event) => {
    event.stopPropagation()
    setOpenRightPanel(true)
    setIsHovered(false)

    if (isEmpty(salesforceInfo)) {
      const {
        response,
        data
      } = await fetchSalesforceClient({
        clientId: clientInfo.salesforceId
      })

      if (response.status === HTTP_STATUS_CODES.SUCCESS) {
        setSalesforceInfo(data)
      } else if (response.status === HTTP_STATUS_CODES.NO_CONTENT) {
        setSalesforceInfo({
          _empty: true,
          clientId: clientInfo.salesforceId
        })
      } else {
        console.error(`Unexpected response status: ${response.status}`)
        setSalesforceInfo({
          _error: true,
          clientId: clientInfo.salesforceId
        })
      }
    }
  }, [clientInfo?.salesforceId, salesforceInfo])

  const onGetWealthboxInfo = useCallback(async (event) => {
    event.stopPropagation()
    setOpenRightPanel(true)
    setIsHovered(false)

    if (isEmpty(wealthboxInfo)) {
      const result = await fetchWealthboxClient({
        clientId: clientInfo.clientId
      })
      const {
        response,
        data
      } = result
      if (response.status === HTTP_STATUS_CODES.SUCCESS) {
        setWealthboxInfo(data)
      } else if (response.status === HTTP_STATUS_CODES.NO_CONTENT) {
        setWealthboxInfo({
          _empty: true,
          clientId: clientInfo.clientId
        })
      } else {
        console.error(`Unexpected response status: ${response.status}`)
        setWealthboxInfo({
          _error: true,
          clientId: clientInfo.clientId
        })
      }
    }
  }, [clientInfo?.clientId, wealthboxInfo])

  const onGetRedtailInfo = useCallback(async (event, forceFetch = false) => {
    if (event) {
      event.stopPropagation()
    }

    if (!forceFetch || event) {
      setOpenRightPanel(true)
      setIsHovered(false)
    }

    if ((isEmpty(redtailInfo) || forceFetch) && (openRightPanel || event)) {
      const result = await fetchRedtailClient({
        clientId: clientInfo.clientId
      })
      const {
        response,
        data
      } = result
      if (response.status === HTTP_STATUS_CODES.SUCCESS) {
        setRedtailInfo(data)
        return data
      } else if (response.status === HTTP_STATUS_CODES.NO_CONTENT) {
        setRedtailInfo({
          _empty: true,
          clientId: clientInfo.clientId
        })
      } else {
        console.error(`Unexpected response status: ${response.status}`)
        setRedtailInfo({
          _error: true,
          clientId: clientInfo.clientId
        })
      }
    }
  }, [clientInfo?.clientId, redtailInfo, openRightPanel])

  const closeRightPanel = (event) => {
    event.stopPropagation()
    setOpenRightPanel(false)
  }

  const toggleOpen = (event) => {
    event.stopPropagation()
    setOpenRightPanel(false)
  }

  useEffect(() => {
    if (redtailInfo.accountUploadInProgress) {
      setTimeout(() => {
        onGetRedtailInfo(null, true)
          .catch(console.error)
      }, 3000)
    }
  }, [redtailInfo, onGetRedtailInfo])

  const uploadRedtailAccountAssets = useCallback((clientId) => {
    setRedtailInfo({ ...redtailInfo, accountUploadInProgress: true })
    integrations[REDTAIL_INTEGRATION].uploadAccountAssets(clientId)
      .then(() => {
        setAlert({
          openAlert: true,
          alertMessage: 'Uploading client data.',
          alertSeverity: ALERT_SEVERITY.success
        })
        setTimeout(() => {
          setAlert(null)
        }, 6000)
      })
      .catch((ex) => {
        setRedtailInfo({ ...redtailInfo, accountUploadInProgress: false })
        console.error(ex)
      })
  }, [redtailInfo])

  const rightPanelSubtitle = useMemo(() => {
    if (salesforceInfo?.url) {
      return (
        <Link
          to={{ pathname: salesforceInfo.url }}
          target='_blank'
          className={classes.rightPanelSubtitle}
        >
          View in Salesforce
        </Link>
      )
    }
    if (SHOW_REDTAIL_CLIENT_INFO && REDTAIL_UPDATE_CLIENT_ACCOUNTS && clientInfo?.clientId) {
      return (
        <Tooltip title='Send account data to Redtail'>
          <IconButton
            className={classes.redtailRightPanelSubtitle}
            onClick={() => uploadRedtailAccountAssets(clientInfo.clientId)}
            disabled={redtailInfo?.accountUploadInProgress}
            focusRipple={false}
          >
            <Icon name={ICON_NAMES.cloudUpload} customSize='1.25rem' color={theme.palette.grey[800]} />
          </IconButton>
        </Tooltip>
      )
    }
  }, [
    salesforceInfo.url,
    SHOW_REDTAIL_CLIENT_INFO,
    REDTAIL_UPDATE_CLIENT_ACCOUNTS,
    clientInfo.clientId,
    classes.rightPanelSubtitle,
    classes.redtailRightPanelSubtitle,
    theme.palette.grey,
    redtailInfo,
    uploadRedtailAccountAssets
  ])

  return (
    <div
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      className={classes.container}
      onClick={onClick}
    >
      {hasNotification && (
        <IconButton
          className={clsx(
            classes.iconButton,
            classes.notificationIcon,
            classes.notification
          )}
          onClick={noop}
        >
          <Icon
            name={ICON_NAMES.chat}
            customSize='1rem'
            padding='0.75rem'
          />
        </IconButton>
      )}
      {children}
      <animated.div
        style={actionsProps}
        className={classes.actions}
      >
        <>
          <IconButton
            className={clsx(classes.iconButton, {
              [classes.notification]: hasNotification
            })}
            onClick={onCommentClick}
          >
            <Icon
              name='chat'
              customSize='1.125rem'
              padding='1rem'
            />
          </IconButton>
          {SHOW_SALESFORCE_CLIENT_INFO && isAdvisor && isCrmAuthorized && (
            <IconButton
              className={clsx(classes.iconButton)}
              onClick={onGetExtraInfo}
            >
              <Icon
                name={ICON_NAMES.salesforceSmall}
                customSize='1.125rem'
                padding='1rem'
              />
            </IconButton>
          )}
          {SHOW_WEALTHBOX_CLIENT_INFO && isAdvisor && isCrmAuthorized && (
            <IconButton
              className={clsx(classes.iconButton)}
              onClick={onGetWealthboxInfo}
            >
              <Icon
                name='wealthboxSmall'
                customSize='1.125rem'
                padding='1rem'
              />
            </IconButton>
          )}

          {SHOW_REDTAIL_CLIENT_INFO && isAdvisor && isCrmAuthorized && (
            <IconButton
              className={clsx(classes.iconButton)}
              onClick={onGetRedtailInfo}
            >
              <Icon
                name='redtailSmall'
                customSize='1.125rem'
                padding='1rem'
                additionalClasses={clsx(classes.redtailIconScale)}
              />
            </IconButton>
          )}

        </>
      </animated.div>
      <RightPanel
        open={openRightPanel}
        title={<Text text={rightPanelTittle} variant={TEXT_VARIANTS.h2} />}
        subtitle={rightPanelSubtitle}
        toggleOpen={toggleOpen}
        width='400px'
        height='100%'
      >
        {SHOW_SALESFORCE_CLIENT_INFO && isAdvisor && (
          <SalesforceInfo
            data={salesforceInfo}
            onClose={closeRightPanel}
          />
        )}

        {SHOW_WEALTHBOX_CLIENT_INFO && isAdvisor && (
          <WealthboxInfo
            data={wealthboxInfo}
            onClose={closeRightPanel}
          />
        )}

        {SHOW_REDTAIL_CLIENT_INFO && isAdvisor && (
          <RedtailInfo
            data={redtailInfo}
            onClose={closeRightPanel}
            showAccountsUpdatedField={REDTAIL_INTEGRATION && REDTAIL_UPDATE_CLIENT_ACCOUNTS}
          />
        )}
      </RightPanel>
      {alert && <SnackAlert alert={alert} />}
    </div>
  )
}

ClientCard.propTypes = {
  children: childrenSchema.isRequired,
  data: PropTypes.object.isRequired,
  isCrmAuthorized: PropTypes.bool,
  onClick: PropTypes.func
}

ClientCard.defaultProps = {
  onClick: undefined,
  isCrmAuthorized: false
}

export default ClientCard
