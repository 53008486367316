import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { ELEMENT_ALIGNMENTS, TEXT_VARIANTS } from '../../constants'

const useStyles = makeStyles((theme) => ({
  container: (props) => ({
    display: 'flex',
    flexDirection: 'row',
    ...(!props.spaceBetween ? { justifyContent: 'space-between' } : {}),
    boxSizing: 'border-box',
    alignItems: 'center',
    ...(!props.noBorderBottom && {
      borderBottomStyle: 'solid',
      borderBottomWidth: '1.5px',
      borderColor: theme.palette.gray.main
    }),
    padding: props.customPadding
      ? props.customPadding
      : `${theme.spacing(1.5)}px ${theme.spacing(0.5)}px`,
    width: '100%'
  }),
  leftElement: ({ spaceBetween }) => ({
    marginRight: spaceBetween || theme.spacing(1)
  }),
  titleContainer: ({ mainAlignment }) => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: mainAlignment || 'center'
  })
}))

const ListItem = ({
  additionalClasses,
  customPadding,
  leftElement,
  mainAlignment,
  rightElement,
  spaceBetween,
  title,
  titleElement,
  titleVariant,
  noBorderBottom
}) => {
  const classes = useStyles({
    mainAlignment,
    noBorderBottom,
    customPadding,
    spaceBetween
  })

  const renderedTitle = useMemo(() => {
    if (titleElement) return titleElement
    return (
      <Typography component='div' variant={titleVariant}>
        {title}
      </Typography>
    )
  }, [title, titleElement, titleVariant])
  return (
    <div className={clsx(additionalClasses, classes.container)}>
      <div className={classes.titleContainer}>
        {leftElement && (
          <div className={classes.leftElement}>{leftElement}</div>
        )}
        {renderedTitle}
      </div>
      {rightElement && (
        <div className={classes.rightElement}>
          <div>{rightElement}</div>
        </div>
      )}
    </div>
  )
}

ListItem.propTypes = {
  additionalClasses: PropTypes.string,
  customPadding: PropTypes.string,
  leftElement: PropTypes.node,
  mainAlignment: PropTypes.oneOf(Object.values(ELEMENT_ALIGNMENTS)),
  noBorderBottom: PropTypes.bool,
  rightElement: PropTypes.node,
  spaceBetween: PropTypes.string,
  title: PropTypes.string,
  titleElement: PropTypes.node,
  titleVariant: PropTypes.oneOf(Object.values(TEXT_VARIANTS))
}

ListItem.defaultProps = {
  title: '',
  additionalClasses: '',
  leftElement: null,
  noBorderBottom: false,
  customPadding: null,
  rightElement: null,
  titleElement: null,
  spaceBetween: null,
  titleVariant: TEXT_VARIANTS.body1,
  mainAlignment: ELEMENT_ALIGNMENTS.center
}

export default ListItem
