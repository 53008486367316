import React, { useCallback } from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import calcTree from 'relatives-tree'
import { makeStyles } from '@material-ui/core'
import { familyTreeNode } from '../../prop-types'

const useStyles = makeStyles((theme) => ({
  tree: ({ canvas, width, height }) => ({
    position: 'relative',
    borderRadius: '0.25rem',
    width: canvas.width * width,
    height: canvas.height * height,
    background: theme.palette.white
  }),
  connector: {
    position: 'absolute',
    pointerEvents: 'none',
    background: theme.palette.gallery
  }
}))

const FamilyTree = ({
  rootId,
  className,
  renderNode,
  placeholders,
  width: _width,
  height: _height,
  nodes: treeNodes,
  hideConnectors
}) => {
  const { canvas, connectors, nodes } = calcTree(treeNodes, {
    rootId: rootId,
    placeholders: placeholders
  })
  const width = _width / 2
  const height = _height / 2
  const classes = useStyles({ canvas, width, height })

  const renderConnector = useCallback((connector, id) => {
    const [x1, y1, x2, y2] = connector
    return (
      <i
        key={id}
        className={classes.connector}
        style={{
          width: Math.max(1, (x2 - x1) * width + 1),
          height: Math.max(1, (y2 - y1) * height + 1),
          transform: `translate(${x1 * width}px, ${y1 * height}px)`
        }}
      />
    )
  }, [classes.connector, width, height])

  return (
    <div className={clsx(classes.tree, { [className]: !!className })}>
      {hideConnectors && connectors.map((connector, idx) =>
        renderConnector(connector, idx)
      )}
      {nodes.map(renderNode)}
    </div>
  )
}

FamilyTree.propTypes = {
  rootId: PropTypes.string.isRequired,
  className: PropTypes.string,
  nodes: PropTypes.arrayOf(familyTreeNode),
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  placeholders: PropTypes.string,
  renderNode: PropTypes.func.isRequired,
  hideConnectors: PropTypes.bool
}

FamilyTree.defaultProps = {
  className: '',
  nodes: [],
  placeholders: ''
}

export default FamilyTree
