import get from 'lodash/get'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import sameOrAfter from 'dayjs/plugin/isSameOrAfter'
import sameOrBefore from 'dayjs/plugin/isSameOrBefore'
import isBetween from 'dayjs/plugin/isBetween'

dayjs.extend(utc)
dayjs.extend(sameOrAfter)
dayjs.extend(sameOrBefore)
dayjs.extend(isBetween)

/**
 * Retrieves a value from an object, either directly or via a path.
 * @param {*} val - The value or path to retrieve.
 * @param {Object} item - The object to retrieve the value from.
 * @returns {*} The retrieved value.
 */
function getValueOrPath (val, item) {
  if (val === '$') return val
  if (typeof val === 'string' && val.startsWith('$.')) {
    const result = get(item, val.slice(2))
    console.log(`getting path: '${val.slice(2)}': ${result}`, item)

    return result
  }
  return val
}

/**
 * gets a path from an object, but allows the symbol $ for identity
 * @param {Object} item
 * @param {string} path
 * @returns {*} The value at the path, or undefined
 */
function resolvePath (item, path) {
  if (path === '$') return item

  return get(item, path)
}

/**
 * @typedef {Object} Operators
 * @property {Function} $eq - Equality operator.
 * @property {Function} $neq - Not equal operator.
 * @property {Function} $within - Range operator.
 * @property {Function} $any - Array inclusion operator.
 * @property {Function} $and - Logical AND operator.
 * @property {Function} $or - Logical OR operator.
 * @property {Function} $use - Property usage operator.
 * @property {Function} $gt - Greater than operator.
 * @property {Function} $gte - Greater than or equal operator.
 * @property {Function} $lt - Less than operator.
 * @property {Function} $lte - Less than or equal operator.
 * @property {Function} $not - Logical NOT operator.
 * @property {Function} $sw - Starts with operator.
 * @property {Function} $ew - Ends with operator.
 * @property {Function} $contains - Contains operator.
 * @property {Function} $matches - Exact equality operator (considering case sensitivity).
 * @property {Function} $regex - Regular expression operator.
 * @property {Function} $dateSameAs - Date equality operator.
 * @property {Function} $dateNotSameAs - Date inequality operator.
 * @property {Function} $dateBefore - Date before operator.
 * @property {Function} $dateSameOrBefore - Date same or before operator.
 * @property {Function} $dateAfter - Date after operator.
 * @property {Function} $dateSameOrAfter - Date same or after operator.
 * @property {Function} $dateWithin - Date within range.
 * @property {Function} $withinPercentage - Within percentage operator.
 */

/**
 * Ensures the value is a string, or throws an error if strict mode is enabled.
 * @param {*} value - The value to check.
 * @param {boolean} strict - Whether to use strict mode.
 * @returns {string} The string value.
 * @throws {Error} If the value is not a string in strict mode.
 */
function ensureString (value, strict) {
  if (typeof value === 'string') return value
  if (strict) throw new Error(`Value ${value} is not a string`)
  return String(value)
}

/**
 * Ensures the value is a valid date, or throws an error if it's not.
 * @param {*} value - The value to check.
 * @param {boolean} useUtc - Whether to use UTC.
 * @returns {dayjs.Dayjs|null} The dayjs date object or null if the value is null/undefined.
 * @throws {Error} If the value is not a valid date.
 */
function ensureDate (value, useUtc) {
  if (value == null) {
    return null
  }
  const date = useUtc ? dayjs.utc(value) : dayjs(value)
  if (!date.isValid()) {
    throw new Error(`Value ${value} is not a valid date`)
  }
  return date
}

/**
 * Object containing operator functions for rule evaluation.
 * @type {Operators}
 */
const operators = {
  /**
   * Equality operator.
   * @param {Object} item - The item to evaluate.
   * @param {Object} rule - The rule containing the $eq operator.
   * @returns {boolean} True if the item's property is equal to the comparison value.
   */
  $eq: (item, rule) => {
    const propertyValue = resolvePath(item, rule.$eq)
    const comparisonValue = getValueOrPath(rule.value, item)

    return propertyValue === comparisonValue
  },

  /**
   * Not equal operator.
   * @param {Object} item - The item to evaluate.
   * @param {Object} rule - The rule containing the $neq operator.
   * @returns {boolean} True if the item's property is not equal to the comparison value.
   */
  $neq: (item, rule) => {
    const propertyValue = resolvePath(item, rule.$neq)
    const comparisonValue = getValueOrPath(rule.value, item)

    return propertyValue !== comparisonValue
  },

  /**
   * Greater than operator.
   * @param {Object} item - The item to evaluate.
   * @param {Object} rule - The rule containing the $gt operator.
   * @returns {boolean} True if the item's property is greater than the comparison value.
   */
  $gt: (item, rule) => {
    const propertyValue = resolvePath(item, rule.$gt)
    const comparisonValue = getValueOrPath(rule.value, item)
    return propertyValue > comparisonValue
  },

  /**
   * Greater than or equal operator.
   * @param {Object} item - The item to evaluate.
   * @param {Object} rule - The rule containing the $gte operator.
   * @returns {boolean} True if the item's property is greater than or equal to the comparison value.
   */
  $gte: (item, rule) => {
    const propertyValue = resolvePath(item, rule.$gte)
    const comparisonValue = getValueOrPath(rule.value, item)
    return propertyValue >= comparisonValue
  },

  /**
   * Less than operator.
   * @param {Object} item - The item to evaluate.
   * @param {Object} rule - The rule containing the $lt operator.
   * @returns {boolean} True if the item's property is less than the comparison value.
   */
  $lt: (item, rule) => {
    const propertyValue = resolvePath(item, rule.$lt)
    const comparisonValue = getValueOrPath(rule.value, item)
    return propertyValue < comparisonValue
  },

  /**
   * Less than or equal operator.
   * @param {Object} item - The item to evaluate.
   * @param {Object} rule - The rule containing the $lte operator.
   * @returns {boolean} True if the item's property is less than or equal to the comparison value.
   */
  $lte: (item, rule) => {
    const propertyValue = resolvePath(item, rule.$lte)
    const comparisonValue = getValueOrPath(rule.value, item)
    return propertyValue <= comparisonValue
  },

  /**
   * Within range operator.
   * @param {Object} item - The item to evaluate.
   * @param {Object} rule - The rule containing the $within operator.
   * @returns {boolean} True if the item's property is within the specified range.
   */
  $within: (item, rule) => {
    const propertyValue = +resolvePath(item, rule.$within)
    const baseValue = +getValueOrPath(rule.value, item)
    const lowerBound = baseValue - (+rule.tolerance || 0)
    const upperBound = baseValue + (+rule.tolerance || 0)

    return propertyValue >= lowerBound && propertyValue <= upperBound
  },

  /**
   * Array inclusion operator.
   * @param {Object} item - The item to evaluate.
   * @param {Object} rule - The rule containing the $any operator.
   * @param {Function} evaluate - The evaluate function to use for nested rules.
   * @returns {boolean} True if any element in the array satisfies the condition.
   */
  $any: (item, rule, evaluate) => {
    const propertyValue = resolvePath(item, rule.$any)
    if (Array.isArray(propertyValue)) {
      return propertyValue.some(val => evaluate(val, rule.has))
    }
    return evaluate(propertyValue, rule.has)
  },

  /**
   * Logical AND operator.
   * @param {Object} item - The item to evaluate.
   * @param {Object} rule - The rule containing the $and operator.
   * @param {Function} evaluate - The evaluate function to use for nested rules.
   * @returns {boolean} True if all nested rules evaluate to true.
   * @throws {Error} If $and is not assigned an array of rules.
   */
  $and: (item, rule, evaluate) => {
    if (!Array.isArray(rule.$and)) {
      throw new Error('incorrect usage of $and: must assign an array of rules')
    }

    return rule.$and.reduce((prev, cur) => {
      console.log(`$and: ${JSON.stringify(cur)}: ${evaluate(item, cur)}`)
      return prev && evaluate(item, cur)
    }, rule.$and.length > 0)
  },

  /**
   * Logical OR operator.
   * @param {Object} item - The item to evaluate.
   * @param {Object} rule - The rule containing the $or operator.
   * @param {Function} evaluate - The evaluate function to use for nested rules.
   * @returns {boolean} True if any nested rule evaluates to true.
   * @throws {Error} If $or is not assigned an array of rules.
   */
  $or: (item, rule, evaluate) => {
    if (!Array.isArray(rule.$or)) {
      throw new Error('incorrect usage of $or: must assign an array of rules')
    }

    return rule.$or.reduce((prev, cur) => {
      return prev || evaluate(item, cur)
    }, false)
  },

  /**
   * Property usage operator.
   * @param {Object} item - The item to evaluate.
   * @param {Object} rule - The rule containing the $use operator.
   * @param {Function} evaluate - The evaluate function to use for nested rules.
   * @returns {boolean} The result of evaluating the nested rule with the specified property.
   */
  $use: (item, rule, evaluate) => {
    const propertyValue = resolvePath(item, rule.$use)
    return evaluate(propertyValue, rule.with)
  },

  /**
   * Logical NOT operator.
   * @param {Object} item - The item to evaluate.
   * @param {Object} rule - The rule containing the $not operator.
   * @param {Function} evaluate - The evaluate function to use for nested rules.
   * @returns {boolean} The negated result of the nested rule evaluation.
   * @throws {Error} If $not is not assigned an object containing a rule.
   */
  $not: (item, rule, evaluate) => {
    if (typeof rule.$not !== 'object' || rule.$not === null) {
      throw new Error('incorrect usage of $not: must assign an object containing a rule')
    }
    return !evaluate(item, rule.$not)
  },

  /**
   * Starts with operator.
   * @param {Object} item - The item to evaluate.
   * @param {Object} rule - The rule containing the $sw operator.
   * @returns {boolean} True if the item's property starts with the comparison value.
   */
  $sw: (item, rule) => {
    const propertyValue = ensureString(resolvePath(item, rule.$sw), rule.strict)
    const comparisonValue = ensureString(getValueOrPath(rule.value, item), rule.strict)
    return rule.case ? propertyValue.startsWith(comparisonValue)
      : propertyValue.toLowerCase().startsWith(comparisonValue.toLowerCase())
  },

  /**
   * Ends with operator.
   * @param {Object} item - The item to evaluate.
   * @param {Object} rule - The rule containing the $ew operator.
   * @returns {boolean} True if the item's property ends with the comparison value.
   */
  $ew: (item, rule) => {
    const propertyValue = ensureString(resolvePath(item, rule.$ew), rule.strict)
    const comparisonValue = ensureString(getValueOrPath(rule.value, item), rule.strict)
    return rule.case ? propertyValue.endsWith(comparisonValue)
      : propertyValue.toLowerCase().endsWith(comparisonValue.toLowerCase())
  },

  /**
   * Contains operator.
   * @param {Object} item - The item to evaluate.
   * @param {Object} rule - The rule containing the $contains operator.
   * @returns {boolean} True if the item's property contains the comparison value.
   */
  $contains: (item, rule) => {
    const propertyValue = ensureString(resolvePath(item, rule.$contains), rule.strict)
    const comparisonValue = ensureString(getValueOrPath(rule.value, item), rule.strict)
    return rule.case ? propertyValue.includes(comparisonValue)
      : propertyValue.toLowerCase().includes(comparisonValue.toLowerCase())
  },

  /**
   * Exact equality operator (considering case sensitivity).
   * @param {Object} item - The item to evaluate.
   * @param {Object} rule - The rule containing the $matches operator.
   * @returns {boolean} True if the item's property exactly matches the comparison value.
   */
  $matches: (item, rule) => {
    const propertyValue = ensureString(resolvePath(item, rule.$matches), rule.strict)
    const comparisonValue = ensureString(getValueOrPath(rule.value, item), rule.strict)
    return rule.case ? propertyValue === comparisonValue
      : propertyValue.toLowerCase() === comparisonValue.toLowerCase()
  },

  /**
   * Regular expression operator.
   * @param {Object} item - The item to evaluate.
   * @param {Object} rule - The rule containing the $regex operator.
   * @returns {boolean} True if the item's property matches the regular expression.
   */
  $regex: (item, rule) => {
    const propertyValue = ensureString(resolvePath(item, rule.$regex), rule.strict)
    const regexPattern = getValueOrPath(rule.value, item)
    const flags = rule.case ? '' : 'i'
    return new RegExp(regexPattern, flags).test(propertyValue)
  },

  /**
   * Date equality operator.
   * @param {Object} item - The item to evaluate.
   * @param {Object} rule - The rule containing the $dateSameAs operator.
   * @returns {boolean} True if the dates are the same.
   */
  $dateSameAs: (item, rule) => {
    const propertyDate = ensureDate(resolvePath(item, rule.$dateSameAs), rule.utc)
    const comparisonDate = ensureDate(getValueOrPath(rule.value, item), rule.utc)
    if (propertyDate === null || comparisonDate === null) return false
    return propertyDate.isSame(comparisonDate, rule.period)
  },

  /**
   * Date inequality operator.
   * @param {Object} item - The item to evaluate.
   * @param {Object} rule - The rule containing the $dateNotSameAs operator.
   * @returns {boolean} True if the dates are not the same.
   */
  $dateNotSameAs: (item, rule) => {
    const propertyDate = ensureDate(resolvePath(item, rule.$dateNotSameAs), rule.utc)
    const comparisonDate = ensureDate(getValueOrPath(rule.value, item), rule.utc)
    if (propertyDate === null || comparisonDate === null) return true
    return !propertyDate.isSame(comparisonDate, rule.period)
  },

  /**
   * Date before operator.
   * @param {Object} item - The item to evaluate.
   * @param {Object} rule - The rule containing the $dateBefore operator.
   * @returns {boolean} True if the property date is before the comparison date.
   */
  $dateBefore: (item, rule) => {
    const propertyDate = ensureDate(resolvePath(item, rule.$dateBefore), rule.utc)
    const comparisonDate = ensureDate(getValueOrPath(rule.value, item), rule.utc)
    if (propertyDate === null || comparisonDate === null) return false
    return propertyDate.isBefore(comparisonDate, rule.period)
  },

  /**
   * Date same or before operator.
   * @param {Object} item - The item to evaluate.
   * @param {Object} rule - The rule containing the $dateSameOrBefore operator.
   * @returns {boolean} True if the property date is the same as or before the comparison date.
   */
  $dateSameOrBefore: (item, rule) => {
    const propertyDate = ensureDate(resolvePath(item, rule.$dateSameOrBefore), rule.utc)
    const comparisonDate = ensureDate(getValueOrPath(rule.value, item), rule.utc)
    if (propertyDate === null || comparisonDate === null) return false
    return propertyDate.isSameOrBefore(comparisonDate, rule.period)
  },

  /**
   * Date after operator.
   * @param {Object} item - The item to evaluate.
   * @param {Object} rule - The rule containing the $dateAfter operator.
   * @returns {boolean} True if the property date is after the comparison date.
   */
  $dateAfter: (item, rule) => {
    const propertyDate = ensureDate(resolvePath(item, rule.$dateAfter), rule.utc)
    const comparisonDate = ensureDate(getValueOrPath(rule.value, item), rule.utc)
    if (propertyDate === null || comparisonDate === null) return false
    return propertyDate.isAfter(comparisonDate, rule.period)
  },

  /**
   * Date same or after operator.
   * @param {Object} item - The item to evaluate.
   * @param {Object} rule - The rule containing the $dateSameOrAfter operator.
   * @returns {boolean} True if the property date is the same as or after the comparison date.
   */
  $dateSameOrAfter: (item, rule) => {
    const propertyDate = ensureDate(resolvePath(item, rule.$dateSameOrAfter), rule.utc)
    const comparisonDate = ensureDate(getValueOrPath(rule.value, item), rule.utc)
    if (propertyDate === null || comparisonDate === null) return false
    return propertyDate.isSameOrAfter(comparisonDate, rule.period)
  },

  /**
   * Date within range operator.
   * @param {Object} item - The item to evaluate.
   * @param {Object} rule - The rule containing the $dateWithin operator.
   * @returns {boolean} True if the property date is within the specified range of the comparison date.
   */
  $dateWithin: (item, rule) => {
    const propertyDate = ensureDate(resolvePath(item, rule.$dateWithin), rule.utc)
    const comparisonDate = ensureDate(getValueOrPath(rule.value, item), rule.utc)
    if (propertyDate === null || comparisonDate === null) return false

    const tolerance = rule.tolerance || 0
    const lowerBound = comparisonDate.subtract(tolerance, rule.period)
    const upperBound = comparisonDate.add(tolerance, rule.period)

    return propertyDate.isBetween(lowerBound, upperBound, rule.period, '[]') // '[]' includes the bounds
  },

  /**
   * Within percentage operator.
   * @param {Object} item - The item to evaluate.
   * @param {Object} rule - The rule containing the $withinPercentage operator.
   * @returns {boolean} True if the item's property is within the specified percentage range.
   */
  $withinPercentage: (item, rule) => {
    const propertyValue = +resolvePath(item, rule.$withinPercentage)
    const baseValue = +getValueOrPath(rule.value, item)
    const tolerance = +rule.tolerance || 0
    const lowerBound = baseValue * (1 - tolerance)
    const upperBound = baseValue * (1 + tolerance)

    return (baseValue >= 0 && propertyValue >= lowerBound && propertyValue <= upperBound) ||
           (baseValue < 0 && propertyValue <= lowerBound && propertyValue >= upperBound)
  }
}

const operations = Object.keys(operators)

/**
 * Evaluates a rule against an item.
 * @param {Object} item - The item to evaluate.
 * @param {Object} rule - The rule to apply.
 * @returns {boolean} The result of the rule evaluation.
 * @throws {Error} If no rule is specified or if multiple rules are specified.
 */
export function evaluateRule (item, rule) {
  const ruleKeys = Object.keys(rule)
  const ops = ruleKeys.filter(ruleKey => operations.includes(ruleKey))
  if (!ops.length) {
    throw new Error('No rule specified')
  }
  if (ops.length > 1) {
    throw new Error('Confusing rule')
  }

  const op = ops.at(0)
  return operators[op](item, rule, evaluateRule)
}
