import React, { forwardRef, useCallback, useImperativeHandle, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import { useSearchAssignedPeople } from '../../../../../api/people'
import StyledDialog from '../../shared/StyledDialog'
import { useClientDetails } from '../ClientDetailsFormContext'
import CreateUserForm from './CreateUserForm'
import { useRoleOptions } from './useRoleOptions'

const useStyles = makeStyles((theme) => ({
  assignmentForm: {
    minHeight: '400px'
  }
}))

const mapPerson = (data) => data?.data[0] ?? null

/**
 * Searches for people related to the current person in state
 * @param current
 * @param client
 * @return {UseQueryResult<*, unknown>}
 */
const usePeople = (current, client) => {
  const query = useMemo(() => ({
    filters: {
      assignedToClientIds: [client.clientId],
      userId: { op: 'isnull' },
      personId: current ? current.personId : null
    },
    includes: {
      users: true,
      experiences: true
    },
    take: 1
  }), [client, current])

  return useSearchAssignedPeople(query, {
    enabled: !!current,
    mapper: mapPerson
  })
}

/**
 * Handles passing the form data into the onConfirm handler
 * @param onConfirm
 * @return {{formRef: React.MutableRefObject<undefined>, submitting: boolean, submitHandler: (function({invite: *}): function(*): Promise<void>)}}
 */
const useSubmitter = (onConfirm) => {
  const formRef = useRef()
  const [submitting, setSubmitting] = useState(false)
  const submitHandler = useCallback(({ invite }) => async (e) => {
    const onValid = async (form) => {
      setSubmitting(true)
      try {
        await onConfirm({
          user: {
            firstName: form.user?.firstName || null,
            lastName: form.user?.lastName || null,
            email: form.user?.email || null,
            internal: form.user?.internal || false,
            userTypeId: 1
          },
          roleId: form.roleId || null,
          personId: form.personId || null,
          invite
        })
      } finally {
        setSubmitting(false)
      }
    }
    const onInvalid = (errors) => {
      console.error(errors)
      alert('There was a problem with your submission')
    }

    const handler = formRef.current.handleSubmit(onValid, onInvalid)
    await handler(e)
  }, [onConfirm, formRef, setSubmitting])

  return {
    submitting,
    submitHandler,
    formRef
  }
}

// Sorry that the form is so complicated. The place where the submit buttons are doesn't work well with the form
const CreateUserDialog = forwardRef(function CreateUserDialog ({ onConfirm }, ref) {
  const classes = useStyles()
  const { client } = useClientDetails()
  const [current, setCurrent] = useState(null)

  const { data, isLoading } = usePeople(current, client)
  const { data: roles, defaultOption, isLoading: rolesLoading } = useRoleOptions()
  const { formRef, submitHandler, submitting } = useSubmitter(onConfirm)

  const innerRef = useRef()
  useImperativeHandle(ref, () => {
    return {
      open: (state) => {
        setCurrent(state)
        innerRef.current?.open()
      },
      close: () => {
        setCurrent(null)
        innerRef.current?.close()
      }
    }
  }, [innerRef, setCurrent])

  return (
    <StyledDialog
      ref={innerRef}
      confirmText='Save'
      specialText='Save and Invite'
      onConfirm={submitHandler({ invite: false })}
      onSpecial={submitHandler({ invite: true })}
      title='Create User'
      size='md'
      processing={submitting}
      disabled={isLoading}
    >
      <div className={classes.assignmentForm}>
        {(isLoading || rolesLoading) ? (
          <div>Please Wait</div>
        ) : (
          <CreateUserForm
            ref={formRef}
            person={data}
            roleOptions={roles}
            defaultRoleOption={defaultOption}
          />
        )}
      </div>
    </StyledDialog>
  )
})

CreateUserDialog.propTypes = {
  onConfirm: PropTypes.func
}

export default CreateUserDialog
