import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import DOMPurify from 'dompurify'
import clsx from 'clsx'
import { ELEMENT_ALIGNMENTS, TEXT_VARIANTS } from '../../constants'

const useStyles = makeStyles(() => ({
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  titleContent: ({ alignment }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: alignment,
    justifyContent: 'flex-start',
    width: '100%'
  }),
  title: ({ titlePadding }) => ({

    padding: titlePadding || 'inherit'
  }),
  subtitleText: ({
    subtitleCustomFontSize,
    subtitleCustomFontWeight,
    subtitleColor
  }) => ({
    color: subtitleColor || 'inherit',
    ...(subtitleCustomFontSize && { fontSize: `${subtitleCustomFontSize} !important` }),
    ...(subtitleCustomFontWeight && { fontWeight: `${subtitleCustomFontWeight} !important` })
  })
}))

const TitleWithSubtitle = ({
  additionalClasses,
  alignment,
  subtitle,
  subtitleElement,
  subtitleVariant,
  subtitleCustomFontSize,
  subtitleCustomFontWeight,
  subtitleColor,
  title,
  titleElement,
  titlePadding,
  titleVariant
}) => {
  const classes = useStyles({
    alignment,
    titlePadding,
    subtitleCustomFontSize,
    subtitleCustomFontWeight,
    subtitleColor
  })

  const renderedTitle = useMemo(() => {
    if (titleElement) return titleElement
    return (
      <Typography component='div' variant={titleVariant}>
        {title}
      </Typography>
    )
  }, [title, titleElement, titleVariant])

  const renderedSubtitle = useMemo(() => {
    if (subtitleElement) return subtitleElement
    if (subtitle) {
      return (
        <Typography
          component='div'
          variant={subtitleVariant}
          classes={{
            root: clsx(classes.subtitleText)
          }}
        >
          <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(subtitle) }} />
        </Typography>
      )
    }
    return null
  }, [subtitle, subtitleElement, subtitleVariant, classes.subtitleText])

  return (
    <div className={`${classes.titleContainer} ${additionalClasses}`}>
      <div className={classes.titleContent}>
        <div className={classes.title}>{renderedTitle}</div>
        {renderedSubtitle}
      </div>
    </div>
  )
}

TitleWithSubtitle.propTypes = {
  additionalClasses: PropTypes.string,
  alignment: PropTypes.oneOf(Object.values(ELEMENT_ALIGNMENTS)),
  subtitle: PropTypes.string,
  subtitleElement: PropTypes.any,
  subtitleVariant: PropTypes.oneOf(Object.keys(TEXT_VARIANTS)),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.any]),
  titleElement: PropTypes.any,
  titlePadding: PropTypes.string,
  titleVariant: PropTypes.oneOf(Object.keys(TEXT_VARIANTS)),
  subtitleCustomFontSize: PropTypes.string,
  subtitleCustomFontWeight: PropTypes.string,
  subtitleColor: PropTypes.string
}

TitleWithSubtitle.defaultProps = {
  additionalClasses: '',
  alignment: ELEMENT_ALIGNMENTS.flexStart,
  subtitle: undefined,
  subtitleElement: null,
  subtitleVariant: TEXT_VARIANTS.body1,
  title: '',
  titleElement: null,
  titlePadding: undefined,
  titleVariant: TEXT_VARIANTS.body2,
  subtitleCustomFontSize: '',
  subtitleCustomFontWeight: '',
  subtitleColor: ''
}

export default TitleWithSubtitle
