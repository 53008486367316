import React from 'react'
import PropTypes from 'prop-types'
import { alpha, lighten, makeStyles } from '@material-ui/core'
import { Link, useRouteMatch } from 'react-router-dom'
import FadeIn from '../../molecules/Transitions/FadeIn'
import Icon from '../../atoms/Icon'
import { usePreloadAbundanceEngineView } from '../../../api/abundanceEngine'
import { useGetViewConfig, useHomeDashboard } from './HomeDashboardContext'

const useStyles = makeStyles((theme) => ({
  navigationCard: {
    backgroundColor: alpha(theme.palette.background.default, 0.9),
    padding: `${theme.layout.padding.medium} ${theme.layout.padding.loose}`,
    borderRadius: theme.layout.radius.round,
    minHeight: '80px',
    height: '80px',
    width: '100%',
    outline: ({ match }) => match ? `2px solid ${theme.palette.primary.main}` : `1px solid ${theme.palette.gray.dark}`,
    boxShadow: theme.layout.shadow.normal,
    transition: 'outline .2s ease-in-out, background-color .2s ease-in-out',
    '&:hover': {
      outline: ({ match }) => match ? `2px solid ${theme.palette.primary.main}` : `2px solid ${alpha(theme.palette.primary.main, 0.5)}`
    },
    '&:active': {
      transition: 'background-color 0s',
      backgroundColor: lighten(theme.palette.primary.main, 0.9)
    },
    '& .__text': {
      fontSize: theme.typography.fontSizes.xxl
    }
  },
  nohover: {
    '&:hover': {
      textDecoration: 'none !important'
    }
  },
  navigationContent: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.layout.gap.medium,
    alignItems: 'center',
    height: '100%'
  },
  icon: {
    fill: theme.palette.primary.main
  }
}))

function NavigationCard ({ id, name, style }) {
  const { rootUrl } = useHomeDashboard()
  const { path, icon } = useGetViewConfig(id)
  const match = useRouteMatch(`${rootUrl}/${path}`)
  const classes = useStyles({ match })
  const { preload } = usePreloadAbundanceEngineView(path)

  if (!path) {
    return (
      <FadeIn className={classes.navigationCard} onMouseEnter={preload} style={style}>
        <div className={classes.navigationContent}>
          {icon ? (<Icon additionalClasses={classes.icon} name={icon} customSize={25} />) : null}
          <span className='__text'>{name}</span>
        </div>
      </FadeIn>
    )
  }

  return (
    <Link className={classes.nohover} to={`${rootUrl}/${path}`} onMouseEnter={preload}>
      <FadeIn className={classes.navigationCard} style={style}>
        <div className={classes.navigationContent}>
          {icon ? (<Icon additionalClasses={classes.icon} name={icon} customSize={25} />) : null}
          <span className='__text'>{name}</span>
        </div>
      </FadeIn>
    </Link>
  )
}

NavigationCard.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  style: PropTypes.object
}

export default NavigationCard
