import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useMyUserProfile } from '../../api/users'

const pendo = global.pendo ?? {
  initialize () {}
}

const fullName = (firstName, lastName) => {
  if (firstName && lastName) {
    return `${firstName} ${lastName}`
  }
  if (firstName) {
    return firstName
  }

  return lastName
}

const AnalyticsProvider = ({ children, currentUser }) => {
  const [initialized, setInitialized] = useState(false)
  const { data: profile, isLoading } = useMyUserProfile()

  useEffect(() => {
    if (initialized) return
    if (!currentUser) return
    if (isLoading) return

    try {
      pendo.initialize({
        visitor: {
          id: currentUser.userId,
          email: currentUser.email,
          full_name: fullName(currentUser.firstName, currentUser.lastName),
          role: currentUser.userType,
          firmId: currentUser.firmId,
          internalId: currentUser.internalId,
          status: currentUser.status,
          assignedRoleId: profile.roleId,
          assignedRoleName: profile.roleName
        },
        account: {
          id: currentUser.firmId
        }
      })
      setInitialized(true)
      console.log('pendo initialized')
    } catch (err) {
      console.warn('unable to initialize pendo:', err.toString())
    }
  }, [currentUser, initialized, setInitialized, isLoading, profile])

  return children
}

AnalyticsProvider.propTypes = {
  children: PropTypes.node,
  currentUser: PropTypes.shape({
    userId: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    userType: PropTypes.string,
    firmId: PropTypes.number,
    internalId: PropTypes.number,
    status: PropTypes.string
  })
}

export default AnalyticsProvider
