import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core'
import { usePolicy } from '../../../hooks/usePolicy'
import EditClientInfoDialog from './EditClientInfoDialog'
import ClientInfoForm from './ClientInfoForm'

const useStyles = makeStyles((theme) => ({
  buttonContainer: ({ canEdit }) => canEdit ? {
    '&:hover': {
      cursor: 'pointer'
    }
  } : {}
}))

function EditClientInfoButton ({ children, className, client, onClose }) {
  const canEditWealthMission = usePolicy('wealth_mission_edit')
  const canEditClientAvatar = usePolicy('client_avatar_edit')
  const canEdit = canEditWealthMission || canEditClientAvatar
  const classes = useStyles({ canEdit })
  const [state, setState] = useState({ open: false })

  const close = useCallback((e) => {
    e.preventDefault()
    e.stopPropagation()
    setState({ open: false })
    onClose()
  }, [setState, onClose])

  const onClick = useCallback((e) => {
    if (!canEdit) return
    e.preventDefault()
    e.stopPropagation()
    setState({ open: true })
  }, [setState, canEdit])

  return (
    <div className={clsx(classes.buttonContainer, className)} role='button' onClick={onClick}>
      {children}
      <EditClientInfoDialog open={state.open} onClose={close} title={client?.longName}>
        <ClientInfoForm
          client={client}
          canEditWealthMission={canEditWealthMission}
          canEditClientAvatar={canEditClientAvatar}
          onCancel={close}
          onConfirm={close}
        />
      </EditClientInfoDialog>
    </div>
  )
}

EditClientInfoButton.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  client: PropTypes.shape({
    clientId: PropTypes.number,
    extras: PropTypes.object,
    longName: PropTypes.string
  }),
  onClose: PropTypes.func
}

EditClientInfoButton.defaultProps = {
  onClose: () => {}
}

export default EditClientInfoButton
