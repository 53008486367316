import React from 'react'
import PropTypes from 'prop-types'
import Image from '../../../atoms/Image'

const Logo = ({
  settings
}) => {
  const { src, width, maxWidth, height, maxHeight, isPublicResource, ...rest } = settings
  return (
    <Image
      src={src}
      height={height || maxHeight}
      width={width || maxWidth}
      isPublicResource={isPublicResource}
      extraStyles={rest}
    />
  )
}

Logo.propTypes = {
  settings: PropTypes.object.isRequired
}

export default Logo
