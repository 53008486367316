import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import TargetList from './TargetList'

const useStyles = makeStyles({
  jobs: {
    padding: '1rem'
  }
})

const TargetsIndex = () => {
  const classes = useStyles()

  return (
    <article className={classes.jobs}>
      <main>
        <TargetList />
      </main>
    </article>
  )
}

export default TargetsIndex
