import React from 'react'
import PropTypes from 'prop-types'
import Section from '../shared/Section'

function FeeScheduleInfo ({ feeSchedule }) {
  if (!feeSchedule) {
    return null
  }

  return (
    <Section>
      <div><strong>{feeSchedule.longName}</strong></div>
    </Section>
  )
}

FeeScheduleInfo.propTypes = {
  feeSchedule: PropTypes.any
}

export default FeeScheduleInfo
