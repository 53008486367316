import PropTypes from 'prop-types'

export const labelsPropTypes = PropTypes.shape({
  chart: PropTypes.shape({
    blocksTitle: PropTypes.string,
    totalTitle: PropTypes.string,
    total: PropTypes.string,
    liabilitiesBlockTitle: PropTypes.string
  }),
  drillDown: PropTypes.shape({
    backButton: PropTypes.string
  }),
  details: PropTypes.shape({
    assets: PropTypes.string,
    assetsBlock: PropTypes.string,
    liabilities: PropTypes.string,
    other: PropTypes.string
  })
})
