import { useMemo } from 'react'
import concat from 'lodash/concat'
import dayjs from 'dayjs'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import utc from 'dayjs/plugin/utc'
import { useSearchPermissionGroups, useSearchRoles, useSearchUsers } from '../../../../api/users'
import { QUERY_KEYS } from '../../../../api/queryKeys'
import { ADMIN_ROUTES, USER_TYPE } from '../../../../constants'
import { ADVISOR, ROLES } from '../../../../policies/admin'
import { useCheckPolicy } from '../../../../hooks'
import { postNamedCommand } from '../../../../service'
import { cellTemplates, emailAccesor } from './helpers'

dayjs.extend(utc)

export const useUsersCSVData = ({ data, columns }) => {
  return useMemo(() => {
    if (!data) return []
    const labels = columns.map(({ Header }) => Header)
    const rows = data.map(({ firstName, lastName, email, roleName }) => {
      return [
        firstName || '',
        lastName || '',
        email || '',
        roleName || ''
      ]
    })
    return concat([labels], rows)
  }, [data, columns])
}

const getSearch = (searchText, tableMode) => {
  if (tableMode !== 'client') return searchText
  return ''
}

const textSearchAdvisorFields = ['firstName', 'lastName', 'email']
const textSearchRolesFields = ['name', 'description']

const getSortParams = (sort) => {
  if (sort && sort[0]) {
    return {
      sort: sort.map(({ id, desc }) => ({ field: id, dir: desc ? 'desc' : 'asc' }))
    }
  }
  return {}
}

const getSearchParams = (searchText, fields) => {
  if (!searchText) return {}
  return {
    textSearch: fields.reduce((prev, field) => {
      prev[field] = [{ op: 'contains', value: searchText }]
      return prev
    }, {})
  }
}

export const useSearchAdvisors = ({
  pageIndex,
  pageSize,
  sort,
  searchText,
  tableMode,
  isSummitUser
}) => {
  const search = useMemo(() => getSearch(searchText, tableMode), [searchText, tableMode])
  const query = useMemo(() => ({
    limit: pageSize,
    offset: pageIndex * pageSize,
    includes: {
      role: true
    },
    filters: {
      userTypeId: [{ op: 'eq', value: USER_TYPE.ADVISOR }],
      ...!isSummitUser ? { internal: [{ op: 'eq', value: 0 }] } : {}
    },
    ...getSortParams(sort),
    ...getSearchParams(search, textSearchAdvisorFields)
  }), [pageSize, pageIndex, sort, search, isSummitUser])

  const { data = {}, isFetching } = useSearchUsers(query)
  const { data: count = {}, isLoading: countLoading } = useSearchUsers({ ...query, resultType: 'total' })

  return {
    data: isFetching || countLoading ? [] : data,
    loading: isFetching,
    count: countLoading ? 0 : count.total
  }
}

export const useSearchRolesData = ({
  pageIndex,
  pageSize,
  sort,
  searchText,
  tableMode,
  assignableToUserType = USER_TYPE.ADVISOR
}) => {
  const search = useMemo(() => getSearch(searchText, tableMode), [searchText, tableMode])
  const query = useMemo(() => ({
    limit: pageSize,
    offset: pageIndex * pageSize,
    filters: {
      assignableToUserType: assignableToUserType
    },
    includes: {
      assignedUserCount: true
    },
    ...getSortParams(sort),
    ...getSearchParams(search, textSearchRolesFields)
  }), [pageSize, pageIndex, assignableToUserType, sort, search])

  const { data = {}, isLoading } = useSearchRoles(query)
  const { data: count = {}, isLoading: countLoading } = useSearchRoles({ ...query, resultType: 'total' })

  return {
    data: isLoading || countLoading ? [] : data,
    loading: isLoading || countLoading,
    count: isLoading || countLoading ? 0 : count.total
  }
}

export const useGetTotalUsersByRoleId = ({
  roleId,
  isSummitUser,
  userTypeId
}) => {
  const query = useMemo(() => ({
    resultType: 'total',
    includes: {
      role: true
    },
    filters: {
      roleId: [{ op: 'eq', value: Number(roleId) }],
      ...userTypeId ? { userTypeId: [{ op: 'eq', value: userTypeId }] } : {},
      ...!isSummitUser ? { internal: [{ op: 'eq', value: 0 }] } : {}
    }
  }), [roleId, isSummitUser, userTypeId])

  const { data = {}, isLoading } = useSearchUsers(query)

  return {
    data: isLoading ? undefined : data?.total,
    loading: isLoading
  }
}

export const linkTabOptions = {
  advisors: {
    key: 'advisors',
    label: 'Advisors',
    path: ADMIN_ROUTES.ADVISORS
  },
  roles: {
    key: 'roles',
    label: 'Roles',
    path: ADMIN_ROUTES.ROLES
  }
}

export const useTabsOptions = () => {
  const canViewAdvisors = useCheckPolicy(ADVISOR.viewAdvisor)
  const canViewRoles = useCheckPolicy(ROLES.viewAdvisorRoles)

  return useMemo(() => ({
    ...canViewAdvisors ? { advisors: linkTabOptions.advisors } : {},
    ...canViewRoles ? { roles: linkTabOptions.roles } : {}
  }), [canViewAdvisors, canViewRoles])
}

const nameAccessor = (row) => row.firstName

export const useAdvisorsColumnConfig = ({
  classes = {}
}) => {
  return useMemo(() => ({
    columns: [
      { Header: 'First Name', accessor: nameAccessor, id: 'firstName', Cell: cellTemplates.withAvatar({ accessor: nameAccessor, classes }) },
      { Header: 'Last Name', accessor: 'lastName', id: 'lastName' },
      { Header: 'Email', accessor: emailAccesor, id: 'email', Cell: cellTemplates.drilldownLink(emailAccesor) },
      { Header: 'Roles', accessor: 'role.name', id: 'roles' }
    ],
    defaultSort: [
      { id: 'firstName', desc: false }
    ]
  }), [classes])
}

export const usePermissionGroups = ({ assignableToUserType }) => {
  const query = useMemo(() => ({
    includes: {
      permissions: true
    },
    ...assignableToUserType
      ? {
        filters: {
          assignableToUserType
        }
      }
      : {}
  }), [assignableToUserType])

  const { data: permissionGroups = [], isLoading } = useSearchPermissionGroups(query)

  const permissionGroupsById = useMemo(() => {
    return permissionGroups.reduce((acc, permissionGroup) => {
      return {
        ...acc,
        [permissionGroup.permissionGroupId]: permissionGroup
      }
    }, {})
  }, [permissionGroups])

  return {
    data: isLoading ? {} : permissionGroupsById,
    isLoading
  }
}

export function useUpdateRole () {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (body) => {
      return postNamedCommand('users', 'updateRole', body)
    },
    onMutate: async (parameters) => {
      // Snapshot the previous value
      const prevRole = queryClient.getQueryData(
        [QUERY_KEYS.searchRole],
        { exact: false }
      )

      const newRole = {
        ...prevRole || {},
        name: parameters.name,
        description: parameters.description
      }

      // Modify cache to reflect this optimistic update
      queryClient.setQueryData([QUERY_KEYS.searchRole], newRole)

      // Return a snapshot so we can rollback in case of failure
      return { prevRole }
    },
    // If the mutation fails, use the context we returned above
    onError: (error, newAsset, { prevRole }) => {
      // Rollback the changes using the snapshot
      queryClient.setQueryData(
        [QUERY_KEYS.searchRole],
        prevRole
      )
      console.error(error, newAsset)
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.searchRole]
      })
    }
  })
}
