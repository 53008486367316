import React, { useCallback, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import WealthJourneyQueryLoader from '../../components/WealthJourneyQueryLoader'
import { useSetCurrentClient } from '../../../../../redux/slices/appContext'
import { filterOptions } from './filterOptions'
import { applyTemplate, cellTemplates, getColumnConfig } from './columnConfig'
import MilestoneDetailsHeader from './Header'
import MilestoneDetailTable from './Table'

const createTotalQuery = ({ filters }) => ({
  resultType: 'total',
  filters
})

const useDetailsPage = (columnConfig) => {
  const [selectedFilter, setSelectedFilter] = useState(filterOptions.next7)
  const onFilterSelected = useCallback((v) => setSelectedFilter(v), [setSelectedFilter])
  const totalsQueryProps = useMemo(() => ({
    query: {
      next7: createTotalQuery(filterOptions.next7.value),
      next30: createTotalQuery(filterOptions.next30.value),
      last30: createTotalQuery(filterOptions.last30.value)
    },
    options: {
      queryName: 'searchEntriesMultiple'
    },
    queryKey: 'milestone_details_totals'
  }), [])

  const detailQueryProps = useMemo(() => ({
    query: {
      take: 1000,
      includes: {
        client: true,
        category: true
      },
      ...selectedFilter.value
    },
    queryKey: `milestone_details_${selectedFilter.title}`
  }), [selectedFilter])

  const setCurrentClient = useSetCurrentClient()
  const { push } = useHistory()
  const onClientSelect = useCallback((client) => () => {
    if (client) {
      setCurrentClient(client.clientId)
      push('/')
    } else {
      console.log('no client was passed to the link callback')
    }
  }, [setCurrentClient, push])

  const tableProps = useMemo(() => {
    const columns = getColumnConfig(columnConfig)
    const columnsWithTemplates = applyTemplate(columns, 'clientLink', cellTemplates.link(onClientSelect, (client) => client.longName))
    return {
      columns: columnsWithTemplates
    }
  }, [columnConfig, onClientSelect])

  return {
    headerProps: {
      selectedFilter,
      onFilterSelected
    },
    totalsQueryProps,
    detailQueryProps,
    tableProps
  }
}

const MilestonesDetails = ({ columnConfig }) => {
  const {
    headerProps,
    totalsQueryProps,
    detailQueryProps,
    tableProps
  } = useDetailsPage()
  return (
    <div>
      <WealthJourneyQueryLoader noWait {...totalsQueryProps}>
        {(totals) => <MilestoneDetailsHeader totals={totals} {...headerProps} />}
      </WealthJourneyQueryLoader>
      <div>
        <WealthJourneyQueryLoader {...detailQueryProps}>
          {({ data }) => (
            <MilestoneDetailTable data={data} {...tableProps} />
          )}
        </WealthJourneyQueryLoader>
      </div>
    </div>
  )
}

MilestonesDetails.propTypes = {
  columnConfig: PropTypes.oneOfType([PropTypes.string])
}

MilestonesDetails.defaultProps = {
  columnConfig: 'default'
}

export default MilestonesDetails
