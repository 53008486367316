import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { useSelectFilterContext } from '../SelectFilterProvider'
import MuiPickersUtcUtilsProvider from '../../../../providers/MuiPickersUtcUtilsProvider'
import DateTimePicker from '../../../atoms/DateTimePicker'
import { disableWeekends } from '../../../../utils'
import Icon from '../../../atoms/Icon'
import { DATE_FORMAT_US_LONG, ICON_NAMES } from '../../../../constants'
import FilterButton from './FilterButton'
import FilterContainer from './FilterContainer'

dayjs.extend(utc)

const useStyles = makeStyles(() => ({
  content: {
    minWidth: '0',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '0.5rem'
  },
  datePickerContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    cursor: 'pointer',
    borderRadius: '4px',
    border: '2px solid #EEF0F8',
    background: '#FFF',
    fontSize: '0.875rem',
    fontWeight: 'bold',
    '& svg': {
      marginLeft: 'auto'
    },
    '& span:first-child': {
      minWidth: '5rem',
      padding: '1rem'
    }
  },
  label: {
    color: '#141929',
    lineHeight: '1rem',
    paddingBottom: '0',
    fontWeight: 'bold',
    fontSize: '1rem !important'
  },
  input: {
    padding: '13px 14px 13px 14px',
    textAlign: 'left',
    cursor: 'pointer',
    zIndex: 1,
    '& input': {
      padding: '0',
      textAlign: 'left',
      cursor: 'pointer',
      fontSize: '0.875rem',
      fontWeight: 'bold'
    }
  },
  icon: {
    zIndex: 0,
    position: 'absolute',
    inset: '0.65rem 0.5rem 0.5rem'
  },
  buttonGroup: {
    gap: '0.5rem',
    display: 'flex',
    flexDirection: 'row',
    width: '100%'
  }
}))

const DateFilter = ({
  format,
  defaultValue,
  disabledDateRange
}) => {
  const classes = useStyles()
  const { onClearFilters, onApplyFilters } =
    useSelectFilterContext()

  const [value, setValue] = useState(defaultValue)

  const shouldDisableDate = useCallback((date) => disableWeekends(date), [])

  const applyValue = useCallback(() => {
    if (value) {
      const dValue = dayjs.utc(dayjs(value).format('YYYY-MM-DD'))
      onApplyFilters({
        displayLabel: dValue.format(format),
        // This value gets transformed further down in ReportResultsTable.jsx
        value: dValue,
        optionValue: '='
      })
    }
  }, [onApplyFilters, value, format])

  const inputProps = {
    classes: {
      root: classes.input
    }
  }

  return (
    <FilterContainer>
      <div className={classes.content}>
        <div className={classes.datePickerContainer}>
          <MuiPickersUtcUtilsProvider>
            <DateTimePicker
              format={format}
              disableFuture
              value={value}
              defaultValue={value || null}
              placeholder={format}
              onChange={setValue}
              inputProps={inputProps}
              className={classes.datePicker}
              disabledDateRange={disabledDateRange}
              shouldDisableDate={shouldDisableDate}
              inputClassName={classes.label}
            />
          </MuiPickersUtcUtilsProvider>
          <Icon
            additionalClasses={classes.icon}
            name={ICON_NAMES.calendar}
            customSize='1.25rem'
          />
        </div>
        <div className={classes.buttonGroup}>
          <FilterButton onClick={onClearFilters} color='#D44333'>
            Clear Date
          </FilterButton>
          <FilterButton onClick={applyValue}>Apply</FilterButton>
        </div>
      </div>
    </FilterContainer>
  )
}

DateFilter.propTypes = {
  format: PropTypes.string,
  defaultValue: PropTypes.string,
  disabledDateRange: PropTypes.shape({
    min: PropTypes.string,
    max: PropTypes.string
  })
}

DateFilter.defaultProps = {
  format: DATE_FORMAT_US_LONG,
  defaultValue: undefined,
  disabledDateRange: undefined
}

export default DateFilter
