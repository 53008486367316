import { useRef, useCallback, useEffect } from 'react'

function useAbortController () {
  const abortControllerRef = useRef()

  const getAbortController = useCallback(() => {
    if (!abortControllerRef.current) {
      abortControllerRef.current = new AbortController()
    }
    return abortControllerRef.current
  }, [abortControllerRef])

  useEffect(() => {
    return () => getAbortController().abort()
  }, [getAbortController])

  const getSignal = useCallback(() => getAbortController().signal, [
    getAbortController
  ])

  return getSignal
}

export default useAbortController
