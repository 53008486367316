import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { parseUrlSearchParams } from '../../../../utils/parseUrlSearchParams'
import { ContextContainerProvider } from '../../../../abundanceEngine/components/ContextContainer'
import { getExposureColumnConfig, useColumns } from './columnConfig'
import { EXPOSURE_REPORT_CONTEXT_KEY } from './helpers'
import ReportResultsContainer from './ReportResultContainer'

const useReportResult = ({ columnConfig: _columnConfig, useSymbolInsteadOfZero, showCustodianFilter }) => {
  const history = useHistory()
  const params = useMemo(() => {
    return parseUrlSearchParams(history)
  }, [history])

  const reportParams = useMemo(
    () => {
      return {
        exposureType: params?.exposureType?.[0] || 'asset',
        exposureTarget: params?.exposureTarget?.[0] || null,
        levelType: params?.levelType?.[0] || 'client',
        startDate: params?.startDate?.[0] || null,
        endDate: params?.endDate?.[0] || null,
        dateRangePreset: params?.dateRangePreset?.[0] || null,
        dataSet: params?.dataSet?.[0] || null,
        minDailyStartDate: params?.minDailyStartDate?.[0] || null,
        includeTags: params?.includeTags?.[0] || false
      }
    },
    [params]
  )
  const columnConfig = _columnConfig || getExposureColumnConfig(
    reportParams.dataSet,
    useSymbolInsteadOfZero
  )
  const { columns, defaultSort } = useColumns(reportParams.levelType, columnConfig)
  return {
    reportParams,
    columns,
    defaultSort,
    showCustodianFilter
  }
}

const ReportResults = ({ columnConfig, useSymbolInsteadOfZero, showCustodianFilter }) => {
  const reportResult = useReportResult({ columnConfig, useSymbolInsteadOfZero, showCustodianFilter })

  const contextState = useMemo(
    () => ({
      name: EXPOSURE_REPORT_CONTEXT_KEY,
      initialState: { ...reportResult.reportParams }
    }),
    [reportResult.reportParams]
  )

  return (
    <ContextContainerProvider context={contextState}>
      <ReportResultsContainer {...reportResult} />
    </ContextContainerProvider>
  )
}

ReportResults.propTypes = {
  columnConfig: PropTypes.object,
  useSymbolInsteadOfZero: PropTypes.string,
  showCustodianFilter: PropTypes.bool
}

export default ReportResults
