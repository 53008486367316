import React from 'react'
import PropTypes from 'prop-types'
import { Box, makeStyles } from '@material-ui/core'
import { isEmpty } from 'lodash'
import SearchBarInput from '../../../../../molecules/SearchBar/SearchBarInput'
import GroupingPicker from '../../../../../organisms/GroupingProvider/GroupingPicker'
import GroupTagFiltersSkeleton from '../../../../../molecules/GroupTagFilters/GroupTagFiltersSkeleton'
import { useAccountsContext } from '../AccountsContext'
import { DYNAMIC_FILTER_PREFIX } from '../useGroupTypeColumns'
import GroupTypeFilters from './GroupTypeFilters'
import StaticFilters from './StaticFilters'

const useStyles = makeStyles(() => ({
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  content: {
    display: 'flex'
  },
  filters: {
    display: 'flex',
    alignItems: 'center',
    padding: '0.75rem 0'
  },
  searchInput: {
    marginBottom: '0.5rem'
  }
}))

const AccountsHeader = ({ tableOptions }) => {
  const classes = useStyles()

  const {
    isLoading,
    searchText,
    visibleFilters,
    isLoadingGroupTypes,
    operationalTable: { onSearchTextChange }
  } = useAccountsContext()

  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <Box width='20rem'>
          <SearchBarInput
            value={searchText}
            loading={isLoading}
            setValue={onSearchTextChange}
            searchInputClassName={classes.searchInput}
            placeholder='Search by account'
          />
        </Box>
        <Box ml='auto'>
          <GroupingPicker options={tableOptions} />
        </Box>
      </div>
      {!isEmpty(
        visibleFilters.filter((filter) =>
          filter.startsWith(DYNAMIC_FILTER_PREFIX)
        )
      ) && (
        <div className={classes.filters}>
          {isLoadingGroupTypes ? (
            <GroupTagFiltersSkeleton />
          ) : (
            <>
              <StaticFilters />
              <GroupTypeFilters />
            </>
          )}
        </div>
      )}
    </div>
  )
}

AccountsHeader.propTypes = {
  tableOptions: PropTypes.object
}

AccountsHeader.defaultProps = {
  tableOptions: {
    hideGroupingsUnderline: true,
    hideCustomizeColumnsDateRangeSelectors: true,
    hideCustomizeColumnsColumnHiding: false,
    hideCustomizeColumnsButton: false,
    hideExpandCollapseButton: true,
    hideExportButton: false,
    hideExportTypePicker: true
  }
}

export default AccountsHeader
