import React from 'react'
import PropTypes from 'prop-types'
import { Route, Switch } from 'react-router-dom'
import PublicLayout from '../../molecules/PublicLayout'
import { PASSWORD_ROUTES } from '../../../constants'
import ForgotPasswordView from './ForgotPasswordView'
import { usePublicStyles } from './usePublicStyles'
import LoginView from './LoginView'
import ActivationView from './ActivationView'
import { useFirmInfo } from './hooks'
import SplashAlert from './SplashAlert'

function LoginContainer ({ loginError }) {
  const classes = usePublicStyles()
  const firmInfo = useFirmInfo()
  if (!firmInfo) {
    return null
  }

  return (
    <PublicLayout className={classes.layout} backgroundPath={firmInfo?.backgroundPath} splash={<SplashAlert />}>
      <Switch>
        <Route path='/login'>
          <LoginView loginError={loginError} />
        </Route>
        <Route path={PASSWORD_ROUTES.FORGOT}>
          <ForgotPasswordView />
        </Route>
        <Route path={PASSWORD_ROUTES.ACTIVATION}>
          <ActivationView />
        </Route>
        <Route path={PASSWORD_ROUTES.RECOVER}>
          <ActivationView />
        </Route>
        <Route path={PASSWORD_ROUTES.RESET_USER}>
          <ActivationView />
        </Route>
        <Route path='/'>
          <LoginView loginError={loginError} />
        </Route>
      </Switch>
    </PublicLayout>
  )
}

LoginContainer.propTypes = {
  loginError: PropTypes.bool
}

LoginContainer.defaultProps = {
  loginError: false
}

export default LoginContainer
