import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    margin: '0 1rem',
    padding: '20px 18px',
    borderBottom: `1px solid ${theme.palette.gray.main}`
  }
}))

const ThreadHeader = ({ conversation }) => {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      {conversation.name}
    </div>
  )
}

ThreadHeader.propTypes = {
  conversation: PropTypes.shape({
    name: PropTypes.string
  })
}

export default ThreadHeader
