import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useParams } from 'react-router-dom'
import { useIngestionTargetById } from '../../../../api/ingestion'
import TargetMappingTable from '../components/TargetMappingTable'
import PublishingPolicyItem from './PublishingPolicyItem'

const useStyles = makeStyles((theme) => ({
  targetDetailsIndex: {
    padding: '1rem',
    '& > header': {
      borderBottom: '1px solid black'
    },
    '& .__description': {
      padding: '.5rem 0',
      color: theme.palette.darkGrey
    }
  },
  columnsAndPublishing: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    '& .__left, & .__right': {
      flex: '1 0',
      flexShrink: 0,
      width: '50%',
      paddingRight: '1rem'
    },
    '& .__sticky': {
      position: 'sticky',
      top: 0
    }
  }
}))

const TargetDetailsIndex = () => {
  const classes = useStyles()
  const { targetId } = useParams()
  const { data: target, isLoading } = useIngestionTargetById(targetId)

  if (isLoading) {
    return (
      <div className={classes.targetDetailsIndex}>Loading...</div>
    )
  }

  return (
    <div className={classes.targetDetailsIndex}>
      <header>
        <h2>
          <span>{target.name}</span>
        </h2>
        <div className='__description'>
          <span>{target.schema}</span>
          <span>.</span>
          <span>{target.table}</span>
        </div>
      </header>
      <main className={classes.columnsAndPublishing}>
        <div className='__left'>
          <h3>Target Column Config</h3>
          <div className='__sticky'>
            <TargetMappingTable target={target} />
          </div>
        </div>
        <div className='__right'>
          <h3>Publishing</h3>
          {target.policies.map(pol => (
            <PublishingPolicyItem policy={pol} key={pol.publishingPolicyId} />
          ))}
        </div>
      </main>
    </div>
  )
}

export default TargetDetailsIndex
