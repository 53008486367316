import React from 'react'

const {
  NODE_ENV,
  REACT_APP_USE_WHY_DID_YOU_RENDER = false
} = process.env

if (NODE_ENV === 'development' && REACT_APP_USE_WHY_DID_YOU_RENDER) {
  const whyDidYouRender = require('@welldone-software/why-did-you-render')
  whyDidYouRender(React, {
    trackAllPureComponents: true
  })
}
