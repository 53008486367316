import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer
} from 'react'
import PropTypes from 'prop-types'

import isEmpty from 'lodash/isEmpty'

import useAbortController from '../../hooks/useAbortController'
import { useAppContext } from '../../redux/slices/appContext'
import {
  useNoteContext,
  useOnAddReplyOrThread,
  useOnRemoveReply,
  useOnRemoveThread,
  useSetNoteContext
} from '../../redux/slices/noteContext'
import {
  editThread,
  fetchClient,
  fetchClients,
  fetchReplies,
  fetchThreads,
  getClientsThreadsOverview,
  getNotificationsByClients
} from '../../service'
import { removeEmptyProps } from '../../utils'
import { useMainSearchViewPath } from '../../redux/slices/appConfig'
import { NOTE_TRIGGERED_BY } from '../../constants'
import { ACTIONS, reducer } from './reducer'
import { mapClientsThreadsOverview } from './helper'

const NoteThreadContext = createContext()

export const activeThreadInitialState = { threadId: null }
export const activeClientThreadsOverviewInitialState = { clientId: null }

export const noteThreadContextInitialState = {
  clientsThreadsOverview: [],
  clientsThreadOverviewEmpty: false,
  replies: [],
  threads: [],
  threadsEmpty: false,
  isLoading: false,
  isNoteEmpty: false,
  activeClientThreadsOverview: { ...activeClientThreadsOverviewInitialState },
  activeThread: { ...activeThreadInitialState },
  showOnlyActiveThreads: false
}

function NoteThreadContextProvider ({
  children,
  showCommentsGroupedByClient,
  showCurrentClientThreadsByDefault
}) {
  const [state, dispatch] = useReducer(reducer, noteThreadContextInitialState)
  const onRemoveReply = useOnRemoveReply()
  const setNoteContext = useSetNoteContext()
  const onRemoveThread = useOnRemoveThread()
  const abortController = useAbortController()
  const onAddReplyOrThread = useOnAddReplyOrThread()
  const mainSearchViewPath = useMainSearchViewPath()
  const {
    userId,
    clientId: appContextClientId,
    isNotesSideNavOpen,
    isFullViewOpen
  } = useAppContext()
  const {
    viewId,
    filters,
    sectionId,
    reloadThreads,
    clientId: noteContextClientId
  } = useNoteContext()

  const clientId = useMemo(
    () => noteContextClientId || appContextClientId,
    [appContextClientId, noteContextClientId]
  )

  const showOnlyActiveThreads = useMemo(
    () => state.showOnlyActiveThreads,
    [state.showOnlyActiveThreads]
  )

  const threads = useMemo(() => state.threads, [state.threads])
  const replies = useMemo(() => state.replies, [state.replies])
  const clientsThreadOverviewEmpty = useMemo(
    () => state.clientsThreadOverviewEmpty,
    [state.clientsThreadOverviewEmpty]
  )

  const activeThread = useMemo(() => state.activeThread, [state.activeThread])
  const activeClientThreadsOverview = useMemo(
    () => state.activeClientThreadsOverview,
    [state.activeClientThreadsOverview]
  )

  useEffect(() => {
    if (!state.isLoading) {
      dispatch({ type: ACTIONS.TOGGLE_NOTE_EMPTY, payload: isEmpty(threads) })
    }
  }, [threads, state.isLoading])

  useEffect(() => {
    if (!showOnlyActiveThreads && isFullViewOpen) {
      if (activeThread.threadId) {
        setNoteContext({
          viewId: activeThread.viewId,
          sectionId: activeThread.sectionId
        })
      } else if (viewId) {
        setNoteContext({
          viewId
        })
      } else {
        setNoteContext({ sectionId: undefined })
      }
    }
  }, [
    viewId,
    activeThread,
    isFullViewOpen,
    setNoteContext,
    showOnlyActiveThreads
  ])

  const updateThreadById = useCallback(
    (threadId, body) =>
      dispatch({
        type: ACTIONS.UPDATE_THREAD_BY_ID,
        payload: { threadId, body }
      }),
    []
  )

  const updateThreadReplyById = useCallback(
    (replyId, body) =>
      dispatch({
        type: ACTIONS.UPDATE_THREAD_REPLY_BY_ID,
        payload: { replyId, body }
      }),
    []
  )

  const fetchCallback = useCallback(async (responseProp, func, ...args) => {
    try {
      dispatch({ type: ACTIONS.SET_IS_LOADING })
      return await func.apply(null, args)
    } catch (err) {
      console.error(err)
      dispatch({ type: ACTIONS.SET_IS_LOADING, payload: false })
      return { data: { [responseProp]: undefined } }
    }
  }, [])

  const fetchThreadsCallback = useCallback(
    async (searchParams, signal) => {
      const response = await fetchCallback(
        'rows',
        fetchThreads,
        { signal },
        searchParams
      )
      const {
        data: { rows: threads }
      } = response
      return threads
    },
    [fetchCallback]
  )

  const fetchRepliesCallback = useCallback(
    async (threadId, signal) => {
      const {
        data: { rows: replies }
      } = await fetchCallback('rows', fetchReplies, threadId, {
        signal
      })
      return replies
    },
    [fetchCallback]
  )

  const onAddReplyHandler = useCallback(
    (threadId, reply, thread) => {
      if (threadId !== null) {
        dispatch({ type: ACTIONS.ON_ADD_REPLY, payload: { threadId, reply } })
      } else {
        dispatch({ type: ACTIONS.SET_THREADS, payload: { threads: [thread] } })
      }
      onAddReplyOrThread({
        sectionId,
        newThread: !!thread,
        openModal: showOnlyActiveThreads,
        threadReplies: !activeThread.resolved
          ? undefined
          : activeThread.replies + 1
      })
    },
    [
      sectionId,
      onAddReplyOrThread,
      showOnlyActiveThreads,
      activeThread.resolved,
      activeThread.replies
    ]
  )

  const onRemoveThreadHandler = useCallback(
    (id) => {
      const threadId = id || activeThread.threadId
      onRemoveThread({ sectionId, replies: activeThread.replies })
      dispatch({ type: ACTIONS.ON_REMOVE_THREAD, payload: threadId })
    },
    [activeThread.threadId, activeThread.replies, onRemoveThread, sectionId]
  )

  const onRemoveReplyHandler = useCallback(
    (threadId, replyId) => {
      if (sectionId) onRemoveReply({ sectionId })
      dispatch({
        type: ACTIONS.ON_REMOVE_REPLY,
        payload: { threadId, replyId }
      })
    },
    [onRemoveReply, sectionId]
  )

  const resolveThreadCallback = useCallback(async (isThreadResolved = true) => {
    const { data: archivedThread } = await fetchCallback(
      'data',
      editThread,
      activeThread.threadId,
      {
        ...activeThread,
        resolved: isThreadResolved
      }
    )
    dispatch({
      type: ACTIONS.ON_RESOLVE_THREAD,
      payload: { archivedThread, isLoading: false }
    })
    if (threads.length === 1) {
      setNoteContext({ openModal: false, openNewCommentModal: true })
    }
    onRemoveThread({ sectionId, replies })
  }, [
    replies,
    threads,
    sectionId,
    activeThread,
    fetchCallback,
    setNoteContext,
    onRemoveThread
  ])

  useEffect(() => {
    if (activeThread.threadId) {
      dispatch({
        type: ACTIONS.FIND_AND_SET_LAST_ACTIVE_THREAD,
        payload: activeThread.threadId
      })
    }
  }, [threads, showOnlyActiveThreads, activeThread.threadId])

  const getThreadsHandler = useCallback(async (threadParams) => {
    const { viewId: _viewId } = threadParams || {}
    const signal = abortController()

    let viewIdParam = null
    if (!isEmpty(filters)) {
      const [noteFilter] = filters
      viewIdParam = noteFilter.id
    } else if (_viewId !== undefined) {
      viewIdParam = _viewId
    } else if ((viewId !== mainSearchViewPath) && isNotesSideNavOpen) {
      viewIdParam = _viewId || viewId
    }

    const activeClientId = clientId || activeClientThreadsOverview.clientId

    const searchParams = removeEmptyProps({
      viewId: viewIdParam,
      resolved: isNotesSideNavOpen ? null : false,
      clientId: activeClientId,
      sectionId,
      userId
    })

    if (signal.aborted) return

    const [threads, { data: client }] = await Promise.all([
      fetchThreadsCallback(searchParams, signal),
      fetchClient(activeClientId)
    ])
    dispatch({
      type: ACTIONS.SET_THREADS,
      payload: {
        client,
        threads,
        isLoading: false,
        showOnlyActiveThreads: !isNotesSideNavOpen
      }
    })
  }, [
    viewId,
    userId,
    filters,
    clientId,
    sectionId,
    abortController,
    mainSearchViewPath,
    isNotesSideNavOpen,
    fetchThreadsCallback,
    activeClientThreadsOverview.clientId
  ])

  const getClientThreadsOverview = useCallback(async () => {
    const signal = abortController()
    const {
      data: { rows: clientsThreadsOverview }
    } = await fetchCallback('rows', getClientsThreadsOverview, {
      signal
    })

    if (!isEmpty(clientsThreadsOverview)) {
      const clientIds = clientsThreadsOverview.map(({ clientId }) => clientId)
      const { data: clients } = await fetchClients({ clientIds: [...new Set([...clientIds, clientId])] })
      const { data: clientsNotifications } = await getNotificationsByClients(
        userId,
        { signal },
        { clientIds }
      )
      const clientsThreadsOverviewHydrated = mapClientsThreadsOverview(
        clientsThreadsOverview,
        clientsNotifications,
        clients
      )

      if (showCurrentClientThreadsByDefault || noteContextClientId) {
        dispatch({
          type: ACTIONS.SET_ACTIVE_CLIENT_OVERVIEW_BY_CLIENT_ID,
          payload: {
            isLoading: false,
            clientsThreadsOverview: clientsThreadsOverviewHydrated
          }
        })
      } else {
        dispatch({
          type: ACTIONS.SET_CLIENTS_OVERVIEW,
          payload: {
            isLoading: false,
            clientsThreadsOverview: clientsThreadsOverviewHydrated
          }
        })
      }
    } else {
      dispatch({
        type: ACTIONS.SET_CLIENTS_OVERVIEW,
        payload: {
          isLoading: false,
          clientsThreadsOverview: [],
          clientsThreadOverviewEmpty: true
        }
      })
    }
  }, [
    userId,
    clientId,
    fetchCallback,
    abortController,
    noteContextClientId,
    showCurrentClientThreadsByDefault
  ])

  const onAddReaction = useCallback(({ replyId, threadId, reaction }) => {
    if (replyId) {
      dispatch({
        type: ACTIONS.ON_ADD_REPLY_REACTION,
        payload: { replyId, reaction }
      })
    } else if (threadId) {
      dispatch({
        type: ACTIONS.ON_ADD_THREAD_REACTION,
        payload: { threadId, reaction }
      })
    }
  }, [])

  const onRemoveReaction = useCallback(({ replyId, threadId, reactionId }) => {
    if (replyId) {
      dispatch({
        type: ACTIONS.ON_REMOVE_REPLY_REACTION,
        payload: { replyId, reactionId }
      })
    } else if (threadId) {
      dispatch({
        type: ACTIONS.ON_REMOVE_THREAD_REACTION,
        payload: { threadId, reactionId }
      })
    }
  }, [])

  const setActiveThread = useCallback(
    (thread) => dispatch({ type: ACTIONS.SET_ACTIVE_THREAD, payload: thread }),
    []
  )

  const setActiveClientThreadsOverview = useCallback(
    (clientThreadsOverview) =>
      dispatch({
        type: ACTIONS.SET_ACTIVE_CLIENT_OVERVIEW,
        payload: { clientThreadsOverview }
      }),
    []
  )

  const removeThreadNotification = useCallback((clientId, threadId, notificationIds) => {
    dispatch({
      type: ACTIONS.REMOVE_THREAD_NOTIFICATION,
      payload: { clientId, threadId, notificationIds }
    })
  }, [])

  useEffect(() => {
    if (reloadThreads) {
      getThreadsHandler()
      setNoteContext({
        reloadThreads: false,
        triggeredBy: isNotesSideNavOpen ? NOTE_TRIGGERED_BY.BOARD : null
      })
    }
  }, [isNotesSideNavOpen, reloadThreads, setNoteContext, getThreadsHandler])

  useEffect(() => {
    if (!activeThread.threadId) return
    const signal = abortController()
    async function getReplies () {
      const threadId = activeThread.threadId
      const replies = await fetchRepliesCallback(threadId, signal)
      dispatch({
        type: ACTIONS.SET_REPLIES,
        payload: { replies, isLoading: false }
      })
    }
    getReplies()
  }, [
    abortController,
    fetchRepliesCallback,
    activeThread.noteId,
    activeThread.threadId
  ])

  const clear = useCallback(() => dispatch({ type: ACTIONS.CLEAR }), [])

  const value = useMemo(
    () => ({
      ...state,
      clearNotesContext: clear,
      updateThreadById,
      updateThreadReplyById,
      fetchThreadsCallback,
      fetchRepliesCallback,
      setActiveThread,
      onAddReaction,
      onRemoveReaction,
      removeThreadNotification,
      clientsThreadOverviewEmpty,
      showCommentsGroupedByClient,
      setActiveClientThreadsOverview,
      onAddReply: onAddReplyHandler,
      loadThreads: getThreadsHandler,
      onRemoveReply: onRemoveReplyHandler,
      onRemoveThread: onRemoveThreadHandler,
      onResolveThreadHandler: resolveThreadCallback,
      loadClientThreadsOverview: getClientThreadsOverview
    }),
    [
      state,
      clear,
      updateThreadById,
      updateThreadReplyById,
      fetchThreadsCallback,
      fetchRepliesCallback,
      getThreadsHandler,
      setActiveThread,
      onAddReaction,
      onRemoveReaction,
      onAddReplyHandler,
      onRemoveReplyHandler,
      onRemoveThreadHandler,
      resolveThreadCallback,
      showCommentsGroupedByClient,
      setActiveClientThreadsOverview,
      removeThreadNotification,
      getClientThreadsOverview,
      clientsThreadOverviewEmpty
    ]
  )

  return (
    <NoteThreadContext.Provider value={value}>
      {children}
    </NoteThreadContext.Provider>
  )
}

function useNoteThreadContext () {
  const context = useContext(NoteThreadContext)
  if (context === undefined) {
    throw new Error(
      'useNoteContext must be used within a NoteThreadContextProvider component'
    )
  }
  return context
}

NoteThreadContextProvider.propTypes = {
  children: PropTypes.node,
  showCommentsGroupedByClient: PropTypes.bool,
  showCurrentClientThreadsByDefault: PropTypes.bool
}

NoteThreadContextProvider.defaultProps = {
  children: null,
  showCommentsGroupedByClient: false,
  showCurrentClientThreadsByDefault: false
}

export { useNoteThreadContext, NoteThreadContextProvider }
