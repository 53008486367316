import React, { useCallback } from 'react'
import { Radio } from '@material-ui/core'
import dayjs from 'dayjs'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'

const formatAmountText = (rate, goal) => {
  const rateText = Math.abs(rate.rateValue) / 1000
  let newText = rateText
  if (newText > 1000) {
    newText = newText / 1000 + 'M'
  } else {
    newText = newText + 'K'
  }
  if (goal.rate.rateType === 'OneTime') {
    // newText = newText
  } else {
    newText = newText + '/yr'
  }

  return newText
}

const formatSubTitle = (goal) => {
  const startDate = goal.startDate
  const endDate = goal.endDate
  let subTitleText = ''
  if (startDate && dayjs(startDate)) {
    subTitleText = dayjs(startDate).year()
  }
  if (endDate && dayjs(endDate)) {
    if (dayjs(endDate).diff(startDate, 'day') > 0) {
      subTitleText = subTitleText + '-' + dayjs(endDate).year()
    }
  } else {
    subTitleText = 'From ' + subTitleText + ' on'
  }
  return subTitleText
}

const PlanGoal = ({ goal, goalEditHandler, goalStatusHandler }) => {
  const rate = goal.rate
  let rateColor = '#000000'
  if (rate.rateValue > 0) {
    rateColor = '#3AA76D'
  }
  if (goal.status !== 'on') {
    rateColor = '#c9ccda'
  }

  const handleOnClick = useCallback(() => {
    goalEditHandler(goal)
  }, [goal, goalEditHandler])

  // const getStatusImage = (goal) => {
  //   let statusImage = 'onEye.png'
  //   if (goal.status !== 'on') {
  //     statusImage = 'offEye.png'
  //   }
  //   return statusImage
  // }

  return (
    <div
      className={goal.status === 'on' ? '' : 'disabledText'}
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        maxWidth: '340px',
        margin: '4px 0px'
      }}
    >
      <div
        onClick={() => goalStatusHandler(goal)}
        style={{
          fontSize: '14px',
          padding: '0px',
          width: '48px',
          alignSelf: 'center'
        }}
      >
        <Radio
          checked={goal.status === 'on'}
          onChange={noop}
          value={goal.goalId}
          name='radio-buttons'
          inputProps={{ 'aria-label': goal.goalId }}
          color='default'
        />
      </div>
      <div
        style={{
          fontSize: '14px',
          padding: '4px',
          flexGrow: '4',
          lineHeight: '1.4'
        }}
      >
        <div onClick={handleOnClick} style={{ cursor: 'pointer' }}>
          {goal.goalName}
          <div style={{ fontSize: '12px', fontWeight: '400' }}>
            {formatSubTitle(goal)}
          </div>
        </div>
      </div>
      <div
        onClick={handleOnClick}
        style={{
          fontSize: '16px',
          padding: '4px',
          alignSelf: 'center',
          color: `${rateColor}`,
          cursor: 'pointer'
        }}
      >
        ${formatAmountText(rate, goal)}
      </div>
      {/* <div>
        <br />
        {JSON.stringify(goal)}
      </div> */}
    </div>
  )
}

PlanGoal.propTypes = {
  goal: PropTypes.shape({
    goalId: PropTypes.string,
    edit: PropTypes.string,
    status: PropTypes.string,
    rate: PropTypes.shape({
      rateValue: PropTypes.string
    }),
    goalName: PropTypes.string
  }),
  goalStatusHandler: PropTypes.func,
  goalEditHandler: PropTypes.func
}

PlanGoal.defaultProps = {
  goal: {},
  goalStatusHandler: noop,
  goalEditHandler: noop
}

export default PlanGoal
