import React from 'react'
import PropTypes from 'prop-types'
import { IconButton, makeStyles } from '@material-ui/core'
import { noop } from 'lodash'
import Icon from '../../../../atoms/Icon'
import { ICON_NAMES } from '../../../../../constants'
import Text from '../../../../atoms/Text'
import { useAppContext } from '../../../../../redux/slices/appContext'
import ClientValuesForm from './ClientValuesForm'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '0.5rem',
    width: '100%',
    borderRadius: '0.875rem',
    border: '2px dashed #F1F2F3',
    background: '#FFF',
    cursor: 'pointer',
    transition: 'border .3s ease',
    '&:hover': {
      borderColor: theme.palette.darkBlue
    },
    userSelect: 'none',
    padding: '1.5rem'
  },
  button: {
    backgroundColor: theme.palette.darkBlue,
    color: 'white',
    marginBottom: '0.75rem',
    '&:hover': {
      backgroundColor: theme.palette.darkBlue
    }
  }
}))

const AddClientValueCard = ({
  title,
  onSave,
  showForm,
  description,
  showFormToggle
}) => {
  const classes = useStyles()
  const { clientId } = useAppContext()

  if (showForm) {
    return (
      <div className={classes.container}>
        <ClientValuesForm
          defaultValues={{
            levelId: clientId,
            levelType: 'clients'
          }}
          onSave={onSave}
          onCancel={showFormToggle?.off}
        />
      </div>
    )
  }

  return (
    <div className={classes.container} onClick={showFormToggle?.on}>
      <IconButton className={classes.button}>
        <Icon name={ICON_NAMES.add} customSize='2.5rem' />
      </IconButton>
      <Text customFontSize='1.25rem' customFontWeight='bold'>
        {title}
      </Text>
      {description && <Text customFontSize='0.875rem'>{description}</Text>}
    </div>
  )
}

AddClientValueCard.propTypes = {
  showForm: PropTypes.bool,
  title: PropTypes.string,
  onSave: PropTypes.func,
  description: PropTypes.string,
  showFormToggle: PropTypes.object
}

AddClientValueCard.defaultProps = {
  showForm: false,
  title: 'Add to Your Values',
  description: '',
  onSave: noop,
  showFormToggle: {}
}

export default AddClientValueCard
