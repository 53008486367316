import React, { useCallback, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import PropTypes from 'prop-types'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import { IconButton, makeStyles } from '@material-ui/core'
import SydButton from '../../../../../commonDesign/Button'
import { useModifyEntry } from '../../../../../../api/wealthJourney'
import SydModalActions from '../../../../../commonDesign/SydModal/SydModalActions'
import { useWealthJourney } from '../../WealthJourneyProvider'
import Loading from '../../../../../molecules/Loading'
import SydInput from '../../../../../commonDesign/SydInput'
import { ICON_NAMES } from '../../../../../../constants'
import Icon from '../../../../../atoms/Icon'
import Switch from '../../../../../molecules/Switch'

const useStyles = makeStyles(theme => ({
  taskGrid: {
    display: 'grid',
    gridTemplateColumns: 'auto 1fr auto',
    rowGap: theme.layout.gap.g10,
    columnGap: theme.layout.gap.g10,
    alignItems: 'center'
  }
}))

const defaultTask = () => ({
  description: '',
  status: 'incomplete'
})

const useSubmitter = (form, onComplete, entry) => {
  const { handleSubmit } = form
  const [processing, setProcessing] = useState(false)
  const [error, setError] = useState(null)
  const { mutateAsync: modifyEntry } = useModifyEntry()
  const onSubmit = useCallback(async (formData) => {
    const command = {
      ...entry,
      entryJson: {
        ...(entry.entryJson || {}),
        tasks: formData.tasks
      }
    }

    try {
      setError(null)
      setProcessing(true)
      const result = await modifyEntry(command)
      if (result?.statusCode === 500) {
        throw new Error('Failed to modify entry')
      }
      onComplete(result)
    } catch (err) {
      setError(err?.toString())
    } finally {
      setProcessing(false)
    }
  }, [modifyEntry, setProcessing, onComplete, setError, entry])

  const submitter = useCallback(async (e) => {
    const onValid = async (form) => {
      await onSubmit(form)
    }
    const onInvalid = (errors) => {
      console.error(errors)
    }

    const handler = handleSubmit(onValid, onInvalid)
    await handler(e)
  }, [handleSubmit, onSubmit])

  return {
    submitter,
    processing,
    error
  }
}

function ManageTasksForm ({ onCancel, onComplete, entry }) {
  const classes = useStyles()
  const { entriesLoading: isLoading } = useWealthJourney()
  const form = useForm({
    mode: 'onChange',
    defaultValues: {
      tasks: entry.entryJson?.tasks || []
    }
  })

  const { fields, remove, append } = useFieldArray({
    name: 'tasks',
    control: form.control
  })

  const { submitter, processing, error } = useSubmitter(form, onComplete, entry)

  if (isLoading) {
    return <Loading />
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <div className={classes.taskGrid}>
          {fields.map((field, idx) => (
            <React.Fragment key={`task_${field.id || idx}`}>
              <div>
                <Controller
                  name={`tasks.${idx}.status`}
                  control={form.control}
                  render={((f) => {
                    const value = f.field.value === 'complete'
                    return (
                      <Switch
                        {...f.field} checked={value} onChange={(e) => {
                          f.field.onChange(e.target.checked ? 'complete' : 'incomplete')
                        }}
                      />
                    )
                  })}
                />
              </div>
              <div>
                <Controller
                  name={`tasks.${idx}.description`}
                  control={form.control}
                  render={((f) => (
                    <SydInput size='sm' {...f.field} />
                  ))}
                />
              </div>
              <div>
                <IconButton onClick={() => remove(idx)}>
                  <Icon name={ICON_NAMES.removeCircle} />
                </IconButton>
              </div>
            </React.Fragment>
          ))}
        </div>
      </Grid>
      <Grid item xs={12}>
        <SydButton variant='outline' icon='add' size='sm' onClick={() => append(defaultTask())}>Add Task</SydButton>
      </Grid>
      <Grid item xs={12}>
        <SydModalActions>
          {error ? (<div className='__error'>{error}</div>) : null}
          <SydButton disabled={processing} variant='ghost' size='md' onClick={onCancel}>Cancel</SydButton>
          <SydButton
            variant='primary'
            size='md'
            onClick={submitter}
            processing={processing}
          >
            Save
          </SydButton>
        </SydModalActions>
      </Grid>
    </Grid>
  )
}

ManageTasksForm.propTypes = {
  entry: PropTypes.object,
  onComplete: PropTypes.func,
  onCancel: PropTypes.func
}

export default ManageTasksForm
