import React, { useCallback, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { IconButton, ListItemText, MenuItem, Radio, useTheme } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { DndProvider } from 'react-dnd'
import clsx from 'clsx'
import noop from 'lodash/noop'
import Text from '../../../atoms/Text'
import DragAndDropListItem from '../../../organisms/GroupingProvider/DragAndDropListItem'
import { ICON_NAMES } from '../../../../constants'
import Icon from '../../../atoms/Icon'
import SelectWithSearch from '../../../molecules/Select/SelectWithSearch'
import Divider from '../../../atoms/Divider'

const useStyles = makeStyles((theme) => ({
  viewsContainer: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '.5rem',
    border: `1px solid ${theme.palette.gray.darker}`
  },
  viewsHeader: {
    background: theme.palette.gray.dark,
    borderTopRightRadius: '.5rem',
    borderTopLeftRadius: '.5rem',
    padding: '.75rem .75rem .5rem',
    borderBottom: `1px solid ${theme.palette.gray.darker}`
  },
  viewContent: {
    padding: '.5rem',
    background: theme.palette.gray.light,
    borderBottomRightRadius: '.5rem',
    borderBottomLeftRadius: '.5rem'
  },
  viewList: {
    maxHeight: '40rem',
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    gap: '.5rem'
  },
  viewListItem: {
    borderRadius: '.5rem',
    border: `1px solid ${theme.palette.gray.darker}`,
    background: theme.palette.gray.lighter
  },
  defaultView: {
    background: theme.palette.white
  },
  viewListInternal: {
    padding: '.5em 0',
    flexGrow: 1
  },
  tagBadge: ({ tagColor }) => ({
    marginTop: '.5em',
    fontSize: '.6rem',
    backgroundColor: tagColor ?? theme.palette.gray.darker,
    display: 'inline-flex',
    borderRadius: '.5rem',
    color: theme.palette.getContrastText(tagColor ?? theme.palette.gray.darker),
    padding: '.25rem .5rem'
  })
}))

const ViewTagBadge = ({ tagColor, children }) => {
  const classes = useStyles({ tagColor })

  return (
    <div className={classes.tagBadge}>
      {children}
    </div>
  )
}

ViewTagBadge.propTypes = {
  tagColor: PropTypes.string,
  children: PropTypes.node
}

const RoleViewsEditSortable = ({
  keyName = '',
  selectedViews = [],
  availableViews = [],
  title,
  subtitle,
  onUpdate = noop,
  maxSelections = 9999
}) => {
  const classes = useStyles()
  const theme = useTheme()

  const [editedViews, setEditedViews] = useState(selectedViews)
  useEffect(() => {
    if (selectedViews) {
      setEditedViews(selectedViews)
    }
  }, [selectedViews])

  const [availableViewsFilter, setAvailableViewsFilter] = useState(null)
  const filteredAvailableViews = useMemo(() => {
    if (!availableViewsFilter) return availableViews

    return availableViews.filter((view) => {
      const filterString = availableViewsFilter?.toLowerCase()
      return view.name.toLowerCase().includes(filterString) || view.tagName?.toLowerCase()?.includes(filterString) || view.tagDisplayName?.toLowerCase()?.includes(filterString)
    })
  }, [availableViews, availableViewsFilter])

  const canAddView = useMemo(() =>
    !!availableViews?.length && selectedViews?.length < maxSelections,
  [availableViews?.length, maxSelections, selectedViews?.length]
  )

  const updateOrdinal = useCallback((dragIndex, hoverIndex) => {
    setEditedViews((prevItems) => {
      const newItems = [...prevItems]
      const dragItem = newItems[dragIndex]
      newItems.splice(dragIndex, 1)
      newItems.splice(hoverIndex, 0, dragItem)
      const normalizedItems = newItems.map((item, index) => ({ ...item, ordinal: index + 1 }))
      onUpdate(normalizedItems)
      return normalizedItems
    })
  }, [onUpdate])

  const removeItem = useCallback((viewId) => () => {
    setEditedViews((prevItems) => {
      const newItems = [...prevItems].filter((item) => item.viewId !== viewId)
      const normalizedItems = newItems.map((item, index) => ({ ...item, ordinal: index + 1 }))
      onUpdate(normalizedItems)
      return normalizedItems
    })
  }, [onUpdate])

  const addItem = useCallback((newItem) => {
    setEditedViews((prevItems) => {
      const updatedItems = [...prevItems, newItem]
      const normalizedItems = updatedItems.map((item, index) => ({ ...item, ordinal: index + 1 }))
      onUpdate(normalizedItems)
      return normalizedItems
    })
  }, [onUpdate])

  const toggleDefault = useCallback((viewId, checked) => {
    setEditedViews((prevItems) => {
      const updatedItems = [...prevItems]
      const normalizedItems = updatedItems.map((item, index) => ({ ...item, isDefault: item.viewId === viewId ? checked : false }))
      onUpdate(normalizedItems)
      return normalizedItems
    })
  }, [onUpdate])

  const addViewOptionRenderer = useCallback(
    (option, onChange) => {
      const { name, description, tagName, tagColor, tagDisplayName, viewId } = option

      return (
        <MenuItem
          value={viewId}
          onClick={(event) => onChange(event, viewId)}
        >
          <div>
            <ListItemText primary={name} secondary={description} />
            {tagName && <ViewTagBadge tagColor={tagColor}>{tagDisplayName}</ViewTagBadge>}
          </div>
        </MenuItem>
      )
    },
    []
  )

  return (
    <div className={classes.viewsContainer}>
      {
        (title || subtitle) && (
          <div className={classes.viewsHeader}>
            {title && (<Text text={title} variant='h3' />)}
            {subtitle && <Text text={subtitle} variant='subtitle1' />}
          </div>
        )
      }
      <div className={classes.viewContent}>
        <div className={classes.viewList}>
          <DndProvider backend={HTML5Backend}>
            {editedViews?.map((view, index) => (
              <DragAndDropListItem
                className={clsx([classes.viewListItem, view.isDefault && classes.defaultView])}
                id={view.viewId}
                key={view.viewId}
                index={index}
                value={view.viewId}
                onMoveItem={updateOrdinal}
                size='small'
              >
                <div className={classes.viewListInternal}>
                  <Text text={view.name} variant='h3' />
                  <Text text={view.description} variant='body1' />
                  {view.tagName && <ViewTagBadge tagColor={view.tagColor}>{view.tagDisplayName}</ViewTagBadge>}
                </div>
                <div>
                  {!!view.isDefault && (<Text text='Default Home View' variant='caption' />)}
                  <Radio
                    name={keyName}
                    key={view.viewId}
                    value={view.viewId}
                    checked={!!view.isDefault}
                    onChange={(e) => toggleDefault(view.viewId, e.target.checked)}
                  />
                </div>
                <IconButton className={classes.remove} onClick={removeItem(view.viewId)}>
                  <Icon name={ICON_NAMES.delete} color={theme.palette.error.main} customSize='1.5rem' />
                </IconButton>
              </DragAndDropListItem>
            ))}
          </DndProvider>
        </div>

        {selectedViews?.length < maxSelections && (
          <>
            <Divider style={{ margin: '1rem 0' }} />

            <div
              style={{
                opacity: canAddView ? 1 : 0.5,
                pointerEvents: canAddView ? 'auto' : 'none'
              }}
            >
              <SelectWithSearch
                value={null}
                onChange={(_, selectedOption) => {
                  addItem(selectedOption)
                  setAvailableViewsFilter(null)
                }}
                options={filteredAvailableViews?.map((view) => ({
                  ...view,
                  label: view.name,
                  value: view.viewId
                }))}
                placeholder={canAddView ? 'Add a new view' : 'No more views to add'}
                isMulti={false}
                isDisabled={!availableViews?.length}
                fullWidth
                optionsRenderer={addViewOptionRenderer}
                onQueryChange={setAvailableViewsFilter}
                onClear={() => setAvailableViewsFilter(null)}
                onClose={() => setAvailableViewsFilter(null)}
              />
            </div>
          </>
        )}
      </div>
    </div>
  )
}

const viewPropType = PropTypes.shape({
  viewId: PropTypes.number,
  name: PropTypes.string,
  ordinal: PropTypes.number,
  tagColor: PropTypes.string,
  tagDisplayName: PropTypes.string
})

RoleViewsEditSortable.propTypes = {
  keyName: PropTypes.string,
  selectedViews: PropTypes.arrayOf(viewPropType),
  availableViews: PropTypes.arrayOf(viewPropType),
  title: PropTypes.string,
  subtitle: PropTypes.string,
  onUpdate: PropTypes.func,
  maxSelections: PropTypes.number
}

export default RoleViewsEditSortable
