import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles, Button } from '@material-ui/core'
import noop from 'lodash/noop'

const useStyles = makeStyles(() => ({
  container: {
    width: '100%'
  },
  button: ({ color }) => ({
    color,
    width: '100%',
    fontWeight: 'bold',
    padding: '0.75rem 1rem',
    textTransform: 'capitalize',
    justifyContent: 'center',
    whiteSpace: 'nowrap'
  })
}))

const FilterButton = ({ children, onClick, disabled, color }) => {
  const classes = useStyles({ color })
  return (
    <Button
      size='small'
      className={classes.button}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </Button>
  )
}

FilterButton.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  color: PropTypes.string
}

FilterButton.defaultProps = {
  children: undefined,
  onClick: noop,
  disabled: false,
  color: '#212945'
}

export default FilterButton
