import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useDenaliData } from '../../../api/coreData'
import PresentationTable from '../PresentationTable'
import { useFormattingContext } from '../FormattingProvider/FormattingContext'
import { useDenaliFilter } from './filters/DenaliFilterContextProvider'

export const useColumns = (columns, totals) => {
  const { formatter } = useFormattingContext()

  return useMemo(() => {
    const defaultSort = []
    const includeFooter = totals?.length > 0
    const templatedColumns = columns.map((column) => {
      const result = { ...column }
      if (!result?.Cell) {
        result.Cell = ({ value }) => formatter(value, column.format)
      }
      if (includeFooter && !result?.Footer) {
        const total = totals.at(0)
        result.Footer = () => formatter(total[column.accessor], column.format)
      }

      return result
    })

    return {
      columns: templatedColumns,
      defaultSort,
      includeFooter
    }
  }, [formatter, columns, totals])
}

function DenaliTable ({
  reportId,
  columnConfig,
  baseQuery,
  totalQuery,
  sortable
}) {
  const { filter, membershipFilter } = useDenaliFilter()
  const query = useMemo(() => {
    const q = [{
      key: 'data',
      req: {
        ...baseQuery,
        membershipFilter,
        filters: {
          ...(filter || {}),
          ...(baseQuery?.filters || {})
        },
        reportId
      }
    }]

    if (totalQuery) {
      q.push({
        key: 'total',
        req: {
          ...totalQuery,
          membershipFilter,
          filters: {
            ...(filter || {}),
            ...(baseQuery?.filters || {})
          },
          reportId
        }
      })
    }

    return q
  }, [reportId, baseQuery, totalQuery, filter, membershipFilter])

  const { data, isLoading } = useDenaliData(query)
  const { columns, defaultSort, includeFooter } = useColumns(columnConfig, data?.total || [])

  return (
    <PresentationTable.Wrapper>
      <PresentationTable
        sortable={sortable}
        loading={isLoading}
        variant='bordered'
        data={data?.data || []}
        columns={columns}
        defaultSort={defaultSort}
        includeFooter={includeFooter}
      />
    </PresentationTable.Wrapper>
  )
}

DenaliTable.propTypes = {
  reportId: PropTypes.string,
  columnConfig: PropTypes.array,
  baseQuery: PropTypes.object,
  totalQuery: PropTypes.object,
  sortable: PropTypes.bool
}

DenaliTable.defaultProps = {
  columnConfig: [],
  sortable: false
}

export default DenaliTable
