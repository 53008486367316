import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core/'
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined'
import { useOktaAuth } from '@okta/okta-react'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import noop from 'lodash/noop'
import { useAppContext } from '../../redux/slices/appContext'

const useStyles = makeStyles((theme) => ({
  link: {
    padding: '0px'
  },
  item: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4)
  }
}))

function Profile ({ closeProfileNav }) {
  const classes = useStyles()
  const { oktaAuth } = useOktaAuth()
  const { isAdvisor } = useAppContext()
  const history = useHistory()

  const signOut = useCallback(() => oktaAuth.signOut(), [oktaAuth])

  const goToPreferences = () => {
    closeProfileNav()
    history.push('/preferences')
  }

  return (
    <List>
      <ListItem
        button
        className={clsx(classes.link, classes.item)}
        key='profileLink-1'
        onClick={goToPreferences}
      >
        <ListItemText primary='Preferences' />
      </ListItem>
      {!isAdvisor && (
        <ListItem
          button
          onClick={signOut}
          className={clsx(classes.link, classes.item)}
        >
          <ListItemIcon>
            <ExitToAppOutlinedIcon />
          </ListItemIcon>
          <ListItemText primary='Logout' />
        </ListItem>
      )}
    </List>
  )
}

Profile.propTypes = {
  closeProfileNav: PropTypes.func
}

Profile.defaultProps = {
  closeProfileNav: noop
}

export default Profile
