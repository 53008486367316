import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { replacePlaceholders } from '../../../../utils/replacers'

const LinkCell = ({ column, value, row }) => {
  const toUrl = useMemo(() => replacePlaceholders(column.to, row.original), [column.to, row.original])

  return (
    <Link to={toUrl}>{value}</Link>
  )
}

LinkCell.propTypes = {
  value: PropTypes.string,
  row: PropTypes.object,
  column: PropTypes.shape({
    header: PropTypes.string,
    to: PropTypes.string
  })
}

export default LinkCell
