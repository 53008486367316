import React, { useEffect, useMemo } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { parseUrlSearchParams } from '../../../utils/parseUrlSearchParams'
import { useHideSideMenuEffect, useSetAppContext } from '../../../redux/slices/appContext'
import { postNamedQuery } from '../../../service'
import LoadingView from '../LoadingView'

function getRemainingPath (baseUrl, fullPath) {
  if (!fullPath.startsWith(baseUrl)) {
    return '/' // it should always be safe to go to the root
  }
  return fullPath.substring(baseUrl.length)
}

const useScopeFromQueryString = () => {
  const history = useHistory()
  const queryParams = useMemo(() => {
    return parseUrlSearchParams(history)
  }, [history])

  const clientId = queryParams.client

  return clientId?.length ? clientId.at(0) : null
}

const useRedirectPath = () => {
  const match = useRouteMatch()

  return getRemainingPath(match.path, window.location.pathname)
}

const useRedirectEffect = (clientId, redirectPath) => {
  const history = useHistory()
  const setAppContext = useSetAppContext()

  useEffect(() => {
    if (!clientId) {
      history.replace(redirectPath)
      return
    }

    const fetchClientData = async () => {
      try {
        const result = await postNamedQuery('levels', 'searchClients', {
          filters: {
            clientId: [{ op: 'eq', value: clientId }]
          },
          take: 1
        })

        console.log(result)

        if (result.data.data.find((x) => x.clientId === clientId)) {
          setAppContext({ clientId })
        }

        history.replace(redirectPath)
      } catch (error) {
        console.error(error)
        history.replace('/')
      }
    }

    fetchClientData().catch(console.error)
  }, [clientId, history, redirectPath, setAppContext])
}

const InternalRedirect = () => {
  useHideSideMenuEffect([])
  const clientId = useScopeFromQueryString()
  const redirectPath = useRedirectPath()

  useRedirectEffect(clientId, redirectPath)

  return (
    <LoadingView />
  )
}

export default InternalRedirect
