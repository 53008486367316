import React, { useMemo } from 'react'
import { makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import clsx from 'clsx'
import Text from '../../../../../atoms/Text'
import { commonCardStyles } from '../commonStyles'
import { useWealthJourney } from '../../WealthJourneyProvider'
import { mapThemingColor } from '../../../shared/mapThemingColor'
import EntryMenu from '../../EntryMenu'
import EntryIcon from './EntryIcon'

const useStyles = makeStyles((theme) => ({
  container: {
    userSelect: 'none',
    opacity: ({ disabled }) => (disabled ? 0.5 : 1),
    position: 'relative',
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'center',
    justifyContent: 'flex-start',
    fontSize: '1rem',
    width: 'auto',
    maxWidth: '230px',
    minWidth: '210px',
    height: '280px',
    borderRadius: '8px',
    padding: '1.625rem 1rem 1rem 1rem',
    ...(commonCardStyles(theme)),
    boxSizing: 'border-box',
    textAlign: 'center',
    transition: 'box-shadow .2s ease-in-out, outline .2s ease-in-out',
    outline: '2px solid transparent',
    cursor: ({ clickable }) => (clickable ? 'pointer' : 'none'),
    '&:hover': {
      outlineColor: ({ disabled }) =>
        disabled ? theme.palette.baseBackground.main : `color-mix(in srgb, ${theme.palette.primary.main} 50%, transparent)`,
      cursor: ({ disabled }) => (disabled ? 'initial' : 'pointer')
    },
    '&:active': {
      backgroundColor: `color-mix(in srgb, ${theme.palette.primary.main} 15%, transparent)`
    },
    '&.__selected': {
      outlineColor: theme.palette.primary.main,
      boxShadow: theme.layout.shadow.deep
    },
    '& .MuiIconButton-root': {
      borderRadius: theme.layout.radius.round
    }
  },
  title: {
    fontSize: '1rem',
    fontWeight: 700,
    textAlign: 'center',
    paddingTop: '1.25rem',
    overflow: 'hidden'
  },
  target: {
    fontSize: '0.75rem',
    textAlign: 'center',
    position: 'absolute',
    opacity: '0.5',
    bottom: '1rem'
  },
  icon: {
    width: '5.5rem',
    height: '5.5rem',
    fontSize: '2rem',
    marginTop: '0.5rem',
    color: ({ theming }) => mapThemingColor(theming.iconContrastColor, theme, theme.palette.baseBackground.summitBlue),
    backgroundColor: ({ theming }) => mapThemingColor(theming.iconColor, theme, theme.palette.baseBackground.avatar)
  },
  menu: {
    position: 'absolute',
    top: 0,
    right: 0
  }
}))

const EntryCard = ({ entry, selected, disabled, onClick, allowEdit }) => {
  const { theming } = useWealthJourney()
  const classes = useStyles({ disabled, clickable: Boolean(onClick), theming })
  const [targetDate] = useMemo(
    () => {
      const entryDate = dayjs.utc(entry.entryDate)
      return [entryDate.format('MMM YYYY')]
    },
    [entry.entryDate]
  )

  return (
    <div
      className={clsx(classes.container, { __selected: selected }, 'wj_entry')}
      data-year={entry.year}
      data-entry-id={entry.entryId}
      onClick={onClick}
    >
      {allowEdit ? (
        <div className={classes.menu}>
          <EntryMenu entry={entry} size='20px' />
        </div>
      ) : null}
      <Text
        className={classes.milestoneHeader}
        text={entry?.client?.longName || ''}
      />
      <EntryIcon className={classes.icon} entry={entry} color={entry.entryType?.color} />
      <div className={classes.title}>
        {entry.entryJson.title || entry.entryText}
      </div>
      <div className={classes.target}>
        Target: {targetDate}
      </div>
    </div>
  )
}

EntryCard.propTypes = {
  entry: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  selected: PropTypes.bool,
  onClick: PropTypes.func,
  allowEdit: PropTypes.bool
}

export default EntryCard
