import React from 'react'
import { makeStyles } from '@material-ui/core'
import { BUTTON_SIZES } from '../../../../constants'
import { useToggle } from '../../../../hooks'
import RoundedButton from '../../../atoms/RoundedButton'

import NewAccountsTable from './NewAccounts/NewAccountsTable'

const useStyles = makeStyles(() => ({
  container: {
    height: '100%'
  },
  header: {
    marginBottom: '1rem',
    '& > :first-child': {
      marginRight: '1rem'
    }
  },
  content: {
    height: '100%'
  }
}))

const NewAccountsReport = () => {
  const classes = useStyles()
  const [showNewAccounts, , ...accountToggles] = useToggle(true)
  const [toggleToNewAccounts, toggleToUnassignedAccounts] = accountToggles

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <RoundedButton
          primary={showNewAccounts}
          size={BUTTON_SIZES.small}
          onClick={toggleToNewAccounts}
        >
          New Accounts
        </RoundedButton>
        <RoundedButton
          primary={!showNewAccounts}
          size={BUTTON_SIZES.small}
          onClick={toggleToUnassignedAccounts}
        >
          Unassigned accounts
        </RoundedButton>
      </div>
      <div className={classes.content}>
        {
          showNewAccounts
            ? <NewAccountsTable key='assigned' areAssignedAccounts />
            : <NewAccountsTable key='unassigned' areAssignedAccounts={false} />
        }
      </div>
    </div>
  )
}

export default NewAccountsReport
