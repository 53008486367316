import React from 'react'
import PropTypes from 'prop-types'
import { Box } from '@material-ui/core'
import { useAdvisor } from '../../../../api/groups'
import Avatar from '../../../atoms/Avatar'
import { SKELETON_VARIANTS } from '../../../../constants'
import Skeleton from '../../../atoms/Skeleton'
import Text from '../../../atoms/Text'

const AdvisorProfile = ({ advisorId }) => {
  const {
    data,
    isLoading
  } = useAdvisor(advisorId)

  return (
    <Box height='100%' display='flex' flexDirection='row' alignItems='center'>
      {!isLoading ? (
        <Avatar
          customSize='5rem'
          customFontSize='2rem'
          isPublicResource
          src={data?.profile?.profilePic}
          avatarLetters={data?.longName}
        />
      ) : (
        <Skeleton
          height='4.5rem'
          width='5rem'
          variant={SKELETON_VARIANTS.circle}
        />
      )}

      <Box ml='1rem' display='flex' width='100%' flexDirection='column'>
        {data?.longName ? (
          <Text
            customFontSize='1.5rem'
            customFontWeight='bold'
            text={data?.longName}
          />
        ) : (
          <Box mb={2}>
            <Skeleton width='50%' />
          </Box>
        )}
        {data?.profile?.description ? (
          <Text
            customFontSize='1rem'
            text={data?.profile?.description}
          />
        ) : (
          <Skeleton />
        )}
      </Box>
    </Box>
  )
}

AdvisorProfile.propTypes = {
  advisorId: PropTypes.number.isRequired
}

export default AdvisorProfile
