import dayjs from 'dayjs'
import { DATE_FORMAT_US_LONG } from '../../../../constants'

const sourceColumns = [
  { Header: 'Name', accessor: row => row?.displayName || row?.shortName || row?.longName, id: 'displayName' },
  { Header: 'Full Name', accessor: 'longName', id: 'longName' },
  { Header: 'Symbol', accessor: 'identifier', id: 'identifier' }
]

const blendedColumns = [
  { Header: 'Name', accessor: row => row.displayName ?? row.shortName, id: 'displayName' },
  { Header: 'Full Name', accessor: 'longName', id: 'longName' }
]

const sourceValuesColumns = [
  {
    Header: 'Date',
    accessor: row => dayjs(row.valueDate).add(1, 'day').format(DATE_FORMAT_US_LONG),
    id: 'valueDate'
  },
  { Header: 'Open Value', accessor: row => row.openValue ?? '--', id: 'openValue' },
  { Header: 'Close Value', accessor: row => row.closingValue ?? '--', id: 'closingValue' },
  { Header: 'Low Value', accessor: row => row.lowValue ?? '--', id: 'lowValue' },
  { Header: 'High Value', accessor: row => row.highValue ?? '--', id: 'highValue' },
  { Header: 'Return', accessor: row => `${row.returnValue}%` ?? '--', id: 'returnValue' }
]

export const columnsConfig = {
  sourceColumns,
  blendedColumns,
  sourceValuesColumns,
  defaultSort: [
    { id: 'displayName', desc: false }
  ],
  sourceDefaultSort: [
    { id: 'valueDate', desc: true }
  ]
}
