import React from 'react'
import PropTypes from 'prop-types'
import { Button, makeStyles } from '@material-ui/core'
import Icon from '../../../atoms/Icon'

const useStyles = makeStyles((theme) => ({
  styledButton: {
    textTransform: 'none',
    borderRadius: '10rem',
    padding: '2px 18px',
    outline: ({ priority }) => `1.5px solid ${theme.palette[priority].main}`,
    fontWeight: theme.typography.weights.semibold,
    fontSize: theme.typography.fontSizes.medium,
    transition: 'background-color 200ms ease-in-out, color 200ms ease-in-out',
    backgroundColor: theme.palette.background.default,
    color: ({ priority }) => theme.palette[priority].main,
    '&:hover': {
      backgroundColor: ({ priority }) => theme.palette[priority].main,
      color: theme.palette.background.default
    }
  }
}))

function StyledButton ({ onClick, disabled, style, icon, children, priority }) {
  const classes = useStyles({ priority })

  return (
    <Button
      style={style}
      startIcon={<Icon name={icon} />}
      className={classes.styledButton}
      disabled={disabled}
      variant='outlined'
      onClick={onClick}
    >
      {children}
    </Button>
  )
}

StyledButton.propTypes = {
  priority: PropTypes.oneOf(['primary', 'danger']),
  icon: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  style: PropTypes.object,
  children: PropTypes.node
}

StyledButton.defaultProps = {
  priority: 'primary',
  icon: 'edit',
  policy: null,
  disabled: false
}

export default StyledButton
