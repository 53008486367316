import React, { useCallback, useMemo } from 'react'
import {
  Box
} from '@material-ui/core'
import PropTypes from 'prop-types'
import { DAYJS_OPERATIONS, LEVEL_TYPES, DAYJS_UNIT_TYPES } from '../../../../constants'
import { useFetchState } from '../../../../hooks'
import { useAvailableDates } from '../../../../redux/slices/appContext'
import { fetchTransactions } from '../../../../service'
import { getSafeDate } from '../../../../utils'
import Skeleton from '../../../atoms/Skeleton'
import Text from '../../../atoms/Text'

const AdvisorTransactionsSummaryPreview = ({ advisorId }) => {
  const [availableDates] = useAvailableDates()

  const { totalTransactions, loading } = useFetchState(
    useCallback(
      async (setSafeState) => {
        try {
          const { data } = await fetchTransactions({
            startDate: getSafeDate(availableDates, { operation: DAYJS_OPERATIONS.SUBTRACT, unitType: DAYJS_UNIT_TYPES.DAY, unitValue: 7 }),
            endDate: availableDates.mainDate,
            levelTypes: [LEVEL_TYPES.ACCOUNTS],
            advisorIds: [advisorId]
          })
          const totalTransactions = data.length
          setSafeState({ totalTransactions })
        } catch (err) {
          setSafeState({ error: err })
        }
      },
      [advisorId, availableDates]
    )
  )

  const totalTransactionRendered = useMemo(() => {
    if (loading) {
      return (
        <Skeleton
          height='100px'
        />
      )
    }

    return (
      <Box display='flex' flexDirection='column' alignItems='flex-start' justifyContent='flex-start'>
        <Text text={totalTransactions} customFontSize='56px' color='#212945' />
        <Text text='In the last 7 days' customFontSize='16px' color='#212945' />
      </Box>
    )
  }, [loading, totalTransactions])

  return (
    <Box height='100%' width='100%'>
      <Box mb='0.5rem' display='flex' flexDirection='row' flexWrap='wrap' justifyContent='space-between'>
        <Text text='Transactions' variant='body2' customFontSize='24px' />
        {totalTransactionRendered}
      </Box>
    </Box>
  )
}

AdvisorTransactionsSummaryPreview.propTypes = {
  advisorId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
}

export default AdvisorTransactionsSummaryPreview
