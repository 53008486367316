import { makeStyles } from '@material-ui/core'
import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import DateTimePicker from '../../../atoms/DateTimePicker'
import { ICON_NAMES } from '../../../../constants'
import Icon from '../../../atoms/Icon'
import { useHolidays } from '../../../../api/coreData'
import { disableHolidaysAndWeekends } from '../../../../utils'
import MuiPickersUtcUtilsProvider from '../../../../providers/MuiPickersUtcUtilsProvider'

const useStyles = makeStyles(() => ({
  datePickerContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    cursor: 'pointer',
    '& svg': {
      marginLeft: 'auto'
    },
    '& span:first-child': {
      minWidth: '5rem',
      padding: '1rem'
    }
  },
  outlined: {
    background: '#FFFFFF',
    border: '3px solid #D7DCE1',
    borderRadius: '4px'
  },
  outlinedRounded: {
    background: '#FFFFFF',
    border: '1px solid #D7DCE1',
    borderRadius: '8px'
  },
  label: {
    color: '#141929',
    lineHeight: '1rem',
    paddingBottom: '0',
    fontWeight: 'bold',
    fontSize: '1rem !important'
  },
  input: {
    width: '8rem'
  },
  icon: {
    zIndex: 1,
    position: 'absolute',
    inset: '1rem 1rem 1rem'
  },
  datePicker: {
    zIndex: 2,
    width: '100%',
    padding: '1rem 2rem 1rem 0'
  }
}))

const DateSelector = ({
  value,
  format,
  variant,
  onChange,
  prefixLabel,
  disabledDateRange
}) => {
  const classes = useStyles()
  const { data: holidays } = useHolidays()

  const cn = useMemo(() => {
    return clsx(classes.datePickerContainer, {
      [classes.outlined]: variant === 'outlined',
      [classes.outlinedRounded]: variant === 'outlined-rounded'
    })
  }, [
    classes.datePickerContainer,
    classes.outlined,
    classes.outlinedRounded,
    variant
  ])

  const shouldDisableDate = useCallback(
    (date) => disableHolidaysAndWeekends(date, holidays),
    [holidays]
  )

  return (
    <div className={cn}>
      {prefixLabel && <span className={classes.label}>{prefixLabel}</span>}
      <MuiPickersUtcUtilsProvider>
        <DateTimePicker
          format={format}
          disableFuture
          value={value}
          onChange={onChange}
          className={classes.datePicker}
          disabledDateRange={disabledDateRange}
          shouldDisableDate={shouldDisableDate}
          inputClassName={clsx(classes.label, classes.input)}
        />
      </MuiPickersUtcUtilsProvider>
      <Icon
        additionalClasses={classes.icon}
        name={ICON_NAMES.calendar}
        customSize='1.25rem'
      />
    </div>
  )
}

DateSelector.propTypes = {
  format: PropTypes.string,
  prefixLabel: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  variant: PropTypes.oneOf(['outlined', 'outlined-rounded']),
  disabledDateRange: PropTypes.shape({
    min: PropTypes.string,
    max: PropTypes.string
  })
}

DateSelector.defaultProps = {
  variant: 'outlined',
  format: 'MM/DD/YYYY',
  disabledDateRange: undefined
}

export default DateSelector
