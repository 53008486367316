import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import TreeMapChart from '../../molecules/TreeMapChart'
import useToggle from '../../../hooks/useToggle'
import { labelsPropTypes } from './utils'
import VisualBalanceDrillDown from './VisualBalanceDrilldown'

function VisualBalanceTreeView ({
  clientName,
  data,
  enableDrilldown,
  height,
  labels,
  onClick,
  onPressAccount,
  profilePic,
  totalBackgroundColor,
  totalLiabilitiesBackgroundColor,
  width
}) {
  const [selectedBlock, setSelectedBlock] = useState()
  const [
    showPortfolioBreakdown,
    ,
    togglePortfolioBreakdownOn,
    togglePortfolioBreakdownOff
  ] = useToggle()

  const onBack = useCallback(() => {
    togglePortfolioBreakdownOff()
    onClick(null)
    setSelectedBlock(null)
  }, [
    onClick,
    setSelectedBlock,
    togglePortfolioBreakdownOff
  ])

  const onBlockPress = useCallback(
    (item) => {
      if (enableDrilldown) {
        onClick(item)
        togglePortfolioBreakdownOn()
        setSelectedBlock(item)
      }
    },
    [enableDrilldown, onClick, setSelectedBlock, togglePortfolioBreakdownOn]
  )

  if (showPortfolioBreakdown) {
    return (
      <VisualBalanceDrillDown
        height={height}
        onClickBack={onBack}
        onPressAccount={onPressAccount}
        selectedBlock={selectedBlock}
        backButtonText={labels.drillDown?.backButton}
      />
    )
  }

  return (
    <TreeMapChart
      clientName={clientName}
      data={data}
      height={height}
      totalBlockLabel={labels.chart?.total}
      totalTitle={labels.chart?.totalTitle}
      blocksTitle={labels.chart?.blocksTitle}
      liabilitiesBlockLabel={labels.chart?.liabilitiesBlockTitle}
      onClick={onBlockPress}
      profilePic={profilePic}
      totalBackgroundColor={totalBackgroundColor}
      totalLiabilitiesBackgroundColor={totalLiabilitiesBackgroundColor}
      width={width}
    />
  )
}

VisualBalanceTreeView.propTypes = {
  clientName: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.any),
  enableDrilldown: PropTypes.bool,
  height: PropTypes.string,
  labels: labelsPropTypes,
  onClick: PropTypes.func,
  onPressAccount: PropTypes.func,
  profilePic: PropTypes.string,
  totalBackgroundColor: PropTypes.string,
  totalLiabilitiesBackgroundColor: PropTypes.string,
  width: PropTypes.string
}

VisualBalanceTreeView.defaultProps = {
  clientName: '',
  data: [],
  enableDrillDown: false,
  height: undefined,
  labels: {},
  onClick: noop,
  onPressAccount: noop,
  profilePic: undefined,
  totalBackgroundColor: undefined,
  totalLiabilitiesBackgroundColor: undefined,
  width: undefined
}

export default VisualBalanceTreeView
