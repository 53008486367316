import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useOktaAuth } from '@okta/okta-react'
import dayjs from 'dayjs'
import isEmpty from 'lodash/isEmpty'
import SummitUtilityProvider from './providers/SummitUtilityProvider'
import AnalyticsProvider from './components/atoms/AnalyticsProvider'
import { ExportProvider } from './components/molecules/ExportButton/ExportProvider'
import AppInitializer from './components/templates/AppInitializer'
import { setToken } from './service'
import config from './config'
import { useSetAppContext, useSetUserInformation } from './redux/slices/appContext'
import { useAuthState, usePermissions } from './hooks'
import { useFetchAppConfig } from './redux/slices/appConfig'
import RestrictedView from './components/pages/RestrictedView'
import AcceptTerms from './components/pages/accept-terms'
import ErrorView from './components/pages/ErrorView'
import { useCheckPolicies } from './api/policy'
import LoadingView from './components/pages/LoadingView'
import OutboundSsoProvider from './components/organisms/OutboundSso/OutboundSsoProvider'

/** TODO: put user type on user table */
const findUserType = (groups = []) => {
  const isSummitUser = groups.reduce(
    (acc, group) => group === 'summit' || acc,
    false
  )

  if (isSummitUser) return 'Summit'

  const isAdvisorUser = groups.reduce(
    (acc, group) => group.includes('advisors') || acc,
    false
  )

  if (isAdvisorUser) return 'Advisor'

  return 'Wealth Owner'
}

/* TODO: Remove redux stuff, make configuration immutable */
const useAppConfig = () => {
  const [loading, setLoading] = useState(false)
  const fetchAppConfig = useFetchAppConfig()
  const loadAppConfig = useCallback(async () => {
    setLoading(true)
    try {
      await fetchAppConfig()
    } catch (err) {
      console.error(err)
    } finally {
      setLoading(false)
    }
  }, [setLoading, fetchAppConfig])

  return {
    loading,
    loadAppConfig
  }
}

const useUserProfile = () => {
  const auth = useOktaAuth()
  const authState = useAuthState(auth.authState)
  const { loadAppConfig, loading: appConfigLoading } = useAppConfig()

  useEffect(() => {
    // TODO: this side-effect should be put into context or something
    setToken(authState.accessToken?.accessToken)
    loadAppConfig().catch(console.error)
    return () => {
      setToken(null)
    }
  }, [authState.accessToken?.accessToken, loadAppConfig])

  // feels strange right?
  const userProfile = useMemo(() => {
    // JD: I think this cannot happen anymore
    if (isEmpty(authState) || !authState.isAuthenticated) return { error: 'Not Authenticated' }

    try {
      const user = {
        ...authState.accessToken.claims
      }

      const termAcceptanceIsOutdated = dayjs(user.termsAcceptance).isBefore(config.termsLastUpdateDate)
      const isValidTermsAcceptance = user.termsAcceptance && !termAcceptanceIsOutdated

      return {
        userType: findUserType(authState.accessToken.claims.groups),
        userId: user.uid,
        internalId: user.internalId,
        firmId: +user.firmId,
        allAccountsAccess: user.allAccountsAccess,
        email: user.sub,
        termsAcceptance: user.termsAcceptance,
        isValidTermsAcceptance: isValidTermsAcceptance,
        isNonInteractive: authState?.accessToken?.claims?.groups?.includes('non_interactive')
      }
    } catch (error) {
      console.error('Caught error while starting the application', error)
      return {
        error
      }
      // do not redirect here, because might cause a redirect loop
    }
  }, [authState])

  useSetReducerEffect(userProfile, authState)

  /* TODO: clean up this whole app config mess */
  return { userProfile, appConfigLoading }
}

const useSetReducerEffect = (userProfile, authState) => {
  const setAppContext = useSetAppContext()
  const setUserInformation = useSetUserInformation()
  const { isAdvisor, clientId, firmId, isSummitUser, oktaId: userId } = usePermissions(authState.accessToken || {})

  // these should become contexts instead of redux
  useEffect(() => {
    setAppContext({
      isAdvisor,
      isSummitUser,
      userId,
      firmId,
      userProfile
    })
    setUserInformation({
      userId,
      firmId,
      isAdvisor,
      firmClientId: clientId
    })
  }, [userProfile, setAppContext, setUserInformation, isAdvisor, clientId, firmId, isSummitUser, userId])
}

function SummitApp () {
  const { userProfile, appConfigLoading } = useUserProfile()
  const { isLoading, error } = useCheckPolicies()

  // dunno
  if (userProfile.error) {
    return <ErrorView error={userProfile.error} />
  }

  // sorry, this app is not for you
  if (userProfile.isNonInteractive) {
    return <RestrictedView />
  }

  // gotta sign up for the terms before you can use the app friend
  if (!userProfile.isValidTermsAcceptance) {
    return (
      <AcceptTerms />
    )
  }

  if (error) {
    return (
      <ErrorView error='There was an error loading the application' />
    )
  }

  if (isLoading || appConfigLoading) {
    console.log(`Loading View: isLoading = ${isLoading}, appConfigLoading = ${appConfigLoading})`)
    return <LoadingView />
  }

  // you may proceed
  return (
    <SummitUtilityProvider currentUser={userProfile}>
      <OutboundSsoProvider>
        <AnalyticsProvider currentUser={userProfile}>
          <ExportProvider>
            <AppInitializer />
          </ExportProvider>
        </AnalyticsProvider>
      </OutboundSsoProvider>
    </SummitUtilityProvider>
  )
}

export default SummitApp
