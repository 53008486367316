import React, { useMemo } from 'react'
import numeral from 'numeral'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { Box } from '@material-ui/core'
import isEmpty from 'lodash/isEmpty'
import { useTasksContext } from '../TasksContext'
import FeaturedFilters from '../../../molecules/FeaturedFilters'
import { useTasksMetics } from '../hooks/useTasksMetrics'
import Skeleton from '../../../atoms/Skeleton'
import { SKELETON_VARIANTS } from '../../../../constants'
import { useAdvisorHome } from '../../AdvisorHome/AdvisorHomeContext'
import { TASK_TABS } from '../TasksContextProvider'

dayjs.extend(utc)

const TasksFeaturedFilters = () => {
  const { scope } = useAdvisorHome()

  const {
    selectedTab,
    selectedAsOfDate,
    featuredFilters,
    overdueStartDate,
    setFeaturedFilter,
    excludeTaskStatuses,
    selectedFeaturedFilter
  } = useTasksContext()

  const metricsSpec = useMemo(() => {
    return {
      metricsSpec: featuredFilters.map((filter, index) => ({
        key: filter.value,
        label: filter.label,
        endDate: filter.endDate,
        startDate: filter.startDate,
        order: index + 1
      })),
      asOfDate: selectedAsOfDate,
      includeOverdue: true,
      excludeTaskStatuses,
      overdueStartDate,
      extraFilters:
        selectedTab === TASK_TABS.MY_CLIENTS && !isEmpty(scope?.values)
          ? { groupIds: [{ op: 'in', value: scope?.values ?? [] }] }
          : {}
    }
  }, [
    scope,
    selectedTab,
    featuredFilters,
    overdueStartDate,
    selectedAsOfDate,
    excludeTaskStatuses
  ])

  const { data = [], isLoading } = useTasksMetics(metricsSpec)

  if (isLoading) {
    return (
      <FeaturedFilters.List>
        {Array(featuredFilters.length + 1).fill(0).map((_, index) => {
          return (
            <FeaturedFilters.Item key={index}>
              <Skeleton variant={SKELETON_VARIANTS.rect} height='1.5rem' />
              <Box my={1} />
              <Skeleton variant={SKELETON_VARIANTS.rect} width='60%' height='0.5rem' />
            </FeaturedFilters.Item>
          )
        })}
      </FeaturedFilters.List>
    )
  }

  return (
    <FeaturedFilters.List>
      {data.map((metric, index) => {
        const featureFilter = featuredFilters.find(
          ({ value }) => value === metric.key
        )

        const isSelected =
            selectedFeaturedFilter?.value === featureFilter?.value

        return (
          <FeaturedFilters.Item
            key={index}
            isSelected={isSelected}
            displayName={metric.label}
            onClick={() => setFeaturedFilter(featureFilter)}
          >
            <span>
              <strong>{numeral(metric.total).format('0,0')}</strong> Total
            </span>
          </FeaturedFilters.Item>
        )
      })}
    </FeaturedFilters.List>
  )
}

export default TasksFeaturedFilters
