import React, { useMemo } from 'react'
import { makeStyles, Grid, Divider } from '@material-ui/core'
import ReactMarkdown from 'react-markdown'
import get from 'lodash/get'
import remarkGfm from 'remark-gfm'
import RightPanel from '../../RightPanel'
import FastAvatar from '../../../molecules/FastAvatar'
import FadeIn from '../../../molecules/Transitions/FadeIn'
import TeamMemberAttribute from '../TeamMemberCard/TeamMemberAttribute'
import { useTeamMemberDrawer } from './index'

const useStyles = makeStyles((theme) => ({
  panel: {
    padding: theme.layout.padding.p20
  },
  title: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.layout.gap.g10,
    alignItems: 'center',
    justifyContent: 'center',
    '& .__name-group': {
      marginTop: theme.layout.margin.m10,
      display: 'flex',
      flexDirection: 'column',
      fontWeight: theme.typography.weights.light,
      fontSize: theme.typography.fontSizes.h2,
      alignItems: 'center',
      justifyContent: 'center'
    },
    '& .__name': {
      fontWeight: theme.typography.weights.light,
      fontSize: theme.typography.fontSizes.h2
    },
    '& .__role': {
      fontWeight: theme.typography.weights.light,
      fontStyle: 'italic',
      fontSize: theme.typography.fontSizes.xl3
    }
  },
  bio: {
    fontWeight: theme.typography.weights.light
  },
  avatar: {
    boxShadow: theme.layout.shadow.deep
  },
  attributes: {
    display: 'flex',
    flex: '1',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: theme.layout.gap.g2,
    width: '100%',
    minWidth: '250px',
    height: '100%',
    fontSize: theme.typography.fontSizes.xl,
    padding: `${theme.layout.padding.p20} ${theme.layout.padding.p10}`,
    backgroundColor: theme.palette.background.default,
    borderRadius: theme.layout.radius.loose,
    '& .__attribute': {
      fontWeight: theme.typography.weights.light
    },
    '& .__url': {
      fontWeight: theme.typography.weights.bold
    },
    '& .__table-label': {
      borderRight: `1px solid ${theme.palette.gray.dark}`,
      paddingRight: theme.layout.padding.p5
    }
  }
}))

function DrawerPanel () {
  const classes = useStyles()
  const { teamMember, isOpen, close, schema } = useTeamMemberDrawer()
  const bioValue = useMemo(() => {
    const attribute = schema?.attributes?.find(x => x.attributeId === 'bio')
    return attribute ? get(teamMember, attribute.accessor) : ''
  }, [schema, teamMember])

  return (
    <RightPanel
      open={isOpen}
      title={`About ${teamMember?.name}`}
      toggleOpen={close}
      width='600px'
      height='100%'
      showCloseButton
      titleClassName='__large'
    >
      {isOpen ? (
        <FadeIn className={classes.panel}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div className={classes.title}>
                <FastAvatar
                  className={classes.avatar}
                  avatarUrl={teamMember?.avatarUrl}
                  size='jumbo'
                />
                <div className='__name-group'>
                  <div className='__name'>{teamMember?.name}</div>
                  {teamMember?.role ? (
                    <div className='__role'>{teamMember.role}</div>
                  ) : null}
                </div>
              </div>
            </Grid>
            {bioValue ? (
              <>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={12}>
                  <ReactMarkdown className={classes.bio} children={bioValue} remarkPlugins={[remarkGfm]} />
                </Grid>
              </>
            ) : null}
            {teamMember?.attributes?.length ? (
              <>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={12}>
                  <div className={classes.attributes}>
                    <table>
                      <tbody>
                        {teamMember.attributes.filter(f => f.value).map(f => (
                          <tr key={f.key}>
                            <td className='__table-label'>{f.config?.hideLabel ? '' : f.label}</td>
                            <td>
                              <TeamMemberAttribute
                                key={f.key}
                                className='__attribute'
                                value={f.value}
                                type={f.type}
                                config={f.config}
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </Grid>
              </>
            ) : null}
          </Grid>
        </FadeIn>
      ) : null}
    </RightPanel>
  )
}

export default DrawerPanel
