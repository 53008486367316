import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import { useFormattingContext } from '../FormattingProvider/FormattingContext'

const useStyles = makeStyles((theme) => ({
  allocationPieLegendItem: {
    padding: '.5rem 1rem',
    marginBottom: '.5rem',
    borderLeft: ({ color }) => `5px solid ${color}`
  }
}))

const AllocationPieLegendItem = ({ name, color, value, valueFormat }) => {
  const { formatter } = useFormattingContext()
  const classes = useStyles({ color })
  return (
    <div className={classes.allocationPieLegendItem}>
      <div>{name}</div>
      <div>{formatter(value, valueFormat)}</div>
    </div>
  )
}

AllocationPieLegendItem.propTypes = {
  name: PropTypes.string,
  color: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  valueFormat: PropTypes.string
}

AllocationPieLegendItem.defaultProps = {
  valueFormat: 'marketValue'
}

export default AllocationPieLegendItem
