import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { Box, makeStyles } from '@material-ui/core'
import noop from 'lodash/noop'
import Skeleton from '../atoms/Skeleton'
import { SKELETON_ANIMATIONS, SKELETON_VARIANTS } from '../../constants'
import Text from '../atoms/Text'

const useStyles = makeStyles(() => ({
  container: {
    position: 'relative',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '1rem',
    border: '2px solid #EEF0F8',
    borderRadius: '0.75rem',
    marginBottom: '1.25rem'
  },
  skeletonText: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  placeholder: {
    position: 'absolute',
    left: '50%'
  },
  disabled: {
    opacity: 0.5
  }
}))

const TaskItemSkeleton = ({
  onClick,
  isPlaceholderTask,
  placeholderTitle,
  placeholderSubtitle,
  disabled
}) => {
  const classes = useStyles()
  const skeletonAnimation = isPlaceholderTask
    ? SKELETON_ANIMATIONS.pulse
    : false

  return (
    <div
      className={clsx(classes.container, { [classes.disabled]: disabled })}
      onClick={isPlaceholderTask ? onClick : noop}
    >
      {isPlaceholderTask && (
        <div className={classes.placeholder}>
          <Text text={placeholderTitle} />
          <Text text={placeholderSubtitle} />
        </div>
      )}
      <Box mr='1rem'>
        <Skeleton
          height='1.2rem'
          width='1.25rem'
          variant={SKELETON_VARIANTS.circle}
          animation={skeletonAnimation}
        />
      </Box>
      <div className={classes.skeletonText}>
        <Skeleton
          variant={SKELETON_VARIANTS.text}
          animation={skeletonAnimation}
        />
        <Skeleton
          variant={SKELETON_VARIANTS.text}
          animation={skeletonAnimation}
        />
      </div>
    </div>
  )
}

TaskItemSkeleton.propTypes = {
  onClick: PropTypes.func,
  isPlaceholderTask: PropTypes.bool,
  placeholderTitle: PropTypes.string,
  placeholderSubtitle: PropTypes.string,
  disabled: PropTypes.bool
}

TaskItemSkeleton.defaultProps = {
  onClick: noop,
  isPlaceholderTask: false,
  placeholderTitle: '',
  placeholderSubtitle: '',
  disabled: false
}

export default TaskItemSkeleton
