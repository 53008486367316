import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles, lighten } from '@material-ui/core/styles'
import Icon from '../../atoms/Icon'

const useStyles = makeStyles((theme) => ({
  statusDisplay: ({ statusInfo, size }) => {
    const bg = statusInfo?.color
      ? statusInfo.color
      : 'inherit'

    return ({
      background: bg,
      width: `${size}px`,
      height: `${size}px`,
      display: 'inline-block',
      borderRadius: '40px',
      borderWidth: statusInfo?.color ? '1px' : undefined,
      borderStyle: statusInfo?.color ? 'solid' : undefined,
      borderColor: statusInfo?.color ? statusInfo.color : undefined
    })
  },
  statusIcon: ({ statusInfo, size }) => {
    return {
      borderRadius: '8px',
      backgroundColor: statusInfo?.color ? lighten(statusInfo.color, 0.7) : undefined,
      width: `${size + 10}px`,
      height: `${size + 10}px`,
      padding: '5px',
      '& svg': {
        fill: statusInfo?.color,
        stroke: statusInfo?.color
      }
    }
  }
}))

function StatusDisplay ({ statusInfo, size, useIcon }) {
  const classes = useStyles({ statusInfo, size })
  if (useIcon && statusInfo.icon) {
    return (
      <span className={classes.statusIcon}>
        <Icon
          name={statusInfo.icon}
          customSize={size}
          color={statusInfo.color}
        />
      </span>
    )
  }
  return (
    <span className={classes.statusDisplay} title={statusInfo?.displayName} />
  )
}

StatusDisplay.propTypes = {
  statusInfo: PropTypes.object,
  size: PropTypes.number,
  useIcon: PropTypes.bool
}

StatusDisplay.defaultProps = {
  size: 14,
  useIcon: false
}

export default StatusDisplay
