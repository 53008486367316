import React from 'react'
import PropTypes from 'prop-types'
import { CssBaseline, makeStyles } from '@material-ui/core'
import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
  container: ({ backgroundImage }) => ({
    display: 'flex',
    height: '100vh',
    width: '100vw',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundImage: backgroundImage ? `url("${backgroundImage}")` : '',
    backgroundPosition: 'bottom center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    overflow: 'auto'
  }),
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 12,
    backgroundColor: 'transparent',
    minWidth: 390,
    [theme.breakpoints.up('sm')]: {
      minWidth: 480
    }
  }
}))

const PublicLayout = ({ children, className, backgroundPath, splash }) => {
  const classes = useStyles({ backgroundImage: backgroundPath })

  return (
    <>
      {splash}
      <div className={classes.container}>
        <CssBaseline />
        <div className={clsx(classes.content, { [className]: !!className })}>
          {children}
        </div>
      </div>
    </>
  )
}

PublicLayout.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  backgroundPath: PropTypes.string,
  splash: PropTypes.any
}

PublicLayout.defaultProps = {
  className: undefined,
  backgroundPath: undefined
}

export default PublicLayout
