import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Box } from '@material-ui/core'
import isEmpty from 'lodash/isEmpty'
import { useParameterReplacement } from '../../../../hooks/useAbundanceEngineParameters'
import TableSkeleton from '../../../atoms/TableSkeleton'
import { useAdvisorHome } from '../AdvisorHomeContext'
import { useWealthJourneyQuery } from '../../../../api/wealthJourney'
import AdvisorHomeEmptySection from '../AdvisorHomeEmptySection'
import { ICON_NAMES } from '../../../../constants'
import MeetingList from './MeetingList'

const queryKey = 'upcoming_meetings'

const UpcomingMeetings = ({
  limit,
  startDate,
  endDate,
  linkBaseUrl,
  emptyStateLabel
}) => {
  const { scope } = useAdvisorHome()
  const [start, end] = useParameterReplacement([startDate, endDate])

  const { query, queryOptions } = useMemo(() => {
    return {
      query: {
        take: limit,
        includes: {
          client: true,
          organizer: true
        },
        filters: {
          startDate: [{ op: 'gte', value: start }],
          endDate: [{ op: 'lte', value: end }],
          ...(!isEmpty(scope?.values)
            ? {
              groupId: [{ op: 'in', value: scope?.values }]
            }
            : {})
        },
        sort: [{ field: 'startDate', dir: 'asc' }],
        resultType: 'details'
      },
      queryOptions: {
        queryName: 'searchMeetings',
        mapper: ({ data }) => data
      }
    }
  }, [limit, start, end, scope])

  const { data, isLoading, error } = useWealthJourneyQuery(
    queryKey,
    query,
    queryOptions
  )

  if (error && scope) {
    return (
      <AdvisorHomeEmptySection
        title='No meetings'
        description={emptyStateLabel}
      />
    )
  }

  if (isEmpty(data) && !isLoading) {
    return (
      <AdvisorHomeEmptySection
        title='No meetings'
        description={emptyStateLabel}
        icon={ICON_NAMES.paperCalendar}
      />
    )
  }

  return (
    <Box height='100%' width='100%'>
      {isLoading ? (
        <Box p={2}>
          <TableSkeleton
            rows={5}
            columns={1}
            rowHeight='3rem'
            showHeader={false}
          />
        </Box>
      ) : (
        <MeetingList meetings={data} take={limit} linkBaseUrl={linkBaseUrl} />
      )}
    </Box>
  )
}

UpcomingMeetings.propTypes = {
  limit: PropTypes.number,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  linkBaseUrl: PropTypes.string,
  emptyStateLabel: PropTypes.string
}

UpcomingMeetings.defaultProps = {
  datasetName: 'SalesforceMeetings',
  emptyStateLabel: 'Looks like you have no upcoming meetings',
  limit: 5,
  startDate: '$today',
  endDate: '$in30days',
  linkBaseUrl: null
}

export default UpcomingMeetings
