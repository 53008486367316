import React from 'react'
import { Redirect, Switch, useRouteMatch } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { SecureRoute } from '@okta/okta-react'
import { useSearchParam } from '../../../../hooks/useSearchParam'
import RebalanceFlow from './RebalanceFlow'
import RaiseCashFlow from './RaiseCashFlow/index'

const useStyles = makeStyles((theme) => ({
  analysisView: {
    display: 'flex',
    justifyContent: 'center'
  },
  flowContainer: {
    flex: '1 0 auto',
    width: 'calc(100vw - 40px)',
    padding: '.5rem 3rem',
    maxWidth: '1600px',
    margin: '5px 20px',
    border: '1.5px solid rgba(0, 0, 0, .3)',
    borderRadius: '10px',
    boxShadow: '0px 3px 10px -5px rgba(0, 0, 0, .3)'
  }
}))

function AnalysisView () {
  const { path } = useRouteMatch()
  const clientId = useSearchParam('clientId', Number)
  const classes = useStyles()

  if (!clientId) {
    return (
      <article className={classes.analysisView}>
        No client selected
      </article>
    )
  }

  return (
    <Switch>
      <SecureRoute path={path} exact>
        <Redirect to='/rebalancer' />
      </SecureRoute>
      <SecureRoute path={`${path}/rebalance`} exact>
        <RebalanceFlow clientId={clientId} />
      </SecureRoute>
      <SecureRoute path={`${path}/raiseCash`} exact>
        <RaiseCashFlow clientId={clientId} />
      </SecureRoute>
      <SecureRoute path='*'>
        <Redirect to='/rebalancer' />
      </SecureRoute>
    </Switch>
  )
}

AnalysisView.propTypes = {
}

export default AnalysisView
