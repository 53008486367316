/* eslint-disable react/jsx-boolean-value */
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import isEmpty from 'lodash/isEmpty'
import dayjs from 'dayjs'
import { useForm, Controller } from 'react-hook-form'
import { makeStyles } from '@material-ui/core/styles'
import {
  Box,
  Grid,
  FormLabel,
  InputAdornment,
  TextField,
  useTheme
} from '@material-ui/core'
import utc from 'dayjs/plugin/utc'
import first from 'lodash/first'
import {
  DATE_TIME_PICKER_TYPES,
  ICON_NAMES,
  MILESTONE_CATEGORIES,
  MILESTONE_CATEGORY_OPTIONS
} from '../../constants'
import useMilestone from '../../hooks/useMilestone'
import { messageToUse } from '../pages/admin/common'
import Text from '../atoms/Text'
import Icon from '../atoms/Icon'
import Divider from '../atoms/Divider'
import DateTimePicker from '../atoms/DateTimePicker'
import { useAppContext } from '../../redux/slices/appContext'
import { entryTypeIdentifier } from '../organisms/WealthJourney/v2/entryType'
import { fetchEntryImage } from '../../service'
import WealthJourneyMilestoneCard from './WealthJourneyMilestoneCard'
import CustomSelect from './CustomSelect'
import ProfilePictureFileInput from './ProfilePictureFileInput'
import AttachmentList from './AttachmentList'
import TasksList from './TasksList'
import SaveCancelButtons from './SaveCancelButtons'

dayjs.extend(utc)

const useStyles = makeStyles((theme) => ({
  actions: {
    position: 'absolute',
    bottom: 0,
    left: '50%',
    transform: 'translateX(-50%)'
  },
  mainForm: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '80px'
  },
  column: {
    padding: '0 50px'
  },
  textInput: {
    marginTop: '20px'
  },
  cancelButton: {
    backgroundColor: theme.palette.common.white,
    color: '#212945',
    border: '2px solid #212945',
    '&:hover': {
      backgroundColor: theme.palette.gray.light
    }
  },
  submitButton: {
    backgroundColor: '#212945',
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.gray.dark
    }
  },
  field: {
    display: 'flex',
    flexDirection: 'column'
  },
  label: {
    marginBottom: '10px',
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'flex-start'
  },
  divider: {
    margin: '20px 0px'
  },
  selector: {
    padding: '10px ',
    border: '2px solid #EEF0F8',
    width: '200px',
    borderRadius: '8px',
    minHeight: '50px'
  },
  selectInput: {
    display: 'flex',
    height: '100%',
    paddingBottom: '0px',
    textAlign: 'left !important'
  },
  inputAdornment: {
    padding: '0px'
  },
  input: {
    padding: '14px',
    border: '2px solid #EEF0F8',
    borderRadius: '8px',
    alignItems: 'flex-start',
    '&:hover': {
      border: '2px solid #c9cbd2',
      borderRadius: '8px'
    }
  },
  asterisk: {
    marginLeft: '10px'
  }
}))

const DATE_FORMAT = 'YYYY-MM-DD'

const defaultValues = {
  milestoneId: '',
  title: '',
  descriptionTitle: '',
  description: '',
  date: dayjs().format(DATE_FORMAT),
  pictureUrl: '',
  category: MILESTONE_CATEGORIES.MILESTONE
}

const getFormValues = (milestone) => ({
  milestoneId: milestone.entryId,
  title: milestone.entryJson.title,
  descriptionTitle: milestone.entryJson.subtitle,
  description: milestone.entryJson.description,
  category: milestone.entryJson.category,
  date: dayjs.utc(milestone.entryDate).format(DATE_FORMAT)
})

const WealthJourneyMilestoneForm = ({
  selectedEntry,
  entries,
  onClose,
  onAddEntry,
  onEditEntry,
  taskTitle,
  taskButtonLabel
}) => {
  const classes = useStyles()
  const theme = useTheme()
  const { clientId } = useAppContext()
  const {
    tasks,
    onAddTask,
    onRemoveTask,
    onCompleteTask,
    onChangeTaskBody,
    attachments,
    onAddDocument,
    onRemoveAttachment,
    onAddImage,
    entryImageUploadMeta
  } = useMilestone({ milestone: selectedEntry })

  const [defaultImage, setDefaultImage] = useState(null)

  const isImageUploading = useMemo(() => {
    const { uploaded, aborted, hasError } = entryImageUploadMeta
    return !uploaded && !aborted && !hasError
  }, [entryImageUploadMeta])

  const filteredEntries = useMemo(() => {
    if (isEmpty(entries) || !selectedEntry) return entries
    return entries.reduce((acc, entry) => {
      if (entry.entryId !== selectedEntry.entryId) {
        return [...acc, entry]
      }
      return acc
    }, [])
  }, [selectedEntry, entries])

  const onSubmit = useCallback(
    (data) => {
      const editMode = !!selectedEntry
      const {
        milestoneId,
        title,
        date,
        imageUrl,
        category,
        description,
        descriptionTitle
      } = data

      const entryDate = dayjs.utc(date).format(DATE_FORMAT)

      const entryJson = {
        ...(selectedEntry?.entryJson || {}),
        tasks: tasks.filter(({ title, description }) => title || description),
        title,
        category,
        description,
        attachments,
        images: [{ imageUrl }],
        subtitle: descriptionTitle
      }
      if (!editMode) {
        const saveBody = {
          clientId,
          entryJson,
          entryText: title,
          entryTypeId: entryTypeIdentifier.MILESTONES,
          entryDate
        }
        onAddEntry(saveBody)
        return onClose()
      }
      const { entryText } = selectedEntry
      const editBody = {
        entryId: milestoneId,
        entryText,
        entryJson,
        entryDate
      }
      onEditEntry(editBody)
      return onClose()
    },
    [
      tasks,
      onClose,
      clientId,
      onAddEntry,
      onEditEntry,
      attachments,
      selectedEntry
    ]
  )

  const {
    register,
    control,
    handleSubmit,
    formState,
    setValue,
    clearErrors,
    watch
  } = useForm({
    defaultValues: selectedEntry ? getFormValues(selectedEntry) : defaultValues
  })

  const {
    category: categoryPreview,
    date: datePreview,
    title: titlePreview,
    imagePreview
  } = watch()

  useEffect(() => {
    async function fetchDefaultImage ({ entryId, entryJson }) {
      const { imageUrl } = !isEmpty(entryJson?.images)
        ? first(entryJson.images)
        : {}
      if (!imageUrl) return
      const { data: { downloadUrl } } = await fetchEntryImage(entryId, imageUrl)
      setValue('imageUrl', imageUrl)
      setValue('imagePreview', downloadUrl)
      setDefaultImage(downloadUrl)
    }
    if (selectedEntry) {
      fetchDefaultImage(selectedEntry)
    }
  }, [selectedEntry, setValue])

  const handleFieldChange = (viewFieldName) => (value) => {
    clearErrors(viewFieldName)
    setValue(viewFieldName, value)
  }

  const { isSubmitting, errors, isValid } = formState

  const onUploadEntryPicture = useCallback(async (file) => {
    if (!file) return
    const { newFileName } = await onAddImage(file)
    setValue('imageUrl', newFileName)
    setValue('imagePreview', file?.preview)
  }, [onAddImage, setValue])

  const onRemoveEntryPicture = useCallback(async () => {
    setValue('imageUrl', '')
    setValue('imagePreview', '')
    setDefaultImage(null)
  }, [setValue])

  return (
    <>
      <div style={{ display: 'flex', width: '100%' }}>
        <WealthJourneyMilestoneCard
          milestone={{
            entryDate: datePreview || dayjs().format('YYYY-MM-DD'),
            entryJson: {
              title: titlePreview || '',
              category: categoryPreview || '',
              images: [{ imageUrl: imagePreview, isPreview: true }]
            }
          }}
          onSelect={noop}
        />
        {filteredEntries.map((entry) => (
          <WealthJourneyMilestoneCard
            key={`mk_${entry.entryId}`}
            milestone={entry}
            onSelect={noop}
            selected={selectedEntry}
            disabled
          />
        ))}
      </div>
      <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
        <input
          type='hidden'
          {...register('milestoneId', { required: false })}
        />
        <Grid container style={{ marginTop: '50px' }}>
          <Grid item className={classes.mainForm} xs={12}>
            <Grid item xs={6} className={classes.column}>
              <Box
                display='flex'
                flexDirection='row'
                justifyContent='space-between'
                flexWrap='wrap'
                mt='14px'
              >

                <ProfilePictureFileInput
                  placeholderSrc={defaultImage}
                  isLoading={isImageUploading}
                  onDropAccepted={onUploadEntryPicture}
                  onRemovePicture={onRemoveEntryPicture}
                />

                <div className={classes.field}>
                  <FormLabel htmlFor='date' className={classes.label}>
                    <Text
                      text='Target Month'
                      customFontSize='16px'
                      customFontWeight={600}
                      color='#212945'
                    />
                    <Text
                      text='*'
                      color='#D44333'
                      customFontSize='16px'
                      className={classes.asterisk}
                    />
                  </FormLabel>
                  <Controller
                    name='date'
                    control={control}
                    render={({ field }) => (
                      <DateTimePicker
                        {...field}
                        type={DATE_TIME_PICKER_TYPES.date}
                        className={classes.selector}
                        inputClassName={classes.selectInput}
                        inputProps={{
                          startAdornment: (
                            <InputAdornment position='start'>
                              <Icon
                                name={ICON_NAMES.calendar}
                                customSize='18px'
                                margin='0px 10px 0px 0px'
                              />
                            </InputAdornment>
                          ),
                          endAdornment: (
                            <InputAdornment position='end'>
                              <Icon
                                name={ICON_NAMES.chevronDown}
                                customSize='18px'
                                margin='0px 10px 0px 0px'
                              />
                            </InputAdornment>
                          )
                        }}
                      />
                    )}
                  />
                  {errors.date && (
                    <Text
                      text={messageToUse(errors.date)}
                      color={theme.palette.error.main}
                    />
                  )}
                </div>
                <div className={classes.field}>
                  <FormLabel htmlFor='date' className={classes.label}>
                    <Text
                      text='Category'
                      customFontSize='16px'
                      customFontWeight={600}
                      color='#212945'
                    />
                  </FormLabel>
                  <input type='hidden' {...register('category')} />
                  <CustomSelect
                    defaultValue={
                      selectedEntry?.entryJson?.category ||
                      defaultValues.category
                    }
                    onChange={handleFieldChange('category')}
                    options={MILESTONE_CATEGORY_OPTIONS}
                    className={classes.selector}
                    inputClassName={classes.selectInput}
                    iconFontSize='18px'
                  />
                  {errors.category && (
                    <Text
                      text={messageToUse(errors.category)}
                      color={theme.palette.error.main}
                    />
                  )}
                </div>
              </Box>
              <Box className={classes.textInput}>
                <TextField
                  error={Boolean(errors.title)}
                  id='title'
                  placeholder='Title *'
                  variant='standard'
                  className={classes.input}
                  InputProps={{
                    ...register('title', {
                      minLength: 1,
                      maxLength: 50,
                      required: true
                    }),
                    disableUnderline: true
                  }}
                  fullWidth
                />
                {errors.title && (
                  <Text
                    text={messageToUse(errors.title)}
                    color={theme.palette.error.main}
                  />
                )}
              </Box>
              <Box className={classes.textInput}>
                <TextField
                  error={Boolean(errors.descriptionTitle)}
                  id='descriptionTitle'
                  placeholder='Summary'
                  variant='standard'
                  className={classes.input}
                  InputProps={{
                    ...register('descriptionTitle'),
                    disableUnderline: true
                  }}
                  fullWidth
                />
                {errors.descriptionTitle && (
                  <Text
                    text={messageToUse(errors.descriptionTitle)}
                    color={theme.palette.error.main}
                  />
                )}
              </Box>
              <Box className={classes.textInput}>
                <TextField
                  error={Boolean(errors.description)}
                  id='description'
                  placeholder='Description. Anything else you like to add (optional)'
                  InputProps={{
                    ...register('description'),
                    style: { minHeight: '100px', alignItems: 'flex-start' },
                    disableUnderline: true
                  }}
                  variant='standard'
                  className={classes.input}
                  fullWidth
                  multiline
                />
                {errors.description && (
                  <Text
                    text={messageToUse(errors.description)}
                    color={theme.palette.error.main}
                  />
                )}
              </Box>
              <Divider additionalClasses={classes.divider} />
              <AttachmentList
                readOnly={false}
                documents={attachments}
                onAddDocument={onAddDocument}
                onRemoveDocument={onRemoveAttachment}
              />
            </Grid>
            <Grid item xs={6} className={classes.column}>
              <TasksList
                title={taskTitle}
                buttonLabel={taskButtonLabel}
                tasks={tasks}
                readOnly={false}
                onAddTask={onAddTask}
                onRemoveTask={onRemoveTask}
                onCompleteTask={onCompleteTask}
                onChangeTaskBody={onChangeTaskBody}
              />
            </Grid>
          </Grid>
          <SaveCancelButtons
            onCancel={onClose}
            isFormInvalid={isValid}
            isSubmitting={isSubmitting}
            containerClassName={classes.actions}
          />
        </Grid>
      </form>
    </>
  )
}

WealthJourneyMilestoneForm.propTypes = {
  selectedEntry: PropTypes.object,
  entries: PropTypes.array,
  onClose: PropTypes.func.isRequired,
  onAddEntry: PropTypes.func,
  onEditEntry: PropTypes.func,
  taskTitle: PropTypes.string,
  taskButtonLabel: PropTypes.string
}

WealthJourneyMilestoneForm.defaultProps = {
  selectedEntry: {},
  entries: [],
  onAddEntry: noop,
  onEditEntry: noop
}

export default WealthJourneyMilestoneForm
