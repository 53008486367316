import { InputLabel, makeStyles } from '@material-ui/core'
import React from 'react'
import PropTypes from 'prop-types'
import BlockButtonGroup from '../../../molecules/BlockButtonGroup'

const useStyles = makeStyles(() => ({
  title2: {
    fontSize: '1.2rem',
    color: '#000',
    fontWeight: '600',
    marginTop: '1rem',
    marginBottom: '0.625rem',
    '& span': {
      color: '#db3131'
    }
  },
  footnote: {
    fontSize: '1rem',
    fontWeight: '325',
    color: '#707689',
    '& strong': {
      color: 'black'
    }
  }
}))

const ReportParameterControl = ({ title, value, onSelectOption, options }) => {
  const classes = useStyles()
  return (
    <>
      <InputLabel className={classes.title2}>{title}</InputLabel>
      <BlockButtonGroup
        selectedValue={value}
        onSelectOption={onSelectOption}
      >
        {options.map(({ label, value, footnote }) => (
          <BlockButtonGroup.Option
            key={value} value={value} footnote={
              <div className={classes.footnote}>{footnote}</div>
            }
          >
            {label}
          </BlockButtonGroup.Option>
        ))}
      </BlockButtonGroup>
    </>
  )
}

ReportParameterControl.propTypes = {
  title: PropTypes.string,
  value: PropTypes.string,
  onSelectOption: PropTypes.func,
  options: PropTypes.array
}

export default ReportParameterControl
