import React from 'react'
import PropTypes from 'prop-types'
import PillButtonGroup from '../../../../molecules/PillButtonGroup'

const PageSizePicker = ({ onSelect, value }) => {
  return (
    <PillButtonGroup onSelect={onSelect} selectedValue={value}>
      <PillButtonGroup.Option value={5}>Show 5</PillButtonGroup.Option>
      <PillButtonGroup.Option value={10}>Show 10</PillButtonGroup.Option>
      <PillButtonGroup.Option value={100000}>Show All</PillButtonGroup.Option>
    </PillButtonGroup>
  )
}

PageSizePicker.propTypes = {
  onSelect: PropTypes.func.isRequired,
  value: PropTypes.any
}

export default PageSizePicker
