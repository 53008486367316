import React from 'react'
import PropTypes from 'prop-types'
import { MenuItem, makeStyles } from '@material-ui/core'
import Text from '../../../../atoms/Text'

const useStyles = makeStyles(() => ({
  item: {
    padding: '0.5rem',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& span': {
      marginLeft: 'auto',
      fontSize: '0.75rem',
      opacity: 0.5
    }
  }
}))

const FilterOption = ({
  value,
  label,
  disabled,
  onChange,
  caption,
  checkedIcon
}) => {
  const classes = useStyles()
  return (
    <MenuItem
      value={value}
      disabled={disabled}
      onClick={(event) => onChange(event, value)}
    >
      <div className={classes.item}>
        <Text customFontWeight='600'>{label}</Text>
        {checkedIcon}
        {caption && <span>{caption}</span>}
      </div>
    </MenuItem>
  )
}

FilterOption.propTypes = {
  value: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  caption: PropTypes.string,
  checkedIcon: PropTypes.node
}

export default FilterOption
