import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useTheme } from '@material-ui/core'
import { isArray, isString } from 'lodash'
import dayjs from 'dayjs'
import { useRelativeDateRange } from '../../../molecules/RelativeDateSelect'
import { useSearchAccountsMultiple } from '../../../../api/accounts'
import { useAdvisorHome } from '../AdvisorHomeContext'
import Metric from './Metric'
import InlineDividedMetric from './InlineDividedMetric'

const NewAccountsMetric = ({
  valueFormat,
  valueDescription,
  defaultDateRange,
  redirectTo
}) => {
  const theme = useTheme()
  const { scope } = useAdvisorHome()
  const { dateRange } = useRelativeDateRange(defaultDateRange, dayjs().format('YYYY-MM-DD'))

  const newAccountsQuery = useMemo(() => {
    return {
      query: {
        totalAccounts: {
          resultType: 'total',
          filters: {
            startDate: [
              {
                op: 'gte',
                value: dateRange.startDate
              }
            ],
            ...(scope?.values?.length && { assignedToAdvisorIds: scope?.values })
          }
        },
        totalUnassignedAccounts: {
          filters: {
            hasClientsAssigned: false,
            ...(scope?.values?.length && { assignedToAdvisorIds: scope?.values })
          },
          resultType: 'total'
        }
      },
      queryOptions: {
        mapper: (data) => {
          return {
            totalAccounts: data?.totalAccounts?.total || 0,
            totalUnassignedAccounts: data?.totalUnassignedAccounts?.total || 0
          }
        }
      }
    }
  }, [dateRange, scope?.values])

  const { data, isLoading } = useSearchAccountsMultiple(
    newAccountsQuery.query,
    newAccountsQuery.queryOptions
  )

  return (
    <Metric.Wrapper redirectTo={isString(redirectTo) ? redirectTo : null}>
      <InlineDividedMetric justifyContentEvenly>
        <Metric
          value={data?.totalAccounts}
          isLoading={isLoading}
          valueFormat={valueFormat}
          description={valueDescription}
          redirectTo={isArray(redirectTo) ? redirectTo?.[0] : undefined}
        />
        <Metric
          description='Unassigned'
          isLoading={isLoading}
          valueFormat={valueFormat}
          value={data?.totalUnassignedAccounts}
          valueColor={theme.palette.error.primary}
          redirectTo={isArray(redirectTo) ? redirectTo?.[1] : undefined}
        />
      </InlineDividedMetric>
    </Metric.Wrapper>
  )
}

NewAccountsMetric.propTypes = {
  valueFormat: PropTypes.string,
  valueDescription: PropTypes.string,
  defaultDateRange: PropTypes.string,
  redirectTo: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string)
  ])
}

NewAccountsMetric.defaultProps = {
  valueFormat: 'default',
  defaultDateRange: 'L30D',
  valueDescription: 'in the last 30 days',
  redirectTo: undefined
}

export default NewAccountsMetric
