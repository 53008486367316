import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Box } from '@material-ui/core'
import Text from '../../../atoms/Text'
import NumberFormat from '../../../atoms/NumberFormat'
import { numeralByCase, tableNumberFormatter } from '../../../../utils'
import Skeleton from '../../../atoms/Skeleton'
import Image from '../../../atoms/Image'
import { useAvailableDates } from '../../../../redux/slices/appContext'
import { CALC_TYPES, LEVEL_TYPES } from '../../../../constants'
import { useGetGroupedCoreData } from '../../../../api/coreData'

const AdvisorAnalytics = ({ advisorId, imageLinkSrc, imageLinkUrl, imageLinkStyles }) => {
  const [availableDates] = useAvailableDates()
  const query = useMemo(
    () => ({
      dateRange: {
        startDate: availableDates.min,
        endDate: availableDates.max
      },
      levelFilters: {
        advisorIds: [advisorId],
        levelTypes: [LEVEL_TYPES.CLIENT],
        calcType: CALC_TYPES.balance
      }
    }),
    [advisorId, availableDates]
  )

  const {
    data,
    isLoading,
    isError
  } = useGetGroupedCoreData(query)

  const advisorAnalytics = useMemo(() => {
    if (isError || isLoading) return null

    return ({
      netWorthManaged: data?.reduce((acc, { endingValue }) => endingValue + acc, 0),
      clientBase: data?.length || 0
    })
  }, [isError, isLoading, data])

  return (
    <Box
      height='100%'
      display='flex'
      flexDirection='row'
      justifyContent='space-evenly'
    >
      <Box display='flex' flexDirection='column'>
        <Box mb='0.5rem'>
          <Text
            text='Net Worth Managed'
            customFontWeight='normal'
            variant='subtitle2'
          />
        </Box>
        {isLoading ? (
          <Skeleton height='3.5rem' />
        ) : (
          <NumberFormat
            title={numeralByCase(advisorAnalytics?.netWorthManaged || 0)}
          >
            <Text
              text={tableNumberFormatter(advisorAnalytics?.netWorthManaged || 0)}
              lineHeight='1'
              customFontSize='3.5rem'
              variant='body2'
            />
          </NumberFormat>
        )}
      </Box>
      <Box display='flex' flexDirection='column'>
        <Box mb='0.5rem'>
          <Text
            text='Client Base'
            customFontWeight='normal'
            variant='subtitle2'
          />
        </Box>
        {isLoading ? (
          <Skeleton height='3.5rem' />
        ) : (
          <Text
            text={advisorAnalytics?.clientBase}
            lineHeight='1'
            customFontSize='3.5rem'
            variant='body2'
          />
        )}
      </Box>
      {imageLinkSrc && imageLinkUrl && (
        <Box display='flex' flexDirection='column' justifyContent='center'>
          <a href={imageLinkUrl} target='_blank' rel='noopener noreferrer'>
            <Image
              src={imageLinkSrc}
              isPublicResource
              extraStyles={imageLinkStyles || { width: '40px', borderRadius: '50%' }}
            />
          </a>
        </Box>
      )}
    </Box>
  )
}

AdvisorAnalytics.propTypes = {
  advisorId: PropTypes.number.isRequired,
  imageLinkUrl: PropTypes.string,
  imageLinkSrc: PropTypes.string,
  imageLinkStyles: PropTypes.object
}

export default AdvisorAnalytics
