import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import {
  useSearchClientConversations
} from '../../../../../api/conversations'
import Tabs from '../../../../molecules/Tabs'
import ClientDisplay from '../ClientDisplay'
import ConversationListItem from './ConversationListItem'
import CreateConversationButton from './CreateConversationButton'

const useStyles = makeStyles(theme => ({
  conversationList: {
    borderRight: `1px solid ${theme.palette.gray.main}`,
    minWidth: '460px'
  },
  create: {
    textAlign: 'right'
  },
  tabWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    borderBottom: `1px solid ${theme.palette.gray.main}`
  }
}))

const ConversationList = ({ clientId, selectedConversation, canCreate, isNew }) => {
  const classes = useStyles()
  const query = useMemo(() => ({
    filters: {
      clientId: [{ op: 'eq', value: clientId }]
    }
  }), [clientId])
  const { data, isLoading, error } = useSearchClientConversations(query)
  const [tab, setTab] = useState('open')

  if (isLoading) {
    return (
      <div className={classes.conversationList}>
        <div>&nbsp;</div>
      </div>
    )
  }

  if (error) {
    return (
      <div className={classes.conversationList}>
        {error.message}
      </div>
    )
  }

  return (
    <div className={classes.conversationList}>
      <ClientDisplay clientId={clientId} />
      <div className={classes.tabWrapper}>
        <Tabs.Wrapper noMargin>
          <Tabs
            value={tab}
            indicatorColor='primary'
            textColor='primary'
            onChange={(_, value) => setTab(value)}
          >
            <Tabs.Tab value='open' label='Open' />
            <Tabs.Tab value='resolved' label='Completed' />
          </Tabs>
        </Tabs.Wrapper>
      </div>
      {data.conversations.map(c => (
        <ConversationListItem
          key={c.conversationKey}
          item={c}
          clientId={clientId}
          selectedConversation={selectedConversation}
        />
      ))}
      {canCreate ? (
        <CreateConversationButton clientId={clientId} isNew={isNew} />
      ) : null}
    </div>
  )
}

ConversationList.propTypes = {
  clientId: PropTypes.number,
  selectedConversation: PropTypes.string,
  canCreate: PropTypes.bool,
  isNew: PropTypes.bool
}

ConversationList.defaultProps = {
  canCreate: true,
  isNew: false
}

export default ConversationList
