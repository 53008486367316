import React, { useCallback, useState } from 'react'
import { Box, Grid, makeStyles } from '@material-ui/core'

import PropTypes from 'prop-types'
import PlanGoalEdit from '../PlanGoalEdit/'
import Skeleton from '../../../atoms/Skeleton'
import { useDynamicPlanning } from '../DynamicPlanningContext'
import Text from '../../../atoms/Text'
import { TEXT_VARIANTS } from '../../../../constants'
import GoalCategoryDetailItem from './DetailItem'

const useStyles = makeStyles(() => ({
  goalCategories: {
    marginTop: '2rem',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between'
  },
  loadingSkeletonContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between'
  },
  loadingSkeleton: {
    flexGrow: 1,
    minWidth: '300px',
    maxWidth: '360px',
    margin: '12px'
  },
  disabled: {
    opacity: 0.5,
    pointerEvents: 'none',
    cursor: 'not-allowed'
  }
}))

const GoalCategoryDetailList = ({
  allowToggle = true,
  planCreateContent = (<Text text='Create a plan at the top of the page to get started' variant={TEXT_VARIANTS.h3} />)
}) => {
  const classes = useStyles()
  const {
    currentEditGoalId,
    setCurrentEditGoalId,
    goalCategories,
    isLoadingCategories,
    currentPlan,
    isLoadingData
  } = useDynamicPlanning()
  const [currentEditing, setCurrentEditing] = useState(null)

  const onEditGoal = useCallback((goal) => {
    if (currentEditGoalId === goal.goalId) return
    setCurrentEditGoalId(goal.goalId)
  }, [currentEditGoalId, setCurrentEditGoalId])

  return (
    <div>
      {currentEditGoalId && (
        <PlanGoalEdit
          {...currentEditing}
          onClose={() => setCurrentEditing(null)}
        />
      )}

      <Grid container spacing={3} className={classes.goalCategories}>
        {isLoadingCategories || isLoadingData
          ? (
            <div className={classes.loadingSkeletonContainer}>
              <Skeleton height='200px' className={classes.loadingSkeleton} />
              <Skeleton height='200px' className={classes.loadingSkeleton} />
              <Skeleton height='200px' className={classes.loadingSkeleton} />
            </div>
          ) : typeof currentPlan?.planId === 'string'
            ? (
              <Box flex='1 0 0' textAlign='center'>
                {planCreateContent}
              </Box>
            )
            : goalCategories?.map((category) => (
              <GoalCategoryDetailItem
                key={'category-' + category.categoryId}
                goalCategory={category}
                onEditGoal={onEditGoal}
                allowToggle={allowToggle}
              />
            )
            )}
      </Grid>
    </div>
  )
}

GoalCategoryDetailList.propTypes = {
  allowToggle: PropTypes.bool,
  planCreateContent: PropTypes.string
}

export default GoalCategoryDetailList
