import { useEffect } from 'react'
import PropTypes from 'prop-types'

const getSlide = (index, itemsBySlide) => {
  if (index < itemsBySlide) return 0
  return index - itemsBySlide + 2
}

const CarouselItem = ({ children, selected, carousel, carouselIndex }) => {
  useEffect(() => {
    if (carousel && selected) {
      carousel.goToSlide(getSlide(carouselIndex, carousel.state.slidesToShow))
    }
  }, [selected, carousel, carouselIndex])

  return children
}

CarouselItem.propTypes = {
  children: PropTypes.any,
  selected: PropTypes.bool,
  carousel: PropTypes.shape({
    goToSlide: PropTypes.func,
    state: PropTypes.shape({
      slidesToShow: PropTypes.number
    })
  }),
  carouselIndex: PropTypes.number
}

CarouselItem.defaultProps = {
  children: null,
  selected: false,
  carousel: null,
  carouselIndex: 0
}

export default CarouselItem
