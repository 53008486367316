import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import NumberFormat from '../../../../atoms/NumberFormat'
import { numeralByCase } from '../../../../../utils'
import { useAppContext } from '../../../../../redux/slices/appContext'
import { useClientNetWorth } from '../../../../../api/coreData'
import ClientMetricContainer from './ClientMetricContainer'

const ClientNetWorthValue = ({ title, valueAccessor, filters }) => {
  const { clientId, availableDates } = useAppContext()
  const coreDataQuery = useMemo(
    () => ({
      asOfDate: availableDates.mainDate,
      clientId,
      netWorthGroupName: 'netWorth',
      memberGroupName: 'AccountCategory',
      ...filters
    }),
    [clientId, filters, availableDates.mainDate]
  )
  const { data, isLoading, error } = useClientNetWorth(coreDataQuery)
  const marketValue = useMemo(() => {
    if (isLoading || error) return null

    return data?.[valueAccessor] ?? null
  }, [isLoading, error, valueAccessor, data])
  if (error) return null

  return (
    <ClientMetricContainer title={title} isLoading={isLoading}>
      <NumberFormat
        title={numeralByCase(marketValue)}
        number={marketValue}
        format='($0,0a)'
      />
    </ClientMetricContainer>
  )
}

ClientNetWorthValue.propTypes = {
  title: PropTypes.string,
  valueAccessor: PropTypes.string,
  filters: PropTypes.object
}

ClientNetWorthValue.defaultProps = {
  title: 'Net Worth',
  valueAccessor: 'netWorth',
  filters: {}
}

export default ClientNetWorthValue
