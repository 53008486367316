import React, { useCallback, useRef } from 'react'
import { IconButton } from '@material-ui/core'
import { Link } from 'react-router-dom'
import SydLabel from '../../../../../commonDesign/SydLabel'
import Icon from '../../../../../atoms/Icon'
import SydReadOnly from '../../../../../commonDesign/SydReadOnly'
import { useAccountFormContext } from '../../AccountFormProvider'
import { usePolicy } from '../../../../../../hooks/usePolicy'
import AssignManagerDialog from './AssignManagerDialog'
import RemoveManagerDialog from './RemoveManagerDialog'

function ManagerInput () {
  const { account } = useAccountFormContext()
  const assignRef = useRef()
  const removeRef = useRef()
  const canEdit = usePolicy('admin_edit_acct_tags')
  const onEdit = useCallback(() => {
    if (account.managerId) {
      removeRef.current.open()
    } else {
      assignRef.current.open()
    }
  }, [account, assignRef, removeRef])

  return (
    <>
      <SydLabel label='Manager'>
        <div className='__control-group'>
          <SydReadOnly disabled iconName={account.managerId ? 'link' : null}>
            {account.managerId ? (
              <Link to={`/admin/managers/${account.managerId}`}>{account.manager?.name}</Link>
            ) : '--'}
          </SydReadOnly>
          {canEdit ? (
            <div>
              <IconButton onClick={onEdit}>
                <Icon customSize={20} name='edit' />
              </IconButton>
            </div>
          ) : null}
        </div>
      </SydLabel>
      <AssignManagerDialog ref={assignRef} />
      <RemoveManagerDialog ref={removeRef} />
    </>
  )
}

ManagerInput.propTypes = {
}

export default ManagerInput
