import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import OverrideDetail from './OverrideDetail'

function OverrideList ({ overrides, display }) {
  const [billable, nonBillable] = useMemo(() => {
    const b = (overrides || []).filter(x => !x.nonBillable)
    const n = (overrides || []).filter(x => !!x.nonBillable)

    return [b, n]
  }, [overrides])

  return (
    <div>
      {billable.length ? (
        <div>
          <div>Billable Overrides</div>
          <div>
            {billable.map(a => (
              <OverrideDetail key={a.feeLevelOverrideId} override={a}>
                {display(a)}
              </OverrideDetail>
            ))}
          </div>
        </div>
      ) : null}
      {nonBillable.length ? (
        <div>
          <div>Non-Billable Overrides</div>
          {nonBillable.map(a => (
            <OverrideDetail key={a.feeLevelOverrideId} override={a} lowlighted>
              {display(a)}
            </OverrideDetail>
          ))}
        </div>
      ) : null}
    </div>
  )
}

OverrideList.propTypes = {
  overrides: PropTypes.array,
  display: PropTypes.func
}

export default OverrideList
