import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useCustodians } from '../../../../api/coreData'
import CheckboxListFilter from '../FilterComponents/CheckboxListFilter'

const mapCustodianOptions = (data) => {
  return data.map(({ custodianId, custodianName }) => ({
    label: custodianName,
    value: custodianId
  }))
}

const CustodiansListFilter = ({ defaultValue }) => {
  const query = useMemo(() => ({ mapper: mapCustodianOptions }), [])
  const { data: custodians } = useCustodians(query)
  return (
    <CheckboxListFilter options={custodians} defaultValue={defaultValue} />
  )
}

CustodiansListFilter.propTypes = {
  defaultValue: PropTypes.object
}

CustodiansListFilter.defaultProps = {
  defaultValue: {}
}

export default CustodiansListFilter
