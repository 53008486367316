import { useMemo } from 'react'
import { orderBy } from 'lodash'
import { useAppContext } from '../../../../redux/slices/appContext'
import {
  useWealthMissionValueDefaults as useMissionValueDefaults,
  useWealthMissionValues as useMissionValues
} from '../../../../api/groups'

const useWealthMissionValueOptions = () => {
  const { clientId } = useAppContext()

  const { query, queryOptions } = useMemo(
    () => ({
      query: { levelType: 'clients', levelIds: [clientId] },
      queryOptions: {}
    }),
    [clientId]
  )

  const {
    data: wealthMissionValueDefaults = [],
    isLoading: isLoadingWealthMissionValueDefaults
  } = useMissionValueDefaults()

  const {
    data: wealthMissionValues = [],
    isLoading,
    refetch
  } = useMissionValues(query, queryOptions)

  const missionValues = useMemo(() => {
    const wealthValueDefaultIds = [
      ...new Set(
        wealthMissionValues.map(
          ({ wealthMissionValueDefaultId }) => wealthMissionValueDefaultId
        )
      )
    ]
    const wealthValueDefaults = wealthMissionValueDefaults.filter(
      ({ wealthMissionValueDefaultId }) =>
        !wealthValueDefaultIds.includes(wealthMissionValueDefaultId)
    )
    return orderBy([...wealthMissionValues, ...wealthValueDefaults], ['title'], ['asc'])
  }, [wealthMissionValueDefaults, wealthMissionValues])

  return {
    missionValues,
    wealthMissionValues,
    wealthMissionValueDefaults,
    refetchWealthMissionValues: refetch,
    isLoading: isLoading || isLoadingWealthMissionValueDefaults
  }
}

export default useWealthMissionValueOptions
