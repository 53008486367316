import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { makeStyles, useTheme } from '@material-ui/core'
import clsx from 'clsx'
import { useFormattingContext } from '../../FormattingProvider/FormattingContext'
import Skeleton from '../../../atoms/Skeleton'
import Tooltip from '../../../atoms/Tooltip'
import RelativeLink from '../../../atoms/RelativeLink'
import MetricWrapper from './MetricWrapper'

const useStyles = makeStyles((theme) => ({
  metricDisplay: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '1rem 2rem',
    '&:hover': {
      borderRadius: '0.25rem',
      backgroundColor: '#F5F6F7'
    },
    '& .__header': {
      fontSize: '0.875rem',
      fontWeight: 'bold'
    },
    '& .__title-container': {
      display: 'flex',
      flexDirection: 'row'
    },
    '& .__title': {
      padding: '1px',
      color: theme.palette.primary.light
    },
    '& .__value': {
      fontSize: ({ valueSize }) => valueSize || '3.5rem'
    },
    '& .__description': {
      color: '#212945',
      fontSize: '1rem',
      fontWeight: 300
    }
  },
  clickable: {
    cursor: 'pointer'
  },
  link: {
    textDecoration: 'none !important',
    '& > *': {
      textDecoration: 'none !important'
    }
  }
}))

const getValueWithSymbol = ({
  value,
  valueFormatted,
  neutralColor,
  positiveColor,
  negativeColor
}) => {
  let valueColor = neutralColor
  let prefixSymbol = ''

  if (value !== 0) {
    const isValueGreaterThanZero = value > 0
    valueColor = isValueGreaterThanZero ? positiveColor : negativeColor
    prefixSymbol = isValueGreaterThanZero ? '+' : ''
  }
  return [`${prefixSymbol}${valueFormatted}`, valueColor]
}

const Metric = ({
  title,
  value,
  header,
  isLoading,
  valueFormat,
  valueSize,
  valueColor,
  description,
  includeSymbol,
  tooltipTitle,
  redirectTo
}) => {
  const classes = useStyles({ valueSize })
  const { formatter } = useFormattingContext()

  const formattedValue = useMemo(
    () => {
      if (valueFormat === 'human') {
        let formatPass = '0.0a'
        if (value >= 100000000 || (value >= 20000 && value < 1000000) || value < 1000) {
          formatPass = '0a'
        }
        return formatter(value, formatPass)
      }
      return formatter(value, valueFormat)
    },
    [formatter, value, valueFormat]
  )

  const theme = useTheme()

  const [valueFormatted, _valueColor] = useMemo(() => {
    if (!includeSymbol) return [formattedValue, theme.palette.black]

    return getValueWithSymbol({
      value,
      valueFormatted: formattedValue,
      neutralColor: theme.palette.black,
      positiveColor: theme.palette.success.A500,
      negativeColor: theme.palette.error.A500
    })
  }, [
    value,
    includeSymbol,
    formattedValue,
    theme.palette.black,
    theme.palette.error.A500,
    theme.palette.success.A500
  ])

  const metric = useMemo(() => {
    return (
      <>
        {header && <div className='__header'>{header}</div>}
        <div className='__title-container'>
          {isLoading ? (
            <Skeleton height={valueSize} width='100%' />
          ) : (
            <div
              className='__value'
              style={{ color: valueColor || _valueColor }}
            >
              {valueFormatted}
            </div>
          )}
          {title && <div className='__title'>{title}</div>}
        </div>
        {description && <div className='__description'>{description}</div>}
      </>
    )
  }, [
    _valueColor,
    title,
    header,
    valueSize,
    isLoading,
    valueColor,
    description,
    valueFormatted
  ])

  const classNames = useMemo(() => {
    return clsx(
      classes.link,
      classes.metricDisplay,
      { [classes.clickable]: Boolean(redirectTo) }
    )
  }, [classes.clickable, classes.link, classes.metricDisplay, redirectTo])

  return (
    <Tooltip title={tooltipTitle}>
      <RelativeLink className={classNames} to={redirectTo}>
        {metric}
      </RelativeLink>
    </Tooltip>
  )
}

Metric.propTypes = {
  header: PropTypes.string,
  title: PropTypes.string,
  value: PropTypes.number,
  isLoading: PropTypes.bool,
  valueColor: PropTypes.string,
  valueFormat: PropTypes.string,
  description: PropTypes.string,
  valueSize: PropTypes.string,
  includeSymbol: PropTypes.bool,
  tooltipTitle: PropTypes.string,
  redirectTo: PropTypes.string
}

Metric.defaultProps = {
  header: undefined,
  valueSize: '3rem',
  valueColor: undefined,
  includeSymbol: false,
  isLoading: false,
  tooltipTitle: undefined,
  redirectTo: undefined
}

Metric.Wrapper = MetricWrapper

export default Metric
