import React from 'react'
import PropTypes from 'prop-types'
import OperationalTable, { useOperationalTable } from '../../../organisms/OperationalTable'

const columnConfig = {
  columns: [
    {
      Header: 'Field Name',
      accessor: 'key',
      id: 'key'
    },
    {
      Header: 'Datatype',
      accessor: 'type',
      id: 'type'
    },
    {
      Header: 'Sample Value',
      accessor: 'sample',
      id: 'sample',
      Cell: ({ value }) => value?.toString() ?? '<null>'
    }
  ],
  defaultSort: [
    { id: 'key', desc: false }
  ]
}

const SourceSchemaTable = ({ schema }) => {
  const {
    // defaultPageSize,
    // pageIndex,
    // pageSize,
    // sort,
    // onPagingChange,
    onSortingChange
  } = useOperationalTable(columnConfig.defaultSort)

  return (
    <OperationalTable.Wrapper>
      <OperationalTable
        mode='client'
        columns={columnConfig.columns}
        defaultSort={columnConfig.defaultSort}
        data={schema}
        itemName='Fields'
        hideFooter
        onSortingChange={onSortingChange}
        variant='v2'
      />
    </OperationalTable.Wrapper>
  )
}

SourceSchemaTable.propTypes = {
  schema: PropTypes.array
}

export default SourceSchemaTable
