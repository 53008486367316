import { useMemo, useState } from 'react'
import isEmpty from 'lodash/isEmpty'
import { DEFAULT_SELECT_SEARCH_PAGINATION, MILLISECONDS_BEFORE_SEARCH } from '../constants'
import { useSearchPositions } from '../api/positions'
import useDebouncedValue from './useDebounceValue'
import { getSearchQuery } from './helpers'

const SEARCH_FIELDS = [
  'symbol',
  'cusip',
  'isin',
  'uniqueIdentifier',
  'summitIdentifier',
  'assetIdentifier'
]

const mapPositions = (positions = []) => {
  return positions.map((position) => ({
    label: position.assetIdentifier,
    value: position.positionId,
    payload: {
      ...position
    }
  }))
}

const getQueryFilters = ({
  query,
  levelId,
  levelType = 'account',
  positionId,
  excludeLevelIds
}) => {
  const levelFilters = { levelType, filters: {} }
  if (query) {
    levelFilters.textSearch = getSearchQuery(SEARCH_FIELDS, query)
  }
  if (!isEmpty(excludeLevelIds)) {
    levelFilters.filters.positionId = [{ op: 'notIn', value: excludeLevelIds }]
  }
  if (positionId) {
    levelFilters.filters.positionId = [{ op: typeof positionId === 'object' ? 'in' : 'eq', value: positionId }]
    if (Array.isArray(positionId)) {
      levelFilters.take = positionId?.length
    }
  }

  if (levelId) {
    levelFilters.levelId = levelId
  }
  return levelFilters
}

const useSearchPositionsDebounced = ({
  defaultPositionId,
  queryOptions: searchQueryOptions = {},
  debounceDelay = MILLISECONDS_BEFORE_SEARCH
}) => {
  const [query, setQuery] = useState('')
  const [defaultId] = useState(defaultPositionId)
  const debouncedQuery = useDebouncedValue(query, debounceDelay)

  const { queryParams, queryOptions } = useMemo(() => {
    const { filters, ...levelFilters } = getQueryFilters({
      ...searchQueryOptions,
      query: debouncedQuery
    })
    return {
      queryParams: {
        levelFilters: {
          ...DEFAULT_SELECT_SEARCH_PAGINATION,
          sort: [
            {
              field: 'accountNumber',
              dir: 'desc'
            }
          ],
          ...levelFilters,
          filters
        }
      },
      queryOptions: {
        mapper: mapPositions
      }
    }
  }, [debouncedQuery, searchQueryOptions])

  const { data, isLoading } = useSearchPositions(queryParams, queryOptions)

  const { query: defaultsQuery, queryOptions: defaultQueryOptions } =
    useMemo(() => {
      if (!defaultId) {
        return {
          query: {},
          queryOptions: { enabled: false }
        }
      }
      const { filters, ...levelFilters } = getQueryFilters({
        positionId: defaultId,
        ...searchQueryOptions
      })
      const defaultsQuery = {
        levelFilters: {
          ...DEFAULT_SELECT_SEARCH_PAGINATION,
          ...levelFilters,
          filters
        }
      }
      return {
        query: defaultsQuery,
        queryOptions: {
          enabled: true,
          mapper: mapPositions
        }
      }
    }, [defaultId, searchQueryOptions])

  const { data: defaultOptions, isLoading: isLoadingDefaultOptions } =
    useSearchPositions(defaultsQuery, defaultQueryOptions)

  const isTransitioningOptions = debouncedQuery !== query || isLoading
  const isTransitioningDefaultOptions =
    isLoadingDefaultOptions && defaultQueryOptions.enabled

  return {
    options: data,
    onChangeQuery: setQuery,
    defaultOptions: defaultOptions,
    shouldFetchDefaultOptions: defaultQueryOptions.enabled,
    isSearchLoading: isTransitioningOptions,
    isLoading: isLoading || isTransitioningDefaultOptions
  }
}

export default useSearchPositionsDebounced
