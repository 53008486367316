import React from 'react'
import BasicChartTooltip from '../../atoms/BasicChartTooltip'

export const getBarChartBaseConfig = ({
  color,
  formatter,
  chartProps,
  dataSetName,
  tooltipFormat,
  axisLeftFormat
}) => ({
  keys: ['value'],
  indexBy: 'label',
  margin: { top: 50, right: 130, bottom: 50, left: 60 },
  padding: 0.2,
  groupMode: 'grouped',
  valueScale: { type: 'linear' },
  indexScale: { type: 'band', round: true },
  colors: [color],
  axisTop: null,
  axisRight: null,
  enableLabel: false,
  axisBottom: {
    tickSize: 5,
    tickPadding: 5,
    tickRotation: 0,
    legend: '',
    legendPosition: 'middle',
    legendOffset: 32
  },
  tooltip: ({ value, color }) => {
    const label = dataSetName
      ? `${dataSetName} ${formatter(value, tooltipFormat)}`
      : formatter(value, tooltipFormat)
    return <BasicChartTooltip label={label} color={color} />
  },
  axisLeft: {
    tickValues: 5,
    tickSize: 5,
    tickPadding: 5,
    tickRotation: 0,
    legend: '',
    legendPosition: 'middle',
    legendOffset: -40,
    format: (value) => {
      return formatter(value, axisLeftFormat)
    },
    ...(chartProps?.axisLeft || {})
  },
  labelSkipWidth: 12,
  labelSkipHeight: 12
})
