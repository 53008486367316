import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import AddIcon from '@material-ui/icons/Add'
import LinkIcon from '@material-ui/icons/Link'
import RoundedButton from '../../atoms/RoundedButton'
import { usePolicy } from '../../../hooks/usePolicy'
import FadeIn from '../../molecules/Transitions/FadeIn'
import { useComments } from './CommentContext'

const useStyles = makeStyles((theme) => ({
  addAttachmentSection: {
    backgroundColor: theme.palette.white,
    position: 'sticky',
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    padding: '24px',
    gap: '15px'
  }
}))

function AddAttachment ({ visible }) {
  const classes = useStyles()
  const enabled = usePolicy('attachments_add')
  const { addAttachment, linkAttachment, attaching } = useComments()

  if (enabled && !attaching && visible) {
    return (
      <FadeIn className={classes.addAttachmentSection}>
        <RoundedButton
          startIcon={<AddIcon />}
          onClick={addAttachment}
          primary
        >
          Add Documents
        </RoundedButton>
        <RoundedButton
          startIcon={<LinkIcon />}
          onClick={linkAttachment}
          secondary
        >
          Find Documents
        </RoundedButton>
      </FadeIn>
    )
  }

  return null
}

AddAttachment.propTypes = {
  visible: PropTypes.bool
}

AddAttachment.defaultProps = {
  visible: true
}

export default AddAttachment
