import React from 'react'
import PropTypes from 'prop-types'
import SydSelect from '../../../../../../commonDesign/SydSelect'

const StatusFilter = ({
  onChange,
  value
}) => {
  const statusOptions = [
    { label: 'Has Fee Adjustments', value: 'feeAdjustmentId' }
  ]

  return (
    <SydSelect
      size='sm'
      placeholder='All clients'
      options={statusOptions}
      value={value}
      multiple
      onChange={(e) => {
        onChange(e.target.value)
      }}
    />
  )
}

StatusFilter.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.array
}

export default StatusFilter
