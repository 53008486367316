import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'
import clsx from 'clsx'

const useStyles = makeStyles(theme => ({
  pageContainer: {
    padding: '1rem 2rem 0',
    animation: '$fadeIn .25s linear'
  },
  '@keyframes fadeIn': {
    '0%': {
      opacity: 0
    },
    '100%': {
      opacity: 1
    }
  }
}))

const PageContainer = ({ children, className }) => {
  const classes = useStyles()
  return (
    <Grid container spacing={1} className={clsx(className, classes.pageContainer)}>
      {children}
    </Grid>
  )
}

PageContainer.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
}

export default PageContainer
