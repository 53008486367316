import React, { useCallback, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { Box, Grid } from '@material-ui/core'
import numeral from 'numeral'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { useBillingRunDetails, useFeeAdjustment } from '../../../../../../../api/billing'
import SydModal from '../../../../../../commonDesign/SydModal'
import Skeleton from '../../../../../../atoms/Skeleton'
import SydInput from '../../../../../../commonDesign/SydInput'
import SydLabel, { hookFormErrorAdapter } from '../../../../../../commonDesign/SydLabel'
import SydButton from '../../../../../../commonDesign/Button'
import { postNamedCommand } from '../../../../../../../service'
import Alert from '../../../../../../atoms/Alert'

dayjs.extend(utc)

const defaultFormValues = {
  adjustmentValue: '',
  adjustmentType: 'FIXED',
  adjustmentReason: ''
}

const FeeAdjustmentEditModal = ({
  onClose = noop,
  onSuccess = noop,
  title = 'Fee Adjustment',
  feeAdjustmentId,
  billingRunId,
  accountId = null,
  billingRunRecordId = null
}) => {
  const hasValidParams = useMemo(() => !!accountId && !!billingRunRecordId, [accountId, billingRunRecordId])
  const [originalFee, setOriginalFee] = useState(null)

  const { data, isFetching } = useFeeAdjustment({ feeAdjustmentId }, {
    enabled: !!feeAdjustmentId,
    staleTime: 0,
    select: (data) => data.body ?? null,
    onSuccess: (data) => {
      if (!data) {
        formMethods.reset({
          ...data,
          adjustmentValue: data?.adjustmentValue ? numeral(data?.adjustmentValue).format('0,0.00') : ''
        })
        return
      }
      formMethods.reset({
        ...data,
        adjustmentValue: data?.adjustmentValue ? numeral(data?.adjustmentValue).format('0,0.00') : ''
      })
      if (data?.preFeeAdjustmentAccountFee) {
        setOriginalFee(data?.preFeeAdjustmentAccountFee)
      }
    }
  })

  const { isFetching: isFetchingBillingRunRecord } = useBillingRunDetails({
    billingRunId,
    filters: {
      billingRunRecordId: [{ op: 'eq', value: billingRunRecordId, combine: 'AND' }],
      accountId: [{ op: 'eq', value: accountId, combine: 'AND' }]
    },
    take: 1,
    skip: 0
  }, {
    enabled: !feeAdjustmentId && !!accountId && !data?.length && !isFetching,
    staleTime: 0,
    select: (data) => {
      return data?.details?.[0] ?? null
    },
    onSuccess: (data) => {
      if (data?.accountFee) {
        setOriginalFee(data?.accountFee)
      }
    },
    onError: () => {
      formMethods.reset(defaultFormValues)
      formMethods.setError('root', { message: 'Error fetching fee adjustment.  Try again' })
    }
  })

  const isLoading = useMemo(() => isFetchingBillingRunRecord || isFetching, [isFetching, isFetchingBillingRunRecord])

  const formMethods = useForm({
    defaultValues: defaultFormValues,
    reValidateMode: 'onSubmit'
  })

  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
    watch
  } = formMethods

  const adjustmentValue = watch('adjustmentValue')
  const currentFee = useMemo(() => {
    return Number(originalFee) + Number(adjustmentValue)
  }, [adjustmentValue, originalFee])

  const onSubmit = async (data) => {
    try {
      formMethods.clearErrors()
      const { data: { statusCode } } = await postNamedCommand('billing', 'create-fee-adjustment', {
        ...data,
        adjustmentValue: numeral(data.adjustmentValue).value(),
        accountId,
        billingRunRecordId
      })
      if (statusCode > 200) {
        throw new Error('Error submitting command')
      }

      onSuccess(billingRunRecordId)
      onClose()

      formMethods.reset(defaultFormValues)
    } catch (err) {
      formMethods.setError('root', { message: 'Issue submitting fee adjustment.  Try again' })
    }
  }

  const handleOnClose = useCallback(() => {
    formMethods.reset({
      adjustmentValue: ''
    })
    onClose()
  }, [formMethods, onClose])

  const validateBalance = useCallback((value, invalidResponse) => {
    return !numeral(value).value() ? (invalidResponse ?? 'You must enter in a valid value') : true
  }, [])

  const subtitle = useMemo(() => {
    if (!data || data.updatedAt === data.createdAt) {
      return null
    }
    return `Last updated by ${data.createdByFirstName} ${data.createdByLastName} at ${dayjs.utc(data.createdAt).local().format('MM/DD/YYYY h:mma')}`
  }, [data])

  return (
    <SydModal
      title={title}
      subtitle={subtitle}
      onClose={handleOnClose}
      open={hasValidParams}
      size='xmedium'
    >
      {isLoading ? (
        <>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <Skeleton variant='text' height='60px' />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Skeleton variant='text' height='60px' />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Skeleton variant='text' height='60px' />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Skeleton variant='text' height='60px' />
            </Grid>
          </Grid>
          <Box display='flex' justifyContent='space-between' mt='20px'>
            <Box width='100px'>
              <Skeleton height='45px' />
            </Box>
            <Box width='100px'>
              <Skeleton height='45px' />
            </Box>
          </Box>
        </>
      ) : (
        <>
          <FormProvider {...formMethods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              {errors.root?.message?.length && (
                <Box mb={4}>
                  <Alert
                    severity='error'
                  >
                    {errors.root?.message}
                  </Alert>
                </Box>
              )}
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <SydLabel label='Original Fee'>
                    <SydInput
                      value={numeral(originalFee).format('0,000.00')}
                      type='input'
                      placeholder='Loading...'
                      disabled
                    />
                  </SydLabel>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Controller
                    control={control}
                    name='adjustmentValue'
                    rules={{
                      required: 'Adjustment Value is required',
                      validate: (val) => validateBalance(val, 'Adjustment value must be a valid amount')
                    }}
                    render={({ field, fieldState }) => (
                      <SydLabel
                        label='Adjustment Value'
                        required
                        error={hookFormErrorAdapter(formMethods, fieldState)}
                      >
                        <SydInput
                          {...field}
                          disabled={isSubmitting}
                          type='input'
                          placeholder='Enter Adjustment Value'
                          error={hookFormErrorAdapter(formMethods, fieldState)}
                        />
                      </SydLabel>
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <SydLabel label='New Fee'>
                    <SydInput
                      value={numeral(currentFee).format('0,000.00')}
                      type='input'
                      placeholder='Loading...'
                      disabled
                    />
                  </SydLabel>
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    control={control}
                    name='adjustmentReason'
                    rules={{
                      required: 'Adjustment Reason is required',
                      validate: (val) => !!val.trim().length || 'Adjustment Reason is required'
                    }}
                    render={({ field, fieldState }) => (
                      <SydLabel
                        label='Adjustment Reason'
                        required
                        error={hookFormErrorAdapter(formMethods, fieldState)}
                      >
                        <SydInput
                          {...field}
                          disabled={isSubmitting}
                          type='input'
                          placeholder='Enter reason for adjustment'
                          error={hookFormErrorAdapter(formMethods, fieldState)}
                        />
                      </SydLabel>
                    )}
                  />
                </Grid>
              </Grid>
              <Box display='flex' justifyContent='space-between' width='100%' mt={4}>
                <SydButton
                  variant='secondary'
                  onClick={handleOnClose}
                  disabled={isSubmitting}
                >
                  Cancel
                </SydButton>
                <SydButton
                  variant='primary'
                  onClick={(e) => {
                    formMethods.clearErrors('root')
                    handleSubmit(onSubmit)(e)
                  }}
                  type='submit'
                  disabled={isSubmitting}
                >
                  Save
                </SydButton>
              </Box>
            </form>
          </FormProvider>
        </>
      )}

    </SydModal>
  )
}

FeeAdjustmentEditModal.propTypes = {
  title: PropTypes.string,
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
  feeAdjustmentId: PropTypes.number,
  accountId: PropTypes.number,
  billingRunId: PropTypes.number,
  billingRunRecordId: PropTypes.number
}

export default FeeAdjustmentEditModal
