import React from 'react'
import PropTypes from 'prop-types'
import SalesForceButton from '../ClientIntegrations/SalesForceButton'
import WealthBoxButton from '../ClientIntegrations/WealthBoxButton'
import RedTailButton from '../ClientIntegrations/RedTailButton'
import PractifiButton from '../ClientIntegrations/PractifiButton'

const ClientCardIntegrationButtons = ({
  clientId,
  disabled,
  showSalesforceClientInfo,
  showWealthBoxClientInfo,
  showRedTailClientInfo,
  showPractifiClientInfo
}) => {
  return (
    <>
      {showSalesforceClientInfo && (
        <SalesForceButton clientSalesforceId={clientId} disabled={disabled} />
      )}
      {showWealthBoxClientInfo && (
        <WealthBoxButton clientId={clientId} disabled={disabled} />
      )}
      {showRedTailClientInfo && (
        <RedTailButton clientId={clientId} disabled={disabled} />
      )}
      {showPractifiClientInfo ? (
        <PractifiButton clientSalesforceId={clientId} disabled={disabled} />
      ) : null}
    </>
  )
}

ClientCardIntegrationButtons.propTypes = {
  disabled: PropTypes.bool,
  clientId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  showSalesforceClientInfo: PropTypes.bool,
  showWealthBoxClientInfo: PropTypes.bool,
  showRedTailClientInfo: PropTypes.bool,
  showPractifiClientInfo: PropTypes.bool
}

export default ClientCardIntegrationButtons
