import React, { forwardRef, useEffect, useImperativeHandle } from 'react'
import PropTypes from 'prop-types'
import { makeStyles, Tooltip } from '@material-ui/core'
import { useForm } from 'react-hook-form'
import Icon from '../../../../atoms/Icon'
import KdpInput from './KdpInput'

const useStyles = makeStyles((theme) => ({
  tagDisplay: {
    '& th': {
      backgroundColor: theme.palette.gray.main,
      padding: '10px'
    },
    '& td': {
      padding: '10px'
    },
    '& .__not-assigned': {
      color: theme.palette.gray.darker
    },
    '& .__remove-button': {
      textTransform: 'none'
    },
    '& .__select-option': {
      minWidth: '300px'
    },
    '& .__select-group': {
      display: 'flex',
      flexDirection: 'row',
      gap: '20px'
    }
  },
  code: {
    fontSize: theme.typography.fontSizes.md,
    fontWeight: theme.typography.weights.light
  },
  groupType: {
    fontSize: theme.typography.fontSizes.xl,
    fontWeight: theme.typography.weights.semibold
  },
  kdpLabel: {
    display: 'flex',
    flexDirection: 'row',
    gap: '10px',
    alignItems: 'center'
  },
  groupName: {
    fontSize: theme.typography.fontSizes.xl,
    fontWeight: theme.typography.weights.light
  }
}))

const KdpDisplay = forwardRef(function KdpDisplay ({ datapoints, schema, editing }, ref) {
  const classes = useStyles()
  const form = useForm({
    mode: 'onChange',
    shouldUnregister: true,
    defaultValues: datapoints.reduce((agg, c) => {
      const si = schema.find(x => x.codeName === c.codeName)
      switch (si.configurationJson?.type) {
        case 'string':
        case 'text':
        case 'list':
        case 'integer':
        case 'fixed':
        case 'number':
          agg[c.codeName] = [null, undefined].includes(c.value) ? '' : c.value
          break
        case 'bool':
          agg[c.codeName] = c.value || false
          break
        case 'json':
          agg[c.codeName] = c.value ? JSON.stringify(c.value) : ''
          break
        case 'date':
        case 'datetime':
          agg[c.codeName] = c.value || null
          break
        default:
          agg[c.codeName] = c.value || ''
          break
      }
      return agg
    }, {})
  })

  useEffect(() => {
    if (!editing) {
      form.reset()
    }
  }, [editing, form])

  useImperativeHandle(ref, () => {
    return {
      form
    }
  }, [form])

  return (
    <table className={classes.tagDisplay}>
      <thead>
        <tr>
          <th>Kdp Name/Code</th>
          <th>Kdp Value</th>
        </tr>
      </thead>
      <tbody>
        {(schema.filter(x => !x.hidden)).map((kdp, i) => (
          <tr className={classes.group} key={kdp.codeName}>
            <td className={classes.groupType}>
              <div className={classes.kdpLabel}>
                <div>
                  <div className={classes.name}>{kdp.displayName}</div>
                  <div className={classes.code}>{kdp.codeName}</div>
                </div>
                <div>
                  {kdp.automated ? (
                    <Tooltip placement='top' title='The value of this datapoint is automated and may be overwritten'>
                      <span>
                        <Icon name='info' customSize={20} />
                      </span>
                    </Tooltip>
                  ) : null}
                </div>
              </div>
            </td>
            <td className={classes.groupName}>
              <KdpInput
                name={kdp.codeName}
                control={form.control}
                datapoint={kdp}
                disabled={!editing || !!kdp.readonly}
              />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  )
})

KdpDisplay.propTypes = {
  datapoints: PropTypes.array,
  schema: PropTypes.array,
  editing: PropTypes.bool
}

KdpDisplay.defaultProps = {
  datapoints: [],
  schema: [],
  editing: false
}

export default KdpDisplay
