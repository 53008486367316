import { useQuery } from '@tanstack/react-query'
import { useMemo } from 'react'
import { fetchCustomDataset } from '../service'
import { useAppContext } from '../redux/slices/appContext'

export const useSalesforceTasks = (query, options) => {
  const { userId } = useAppContext()

  const functionName = useMemo(() => options?.functionName ?? 'SalesforceOpenTasks', [options?.functionName])

  const queryKey = useMemo(() => {
    return [userId, functionName, query?.advisorId, query?.startDate, query?.endDate]
  }, [userId, functionName, query?.advisorId, query?.startDate, query?.endDate])

  const queryResult = useQuery({
    queryKey,
    queryFn: async () => {
      const { data } = await fetchCustomDataset('custom', {
        levelFilters: {
          functionName,
          parameters: query
        }
      })

      return data[options?.index ?? 0]
    },
    enabled: options?.enabled
  })

  return queryResult
}

export const useSalesforceMeetings = (query, options) => {
  const { userId } = useAppContext()
  const functionName = useMemo(() => options?.functionName ?? 'SalesforceMeetings', [options?.functionName])
  const queryKey = useMemo(() => {
    return [userId, functionName, query?.advisorId, query?.startDate, query?.endDate]
  }, [userId, functionName, query?.advisorId, query?.startDate, query?.endDate])
  const queryResult = useQuery({
    queryKey,
    queryFn: async () => {
      const { data } = await fetchCustomDataset('custom', {
        levelFilters: {
          functionName,
          parameters: query
        }
      })

      return data[options?.index ?? 0]
    }
  })

  return queryResult
}
