import React, { useMemo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import dayjs from 'dayjs'
import FastAvatar from '../../../molecules/FastAvatar'
import FadeIn from '../../../molecules/Transitions/FadeIn'

const useStyles = makeStyles((theme) => ({
  card: {
    position: 'relative',
    textDecoration: 'none !important',
    width: '210px',
    height: '160px',
    padding: '25px .5rem',
    borderRadius: '10px',
    backgroundColor: theme.palette.gray.lighter,
    outline: `.5px solid ${theme.palette.gray.dark}`,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    transition: 'outline .3s ease-in-out, background-color .3s ease-in-out',

    '&:hover': {
      outline: `2.5px solid ${theme.palette.summitBlue}`,
      backgroundColor: theme.palette.white
    }
  },
  client: {
    fontSize: '16px',
    color: theme.palette.summitBlue,
    margin: '10px 0 0'
  },
  model: {
    color: theme.palette.gray.A500
  },
  rebalanceDate: {
    position: 'absolute',
    top: '140px',
    // right: 0,
    gap: '3px',
    color: theme.palette.gray.A500,
    display: 'flex',
    flexDirection: 'row',
    padding: '0 7px'
  }
}))

function ClientModelCard ({ index, data }) {
  const classes = useStyles()
  const lastRebalance = useMemo(() => {
    if (!data.lastRebalanceDate) return null
    return dayjs.utc(data.lastRebalanceDate).format('M/D/YY')
  }, [data.lastRebalanceDate])
  const to = useMemo(() => {
    return `/rebalancer/start/workflow?clientId=${data.clientId}`
  }, [data.clientId])
  return (
    <FadeIn delay={index * 0.01}>
      <Link to={to} className={classes.card}>
        <FastAvatar
          avatarUrl={data.avatarUrl}
          avatarType={data.avatarType}
          abbreviation={data.clientAbbreviation}
        />
        <div className={classes.client}>{data.clientLongName}</div>
        <div className={classes.model}>{data.modelName}</div>
        {lastRebalance ? (
          <div className={classes.rebalanceDate}>
            <span>Last Run</span>
            <span>@</span>
            <span>{lastRebalance}</span>
          </div>
        ) : (
          <div className={classes.rebalanceDate} />
        )}
      </Link>
    </FadeIn>
  )
}

ClientModelCard.propTypes = {
  index: PropTypes.number,
  data: PropTypes.shape({
    clientId: PropTypes.number,
    modelName: PropTypes.string,
    clientLongName: PropTypes.string,
    lastRebalanceDate: PropTypes.string,
    avatarUrl: PropTypes.string,
    avatarType: PropTypes.oneOf(['media', 'override', 'resource', 'public']),
    clientAbbreviation: PropTypes.string
  })
}

ClientModelCard.defaultProps = {
  index: 0
}

export default ClientModelCard
