import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import AdvisorHomeItem from './Item'
import AdvisorHomeLinkItem from './LinkItem'

const useStyles = makeStyles(() => ({
  listContainer: {
    padding: '0.5rem',
    display: 'flex',
    flexDirection: 'column',
    gap: '0.5rem',
    borderRadius: '0.75rem'
  }
}))

const AdvisorHomeList = ({ children }) => {
  const classes = useStyles()
  return (
    <div className={classes.listContainer}>
      {children}
    </div>
  )
}

AdvisorHomeList.propTypes = {
  children: PropTypes.node
}

AdvisorHomeList.Item = AdvisorHomeItem
AdvisorHomeList.LinkItem = AdvisorHomeLinkItem

export default AdvisorHomeList
