import React from 'react'
import { makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import FadeIn from '../../../../molecules/Transitions/FadeIn'
import PersonalSpace from '../../shared/PersonalSpace'
import AccountInformation from './AccountInformation'
import AccountTags from './AccountTags'

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%'
  },
  sections: {
    display: 'flex',
    flexDirection: 'column',
    gap: '30px'
  }
}))

const AccountGeneralInfo = ({
  account,
  loading
}) => {
  const classes = useStyles()

  return (
    <FadeIn className={classes.container}>
      <div className={classes.sections}>
        <AccountInformation account={account} />
        <AccountTags loading={loading} />
      </div>
      <PersonalSpace />
    </FadeIn>
  )
}

AccountGeneralInfo.propTypes = {
  account: PropTypes.object,
  loading: PropTypes.bool
}

export default AccountGeneralInfo
