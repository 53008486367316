import PropTypes from 'prop-types'
import { useAppContext } from '../../redux/slices/appContext'

const AdvisorOnlyContent = ({ children }) => {
  const { isAdvisor } = useAppContext()

  if (isAdvisor) {
    return typeof children === 'function' ? children() : children
  }

  return null
}

AdvisorOnlyContent.propTypes = {
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]).isRequired
}

export default AdvisorOnlyContent
