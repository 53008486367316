import React from 'react'
import PropTypes from 'prop-types'
import FadeIn from '../../../molecules/Transitions/FadeIn'
import PlatformDataSources from '../PlatformDataSources'
import DataOut from '../DataOut'
import Denali from '../Denali'
import IndexViewTabs from './IndexViewTabs'

function DataHomeIndexView ({ root }) {
  return (
    <FadeIn>
      <IndexViewTabs
        root={root}
        dataInTab={<PlatformDataSources root={root} />}
        dataOutTab={<DataOut />}
        denaliTab={<Denali />}
      />
    </FadeIn>
  )
}

DataHomeIndexView.propTypes = {
  root: PropTypes.any
}

export default DataHomeIndexView
