import { useCallback, useReducer, useEffect } from 'react'
import keyMirror from 'keymirror'
import isEmpty from 'lodash/isEmpty'
import { insertDataAtIndex, updateRowByIndex } from '../utils'

const DEFAULT_INSERT_COLUMNS_AT = { rows: [], insertAtIndex: -1 }

const ACTIONS = keyMirror({
  SET_IS_LOADING: null,
  SET_ROWS: null,
  SET_COLUMNS: null,
  SET_FINAL_ROW: null,
  MODIFY_COLUMNS: null,
  RECURSIVELY_UPDATE_ROW_CONFIG_BY_INDEX: null
})

const reducer = (prevState, { type: action, payload }) => {
  switch (action) {
    case ACTIONS.SET_IS_LOADING: {
      const { isLoading } = payload
      return { ...prevState, isLoading }
    }
    case ACTIONS.SET_ROWS: {
      const { rowsFormatter, insertColumnsAt } = prevState
      const { rows } = payload

      const { rows: extraRows, insertAtIndex: rowIndex } = insertColumnsAt

      const rowsHydrated = insertDataAtIndex(rows, extraRows, rowIndex)
      const rowsFormatted = rowsFormatter
        ? rowsFormatter(rowsHydrated)
        : rowsHydrated

      return {
        ...prevState,
        rows: rowsFormatted
      }
    }
    case ACTIONS.SET_FINAL_ROW: {
      const { insertColumnsAt, rowsFormatter } = prevState
      const { finalRow } = payload

      const { finalRow: finalRowExtras, insertAtIndex: finalRowIndex } =
        insertColumnsAt

      if (isEmpty(finalRow)) {
        return prevState
      }
      const finalRowHydrated = insertDataAtIndex(
        finalRow,
        finalRowExtras,
        finalRowIndex
      )
      const finalRowFormatted = rowsFormatter
        ? rowsFormatter(finalRowHydrated)
        : finalRowHydrated

      return {
        ...prevState,
        finalRow: finalRowFormatted
      }
    }
    case ACTIONS.SET_COLUMNS: {
      const { insertColumnsAt } = prevState
      const { columns } = payload
      if (isEmpty(columns)) {
        return prevState
      }
      const { columns: extraColumns, insertAtIndex: index } = insertColumnsAt
      const columnsHydrated = insertDataAtIndex(columns, extraColumns, index)

      return {
        ...prevState,
        columns: columnsHydrated
      }
    }
    case ACTIONS.MODIFY_COLUMNS: {
      const { insertionCriteria } = payload
      const { columns } = prevState
      const columnsHydrated = insertionCriteria(columns)

      if (isEmpty(columnsHydrated)) {
        return prevState
      }
      return {
        ...prevState,
        columns: columnsHydrated
      }
    }
    case ACTIONS.RECURSIVELY_UPDATE_ROW_CONFIG_BY_INDEX: {
      const { rowIndexes, rowConfig } = payload
      return {
        ...prevState,
        rows: updateRowByIndex(prevState.rows, rowIndexes, rowConfig)
      }
    }
    default: {
      return prevState
    }
  }
}

const useExpandibleTable = ({
  rows = [],
  columns = [],
  finalRow = [],
  rowsFormatter,
  insertColumnsAt = DEFAULT_INSERT_COLUMNS_AT
}) => {
  const [state, dispatch] = useReducer(reducer, {
    rows,
    columns,
    finalRow,
    isLoading: false,
    isDataEmpty: false,
    rowsFormatter,
    insertColumnsAt
  })

  useEffect(() => {
    if (!isEmpty(rows)) {
      dispatch({
        type: ACTIONS.SET_ROWS,
        payload: { rows }
      })
    }
  }, [rows])

  useEffect(() => {
    if (!isEmpty(finalRow)) {
      dispatch({
        type: ACTIONS.SET_FINAL_ROW,
        payload: { finalRow }
      })
    }
  }, [finalRow])

  const toggleIsLoading = useCallback(
    (isLoading) =>
      dispatch({
        type: ACTIONS.SET_IS_LOADING,
        payload: { isLoading }
      }),
    []
  )

  const setRows = useCallback(
    (rows) =>
      dispatch({
        type: ACTIONS.SET_ROWS,
        payload: { rows }
      }),
    []
  )

  const recursivelyUpdateRowByIndex = useCallback(
    (rowConfig, rowIndexes) =>
      dispatch({
        type: ACTIONS.RECURSIVELY_UPDATE_ROW_CONFIG_BY_INDEX,
        payload: { rowIndexes, rowConfig }
      }),
    []
  )

  const setColumns = useCallback(
    (columns) =>
      dispatch({
        type: ACTIONS.SET_COLUMNS,
        payload: { columns }
      }),
    []
  )

  const setFinalRow = useCallback(
    (finalRow) =>
      dispatch({
        type: ACTIONS.SET_FINAL_ROW,
        payload: { finalRow }
      }),
    []
  )

  const modifyColumns = useCallback((insertionCriteria) => dispatch({
    type: ACTIONS.MODIFY_COLUMNS,
    payload: { insertionCriteria }
  }), [])

  return {
    rows: state.rows,
    columns: state.columns,
    finalRow: state.finalRow,
    isLoading: state.isLoading,
    setRows,
    setColumns,
    setFinalRow,
    setIsLoading: toggleIsLoading,
    modifyColumns,
    recursivelyUpdateRowByIndex
  }
}

export default useExpandibleTable
