import { useCallback, useState } from 'react'
import { postNamedCommand, postNamedQuery } from '../../../service'

/** TODO - make this maybe good some time */
export const useFileDownloader = (platformDataSourceId, options) => {
  const [downloading, setDownloading] = useState(false)
  const [error, setError] = useState()
  const downloadProcess = useCallback(async () => {
    setDownloading(true)
    setError(false)
    const { data: downloadMarker } = await postNamedCommand('ingestion', 'downloadPlatformDataSource', {
      platformDataSourceId,
      limit: options?.limit ?? 1000,
      maxRounds: options?.maxRounds ?? 1000
    })

    const tryDownloadFile = clearHandle => ({ data }) => {
      if (data.status === 'error') {
        setError(true)
        setDownloading(false)
        if (clearHandle) {
          clearInterval(clearHandle)
        }
      } else if (data.status === 'completed') {
        setError(false)
        setDownloading(false)
        if (clearHandle) {
          clearInterval(clearHandle)
        }
        window.location.href = data.downloadUrl
      }
    }

    if (downloadMarker.isImmediateDownload) {
      postNamedQuery('ingestion', 'getFileDownloadById', {
        fileDownloadId: downloadMarker.download.fileDownloadId,
        includeDownloadUrl: true
      }).then(tryDownloadFile())
    } else {
      const timeoutHandle = setInterval(() => {
        postNamedQuery('ingestion', 'getFileDownloadById', {
          fileDownloadId: downloadMarker.download.fileDownloadId,
          includeDownloadUrl: true
        }).then(tryDownloadFile(timeoutHandle))
      }, options?.pollingInterval ?? 5000)
    }
  }, [platformDataSourceId, options, setDownloading, setError])

  return {
    download: downloadProcess,
    downloading,
    error
  }
}

export const useAnalyzer = (platformDataSourceId, options) => {
  const analyze = useCallback(async () => {
    await postNamedCommand('ingestion', 'analyzePlatformDataSource', {
      platformDataSourceId,
      limit: options?.limit ?? 1000,
      maxRounds: options?.maxRounds ?? 20
    })
  }, [platformDataSourceId, options])

  return {
    analyze
  }
}
