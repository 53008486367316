import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import get from 'lodash/get'
import { FormLabel, makeStyles, Checkbox } from '@material-ui/core'
import isEmpty from 'lodash.isempty'
import NoteThreadTitle from '../molecules/NoteThreadTitle'
import NumberFormat from '../atoms/NumberFormat'
import Skeleton from '../atoms/Skeleton'
import Icon from '../atoms/Icon'
import ListItem from '../molecules/ListItem'

import { fetchWealthboxClient } from '../../service'
import {
  HTTP_STATUS_CODES,
  SKELETON_VARIANTS,
  TEXT_VARIANTS,
  ICON_NAMES,
  WEALTHBOX_ITEMS
} from '../../constants'
import { numeralByCase, tableNumberFormatter } from '../../utils'

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    padding: '20px'
  },
  infoContainer: {
    display: 'inline-block',
    width: '50%'
  },
  iconContainer: {
    width: '5rem',
    height: '5rem',
    margin: '0 auto'
  },
  header: {
    ...theme.typography[TEXT_VARIANTS.subtitle2],
    color: 'inherit'
  },
  center: {
    ...theme.typography[TEXT_VARIANTS.subtitle2],
    color: 'inherit',
    textAlign: 'center'
  },
  label: {
    ...theme.typography[TEXT_VARIANTS.body1],
    color: 'inherit'
  }
}))

const TITLE_HEIGHT = 56

const renderMultiCheckBoxElement = (data, field, classes) => {
  const values = get(data, field.path.split('.'), [])

  const emptyElement = (
    <FormLabel htmlFor='accountName' className={classes.label}>
      -
    </FormLabel>
  )

  const element = (
    values.map((value, index, collection) => {
      return (
        <FormLabel htmlFor='accountName' className={classes.label} key={index}>
          {value} <br />
        </FormLabel>
      )
    })
  )
  return (values.length === 0) ? emptyElement : element
}

const renderTextFieldElement = (data, field, classes) => {
  const path = (Array.isArray(field.path)) ? field.path : field.path.split('.')
  const value = get(data, path, '-')

  return (
    <FormLabel htmlFor='accountName' className={classes.label}>
      {value}
    </FormLabel>
  )
}

const renderSingleSelectElement = (data, field, classes) => {
  const path = (Array.isArray(field.path)) ? field.path : field.path.split('.')
  const values = get(data, path, [])
  const emptyElement = (
    <FormLabel htmlFor='accountName' className={classes.label}>
      -
    </FormLabel>
  )
  const element = values.map((value, index, collection) => {
    return (
      <FormLabel htmlFor='accountName' className={classes.label} key={index}>
        <ListItem title={value} noBorderBottom={index === collection.length - 1} customPadding='0px 0px' />
      </FormLabel>
    )
  })
  return (values.length === 0) ? emptyElement : element
}

const renderCheckBoxElement = (data, field, classes) => {
  const path = (Array.isArray(field.path)) ? field.path : field.path.split('.')
  const values = get(data, path, [])
  return (
    <FormLabel htmlFor='accountName' className={classes.labelNext}>
      <Checkbox disabled checked={values.includes('on')} />
    </FormLabel>
  )
}

const WealthboxBoard = ({ wealthboxAccountId }) => {
  const classes = useStyles()

  const [wealthboxInfo, setWealthboxInfo] = useState({})
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    async function getWealthboxData () {
      const { response, data } = await fetchWealthboxClient({
        clientId: wealthboxAccountId
      })

      if (response.status === HTTP_STATUS_CODES.SUCCESS) {
        setWealthboxInfo(data)
      }

      setIsLoading(false)
    }

    getWealthboxData()
  }, [wealthboxAccountId, setWealthboxInfo])

  return (
    <>
      <NoteThreadTitle
        title='Wealthbox'
        onClose={noop}
        height={TITLE_HEIGHT}
        showClose={false}
      />
      <div className={classes.mainContainer}>

        {isLoading
          ? (
            <>
              <Skeleton width='100%' height='72px' variant={SKELETON_VARIANTS.text} />
              <Skeleton width='100%' height='72px' variant={SKELETON_VARIANTS.text} />
              <Skeleton width='100%' height='72px' variant={SKELETON_VARIANTS.text} />
              <Skeleton width='100%' height='72px' variant={SKELETON_VARIANTS.text} />
              <Skeleton width='100%' height='72px' variant={SKELETON_VARIANTS.text} />
            </>
          ) : (
            <>
              {
                !isEmpty(wealthboxInfo)
                  ? Object.keys(WEALTHBOX_ITEMS).map((key, index) => {
                    return (
                      <div className={classes.container} key={key + index}>
                        <h1 className={classes.header}>{WEALTHBOX_ITEMS[key].title}</h1>

                        {WEALTHBOX_ITEMS[key].type === 'multi-check-box' && renderMultiCheckBoxElement(wealthboxInfo, WEALTHBOX_ITEMS[key], classes)}
                        {WEALTHBOX_ITEMS[key].type === 'text-field' && renderTextFieldElement(wealthboxInfo, WEALTHBOX_ITEMS[key], classes)}
                        {WEALTHBOX_ITEMS[key].type === 'single-select' && renderSingleSelectElement(wealthboxInfo, WEALTHBOX_ITEMS[key], classes)}
                        {WEALTHBOX_ITEMS[key].type === 'check-box' && renderCheckBoxElement(wealthboxInfo, WEALTHBOX_ITEMS[key], classes)}
                        {WEALTHBOX_ITEMS[key].isNumber && <NumberFormat number={tableNumberFormatter(wealthboxInfo[key])} title={numeralByCase(wealthboxInfo[key])} skipFormat />}
                      </div>
                    )
                  })
                  : (
                    <>
                      <div className={classes.iconContainer}>
                        <Icon name={ICON_NAMES.noInfo} customSize='100%' />
                      </div>
                      <h1 className={classes.center}>Missing Information</h1>
                    </>
                  )
              }
            </>
          )}
      </div>
    </>

  )
}

WealthboxBoard.propTypes = {
  wealthboxAccountId: PropTypes.string.isRequired
}

WealthboxBoard.defaultProps = {
  wealthboxAccountId: ''
}

export default React.memo(WealthboxBoard)
