import React, { useMemo } from 'react'
import dayjs from 'dayjs'
import ClientAvatarWithName from '../../../../molecules/ClientAvatarWithName'

export const cellTemplates = {
  date: ({ value }) => dayjs.utc(value).format('M/D/YYYY'),
  count: ({ value }) => value?.length || '-',
  clientAvatarWithName: ({ value }) => value ? <ClientAvatarWithName client={value} /> : '-',
  titleCaseText: ({ value }) => {
    const result = value.replace(/([A-Z])/g, ' $1')
    return result.charAt(0).toUpperCase() + result.slice(1)
  }
}

export const defaultColumnConfig = {
  columns: [
    { id: 'targetDate', Header: 'Target Date', width: 60, accessor: 'entryDate', Cell: cellTemplates.date },
    { id: 'client', Header: 'Client', width: 150, accessor: 'client', Cell: cellTemplates.clientAvatarWithName },
    { id: 'title', Header: 'Name', width: 100, accessor: 'entryJson.title' },
    { id: 'category', Header: 'Category', width: 100, accessor: 'entryJson.category', Cell: cellTemplates.titleCaseText },
    { id: 'description', Header: 'Description', width: 300, accessor: 'entryJson.description' },
    { id: 'tasks', Header: '# of Tasks', width: 50, accessor: 'entryJson.tasks', Cell: cellTemplates.count }
  ],
  defaultSort: [{ id: 'targetDate', desc: true }]
}

const columnTemplateConfigurations = {
  string: (col) => col
}

export const useColumnConfig = (columnConfig = defaultColumnConfig) => {
  return useMemo(() => {
    return {
      columns: columnConfig.columns.map((x) => {
        const type = x.type in columnTemplateConfigurations ? x.type : 'string'
        return {
          ...x,
          ...columnTemplateConfigurations[type](x)
        }
      }),
      defaultSort: columnConfig.defaultSort
    }
  }, [columnConfig])
}
