import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { usePolicy } from '../../../../../hooks/usePolicy'
import { ADMIN_ROUTES } from '../../../../../constants'
import { useWealthOwnerClientsActions } from './WealthOwnerClientsTableContext'

function ClientInfoCell ({ value, row }) {
  const { editing } = useWealthOwnerClientsActions()
  const canView = usePolicy('admin_view_clients')
  const clientId = row.original?.clientId
  if (canView && clientId && !editing) {
    return (
      <div className='__info-cell'>
        <Link to={`${ADMIN_ROUTES.CLIENTS}/${clientId}`}>{value}</Link>
      </div>
    )
  }
  if (clientId) {
    return (
      <div className='__info-cell'>{value}</div>
    )
  }
  return (
    <div className='__info-cell'>--</div>
  )
}

ClientInfoCell.propTypes = {
  value: PropTypes.any,
  row: PropTypes.any
}

export default ClientInfoCell
