import React from 'react'
import { InputAdornment, IconButton, makeStyles } from '@material-ui/core'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'

import Icon from '../components/atoms/Icon'

import { ICON_NAMES } from '../constants'

import useToggleVisibility from './useToggleVisibility'

const useStyles = makeStyles((theme) => ({
  inputIcon: {
    marginRight: '15px'
  },
  customIconSize: {
    width: '1rem',
    maxHeight: 'inherit'
  },
  inputBase: {
    borderRadius: '0px',
    border: 'none',
    padding: '0.75rem',
    fontSize: '1rem',
    fontWeight: 600
  },
  input: {
    padding: '10px 0 7px',
    '&::placeholder': {
      fontSize: '1rem',
      textOverflow: 'ellipsis !important',
      color: theme.palette.common.black,
      opacity: '60%',
      fontWeight: 600
    }
  }
}))

export default function useGetInputProps (keys, addCustomProps = true) {
  const classes = useStyles()
  const [showPasswords, toggleVisility] = useToggleVisibility(keys)

  const getInputProps = (key) => ({
    ...(addCustomProps && {
      startAdornment: (
        <InputAdornment position='start' className={classes.inputIcon}>
          <Icon
            name={ICON_NAMES.lock}
            additionalClasses={classes.customIconSize}
          />
        </InputAdornment>
      ),
      className: classes.inputBase,
      classes: {
        input: classes.input
      },
      disableUnderline: true
    }),
    endAdornment: (
      <InputAdornment position='end'>
        <IconButton
          aria-label='password visibility'
          onClick={() => toggleVisility((key))}
          size={addCustomProps ? 'medium' : 'small'}
        >
          {showPasswords[key] ? <Visibility /> : <VisibilityOff />}
        </IconButton>
      </InputAdornment>
    )
  })

  return [showPasswords, getInputProps]
}
