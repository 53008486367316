import dayjs from 'dayjs'
import React, { useCallback, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { useCheckPolicy } from '../../../../../hooks'
import { ACCOUNTS } from '../../../../../policies/admin'
import { useModifyAccountPerformanceStartDateMutation } from '../../../../../api/accounts'
import QuickEditDateInput from './QuickEditDateInput'

const PerformanceDateInput = ({
  account,
  disableEdit,
  onSave
}) => {
  const { performanceStartDate, closeDate, startDate, accountId } = account ?? {}

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isSaved, setIsSaved] = useState(false)
  const userCanEdit = useCheckPolicy(ACCOUNTS.editPerformanceStartDate)
  const [error, setError] = useState(null)

  const dateRange = useMemo(() => ({
    ...(closeDate && { maxDate: dayjs.utc(closeDate).add(-1, 'day').format('YYYY-MM-DD') }),
    ...(startDate && { minDate: dayjs.utc(startDate).add(1, 'day').format('YYYY-MM-DD') })
  }), [closeDate, startDate])

  const { mutateAsync: setPerformanceStartDate } = useModifyAccountPerformanceStartDateMutation()
  const onSubmit = useCallback(async (values) => {
    setIsSubmitting(true)
    setIsSaved(false)
    setError(null)

    try {
      await setPerformanceStartDate({
        accountId,
        performanceStartDate: values.value || null
      })
      setIsSaved(true)
      setIsSubmitting(false)
    } catch (err) {
      setError(err?.message ?? 'There wasn issue saving. Try again.')
    }
    onSave(values.value)
    setIsSaved(false)
  }, [accountId, onSave, setPerformanceStartDate])

  return (
    <QuickEditDateInput
      currentValue={performanceStartDate}
      editModalTitle='Edit Performance Start Date'
      editModalSubtitle='Edit or clear the performance start date'
      label='Performance Start Date'
      dateRange={dateRange}
      onSubmit={onSubmit}
      isSubmitting={isSubmitting}
      isSaved={isSaved}
      error={error}
      userCanEdit={disableEdit || !userCanEdit}
    />
  )
}

PerformanceDateInput.propTypes = {
  account: PropTypes.object,
  disableEdit: PropTypes.bool,
  onSave: PropTypes.func
}

export default PerformanceDateInput
