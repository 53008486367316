import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import { useFormattingContext } from '../../../../../organisms/FormattingProvider/FormattingContext'
import SydButton from '../../../../../commonDesign/Button'
import { usePolicy } from '../../../../../../hooks/usePolicy'
import { useClientReviewContext } from '../ClientReviewContext'

const useStyles = makeStyles((theme) => ({
  override: {
    border: `1px solid ${theme.palette.gray.darker}`,
    padding: `${theme.layout.padding.thin} ${theme.layout.padding.thin}`,
    marginBottom: theme.layout.margin.medium,
    fontWeight: theme.typography.weights.light,
    color: ({ lowlighted }) => lowlighted ? theme.palette.gray.darker : undefined,
    '& .__header': {
      display: 'flex',
      flexDirection: 'row',
      gap: theme.layout.gap.dense,
      justifyContent: 'space-between',
      alignItems: 'center'
    },
    '& .__body': {
      padding: theme.layout.padding.thin
    }
  },
  overrides: {
    padding: theme.layout.padding.medium
  }
}))

function OverrideDetail ({ override, lowlighted, children }) {
  const classes = useStyles({ lowlighted })
  const canEditOverrides = usePolicy('billing_edit_overrides')
  const { formatter } = useFormattingContext()
  const cr = useClientReviewContext()

  return (

    <div className={classes.override}>
      <div className='__header'>
        <span>{children}</span>
        {canEditOverrides ? (
          <span>
            <SydButton size='xs' icon='delete' priority='warning' onClick={() => cr.dialogs.removeClientOverride(override, children)}>Remove</SydButton>
          </span>
        ) : null}
      </div>
      <div className='__body'>
        <table>
          <tbody>
            <tr>
              <td>Fee Schedule</td>
              <td>{override.feeScheduleName}</td>
            </tr>
            {override.overrideCalcTypeId !== 1
              ? (
                <tr>
                  <td>Fee Override Type</td>
                  <td>{override.feeOverrideType}</td>
                </tr>
              ) : null}
            {override.overrideCalcTypeId === 5 ? (
              <tr>
                <td>Partial Override Amount</td>
                <td>{formatter(override?.overrideData?.overrideAmount, 'marketValue')}</td>
              </tr>
            ) : null}
          </tbody>
        </table>
      </div>
    </div>
  )
}

OverrideDetail.propTypes = {
  override: PropTypes.any,
  lowlighted: PropTypes.bool,
  children: PropTypes.node
}

export default OverrideDetail
