import React, { useMemo, useState, useEffect, useCallback } from 'react'
import { useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'
import { Box } from '@material-ui/core'
import { noop } from 'lodash'
import { DATE_PRESET, DEFAULT_SEARCH_CLIENTS_PAGE_SIZE, DEFAULT_SEARCH_RECENTS_CLIENTS_PAGE_SIZE, LEVEL_TYPES } from '../../../../constants'
import { useCSVData, useFetchState, useToggle } from '../../../../hooks'
import {
  useAppContext,
  useAvailableDates
} from '../../../../redux/slices/appContext'
import {
  fetchTransactions,
  fetchClients,
  fetchAssetsSymbols,
  getClientActivities
} from '../../../../service'
import { getTransactionsTableConfig } from '../../../../utils/tableHelper'
import EmptySection from '../../../atoms/EmptySection'
import TableSkeleton from '../../../atoms/TableSkeleton'
import CSVButton from '../../../atoms/CSVButton'
import AvailableDateSelect from '../../../molecules/AvailableDateSelect'
import { TableVirtualizedWithDynamicHeight } from '../../../molecules/Table'
import AutoCompleteSearchInput from '../../../molecules/AutoCompleteSearchInput'
import useDatePresetOptions from '../../../../hooks/useDatePresetOptions'

const DEFAULT_SEARCH_SYMBOLS_PAGE_SIZE = 10

const AdvisorTransactionsTable = ({ allowDownload }) => {
  const { userId, loadingClient } = useAppContext()
  const [availableDates] = useAvailableDates()
  const [selectedValue, setSelectedDateValue] = useState(DATE_PRESET.L7D)
  const [clients, setClients] = useState([])
  const [recentClients, setRecentClients] = useState([])
  const [assetSymbols, setAssetSymbols] = useState([])
  const [clientId, setClientId] = useState(null)
  const [searching, , toggleSearchingOn, toggleSearchingOff] = useToggle()
  const [selectedAssetSymbol, setAssetSymbol] = useState({})
  const [loading, setLoading] = useState(false)

  const { pathname } = useLocation()
  const advisorId = pathname.split('/').at(-1)

  const { dateValue: date } = useDatePresetOptions(selectedValue)

  useEffect(() => {
    async function fetchRecentClients () {
      const { data: recentClients } = await getClientActivities({
        take: DEFAULT_SEARCH_RECENTS_CLIENTS_PAGE_SIZE,
        userId
      })
      setRecentClients(recentClients)
    }
    if (!loadingClient) {
      fetchRecentClients()
    }
  }, [loadingClient, userId])

  const { rows, columns } = useFetchState(
    useCallback(async (setSafeState) => {
      setLoading(true)

      const params = {
        startDate: date,
        endDate: availableDates.mainDate,
        levelTypes: [LEVEL_TYPES.CLIENT, LEVEL_TYPES.ACCOUNTS],
        advisorIds: [advisorId]
      }

      if (clientId) {
        params.clientIds = [clientId]
      }

      if (selectedAssetSymbol.value) {
        params.assetIds = [selectedAssetSymbol.value]
      }

      try {
        const { data } = await fetchTransactions(params)
        const { rows, columns } = getTransactionsTableConfig(
          data.sort(({ valueDate: valueDateA }, { valueDate: valueDateB }) => {
            return new Date(valueDateB) - new Date(valueDateA)
          }),
          true
        )
        setSafeState({ rows, columns })
      } catch (err) {
        console.error(err)
      } finally {
        setLoading(false)
      }
    }, [date, selectedAssetSymbol, clientId, availableDates.mainDate, advisorId])
  )

  const csvData = useCSVData({
    loading,
    disabled: !allowDownload,
    rows: !isEmpty(rows) ? rows : [],
    labels: !isEmpty(columns) ? columns : []
  })

  const onSelectClient = useCallback(
    (client) => {
      if (client) {
        setClientId(client.clientId)
      }
    }, []
  )

  const dateSelectRendered = useMemo(
    () => (
      <AvailableDateSelect
        initialValue={selectedValue}
        selectedValue={selectedValue}
        onChange={(_, __, valueKey) => setSelectedDateValue(valueKey)}
      />
    ),
    [selectedValue]
  )

  const onSearchSymbol = useCallback(
    async (value = '') => {
      try {
        toggleSearchingOn()
        const { data: symbols } = await fetchAssetsSymbols({
          search: value,
          take: DEFAULT_SEARCH_SYMBOLS_PAGE_SIZE
        })
        setAssetSymbols(symbols)
      } catch (err) {
        console.error(err)
      } finally {
        toggleSearchingOff()
      }
    },
    [toggleSearchingOn, toggleSearchingOff]
  )

  const onSelectSymbol = useCallback((symbol) => {
    if (symbol) {
      setAssetSymbol({
        label: symbol?.symbol || symbol?.shortName,
        value: symbol.assetId
      })
    }
  }, [])

  const symbolsAutocompleteSearchInput = useMemo(
    () => (
      <AutoCompleteSearchInput
        autoFocus={false}
        startAsOpen={false}
        embeddedInput
        width='12rem'
        optionValueKey='assetId'
        optionTitleKey='symbol'
        recentOptionValueKey='assetId'
        recentOptionTitleKey='symbol'
        placeholder='Search for a symbol'
        primaryButtonLabel='View all Clients'
        options={assetSymbols}
        loading={searching}
        onChange={onSearchSymbol}
        onSelectOption={onSelectSymbol}
      />
    ),
    [assetSymbols, searching, onSearchSymbol, onSelectSymbol]
  )

  const onSearchClients = useCallback(
    async (value = '') => {
      try {
        toggleSearchingOn()
        const { data: clients } = await fetchClients({
          search: value,
          take: DEFAULT_SEARCH_CLIENTS_PAGE_SIZE
        })
        setClients(clients)
      } catch (err) {
        console.error(err)
      } finally {
        toggleSearchingOff()
      }
    },
    [toggleSearchingOn, toggleSearchingOff]
  )

  const clientsAutoCompleteSearchInput = useMemo(() => (
    <AutoCompleteSearchInput
      autoFocus={false}
      startAsOpen={false}
      embeddedInput
      width='100%'
      recentOptionTitleKey='shortName'
      recentOptionValueKey='clientId'
      optionTitleKey='shortName'
      optionValueKey='clientId'
      placeholder='Search for clients'
      primaryButtonLabel='View all Clients'
      options={clients}
      recentOptions={recentClients}
      loading={searching}
      onChange={onSearchClients}
      onSelectOption={onSelectClient}
      onSearchClick={noop}
      onPrimaryButtonClick={noop}
    />
  ), [clients, recentClients, searching, onSelectClient, onSearchClients])

  const tableRendered = useMemo(() => {
    if (loading) {
      return <TableSkeleton columns={8} rows={5} />
    }
    if (isEmpty(rows)) {
      return (
        <EmptySection
          title='There is not data to display here yet.'
          description=''
        />
      )
    }
    return (
      <TableVirtualizedWithDynamicHeight
        rows={rows}
        labels={columns}
        spacing='0.5rem'
        skipOrderBy
      />
    )
  }, [loading, rows, columns])

  return (
    <Box width='100%'>
      <Box display='flex' height='4rem' flexDirection='row'>
        <Box width='18rem'>
          {clientsAutoCompleteSearchInput}
        </Box>
        <Box ml={4}>{symbolsAutocompleteSearchInput}</Box>
        <Box display='flex' flexDirection='row' ml='auto'>
          {dateSelectRendered}
          {allowDownload && !isEmpty(csvData) && <CSVButton data={csvData} />}
        </Box>
      </Box>
      {tableRendered}
    </Box>
  )
}

AdvisorTransactionsTable.propTypes = {
  allowDownload: PropTypes.bool
}

AdvisorTransactionsTable.defaultProps = {
  allowDownload: false
}

export default AdvisorTransactionsTable
