import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { makeStyles, useTheme, Box } from '@material-ui/core'
import clsx from 'clsx'
import Image from '../../../../../atoms/Image'
import Text from '../../../../../atoms/Text'
import Icon from '../../../../../atoms/Icon'
import { ICON_NAMES } from '../../../../../../constants'
import PreviewLinkContainer from './container'

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    padding: '1rem',
    alignItems: 'center',
    borderRadius: '8px',
    border: '2px solid rgba(33, 41, 69, 0.3)'
  },
  containerHover: {
    '&:hover $smallBackgroundImage': {
      opacity: 0
    },
    '&:hover $mainTitle': {
      opacity: 0
    },
    '&:hover $hoverTitleContainer': {
      opacity: 1,
      textDecoration: 'none'
    },
    '&:hover $hoverChildContainer': {
      opacity: 1
    }
  },
  smallChildContainer: {
    top: '0',
    position: 'absolute',
    width: '100%',
    height: '100%',
    overflow: 'hidden'
  },
  mainTitle: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    borderRadius: '24px',
    color: theme.palette.primary.contrastText,
    padding: '12px 16px 10px',
    margin: '0 1rem',
    fontSize: '16px',
    fontWeight: '400',
    zIndex: '100',
    textAlign: 'center',
    backgroundColor: theme.palette.primary.main,
    transition: 'opacity 0.3s ease-in-out'
  },
  hoverTitleContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    opacity: 0,
    transition: 'opacity 0.3s ease-in-out'
  },
  hoverChildContainer: {
    width: '100%',
    height: '100%',
    overflowX: 'hidden',
    padding: '0.5rem',
    position: 'relative',
    transition: 'opacity 0.3s ease-in-out',
    opacity: 0,
    '&::-webkit-scrollbar': {
      width: '1em',
      height: '1em',
      backgroundColor: 'transparent'
    },
    '&::-webkit-scrollbar-track': {
      borderRadius: '20px',
      backgroundColor: 'transparent'
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '20px',
      backgroundColor: theme.palette.primary.dark,
      border: '3px solid white'
    }
  },
  textContainer: {
    display: 'flex',
    flex: '1 1 0%',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    fontWeight: 'bold',
    textAlign: 'center'
  },
  smallBackgroundImage: {
    inset: '0',
    margin: '6%',
    opacity: 0.3,
    position: 'initial',
    justifyContent: 'center',
    transition: 'opacity 0.3s ease-in-out'
  },
  [theme.breakpoints.down('xs')]: {
    container: {
      position: 'relative',
      maxWidth: '100%',
      width: '100%',
      transform: 'none',
      left: 'inherit !important',
      top: 'inherit !important',
      height: '12rem'
    },
    smallBackgroundImage: {
      display: 'flex'
    },
    smallChildContainer: {
      position: 'initial'
    }
  },
  selected: {
    border: '2.4px solid #73C496',
    boxShadow: '0px 2.4px 14.4px 0px rgba(13, 14, 48, 0.12)',
    '&:hover': {
      border: '2.4px solid #67B087'
    },
    transition: 'border 0.1s ease-in-out, opacity 0.5s linear 0.1s'
  }
}))

const PreviewLink = ({
  to,
  title,
  smallImageSrc,
  smallTitle,
  text,
  view,
  onHoverChange,
  position,
  lowlighted,
  selectable,
  selected,
  onSelectItem
}) => {
  const classes = useStyles()
  const theme = useTheme()

  const onMouse = useCallback(
    (e) => {
      onHoverChange(view, e.type === 'mouseenter')
    },
    [view, onHoverChange]
  )

  return (
    <PreviewLinkContainer
      to={to}
      onMouse={onMouse}
      position={position}
      lowlighted={lowlighted}
      selectable={selectable}
      onClick={onSelectItem}
    >
      <div
        className={clsx(classes.container, {
          [classes.containerHover]: !selectable,
          [classes.selected]: Boolean(selectable && selected)
        })}
      >
        {selected && selectable && (
          <Box position='absolute' top='1rem' right='1rem'>
            <Icon name={ICON_NAMES.radioChecked} customSize='2rem' />
          </Box>
        )}
        {smallTitle && <div className={classes.mainTitle}>{smallTitle}</div>}
        <div className={classes.smallChildContainer}>
          {smallImageSrc && (
            <div className={classes.smallBackgroundImage}>
              <Image
                src={`${smallImageSrc}`}
                height='auto'
                width='auto'
                isPublicResource
                extraStyles={{
                  maxHeight: '100%',
                  maxWidth: '100%',
                  objectFit: 'contain'
                }}
              />
            </div>
          )}
        </div>
        {title && !selectable && (
          <div className={classes.hoverTitleContainer}>
            <Text
              text={title}
              customFontSize='13px'
              customFontWeight='600'
              color={theme.palette.primary.main}
            />
          </div>
        )}
        <div className={classes.hoverChildContainer}>
          <div className={classes.textContainer}>
            <Text text={text} customFontSize='13px' customFontWeight='bold' />
          </div>
        </div>
      </div>
    </PreviewLinkContainer>
  )
}

PreviewLink.propTypes = {
  to: PropTypes.string,
  title: PropTypes.string,
  smallImageSrc: PropTypes.string,
  smallTitle: PropTypes.string,
  text: PropTypes.string,
  view: PropTypes.string,
  position: PropTypes.array,
  onHoverChange: PropTypes.func,
  lowlighted: PropTypes.bool,
  selectable: PropTypes.bool,
  selected: PropTypes.bool,
  onSelectItem: PropTypes.func
}

export default PreviewLink
