import { useQuery } from '@tanstack/react-query'
import { postNamedQuery } from '../service'
import { useAppContext } from '../redux/slices/appContext'
import { QUERY_KEYS } from './queryKeys'

export const useLevelPlans = (query, queryOptions) => {
  const { userId } = useAppContext()
  const { mapper, enabled } = queryOptions
  const { levelId, levelTypeId, include = {} } = query
  return useQuery({
    queryKey: [QUERY_KEYS.planning, 'getPlansByLevel', userId, levelId, levelTypeId],
    queryFn: async () => {
      const { data } = await postNamedQuery('planning', 'getPlansByLevel', { levelId, levelTypeId, include })
      return data
    },
    select: mapper,
    enabled
  })
}

export const usePlanGoals = (query, queryOptions) => {
  const { userId } = useAppContext()
  const { mapper, enabled } = queryOptions
  const { planId } = query
  return useQuery({
    queryKey: [QUERY_KEYS.planning, 'getGoalsByPlan', userId, planId],
    queryFn: async () => {
      const { data } = await postNamedQuery('planning', 'getGoalsByPlan', { planId })
      return data
    },
    select: mapper,
    enabled
  })
}

export const useGoalCategories = (query, queryOptions) => {
  const { userId } = useAppContext()
  const { mapper, enabled } = queryOptions
  return useQuery({
    queryKey: [QUERY_KEYS.planning, 'getGoalCategories', userId],
    queryFn: async () => {
      const { data } = await postNamedQuery('planning', 'getGoalCategories')
      return data
    },
    select: mapper,
    enabled
  })
}

export const usePlanStatuses = (query, queryOptions) => {
  const { userId } = useAppContext()
  const { mapper, enabled } = queryOptions
  return useQuery({
    queryKey: [QUERY_KEYS.planning, 'getPlanStatuses', userId],
    queryFn: async () => {
      const { data } = await postNamedQuery('planning', 'getPlanStatuses')
      return data
    },
    select: mapper,
    enabled
  })
}
