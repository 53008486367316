import React from 'react'
import PropTypes from 'prop-types'
import { TabularDatasetContext, useTabularDatasetContext } from './TabularDatasetContext'

const TabularDatasetProvider = ({ children, configuration }) => {
  const contextValue = useTabularDatasetContext(configuration)
  return (
    <TabularDatasetContext.Provider value={contextValue}>
      {children}
    </TabularDatasetContext.Provider>
  )
}

TabularDatasetProvider.propTypes = {
  children: PropTypes.node,
  configuration: PropTypes.object
}

export default TabularDatasetProvider
