import React, { useCallback } from 'react'
import { Box, Button, Container, makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import { useHistory } from 'react-router-dom'
import OperationalTable, { useOperationalTable } from '../../../organisms/OperationalTable'
import ViewTabs from '../../../organisms/LinkViewTabs'
import { useCheckPolicy } from '../../../../hooks'
import { ROLES } from '../../../../policies/admin'
import { defaultRolesColumnConfig } from '../Advisors/helpers'
import { useSearchRolesData, useTabsOptions } from '../Advisors/hooks'
import Icon from '../../../atoms/Icon'
import { ADMIN_ROUTES, ICON_NAMES } from '../../../../constants'

const useStyles = makeStyles((theme) => ({
  headspace: {
    paddingTop: '1rem',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  container: {
    padding: '1rem',
    height: '100%',
    [theme.breakpoints.down('xs')]: {
      padding: '1.75rem'
    }
  },
  pillBtn: {
    borderRadius: 25,
    padding: '8px 16px',
    textTransform: 'none',
    fontWeight: 'bold',
    fontSize: '0.75rem'
  },
  pillSecondary: {
    backgroundColor: theme.palette.summitBlue,
    color: theme.palette.white,
    '&:hover': {
      backgroundColor: theme.palette.summitBlue
    },
    '&:disabled': {
      backgroundColor: theme.palette.romanSilver,
      color: theme.palette.white
    }
  },
  button: {
    borderRadius: '30px',
    fontWeight: 800
  },
  link: {
    textDecoration: 'none !important'
  }
}))

function RolesView () {
  const history = useHistory()
  const {
    defaultPageSize,
    pageIndex,
    pageSize,
    sort,
    searchText,
    onPagingChange,
    onSortingChange,
    onSearchTextChange,
    onTableModeChange,
    tableMode
  } = useOperationalTable(defaultRolesColumnConfig.defaultSort)
  const classes = useStyles()
  const linkTabOptions = useTabsOptions()
  const canEditRoles = useCheckPolicy(ROLES.editAdvisorRoles)

  const onChangeTab = useCallback(() => {
    onSearchTextChange('')
  }, [onSearchTextChange])

  const { data = [], loading, count = 0 } = useSearchRolesData({
    pageIndex,
    pageSize,
    sort,
    searchText,
    tableMode
  })

  return (
    <Container maxWidth={false} className={classes.container}>
      <ViewTabs.Group tabOptions={linkTabOptions} onChange={onChangeTab}>
        <ViewTabs.Links
          tabsKey='advisors'
          rightAdornment={canEditRoles && (
            <Button
              className={clsx(classes.pillBtn, classes.pillSecondary)}
              variant='outlined'
              onClick={() => history.push(ADMIN_ROUTES.ROLE_NEW)}
              startIcon={<Icon customSize='1rem' name={ICON_NAMES.add} />}
            >
              Add Role
            </Button>
          )}
        />
      </ViewTabs.Group>
      <Box className={classes.tabsContainer}>
        <OperationalTable.Wrapper>
          <OperationalTable.SuperHeader className={classes.headspace}>
            <OperationalTable.Search
              onChange={onSearchTextChange}
              placeholder='Search by role name'
              delay={250}
            />
          </OperationalTable.SuperHeader>
          <OperationalTable
            mode='server'
            columns={defaultRolesColumnConfig.columns}
            data={data}
            defaultPageSize={defaultPageSize}
            defaultSort={defaultRolesColumnConfig.defaultSort}
            itemName='Roles'
            loading={loading}
            total={count}
            searchText={searchText}
            onSortingChange={onSortingChange}
            onPagingChange={onPagingChange}
            onTableModeChange={onTableModeChange}
          />
        </OperationalTable.Wrapper>
      </Box>
    </Container>
  )
}

export default RolesView
