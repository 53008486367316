import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { makeStyles } from '@material-ui/core'
import { useDashboardSettings } from '../ClientDashboardContextProvider'
import MinimapLink from './MinimapLink'
import { useDashboardNavigation } from './context'

const PREVIEW_SIZES = { height: 15, width: 20 }
const CENTER_PREVIEW_SIZE = { height: 18, width: 18 }
const CONTAINER_SIZES = { height: 68, width: 84 }

const useStyles = makeStyles((theme) => ({
  container: ({ containerHeight, containerWidth }) => ({
    backgroundColor: theme.palette.common.white,
    height: `${containerHeight}px`,
    position: 'fixed',
    right: '1rem',
    bottom: '1rem',
    width: `${containerWidth}px`,
    boxShadow: '0px 10px 10px -5px rgba(0,0,0,0.5)',
    zIndex: 1110
  }),
  resetView: ({ centerViewSize: { height, width } }) => ({
    position: 'absolute',
    backgroundColor: theme.palette.dimmed,
    cursor: 'pointer',
    border: '0px solid rgba(33, 41, 69, 0.2)',
    width: `${width}px`,
    height: `${height}px`,
    borderRadius: '100%',
    left: '50%',
    top: '50%',
    transform: 'translate3d(-50%, -50%, 0)',
    '&:hover': {
      backgroundColor: `${theme.palette.selected} !important`,
      border: '0px solid rgba(33, 41, 69, 0.8)'
    }
  }),
  [theme.breakpoints.down('xs')]: {
    container: {
      display: 'none'
    }
  }
}))

const isHighlighted = (highlightedView, pathname, itemView) => {
  if (highlightedView) {
    return highlightedView === itemView
  }
  if (pathname) {
    return pathname.includes(`/${itemView}`)
  }
  return false
}

const Minimap = () => {
  const classes = useStyles({
    containerWidth: CONTAINER_SIZES.width,
    containerHeight: CONTAINER_SIZES.height,
    width: PREVIEW_SIZES.width,
    height: PREVIEW_SIZES.height,
    centerViewSize: CENTER_PREVIEW_SIZE
  })

  const { items, root, highlightedView } = useDashboardSettings()
  const navMan = useDashboardNavigation()
  const { pathname } = useLocation()

  return (
    <div className={classes.container}>
      {items.map((item) => (
        <MinimapLink
          key={item.view}
          item={item}
          highlighted={isHighlighted(highlightedView, pathname, item.view)}
          position={navMan.getMiniPosition(item.view)}
        />
      ))}
      <Link to={root}>
        <div className={classes.resetView} />
      </Link>
    </div>
  )
}

export default Minimap
