import { useMemo, useState } from 'react'
import isEmpty from 'lodash/isEmpty'
import {
  DEFAULT_SELECT_SEARCH_PAGINATION,
  MILLISECONDS_BEFORE_SEARCH
} from '../constants'
import { useSearchClients } from '../api/clients'
import useDebouncedValue from './useDebounceValue'
import { getSearchQuery } from './helpers'

const SEARCH_FIELDS = ['longName', 'shortName']

export const mapClients = ({ data: clients = [] }) => {
  return clients.map((client) => ({
    label: client.shortName,
    value: client.clientId,
    payload: { ...client }
  }))
}

const getQueryFilters = ({ query }) => ({
  textSearch: getSearchQuery(SEARCH_FIELDS, query)
})

const useSearchClientsDebounced = ({
  query = {},
  queryOptions = {},
  defaultIds = [],
  debounceDelay = MILLISECONDS_BEFORE_SEARCH
} = {}) => {
  const [queryText, setQueryText] = useState('')
  const debouncedQuery = useDebouncedValue(queryText, debounceDelay)

  const { queryParams, queryParamOptions } = useMemo(() => {
    const queryParams = getQueryFilters({
      query: debouncedQuery
    })

    return {
      queryParams: {
        ...DEFAULT_SELECT_SEARCH_PAGINATION,
        ...queryParams,
        ...query,
        ...(debouncedQuery?.length && {
          sort: [
            { field: 'shortName', dir: 'desc' }
          ]
        })
      },
      queryParamOptions: {
        mapper: mapClients,
        ...queryOptions
      }
    }
  }, [query, queryOptions, debouncedQuery])

  const { data, isLoading } = useSearchClients(queryParams, queryParamOptions)

  const defaultOptionsQuery = useMemo(() => {
    return {
      queryParams: {
        filters: {
          clientId: defaultIds
        }
      },
      queryParamOptions: {
        mapper: mapClients,
        enabled: !isEmpty(defaultIds),
        ...queryOptions
      }
    }
  }, [queryOptions, defaultIds])

  const { data: defaultOptions, isLoading: isLoadingDefaults } = useSearchClients(
    defaultOptionsQuery.queryParams,
    defaultOptionsQuery.queryParamOptions
  )

  const isTransitioningOptions = isLoading || debouncedQuery !== queryText

  return {
    options: data,
    onChangeQuery: setQueryText,
    isSearchLoading: isTransitioningOptions,
    isLoading: (isLoadingDefaults && !isEmpty(defaultIds)),
    defaultOptions
  }
}

export default useSearchClientsDebounced
