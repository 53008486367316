import React from 'react'
import PropTypes from 'prop-types'
import { Box, useTheme } from '@material-ui/core'
import Icon from '../../../../../../atoms/Icon'

const NonBillableCell = ({ value }) => {
  const theme = useTheme()
  if (!value) return null

  return (
    <Box style={{ textAlign: 'center' }}>
      <Icon name='check' color={theme.palette.success.main} customSize={18} />
    </Box>
  )
}

NonBillableCell.propTypes = {
  value: PropTypes.any
}

export default NonBillableCell
