import { useMemo } from 'react'
import { isEmpty } from 'lodash'
import { useDates } from '../../../../hooks/useDates'
import { CALC_TYPES } from '../../../../constants'
import { QUERY_KEYS } from '../../../../api/queryKeys'
import { useClientBalances } from '../../../../api/clients'

const useFetchClientBalances = (query = {}, queryOptions = {}) => {
  const { availableDates } = useDates()

  const clientBalancesQuery = useMemo(() => {
    const { enabled } = queryOptions
    return {
      query: {
        calcType: CALC_TYPES.balance,
        startDate: availableDates.mainDate,
        endDate: availableDates.mainDate,
        ...query
      },
      options: {
        ...queryOptions,
        queryKey: QUERY_KEYS.recentClientBalances,
        enabled: enabled || !isEmpty(availableDates)
      }
    }
  }, [availableDates, query, queryOptions])

  return useClientBalances(
    clientBalancesQuery.query,
    clientBalancesQuery.options
  )
}

export default useFetchClientBalances
