import React from 'react'
import PropTypes from 'prop-types'
import { useMediaQuery, useTheme } from '@material-ui/core'
import SectionContainer from './SectionContainer'
import SectionTitle from './SectionTitle'
import SectionContent from './SectionContent'
import SectionActions from './SectionActions'

const Section = ({
  title,
  subTitle,
  style,
  actions,
  children,
  className,
  contentStyle,
  columnContent,
  borderedContent
}) => {
  const theme = useTheme()
  const matchesBreakpoint = useMediaQuery(theme.breakpoints.up('md'))

  return (
    <SectionContainer style={style} className={className}>
      <SectionTitle title={title} actions={actions} />
      {subTitle}
      <SectionContent
        columnContent={columnContent}
        borderedContent={borderedContent}
        style={contentStyle}
      >
        {children}
        {actions && !matchesBreakpoint && <SectionActions actions={actions} />}
      </SectionContent>
    </SectionContainer>
  )
}

Section.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  contentStyle: PropTypes.object,
  style: PropTypes.object,
  actions: PropTypes.node,
  columnContent: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  subTitle: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  borderedContent: PropTypes.bool
}

export default Section
