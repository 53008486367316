import React from 'react'
import PropTypes from 'prop-types'
import { Box, makeStyles } from '@material-ui/core'
import { isArray } from 'lodash'
import Text from '../../../atoms/Text'

const useStyles = makeStyles(() => ({
  section: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: '0.875rem',
    '& .__title': {
      fontWeight: 'bold'
    },
    '& .__value': {},
    '& .__value-placeholder': {
      opacity: '0.6'
    }
  }
}))

const InfoSection = ({ title, placeholder, value }) => {
  const classes = useStyles()
  return (
    <div className={classes.section}>
      <Text text={title} className='__title' />
      {!value && <Text text={placeholder} className='__value-placeholder' />}
      {isArray(value) ? (
        <Box>
          {value.map((val, index) => (
            <Text key={index} text={val} className='__value' />
          ))}
        </Box>
      ) : (
        <Text text={value} className='__value' />
      )}
    </div>
  )
}

InfoSection.propTypes = {
  title: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string
}

export default InfoSection
