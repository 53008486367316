import isEmpty from 'lodash/isEmpty'
import { getRandomColor } from '../../../../utils'

export const mapGroupsToKeyValue = (groupOptions) => {
  return groupOptions?.reduce(
    (groupAcc, group) => ({
      ...groupAcc,
      [group.value]: group.options.reduce(
        (acc, { label, value }) => ({
          ...acc,
          [value]: label
        }),
        {}
      )
    }),
    {}
  )
}

export const mapGroupTypesOptions = ({ data }) => {
  return data
    .filter(({ groups }) => !isEmpty(groups))
    .map(({ groupTypeId, longName, groups }) => ({
      label: longName,
      value: groupTypeId,
      color: getRandomColor(),
      options: groups.map(({ groupId, shortName }) => ({
        label: shortName,
        value: groupId
      }))
    }))
}

export const tagTypesQueryOptions = {
  mapper: mapGroupTypesOptions
}

export const mapGroupsOptions = ({ data = [] }) => {
  return data.map(({ groupId, groupType }) => ({
    groupId,
    groupTypeId: groupType.groupTypeId
  }))
}

export const tagsQueryOptions = {
  mapper: mapGroupsOptions
}

export const findFieldIndexById = (id, fields) => {
  const field = fields.find((field) => field.id === id)
  const fieldIndex = fields.indexOf(field)
  return fieldIndex
}

const findDiffTags = (targetTags) => (sourceTag) => {
  const { groupId, groupTypeId } = sourceTag

  const tag = targetTags.find(
    (tag) => tag.groupId === groupId && tag.groupTypeId === groupTypeId
  )
  return !tag
}

export const getGroupedTagsBody = (sourceTags, targetTags) => {
  const addTagsToGroup = sourceTags.filter(findDiffTags(targetTags))
  const removeTagsFromGroup = targetTags.filter(findDiffTags(sourceTags))
  return {
    addTagsToGroup,
    removeTagsFromGroup
  }
}

export const getGroupTypesQuery = ({ memberLevelId, memberLevelTypeId }) => {
  return {
    skip: 0,
    take: 100,
    includes: {
      groups: true,
      groupMembers: true,
      groupMemberTypes: true
    },
    filters: {
      memberLevelTypeIds: [memberLevelTypeId],
      ...(memberLevelId ? { memberLevelIds: [memberLevelId] } : {})
    }
  }
}

export const getGroupsQuery = ({ memberLevelId, memberLevelTypeId }) => {
  return {
    skip: 0,
    take: 100,
    includes: {
      groupType: true
    },
    filters: {
      memberLevelTypeIds: [memberLevelTypeId],
      ...(memberLevelId ? { memberLevelIds: [memberLevelId] } : {})
    }
  }
}
