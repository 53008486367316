import { Checkbox, withStyles } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import React from 'react'
import PropTypes from 'prop-types'
import { noop } from 'lodash'
import clsx from 'clsx'

const useStyles = makeStyles(() => ({
  container: ({ checked }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    background: '#FFFFFF',
    border: '2px solid #EEF0F8',
    borderRadius: '8px',
    marginBottom: '1.125rem',
    padding: '0.75rem 1rem',
    transition: 'border-color 0.3s ease-in-out',
    ...(checked
      ? {
        background: ' #F3F5FA',
        border: '2px solid #212945',
        borderRadius: '8px'
      }
      : {})
  })
}))

const checkBoxStyles = () => ({
  root: {
    '&$checked': {
      color: '#212945'
    }
  },
  checked: {}
})

const CustomCheckbox = withStyles(checkBoxStyles)(Checkbox)

const CheckBoxItem = ({ checked, disabled, children, onChange, containerClassName }) => {
  const classes = useStyles({ checked })

  return (
    <div
      className={clsx(classes.container, {
        [containerClassName]: !!containerClassName
      })}
    >
      <CustomCheckbox
        disabled={disabled}
        checked={checked}
        onChange={onChange}
        className={classes.checkbox}
      />
      {children}
    </div>
  )
}

CheckBoxItem.defaultProps = {
  checked: false,
  onChange: noop,
  disabled: false,
  children: null,
  containerClassName: ''
}

CheckBoxItem.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  children: PropTypes.node,
  containerClassName: PropTypes.string
}

export default CheckBoxItem
