import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import Select from '../Select'
import { BUTTON_SIZES } from '../../../constants'
import { defaultOptions, useRelativeDateRange } from '../RelativeDateSelect'
import { useAppContext } from '../../../redux/slices/appContext'
import ComponentsOfChangePivotTable, {
  componentsOfChangePivotTable
} from './Table'
import { DATA_POINTS_GROUP_BY } from './helpers'

const useStyles = makeStyles(() => ({
  actions: {
    padding: '0.5rem 0',
    display: 'flex',
    gap: '0.5rem',
    justifyContent: 'end'
  }
}))

const GROUP_BY_OPTIONS = Object.values(DATA_POINTS_GROUP_BY).map(
  (groupByKey) => ({
    label: groupByKey,
    value: groupByKey
  })
)

const defaultGroupByMonth = {
  format: 'MMMM',
  timeLeapUnit: 1,
  header: 'Month over Month',
  groupBy: DATA_POINTS_GROUP_BY.month
}

const defaultGroupByYear = {
  format: 'YYYY',
  timeLeapUnit: 1,
  header: 'Year over Year',
  groupBy: DATA_POINTS_GROUP_BY.year
}

const ComponentsOfChangePivotTableWrapper = ({
  showDateRangeSelector,
  showGroupBySelector,
  defaultDateRange,
  dateRangeOptions,
  groupBySpec: _groupBySpec,
  ...props
}) => {
  const classes = useStyles()
  const { availableDates } = useAppContext()
  const [groupBy, setGroupBy] = useState(DATA_POINTS_GROUP_BY.year)
  const [selectedDateRange, setSelectedDateRange] = useState(defaultDateRange)

  const { options: relativeDateRangeOptions } = useRelativeDateRange(
    selectedDateRange,
    availableDates,
    dateRangeOptions
  )

  const groupBySpec = useMemo(() => {
    if (!showGroupBySelector) return _groupBySpec

    const defaultGroupBy =
      groupBy === DATA_POINTS_GROUP_BY.month
        ? defaultGroupByMonth
        : defaultGroupByYear

    const groupByOverride = _groupBySpec.groupBy === defaultGroupBy.groupBy
      ? _groupBySpec
      : {}

    return { ...defaultGroupBy, ...groupByOverride }
  }, [_groupBySpec, groupBy, showGroupBySelector])

  return (
    <div>
      {(showDateRangeSelector || showGroupBySelector) && (
        <div className={classes.actions}>
          {showDateRangeSelector && (
            <Select
              size={BUTTON_SIZES.small}
              value={selectedDateRange}
              onChange={setSelectedDateRange}
              options={relativeDateRangeOptions}
            />
          )}
          {showGroupBySelector && (
            <Select
              size={BUTTON_SIZES.small}
              value={groupBy}
              onChange={setGroupBy}
              options={GROUP_BY_OPTIONS}
            />
          )}
        </div>
      )}
      <ComponentsOfChangePivotTable
        {...props}
        groupBySpec={groupBySpec}
        selectedDateRange={selectedDateRange}
        dateRangeOptions={relativeDateRangeOptions}
      />
    </div>
  )
}

export const componentsOfChangePivotTableWrapper = {
  ...componentsOfChangePivotTable,
  showDateRangeSelector: PropTypes.bool,
  showGroupBySelector: PropTypes.bool,
  defaultDateRange: PropTypes.oneOf(defaultOptions.map(({ key }) => key))
}

ComponentsOfChangePivotTableWrapper.propTypes =
  componentsOfChangePivotTableWrapper

ComponentsOfChangePivotTableWrapper.defaultProps = {
  showDateRangeSelector: false,
  showGroupBySelector: false,
  defaultDateRange: 'SI'
}

export default ComponentsOfChangePivotTableWrapper
