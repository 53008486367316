import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import { makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import Icon from '../../atoms/Icon'
import { mapInputStyles } from './styles'

const ICON_SIZES = {
  sm: 18,
  lg: 22
}

const useStyles = makeStyles((theme) => ({
  sydInput: mapInputStyles(theme, {
    minWidth: '300px',
    width: '100%',
    border: `1px solid ${theme.palette.gray.dark}`,
    borderRadius: '4px',
    backgroundColor: theme.palette.white,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    transition: 'outline 200ms ease-in-out',
    outline: '1px solid transparent',
    '&:has(:-internal-autofill-selected)': {
      backgroundColor: 'rgb(232, 240, 254)'
    },
    '&:focus-within': {
      outline: `1px solid ${theme.palette.primary.main}`
    },
    '& .__icon': {
      marginRight: '5px'
    }
  })
}))

const SydInput = forwardRef(function SydInput ({
  className,
  name,
  onChange = noop,
  onBlur = noop,
  onKeyDown = noop,
  value: _value,
  placeholder,
  iconName,
  defaultValue,
  disabled,
  size,
  type,
  autoComplete,
  minWidth = '300px'
}, ref) {
  const classes = useStyles({ size, disabled, minWidth })
  const inputRef = useRef(null)
  const [value, setValue] = useState(defaultValue ?? '')
  useImperativeHandle(ref, () => ({
    clear: () => setValue(defaultValue),
    focus: () => inputRef.current.focus(),
    value
  }), [value, defaultValue, inputRef])

  const handleChange = useCallback((e) => {
    setValue && setValue(e.target.value)
    onChange(e)
  }, [setValue, onChange])

  const handleBlur = useCallback((e) => {
    setValue && setValue(e.target.value)
    onBlur(e)
  }, [setValue, onBlur])

  useEffect(() => {
    if (_value === undefined) return
    if (value === _value) return
    setValue(_value)
  }, [_value, value, setValue])

  return (
    <div className={clsx(classes.sydInput, className)}>
      {iconName ? (
        <Icon additionalClasses='__icon' name={iconName} customSize={ICON_SIZES[size]} />
      ) : null}
      <input
        name={name}
        autoComplete={autoComplete}
        type={type}
        ref={inputRef}
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        placeholder={placeholder}
        disabled={disabled}
        onKeyDown={onKeyDown}
      />
    </div>
  )
})

SydInput.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onKeyDown: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  placeholder: PropTypes.string,
  iconName: PropTypes.string,
  defaultValue: PropTypes.string,
  disabled: PropTypes.bool,
  size: PropTypes.oneOf(['sm', 'lg']),
  type: PropTypes.oneOf(['input', 'password', 'number']),
  autoComplete: PropTypes.string,
  minWidth: PropTypes.string
}

SydInput.defaultProps = {
  defaultValue: '',
  disabled: false,
  size: 'lg',
  type: 'input'
}

export default SydInput
