import React, { useMemo } from 'react'
import { Switch, useRouteMatch } from 'react-router-dom'
import { SecureRoute } from '@okta/okta-react'
import ClientDashboardContextProvider from '../../../../organisms/ClientDashboard/ClientDashboardContextProvider'
import ClientDashboardItem from '../../../../organisms/ClientDashboard/ClientDashboardItem'
import ErrorBoundary from '../../../../molecules/ErrorBoundary'
import NavigationProvider from '../../../../organisms/ClientDashboard/NavigationProvider'
import SubViewRoute from '../../../../organisms/ClientDashboard/components/SubViewRoute'
import { useAbundanceEngineGalleryContext } from '../abundanceEngineGalleryContext'
import WheelPreview from '../wheelPreview'
import { getLayoutSettings } from '../helpers'
import List from './list'

const AbundanceEngineGallery = () => {
  const { path, url } = useRouteMatch()

  const { firmConfig, abundanceEngineViews } =
    useAbundanceEngineGalleryContext()

  const items = useMemo(() => {
    console.log('abundance engine views', abundanceEngineViews)
    return (abundanceEngineViews || []).filter(x => x.liveView).map((item) => {
      return (
        <ClientDashboardItem
          key={item.viewId}
          title={item.name}
          smallImageSrc={null}
          smallTitle={item.name}
          text={item.description}
          viewPath={item.path}
        />
      )
    })
  }, [abundanceEngineViews])

  return (
    <ErrorBoundary name='Abundance Engine Gallery'>
      <Switch>
        <SecureRoute path={`${path}/preview`}>
          <WheelPreview />
        </SecureRoute>
        <ClientDashboardContextProvider
          settings={getLayoutSettings(firmConfig)}
          dashboardItems={items}
          root={url}
        >
          <NavigationProvider dashboardItems={items} root={url} hideMinimap>
            <SecureRoute path={path}>
              <List />
            </SecureRoute>
            <SecureRoute path={`${path}/:subView`}>
              <SubViewRoute />
            </SecureRoute>
          </NavigationProvider>
        </ClientDashboardContextProvider>
      </Switch>
    </ErrorBoundary>
  )
}

export default AbundanceEngineGallery
