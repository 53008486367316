/* eslint-disable react/jsx-boolean-value */
import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import dayjs from 'dayjs'
import { useForm, Controller } from 'react-hook-form'
import { makeStyles } from '@material-ui/core/styles'
import {
  Box,
  Grid,
  TextField,
  useTheme
} from '@material-ui/core'
import utc from 'dayjs/plugin/utc'
import {
  DATE_TIME_PICKER_TYPES,
  ICON_NAMES,
  MEETINGS_BODY_TYPES
} from '../../constants'
import { messageToUse } from '../pages/admin/common'
import Text from '../atoms/Text'
import Icon from '../atoms/Icon'
import DateTimePicker from '../atoms/DateTimePicker'
import { useAppContext } from '../../redux/slices/appContext'
import WYSIWYGEditor from '../organisms/WYSIWYGEditor'
import { entryTypeIdentifier } from '../organisms/WealthJourney/v2/entryType'
import SaveCancelButtons from './SaveCancelButtons'

dayjs.extend(utc)

const useStyles = makeStyles((theme) => ({
  actions: {
    position: 'absolute',
    bottom: 0,
    left: '50%',
    transform: 'translateX(-50%)'
  },
  mainForm: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '80px'
  },
  column: {
    padding: '0 50px'
  },
  textInput: {
    marginTop: '20px'
  },
  cancelButton: {
    backgroundColor: theme.palette.common.white,
    color: '#212945',
    border: '2px solid #212945',
    '&:hover': {
      backgroundColor: theme.palette.gray.light
    }
  },
  submitButton: {
    backgroundColor: '#212945',
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.gray.dark
    }
  },
  field: {
    marginLeft: 'calc(18px + 32px)',
    marginTop: '30px'
  },
  label: {
    marginBottom: '10px',
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'flex-start'
  },
  divider: {
    margin: '20px 0px'
  },
  selector: {
    border: 'none'
  },
  selectInput: {
    display: 'flex',
    height: '100%',
    paddingBottom: '0px',
    textAlign: 'left !important',
    fontSize: '1.25rem'
  },
  input: {
    paddingLeft: '0px',
    border: 'none',
    alignItems: 'flex-start'
  },
  inputText: {
    fontSize: '1.5rem !important'
  },
  asterisk: {
    marginLeft: '10px'
  },
  dateIcon: {
    margin: '0px 18px 0px 0px',
    backgroundColor: '#EEF0F8',
    width: '32px',
    height: '36px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  }
}))

const DATE_FORMAT = 'YYYY-MM-DD'

const defaultValues = {
  meetingId: '',
  title: '',
  description: {},
  date: dayjs().format(DATE_FORMAT)
}

const getFormValues = (meeting) => ({
  meetingId: meeting.entryId,
  title: meeting.entryJson.title,
  description: meeting.entryJson.body,
  date: dayjs.utc(meeting.entryDate).format(DATE_FORMAT)
})

const getLabel = (value) => {
  const date = dayjs(value)
  if (date.isSame(dayjs(), 'day')) return 'today'
  return date.format('dddd')
}

const getTempTitle = (value) => {
  const date = dayjs(value)
  return date.format('[Q]Q YYYY [Meeting]')
}

const WealthJourneyMeetingForm = ({
  editMode,
  selectedEntry,
  onClose,
  onAddEntry,
  onEditEntry,
  setSelectedMeeting
}) => {
  const classes = useStyles()
  const theme = useTheme()
  const { clientId } = useAppContext()
  const [selectedEntryBody, isMarkdown] = useMemo(() => {
    if (!editMode) return [null, false]
    const isMarkdown = selectedEntry.entryJson.bodyType === MEETINGS_BODY_TYPES.MARKDOWN
    let body = selectedEntry.entryJson.body
    if (isMarkdown) {
      body = body.replaceAll('&gt;', '>')
    }
    return [body, isMarkdown]
  }, [editMode, selectedEntry])

  const {
    register,
    control,
    handleSubmit,
    formState,
    clearErrors,
    setValue,
    watch
  } = useForm({
    defaultValues: editMode ? getFormValues(selectedEntry) : defaultValues
  })

  const {
    date: datePreview
  } = watch()

  const { isSubmitting, errors, isValid } = formState

  const onSubmit = useCallback(
    (data) => {
      clearErrors()
      const {
        meetingId,
        title,
        description,
        date
      } = data

      const entryDate = dayjs.utc(date).format(DATE_FORMAT)
      const entryJson = {
        ...(selectedEntry?.entryJson || {}),
        title,
        body: description,
        bodyType: MEETINGS_BODY_TYPES.MARKDOWN
      }
      const entryText = title

      if (!editMode) {
        const saveBody = {
          clientId,
          entryJson,
          entryTypeId: entryTypeIdentifier.MEETINGS,
          entryDate,
          entryText
        }
        onAddEntry(saveBody, setSelectedMeeting)
        return onClose()
      }
      const editBody = {
        entryId: meetingId,
        entryJson,
        entryDate,
        entryText
      }
      onEditEntry(editBody)
      return onClose()
    },
    [
      clearErrors,
      editMode,
      onClose,
      clientId,
      onAddEntry,
      onEditEntry,
      selectedEntry,
      setSelectedMeeting
    ]
  )
  const handleFieldChange = (viewFieldName) => (value) => {
    clearErrors(viewFieldName)
    setValue(viewFieldName, value)
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%', maxWidth: '1200px', margin: '0 auto' }}>
        <input
          type='hidden'
          {...register('meetingId', { required: false })}
        />
        <Grid container style={{ marginTop: '50px' }}>
          <Grid item className={classes.mainForm} xs={12}>
            <Box display='flex' flexDirection='row' justifyContent='flex-start' alignItems='center'>
              <div className={classes.dateIcon}>
                <Icon
                  name={ICON_NAMES.calendar}
                  customSize='12px'
                />
              </div>
              <Box display='flex' flexDirection='column' alignItems='flex-start'>
                <Text text={getLabel(datePreview)} customSize='0.875rem' color='#141929' />
                <Controller
                  name='date'
                  control={control}
                  render={({ field }) => (
                    <DateTimePicker
                      {...field}
                      color='#141929'
                      type={DATE_TIME_PICKER_TYPES.date}
                      format='MMM D, YYYY'
                      className={classes.selector}
                      inputClassName={classes.selectInput}
                    />
                  )}
                />
                {errors.date && (
                  <Text
                    text={messageToUse(errors.date)}
                    color={theme.palette.error.main}
                  />
                )}
              </Box>
            </Box>
            <Box className={classes.field}>
              <TextField
                error={Boolean(errors.title)}
                id='title'
                placeholder={getTempTitle(datePreview)}
                variant='standard'
                className={classes.input}
                inputProps={{ style: { fontSize: '1.5rem' } }}
                InputProps={{
                  ...register('title', {
                    minLength: 1,
                    maxLength: 50,
                    required: true
                  }),
                  disableUnderline: true
                }}
                fullWidth
              />
              {errors.title && (
                <Text
                  text={messageToUse(errors.title)}
                  color={theme.palette.error.main}
                />
              )}
            </Box>
            <Box className={classes.field} style={{ marginBottom: '80px' }}>
              <input type='hidden' {...register('description')} />
              <WYSIWYGEditor
                placeholder='Add some meeting notes...'
                defaultValue={selectedEntryBody}
                onChange={handleFieldChange('description')}
                isMarkdown={isMarkdown}
              />
            </Box>
          </Grid>
          <SaveCancelButtons
            onCancel={onClose}
            isFormInvalid={isValid}
            isSubmitting={isSubmitting}
            containerClassName={classes.actions}
          />
        </Grid>
      </form>
    </>
  )
}

WealthJourneyMeetingForm.propTypes = {
  editMode: PropTypes.bool,
  selectedEntry: PropTypes.object,
  entries: PropTypes.array,
  onClose: PropTypes.func.isRequired,
  onAddEntry: PropTypes.func,
  onEditEntry: PropTypes.func,
  setSelectedMeeting: PropTypes.func
}

WealthJourneyMeetingForm.defaultProps = {
  editMode: false,
  selectedEntry: {},
  entries: [],
  onAddEntry: noop,
  onEditEntry: noop,
  setSelectedMeeting: noop
}

export default WealthJourneyMeetingForm
