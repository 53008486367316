import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  label: {
    userSelect: 'none',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  toggleBody: {
    display: ({ collapsed }) => collapsed ? 'none' : 'block'
  }
}))

const Hideable = ({ label, children }) => {
  const [collapsed, setCollapsed] = useState(false)
  const toggle = useCallback((e) => {
    e.preventDefault()
    e.stopPropagation()

    setCollapsed(!collapsed)
  }, [collapsed, setCollapsed])
  const classes = useStyles({ collapsed })

  return (
    <>
      <h3 className={classes.label} onClick={toggle}>{label}</h3>
      <div className={classes.toggleBody}>{children}</div>
    </>
  )
}

Hideable.propTypes = {
  label: PropTypes.string,
  children: PropTypes.node
}

export default Hideable
