import React from 'react'
import PropTypes from 'prop-types'

const customTooltipStyle = {
  container: {
    background: 'white',
    color: 'inherit',
    fontSize: 'inherit',
    borderRadius: '2px',
    boxShadow: 'rgb(0 0 0 / 25%) 0px 1px 2px',
    padding: '5px 9px'
  },
  content: {
    whiteSpace: 'pre',
    display: 'flex',
    alignItems: 'center'
  },
  span: ({ color }) => ({
    display: 'block',
    width: '12px',
    height: '12px',
    background: color,
    marginRight: '7px'
  })
}

const BasicChartTooltip = ({ label, color }) => {
  return (
    <div style={customTooltipStyle.container}>
      <div style={customTooltipStyle.content}>
        <span style={customTooltipStyle.span({ color })} />
        <span>{label}</span>
      </div>
    </div>
  )
}

BasicChartTooltip.propTypes = {
  label: PropTypes.string,
  color: PropTypes.string
}

export default BasicChartTooltip
