import React, { useCallback, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { animated, useSpring } from 'react-spring'
import { makeStyles } from '@material-ui/core/styles'
import { IconButton, darken } from '@material-ui/core'
import isEmpty from 'lodash.isempty'
import noop from 'lodash/noop'
import clsx from 'clsx'
import { useAppContext, useSetIsNotesBoardOpen } from '../../../redux/slices/appContext'
import { useFeatureFlag } from '../../../redux/slices/appConfig'
import { useSetNotesClientId } from '../../../redux/slices/noteContext'
import { fetchSalesforceClient } from '../../../service'
import {
  HTTP_STATUS_CODES,
  FEATURE_FLAG,
  ICON_NAMES,
  TEXT_VARIANTS, SALESFORCE_INTEGRATION
} from '../../../constants'

import RightPanel from '../../organisms/RightPanel'
import Icon from '../../atoms/Icon'
import Text from '../../atoms/Text'
import SalesforceInfo from './SalesforceInfo'

export const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '220px',
    height: '260px',
    background: theme.palette.gray.lighter,
    borderRadius: '8px',
    margin: '0px 50px 40px 0px',
    border: `2px solid ${theme.palette.gray.lighter}`,
    boxSizing: 'border-box',
    '&:hover': {
      background: theme.palette.common.white,
      border: '2px solid rgba(33, 41, 69, 0.24)',
      cursor: 'pointer'
    }
  },
  actions: ({ withActionsBackground }) => ({
    position: 'absolute',
    bottom: 0,
    height: '50px',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: withActionsBackground ? theme.palette.common.white : 'transparent'
  }),
  iconButton: {
    margin: '0 5px',
    padding: '1rem',
    backgroundColor: theme.palette.seaFoam,
    '&:hover': {
      backgroundColor: darken(theme.palette.seaFoam, 0.1)
    }
  },
  iconButtonDisabled: {
    cursor: 'not-allowed !important',
    opacity: '0.5',
    '&:hover': {
      backgroundColor: theme.palette.seaFoam
    }
  },
  notification: {
    backgroundColor: theme.palette.success.A500,
    color: theme.palette.white,
    '&:hover': {
      backgroundColor: `${darken(theme.palette.success.A500, 0.1)} !important`
    }
  },
  notificationIcon: {
    padding: '0.75rem !important',
    position: 'absolute',
    margin: '0 !important',
    right: '0.5rem',
    top: '0.5rem'
  }
}))

function TeamCard ({
  data,
  children,
  layoutSettings,
  onClick
}) {
  const {
    name, clientsSettings: {
      withChat = true,
      withNotification = false,
      withActionsBackground = true
    }
  } = layoutSettings
  const classes = useStyles({ withActionsBackground })
  const { clientsNotifications, isAdvisor } = useAppContext()
  const { active: INTEGRATIONS, extras: INTEGRATIONS_LIST = { active: [] } } = useFeatureFlag(FEATURE_FLAG.INTEGRATIONS)
  // const { active: SHOW_SALESFORCE_CLIENT_INFO } = useFeatureFlag(FEATURE_FLAG.SHOW_SALESFORCE_CLIENT_INFO)
  const SHOW_SALESFORCE_CLIENT_INFO = INTEGRATIONS && INTEGRATIONS_LIST.active.includes(SALESFORCE_INTEGRATION)
  const [isHovered, setIsHovered] = useState(false)
  const [openRightPanel, setOpenRightPanel] = useState(false)
  const [salesforceInfo, setSalesforceInfo] = useState({})

  const onMouseEnter = () => setIsHovered(true)
  const onMouseLeave = () => setIsHovered(false)

  const setNotesClientId = useSetNotesClientId()
  const setIsNotesBoardOpen = useSetIsNotesBoardOpen()

  const actionsProps = useSpring({
    from: {
      bottom: 0,
      opacity: 0
    },
    to: {
      bottom: isHovered ? 20 : 0,
      opacity: isHovered ? 1 : 0
    }
  })

  const clientInfo = useMemo(() => {
    const { clientId, salesforceId } = data?.client || {}
    return {
      clientId,
      salesforceId
    }
  }, [data])

  const hasNotification = useMemo(
    () => {
      if (data?.client) {
        const { clientId } = data.client
        const clientNotification = clientsNotifications
          .find(({ clientId: clientWithNotificationId }) => clientWithNotificationId === clientId)
        return clientNotification && clientNotification.notifications.unread !== 0
      }
    },
    [data, clientsNotifications]
  )

  const onCommentClick = useCallback(
    async (e) => {
      e.stopPropagation()
      setNotesClientId(clientInfo.clientId)
      setIsNotesBoardOpen(true)
    },
    [clientInfo, setNotesClientId, setIsNotesBoardOpen]
  )

  const onGetExtraInfo = async (event) => {
    event.stopPropagation()
    setOpenRightPanel(true)
    setIsHovered(false)

    if (isEmpty(salesforceInfo)) {
      const { response, data } = await fetchSalesforceClient({
        clientId: clientInfo.salesforceId
      })

      if (response.status === HTTP_STATUS_CODES.SUCCESS) {
        setSalesforceInfo(data)
      } else if (response.status === HTTP_STATUS_CODES.NO_CONTENT) {
        setSalesforceInfo({
          _empty: true,
          clientId: clientInfo.salesforceId
        })
      } else {
        console.error(`Unexpected response status: ${response.status}`)
        setSalesforceInfo({
          _error: true,
          clientId: clientInfo.salesforceId
        })
      }
    }
  }

  const closeRightPanel = (event) => {
    event.stopPropagation()
    setOpenRightPanel(false)
  }

  return (
    <div
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      className={classes.container}
      name={name}
      onClick={onClick}
    >
      {
        withNotification && hasNotification && (
          <IconButton className={clsx(classes.iconButton, classes.notificationIcon, classes.notification)} onClick={noop}>
            <Icon name={ICON_NAMES.chat} customSize='1rem' padding='0.75rem' />
          </IconButton>
        )
      }
      {children}
      <animated.div
        style={actionsProps}
        className={classes.actions}
      >
        <>
          {withChat && (
            <IconButton className={clsx(classes.iconButton, { [classes.notification]: hasNotification })} onClick={onCommentClick}>
              <Icon
                name='chat'
                customSize='1.125rem'
                padding='1rem'
              />
            </IconButton>
          )}

          {SHOW_SALESFORCE_CLIENT_INFO && isAdvisor && (
            <IconButton className={clsx(classes.iconButton)} onClick={onGetExtraInfo}>
              <Icon name={ICON_NAMES.salesforceSmall} customSize='1.125rem' padding='1rem' />
            </IconButton>
          )}
        </>
      </animated.div>
      <RightPanel
        open={openRightPanel}
        title={<Text text='Salesforce Info' variant={TEXT_VARIANTS.h2} />}
        width='400px'
        height='100%'
      >
        <SalesforceInfo data={salesforceInfo} onClose={closeRightPanel} />
      </RightPanel>
    </div>
  )
}

TeamCard.propTypes = {
  children: PropTypes.node,
  data: PropTypes.object.isRequired,
  layoutSettings: PropTypes.shape({
    name: PropTypes.string,
    clientsSettings: PropTypes.shape({
      type: PropTypes.string,
      withChat: PropTypes.bool,
      withActions: PropTypes.bool,
      withActionsBackground: PropTypes.bool,
      withNotification: PropTypes.bool
    })
  }).isRequired,
  onClick: PropTypes.func
}

TeamCard.defaultProps = {
  onClick: undefined
}

export default TeamCard
