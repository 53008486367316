import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Box } from '@material-ui/core'
import { useTheme } from '@material-ui/styles'
import { makeStyles } from '@material-ui/core/styles'
import { CALC_TYPES, LEVEL_TYPES, SKELETON_VARIANTS, TEXT_VARIANTS } from '../../../../constants'
import { useAvailableDates } from '../../../../redux/slices/appContext'
import { getSafeDate, tableNumberFormatter } from '../../../../utils'
import Text from '../../../atoms/Text'
import Skeleton from '../../../atoms/Skeleton'
import { useGetGroupedCoreData } from '../../../../api/coreData'

const useStyles = makeStyles(() => ({
  inlineText: {
    display: 'inline'
  }
}))

const CashReportSummary = ({ advisorId, tagId, filterLevel }) => {
  const theme = useTheme()
  const classes = useStyles()
  const [availableDates] = useAvailableDates()

  const { query, queryOptions } = useMemo(
    () => ({
      query: {
        dateRange: {
          startDate: getSafeDate(availableDates, { unitValue: 7 }),
          endDate: availableDates.mainDate
        },
        levelFilters: {
          levelTypes: [LEVEL_TYPES.ADVISOR],
          ...(filterLevel && filterLevel ? { [filterLevel]: [tagId] } : {}),
          advisorIds: [advisorId],
          calcType: CALC_TYPES.performance
        }
      },
      queryOptions: {
        mapper: (data) => data?.[0] ?? {}
      }
    }),
    [advisorId, tagId, filterLevel, availableDates]
  )

  const {
    data: summary,
    error,
    isLoading: loading
  } = useGetGroupedCoreData(query, queryOptions)

  const reportSummary = () => {
    if (loading) {
      return (
        <>
          <Skeleton width='100%' height='3.5rem' variant={SKELETON_VARIANTS.text} />
          <Skeleton width='100%' height='1rem' variant={SKELETON_VARIANTS.text} />
        </>
      )
    }

    if (error) {
      return <div>Error! {error.toString()}</div>
    }

    const flowChange = tableNumberFormatter(summary?.netFlows || 0)

    const flowColor =
      summary?.netFlows > 0
        ? theme.palette.mediumSeaGreen
        : theme.palette.error.primary

    const flowChangeText =
      summary?.netFlows > 0 ? `+${flowChange}` : flowChange

    return (
      <>
        <Text
          text={flowChangeText}
          lineHeight='1'
          customFontSize='1rem'
          variant={TEXT_VARIANTS.body2}
          color={flowColor}
          rootClassName={classes.inlineText}
        />
        {' '}
        <Text
          text='in last 7 days'
          lineHeight='1'
          customFontSize='1rem'
          variant={TEXT_VARIANTS.body2}
          customFontWeight='300'
          color={theme.palette.summitBlue}
          rootClassName={classes.inlineText}
        />
      </>
    )
  }

  return (
    <Box height='100%' width='100%' style={{ padding: '1rem' }}>
      <Box mb='1.5rem'>
        <Text text='Cash Report' variant='body2' customFontSize='1.2rem' />
      </Box>
      <Box mb='0.5rem'>
        {loading ? (
          <Skeleton width='100%' height='3.5rem' variant={SKELETON_VARIANTS.text} />
        ) : (
          <Text
            text={tableNumberFormatter(summary?.endingValue || 0)}
            lineHeight='1'
            customFontSize='3.5rem'
            variant={TEXT_VARIANTS.body2}
            color={theme.palette.summitBlue}
          />
        )}

      </Box>
      <Box>
        {reportSummary}
      </Box>
    </Box>
  )
}

CashReportSummary.propTypes = {
  advisorId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  tagId: PropTypes.number,
  filterLevel: PropTypes.string
}

export default CashReportSummary
