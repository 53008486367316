import React, { useCallback, useEffect, useMemo } from 'react'
import { Box, makeStyles } from '@material-ui/core'
import { FormProvider, useForm } from 'react-hook-form'
import SectionScreen from '../SectionScreen'
import { useClientDetails, useSectionEditing } from '../ClientDetailsFormContext'
import EditButton from '../EditButton'
import { useFeeSchedule, useSaveClientBillingInfoMutation } from '../../../../../api/billing'
import SectionHeader from '../../shared/SectionHeader'
import PersonalSpace from '../../shared/PersonalSpace'
import { useClientBillingInfo } from '../../../../../api/clients'
import Skeleton from '../../../../atoms/Skeleton'
import { usePolicy } from '../../../../../hooks/usePolicy'
import FeeScheduleAssignment from './FeeScheduleAssignment'
import FeeOverridesAssignment from './FeeOverridesAssignment'
import { useOverrideAssignments } from './useOverrideAssignments'
import FamilyRateAssignment from './FamilyRateAssignment'

const useStyles = makeStyles((theme) => ({
  feeScheduleSection: {
    position: 'relative',
    padding: '10px'
  }
}))

const useFeeSchedules = (client) => {
  const { data: feeSchedules, isLoading: feeSchedulesLoading } = useFeeSchedule()
  const { data: billingInfo, isLoading: billingInfoLoading, refetch } = useClientBillingInfo(client.clientId)

  return {
    feeSchedules,
    billingInfo,
    refetch,
    isLoading: feeSchedulesLoading || billingInfoLoading
  }
}

const useFormDefaults = ({ clientId, billingInfo, editing }) => {
  const defaultValues = useMemo(() => {
    return {
      clientId,
      feeOverrides: billingInfo?.client?.feeOverrides || [],
      feeScheduleId: billingInfo?.client?.feeSchedule?.feeScheduleId || '',
      addExtraBalance: billingInfo?.client?.feeSchedule?.extraJSON?.addExtraBalance ?? []
    }
  }, [clientId, billingInfo])

  const formMethods = useForm({
    mode: 'onChange',
    reValidateMode: 'onSubmit',
    criteriaMode: 'all',
    defaultValues
  })

  useEffect(() => {
    formMethods.reset(defaultValues)
  }, [defaultValues, formMethods, editing])

  return formMethods
}

/**
 * @param {object} oldState
 * @param {number | null} oldState.client.feeSchedule.feeScheduleId
 * @param {[]} oldState.client.feeOverrides
 * @param {object} newState
 * @param {number | null} newState.feeScheduleId
 * @param {number[] | null} newState.addExtraBalance
 * @param {[]} newState.feeOverrides
 */
function mapSave (oldState, newState) {
  const oldFeeScheduleId = oldState.client?.feeSchedule?.feeScheduleId
  const newFeeScheduleId = newState.feeScheduleId
  const addExtraBalance = newState.addExtraBalance
  const removeFeeScheduleId = oldFeeScheduleId && !newFeeScheduleId
    ? oldFeeScheduleId
    : null

  const feeScheduleId = newFeeScheduleId || null

  const oldOverrides = oldState.client.feeOverrides
  const newOverrides = newState.feeOverrides

  const removeFeeOverrides = oldOverrides.filter(old => {
    return !newOverrides.some(n => n.feeLevelOverrideId === old.feeLevelOverrideId)
  }).map(old => old.feeLevelOverrideId)
  const feeOverrides = newOverrides.map(n => ({
    feeLevelOverrideId: n.feeLevelOverrideId,
    level: n.overrideLevelType,
    levelId: n.overrideLevelId,
    feeScheduleId: n.feeScheduleId
  }))

  return {
    feeScheduleId,
    removeFeeScheduleId,
    feeOverrides,
    removeFeeOverrides,
    extraJSON: {
      ...(oldState?.client?.feeSchedule?.extraJSON ?? {}),
      addExtraBalance: (addExtraBalance?.length && feeScheduleId) ? addExtraBalance : null
    }
  }
}

function FeeSchedulesSection () {
  const classes = useStyles()
  const { client, editSection } = useClientDetails()
  const canAssignFeeSchedules = usePolicy('billing_assign_fee_schedules')
  const canEditOverrides = usePolicy('billing_edit_overrides')
  const canEditFamilyRates = usePolicy('billing_edit_family_rates')
  const editing = useSectionEditing('fee-schedule')
  const { feeSchedules, billingInfo, isLoading, refetch } = useFeeSchedules(client)
  const { handleSubmit, ...formMethods } = useFormDefaults({
    clientId: client.clientId,
    billingInfo,
    editing
  })
  const { mutateAsync: saveBillingInfo } = useSaveClientBillingInfoMutation()
  const { data: assigned, isLoading: assignedLoading } = useOverrideAssignments(client, billingInfo, isLoading)

  const feeScheduleOptions = useMemo(() => {
    if (isLoading) return []
    return feeSchedules.map(x => ({
      label: x.longName,
      value: x.feeScheduleId
    }))
  }, [isLoading, feeSchedules])

  const onSave = useCallback(async (e) => {
    const onSuccess = async (form) => {
      await saveBillingInfo({
        clientId: client.clientId,
        billingInfo: mapSave(billingInfo, {
          feeScheduleId: form.feeScheduleId,
          feeOverrides: form.feeOverrides,
          addExtraBalance: form.addExtraBalance.map(x => x.value)
        })
      })
    }
    const onError = (errors) => {
      // eslint-disable-next-line no-throw-literal
      throw { validationErrors: errors }
    }
    const handler = handleSubmit(onSuccess, onError)
    await handler(e)

    await refetch()
  }, [handleSubmit, billingInfo, saveBillingInfo, client, refetch])

  if (isLoading || assignedLoading) {
    return (
      <Box display='flex' flexDirection='column' gridGap='20px' mt='28px'>
        <Skeleton width='250px' height='30px' />
        <Box display='flex' flexDirection='column' gridGap='5px'>
          <Skeleton width='150px' height='30px' />
          <Skeleton width='540px' height='55px' />
        </Box>
        <Box display='flex' flexDirection='column' gridGap='5px'>
          <Skeleton width='150px' height='30px' />
          <Skeleton width='540px' height='55px' />
        </Box>
      </Box>
    )
  }

  return (
    <FormProvider handleSubmit={handleSubmit} {...formMethods}>
      <SectionScreen sectionName='fee-schedule' className={classes.feeScheduleSection}>
        <SectionHeader text='Billing Details'>
          <div>
            <EditButton
              policy='admin_edit_client_billing'
              editing={editing}
              onClick={() => editSection({
                section: 'fee-schedule',
                saveDescription: 'Save Fee Schedule',
                onSave
              })}
            />
          </div>
        </SectionHeader>
        <FeeScheduleAssignment
          editing={editing && canAssignFeeSchedules}
          feeSchedules={feeSchedules}
          feeScheduleOptions={feeScheduleOptions}
        />
        {formMethods.watch('feeScheduleId') && (
          <FamilyRateAssignment
            editing={editing && canEditFamilyRates}
            control={formMethods.control}
          />)}
        <FeeOverridesAssignment
          editing={editing && canEditOverrides}
          feeScheduleOptions={feeScheduleOptions}
          assigned={assigned}
        />
        <PersonalSpace />
      </SectionScreen>
    </FormProvider>
  )
}

export default FeeSchedulesSection
