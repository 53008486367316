import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import PropTypes, { arrayOf } from 'prop-types'
import { Box, darken, getLuminance, makeStyles, recomposeColor } from '@material-ui/core'
import { decomposeColor } from '@material-ui/core/styles/colorManipulator'
import numeral from 'numeral'
import { Pie } from '@nivo/pie'
import { BasicTooltip } from '@nivo/tooltip'
import theme from '../../../../theme'
import { TEXT_VARIANTS } from '../../../../constants'
import Text from '../../../atoms/Text'

const useStyles = makeStyles(() => ({
  container: {
    position: 'relative'
  }
}))

const CenteredMetric = ({ dataWithArc, centerX, centerY, innerRadius }) => {
  const metricValue = useMemo(() => dataWithArc.find(row => row.id === 'successRatio'), [dataWithArc])
  const metricRange = useMemo(() => metricValue?.data?.range ?? { min: 0, max: 0 }, [metricValue])
  const fillColor = useMemo(() => darken(metricValue?.color, 0.25), [metricValue?.color])
  const [displayMin, setDisplayMin] = React.useState(metricRange.min)
  const [displayMax, setDisplayMax] = React.useState(metricRange.max)

  const animateMinIntervalRef = useRef(0)
  const animateMin = useCallback((newValue) => {
    clearInterval(animateMinIntervalRef.current)
    animateMinIntervalRef.current = setInterval(() => {
      setDisplayMin((oldValue) => {
        if (oldValue === newValue || oldValue < 0.001) {
          clearInterval(animateMinIntervalRef.current)
          return newValue
        }
        return oldValue + (newValue - oldValue) / 10
      })
    }, 25)
  }, [])

  const animateMaxIntervalRef = useRef(0)
  const animateMax = useCallback((newValue) => {
    clearInterval(animateMaxIntervalRef.current)
    animateMaxIntervalRef.current = setInterval(() => {
      setDisplayMax((oldValue) => {
        if (oldValue === newValue || oldValue < 0.001) {
          clearInterval(animateMaxIntervalRef.current)
          return newValue
        }
        return oldValue + (newValue - oldValue) / 10
      })
    }, 25)
  }, [])

  useEffect(() => {
    if (displayMin !== metricRange.min) {
      animateMin(metricRange.min)
    }
    if (displayMax !== metricRange.max) {
      animateMax(metricRange.max)
    }
  }, [animateMin, animateMax, displayMax, displayMin, metricRange])

  const formattedValue = useMemo(() => {
    const formattedObj = {
      min: numeral(displayMin * 100).format('0'),
      max: numeral(displayMax * 100).format('0')
    }
    if (formattedObj.min === formattedObj.max) {
      return formattedObj.max + '%'
    }
    return `${formattedObj.min} - ${formattedObj.max}%`
  }, [displayMax, displayMin])

  return (
    <text
      x={centerX}
      y={centerY - 10}
      textAnchor='middle'
      dominantBaseline='central'
      style={{ fontSize: `${innerRadius / 48}rem`, fill: fillColor }}
    >
      {formattedValue}
    </text>
  )
}
CenteredMetric.propTypes = {
  dataWithArc: arrayOf(PropTypes.shape({
    id: PropTypes.string,
    value: PropTypes.number,
    formattedValue: PropTypes.string
  })),
  centerX: PropTypes.number,
  centerY: PropTypes.number,
  innerRadius: PropTypes.number
}

const CustomTooltip = ({ datum }) => {
  const formattedValue = useMemo(() => {
    const range = datum.data?.range ?? { min: 0, max: 0 }
    const formattedObj = {
      min: numeral(range.min * 100).format('0'),
      max: numeral(range.max * 100).format('0')
    }
    if (formattedObj.min === formattedObj.max) {
      return formattedObj.max + '%'
    }
    return `${formattedObj.min}-${formattedObj.max}%`
  }, [datum])

  if (datum?.data?.hideTooltip) {
    return null
  }
  return (
    <BasicTooltip
      id={datum.label}
      value={formattedValue}
      enableChip
      color={datum.color}
    />)
}
CustomTooltip.propTypes = {
  datum: PropTypes.shape({
    label: PropTypes.string,
    formattedValue: PropTypes.string,
    color: PropTypes.string,
    data: PropTypes.arrayOf(PropTypes.shape({
      hideTooltip: PropTypes.bool,
      range: PropTypes.objectOf(PropTypes.shape({
        min: PropTypes.number,
        max: PropTypes.number
      }))
    }))
  })
}

const CategorySuccessChart = ({
  range,
  color,
  width = 140,
  height = 70,
  isInteractive,
  isUpdating = false
}) => {
  const classes = useStyles()

  const standardizedColor = useMemo(() => {
    if (getLuminance(color) > 0.7) {
      const colorObj = decomposeColor(color)
      if (colorObj.type === 'rgb') {
        return recomposeColor({
          ...colorObj,
          values: colorObj.values.map((v, i) => i !== 1 ? Math.max(v - 35, 0) : v)
        })
      }
      return darken(color, 0.1)
    }
    return color
  }, [color])

  const data = useMemo(() => {
    return [{
      label: 'Probability of Success',
      id: 'successRatio',
      value: range.max,
      range: range,
      color: standardizedColor ?? theme.palette.summitBlue
    }, {
      label: 'Remaining',
      id: 'remaining',
      value: 1 - range.max,
      color: theme.palette.lightGrey,
      hideTooltip: true
    }]
  }, [range, standardizedColor])

  return (
    <div className={classes.container}>
      <Pie
        isInteractive={isInteractive}
        animate
        width={width}
        height={height}
        enableArcLabels={false}
        enableArcLinkLabels={false}
        data={data}
        innerRadius={0.8}
        padAngle={0.5}
        cornerRadius={5}
        arcLinkLabelsThickness={3}
        startAngle={-90}
        endAngle={90}
        valueFormat={v => numeral(v).format('0%')}
        colors={c => c.data.color}
        layers={['arcs', CenteredMetric]}
        tooltip={CustomTooltip}
      />

      {isUpdating && (
        <Box position='absolute' left={0} top='100%' right={0} textAlign='center'>
          <Text variant={TEXT_VARIANTS.body} text='Calculating...' customFontSize='10px' />
        </Box>
      )}
    </div>
  )
}

CategorySuccessChart.propTypes = {
  range: PropTypes.shape({
    min: PropTypes.number,
    max: PropTypes.number
  }),
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  isInteractive: PropTypes.bool,
  isUpdating: PropTypes.bool
}

export default CategorySuccessChart
