import React, { forwardRef, useImperativeHandle, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { TextField, Grid, makeStyles } from '@material-ui/core'
import { useAppContext } from '../../../../../redux/slices/appContext'
import Switch from '../../../../molecules/Switch'
import Select from '../../../../molecules/Select'

const useStyles = makeStyles((theme) => ({
  formSwitch: {
    justifyContent: 'flex-end',
    display: 'flex',
    flexDirection: 'row',
    gap: '10px',
    alignItems: 'center'
  },
  formLayout: {
    maxWidth: '800px'
  },
  wrap: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  experience: {
    width: '100%'
  },
  personalSpace: {
    marginBottom: '40px'
  }
}))

const useFormValues = (person, defaultRoleOption) => {
  const { isSummitUser } = useAppContext()
  const defaultValues = useMemo(() => {
    return {
      user: {
        firstName: person?.firstName || '',
        lastName: person?.lastName || '',
        email: person?.email || '',
        userTypeId: 1,
        internal: isSummitUser
      },
      roleId: defaultRoleOption?.value ?? '',
      personId: person?.personId || ''
    }
  }, [person, isSummitUser, defaultRoleOption])
  const formValues = useForm({
    mode: 'onChange',
    reValidateMode: 'onSubmit',
    criteriaMode: 'all',
    defaultValues
  })

  return {
    ...formValues,
    isSummitUser
  }
}

const CreateUserForm = forwardRef(function CreateUserForm ({ person, roleOptions, defaultRoleOption }, ref) {
  const classes = useStyles()
  const { isSummitUser, handleSubmit, ...formValues } = useFormValues(person, defaultRoleOption)
  useImperativeHandle(ref, () => {
    return {
      handleSubmit
    }
  }, [handleSubmit])

  return (
    <FormProvider handleSubmit={handleSubmit} {...formValues}>
      <div className={classes.wrap}>
        <Grid className={classes.formLayout} container spacing={3}>
          <Grid className={classes.personalSpace} item xs={12}>
            Please confirm the details for the user. The created user will be assigned client access to this client.
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name='user.firstName'
              rules={{ required: true, maxLength: 30 }}
              render={({ field, fieldState }) => (
                <TextField
                  label='First Name'
                  fullWidth
                  helperText='Required, 30 max characters'
                  error={(fieldState.isDirty || fieldState.isTouched) && fieldState.error}
                  inputProps={field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name='user.lastName'
              rules={{ required: true, maxLength: 30 }}
              render={({ field, fieldState }) => (
                <TextField
                  label='Last Name'
                  fullWidth
                  helperText='Required, 30 max characters'
                  error={(fieldState.isDirty || fieldState.isTouched) && fieldState.error}
                  inputProps={field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name='user.email'
              rules={{ required: true, maxLength: 80, email: true }}
              render={({ field, fieldState }) => (
                <TextField
                  label='Email'
                  fullWidth
                  helperText='Required, 80 max characters'
                  error={(fieldState.isDirty || fieldState.isTouched) && fieldState.error}
                  inputProps={field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name='roleId'
              render={({ field, fieldState }) => (
                <Select
                  className={classes.experience}
                  placeholder='Select an Experience'
                  options={roleOptions}
                  onChange={field.onChange}
                  value={field.value}
                  showCheckMarOnSelectedItems
                  error={(fieldState.isDirty || fieldState.isTouched) && fieldState.error}
                />
              )}
            />
          </Grid>
          {isSummitUser ? (
            <Grid item xs={12} md={6}>
              <Controller
                name='user.internal'
                render={({ field, fieldState }) => (
                  <div className={classes.formSwitch}>
                    <span>Internal</span>
                    <Switch
                      onChange={field.onChange}
                      checked={field.value}
                    />
                  </div>
                )}
              />
            </Grid>
          ) : null}
        </Grid>
      </div>
    </FormProvider>
  )
})

CreateUserForm.propTypes = {
  person: PropTypes.shape({
    personId: PropTypes.number,
    firstName: PropTypes.number,
    lastName: PropTypes.number,
    email: PropTypes.number
  }),
  roleOptions: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.number
  })),
  defaultRoleOption: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.number
  })
}

export default CreateUserForm
