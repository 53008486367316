import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import CloseIcon from '@material-ui/icons/Close'
import { Button, ButtonGroup, Tooltip, alpha, makeStyles } from '@material-ui/core'
import noop from 'lodash/noop'
import clsx from 'clsx'

const baseButtonStyles = (theme, backgroundColor) => ({
  backgroundColor: backgroundColor || `${theme.palette.summitBlue} !important`,
  '&:hover': {
    backgroundColor: `${alpha(backgroundColor || theme.palette.mirage, 0.7)} !important`
  },
  color: `${theme.palette.getContrastText(
    backgroundColor || theme.palette.summitBlue
  )} !important`,
  border: '0px !important'
})

const useStyles = makeStyles((theme) => ({
  mainButton: ({ backgroundColor }) => ({
    padding: '4px 12px',
    fontSize: '.75rem',
    fontFamily: theme.typography.fontFamily,
    borderTopLeftRadius: '1rem',
    borderBottomLeftRadius: '1rem',
    maxWidth: '10rem',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap !important',
    display: 'flex',
    justifyContent: 'left',
    ...baseButtonStyles(theme, backgroundColor)
  }),
  secondaryButton: ({ backgroundColor }) => ({
    padding: '2px 6px 2px 4px',
    minWidth: '20px',
    borderTopRightRadius: '1rem',
    borderBottomRightRadius: '1rem',
    ...baseButtonStyles(theme, backgroundColor)
  }),
  icon: ({ iconFontSize }) => ({
    fontSize: `${iconFontSize} !important` || '0.75rem'
  })
}))

const SplitButton = ({
  icon,
  text,
  onClick,
  className,
  buttonClassName,
  buttonMainClassName,
  buttonSecondaryClassName,
  onClickIcon,
  iconFontSize,
  backgroundColor,
  readOnly,
  tooltipText,
  withTooltip
}) => {
  const classes = useStyles({ iconFontSize, backgroundColor })
  const Icon = useMemo(() => icon || CloseIcon, [icon])

  const mainButton = useMemo(() => (
    <Button
      onClick={onClick}
      disabled={readOnly}
      className={clsx(classes.mainButton, {
        [buttonClassName]: !!buttonClassName,
        [buttonMainClassName]: !!buttonMainClassName
      })}
    >
      {text}
    </Button>

  ), [
    buttonClassName,
    buttonMainClassName,
    classes.mainButton,
    onClick,
    readOnly,
    text
  ])

  return (
    <ButtonGroup
      variant='contained'
      aria-label='split button'
      className={className || ''}
      disableElevation
    >
      {withTooltip && (
        <Tooltip title={tooltipText}>
          {mainButton}
        </Tooltip>
      )}
      {!withTooltip && mainButton}
      <Button
        size='small'
        aria-expanded='true'
        aria-haspopup='false'
        aria-controls='split-button-menu'
        aria-label='split button right section'
        onClick={onClickIcon}
        disabled={readOnly}
        className={clsx(classes.secondaryButton, {
          [buttonClassName]: !!buttonClassName,
          [buttonSecondaryClassName]: !!buttonSecondaryClassName
        })}
      >
        <Icon className={classes.icon} />
      </Button>
    </ButtonGroup>
  )
}

SplitButton.propTypes = {
  icon: PropTypes.any,
  text: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
  onClickIcon: PropTypes.func,
  iconFontSize: PropTypes.string,
  buttonClassName: PropTypes.string,
  buttonMainClassName: PropTypes.string,
  buttonSecondaryClassName: PropTypes.string,
  backgroundColor: PropTypes.string,
  readOnly: PropTypes.bool,
  tooltipText: PropTypes.string,
  withTooltip: PropTypes.bool
}

SplitButton.defaultProps = {
  icon: undefined,
  text: undefined,
  onClick: noop,
  className: '',
  onClickIcon: noop,
  iconFontSize: undefined,
  buttonClassName: '',
  buttonMainClassName: '',
  buttonSecondaryClassName: '',
  backgroundColor: '',
  readOnly: false,
  tooltipText: '',
  withTooltip: false
}

export default SplitButton
