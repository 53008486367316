import React, { useCallback, useImperativeHandle, useState } from 'react'
import PropTypes from 'prop-types'
import RoundedModal from '../../../../../molecules/RoundedModal'
import EditSsoAssignmentForm from './form'

const EditSsoAssignmentDialog = React.forwardRef(function EditSsoAssignmentDialog ({ roleId }, ref) {
  const [dialogState, setDialogState] = useState({ open: false, processing: false })
  const close = useCallback(() => {
    setDialogState({ open: false, processing: false })
  }, [setDialogState])

  useImperativeHandle(ref, () => ({
    open: (ssoProvider) => setDialogState({ open: true, processing: false, ssoProvider })
  }), [setDialogState])

  return (
    <RoundedModal
      title={dialogState.ssoProvider ? `Edit ${dialogState.ssoProvider.name} Settings` : 'Edit SSO Settings'}
      open={dialogState.open}
      onClose={close}
      position='top'
      size='large'
      disablePortal={false}
    >
      <div>
        {dialogState.open ? (<EditSsoAssignmentForm roleId={roleId} sso={dialogState.ssoProvider} onComplete={close} onCancel={close} />) : null}
      </div>
    </RoundedModal>
  )
})

EditSsoAssignmentDialog.propTypes = {
  roleId: PropTypes.number
}

export default EditSsoAssignmentDialog
