import React from 'react'
import { makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import WYSIWYGEditor from '../../../../WYSIWYGEditor'

const useStyles = makeStyles((theme) => ({
  markdownEditor: {
    border: `1px solid ${theme.palette.gray.dark}`,
    borderRadius: theme.layout.radius.tight,
    padding: theme.layout.padding.p10,
    width: '100%',
    minHeight: 'max(10vh, 120px)'
  }
}))

function MarkdownFieldInput (props) {
  const classes = useStyles()

  return (
    <WYSIWYGEditor
      className={classes.markdownEditor}
      placeholder='Add some meeting notes...'
      {...props}
      defaultValue={props.value}
      isMarkdown
    />
  )
}

MarkdownFieldInput.propTypes = {
  value: PropTypes.any
}

export default MarkdownFieldInput
