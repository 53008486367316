import dayjs from 'dayjs'

const stringTemplate = (col, size) => size
  ? x => x && x[col.field] && x[col.field].substring(0, size)
  : x => x && x[col.field]

const numberTemplate = (col, places) => places
  ? x => x && Number(x[col.field]) && Number(x[col.field]).toFixed(places)
  : x => x && Number(Number(x[col.field]).toFixed(0)).toLocaleString()

const dateTemplate = (col, format = 'M/D/YY') => x => x && (x[col.field] ? dayjs.utc(x[col.field]).format(format) : '')

const formattedBoolTemplate = (trueStr, falseStr) => (col) => x => x && (x[col.field] ? trueStr : falseStr)

const cellTemplates = {
  default: stringTemplate,
  string: stringTemplate,
  number: numberTemplate,
  integer: col => numberTemplate(col, 0),
  decimal: numberTemplate,
  date: dateTemplate,
  datetime: dateTemplate,
  bool: formattedBoolTemplate('true', 'false'),
  yesNo: formattedBoolTemplate('Y', 'N')
}

export default cellTemplates
