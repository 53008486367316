import PropTypes from 'prop-types'
import FamilyTreeContainer from './container'
import FamilyTreeNode from './node'
import FamilyTreeLevelContainer from './levelContainer'
import FamilyTreeSkeleton from './skeleton'

const FamilyTree = ({ children }) => {
  return children
}

FamilyTree.propTypes = {
  children: PropTypes.node
}

FamilyTree.LevelContainer = FamilyTreeLevelContainer
FamilyTree.Container = FamilyTreeContainer
FamilyTree.Node = FamilyTreeNode
FamilyTree.Skeleton = FamilyTreeSkeleton

export default FamilyTree
