const suppression = new Map()
const subscriptions = new Set()

const invokeSubscriptions = () => {
  const toDelete = []
  for (const subscription of subscriptions) {
    try {
      subscription()
    } catch (e) {
      console.warn('global suppression subscription not found')
      toDelete.push(subscription)
    }
  }
  toDelete.forEach((e) => {
    subscriptions.delete(e)
  })
}

export const setSuppression = (key, value) => {
  suppression.set(key, !!value)
  invokeSubscriptions()
}

export const getSuppression = (key) => {
  if (suppression.has(key)) {
    return suppression.get(key)
  }
  return false
}

export const clearSuppression = (key) => {
  if (suppression.has(key)) {
    suppression.delete(key)
    invokeSubscriptions()
  }
}

export const subscribeSuppression = (callback) => {
  subscriptions.add(callback)
  return () => {
    subscriptions.delete(callback)
  }
}
