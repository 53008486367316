import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import Avatar from '@material-ui/core/Avatar'
import { useFormattingContext } from '../FormattingProvider/FormattingContext'

const useStyles = makeStyles((theme) => ({
  holdingListItem: {
    margin: '.5rem 1rem',
    padding: '.2rem 0',
    '& .__holding-wrapper': {
      display: 'flex',
      flexDirection: 'row'
    },
    '& .__holding-avatar': {
      margin: '0 1rem 0 0',
      display: 'flex',
      alignItems: 'center'
    },
    '& .__holding-details': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      flex: '1 0'
    },
    '& .__holding-details-pair': {
      display: 'flex',
      flexDirection: 'row',
      '& > *:first-child': {
        flex: '1 0'
      },
      '& > *:last-child': {
        justifySelf: 'flex-end'
      }
    }
  },
  change: {
    color: ({ change }) => (change > 0)
      ? theme.palette.change.positive
      : (change < 0)
        ? theme.palette.change.negative
        : 'inherit'
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    fontSize: '.8rem'
  }
}))

const nullish = [null, undefined]

const HoldingListItem = ({
  className,
  name,
  alternateName,
  value,
  valueFormat,
  change,
  changeFormat,
  avatarSrc,
  showChange
}) => {
  const classes = useStyles({ change })
  const { formatter } = useFormattingContext()

  const [formattedValue, formattedChange] = useMemo(() => {
    const fv = formatter(value, valueFormat)
    const fc = nullish.includes(change) ? '' : formatter(change, changeFormat)
    return [fv, fc]
  }, [formatter, value, change, valueFormat, changeFormat])

  const hasSecondRow = useMemo(
    () => !!change || !!alternateName,
    [change, alternateName]
  )

  return (
    <div className={clsx(classes.holdingListItem, className)}>
      <div className='__holding-wrapper'>
        <div className='__holding-avatar'>
          <Avatar className={classes.small} src={avatarSrc}>
            {name?.at(0) ?? '?'}
          </Avatar>
        </div>
        <div className='__holding-details'>
          <div className='__holding-details-pair'>
            <div className='__holding-name'>{name ?? '--'}</div>
            <div className='__holding-name'>{formattedValue}</div>
          </div>
          {hasSecondRow && (
            <div className='__holding-details-pair'>
              <div className='__holding-alternateName'>{alternateName}</div>
              {showChange && (
                <div className='__holding-change'>
                  <span className={classes.change}>{formattedChange}</span>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

HoldingListItem.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  alternateName: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  valueFormat: PropTypes.string,
  change: PropTypes.number,
  changeFormat: PropTypes.string,
  avatarSrc: PropTypes.string,
  showChange: PropTypes.bool
}

HoldingListItem.defaultProps = {
  valueFormat: 'human',
  changeFormat: 'human',
  showChange: false
}

export default HoldingListItem
