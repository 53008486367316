import React from 'react'
import PropTypes from 'prop-types'
import { usePolicy } from '../../../../hooks/usePolicy'
import SydButton from '../../../commonDesign/Button'

function EditButton ({ policy, onClick, editing, disabled, buttonText }) {
  const allowed = usePolicy(policy)

  if (!allowed) return null

  return (
    <SydButton
      size='sm'
      icon='edit'
      disabled={disabled}
      variant={editing ? 'primary' : 'outline'}
      onClick={onClick}
    >
      {buttonText}
    </SydButton>
  )
}

EditButton.propTypes = {
  policy: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  editing: PropTypes.bool,
  buttonText: PropTypes.string
}

EditButton.defaultProps = {
  policy: null,
  disabled: false,
  editing: false,
  buttonText: 'Edit'
}

export default EditButton
