import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Tooltip } from '@material-ui/core'
import isBoolean from 'lodash/isBoolean'
import isEmpty from 'lodash/isEmpty'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { DATE_FORMAT_US_LONG } from '../../../../../constants'

dayjs.extend(utc)

const mapExceptionNotes = (exceptionFootnote, rowOriginal) => {
  const result = Object.entries(exceptionFootnote).reduce((acc, [exceptionNoteKey, exceptionNoteValues]) => {
    if (rowOriginal?.[exceptionNoteKey] === undefined) return acc
    if (exceptionNoteKey === 'firstDataAvailable') return acc // skip this key because it has special handling

    // this matches the exception node to the value from the field in the core data response
    const exceptionNote = exceptionNoteValues[rowOriginal[exceptionNoteKey]]
    acc.push(exceptionNote)
    return acc
  }, []).filter(Boolean)

  if (result.length && 'firstDataAvailable' in exceptionFootnote) {
    result.unshift(`${exceptionFootnote.firstDataAvailable} ${dayjs.utc(rowOriginal?.firstDataAvailable).format(DATE_FORMAT_US_LONG)}`)
  }

  return result
}

const HoldingPeriodReturnCell = ({ column, value, row }) => {
  const { highlightSymbol, tooltipTitle, valueFormatted } = useMemo(() => {
    const { parent, payload, formatter, format } = column

    const rowOriginal = row?.original?.[parent?.id]
    const notHeldEntirePeriod = Boolean(rowOriginal && !rowOriginal?.heldEntirePeriod)
    const valueFormatted = formatter ? formatter(value, format) : value

    const {
      highlightSymbol,
      exceptionFootnote,
      showSymbolWhenNotHeldEntirePeriod
    } = payload || {}

    const placeholderSymbol = !isBoolean(showSymbolWhenNotHeldEntirePeriod)
      ? showSymbolWhenNotHeldEntirePeriod
      : '-'

    const tooltipTitle = exceptionFootnote ? mapExceptionNotes(exceptionFootnote, rowOriginal) : []
    return {
      highlightSymbol:
        (notHeldEntirePeriod || exceptionFootnote) && !isEmpty(tooltipTitle)
          ? highlightSymbol || '*'
          : '',
      valueFormatted: showSymbolWhenNotHeldEntirePeriod && notHeldEntirePeriod
        ? placeholderSymbol
        : valueFormatted,
      tooltipTitle: tooltipTitle.join('\n')
    }
  }, [column, row?.original, value])

  if (!value && !row) {
    return column.header
  }

  if (tooltipTitle) {
    return (
      <Tooltip
        title={<div style={{ whiteSpace: 'pre-line' }}>{tooltipTitle}</div>}
      >
        <span>
          {valueFormatted}
          {highlightSymbol}
        </span>
      </Tooltip>
    )
  }

  return (
    <span>
      {valueFormatted}
      {highlightSymbol}
    </span>
  )
}

HoldingPeriodReturnCell.propTypes = {
  value: PropTypes.oneOf([PropTypes.string, PropTypes.number]),
  row: PropTypes.object,
  column: PropTypes.shape({
    parent: PropTypes.object,
    header: PropTypes.string,
    format: PropTypes.string,
    payload: PropTypes.object,
    formatter: PropTypes.func,
    cellTooltipTitle: PropTypes.string
  })
}

export default HoldingPeriodReturnCell
