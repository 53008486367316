import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { Grid, Container, Box, makeStyles } from '@material-ui/core'

import { useViewContext } from '../../../redux/slices/viewContext'
import { useAppContext } from '../../../redux/slices/appContext'
import { fetchClient, getTeamMembers, getRoleTypes } from '../../../service'
import { useFetchState } from '../../../hooks'
import useAbortController from '../../../hooks/useAbortController'
import { SKELETON_VARIANTS, TEXT_VARIANTS } from '../../../constants'
import theme from '../../../theme'
import Skeleton from '../../atoms/Skeleton'
import Text from '../../atoms/Text'
import Divider from '../../atoms/Divider'
import FamilyValuesInput from '../../molecules/FamilyValuesInput'
import HouseHoldFamilyTree from '../../molecules/HouseHoldFamilyTree'
import Tag from '../../atoms/Tag'
import NoteButton from '../../molecules/NoteButton'
import RoundedButton from '../../atoms/RoundedButton'
import Avatar from '../../atoms/Avatar'
import TitleWithSubtitle from '../../molecules/TitleWithSubtitle'
import EmbeddedNotesBoard from '../../molecules/EmbeddedNotesBoard'
import Report from '../../molecules/Report'
import TeamCard from './TeamCard'

const INTERESTS = ['Golf', 'ESG', 'Equality', 'Giving', 'Wine', 'Travel', 'Seattle', 'Sailing', 'UCLA', 'Arts']
const REPORTS = [
  {
    title: 'December 2019',
    content: 'Your portfolio had a good month. You gained 1.8% over the last 30 days and 6.7%...'
  },
  {
    title: 'November 2019',
    content: 'Your portfolio had a good month. You gained 2.3% over the last 30 days...'
  },
  {
    title: 'October 2019',
    content: 'Your portfolio had a good month. You gained 1.6% over the last 30 days...'
  }
]

const useStyles = makeStyles(() => ({
  datePicker: {
    marginBottom: '1.25rem'
  },
  skeleton: {
    position: 'relative',
    top: '1.875rem'
  },
  balanceSheetContainer: {
    marginBottom: '2.5rem',
    marginTop: '1.25rem'
  },
  endContent: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  centerText: {
    textAlign: 'center'
  },
  bottom15: {
    marginBottom: '0.938rem'
  },
  bottom32: {
    marginBottom: '2rem'
  },
  teamContainer: {
    margin: '3rem 0px 2rem'
  },
  avatarContainer: {
    margin: '1.875rem 0px 0.625rem'
  },
  role: {
    marginTop: '0.938rem'
  },
  report: {
    marginTop: '0.5rem'
  }
}))

const cardSettings = {
  name: 'card',
  clientsSettings: {
    type: 'card',
    withChat: false,
    withActions: false,
    withActionsBackground: false
  }
}

const Household = ({
  teamTitle,
  lifeStatement,
  showFamilyTree,
  showInterests,
  showReports,
  isPublicResource,
  extraStyles
}) => {
  const classes = useStyles()
  const { clientId } = useAppContext()
  const viewContext = useViewContext()

  const abortController = useAbortController()

  const [loading, setLoading] = useState(true)

  const { client = {}, members = [], roles = [] } = useFetchState(
    useCallback(async (setSafeState) => {
      const signal = abortController()

      try {
        setLoading(true)

        const [{ data: client }, { data: roles }, { data: members }] =
            await Promise.all([
              fetchClient(clientId),
              getRoleTypes(),
              getTeamMembers(clientId, signal)
            ])

        setSafeState({ client, members, roles })
      } catch (err) {
        console.error(err)
      } finally {
        setLoading(false)
      }
    }, [clientId, abortController])
  )

  return (
    <Container maxWidth='xl'>
      <Box sx={{ flexGrow: 1 }} pt={4}>
        <Grid container direction='row' justifyContent='center' alignItems='flex-start' spacing={4}>
          {loading ? (
            <>
              <Grid item xs={5}>
                <Skeleton width='100%' height='80vh' variant={SKELETON_VARIANTS.rect} className={classes.skeleton} />
              </Grid>
              <Grid item xs={7}>
                <Skeleton width='100%' height='80vh' variant={SKELETON_VARIANTS.rect} className={classes.skeleton} />
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={5}>
                <Grid item xs={8}>
                  <Text text={lifeStatement} color={theme.palette.hardcoded} variant={TEXT_VARIANTS.subtitle2} className={classes.bottom15} />
                  <FamilyValuesInput
                    defaultValue={client?.description || 'Not available'}
                    clientId={clientId}
                    className={classes.bottom15}
                    extraStyles={extraStyles}
                  />
                  <Divider additionalClasses={classes.bottom15} />
                  {
                    showFamilyTree && (
                      <>
                        <Text text={client?.familyName || 'Not available'} color={theme.palette.hardcoded} variant={TEXT_VARIANTS.subtitle2} />
                        <HouseHoldFamilyTree />
                        <Divider additionalClasses={classes.bottom15} />
                      </>
                    )
                  }
                </Grid>
                {
                  showInterests && (
                    <>
                      <Text text='Interests' color={theme.palette.hardcoded} variant={TEXT_VARIANTS.subtitle2} className={classes.bottom15} />
                      <Grid container direction='row' spacing={1}>
                        {
                          INTERESTS.map((interest, index) => (
                            <Grid item key={`${index}-${interest}`}>
                              <Tag
                                label={interest}
                                backgroundColor='#F5F5F5'
                                borderRadius='16px'
                                color='#AAADB1'
                                padding='4px 8px'
                                fontSize='14px'
                                height='max-content'
                                className={classes.tag}
                              />
                            </Grid>
                          ))
                        }
                      </Grid>
                    </>
                  )
                }
                {
                  members.length && (
                    <>
                      <Grid container direction='row' className={classes.teamContainer} justifyContent='space-between'>
                        <Text text={teamTitle} color={theme.palette.hardcoded} variant={TEXT_VARIANTS.subtitle2} />
                        <NoteButton viewId={viewContext?.viewId}>
                          <RoundedButton size='large' variant='text'>
                            <Text text='Message team' customFontWeight='bold' lineHeight='1' variant={TEXT_VARIANTS.body1} />
                          </RoundedButton>
                        </NoteButton>
                      </Grid>
                      <Grid container direction='row'>
                        {
                          members.map((member) => {
                            const fullName = `${member.firstName} ${member.lastName}`
                            const role = roles.find((role) => role.roleTypeId === member.roleTypeId).longName

                            return (
                              <TeamCard layoutSettings={cardSettings} data={member} key={member.clientId}>
                                <>
                                  <Avatar
                                    customSize='5rem'
                                    src={member.profilePic}
                                    avatarLetters={fullName}
                                    isPublicResource={isPublicResource}
                                    useOneInitial
                                    customClassName={classes.avatarContainer}
                                  />
                                  <TitleWithSubtitle
                                    title={
                                      <Text
                                        text={fullName}
                                        color={theme.palette.cloudBurst}
                                        customFontSize='1.25rem'
                                        customFontWeight='400'
                                      />
                                    }
                                    subtitle={member.title}
                                    alignment='center'
                                  />
                                  <Text text={role} className={classes.role} />
                                </>
                              </TeamCard>
                            )
                          })
                        }
                      </Grid>
                    </>
                  )
                }
              </Grid>
              <Grid item xs={7}>
                <EmbeddedNotesBoard salesforceId={client?.extras?.salesforceId} />
                {
                  showReports && (
                    <>
                      <Divider additionalClasses={classes.bottom32} />
                      <Text text='Reports' variant={TEXT_VARIANTS.subtitle2} color={theme.palette.hardcoded} />
                      {
                        REPORTS.map((report, index) => (
                          <div className={classes.report} key={`${index}-${report.title}`}>
                            <Report title={report.title} content={report.content} className={classes.report} />
                          </div>
                        ))
                      }
                    </>
                  )
                }
              </Grid>
            </>
          )}
        </Grid>
      </Box>
    </Container>

  )
}

Household.propTypes = {
  teamTitle: PropTypes.string,
  lifeStatement: PropTypes.string,
  showFamilyTree: PropTypes.bool,
  showInterests: PropTypes.bool,
  showReports: PropTypes.bool,
  isPublicResource: PropTypes.bool,
  extraStyles: PropTypes.object
}

Household.defaultProps = {
  teamTitle: 'Your team',
  lifeStatement: 'Family Values',
  showFamilyTree: true,
  showInterests: true,
  showReports: true,
  isPublicResource: true,
  extraStyles: {}
}

export default Household
