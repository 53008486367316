import React from 'react'
import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'
import dayjs from 'dayjs'

import ReactTableInfinite from '../../../../molecules/InfiniteTable'
import Text from '../../../../atoms/Text'

import useFetchNewAccounts from '../../../../../hooks/useFetchNewAccounts'

const COLUMNS = [
  {
    accessor: 'shortNumber',
    Header: 'Account Number'
  },
  {
    accessor: (data) => data.shortName || data.longName,
    Header: 'Account Name'
  },
  {
    accessor: 'createdDate',
    Header: 'Date Added',
    Cell: ({ value }) => {
      return dayjs(value).format('MM/DD/YYYY')
    }
  },
  {
    accessor: 'assignedClients',
    Header: 'Clients',
    Cell: (cell) => {
      const { value } = cell
      const clientsEmpty = isEmpty(value)
      return (
        <Text
          customFontWeight='bold'
          color={clientsEmpty ? '#D44333' : 'black'}
          text={
            !clientsEmpty
              ? value.map(({ shortName, longName }) => shortName || longName).join(', ')
              : 'Unassigned'
          }
        />
      )
    }
  }
]

const NewAccountsTable = ({ areAssignedAccounts }) => {
  const { isLoading, rows, rowCount, loadMoreRows } = useFetchNewAccounts(areAssignedAccounts)

  return (
    <ReactTableInfinite
      columns={COLUMNS}
      data={rows}
      isLoading={isLoading}
      rowCount={rowCount}
      loadMoreRows={loadMoreRows}
      threshold={50}
    />
  )
}

NewAccountsTable.propTypes = {
  areAssignedAccounts: PropTypes.bool.isRequired
}

export default NewAccountsTable
