import React from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import VisualBalanceTreeView from './VisualBalanceTreeView'
import VisualBalanceSide from './VisualBalanceSide'
import {
  VisualBalanceDataShape,
  VisualBalanceFormatsShape,
  VisualBalanceHoldingTableColumnsShape,
  VisualBalanceHoldingTableDefaultSortShape,
  VisualBalanceLabelShape,
  VisualBalanceSectionsShape
} from './utils'

function VisualBalanceSheetContainer ({
  data,
  enableDrilldown,
  height,
  labels,
  margin,
  onClick,
  onPressAccount,
  currentView,
  width,
  sections,
  splitAssetClassTagIds,
  formats,
  holdingTable,
  showOtherAssetCards,
  client,
  maxBlocksPerGroupInDrilldown
}) {
  if (currentView === 'split-view') {
    return (
      <VisualBalanceSide
        data={data}
        width={width}
        margin={margin}
        height={height}
        overviewFormat={formats.list.overview}
        detailFormat={formats.list.details}
        sections={sections}
      />
    )
  }

  return (
    <VisualBalanceTreeView
      client={client}
      data={data}
      enableDrilldown={enableDrilldown}
      height={height}
      labels={labels}
      onClick={onClick}
      onPressAccount={onPressAccount}
      width={width}
      sections={sections}
      splitAssetClassTagIds={splitAssetClassTagIds}
      overviewFormat={formats.tree.overview}
      detailFormat={formats.tree.details}
      holdingTable={holdingTable}
      showOtherAssetCards={showOtherAssetCards}
      maxBlocksPerGroupInDrilldown={maxBlocksPerGroupInDrilldown}
    />
  )
}

VisualBalanceSheetContainer.propTypes = {
  // The client name initials will be used if the profilePic source fails
  client: PropTypes.shape({
    clientAbbreviation: PropTypes.string,
    avatarUrl: PropTypes.string
  }),
  // The data to display in the TreeMap
  data: PropTypes.shape(VisualBalanceDataShape),
  // Toggles VBS breakdown to drilldown view
  enableDrilldown: PropTypes.bool,
  // Height of the chart
  height: PropTypes.number,
  // labels and titles for different places in the view
  labels: VisualBalanceLabelShape,
  // Margin between different kinds of blocks
  margin: PropTypes.number,
  // callback triggered after pressing block
  onClick: PropTypes.func,
  // callback triggered to specify when an account is pressed
  onPressAccount: PropTypes.func,
  // Toggles VBS split view to side by side view
  currentView: PropTypes.string,
  // Width of the chart
  width: PropTypes.string,
  // Sections to group in
  sections: VisualBalanceSectionsShape,
  // asset tags to split by
  splitAssetClassTagIds: PropTypes.arrayOf(PropTypes.number),
  // formats for numbers
  formats: VisualBalanceFormatsShape,
  // holding table columns + default sort params on drilldown
  holdingTable: PropTypes.shape({
    columns: PropTypes.arrayOf(VisualBalanceHoldingTableColumnsShape),
    defaultSort: PropTypes.arrayOf(VisualBalanceHoldingTableDefaultSortShape)
  }),
  // boolean for other asset cards in tree view
  showOtherAssetCards: PropTypes.bool,
  // max blocks per group in drilldown
  maxBlocksPerGroupInDrilldown: PropTypes.number
}

VisualBalanceSheetContainer.defaultProps = {
  data: {},
  enableDrilldown: false,
  height: undefined,
  labels: {},
  margin: undefined,
  onClick: noop,
  onPressAccount: noop,
  currentView: 'visual-balance-sheet',
  width: undefined,
  sections: {},
  splitAssetClassTagIds: []
}

export default VisualBalanceSheetContainer
