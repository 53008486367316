import React from 'react'
import AceEditor from 'react-ace'

import 'ace-builds/src-noconflict/mode-jsx'
import 'ace-builds/src-noconflict/theme-xcode'
import 'ace-builds/src-noconflict/ext-language_tools'

import PropTypes from 'prop-types'

const AceCodeEditor = ({
  id,
  value,
  defaultValue,
  fontSize,
  onChange,
  onLoad,
  placeholder,
  showGutter,
  highlightActiveLine,
  showLineNumbers,
  tabSize,
  name,
  disabled,
  mode
}) => {
  const onAceEditorInit = (editor) => {
    const { height: parentHeight } = editor.container.parentElement.getBoundingClientRect()
    editor.container.style.height = `${parentHeight - 20}px`
    onLoad(editor)
  }

  return (
    <AceEditor
      id={id}
      height='100%'
      width='100%'
      placeholder={placeholder}
      mode={mode}
      theme='xcode'
      name={name}
      defaultValue={defaultValue}
      onLoad={onAceEditorInit}
      onChange={onChange}
      fontSize={fontSize}
      showPrintMargin
      showGutter={showGutter}
      highlightActiveLine={highlightActiveLine}
      value={value}
      editorProps={{ $blockScrolling: true }}
      setOptions={{
        enableBasicAutocompletion: true,
        enableLiveAutocompletion: false,
        enableSnippets: false,
        showLineNumbers,
        tabSize,
        readOnly: disabled
      }}
    />
  )
}

AceCodeEditor.propTypes = {
  id: PropTypes.string,
  value: PropTypes.string,
  fontSize: PropTypes.number,
  onChange: PropTypes.func,
  onLoad: PropTypes.func,
  placeholder: PropTypes.string,
  showGutter: PropTypes.bool,
  highlightActiveLine: PropTypes.bool,
  showLineNumbers: PropTypes.bool,
  tabSize: PropTypes.number,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  defaultValue: PropTypes.string,
  mode: PropTypes.string
}

AceCodeEditor.defaultProps = {
  value: '',
  fontSize: 14,
  onChange: () => {
  },
  onLoad: () => {
  },
  mode: 'jsx',
  placeholder: '',
  showGutter: true,
  highlightActiveLine: true,
  showLineNumbers: true,
  tabSize: 2,
  name: '',
  disabled: false,
  defaultValue: ''
}

export default AceCodeEditor
