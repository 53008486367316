import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Box } from '@material-ui/core'

function OtherSectionBackground ({ color, index, pyramidWidth, isPulsing = false }) {
  const dimensions = useMemo(() => ({
    width: (pyramidWidth * 0.63) + ((index - 1) * 188),
    height: 142
  }), [index, pyramidWidth])

  return (
    <Box width={dimensions.width} margin='0 auto' minHeight={dimensions.height} flex='1 0 0'>
      <svg
        viewBox={`0 0 ${dimensions.width} ${dimensions.height}`}
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        style={{ display: 'block' }}
        width={dimensions.width}
        height='100%'
        preserveAspectRatio='none'
        className={isPulsing ? 'MuiSkeleton-pulse' : ''}
      >
        <path d={`M79 0L0 ${dimensions.height}H${dimensions.width}L${dimensions.width - 79} 0`} fill={color ?? '#ddd'} />
      </svg>
    </Box>
  )
}
OtherSectionBackground.propTypes = {
  color: PropTypes.string,
  index: PropTypes.number,
  pyramidWidth: PropTypes.number,
  isPulsing: PropTypes.bool
}

const PyramidTopSection = ({ color, pyramidWidth, isPulsing = false }) => {
  const dimensions = useMemo(() => {
    return {
      width: pyramidWidth * 0.3,
      height: pyramidWidth * 0.26
    }
  }, [pyramidWidth])
  return (
    <Box width={dimensions.width} margin='0 auto' minHeight={dimensions.height} flex='1 0 0'>
      <svg
        viewBox={`0 0 ${dimensions.width} ${dimensions.height}`}
        fill={color ?? '#eee'}
        xmlns='http://www.w3.org/2000/svg'
        style={{ display: 'block' }}
        preserveAspectRatio='none'
        width={dimensions.width}
        height='100%'
        className={isPulsing ? 'MuiSkeleton-pulse' : ''}
      >
        <path d={`M${dimensions.height} ${dimensions.height}H0L${dimensions.width / 2} 0L${dimensions.width} ${dimensions.height}Z`} stroke='none' />
      </svg>
    </Box>
  )
}
PyramidTopSection.propTypes = {
  color: PropTypes.string,
  pyramidWidth: PropTypes.number,
  isPulsing: PropTypes.bool
}

const PyramidSection = ({ color, index, pyramidWidth, isPulsing = false }) => {
  if (index === 0) {
    return <PyramidTopSection color={color} pyramidWidth={pyramidWidth} isPulsing={isPulsing} />
  }
  return <OtherSectionBackground color={color} index={index} pyramidWidth={pyramidWidth} isPulsing={isPulsing} />
}
PyramidSection.propTypes = {
  color: PropTypes.string,
  index: PropTypes.number,
  pyramidWidth: PropTypes.number,
  isPulsing: PropTypes.bool
}
export default PyramidSection
