import { noop } from 'lodash'
import { createContext, useCallback, useContext, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { localStorageHelper } from '../../../utils/localStorageHelper'

export const AdvisorHomeContext = createContext({
  scope: null,
  setScope: noop
})

export const useAdvisorHome = () => {
  const contextValue = useContext(AdvisorHomeContext)

  return contextValue
}

export const useAdvisorHomeValues = ({ scope: initialScope, storageKey, urlState }) => {
  const [scope, setScope] = useState(initialScope)
  const history = useHistory()
  const location = useLocation()
  const _setScope = useCallback((newScope) => {
    if (storageKey) {
      localStorageHelper.store(storageKey, newScope?.values || [])
    }

    setScope((prevScope) => ({
      ...prevScope,
      ...newScope
    }))

    const params = new URLSearchParams({ [scope.pathSegment]: newScope?.values })
    return urlState ? history.push(`${location.pathname}?${params.toString()}`) : undefined
  }, [scope?.pathSegment, setScope, history, location.pathname, storageKey, urlState])

  return { scope, setScope: _setScope, storageKey, urlState }
}
