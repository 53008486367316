import React, { useCallback, useMemo, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import PropTypes from 'prop-types'
import { Controller, useForm } from 'react-hook-form'
import SydButton from '../../../../../commonDesign/Button'
import { useModifyEntry } from '../../../../../../api/wealthJourney'
import SydModalActions from '../../../../../commonDesign/SydModal/SydModalActions'
import SydInput from '../../../../../commonDesign/SydInput'
import { useWealthJourney } from '../../WealthJourneyProvider'
import SydLabel, { hookFormErrorAdapter } from '../../../../../commonDesign/SydLabel'
import Loading from '../../../../../molecules/Loading'
import SydDatePicker from '../../../../../commonDesign/SydDatePicker'
import TypeChip from '../../../shared/TypeChip'
import EntryContentFieldInput from '../../EntryContent/EntryContentField/Input'
import FlexRow from '../../../../../molecules/FlexRow'
import AvatarInput from '../AvatarInput'

const useSubmitter = (form, onComplete, entry) => {
  const { handleSubmit } = form
  const [processing, setProcessing] = useState(false)
  const [error, setError] = useState(null)
  const { mutateAsync: modifyEntry } = useModifyEntry()
  const onSubmit = useCallback(async (formData) => {
    const command = {
      ...formData
    }
    if (entry.entryType.useAvatars && formData.avatar !== entry.avatar) {
      command.entryJson = {
        ...(formData.entryJson || {}),
        images: [{ imageUrl: formData.avatar }]
      }
    }

    try {
      setError(null)
      setProcessing(true)
      const result = await modifyEntry(command)
      if (result?.statusCode === 500) {
        throw new Error('Failed to modify entry')
      }
      onComplete(result)
    } catch (err) {
      setError(err?.toString())
    } finally {
      setProcessing(false)
    }
  }, [modifyEntry, setProcessing, onComplete, setError, entry])

  const submitter = useCallback(async (e) => {
    const onValid = async (form) => {
      await onSubmit(form)
    }
    const onInvalid = (errors) => {
      console.error(errors)
    }

    const handler = handleSubmit(onValid, onInvalid)
    await handler(e)
  }, [handleSubmit, onSubmit])

  return {
    submitter,
    processing,
    error
  }
}

function UpdateEntryForm ({ onCancel, onComplete, entry }) {
  const { entriesLoading: isLoading } = useWealthJourney()
  const form = useForm({
    mode: 'onChange',
    defaultValues: {
      ...entry
    }
  })

  const { submitter, processing, error } = useSubmitter(form, onComplete, entry)
  const attributes = useMemo(() => {
    return entry.entryType.attributes.sort((a, b) => a.ordinal - b.ordinal)
  }, [entry])

  if (isLoading) {
    return <Loading />
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <FlexRow style={{ justifyContent: 'space-between' }}>
          {entry?.entryType?.useAvatars ? (
            <div>
              <Controller
                name='avatar'
                control={form.control}
                render={(f) => (
                  <AvatarInput clientId={entry.clientId} value={f.field.value} onChange={f.field.onChange} />
                )}
              />
            </div>
          ) : null}
          <TypeChip entryTypeId={entry.entryTypeId} />
        </FlexRow>
      </Grid>
      <Grid item xs={6}>
        <Controller
          name='entryDate'
          control={form.control}
          render={(f) => (
            <SydLabel label='Date'>
              <SydDatePicker size='sm' {...f.field} />
            </SydLabel>
          )}
        />
      </Grid>
      <Grid item xs={6}>
        <Controller
          name='entryText'
          control={form.control}
          render={(f) => (
            <SydLabel label='Title'>
              <SydInput size='sm' {...f.field} />
            </SydLabel>
          )}
        />
      </Grid>
      {attributes.map(attribute => (
        <Grid item xs={12} key={attribute.attributeId}>
          <div>
            <Controller
              control={form.control}
              name={`${attribute.accessor}`}
              rules={attribute.data?.validation}
              render={({ field, fieldState }) => (
                <SydLabel
                  label={attribute.label}
                  description={attribute.data?.instruction}
                  error={hookFormErrorAdapter(form, fieldState)}
                >
                  <EntryContentFieldInput type={attribute.type} placeholder={attribute.data?.placeholder} {...field} />
                </SydLabel>
              )}
            />
          </div>
        </Grid>
      ))}
      <Grid item xs={12}>
        <SydModalActions>
          {error ? (<div className='__error'>{error}</div>) : null}
          <SydButton disabled={processing} variant='ghost' size='md' onClick={onCancel}>Cancel</SydButton>
          <SydButton
            variant='primary'
            size='md'
            onClick={submitter}
            processing={processing}
          >
            Update
          </SydButton>
        </SydModalActions>
      </Grid>
    </Grid>
  )
}

UpdateEntryForm.propTypes = {
  entry: PropTypes.object,
  onComplete: PropTypes.func,
  onCancel: PropTypes.func
}

export default UpdateEntryForm
