function clearFavicons () {
  const icons = document.head.querySelectorAll('link[rel~=\'icon\'], link[rel~=\'apple-touch-icon\']')
  icons.forEach(item => {
    document.head.removeChild(item)
  })
}

function setIcon (brand, path, rel, sizes) {
  if (brand.data?.icons?.[path]) {
    console.log(path, rel, sizes)
    const iconUrl = brand.data?.icons[path]
    const linkElement = document.createElement('link')
    linkElement.rel = rel
    linkElement.href = iconUrl
    if (sizes) {
      linkElement.sizes = sizes
    }
    document.head.appendChild(linkElement)
  }
}

function setDescription (brand) {
  if (brand.tagLine) {
    const metaElement = document.querySelector('meta[name~=\'description\']')
    if (metaElement) {
      metaElement.content = brand.tagLine
    } else {
      const el = document.createElement('meta')
      el.name = 'description'
      el.content = brand.tagLine
    }
  }
}

function setTitle (brand) {
  document.title = brand.firmName || document.title
}

function applyBrand (brand) {
  try {
    clearFavicons()
    setTitle(brand)
    setDescription(brand)
    setIcon(brand, 'favicon', 'icon')
    setIcon(brand, 'favicon-16x16', 'icon', '16x16')
    setIcon(brand, 'favicon-32x32', 'icon', '32x32')
    setIcon(brand, 'apple-touch-icon', 'apple-touch-icon')
    setIcon(brand, 'android-chrome-192x192', 'icon', '192x192')
    setIcon(brand, 'android-chrome-512x512', 'icon', '512x512')
  } catch (err) {
    console.error(err, 'unable to apply branding')
  }
}

function saveBrand (brand) {
  global.localStorage.setItem('sws-brand-info', JSON.stringify(brand))
}

function getSavedBrand () {
  const value = global.localStorage.getItem('sws-brand-info')
  if (value) {
    try {
      return JSON.parse(value)
    } catch (err) {
      console.error(err, 'failed to parse saved brand')
    }
  }
}

module.exports = {
  applyBrand,
  saveBrand,
  getSavedBrand
}
