import React, { useCallback, forwardRef, useMemo } from 'react'
import PropTypes from 'prop-types'
import JsxParser from 'react-jsx-parser'
import noop from 'lodash/noop'
import isEmpty from 'lodash/isEmpty'
import ErrorView from '../components/pages/ErrorView'
import mergePropsWithDefaultProps from '../HOCs/mergePropsWithDefaultProps'
import ComponentsLibrary from './abundanceComponentsRegister'
import { BLACKLISTED_TAGS } from './constants'

const AbundanceParser = forwardRef((
  {
    jsx,
    onError,
    bindings,
    className,
    renderError,
    showWarnings,
    componentsOnly,
    blacklistedTags,
    renderInWrapper,
    blacklistedAttrs,
    disableFragments,
    renderUnrecognized,
    disableKeyGeneration,
    allowUnknownElements,
    autoCloseVoidElements,
    jsxComponentsDefaultProps
  },
  ref
) => {
  const onRenderUNrecognizedTag = useCallback(
    (tagName) => {
      if (renderUnrecognized) {
        renderUnrecognized(tagName)
      } else {
        console.warn(`Abundance Engine parser: unrecognized tag ${tagName}`)
      }
    },
    [renderUnrecognized]
  )

  const onRenderError = useCallback(
    ({ error }) => {
      if (renderError) return renderError(error)
      return <ErrorView error={{ message: error }} />
    },
    [renderError]
  )

  const componentsLibrary = useMemo(() => {
    if (isEmpty(jsxComponentsDefaultProps)) {
      return ComponentsLibrary
    }
    const componentsLibraryWithDefaultProps = Object.entries(
      ComponentsLibrary
    ).reduce((acc, [componentKey, component]) => {
      const componentDefaultProps = jsxComponentsDefaultProps[componentKey]

      if (!isEmpty(componentDefaultProps)) {
        component = mergePropsWithDefaultProps(
          component,
          componentDefaultProps
        )
      }
      acc.push([componentKey, component])

      return acc
    }, [])
    return Object.fromEntries(componentsLibraryWithDefaultProps)
  }, [jsxComponentsDefaultProps])

  return (
    <JsxParser
      jsx={jsx}
      onError={onError}
      bindings={bindings}
      className={className}
      renderError={onRenderError}
      showWarnings={showWarnings}
      components={componentsLibrary}
      componentsOnly={componentsOnly}
      renderInWrapper={renderInWrapper}
      blacklistedTags={blacklistedTags}
      disableFragments={disableFragments}
      blacklistedAttrs={blacklistedAttrs}
      autoCloseVoidElements={autoCloseVoidElements}
      disableKeyGeneration={disableKeyGeneration}
      allowUnknownElements={allowUnknownElements}
      renderUnrecognized={onRenderUNrecognizedTag}
      ref={ref}
    />
  )
})

AbundanceParser.propTypes = {
  jsx: PropTypes.string,
  onError: PropTypes.func,
  bindings: PropTypes.object,
  className: PropTypes.string,
  renderError: PropTypes.func,
  showWarnings: PropTypes.bool,
  componentsOnly: PropTypes.bool,
  renderInWrapper: PropTypes.bool,
  disableFragments: PropTypes.bool,
  renderUnrecognized: PropTypes.func,
  disableKeyGeneration: PropTypes.bool,
  allowUnknownElements: PropTypes.bool,
  autoCloseVoidElements: PropTypes.bool,
  blacklistedAttrs: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(RegExp)])
  ),
  blacklistedTags: PropTypes.arrayOf(PropTypes.string),
  jsxComponentsDefaultProps: PropTypes.object
}

AbundanceParser.defaultProps = {
  jsx: '',
  bindings: {},
  className: '',
  onError: noop,
  components: {},
  showWarnings: true,
  componentsOnly: false,
  renderInWrapper: true,
  renderError: undefined,
  disableFragments: false,
  disableKeyGeneration: false,
  allowUnknownElements: false,
  autoCloseVoidElements: false,
  blacklistedAttrs: [new RegExp(/^on.+/i)],
  renderUnrecognized: undefined,
  blacklistedTags: Object.values(BLACKLISTED_TAGS),
  jsxComponentsDefaultProps: {}
}

export default React.memo(AbundanceParser)
