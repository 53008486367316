import PropTypes from 'prop-types'
import React from 'react'
import Avatar from '../../atoms/Avatar'
import { useContextContainer } from '../../../abundanceEngine/components/ContextContainer'
import { ASSET_TEAR_CONTEXT } from '.'

const AssetTearAvatar = ({ label, icon, redirectPath, ...props }) => {
  const [{ data }] = useContextContainer(ASSET_TEAR_CONTEXT)
  return (
    <Avatar
      isPublicResource
      avatarLetters={data?.symbol || ''}
      src={data?.avatarPath || ''}
      customSize='4.5rem'
      {...props}
    />
  )
}

AssetTearAvatar.propTypes = {
  label: PropTypes.string,
  icon: PropTypes.string,
  redirectPath: PropTypes.string
}

AssetTearAvatar.defaultProps = {
  label: 'Back to Alternatives',
  icon: 'back',
  redirectPath: '/'
}

export default AssetTearAvatar
