import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
// import PersonIcon from '@material-ui/icons/Person'
import PersonAddIcon from '@material-ui/icons/PersonAdd'
import PropTypes from 'prop-types'
import FadeIn from '../../molecules/Transitions/FadeIn'
import OptionCard from './OptionCard'
import CurrentClientOptionCard from './components/CurrentClientOptionCard'
import { usePreloadListRebalancerClients } from './hooks/usePreloadListRebalancerClients'

const useStyles = makeStyles({
  startView: {
    width: '100%'
  },
  guidance: {
    fontWeight: '600',
    fontSize: '4.5rem',
    padding: 0,
    lineHeight: '5rem',
    textShadow: '2px 2px 5px rgba(0,0,0,0.1)',
    margin: 0
  },
  guidanceSubtext: {
    fontSize: '1.2rem',
    padding: '1rem 0',
    margin: 0,
    fontWeight: '100',
    textAlign: 'center'
  },
  columns: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly'
  },
  actions: {
    margin: '5rem',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },
  button: {
    fontSize: '2rem',
    border: '1px solid gray',
    padding: '.5rem 5rem'
  },
  options: {
    display: 'flex',
    justifyContent: 'center',
    padding: '3rem 0'
  },
  optionsContainer: {
    display: 'flex',
    width: '100%',
    gap: '30px',
    justifyContent: 'center'
  },
  client: {
    display: 'flex',
    width: '40rem',
    padding: '3rem 0',
    justifyContent: 'flex-end'
  },
  header: {
    height: '60px',
    display: 'flex',
    alignItems: 'center'
  }
})

const StartView = (props) => {
  const classes = useStyles()
  usePreloadListRebalancerClients()

  return (
    <div className={classes.startView}>
      <div className={classes.header} />
      <FadeIn>
        <h1 className={classes.guidance}>To begin, select how you'd like to rebalance...</h1>
      </FadeIn>
      <FadeIn delay={0.1}>
        <p className={classes.guidanceSubtext}>
          Let us know if you're focusing on a single client or rebalancing across your book of business. Multiple
          clients will allow you to select certain clients or pick from a rebalancing group you've setup.
        </p>
      </FadeIn>
      <FadeIn delay={0.2}>
        <div className={classes.options}>
          <div className={classes.optionsContainer}>
            <CurrentClientOptionCard includeSelectClientLink />
            {props.clientOptions.map(opt => (
              <OptionCard
                to='/rebalancer/start/client'
                outlined
                variant='round'
                size='lg'
                title={opt.title}
                icon={opt.icon}
                key={opt.title}
              />
            ))}
          </div>
        </div>
      </FadeIn>
    </div>
  )
}

StartView.propTypes = {
  clientOptions: PropTypes.array
}

StartView.defaultProps = {
  clientOptions: [
    // {
    //   title: 'Select Client',
    //   value: 'single',
    //   icon: (props) => <PersonIcon htmlColor={props.fillColor} />
    // },
    {
      title: 'Multiple Clients',
      value: 'multiple',
      icon: (props) => <PersonAddIcon transform='scale(-1 1)' htmlColor={props.fillColor} />
    }
  ]
}

export default StartView
