import React from 'react'
import SydInput from '../../../../../commonDesign/SydInput'

function NumberFieldInput (props) {
  return (
    <SydInput size='sm' type='number' {...props} />
  )
}

export default NumberFieldInput
