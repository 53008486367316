import React, { useCallback, useMemo, useRef } from 'react'
import { makeStyles } from '@material-ui/core'
import { Link } from 'react-router-dom'
import dayjs from 'dayjs'
import PropTypes from 'prop-types'
import FadeIn from '../../../../molecules/Transitions/FadeIn'
import { useStatusTemplateContext } from '../StatusTemplateProvider'
import PersonalSpace from '../../shared/PersonalSpace'
import { useListClientStatusTemplateAssignments, useRunClientStatusReportMutation } from '../../../../../api/groups'
import { ADMIN_ROUTES } from '../../../../../constants'
import OperationalTable from '../../../../organisms/OperationalTable'
import SydInput from '../../../../commonDesign/SydInput'
import SydLabel from '../../../../commonDesign/SydLabel'
import SydButton from '../../../../commonDesign/Button'

const useStyles = makeStyles((theme) => ({
  form: {
    marginTop: '20px'
  },
  dateControl: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '3px'
  },
  tableLabel: {
    fontSize: theme.typography.fontSizes.h5
  }
}))

const cellTemplates = {
  link: (accessor) => ({ row }) => (
    <Link
      onClick={(event) => event.stopPropagation()}
      to={`${ADMIN_ROUTES.CLIENTS}/${row.original.clientId}`}
    >
      {accessor(row.original) || 'Link'}
    </Link>
  )
}

export const defaultColumnConfig = {
  columns: [
    { Header: 'Client Id', accessor: 'clientId', type: 'number', Cell: cellTemplates.link(row => row.clientId) },
    { Header: 'Client Name', accessor: 'longName', type: 'string', Cell: cellTemplates.link(row => row.longName) },
    { Header: '# Reports', accessor: 'numReports', type: 'number' },
    { Header: 'Last Report Date', accessor: 'lastReportDate', type: 'date' },
    // eslint-disable-next-line react/prop-types
    { Header: 'Actions', id: 'actions', accessor: 'clientId', Cell: ({ row }) => <RunButton clientId={row.original.clientId} /> }
  ],
  defaultSort: [{ id: 'longName', desc: false }]
}

const RunContext = React.createContext(null)

function RunButton ({ clientId }) {
  const { dateRef } = React.useContext(RunContext)
  const { mutateAsync: runStatusReport, isLoading, isSuccess, isError } = useRunClientStatusReportMutation()
  const onClick = useCallback(async () => {
    await runStatusReport({
      clientId: clientId,
      asOfDate: dateRef.current.value?.trim()
    })
  }, [dateRef, runStatusReport, clientId])
  return (
    <SydButton
      size='sm'
      priority={isError ? 'warning' : 'main'}
      variant={isSuccess ? 'ghost' : 'primary'}
      icon={isSuccess ? 'check' : 'document'}
      onClick={onClick}
      processing={isLoading}
    >
      Run
    </SydButton>
  )
}

RunButton.propTypes = {
  clientId: PropTypes.number
}

function AssignmentsTab () {
  const classes = useStyles()
  const { statusTemplate } = useStatusTemplateContext()
  const { data, isLoading } = useListClientStatusTemplateAssignments(statusTemplate.statusTemplateId)
  const dateRef = useRef()
  const defaultAsOfDate = useMemo(() => {
    return dayjs().add(-1, 'days').format('YYYY-MM-DD')
  }, [])

  return (
    <FadeIn>
      <RunContext.Provider value={{ dateRef }}>
        <div className={classes.form}>
          <div className={classes.dateControl}>
            <div className={classes.tableLabel}>Assigned Clients</div>
            <div>
              <SydLabel label='Run as-of'>
                <SydInput ref={dateRef} size='sm' defaultValue={defaultAsOfDate} />
              </SydLabel>
            </div>
          </div>
          <OperationalTable
            variant='v2'
            mode='client'
            itemName='Assignments'
            data={data?.assignments || []}
            loading={isLoading}
            total={data?.assignments?.length}
            columns={defaultColumnConfig.columns}
            defaultSort={defaultColumnConfig.defaultSort}
          />
        </div>
        <PersonalSpace />
      </RunContext.Provider>
    </FadeIn>
  )
}

AssignmentsTab.propTypes = {
}

export default AssignmentsTab
