import React, { useCallback, useReducer, useMemo, useState } from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Container,
  Grid,
  makeStyles
} from '@material-ui/core'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import isEmpty from 'lodash/isEmpty'
import last from 'lodash/last'
import { useAppContext } from '../../redux/slices/appContext'
import { getSafeDate } from '../../utils'
import useAccordion from '../../hooks/useAccordion'
import Text from '../atoms/Text'
import Tag from '../atoms/Tag'
import PerformanceAllocationTable from '../molecules/PerformanceAllocationTable'
import PerformanceTransactionsTable from '../molecules/PerformanceTransactionsTable'
import PortfolioAllocationTotalBalanceSummary from '../molecules/PortfolioAllocationTotalBalanceSummary'
import PortfolioAllocationPieProgressChart from '../molecules/PortfolioAllocationPieProgressChart'
import PortfolioAllocationHistoryStreamChart from '../molecules/PortfolioAllocationHistoryStreamChart'
import PortfolioAllocationHoldingsTable from '../molecules/PortfolioAllocationHoldingsTable'
import PrintViewHeader from '../molecules/PrintViewHeader'
import WebOnlyContent from '../atoms/WebOnlyContent'
import { portfolioViewPropTypes } from '../../prop-types'
import { getShouldClearExistingFilters, hydrateColumnsSelectProps } from '../../utils/tableHelper'
import {
  CALC_TYPES,
  DAYJS_OPERATIONS,
  DAYJS_UNIT_TYPES,
  EXPORT_CLASSNAME,
  TEXT_VARIANTS
} from '../../constants'
import ExportRoot from '../molecules/ExportButton/ExportRoot'
import LineChartContainer from './LineChartContainerV2'
import PerformanceTableContainer, { getTablePropName } from './PerformanceTableContainer'

const reducer = (state, payload) => {
  return {
    ...state,
    ...payload
  }
}

const useStyles = makeStyles((theme) => ({
  rootAccordion: {
    boxShadow: 'none',
    backgroundColor: 'white'
  },
  tableButton: {
    borderRadius: '1rem',
    padding: '4px 10px 2px',
    fontSize: '0.875rem',
    fontWeight: 'bold'
  },
  accordionSummary: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  pieChartToggleButton: {
    alignSelf: 'center',
    marginTop: '3rem'
  },
  accordionTitle: {
    color: theme.palette.cloudBurst
  },
  reverse: {
    flexDirection: 'row-reverse',
    gap: '10px'
  },
  expanded: {
    '& .MuiAccordionSummary-expandIcon.Mui-expanded': {
      transform: 'rotate(90deg)'
    }
  }
}))

const PortfolioReviewView = ({
  showFilterTag,
  accountCategoryIds,
  holdingsSectionProps,
  historicalSectionProps,
  lineChartContainerProps,
  transactionsSectionProps,
  portfolioTableSectionProps,
  performanceTableSectionProps,
  balanceInformationSectionProps,
  portfolioAllocationPieChartProps,
  onAvailableDatesChangeStrategyKey,
  lineChartVersion,
  showExport
}) => {
  const classes = useStyles()
  const appContext = useAppContext()
  const [viewFilters, setViewFilters] = useState({})
  const [viewContext, setViewContext] = useReducer(reducer, {
    currentViewId: '4',
    assetClassId: null,
    lineChartDataIdentifier: 'endingValue',
    assetFilterType: 'ASSET_CLASSES',
    minClientDate: getSafeDate(appContext.availableDates, { useMin: true }),
    maxClientDate: getSafeDate(appContext.availableDates, { useMax: true }),
    mainDate: appContext?.availableDates.mainDate,
    streamChartDate: getSafeDate(appContext.availableDates, {
      operation: DAYJS_OPERATIONS.SUBTRACT,
      unitType: DAYJS_UNIT_TYPES.MONTH,
      unitValue: 12
    }),
    streamChartDateSelectedOption: getSafeDate(appContext.availableDates, {
      operation: DAYJS_OPERATIONS.SUBTRACT,
      unitType: DAYJS_UNIT_TYPES.MONTH,
      unitValue: 12
    }),
    assetClassType: 'class'
  })
  const showFilteredTag = useMemo(() => {
    return showFilterTag && !isEmpty(viewFilters)
  }, [viewFilters, showFilterTag])

  const showOnlyToAdvisorsAndSummitUsers = useMemo(() => {
    return appContext.isSummitUser || appContext.isAdvisor
  }, [appContext.isSummitUser, appContext.isAdvisor])

  const onPerformanceAllocationRowClick = useCallback(
    (_, rowData, { rowAssetsConfig }) => {
      const rowConfig = last(rowData)
      const { value, text, classType: { type: classTypeValue } } = rowConfig
      const filter = {
        id: value,
        label: text,
        payload: {
          classType: classTypeValue,
          assetTree: rowAssetsConfig
        }
      }
      setViewFilters((prevFilters) => {
        const shouldClearExistingFilters = getShouldClearExistingFilters(
          prevFilters,
          rowAssetsConfig
        )
        return {
          ...(shouldClearExistingFilters
            ? { [classTypeValue]: filter }
            : { ...prevFilters, [classTypeValue]: filter })
        }
      })
    },
    []
  )

  const performanceTableProps = useMemo(() => {
    const { multiTablesContainer, hidden } = portfolioTableSectionProps
    if (!multiTablesContainer || hidden) return null
    const { tableTabs, baseTableProps, ...restTableProps } = multiTablesContainer
    const tablesProps = tableTabs.reduce((acc, tableTab) => {
      const { propName } = getTablePropName(tableTab)
      return {
        ...acc,
        [propName]: multiTablesContainer[propName]
      }
    }, {})
    return {
      tabs: tableTabs,
      ...restTableProps,
      ...tablesProps,
      baseTableProps: {
        ...(baseTableProps || {}),
        onRowClick: onPerformanceAllocationRowClick,
        accountCategoryFilters: accountCategoryIds
      }
    }
  }, [
    accountCategoryIds,
    portfolioTableSectionProps,
    onPerformanceAllocationRowClick
  ])

  const holdingsAccordion = useAccordion(false)
  const historicalAccordion = useAccordion(false)
  const transactionsAccordion = useAccordion(false)

  return (
    <ExportRoot enabled={showExport}>
      <Container className={EXPORT_CLASSNAME} maxWidth='xl'>
        <Box sx={{ flexGrow: 1 }} pt={4}>
          <Grid container spacing={4}>
            <PrintViewHeader />
            <Grid item xs={12} md={4} xl={4}>
              <PortfolioAllocationTotalBalanceSummary
                viewFilters={viewFilters}
                setViewFilters={setViewFilters}
                viewContext={viewContext}
                setViewContext={setViewContext}
                showFilteredTag={showFilteredTag}
                fetchParams={{ accountCategoryIds }}
                titleFormat={balanceInformationSectionProps.titleFormat}
                onAvailableDatesChangeStrategyKey={onAvailableDatesChangeStrategyKey}
                showInvestableAssetsLabel={
                  balanceInformationSectionProps.showInvestableAssetsLabel
                }
                disableOnClickBalanceFormatToggle={
                  balanceInformationSectionProps.disableOnClickBalanceFormatToggle
                }
              />
            </Grid>
            <Grid item xs={12} md={8} xl={8}>
              <LineChartContainer
                showTagLabel={showFilteredTag}
                accountCategoryFilters={accountCategoryIds}
                seriesColors={lineChartContainerProps.seriesColors}
                assetFilters={viewFilters}
                onGraphDateChange={(value, selectedValue) =>
                  setViewContext({
                    streamChartDate: value,
                    streamChartDateSelectedOption: selectedValue
                  })}
                defaultGraphDate={lineChartContainerProps.defaultGraphDate}
                dateRangeOptions={lineChartContainerProps.dateRangeOptions}
              />
            </Grid>
            <Grid item xs={12} md={4} xl={4}>
              <Box display='flex' flexDirection='column'>
                <PortfolioAllocationPieProgressChart
                  viewContext={viewContext}
                  setViewContext={setViewContext}
                  fetchParams={{ accountCategoryIds }}
                  showLegends={portfolioAllocationPieChartProps.showLegends}
                  toggleTitles={portfolioAllocationPieChartProps.toggleTitles}
                  toggleButtonLabels={
                    portfolioAllocationPieChartProps.toggleButtonLabels
                  }
                  pieProgressBarsChartProps={
                    portfolioAllocationPieChartProps.chartProps
                  }
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={8} xl={8}>
              <PortfolioAllocationHistoryStreamChart
                fetchParams={{ accountCategoryIds }}
                viewContext={viewContext}
                viewFilters={viewFilters}
                showFilteredTag={showFilteredTag}
              />
            </Grid>
            {performanceTableProps && (
              <Grid item xs={12}>
                <Text
                  text='Table View'
                  color='#212945'
                  customFontSize='1rem'
                  customFontFamily='Gotham-Book'
                  textTransform='uppercase'
                  variant={TEXT_VARIANTS.subtitle2}
                />
                <PerformanceTableContainer {...performanceTableProps} />
              </Grid>
            )}
            {portfolioTableSectionProps.hidden && (
              <Grid item xs={12}>
                <Box>
                  <Text
                    text='Table View'
                    className={classes.accordionTitle}
                    customFontSize='1rem'
                    customFontWeight='bold'
                    customFontFamily='Gotham-Book'
                    textTransform='uppercase'
                    variant={TEXT_VARIANTS.subtitle1}
                  />
                  <Box
                    display={
                      appContext.isSummitUser &&
                      viewContext.assetFilterType === 'ASSET_CLASSES'
                        ? 'inherit'
                        : 'none'
                    }
                  >
                    <PerformanceAllocationTable
                      showResearchTable
                      disableAssetFilters
                      disableFinalRow={false}
                      onRowClick={onPerformanceAllocationRowClick}
                      accountCategoryFilters={accountCategoryIds}
                      {...performanceTableSectionProps.allocationTableProps}
                      columns={hydrateColumnsSelectProps(
                        performanceTableSectionProps.allocationTableProps.columns,
                        appContext.availableDates
                      )}
                    />
                  </Box>
                </Box>
              </Grid>
            )}
            <Grid item xs={12}>
              <WebOnlyContent force={holdingsAccordion.expanded}>
                <Box>
                  <Accordion
                    classes={{ root: classes.rootAccordion }}
                    {...holdingsAccordion}
                  >
                    <AccordionSummary
                      expandIcon={<ChevronRightIcon />}
                      classes={{ expanded: classes.expanded }}
                      className={classes.reverse}
                    >
                      <Box className={classes.accordionSummary}>
                        <Text
                          text='Holdings Table View'
                          className={classes.accordionTitle}
                          customFontSize='1rem'
                          customFontWeight='bold'
                          customFontFamily='Gotham-Book'
                          textTransform='uppercase'
                          variant={TEXT_VARIANTS.subtitle1}
                        />
                        {showFilteredTag && (
                          <Box ml={2}>
                            <Tag label='Filtered' />
                          </Box>
                        )}
                      </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                      {holdingsSectionProps.showExtendedVariant ? (
                        <PerformanceAllocationTable
                          disableAssetFilters
                          disableFinalRow={false}
                          accountCategoryFilters={accountCategoryIds}
                          {...holdingsSectionProps.allocationTableProps}
                          columns={hydrateColumnsSelectProps(
                            holdingsSectionProps.allocationTableProps.columns,
                            appContext.availableDates
                          )}
                        />
                      ) : (
                        <PortfolioAllocationHoldingsTable
                          viewFilters={viewFilters}
                          viewContext={viewContext}
                          fetchParams={holdingsSectionProps.fetchParams}
                          allowDownloadTable={
                            holdingsSectionProps.allowDownloadTable
                          }
                        />
                      )}
                    </AccordionDetails>
                  </Accordion>
                </Box>
              </WebOnlyContent>
            </Grid>
            <Grid item xs={12}>
              <WebOnlyContent force={historicalAccordion.expanded}>
                <Box>
                  <Accordion
                    classes={{ root: classes.rootAccordion }}
                    {...historicalAccordion}
                  >
                    <AccordionSummary
                      expandIcon={<ChevronRightIcon />}
                      classes={{ expanded: classes.expanded }}
                      className={classes.reverse}
                    >
                      <Box className={classes.accordionSummary}>
                        <Text
                          text={historicalSectionProps.title}
                          className={classes.accordionTitle}
                          customFontSize='1rem'
                          customFontWeight='bold'
                          customFontFamily='Gotham-Book'
                          textTransform='uppercase'
                          variant={TEXT_VARIANTS.subtitle1}
                        />
                        {showFilteredTag && (
                          <Box ml={2}>
                            <Tag label='Filtered' />
                          </Box>
                        )}
                      </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                      <PerformanceAllocationTable
                        assetFilters={viewFilters}
                        accountCategoryFilters={accountCategoryIds}
                        {...historicalSectionProps}
                        allowDownload={historicalSectionProps.allowDownloadTable}
                        balanceInformationDateFilters={
                          historicalSectionProps.balanceInformationDateFilters
                        }
                        columns={[
                          { hidden: true },
                          { width: '20%', name: '' },
                          { width: '10%', name: 'Quarter to Date' },
                          { width: '10%', name: 'Year to Date' },
                          { width: '10%', name: '1 year' },
                          { width: '10%', name: '2 years' },
                          { width: '10%', name: '3 years' },
                          { width: '10%', name: '5 years' },
                          { width: '10%', name: '7 years' },
                          { width: '10%', name: 'Inception' }
                        ]}
                        showOnlyPercentages={
                          historicalSectionProps.showOnlyPercentages
                        }
                      />
                    </AccordionDetails>
                  </Accordion>
                </Box>
              </WebOnlyContent>
            </Grid>
            <Grid
              item
              xs={12}
              hidden={transactionsSectionProps.hidden || !showOnlyToAdvisorsAndSummitUsers}
            >
              <WebOnlyContent force={transactionsAccordion.expanded}>
                <Box>
                  <Accordion
                    classes={{ root: classes.rootAccordion }}
                    {...transactionsAccordion}
                  >
                    <AccordionSummary
                      expandIcon={<ChevronRightIcon />}
                      classes={{ expanded: classes.expanded }}
                      className={classes.reverse}
                    >
                      <Text
                        text='TRANSACTIONS TABLE VIEW'
                        className={classes.accordionTitle}
                        customFontSize='1rem'
                        customFontWeight='bold'
                        customFontFamily='Gotham-Book'
                        textTransform='uppercase'
                        variant={TEXT_VARIANTS.subtitle1}
                      />
                    </AccordionSummary>
                    <AccordionDetails>
                      <PerformanceTransactionsTable
                        allowDownload={
                          transactionsSectionProps.allowDownloadTable
                        }
                      />
                    </AccordionDetails>
                  </Accordion>
                </Box>
              </WebOnlyContent>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </ExportRoot>
  )
}

PortfolioReviewView.propTypes = portfolioViewPropTypes

PortfolioReviewView.defaultProps = {
  showFilterTag: true,
  transactionsSectionProps: {
    allowDownloadTable: true
  },
  performanceTableSectionProps: {
    allowDownloadTable: true,
    multiHeaderRows: false,
    balanceInformationDateFilters: undefined,
    columns: [
      { hidden: true },
      { name: '' },
      { colSpan: 2, name: 'Total Value' },
      {
        colSpan: 2,
        isSelect: true,
        selectProps: {
          sourceKey: 'balanceInformation2Column',
          selectType: 'date',
          selectedValue: {
            operation: 'subtract',
            unitType: 'month',
            unitValue: 3
          }
        }
      },
      {
        colSpan: 2,
        isSelect: true,
        selectProps: {
          sourceKey: 'balanceInformation3Column',
          selectType: 'date',
          selectedValue: {
            operation: 'subtract',
            unitType: 'month',
            unitValue: 12
          }
        }
      }
    ]
  },
  accountCategoryIds: [13],
  portfolioAllocationPieChartProps: {
    primaryToggleButtonLabel: 'View Component Allocation',
    secondaryToggleButtonLabel: 'View Portfolio Allocation',
    chartProps: {
      orderByOrdinal: false,
      dataOrder: 'asc'
    }
  },
  historicalSectionProps: {
    title: 'Historical Table View',
    allowDownloadTable: true,
    showOnlyPercentages: true
  },
  holdingsSectionProps: {
    allowDownloadTable: true,
    fetchParams: {},
    showExtendedVariant: true,
    allocationTableProps: {
      classTypes: {
        type: 'ASSET_CLASSES',
        mapperType: 'HOLDINGS',
        childType: {
          type: 'ASSETS',
          mapperType: 'HOLDINGS'
        }
      },
      balanceInformationDateFilters: [
        {
          sourceKey: 'balanceInformationToday',
          unitValue: 0,
          unitType: 'day',
          payload: {
            calcType: CALC_TYPES.balance
          }
        }
      ],
      columns: [
        { name: 'Name' },
        { name: 'Units' },
        { name: 'Net Gain' },
        { name: 'Allocation' },
        { name: 'Market Value' }
      ]
    }
  },
  onAvailableDatesChangeStrategyKey: 'default',
  balanceInformationSectionProps: {
    showInvestableAssetsLabel: false
  },
  lineChartVersion: null
}

export default PortfolioReviewView
