import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { LinearProgress } from '@material-ui/core'
import FadeIn from '../../molecules/Transitions/FadeIn'
import Comment from './Comment'
import CommentEditor from './CommentEditor'
import AddComment from './AddComment'
import NewCommentPlaceholder from './NewCommentPlaceholder'

const useStyles = makeStyles((theme) => ({
  panelBody: {
    marginTop: '5px',
    padding: '10px 1.5rem'
  },
  sparkRoot: {
    opacity: ({ loading }) => loading ? 1 : 0,
    transition: ({ loading }) => loading ? 'opacity .25s ease-in-out .25s' : 'opacity .1s ease-in-out',
    height: '2px'
  },
  noData: {
    minHeight: '300px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '22px'
  }
}))

const isAUsefulKey = (key) => key !== 'new'

function CommentPanelBody ({ threadKey, loading, data, onSave, onCancel, title }) {
  const classes = useStyles({ loading })

  if (loading && isAUsefulKey(threadKey)) {
    return (
      <LinearProgress classes={{ root: classes.sparkRoot }} variant='indeterminate' />
    )
  }

  if (!threadKey) {
    return (
      <FadeIn className={classes.panelBody}>
        <div className={classes.noData}>
          No Comments
        </div>
        <AddComment newThread />
      </FadeIn>
    )
  }

  if (threadKey === 'new') {
    return (
      <FadeIn className={classes.panelBody}>
        <CommentEditor onSubmit={onSave} onCancel={onCancel} />
      </FadeIn>
    )
  }

  return (
    <>
      <FadeIn className={classes.panelBody}>
        <NewCommentPlaceholder />
        {data.comments.map(c => (
          <Comment key={c.commentKey} comment={c} authors={data.authors} />
        ))}
      </FadeIn>
      <AddComment />
    </>
  )
}

CommentPanelBody.propTypes = {
  loading: PropTypes.bool,
  data: PropTypes.shape({
    comments: PropTypes.array,
    authors: PropTypes.array
  }),
  threadKey: PropTypes.string,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  title: PropTypes.string
}

export default CommentPanelBody
