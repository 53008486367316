import React, { forwardRef, useMemo } from 'react'
import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'
import { makeStyles } from '@material-ui/core/styles'
import { Box } from '@material-ui/core'
import PresentationTable from '../../PresentationTable'
import { defaultColumnConfig, useColumns } from '../columnConfig'
import { useAlternativesReport } from '../useAlternativesReport'
import { useAppContext } from '../../../../redux/slices/appContext'
import Text from '../../../atoms/Text'
import { overrideColumnConfiguration } from '../../../../utils'

const DEFAULT_PAGE_SIZE = 500

const useStyles = makeStyles(() => ({
  emptyContainer: {
    height: '300px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}))

const AlternativesReportTable = ({
  groupings,
  format = {},
  expanded = true,
  sortable = true,
  useSelectedClient = true,
  initialDepth = 2,
  initialExpandDepth = 1,
  defaultFilter = {},
  columnConfig: _columnConfig = undefined,
  queryParams = { take: DEFAULT_PAGE_SIZE },
  emptyMessage,
  columnConfigOverrides = undefined,
  includeProjections,
  numProjectionPeriods
}, ref) => {
  const { clientId } = useAppContext()
  const classes = useStyles()

  const columnConfig = useMemo(() => {
    const baseColumnConfig = _columnConfig || defaultColumnConfig
    if (!columnConfigOverrides) return baseColumnConfig
    return overrideColumnConfiguration(baseColumnConfig, columnConfigOverrides)
  }, [_columnConfig, columnConfigOverrides])

  const { columns, defaultSort } = useColumns({ columnsConfig: columnConfig, format })

  const defaultFilterParams = useMemo(() => {
    return {
      ...defaultFilter,
      ...(useSelectedClient ? { clientId } : {})
    }
  }, [clientId, useSelectedClient, defaultFilter])

  const { data, isLoading, onLevelExpand } = useAlternativesReport({
    expanded,
    queryParams,
    defaultFilter: defaultFilterParams,
    groupings,
    initialDepth,
    initialExpandDepth,
    includeProjections,
    numProjectionPeriods
  })

  if (!isLoading && isEmpty(data)) {
    return (
      <Box className={classes.emptyContainer}>
        <Text text={emptyMessage} />
      </Box>
    )
  }

  return (
    <PresentationTable.Wrapper>
      <PresentationTable
        ref={ref}
        columns={columns}
        data={data}
        expandable
        loading={isLoading}
        onRowClick={onLevelExpand}
        defaultSort={defaultSort}
        sortable={sortable}
      />
    </PresentationTable.Wrapper>
  )
}

export const alternativesReportTablePropTypes = {
  expanded: PropTypes.bool,
  queryParams: PropTypes.object,
  defaultFilter: PropTypes.object,
  groupings: PropTypes.arrayOf(PropTypes.string).isRequired,
  initialDepth: PropTypes.number,
  initialExpandDepth: PropTypes.number,
  sortable: PropTypes.bool,
  columnConfig: PropTypes.arrayOf(PropTypes.object),
  useSelectedClient: PropTypes.bool,
  format: PropTypes.object,
  emptyMessage: PropTypes.string,
  columnConfigOverrides: PropTypes.arrayOf(PropTypes.object),
  includeProjections: PropTypes.bool,
  numProjectionPeriods: PropTypes.number
}

export const alternativesReportTableDefaultProps = {
  expanded: true,
  queryParams: { take: DEFAULT_PAGE_SIZE },
  defaultFilter: {},
  initialDepth: 2,
  initialExpandDepth: 1,
  sortable: true,
  columnConfig: undefined,
  useSelectedClient: true,
  format: {},
  emptyMessage: 'No Data Available',
  columnConfigOverrides: undefined,
  numProjectionPeriods: 5
}

AlternativesReportTable.propTypes = alternativesReportTablePropTypes
AlternativesReportTable.defaultProps = alternativesReportTableDefaultProps

export default forwardRef(AlternativesReportTable)
