import React from 'react'
import { makeStyles } from '@material-ui/core'
import { useManagerContext } from '../ManagerProvider'

const useStyles = makeStyles((theme) => ({
  header: {
    marginBottom: '20px',
    '& .__name': {
      fontSize: theme.typography.fontSizes.h3
    },
    '& .__desc': {
      fontWeight: theme.typography.weights.light
    }
  }
}))

function ManagerHeader () {
  const classes = useStyles()
  const { manager } = useManagerContext()

  return (
    <div className={classes.header}>
      <div className='__name'>{manager.name}</div>
      <div className='__desc'>{manager.symbol}</div>
    </div>
  )
}

export default ManagerHeader
