import React, { createElement, useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useTooltip } from '@nivo/tooltip'
import { to, useSpring, animated } from '@react-spring/web'
import { useFormattingContext } from '../../organisms/FormattingProvider/FormattingContext'
import { interpolatePosition } from './tools'
import MarkerTooltip from './MarkerTooltip'

export const useMarkerLayer = (valueDegrees, markerConfig, height) => {
  return useCallback((props) => {
    const { centerX, centerY, radius, innerRadius } = props

    return (
      <MarkerLayer
        value={valueDegrees}
        cx={centerX}
        cy={centerY}
        cr={innerRadius + ((radius - innerRadius) / 2)}
        marker={markerConfig}
        height={height}
      />
    )
  }, [valueDegrees, markerConfig, height])
}

const useTooltipEvents = (marker, tooltip) => {
  const { formatter } = useFormattingContext()
  const { showTooltipFromEvent, hideTooltip } = useTooltip()

  const datum = useMemo(() => ({
    id: marker.id,
    formattedValue: formatter(marker.value, marker.format || 'percentage'),
    color: marker.color
  }), [formatter, marker])

  const handleMouseEnter = useCallback((event) => {
    showTooltipFromEvent(createElement(tooltip, { datum }), event)
  }, [datum, showTooltipFromEvent, tooltip])

  const handleMouseLeave = useCallback(() => {
    hideTooltip()
  }, [hideTooltip])

  const handleMouseMove = useCallback((event) => {
    showTooltipFromEvent(createElement(tooltip, { datum }), event)
  }, [datum, showTooltipFromEvent, tooltip])

  return {
    handleMouseEnter,
    handleMouseLeave,
    handleMouseMove
  }
}

const useAnimatedMarker = (cr, cx, cy, value) => {
  const { ix, iy } = useMemo(() => interpolatePosition(cr, cx, cy), [cr, cx, cy])
  const [p] = useSpring(() => ({
    from: { value: 0, opacity: 0 },
    to: { value, opacity: 1 },
    config: (key) => {
      return key === 'value' ? {
        mass: 1,
        friction: 6,
        tension: 15
      } : {}
    },
    delay: 200,
    loop: false,
    reset: false
  }), [value])

  return {
    vx: to(p.value, ix),
    vy: to(p.value, iy),
    styles: p
  }
}

function MarkerLayer ({ value, cx, cy, cr, marker, tooltip, height }) {
  const {
    color = 'black',
    radius = 20
  } = marker
  const scaledRadius = useMemo(() => radius * (height / 250), [radius, height])
  const innerRadius = useMemo(() => scaledRadius / 2, [scaledRadius])
  const { handleMouseEnter, handleMouseLeave, handleMouseMove } = useTooltipEvents(marker, tooltip)
  const { vx, vy, styles } = useAnimatedMarker(cr, cx, cy, value)

  return (
    <>
      <filter id='shadow' colorInterpolationFilters='sRGB'>
        <feDropShadow dx='2' dy='2' stdDeviation='3' floodOpacity='0.5' />
      </filter>
      <g filter='url(#shadow)' className='__marker-layer' onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} onMouseMove={handleMouseMove}>
        <animated.circle style={styles} r={scaledRadius} cx={vx} cy={vy} stroke={color} fill={color} />
        <animated.circle style={styles} r={innerRadius} cx={vx} cy={vy} stroke='white' fill='white' />
      </g>
    </>
  )
}

MarkerLayer.propTypes = {
  value: PropTypes.number,
  cx: PropTypes.number,
  cy: PropTypes.number,
  cr: PropTypes.number,
  marker: PropTypes.shape({
    id: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    color: PropTypes.string,
    radius: PropTypes.number,
    format: PropTypes.string
  }),
  tooltip: PropTypes.any,
  height: PropTypes.number
}

MarkerLayer.defaultProps = {
  tooltip: MarkerTooltip
}

export default MarkerLayer
