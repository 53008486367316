import React, { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { Box } from '@material-ui/core'
import { ADMIN_ROUTES } from '../../../../constants'
import FadeIn from '../../../molecules/Transitions/FadeIn'
import ClassifyAssetsContextProvider from './ClassifyAssetsContext'
import ClassifyAssetsTableWrapper from './tableWrapper'
import ClassifyAssetsTable from './table'
import ClassifyAssetsHeader from './header'
import { defaultColumnConfig } from './helpers'

function ClassifyAssets () {
  const history = useHistory()

  const onClickRow = useCallback((row) => {
    if (row.original) {
      history.push(`${ADMIN_ROUTES.CLASSIFY_ASSETS}/${row.original.assetId}`)
    }
  }, [history])

  return (
    <FadeIn>
      <Box p={2}>
        <ClassifyAssetsContextProvider
          defaultColumnConfig={defaultColumnConfig}
        >
          <ClassifyAssetsTableWrapper>
            <ClassifyAssetsTable
              header={<ClassifyAssetsHeader />}
              onClickRow={onClickRow}
            />
          </ClassifyAssetsTableWrapper>
        </ClassifyAssetsContextProvider>
      </Box>
    </FadeIn>
  )
}

export default ClassifyAssets
