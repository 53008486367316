import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  actionGroup: {
    position: 'absolute',
    top: 10,
    right: 10,
    display: 'flex',
    flexDirection: 'row',
    gap: '10px',
    zIndex: 100
  }
}))

function ActionGroup ({ children }) {
  const classes = useStyles()

  return (
    <div className={classes.actionGroup}>
      {children}
    </div>
  )
}

ActionGroup.propTypes = {
  children: PropTypes.node
}

export default ActionGroup
