import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import ClientConversationCard from './ClientConversationCard'

const useStyles = makeStyles(theme => ({
  list: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap'
  }
}))

const ConversationCardList = ({ conversations }) => {
  const classes = useStyles()

  return (
    <div className={classes.list}>
      {conversations.map(c => (
        <ClientConversationCard key={c.conversationKey} clientConversation={c} />
      ))}
    </div>
  )
}

ConversationCardList.propTypes = {
  conversations: PropTypes.arrayOf(PropTypes.shape({
    conversationKey: PropTypes.string,
    name: PropTypes.string,
    statusTypeId: PropTypes.string,
    firstActivityDate: PropTypes.string,
    lastActivityDate: PropTypes.string,
    lastMessageDate: PropTypes.string,
    lastMessagePreview: PropTypes.string,
    clientId: PropTypes.number,
    clientShortName: PropTypes.string,
    clientLongName: PropTypes.string,
    clientAbbreviation: PropTypes.string,
    clientAvatarUrl: PropTypes.string,
    clientAvatarIsPublic: PropTypes.oneOfType([PropTypes.bool, PropTypes.number])
  }))
}

ConversationCardList.defaultProps = {
}

export default ConversationCardList
