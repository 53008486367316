import React, { useCallback, useImperativeHandle, useState } from 'react'
import RoundedModal from '../../../../../../molecules/RoundedModal'
import ReactivateUserForm from './form'

const ReactivateUserDialog = React.forwardRef(function ReactivateUserDialog (_, ref) {
  const [dialogState, setDialogState] = useState({ open: false, processing: false })
  const close = useCallback(() => {
    setDialogState({ open: false, processing: false })
  }, [setDialogState])

  useImperativeHandle(ref, () => ({
    open: (user) => setDialogState({ open: true, processing: false, user })
  }), [setDialogState])

  return (
    <RoundedModal
      title='Reactivate Wealth Owner'
      open={dialogState.open}
      onClose={close}
      position='top'
      disablePortal={false}
    >
      <div>
        {dialogState.open ? (<ReactivateUserForm user={dialogState.user} onComplete={close} onCancel={close} />) : null}
      </div>
    </RoundedModal>
  )
})

ReactivateUserDialog.propTypes = {
}

export default ReactivateUserDialog
