import React from 'react'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  preformattedTextInput: {
    fontWeight: theme.typography.weights.light,
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.fontSizes.xxl,
    whiteSpace: 'pre-line',
    wordWrap: 'break-word',
    border: `1px solid ${theme.palette.gray.dark}`,
    borderRadius: theme.layout.radius.tight,
    padding: theme.layout.padding.p10,
    width: '100%',
    minHeight: 'max(10vh, 120px)'
  }
}))

function PreformattedTextFieldInput (props) {
  const classes = useStyles()

  return (
    <textarea className={classes.preformattedTextInput} {...props} />
  )
}

export default PreformattedTextFieldInput
