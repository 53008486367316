import React from 'react'
import PropTypes from 'prop-types'
import { Box } from '@material-ui/core'
import Select from '../Select'
import { DATE_TYPES } from '../../../constants'
import BarChartContainer from './container'
import { useChartGroupedCoreData } from './useChartGroupedCoreData'
import BarChart from './barChart'

const dateTypeOptions = Object.keys(DATE_TYPES).map((dateType) => ({
  label: dateType,
  value: dateType
}))

const GroupedCoreBarChart = ({
  color,
  dateType,
  groupings,
  dataPoints,
  chartProps,
  tooltipFormat,
  defaultFilters,
  axisLeftFormat,
  axisBottomFormat,
  emptySectionLabel,
  defaultDateRange,
  showDataPointsSelectors
}) => {
  const {
    series,
    isLoading,
    dataPoint: {
      label: dataPointLabel,
      value: dataPointValue,
      onChange: onChangeDataPoint
    },
    dataInterval: {
      value: dataIntervalValue,
      onChange: onChangeDataInterval
    }
  } = useChartGroupedCoreData({
    groupings,
    defaultFilters,
    defaultDateRange,
    defaultDateType: dateType,
    labelFormat: axisBottomFormat,
    defaultDataPoints: dataPoints
  })

  return (
    <>
      {showDataPointsSelectors && (
        <Box my='1rem' display='flex' flexDirection='row' gap='1rem'>
          <Select
            value={dataPointValue}
            options={dataPoints}
            onChange={(_, option) => onChangeDataPoint(option)}
          />
          <Select
            value={dataIntervalValue}
            options={dateTypeOptions}
            onChange={onChangeDataInterval}
          />
        </Box>
      )}
      <BarChart
        data={series?.[dataPointValue]}
        dataSetName={dataPointLabel}
        color={color}
        isLoading={isLoading}
        chartProps={chartProps}
        tooltipFormat={tooltipFormat}
        axisLeftFormat={axisLeftFormat}
        emptySectionLabel={emptySectionLabel}
        {...chartProps}
      />
    </>
  )
}

GroupedCoreBarChart.propTypes = {
  color: PropTypes.string,
  dataPoints: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string
    })
  ),
  groupings: PropTypes.array,
  dateType: PropTypes.oneOf(Object.values(DATE_TYPES)),
  chartProps: PropTypes.object,
  defaultFilters: PropTypes.object,
  tooltipFormat: PropTypes.string,
  axisLeftFormat: PropTypes.string,
  axisBottomFormat: PropTypes.string,
  emptySectionLabel: PropTypes.string,
  defaultDateRange: PropTypes.string,
  showDataPointsSelectors: PropTypes.bool
}

GroupedCoreBarChart.defaultProps = {
  color: '#4472C4',
  dataPoints: [],
  groupings: [],
  dateType: DATE_TYPES.month,
  defaultFilters: {},
  tooltipFormat: 'human',
  axisLeftFormat: 'marketValue',
  axisBottomFormat: "MMM 'D",
  defaultDateRange: 'L12M',
  emptySectionLabel: 'No data found',
  showDataPointsSelectors: false
}

GroupedCoreBarChart.Chart = BarChart
GroupedCoreBarChart.Container = BarChartContainer

export default GroupedCoreBarChart
