import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Tooltip, lighten, makeStyles } from '@material-ui/core'
import { getRandomColor } from '../../../../../utils'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '0.5rem',
    maxWidth: '26rem'
  },
  item: {
    padding: '0.25rem 0.5rem',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '0.5rem',
    gap: '6px',
    border: '1px solid #E7E7E7',
    background: theme.palette.white,
    fontWeight: 'bold'
  },
  tooltip: {
    '& ul': {
      padding: '0.5rem',
      listStyleType: 'none'
    }
  }
}))

const ClientTagsCell = ({
  row: { original: client },
  column: { visibleTagsUntilCounting }
}) => {
  const classes = useStyles()

  const { tags, remainingTagNames, remainingTagsCounter } = useMemo(() => {
    const groupsCount = client?.groups?.length || 0
    const remainingGroupsCount = groupsCount - visibleTagsUntilCounting
    return {
      tags:
        groupsCount <= visibleTagsUntilCounting
          ? client?.groups
          : client?.groups.slice(0, visibleTagsUntilCounting),
      remainingTagNames: client?.groups
        .slice(visibleTagsUntilCounting)
        .map(({ name }) => name),
      remainingTagsCounter: remainingGroupsCount
    }
  }, [client?.groups, visibleTagsUntilCounting])

  return (
    <div className={classes.container}>
      {tags.map((group) => {
        const fallbackColor = getRandomColor(500)
        const color = group?.color || fallbackColor || '#000'
        const lightenColor = lighten(color, 0.9)

        return (
          <div
            key={group.groupId}
            className={classes.item}
            style={{
              backgroundColor: lightenColor,
              border: `1px solid ${color}`
            }}
          >
            {group.name}
          </div>
        )
      })}
      {remainingTagsCounter > 0 && (
        <Tooltip
          title={
            <ul>
              {remainingTagNames.map((tag) => (
                <li key={tag}>{tag}</li>
              ))}
            </ul>
          }
          classes={{ tooltip: classes.tooltip }}
        >
          <div className={classes.item}>
            +{remainingTagsCounter}
          </div>
        </Tooltip>
      )}
    </div>
  )
}

ClientTagsCell.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      groups: PropTypes.array
    })
  }),
  column: PropTypes.shape({
    visibleTagsUntilCounting: PropTypes.number
  })
}

export default ClientTagsCell
