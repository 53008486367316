import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  targetListItem: {
    display: 'block',
    backgroundColor: theme.palette.white.main,
    border: `1px solid ${theme.palette.darkGrey}`,
    maxWidth: '20rem',
    minWidth: '20rem',
    minHeight: '10rem',
    margin: '1rem',
    padding: '0rem 1rem',
    '& .__title': {
    },
    '& .__description': {
      color: theme.palette.darkGrey
    }
  }
}))

const TargetListItem = ({ target }) => {
  const classes = useStyles()
  const to = useMemo(() => {
    return `/data-home/targets/${target.ingestionTargetId}`
  }, [target.ingestionTargetId])

  return (
    <Link to={to} className={classes.targetListItem}>
      <div>
        <h3 className='__title'>[{target.ingestionTargetId}] {target.name}</h3>
        <div className='__description'>{target.schema}.{target.table}</div>
      </div>
    </Link>
  )
}

TargetListItem.propTypes = {
  target: PropTypes.shape({
    ingestionTargetId: PropTypes.number,
    name: PropTypes.string,
    schema: PropTypes.string,
    table: PropTypes.string
  })
}

export default TargetListItem
