import React, { useCallback, useMemo, useRef } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import SydButton from '../../../../../commonDesign/Button'
import { useResetUserMutation } from '../../../../../../api/users'
import ResetUserDialog from './ResetUserDialog'

const ResetUserAction = ({ wealthOwner, status, isLoading }) => {
  const { mutateAsync: resetUser, isLoading: resetting } = useResetUserMutation()
  const dialogRef = useRef()
  const confirmHandler = useCallback(async () => {
    try {
      await resetUser(wealthOwner)
    } catch (err) {
      console.error(err)
      alert('There was an unexpected issue resetting the user')
    }
  }, [wealthOwner, resetUser])

  const disabled = useMemo(() => {
    if (isLoading) return true

    switch (status) {
      case 'NOT AVAILABLE':
      case 'PROVISIONED':
        return true
      default:
        return false
    }
  }, [status, isLoading])

  const description = useMemo(() => {
    if (isLoading) return ''

    switch (status) {
      case 'NOT AVAILABLE':
      case 'PROVISIONED':
        return 'Action is not currently available.'
      default:
        return 'Reset user credentials. User will need to re-activate their account.'
    }
  }, [status, isLoading])

  return (
    <div className='__row'>
      <div>
        <SydButton
          size='sm'
          icon='sync'
          variant='outline'
          disabled={disabled}
          processing={resetting}
          onClick={() => dialogRef.current.open()}
        >
          Reset User
        </SydButton>
      </div>
      <div className={clsx({ __disabled: disabled })}>
        {description}
      </div>
      <ResetUserDialog ref={dialogRef} onConfirm={confirmHandler} processing={resetting} />
    </div>
  )
}

ResetUserAction.propTypes = {
  wealthOwner: PropTypes.object,
  status: PropTypes.string,
  isLoading: PropTypes.bool
}

export default ResetUserAction
