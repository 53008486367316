import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import SydInput from '../../../../../commonDesign/SydInput'

function parseAttributes (htmlString) {
  try {
    const parser = new DOMParser()
    const doc = parser.parseFromString(htmlString, 'text/html')
    const element = doc.body.firstChild

    const frameProps = {}
    let tag = null
    if (element) {
      Array.from(element.attributes).forEach(attr => {
        frameProps[attr.name] = attr.value
      })
      tag = element.tagName.toLowerCase()
    }

    return { frameProps, tag, raw: htmlString }
  } catch (err) {
    return null
  }
}

function EmbeddedContentFieldInput ({ value, onChange, ...props }) {
  const _value = useMemo(() => {
    if (typeof value === 'string') {
      return value.replaceAll('&gt;', '>').replaceAll('&lt;', '<')
    }
    return value?.raw?.replaceAll('&gt;', '>')?.replaceAll('&lt;', '<')
  }, [value])

  const handleChange = useCallback((e) => {
    const attributes = parseAttributes(e.target.value)
    onChange({
      ...(attributes || {}),
      raw: e.target.value
    })
  }, [onChange])

  return (
    <>
      <SydInput size='sm' value={_value} onChange={handleChange} {...props} />
      {value?.frameProps ? (
        <iframe title='Embedded Content' {...value.frameProps} />
      ) : null}
    </>
  )
}

EmbeddedContentFieldInput.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func
}

export default EmbeddedContentFieldInput
