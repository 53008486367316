import DateRangeSelect from './DateRangeSelect'
import ChartOptionsProvider from './ChartOptionsProvider'
import IntervalSelect from './IntervalSelect'
import SeriesSelect from './SeriesSelect'
import LineChartSubscriber from './index'

const PerformanceLineModule = {
  LineChart: LineChartSubscriber,
  LineChartDateRangeSelect: DateRangeSelect,
  LineChartIntervalSelect: IntervalSelect,
  LineChartSeriesSelect: SeriesSelect,
  LineChartProvider: ChartOptionsProvider
}

export default PerformanceLineModule
