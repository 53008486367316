import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'
import { makeStyles } from '@material-ui/core/styles'
import { Box, useTheme } from '@material-ui/core'
import { useAlternatives } from '../../../api/coreData'
import { useAppContext } from '../../../redux/slices/appContext'
import Text from '../../atoms/Text'
import ProgressBar from '../../atoms/ProgressBar'
import { useFormattingContext } from '../FormattingProvider/FormattingContext'
import Skeleton from '../../atoms/Skeleton'

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: '20px'
  },
  emptyContainer: {
    height: '300px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  progressBarContainer: {
    display: 'flex',
    width: '100%',
    overflow: 'auto',
    flexDirection: 'column'
  },
  itemHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: '10px'
  },
  bar: {
    position: 'relative',
    width: '100%'
  },
  skeletonBar: {
    marginBottom: '26px'
  },
  percentage: {
    transition: 'left 1.4s ease-in-out',
    position: 'absolute',
    top: '4px',
    right: '4px'
  },
  date: {
    margin: '8px 0 26px'
  },
  title: {
    marginBottom: '36px'
  }
}))

const useAlternativesQuery = ({ defaultFilter, defaultLevelType, top }) => {
  const { clientId, availableDates, loadingAvailableDates } = useAppContext()
  const query = useMemo(() => loadingAvailableDates ? null : {
    dateRange: {
      startDate: availableDates.mainDate,
      endDate: availableDates.mainDate
    },
    levelType: defaultLevelType,
    resultType: 'details',
    filters: {
      ...(defaultFilter || {}),
      clientId: clientId,
      inFlight: true
    },
    sort: [
      { field: 'capitalCalled', dir: 'desc' }
    ],
    take: top
  }, [clientId, availableDates, loadingAvailableDates, defaultFilter, defaultLevelType, top])

  const { data, isLoading } = useAlternatives(query)

  return {
    data: data || [],
    isLoading
  }
}

const TopInFlight = ({
  colors,
  defaultFilter,
  defaultLevelType,
  emptyMessage,
  format,
  title,
  top
}) => {
  const classes = useStyles()
  const theme = useTheme()
  const { formatter } = useFormattingContext()
  const { data = [], isLoading } = useAlternativesQuery({ defaultFilter, defaultLevelType, top })

  const dataSet = useMemo(() => {
    if (isLoading) return []
    return data.map((item) => ({
      id: item.levelId,
      color: colors.percentageCalled || '#212945',
      title: item.assetName,
      date: formatter(item.firstCommitDate, format.firstCommitDate),
      percentage: item.percentageCalled * 100,
      percentageLabel: formatter(item.percentageCalled, format.percentageCalled),
      balance: formatter(item.committedCapital, format.committedCapital)
    }))
  }, [data, isLoading, format, formatter, colors])

  const loadingState = useMemo(() => {
    return (
      <Box className={classes.container}>
        <Skeleton height='30px' width='200px' className={classes.title} />
        <Box className={classes.progressBarContainer}>
          {Array(top).fill(0).map((_, index) => (
            <div className={classes.skeletonBar} key={`skeleton-${index}`}>
              <Skeleton height='80px' />
            </div>
          ))}
        </Box>
      </Box>
    )
  }, [top, classes])

  if (isLoading) {
    return loadingState
  }

  if (isEmpty(dataSet)) {
    return (
      <Box className={classes.emptyContainer}>
        <Text text={emptyMessage} />
      </Box>
    )
  }

  return (
    <Box className={classes.container}>
      <Text
        text={title}
        customFontSize='24px'
        customFontFamily='GothamPro-Bold'
        color='#141929'
        className={classes.title}
      />
      <Box className={classes.progressBarContainer}>
        {dataSet.map(
          ({ id, color, title, percentage, balance, date, percentageLabel }) => {
            return (
              <div key={id}>
                <div className={classes.itemHeader}>
                  <Text text={title} color='#141929' customFontSize='1rem' />
                  <Text text={balance} color='#141929' customFontSize='1rem' />
                </div>
                <div className={classes.bar}>
                  <ProgressBar
                    squared
                    color={color}
                    backgroundColor={theme.palette.seaFoam50}
                    percentage={percentage}
                  />
                  <Text className={classes.percentage} text={percentageLabel} />
                </div>
                <Text
                  text={date}
                  color='#212945'
                  className={classes.date}
                />
              </div>
            )
          }
        )}
      </Box>
    </Box>
  )
}

TopInFlight.propTypes = {
  colors: PropTypes.shape({
    percentageCalled: PropTypes.string
  }),
  defaultFilter: PropTypes.object,
  defaultLevelType: PropTypes.string,
  emptyMessage: PropTypes.string,
  format: PropTypes.shape({
    firstCommitDate: PropTypes.string,
    percentageCalled: PropTypes.string,
    committedCapital: PropTypes.string
  }),
  title: PropTypes.string,
  top: PropTypes.number
}

TopInFlight.defaultProps = {
  defaultFilter: {},
  defaultLevelType: 'asset',
  colors: {},
  emptyMessage: 'No current investments in flight',
  format: {},
  title: 'Top in Flight',
  top: 5
}

export default TopInFlight
