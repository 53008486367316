import React, { useCallback, useMemo, useState } from 'react'
import { Box, CircularProgress, makeStyles } from '@material-ui/core'
import { useOktaAuth } from '@okta/okta-react'
import PropTypes from 'prop-types'
import { Controller, useForm } from 'react-hook-form'
import { useChangeMyPasswordMutation, useMyPasswordPolicy } from '../../../../api/users'
import FadeIn from '../../../molecules/Transitions/FadeIn'
import SydButton from '../../../commonDesign/Button'
import PasswordInput from '../shared/PasswordInput'
import RequirementValidator from './RequirementValidator'
import { createRuleValidators } from './useRequirementValidator'

const useStyles = makeStyles((theme) => ({
  passwordForm: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: '15px',
    '& .__form': {
      minWidth: '400px',
      display: 'flex',
      flexDirection: 'column',
      gap: '10px'
    },
    '& .__requirements': {
      padding: '15px',
      fontWeight: theme.typography.weights.light,
      borderLeft: `1px solid ${theme.palette.gray.main}`
    },
    '& .__header': {
      fontSize: theme.typography.fontSizes.h5,
      fontWeight: theme.typography.weights.bold
    }
  },
  errors: {
    display: 'flex',
    gap: '10px',
    flexWrap: 'wrap',
    marginBottom: '15px',
    '& > .__error': {
      padding: '1px 10px',
      borderRadius: '4px',
      fontSize: theme.typography.fontSizes.xl,
      backgroundColor: theme.palette.danger.main,
      color: theme.palette.danger.contrast
    }
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    gap: '20px'
  }
}))

function PasswordForm ({ onClose }) {
  const classes = useStyles()
  // Used for the hidden input
  const { authState } = useOktaAuth()
  const form = useForm({
    mode: 'onChange',
    defaultValues: {
      current: '',
      password: '',
      confirm: ''
    }
  })
  const password = form.watch('password')

  // Used to drive the rules on the page
  const { data, isLoading } = useMyPasswordPolicy()
  const requirements = useMemo(() => {
    if (!data) return null

    const complexity = data.policy.passwordPolicy.password.complexity
    const rules = Object.entries({
      minLength: complexity.minLength,
      minLowerCase: complexity.minLowerCase,
      minUpperCase: complexity.minUpperCase,
      minNumber: complexity.minNumber,
      minSymbol: complexity.minSymbol
    }).filter(x => !!x[1])
    const validators = createRuleValidators(rules)

    return { rules, validators }
  }, [data])

  const confirmMatches = useCallback((v) => v === password ? true : 'Passwords must match', [password])
  const { mutateAsync: changePassword } = useChangeMyPasswordMutation()
  const [submitState, setSubmitState] = useState({ processing: false })
  const { handleSubmit } = form
  const onSubmit = useCallback(async (e) => {
    const onSuccess = async (formData) => {
      setSubmitState({ processing: true })
      const saveResult = await changePassword({
        oldPassword: formData.current,
        newPassword: formData.password
      })
      console.log(saveResult)
      if (saveResult.success) {
        onClose()
      } else {
        setSubmitState({
          processing: false,
          submitErrors: saveResult.error?.errorCauses?.map(e => e.errorSummary) ?? ['Something went wrong changing your password']
        })
      }
    }

    const onError = (errors) => {
      // eslint-disable-next-line no-throw-literal
      throw { validationErrors: errors }
    }
    const handler = handleSubmit(onSuccess, onError)
    await handler(e)
  }, [handleSubmit, setSubmitState, changePassword, onClose])

  if (isLoading || !requirements) {
    return (
      <div className={classes.formContainer}>
        <Box display='flex' justifyContent='center' alignItems='center' width='100%' height='300px'>
          <CircularProgress size={50} />
        </Box>
      </div>
    )
  }

  return (
    <div className={classes.formContainer}>
      {submitState.submitErrors ? (
        <FadeIn className={classes.errors}>
          {submitState.submitErrors.map(err => (
            <div key={err} className='__error'>{err}</div>
          ))}
        </FadeIn>
      ) : null}
      <FadeIn className={classes.passwordForm}>
        <div className='__form'>
          <input type='hidden' autoComplete='username' value={authState.idToken.claims.email} />
          <Controller
            name='current'
            control={form.control}
            rules={{ required: 'Required' }}
            render={({ field, fieldState }) => (
              <PasswordInput
                label='Current Password'
                autoComplete='current-password'
                error={(form.formState.isSubmitted || fieldState.isDirty || fieldState.isTouched) && fieldState.error}
                {...field}
              />
            )}
          />
          <Controller
            name='password'
            control={form.control}
            rules={{ required: 'Required', validate: requirements.validators }}
            render={({ field, fieldState }) => (
              <PasswordInput
                label='New Password'
                autoComplete='new-password'
                error={(form.formState.isSubmitted || fieldState.isDirty || fieldState.isTouched) && fieldState.error}
                {...field}
              />
            )}
          />
          <Controller
            name='confirm'
            control={form.control}
            rules={{ required: 'Required', validate: confirmMatches }}
            render={({ field, fieldState }) => (
              <PasswordInput
                label='Confirm Password'
                autoComplete='new-password'
                error={(form.formState.isSubmitted || fieldState.isDirty || fieldState.isTouched) && fieldState.error}
                {...field}
              />
            )}
          />
        </div>
        <div className='__requirements'>
          <header className='__header'>Password Requirements:</header>
          {requirements.rules.map(r => (
            <RequirementValidator key={r[0]} rule={r} value={password} />
          ))}
        </div>
      </FadeIn>
      <div className={classes.actions}>
        <SydButton variant='outline' onClick={onClose} disabled={submitState.processing}>Cancel</SydButton>
        <SydButton
          variant='primary'
          disabled={isLoading || !form.formState.isValid}
          onClick={onSubmit}
          processing={submitState.processing}
        >
          Submit
        </SydButton>
      </div>
    </div>
  )
}

PasswordForm.propTypes = {
  onClose: PropTypes.func
}

export default PasswordForm
