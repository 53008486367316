import React, { useCallback, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Controller, useForm } from 'react-hook-form'
import { Checkbox, CircularProgress, Tooltip } from '@material-ui/core'
import SydButton from '../../../../../commonDesign/Button'
import { useDialogStyles } from '../common'
import { useAccountSearch } from '../../../../../../api/coreData'
import Icon from '../../../../../atoms/Icon'

function AssignedWarning ({ account, groupType, group }) {
  const [isAssigned, assignedTo] = useMemo(() => {
    const isAssigned = groupType.uniqueMembers && groupType.groups.some(g =>
      g.groupId !== group.groupId && g.accounts.some(a => a.accountId === account.accountId)
    )

    const assignedTo = groupType.groups.find(g => g.accounts.some(a => a.accountId === account.accountId))
    return [isAssigned, assignedTo]
  }, [groupType, group, account])

  if (!isAssigned) return null

  return (
    <Tooltip title={`This account is already assigned to ${assignedTo?.longName}. Assigning it to this group will remove it from that group`}>
      <div className='__rowGroup'>
        <Icon name='warning' />
        Assigned to <strong>{assignedTo?.longName}</strong>
      </div>
    </Tooltip>
  )
}

AssignedWarning.propTypes = {
  account: PropTypes.any,
  groupType: PropTypes.any,
  group: PropTypes.any
}

function ManageMembershipForm ({ onComplete, onClose, groupType, group, client }) {
  const classes = useDialogStyles()

  const { handleSubmit, ...form } = useForm({
    mode: 'onChange',
    defaultValues: {
      clientId: client.clientId,
      groupTypeId: groupType.groupTypeId,
      groupId: group.groupId,
      accounts: group.accounts.map(x => x.accountId)
    }
  })

  const query = useMemo(() => {
    return {
      filters: {
        assignedToClientIds: [client.clientId]
      },
      take: 100,
      sort: [{ field: 'accountName', dir: 'asc' }]
    }
  }, [client])
  const { data, isFetching } = useAccountSearch(query)
  const options = data?.data || []

  const [processing, setProcessing] = useState(false)
  const onAssign = useCallback(async (e) => {
    const onValid = async (form) => {
      setProcessing(true)
      try {
        await onComplete({
          accountIds: form.accounts
        })
      } finally {
        setProcessing(false)
      }
    }
    const onInvalid = (errors) => {
      console.error(errors)
    }

    const handler = handleSubmit(onValid, onInvalid)
    try {
      await handler(e)
    } catch (err) {
      alert('There was a problem saving the group assignment')
      console.error(err)
      throw err
    }
  }, [handleSubmit, onComplete])

  return (
    <>
      <div className={classes.form}>
        <div>
          {isFetching ? (
            <CircularProgress />
          ) : options.length ? (
            <Controller
              control={form.control} name='accounts'
              render={({ field }) => (
                <table className={classes.checkTable}>
                  <tbody>
                    {options.map(x => (
                      <tr
                        key={x.accountId}
                        onClick={() => {
                          const isChecked = field.value.some(f => f === x.accountId)
                          if (!isChecked) {
                            field.onChange({
                              target: {
                                value: [...field.value, +x.accountId]
                              }
                            })
                          } else {
                            field.onChange({
                              target: {
                                value: [...field.value.filter(f => f !== (+x.accountId))]
                              }
                            })
                          }
                        }}
                      >
                        <td>
                          <Checkbox
                            className={classes.inputLabel}
                            checked={field.value.some(f => f === x.accountId)}
                            value={x.accountId}
                            onChange={(e) => {
                              if (e.target.checked) {
                                field.onChange({
                                  target: {
                                    value: [...field.value, +e.target.value]
                                  }
                                })
                              } else {
                                field.onChange({
                                  target: {
                                    value: [...field.value.filter(f => f !== (+e.target.value))]
                                  }
                                })
                              }
                            }}
                            color='#ff0000'
                          />
                        </td>
                        <td>{x.accountName}</td>
                        <td>{x.accountNumber}</td>
                        <td><AssignedWarning account={x} group={group} groupType={groupType} /></td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            />
          ) : (
            <div>No Results</div>
          )}
        </div>
      </div>
      <div className={classes.actions}>
        <SydButton variant='ghost' disabled={isFetching} onClick={onClose}>Cancel</SydButton>
        <SydButton
          variant='primary'
          onClick={onAssign}
          processing={processing}
          disabled={isFetching}
        >
          Assign
        </SydButton>
      </div>
    </>
  )
}

ManageMembershipForm.propTypes = {
  onComplete: PropTypes.func,
  onClose: PropTypes.func,
  groupType: PropTypes.shape({
    groupTypeId: PropTypes.number,
    longName: PropTypes.string,
    uniqueMembers: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
    groups: PropTypes.arrayOf(PropTypes.shape({
      accounts: PropTypes.arrayOf(PropTypes.shape({
        accountId: PropTypes.number,
        accountName: PropTypes.string
      }))
    }))
  }),
  group: PropTypes.shape({
    groupId: PropTypes.number,
    longName: PropTypes.string,
    accounts: PropTypes.array
  }),
  client: PropTypes.object
}

export default ManageMembershipForm
