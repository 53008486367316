import { useState, useCallback } from 'react'

function useFallbackImg (img, fallback) {
  const [src, setImg] = useState(img || fallback)

  const onError = useCallback(
    (e) => {
      setImg(fallback)
    },
    [fallback]
  )

  return { src, onError }
}

export default useFallbackImg
