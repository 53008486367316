import { useCallback, useEffect, useMemo, useState } from 'react'
import { find, first, get, isEmpty, isFunction, last } from 'lodash'
import dayjs from 'dayjs'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import utc from 'dayjs/plugin/utc'
import { darken } from '@material-ui/core'
import { useAppContext } from '../../../redux/slices/appContext'
import { useDeepCompareMemo, useFetchState, useToggle } from '../../../hooks'
import { getHistoricalRegisterIdentifiers } from '../../../service'
import {
  CALC_TYPES,
  DATE_TYPES,
  DAYJS_UNIT_TYPES,
  LEVEL_TYPES,
  CHART_CURVE_INTERPOLATIONS,
  BUTTON_SIZES
} from '../../../constants'
import { capitalizeFirstLetter, isNullOrUndefined } from '../../../utils'
import {
  DATE_RANGE_CUSTOM_OPTION_KEYS,
  useRelativeDateRange
} from '../../molecules/RelativeDateSelect'
import { localStorageHelper } from '../../../utils/localStorageHelper'
import { useCoreSeriesData, useNormalizeDates } from '../../../api/coreData'

dayjs.extend(isSameOrBefore)
dayjs.extend(utc)

const SERIES_IDS = {
  ENDING_VALUE_ID: 'endingValue',
  RETURN_ID: 'cumulativeReturn',
  CLIENT_BENCHMARK_ID: 'clientBenchmark',
  NET_ADDITIONS_ID: 'adjustedNetAdditions'
}

const DEFAULT_COLORS = {
  [SERIES_IDS.ENDING_VALUE_ID]: '#3C5357',
  [SERIES_IDS.RETURN_ID]: 'green',
  [SERIES_IDS.CLIENT_BENCHMARK_ID]: '#8ac7fe',
  [SERIES_IDS.NET_ADDITIONS_ID]: '#bca167'
}

const seriesMappers = {
  default: (summaryValues) => {
    return summaryValues.map(({ value, label }) => ({
      x: label,
      y: value
    }))
  },
  netAdditions: (values) => {
    return values.map(({ value, label }) => ({
      x: label,
      y: value
    }))
  }
}

const lineChartMappers = {
  axisLeft: {
    percentagesMapper: function () {
      return this.isReturnOrBenchmark
        ? (value) => Math.trunc(value * 100 * 100) / 100 + '%'
        : '.2s'
    }
  },
  identifiersMapper: {
    skipCumulativeBenchmarkMapper: function () {
      return this.options.filter(
        (identifier) => identifier.value !== 'cumulativeBenchmark'
      )
    }
  },
  lineData: {
    summaryMapper: function () {
      return this.values
        ? [
          {
            id: 'Summary',
            color: this.color || 'green',
            data: this.values.map(({ value, label }) => ({
              x: label,
              y: value
            }))
          }
        ]
        : []
    }
  }
}

const getSeriesData = ({ seriesId, data, series, color: _color }) => {
  const { label, color } = find(series, { id: seriesId }) || {
    label: seriesId,
    color: DEFAULT_COLORS[seriesId]
  }
  return { id: label, color: color || _color, data }
}

const getSeriesLineData = ({
  identifier,
  selectedSeries = [],
  netAdditionsSeries = [],
  series = [],
  color
}) => {
  if (isEmpty(selectedSeries)) return []

  const seriesData = [
    getSeriesData({
      seriesId: identifier,
      data: seriesMappers.default(selectedSeries),
      series,
      color
    })
  ]
  if (!isEmpty(netAdditionsSeries)) {
    seriesData.push(
      getSeriesData({
        seriesId: SERIES_IDS.NET_ADDITIONS_ID,
        data: seriesMappers.netAdditions(netAdditionsSeries),
        series
      })
    )
  }
  return seriesData
}

const DEFAULT_BREAKPOINTS = {
  day: 0,
  month: 185,
  quarter: 730,
  year: 1460
}

const getDateIntervalByDateRange = (startDate, endDate, breakPoints = DEFAULT_BREAKPOINTS) => {
  const diffInDays = Math.floor(
    dayjs.utc(endDate).diff(startDate, 'day', true)
  )
  if (!isNullOrUndefined(breakPoints.year) && diffInDays > breakPoints.year) return DATE_TYPES.year
  if (!isNullOrUndefined(breakPoints.quarter) && diffInDays > breakPoints.quarter) return DATE_TYPES.quarter
  if (!isNullOrUndefined(breakPoints.month) && diffInDays > breakPoints.month) return DATE_TYPES.month

  return DATE_TYPES.day
}

const getCustomDataInterval = ({
  identifier,
  minDate,
  minDailyStartDate,
  selectedDate,
  selectedDataInterval
}) => {
  if (!minDailyStartDate) return selectedDataInterval

  const isHistoryAvailable = dayjs
    .utc(minDate)
    .isBefore(minDailyStartDate, DAYJS_UNIT_TYPES.DAY)
  if (!isHistoryAvailable) return selectedDataInterval

  const isDateBeforeMinDailyStartDate = dayjs
    .utc(selectedDate)
    .isBefore(minDailyStartDate, DAYJS_UNIT_TYPES.DAY)

  if (
    selectedDataInterval === DATE_TYPES.day &&
    isDateBeforeMinDailyStartDate &&
    identifier !== 'cumulativeReturn'
  ) {
    return DATE_TYPES.month
  }
  if (
    (selectedDataInterval === DATE_TYPES.month &&
      !isDateBeforeMinDailyStartDate) ||
    identifier === 'cumulativeReturn'
  ) {
    return DATE_TYPES.day
  }
  return selectedDataInterval
}

const calculateGetDateInterval = ({
  value,
  availableDates,
  selectedDateRange,
  selectedIdentifier,
  selectedGraphDataValue,
  intervalBreakpoints
}) => {
  const { startDate: start } = selectedDateRange || {}
  const { min, mainDate, minDailyStartDate } = availableDates
  const { startDate } = selectedGraphDataValue || {}
  const selectedStartDate = start || startDate || mainDate

  return getCustomDataInterval({
    selectedDate: selectedStartDate,
    identifier: selectedIdentifier,
    minDate: min,
    minDailyStartDate,
    selectedDataInterval: value,
    intervalBreakpoints
  })
}

const useDateInterval = ({
  selectedIdentifier,
  availableDates,
  selectedDateRange,
  selectedGraphDataValue,
  defaultDateInterval,
  intervalBreakpoints
}) => {
  const [selectedDataInterval, setSelectedDataInterval] = useState(
    defaultDateInterval || getDateIntervalByDateRange(
      selectedGraphDataValue.startDate,
      selectedGraphDataValue.endDate,
      intervalBreakpoints
    )
  )

  const onSelectDataInterval = useCallback(
    (value, automaticDataInterval = true) => {
      let dataInterval = value
      if (automaticDataInterval) {
        dataInterval = calculateGetDateInterval({
          value,
          availableDates,
          selectedDateRange,
          selectedIdentifier,
          selectedGraphDataValue
        })
      }
      setSelectedDataInterval(dataInterval)
    },
    [
      availableDates,
      selectedDateRange,
      selectedIdentifier,
      selectedGraphDataValue
    ]
  )

  return {
    selectedDataInterval,
    setSelectedDataInterval,
    onSelectDataInterval
  }
}

const getStartDateForCumulativeReturn = ({
  selectedStartDate,
  selectedEndDate,
  adjustedStartDate
}) => {
  if (!adjustedStartDate) return selectedStartDate

  const isStartDateBeforeMinDailyStartDate = dayjs
    .utc(selectedStartDate)
    .isBefore(adjustedStartDate, DATE_TYPES.day)

  if (isStartDateBeforeMinDailyStartDate) {
    const isEndDateBeforeMinDailyStartDate = dayjs
      .utc(selectedEndDate)
      .isBefore(adjustedStartDate, DATE_TYPES.day)

    if (!isEndDateBeforeMinDailyStartDate) return adjustedStartDate
  }
  return selectedStartDate
}

export const useLineChart = ({
  identifiersMapper,
  assetFilters,
  series,
  lineChartProps,
  onGraphDateChange,
  defaultFilter,
  dateRangeOptions,
  defaultValues,
  configurationStorageKey,
  dateIntervalOptions,
  intervalBreakpoints
}) => {
  const { availableDates, clientId } = useAppContext()
  const [selectedDateRange, setSelectedDateRange] = useState(
    defaultValues.dateRange
  )
  const [selectedGraphCurve, setSelectedGraphCurve] = useState(
    defaultValues.graphCurve
  )
  const [selectedGraphDate, setSelectedGraphDate] = useState(
    defaultValues.graphDate
  )
  const [selectedIdentifier, setSelectedIdentifier] = useState(
    defaultValues.identifier
  )

  const [
    showDateRangePicker,
    ,
    toggleDateRangePickerOn,
    toggleDateRangePickerOff
  ] = useToggle()

  const { dateRange: selectedGraphDataValue, options: graphDateRangeOptions } =
    useRelativeDateRange(selectedGraphDate, availableDates, dateRangeOptions)

  const { selectedDataInterval, onSelectDataInterval } = useDateInterval({
    availableDates,
    selectedDateRange,
    selectedIdentifier,
    selectedGraphDataValue,
    defaultDateInterval: defaultValues.dataInterval,
    intervalBreakpoints
  })

  useEffect(() => {
    if (configurationStorageKey) {
      const payload = {
        dateRange: selectedDateRange,
        dataInterval: selectedDataInterval,
        graphCurve: selectedGraphCurve,
        graphDate: selectedGraphDate,
        identifier: selectedIdentifier
      }
      if (Object.values(payload).every((val) => val !== undefined)) {
        localStorageHelper.store(configurationStorageKey, payload)
      }
    }
  }, [
    selectedDateRange,
    selectedGraphCurve,
    selectedGraphDate,
    selectedIdentifier,
    selectedDataInterval,
    configurationStorageKey
  ])

  const { key, startDate, endDate, minDailyStartDate, minPerformanceStartDate } = useMemo(() => {
    const { mainDate, minDailyStartDate, minPerformanceStartDate } = availableDates
    const { startDate: start, endDate: end } = selectedDateRange || {}
    return {
      key: selectedGraphDataValue?.key,
      startDate: start || selectedGraphDataValue?.startDate || mainDate,
      endDate: end || selectedGraphDataValue?.endDate || mainDate,
      minDailyStartDate,
      minPerformanceStartDate
    }
  }, [
    availableDates,
    selectedDateRange,
    selectedGraphDataValue?.key,
    selectedGraphDataValue?.endDate,
    selectedGraphDataValue?.startDate
  ])

  const dataParams = useDeepCompareMemo(() => {
    let selectedStartDate = startDate
    if (selectedIdentifier === 'cumulativeReturn' && (minDailyStartDate || minPerformanceStartDate)) {
      selectedStartDate = getStartDateForCumulativeReturn({
        selectedStartDate: startDate,
        selectedEndDate: endDate,
        adjustedStartDate: minPerformanceStartDate ?? minDailyStartDate
      })
    }
    const identifiers = [selectedIdentifier]
    if (
      defaultFilter?.benchmark?.benchmarkType === 'client' &&
      selectedIdentifier === 'cumulativeReturn'
    ) {
      identifiers.push('cumulativeBenchmark')
    }
    identifiers.push('adjustedNetAdditions')

    return {
      key,
      endDate,
      startDate: selectedStartDate,
      identifiers
    }
  }, [
    key,
    defaultFilter,
    availableDates,
    selectedDateRange,
    selectedIdentifier,
    selectedGraphDataValue
  ])

  /** Resolve line chart identifiers */
  const { historicalIdentifierOptions, loading: identifiersLoading } =
    useFetchState(
      useCallback(
        async (safeSetState) => {
          try {
            const { data } = await getHistoricalRegisterIdentifiers()
            const options = data.map(({ name, value }) => ({
              value,
              label: name
            }))
            const historicalIdentifierOptions =
              get(
                lineChartMappers.identifiersMapper,
                identifiersMapper,
                undefined
              ) || identifiersMapper
            safeSetState({
              historicalIdentifierOptions: historicalIdentifierOptions
                ? historicalIdentifierOptions.call({ options })
                : options
            })
          } catch (err) {
            console.error(err)
          }
        },
        [identifiersMapper]
      )
    )

  const normalizedDatesQuery = useMemo(() => {
    const { key, startDate, endDate } = dataParams
    return {
      query: {
        dateRanges: { seriesDateRange: { key, startDate, endDate } }
      },
      queryOptions: {}
    }
  }, [dataParams])

  const {
    data: normalizedDateRanges,
    isLoading: isLoadingNormalizedDates
  } = useNormalizeDates(
    normalizedDatesQuery.query,
    normalizedDatesQuery.queryOptions
  )

  const coreSeriesQuery = useMemo(() => {
    const {
      ASSET_CLASSES,
      SUBCLASSES,
      ASSETS_SUBCLASS,
      ASSETS: _ASSETS
    } = assetFilters

    const ASSETS = ASSETS_SUBCLASS || _ASSETS
    const assetClassTagIds = ASSET_CLASSES ? [ASSET_CLASSES?.id] : []
    const subclassTagIds = SUBCLASSES ? [SUBCLASSES?.id] : []
    const assetIds = ASSETS ? [ASSETS?.id] : []

    const { identifiers } = dataParams
    const [leadIdentifier] = identifiers

    const benchmarkIndex = identifiers.indexOf(SERIES_IDS.CLIENT_BENCHMARK_ID)
    const dataPoints = [...identifiers]
    if (benchmarkIndex !== -1) {
      dataPoints[benchmarkIndex] = 'benchmarks.benchmarkValue'
    }
    const { seriesDateRange } = normalizedDateRanges || {}
    return {
      query: {
        dateRange: seriesDateRange,
        levelFilters: {
          identifier: leadIdentifier,
          dateType: selectedDataInterval,
          assetIds,
          subclassTagIds,
          assetClassTagIds,
          clientIds: [clientId],
          calcType: CALC_TYPES.timeSeries,
          levelTypes: [
            LEVEL_TYPES.CLIENT,
            ASSET_CLASSES && LEVEL_TYPES.ASSET_CLASS_TAG,
            ASSETS_SUBCLASS && LEVEL_TYPES.SUBCLASS_TAG,
            ASSETS && LEVEL_TYPES.ASSETS
          ].filter(Boolean),
          ...defaultFilter
        },
        options: {
          dataPoints: identifiers
        }
      },
      queryOptions: {
        enabled: !isLoadingNormalizedDates
      }
    }
  }, [
    clientId,
    dataParams,
    defaultFilter,
    assetFilters,
    selectedDataInterval,
    normalizedDateRanges,
    isLoadingNormalizedDates
  ])

  /** Get series data from api */
  const { data: seriesInfo, isLoading } = useCoreSeriesData(
    coreSeriesQuery.query,
    coreSeriesQuery.queryOptions
  )

  const baseLineChartProps = useMemo(() => {
    if (!seriesInfo?.series) return {}

    const isReturnSelected =
      dataParams.identifiers.includes('cumulativeReturn')
    const isBenchmarkSelected =
      dataParams.identifiers.includes('cumulativeBenchmark')
    const isReturnOrBenchmark = isReturnSelected || isBenchmarkSelected

    const data = getSeriesLineData({
      identifier: selectedIdentifier,
      selectedSeries: seriesInfo.series[selectedIdentifier],
      series
    })

    let { axisLeft, lineData } = lineChartProps
    if (!isEmpty(lineChartProps.lineData)) {
      const lineDataMapper =
        get(lineChartMappers.lineData, lineChartProps.lineData, undefined) ||
        lineChartProps.lineData

      lineData = lineDataMapper.call({
        values: seriesInfo.series[selectedIdentifier]
      })
    } else {
      if (isReturnSelected && isBenchmarkSelected) {
        const benchmarkSeries = seriesInfo.series['benchmarks.benchmarkValue']
          ? seriesInfo.names.benchmarkNames.reduce(
            (acc, name, index) => {
              const benchmarkIdentifier = name
              const benchmarkColor = darken(
                DEFAULT_COLORS[SERIES_IDS.CLIENT_BENCHMARK_ID],
                index + 1 * 0.25
              )

              return [
                ...acc,
                ...getSeriesLineData({
                  identifier: benchmarkIdentifier,
                  color: benchmarkColor,
                  selectedSeries: seriesInfo.series[
                    'benchmarks.benchmarkValue'
                  ].map((seriesData) => {
                    return {
                      ...seriesData,
                      value: seriesData.value[index]
                    }
                  }),
                  series
                })
              ]
            },
            []
          ) : []

        lineData = [
          ...getSeriesLineData({
            identifier: selectedIdentifier,
            selectedSeries: seriesInfo.series[selectedIdentifier],
            series
          }),
          ...benchmarkSeries
        ]
      } else {
        lineData = getSeriesLineData({
          identifier: selectedIdentifier,
          selectedSeries: seriesInfo.series[selectedIdentifier],
          netAdditionsSeries:
            isReturnSelected && !isBenchmarkSelected
              ? []
              : seriesInfo.series.adjustedNetAdditions,
          series
        })
      }
    }

    if (isEmpty(axisLeft)) {
      axisLeft = {
        format: isReturnOrBenchmark
          ? (value) => {
            return Math.trunc(value * 100 * 100) / 100 + '%'
          }
          : '$.2s'
      }
    } else {
      const axisLeftFormat = get(
        lineChartMappers.axisLeft,
        axisLeft.format,
        axisLeft.format
      )

      axisLeft = {
        ...axisLeft,
        format: isFunction(axisLeftFormat)
          ? axisLeftFormat.call({ isReturnOrBenchmark })
          : axisLeftFormat
      }
    }

    const { config: configOverrides = {} } =
      series.find(({ label }) => label === first(data)?.id) || {}

    const { config: secondaryConfigOverrides = {} } =
      series.find(({ label }) => label === last(lineData)?.id) || {}

    return {
      data,
      width: '100%',
      height: '404px',
      fontFamily: 'Gotham-Book',
      areaOpacity: 1,
      margin: {
        top: 20,
        right: 20,
        bottom: 60,
        left: 60
      },
      axisBottom: { tickRotation: -40 },
      lineWidth: isReturnOrBenchmark ? 4 : 0,
      yFormat: isReturnOrBenchmark ? '.2s' : '$.2s',
      enableArea: !isReturnOrBenchmark,
      tooltipFormatter: isReturnOrBenchmark ? 'DATE_PERCENTAGE' : '',
      yScale: {
        type: 'linear',
        min: 'auto',
        max: 'auto'
      },
      ...lineChartProps,
      axisLeft: {
        tickValues: 5,
        ...axisLeft
      },
      lineData,
      curve: selectedGraphCurve,
      primaryLineConfigOverrides: configOverrides,
      secondaryLineConfigOverrides: secondaryConfigOverrides
    }
  }, [
    dataParams,
    series,
    lineChartProps,
    seriesInfo,
    selectedIdentifier,
    selectedGraphCurve
  ])

  const onDateRangeChange = useCallback((startDate, endDate) => {
    setSelectedDateRange({ startDate, endDate })
  }, [])

  const onSelectIdentifier = useCallback((value) => {
    setSelectedIdentifier(value)
  }, [])

  const onAvailableDateSelect = useCallback(
    (value, selectedOption) => {
      setSelectedGraphDate(value)
      if (value === DATE_RANGE_CUSTOM_OPTION_KEYS.CUSTOM) {
        toggleDateRangePickerOn()
      } else {
        setSelectedDateRange(null)
        toggleDateRangePickerOff()

        const startDate = selectedOption.getStartDate(availableDates)
        const dataInterval = getDateIntervalByDateRange(startDate, endDate, intervalBreakpoints)
        onSelectDataInterval(dataInterval, false)

        onGraphDateChange && onGraphDateChange(value, selectedOption)
      }
    },
    [
      endDate,
      availableDates,
      onGraphDateChange,
      onSelectDataInterval,
      toggleDateRangePickerOn,
      toggleDateRangePickerOff,
      intervalBreakpoints
    ]
  )

  const chartCurveOptions = Object.values(CHART_CURVE_INTERPOLATIONS).map(
    (curveType) => ({
      key: curveType,
      value: curveType,
      label: capitalizeFirstLetter(curveType)
    })
  )

  const onSelectGraphCurve = useCallback(
    (value) => {
      if (value in CHART_CURVE_INTERPOLATIONS) {
        setSelectedGraphCurve(value)
      } else {
        setSelectedGraphCurve(CHART_CURVE_INTERPOLATIONS.natural)
      }
    },
    [setSelectedGraphCurve]
  )

  return {
    historicalIdentifierOptions,
    identifiersLoading,
    seriesInfo,
    loading: isLoading,
    showDateRangePicker,
    selectedIdentifier,
    baseLineChartProps,
    availableDates,
    onAvailableDateSelect,
    onSelectIdentifier,
    dateInterval: {
      value: selectedDataInterval || DATE_TYPES.day,
      onChange: onSelectDataInterval,
      options: dateIntervalOptions,
      size: BUTTON_SIZES.small,
      showCheckMarOnSelectedItems: true
    },
    chartCurve: {
      value: selectedGraphCurve || CHART_CURVE_INTERPOLATIONS.natural,
      onChange: onSelectGraphCurve,
      options: chartCurveOptions,
      size: BUTTON_SIZES.small,
      showCheckMarOnSelectedItems: true
    },
    onDateRangeChange,
    selectedGraphDate,
    graphDateRangeOptions
  }
}
