import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useFormattingContext } from '../../../organisms/FormattingProvider/FormattingContext'
import AnalysisMetric from './AnalysisMetric'

function AvailableCashMetric ({ cashData, loading }) {
  const { formatter } = useFormattingContext()
  const value = useMemo(() => {
    if (loading) return ''

    const variance = cashData.availableCash

    return formatter(variance, '$0,0.0a')
  }, [loading, cashData, formatter])

  return (
    <AnalysisMetric loading={loading} metricName='Cash Available' value={value} />
  )
}

AvailableCashMetric.propTypes = {
  loading: PropTypes.bool,
  cashData: PropTypes.shape({
    availableCash: PropTypes.number
  })
}

AvailableCashMetric.defaultProps = {
}

export default AvailableCashMetric
