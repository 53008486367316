import React from 'react'
import PropTypes from 'prop-types'
import { useOktaAuth } from '@okta/okta-react'
import { makeStyles } from '@material-ui/core/styles'
import ErrorIcon from '@material-ui/icons/Error'
import { TEXT_VARIANTS } from '../../constants'
import { useAuthState, usePermissions } from '../../hooks'
import Text from './Text'
import RoundedButton from './RoundedButton'

const useStyles = makeStyles(() => ({
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  button: {
    marginTop: '1rem'
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    maxHeight: '500px'
  },
  publicText: {
    margin: '1rem 0'
  },
  debug: {
    width: '500px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'left'
  }
}))

const dbugText = ({ code, description, message, stack }) => {
  const errorDebug = { code, description, message, stack }
  return Object.keys(errorDebug).map((errorKey) => {
    const text = `${errorKey.toLocaleUpperCase()}: ${errorDebug[errorKey]}`
    return (<Text key={errorKey} text={text} variant={TEXT_VARIANTS.body2} />)
  })
}

function ErrorComponent ({ error, refresh }) {
  const { authState: oktaAuthState } = useOktaAuth() || {}
  const authState = useAuthState(oktaAuthState)
  const { isSummitUser } = usePermissions(authState.accessToken || {})
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <ErrorIcon />
        <Text
          text='An unexpected error has occurred. Please contact the administrators'
          variant={TEXT_VARIANTS.body2}
          customFontSize='1rem'
          className={classes.publicText}
        />
        {refresh &&
          <RoundedButton className={classes.button} primary onClick={() => window.location.reload()}>
            Reload
          </RoundedButton>}
        {isSummitUser && (
          <>
            <Text text='This and the next lines are visible only for summit users' variant={TEXT_VARIANTS.body2} color='red' />
            <Text text='Error Details' variant={TEXT_VARIANTS.body2} />
            <div className={classes.debug}>
              {dbugText(error)}
            </div>
          </>
        )}
      </div>
    </div>
  )
}

ErrorComponent.propTypes = {
  error: PropTypes.instanceOf(Error).isRequired,
  refresh: PropTypes.bool
}

ErrorComponent.defaultProps = {
  refresh: false
}

export default ErrorComponent
