import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import Text from '../../atoms/Text'

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: '3rem'
  },
  header: {
    marginBottom: '2rem'
  },
  title: {
    color: '#141929',
    fontSize: '1.5rem',
    fontWeight: 'bold'
  }
}))

const WealthMissionSection = ({ title, children }) => {
  const classes = useStyles()
  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <Text className={classes.title}>{title}</Text>
      </div>
      {children}
    </div>
  )
}

WealthMissionSection.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
}

export default WealthMissionSection
