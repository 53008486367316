import React, { useCallback, useState } from 'react'
import { IconButton, ListItemIcon, ListItemText, Menu, MenuItem } from '@material-ui/core'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import EditIcon from '@material-ui/icons/Edit'
import EyeIcon from '@material-ui/icons/Visibility'
import PropTypes from 'prop-types'
import { usePolicy } from '../../../../../hooks/usePolicy'

function SsoProviderActions ({ onEdit, onViewActivity }) {
  const canEdit = usePolicy('admin_edit_wo_integrations')
  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = useCallback((event) => {
    setAnchorEl(event.currentTarget)
  }, [])

  const handleClose = useCallback(() => {
    setAnchorEl(null)
  }, [])

  const handleAction = useCallback((action) => () => {
    action()
    handleClose()
  }, [handleClose])

  return (
    <>
      <IconButton onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        {canEdit ? (
          <MenuItem onClick={handleAction(onEdit)}>
            <ListItemIcon>
              <EditIcon />
            </ListItemIcon>
            <ListItemText>Edit</ListItemText>
          </MenuItem>
        ) : null}
        <MenuItem onClick={handleAction(onViewActivity)}>
          <ListItemIcon>
            <EyeIcon />
          </ListItemIcon>
          <ListItemText>View User Activity</ListItemText>
        </MenuItem>
      </Menu>
    </>
  )
}

SsoProviderActions.propTypes = {
  onEdit: PropTypes.func,
  onViewActivity: PropTypes.func
}

export default SsoProviderActions
