import React, { useCallback, useMemo, useRef } from 'react'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { Tooltip } from '@material-ui/core'
import Select from '../../molecules/Select'
import { BUTTON_SIZES } from '../../../constants'
import Icon from '../../atoms/Icon'
import { useFormattingContext } from '../FormattingProvider/FormattingContext'
import CalendarYearDialog from './CalendarYearDialog'
import CustomRangeDialog from './CustomRangeDialog'

dayjs.extend(utc)

const DateRangeSelectorCell = ({ column, dateRangeOptions, dateRangeKey, values, onChange }) => {
  const { formatter } = useFormattingContext()

  const _values = useMemo(() => {
    return Object.entries(values).reduce((prev, [key, value]) => {
      prev[key] = {
        ...value,
        key: value.key.split(':').at(0)
      }
      return prev
    }, {})
  }, [values])
  const resolvedDateRange = useMemo(() => {
    return _values?.[dateRangeKey]
  }, [dateRangeKey, _values])

  const options = useMemo(() => {
    const disabledKeys = Object.entries(_values).filter(([k]) => k !== dateRangeKey).map(([, v]) => v.key)
    const selectedValue = _values?.[dateRangeKey]
    return dateRangeOptions.map(dro => {
      return ({
        ...dro,
        disabled: disabledKeys.includes(dro.value),
        displayLabel: dro.key === selectedValue?.key ? selectedValue?.label : undefined
      })
    })
  }, [dateRangeOptions, _values, dateRangeKey])

  const calendarRef = useRef(null)
  const customRef = useRef(null)
  const changeHandler = useCallback((value) => {
    if (value === 'CLY') {
      return calendarRef.current.open()
    }
    if (value === 'CTM') {
      return customRef.current.open()
    }
    onChange(dateRangeKey, value)
  }, [calendarRef, customRef, dateRangeKey, onChange])

  const style = useMemo(() => {
    return {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: column.alignRight ? 'flex-end' : 'center',
      alignItems: 'center',
      gap: '10px'
    }
  }, [column])

  return (
    <div style={style} className='__date-range-selector'>
      <Select
        placeholder='No Date Selected'
        value={resolvedDateRange?.key}
        options={options}
        onChange={changeHandler}
        size={BUTTON_SIZES.small}
        showCheckMarOnSelectedItems
      />
      {resolvedDateRange ? (
        <Tooltip
          enterDelay={0}
          title={(
            <div>
              <span>{formatter(resolvedDateRange.startDate, 'tradeDate')}</span>
              <span> to </span>
              <span>{formatter(resolvedDateRange.endDate, 'tradeDate')}</span>
            </div>
          )}
          arrow
          placement='top'
          classes={{
            tooltip: 'big_tooltip'
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Icon name='info' />
          </div>
        </Tooltip>
      ) : null}
      <CalendarYearDialog ref={calendarRef} onSave={(value) => onChange(dateRangeKey, value)} />
      <CustomRangeDialog ref={customRef} onSave={(value) => onChange(dateRangeKey, value)} />
    </div>
  )
}

DateRangeSelectorCell.propTypes = {
  column: PropTypes.object,
  dateRangeOptions: PropTypes.array,
  dateRangeKey: PropTypes.string,
  values: PropTypes.objectOf(PropTypes.string),
  onChange: PropTypes.func
}

export default DateRangeSelectorCell
