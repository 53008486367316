import React, { useCallback, useImperativeHandle, useState } from 'react'
import PropTypes from 'prop-types'
import RoundedModal from '../../../../../molecules/RoundedModal'
import { useComponentModelContext } from '../ComponentModelProvider'
import AddTagForm from './AddTagForm'

const AddTagDialog = React.forwardRef(function AddTagDialog ({ onAdd }, ref) {
  const { componentModel } = useComponentModelContext()
  const [dialogState, setDialogState] = useState({ open: false, processing: false })
  const close = useCallback(() => {
    setDialogState({ open: false, processing: false })
  }, [setDialogState])

  const complete = useCallback((componentModel) => {
    close()
    onAdd(componentModel)
  }, [close, onAdd])

  useImperativeHandle(ref, () => ({
    open: (item) => setDialogState({ open: true, processing: false, parent: item })
  }), [setDialogState])

  return (
    <RoundedModal
      title='Add Classification Tag'
      open={dialogState.open}
      onClose={close}
      position='top'
      disablePortal={false}
    >
      <div>
        <AddTagForm onComplete={complete} classificationTagTypeId={componentModel.classificationTagTypeId} />
      </div>
    </RoundedModal>
  )
})

AddTagDialog.propTypes = {
  onAdd: PropTypes.func.isRequired
}

export default AddTagDialog
