import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { TEXT_VARIANTS, SIZE_VARIANTS } from '../../constants'
import Avatar from '../atoms/Avatar'

const useStyles = makeStyles((theme) => ({
  small: {
    width: theme.spacing(4),
    height: theme.spacing(4)
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  titleContent: {
    marginLeft: theme.spacing(2),
    justifyContent: 'flex-start',
    alignItems: 'center'
  }
}))

const ProfileTitle = ({
  avatarUseOneInitial,
  additionalClasses,
  avatarLetters,
  avatarSize,
  avatarSrc,
  subtitle,
  title
}) => {
  const classes = useStyles()
  return (
    <div className={`${classes.titleContainer} ${additionalClasses}`}>
      <Avatar src={avatarSrc} size={avatarSize} useOneInitial={avatarUseOneInitial} avatarLetters={avatarLetters} />
      <div className={classes.titleContent}>
        <Typography component='div' variant={TEXT_VARIANTS.h3}>
          {title}
        </Typography>
        {subtitle && <Typography component='div'>{subtitle}</Typography>}
      </div>
    </div>
  )
}

ProfileTitle.propTypes = {
  additionalClasses: PropTypes.string,
  avatarUseOneInitial: PropTypes.bool,
  avatarLetters: PropTypes.string,
  avatarSize: PropTypes.oneOf(Object.values(SIZE_VARIANTS)),
  avatarSrc: PropTypes.string,
  subtitle: PropTypes.string,
  title: PropTypes.string.isRequired
}

ProfileTitle.defaultProps = {
  additionalClasses: '',
  avatarUseOneInitial: false,
  avatarLetters: undefined,
  avatarSize: SIZE_VARIANTS.medium,
  avatarSrc: '',
  subtitle: undefined
}

export default ProfileTitle
