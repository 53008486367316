/* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import Section from './shared/Section'

const useStyles = makeStyles((theme) => ({
  overrides: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.layout.gap.medium,
    padding: `0 ${theme.layout.padding.medium}`
  },
  item: {
    backgroundColor: theme.palette.gray.main,
    textAlign: 'center',
    padding: `${theme.layout.padding.thin} ${theme.layout.padding.medium}`,
    width: '100%',
    '&:hover': {
      cursor: 'pointer'
    },
    '&.__selected': {
      outline: `2px solid ${theme.palette.primary.main}`
    }
  }
}))

function FeeOverrides ({ overrides, selectedDetail, onSelectDetail }) {
  const classes = useStyles()

  if (!overrides) {
    return null
  }

  return (
    <Section>
      {overrides ? (
        <div className={classes.overrides}>
          <div
            className={clsx(classes.item, {__selected: selectedDetail?.type === 'accountOverrides' })}
            onClick={() => onSelectDetail({type: 'accountOverrides', detail: overrides.accountOverrides })}
          >
            {overrides.accountOverrides.length} Account Overrides
          </div>
          <div
            className={clsx(classes.item, {__selected: selectedDetail?.type === 'classOverrides' })}
            onClick={() => onSelectDetail({type: 'classOverrides', detail: overrides.classOverrides })}
          >
            {overrides.classOverrides.length} Class Overrides
          </div>
          <div
            className={clsx(classes.item, {__selected: selectedDetail?.type === 'positionOverrides' })}
            onClick={() => onSelectDetail({type: 'positionOverrides', detail: overrides.positionOverrides })}
          >
            {overrides.positionOverrides.length} Position Overrides
          </div>
          <div
            className={clsx(classes.item, {__selected: selectedDetail?.type === 'assetOverrides' })}
            onClick={() => onSelectDetail({type: 'assetOverrides', detail: overrides.assetOverrides })}
          >
            {overrides.assetOverrides.length} Asset Overrides
          </div>
        </div>
      ) : null}
    </Section>
  )
}

FeeOverrides.propTypes = {
  overrides: PropTypes.shape({
    accountOverrides: PropTypes.array,
    classOverrides: PropTypes.array,
    assetOverrides: PropTypes.array,
    positionOverrides: PropTypes.array
  }),
  selectedDetail: PropTypes.any,
  onSelectDetail: PropTypes.func
}

export default FeeOverrides
