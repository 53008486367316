import React, { useCallback } from 'react'
import { useGroupingContext } from '../../organisms/GroupingProvider/GroupingContext'
import BenchmarksTable from './BenchmarksTable'

const BenchmarksTableWrapper = (props) => {
  const { groupingDateRanges, setPerformanceTableProps } = useGroupingContext()

  const tableRef = useCallback(
    (node) => {
      if (node !== null) {
        setPerformanceTableProps(node)
      }
    },
    [setPerformanceTableProps]
  )
  return (
    <BenchmarksTable
      ref={tableRef}
      {...props}
      dateRanges={groupingDateRanges}
    />
  )
}

export default BenchmarksTableWrapper
